import { ComponentsManager, Entity } from '@comall-backend-builder/core/lib/parser';
import { WrappedFormUtils } from 'antd/lib/form/Form';
import { defaults } from 'lodash';
import { Component, createElement } from 'react';
import { connect } from 'react-redux';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';

interface Props {
    componentId?: string;
    entity: Entity;
    config: {
        component: string;
    };
    wrappedComponentRef: (instance: WrappedFormUtils) => void;
    getForm(): WrappedFormUtils;
}

class InternalWechatOfficalParametricQrcodeSubsiteMerchantForm extends Component<Props> {
    private get isCreateForm() {
        return this.props.config.component.includes('CreateForm');
    }
    private get subsiteIdPath() {
        const { getForm } = this.props;
        const form = getForm();
        if (!form) {
            return '';
        }
        if (form.getFieldValue('baseInfo.dimensionType') === 'SUBSITE') {
            return 'baseInfo.subsite[0].id';
        }
        return 'baseInfo.merchant[0].subSiteId';
    }
    private get wechatOfficialAccountPlaceholder() {
        const { getForm } = this.props;
        const form = getForm();
        if (!form) {
            return services.language.getText('qxzygmd');
        }
        if (form.getFieldValue('baseInfo.dimensionType') === 'SUBSITE') {
            return services.language.getText('qxzygmd');
        }
        if (form.getFieldValue('baseInfo.dimensionType') === 'MERCHANT') {
            return '请选择一个专柜';
        }
        return services.language.getText('qxzygmd');
    }

    render() {
        const {
            config: { component },
            componentId,
            entity,
            wrappedComponentRef,
        } = this.props;
        return createElement(ComponentsManager.get('Card'), {
            content: {
                component,
                componentId,
                entity,
                wrappedComponentRef,
                direction: 'horizontal',
                labelCol: 5,
                controlCol: 19,
                fields: [
                    { property: 'baseInfo.dimensionType', modifiable: this.isCreateForm },
                    {
                        property: 'baseInfo.subsite',
                        modifiable: this.isCreateForm,
                        visible(row: any) {
                            return row.baseInfo.dimensionType === 'SUBSITE';
                        },
                    },
                    {
                        property: 'baseInfo.merchant',
                        modifiable: this.isCreateForm,
                        visible(row: any) {
                            return row.baseInfo.dimensionType === 'MERCHANT';
                        },
                    },
                    {
                        property: 'baseInfo.wechatOfficialAccount',
                        modifiable: this.isCreateForm,
                        controlConfig: {
                            subsiteIdPath: this.subsiteIdPath,
                            placeholder: this.wechatOfficialAccountPlaceholder,
                        },
                    },
                    { property: 'baseInfo.name' },
                    { property: 'baseInfo.effectiveType', visible: () => this.isCreateForm },
                    {
                        property: 'baseInfo.effectiveTypeDisplay',
                        visible: () => !this.isCreateForm,
                    },
                    {
                        property: 'baseInfo.effectiveDays',
                        visible: (fields: any) => {
                            return (
                                fields.baseInfo.effectiveType === 'temporary' && this.isCreateForm
                            );
                        },
                    },
                    { property: 'baseInfo.replyEffectiveType' },
                    {
                        property: 'baseInfo.replyDateRange',
                        visible(fields: any) {
                            return fields.baseInfo.replyEffectiveType === 'temporary';
                        },
                    },
                    { property: 'content.useDefaultReply' },
                    {
                        property: 'content.replyContents',
                        visible(fields: any) {
                            return (
                                !fields.content.useDefaultReply &&
                                fields.baseInfo?.wechatOfficialAccount
                            );
                        },
                    },
                    {
                        property: 'content.defaultReplyContents',
                        modifiable: false,
                        className: 'wechat-official-parametric-qrcode__default-reply',
                        visible(fields: any) {
                            return (
                                fields.content.useDefaultReply &&
                                fields.baseInfo?.wechatOfficialAccount
                            );
                        },
                    },
                ],
                submit: true,
                footer: {
                    items: [
                        {
                            htmlType: 'button',
                            route: 'goBack',
                            text: services.language.getText('back'),
                            type: 'default',
                            style: { marginLeft: 10 },
                        },
                    ],
                },
                onSubmitSuccess: () => {
                    AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                        services.behaviorHandle({ route: 'goBack' })
                    );
                },
            },
        });
    }
}
function mapStateToProps(state: any, props: any) {
    const { components = {} } = state;
    for (const key in components) {
        if (Object.prototype.hasOwnProperty.call(components, key)) {
            const component = components[key];
            if (key.includes('WechatOfficalParametricQrcodeSubsiteMerchantForm')) {
                return {
                    fields: component.fields,
                };
            }
        }
    }
    return {};
}
function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit } = props;
    let form: any;

    return defaults(
        {
            onSubmit,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
        }
    );
}
export const WechatOfficalParametricQrcodeSubsiteMerchantForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(InternalWechatOfficalParametricQrcodeSubsiteMerchantForm);
