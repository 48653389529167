import React, { Component } from 'react';
import { forEach, isEqual } from 'lodash';
import {
    PickingListsOrders,
    ORDER_STATUS_ENUM,
    PickingListsOrderItems,
    ProductType,
    OrderItemType,
} from '../../../services/order-print';
import { PickingGoods } from './picking-goods';
import './index.less';
import { services } from '@comall-backend-builder/core';

interface GoodsListProps {
    /**
     * 订单列表数据
     */
    pickingListOrder: PickingListsOrders;
    /**
     * 订单状态
     */
    status: ORDER_STATUS_ENUM;
    /**
     * 修改订单内容函数
     */
    onfieldChange: (field: string, value: string | number, id: string, orderId: string) => void;
}

interface GoodsListStates {
    /**
     * 称重商品列表
     */
    weightGoods: PickingListsOrderItems[];
    /**
     * 普通商品列表
     */
    normalGoods: PickingListsOrderItems[];
    /**
     * 赠品列表
     */
    giftGoods: PickingListsOrderItems[];
}

export class GoodsList extends Component<GoodsListProps, GoodsListStates> {
    state = {
        weightGoods: [],
        normalGoods: [],
        giftGoods: [],
    };
    componentDidMount() {
        const { pickingListOrder } = this.props;
        if (pickingListOrder) {
            this.handleOrderData(pickingListOrder);
        }
    }
    componentWillReceiveProps(props: GoodsListProps) {
        const { pickingListOrder: nextOrders } = props;
        const { pickingListOrder: prevOrders } = this.props;
        if (nextOrders && !isEqual(nextOrders, prevOrders)) {
            this.handleOrderData(nextOrders);
        }
    }

    handleOrderData = (order: PickingListsOrders) => {
        const normalGoods: PickingListsOrderItems[] = [];
        const weightGoods: PickingListsOrderItems[] = [];
        const giftGoods: PickingListsOrderItems[] = [];
        forEach(order.orderItems, (item) => {
            const type = item.goods.productType;
            if (item.orderItemType === OrderItemType.Gift) {
                giftGoods.push(item);
            } else {
                if (type === ProductType.WEIGHT) {
                    weightGoods.push(item);
                } else {
                    normalGoods.push(item);
                }
            }
        });
        this.setState({
            normalGoods,
            weightGoods,
            giftGoods,
        });
    };

    onfieldChange = (field: string, value: number | string, id: string) => {
        const { onfieldChange, pickingListOrder } = this.props;
        if (onfieldChange) {
            onfieldChange(field, value, id, pickingListOrder.orderId);
        }
    };

    render() {
        const { pickingListOrder, status } = this.props;
        const { weightGoods, normalGoods, giftGoods } = this.state;
        return (
            pickingListOrder && (
                <div className="list-wrapper">
                    <div className="list-row">
                        {services.language.getText('gldd')}：{pickingListOrder.orderNo}
                    </div>
                    <div className="list-row">
                        {services.language.getText('hybz')}:{pickingListOrder.orderRemark}
                    </div>
                    {weightGoods && weightGoods.length > 0 && (
                        <div className="list-row">{services.language.getText('czpmx')}</div>
                    )}
                    {weightGoods &&
                        weightGoods.length > 0 &&
                        weightGoods.map((goods) => {
                            return (
                                <PickingGoods
                                    status={status}
                                    orderItem={goods}
                                    onfieldChange={this.onfieldChange}
                                />
                            );
                        })}
                    {normalGoods && normalGoods.length > 0 && (
                        <div className="list-row">{services.language.getText('cgpmx')}</div>
                    )}
                    {normalGoods &&
                        normalGoods.length > 0 &&
                        normalGoods.map((goods) => {
                            return (
                                <PickingGoods
                                    status={status}
                                    orderItem={goods}
                                    onfieldChange={this.onfieldChange}
                                />
                            );
                        })}
                    {giftGoods && giftGoods.length > 0 && (
                        <div className="list-row">{services.language.getText('zpmx')}</div>
                    )}
                    {giftGoods &&
                        giftGoods.length > 0 &&
                        giftGoods.map((goods) => {
                            return (
                                <PickingGoods
                                    status={status}
                                    orderItem={goods}
                                    onfieldChange={this.onfieldChange}
                                />
                            );
                        })}
                </div>
            )
        );
    }
}
