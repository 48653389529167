import { api } from '@comall-backend-builder/core/lib/services';

interface TenantPrivilegeMetadata {
    /**
     * 权限名称，会用到
     */
    code: string;
    source?: {
        apiPath: string;
        apiRoot?: string;
        params?: any;
        transform?<I = any>(response: I): boolean;
    };
}

interface TenantPrivilege {
    metadata: TenantPrivilegeMetadata;
    status: 'pending' | 'fullfilled' | 'rejected';
    value: boolean;
}
const TenantPrivileges = new Map<string, TenantPrivilege>();

export function addTenantPrivilege(metadata: TenantPrivilegeMetadata) {
    if (TenantPrivileges.has(metadata.code)) {
        return;
    }
    TenantPrivileges.set(metadata.code, {
        metadata,
        status: 'pending',
        value: false,
    });
}

export function initTenantPrivileges() {
    TenantPrivileges.forEach(initTenantPrivilege);
}

export function checkTenantPrivilege(code: string) {
    const tenantPrivilege = TenantPrivileges.get(code);
    if (!tenantPrivilege) {
        // 如果没有注册过这个权限，则默认放行
        return true;
    }
    if (tenantPrivilege.status !== 'fullfilled') {
        initTenantPrivilege(tenantPrivilege);
    }
    return tenantPrivilege.value;
}

async function initTenantPrivilege(tenantPrivilege: TenantPrivilege) {
    const {
        metadata: { source },
    } = tenantPrivilege;
    if (!source) {
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
            apiPath: `/roles/super/privilege_set/${tenantPrivilege.metadata.code}/verify`,
        };
        return api
            .get({}, config)
            .then((response) => {
                tenantPrivilege.status = 'fullfilled';
                tenantPrivilege.value = Boolean(response);
            })
            .catch(() => {
                tenantPrivilege.status = 'rejected';
            });
    }
    return api
        .get(source.params, {
            apiPath: source.apiPath,
            apiRoot: source.apiRoot,
        })
        .then((response) => {
            const value = source.transform ? source.transform(response) : Boolean(response);
            tenantPrivilege.status = 'fullfilled';
            tenantPrivilege.value = value;
        })
        .catch(() => {
            tenantPrivilege.status = 'rejected';
        });
}
