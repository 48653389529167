import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { find } from 'lodash';

export const config: Config = {
    entities: {
        ReserveActivitiesEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
            apiPath: '/loader/admin/reserve-activities',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<applicableSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOTSTARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
            },
            properties: {
                activityInfo: {
                    type: 'object.subForm',
                    displayName: '<<hdsz>>',
                    properties: {
                        executeStatus: {
                            type: 'string',
                            displayName: '<<status>>',
                        },
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [{ required: true, whitespace: true, message: '<<qsrhdmc>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<applicableSubsite>>',
                            rules: [{ required: true, type: 'array' }],
                        },
                        goods: {
                            type: 'array.PresaleProducts',
                            displayName: '<<components.Tools.selectProduct>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<components.Tools.productSelectRequired>>',
                                },
                            ],
                        },
                        reserveType: {
                            type: 'string.options.radio',
                            displayName: '<<yyms>>',
                            defaultValue: 'ACTIVITY',
                            options: [
                                { id: 'ACTIVITY', name: '<<ahdyy>>' },
                                { id: 'ROUND', name: '<<accyy>>' },
                            ],
                            extra: '<<rahdyy>>',
                        },
                        maxOrderCount: {
                            type: 'number.tip',
                            displayName: '<<xdcs>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<inputThanZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^([1-9]\d*)$/;
                                        if (
                                            value !== undefined &&
                                            value !== null &&
                                            (!reg.test(value) || value === 0)
                                        ) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                addonbefore: '<<hdqjmyhkcggm>>',
                                addonafter: '<<frequency>>',
                                style: { width: '140px' },
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                                placeholder: '<<inputPlease>>',
                            },
                            extra: '<<zyhzhdqjkcggm>>',
                        },
                        description: {
                            type: 'string.text.paragraph',
                            displayName: '<<gzsm>>',
                            rules: [{ max: 5000 }],
                            controlConfig: {
                                rows: 4,
                                maxLength: 5000,
                            },
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],

                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.activityInfo?.subsites?.length &&
                                        row.activityInfo.subsites
                                            .map((item: any) => item.id)
                                            .join(',');
                                    return { subsiteIds };
                                },
                            },
                        },
                    },
                },
                roundInfo: {
                    type: 'object.subForm',
                    displayName: '<<hdcc>>',
                    properties: {
                        period: {
                            type: 'string.options.radio',
                            displayName: '<<cczq>>',
                            defaultValue: 'DAY',
                            options: [{ id: 'DAY', name: '<<mryc>>' }],
                        },
                        reserveTime: {
                            type: 'array.presaleTimepicker',
                            displayName: '<<yysj>>',
                            className: 'presale-range-timepicker',
                            rules: [
                                {
                                    required: true,
                                    message: '<<qtxyysj>>',
                                },
                            ],
                        },
                        time: {
                            type: 'array.presaleTimepicker',
                            displayName: '<<qgsj>>',
                            className: 'presale-range-timepicker',
                            rules: [
                                {
                                    required: true,
                                    message: '<<qtxqgsj>>',
                                },
                            ],
                            extra: '<<xyphyysjjxldxz>>',
                        },
                        memberLimit: {
                            type: 'number.tip',
                            displayName: '<<qgrs>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<inputThanZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^([1-9]\d*)$/;
                                        if (
                                            value !== undefined &&
                                            value !== null &&
                                            (!reg.test(value) || value === 0)
                                        ) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                addonbefore: '<<mchdzd>>',
                                addonafter: '<<rqg>>',
                                style: { width: '140px' },
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                                placeholder: '<<inputPlease>>',
                            },
                            extra: '<<zsyyyydyhjkqg>>',
                        },
                        quantityLimit: {
                            type: 'number.tip',
                            displayName: '<<mcxg>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<inputThanZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^([1-9]\d*)$/;
                                        if (
                                            value !== undefined &&
                                            value !== null &&
                                            (!reg.test(value) || value === 0)
                                        ) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                addonbefore: '<<mchdmyhzdgm>>',
                                addonafter: '<<jian>>',
                                style: { width: '140px' },
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                                placeholder: '<<inputPlease>>',
                            },
                        },
                    },
                },
                seniorInfo: {
                    type: 'object.subForm',
                    displayName: '<<seniorSetting>>',
                    collapsed: true,
                    properties: {
                        participateCondition: {
                            type: 'object.participateCondition',
                            displayName: '<<yytj>>',
                            controlConfig: {
                                codes: [
                                    {
                                        code: 'MINREGISTERDAYS',
                                        tip: '<<hdtksjs>>',
                                    },
                                    {
                                        code: 'CONSUMPTIONT',
                                        tip: '<<zcyhbxmyxfcsdd>>',
                                    },
                                    {
                                        code: 'CERTIFICATION',
                                    },
                                ],
                            },
                        },
                        receiptCertification: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '<<gmtj>>',
                            defaultValue: [],
                            options: [
                                {
                                    id: 'NEED',
                                    name: '<<shrxxwyhsmrzdxx>>',
                                },
                            ],
                            extra: '<<zshrxxywyh>>',
                        },
                    },
                },
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
        },
    },
    components: {
        PreSaleActivitiesView: {
            component: 'Viewport',
        },
        PreSaleActivitiesPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'tabs-content-viewport',
            items: [{ component: 'PreSaleActivitiesTables' }],
        },
        PreSaleActivitiesTables: {
            component: 'Tabs',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<yyqg>>',
                    content: {
                        component: 'ReserveActivitiesPage',
                    },
                },
            ],
        },
        ReserveActivitiesPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'ReserveActivitiesEntity',
            items: [
                { component: 'PreSaleActivitiesFilter', params: {} },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/presale-activities/add-RESERVE',
                                },
                            ],
                        },
                    ],
                },
                { component: 'ReserveActivitiesTable', params: {} },
            ],
        },
        PreSaleActivitiesFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsryhqlbmc>>',
                    },
                },
                {
                    property: 'subsiteIds',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        ReserveActivitiesTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'name',
                            width: 180,
                        },
                        {
                            property: 'startTime',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'endTime',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'subsites',
                            width: 200,
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '250px',
                                },
                                countSuffix: '<<subsites>>',
                            },
                        },
                        {
                            property: 'executeStatus',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                                statusConfig: {
                                    NOTSTARTED: {
                                        text: '<<notStarted>>',
                                        status: 'success',
                                    },
                                    STARTED: {
                                        text: '<<inprogress>>',
                                        status: 'processing',
                                    },
                                    COMPLETE: {
                                        text: '<<end>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: false },
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                                            apiPath: '/admin/reserve_activities/:id/disabled',
                                            config: {
                                                content: '<<hd>>{{row.name}}<<areadyClosed>>',
                                                text: '<<common.close>>',
                                            },
                                            confirm: {
                                                text: '<<isCloseActivity>>{{row.name}}?',
                                            },
                                        },
                                        {
                                            params: { status: true },
                                            value: false,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                                            apiPath: '/admin/reserve_activities/:id/enabled',
                                            config: {
                                                content: '<<hd>>{{row.name}}<<areadyOpend>>',
                                                text: '<<common.opend>>',
                                            },
                                            confirm: {
                                                text: '<<isAreadyOpend>><<hd>>{{row.name}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                text: '<<common.edit>>',
                                type: 'link',
                                path:
                                    '/menu-list/market/presale-activities/edit-RESERVE/{{row.id}}',
                            },
                            {
                                text: '<<common.log>>',
                                type: 'link',
                                path: '/menu-list/market/presale-activities/logs/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        ReserveActivitiesAddPage: {
            component: 'Card',
            content: { component: 'ReserveActivitiesAddForm' },
        },
        ReserveActivitiesAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'ReserveActivitiesEntity',
            labelCol: 4,
            controlCol: 20,
            style: { width: 920 },
            fields: [
                { property: 'activityInfo.name' },
                { property: 'activityInfo.dateRange' },
                { property: 'activityInfo.subsites' },
                {
                    property: 'activityInfo.marketingSchedule',
                },
                { property: 'activityInfo.goods' },
                { property: 'activityInfo.reserveType' },
                { property: 'activityInfo.maxOrderCount' },
                { property: 'activityInfo.description' },
                { property: 'roundInfo.period' },
                {
                    property: 'roundInfo.reserveTime',
                    controlConfig: {
                        format: 'HH:mm',
                        tip: '<<yhkyjxyy>>',
                    },
                },
                {
                    property: 'roundInfo.time',
                    controlConfig: {
                        format: 'HH:mm',
                        tip: '<<yhkyjxgm>>',
                    },
                },
                { property: 'roundInfo.memberLimit' },
                { property: 'roundInfo.quantityLimit' },
                {
                    property: 'seniorInfo.participateCondition',
                },
                {
                    property: 'seniorInfo.receiptCertification',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                //校验预约时间与抢购时间
                const reserveTime = entity.roundInfo.reserveTime[0];
                const time = entity.roundInfo.time[0];
                const reserveTimeStart = new Date(
                    '2021-1-1 ' + reserveTime.start + ':00'
                ).getTime();
                const reserveTimeEnd = new Date('2021-1-1 ' + reserveTime.end + ':00').getTime();
                const timeStart = new Date('2021-1-1 ' + time.start + ':00').getTime();
                if (timeStart - reserveTimeStart <= 0 || timeStart - reserveTimeEnd < 0) {
                    AntMessage.error(services.language.getText('yyjssjyzyhdyqgkssj'));
                    return false;
                }
                //校验预约条件配置输入值
                const participateCondition = entity.seniorInfo.participateCondition;
                if (participateCondition && participateCondition.length > 0) {
                    const minRegisterDays: any = find(participateCondition, {
                        code: 'MINREGISTERDAYS',
                    });
                    const consumption: any = find(participateCondition, { code: 'CONSUMPTIONT' });
                    if (minRegisterDays.checked && !minRegisterDays.value) {
                        AntMessage.error(services.language.getText('qtxyytjxzxddyz'));
                        return false;
                    }
                    if (consumption.checked && !consumption.value) {
                        AntMessage.error(services.language.getText('qtxyytjxzxddyz'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        ReserveActivitiesEditPage: {
            component: 'FlexLayout',
            loaderType: 'get',
            entity: 'ReserveActivitiesEntity',
            direction: 'horizontal',
            items: [{ component: 'ReserveActivitiesEditFormView' }],
        },
        ReserveActivitiesLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                    apiPath: '/admin/reserve_activities/{id}/operation_logs',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/presale-activities',
                    component: 'PreSaleActivitiesView',
                    breadcrumbName: '<<yyqg>>',
                    privilege: {
                        path: 'presaleActivity',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add-RESERVE',
                            component: 'ReserveActivitiesAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit-RESERVE/:id',
                            component: 'ReserveActivitiesEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/logs/:id',
                            component: 'ReserveActivitiesLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        { path: '/', component: 'PreSaleActivitiesPage' },
                    ],
                },
            ],
        },
    ],
};
