import React, { Component } from 'react';
import { Input } from 'antd';
import { get, isEqual, map } from 'lodash';
import { ExternalCoupon, ExternalCouponSelector } from './external-coupon-selector';
import './index.less';
import { services } from '@comall-backend-builder/core';

export interface ExternalCouponChooseProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    value: ExternalCoupon | null;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: ExternalCoupon | null, name: string) => void;
    showChoose?: boolean;
}
export class ExternalCouponChoose extends Component<ExternalCouponChooseProps> {
    componentDidMount() {
        let { value } = this.props;
        if (!value) {
            this.onChange();
        }
    }

    componentWillReceiveProps(nextProps: any) {
        const currentSubsite = get(this.props, 'row.productInfo.subsite');
        const nextSubsite = get(nextProps, 'row.productInfo.subsite');
        if (currentSubsite && !isEqual(currentSubsite, nextSubsite)) {
            this.onChange();
        }
    }

    onChange() {
        const { name, onChange } = this.props;
        const defalutValue: ExternalCoupon = {
            code: '',
            name: '',
        };
        onChange(defalutValue, name);
    }

    changeName = (inputValue: string) => {
        let { name, value, onChange } = this.props;
        if (value) {
            value.name = inputValue;
            if (onChange) {
                onChange(value, name);
            }
        }
    };

    onSelect = (selectValues: ExternalCoupon[]) => {
        const { name, value, onChange } = this.props;
        if (selectValues && value) {
            value.code = selectValues[0].code;
            value.name = selectValues[0].name;
            if (onChange) {
                onChange(value, name);
            }
        }
    };

    getSubsiteId = (props: any) => {
        const { row } = props;
        const subsiteIds =
            row.productInfo && row.productInfo.subsite ? map(row.productInfo.subsite, 'id') : null;
        return subsiteIds;
    };

    render() {
        const { value, showChoose } = this.props;
        const selectValues: ExternalCoupon[] = [];
        if (value) {
            selectValues.push(value);
        }
        const subsiteId = this.getSubsiteId(this.props);
        const params = {
            subsiteId,
        };
        return (
            <div className="external-coupon-choose">
                <div className="external-coupon-choose__item">
                    <span className="external-coupon-choose__item-text">
                        {services.language.getText('qbm')}
                    </span>
                    <Input
                        style={{ width: '300px', margin: '0 10px' }}
                        disabled
                        type="string"
                        value={value ? value.code : ''}
                    />
                    {showChoose && (
                        <ExternalCouponSelector
                            selectValues={selectValues}
                            onChange={this.onSelect}
                            type="radio"
                            params={params}
                        />
                    )}
                </div>
                <div className="external-coupon-choose__item">
                    <span className="external-coupon-choose__item-text">
                        {services.language.getText('qmc')}
                    </span>
                    <Input
                        style={{ width: '300px', margin: '0 10px' }}
                        type="string"
                        value={value ? value.name : ''}
                        onChange={(e) => {
                            this.changeName(e.target.value);
                            return e.target.value;
                        }}
                    />
                </div>
            </div>
        );
    }
}
