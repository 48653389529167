import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import get from 'lodash/get';
import { forEach, isEmpty, findIndex } from 'lodash';
import { TipsModalContentType } from '../../components';

/*
 *可选配送时间状态
 */
export enum TimeRuleSatus {
    /**
     * 启用
     */
    ENABLED = 'ENABLED',
    /**
     * 关闭
     */
    DISABLED = 'DISABLED',
}

export const config: Config = {
    entities: {
        SubsitePickUpDeliveryEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: '/loader/admin/subsite_pick_up_delivery',
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<ztdxx>>',
                    properties: {
                        name: {
                            type: 'string.options.select',
                            displayName: '<<deliveryType>>',
                            rules: [{ required: true }],
                            defaultValue: 'ALL',
                            options: [{ id: 'ALL', name: '<<mdzt>>' }],
                            controlConfig: {
                                disabled: true,
                                style: {
                                    width: 200,
                                },
                            },
                        },
                        subsite: {
                            type: 'string.options.select',
                            displayName: '<<subsites>>',
                            rules: [{ required: true }],
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            controlConfig: {
                                style: {
                                    width: 300,
                                },
                            },
                        },
                        address: {
                            type: 'string.tip',
                            displayName: '<<ztdz>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qtxztdxxdz>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<qtxztdxxdz>>',
                                minLength: 5,
                                maxLength: 50,
                            },
                        },
                        pickUpTime: {
                            type: 'string',
                            displayName: '<<ztsjsm>>',
                            controlConfig: {
                                placeholder: '<<qtxztsjsm>>',
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        reminder: {
                            type: 'string.modalTips',
                            displayName: '<<wxts>>',
                            controlConfig: {
                                placeholder: '<<jytxrqnsdztdxhddzte>>',
                                style: {
                                    width: 450,
                                },
                                tipsModalConfig: {
                                    contentType: TipsModalContentType.DeliveryReminder,
                                    showIcon: false,
                                    text: '<<viewExample>>',
                                    textColor: '#1890ff',
                                    title: '<<example>>',
                                    width: 648,
                                },
                            },
                            extra: '<<reminderExtra>>',
                        },
                    },
                },
                chooseInfo: {
                    type: 'object.subForm',
                    displayName: '<<ztgz>>',
                    properties: {
                        reservedTimeHour: {
                            type: 'number.tip',
                            displayName: '<<bhsj>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<inputThanZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && (!reg.test(value) || value === 0)) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 100,
                                },
                                addonbefore: '<<sjzstq>>',
                                addonafter: '<<xsbh>>',
                                tip: '',
                            },
                        },
                        topLimitDay: {
                            type: 'number.tip',
                            displayName: '<<topLimitDay>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<inputThanZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && (!reg.test(value) || value === 0)) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 100,
                                },
                                addonbefore: '<<zc_2>>',
                                addonafter: '<<tnth>>',
                                tip: '',
                            },
                        },
                        isDeliveryTimePeriods: {
                            type: 'string.options.radio',
                            displayName: '<<mjkxztsj>>',
                            defaultValue: 'NO',
                            options: [
                                { id: 'YES', name: '<<enable>>' },
                                { id: 'NO', name: '<<common.close>>' },
                            ],
                            extra: '<<mjzjsykyxzpssj>>',
                        },
                        deliveryTimePeriods: {
                            type: 'array.presaleTimepicker',
                            displayName: '<<kztsjd>>',
                            className: 'presale-range-timepicker',
                            rules: [
                                {
                                    message: '<<mgsjdbkzf>>',
                                    validator: (rule: any, value: any) => {
                                        let result: Array<boolean> = [];
                                        if (value !== null && !isEmpty(value)) {
                                            // 如果是交叉时间 就提示
                                            forEach(value, (outerItem, outerIndex) => {
                                                forEach(value, (item, index) => {
                                                    if (
                                                        !isEmpty(outerItem) &&
                                                        !isEmpty(item) &&
                                                        outerIndex !== index
                                                    ) {
                                                        let valueStart = new Date(
                                                            '2020-01-01 ' + outerItem.start
                                                        ).getTime();
                                                        let valueEnd = new Date(
                                                            '2020-01-01 ' + outerItem.end
                                                        ).getTime();
                                                        let itemStart = new Date(
                                                            '2020-01-01 ' + item.start
                                                        ).getTime();
                                                        let itemEnd = new Date(
                                                            '2020-01-01 ' + item.end
                                                        ).getTime();

                                                        if (valueStart - itemEnd >= 0) {
                                                            result.push(true);
                                                        } else if (valueEnd - itemStart <= 0) {
                                                            result.push(true);
                                                        } else {
                                                            result.push(false);
                                                        }
                                                    }
                                                });
                                            });
                                        }
                                        if (result.indexOf(false) > -1) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                format: 'HH:mm',
                                tip: '<<qasxtj>>',
                                deleteButtontext: '<<common.delete>>',
                                addButtonText: '<<tjsjd>>',
                            },
                        },
                    },
                },
                ruleInfo: {
                    type: 'object.subForm',
                    displayName: '<<wasthcl>>',
                    properties: {
                        orderHandleMode: {
                            type: 'string.options.radio',
                            displayName: '<<gqwthddsfgb>>',
                            defaultValue: 'NOT_HANDLE',
                            options: [
                                { id: 'PART_HANDLE', name: '<<yes>>' },
                                { id: 'NOT_HANDLE', name: '<<no>>' },
                            ],
                        },
                        activityProductType: {
                            type: 'string.options.radio',
                            displayName: '<<activityProduct>>',
                            defaultValue: 'ALL',
                            options: [
                                { id: 'ALL', name: '<<allProduct>>' },
                                { id: 'INCLUDE_PRODUCT', name: '<<includeProduct>>' },
                                { id: 'EXCLUDE_PRODUCT', name: '<<excludeProduct>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        goods: {
                            type: 'object.productCategory.selector',
                            displayName: '<<szsp>>',
                            controlConfig: {
                                selectProductTypes: ['NORMAL,WEIGHT,COMBINE'],
                                verifyName: 'baseInfo.subsite',
                                verifyTip: '<<selectSubsite>>',
                                handleSelectMode: (row: any) => {
                                    return 'SUB_SITE';
                                },
                                productDisplaySelectValueType: 'goods',
                            },
                        },
                        pickUpLimitDays: {
                            type: 'number.tip',
                            displayName: '<<gqtssz>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 0,
                                    message: '<<qsrdydy>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && !reg.test(value)) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 100,
                                },
                                addonbefore: '<<gq>>',
                                addonafter: '<<thwth>>',
                                tip: '<<glyhzwthsj>>',
                            },
                        },
                    },
                },
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsites>>',
                },
                address: {
                    type: 'string',
                    displayName: '<<ztdz>>',
                },
                pickUpTime: {
                    type: 'string',
                    displayName: '<<ztsj>>',
                },
                reminder: {
                    type: 'string',
                    displayName: '<<wxts>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    defaultValue: 'true',
                    options: [
                        { id: 'true', name: '<<enabled>>' },
                        { id: 'false', name: '<<beforeClose>>' },
                    ],
                },
                status: {
                    type: 'string',
                    displayName: '<<status>>',
                },
            },
        },
        SubsiteCitywideDeliveryEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: '/loader/admin/subsite_citywidh_delivery',
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<psgzmc>>',
                },
                scope: {
                    type: 'string.options.radio',
                    displayName: '<<psfw>>',
                    defaultValue: 'CUSTOM',
                    options: [
                        {
                            id: 'CUSTOM',
                            name: '<<zdypsfw>>',
                        },
                        {
                            id: 'RADIUS',
                            name: '<<fwbj>>',
                        },
                    ],
                },
                baseFreight: {
                    type: 'string',
                    displayName: '<<jcyf>>',
                },
                freightLadderTexts: {
                    type: 'string',
                    displayName: '<<jtj>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    defaultValue: 'true',
                    options: [
                        { id: 'true', name: '<<enabled>>' },
                        { id: 'false', name: '<<beforeClose>>' },
                    ],
                },
                status: {
                    type: 'string',
                    displayName: '<<kqzt>>',
                },
                info: {
                    type: 'object.subForm',
                    displayName: '<<psfw>>',
                    properties: {
                        subsite: {
                            type: 'object.option.select',
                            displayName: '<<otherSelectSubsite>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<selectSubsite>>',
                            },
                            rules: [
                                {
                                    required: true,
                                    message: '<<selectSubsite>>',
                                },
                            ],
                        },
                        name: {
                            type: 'string',
                            displayName: '<<psgzmc>>',
                            controlConfig: {
                                placeholder: '<<qsrpsgzmc>>',
                                style: {
                                    width: 450,
                                },
                            },
                            rules: [
                                {
                                    required: true,
                                    max: 20,
                                    message: '<<qsrpsgzmc>>',
                                },
                            ],
                        },
                        address: {
                            type: 'string',
                            displayName: '<<deliveryAddress>>',
                            defaultValue: '<<qtqszhmddz>>',
                        },
                        deliveryScope: {
                            type: 'array.geofences',
                            displayName: '<<psfw>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },
                    },
                },
                freight: {
                    type: 'object.subForm',
                    displayName: '<<psjg>>',
                    properties: {
                        baseFreight: {
                            type: 'number.tip',
                            displayName: '<<jcyf>>',
                            controlConfig: {
                                addonafter: '<<yuan>>',
                                style: {
                                    width: 100,
                                },
                            },
                            rules: [
                                { required: true },
                                {
                                    message: '<<jzclrdy0dje>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;
                                        if (value && !reg.test(value)) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            ],
                        },
                        isFreightLadders: {
                            type: 'string.options.radio',
                            displayName: '<<qyjtj>>',
                            defaultValue: 'NO',
                            options: [
                                { id: 'YES', name: '<<enable>>' },
                                { id: 'NO', name: '<<common.close>>' },
                            ],
                            extra: '<<shdzzpsfwwdmjbkyxd>>',
                        },
                        firstLadderStartValue: {
                            type: 'object.citywideDeliveryFreightLadder',
                            displayName: '<<sfbz>>',
                            controlConfig: {
                                ladderValueAddbefore: '<<qszl>>',
                                ladderValueAddAfter: 'kg，',
                                ladderAmountAddbefore: '<<qsjl>>',
                                ladderAmountAddAfter: '<<syjcyf>>',
                                ladderValuePrecision: 3,
                                ladderAmountPrecision: 3,
                            },
                        },
                        weightFreightLadder: {
                            type: 'object.citywideDeliveryFreightLadder',
                            displayName: '<<pszlmzj>>',
                            controlConfig: {
                                ladderValueAddbefore: '',
                                ladderValueAddAfter: 'kg，',
                                ladderAmountAddbefore: '<<fyzj>>',
                                ladderAmountAddAfter: '<<yuan>>',
                                ladderValuePrecision: 3,
                                ladderAmountPrecision: 2,
                            },
                        },
                        distanceFreightLadder: {
                            type: 'object.citywideDeliveryFreightLadder',
                            displayName: '<<psjlmzj>>',
                            extra: '<<psjlaqxjljs>>',
                            controlConfig: {
                                ladderValueAddbefore: '',
                                ladderValueAddAfter: 'km，',
                                ladderAmountAddbefore: '<<fyzj>>',
                                ladderAmountAddAfter: '<<yuan>>',
                                ladderValuePrecision: 3,
                                ladderAmountPrecision: 2,
                            },
                        },
                    },
                },
                timeRule: {
                    type: 'object.subForm',
                    displayName: '<<pssj>>',
                    properties: {
                        status: {
                            type: 'string.options.radio',
                            displayName: '<<mjkxpssj>>',
                            defaultValue: TimeRuleSatus.DISABLED,
                            options: [
                                { id: TimeRuleSatus.ENABLED, name: '<<enable>>' },
                                { id: TimeRuleSatus.DISABLED, name: '<<common.close>>' },
                            ],
                        },
                        timePeriods: {
                            type: 'array.presaleTimepicker',
                            displayName: '<<yjsdsjd>>',
                            className: 'presale-range-timepicker',
                            rules: [
                                {
                                    message: '<<mgsjdbkzf>>',
                                    validator: (rule: any, value: any) => {
                                        let result: Array<boolean> = [];
                                        if (value !== null && !isEmpty(value)) {
                                            // 如果是交叉时间 就提示
                                            forEach(value, (outerItem, outerIndex) => {
                                                forEach(value, (item, index) => {
                                                    if (
                                                        !isEmpty(outerItem) &&
                                                        !isEmpty(item) &&
                                                        outerIndex !== index
                                                    ) {
                                                        let valueStart = new Date(
                                                            '2020-01-01 ' + outerItem.start
                                                        ).getTime();
                                                        let valueEnd = new Date(
                                                            '2020-01-01 ' + outerItem.end
                                                        ).getTime();
                                                        let itemStart = new Date(
                                                            '2020-01-01 ' + item.start
                                                        ).getTime();
                                                        let itemEnd = new Date(
                                                            '2020-01-01 ' + item.end
                                                        ).getTime();

                                                        if (valueStart - itemEnd >= 0) {
                                                            result.push(true);
                                                        } else if (valueEnd - itemStart <= 0) {
                                                            result.push(true);
                                                        } else {
                                                            result.push(false);
                                                        }
                                                    }
                                                });
                                            });
                                        }
                                        if (result.indexOf(false) > -1) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                format: 'HH:mm',
                                tip: '<<srsjgsck>>',
                                deleteButtontext: '<<common.delete>>',
                                addButtonText: '<<tjsjd>>',
                                showValue: {
                                    addonbefore: '<<bsdzcpsl>>',
                                    addonafter: '<<dan>>',
                                },
                            },
                            extra: '<<bsddpslbt>>',
                        },
                        reservedTimeUnit: {
                            type: 'string.options.radio',
                            displayName: '<<yyxdsjdw>>',
                            defaultValue: 'HOUR',
                            options: [
                                { id: 'HOUR', name: '<<hour>>' },
                                { id: 'MINUTE', name: '<<minutes>>' },
                            ],
                        },
                        reservedTimeValue: {
                            type: 'number.tip',
                            displayName: '<<yyxd>>',
                            controlConfig: {
                                placeholder: '',
                                style: {
                                    width: 100,
                                },
                                addonbefore: '<<tq>>',
                                addonafter: (row: any) => {
                                    const reservedTimeUnit = get(row, 'timeRule.reservedTimeUnit');
                                    let reservedTimeUnitContent = '';
                                    if (reservedTimeUnit === 'HOUR') {
                                        reservedTimeUnitContent = services.language.getText('hour');
                                    }
                                    if (reservedTimeUnit === 'MINUTE') {
                                        reservedTimeUnitContent = services.language.getText(
                                            'minutes'
                                        );
                                    }
                                    return (
                                        reservedTimeUnitContent + services.language.getText('yy_2')
                                    );
                                },
                            },
                            rules: [
                                {
                                    message: '<<supportGtZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && !reg.test(value)) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            ],
                        },
                        topLimit: {
                            type: 'number.tip',
                            displayName: '<<topLimitDay>>',
                            controlConfig: {
                                placeholder: '',
                                style: {
                                    width: 100,
                                },
                                addonbefore: '<<kyy>>',
                                addonafter: '<<tndd>>',
                            },
                            rules: [
                                {
                                    message: '<<supportGtZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && !reg.test(value)) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            ],
                        },
                    },
                },
            },
        },
        SubsiteExpressDeliveryEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: '/loader/admin/subsite_express_delivery',
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 300 },
                    },
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseInfo>>',
                    properties: {
                        name: {
                            type: 'string.tip',
                            displayName: '<<gzmc>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qtxgzmc>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<hbdqm99yby>>',
                                maxLength: 20,
                            },
                        },
                        subsite: {
                            type: 'object.option.select',
                            displayName: '<<sxmd_1>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<qxzsxmd>>',
                            },
                            rules: [
                                {
                                    required: true,
                                    message: '<<qxzsxmd>>',
                                },
                            ],
                        },
                    },
                },
                freightInfo: {
                    type: 'object.subForm',
                    displayName: '<<yfgz>>',
                    properties: {
                        isWeighTharging: {
                            type: 'string.options.radio',
                            displayName: '<<jffs>>',
                            defaultValue: 'YES',
                            options: [
                                { id: 'YES', name: '<<zlcyjf>>' },
                                { id: 'NO', name: '<<zlbcyjf>>' },
                            ],
                        },
                        isWeighThargingFreightRule: {
                            type: 'object.delivery.rules',
                            displayName: '<<psgz>>',
                            defaultValue: {
                                deliveryScopes: [],
                                deliveryScopesInfo: [],
                                showDeliveryScopes: [],
                                freightRanges: [
                                    {
                                        startAmount: null,
                                        endAmount: null,
                                        firstAmount: null,
                                        freightLadders: [
                                            {
                                                startValue: null,
                                                ladderValue: null,
                                                ladderAmount: null,
                                                type: 'WEIGHT',
                                            },
                                        ],
                                    },
                                ],
                            },
                            controlConfig: {
                                pageType: 'subsite',
                                isWeighTharging: true,
                            },
                        },
                        noWeighThargingFreightRule: {
                            type: 'object.delivery.rules',
                            displayName: '<<psgz>>',
                            defaultValue: {
                                deliveryScopes: [],
                                deliveryScopesInfo: [],
                                showDeliveryScopes: [],
                                freightRanges: [
                                    {
                                        startAmount: null,
                                        endAmount: null,
                                        firstAmount: null,
                                        freightLadders: [
                                            {
                                                startValue: null,
                                                ladderValue: null,
                                                ladderAmount: null,
                                                type: 'WEIGHT',
                                            },
                                        ],
                                    },
                                ],
                            },
                            controlConfig: {
                                pageType: 'subsite',
                                isWeighTharging: false,
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        SubsiteDeliveryView: {
            component: 'Viewport',
        },
        SubsiteDeliveryPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            style: {
                padding: '12px 32px 24px 24px',
            },
            items: [{ component: 'SubsiteDeliveryPageTables' }],
        },
        SubsiteDeliveryPageTables: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<zt>>',
                    content: {
                        component: 'SubsitePickUpDeliveryPage',
                    },
                },
                {
                    title: '<<tcp>>',
                    content: {
                        component: 'SubsiteCitywideDeliveryPage',
                    },
                },
                {
                    title: '<<kd_2>>',
                    content: {
                        component: 'SubsiteExpressDeliveryPage',
                    },
                },
            ],
        },
        SubsitePickUpDeliveryPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'SubsitePickUpDeliveryEntity',
            items: [
                {
                    component: 'SubsitePickUpDeliveryFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzmdztpz_1>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/subsite-delivery/add-pick-up',
                                },
                            ],
                        },
                    ],
                },
                { component: 'SubsitePickUpDeliveryTable' },
            ],
        },
        SubsitePickUpDeliveryFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                },
            ],
        },
        SubsitePickUpDeliveryTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'subsiteName',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '200px',
                        },
                    },
                },
                {
                    property: 'address',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '200px',
                        },
                    },
                },
                {
                    property: 'pickUpTime',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '200px',
                        },
                    },
                },
                {
                    property: 'reminder',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '200px',
                        },
                    },
                },
                {
                    property: 'executeStatus',
                    width: 100,
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                    apiPath: '/admin/pick_up_deliveries/:id/disabled',
                                    config: {
                                        content: '<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                    apiPath: '/admin/pick_up_deliveries/:id/enabled',
                                    config: {
                                        content: '<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/subsite-delivery/edit-pick-up/{{row.id}}',
                    },
                ],
            },
        },
        SubsitePickUpDeliveryAddPage: {
            component: 'Card',
            content: { component: 'SubsitePickUpDeliveryAddForm' },
        },
        SubsitePickUpDeliveryAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'SubsitePickUpDeliveryEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.subsite' },
                { property: 'baseInfo.address' },
                { property: 'baseInfo.pickUpTime' },
                { property: 'baseInfo.reminder' },
                { property: 'chooseInfo.reservedTimeHour' },
                { property: 'chooseInfo.topLimitDay' },
                { property: 'chooseInfo.isDeliveryTimePeriods' },
                {
                    property: 'chooseInfo.deliveryTimePeriods',
                    visible: (values: any) =>
                        get(values, 'chooseInfo.isDeliveryTimePeriods') === 'YES',
                },
                { property: 'ruleInfo.orderHandleMode' },
                {
                    property: 'ruleInfo.activityProductType',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.orderHandleMode') === 'PART_HANDLE',
                },
                {
                    property: 'ruleInfo.goods',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.orderHandleMode') === 'PART_HANDLE',
                },
                {
                    property: 'ruleInfo.pickUpLimitDays',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.orderHandleMode') === 'PART_HANDLE',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                let flag = true;
                if (entity.chooseInfo.isDeliveryTimePeriods === 'YES') {
                    if (
                        entity.chooseInfo.deliveryTimePeriods === undefined ||
                        entity.chooseInfo.deliveryTimePeriods === null
                    ) {
                        AntMessage.error(services.language.getText('qpzztsj'));
                        return false;
                    }
                    if (entity.chooseInfo.deliveryTimePeriods.length > 0) {
                        entity.chooseInfo.deliveryTimePeriods.forEach((item: any) => {
                            if (!item.start || !item.end) {
                                flag = false;
                            }
                        });
                    }
                    if (!entity.chooseInfo.topLimitDay) {
                        AntMessage.error(services.language.getText('qtxyysx'));
                        return false;
                    }
                }
                if (!flag) {
                    AntMessage.error(services.language.getText('qpzwzdztsj'));
                    return false;
                }
                const orderHandleMode = entity.ruleInfo.orderHandleMode;
                if (orderHandleMode === 'PART_HANDLE') {
                    if (
                        entity.ruleInfo.activityProductType !== 'ALL' &&
                        (!entity.ruleInfo.goods || entity.ruleInfo.goods.length === 0)
                    ) {
                        AntMessage.error(
                            services.language.getText('components.Tools.productSelectRequired')
                        );
                        return false;
                    }
                    if (!entity.ruleInfo.pickUpLimitDays && entity.ruleInfo.pickUpLimitDays !== 0) {
                        AntMessage.error(services.language.getText('qpzgqts'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        SubsitePickUpDeliveryEditPage: {
            component: 'Card',
            content: { component: 'SubsitePickUpDeliveryEditForm' },
        },
        SubsitePickUpDeliveryEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'SubsitePickUpDeliveryEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                {
                    property: 'baseInfo.subsite',
                    controlConfig: {
                        disabled: true,
                        style: {
                            width: 300,
                        },
                    },
                },
                { property: 'baseInfo.address' },
                { property: 'baseInfo.pickUpTime' },
                { property: 'baseInfo.reminder' },
                { property: 'chooseInfo.reservedTimeHour' },
                { property: 'chooseInfo.topLimitDay' },
                { property: 'chooseInfo.isDeliveryTimePeriods' },
                {
                    property: 'chooseInfo.deliveryTimePeriods',
                    visible: (values: any) =>
                        get(values, 'chooseInfo.isDeliveryTimePeriods') === 'YES',
                },
                { property: 'ruleInfo.orderHandleMode' },
                {
                    property: 'ruleInfo.activityProductType',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.orderHandleMode') === 'PART_HANDLE',
                },
                {
                    property: 'ruleInfo.goods',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.orderHandleMode') === 'PART_HANDLE',
                },
                {
                    property: 'ruleInfo.pickUpLimitDays',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.orderHandleMode') === 'PART_HANDLE',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                let flag = true;
                if (entity.chooseInfo.isDeliveryTimePeriods === 'YES') {
                    if (
                        entity.chooseInfo.deliveryTimePeriods === undefined ||
                        entity.chooseInfo.deliveryTimePeriods === null
                    ) {
                        AntMessage.error(services.language.getText('qpzztsj'));
                        return false;
                    }
                    if (entity.chooseInfo.deliveryTimePeriods.length > 0) {
                        entity.chooseInfo.deliveryTimePeriods.forEach((item: any) => {
                            if (!item.start || !item.end) {
                                flag = false;
                            }
                        });
                    }
                    if (!entity.chooseInfo.topLimitDay) {
                        AntMessage.error(services.language.getText('qtxyysx'));
                        return false;
                    }
                }
                if (!flag) {
                    AntMessage.error(services.language.getText('qpzwzdztsj'));
                    return false;
                }
                const orderHandleMode = entity.ruleInfo.orderHandleMode;
                if (orderHandleMode === 'PART_HANDLE') {
                    if (
                        entity.ruleInfo.activityProductType !== 'ALL' &&
                        (!entity.ruleInfo.goods || entity.ruleInfo.goods.length === 0)
                    ) {
                        AntMessage.error(
                            services.language.getText('components.Tools.productSelectRequired')
                        );
                        return false;
                    }
                    if (!entity.ruleInfo.pickUpLimitDays && entity.ruleInfo.pickUpLimitDays !== 0) {
                        AntMessage.error(services.language.getText('qpzgqts'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },

        SubsiteCitywideDeliveryPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'SubsiteCitywideDeliveryEntity',
            items: [
                {
                    component: 'SubsiteCitywideDeliveryFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzpsgz>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/subsite-delivery/add-citywide',
                                },
                            ],
                        },
                    ],
                },
                { component: 'SubsiteCitywideDeliveryTable' },
            ],
        },
        SubsiteCitywideDeliveryFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
                style: {
                    marginLeft: 16,
                },
            },
            fields: [
                {
                    property: 'subsiteId',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        SubsiteCitywideDeliveryTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'CitywideDeliveryDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: {
                        x: 1100,
                    },
                    columns: [
                        {
                            property: 'subsiteName',
                            width: 130,
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '130px',
                                },
                            },
                        },
                        {
                            property: 'name',
                            width: 150,
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '150px',
                                },
                            },
                        },
                        {
                            property: 'scope',
                            width: 150,
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '150px',
                                },
                            },
                        },
                        {
                            property: 'baseFreight',
                            width: 100,
                        },
                        {
                            property: 'freightLadderTexts',
                            width: 350,
                            displayConfig: {
                                style: {
                                    maxWidth: '400px',
                                },
                            },
                        },
                        {
                            property: 'executeStatus',
                            width: 150,
                        },
                    ],
                    actionColumn: {
                        key: 'operation',
                        className: 'action-column',
                        fixed: 'right',
                        title: '<<common.tableAction>>',
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: false },
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                            apiPath: '/admin/citywide_deliveries/:id/disabled',
                                            config: {
                                                content: '<<areadyClosed>>',
                                                text: '<<common.close>>',
                                            },
                                            confirm: {
                                                text: '<<isAreadyClose>>?',
                                            },
                                        },
                                        {
                                            params: { status: true },
                                            value: false,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                            apiPath: '/admin/citywide_deliveries/:id/enabled',
                                            config: {
                                                content: '<<areadyOpend>>',
                                                text: '<<common.opend>>',
                                            },
                                            confirm: {
                                                text: '<<isAreadyOpend>>?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                path: '/subsite-delivery/edit-citywide/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        SubsiteCitywideDeliveryAddPage: {
            component: 'Card',
            content: { component: 'SubsiteCitywideDeliveryAddForm' },
        },
        SubsiteCitywideDeliveryAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'SubsiteCitywideDeliveryEntity',
            labelCol: 3,
            controlCol: 21,
            fields: [
                { property: 'info.subsite' },
                { property: 'info.name' },
                { property: 'info.address', modifiable: false },
                { property: 'info.deliveryScope' },
                {
                    property: 'freight.baseFreight',
                    extra: '<<sjwszjtjs>>',
                },
                {
                    property: 'freight.isFreightLadders',
                },
                {
                    property: 'freight.firstLadderStartValue',
                    visible: (values: any) => get(values, 'freight.isFreightLadders') === 'YES',
                },
                {
                    property: 'freight.weightFreightLadder',
                    visible: (values: any) => get(values, 'freight.isFreightLadders') === 'YES',
                },
                {
                    property: 'freight.distanceFreightLadder',
                    visible: (values: any) => get(values, 'freight.isFreightLadders') === 'YES',
                },
                {
                    property: 'timeRule.status',
                    extra: '<<xyxzsdsj>>',
                },
                {
                    property: 'timeRule.timePeriods',
                    visible: (values: any) =>
                        get(values, 'timeRule.status') === TimeRuleSatus.ENABLED,
                },
                {
                    property: 'timeRule.reservedTimeUnit',
                    visible: (values: any) =>
                        get(values, 'timeRule.status') === TimeRuleSatus.ENABLED,
                },
                {
                    property: 'timeRule.reservedTimeValue',
                    extra: '<<mjjkxzxdsj>>',
                    visible: (values: any) =>
                        get(values, 'timeRule.status') === TimeRuleSatus.ENABLED,
                },
                {
                    property: 'timeRule.topLimit',
                    visible: (values: any) =>
                        get(values, 'timeRule.status') === TimeRuleSatus.ENABLED,
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entityFields: any) => {
                let deliveryScopeFlag = true;
                const deliveryScope = get(entityFields, 'info.deliveryScope', null);
                if (
                    deliveryScope &&
                    deliveryScope.geofences &&
                    deliveryScope.geofences.length > 0
                ) {
                    const geofenceNames: Array<string> = [];
                    deliveryScope.geofences.forEach((geofence: any) => {
                        const hasIndex = findIndex(geofenceNames, (o: any) => o === geofence.name);
                        if (hasIndex === -1) {
                            geofenceNames.push(geofence.name);
                        } else {
                            deliveryScopeFlag = false;
                        }
                    });
                }
                if (!deliveryScopeFlag) {
                    AntMessage.warning(services.language.getText('psfwzdymcbnzf'));
                    return false;
                }
                const isFreightLadders = get(entityFields, 'freight.isFreightLadders', null);
                if (isFreightLadders === 'YES') {
                    const firstLadderStartValue = get(
                        entityFields,
                        'freight.firstLadderStartValue',
                        null
                    );
                    if (
                        !firstLadderStartValue ||
                        !firstLadderStartValue.ladderValue ||
                        !firstLadderStartValue.ladderAmount ||
                        firstLadderStartValue.ladderValue === 0 ||
                        firstLadderStartValue.ladderAmount === 0
                    ) {
                        AntMessage.warning(services.language.getText('qtxsfbz'));
                        return false;
                    }
                    const weightFreightLadder = get(
                        entityFields,
                        'freight.weightFreightLadder',
                        null
                    );
                    if (
                        !weightFreightLadder ||
                        !weightFreightLadder.ladderValue ||
                        !weightFreightLadder.ladderAmount ||
                        weightFreightLadder.ladderValue === 0 ||
                        weightFreightLadder.ladderAmount === 0
                    ) {
                        AntMessage.warning(services.language.getText('qtxpszljtj'));
                        return false;
                    }
                    const distanceFreightLadder = get(
                        entityFields,
                        'freight.distanceFreightLadder',
                        null
                    );
                    if (
                        !distanceFreightLadder ||
                        !distanceFreightLadder.ladderValue ||
                        !distanceFreightLadder.ladderAmount ||
                        distanceFreightLadder.ladderValue === 0 ||
                        distanceFreightLadder.ladderAmount === 0
                    ) {
                        AntMessage.warning(services.language.getText('qtxpsjljtj'));
                        return false;
                    }
                }
                const timeRuleStatus = get(entityFields, 'timeRule.status', null);
                if (timeRuleStatus === 'ENABLED') {
                    if (
                        entityFields.timeRule.timePeriods === undefined ||
                        entityFields.timeRule.timePeriods === null
                    ) {
                        AntMessage.error(services.language.getText('qpzyjsdsjd'));
                        return false;
                    }
                    if (
                        entityFields.timeRule.reservedTimeValue === undefined ||
                        entityFields.timeRule.reservedTimeValue === null
                    ) {
                        AntMessage.error(services.language.getText('qtxyyxd'));
                        return false;
                    }
                    if (
                        entityFields.timeRule.topLimit === undefined ||
                        entityFields.timeRule.topLimit === null
                    ) {
                        AntMessage.error(services.language.getText('qsryysx'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        SubsiteCitywideDeliveryEditPage: {
            component: 'Card',
            content: { component: 'SubsiteCitywideDeliveryEditForm' },
        },
        SubsiteCitywideDeliveryEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'SubsiteCitywideDeliveryEntity',
            loaderType: 'get',
            labelCol: 3,
            controlCol: 21,
            fields: [
                { property: 'info.subsite', modifiable: false },
                { property: 'info.name' },
                { property: 'info.address', modifiable: false },
                { property: 'info.deliveryScope' },
                {
                    property: 'freight.baseFreight',
                    extra: '<<sjwszjtjs>>',
                },
                {
                    property: 'freight.isFreightLadders',
                },
                {
                    property: 'freight.firstLadderStartValue',
                    visible: (values: any) => get(values, 'freight.isFreightLadders') === 'YES',
                },
                {
                    property: 'freight.weightFreightLadder',
                    visible: (values: any) => get(values, 'freight.isFreightLadders') === 'YES',
                },
                {
                    property: 'freight.distanceFreightLadder',
                    visible: (values: any) => get(values, 'freight.isFreightLadders') === 'YES',
                },
                {
                    property: 'timeRule.status',
                    extra: '<<xyxzsdsj>>',
                },
                {
                    property: 'timeRule.timePeriods',
                    visible: (values: any) =>
                        get(values, 'timeRule.status') === TimeRuleSatus.ENABLED,
                },
                {
                    property: 'timeRule.reservedTimeUnit',
                    visible: (values: any) =>
                        get(values, 'timeRule.status') === TimeRuleSatus.ENABLED,
                },
                {
                    property: 'timeRule.reservedTimeValue',
                    extra: '<<mjjkxzxdsj>>',
                    visible: (values: any) =>
                        get(values, 'timeRule.status') === TimeRuleSatus.ENABLED,
                },
                {
                    property: 'timeRule.topLimit',
                    visible: (values: any) =>
                        get(values, 'timeRule.status') === TimeRuleSatus.ENABLED,
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entityFields: any) => {
                let deliveryScopeFlag = true;
                const deliveryScope = get(entityFields, 'info.deliveryScope', null);
                if (
                    deliveryScope &&
                    deliveryScope.geofences &&
                    deliveryScope.geofences.length > 0
                ) {
                    const geofenceNames: Array<string> = [];
                    deliveryScope.geofences.forEach((geofence: any) => {
                        const hasIndex = findIndex(geofenceNames, (o: any) => o === geofence.name);
                        if (hasIndex === -1) {
                            geofenceNames.push(geofence.name);
                        } else {
                            deliveryScopeFlag = false;
                        }
                    });
                }
                if (!deliveryScopeFlag) {
                    AntMessage.warning(services.language.getText('psfwzdymcbnzf'));
                    return false;
                }
                const isFreightLadders = get(entityFields, 'freight.isFreightLadders', null);
                if (isFreightLadders === 'YES') {
                    const firstLadderStartValue = get(
                        entityFields,
                        'freight.firstLadderStartValue',
                        null
                    );
                    if (
                        !firstLadderStartValue ||
                        !firstLadderStartValue.ladderValue ||
                        !firstLadderStartValue.ladderAmount ||
                        firstLadderStartValue.ladderValue === 0 ||
                        firstLadderStartValue.ladderAmount === 0
                    ) {
                        AntMessage.warning(services.language.getText('qtxsfbz'));
                        return false;
                    }
                    const weightFreightLadder = get(
                        entityFields,
                        'freight.weightFreightLadder',
                        null
                    );
                    if (
                        !weightFreightLadder ||
                        !weightFreightLadder.ladderValue ||
                        !weightFreightLadder.ladderAmount ||
                        weightFreightLadder.ladderValue === 0 ||
                        weightFreightLadder.ladderAmount === 0
                    ) {
                        AntMessage.warning(services.language.getText('qtxpszljtj'));
                        return false;
                    }
                    const distanceFreightLadder = get(
                        entityFields,
                        'freight.distanceFreightLadder',
                        null
                    );
                    if (
                        !distanceFreightLadder ||
                        !distanceFreightLadder.ladderValue ||
                        !distanceFreightLadder.ladderAmount ||
                        distanceFreightLadder.ladderValue === 0 ||
                        distanceFreightLadder.ladderAmount === 0
                    ) {
                        AntMessage.warning(services.language.getText('qtxpsjljtj'));
                        return false;
                    }
                }
                const timeRuleStatus = get(entityFields, 'timeRule.status', null);
                if (timeRuleStatus === 'ENABLED') {
                    if (
                        entityFields.timeRule.timePeriods === undefined ||
                        entityFields.timeRule.timePeriods === null
                    ) {
                        AntMessage.error(services.language.getText('qpzyjsdsjd'));
                        return false;
                    }
                    if (
                        entityFields.timeRule.reservedTimeValue === undefined ||
                        entityFields.timeRule.reservedTimeValue === null
                    ) {
                        AntMessage.error(services.language.getText('qtxyyxd'));
                        return false;
                    }
                    if (
                        entityFields.timeRule.topLimit === undefined ||
                        entityFields.timeRule.topLimit === null
                    ) {
                        AntMessage.error(services.language.getText('qsryysx'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        SubsiteExpressDeliveryPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'SubsiteExpressDeliveryEntity',
            items: [
                { component: 'SubsiteExpressDeliveryPageFilter' },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzyfgz>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/subsite-delivery/add-express',
                                },
                            ],
                        },
                    ],
                },
                { component: 'SubsiteExpressDeliveryPageTable' },
            ],
        },
        SubsiteExpressDeliveryPageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        SubsiteExpressDeliveryPageTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataList',
                    pageType: 'subsite',
                    renderItem: 'DeliveryExpressItem',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                },
            ],
        },
        SubsiteExpressDeliveryAddPage: {
            component: 'Card',
            style: { padding: 0 },
            content: { component: 'SubsiteExpressDeliveryAddForm' },
        },
        SubsiteExpressDeliveryAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'SubsiteExpressDeliveryEntity',
            labelCol: 2,
            controlCol: 22,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.subsite' },
                { property: 'freightInfo.isWeighTharging' },
                {
                    property: 'freightInfo.isWeighThargingFreightRule',
                    visible: (values: any) => get(values, 'freightInfo.isWeighTharging') === 'YES',
                },
                {
                    property: 'freightInfo.noWeighThargingFreightRule',
                    visible: (values: any) => get(values, 'freightInfo.isWeighTharging') === 'NO',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const isWeighTharging = get(entity, 'freightInfo.isWeighTharging', null);
                if (isWeighTharging === 'YES') {
                    const isWeighThargingFreightRule = get(
                        entity,
                        'freightInfo.isWeighThargingFreightRule',
                        null
                    );
                    //2.校验地区是否已选择
                    if (
                        !isWeighThargingFreightRule.deliveryScopes ||
                        isWeighThargingFreightRule.deliveryScopes.length === 0
                    ) {
                        AntMessage.error(services.language.getText('nbxyxzygkpsqy'));
                        return false;
                    }
                    //3.校验输入框是否已全部填写完毕
                    let freightRangeFlag = true;
                    forEach(isWeighThargingFreightRule.freightRanges, (freightRange: any) => {
                        if (
                            freightRange.startAmount === null ||
                            freightRange.endAmount === null ||
                            freightRange.firstAmount === null ||
                            freightRange.freightLadders[0].startValue === null ||
                            freightRange.freightLadders[0].ladderAmount === null ||
                            freightRange.freightLadders[0].ladderValue === null
                        ) {
                            freightRangeFlag = false;
                            return false;
                        }
                    });
                    if (!freightRangeFlag) {
                        AntMessage.warning(services.language.getText('qtxkpsqydydyfz'));
                        return false;
                    }
                }
                if (isWeighTharging === 'NO') {
                    const noWeighThargingFreightRule = get(
                        entity,
                        'freightInfo.noWeighThargingFreightRule',
                        null
                    );
                    //2.校验地区是否已选择
                    if (
                        !noWeighThargingFreightRule.deliveryScopes ||
                        noWeighThargingFreightRule.deliveryScopes.length === 0
                    ) {
                        AntMessage.error(services.language.getText('nbxyxzygkpsqy'));
                        return false;
                    }
                    //3.校验输入框是否已全部填写完毕
                    let freightRangeFlag = true;
                    forEach(noWeighThargingFreightRule.freightRanges, (freightRange: any) => {
                        if (
                            freightRange.startAmount === null ||
                            freightRange.endAmount === null ||
                            freightRange.firstAmount === null
                        ) {
                            freightRangeFlag = false;
                            return false;
                        }
                    });
                    if (!freightRangeFlag) {
                        AntMessage.warning(services.language.getText('qtxkpsqydydyfz'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        SubsiteExpressDeliveryEditPage: {
            component: 'Card',
            style: { padding: 0 },
            content: { component: 'SubsiteExpressDeliveryEditForm' },
        },
        SubsiteExpressDeliveryEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'SubsiteExpressDeliveryEntity',
            loaderType: 'get',
            labelCol: 2,
            controlCol: 22,
            fields: [
                { property: 'baseInfo.name' },
                {
                    property: 'baseInfo.subsite',
                    modifiable: false,
                },
                { property: 'freightInfo.isWeighTharging' },
                {
                    property: 'freightInfo.isWeighThargingFreightRule',
                    visible: (values: any) => get(values, 'freightInfo.isWeighTharging') === 'YES',
                },
                {
                    property: 'freightInfo.noWeighThargingFreightRule',
                    visible: (values: any) => get(values, 'freightInfo.isWeighTharging') === 'NO',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const isWeighTharging = get(entity, 'freightInfo.isWeighTharging', null);
                if (isWeighTharging === 'YES') {
                    const isWeighThargingFreightRule = get(
                        entity,
                        'freightInfo.isWeighThargingFreightRule',
                        null
                    );
                    //1.校验地区是否已选择
                    if (
                        isWeighThargingFreightRule.showDeliveryScopes &&
                        isWeighThargingFreightRule.showDeliveryScopes.length > 0
                    ) {
                        let length = 0;
                        forEach(isWeighThargingFreightRule.showDeliveryScopes, (parentRegion) => {
                            if (parentRegion.selectStatus === 'ALL') {
                                length += 1;
                            }
                            if (parentRegion.selectStatus === 'PART') {
                                length += 1;
                            }
                        });
                        if (length === 0) {
                            AntMessage.error(services.language.getText('nbxyxzygkpsqy'));
                            return false;
                        }
                    }
                    //2.校验输入框是否已全部填写完毕
                    let freightRangeFlag = true;
                    forEach(isWeighThargingFreightRule.freightRanges, (freightRange: any) => {
                        if (
                            freightRange.startAmount === null ||
                            freightRange.endAmount === null ||
                            freightRange.firstAmount === null ||
                            freightRange.freightLadders[0].startValue === null ||
                            freightRange.freightLadders[0].ladderAmount === null ||
                            freightRange.freightLadders[0].ladderValue === null
                        ) {
                            freightRangeFlag = false;
                            return false;
                        }
                    });
                    if (!freightRangeFlag) {
                        AntMessage.warning(services.language.getText('qtxkpsqydydyfz'));
                        return false;
                    }
                }
                if (isWeighTharging === 'NO') {
                    const noWeighThargingFreightRule = get(
                        entity,
                        'freightInfo.noWeighThargingFreightRule',
                        null
                    );
                    //1.校验地区是否已选择
                    if (
                        noWeighThargingFreightRule.showDeliveryScopes &&
                        noWeighThargingFreightRule.showDeliveryScopes.length > 0
                    ) {
                        let length = 0;
                        forEach(noWeighThargingFreightRule.showDeliveryScopes, (parentRegion) => {
                            if (parentRegion.selectStatus === 'ALL') {
                                length += 1;
                            }
                            if (parentRegion.selectStatus === 'PART') {
                                length += 1;
                            }
                        });
                        if (length === 0) {
                            AntMessage.error(services.language.getText('nbxyxzygkpsqy'));
                            return false;
                        }
                    }
                    //2.校验输入框是否已全部填写完毕
                    let freightRangeFlag = true;
                    forEach(noWeighThargingFreightRule.freightRanges, (freightRange: any) => {
                        if (
                            freightRange.startAmount === null ||
                            freightRange.endAmount === null ||
                            freightRange.firstAmount === null
                        ) {
                            freightRangeFlag = false;
                            return false;
                        }
                    });
                    if (!freightRangeFlag) {
                        AntMessage.warning(services.language.getText('qtxkpsqydydyfz'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/subsite-delivery',
                    component: 'SubsiteDeliveryView',
                    breadcrumbName: '<<subsiteDelivery>>',
                    privilege: {
                        path: 'subsiteDeliveryFreightRule',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add-pick-up',
                            component: 'SubsitePickUpDeliveryAddPage',
                            breadcrumbName: '<<xzztd>>',
                        },
                        {
                            path: '/edit-pick-up/:id',
                            component: 'SubsitePickUpDeliveryEditPage',
                            breadcrumbName: '<<bjztd>>',
                        },
                        {
                            path: '/add-citywide',
                            component: 'SubsiteCitywideDeliveryAddPage',
                            breadcrumbName: '<<xzpsgz>>',
                        },
                        {
                            path: '/edit-citywide/:id',
                            component: 'SubsiteCitywideDeliveryEditPage',
                            breadcrumbName: '<<bjpsgz>>',
                        },
                        {
                            path: '/add-express',
                            component: 'SubsiteExpressDeliveryAddPage',
                            breadcrumbName: '<<xzyfgz>>',
                        },
                        {
                            path: '/edit-express/:id',
                            component: 'SubsiteExpressDeliveryEditPage',
                            breadcrumbName: '<<bjyfgz>>',
                        },
                        { path: '/', component: 'SubsiteDeliveryPage' },
                    ],
                },
            ],
        },
    ],
};
