export const config = {
    entities: {
        cardLevel: {
            apiPath: '/loader/admin/cardLevels',
            properties: {
                id: { type: 'string' },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseInfo>>',
                    properties: {
                        code: {
                            type: 'string',
                            displayName: '等级编码',
                            rules: [{ required: true }],
                        },
                        level: {
                            type: 'number.integer',
                            displayName: '等级排序',
                            rules: [
                                { required: true },
                                { type: 'number', min: 0, message: '等级排序不能小于0' },
                            ],
                        },
                        name: {
                            type: 'string',
                            displayName: '<<hykmc>>',
                            rules: [
                                { required: true },
                                { max: 15, message: '会员名称不能超过15个字' },
                            ],
                        },
                        iconPicture: {
                            displayName: '等级图标',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                className: 'sigle-image-field',
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                                limitTips: {
                                    limitText:
                                        '建议图片宽度为600px，高度不超过380px；大小不超过1M；',
                                    className: 'image-picker-tips-ext',
                                },
                            },
                            rules: [{ required: true }],
                        },
                        cardCoverPicture: {
                            displayName: '会员卡卡面',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                className: 'sigle-image-field',
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                                limitTips: {
                                    limitText:
                                        '建议图片宽度为136px，高度不超过62px；大小不超过1M；',
                                    className: 'image-picker-tips-ext',
                                },
                            },
                            rules: [{ required: true }],
                        },
                        cardDiscountTag: {
                            type: 'object.memberCardDiscountTag',
                            displayName: '会员折扣标签',
                        },

                        status: {
                            type: 'string.statusBadge',
                            displayName: '<<status>>',
                            defaultValue: 'DISABLE',
                            options: [
                                { id: 'ENABLE', name: '<<enable>>' },
                                { id: 'DISABLE', name: '<<disabled>>' },
                            ],
                            rules: [{ required: true }],
                        },
                    },
                },
                rightInfo: {
                    type: 'object.subForm',
                    displayName: '会员权益',
                    properties: {
                        rights: {
                            type: 'array.cardLevelRights',
                            displayName: '权益项',
                        },
                        description: {
                            type: 'string.richTextPlus',
                            displayName: '权益详情',
                            rules: [{ max: 20000, message: '<<notMoreThan2000>>' }],
                            controlConfig: {
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                        },
                    },
                },
            },
        },
        storeCardLevel: {
            apiPath: '/loader/admin/storeCardLevels',
            properties: {
                id: { type: 'string' },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseInfo>>',
                    properties: {
                        code: {
                            type: 'string',
                            displayName: '等级编码',
                            rules: [{ required: true }],
                        },
                        level: {
                            type: 'number.integer',
                            displayName: '等级排序',
                            rules: [
                                { required: true },
                                { type: 'number', min: 0, message: '等级排序不能小于0' },
                            ],
                        },
                        name: {
                            type: 'string',
                            displayName: '会员名称',
                            rules: [
                                { required: true },
                                { max: 15, message: '会员名称不能超过15个字' },
                            ],
                        },
                        iconPicture: {
                            displayName: '等级图标',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                className: 'sigle-image-field',
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                                limitTips: {
                                    limitText:
                                        '建议图片宽度为600px，高度不超过380px；大小不超过1M；',
                                    className: 'image-picker-tips-ext',
                                },
                            },
                            rules: [{ required: true }],
                        },
                        cardCoverPicture: {
                            displayName: '会员卡卡面',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                className: 'sigle-image-field',
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                                limitTips: {
                                    limitText:
                                        '建议图片宽度为136px，高度不超过62px；大小不超过1M；',
                                    className: 'image-picker-tips-ext',
                                },
                            },
                            rules: [{ required: true }],
                        },

                        status: {
                            type: 'string.statusBadge',
                            displayName: '<<status>>',
                            defaultValue: 'ENABLE',
                            options: [
                                { id: 'ENABLE', name: '<<enable>>' },
                                { id: 'DISABLE', name: '<<disabled>>' },
                            ],
                            rules: [{ required: true }],
                        },
                    },
                },
                rightInfo: {
                    type: 'object.subForm',
                    displayName: '会员权益',
                    properties: {
                        rights: {
                            type: 'array.cardLevelRights',
                            displayName: '权益项',
                        },
                        description: {
                            type: 'string.richTextPlus',
                            displayName: '权益详情',
                            rules: [{ max: 20000, message: '<<notMoreThan2000>>' }],
                            controlConfig: {
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    fileName: 'fileName',
                                    apiPath: `/admin/images/upload`,
                                },
                            },
                        },
                    },
                },
            },
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
            },
        },
        cardLevelRight: {
            apiPath: '',
            properties: {
                id: { type: 'string' },
                name: {
                    type: 'string',
                    displayName: '权益名称',
                    rules: [{ required: true }, { max: 6, message: '不能超过6个字' }],
                },
                iconPicture: {
                    displayName: '权益图标',
                    type: 'imageObjectArray',
                    className: 'sigle-image-field',
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        className: 'sigle-image-field',
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                        limitTips: {
                            limitText: '建议图片宽度为600px，高度不超过380px；大小不超过1M；',
                            className: 'image-picker-tips-ext',
                        },
                    },
                    rules: [{ required: true }],
                },
                context: {
                    type: 'string.richTextPlus',
                    displayName: '权益详情',
                    rules: [
                        {
                            max: 20000,
                            message: '<<notMoreThan2000>>',
                        },
                    ],
                    controlConfig: {
                        toolbarId: 'contextRichText',
                        style: {
                            height: 300,
                        },
                        uploadConfig: {
                            apiPath: `/admin/images/upload`,
                            fileName: 'fileName',
                        },
                    },
                },
            },
        },
        cardLevelBenefit: {
            apiPath: '',
            properties: {
                benefitInfos: {
                    type: 'array.cardLevelRights',
                    displayName: '权益项',
                },
                description: {
                    type: 'string.richTextPlus',
                    displayName: '等级权益详情',
                    rules: [{ max: 20000, message: '<<notMoreThan2000>>' }],
                    controlConfig: {
                        toolbarId: 'descriptionRichText',
                        style: {
                            height: 300,
                        },
                        uploadConfig: {
                            fileName: 'fileName',
                            apiPath: `/admin/images/upload`,
                        },
                    },
                },
            },
        },
    },
};
