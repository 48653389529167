import React, { Component } from 'react';
import { InputNumber, Select, message } from 'antd';
import { forEach, isArray, map, isEmpty, findIndex, cloneDeep } from 'lodash';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { services } from '@comall-backend-builder/core';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

type MemberPointDoubleRuleValue = {
    /**
     * 会员卡ID
     */
    memberLevelId?: string;
    /**
     * 倍数
     */
    multiple?: number;
};

export interface MemberPointDoubleRuleProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: MemberPointDoubleRuleValue | null, name: string) => void;
    /**
     * 当前值
     */
    value: MemberPointDoubleRuleValue;
    rules: any;
    options: any[];
    rewardText: string;
    returnCardLevelId?: boolean;
    /**
     * 请求配置
     */
    reqConfig: ApiRequestConfig;
}
const { api } = services;
export class MemberPointDoubleRule extends Component<MemberPointDoubleRuleProps, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            rewardText: '',
        };
    }
    componentDidMount() {
        const { rewardText } = this.props;
        if (rewardText) {
            this.setState({
                rewardText,
            });
        }
        setTimeout(this.loadData, 300);
    }
    loadData = () => {
        const { reqConfig } = this.props;
        if (!reqConfig) return;
        api.get({}, reqConfig).then((res: any) => {
            if (res && res.goldAlias) {
                this.setState({
                    rewardText: language.getText('jmultiple') + res.goldAlias,
                });
            }
        });
    };
    componentWillReceiveProps(nextProps: MemberPointDoubleRuleProps) {
        if (this.props.value && this.props.value.memberLevelId) {
            const index = this.props.returnCardLevelId
                ? findIndex(
                      nextProps.options,
                      (option) =>
                          Number(option.cardLevelId) === Number(this.props.value.memberLevelId)
                  )
                : findIndex(
                      nextProps.options,
                      (option) => Number(option.id) === Number(this.props.value.memberLevelId)
                  );
            if (index === -1) {
                const { onChange, name, value } = this.props;
                const newValue = cloneDeep(value);
                newValue.memberLevelId = '';
                onChange(newValue, name);
            }
        }
    }
    onMultipleChange(ladderAmount: any) {
        let { name, value, onChange } = this.props;
        let newValue = cloneDeep(value);
        if (!newValue) {
            newValue = {};
        }
        newValue.multiple = ladderAmount;
        if (onChange) {
            onChange(newValue, name);
        }
    }

    onMemberLevelIdChange(memberLevelId: string) {
        let { name, value, onChange } = this.props;
        let newValue = cloneDeep(value);
        if (newValue !== null && !isEmpty(newValue)) {
            newValue.memberLevelId = memberLevelId;
        } else {
            if (isEmpty(memberLevelId)) {
                message.warning(language.getText('qxzhydj'));
                return;
            }
            newValue = {
                memberLevelId,
            };
        }
        if (onChange) {
            onChange(newValue, name);
        }
    }

    render() {
        const { value, rules, options, returnCardLevelId } = this.props;
        const { rewardText } = this.state;
        const selectOption =
            isArray(options) &&
            value &&
            options.filter((option) => {
                if (returnCardLevelId) {
                    return Number(option.cardLevelId) === Number(value.memberLevelId);
                }
                return Number(option.id) === Number(value.memberLevelId);
            });
        const ruleLevelName = selectOption && selectOption.length > 0 && selectOption[0].name;
        let ruleInfo = {};
        if (rules && rules.length) {
            forEach(rules, (item) => {
                Object.assign(ruleInfo, item);
            });
        }
        return (
            <div className="point-reward-content">
                <div className="content">
                    <Select
                        className="rule-type-selection"
                        placeholder={services.language.getText('selectPlease')}
                        value={ruleLevelName ? ruleLevelName : undefined}
                        onChange={(memberLevelId: any) => this.onMemberLevelIdChange(memberLevelId)}
                    >
                        {isArray(options) &&
                            map(options, ({ id, name, cardLevelId }) => (
                                <Select.Option key={returnCardLevelId ? cardLevelId : id}>
                                    {name}
                                </Select.Option>
                            ))}
                    </Select>
                    <span className="item-text">{language.getText('song')}</span>
                    <div className="number-input">
                        <InputNumber
                            placeholder={services.language.getText('inputPlease')}
                            value={value && value.multiple}
                            min={1.01}
                            max={999999}
                            step={1}
                            precision={2}
                            style={{ width: '130px' }}
                            onChange={(value: any) => {
                                this.onMultipleChange(value);
                            }}
                            {...ruleInfo}
                        ></InputNumber>
                    </div>
                    <span className="item-text">
                        {rewardText ? rewardText : language.getText('bjf')}
                    </span>
                </div>
            </div>
        );
    }
}
