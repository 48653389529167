import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach, chain, get } from 'lodash';
import { RegistrationPriceType } from '../components/registration-price';
import { SessionType } from '../containers';
import { getTypeName } from './coupon/coupon-sale-activity-loader';

const api = services.api;

export const MallActivitySettingLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = '/admin/mall_activities';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/mall_activities/${id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                if (res.registration && res.registration.registrationStatus) {
                    res.editRegistrationStatus = res.registration.registrationStatus;
                } else {
                    res.editRegistrationStatus = 'NONE';
                }

                res.baseInfo = {
                    id: res.mallActivity.id,
                    name: res.mallActivity.name,
                    pictures: [
                        {
                            id: res.mallActivity.pictures[0].id || '',
                            path: res.mallActivity.pictures[0].url || '',
                        },
                    ],
                    dateRange: {
                        start: res.mallActivity.startTime,
                        end: res.mallActivity.endTime,
                    },
                    dayTime: [
                        {
                            start: res.mallActivity.dayStartTime,
                            end: res.mallActivity.dayEndTime,
                        },
                    ],
                    address: res.mallActivity.address,
                    phone: res.mallActivity.phone,
                    info: res.mallActivity.info,
                };
                // 从baseinfo里挪出来，方便其他属性设置dependences
                res.merchants = res.mallActivity.subSiteMerchants;
                res.tagIdList =
                    get(res, 'mallActivity.saleRuleTags') &&
                    get(res, 'mallActivity.saleRuleTags.length')
                        ? get(res, 'mallActivity.saleRuleTags').map((item: any) => {
                              const { tagId, tagName } = item;
                              return { id: tagId, name: tagName };
                          })
                        : [];
                res.targetUserLevels = condition2property(res.mallActivity.normalConditions);
                if (res.registration) {
                    res.registrationType = 'YES';
                    res.registrationDateRange = {
                        start: res.registration.startTime,
                        end: res.registration.endTime,
                    };
                    res.totalQuantity = res.mallActivity.totalQuantity;
                    res.info = res.registration.info;
                    res.rewardType = res.registration.rewardType;
                    res.rewardName = res.registration.rewardName;
                    res.selectCoupon = res.mallActivity.couponDefinitions.map((coupon: any) => {
                        coupon.couponTypeDesc = getTypeName(coupon.couponType);
                        return coupon;
                    });
                    res.timeLimit = res.registration.timeLimit;
                    res.targetUserBirthDay = {
                        from: res.registration.startBirthdayScope,
                        to: res.registration.endBirthdayScope,
                    };
                    res.targetUserSexuality = res.registration.gender;
                    res.reviewType = res.registration.review ? 'TRUE' : 'FALSE';
                    res.session = res.registration.session ? SessionType.YES : SessionType.NO;
                    res.registrationTimes = res.registration.registrationTimes;
                    res.totalQuantity = res.mallActivity.totalQuantity;
                    if (res.registration.extension) {
                        res.customInfo = JSON.parse(res.registration.extension);
                    } else {
                        res.customInfo = [];
                    }
                } else {
                    res.registrationType = 'NO';
                    res.selectCoupon = res.mallActivity.couponDefinitions;
                    res.rewardType = 'OFF_LINE';
                }
                // 因为没有状态标识，这里要自行判断
                if (
                    get(res, 'targetUserLevels.length') > 0 ||
                    (get(res, 'registration.gender') && get(res, 'registration.gender') !== '') ||
                    (get(res, 'registration.startBirthdayScope') &&
                        get(res, 'registration.startBirthdayScope') !== '')
                ) {
                    res.targetUserType = 'SOME';
                }
                if (!res.mallActivity.point && !res.mallActivity.salePrice) {
                    res.registrationPrice = {
                        type: RegistrationPriceType.NONE,
                        price: '',
                        integral: '',
                    };
                } else {
                    res.registrationPrice = {
                        type: RegistrationPriceType.PAY,
                        price: res.mallActivity.salePrice,
                        integral: res.mallActivity.point,
                    };
                }
                if (res.mallActivity.limit === 0) {
                    res.limitCountType = 'NO';
                    res.limitCount = 1;
                } else {
                    res.limitCountType = 'YES';
                    res.limitCount = res.mallActivity.limit;
                }
                res.id = res.mallActivity.id;
            } else {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        if (!item.registrationStatus) {
                            item.registrationStatus = 'NONE';
                        }
                        let registrationStatus = '非报名';
                        if (item.registrationStatus === 'NOTSTARTED') {
                            registrationStatus = '未开始';
                        } else if (item.registrationStatus === 'STARTED') {
                            registrationStatus = '进行中';
                        } else if (item.registrationStatus === 'COMPLETE') {
                            registrationStatus = '已结束';
                        }
                        item.registrationStatus = registrationStatus;
                        return item;
                    });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = '/admin/mall_activities';
        let newParams: any = {};
        if (params.baseInfo) {
            const shopArray: any = [];
            forEach(params.merchants, (shop) => {
                shopArray.push(shop.id);
            });
            newParams.mallActivity = {
                id: 0,
                name: params.baseInfo.name,
                pictures: [
                    {
                        id: params.baseInfo.pictures[0].id,
                        url: params.baseInfo.pictures[0].path,
                    },
                ],
                startTime: params.baseInfo.dateRange.start + ' 00:00:00',
                endTime: params.baseInfo.dateRange.end + ' 23:59:59',
                dayStartTime: params.baseInfo.dayTime[0].start,
                dayEndTime: params.baseInfo.dayTime[0].end,
                address: params.baseInfo.address,
                phone: params.baseInfo.phone,
                info: params.baseInfo.info,
                subSiteMerchantIds: shopArray,
                normalConditions: property2condition(params.targetUserLevels),
                limit: 0,
            };
        }

        if (params.tagIdList && params.tagIdList.length) {
            newParams.tagIdList = params.tagIdList.map((item: any) => item.id);
        }
        if (params.registrationType && params.registrationType === 'YES') {
            newParams.registration = {
                startTime: params.registrationDateRange.start,
                endTime: params.registrationDateRange.end,
                info: params.info,
                rewardType: params.rewardType,
                rewardName: params.rewardName,
                startBirthdayScope: params.targetUserBirthDay
                    ? params.targetUserBirthDay.from
                    : undefined,
                endBirthdayScope: params.targetUserBirthDay
                    ? params.targetUserBirthDay.to
                    : undefined,
                gender: params.targetUserSexuality,
                timeLimit: params.timeLimit,
                review: params.reviewType === 'TRUE' ? true : false,
                session: params.session === SessionType.YES,
            };

            newParams.mallActivity.limit = params.limitCountType === 'YES' ? params.limitCount : 0;
            if (params.customInfo && params.customInfo.length) {
                newParams.registration.extension = JSON.stringify(params.customInfo);
            }
            if (params.rewardType === 'COUPON') {
                const idArray = new Array<{
                    id: number;
                    couponType: string;
                }>();
                if (params.selectCoupon && params.selectCoupon.length > 0) {
                    forEach(params.selectCoupon, (coupon) => {
                        idArray.push({
                            id: coupon.id,
                            couponType: coupon.type,
                        });
                    });
                }
                newParams.mallActivity.couponDefinitions = idArray;
            }
            if (params.registrationPrice.type === RegistrationPriceType.PAY) {
                newParams.mallActivity.point = params.registrationPrice.integral
                    ? params.registrationPrice.integral
                    : 0;
                newParams.mallActivity.salePrice = params.registrationPrice.price
                    ? params.registrationPrice.price
                    : 0;
            }

            if (params.session === SessionType.YES) {
                newParams.registration.registrationTimes = params.registrationTimes;
                newParams.mallActivity.totalQuantity = params.registrationTimes.reduce(
                    (cur: number, item: any) => cur + item.quantity,
                    0
                );
            } else {
                newParams.mallActivity.totalQuantity = params.totalQuantity;
            }
        } else {
            newParams.registration = null;
        }
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        if (params.id) {
            config.apiPath = `/admin/mall_activities/${params.id}`;
        }
        let newParams: any = {};
        if (params.baseInfo) {
            const shopArray: any = [];
            forEach(params.merchants, (shop) => {
                shopArray.push(shop.id);
            });
            //前端组件是年月日，后端是年月日时分秒，所以在此处做判断
            //当用户重新修改时间，则重新赋值
            let startTimeString = '';
            let endTimeString = '';
            if (params.baseInfo.dateRange.start.length <= 10) {
                startTimeString = params.baseInfo.dateRange.start + ' 00:00:00';
                endTimeString = params.baseInfo.dateRange.end + ' 23:59:59';
            } else {
                startTimeString = params.baseInfo.dateRange.start;
                endTimeString = params.baseInfo.dateRange.end;
            }
            newParams.mallActivity = {
                id: params.id,
                name: params.baseInfo.name,
                pictures: [
                    {
                        id: params.baseInfo.pictures[0].id,
                        url: params.baseInfo.pictures[0].path,
                    },
                ],
                startTime: startTimeString,
                endTime: endTimeString,
                dayStartTime: params.baseInfo.dayTime[0].start,
                dayEndTime: params.baseInfo.dayTime[0].end,
                address: params.baseInfo.address,
                phone: params.baseInfo.phone,
                info: params.baseInfo.info,
                subSiteMerchantIds: shopArray,
                normalConditions: property2condition(params.targetUserLevels),
                limit: 0,
            };
        }

        if (params.tagIdList && params.tagIdList.length) {
            newParams.tagIdList = params.tagIdList.map((item: any) => item.id);
        }
        if (params.registrationType && params.registrationType === 'YES') {
            newParams.registration = {
                startTime: params.registrationDateRange.start,
                endTime: params.registrationDateRange.end,
                info: params.info,
                rewardType: params.rewardType,
                rewardName: params.rewardName,
                startBirthdayScope: params.targetUserBirthDay
                    ? params.targetUserBirthDay.from
                    : undefined,
                endBirthdayScope: params.targetUserBirthDay
                    ? params.targetUserBirthDay.to
                    : undefined,
                gender: params.targetUserSexuality,
                timeLimit: params.timeLimit,
                review: params.reviewType === 'TRUE' ? true : false,
                session: params.session === SessionType.YES,
            };

            if (params.registrationPrice.type === RegistrationPriceType.PAY) {
                newParams.mallActivity.point = params.registrationPrice.integral
                    ? params.registrationPrice.integral
                    : 0;
                newParams.mallActivity.salePrice = params.registrationPrice.price
                    ? params.registrationPrice.price
                    : 0;
            }
            newParams.mallActivity.limit = params.limitCountType === 'YES' ? params.limitCount : 0;
            if (params.customInfo && params.customInfo.length) {
                newParams.registration.extension = JSON.stringify(params.customInfo);
            }
            if (params.rewardType === 'COUPON') {
                const idArray = new Array<{
                    id: number;
                    couponType: string;
                }>();
                if (params.selectCoupon && params.selectCoupon.length > 0) {
                    forEach(params.selectCoupon, (coupon) => {
                        idArray.push({
                            id: coupon.id,
                            couponType: coupon.type,
                        });
                    });
                }
                newParams.mallActivity.couponDefinitions = idArray;
            }

            if (params.session === SessionType.YES) {
                newParams.registration.registrationTimes = params.registrationTimes;
                newParams.mallActivity.totalQuantity = params.registrationTimes.reduce(
                    (cur: number, item: any) => cur + item.quantity,
                    0
                );
            } else {
                newParams.mallActivity.totalQuantity = params.totalQuantity;
            }
        } else {
            newParams.registration = null;
        }
        return await api.put(newParams, config);
    },
};

export const MallActivitySettingUserCardLevelLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const { merchants } = data;
        // 业务上只允许选择同一分站下的门店
        const subsiteId = merchants && merchants[0] && merchants[0].subSiteId;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`;
        config.apiPath = '/admin/store_card_levels';
        // 需要的是非分页数据
        const params = { subsiteId, page: 1, per_page: 100, status: 'ENABLE' };
        const res = await api.get<any>(params, config);
        return chain(res.result)
            .map((card) => ({ id: card.cardLevelId + '', name: card.name }))
            .unionBy('id')
            .value();
    },
};

/**
 * 将用户等级限制字段转为限制条件
 *
 * 输出标准限制条件
 *
 * @example
 * [
 *     {
 *         type: 'MEMBER_CARD_LEVEL',
 *         scopes: [],
 *     },
 * ]
 */
function property2condition(property: any) {
    return property
        ? [
              {
                  type: 'MEMBER_CARD_LEVEL',
                  scopes: property,
              },
          ]
        : [];
}

/**
 * 将限制条件转为用户等级限制字段
 *
 * 输出会员卡等级数组
 *
 * @example
 * [{ "id": 1, "name": "金卡" }]
 */
function condition2property(condition: any[]) {
    return chain(condition)
        .filter((c) => c.type === 'MEMBER_CARD_LEVEL')
        .map((c) => c.scopes)
        .flatMap()
        .map((c) => ({ id: c.id + '', name: c.name }))
        .unionBy('id')
        .value();
}
