import React, { Component } from 'react';
import { Input, Button, Modal, message, Checkbox } from 'antd';
import { getSupportAlipay } from '../../../../../services';

import './index.less';
import { services } from '@comall-backend-builder/core';

const { getText } = services.language;

interface MiniprogramLinkParams {
    appId?: string;
    weappOriginalId?: string;
    path?: string;
    alipayAppId?: string;
    alipayPath?: string;
    weappNeedAuth?: boolean;
    alipayNeedAuth?: boolean;
}

class CustomMiniprogram extends Component<
    any,
    { linkParams?: MiniprogramLinkParams; showModal: boolean }
> {
    constructor(props: any) {
        super(props);
        this.state = {
            showModal: false,
        };
    }
    componentDidMount(): void {
        this.valueToState();
    }

    supportAlipay = getSupportAlipay();

    valueToState = () => {
        const { value } = this.props;
        this.setState({ linkParams: value?.linkParams });
    };
    showModal = () => {
        this.valueToState();
        this.setState({ showModal: true });
    };
    onOk = () => {
        const { onChange } = this.props;
        const { linkParams } = this.state;
        if (!linkParams?.appId) {
            message.warn(getText('h_qtxwxxcxappid'));
            return;
        }
        onChange(linkParams);
        this.setState({ showModal: false });
    };
    onCancel = () => {
        this.setState({ showModal: false });
        this.valueToState();
    };
    handleChange: <T extends keyof MiniprogramLinkParams>(
        key: T,
        val: MiniprogramLinkParams[T]
    ) => void = (key, val) => {
        const { linkParams } = this.state;
        const newLinkParams = { ...(linkParams || {}) };
        newLinkParams[key] = val;
        this.setState({ linkParams: newLinkParams });
    };
    renderModal = () => {
        const { linkParams, showModal } = this.state;
        return (
            <Modal
                visible={showModal}
                title={getText('qtxpz')}
                onOk={this.onOk}
                onCancel={this.onCancel}
            >
                <div className="miniprogram-form__sub-form">{getText('wxMiniP')}</div>
                <div className="miniprogram-form__input">
                    <label className="miniprogram-form__input-label">
                        {getText('h_xcxappid')}：{' '}
                    </label>
                    <Input
                        placeholder={getText('h_qtxxcxappid')}
                        value={linkParams?.appId}
                        onChange={(e) => this.handleChange('appId', e.target.value)}
                    />
                </div>
                <div className="miniprogram-form__input">
                    <label className="miniprogram-form__input-label">{getText('h_ysid')}： </label>
                    <Input
                        placeholder={services.language.getText('ndkxcxxysrys')}
                        value={linkParams?.weappOriginalId}
                        onChange={(e) => this.handleChange('weappOriginalId', e.target.value)}
                    />
                </div>
                <div className="miniprogram-form__input">
                    <label className="miniprogram-form__input-label">
                        {getText('miniProgramPath')}：{' '}
                    </label>
                    <Input
                        placeholder={getText('qtxxcxlj')}
                        value={linkParams?.path}
                        onChange={(e) => this.handleChange('path', e.target.value)}
                    />
                </div>
                <div className="miniprogram-form__input">
                    <label className="miniprogram-form__input-label">{getText('qzdl')}： </label>
                    <Checkbox
                        checked={linkParams?.weappNeedAuth}
                        onChange={(e) => this.handleChange('weappNeedAuth', e.target.checked)}
                    />
                </div>
                <div className="miniprogram-form__sub-form">{getText('aliMiniP')}</div>
                <div className="miniprogram-form__desc">
                    {services.language.getText('xyzcsyzfbxcx')}
                </div>
                <div className="miniprogram-form__input">
                    <label className="miniprogram-form__input-label">
                        {getText('h_xcxappid')}：{' '}
                    </label>
                    <Input
                        placeholder={getText('h_qtxxcxappid')}
                        value={linkParams?.alipayAppId}
                        disabled={!this.supportAlipay}
                        onChange={(e) => this.handleChange('alipayAppId', e.target.value)}
                    />
                </div>
                <div className="miniprogram-form__input">
                    <label className="miniprogram-form__input-label">
                        {getText('miniProgramPath')}：{' '}
                    </label>
                    <Input
                        placeholder={getText('qtxxcxlj')}
                        value={linkParams?.alipayPath}
                        disabled={!this.supportAlipay}
                        onChange={(e) => this.handleChange('alipayPath', e.target.value)}
                    />
                </div>
                <div className="miniprogram-form__input">
                    <label className="miniprogram-form__input-label">{getText('qzdl')}： </label>
                    <Checkbox
                        checked={linkParams?.alipayNeedAuth}
                        onChange={(e) => this.handleChange('alipayNeedAuth', e.target.checked)}
                    />
                </div>
            </Modal>
        );
    };
    render() {
        const { value } = this.props;
        const appId = value?.linkParams?.appId;
        return (
            <div className="miniprogram-form">
                <span className="link-name-label">{appId || ''}</span>
                <Button type={'link'} onClick={this.showModal} className="link-type-edit">
                    {getText('settings')}
                </Button>
                {this.renderModal()}
            </div>
        );
    }
}

export default CustomMiniprogram;
