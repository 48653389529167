import React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { CityTreeSelect } from './city';

export class SelectCityCodeMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <CityTreeSelect {...displayInfo} />;
    }
}
