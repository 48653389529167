import React, { PureComponent } from 'react';
import { Table, InputNumber, Button, message, Popconfirm, Modal } from 'antd';
import { cloneDeep, remove, find, forEach, isNaN, isNull, isNil } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';
import './index.less';

interface DistributionProductSelectedDisplayProps {
    data: any[];
    onChange: (data: any[]) => void;
    modifiable: boolean;
    disabled?: boolean;
}

interface DistributionProductSelectedDisplayState {
    showPercentageModal: boolean;
    source: {
        identityLevel: string;
        identityLevelId: string;
        percentage: undefined | number;
        memberIdentity: string;
    }[];
}

/**
 * 商品展示和内容编辑
 */
class DistributionProductSelectedDisplay extends PureComponent<
    DistributionProductSelectedDisplayProps,
    DistributionProductSelectedDisplayState
> {
    constructor(props: DistributionProductSelectedDisplayProps) {
        super(props);
        this.state = {
            showPercentageModal: false,
            source: [
                {
                    identityLevel: services.language.getText('fxy'),
                    identityLevelId: '1',
                    percentage: 0,
                    memberIdentity: 'SHOPPING_GUIDE',
                },
            ],
        };
    }
    selectedRows: any[] = [];

    getTableConfig = () => {
        const { modifiable, disabled } = this.props;
        let config: any = {
            rowKey: 'id',
            columns: [
                {
                    title: services.language.getText('components.Products.name'),
                    dataIndex: 'productName',
                    key: 'productName',
                    width: 150,
                    render: (i: string) => (
                        <span title={i} className="goods-field-name">
                            {i}
                        </span>
                    ),
                },
                {
                    title: services.language.getText('goodName'),
                    dataIndex: 'name',
                    key: 'name',
                    width: 150,
                    render: (i: string) => (
                        <span title={i} className="goods-field-name">
                            {i}
                        </span>
                    ),
                },
                {
                    title: services.language.getText('productCode'),
                    dataIndex: 'productCode',
                    key: 'productCode',
                    width: 120,
                },
                {
                    title: services.language.getText('goodCode'),
                    dataIndex: 'productStyleCode',
                    key: 'productStyleCode',
                    width: 120,
                },
                {
                    title: services.language.getText('subsiteName'),
                    dataIndex: 'subsiteName',
                    key: 'subsiteName',
                    width: 120,
                },
                {
                    title: language.getText('merchantName'),
                    dataIndex: 'merchantName',
                    key: 'merchantName',
                    width: 120,
                },
                {
                    title: services.language.getText('xsj'),
                    dataIndex: 'salesPrice',
                    key: 'salesPrice',
                    width: 100,
                    align: 'center' as const,
                },
                {
                    title: services.language.getText('stock'),
                    dataIndex: 'stock',
                    key: 'stock',
                    width: 100,
                    align: 'center' as const,
                },
                {
                    title: services.language.getText('yjbl'),
                    key: 'commissionInfos',
                    dataIndex: 'commissionInfos',
                    width: 120,
                    align: 'center' as const,
                    render: (v: any, r: any) => {
                        let value = !isNil(v[0].percentage)
                            ? Number((v[0].percentage * 100).toFixed(2))
                            : undefined;
                        return modifiable ? (
                            <InputNumber
                                value={value}
                                min={0.0}
                                step={0.01}
                                max={100}
                                formatter={(value) => (value && !isNaN(value) ? `${value}%` : '%')}
                                onChange={(value: any) => this.changeCommissionInfos(value, r)}
                            ></InputNumber>
                        ) : (
                            value + '%'
                        );
                    },
                },
                {
                    title: services.language.getText('yjfy'),
                    dataIndex: 'commissionValue',
                    key: 'commissionValue',
                    width: 150,
                    render: (i: string, r: any) => (
                        <span title={i} className="goods-field-name">
                            {services.language.getText('highest')}
                            {i}
                        </span>
                    ),
                },
            ],
            scroll: {
                x: 1900,
                y: 500,
            },
        };
        if (modifiable && !disabled) {
            config.rowSelection = {
                onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                    this.selectedRows = selectedRows;
                },
                fixed: true,
            };
            config.columns.push({
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                key: 'operation',
                fixed: 'right' as const,
                width: 100,
                render: (i: any, row: any) => (
                    <Button type={'link'} disabled={disabled} onClick={this.onRemove(row.id)}>
                        {services.language.getText('common.delete')}
                    </Button>
                ),
            });
        }
        return config;
    };

    renderFooter = () => {
        return (
            <div className="product-selected-footer">
                <div className="batch-remove">
                    <Popconfirm
                        onConfirm={this.batchRemove()}
                        title={services.language.getText('confirmDeleteColumn')}
                        okText={services.language.getText('common.ok')}
                        cancelText={services.language.getText('common.cancel')}
                    >
                        <Button className="setting-item-ok" type="default">
                            {services.language.getText('batchDelete')}
                        </Button>
                    </Popconfirm>
                </div>
                <div className="batch-remove">
                    <Button
                        onClick={this.togglePercentageModal}
                        className="setting-item-ok"
                        type="default"
                    >
                        {services.language.getText('plsz')}
                    </Button>
                </div>
            </div>
        );
    };

    renderPercentageModal = () => {
        const { showPercentageModal, source } = this.state;
        const columns = [
            {
                title: services.language.getText('dj_2'),
                dataIndex: 'identityLevel',
                key: 'identityLevel',
            },
            {
                title: services.language.getText('yjbl'),
                dataIndex: 'percentage',
                key: 'percentage',
                width: 300,
                render: (v: number, r: any) => {
                    let value = !isNil(v) ? Number((v * 100).toFixed(2)) : undefined;
                    return (
                        <div>
                            <InputNumber
                                value={value}
                                style={{ width: 100 }}
                                min={0.0}
                                step={0.01}
                                max={100}
                                formatter={(value) => (value && !isNaN(value) ? `${value}%` : '%')}
                                onChange={(value: any) => this.changePercentage(value, r)}
                            ></InputNumber>
                        </div>
                    );
                },
            },
        ];
        return (
            <Modal
                visible={showPercentageModal}
                title={services.language.getText('zdyyjbl')}
                okText={services.language.getText('common.ok')}
                cancelText={services.language.getText('common.cancel')}
                mask
                onOk={this.handleChangePercentage}
                onCancel={this.togglePercentageModal}
                bodyStyle={{ padding: '20px 40px' }}
            >
                <Table columns={columns} pagination={false} dataSource={source}></Table>
            </Modal>
        );
    };

    togglePercentageModal = () => {
        const { showPercentageModal } = this.state;
        if (!showPercentageModal) {
            const selectedRow = this.selectedRows;
            if (!selectedRow || selectedRow.length === 0) {
                message.info(services.language.getText('selectSetDataColumn'));
                return;
            }
        }
        this.setState({
            showPercentageModal: !showPercentageModal,
        });
    };

    changePercentage = (value: any, row: any) => {
        if (isNaN(value) || isNull(value) || typeof value === 'string') {
            message.warning(services.language.getText('pleaseInputNumber'));
            value = 0.0;
        }
        const arr = value.toString().split('.');
        if (arr.length > 1) {
            value = Number(arr[0] + '.' + arr[1].slice(0, 2));
        }
        const { source } = this.state;
        const newSource = cloneDeep(source);
        const item = find(newSource, (i) => i.identityLevelId === row.identityLevelId);
        if (item) {
            item.percentage = value / 100;
        }
        this.setState({
            source: newSource,
        });
    };

    handleChangePercentage = () => {
        const selectedRow = this.selectedRows;
        const { source } = this.state;
        if (!source || source.length === 0 || isNil(source[0].percentage)) {
            message.info(services.language.getText('qtxyjbl'));
            return;
        }
        const { data, onChange } = this.props;
        const result = cloneDeep(data);
        forEach(result, (item) => {
            if (!!find(selectedRow, (j) => j.id === item.id)) {
                item.commissionInfos[0].percentage = source[0].percentage;
                item.commissionValue = (
                    (item.salesPrice * Number(source[0].percentage) * 100) /
                    100
                ).toFixed(2);
            }
        });
        onChange(result);
        source[0].percentage = 0;
        this.setState({ source });
        this.togglePercentageModal();
    };

    onRemove = (id: string) => {
        const { data, onChange } = this.props;
        return () => {
            const result = cloneDeep(data);
            remove(result, (i) => id === i.id);
            onChange(result);
        };
    };

    batchRemove = () => {
        return () => {
            // @ts-ignore：
            const selectedRow = this.selectedRows;
            if (!selectedRow || selectedRow.length === 0) {
                message.info(services.language.getText('common.delete'));
                return;
            }
            const { data, onChange } = this.props;
            const result = cloneDeep(data);
            remove(result, (i) => !!find(selectedRow, (j) => j.id === i.id));
            onChange(result);
            this.selectedRows = [];
        };
    };

    changeValue = (field: string, value: any, id: string) => {
        const { data, onChange } = this.props;
        const result = cloneDeep(data);
        let item = find(result, (i) => id === i.id);
        if (item) {
            item[field] = value;
            onChange(result);
        }
    };

    changeCommissionInfos = (value: any, row: any) => {
        if (isNaN(value) || isNull(value) || typeof value === 'string') {
            message.warning(services.language.getText('pleaseInputNumber'));
            value = 0.0;
        }
        const arr = value.toString().split('.');
        if (arr.length > 1) {
            value = Number(arr[0] + '.' + arr[1].slice(0, 2));
        }
        const { data, onChange } = this.props;
        const result = cloneDeep(data);
        let item = find(result, (i) => row.id === i.id);
        if (item) {
            item.commissionInfos[0].percentage = (value / 100).toFixed(4);
            item.commissionValue = ((row.salesPrice * value) / 100).toFixed(2);
            onChange(result);
        }
    };

    render() {
        const { data, modifiable, disabled } = this.props;
        let tableConfig = this.getTableConfig();
        const tableProps = { ...tableConfig, dataSource: data };
        return (
            <>
                <Table {...tableProps} pagination={false}></Table>
                {modifiable && !disabled && this.renderFooter()}
                {modifiable && !disabled && this.renderPercentageModal()}
            </>
        );
    }
}

export { DistributionProductSelectedDisplay };
