import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach, map, camelCase, forIn, cloneDeep, snakeCase } from 'lodash';
import {
    PayWithGiftConsumptionType,
    PayWithGiftRewardActivityRewardValue,
} from '../../types/mode/object/pay-with-gifts-reward/pay-with-gifts-reward';
import { MsgChannelType } from '../../types/mode/array/array-message-notification-method/message-notification-method';
const api = services.api;

export const PayWithGiftsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/event_markets';
        if (data.id) {
            config.apiPath = `/admin/event_markets/${data.id}`;
        } else {
            data.eventType = 10;
            data.menuCode = 'payAward';
            if (data.status === 'ALL') {
                delete data.status;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (data.id) {
                const selectModeValue = findValueById(27, res.conditionValues);
                const rangeValueList = res.conditionValues.filter(
                    (item: any) => item.conditionId === 18
                );
                res.baseInfo = {
                    name: res.name,
                    time: { start: res.startTime, end: res.endTime },
                    subsiteId: String(res.subsites[0].id),
                    selectMode: selectModeValue,
                    merchants: res.subsites[0].subSiteMerchants || [],
                    range:
                        rangeValueList && rangeValueList.length > 0
                            ? rangeValueList.map((v: any) => {
                                  return v.value;
                              })
                            : undefined,
                };
                if (res.ladderConf) {
                    const typeVo = findValueById(19, res.conditionValues);
                    const secordTypeVo = findValueById(31, res.conditionValues);
                    res.baseInfo.reward = {
                        type: Number(typeVo),
                        secordType: secordTypeVo ? Number(secordTypeVo) : undefined,
                        ladderConf: res.ladderConf.map((reward: any) => {
                            const couponVo = reward.eventMarketConfList.find(
                                (i: any) => i.awardType === 2 || i.awardType === 6
                            );
                            const activityVo = reward.eventMarketConfList.find(
                                (i: any) => i.awardType === 11
                            );
                            const coupon =
                                couponVo && couponVo.coupons && couponVo.coupons.length > 0
                                    ? couponVo.coupons[0]
                                    : undefined;
                            if (coupon) {
                                coupon.couponName = coupon.name;
                                coupon.type = coupon.couponType;
                            }
                            return {
                                id: reward.id,
                                totalMoneyMin: reward.totalMoneyMin,
                                totalMoneyMax: reward.totalMoneyMax,
                                couponsEventMarketConfId: couponVo ? couponVo.id : undefined,
                                couponsChecked: couponVo ? true : false,
                                coupons: couponVo ? [coupon] : [],
                                distributionType: couponVo
                                    ? Number(couponVo.distributionType)
                                    : undefined,
                                receiveDays: couponVo ? couponVo.receiveDays : undefined,
                                maxAwardNumberEveryDay: couponVo
                                    ? couponVo.maxAwardNumberEveryDay
                                    : undefined,
                                rewardPicture:
                                    couponVo && couponVo.rewardPicture
                                        ? [
                                              {
                                                  id: 1,
                                                  path: couponVo.rewardPicture,
                                              },
                                          ]
                                        : undefined,
                                activitysEventMarketConfId: activityVo ? activityVo.id : false,
                                activitysChecked: activityVo ? true : false,
                                activitys: activityVo
                                    ? [
                                          {
                                              id: activityVo.activity.id,
                                              name: activityVo.activity.name,
                                              startTime: activityVo.activity.startTime,
                                              endTime: activityVo.activity.endTime,
                                              eventType: activityVo.activity.type,
                                              quantity: activityVo.quantity,
                                          },
                                      ]
                                    : [],
                                activityRewardPicture:
                                    activityVo && activityVo.rewardPicture
                                        ? [
                                              {
                                                  id: 1,
                                                  path: activityVo.rewardPicture,
                                              },
                                          ]
                                        : undefined,
                                effectiveDays: activityVo ? activityVo.effectiveDays : undefined,
                            };
                        }),
                    };
                }
                const type = findValueById(22, res.conditionValues);
                const count = findValueById(23, res.conditionValues);
                const recover = findValueById(26, res.conditionValues);
                res.baseInfo.limits = {
                    type: type,
                    dayLimitCount: type === '0' ? count : undefined,
                    activityLimitCount: type === '1' ? count : undefined,
                };
                res.baseInfo.recover = recover;
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : services.language.getText('longTerm'),
                    };
                }
                res.baseInfo.remark = res.remark;
                res.baseInfo.marketingSchedule = res.marketingSchedule
                    ? [res.marketingSchedule]
                    : [];
                const businessNotifications =
                    res.businessNotifications && res.businessNotifications.length
                        ? res.businessNotifications.map((item: any) => {
                              let content: any[] = [];
                              if (item.content) {
                                  try {
                                      const newContent = JSON.parse(item.content);
                                      newContent.forEach((i: any) => {
                                          const newItem: any = {};
                                          forIn(i, (value, key) => {
                                              newItem[camelCase(key)] = value;
                                          });
                                          if (
                                              (item.msgChannelType === MsgChannelType.APP_PUSH ||
                                                  item.msgChannelType === MsgChannelType.SITEMAIL ||
                                                  item.msgChannelType === MsgChannelType.EMAIL) &&
                                              newItem.fieldType === 'file' &&
                                              newItem.value
                                          ) {
                                              newItem.value = JSON.parse(newItem.value);
                                          }
                                          content.push(newItem);
                                      });
                                  } catch (error) {
                                      content = item.content;
                                  }
                              }
                              let targetUrl = '';
                              try {
                                  targetUrl = JSON.parse(item.targetUrl);
                              } catch (error) {
                                  targetUrl = item.targetUrl;
                              }
                              return {
                                  id: item.id,
                                  status: item.status,
                                  msgChannelType: item.msgChannelType,
                                  scene: item.scene,
                                  targetType: item.targetType,
                                  targetUrl: targetUrl,
                                  params: content,
                              };
                          })
                        : [];
                res.baseInfo.businessNotifications = businessNotifications;
            } else {
                res.result = res.result.map((item: any) => {
                    const consumeScopeNames: string[] = [];
                    if (item.consumeScopes) {
                        forEach(item.consumeScopes, (scope) => {
                            if (scope === '0') {
                                consumeScopeNames.push('电子小票');
                            } else if (scope === '1') {
                                consumeScopeNames.push('线上订单');
                            } else if (scope === '2') {
                                consumeScopeNames.push('商圈消费');
                            }
                        });
                        if (item.typeId.toString() === '1') {
                            item.eventTypeName = '新人有礼';
                        }
                    }
                    item.rangesName = consumeScopeNames.join('，');
                    return item;
                });
            }
            return res;
        });
    },
    delete: async function(data, config: ApiRequestConfig) {
        const { id } = data;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/event_markets/${id}`;

        return await api.delete(data, config);
    },

    post: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/event_markets';

        try {
            const params = transform(data);
            return await api.post(params, config);
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    put: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/event_markets/${data.id}`;

        try {
            const params = transform(data);
            return await api.put(params, config);
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
};

function transform(data: any) {
    const isEdit = window.location.href.includes('/edit');
    const params: any = {};
    params.typeId = 10;
    const { baseInfo } = data;
    params.name = baseInfo.name;
    params.startTime = baseInfo.time.start;
    params.endTime = baseInfo.time.end;
    params.subsiteIds = [baseInfo.subsiteId];
    params.subsiteMerchants =
        baseInfo.selectMode !== '0' && baseInfo.merchants && baseInfo.merchants.length
            ? baseInfo.merchants.map((item: any) => ({
                  subsiteId: item.subSiteId,
                  merchantId: item.merchantId,
              }))
            : [];

    params.status = '1';
    params.remark = baseInfo.remark;
    params.eventMarketConfList = [];
    params.ladderConf =
        baseInfo.reward.ladderConf && baseInfo.reward.ladderConf.length > 0
            ? baseInfo.reward.ladderConf.map((reward: PayWithGiftRewardActivityRewardValue) => {
                  const eventMarketConfList = [];
                  if (reward.couponsChecked) {
                      const coupon = reward.coupons[0];
                      eventMarketConfList.push({
                          id:
                              isEdit && reward.couponsEventMarketConfId
                                  ? reward.couponsEventMarketConfId
                                  : undefined,
                          awardType: coupon.couponType === 'PACKAGE' ? 6 : 2,
                          distributionType: reward.distributionType,
                          receiveDays: reward.receiveDays,
                          maxAwardNumberEveryDay: reward.maxAwardNumberEveryDay,
                          rewardPicture:
                              reward.rewardPicture && reward.rewardPicture.length > 0
                                  ? reward.rewardPicture[0].path
                                  : undefined,
                          coupons: [
                              {
                                  id: coupon.id,
                                  batchNo: coupon.batchNo,
                                  quantity: coupon.quantity ? coupon.quantity : 1,
                              },
                          ],
                      });
                  }
                  if (reward.activitysChecked) {
                      const activity = reward.activitys[0];
                      eventMarketConfList.push({
                          id:
                              isEdit && reward.activitysEventMarketConfId
                                  ? reward.activitysEventMarketConfId
                                  : undefined,
                          awardType: '11',
                          awardValue: activity.id,
                          quantity: activity.quantity,
                          rewardPicture:
                              reward.activityRewardPicture &&
                              reward.activityRewardPicture.length > 0
                                  ? reward.activityRewardPicture[0].path
                                  : undefined,
                          effectiveDays: reward.effectiveDays,
                      });
                  }
                  const newItem = {
                      id: isEdit && reward.id ? reward.id : undefined,
                      totalMoneyMin: reward.totalMoneyMin,
                      totalMoneyMax: reward.totalMoneyMax,
                      eventMarketConfList: eventMarketConfList,
                  };
                  return newItem;
              })
            : [];

    params.eventMarketConditionValueVolist = tranformCondition(data);
    params.marketingScheduleId =
        (baseInfo?.marketingSchedule?.length && baseInfo.marketingSchedule[0].id) || null;
    if (baseInfo?.businessNotifications && baseInfo.businessNotifications.length) {
        const businessNotifications = map(
            cloneDeep(baseInfo.businessNotifications),
            (item: any) => {
                const isSitemail =
                    item.msgChannelType === MsgChannelType.APP_PUSH ||
                    item.msgChannelType === MsgChannelType.SITEMAIL;
                const content = item.params.map((param: any) => {
                    if (param.fieldType === 'file' && param.value && isSitemail) {
                        param.value = param.value.id;
                    }
                    if (
                        param.fieldType === 'file' &&
                        param.value &&
                        item.msgChannelType === MsgChannelType.EMAIL
                    ) {
                        param.value = param.value.map((i: any) => i.id).join(',');
                    }
                    if (item.msgChannelType === MsgChannelType.SMS && !param.editable) {
                        param.value = '';
                    }
                    const newParam: any = {};
                    forIn(param, (value, key) => {
                        newParam[snakeCase(key)] = value;
                    });
                    return newParam;
                });
                return {
                    content: JSON.stringify(content),
                    id: item?.id,
                    msgChannelType: item.msgChannelType,
                    scene: item.scene,
                    status: item.status,
                    targetType: item.targetType,
                    targetUrl:
                        item.targetUrl && isSitemail
                            ? JSON.stringify(item.targetUrl)
                            : item.targetUrl,
                };
            }
        );
        params.businessNotifications = businessNotifications;
    }
    return params;
}

function tranformCondition({ baseInfo }: any) {
    let count = null;
    if (baseInfo.limits.type === '0') {
        count = baseInfo.limits.dayLimitCount;
    } else if (baseInfo.limits.type === '1') {
        count = baseInfo.limits.activityLimitCount;
    }
    let conditionList: any = [
        {
            conditionId: 19, //奖励条件（0-单次奖励、1-累计奖励、2-范围奖励）
            value: baseInfo.reward.type,
        },
        {
            conditionId: 20, //发放时间（0-支付后发放）  参与门槛（NULL）
            value: 0,
        },
        {
            conditionId: 22, //发放限制（-1:不限制、0:限制每人每天发放次数、1:限制每人在活动内发放次数）
            value: baseInfo.limits.type,
        },
        {
            conditionId: 23, //发放数量（不限制时此值是NULL、 限制每人每天发放10次、限制每人在活动内发放10次 ）
            value: count,
        },
        {
            conditionId: 24, //累积周期(天)（ 单次奖励时值是1、 累计奖励时是1、范围奖励时是1）
            value: 1,
        },
        {
            conditionId: 26, // 奖励追回（0-退款后奖励回收、1-退款后奖励不回收）
            value: baseInfo.recover,
        },
        {
            conditionId: 27, //活动专柜（0-全部专柜参与 1-指定专柜参与 2-指定专柜不参与 ）
            value: baseInfo.selectMode,
        },
        {
            conditionId: 30,
            value: 'payAward',
        },
    ];
    if (baseInfo.reward.type === PayWithGiftConsumptionType.ConsumptionWithinTheScope) {
        conditionList.push({
            conditionId: 31,
            value: baseInfo.reward.secordType,
        });
    }
    if (baseInfo.range.length) {
        baseInfo.range.forEach((v: string) => {
            conditionList.push({
                conditionId: 18, //订单范围(有两条数据0和1 表示 线上和线下 0-线下 1-线上)
                value: v,
            });
        });
    }
    return conditionList;
}

function findValueById(id: number, list: any[]) {
    const item = list.find((i) => i.conditionId === id);
    return item ? item.value : null;
}
