import React from 'react';
import classNames from 'classnames';
import { isNull, isUndefined, cloneDeep, isString } from 'lodash';
import { tools } from '@comall-backend-builder/design-components-basis';
import { Checkbox } from 'antd';

// 暂时借用可视化的选择器可以实现当前需求，当后续无法满足需求时需自行设计
import { customSelectorConfig } from '../../applications/design/design';

import {
    LinkTypeEffectPathConfig,
    linkTypeEffectPathConfigList,
    getNewLinkTypeEffectPathConfigList,
} from './config';

import './index.less';
import { services } from '@comall-backend-builder/core';

export interface LinkTypeEffectPathValue {
    linkType: string;
    linkParams: any;
    linkName: string;
    linkPath: string;
    needUrlLink: boolean;
}

export interface LinkTypeEffectPathProps {
    name: string;
    value?: LinkTypeEffectPathValue;
    disabled?: boolean;
    row: any;
    applicationVersion?: string;
    onChange?(value: LinkTypeEffectPathValue, name: string): void;
    hideNeedUrlLink?: boolean;
    needUrlLinkExplain?: string;
    bussinessType?: string;
}

const DEFAULT_VALUE = {
    linkType: '',
    linkParams: null,
    linkName: '',
    linkPath: '',
    needUrlLink: false,
};

const selectorConfig = cloneDeep({ ...customSelectorConfig }) as any;

export const LinkTypeEffectPath: React.FC<LinkTypeEffectPathProps> = (props) => {
    const {
        value,
        name,
        disabled,
        row,
        applicationVersion,
        onChange,
        hideNeedUrlLink,
        needUrlLinkExplain = services.language.getText('linkTypeShort'),
        bussinessType = '',
    } = props;
    const current = value || DEFAULT_VALUE;
    const isApplicationVersionV2 = applicationVersion === 'V2';
    if (isApplicationVersionV2 && current.linkType === 'topic') {
        // topic 老saas专题页，
        // newTopic 新saas专题页
        // 兼容老Saas专题展示
        current.linkType = 'newTopic';
    }
    let linkTypes = isApplicationVersionV2
        ? getNewLinkTypeEffectPathConfigList()
        : linkTypeEffectPathConfigList;

    // 渠道二维码增加过滤条件
    if (bussinessType === 'miniprogram-v2') {
        const disableLinkTypesByTypeMap = {
            'miniprogram-v2': ['none'],
        };
        linkTypes = linkTypes.filter((linkType) => {
            const disableLinkTypes = disableLinkTypesByTypeMap[bussinessType];
            return !disableLinkTypes.includes(linkType.id);
        });
    }
    function transformParams(linkParams: any, paramsHandler?: Function): any {
        if (!linkParams || !paramsHandler) {
            return linkParams;
        }

        return paramsHandler(linkParams);
    }

    function linkNameGen(name: string, linkParams: any): string {
        return [name, linkParams && linkParams.name].filter(Boolean).join('-');
    }

    function linkPathGen(basePath: string, params?: string | string[], paramsObj?: any): string {
        if (!params || !paramsObj) {
            return basePath;
        }

        const paramsStr = [params].flat(3).reduce((result, key) => {
            const value = paramsObj[key];
            if (isNull(value) || isUndefined(value) || (isString(value) && value.length === 0)) {
                return result;
            }
            return `${result}&${key}=${value}`;
        }, '');

        if (!paramsStr) {
            return basePath;
        }

        return /\?/.test(basePath)
            ? `${basePath}${paramsStr}`
            : `${basePath}?${paramsStr.slice(1)}`;
    }

    function tryInjectParentRow(id: string): void {
        const selector = selectorConfig[id];

        if (!selector) {
            return;
        }

        const contentConfig = (selector.contentConfig = selector.contentConfig || {});

        Object.assign(contentConfig, {
            parentRow: row,
        });
    }
    const findLinkTypeById = (
        id: string,
        linkTypes: LinkTypeEffectPathConfig[]
    ): LinkTypeEffectPathConfig | undefined => {
        let config;
        linkTypes.forEach((linkType) => {
            if (!linkType.children && linkType.id === id) {
                config = linkType;
            }
            if (linkType.children && linkType.children.length > 0) {
                linkType.children.forEach((it) => {
                    if (it.id === id) {
                        config = it;
                    }
                });
            }
        });
        return config;
    };
    function handleChange(value: LinkTypeEffectPathValue): void {
        if (disabled) {
            return;
        }
        const config = findLinkTypeById(value.linkType, linkTypes);
        if (!config) {
            onChange && onChange(DEFAULT_VALUE, name);

            return;
        }

        const paramsObj = transformParams(value.linkParams, config.paramsHandler);

        Object.assign(value, {
            linkName: linkNameGen(config.name, value.linkParams),
            linkPath: linkPathGen(config.basePath, config.params, paramsObj),
        });

        tryInjectParentRow(config.id);
        onChange && onChange(value, name);
    }

    function changeNeedUrlLink(): void {
        if (disabled) {
            return;
        }
        Object.assign(current, {
            needUrlLink: !current.needUrlLink,
        });
        onChange && onChange(current, name);
    }
    const showUrlLink = !hideNeedUrlLink && row.statisticType !== 'MERCHANT';
    return (
        <div
            className={classNames({
                'link-type-effect-path': true,
                'link-type-effect-path--disabled': disabled,
            })}
        >
            <tools.LinkType
                selector={selectorConfig}
                linkTypes={linkTypes}
                linkTypeMode={'cascader'}
                value={current}
                onChange={handleChange}
            />
            {showUrlLink && (
                <Checkbox onChange={changeNeedUrlLink} checked={current.needUrlLink}>
                    {needUrlLinkExplain}
                </Checkbox>
            )}
        </div>
    );
};
// /**
//  * 为了解决ObjectMode 给实体属性设置defaultValue无效
//  * @param props
//  */
// const HocLinkType = (props: any) => {
//     const newProps = { ...props };
//     newProps.value = props.value || { linkType: 'none' };
//     return (
//         <tools.LinkType
//             selector={props.selector}
//             linkTypeMode={'cascader'}
//             linkTypes={props.linkTypes}
//             {...newProps}
//         />
//     );
// };
