import React, { PureComponent } from 'react';
import { Input } from 'antd';
import { services, Loader } from '@comall-backend-builder/core';

import { debounce } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';
export class ActivityCreatorPhoneInput extends PureComponent<any> {
    checkPhone: any;
    constructor(props: any) {
        super(props);
        this.checkPhone = debounce(this.check, 500);
    }
    state = {
        value: '',
        hasError: false,
    };
    onInput = (e: any) => {
        const { onChange } = this.props;
        const value = e.target.value;
        this.setState({
            value,
        });
        if (value && value.trim()) {
            this.checkPhone();
        }
        onChange && onChange(e.target.value.trim());
    };
    check = () => {
        const val = this.state.value.trim();
        if (!val) return;
        Loader.load('get', {
            apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
            apiPath: `/admin/members/mobile/${val}`,
        })
            .then((res: any) => {
                if (res) {
                    this.setState({ hasError: false });
                } else {
                    this.setState({ hasError: true });
                }
            })
            .catch(services.errorHandle);
    };
    render() {
        const { hasError, value } = this.state;
        return (
            <div>
                <div>
                    <Input onInput={this.onInput} {...this.props}></Input>
                    {!!(hasError && value) && (
                        <span style={{ color: '#f5222d', marginLeft: '5px' }}>
                            {language.getText('gsjhmyzc')}
                        </span>
                    )}
                </div>
            </div>
        );
    }
}
