import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { remove, get, map } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Modal, message } from 'antd';
import { ImportProductsModal } from './import-products-modal';
import { ProductSelector } from '../product-selector';
import { language } from '@comall-backend-builder/core/lib/services';

const TableComponentId = 'ProductsSelectorDataTable';
let Entity: any = {};
let productsEntity: any = {};

export enum ProductsSelectType {
    /**
     * 指定商品
     */
    APPOINT_PRODUCT = 'APPOINT_PRODUCT',
    /**
     * 导入商品
     */
    IMPORT = 'IMPORT',
}

export interface ProductImportConfig {
    apiRoot: string;
    apiPath: string;
    activityType?: string;
    importFileSource: {
        apiRoot: string;
        apiPath: string;
    };
    templateSource: {
        apiRoot: string;
        apiPath: string;
    };
}

/**
 * 选择商品，导入商品
 */
class productsSelectorPlus extends PureComponent<{
    onChange: (values: any[]) => void;
    onImport: (data: any) => void;
    params?: any;
    buttonText?: string;
    requestStatus: string;
    rowSelectionType?: string;
    needSubsites?: boolean;
    filterFields?: any;
    selectTypes?: ProductsSelectType[];
    importConfig?: ProductImportConfig;
    activityId: string | null;
}> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('CustomDesignProductStyleSelector');
        productsEntity = new Entity({});
    }
    dispatch: any = {};
    state = {
        showSelectModal: false,
        showImportModal: false,
    };

    selectedRows: any[] = [];

    onTableRowSelect = (record: any, selected: boolean) => {
        const { rowSelectionType } = this.props;
        if (rowSelectionType === 'radio') {
            this.selectedRows = [record];
        } else {
            if (selected) {
                this.selectedRows.push(record);
            } else {
                remove(this.selectedRows, (i) => {
                    return i.id === record.id;
                });
            }
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: any[]) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            this.selectedRows = [];
        }
    };

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.requestStatus === 'success' && this.props.requestStatus === 'pending') {
            const selectedRows = this.selectedRows;
            if (selectedRows && selectedRows.length > 0)
                setTimeout(() => {
                    this.dispatch(
                        actions.tableRowSelectionChangeAction(
                            TableComponentId,
                            map(selectedRows, 'id')
                        )
                    );
                }, 300);
        }
    }

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(productsEntity));
    }

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
    };

    onOk = () => {
        const { onChange } = this.props;
        if (onChange) {
            onChange(this.selectedRows);
            this.hideModal();
        }
    };

    onImportSuccess = (data: any) => {
        const { onImport } = this.props;
        if (onImport) {
            onImport(data);
            this.hideModal();
        }
    };

    getProdutsConfig = () => {
        let { params, rowSelectionType } = this.props;
        if (!rowSelectionType) {
            rowSelectionType = 'checkbox';
        }
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: productsEntity,
            params: params || {},
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'right' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        productsEntity.pageChange(
                            Object.assign({}, params, productsEntity.paging, { page: 1 })
                        );
                        productsEntity.search(params);
                    },
                    fields: [
                        {
                            property: 'activityProductTypes',
                        },
                        {
                            property: 'code',
                        },
                        {
                            property: 'name',
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,

                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(language.getText('total'), {
                                total,
                            });
                        },
                    },
                    rowSelection: {
                        type: rowSelectionType,
                        onSelect: (record: any, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: any) => {
                            this.onTableRowSelectAll(selected, rows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    columns: [
                        {
                            property: 'name',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                width: 400,
                                style: {
                                    width: 400,
                                    maxWidth: 400,
                                },
                            },
                        },
                        {
                            title: services.language.getText('productCode'),
                            property: 'barcode',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                width: 180,
                                style: {
                                    width: 180,
                                    maxWidth: 180,
                                },
                            },
                        },
                        {
                            title: language.getText('productType'),
                            property: 'productType',
                        },
                    ],
                },
            ],
        };
    };

    showModal = (type: ProductsSelectType) => {
        const { params } = this.props;
        const { subsiteIds } = params;
        if (!subsiteIds || subsiteIds.length === 0) {
            message.warning(language.getText('selectBeforeMerchant'));
            return;
        }
        if (type === ProductsSelectType.APPOINT_PRODUCT) {
            this.setState({ showSelectModal: true }, () => {
                if (productsEntity.paging) {
                    productsEntity.search({
                        ...productsEntity.paging,
                        ...params,
                    });
                }
            });
        } else {
            this.setState({ showImportModal: true });
        }
    };

    hideModal = () => {
        this.setState({ showSelectModal: false, showImportModal: false });
    };

    render() {
        const productsConfig = this.getProdutsConfig();
        const productsContent = createElement(
            ComponentsManager.get(productsConfig.component),
            productsConfig
        );

        const modalProps = {
            width: 1000,
            title: language.getText('productSelectRequired'),
            visible: this.state.showSelectModal,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.hideModal,
        };
        const {
            buttonText = language.getText('components.Tools.selectProduct'),
            selectTypes,
            importConfig,
            activityId,
            params,
            needSubsites,
            filterFields,
            onChange,
        } = this.props;
        return (
            <div style={{ display: 'inline-block' }}>
                {(!selectTypes ||
                    selectTypes.findIndex((type) => type === ProductsSelectType.APPOINT_PRODUCT) !==
                        -1) && (
                    <ProductSelector
                        buttonText={buttonText}
                        params={params}
                        onChange={onChange}
                        needSubsites={needSubsites}
                        filterFields={filterFields}
                    />
                )}
                {selectTypes &&
                    selectTypes.findIndex((type) => type === ProductsSelectType.IMPORT) !== -1 && (
                        <Button
                            type={'link'}
                            onClick={this.showModal.bind(this, ProductsSelectType.IMPORT)}
                        >
                            {language.getText('batchImport')}
                        </Button>
                    )}
                <Modal {...modalProps}>
                    <div style={{ padding: '0 20px', width: '100%' }}>{productsContent}</div>
                </Modal>
                {importConfig && (
                    <ImportProductsModal
                        closeModal={this.hideModal}
                        onImportSuccess={this.onImportSuccess}
                        visible={this.state.showImportModal}
                        importConfig={importConfig}
                        activityId={activityId}
                        params={params}
                    />
                )}
            </div>
        );
    }
}

export const ProductsSelectorPlus = connect((_state: any) => {
    let requestStatus = get(productsEntity, 'requestStatus', null);
    const result = { requestStatus };
    return result;
})(productsSelectorPlus);
