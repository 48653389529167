import React, { PureComponent } from 'react';
import { Button, DatePicker, TimePicker } from 'antd';
import moment from 'moment';
import { map, get } from 'lodash';

import { PresaleSelectProduct } from '../../presale-select-product';

import './index.less';
import { services } from '@comall-backend-builder/core';

interface RegistrationTime {
    startDate?: string;
    drawTime?: string;
    drawDateTime?: string;
    quantity?: number;
    whiteListQuantity?: number;
    goods?: any;
}

const TIME_FORMAT = 'HH:mm';
const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

export interface MaotaiPresaleTimePickerProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 当前值
     */
    value: RegistrationTime[];
    /**
     * 禁用状态
     */
    disabled: boolean;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: RegistrationTime[], name: string) => void;
    roundType?: string;
    dateRange: { start: string; end: string };
    row?: any;
    pageType?: string;
}
export class MaotaiPresalePicker extends PureComponent<MaotaiPresaleTimePickerProps> {
    static defaultProps = {
        value: [],
    };

    componentDidMount() {
        const { value } = this.props;

        if (value.length === 0) {
            this.handleAdd();
        }
    }

    componentWillReceiveProps(nextProps: any) {
        const { roundType, onChange, name } = this.props;
        const { roundType: nextRoundType } = nextProps;
        if (roundType !== nextRoundType) {
            const newValue = [
                {
                    startDate: undefined,
                    startTime: undefined,
                    endTime: undefined,
                    quantity: undefined,
                },
            ];
            onChange(newValue, name);
        }
    }

    handleDrawDateTimeChange = (date: any, index: number) => {
        const { name, value, onChange } = this.props;
        const newValue = value.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    drawDateTime: moment(date || undefined).format(dateTimeFormat),
                };
            }
            return item;
        });
        onChange(newValue, name);
    };

    handleAdd = () => {
        const { name, value, onChange } = this.props;
        const newValue = [
            ...value,
            {
                startDate: undefined,
                startTime: undefined,
                endTime: undefined,
                quantity: undefined,
            },
        ];
        onChange && onChange(newValue, name);
    };

    handleDelete = (index: number) => {
        const { name, value, onChange } = this.props;
        const newValue = value.filter((_, i) => i !== index);
        onChange && onChange(newValue, name);
    };

    setSameData = () => {
        const { name, value, onChange } = this.props;
        const item = value[0];
        const { quantity, whiteListQuantity, drawTime, drawDateTime, goods } = item;
        const newValue = value.map((item, i) => {
            if (i !== 0) {
                return {
                    ...item,
                    drawTime,
                    drawDateTime,
                    quantity,
                    whiteListQuantity,
                    goods,
                };
            }
            return item;
        });
        onChange(newValue, name);
    };
    onGoodsChange = (goods: any, index: number) => {
        const { name, value, onChange } = this.props;
        const newValue = value.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    goods,
                };
            }
            return item;
        });
        onChange(newValue, name);
    };
    handleTimeChange = (time: any, index: number) => {
        const { name, value, onChange } = this.props;
        const newValue = value.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    drawTime: time,
                };
            }
            return item;
        });

        onChange(newValue, name);
    };

    renderItem = (item: RegistrationTime, index: number) => {
        const { value, roundType, row, pageType } = this.props;
        const { drawDateTime, drawTime } = item;
        const onlyFirst = index === 0 && value.length === 1;
        const last = value.length - 1 === index;
        const mode = get(row, 'activityInfo.mode');
        const selectionType = mode === 'SINGLE' ? 'radio' : 'checkbox';
        const drawDatePicker =
            roundType === 'ROUND' ? (
                <TimePicker
                    allowClear={false}
                    value={drawTime ? moment(drawTime, TIME_FORMAT) : undefined}
                    format={TIME_FORMAT}
                    onChange={(value: any, strValue: string) =>
                        this.handleTimeChange(strValue, index)
                    }
                />
            ) : (
                <DatePicker
                    value={drawDateTime ? moment(drawDateTime, dateTimeFormat) : undefined}
                    onChange={(value: any) => this.handleDrawDateTimeChange(value, index)}
                    showTime
                    format={dateTimeFormat}
                />
            );

        const deleteButton = (
            <Button type="link" style={{ paddingLeft: 0 }} onClick={() => this.handleDelete(index)}>
                {services.language.getText('common.delete')}
            </Button>
        );

        const addButton = (
            <Button type="default" className="mrp-10" onClick={this.handleAdd}>
                {services.language.getText('tjcc')}
            </Button>
        );

        return (
            <div className="maotai-presale-container" key={index}>
                <div className="maotai-presale-content">
                    <div className="maotai-presale-line">
                        {index === 0 && value.length !== 1 && (
                            <Button
                                onClick={this.setSameData}
                                style={{ paddingLeft: 0 }}
                                type="link"
                            >
                                {services.language.getText('szsyccsjdhbtsjxt')}
                            </Button>
                        )}
                        <span>{!onlyFirst && deleteButton}</span>
                    </div>
                    <div className="maotai-presale-line">
                        <span className="maotai-presale-line">
                            <span className="maotai-presale-require">*</span>每场申购时间：
                            {drawDatePicker}
                        </span>
                    </div>
                    <div>
                        <PresaleSelectProduct
                            row={this.props.row}
                            pageType={pageType}
                            selectionType={selectionType}
                            value={item.goods}
                            onChange={(goods) => {
                                this.onGoodsChange(goods, index);
                            }}
                            needSubsites
                            buttonTitle={services.language.getText('xzsp_1')}
                            showMaotaiPreSaleInput
                        />
                    </div>
                    <div className="maotai-presale-line">
                        {<span>{roundType !== 'ROUND' && last && addButton}</span>}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { value } = this.props;

        return (
            <div className="maotai-presale-components">
                <div>{map(value, this.renderItem)}</div>
            </div>
        );
    }
}
