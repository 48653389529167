import React, { PureComponent } from 'react';
import { Button, Modal, Table } from 'antd';
import { services } from '@comall-backend-builder/core';
import { errorHandle } from '../../applications/cae/services/error-handle';

import './index.less';

const { api } = services;
/**
 * 预售管理 - 预售活动 - 列表活动数据按钮
 */
export class CgbManageDataStatistics extends PureComponent<
    any,
    { showModal: boolean; statisticData: any[]; loading: boolean }
> {
    state = { showModal: false, statisticData: [], loading: false };

    tableConfig = {
        rowKey: 'storeName',
        columns: [
            {
                title: services.language.getText('subsiteName'),
                dataIndex: 'subsiteName',
                key: 'subsiteName',
            },
            {
                title: '购买人数',
                dataIndex: 'buyerCount',
                key: 'buyerCount',
                align: 'center' as const,
            },
            {
                title: '购买件数',
                dataIndex: 'soldCount',
                key: 'soldCount',
                align: 'center' as const,
            },
        ],
        pagination: { pageSize: 6 },
    };

    toggleModal = () => () => {
        const { showModal } = this.state;
        if (showModal === false) {
            this.loadData();
        }
        this.setState({ showModal: !showModal });
    };

    loadData = () => {
        const { row } = this.props;
        const id = row.id;
        this.setState({ loading: true });
        api.get(
            { id: id },
            {
                apiPath: '/admin/presales/:id/sales_statistics',
                apiRoot: ENV.API_ROOT.replace('/WEB-API', '/CYBERSHOP-PRESALE'),
            }
        )
            .then((res) => {
                const data = res as any[];
                this.setState({ statisticData: data });
            }, errorHandle)
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    render() {
        const { showModal, statisticData, loading } = this.state;
        const { row } = this.props;
        const activityName = row ? row.baseInfo.name : '';

        return (
            <div className="cgb-manage-data-statistics">
                <Button type="link" className="btn-data-statistics" onClick={this.toggleModal()}>
                    活动数据
                </Button>
                <Modal
                    onCancel={this.toggleModal()}
                    footer={[
                        <Button type="primary" onClick={this.toggleModal()}>
                            确定
                        </Button>,
                    ]}
                    visible={showModal}
                    title={`「${activityName}」活动数据`}
                >
                    <Table
                        loading={loading}
                        dataSource={statisticData}
                        {...this.tableConfig}
                    ></Table>
                </Modal>
            </div>
        );
    }
}
