import React, { PureComponent, Fragment } from 'react';
import { isEmpty } from 'lodash';
import { message as AntMessage, Modal as AntModal } from 'antd';
import { api } from '@comall-backend-builder/core/lib/services';
import { PrintTicket } from '../../../components/order-picking-assistant/print';
import { GetPrintOrders } from '../../../services/order-print';
import { PickOrdersTemplate } from '../../../components';
import { services } from '@comall-backend-builder/core';

/**
 * 拣货小助手--待接单--批量打印拣货单
 */
export class BatchPrintPickOrder extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            pickingListTemplate: null,
            loading: false,
        };
        this.setReceiptRef = this.setReceiptRef.bind(this);
    }

    ReceiptRef = null;

    setReceiptRef(ref: any) {
        this.ReceiptRef = ref;
    }

    changeStatusAndPrintPickingList = (ref: any) => {
        const _this = this;
        const {
            parentProps: { entity, refresh },
        } = _this.props;
        const { selectedRowKeys } = this.props;
        if (isEmpty(selectedRowKeys)) {
            AntMessage.warning(services.language.getText('qxzjhd'));
            return;
        }
        AntModal.confirm({
            title: services.language.getText('sfkspljhyxjhd'),
            okText: services.language.getText('common.confirm'),
            cancelText: services.language.getText('common.cancel'),
            onOk: () => {
                this.setState({ loading: true });
                this.PickingOrder(selectedRowKeys).then(() => {
                    GetPrintOrders(selectedRowKeys).then((data) => {
                        _this.setState({ ReceiptData: data, loading: false }, () => {
                            const ref: any = _this.ReceiptRef;
                            ref && ref.onPrint();
                            entity.search();
                            refresh();
                        });
                    });
                });
            },
        });
    };

    PickingOrder(ids: number[]) {
        return api.put(
            { ids },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PICKING`,
                apiPath: `/admin/picking_lists/batch_picking`,
            }
        );
    }

    render() {
        const { loading = false } = this.state;
        const printProps = {
            button: {
                text: services.language.getText('plksjhbdy'),
                type: 'primary',
                loading,
            },
            click: this.changeStatusAndPrintPickingList,
            template: this.state.pickingListTemplate,
        };
        const { entity } = this.props.parentProps;
        return entity.result && !isEmpty(entity.result) ? (
            <Fragment>
                <PickOrdersTemplate
                    setWrappedInstance={this.setReceiptRef}
                    data={this.state.ReceiptData}
                />
                <PrintTicket {...printProps} />
            </Fragment>
        ) : (
            <PickOrdersTemplate
                setWrappedInstance={this.setReceiptRef}
                data={this.state.ReceiptData}
            />
        );
    }
}
