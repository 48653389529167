import React, { Component } from 'react';
import { Input, message as AntMessage, Modal } from 'antd';
import { LinkTypePlus } from '../../../../applications/design/components';
import { newClientCustomSelectorConfig } from '../../../../applications/design/design';
import {
    LinkTypeEffectPathConfig,
    getNewLinkTypeEffectPathConfigList,
} from '../../../../components/link-type-effect-path/config';
import { linkNameGen, linkPathGen, transformParams } from '../../../miniprogram-v2';
import { cloneDeep } from 'lodash';
import { checkLinkTypeParamsRequired } from '../../../../types/format';
import { WechatImage, WechatMiniprogrampage } from '../../components/wechant-content';
import { WechatOfficialAutoReplySettingPageImageModal } from '../wechat-official-auto-reply-setting-page-image-modal';
import './index.less';
import { decodeURLToPath, encodePathToURL } from '../../utils';
import WechatOfficialReleatedSubsiteSelector from '../wechat-official-related-subsite-selector';
import { services } from '@comall-backend-builder/core';

interface Props {
    appId: string;
    visible: boolean;
    onConfrim: (value: WechatMiniprogrampage) => void;
    onCancel: () => void;
    editInfo?: WechatMiniprogrampage;
}

interface States {
    imageModalVisible: boolean;
    chooseInfo: WechatMiniprogrampage;
    linkTypes: LinkTypeEffectPathConfig[];
}

export class WechatOfficialAutoReplySettingPageMiniprogrampageModalVisibleModal extends Component<
    Props,
    States
> {
    constructor(props: any) {
        super(props);
        this.state = {
            imageModalVisible: false,
            chooseInfo: {
                title: '',
                appid: '{miniprogram-appid}',
                pagepath: '',
                target: {
                    linkType: '',
                    linkParams: '',
                    linkName: '',
                    linkPath: '',
                },
            },
            linkTypes: getNewLinkTypeEffectPathConfigList(),
        };
    }

    componentDidMount() {
        //编辑回显
        const { editInfo } = this.props;
        if (editInfo) {
            this.setState({
                chooseInfo: cloneDeep({
                    ...editInfo,
                    pagepath: decodeURLToPath(editInfo.pagepath),
                }),
            });
        }
    }

    findLinkTypeById = (
        id: string,
        linkTypes: LinkTypeEffectPathConfig[]
    ): LinkTypeEffectPathConfig | undefined => {
        let config;
        linkTypes.forEach((linkType) => {
            if (!linkType.children && linkType.id === id) {
                config = linkType;
            }
            if (linkType.children && linkType.children.length > 0) {
                linkType.children.forEach((it) => {
                    if (it.id === id) {
                        config = it;
                    }
                });
            }
        });
        return config;
    };

    changeTargetValue = (val: any) => {
        const { chooseInfo, linkTypes } = this.state;
        const config = this.findLinkTypeById(val.linkType, linkTypes);
        if (config) {
            const valObj = { ...val };
            const paramsObj = transformParams(val.linkParams, config.paramsHandler);
            Object.assign(valObj, {
                linkName: linkNameGen(config.name, valObj.linkParams),
                linkPath: linkPathGen(config.basePath, config.params, paramsObj),
            });
            chooseInfo.target = valObj;
            chooseInfo.pagepath = valObj.linkPath;
            this.setState({
                chooseInfo,
            });
        }
    };

    renderLink = () => {
        const { chooseInfo, linkTypes: allLinkTypes } = this.state;
        const linkTypes = allLinkTypes.filter(
            (item: any) => item.id !== 'none' && item.id !== 'miniprogram' && item.id !== 'urlLink'
        );
        const subsiteIds = chooseInfo.target.subsiteId
            ? [String(chooseInfo.target.subsiteId)]
            : undefined;
        const linkTypeSelector = newClientCustomSelectorConfig;
        if (!subsiteIds) {
            return (
                <div className="right-link">
                    <span>{services.language.getText('ljlx')}：</span>
                    <span className="right-hint">
                        {services.language.getText('selectBeforeMerchant')}
                    </span>
                </div>
            );
        }
        return (
            <div className="right-link">
                <LinkTypePlus
                    selector={linkTypeSelector as any}
                    linkTypes={linkTypes}
                    linkTypeMode="cascader"
                    value={chooseInfo.target}
                    onChange={(val) => this.changeTargetValue(val)}
                    subsiteIds={subsiteIds}
                />
            </div>
        );
    };

    onTextChange = (e: any) => {
        const { chooseInfo } = this.state;
        chooseInfo.title = e.target.value;
        this.setState({
            chooseInfo,
        });
    };

    hardelImageModalVisible = () => {
        const { imageModalVisible } = this.state;
        this.setState({
            imageModalVisible: !imageModalVisible,
        });
    };

    imageModalConfrim = (wechatImage: WechatImage) => {
        const { chooseInfo } = this.state;
        chooseInfo.thumbMediaId = wechatImage.mediaId;
        chooseInfo.thumbMediaInfo = wechatImage;
        this.setState({
            chooseInfo,
        });
        this.hardelImageModalVisible();
    };

    OK = () => {
        const { onConfrim } = this.props;
        const { chooseInfo } = this.state;
        if (!chooseInfo.thumbMediaId) {
            AntMessage.warning(services.language.getText('qxztp'));
            return false;
        }
        if (!chooseInfo.target.subsiteId) {
            AntMessage.warning(services.language.getText('selectSubsite'));
            return false;
        }
        if (!chooseInfo.title) {
            AntMessage.warning(services.language.getText('qsrxcxbt'));
            return false;
        }
        if (!chooseInfo.target || !chooseInfo.target.linkType) {
            AntMessage.warning(services.language.getText('qxzljlx'));
            return false;
        }
        if (
            chooseInfo.target &&
            !checkLinkTypeParamsRequired(chooseInfo.target.linkType, chooseInfo.target.linkParams)
        ) {
            AntMessage.warning(services.language.getText('selectLinkTarget'));
            return false;
        }
        onConfrim({
            ...chooseInfo,
            pagepath: encodePathToURL(chooseInfo.pagepath, chooseInfo.target.subsiteId),
        });
    };

    onDelete = () => {
        const { chooseInfo } = this.state;
        chooseInfo.thumbMediaId = undefined;
        chooseInfo.thumbMediaInfo = undefined;
        this.setState({
            chooseInfo,
        });
    };

    private onSubsiteChange = (subsite: { id: number; name: string }) => {
        const { chooseInfo } = this.state;
        this.setState({
            chooseInfo: {
                ...chooseInfo,
                target: {
                    ...chooseInfo.target,
                    subsiteId: subsite.id,
                    subsiteName: subsite.name,
                },
            },
        });
    };

    render() {
        const { visible, onCancel, appId } = this.props;
        const { chooseInfo, imageModalVisible } = this.state;
        const thumbMediaInfoUrl =
            chooseInfo.thumbMediaInfo && chooseInfo.thumbMediaInfo.url
                ? chooseInfo.thumbMediaInfo.url
                : require('../images/icon-background.png');
        const deleteImage = require('../images/icon-delete.png');
        const logoImage = require('../images/icon-logo.png');
        const miniImage = require('../images/icon-mini.png');
        const subsite =
            (chooseInfo.target.subsiteId &&
                chooseInfo.target.subsiteName && {
                    id: chooseInfo.target.subsiteId,
                    name: chooseInfo.target.subsiteName,
                }) ||
            undefined;
        return (
            <Modal
                visible={visible}
                width={690}
                title={services.language.getText('xcxkp')}
                onCancel={onCancel}
                onOk={this.OK}
            >
                <div className="wechat-official-auto-reply-setting-page-miniprogrampage-modal">
                    <div className="left">
                        <div className="left-wrap">
                            <div>
                                <img width={18} height={18} alt="" src={logoImage} />
                                &nbsp;&nbsp;{services.language.getText('xcx')}
                            </div>
                            <div className="title">
                                <span>
                                    {chooseInfo.title
                                        ? chooseInfo.title
                                        : services.language.getText('xcxbt')}
                                </span>
                            </div>
                            <div className="img-wrap">
                                <div className="img-wrap-background">
                                    <div
                                        style={{
                                            width: '210px',
                                            height: '171px',
                                            backgroundSize: 'cover',
                                            backgroundImage: 'url(' + thumbMediaInfoUrl + ')',
                                        }}
                                    ></div>
                                </div>
                                {thumbMediaInfoUrl && (
                                    <div className="img-wrap-delete" onClick={this.onDelete}>
                                        <img width={20} height={20} alt="" src={deleteImage} />
                                    </div>
                                )}
                                <div
                                    className="img-wrap-button"
                                    onClick={this.hardelImageModalVisible}
                                >
                                    {thumbMediaInfoUrl
                                        ? services.language.getText('ghtp')
                                        : services.language.getText('qsc')}
                                </div>
                            </div>
                            <div className="miniprogrampage-left-footer">
                                <img width={10} height={10} alt="" src={miniImage} />
                            </div>
                        </div>
                        <div className="left-tip">{services.language.getText('fmtpjycc')}：</div>
                    </div>
                    <div className="right">
                        <div className="right-title" style={{ marginBottom: 20, marginLeft: 14 }}>
                            <div>{services.language.getText('otherSelectSubsite')}：</div>
                            <WechatOfficialReleatedSubsiteSelector
                                appId={appId}
                                onChange={this.onSubsiteChange}
                                subsite={subsite}
                            />
                        </div>
                        <div className="right-title">
                            <div>{services.language.getText('xcxbt')}：</div>
                            <Input
                                className="right-input"
                                required
                                placeholder={services.language.getText('qsrxcxbt')}
                                value={chooseInfo.title}
                                onChange={this.onTextChange}
                                maxLength={35}
                            />
                        </div>
                        {this.renderLink()}
                    </div>
                    {imageModalVisible && (
                        <WechatOfficialAutoReplySettingPageImageModal
                            appId={appId}
                            visible={imageModalVisible}
                            onConfrim={this.imageModalConfrim}
                            onCancel={this.hardelImageModalVisible}
                        />
                    )}
                </div>
            </Modal>
        );
    }
}
