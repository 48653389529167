import React, { Component } from 'react';
import { Button, Modal, Input, Table } from 'antd';
import { services } from '@comall-backend-builder/core';
import { map } from 'lodash';
import { CrowdItem } from './giving-coupon-crowds';
import { TextTips } from '../../../../components/text-tips';
import { CrowdsSelectedDisplay } from './crowds-selected-display';
import './index.less';

const { api } = services;

interface Props {
    disabled: boolean;
    subsiteId: string;
    value: CrowdItem[];
    onChange: (crowds: CrowdItem[]) => void;
}
interface State {
    visible: boolean;
    name: string;
    total: number;
    pagination: {
        page: number;
        perPage: number;
    };
    loading: boolean;
    datasource: CrowdItem[];
    selectedRows: CrowdItem[];
}

export class CrowdsSelector extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
            name: '',
            total: 0,
            pagination: {
                page: 1,
                perPage: 10,
            },
            loading: false,
            datasource: [],
            selectedRows: [],
        };
    }

    onOk = () => {
        const { selectedRows } = this.state;
        this.props.onChange(selectedRows);
        this.toggleModal();
    };
    toggleModal = () => {
        const { visible } = this.state;

        this.setState({ visible: !visible }, () => {
            if (visible) {
                this.setState({
                    datasource: [],
                    pagination: {
                        page: 1,
                        perPage: 10,
                    },
                    name: '',
                });
            } else {
                this.setState({
                    selectedRows: this.props.value,
                });
                this.loadData();
            }
        });
    };
    onChangeNameFilter = (e: any) => {
        const { value } = e.target;
        this.setState({
            name: value,
        });
    };
    loadData = async () => {
        const { name, pagination } = this.state;
        const { subsiteId } = this.props;
        try {
            const res: any = await api.get(
                {
                    ...pagination,
                    crowdName: name || undefined,
                    subsiteId,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                    apiPath: '/admin/crowds',
                }
            );
            this.setState({
                datasource: res.result,
                total: res.totalNum,
                loading: false,
            });
        } catch (error) {
            services.errorHandle(error as any);
        }
    };
    onSearch = () => {
        this.setState(
            {
                pagination: {
                    page: 1,
                    perPage: 10,
                },
            },
            this.loadData
        );
    };
    onReset = () => {
        this.setState({
            name: '',
        });
    };
    pageChange = (pageVal: any) => {
        this.setState({ pagination: { ...pageVal } }, () => {
            this.loadData();
        });
    };

    renderModalContent = () => {
        const { name, loading, pagination, total, datasource, selectedRows } = this.state;
        return (
            <div className={prefix}>
                <div className={`${prefix}__filter`}>
                    <span>人群名称：</span>
                    <Input
                        placeholder={services.language.getText('inputPlease')}
                        onChange={this.onChangeNameFilter}
                        value={name}
                        style={{ width: 200, marginRight: 10 }}
                    />
                    <Button type="primary" style={{ marginRight: 10 }} onClick={this.onSearch}>
                        查询
                    </Button>
                    <Button onClick={this.onReset}>重置</Button>
                </div>
                <div className={`${prefix}__table`}>
                    <Table
                        rowSelection={{
                            type: 'radio',
                            selectedRowKeys: map(selectedRows, 'id'),
                            onSelect: (record: CrowdItem, selected: boolean) => {
                                if (selected) {
                                    this.setState({
                                        selectedRows: [record],
                                    });
                                } else {
                                    this.setState({
                                        selectedRows: [],
                                    });
                                }
                            },
                            onChange: () => {},
                        }}
                        rowKey={(record: any) => record.id}
                        loading={loading}
                        pagination={{
                            current: pagination.page,
                            pageSize: pagination.perPage,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            pageSizeOptions: ['10', '15', '20'],
                            total,
                            showTotal(total: number) {
                                return `共 ${total} 条记录`;
                            },
                            onChange: (page, perPage) => {
                                this.pageChange({ page, perPage });
                            },
                            onShowSizeChange: (page, perPage) => {
                                this.pageChange({ page, perPage });
                            },
                        }}
                        columns={[
                            {
                                title: services.language.getText('peopleName'),
                                dataIndex: 'crowdName',
                            },
                            {
                                title: services.language.getText('createType'),
                                dataIndex: 'creationMode',
                                render: (value: string) => {
                                    return value === 'RULE_MODE' ? '规则创建' : '导入指定用户';
                                },
                            },
                            {
                                title: '门店',
                                dataIndex: 'subsiteName',
                            },
                            {
                                title: (
                                    <TextTips
                                        text={services.language.getText('peopleNum')}
                                        tips={services.language.getText('limitPeopleCount')}
                                    />
                                ),
                                dataIndex: 'peopleNumber',
                            },
                        ]}
                        dataSource={datasource}
                    />
                </div>
            </div>
        );
    };

    render() {
        const { disabled, value } = this.props;
        const modalProps = {
            width: 900,
            title: '选择人群',
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };
        return (
            <div>
                {!value || value.length === 0 ? (
                    <Button type="link" disabled={disabled} onClick={this.toggleModal}>
                        选择人群
                    </Button>
                ) : null}
                {value && value.length ? (
                    <CrowdsSelectedDisplay
                        disabled={disabled}
                        data={value}
                        toggleModal={this.toggleModal}
                    />
                ) : null}
                <Modal {...modalProps}>{this.renderModalContent()}</Modal>
            </div>
        );
    }
}

const prefix = 'crowds-selector';
