import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { TableDataItem } from '../types/mode/array/array-card-level-items/card-level-items';

const api = services.api;

export const CardLevelTemplateLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`;
        config.apiPath = '/admin/card_levels';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/level_plans/${id}`;
        }
        return api.get(data, config).then((res: any) => {
            if (id) {
                res = processResult(res);
            }
            return res;
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`;
        config.apiPath = `/admin/level_plans/${id}`;
        const newParams = dataParser(params);
        newParams.id = id;
        return await api.put(newParams, config);
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`;
        config.apiPath = `/admin/level_plans`;
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
};

function processResult(data: any) {
    const cardLevelItems = (data.levelItems || []).map((item: any) => {
        const benefitInfos = (item.benefitInfos || []).map((benefit: any) => {
            return {
                id: benefit.id,
                context: benefit.description,
                iconUrl: benefit.iconUrl,
                iconPicture: [
                    {
                        id: 0,
                        path: benefit.iconUrl,
                    },
                ],
                name: benefit.title,
            };
        });
        const benefitData =
            item.description || benefitInfos.length
                ? {
                      description: item.description,
                      benefitInfos,
                  }
                : undefined;
        return {
            id: item.id,
            levelCode: item.levelCode,
            levelId: item.levelId,
            level: item.level,
            levelName: item.levelName,
            isDefault: item.isDefault,
            benefitData,
            styleIconUrl: item.styleIconUrl,
            styleBackgroundPictureUrl: item.styleBackgroundPictureUrl,
        };
    });
    data = {
        name: data.name,
        cardLevelItems,
    };
    return data;
}
/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {
        organizationType: 'TENANT',
        organizationId: 0,
        planType: 'TEMPLATE',
        scenario: 'TRADE',
        status: 'ENABLE',
    };
    if (params) {
        newParams.name = params.name;
        const cardLevelItems = params.cardLevelItems;
        const levelItems: any[] = [];
        if (cardLevelItems && cardLevelItems.length) {
            cardLevelItems.forEach((item: TableDataItem) => {
                const benefitInfos = (item?.benefitData?.benefitInfos || []).map((benefit: any) => {
                    return {
                        id: benefit.id,
                        description: benefit.context,
                        iconUrl: benefit.iconUrl,
                        title: benefit.name,
                    };
                });
                levelItems.push({
                    id: item.id,
                    isDefault: item.isDefault,
                    description: item?.benefitData?.description || '',
                    level: item.level,
                    levelCode: item.levelCode,
                    levelId: item.levelId,
                    levelName: item.levelName,
                    status: 'ENABLE',
                    styleIconUrl: item.styleIconUrl,
                    styleBackgroundPictureUrl: item.styleBackgroundPictureUrl,
                    benefitInfos,
                });
            });
        }
        newParams.levelItems = levelItems;
    }
    return newParams;
}
