import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { get, find, isNumber } from 'lodash';
import moment from 'moment';
import { MaotaiPresaleConditionsDefaultValue, MaotaiMessageTypes } from '../containers/';
import { ConditionType } from '../containers/maotai-presale/maotai-presale-conditions/conditonItem';
const api = services.api;

export const MaotaiPresaleForecastLoder: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`;
        config.apiPath = '/admin/statistics/forecast';

        if (get(data, 'dateRange.start') && get(data, 'dateRange.end')) {
            data.startTime = data.dateRange.start;
            data.endTime = data.dateRange.end;
            delete data.dateRange;
        }

        if (get(data, 'subsiteId.length')) {
            data.subsiteIds = data.subsiteId.map((i: any) => i.id).join(',');
            delete data.subsiteId;
        }

        return await api.get(data, config).then((res: any) => {
            res.result =
                res &&
                res.result.map((item: any) => {
                    const activityArr = item.activityTime ? item.activityTime.split('~') : [];
                    return {
                        ...item,
                        activityStartTime: activityArr[0],
                        activityEndTime: activityArr[1],
                    };
                });

            return res;
        });
    },
};
export const MaotaiPresaleLoder: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`;
        config.apiPath = '/admin/presale';

        if (data.id) {
            config.apiPath = `/admin/presale/${data.id}`;
        } else {
            if (get(data, 'dateRange.start') && get(data, 'dateRange.end')) {
                data.startTime = data.dateRange.start;
                data.endTime = data.dateRange.end;
                delete data.dateRange;
            }
        }

        return await api.get(data, config).then((res: any) => {
            if (!data.id) {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        //复制多门店下的链接
                        let urlLink = '';
                        item.subsites &&
                            item.subsites.forEach((subsites: any) => {
                                urlLink +=
                                    subsites.name +
                                    `：subpackages/maotai-pre-sale/pages/list/index?subsiteId=${subsites.id} `;
                            });
                        return {
                            ...item,
                            urlLink: urlLink,
                            needUrlLink: true,
                        };
                    });
            } else {
                try {
                    res.activityInfo = {
                        name: res.name,
                        code: res.code,
                        dateRange: { start: res.startTime, end: res.endTime },
                        subsites: res.susbiteVos,
                        mode: res.mode,
                        // goods: res.goodVos.map((i: any) => ({
                        //     ...i,
                        //     goodsStock: i.stock,
                        //     subsiteId: res.susbiteVos[0].id,
                        // })),
                        //description: res.description,
                    };

                    let rounds =
                        res.purchaseMode === 'DIFFERENT'
                            ? res.rounds.map((i: any) => {
                                  const item: any = {
                                      drawTime: i.drawTime,
                                      registrationStartTime: i.registrationStartTime,
                                      registrationEndTime: i.registrationEndTime,
                                      goodsVos: i.goodsVos,
                                  };
                                  if (i.id) {
                                      item.id = i.id;
                                  }
                                  return item;
                              })
                            : res.rounds.map((i: any) => {
                                  const startDate = i.drawTime.split(' ')[0];
                                  const drawTime = i.drawTime.split(' ')[1];

                                  const item: any =
                                      res.roundType === 'ROUND'
                                          ? {
                                                startDate,
                                                drawTime,
                                                goods: i.goodsVos.map((goods: any) => ({
                                                    ...goods,
                                                    id: goods.goodsId,
                                                    goodsStock: goods.stock,
                                                    subsiteId: res.susbiteVos[0].id,
                                                    maotaiWhiteListQuantity:
                                                        goods.whiteListQuantity,
                                                    maotaiQuantity: goods.quantity,
                                                })),
                                            }
                                          : {
                                                goods: i.goodsVos.map((goods: any) => ({
                                                    ...goods,
                                                    id: goods.goodsId,
                                                    goodsStock: goods.stock,
                                                    subsiteId: res.susbiteVos[0].id,
                                                    maotaiWhiteListQuantity:
                                                        goods.whiteListQuantity,
                                                    maotaiQuantity: goods.quantity,
                                                })),
                                                drawDateTime: i.drawTime,
                                            };
                                  if (i.id) {
                                      item.id = i.id;
                                  }
                                  return item;
                              });

                    if (res.roundType === 'ROUND' && res.purchaseMode !== 'DIFFERENT') {
                        rounds = [{ ...rounds[rounds.length - 1] }];
                    }

                    res.roundsInfo = {
                        purchaseMode: {
                            mode: res.purchaseMode,
                            rounds: res.purchaseMode === 'DIFFERENT' ? rounds : undefined,
                        },
                        purchaseTime: res.purchaseTime,
                        roundType: res.roundType,
                        rounds: rounds,
                        registerTimeRange: {
                            start: res.startRegisterTime,
                            end: res.endRegisterTime,
                        },
                    };

                    if (res.registrationConditions && res.registrationConditions.length) {
                        const registrationConditionsHash: any = {};
                        res.registrationConditions.forEach((item: any) => {
                            if (!registrationConditionsHash[item.conditionType]) {
                                registrationConditionsHash[item.conditionType] = item;
                            }
                        });

                        res.registrationConditionsInfo = {
                            conditions: [
                                {
                                    conditionType: ConditionType.REALNAME,
                                    checked: !!registrationConditionsHash[ConditionType.REALNAME],
                                },
                                {
                                    conditionType: ConditionType.AGE,
                                    checked: !!registrationConditionsHash[ConditionType.AGE],
                                    conditionValue: get(
                                        registrationConditionsHash[ConditionType.AGE],
                                        'conditionValue'
                                    )
                                        ? JSON.parse(
                                              registrationConditionsHash[ConditionType.AGE]
                                                  .conditionValue
                                          )
                                        : { value: 18 },
                                },
                                {
                                    conditionType: ConditionType.LEVEL,
                                    checked: !!registrationConditionsHash[ConditionType.LEVEL],
                                    conditionValue: get(
                                        registrationConditionsHash[ConditionType.LEVEL],
                                        'conditionValue'
                                    )
                                        ? JSON.parse(
                                              registrationConditionsHash[ConditionType.LEVEL]
                                                  .conditionValue
                                          )
                                        : { value: [] },
                                },
                                {
                                    conditionType: ConditionType.REGISTRATION_TIME,
                                    checked: !!registrationConditionsHash[
                                        ConditionType.REGISTRATION_TIME
                                    ],
                                    conditionValue: get(
                                        registrationConditionsHash[ConditionType.REGISTRATION_TIME],
                                        'conditionValue'
                                    )
                                        ? JSON.parse(
                                              registrationConditionsHash[
                                                  ConditionType.REGISTRATION_TIME
                                              ].conditionValue
                                          )
                                        : { timeType: 'STATIC' },
                                },
                                {
                                    conditionType: ConditionType.TOTAL_POINT,
                                    checked: !!registrationConditionsHash[
                                        ConditionType.TOTAL_POINT
                                    ],
                                    conditionValue: get(
                                        registrationConditionsHash[ConditionType.TOTAL_POINT],
                                        'conditionValue'
                                    )
                                        ? JSON.parse(
                                              registrationConditionsHash[ConditionType.TOTAL_POINT]
                                                  .conditionValue
                                          )
                                        : { timeType: 'CURRENT_MOUTH' },
                                },
                                {
                                    conditionType: ConditionType.SUPERMARKET_POINT,
                                    checked: !!registrationConditionsHash[
                                        ConditionType.SUPERMARKET_POINT
                                    ],
                                    conditionValue: get(
                                        registrationConditionsHash[ConditionType.SUPERMARKET_POINT],
                                        'conditionValue'
                                    )
                                        ? JSON.parse(
                                              registrationConditionsHash[
                                                  ConditionType.SUPERMARKET_POINT
                                              ].conditionValue
                                          )
                                        : { timeType: 'CURRENT_MOUTH' },
                                },
                                {
                                    conditionType: ConditionType.STORE_POINT,
                                    checked: !!registrationConditionsHash[
                                        ConditionType.STORE_POINT
                                    ],
                                    conditionValue: get(
                                        registrationConditionsHash[ConditionType.STORE_POINT],
                                        'conditionValue'
                                    )
                                        ? JSON.parse(
                                              registrationConditionsHash[ConditionType.STORE_POINT]
                                                  .conditionValue
                                          )
                                        : { timeType: 'CURRENT_MOUTH' },
                                },

                                {
                                    conditionType: ConditionType.CONSUME_AMOUNT,
                                    checked: !!registrationConditionsHash[
                                        ConditionType.CONSUME_AMOUNT
                                    ],
                                    conditionValue: get(
                                        registrationConditionsHash[ConditionType.CONSUME_AMOUNT],
                                        'conditionValue'
                                    )
                                        ? JSON.parse(
                                              registrationConditionsHash[
                                                  ConditionType.CONSUME_AMOUNT
                                              ].conditionValue
                                          )
                                        : { timeType: 'CURRENT_MOUTH' },
                                },
                                {
                                    conditionType: ConditionType.TRANSACTION_QUANTITY,
                                    checked: !!registrationConditionsHash[
                                        ConditionType.TRANSACTION_QUANTITY
                                    ],
                                    conditionValue: get(
                                        registrationConditionsHash[
                                            ConditionType.TRANSACTION_QUANTITY
                                        ],
                                        'conditionValue'
                                    )
                                        ? JSON.parse(
                                              registrationConditionsHash[
                                                  ConditionType.TRANSACTION_QUANTITY
                                              ].conditionValue
                                          )
                                        : { timeType: 'CURRENT_MOUTH' },
                                },
                                {
                                    conditionType: ConditionType.UNIT_PRICE,
                                    checked: !!registrationConditionsHash[ConditionType.UNIT_PRICE],
                                    conditionValue: get(
                                        registrationConditionsHash[ConditionType.UNIT_PRICE],
                                        'conditionValue'
                                    )
                                        ? JSON.parse(
                                              registrationConditionsHash[ConditionType.UNIT_PRICE]
                                                  .conditionValue
                                          )
                                        : { timeType: 'CURRENT_MOUTH' },
                                },
                                {
                                    conditionType: ConditionType.REGION,
                                    checked: !!registrationConditionsHash[ConditionType.REGION],
                                    conditionValue: get(
                                        registrationConditionsHash[ConditionType.REGION],
                                        'conditionValue'
                                    )
                                        ? JSON.parse(
                                              registrationConditionsHash[ConditionType.REGION]
                                                  .conditionValue
                                          )
                                        : {
                                              value: { regionIds: [], regionNames: [] },
                                          },
                                },
                            ],
                        };
                    } else {
                        res.registrationConditionsInfo = {
                            conditions: MaotaiPresaleConditionsDefaultValue,
                        };
                    }

                    res.prizeInfo = {
                        whiteQuantity: undefined,
                        prizeCondition: [],
                        prizeQualityLimit: [],
                        drawType: res.prizeRule.drawType,
                        drawQuantitys: [],
                    };

                    if (get(res, 'whiteQuantity')) {
                        const whiteQuantity = get(res, 'whiteQuantity.quantity');
                        res.prizeInfo.whiteQuantity = whiteQuantity;
                    }

                    if (get(res, 'prizeRule.lastOffDays')) {
                        res.prizeInfo.prizeCondition.push({
                            checked: true,
                            conditionType: 'CONSUME',
                            day: res.prizeRule.lastOffDays,
                        });
                    }
                    if (get(res, 'prizeRule.lastPrizeDays')) {
                        res.prizeInfo.prizeCondition.push({
                            checked: true,
                            conditionType: 'PRIZE',
                            day: res.prizeRule.lastPrizeDays,
                        });
                    }

                    if (
                        get(res, 'prizeRule.limitEndTime') &&
                        get(res, 'prizeRule.limitStartTime')
                    ) {
                        res.prizeInfo.prizeQualityLimit.push({
                            checked: true,
                            conditionType: 'LIMIT1',
                            dateRange: [
                                res.prizeRule.limitStartTime.split(' ')[0],
                                res.prizeRule.limitEndTime.split(' ')[0],
                            ],
                            num: res.prizeRule.limitQuantity,
                        });
                    }

                    // if (get(res, 'prizeRule.memberLimitQuantity')) {
                    //     // res.prizeInfo.prizeQualityLimit.push({
                    //     //     checked: true,
                    //     //     conditionType: 'LIMIT2',
                    //     //     num: res.prizeRule.memberLimitQuantity,
                    //     // });
                    //     res.prizeInfo.memberLimitQuantity = res.prizeRule.memberLimitQuantity;
                    // }

                    if (get(res, 'prizeQuantitys.length')) {
                        const rules = res.prizeQuantitys.map((i: any) => {
                            return {
                                num: i.quantity,
                                conditionRules: i.rules.map((j: any) => {
                                    return j.prizeConditions.map((k: any) => {
                                        return {
                                            conditionType: k.conditionType,
                                            conditionValue: k.conditionValue
                                                ? JSON.parse(k.conditionValue)
                                                : {},
                                        };
                                    });
                                }),
                            };
                        });

                        res.prizeInfo.prizeQualityLimit.push({
                            rules,
                            conditionType: 'LIMIT3',
                            checked: true,
                        });
                    } else {
                        res.prizeInfo.prizeQualityLimit.push({
                            conditionType: 'LIMIT3',
                            rules: [{ num: '', conditionRules: [[]] }],
                        });
                    }

                    if (res.drawQuantitys && res.drawQuantitys.length) {
                        res.prizeInfo.drawQuantitys = res.drawQuantitys.map((i: any) => {
                            return {
                                quantity: i.quantity,
                                ruleCommands: i.drawRules.map((j: any) => {
                                    return j.prizeConditions.map((k: any) => {
                                        return {
                                            conditionType: k.conditionType,
                                            conditionValue: k.conditionValue
                                                ? JSON.parse(k.conditionValue)
                                                : {},
                                        };
                                    });
                                }),
                            };
                        });
                    }

                    res.messageConfig = {
                        messageType: [
                            {
                                type: MaotaiMessageTypes.MINIPROGRAM_SUBSCRIPTION,
                                checked: res.messageTypes.includes(
                                    MaotaiMessageTypes.MINIPROGRAM_SUBSCRIPTION
                                ),
                            },
                            {
                                type: MaotaiMessageTypes.WECHAT_TEMPLATE,
                                checked: res.messageTypes.includes(
                                    MaotaiMessageTypes.WECHAT_TEMPLATE
                                ),
                                data: {
                                    top: get(res, 'wechatTemplate.topContent', ''),
                                    bottom: get(res, 'wechatTemplate.bottomContent', ''),
                                    targetUrl: get(res, 'wechatTemplate.targetUrl', ''),
                                    targetEnum: get(
                                        res,
                                        'wechatTemplate.targetEnum',
                                        'SELF_MINI_PROGRAM'
                                    ),
                                    templateId: get(res, 'wechatTemplate.templateId', ''),
                                    targetAppId: get(res, 'wechatTemplate.targetAppId', ''),
                                },
                            },
                            {
                                type: MaotaiMessageTypes.MESSAGE,
                                checked: res.messageTypes.includes(MaotaiMessageTypes.MESSAGE),
                            },
                        ],
                    };
                } catch (error) {
                    console.log(error);
                }
            }
            return res;
        });
    },

    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`;
        config.apiPath = '/admin/presale';

        const sendData = transformData(params);

        if (params.pageType === 'normal') {
            sendData.showWhite = false;
        }
        if (sendData.rounds && sendData.rounds.length) {
            sendData.rounds.forEach((item: any) => {
                if (item.id) {
                    delete item.id;
                }
            });
        }
        return await api.post(sendData, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`;
        if (params.id) {
            config.apiPath = `/admin/presale/${params.id}`;
        }

        const sendData = transformData(params);
        if (params.pageType === 'normal') {
            sendData.showWhite = false;
        }
        return await api.put(sendData, config);
    },
};

function transformData(entityData: any) {
    let data: any = {
        // 基本信息
        code: entityData.activityInfo.code,
        name: entityData.activityInfo.name,
        startTime: entityData.activityInfo.dateRange.start,
        endTime: entityData.activityInfo.dateRange.end,
        susbiteIds: entityData.activityInfo.subsites.map((i: any) => i.id),
        // goodsIds: entityData.activityInfo.goods.map((i: any) => i.id),
        //description: entityData.activityInfo.description,
        mode: entityData.activityInfo.mode,
        // 场次
        roundType: entityData.roundsInfo.roundType,
        startRegisterTime: entityData.roundsInfo.registerTimeRange.start,
        endRegisterTime: entityData.roundsInfo.registerTimeRange.end,
        purchaseMode:
            entityData.roundsInfo.purchaseMode && entityData.roundsInfo.purchaseMode.mode
                ? entityData.roundsInfo.purchaseMode.mode
                : 'SAME',
        purchaseTime: entityData.roundsInfo.purchaseTime,
    };

    if (data.roundType === 'ROUND' && data.purchaseMode !== 'DIFFERENT') {
        const diffDays =
            moment(entityData.activityInfo.dateRange.end).diff(
                moment(entityData.activityInfo.dateRange.start),
                'days'
            ) + 1;
        const registrationTimes: any = [];
        for (let i = 0; i < diffDays; i++) {
            const item: any = {
                ...entityData.roundsInfo.rounds[0],

                startDate: moment(entityData.activityInfo.dateRange.start, 'YYYY-MM-DD')
                    .subtract(0 - i, 'days')
                    .format('YYYY-MM-DD'),
            };

            registrationTimes.push(item);
        }
        entityData.roundsInfo.rounds = registrationTimes;
    }

    data.rounds =
        data.purchaseMode === 'DIFFERENT'
            ? entityData.roundsInfo.purchaseMode.rounds.map((i: any) => {
                  const item: any = {
                      drawTime: i.drawTime,
                      registrationStartTime: i.registrationStartTime,
                      registrationEndTime: i.registrationEndTime,
                      goodsList: i.goodsVos.map((good: any) => {
                          return {
                              goodsId: good.goodsId,
                              quantity: good.quantity,
                              whiteListQuantity: good.whiteListQuantity,
                          };
                      }),
                  };
                  if (i.id) {
                      item.id = i.id;
                  }
                  return item;
              })
            : entityData.roundsInfo.rounds.map((i: any) => {
                  const item: any = {
                      drawTime:
                          data.roundType === 'ROUND'
                              ? i.startDate +
                                ' ' +
                                i.drawTime +
                                (i.drawTime.split(':').length === 3 ? '' : ':00')
                              : i.drawDateTime,
                      goodsList: i.goods.map((good: any) => {
                          return {
                              goodsId: good.id,
                              quantity: good.maotaiQuantity,
                              whiteListQuantity: good.maotaiWhiteListQuantity
                                  ? good.maotaiWhiteListQuantity
                                  : 0,
                          };
                      }),
                  };
                  if (i.id) {
                      item.id = i.id;
                  }
                  return item;
              });
    // data.roundType === 'ROUND'
    //     ? entityData.roundsInfo.rounds.map((i: any) => {
    //           const item: any = {
    //               drawTime: i.drawDateTime,
    //               purchaseTime: i.purchaseTime,
    //               quantity: i.quantity,
    //               registrationStartTime: i.startDate + ' ' + i.startTime,
    //               registrationEndTime: i.startDate + ' ' + i.endTime,
    //               whiteListQuantity: i.whiteListQuantity,
    //           };
    //           if (i.id) {
    //               item.id = i.id;
    //           }
    //           return item;
    //       })
    //     : entityData.roundsInfo.rounds.map((i: any) => {
    //           const item: any = {
    //               drawTime: i.drawDateTime,
    //               purchaseTime: i.purchaseTime,
    //               quantity: i.quantity,
    //               registrationStartTime: i.startDateTime,
    //               registrationEndTime: i.endDateTime,
    //               whiteListQuantity: i.whiteListQuantity,
    //           };
    //           if (i.id) {
    //               item.id = i.id;
    //           }
    //           return item;
    //       });
    // 报名条件
    data.registrationConditions = entityData.registrationConditionsInfo.conditions
        .filter((i: any) => i.checked)
        .map((i: any) => {
            return {
                conditionType: i.conditionType,
                conditionValue: i.conditionValue
                    ? JSON.stringify(i.conditionValue)
                    : JSON.stringify({}),
            };
        });
    data.prizeRule = {};
    // 中奖条件
    if (entityData.prizeInfo && entityData.prizeInfo.prizeCondition) {
        const lastPrizeDays = entityData.prizeInfo.prizeCondition.find(
            (i: any) => i.checked && i.conditionType === 'PRIZE'
        );
        const lastOffDays = entityData.prizeInfo.prizeCondition.find(
            (i: any) => i.checked && i.conditionType === 'CONSUME'
        );
        if (lastPrizeDays) {
            data.prizeRule.lastPrizeDays = lastPrizeDays.day;
        }
        if (lastOffDays) {
            data.prizeRule.lastOffDays = lastOffDays.day;
        }
    }

    // 中奖数量限制
    // if (entityData.prizeInfo.memberLimitQuantity) {
    //     data.prizeRule.memberLimitQuantity = entityData.prizeInfo.memberLimitQuantity;
    // }
    if (entityData.prizeInfo && entityData.prizeInfo.prizeQualityLimit) {
        const limit1 = entityData.prizeInfo.prizeQualityLimit.find(
            (i: any) => i.checked && i.conditionType === 'LIMIT1'
        );
        const limit2 = entityData.prizeInfo.prizeQualityLimit.find(
            (i: any) => i.checked && i.conditionType === 'LIMIT2'
        );
        const limit3 = entityData.prizeInfo.prizeQualityLimit.find(
            (i: any) => i.conditionType === 'LIMIT3'
        );
        if (limit1) {
            data.prizeRule.limitStartTime = limit1.dateRange[0] + ' 00:00:00';
            data.prizeRule.limitEndTime = limit1.dateRange[1] + ' 23:59:59';
            data.prizeRule.limitQuantity = limit1.num;
        }
        if (limit2) {
            data.prizeRule.memberLimitQuantity = limit2.num;
        }

        if (limit3) {
            const prizeQuantitys = limit3.rules.map((i: any) => {
                return {
                    quantity: i.num,
                    ruleCommands: i.conditionRules.map((j: any) => {
                        return {
                            conditionCommands: j.map((k: any) => {
                                return {
                                    conditionType: k.conditionType,
                                    conditionValue: k.conditionValue
                                        ? JSON.stringify(k.conditionValue)
                                        : JSON.stringify({}),
                                };
                            }),
                        };
                    }),
                };
            });

            data.prizeQuantitys = prizeQuantitys;
        }
    }

    //按轮次抽奖

    data.prizeRule.drawType = entityData.prizeInfo ? entityData.prizeInfo.drawType : 'AUTO';

    // 设置抽奖次数
    if (entityData.prizeInfo && entityData.prizeInfo.drawQuantitys) {
        data.drawQuantitys = entityData.prizeInfo.drawQuantitys.map((i: any, index: number) => {
            return {
                quantity: i.quantity,
                sequence: index + 1,
                ruleCommands: i.ruleCommands.map((j: any) => {
                    return {
                        conditionCommands: j.map((k: any) => {
                            return {
                                conditionType: k.conditionType,
                                conditionValue: k.conditionValue
                                    ? JSON.stringify(k.conditionValue)
                                    : JSON.stringify({}),
                            };
                        }),
                    };
                }),
            };
        });
    }

    // 通知渠道
    data.messageTypes = entityData.messageConfig.messageType
        .filter((i: any) => i.checked)
        .map((i: any) => i.type);
    const templateData = get(
        entityData.messageConfig.messageType.find(
            (i: any) => i.type === MaotaiMessageTypes.WECHAT_TEMPLATE
        ),
        'data'
    );
    if (templateData) {
        data.wechatTemplateCommand = {
            topContent: get(templateData, 'top'),
            bottomContent: get(templateData, 'bottom'),
            targetEnum: get(templateData, 'targetEnum'),
            targetUrl:
                get(templateData, 'targetEnum') === 'H5'
                    ? get(templateData, 'hTargetUrl')
                    : get(templateData, 'targetEnum') === 'SELF_MINI_PROGRAM'
                    ? get(templateData, 'miniTargetUrl')
                    : get(templateData, 'targetEnum') === 'OTHER_MINI_PROGRAM'
                    ? get(templateData, 'otherTargetUrl')
                    : '',
            templateId: get(templateData, 'templateId'),
            targetAppId: get(templateData, 'targetAppId'),
            topContentColor: '#000',
            bottomContentColor: '#000',
        };
    }
    if (
        entityData.prizeInfo &&
        isNumber(entityData.prizeInfo.whiteQuantity) &&
        entityData.prizeInfo.whiteQuantity >= 0
    ) {
        data.whiteQuantityCommand = {
            quantity: entityData.prizeInfo.whiteQuantity,
        };
    }

    return data;
}

export const MaotaiCardLevelsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`;
        // config.apiPath = '/admin/store_card_levels';
        config.apiPath = '/admin/card_levels';
        // data.page = 1;
        // data.perPage = 200;
        data.status = 'ENABLE';

        // const subsites = data['activityInfo.subsites'];
        // if (subsites && subsites.length > 1) {
        //     data.subsiteIds = subsites.map((i: any) => i.id).join(',');
        // } else if (subsites && subsites.length === 1) {
        //     data.subsiteId = subsites[0]['id'];
        // }

        // delete data['activityInfo.subsites'];

        return await api.get(data, config).then((res: any) => {
            return res;
        });
    },
};

export const MaotaiLimitRuleLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`;
        config.apiPath = '/admin/rule';
        return await api.get(data, config).then((res: any) => {
            const singleInfo = find(res, { mode: 'SINGLE' });
            const multipleInfo = find(res, { mode: 'MULTIPLE' });
            const otherInfo = find(res, { mode: 'OTHER' });
            res = {
                singleLimitSubsite: [],
                singlePrizeCondition: [],
                singlePrizeQualityLimit: [],
                singleDescription: singleInfo ? singleInfo.description : '',
                multipleLimitSubsite: [],
                multiplePrizeCondition: [],
                multiplePrizeQualityLimit: [],
                multipleDescription: multipleInfo ? multipleInfo.description : '',
                otherPrizeCondition: [],
                otherPrizeQualityLimit: [],
                otherDescription: otherInfo ? otherInfo.description : '',
            };

            if (get(singleInfo, 'limitSubsite')) {
                res.singleLimitSubsite.push('true');
            }

            if (get(singleInfo, 'lastOffDays')) {
                res.singlePrizeCondition.push({
                    checked: true,
                    conditionType: 'CONSUME',
                    day: singleInfo.lastOffDays,
                });
            }
            if (get(singleInfo, 'lastPrizeDays')) {
                res.singlePrizeCondition.push({
                    checked: true,
                    conditionType: 'PRIZE',
                    day: singleInfo.lastPrizeDays,
                });
            }
            if (get(singleInfo, 'limitQuantity')) {
                res.singlePrizeQualityLimit.push({
                    checked: true,
                    conditionType: 'LIMIT1',
                    num: singleInfo.limitQuantity,
                    dateRange: [singleInfo.limitStartTime, singleInfo.limitEndTime],
                });
            }
            if (get(singleInfo, 'monthLimitQuantity')) {
                res.singlePrizeQualityLimit.push({
                    checked: true,
                    conditionType: 'LIMIT2',
                    num: singleInfo.monthLimitQuantity,
                });
            }

            if (get(multipleInfo, 'limitSubsite')) {
                res.multipleLimitSubsite.push('true');
            }

            if (get(multipleInfo, 'lastOffDays')) {
                res.multiplePrizeCondition.push({
                    checked: true,
                    conditionType: 'CONSUME',
                    day: multipleInfo.lastOffDays,
                });
            }
            if (get(multipleInfo, 'lastPrizeDays')) {
                res.multiplePrizeCondition.push({
                    checked: true,
                    conditionType: 'PRIZE',
                    day: multipleInfo.lastPrizeDays,
                });
            }
            if (get(multipleInfo, 'limitQuantity')) {
                res.multiplePrizeQualityLimit.push({
                    checked: true,
                    conditionType: 'LIMIT1',
                    num: multipleInfo.limitQuantity,
                    dateRange: [multipleInfo.limitStartTime, multipleInfo.limitEndTime],
                });
            }
            if (get(multipleInfo, 'monthLimitQuantity')) {
                res.multiplePrizeQualityLimit.push({
                    checked: true,
                    conditionType: 'LIMIT2',
                    num: multipleInfo.monthLimitQuantity,
                });
            }
            if (get(otherInfo, 'lastOffDays')) {
                res.otherPrizeCondition.push({
                    checked: true,
                    conditionType: 'CONSUME',
                    day: otherInfo.lastOffDays,
                });
            }
            if (get(otherInfo, 'lastPrizeDays')) {
                res.otherPrizeCondition.push({
                    checked: true,
                    conditionType: 'PRIZE',
                    day: otherInfo.lastPrizeDays,
                });
            }
            if (get(otherInfo, 'limitQuantity')) {
                res.otherPrizeQualityLimit.push({
                    checked: true,
                    conditionType: 'LIMIT1',
                    num: otherInfo.limitQuantity,
                    dateRange: [otherInfo.limitStartTime, otherInfo.limitEndTime],
                });
            }
            if (get(otherInfo, 'monthLimitQuantity')) {
                res.otherPrizeQualityLimit.push({
                    checked: true,
                    conditionType: 'LIMIT2',
                    num: otherInfo.monthLimitQuantity,
                });
            }
            return res;
        });
    },
};
