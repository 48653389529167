import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { ArrayCouponRuleStack } from './array-coupon-rule-stack';

export class ArrayCouponRuleStackMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(controlInfo: any): JSX.Element {
        return <ArrayCouponRuleStack {...controlInfo} />;
    }
}
