import React, { Component } from 'react';
import { Input } from 'antd';
import { api } from '@comall-backend-builder/core/lib/services';

export class StringPasswordPlus extends Component<any, any> {
    state = {
        newPasswordHelp: '',
        extraTxt: '',
    };

    componentDidMount() {
        const { extraStatus } = this.props;
        if (extraStatus) {
            this.handleBlur({ target: { value: '1' }, extraType: 'extra' });
        }
    }
    private handleBlur = (e: any) => {
        const { value } = e.target;
        api.get(
            {
                password: value,
            },
            {
                apiPath: `/open_api/password_strength/verify`,
                apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
            }
        ).then((res: any) => {
            if (e.extraType) {
                this.setState({
                    extraTxt: res.ok ? '' : res.msg,
                });
            } else {
                this.setState({
                    newPasswordHelp: res.ok ? '' : res.msg,
                });
            }
        });
    };
    render() {
        const { openCheck } = this.props;
        const { newPasswordHelp, extraTxt } = this.state;
        const handleBlur = openCheck ? this.handleBlur : undefined;
        return (
            <div className={newPasswordHelp ? 'has-error' : ''}>
                <Input.Password {...this.props} onBlur={handleBlur} />
                {!newPasswordHelp && extraTxt ? (
                    <div className="ant-form-extra">{extraTxt}</div>
                ) : null}
                {newPasswordHelp ? <div className="ant-form-explain">{newPasswordHelp}</div> : null}
            </div>
        );
    }
}
