import React, { PureComponent } from 'react';
import { Spin, Icon, Tag, Checkbox } from 'antd';
import classNames from 'classnames';
import { categorie, brandItem } from '../index';
import '../index.less';

export enum TimeHorizon {
    RecentOneYears = 1,
    RecentThreeYears = 2,
}

interface ChartItem {
    key: string;
    value: number;
    type: string;
}
type TimeHorizonType = 'expenseRecordTimeHorizon' | 'reachStoreTimeHorizon';
interface Props {
    filtersLoading: boolean;
    allSelecded: categorie[]; //全部已选分类
    searchCategoriesData: categorie[]; //分类数据
    selectedGrand: string | number | undefined; //已选的一级分类
    isExpandGrand: boolean; //一级分类展开状态
    parentData: categorie[]; //二级分类数据
    selectedParent: string | number | undefined; //已选的二级分类
    isExpandParent: boolean; //二级分类展开状态
    assortData: categorie[]; //三级分类数据
    selectedAssorts: number[]; //已选的三级分类
    isExpandAssort: boolean; //三级分类展开状态
    brandData: brandItem[]; //品牌数据
    selectedBrands: number[]; //已选的品牌数据
    isExpandBrand: boolean; //品牌数据展开状态
    originData: string[]; //产地数据
    selectedOrigin: string | number | undefined; //已选的产地数据
    isExpandOrigin: boolean; //产地数据展开状态
    onSelectOrigin: (item: string, index: number) => void;
    onChangeCheckBox: (value: Array<any>, type: string) => void;
    onSelectClassify: (item: any, type: string) => void;
    onClickFold: (operateType: string) => void;
    clearSelecded: (item: any, type?: string) => void;
}
interface State {}
export default class SelectionPoolSearchResult extends PureComponent<Props, unknown> {
    state = {};
    private renderSelecdFiters = () => {
        const { allSelecded, clearSelecded } = this.props;
        return (
            <div className="selection-pool-page__top__selecd">
                <div className="selection-pool-page__top__selecd__left">
                    全部结果
                    <Icon type="right"></Icon>
                </div>
                <div className="selection-pool-page__top__selecd__content">
                    {allSelecded?.map((tag: categorie) => (
                        <Tag
                            key={tag.id}
                            className="selection-pool-page__top__selecd__content-tag"
                            closable
                            onClose={() => clearSelecded(tag)}
                        >
                            {tag.name}
                        </Tag>
                    ))}
                </div>
                {allSelecded?.length > 0 && (
                    <div
                        className="selection-pool-page__top__selecd__clear"
                        onClick={() => clearSelecded({}, 'all')}
                    >
                        清除全部
                    </div>
                )}
            </div>
        );
    };
    private renderGrandClassify = () => {
        const {
            searchCategoriesData,
            selectedGrand,
            isExpandGrand,
            onSelectClassify,
            onClickFold,
        } = this.props;
        if (!searchCategoriesData || !searchCategoriesData?.length) {
            return null;
        }
        let newData: categorie[] = [];
        if (!isExpandGrand && searchCategoriesData.length > 6) {
            newData = searchCategoriesData.slice(0, 6);
        } else {
            newData = searchCategoriesData;
        }
        return (
            <div className={wrap}>
                <div className={classifycommonClsNames.content}>
                    <div className={classifycommonClsNames.title}>类目</div>
                    <div className={classifycommonClsNames.assorts}>
                        {newData.map((item: categorie) => (
                            <div
                                className={classNames(classifycommonClsNames.assort, {
                                    [classifycommonClsNames.active]:
                                        item.categoryId === selectedGrand,
                                })}
                                key={item.categoryId}
                                onClick={() => onSelectClassify(item, 'Grand')}
                            >
                                {item.categoryName}
                            </div>
                        ))}
                    </div>
                    {searchCategoriesData?.length > 6 && (
                        <div
                            className={classifycommonClsNames.more}
                            onClick={() => onClickFold('Grand')}
                        >
                            {isExpandGrand ? '收起' : '展开'}
                            <Icon type={isExpandGrand ? 'up' : 'down'} />
                        </div>
                    )}
                </div>
            </div>
        );
    };
    private renderParentClassify = () => {
        const {
            parentData,
            selectedParent,
            isExpandParent,
            onSelectClassify,
            onClickFold,
        } = this.props;
        if (!parentData || !parentData?.length) {
            return null;
        }
        let newData: categorie[] = [];
        if (!isExpandParent && parentData.length > 6) {
            newData = parentData.slice(0, 6);
        } else {
            newData = parentData;
        }
        return (
            <div className={wrap}>
                <div className={classifycommonClsNames.content}>
                    <div className={classifycommonClsNames.title}>分类</div>
                    <div className={classifycommonClsNames.assorts}>
                        {newData.map((item: categorie) => (
                            <div
                                className={classNames(classifycommonClsNames.assort, {
                                    [classifycommonClsNames.active]:
                                        item.categoryId === selectedParent,
                                })}
                                onClick={() => onSelectClassify(item, 'Parent')}
                                key={item.categoryId}
                            >
                                {item.categoryName}
                            </div>
                        ))}
                    </div>
                    {parentData?.length > 6 && (
                        <div
                            className={classifycommonClsNames.more}
                            onClick={() => onClickFold('Parent')}
                        >
                            {isExpandParent ? '收起' : '展开'}
                            <Icon type={isExpandParent ? 'up' : 'down'} />
                        </div>
                    )}
                </div>
            </div>
        );
    };
    private renderAssortClassify = () => {
        const {
            assortData,
            selectedAssorts,
            isExpandAssort,
            onChangeCheckBox,
            onClickFold,
        } = this.props;
        if (!assortData || !assortData?.length) {
            return null;
        }
        let newData: categorie[] = [];
        if (!isExpandAssort && assortData.length > 6) {
            newData = assortData.slice(0, 6);
        } else {
            newData = assortData;
        }
        return (
            <div className={wrap}>
                <div className={classifycommonClsNames.content}>
                    <div className={classifycommonClsNames.title}>下级分类</div>
                    <Checkbox.Group
                        value={selectedAssorts}
                        onChange={(value) => onChangeCheckBox(value, 'Classifys')}
                    >
                        <div className={classifycommonClsNames.assorts}>
                            {newData.map((item: categorie) => (
                                <div
                                    className={classifycommonClsNames.assort}
                                    key={item.categoryId}
                                >
                                    <Checkbox value={item.categoryId}>{item.categoryName}</Checkbox>
                                </div>
                            ))}
                        </div>
                    </Checkbox.Group>
                    {assortData?.length > 6 && (
                        <div
                            className={classifycommonClsNames.more}
                            onClick={() => onClickFold('Assort')}
                        >
                            {isExpandAssort ? '收起' : '展开'}
                            <Icon type={isExpandAssort ? 'up' : 'down'} />
                        </div>
                    )}
                </div>
            </div>
        );
    };
    private renderBrands = () => {
        const {
            brandData,
            selectedBrands,
            isExpandBrand,
            onChangeCheckBox,
            onClickFold,
        } = this.props;
        if (!brandData || !brandData?.length) {
            return null;
        }
        let newData: brandItem[] = [];
        if (!isExpandBrand && brandData.length > 6) {
            newData = brandData.slice(0, 6);
        } else {
            newData = brandData;
        }
        return (
            <div className={wrap}>
                <div className={classifycommonClsNames.content}>
                    <div className={classifycommonClsNames.title}>品牌</div>
                    <Checkbox.Group
                        value={selectedBrands}
                        onChange={(value) => onChangeCheckBox(value, 'Brands')}
                    >
                        <div className={classifycommonClsNames.assorts}>
                            {newData.map((item: brandItem) => (
                                <div className={classifycommonClsNames.assort} key={item.brandId}>
                                    <Checkbox value={item.brandId}>{item.brandNameCn}</Checkbox>
                                </div>
                            ))}
                        </div>
                    </Checkbox.Group>
                    {brandData?.length > 6 && (
                        <div
                            className={classifycommonClsNames.more}
                            onClick={() => onClickFold('Brand')}
                        >
                            {isExpandBrand ? '收起' : '展开'}
                            <Icon type={isExpandBrand ? 'up' : 'down'} />
                        </div>
                    )}
                </div>
            </div>
        );
    };
    private renderOrigins = () => {
        const {
            originData,
            selectedOrigin,
            isExpandOrigin,
            onClickFold,
            onSelectOrigin,
        } = this.props;
        if (!originData || !originData?.length) {
            return null;
        }
        let newData: string[] = [];
        if (!isExpandOrigin && originData.length > 6) {
            newData = originData.slice(0, 6);
        } else {
            newData = originData;
        }
        return (
            <div className={wrap}>
                <div className={classifycommonClsNames.content}>
                    <div className={classifycommonClsNames.title}>产地</div>
                    <div className={classifycommonClsNames.assorts}>
                        {newData.map((item: string, index: number) => (
                            <div
                                className={classNames(classifycommonClsNames.assort, {
                                    [classifycommonClsNames.active]: item === selectedOrigin,
                                })}
                                key={index}
                                onClick={() => onSelectOrigin(item, index)}
                            >
                                {item}
                            </div>
                        ))}
                    </div>
                    {originData?.length > 6 && (
                        <div
                            className={classifycommonClsNames.more}
                            onClick={() => onClickFold('Origin')}
                        >
                            {isExpandOrigin ? '收起' : '展开'}
                            <Icon type={isExpandOrigin ? 'up' : 'down'} />
                        </div>
                    )}
                </div>
            </div>
        );
    };
    render() {
        const { filtersLoading } = this.props;
        return (
            <Spin spinning={filtersLoading}>
                <div className="selection-pool-page__top__paddig">
                    {this.renderSelecdFiters()}
                    {this.renderGrandClassify()}
                    {this.renderParentClassify()}
                    {this.renderAssortClassify()}
                    {this.renderBrands()}
                    {this.renderOrigins()}
                </div>
            </Spin>
        );
    }
}

const wrap = `selection-pool-page__top__assorts`;
const classifycommonClsNames = {
    content: `${wrap}__content`,
    title: `${wrap}__content__title`,
    assorts: `${wrap}__content__assorts`,
    assort: `${wrap}__content__assorts__item`,
    active: `${wrap}__content__assorts__item__active`,
    more: `${wrap}__content__more`,
};
