import React, { Component } from 'react';
import { Input } from 'antd';

import { ProductSelector } from '../../../../components';
import { services } from '@comall-backend-builder/core';

export class MemberGiftManageName extends Component<any, any> {
    onSelect = (products: any) => {
        const { changeField, onChange } = this.props;
        if (!changeField || !products.length) {
            return;
        }
        const [
            {
                name,
                pictures,
                goodsMvo: { weight },
                salesUnit,
                details,
            },
        ] = products;
        onChange(name);
        if (pictures && pictures.length > 0) {
            changeField('picsConfig.images', pictures);
        }
        changeField('baseInfo.weight', weight);
        changeField('baseInfo.salesUnit', salesUnit);
        const decodedText = document.createElement('textarea');
        decodedText.innerHTML = details;
        const decodedResult = decodedText.value;
        changeField('otherConfig.details', decodedResult);
    };

    render() {
        return (
            <div>
                <Input className="input" {...this.props} />
                <div style={{ display: 'inline-block' }}>
                    <ProductSelector
                        onChange={this.onSelect}
                        rowSelectionType="radio"
                        buttonText={services.language.getText('applyProductInfo')}
                    />
                </div>
            </div>
        );
    }
}
