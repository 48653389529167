import React, { Component } from 'react';
import { Select, Checkbox, Popover, Icon } from 'antd';
import { get, isEqual } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';
import './index.less';

const Option = Select.Option;

interface BirthdayCaringMessageValue {
    activityCheck: boolean;
    activityDay: string;
    activityTime: string;
    memberCheck: boolean;
    memberDay: string;
    memberTime: string;
}

interface Props {
    onChange: (value: BirthdayCaringMessageValue) => void;
    value: BirthdayCaringMessageValue;
    disabled: boolean;
    row: any;
}

interface States {
    msgDisabled: boolean;
}

const defaultValue: BirthdayCaringMessageValue = {
    activityCheck: false,
    activityDay: '0',
    activityTime: '08:00',
    memberCheck: false,
    memberDay: '0',
    memberTime: '08:00',
};

export class BirthdayMessageTime extends Component<Props, States> {
    constructor(props: any) {
        super(props);
        this.state = {
            msgDisabled: true,
        };
    }

    componentWillReceiveProps(nextProps: any) {
        const currentIndustryId = get(this.props, 'row.baseInfo.birthdayCaringType');
        const nextIndustryId = get(nextProps, 'row.baseInfo.birthdayCaringType');
        if (
            currentIndustryId &&
            nextIndustryId &&
            !isEqual(currentIndustryId, nextIndustryId) &&
            nextIndustryId === 'SAME_DAY'
        ) {
            this.onResetSameDayValue();
        }
    }

    componentDidMount() {
        const { value, onChange } = this.props;
        if (!value) {
            onChange(defaultValue);
        }
    }

    onResetSameDayValue = () => {
        const { value, onChange } = this.props;
        const newValue = {
            ...value,
            activityCheck: false,
            activityDay: '0',
            activityTime: '8:00',
        };
        onChange(newValue);
    };

    onChangeValue = (type: string, content: any) => {
        const { value, onChange } = this.props;
        // @ts-ignore
        value[type] = content;
        onChange(value);
    };

    renderModal = () => {
        return (
            <div className="birthday-message-time__modal">
                <div>{language.getText('ttpzlghdgz')}</div>
                <div style={{ fontWeight: 'bold' }}>{language.getText('azhdsjfstz')}</div>
                <div>
                    {language.getText('rhdsjwsrdt')}
                    {language.getText('hyhsrdqjtgyhfstt')}
                </div>
                <div>{language.getText('rhdsjwdrdy')}</div>
                <div>{language.getText('rhdsjwsrqjt')}</div>
                <div style={{ fontWeight: 'bold' }}>{language.getText('azhysrhdsjfstz')}</div>
                <div>{language.getText('hysidtjxtz')}</div>
            </div>
        );
    };

    renderTime = () => {
        const { value } = this.props;
        if (!value) return null;
        const dayList: Array<{ value: string; label: string }> = [];
        for (let i = 1; i <= 7; i++) {
            dayList.push({
                value: i + '',
                label: `前${i}天`,
            });
        }
        dayList.push({
            value: '0',
            label: `当天`,
        });
        const timeList: Array<{ value: string; label: string }> = [];
        for (let i = 8; i <= 20; i++) {
            timeList.push({
                value: i.toString().length === 1 ? `0${i}:00` : `${i}:00`,
                label: `${i}:00`,
            });
        }
        const birthdayCaringType = get(this.props, 'row.baseInfo.birthdayCaringType');
        const isShowActivity = birthdayCaringType !== 'SAME_DAY';
        return (
            <div className="birthday-message-time__time">
                <div>
                    <Popover placement="left" content={this.renderModal()} trigger="hover">
                        <Icon type="question-circle" />
                    </Popover>
                </div>
                <div className="birthday-message-time__time-list">
                    <div className="birthday-message-time__time-item">
                        <Checkbox
                            checked={value.memberCheck}
                            onChange={(event) => {
                                const checked = event.target.checked;
                                this.onChangeValue('memberCheck', checked);
                            }}
                        ></Checkbox>
                        &nbsp;&nbsp; {language.getText('memberBirthday')}：&nbsp;&nbsp;
                        <Select
                            style={{ width: '100px' }}
                            value={value.memberDay}
                            onChange={(value: any) => {
                                this.onChangeValue('memberDay', value);
                            }}
                        >
                            {dayList.map((day, index) => {
                                return (
                                    <Option value={day.value} key={index}>
                                        {day.label}
                                    </Option>
                                );
                            })}
                        </Select>
                        &nbsp;&nbsp;
                        <Select
                            style={{ width: '100px' }}
                            value={value.memberTime}
                            onChange={(value: any) => {
                                this.onChangeValue('memberTime', value);
                            }}
                        >
                            {timeList.map((time, index) => {
                                return (
                                    <Option value={time.value} key={index}>
                                        {time.label}
                                    </Option>
                                );
                            })}
                        </Select>
                        &nbsp;&nbsp; {language.getText('jxtz')}
                    </div>
                    {isShowActivity && (
                        <div className="birthday-message-time__time-item">
                            <Checkbox
                                checked={value.activityCheck}
                                onChange={(event) => {
                                    const checked = event.target.checked;
                                    this.onChangeValue('activityCheck', checked);
                                }}
                            ></Checkbox>
                            &nbsp;&nbsp;{language.getText('activityStart')}&nbsp;&nbsp;
                            <Select
                                style={{ width: '100px' }}
                                value={value.activityDay}
                                onChange={(value: any) => {
                                    this.onChangeValue('activityDay', value);
                                }}
                            >
                                {dayList.map((day, index) => {
                                    return (
                                        <Option value={day.value} key={index}>
                                            {day.label}
                                        </Option>
                                    );
                                })}
                            </Select>
                            &nbsp;&nbsp;
                            <Select
                                style={{ width: '100px' }}
                                value={value.activityTime}
                                onChange={(value: any) => {
                                    this.onChangeValue('activityTime', value);
                                }}
                            >
                                {timeList.map((time, index) => {
                                    return (
                                        <Option value={time.value} key={index}>
                                            {time.label}
                                        </Option>
                                    );
                                })}
                            </Select>
                            &nbsp;&nbsp; {language.getText('jxtz')}
                        </div>
                    )}
                    <div className="birthday-message-time__tip">
                        {language.getText('wpmdyhgddr')}
                    </div>
                    <div className="birthday-message-time__tip">
                        {language.getText('pzgzsshhwyhfsxxtz')}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { value } = this.props;
        if (!value) return null;
        return <div className="birthday-message-time">{this.renderTime()}</div>;
    }
}
