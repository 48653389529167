import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { map, get, isArray, round } from 'lodash';

export const config: Config = {
    entities: {
        couponSaleActivityEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/coupon_sale_activity',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<qmc>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOTSTARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        subsites: {
                            type: 'array.options.autoComplete',
                            className: 'custom-array-options',
                            displayName: '<<applicableSubsite>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<selectSubsite>>',
                            },
                        },
                        selectCoupon: {
                            type: 'array.couponSelectorWithPackage',
                            displayName: '选择优惠券',
                            rules: [{ required: true, type: 'array' }],
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '售卖时间',
                            rules: [{ required: false, message: '<<effectiveTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                    },
                },
                saleInfo: {
                    type: 'object.subForm',
                    displayName: '售卖设置',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<qmc>>',
                            rules: [{ required: false, whitespace: true }],
                            controlConfig: {
                                placeholder: '不填写，自动读取选择的优惠券或礼包标题',
                                style: {
                                    width: 300,
                                },
                                maxLength: 20,
                            },
                        },
                        couponPicture: {
                            displayName: '<<qtp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 5,
                                maxSize: 1024,
                                multiple: true,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                        },
                        couponSalePrice: {
                            type: 'number.price',
                            displayName: '券单卖价',
                            rules: [
                                {
                                    required: true,
                                    message: '最小填写0.01且只支持两位小数',
                                    validator: (rule: any, value: any) => {
                                        const reg = /^(-)?(0|[1-9]\d*)(\s|$|\.\d{1,2}\b)/;
                                        if (value && reg.test(value) && Number(value) >= 0.01) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                addonafter: '<<yuan>>',
                                placeholder: '<<pleaseInputNumber>>',
                                style: { width: '140px' },
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                            },
                        },
                        saleLimitType: {
                            type: 'string.options.radio',
                            displayName: '<<gmxz>>',
                            defaultValue: 'NO',
                            options: [
                                { id: 'NO', name: '不限制' },
                                { id: 'YES', name: '限制' },
                            ],
                        },
                        saleLimitNum: {
                            type: 'number.tip',
                            displayName: '<<limitCount>>',
                            rules: [
                                {
                                    type: 'number',
                                    message: '<<jzctxzzs>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^([1-9]\d*)$/;
                                        if (value && !reg.test(value)) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: '150px' },
                                placeholder: '<<inputPlease>>',
                                addonbefore: '<<mgyhgmsl>>',
                                addonafter: '<<jian>>',
                                tip: '',
                            },
                        },
                        // 限售总数 二期
                        // limitNumber: {
                        //     type: 'number.tip',
                        //     displayName: '限售总数',
                        //     displayConfig: { addonafter: '<<zhang>>' },
                        //     controlConfig: {
                        //         addonafter: '<<zhang>>',
                        //         placeholder: '<<period>>',
                        //         style: { width: '140px' },
                        //         rules: [
                        //             {
                        //                 type: 'number',
                        //             },
                        //         ],
                        //     },
                        // },
                    },
                },
                // 限购规则 二期
                // rulesInfo: {
                //     type: 'object.subForm',
                //     displayName: '限购规则',
                //     properties: {
                //         limitRules: {
                //             type: 'number.presaleTitleTip',
                //              displayName: '<<xgtj>>',
                //             displayConfig: {
                //                 eachPresaleConfig: {
                //                     addonbefore: '活动期间每人最多购买',
                //                     addonafter: '<<jian>>',
                //                 },
                //                 eachOrderConfig: {
                //                     addonbefore: '每人每单最多购买',
                //                     addonafter: '<<jian>>',
                //                 },
                //             },
                //             controlConfig: {
                //                 configInfo: [
                //                     {
                //                         addonbefore: '活动期间每人最多购买',
                //                         addonafter: '<<jian>>',
                //                         type: 'EACH_PRESALE',
                //                         rules: [
                //                             {
                //                                 type: 'number',
                //                             },
                //                         ],
                //                     },
                //                     {
                //                         addonbefore: '每人每单最多购买',
                //                         addonafter: '<<jian>>',
                //                         type: 'EACH_ORDER',
                //                         rules: [
                //                             {
                //                                 type: 'number',
                //                             },
                //                         ],
                //                     },
                //                 ],
                //             },
                //         },
                //     },
                // },
                // 服务设置 二期
                // serviceInfo: {
                //     type: 'object.subForm',
                //     displayName: '服务设置',
                //     properties: {
                //         serviceItem: {
                //             type: 'array.optionIds.checkbox.tip',
                //             displayName: '服务项',
                //             options: [{ id: 'pointsDeductionCash', name: '积分抵现' }],
                //         },
                //     },
                // },
                useRulesInfo: {
                    type: 'object.subForm',
                    displayName: '使用规则',
                    properties: {
                        info: {
                            type: 'string.text.paragraph',
                            displayName: '<<syxz>>',
                            rules: [{ max: 1000 }],
                            controlConfig: {
                                rows: 4,
                                maxLength: 1000,
                            },
                        },
                    },
                },
                categoryInfo: {
                    type: 'object.subForm',
                    displayName: '卖券分类',
                    properties: {
                        categories: {
                            type: 'array.couponsSaleCategories',
                            displayName: '选择分类',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },
                    },
                },
                name: {
                    type: 'string',
                    displayName: '<<qmc>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<effectiveTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<applicableSubsite>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                salePrice: {
                    type: 'number.price',
                    displayName: '券售卖价',
                },
            },
        },
        CouponSaleActivitySelectedEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/admin/sale_rules/selected',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<qmc>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                    displayName: '<<qmc>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<effectiveTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<applicableSubsite>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
        },
    },
    components: {
        couponSaleActivityView: {
            component: 'Viewport',
            entity: 'couponSaleActivityEntity',
        },
        couponSaleActivityPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'couponSaleActivityFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    style: {
                        paddingTop: 10,
                        paddingBottom: 10,
                    },
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '新增卖券活动',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/coupon/coupon-sale-activity/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'couponSaleActivityTable' },
            ],
        },
        couponSaleActivityFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'status',
                    controlConfig: {
                        style: { width: 120 },
                    },
                },
            ],
        },
        couponSaleActivityTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'name',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'salePrice',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'startTime',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'endTime',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOTSTARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
                {
                    property: 'subsites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                items: [
                    {
                        type: 'component',
                        component: 'couponSaleActivityListStatusButton',
                    },
                    {
                        type: 'link',
                        text: '<<bjkq>>',
                        statusKey: 'row.canEdit',
                        path: '/menu-list/coupon/coupon-sale-activity/edit/{{row.id}}',
                    },
                ],
            },
        },
        couponSaleActivityAddPage: {
            component: 'Card',
            content: { component: 'couponSaleActivityAddForm' },
        },
        couponSaleActivityAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'couponSaleActivityEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.subsites' },
                {
                    property: 'baseInfo.selectCoupon',
                    extra:
                        '优惠券和优惠券礼包仅支持设置一种，请添加适用门店通用的优惠券和优惠券礼包（或全部专柜可用），否则用户购买后可能无法使用。',
                },
                {
                    property: 'baseInfo.dateRange',
                    extra: '不填写则自动读取选择的优惠券或优惠券礼包开始、结束时间',
                },
                { property: 'saleInfo.name' },
                {
                    property: 'saleInfo.couponPicture',
                    extra: `<<zdkscPic>>`,
                },
                {
                    property: 'saleInfo.couponSalePrice',
                },
                { property: 'saleInfo.saleLimitType' },
                {
                    property: 'saleInfo.saleLimitNum',
                    visible: (values: any) => get(values, 'saleInfo.saleLimitType') === 'YES',
                },
                // 限售总数 二期
                // {
                //     property: 'saleInfo.limitNumber',
                // },
                // 限购规则 二期
                // {
                //     property: 'rulesInfo.limitRules',
                // },
                // 服务设置 二期
                // {
                //     property: 'serviceInfo.serviceItem',
                // },
                {
                    property: 'useRulesInfo.info',
                    extra: '不填写则自动读取选择的优惠券使用规则',
                },
                {
                    property: 'categoryInfo.categories',
                    controlConfig: {
                        style: {
                            width: 500,
                        },
                        getParams(props: any) {
                            const { row } = props;
                            const subsiteIds =
                                row.baseInfo && row.baseInfo.subsites
                                    ? map(row.baseInfo.subsites, 'id').join(',')
                                    : null;
                            return { subsiteIds };
                        },
                    },
                },
            ],
            submit: true,
            onValidate: (entity: any) => {
                const couponInfo = get(entity, 'baseInfo.selectCoupon[0].couponInfo');
                const saleLimitType = get(entity, 'saleInfo.saleLimitType');
                const saleLimitNum = get(entity, 'saleInfo.saleLimitNum');
                const useRulesInfo = get(entity, 'useRulesInfo.info');
                const couponSalePrice = get(entity, 'saleInfo.couponSalePrice');

                if (saleLimitType === 'YES' && !saleLimitNum) {
                    AntMessage.error(services.language.getText('qsryhxgsl'));
                    return false;
                }

                if (!!couponInfo && isArray(couponInfo)) {
                    if (!useRulesInfo || !useRulesInfo.trim()) {
                        AntMessage.error('配置优惠券礼包时，需要同时配置使用须知');
                        return false;
                    }

                    if (couponInfo.length > 0) {
                        const notExist = couponInfo.some(
                            (item: any) => !item.apportionPrice && item.apportionPrice !== 0
                        );
                        if (notExist) {
                            AntMessage.error(services.language.getText('yhqlbsmsqtxftj'));
                            return false;
                        }
                        let apportionPrice = 0;
                        couponInfo.forEach((item: any) => {
                            apportionPrice += item.apportionPrice * item.quantity;
                        });

                        if (
                            round(Number(couponSalePrice), 2) !== round(Number(apportionPrice), 2)
                        ) {
                            AntMessage.error(services.language.getText('ftjzhylbdmjbd'));
                            return false;
                        }
                    }
                }
                return true;
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        couponSaleActivityEditPage: {
            component: 'Card',
            entity: 'couponSaleActivityEntity',
            loaderType: 'get',
            content: { component: 'CouponSaleActivityEditFormView' },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/coupon/coupon-sale-activity',
                    component: 'couponSaleActivityView',
                    breadcrumbName: '卖券活动',
                    privilege: {
                        path: 'saleCouponActivity',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'couponSaleActivityAddPage',
                            breadcrumbName: '新增卖券活动',
                        },
                        {
                            path: '/edit/:id',
                            component: 'couponSaleActivityEditPage',
                            breadcrumbName: '<<bjkq>>',
                        },
                        { path: '/', component: 'couponSaleActivityPage' },
                    ],
                },
            ],
        },
    ],
};
