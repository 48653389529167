import React, { Component } from 'react';
import { services } from '@comall-backend-builder/core';
import { Select, Checkbox, Input, Popover, Icon } from 'antd';
import { get, isEqual } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';
import './index.less';

const Option = Select.Option;
const { TextArea } = Input;
const { api } = services;

interface BirthdayCaringMessageValue {
    msgChecked: boolean;
    smsChecked: boolean;
    smsContent: string;
    activityCheck: boolean;
    activityDay: string;
    activityTime: string;
    memberCheck: boolean;
    memberDay: string;
    memberTime: string;
}

interface Props {
    onChange: (value: BirthdayCaringMessageValue) => void;
    value: BirthdayCaringMessageValue;
    disabled: boolean;
    row: any;
}

interface States {
    msgDisabled: boolean;
}

const defaultValue: BirthdayCaringMessageValue = {
    msgChecked: false,
    smsChecked: false,
    smsContent: '',
    activityCheck: false,
    activityDay: '0',
    activityTime: '08:00',
    memberCheck: false,
    memberDay: '0',
    memberTime: '08:00',
};

export class BirthdayCaringMessage extends Component<Props, States> {
    constructor(props: any) {
        super(props);
        this.state = {
            msgDisabled: true,
        };
    }

    componentWillReceiveProps(nextProps: any) {
        const currentIndustryId = get(this.props, 'row.baseInfo.birthdayCaringType');
        const nextIndustryId = get(nextProps, 'row.baseInfo.birthdayCaringType');
        if (
            currentIndustryId &&
            nextIndustryId &&
            !isEqual(currentIndustryId, nextIndustryId) &&
            nextIndustryId === 'SAME_DAY'
        ) {
            this.onResetSameDayValue();
        }
    }

    onResetSameDayValue = () => {
        const { value, onChange } = this.props;
        const newValue = {
            ...value,
            activityCheck: false,
            activityDay: '0',
            activityTime: '8:00',
        };
        onChange(newValue);
    };

    componentDidMount() {
        const { value, onChange } = this.props;
        if (!value) {
            onChange({
                ...defaultValue,
                smsContent: language.getText('smsContent'),
            });
        }
        this.loadMsgDisabled();
    }

    loadMsgDisabled = async () => {
        const { value, onChange } = this.props;
        const res: any = await api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                apiPath:
                    '/admin/template_definitions/template_id?scene=BIRTHDAY_GIFT_RECEIVE_REMIND',
            }
        );
        if (res && res.templateId) {
            this.setState({
                msgDisabled: false,
            });
        } else {
            //如果订阅消息模版没有开启或者开启后又关闭了 ，并且短信也没勾选，那就将之前历史数据保存的活动时间都重置
            if (value) {
                const noReset = value.msgChecked + '' === 'true' ? false : true;
                onChange({
                    ...value,
                    msgChecked: false,
                    activityCheck: noReset ? value.activityCheck : false,
                    activityDay: noReset ? value.activityDay : '0',
                    activityTime: noReset ? value.activityTime : '08:00',
                    memberCheck: noReset ? value.memberCheck : false,
                    memberDay: noReset ? value.memberDay : '0',
                    memberTime: noReset ? value.memberTime : '08:00',
                });
            }
        }
    };
    onChangeValue = (type: string, content: any) => {
        const { value, onChange } = this.props;
        // @ts-ignore
        value[type] = content;
        onChange(value);
    };

    renderMsg = () => {
        const { value } = this.props;
        const { msgDisabled } = this.state;
        if (!value) return null;
        return (
            <div className="birthday-caring-message__choose__item">
                <div className="birthday-caring-message__choose__item__title">
                    <Checkbox
                        disabled={msgDisabled}
                        checked={value.msgChecked}
                        onChange={(event) => {
                            const checked = event.target.checked;
                            this.onChangeValue('msgChecked', checked);
                        }}
                    ></Checkbox>
                    <span className="birthday-caring-message__choose__item__title-text">
                        {language.getText('xcxdyxx')}
                    </span>
                </div>
                <div className="birthday-caring-message__tip">
                    {language.getText('yhaxdydyhcckfsxx')}
                </div>
                {!msgDisabled ? (
                    <div className="birthday-caring-message__choose__item__content">
                        <div>{language.getText('srlwlqsm')}</div>
                        <div>
                            <span className="birthday-caring-message__choose__item__content-left">
                                {language.getText('lwmc')}：
                            </span>
                            {language.getText('dqhdmcc')}
                        </div>
                        <div>
                            <span className="birthday-caring-message__choose__item__content-left">
                                {language.getText('lqmd')}：
                            </span>
                            {language.getText('lqpzdhdmc')}
                        </div>
                        <div>
                            <span className="birthday-caring-message__choose__item__content-left">
                                {language.getText('subsiteAddress')}：
                            </span>
                            {language.getText('dqmddz')}
                        </div>
                        <div>
                            <span className="birthday-caring-message__choose__item__content-left">
                                {language.getText('wxts')}：
                            </span>
                            {language.getText('hyncsrklbwjlql')}
                        </div>
                        <div>
                            <span className="birthday-caring-message__choose__item__content-left">
                                {language.getText('tzlj')}：
                            </span>
                            {language.getText('birthdayPage')}
                        </div>
                    </div>
                ) : (
                    <div className="birthday-caring-message__choose__item__content">
                        <div className="birthday-caring-message__choose__item__content-empty">
                            <div>
                                {language.getText('qqw')}
                                <span
                                    onClick={this.go}
                                    style={{ color: '#1890ff', cursor: 'pointer' }}
                                >
                                    {language.getText('xxts')}
                                </span>
                                {language.getText('cdkgdyxx')}
                            </div>
                            <div>{language.getText('ckfsxx')}</div>
                        </div>
                    </div>
                )}
            </div>
        );
    };

    go = () => {
        window.open('#/subscription-message');
    };

    renderModal = () => {
        return (
            <div className="birthday-caring-message__modal">
                <div>{language.getText('ttpzlghdgz')}</div>
                <div style={{ fontWeight: 'bold' }}>{language.getText('azhdsjfstz')}</div>
                <div>
                    {language.getText('rhdsjwsrdt')}
                    {language.getText('hyhsrdqjtgyhfstt')}
                </div>
                <div>{language.getText('rhdsjwdrdy')}</div>
                <div>{language.getText('rhdsjwsrqjt')}</div>
                <div style={{ fontWeight: 'bold' }}>{language.getText('azhysrhdsjfstz')}</div>
                <div>{language.getText('hysidtjxtz')}</div>
            </div>
        );
    };

    renderTime = () => {
        const { value } = this.props;
        if (!value) return null;
        const dayList: Array<{ value: string; label: string }> = [];
        for (let i = 1; i <= 7; i++) {
            dayList.push({
                value: i + '',
                label: `前${i}天`,
            });
        }
        dayList.push({
            value: '0',
            label: `当天`,
        });
        const timeList: Array<{ value: string; label: string }> = [];
        for (let i = 8; i <= 20; i++) {
            timeList.push({
                value: i.toString().length === 1 ? `0${i}:00` : `${i}:00`,
                label: `${i}:00`,
            });
        }
        const birthdayCaringType = get(this.props, 'row.baseInfo.birthdayCaringType');
        const isShowActivity = birthdayCaringType !== 'SAME_DAY';
        return (
            <div className="birthday-caring-message__time">
                <div>
                    <span className="birthday-caring-message__red">*</span>
                    {language.getText('tzsj')}：
                    <Popover placement="left" content={this.renderModal()} trigger="hover">
                        <Icon type="question-circle" />
                    </Popover>
                </div>
                <div className="birthday-caring-message__time-list">
                    <div className="birthday-caring-message__time-item">
                        <Checkbox
                            checked={value.memberCheck}
                            onChange={(event) => {
                                const checked = event.target.checked;
                                this.onChangeValue('memberCheck', checked);
                            }}
                        ></Checkbox>
                        &nbsp;&nbsp; {language.getText('memberBirthday')}：&nbsp;&nbsp;
                        <Select
                            style={{ width: '100px' }}
                            value={value.memberDay}
                            onChange={(value: any) => {
                                this.onChangeValue('memberDay', value);
                            }}
                        >
                            {dayList.map((day, index) => {
                                return (
                                    <Option value={day.value} key={index}>
                                        {day.label}
                                    </Option>
                                );
                            })}
                        </Select>
                        &nbsp;&nbsp;
                        <Select
                            style={{ width: '100px' }}
                            value={value.memberTime}
                            onChange={(value: any) => {
                                this.onChangeValue('memberTime', value);
                            }}
                        >
                            {timeList.map((time, index) => {
                                return (
                                    <Option value={time.value} key={index}>
                                        {time.label}
                                    </Option>
                                );
                            })}
                        </Select>
                        &nbsp;&nbsp; {language.getText('jxtz')}
                    </div>
                    {isShowActivity && (
                        <div className="birthday-caring-message__time-item">
                            <Checkbox
                                checked={value.activityCheck}
                                onChange={(event) => {
                                    const checked = event.target.checked;
                                    this.onChangeValue('activityCheck', checked);
                                }}
                            ></Checkbox>
                            &nbsp;&nbsp;{language.getText('activityStart')}&nbsp;&nbsp;
                            <Select
                                style={{ width: '100px' }}
                                value={value.activityDay}
                                onChange={(value: any) => {
                                    this.onChangeValue('activityDay', value);
                                }}
                            >
                                {dayList.map((day, index) => {
                                    return (
                                        <Option value={day.value} key={index}>
                                            {day.label}
                                        </Option>
                                    );
                                })}
                            </Select>
                            &nbsp;&nbsp;
                            <Select
                                style={{ width: '100px' }}
                                value={value.activityTime}
                                onChange={(value: any) => {
                                    this.onChangeValue('activityTime', value);
                                }}
                            >
                                {timeList.map((time, index) => {
                                    return (
                                        <Option value={time.value} key={index}>
                                            {time.label}
                                        </Option>
                                    );
                                })}
                            </Select>
                            &nbsp;&nbsp; {language.getText('jxtz')}
                        </div>
                    )}
                    <div className="birthday-caring-message__tip">
                        {language.getText('wpmdyhgddr')}
                    </div>
                    <div className="birthday-caring-message__tip">
                        {language.getText('pzgzsshhwyhfsxxtz')}
                    </div>
                </div>
            </div>
        );
    };

    renderSms = () => {
        const { value } = this.props;
        if (!value) return null;
        return (
            <div className="birthday-caring-message__choose__item">
                <div className="birthday-caring-message__choose__item__title">
                    <Checkbox
                        checked={value.smsChecked}
                        onChange={(event) => {
                            const checked = event.target.checked;
                            this.onChangeValue('smsChecked', checked);
                        }}
                    ></Checkbox>
                    <span className="birthday-caring-message__choose__item__title-text">
                        {' '}
                        {language.getText('dx')}
                    </span>
                </div>
                <div className="birthday-caring-message__choose__item__content">
                    <div>
                        <TextArea
                            onChange={(event: any) => {
                                const value = event.target.value;
                                this.onChangeValue('smsContent', value);
                            }}
                            value={value.smsContent}
                            rows={4}
                            placeholder="请输入"
                            maxLength={500}
                            style={{ width: 400 }}
                        ></TextArea>
                    </div>
                </div>
                <div className="birthday-caring-message__tip" style={{ marginTop: '10px' }}>
                    <div>
                        {language.getText('dxzfs')}
                        <span style={{ color: '#1890ff' }}>{value.smsContent.length}</span>
                        {language.getText('cgqsgzfadtfs')}
                    </div>
                    <div>{language.getText('blzihzddqhdmm')}</div>
                    <div>{language.getText('rgablzdzwfzddqdymc')}</div>
                    <div>{language.getText('alzjdyhndsrl')}</div>
                </div>
            </div>
        );
    };
    render() {
        const { value } = this.props;
        if (!value) return null;
        return (
            <div className="birthday-caring-message">
                <div className="birthday-caring-message__tip" style={{ margin: '8px 0 10px 0' }}>
                    {language.getText('khxmzgtzqddcftj')}
                </div>
                <div className="birthday-caring-message__choose">
                    {this.renderMsg()}
                    {this.renderSms()}
                </div>
                {(value.smsChecked || value.msgChecked) && this.renderTime()}
            </div>
        );
    }
}
