import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export let config: Config = {
    components: {
        MerchantSaleBillView: {
            component: 'Viewport',
            entity: 'MerchantSaleBillEntity',
        },
        MerchantSaleBillPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [{ component: 'MerchantSaleBillTable' }],
        },
        MerchantSaleBillTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'MerchantSaleBillFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'ExportBillButton',
                                    params: {
                                        apiRoot: `${ENV.AUTH_API_ROOT}/DATA-STATISTICS`,
                                        apiPath:
                                            '/admin/merchant_sale_bills/merchant_sale_bills.xls',
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: 'MerchantSaleBillDataTable',
                    loadFirstPage: true,
                    scroll: { x: true },
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'subsiteName',
                            displayConfig: {
                                displayType: 'dynamic',
                                label: 'arrayTreeNodeIdsTree',
                                maxLength: 3,
                            },
                        },
                        {
                            property: 'merchantName',
                        },
                        {
                            property: 'totalSalesAmount',
                        },
                        {
                            property: 'totalRefundAmount',
                        },
                        {
                            property: 'totalGoodsAmount',
                        },
                        {
                            property: 'totalFreightAmount',
                        },
                        {
                            property: 'totalCouponAmount',
                        },
                        {
                            property: 'totalBalanceCouponAmount',
                        },
                        {
                            property: 'totalPointAmount',
                        },
                        {
                            property: 'totalGoldAmount',
                        },
                        {
                            property: 'totalPlatformIncome',
                        },
                        {
                            property: 'totalProcedureFee',
                        },
                        {
                            property: 'totalMerchantSettlementAmount',
                        },
                    ],
                },
            ],
        },
        MerchantSaleBillFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'dateRange',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/merchant-sale-bill',
                    breadcrumbName: '<<merchantSaleBill>>',
                    component: 'MerchantSaleBillView',
                    privilege: {
                        path: 'merchantSaleBill',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'MerchantSaleBillPage' }],
                },
            ],
        },
    ],
};
