import React, { PureComponent, createElement } from 'react';
import { remove, forEach, map, isEmpty } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Icon, message, Modal } from 'antd';
import { Shop } from '../../../select-shop';
import { api } from '@comall-backend-builder/core/lib/services';
import './index.less';
import { getUrlParam } from '../../../../applications/cae/services/utils';

const TableComponentId = 'ShopSelectorTable';
let Entity: any = {};
let entity: any = {};

/**
 * 选择专柜
 */
export class WechatOfficialAutoReplySettingPageStoreSelector extends PureComponent<{
    onChange: (products: Shop[]) => void;
    currentSubsite?: {
        id: number;
        name: string;
    };
    appId?: string;
    appName?: string;
    recordErrorTip: string;
    isWechatOffcialParametricQrcode?: boolean;
}> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('newStoreSelect');
        entity = new Entity({});
    }
    dispatch: any = {};
    state = { visible: false, isWechatOffcialParametricQrcode: false };

    selectedRows = new Array<Shop>();

    onTableRowSelect = (record: Shop, selected: boolean) => {
        if (selected) {
            this.selectedRows = [record];
        } else {
            remove(this.selectedRows, (i) => {
                return i.id === record.id;
            });
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: Shop[]) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            this.selectedRows = [];
        }
    };

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
    };

    onOk = () => {
        const { onChange } = this.props;
        if (onChange) {
            if (isEmpty(this.selectedRows)) {
                message.warning(services.language.getText('qzsxzygmd'));
                return;
            }
            forEach(this.selectedRows, (subsite) => {
                subsite.select = true;
            });
            onChange(this.selectedRows);
            this.toggleModal();
        }
    };
    initEntityFilters = () => {
        entity.filtersChange({
            subSiteName: undefined,
            merchantType: undefined,
            merchantTagId: undefined,
            merchantName: undefined,
            sorter: undefined,
        });
        entity.pageChange({ page: 1 });
        const store = builder.getStore();
        store.dispatch(actions.filtersChangeAction(entity, {}));
        store.dispatch(
            actions.tableSortChangeAction(TableComponentId, { field: undefined, order: undefined })
        );
    };

    getConfig = () => {
        let selectValues: any = [];
        const { currentSubsite } = this.props;
        if (currentSubsite && currentSubsite.id) {
            selectValues = [
                {
                    id: currentSubsite.id,
                },
            ];
        }
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    // style: { textAlign: 'right' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        entity.search();
                    },
                    onReset: () => {
                        this.initEntityFilters();
                    },
                    fields: [
                        {
                            property: 'name',
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    scroll: {
                        y: 400,
                    },
                    pagination: false,
                    rowSelection: {
                        type: 'radio',
                        selectedRowKeys: map(selectValues, 'id'),
                        onSelect: (record: Shop, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: Shop[]) => {
                            this.onTableRowSelectAll(selected, rows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    columns: [
                        {
                            property: 'name',
                        },
                    ],
                },
            ],
        };
    };

    toggleModal = () => {
        const { visible } = this.state;
        //判断filters 当前是否有排序字段 没有则更新页面显示（解决同一组件多场景使用下！.filters.order页面排序却高亮的问题）
        if (entity && (!entity.filters || (entity.filters && !entity.filters.order))) {
            const store = builder.getStore();
            store.dispatch(
                actions.tableSortChangeAction(TableComponentId, {
                    field: undefined,
                    order: undefined,
                })
            );
        }

        this.setState({ visible: !visible }, () => {
            if (!visible && entity.paging) {
                entity.filtersChange({
                    subSiteName: undefined,
                    merchantName: undefined,
                });
                entity.search({
                    ...entity.paging,
                });
                this.onClearAll();
            }
        });
    };

    //跳往授权页面
    setEmpower = () => {
        const { appId } = this.props;
        api.post(
            {
                appId: appId,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                apiPath: `/admin/wechat_official_accounts/auth_page`,
            }
        )
            .then((result: any) => {
                //火狐浏览器window.open出现拦截弹层，采用setTimeout解决
                setTimeout(() => {
                    window.open(result.authPageUrl);
                }, 100);
            })
            .catch((error) => {
                services.errorHandle(error);
            });
    };

    renderTip = () => {
        const { appName, currentSubsite, isWechatOffcialParametricQrcode } = this.props;
        if (!currentSubsite) {
            if (isWechatOffcialParametricQrcode) {
                const appId = getUrlParam(window.location.href, 'appId');
                if (appId) {
                    return null;
                }
                return (
                    <div>
                        <div className="wechant-info">
                            <span>
                                {services.language.getText('wechatOfficialAccount')}：
                                <span style={{ color: '#999' }}>
                                    {services.language.getText('qxxzygmd')}
                                </span>
                            </span>
                        </div>
                    </div>
                );
            }
            return (
                <div className="wechant-info-tip">{services.language.getText('nzwsjqxclxgyw')}</div>
            );
        }
        if (appName) {
            return null;
        }
        return <div className="wechant-info-tip"> {services.language.getText('zwglgzh')}</div>;
    };

    render() {
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 900,
            title: services.language.getText('otherSelectSubsite'),
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };
        const {
            currentSubsite,
            appName,
            recordErrorTip,
            isWechatOffcialParametricQrcode,
        } = this.props;
        const qrcodeEditAppId = getUrlParam(window.location.href, 'appId'); //添加和编辑二维码页面不展示门店信息
        return (
            <div className="wechat-official-auto-reply-setting-page-store-selector">
                {!qrcodeEditAppId && (
                    <div>
                        <span>
                            {isWechatOffcialParametricQrcode
                                ? services.language.getText('otherSelectSubsite')
                                : services.language.getText('subsites')}
                            ：{' '}
                        </span>
                        <Button onClick={this.toggleModal}>
                            {currentSubsite && currentSubsite.name
                                ? currentSubsite.name
                                : services.language.getText('selectSubsite')}
                            <Icon type="down" style={{ marginLeft: 8 }} />
                        </Button>
                    </div>
                )}
                {this.renderTip()}
                {appName && (
                    <div>
                        <div className="wechant-info">
                            {appName && (
                                <span>
                                    <span
                                        style={
                                            !isWechatOffcialParametricQrcode
                                                ? { fontWeight: 'bold', marginLeft: '-15px' }
                                                : undefined
                                        }
                                    >
                                        {services.language.getText('wechatOfficialAccount')}：
                                        {appName}
                                    </span>
                                    {recordErrorTip && (
                                        <Button
                                            className="wechant-info-button"
                                            type="link"
                                            onClick={this.setEmpower}
                                        >
                                            {services.language.getText('ljsq')}
                                        </Button>
                                    )}
                                </span>
                            )}
                        </div>
                        <div className="wechant-info-tip">{recordErrorTip}</div>
                    </div>
                )}
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}
