import React, { PureComponent } from 'react';
import { Button, message as AntMessage, Modal } from 'antd';
import { services } from '@comall-backend-builder/core';
import { errorHandle } from '@comall-backend-builder/core/lib/services';

const language = services.language;
const api = services.api;

interface Props {
    row: any;
    onRefresh: () => void;
}

export class InvoiceRequestLogButtons extends PureComponent<Props, any> {
    isReInvoicing = false;
    reInvoicing = () => {
        const { onRefresh, row } = this.props;
        if (row.success) {
            return;
        }
        if (this.isReInvoicing) {
            return;
        }
        this.isReInvoicing = true;
        const params = row.extension;
        Modal.confirm({
            content: '确定要重新开票吗？',
            iconType: 'warning',
            okType: 'danger',
            onOk: () => {
                api.put(params, {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
                    apiPath: `/admin/invoices`,
                })
                    .then(() => {
                        AntMessage.success(language.getText('cxqqcg'));
                        onRefresh();
                        setTimeout(() => {
                            this.isReInvoicing = false;
                        }, 3000);
                    })
                    .catch((error) => {
                        errorHandle(error);
                        this.isReInvoicing = false;
                    });
            },
            onCancel: () => {
                this.isReInvoicing = false;
            },
        });
    };
    render() {
        const { row } = this.props;
        return (
            <span className="invoice-request-log-buttons">
                <Button
                    type="link"
                    onClick={this.reInvoicing}
                    disabled={row.success}
                    className="invoice-request-log-buttons__frist"
                >
                    {language.getText('cxqq')}
                </Button>
            </span>
        );
    }
}
