import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { CollectionPointsTimesRule } from './collection-points-times-rule';

export class CollectionPointsTimesRuleMode extends modes.ObjectMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <CollectionPointsTimesRule {...displayInfo} />;
    }
}
