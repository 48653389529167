import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';

import { SubsiteLinkTypeSelector } from './subsite-link-type-selector';

export class SubsiteLinkTypeSelectorMode extends ObjectMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(object: any, displayInfo: any) {
        return (
            <SubsiteLinkTypeSelector
                value={object}
                disabled
                {...displayInfo}
            ></SubsiteLinkTypeSelector>
        );
    }
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <SubsiteLinkTypeSelector {...controlInfo}></SubsiteLinkTypeSelector>;
    }
}
