import React, { PureComponent, createElement } from 'react';
import { get, defaults } from 'lodash';
import { connect } from 'react-redux';
import { ComponentsManager, EntitiesManager, services } from '@comall-backend-builder/core';
import { Modal, message as AntMessage } from 'antd';
import { internalPurchaseActivityId } from '../goods-manage-button';
const api = services.api;
let Entity: any = {};
let entity: any = {};
/**
 * 批量添加商品
 */
class internalPurchaseBatchAddGoods extends PureComponent<any, any> {
    onSubmit = (event: any, fields: any) => {
        event.stopPropagation();
        const { getForm, selectedGoods } = this.props;
        const form = getForm();
        form.validateFields(['discount', 'memberLimit', 'tagIds'], { force: true }).then(
            (entityFields: any) => {
                if (entityFields) {
                    const hasValidate = this.validate(entityFields);
                    if (hasValidate) {
                        let goodsIds = selectedGoods.map((item: any) =>
                            item.goodsMvo ? item.goodsMvo.id : item.goodsId
                        );
                        let params: any = {};
                        params = entityFields;
                        params.tagIds = entityFields.tagIds
                            ? entityFields.tagIds.map((item: any) => item.id)
                            : null;
                        params.goodsIds = goodsIds;
                        params.activityId = internalPurchaseActivityId;
                        api.post(entityFields, {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/internal_purchase_goods',
                        }).then(
                            () => {
                                AntMessage.success(services.language.getText('common.saveSuccess'));
                                this.onCancel();
                            },
                            (error) => {
                                services.errorHandle(error);
                            }
                        );
                    }
                }
            }
        );

        return;
    };
    /**
     * 提交前额外验证
     * @param entityFields
     */
    validate = (entityFields: any) => {
        let discount: any = get(entityFields, 'discount', '');
        const reg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;
        if ((discount && !reg.test(discount)) || discount === 0) {
            AntMessage.warning(services.language.getText('jzcszdy'));
            return false;
        }
        let memberLimit: any = get(entityFields, 'memberLimit', '');
        let numberReg = /^([1-9]\d*)$/;
        if ((memberLimit && !numberReg.test(memberLimit)) || memberLimit === 0) {
            AntMessage.warning('个人限购数请输入正整数');
            return false;
        }

        return true;
    };

    renderModal = () => {
        const { wrappedComponentRef } = this.props;
        Entity = EntitiesManager.get('InternalPurchaseGoodsEntity');
        entity = new Entity({});
        let formProps = {
            entity,
            wrappedComponentRef: wrappedComponentRef,
            componentId: 'internalPurchaseBatchAddGoods',
            onSubmit: this.onSubmit,
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 14,
            style: { width: 620 },
            fields: [
                {
                    property: 'discount',
                },
                { property: 'memberLimit' },
                { property: 'tagIds' },
            ],
            submit: false,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        onClick: this.onSubmit,
                        text: '确定',
                        type: 'primary',
                        style: { marginLeft: 10 },
                    },
                    {
                        htmlType: 'button',
                        onClick: this.onCancel,
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
        };
        return createElement(ComponentsManager.get('CreateForm'), formProps);
    };

    onCancel = () => {
        const { onCancel } = this.props;
        onCancel && onCancel();
    };

    render() {
        const { visible } = this.props;
        const modalProps = {
            width: 800,
            title: '批量添加商品',
            visible: visible,
            footer: null,
            onCancel: this.onCancel,
        };

        return (
            <Modal {...modalProps}>
                <div>{this.renderModal()}</div>
            </Modal>
        );
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit } = props;
    let form: any;

    return defaults(
        {
            onSubmit,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
        }
    );
}
export const InternalPurchaseBatchAddGoods = connect(
    null,
    mapDispatchToProps
)(internalPurchaseBatchAddGoods);
