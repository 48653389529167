import React, { PureComponent } from 'react';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { DesignPage } from '@comall-backend-builder/design-components-basis';
import { CustomComponentSelector } from '../../design/components/custom-component-selector';
import { WfjIndexComponentSelector } from '../../design/components/wfj-index-component-selector';
import { PlatformHomeComponentSelector } from '../../design/components/platform-home-component-selector';
import {
    DESIGN_PAGE_LINK_TYPES_LIST,
    PLATFORM_PAGE_LINK_TYPES_LIST,
} from '../../constants/page-design';
import { errorHandle } from '../../../cae/services/error-handle';
const api = services.api;

/**
 * 当前门店组
 */
let subsites: any = {};

export { subsites };

const tenantDomain = ENV.TENANT_DOMAIN || window.location.origin;
/**
 * 可视化--页面设计
 */
export class PageDesign extends PureComponent<any> {
    componentWillMount() {
        subsites = {};
    }
    loadProfile = () => {
        const {
            params: { id },
            apiPath,
        } = this.props;
        return api.get({ id }, { apiPath: apiPath }).then((res: any) => {
            subsites = res.subsites;
            return res;
        });
    };
    onSave = (data: any) => {
        const { apiPath } = this.props;
        api.put(data, { apiPath: apiPath })
            .then(() => {
                const saveSuccess = services.language.getText('common.saveSuccess');
                message.success(saveSuccess);
                services.navigation.goBack();
            })
            .catch(errorHandle);
    };
    onCancel = () => {
        services.navigation.goBack();
    };

    componentWillUnmount() {
        subsites = {};
    }
    getClientUrl = (profile: any, fixed: boolean) => {
        const { client } = this.props;
        let url = client + `?page=WYSIWG&mode=edit`;
        if (profile) {
            url =
                url +
                `&type=${profile.metainfo.type}&fixed=${fixed}&tenant=${encodeURIComponent(
                    tenantDomain
                )}`;
        }
        if (subsites && subsites.length > 0) {
            url = url + `&subsites=${JSON.stringify(subsites.slice(0, 1))}`;
        }
        return url;
    };

    render() {
        const submitProps = {
            onClick: this.onSave,
        };
        const cancelProps = {
            onClick: this.onCancel,
        };

        const { type } = this.props;
        if (type === 'home' && ENV.TENANT === 'wfj') {
            return (
                <DesignPage
                    clientUrl={this.getClientUrl}
                    loadProfile={this.loadProfile}
                    componentsSelector={(props) => <WfjIndexComponentSelector {...props} />}
                    submit={submitProps}
                    cancel={cancelProps}
                    verbose={process.env.NODE_ENV === 'development'}
                    isTemplate={false}
                    linkTypes={DESIGN_PAGE_LINK_TYPES_LIST}
                    linkTypeMode={'cascader'}
                    uploadAction={`${ENV.API_ROOT}/admin/images/upload`}
                ></DesignPage>
            );
        }

        if (type === 'platform') {
            return (
                <DesignPage
                    clientUrl={this.getClientUrl}
                    loadProfile={this.loadProfile}
                    componentsSelector={(props) => <PlatformHomeComponentSelector {...props} />}
                    submit={submitProps}
                    cancel={cancelProps}
                    verbose={process.env.NODE_ENV === 'development'}
                    isTemplate={false}
                    linkTypes={PLATFORM_PAGE_LINK_TYPES_LIST}
                    linkTypeMode={'cascader'}
                    uploadAction={`${ENV.API_ROOT}/admin/images/upload`}
                ></DesignPage>
            );
        }

        const {
            params: { id },
            apiPath,
        } = this.props;
        return (
            <DesignPage
                clientUrl={this.getClientUrl}
                loadProfile={this.loadProfile}
                componentsSelector={(props) => (
                    <CustomComponentSelector {...props} apiPath={apiPath} currentPageID={id} />
                )}
                submit={submitProps}
                cancel={cancelProps}
                verbose={process.env.NODE_ENV === 'development'}
                isTemplate={false}
                linkTypes={DESIGN_PAGE_LINK_TYPES_LIST}
                linkTypeMode={'cascader'}
                uploadAction={`${ENV.API_ROOT}/admin/images/upload`}
            ></DesignPage>
        );
    }
}
