import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Form, Input, Button as AntButton, message, Spin } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { api, behaviorHandle, language } from '@comall-backend-builder/core/lib/services';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { Button } from '@comall-backend-builder/components-basis';
import { get } from 'lodash';
import { errorHandle } from '../../services/error-handle';
import { passwrodReg } from '../../services/utils';
import { services } from '@comall-backend-builder/core';

/**
 * 重置密码
 */
interface ResetPasswordProps {
    form: FormComponentProps['form'];
    entity: Entity;
    params: any;
}
const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 6 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 10 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 0,
        },
        sm: {
            span: 10,
            offset: 6,
        },
    },
};
class resetPassword extends PureComponent<ResetPasswordProps, any> {
    constructor(props: ResetPasswordProps) {
        super(props);
        this.state = {
            confirmDirty: false,
            spinning: false,
        };
    }
    componentWillMount() {
        const { entity } = this.props;
        entity.get({});
    }
    compareToFirstPassword = (rule: any, value: any, callback: any) => {
        const { form } = this.props;
        if (value && value !== form.getFieldValue('password')) {
            callback('两次密码输入不一致!');
        } else {
            callback();
        }
    };

    validateToNextPassword = (rule: any, value: any, callback: any) => {
        const { form } = this.props;
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirm'], { force: true });
        }
        callback();
    };
    handleConfirmBlur = (e: any) => {
        const { value } = e.target;
        this.setState({ confirmDirty: this.state.confirmDirty || !!value });
    };
    handleSubmit = (e: any) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                this.setState({ spinning: true });
                const { params } = this.props;
                const id = get(params, 'id', null);
                api.put(
                    { password: values.password },
                    {
                        apiPath: `/admin/users/${id}/password`,
                        apiRoot: `${ENV.AUTH_API_ROOT}/CAE-PRIVILEGE`,
                    }
                )
                    .then(() => {
                        message.success(language.getText('common.saveSuccess'), () =>
                            behaviorHandle({ route: 'goBack' })
                        );
                    })
                    .catch(errorHandle)
                    .finally(() => {
                        this.setState({ spinning: false });
                    });
            }
        });
    };
    render() {
        const {
            form: { getFieldDecorator },
            entity,
        } = this.props;
        const { spinning } = this.state;
        return (
            <Spin spinning={spinning}>
                <div style={{ width: 720 }}>
                    <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                        <Form.Item label="登录账户">
                            <span className="ant-form-text">
                                {get(entity, 'fields.userName', '-')}
                            </span>
                        </Form.Item>
                        <Form.Item label="新密码">
                            {getFieldDecorator('password', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请输入新密码!',
                                    },
                                    {
                                        pattern: passwrodReg,
                                        message: '请输入8~16位密码，数字、字母、字符至少包含两种',
                                    },
                                    {
                                        validator: this.validateToNextPassword,
                                    },
                                ],
                            })(<Input.Password maxLength={16} placeholder="请输入新密码" />)}
                        </Form.Item>
                        <Form.Item label="新密码确认">
                            {getFieldDecorator('confirm', {
                                rules: [
                                    {
                                        required: true,
                                        message: '请再次确认新密码!',
                                    },
                                    {
                                        validator: this.compareToFirstPassword,
                                    },
                                ],
                            })(
                                <Input.Password
                                    maxLength={16}
                                    onBlur={this.handleConfirmBlur}
                                    placeholder="请再次输入新密码"
                                />
                            )}
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout}>
                            <AntButton type="primary" htmlType="submit">
                                提交
                            </AntButton>
                            <Button
                                style={{ marginLeft: 10 }}
                                route="goBack"
                                text={services.language.getText('back')}
                            />
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
        );
    }
}
export const ResetPassword = connect((state: any, props: ResetPasswordProps) => {
    const { entity } = props;
    return {
        fields: entity.fields,
    };
}, null)(Form.create()(resetPassword));
