import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Modal, Radio } from 'antd';
import { get } from 'lodash';
import { Button } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { services } from '@comall-backend-builder/core';
import { PhotoCreditReviewEditAgreeForm } from './photo-credit-review-edit-agree-form';
import { PhotoCreditReviewDisagreeForm } from '../../components/photo-credit-review-disagree-form';
import { PhotoCreditReviewPreviewPicture } from '../photo-credit-review-preview-picture';

import './index.less';

const api = services.api;
const NO_DATA = null;

enum StatusType {
    /**
     * 通过
     */
    SUCCESS = 'SUCCESS',
    /**
     * 不通过
     */
    FAIL = 'FAIL',
}

export const PhotoCraditReviewListSubmitButton = (props: any) => {
    const [visible, setVisible] = useState<boolean>(false);
    const [statusType, setStatusType] = useState<StatusType>(StatusType.SUCCESS);
    const [data, setData] = useState<any>(NO_DATA);
    const submitRef = useRef<any>();
    const imgUrl = get(props, 'row.imgUrl');
    const id = get(props, 'row.id');
    const status = get(props, 'row.status');

    const refreshList = useCallback(() => {
        handleVisible();
        props.entity.search(props.params);
    }, [props.entity, props.params]);

    useEffect(() => {
        async function loadData() {
            const params = {
                id,
            };
            const config = {
                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`,
                apiPath: '/admin/photo_crediting/:id',
            };
            const result: any = await api.get(params, config);
            result.shop = {
                id: result.merchantId,
                subSiteId: result.susbiteId,
                subSiteName: result.subsiteName,
                merchantId: result.merchantId,
                merchantName: result.merchantName,
                merchantType: result.merchantType,
                merchantStatus: result.merchantStatus,
                code: result.code,
                select: true,
            };
            setData(result);
        }
        if (visible && data === NO_DATA && status === 'EXCEPTION') {
            loadData();
        }
        if (visible) {
            setStatusType(StatusType.SUCCESS);
        }
    }, [visible, data, id, status]);

    if (status !== 'EXCEPTION') {
        return null;
    }

    function handleVisible(): void {
        setVisible((value) => !value);
    }

    function handleSubmit(): void {
        if (!submitRef || typeof submitRef.current !== 'function') {
            return;
        }

        submitRef.current();
    }

    function handleDownload(): void {
        window.open(imgUrl, '_self');
    }

    function handleStatusType(event: RadioChangeEvent): void {
        setStatusType(event.target.value);
    }

    return (
        <span className="photo-credit-review-list-submit-button">
            <Button style={{ padding: 0, margin: 0 }} type="link" onClick={handleVisible}>
                重新提交
            </Button>
            <Modal
                destroyOnClose
                visible={visible}
                title={services.language.getText('txshxx')}
                width="1100px"
                okText={statusType === StatusType.SUCCESS ? '同意' : '不同意'}
                onCancel={handleVisible}
                onOk={handleSubmit}
            >
                <div className="photo-credit-review-list-submit-button__modal">
                    <div className="photo-credit-review-list-submit-button__ticket">
                        <PhotoCreditReviewPreviewPicture imgUrl={imgUrl} />
                        <Button
                            className="photo-credit-review-list-submit-button__ticket-download"
                            onClick={handleDownload}
                        >
                            下载小票图
                        </Button>
                    </div>
                    <div>
                        <div className="photo-credit-review-list-submit-button__select">
                            <Radio.Group onChange={handleStatusType} value={statusType}>
                                <Radio value={StatusType.SUCCESS}>通过</Radio>
                                <Radio value={StatusType.FAIL}>
                                    {services.language.getText('btg')}
                                </Radio>
                            </Radio.Group>
                        </div>
                        {statusType === StatusType.SUCCESS && data ? (
                            <PhotoCreditReviewEditAgreeForm
                                data={data}
                                params={{
                                    id,
                                    status: StatusType.SUCCESS,
                                }}
                                refreshList={refreshList}
                                submitRef={submitRef}
                            />
                        ) : (
                            <PhotoCreditReviewDisagreeForm
                                params={{
                                    id,
                                    status: StatusType.FAIL,
                                }}
                                refreshList={refreshList}
                                submitRef={submitRef}
                            />
                        )}
                    </div>
                </div>
            </Modal>
        </span>
    );
};
