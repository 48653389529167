import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;
export const ActivationPromotionEncourageLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/activation_activities';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/activation_activities/${id}`;
        } else {
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.id = res.activityVo.id;
                res.baseInfo = {
                    eventType: res.activityVo.eventType,
                    name: res.activityVo.name,
                    dateRange: {
                        start: res.activityVo.startTime,
                        end: res.activityVo.endTime,
                    },
                };
                if (res.activityVo.rewardConfig) {
                    res.encourageInfo = {
                        rewardRule: res.activityVo.rewardConfig.rewardRule,
                        cycleRewardRule: res.activityVo.rewardConfig.activityRewards,
                        ladderRewardRule: res.activityVo.rewardConfig.activityRewards,
                        activityRewardLimit: res.activityVo.rewardConfig.activityRewardLimit,
                    };
                }
                res.encourageInfo.noteTypes = res.noteTypes;
                res.encourageInfo.creationRewardType = res.creationRewardType;
                res.encourageInfo.rewardCalculation = res.rewardCalculation;
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/activation_activities';
        let activityRewards;
        let newParams: any = {};
        if (params.baseInfo) {
            newParams.activityVo = {
                eventType: params.baseInfo.eventType,
                name: params.baseInfo.name,
                startTime: params.baseInfo.dateRange.start,
                endTime: params.baseInfo.dateRange.end,
            };
        }
        if (params.encourageInfo) {
            if (params.encourageInfo.rewardRule === 'CYCLE') {
                activityRewards = params.encourageInfo.cycleRewardRule;
            } else {
                activityRewards = params.encourageInfo.ladderRewardRule;
            }
            newParams.noteTypes = params.encourageInfo.noteTypes;
            newParams.creationRewardType = params.encourageInfo.creationRewardType;
            newParams.activityVo.rewardConfig = {
                rewardRule: params.encourageInfo.rewardRule,
                activityRewards: activityRewards,
                activityRewardLimit: params.encourageInfo.activityRewardLimit,
            };
            newParams.rewardCalculation = params.encourageInfo.rewardCalculation;
        }
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        if (params.id) {
            config.apiPath = `/admin/activation_activities/${params.id}`;
        }
        let newParams: any = {};
        if (params.baseInfo) {
            newParams.name = params.baseInfo.name;
            newParams.startTime = params.baseInfo.dateRange.start;
            newParams.endTime = params.baseInfo.dateRange.end;
        }
        if (params.encourageInfo) {
            newParams.noteTypes = params.encourageInfo.noteTypes;
            newParams.creationRewardType = params.encourageInfo.creationRewardType;
            let activityRewards;
            if (params.encourageInfo.rewardRule === 'CYCLE') {
                activityRewards = params.encourageInfo.cycleRewardRule;
            } else {
                activityRewards = params.encourageInfo.ladderRewardRule;
            }
            newParams.rewardConfig = {
                rewardRule: params.encourageInfo.rewardRule,
                activityRewards: activityRewards,
                activityRewardLimit: params.encourageInfo.activityRewardLimit,
            };
            newParams.rewardCalculation = params.encourageInfo.rewardCalculation;
        }
        return await api.put(newParams, config);
    },
};
