import React, { PureComponent } from 'react';
import { Checkbox, Radio } from 'antd';
import { cloneDeep } from 'lodash';
import { services } from '@comall-backend-builder/core';

const { getText } = services.language;

export enum InvoiceModeEnum {
    /**
     * 税控发票
     */
    TAX_CONTROL = 'TAX_CONTROL',
    /**
     * 数电票
     */
    DIGITAL_ELECTRONIC = 'DIGITAL_ELECTRONIC',
}

interface Props {
    row: any;
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    value: InvoiceModeEnum.TAX_CONTROL;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: InvoiceModeEnum, name: string) => void;
}

export class InvoiceMode extends PureComponent<Props> {
    componentDidMount() {
        let { name, value, onChange } = this.props;
        if (!value) {
            if (onChange) {
                onChange(InvoiceModeEnum.TAX_CONTROL, name);
            }
        }
    }

    onChangeMerchantScope = (scope: InvoiceModeEnum) => {
        const { name, onChange } = this.props;
        onChange(cloneDeep(scope), name);
    };

    render() {
        const { value } = this.props;
        return (
            <div style={{ marginTop: '9px' }}>
                <Radio.Group
                    value={value}
                    onChange={(e: any) => {
                        this.onChangeMerchantScope(e.target.value);
                    }}
                >
                    <div>
                        <Radio value={InvoiceModeEnum.TAX_CONTROL}>{getText('skfp')}</Radio>
                        <Checkbox
                            style={{ marginLeft: '20px' }}
                            checked={value && value === InvoiceModeEnum.TAX_CONTROL ? true : false}
                            disabled
                        >
                            {getText('ptdzfp')}
                        </Checkbox>
                    </div>
                    <div style={{ marginTop: '9px' }}>
                        <Radio value={InvoiceModeEnum.DIGITAL_ELECTRONIC}>{getText('sdp')}</Radio>
                        <Checkbox
                            style={{ marginLeft: '34px' }}
                            checked={value && value !== InvoiceModeEnum.TAX_CONTROL ? true : false}
                            disabled
                        >
                            {getText('ptdzfp')}
                        </Checkbox>
                        <div style={{ margin: '5px 0 0 23px', color: '#ccc' }}>
                            {getText('h_invoice_mode_tip')}
                        </div>
                    </div>
                </Radio.Group>
            </div>
        );
    }
}
