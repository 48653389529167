import React, { PureComponent } from 'react';
import { Button } from 'antd';
import { services } from '@comall-backend-builder/core';
import { SelectCoupon } from '../select-coupon';
import { SelectPackageCoupon } from '../select-coupon-package';

const language = services.language;
export class CouponSelector extends PureComponent<
    {
        value: any;
        contentConfig: Record<string, any>;
        extraConfig?: Record<string, any>;
        modalConfig: Record<string, any>;
        extraModalConfig?: Record<string, any>;
        onChange: (value: any) => void;
    },
    { visibleConponSelectModal: boolean; visibleConponPackageSelectModal: boolean }
> {
    state = {
        visibleConponSelectModal: false,
        visibleConponPackageSelectModal: false,
    };

    showCouponSelectModal = () => {
        this.setState({ visibleConponSelectModal: true });
    };

    showCouponPackageSelectModal = () => {
        this.setState({ visibleConponPackageSelectModal: true });
    };

    hideCouponSelectModal = () => {
        this.setState({ visibleConponSelectModal: false });
    };

    hideCouponPackageSelectModal = () => {
        this.setState({ visibleConponPackageSelectModal: false });
    };

    onChange = (value: any) => {
        const { onChange } = this.props;
        this.hideCouponSelectModal();
        onChange(value);
    };

    render() {
        const {
            value: { linkParams },
            contentConfig,
            extraConfig,
            modalConfig,
            extraModalConfig,
        } = this.props;

        const { visibleConponSelectModal, visibleConponPackageSelectModal } = this.state;
        const selectProps = {
            value: linkParams,
            onChange: this.onChange,
        };
        return (
            <span className="link-name">
                <Button type="link" onClick={this.showCouponSelectModal} className="link-type-edit">
                    {language.getText('components.Tools.selectCoupon')}
                </Button>
                <Button
                    type="link"
                    onClick={this.showCouponPackageSelectModal}
                    className="link-type-edit"
                >
                    {language.getText('components.Tools.selectCouponPackage')}
                </Button>
                <SelectCoupon
                    contentConfig={contentConfig}
                    modalConfig={modalConfig}
                    showModal={visibleConponSelectModal}
                    onCancel={this.hideCouponSelectModal}
                    {...selectProps}
                />
                <SelectPackageCoupon
                    contentConfig={extraConfig}
                    modalConfig={extraModalConfig}
                    showModal={visibleConponPackageSelectModal}
                    onCancel={this.hideCouponPackageSelectModal}
                    {...selectProps}
                />
            </span>
        );
    }
}
