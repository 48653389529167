import React, { PureComponent } from 'react';
import { tools, Products } from '@comall-backend-builder/design-components-basis';

/**
 * 首页内容列表组件配置表单
 */
class virtualProducts extends PureComponent<any> {
    render() {
        return <Products {...this.props}></Products>;
    }
}

export const VirtualProducts = tools.ConfigFormDecorator(virtualProducts);
