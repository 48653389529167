import React, { Component } from 'react';
import { Popover, Icon } from 'antd';
import { modes } from '@comall-backend-builder/types';
import { Checkbox } from 'antd';

export class ArrayOptionIdsCheckboxPopoverMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <CheckboxPopover {...displayInfo} />;
    }
}

class CheckboxPopover extends Component<any, any> {
    render() {
        const {
            value,
            className,
            style,
            disabled,
            options,
            onChange,
            popoverConfigs,
            name,
        } = this.props;

        const checkboxGroupProps = {
            name,
            value,
            className,
            style,
            disabled,
            onChange,
        };
        return (
            <div>
                <Checkbox.Group {...checkboxGroupProps}>
                    {options.map((option: any, index: number) => {
                        const popoverConfig = popoverConfigs[index];

                        return (
                            <Checkbox
                                key={option.id}
                                value={option.id}
                                disabled={option.disabled ? option.disabled : false}
                            >
                                {option.name}
                                {popoverConfig &&
                                    (popoverConfig?.contentText || popoverConfig?.imageUrl) && (
                                        <Popover
                                            autoAdjustOverflow
                                            placement={
                                                popoverConfig.placement
                                                    ? popoverConfig.placement
                                                    : 'bottom'
                                            }
                                            content={
                                                <div style={{ maxWidth: 400 }}>
                                                    {popoverConfig.imageUrl && (
                                                        <img
                                                            src={popoverConfig.imageUrl}
                                                            width="400px"
                                                            alt=""
                                                        />
                                                    )}
                                                    {popoverConfig.contentText && (
                                                        <div>{popoverConfig.contentText}</div>
                                                    )}
                                                </div>
                                            }
                                        >
                                            {popoverConfig.showIcon && (
                                                <Icon
                                                    className="tips-modal-icon"
                                                    style={{ marginLeft: '5px' }}
                                                    type={
                                                        popoverConfig.iconType
                                                            ? popoverConfig.iconType
                                                            : 'question-circle'
                                                    }
                                                />
                                            )}
                                            {!!popoverConfig.text && (
                                                <span className="tips-modal-text">
                                                    {popoverConfig.text}
                                                </span>
                                            )}
                                        </Popover>
                                    )}
                            </Checkbox>
                        );
                    })}
                </Checkbox.Group>
            </div>
        );
    }
}
