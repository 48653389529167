import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { isEmpty, forEach, get } from 'lodash';
import { TipsModalContentType } from '../../components';
import { getUrlParam } from '../../applications/cae/services/utils';

function getDefaultValue() {
    const subsiteId = getUrlParam(window.location.hash, 'subsiteId');
    return subsiteId
        ? {
              id: subsiteId,
          }
        : {};
}

export const config: Config = {
    entities: {
        MerchantPickUpDeliveryEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: '/loader/admin/merchant_pick_up_delivery',
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 200 },
                    },
                },
                merchantId: {
                    // type: 'string.filterOptions.select',
                    type: 'string.options.autoComplete',
                    displayName: '<<merchantName>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/delivery_subsites_merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 200 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                    },
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<ztdxx>>',
                    properties: {
                        merchants: {
                            type: 'array.shop.selector',
                            displayName: '<<selectSubsites>>',
                            controlConfig: {
                                type: 'radio',
                                fields: [
                                    {
                                        property: 'subSiteId',
                                    },
                                    {
                                        property: 'merchantName',
                                    },
                                    {
                                        property: 'merchantTagId',
                                    },
                                ],
                                formColumns: [
                                    {
                                        property: 'subSiteName',
                                    },
                                    {
                                        property: 'merchantName',
                                    },
                                    {
                                        property: 'merchantCode',
                                        sorter: true,
                                    },
                                    {
                                        property: 'merchantType',
                                    },
                                    {
                                        property: 'merchantStatus',
                                    },
                                ],
                                isDeliveryShop: true,
                            },
                            defaultValue: [],
                            rules: [
                                {
                                    message: '<<pleaseSelectMerchant>>',
                                    validator: (rule: any, value: any) => {
                                        if (value && value.length > 0) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                        },
                        address: {
                            type: 'string.tip',
                            displayName: '<<ztdz>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qtxztdxxdz>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<qtxztdxxdz>>',
                                minLength: 5,
                                maxLength: 50,
                            },
                        },
                        pickUpTime: {
                            type: 'string',
                            displayName: '<<ztsjsm>>',
                            controlConfig: {
                                placeholder: '<<qtxztsjsm>>',
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        reminder: {
                            type: 'string.modalTips',
                            displayName: '<<wxts>>',
                            controlConfig: {
                                placeholder: '<<jytxrqnsdztdxhddzte>>',
                                style: {
                                    width: 450,
                                },
                                tipsModalConfig: {
                                    contentType: TipsModalContentType.DeliveryReminder,
                                    showIcon: false,
                                    text: '<<viewExample>>',
                                    textColor: '#1890ff',
                                    title: '<<example>>',
                                    width: 648,
                                },
                            },
                            extra: '<<reminderExtra>>',
                        },
                    },
                },
                chooseInfo: {
                    type: 'object.subForm',
                    displayName: '<<ztgz>>',
                    properties: {
                        reservedTimeHour: {
                            type: 'number.tip',
                            displayName: '<<bhsj>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<inputThanZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && (!reg.test(value) || value === 0)) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 100,
                                },
                                addonbefore: '<<sjzstq>>',
                                addonafter: '<<xsbh>>',
                                tip: '',
                            },
                        },
                        topLimitDay: {
                            type: 'number.tip',
                            displayName: '<<topLimitDay>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<inputThanZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && (!reg.test(value) || value === 0)) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 100,
                                },
                                addonbefore: '<<zc_2>>',
                                addonafter: '<<tnth>>',
                                tip: '',
                            },
                        },
                        isDeliveryTimePeriods: {
                            type: 'string.options.radio',
                            displayName: '<<mjkxztsj>>',
                            defaultValue: 'NO',
                            options: [
                                { id: 'NO', name: '<<common.close>>' },
                                { id: 'YES', name: '<<enable>>' },
                            ],
                            extra: '<<mjzjsykyxzpssj>>',
                        },
                        deliveryTimePeriods: {
                            type: 'array.presaleTimepicker',
                            displayName: '<<kztsjd>>',
                            className: 'presale-range-timepicker',
                            rules: [
                                {
                                    message: '<<mgsjdbkzf>>',
                                    validator: (rule: any, value: any) => {
                                        let result: Array<boolean> = [];
                                        if (value !== null && !isEmpty(value)) {
                                            // 如果是交叉时间 就提示
                                            forEach(value, (outerItem, outerIndex) => {
                                                forEach(value, (item, index) => {
                                                    if (
                                                        !isEmpty(outerItem) &&
                                                        !isEmpty(item) &&
                                                        outerIndex !== index
                                                    ) {
                                                        let valueStart = new Date(
                                                            '2020-01-01 ' + outerItem.start
                                                        ).getTime();
                                                        let valueEnd = new Date(
                                                            '2020-01-01 ' + outerItem.end
                                                        ).getTime();
                                                        let itemStart = new Date(
                                                            '2020-01-01 ' + item.start
                                                        ).getTime();
                                                        let itemEnd = new Date(
                                                            '2020-01-01 ' + item.end
                                                        ).getTime();

                                                        if (valueStart - itemEnd >= 0) {
                                                            result.push(true);
                                                        } else if (valueEnd - itemStart <= 0) {
                                                            result.push(true);
                                                        } else {
                                                            result.push(false);
                                                        }
                                                    }
                                                });
                                            });
                                        }
                                        if (result.indexOf(false) > -1) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                format: 'HH:mm',
                                tip: '<<qasxtj>>',
                                deleteButtontext: '<<common.delete>>',
                                addButtonText: '<<tjsjd>>',
                            },
                        },
                    },
                },
                ruleInfo: {
                    type: 'object.subForm',
                    displayName: '<<wasthcl>>',
                    properties: {
                        orderHandleMode: {
                            type: 'string.options.radio',
                            displayName: '<<gqwthddsfgb>>',
                            defaultValue: 'NOT_HANDLE',
                            options: [
                                { id: 'PART_HANDLE', name: '<<yes>>' },
                                { id: 'NOT_HANDLE', name: '<<no>>' },
                            ],
                        },
                        activityProductType: {
                            type: 'string.options.radio',
                            displayName: '<<activityProduct>>',
                            defaultValue: 'ALL',
                            options: [
                                { id: 'ALL', name: '<<allProduct>>' },
                                { id: 'INCLUDE_PRODUCT', name: '<<includeProduct>>' },
                                { id: 'EXCLUDE_PRODUCT', name: '<<excludeProduct>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        goods: {
                            type: 'object.productCategory.selector',
                            displayName: '<<szsp>>',
                            controlConfig: {
                                selectProductTypes: ['NORMAL,WEIGHT,COMBINE'],
                                verifyName: 'baseInfo.merchants',
                                verifyTip: '<<pleaseSelectMerchant>>',
                                handleSelectMode: (row: any) => {
                                    return 'MERCHANT';
                                },
                                productDisplaySelectValueType: 'goods',
                            },
                        },
                        pickUpLimitDays: {
                            type: 'number.tip',
                            displayName: '<<gqtssz>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 0,
                                    message: '<<qsrdydy>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && !reg.test(value)) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 100,
                                },
                                addonbefore: '<<gq>>',
                                addonafter: '<<thwth>>',
                            },
                        },
                    },
                },
                id: {
                    type: 'number.integer',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                code: {
                    type: 'string',
                    displayName: '<<merchantCode>>',
                },
                address: {
                    type: 'string',
                    displayName: '<<ztdz>>',
                },
                pickUpTime: {
                    type: 'string',
                    displayName: '<<ztsj>>',
                },
                reminder: {
                    type: 'string',
                    displayName: '<<wxts>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    defaultValue: 'true',
                    options: [
                        { id: 'true', name: '<<enabled>>' },
                        { id: 'false', name: '<<beforeClose>>' },
                    ],
                },
                status: {
                    type: 'string',
                    displayName: '<<status>>',
                },
            },
        },
        MerchantExpressDeliveryEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: '/loader/admin/merchant_express_delivery',
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 300 },
                    },
                },
                merchantId: {
                    // type: 'string.filterOptions.select',
                    type: 'string.options.autoComplete',
                    displayName: '<<merchantName>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/delivery_subsites_merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    },
                    controlConfig: {
                        style: { width: 300 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                    },
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseInfo>>',
                    properties: {
                        merchants: {
                            type: 'array.shop.selector',
                            displayName: '<<selectSubsites>>',
                            controlConfig: {
                                type: 'radio',
                                fields: [
                                    {
                                        property: 'subSiteId',
                                    },
                                    {
                                        property: 'merchantName',
                                    },
                                    {
                                        property: 'merchantTagId',
                                    },
                                ],
                                formColumns: [
                                    {
                                        property: 'subSiteName',
                                    },
                                    {
                                        property: 'merchantName',
                                    },
                                    {
                                        property: 'merchantCode',
                                        sorter: true,
                                    },
                                    {
                                        property: 'merchantType',
                                    },
                                    {
                                        property: 'merchantStatus',
                                    },
                                ],
                                isDeliveryShop: true,
                            },
                            defaultValue: [],
                            rules: [
                                {
                                    message: '<<pleaseSelectMerchant>>',
                                    validator: (rule: any, value: any) => {
                                        if (value && value.length > 0) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                        },
                    },
                },
                freightInfo: {
                    type: 'object.subForm',
                    displayName: '<<yfgz>>',
                    properties: {
                        name: {
                            type: 'string.tip',
                            displayName: '<<gzmc>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qtxgzmc>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<hbdqm99yby>>',
                                maxLength: 20,
                            },
                        },
                        isWeighTharging: {
                            type: 'string.options.radio',
                            displayName: '<<jffs>>',
                            defaultValue: 'YES',
                            options: [
                                { id: 'YES', name: '<<zlcyjf>>' },
                                { id: 'NO', name: '<<zlbcyjf>>' },
                            ],
                        },
                        isWeighThargingFreightRule: {
                            type: 'object.delivery.rules',
                            displayName: '<<psgz>>',
                            defaultValue: {
                                deliveryScopes: [],
                                deliveryScopesInfo: [],
                                showDeliveryScopes: [],
                                freightRanges: [
                                    {
                                        startAmount: null,
                                        endAmount: null,
                                        firstAmount: null,
                                        freightLadders: [
                                            {
                                                startValue: null,
                                                ladderValue: null,
                                                ladderAmount: null,
                                                type: 'WEIGHT',
                                            },
                                        ],
                                    },
                                ],
                            },
                            controlConfig: {
                                pageType: 'merchant',
                                isWeighTharging: true,
                            },
                        },
                        noWeighThargingFreightRule: {
                            type: 'object.delivery.rules',
                            displayName: '<<psgz>>',
                            defaultValue: {
                                deliveryScopes: [],
                                deliveryScopesInfo: [],
                                showDeliveryScopes: [],
                                freightRanges: [
                                    {
                                        startAmount: null,
                                        endAmount: null,
                                        firstAmount: null,
                                        freightLadders: [
                                            {
                                                startValue: null,
                                                ladderValue: null,
                                                ladderAmount: null,
                                                type: 'WEIGHT',
                                            },
                                        ],
                                    },
                                ],
                            },
                            controlConfig: {
                                pageType: 'merchant',
                                isWeighTharging: false,
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        MerchantDeliveryView: {
            component: 'Viewport',
        },
        MerchantDeliveryPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            style: {
                padding: '12px 32px 24px 24px',
            },
            items: [{ component: 'MerchantDeliveryPageTables' }],
        },
        MerchantDeliveryPageTables: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<zt>>',
                    content: {
                        component: 'MerchantPickUpDeliveryPage',
                    },
                },
                {
                    title: '<<kd_2>>',
                    content: {
                        component: 'MerchantExpressDeliveryPage',
                    },
                },
            ],
        },
        MerchantPickUpDeliveryPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'MerchantPickUpDeliveryEntity',
            items: [
                { component: 'MerchantPickUpDeliveryPageFilter' },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzzjztgz>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/merchant-delivery/add-pick-up',
                                },
                            ],
                        },
                    ],
                },
                { component: 'MerchantPickUpDeliveryPageTable' },
            ],
        },
        MerchantPickUpDeliveryPageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                    defaultValue: getDefaultValue(),
                },
                {
                    property: 'merchantId',
                },
            ],
        },
        MerchantPickUpDeliveryPageTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'merchantName',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '130px',
                                },
                            },
                        },
                        {
                            property: 'address',
                        },
                        {
                            property: 'pickUpTime',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '200px',
                                },
                            },
                        },
                        {
                            property: 'reminder',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '200px',
                                },
                            },
                        },
                        {
                            property: 'executeStatus',
                            width: 100,
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        width: 180,
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: false },
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                            apiPath: '/admin/pick_up_deliveries/:id/disabled',
                                            config: {
                                                content: '{{row.name}}<<areadyClosed>>',
                                                text: '<<common.close>>',
                                            },
                                            confirm: {
                                                text: '<<isCloseActivity>>{{row.name}}?',
                                            },
                                        },
                                        {
                                            params: { status: true },
                                            value: false,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                            apiPath: '/admin/pick_up_deliveries/:id/enabled',
                                            config: {
                                                content: '{{row.name}}<<areadyOpend>>',
                                                text: '<<common.opend>>',
                                            },
                                            confirm: {
                                                text: '<<isAreadyOpend>>{{row.name}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                statusKey: 'row.canEdit',
                                path: '/merchant-delivery/edit-pick-up/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        MerchantExpressDeliveryPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'MerchantExpressDeliveryEntity',
            items: [
                { component: 'MerchantExpressDeliveryPageFilter' },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzyfgz>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/merchant-delivery/add-express',
                                },
                            ],
                        },
                    ],
                },
                { component: 'MerchantExpressDeliveryPageTable' },
            ],
        },
        MerchantExpressDeliveryPageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
            ],
        },
        MerchantExpressDeliveryPageTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataList',
                    pageType: 'merchant',
                    renderItem: 'DeliveryExpressItem',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                },
            ],
        },
        MerchantPickUpDeliveryAddPage: {
            component: 'Card',
            content: { component: 'MerchantPickUpDeliveryAddForm' },
        },
        MerchantPickUpDeliveryAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'MerchantPickUpDeliveryEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'baseInfo.merchants',
                },
                { property: 'baseInfo.address' },
                { property: 'baseInfo.pickUpTime' },
                { property: 'baseInfo.reminder' },
                { property: 'chooseInfo.reservedTimeHour' },
                { property: 'chooseInfo.topLimitDay' },
                { property: 'chooseInfo.isDeliveryTimePeriods' },
                {
                    property: 'chooseInfo.deliveryTimePeriods',
                    visible: (values: any) =>
                        get(values, 'chooseInfo.isDeliveryTimePeriods') === 'YES',
                },
                { property: 'ruleInfo.orderHandleMode' },
                {
                    property: 'ruleInfo.activityProductType',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.orderHandleMode') === 'PART_HANDLE',
                },
                {
                    property: 'ruleInfo.goods',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.orderHandleMode') === 'PART_HANDLE',
                },
                {
                    property: 'ruleInfo.pickUpLimitDays',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.orderHandleMode') === 'PART_HANDLE',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                let flag = true;
                if (entity.chooseInfo.isDeliveryTimePeriods === 'YES') {
                    if (
                        entity.chooseInfo.deliveryTimePeriods === undefined ||
                        entity.chooseInfo.deliveryTimePeriods === null
                    ) {
                        AntMessage.error(services.language.getText('qpzztsj'));
                        return false;
                    }
                    if (entity.chooseInfo.deliveryTimePeriods.length > 0) {
                        entity.chooseInfo.deliveryTimePeriods.forEach((item: any) => {
                            if (!item.start || !item.end) {
                                flag = false;
                            }
                        });
                    }
                }
                if (!flag) {
                    AntMessage.error(services.language.getText('qpzwzdztsj'));
                    return false;
                }
                const orderHandleMode = entity.ruleInfo.orderHandleMode;
                if (orderHandleMode === 'PART_HANDLE') {
                    if (
                        entity.ruleInfo.activityProductType !== 'ALL' &&
                        (!entity.ruleInfo.goods || entity.ruleInfo.goods.length === 0)
                    ) {
                        AntMessage.error(
                            services.language.getText('components.Tools.productSelectRequired')
                        );
                        return false;
                    }
                    if (!entity.ruleInfo.pickUpLimitDays && entity.ruleInfo.pickUpLimitDays !== 0) {
                        AntMessage.error(services.language.getText('qpzgqts'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        MerchantPickUpDeliveryEditPage: {
            component: 'Card',
            content: { component: 'MerchantPickUpDeliveryEditForm' },
        },
        MerchantPickUpDeliveryEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'MerchantPickUpDeliveryEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'baseInfo.merchants',
                    controlConfig: {
                        disabled: true,
                    },
                },
                { property: 'baseInfo.address' },
                { property: 'baseInfo.pickUpTime' },
                { property: 'baseInfo.reminder' },
                { property: 'chooseInfo.reservedTimeHour' },
                { property: 'chooseInfo.topLimitDay' },
                { property: 'chooseInfo.isDeliveryTimePeriods' },
                {
                    property: 'chooseInfo.deliveryTimePeriods',
                    visible: (values: any) =>
                        get(values, 'chooseInfo.isDeliveryTimePeriods') === 'YES',
                },
                { property: 'ruleInfo.orderHandleMode' },
                {
                    property: 'ruleInfo.activityProductType',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.orderHandleMode') === 'PART_HANDLE',
                },
                {
                    property: 'ruleInfo.goods',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.orderHandleMode') === 'PART_HANDLE',
                },
                {
                    property: 'ruleInfo.pickUpLimitDays',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.orderHandleMode') === 'PART_HANDLE',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                let flag = true;
                if (entity.chooseInfo.isDeliveryTimePeriods === 'YES') {
                    if (
                        entity.chooseInfo.deliveryTimePeriods === undefined ||
                        entity.chooseInfo.deliveryTimePeriods === null
                    ) {
                        AntMessage.error(services.language.getText('qpzztsj'));
                        return false;
                    }
                    if (entity.chooseInfo.deliveryTimePeriods.length > 0) {
                        entity.chooseInfo.deliveryTimePeriods.forEach((item: any) => {
                            if (!item.start || !item.end) {
                                flag = false;
                            }
                        });
                    }
                }
                if (!flag) {
                    AntMessage.error(services.language.getText('qpzwzdztsj'));
                    return false;
                }
                const orderHandleMode = entity.ruleInfo.orderHandleMode;
                if (orderHandleMode === 'PART_HANDLE') {
                    if (
                        entity.ruleInfo.activityProductType !== 'ALL' &&
                        (!entity.ruleInfo.goods || entity.ruleInfo.goods.length === 0)
                    ) {
                        AntMessage.error(
                            services.language.getText('components.Tools.productSelectRequired')
                        );
                        return false;
                    }
                    if (!entity.ruleInfo.pickUpLimitDays && entity.ruleInfo.pickUpLimitDays !== 0) {
                        AntMessage.error(services.language.getText('qpzgqts'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        MerchantExpressDeliveryAddPage: {
            component: 'Card',
            style: { padding: 0 },
            content: { component: 'MerchantExpressDeliveryAddForm' },
        },
        MerchantExpressDeliveryAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'MerchantExpressDeliveryEntity',
            labelCol: 2,
            controlCol: 22,
            fields: [
                { property: 'baseInfo.merchants' },
                { property: 'freightInfo.name' },
                { property: 'freightInfo.isWeighTharging' },
                {
                    property: 'freightInfo.isWeighThargingFreightRule',
                    visible: (values: any) => get(values, 'freightInfo.isWeighTharging') === 'YES',
                },
                {
                    property: 'freightInfo.noWeighThargingFreightRule',
                    visible: (values: any) => get(values, 'freightInfo.isWeighTharging') === 'NO',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const isWeighTharging = get(entity, 'freightInfo.isWeighTharging', null);
                if (isWeighTharging === 'YES') {
                    const isWeighThargingFreightRule = get(
                        entity,
                        'freightInfo.isWeighThargingFreightRule',
                        null
                    );
                    //2.校验地区是否已选择
                    if (
                        !isWeighThargingFreightRule.deliveryScopes ||
                        isWeighThargingFreightRule.deliveryScopes.length === 0
                    ) {
                        AntMessage.error(services.language.getText('nbxyxzygkpsqy'));
                        return false;
                    }
                    //3.校验输入框是否已全部填写完毕
                    let freightRangeFlag = true;
                    forEach(isWeighThargingFreightRule.freightRanges, (freightRange: any) => {
                        if (
                            freightRange.startAmount === null ||
                            freightRange.endAmount === null ||
                            freightRange.firstAmount === null ||
                            freightRange.freightLadders[0].startValue === null ||
                            freightRange.freightLadders[0].ladderAmount === null ||
                            freightRange.freightLadders[0].ladderValue === null
                        ) {
                            freightRangeFlag = false;
                            return false;
                        }
                    });
                    if (!freightRangeFlag) {
                        AntMessage.warning(services.language.getText('qtxkpsqydydyfz'));
                        return false;
                    }
                }
                if (isWeighTharging === 'NO') {
                    const noWeighThargingFreightRule = get(
                        entity,
                        'freightInfo.noWeighThargingFreightRule',
                        null
                    );
                    //2.校验地区是否已选择
                    if (
                        !noWeighThargingFreightRule.deliveryScopes ||
                        noWeighThargingFreightRule.deliveryScopes.length === 0
                    ) {
                        AntMessage.error(services.language.getText('nbxyxzygkpsqy'));
                        return false;
                    }
                    //3.校验输入框是否已全部填写完毕
                    let freightRangeFlag = true;
                    forEach(noWeighThargingFreightRule.freightRanges, (freightRange: any) => {
                        if (
                            freightRange.startAmount === null ||
                            freightRange.endAmount === null ||
                            freightRange.firstAmount === null
                        ) {
                            freightRangeFlag = false;
                            return false;
                        }
                    });
                    if (!freightRangeFlag) {
                        AntMessage.warning(services.language.getText('qtxkpsqydydyfz'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        MerchantExpressDeliveryEditPage: {
            component: 'Card',
            style: { padding: 0 },
            content: { component: 'MerchantExpressDeliveryEditForm' },
        },
        MerchantExpressDeliveryEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'MerchantExpressDeliveryEntity',
            loaderType: 'get',
            labelCol: 2,
            controlCol: 22,
            fields: [
                {
                    property: 'baseInfo.merchants',
                    controlConfig: {
                        disabled: true,
                    },
                },
                { property: 'freightInfo.name' },
                { property: 'freightInfo.isWeighTharging' },
                {
                    property: 'freightInfo.isWeighThargingFreightRule',
                    visible: (values: any) => get(values, 'freightInfo.isWeighTharging') === 'YES',
                },
                {
                    property: 'freightInfo.noWeighThargingFreightRule',
                    visible: (values: any) => get(values, 'freightInfo.isWeighTharging') === 'NO',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const isWeighTharging = get(entity, 'freightInfo.isWeighTharging', null);
                if (isWeighTharging === 'YES') {
                    const isWeighThargingFreightRule = get(
                        entity,
                        'freightInfo.isWeighThargingFreightRule',
                        null
                    );
                    //1.校验地区是否已选择
                    if (
                        isWeighThargingFreightRule.showDeliveryScopes &&
                        isWeighThargingFreightRule.showDeliveryScopes.length > 0
                    ) {
                        let length = 0;
                        forEach(isWeighThargingFreightRule.showDeliveryScopes, (parentRegion) => {
                            if (parentRegion.selectStatus === 'ALL') {
                                length += 1;
                            }
                            if (parentRegion.selectStatus === 'PART') {
                                length += 1;
                            }
                        });
                        if (length === 0) {
                            AntMessage.error(services.language.getText('nbxyxzygkpsqy'));
                            return false;
                        }
                    }
                    //2.校验输入框是否已全部填写完毕
                    let freightRangeFlag = true;
                    forEach(isWeighThargingFreightRule.freightRanges, (freightRange: any) => {
                        if (
                            freightRange.startAmount === null ||
                            freightRange.endAmount === null ||
                            freightRange.firstAmount === null ||
                            freightRange.freightLadders[0].startValue === null ||
                            freightRange.freightLadders[0].ladderAmount === null ||
                            freightRange.freightLadders[0].ladderValue === null
                        ) {
                            freightRangeFlag = false;
                            return false;
                        }
                    });
                    if (!freightRangeFlag) {
                        AntMessage.warning(services.language.getText('qtxkpsqydydyfz'));
                        return false;
                    }
                }
                if (isWeighTharging === 'NO') {
                    const noWeighThargingFreightRule = get(
                        entity,
                        'freightInfo.noWeighThargingFreightRule',
                        null
                    );
                    //1.校验地区是否已选择
                    if (
                        noWeighThargingFreightRule.showDeliveryScopes &&
                        noWeighThargingFreightRule.showDeliveryScopes.length > 0
                    ) {
                        let length = 0;
                        forEach(noWeighThargingFreightRule.showDeliveryScopes, (parentRegion) => {
                            if (parentRegion.selectStatus === 'ALL') {
                                length += 1;
                            }
                            if (parentRegion.selectStatus === 'PART') {
                                length += 1;
                            }
                        });
                        if (length === 0) {
                            AntMessage.error(services.language.getText('nbxyxzygkpsqy'));
                            return false;
                        }
                    }
                    //2.校验输入框是否已全部填写完毕
                    let freightRangeFlag = true;
                    forEach(noWeighThargingFreightRule.freightRanges, (freightRange: any) => {
                        if (
                            freightRange.startAmount === null ||
                            freightRange.endAmount === null ||
                            freightRange.firstAmount === null
                        ) {
                            freightRangeFlag = false;
                            return false;
                        }
                    });
                    if (!freightRangeFlag) {
                        AntMessage.warning(services.language.getText('qtxkpsqydydyfz'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/merchant-delivery',
                    component: 'MerchantDeliveryView',
                    breadcrumbName: '<<merchantDelivery>>',
                    privilege: {
                        path: 'merchantDeliveryFreightRule',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add-pick-up',
                            component: 'MerchantPickUpDeliveryAddPage',
                            breadcrumbName: '<<xzzjztgz>>',
                        },
                        {
                            path: '/edit-pick-up/:id',
                            component: 'MerchantPickUpDeliveryEditPage',
                            breadcrumbName: '<<bjzjztgz>>',
                        },
                        {
                            path: '/add-express',
                            component: 'MerchantExpressDeliveryAddPage',
                            breadcrumbName: '<<xzyfgz>>',
                        },
                        {
                            path: '/edit-express/:id',
                            component: 'MerchantExpressDeliveryEditPage',
                            breadcrumbName: '<<bjyfgz>>',
                        },
                        { path: '/', component: 'MerchantDeliveryPage' },
                    ],
                },
            ],
        },
    ],
};
