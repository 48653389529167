import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { EntityButtonProps } from '../../../components';
import { ForwardDataCenterModal } from '../../../services';
import { isEmpty } from 'lodash';

const api = services.api;

/**
 * 券状态
 */
enum CouponStatus {
    /**
     * 全部
     */
    ALL = 'ALL',
    /**
     * 待绑定
     */
    UNBOUND = 'UNBOUND',
    /**
     * 待使用
     */
    ENABLED = 'ENABLED',
    /**
     * 已使用
     */
    USED = 'USED',
    /**
     * 已过期
     */
    EXPIRED = 'EXPIRED',
    /**
     * 已失效
     */
    INVALID = 'INVALID',
    /**
     * 已冻结
     */
    FREEZE = 'FREEZE',
    /**
     * 预绑定
     */
    PREBINDING = 'PREBINDING',
    /**
     * 赠送中
     */
    TRANSFERRING = 'TRANSFERRING',
}

/**
 * 兑换状态
 */
enum ExchangeStatus {
    /**
     * 全部
     */
    ALL = 'ALL',
    /**
     * 待兑换
     */
    UNEXCHANGED = 'UNEXCHANGED',
    /**
     * 已兑换
     */
    EXCHANGED = 'EXCHANGED',
}

const COUPON_STATUS_OPTIONS = [
    {
        id: CouponStatus.ALL,
        name: '<<all>>',
    },
    {
        id: CouponStatus.PREBINDING,
        name: '<<jybd>>',
    },
    {
        id: CouponStatus.UNBOUND,
        name: '<<dbd>>',
    },
    {
        id: CouponStatus.ENABLED,
        name: '<<dsy>>',
    },
    {
        id: CouponStatus.USED,
        name: '<<ysy_1>>',
    },
    {
        id: CouponStatus.EXPIRED,
        name: '<<ygq>>',
    },
    {
        id: CouponStatus.INVALID,
        name: '<<ysx>>',
    },
    {
        id: CouponStatus.FREEZE,
        name: '<<ydj>>',
    },
    {
        id: CouponStatus.TRANSFERRING,
        name: '<<zsz_1>>',
    },
];

const EXCHANGE_STATUS_OPTIONS = [
    {
        id: ExchangeStatus.ALL,
        name: '<<all>>',
    },
    {
        id: ExchangeStatus.UNEXCHANGED,
        name: '<<ddh>>',
    },
    {
        id: ExchangeStatus.EXCHANGED,
        name: '<<ydh_1>>',
    },
];

const SOURCE_TYPE_OPTIONS = [
    {
        id: ExchangeStatus.ALL,
        name: '<<all>>',
    },
    {
        id: 'activity',
        name: '<<hdy>>',
    },
    {
        id: 'productDetail',
        name: '<<spxqy>>',
    },
    {
        id: 'cart',
        name: '<<gwcy>>',
    },
    {
        id: 'manual',
        name: '<<htsdbd>>',
    },
    {
        id: 'REGISTER_AWARD',
        name: '<<zcjl>>',
    },
    {
        id: 'INVITATION',
        name: '<<yqyl>>',
    },
    {
        id: 'POINT_EXCHANGE',
        name: '<<jfdh>>',
    },
    {
        id: 'GUIDE_GIVE_COUPON',
        name: '<<dgmdmfq>>',
    },
    {
        id: 'GUIDE_SHARE_COUPON',
        name: '<<dgfxfq>>',
    },
    {
        id: 'GUIDE_SMS_GIVE_COUPON',
        name: '<<dgdxfq>>',
    },
    {
        id: 'RECEIVE',
        name: '<<lq>>',
    },
    {
        id: 'PURCHASE',
        name: '<<gm_1>>',
    },
    {
        id: 'ACTIVITY_AWARD',
        name: '<<hdjl>>',
    },
    {
        id: 'SIGN_IN',
        name: '<<linkTypes.checkinIndex>>',
    },
    {
        id: 'LUCK_DRAW_GIVE',
        name: '<<cjzs>>',
    },
    {
        id: 'GIVING_COUPON_SCHEDULE',
        name: '<<dxfq>>',
    },
    {
        id: 'BIRTHDAY_GIVE',
        name: '<<sryl>>',
    },
    {
        id: 'BIRTHDAY_CARING',
        name: '<<birthdayCaring>>',
    },

    {
        id: 'REDEEM_CODE',
        name: '<<redeCode>>',
    },
    {
        id: 'CARD_COLLECTION',
        name: '<<jk>>',
    },
    {
        id: 'COLLECTION_POINT',
        name: '<<collectionPointsCoupon>>',
    },
    {
        id: 'LUCKY_RED_PACKAGE',
        name: '<<psqhb>>',
    },
    {
        id: 'TOPIC_ACTIVITY',
        name: '<<topicPromotionEncourage>>',
    },
    {
        id: 'SHARE_PAGE',
        name: '<<fxyl>>',
    },
    {
        id: 'PROMOTION',
        name: '<<cxgz>>',
    },
    {
        id: 'REFUND_TYPE_RECOUP_COUPON',
        name: '<<shbc>>',
    },
    {
        id: 'EVENT_MARKET_MEMBER_REGISTER',
        name: '<<marketMemberRegister>>',
    },
    {
        id: 'EVENT_MARKET_RECEIVE_ORDER',
        name: '<<marketReceiveOrder>>',
    },
    {
        id: 'EVENT_MARKET_PAYMENT_POS_RECEIPT',
        name: '<<marketPaymentPosReceipt>>',
    },
    {
        id: 'EVENT_MARKET_PAYMENT_ORDER',
        name: '<<marketParmentOrder>>',
    },
    {
        id: 'EVENT_MARKET_PAYMENT_BUSINESS_MALL',
        name: '<<marketPaymentBusinessMall>>',
    },
    {
        id: 'TRANSFER',
        name: '<<zs_4>>',
    },
    {
        id: 'FOLLOW_WECHAT_OFFICIAL_ACCOUNT',
        name: '<<gzgzhyl>>',
    },
    {
        id: 'WATCHWORD',
        name: '<<watchwordCouponCollection>>',
    },
];

export const config: Config = {
    entities: {
        CouponRulesDetailsReportEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
            apiPath: '/loader/admin/coupon_rules_details_report',
            filters: {
                mobile: {
                    type: 'string.phone',
                    displayName: '<<member>>',
                    defaultValue: undefined,
                    controlConfig: {
                        placeholder: '<<qsrsjh>>',
                    },
                },
                couponNo: {
                    type: 'string',
                    displayName: '<<dzqh>>',
                    defaultValue: undefined,
                    controlConfig: {
                        placeholder: '<<qsrqm>>',
                    },
                },
                couponStatus: {
                    type: 'string.options.select',
                    displayName: '<<yhqzt>>',
                    defaultValue: undefined,
                    controlConfig: {
                        style: {
                            width: 160,
                        },
                        placeholder: '<<selectPlease>>',
                    },
                    options: COUPON_STATUS_OPTIONS,
                },
                exchangeCode: {
                    type: 'string',
                    displayName: '<<redeCode>>',
                    defaultValue: undefined,
                    controlConfig: {
                        placeholder: '<<qsrdhm>>',
                    },
                },
                consumeTradeNo: {
                    type: 'string',
                    displayName: '<<consumeTradeNo>>',
                    defaultValue: undefined,
                    controlConfig: {
                        placeholder: '<<qsrhxlsh>>',
                    },
                },
                exchangeStatus: {
                    type: 'string.options.select',
                    displayName: '<<dhzt>>',
                    defaultValue: undefined,
                    controlConfig: {
                        style: {
                            width: 160,
                        },
                        placeholder: '<<selectPlease>>',
                    },
                    options: EXCHANGE_STATUS_OPTIONS,
                },
                couponDetailsGuideFilter: {
                    type: 'object.couponDetailsGuideFilter',
                    displayName: '<<fqsjmc>>',
                    defaultValue: undefined,
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                    },
                    options: SOURCE_TYPE_OPTIONS,
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<bqsj>>',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                couponNo: {
                    type: 'string',
                    displayName: '<<qmbh>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
                crmId: {
                    type: 'string',
                    displayName: '<<crmId>>',
                },
                exchangeCode: {
                    type: 'string',
                    displayName: '<<dhmbh>>',
                },
                exchangeStatus: {
                    type: 'string',
                    displayName: '<<dhzt>>',
                },
                sourceDesc: {
                    type: 'string',
                    displayName: '<<setCouponType>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<yhqzt>>',
                    options: COUPON_STATUS_OPTIONS,
                },
                bindingTime: {
                    type: 'string',
                    displayName: '预绑定/绑定/兑换时间',
                },
                consumeTime: {
                    type: 'string',
                    displayName: '<<hxsj>>',
                },
                consumeTradeNo: {
                    type: 'string',
                    displayName: '<<consumeTradeNo>>',
                },
                endTime: {
                    type: 'string',
                    displayName: '<<sxsj>>',
                },
                channelName: {
                    type: 'string',
                    displayName: '<<sfqd>>',
                },
                sourceType: {
                    type: 'string',
                    displayName: '<<fqsjmc>>',
                },
                transferMobile: {
                    type: 'string',
                    displayName: '<<transferMobile>>',
                },
                sentSubSiteName: {
                    type: 'string',
                    displayName: '<<fqmd>>',
                },
                sentMerchantName: {
                    type: 'string',
                    displayName: '<<fqzj>>',
                },
                sentStaffName: {
                    type: 'string',
                    displayName: '<<fqyg>>',
                },
            },
        },
    },
    components: {
        CouponRulesDetailsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'tabs-content-viewport',
            items: [
                {
                    component: 'Tabs',
                    type: 'card',
                    cache: false,
                    animated: false,
                    items: [
                        {
                            title: '<<yhqsj>>',
                            content: {
                                component: 'CouponRulesDetailsReport',
                            },
                        },
                    ],
                },
            ],
        },

        CouponRulesDetailsReport: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'CouponRulesDetailsReportEntity',
            items: [
                {
                    component: 'CouponRulesDetailsReportFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'EntityButton',
                                    props: {
                                        type: 'primary',
                                        children: '<<dcyhqsj>>',
                                        onClick: (_: any, entityButtonProps: EntityButtonProps) => {
                                            const params: any = Object.assign(
                                                {},
                                                entityButtonProps.entity.params,
                                                entityButtonProps.entity.filters
                                            );
                                            if (!isEmpty(params.dateRange)) {
                                                params.bindingStartTime =
                                                    params.dateRange.start + ' 00:00:00';
                                                params.bindingEndTime =
                                                    params.dateRange.end + ' 23:59:59';
                                                delete params.dateRange;
                                            }
                                            if (!isEmpty(params.couponDetailsGuideFilter)) {
                                                params.sourceType =
                                                    params.couponDetailsGuideFilter &&
                                                    params.couponDetailsGuideFilter.type
                                                        ? params.couponDetailsGuideFilter.type
                                                        : undefined;
                                                params.guideGiveCouponKeywords =
                                                    params.couponDetailsGuideFilter &&
                                                    params.couponDetailsGuideFilter.keyword
                                                        ? params.couponDetailsGuideFilter.keyword
                                                        : undefined;
                                                delete params.couponDetailsGuideFilter;
                                            }
                                            const config = {
                                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                                                apiPath:
                                                    '/admin/' +
                                                    params.type +
                                                    '_coupons/' +
                                                    params.type +
                                                    '_coupons.excel',
                                            };
                                            params.couponRuleId = params.id;
                                            delete params.type;
                                            delete params.id;
                                            api.get(params, config)
                                                .then(() => {
                                                    ForwardDataCenterModal();
                                                })
                                                .catch((error) => {
                                                    services.errorHandle(error);
                                                });
                                        },
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'CouponRulesDetailsReportTable',
                },
            ],
        },
        CouponRulesDetailsReportFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'mobile',
                },
                {
                    property: 'couponNo',
                },
                {
                    property: 'couponStatus',
                },
                {
                    property: 'exchangeCode',
                },
                {
                    property: 'consumeTradeNo',
                },
                {
                    property: 'exchangeStatus',
                },
                {
                    property: 'couponDetailsGuideFilter',
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        CouponRulesDetailsReportTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'couponNo',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'crmId',
                    displayConfig: {
                        style: {
                            minWidth: '140px',
                        },
                    },
                },
                {
                    property: 'exchangeCode',
                },
                {
                    property: 'exchangeStatus',
                },
                {
                    property: 'sourceDesc',
                },
                {
                    property: 'status',
                },
                {
                    property: 'bindingTime',
                    width: 200,
                },
                {
                    property: 'consumeTime',
                    width: 200,
                },
                {
                    property: 'consumeTradeNo',
                },
                {
                    property: 'endTime',
                    width: 200,
                },
                {
                    property: 'channelName',
                },
                {
                    property: 'sourceType',
                },
                {
                    property: 'transferMobile',
                },
                {
                    property: 'sentSubSiteName',
                },
                {
                    property: 'sentMerchantName',
                },
                {
                    property: 'sentStaffName',
                },
            ],
        },
    },
};
