import * as React from 'react';
import { Popover } from 'antd';
import { isNull } from 'lodash';
import { modes } from '@comall-backend-builder/types';

import './index.less';
/**
 * 长文本气泡展示
 */
export class StringPopoverMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        if (isNull(value)) return this.getNotAvailableDisplayComponent(displayInfo);
        const { showLength } = displayInfo;
        const length = showLength || 11;
        return (
            <div style={{ whiteSpace: 'nowrap' }}>
                {value && value.length > length ? (
                    <Popover
                        style={{ zIndex: 1030 }}
                        className="group-popover"
                        placement="top"
                        content={
                            <div className="group-popover" style={{ maxWidth: 300 }}>
                                {value}
                            </div>
                        }
                    >
                        <span>{value.slice(0, length)}...</span>
                    </Popover>
                ) : (
                    <span>{value}</span>
                )}
            </div>
        );
    }
}
