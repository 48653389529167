// @ts-nocheck
import React, { PureComponent } from 'react';
import { Table } from 'antd';
import { DistributionCommissionAccount } from './index';
import './index.less';
interface BatchRevokedModalContentProps {
    accounts: DistributionCommissionAccount[];
}
export class BatchRevokedModalContent extends PureComponent<BatchRevokedModalContentProps> {
    getTableConfig = () => {
        const columns = [
            {
                title: '员工姓名',
                key: 'realName',
                dataIndex: 'realName',
                render: (realName: string) => {
                    return <span>{realName}</span>;
                },
            },
            {
                title: '手机号',
                key: 'mobile',
                dataIndex: 'mobile',
                render: (mobile: string) => {
                    return <span>{mobile}</span>;
                },
            },
            {
                title: '剩余佣金',
                key: 'balance',
                dataIndex: 'balance',
                render: (balance: string) => {
                    return (
                        <span>
                            {services.language.getText('monetaryUnit')} {balance}
                        </span>
                    );
                },
            },
        ];
        let config: any = {
            rowKey: 'id',
            columns,
            scroll: {
                y: 216,
            },
            style: {
                width: 500,
                marginTop: 20,
            },
        };
        return config;
    };

    renderAccountsTable = () => {
        const { accounts } = this.props;
        if (!accounts.length) {
            return null;
        }
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: accounts,
        };
        return <Table {...tableProps} pagination={false}></Table>;
    };

    render() {
        const { accounts } = this.props;
        let tips = '是否确认注销账号，注销后账号将无法恢复？';
        if (accounts.length) {
            tips = '您选择的账号还有佣金未使用，' + tips;
        }
        return (
            <div>
                <div>{tips}</div>
                {this.renderAccountsTable()}
            </div>
        );
    }
}
