import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { ServiceReservedStock } from './service-reserved-stock';

export class ServiceReservedStockMode extends modes.ObjectMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <ServiceReservedStock {...displayInfo} />;
    }
}
