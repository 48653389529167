import { builder } from '@comall-backend-builder/core';
import { FestivalMarketingLoader } from './festival-marketing-loader';
import { PointBenefitLoader } from './point-benefit';
import { CardLevelsPointBenefitLoader } from './card-level-point-benefit';
import { HiBeiBenefitLoader } from './hibei-benefit';
import { PointSettingLoader } from './point-setting';
import { PointValidLoader } from './point-valid';
import { PointDeductionProductLoader } from './point-deduction-product';
import { PointExchangeRuleLoader } from './point-exchange-rule';

import {
    MemberInfoLoader,
    MemberCouponLoader,
    MemberPointLoader,
    MemberGoldDetailLoader,
    TransActionSelectorLoader,
} from './member-info';
import { MemberCardDiscountLoader } from './member-card-discount-loader';

import { MemberTagLoader, MemberTagTypeLoader } from './member-tag-loader';
import { SocialIdentityTagPermissionsLoader } from './social-identity-tag-permissions';
import { PointDoublePromotionsLoader } from './point-double-promitions';
import { ExperienceBenefitLoader } from './experience-benefit';
import { ExperienceDoublePromotionsLoader } from './experience-double-promitions';
import { HibeiDoublePromotionsLoader } from './hibei-double-promitions';
import { WechatBusinessMallsLoader, AlipayBusinessMallsLoader } from './business-malls-loader';
import { ExperienceRuleELoader } from './experience-rule-loader';
import { OrderCommentsLoader } from './order-comments-loader';
import { MerchantBigCodesLoader } from './merchant-big-codes-loader';
import { LabelManageLoader } from './label-manage-loader';
import { CustomCrowdBatchLoader } from './crowd-manage-loader';
import { StoredValueCardActivityLoader } from './stored-value-card-activity-loader';
import { StoredValueCardConfigLoader } from './stored-value-card-config-loader';
import { StoredValueCardProductLoader } from './stored-value-card-product-loader';
import { TouchlessParkingAuthorizationLoader } from './touchless-parking-authorization-loader';
import { TouchlessParkingAssetAuthorizationLoader } from './touchless-parking-asset-authorization-loader';
import { StoredValueSettingLoader } from './stored-value-card-setting-loader';
import { StoredValueCardSettingSubsitePickUpDeliveryLoader } from './stored-value-card-setting-subsite-pick-up-delivery-loader';
import { OfflineOrderReviewLoader, offlineOrdersLoader } from './offline-order-review';
import { StoredValueCardBuyActivityLoader } from './stored-value-card-buy-activity-loader';
import { RestaurantReservationLoader } from './restaurant-reservation-loader';
import { StoredValueCardOrderLoader } from './stored-value-card-order-loader';
import { ManageGiftLoader } from './manage-gift-loader';

builder.registerLoader('/admin/festival_marketing', FestivalMarketingLoader);
builder.registerLoader('/admin/promotion', PointBenefitLoader);
builder.registerLoader('/admin/cardLevelsPointBenefit', CardLevelsPointBenefitLoader);
builder.registerLoader('/admin/promotion/hibei', HiBeiBenefitLoader);
builder.registerLoader('/admin/pointSetting', PointSettingLoader);
builder.registerLoader('/admin/pointValid', PointValidLoader);
builder.registerLoader('/admin/pointDeductionProduct', PointDeductionProductLoader);
builder.registerLoader('/admin/deduction_merchant_big_codes', MerchantBigCodesLoader);
builder.registerLoader('/admin/members', MemberInfoLoader);
builder.registerLoader('/admin/coupons/:id/coupons', MemberCouponLoader);
builder.registerLoader('/admin/members/:id/points', MemberPointLoader);
builder.registerLoader('/admin/gold_accounts', MemberGoldDetailLoader);
builder.registerLoader('/admin/gold_accounts/trans_action_type', TransActionSelectorLoader);
builder.registerLoader('/admin/member_card_discount_activities', MemberCardDiscountLoader);
builder.registerLoader('/admin/memberTag', MemberTagLoader);
builder.registerLoader('/admin/memberTagType', MemberTagTypeLoader);
builder.registerLoader('/admin/socialIdentityTagPermissions', SocialIdentityTagPermissionsLoader);
builder.registerLoader('/admin/point_double_promotions', PointDoublePromotionsLoader);
builder.registerLoader('/admin/pointExchangeRule', PointExchangeRuleLoader);
builder.registerLoader('/admin/promotion/experience', ExperienceBenefitLoader);
builder.registerLoader(
    '/admin/promotion/experience_double_promotions',
    ExperienceDoublePromotionsLoader
);
builder.registerLoader('/admin/promotion/hibei_double_promotions', HibeiDoublePromotionsLoader);
builder.registerLoader('/admin/wechat-business-malls', WechatBusinessMallsLoader);
builder.registerLoader('/admin/alipay-business-malls', AlipayBusinessMallsLoader);
builder.registerLoader('/admin/experience_rules', ExperienceRuleELoader);
builder.registerLoader('/admin/order-comments', OrderCommentsLoader);
builder.registerLoader('/admin/tags', LabelManageLoader);
builder.registerLoader('/admin/crowd_members', CustomCrowdBatchLoader);
builder.registerLoader('/admin/stored_value_card_config', StoredValueCardConfigLoader);
builder.registerLoader('/admin/stored_value_card_product', StoredValueCardProductLoader);
builder.registerLoader('/admin/stored_value_card_activity', StoredValueCardActivityLoader);
builder.registerLoader(
    '/admin/touchless_parking/authorizations',
    TouchlessParkingAuthorizationLoader
);
builder.registerLoader(
    '/admin/touchless_parking/asset_authorizations',
    TouchlessParkingAssetAuthorizationLoader
);
builder.registerLoader('/admin/stored_value_card_settting', StoredValueSettingLoader);
builder.registerLoader(
    '/admin/stored_value_card_setting_subsite_pick_up_delivery',
    StoredValueCardSettingSubsitePickUpDeliveryLoader
);
builder.registerLoader(
    '/admin/offline_payment_bill/manual_review_records',
    OfflineOrderReviewLoader
);
builder.registerLoader('/admin/order/list', offlineOrdersLoader);
builder.registerLoader('/admin/stored_value_card_buy_activity', StoredValueCardBuyActivityLoader);
builder.registerLoader('/admin/restaurant_reservation', RestaurantReservationLoader);
builder.registerLoader('/admin/stored_value_card_order', StoredValueCardOrderLoader);
builder.registerLoader('/admin/manage_gift', ManageGiftLoader);
