import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    components: {
        DataPanelsPaymentsView: {
            component: 'Viewport',
        },
        DataPanelsPaymentsPage: {
            entity: 'DataPanelsPaymentsEntity',
            component: 'FlexLayout',
            loaderType: 'get',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'GridLayout',
                    className: 'mb24',
                    items: [
                        {
                            component: 'DataPanelsPaymentsFilter',
                        },
                    ],
                },
                {
                    component: 'DataPanelsPaymentsCard',
                },
            ],
        },
        DataPanelsPaymentsFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                },
                {
                    property: 'shopId',
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        DataPanelsPaymentsCard: {
            component: 'Card',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'DataPanelTitle',
                        title: '<<paymentsData>>',
                    },
                    {
                        component: 'DataPanelsPaymentsCardView',
                    },
                ],
            },
        },
    },
    entities: {
        DataPanelsPaymentsEntity: {
            apiPath: '/loader/admin/payments-data-panel',
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                shopId: {
                    type: 'string.filterOptions.autoComplete',
                    displayName: '<<merchant>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                        //专柜默认正序排序
                        params: {
                            sequence: 'code',
                            order: 'asc',
                        },
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                dateRange: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<time>>',
                },
            },
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<mc>>',
                },
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/data-panels-payments',
                    component: 'DataPanelsPaymentsView',
                    breadcrumbName: '<<paymentsData>>',
                    privilege: {
                        path: 'paymentsData',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'DataPanelsPaymentsPage' }],
                },
            ],
        },
    ],
};
