import * as React from 'react';
import { map } from 'lodash';
import { ArrayMode } from '@comall-backend-builder/types';
import './index.less';

export class GroupBuyingCheckbox extends ArrayMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(array: any, displayInfo: any) {
        const { className, style, name } = displayInfo;
        if (array && array.length > 0) {
            return (
                <div className={className} style={style}>
                    {map(array, (item, index) => {
                        return (
                            <div key={index}>
                                <span className="group-buying-checkbox-select"></span>
                                <span className="group-buying-checkbox-placeholder">{name}</span>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return (
                <div className={className} style={style}>
                    <div>
                        <span className="group-buying-checkbox-un-select"></span>
                        <span className="group-buying-checkbox-placeholder">{name}</span>
                    </div>
                </div>
            );
        }
    }
}
