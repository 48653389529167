import React, { Component } from 'react';
import { Button, InputNumber } from 'antd';
import { clone, isEqual, map, concat, isEmpty } from 'lodash';
import { TimeRangePickerPlus } from '..';

import './index.less';

interface TimepickerRange {
    start?: string;
    end?: string;
    num?: number;
}

export interface PresaleTimepickerProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 格式化
     */
    format: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Array<TimepickerRange> | null, name: string) => void;
    /**
     * 当前值
     */
    value: Array<TimepickerRange> | null;
    tip: string;
    warnTip: string;
    deleteButtonText: string;
    addButtonText: string;
    disabled: boolean;
    /**
     * 在时间段后面有扩展内容字段
     */
    showValue?: {
        addonbefore: string;
        addonafter: string;
    };
}
export class TimepickerRangeTips extends Component<PresaleTimepickerProps> {
    static defaultProps = {
        format: 'HH:mm:ss',
        disabled: false,
    };
    onPresaleTimepickerChange(value: Array<string>, name: string) {}
    errorTip = '';
    onAddPresaleTimepicker() {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value) && value[value.length - 1].start) {
            value = concat([], value);
            value.splice(value.length, 0, {});
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onRemovePresaleTimepicker(index: number) {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(index, 1);
            if (!value.length) {
                value = null;
            }
        }
        this.errorTip = '';
        if (onChange) {
            onChange(value, name);
        }
    }

    shouldComponentUpdate(nextProps: PresaleTimepickerProps) {
        return !isEqual(this.props, nextProps);
    }

    onhandlePickerChange(valueMoment: any, valueString: TimepickerRange, index: number) {
        let { name, value, onChange } = this.props;

        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.start = valueString.start;
            item.end = valueString.end;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: TimepickerRange = {};
            item.start = valueString.start;
            item.end = valueString.end;
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
        return {};
    }

    onhandleNumChange = (numString: any, index: number) => {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.num = numString;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: TimepickerRange = {};
            item.num = numString;
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    };

    render() {
        let { tip, warnTip, value, deleteButtonText, addButtonText, format, disabled } = this.props;
        return (
            <div className="presale-timepicker-components">
                <div className="presale-timepicker-info">
                    <div>
                        {map(value, (item, index) =>
                            this.renderPresaleTimepicker(
                                item,
                                index,
                                deleteButtonText,
                                format,
                                disabled
                            )
                        )}
                        {!(value && value.length) &&
                            this.renderPresaleTimepicker({}, 0, deleteButtonText, format, disabled)}
                    </div>
                    {addButtonText && (
                        <Button
                            type="default"
                            className="add-presale-timepicker-button"
                            onClick={() => this.onAddPresaleTimepicker()}
                        >
                            {addButtonText}
                        </Button>
                    )}
                </div>
                {tip && (
                    <div className="presale-timepicker-tips">
                        <div>{tip}</div>
                        <div>{warnTip}</div>
                    </div>
                )}
            </div>
        );
    }

    renderPresaleTimepicker(
        value: TimepickerRange,
        index: number,
        deleteButtonText: string,
        format: string,
        disabled: boolean
    ) {
        const { showValue } = this.props;
        return (
            <div className="presale-timepicker-line" key={index}>
                <div className="presale-timepicker-content">
                    <div className="presale-timepicker-newline">
                        <TimeRangePickerPlus
                            disabled={disabled}
                            format={format}
                            name="start"
                            value={value}
                            className="presale-timepicker mrp-10"
                            onChange={(time, name) => this.onhandlePickerChange({}, time, index)}
                        />
                        {showValue && (
                            <span>
                                {showValue.addonbefore && (
                                    <span className="presale-timepicker-content-left">
                                        {showValue.addonbefore}
                                    </span>
                                )}
                                <InputNumber
                                    precision={0}
                                    type="number"
                                    min={0}
                                    max={999999}
                                    className="presale-timepicker-content-left"
                                    value={value.num}
                                    onChange={(num) => this.onhandleNumChange(num, index)}
                                />
                                {showValue.addonafter && <span>{showValue.addonafter}</span>}
                            </span>
                        )}
                        {value.start && deleteButtonText && (
                            <Button
                                type="default"
                                className="remove-presale-timepicker-button"
                                onClick={() => this.onRemovePresaleTimepicker(index)}
                            >
                                {deleteButtonText}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
