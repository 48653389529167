import React, { ReactNode, useState } from 'react';
import { Button, Input, Select, Table } from 'antd';
import { ColumnProps, TableProps } from 'antd/lib/table';
import { ProductSelector } from '../../../../components/product-selector';
import { formatPrice, PRICE_TYPE } from '../../../../config/promotion/market/live-room-product';
import './live-room-product-add.less';
import { ComponentsManager, services } from '@comall-backend-builder/core';

interface LiveRoomProduct {
    goodsId: number;
    name: string;
    price: number;
    price2: number;
    priceType: string;
}
export const LiveRoomProductAdd = ({
    onChange: onOriginChange,
}: {
    onChange?: (value: LiveRoomProduct[]) => void;
}) => {
    const [shops, setShops] = useState<any[]>([]);
    const [products, setProducts] = useState<any[]>([]);
    const shopColumns: ColumnProps<any>[] = [
        { title: services.language.getText('subsiteName'), dataIndex: 'subSiteName' },
        { title: services.language.getText('merchantName'), dataIndex: 'merchantName' },
        { title: services.language.getText('merchantType'), dataIndex: 'merchantType' },
        { title: services.language.getText('merchantStatus'), dataIndex: 'merchantStatus' },
    ];
    const productColumns: ColumnProps<any>[] = [
        { title: services.language.getText('components.Products.name'), dataIndex: 'name' },
        { title: services.language.getText('goodName'), dataIndex: 'goodsMvo.name' },
        { title: services.language.getText('productCode'), dataIndex: 'goodsMvo.spuCode' },
        { title: services.language.getText('goodCode'), dataIndex: 'goodsMvo.csku' },
        { title: services.language.getText('subsiteName'), dataIndex: 'goodsMvo.subsiteName' },
        { title: services.language.getText('merchantName'), dataIndex: 'goodsMvo.merchantName' },
        { title: services.language.getText('xsj'), dataIndex: 'goodsMvo.salesPrice.value' },
        { title: services.language.getText('stock'), dataIndex: 'goodsMvo.stock' },
        {
            title: services.language.getText('components.Products.name'),
            dataIndex: '0',
            render: (_, row, index) => {
                return (
                    <Input
                        style={{ width: '250px' }}
                        value={row.forSubmit.name}
                        onChange={(e) => onForSubmitChange(index, { name: e.target.value })}
                        maxLength={14}
                        minLength={3}
                    />
                );
            },
        },
        {
            title: services.language.getText('jglx'),
            dataIndex: '1',
            render: (_, row, index) => {
                return (
                    <Select
                        style={{ width: '100px' }}
                        value={row.forSubmit.priceType}
                        onChange={(value: any) =>
                            onForSubmitChange(index, {
                                priceType: value,
                                price: null,
                                price2: null,
                            })
                        }
                    >
                        {Object.getOwnPropertyNames(PRICE_TYPE).map((key: string) => {
                            return (
                                <Select.Option value={(PRICE_TYPE as any)[key]} key={key}>
                                    {key}
                                </Select.Option>
                            );
                        })}
                    </Select>
                );
            },
        },
        {
            title: services.language.getText('jgsz'),
            dataIndex: '2',
            render: (_, row, index) => {
                const createPriceDom = (key: 'price' | 'price2') => {
                    return (
                        <Input
                            type="number"
                            value={row.forSubmit[key]}
                            onChange={(e) =>
                                onForSubmitChange(index, { [key]: formatPrice(e.target.value) })
                            }
                        />
                    );
                };
                const priceDom = createPriceDom('price');
                const price2Dom = createPriceDom('price2');
                let innerDom: ReactNode;
                if (row.forSubmit.priceType === PRICE_TYPE.一口价) {
                    innerDom = priceDom;
                } else if (row.forSubmit.priceType === PRICE_TYPE.价格区间) {
                    innerDom = (
                        <>
                            {priceDom}
                            {services.language.getText('to')}
                            {price2Dom}
                        </>
                    );
                } else if (row.forSubmit.priceType === PRICE_TYPE.折扣价) {
                    innerDom = (
                        <>
                            {services.language.getText('marketPrice')}
                            {priceDom}
                            {services.language.getText('zkj')}
                            {price2Dom}
                        </>
                    );
                }
                return <div className="live-room-product-add__price">{innerDom}</div>;
            },
        },
    ];
    const onForSubmitChange = (index: number, merge: any) => {
        const nextProducts = [...products];
        nextProducts[index].forSubmit = { ...nextProducts[index].forSubmit, ...merge };
        onChange(nextProducts);
    };
    const onChange = (value: any[]) => {
        setProducts(value);
        refreshForSubmits(value);
        onOriginChange?.(value.map((product) => product.forSubmit));
    };
    const refreshForSubmits = (value: any[]) => {
        value.forEach((product) => {
            if (!product.forSubmit) {
                product.forSubmit = {
                    goodsId: product.goodsMvo.id,
                    name: product.name?.substring(0, 14),
                    price: formatPrice(product.goodsMvo.marketPrice.value),
                    price2: null,
                    priceType: PRICE_TYPE.一口价,
                };
            }
            product.forSubmit.price = formatPrice(product.forSubmit.price);
            product.forSubmit.price2 = formatPrice(product.forSubmit.price2);
        });
    };
    const addProducts: typeof onChange = (value) => {
        const nextProducts = [...products, ...value];
        onChange(nextProducts);
    };
    const ShopSelector = ComponentsManager.get('ShopSelector');
    const shopSelectorProps = {
        fields: [
            { property: 'subSiteName' },
            { property: 'merchantTagId' },
            { property: 'merchantName' },
        ],
        formColumns: [
            { property: 'subSiteName' },
            { property: 'merchantName' },
            { property: 'merchantType' },
            { property: 'merchantStatus' },
        ],
    };
    const merchantIds = shops.map(({ merchantId }) => merchantId).join(',');
    const subsiteIds = shops.map(({ subSiteId }) => subSiteId).join(',');
    return (
        <div className="live-room-product-add">
            <div className="live-room-product-add__item">
                <div className="live-room-product-add__label">
                    {services.language.getText('selectSubsites')}：
                </div>
                <div className="live-room-product-add__value">
                    <ShopSelector {...shopSelectorProps} onChange={setShops} />
                    <CheckboxTable columns={shopColumns} value={shops} onChange={setShops} />
                </div>
            </div>
            <div className="live-room-product-add__item">
                <div className="live-room-product-add__label">
                    {services.language.getText('xzsp_1')}：
                </div>
                <div className="live-room-product-add__value">
                    <ProductSelector params={{ merchantIds, subsiteIds }} onChange={addProducts} />
                    <CheckboxTable columns={productColumns} value={products} onChange={onChange} />
                </div>
            </div>
        </div>
    );
};

type CheckboxTableProps<T = any> = {
    columns: TableProps<T>['columns'];
    value: T[];
    onChange: (datas: T[]) => void;
};

const CheckboxTable = <T,>(props: CheckboxTableProps<T>) => {
    const [selectedIndexes, setSelectedIndexes] = useState<number[]>([]);
    const { value, columns: originalColumns, onChange } = props;
    if (value.length === 0) {
        return null;
    } else {
        const columns: TableProps<T>['columns'] = [
            ...(originalColumns || []),
            {
                title: services.language.getText('common.tableAction'),
                dataIndex: 'id',
                render: (_, __, index) => {
                    return (
                        <Button
                            type="link"
                            onClick={() => removeByIndex(index)}
                            children={services.language.getText('common.delete')}
                        />
                    );
                },
            },
        ];
        const removeByIndex = (index: number) => {
            debugger;
            const nextValue = [...value];
            nextValue.splice(index, 1);
            const nextSelectedIndexes = selectedIndexes
                .filter((key) => key !== index)
                .map((key) => (key > index ? --key : key));
            setSelectedIndexes(nextSelectedIndexes);
            onChange(nextValue);
        };
        const removeSelected = () => {
            const nextValue: (T | null)[] = [...value];
            for (let i = 0; i < selectedIndexes.length; i++) nextValue[selectedIndexes[i]] = null;
            setSelectedIndexes([]);
            onChange(nextValue.filter(Boolean) as T[]);
        };
        return (
            <>
                <div style={{ width: '100%', overflowX: 'auto' }}>
                    <Table
                        scroll={{ x: true }}
                        rowKey={(_, index) => `${index}`}
                        locale={{ emptyText: <></> }}
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys: selectedIndexes.map((key) => `${key}`),
                            onChange: (selectedRowKeys: any[]) => {
                                const indexes = selectedRowKeys.map((key) => parseInt(`${key}`));
                                setSelectedIndexes(indexes);
                            },
                        }}
                        dataSource={value}
                        columns={columns}
                        pagination={false}
                    />
                </div>
                <Button type="primary" onClick={removeSelected}>
                    {services.language.getText('batchDelete')}
                </Button>
            </>
        );
    }
};
