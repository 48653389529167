// 配送方式 EXPRESS=快递|CITYWIDE=同城配
export enum DeliveryMode {
    EXPRESS = 'EXPRESS',
    CITYWIDE = 'CITYWIDE',
}
export const DeliveryModeValue = {
    EXPRESS: 'kd_2',
    CITYWIDE: 'tcp',
};
// 物流单（运单）生成方式 AUTOMATIC=自动叫单｜MANUAL=手动填单
export enum LogisticsBillGenerationMethodEnum {
    AUTOMATIC = 'AUTOMATIC',
    MANUAL = 'MANUAL',
}
// 物流单（运单）生成方式 AUTOMATIC=自动叫单｜MANUAL=手动填单
export const LogisticsBillGenerationMethodValue = {
    AUTOMATIC: 'zdjd',
    MANUAL: 'sdtd',
};
// 快递公司编码
// 圆通速递 yuantong
// 中通快递 zhongtong
// 百世快递  huitongkuaidi
// 韵达快递 yunda
// 顺丰速运  shunfeng
// 邮政快递包裹  youzhengguonei
// 邮政标准快递  youzhengbk
// 品骏快递 pjbest
// 德邦快递  debangkuaidi
// 申通快递  shentong
// 安能物流  annengwuliu
// 优速快递  youshuwuliu
// 京东快递  jd
// 天天快递  tiantian
// 极兔快递  jtexpress
// 美团 MEITUAN
// 达达 IMDADA
export enum DeliveryCompanyCodeEnum {
    yuantong = 'yuantong',
    zhongtong = 'zhongtong',
    huitongkuaidi = 'huitongkuaidi',
    yunda = 'yunda',
    shunfeng = 'shunfeng',
    youzhengguonei = 'youzhengguonei',
    youzhengbk = 'youzhengbk',
    pjbest = 'pjbest',
    debangkuaidi = 'debangkuaidi',
    shentong = 'shentong',
    annengwuliu = 'annengwuliu',
    youshuwuliu = 'youshuwuliu',
    jd = 'jd',
    tiantian = 'tiantian',
    jtexpress = 'jtexpress',
    MEITUAN = 'MEITUAN',
    IMDADA = 'IMDADA',
    TEST = 'TEST',
}
// 快递公司前端配置对象
export const DeliveryCompanyObj = {
    yuantong: {
        account: {
            name: 'khbm',
            placeholder: 'qsrytsddkhbm',
            required: true,
        },
        password: {
            name: 'khmy',
            placeholder: 'qsrytsddkhmy',
            required: true,
        },
    },
    zhongtong: {
        account: {
            name: 'dzmdzh',
            placeholder: 'qsrztkdddzmdzh',
            required: true,
        },
        password: {
            name: 'mm',
            placeholder: 'qsrztkddmm',
            required: true,
        },
    },
    huitongkuaidi: {
        account: {
            name: 'dzmdzh',
            placeholder: 'qsrbskdddzmdzh',
            required: true,
        },
        password: {
            name: 'my_1',
            placeholder: 'qsrbskddmy',
            required: true,
        },
    },
    yunda: {
        account: {
            name: 'khzh',
            placeholder: 'qsrydkddkhzh',
            required: true,
        },
        password: {
            name: 'mm',
            placeholder: 'qsrydkddmm',
            required: true,
        },
    },
    shunfeng: {
        account: {
            name: 'yjkh',
            placeholder: 'qsrsfsydyjkh',
            required: true,
        },
        password: {
            name: 'mm',
            placeholder: 'qsrsfsydmm',
            required: false,
        },
    },
    youzhengguonei: {
        account: {
            name: 'khxyh',
            placeholder: 'qsrzgyzsdwldkhxyh',
            required: true,
        },
        password: null,
    },
    youzhengbk: {
        account: {
            name: 'khxyh',
            placeholder: 'qsrzgyzsdwldkhxyh',
            required: true,
        },
        password: null,
    },
    pjbest: {
        account: {
            name: 'yjzh',
            placeholder: 'qsrpjkddyjzh',
            required: true,
        },
        password: {
            name: 'my_1',
            placeholder: 'qsrpjkddmy',
            required: true,
        },
    },
    debangkuaidi: {
        account: {
            name: 'khbm',
            placeholder: 'qsrdbkddkhbm',
            required: true,
        },
        password: {
            name: 'khmy',
            placeholder: 'qsrdbkddkhmy',
            required: true,
        },
    },
    shentong: {
        account: {
            name: 'zhmc',
            placeholder: 'qsrstkddzhmc',
            required: true,
        },
        password: {
            name: 'zhmm',
            placeholder: 'qsrstkddzhmm',
            required: true,
        },
    },
    annengwuliu: {
        account: {
            name: 'khbm',
            placeholder: 'qsranwldkhbm',
            required: true,
        },
        password: {
            name: 'khmy',
            placeholder: 'qsranwldkhmy',
            required: true,
        },
    },
    youshuwuliu: {
        account: {
            name: 'khbh',
            placeholder: 'qsryskddkhbh',
            required: true,
        },
        password: {
            name: 'khmy',
            placeholder: 'qsryskddkhmy',
            required: true,
        },
    },
    jd: {
        account: {
            name: 'khbm',
            placeholder: 'qsrjdkddkhbm',
            required: true,
        },
        password: null,
    },
    tiantian: {
        account: {
            name: 'khbh',
            placeholder: 'qsrttkddkhbh',
            required: true,
        },
        password: {
            name: 'khmy',
            placeholder: 'qsrttkddkhmy',
            required: true,
        },
    },
    jtexpress: {
        account: {
            name: 'khbm',
            placeholder: 'qsrjtkddkhbm',
            required: true,
        },
        password: {
            name: 'khmy',
            placeholder: 'qsrjtkddkhmy',
            required: true,
        },
    },
    MEITUAN: {
        account: null,
        password: null,
    },
    IMDADA: { account: null, password: null },
    TEST: { account: null, password: null },
};

// 门店物流公司设置
export interface DeliveryCompanyConfig {
    id: number;
    companyName: string;
    logisticsBillGenerationMethods: Array<LogisticsBillGenerationMethodEnum>;
    companyCode: DeliveryCompanyCodeEnum;
    account: string;
    password: string;
    defaulted: boolean;
}

// 物流公司基础信息
export interface BaseDeliveryCompany {
    id: number;
    name: string;
    code: DeliveryCompanyCodeEnum;
    deliveryModeName?: string;
}
