import { Config } from '@comall-backend-builder/core/lib/parser';
export const config: Config = {
    entities: {
        CouponRulesChannelsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
            apiPath: '/loader/admin/coupon_channel_stocks_statistics',
            filters: {
                channelName: {
                    type: 'string',
                    displayName: '<<qdmc>>',
                    defaultValue: undefined,
                    controlConfig: {
                        placeholder: '<<inputPlease>>',
                    },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '渠道发送状态',
                    defaultValue: undefined,
                    controlConfig: {
                        style: {
                            width: 160,
                        },
                        placeholder: '<<selectPlease>>',
                    },
                    options: [
                        {
                            id: 'ALL',
                            name: '<<all>>',
                        },
                        {
                            id: 'true',
                            name: '<<common.opend>>',
                        },
                        {
                            id: 'false',
                            name: '<<beforeClose>>',
                        },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                channelName: {
                    type: 'string',
                    displayName: '<<qdmc>>',
                },
                stockModel: {
                    type: 'string.options.select',
                    displayName: '渠道库存类型',
                    options: [
                        { id: 'INDEPENDENT', name: '独享' },
                        { id: 'SHARE', name: '共享' },
                    ],
                },
                maxCouponQuantity: {
                    type: 'string',
                    displayName: '<<qdkcsl>>',
                },
                remainingCouponQuantity: {
                    type: 'string',
                    displayName: '剩余库存',
                },
                memberQuantity: {
                    type: 'string',
                    displayName: '发券人数',
                },
                couponQuantity: {
                    type: 'string',
                    displayName: '已发券张数',
                },
                consumeCouponQuantity: {
                    type: 'string',
                    displayName: '<<hxzs>>',
                },
                consumeCouponMemberQuantity: {
                    type: 'string',
                    displayName: '<<hxrs>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '渠道发送状态',
                    options: [
                        {
                            id: 'ALL',
                            name: '<<all>>',
                        },
                        {
                            id: 'true',
                            name: '<<common.opend>>',
                        },
                        {
                            id: 'false',
                            name: '<<beforeClose>>',
                        },
                    ],
                },
            },
        },
    },
    components: {
        CouponRulesChannelsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'CouponRulesChannels',
                },
            ],
        },
        CouponRulesChannels: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'CouponRulesChannelsEntity',
            items: [
                {
                    component: 'CouponRulesChannelsFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    style: {
                        paddingTop: 10,
                        paddingBottom: 10,
                    },
                    items: [],
                },
                {
                    component: 'CouponRulesChannelsTable',
                },
            ],
        },
        CouponRulesChannelsFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'channelName',
                },
                {
                    property: 'status',
                },
            ],
        },
        CouponRulesChannelsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'channelName',
                },
                {
                    property: 'stockModel',
                },
                {
                    property: 'maxCouponQuantity',
                },
                {
                    property: 'remainingCouponQuantity',
                },
                {
                    property: 'memberQuantity',
                },
                {
                    property: 'couponQuantity',
                },
                {
                    property: 'consumeCouponQuantity',
                },
                {
                    property: 'consumeCouponMemberQuantity',
                },
                {
                    property: 'status',
                },
            ],
        },
    },
};
