import React, { PureComponent, createElement } from 'react';
import { message as AntMessage, Select, InputNumber, Modal, Input } from 'antd';
import { connect } from 'react-redux';
import { isArray, map } from 'lodash';
import { services, ComponentsManager } from '@comall-backend-builder/core';
import './index.less';

const api = services.api;

const POINT_TRANS_ACTION = [
    // {
    //     id: 'POINT_PAY',
    //     name: '消费',
    // },
    // {
    //     id: 'AFTER_SALE_ORDER',
    //     name: '售后单',
    // },
    // {
    //     id: 'RECHARGE',
    //     name: '充值',
    // },
    {
        id: 'MAN_MADE_UP',
        name: 'zj_2',
    },
    {
        id: 'MAN_MADE_DOWN',
        name: 'js_1',
    },
    // {
    //     id: 'SIGN_IN',
    //     name: '签到奖励',
    // },
    // {
    //     id: 'STOP_DOWN',
    //     name: '停车扣减',
    // },
    // {
    //     id: 'LUCK_DRAW_GIVE',
    //     name: '抽奖赠送',
    // },
    // {
    //     id: 'LUCK_DRAW_CONSUME',
    //     name: '抽奖消耗',
    // },
    // {
    //     id: 'PERFECT_GIVE',
    //     name: '完善信息赠送',
    // },
    // {
    //     id: 'EXCHANGE',
    //     name: '积分兑换',
    // },
    // {
    //     id: 'QUESTIONNAIRE_GIVE',
    //     name: '问卷调查赠送',
    // },
    // {
    //     id: 'ACTIVITY_UP',
    //     name: '活动奖励',
    // },
    // {
    //     id: 'ACTIVITY_DOWN',
    //     name: '活动扣减',
    // },
    // {
    //     id: 'REGISTER_AWARD',
    //     name: '注册奖励',
    // },
    // {
    //     id: 'INVITATION_AWARD',
    //     name: '邀请奖励',
    // },
    // {
    //     id: 'BIRTHDAY_GIVE',
    //     name: '生日赠送',
    // },
    // {
    //     id: 'EVENT_MARKETING_LICKED',
    //     name: '被点赞奖励',
    // },
    // {
    //     id: 'EVENT_MARKETING_COMMENT',
    //     name: '被评论奖励',
    // },
    // {
    //     id: 'EVENT_MARKETING_FAVORITES',
    //     name: '被收藏奖励',
    // },
    // {
    //     id: 'EVENT_INTERACTIVE_FOLLOW',
    //     name: '关注他人奖励',
    // },
    // {
    //     id: 'EVENT_INTERACTIVE_BE_FOLLOW',
    //     name: '被关注奖励',
    // },
    // {
    //     id: 'EVENT_INTERACTIVE_MUTUAL_FOLLOW',
    //     name: '建立好友奖励',
    // },
    // {
    //     id: 'TOPIC_ACTIVITY',
    //     name: '话题激励',
    // },
    // {
    //     id: 'POINT_RETURN',
    //     name: '兑换线下积分退回',
    // },
    // {
    //     id: 'EXCHANGE_OFFLINE_POINT',
    //     name: '兑换线下积分',
    // },
    // {
    //     id: 'ORDER_RECEIVE_GIVE',
    //     name: '下单赠送',
    // },
    // {
    //     id: 'PRODUCT_COMMENT_GIVE',
    //     name: '评论赠送',
    // },
    // {
    //     id: 'ORDER_AFTER_SALE_RETURN',
    //     name: '售后退还',
    // },
];

/**
 * 会员个人信息--积分明细
 */
class memberPointTransLogView extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            availablePoint: 0,
            cumulativePoint: 0,
            showModal: false,
            transAction: 'MAN_MADE_UP',
            transAmount: 1,
            remark: '',
            loading: false,
        };
    }
    componentDidMount() {
        this.loadData();
    }
    loadData = () => {
        const { params } = this.props;
        const id = params.id;
        //获取详细信息
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                apiPath: `/admin/member_account_trans_logs/${id}`,
            }
        ).then((res: any) => {
            this.setState({
                availablePoint: res.availablePoint,
                cumulativePoint: res.cumulativePoint,
            });
        });
    };
    renderFilter() {
        const { entity, componentId } = this.props;
        let config = {
            component: 'FilterFormPlus',
            direction: 'inline',
            entity,
            componentId,
            submit: {
                text: services.language.getText('common.search'),
            },
            reset: {
                text: services.language.getText('common.reset'),
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'subsite',
                },
                {
                    property: 'dateRange',
                },
            ],
        };
        return createElement(ComponentsManager.get('FilterFormPlus'), config);
    }
    renderComponent() {
        const { entity, componentId } = this.props;
        let config = {
            component: 'DataTable',
            entity,
            componentId,
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'transTime',
                },
                {
                    property: 'subsiteName',
                },
                {
                    property: 'transActionRemark',
                },
                {
                    property: 'transAmount',
                },
                {
                    property: 'desc',
                },
                {
                    property: 'associateOrder',
                },
                {
                    property: 'operator',
                },
                {
                    property: 'remark',
                },
            ],
        };

        return createElement(ComponentsManager.get('DataTable'), config);
    }

    renderEditModal = () => {
        const { transAction, transAmount, remark } = this.state;
        return (
            <div className="edit-modal">
                <div className="modal-row">
                    <span className="row-title">
                        <span className="required">*</span>
                        {services.language.getText('xgjf')}：
                    </span>
                    <Select
                        className="trans-action-selection"
                        style={{ width: 130 }}
                        value={transAction}
                        placeholder={services.language.getText('selectPlease')}
                        onChange={(transAction: any) => this.selectedTransAction(transAction)}
                    >
                        {isArray(POINT_TRANS_ACTION) &&
                            map(POINT_TRANS_ACTION, ({ id, name }) => (
                                <Select.Option key={id}>
                                    {services.language.getText(name)}
                                </Select.Option>
                            ))}
                    </Select>
                    <div className="number-input">
                        <InputNumber
                            placeholder={services.language.getText('inputPlease')}
                            min={1}
                            max={999999}
                            step={1}
                            precision={0}
                            style={{ width: '130px' }}
                            value={transAmount}
                            onChange={(value: any) => {
                                this.changeTransAmount(value);
                            }}
                        ></InputNumber>
                    </div>
                </div>
                <div className="edit-tip">{services.language.getText('qsrclwzzs')}</div>
                <div className="modal-row">
                    <span className="row-title">
                        <span className="required">*</span>
                        {services.language.getText('remark')}：
                    </span>
                    <Input.TextArea
                        value={remark}
                        style={{ width: 500 }}
                        placeholder={services.language.getText('jzdsrxbzzjyy')}
                        rows={4}
                        maxLength={50}
                        onChange={this.changeRemark}
                    />
                </div>
            </div>
        );
    };
    selectedTransAction = (transAction: any) => {
        this.setState({ transAction });
    };
    changeTransAmount = (transAmount: number) => {
        this.setState({ transAmount });
    };
    changeRemark = (e: any) => {
        this.setState({ remark: e.target.value });
    };
    onConfirmEditPoint = () => {
        const { params, entity } = this.props;
        const id = params.id;
        const { transAction, transAmount, remark, loading } = this.state;
        if (!transAction) {
            AntMessage.warn(services.language.getText('qxzjflx'));
            return;
        }
        if (!transAmount) {
            AntMessage.warn(services.language.getText('qtxjf'));
            return;
        }
        const reg = /^\+?[0-9]\d*$/;
        if (!reg.test(transAmount)) {
            AntMessage.warn(services.language.getText('qtxdydy'));
            return;
        }
        if (!remark) {
            AntMessage.warn(services.language.getText('qtxbz'));
            return;
        }
        if (remark && remark.length > 50) {
            AntMessage.warn(services.language.getText('bzzdsr'));
        }
        this.setState({ loading: true });
        if (loading) {
            return;
        }
        api.put(
            { transAction, transAmount, remark },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                apiPath: `/admin/point_accounts/v2/${id}`,
            }
        )
            .then(
                () => {
                    AntMessage.success(services.language.getText('common.modifySuccess'), () => {
                        this.onCancel();
                        entity.search();
                        this.loadData();
                        this.setState({ transAction: 'MAN_MADE_UP', transAmount: 1, remark: '' });
                    });
                },
                () => {
                    AntMessage.error(services.language.getText('modifyFailWait'), () => {
                        this.onCancel();
                        entity.search();
                        this.loadData();
                        this.setState({ transAction: 'MAN_MADE_UP', transAmount: 1, remark: '' });
                    });
                }
            )
            .finally(() => {
                this.setState({ loading: false });
            });
    };
    onCancel = () => {
        this.setState({ showModal: false });
    };
    openModal = () => {
        this.setState({ showModal: true });
    };

    render() {
        const { availablePoint, cumulativePoint, showModal } = this.state;
        const props = {
            width: 720,
            visible: showModal,
            title: services.language.getText('xgjf'),
            onCancel: this.onCancel,
            onOk: this.onConfirmEditPoint,
        };
        return (
            <div className="member-point-trans-log-view">
                <div className="member-points-wrap">
                    <div className="member-points-item">
                        <div>{cumulativePoint}</div>
                        <div>{services.language.getText('ljjf')}</div>
                    </div>
                    <div className="member-points-item member-points-edit">
                        <div className="">
                            <span>{availablePoint}</span>
                            <div>{services.language.getText('kyjf')}</div>
                        </div>
                        {/* <Button
                            className="member-points-edit-button"
                            type="default"
                            onClick={this.openModal}
                        >
                            修改积分
                        </Button> */}
                    </div>
                </div>
                {this.renderFilter()}
                {this.renderComponent()}

                <Modal {...props}>
                    <div>{this.renderEditModal()}</div>
                </Modal>
            </div>
        );
    }
}

function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
        fields: entity.fields,
    };
}

export const MemberPointTransLogView = connect(mapStateToProps)(memberPointTransLogView);
