import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        WechatTemplatesEntity: {
            apiPath: '/loader/admin/wechat_templates',
            properties: {
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsites>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchant>>',
                },
                orderNo: {
                    type: 'object.link',
                    displayName: '<<orderNumber>>',
                },
                orderType: {
                    type: 'string.options.select',
                    displayName: '<<ddlx>>',
                    options: [
                        {
                            id: 'ORDINARY',
                            name: '<<ptdd_1>>',
                        },
                        {
                            id: 'PRESALE',
                            name: '<<ysdd>>',
                        },
                        {
                            id: 'PRESALE_SHARE_STOCK',
                            name: '<<ysgxkcdd>>',
                        },
                        {
                            id: 'CGB',
                            name: '<<sqtgdd>>',
                        },
                        {
                            id: 'GROUP_BUYING',
                            name: '<<ptdd>>',
                        },
                        {
                            id: 'RESERVE',
                            name: '<<yyqgdd>>',
                        },
                        {
                            id: 'INTERNAL_PURCHASE',
                            name: '<<qynghddd>>',
                        },
                        {
                            id: 'BLIND_BOX',
                            name: '<<mhdd>>',
                        },
                    ],
                },
                memberMobile: {
                    type: 'string',
                    displayName: '<<member>>',
                },
                receiverName: {
                    type: 'string',
                    displayName: '<<shr>>',
                },
                receiverMobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                deliveryMode: {
                    type: 'string.options.select',
                    displayName: '<<deliveryType>>',
                    options: [
                        {
                            id: 'EXPRESS_DELIVERY',
                            name: '<<kd_2>>',
                        },
                        {
                            id: 'SELF_MENTION',
                            name: '<<zt>>',
                        },
                        {
                            id: 'CITYWIDE_DELIVERY',
                            name: '<<tcp>>',
                        },
                    ],
                },
                deliveryDate: {
                    type: 'string',
                    displayName: '<<sd_1>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<xdsj>>',
                },
                remark: {
                    type: 'string.popover',
                    displayName: '<<hybz>>',
                },
            },
        },
        MessageParameterEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
            apiPath: '/admin/message_parameters',
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.copy',
                    displayName: '<<bl_2>>',
                },
                description: {
                    type: 'string',
                    displayName: '<<blsm>>',
                },
            },
        },
    },
    components: {
        WechatTemplatesView: {
            component: 'Viewport',
        },
        WechatTemplatesPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'WechatTemplatesTabs',
                },
            ],
        },
        WechatTemplatesTabs: {
            component: 'WechatTemplatesFind',
            entity: 'storeSelect',
            items: {
                ALL: 'WechatTemplatesListPane',
            },
        },
        WechatTemplatesListPane: {
            component: 'WechatTemplatesFindInterval',
            entity: 'WechatTemplatesEntity',
            switchRefresh: true,
            content: {
                component: 'DataList',
                grid: { gutter: 9, column: 3 },
                renderItem: 'WechantTemplatesFindListItem',
                loadFirstPage: false,
                split: false,
                className: 'order-waiting-pick',
                pagination: {
                    pageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '15', '20'],
                    showTotal(total: any) {
                        return services.interpolate(services.language.getText('total'), {
                            total,
                        });
                    },
                },
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/wechat-templates',
                    component: 'WechatTemplatesView',
                    breadcrumbName: '<<wechatTemplate>>',
                    privilege: {
                        path: 'wechatTemplate',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/:status',
                            component: 'WechatTemplatesPage',
                        },
                        {
                            path: '/',
                            component: 'WechatTemplatesPage',
                        },
                    ],
                },
            ],
        },
    ],
};
