import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
export const PointExchangeRuleLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiPath = '/admin/point_exchange_rule';
        const { id, subsiteId } = data;
        let subsites: any[] = [];

        if (id) {
            config.apiPath = `/admin/point_exchange_rule/${id}`;
            const { result } = await api.get({}, {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: '/admin/subsites/mine',
            });
            subsites = result
        }
        if (subsiteId) {
            data.subsiteIds = subsiteId.id ? subsiteId.id : subsiteId;
            delete data.subsiteId;
        }

        return await api.get(data, config).then((res: any) => {
            if (!id) {
                res.result =
                res &&
                res.result.map((item: any) => {
                    item.subsiteName = item.subsites ? item.subsites.map((i: any) => ({
                        id: i.id,
                        name: i.subsiteName
                    })) : [];
                    item.pointUnitDesc = `<div>
                        <p>最小抵扣单位: ${item.deductionUnit}积分</p>
                        <p>最少使用: ${item.minUse}积分</p>
                        <p>最多使用: ${item.maxUse}积分</p>
                    </div>`
                    return item;
                });
            } else {
                if (res && res.subsites.length > 0) {
                    res.subsites = subsites.filter((i: any) => {
                        return res.subsites.findIndex((subsite: any) => subsite.id.toString() === i.id.toString()) >= 0
                    })
                }
            }
            return res;
        });
    },
    post: async function(data, config: ApiRequestConfig) {
        config.apiPath = '/admin/point_exchange_rule';
        if (data.subsites && data.subsites.length > 0) {
            data.subsites = data.subsites.map((i: any) => ({
                id: i.id,
                subsiteName: i.name
            }))
        }
        return await api.post(data, config);
    },
    put: async function(data, config: ApiRequestConfig) {
        config.apiPath = `/admin/point_exchange_rule/${data.id}`;
        if (data.subsites && data.subsites.length > 0) {
            data.subsites = data.subsites.map((i: any) => ({
                id: i.id,
                subsiteName: i.name
            }))
        }
        return await api.put(data, config);
    },
    delete: function(data, config: ApiRequestConfig) {
        config.apiPath = `/admin/point_exchange_rule/${data.id}`;
        return api.delete({}, config);
    },
};
