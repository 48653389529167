import { getUrlParam } from '../../../applications/cae/services/utils';

const REDICRECT_PAGE_PATH = 'entrace/index';

/**
 * 将真实的小程序路径转换成中转页含参路径
 * @param link 真实页面路径（可含参）
 * @returns
 */
export function encodePathToURL(link: string, subsiteId?: number) {
    let path = link.startsWith('/') ? link : `/${link}`;
    let URL = `${REDICRECT_PAGE_PATH}?url=${encodeURIComponent(
        path
    )}&ut={utm_type}&ui={utm_id}&sc={scenario_code}&rt={reply_time}&ai={app_id}`;
    if (subsiteId !== undefined) {
        URL += `&sid=${subsiteId}`;
    }
    return URL;
}
/**
 * 将中转页含参路径转换成真实小程序路径
 * @param url 完整中转页含参路径
 * @returns
 */
export function decodeURLToPath(url: string) {
    const path = getUrlParam(url, 'url') || '';
    return path.startsWith('/') ? path.substring(1) : path;
}
