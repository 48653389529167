import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { DayPeriodCondition } from './day-period-condition';

export class ObjectDayPeriodConditionMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <DayPeriodCondition {...controlInfo} />;
    }
}
