import React, { PureComponent } from 'react';
import { find } from 'lodash';
import { Modal, Table, Tooltip, Empty } from 'antd';
import { RegisterchannelEnum, MemberInfoGender, MemberInfoStatus } from '../../common';
import './index.less';
import { api, language } from '@comall-backend-builder/core/lib/services';

enum ClientType {
    WECHAT = 'WECHAT',
    ALIPAY = 'ALIPAY',
    APP = 'APP',
    BYTE_DANCE = 'BYTE_DANCE',
    OFFICIAL = 'OFFICIAL',
}
interface MemberInfoClient {
    client: ClientType;
    recordCount: number;
}
export interface MemberInfo {
    avatarUrl: string;
    birthday: string;
    clients: MemberInfoClient[];
    crmId: string;
    gender: MemberInfoGender;
    id: string;
    idCardNo: string;
    mobile: string;
    realName: string;
    region: string;
    registerChannel: RegisterchannelEnum;
    registerSubsiteName: string;
    registerSubsiteCode: string;
    registerTime: string;
    status: MemberInfoStatus;
    timeOfLife: string;
}
enum ModalVisibleState {
    None = 'none',
    Invoice = 'invoice',
    Address = 'address',
    Card = 'card',
}
export enum MemberInfoError {
    crmError = 1250000,
}
interface Props {
    memberId: string;
    memberInfo?: MemberInfo | null;
    memberInfoError?: MemberInfoError;
}
interface State {
    modalVisibleState: ModalVisibleState;
    modalData: any[];
    loading: boolean;
}

const statusOptions = [
    { id: MemberInfoStatus.NORMAL, name: 'zc_1' },
    { id: MemberInfoStatus.INVALID, name: 'invalid' },
    { id: MemberInfoStatus.OFF, name: 'off' },
];
const genderOptions = [
    {
        id: MemberInfoGender.UNKNOWN,
        name: 'wz_4',
    },
    { id: MemberInfoGender.WOMAN, name: 'n' },
    { id: MemberInfoGender.MAN, name: 'n_2' },
];
export const registerChannelOptions = [
    {
        id: RegisterchannelEnum.CRM,
        name: 'crm',
    },
    {
        id: RegisterchannelEnum.MAGIC_WECHAT,
        name: 'jmagicwxxcx',
    },
    {
        id: RegisterchannelEnum.MAGIC_ALIPAY,
        name: 'jmagiczfbxcx',
    },
    {
        id: RegisterchannelEnum.MAGIC_ANDROID,
        name: 'jmagicazkhd',
    },
    {
        id: RegisterchannelEnum.MAGIC_IOS,
        name: 'jmagicpgkhd',
    },
    {
        id: RegisterchannelEnum.MAGIC_PC,
        name: 'jmagicwyd',
    },
    {
        id: RegisterchannelEnum.MAGIC_BYTE_DANCE,
        name: 'jmagicdouyin',
    },
];

export default class MemberInfoPane extends PureComponent<Props, State> {
    state = {
        modalVisibleState: ModalVisibleState.None,
        modalData: [],
        loading: false,
    };

    private onChangeModal = (state: ModalVisibleState) => {
        this.setState(
            {
                modalVisibleState: state,
                loading: state !== ModalVisibleState.None,
            },
            () => {
                this.getMoadlData();
            }
        );
    };

    private getMoadlData = async () => {
        const { memberId } = this.props;
        const { modalVisibleState } = this.state;
        if (modalVisibleState === ModalVisibleState.Invoice) {
            const res: any[] = await api.get(
                {
                    memberId,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`,
                    apiPath: `/admin/member_invoice_titles/mine`,
                }
            );

            this.setState({
                modalData: res,
                loading: false,
            });
        } else if (modalVisibleState === ModalVisibleState.Address) {
            const res: any[] = await api.get(
                {
                    memberId,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`,
                    apiPath: `/admin/addresses/member_address`,
                }
            );

            this.setState({
                modalData: res,
                loading: false,
            });
        } else if (modalVisibleState === ModalVisibleState.Card) {
            const res: any[] = await api.get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`,
                    apiPath: `/admin/members/${memberId}/car_numbers`,
                }
            );

            this.setState({
                modalData: res,
                loading: false,
            });
        } else if (modalVisibleState === ModalVisibleState.None) {
            this.setState({
                modalData: [],
            });
        }
    };

    private getIconList = () => {
        const { memberInfo } = this.props;
        const wechatIcon: string = require('./wechat-icon.png');
        const alipayIcon: string = require('./alipay-icon.png');
        const officialAccountIcon: string = require('./official-account-icon.png');
        const appIcon: string = require('./app-icon.png');
        const byteDanceIcon: string = require('./douyin-icon.png');
        const clientList: any[] = [];
        if (memberInfo && memberInfo.clients) {
            memberInfo?.clients.forEach((client) => {
                let icon = '';
                let text = '';
                if (client.client === ClientType.WECHAT) {
                    icon = wechatIcon;
                    text = language.getText('wxMiniP');
                } else if (client.client === ClientType.ALIPAY) {
                    icon = alipayIcon;
                    text = language.getText('aliMiniP');
                } else if (client.client === ClientType.OFFICIAL) {
                    icon = officialAccountIcon;
                    text = language.getText('wxgzh');
                } else if (client.client === ClientType.APP) {
                    icon = appIcon;
                    text = 'APP';
                } else if (client.client === ClientType.BYTE_DANCE) {
                    icon = byteDanceIcon;
                }
                clientList.push({
                    ...client,
                    icon,
                    text,
                });
            });
        }
        return clientList;
    };
    private renderInfoTop = () => {
        const { memberInfo } = this.props;
        const classes = {
            top: `${prefix}__top`,
            topLeft: `${prefix}__top__left`,
            avatar: `${prefix}__top__left__avatar`,
            id: `${prefix}__top__left__id`,
            division: `${prefix}__top__division`,
            divisionLine: `${prefix}__top__division__line`,
            topRight: `${prefix}__top__right`,
            account: `${prefix}__top__right`,
            accountName: `${prefix}__top__right__name`,
            accountIcon: `${prefix}__top__right__icon`,
            accountIconItem: `${prefix}__top__right__icon__item`,
        };
        const defaultAvatarPic = require('./default-avatar.png');
        const iconList = this.getIconList();
        if (memberInfo) {
            return (
                <div className={classes.top}>
                    <div className={classes.topLeft}>
                        <img
                            className={classes.avatar}
                            src={memberInfo.avatarUrl || defaultAvatarPic}
                            alt=""
                        />
                        <div className={classes.id}>
                            {this.renderItem(
                                language.getText('phoneNum'),
                                memberInfo.mobile,
                                `${prefix}__top`
                            )}
                            {this.renderItem(
                                language.getText('memberId'),
                                memberInfo.id,
                                `${prefix}__top`
                            )}
                            {this.renderItem('CRMID', memberInfo.crmId, `${prefix}__top`)}
                        </div>
                    </div>
                    <div className={classes.division}>
                        <div className={classes.divisionLine}></div>
                    </div>
                    <div className={classes.topRight}>
                        <div className={classes.accountName}>{language.getText('sygqd')}</div>
                        <div className={classes.accountIcon}>
                            {iconList.map((item) => {
                                return (
                                    <Tooltip title={item.text}>
                                        <img
                                            src={item.icon}
                                            alt=""
                                            className={classes.accountIconItem}
                                        />
                                    </Tooltip>
                                );
                            })}
                        </div>
                    </div>
                </div>
            );
        }
        return null;
    };

    private renderItem = (key: string, value: React.ReactNode, prefix: string) => {
        const classes = {
            item: `${prefix}__item`,
            itemKey: `${prefix}__item__key`,
            itemValue: `${prefix}__item__value`,
        };
        return (
            <div className={classes.item}>
                <div className={classes.itemKey}>{key}：</div>
                <div className={classes.itemValue}>{value || ''}</div>
            </div>
        );
    };

    private renderStatus = () => {
        const { memberInfo } = this.props;
        if (memberInfo) {
            const statusText = find(statusOptions, { id: memberInfo.status });
            return (
                <div className={`${prefix}__status`}>
                    <div className={`${prefix}__status__text`}>{statusText?.name || '-'}</div>
                </div>
            );
        }
        return null;
    };

    private getLeftInfo = (
        memberInfo: MemberInfo
    ): {
        key: string;
        value: React.ReactNode;
    }[] => {
        const genderItem = find(genderOptions, { id: memberInfo.gender });
        return [
            {
                key: language.getText('name'),
                value: memberInfo?.realName || '',
            },
            {
                key: language.getText('xb_1'),
                value: genderItem?.name || '',
            },
            {
                key: language.getText('csrq'),
                value: memberInfo?.birthday || '',
            },
            {
                key: language.getText('zjh'),
                value: memberInfo?.idCardNo || '',
            },
            {
                key: language.getText('dq'),
                value: memberInfo?.region || '',
            },
            {
                key: language.getText('fptt'),
                value: (
                    <span
                        className={`${prefix}__archives__item__button`}
                        onClick={this.onChangeModal.bind(this, ModalVisibleState.Invoice)}
                    >
                        {language.getText('common.view')}
                    </span>
                ),
            },
            {
                key: language.getText('shdz'),
                value: (
                    <span
                        className={`${prefix}__archives__item__button`}
                        onClick={this.onChangeModal.bind(this, ModalVisibleState.Address)}
                    >
                        {language.getText('common.view')}
                    </span>
                ),
            },
            {
                key: language.getText('bdcp'),
                value: (
                    <span
                        className={`${prefix}__archives__item__button`}
                        onClick={this.onChangeModal.bind(this, ModalVisibleState.Card)}
                    >
                        {language.getText('common.view')}
                    </span>
                ),
            },
        ];
    };
    private getRightInfo = (
        memberInfo: MemberInfo
    ): {
        key: string;
        value: React.ReactNode;
    }[] => {
        const registerChannel = find(registerChannelOptions, { id: memberInfo?.registerChannel });
        return [
            {
                key: language.getText('zcmd'),
                value: memberInfo?.registerSubsiteName || '',
            },
            {
                key: language.getText('zcsj'),
                value: memberInfo?.registerTime || '',
            },
            {
                key: language.getText('hl'),
                value: memberInfo?.timeOfLife || '',
            },
            {
                key: language.getText('zcqd'),
                value: registerChannel ? language.getText(registerChannel.name) : '',
            },
        ];
    };

    private renderArchives = () => {
        const { memberInfo } = this.props;
        const classes = {
            archives: `${prefix}__archives`,
            left: `${prefix}__archives__left`,
            right: `${prefix}__archives__right`,
            title: `${prefix}__archives__title`,
            item: `${prefix}__archives__item`,
            itemKey: `${prefix}__archives__item__key`,
            itemButton: `${prefix}__archives__item__button`,
        };
        if (memberInfo) {
            const leftInfoList = this.getLeftInfo(memberInfo);
            const rightInfoList = this.getRightInfo(memberInfo);

            return (
                <div className={classes.archives}>
                    <div className={classes.left}>
                        <div className={classes.title}>{language.getText('grda')}</div>
                        {leftInfoList.map((item) => {
                            return this.renderItem(item.key, item.value, `${prefix}__archives`);
                        })}
                    </div>
                    <div className={classes.right}>
                        <div className={classes.title}>{language.getText('zcxx')}</div>
                        {rightInfoList.map((item) => {
                            return this.renderItem(item.key, item.value, `${prefix}__archives`);
                        })}
                    </div>
                </div>
            );
        }
        return null;
    };

    private getModalTitle = () => {
        const { modalVisibleState } = this.state;
        if (modalVisibleState === ModalVisibleState.None) {
            return '';
        } else if (modalVisibleState === ModalVisibleState.Invoice) {
            return language.getText('fptt');
        } else if (modalVisibleState === ModalVisibleState.Address) {
            return language.getText('shdz');
        } else if (modalVisibleState === ModalVisibleState.Card) {
            return language.getText('bdcp');
        }
    };
    private getModalColumns = () => {
        const { modalVisibleState } = this.state;
        if (modalVisibleState === ModalVisibleState.None) {
            return [];
        } else if (modalVisibleState === ModalVisibleState.Invoice) {
            return [
                {
                    title: language.getText('fptt'),
                    dataIndex: 'type',
                    render: (type: string) => (
                        <span>
                            {type === 'PERSONAL'
                                ? language.getText('fqydw')
                                : language.getText('qydw')}
                        </span>
                    ),
                },
                {
                    title: language.getText('jttdwmc'),
                    dataIndex: 'name',
                },
                {
                    title: language.getText('nsrsbm'),
                    dataIndex: 'taxNo',
                },
            ];
        } else if (modalVisibleState === ModalVisibleState.Address) {
            return [
                {
                    title: '',
                    dataIndex: 'id',
                    render: (id: string, row: any) => (
                        <div>
                            {row.isDefault ? (
                                <span className={`${prefix}__default`}>
                                    {language.getText('mr')}
                                </span>
                            ) : null}
                            {row.addressTag ? (
                                <span className={`${prefix}__tag`}>
                                    {row.addressTag === 'OFFICE'
                                        ? language.getText('gs_1')
                                        : language.getText('j_5')}
                                </span>
                            ) : null}
                        </div>
                    ),
                },
                {
                    title: language.getText('shr'),
                    dataIndex: 'name',
                },
                {
                    title: language.getText('phoneNum'),
                    dataIndex: 'mobile',
                },
                {
                    title: language.getText('dz_1'),
                    dataIndex: 'address',
                    render: (address: string, row: any) => {
                        const region = (row.region || []).map((i: any) => i.name).join('');
                        return (
                            <span>
                                {region}
                                {address}
                            </span>
                        );
                    },
                },
            ];
        } else if (modalVisibleState === ModalVisibleState.Card) {
            return [
                {
                    title: language.getText('cph'),
                    dataIndex: 'carNumber',
                },
                {
                    title: language.getText('bdsj'),
                    dataIndex: 'createTime',
                },
            ];
        }
    };
    private renderModal = () => {
        const { modalVisibleState, modalData, loading } = this.state;
        const visible = modalVisibleState !== ModalVisibleState.None;
        const columns = this.getModalColumns();
        const modalProps = {
            className: `${prefix}__table`,
            width: 900,
            title: this.getModalTitle(),
            visible,
            footer: null,
            onCancel: this.onChangeModal.bind(this, ModalVisibleState.None),
        };
        return (
            <Modal {...modalProps}>
                <Table
                    rowClassName={(_, index: number) => {
                        if (index % 2) {
                            return `${prefix}__table__bg`;
                        }
                        return '';
                    }}
                    rowKey={(record: any) => record.id}
                    columns={columns}
                    loading={loading}
                    dataSource={modalData}
                    pagination={false}
                />
            </Modal>
        );
    };

    private renderError = () => {
        const { memberInfo, memberInfoError } = this.props;
        if (!memberInfo && memberInfoError) {
            const description =
                memberInfoError === MemberInfoError.crmError ? language.getText('jcrmyhbcz') : '';
            return (
                <div className={`${prefix}__error`}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={description} />
                </div>
            );
        }
        return null;
    };

    render() {
        return (
            <div className={prefix}>
                <div className={`${prefix}__title`}>{language.getText('hyxx')}</div>
                {this.renderInfoTop()}
                {this.renderStatus()}
                {this.renderArchives()}
                {this.renderModal()}
                {this.renderError()}
            </div>
        );
    }
}

const prefix = 'member-info-pane';
