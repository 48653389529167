import React from 'react';
import { TreeSelect } from 'antd';
import { get } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import {
    handleOrganizationTreeData,
    handleFilterCustomOrganization,
    getAttributeValue,
    OrganizationType,
    AttributeCode,
} from '../common';
const AntdTreeNode = TreeSelect.TreeNode;
interface TreeNode {
    name: string;
    id: string;
    children: TreeNode[];
    namePath: string[];
}

interface OrganizationSelectorState {
    options: TreeNode[];
    flatData: TreeNode[];
}
interface OrganizationSelectorProps {
    onChange: (val: SelectedValue) => void;
    value: SelectedValue;
    disabled: boolean;
    style: any;
    filterCustomOrganization?: boolean;
}

interface SelectedValue {
    organizationId: string;
    organizationName?: string; // 为查看模式预留字段
    parentNamePath?: string;
    organizationType: OrganizationType;
    originId: string;
    subsiteId?: string;
}

/**
 * showCheckedStrategy 只能用于复选框模式下的树，单选时无法 显示 已选择项的父级
 * 所以需要先自行拼好了 回填到value中
 */

export class OrganizationSelector extends React.Component<
    OrganizationSelectorProps,
    OrganizationSelectorState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            options: [],
            flatData: [],
        };
    }

    componentDidMount() {
        this.loadTree();
    }

    onChange = (organizationId: string) => {
        const { onChange } = this.props;
        const { flatData } = this.state;
        const item = flatData.find((i) => i.id === organizationId);
        onChange({
            organizationId,
            organizationName: get(item, 'name'),
            parentNamePath: get(item, 'parentNamePath', ''),
            organizationType: getAttributeValue(item, AttributeCode.ORGANIZATION_TYPE),
            originId: getAttributeValue(item, AttributeCode.ORGIN_ID),
            subsiteId: getAttributeValue(item, AttributeCode.SUBSITE_ID),
        });
    };
    onSearch = (val: string) => {
        this.loadTree(val);
    };
    filterTreeNode = () => {
        return true;
    };
    loadTree = async (name?: string) => {
        try {
            const { filterCustomOrganization } = this.props;
            const res: any = await services.api.get(
                { name },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
                    apiPath: `/organizations`,
                }
            );
            const organizations = filterCustomOrganization
                ? handleOrganizationTreeData(handleFilterCustomOrganization(res.result))
                : handleOrganizationTreeData(res.result);
            const treeData = this.handleTreeData(organizations);

            // 完整树的扁平化数据
            if (!name) {
                let flatData: TreeNode[] = [];
                this.flatList(treeData, flatData);
                this.setState({ flatData });
            }

            this.setState({ options: treeData });
        } catch (error) {
            errorHandle(error as any);
        }
    };
    flatList = (data: TreeNode[], flatData: TreeNode[]) => {
        for (let i = 0; i < data.length; i++) {
            const node = data[i];
            flatData.push(node);
            if (node.children) {
                this.flatList(node.children, flatData);
            }
        }
    };
    handleTreeData = (treeData: TreeNode[]) => {
        treeData.forEach((item) => {
            const parentName = item.name;
            if (item.children && item.children.length) {
                item.children = item.children.map((i) => {
                    if (item.namePath && item.namePath.length) {
                        i.namePath = [...item.namePath, i.name];
                    } else {
                        i.namePath = [parentName, i.name];
                    }
                    return i;
                });

                this.handleTreeData(item.children);
            }
        });
        return treeData;
    };

    renderTreeNodes = (treeNodes: TreeNode[]) => {
        const { value } = this.props;
        return treeNodes.map((item: TreeNode) => {
            const selected = get(value, 'organizationId') === item.id;
            const style: React.CSSProperties = {
                color: selected ? '#448EF7' : 'rgba(0, 0, 0, 0.65)',
                fontWeight: selected ? 'bold' : 'normal',
            };
            const title = <span style={style}>{item.name}</span>;
            return (
                <AntdTreeNode key={item.id} value={item.id} title={title}>
                    {item.children ? this.renderTreeNodes(item.children) : undefined}
                </AntdTreeNode>
            );
        });
    };

    render(): React.ReactNode {
        const { options, flatData } = this.state;
        const { value, style, disabled } = this.props;
        let valueText = '';

        if (value && value.organizationId) {
            const selected = flatData.find((i: TreeNode) => value.organizationId === i.id);
            if (selected) {
                valueText =
                    selected.namePath && selected.namePath.length
                        ? selected.namePath.join('/')
                        : selected.name;
            }
        }
        let treeDefaultExpandedKeys: string[] = [];
        options.forEach((item) => {
            treeDefaultExpandedKeys.push(item.id);
        });
        return (
            <div style={style}>
                <TreeSelect
                    disabled={disabled}
                    showSearch
                    style={{ width: '100%' }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    placeholder={services.language.getText('selectPlease')}
                    allowClear
                    treeDefaultExpandedKeys={treeDefaultExpandedKeys}
                    onChange={this.onChange}
                    value={valueText}
                    onSearch={this.onSearch}
                    filterTreeNode={this.filterTreeNode}
                >
                    {this.renderTreeNodes(options)}
                </TreeSelect>
            </div>
        );
    }
}
