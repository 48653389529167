import React, { Component } from 'react';
import { map } from 'lodash';

import { TypesManager } from '@comall-backend-builder/core';

export class Info extends Component {
    render() {
        // fixme: any
        const { fields, entity } = this.props as any;
        const properties = entity.metainfo.properties;

        return fields ? (
            <ul>
                {map(properties, (property, name) => {
                    let { type, options } = property;
                    return (
                        <li key={name}>
                            {TypesManager.get(type).getDisplayComponent(fields[name], {
                                ...properties.displayConfig,
                                name: name,
                                options: options,
                            })}
                        </li>
                    );
                })}
            </ul>
        ) : null;
    }
}
