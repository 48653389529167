import React, { Component } from 'react';
import { Table, Button, message as AntMessage } from 'antd';
import { cloneDeep, remove, forEach, filter, map, find, isEqual } from 'lodash';
import { Member, SelectMemberValue } from './config-select-member';
import { language } from '@comall-backend-builder/core/lib/services';
import './index.less';
import { services } from '@comall-backend-builder/core';

interface MemberSelectedDisplayProps {
    value: SelectMemberValue;
    onChangeSelect: (data: Member[]) => void;
    onRemove: (data: SelectMemberValue) => void;
    disabled: boolean;
}
interface MemberSelectedDisplayState {
    selectedRows: Array<Member>;
    displayAll: boolean;
}

/**
 * 分站展示和内容编辑
 */
class MemberSelectedDisplay extends Component<
    MemberSelectedDisplayProps,
    MemberSelectedDisplayState
> {
    state: MemberSelectedDisplayState = {
        selectedRows: [],
        displayAll: false,
    };
    componentDidMount() {
        this.setState({ selectedRows: this.props.value?.list });
    }
    componentWillReceiveProps(nextProps: MemberSelectedDisplayProps) {
        if (!isEqual(nextProps.value, this.props.value)) {
            this.setState({ selectedRows: nextProps.value.list });
        }
    }

    getTableConfig = () => {
        const { disabled } = this.props;
        let config: any = {
            rowKey: 'id',
            columns: [
                {
                    title: language.getText('subsiteName'),
                    dataIndex: 'subsiteName',
                    key: 'subsiteName',
                    ellipsis: true,
                },
                {
                    title: '会员卡名称',
                    dataIndex: 'cardLevelName',
                    key: 'cardLevelName',
                },
                {
                    title: '状态',
                    dataIndex: 'status',
                    key: 'status',
                    render: (status: string) => (
                        <span
                            title={status === 'ENABLE' ? '启用' : '禁用'}
                            className="goods-field-name"
                        >
                            {status === 'ENABLE' ? '启用' : '禁用'}
                        </span>
                    ),
                },
                {
                    title: services.language.getText('common.tableAction'),
                    className: 'action-column',
                    key: 'operation',
                    width: 100,
                    render: (i: any, row: any) => {
                        return !disabled ? (
                            <Button type={'link'} onClick={this.onRemove(row)}>
                                {services.language.getText('common.delete')}
                            </Button>
                        ) : null;
                    },
                },
            ],
            footer: () => this.renderFooter(),
            style: {
                marginTop: 20,
            },
        };
        return config;
    };

    renderFooter = () => {
        const { disabled, value } = this.props;
        const { displayAll } = this.state;
        return (
            <div className="product-selected-footer">
                {value && value.list && value.list.length > 5 && (
                    <Button
                        className="table-footer-expand"
                        type="link"
                        onClick={this.handleDisplayChange}
                    >
                        {displayAll ? '隐藏门店' : '查看门店'}
                    </Button>
                )}
                {!disabled && (
                    <div className="setting-item">
                        <Button
                            className="setting-item-ok"
                            type="primary"
                            onClick={this.onRemoveAll}
                        >
                            {services.language.getText('batchDelete')}
                        </Button>
                    </div>
                )}
            </div>
        );
    };

    onRemoveAll = () => {
        const { value, onRemove } = this.props;
        const { selectedRows } = this.state;

        if (selectedRows) {
            const results = cloneDeep(value.list);
            let hasLeader = false;
            forEach(results, (subsite: any) => {
                const selectSubsite = find(selectedRows, (selectSubsite: any) => {
                    return selectSubsite.id === subsite.id;
                });
                if (selectSubsite && subsite.select) {
                    if (subsite.hasLeaders) {
                        hasLeader = true;
                    } else {
                        subsite.delete = true;
                    }
                }
            });
            remove(results, (subsite) => subsite.delete);
            if (hasLeader) {
                AntMessage.error(language.getText('subsiteHasLeader'));
            }
            value.list = results;
            onRemove(value);
        }
    };

    onRemove = (row: any) => {
        const { value, onRemove } = this.props;

        return () => {
            if (row.hasLeaders) {
                AntMessage.error(language.getText('subsiteHasLeader'));
            } else {
                const result = cloneDeep(value.list);
                remove(result, (item: any) => row.id === item.id);
                value.list = result;
                onRemove(value);
            }
        };
    };

    batchChangeValue = (field: string, value: any) => {};

    changeBatchInputValue = (field: string, value: any) => {};

    handleDisplayChange = () => {
        const { displayAll } = this.state;
        this.setState({
            displayAll: !displayAll,
        });
    };

    render() {
        const { value, onChangeSelect, disabled } = this.props;
        const { displayAll } = this.state;
        let tableConfig = this.getTableConfig();
        const list = value && value.list ? cloneDeep(value.list) : [];
        const tableProps = {
            ...tableConfig,
            dataSource: displayAll ? list : list.slice(0, 5),
        };
        if (!disabled) {
            tableProps.rowSelection = {
                selectedRowKeys: new Array<string>(),
                onChange: (
                    selectedRowKeys: Array<number> | Array<string>,
                    selectedRows: Array<Member>
                ) => {
                    this.setState({ selectedRows });
                    onChangeSelect(selectedRows);
                },
            };
        }

        if (list && !disabled) {
            const selectedRowKeys = map(
                filter(list, (subsite) => {
                    return subsite.select ? true : false;
                }),
                'id'
            );

            tableProps.rowSelection.selectedRowKeys = selectedRowKeys;
        }

        return <Table {...tableProps} pagination={false}></Table>;
    }
}

export { MemberSelectedDisplay };
