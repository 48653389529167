import React, { Component } from 'react';
import { InputNumber } from 'antd';
import { forEach } from 'lodash';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { services } from '@comall-backend-builder/core';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

type CitywideDeliveryFreightLadderValue = {
    /**
     * 阶梯数
     */
    ladderValue?: number;
    /**
     * 阶梯金额
     */
    ladderAmount?: number;
};

export interface PointRewardContentProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: CitywideDeliveryFreightLadderValue | null, name: string) => void;
    /**
     * 当前值
     */
    value: CitywideDeliveryFreightLadderValue;
    rules: any;
    rewardText: string;
    ladderAmountPrecision?: number;
    /**
     * 请求配置
     */
    reqConfig: ApiRequestConfig;
}
const { api } = services;
export class PointRewardContent extends Component<PointRewardContentProps, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            rewardText: '',
        };
    }
    componentDidMount() {
        const { rewardText } = this.props;
        if (rewardText) {
            this.setState({
                rewardText,
            });
        }
        setTimeout(this.loadData, 300);
    }
    loadData = () => {
        const { reqConfig } = this.props;
        if (!reqConfig) return;
        api.get({}, reqConfig).then((res: any) => {
            if (!!res) {
                this.setState({
                    rewardText: res.goldAlias,
                });
            }
        });
    };
    onLadderValueChange(ladderValue: any) {
        let { name, value, onChange } = this.props;
        if (!value) {
            value = {};
        }
        value.ladderValue = ladderValue;
        if (onChange) {
            onChange(value, name);
        }
    }

    onLadderAmountChange(ladderAmount: any) {
        let { name, value, onChange } = this.props;
        if (!value) {
            value = {};
        }
        value.ladderAmount = ladderAmount;
        if (onChange) {
            onChange(value, name);
        }
    }

    render() {
        const { value, rules, ladderAmountPrecision = 0 } = this.props;
        const { rewardText } = this.state;

        let ruleInfo = {};
        if (rules && rules.length) {
            forEach(rules, (item) => {
                Object.assign(ruleInfo, item);
            });
        }
        return (
            <div className="point-reward-content">
                <div className="content">
                    <span className="item-text">{language.getText('mm_1')}</span>
                    <div className="number-input">
                        <InputNumber
                            placeholder={services.language.getText('inputPlease')}
                            value={value && value.ladderValue}
                            min={0.01}
                            max={999999}
                            step={1}
                            precision={2}
                            style={{ width: '130px' }}
                            onChange={(value: any) => {
                                this.onLadderValueChange(value);
                            }}
                            {...ruleInfo}
                        ></InputNumber>
                    </div>
                    <span className="item-text">{language.getText('yuan')}，</span>
                    <span className="item-text">{language.getText('song')}</span>
                    <div className="number-input">
                        <InputNumber
                            placeholder={services.language.getText('inputPlease')}
                            value={value && value.ladderAmount}
                            min={ladderAmountPrecision ? 0.01 : 1}
                            max={999999}
                            step={1}
                            precision={ladderAmountPrecision}
                            style={{ width: '130px' }}
                            onChange={(value: any) => {
                                this.onLadderAmountChange(value);
                            }}
                            {...ruleInfo}
                        ></InputNumber>
                    </div>
                    <span className="item-text">
                        {rewardText ? rewardText : language.getText('point')}
                    </span>
                </div>
            </div>
        );
    }
}
