import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
export const SlotMachinesReportLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/slot_machine_activities';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/slot_machine_activities/${id}/record`;
        }
        return await api.get(data, config).then((res: any) => {
            return res;
        });
    },
};
