import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
export const MallActivityRefundOrderLoader: Loader = {
    get(data, config: ApiRequestConfig) {
        config.apiPath = '/admin/mall_activity_refunds';

        if (data && data.interfaceStatus === 'ALL') {
            delete data.interfaceStatus;
        }
        if (window.location.hash !== '#/mall-activity-refund-order') {
            data.orderType = 'MALL_ACTIVITY_NEW';
        }

        return api.get(data, config).then((res: any) => {
            res.result =
                res &&
                res.result.map((item: any) => {
                    item.canRefund = item.interfaceStatus === 'REFUND_FAIL';
                    return item;
                });
            return res;
        });
    },
};
