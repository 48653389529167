import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { ColorPickerPlus } from '../../../components';
export class StringColorMode extends modes.StringMode {
    getAvailableDisplayComponent(value: any, displayInfo: any): JSX.Element {
        return (
            <span title={value} {...displayInfo}>
                {value}
            </span>
        );
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <ColorPickerPlus {...displayInfo} />;
    }
}
