import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
export const config: Config = {
    entities: {
        MarketingScheduleManagementEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
            apiPath: '/loader/admin/marketing_schedule_management',
            properties: {
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                time: {
                    type: 'string',
                    displayName: '<<dqsj>>',
                },
                thirdId: {
                    type: 'string',
                    displayName: '<<thirdId>>',
                },
                subsite: {
                    type: 'string.options.select',
                    displayName: '<<otherSelectSubsite>>',
                    rules: [{ required: true }],
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
                name: {
                    type: 'string',
                    displayName: '<<yxdqmc>>',
                    rules: [{ required: true, whitespace: true }],
                    controlConfig: {
                        placeholder: '<<inputPlease>>',
                        style: {
                            width: 450,
                        },
                        maxLength: 40,
                    },
                },
                dateRange: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<dqsj>>',
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                        range: {
                            start: new Date(),
                        },
                    },
                },
                thirdActivityId: {
                    type: 'string',
                    displayName: '<<thirdActivityId>>',
                    controlConfig: {
                        placeholder: '<<inputPlease>>',
                        style: {
                            width: 450,
                        },
                        maxLength: 40,
                    },
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<yxdqmc>>',
                    controlConfig: {
                        placeholder: '<<inputPlease>>',
                        style: {
                            width: 260,
                        },
                        maxLength: 40,
                    },
                },
            },
        },
    },
    components: {
        MarketingScheduleManagementView: {
            component: 'Viewport',
            entity: 'MarketingScheduleManagementEntity',
        },
        MarketingScheduleManagementPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<cjdq>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/marketing-schedule-management/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'MarketingScheduleManagementTable' },
            ],
        },
        MarketingScheduleManagementTable: {
            component: 'DataTable',
            componentId: 'MarketingScheduleManagementTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'subsiteName',
                },
                {
                    property: 'time',
                },
                {
                    property: 'thirdId',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                items: [
                    {
                        text: '<<common.edit>>',
                        type: 'link',
                        path: '/marketing-schedule-management/edit/{{row.id}}',
                    },
                    {
                        type: 'delete',
                        text: '<<common.delete>>',
                        config: {
                            type: 'link',
                            tooltip: null,
                            title: '<<confirmDelete>>',
                            okText: '<<common.ok>>',
                            cancelText: '<<common.cancel>>',
                        },
                    },
                ],
            },
        },
        MarketingScheduleManagementAddPage: {
            component: 'Card',
            content: { component: 'MarketingScheduleManagementAddForm' },
        },
        MarketingScheduleManagementAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'MarketingScheduleManagementEntity',
            labelCol: 3,
            controlCol: 21,
            fields: [
                { property: 'subsite' },
                { property: 'name' },
                { property: 'dateRange' },
                { property: 'thirdActivityId' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: () => {
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        MarketingScheduleManagementEditPage: {
            component: 'Card',
            content: { component: 'MarketingScheduleManagementEditForm' },
        },
        MarketingScheduleManagementEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'MarketingScheduleManagementEntity',
            loaderType: 'get',
            labelCol: 3,
            controlCol: 21,
            fields: [
                { property: 'subsite' },
                { property: 'name' },
                { property: 'dateRange' },
                { property: 'thirdActivityId' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: () => {
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/marketing-schedule-management',
                    component: 'MarketingScheduleManagementView',
                    breadcrumbName: '<<yxdqgl>>',
                    privilege: {
                        path: 'operatorLog',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'MarketingScheduleManagementAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'MarketingScheduleManagementEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        { path: '/', component: 'MarketingScheduleManagementPage' },
                    ],
                },
            ],
        },
    ],
};
