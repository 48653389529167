import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { DeliveryLogisticsConfigsMerchants } from './delivery-logistics-configs-merchant';

export class DeliveryLogisticsConfigsMerchantsMode extends modes.ObjectMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <DeliveryLogisticsConfigsMerchants {...displayInfo} />;
    }
}
