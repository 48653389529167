import React, { PureComponent } from 'react';
import { Button, Modal, Upload, Icon, message, Modal as AntModal } from 'antd';
import { services } from '@comall-backend-builder/core';

import './index.less';
import 'antd/dist/antd.css';
import { isString } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

const { api } = services;
const { Dragger } = Upload;

interface Resource {
    fileId: number;
    fileUrl: string;
}

/**
 * 导入数据按钮
 */
export class MerchantBigCodesButton extends PureComponent<
    any,
    { fileList: any; showModal: boolean; statusMsg: string; resource: Resource }
> {
    state = {
        fileList: [],
        showModal: false,
        statusMsg: language.getText('uploadFileClickButton'),
        resource: {
            fileId: 0,
            fileUrl: '',
        },
    };

    toggleModal = () => {
        const { showModal } = this.state;
        this.setState({ showModal: !showModal });
    };

    onImport = () => {
        const { fileList } = this.state;
        const { props } = this.props;
        const apiRoot = `${ENV.AUTH_API_ROOT}/FILE`;
        const apiPath = props?.controlUploadApiPath
            ? props?.controlUploadApiPath
            : `/admin/merchant_big_codes/excel_import_template`;
        api.upload(
            { files: fileList[0] },
            { apiRoot, apiPath, fileName: 'files', progressCallBack: () => {} }
        )
            .then((res) => {
                const { path, id } = res;
                this.setState({
                    resource: {
                        fileId: id,
                        fileUrl: path,
                    },
                });

                let contentBefore = React.createElement(
                    'span',
                    null,
                    language.getText('fileUploading')
                );
                let contentAfter = React.createElement('span', null, language.getText('toView'));
                let link = React.createElement(
                    'a',
                    {
                        onClick: () => {
                            services.behaviorHandle({
                                route: '/report-download-center',
                            });
                            AntModal.destroyAll();
                        },
                    },
                    language.getText('dataExportAndImport')
                );
                let content = React.createElement('div', {}, [contentBefore, link, contentAfter]);
                AntModal.info({
                    content: content,
                });
                this.props.entity.search({});

                this.toggleModal();
                // message.success('上传成功');
            })
            .catch((err) => {
                if (err) {
                    message.error(err.response?.body?.err_msg || language.getText('uoloadFail'));
                }
            });
    };

    handleOk = () => {
        const { fileList } = this.state;
        if (fileList && fileList.length > 0) {
            this.onImport();
        } else {
            message.error(language.getText('pleaseUploadFile'));
            return;
        }
    };
    downloadTemplate = () => {
        services.api.download(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: '/admin/merchant_big_codes/excel_import_template',
            }
        );
    };

    render() {
        const { showModal, fileList, statusMsg } = this.state;
        const { props } = this.props;
        let importExportTypeText = props.triggerText;
        const uploadProps = {
            accept: '.xlsx,.xls,.csv',
            multiple: false,
            onRemove: (file: any) => {
                this.setState((state: any) => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                        resource: {
                            fileId: 0,
                            fileUrl: '',
                        },
                    };
                });
            },
            beforeUpload: (file: any) => {
                this.setState({
                    fileList: [file],
                });
                return false;
            },
            fileList,
        };
        return (
            <div className="merchant-big-codes-data-statistics">
                <Button
                    type="primary"
                    icon="plus"
                    className="btn-data-statistics"
                    onClick={this.toggleModal}
                >
                    {importExportTypeText}
                </Button>
                <Modal
                    onCancel={this.toggleModal}
                    visible={showModal}
                    title={language.getText('importBarCode')}
                    footer={[
                        <span className="merchant-big-codes-status-msg">{statusMsg}</span>,
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            {language.getText('importBarCode')}
                        </Button>,
                        <Button key="back" onClick={this.toggleModal}>
                            {language.getText('common.goBack')}
                        </Button>,
                    ]}
                >
                    <Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">{language.getText('selectFile')}</p>
                        <p className="ant-upload-hint">
                            {language.getText('supportExtension')}.xlsx、.xls、.csv
                        </p>
                    </Dragger>
                    <Button
                        onClick={this.downloadTemplate}
                        className="merchant-big-codes-button-template"
                        type="link"
                    >
                        {language.getText('clickDownloadTemplate')}
                    </Button>

                    <div className="merchant-big-codes-tips">
                        <p className="merchant-big-codes-tips-text">
                            {language.getText('importRules')}
                        </p>
                        {props.controlUploadRules &&
                            props.controlUploadRules.map((uploadRule: any) => {
                                if (isString(uploadRule)) {
                                    return (
                                        <p
                                            className="merchant-big-codes-tips-text"
                                            key={uploadRule}
                                        >
                                            {uploadRule}
                                        </p>
                                    );
                                }
                                return (
                                    <p
                                        className="merchant-big-codes-tips-text"
                                        style={{ color: uploadRule.color || '' }}
                                        key={uploadRule.text}
                                    >
                                        {uploadRule.text}
                                    </p>
                                );
                            })}
                    </div>
                </Modal>
            </div>
        );
    }
}
