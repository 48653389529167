import React, { Component } from 'react';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { Button } from 'antd';
import { language, navigation } from '@comall-backend-builder/core/lib/services';

export interface ButtonProps {
    row: any;
    entity: Entity;
    params: any;
}
export class subsiteAddFloorButton extends Component<ButtonProps> {
    goPage = () => {
        const { params } = this.props;
        navigation.goto(`/subsite/view-floor-add/${params.id}`);
    };
    render() {
        return (
            <Button
                type="primary"
                onClick={this.goPage}
                icon="plus"
                style={{ width: '110px', marginBottom: '24px' }}
            >
                {language.getText('addFloor')}
            </Button>
        );
    }
}
