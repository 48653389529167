import React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { CityAndAddressSelect } from './select-city-and-address';

export class SelectCityAndAddressMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <CityAndAddressSelect {...displayInfo} />;
    }
}
