import { isEmpty } from 'lodash';
import { services } from '@comall-backend-builder/core/';
import { subsites } from '../containers/page-design';
import { merchantId } from '../../../containers/design-merchant';

export const CouponPackagesLoader = {
    get: async function(data: any, config: any) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        config.apiPath = '/admin/coupon_package_rules';
        if (merchantId >= 0) {
            data.merchantIds = merchantId;
        }
        if (!isEmpty(subsites)) {
            data.subsiteIds = subsites
                .map((subsite: any) => {
                    return subsite.id;
                })
                .join(',');
        }
        data.executeStatus = 'NOT_FINISHED';
        return await services.api.get(data, config);
    },
};
