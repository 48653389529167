import React, { Component, createElement, Fragment } from 'react';
import { connect } from 'react-redux';
import { get, camelCase, forIn } from 'lodash';
import Cookies from 'js-cookie';
import { ComponentsManager, services, actions } from '@comall-backend-builder/core';
import { SessionsLoader } from '../../loaders/sessions-loader';
import { getUrlParam } from '../../services/utils';
import { initSupportAlipay, initSupportMemberMarking } from '../../../../services/tenant';
import { initTenantPrivileges } from '../../../../services/tenant-privilege';
const localStorage = services.localStorage;

const REDIRECT_URI = window.location.origin;
/**
 * 说明
 * 1、这个组件不做任何展示性东西
 * 2、这个组件主要作为一个首页检查登录code换token功能使用
 */
class customAuthentication extends Component<any, { inited: boolean }> {
    constructor(props: any) {
        super(props);
        this.state = { inited: false };
    }
    componentWillMount() {
        this.loadLogin();
    }
    inited = () => {
        this.setState({ inited: true });
        initSupportAlipay();
        initSupportMemberMarking();
        initTenantPrivileges();
    };
    loadLogin = () => {
        // 当三方跳转登录时，会携带cookie
        // 先判断是否三方跳转，如果是，则根据三方携带信息直接登录
        const authentificationInfo = getAuthentificationInfoCookie();
        if (authentificationInfo) {
            // console.log('authentificationInfo==>', authentificationInfo);
            this.props.login(authentificationInfo);
            this.inited();
            clearAuthentificationInfoCookie();
            return;
        }
        if (services.privilege.hasPrivilege()) {
            this.inited();
            return;
        }
        let code = getUrlParam(window.location.href, 'code');
        let timeStamp = new Date().getTime();
        const loginPath = `/oauth/authorize?response_type=code&client_id=${ENV.CLIENT_ID}&t=${timeStamp}`;
        if (code) {
            SessionsLoader.post(
                {
                    code: code,
                    grant_type: 'authorization_code',
                    redirect_uri: REDIRECT_URI,
                },
                {
                    apiPath: '/oauth/token',
                    apiRoot: ENV.AUTH_ROOT,
                }
            )
                .then((res: any) => {
                    this.props.login(res);
                    this.inited();
                })
                .catch((e: any) => {
                    if (e.status) {
                        window.location.href = `${ENV.AUTH_ROOT}${loginPath}&redirect_uri=${REDIRECT_URI}`;
                    }
                });
        } else {
            window.location.href = `${ENV.AUTH_ROOT}${loginPath}&redirect_uri=${REDIRECT_URI}`;
        }
    };
    render() {
        const {
            content: { component },
            accessToken,
        } = this.props;
        const { inited } = this.state;
        // console.log('render==>', inited, accessToken);
        if (inited && accessToken) {
            // console.log('ComponentsManager-component:', component);
            return createElement(ComponentsManager.get(component), this.props);
        } else {
            return <Fragment />;
        }
    }
}

// 获取三方跳转后携带的cookie
function getAuthentificationInfoCookie() {
    const authentificationInfo = Cookies.get('authentification_info');
    if (authentificationInfo) {
        const user: any = {};
        forIn(JSON.parse(authentificationInfo), (value, key) => {
            user[camelCase(key)] = value;
        });
        return user;
    }
    return null;
}
/**
 * 清除三方 cookie: authentification_info
 */
function clearAuthentificationInfoCookie() {
    const authentificationInfo = Cookies.get('authentification_info');
    const domain = `.${window.location.hostname
        .split('.')
        .slice(-2)
        .join('.')}`;
    if (authentificationInfo) {
        Cookies.remove('authentification_info', { path: '/', domain });
    }
}

//写入cookie

let HasAccessTokenCookie = false;

/**
 * 把token写入cookie，httl从中获取做权限标识
 * @param token
 */
function recordTokenToCookie(token: string) {
    const authentificationInfo = getAuthentificationInfoCookie();
    Cookies.remove('token');
    if (authentificationInfo) {
        // console.log('recordTokenToCookie====true=>', authentificationInfo);
        Cookies.set('token', authentificationInfo['accessToken']);
    } else {
        // console.log('recordTokenToCookie====false=>', token);
        Cookies.set('token', token);
    }
}

export const CustomAuthentication = connect(
    (state: any) => {
        const accessToken = get(state, 'user.accessToken', null);
        if (!HasAccessTokenCookie && accessToken) {
            recordTokenToCookie(accessToken);
            HasAccessTokenCookie = true;
        }
        return { accessToken: accessToken, user: get(state, 'user', {}) };
    },
    (dispatch, props: any) => {
        return {
            login: (user: any) => {
                const {
                    entities: { privileges },
                } = props;
                dispatch(actions.loginSuccessAction(user));
                dispatch(actions.getPrivilegeAction(privileges, user));
                localStorage.set(
                    'expiresTime',
                    new Date().getTime() + get(user, 'expiresIn', 0) * 1000
                );
            },
        };
    }
)(customAuthentication);
