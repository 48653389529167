import React, { PureComponent } from 'react';
import { message as AntMessage, Modal, Input, Button, message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { isObject, isArray, forEach, find, debounce, isFunction, remove } from 'lodash';
import classNames from 'classnames';
import { BigCodesPrint } from '../big-codes-print';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
import { ChannelType } from '../../types/mode/array/array-channel-checkbox';

const api = services.api;

interface batchAppendDataVo {
    id: number;
    /**
     * 微信短链接
     */
    wechatUrlLink: string;
    /**
     * 支付宝短链接
     */
    alipayUrlLink: string;
    /**
     * 推广码链接
     */
    codeFiles: Array<{
        codeFileType: string;
        fileId: number;
        fileName: string;
        fileUrl: string;
    }>;
    pageUrl: string;
    subsite: {
        id: number;
        name: string;
    };
}

enum CodeFileTypeEnum {
    /**
     * 聚合码
     */
    AGGREGATION_CODE = 'AGGREGATION_CODE',
    /**
     * 微信小程序码
     */
    WECHAT_MINI_PROGRAM_CODE = 'WECHAT_MINI_PROGRAM_CODE',
    /**
     * 支付宝小程序码
     */
    ALIPAY_MINI_PROGRAM_CODE = 'ALIPAY_MINI_PROGRAM_CODE',
}

interface PageNewPreviewCodeButtonState {
    visibleModal: boolean;
    currentCodeFileType: CodeFileTypeEnum;
    batchAppendData: Array<batchAppendDataVo>;
    codeFileTypes: Array<{
        id: CodeFileTypeEnum;
        name: string;
    }>;
    wechatH5Url: string;
    alipayH5Url: string;
    fileUrl: string;
    currentBargainTag: string;
    homeLogoFileUrl: string;
}

const path: any = {
    platformPage: {
        id: 'platform',
        name: '平台首页',
        basePath: 'pages/platform/index',
    },
    storePage: {
        id: 'subsite',
        name: '门店首页',
        basePath: 'pages/home/index',
        params: ['id', 'name'],
    },
    merchantPage: {
        id: 'newMerchant',
        name: '专柜首页',
        basePath: 'subpackages/merchant/index',
        params: ['id', 'merchantId', 'storeId', 'name'],
    },
    customPage: {
        id: 'newTopic',
        name: '专题活动',
        basePath: 'subpackages/topic/index',
        params: ['id', 'name'],
    },
    memberCenterPage: {
        id: 'member',
        name: '会员中心',
        basePath: 'pages/member-center/index',
    },
    creditEshopPage: {
        id: 'member.creditEshop',
        name: '积分商城',
        basePath: 'subpackages/credit-shop/index',
        zipName: '积分商城列表',
    },
    creditShopDetailPage: {
        id: 'credit.creditShopDetail',
        name: '积分商城',
        basePath: 'subpackages/credit-shop/pages/credit-shop-details/index',
        params: ['id', 'name', 'saleRuleId', 'subsiteId'],
    },
    mallActivityListPage: {
        id: 'member.mallActivity',
        name: '商场活动列表',
        basePath: 'subpackages/mall-activity/index',
        zipName: '商场活动列表',
    },
    mallActivityPage: {
        id: 'mallActivity',
        name: '商场活动',
        basePath: 'sub-packages/mall-activity/pages/activity-detail/index',
        params: ['id', 'name'],
    },
    parkingPage: {
        id: 'member.parking',
        name: '停车缴费',
        basePath: 'subpackages/parking-fee/index',
    },
    fixedPricePage: {
        id: 'marketing.fixedPrice',
        name: '打包一口价',
        basePath: '/subpackages/search-result/index',
        params: ['id', 'name'],
    },
    connectWifi: {
        id: 'service.connectWifi',
        name: 'Wi-Fi一键连',
        basePath: 'subpackages/market/connect-wifi/pages/index',
    },
    bargainPage: {
        id: 'marketing.bargainActivity',
        name: '砍价活动',
        basePath: 'subpackages/market/bargain/pages/detail/index',
        params: ['id', 'name'],
    },
    cashierCounter: {
        id: 'cashierCounter',
        name: '买单收银',
        basePath: 'subpackages/my-checkout/pages/cashier-counter/index',
        params: ['subsiteId', 'merchantId', 'id'],
    },
    paymentRewardActivity: {
        id: 'marketing.paymentRewardActivity',
        name: '支付有礼',
        basePath: 'subpackages/market/payment-reward/pages/detail/index?type=PAY_AWARD',
    },
    couponTransfer: {
        id: 'service.couponTransfer',
        name: '赠送优惠券',
        basePath: 'subpackages/market/connect-wifi/pages/index',
    },
    anniversary: {
        id: 'anniversary',
        name: '周年庆报告',
        basePath: 'subpackages/market/connect-wifi/pages/index',
    },
    gamePlatform: {
        id: 'gamePlatform',
        name: '游戏平台',
        basePath: 'subpackages/market/connect-wifi/pages/index',
    },
};

/**
 * 新c可视化页面预览
 */
export class PageNewPreviewCodeButton extends PureComponent<any, PageNewPreviewCodeButtonState> {
    static defaultProps = {
        extraParams: [],
    };
    constructor(props: any) {
        super(props);
        this.state = {
            visibleModal: false,
            currentCodeFileType: CodeFileTypeEnum.AGGREGATION_CODE,
            batchAppendData: [],
            codeFileTypes: [],
            wechatH5Url: '',
            alipayH5Url: '',
            fileUrl: '',
            homeLogoFileUrl: '',
            currentBargainTag: language.getText('qrCode'),
        };
        this.setReceiptRef = this.setReceiptRef.bind(this);
    }
    setRef = React.createRef<any>();
    pickOrderRef = null;
    setTab = (id: CodeFileTypeEnum) => {
        this.setState({
            currentCodeFileType: id,
            currentBargainTag: language.getText('qrCode'),
        });
    };
    setReceiptRef(ref: any) {
        this.pickOrderRef = ref;
    }

    isNewStyle = () => {
        const { codePageType } = this.props;
        return codePageType && ['bargainPage', 'cashierCounter'].includes(codePageType);
    };

    /**
     * 获取价格处理小数点前后问题
     */
    getFormatProductPrice = () => {
        const {
            salesPrice,
            product: { price = '' },
        } = this.props.row;
        let prices: any = String(price);
        let salesPrices: any = String(salesPrice);
        /**
         * 如果价格有小数点
         */
        if (prices.indexOf('.') !== -1) {
            prices = [...prices.split('.')];
        } else {
            prices = [prices.split('.')[0], '00'];
        }
        if (salesPrices.indexOf('.') !== -1) {
            salesPrices = [...salesPrices.split('.')];
        } else {
            salesPrices = [salesPrices.split('.')[0], '00'];
        }
        return { prices, salesPrices };
    };

    getTextByWidth = (ctx: any, name: string, maxWidth: number) => {
        let lineWidth = 0;
        let res = name;
        for (let index = 0; index < name.length; index++) {
            lineWidth += ctx.measureText(name[index]).width;
            if (lineWidth > maxWidth) {
                res = `${name.substring(0, index)}...`;
                break;
            }
        }
        return res;
    };
    /**
     * title换行
     */
    wordsWrap = (
        ctx: any,
        name: string,
        maxWidth: number,
        startX: number,
        startY: number,
        wordsHight: number
    ) => {
        let line = 1;
        let lineWidth = 0;
        let lastSubStrIndex = 0;
        let currStartY = startY;
        for (let index = 0; index < name.length; index++) {
            lineWidth += ctx.measureText(name[index]).width;
            if (lineWidth > maxWidth) {
                // 标题最多两行
                if (line < 2) {
                    ctx.fillText(name.substring(lastSubStrIndex, index), startX, currStartY);
                    currStartY += wordsHight;
                    line += 1;
                    lineWidth = 0;
                    lastSubStrIndex = index;
                } else {
                    // 超出展示...
                    ctx.fillText(
                        `${name.substring(lastSubStrIndex, index - 2)}...`,
                        startX,
                        currStartY
                    );
                    break;
                }
            }
            if (index === name.length - 1) {
                ctx.fillText(name.substring(lastSubStrIndex, index + 1), startX, currStartY);
            }
        }
    };
    loadCanvas = async () => {
        const bg = require('./images/bg.png');
        const {
            product: { img = '', name = '' },
            subsites,
            merchant,
        } = this.props.row;
        const { homeLogoFileUrl, fileUrl } = this.state;
        const imageUrls = [bg, homeLogoFileUrl, img, fileUrl];
        const canvas = this.setRef.current;
        if (canvas && imageUrls) {
            var ctx = canvas?.getContext('2d');
            if (ctx) {
                const images: any[] = [];
                let loadedCount = 0;

                const checkAllImagesLoaded = async () => {
                    loadedCount++;
                    if (loadedCount === imageUrls.length) {
                        // 所有图片加载完成后进行绘制和保存操作
                        for (let i = 0; i < images.length; i++) {
                            const image = images[i];
                            //背景
                            if (i === 0) {
                                ctx.drawImage(image, 0, 0, 300, 500);
                                //背景颜色
                                // ctx.rect(10, 100, 280, 390);
                                // ctx.fillStyle = '#fff';
                                // ctx.fill();
                                const x = 10; // 矩形起始点 x 坐标
                                const y = 100; // 矩形起始点 y 坐标
                                const width = 280; // 矩形宽度
                                const height = 390; // 矩形高度
                                const cornerRadius = 10; // 圆角半径

                                ctx.fillStyle = '#ffffff'; // 设置填充颜色为白色
                                ctx.beginPath();
                                ctx.moveTo(x + cornerRadius, y);
                                ctx.lineTo(x + width - cornerRadius, y);
                                ctx.arcTo(x + width, y, x + width, y + cornerRadius, cornerRadius);
                                ctx.lineTo(x + width, y + height - cornerRadius);
                                ctx.arcTo(
                                    x + width,
                                    y + height,
                                    x + width - cornerRadius,
                                    y + height,
                                    cornerRadius
                                );
                                ctx.lineTo(x + cornerRadius, y + height);
                                ctx.arcTo(
                                    x,
                                    y + height,
                                    x,
                                    y + height - cornerRadius,
                                    cornerRadius
                                );
                                ctx.lineTo(x, y + cornerRadius);
                                ctx.arcTo(x, y, x + cornerRadius, y, cornerRadius);
                                ctx.closePath();
                                ctx.fill();
                            } else if (i === 1) {
                                //logo
                                ctx.drawImage(image, 100, 15, 106, 29);
                            } else if (i === 2) {
                                //商品图
                                ctx.drawImage(image, 10, 100, 280, 260);
                            } else if (i === 3) {
                                //二维码
                                ctx.drawImage(image, 180, 380, 100, 100);
                            }
                        }
                        if (subsites.length) {
                            // 门店文案
                            const x = canvas.width / 2; // 画布的中心点 x 坐标
                            const subTxt = subsites[0] ? subsites[0].name : '';
                            const merTxt = '-' + merchant.name || '';
                            const preText = this.getTextByWidth(ctx, subTxt + merTxt, 210);
                            ctx.font = '14px PingFangSC-Medium, PingFang SC';
                            ctx.fillStyle = '#fff';
                            ctx.textAlign = 'center'; // 设置文字水平居中对齐
                            ctx.fillText(preText, x, 66);
                            ctx.textAlign = 'left';
                        }
                        // 推荐文案
                        ctx.font = 'normal 11px PingFangSC-Medium, PingFang SC';
                        ctx.fillStyle = '#fff';
                        ctx.fillText(language.getText('recommendGoodItems'), 60, 85);

                        // 价格文案
                        ctx.font = '14px PingFangSC-Medium, PingFang SC';
                        ctx.fillStyle = 'red';
                        ctx.fillText(language.getText('bargainingBenefits'), 25, 390);

                        const priceNameWidth = await ctx.measureText(
                            language.getText('bargainingBenefits')
                        ).width;
                        // 价格¥
                        ctx.font = '12px PingFangSC-Medium, PingFang SC';
                        ctx.fillStyle = 'red';
                        ctx.fillText(
                            services.language.getText('monetaryUnit'),
                            30 + priceNameWidth,
                            390
                        );
                        const {
                            prices: [integer, decimal],
                        } = this.getFormatProductPrice();
                        // 主价格
                        ctx.font = '20px PingFangSC-Medium, PingFang SC';
                        ctx.fillStyle = 'red';
                        ctx.fillText(integer, 42 + priceNameWidth, 390);

                        const { width: decimallOffset } = await ctx.measureText(integer);
                        // 价格小数点
                        ctx.font = '12px PingFangSC-Medium, PingFang SC';
                        ctx.fillStyle = 'red';
                        ctx.fillText(`.${decimal}`, 44 + decimallOffset + priceNameWidth, 390);
                        // 划线价格2

                        const {
                            salesPrices: [salesinteger, salesdecimal],
                        } = this.getFormatProductPrice();
                        ctx.font = '11px PingFangSC-Medium, PingFang SC';
                        ctx.fillStyle = '#999';
                        ctx.fillText(
                            `${services.language.getText(
                                'monetaryUnit'
                            )} ${salesinteger}.${salesdecimal}`,
                            25,
                            410
                        );
                        //划线
                        const lineWidth = await ctx.measureText(
                            `${services.language.getText(
                                'monetaryUnit'
                            )} ${salesinteger}.${salesdecimal}`
                        ).width;
                        ctx.beginPath();
                        ctx.fillStyle = '#999';
                        ctx.moveTo(25, 406);
                        ctx.lineTo(25 + lineWidth, 406);
                        ctx.closePath();
                        ctx.stroke();

                        // 名称
                        ctx.font = '14px PingFangSC-Medium, PingFang SC';
                        ctx.fillStyle = '#000';
                        this.wordsWrap(ctx, String(name), 150, 25, 440, 20);

                        // 保存图像-如果是网络图片，存在跨域问题不能导出，需要后端给base64图片
                        // const imageData = canvas.toDataURL('image/png');
                        // const link = document.createElement('a');
                        // link.href = imageData;
                        // link.download = 'canvas_image.png'; // 指定保存的文件名
                        // link.click();
                    }
                };

                for (let i = 0; i < imageUrls.length; i++) {
                    const image = new Image();
                    image.src = imageUrls[i];

                    image.onload = () => {
                        images[i] = image;
                        checkAllImagesLoaded();
                    };
                }
            }
        }
    };
    setBargainTag = (tag: string) => {
        this.setState({
            currentBargainTag: tag,
        });
        if (tag === language.getText('poster')) {
            this.loadCanvas();
        }
    };

    getH5Url = async () => {
        const { codeTypes } = this.props;
        if (codeTypes.indexOf('h5') > -1) {
            const { previewMode } = this.props.row;
            if (previewMode === 'OPEN_MINI_PROGRAM') {
                //微信h5
                const { subsites } = this.props.row;
                const wechatH5UrlArray: Array<string> = [];
                for (let index = 0; index < subsites.length; index++) {
                    const url = this.formatUrls('OPEN_MINI_PROGRAM', subsites[index]);
                    const res = await api.post(
                        {
                            link_url: encodeURI(url),
                        },
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-SHORTLINK`,
                            apiPath: '/admin/short_link/CAE',
                        }
                    );
                    if (res) {
                        if (subsites.length === 1) {
                            wechatH5UrlArray.push(res + '');
                        } else {
                            wechatH5UrlArray.push(subsites[index].name + ':' + res);
                        }
                    }
                }
                this.setState({
                    wechatH5Url: wechatH5UrlArray.join(','),
                });
                //支付宝h5
                const alipayH5UrlArray: Array<string> = [];
                for (let index = 0; index < subsites.length; index++) {
                    const url = this.formatUrls('OPEN_ALIPAY_MINI_PROGRAM', subsites[index]);
                    const res = await api.post(
                        {
                            link_url: encodeURI(url),
                        },
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-SHORTLINK`,
                            apiPath: '/admin/short_link/CAE',
                        }
                    );
                    if (res) {
                        if (subsites.length === 1) {
                            alipayH5UrlArray.push(res + '');
                        } else {
                            alipayH5UrlArray.push(subsites[index].name + ':' + res);
                        }
                    }
                }
                this.setState({
                    alipayH5Url: alipayH5UrlArray.join(','),
                });
            }
        }
    };

    onCopy = (url: string) => {
        let input = document.createElement('textarea');
        let urlArray = url.split(',');
        const urls: string =
            urlArray && urlArray.length > 1
                ? urlArray
                      .map((u: string) => {
                          return `${u}\r\n`;
                      })
                      .join('')
                : url;
        input.value = urls;
        document.body.appendChild(input);
        input.select();
        if (document.execCommand('Copy')) {
            AntMessage.success(services.language.getText('copySuccess'));
        } else {
            AntMessage.success(services.language.getText('copyError'));
        }
        document.body.removeChild(input);
    };
    formatUrls = (previewMode: string, subsite: any) => {
        const { extraParams } = this.props;
        const { type, id, lastModifyTime, h5Domain, merchantId, name } = this.props.row;
        const time = (lastModifyTime + '').replace(' ', '');
        const path: any = {
            platformPage: '/#/pages/platform/index',
            storePage: '/#/pages/home/index',
            merchantPage: '/#/subpackages/merchant/index',
            customPage: '/#/subpackages/topic/index',
            memberCenterPage: '/#/pages/member-center/index',
        };
        let url =
            'https://' +
            h5Domain +
            path[type] +
            `?id=${id}&name=${name}&previewMode=${previewMode}&rand=${time}`;
        if (type === 'merchantPage') {
            url = url + `&merchantId=${merchantId}`;
        }
        url = url + `&subsites=${JSON.stringify([subsite])}`;
        if (extraParams.length > 0) {
            extraParams.forEach((item: string) => {
                const param = this.props.row[item];
                if (param) {
                    url = url + `&${item}=${this.formatParams(param)}`;
                }
            });
        }
        return url;
    };
    formatParams = (data: any) => {
        if (isObject(data) || isArray(data)) {
            return JSON.stringify(data);
        } else {
            return data;
        }
    };
    base64ToBlob = (code: string) => {
        let parts = code.split(';base64,');
        let contentType = parts[0].split(':')[1];
        let raw = window.atob(parts[1]);
        let rawLength = raw.length;
        let uInt8Array = new Uint8Array(rawLength);

        for (let i = 0; i < rawLength; ++i) {
            uInt8Array[i] = raw.charCodeAt(i);
        }
        return new Blob([uInt8Array], { type: contentType });
    };

    downloadFile = (fileName: string, content: string) => {
        let aLink = document.createElement('a');
        let blob = this.base64ToBlob(content);
        let evt = document.createEvent('HTMLEvents');
        //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
        evt.initEvent('click', true, true);
        aLink.download = fileName;
        aLink.href = URL.createObjectURL(blob);
        aLink.click();
    };

    downPng = (fileId: number, fileName: string) => {
        message.loading(language.getText('downLoading'));
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
                apiPath: `/admin/download/files/${fileId}/base64`,
            }
        )
            .then((res: any) => {
                this.downloadFile(fileName, 'data:image/jpg;base64,' + res.base64);
            })
            .finally(() => {
                message.destroy();
            });
    };

    isDownZipSubmit = false;
    downZip = debounce(async () => {
        const { row, codePageType } = this.props;
        //列表项上按钮取type,列表上方按钮取codePageType
        if (!row?.type && !codePageType) {
            return;
        }
        const target = new Target({ ...this.props, getSubsitesArray: this.getSubsitesArray });
        const { batchAppendData, currentCodeFileType } = this.state;
        if (!batchAppendData || batchAppendData.length === 0) {
            return;
        }
        const {
            config: { zipName },
            linkName,
        } = target;
        const allTypeCodeFile: any[] = [];
        forEach(batchAppendData, (batchAppendDataVo) => {
            if (batchAppendDataVo.codeFiles && batchAppendDataVo.codeFiles.length > 0) {
                forEach(batchAppendDataVo.codeFiles, (codeFile) => {
                    if (codeFile.codeFileType === currentCodeFileType) {
                        allTypeCodeFile.push({
                            id: codeFile.fileId,
                            name: codeFile.fileName,
                            url: codeFile.fileUrl,
                        });
                    }
                });
            }
        });
        if (this.isDownZipSubmit) {
            return false;
        }
        this.isDownZipSubmit = true;
        message.loading(language.getText('downLoading'));
        api.post(
            {
                files: allTypeCodeFile,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
                apiPath: '/admin/download/zip',
            }
        )
            .then((res: any) => {
                const name = zipName || linkName;
                this.downloadFile(`${name}.zip`, 'data:application/zip;base64,' + res.base64);
            })
            .finally(() => {
                this.isDownZipSubmit = false;
                message.destroy();
            });
    }, 200);

    renderWrap = () => {
        const { currentCodeFileType, codeFileTypes } = this.state;

        return (
            <div className="new-preview-content-code-left">
                <div className="left-tab-list">
                    {codeFileTypes.map((type) => {
                        return (
                            <div
                                className={classNames({
                                    'left-tab': true,
                                    'left-tab-current': type.id === currentCodeFileType,
                                })}
                                onClick={this.setTab.bind(this, type.id)}
                            >
                                {type.name}
                            </div>
                        );
                    })}
                </div>
                {this.isNewStyle() && this.renderBargainCodeWrap()}
                {this.renderLinkWrap()}
                {!this.isNewStyle() && this.renderCodeWrap()}
            </div>
        );
    };
    renderBargainCodeWrap = () => {
        const downUrl = require('./images/down.png');
        const {
            currentCodeFileType,
            batchAppendData,
            codeFileTypes,
            currentBargainTag,
        } = this.state;
        const { codePageType } = this.props;
        if (!batchAppendData || batchAppendData.length === 0) {
            return;
        }
        const buttonName = find(codeFileTypes, { id: currentCodeFileType });

        const currentBatchAppendData = batchAppendData[0];
        if (!currentBatchAppendData.codeFiles || currentBatchAppendData.codeFiles.length === 0) {
            return null;
        }
        const codeFile = find(currentBatchAppendData.codeFiles, {
            codeFileType: currentCodeFileType,
        });
        if (!codeFile) {
            return null;
        }
        this.setState({
            fileUrl: codeFile.fileUrl,
        });
        const tagList =
            codePageType === 'cashierCounter'
                ? [language.getText('qrCode')]
                : [language.getText('qrCode'), language.getText('poster')];
        return (
            <div className="code-wrap" style={{ minWidth: '325px', overflow: 'hidden' }}>
                <div className="code-tab-list">
                    {tagList.map((tag) => {
                        return (
                            <div
                                className={classNames({
                                    'code-tab-item': true,
                                    'code-tab-item-current': tag === currentBargainTag,
                                })}
                                onClick={this.setBargainTag.bind(this, tag)}
                            >
                                {tag}
                            </div>
                        );
                    })}
                </div>
                <div
                    className={'code-image-wraps'}
                    style={{
                        height:
                            language.getText('qrCode') === currentBargainTag ? '300px' : '500px',
                    }}
                >
                    <div
                        className={classNames({
                            'code-image': true,
                            'code-image-block': language.getText('qrCode') === currentBargainTag,
                        })}
                    >
                        <img alt="" width={280} height={280} src={codeFile.fileUrl} />
                    </div>
                    <div
                        className={classNames({
                            'code-image': true,
                            'code-image-block': language.getText('poster') === currentBargainTag,
                        })}
                    >
                        <canvas ref={this.setRef} className="canvas" width="300" height="500" />
                    </div>
                </div>
                {currentBargainTag === language.getText('poster') ? (
                    <div className="button-wrap">
                        <div className="span-text-button">{language.getText('clickSavePic')}</div>
                    </div>
                ) : (
                    <div className="button-wrap">
                        <div>
                            <img alt="" width={16} height={16} src={downUrl} />
                        </div>
                        <div
                            className="span-link-button"
                            onClick={this.downPng.bind(this, codeFile.fileId, codeFile.fileName)}
                        >
                            {language.getText('downLoad')}
                            {buttonName && buttonName.name}
                        </div>
                        {codePageType === 'cashierCounter' ? (
                            <div
                                className="span-link-button"
                                style={{ marginLeft: 10 }}
                                onClick={this.onPrint}
                            >
                                {language.getText('printQrcode')}
                            </div>
                        ) : null}
                    </div>
                )}
            </div>
        );
    };

    onPrint = () => {
        if (this.pickOrderRef) {
            (this.pickOrderRef as any).onPrint();
        }
    };
    renderCodeWrap = () => {
        const imageUrl = require('./images/zip.png');
        const downUrl = require('./images/down.png');
        const { currentCodeFileType, batchAppendData, codeFileTypes } = this.state;
        if (!batchAppendData || batchAppendData.length === 0) {
            return;
        }
        const buttonName = find(codeFileTypes, { id: currentCodeFileType });
        if (batchAppendData.length > 1) {
            return (
                <div className="code-wrap">
                    <div className="code-image-wrap">
                        <img alt="" width={100} height={100} src={imageUrl} />
                    </div>
                    <div className="button-wrap" onClick={this.downZip}>
                        <div>
                            <img alt="" width={16} height={16} src={downUrl} />
                        </div>
                        <div className="span-link-button">
                            {language.getText('downLoad')}
                            {buttonName && buttonName.name}
                        </div>
                    </div>
                </div>
            );
        }
        const currentBatchAppendData = batchAppendData[0];
        if (!currentBatchAppendData.codeFiles || currentBatchAppendData.codeFiles.length === 0) {
            return null;
        }
        const codeFile = find(currentBatchAppendData.codeFiles, {
            codeFileType: currentCodeFileType,
        });
        if (!codeFile) {
            return null;
        }
        return (
            <div className="code-wrap">
                <div className="code-image-wrap">
                    <img alt="" width={100} height={100} src={codeFile.fileUrl} />
                </div>
                <div
                    className="button-wrap"
                    onClick={this.downPng.bind(this, codeFile.fileId, codeFile.fileName)}
                >
                    <div>
                        <img alt="" width={16} height={16} src={downUrl} />
                    </div>
                    <div className="span-link-button">
                        {language.getText('downLoad')}
                        {buttonName && buttonName.name}
                    </div>
                </div>
            </div>
        );
    };

    renderLinkWrap = () => {
        const { batchAppendData, currentCodeFileType } = this.state;
        const { hideAlipay } = this.props;
        const links: any = [];
        if (!batchAppendData || batchAppendData.length === 0) {
            return null;
        }
        const miniUrlArray: String[] = [];
        const wechatUrlLinkArray: string[] = [];
        const alipayUrlLinkArray: string[] = [];
        const length = batchAppendData.length;
        forEach(batchAppendData, (batchAppendDataVo) => {
            //微信小程序码
            const miniUrl = `/subpackages/miniprogram-code/index?mid=${batchAppendDataVo.id}`;
            miniUrlArray.push(
                length === 1 ? miniUrl : batchAppendDataVo.subsite.name + ':' + miniUrl
            );
            //微信短链
            if (batchAppendDataVo.wechatUrlLink) {
                wechatUrlLinkArray.push(
                    length === 1
                        ? batchAppendDataVo.wechatUrlLink
                        : batchAppendDataVo.subsite.name + ':' + batchAppendDataVo.wechatUrlLink
                );
            }
            if (!hideAlipay) {
                //支付宝短链接
                if (batchAppendDataVo.alipayUrlLink) {
                    alipayUrlLinkArray.push(
                        length === 1
                            ? batchAppendDataVo.alipayUrlLink
                            : batchAppendDataVo.subsite.name + ':' + batchAppendDataVo.alipayUrlLink
                    );
                }
            }
        });
        links.push({
            title: language.getText('miniProgramPath'),
            url: miniUrlArray.join(','),
        });
        if (
            currentCodeFileType === CodeFileTypeEnum.AGGREGATION_CODE ||
            currentCodeFileType === CodeFileTypeEnum.WECHAT_MINI_PROGRAM_CODE
        ) {
            links.push({
                title: language.getText('wxShortChain'),
                url: wechatUrlLinkArray.join(','),
            });
        }
        if (
            (currentCodeFileType === CodeFileTypeEnum.AGGREGATION_CODE ||
                currentCodeFileType === CodeFileTypeEnum.ALIPAY_MINI_PROGRAM_CODE) &&
            alipayUrlLinkArray.length > 0
        ) {
            links.push({
                title: language.getText('aliShortChain'),
                url: alipayUrlLinkArray.join(','),
            });
        }
        return (
            <div className="new-preview-content-code-right">
                {links.map((link: any) => {
                    return (
                        <div className="links-wrap">
                            <div className="link-name">{link.title}</div>
                            <div className="link-wrap">
                                <Input className="input" value={link.url} disabled={true} />
                                <Button
                                    type={'primary'}
                                    onClick={this.onCopy.bind(this, link.url)}
                                    style={{
                                        width: '62px',
                                        height: '32px',
                                        background: '#4C91FF',
                                        borderRadius: '0px 2px 2px 0px',
                                    }}
                                >
                                    {language.getText('copy')}
                                </Button>
                            </div>
                        </div>
                    );
                })}
                {this.renderH5()}
            </div>
        );
    };

    renderH5 = () => {
        const { wechatH5Url, alipayH5Url, currentCodeFileType } = this.state;
        return (
            <div>
                {wechatH5Url &&
                    (currentCodeFileType === CodeFileTypeEnum.AGGREGATION_CODE ||
                        currentCodeFileType === CodeFileTypeEnum.WECHAT_MINI_PROGRAM_CODE) && (
                        <div className="links-wrap">
                            <div className="link-name">{language.getText('advertisingH5ToWx')}</div>
                            <div className="link-wrap">
                                <Input className="input" value={wechatH5Url} disabled={true} />
                                <Button
                                    type={'primary'}
                                    onClick={this.onCopy.bind(this, wechatH5Url)}
                                    style={{
                                        width: '62px',
                                        height: '32px',
                                        background: '#4C91FF',
                                        borderRadius: '0px 2px 2px 0px',
                                    }}
                                >
                                    {language.getText('copy')}
                                </Button>
                            </div>
                        </div>
                    )}
                {alipayH5Url &&
                    (currentCodeFileType === CodeFileTypeEnum.AGGREGATION_CODE ||
                        currentCodeFileType === CodeFileTypeEnum.ALIPAY_MINI_PROGRAM_CODE) && (
                        <div className="links-wrap">
                            <div className="link-name">
                                {language.getText('advertisingH5ToAli')}
                            </div>
                            <div className="link-wrap">
                                <Input className="input" value={alipayH5Url} disabled={true} />
                                <Button
                                    type={'primary'}
                                    onClick={this.onCopy.bind(this, alipayH5Url)}
                                    style={{
                                        width: '62px',
                                        height: '32px',
                                        background: '#4C91FF',
                                        borderRadius: '0px 2px 2px 0px',
                                    }}
                                >
                                    {language.getText('copy')}
                                </Button>
                            </div>
                        </div>
                    )}
            </div>
        );
    };
    getSubsitesArray = () => {
        let subsitesArray: any[] = [];
        const { subsites, subSites, subsiteId, subsiteName, subsite, subsiteInfo } = this.props.row;
        if (subsites && subsites.length > 0) {
            subsitesArray = subsites;
        }
        if (subSites && subSites.length > 0) {
            subsitesArray = subSites;
        }
        if (subsiteId && subsiteName) {
            subsitesArray.push({
                id: subsiteId,
                name: subsiteName,
            });
        }
        if (subsite) {
            subsitesArray.push(subsite);
        }

        // 专柜商家码处理门店
        if (subsiteInfo && subsiteInfo.id) {
            subsitesArray.push({
                id: subsiteInfo.id,
                name: subsiteInfo.name,
            });
        }
        return subsitesArray;
    };
    getMerchantIds = () => {
        const { merchantId } = this.props;
        return merchantId;
    };
    getName = (subsiteName: string) => {
        const { codeModalType, codePageType, customGetName } = this.props;
        let codeName = subsiteName;
        if (customGetName && isFunction(customGetName)) {
            return customGetName();
        }
        if (codeModalType === 'item') {
            if (codePageType === 'parkingPage') {
                codeName += language.getText('parkingPayment');
            } else if (codePageType === 'paymentRewardActivity') {
                codeName += language.getText('payGift');
            } else if (codePageType === 'cashierCounter') {
                const { merchantInfo, bigCode } = this.props.row;
                codeName = `${merchantInfo.name}-${merchantInfo.code}-${bigCode}`;
            } else {
                const { id, name } = this.props.row;
                codeName += `-${name}`;
                codeName += `-${id}`;
            }
        }
        if (codeModalType === 'list') {
            const type = this.props.row?.type || this.props.codePageType;
            const object = path[type];
            if (object) {
                codeName += `-${object.name}`;
            }
        }
        return codeName;
    };

    public get needChannelType() {
        const {
            row: { type },
        } = this.props;
        return type === 'storePage' || type === 'customPage';
    }

    isSubmit = false;
    hardleModal = debounce(async () => {
        const { visibleModal } = this.state;
        const { codePageType, row, codeModalType, customParams } = this.props;
        if (!visibleModal) {
            if (this.isSubmit) {
                return false;
            }
            this.isSubmit = true;
            if (!codeModalType) {
                return;
            }
            //列表项上按钮取type,列表上方按钮取codePageType
            if (!row?.type && !codePageType) {
                AntMessage.error(language.getText('notSupportedPromotion'));
                return;
            }
            const target = new Target({ ...this.props, getSubsitesArray: this.getSubsitesArray });
            const { linkName, linkType, getTarget } = target;
            let subsitesArray: any[] = [];
            if (codeModalType === 'item') {
                subsitesArray = this.getSubsitesArray();
            }
            if (codeModalType === 'list') {
                const res: any = await api.get(
                    {},
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}`,
                        apiPath: '/WEB-API/admin/defaults/subsite',
                    }
                );
                subsitesArray = res;
            }
            if (codePageType === 'bargainPage') {
                const res: any = await api.get(
                    {},
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}`,
                        apiPath: '/WEB-API/admin/front_info/1',
                    }
                );
                this.setState({
                    currentBargainTag: language.getText('qrCode'),
                    homeLogoFileUrl: res.picDomain + res.homeLogoFileUrl,
                });
            }
            if (subsitesArray && subsitesArray.length > 0) {
                const commands: any[] = [];
                forEach(subsitesArray, (subsite) => {
                    const { target, linkPath } = getTarget(subsite.id);
                    console.log('--->', this.needChannelType, row.channels);
                    commands.push({
                        name: this.getName(subsite.name),
                        pageName: linkName,
                        needUrlLink: true,
                        target,
                        subsiteId: subsite.id,
                        merchantIds: this.getMerchantIds(),
                        applicationVersion: 'V2',
                        channel: language.getText('defaultChannel'),
                        channelType: '',
                        statistic_type: 'CHANNEL',
                        type: linkType,
                        url: linkPath,
                        channels: this.needChannelType && row.channels ? row.channels : [],
                        ...customParams,
                    });
                });
                message.loading(language.getText('generating'), 60000);
                const apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
                const apiPath = '/admin/mini_program_codes/batch_append';
                services.api
                    .post(commands, { apiRoot, apiPath })
                    .then((batchAppendData: any) => {
                        this.setState(
                            {
                                visibleModal: true,
                                batchAppendData,
                            },
                            () => {
                                this.loadCodeFileTypes();
                                this.getH5Url();
                            }
                        );
                    })
                    .catch((error: any) => {
                        setTimeout(() => {
                            services.errorHandle(error);
                        }, 500);
                    })
                    .finally(() => {
                        this.isSubmit = false;
                        message.destroy();
                    });
            }
        } else {
            this.setState({
                visibleModal: !visibleModal,
                currentCodeFileType: CodeFileTypeEnum.AGGREGATION_CODE,
                batchAppendData: [],
                codeFileTypes: [],
            });
        }
    }, 100);

    loadCodeFileTypes = () => {
        const { batchAppendData } = this.state;
        const { hideAlipay, row } = this.props;
        if (!batchAppendData || batchAppendData.length === 0) {
            return;
        }
        const codeFileTypes = [
            {
                id: CodeFileTypeEnum.AGGREGATION_CODE,
                name: language.getText('aggregationCode'),
            },
            {
                id: CodeFileTypeEnum.WECHAT_MINI_PROGRAM_CODE,
                name: language.getText('wxCode'),
            },
        ];
        let isHavaAlipay = false;
        forEach(batchAppendData, (batchAppendDataVo) => {
            if (batchAppendDataVo.codeFiles && batchAppendDataVo.codeFiles.length > 0) {
                const codeFile = find(batchAppendDataVo.codeFiles, {
                    codeFileType: CodeFileTypeEnum.ALIPAY_MINI_PROGRAM_CODE,
                });
                if (codeFile) {
                    isHavaAlipay = true;
                }
            }
        });
        if (hideAlipay) {
            isHavaAlipay = false;
        }
        if (isHavaAlipay) {
            codeFileTypes.push({
                id: CodeFileTypeEnum.ALIPAY_MINI_PROGRAM_CODE,
                name: language.getText('aliCode'),
            });
        }

        if (this.needChannelType && row.channels && row.channels.length) {
            if (row.channels.length <= 1) {
                if (row.channels.includes(ChannelType.Wechat)) {
                    remove(codeFileTypes, {
                        id: CodeFileTypeEnum.ALIPAY_MINI_PROGRAM_CODE,
                    });
                }
                if (row.channels.includes(ChannelType.Alipay)) {
                    remove(codeFileTypes, {
                        id: CodeFileTypeEnum.WECHAT_MINI_PROGRAM_CODE,
                    });
                }
                remove(codeFileTypes, {
                    id: CodeFileTypeEnum.AGGREGATION_CODE,
                });
            }
            this.setState({
                currentCodeFileType: codeFileTypes[0].id,
            });
        }
        this.setState({
            codeFileTypes,
        });
    };

    renderPrint = () => {
        const { codePageType, row } = this.props;
        const { fileUrl } = this.state;
        if (codePageType !== 'cashierCounter' || !fileUrl) {
            return null;
        }
        const { bigCode, name, subsiteInfo, merchantInfo } = row;
        const bigCodes = [
            {
                id: bigCode,
                name,
                subsiteName: subsiteInfo.name,
                merchantName: merchantInfo.name,
                url: fileUrl,
            },
        ];
        return <BigCodesPrint setWrappedInstance={this.setReceiptRef} bigCodes={bigCodes} />;
    };

    render() {
        const { visibleModal } = this.state;
        const { buttonTitle, codeModalType } = this.props;
        const modalWidth = this.isNewStyle() ? '860px' : '670px';
        return (
            <span className="table-action-column-item">
                {codeModalType === 'item' ? (
                    <span className="copy-span-link" onClick={this.hardleModal}>
                        {buttonTitle ? buttonTitle : language.getText('generalization')}
                    </span>
                ) : (
                    <Button
                        className="span-link"
                        style={{ padding: '0 15px', marginLeft: '10px' }}
                        type="default"
                        onClick={this.hardleModal}
                    >
                        {buttonTitle ? buttonTitle : language.getText('generalization')}
                    </Button>
                )}
                <Modal
                    destroyOnClose
                    visible={visibleModal}
                    title={language.getText('generalization')}
                    width={modalWidth}
                    onCancel={this.hardleModal}
                    onOk={this.hardleModal}
                    okText={language.getText('common.ok')}
                    cancelText={language.getText('common.cancel')}
                >
                    <div className="new-preview-content-code">{this.renderWrap()}</div>
                </Modal>
                {this.renderPrint()}
            </span>
        );
    }
}

type TargetRecord = Record<string, any>;

export class Target {
    constructor(props: any) {
        const { row, codePageType, getSubsitesArray } = props;
        //列表项上按钮取type,列表上方按钮取codePageType
        const type = codePageType || row?.type;
        if (!type) {
            throw new Error(language.getText('typeError'));
        }
        this.type = type;
        this.row = row;
        const config = path[type];
        this.config = config;
        let linkParams: TargetRecord = {};
        let linkName = config.name;
        const getParamsValue = (key: string) => {
            let value = row[key];
            if (key === 'storeId') {
                const subsites = getSubsitesArray();
                if (subsites && subsites.length > 0) {
                    value = subsites[0].id;
                }
            }
            return value;
        };
        if (config.params) {
            config.params.forEach((key: string) => {
                const value = getParamsValue(key);
                if (value) {
                    linkParams[key] = value;
                }
                if (key === 'name') {
                    linkName += `-${value}`;
                }
            });
        }
        this.linkName = linkName;
        this._linkParams = linkParams;
    }
    private row: TargetRecord;
    private type: string;
    public config: TargetRecord;
    public linkName: string;
    public get linkType() {
        return this.config.id;
    }

    private _linkParams: TargetRecord;
    private linkParams = (subsiteId?: string): TargetRecord => {
        if (!this.config || !this._linkParams) {
            return {};
        }
        if (this.config.id === 'subsite') {
            return { ...this._linkParams, id: subsiteId };
        }
        if (this._linkParams.storeId) {
            return { ...this._linkParams, storeId: subsiteId };
        }
        return { ...this._linkParams };
    };
    public getTarget = (subsiteId?: string) => {
        const linkParams = this.linkParams(subsiteId);
        const query = linkParams
            ? Object.keys(linkParams)
                  .map((key: string) => `${key}=${linkParams[key]}`)
                  .join('&')
            : undefined;
        let linkPath = query ? this.config.basePath + '?' + query : this.config.basePath;
        if (this.type === 'fixedPricePage') {
            linkPath += `&promotionRuleId=${this.row.id}&rewardType=11`;
        }
        const target = JSON.stringify({
            linkType: this.linkType,
            linkParams,
            linkName: this.linkName,
            linkPath,
            needUrlLink: true,
            zipName: this.config.zipName || '',
        });
        return { target, linkPath };
    };
}
