import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { ProductCategorySelectMode } from '../../../components/product-category-select/select-product-category';
import get from 'lodash/get';
import { language } from '@comall-backend-builder/core/lib/services';

enum CannelType {
    ONLINE = 'ONLINE',
    OFFLINE = 'OFFLINE',
}

const fromConfig = {
    direction: 'horizontal',
    entity: 'FestivalMarketingEntity',
    labelCol: 4,
    controlCol: 20,
    style: { width: 720 },
    fields: [
        {
            property: 'baseInfo.name',
        },

        {
            property: 'baseInfo.dateRange',
        },
        {
            property: 'ruleInfo.subsiteId',
        },
        { property: 'ruleInfo.marketingSchedule' },
        {
            property: 'ruleInfo.merchants',
        },
        {
            property: 'ruleInfo.channel',
        },
        {
            property: 'ruleInfo.activityProductType',
            visible: (values: any) => get(values, 'ruleInfo.channel') === CannelType.ONLINE,
        },
        {
            property: 'ruleInfo.products',
            visible: (values: any) => get(values, 'ruleInfo.channel') === CannelType.ONLINE,
        },
        {
            property: 'ruleInfo.memberLevelRule',
        },
    ],
    submit: true,
    footer: {
        items: [
            {
                htmlType: 'button',
                route: 'goBack',
                text: '<<common.cancel>>',
                type: 'default',
                style: { marginLeft: 10 },
            },
        ],
    },
    onValidate: (entity: any) => {
        let memberLevelRule = get(entity, 'ruleInfo.memberLevelRule');
        if (memberLevelRule) {
            const unifiedReward = get(memberLevelRule, 'unifiedReward');
            const rewardInfos = get(memberLevelRule, 'rewardInfos');
            if (unifiedReward) {
                if (
                    !rewardInfos ||
                    rewardInfos.length === 0 ||
                    !(rewardInfos[0].rewardValue || rewardInfos[0].experienceValue)
                ) {
                    AntMessage.error(language.getText('hydjgzbkwk'));
                    return false;
                }
            } else {
                if (!rewardInfos || rewardInfos.length === 0) {
                    AntMessage.error(language.getText('gmdxwhydj'));
                    return false;
                } else {
                    const hasValue = rewardInfos.some(
                        (r: any) => r.rewardValue || r.experienceValue
                    );
                    if (!hasValue) {
                        AntMessage.error(language.getText('hydjgzbkwk'));
                        return false;
                    }
                }
            }
        }
        return true;
    },
    onSubmitSuccess: () => {
        AntMessage.success(services.language.getText('common.saveSuccess'), () =>
            services.behaviorHandle({ route: 'goBack' })
        );
    },
};

export const config: Config = {
    entities: {
        FestivalMarketingEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/festival_marketing',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<applicableSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
                channel: {
                    type: 'string.options.select',
                    displayName: '<<qd_1>>',
                    options: [
                        { id: 'ONLINE', name: '<<xsgz>>' },
                        { id: 'OFFLINE', name: '<<xxgz>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string',
                    displayName: '<<endTime>>',
                },
                subsite: {
                    type: 'string',
                    displayName: '<<applicableSubsite>>',
                },
                activityProductType: {
                    type: 'string.options.select',
                    displayName: '<<cysp>>',
                    options: [
                        { id: 'ALL', name: '<<allProduct>>' },
                        { id: 'INCLUDE_PRODUCT', name: '<<includeProduct>>' },
                        { id: 'EXCLUDE_PRODUCT', name: '<<excludeProduct>>' },
                    ],
                },
                merchants: {
                    type: 'array.popover',
                    displayName: '<<applyMerchants>>',
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<status>>',
                },
                channel: {
                    type: 'string.options.select',
                    displayName: '<<qd_1>>',
                    options: [
                        { id: 'ONLINE', name: '<<xsgz>>' },
                        { id: 'OFFLINE', name: '<<xxgz>>' },
                    ],
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'NOT_STARTED',
                    options: [
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<xgsj>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseInfo>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrhdmc>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<shopCelebration>>',
                                maxLength: 20,
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<hdrq>>',
                            rules: [{ required: true, message: '<<qxzhdrq>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                    },
                },
                ruleInfo: {
                    type: 'object.subForm',
                    displayName: '<<activityRule>>',
                    properties: {
                        subsiteId: {
                            type: 'string.options.select',
                            className: 'custom-array-options',
                            displayName: '<<applicableSubsite>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<selectSubsite>>',
                                style: {
                                    width: 300,
                                },
                            },
                        },
                        merchants: {
                            type: 'array.merchant.selector',
                            displayName: '<<applyMerchants>>',
                            defaultValue: [],
                            controlConfig: {
                                subsiteField: 'ruleInfo.subsiteId',
                                tips: '<<bxzzjzbgzsyysxmdxsyzj>>',
                            },
                        },
                        channel: {
                            type: 'string.options.radio',
                            displayName: '<<qd_1>>',
                            defaultValue: CannelType.ONLINE,
                            options: [
                                { id: CannelType.ONLINE, name: '<<xsgz>>' },
                                { id: CannelType.OFFLINE, name: '<<xxgz>>' },
                            ],
                            rules: [{ required: true }],
                            extra: '<<xgzfbdyjfhjyzdxs>>',
                        },
                        activityProductType: {
                            type: 'string.options.radio',
                            displayName: '<<activityProduct>>',
                            defaultValue: 'ALL',
                            options: [
                                { id: 'ALL', name: '<<allProduct>>' },
                                { id: 'INCLUDE_PRODUCT', name: '<<includeProduct>>' },
                                { id: 'EXCLUDE_PRODUCT', name: '<<excludeProduct>>' },
                            ],
                        },
                        products: {
                            type: 'object.productCategory.selector',
                            displayName: '<<components.Tools.selectProduct>>',
                            controlConfig: {
                                selectProductTypes: ['NORMAL,COMBINE'],
                                subsiteSelectMode: 'ONLY_SUBSITE',
                                handleSelectMode: (row: any) => {
                                    const merchants = get(row, 'ruleInfo.merchants');
                                    if (merchants && merchants.length > 0) {
                                        return ProductCategorySelectMode.MERCHANT;
                                    }
                                    return ProductCategorySelectMode.SUB_SITE;
                                },
                            },
                        },
                        memberLevelRule: {
                            type: 'object.memberCardLevelPointReward',
                            displayName: '<<hydjgz>>',
                            controlConfig: {
                                subsiteField: 'ruleInfo.subsiteId',
                                enableExperienceValue: true,
                            },
                            rules: [{ required: true }],
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],

                            controlConfig: {
                                subsiteField: 'ruleInfo.subsiteId',
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    return { subsiteIds: row.ruleInfo?.subsiteId };
                                },
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        FestivalMarketingView: {
            component: 'Viewport',
            entity: 'FestivalMarketingEntity',
        },
        FestivalMarketingPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'FestivalMarketingFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/festival-marketing/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'FestivalMarketingTable' },
            ],
        },
        FestivalMarketingFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
                style: {
                    marginLeft: 16,
                },
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsrhdmc>>',
                    },
                },
                {
                    property: 'subsiteId',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'channel',
                    controlConfig: {
                        style: { width: 180 },
                    },
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        FestivalMarketingTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'activityProductType',
                },
                {
                    property: 'subsite',
                },
                {
                    property: 'merchants',
                    width: 180,
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                        countSuffix: '<<merchant>>',
                    },
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
                {
                    property: 'channel',
                },
                {
                    property: 'lastModifyTime',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 220,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'enable',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/festival_marketing_activities/:id/disable',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isCloseActivity>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/festival_marketing_activities/:id/enable',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/festival-marketing/edit/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'NewImportButton',
                        statusKey: 'row.canImport',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
                            apiPath: '/admin/files/locations/800/upload',
                            text: '<<importProduct>>',
                            templateSource: {
                                apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                apiPath: '/admin/festival_marketing_activities/export.xlsx',
                            },
                            specialTips: ['<<rxtmddsphfgzqdrdsp>>'],
                        },
                    },
                ],
            },
        },
        FestivalMarketingAddPage: {
            component: 'FlexLayout',
            items: [{ component: 'FestivalMarketingAddFormView' }],
        },
        FestivalMarketingAddFormView: {
            component: 'CreateFormPlus',
            ...fromConfig,
        },
        FestivalMarketingEditPage: {
            component: 'FlexLayout',
            items: [{ component: 'FestivalMarketingEditFormView' }],
        },
        FestivalMarketingEditFormView: {
            component: 'ModifyFormPlus',
            loaderType: 'get',
            ...fromConfig,
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/festival-marketing',
                    component: 'FestivalMarketingView',
                    breadcrumbName: '<<festivalMarketing>>',
                    privilege: {
                        path: 'festivalMarketing',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'FestivalMarketingAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'FestivalMarketingEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        { path: '/', component: 'FestivalMarketingPage' },
                    ],
                },
            ],
        },
    ],
};
