import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { isEmpty } from 'lodash';

const api = services.api;
export const OfflineOrderCommentsLoader: Loader = {
    get: async function(data, config) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COMMENT`;
        config.apiPath = '/admin/comments';
        data.scene = 'POS_RECEIPT_ORDER';
        if (!isEmpty(data.subsite)) {
            data.subsiteId = data.subsite.id;
            delete data.subsite;
        }
        if (data.commentLevel === 'ALL') {
            delete data.commentLevel;
        }
        if (data.hasBusinessReply === 'ALL') {
            delete data.hasBusinessReply;
        }
        if (data.hasContent === 'ALL') {
            delete data.hasContent;
        }
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        return await api.get(data, config).then((res: any) => {
            res.result = res.result.map((item: any) => ({
                ...item,
                commentItemContent: item.commentItemContent + item.content,
                canReply: item.businessReplyId ? false : true,
            }));
            return res;
        });
    },
};
