import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import isEmpty from 'lodash/isEmpty';

const api = services.api;
export const PosReceiptLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        if (!isEmpty(data.invoiceDateRange)) {
            data.invoiceStartTime = data.invoiceDateRange.start + ' 00:00:00';
            data.invoiceEndTime = data.invoiceDateRange.end + ' 23:59:59';
            delete data.invoiceDateRange;
        }
        return await api.get(data, config).then((res: any) => {
            res.result = res.result.map((item: any) => {
                let subsiteName = '';
                if (item.subsites && item.subsites.length > 0) {
                    subsiteName = item.subsites[0].name;
                }
                item.subsiteName = subsiteName;
                return item;
            });
            return res;
        });
    },
};
