import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach } from 'lodash';

const api = services.api;
export const BuyLimitLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const { id } = data;

        let paramsFilter = config.paramsFilter;

        let type = paramsFilter && paramsFilter.length > 0 ? paramsFilter[0] : 'SUBSITE';
        if (type) {
            data.type = type;
            delete config.paramsFilter;
        }

        return await api.get(data, config).then((res: any) => {
            if (id) {
                if (res) {
                    res.subsiteIds = res.subsites;
                    res.merchantIds = res.subsiteMerchants;
                }
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        let newParams = dataParser(params);
        if (config.paramsFilter) {
            delete config.paramsFilter;
        }

        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        let newParams = dataParser(params);
        if (config.paramsFilter) {
            delete config.paramsFilter;
        }
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    if (params.id) {
        newParams.id = params.id;
    }
    newParams.type = 'AMOUNT';
    newParams.subsites = params.subsiteIds;
    newParams.deliveryModes = params.deliveryModes;
    newParams.value = params.value;
    if (params.merchantIds && params.merchantIds.length > 0) {
        const merchantArray: any = [];
        forEach(params.merchantIds, (merchant) => {
            merchantArray.push({ id: merchant.merchantId });
        });
        newParams.merchants = merchantArray;
    }

    return newParams;
}
