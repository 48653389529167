import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

function formatSubmitParams(params: any) {
    const {
        resource: [resource],
        cityIds,
        sequence,
    } = params;
    return {
        ...params,
        resourceId: +resource.id,
        resourceUrl: resource.path,
        cityIds: cityIds.map((id: string) => +id),
        sequence: +sequence,
        resource: undefined,
    };
}

function formatResult(result: any) {
    const { resourceUrl, cities } = result;
    return {
        ...result,
        cityIds: cities.map(({ id }: any) => String(id)),
        resource: resourceUrl
            ? [
                  {
                      id: 1,
                      path: resourceUrl,
                  },
              ]
            : [],
    };
}

function formatResultList(result: any) {
    return result.map(formatResult);
}

export const ContentPlateLoader: Loader = {
    async get(params, config: ApiRequestConfig) {
        return api.get<any>(params, config).then((response) => {
            if (response.result) {
                response.result = formatResultList(response.result);
            } else {
                response = formatResult(response);
            }
            return response;
        });
    },
    async post(params, config: ApiRequestConfig) {
        return api.post(formatSubmitParams(params), config);
    },
    async put(params, config: ApiRequestConfig) {
        return api.put(formatSubmitParams(params), config);
    },
};
