import { Config } from '@comall-backend-builder/core/lib/parser';

export let config: Config = {
    entities: {
        MemberManage: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
            apiPath: '/loader/admin/members',
        },
    },
    components: {
        MemberManageView: {
            component: 'Viewport',
            entity: 'MemberManage',
        },
        MemberManageReportPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'MemberManage',
            items: [{ component: 'MemberManageReport' }],
        },
        MemberManageDetailPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'member-info-detail-page-card',
            entity: 'MemberManage',
            items: [{ component: 'MemberInfoDetailPage' }],
        },
        MemberManageDetailLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                    apiPath: `/admin/operation_logs/MEMBER_STATUS_CHANGE/{id}`,
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/member-manage',
                    breadcrumbName: '<<memberManage>>',
                    component: 'MemberManageView',
                    privilege: {
                        path: 'memberManage',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/info/:id',
                            component: 'MemberManageDetailPage',
                            breadcrumbName: '<<common.view>>',
                        },
                        {
                            path: '/logs/:id',
                            component: 'MemberManageDetailLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },

                        { path: '/', component: 'MemberManageReportPage' },
                    ],
                },
            ],
        },
    ],
};
