import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { ActivityRewardBase } from '../../../../components/avtivity-reward-base';

/**
 *  活动奖励
 */
export class ObjectActivityRewardSingleMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <ActivityRewardBase {...controlInfo} />;
    }
}
