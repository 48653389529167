import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import isEmpty from 'lodash/isEmpty';

const api = services.api;
export const WechatOfficialAccountFanLoader: Loader = {
    get: async function(data, config) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`;
        config.apiPath = '/admin/wechat_official_accounts_fan';
        if (!isEmpty(data.dateRange)) {
            data.subscribeStartTime = data.dateRange.start + ' 00:00:00';
            data.subscribeEndTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        if (!isEmpty(data.unsubscribeDateRange)) {
            data.unsubscribeStartTime = data.unsubscribeDateRange.start + ' 00:00:00';
            data.unsubscribeEndTime = data.unsubscribeDateRange.end + ' 23:59:59';
            delete data.unsubscribeDateRange;
        }
        return await api.get(data, config);
    },
};
