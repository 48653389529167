import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { defaults, get } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';

export interface BargainActivityEditFormProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
}

class bargainActivityEditFormView extends PureComponent<BargainActivityEditFormProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }
    render() {
        const { entity, onSubmit, wrappedComponentRef } = this.props;
        let fields = [
            { property: 'baseInfo.name' },
            {
                property: 'baseInfo.dateRange',
                controlConfig: {
                    style: {
                        width: 450,
                    },
                },
                rules: [
                    { required: true, message: services.language.getText('acitivityTimeRequired') },
                    {
                        async validator(_: any, value: any) {
                            const originStartDate = get(entity.fields, 'baseInfo.dateRange.start');
                            const originEndDate = get(entity.fields, 'baseInfo.dateRange.end');
                            if (!value) {
                                return;
                            }
                            const currentStartDate = value.start;
                            const currentEndDate = value.end;
                            if (originStartDate !== currentStartDate) {
                                throw new Error(
                                    services.language.getText('ksrqbngg') + `${originStartDate}`
                                );
                            }
                            if (new Date(currentEndDate).getTime() < new Date().getTime()) {
                                throw new Error(services.language.getText('jssjbnzydqsj'));
                            }
                            if (
                                new Date(originEndDate).getTime() >
                                new Date(currentEndDate).getTime()
                            ) {
                                throw new Error(services.language.getText('xxgdjssjbnzydqdjssj'));
                            }
                        },
                    },
                ],
            },
            {
                property: 'baseInfo.subsites',
                controlConfig: {
                    disabled: true,
                },
            },
            {
                property: 'baseInfo.productOrGoods',
                controlConfig: {
                    disabled: true,
                },
            },
            { property: 'baseInfo.marketingSchedule' },
            {
                property: 'initiatorInfo.effectiveHours',
                controlConfig: {
                    disabled: true,
                },
            },
            {
                property: 'initiatorInfo.initBargainAmount',
                controlConfig: {
                    disabled: true,
                },
            },
            {
                property: 'initiatorInfo.bargainCountEachMember',
                controlConfig: {
                    disabled: true,
                },
            },
            {
                property: 'helpInfo.bargainAmountCalculateMethod',
                controlConfig: {
                    disabled: true,
                },
            },
            {
                property: 'helpInfo.helpMemberCount',
                controlConfig: {
                    disabled: true,
                },
            },
            {
                property: 'helpInfo.helpBargainCountEachMember',
                controlConfig: {
                    disabled: true,
                },
            },
            { property: 'helpInfo.rewardConfigId', className: 'hidden' },
            { property: 'helpInfo.coupons' },
            { property: 'pictureInfo.shareDescription' },
            { property: 'pictureInfo.backgroundPicture' },
            { property: 'pictureInfo.bargainButtonPicture' },
            { property: 'pictureInfo.bargainRewardPicture' },
            { property: 'pictureInfo.openBargainRewardPicture' },
            {
                property: 'pictureInfo.description',
            },
        ];

        let formProps = {
            entity,
            componentId: 'BargainActivityEditFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'bargain-activity-edit-form-container',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            fields: fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: services.language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: async (entity: any) => {
                const products = entity.baseInfo.productOrGoods.products;
                const goods = entity.baseInfo.productOrGoods.goods;
                if (products.length === 0 && goods.length === 0) {
                    AntMessage.warning(services.language.getText('productSelectRequired'));
                    return false;
                }
                const initBargainAmount = entity.initiatorInfo.initBargainAmount;
                const helpMemberCount = entity.helpInfo.helpMemberCount;
                if (products.length > 0) {
                    const product = products[0];
                    const salesPrice = product.salesPrice;
                    const generalPrice = product.generalPrice;
                    if (initBargainAmount && initBargainAmount > salesPrice - generalPrice) {
                        AntMessage.warning(services.language.getText('csjebdgyxsjydjdce'));
                        return false;
                    }
                    if (salesPrice && generalPrice) {
                        let price = salesPrice - generalPrice;
                        if (initBargainAmount) {
                            price = price - initBargainAmount;
                        }
                        if (Number(price.toFixed(2)) / helpMemberCount < 0.01) {
                            AntMessage.warning(services.language.getText('mrzsbk'));
                            return false;
                        }
                    }
                }
                if (goods.length > 0) {
                    const good = goods[0];
                    const salesPrice = good.salesPrice;
                    const generalPrice = good.generalPrice;
                    if (initBargainAmount && initBargainAmount > salesPrice - generalPrice) {
                        AntMessage.warning(services.language.getText('csjebdgyxsjydjdce'));
                        return false;
                    }
                    if (salesPrice && generalPrice) {
                        let price = salesPrice - generalPrice;
                        if (initBargainAmount) {
                            price = price - initBargainAmount;
                        }
                        if (Number(price.toFixed(2)) / helpMemberCount < 0.01) {
                            AntMessage.warning(services.language.getText('mrzsbk'));
                            return false;
                        }
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange } = props;
    let form: any;

    return defaults(
        {
            onSubmit,
            onFieldChange,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('BargainActivityEditFormContainer'));
            },
        }
    );
}
export const BargainActivityEditForm = connect(mapDispatchToProps)(bargainActivityEditFormView);
