import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { api, localStorage } from '@comall-backend-builder/core/lib/services';
import { cloneDeep, find, findIndex, forEach, set } from 'lodash';
import { Icon, Input, Modal, Tooltip, message as AntMessage, Cascader } from 'antd';
import { MessageNotificationMethodValueItem } from '../../message-notification-method';
import { MessageParameterSelector } from '../../../../../../containers/wechat/components/message-parameter-selector';
import { PushAndSiteMailSceneType, SpecialType } from '../../../../../../containers/wechat/common';
import { searchWebParse, searchStringify } from '../../../../../../services/utils';
import { CustomSelectScratchCard } from '../../../../../../applications/design/design/components/custom-select-scratch-card';
import { CustomSelectRedPackageRain } from '../../../../../../applications/design/design/components/custom-select-red-package-rain';
import { CustomSelectWheelDraw } from '../../../../../../applications/design/design/components/custorm-select-wheel-draw';
import { TopicSelector } from '../../../../../../applications/design/design/components/custom-select-topic';
import { CustomSelectLotteryCode } from '../../../../../../applications/design/design/components/custom-select-lottery-code';
import './message-notification-weapp-edit-modal.less';

interface Props {
    visible: boolean;
    template: MessageNotificationMethodValueItem;
    scene: PushAndSiteMailSceneType;
    landingPage: any;
    onChange: (template: MessageNotificationMethodValueItem) => void;
    closeModal: () => void;
}
interface State {
    editTemplate: MessageNotificationMethodValueItem;
    subsiteWechantName: string;
    logoUrl: string;
    appId: string;
}

export class MessageNotificationWeappEditModal extends PureComponent<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            editTemplate: cloneDeep(props.template),
            subsiteWechantName: '',
            logoUrl: '',
            appId: '',
        };
    }
    componentDidMount() {
        this.loadWechatOfficialAccount();
    }
    loadWechatOfficialAccount = async () => {
        let store: any = localStorage.get('wechantTemplatesStore');
        if (store && store.id) {
            //公众号名称
            const result: any = await api.get(
                {
                    subsiteId: store.id,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: `/admin/wechat_official_accounts/by_subsite_id`,
                }
            );
            if (result) {
                this.setState({
                    subsiteWechantName: result.appName ? result.appName : '',
                    appId: result.appId ? result.appId : undefined,
                });
            }
        }
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: `/admin/front_config`,
            }
        ).then((response: any) => {
            if (response) {
                this.setState({
                    logoUrl: response.authorizeLogoFileUrl,
                });
            }
        });
    };
    handleSubmit = () => {
        const { onChange, closeModal } = this.props;
        const { editTemplate } = this.state;
        const { targetType, targetUrl } = editTemplate;
        if (!targetType) {
            AntMessage.warning(services.language.getText('qxzljlx'));
            return false;
        }
        if (targetType === 'parameterConcatenation') {
            const needUrl = [
                'subpackages/topic',
                'wheel-draw/pages',
                'packet-rain/index',
                'scratch-card/index',
                'lottery-code/index',
            ];
            if (!targetUrl) {
                AntMessage.warning(services.language.getText('selectLinkTarget'));
                return false;
            }
            if (needUrl.some((i) => targetUrl.includes(i)) && !targetUrl.includes('?')) {
                AntMessage.warning(services.language.getText('selectLinkTarget'));
                return false;
            }
        }
        onChange(editTemplate);
        closeModal();
    };

    getParams = () => {
        const { scene } = this.props;
        const { editTemplate } = this.state;
        let top, bottom;
        let messageList: any[] = [];
        let successList: any[] = [];
        let failList: any[] = [];
        let detailContentColor = '';
        if (editTemplate && editTemplate.params) {
            top = find(editTemplate.params, { name: 'top' });
            bottom = find(editTemplate.params, { name: 'bottom' });
            forEach(editTemplate.params, (param: any) => {
                if (param.name !== 'name' && param.name !== 'top' && param.name !== 'bottom') {
                    if (scene === PushAndSiteMailSceneType.LOTTERY_CODE_AWARD_NOTICE) {
                        if (
                            param.fieldType === 'lotteryCodeRewardResult_success' ||
                            param.fieldType === 'remark_success'
                        ) {
                            successList.push(param);
                        } else if (
                            param.fieldType === 'lotteryCodeRewardResult_fail' ||
                            param.fieldType === 'remark_fail'
                        ) {
                            failList.push(param);
                        } else {
                            messageList.push(param);
                        }
                    } else if (
                        scene === PushAndSiteMailSceneType.MALL_ACTIVITY_REGISTRATION_SUCCEEDED_MSG
                    ) {
                        if (
                            param.fieldType === 'registeredResult_success' ||
                            param.fieldType === 'remark_success'
                        ) {
                            successList.push(param);
                        } else if (
                            param.fieldType === 'registeredResult_fail' ||
                            param.fieldType === 'remark_fail'
                        ) {
                            failList.push(param);
                        } else {
                            messageList.push(param);
                        }
                    } else {
                        messageList.push(param);
                    }
                }
            });
            if (messageList.length > 0) {
                detailContentColor = messageList[0].color;
            }
        }
        return {
            top,
            bottom,
            messageList,
            successList,
            failList,
            detailContentColor,
        };
    };
    renderLeftWrap = () => {
        const { subsiteWechantName, editTemplate } = this.state;
        const topImage = require('../../images/icon-miniprogram-top.png');
        const goImage = require('../../images/icon-miniprogram-right.png');
        const {
            top,
            bottom,
            messageList,
            detailContentColor,
            successList,
            failList,
        } = this.getParams();
        return (
            <div className={classes.left}>
                <div className={classes.leftInfo}>
                    <div className={classes.leftInfoTop}>
                        <img src={topImage} width="364px" alt="" />
                        <div className={classes.leftInfoTopName}>{subsiteWechantName}</div>
                    </div>
                    <div className={classes.leftInfoData}>
                        <div className={classes.leftInfoDataContent}>
                            <div className={classes.contentHeader}>
                                <div>
                                    {editTemplate && editTemplate.name
                                        ? editTemplate.name
                                        : services.language.getText('mbbt')}
                                </div>
                                <div className={classes.contentDianWrap}>
                                    <div className={classes.contentDian}></div>
                                    <div className={classes.contentDian}></div>
                                    <div className={classes.contentDian}></div>
                                </div>
                            </div>
                            <div>
                                {top ? (
                                    <div className={classes.contentCenter}>
                                        <span
                                            style={{
                                                whiteSpace: 'pre-line',
                                            }}
                                        >
                                            {top.value}
                                        </span>
                                    </div>
                                ) : null}
                                <div className={classes.contentOther}>
                                    {messageList.map((o: any) => {
                                        return (
                                            <div key={o.name} className={classes.contentOtherItem}>
                                                <span>{o.name}：</span>
                                                <span
                                                    style={{
                                                        color: detailContentColor
                                                            ? detailContentColor
                                                            : '#000',
                                                        flex: 1,
                                                        whiteSpace: 'pre-line',
                                                    }}
                                                >
                                                    {o.value}
                                                </span>
                                            </div>
                                        );
                                    })}
                                </div>
                                {successList && successList.length ? (
                                    <div className={classes.contentOther} style={{ marginTop: 0 }}>
                                        {successList.map((o: any) => {
                                            return (
                                                <div
                                                    key={o.name}
                                                    className={classes.contentOtherItem}
                                                >
                                                    <span>{o.name}：</span>
                                                    <span
                                                        style={{
                                                            color: detailContentColor
                                                                ? detailContentColor
                                                                : '#000',
                                                            flex: 1,
                                                            whiteSpace: 'pre-line',
                                                        }}
                                                    >
                                                        {o.value}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                                {failList && failList.length ? (
                                    <div className={classes.contentOther} style={{ marginTop: 0 }}>
                                        {failList.map((o: any) => {
                                            return (
                                                <div
                                                    key={o.name}
                                                    className={classes.contentOtherItem}
                                                >
                                                    <span>{o.name}：</span>
                                                    <span
                                                        style={{
                                                            color: detailContentColor
                                                                ? detailContentColor
                                                                : '#000',
                                                            flex: 1,
                                                            whiteSpace: 'pre-line',
                                                        }}
                                                    >
                                                        {o.value}
                                                    </span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                ) : null}
                                {bottom ? (
                                    <div
                                        className={classes.contentBottom}
                                        style={{
                                            whiteSpace: 'pre-line',
                                            color: bottom.color || '#D0021B',
                                        }}
                                    >
                                        {bottom.value}
                                    </div>
                                ) : null}
                            </div>
                        </div>
                        <div className={classes.footer}>
                            <div>
                                <span>{services.language.getText('jrxcxck')}</span>
                            </div>
                            <div>
                                <img
                                    src={goImage}
                                    alt=""
                                    style={{ marginLeft: '4px', width: '16px' }}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    renderRightWrap = () => {
        const { scene } = this.props;
        const { editTemplate } = this.state;
        if (!editTemplate) {
            return null;
        }
        const { messageList, successList, failList } = this.getParams();
        const info = {
            maxLength: 20,
            valid: new RegExp(/^[\u4e00-\u9fa5\d\w\s\S]{1,20}$/),
            placeholder: services.language.getText('gynzf20'),
            tip: services.language.getText('wxts20Tip'),
            errorTip: services.language.getText('gynzf20'),
        };
        return (
            <div className={classes.right}>
                <div className={classes.rightTitle}>{services.language.getText('nrpz')}</div>
                {messageList && messageList.length > 0 && (
                    <div className="message-notification-weapp-edit-modal__parameter">
                        <MessageParameterSelector params={{ scene: scene }} />
                        <div>{services.language.getText('qfzkydblmc')}</div>
                    </div>
                )}
                {editTemplate.name && (
                    <div className="message-notification-weapp-edit-modal__flex">
                        <div className="message-notification-weapp-edit-modal__title">
                            {services.language.getText('mbbt')}：
                        </div>
                        <div className="message-notification-weapp-edit-modal__value">
                            {editTemplate.name}
                        </div>
                    </div>
                )}
                {messageList &&
                    messageList.map((o: any, index: number) => {
                        return (
                            <div
                                className="message-notification-weapp-edit-modal__flex"
                                key={index}
                            >
                                <div className="message-notification-weapp-edit-modal__title">
                                    {o.name}：
                                </div>
                                <div className="message-notification-weapp-edit-modal__value">
                                    {o.editable ? (
                                        <div className="message-notification-weapp-edit-modal__other-wrap">
                                            <Input
                                                placeholder={info.placeholder}
                                                value={o.value}
                                                onChange={(value: any) =>
                                                    this.onHandleOtherChange(
                                                        o.name,
                                                        value.target.value
                                                    )
                                                }
                                                onBlur={this.onInputBlur.bind(this, o.name)}
                                                maxLength={info.maxLength}
                                            />
                                            <Tooltip
                                                placement="top"
                                                title={info.tip}
                                                className="message-notification-weapp-edit-modal__other-wrap__icon"
                                            >
                                                <Icon type="question-circle" theme="outlined" />
                                            </Tooltip>
                                        </div>
                                    ) : (
                                        o.value
                                    )}
                                </div>
                            </div>
                        );
                    })}
                {successList && successList.length ? (
                    <div className="message-notification-weapp-edit-modal__right__content">
                        <div className="message-notification-weapp-edit-modal__flex">
                            <div className="message-notification-weapp-edit-modal__title">
                                内容1：
                            </div>
                            <div className="message-notification-weapp-edit-modal__value">中奖</div>
                        </div>
                        {successList.map((o: any, index: number) => {
                            return (
                                <div
                                    className="message-notification-weapp-edit-modal__flex"
                                    key={index}
                                >
                                    <div className="message-notification-weapp-edit-modal__title">
                                        {o.name}：
                                    </div>
                                    <div className="message-notification-weapp-edit-modal__value">
                                        {o.editable ? (
                                            <div className="message-notification-weapp-edit-modal__other-wrap">
                                                <Input
                                                    placeholder={info.placeholder}
                                                    value={o.value}
                                                    onChange={(value: any) =>
                                                        this.onHandleSuccessAndFailChange(
                                                            o.name,
                                                            o.fieldType,
                                                            value.target.value
                                                        )
                                                    }
                                                    onBlur={this.onInputSuccessAndFailBlur.bind(
                                                        this,
                                                        o.name,
                                                        o.fieldType
                                                    )}
                                                    maxLength={info.maxLength}
                                                />
                                                <Tooltip
                                                    placement="top"
                                                    title={info.tip}
                                                    className="message-notification-weapp-edit-modal__other-wrap__icon"
                                                >
                                                    <Icon type="question-circle" theme="outlined" />
                                                </Tooltip>
                                            </div>
                                        ) : (
                                            o.value
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : null}
                {failList && failList.length ? (
                    <div className="message-notification-weapp-edit-modal__right__content">
                        <div className="message-notification-weapp-edit-modal__flex">
                            <div className="message-notification-weapp-edit-modal__title">
                                内容2：
                            </div>
                            <div className="message-notification-weapp-edit-modal__value">
                                未中奖
                            </div>
                        </div>
                        {failList.map((o: any, index: number) => {
                            return (
                                <div
                                    className="message-notification-weapp-edit-modal__flex"
                                    key={index}
                                >
                                    <div className="message-notification-weapp-edit-modal__title">
                                        {o.name}：
                                    </div>
                                    <div className="message-notification-weapp-edit-modal__value">
                                        {o.editable ? (
                                            <div className="message-notification-weapp-edit-modal__other-wrap">
                                                <Input
                                                    placeholder={info.placeholder}
                                                    value={o.value}
                                                    onChange={(value: any) =>
                                                        this.onHandleSuccessAndFailChange(
                                                            o.name,
                                                            o.fieldType,
                                                            value.target.value
                                                        )
                                                    }
                                                    onBlur={this.onInputSuccessAndFailBlur.bind(
                                                        this,
                                                        o.name,
                                                        o.fieldType
                                                    )}
                                                    maxLength={info.maxLength}
                                                />
                                                <Tooltip
                                                    placement="top"
                                                    title={info.tip}
                                                    className="message-notification-weapp-edit-modal__other-wrap__icon"
                                                >
                                                    <Icon type="question-circle" theme="outlined" />
                                                </Tooltip>
                                            </div>
                                        ) : (
                                            o.value
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                ) : null}
                {this.rendeLinkType()}
            </div>
        );
    };

    getLinkTypes = () => {
        const { scene } = this.props;
        if (scene === PushAndSiteMailSceneType.PAY_AWARD_RECEIVE_REMIND) {
            return [
                {
                    value: 'subpackages/topic/index',
                    label: services.language.getText('zthd'),
                },
                {
                    value: 'marketing',
                    label: services.language.getText('ycxhd'),
                    children: [
                        {
                            value: 'subpackages/wheel-draw/pages/detail/index',
                            label: services.language.getText('dzpcj'),
                        },
                        {
                            value: 'subpackages/packet-rain/index',
                            label: services.language.getText('hby'),
                        },
                        {
                            value:
                                'subpackages/market/payment-reward/pages/detail/index?type=PAY_AWARD&subsiteId={$subsiteId}',
                            label: services.language.getText('zfyl'),
                        },
                    ],
                },
                {
                    value: 'credit',
                    label: services.language.getText('jfhd'),
                    children: [
                        {
                            value: 'subpackages/scratch-card/index',
                            label: services.language.getText('scratchCard'),
                        },
                        {
                            value: 'subpackages/lottery-code/index',
                            label: services.language.getText('lotteryCode'),
                        },
                    ],
                },
            ];
        }
        return undefined;
    };

    rendeLinkType = () => {
        const { landingPage } = this.props;
        const { editTemplate } = this.state;
        const { targetUrl } = editTemplate;
        const linkTypes = (this.getLinkTypes() as any[]) || [];
        if (linkTypes && linkTypes.length) {
            return (
                <>
                    <div className="message-notification-weapp-edit-modal__flex">
                        <div className="message-notification-weapp-edit-modal__title">
                            {services.language.getText('ljlx')}：
                        </div>
                        <div className="message-notification-weapp-edit-modal__value">
                            {linkTypes && linkTypes.length ? (
                                <Cascader
                                    className="trans-action-selection"
                                    style={{ width: '230px' }}
                                    value={this.getCascaderMiniTargetUrl(targetUrl)}
                                    options={linkTypes}
                                    placeholder={services.language.getText('selectPlease')}
                                    onChange={this.onTargetTypeChange}
                                />
                            ) : (
                                landingPage.name
                            )}
                        </div>
                    </div>
                    <div className="message-notification-weapp-edit-modal__flex">
                        <div
                            className="message-notification-weapp-edit-modal__title"
                            style={{ alignContent: 'center' }}
                        >
                            {services.language.getText('ljmb')}：
                        </div>
                        <div className="message-notification-weapp-edit-modal__value">
                            {this.getTargetUrlParamsComponent(targetUrl)}
                        </div>
                    </div>
                </>
            );
        }
        return (
            <div className="message-notification-weapp-edit-modal__flex">
                <div className="message-notification-weapp-edit-modal__title">
                    {services.language.getText('ljlx')}：
                </div>
                <div className="message-notification-weapp-edit-modal__value">
                    {landingPage.name}
                </div>
            </div>
        );
    };
    getCascaderMiniTargetUrl = (targetUrl: string | null) => {
        if (targetUrl) {
            const marketing = [
                'wheel-draw/pages',
                'packet-rain/index',
                'market/payment-reward/pages/detail/index?type=PAY_AWARD',
            ];
            const credit = ['scratch-card/index', 'lottery-code/index'];
            if (marketing.some((i) => targetUrl.includes(i))) {
                return ['marketing', targetUrl.split('?')[0]];
            }
            if (credit.some((i) => targetUrl.includes(i))) {
                return ['credit', targetUrl.split('?')[0]];
            }
            if (targetUrl.includes('subpackages/topic')) {
                return [targetUrl.split('?')[0]];
            }
            return [targetUrl];
        }
        return [];
    };
    getTargetUrlParamsComponent = (targetUrl: string | null) => {
        if (targetUrl) {
            const pathParams = searchWebParse(targetUrl);
            // 专题活动
            if (targetUrl.includes('subpackages/topic')) {
                return (
                    <TopicSelector
                        newTopic
                        subsiteIds={[]}
                        value={{ linkParams: pathParams }}
                        onChange={this.onChangePathParams}
                    />
                );
            }
            // 大转盘抽奖
            if (targetUrl.includes('wheel-draw/pages')) {
                return (
                    <CustomSelectWheelDraw
                        subsiteIds={[]}
                        value={{ linkParams: pathParams }}
                        contentConfig={{}}
                        modalConfig={{}}
                        onChange={this.onChangePathParams}
                    />
                );
            }
            // 红包雨
            if (targetUrl.includes('packet-rain/index')) {
                return (
                    <CustomSelectRedPackageRain
                        subsiteIds={[]}
                        value={{ linkParams: pathParams }}
                        contentConfig={{}}
                        modalConfig={{}}
                        onChange={this.onChangePathParams}
                    />
                );
            }
            // 积分刮刮卡
            if (targetUrl.includes('scratch-card/index')) {
                return (
                    <CustomSelectScratchCard
                        subsiteIds={[]}
                        value={{ linkParams: pathParams }}
                        contentConfig={{}}
                        modalConfig={{}}
                        onChange={this.onChangePathParams}
                    />
                );
            }
            // 积分抽奖码
            if (targetUrl.includes('lottery-code/index')) {
                return (
                    <CustomSelectLotteryCode
                        subsiteIds={[]}
                        value={{ linkParams: pathParams }}
                        contentConfig={{}}
                        modalConfig={{}}
                        onChange={this.onChangePathParams}
                    />
                );
            }
            if (targetUrl.includes('market/payment-reward/pages/detail/index?type=PAY_AWARD')) {
                return <div>{services.language.getText('zfyl')}</div>;
            }
        }
    };
    onChangePathParams = (value: any) => {
        const { editTemplate } = this.state;
        const newTempate = cloneDeep(editTemplate);
        if (value) {
            const targetUrl = newTempate.targetUrl?.includes('?')
                ? `${newTempate.targetUrl.split('?')[0]}&${searchStringify(value)}`
                : `${newTempate.targetUrl}?${searchStringify(value)}`;
            newTempate.targetUrl = targetUrl;
        }
        this.setState({
            editTemplate: newTempate,
        });
    };
    onTargetTypeChange = (val: string[]) => {
        const { editTemplate } = this.state;
        const newTempate = cloneDeep(editTemplate);
        if (val && val.length) {
            const targetUrl = val[val.length - 1];
            newTempate.targetType = SpecialType.ParameterConcatenation;
            newTempate.targetUrl = targetUrl;
        } else {
            newTempate.targetType = '';
            newTempate.targetUrl = '';
        }
        this.setState({
            editTemplate: newTempate,
        });
    };

    onHandleOtherChange = (name: string, value: string) => {
        const { editTemplate } = this.state;
        const newTempate = cloneDeep(editTemplate);
        const index = findIndex(newTempate.params, { name: name });
        if (index > -1) {
            set(newTempate.params, index, { ...newTempate.params[index], value: value });
        }
        this.setState({
            editTemplate: newTempate,
        });
    };
    onHandleSuccessAndFailChange = (name: string, type: string, value: string) => {
        const { editTemplate } = this.state;
        const newTempate = cloneDeep(editTemplate);
        const index = findIndex(newTempate.params, { name: name, fieldType: type });
        if (index > -1) {
            set(newTempate.params, index, { ...newTempate.params[index], value: value });
        }
        this.setState({
            editTemplate: newTempate,
        });
    };
    onInputBlur = (name: string) => {
        const { editTemplate } = this.state;
        const newTempate = cloneDeep(editTemplate);
        const index = findIndex(newTempate.params, { name: name });
        if (index > -1) {
            const currentItem = newTempate.params[index];
            if (!currentItem.value) {
                set(newTempate.params, index, {
                    ...newTempate.params[index],
                    value: currentItem.defaultValue,
                });
            }
        }
        this.setState({
            editTemplate: newTempate,
        });
    };
    onInputSuccessAndFailBlur = (name: string, type: string) => {
        const { editTemplate } = this.state;
        const newTempate = cloneDeep(editTemplate);
        const index = findIndex(newTempate.params, { name: name, fieldType: type });
        if (index > -1) {
            const currentItem = newTempate.params[index];
            if (!currentItem.value) {
                set(newTempate.params, index, {
                    ...newTempate.params[index],
                    value: currentItem.defaultValue,
                });
            }
        }
        this.setState({
            editTemplate: newTempate,
        });
    };
    render() {
        const { visible, closeModal } = this.props;
        return (
            <Modal
                destroyOnClose
                visible={visible}
                title={services.language.getText('bjmbxx')}
                width="1000px"
                onCancel={closeModal}
                onOk={this.handleSubmit}
            >
                <div className={prefix}>
                    {this.renderLeftWrap()}
                    {this.renderRightWrap()}
                </div>
            </Modal>
        );
    }
}

const prefix = 'message-notification-weapp-edit-modal';
const classes = {
    left: `${prefix}__left`,
    leftInfo: `${prefix}__left__info`,
    leftInfoTop: `${prefix}__left__info__top`,
    leftInfoTopName: `${prefix}__left__info__top__name`,
    leftInfoData: `${prefix}__left__info__data`,
    leftInfoDataContent: `${prefix}__left__info__data__content`,
    contentHeader: `${prefix}__left__info__data__content__header`,
    contentDianWrap: `${prefix}__left__info__data__content__dian-wrap`,
    contentDian: `${prefix}__left__info__data__content__dian`,
    contentCenter: `${prefix}__left__info__data__content__center`,
    contentOther: `${prefix}__left__info__data__content__other`,
    contentOtherItem: `${prefix}__left__info__data__content__other-item`,
    contentBottom: `${prefix}__left__info__data__content__bottom`,
    footer: `${prefix}__left__info__data__footer`,
    right: `${prefix}__right`,
    rightTitle: `${prefix}__right__title`,
};
