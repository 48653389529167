import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import { MarketingScheduleSelector } from './marketing-schedule-selector';
import { MarketingScheduleDisplay } from './marketing-schedule-display';

export class MarketingScheduleMode extends modes.ArrayMode {
    getDisplayComponent(value: any) {
        return <MarketingScheduleDisplay data={value || []} disabled={true} />;
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <MarketingScheduleSelector {...displayInfo} />;
    }
}
