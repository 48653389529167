import React, { PureComponent, createElement } from 'react';
import { Button, Modal, Upload, Icon, message } from 'antd';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { ExportButton } from '@comall-backend-builder/components-basis';
import { ForwardDataCenterModal } from '../../services';
import 'antd/dist/antd.css';
// import './index.less';

const { Dragger } = Upload;

interface Props {
    triggerComponent?: {
        component: string;
        config: Record<string, unknown>;
    };
    beforeShow?(): boolean | Promise<boolean>;
    row?: any;
    entity: any;
    params: {
        apiRoot?: string;
        apiPath: string;
        templateSource: {
            apiRoot?: string;
            apiPath: string;
        };
        specialTips?: string[];
        text: string;
    };
}
export class NewImportButtons extends PureComponent<
    Props,
    { fileList: any; showModal: boolean; statusMsg: string }
> {
    state = {
        fileList: [],
        showModal: false,
        statusMsg: services.language.getText('uploadFileClickButton'),
    };

    toggleModal = async () => {
        const { beforeShow } = this.props;
        const { showModal } = this.state;
        if (!showModal && beforeShow && (await beforeShow()) === false) {
            return;
        }
        this.setState({ showModal: !showModal, fileList: [] });
    };

    onImport = (importType: string) => {
        this.setState({
            statusMsg: '导入未完成之前,不要关闭页面,否则可能数据错误',
        });
        const { entity, params } = this.props;
        let { apiRoot, apiPath } = params;
        const { fileList } = this.state;
        const formData = new FormData();
        formData.append('files', fileList[0]);
        if (entity?.params?.id) {
            formData.append(
                'meta_data',
                JSON.stringify({ crowdId: entity.params.id, importStrategy: importType })
            );
        }
        services.api
            .upload(
                { files: formData },
                {
                    apiRoot,
                    apiPath,
                    fileName: 'files',
                    progressCallBack: () => {},
                    useCustomData: true,
                }
            )
            .then(() => {
                ForwardDataCenterModal();
                // @ts-ignore
                entity.search();
                this.toggleModal();
            })
            .catch((err) => {
                if (err) {
                    message.error(
                        err.response?.body?.err_msg || services.language.getText('uoloadFail')
                    );
                }
            });
    };

    handleOk = (importType: string) => {
        const { fileList } = this.state;
        if (fileList && fileList.length > 0) {
            this.onImport(importType);
        } else {
            message.error(services.language.getText('pleaseUploadFile'));
            return;
        }
    };

    private get disabled() {
        const { row } = this.props;
        let disabled = false;
        if (row?.canImport !== undefined) {
            disabled = !row.canImport;
        }
        return disabled;
    }

    private renderTrigger() {
        const {
            triggerComponent,
            params: { text },
        } = this.props;
        if (!triggerComponent) {
            return (
                <Button
                    disabled={this.disabled}
                    type="link"
                    className="btn-data-statistics"
                    onClick={this.toggleModal}
                >
                    {text}
                </Button>
            );
        }
        return createElement(ComponentsManager.get(triggerComponent.component), {
            onClick: this.toggleModal,
            ...triggerComponent.config,
        });
    }

    render() {
        const { showModal, fileList, statusMsg } = this.state;
        const {
            params: { text, templateSource, specialTips },
        } = this.props;
        const uploadProps: any = {
            accept: '.xlsx,.xls,.csv',
            multiple: false,
            onRemove: (file: any) => {
                this.setState({ fileList: [] });
            },
            beforeUpload: (file: any) => {
                this.setState({
                    fileList: [file],
                });

                return false;
            },
            fileList,
        };
        return (
            <div className="import-button-data-statistics">
                {this.renderTrigger()}
                <Modal
                    onCancel={this.toggleModal}
                    visible={showModal}
                    width={580}
                    title={text}
                    footer={[
                        <span key="span" className="import-button-status-msg">
                            {statusMsg}
                        </span>,
                        <Button
                            key="addSubmit"
                            type="primary"
                            onClick={() => this.handleOk('APPEND')}
                        >
                            新增导入
                        </Button>,
                        <Button
                            key="coverSubmit"
                            type="primary"
                            onClick={() => this.handleOk('COVER')}
                        >
                            覆盖导入
                        </Button>,
                        <Button key="back" onClick={this.toggleModal}>
                            {services.language.getText('back')}
                        </Button>,
                    ]}
                >
                    <Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">{services.language.getText('selectFile')}</p>
                        <p className="ant-upload-hint">
                            {services.language.getText('supportExtension')}.xlsx、.xls、.csv
                        </p>
                    </Dragger>
                    <div className="import-button-tips">
                        <p className="import-button-tips-text">
                            {services.language.getText('importRules')}
                        </p>
                        <p className="import-button-tips-text">
                            {services.language.getText('merchantBigCodeIntro1')}
                        </p>
                        <p className="import-button-tips-text" style={{ color: 'red' }}>
                            {services.language.getText('merchantBigCodeNote')}
                        </p>
                        <p className="import-button-tips-text">
                            2.当前仅支持xlxs、xls、csv（建议文件大小在20M以内且行数在10万以内，请使用Office
                            2010及以上版本，否则可能出现乱码
                        </p>
                        <p className="import-button-tips-text">3.请严格参考模版内的信息进行填写</p>
                        {specialTips &&
                            specialTips.length > 0 &&
                            specialTips.map((tip: string) => {
                                return <p className="import-button-tips-text">{tip}</p>;
                            })}
                    </div>
                    <div className="upload-tips">
                        <ExportButton
                            // @ts-ignore
                            type="link"
                            text="下载导入模板"
                            showErrorMessage={true}
                            source={{
                                apiRoot: templateSource.apiRoot,
                                apiPath: templateSource.apiPath,
                            }}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}
