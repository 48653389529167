import { Config } from '@comall-backend-builder/core/lib/parser';
import { ButtonProps } from 'antd/lib/button';
import { EntityButtonProps } from '../../../components';
import { services } from '@comall-backend-builder/core';

export const config: Config = {
    entities: {
        CustomCrowdEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
            apiPath: '/admin/crowds',
        },
        CustomCrowdBatchEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
            apiPath: '/loader/admin/crowd_members',
            properties: {
                creator: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
                createTime: {
                    type: 'string.date',
                    displayName: '<<czsj>>',
                },
                importStrategy: {
                    type: 'string',
                    displayName: '<<drfs>>',
                },
                result: {
                    type: 'string.options.select',
                    displayName: '<<czjg>>',
                },
                importResult: {
                    type: 'string',
                    displayName: '<<importResult>>',
                },
            },
        },
    },
    components: {
        CrowdManageView: {
            component: 'Viewport',
        },
        CrowdManageViewPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            style: {
                padding: '12px 32px 24px 32px',
            },
            items: [{ component: 'CrowdManageViewTabs' }],
        },
        CrowdManageViewTabs: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<zdyrq>>',
                    content: {
                        component: 'CustomCrowdPage',
                    },
                },
            ],
        },
        CustomCrowdAddPage: {
            component: 'Card',
            entity: 'CustomCrowdEntity',
            content: { component: 'CustomCrowdRuleForm' },
        },
        CustomCrowdEditPage: {
            component: 'Card',
            entity: 'CustomCrowdEntity',
            content: { component: 'CustomCrowdRuleForm' },
        },

        CustomCrowdBatchPage: {
            entity: 'CustomCrowdBatchEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'CustomCrowdBatchListHandler',
                        },
                    ],
                },
                { component: 'CustomCrowdBatchTable' },
            ],
        },
        CustomCrowdBatchListHandler: {
            component: 'GridLayout',
            style: {
                marginBottom: 16,
            },
            items: [
                {
                    type: 'component',
                    component: 'NewImportButtons',
                    params: {
                        apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
                        apiPath: '/admin/files/locations/8050/upload',
                        text: '<<drhy>>',
                        templateSource: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`,
                            apiPath: '/admin/tags/download_template',
                        },
                        specialTips: ['<<rxtmddsphfgzqdrdsp>>'],
                    },
                    triggerComponent: {
                        component: 'Button',
                        config: {
                            type: 'primary',
                            text: '<<drhy>>',
                        },
                    },
                },
            ],
        },
        CustomCrowdBatchTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'creator',
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'importStrategy',
                },
                {
                    property: 'result',
                },
                {
                    property: 'importResult',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                items: [
                    {
                        type: 'component',
                        component: 'EntityButton',
                        config: {
                            props: {
                                type: 'link',
                                disabled: (entityButtonProps: EntityButtonProps) =>
                                    !entityButtonProps.row.failFileUrl,
                                children: '<<dcsbqd>>',
                            },
                            propsHandler(
                                buttonProps: ButtonProps,
                                entityButtonProps: EntityButtonProps
                            ): ButtonProps {
                                return {
                                    ...buttonProps,
                                    download: '<<rqhypldrsbqd>>',
                                    href: entityButtonProps.row.failFileUrl,
                                };
                            },
                        },
                    },
                    {
                        type: 'component',
                        component: 'EntityButton',
                        config: {
                            props: {
                                type: 'link',
                                disabled: (entityButtonProps: EntityButtonProps) =>
                                    !entityButtonProps.row.importFileUrl,
                                children: '<<qbdc>>',
                            },
                            propsHandler(
                                buttonProps: ButtonProps,
                                entityButtonProps: EntityButtonProps
                            ): ButtonProps {
                                return {
                                    ...buttonProps,
                                    download: '<<rqhypldrsbqd>>',
                                    href: entityButtonProps.row.importFileUrl,
                                };
                            },
                        },
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/crowd-manage',
                    component: 'CrowdManageView',
                    breadcrumbName: '<<crowdManage>>',
                    privilege: {
                        path: 'memberv2Crowds',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'CustomCrowdAddPage',
                            breadcrumbName: '<<xzzdyrq>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'CustomCrowdEditPage',
                            breadcrumbName: '<<bjzdyrq>>',
                        },
                        {
                            path: '/batch-members/:id',
                            component: 'CustomCrowdBatchPage',
                        },
                        { path: '/', component: 'CrowdManageViewPage' },
                    ],
                },
            ],
        },
    ],
};
