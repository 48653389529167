import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { mapValues, defaults, get, filter } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import {
    checkBusinessNotifications,
    checkNotificationLink,
} from '../../config/member/caring/birthday-caring';
import { PushAndSiteMailSceneType } from '../../containers/wechat/common';

import './index.less';

export interface LotteryCodeAddFormProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
    pointRule: string;
    noticeType: string;
    rewardType: any;
    drawMethod: string;
}

class lotteryCodeAddFormView extends PureComponent<LotteryCodeAddFormProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }

    render() {
        const { entity, onSubmit, wrappedComponentRef } = this.props;
        let { pointRule, noticeType, rewardType, drawMethod } = this.props;
        if (!pointRule) {
            pointRule = 'NO';
        }
        if (!noticeType) {
            noticeType = 'OFFLINE';
        }
        if (!drawMethod) {
            drawMethod = 'AUTOMATIC';
        }
        const showTime = get(rewardType, '[0].checked') !== 'COUPON';

        let fields = [
            { property: 'baseInfo.name' },
            { property: 'baseInfo.dateRange' },
            { property: 'baseInfo.drawTime' },
            { property: 'baseInfo.drawMethod' },
            { property: 'baseInfo.subsiteIds' },
            { property: 'baseInfo.marketingSchedule' },
            { property: 'baseInfo.userWhiteListScope' },
            { property: 'baseInfo.noticeType' },
            {
                property: 'baseInfo.businessNotifications',
                controlConfig: {
                    scene: PushAndSiteMailSceneType.LOTTERY_CODE_AWARD_NOTICE,
                    isAdd: true,
                },
                className: noticeType === 'ONLINE' ? '' : 'property-hide',
            },
            { property: 'baseInfo.shareContent' },
            // { property: 'baseInfo.shareAssistContent' },
            { property: 'baseInfo.description' },
            { property: 'baseInfo.remark' },
            { property: 'numberInfo.activityRewardLimit' },
            { property: 'numberInfo.shareRewardLimit' },
            // { property: 'numberInfo.shareAssistLimit' },
            // { property: 'numberInfo.assistLimit' },
            { property: 'numberInfo.pointRule' },
            {
                property: 'numberInfo.point',
                className: pointRule === 'YES' ? '' : 'property-hide',
            },
            {
                property: 'numberInfo.pointExchangeLimit',
                className: pointRule === 'YES' ? '' : 'property-hide',
            },
            {
                property: 'numberInfo.refundPoint',
                className: pointRule === 'YES' ? '' : 'property-hide',
            },
            { property: 'prizeInfo.name' },
            { property: 'prizeInfo.type' },
            { property: 'prizeInfo.picture' },
            {
                property: 'prizeInfo.quantity',
                className: drawMethod === 'AUTOMATIC' ? '' : 'property-hide',
            },
            { property: 'prizeInfo.receiveRewardTime', className: showTime ? '' : 'property-hide' },
        ];

        let formProps = {
            entity,
            componentId: 'LotteryCodeAddFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'lottery-code-add-form-container',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            fields: fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: services.language.getText('qx'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('CreateForm'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;
    let form: any;
    let pointRule = get(
        _dispatch,
        'components.LotteryCodeAddFormContainer.fields.numberInfo.pointRule'
    );

    let noticeType = get(
        _dispatch,
        'components.LotteryCodeAddFormContainer.fields.baseInfo.noticeType'
    );
    let rewardType = get(_dispatch, 'components.LotteryCodeAddFormContainer.fields.prizeInfo.type');
    let drawMethod = get(
        _dispatch,
        'components.LotteryCodeAddFormContainer.fields.baseInfo.drawMethod'
    );
    /**
     * 提交前额外验证
     * @param entityFields
     */
    const validate = (entityFields: any) => {
        // const activityRewardLimit = get(entityFields, 'baseInfo.activityRewardLimit', null);
        // const shareRewardLimit = get(entityFields, 'baseInfo.shareRewardLimit', null);
        // if (activityRewardLimit === 0 && shareRewardLimit === 0) {
        //     AntMessage.warning(
        //         '初始赠送抽奖码数量与分享获得抽奖码上限不能同时设置为0，请检查后重新提交'
        //     );
        //     return false;
        // }
        if (pointRule === 'YES') {
            const point = get(entityFields, 'numberInfo.point', null);
            const pointExchangeLimit = get(entityFields, 'numberInfo.pointExchangeLimit', null);
            const reg = /^([1-9]\d*)$/;
            if (!point || point === '0' || !reg.test(point)) {
                AntMessage.warning(services.language.getText('qzqtxmccjxhjf'));
                return false;
            }
            if (
                !pointExchangeLimit ||
                pointExchangeLimit === '0' ||
                !reg.test(pointExchangeLimit)
            ) {
                AntMessage.warning(services.language.getText('qzqtxzddhcs'));
                return false;
            }
        }
        if (noticeType === 'ONLINE') {
            // const noticeChannels = get(entityFields, 'baseInfo.noticeChannels', null);
            // if (!noticeChannels || noticeChannels.length === 0) {
            //     AntMessage.warning(services.language.getText('qxztzfs'));
            //     return false;
            // }
            const businessNotifications = get(entityFields, 'baseInfo.businessNotifications', []);
            const selectedInfo = filter(businessNotifications, ['status', 1]);
            if (selectedInfo.length > 0) {
                for (let index = 0; index < selectedInfo.length; index++) {
                    const item = selectedInfo[index];
                    if (!checkBusinessNotifications(item)) {
                        return false;
                    }
                    if (!checkNotificationLink(item)) {
                        return false;
                    }
                }
            }
        }

        if (
            get(entityFields, 'prizeInfo.type[0].checked') === 'COUPON' &&
            !get(entityFields, 'prizeInfo.type[0].assetRewards[0].rewardValue.length')
        ) {
            AntMessage.warning(services.language.getText('qxzyhqjp'));
            return false;
        }

        if (
            drawMethod === 'AUTOMATIC' &&
            (entityFields.prizeInfo.quantity === undefined ||
                entityFields.prizeInfo.quantity === null)
        ) {
            AntMessage.warning(services.language.getText('quantityErrorMessage'));
            return false;
        }

        if (
            get(entityFields, 'prizeInfo.type[0].checked') !== 'COUPON' &&
            !entityFields.prizeInfo.receiveRewardTime
        ) {
            AntMessage.warning(services.language.getText('qxzljsj'));
            return false;
        }

        if (
            entityFields.prizeInfo.receiveRewardTime &&
            entityFields.prizeInfo.receiveRewardTime.start &&
            new Date(entityFields.prizeInfo.receiveRewardTime.start) <
                new Date(entityFields.baseInfo.drawTime)
        ) {
            AntMessage.warning(services.language.getText('ljsjbnzykjsj'));
            return false;
        }
        return true;
    };

    return defaults(
        {
            onSubmit,
            onFieldChange,
            pointRule,
            noticeType,
            rewardType,
            drawMethod,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: (event: any, fields: any) => {
                const entityFields = mapValues(fields, (field) => {
                    return field.value;
                });
                if (entityFields) {
                    const hasValidate = validate(entityFields);
                    if (hasValidate) {
                        entity.add(entityFields, params);
                    }
                }
            },
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('LotteryCodeAddFormContainer'));
            },
        }
    );
}
export const LotteryCodeAddFormView = connect(mapDispatchToProps)(lotteryCodeAddFormView);
