import React, { PureComponent, createElement } from 'react';
import { ComponentsManager } from '@comall-backend-builder/core';
import { defaults } from 'lodash';
import { connect } from 'react-redux';
import { message, Modal } from 'antd';
import { api, language } from '@comall-backend-builder/core/lib/services';
import { errorHandle } from '../../../services/error-handle';
/**
 * 重置密码
 */
class resetPasswordModal extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            confirmDirty: false,
            spinning: false,
        };
    }

    compareToFirstPassword = (rule: any, value: any, callback: any) => {
        const { getForm } = this.props;
        const form = getForm();
        if (value && value !== form.getFieldValue('password')) {
            callback(language.getText('lcmmsrbyz'));
        } else {
            callback();
        }
    };

    onSubmit = (event: any) => {
        event.stopPropagation();
        const { getForm, row, onOk } = this.props;

        const form = getForm();
        form.validateFields(['password', 'confirmPassword'], { force: true }).then(
            (entityFields: any) => {
                if (entityFields) {
                    api.get(
                        {
                            password: entityFields.password,
                        },
                        {
                            apiPath: `/open_api/password_strength/verify`,
                            apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
                        }
                    ).then((res: any) => {
                        if (res.ok) {
                            api.put(
                                { password: entityFields.password },
                                {
                                    apiPath: `/users/${row.id}/password`,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
                                }
                            )
                                .then(() => {
                                    message.success(language.getText('mmzzcg'), () => {
                                        onOk && onOk();
                                    });
                                })
                                .catch(errorHandle);
                        } else {
                            message.warning(res.msg);
                        }
                    });
                }
            }
        );

        return;
    };
    closeModal = () => {
        const { onCancel } = this.props;
        onCancel && onCancel();
    };
    renderForm = () => {
        const { wrappedComponentRef, entity } = this.props;

        let formProps = {
            entity,
            wrappedComponentRef: wrappedComponentRef,
            componentId: 'resetPasswordModal',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 14,
            fields: [
                { property: 'password', label: language.getText('xmm') },
                {
                    property: 'confirmPassword',
                    rules: [
                        {
                            required: true,
                            message: language.getText('qzcqrxmm'),
                        },
                        {
                            validator: this.compareToFirstPassword,
                        },
                    ],
                },
            ],
            submit: false,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        onClick: this.onSubmit,
                        text: language.getText('common.ok'),
                        type: 'primary',
                        style: { marginLeft: 10 },
                    },
                    {
                        htmlType: 'button',
                        onClick: this.closeModal,
                        text: language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmit: this.onSubmit,
        };
        return createElement(ComponentsManager.get('ModifyForm'), formProps);
    };
    render() {
        const { row, visible } = this.props;
        const modalProps = {
            width: 650,
            title: language.getText('zzmm'),
            visible: visible,
            footer: null,
            onCancel: this.closeModal,
        };
        return (
            <Modal {...modalProps}>
                <div className="form ant-form ant-form-horizontal">
                    <div className="ant-row ant-form-item">
                        <span className="ant-col ant-col-6 ant-form-item-label">
                            {language.getText('phoneNum')}：
                        </span>
                        <span
                            className="ant-col ant-col-14 ant-form-item-label"
                            style={{ textAlign: 'left' }}
                        >
                            {row.phone}
                        </span>
                    </div>
                    <div className="ant-row ant-form-item">
                        <span className="ant-col ant-col-6 ant-form-item-label">
                            {language.getText('dlzh')}：
                        </span>
                        <span
                            className="ant-col ant-col-14 ant-form-item-label"
                            style={{ textAlign: 'left' }}
                        >
                            {row.userName}
                        </span>
                    </div>
                    {this.renderForm()}
                </div>
            </Modal>
        );
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit } = props;
    let form: any;

    return defaults(
        {
            onSubmit,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
        }
    );
}
function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
        fields: entity.fields,
    };
}

export const ResetPasswordModal = connect(mapStateToProps, mapDispatchToProps)(resetPasswordModal);
