import { ObjectFormat } from '@comall-backend-builder/types';
import { language } from '@comall-backend-builder/core/lib/services';

/**
 *  会员生日营销，会员等级奖励类型校验
 */
export class BirthdayMemberLevelCouponRuleFormat extends ObjectFormat {
    /**
     * 对数据进行校验
     */
    public validate(rule: any, value: any, callback: any) {
        try {
            // if (!value || !value.memberLevel) {
            //     callback('请设置会员等级规则');
            //     return;
            // }
            // if (value.type === 'ALL' && !get(value, 'memberLevel[0].coupons.length', null)) {
            //     callback('请设置会员等级规则');
            //     return;
            // }
            callback();
        } catch (error) {
            callback(language.getText('validation.object.default'));
        }
    }
}
