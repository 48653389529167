import { isEmpty } from 'lodash';
import { services } from '@comall-backend-builder/core/';
import { subsites } from '../containers/page-design';

// 1、传门店首页/专题首页--只查出传入的门店下的拼团货品
// 2、传专柜首页 --能查出传入的门店+专柜下的拼团货品
// 3、传平台首页/不传--能查出所有拼团的货品

export const GroupProductLoader = {
    get: async function(data: any, config: any) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/GROUP-BUYING`;
        config.apiPath = '/admin/group_buying_rules/goods';
        if (data.productType === 'ALL') {
            delete data.productType;
        }
        if (!isEmpty(subsites)) {
            data.subSiteIds = subsites
                .map((subsite: any) => {
                    return subsite.id;
                })
                .join(',');
        }
        return services.api.get(data, config).then((res: any) => {
            res.result = res.result.map((item: any) => ({
                ...item,
                name: item.productName,
                isProduct: true,
            }));
            return res;
        });
    },
};
