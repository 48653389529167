import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        ManageOrderEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/couponOrder',
            filters: {},
            properties: {},
        },
    },
    components: {
        ManageOrderView: {
            component: 'Viewport',
            entity: 'ManageOrderEntity',
        },
        OrderManagePage: {
            component: 'Viewport',
        },

        ManageOrderDeliverPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ManageOrderDeliverForm',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/manage-order',
                    component: 'ManageOrderView',
                    breadcrumbName: '',
                    privilege: {
                        path: 'orderManager',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/deliver/:id',
                            component: 'ManageOrderDeliverPage',
                            breadcrumbName: '发货',
                        },
                        { path: '/', component: 'OrderManagePage' },
                    ],
                },
            ],
        },
    ],
};
