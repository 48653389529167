import React, { useEffect, useState } from 'react';
import { Popover, Button, message, Spin } from 'antd';

import './index.less';
import { services } from '@comall-backend-builder/core';

export const MiniprogramV2PreviewButton: React.FC<any> = (props) => {
    const { fileName, filePath, fileId } = props.row;
    const [visible, setVisible] = useState<boolean>(false);
    const [qrcode, setQrcodes] = useState<string>(filePath);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        if (filePath) {
            setLoading(false);
        }
    }, [filePath]);

    const isNeedRefeshFilePath = (fileId: null | number, qrcode: string, filePath?: string) => {
        return fileId === null && !qrcode && !filePath;
    };
    const onVisibleChange = (visible: boolean) => {
        if (visible) {
            if (isNeedRefeshFilePath(fileId, qrcode, filePath)) {
                // filepath 为空 手动触发  `/wechat/jhcode`，更新file_path zip包
                const params = {
                    page: 'subpackages/miniprogram-code/index',
                    type: 'MINI_PROGRAME_CODE',
                    scene: `mid=${props.row.id}`,
                    miniProgramCodeId: props.row.id,
                };
                const apiRoot = `${ENV.API_ROOT}`;
                setLoading(true);
                services.api
                    .get(params, {
                        apiRoot,
                        apiPath: `/wechat/jhcode`,
                    })
                    .then(({ image }: any) => {
                        setQrcodes('data:image/jpg;base64,' + image);
                        // filePath 字段更新与此接口不是同时更新，所以不能结束loading
                        // setLoading(false);
                    });
            } else if (!filePath) {
                setLoading(true);
            }
        }
        setVisible(visible);
    };

    const onRenderContent = () => {
        function handleClick(): void {
            message.info(services.language.getText('xcxmscz'));
        }

        const downloadElement = filePath ? (
            <Button
                className="span-link"
                style={{ padding: 0 }}
                type="link"
                href={filePath}
                download={fileName}
            >
                {services.language.getText('djxz')}
            </Button>
        ) : (
            <Button className="span-link" style={{ padding: 0 }} type="link" onClick={handleClick}>
                {services.language.getText('djxz')}
            </Button>
        );

        const imageUrl = /\.zip$/.test(filePath) ? './images/avatar/zip.png' : filePath;
        const imageElement = loading ? (
            <Spin className="mini-preview-content__qr-code" />
        ) : (
            <img
                className="mini-preview-content__qr-code"
                src={imageUrl}
                alt={services.language.getText('wxCode')}
            />
        );

        const popoverContentElement = (
            <div className="mini-preview-content">
                {imageElement}
                <p className="mini-preview-content__info">
                    {downloadElement}
                    {services.language.getText('xcxm')}
                </p>
            </div>
        );

        return popoverContentElement;
    };
    return (
        <Popover
            trigger="hover"
            content={onRenderContent()}
            visible={visible}
            onVisibleChange={onVisibleChange}
        >
            <Button
                type="link"
                style={{ padding: 0 }}
                className="table-action-column-item span-link"
            >
                {services.language.getText('xcxm')}
            </Button>
        </Popover>
    );
};
