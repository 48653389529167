import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { Button as AntButton, Form, message, Spin, Modal, Input } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { api, errorHandle, navigation } from '@comall-backend-builder/core/lib/services';
import { isNil, forEach } from 'lodash';
import { strip, plus } from 'number-precision';
import { ReceiptInfo } from '../../../services/order-print';
import { OrderSelector } from './selector-order';
import { OrderInfos } from './order-info';
import { GoodsInfo } from './goods';
import { OrderFlowRule } from './order-flow';
import { CGBConfigSelectSubsite } from '../../../containers';
import { PaymentResult } from './order-flow-selector';
import './index.less';
import { services } from '@comall-backend-builder/core';

const { getText } = services.language;

export interface OnlineOrderReviewProps {
    id: string | number;
    row: any;
    entity: Entity;
    params: any;
    form: FormComponentProps['form'];
}

interface OnlineOrderReviewState {
    loading: boolean;
    paymentResults: Array<PaymentResult>;
    orderInfo?: ReceiptInfo;
    goods: Array<any>;
    smsCode: string;
    visible: boolean;
    isViewPage: boolean;
    sendMsgDisabled: boolean;
    submitLoading: boolean;
    paymentReceipts: any;
}

class OnlineOrderReview extends PureComponent<OnlineOrderReviewProps, OnlineOrderReviewState> {
    state: OnlineOrderReviewState = {
        loading: false,
        paymentResults: [],
        orderInfo: undefined,
        goods: [],
        smsCode: '',
        visible: false,
        isViewPage: false,
        sendMsgDisabled: false,
        submitLoading: false,
        paymentReceipts: null,
    };
    componentDidMount() {
        this.getData();
    }

    getData = () => {
        const {
            params: { id },
        } = this.props;
        if (!id) {
            return;
        }
        this.setState({ loading: true, isViewPage: true });
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                apiPath: `/admin/offline_payment_bill/manual_review_records/${id}`,
            }
        )
            .then(async (data: any) => {
                await this.getOrderInfo(data.orderId);
                this.setState({ loading: false, paymentResults: data.offlinePaymentBills });
            })
            .catch((error) => {
                this.setState({ loading: false });
                errorHandle(error);
            });
    };
    handleSubmit = (e: any) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.judgmentRule(values);
            }
        });
    };
    judgmentRule = (values: any) => {
        const { paymentResults } = this.state;
        let orderInfo: any = this.state.orderInfo;
        if (!orderInfo || !orderInfo?.id) {
            message.error(getText('qxzdd'));
            return;
        }
        if (!paymentResults || !paymentResults?.length) {
            message.error(getText('qszxxzfjylsh'));
            return;
        }
        const errorArr = paymentResults.filter((i) => isNil(i.paidAmount));
        if (errorArr?.length) {
            message.error(getText('qszxxzfjylshje'));
            return;
        }

        let totalAmount = 0;
        forEach(paymentResults, (item) => {
            totalAmount = plus(totalAmount, item.paidAmount);
        });
        const payableAmount = Number(orderInfo?.paymentInfo?.payableAmount || 0);
        if (strip(totalAmount) !== strip(payableAmount)) {
            message.error(getText('ddjeyxxzfjylsjebpp'));
            return;
        }
        this.sendMessage();
    };
    sendMessage = () => {
        let orderInfo: any = this.state.orderInfo;
        let subsiteId = orderInfo?.subsite?.id;
        if (!subsiteId) {
            return;
        }
        this.setState({ sendMsgDisabled: true });
        api.post(
            { subsiteId },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                apiPath: `/admin/sms/OFFLINE_PAYMENT_BILL_MANUAL_REVIEW_SMS/backend_send`,
            }
        )
            .then(() => {
                this.setState({ visible: true, sendMsgDisabled: false });
            })
            .catch((error) => {
                this.setState({ sendMsgDisabled: false });
                errorHandle(error);
            });
    };
    /**
     * 处理新增接口数据格式
     * @param params
     */
    dataParser = () => {
        const { paymentResults, smsCode } = this.state;
        const {
            form: { getFieldValue },
        } = this.props;
        let orderInfo: any = this.state.orderInfo;
        const subsite = getFieldValue('subsites') ? getFieldValue('subsites')[0] : {};
        const payableAmount = Number(orderInfo?.paymentInfo?.payableAmount || 0);
        let newParams = {
            businessType: 'STORED_VALUE_CARD_SALES_ORDER',
            outTradeNos:
                paymentResults?.map((item) => {
                    return item.outTradeNo;
                }) || [],
            paymentMode:
                orderInfo?.paymentInfo?.paymentModeType === 7
                    ? 'CHINA_MERCHANTS_BANK_TRANS_FER_PAY'
                    : '',
            orderNo: orderInfo?.orderNo,
            smsCode,
            subsiteId: subsite?.id,
            subsiteCode: subsite?.code,
            subsiteName: subsite?.name,
            payableAmount,
        };
        return newParams;
    };

    onSelectorSubsiteChange = (subsite: any) => {
        const {
            form: { getFieldValue, setFieldsValue },
        } = this.props;
        let subSiteId = getFieldValue('subsites') ? getFieldValue('subsites')[0].id : '';
        let nexSubSiteId = subsite?.length ? subsite[0].id : '';
        if (subSiteId !== nexSubSiteId) {
            this.setState({
                orderInfo: undefined,
                goods: [],
                paymentResults: [],
            });
            setFieldsValue({ order: [] });
        }
    };

    onSelectorOrderChange = (order: any) => {
        const {
            form: { getFieldValue },
        } = this.props;
        let orderId = getFieldValue('order')?.length ? getFieldValue('order')[0].id : '';
        let nexOrderId = order?.length ? order[0].id : '';
        if (!order || !order?.length) {
            this.setState({ orderInfo: undefined, goods: [], paymentResults: [] });
            return;
        }
        if (orderId !== nexOrderId) {
            this.setState(
                {
                    orderInfo: undefined,
                    goods: [],
                    paymentResults: [],
                },
                () => {
                    this.getOrderInfo(order[0].id);
                }
            );
        } else {
            this.getOrderInfo(order[0].id);
        }
    };
    getOrderInfo = (orderId: string) => {
        const { isViewPage } = this.state;
        api.get<any>(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`,
                apiPath: ` /admin/orders/${orderId}`,
            }
        )
            .then((data) => {
                const goods =
                    data?.orderItems?.map((item: any) => {
                        item.name = item.goods.name || item.goods.productName || '';
                        item.marketPrice = `${item.goods.marketPrice}${getText('mz_1')}`;
                        return item;
                    }) || [];
                this.setState({ orderInfo: data, goods }, () => {
                    if (!isViewPage) {
                        this.getOrderPaymentReceipts(data.orderNo);
                    }
                });
            })
            .catch((error) => {
                this.setState({ loading: false });
                errorHandle(error);
            });
    };
    getOrderPaymentReceipts = (orderNo: string) => {
        const { paymentResults } = this.state;
        api.get<any>(
            {
                businessType: 'STORED_VALUE_CARD_SALES_ORDER',
                orderNo,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                apiPath: `/admin/payment_receipts`,
            }
        ).then((data) => {
            const list = data?.offlinePaymentBills || [];
            const newList = list.concat(paymentResults);
            this.setState({ paymentResults: newList, paymentReceipts: data });
        });
    };

    onRulesChange = (value: Array<PaymentResult>) => {
        this.setState({ paymentResults: value });
    };
    onInputChange = (e: any) => {
        this.setState({ smsCode: e.target.value });
    };
    onCancel = () => {
        this.setState({ visible: false, smsCode: '', submitLoading: false });
    };
    onOk = () => {
        const { smsCode } = this.state;
        if (!smsCode) {
            message.error(getText('qsrdxyzm'));
            return;
        }
        this.setState({ submitLoading: true });
        const params = this.dataParser();
        api.post(
            { ...params },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                apiPath: `/admin/offline_payment_bill/manual_review_records`,
            }
        )
            .then(() => {
                message.success(getText('fhcg_1'));
                this.onCancel();
                setTimeout(() => {
                    navigation.goBack();
                });
            })
            .catch((error) => {
                this.setState({ submitLoading: false });
                errorHandle(error);
            });
    };
    renderMessageModal = () => {
        const { visible, smsCode, submitLoading } = this.state;
        return (
            <Modal
                title={getText('dxyzmhy')}
                width={600}
                visible={visible}
                className="offline-order-modal"
                onCancel={this.onCancel}
                confirmLoading={submitLoading}
                onOk={this.onOk}
            >
                <div className="form">
                    <div className="form__item">
                        <div className="form__item-label">{getText('qsrnsddyzm')}</div>
                        <Input
                            onChange={this.onInputChange}
                            className="form__item-input"
                            value={smsCode}
                            maxLength={6}
                        ></Input>
                    </div>
                </div>
            </Modal>
        );
    };
    renderContent = () => {
        const { isViewPage } = this.state;
        if (isViewPage) {
            return this.renderViewContent();
        }
        return this.renderForm();
    };
    renderViewContent = () => {
        const { isViewPage, goods, paymentResults } = this.state;
        let orderInfo: any = this.state.orderInfo;
        if (isViewPage) {
            return (
                <div className="offline-order-review-form" style={{ width: '100%' }}>
                    <div className="subsite-name">
                        {getText('subsites')}：{orderInfo?.subsite?.name}
                    </div>
                    <div className="form-title">{getText('ddxx')}</div>
                    <div className="title">{getText('ddgyxx')}</div>
                    <OrderInfos data={orderInfo} />
                    <div className="title">{getText('hpxx')}</div>
                    <GoodsInfo data={goods} />
                    <div className="form-title mt10">{getText('xxzfjylsh')}</div>
                    <OrderFlowRule
                        isViewPage={isViewPage}
                        value={paymentResults}
                        onChange={this.onRulesChange}
                    />
                    <div className="footer-btn">
                        <AntButton onClick={navigation.goBack}>
                            {services.language.getText('back')}
                        </AntButton>
                    </div>
                </div>
            );
        }
    };
    renderForm = () => {
        const { paymentResults, orderInfo, goods, isViewPage, sendMsgDisabled } = this.state;
        const {
            form: { getFieldDecorator, getFieldValue },
        } = this.props;
        let subSiteId = getFieldValue('subsites') ? getFieldValue('subsites')[0].id : '';
        const subsiteProps = {
            name: 'subsites',
            type: 'radio',
            value: getFieldValue('subsites'),
            ...this.props,
        };
        const selectValues = getFieldValue('order') ? getFieldValue('order') : [];
        return (
            <Form
                onSubmit={this.handleSubmit}
                className="offline-order-review-form"
                style={{ width: '100%' }}
            >
                <Form.Item label={services.language.getText('otherSelectSubsite')}>
                    {getFieldDecorator('subsites', {
                        rules: [
                            {
                                required: true,
                                message: getText('selectSubsite'),
                            },
                        ],
                    })(
                        <CGBConfigSelectSubsite
                            {...subsiteProps}
                            onChange={this.onSelectorSubsiteChange}
                        />
                    )}
                </Form.Item>
                <Form.Item label={getText('xzdd_1')}>
                    {getFieldDecorator('order', {
                        rules: [
                            {
                                required: true,
                                message: getText('qxzdd'),
                            },
                        ],
                    })(
                        <OrderSelector
                            selectValues={selectValues}
                            subsiteId={subSiteId}
                            onChange={this.onSelectorOrderChange}
                        />
                    )}
                </Form.Item>
                <div className="form-title">{getText('ddxx')}</div>
                <div className="title">{getText('ddgyxx')}</div>
                <OrderInfos data={orderInfo} />
                <div className="title">{getText('hpxx')}</div>
                <GoodsInfo data={goods} />
                <div className="form-title mt10">{getText('xxzfjylsh')}</div>
                <OrderFlowRule
                    isViewPage={isViewPage}
                    value={paymentResults}
                    onChange={this.onRulesChange}
                    orderInfo={orderInfo}
                />
                <Form.Item className="footer-btn pdt20">
                    <AntButton
                        disabled={sendMsgDisabled}
                        type="primary"
                        htmlType="submit"
                        style={{ marginRight: 10 }}
                    >
                        {getText('zffh')}
                    </AntButton>
                    <AntButton onClick={navigation.goBack}>{getText('qx')}</AntButton>
                </Form.Item>
            </Form>
        );
    };
    render() {
        const { loading } = this.state;
        return (
            <Fragment>
                <Spin spinning={loading}>{this.renderContent()}</Spin>
                <div>{this.renderMessageModal()}</div>
            </Fragment>
        );
    }
}
function mapStateToProps(state: OnlineOrderReviewState, props: OnlineOrderReviewProps) {
    const { entity, row, params, id } = props;
    return {
        id,
        entity,
        row,
        params,
    };
}
export const OnlineOrderReviewForm = connect(mapStateToProps)(Form.create()(OnlineOrderReview));
