import React, { PureComponent } from 'react';
import { Button } from 'antd';
import { SelectPage } from './select-page';
import { services } from '@comall-backend-builder/core';
const language = services.language;

export class MallActivityPageSelector extends PureComponent<
    {
        value: any;
        contentConfig: Record<string, any>;
        modalConfig: Record<string, any>;
        onChange: (value: any) => void;
    },
    { visibleModal: boolean }
> {
    state = {
        visibleModal: false,
    };

    showModal = () => {
        this.setState({ visibleModal: true });
    };
    hideModal = () => {
        this.setState({ visibleModal: false });
    };
    onChange = (value: any) => {
        const { onChange } = this.props;
        this.hideModal();
        onChange(value);
    };

    render() {
        const {
            value: { linkParams },
            contentConfig,
            modalConfig,
        } = this.props;

        const { visibleModal } = this.state;
        const hasSingleTarget = linkParams && linkParams.name;
        const selectProps = {
            value: linkParams,
            onChange: this.onChange,
        };
        // 海信目前可视化做成 不传id拉取可视化页面
        return ENV.TENANT === 'hxgc' || ENV.TENANT === 'localhost' ? (
            <span>商场活动页</span>
        ) : (
            <span className="link-name">
                {hasSingleTarget ? (
                    <span className="link-name-label">{linkParams.name}</span>
                ) : (
                    '未选择'
                )}
                <Button type={'link'} onClick={this.showModal} className="link-type-edit">
                    {hasSingleTarget
                        ? language.getText('components.Tools.modify')
                        : language.getText('components.Tools.select')}
                </Button>
                <SelectPage
                    contentConfig={contentConfig}
                    modalConfig={modalConfig}
                    showModal={visibleModal}
                    onCancel={this.hideModal}
                    {...selectProps}
                />
            </span>
        );
    }
}
