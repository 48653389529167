import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { ServiceFacilities } from './service-facilities';

export class ServiceFacilitiesMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <ServiceFacilities {...controlInfo} />;
    }
}
