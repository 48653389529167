import React, { Component } from 'react';
import { InputNumber, Radio } from 'antd';
import './index.less';
import { services } from '@comall-backend-builder/core';

interface Props {
    onChange: (value: PayWithGiftsLimitsValue) => void;
    value: PayWithGiftsLimitsValue;
    disabled: boolean;
}

interface PayWithGiftsLimitsValue {
    type: string;
    dayLimitCount?: number;
    activityLimitCount?: number;
}

export class PayWithGiftsLimits extends Component<Props, unknown> {
    componentDidMount() {
        const { value, onChange } = this.props;
        if (!value) {
            const defalutValue = {
                type: '-1',
            };
            onChange(defalutValue);
        }
    }

    changeValue = (inputValue: number, type: string) => {
        const { value, onChange } = this.props;
        if (type === 'dayLimitCount') {
            value.dayLimitCount = inputValue;
        } else if (type === 'activityLimitCount') {
            value.activityLimitCount = inputValue;
        }
        onChange(value);
    };

    changeLimits = (e: any) => {
        const { value, onChange } = this.props;
        const type: string = e.target.value;
        value.type = type;
        if (type === '-1') {
            value.dayLimitCount = undefined;
            value.activityLimitCount = undefined;
        } else if (type === '0') {
            value.activityLimitCount = undefined;
        } else if (type === '1') {
            value.dayLimitCount = undefined;
        }
        onChange(value);
    };

    render() {
        const { value, disabled } = this.props;
        if (!value) {
            return null;
        }
        return (
            <div className="pay-with-gifts-limits">
                <Radio.Group onChange={this.changeLimits} value={value.type} disabled={disabled}>
                    <div className="item">
                        <Radio value="-1">不限制</Radio>
                    </div>
                    <div className="item">
                        <Radio value="0">
                            每人每天发放&nbsp;&nbsp;
                            <InputNumber
                                disabled={disabled || value.type !== '0' ? true : false}
                                placeholder={services.language.getText('inputPlease')}
                                value={value.dayLimitCount}
                                min={1}
                                max={999999}
                                precision={0}
                                onChange={(value: any) => this.changeValue(value, 'dayLimitCount')}
                            ></InputNumber>
                            <span className="item-count">次</span>
                        </Radio>
                    </div>
                    <div className="item">
                        <Radio value="1">
                            {services.language.getText('mrzhdqjff')}&nbsp;&nbsp;
                            <InputNumber
                                disabled={disabled || value.type !== '1' ? true : false}
                                placeholder={services.language.getText('inputPlease')}
                                value={value.activityLimitCount}
                                min={1}
                                max={999999}
                                precision={0}
                                onChange={(value: any) =>
                                    this.changeValue(value, 'activityLimitCount')
                                }
                            ></InputNumber>
                            <span className="item-count">次</span>
                        </Radio>
                    </div>
                </Radio.Group>
            </div>
        );
    }
}
