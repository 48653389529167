import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import isEmpty from 'lodash/isEmpty';

const api = services.api;

export const CouponRulesDetailReportLoader: Loader = {
    async get(data, config: ApiRequestConfig) {
        const type = data.type;
        delete data.type;
        if (data.couponStatus === 'ALL') {
            data = {
                ...data,
                couponStatus: undefined,
            };
        }

        if (data.exchangeStatus === 'ALL') {
            data = {
                ...data,
                exchangeStatus: undefined,
            };
        }
        data.couponRuleId = data.id;
        if (!isEmpty(data.dateRange)) {
            data.bindingStartTime = data.dateRange.start + ' 00:00:00';
            data.bindingEndTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        if (!isEmpty(data.couponDetailsGuideFilter)) {
            data.sourceType =
                data.couponDetailsGuideFilter && data.couponDetailsGuideFilter.type
                    ? data.couponDetailsGuideFilter.type
                    : undefined;
            data.guideGiveCouponKeywords =
                data.couponDetailsGuideFilter && data.couponDetailsGuideFilter.keyword
                    ? data.couponDetailsGuideFilter.keyword
                    : undefined;
            delete data.couponDetailsGuideFilter;
        }
        return api
            .get(data, {
                ...config,
                apiPath: `/admin/${type}_coupons`,
            })
            .then((res: any) => {
                res.result = res.result.map((item: any) => {
                    return {
                        ...item,
                        sentSubSiteName: item.sentSubSiteName || '-',
                        sentMerchantName: item.sentMerchantName || '-',
                        sentStaffName: item.sentStaffName || '-',
                        transferMobile: item.transferMobile || '-',
                    };
                });
                return res;
            });
    },
};
