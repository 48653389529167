import React, { PureComponent } from 'react';
import { SelectAutoComplete } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core';
import { get, map, forEach } from 'lodash';
import { message as AntMessage } from 'antd';

const api = services.api;

/**
 * 支持添加编辑配置联动和自动匹配的下拉多选
 */
export class ArrayOptionsAutoCompleteStoreCardLevels extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        const dependencesValue = this.getDependencesValue(props);
        this.state = {
            // 当前的属性名
            name: props.name,
            // 依赖项的值
            dependencesValue: dependencesValue,
            //是否展示需要依赖的提示语
            showNeedDependencesMessage: true,
            //下拉框options值
            options: [],
        };
    }

    getDependencesValue = (props: any) => {
        const { row } = props;
        let dependencesValue = row && get(row, 'subsiteId');
        return dependencesValue;
    };

    componentDidMount() {
        this.loadFirstVisible();
    }

    loadFirstVisible = () => {
        const nextDependencesValue = this.getDependencesValue(this.props);
        if (nextDependencesValue && nextDependencesValue.length > 0) {
            this.setState(
                {
                    options: [],
                    dependencesValue: nextDependencesValue,
                    showNeedDependencesMessage: false,
                },
                () => {
                    this.loadOptions(nextDependencesValue);
                }
            );
        }
    };

    // 当props发生变化时，更新待选项数据
    componentWillReceiveProps(nextProps: any) {
        const { name, onChange } = nextProps;
        const { dependencesValue } = this.state;
        const nextDependencesValue = this.getDependencesValue(nextProps);
        let showNeedDependencesMessage = false;
        // 如果props变更后，依赖项有值
        if (nextDependencesValue) {
            if (dependencesValue !== nextDependencesValue) {
                if (nextDependencesValue.length === 0) {
                    this.clearValue(nextProps);
                    return;
                }
                //清空已选择的值
                if (dependencesValue) {
                    if (onChange) {
                        onChange(null, name);
                    }
                }
                //重新请求options
                this.setState(
                    {
                        options: [],
                        dependencesValue: nextDependencesValue,
                        showNeedDependencesMessage: showNeedDependencesMessage,
                    },
                    () => {
                        this.loadOptions(nextDependencesValue);
                    }
                );
            }
        } else if (dependencesValue) {
            this.clearValue(nextProps);
        }
    }

    clearValue = (nextProps: any) => {
        const { name, onChange } = nextProps;
        const { dependencesValue } = this.state;
        if (dependencesValue) {
            //清空已选择的值
            if (onChange) {
                onChange(null, name);
            }
        }
        // 清楚保存的依赖项的值
        this.setState({
            dependencesValue: null,
            options: [],
            showNeedDependencesMessage: true,
        });
    };

    loadOptions = (dependencesValue: any) => {
        const subsiteIds = dependencesValue ? map(dependencesValue, 'id') : [];
        const params = {
            subsiteIds: subsiteIds.join(','),
            page: 1,
            perPage: 100,
            status: 'ENABLE',
        };
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
            apiPath: '/admin/store_card_levels/legal',
        };
        api.get(params, config).then((res: any) => {
            const result: Array<any> = [];
            forEach(res.result, (level: any) => {
                result.push({
                    id: level.cardLevelId,
                    name: level.name,
                });
            });
            this.setState({
                options: result,
            });
        });
    };

    handleClick = () => {
        const { showNeedDependencesMessage } = this.state;
        if (showNeedDependencesMessage) {
            AntMessage.warning(services.language.getText('selectSubsite'));
            return false;
        }
    };

    render() {
        const props: any = this.props;
        let { options, showNeedDependencesMessage } = this.state;
        if (props.disabled) {
            showNeedDependencesMessage = true;
        }
        return (
            <div onClick={this.handleClick}>
                <SelectAutoComplete
                    mode="multiple"
                    {...props}
                    disabled={showNeedDependencesMessage}
                    options={options}
                />
            </div>
        );
    }
}
