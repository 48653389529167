import React, { PureComponent } from 'react';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { DesignPage } from '@comall-backend-builder/design-components-basis';
import { CustomComponentSelectorMember } from '../../applications/design/design/components/custom-component-selector-member';
import { get, find, forEach } from 'lodash';
import { DESIGN_PAGE_LINK_TYPES_LIST } from '../../applications/design/constants/content-page-design';
const api = services.api;

const tenantDomain = ENV.TENANT_DOMAIN || window.location.origin;

/**
 * 默认会员中心配置
 */
const defaultMemberCenterProfile = {
    metainfo: {
        title: '',
        default: false,
        type: 'member',
    },
    components: {
        'member-card': {
            component: 'memberCard',
            bgImage: null,
            level: 'show',
            code: 'show',
            identity: 'hide',
        },
        'member-assets': {
            component: 'memberAssets',
            showContent: ['balance', 'integral', 'coupon'],
        },
        'member-order': {
            component: 'memberOrder',
        },
        'member-distribution': {
            component: 'memberDistribution',
            title: '分销中心',
            secondary: '查看结算金额',
        },
        'member-group': {
            component: 'memberGroup',
            title: '社区团购',
            secondary: '查看结算金额',
        },
        'member-service': {
            component: 'memberService',
            title: '服务',
            entry: [
                { name: 'couponOrder', title: '卡券订单', checked: true },
                { name: 'group', title: '社区团购', checked: true },
                { name: 'group.order', title: '拼团订单', checked: true },
                { name: 'identification', title: '会员认证', checked: true },
                { name: 'feedback', title: '意见反馈', checked: true },
                { name: 'invoice', title: '发票开立', checked: true },
                { name: 'favorite', title: '收藏', checked: true },
                { name: 'coupon', title: '优惠券', checked: true },
                { name: 'consignee', title: '收货地址', checked: true },
                { name: 'services', title: '在线客服', checked: true },
                { name: 'help', title: 'bzzx', checked: true },
                // { name: 'invitation', title: '邀请有礼', checked: true },
                { name: 'membership', title: '会员权益', checked: true },
                { name: 'parking', title: '停车缴费', checked: true },
                { name: 'activity', title: '我的活动', checked: true },
                { name: 'creditEshop', title: '积分换礼', checked: true },
                { name: 'birthdayCaring', title: 'sryl', checked: true },
                { name: 'relegation', title: '保级查询', checked: true },
                { name: 'selfCrediting', title: '自助积分', checked: true },
                { name: 'serviceReservedOrderList', title: '我的预约', checked: true },
                // { name: 'coupon.couponConsume', title: '导购核销', checked: true },
                { name: 'annualWelfare', title: '年终回馈', checked: true },
            ],
        },
        'persional-profile': {
            component: 'persionalProfile',
        },
    },

    root: {
        share: {
            title: '',
            description: '',
            pic: '',
        },
        backgroundColor: '#f6f6f6',
        items: [
            { component: 'member-card' },
            { component: 'member-assets' },
            { component: 'member-order' },
            // { component: 'member-distribution' },
            // { component: 'member-group' },
            { component: 'member-service' },
        ],
    },
};

/**
 * 可视化--会员中心页设计器
 */
export class DesignContentMemberCenter extends PureComponent<any> {
    loadProfile = () => {
        const {
            params: { id },
            apiPath,
        } = this.props;
        return api.get({ id }, { apiPath: apiPath }).then((res: any) => {
            if (!res.config) {
                return {
                    ...res,
                    config: JSON.stringify(defaultMemberCenterProfile),
                    type: 'member',
                };
            }

            let config = JSON.parse(res.config);
            if (config.root && typeof config.root.backgroundColor !== 'string') {
                config.root.backgroundColor = config.root.backgroundColor.color || '#f6f6f6';
            }
            let items = get(config, 'components.member-service.entry', undefined);
            if (items) {
                const memberServiceEntry = get(
                    defaultMemberCenterProfile,
                    'components.member-service.entry'
                );
                let additionalContents: any = [];
                forEach(memberServiceEntry, (additionalItem) => {
                    if (!find(items, (item) => item.name === additionalItem.name)) {
                        additionalContents.push({ ...additionalItem, checked: false });
                    }
                });
                items.push(...additionalContents);
            }
            res.config = JSON.stringify(config);

            return res;
        });
    };
    onSave = (data: any) => {
        const { apiPath } = this.props;
        api.put(data, { apiPath: apiPath }).then(() => {
            const saveSuccess = services.language.getText('common.saveSuccess');
            message.success(saveSuccess);
            services.navigation.goBack();
        });
    };
    onCancel = () => {
        services.navigation.goBack();
    };

    getClientUrl = (profile: any, fixed: boolean) => {
        const { client } = this.props;
        let url = client + `?page=WYSIWG&mode=edit`;
        if (profile) {
            url = url + `&type=content&fixed=false&tenant=${encodeURIComponent(tenantDomain)}`;
        }

        return url;
    };
    render() {
        const submitProps = {
            onClick: this.onSave,
        };
        const cancelProps = {
            onClick: this.onCancel,
        };
        return (
            <DesignPage
                clientUrl={this.getClientUrl}
                loadProfile={this.loadProfile}
                componentsSelector={(props) => <CustomComponentSelectorMember {...props} />}
                submit={submitProps}
                cancel={cancelProps}
                isTemplate={false}
                linkTypes={DESIGN_PAGE_LINK_TYPES_LIST}
                verbose={process.env.NODE_ENV === 'development'}
                linkTypeMode={'cascader'}
                uploadAction={`${ENV.API_ROOT}/admin/images/upload`}
            ></DesignPage>
        );
    }
}
