import React, { createRef, PureComponent } from 'react';
import { cloneDeep } from 'lodash';
import { RewardSettingDisplay, RewardSettingForm, Reward } from '../../components';
import { Entity } from '@comall-backend-builder/core/lib/parser';

export interface RewardSettingProps {
    onChange: (data: Array<Reward>) => void;
    /**
     * 当前值
     */
    value: Array<Reward>;
    /**
     * 是否禁用
     */
    disabled?: boolean;

    row: any;

    eventType: string;
    /**
     * 当前活动是否开启
     */
    isOpen?: boolean;
    /**
     * 当前活动时间是否已到
     */
    isBegin?: boolean;
    /**
     * 当前活动状态
     */
    entity: Entity;
    /**
     * 当前活动状态
     */
    executeStatus: string;
}

export class RewardSetting extends PureComponent<RewardSettingProps> {
    state = {
        formEditable: true,
    };
    formRef = createRef<any>();

    onChange = (data: Array<Reward>) => {
        const { onChange } = this.props;
        data = data.map((item: any, idx: number) => ({ ...item, sequence: idx + 1 }));
        onChange(data);
    };

    onAdd = (item: Reward) => {
        const { value, eventType } = this.props;
        const newData: Array<Reward> = cloneDeep(value || []);
        if (eventType === 'MALL_ACTIVITY') {
            if (item.type.checked === 'CUSTOM') {
                this.onChange([]);
            } else {
                this.onChange([item]);
            }
        } else {
            newData.push(item);
            this.onChange(newData);
        }
    };

    handleEditStart = (item: Reward) => {
        if (!this.formRef.current) {
            return;
        }
        this.setState({ formEditable: true });

        this.formRef.current.open(item);
    };
    handleViewStart = (item: Reward) => {
        if (!this.formRef.current) {
            return;
        }
        this.setState({ formEditable: false });

        this.formRef.current.open(item);
    };

    private onSettingFormClose = () => {
        this.setState({ formEditable: true });
    };

    handleEditEnd = (item: Reward) => {
        const { value } = this.props;
        const newValue = value.map((reward: any) => {
            if (reward.sequence === item.sequence) {
                return { ...reward, ...item };
            }
            return reward;
        });
        this.onChange(newValue);
    };

    clearValue = () => {
        this.onChange([]);
    };

    render() {
        const {
            value,
            disabled,
            row,
            eventType,
            isOpen,
            isBegin,
            entity: { fields = {} },
        } = this.props;
        const { formEditable } = this.state;
        const { editExecuteStatus } = fields as Record<string, any>;
        const hasValue = value && value.length > 0;
        const activityId = row ? row.id : 0;

        return (
            <div>
                <div hidden={disabled}>
                    <RewardSettingForm
                        ref={this.formRef}
                        onAdd={this.onAdd}
                        onEdit={this.handleEditEnd}
                        data={value}
                        eventType={eventType}
                        row={row}
                        executeStatus={editExecuteStatus}
                        isOpen={isOpen}
                        isBegin={isBegin}
                        editable={formEditable}
                        onClose={this.onSettingFormClose}
                        clearValue={this.clearValue}
                    />
                </div>

                {hasValue && (
                    <RewardSettingDisplay
                        onChange={disabled ? () => {} : this.onChange}
                        onEdit={this.handleEditStart}
                        onView={this.handleViewStart}
                        data={value}
                        disabled={disabled}
                        activityId={activityId}
                        eventType={eventType}
                        executeStatus={editExecuteStatus}
                        isOpen={isOpen}
                        isBegin={isBegin}
                    ></RewardSettingDisplay>
                )}
            </div>
        );
    }
}
