import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { mapValues, defaults, get, isArray, forEach, some, debounce } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { InterActiveEventType } from '../interactive-promotion-encourage-add-form';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

enum RewardRule {
    /**
     * 每满设置
     */
    CYCLE = 'CYCLE',
    /**
     * 阶梯设置
     */
    LADDER = 'LADDER',
}

export interface InteractivePromotionEncourageEditFormProps extends FormComponentProps {
    eventType: InterActiveEventType;
    rewardRule: RewardRule;
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
}

class interactivePromotionEncourageEditFormView extends PureComponent<
    InteractivePromotionEncourageEditFormProps,
    {}
> {
    static defaultProps: any = {
        eventType: InterActiveEventType.FOLLOW,
        rewardRule: RewardRule.CYCLE,
    };

    componentWillUnmount() {
        this.props.unmountComponent();
    }
    render() {
        const { entity, onSubmit, wrappedComponentRef, eventType } = this.props;

        let rewardStartText = '';
        let rewardEndText = language.getText('hdyxjl');
        let rewardUnit = language.getText('people');
        switch (eventType) {
            case InterActiveEventType.FOLLOW:
                rewardStartText = language.getText('jmgz');
                break;
            case InterActiveEventType.BE_FOLLOW:
                rewardStartText = language.getText('mb_1');
                rewardEndText = language.getText('gzhdyxjl');
                break;
            case InterActiveEventType.MUTUAL_FOLLOW:
                rewardStartText = language.getText('mxhgz');
                rewardUnit = language.getText('d_3');
                break;
        }

        let fields = [
            { property: 'baseInfo.eventType', className: 'property-hide' },
            { property: 'baseInfo.name' },
            { property: 'baseInfo.dateRange' },
            {
                property: 'encourageInfo.rewardRule',
                controlConfig: { disabled: true },
            },
            {
                property: 'encourageInfo.cycleRewardRule',
                controlConfig: {
                    reqConfig: {
                        apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                        apiPath: '/admin/activities/reward_items',
                    },
                    reqParams: { eventType: eventType },
                    rewardStartText: rewardStartText,
                    rewardEndText: rewardEndText,
                    rewardUnit: rewardUnit,
                    disabled: true,
                    baseConfig: {
                        isConditionsInput: true,
                        isItmesRadio: false,
                        isLadder: false,
                    },
                },
                visible: (values: any) =>
                    get(values, 'encourageInfo.rewardRule') === RewardRule.CYCLE,
            },
            {
                property: 'encourageInfo.ladderRewardRule',
                controlConfig: {
                    reqConfig: {
                        apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                        apiPath: '/admin/activities/reward_items',
                    },
                    reqParams: { eventType: eventType },
                    rewardStartText: rewardStartText,
                    rewardEndText: rewardEndText,
                    rewardUnit: rewardUnit,
                    disabled: true,
                    baseConfig: {
                        isConditionsInput: true,
                        isItmesRadio: false,
                        isLadder: true,
                        maxCount: 10,
                    },
                },
                visible: (values: any) =>
                    get(values, 'encourageInfo.rewardRule') === RewardRule.LADDER,
            },
            {
                property: 'encourageInfo.rewardCalculation',
                controlConfig: { disabled: true },
                visible: (values: any) =>
                    get(values, 'baseInfo.eventType') !== InterActiveEventType.BE_FOLLOW,
            },
            { property: 'encourageInfo.activityRewardLimit' },
        ];

        let formProps = {
            entity,
            componentId: 'InteractivePromotionEncourageEditFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'interactive-promotion-encourage-edit-form-container',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 800 },
            fields: fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;
    let form: any;

    let rewardRule: RewardRule = RewardRule.CYCLE;
    let rewardRuleSelect = get(entity.fields, 'encourageInfo.rewardRule', null);
    rewardRule = rewardRuleSelect;

    let eventType = get(entity.fields, 'baseInfo.eventType', null);

    let rewardStartText = '';
    switch (eventType) {
        case InterActiveEventType.FOLLOW:
            rewardStartText = services.language.getText('gztr');
            break;
        case InterActiveEventType.BE_FOLLOW:
            rewardStartText = services.language.getText('btrgz');
            break;
        case InterActiveEventType.MUTUAL_FOLLOW:
            rewardStartText = services.language.getText('jlhy');
            break;
    }

    /**
     * 提交前额外验证
     * @param entityFields
     */
    const validate = (entityFields: any) => {
        const rewardRule = get(entityFields, 'encourageInfo.rewardRule', null);
        const cycleRewardRule = get(entityFields, 'encourageInfo.cycleRewardRule', null);

        if (rewardRule === RewardRule.CYCLE) {
            const data = cycleRewardRule[0];
            if (data.conditionValue && data.conditionValue > 0) {
                if (data.assetRewards && isArray(data.assetRewards)) {
                    if (data.assetRewards.some((assetReward: any) => assetReward.rewardValue > 0)) {
                        return true;
                    } else {
                        AntMessage.warning(services.language.getText('qtxmztjhdjlsz'));
                    }
                } else {
                    AntMessage.warning(services.language.getText('qtxmztjhdjlsz'));
                }
            } else {
                AntMessage.warning(services.language.getText('qtxjltjmzsz'));
            }
        } else {
            const ladderRewardRule = get(entityFields, 'encourageInfo.ladderRewardRule', null);
            if (ladderRewardRule && ladderRewardRule.length > 0) {
                let hasValidate = true;
                let maxConditionValue = 0;
                forEach(ladderRewardRule, (reward: any) => {
                    if (reward.conditionValue) {
                        if (reward.conditionValue > maxConditionValue) {
                            maxConditionValue = reward.conditionValue;
                        } else {
                            AntMessage.warning(
                                services.interpolate(
                                    services.language.getText('jbcsbgsxydxdysydgs'),
                                    { rewardStartText }
                                )
                            );
                            hasValidate = false;
                            return false;
                        }
                    } else {
                        AntMessage.warning(
                            services.interpolate(services.language.getText('jbcsbgsxydxdysydgs'), {
                                rewardStartText,
                            })
                        );
                        hasValidate = false;
                        return false;
                    }

                    //2.验证已填写（必须选择一项）
                    if (!reward.assetRewards) {
                        AntMessage.warning(services.language.getText('qsrjlx'));
                        hasValidate = false;
                        return false;
                    } else {
                        if (
                            !some(
                                reward.assetRewards,
                                (assetReward: any) => assetReward.rewardValue > 0
                            )
                        ) {
                            AntMessage.warning(services.language.getText('qsrjlx'));
                            hasValidate = false;
                            return false;
                        }
                    }
                });
                if (!hasValidate) {
                    return false;
                }
            }
            return true;
        }
        return false;
    };

    return defaults(
        {
            onSubmit,
            onFieldChange,
            eventType,
            rewardRule,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: debounce((event: any, fields: any) => {
                const entityFields = mapValues(fields, (field, name) => {
                    return field.value;
                });
                if (entityFields) {
                    const hasValidate = validate(entityFields);
                    if (hasValidate) {
                        entity.modify(entityFields, params);
                    }
                }
            }, 300),
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(
                        actions.unmountComponentAction(
                            'InteractivePromotionEncourageEditFormContainer'
                        )
                    );
            },
        }
    );
}
export const InteractivePromotionEncourageEditFormView = connect(mapDispatchToProps)(
    interactivePromotionEncourageEditFormView
);
