import { isEmpty } from 'lodash';
import { services } from '@comall-backend-builder/core/';
import { subsites } from '../containers/page-design';
import { merchantId } from '../../../containers/design-merchant';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

export const VirtualCategroyLoader = {
    get: async function(data: any = {}, config: ApiRequestConfig) {
        config.apiPath = '/admin/virtual_categories';
        if (merchantId >= 0) {
            data.merchantIds = merchantId;
        }
        if (!isEmpty(subsites)) {
            data.subsiteIds = subsites
                .map((subsite: any) => {
                    return subsite.id;
                })
                .join(',');
        }
        data.type = 'VIRTUAL_CATEGORY';
        return services.api.get(data, config);
    },
};
