import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        reservationDetailsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/service_reserved_activity_orders',
            filters: {
                activityName: {
                    type: 'string',
                    displayName: '<<fwmc>>',
                    controlConfig: { placeholder: '<<qsrhdmc>>' },
                },
                subSiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                mobile: {
                    type: 'string',
                    displayName: '<<mobile>>',
                    controlConfig: { placeholder: '<<qsryyzdh>>' },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<yysj>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<yyxm>>',
                    controlConfig: { placeholder: '<<qsryyrxm>>' },
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<yydh>>',
                    controlConfig: { placeholder: '<<qsryydh>>' },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<yyddh>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                activityName: {
                    type: 'string.popover',
                    displayName: '<<fwmc>>',
                },
                orderAmount: {
                    type: 'string',
                    displayName: '<<yj_1>>',
                },
                quantity: {
                    type: 'string',
                    displayName: '<<number>>',
                },
                reservedTime: {
                    type: 'string',
                    displayName: '<<yysj>>',
                },
                orderStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<yyzt>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<yyr>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<yysjh>>',
                },
                status: {
                    type: 'string',
                },
                servicePlaceName: {
                    type: 'string',
                    displayName: '<<zjdd>>',
                },
                lendTime: {
                    type: 'string',
                    displayName: '<<jcsj>>',
                },
                returnTime: {
                    type: 'string',
                    displayName: '<<ghsj>>',
                },
            },
        },
    },
    components: {
        reservationDetailsView: {
            component: 'Viewport',
            entity: 'reservationDetailsEntity',
        },
        reservationDetailsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'reservationDetailsFilter',
                },
                {
                    component: 'ExportDataByFilterParamsButton',
                    params: {
                        text: '<<exportSearchResult>>',
                        style: {
                            marginTop: 17,
                            marginBottom: 24,
                            width: '120px',
                        },
                        apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                        apiPath: '/admin/service_reserved_activity_orders/export',
                        type: `service-reserved-activity-orders`,
                        requestMethod: 'get',
                        target: '_blank',
                    },
                },
                { component: 'reservationDetailsTable' },
            ],
        },
        reservationDetailsFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'activityName',
                },
                {
                    property: 'subSiteId',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'name',
                },
                {
                    property: 'orderNo',
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        reservationDetailsTablesPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'tabs-content-viewport',
            items: [{ component: 'reservationDetailsTables' }],
        },
        reservationDetailsTables: {
            component: 'TabsPlus',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<all>>',
                    content: {
                        component: 'reservationDetailsPage',
                    },
                    params: { orderStatus: '' },
                },
                {
                    title: '<<dzf>>',
                    content: {
                        component: 'reservationDetailsPage',
                    },
                    params: { orderStatus: 'CREATE' },
                },
                {
                    title: '<<yyy>>',
                    content: {
                        component: 'reservationDetailsPage',
                    },
                    params: { orderStatus: 'PAYMENT' },
                },
                {
                    title: '<<inUse>>',
                    content: {
                        component: 'reservationDetailsPage',
                    },
                    params: { orderStatus: 'RECEIVE' },
                },
                {
                    title: '<<ygh>>',
                    content: {
                        component: 'reservationDetailsPage',
                    },
                    params: { orderStatus: 'CLOSE' },
                },
                {
                    title: '<<yqx>>',
                    content: {
                        component: 'reservationDetailsPage',
                    },
                    params: { orderStatus: 'CANCEL' },
                },
            ],
        },
        reservationDetailsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'orderNo',
                    width: '200px',
                },
                {
                    property: 'subsiteName',
                    width: '100px',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                    },
                },
                {
                    property: 'activityName',
                    width: 180,
                },
                {
                    property: 'orderAmount',
                    width: '100px',
                },
                {
                    property: 'quantity',
                    width: '100px',
                },
                {
                    property: 'reservedTime',
                    width: '10px',
                },
                {
                    property: 'orderStatus',
                    width: '100px',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        statusConfig: {
                            CREATE: {
                                text: '<<dzf>>',
                                status: 'processing',
                            },
                            PAYMENT: {
                                text: '<<yyy>>',
                                status: 'success',
                            },
                            RECEIVE: {
                                text: '<<inUse>>',
                                status: 'processing',
                            },
                            CANCEL: {
                                text: '<<yqx>>',
                                status: 'default',
                            },
                            CLOSE: {
                                text: '<<ygh>>',
                                status: 'default',
                            },
                        },
                    },
                },
                {
                    property: 'name',
                    width: '100px',
                },
                {
                    property: 'mobile',
                    width: '150px',
                },
                {
                    property: 'servicePlaceName',
                },
                {
                    property: 'lendTime',
                },
                {
                    property: 'returnTime',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 200,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        statusKey: 'row.canLender',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    value: 'enabled',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                    apiPath: '/admin/service_reserved_activity_orders/:id/lender',
                                    config: {
                                        content: '{{row.orderNo}}<<ybjc>>',
                                        text: '<<jc_1>>',
                                    },
                                    confirm: {
                                        text: '<<sfjc>>{{row.name}}<<de>>{{row.activityName}}?',
                                        className: 'confirm-content',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        statusKey: 'row.canRemand',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    value: 'enabled',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                    apiPath: '/admin/service_reserved_activity_orders/:id/remand',
                                    config: {
                                        content: '{{row.orderNo}}<<ybgh>>',
                                        text: '<<gh>>',
                                    },
                                    confirm: {
                                        text: '<<sfgh>>{{row.name}}<<de>>{{row.activityName}}?',
                                        className: 'confirm-content',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        statusKey: 'row.canCancel',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    value: 'enabled',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                    apiPath: '/admin/service_reserved_activity_orders/:id/cancel',
                                    config: {
                                        content: '{{row.orderNo}}<<ybqx>>',
                                        text: '<<common.cancel>>',
                                    },
                                    confirm: {
                                        text: '<<sfqx>>{{row.name}}<<de>>{{row.activityName}}?',
                                        className: 'confirm-content',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/reservation-details',
                    component: 'reservationDetailsView',
                    breadcrumbName: '<<serviceReservedOrder>>',
                    privilege: {
                        path: 'serviceReservedOrder',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'reservationDetailsTablesPage' }],
                },
            ],
        },
    ],
};
