import { connect } from 'react-redux';
import { FooterInfo } from '../../components/footer-info';

function mapStateToProps(state: any, props: any) {
    return {
        fields: props.entity.fields,
    };
}

export const FooterInfoContainer = connect(mapStateToProps)(FooterInfo);
