/*
 * @Author: Chanphy
 * @Date: 2020-09-01 14:09:41
 * @Description: 专柜页管理
 */
import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
export const config: Config = {
    components: {
        MerchantPageManageView: {
            component: 'Viewport',
            entity: 'MerchantPageManageEntity',
        },
        MerchantPageManageDesign: {
            component: 'Card',
            content: {
                component: 'PageDesignMerchant',
                client: ENV.DESIGN_CLIENT_URL,
                apiPath: '/admin/merchant_pages/:id',
                type: 'page',
            },
        },
        MerchantPageManage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'GridLayout',
                    items: [
                        {
                            component: 'MerchantPageManageFilter',
                            span: 24,
                        },
                        {
                            component: 'Button',
                            text: '<<components.TopicManage.createPage>>',
                            type: 'primary',
                            icon: 'plus',
                            span: 4,
                            route: '/merchant-page-manage/add',
                            className: 'mt24 mb24',
                        },
                    ],
                },

                {
                    component: 'MerchantPageManageTable',
                },
            ],
        },
        MerchantPageManageFilter: {
            component: 'FilterForm',
            direction: 'inline',
            fields: [
                {
                    property: 'merchantName',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        optionFilterProp: 'children',
                        style: { width: '160px' },
                    },
                },
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: '160px' },
                    },
                },
            ],
            submit: { text: '<<common.search>>' },
        },
        MerchantPageManageTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'groupSubsiteName',
                },
                {
                    property: 'subsites',
                },
                {
                    property: 'enabled',
                },
                {
                    property: 'pageName',
                    width: 180,
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'lastModifyTime',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                items: [
                    {
                        type: 'link',
                        text: '<<components.TopicManage.setting>>',
                        path: '/merchant-page-manage/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<components.TopicManage.decorate>>',
                        path: '/merchant-page-manage/design/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'PagePreviewButton',
                        config: {
                            type: 'all',
                            extraParams: ['merchantId'],
                        },
                    },
                    {
                        type: 'delete',
                        text: '<<components.TopicManage.delete>>',
                        statusKey: 'row.canDelete',
                        config: {
                            type: 'link',
                            tooltip: null,
                            title: '<<confirmDelete>>',
                            okText: '<<common.ok>>',
                            cancelText: '<<common.cancel>>',
                        },
                    },
                ],
            },
        },
        MerchantPageManageAdd: {
            component: 'Card',
            title: '<<components.TopicManage.createPage>>',
            entities: [{ name: 'PageTemplateEntity', entityName: 'PageTemplateEntity' }],
            content: {
                component: 'MerchantPageManageAddForm',
            },
        },
        MerchantPageManageAddForm: {
            component: 'CreateForm',
            entity: 'MerchantPageManageEntity',
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'merchantSelect',
                    controlConfig: {
                        pageType: 'merchantSelect',
                    },
                },
                {
                    property: 'enabled',
                },
                {
                    property: 'template',
                    controlConfig: {
                        pageType: 'merchant',
                    },
                },
            ],
            labelCol: 8,
            submit: { text: '<<common.submit>>' },
            submitSuccessBehavior: {
                route: '/merchant-page-manage',
            },
            footer: {
                items: [
                    {
                        text: '<<common.cancel>>',
                        htmlType: 'button',
                        type: 'default',
                        route: 'goBack',
                        style: { marginLeft: 16 },
                    },
                ],
            },
            style: {
                width: '400px',
            },
        },
        MerchantPageManageEdit: {
            component: 'Card',
            title: '<<components.TopicManage.setPage>>',
            content: {
                component: 'MerchantPageManageEditForm',
            },
        },
        MerchantPageManageEditForm: {
            component: 'ModifyForm',
            entity: 'MerchantPageManageEntity',
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'merchantSelect',
                    controlConfig: {
                        pageType: 'merchantSelectEdit',
                    },
                },
                {
                    property: 'enabled',
                },
                {
                    property: 'template',
                    controlConfig: {
                        pageType: 'merchant',
                    },
                },
            ],
            loaderType: 'get',
            labelCol: 8,
            submit: { text: '<<common.save>>' },
            submitSuccessBehavior: {
                route: '/merchant-page-manage',
            },
            footer: {
                items: [
                    {
                        text: '<<common.cancel>>',
                        htmlType: 'button',
                        type: 'default',
                        route: 'goBack',
                        style: { marginLeft: 16 },
                    },
                ],
            },
            style: {
                width: '400px',
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/merchant-page-manage',
                    component: 'MerchantPageManageView',
                    breadcrumbName: '<<components.NavMenu.merchantPageManagement>>',
                    privilege: {
                        path: 'merchantPage',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/design/:id',
                            component: 'MerchantPageManageDesign',
                        },
                        {
                            path: '/add',
                            component: 'MerchantPageManageAdd',
                        },
                        {
                            path: '/edit/:id',
                            component: 'MerchantPageManageEdit',
                        },
                        {
                            path: '/',
                            component: 'MerchantPageManage',
                        },
                    ],
                },
            ],
        },
    ],
};
