import React, { PureComponent } from 'react';
import { Button as AntButton } from 'antd';

/**
 * 导出数据
 */
export class LotteryCodeExportButton extends PureComponent<any, any> {
    render() {
        const { row, importExportType } = this.props;
        const { whiteUserListFileUrl, blackUserListFileUrl } = row;
        return (
            <span>
                {importExportType === 'WHITE_LIST' && whiteUserListFileUrl ? (
                    <AntButton
                        type="link"
                        style={{ padding: 0, marginLeft: 5 }}
                        className="span-link"
                    >
                        <a href={whiteUserListFileUrl}>下载数据</a>
                    </AntButton>
                ) : null}
                {importExportType === 'BLACK_LIST' && blackUserListFileUrl ? (
                    <AntButton
                        type="link"
                        style={{ padding: 0, marginLeft: 5 }}
                        className="span-link"
                    >
                        <a href={blackUserListFileUrl}>下载数据</a>
                    </AntButton>
                ) : null}
            </span>
        );
    }
}
