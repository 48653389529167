/**
 * 优惠券订单详情
 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
    Table as AntTable,
    Row as AntRow,
    Col as AntCol,
    Card as AntCard,
    Tag as AntTag,
    Popover as AntPopover,
    message as AntMessage,
} from 'antd';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { map } from 'lodash';
import {
    couponRechargeRecordsLoader,
    groupBuyingCouponOrderLoader,
} from '../../loaders/coupon-order-loader';
import { getCouponStatus } from '../../types/format/utils';
import { language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

enum CouponOrderStatus {
    CREATE = 'CREATE',
    PAYMENT = 'PAYMENT',
    RECEIVE = 'RECEIVE',
    CANCEL = 'CANCEL',
    CLOSE = 'CLOSE',
}

enum CouponOrderStatusStr {
    CREATE = 'dfk',
    PAYMENT = 'dsy',
    RECEIVE = 'ywc',
    CANCEL = 'yqx',
    CLOSE = 'beforeClose',
}

enum GroupBuyingStatus {
    FAIL = 'ptsb',
    IN_PROGRESS = 'ptjxz',
    SUCCESS = 'ptcg',
    END = 'ptjs',
}

enum CouponorderOrigin {
    WECHAT = 'WECHAT',
    ALIPAY = 'ALIPAY',
}

enum CouponorderOriginStr {
    WECHAT = 'wxMiniP',
    ALIPAY = 'aliMiniP',
    APP = 'APP',
}

interface CouponOrder {
    id: number;
    coupons: [
        {
            couponName: string;
            bizStatus: string;
            couponNo: string;
            paidAmount: number;
            paidPointAmount: any;
            transferred: boolean;
            transferring: boolean;
        }
    ];
    itemList: [
        {
            key: number;
            id: number;
            afterFoldingPrice: number;
            dealPrice: number;
            executePrice: number;
            quantity: number;
            salePrice: number;
            saleRuleId: number;
            saleRuleName: string;
            totalPrice: number;
            pointDealPrice: number;
            coupons: any;
            unitPrice: string;
            pointExcutePrice: number;
            couponVos: any;
            couponName: any;
            couponNos: any;
        }
    ];
    member: {
        email: string | null;
        mark: string;
        memberId: number | null;
        memberName: string;
        mobile: string;
    };
    orderCreateTime: string;
    orderNo: string;
    tradeNo: string;
    orderOrigin: CouponorderOrigin;
    mergePaymentNo: string;
    orderPayable: {
        freightAmount: number;
        orderAmount: number;
        paidAmount: number;
        payableAmount: number;
        pointAmount: number;
    };
    orderPayableList: [
        {
            createTime: string;
            id: number;
            mergePaymentNo: string;
            paidAmount: number;
            paidPointAmount: number;
            paymentStatus: string;
            paymentType: string;
        }
    ];
    orderStatus: CouponOrderStatus;
    paymentStatus: string;
    paymentType: string;
    quantity: number;
    subsiteName: string;
}
interface MemberInfo {
    avatarId: string;
    mobile: string;
    orderStatus: string;
}

interface CouponRechargeRecord {
    amount: number;
    couponName: string;
    endTime: string;
    externalCouponCode: string;
    id: number;
    quantity: number;
    result: string;
    status: 'NONE' | 'SUCCESS' | 'FAIL';
    subsite: {
        address: string;
        id: number;
        name: string;
        /**
         * 门店经营状态 0=删除 1=正常 2=停用
         */
        status: number;
    };
    totalAmount: number;
    transactionId: string;
}
interface couponOrderInfoState {
    isGroupBuying: boolean;
    groupBuyingOrderInfo: {
        completeTime: string;
        members: MemberInfo[];
        teamLeader: MemberInfo;
        /**
         * 拼团状态
         * NONE 未开团
         * IN_PROGRESS 进行中
         * SUCCESS 成功
         * FAIL 失败
         * END 结束
         */
        status: 'IN_PROGRESS' | 'SUCCESS' | 'FAIL' | 'END';
    };
    balanceCouponList: CouponRechargeRecord[];
    /**
     * 是否显示优惠券信息
     */
    isDisplayCouponsInfo: boolean;
}

class couponOrderInfo extends PureComponent<
    {
        fields: CouponOrder | undefined;
        entity: Entity;
        params: { id: string; orderType: string };
    },
    couponOrderInfoState
> {
    state: couponOrderInfoState = {
        isGroupBuying: false,
        groupBuyingOrderInfo: {
            completeTime: '',
            members: [],
            teamLeader: {
                avatarId: '',
                mobile: '',
                orderStatus: 'SUCCESS',
            },
            status: 'SUCCESS',
        },
        balanceCouponList: [],
        isDisplayCouponsInfo: false,
    };
    componentDidMount() {
        const { params } = this.props;
        if (params.orderType === 'GROUP_BUYING_COUPON') {
            this.setState({ isGroupBuying: true });
            groupBuyingCouponOrderLoader
                .get({ orderId: this.props.params.id })
                .then((groupBuyingOrderInfo) => {
                    this.setState({ groupBuyingOrderInfo });
                });
        }
        let pathname: any = window.location.href;
        if (pathname && pathname.indexOf('/displayCouponsInfo') > -1) {
            this.setState({ isDisplayCouponsInfo: true });
        }
        couponRechargeRecordsLoader.get({ orderId: this.props.params.id }).then((data) => {
            this.setState({ balanceCouponList: data.result });
        });
    }

    copyId = (text: string) => {
        let input = document.createElement('input');
        input.value = text;
        document.body.appendChild(input);
        input.select();
        if (document.execCommand('Copy')) {
            AntMessage.success(language.getText('copySuccess'));
        } else {
            AntMessage.success(language.getText('copyError'));
        }
        document.body.removeChild(input);
    };

    render() {
        const { fields } = this.props;
        const {
            isGroupBuying = false,
            groupBuyingOrderInfo,
            balanceCouponList,
            isDisplayCouponsInfo = false,
        } = this.state;
        const showBalanceCouponList = balanceCouponList && balanceCouponList.length > 0;
        const tableProps = fields
            ? {
                  loading: false,
                  columns: [
                      {
                          title: services.language.getText('goodId'),
                          key: 'saleRuleId',
                          dataIndex: 'saleRuleId',
                          render: (text: any, record: any) => {
                              return <span>{text}</span>;
                          },
                      },
                      {
                          title: services.language.getText('goodName'),
                          key: 'saleRuleName',
                          dataIndex: 'saleRuleName',
                          render: (text: any, record: any, index: number) => {
                              return <span>{text}</span>;
                          },
                      },
                      {
                          title: services.language.getText('dj_1'),
                          key: 'unitPrice',
                          dataIndex: 'unitPrice',
                          render: (text: any, record: any, index: number) => {
                              return <span>{text}</span>;
                          },
                      },
                      {
                          title: services.language.getText('number'),
                          key: 'quantity',
                          dataIndex: 'quantity',
                          render: (text: any, record: any, index: number) => {
                              return <span>{text}</span>;
                          },
                      },
                      {
                          title: services.language.getText('zxzfje'),
                          key: 'totalPrice',
                          dataIndex: 'totalPrice',
                          render: (text: any, record: any, index: number) => {
                              return <span>{(+text || 0).toFixed(2)}</span>;
                          },
                      },
                      {
                          title: services.language.getText('jfzje'),
                          key: 'pointDealPrice',
                          dataIndex: 'pointDealPrice',
                          render: (text: any, record: any, index: number) => {
                              return (
                                  <span>
                                      {text}
                                      {language.getText('point')}
                                  </span>
                              );
                          },
                      },
                      {
                          title: services.language.getText('zxj'),
                          key: 'executePrice',
                          dataIndex: 'executePrice',
                          render: (text: any, record: any, index: number) => {
                              return <span>{(+text || 0).toFixed(2)}</span>;
                          },
                      },
                      {
                          title: services.language.getText('cjje_1'),
                          key: 'dealPrice',
                          dataIndex: 'dealPrice',
                          render: (text: any, record: any, index: number) => {
                              return <span>{(+text || 0).toFixed(2)}</span>;
                          },
                      },
                      {
                          title: services.language.getText('zhje'),
                          key: 'afterFoldingPrice',
                          dataIndex: 'afterFoldingPrice',
                          render: (text: any, record: any, index: number) => {
                              return <span>{(+text || 0).toFixed(2)}</span>;
                          },
                      },
                      {
                          title: services.language.getText('couponName'),
                          key: 'couponName',
                          dataIndex: 'couponName',
                          render: (value: any, record: any, index: number) => {
                              if (value && value.length > 0) {
                                  return (
                                      <div>
                                          {map(value, (item, index) => {
                                              return (
                                                  <div key={index + 'i'}>
                                                      <span>{item.couponName}</span>
                                                  </div>
                                              );
                                          })}
                                      </div>
                                  );
                              }
                          },
                      },
                      {
                          title: services.language.getText('couponBaCode'),
                          key: 'couponNos',
                          dataIndex: 'couponNos',
                          render: (couponNos: any, record: any, index: number) => {
                              //   let tests: any = '011011210715134157004080,011011210715134157004082';
                              if (couponNos && couponNos.length) {
                                  return couponNos.map((item: any, index: number) => {
                                      return (
                                          <div key={index}>
                                              {item.couponNos && item.couponNos.length ? (
                                                  <div>
                                                      {item.couponNos[0].couponNo}&nbsp;&nbsp;
                                                  </div>
                                              ) : (
                                                  ''
                                              )}
                                              {item.couponNos && item.couponNos.length ? (
                                                  <AntPopover
                                                      placement={'left'}
                                                      content={
                                                          <div style={{ maxWidth: 220 }}>
                                                              {item.couponNos.map(
                                                                  (i: any, index: any) => (
                                                                      <AntTag
                                                                          key={index}
                                                                          style={{
                                                                              marginBottom: 10,
                                                                          }}
                                                                      >
                                                                          {i.couponNo}
                                                                      </AntTag>
                                                                  )
                                                              )}
                                                          </div>
                                                      }
                                                  >
                                                      <span className="group-tips">
                                                          {item.couponNos.length}
                                                          {language.getText('ge')}
                                                      </span>
                                                  </AntPopover>
                                              ) : (
                                                  ''
                                              )}
                                          </div>
                                      );
                                  });
                              } else {
                                  return '';
                              }
                          },
                      },
                  ],
              }
            : {};

        const data =
            fields &&
            map(fields.itemList, (item) => {
                item.key = item.saleRuleId;
                item.couponName = item.couponVos;
                item.couponNos = item.couponVos;

                item.unitPrice = [
                    item.pointExcutePrice
                        ? `${item.pointExcutePrice}${language.getText('point')}`
                        : undefined,
                    item.salePrice
                        ? `${services.language.getText('monetaryUnit')}${item.salePrice}`
                        : undefined,
                ]
                    .filter((i) => !!i)
                    .join(' + ');

                return item;
            });

        const couponsTableProps = {
            loading: false,
            scroll: { x: true },
            columns: [
                {
                    title: services.language.getText('couponAndBag'),
                    key: 'couponName',
                    dataIndex: 'couponName',
                    render: (text: any) => {
                        return <span>{text}</span>;
                    },
                },
                {
                    title: services.language.getText('couponBaCode'),
                    key: 'couponNo',
                    dataIndex: 'couponNo',
                    render: (text: any) => {
                        return <span>{text}</span>;
                    },
                },
                {
                    title: services.language.getText('yhqzt'),
                    dataIndex: 'bizStatus',
                    key: 'bizStatus',
                    render: (val: string, record: any) => {
                        return (
                            <span>
                                {getCouponStatus(val)}
                                {record.transferred ? language.getText('zstr') : ''}
                            </span>
                        );
                    },
                },
                {
                    title: language.getText('ftdjg'),
                    key: 'paidAmount',
                    dataIndex: 'paidAmount',
                    render: (val: any, record: any) => {
                        let valStr = '';
                        if (record.paidPointAmount) {
                            valStr = `${record.paidPointAmount}${language.getText(
                                'point'
                            )}+${services.language.getText('monetaryUnit')}${val}`;
                        } else {
                            valStr = `${services.language.getText('monetaryUnit')}${val}`;
                        }
                        return <span>{valStr}</span>;
                    },
                },
            ],
        };

        const balanceCouponTableProps = {
            loading: false,
            scroll: { x: true },
            columns: [
                {
                    title: services.language.getText('qczjy'),
                    key: 'id',
                    dataIndex: 'id',
                    width: 250,
                    render: (text: any) => {
                        return (
                            <span>
                                <label>{text}</label>
                                <label
                                    style={{ marginLeft: '10px', color: '#1890ff' }}
                                    onClick={this.copyId.bind(this, text)}
                                >
                                    {services.language.getText('copy')}
                                </label>
                            </span>
                        );
                    },
                },
                {
                    title: services.language.getText('qbm'),
                    key: 'externalCouponCode',
                    dataIndex: 'externalCouponCode',
                    render: (text: any) => {
                        return <span>{text}</span>;
                    },
                },
                {
                    title: services.language.getText('qmc'),
                    key: 'couponName',
                    dataIndex: 'couponName',
                    render: (text: any) => {
                        return <span>{text}</span>;
                    },
                },
                {
                    title: services.language.getText('jsrq'),
                    key: 'endTime',
                    dataIndex: 'endTime',
                    render: (text: any) => {
                        return <span>{text}</span>;
                    },
                },
                {
                    title: services.language.getText('bgmd'),
                    key: 'subsite',
                    dataIndex: 'subsite',
                    render: (subsite: any) => {
                        return <span>{subsite.name}</span>;
                    },
                },
                {
                    title: services.language.getText('mz_1'),
                    key: 'amount',
                    dataIndex: 'amount',
                    render: (text: any) => {
                        return <span>{text}</span>;
                    },
                },
                {
                    title: services.language.getText('number'),
                    key: 'quantity',
                    dataIndex: 'quantity',
                    render: (text: any) => {
                        return <span>{text}</span>;
                    },
                },
                {
                    title: services.language.getText('bgje'),
                    key: 'totalAmount',
                    dataIndex: 'totalAmount',
                    render: (text: any) => {
                        return <span>{text}</span>;
                    },
                },
                {
                    title: services.language.getText('zhbgjylsh'),
                    key: 'transactionId',
                    dataIndex: 'transactionId',
                    render: (text: any) => {
                        return <span>{text}</span>;
                    },
                },
                {
                    title: services.language.getText('czje'),
                    key: 'totalAmount',
                    dataIndex: 'totalAmount',
                    render: (text: any) => {
                        return <span>{text}</span>;
                    },
                },
                {
                    title: services.language.getText('status'),
                    dataIndex: 'status',
                    key: 'shelfStatus',
                    render: (val: string) => {
                        let statusName = '';
                        if (val === 'NONE') {
                            statusName = services.language.getText('wcz');
                        } else if (val === 'FAIL') {
                            statusName = services.language.getText('sb_2');
                        } else if (val === 'SUCCESS') {
                            statusName = services.language.getText('success');
                        }
                        return <span>{statusName}</span>;
                    },
                },
                {
                    title: services.language.getText('reason'),
                    key: 'result',
                    dataIndex: 'result',
                    render: (text: any) => {
                        return <span>{text}</span>;
                    },
                },
            ],
        };

        return fields ? (
            <div className="coupon-order-info">
                <AntCard>
                    <AntRow style={{ marginBottom: 20 }}>
                        <AntCard type="inner" title={services.language.getText('otherBaseInfo')}>
                            <AntRow style={{ marginBottom: 10 }}>
                                <AntCol span={2}>
                                    {services.language.getText('orderNumber')}:
                                </AntCol>
                                <AntCol span={6}>{fields.orderNo}</AntCol>
                                <AntCol span={3}>
                                    {services.language.getText('productNumJian')}:
                                </AntCol>
                                <AntCol span={5}>{fields.quantity}</AntCol>
                                <AntCol span={3}>{services.language.getText('ddzt')}:</AntCol>
                                <AntCol span={5}>
                                    {language.getText(CouponOrderStatusStr[fields.orderStatus])}
                                </AntCol>
                            </AntRow>
                            <AntRow style={{ marginBottom: 10 }}>
                                <AntCol span={2}>{services.language.getText('xdsj')}:</AntCol>
                                <AntCol span={6}>{fields.orderCreateTime}</AntCol>
                                <AntCol span={3}>{services.language.getText('zffs')}:</AntCol>
                                <AntCol span={5}>
                                    {fields.orderPayableList[0].paymentType === 'pay'
                                        ? services.language.getText('ptzf')
                                        : services.language.getText('hbzf')}
                                </AntCol>
                                <AntCol span={3}>{services.language.getText('zfzt')}:</AntCol>
                                <AntCol span={5}>
                                    {fields.orderPayableList[0].paymentStatus === 'NOT_PAY'
                                        ? services.language.getText('wzf')
                                        : services.language.getText('yzf_1')}
                                </AntCol>
                            </AntRow>
                            <AntRow style={{ marginBottom: 10 }}>
                                <AntCol span={2}>{services.language.getText('jylsh')}:</AntCol>
                                <AntCol span={6}>{fields.tradeNo}</AntCol>
                                <AntCol span={3}>{services.language.getText('qdly')}:</AntCol>
                                <AntCol span={5}>
                                    {language.getText(CouponorderOriginStr[fields.orderOrigin])}
                                </AntCol>
                                {/* <AntCol span={3}>中台流水号:</AntCol>
                                <AntCol span={5}>{fields.mergePaymentNo}</AntCol> */}
                            </AntRow>
                            {isGroupBuying ? (
                                <AntRow style={{ marginBottom: 10 }}>
                                    <AntCol span={2}>{services.language.getText('ctsj')}:</AntCol>
                                    <AntCol span={6}>{groupBuyingOrderInfo.completeTime}</AntCol>
                                </AntRow>
                            ) : null}
                        </AntCard>
                    </AntRow>
                    <AntRow style={{ marginBottom: 20 }}>
                        <AntCol span={12} style={{ paddingRight: 10 }}>
                            <AntCard
                                type="inner"
                                title={services.language.getText('mjxx')}
                                style={{ height: 240 }}
                            >
                                <AntRow style={{ marginBottom: 10 }}>
                                    <AntCol span={6}>{services.language.getText('member')}:</AntCol>
                                    <AntCol span={6}>{fields.member.memberName}</AntCol>
                                </AntRow>
                                <AntRow style={{ marginBottom: 10 }}>
                                    <AntCol span={6}>
                                        {services.language.getText('phoneNum')}:
                                    </AntCol>
                                    <AntCol span={6}>{fields.member.mobile}</AntCol>
                                </AntRow>
                                <AntRow style={{ marginBottom: 10 }}>
                                    <AntCol span={6}>{services.language.getText('email')}:</AntCol>
                                    <AntCol span={6}>
                                        {fields.member.email ? fields.member.email : '-'}
                                    </AntCol>
                                </AntRow>
                                <AntRow style={{ marginBottom: 10 }}>
                                    <AntCol span={6}>{services.language.getText('hybz')}:</AntCol>
                                    <AntCol span={6}>{fields.member.mark}</AntCol>
                                </AntRow>
                            </AntCard>
                        </AntCol>
                        <AntCol span={12} style={{ paddingLeft: 10 }}>
                            <AntCard
                                type="inner"
                                title={services.language.getText('yfxx')}
                                style={{ height: 240 }}
                            >
                                <AntRow style={{ marginBottom: 10 }}>
                                    <AntCol span={6}>{services.language.getText('spze')}:</AntCol>
                                    <AntCol span={6}>
                                        {fields.orderPayable.orderAmount.toFixed(2)}
                                        {services.language.getText('yuan')}
                                    </AntCol>
                                </AntRow>
                                <AntRow style={{ marginBottom: 10 }}>
                                    <AntCol span={6}>{services.language.getText('jfze')}:</AntCol>
                                    <AntCol span={6}>
                                        {fields.orderPayable.pointAmount.toFixed(2)}
                                        {services.language.getText('point')}
                                    </AntCol>
                                </AntRow>
                                <AntRow style={{ marginBottom: 10 }}>
                                    <AntCol span={6}>
                                        {services.language.getText('tenantDeliverMoney')}:
                                    </AntCol>
                                    <AntCol span={6}>
                                        {fields.orderPayable.freightAmount.toFixed(2)}
                                        {services.language.getText('yuan')}
                                    </AntCol>
                                </AntRow>
                                <AntRow style={{ marginBottom: 10 }}>
                                    <AntCol span={6}>{services.language.getText('zxzfje')}:</AntCol>
                                    <AntCol span={6}>
                                        {fields.orderPayable.payableAmount.toFixed(2)}
                                        {services.language.getText('yuan')}
                                    </AntCol>
                                </AntRow>
                                <AntRow style={{ marginBottom: 10 }}>
                                    <AntCol span={6}>{services.language.getText('yfje_2')}:</AntCol>
                                    <AntCol span={6}>
                                        {fields.orderPayable.paidAmount.toFixed(2)}
                                        {services.language.getText('yuan')}
                                    </AntCol>
                                </AntRow>
                            </AntCard>
                        </AntCol>
                    </AntRow>
                    <AntRow style={{ marginBottom: 20 }}>
                        <AntCard type="inner" title={services.language.getText('hpmx')}>
                            <AntTable
                                style={{ overflowX: 'scroll' }}
                                rowKey={(record) => record.key.toString()}
                                dataSource={data}
                                {...tableProps}
                            />
                        </AntCard>
                    </AntRow>
                    {isDisplayCouponsInfo && (
                        <AntRow style={{ marginBottom: 20 }}>
                            <AntCard type="inner" title={services.language.getText('couponInfo')}>
                                <AntTable
                                    rowKey={(record) => record.couponNo}
                                    dataSource={fields.coupons}
                                    {...couponsTableProps}
                                />
                            </AntCard>
                        </AntRow>
                    )}
                    {showBalanceCouponList && (
                        <AntRow style={{ marginBottom: 20 }}>
                            <AntCard type="inner" title={services.language.getText('sfqmx')}>
                                <AntTable
                                    rowKey={(record) => record.id.toString()}
                                    dataSource={balanceCouponList}
                                    {...balanceCouponTableProps}
                                />
                            </AntCard>
                        </AntRow>
                    )}
                    {isGroupBuying ? (
                        <AntRow style={{ marginBottom: 20 }}>
                            <AntCol span={12} style={{ paddingRight: 10 }}>
                                <AntCard type="inner" title={services.language.getText('ptxx')}>
                                    {groupBuyingOrderInfo.status ? (
                                        <AntRow style={{ marginBottom: 10 }}>
                                            <AntCol span={6}>
                                                {services.language.getText('tzt')}:
                                            </AntCol>
                                            <AntCol span={6}>
                                                {language.getText(
                                                    GroupBuyingStatus[groupBuyingOrderInfo.status]
                                                )}
                                            </AntCol>
                                        </AntRow>
                                    ) : null}

                                    {groupBuyingOrderInfo.teamLeader ? (
                                        <AntRow style={{ marginBottom: 10 }}>
                                            <AntCol span={6}>
                                                {services.language.getText('tz')}:
                                            </AntCol>
                                            <AntCol span={6}>
                                                {groupBuyingOrderInfo.teamLeader.mobile}
                                            </AntCol>
                                        </AntRow>
                                    ) : null}

                                    {groupBuyingOrderInfo.members &&
                                    Array.isArray(groupBuyingOrderInfo.members)
                                        ? groupBuyingOrderInfo.members.map((member, index) => (
                                              <AntRow style={{ marginBottom: 10 }} key={index}>
                                                  <AntCol span={6}>
                                                      {services.language.getText('ty_1')}
                                                      {index + 1}:
                                                  </AntCol>
                                                  <AntCol span={6}>{member.mobile}</AntCol>
                                              </AntRow>
                                          ))
                                        : null}
                                </AntCard>
                            </AntCol>
                        </AntRow>
                    ) : null}
                </AntCard>
            </div>
        ) : null;
    }
}

function mapStateToProps(state: any, props: any) {
    const {
        entity: { fields },
        componentId,
    } = props;
    return {
        fields,
        componentId,
    };
}

export const CouponOrderInfo = connect(mapStateToProps)(couponOrderInfo);
