import React, { PureComponent } from 'react';
import { Table as AntTable, Row as AntRow, Col as AntCol, Card as AntCard } from 'antd';
import { services } from '@comall-backend-builder/core';
import { getDepostOrderChannelName, getDepostOrderStatusName } from './common';

interface State {
    info?: any;
    posInfo?: any;
}

interface Props {
    params: any;
}

const api = services.api;
export class DepositOrderInfo extends PureComponent<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            info: undefined,
            posInfo: undefined,
        };
    }
    componentDidMount() {
        const id = this.props.params.id;
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`,
                apiPath: `/admin/deposit_orders/${id}`,
            }
        ).then((response: any) => {
            this.setState({
                info: response,
            });
            this.loadPosReceipts();
        });
    }

    loadPosReceipts = () => {
        const id = this.props.params.id;
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`,
                apiPath: `/admin/deposit_orders/${id}/pos_receipts`,
            }
        ).then((response: any) => {
            this.setState({
                posInfo: response,
            });
        });
    };

    renderTable = () => {
        const { posInfo } = this.state;
        if (!posInfo || posInfo.length === 0) {
            return null;
        }
        const tableProps = {
            loading: false,
            scroll: { x: true },
            columns: [
                {
                    title: services.language.getText('xph'),
                    key: 'posReceiptNo',
                    dataIndex: 'posReceiptNo',
                },
                {
                    title: services.language.getText('xdsj'),
                    key: 'createTime',
                    dataIndex: 'createTime',
                },
                {
                    title: services.language.getText('djsyje'),
                    dataIndex: 'orderAmount',
                    key: 'orderAmount',
                },
            ],
        };
        return (
            <AntRow style={{ marginBottom: 20 }}>
                <AntCard type="inner" title={services.language.getText('ddsxdj')}>
                    <AntTable
                        style={{ overflowX: 'scroll' }}
                        rowKey={(record, index) => index.toString()}
                        dataSource={posInfo}
                        {...tableProps}
                    />
                </AntCard>
            </AntRow>
        );
    };

    render() {
        const { info } = this.state;
        if (!info) {
            return null;
        }
        return (
            <div className="coupon-order-info">
                <div>
                    <AntRow style={{ marginBottom: 20 }}>
                        <AntCard type="inner" title={services.language.getText('baseSetting')}>
                            <AntRow style={{ marginBottom: 10 }}>
                                <AntCol span={8}>
                                    {services.language.getText('djdh')}：{info.orderNo}
                                </AntCol>
                                <AntCol span={8}>
                                    {services.language.getText('subsites')}：{info.subsite.name}
                                </AntCol>
                                <AntCol span={8}>
                                    {services.language.getText('merchant')}：{info.merchant.name}
                                </AntCol>
                            </AntRow>
                            <AntRow style={{ marginBottom: 10 }}>
                                <AntCol span={8}>
                                    {services.language.getText('orderAmount')}：{info.orderAmount}
                                </AntCol>
                                <AntCol span={8}>
                                    {services.language.getText('ddzt')}：
                                    {getDepostOrderStatusName(info.status)}
                                </AntCol>
                                <AntCol span={8}>
                                    {services.language.getText('qdly')}：
                                    {getDepostOrderChannelName(info.channelName)}
                                </AntCol>
                            </AntRow>
                            <AntRow style={{ marginBottom: 10 }}>
                                <AntCol span={8}>
                                    {services.language.getText('xdsj')}：{info.orderCreateTime}
                                </AntCol>
                                <AntCol span={8}>
                                    {services.language.getText('kddg')}：{info.guideInfo.name}
                                </AntCol>
                            </AntRow>
                        </AntCard>
                    </AntRow>
                    <AntRow style={{ marginBottom: 20 }}>
                        <AntCard type="inner" title={services.language.getText('mjxx')}>
                            <AntRow style={{ marginBottom: 10 }}>
                                <AntCol span={8}>
                                    {services.language.getText('hysjh')}：{info.memberInfo.mobile}
                                </AntCol>
                                <AntCol span={8}>
                                    {services.language.getText('nickName')}：
                                    {info.memberInfo.nickName}
                                </AntCol>
                                <AntCol span={8}>
                                    {services.language.getText('hydj')}：
                                    {info.memberInfo.memberCardLevelName}
                                </AntCol>
                            </AntRow>
                        </AntCard>
                    </AntRow>
                    <AntRow style={{ marginBottom: 20 }}>
                        <AntCard type="inner" title={services.language.getText('djsp')}>
                            <AntRow style={{ marginBottom: 10 }}>
                                <AntCol span={8}>
                                    {services.language.getText('djsjm')}：
                                    {info.items[0].merchantBigCode}
                                </AntCol>
                                <AntCol span={8}>
                                    {services.language.getText('bigCodeName')}：{info.items[0].name}
                                </AntCol>
                                <AntCol span={8}>
                                    {services.language.getText('sj_1')}：{info.items[0].price}
                                </AntCol>
                            </AntRow>
                        </AntCard>
                    </AntRow>
                    {info.paymentRecords && info.paymentRecords.length > 0 && (
                        <AntRow style={{ marginBottom: 20 }}>
                            <AntCard type="inner" title={services.language.getText('zfxx')}>
                                {info.paymentRecords.map((payment: any) => {
                                    return (
                                        <AntRow style={{ marginBottom: 10 }}>
                                            <AntCol span={9}>
                                                {services.language.getText('zfptjydh')}：
                                                {payment.tradeNo}
                                            </AntCol>
                                            <AntCol span={4}>
                                                {services.language.getText('zfqd')}：
                                                {payment.payModeName}
                                            </AntCol>
                                            <AntCol span={4}>
                                                {services.language.getText('zfzt')}：已支付
                                            </AntCol>
                                            <AntCol span={6}>
                                                {services.language.getText('zfsj')}：
                                                {payment.paidTime}
                                            </AntCol>
                                            {payment.merchantTradeNo && (
                                                <AntCol span={9} style={{ marginTop: '15px' }}>
                                                    {services.language.getText('zfptshddh')}：
                                                    {payment.merchantTradeNo}
                                                </AntCol>
                                            )}
                                        </AntRow>
                                    );
                                })}
                            </AntCard>
                        </AntRow>
                    )}
                    {this.renderTable()}
                </div>
            </div>
        );
    }
}
