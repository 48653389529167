import React, { Component } from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import { Carousel } from 'antd';
import classNames from 'classnames';
import './index.less';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

class UpAdsPreview extends Component<any, any> {
    state = {
        preUrl: '',
        imgHeight: 0,
        currentIndex: 0,
    };

    componentWillReceiveProps(nextProps: any, nextState: any) {
        if (
            nextProps &&
            nextProps.pictureType === 'MULTIPLE_IMAGE_CAROUSEL' &&
            nextProps.operator &&
            nextProps.operator.length
        ) {
            const firstImg = nextProps.operator.find((item: any) => item.pic && item.pic.path);
            if (firstImg && nextState.preUrl !== firstImg.pic.path) {
                this.getImageDimensions(firstImg.pic.path);
            }
        }
    }

    getImageDimensions = (src: string) => {
        const img = new Image();
        img.src = src;
        img.onload = () => {
            this.setState({
                preUrl: src,
                imgHeight: (img.height / img.width) * 300,
            });
        };
    };
    onChange = (current: any) => {
        this.setState({
            currentIndex: current,
        });
    };
    renderPop = () => {
        const { pictureType, preview, operator } = this.props;
        const { imgHeight, currentIndex } = this.state;
        const url = preview ? get(preview, '[0].path', '') : '';
        if (pictureType === 'SINGLE_IMAGE_FIXED' && url) {
            return (
                <div className="pop-up-ads">
                    <img className="pop-up-ads-img" alt={language.getText('ylgg')} src={url}></img>
                    <img
                        className="pop-up-ads-img__close"
                        src="https://b-puzhehei-cdn.co-mall.net/global/btn_close.png"
                        alt={language.getText('common.close')}
                    />
                </div>
            );
        }
        const operatorImg: string[] = [];
        (operator || []).forEach((item: any) => {
            if (item.pic && item.pic.path) {
                operatorImg.push(item.pic.path);
            }
        });
        if (pictureType === 'MULTIPLE_IMAGE_CAROUSEL' && operatorImg.length) {
            return (
                <div className="pop-up-ads">
                    <div className="pop-up-ads__carousel">
                        <Carousel autoplay dots={false} afterChange={this.onChange}>
                            {operatorImg.map((url) => {
                                const style = {
                                    backgroundImage: `url(${url})`,
                                    widht: 300,
                                    height: imgHeight,
                                };
                                return (
                                    <div>
                                        <div
                                            className="pop-up-ads__carousel__img"
                                            style={style}
                                        ></div>
                                    </div>
                                );
                            })}
                        </Carousel>
                    </div>
                    <div className="pop-up-ads__dots">
                        {operatorImg.map((_, index) => {
                            const cls = classNames('pop-up-ads__dots__item', {
                                'pop-up-ads__dots__item--actived': index === currentIndex,
                            });
                            return <div className={cls}></div>;
                        })}
                    </div>
                    <img
                        className="pop-up-ads-img__close"
                        src="https://b-puzhehei-cdn.co-mall.net/global/btn_close.png"
                        alt={language.getText('common.close')}
                    />
                </div>
            );
        }
        return null;
    };
    render() {
        return (
            <div>
                <div className="pop-up-ads-preview">
                    <img
                        className="pop-up-ads-background-img"
                        alt={services.language.getText('preview')}
                        src="./images/avatar/home-preview.png"
                    ></img>
                    {this.renderPop()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (_state: any, props: any) => {
    let preview = null;
    let pictureType = 'SINGLE_IMAGE_FIXED';
    let operator = [];
    const entityId = props.entity.id;
    for (var compId in _state.components) {
        const comp = _state.components[compId];
        if (
            (comp.type === 'CreateForm' || comp.type === 'EditForm') &&
            comp.entityId === entityId
        ) {
            preview = comp.fields['pic'];
            pictureType = comp.fields['pictureType'];
            operator = comp.fields['operator'];
        }
    }
    return { preview: preview, pictureType, operator };
};

export const PopUpAdsPreview = connect(mapStateToProps)(UpAdsPreview);
