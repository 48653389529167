import React, { PureComponent } from 'react';
import { forEach, cloneDeep, findIndex } from 'lodash';
import {
    SaleProductSelectedDisplay,
    ProductsSelectorPlus,
    ProductsSelectType,
} from '../../components';
import { language } from '@comall-backend-builder/core/lib/services';

/**
 * 拼团活动选择商品
 */
export class SaleSelectProduct extends PureComponent<{
    value: any[];
    onChange: (v: any) => void;
    getParams: (v: any) => void;
    disabled: any;
    selectTypes?: ProductsSelectType[];
    importConfig?: any;
    params?: {
        id: string;
    };
    needSubsites?: any;
    bathTypeConfig?: string[];
    filterFields?: any;
}> {
    state = {
        value: [],
    };

    processSelectedData = (data: any[]) => {
        let items: any[] = [];
        if (data && data.length) {
            forEach(data, (product) => {
                forEach(product.goodsVos, (goods: any) => {
                    items.push({
                        id: goods.id,
                        name: goods.name,
                        productName: product.name,
                        productCode: goods.productCode,
                        goodsCode: goods.productStyleCode,
                        barCode: goods.productStyleBarcode,
                        subsiteName: goods.subsiteName,
                        subsiteId: goods.subsiteId,
                        merchantName: goods.merchantName,
                        costPrice: +goods.costPrice.value,
                        profit: null,
                        groupLeftStock: goods.stock,
                    });
                });
            });
        }
        return items;
    };

    processImportData = (goods: any[]) => {
        let items: any[] = [];
        if (goods && goods.length) {
            forEach(goods, (item) => {
                items.push({
                    id: item.goodsId,
                    name: item.goodsName,
                    productName: item.productName,
                    productCode: item.productCode,
                    goodsCode: item.goodsCode,
                    barCode: item.barcode,
                    subsiteName: item.subsite.name,
                    subsiteId: item.subsite.id,
                    merchantName: item.subsite.merchant.name,
                    costPrice: +item.costPrice,
                    groupLeftStock: item.balanceStock,
                    salePrice: item.limitPrice,
                    profit: item.limitPrice - item.costPrice,
                    saleStock: item.lockedStock,
                });
            });
        }
        return items;
    };

    onChange = (goods: any[]) => {
        const { onChange } = this.props;
        forEach(goods, (i) => {
            i.profit = i.salePrice - i.costPrice;
        });
        onChange(goods);
    };

    onSelect = (values: any[]) => {
        const data = this.processSelectedData(values);
        this.changeValue(data);
    };

    changeValue = (values: any[]) => {
        const { value } = this.props;
        const newData: any[] = cloneDeep(value || []);
        values.forEach((i: any) => {
            const hasIndex = findIndex(newData, (o: any) => Number(o.id) === Number(i.id));
            if (hasIndex === -1) {
                newData.unshift(i);
            } else {
                newData[hasIndex] = i;
            }
        });
        this.onChange(newData);
    };

    onImport = (data: any) => {
        if (!data || !data.timeLimitGoods) return;
        const goods = this.processImportData(data.timeLimitGoods);
        this.changeValue(goods);
    };

    getParams = () => {
        const { getParams } = this.props;

        if (typeof getParams === 'function') {
            return getParams(this.props);
        }

        return {};
    };

    render() {
        const {
            value,
            disabled,
            selectTypes,
            importConfig,
            bathTypeConfig,
            needSubsites,
            filterFields,
        } = this.props;
        const hasValue = value && value.length > 0;
        const params = this.getParams();
        const activityId = this.props.params && this.props.params.id ? this.props.params.id : null;
        return (
            <div className="cgb-select-product">
                {!disabled && (
                    <ProductsSelectorPlus
                        params={params}
                        buttonText={language.getText('select')}
                        onChange={this.onSelect}
                        onImport={this.onImport}
                        selectTypes={selectTypes}
                        importConfig={importConfig}
                        activityId={activityId}
                        needSubsites={needSubsites}
                        filterFields={filterFields}
                    />
                )}
                {hasValue && (
                    <SaleProductSelectedDisplay
                        modifiable={true}
                        data={value}
                        onChange={this.onChange}
                        disabled={Boolean(disabled)}
                        bathTypeConfig={bathTypeConfig}
                    ></SaleProductSelectedDisplay>
                )}
            </div>
        );
    }
}
