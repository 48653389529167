import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';

const modeOption = [
    { id: 'MANUAL', name: '<<sdbq>>' },
    // { id: 'AUTO', name: '自动标签' },
];

export const config: Config = {
    entities: {
        MemberTagEntity: {
            apiPath: '/loader/admin/memberTag',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<tagName>>',
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                name: {
                    type: 'string.tip',
                    displayName: '<<tagName>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        maxLength: 20,
                    },
                },
                mode: {
                    type: 'string.options.radio',
                    displayName: '<<dbfs>>',
                    defaultValue: 'MANUAL',
                    options: modeOption,
                    rules: [{ required: true }],
                },
                typeId: {
                    type: 'string.options.select',
                    displayName: '<<bqlx>>',
                    defaultValue: '',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/memberTagType',
                        loadOnInit: false,
                        dependences: ['mode'],
                    },
                    rules: [{ required: true }],
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                    },
                },
                typeName: {
                    type: 'string',
                    displayName: '<<bqlx>>',
                },
                memberCount: {
                    type: 'string',
                    displayName: '<<hys>>',
                },
                icon: {
                    displayName: '<<bqtb>>',
                    type: 'imageObjectArray',
                    className: 'sigle-image-field',
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        className: 'sigle-image-field',
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                        limitTips: {
                            limitText: '<<iconLimitTips>>',
                            className: 'image-picker-tips-ext',
                        },
                    },
                    extra: '<<iconLimitTips>>',
                },
                cornerIcon: {
                    displayName: '<<bqjb>>',
                    type: 'imageObjectArray',
                    className: 'sigle-image-field',
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        className: 'sigle-image-field',
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                        limitTips: {
                            limitText: '<<cornerIconLimitTips>>',
                            className: 'image-picker-tips-ext',
                        },
                    },
                    extra: '<<cornerIconExtra>>',
                },
            },
        },
    },
    components: {
        MemberTagView: {
            component: 'Viewport',
            entity: 'MemberTagEntity',
        },
        MemberTagPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'MemberTagFilter',
                },
                { component: 'MemberTagTable' },
            ],
        },
        MemberTagFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
            ],
        },
        MemberTagTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'Button',
                    text: '<<addTags>>',
                    route: '/memberTag/add',
                    type: 'primary',
                    icon: 'plus',
                    style: {
                        width: 140,
                    },
                    className: 'mt24 mb24',
                },
                {
                    component: 'DataTable',
                    componentId: 'MemberTagDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'name',
                        },
                        {
                            property: 'mode',
                        },
                        {
                            property: 'typeName',
                        },
                        {
                            property: 'memberCount',
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        items: [
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                path: '/memberTag/edit/{{row.id}}',
                            },
                            {
                                type: 'component',
                                text: '<<drhy>>',
                                component: 'memberTagImportButton',
                            },
                            {
                                type: 'delete',
                                text: '<<common.delete>>',
                                statusKey: 'row.canDelete',
                                config: {
                                    type: 'link',
                                    id: 'row.id',
                                    tooltip: null,
                                    title: '<<confirmDelete>>',
                                    okText: '<<common.ok>>',
                                    cancelText: '<<common.cancel>>',
                                },
                            },
                        ],
                    },
                },
            ],
        },
        MemberTagEditPage: {
            component: 'Card',
            content: { component: 'MemberTagEditForm' },
        },
        MemberTagEditForm: {
            component: 'ModifyForm',
            direction: 'horizontal',
            entity: 'MemberTagEntity',
            loaderType: 'get',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        maxLength: 20,
                    },
                },
                {
                    property: 'mode',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'typeId',
                    controlConfig: {
                        disabled: true,
                    },
                },
                { property: 'icon' },
                { property: 'cornerIcon' },
            ],
            submit: { text: '<<common.save>>' },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        MemberTagAddPage: {
            component: 'Card',
            content: { component: 'MemberTagAddForm' },
        },
        MemberTagAddForm: {
            component: 'CreateForm',
            direction: 'horizontal',
            entity: 'MemberTagEntity',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        maxLength: 20,
                    },
                },
                { property: 'mode' },
                { property: 'typeId' },
                { property: 'icon' },
                { property: 'cornerIcon' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/memberTag',
                    component: 'MemberTagView',
                    breadcrumbName: '<<memberTag>>',
                    privilege: {
                        path: 'memberTag',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'MemberTagAddPage',
                            breadcrumbName: '<<addTags>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'MemberTagEditPage',
                            breadcrumbName: '<<editTag>>',
                        },
                        { path: '/', component: 'MemberTagPage' },
                    ],
                },
            ],
        },
    ],
};
