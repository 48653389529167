import React, { PureComponent, createElement } from 'react';
import { ComponentsManager } from '@comall-backend-builder/core';
import { Modal, message } from 'antd';
import { api } from '@comall-backend-builder/core/lib/services';
import { get } from 'lodash';
import { ButtonProps } from 'antd/lib/button';
import { services, builder, actions } from '@comall-backend-builder/core';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import { EntityButtonProps } from '../../../../../components';
import { SelectTree } from '../../select-tree';
import './index.less';

const { confirm } = Modal;

interface NewRoleManageProps {
    entity: any;
    visible: boolean;
    avatar: string;
    onCancel: () => void;
    onOk: (avatar: string) => void;
}
interface TreeData {
    children: TreeData[];
    name: string;
    id: string;
    code: string;
    parentNames?: string[];
    level: number;
}
interface NewRoleManageState {
    treeData: TreeData[];
    selectedTreeNode: TreeData | undefined;
    selectedTreeNodeChildren: TreeData[];
    addModalVisible: boolean;
}
export class NewRoleManageViewContainer extends PureComponent<
    NewRoleManageProps,
    NewRoleManageState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            treeData: [],
            selectedTreeNodeChildren: [],
            selectedTreeNode: undefined,
            addModalVisible: false,
        };
    }

    componentDidMount() {
        const { entity } = this.props;
        const parentId = entity.filters.parentId;

        this.loadTreeData(parentId);
    }
    /**
     * 加载组织机构树形数据
     */
    loadTreeData = (parentId?: any) => {
        api.get(
            {},
            {
                apiPath: `/roles`,
                apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
            }
        ).then((res: any) => {
            const selectedTreeNode = parentId
                ? { id: parentId }
                : res && res.result && res.result.length
                ? res.result[0]
                : undefined;
            const treeData = this.handleTreeData(res.result);

            this.setState({ treeData, selectedTreeNode }, this.loadSelectedTreeNodeChildren);
        });
    };

    handleTreeData = (treeData: TreeData[]) => {
        treeData.forEach((item) => {
            const parentName = item.name;
            if (item.children && item.children.length) {
                item.children = item.children.map((i) => {
                    if (item.parentNames && item.parentNames.length) {
                        i.parentNames = [...item.parentNames, parentName];
                    } else {
                        i.parentNames = [parentName];
                    }
                    return i;
                });
                this.handleTreeData(item.children);
            }
        });
        return treeData;
    };

    /**
     * 加载当前选中树的子级树
     */
    loadSelectedTreeNodeChildren = () => {
        const { entity } = this.props;
        const { selectedTreeNode } = this.state;
        if (selectedTreeNode) {
            const data = { ...entity.filters, parentId: selectedTreeNode.id };
            delete data.name;
            const store = builder.getStore();
            store.dispatch(actions.filtersChangeAction(entity, data));

            entity.search();
        }
    };

    onTreeSelect = (selectedTreeNode: TreeData) => {
        const { entity } = this.props;
        const store = builder.getStore();
        store.dispatch(actions.pageChangeAction(entity, { ...entity.paging, page: 1 }));
        this.setState({ selectedTreeNode }, this.loadSelectedTreeNodeChildren);
    };
    goAddPage = () => {
        const { selectedTreeNode } = this.state;

        if (!get(selectedTreeNode, 'id')) {
            message.error(services.language.getText('qxxzygsjjs'));
        }
        if (selectedTreeNode && get(selectedTreeNode, 'level') >= 5) {
            message.error(services.language.getText('zdzktj'));
        }
    };

    renderAddBtn = () => {
        const btnProps: any = {
            text: services.language.getText('tjxjjs'),
            type: 'default',
        };
        const { selectedTreeNode } = this.state;
        if (selectedTreeNode && selectedTreeNode.id && selectedTreeNode.level < 5) {
            const names = selectedTreeNode.parentNames
                ? [...selectedTreeNode.parentNames, selectedTreeNode.name].join('>')
                : selectedTreeNode.name;
            btnProps.route = `/new-role-manage/add/${selectedTreeNode.id}/${names}`;
        } else {
            btnProps.onClick = this.goAddPage;
        }
        return createElement(ComponentsManager.get('Button'), btnProps);
    };

    render() {
        const { treeData, selectedTreeNode } = this.state;

        return (
            <div className="new-role-manage-view">
                {this.renderFilters()}
                <div className="data-view">
                    <div className="tree-view">
                        <div className="buttons">{this.renderAddBtn()}</div>
                        <div className="tree-wrap">
                            {treeData && treeData.length ? (
                                <SelectTree
                                    onSelect={this.onTreeSelect}
                                    result={treeData}
                                    selectedTreeNode={selectedTreeNode}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    </div>
                    <div className="table-view">
                        <div className="buttons"></div>
                        {this.renderTable()}
                    </div>
                </div>
            </div>
        );
    }

    renderFilters = () => {
        const { entity } = this.props;
        let filterFormPlusProps = {
            entity,
            component: 'FilterFormPlus',
            componentId: 'NewRoleManageFilterFormPlus',
            direction: 'inline',
            submit: {
                text: services.language.getText('common.search'),
            },
            reset: {
                text: services.language.getText('common.reset'),
                style: {
                    marginLeft: 16,
                },
                onClick() {
                    const store = builder.getStore();
                    store.dispatch(actions.filtersChangeAction(entity, {}));
                    entity.search();
                },
            },
            onSubmit: (e: any, value: any) => {
                const data = { ...entity.filters };
                const store = builder.getStore();
                delete data.parentId;
                store.dispatch(actions.filtersChangeAction(entity, data));
                store.dispatch(actions.pageChangeAction(entity, { ...entity.paging, page: 1 }));
                entity.search();
                this.setState({
                    selectedTreeNode: undefined,
                });
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 250 },
                    },
                },
                {
                    property: 'status',
                    controlConfig: {
                        style: { width: 250 },
                    },
                },
            ],
        };
        return createElement(ComponentsManager.get('FilterForm'), filterFormPlusProps);
    };

    renderTable = () => {
        const { entity } = this.props;
        const _this = this;
        let tableProps = {
            entity,
            componentId: 'NewRoleManageTable',
            rowKey: 'id',
            loadFirstPage: false,
            scroll: { x: true },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 220,
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'remark',
                    width: 220,
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '220px',
                        },
                    },
                },
                {
                    property: 'userNumber',
                    width: 100,
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '220px',
                        },
                    },
                },
                {
                    property: 'status',
                    width: 80,
                    displayConfig: {
                        statusConfig: {
                            ACTIVE: {
                                text: services.language.getText('enable'),
                                status: 'success',
                            },
                            INACTIVE: {
                                text: services.language.getText('disabled'),
                                status: 'error',
                            },
                        },
                    },
                },
                // { property: 'sequence', width: 80 },
            ],

            actionColumn: {
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                fixed: 'right',
                width: 200,
                style: { width: 200 },
                items: [
                    {
                        type: 'component',
                        component: 'RoleStatusButton',
                    },
                    {
                        statusKey: 'row.disable',
                        type: 'link',
                        text: services.language.getText('common.edit'),
                        path: '/new-role-manage/edit/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'EntityButton',
                        config: {
                            props: {
                                type: 'link',
                                children: services.language.getText('common.delete'),
                                onClick(a: any, config: any) {
                                    confirm({
                                        title: '',
                                        content: services.language.getText('confirmDelete'),
                                        onOk() {
                                            services.api
                                                .delete(
                                                    {},
                                                    {
                                                        apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
                                                        apiPath: `/roles/${config.row.id}`,
                                                    }
                                                )
                                                .then(() => {
                                                    message.success(
                                                        services.language.getText('sccg')
                                                    );
                                                    const { selectedTreeNode } = _this.state;
                                                    _this.loadTreeData(
                                                        selectedTreeNode && selectedTreeNode.id
                                                    );
                                                })
                                                .catch(errorHandle);
                                        },
                                        onCancel() {},
                                    });
                                },
                            },
                            propsHandler(
                                buttonProps: ButtonProps,
                                entityButtonProps: EntityButtonProps
                            ): ButtonProps {
                                const props: ButtonProps = {
                                    ...buttonProps,
                                    style: {
                                        paddingLeft: 0,
                                    },
                                };

                                props.disabled = !entityButtonProps.row.canDelete;
                                return props;
                            },
                        },
                    },
                ],
            },
        };
        return createElement(ComponentsManager.get('DataTable'), tableProps);
    };
}
