import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach, isEmpty, map } from 'lodash';
import moment from 'moment';
import { ReceiveLimitEnum } from '../../config/promotion/coupon/cash-coupon-rules';

const api = services.api;

export const LotteryCouponRulesLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        config.apiPath = '/admin/lottery_coupon_rules';
        const { id, type = '' } = data;
        if (id) {
            config.apiPath = `/admin/lottery_coupon_rules/${id}`;
        } else {
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.id = res.couponRule.id;
                res.baseInfo = {
                    name: res.couponRule.name,
                    dateRange: {
                        start: res.consumeEffectivePeriodRule.period.startTime,
                        end: res.consumeEffectivePeriodRule.period.endTime,
                    },
                    dynamicEffectivePeriod: res.consumeEffectivePeriodRule.dynamicEffectivePeriod,
                    batchNo: res.couponRule.code,
                    stock: {
                        maxCount: res.couponRule.stock,
                        changeCount: '',
                        surplusCount: res.couponRule.leftStock,
                        defalutMaxCount: res.couponRule.stock,
                        defalutSurplusCount: res.couponRule.leftStock,
                    },
                };
                let activityProductType = res.consumeRule.productScope;
                if (res.consumeRule.productScope === 'INCLUDE_PRODUCTS') {
                    activityProductType = 'INCLUDE_PRODUCT';
                } else if (res.consumeRule.productScope === 'EXCLUDE_PRODUCTS') {
                    activityProductType = 'EXCLUDE_PRODUCT';
                }
                let productsValue: any = null;
                if (res.goods && res.goods.length > 0) {
                    productsValue = {
                        type: 'APPOINT_PRODUCT',
                        values: res.goods.map((item: any) => {
                            return {
                                ...item,
                                name: item.productName,
                                code: item.goodsCode,
                                subsiteName: item.subsite.name,
                                merchantName: item.merchant.name,
                            };
                        }),
                    };
                }
                if (res.brands && res.brands.length > 0) {
                    productsValue = {
                        type: 'APPOINT_BRAND',
                        values: res.brands,
                    };
                }
                res.productInfo = {
                    selectMode: res.couponRule.subSiteOrMerchant,
                    shop: res.couponRule.subSiteMerchants || [],
                    subsite: res.couponRule.subsites || [],
                    activityProductType: activityProductType,
                    products: productsValue,
                    remark: res.couponRule.remark,
                };
                res.ruleInfo = {
                    couponValue: res.couponValue.value,
                    useThreshold: res.consumeRule.condition,
                    usedLowestLimitAmountValue:
                        res.consumeRule.condition === 'MONEY'
                            ? res.consumeRule.conditionLowerValue
                            : null,
                    usedLowestLimitQuantityValue:
                        res.consumeRule.condition === 'QUANTITY'
                            ? res.consumeRule.conditionLowerValue
                            : null,
                };
                let usedChannelLimit: any = [];
                let consumeRuleMemberLevels: any = [];
                let receiveRuleMemberLevels: any = [];
                forEach(res.consumeRule.dimensions, (dimension) => {
                    if (dimension.dimensionType === 'CHANNEL') {
                        usedChannelLimit = dimension.ids.map((value: any) => {
                            return value + '';
                        });
                    }
                    if (dimension.dimensionType === 'MEMBER_CARD_LEVEL') {
                        forEach(dimension.ids, (id: any) => {
                            consumeRuleMemberLevels.push({
                                id: id + '',
                            });
                        });
                    }
                });
                if (res.receiveRule.cardLevels && res.receiveRule.cardLevels.length > 0) {
                    forEach(res.receiveRule.cardLevels, (levelId: any) => {
                        receiveRuleMemberLevels.push({
                            id: levelId + '',
                        });
                    });
                }
                res.useRuleInfo = {
                    receivePeriod:
                        res.receiveRule.period.startTime && res.receiveRule.period.endTime
                            ? {
                                  start: res.receiveRule.period.startTime,
                                  end: res.receiveRule.period.endTime,
                              }
                            : undefined,
                    receiveLimit: res.receiveRule.receiveLimitType,
                    useLimitations:
                        consumeRuleMemberLevels.length > 0 ? 'MEMBER_LIMIT' : 'NO_LIMIT',
                    memberLevels: receiveRuleMemberLevels,
                    registerMemberSource: res.receiveRule.registerTimeLimit
                        ? res.receiveRule.registerTimeLimit.registerMemberSource
                        : 'ONLINE',
                    useLimitationsMode:
                        res.receiveRule.registerTimeLimit &&
                        res.receiveRule.registerTimeLimit.registerDays
                            ? 'DAY'
                            : 'DATE',
                    useLimitationsDay: res.receiveRule.registerTimeLimit?.registerDays || undefined,
                    useLimitationsDate: res.receiveRule.registerTimeLimit?.registerPeriod
                        ? {
                              start: moment(
                                  res.receiveRule.registerTimeLimit?.registerPeriod.startTime
                              ).format('YYYY-MM-DD'),
                              end: moment(
                                  res.receiveRule.registerTimeLimit?.registerPeriod.endTime
                              ).format('YYYY-MM-DD'),
                          }
                        : undefined,
                    receiveQuantityLimitType: res.receiveRule.receiveQuantityLimitType,
                    memberLimitQuantity:
                        res.receiveRule.receiveQuantityLimitType === 'EVERY_MEMBER_LIMIT' ||
                        res.receiveRule.receiveQuantityLimitType === 'EVERY_MEMBER_DAILY_LIMIT'
                            ? res.receiveRule.memberLimitQuantity
                            : null,
                    showChannels: res.showRules
                        ? res.showRules.map((value: any) => {
                              return value.showChannel;
                          })
                        : [],
                    usedChannelLimit: usedChannelLimit,
                    receiveLimitCrowds: res.receiveRule.crowdLimits || [],
                };
                res.instructions = {
                    info: res.couponRule.instructions,
                };
                if (
                    res.pictureConfigs &&
                    res.pictureConfigs.length > 0 &&
                    res.pictureConfigs[0].pictureId
                ) {
                    res.pictureInfo = {
                        couponPicture: [
                            {
                                id: res.pictureConfigs[0].pictureId || '',
                                path: res.pictureConfigs[0].url || '',
                            },
                        ],
                    };
                }
                res.seniorSetting = {
                    advanceTime:
                        res.expirationNoticeRule && res.expirationNoticeRule.advanceTime
                            ? res.expirationNoticeRule.advanceTime / 24 / 60
                            : undefined,
                };

                if (type === 'copydata') {
                    delete res.baseInfo.batchNo;
                    delete res.baseInfo.leftStock;
                }
            } else {
                res.result = res.result.map((item: any) => {
                    return {
                        ...item,
                        merchantName: item.merchants && item.merchants[0] && item.merchants[0].name,
                        info: {
                            name: item.name,
                            url:
                                item?.pictureConfigs?.[0]?.url ||
                                `https://b-puzhehei-cdn.co-mall.net/global/icon_coupon_center_lottery.png`,
                            startTime: item.startTime,
                            endTime: item.endTime,
                        },
                    };
                });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        config.apiPath = `/admin/lottery_coupon_rules`;
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        const newParams: any = dataParser(params);
        newParams.couponRule.id = params.id;
        if (params?.type === 'copydata') {
            config.apiPath = '/admin/lottery_coupon_rules';
            return await api.post(newParams, config);
        } else if (params.id) {
            config.apiPath = `/admin/lottery_coupon_rules/${params.id}`;
        }
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    const shopArray: any = [];
    const subsiteArray: any[] = [];
    if (params.productInfo.shop && params.productInfo.shop.length > 0) {
        forEach(params.productInfo.shop, (shop) => {
            shopArray.push(`${shop.subSiteId},${shop.merchantId}`);
        });
    }
    if (params.productInfo.subsite && params.productInfo.subsite.length > 0) {
        forEach(params.productInfo.subsite, (subsite) => {
            subsiteArray.push(subsite);
        });
    }
    newParams.couponRule = {
        code: params.baseInfo.batchNo,
        couponType: 'LOTTERY',
        instructions: params.instructions.info,
        merchants: params.productInfo.selectMode === 'MERCHANT' ? shopArray : [],
        name: params.baseInfo.name,
        subSiteOrMerchant: params.productInfo.selectMode,
        subsites: subsiteArray,
        stock: params.baseInfo.stock.maxCount,
        remark: params.productInfo.remark,
        source: 'OFFLINE',
        mode: 'GENERATE_COUPON_CODE',
    };
    newParams.consumeEffectivePeriodRule = {
        dynamicEffectivePeriod: params.baseInfo.dynamicEffectivePeriod,
        period: {
            startTime: params.baseInfo.dateRange.start,
            endTime: params.baseInfo.dateRange.end,
        },
    };
    newParams.couponValue = {
        type: 'TIMES',
        value: params.ruleInfo.couponValue,
    };
    if (
        params.pictureInfo &&
        params.pictureInfo.couponPicture &&
        params.pictureInfo.couponPicture[0]
    ) {
        newParams.pictureConfigs = [
            {
                pictureId: params.pictureInfo.couponPicture[0].id,
                type: 'COVER',
            },
        ];
    }
    let conditionLowerValue = null;
    if (params.ruleInfo.useThreshold === 'MONEY') {
        conditionLowerValue = params.ruleInfo.usedLowestLimitAmountValue;
    } else if (params.ruleInfo.useThreshold === 'QUANTITY') {
        conditionLowerValue = params.ruleInfo.usedLowestLimitQuantityValue;
    }
    newParams.consumeRule = {
        canBeConsumed: true,
        condition: params.ruleInfo.useThreshold,
        conditionLowerValue: conditionLowerValue,
        conditionUpperValue: null,
        dimensions: [
            {
                all: false,
                dimensionType: 'CHANNEL',
                ids:
                    params.useRuleInfo && params.useRuleInfo.usedChannelLimit
                        ? params.useRuleInfo.usedChannelLimit.map((value: any) => parseInt(value))
                        : [],
            },
        ],
        orderLimitQuantity: null,
    };
    if (params.productInfo.activityProductType) {
        if (params.productInfo.activityProductType === 'ALL') {
            newParams.consumeRule.dimensions.push({
                all: true,
                dimensionType: 'GOODS',
                ids: [-1],
            });
        } else if (
            params.productInfo.activityProductType === 'INCLUDE_PRODUCT' ||
            params.productInfo.activityProductType === 'EXCLUDE_PRODUCT'
        ) {
            let productsType = 'GOODS';
            if (params.productInfo.products && params.productInfo.products.type) {
                if (params.productInfo.products.type === 'APPOINT_PRODUCT') {
                    productsType = 'GOODS';
                } else if (params.productInfo.products.type === 'APPOINT_BRAND') {
                    productsType = 'BRAND';
                }
            }
            newParams.consumeRule.dimensions.push({
                all: params.productInfo.activityProductType === 'EXCLUDE_PRODUCT' ? true : false,
                dimensionType: productsType,
                ids:
                    params.productInfo.products && params.productInfo.products.values
                        ? params.productInfo.products.values.map((value: any) => value.id)
                        : [],
            });
        }
    }
    if (
        params.useRuleInfo.useLimitations &&
        params.useRuleInfo.useLimitations === 'MEMBER_LIMIT' &&
        params.useRuleInfo.receiveLimit === ReceiveLimitEnum.MEMBER_LIMIT
    ) {
        newParams.consumeRule.dimensions.push({
            all: false,
            dimensionType: 'MEMBER_CARD_LEVEL',
            ids: map(params.useRuleInfo.memberLevels, 'id'),
        });
    }
    newParams.receiveRule = {
        canBeReceiveWhenLackOfStock: false,
        canBeReceived: true,
        memberLimitQuantity:
            params.useRuleInfo.receiveQuantityLimitType === 'EVERY_MEMBER_LIMIT' ||
            params.useRuleInfo.receiveQuantityLimitType === 'EVERY_MEMBER_DAILY_LIMIT'
                ? params.useRuleInfo.memberLimitQuantity
                : null,
        orderLimitQuantity: null,
        period: {
            endTime: params.useRuleInfo.receivePeriod?.end ?? null,
            startTime: params.useRuleInfo.receivePeriod?.start ?? null,
        },
        receiveQuantityLimitType: params.useRuleInfo.receiveQuantityLimitType,
        cardLevels:
            params.useRuleInfo.receiveLimit === ReceiveLimitEnum.MEMBER_LIMIT
                ? map(params.useRuleInfo.memberLevels, 'id')
                : null,
        registerTimeLimit:
            params.useRuleInfo.receiveLimit === ReceiveLimitEnum.TIME_LIMIT
                ? {
                      registerMemberSource: params.useRuleInfo.registerMemberSource,
                      registerDays:
                          params.useRuleInfo.useLimitationsMode === 'DAY'
                              ? params.useRuleInfo.useLimitationsDay
                              : undefined,
                      registerPeriod:
                          params.useRuleInfo.useLimitationsMode === 'DATE'
                              ? {
                                    startTime:
                                        params.useRuleInfo.useLimitationsDate.start + ' 00:00:00',
                                    endTime:
                                        params.useRuleInfo.useLimitationsDate.end + ' 23:59:59',
                                }
                              : undefined,
                  }
                : undefined,
        crowdIds:
            params.useRuleInfo.receiveLimit === ReceiveLimitEnum.CROWD_LIMIT
                ? params.useRuleInfo.receiveLimitCrowds.map((i: any) => i.id)
                : undefined,
    };
    newParams.showRules =
        params.useRuleInfo.showChannels &&
        params.useRuleInfo.showChannels.map((value: any) => {
            const item: any = {};
            item.period = {
                endTime: params.baseInfo.dateRange.end,
                startTime: params.baseInfo.dateRange.start,
            };
            item.showChannel = value;
            return item;
        });
    if (params.seniorSetting) {
        newParams.expirationNoticeRule = params.seniorSetting.advanceTime
            ? {
                  advanceTime: Number(params.seniorSetting.advanceTime) * 24 * 60,
              }
            : null;
    }
    return newParams;
}
