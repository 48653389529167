import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { MemberCardDiscountTag } from './member-card-discount-tag';

export class ObjectMemberCardDiscountTagMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <MemberCardDiscountTag {...controlInfo} />;
    }
}
