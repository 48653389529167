import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
export const SecurityPolicyLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PASSPORT`;
        config.apiPath = '/admin/security_policy';

        return await api.get(data, config).then((res: any) => {
            const newRes: any = {
                registerSecurityPolicy: {},
                loginSecurityPolicy: {},
            };
            if (res) {
                newRes.registerSecurityPolicy.id = res.registerPolicy?.id;
                newRes.registerSecurityPolicy.tip = res.registerPolicy?.tip || '';
                newRes.registerSecurityPolicy.securityPolicyCondition = {
                    status: Boolean(res.registerPolicy?.status === 0),
                    virtualNumber:
                        res.registerPolicy?.virtualNumberCondition?.conditionQuantity || 0,
                    virtualNumberStatus: Boolean(
                        res.registerPolicy?.virtualNumberCondition?.status === 0
                    ),
                    channelTimeRange:
                        res.registerPolicy?.channelIdentification?.timeRange || 'MONTH',
                    channelExpression:
                        res.registerPolicy?.channelIdentification?.expression ||
                        'MORE_THAN_OR_EQUAL',
                    accountNumber: res.registerPolicy?.channelIdentification?.accountNumber,
                    channelStatus: Boolean(res.registerPolicy?.channelIdentification?.status === 0),
                };

                newRes.loginSecurityPolicy.id = res.loginPolicy?.id;
                newRes.loginSecurityPolicy.tip = res.loginPolicy?.tip || '';
                newRes.loginSecurityPolicy.securityPolicyCondition = {
                    status: Boolean(res.loginPolicy?.status === 0),
                    virtualNumber: res.loginPolicy?.virtualNumberCondition?.conditionQuantity || 0,
                    virtualNumberStatus: Boolean(
                        res.loginPolicy?.virtualNumberCondition?.status === 0
                    ),
                    channelTimeRange: res.loginPolicy?.channelIdentification?.timeRange || 'MONTH',
                    channelExpression:
                        res.loginPolicy?.channelIdentification?.expression || 'MORE_THAN_OR_EQUAL',
                    accountNumber: res.loginPolicy?.channelIdentification?.accountNumber,
                    channelStatus: Boolean(res.loginPolicy?.channelIdentification?.status === 0),
                };

                newRes.loginSecurityPolicy.userLoginDuration =
                    res.userLoginDuration?.userLoginDuration;
                newRes.loginSecurityPolicy.multiSessionLogin =
                    res.multiSessionLogin?.multiSessionLogin;
            }
            return newRes;
        });
    },

    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PASSPORT`;
        config.apiPath = '/admin/security_policy';
        const newParams = {
            registerPolicy: {
                id: params.registerSecurityPolicy.id,
                status: params.registerSecurityPolicy.securityPolicyCondition.status ? 0 : 1,
                tip: params.registerSecurityPolicy.tip,
                virtualNumberConditionStatus: params.registerSecurityPolicy.securityPolicyCondition
                    .virtualNumberStatus
                    ? 0
                    : 1,
                channelIdentification: {
                    timeRange:
                        params.registerSecurityPolicy.securityPolicyCondition.channelTimeRange,
                    expression:
                        params.registerSecurityPolicy.securityPolicyCondition.channelExpression,
                    accountNumber:
                        params.registerSecurityPolicy.securityPolicyCondition.accountNumber,
                    status: params.registerSecurityPolicy.securityPolicyCondition.channelStatus
                        ? 0
                        : 1,
                },
            },
            loginPolicy: {
                id: params.loginSecurityPolicy.id,
                status: params.loginSecurityPolicy.securityPolicyCondition.status ? 0 : 1,
                tip: params.loginSecurityPolicy.tip,
                virtualNumberConditionStatus: params.loginSecurityPolicy.securityPolicyCondition
                    .virtualNumberStatus
                    ? 0
                    : 1,
                channelIdentification: {
                    timeRange: params.loginSecurityPolicy.securityPolicyCondition.channelTimeRange,
                    expression:
                        params.loginSecurityPolicy.securityPolicyCondition.channelExpression,
                    accountNumber: params.loginSecurityPolicy.securityPolicyCondition.accountNumber,
                    status: params.loginSecurityPolicy.securityPolicyCondition.channelStatus
                        ? 0
                        : 1,
                },
            },
            multiSessionLogin: params.loginSecurityPolicy.multiSessionLogin,
            userLoginDuration: params.loginSecurityPolicy.userLoginDuration,
        };
        return await api.put(newParams, config);
    },
};
