import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { get } from 'lodash';

const api = services.api;

export const MerchantUserLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiPath = '/admin/merchant_users';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/merchant_users/${id}`;
        }
        return await api.get(data, config).then((result: any) => {
            if (id) {
                result.merchants = [result.merchant];
                result.status = result.status === 'ENABLED' ? true : false;
                result.avatar = result.avatar ? [result.avatar] : [];
                result.wechatCardPicture = result.wechatCardPicture
                    ? [result.wechatCardPicture]
                    : [];
            }
            return result;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        const subSiteId = get(params.merchants[0], 'subSiteId', null);
        const merchantId = get(params.merchants[0], 'merchantId', null);
        const newParams: {
            mobile: string;
            name: string;
            subSiteId: string;
            merchantId: string;
            status: false;
            avatar: string;
            wechatCardPicture: string;
        } = {
            name: params.name,
            mobile: params.mobile,
            status: params.status ? params.status : false,
            subSiteId: subSiteId,
            merchantId: merchantId,
            wechatCardPicture:
                params.wechatCardPicture && params.wechatCardPicture.length > 0
                    ? params.wechatCardPicture[0]
                    : null,
            avatar: params.avatar && params.avatar.length > 0 ? params.avatar[0] : null,
        };
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const subSiteId = get(params.merchants[0], 'subSiteId', null);
        const merchantId = get(params.merchants[0], 'merchantId', null);
        delete params.merchants;

        if (params.wechatCardPicture && params.wechatCardPicture.length > 0) {
            params.wechatCardPicture = params.wechatCardPicture[0];
        } else {
            params.wechatCardPicture = null;
        }
        if (params.avatar && params.avatar.length > 0) {
            params.avatar = params.avatar[0];
        } else {
            params.avatar = null;
        }
        if (!params.status) {
            params.status = false;
        }
        params.subSiteId = subSiteId;
        params.merchantId = merchantId;
        return await api.put(params, config);
    },
};
