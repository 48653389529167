import React, { Component } from 'react';
import { result, find, get } from 'lodash';
import { modes } from '@comall-backend-builder/types';
import { Select } from '@comall-backend-builder/components-basis';

export class RewardSettingOptionsSelectMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    public getAvailableDisplayComponent(value: any, displayInfo: any) {
        const { options } = displayInfo;

        if (options) {
            // 下拉选择框，通过key，查找显示的value值
            value = result(
                find(options, function(option) {
                    return option.id === String(value);
                }),
                'name'
            );
        }
        return (
            <div>
                <span>{value}</span>
            </div>
        );
    }

    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        // 下拉选择框
        return <SelectPlus {...controlInfo} />;
    }
}

class SelectPlus extends Component<any, any> {
    render() {
        const props: any = this.props;
        const { row, value } = props;
        const typeChecked = row && row.type && row.type.length > 0 ? row.type[0].checked : 'NONE';
        let type: any = get(row, 'type', undefined);
        let showTip = false;
        if (type && typeChecked === 'COUPON' && value === 'NO') {
            showTip = true;
        }
        return (
            <div>
                <Select
                    style={{ display: 'inline-block', width: '100px', margin: '0 5px' }}
                    {...props}
                />
                {showTip && (
                    <span style={{ marginLeft: '10px', color: 'red' }}>
                        选择优惠券时，请限制奖品数量
                    </span>
                )}
            </div>
        );
    }
}
