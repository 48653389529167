import { api, localStorage } from '@comall-backend-builder/core/lib/services';
import { get } from 'lodash';
export const SessionsLoader = {
    post: async function(
        data: any,
        {
            apiPath = '/oauth/token?response_type=password&grant_type=password',
            customHeaders = {},
            apiRoot = ENV.AUTH_API_ROOT,
        }: {
            apiPath?: string;
            apiRoot?: string;
            customHeaders?: any;
        }
    ) {
        const token = window.btoa(`${ENV.CLIENT_ID}:${ENV.CLIENT_SECRET}`);
        customHeaders = {
            'Content-Type': 'application/x-www-form-urlencoded',
            Authorization: `Basic ${token}`,
            ...customHeaders,
        };
        return await api.post(data, {
            customHeaders,
            apiPath,
            apiRoot,
        });
    },
    get: async function() {
        return await api.get(
            {},
            { apiPath: `/admin/user`, apiRoot: `${ENV.AUTH_API_ROOT}/CAE-AUTHORIZATION` }
        );
    },
    delete: async function({ apiPath, apiRoot }: { apiPath?: string; apiRoot?: string }) {
        const user: any = localStorage.get('user');
        const accessToken = get(user, 'accessToken', null);
        apiPath = `${apiPath}?access_token=${accessToken}`;
        const token = window.btoa(`${ENV.CLIENT_ID}:${ENV.CLIENT_SECRET}`);
        let customHeaders = {
            Authorization: `Basic ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        };
        return await api.delete({}, { apiPath, customHeaders, apiRoot });
    },
};
