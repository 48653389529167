import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import get from 'lodash/get';

const api = services.api;
export const MiniprogramCodeV2StaffLoader: Loader = {
    get(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/mini_program_codes';
        if (data.id) {
            config.apiPath = `/admin/mini_program_codes/${data.id}`;
        }
        data.applicationVersion = 'V2';
        return api.get(data, config).then((res: any) => {
            if (data.id) {
                if (res.target) {
                    res.target = JSON.parse(res.target);
                }
                res.subsiteId = String(res.subsiteId);
                res.merchantIds = res.subsiteMerchants;
            }
            return res;
        });
    },
    put(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/mini_program_codes/batch_target_link`;
        const newParams: any = {
            ids: id,
            applicationVersion: 'V2',
            showTab: 'GUIDE_CODE',
            type: get(params.target, 'linkType'),
            target: JSON.stringify(params.target),
        };
        return api.put(newParams, config);
    },
};

export const MiniprogramCodeV2MerchantLoader: Loader = {
    get(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/mini_program_codes';
        if (data.id) {
            config.apiPath = `/admin/mini_program_codes/${data.id}`;
        }
        data.applicationVersion = 'V2';
        return api.get(data, config).then((res: any) => {
            if (data.id) {
                if (res.target) {
                    res.target = JSON.parse(res.target);
                }
                res.subsiteId = String(res.subsiteId);
                res.merchantIds = res.subsiteMerchants;
            }
            return res;
        });
    },
    put(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/mini_program_codes/batch_target_link`;
        const newParams: any = {
            ids: id,
            applicationVersion: 'V2',
            showTab: 'MERCHANT_CODE',
            type: get(params.target, 'linkType'),
            target: JSON.stringify(params.target),
        };
        return api.put(newParams, config);
    },
};
