import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;
export const MerchantFacilityLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/facility';
        const { id } = data;
        if (!id) {
            if (!isEmpty(data.subsites)) {
                data.subsiteId = data.subsites.id;
                delete data.subsites;
            }
            if (!data.subsites) {
                delete data.subsites;
            }
            if (isEmpty(data.name)) {
                delete data.name;
            }
        } else {
            config.apiPath = config.apiPath + `/${id}`;
            data = {};
        }

        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.iconPicture = [res.showPicture];
                res.subsiteFloorObj = {
                    id: String(res.subsiteFloorId),
                    name: res.subsiteFloorName,
                };
                res.subsiteId = String(res.subsiteId);
                res.facilityTags.map((item: { id: string }) => {
                    item.id = String(item.id);
                    return item;
                });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/facility';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id, ...otherParams } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/facility/${params.id}`;
        const newParams: any = dataParser(otherParams);
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    const { iconPicture, subsiteFloorObj, ...otherParams } = params;
    if (iconPicture?.length) {
        newParams.showPictureId = params.iconPicture[0].id;
    }
    newParams.subsiteFloorId = subsiteFloorObj;
    newParams = {
        ...newParams,
        ...otherParams,
    };
    return newParams;
}
