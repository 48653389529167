import { services } from '@comall-backend-builder/core';
import { BaseConfig } from '../interfaces';

export const config: BaseConfig = {
    entities: {
        AuditGuideUserEntity: {
            apiPath: '/loader/cae/audit-user',
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            filters: {
                code: {
                    type: 'string',
                    displayName: '<<ygbh>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<ygxm>>',
                },
                loginName: {
                    type: 'string',
                    displayName: '<<dlzh>>',
                },
                organizationId: {
                    type: 'string.cascader',
                    displayName: '<<zzjg>>',
                    controlConfig: {
                        apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
                        apiPath: '/organizations',
                    },
                },
                subsiteId: {
                    type: 'string.options.select',
                    displayName: '<<lsmd>>',
                    options: [],
                    source: {
                        apiPath: '/loader/cae/organization-subsite',
                    },
                },
                merchantId: {
                    type: 'string.options.select',
                    displayName: '<<lszj>>',
                    options: [],
                    source: {
                        apiPath: '/loader/cae/organization-merchant',
                    },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<auditStatus>>',
                    options: [
                        {
                            id: 'WAITING',
                            name: '<<waiting>>',
                        },
                        {
                            id: 'APPROVED',
                            name: '<<approved>>',
                        },
                        {
                            id: 'REJECTED',
                            name: '<<shbtg>>',
                        },
                    ],
                },
                roleName: {
                    type: 'string',
                    displayName: '<<js_4>>',
                },
            },
            properties: {
                code: {
                    type: 'string',
                    displayName: '<<ygbm>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<ygxm>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                loginName: {
                    type: 'string',
                    displayName: '<<dlzh>>',
                },
                organizationName: {
                    type: 'string',
                    displayName: '<<zzjg>>',
                },
                userOrganizationInfo: {
                    type: 'object',
                },
                roleNames: {
                    type: 'array.popover',
                    displayName: '<<js_4>>',
                },
                merchant: {
                    type: 'array.popover',
                    displayName: '<<glmdzg>>',
                },

                status: {
                    type: 'string.statusBadge',
                    displayName: '<<auditStatus>>',
                    defaultValue: 'ACTIVE',
                    options: [
                        {
                            id: 'WAITING',
                            name: '<<waiting>>',
                        },
                        {
                            id: 'APPROVED',
                            name: '<<approved>>',
                        },
                        {
                            id: 'REJECTED',
                            name: '<<shbtg>>',
                        },
                    ],
                },
                createTime: {
                    type: 'string',
                    displayName: '<<sqsj>>',
                },

                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<zhjcxx>>',
                    properties: {
                        mobile: {
                            type: 'string.phone',
                            displayName: '<<phoneNum>>',
                            rules: [{ required: true, message: '<<qsrsjh>>' }],
                            controlConfig: {
                                placeholder: '<<qsrsjh>>',
                                disabled: true,
                                maxLength: 20,
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        loginName: {
                            type: 'string',
                            displayName: '<<dlzh>>',
                            rules: [{ required: true, message: '<<qsrdlzh>>' }],
                            controlConfig: {
                                placeholder: '<<qsrdlzh>>',
                                disabled: true,
                                maxLength: 32,
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        name: {
                            type: 'string',
                            displayName: '<<ygxm>>',
                            rules: [
                                { max: 100 },
                                { required: true, whitespace: true, message: '<<qsrygxm>>' },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrygxm>>',
                                maxLength: 100,
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        code: {
                            type: 'string',
                            displayName: '<<ygbm>>',
                            controlConfig: {
                                placeholder: '<<qsrygbm>>',
                                maxLength: 100,
                                style: {
                                    width: 400,
                                },
                            },
                        },
                    },
                },
                authInfo: {
                    type: 'object.subForm',
                    displayName: '<<qxsz>>',
                    properties: {
                        organizationId: {
                            type: 'object.organization.selector',
                            displayName: '<<sszzjg>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                // filterCustomOrganization: true,
                            },
                        },
                        privilegeInfos: {
                            type: 'array.organization.store',
                            displayName: '<<glmdzg>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        roleInfos: {
                            type: 'array.role.selector',
                            displayName: '<<gljs>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                hideRemark: true,
                            },
                        },
                        remark: {
                            type: 'string',
                            displayName: '<<remark>>',
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        isCreateMerchant: {
                            type: 'boolean.merchant.select',
                            displayName: '<<cjzjdy>>',
                            defaultValue: true,
                        },
                    },
                },
            },
        },
    },
    components: {
        AuditGuideUserView: {
            component: 'Viewport',
            entity: 'AuditGuideUserEntity',
        },
        AuditGuideUserPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'AuditGuideUserFilter' }, { component: 'AuditGuideUserTable' }],
        },
        AuditGuideUserFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'code',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'name',
                },
                {
                    property: 'loginName',
                },
                {
                    property: 'organizationId',
                },

                {
                    property: 'status',
                    controlConfig: {
                        style: { width: 180 },
                    },
                },
                {
                    property: 'roleName',
                },
            ],
        },

        AuditGuideUserTable: {
            component: 'DataTable',
            loadFirstPage: true,
            scroll: { x: true },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'code',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'name',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'mobile',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'loginName',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'organizationName',
                },
                {
                    property: 'roleNames',
                },
                {
                    property: 'merchant',
                },
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: {
                            APPROVED: {
                                text: '<<approved>>',
                                status: 'success',
                            },
                            WAITING: {
                                text: '<<waiting>>',
                                status: 'default',
                            },
                            REJECTED: {
                                text: '<<shbtg>>',
                                status: 'error',
                            },
                        },
                    },
                },
                {
                    property: 'createTime',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                style: { width: 200 },
                with: 200,
                items: [
                    {
                        text: '<<common.edit>>',
                        type: 'link',
                        statusKey: 'row.canEdit',
                        path: '/audit-guide-user/edit/{{row.id}}',
                    },
                    {
                        text: '<<audity>>',
                        statusKey: 'row.canEdit',
                        type: 'component',
                        component: 'AuditGuideUserButton',
                    },
                    {
                        text: '<<common.view>>',
                        type: 'link',
                        path: '/audit-guide-user/view/{{row.id}}',
                    },
                ],
            },
        },
        AuditGuideUserEditPage: {
            component: 'Card',
            entity: 'AuditGuideUserEntity',
            loaderType: 'get',
            content: {
                component: 'AuditGuideUserEditForm',
            },
        },
        AuditGuideUserViewPage: {
            component: 'Card',
            entity: 'AuditGuideUserEntity',
            loaderType: 'get',
            content: {
                component: 'AuditGuideUserViewForm',
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/audit-guide-user',
                    breadcrumbName: '<<auditGuideUser>>',
                    category: '<<auditGuideUser>>',
                    component: 'AuditGuideUserView',
                    privilege: {
                        path: 'shopping_guide_application_records_manage',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            breadcrumbName: '<<bjdgzh>>',
                            component: 'AuditGuideUserEditPage',
                        },
                        {
                            path: '/view/:id',
                            breadcrumbName: '<<ckdgzh>>',
                            component: 'AuditGuideUserViewPage',
                        },
                        { path: '/', component: 'AuditGuideUserPage' },
                    ],
                },
            ],
        },
    ],
};
