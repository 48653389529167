import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import { CheckboxGroup } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core';
const api = services.api;

export class CarparkRuleEntrancesMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <CarparkRuleEntrances {...displayInfo} />;
    }
}

enum RuleEntrances {
    GOLD_EXCHANGE_POINT = 'GOLD_EXCHANGE_POINT',
}

class CarparkRuleEntrances extends React.Component<any, { options: any[] }> {
    constructor(props: any) {
        super(props);
        this.state = {
            options: [],
        };
    }
    componentDidMount() {
        this.getGoldAlias();
    }
    getGoldAlias = async () => {
        const res: any = await api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                apiPath: '/admin/gold_exchange_rules',
            }
        );
        const { goldAlias } = res;
        this.setState({
            options: [
                {
                    name: `${goldAlias}兑积分`,
                    id: RuleEntrances.GOLD_EXCHANGE_POINT,
                },
            ],
        });
    };
    render() {
        const { options } = this.state;
        if (!options.length) {
            return null;
        }
        const displayInfo: any = this.props || {};
        return <CheckboxGroup {...displayInfo} options={options} />;
    }
}
