import React, { Component } from 'react';
import { default as Radio, RadioGroupProps as AntRadioGroupProps } from 'antd/lib/radio';
import { find, isEqual } from 'lodash';
import './index.less';
export interface GroupOption {
    disabled?: boolean;
    /**
     * 候选项id
     */
    id: string;
    /**
     * 候选项显示名称
     */
    name: string;
}

export interface RadioGroupProps extends Pick<AntRadioGroupProps, 'value' | 'disabled'> {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: string, name: string) => void;
    /**
     * 候选项集合
     */
    options: Array<GroupOption>;
    images: {
        [str: string]: any;
    };
    /**
     * 自定义样式类
     */
    className?: string;
    /**
     * 样式
     */
    style?: React.CSSProperties;
    /**
     * 提示语集合
     */
    tips?: Array<String>;
}

export class StringRadioImage extends Component<RadioGroupProps> {
    onChange = (e: any) => {
        const { name, onChange } = this.props;

        if (onChange) {
            onChange(e.target.value, name);
        }
    };
    componentWillReceiveProps(nextProps: any) {
        const { name, value, options, onChange, defaultValueIndex } = nextProps;

        if (isEqual(this.props.options, options)) {
            return;
        }

        if (value) {
            if (!find(options, { id: value })) {
                onChange(undefined, name);
            }
        } else if (defaultValueIndex !== undefined) {
            onChange(options[defaultValueIndex].id, name);
        }
    }

    shouldComponentUpdate(nextProps: any) {
        return !isEqual(this.props, nextProps);
    }
    render() {
        const { value, className, style, disabled, options, images, tips } = this.props;

        const radioGroupProps = {
            value,
            className,
            style,
            disabled,
            images,
        };
        const path = images[value];
        return (
            <>
                <Radio.Group {...radioGroupProps} onChange={this.onChange}>
                    {options.map((option) => {
                        return (
                            <Radio
                                value={option.id}
                                disabled={option.disabled ? option.disabled : false}
                            >
                                {option.name}
                            </Radio>
                        );
                    })}
                </Radio.Group>
                <img className="image-radio-preview" src={path} alt="" />
                {tips &&
                    tips.map((tip) => {
                        return <div className="image-radio-tip">{tip}</div>;
                    })}
            </>
        );
    }
}
