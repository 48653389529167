import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isNil, get, isNaN } from 'lodash';

import {
    MemberLevelAccessTypes,
    MemberLevelRelationTypes,
} from '../components/member-level-up-rule';
const api = services.api;

export const MemberLevelLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`;
        config.apiPath = '/admin/member_levels';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/member_levels/${id}`;
        }
        function processResult(item: any) {
            item.baseInfo = {
                name: item.name,
                iconPicture:
                    item.picUrl && item.picId
                        ? [
                              {
                                  id: item.picId,
                                  path: item.picUrl || '',
                              },
                          ]
                        : [],
            };

            if (item.memberLevelRuleNoteVo) {
                const {
                    totalNote,
                    videoNote,
                    topicNote,
                    totalLiked,
                    relation,
                    totalCollect,
                } = item.memberLevelRuleNoteVo;
                item.ruleConfig = {
                    memberLevelUpRule: {
                        accessConditionType: MemberLevelAccessTypes.GrowthRules,
                        totalNote,
                        videoNote,
                        topicNote,
                        totalLiked,
                        relation,
                        totalCollect,
                        // 默认值
                        expLower: 0,
                        expUpper: 0,
                    },
                };
            } else {
                item.ruleConfig = {
                    memberLevelUpRule: {
                        accessConditionType: MemberLevelAccessTypes.ExperienceValue,
                        expLower: item.expLower || 0,
                        expUpper: item.expUpper || 0,
                        // 默认值：
                        totalNote: 0,
                        videoNote: 0,
                        topicNote: 0,
                        totalLiked: 0,
                        relation: MemberLevelRelationTypes.Perhaps,
                        totalCollect: 0,
                    },
                };
            }

            item.ruleConfig.levelProcess = item.levelProcess === true ? 'true' : 'false';
            item.ruleConfig.validDays = item.validDays;
            item.ruleConfig.deduct = item.deduct;
            item.ruleConfig.description = item.description;
            return item;
        }

        return await api.get(data, config).then((res: any) => {
            if (id) {
                res = processResult(res);
            } else {
                if (res) {
                    res.result = res.result.map(processResult);
                }
            }
            return res;
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`;
        config.apiPath = `/admin/member_levels/${id}`;
        const newParams = dataParser(params, 'update');
        newParams.id = id;
        return await api.put(newParams, config);
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`;
        config.apiPath = `/admin/member_levels`;
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any, action: 'create' | 'update' = 'create') {
    let newParams: any = {};
    if (params.baseInfo) {
        newParams.name = params.baseInfo.name;
        const iconPicture = params.baseInfo.iconPicture;
        newParams.picId = iconPicture && iconPicture[0].id ? iconPicture[0].id : '';
    }
    if (params.ruleConfig) {
        const type = get(params, 'ruleConfig.memberLevelUpRule.accessConditionType');
        const memberLevelUpRule = get(params, 'ruleConfig.memberLevelUpRule') || {};
        const validDays = params.ruleConfig.validDays;
        const deduct = params.ruleConfig.deduct;
        const levelProcess = params.ruleConfig.levelProcess;
        newParams.levelProcess = levelProcess === 'true' ? true : false;
        newParams.description = params.ruleConfig.description;

        if (!isNil(validDays) && !isNaN(validDays)) {
            newParams.validDays = validDays;
        }
        if (!isNil(deduct) && !isNaN(deduct)) {
            newParams.deduct = deduct;
        }

        if (levelProcess === 'true') {
            if (type === MemberLevelAccessTypes.ExperienceValue) {
                newParams.expLower = memberLevelUpRule.expLower;
                newParams.expUpper = memberLevelUpRule.expUpper;
            }
            if (type === MemberLevelAccessTypes.GrowthRules) {
                newParams.createMemberLevelRuleNoteCommand = {
                    totalNote: memberLevelUpRule.totalNote,
                    videoNote: memberLevelUpRule.videoNote,
                    topicNote: memberLevelUpRule.topicNote,
                    totalLiked: memberLevelUpRule.totalLiked,
                    relation: memberLevelUpRule.relation,
                    totalCollect: memberLevelUpRule.totalCollect,
                };
            }

            if (action === 'update') {
                newParams.updateMemberLevelRuleNoteCommand =
                    newParams.createMemberLevelRuleNoteCommand;
                delete newParams.createMemberLevelRuleNoteCommand;
            }
        }
    }
    return newParams;
}
