import React, { PureComponent } from 'react';
import { Button } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core';
export class PageBackFooter extends PureComponent<any, any> {
    render() {
        return (
            <div>
                <Button route="goBack">{services.language.getText('back')}</Button>
            </div>
        );
    }
}
