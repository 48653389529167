import React, { PureComponent } from 'react';
import { Table, InputNumber, Input, Checkbox } from 'antd';
import { find } from 'lodash';
import { TipsModal } from '../../../../components';
import { services } from '@comall-backend-builder/core';

const { getText } = services.language;

export enum OpenInvoiceTabSequencesItemType {
    /**
     * 购物小票
     */
    ShoppingReceipt = 'SHOPPING_RECEIPT',
    /**
     * 停车订单
     */
    Carpark = 'CARPARK',
    /**
     * 商城订单
     */
    Order = 'ORDER',
}

interface OpenInvoiceTabSequencesData {
    id: number;
    name: string;
    code: string;
    value: Array<{
        orderType: OpenInvoiceTabSequencesItemType;
        status: boolean;
        sequence: string;
        supportScanCodeForInvoicing: boolean; //是否支持扫码开票
        supportfillInForInvoicing: boolean; //是否支持手工填票
        alias: string;
    }>;
}

interface OpenInvoiceTabSequencesProps {
    onChange: (value: OpenInvoiceTabSequencesData, name: string) => void;
    value: OpenInvoiceTabSequencesData;
    row: any;
    name: string;
    tipsModalConfig: any;
}
export class OpenInvoiceTabSequences extends PureComponent<OpenInvoiceTabSequencesProps, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    changeSequenceValue = (record: any, sequenceValue: string) => {
        const { value, onChange, name } = this.props;
        if (value) {
            let row: any = find(value.value, { orderType: record.orderType });
            if (row) {
                row.sequence = sequenceValue;
                onChange(value, name);
            }
        }
    };

    changeAliasValue = (record: any, nameValue: any) => {
        const { value, onChange, name } = this.props;
        if (value) {
            let row: any = find(value.value, { orderType: record.orderType });
            if (row) {
                row.alias = nameValue.target.value;
                onChange(value, name);
            }
        }
    };

    changeStatusValue = (record: any, e: any) => {
        const { value, onChange, name } = this.props;
        if (value) {
            let row: any = find(value.value, { orderType: record.orderType });
            if (row) {
                let checkboxValue = e.target.checked;
                if (record.orderType === OpenInvoiceTabSequencesItemType.ShoppingReceipt) {
                    row.supportScanCodeForInvoicing = false;
                }
                row.status = checkboxValue;
                onChange(value, name);
            }
        }
    };

    private get tableProps() {
        const { value: data } = this.props;
        if (!data) {
            return null;
        }
        return {
            dataSource: data.value,
            pagination: false as const,
            columns: [
                {
                    title: services.language.getText('display'),
                    dataIndex: 'status',
                    key: 'status',
                    render: (status: any, record: any) => {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', width: '30px' }}>
                                <Checkbox
                                    className="reward-content-item-checkbox"
                                    onChange={(e) => this.changeStatusValue(record, e)}
                                    checked={status ? true : false}
                                    value="point"
                                />
                            </div>
                        );
                    },
                },
                {
                    title: getText('ddlx'),
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: getText('qzz'),
                    dataIndex: 'sequence',
                    key: 'sequence',
                    render: (sequence: any, record: any) => {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', width: '120px' }}>
                                <InputNumber
                                    style={{ width: '120px' }}
                                    placeholder={services.language.getText('inputPlease')}
                                    value={sequence}
                                    min={1}
                                    max={999999999}
                                    precision={0}
                                    onChange={(value: any) =>
                                        this.changeSequenceValue(record, value)
                                    }
                                ></InputNumber>
                            </div>
                        );
                    },
                },
                {
                    title: getText('zdymc'),
                    dataIndex: 'alias',
                    key: 'alias',
                    render: (name: any, record: any) => {
                        return (
                            <div style={{ display: 'flex', alignItems: 'center', width: '150px' }}>
                                <Input
                                    value={name}
                                    placeholder={services.language.getText('inputPlease')}
                                    maxLength={6}
                                    onChange={(value: any) => this.changeAliasValue(record, value)}
                                ></Input>
                            </div>
                        );
                    },
                },
            ],
        };
    }

    onCheckboxChange = (event: any) => {
        let { name, value, onChange } = this.props;
        let checkboxValue = event.target.checked;
        const shoppingReceipt = find(value.value, {
            orderType: OpenInvoiceTabSequencesItemType.ShoppingReceipt,
        });
        if (shoppingReceipt) {
            shoppingReceipt.supportScanCodeForInvoicing = checkboxValue;
            onChange(value, name);
        }
    };

    onFillInChange = (event: any) => {
        let { name, value, onChange } = this.props;
        let checkboxValue = event.target.checked;
        const shoppingReceipt = find(value.value, {
            orderType: OpenInvoiceTabSequencesItemType.ShoppingReceipt,
        });
        if (shoppingReceipt) {
            shoppingReceipt.supportfillInForInvoicing = checkboxValue;
            onChange(value, name);
        }
    };

    render() {
        const { tipsModalConfig, value } = this.props;
        if (!value) {
            return null;
        }
        const shoppingReceipt = find(value.value, {
            orderType: OpenInvoiceTabSequencesItemType.ShoppingReceipt,
        });
        return (
            <div style={{ display: 'flex' }}>
                <div style={{ width: '600px' }}>
                    <Table {...this.tableProps}></Table>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <p style={{ color: '#ccc', margin: 0, marginRight: '10px' }}>
                            {getText('qzzszyd')}
                        </p>
                        {tipsModalConfig && <TipsModal {...tipsModalConfig} />}
                    </div>
                </div>
                {shoppingReceipt && shoppingReceipt.status && (
                    <div style={{ margin: '65px 0 0 40px' }}>
                        <Checkbox
                            className="reward-content-item-checkbox"
                            onChange={(e) => this.onCheckboxChange(e)}
                            checked={
                                shoppingReceipt && shoppingReceipt.supportScanCodeForInvoicing
                                    ? true
                                    : false
                            }
                            value="point"
                        >
                            {getText('smkp')}
                        </Checkbox>
                        <Checkbox
                            className="reward-content-item-checkbox"
                            onChange={(e) => this.onFillInChange(e)}
                            checked={
                                shoppingReceipt && shoppingReceipt.supportfillInForInvoicing
                                    ? true
                                    : false
                            }
                        >
                            {getText('sgtp')}
                        </Checkbox>
                    </div>
                )}
            </div>
        );
    }
}
