import { Component, createElement } from 'react';
import { connect } from 'react-redux';
import { message as AntMessage, Modal as AntModal } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { defaults, debounce, get, assign, find, reduce } from 'lodash';
import { actions, builder, Loader, services } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { navigation, language } from '@comall-backend-builder/core/lib/services';
import { errorHandle } from '../../applications/cae/services/error-handle';
import { GroupBuyingProductType } from '../../components';
import './index.less';

const GROUP_BUYING_ADD_VIEW_ID = 'GroupBuyingAddView';
export interface GroupBuyingProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
    modifiable?: boolean;
    // 选择的商品是否为优惠券
    isCoupon: boolean;
    //选择限购类型;
    saleLimitType: string;
    // 是否含有旧商品数据
    hasGoods: boolean;
}

class GroupBuyingAddLayout extends Component<GroupBuyingProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }

    formatGoods = (groupBuyingProductType: keyof typeof GroupBuyingProductType, goods: any[]) => {
        return goods.map((item: any) => {
            const result = {
                groupBuyingProductType,
                salesPrice: item.salesPrice,
                subsiteId: item.subsiteId || null,
                subsiteName: item.subsiteName || null,
                generalPrice: item.generalPrice,
                productCode: item.productCode ? String(item.productCode) : String(item.goodsCode), //有些地方选的是货品有些选的是商品，因不确定原需求，用此兼容写法？
                goodsCode: String(item.goodsCode),
                productName: item.productName,
                realStockQuantity: item.goodsStock,
                productId: item.productId,
                goodsId: item.goodsId,
                packageItemPrices: item.apportionPrices
                    ? item.apportionPrices.map((item: any) => {
                          return {
                              id: item.couponDefinitionId,
                              apportionPrice: item.apportionPrice,
                              quantity: item.quantity,
                          };
                      })
                    : [],
            };

            return result;
        });
    };

    getFields = (shouldValdate = false) => {
        const { saleLimitType, hasGoods } = this.props;
        const isSaleLimitNum = saleLimitType === 'YES';
        return [
            'baseSettings.name',
            'baseSettings.dateRange',
            'baseSettings.subsites',
            'baseSettings.marketingSchedule',

            'groupBuyingSettings.groupBuyingType',
            'groupBuyingSettings.groupBuyingLimit',
            'groupBuyingSettings.quantity',
            'groupBuyingSettings.groupBuyingTime',
            'groupBuyingSettings.hasAutoGroupBuyingTime',
            'groupBuyingSettings.autoGroupBuyingTime',
            'groupBuyingSettings.hasAutoGroupBuyingQuantity',
            'groupBuyingSettings.autoGroupBuyingQuantity',

            hasGoods && 'goodsSettings.goods',
            !hasGoods && 'goodsSettings.products',
            'goodsSettings.saleLimitType',
            isSaleLimitNum && 'goodsSettings.saleLimitNum',

            'groupBuyingAdvancedSettings.togetherFlag',
        ].filter(Boolean);
    };

    generateRequestBody = (values: any) => {
        const {
            baseSettings,
            baseSettings: { name, dateRange, subsites },
            groupBuyingSettings: {
                groupBuyingType,
                groupBuyingLimit,
                quantity,
                groupBuyingTime,
                hasAutoGroupBuyingTime,
                autoGroupBuyingTime,
                hasAutoGroupBuyingQuantity,
                autoGroupBuyingQuantity,
            },

            goodsSettings: { products, saleLimitType, saleLimitNum },
            groupBuyingAdvancedSettings: { togetherFlag },
            // useRulesInfo,
        } = values;
        let { marketingSchedule } = baseSettings;
        if (marketingSchedule) {
            marketingSchedule = marketingSchedule.length && marketingSchedule[0].id;
        }
        const groupBuyingProductType = products[0].groupBuyingProductType;
        const requestBody = {};

        assign(requestBody, {
            name,
            startTime: dateRange.start,
            endTime: dateRange.end,
            groupBuyingSubsiteCommands: subsites.map(
                ({ id: subsiteId, name: subsiteName }: any) => ({
                    subsiteId,
                    subsiteName,
                })
            ),

            groupBuyingType,
            groupBuyingLimit,
            quantity,
            groupBuyingTime,
            couponDefinitionId: products[0].virtualProductCouponDefinitionId,
            definitionType: products[0].virtualProductCouponDefinitionType,
            groupBuyingProductCommands: this.formatGoods(groupBuyingProductType, products),
            groupBuyingProductType,
            togetherFlag: togetherFlag.length ? true : false,
            hasAutoGroupBuyingTime: hasAutoGroupBuyingTime.length ? true : false,
            autoGroupBuyingTime,
            hasAutoGroupBuyingQuantity: hasAutoGroupBuyingQuantity.length ? true : false,
            autoGroupBuyingQuantity,
            marketingScheduleId: marketingSchedule,
            rules:
                saleLimitType === 'YES' && groupBuyingProductType === 'VIRTUAL_GOODS'
                    ? [
                          {
                              ruleType: 'EACH_PERIOD',
                              ruleCount: saleLimitNum,
                          },
                      ]
                    : null,
        });

        return requestBody;
    };

    handleSubmit = (e: any) => {
        e.preventDefault();
        const { isCoupon, getForm } = this.props;
        const form = getForm();
        const fields = this.getFields(!isCoupon);

        form.validateFieldsAndScroll(fields, (err: any, values: any) => {
            if (!err) {
                if (
                    values.goodsSettings.saleLimitType === 'YES' &&
                    !values.goodsSettings.saleLimitNum
                ) {
                    AntMessage.error(services.language.getText('qsryhxgsl'));
                    return false;
                }
                const products = values.goodsSettings.products;
                if (products && products.length > 0) {
                    const emptyPrice = find(products, (p) => !p.generalPrice);
                    if (emptyPrice && emptyPrice.length > 0) {
                        AntMessage.error(services.language.getText('qsrspptj'));
                        return false;
                    }
                    const emptyApportionPrice = find(products, (p) => {
                        return (
                            p.virtualProductCouponDefinitionType === 'COUPON_PACKAGE' &&
                            (!p.apportionPrices ||
                                p.apportionPrices.length < 0 ||
                                p.generalPrice !==
                                    reduce(
                                        p.apportionPrices,
                                        (s: any, n) => s + n.apportionPrice,
                                        0
                                    ))
                        );
                    });
                    if (emptyApportionPrice && emptyApportionPrice.length > 0) {
                        const errorInfo =
                            emptyApportionPrice.subsiteName +
                            services.language.getText('de') +
                            emptyApportionPrice.productName +
                            services.language.getText('ftjzhyptjbd');
                        AntMessage.error(errorInfo);
                        return false;
                    }
                }
                const onOk = async () => {
                    const {
                        params: { id },
                    } = this.props;
                    let method: 'post' | 'put' = id ? 'put' : 'post';
                    if (window.location.href.includes('/copy')) {
                        method = 'post';
                    }
                    const newPrams = this.generateRequestBody(values);
                    if (window.location.href.includes('/edit')) {
                        id && assign(newPrams, { id });
                    }
                    try {
                        await Loader.load(method, {
                            apiPath: `/loader/admin/group-buying`,
                            apiRoot: `${ENV.AUTH_API_ROOT}/GROUP-BUYING`,
                            params: newPrams,
                        });
                        AntMessage.success(language.getText('common.saveSuccess'));
                        setTimeout(navigation.goBack, 500);
                    } catch (e) {
                        errorHandle(e);
                    }
                };

                AntModal.confirm({
                    title: services.language.getText('sfqrtj'),
                    icon: 'exclamation-circle',
                    onOk: debounce(onOk, 500),
                });
            }
        });
    };

    renderForm = () => {
        const {
            entity,
            wrappedComponentRef,
            modifiable = true,
            params: { id },
            isCoupon,
        } = this.props;
        const fields = this.getFields().map((property) => ({ property, modifiable }));
        return {
            entity,
            wrappedComponentRef,
            fields,
            component: id ? 'ModifyForm' : 'CreateForm',
            componentId: GROUP_BUYING_ADD_VIEW_ID,
            direction: 'horizontal',
            className: isCoupon ? '' : 'use-rules-info--hide',
            labelCol: 4,
            controlCol: 18,
            submit: modifiable
                ? { text: services.language.getText('common.save'), onClick: this.handleSubmit }
                : null,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: services.language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
        };
    };

    render() {
        return createElement(ComponentsManager.get('Card'), {
            className: 'group-buying-add-form-container',
            content: this.renderForm(),
        });
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange } = props;
    const productsProperty = `components.${GROUP_BUYING_ADD_VIEW_ID}.fields.goodsSettings.products`;
    const goodsProperty = `components.${GROUP_BUYING_ADD_VIEW_ID}.fields.goodsSettings.goods`;
    const saleLimitTypeProperty = `components.${GROUP_BUYING_ADD_VIEW_ID}.fields.goodsSettings.saleLimitType`;
    let products = get(_dispatch, productsProperty, []);
    let goods = get(_dispatch, goodsProperty, []);
    const hasGoods = goods && goods.length > 0;
    let saleLimitType = get(_dispatch, saleLimitTypeProperty);
    let form: any;

    return defaults(
        {
            onSubmit,
            onFieldChange,
        },
        {
            isCoupon: products.some((item: any) => item.groupBuyingProductType === 'VIRTUAL_GOODS'),
            saleLimitType: saleLimitType,
            hasGoods,
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction(GROUP_BUYING_ADD_VIEW_ID));
            },
        }
    );
}

export const GroupBuyingAddView = connect(mapDispatchToProps)(GroupBuyingAddLayout);
