import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;
export const merchantBillLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/DATA-STATISTICS`;

        let paramsFilter = config.paramsFilter;
        let filter = paramsFilter && paramsFilter.length > 0 ? paramsFilter[0] : '';
        if (filter === 'saleBill') {
            delete config.paramsFilter;
            config.apiPath = '/admin/merchant_sale_bills';
        } else if (filter === 'bookkeepingBill') {
            delete config.paramsFilter;
            config.apiPath = '/admin/merchant_bookkeeping_bills';
        }

        if (!isEmpty(data.dateRange)) {
            data.startDate = data.dateRange.start;
            data.endDate = data.dateRange.end;
            delete data.dateRange;
        }
        return await api.get(data, config);
    },
};
