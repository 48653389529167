import React, { PureComponent, Fragment } from 'react';
import { services } from '@comall-backend-builder/core';
import { Button, Form, Input, Pagination, Spin, Checkbox, Icon, Popover } from 'antd';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { uniqBy, isBoolean, isNull, isEmpty } from 'lodash';
import { getUrlParam } from '../../../applications/cae/services/utils';
import SelectionPoolSearchResult from './part/search-result';
import SelectionPoolFilterClassify from './part/filter-product-classify';
import SelectionPoolFilterSupplier from './part/filter-product-supplier';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const { navigation } = services;

interface goodItem {
    barcode: string;
    brandName: string;
    goodsMainImage: string;
    id: string;
    goodsName: string;
    spuNo: string;
    price: string;
    tradeTypeList: Array<string>;
    tags: string[];
}
export interface responseSuccess {
    page: number;
    perPage: number;
    result: goodItem[];
    totalNum: number;
    totalPage: number;
}

interface responseBrand {
    initial: string;
    list: brandItem[];
}
export interface brandItem {
    brandId: number;
    id: number;
    brandImage: string;
    brandNameCn: string;
    brandNameEn: string;
    list: null | brandItem[];
}
export interface categorie {
    id: number;
    categoryId: number;
    categoryName: string;
    name: string;
    level: number;
    children: categorie[];
    parentId: number;
    path: string;
    typeName?: string;
    topParentId: number;
    itemType?: string;
}
interface responseQuery {
    brand_ids: string;
    categoryIds: string;
    filterErpBrand: boolean;
    filterSample: boolean;
    filterSellOut: boolean;
    keyword: string;
    tradeType: string;
    filterCategoryIds: string;
    supplierIds: string;
}
const tradeModes: Array<{ id: string; name: string }> = [
    {
        id: 'ALL',
        name: 'all',
    },
    {
        id: 'DOMESTIC_TRADE',
        name: 'domesticTrade',
    },
    {
        id: 'DUTY_PAID_IMPORT',
        name: 'dutyPaidImport',
    },
    {
        id: 'BONDED_DIRECT_SUPPLY',
        name: 'bondedDirectSupply',
    },
    {
        id: 'HONG_KONG_DIRECT',
        name: 'hongkongMail',
    },
    {
        id: 'OVERSEAS_DIRECT',
        name: 'overseasDirectMail',
    },
];
const CollectionCreateForm = Form.create({ name: 'searchForm' })(
    class extends React.Component {
        static propTypes: {
            onSearch: PropTypes.Requireable<(...args: any[]) => any>;
            onChange: PropTypes.Requireable<(...args: any[]) => any>;
            onCategoryChange: PropTypes.Requireable<(...args: any[]) => any>;
            onSupplierChange: PropTypes.Requireable<(...args: any[]) => any>;
            filterTypes: [];
            categoriesData: [];
            filterCategoryIds: [];
            supplierIds: [];
        };
        handleReset = () => {
            const { form } = this.props as any;
            form.resetFields();
        };
        handleSearch = (e: any) => {
            e.preventDefault();
            const { form, onSearch } = this.props as any;
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSearch(values);
                }
            });
        };
        select = (content: any) => {
            const { onChange } = this.props as any;
            if (onChange) {
                onChange(content);
            }
        };
        render() {
            const {
                form,
                filterTypes,
                categoriesData,
                filterCategoryIds,
                onCategoryChange,
                supplierIds,
                onSupplierChange,
            } = this.props as any;

            const { getFieldDecorator } = form;
            return (
                <Form layout="inline" ref="searchFrom">
                    <Form.Item label={language.getText('keywords')}>
                        {getFieldDecorator('keyword')(
                            <Input
                                className="find-filter-width"
                                placeholder={language.getText('keywordsErrorMessage')}
                            />
                        )}
                    </Form.Item>
                    <div className="selection-pool-page-filter__checkbox">
                        <Checkbox.Group value={filterTypes} onChange={this.select}>
                            <Checkbox
                                style={{ marginTop: 10, marginBottom: 5 }}
                                value="filterSellOut"
                            >
                                {services.language.getText('bbhwkcsp')}
                            </Checkbox>
                            <Checkbox
                                style={{ marginTop: 10, marginBottom: 5 }}
                                value="filterErpBrand"
                            >
                                {services.language.getText('bbhxxzjpp')}
                            </Checkbox>
                            <Checkbox style={{ marginBottom: 5 }} value="filterSample">
                                {services.language.getText('bbhyp')}
                            </Checkbox>
                        </Checkbox.Group>
                    </div>
                    <SelectionPoolFilterClassify
                        categoriesData={categoriesData}
                        filterCategoryIds={filterCategoryIds}
                        onCategoryChange={onCategoryChange}
                    />
                    <SelectionPoolFilterSupplier
                        supplierIds={supplierIds}
                        onSupplierChange={onSupplierChange}
                    />
                    <Form.Item>
                        <Button type="primary" onClick={this.handleSearch}>
                            {services.language.getText('common.search')}
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="reset" onClick={this.handleReset}>
                            {services.language.getText('common.reset')}
                        </Button>
                    </Form.Item>
                </Form>
            );
        }
    }
);

export class SelectionPoolFind extends PureComponent {
    state = {
        datasource: [],
        searchParams: {},
        total: 0,
        current: 0,
        pagination: {
            page: 1,
            perPage: 12,
        },
        loading: false,
        clientWidth: 0,
        brandData: [], //品牌展示数据
        categoriesData: [], //分类的全部数据
        originData: [], //产地
        isExpandGrand: false,
        isExpandParent: false,
        isExpandAssort: false,
        isExpandBrand: false,
        isExpandOrigin: false,
        allSelecded: [],
        filtersLoading: false,
        filterTypes: [],
        filterCategoryIds: [], //不展示的分类ID
        supplierIds: [], //选择的供应链
        activeMode: 'ALL',
        activeCategory: 0,
        isSearchView: false,
        //存储已选的路有传过来的分类数据
        categorys: [],
        keyword: '',
        selectedGrand: undefined,
        selectedParent: undefined,
        selectedBrands: [],
        selectedAssorts: [],
        selectedCategorys: [],
        parentData: [], //二级分类数据展示
        assortData: [], //三级分类数据展示
        selectedOrigin: undefined,
        searchCategoriesData: [],
    };
    private contentRef = React.createRef<HTMLDivElement>();

    componentDidMount() {
        const clientWidth: number = this.contentRef.current?.clientWidth || 0;
        const isSearchView = window.location.href.includes('category');
        this.setState({ clientWidth, isSearchView }, () => {
            this.getFitlerTypes();
        });
    }
    componentDidUpdate(prevProps: any, prevState: any) {
        const clientWidth: number = this.contentRef.current?.clientWidth || 0;
        if (prevState.clientWidth !== clientWidth) {
            this.setState({ clientWidth });
        }
    }

    onSearchFind = (params: { keyword?: string } = {}) => {
        const { searchParams, isSearchView } = this.state;
        this.setState(
            {
                pagination: {
                    page: 1,
                    perPage: 12,
                },
                keyword: params?.keyword,
                ...this.resetData(),
            },
            () => {
                if (isSearchView) {
                    this.getFilters(params?.keyword);
                }
                this.onSearch({
                    ...searchParams,
                    ...params,
                    brandIds: '',
                    categoryIds: '',
                    originName: '',
                });
            }
        );
    };
    resetData = () => {
        return {
            categorys: [],
            selectedGrand: undefined,
            selectedParent: undefined,
            selectedBrands: [],
            selectedAssorts: [],
            selectedCategorys: [],
            selectedOrigin: undefined,
            allSelecded: [],
            parentData: [],
            assortData: [],
        };
    };
    getFilters = async (keyword?: string) => {
        const { isSearchView } = this.state;
        this.setState({ filtersLoading: true });
        try {
            await this.getCategoriesList(keyword);
            if (isSearchView) {
                await this.getBrandList();
                await this.getOriginList();
            }
            this.setState({ filtersLoading: false });
        } catch (error) {
            this.setState({ filtersLoading: false });
            throw error;
        }
    };
    getFitlerTypes = async () => {
        const { searchParams, isSearchView } = this.state;
        try {
            let res: responseQuery = await services.api.get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                    apiPath: '/admin/goods/query_param',
                }
            );
            if (res) {
                const filterTypes: string[] = [];
                let keyword = getUrlParam(window.location.href, 'keyword');
                if (isBoolean(res.filterSellOut) && res.filterSellOut) {
                    filterTypes.push('filterSellOut');
                }
                if (isBoolean(res.filterErpBrand) && res.filterErpBrand) {
                    filterTypes.push('filterErpBrand');
                }
                if (isBoolean(res.filterSample) && res.filterSample) {
                    filterTypes.push('filterSample');
                }
                let state: Record<string, any> = {
                    filterTypes: filterTypes,
                };
                if (isSearchView) {
                    // if (!isNull(res.tradeType)) {
                    //     state.activeMode = res.tradeType;
                    // }
                    if (keyword) {
                        state.keyword = keyword;
                    }
                    let category = getUrlParam(window.location.href, 'category');
                    if (!isNull(category)) {
                        let newCategorys = category.split('_');
                        state.categorys = newCategorys;
                        state.selectedCategorys = [newCategorys[newCategorys.length - 1]];
                    }
                }
                const filterCategoryIds = !isEmpty(res.filterCategoryIds)
                    ? res.filterCategoryIds.split(',')
                    : null;
                if (filterCategoryIds) {
                    state.filterCategoryIds = filterCategoryIds;
                }

                const supplierIds = !isEmpty(res.supplierIds) ? res.supplierIds.split(',') : null;
                if (supplierIds) {
                    //屏蔽欧派供应链筛选条件
                    state.supplierIds = supplierIds.filter((supplier: string) => supplier !== '2');
                }
                this.setState(state, async () => {
                    let params: Record<string, string> = {
                        ...searchParams,
                    };
                    if (isSearchView) {
                        if (state?.selectedCategorys?.length) {
                            params.categoryIds =
                                state?.selectedCategorys[state?.selectedCategorys.length - 1];
                        }
                    }
                    await this.getFilters();
                    this.onSearch({
                        ...params,
                        filterSellOut: filterTypes.includes('filterSellOut'),
                        filterErpBrand: filterTypes.includes('filterErpBrand'),
                        filterSample: filterTypes.includes('filterSample'),
                        filterCategoryIds,
                        supplierIds,
                    });
                });
            }
        } catch (error) {
            this.onSearch();
            this.getFilters();
            throw error;
        }
    };
    onMouseEnterCls = (categorie: categorie) => {
        this.setState({ activeCategory: categorie.categoryId });
    };
    onMouseLeaveCls = () => {
        this.setState({ activeCategory: 0 });
    };
    onClickTradeMode = (id: string) => {
        const { searchParams, isSearchView, activeMode } = this.state;
        if (id === activeMode) {
            return;
        }
        const params: Record<string, string> = {
            ...searchParams,
        };
        if (id !== 'ALL') {
            params.tradeType = id;
        } else {
            delete params.tradeType;
        }
        this.setState({ activeMode: id, ...this.resetData() }, () => {
            if (isSearchView) {
                this.getBrandList();
                this.getOriginList();
            }
            this.onSearch({
                ...params,
                brandIds: '',
                categoryIds: '',
                originName: '',
            });
        });
    };
    onClickCategorie = (event: any, grandId: number, parentId?: number, id?: number) => {
        const { activeMode } = this.state as any;
        if (event) {
            event.stopPropagation();
        }
        let category;
        if (id) {
            category = grandId + '_' + parentId + '_' + id;
        } else if (parentId) {
            category = grandId + '_' + parentId;
        } else {
            category = grandId;
        }
        let aLink = document.createElement('a');
        let evt = document.createEvent('HTMLEvents');
        let url = `/#selection-pool?tradeTypeId=${activeMode}&category=${category}`;
        //initEvent 不加后两个参数在FF下会报错  事件类型，是否冒泡，是否阻止浏览器的默认行为
        evt.initEvent('click', true, true);
        aLink.href = url;
        aLink.target = '_blank';
        aLink.click();
    };

    onSearch = async (searchParams: object = {}) => {
        this.setState({ searchParams: searchParams, loading: true });
        const params: any = {
            ...searchParams,
        };
        if (!params.brandIds) {
            delete params.brandIds;
        }
        if (!params.categoryIds) {
            delete params.categoryIds;
        }
        if (!params.originName) {
            delete params.originName;
        }
        if (!params.filterCategoryIds) {
            delete params.filterCategoryIds;
        }
        if (!params.supplierIds) {
            delete params.supplierIds;
        }
        const { pagination } = this.state;
        try {
            let res: responseSuccess = await services.api.get(
                {
                    ...params,
                    ...pagination,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                    apiPath: '/admin/goods',
                }
            );
            if (res && res.result) {
                res.result = res.result.map((good: goodItem, index: number) => {
                    return {
                        ...good,
                        id: `${good.barcode}${index}`,
                    };
                });
                this.setState({
                    datasource: res.result,
                    total: res.totalNum,
                    current: res.page,
                    loading: false,
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            throw error;
        }
    };
    getBrandList = async () => {
        const params = await this.getParams();
        try {
            let res: responseBrand[] = await services.api.get(params, {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: '/admin/goods/brand_list',
            });
            res =
                res?.map((brand: responseBrand) => {
                    brand.list = brand?.list?.map((item: brandItem) => {
                        return {
                            ...item,
                            id: item.brandId,
                        };
                    });
                    return {
                        ...brand,
                    };
                }) || [];
            this.setState({
                brandData: res,
                isExpandBrand: false,
            });
        } catch (error) {
            this.setState({
                brandData: [],
                isExpandBrand: false,
            });
            throw error;
        }
    };
    getOriginList = async () => {
        const params = await this.getParams();
        try {
            let res: string[] = await services.api.get(params, {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: '/admin/goods/origin_list',
            });
            this.setState({
                originData: res ? res : [],
                isExpandOrigin: false,
            });
        } catch (error) {
            this.setState({
                originData: [],
                isExpandOrigin: false,
            });
            throw error;
        }
    };
    getParams = () => {
        const {
            keyword,
            filterTypes,
            activeMode,
            selectedCategorys,
            selectedBrands,
            filterCategoryIds,
            supplierIds,
        } = this.state as any;
        const params: Record<string, string> = {
            keyword,
            filterSellOut: filterTypes.includes('filterSellOut'),
            filterErpBrand: filterTypes.includes('filterErpBrand'),
            filterSample: filterTypes.includes('filterSample'),
        };
        if (activeMode !== 'ALL') {
            params.tradeType = activeMode;
        }
        if (selectedCategorys?.length) {
            params.categoryIds = selectedCategorys.join(',');
        }
        if (selectedBrands?.length) {
            params.brandIds = selectedBrands.join(',');
        }
        if (filterCategoryIds?.length) {
            params.filterCategoryIds = filterCategoryIds.join(',');
        }
        if (supplierIds?.length) {
            params.supplierIds = supplierIds.join(',');
        }
        return params;
    };
    getCategoriesList = async (keyWord?: string) => {
        const { isSearchView, categoriesData } = this.state;
        let params = isSearchView ? { keyWord } : {};
        try {
            let res: categorie[] = await services.api.get(params, {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: '/admin/categories',
            });
            if (res && res.length) {
                res = await this.setData(res);
                this.setState({
                    categoriesData: keyWord ? categoriesData : res,
                    isExpandGrand: false,
                    searchCategoriesData: isSearchView ? res : [],
                });
            }
            if (!res?.length) {
                this.setState({
                    categoriesData: keyWord ? categoriesData : [],
                    isExpandGrand: false,
                    searchCategoriesData: [],
                });
            }
        } catch (error) {
            throw error;
        }
    };
    setData = (res: categorie[]) => {
        const { categorys } = this.state;
        let selectedGrand = undefined;
        let selectedParent = undefined;
        let selectedAssorts: Array<string | number> = [];
        let parentData: categorie[] = [];
        let assortData: Array<categorie> = [];
        let allSelecded: Array<categorie> = [];
        let data = res.map((categories: categorie) => {
            if (categorys?.length && String(categories.categoryId) === categorys[0]) {
                selectedGrand = categories.categoryId;
                allSelecded.push({
                    ...categories,
                    name: categories.categoryName,
                    id: categories.categoryId,
                    itemType: 'Grand',
                });
                if (categories?.children?.length) {
                    parentData = categories.children;
                } else {
                    parentData.push(categories);
                }
            }
            categories.children =
                categories?.children?.map((t: categorie) => {
                    if (categorys?.length && String(t.categoryId) === categorys[1]) {
                        selectedParent = t.categoryId;
                        allSelecded.push({
                            ...t,
                            name: t.categoryName,
                            id: t.categoryId,
                            itemType: 'Parent',
                        });
                        if (t?.children?.length) {
                            assortData = t.children;
                        }
                    }
                    t.children =
                        t?.children?.map((s: categorie) => {
                            if (categorys?.length && String(s.categoryId) === categorys[2]) {
                                selectedAssorts = [s.categoryId];
                                allSelecded.push({
                                    ...s,
                                    name: s.categoryName,
                                    id: s.categoryId,
                                    itemType: 'Assort',
                                });
                            }
                            return {
                                ...s,
                                parentId: t.categoryId,
                                topParentId: categories.categoryId,
                            };
                        }) || [];
                    return {
                        ...t,
                        parentId: categories.categoryId,
                    };
                }) || [];
            return {
                ...categories,
            };
        });
        this.setState({
            selectedGrand,
            selectedParent,
            selectedAssorts,
            parentData,
            assortData,
            allSelecded,
        });
        return data;
    };
    pageChange = (pageVal: object = {}) => {
        const { searchParams } = this.state;
        this.setState({ pagination: { ...pageVal } }, () => {
            this.onSearch(searchParams);
        });
    };
    onChangeFiterType = (value: string[]) => {
        const { searchParams, isSearchView } = this.state;
        let params: object = {
            filterSellOut: value.includes('filterSellOut'),
            filterErpBrand: value.includes('filterErpBrand'),
            filterSample: value.includes('filterSample'),
            brandIds: '',
            categoryIds: '',
            originName: '',
        };
        this.setState(
            {
                filterTypes: value,
                ...this.resetData(),
            },
            async () => {
                if (isSearchView) {
                    this.getBrandList();
                    this.getOriginList();
                }
                this.onSearch({
                    ...searchParams,
                    ...params,
                });
            }
        );
    };
    onCategoryChange = (value: string[]) => {
        const { searchParams, isSearchView } = this.state;
        let params: object = {
            filterCategoryIds: value?.length ? value.join(',') : null,
            brandIds: '',
            categoryIds: '',
            originName: '',
        };
        this.setState(
            {
                filterCategoryIds: value,
                ...this.resetData(),
            },
            async () => {
                if (isSearchView) {
                    this.getBrandList();
                    this.getOriginList();
                }
                this.onSearch({
                    ...searchParams,
                    ...params,
                });
            }
        );
    };
    onSupplierChange = (value: string[]) => {
        const { searchParams, isSearchView } = this.state;
        let params: object = {
            supplierIds: value?.length ? value.join(',') : null,
            brandIds: '',
            categoryIds: '',
            originName: '',
        };
        this.setState(
            {
                supplierIds: value,
                ...this.resetData(),
            },
            async () => {
                if (isSearchView) {
                    this.getBrandList();
                    this.getOriginList();
                }
                this.onSearch({
                    ...searchParams,
                    ...params,
                });
            }
        );
    };
    onChangeCheckBox = async (value: Array<any>, type: string) => {
        const { searchParams, allSelecded, assortData, brandData, selectedParent } = this.state;
        const data = type === 'Classifys' ? assortData : brandData;
        let selectedVals: any = [];
        let newSelectedAll: any =
            type === 'Classifys' //如果选中为三级分类则底下品牌和产地都清除  //选中的是品牌则产地的选中数据清掉
                ? allSelecded.filter(
                      (item: categorie) =>
                          item.itemType !== type &&
                          item.itemType !== 'Brands' &&
                          item.itemType !== 'Origin'
                  )
                : allSelecded.filter(
                      (item: categorie) => item.itemType !== type && item.itemType !== 'Origin'
                  );
        if (!value.length) {
            selectedVals = newSelectedAll;
        } else {
            data.forEach((item: any) => {
                let key = type === 'Classifys' ? item.categoryId : item.brandId;
                let index = value.findIndex((v: number) => v === key);
                if (index > -1) {
                    selectedVals.push({
                        ...item,
                        id: item?.categoryId || item?.brandId,
                        name: item?.categoryName || item?.brandNameCn,
                        itemType: type,
                    });
                }
            });
        }
        let newAll = !value.length
            ? selectedVals
            : uniqBy(newSelectedAll.concat(selectedVals), 'id');
        if (type === 'Classifys') {
            this.setState(
                {
                    selectedAssorts: value,
                    allSelecded: newAll,
                    selectedCategorys: value.length ? value : [selectedParent],
                    selectedBrands: [],
                    selectedOrigin: undefined,
                },
                () => {
                    this.getBrandList();
                    this.getOriginList();
                    this.onSearch({
                        ...searchParams,
                        categoryIds: value?.length ? value.join(',') : selectedParent,
                        brandIds: '',
                        originName: '',
                    });
                }
            );
        } else {
            this.setState(
                {
                    selectedBrands: value,
                    allSelecded: newAll,
                    selectedOrigin: undefined,
                },
                () => {
                    this.getOriginList();
                    this.onSearch({
                        ...searchParams,
                        brandIds: value?.length ? value.join(',') : '',
                        originName: '',
                    });
                }
            );
        }
    };
    clearSelecded = (item: any, type: string = '') => {
        const {
            searchParams,
            allSelecded,
            selectedAssorts,
            selectedBrands,
            selectedParent,
        } = this.state;
        if (type) {
            this.setState(
                {
                    ...this.resetData(),
                },
                () => {
                    this.getBrandList();
                    this.getOriginList();
                    this.onSearch({
                        ...searchParams,
                        brandIds: '',
                        categoryIds: '',
                        originName: '',
                    });
                }
            );
            return;
        }
        if (item.itemType === 'Grand') {
            this.setState(
                {
                    ...this.resetData(),
                },
                () => {
                    this.getBrandList();
                    this.getOriginList();
                    this.onSearch({
                        ...searchParams,
                        brandIds: '',
                        categoryIds: '',
                        originName: '',
                    });
                }
            );
            return;
        }
        if (item.itemType === 'Parent') {
            let newAll = allSelecded.filter((item: categorie) => item.itemType === 'Grand');
            this.setState(
                {
                    selectedCategorys: [item.parentId],
                    selectedParent: undefined,
                    selectedAssorts: [],
                    selectedOrigin: undefined,
                    assortData: [],
                    selectedBrands: [],
                    allSelecded: newAll,
                },
                () => {
                    this.getBrandList();
                    this.getOriginList();
                    this.onSearch({
                        ...searchParams,
                        brandIds: '',
                        categoryIds: item.parentId,
                        originName: '',
                    });
                }
            );
            return;
        }
        if (item.itemType === 'Classifys') {
            let newAll = allSelecded.filter(
                (c: categorie) =>
                    c.categoryId !== item.categoryId &&
                    c.itemType !== 'Brands' &&
                    c.itemType !== 'Origin'
            );
            let selectCls = selectedAssorts.filter((s: number) => s !== item.id);
            let selectedIds = selectCls?.length ? selectCls.join(',') : '';
            this.setState(
                {
                    selectedCategorys: selectCls?.length ? selectCls : [selectedParent],
                    selectedAssorts: selectCls,
                    selectedOrigin: undefined,
                    selectedBrands: [],
                    allSelecded: newAll,
                },
                () => {
                    this.getBrandList();
                    this.getOriginList();
                    this.onSearch({
                        ...searchParams,
                        brandIds: '',
                        categoryIds: selectedIds ? selectedIds : selectedParent,
                        originName: '',
                    });
                }
            );
            return;
        }
        if (item.itemType === 'Brands') {
            let newAll = allSelecded.filter(
                (c: categorie) => c.id !== item.brandId && c.itemType !== 'Origin'
            );
            let selectBs = selectedBrands.filter((s: number) => s !== item.id);
            let selectedIds = selectBs?.length ? selectBs.join(',') : '';
            this.setState(
                {
                    selectedBrands: selectBs,
                    selectedOrigin: undefined,
                    allSelecded: newAll,
                },
                () => {
                    this.getOriginList();
                    this.onSearch({
                        ...searchParams,
                        brandIds: selectedIds,
                        originName: '',
                    });
                }
            );
            return;
        }
        if (item.itemType === 'Origin') {
            let newAll = allSelecded.filter((c: categorie) => c.name !== item);
            this.setState(
                {
                    selectedOrigin: undefined,
                    allSelecded: newAll,
                },
                () => {
                    this.onSearch({
                        ...searchParams,
                        originName: '',
                    });
                }
            );
        }
    };
    private onClickFold = (operateType: string) => {
        const {
            isExpandGrand,
            isExpandParent,
            isExpandBrand,
            isExpandOrigin,
            isExpandAssort,
        } = this.state;
        if (operateType === 'Grand') {
            this.setState({ isExpandGrand: !isExpandGrand });
        } else if (operateType === 'Parent') {
            this.setState({ isExpandParent: !isExpandParent });
        } else if (operateType === 'Brand') {
            this.setState({ isExpandBrand: !isExpandBrand });
        } else if (operateType === 'Origin') {
            this.setState({ isExpandOrigin: !isExpandOrigin });
        } else if (operateType === 'Assort') {
            this.setState({ isExpandAssort: !isExpandAssort });
        }
    };
    private onSelectClassify = (item: categorie, type: string) => {
        const {
            searchCategoriesData,
            parentData,
            allSelecded,
            searchParams,
            selectedGrand,
            selectedParent,
        } = this.state as any;
        if (type === 'Grand') {
            if (item.categoryId === selectedGrand) {
                return;
            }
            let parentData: categorie[] = [];
            let index = (searchCategoriesData as categorie[]).findIndex(
                (categorie: categorie) => categorie.categoryId === item.categoryId
            );
            if (index > -1) {
                parentData = searchCategoriesData[index].children;
            }
            this.setState(
                {
                    selectedGrand: item.categoryId,
                    selectedCategorys: [item.categoryId],
                    selectedParent: undefined,
                    parentData,
                    selectedAssorts: [],
                    selectedOrigin: undefined,
                    brandData: [],
                    originData: [],
                    assortData: [],
                    selectedBrands: [],
                    allSelecded: [
                        {
                            ...item,
                            id: item.categoryId,
                            name: item.categoryName,
                            itemType: 'Grand',
                        },
                    ],
                },
                () => {
                    this.getBrandList();
                    this.getOriginList();
                    this.onSearch({
                        ...searchParams,
                        categoryIds: item.categoryId,
                        brandIds: '',
                        originName: '',
                    });
                }
            );
        }
        if (type === 'Parent') {
            if (item.categoryId === selectedParent) {
                return;
            }
            let assortData: categorie[] = [];
            let index = (parentData as categorie[]).findIndex(
                (categorie: categorie) => categorie.categoryId === item.categoryId
            );
            if (index > -1) {
                assortData = parentData[index].children;
            }
            let newAllSelecded = allSelecded.filter((item: categorie) => item.itemType === 'Grand');
            newAllSelecded.push({
                ...item,
                id: item.categoryId,
                name: item.categoryName,
                itemType: 'Parent',
            });
            this.setState(
                {
                    selectedParent: item.categoryId,
                    selectedCategorys: [item.categoryId],
                    selectedAssorts: [],
                    selectedOrigin: undefined,
                    brandData: [],
                    originData: [],
                    assortData,
                    allSelecded: newAllSelecded,
                    selectedBrands: [],
                },
                () => {
                    this.getBrandList();
                    this.getOriginList();
                    this.onSearch({
                        ...searchParams,
                        categoryIds: item.categoryId,
                        brandIds: '',
                        originName: '',
                    });
                }
            );
        }
    };
    private onSelectOrigin = (item: string, index: number) => {
        const { allSelecded, searchParams, selectedOrigin } = this.state;
        if (item === selectedOrigin) {
            return;
        }
        let newAllSelecded: any = allSelecded.filter(
            (item: categorie) => item.itemType !== 'Origin'
        );
        newAllSelecded.push({
            id: index,
            name: item,
            itemType: 'Origin',
        });
        this.setState(
            {
                allSelecded: newAllSelecded,
                selectedOrigin: item,
            },
            () => {
                this.onSearch({
                    ...searchParams,
                    originName: item,
                });
            }
        );
    };
    private goRiskCommodityListPage = () => {
        navigation.goto('/selection-pool/risk-goods');
    };

    private gotoDetail = (spuNo: string) => {
        window.open(`#/selection-pool/detail/${encodeURIComponent(spuNo)}`, '_blank');
        // window.location.href = `#/selection-pool/detail/${encodeURIComponent(spuNo)}`;
    };

    private renderProductList = () => {
        const { datasource, pagination, total, current } = this.state;
        const paginationCongid = {
            pageSize: pagination.perPage,
            // showSizeChanger: true,
            showQuickJumper: true,
            // pageSizeOptions: ['12', '18', '24'],
            total,
            current,
            showTotal(total: number) {
                return `共 ${total} 条记录`;
            },
            onChange: (page: number | undefined, perPage: number | undefined) => {
                this.pageChange({ page, perPage });
            },
            onShowSizeChange: (page: number | undefined, perPage: number | undefined) => {
                this.pageChange({ page, perPage });
            },
        };
        const pic = require('./images/no_data-pic.png');
        if (!datasource || !datasource?.length) {
            return (
                <div className="selection-pool-page-content__no-data">
                    <img alt="" src={pic} />
                    <span>{language.getText('zwsp')}</span>
                </div>
            );
        }
        return (
            <Fragment>
                <div className="selection-pool-page-content-list">
                    {datasource.map(this.renderProductItem)}
                </div>
                <div className="selection-pool-page-content-pagination">
                    <Pagination {...paginationCongid} />
                </div>
            </Fragment>
        );
    };

    private renderProductItem = (item: goodItem, index: number) => {
        const { clientWidth } = this.state;
        const itemWidth = (clientWidth - 40) / 6;
        const itemKey = `${item.id}-${index}`;
        const sortTags = item.tags && item.tags.length > 0 && item.tags.slice().reverse();
        const tagsString = item.tags && item.tags.length > 0 ? item.tags.join(', ') : '';
        return (
            <div
                className={classNames('selection-pool-page-content-list-item', {
                    'selection-pool-page-content-list-item__no-margin':
                        index && (index + 1) % 6 === 0,
                })}
                style={{ width: itemWidth }}
                key={itemKey}
                onClick={() => this.gotoDetail(item.spuNo)}
            >
                <div
                    style={{ height: itemWidth }}
                    className="selection-pool-page-content-list-item__pic"
                >
                    <img src={item.goodsMainImage} alt="" />
                </div>
                <div className="selection-pool-page-content-list-item__main">
                    <div className="selection-pool-page-content-list-item__brand">
                        {item?.tradeTypeList.map((trade: string, index: number) => (
                            <span
                                key={index}
                                className="selection-pool-page-content-list-item__brand-trade"
                            >
                                {trade && trade.substring(0, 2)}
                            </span>
                        ))}
                        <span className="selection-pool-page-content-list-item__brand-name">
                            {item.brandName}
                        </span>
                    </div>
                    <div className="selection-pool-page-content-list-item__name">
                        {item.goodsName}
                    </div>
                    <div className="selection-pool-page-content-list-item__infos">
                        <div className="selection-pool-page-content-list-item__infos-price">
                            <span>
                                {services.language.getText('monetaryUnit')}
                                {item.price}
                            </span>{' '}
                            起
                        </div>
                    </div>

                    {tagsString.length > 18 ? (
                        <Popover
                            placement={'top'}
                            content={
                                <div style={{ maxWidth: 300 }}>
                                    {sortTags &&
                                        sortTags.map((i: string) => (
                                            <span
                                                className="selection-pool-page-content-list-item__tags-tag"
                                                key={i}
                                            >
                                                {i}
                                            </span>
                                        ))}
                                </div>
                            }
                        >
                            <div className="selection-pool-page-content-list-item__tags">
                                {sortTags &&
                                    sortTags.map((i: string) => (
                                        <span
                                            className="selection-pool-page-content-list-item__tags-tag"
                                            key={i}
                                        >
                                            {i}
                                        </span>
                                    ))}
                            </div>
                        </Popover>
                    ) : (
                        <div className="selection-pool-page-content-list-item__tags">
                            {sortTags &&
                                sortTags.map((i: string) => (
                                    <span
                                        className="selection-pool-page-content-list-item__tags-tag"
                                        key={i}
                                    >
                                        {i}
                                    </span>
                                ))}
                        </div>
                    )}
                </div>
            </div>
        );
    };
    renderTopClassifys = () => {
        const { categoriesData, activeCategory, clientWidth } = this.state;
        if (!categoriesData || !categoriesData?.length) {
            return null;
        }
        const hStyle = {
            width: `${clientWidth - 185}px`,
        };
        const name = `selection-pool-page__top__popover__classify-popover`;
        return (
            <div className="selection-pool-page__top__classify-wrap">
                <div
                    className="selection-pool-page__top__classify-wrap__main"
                    onMouseLeave={this.onMouseLeaveCls.bind(this)}
                >
                    {categoriesData.map((categorie: categorie) => (
                        <div
                            key={categorie.categoryId}
                            onClick={(e) => this.onClickCategorie(e, categorie.categoryId)}
                        >
                            <Popover
                                placement={'rightTop'}
                                overlayClassName={name}
                                content={
                                    <div
                                        style={hStyle}
                                        className="selection-pool-page__top__classify-wrap__r"
                                    >
                                        {categorie.children?.map((t: categorie) => (
                                            <div
                                                className="selection-pool-page__top__classify-wrap__r__main"
                                                key={t.categoryId}
                                                onClick={(e) =>
                                                    this.onClickCategorie(
                                                        e,
                                                        categorie.categoryId,
                                                        t.categoryId
                                                    )
                                                }
                                            >
                                                <div
                                                    className={classNames(
                                                        'selection-pool-page__top__classify-wrap__r__main-title',
                                                        {
                                                            'selection-pool-page__top__classify-wrap__r__main-title__c':
                                                                t.children?.length,
                                                            'selection-pool-page__top__classify-wrap__r__main-title__n': !t
                                                                .children?.length,
                                                        }
                                                    )}
                                                >
                                                    <div className="name">{t.categoryName}</div>
                                                    {t?.children?.length > 0 && (
                                                        <Icon type="right"></Icon>
                                                    )}
                                                </div>
                                                <div className="selection-pool-page__top__classify-wrap__r__main-f">
                                                    {t?.children?.map((f: categorie) => (
                                                        <div
                                                            className="selection-pool-page__top__classify-wrap__r__main-f__item"
                                                            key={f.categoryId}
                                                            onClick={(e) =>
                                                                this.onClickCategorie(
                                                                    e,
                                                                    categorie.categoryId,
                                                                    t.categoryId,
                                                                    f.categoryId
                                                                )
                                                            }
                                                        >
                                                            {f.categoryName}
                                                            <span></span>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }
                            >
                                <div
                                    className={classNames(
                                        'selection-pool-page__top__classify-wrap__main-o',
                                        {
                                            'selection-pool-page__top__classify-wrap__main-o__active':
                                                categorie.categoryId === activeCategory,
                                        }
                                    )}
                                    onMouseEnter={this.onMouseEnterCls.bind(this, categorie)}
                                >
                                    {categorie.categoryName}
                                </div>
                            </Popover>
                        </div>
                    ))}
                </div>
            </div>
        );
    };

    private renderFiters = () => {
        const { activeMode } = this.state;
        const icon = require('./images/Icon_save.png');
        const name = `selection-pool-page__top__popover`;
        return (
            <div className="selection-pool-page__top__filters">
                <div className="selection-pool-page__top__filters__content-wrap">
                    <div className="selection-pool-page__top__filters__content">
                        <div className="selection-pool-page__top__filters__content__classify">
                            <Popover
                                placement={'bottomLeft'}
                                overlayClassName={name}
                                content={this.renderTopClassifys()}
                            >
                                <div className="selection-pool-page__top__filters__content__classify-text">
                                    <img alt="" src={icon} />
                                    <span>{language.getText('syspfl')}</span>
                                </div>
                            </Popover>
                        </div>
                        <div className="selection-pool-page__top__filters__content__modes">
                            {tradeModes.map((mode: { id: string; name: string }) => (
                                <div
                                    key={mode.id}
                                    onClick={this.onClickTradeMode.bind(this, mode.id)}
                                    className={classNames(
                                        'selection-pool-page__top__filters__content__modes__mode',
                                        {
                                            [`selection-pool-page__top__filters__content__modes__mode__active`]:
                                                mode.id === activeMode,
                                        }
                                    )}
                                >
                                    {language.getText(mode.name)}
                                </div>
                            ))}
                        </div>
                    </div>
                    <div
                        className="selection-pool-page__top__filters__right"
                        onClick={this.goRiskCommodityListPage}
                    >
                        {language.getText('fxspbd')}
                    </div>
                </div>
            </div>
        );
    };
    render() {
        const {
            loading,
            filtersLoading,
            filterTypes,
            isSearchView,
            allSelecded,
            searchCategoriesData,
            selectedGrand,
            isExpandGrand,
            parentData,
            selectedParent,
            isExpandParent,
            assortData,
            selectedAssorts,
            brandData,
            selectedBrands,
            isExpandBrand,
            originData,
            selectedOrigin,
            isExpandOrigin,
            isExpandAssort,
            categoriesData,
            filterCategoryIds,
            supplierIds,
        } = this.state;
        return (
            <Spin spinning={loading}>
                <div className="selection-pool-page" ref={this.contentRef}>
                    <div className="selection-pool-page-filter">
                        <CollectionCreateForm
                            filterTypes={filterTypes}
                            categoriesData={categoriesData}
                            filterCategoryIds={filterCategoryIds}
                            supplierIds={supplierIds}
                            onSearch={this.onSearchFind}
                            onChange={this.onChangeFiterType}
                            onCategoryChange={this.onCategoryChange}
                            onSupplierChange={this.onSupplierChange}
                        />
                    </div>
                    <div className="selection-pool-page-main">
                        <div className="selection-pool-page__top">
                            {this.renderFiters()}
                            {isSearchView && (
                                <SelectionPoolSearchResult
                                    filtersLoading={filtersLoading}
                                    allSelecded={allSelecded}
                                    searchCategoriesData={searchCategoriesData}
                                    selectedGrand={selectedGrand}
                                    isExpandGrand={isExpandGrand}
                                    parentData={parentData}
                                    selectedParent={selectedParent}
                                    isExpandParent={isExpandParent}
                                    assortData={assortData}
                                    selectedAssorts={selectedAssorts}
                                    brandData={brandData}
                                    selectedBrands={selectedBrands}
                                    isExpandBrand={isExpandBrand}
                                    originData={originData}
                                    selectedOrigin={selectedOrigin}
                                    isExpandOrigin={isExpandOrigin}
                                    isExpandAssort={isExpandAssort}
                                    onSelectOrigin={this.onSelectOrigin}
                                    onChangeCheckBox={this.onChangeCheckBox}
                                    onClickFold={this.onClickFold}
                                    onSelectClassify={this.onSelectClassify}
                                    clearSelecded={this.clearSelecded}
                                />
                            )}
                        </div>
                        <div className="selection-pool-page__bottom">
                            <div className="selection-pool-page-content">
                                {this.renderProductList()}
                            </div>
                        </div>
                    </div>
                </div>
            </Spin>
        );
    }
}
