import React, { PureComponent } from 'react';
import { Table, Button, InputNumber, message as AntMessage } from 'antd';
import { cloneDeep, remove, find, differenceBy } from 'lodash';
import { CouponSelector } from '../coupon-selector';
import { Coupon } from '../birthday-member-level-coupon-rule/birthday-member-level-types';
import { services } from '@comall-backend-builder/core';

interface CouponSelectorWithDisplayProps {
    data: Array<Coupon>;
    onChange: (data: Array<Coupon>) => void;
    /**
     * 搜索查询条件
     */
    filterParams?: any;
    /**
     * 是否可以编辑优惠券数量操作
     * 默认 true
     */
    canEditeQuantity?: boolean;
    /**
     * 是否可以删除操作
     * 默认 true
     */
    canRemove?: boolean;
    /**
     * 是否显示添加优惠券按钮
     */
    canAdd?: boolean;
    /**
     * 是否禁止修改
     */
    disabled?: boolean;
    /**
     * 是否显示分摊这一列
     */
    canShowApportionPrice?: boolean;
    /**
     * 显示面值
     */
    canShowAmountValue?: boolean;
    /**
     * 显示剩余库存
     */
    canShowLeftStock?: boolean;
}
interface CouponSelectorWithDisplayState {
    selectedRows: Array<Coupon>;
}

/**
 * 优惠券选择并且展示选择内容
 */
class CouponSelectorWithDisplay extends PureComponent<
    CouponSelectorWithDisplayProps,
    CouponSelectorWithDisplayState
> {
    static defaultProps = {
        canEditeQuantity: true,
        canRemove: true,
        canAdd: true,
        disabled: false,
        canShowApportionPrice: false,
        canShowAmountValue: false,
    };

    getTableConfig = () => {
        const {
            canRemove,
            canEditeQuantity,
            disabled,
            canShowApportionPrice,
            canShowAmountValue,
            canShowLeftStock,
        } = this.props;
        let columns: any[] = [];
        columns = [
            {
                title: services.language.getText('yhqmc'),
                dataIndex: 'name',
                key: 'name',
            },
        ];
        if (canShowLeftStock) {
            columns.push({
                title: services.language.getText('sykc'),
                dataIndex: 'leftStock',
                key: 'leftStock',
                render: (leftStock: any) => {
                    return <>{leftStock ? leftStock : 0}</>;
                },
            });
        }
        columns.push(
            {
                title: services.language.getText('couponBaCode'),
                dataIndex: 'batchNo',
                key: 'batchNo',
            },
            {
                title: services.language.getText('couponType'),
                dataIndex: 'couponTypeDes',
                key: 'couponTypeDes',
            },
            {
                title: services.language.getText('setCouponType'),
                dataIndex: 'sourceDesc',
                key: 'sourceDesc',
            }
        );
        if (canShowAmountValue) {
            columns.push(
                {
                    title: services.language.getText('mz_1'),
                    dataIndex: 'amountValue',
                    key: 'amountValue',
                },
                {
                    title: services.language.getText('dynamicEffectivePeriodDay'),
                    dataIndex: 'dynamicEffectivePeriod',
                    key: 'dynamicEffectivePeriod',
                }
            );
        }
        columns.push(
            {
                title: services.language.getText('couponStartTime'),
                dataIndex: 'startTime',
                key: 'startTime',
            },
            {
                title: services.language.getText('couponEndTime'),
                dataIndex: 'endTime',
                key: 'endTime',
            },
            {
                title: services.language.getText('usedChannelLimit'),
                dataIndex: 'consumeChannelDescription',
                key: 'consumeChannelDescription',
            },
            {
                title: services.language.getText('ffsl'),
                dataIndex: 'quantity',
                key: 'quantity',
                fixed: 'right',
                render: (quantity: number, record: any) => {
                    return (
                        <InputNumber
                            min={1}
                            max={record.stock ? record.stock : 9999}
                            step={1}
                            precision={0}
                            value={quantity}
                            onChange={(value) => this.onChangeQuantity(value, record)}
                            disabled={disabled}
                        ></InputNumber>
                    );
                },
            }
        );
        if (canShowApportionPrice) {
            columns.push({
                title: services.language.getText('ftj'),
                dataIndex: 'apportionPrice',
                key: 'apportionPrice',
                render: (apportionPrice: number, record: any) => {
                    return (
                        <InputNumber
                            min={0}
                            value={apportionPrice}
                            onChange={(value) => this.onChangeApportionPrice(value, record)}
                        ></InputNumber>
                    );
                },
            });
        }
        columns.push({
            title: services.language.getText('common.tableAction'),
            className: 'action-column',
            key: 'operation',
            fixed: 'right',
            width: 100,
            render: (i: any, row: any) => {
                return (
                    <Button
                        type={'link'}
                        onClick={this.onRemove(row)}
                        style={{ padding: '0' }}
                        disabled={disabled}
                    >
                        {services.language.getText('common.delete')}
                    </Button>
                );
            },
        });

        let tableConfig: any = {
            columns: columns,
            scroll: { x: true },
            style: {
                width: '80%',
                marginTop: 10,
            },
        };
        if (!canRemove) {
            remove(tableConfig.columns, { key: 'operation' });
        }
        if (!canEditeQuantity) {
            remove(tableConfig.columns, { key: 'quantity' });
        }
        return tableConfig;
    };

    onRemove = (row: Coupon) => {
        const { data, onChange } = this.props;

        return () => {
            const result = cloneDeep(data);
            remove(result, (coupon) => row.id === coupon.id);
            onChange(result);
        };
    };

    onSelectCoupon = (coupons: Array<any>) => {
        const { data, onChange } = this.props;
        let newData = data.slice(0);
        const add = differenceBy(coupons, data, 'id');
        newData = newData.concat(add);
        onChange(newData);
    };

    onChangeQuantity = (value: any, record: any) => {
        const { data, onChange } = this.props;
        let newData = data.slice(0);
        let row = find(newData, { id: record.id });
        if (row) {
            row.quantity = value;
            onChange(newData);
        }
    };

    onChangeApportionPrice = (value: any, record: any) => {
        const { data, onChange } = this.props;
        let newData = data.slice(0);
        let row = find(newData, { id: record.id });
        if (row) {
            var reg = /^(?!0+$)(?!0*\.0*$)\d{1,8}(\.\d{1,2})?$/;
            if (value && (Number(value) < 0.01 || !reg.test(value))) {
                AntMessage.warning(services.language.getText('ftjjzcdxsdhlw'));
                row.apportionPrice = 0;
                onChange(newData);
                return false;
            } else {
                row.apportionPrice = value;
                onChange(newData);
            }
        }
    };

    render() {
        const { data, filterParams, canAdd } = this.props;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: data,
            pagination: false,
        };
        return (
            <div className="coupon-selector-with-display">
                {canAdd && (
                    <CouponSelector
                        type={'checkbox'}
                        selectValues={[]}
                        onChange={this.onSelectCoupon}
                        params={filterParams}
                    />
                )}
                <Table {...tableProps}></Table>
            </div>
        );
    }
}

export { CouponSelectorWithDisplay };
