import React, { PureComponent } from 'react';
import { EntitiesManager } from '@comall-backend-builder/core';
import { ImportTable } from '../../../components';
import { language } from '@comall-backend-builder/core/lib/services';

export interface MaotaiPresaleImportWhiteProps {
    name: string;
    value: any[];
    onChange: (value: any[]) => void;
}
export class MaotaiPresaleImportWhite extends PureComponent {
    render() {
        const { row: tableRow } = this.props as any;

        const props: any = {
            triggerType: 'link',
            triggerText: '报名白名单',
            triggerStyle: { padding: 0, marginRight: 10 },
            controlDownloadTemplate: {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                // apiPath: `/admin/reserve_draw_lots_activities/template.xlsx?show_subsite=true`,
                apiPath: `/admin/presale/listing/download_template/WHITE?show_subsite=true`,
            },
            controlHandles: [
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                    apiPath:
                        // '/admin/reserve_draw_lots_activities/:id/upload?importType=ADD&type=REGISTRATION',
                        '/admin/presale/listing/:id/REGISTRATION/upload?importType=ADD&type=BLACK',
                    params: {
                        importType: 'ADD',
                        type: 'REGISTRATION',
                        name: tableRow.name,
                    },
                    type: 'primary',
                    text: language.getText('xzdr'),
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                    apiPath:
                        // '/admin/reserve_draw_lots_activities/:id/upload?importType=COVER&type=REGISTRATION',
                        '/admin/presale/listing/:id/REGISTRATION/upload?importType=COVER&type=BLACK',
                    params: {
                        importType: 'COVER',
                        type: 'REGISTRATION',
                        name: tableRow.name,
                    },
                    type: 'primary',
                    text: '覆盖导入',
                },
            ],
        };
        const { id } = tableRow;
        const row: any = { id };
        const entity: any = EntitiesManager.get('WhitelistManagerListEntity');
        return <ImportTable row={row} entity={entity} props={props}></ImportTable>;
    }
}
