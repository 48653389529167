import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage, Modal } from 'antd';
import { get } from 'lodash';
import {
    getLinkTypeI18NKeyById,
    newLinkTypeEffectPathConfigList,
    LinkTypeEffectPathConfig,
} from '../../../components/link-type-effect-path/config';
import { getUrlParam } from '../../../applications/cae/services/utils';
import { language } from '@comall-backend-builder/core/lib/services';

enum CardStatus {
    Enable = 'ENABLE',
    Disable = 'DISABLE',
}
export enum CardCodeType {
    BarCode = 'BAR_CODE',
    Qrcode = 'QRCODE',
    BarCodeAndQrcode = 'BAR_CODE_AND_QRCODE',
    NoneCode = 'NONE_CODE',
}
export enum CommonFieldList {
    Mobile = 'USER_FORM_FLAG_MOBILE',
    Name = 'USER_FORM_FLAG_NAME',
    Sex = 'USER_FORM_FLAG_SEX',
    Birthday = 'USER_FORM_FLAG_BIRTHDAY',
    Email = 'USER_FORM_FLAG_EMAIL',
    City = 'USER_FORM_FLAG_CITY',
    Address = 'USER_FORM_FLAG_ADDRESS',
}
export enum FavorModuleTitle {
    Favor = 'FAVOR',
    Vip = 'VIP',
}
export enum MemberPriceTitle {
    MemberActivity = 'MEMBER_ACTIVITY',
    MemberPrice = 'MEMBER_PRICE',
}
export enum MemberCodeMode {
    /**
     * 实时传入
     */
    RealTime = 'REAL_TIME',
    /**
     * 系统分配
     */
    SystemAllocate = 'SYSTEM_ALLOCATE',
}

const changeOptionNameToI18NKey = (option: LinkTypeEffectPathConfig) => {
    return { ...option, name: `<<${getLinkTypeI18NKeyById(option.id)}>>` };
};

const getLinkOptions = (ids: string[]) => {
    const pathList: LinkTypeEffectPathConfig[] = [];
    newLinkTypeEffectPathConfigList.forEach((item) => {
        if (ids.includes(item.id)) {
            pathList.push(changeOptionNameToI18NKey(item));
        }
        if (item.children && item.children.length) {
            item.children.forEach((child) => {
                if (ids.includes(child.id)) {
                    pathList.push(changeOptionNameToI18NKey(child));
                }
            });
        }
    });
    return pathList;
};
export const memberPricePathOptions = getLinkOptions(['member.mallActivity', 'reserveService']);
export const pointJumpPathOptions = getLinkOptions(['member.creditEshop', 'service.myPoint']);
export const pointSupportPathOptions = getLinkOptions([
    'member.selfCrediting',
    'fastIntegrate',
    'fastIntegrateMerchant',
]);
export const invoiceJumpPathOptions = getLinkOptions([
    'service.openInvoice',
    'service.invoiceCenter',
]);
const getDefaultValue = () => {
    return [
        {
            path: getUrlParam(window.location.hash, 'backgroundUrl') || '',
            id: '0',
        },
    ];
};
const getDefaultLevel = () => {
    return getUrlParam(window.location.hash, 'levelName') || '';
};

const { privilege } = services;

export let config: Config = {
    entities: {
        CardLevelTemplateEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/cardLevelsTemplate',
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<kdjmbmc>>',
                    rules: [
                        {
                            required: true,
                            whitespace: true,
                            message: '<<qsrkdjmbmc>>',
                        },
                    ],
                    controlConfig: {
                        placeholder: '<<qsrkdjmbmcbcg20z>>',
                        maxLength: 20,
                        style: {
                            width: 450,
                        },
                    },
                },
                cardLevelItems: {
                    type: 'array.cardLevelItems',
                    displayName: '<<djsz>>',
                    rules: [{ required: true }],
                },
            },
        },
        WechatCardManagePageEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
            apiPath: '/loader/admin/channelCardTemplates',
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<basicSetting>>',
                    properties: {
                        mchId: {
                            type: 'string',
                            displayName: '<<shh>>',
                            rules: [
                                { required: true, message: '<<qsrppid>>' },
                                {
                                    pattern: /^[A-Za-z0-9]{8,10}$/,
                                    message: '<<jzc8z10wszzm>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<mallIdRequired>>',
                                style: {
                                    width: 400,
                                },
                                maxLength: 10,
                            },
                        },
                        status: {
                            type: 'string.options.radio',
                            displayName: '<<wxhyk>>',
                            className: 'no-margin-field',
                            defaultValue: CardStatus.Disable,
                            options: [
                                {
                                    id: CardStatus.Enable,
                                    name: '<<sy_1>>',
                                },
                                {
                                    id: CardStatus.Disable,
                                    name: '<<bsy>>',
                                },
                            ],
                        },
                        statusTips: {
                            type: 'string.tips',
                            colon: false,
                            displayName: ' ',
                            controlConfig: {
                                tips: [
                                    [
                                        {
                                            text: '<<jwxhyksjhwxstnl>>',
                                        },
                                    ],
                                    [
                                        {
                                            text: '<<kqwxhyktj>>',
                                        },
                                    ],
                                    [
                                        {
                                            text: '<<ckgzhlx>>',
                                        },
                                    ],
                                    [
                                        {
                                            text: '<<jcwwxzfdppsj>>',
                                        },
                                    ],
                                    [
                                        {
                                            text: '<<sywxhykh>>',
                                        },
                                    ],
                                    [
                                        {
                                            text: '<<jmqwxhykjzcjttywxhyk>>',
                                        },
                                    ],
                                ],
                            },
                        },
                        appId: {
                            type: 'string.options.select',
                            displayName: '<<wxgzh>>',
                            className: 'no-margin-field',
                            options: [],
                            source: {
                                apiPath: '/loader/admin/channelWechatOfficialAccounts',
                            },
                            rules: [
                                {
                                    required: true,
                                    message: '<<qxzwxgzh>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                placeholder: '<<selectPlease>>',
                            },
                        },
                        appIdTips: {
                            type: 'string.tips',
                            colon: false,
                            displayName: ' ',
                            controlConfig: {
                                tips: [
                                    [
                                        {
                                            text: '<<jhyxgbhjtggfwhcdyh>>',
                                        },
                                        {
                                            type: 'link',
                                            text: '<<jgzhglgzh>>',
                                            onClick: () => {
                                                if (
                                                    privilege.check(
                                                        'wechatOfficialAccountManger',
                                                        'full'
                                                    )
                                                ) {
                                                    window.open('#/wechat-official-accounts');
                                                } else {
                                                    AntMessage.warning('<<qlxglypz>>');
                                                }
                                            },
                                        },
                                        {
                                            text: '<<jjxbd>>',
                                        },
                                    ],
                                ],
                            },
                        },
                        brandId: {
                            type: 'string',
                            displayName: '<<brandId>>',
                            rules: [{ required: true, message: '<<qsrppid>>' }],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                            },
                            extra: '<<brandIdExtra>>',
                        },
                        freezeBrandId: {
                            type: 'string',
                            displayName: '<<brandId>>',
                            rules: [{ required: true, message: '<<qsrppid>>' }],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                            },
                            extra: '<<brandIdExtra>>',
                        },
                        brandName: {
                            type: 'string',
                            displayName: '<<brandName>>',
                            className: 'no-margin-field',
                            rules: [{ required: true, message: '<<qsrppmc>>' }],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                maxLength: 10,
                            },
                        },
                        freezeBrandName: {
                            type: 'string',
                            displayName: '<<brandName>>',
                            className: 'no-margin-field',
                            rules: [{ required: true, message: '<<qsrppmc>>' }],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                maxLength: 10,
                            },
                        },
                        brandNameTips: {
                            type: 'string.tips',
                            colon: false,
                            displayName: ' ',
                            controlConfig: {
                                tips: [
                                    [
                                        {
                                            text: '<<zszhykms>>',
                                        },
                                    ],
                                    [
                                        {
                                            text: '<<zczz10gzwz>>',
                                        },
                                    ],
                                    [
                                        {
                                            text: '<<jzczwzywzf>>',
                                        },
                                    ],
                                    [
                                        {
                                            text: '<<jkzsccjssz>>',
                                        },
                                    ],
                                ],
                            },
                        },
                    },
                },
                contentInfo: {
                    type: 'object.subForm',
                    displayName: '<<hyknrsz>>',
                    properties: {
                        logoPic: {
                            type: 'imageObjectArray',
                            displayName: '<<cardLogo>>',
                            className: 'sigle-image-field',
                            rules: [{ required: true, message: '<<qschyklogo>>' }],
                            controlConfig: {
                                accept: 'image/jpg, image/png, image/bmp',
                                maxCount: 1,
                                maxSize: 2 * 1024,
                                multiple: false,
                                fileName: 'files',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/locations/90/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<logoPicExtra>>',
                        },
                        title: {
                            type: 'string',
                            displayName: '<<hykmc>>',
                            className: 'no-margin-field',
                            rules: [{ required: true, message: '<<qsrhykmc>>' }],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                maxLength: 10,
                            },
                        },
                        titleTips: {
                            type: 'string.tips',
                            colon: false,
                            displayName: ' ',
                            controlConfig: {
                                tips: [
                                    [
                                        {
                                            text: '<<jzszkms>>',
                                        },
                                    ],
                                    [
                                        {
                                            text: '<<zczz10gzwz>>',
                                        },
                                    ],
                                    [
                                        {
                                            text: '<<jzczwzywzfbd>>',
                                        },
                                    ],
                                ],
                            },
                        },
                        needDisplayLevel: {
                            type: 'string.options.radio',
                            displayName: '<<hydj>>',
                            defaultValue: 'true',
                            options: [
                                {
                                    id: 'true',
                                    name: '<<show>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<hide>>',
                                },
                            ],
                            extra: '<<kmszshydj>>',
                        },
                        codeMode: {
                            type: 'string.options.radio',
                            displayName: '<<hykhlx>>',
                            defaultValue: MemberCodeMode.RealTime,
                            options: [
                                {
                                    id: MemberCodeMode.RealTime,
                                    name: '<<syyhzcsxtfpdkh>>',
                                },
                                {
                                    id: MemberCodeMode.SystemAllocate,
                                    name: '<<sywxfpdkh>>',
                                },
                            ],
                            extra: '<<jsyxtfpdkh>>',
                        },
                        showCodeType: {
                            type: 'string.options.radio',
                            displayName: '<<hym>>',
                            defaultValue: 'true',
                            options: [
                                {
                                    id: 'true',
                                    name: '<<show>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<hide>>',
                                },
                            ],
                        },
                        codeType: {
                            type: 'string.options.radio',
                            displayName: '<<hymlx>>',
                            defaultValue: CardCodeType.BarCode,
                            options: [
                                {
                                    id: CardCodeType.BarCode,
                                    name: '<<txm>>',
                                },
                                {
                                    id: CardCodeType.Qrcode,
                                    name: '<<qrCode>>',
                                },
                                {
                                    id: CardCodeType.BarCodeAndQrcode,
                                    name: '<<ewmhtxm>>',
                                },
                            ],
                        },
                        backgroundPic: {
                            type: 'array.imageObjectArrayForWechatCardBackground',
                            displayName: '<<hykbjt>>',
                            className: 'sigle-image-field',
                            controlConfig: {
                                accept: 'image/jpg, image/png, image/bmp',
                                maxCount: 1,
                                maxSize: 2 * 1024,
                                multiple: false,
                                fileName: 'files',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/locations/90/upload`,
                                mode: 'picture-card',
                                getDefaultValue: getDefaultValue,
                                getDefaultLevel: getDefaultLevel,
                            },
                            extra: '<<picLimit380>>',
                        },
                        servicePhone: {
                            type: 'string',
                            displayName: '<<fwdh>>',
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                            },
                            extra: '<<zszhykxqn>> ',
                        },
                        description: {
                            type: 'string.text.paragraph',
                            displayName: '<<syxz>>',
                            rules: [{ required: true, message: '<<qsrsyxz>>' }],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                rows: 4,
                                maxLength: 500,
                            },
                            extra: '<<jytxhyqyjfwxgms>>',
                        },
                        commonFieldList: {
                            type: 'array.optionIds.checkbox',
                            displayName: '<<kkxxzd>>',
                            rules: [{ required: true, message: '<<qxzkkxxzd>>' }],
                            className: 'no-margin-field',
                            defaultValue: [CommonFieldList.Name],
                            options: [
                                { id: CommonFieldList.Name, name: '<<name>>' },
                                { id: CommonFieldList.Sex, name: '<<xb_1>>' },
                                { id: CommonFieldList.Birthday, name: '<<birthday>>' },
                                { id: CommonFieldList.Address, name: '<<dz_1>>' },
                                { id: CommonFieldList.Email, name: '<<email>>' },
                                { id: CommonFieldList.City, name: '<<cs_2>>' },
                            ],
                        },
                        commonFieldListTips: {
                            type: 'string.tips',
                            colon: false,
                            displayName: ' ',
                            controlConfig: {
                                tips: [
                                    [
                                        {
                                            text: '<<proposalIntro1>>',
                                        },
                                    ],
                                    [
                                        {
                                            text: '<<proposalIntro2>>',
                                        },
                                    ],
                                ],
                            },
                        },
                    },
                },
                discountsInfo: {
                    type: 'object.subForm',
                    displayName: '<<hyyhsz>>',
                    properties: {
                        showFavor: {
                            type: 'string.options.radio',
                            displayName: '<<hyyhl>>',
                            defaultValue: 'true',
                            options: [
                                {
                                    id: 'true',
                                    name: '<<show>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<hide>>',
                                },
                            ],
                            extra: '<<szzhykxfsfzshyyhbk>>',
                        },
                        favorModuleTitle: {
                            type: 'string.options.radio',
                            displayName: '<<hyyhlmc>>',
                            defaultValue: FavorModuleTitle.Favor,
                            options: [
                                {
                                    id: FavorModuleTitle.Favor,
                                    name: '<<hyyh>>',
                                },
                                {
                                    id: FavorModuleTitle.Vip,
                                    name: '<<hyzx>>',
                                },
                            ],
                            extra: '<<jyxhyyhlmdzsmcw>>',
                        },
                        memberPriceTitle: {
                            type: 'string.options.radio',
                            displayName: '<<hyyhbt>>',
                            defaultValue: MemberPriceTitle.MemberActivity,
                            options: [
                                {
                                    id: MemberPriceTitle.MemberActivity,
                                    name: '<<hyhd>>',
                                },
                                {
                                    id: MemberPriceTitle.MemberPrice,
                                    name: '<<hyj>>',
                                },
                            ],
                            extra: '<<jyxhyyhlmxdbtzswaw>>',
                        },
                        memberPriceWord: {
                            type: 'string',
                            displayName: '<<hyyhwa>>',
                            rules: [{ required: true, message: '<<qsrhyyhwa>>' }],
                            controlConfig: {
                                placeholder: '<<zdzc8gz>>',
                                style: {
                                    width: 400,
                                },
                                maxLength: 8,
                            },
                            extra: '<<rzehyqcbz>>',
                        },
                        memberPricePath: {
                            type: 'string.options.select',
                            displayName: '<<hyyhym>>',
                            options: memberPricePathOptions,
                            controlConfig: {
                                allowClear: true,
                                style: {
                                    width: 400,
                                },
                                placeholder: '<<selectPlease>>',
                            },
                            extra: '<<jypzlrghyhdhyhdxsymhhdsmym>>',
                        },
                    },
                },
                pointInfo: {
                    type: 'object.subForm',
                    displayName: '<<hyjfsz>>',
                    properties: {
                        showPoint: {
                            type: 'string.options.radio',
                            displayName: '<<hyjfl>>',
                            defaultValue: 'true',
                            options: [
                                {
                                    id: 'true',
                                    name: '<<show>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<hide>>',
                                },
                            ],
                            extra: '<<szzhykxfsfzshyjfbk>>',
                        },
                        pointValueWord: {
                            type: 'string',
                            displayName: '<<jfbtmc>>',
                            rules: [{ required: true, message: '<<qsrjfbtmc>>' }],
                            controlConfig: {
                                placeholder: '<<wdjf>>',
                                style: {
                                    width: 400,
                                },
                                maxLength: 4,
                            },
                            extra: '<<jbcg4gz>>',
                        },
                        pointCostWord: {
                            type: 'string',
                            displayName: '<<jfdhwa>>',
                            rules: [{ required: true, message: '<<qsrjfdhwa>>' }],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                            },
                            extra: '<<r500jfdy2xstcq>>',
                        },
                        pointJumpWord: {
                            type: 'string',
                            displayName: '<<jftzwa>>',
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                maxLength: 8,
                            },
                            extra: '<<wdjfjlhsjfsc>>',
                        },
                        pointJumpPath: {
                            type: 'string.options.select',
                            displayName: '<<jftzym>>',
                            options: pointJumpPathOptions,
                            controlConfig: {
                                allowClear: true,
                                style: {
                                    width: 400,
                                },
                                placeholder: '<<selectPlease>>',
                            },
                            extra: '<<jypzlrghyhdhyhdxsymhhdsmym>>',
                        },
                        pointSupportPath: {
                            type: 'string.options.select',
                            displayName: '<<zzjftzym>>',
                            className: 'no-margin-field',
                            options: pointSupportPathOptions,
                            controlConfig: {
                                allowClear: true,
                                style: {
                                    width: 400,
                                },
                                placeholder: '<<selectPlease>>',
                            },
                        },
                        pointSupportPathTips: {
                            type: 'string.tips',
                            colon: false,
                            displayName: ' ',
                            controlConfig: {
                                tips: [
                                    [
                                        {
                                            text: '<<xxkt>>',
                                        },
                                        {
                                            type: 'link',
                                            text: '<<selfCrediting>>',
                                            onClick: () => {
                                                if (privilege.check('pageSetting', 'full')) {
                                                    window.open('#/self-crediting-setting');
                                                } else {
                                                    AntMessage.warning('<<qlxglypz>>');
                                                }
                                            },
                                        },
                                        {
                                            text: '<<ji>>',
                                        },
                                        {
                                            type: 'link',
                                            text: '<<sqjf>>',
                                            onClick: () => {
                                                if (privilege.check('businessMall', 'full')) {
                                                    window.open('#/business-malls');
                                                } else {
                                                    AntMessage.warning('<<qlxglypz>>');
                                                }
                                            },
                                        },
                                        {
                                            text: '<<qlxkhjlpzsqwcsqjfdsq>>',
                                        },
                                    ],
                                ],
                            },
                        },
                    },
                },
                invoiceInfo: {
                    type: 'object.subForm',
                    displayName: '<<hyfpsz>>',
                    properties: {
                        showInvoice: {
                            type: 'string.options.radio',
                            displayName: '<<hyfp>>',
                            defaultValue: 'true',
                            options: [
                                {
                                    id: 'true',
                                    name: '<<show>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<hide>>',
                                },
                            ],
                            extra: '<<hykxqyzzsfpl>>',
                        },
                        invoiceJumpWord: {
                            type: 'string',
                            displayName: '<<fptzwa>>',
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                maxLength: 8,
                            },
                            extra: '<<jbcg8gzwdfp>>',
                        },
                        invoiceJumpPath: {
                            type: 'string.options.select',
                            displayName: '<<jfptzym>>',
                            className: 'no-margin-field',
                            options: invoiceJumpPathOptions,
                            controlConfig: {
                                allowClear: true,
                                style: {
                                    width: 400,
                                },
                                placeholder: '<<selectPlease>>',
                            },
                        },
                        invoiceJumpPathTips: {
                            type: 'string.tips',
                            colon: false,
                            displayName: ' ',
                            controlConfig: {
                                tips: [
                                    [
                                        {
                                            text: '<<xxkt>>',
                                        },
                                        {
                                            type: 'link',
                                            text: '<<fp>>',
                                            onClick: () => {
                                                if (privilege.check('invoiceConfig', 'full')) {
                                                    window.open('#/invoice-config');
                                                } else {
                                                    AntMessage.warning(
                                                        language.getText('qlxglypz')
                                                    );
                                                }
                                            },
                                        },
                                        {
                                            text: '<<jgnzpzcx>>',
                                        },
                                    ],
                                ],
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        CardLevelTemplateView: {
            component: 'Viewport',
            entity: 'CardLevelTemplateEntity',
        },
        CardLevelTemplatePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzhydj>>',
                                    route: '/card-level-manage/add',
                                    type: 'primary',
                                    icon: 'plus',
                                    style: {
                                        width: 140,
                                    },
                                    className: 'mb24',
                                },
                            ],
                        },
                    ],
                },
                { component: 'MemberCardLevelTemplateTable' },
            ],
        },
        CardLevelTemplateAddPage: {
            component: 'FlexLayout',
            entity: 'CardLevelTemplateEntity',
            direction: 'vertical',
            items: [{ component: 'CardLevelTemplateAddForm' }],
        },
        CardLevelTemplateAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'name' },
                {
                    property: 'cardLevelItems',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const cardLevelItems = get(entity, 'cardLevelItems', []);
                for (let index = 0; index < cardLevelItems.length; index++) {
                    const item = cardLevelItems[index];
                    if (!item.levelName) {
                        AntMessage.warning(language.getText('qsrdjmc'));
                        return false;
                    }
                    // const regex = /^[0-9a-zA-Z\u4e00-\u9fa5]{1,20}$/;
                    // if (!regex.test(item.levelName)) {
                    //     AntMessage.warning('等级名称仅支持输入英文+数字+中文汉字');
                    //     return false;
                    // }
                    if (!item.styleIconUrl) {
                        AntMessage.warning(language.getText('djtbbnwk'));
                        return false;
                    }
                    if (!item.styleBackgroundPictureUrl) {
                        AntMessage.warning(language.getText('djkmbnwk'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        CardLevelTemplateEditPage: {
            component: 'FlexLayout',
            entity: 'CardLevelTemplateEntity',
            direction: 'vertical',
            items: [{ component: 'CardLevelTemplateEditForm' }],
        },
        CardLevelTemplateEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'CardLevelTemplateEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'name' },
                {
                    property: 'cardLevelItems',
                    controlConfig: {
                        isEdit: true,
                    },
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const cardLevelItems = get(entity, 'cardLevelItems', []);
                for (let index = 0; index < cardLevelItems.length; index++) {
                    const item = cardLevelItems[index];
                    if (!item.levelName) {
                        AntMessage.warning(language.getText('qsrdjmc'));
                        return false;
                    }
                    // const regex = /^[0-9a-zA-Z\u4e00-\u9fa5]{1,20}$/;
                    // if (!regex.test(item.levelName)) {
                    //     AntMessage.warning('等级名称仅支持输入英文+数字+中文汉字');
                    //     return false;
                    // }
                    if (!item.styleIconUrl) {
                        AntMessage.warning(language.getText('djtbbnwk'));
                        return false;
                    }
                    if (!item.styleBackgroundPictureUrl) {
                        AntMessage.warning(language.getText('djkmbnwk'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        WechatCardManageAddPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [
                { component: 'WechatCardManagePreview' },
                { component: 'WechatCardManageAddForm' },
            ],
        },
        WechatCardManagePage: {
            component: 'FlexLayout',
            entity: 'WechatCardManagePageEntity',
            direction: 'horizontal',
            items: [
                { component: 'WechatCardManagePreview' },
                { component: 'WechatCardManagePageForm' },
            ],
        },
        WechatCardManageAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'WechatCardManagePageEntity',
            labelCol: 6,
            controlCol: 18,
            fields: [
                {
                    property: 'baseInfo.status',
                },
                {
                    property: 'baseInfo.statusTips',
                },
                {
                    property: 'baseInfo.mchId',
                },
                {
                    property: 'baseInfo.appId',
                },
                {
                    property: 'baseInfo.appIdTips',
                },
                {
                    property: 'baseInfo.brandId',
                },
                {
                    property: 'baseInfo.brandName',
                },
                {
                    property: 'baseInfo.brandNameTips',
                },
                {
                    property: 'contentInfo.logoPic',
                },
                {
                    property: 'contentInfo.title',
                },
                {
                    property: 'contentInfo.titleTips',
                },
                {
                    property: 'contentInfo.needDisplayLevel',
                },
                {
                    property: 'contentInfo.codeMode',
                },
                {
                    property: 'contentInfo.showCodeType',
                },
                {
                    property: 'contentInfo.codeType',
                    visible: (values: any) => {
                        return get(values, 'contentInfo.showCodeType') === 'true';
                    },
                },
                {
                    property: 'contentInfo.backgroundPic',
                },
                {
                    property: 'contentInfo.servicePhone',
                },
                {
                    property: 'contentInfo.description',
                },
                {
                    property: 'contentInfo.commonFieldList',
                },
                {
                    property: 'contentInfo.commonFieldListTips',
                },
                {
                    property: 'discountsInfo.showFavor',
                },
                {
                    property: 'discountsInfo.favorModuleTitle',
                    visible: (values: any) => {
                        return get(values, 'discountsInfo.showFavor') === 'true';
                    },
                },
                {
                    property: 'discountsInfo.memberPriceTitle',
                    visible: (values: any) => {
                        return get(values, 'discountsInfo.showFavor') === 'true';
                    },
                },
                {
                    property: 'discountsInfo.memberPriceWord',
                    visible: (values: any) => {
                        return get(values, 'discountsInfo.showFavor') === 'true';
                    },
                },
                {
                    property: 'discountsInfo.memberPricePath',
                    visible: (values: any) => {
                        return get(values, 'discountsInfo.showFavor') === 'true';
                    },
                },
                {
                    property: 'pointInfo.showPoint',
                },
                {
                    property: 'pointInfo.pointValueWord',
                    visible: (values: any) => {
                        return get(values, 'pointInfo.showPoint') === 'true';
                    },
                },
                {
                    property: 'pointInfo.pointCostWord',
                    visible: (values: any) => {
                        return get(values, 'pointInfo.showPoint') === 'true';
                    },
                },
                {
                    property: 'pointInfo.pointJumpWord',
                    visible: (values: any) => {
                        return get(values, 'pointInfo.showPoint') === 'true';
                    },
                },
                {
                    property: 'pointInfo.pointJumpPath',
                    visible: (values: any) => {
                        return get(values, 'pointInfo.showPoint') === 'true';
                    },
                },
                {
                    property: 'pointInfo.pointSupportPath',
                    visible: (values: any) => {
                        return get(values, 'pointInfo.showPoint') === 'true';
                    },
                },
                {
                    property: 'pointInfo.pointSupportPathTips',
                    visible: (values: any) => {
                        return get(values, 'pointInfo.showPoint') === 'true';
                    },
                },
                {
                    property: 'invoiceInfo.showInvoice',
                },
                {
                    property: 'invoiceInfo.invoiceJumpWord',
                    visible: (values: any) => {
                        return get(values, 'invoiceInfo.showInvoice') === 'true';
                    },
                },
                {
                    property: 'invoiceInfo.invoiceJumpPath',
                    visible: (values: any) => {
                        return get(values, 'invoiceInfo.showInvoice') === 'true';
                    },
                },
                {
                    property: 'invoiceInfo.invoiceJumpPathTips',
                    visible: (values: any) => {
                        return get(values, 'invoiceInfo.showInvoice') === 'true';
                    },
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        WechatCardManagePageForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'WechatCardManagePageEntity',
            loaderType: 'get',
            labelCol: 6,
            controlCol: 18,
            fields: [
                {
                    property: 'baseInfo.status',
                },
                {
                    property: 'baseInfo.statusTips',
                },
                {
                    property: 'baseInfo.mchId',
                },
                {
                    property: 'baseInfo.appId',
                },
                {
                    property: 'baseInfo.appIdTips',
                },
                {
                    property: 'baseInfo.brandId',
                    visible: (values: any) => {
                        return !get(values, 'baseInfo.freezeBrandId');
                    },
                },
                {
                    property: 'baseInfo.freezeBrandId',
                    visible: (values: any) => {
                        return get(values, 'baseInfo.freezeBrandId');
                    },
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'baseInfo.brandName',
                    visible: (values: any) => {
                        return !get(values, 'baseInfo.freezeBrandName');
                    },
                },
                {
                    property: 'baseInfo.freezeBrandName',
                    visible: (values: any) => {
                        return get(values, 'baseInfo.freezeBrandName');
                    },
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'baseInfo.brandNameTips',
                },
                {
                    property: 'contentInfo.logoPic',
                },
                {
                    property: 'contentInfo.title',
                },
                {
                    property: 'contentInfo.titleTips',
                },
                {
                    property: 'contentInfo.needDisplayLevel',
                },
                {
                    property: 'contentInfo.codeMode',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'contentInfo.showCodeType',
                },
                {
                    property: 'contentInfo.codeType',
                    visible: (values: any) => {
                        return get(values, 'contentInfo.showCodeType') === 'true';
                    },
                },
                {
                    property: 'contentInfo.backgroundPic',
                },
                {
                    property: 'contentInfo.servicePhone',
                },
                {
                    property: 'contentInfo.description',
                },
                {
                    property: 'contentInfo.commonFieldList',
                },
                {
                    property: 'contentInfo.commonFieldListTips',
                },
                {
                    property: 'discountsInfo.showFavor',
                },
                {
                    property: 'discountsInfo.favorModuleTitle',
                    visible: (values: any) => {
                        return get(values, 'discountsInfo.showFavor') === 'true';
                    },
                },
                {
                    property: 'discountsInfo.memberPriceTitle',
                    visible: (values: any) => {
                        return get(values, 'discountsInfo.showFavor') === 'true';
                    },
                },
                {
                    property: 'discountsInfo.memberPriceWord',
                    visible: (values: any) => {
                        return get(values, 'discountsInfo.showFavor') === 'true';
                    },
                },
                {
                    property: 'discountsInfo.memberPricePath',
                    visible: (values: any) => {
                        return get(values, 'discountsInfo.showFavor') === 'true';
                    },
                },
                {
                    property: 'pointInfo.showPoint',
                },
                {
                    property: 'pointInfo.pointValueWord',
                    visible: (values: any) => {
                        return get(values, 'pointInfo.showPoint') === 'true';
                    },
                },
                {
                    property: 'pointInfo.pointCostWord',
                    visible: (values: any) => {
                        return get(values, 'pointInfo.showPoint') === 'true';
                    },
                },
                {
                    property: 'pointInfo.pointJumpWord',
                    visible: (values: any) => {
                        return get(values, 'pointInfo.showPoint') === 'true';
                    },
                },
                {
                    property: 'pointInfo.pointJumpPath',
                    visible: (values: any) => {
                        return get(values, 'pointInfo.showPoint') === 'true';
                    },
                },
                {
                    property: 'pointInfo.pointSupportPath',
                    visible: (values: any) => {
                        return get(values, 'pointInfo.showPoint') === 'true';
                    },
                },
                {
                    property: 'pointInfo.pointSupportPathTips',
                    visible: (values: any) => {
                        return get(values, 'pointInfo.showPoint') === 'true';
                    },
                },
                {
                    property: 'invoiceInfo.showInvoice',
                },
                {
                    property: 'invoiceInfo.invoiceJumpWord',
                    visible: (values: any) => {
                        return get(values, 'invoiceInfo.showInvoice') === 'true';
                    },
                },
                {
                    property: 'invoiceInfo.invoiceJumpPath',
                    visible: (values: any) => {
                        return get(values, 'invoiceInfo.showInvoice') === 'true';
                    },
                },
                {
                    property: 'invoiceInfo.invoiceJumpPathTips',
                    visible: (values: any) => {
                        return get(values, 'invoiceInfo.showInvoice') === 'true';
                    },
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: async (entity: any) => {
                const result = await new Promise((resolve, reject) => {
                    Modal.confirm({
                        title: language.getText('tip'),
                        content: language.getText('dqyjysxdwxhykmb'),
                        okText: language.getText('qrfg'),
                        cancelText: language.getText('common.cancel'),
                        onOk() {
                            resolve(true);
                        },
                        onCancel() {
                            resolve(false);
                        },
                    });
                });
                return result;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/card-level-manage',
                    breadcrumbName: '<<hykmb>>',
                    component: 'CardLevelTemplateView',
                    privilege: {
                        path: 'memberCardLevelManage',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'CardLevelTemplateAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'CardLevelTemplateEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        {
                            path: '/channel-card/add',
                            component: 'WechatCardManageAddPage',
                            breadcrumbName: '<<wxhykpz>>',
                        },
                        {
                            path: '/channel-card/:id',
                            component: 'WechatCardManagePage',
                            breadcrumbName: '<<wxhykpz>>',
                        },
                        { path: '/', component: 'CardLevelTemplatePage' },
                    ],
                },
            ],
        },
    ],
};
