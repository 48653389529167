import React, { Component } from 'react';
import { api } from '@comall-backend-builder/core/lib/services';
import { message, Button } from 'antd';
import { cloneDeep, forEach, findIndex, set, find, map } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';
import {
    PushAndSiteMailSceneType,
    SpecialType,
    linkTypeList,
} from '../../../../containers/wechat/common';
import { MessageNotificationItem } from './components/message-notification-item/message-notification-item';
import './index.less';

export enum MsgChannelType {
    /**
     * 微信订阅消息
     */
    WEAPP = 'WEAPP',
    /**
     * 微信公众号模板消息
     */
    WECHAT_OFFICIAL_ACCOUNT = 'WECHAT_OFFICIAL_ACCOUNT',
    /**
     * 支付宝小程序订阅消息
     */
    ALIPAY_SUBSCRIBE_MSG = 'ALIPAY_SUBSCRIBE_MSG',
    /**
     * 邮件
     */
    EMAIL = 'EMAIL',
    /**
     * 站内信
     */
    SITEMAIL = 'SITEMAIL',
    /**
     * 短信
     */
    SMS = 'SMS',
    /**
     * apppush
     */
    APP_PUSH = 'PUSH',
}
export interface MessageNotificationMethodValueItemParams {
    name: string;
    value: string;
    editable: boolean;
    fieldType: string;
    color?: string;
    defaultValue?: string;
}
export interface MessageNotificationMethodValueItem {
    id?: string;
    status: 0 | 1;
    msgChannelType: MsgChannelType;
    msgStatus: 'ENABLE' | 'DISABLE';
    name: string;
    targetType: string;
    targetUrl: string | null;
    scene: PushAndSiteMailSceneType;
    params: MessageNotificationMethodValueItemParams[];
}
interface Props {
    onChange: (value: MessageNotificationMethodValueItem[]) => void;
    value: MessageNotificationMethodValueItem[];
    scene: PushAndSiteMailSceneType;
    title: string;
    row: any;
    isAdd: boolean;
    showCollapse?: boolean;
}
interface State {
    isInit: boolean;
    collapse: boolean;
}

export interface LandingPage {
    name: string;
    targetType: string;
    targetUrl: string | null;
}

export class MessageNotificationMethod extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            isInit: false,
            collapse: false,
        };
    }

    componentDidUpdate(prevProps: Props) {
        if (this.props.value && !prevProps.value && !this.state.isInit) {
            this.getSceneTemplates();
        }
    }

    componentDidMount(): void {
        if (this.props.isAdd) {
            this.getSceneTemplates();
        } else {
            if (this.props.value) {
                this.getSceneTemplates();
            }
        }
    }

    onChangeItem = (template: MessageNotificationMethodValueItem) => {
        const { value, onChange } = this.props;
        const newValue = cloneDeep(value);
        const tempIdx = findIndex(newValue, { msgChannelType: template.msgChannelType });
        if (tempIdx > -1) {
            set(newValue, `[${tempIdx}]`, template);
        }
        onChange(newValue);
    };
    getLandingPage: () => LandingPage = () => {
        const { scene } = this.props;
        if (scene) {
            if (scene === PushAndSiteMailSceneType.PAY_AWARD_RECEIVE_REMIND) {
                return {
                    name: '',
                    targetType: '',
                    targetUrl: null,
                };
            }
            return {
                name: language.getText(linkTypeList[scene]),
                targetType: SpecialType.PathRedirect,
                targetUrl: null,
            };
        }
        return {
            name: '',
            targetType: SpecialType.PathRedirect,
            targetUrl: null,
        };
    };

    getSceneTemplates = () => {
        const { scene, value = [], onChange } = this.props;
        if (scene) {
            api.get(
                { scene },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                    apiPath: '/admin/template_definitions/scene',
                }
            ).then((res: any) => {
                const newValue: MessageNotificationMethodValueItem[] = [];
                if (res && res.length) {
                    forEach(res, (item: MessageNotificationMethodValueItem) => {
                        if (!item) {
                            return;
                        }
                        const messageAndWechat =
                            item.msgChannelType === MsgChannelType.WEAPP ||
                            item.msgChannelType === MsgChannelType.WECHAT_OFFICIAL_ACCOUNT ||
                            item.msgChannelType === MsgChannelType.ALIPAY_SUBSCRIBE_MSG;
                        //站内信和push
                        const siteMailAndMessage =
                            item.msgChannelType === MsgChannelType.SITEMAIL ||
                            item.msgChannelType === MsgChannelType.APP_PUSH;
                        const template = find(value, {
                            msgChannelType: item.msgChannelType,
                        });
                        let { targetType, targetUrl } = this.getLandingPage();
                        if (scene === PushAndSiteMailSceneType.PAY_AWARD_RECEIVE_REMIND) {
                            if (messageAndWechat) {
                                targetType = 'parameterConcatenation';
                                targetUrl =
                                    'subpackages/market/payment-reward/pages/detail/index?type=PAY_AWARD&subsiteId={$subsiteId}';
                            } else if (siteMailAndMessage) {
                                targetType = 'marketing.paymentRewardActivity';
                                targetUrl = null;
                            }
                        }
                        const notification: MessageNotificationMethodValueItem = cloneDeep({
                            ...item,
                            scene,
                            status: 0,
                            targetType,
                            targetUrl,
                        });
                        if (template) {
                            notification.status = template.status;
                            notification.id = template.id;
                            if (template.targetType) {
                                notification.targetType = template.targetType;
                                notification.targetUrl = template.targetUrl;
                            }
                            notification.params = map(
                                notification.params,
                                (param: MessageNotificationMethodValueItemParams) => {
                                    const currentItem = find(template.params, {
                                        name: param.name,
                                        fieldType: param.fieldType,
                                    });
                                    return {
                                        ...param,
                                        value:
                                            currentItem && currentItem.value
                                                ? currentItem.value
                                                : param.value,
                                    };
                                }
                            );
                        }
                        newValue.push(notification);
                    });
                }
                onChange(newValue);
                this.setState({
                    isInit: true,
                });
            });
        } else {
            message.warn(language.getText('sceneValueNull'));
        }
    };

    onChangeCollapse = () => {
        this.setState({
            collapse: !this.state.collapse,
        });
    };

    render() {
        const { scene, value, title, showCollapse } = this.props;
        const { isInit, collapse } = this.state;
        return (
            <div className="message-notification-method">
                <div
                    className="message-notification-method__tip"
                    style={{ margin: '8px 0 10px 0' }}
                >
                    {title}
                    {showCollapse ? (
                        <Button type="link" onClick={this.onChangeCollapse}>
                            {collapse ? language.getText('launch') : language.getText('putAway')}
                        </Button>
                    ) : null}
                </div>
                <div className="message-notification-method__choose">
                    {isInit &&
                        !collapse &&
                        value &&
                        value.length &&
                        value.map((item) => {
                            return (
                                <MessageNotificationItem
                                    key={item.msgChannelType}
                                    template={item}
                                    scene={scene}
                                    onChange={this.onChangeItem}
                                    landingPage={this.getLandingPage()}
                                />
                            );
                        })}
                </div>
            </div>
        );
    }
}
