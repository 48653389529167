import React, { PureComponent } from 'react';
import { cloneDeep, map, findIndex } from 'lodash';
import { CouponSelector } from '../../components';
import { CouponSelectorWithDisplay } from '../../components/coupon-selector-with-display';

import { Coupon } from '../select-coupon';

export class SelectCouponsWithQuantity extends PureComponent<
    any,
    {
        value: Array<Coupon>;
    }
> {
    state = { value: new Array<Coupon>() };

    getSubsiteIds = (props: any) => {
        const { row } = props;
        const subsiteIds =
            row.baseInfo && row.baseInfo.subsiteIds ? map(row.baseInfo.subsiteIds, 'id') : null;
        return subsiteIds;
    };

    /**
     * 选择优惠券
     * @param selectValues
     */
    onChange = (selectValues: any[]) => {
        const { onChange } = this.props;
        if (onChange) {
            if (selectValues && selectValues.length === 0) {
                onChange([]);
            } else {
                const newValue = selectValues.map((item) => ({
                    ...item,
                    quantity: item.quantity || 1,
                    couponTypeDes: item.typeName,
                }));
                onChange(newValue);
            }
        }
    };

    /**
     * 添加优惠券弹窗确认后回调
     * @param selectValues 选中优惠券
     */
    onSelect = (selectValues: Coupon[]) => {
        const { value } = this.props;
        let newData: Coupon[] = [];
        if (value) {
            newData = cloneDeep(value);
        }
        if (selectValues) {
            newData = [];
            selectValues.forEach((selectCuopon: any) => {
                const hasIndex = findIndex(newData, (coupon: any) => coupon.id === selectCuopon.id);
                if (hasIndex === -1) {
                    newData.push(selectCuopon);
                }
            });
        }
        this.onChange(newData);
    };

    render() {
        const { value, disabled, style } = this.props;
        const hasValue = value && value.length > 0;
        const subsiteIds = this.getSubsiteIds(this.props);
        return (
            <div style={style}>
                {!disabled && (
                    <CouponSelector
                        onChange={this.onSelect}
                        selectValues={value}
                        type="checkbox"
                        params={{ subsiteIds: subsiteIds }}
                        canShowAmountValue={true}
                    />
                )}
                {hasValue && (
                    <CouponSelectorWithDisplay
                        onChange={this.onChange}
                        data={value}
                        canAdd={false}
                        disabled={disabled}
                        filterParams={this.props.filterCouponParams}
                        canShowApportionPrice={false}
                        canShowAmountValue={true}
                    />
                )}
            </div>
        );
    }
}
