import React, { Component } from 'react';
import { Icon, Modal, Button } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services';

interface State {
    showModal: boolean;
}

export class ChangeLanguage extends Component<any, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            showModal: false,
        };
    }
    taggleModal = () => {
        const { showModal } = this.state;
        this.setState({ showModal: !showModal });
    };
    changeTo = (id: string) => {
        language.changeTo(id);
    };
    render() {
        const { showModal } = this.state;
        return (
            <div onClick={this.taggleModal}>
                <Icon type="reload"></Icon>
                <span>{language.getText('change_language')}</span>
                <Modal footer={null} onCancel={this.taggleModal} visible={showModal}>
                    <Button onClick={() => this.changeTo('zh-CN')}>切换中文</Button>
                    <Button onClick={() => this.changeTo('zh-HK')}>切换繁体</Button>
                    <Button onClick={() => this.changeTo('test-language')}>测试语言</Button>
                </Modal>
            </div>
        );
    }
}
