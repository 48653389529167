import React from 'react';

import { modes } from '@comall-backend-builder/types';
import { services } from '@comall-backend-builder/core';

export class MerchantBigCodesNameMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    public getAvailableDisplayComponent(value: any, displayInfo: any) {
        return (
            <div>
                <div>{value}</div>
                <div>{displayInfo?.row?.name}</div>
                <div style={{ color: 'red' }}>
                    {displayInfo?.row?.price
                        ? services.language.getText('monetaryUnit') + displayInfo?.row?.price
                        : ''}
                </div>
            </div>
        );
    }
    /**
     * 获取输入组件
     */
    // public getControlComponent(controlInfo: any) {
    //     return <Input.Password {...controlInfo} />;
    // }
}
