import React, { Component } from 'react';
import { InputNumber } from 'antd';
import classnames from 'classnames';
import { get, isEqual } from 'lodash';
import './index.less';

interface SalesElectronicCouponStockProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name?: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: number | undefined | null, name?: string) => void;
    /**
     * 当前值
     */
    value?: number | undefined;
    addonbefore?: string;
    addonafter?: string;
    tip?: string;
    rules?: any;
    row?: any;
    className?: string;
    /**
     * input的宽度
     */
    width?: number;
    /**
     * 是否允许浮点数
     */
    allowFloat?: boolean;
}

export class SalesElectronicCouponStock extends Component<SalesElectronicCouponStockProps, any> {
    componentWillReceiveProps(nextProps: any) {
        const currentCoupon = get(this.props, 'row.baseInfo.coupon', []);
        const nextCoupon = get(nextProps, 'row.baseInfo.coupon', []);
        if (!isEqual(currentCoupon, nextCoupon) && nextCoupon && nextCoupon.length > 0) {
            const [coupon] = nextCoupon;
            if (window.location.href.includes('/add')) {
                this.onValueChange(coupon.leftStock);
            }
            if (window.location.href.includes('/copy')) {
                if (coupon.leftStock) {
                    this.onValueChange(coupon.leftStock);
                } else {
                    const stock = get(nextProps, 'row.baseInfo.stock', []);
                    this.onValueChange(stock);
                }
            }
        }
    }

    onValueChange(num: number | undefined) {
        let { name, value, onChange, allowFloat } = this.props;
        if (!value) {
            value = undefined;
        }
        if (num) {
            if (num < 0) {
                num = 0;
            }
            if (!allowFloat) {
                num = Math.floor(num);
            }
        }
        value = num;
        if (onChange) {
            onChange(value, name);
        }
    }

    render() {
        const { value, addonbefore, addonafter, tip, rules, className, width } = this.props;
        return (
            <div className={classnames('integer-input-title-tip-template', className)}>
                <div className="input-title-line">
                    <div>
                        <span className="ant-input-group-wrapper">
                            <span className="ant-input-wrapper ant-input-group">
                                {addonbefore && (
                                    <span className="ant-input-group-addon">{addonbefore}</span>
                                )}
                                {rules && rules[0] ? (
                                    <InputNumber
                                        {...rules[0]}
                                        value={value === undefined ? '' : value}
                                        onChange={(value) => this.onValueChange(value)}
                                        style={{ width }}
                                    />
                                ) : (
                                    <InputNumber
                                        {...rules}
                                        value={value}
                                        onChange={(value) => this.onValueChange(value)}
                                        style={{ width }}
                                    />
                                )}

                                {addonafter && (
                                    <span className="ant-input-group-addon">{addonafter}</span>
                                )}
                            </span>
                        </span>
                        {tip && <div style={{ fontSize: 12, color: '#ccc' }}>{tip}</div>}
                    </div>
                </div>
            </div>
        );
    }
}
