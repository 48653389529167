import React, { Component } from 'react';
import { Input, Select } from 'antd';
import { services } from '@comall-backend-builder/core';
import './index.less';

interface CouponDetailGuideFilterValue {
    type: string;
    keyword: string;
}
interface Props {
    placeholder: string;
    options: Array<any>;
    value: CouponDetailGuideFilterValue | null;
    onChange: (value: CouponDetailGuideFilterValue | null) => void;
}
const array = ['GUIDE_GIVE_COUPON', 'GUIDE_SHARE_COUPON', 'GUIDE_SMS_GIVE_COUPON'];
export class CouponDetailGuideFilter extends Component<Props, {}> {
    onChange = (inputValue: string, fields: keyof CouponDetailGuideFilterValue) => {
        let { value, onChange } = this.props;
        if (!value) {
            value = {
                type: '',
                keyword: '',
            };
        }
        value[fields] = inputValue;
        if (value.type && !array.includes(value.type)) {
            value.keyword = '';
        }
        onChange(value);
    };
    render() {
        const { value, options, placeholder } = this.props;
        return (
            <div className="coupon-details-guide-filter">
                <Select
                    className="select"
                    value={value ? value.type : undefined}
                    onChange={(newValue: any) => {
                        this.onChange(newValue, 'type');
                    }}
                    placeholder={placeholder}
                >
                    {options.map((option: any) => {
                        return <Select.Option value={option.id}>{option.name}</Select.Option>;
                    })}
                </Select>
                {value && value.type && array.includes(value.type) && (
                    <Input
                        className="input"
                        value={value ? value.keyword : undefined}
                        onInput={(e: any) => {
                            this.onChange(e.target.value, 'keyword');
                        }}
                        placeholder={services.language.getText('srfqmczgygmc')}
                    />
                )}
            </div>
        );
    }
}
