import React, { PureComponent } from 'react';
import { Form, Slider, Radio } from 'antd';
import RadioGroup from 'antd/lib/radio/group';
import { tools } from '@comall-backend-builder/design-components-basis';
import { services } from '@comall-backend-builder/core';

const FormItem = Form.Item;
/**
 * 内容UI改版-服务-门店组件
 */
class serviceSubsite extends PureComponent<any> {
    render() {
        const { form } = this.props;

        const { getFieldDecorator } = form;
        const formItemLayout = tools.FormItemLayout;

        return (
            <Form className="service-subsite-card">
                <FormItem {...formItemLayout} label={'文字大小'}>
                    {getFieldDecorator('fontSize')(
                        <Slider defaultValue={28} min={20} max={36} step={2} />
                    )}
                </FormItem>

                <FormItem {...formItemLayout} label={'营业时间'}>
                    {getFieldDecorator('showBusinessHours', {
                        initialValue: 'hide',
                    })(
                        <RadioGroup className="">
                            <Radio value="show">{services.language.getText('display')}</Radio>
                            <Radio value="hide">{services.language.getText('hidden')}</Radio>
                        </RadioGroup>
                    )}
                </FormItem>
            </Form>
        );
    }
}

export const ServiceSubsite = tools.ConfigFormDecorator(serviceSubsite);
