import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach } from 'lodash';

const api = services.api;
export const QrcodeRuleLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/qr_code_rules';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/qr_code_rules/${id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.baseInfo = {
                    name: res.name,
                    type: res.type,
                    subsiteIds: res.subsites.map((item: any) => ({
                        id: item.id + '',
                        name: item.name,
                    })),
                    subSiteMerchantIds: res.subsiteMerchants,
                    linkType: res.linkType,
                    channel: res.channel,
                };
            } else {
                res.result = res.result.map((item: any) => {
                    item.type = item.type === 'SUBSITE' ? '门店二维码' : '专柜二维码';
                    item.linkType = item.linkType === 'HOME' ? '小程序首页' : '';
                    item.channel = item.channel === 'OFFLINE' ? '线下地推' : '';
                    return item;
                });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/qr_code_rules';
        let newParams: any = {};
        if (params.baseInfo) {
            newParams.name = params.baseInfo.name;
            newParams.type = params.baseInfo.type;
            if (params.baseInfo.type === 'SUBSITE') {
                if (params.baseInfo.subsiteIds && params.baseInfo.subsiteIds.length > 0) {
                    const subsitesArray: any = [];
                    params.baseInfo.subsiteIds.map((subsite: any) => {
                        subsitesArray.push(subsite.id);
                        return subsite.id;
                    });
                    newParams.subsiteIds = subsitesArray;
                }
            } else if (params.baseInfo.type === 'MERCHANT') {
                if (
                    params.baseInfo.subSiteMerchantIds &&
                    params.baseInfo.subSiteMerchantIds.length > 0
                ) {
                    const merchantArray: any = [];
                    forEach(params.baseInfo.subSiteMerchantIds, (merchant) => {
                        merchantArray.push(merchant.id);
                    });
                    newParams.subSiteMerchantIds = merchantArray;
                }
            }
            newParams.linkType = params.baseInfo.linkType;
            if (params.baseInfo.linkType === 'HOME') {
                newParams.link = 'index';
            }
            newParams.channel = params.baseInfo.channel;
        }
        return await api.post(newParams, config);
    },

    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/qr_code_rules/${id}`;
        let newParams: any = {};
        if (params.baseInfo) {
            newParams.name = params.baseInfo.name;
            newParams.type = params.baseInfo.type;
            if (params.baseInfo.type === 'SUBSITE') {
                if (params.baseInfo.subsiteIds && params.baseInfo.subsiteIds.length > 0) {
                    const subsitesArray: any = [];
                    params.baseInfo.subsiteIds.map((subsite: any) => {
                        subsitesArray.push(subsite.id);
                        return subsite.id;
                    });
                    newParams.subsiteIds = subsitesArray;
                }
            } else if (params.baseInfo.type === 'MERCHANT') {
                if (
                    params.baseInfo.subSiteMerchantIds &&
                    params.baseInfo.subSiteMerchantIds.length > 0
                ) {
                    const merchantArray: any = [];
                    forEach(params.baseInfo.subSiteMerchantIds, (merchant) => {
                        merchantArray.push(merchant.id);
                    });
                    newParams.subSiteMerchantIds = merchantArray;
                }
            }
            newParams.linkType = params.baseInfo.linkType;
            if (params.baseInfo.linkType === 'HOME') {
                newParams.link = 'index';
            }
            newParams.channel = params.baseInfo.channel;
        }
        return await api.put(newParams, config);
    },
};
