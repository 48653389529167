import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

let time = new Date().getTime();
let yesterday: any = new Date(time - 24 * 60 * 60 * 1000);
let lastWeek: any = new Date(time - 7 * 24 * 60 * 60 * 1000);
let yesterdayDate =
    yesterday.getFullYear() +
    '-' +
    (yesterday.getMonth() + 1 > 9 ? yesterday.getMonth() + 1 : '0' + (yesterday.getMonth() + 1)) +
    '-' +
    (yesterday.getDate() > 9 ? yesterday.getDate() : '0' + yesterday.getDate()) +
    ' 23:59:59';
let lastWeekDate =
    lastWeek.getFullYear() +
    '-' +
    (lastWeek.getMonth() + 1 > 9 ? lastWeek.getMonth() + 1 : '0' + (lastWeek.getMonth() + 1)) +
    '-' +
    (lastWeek.getDate() > 9 ? lastWeek.getDate() : '0' + lastWeek.getDate()) +
    ' 00:00:00';

export const config: Config = {
    components: {
        MiniprogramCodeDatasView: {
            component: 'Viewport',
        },
        MiniprogramCodeDatasPage: {
            entity: 'MiniprogramCodeDatasEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'GridLayout',
                    style: {
                        marginBottom: 10,
                    },
                    items: [
                        {
                            component: 'MiniprogramCodeDatasFilter',
                        },
                    ],
                },
                {
                    component: 'MiniprogramCodeDatasCard',
                },
                {
                    component: 'MiniprogramCodeDatasTable',
                },
            ],
        },
        MiniprogramCodeDatasFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'subsiteId',
                },
                {
                    property: 'urlLink',
                },
                {
                    property: 'channelType',
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        MiniprogramCodeDatasTable: {
            component: 'DataTable',
            loadFirstPage: true,
            rowKey: 'id',
            scroll: { x: true },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'subsiteName',
                },
                {
                    property: 'pageName',
                },
                {
                    property: 'source',
                },
                {
                    property: 'channel',
                },
                {
                    property: 'channelType',
                },
                {
                    property: 'scanCounts',
                },
                {
                    property: 'registerCounts',
                },
                {
                    property: 'crmRegisterCounts',
                },
                {
                    property: 'userCounts',
                },
                {
                    property: 'loginCounts',
                },
                {
                    property: 'orderCounts',
                },
                {
                    property: 'orderAmount',
                },
                {
                    property: 'orderPoints',
                },
                {
                    property: 'createTime',
                },
            ],
        },
        MiniprogramCodeDatasCard: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'MiniprogramCodeDatasCardView',
                },
            ],
        },
    },
    entities: {
        MiniprogramCodeDatasEntity: {
            apiPath: '/loader/admin/miniprogram-code-data-list',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<tgjkmc>>',
                    controlConfig: { placeholder: '<<qsrtgjkmc>>' },
                },
                subsiteId: {
                    type: 'string.options.autoComplete.plus',
                    displayName: '<<tgmd>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },

                    controlConfig: {
                        style: { width: 200 },
                        allowClear: true,
                    },
                },
                urlLink: {
                    type: 'string',
                    displayName: '<<ljdz>>',
                },
                channelType: {
                    type: 'string',
                    displayName: '<<qdlx>>',
                },
                dateRange: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<date>>',
                    defaultValue: { start: lastWeekDate, end: yesterdayDate },
                    controlConfig: {
                        placeholder: ['<<selectStartTime>>', '<<selectEndTime>>'],
                    },
                },
            },
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<tgjkmc>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<tgmd>>',
                },
                pageName: {
                    type: 'string',
                    displayName: '<<tgym>>',
                },
                source: {
                    type: 'string',
                    displayName: '<<yyly>>',
                },
                channel: {
                    type: 'string',
                    displayName: '<<tgqd>>',
                },
                channelType: {
                    type: 'string',
                    displayName: '<<qdlx>>',
                },
                scanCounts: {
                    type: 'string',
                    displayName: '<<smcs>>',
                },
                registerCounts: {
                    type: 'string',
                    displayName: '<<scdlyhs>>',
                },
                crmRegisterCounts: {
                    type: 'string',
                    displayName: '<<xzcyhs>>',
                },
                userCounts: {
                    type: 'string',
                    displayName: '<<fwyhs>>',
                },
                loginCounts: {
                    type: 'string',
                    displayName: '<<ydlfwhys>>',
                },
                orderCounts: {
                    type: 'string',
                    displayName: '<<orderCount>>',
                },
                orderAmount: {
                    type: 'string',
                    displayName: '<<orderAmount>>',
                },
                orderPoints: {
                    type: 'string',
                    displayName: '<<ddxhjf>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<createTimeStr>>',
                },
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/miniprogrom-code-datas',
                    component: 'MiniprogramCodeDatasView',
                    breadcrumbName: '<<miniProgramCodeDatas>>',
                    privilege: {
                        path: 'miniProgramCodeData',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'MiniprogramCodeDatasPage' }],
                },
            ],
        },
    ],
};
