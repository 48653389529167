import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';
import { isArray } from 'lodash';

export interface LinkTypeEffectPathConfig {
    /**
     * 配置 ID
     */
    id: string;
    /**
     * 配置名称
     */
    name: string;
    /**
     * 基础路径
     */
    basePath: string;
    /**
     * 参数字段名，当只有一个参数时可以简写为字符串
     */
    params?: string | string[];
    /**
     * 处理参数对象，使其兼容参数字段名
     */
    paramsHandler?(paramsObj: any): any;
    children?: LinkTypeEffectPathConfig[];
}

// 老可视化连接类型弃用，未支持i18n
export const linkTypeEffectPathConfigList: LinkTypeEffectPathConfig[] = [
    {
        id: 'home',
        name: '首页',
        basePath: 'pages/home/index',
    },
    {
        id: 'topic',
        name: '专题活动',
        basePath: 'pages/design/index?type=topic',
        params: 'id',
    },
    {
        id: 'member.creditEshop',
        name: '积分换礼',
        basePath: 'sub-packages/credit-eshop/pages/credit-eshop/index',
    },
    {
        id: 'product',
        name: '商品详情',
        basePath: 'sub-packages/product/pages/product-info/index',
        params: ['productId', 'goodsId'],
        paramsHandler(paramsObj: any) {
            return {
                productId: paramsObj.id,
                goodsId: paramsObj.goodsId || paramsObj.goodsMvo.id,
            };
        },
    },
    {
        id: 'products',
        name: '商品列表',
        basePath: 'sub-packages/product/pages/product-list/index',
    },
    {
        id: 'category',
        name: '分类',
        basePath: 'sub-packages/product/pages/product-list/index',
        params: ['title', 'c', 'vc'],
        paramsHandler(paramsObj: any) {
            return {
                title: paramsObj.name,
                c: 'category' === paramsObj.type ? paramsObj.id : undefined,
                vc: 'virtualCategory' === paramsObj.type ? paramsObj.id : undefined,
            };
        },
    },
    {
        id: 'live',
        name: '直播列表',
        basePath: 'pages/live-list/index',
    },
    {
        id: 'liveInfo',
        name: '直播间',
        basePath: 'plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin',
        params: 'room_id',
        paramsHandler(paramsObj: any) {
            return {
                room_id: paramsObj.roomid,
            };
        },
    },
    // {
    //     id: 'urlLink',
    //     name: 'URL地址',
    //     basePath: 'pages/webview/index',
    //     params: 'url',
    // },
    {
        id: 'flashList',
        name: '秒杀列表',
        basePath: 'pages/flash-sale-list/index',
    },
    {
        id: 'mallActivity',
        name: '商场活动',
        basePath: 'sub-packages/mall-activity/pages/activity-detail/index',
        params: 'id',
    },
    {
        id: 'mallActivityList',
        name: '商场活动列表',
        basePath: 'sub-packages/mall-activity/pages/activity-list/index',
        params: ['showCount', 'count', 'showStatus'],
    },
    {
        id: 'couponProductList',
        name: '售卖优惠券',
        basePath: 'sub-packages/shop/pages/coupon-product-list/index',
    },
    {
        id: 'couponProductGroupList',
        name: '拼团卖券列表',
        basePath: 'sub-packages/shop/pages/group-buying-list/index',
    },
    {
        id: 'wheelDraw',
        name: '大转盘活动',
        basePath: 'sub-packages/wheel-draw/pages/wheel-draw/index',
        params: 'id',
    },
    {
        id: 'redPackageRain',
        name: '红包雨活动',
        basePath: 'sub-packages/red-package-rain/pages/red-package-rain/index',
        params: 'id',
    },
    {
        id: 'marketing.wechatOfficialAccounts',
        name: '关注公众号',
        basePath: 'subpackages/wechat/pages/official-account/index',
    },
    {
        id: 'lotteryCode',
        name: '抽奖码活动',
        basePath: 'sub-packages/lottery-code/pages/lottery-code/index',
        params: 'id',
    },
    {
        id: 'scratchCard',
        name: '刮刮卡抽奖',
        basePath: 'sub-packages/scratch-card/pages/scratch-card/index',
        params: 'id',
    },
    {
        id: 'checkinIndex',
        name: '签到有礼',
        basePath: 'sub-packages/checkin/pages/design/index?type=checkinIndex',
    },
    {
        id: 'invite',
        name: '邀请有礼',
        basePath: 'sub-packages/invite/pages/design/index?type=invite',
        params: 'id',
    },
    {
        id: 'sharingReward',
        name: '分享有礼',
        basePath: 'sub-packages/topic/pages/share-page-activity-detail/index',
        params: 'id',
    },
    {
        id: 'serviceReserved',
        name: '服务预约',
        basePath: 'sub-packages/service-reserved/pages/service-reserved/index',
        params: 'id',
    },
    {
        id: 'serviceReservedList',
        name: '服务预约列表',
        basePath: 'sub-packages/service-reserved/pages/service-reserved-list/index',
    },
    {
        id: 'exchangeCoupons',
        name: '兑换优惠券',
        basePath: 'sub-packages/shop/pages/exchange/index',
    },
    {
        id: 'member',
        name: '会员中心',
        basePath: 'pages/mine/index',
    },
    {
        id: 'member.couponOrder',
        name: '卡券订单',
        basePath: 'sub-packages/shop/pages/order-list/index',
    },
    {
        id: 'member.group',
        name: '社区团购',
        basePath: 'sub-packages/cgb/pages/cgb-leader-center/index',
    },
    {
        id: 'member.group.order',
        name: '拼团订单',
        basePath: 'sub-packages/mine/pages/order-list/index',
    },
    {
        id: 'member.identification',
        name: '会员认证',
        basePath: 'sub-packages/mine/pages/user-info/identification/index',
    },
    {
        id: 'member.feedbackAdd',
        name: '意见反馈',
        basePath: '/subpackages/feedback/pages/feedback-add/index',
    },
    {
        id: 'member.feedback',
        name: '我的意见反馈',
        basePath: 'sub-packages/mine/pages/feedback/index',
    },
    {
        id: 'member.favorite',
        name: '收藏',
        basePath: 'sub-packages/mine/pages/favorite/index',
    },
    {
        id: 'member.coupon',
        name: '优惠券',
        basePath: 'sub-packages/shop/pages/coupon-list/index',
    },
    {
        id: 'member.consignee',
        name: '收货地址',
        basePath: 'sub-packages/mine/pages/consignee/index',
        params: 'displayMode',
    },
    {
        id: 'member.help',
        name: '帮助中心',
        basePath: 'sub-packages/mine/pages/help-center/index',
    },
    {
        id: 'member.membership',
        name: '会员权益',
        basePath: 'sub-packages/mine/pages/member-card-level-rights/index',
    },
    {
        id: 'member.parking',
        name: '停车缴费',
        basePath: 'sub-packages/carpark/pages/index/index',
    },
    {
        id: 'service.myCars',
        name: '我的车辆',
        basePath: 'subpackages/parking-fee/pages/my-cars/index',
    },
    {
        id: 'member.activity',
        name: '我的活动',
        basePath: 'sub-packages/mall-activity/pages/order-list/index',
    },
    {
        id: 'member.birthdayCaring',
        name: '生日有礼',
        basePath: 'sub-packages/mine/pages/birthday-caring/index',
    },
    {
        id: 'merchantGuide',
        name: '商场导览',
        basePath: 'sub-packages/merchant/pages/guide/index',
    },
    {
        id: 'coupon.couponConsume',
        name: '导购核销',
        basePath: 'sub-packages/consume/pages/consume/index',
    },
    {
        id: 'fengmap',
        name: '地图导览',
        basePath: 'pages/design/index?type=fengmapPage',
    },
    {
        id: 'member.selfCrediting',
        name: '自助积分',
        basePath: 'sub-packages/self-crediting/pages/self-crediting/index',
    },
    {
        id: 'member.serviceReservedOrderList',
        name: '我的预约',
        basePath: 'sub-packages/service-reserved/pages/order-list/index',
    },
    // {
    //     id: 'member.annualWelfare',
    //     name: '年终回馈',
    //     basePath: 'sub-packages/annual-welfare/pages/design/index?type=annualWelfare',
    // },
    {
        id: 'merchant',
        name: '专柜详情',
        basePath: 'pages/design/index?type=merchant',
        params: ['id', 'merchantId'],
        paramsHandler(paramsObj: any) {
            return paramsObj;
        },
    },
    {
        id: 'cardCollection',
        name: '集卡有礼',
        basePath: 'sub-packages/card-collection/pages/card-collection/index',
        params: 'id',
    },
    {
        id: 'member.offlineOrderComment',
        name: '线下订单',
        basePath: 'sub-packages/offline-order-comment/pages/offline-order-comment-list/index',
    },
];
if (ENV.TENANT === 'hxgc' || ENV.TENANT === 'localhost') {
    linkTypeEffectPathConfigList.push({
        id: 'gamePlatform',
        name: '游戏平台',
        basePath: 'sub-packages/third-party/pages/game-list/index',
    });
    linkTypeEffectPathConfigList.push({
        id: 'anniversary',
        name: '周年庆报告',
        basePath: 'sub-packages/third-party/pages/anniversary/index',
    });
}
/**
 * 注：newLinkTypeEffectPathConfigList
 * id: 老c存在即跟老c保持一致
 * basePath： 使用新c对应的路径
 * params： string | string[]
 * paramsHandler 参数处理钩子
 *
 */
export const newLinkTypeEffectPathConfigList: LinkTypeEffectPathConfig[] = [
    {
        id: 'none',
        name: '无链接',
        basePath: 'none',
    },
    {
        id: 'miniprogram',
        name: '它方小程序',
        basePath: 'none',
    },
    {
        id: 'urlLink',
        name: 'URL地址',
        basePath: 'subpackages/url-link-page/index',
        params: ['url', 'needAuthrization'],
    },
    {
        id: 'subsite',
        name: '门店首页',
        basePath: 'pages/home/index',
        params: ['id', 'name'],
    },
    {
        id: 'platform',
        name: '平台首页',
        basePath: 'pages/platform/index',
    },
    {
        id: 'loginStorePage',
        name: '登录/注册页',
        basePath: 'subpackages/login/pages/login-choice/index',
    },
    {
        id: 'cart',
        name: '购物车',
        basePath: 'subpackages/my-carts-in-sub/index',
    },
    {
        id: 'newMerchant',
        name: '专柜首页',
        basePath: 'subpackages/merchant/index',
        params: ['id', 'merchantId', 'storeId', 'name'],
        paramsHandler(paramsObj: any) {
            return paramsObj;
        },
    },
    {
        id: 'category',
        name: '标准/虚拟分类商品列表',
        basePath: 'subpackages/search-result/index',
        params: ['title', 'c', 'vc'],
        paramsHandler(paramsObj: any) {
            return {
                title: paramsObj.name,
                c: 'category' === paramsObj.type ? paramsObj.id : undefined,
                vc: 'virtualCategory' === paramsObj.type ? paramsObj.id : undefined,
            };
        },
    },
    {
        id: 'categoryNav',
        name: '营销分类模板',
        basePath: 'pages/category/index',
        params: ['categoryId', 'name'],
        paramsHandler(paramsObj: any) {
            return {
                categoryId: paramsObj.id,
                name: paramsObj.name,
            };
        },
    },
    {
        id: 'categoryNavProduct',
        name: '营销分类商品列表',
        basePath: 'subpackages/search-result/index',
        params: ['categoryIds', 'virtualCategoryIds', 'name', 'id'],
        paramsHandler(paramsObj: any) {
            return {
                categoryIds: paramsObj.c,
                virtualCategoryIds: paramsObj.vc,
                name: paramsObj.name,
                id: paramsObj.id,
            };
        },
    },
    {
        id: 'products',
        name: '普通商品列表',
        basePath: 'subpackages/search-result/index',
    },
    {
        id: 'couponProductList',
        name: '虚拟商品列表',
        basePath: 'subpackages/virtual-products/index',
    },
    {
        id: 'product',
        name: '商品详情',
        basePath: 'subpackages/product-detail/index',
        params: ['productId', 'goodsId'],
        paramsHandler(paramsObj: any) {
            return {
                productId: paramsObj.id,
                goodsId: paramsObj.goodsId || paramsObj.goodsMvo.id,
            };
        },
    },
    {
        id: 'merchantGuide',
        name: '商场导览',
        basePath: 'subpackages/merchant-guide/index',
    },
    {
        id: 'merchantGuideBrand',
        name: '品牌馆',
        basePath: '/subpackages/merchant-guide/pages/brand/index',
    },
    {
        id: 'facilityGuide',
        name: '设施导览',
        basePath: '/subpackages/facility-guide/index',
    },
    {
        id: 'fengmap',
        name: '地图导航',
        basePath: 'subpackages/url-link-page/index',
    },
    {
        id: 'search',
        name: '搜索',
        basePath: 'subpackages/search/index',
    },
    {
        id: 'newTopic',
        name: '专题活动',
        basePath: 'subpackages/topic/index',
        params: 'id',
    },

    {
        id: 'member',
        name: '会员中心',
        basePath: 'pages/member-center/index',
    },

    {
        id: 'member.membership',
        name: '会员权益',
        basePath: 'subpackages/member-card-level-rights/index',
    },
    {
        id: 'liveInfo',
        name: '微信直播间',
        basePath: 'plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin',
        params: 'room_id',
        paramsHandler(paramsObj: any) {
            return {
                room_id: paramsObj.roomid,
            };
        },
    },
    {
        id: 'live',
        name: '微信直播列表',
        basePath: 'subpackages/live-list/index',
    },
    {
        id: 'topicContent',
        name: '话题',
        basePath: 'subpackages/content/pages/hashtag-list/index',
        params: 'id',
    },
    {
        id: 'contentNoteDetail',
        name: '笔记详情',
        basePath: 'subpackages/content/pages/detail/index',
        params: ['id', 'type'],
        paramsHandler(paramsObj: any) {
            return {
                type: paramsObj.type,
                id: paramsObj.id,
            };
        },
    },
    {
        id: 'marketing',
        name: '营促销活动',
        basePath: '',
        children: [
            {
                id: 'flashList',
                name: '秒杀列表',
                basePath: 'subpackages/flash-sale/index',
            },
            {
                id: 'wheelDraw',
                name: '大转盘抽奖',
                basePath: 'subpackages/wheel-draw/pages/detail/index',
                params: 'id',
            },
            {
                id: 'invite',
                name: '邀请有礼',
                basePath: 'subpackages/invite-reward/pages/detail/index',
                params: 'id',
            },
            {
                id: 'checkinIndex',
                name: '签到有礼',
                basePath: 'subpackages/sign-in-with-gift/index',
            },
            {
                id: 'cardCollection',
                name: '集卡活动',
                basePath: 'subpackages/card-collection/index',
                params: 'id',
            },
            {
                id: 'collectionPoint',
                name: '集点卡',
                basePath: 'subpackages/market/pages/collection-point/index',
                params: 'id',
            },
            {
                id: 'watchword',
                name: '口令领券',
                basePath: 'subpackages/market/watchword/pages/detail/index',
                params: 'id',
            },
            {
                id: 'redPackageRain',
                name: '红包雨',
                basePath: 'subpackages/packet-rain/index',
                params: 'id',
            },
            {
                id: 'marketing.wechatOfficialAccounts',
                name: '关注公众号',
                basePath: 'subpackages/wechat/pages/official-account/index',
            },
            {
                id: 'sharingReward',
                name: '分享有礼',
                basePath: 'subpackages/topic/pages/share-page-activity-detail/index',
                params: 'id',
            },
            {
                id: 'marketing.paymentRewardActivity',
                name: '支付有礼',
                basePath: 'subpackages/market/payment-reward/pages/detail/index?type=PAY_AWARD',
            },
            {
                id: 'marketing.paymentReward',
                name: '支付奖励',
                basePath:
                    'subpackages/market/payment-reward/pages/detail/index?type=EVENT_MARKET_TYPE_PAY_AWARD',
            },
            {
                id: 'groupBuyingList',
                name: '普通商品拼团列表',
                basePath: 'subpackages/group-buying/pages/list/index',
            },
            {
                id: 'couponProductGroupList',
                name: '虚拟商品拼团列表',
                basePath: 'subpackages/group-buying/pages/virtual-list/index',
            },
            {
                id: 'marketing.groupBuyingDetail',
                name: '拼团商品',
                basePath: 'subpackages/product-detail/index',
                params: ['productId', 'goodsId'],
                paramsHandler(paramsObj: any) {
                    return {
                        productId: paramsObj.productId,
                        goodsId: paramsObj.goodsId,
                    };
                },
            },

            {
                id: 'blindBoxList',
                name: '盲盒活动列表',
                basePath: 'subpackages/blind-box/pages/list/index',
            },
            {
                id: 'blindBox',
                name: '盲盒活动',
                basePath: 'subpackages/blind-box/pages/detail/index',
                params: ['id', 'name'],
            },
            {
                id: 'marketing.openBoxDetail',
                name: '开盒有礼',
                basePath: 'subpackages/market/open-box/pages/open/index',
                params: ['id', 'name'],
            },
            {
                id: 'fullPayment',
                name: '全款预售',
                basePath: 'subpackages/activities/pages/presale-activities/index?mode=FULL_PAYMENT',
            },
            {
                id: 'reserve',
                name: '预约抢购',
                basePath: 'subpackages/activities/pages/presale-activities/index?mode=RESERVE',
            },
            {
                id: 'marketing.maotaiPreSaleList',
                name: '茅台预售列表',
                basePath: 'subpackages/maotai-pre-sale/pages/list/index',
            },
            {
                id: 'marketing.maotaiPreSaleDetail',
                name: '茅台预售商品',
                basePath: 'subpackages/maotai-pre-sale/pages/detail/index',
                params: ['productId', 'goodsId', 'activityId'],
                paramsHandler(paramsObj: any) {
                    return {
                        productId: paramsObj.productId,
                        goodsId: paramsObj.goodsId,
                        activityId: paramsObj.activityId,
                    };
                },
            },
            {
                id: 'internalPurchase',
                name: '企业内购',
                basePath: 'subpackages/internal-purchase/pages/main/index',
                params: ['id', 'name', 'subsiteId'],
                paramsHandler(paramsObj: any) {
                    return paramsObj;
                },
            },
            {
                id: 'marketing.fixedPrice',
                name: '打包一口价',
                basePath: '/subpackages/search-result/index',
                params: ['id', 'name', 'subsiteId'],
            },
            {
                id: 'marketing.bargainActivityList',
                name: '砍价活动列表',
                basePath: '/subpackages/market/bargain/pages/list/index',
            },
            {
                id: 'marketing.bargainActivity',
                name: '砍价活动',
                basePath: '/subpackages/market/bargain/pages/detail/index',
                params: ['id', 'name'],
            },
        ],
    },
    {
        id: 'credit',
        name: '积分活动',
        basePath: '',
        children: [
            {
                id: 'member.creditEshop',
                name: '积分商城',
                basePath: 'subpackages/credit-shop/index',
            },
            {
                id: 'credit.creditShopDetail',
                name: '积分商城商品',
                basePath: 'subpackages/credit-shop/pages/credit-shop-details/index',
                params: ['saleRuleId', 'subsiteId'],
            },
            {
                id: 'scratchCard',
                name: '积分刮刮卡',
                basePath: 'subpackages/scratch-card/index',
                params: 'id',
            },
            {
                id: 'lotteryCode',
                name: '积分抽奖码',
                basePath: 'subpackages/lottery-code/index',
                params: 'id',
            },
            {
                id: 'member.selfCrediting',
                name: '自助积分',
                basePath: 'subpackages/self-crediting/pages/main/index',
            },
            {
                id: 'fastIntegrate',
                name: '快速积分',
                basePath: 'plugin://business-circle-point-plugin/index',
            },
            {
                id: 'fastIntegrateMerchant',
                name: '快速积分商家',
                basePath: 'subpackages/merchant-guide/pages/quick-point-merchants/index',
            },
            {
                id: 'pointExchange',
                name: '积分换余额券',
                basePath: 'subpackages/point-exchange/index',
            },
        ],
    },
    {
        id: 'member-menu',
        name: '会员相关活动',
        basePath: '',
        children: [
            {
                id: 'serviceReserved',
                name: '服务预约',
                basePath: 'subpackages/reserve-service/pages/detail/index',
                params: ['name', 'id'],
            },
            {
                id: 'reserveService',
                name: '服务预约列表',
                basePath: 'subpackages/reserve-service/pages/list/index',
            },
            {
                id: 'mallActivity',
                name: '商场活动',
                // basePath: 'subpackages/mall-activity/index',
                basePath: 'sub-packages/mall-activity/pages/activity-detail/index',
                params: 'id',
            },
            {
                id: 'member.mallActivity',
                name: '商场活动列表',
                basePath: 'subpackages/mall-activity/index',
            },
            // 商场活动列表 待产品确认是否需要增加
            {
                id: 'member.birthdayCaring',
                name: '生日有礼',
                basePath: 'subpackages/birthday-marketing/index',
            },
            // {
            //     id: 'annualWelfare',
            //     name: '年终回馈',
            //     basePath: 'subpackages/annual-welfare/index',
            // },
            {
                id: 'member.storedValueCard',
                name: '储值卡首页',
                basePath: 'subpackages/stored-value-card/index',
            },
            {
                id: 'member.restaurantReservation',
                name: '餐厅订座',
                basePath: 'subpackages/restaurant-reservation/pages/restaurant-reservation/index',
                params: ['id', 'name'],
            },
            {
                id: 'member.reserveGift',
                name: '礼品预约',
                basePath: 'subpackages/market/reserve-gift/pages/list/index',
            },
        ],
    },
    {
        id: 'service',
        name: '服务工具',
        basePath: '',
        children: [
            {
                id: 'service.identification',
                name: '个人信息',
                basePath: 'subpackages/my-profile/pages/my-profile-editor/index',
            },
            {
                id: 'service.passwordEditor',
                name: '重置密码',
                basePath: 'subpackages/my-profile/pages/sms-verification/index',
                params: ['name', 'paymentMode'],
            },
            // 隐藏部分链接
            // {
            //     id: 'service.merchantCollection',
            //     name: '专柜收藏',
            //     basePath: 'subpackages/my-merchant-collections/index',
            // },
            {
                id: 'member.parking',
                name: '停车缴费',
                basePath: 'subpackages/parking-fee/index',
            },
            {
                id: 'service.myCars',
                name: '我的车辆',
                basePath: 'subpackages/parking-fee/pages/my-cars/index',
            },
            {
                id: 'service.findCars',
                name: '寻找车辆',
                basePath: 'subpackages/parking-fee/pages/find-cars/index',
            },
            // {
            //     id: 'member.consignee',
            //     name: '收货地址',
            //     basePath: 'subpackages/my-address-book/index',
            //     params: 'displayMode',
            // },
            {
                id: 'member.coupon',
                name: '我的优惠券',
                basePath: 'subpackages/coupons-center/index',
            },
            {
                id: 'service.onlineCoupon',
                name: '线上券',
                basePath: 'subpackages/coupons-center/pages/my-online-coupons/index',
            },
            {
                id: 'service.exchange',
                name: '兑换优惠券',
                basePath: 'subpackages/coupons-center/pages/exchange/index',
            },
            {
                id: 'service.couponTransfer',
                name: '赠送优惠券',
                basePath: '/subpackages/coupons-center/pages/coupon-transfer-choose/index',
            },
            {
                id: 'service.balanceCoupon',
                name: '我的余额券',
                basePath: 'subpackages/coupons-center/pages/my-balance-coupons/index',
                params: ['title'],
            },
            {
                id: 'service.thirdCoupon',
                name: 'CRM券',
                basePath: 'subpackages/third-coupons-center/index',
            },
            // {
            //     id: 'member.favorite',
            //     name: '商品收藏',
            //     basePath: 'subpackages/my-goods-collections/index',
            // },
            {
                id: 'service.distribution',
                name: '分销赚钱',
                basePath: 'subpackages/distribution/index',
            },
            {
                id: 'service.ordinaryProductOrders',
                name: '普通商品订单',
                basePath: '/subpackages/my-orders/index?type=0',
            },
            {
                id: 'service.virtualOrder',
                name: '虚拟商品订单',
                basePath: 'subpackages/my-virtual-orders/index',
            },
            {
                id: 'service.storedValueCardOrder',
                name: '储值卡订单',
                basePath: 'subpackages/order-manage/pages/stored-value-card-orders/index',
            },
            {
                id: 'service.depositOrder',
                name: '订金订单',
                basePath: 'subpackages/order-manage/pages/deposit-order-list/index',
            },
            {
                id: 'service.offlineDepositOrder',
                name: '线下订金单',
                basePath: 'subpackages/order-manage/pages/offline-deposit-order-list/index',
            },
            {
                id: 'service.myLotteryCode',
                name: '我的抽奖',
                basePath: 'subpackages/lottery-code/pages/my-lotteries/index',
            },
            // {
            //     id: 'member.serviceReservedOrderList',
            //     name: '我的抢购预约',
            //     basePath: 'subpackages/activities/pages/my-reservation/index',
            // },
            {
                id: 'service.reserveService',
                name: '我的服务预约',
                basePath: 'subpackages/reserve-service/pages/orders/index',
            },
            {
                id: 'service.myMallAcivityOrder',
                name: '我的商场活动',
                basePath: 'subpackages/mall-activity/pages/my-order/index',
            },
            {
                id: 'service.myRestaurantReservation',
                name: '我的预订',
                basePath:
                    'subpackages/restaurant-reservation/pages/my-restaurant-reservation/index',
            },
            {
                id: 'service.invoiceCenter',
                name: '我的发票',
                basePath: 'subpackages/invoice/pages/my-invoice/index',
            },
            {
                id: 'service.myPoint',
                name: '我的积分',
                basePath: 'subpackages/my-points/index',
            },
            {
                id: 'member.offlineOrderComment',
                name: '电子小票',
                basePath: 'subpackages/electronic-bill/pages/list/index',
            },
            {
                id: 'member.feedbackAdd',
                name: '意见反馈',
                basePath: '/subpackages/feedback/pages/feedback-add/index',
            },
            {
                id: 'member.feedback',
                name: '我的意见反馈',
                basePath: 'subpackages/feedback/index',
            },
            {
                id: 'service.openInvoice',
                name: '开具发票',
                basePath: '/subpackages/invoice/pages/open-invoice/index',
            },
            {
                id: 'service.topicActivities',
                name: '创作排行',
                basePath: '/subpackages/content/pages/topic-activities-list/index',
            },
            {
                id: 'service.connectWifi',
                name: 'Wi-Fi一键连',
                basePath: '/subpackages/market/connect-wifi/pages/index',
            },
        ],
    },

    // {
    //     id: 'storePage',
    //     name: '商场',
    //     basePath: 'pages/home/index',
    // },

    // {
    //     id: 'member.couponOrder',
    //     name: '虚拟商品订单',
    //     basePath: 'subpackages/my-virtual-orders/index',
    // },
];

/**
 * 注：openLinkTypeEffectPathConfigList 开屏广告写死版
 *
 */
const openLinkTypeEffectPathConfigList: LinkTypeEffectPathConfig[] = [
    // {
    //     id: 'none',
    //     name: '无链接',
    //     basePath: 'none',
    // },
    {
        id: 'miniprogram',
        name: '它方小程序',
        basePath: 'none',
    },
    {
        id: 'urlLink',
        name: 'URL地址',
        basePath: 'subpackages/url-link-page/index',
        params: ['url', 'needAuthrization'],
    },
    {
        id: 'subsite',
        name: '门店首页',
        basePath: 'pages/home/index',
        params: ['id', 'name'],
    },
    // {
    //     id: 'platform',
    //     name: '平台首页',
    //     basePath: 'pages/platform/index',
    // },
    // {
    //     id: 'loginStorePage',
    //     name: '登录/注册页',
    //     basePath: 'subpackages/login/pages/login-choice/index',
    // },
    // {
    //     id: 'cart',
    //     name: '购物车',
    //     basePath: 'subpackages/my-carts-in-sub/index',
    // },
    {
        id: 'newMerchant',
        name: '专柜首页',
        basePath: 'subpackages/merchant/index',
        params: ['id', 'merchantId', 'storeId', 'name'],
        paramsHandler(paramsObj: any) {
            return paramsObj;
        },
    },
    {
        id: 'category',
        name: '标准/虚拟分类商品列表',
        basePath: 'subpackages/search-result/index',
        params: ['title', 'c', 'vc'],
        paramsHandler(paramsObj: any) {
            return {
                title: paramsObj.name,
                c: 'category' === paramsObj.type ? paramsObj.id : undefined,
                vc: 'virtualCategory' === paramsObj.type ? paramsObj.id : undefined,
            };
        },
    },
    {
        id: 'categoryNav',
        name: '营销分类模板',
        basePath: 'pages/category/index',
        params: ['categoryId', 'name'],
        paramsHandler(paramsObj: any) {
            return {
                categoryId: paramsObj.id,
                name: paramsObj.name,
            };
        },
    },
    {
        id: 'categoryNavProduct',
        name: '营销分类商品列表',
        basePath: 'subpackages/search-result/index',
        params: ['categoryIds', 'virtualCategoryIds', 'name', 'id'],
        paramsHandler(paramsObj: any) {
            return {
                categoryIds: paramsObj.c,
                virtualCategoryIds: paramsObj.vc,
                name: paramsObj.name,
                id: paramsObj.id,
            };
        },
    },
    {
        id: 'products',
        name: '普通商品列表',
        basePath: 'subpackages/search-result/index',
    },
    {
        id: 'couponProductList',
        name: '虚拟商品列表',
        basePath: 'subpackages/virtual-products/index',
    },
    // {
    //     id: 'product',
    //     name: '商品详情',
    //     basePath: 'subpackages/product-detail/index',
    //     params: ['productId', 'goodsId'],
    //     paramsHandler(paramsObj: any) {
    //         return {
    //             productId: paramsObj.id,
    //             goodsId: paramsObj.goodsId || paramsObj.goodsMvo.id,
    //         };
    //     },
    // },
    {
        id: 'merchantGuide',
        name: '商场导览',
        basePath: 'subpackages/merchant-guide/index',
    },
    {
        id: 'merchantGuideBrand',
        name: '品牌馆',
        basePath: '/subpackages/merchant-guide/pages/brand/index',
    },
    // {
    //     id: 'facilityGuide',
    //     name: '设施导览',
    //     basePath: '/subpackages/facility-guide/index',
    // },
    // {
    //     id: 'fengmap',
    //     name: '地图导航',
    //     basePath: 'subpackages/url-link-page/index',
    // },
    // {
    //     id: 'search',
    //     name: '搜索',
    //     basePath: 'subpackages/search/index',
    // },
    {
        id: 'newTopic',
        name: '专题活动',
        basePath: 'subpackages/topic/index',
        params: 'id',
    },

    {
        id: 'member',
        name: '会员中心',
        basePath: 'pages/member-center/index',
    },

    // {
    //     id: 'member.membership',
    //     name: '会员权益',
    //     basePath: 'subpackages/member-card-level-rights/index',
    // },
    // {
    //     id: 'liveInfo',
    //     name: '微信直播间',
    //     basePath: 'plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin',
    //     params: 'room_id',
    //     paramsHandler(paramsObj: any) {
    //         return {
    //             room_id: paramsObj.roomid,
    //         };
    //     },
    // },
    // {
    //     id: 'live',
    //     name: '微信直播列表',
    //     basePath: 'subpackages/live-list/index',
    // },
    {
        id: 'topicContent',
        name: '话题',
        basePath: 'subpackages/content/pages/hashtag-list/index',
        params: 'id',
    },
    // {
    //     id: 'contentNoteDetail',
    //     name: '笔记详情',
    //     basePath: 'subpackages/content/pages/detail/index',
    //     params: ['id', 'type'],
    //     paramsHandler(paramsObj: any) {
    //         return {
    //             type: paramsObj.type,
    //             id: paramsObj.id,
    //         };
    //     },
    // },
    {
        id: 'marketing',
        name: '营促销活动',
        basePath: '',
        children: [
            {
                id: 'flashList',
                name: '秒杀列表',
                basePath: 'subpackages/flash-sale/index',
            },
            {
                id: 'wheelDraw',
                name: '大转盘抽奖',
                basePath: 'subpackages/wheel-draw/pages/detail/index',
                params: 'id',
            },
            {
                id: 'invite',
                name: '邀请有礼',
                basePath: 'subpackages/invite-reward/pages/detail/index',
                params: 'id',
            },
            {
                id: 'checkinIndex',
                name: '签到有礼',
                basePath: 'subpackages/sign-in-with-gift/index',
            },
            {
                id: 'cardCollection',
                name: '集卡活动',
                basePath: 'subpackages/card-collection/index',
                params: 'id',
            },
            {
                id: 'collectionPoint',
                name: '集点卡',
                basePath: 'subpackages/market/pages/collection-point/index',
                params: 'id',
            },
            {
                id: 'watchword',
                name: '口令领券',
                basePath: 'subpackages/market/watchword/pages/detail/index',
                params: 'id',
            },
            {
                id: 'redPackageRain',
                name: '红包雨',
                basePath: 'subpackages/packet-rain/index',
                params: 'id',
            },
            {
                id: 'marketing.wechatOfficialAccounts',
                name: '关注公众号',
                basePath: 'subpackages/wechat/pages/official-account/index',
            },
            {
                id: 'sharingReward',
                name: '分享有礼',
                basePath: 'subpackages/topic/pages/share-page-activity-detail/index',
                params: 'id',
            },
            {
                id: 'marketing.paymentRewardActivity',
                name: '支付有礼',
                basePath: 'subpackages/market/payment-reward/pages/detail/index?type=PAY_AWARD',
            },
            {
                id: 'marketing.paymentReward',
                name: '支付奖励',
                basePath:
                    'subpackages/market/payment-reward/pages/detail/index?type=EVENT_MARKET_TYPE_PAY_AWARD',
            },
            {
                id: 'groupBuyingList',
                name: '普通商品拼团列表',
                basePath: 'subpackages/group-buying/pages/list/index',
            },
            {
                id: 'couponProductGroupList',
                name: '虚拟商品拼团列表',
                basePath: 'subpackages/group-buying/pages/virtual-list/index',
            },
            {
                id: 'marketing.groupBuyingDetail',
                name: '拼团商品',
                basePath: 'subpackages/product-detail/index',
                params: ['productId', 'goodsId'],
                paramsHandler(paramsObj: any) {
                    return {
                        productId: paramsObj.productId,
                        goodsId: paramsObj.goodsId,
                    };
                },
            },

            {
                id: 'blindBoxList',
                name: '盲盒活动列表',
                basePath: 'subpackages/blind-box/pages/list/index',
            },
            {
                id: 'blindBox',
                name: '盲盒活动',
                basePath: 'subpackages/blind-box/pages/detail/index',
                params: ['id', 'name'],
            },
            {
                id: 'marketing.openBoxDetail',
                name: '开盒有礼',
                basePath: 'subpackages/market/open-box/pages/open/index',
                params: ['id', 'name'],
            },
            {
                id: 'fullPayment',
                name: '全款预售',
                basePath: 'subpackages/activities/pages/presale-activities/index?mode=FULL_PAYMENT',
            },
            {
                id: 'reserve',
                name: '预约抢购',
                basePath: 'subpackages/activities/pages/presale-activities/index?mode=RESERVE',
            },
            {
                id: 'marketing.maotaiPreSaleList',
                name: '茅台预售列表',
                basePath: 'subpackages/maotai-pre-sale/pages/list/index',
            },
            {
                id: 'marketing.maotaiPreSaleDetail',
                name: '茅台预售商品',
                basePath: 'subpackages/maotai-pre-sale/pages/detail/index',
                params: ['productId', 'goodsId', 'activityId'],
                paramsHandler(paramsObj: any) {
                    return {
                        productId: paramsObj.productId,
                        goodsId: paramsObj.goodsId,
                        activityId: paramsObj.activityId,
                    };
                },
            },
            {
                id: 'internalPurchase',
                name: '企业内购',
                basePath: 'subpackages/internal-purchase/pages/main/index',
                params: ['id', 'name', 'subsiteId'],
                paramsHandler(paramsObj: any) {
                    return paramsObj;
                },
            },
            {
                id: 'marketing.fixedPrice',
                name: '打包一口价',
                basePath: '/subpackages/search-result/index',
                params: ['id', 'name', 'subsiteId'],
            },
            {
                id: 'marketing.bargainActivityList',
                name: '砍价活动列表',
                basePath: '/subpackages/market/bargain/pages/list/index',
            },
            {
                id: 'marketing.bargainActivity',
                name: '砍价活动',
                basePath: '/subpackages/market/bargain/pages/detail/index',
                params: ['id', 'name'],
            },
        ],
    },
    {
        id: 'credit',
        name: '积分活动',
        basePath: '',
        children: [
            {
                id: 'member.creditEshop',
                name: '积分商城',
                basePath: 'subpackages/credit-shop/index',
            },
            {
                id: 'credit.creditShopDetail',
                name: '积分商城商品',
                basePath: 'subpackages/credit-shop/pages/credit-shop-details/index',
                params: ['saleRuleId', 'subsiteId'],
            },
            {
                id: 'scratchCard',
                name: '积分刮刮卡',
                basePath: 'subpackages/scratch-card/index',
                params: 'id',
            },
            {
                id: 'lotteryCode',
                name: '积分抽奖码',
                basePath: 'subpackages/lottery-code/index',
                params: 'id',
            },
            // {
            //     id: 'member.selfCrediting',
            //     name: '自助积分',
            //     basePath: 'subpackages/self-crediting/pages/main/index',
            // },
            // {
            //     id: 'fastIntegrate',
            //     name: '快速积分',
            //     basePath: 'plugin://business-circle-point-plugin/index',
            // },
            // {
            //     id: 'fastIntegrateMerchant',
            //     name: '快速积分商家',
            //     basePath: 'subpackages/merchant-guide/pages/quick-point-merchants/index',
            // },
            {
                id: 'pointExchange',
                name: '积分换余额券',
                basePath: 'subpackages/point-exchange/index',
            },
        ],
    },
    {
        id: 'member-menu',
        name: '会员相关活动',
        basePath: '',
        children: [
            // {
            //     id: 'serviceReserved',
            //     name: '服务预约',
            //     basePath: 'subpackages/reserve-service/pages/detail/index',
            //     params: ['name', 'id'],
            // },
            // {
            //     id: 'reserveService',
            //     name: '服务预约列表',
            //     basePath: 'subpackages/reserve-service/pages/list/index',
            // },
            {
                id: 'mallActivity',
                name: '商场活动',
                // basePath: 'subpackages/mall-activity/index',
                basePath: 'sub-packages/mall-activity/pages/activity-detail/index',
                params: 'id',
            },
            {
                id: 'member.mallActivity',
                name: '商场活动列表',
                basePath: 'subpackages/mall-activity/index',
            },
            // 商场活动列表 待产品确认是否需要增加
            // {
            //     id: 'member.birthdayCaring',
            //     name: '生日有礼',
            //     basePath: 'subpackages/birthday-marketing/index',
            // },
            // {
            //     id: 'annualWelfare',
            //     name: '年终回馈',
            //     basePath: 'subpackages/annual-welfare/index',
            // },
            {
                id: 'member.storedValueCard',
                name: '储值卡首页',
                basePath: 'subpackages/stored-value-card/index',
            },
            {
                id: 'member.restaurantReservation',
                name: '餐厅订座',
                basePath: 'subpackages/restaurant-reservation/pages/restaurant-reservation/index',
                params: ['id', 'name'],
            },
        ],
    },
    {
        id: 'service',
        name: '服务工具',
        basePath: '',
        children: [
            // {
            //     id: 'service.identification',
            //     name: '个人信息',
            //     basePath: 'subpackages/my-profile/pages/my-profile-editor/index',
            // },
            // {
            //     id: 'service.passwordEditor',
            //     name: '重置密码',
            //     basePath: 'subpackages/my-profile/pages/sms-verification/index',
            //     params: ['name', 'paymentMode'],
            // },
            // {
            //     id: 'member.parking',
            //     name: '停车缴费',
            //     basePath: 'subpackages/parking-fee/index',
            // },
            // {
            //     id: 'member.coupon',
            //     name: '我的优惠券',
            //     basePath: 'subpackages/coupons-center/index',
            // },
            // {
            //     id: 'service.onlineCoupon',
            //     name: '线上券',
            //     basePath: 'subpackages/coupons-center/pages/my-online-coupons/index',
            // },
            {
                id: 'service.exchange',
                name: '兑换优惠券',
                basePath: 'subpackages/coupons-center/pages/exchange/index',
            },
            // {
            //     id: 'service.balanceCoupon',
            //     name: '我的余额券',
            //     basePath: 'subpackages/coupons-center/pages/my-balance-coupons/index',
            //     params: ['title'],
            // },
            // {
            //     id: 'service.thirdCoupon',
            //     name: 'CRM券',
            //     basePath: 'subpackages/third-coupons-center/index',
            // },
            {
                id: 'service.distribution',
                name: '分销赚钱',
                basePath: 'subpackages/distribution/index',
            },
            // {
            //     id: 'service.virtualOrder',
            //     name: '虚拟商品订单',
            //     basePath: 'subpackages/my-virtual-orders/index',
            // },
            // {
            //     id: 'service.myLotteryCode',
            //     name: '我的抽奖',
            //     basePath: 'subpackages/lottery-code/pages/my-lotteries/index',
            // },
            // {
            //     id: 'service.reserveService',
            //     name: '我的服务预约',
            //     basePath: 'subpackages/reserve-service/pages/orders/index',
            // },
            // {
            //     id: 'service.myMallAcivityOrder',
            //     name: '我的商场活动',
            //     basePath: 'subpackages/mall-activity/pages/my-order/index',
            // },
            // {
            //     id: 'service.myRestaurantReservation',
            //     name: '我的预订',
            //     basePath:
            //         'subpackages/restaurant-reservation/pages/my-restaurant-reservation/index',
            // },
            // {
            //     id: 'service.invoiceCenter',
            //     name: '我的发票',
            //     basePath: 'subpackages/invoice/pages/my-invoice/index',
            // },
            // {
            //     id: 'service.myPoint',
            //     name: '我的积分',
            //     basePath: 'subpackages/my-points/index',
            // },
            // {
            //     id: 'member.offlineOrderComment',
            //     name: '电子小票',
            //     basePath: 'subpackages/electronic-bill/pages/list/index',
            // },
            // {
            //     id: 'member.feedback',
            //     name: '意见反馈',
            //     basePath: 'subpackages/feedback/index',
            // },
            // {
            //     id: 'service.openInvoice',
            //     name: '开具发票',
            //     basePath: '/subpackages/invoice/pages/open-invoice/index',
            // },
            {
                id: 'service.topicActivities',
                name: '创作排行',
                basePath: '/subpackages/content/pages/topic-activities-list/index',
            },
            // {
            //     id: 'service.connectWifi',
            //     name: 'Wi-Fi一键连',
            //     basePath: '/subpackages/market/connect-wifi/pages/index',
            // },
        ],
    },
];

const thirdLinkTypeEffectPathConfigList: LinkTypeEffectPathConfig[] = [
    {
        id: 'miniprogram',
        name: '它方小程序',
        basePath: 'none',
    },
    {
        id: 'urlLink',
        name: 'URL地址',
        basePath: 'subpackages/url-link-page/index',
        params: ['url', 'needAuthrization'],
    },
];

const popupLinkTypeEffectPathConfigList: LinkTypeEffectPathConfig[] = [
    {
        id: 'popupPicture',
        name: '图片弹窗',
        basePath: 'none',
    },
];

const staffNewLinkTypeEffectPathConfigList: LinkTypeEffectPathConfig[] = [
    {
        id: 'loginStorePage',
        name: '登录/注册页',
        basePath: 'subpackages/login/pages/login-choice/index',
    },
    {
        id: 'member',
        name: '会员中心',
        basePath: 'pages/member-center/index',
    },
    {
        id: 'subsite',
        name: '门店首页',
        basePath: 'pages/home/index',
        params: ['id', 'name'],
    },
    {
        id: 'newTopic',
        name: '专题活动',
        basePath: 'subpackages/topic/index',
        params: 'id',
    },
];

const merchantNewLinkTypeEffectPathConfigList: LinkTypeEffectPathConfig[] = [
    ...staffNewLinkTypeEffectPathConfigList,
    // 无目标专柜页 不需要选择对应专柜装修id需要自己处理门店id与专柜id
    {
        id: 'defaultMerchant',
        name: '专柜首页',
        basePath: 'subpackages/merchant/index',
    },
];

if (ENV.TENANT === 'hxgc' || ENV.TENANT === 'miao' || ENV.TENANT === 'localhost') {
    const serviceMenuItem = newLinkTypeEffectPathConfigList.find((it) => it.id === 'service');
    serviceMenuItem!.children!.push({
        id: 'gamePlatform',
        name: '游戏平台',
        basePath: 'subpackages/third-party/pages/game-list/index',
    });
    serviceMenuItem!.children!.push({
        id: 'anniversary',
        name: '周年庆报告',
        basePath: 'subpackages/third-party/pages/anniversary/index',
    });
    serviceMenuItem!.children!.push({
        id: 'dreamService',
        name: '梦服务',
        basePath: 'subpackages/url-link-page/index',
    });
    // const servicesMenuItem = openLinkTypeEffectPathConfigList.find((it) => it.id === 'service');
    // servicesMenuItem!.children!.push({
    //     id: 'gamePlatform',
    //     name: '游戏平台',
    //     basePath: 'subpackages/third-party/pages/game-list/index',
    // });
    // servicesMenuItem!.children!.push({
    //     id: 'anniversary',
    //     name: '周年庆报告',
    //     basePath: 'subpackages/third-party/pages/anniversary/index',
    // });
}

/**
 * 根据可视化链接类型的id获取链接类型名称的i18n多语言配置文件中的key
 * 例如开盒有礼的id为marketing.openBoxDetail，获取到的key为 linkTypes.marketing_openBoxDetail
 * 维护可视化链接类型名称的多语言配置时，必须按照此规范维护
 * @param id 可视化链接类型id
 * @returns i18n key
 */
export function getLinkTypeI18NKeyById(id: string) {
    if (typeof id !== 'string' || !id) {
        return '';
    }
    return `linkTypes.${id.split('.').join('_')}`;
}

/**
 * 根据链接类型id获取链接类型名称
 */
export function getI18NLinkNameById(id: string) {
    return services.language.getText(getLinkTypeI18NKeyById(id));
}

/**
 * 处理链接类型集合中名称支持多语言的方法
 * 使用链接类型集合时，应在运行时使用经过此方法处理返回的链接类型集合，否则多语言特性失效
 */
function mapListGetName(list: LinkTypeEffectPathConfig[]): LinkTypeEffectPathConfig[] {
    return list.map((item) => {
        const children =
            item.children && item.children.length ? mapListGetName(item.children) : undefined;
        return {
            ...item,
            name: getI18NLinkNameById(item.id),
            children,
        };
    });
}

/**
 *
 * @param list 不同场景不同的链接List集合/文本
 * @param config
 * @returns 返回可展示的链接类型
 */
export function getPathconfiglist(list: any, config: any[]) {
    const configList: LinkTypeEffectPathConfig[] = [];
    if (!list) {
        return;
    }
    let linkList;
    // 展示类型 默认是select 级联选择 text文本展示
    let displayType = 'select';
    if (isArray(list)) {
        list.forEach((item: any) => {
            let result: LinkTypeEffectPathConfig | undefined,
                menu = config;
            item.forEach((i: any, index: number) => {
                if (!menu.length) {
                    return;
                }
                const res = menu.find((c) => c.id === i);
                if (index === item.length - 1 && res) {
                    result = res;
                } else if (res?.children) {
                    const childrenRes = res.children.filter((child: any) =>
                        item.includes(child.id)
                    );
                    result = { ...res, children: childrenRes };
                }
            });
            if (result) {
                configList.push(result);
            }
        });
        linkList = mapListGetName(configList);
        displayType = 'select';
    } else {
        linkList = language.getText(list);
        displayType = 'text';
    }
    return { linkList, displayType };
}

export function getNewLinkTypeEffectPathConfigList() {
    return mapListGetName(newLinkTypeEffectPathConfigList);
}

export function getOpenLinkTypeEffectPathConfigList() {
    return mapListGetName(openLinkTypeEffectPathConfigList);
}

export function getThirdLinkTypeEffectPathConfigList() {
    return mapListGetName(thirdLinkTypeEffectPathConfigList);
}

export function getPopupLinkTypeEffectPathConfigList() {
    return mapListGetName(popupLinkTypeEffectPathConfigList);
}

export function getStaffNewLinkTypeEffectPathConfigList() {
    return mapListGetName(staffNewLinkTypeEffectPathConfigList);
}

export function getMerchantNewLinkTypeEffectPathConfigList() {
    return mapListGetName(merchantNewLinkTypeEffectPathConfigList);
}
