import React, { Component, createElement } from 'react';
import { connect } from 'react-redux';
import { find, get, isEmpty, isEqual } from 'lodash';
import { Button, Icon, message, Modal } from 'antd';
import { localStorage, uuid, api } from '@comall-backend-builder/core/lib/services';
import { ComponentsManager, services } from '@comall-backend-builder/core';

const hasStoreInfo = () => !!localStorage.get('cyStore');
const preDefinedComponentId = 'store-selector-' + uuid();

class storeSelector extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
            options: [],
            hasSubsite: hasStoreInfo(),
        };
    }

    shouldComponentUpdate(nextProps: any, nextState: any) {
        return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
    }

    componentDidMount() {
        let store: any = localStorage.get('cyStore');
        const { refresh } = this.props;
        if (store && store.id) {
            this.setState({
                hasSubsite: true,
            });
        } else {
            api.get({}, { apiPath: '/admin/subsites/mine' }).then((res: any) => {
                if (res && res.result.length > 0) {
                    localStorage.set('cyStore', { id: res.result[0].id, name: res.result[0].name });
                    this.setState(
                        {
                            hasSubsite: true,
                        },
                        () => {
                            refresh();
                        }
                    );
                }
            });
        }
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    hideModal = () => {
        this.setState({
            visible: false,
        });
    };

    confirmHandle = () => {
        const { refresh, selectedRowKeys, entity } = this.props;
        if (isEmpty(selectedRowKeys)) {
            message.warning(services.language.getText('qzsxzygmd'));
            return;
        }
        const store = find(entity.result, (option) => {
            return option.id === selectedRowKeys[0];
        });
        if (!isEmpty(store)) {
            localStorage.set('cyStore', { id: store.id, name: store.name });
        }
        this.hideModal();
        refresh();
    };

    render() {
        const { visible, hasSubsite } = this.state;
        const { entity } = this.props;
        if (entity.params && entity.params.status) {
            delete entity.params.status;
        }
        const store: any = localStorage.get('cyStore') || {};
        return (
            <div
                style={{
                    width: 300,
                    textAlign: 'right',
                    position: 'absolute',
                    right: 60,
                    top: 100,
                }}
            >
                <span>{services.language.getText('subsites')}: </span>
                <Button onClick={this.showModal}>
                    {hasSubsite && store.name
                        ? store.name
                        : services.language.getText('selectSubsite')}
                    <Icon type="down" style={{ marginLeft: 8 }} />
                </Button>
                <Modal
                    title={services.language.getText('otherSelectSubsite')}
                    className="store-selector"
                    width={800}
                    visible={visible}
                    onCancel={this.hideModal}
                    cancelButtonProps={{ disabled: true }}
                    footer={[
                        <Button key="back" onClick={this.hideModal}>
                            {services.language.getText('common.cancel')}
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.confirmHandle}>
                            {services.language.getText('common.ok')}
                        </Button>,
                    ]}
                >
                    <div>
                        {createElement(ComponentsManager.get('FilterFormPlus'), {
                            style: {
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginBottom: 8,
                            },
                            direction: 'inline',
                            entity,
                            submit: {
                                text: services.language.getText('common.search'),
                                style: { marginRight: -32 },
                            },
                            fields: [
                                {
                                    property: 'name',
                                    controlConfig: {
                                        style: { width: 300 },
                                    },
                                },
                            ],
                        })}
                        {createElement(ComponentsManager.get('DataTable'), {
                            loadFirstPage: true,
                            entity,
                            componentId: preDefinedComponentId,
                            columns: [{ property: 'name' }],
                            rowSelection: {
                                type: 'radio',
                                onChange() {},
                            },
                            pagination: {
                                pageSize: 10,
                                showSizeChanger: true,
                                showQuickJumper: true,
                                pageSizeOptions: ['10', '15', '20'],
                                showTotal(total: string) {
                                    return services.interpolate(
                                        services.language.getText('total'),
                                        {
                                            total,
                                        }
                                    );
                                },
                            },
                        })}
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    const component = state.components[preDefinedComponentId];

    return {
        selectedRowKeys: get(component, 'selectedRowKeys'),
    };
};

export const StoreSelector = connect(mapStateToProps)(storeSelector);
