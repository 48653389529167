import React, { Component } from 'react';
import { Checkbox, Button, Modal, message, Input, message as AntMessage } from 'antd';
import { DndProvider as DragDropContext } from 'react-dnd';
import update from 'immutability-helper';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ImageUpload } from '@comall-backend-builder/components-basis';
import { debounce, cloneDeep, remove, get, forEach, find } from 'lodash';
import { DragSortBox } from '../../../../components';
import { LinkTypePlus } from '../../../../applications/design/components';
import { newClientCustomSelectorConfig } from '../../../../applications/design/design';
import {
    LinkTypeEffectPathConfig,
    getNewLinkTypeEffectPathConfigList,
} from '../../../../components/link-type-effect-path/config';
import { checkLinkTypeParamsRequired } from '../../../format';
import './index.less';
import { services } from '@comall-backend-builder/core';

const { getText } = services.language;

enum ModuleType {
    /**
     * 自定义
     */
    CUSTOM = 'CUSTOM',
    /**
     * 我的订单
     */
    MYORDER = 'MYORDER',
    /**
     * 购卡
     */
    CARDPURCHASE = 'CARDPURCHASE',
    /**
     * 绑卡
     */
    BINDCARD = 'BINDCARD',
    /**
     * 转赠
     */
    TRANSFERCARD = 'TRANSFERCARD',
    /**
     * 实名认证
     */
    CERTIFICATION = 'CERTIFICATION',
    /**
     * 充值
     */
    RECHARGE = 'RECHARGE',
    /**
     * 余额查询
     */
    BALANCEQUERY = 'BALANCEQUERY',
}

interface Value {
    moduleType: ModuleType;
    name: string;
    target?: string;
    picture?: {
        id: number;
        path: string;
    };
    visible: boolean;
}
interface Props {
    value: Array<Value>;
    onChange: (value: Array<Value>) => void;
    row: any;
}

interface State {
    showModal: boolean;
    changeIndex?: number;
    changeValue?: Value;
    linkTypes: LinkTypeEffectPathConfig[];
}

const buyStoredValueCardConfig = {
    linkName: 'gk_2',
    linkPath: 'subpackages/stored-value-card/pages/buy-stored-value-card/index',
    linkType: 'member.buyStoredValueCard',
    linkParams: null,
};
const myOrderConfig = {
    linkName: 'wddd',
    linkPath: 'subpackages/my-orders/index?type=0',
    linkType: 'service.ordinaryProductOrders',
    linkParams: null,
};
const bindValueCardConfig = {
    linkName: 'bk_1',
    linkPath: 'subpackages/my-value-card/pages/bind/index',
    linkType: 'member.bindValueCard',
    linkParams: null,
};
const transferValueCardConfig = {
    linkName: 'zz_5',
    linkPath: 'subpackages/stored-value-card/pages/my-stored-value-card/index?transfer=true',
    linkType: 'member.myStoredValueCardByTransfer',
    linkParams: null,
};
const certificationConfig = {
    linkName: 'smrz',
    linkPath: 'subpackages/stored-value-card/pages/stored-value-card-certification/index',
    linkType: 'member.storedValueCardCertification',
    linkParams: null,
};
const rechargeConfig = {
    linkName: 'cz_1',
    linkPath: 'subpackages/stored-value-card/pages/stored-value-card-recharge/index',
    linkType: 'member.storedValueCardRecharge',
    linkParams: null,
};
const balanceQueryConfig = {
    linkName: 'yecx',
    linkPath: 'subpackages/stored-value-card/pages/stored-value-card-balance-query/index',
    linkType: 'member.storedValueCardBalanceQuery',
    linkParams: null,
};
const defalutValue: Array<Value> = [
    {
        moduleType: ModuleType.CARDPURCHASE,
        name: 'gk_2',
        target: JSON.stringify(buyStoredValueCardConfig),
        picture: {
            id: 0,
            path:
                'https://b-puzhehei-cdn.co-mall.net/global/stored-value-card/icon-card-purchase.png',
        },
        visible: true,
    },
    {
        moduleType: ModuleType.MYORDER,
        name: 'wddd',
        target: JSON.stringify(myOrderConfig),
        picture: {
            id: 0,
            path: 'https://b-puzhehei-cdn.co-mall.net/global/stored-value-card/icon-my-order.png',
        },
        visible: true,
    },
    {
        moduleType: ModuleType.BINDCARD,
        name: 'bk_1',
        target: JSON.stringify(bindValueCardConfig),
        picture: {
            id: 0,
            path:
                'https://b-puzhehei-cdn.co-mall.net/global/stored-value-card/icon-bind-value-card.png',
        },
        visible: true,
    },
    {
        moduleType: ModuleType.TRANSFERCARD,
        name: 'zz_5',
        target: JSON.stringify(transferValueCardConfig),
        picture: {
            id: 0,
            path:
                'https://b-puzhehei-cdn.co-mall.net/global/stored-value-card/icon-transfer-value-card.png',
        },
        visible: true,
    },
    {
        moduleType: ModuleType.CERTIFICATION,
        name: 'smrz',
        target: JSON.stringify(certificationConfig),
        picture: {
            id: 0,
            path:
                'https://b-puzhehei-cdn.co-mall.net/global/stored-value-card/icon-value-card-authentication.png',
        },
        visible: true,
    },
    {
        moduleType: ModuleType.RECHARGE,
        name: 'cz_1',
        target: JSON.stringify(rechargeConfig),
        picture: {
            id: 0,
            path:
                'https://b-puzhehei-cdn.co-mall.net/global/stored-value-card/icon-value-card-recharge.png',
        },
        visible: true,
    },
    {
        moduleType: ModuleType.BALANCEQUERY,
        name: 'yecx',
        target: JSON.stringify(balanceQueryConfig),
        picture: {
            id: 0,
            path:
                'https://b-puzhehei-cdn.co-mall.net/global/stored-value-card/icon-value-card-balance-query.png',
        },
        visible: true,
    },
];

function getDefaultValue() {
    return defalutValue.map((item) => {
        const targetObj = JSON.parse(item.target || '');
        const target = {
            ...targetObj,
            linkName: getText(targetObj.linkName),
        };
        return {
            ...item,
            name: getText(item.name),
            target: JSON.stringify(target),
        };
    });
}

/**
 * 停车页面配置功能菜单
 */
export class StoredValueCardMenus extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            showModal: false,
            linkTypes: getNewLinkTypeEffectPathConfigList(),
        };
    }

    componentDidMount() {
        const { value, onChange } = this.props;
        if (!value) {
            onChange(getDefaultValue());
        }
        setTimeout(() => {
            this.validValue();
        }, 1000);
    }

    validValue = () => {
        //编辑页面验证栏目完整性，新增的在历史编辑页也展示出来
        if (!window.location.href.includes('/edit')) {
            return;
        }
        const { value, onChange } = this.props;
        const addValues: Array<Value> = [];
        let nowLength = 0;
        forEach(getDefaultValue(), (linkType: Value) => {
            const row = find(value, {
                moduleType: linkType.moduleType,
            });
            if (row) {
                nowLength = nowLength + 1;
            } else {
                linkType.visible = false;
                addValues.push(linkType);
            }
        });
        value.splice(nowLength, 0, ...addValues);
        onChange(cloneDeep(value));
    };

    componentWillReceiveProps(nextProps: Readonly<Props>) {
        const currentSubsiteId = get(this.props, 'row.baseConfig.subsiteIds');
        const nextSubsiteId = get(nextProps, 'row.baseConfig.subsiteIds');
        if (currentSubsiteId && nextSubsiteId && currentSubsiteId !== nextSubsiteId) {
            this.onValueChange(cloneDeep(getDefaultValue()));
        }
    }

    onValueChange = (newValue: Array<Value>) => {
        const { onChange } = this.props;
        onChange(newValue);
    };

    onChangePosition = debounce((hoverItem: any, dragItem: any) => {
        const { value } = this.props;
        const newValue = update(value, {
            $splice: [
                [dragItem.index, 1],
                [hoverItem.index, 0, value[dragItem.index]],
            ],
        });
        this.onValueChange(newValue);
    }, 100);

    onItemCheck = (index: number) => {
        const { value } = this.props;
        const newValue = cloneDeep(value);
        newValue[index].visible = !newValue[index].visible;
        this.onValueChange(newValue);
    };

    onLinkValueChange = (linkValue: any) => {
        const { changeValue } = this.state;
        if (!changeValue) {
            return null;
        }
        const newChangeValue = cloneDeep(changeValue);
        newChangeValue.target = linkValue ? JSON.stringify(linkValue) : undefined;
        this.setState({
            changeValue: newChangeValue,
        });
    };

    showModal = (index: number, item: Value) => {
        this.setState({ showModal: true, changeIndex: index, changeValue: item });
    };
    closeModal = () => {
        this.setState({ showModal: false });
    };
    onModalConfirm = () => {
        const { value } = this.props;
        const { changeIndex, changeValue } = this.state;
        if (!changeValue) {
            return false;
        }
        if (!changeValue.name) {
            message.warn(getText('qsrcdmc'));
            return false;
        }
        if (!changeValue.picture) {
            message.warn(getText('qsctb'));
            return false;
        }
        if (!changeValue.target) {
            AntMessage.warning(services.language.getText('qxzljlx'));
            return false;
        }
        const linkValue = changeValue.target
            ? JSON.parse(changeValue.target)
            : {
                  linkType: '',
                  linkParams: null,
              };
        if (!linkValue.linkType) {
            AntMessage.warning(services.language.getText('qxzljlx'));
            return false;
        }
        if (
            linkValue.linkType &&
            !checkLinkTypeParamsRequired(linkValue.linkType, linkValue.linkParams)
        ) {
            AntMessage.warning(services.language.getText('selectLinkTarget'));
            return false;
        }
        if (changeIndex === undefined) {
            //新增自定义
            value.push(changeValue);
        } else {
            //编辑
            value[changeIndex] = changeValue;
        }
        this.onValueChange(cloneDeep(value));
        this.closeModal();
    };

    onPictrueChange = (picture: any) => {
        const { changeValue } = this.state;
        if (!changeValue) {
            return null;
        }
        const newChangeValue = cloneDeep(changeValue);
        newChangeValue.picture = picture && picture.length > 0 ? picture[0] : undefined;
        this.setState({
            changeValue: newChangeValue,
        });
        return {};
    };

    onNameChange = (value: string) => {
        const { changeValue } = this.state;
        if (!changeValue) {
            return null;
        }
        const newChangeValue = cloneDeep(changeValue);
        newChangeValue.name = value;
        this.setState({
            changeValue: newChangeValue,
        });
    };

    renderModal = () => {
        const { showModal, changeValue, linkTypes } = this.state;
        if (!changeValue) {
            return null;
        }
        const linkValue = changeValue.target
            ? JSON.parse(changeValue.target)
            : {
                  linkType: '',
                  linkParams: null,
              };

        const onChangeLink = (linkValue: any) => this.onLinkValueChange(linkValue);
        let imageControlInfo = {
            name: 'picture',
            value: changeValue.picture ? changeValue.picture.path : undefined,
            accept: 'image/*',
            maxCount: 1,
            multiple: false,
            fileName: 'fileName',
            uploadType: 'single',
            uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
            mode: 'picture-card',
            maxSize: 1 * 1024,
            preview: {
                size: 500,
            },
            uploadLimitSizeErrorMessage:
                services.language.getText('pictures') +
                '{{file.name}}' +
                services.language.getText('pictureUploadLimit1'),
            limitTips: {
                style: {
                    display: 'none',
                },
            },
        };
        const onChangePicture = (value: any, name: any) => {
            this.onPictrueChange(value);
            return {};
        };
        return (
            <Modal
                title={getText('szcd')}
                onCancel={this.closeModal}
                onOk={this.onModalConfirm}
                visible={showModal}
            >
                <div className="stored-value-card-menus__modal">
                    <div className="stored-value-card-menus__modal-item">
                        <div className="stored-value-card-menus__modal-item-name">
                            <span className="red">*</span>
                            {getText('cdmc')}：
                        </div>
                        <div className="stored-value-card-menus__modal-item-info">
                            <Input
                                maxLength={5}
                                placeholder={getText('cdmc')}
                                value={changeValue.name}
                                onChange={(e) => this.onNameChange(e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="stored-value-card-menus__modal-item">
                        <div className="stored-value-card-menus__modal-item-name">
                            <span className="red">*</span>
                            {getText('cdtb')}：
                        </div>
                        <div className="stored-value-card-menus__modal-item-info">
                            <div className="stored-value-card-menus__modal__img">
                                <ImageUpload {...imageControlInfo} onChange={onChangePicture} />
                                <span className="stored-value-card-menus__modal__img__tips">
                                    {getText('tpccjyw')}50px*50px
                                </span>
                            </div>
                        </div>
                    </div>
                    {changeValue.moduleType === ModuleType.CUSTOM && (
                        <div className="stored-value-card-menus__modal-item">
                            <div className="stored-value-card-menus__modal-item-name">
                                <span className="red">*</span>
                                {getText('linkType')}：
                            </div>
                            <div className="stored-value-card-menus__modal-item-info">
                                <LinkTypePlus
                                    selector={newClientCustomSelectorConfig as any}
                                    linkTypes={linkTypes}
                                    linkTypeMode="cascader"
                                    value={linkValue}
                                    onChange={onChangeLink}
                                />
                            </div>
                        </div>
                    )}
                </div>
            </Modal>
        );
    };

    delete = (index: number) => {
        const { value, onChange } = this.props;
        const result = cloneDeep(value);
        remove(result, (item: any, itemIndex: number) => itemIndex === index);
        onChange(result);
    };

    renderItem = (item: Value, index: number) => {
        const { name, visible } = item;
        return (
            <div className="stored-value-card-menus__item">
                <div className="stored-value-card-menus__item__content">
                    <Checkbox checked={visible} onChange={() => this.onItemCheck(index)} />
                    <span className="stored-value-card-menus__item__name">{name}</span>
                    <div className="stored-value-card-menus__item__setting">
                        <Button
                            onClick={this.showModal.bind(this, index, item)}
                            style={{
                                margin: 0,
                                padding: 0,
                            }}
                            type="link"
                        >
                            {getText('settings')}
                        </Button>
                        {item.moduleType === ModuleType.CUSTOM && (
                            <Button
                                onClick={this.delete.bind(this, index)}
                                style={{
                                    margin: '0 0 0 10px',
                                    padding: 0,
                                }}
                                type="link"
                            >
                                {services.language.getText('common.delete')}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    onAddService = () => {
        this.setState({
            showModal: true,
            changeIndex: undefined,
            changeValue: {
                moduleType: ModuleType.CUSTOM,
                name: '',
                target: undefined,
                picture: undefined,
                visible: true,
            },
        });
    };

    render() {
        const { value } = this.props;
        if (!value) {
            return null;
        }
        return (
            <DragDropContext backend={HTML5Backend}>
                <div className="stored-value-card-menus__desc">{getText('tzcdxkjxpx')}</div>
                <div className="stored-value-card-menus">
                    {value.map((item, index) => {
                        return (
                            <DragSortBox
                                type="CARPARK"
                                data={{ index }}
                                hover={this.onChangePosition}
                            >
                                {this.renderItem(item, index)}
                            </DragSortBox>
                        );
                    })}
                </div>
                <Button
                    type="default"
                    className="carpark-add-carpark-button"
                    onClick={() => this.onAddService()}
                >
                    {getText('zjcd')}
                </Button>
                {this.renderModal()}
            </DragDropContext>
        );
    }
}
