import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { EntityButtonProps } from '../../../components';
import { isEmpty } from 'lodash';
import { api } from '@comall-backend-builder/core/lib/services';
import { ForwardDataCenterModal } from '../../../services';

export enum DepositOrderRefundStatus {
    /**
     * 未处理
     */
    APPLYING = 'APPLYING',
    /**
     * 退款中
     */
    REFUNDING = 'REFUNDING',
    /**
     * 完成退款
     */
    REFUND_SUCC = 'REFUND_SUCC',
    /**
     * 退款失败
     */
    REFUND_FAIL = 'REFUND_FAIL',
}

export enum DepositOrderAfterSaleStatus {
    /**
     * 未处理
     */
    APPLYING = 'APPLYING',
    /**
     * 已取消
     */
    CANCEL = 'CANCEL',
    /**
     * 审核通过
     */
    APPLY_SUCC = 'APPLY_SUCC',
    /**
     * 审核不通过
     */
    APPLY_FAIL = 'APPLY_FAIL',
}

export const DEPOSIT_REFUND_STATUS_TO_TEST: any = {
    [DepositOrderRefundStatus.APPLYING]: '<<wcl>>',
    [DepositOrderRefundStatus.REFUND_SUCC]: '<<tkcg>>',
    [DepositOrderRefundStatus.REFUND_FAIL]: '<<tksb>>',
} as const;

export const DEPOSIT_REFUND_STATUS_OPTIONS = [
    {
        name: '<<all>>',
        id: 'ALL',
    },
    {
        name: DEPOSIT_REFUND_STATUS_TO_TEST[DepositOrderRefundStatus.APPLYING],
        id: DepositOrderRefundStatus.APPLYING,
    },
    {
        name: DEPOSIT_REFUND_STATUS_TO_TEST[DepositOrderRefundStatus.REFUND_SUCC],
        id: DepositOrderRefundStatus.REFUND_SUCC,
    },
    {
        name: DEPOSIT_REFUND_STATUS_TO_TEST[DepositOrderRefundStatus.REFUND_FAIL],
        id: DepositOrderRefundStatus.REFUND_FAIL,
    },
];

export const DEPOSIT_AFTRE_SALE_STATUS_TO_TEST = {
    [DepositOrderAfterSaleStatus.APPLYING]: '<<wcl>>',
    [DepositOrderAfterSaleStatus.CANCEL]: '<<yqx>>',
    [DepositOrderAfterSaleStatus.APPLY_SUCC]: '<<approved>>',
    [DepositOrderAfterSaleStatus.APPLY_FAIL]: '<<shbtg>>',
} as const;

export const DEPOSIT_AFTER_SALE_STATUS_OPTIONS = [
    {
        name: '<<all>>',
        id: 'ALL',
    },
    {
        name: DEPOSIT_AFTRE_SALE_STATUS_TO_TEST[DepositOrderAfterSaleStatus.APPLYING],
        id: DepositOrderAfterSaleStatus.APPLYING,
    },
    {
        name: DEPOSIT_AFTRE_SALE_STATUS_TO_TEST[DepositOrderAfterSaleStatus.CANCEL],
        id: DepositOrderAfterSaleStatus.CANCEL,
    },
    {
        name: DEPOSIT_AFTRE_SALE_STATUS_TO_TEST[DepositOrderAfterSaleStatus.APPLY_SUCC],
        id: DepositOrderAfterSaleStatus.APPLY_SUCC,
    },
    {
        name: DEPOSIT_AFTRE_SALE_STATUS_TO_TEST[DepositOrderAfterSaleStatus.APPLY_FAIL],
        id: DepositOrderAfterSaleStatus.APPLY_FAIL,
    },
];

export const config: Config = {
    entities: {
        DepositAfterSaleEntity: {
            apiPath: '/loader/admin/deposit_order_after_sale',
            filters: {
                orderNo: {
                    type: 'string',
                    displayName: '<<djdh>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
                serviceApplyNo: {
                    type: 'string',
                    displayName: '<<ddshdh>>',
                },
                guideName: {
                    type: 'string',
                    displayName: '<<kdr>>',
                },
                merchantBigCode: {
                    type: 'string',
                    displayName: '<<djsjm>>',
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                merchantId: {
                    type: 'string.filterOptions.autoComplete',
                    displayName: '<<merchantName>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<sqsj>>',
                },
                refundStatus: {
                    type: 'string.options.select',
                    displayName: '<<tkzt>>',
                    options: DEPOSIT_REFUND_STATUS_OPTIONS,
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 200 },
                    },
                },
                amountRange: {
                    type: 'object.amountRange',
                    displayName: '<<ddjjfw>>',
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                serviceApplyNo: {
                    type: 'string',
                    displayName: '<<afterSalesOrderNo>>',
                },
                depositOrderNo: {
                    type: 'string',
                    displayName: '<<djdh>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                orderAmount: {
                    type: 'string',
                    displayName: '<<tkje>>',
                },
                merchantTradeNo: {
                    type: 'string',
                    displayName: '<<zfptshddh>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<shzt>>',
                    options: DEPOSIT_AFTER_SALE_STATUS_OPTIONS,
                },
                createTime: {
                    type: 'number',
                    displayName: '<<sqsj>>',
                },
                applyUser: {
                    type: 'number',
                    displayName: '<<sqr>>',
                },
                auditUser: {
                    type: 'string',
                    displayName: '<<shr_1>>',
                },
                refundStatus: {
                    type: 'string.options.select',
                    displayName: '<<tkzt>>',
                    options: DEPOSIT_REFUND_STATUS_OPTIONS,
                },
                guideName: {
                    type: 'string',
                    displayName: '<<kdr>>',
                },
                merchantBigCode: {
                    type: 'string',
                    displayName: '<<djsjm>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
            },
        },
    },
    components: {
        DepositAfterSaleView: {
            component: 'Viewport',
            entity: 'DepositAfterSaleEntity',
        },
        DepositAfterSalePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'DepositAfterSaleFilter',
                },
                {
                    component: 'EntityButton',
                    props: {
                        type: 'primary',
                        children: '<<exportSearchResult>>',
                        style: {
                            width: 110,
                            marginBottom: 24,
                            marginTop: 17,
                        },
                        onClick: async (
                            _: any,
                            entityButtonProps: EntityButtonProps
                        ): Promise<void> => {
                            try {
                                const params: Record<string, any> = Object.assign(
                                    {},
                                    entityButtonProps.entity.params,
                                    entityButtonProps.entity.filters
                                );

                                //状态-解决点击tab某一项，列表点击搜索，tab状态参数却没有的情况
                                params.status = 'ALL';
                                const href = window.location.href;
                                const list = href.split('#');
                                if (list && list.length >= 3) {
                                    const statusNum = href.split('#')[2];
                                    const statusEnum: any = {
                                        [params.APPLYING]: 'APPLYING',
                                        [params.APPLY_SUCC]: 'APPLY_SUCC',
                                        [params.APPLY_FAIL]: 'APPLY_FAIL',
                                    };
                                    params.status = statusEnum[statusNum];
                                }
                                if (params.status === 'ALL') {
                                    delete params.status;
                                }

                                if (params.refundStatus === 'ALL') {
                                    delete params.refundStatus;
                                }
                                if (!isEmpty(params.subsiteId)) {
                                    params.subsiteId = params.subsiteId.id;
                                }
                                if (!isEmpty(params.merchantId)) {
                                    params.merchantId = params.merchantId.id;
                                }
                                if (!isEmpty(params.dateRange)) {
                                    params.startTime = params.dateRange.start + ' 00:00:00';
                                    params.endTime = params.dateRange.end + ' 23:59:59';
                                    delete params.dateRange;
                                }
                                if (!isEmpty(params.amountRange)) {
                                    params.startOrderAmount = params.amountRange.start
                                        ? params.amountRange.start
                                        : undefined;
                                    params.endOrderAmount = params.amountRange.end
                                        ? params.amountRange.end
                                        : undefined;
                                    delete params.amountRange;
                                }
                                const config = {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`,
                                    apiPath: '/admin/deposit_order_service_applies/export',
                                };
                                await api.get(params, config);
                                ForwardDataCenterModal();
                            } catch (err) {
                                services.errorHandle(err as any);
                            }
                        },
                    },
                },
                { component: 'DepositAfterSaleTables' },
            ],
        },
        DepositAfterSaleFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'orderNo',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'serviceApplyNo',
                },
                {
                    property: 'merchantBigCode',
                },
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'guideName',
                },
                {
                    property: 'dateRange',
                },
                {
                    property: 'refundStatus',
                },
                {
                    property: 'amountRange',
                },
            ],
        },
        DepositAfterSaleTables: {
            component: 'TabsPlus',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<all>>',
                    content: {
                        component: 'DepositAfterSaleTable',
                    },
                    params: {},
                },
                {
                    title: '<<waiting>>',
                    content: {
                        component: 'DepositAfterSaleTable',
                    },
                    params: { status: 'APPLYING' },
                },
                {
                    title: '<<approved>>',
                    content: {
                        component: 'DepositAfterSaleTable',
                    },
                    params: { status: 'APPLY_SUCC' },
                },
                {
                    title: '<<shbtg>>',
                    content: {
                        component: 'DepositAfterSaleTable',
                    },
                    params: { status: 'APPLY_FAIL' },
                },
            ],
        },
        DepositAfterSaleTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: { x: true },
                    columns: [
                        {
                            property: 'serviceApplyNo',
                        },
                        {
                            property: 'depositOrderNo',
                        },
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'merchantName',
                        },
                        {
                            property: 'guideName',
                        },
                        {
                            property: 'merchantBigCode',
                        },
                        {
                            property: 'orderAmount',
                        },
                        {
                            property: 'merchantTradeNo',
                        },
                        {
                            property: 'mobile',
                        },
                        {
                            property: 'status',
                        },
                        {
                            property: 'createTime',
                        },
                        {
                            property: 'applyUser',
                        },
                        {
                            property: 'auditUser',
                        },
                        {
                            property: 'refundStatus',
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        fixed: 'right',
                        width: 250,
                        items: [
                            {
                                type: 'component',
                                component: 'DepositAfterSaleApplyButton',
                            },
                            {
                                type: 'link',
                                text: '<<details>>',
                                path: '/deposit-after-sale/info/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        DepositAfterSaleDetailPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DepositAfterSaleDetail',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/deposit-after-sale',
                    component: 'DepositAfterSaleView',
                    breadcrumbName: '<<depositAfterSale>>',
                    privilege: {
                        path: 'depositOrderServiceApply',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/info/:id',
                            component: 'DepositAfterSaleDetailPage',
                            breadcrumbName: '<<details>>',
                        },
                        { path: '/', component: 'DepositAfterSalePage' },
                    ],
                },
            ],
        },
    ],
};
