import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { remove, get, map, isEqual } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Modal, Menu, message } from 'antd';

const WheelDrawTableId = 'PayWithGiftRewardActivitySelectorWheelDrawDataTable';
const ScratchCardTableId = 'PayWithGiftRewardActivitySelectorScratchCardDataTable';
const RedPackageRainTableId = 'PayWithGiftRewardActivitySelectorRedPackageRainDataTable';
const LotteryCodeTableId = 'PayWithGiftRewardActivitySelectorLotteryCodeDataTable';
let Entity: any = {};
let wheelDrawEntity: any = {};
let scratchCardEntity: any = {};
let redPackageRainEntity: any = {};
let lotteryCodeEntity: any = {};

export enum SelectType {
    WheelDraw = 'WHEEL_DRAW',
    ScratchCard = 'SCRATCH_CARD',
    RedPackageRain = 'RED_PACKAGE_RAIN',
    LotteryCode = 'LOTTERY_CODE',
}
export interface Coupon {
    id: string;
    // type: string;
    name: number;
    startTime: string;
    endTime: string;
    quantity?: number;
    /**
     * 被选中的优惠券
     */
    select?: boolean;
}
/**
 * 选择优惠券，礼包组件
 */
class payWithGiftRewardActivitySelector extends PureComponent<
    {
        onRef: any;
        rewardIndex: number;
        selectValues: any[]; //选中数据
        onChange: (values: any[], type: string) => void;
        params?: any; //表格筛选参数
        buttonText?: string;
        requestStatus: string;
        rowSelectionType?: string;
        verifyTip?: string;
    },
    any
> {
    dispatch: any = {};
    selectedRows: any[] = [];

    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('WheelDrawEntity');
        wheelDrawEntity = new Entity({});
        Entity = EntitiesManager.get('ScratchCardEntity');
        scratchCardEntity = new Entity({});
        Entity = EntitiesManager.get('redPackageRainEntity');
        redPackageRainEntity = new Entity({});
        Entity = EntitiesManager.get('LotteryCodeEntity');
        lotteryCodeEntity = new Entity({});
        this.state = {
            visible: false,
            selectType: SelectType.WheelDraw,
        };
    }

    componentDidMount() {
        this.props.onRef(this);
    }
    componentWillReceiveProps(nextProps: any) {
        //如果数据发生变化，则清空数据
        const nextType = nextProps.selectValues;
        const currentDisplayType = this.props.selectValues;
        if (nextProps.rewardIndex !== this.props.rewardIndex) {
            this.onClearAll();
        } else if (!isEqual(nextType, currentDisplayType)) {
            this.onClearAll();
        }
    }

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(wheelDrawEntity));
        this.dispatch(actions.unmountEntityAction(scratchCardEntity));
        this.dispatch(actions.unmountEntityAction(redPackageRainEntity));
        this.dispatch(actions.unmountEntityAction(lotteryCodeEntity));
    }
    onCouponTableRowSelect = (record: any, selected: boolean, rowSelectionType: string) => {
        if (rowSelectionType === 'radio') {
            this.selectedRows = [record];
        } else {
            if (selected) {
                this.selectedRows.push(record);
            } else {
                remove(this.selectedRows, (i) => {
                    return i.id === record.id;
                });
            }
        }
    };

    onCouponTableRowSelectAll = (selected: boolean, rows: any[]) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            this.selectedRows = [];
        }
    };

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(WheelDrawTableId, []));
        this.dispatch(actions.tableRowSelectionChangeAction(ScratchCardTableId, []));
        this.dispatch(actions.tableRowSelectionChangeAction(RedPackageRainTableId, []));
        this.dispatch(actions.tableRowSelectionChangeAction(LotteryCodeTableId, []));
    };

    onOk = () => {
        const { onChange } = this.props;
        const { selectType } = this.state;
        if (onChange) {
            onChange(this.selectedRows, selectType);
            this.toggleModal();
        }
    };

    getConfig = (type: SelectType) => {
        const { params, selectValues } = this.props;
        let entity = wheelDrawEntity;
        if (type === SelectType.ScratchCard) {
            entity = scratchCardEntity;
        } else if (type === SelectType.RedPackageRain) {
            entity = redPackageRainEntity;
        } else if (type === SelectType.LotteryCode) {
            entity = lotteryCodeEntity;
        }
        let columns: any[] = [];
        columns = [
            {
                property: 'name',
                displayConfig: {
                    className: 'ant-table-cell-ellipsis',
                    style: {
                        maxWidth: '200px',
                    },
                },
            },
            {
                property: 'startTime',
            },
            {
                property: 'endTime',
            },
            {
                property: 'executeStatus',
                displayConfig: {
                    className: 'ant-table-cell-ellipsis',
                    style: {
                        maxWidth: '100px',
                    },
                    statusConfig: {
                        NOT_STARTED: {
                            text: '未开始',
                            status: 'success',
                        },
                        STARTED: {
                            text: '进行中',
                            status: 'processing',
                        },
                        COMPLETE: {
                            text: '已结束',
                            status: 'default',
                        },
                    },
                },
            },
        ];
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: params || {},
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'right' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        entity.pageChange(Object.assign({}, entity.paging, { page: 1 }));
                        entity.search(params);
                    },
                    fields: [
                        {
                            property: 'name',
                        },
                        {
                            property: 'executeStatus',
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: WheelDrawTableId,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    rowSelection: {
                        type: 'radio',
                        selectedRowKeys: map(selectValues, 'id'),
                        onSelect: (record: Coupon, selected: boolean) => {
                            this.onCouponTableRowSelect(record, selected, 'radio');
                        },
                        onSelectAll: (selected: boolean, rows: Coupon[]) => {
                            this.onCouponTableRowSelectAll(selected, rows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    scroll: { x: true },
                    columns: columns,
                },
            ],
        };
    };

    setDisplayType = () => {
        const { selectValues } = this.props;
        let displayType = SelectType.WheelDraw;
        if (selectValues.length && selectValues[0].type) {
            displayType = selectValues[0].type;
        }
        return displayType;
    };

    toggleModal = () => {
        let entity = wheelDrawEntity;
        if (this.setDisplayType() !== SelectType.WheelDraw) {
            const type = this.setDisplayType();
            this.setState({
                selectType: type,
            });
        }
        if (this.checkSubsiteOrMerchantExist()) {
            const { params } = this.props;
            const { visible } = this.state;

            this.setState({ visible: !visible }, () => {
                if (!visible && entity.paging) {
                    entity.search({
                        ...entity.paging,
                        ...params,
                    });
                    this.onClearAll();
                }
            });
        }
    };

    onSelectKey = (key: string) => {
        if (this.state.selectType !== key) {
            this.setState({
                selectType: key,
            });
            this.onClearAll();
        }
    };

    render() {
        const wheelDrawConfig = this.getConfig(SelectType.WheelDraw);
        const wheelDrawContent = createElement(
            ComponentsManager.get(wheelDrawConfig.component),
            wheelDrawConfig
        );

        const scratchCardConfig = this.getConfig(SelectType.ScratchCard);
        const scratchCardContent = createElement(
            ComponentsManager.get(scratchCardConfig.component),
            scratchCardConfig
        );

        const redPackageRainConfig = this.getConfig(SelectType.RedPackageRain);
        const redPackageRainContent = createElement(
            ComponentsManager.get(redPackageRainConfig.component),
            redPackageRainConfig
        );

        const lotteryCodeConfig = this.getConfig(SelectType.LotteryCode);
        const lotteryCodeContent = createElement(
            ComponentsManager.get(lotteryCodeConfig.component),
            lotteryCodeConfig
        );

        const modalProps = {
            width: 1200,
            title: '选择抽奖活动',
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };

        const { selectType } = this.state;
        return (
            <div style={{ display: 'inline-block' }}>
                <Modal {...modalProps}>
                    <div style={{ display: 'flex' }}>
                        <Menu
                            selectedKeys={[selectType]}
                            onClick={({ key }) => {
                                this.onSelectKey(key);
                            }}
                        >
                            <Menu.Item key={SelectType.WheelDraw}>
                                {services.language.getText('dzp')}
                            </Menu.Item>
                            <Menu.Item key={SelectType.ScratchCard}>刮刮卡</Menu.Item>
                            <Menu.Item key={SelectType.RedPackageRain}>
                                {services.language.getText('hby')}
                            </Menu.Item>
                            <Menu.Item key={SelectType.LotteryCode}>抽奖码</Menu.Item>
                        </Menu>
                        <div style={{ padding: '0 20px', width: '100%' }}>
                            {selectType === SelectType.WheelDraw && wheelDrawContent}
                            {selectType === SelectType.ScratchCard && scratchCardContent}
                            {selectType === SelectType.RedPackageRain && redPackageRainContent}
                            {selectType === SelectType.LotteryCode && lotteryCodeContent}
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    private checkSubsiteOrMerchantExist = () => {
        const { params, verifyTip = '请先选择门店' } = this.props;
        const { subsiteIds } = params;
        if (!subsiteIds || subsiteIds.length === 0) {
            message.warning(verifyTip);
            return false;
        }
        return true;
    };
}

export const PayWithGiftRewardActivitySelector = connect((_state: any) => {
    let requestStatus = get(wheelDrawEntity, 'requestStatus', null);
    const result = { requestStatus };
    return result;
})(payWithGiftRewardActivitySelector);
