import React, { Component } from 'react';
import { Table, InputNumber, Button } from 'antd';
import { MemberLevelRewardInfos } from './affiliate-marketing-member-level';
import { cloneDeep } from 'lodash';
import { services } from '@comall-backend-builder/core';

interface AffiliateMarketingMemberLevelTableProps {
    onChange: (value: any) => void;
    data: MemberLevelRewardInfos[] | undefined;
    loading?: boolean;
    enableExperienceValue?: boolean;
    rewardValueTitle?: string;
}

export default class AffiliateMarketingMemberLevelTable extends Component<
    AffiliateMarketingMemberLevelTableProps,
    {}
> {
    changeValue = (
        record: MemberLevelRewardInfos,
        value: string,
        field: 'rewardValue' | 'experienceValue'
    ) => {
        const { data, onChange } = this.props;
        if (!data) return;
        const newData = cloneDeep(data);
        newData.forEach((r) => {
            if (r.cardLevelId === record.cardLevelId) {
                r[field] = value;
            }
        });
        onChange && onChange(newData);
    };

    resetPayValue = (index: number) => {
        const { data, onChange } = this.props;
        if (!data) {
            return;
        }
        const list = cloneDeep(data);
        if (list) {
            const record: MemberLevelRewardInfos = list[index];
            list.splice(index, 1, {
                ...record,
                rewardValue: '',
                experienceValue: '',
            });
            onChange(list);
        }
    };

    getTableProps = () => {
        const { data, loading = false } = this.props;
        const resetPayValue = this.resetPayValue;
        return {
            dataSource: data,
            pagination: false as const,
            loading,
            columns: [
                {
                    title: services.language.getText('hydj'),
                    dataIndex: 'cardLevelName',
                    key: 'cardLevelName',
                    render: (value: any) => {
                        return (
                            <span>
                                <span style={{ color: 'red', marginRight: '5px' }}>*</span>
                                {value}
                            </span>
                        );
                    },
                },
                {
                    title: services.language.getText('cshdcjcs'),
                    dataIndex: 'rewardValue',
                    key: 'rewardValue',
                    render: (rewardValue: any, record: any) => {
                        return (
                            <div>
                                <InputNumber
                                    placeholder={services.language.getText('inputPlease')}
                                    value={rewardValue}
                                    min={0}
                                    precision={0}
                                    max={999999}
                                    onChange={(value: any) =>
                                        this.changeValue(record, value, 'rewardValue')
                                    }
                                ></InputNumber>
                                &nbsp;{services.language.getText('frequency')}
                            </div>
                        );
                    },
                },
                {
                    title: services.language.getText('mtewhdcjcs'),
                    dataIndex: 'experienceValue',
                    key: 'experienceValue',
                    render: (experienceValue: any, record: any) => {
                        return (
                            <div>
                                <InputNumber
                                    placeholder={services.language.getText('inputPlease')}
                                    value={experienceValue}
                                    min={0}
                                    precision={0}
                                    max={999999}
                                    onChange={(value: any) =>
                                        this.changeValue(record, value, 'experienceValue')
                                    }
                                ></InputNumber>
                                &nbsp;{services.language.getText('frequency')}
                            </div>
                        );
                    },
                },
                {
                    title: services.language.getText('common.tableAction'),
                    key: 'action',
                    render(value: any, record: any, index: number) {
                        return (
                            <Button type="link" onClick={resetPayValue.bind(this, index)}>
                                {services.language.getText('common.reset')}
                            </Button>
                        );
                    },
                },
            ],
        };
    };

    render() {
        const tableProps = this.getTableProps();
        return <Table {...tableProps}></Table>;
    }
}
