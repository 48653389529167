import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { MerchantTagStatus } from '../config/merchant/merchant-manager/merchant-tag';
const api = services.api;
export const MerchantFacilityTagLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/facility_tag';
        const { id } = data;
        if (!id) {
            if (data.status === MerchantTagStatus.ALL) {
                delete data.status;
            }
            const subsiteName = data.subsiteName;
            if (subsiteName) {
                data.subsiteId = subsiteName.id ? subsiteName.id : subsiteName;
            }
            delete data.subsiteName;
        }

        return await api.get(data, config).then((res: any) => {
            if (!id) {
                res.result = res.result.map((item: any) => {
                    item.subsiteList.map((innerItem: { id: string }) => {
                        innerItem.id = String(innerItem.id);
                        return innerItem;
                    });
                    item.subsites = item.subsiteList;
                    let status = '';
                    if (item.status === MerchantTagStatus.ENABLE) {
                        status = MerchantTagStatus.ENABLE;
                    } else {
                        status = MerchantTagStatus.DISABLE;
                    }
                    return { ...item, status };
                });
            }
            return res;
        });
    },
};
