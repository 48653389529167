import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const BlackListLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/blacklist';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/blacklist/${data.id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                const { memberid, memberName, regionId, regionName, parentRegionIds } = res;
                res.member = [{ id: memberid, loginName: memberName }];
                res.region = {
                    id: regionId,
                    name: regionName,
                    parentIds: parentRegionIds,
                };
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        const newParams = dataParser(params);
        return api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id, ...otheParams } = params;
        config.apiPath = config.apiPath.replace(/:id/g, id);
        const newParams = dataParser(otheParams);
        return api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    const { member, region, ...otherParams } = params;
    let memberid, memberName, regionId, regionName;
    if (member && member.length) {
        memberid = member[0].id;
        memberName = member[0].loginName;
    }
    if (region) {
        regionId = region.id;
        regionName = region.name;
    }
    const newParams = {
        ...otherParams,
        memberid,
        memberName,
        regionId,
        regionName,
    };
    return newParams;
}
