import React, { PureComponent } from 'react';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import remove from 'lodash/remove';
import { services } from '@comall-backend-builder/core';
import { Button, Modal, Form, Input, Table } from 'antd';
import { message as AntMessage } from 'antd';
import PropTypes from 'prop-types';

export interface GoodsActivity {
    saleRuleGoodsId: any;
    name: string;
    saleRuleGoodsName: string;
    sequence?: number;
    quantity?: number;
    subsite?: any;
    status: boolean;
    select?: boolean;
    params?: any;
    id?: string;
    /**
     * 门店id
     */
    subSiteId?: string;
}

const CollectionCreateForm = Form.create({ name: 'searchForm' })(
    class extends React.Component {
        static propTypes: {
            onSearch: PropTypes.Requireable<(...args: any[]) => any>;
        };
        handleReset = () => {
            const { form } = this.props as any;
            form.resetFields();
        };
        handleSearch = (e: any) => {
            e.preventDefault();
            const { form, onSearch } = this.props as any;
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSearch(values);
                }
            });
        };
        render() {
            const { form } = this.props as any;
            const { getFieldDecorator } = form;
            return (
                <Form layout="inline" ref="searchFrom" style={{ textAlign: 'right' }}>
                    <Form.Item label="标签名称">
                        {getFieldDecorator('tagName')(<Input placeholder="输入标签名称" />)}
                    </Form.Item>
                    <Form.Item label="商品名称">
                        {getFieldDecorator('saleRuleGoodsName')(
                            <Input placeholder="输入商品名称" />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.handleSearch}>
                            查询
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="reset" onClick={this.handleReset}>
                            重置
                        </Button>
                    </Form.Item>
                </Form>
            );
        }
    }
);

export class GoodsSelector extends PureComponent<any> {
    state = {
        visible: false,
        datasource: [],
        searchParams: {},
        total: 0,
        pagination: {
            page: 1,
            perPage: 10,
        },
        selectedRows: new Array<GoodsActivity>(),
    };
    toggleModal = () => {
        const { visible } = this.state;
        const { selectValues } = this.props;
        const { subsiteId } = this.props.params;

        if (!subsiteId) {
            AntMessage.error('请选择门店');
            return;
        } else {
            this.setState({ visible: !visible }, () => {
                if (!visible) {
                    this.onSearch();
                    if (selectValues && selectValues.length) {
                        this.setState({ selectedRows: selectValues });
                    } else {
                        this.setState({ selectedRows: [] });
                    }
                }
            });
        }
    };

    onSearch = async (searchParams: object = {}) => {
        this.setState({ searchParams: searchParams });
        const { pagination } = this.state;
        try {
            let res: any = await services.api.get(
                { ...searchParams, ...pagination, ...this.props.params },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                    apiPath: '/admin/credit_eshop_limit/goods',
                }
            );
            this.setState({ datasource: res.result, total: res.totalNum });
        } catch (error) {
            throw error;
        }
    };

    pageChange = (pageVal: object = {}) => {
        const { searchParams } = this.state;
        this.setState({ pagination: { ...pageVal } }, () => {
            this.onSearch(searchParams);
        });
    };
    onTableRowSelect = (record: GoodsActivity, selected: boolean) => {
        const { selectedRows } = this.state;
        if (selected) {
            const rows = [...selectedRows];
            rows.push(record);
            this.setState({ selectedRows: rows });
        } else {
            const rows = [...selectedRows];
            remove(rows, (i) => {
                return i.saleRuleGoodsId === record.saleRuleGoodsId;
            });
            this.setState({ selectedRows: rows });
        }
    };
    onTableRowSelectAll = (selected: boolean, rows: GoodsActivity[]) => {
        const { selectedRows, datasource } = this.state;
        const selectedRowIds = map(selectedRows, 'saleRuleGoodsId');
        if (selected) {
            const addRows = rows.filter((item: GoodsActivity) => {
                return !selectedRowIds.includes(item.saleRuleGoodsId);
            });
            this.setState({ selectedRows: [...selectedRows, ...addRows] });
        } else {
            const deleteIds: any = map(datasource, 'saleRuleGoodsId');
            const deleteRows = selectedRows.filter((item: GoodsActivity) => {
                return !deleteIds.includes(item.saleRuleGoodsId);
            });
            this.setState({ selectedRows: [...deleteRows] });
        }
    };
    onOk = () => {
        const { onChange } = this.props;
        const { selectedRows } = this.state;

        if (onChange) {
            forEach(selectedRows, (subsite) => {
                subsite.select = true;
            });

            onChange(selectedRows);
            this.toggleModal();
        }
    };

    render() {
        const { visible, datasource, pagination, total, selectedRows } = this.state;

        return (
            <>
                <Button type="primary" onClick={this.toggleModal}>
                    ＋ 选择商品
                </Button>
                <Modal
                    title="请选择商品"
                    visible={visible}
                    onOk={this.onOk}
                    okText={services.language.getText('common.ok')}
                    cancelText={services.language.getText('common.cancel')}
                    onCancel={this.toggleModal}
                    width={900}
                >
                    <div className="clerk-search">
                        <CollectionCreateForm onSearch={this.onSearch} />
                    </div>
                    <Table
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys: map(selectedRows, 'saleRuleGoodsId'),
                            onSelect: (
                                record: GoodsActivity,
                                selected: boolean,
                                selectedRow: any
                            ) => {
                                this.onTableRowSelect(record, selected);
                            },
                            onSelectAll: (selected: boolean, rows: GoodsActivity[]) => {
                                this.onTableRowSelectAll(selected, rows);
                            },
                        }}
                        rowKey={(record: any) => record.saleRuleGoodsId}
                        pagination={{
                            pageSize: pagination.perPage,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            pageSizeOptions: ['10', '15', '20'],
                            total,
                            showTotal(total: number) {
                                return `共 ${total} 条记录`;
                            },
                            onChange: (page, perPage) => {
                                this.pageChange({ page, perPage });
                            },
                            onShowSizeChange: (page, perPage) => {
                                this.pageChange({ page, perPage });
                            },
                        }}
                        columns={[
                            {
                                title: services.language.getText('components.Products.name'),
                                dataIndex: 'saleRuleGoodsName',
                                key: 'saleRuleGoodsName',
                            },
                            {
                                title: '标签名称',
                                dataIndex: 'tagName',
                                key: 'tagName',
                            },
                            {
                                title: services.language.getText('subsiteName'),
                                key: 'subsite.name',
                                dataIndex: 'subsite.name',
                            },
                        ]}
                        dataSource={datasource}
                    />
                </Modal>
            </>
        );
    }
}
