import React, { PureComponent } from 'react';
import { Table, Button, InputNumber, Select, Switch } from 'antd';
import { cloneDeep, remove, find, forEach } from 'lodash';
import { SendOutChennel } from './send-out-channel-select';
import { services } from '@comall-backend-builder/core';
interface SendOutChannelSelectDisplayProps {
    data: Array<SendOutChennel>;
    onChange: (data: Array<SendOutChennel>) => void;
}
interface SendOutChannelSelectDisplayState {
    selectedRows: Array<SendOutChennel>;
}
const { Option } = Select;
/**
 * 渠道选择并且展示选择内容
 */
export class SendOutChannelSelectDisplay extends PureComponent<
    SendOutChannelSelectDisplayProps,
    SendOutChannelSelectDisplayState
> {
    onChangeStockModel = (e: any, record: any) => {
        const { data, onChange } = this.props;
        let newData = cloneDeep(data);
        let row = find(newData, { channelId: record.channelId });
        if (row) {
            row.stockModel = e;
            if (e === 'SHARE') {
                row.maxCouponQuantity = '';
            }
            onChange(newData);
        }
    };

    onChangeStatus = (e: any, record: any) => {
        const { data, onChange } = this.props;
        let newData = cloneDeep(data);
        let row = find(newData, { channelId: record.channelId });
        if (row) {
            row.status = e;
            onChange(newData);
        }
    };

    onChangeMaxCouponQuantity = (value: any, record: any) => {
        const { data, onChange } = this.props;
        let newData = cloneDeep(data);
        let row = find(newData, { channelId: record.channelId });
        if (row) {
            row.maxCouponQuantity = value;
            onChange(newData);
        }
    };

    getTableConfig = () => {
        let columns: any[] = [];
        columns = [
            {
                title: services.language.getText('qdmc'),
                dataIndex: 'channelName',
                key: 'channelName',
            },
            {
                title: services.language.getText('qdkclx'),
                dataIndex: 'stockModel',
                key: 'stockModel',
                render: (value: number, row: any) => {
                    return (
                        <Select
                            value={value + ''}
                            style={{ width: '100px' }}
                            onChange={(e: any) => this.onChangeStockModel(e, row)}
                            disabled={row.id ? true : false}
                        >
                            <Option value="">{services.language.getText('selectPlease')}</Option>
                            <Option value="SHARE">
                                {services.language.getText('commonShare')}
                            </Option>
                            <Option value="INDEPENDENT">
                                {services.language.getText('singleShare')}
                            </Option>
                        </Select>
                    );
                },
            },
            {
                title: services.language.getText('qdkc'),
                dataIndex: 'maxCouponQuantity',
                key: 'maxCouponQuantity',
                render: (value: number, row: any) => {
                    return (
                        <div>
                            <InputNumber
                                min={row.stockModel === 'SHARE' ? undefined : 1}
                                max={9999999999}
                                step={1}
                                precision={0}
                                value={row.stockModel === 'SHARE' ? undefined : value}
                                onChange={(value) => this.onChangeMaxCouponQuantity(value, row)}
                                disabled={row.stockModel === 'SHARE' ? true : false}
                                placeholder={
                                    row.stockModel === 'SHARE'
                                        ? ''
                                        : services.language.getText('qsrzs')
                                }
                                style={{
                                    width: '135px',
                                }}
                            ></InputNumber>
                        </div>
                    );
                },
            },
        ];
        //编辑页面，如果有添加时已经有的则展示该字端，没有则不需要展示，操作和新增UI一致
        let showRemainingCouponQuantity = false;
        const { data } = this.props;
        if (data && data.length > 0) {
            forEach(data, (i: any) => {
                if (i.id) {
                    showRemainingCouponQuantity = true;
                }
            });
        }
        if (showRemainingCouponQuantity) {
            columns.push({
                title: services.language.getText('qdsykc'),
                dataIndex: 'remainingCouponQuantity',
                key: 'remainingCouponQuantity',
            });
        }
        columns.push(
            {
                title: services.language.getText('qdffzt'),
                dataIndex: 'status',
                key: 'status',
                render: (value: boolean, row: any) => {
                    return (
                        <Switch
                            checkedChildren={services.language.getText('kai')}
                            unCheckedChildren={services.language.getText('guan')}
                            onChange={(e: any) => this.onChangeStatus(e, row)}
                            checked={value}
                        />
                    );
                },
            },
            {
                title: services.language.getText('common.tableAction'),
                key: 'operation',
                width: 100,
                render: (i: any, row: any) => {
                    return (
                        <Button
                            type={'link'}
                            onClick={this.onRemove(row)}
                            style={{ padding: '0' }}
                            disabled={row.id ? true : false}
                        >
                            {services.language.getText('common.delete')}
                        </Button>
                    );
                },
            }
        );

        let tableConfig: any = {
            columns: columns,
            scroll: {
                y: 400,
            },
            style: {
                marginTop: 10,
            },
        };
        return tableConfig;
    };

    onRemove = (row: SendOutChennel) => {
        const { data, onChange } = this.props;

        return () => {
            const result = cloneDeep(data);
            remove(result, (channel) => row.channelId === channel.channelId);
            onChange(result);
        };
    };

    render() {
        const { data } = this.props;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: data,
            pagination: false,
        };
        return (
            <div>
                <Table {...tableProps}></Table>
            </div>
        );
    }
}
