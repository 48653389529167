import React, { PureComponent } from 'react';
import { Input, Button, message as AntMessage, Spin } from 'antd';
import AMapLoader from '@amap/amap-jsapi-loader';
import { services } from '@comall-backend-builder/core';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import { isNil } from 'lodash';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import './index.less';

const enum MapSystem {
    /**
     * 高德
     */
    AMAP = 'AMAP',
    /**
     * 谷歌
     */
    GOOGLE = 'GOOGLE',
}
interface ScalSelectProps {
    onChange: (data: any) => void;
    /**
     * 当前值
     */
    value: any;
    row: any;
    /**
     * 坐标查询-按钮
     * 默认展示
     */
    showBtn: any;
}
const { api } = services;
const pfix = 'scal-select';

interface ScalSelectStates {
    /**
     * 地图类型
     */
    system: MapSystem;
    /**
     * 地图key
     */
    mapKey: string;
    /**
     * 高德当前地图实例
     */
    map: any;
    /**
     * 高德地图api
     */
    AMap: any;
    /**
     * 位置标记
     */
    marker: any;
    /**
     * 当前选择位置经纬度
     */
    centerPosition: any[];
    loading: boolean;
}

export class ScalSelect extends PureComponent<ScalSelectProps, ScalSelectStates> {
    constructor(props: any) {
        super(props);
        this.state = {
            system: MapSystem.AMAP,
            mapKey: '',
            map: undefined,
            AMap: undefined,
            marker: undefined,
            centerPosition: [],
            loading: false,
        };
    }

    componentDidMount() {
        this.initMap();
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.value) {
            this.setState({
                centerPosition: nextProps.value,
            });
        }
    }
    componentWillUnmount() {
        this.setState = () => {
            return;
        };
    }
    initMap = async () => {
        const result: any = await api.get({}, { apiPath: '/admin/amap/config' });
        if (result) {
            this.setState(
                {
                    system: result.system,
                    mapKey: result.key,
                },
                () => {
                    //高德
                    if (result.system === MapSystem.AMAP) {
                        const { map, AMap } = this.state;
                        if (map && AMap) {
                            this.changePosition();
                        } else {
                            this.setState({ loading: true });
                            AMapLoader.load({
                                key: result.key, // 申请好的Web端开发者Key，首次调用 load 时必填
                                version: '1.4.15', // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                                plugins: ['AMap.Marker', 'AMap.ToolBar', 'AMap.IndoorMap'], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
                            })
                                .then((AMap: any) => {
                                    let map = new AMap.Map('mapContainer', {
                                        zoom: 13,
                                    });
                                    map.addControl(new AMap.ToolBar());
                                    map.on('click', this.showInfoClick);
                                    this.setState({ AMap, map, loading: false });
                                    map.on('complete', this.mapEnd);
                                })
                                .catch((e: any) => {
                                    console.log(e);
                                });
                        }
                    }
                    //谷歌
                    if (result.system === MapSystem.GOOGLE) {
                        const { value, onChange } = this.props;
                        if (!value) {
                            //默认北京
                            const centerPosition = [116.397228, 39.908815];
                            this.setState({
                                centerPosition,
                            });
                            onChange(centerPosition);
                        }
                    }
                }
            );
        }
    };

    //高德地图点击方法
    showInfoClick = (e: any) => {
        const lat = e.lnglat.lat;
        const lng = e.lnglat.lng;
        this.setState(
            {
                centerPosition: [lng, lat],
            },
            () => {
                this.changePosition();
            }
        );
    };
    //高德地图
    mapEnd = () => {
        const { centerPosition } = this.state;
        if (centerPosition.length) {
            this.changePosition();
        }
    };
    //高德地图
    changePosition = () => {
        const { AMap, map, centerPosition, marker } = this.state;
        //移除所有覆盖物
        if (marker) {
            map.remove(marker);
        }
        // 创建一个 Marker 实例：
        let newMarker = new AMap.Marker({
            icon: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png', // 添加 Icon 实例
            zoom: 18,
            position: centerPosition,
        });
        // 将创建的点标记添加到已有的地图实例：
        map.add(newMarker);
        // 缩放地图到合适的视野级别
        //  map.setFitView(newMarker);
        //地图中心点平移至指定点位置
        map.panTo(centerPosition);
        this.props.onChange(centerPosition);
        this.setState({
            AMap,
            map,
            marker: newMarker,
        });
    };
    //搜索方法
    seachNautica = () => {
        const { row } = this.props;
        const { region, address } = row;

        if (!region) {
            return AntMessage.error(services.language.getText('qxzmdqy'));
        }
        if (!address) {
            return AntMessage.error(services.language.getText('qsrmddz'));
        }
        const addressLast = region.name + address;
        api.get({ address: addressLast }, { apiPath: '/admin/amap/get_geo' })
            .then((res: any) => {
                if (res) {
                    if (!res.longitude || !res.latitude) {
                        AntMessage.error(services.language.getText('qsrzqdz'));
                        return;
                    }
                    this.setState(
                        {
                            centerPosition: [res.longitude, res.latitude],
                        },
                        () => {
                            if (this.state.system === MapSystem.AMAP) {
                                this.changePosition();
                            }
                        }
                    );
                } else {
                    AntMessage.error(services.language.getText('dtmrdycsccsx'));
                }
            })
            .catch(errorHandle);
    };

    //谷歌地图点击方法
    handleGoogleClick = (event: any) => {
        const { onChange } = this.props;
        if (event && event.latLng) {
            const centerPosition = [event.latLng.lng().toFixed(6), event.latLng.lat().toFixed(6)];
            this.setState({
                centerPosition,
            });
            onChange(centerPosition);
        }
    };

    //渲染谷歌地图
    renderGoogle = () => {
        const { mapKey, centerPosition } = this.state;
        if (!mapKey || !centerPosition || centerPosition.length !== 2) {
            return null;
        }
        const lng = Number(centerPosition[0]);
        const lat = Number(centerPosition[1]);
        return (
            <div style={{ height: '400px', width: '100%' }}>
                <LoadScript googleMapsApiKey="AIzaSyCxZQYRcF94b7z8Jg_Vv5q4Elb9JZEel8o">
                    <GoogleMap
                        mapContainerStyle={{ width: '100%', height: '400px' }}
                        zoom={11}
                        center={{ lat, lng }}
                        onClick={this.handleGoogleClick}
                    >
                        <Marker position={{ lat, lng }} />
                    </GoogleMap>
                </LoadScript>
            </div>
        );
    };

    //渲染高德地图
    renderAMap = () => {
        const innerName = {
            wrap: `${pfix}--wrap`,
        };
        return (
            <div className={innerName.wrap}>
                <div className="map-container" id="mapContainer"></div>
            </div>
        );
    };
    render() {
        const { showBtn } = this.props;
        const { loading, centerPosition, system } = this.state;
        const longitudeAndLatitude = centerPosition && centerPosition.join(',');
        const innerName = {
            box: `${pfix}`,
            searchBox: `${pfix}--search_box`,
            searchBtn: `${pfix}--search_box_btn`,
        };
        const isShow = isNil(showBtn) ? true : !!showBtn;
        return (
            <div className={innerName.box}>
                <Spin spinning={loading}>
                    <div className={innerName.searchBox}>
                        {isShow && (
                            <Button
                                type="primary"
                                className={innerName.searchBtn}
                                onClick={this.seachNautica}
                            >
                                {services.language.getText('zbcx')}
                            </Button>
                        )}
                        <Input
                            disabled={true}
                            value={longitudeAndLatitude}
                            placeholder={services.language.getText('qxzzb')}
                        />
                    </div>
                    {system === MapSystem.AMAP && this.renderAMap()}
                    {system === MapSystem.GOOGLE && this.renderGoogle()}
                </Spin>
            </div>
        );
    }
}
