import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { SelectCoupon } from '../../../containers';

export class CouponSelectorMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <SelectCoupon {...displayInfo} />;
    }
}
