import { PureComponent, createElement } from 'react';
import { ComponentsManager } from '@comall-backend-builder/core';
export class couponSaleActivityListStatusButton extends PureComponent<any, any> {
    render() {
        const { row } = this.props;
        let closeText = '是否关闭' + row.name;
        if (row.executeStatus === 'STARTED') {
            closeText = row.name + '活动关闭后，用户将无法购买优惠券，请谨慎操作';
        }
        let buttonProps = {
            ...this.props,
            statusKey: 'status',
            renderComponent: 'Button',
            type: 'link',
            style: { display: 'inline-flex' },
            handles: [
                {
                    params: { status: false },
                    value: true,
                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                    apiPath: '/admin/sale_rules/:id/status',
                    config: { content: '关闭', text: '关闭', style: { color: '#f5222d' } },
                    confirm: {
                        text: closeText,
                    },
                },
                {
                    params: { status: true },
                    value: false,
                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                    apiPath: '/admin/sale_rules/:id/status',
                    config: { content: '开启', text: '开启' },
                    confirm: {
                        text: '是否开启{{row.name}}?',
                    },
                },
            ],
        };
        return createElement(ComponentsManager.get('ChangeStatusButton'), buttonProps);
    }
}
