import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { remove, map, find, get } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Modal } from 'antd';

interface MerchantsSelectorItem {
    id: string;
    name: string;
    status: string;
    type: string;
}
const TableComponentId = 'MerchantsSelectorTableBySubsites';
let TableComponentNo = 1;
let Entity: any = {};
let entity: any = {};
/**
 * 选择分站
 */
class merchantsSelector extends PureComponent<any> {
    tableComponentId: string = '';
    dispatch: any = {};
    state = {
        visible: false,
        allMerchants: [],
    };
    selectedRows = new Array<any>();

    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('MerchantPageSelector');
        entity = new Entity({});
        this.tableComponentId = TableComponentId + TableComponentNo;
        TableComponentNo++;
    }

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(entity));
    }

    componentWillReceiveProps(nextProps: any) {
        let tableRowSelectionChangeAction = false;
        //在选中列表中删除数据后更新弹窗选中状态
        if (
            (!nextProps.selectValues || nextProps.selectValues.length === 0) &&
            this.props.selectValues &&
            this.props.selectValues.length > 0
        ) {
            tableRowSelectionChangeAction = true;
            this.selectedRows = [];
        }
        if (nextProps.selectValues) {
            tableRowSelectionChangeAction = true;
            this.selectedRows = nextProps.selectValues.map((subsite: MerchantsSelectorItem) => ({
                ...subsite,
                id: String(subsite.id),
            }));
        }
        if (
            (nextProps.requestStatus === 'success' && this.props.requestStatus === 'pending') ||
            tableRowSelectionChangeAction
        ) {
            const selectedRows = this.selectedRows;
            if (selectedRows && selectedRows.length > 0) {
                setTimeout(() => {
                    this.dispatch(
                        actions.tableRowSelectionChangeAction(
                            this.tableComponentId,
                            map(selectedRows, (value) => {
                                return value.id + '';
                            })
                        )
                    );
                }, 300);
            } else {
                this.dispatch(actions.tableRowSelectionChangeAction(this.tableComponentId, []));
            }
        }
    }

    onTableRowSelect = (record: MerchantsSelectorItem, selected: boolean) => {
        const { type } = this.props;
        if (selected) {
            if ('radio' === type) {
                this.selectedRows = [record];
            } else {
                if (find(this.selectedRows, { id: record.id })) {
                    return;
                }
                this.selectedRows.push(record);
            }
        } else {
            remove(this.selectedRows, (i: any) => {
                return i.id === record.id;
            });
        }
    };

    onTableRowSelectAll = (
        selected: boolean,
        rows: MerchantsSelectorItem[],
        unSelectedRows: any
    ) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            map(unSelectedRows, (row) => {
                remove(this.selectedRows, (i) => {
                    return i.id === row.id;
                });
            });
        }
    };

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(this.tableComponentId, []));
    };

    onOk = () => {
        const { onChange } = this.props;
        if (onChange) {
            onChange(this.selectedRows, () => {
                this.toggleModal();
            });
        }
    };

    getConfig = () => {
        const { value, params, type } = this.props;
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: params || {},
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: {
                        textAlign: 'left',
                    },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    fields: [
                        {
                            property: 'merchantName',
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: this.tableComponentId,
                    scroll: {
                        y: 400,
                    },
                    pagination: false,
                    rowSelection: {
                        type: type ? type : 'checkout',
                        selectedRowKeys: map(value, (v) => {
                            return v.id + '';
                        }),
                        onSelect: (record: MerchantsSelectorItem, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (
                            selected: boolean,
                            rows: MerchantsSelectorItem[],
                            unSelectedRows: MerchantsSelectorItem[]
                        ) => {
                            this.onTableRowSelectAll(selected, rows, unSelectedRows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    columns: [
                        {
                            property: 'subSiteName',
                        },
                        {
                            property: 'merchantName',
                        },
                        {
                            property: 'code',
                            sorter: true,
                        },
                        {
                            property: 'merchantType',
                        },
                        {
                            property: 'merchantStatus',
                        },
                    ],
                },
            ],
        };
    };

    toggleModal = () => {
        const { visible } = this.state;
        const { params } = this.props;

        this.setState({ visible: !visible }, () => {
            if (!visible && entity.paging) {
                entity.pageChange({ page: 1 });
                entity.search({
                    ...params,
                });
            }
        });
    };

    render() {
        const config = this.getConfig();
        const { selectValues, loading } = this.props;
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 900,
            title: services.language.getText('selectSubsites'),
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };
        let merchantName = selectValues && selectValues.length ? selectValues[0].merchantName : '';
        return (
            <div style={{ display: 'inline-block' }}>
                <Button
                    loading={loading}
                    type="link"
                    onClick={this.toggleModal}
                    style={{ padding: 0 }}
                >
                    {services.language.getText('selectSubsites')}
                </Button>
                {merchantName && (
                    <div style={{ color: '#007046', fontSize: 13, marginBottom: 5 }}>
                        {services.language.getText('yxzj_1')}：{merchantName}
                    </div>
                )}
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}

export const MerchantsSelector = connect((_state: any) => {
    let requestStatus = get(entity, 'requestStatus', null);
    const result = { requestStatus };
    return result;
})(merchantsSelector);
