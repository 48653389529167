import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { mapValues, defaults, get, debounce } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { language } from '@comall-backend-builder/core/lib/services';

export interface CommentActivityAddFormViewProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
    data: any;
}

class commentActivityAddFormView extends PureComponent<CommentActivityAddFormViewProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }

    render() {
        const { entity, onSubmit, wrappedComponentRef } = this.props;
        let fields = [
            {
                property: 'baseInfo.name',
            },

            {
                property: 'baseInfo.dateRange',
            },
            {
                property: 'ruleInfo.subsiteId',
            },
            {
                property: 'ruleInfo.channel',
                controlConfig: {
                    onChange: (value: any) => {
                        const store = builder.getStore();
                        if (value === 'ONLINE') {
                            //订单范围如包含‘线上订单’
                            store.dispatch(
                                actions.formChangeAction(
                                    'DataFormPlus-CommentActivityAddFormContainer',
                                    'ruleInfo.activityProductType',
                                    'ALL'
                                )
                            );
                            store.dispatch(
                                actions.formChangeAction(
                                    'DataFormPlus-CommentActivityAddFormContainer',
                                    'ruleInfo.crowdScope',
                                    'CYCLE'
                                )
                            );
                            store.dispatch(
                                actions.formChangeAction(
                                    'DataFormPlus-CommentActivityAddFormContainer',
                                    'ruleInfo.memberLevelRule',
                                    null
                                )
                            );
                        } else {
                            store.dispatch(
                                actions.formChangeAction(
                                    'DataFormPlus-CommentActivityAddFormContainer',
                                    'ruleInfo.activityProductType',
                                    null
                                )
                            );
                            store.dispatch(
                                actions.formChangeAction(
                                    'DataFormPlus-CommentActivityAddFormContainer',
                                    'ruleInfo.crowdScope',
                                    null
                                )
                            );
                            store.dispatch(
                                actions.formChangeAction(
                                    'DataFormPlus-CommentActivityAddFormContainer',
                                    'ruleInfo.memberLevelRule',
                                    []
                                )
                            );
                        }
                    },
                },
            },
            {
                property: 'ruleInfo.activityProductType',
                visible: (values: any) => get(values, 'ruleInfo.channel') === 'ONLINE',
            },
            {
                property: 'ruleInfo.products',
                visible: (values: any) => get(values, 'ruleInfo.channel') === 'ONLINE',
            },
            {
                property: 'ruleInfo.crowdScope',
                visible: (values: any) => get(values, 'ruleInfo.channel') === 'ONLINE',
            },
            {
                property: 'ruleInfo.memberLevelRule',
            },
        ];

        let formProps = {
            entity,
            componentId: 'CommentActivityAddFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'comment-activity-add-form-container',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            fields: fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('CreateFormPlus'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;
    let form: any;

    /**
     * 提交前额外验证
     * @param entityFields
     */
    const validate = (entityFields: any) => {
        const {
            ruleInfo: { memberLevelRule, crowdScope, channel },
        } = entityFields;
        if (!memberLevelRule || !memberLevelRule.length) {
            AntMessage.warning(language.getText('qszhydjgz'));
            return false;
        }
        const reg = /^\+?[0-9]\d*$/;
        let result = true;

        for (let index = 0; index < memberLevelRule.length; index++) {
            const item = memberLevelRule[index];

            if (channel === 'ONLINE' && crowdScope !== 'CYCLE' && !item.levelId) {
                AntMessage.warning(language.getText('qszhydj'));
                result = false;
                break;
            }
            if (
                !item.pointChecked &&
                !item.goldChecked &&
                !item.goldValueChecked &&
                !item.couponsChecked
            ) {
                AntMessage.warning(language.getText('qszzsnr'));
                result = false;
                break;
            } else {
                if (item.pointChecked) {
                    if (!item.point || !reg.test(item.point)) {
                        AntMessage.warning(`${item.pointName}${language.getText('jzczzs')}`);
                        result = false;
                        break;
                    }
                }
                if (item.goldChecked) {
                    if (!item.gold || !reg.test(item.gold)) {
                        AntMessage.warning(`${item.goldName}${language.getText('jzczzs')}`);
                        result = false;
                        break;
                    }
                }
                if (item.goldValueChecked) {
                    if (!item.goldValue || !reg.test(item.goldValue)) {
                        AntMessage.warning(`${item.goldValueName}${language.getText('jzczzs')}`);
                        result = false;
                        break;
                    }
                }
                if (item.couponsChecked) {
                    if (!item.couponsValue.length) {
                        AntMessage.warning(language.getText('qxzyhq'));
                        result = false;
                        break;
                    }
                }
            }
        }
        return result;
    };

    return defaults(
        {
            onSubmit,
            onFieldChange,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: debounce((event: any, fields: any) => {
                const entityFields = mapValues(fields, (field) => {
                    return field.value;
                });
                if (entityFields) {
                    const hasValidate = validate(entityFields);
                    if (hasValidate) {
                        entity.add(entityFields, params);
                    }
                }
            }, 300),
            data:
                get(
                    _dispatch,
                    'components.["DataFormPlus-CommentActivityAddFormContainer"].fields'
                ) || {},

            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(
                        actions.unmountComponentAction(
                            'DataFormPlus-CommentActivityAddFormContainer'
                        )
                    );
            },
        }
    );
}
export const CommentActivityAddFormView = connect(mapDispatchToProps)(commentActivityAddFormView);
