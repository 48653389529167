// @ts-nocheck
import React, { Component } from 'react';
import { Modal, Upload, Icon, Button, message as AntMessage, message } from 'antd';
import { ExportButton } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core';
import { ForwardDataCenterModal } from '../../../../services';

const { Dragger } = Upload;
export class UploadFile extends Component<any, any> {
    state = {
        visible: false,
        confirmLoading: false,
        statusMsg: services.language.getText('uploadFileClickButton'),
        resource: {
            resourceId: 0,
            resourceName: '',
            resourceUrl: '',
            type: 'IMPORT',
        },
        fileList: [],
    };
    onImport = () => {
        const { fileList } = this.state;
        const apiRoot = `${ENV.AUTH_API_ROOT}/FILE`;
        const apiPath = '/admin/files/locations/112/upload';
        services.api
            .upload(
                { files: fileList[0] },
                { apiRoot, apiPath, fileName: 'files', progressCallBack: () => {} }
            )
            .then((res: any) => {
                //const { name, path, id } = res;
                ForwardDataCenterModal();
                this.closeModal();
            })
            .catch((err: any) => {
                if (err) {
                    message.error(
                        err.response?.body?.err_msg || services.language.getText('uoloadFail')
                    );
                }
            });
        return false;
    };

    handleOk = () => {
        const { fileList } = this.state;
        if (fileList && fileList.length) {
            this.onImport();
        } else {
            AntMessage.error(services.language.getText('pleaseUploadFile'));
            return;
        }
    };
    closeModal = () => {
        this.props.onClose();
    };
    render() {
        const { fileList, statusMsg } = this.state;
        const uploadProps = {
            accept: '.xlsx,.xls',
            multiple: false,
            onRemove: (file: any) => {
                this.setState((state: any) => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: (file: any) => {
                this.setState({
                    fileList: [file],
                });
                return false;
            },
            fileList,
        };
        const { visible } = this.props;
        return (
            <Modal
                onCancel={this.closeModal}
                visible={visible}
                title={services.language.getText('batchImport')}
                footer={[
                    <span className="internal-purchase-goods-status-msg">{statusMsg}</span>,
                    <Button key="submit" type="primary" onClick={this.handleOk}>
                        {services.language.getText('import')}
                    </Button>,
                    <Button key="back" onClick={this.closeModal}>
                        {services.language.getText('back')}
                    </Button>,
                ]}
            >
                <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">{services.language.getText('selectFile')}</p>
                    <p className="ant-upload-hint">
                        {services.language.getText('zckzm')}：.xlsx、.xls
                    </p>
                </Dragger>
                <div className="upload-tips">
                    <ExportButton
                        type="link"
                        text={services.language.getText('xzpldrmb')}
                        showErrorMessage={true}
                        source={{
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                            apiPath: '/admin/import_templates/batch_delivery.xlsx',
                        }}
                    />
                </div>
                <div className="internal-purchase-goods-tips">
                    <p className="internal-purchase-goods-tips-text">
                        {services.language.getText('importRules')}
                    </p>
                    <p className="internal-purchase-goods-tips-text">
                        {services.language.getText('zwjztxxydrdxx')}
                    </p>
                    <p className="internal-purchase-goods-tips-text-red">
                        {services.language.getText('merchantBigCodeNote')}
                    </p>
                    <p className="internal-purchase-goods-tips-text">
                        {services.language.getText('merchantBigCodeIntro2')}
                    </p>
                    <p className="internal-purchase-goods-tips-text">
                        {services.language.getText('qygckmbndxxjxtx')}
                    </p>
                </div>
            </Modal>
        );
    }
}
