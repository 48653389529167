import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import { MerchantTagSelector } from './merchant-tag-selector';

export class ArrayMerchantTagSelector extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(displayInfo: any) {
        return <MerchantTagSelector {...displayInfo} />;
    }
}
