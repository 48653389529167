import React, { Component, CSSProperties } from 'react';
import { Row as AntRow, Button, message, Popconfirm, Popover } from 'antd';
import { clone, map, concat, isEmpty, dropRight } from 'lodash';
import { ImageUpload } from '@comall-backend-builder/components-basis';
import { TooltipTrigger } from 'antd/lib/tooltip';
import { DESIGN_PAGE_LINK_TYPES_LIST } from '../../applications/design/constants/page-design';
import {
    customSelectorConfig,
    newClientCustomSelectorConfig,
} from '../../applications/design/design';
import {
    getNewLinkTypeEffectPathConfigList,
    getOpenLinkTypeEffectPathConfigList,
    getThirdLinkTypeEffectPathConfigList,
} from '../link-type-effect-path/config';
import './index.less';
import { LinkTypePlus } from '../../applications/design/components';
import { LinkTypeSelectorBussinessType } from '../../types/mode/object/subsite-link-type-selector/subsite-link-type-selector';
import { language } from '@comall-backend-builder/core/lib/services';

type CarparkImgadsValue = {
    percent?: number;
    amount?: number;
    picture?: any;
    fixed?: boolean;
};

export interface CarparkImageAdsProps {
    name: string;
    value: any;
    maxCount: number;
    maxSize: number;
    disabled: boolean;
    fixed?: boolean;
    onChange: (value: any | null, name: string) => void;
    row: any;
    newClient?: boolean;
    onlyThirdLink?: boolean;
    style?: CSSProperties;
    imgTips: string;
    bussinessType?: LinkTypeSelectorBussinessType;
    extra?: {
        tip?: string;
        img?: string;
        btnText?: string;
        triggerType?: TooltipTrigger;
        style?: CSSProperties;
    };
}
interface CarparkImageAdsState {
    subsiteId: string;
}

const DEFAULT_VALUE = {
    linkType: '',
    linkParams: null,
};

export class CarparkImageAds extends Component<CarparkImageAdsProps, CarparkImageAdsState> {
    state = {
        subsiteId: '',
    };
    static defaultProps = {
        maxCount: 6,
        maxSize: 1 * 1024,
        disabled: false,
    };

    componentWillReceiveProps(nextProps: CarparkImageAdsProps) {
        let { value, fixed, maxCount, newClient } = this.props;
        const valueLength = nextProps.value ? nextProps.value.length : 0;
        const nextSubsiteId = this.getSubsiteId(nextProps);
        const subsiteId = this.state.subsiteId;
        // 门店变动清除链接目标及链接内容
        if (nextSubsiteId !== subsiteId && this.subsiteFirstChanged && newClient) {
            const nextValue = value?.map((it: any) => {
                return {
                    ...it,
                    config: {
                        linkType: it.config?.linkType,
                        linkParams: '',
                    },
                };
            });
            this.changeValue(nextValue);
            this.setState({
                subsiteId: nextSubsiteId,
            });
        }
        if (nextSubsiteId && !subsiteId && !this.subsiteFirstChanged) {
            this.subsiteFirstChanged = true;
            this.setState({
                subsiteId: nextSubsiteId,
            });
        }
        const length = value ? value.length : 0;
        if (
            valueLength === length &&
            maxCount === nextProps.maxCount &&
            fixed === nextProps.fixed
        ) {
            return;
        }

        if (nextProps.fixed) {
            // 固定数量
            if (valueLength < nextProps.maxCount && nextProps.value) {
                //  当前值小于固定数量,补齐~
                let empty = [];
                for (let index = 0; index < nextProps.maxCount - valueLength; index++) {
                    empty.push({});
                }
                this.changeValue(concat(nextProps.value, empty));
            } else {
                let arr = dropRight(nextProps.value, valueLength - nextProps.maxCount);
                this.changeValue(arr);
            }
        }
    }
    changeValue = (value: any) => {
        let { name, onChange } = this.props;
        if (onChange) {
            onChange(value, name);
        }
    };
    /**
     * 是否为首次 subsite 变化
     */
    subsiteFirstChanged = false;
    onAddAds() {
        let { value, maxCount } = this.props;
        if (value !== null && !isEmpty(value)) {
            if (value.length >= maxCount) {
                message.warning(
                    language.getText('mostSupport') + maxCount + language.getText('notAdd')
                );
                return;
            }
            value = concat([], value);
            value.splice(value.length, 0, {});
        }
        this.changeValue(value);
    }
    onRemoveAds = (index: number) => {
        let { value } = this.props;
        if (this.valueStatus) {
            value = concat([], value);
            value.splice(index, 1);
        } else {
            value = [];
        }
        this.changeValue(value);
    };

    private get valueStatus() {
        const { value } = this.props;
        return Boolean(value && value.length);
    }

    onStatusButtonClilk = () => {
        if (this.valueStatus) {
            this.changeValue([]);
        } else {
            this.changeValue([{}]);
        }
    };

    renderStatusButton = () => {
        return (
            <div>
                <Button type="primary" onClick={this.onStatusButtonClilk}>
                    {this.valueStatus
                        ? language.getText('clearSetting')
                        : language.getText('settings')}
                </Button>
            </div>
        );
    };

    renderTips = () => {
        const { imgTips } = this.props;

        return <div className="tips">{imgTips ? imgTips : language.getText('carparkTips')}</div>;
    };

    render() {
        let { value = [{}], maxCount, disabled, style, extra } = this.props;
        return (
            <div className="carpark-image-ads" style={style}>
                {this.renderStatusButton()}
                {this.renderTips()}
                {this.valueStatus &&
                    map(value || [], (carpark, index) =>
                        this.renderCarpark(carpark, index, disabled)
                    )}
                {extra && extra.tip ? (
                    <div>
                        <span style={{ color: '#ccc', fontSize: '14px' }}>{extra.tip}</span>
                        <Popover
                            placement="top"
                            content={
                                <img
                                    alt={language.getText('entities.PageManage.preview')}
                                    style={extra.style ? extra.style : { maxWidth: 'auto' }}
                                    src={extra.img}
                                ></img>
                            }
                            trigger={extra.triggerType}
                        >
                            <Button type="link">{extra.btnText}</Button>
                        </Popover>
                    </div>
                ) : null}
                {this.valueStatus && value.length < maxCount && (
                    <Button
                        type="default"
                        className="carpark-add-carpark-button"
                        onClick={() => this.onAddAds()}
                    >
                        {language.getText('addAdvs')}
                    </Button>
                )}
            </div>
        );
    }

    onPicChange = (picture: any, index: number) => {
        let { value } = this.props;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.picture = picture;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: CarparkImgadsValue = {};
            item.picture = picture;
            value = concat([], item);
        }
        this.changeValue(value);
    };

    linkChange = (val: any, index: number) => {
        let { value } = this.props;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.config = val;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: any = {};
            item.config = val;
            value = concat([], item);
        }
        this.changeValue(value);
    };
    private getSubsiteId(props: any) {
        const { row } = props;
        return row?.carparkConfig?.subsite?.id || row?.subsite?.id;
    }
    newLinkTypeEffectPathConfigList = getNewLinkTypeEffectPathConfigList();
    openLinkTypeEffectPathConfigList = getOpenLinkTypeEffectPathConfigList();
    thirdLinkTypeEffectPathConfigList = getThirdLinkTypeEffectPathConfigList();
    private get linkTypes() {
        const { newClient, onlyThirdLink } = this.props;
        if (onlyThirdLink) {
            return this.thirdLinkTypeEffectPathConfigList;
        }
        if (newClient) {
            return this.newLinkTypeEffectPathConfigList;
        }
        return DESIGN_PAGE_LINK_TYPES_LIST;
    }
    renderCarpark(carpark: CarparkImgadsValue, index: any, isDisabled: boolean) {
        const { value, newClient, bussinessType, maxSize } = this.props;
        const subsiteId = this.getSubsiteId(this.props);
        const linkTypes =
            bussinessType === 'open-screen-ad'
                ? this.openLinkTypeEffectPathConfigList
                : this.linkTypes;
        const linkValue = (value && value[index] && value[index]['config']) || DEFAULT_VALUE;
        const linkTypeSelector =
            bussinessType === 'open-screen-ad'
                ? newClientCustomSelectorConfig
                : newClient
                ? newClientCustomSelectorConfig
                : customSelectorConfig;
        let imageControlInfo = {
            name: 'picture',
            value: carpark.picture,
            accept: 'image/*',
            maxCount: 1,
            multiple: false,
            fileName: 'fileName',
            uploadType: 'single',
            uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
            mode: 'picture-card',
            maxSize: maxSize || 1 * 1024,
            preview: {
                size: 500,
            },
            uploadLimitSizeErrorMessage:
                language.getText('pictures') +
                '{{file.name}}' +
                language.getText('pictureUploadLimit2') +
                maxSize / 1024 +
                language.getText('mPictures'),
            // limitTips: {
            //     limitUnit: '张。尺寸：宽750*300；格式：jpg、jpeg；大小：小于1M',
            // },
        };
        const hideLinkTypePlus = !subsiteId && newClient;
        const subsiteIds = newClient ? [subsiteId] : [];
        return (
            <div className="carpark" key={index}>
                <AntRow>
                    <div className="carpark-content">
                        <span>
                            {language.getText('components.ComponentSelector.imageAd')}
                            {index + 1}:
                        </span>
                        <Popconfirm
                            onConfirm={this.onRemoveAds.bind(this, index)}
                            title={language.getText('isDeletePictures')}
                            okText={language.getText('common.ok')}
                            cancelText={language.getText('common.cancel')}
                        >
                            <Button type="default" className="carpark-remove-carpark-button">
                                {language.getText('common.delete')}
                            </Button>
                        </Popconfirm>
                    </div>
                </AntRow>
                <div className="carpark-pic">
                    <ImageUpload
                        {...imageControlInfo}
                        onChange={(value: any, name: string) => {
                            this.onPicChange(value, index);
                            return {};
                        }}
                    />
                    <div className="carpark-linkType">
                        {hideLinkTypePlus ? (
                            <div style={{ color: 'rgb(191, 191, 191)' }}>
                                {language.getText('selectSubsite')}
                            </div>
                        ) : (
                            <LinkTypePlus
                                selector={linkTypeSelector as any}
                                linkTypes={linkTypes}
                                linkTypeMode="cascader"
                                value={linkValue}
                                onChange={(val) => this.linkChange(val, index)}
                                subsiteIds={subsiteIds}
                            />
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
