import React, { Component, createElement } from 'react';
import { connect } from 'react-redux';
import { find, get, isEmpty, isEqual } from 'lodash';
import { Button, Icon, message, Modal, Popover } from 'antd';
import { localStorage, uuid, api } from '@comall-backend-builder/core/lib/services';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import './index.less';

const hasStoreInfo = () => !!localStorage.get('wechantTemplatesStore');
const preDefinedComponentId = 'store-selector-' + uuid();

class messagesSubscripitonPageViewStoreSelector extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
            options: [],
            hasSubsite: hasStoreInfo(),
            subsiteWechantName: '',
            dailyLimit: '',
            issued: '',
            remain: '',
        };
    }

    shouldComponentUpdate(nextProps: any, nextState: any) {
        return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
    }

    componentDidMount() {
        let store: any = localStorage.get('wechantTemplatesStore');
        const { refresh } = this.props;
        if (store && store.id) {
            this.setState(
                {
                    hasSubsite: true,
                },
                () => {
                    this.loadWechatOfficialAccount();
                }
            );
        } else {
            api.get({}, { apiPath: '/admin/subsites/mine' }).then((res: any) => {
                if (res && res.result.length > 0) {
                    localStorage.set('wechantTemplatesStore', {
                        id: res.result[0].id,
                        name: res.result[0].name,
                    });
                    this.setState(
                        {
                            hasSubsite: true,
                        },
                        () => {
                            refresh();
                            this.loadWechatOfficialAccount();
                        }
                    );
                }
            });
        }
    }

    loadWechatOfficialAccount = () => {
        let store: any = localStorage.get('wechantTemplatesStore');
        if (store && store.id) {
            this.setState(
                {
                    dailyLimit: '',
                    issued: '',
                    remain: '',
                    subsiteWechantName: '',
                },
                () => {
                    //公众号名称
                    api.get(
                        {
                            subsiteId: store.id,
                        },
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                            apiPath: `/admin/wechat_official_accounts/by_subsite_id`,
                        }
                    ).then((response: any) => {
                        this.setState({
                            subsiteWechantName:
                                response && response.appName ? response.appName : '',
                        });
                    });
                    api.get(
                        {
                            subsiteId: store.id,
                        },
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                            apiPath: `/admin/wechat_official_accounts/template_message_daily_statistics`,
                        }
                    ).then((response: any) => {
                        if (response) {
                            const limit = 10000;
                            let dailyLimit = response.dailyLimit;
                            let issued = response.issued;
                            let remain = response.remain;
                            if (dailyLimit > limit) {
                                if (dailyLimit % limit === 0) {
                                    dailyLimit = dailyLimit / limit;
                                } else {
                                    dailyLimit = (dailyLimit / limit).toFixed(2);
                                }
                                dailyLimit += services.language.getText('wan');
                            }
                            if (issued > limit) {
                                if (issued % limit === 0) {
                                    issued = issued / limit;
                                } else {
                                    issued = (issued / limit).toFixed(2);
                                }
                                issued += services.language.getText('wan');
                            }
                            if (remain > limit) {
                                if (remain % limit === 0) {
                                    remain = remain / limit;
                                } else {
                                    remain = (remain / limit).toFixed(2);
                                }
                                remain += services.language.getText('wan');
                            }
                            this.setState({
                                dailyLimit,
                                issued,
                                remain,
                            });
                        } else {
                            this.setState({
                                dailyLimit: '',
                                issued: '',
                                remain: '',
                            });
                        }
                    });
                }
            );
        }
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    hideModal = () => {
        this.setState({
            visible: false,
        });
    };

    confirmHandle = () => {
        const { refresh, selectedRowKeys, entity } = this.props;
        if (isEmpty(selectedRowKeys)) {
            message.warning(services.language.getText('qzsxzygmd'));
            return;
        }
        const store = find(entity.result, (option) => {
            return option.id === selectedRowKeys[0];
        });
        if (!isEmpty(store)) {
            localStorage.set('wechantTemplatesStore', { id: store.id, name: store.name });
        }
        this.hideModal();
        refresh();
        this.loadWechatOfficialAccount();
    };

    renderNum = () => {
        const isValid = this.getIsValid();
        const { subsiteWechantName, dailyLimit, issued, remain } = this.state;
        if (isValid) {
            return (
                <div className={innerClassNames.wechantInfo}>
                    <div className={innerClassNames.wechantNum}>
                        {subsiteWechantName && (
                            <div className={innerClassNames.wechantNumLeft}>
                                {services.language.getText('gzhmc')}：
                                <span className={innerClassNames.num}>{subsiteWechantName}</span>
                            </div>
                        )}
                        {dailyLimit !== '' && (
                            <div className={innerClassNames.wechantNumLeft}>
                                {services.language.getText('rsx')}：
                                <span className={innerClassNames.num}>{dailyLimit}</span>
                            </div>
                        )}
                        {issued !== '' && (
                            <div className={innerClassNames.wechantNumLeft}>
                                {services.language.getText('yff')}：
                                <span className={`${innerClassNames.num} ${innerClassNames.red}`}>
                                    {issued}
                                </span>
                            </div>
                        )}
                        {remain !== '' && (
                            <div>
                                {services.language.getText('syffsl')}：
                                <span className={`${innerClassNames.num} ${innerClassNames.red}`}>
                                    {remain}
                                </span>
                            </div>
                        )}
                    </div>
                </div>
            );
        }
        if (!subsiteWechantName) {
            return (
                <div className={innerClassNames.wechantInfo}>
                    <div className={innerClassNames.wechantNum}>
                        <Icon type="info-circle" style={{ margin: '4px 4px 0 0' }} />
                        当前门店暂未关联公众号，请前往
                        <Button
                            type="link"
                            href="#/wechat-official-accounts"
                            style={{ padding: 0, height: '20px', lineHeight: '20px' }}
                        >
                            {services.language.getText('wechatOfficialAccountManger')}
                        </Button>
                        栏目中进行关联
                    </div>
                </div>
            );
        }
        return null;
    };

    renderTemplateModal = () => {
        const tipsImage = require('../images/tips-image.png');
        const demoUrl = require('../images/wechat-demo.png');
        return (
            <div className={innerClassNames.contentModal}>
                <div className={innerClassNames.contentTitle}>
                    {services.language.getText('gzhmbxx_1')}
                </div>
                <div className={innerClassNames.contentText}>
                    {services.language.getText('officialTemplateIntro1')}
                </div>
                <div className={innerClassNames.contentText}>
                    {services.language.getText('officialTemplateIntro2')}
                    <Popover
                        placement="bottom"
                        content={
                            <img
                                style={{ width: 1200 }}
                                alt={services.language.getText('preview')}
                                src={tipsImage}
                            ></img>
                        }
                        trigger="hover"
                    >
                        <Button style={{ padding: 0, height: '18px' }} type="link">
                            {services.language.getText('viewExample')}
                        </Button>
                    </Popover>
                </div>
                <div className={innerClassNames.contentText}>
                    {services.language.getText('officialTemplateIntro3')}
                </div>
                <div className={innerClassNames.contentText}>
                    {services.language.getText('wxOffcialAdjustRule')}
                    <Popover
                        placement="bottom"
                        content={
                            <img
                                style={{ width: 400 }}
                                alt={services.language.getText('preview')}
                                src={demoUrl}
                            ></img>
                        }
                        trigger="hover"
                    >
                        <Button style={{ padding: 0, height: '18px' }} type="link">
                            {services.language.getText('viewExample')}
                        </Button>
                    </Popover>
                </div>
            </div>
        );
    };

    renderWechatModal = () => {
        return (
            <div className={innerClassNames.contentModal}>
                <div className={innerClassNames.contentTitle}>
                    {services.language.getText('xcxdyxx')}
                </div>
                <div className={innerClassNames.contentText}>
                    1、{services.language.getText('sjzyIntro1')}
                    <span className={innerClassNames.contentHref} onClick={this.openUrl}>
                        https://mp.weixin.qq.com/
                    </span>
                </div>
                <div className={innerClassNames.contentText}>
                    {services.language.getText('sjzyIntro2')}
                </div>
                <div className={innerClassNames.contentText}>
                    {services.language.getText('sjzyIntro3')}
                </div>
                <div className={innerClassNames.contentText}>
                    {services.language.getText('sjzyIntro4')}
                </div>
            </div>
        );
    };

    renderTip = () => {
        return (
            <div className={innerClassNames.content}>
                <div className={innerClassNames.contentTitle}>
                    {services.language.getText('gzhmbxx_1')}
                    <Popover placement="left" content={this.renderTemplateModal()} trigger="hover">
                        <div className={innerClassNames.contentIcon}>
                            <Icon type="question-circle" />
                            &nbsp;{services.language.getText('syxz')}
                        </div>
                    </Popover>
                </div>
                <div>
                    <div className={innerClassNames.contentText}>
                        {services.language.getText('bywxgzhhtkt')}
                    </div>
                </div>
                <div className={innerClassNames.contentTitle}>
                    {services.language.getText('xcxdyxx')}
                    <Popover placement="left" content={this.renderWechatModal()} trigger="hover">
                        <div className={innerClassNames.contentIcon}>
                            <Icon type="question-circle" />
                            &nbsp;{services.language.getText('syxz')}
                        </div>
                    </Popover>
                </div>
                <div className={innerClassNames.contentText}>
                    {services.language.getText('sjzyIntro1')}
                    <span className={innerClassNames.contentHref} onClick={this.openUrl}>
                        https://mp.weixin.qq.com/
                    </span>
                </div>
            </div>
        );
    };

    openUrl = () => {
        window.open('https://mp.weixin.qq.com/', '_blank');
    };

    renderModal = () => {
        const { visible } = this.state;
        const { entity } = this.props;
        if (entity.params && entity.params.status) {
            delete entity.params.status;
        }
        return (
            <Modal
                title={services.language.getText('otherSelectSubsite')}
                className="store-selector"
                width={800}
                visible={visible}
                onCancel={this.hideModal}
                cancelButtonProps={{ disabled: true }}
                footer={[
                    <Button key="back" onClick={this.hideModal}>
                        {services.language.getText('common.cancel')}
                    </Button>,
                    <Button key="submit" type="primary" onClick={this.confirmHandle}>
                        {services.language.getText('common.ok')}
                    </Button>,
                ]}
            >
                <div>
                    {createElement(ComponentsManager.get('FilterFormPlus'), {
                        style: {
                            width: '98%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginBottom: 8,
                        },
                        direction: 'inline',
                        entity,
                        submit: {
                            text: services.language.getText('common.search'),
                            style: { marginRight: -32 },
                        },
                        fields: [
                            {
                                property: 'name',
                                controlConfig: {
                                    style: { width: 300 },
                                },
                            },
                        ],
                    })}
                    {createElement(ComponentsManager.get('DataTable'), {
                        loadFirstPage: true,
                        entity,
                        componentId: preDefinedComponentId,
                        columns: [{ property: 'name' }],
                        rowSelection: {
                            type: 'radio',
                            onChange() {},
                        },
                        pagination: {
                            pageSize: 10,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            pageSizeOptions: ['10', '15', '20'],
                            showTotal(total: string) {
                                return services.interpolate(services.language.getText('total'), {
                                    total,
                                });
                            },
                        },
                    })}
                </div>
            </Modal>
        );
    };

    getIsValid = () => {
        const { subsiteWechantName, dailyLimit, issued, remain } = this.state;
        return subsiteWechantName || dailyLimit || issued || remain;
    };

    render() {
        const { hasSubsite } = this.state;
        const store: any = localStorage.get('wechantTemplatesStore') || {};
        return (
            <div className={prefix}>
                {this.renderTip()}
                {this.renderModal()}
                <div className={innerClassNames.subsiteInfo}>
                    <div className={innerClassNames.subsite}>
                        <span>{services.language.getText('subsites')}： </span>
                        <Button onClick={this.showModal}>
                            {hasSubsite && store.name
                                ? store.name
                                : services.language.getText('selectSubsite')}
                            <Icon type="down" style={{ marginLeft: 8 }} />
                        </Button>
                    </div>
                    {this.renderNum()}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    const component = state.components[preDefinedComponentId];

    return {
        selectedRowKeys: get(component, 'selectedRowKeys'),
    };
};

export const MessagesSubscripitonPageViewStoreSelector = connect(mapStateToProps)(
    messagesSubscripitonPageViewStoreSelector
);

const prefix = 'messages-subscripiton-page-view-store-selector';
const innerClassNames = {
    subsite: `${prefix}__subsite`,
    subsiteInfo: `${prefix}__subsite-info`,
    wechantInfo: `${prefix}__wechant-info`,
    wechantNum: `${prefix}__wechant-num`,
    wechantNumLeft: `${prefix}__wechant-num-left`,
    num: `${prefix}__num`,
    red: `${prefix}__red`,
    noValid: `${prefix}__no-valid`,
    noValidTitle: `${prefix}__no-valid__title`,
    noValidButton: `${prefix}__no-valid__button`,
    noValidList: `${prefix}__no-valid__list`,
    content: `${prefix}__content`,
    contentModal: `${prefix}__content-modal`,
    contentTitle: `${prefix}__content__title`,
    contentText: `${prefix}__content__text`,
    contentHref: `${prefix}__content__href`,
    contentIcon: `${prefix}__content__icon`,
};
