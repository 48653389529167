import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { isEmpty } from 'lodash';

const api = services.api;
export const OfflineOrderCommentsCustomizationLoader: Loader = {
    get: async function(data, config) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COMMENT`;
        config.apiPath = '/admin/custom_comments';
        if (!isEmpty(data.subsite)) {
            data.subsiteId = data.subsite.id;
            delete data.subsite;
        }
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        return await api.get(data, config).then((res: any) => {
            res.result.map((item: any) => {
                if (item.satisfactionStatus === 'PRAISE') {
                    item.satisfactionStatus = '满意';
                } else if (item.satisfactionStatus === 'POOR') {
                    item.satisfactionStatus = '不满意';
                }
                if (item.followUpStatus === true) {
                    item.followUpStatus = '愿意';
                } else if (item.followUpStatus === false) {
                    item.followUpStatus = '不愿意';
                }
                item.score = item.score ? `${item.score}分` : '';
                return item;
            });
            return res;
        });
    },
};
