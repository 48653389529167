import React, { PureComponent } from 'react';
import { Radio, Button, message, Table } from 'antd';
import { get, map } from 'lodash';
import { UploadFile } from './upload-file';
import './index.less';
import classNames from 'classnames';
import { services } from '@comall-backend-builder/core';

interface MaotaiPresalePurchaseModeValue {
    mode: string;
    rounds: any;
}
enum MaotaiPresalePurchaseModeEnum {
    SAME = 'SAME',
    DIFFERENT = 'DIFFERENT',
}
interface MaotaiPresalePurchaseModeProps {
    /**
     * 内容改变回调
     * @param value 新值
     */
    onChange: (value: MaotaiPresalePurchaseModeValue) => void;
    value: MaotaiPresalePurchaseModeValue;
    row: any;
}
interface MaotaiPresalePurchaseModeState {
    visible: boolean;
    showMore: boolean;
}

export class MaotaiPresalePurchaseMode extends PureComponent<
    MaotaiPresalePurchaseModeProps,
    MaotaiPresalePurchaseModeState
> {
    static defaultProps = {
        value: {
            mode: MaotaiPresalePurchaseModeEnum.SAME,
        },
    };
    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
            showMore: false,
        };
    }
    componentWillReceiveProps(prevProps: Readonly<MaotaiPresalePurchaseModeProps>): void {
        const prevSubsiteIds = this.getSubsiteIds(prevProps);
        const subsiteIds = this.getSubsiteIds(this.props);
        if (prevSubsiteIds && !subsiteIds) {
            this.props.onChange({
                mode: MaotaiPresalePurchaseModeEnum.SAME,
                rounds: undefined,
            });
        }
    }
    onChange = (e: any) => {
        const { onChange, value } = this.props;

        if (onChange) {
            value.mode = e.target.value;
            onChange(value);
        }
    };
    changeRounds = (rounds: any) => {
        const { onChange, value } = this.props;
        this.toggleModal();
        if (onChange) {
            value.rounds = rounds;
            onChange(value);
        }
    };
    getSubsiteIds = (props: any) => {
        const { row } = props;
        let subsites = row.subsites;
        if (row.activityInfo && row.activityInfo.subsites) {
            subsites = row.activityInfo.subsites;
        }
        const subsiteIds = subsites ? map(subsites, 'id') : null;
        return subsiteIds;
    };
    toggleModal = () => {
        const { row } = this.props;
        const subsiteIds = this.getSubsiteIds(this.props);
        if (!subsiteIds) {
            message.error(services.language.getText('selectBeforeMerchant'));
            return;
        }
        const registerTimeRange = get(row, 'roundsInfo.registerTimeRange');
        if (!registerTimeRange || !registerTimeRange.start || !registerTimeRange.end) {
            message.error(services.language.getText('qxxzhdbmsj'));
            return;
        }
        this.setState({
            visible: !this.state.visible,
        });
    };
    toggleShowMore = () => {
        this.setState({
            showMore: !this.state.showMore,
        });
    };
    renderRounds = () => {
        const { value } = this.props;
        const { showMore } = this.state;

        return (
            <div>
                {value.rounds.map((item: any, index: number) => {
                    const itemClass = classNames('round-item', {
                        'round-item-hidden': !showMore && index > 1,
                    });
                    return (
                        <div className={itemClass} key={index}>
                            <div className="round-header">
                                <span className="round-header-title">
                                    {services.language.getText('sgsj')}：
                                </span>
                                {item.drawTime}
                            </div>
                            <Table
                                pagination={false}
                                rowKey={'id'}
                                bordered={false}
                                dataSource={item.goodsVos}
                                columns={[
                                    {
                                        title: services.language.getText('subsiteName'),
                                        dataIndex: 'subsiteName',
                                        key: 'subsiteName',
                                    },
                                    {
                                        title: services.language.getText('sp'),
                                        dataIndex: 'name',
                                        key: 'name',
                                    },
                                    {
                                        title: services.language.getText('mcsgsl'),
                                        dataIndex: 'quantity',
                                        key: 'quantity',
                                    },
                                    {
                                        title: services.language.getText('sgbmdsl'),
                                        dataIndex: 'whiteListQuantity',
                                        key: 'whiteListQuantity',
                                    },
                                ]}
                            />
                            {value.rounds.length > 2 && index === 1 && !showMore && (
                                <div className="round-show-more" onClick={this.toggleShowMore}>
                                    <Button type="link">{services.language.getText('ckgd')}</Button>
                                </div>
                            )}
                            {value.rounds.length > 2 &&
                                index === value.rounds.length - 1 &&
                                showMore && (
                                    <div className="round-show-more" onClick={this.toggleShowMore}>
                                        <Button type="link">
                                            {services.language.getText('putAway')}
                                        </Button>
                                    </div>
                                )}
                        </div>
                    );
                })}
            </div>
        );
    };

    render() {
        const { value } = this.props;
        let { visible } = this.state;

        const uploadFileProps = {
            visible: visible,
            onOk: this.changeRounds,
            onClose: this.toggleModal,
            ...this.props,
        };
        return (
            <div className="maotai-presale-purchase-mode">
                <Radio.Group value={value.mode} onChange={this.onChange}>
                    <div className="maotai-presale-purchase-mode-radio">
                        <Radio value={MaotaiPresalePurchaseModeEnum.SAME}>
                            {services.language.getText('mcsgslyz')}
                        </Radio>
                        <Radio value={MaotaiPresalePurchaseModeEnum.DIFFERENT}>
                            {services.language.getText('mcsgslbyz')}
                        </Radio>
                        {value.mode === MaotaiPresalePurchaseModeEnum.DIFFERENT && (
                            <Button type="link" onClick={this.toggleModal}>
                                {services.language.getText('batchImport')}
                            </Button>
                        )}
                    </div>
                </Radio.Group>
                {value.mode === MaotaiPresalePurchaseModeEnum.DIFFERENT &&
                    value.rounds &&
                    value.rounds.length &&
                    this.renderRounds()}
                <UploadFile {...uploadFileProps} />
            </div>
        );
    }
}
