// import React, { PureComponent } from 'react';
// import { some, get } from 'lodash';
import React, { PureComponent } from 'react';
import { Table, Button, InputNumber } from 'antd';
import { cloneDeep, remove, find } from 'lodash';
import { PaginationConfig } from 'antd/lib/table/interface';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

export interface MemberCardDiscountRule {
    memberCardLevelName: string;
    memberCardLevelId: string;
    discount: number | null;
}

interface MemberCardWithDiscountDisplayProps {
    data: Array<MemberCardDiscountRule>;
    onChange: (value: any) => void;
    disabled?: boolean;
    xScrollWidth?: number;
}
interface MemberCardWithDiscountDisplayState {
    selectedRows: Array<MemberCardDiscountRule>;
    visible: boolean;
    settingGoods: object;
}

/**
 * 会员等级展示和内容编辑
 */
export class MemberCardWithDiscountDisplay extends PureComponent<
    MemberCardWithDiscountDisplayProps,
    MemberCardWithDiscountDisplayState
> {
    constructor(props: MemberCardWithDiscountDisplayProps) {
        super(props);
        this.state = { selectedRows: [], visible: false, settingGoods: {} };
    }

    changeValue = (value: number, id: string) => {
        const { data, onChange } = this.props;
        const result = cloneDeep(data);
        let item: MemberCardDiscountRule | undefined = find(
            result,
            (i) => id === i.memberCardLevelId
        );
        if (item) {
            item.discount = value;
            onChange(result);
        }
    };
    getTableConfig = () => {
        const { disabled } = this.props;

        let tableConfig: any = {
            rowKey: 'id',
            columns: [
                {
                    title: language.getText('hykmc'),
                    dataIndex: 'memberCardLevelName',
                    key: 'memberCardLevelName',
                    width: 150,
                },
                {
                    title: language.getText('xfzk'),
                    dataIndex: 'discount',
                    key: 'discount',
                    width: 180,
                    align: 'center',
                    render: (i: any, record: any) => {
                        return !disabled ? (
                            <div>
                                <InputNumber
                                    value={i}
                                    min={0.1}
                                    max={9.9}
                                    placeholder={services.language.getText('inputPlease')}
                                    precision={1}
                                    onChange={(value: any) =>
                                        this.changeValue(value, record.memberCardLevelId)
                                    }
                                />{' '}
                                {language.getText('z_8')}
                            </div>
                        ) : (
                            <div>
                                {i}
                                {language.getText('z_8')}
                            </div>
                        );
                    },
                },
                {
                    title: services.language.getText('common.tableAction'),
                    key: 'operation',
                    width: 100,
                    render: (i: any, row: any) => {
                        return (
                            <Button
                                type={'link'}
                                onClick={this.onRemove(row)}
                                style={{ padding: '0' }}
                                disabled={disabled}
                            >
                                {services.language.getText('common.delete')}
                            </Button>
                        );
                    },
                },
            ],
            style: {
                marginTop: 20,
            },
            scroll: {
                y: 500,
                x: 500,
            },
            footer: () => {
                return disabled ? null : (
                    <Button type={'primary'} onClick={this.onRemoveAll}>
                        {language.getText('allClear')}
                    </Button>
                );
            },
        };

        return tableConfig;
    };

    onRemove = (row: any) => {
        const { data, onChange } = this.props;
        return () => {
            const result = cloneDeep(data);
            remove(result, (r) => row.memberCardLevelId === r.memberCardLevelId);
            onChange(result);
        };
    };

    onRemoveAll = () => {
        const { onChange } = this.props;
        onChange && onChange([]);
    };

    render() {
        const { data } = this.props;
        const pagination = false as PaginationConfig | false;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: cloneDeep(data),
            pagination,
        };

        return (
            <div>
                <Table {...tableProps}></Table>
            </div>
        );
    }
}
