import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Descriptions } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services';

interface orderInfosProps {
    data: any;
}

export class OrderInfos extends Component<orderInfosProps, any> {
    static propTypes = {
        renderItem: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        rowSelection: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
        header: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
    };

    state = {
        indeterminate: false,
        selectedRowKeys: [],
        checkAll: false,
    };

    render() {
        let { data } = this.props;
        return (
            <Descriptions>
                <Descriptions.Item label={language.getText('orderNumber')}>
                    {data?.orderNo}
                </Descriptions.Item>
                <Descriptions.Item label={language.getText('ddzt')}>
                    {data?.statusName}
                </Descriptions.Item>
                <Descriptions.Item label={language.getText('xdrq')}>
                    {data?.createTime}
                </Descriptions.Item>
                <Descriptions.Item label={language.getText('yfze_1')}>
                    {data?.paymentInfo?.payableAmount}
                </Descriptions.Item>
                <Descriptions.Item label={language.getText('nickName')}>
                    {data?.memberInfo?.nickName}
                </Descriptions.Item>
                <Descriptions.Item label={language.getText('phoneNum')}>
                    {data?.memberInfo?.mobile}
                </Descriptions.Item>
                <Descriptions.Item label={language.getText('thr')}>
                    {data?.deliveryInfo?.receiverName}
                </Descriptions.Item>
                <Descriptions.Item label={language.getText('thrsjh')}>
                    {data?.deliveryInfo?.mobile}
                </Descriptions.Item>
            </Descriptions>
        );
    }
}
