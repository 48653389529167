import { ArrayFormat } from '@comall-backend-builder/types';
import { language } from '@comall-backend-builder/core/lib/services';
import { isArray, isNil } from 'lodash';

export class ArrayObjectFormat extends ArrayFormat {
    /**
     * 对数据进行校验
     */
    public validate(rule: any, value: any, callback: any) {
        if (isNil(value)) {
            callback();
        } else if (!isArray(value)) {
            callback(language.getText('validation.array.default'));
        } else {
            try {
                if (typeof value !== 'object') {
                    JSON.parse(value);
                }
                callback();
            } catch (error) {
                callback(language.getText('validation.object.default'));
            }
        }
    }
}
