import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { SelectProductStyle, ProductStyleSelectedDisplay } from '../../../components';

/**
 * 选择商品组件-选择完以商品款式的维度展示（一期支持商品维度，后期会随着需求改）
 */
export class ProductStyleSelector extends modes.ArrayMode {
    getDisplayComponent(value: any, displayInfo: any = {}) {
        const { showFilter } = displayInfo;
        return (
            <ProductStyleSelectedDisplay
                data={value}
                modifiable={false}
                onChange={() => {}}
                showFilter={showFilter}
            ></ProductStyleSelectedDisplay>
        );
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <SelectProductStyle {...displayInfo} />;
    }
}
