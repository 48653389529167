import React, { Component, createElement } from 'react';
import { connect } from 'react-redux';
import { find, get, isEmpty, isEqual } from 'lodash';
import { Button, Icon, message, Modal } from 'antd';
import { localStorage, uuid, api } from '@comall-backend-builder/core/lib/services';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import './index.less';

const hasStoreInfo = () => !!localStorage.get('wechantTemplatesStore');
const preDefinedComponentId = 'store-selector-' + uuid();

class wechatTemplatesFindStoreSelector extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
            options: [],
            hasSubsite: hasStoreInfo(),
            subsiteWechantName: '',
            dailyLimit: '',
            issued: '',
            remain: '',
        };
    }

    shouldComponentUpdate(nextProps: any, nextState: any) {
        return !isEqual(nextProps, this.props) || !isEqual(nextState, this.state);
    }

    componentDidMount() {
        let store: any = localStorage.get('wechantTemplatesStore');
        const { refresh } = this.props;
        if (store && store.id) {
            this.setState(
                {
                    hasSubsite: true,
                },
                () => {
                    this.loadWechatOfficialAccount();
                }
            );
        } else {
            api.get({}, { apiPath: '/admin/subsites/mine' }).then((res: any) => {
                if (res && res.result.length > 0) {
                    localStorage.set('wechantTemplatesStore', {
                        id: res.result[0].id,
                        name: res.result[0].name,
                    });
                    this.setState(
                        {
                            hasSubsite: true,
                        },
                        () => {
                            refresh();
                            this.loadWechatOfficialAccount();
                        }
                    );
                }
            });
        }
    }

    loadWechatOfficialAccount = () => {
        const { pageType = '' } = this.props;
        let store: any = localStorage.get('wechantTemplatesStore');
        if (store && store.id) {
            //公众号名称
            api.get(
                {
                    subsiteId: store.id,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: `/admin/wechat_official_accounts/by_subsite_id`,
                }
            ).then((response: any) => {
                this.setState({
                    subsiteWechantName: response && response.appName ? response.appName : '',
                });
            });
            if (!pageType) {
                //日上限
                api.get(
                    {
                        subsiteId: store.id,
                    },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        apiPath: `/admin/wechat_official_accounts/template_message_daily_statistics`,
                    }
                ).then((response: any) => {
                    if (response) {
                        const limit = 10000;
                        let dailyLimit = response.dailyLimit;
                        let issued = response.issued;
                        let remain = response.remain;
                        if (dailyLimit > limit) {
                            if (dailyLimit % limit === 0) {
                                dailyLimit = dailyLimit / limit;
                            } else {
                                dailyLimit = (dailyLimit / limit).toFixed(2);
                            }
                            dailyLimit += services.language.getText('wan');
                        }
                        if (issued > limit) {
                            if (issued % limit === 0) {
                                issued = issued / limit;
                            } else {
                                issued = (issued / limit).toFixed(2);
                            }
                            issued += services.language.getText('wan');
                        }
                        if (remain > limit) {
                            if (remain % limit === 0) {
                                remain = remain / limit;
                            } else {
                                remain = (remain / limit).toFixed(2);
                            }
                            remain += services.language.getText('wan');
                        }
                        this.setState({
                            dailyLimit,
                            issued,
                            remain,
                        });
                    } else {
                        this.setState({
                            dailyLimit: '',
                            issued: '',
                            remain: '',
                        });
                    }
                });
            }
        }
    };

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    hideModal = () => {
        this.setState({
            visible: false,
        });
    };

    confirmHandle = () => {
        const { refresh, selectedRowKeys, entity } = this.props;
        if (isEmpty(selectedRowKeys)) {
            message.warning(services.language.getText('qzsxzygmd'));
            return;
        }
        const store = find(entity.result, (option) => {
            return option.id === selectedRowKeys[0];
        });
        if (!isEmpty(store)) {
            localStorage.set('wechantTemplatesStore', { id: store.id, name: store.name });
        }
        this.hideModal();
        refresh();
        this.loadWechatOfficialAccount();
    };

    render() {
        const { visible, hasSubsite, subsiteWechantName, dailyLimit, issued, remain } = this.state;
        const { entity, pageType = '' } = this.props;
        if (entity.params && entity.params.status) {
            delete entity.params.status;
        }
        const store: any = localStorage.get('wechantTemplatesStore') || {};
        return (
            <div className="wechat-template-find-store-selector">
                <span>{services.language.getText('subsites')}: </span>
                <Button onClick={this.showModal}>
                    {hasSubsite && store.name ? store.name : '请选择门店'}
                    <Icon type="down" style={{ marginLeft: 8 }} />
                </Button>
                {!pageType && (subsiteWechantName || dailyLimit || issued || remain) && (
                    <div className="wechant-info">
                        <div>
                            {subsiteWechantName && (
                                <span>
                                    {services.language.getText('gzhmc')}：{subsiteWechantName}
                                </span>
                            )}
                        </div>
                        <div className="wechant-num">
                            {dailyLimit !== '' && (
                                <div className="wechant-num-left">
                                    {services.language.getText('rsx')}：
                                    <span className="num">{dailyLimit}</span>
                                </div>
                            )}
                            {issued !== '' && (
                                <div className="wechant-num-left">
                                    {services.language.getText('yff')}：
                                    <span className="num red">{issued}</span>
                                </div>
                            )}
                            {remain !== '' && (
                                <div>
                                    {services.language.getText('syffsl')}：
                                    <span className="num red">{remain}</span>
                                </div>
                            )}
                        </div>
                    </div>
                )}
                <Modal
                    title={services.language.getText('otherSelectSubsite')}
                    className="store-selector"
                    width={800}
                    visible={visible}
                    onCancel={this.hideModal}
                    cancelButtonProps={{ disabled: true }}
                    footer={[
                        <Button key="back" onClick={this.hideModal}>
                            {services.language.getText('qx')}
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.confirmHandle}>
                            {services.language.getText('common.ok')}
                        </Button>,
                    ]}
                >
                    <div>
                        {createElement(ComponentsManager.get('FilterFormPlus'), {
                            style: {
                                width: '98%',
                                display: 'flex',
                                justifyContent: 'flex-end',
                                marginBottom: 8,
                            },
                            direction: 'inline',
                            entity,
                            submit: {
                                text: services.language.getText('common.search'),
                                style: { marginRight: -32 },
                            },
                            fields: [
                                {
                                    property: 'name',
                                    controlConfig: {
                                        style: { width: 300 },
                                    },
                                },
                            ],
                        })}
                        {createElement(ComponentsManager.get('DataTable'), {
                            loadFirstPage: true,
                            entity,
                            componentId: preDefinedComponentId,
                            columns: [{ property: 'name' }],
                            rowSelection: {
                                type: 'radio',
                                onChange() {},
                            },
                            pagination: {
                                pageSize: 10,
                                showSizeChanger: true,
                                showQuickJumper: true,
                                pageSizeOptions: ['10', '15', '20'],
                                showTotal(total: string) {
                                    return `共 ${total} 条记录`;
                                },
                            },
                        })}
                    </div>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state: any) => {
    const component = state.components[preDefinedComponentId];

    return {
        selectedRowKeys: get(component, 'selectedRowKeys'),
    };
};

export const WechatTemplatesFindStoreSelector = connect(mapStateToProps)(
    wechatTemplatesFindStoreSelector
);
