import { DESIGN_PAGE_TYPES_LIST } from '../constants/page-design';
import { ChannelType } from '../../../types/mode/array/array-channel-checkbox';
enum STATUS {
    /**
     * 使用中
     */
    ENABLED = 'ENABLED',
    /**
     * 已停用
     */
    DISABLED = 'DISABLED',
}
const POINT_TAG_STATUS = [
    {
        id: STATUS.DISABLED,
        name: '<<deactivated>>',
    },
    {
        id: STATUS.ENABLED,
        name: '<<inUse>>',
    },
];

// 笔记状态
const CONTENT_NOTE_STATUS = [
    {
        id: 'NORMAL',
        name: '<<zc_1>>',
    },
    {
        id: 'SYSTEM_DELETED',
        name: '<<kfsc>>',
    },
    {
        id: 'DELETED',
        name: '<<zzsc>>',
    },
];

export const config = {
    entities: {
        PageTemplateEntity: {
            apiPath: '/loader/admin/templates',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<common.keywords>>',
                },
            },
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<entities.TemplateManage.templateName>>',
                    rules: [
                        { required: true, message: '<<rules.TemplateManage.nameRequired>>' },
                        { max: 16, message: '<<rules.TemplateManage.nameLength>>' },
                    ],
                    controlConfig: {
                        placeholder: '<<rules.TemplateManage.nameRequired>>',
                    },
                },
                type: {
                    type: 'optionsString',
                    displayName: '<<entities.TemplateManage.templateType>>',
                    rules: [{ required: true }],
                    defaultValue: DESIGN_PAGE_TYPES_LIST[0],
                    options: DESIGN_PAGE_TYPES_LIST,
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        optionFilterProp: 'children',
                    },
                },
                order: {
                    type: 'number.positive',
                    displayName: '<<entities.TemplateManage.sorting>>',
                    defaultValue: '0',
                },
                preview: {
                    type: 'imageObjectArray',
                    rules: [{ required: false }],
                    displayName: '<<entities.TemplateManage.previewImage>>',
                },
                structureFixed: {
                    type: 'boolean.switch',
                    displayName: '<<entities.TemplateManage.FixedPageStructure>>',
                    defaultValue: false,
                },
                isDefault: {
                    type: 'boolean.switch',
                    displayName: '<<entities.TemplateManage.isDefault>>',
                    defaultValue: true,
                },
                enabled: {
                    type: 'boolean.switch',
                    displayName: '<<entities.TemplateManage.enabled>>',
                    defaultValue: false,
                },
            },
        },
        PageManageEntity: {
            apiPath: '/loader/admin/home_pages',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<common.keywords>>',
                },
                groupSubsiteName: {
                    type: 'string',
                    displayName: '<<subsitesOrAll>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                groupSubsiteName: {
                    type: 'groupSubsiteMode',
                    displayName: '<<subsitesOrAll>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                subsites: {
                    type: 'array.subsites.selector',
                    displayName: '<<subsites>>',
                    rules: [{ required: true }],
                },
                // subsites: {
                //     type: 'array.options.autoComplete',
                //     displayName: '<<subsites>>',
                //     options: [],
                //     source: {
                //         apiPath: '/admin/defaults/subsite',
                //     },
                //     rules: [{ required: true }],
                // },
                name: {
                    type: 'string',
                    displayName: '<<entities.PageManage.pagesName>>',
                    rules: [
                        { required: true, message: '<<rules.PageManage.nameRequired>>' },
                        { max: 16, message: '<<rules.PageManage.nameLength>>' },
                    ],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                enabled: {
                    type: 'boolean.switch',
                    displayName: '<<entities.PageManage.enabled>>',
                },
                template: {
                    type: 'pageTemplate',
                    displayName: '<<entities.PageManage.template>>',
                    source: {
                        apiPath: '/admin/templates',
                        loadOnInit: true,
                    },
                },
                createTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.createTime>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.lastedModifyTime>>',
                },
            },
        },
        TopicManageEntity: {
            apiPath: '/loader/admin/topic_pages',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<common.keywords>>',
                },
                groupSubsiteName: {
                    type: 'string',
                    displayName: '<<subsitesOrAll>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                groupSubsiteName: {
                    type: 'groupSubsiteMode',
                    displayName: '<<subsitesOrAll>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                subsites: {
                    type: 'array.subsites.selector',
                    displayName: '<<subsites>>',
                    rules: [{ required: true }],
                },
                // subsites: {
                //     type: 'array.options.autoComplete',
                //     displayName: '<<subsites>>',
                //     options: [],
                //     source: {
                //         apiPath: '/admin/defaults/subsite',
                //     },
                //     rules: [{ required: true }],
                // },
                name: {
                    type: 'string',
                    displayName: '<<entities.PageManage.pagesName>>',
                    rules: [
                        { required: true, message: '<<rules.PageManage.nameRequired>>' },
                        { max: 16, message: '<<rules.PageManage.nameLength>>' },
                    ],
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                enabled: {
                    type: 'boolean.switch',
                    displayName: '<<entities.PageManage.enabled>>',
                },
                template: {
                    type: 'pageTemplate',
                    displayName: '<<entities.PageManage.template>>',
                    source: {
                        apiPath: '/admin/templates',
                        loadOnInit: true,
                    },
                },
                createTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.createTime>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.lastedModifyTime>>',
                },
                channels: {
                    type: 'array.channel.checkbox',
                    displayName: '<<syqd>>',
                },
            },
        },
        MerchantPageManageEntity: {
            apiPath: '/loader/merchantPage',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<common.keywords>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchant>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                merchantName: {
                    type: 'string',
                },
                merchantId: {
                    type: 'string',
                },
                groupSubsiteName: {
                    type: 'string',
                    displayName: '<<merchant>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsites>>',
                },
                enabled: {
                    type: 'boolean.switch',
                    displayName: '<<entities.PageManage.enabled>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<entities.PageManage.pagesName>>',
                    rules: [
                        { required: true, message: '<<rules.PageManage.nameRequired>>' },
                        { max: 100, message: '<<rules.PageManage.nameLongLength>>' },
                    ],
                },
                template: {
                    type: 'pageTemplate',
                    displayName: '<<entities.PageManage.template>>',
                    source: {
                        apiPath: '/admin/templates',
                        loadOnInit: true,
                    },
                },
                merchantSelect: {
                    type: 'merchantSelect',
                    displayName: '<<merchant>>',
                    rules: [
                        {
                            required: true,
                            message: '<<rules.MerchantPageManage.nameRequired>>',
                        },
                    ],
                },
                createTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.createTime>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.lastedModifyTime>>',
                },
                pageName: {
                    type: 'string.popover',
                    displayName: '<<entities.PageManage.pagesName>>',
                },
            },
        },
        NewMerchantPageManageEntity: {
            apiPath: '/loader/new-merchantPage',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<common.keywords>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchant>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                merchantName: {
                    type: 'string',
                },
                merchantId: {
                    type: 'string',
                },
                code: {
                    type: 'string',
                    displayName: '<<merchantCode>>',
                },
                groupSubsiteName: {
                    type: 'string',
                    displayName: '<<merchant>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsites>>',
                },
                enabled: {
                    type: 'boolean.switch',
                    displayName: '<<entities.PageManage.enabled>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<entities.PageManage.pagesName>>',
                    rules: [
                        { required: true, message: '<<rules.PageManage.nameRequired>>' },
                        { max: 100, message: '<<rules.PageManage.nameLongLength>>' },
                    ],
                },
                template: {
                    type: 'pageTemplate',
                    displayName: '<<entities.PageManage.template>>',
                    source: {
                        apiPath: '/admin/templates',
                        loadOnInit: true,
                    },
                },
                merchantSelect: {
                    type: 'merchantSelect',
                    displayName: '<<merchant>>',
                    rules: [
                        {
                            required: true,
                            message: '<<rules.MerchantPageManage.nameRequired>>',
                        },
                    ],
                },
                createTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.createTime>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.lastedModifyTime>>',
                },
            },
        },
        MerchantPageSelector: {
            apiPath: '/loader/subsites/mine/merchants',
            // apiPath: '/loader/merchantList',
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API/admin`,
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                subSiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                code: {
                    type: 'string',
                    displayName: '<<merchantCode>>',
                },
                merchantType: {
                    type: 'string',
                    displayName: '<<merchantType>>',
                },
                merchantStatus: {
                    type: 'string',
                    displayName: '<<businessStatus>>',
                },
            },
            filters: {
                subSiteName: {
                    type: 'string.options.select',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        style: { width: 160 },
                    },
                },
                merchantName: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<merchantName>>',
                },
                merchantCode: {
                    type: 'string',
                    displayName: '<<merchantCode>>',
                },
                merchantType: {
                    type: 'string.options.autoComplete',
                    displayName: '<<merchantType>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/template/merchant_type',
                        params: {
                            isContainsSelfMerchant: false,
                        },
                    },
                    controlConfig: {
                        style: {
                            width: 180,
                        },
                        placeholder: '<<selectPlease>>',
                    },
                },
                merchantTagId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<merchantTag>>',
                    options: [],
                    source: {
                        apiPath: '/admin/merchant_tags?page=1&per_page=100&status=ENABLED',
                        dataType: 'paging',
                    },
                    controlConfig: {
                        style: {
                            width: 180,
                        },
                        placeholder: '<<selectPlease>>',
                    },
                },
            },
        },
        PageTemplateForSelectEntity: {
            apiPath: '/loader/admin/templates/options',
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<entities.TemplateManage.templateName>>',
                },
                type: {
                    type: 'optionsString',
                    displayName: '<<entities.TemplateManage.templateType>>',
                },
                order: {
                    type: 'number.positive',
                    displayName: '<<entities.TemplateManage.sorting>>',
                },
                preview: {
                    type: 'imageObjectArray',
                    displayConfig: {
                        style: {
                            width: 50,
                        },
                        preview: {
                            visible: true,
                            maxCount: 3,
                            autoplay: true,
                            size: 300,
                        },
                    },
                },
                structureFixed: {
                    type: 'boolean.switch',
                    displayName: '<<entities.TemplateManage.FixedPageStructure>>',
                    defaultValue: false,
                },
            },
        },
        CustomDesignProductSelector: {
            apiPath: '/loader/admin/products',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                },
                productInfo: {
                    type: 'object.picAndNameAndCode',
                    displayName: '<<productInformation>>',
                },
                goodsMvo: {
                    type: 'object',
                    properties: {
                        productName: {
                            type: 'string.tip',
                            displayName: '<<components.Products.name>>',
                        },
                        name: {
                            type: 'string',
                            displayName: '<<goodName>>',
                        },
                        subsiteName: {
                            type: 'string',
                            displayName: '<<subsiteName>>',
                        },
                        merchantName: {
                            type: 'string',
                            displayName: '<<merchantName>>',
                        },
                        spuCode: {
                            type: 'string',
                            displayName: '<<spbh>>',
                        },
                        csku: {
                            type: 'string',
                            displayName: '<<hpbh>>',
                        },
                        stock: {
                            type: 'string',
                            displayName: '<<sjkc>>',
                        },
                        productStyleName: {
                            type: 'string',
                            displayName: '<<styleGroupInfo>>',
                        },
                    },
                },
                barcode: {
                    type: 'string',
                    displayName: '<<spewm>>',
                },
                brand: {
                    type: 'string',
                    displayName: '<<brand>>',
                },
                brandName: {
                    type: 'string',
                    displayName: '<<brandName>>',
                },
                productType: {
                    type: 'string.options.select',
                    displayName: '<<productType>>',
                    options: [
                        { id: 'ALL', name: '<<allProduct>>' },
                        { id: 'NORMAL', name: '<<ptsp>>' },
                        { id: 'COMBINE', name: '<<zhsp>>' },
                        { id: 'WEIGHT', name: '<<czsp>>' },
                    ],
                },
                supplyChain: {
                    type: 'string.options.select',
                    displayName: '<<supplierName>>',
                    options: [
                        { id: 'ERP', name: 'ERP' },
                        { id: 'THIRD_PARTY', name: '<<sfgyl>>' },
                    ],
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                tradeType: {
                    type: 'string.options.select',
                    displayName: '<<tradeType>>',
                    options: [
                        { id: 'DOMESTIC_TRADE', name: '<<domesticTrade>>' },
                        { id: 'DUTY_PAID_IMPORT', name: '<<dutyPaidImport>>' },
                        { id: 'BONDED_DIRECT_SUPPLY', name: '<<bondedDirectSupply>>' },
                        { id: 'HONG_KONG_DIRECT', name: '<<hongkongMail>>' },
                        { id: 'OVERSEAS_DIRECT', name: '<<overseasDirectMail>>' },
                    ],
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<components.Products.name>>',
                    controlConfig: {
                        placeholder: '<<qsrgjzss>>',
                    },
                },
                code: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<productCode>>',
                    controlConfig: {
                        displayName: '<<productCodeRequired>>',
                    },
                },
                keywords: {
                    type: 'string',
                    displayName: '<<keywords>>',
                    controlConfig: {
                        placeholder: '<<keywordsPlaceholder>>',
                        style: { width: 330 },
                    },
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                merchantId: {
                    type: 'string.filterOptions.autoComplete',
                    displayName: '<<merchant>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                        //专柜默认正序排序
                        params: {
                            sequence: 'code',
                            order: 'asc',
                        },
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                activityProductTypes: {
                    type: 'string.options.select',
                    displayName: '<<productType>>',
                    defaultValue: 'ALL',
                    options: [
                        {
                            id: 'ALL',
                            name: '<<allProduct>>',
                        },
                        {
                            id: 'NORMAL',
                            name: '<<ptsp>>',
                        },
                        {
                            id: 'COMBINE',
                            name: '<<zhsp>>',
                        },
                    ],
                    controlConfig: {
                        style: {
                            width: 200,
                        },
                    },
                },
                supplyChain: {
                    type: 'string.options.select',
                    displayName: '<<supplierName>>',
                    options: [
                        { id: 'ERP', name: 'ERP' },
                        { id: 'THIRD_PARTY', name: '<<sfgyl>>' },
                    ],
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                tradeType: {
                    type: 'string.options.select',
                    displayName: '<<tradeType>>',
                    options: [
                        { id: 'DOMESTIC_TRADE', name: '<<domesticTrade>>' },
                        { id: 'DUTY_PAID_IMPORT', name: '<<dutyPaidImport>>' },
                        { id: 'BONDED_DIRECT_SUPPLY', name: '<<bondedDirectSupply>>' },
                        { id: 'HONG_KONG_DIRECT', name: '<<hongkongMail>>' },
                        { id: 'OVERSEAS_DIRECT', name: '<<overseasDirectMail>>' },
                    ],
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
            },
        },
        CustomDesignNormalProductSelector: {
            apiPath: `/loader/admin/normal-products`,
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                },
                code: {
                    type: 'string',
                    displayName: '<<productCode>>',
                },
                productType: {
                    type: 'string.options.select',
                    displayName: '商品类型',
                    options: [
                        { id: 'ALL', name: '全部商品' },
                        { id: 'NORMAL', name: '普通商品' },
                        { id: 'COMBINE', name: '组合商品' },
                        { id: 'VIRTUAL_COUPON', name: '虚拟商品' },
                        { id: 'WEIGHT', name: '<<czsp>>' },
                    ],
                },
                goodsMvo: {
                    type: 'object',
                    properties: {
                        productName: {
                            type: 'string.tip',
                            displayName: '<<components.Products.name>>',
                        },
                        name: {
                            type: 'string',
                            displayName: '<<goodName>>',
                        },
                        subsiteName: {
                            type: 'string',
                            displayName: '<<subsiteName>>',
                        },
                        merchantName: {
                            type: 'string',
                            displayName: '<<merchantName>>',
                        },
                        spuCode: {
                            type: 'string',
                            displayName: '商品编号',
                        },
                        csku: {
                            type: 'string',
                            displayName: '货品编号',
                        },
                        productType: {
                            type: 'string.options.select',
                            displayName: '商品类型',
                            options: [
                                { id: 'ALL', name: '全部商品' },
                                { id: 'NORMAL', name: '普通商品' },
                                { id: 'COMBINE', name: '组合商品' },
                                { id: 'WEIGHT', name: '<<czsp>>' },
                            ],
                        },
                    },
                },
                barcode: {
                    type: 'string',
                    displayName: '<<productCode>>',
                },
                brand: {
                    type: 'string',
                    displayName: '<<brand>>',
                },
                productInfo: {
                    type: 'object.picAndNameAndCode',
                    displayName: '<<productInformation>>',
                },
                brandVo: {
                    type: 'object',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<brandName>>',
                        },
                    },
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<components.Products.name>>',
                    controlConfig: {
                        placeholder: '<<qsrgjzss>>',
                    },
                },
                code: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<productCode>>',
                    controlConfig: {
                        displayName: '<<productCodeRequired>>',
                    },
                },
                productType: {
                    type: 'string.options.select',
                    displayName: '商品类型',
                    controlConfig: {
                        style: {
                            width: 150,
                        },
                    },
                    options: [
                        { id: 'NORMAL', name: '普通商品' },
                        { id: 'COMBINE', name: '组合商品' },
                        { id: 'VIRTUAL_COUPON', name: '虚拟商品' },
                        { id: 'WEIGHT', name: '<<czsp>>' },
                    ],
                },
                keywords: {
                    type: 'string',
                    displayName: '关键词搜索',
                    controlConfig: {
                        placeholder: '搜索商品名称/编码,品牌名称进行查询',
                        style: { width: 300 },
                    },
                },
            },
        },
        CustomDesignVirtualProductSelector: {
            apiPath: `/loader/admin/products?product_type=VIRTUAL_COUPON`,
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                },
                goodsMvo: {
                    type: 'object',
                    properties: {
                        productName: {
                            type: 'string.tip',
                            displayName: '<<components.Products.name>>',
                        },
                        name: {
                            type: 'string',
                            displayName: '<<goodName>>',
                        },
                        subsiteName: {
                            type: 'string',
                            displayName: '<<subsiteName>>',
                        },
                        merchantName: {
                            type: 'string',
                            displayName: '<<merchantName>>',
                        },
                        spuCode: {
                            type: 'string',
                            displayName: '商品编号',
                        },
                        csku: {
                            type: 'string',
                            displayName: '货品编号',
                        },
                    },
                },
                barcode: {
                    type: 'string',
                    displayName: '<<spewm>>',
                },
                brand: {
                    type: 'string',
                    displayName: '<<brand>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<components.Products.name>>',
                    controlConfig: {
                        placeholder: '<<qsrgjzss>>',
                    },
                },
            },
        },
        CustomDesignGroupProductSelector: {
            apiPath: `/loader/admin/group_buying_rules/goods`,
            properties: {
                productName: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                },
                productType: {
                    type: 'string.options.select',
                    displayName: '<<productType>>',
                    options: [
                        {
                            id: 'NORMAL',
                            name: '<<swsp>>',
                        },
                        {
                            id: 'VIRTUAL_COUPON',
                            name: '<<xnsp>>',
                        },
                    ],
                },
                productId: {
                    type: 'string',
                },
                goodsId: {
                    type: 'string',
                    displayName: '<<hpbh>>',
                },
                productInfo: {
                    type: 'object.picAndNameAndCode',
                    displayName: '商品信息',
                },
                executeStatus: {
                    type: 'string.options.radio',
                    displayName: '活动状态',
                    options: [
                        { id: 'NOT_STARTED', name: '未开始' },
                        { id: 'STARTED', name: '进行中' },
                        { id: 'COMPLETE', name: '已结束' },
                    ],
                },
                ruleName: {
                    type: 'string',
                    displayName: '活动名称',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '门店名称',
                },
            },
            filters: {
                productName: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<components.Products.name>>',
                    controlConfig: {
                        placeholder: '<<productNameRequired>>',
                    },
                },
                productType: {
                    type: 'string.options.select',
                    displayName: '<<productType>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<allProduct>>' },
                        { id: 'NORMAL', name: '<<swsp>>' },
                        { id: 'VIRTUAL_COUPON', name: '<<xnsp>>' },
                    ],
                },
                keywords: {
                    type: 'string',
                    displayName: '关键词搜索',
                    controlConfig: {
                        placeholder: '搜索商品名称/编码,品牌名称进行查询',
                        style: { width: 300 },
                    },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '活动状态',
                    options: [
                        {
                            id: 'NOT_STARTED',
                            name: '未开始',
                        },
                        {
                            id: 'STARTED',
                            name: '进行中',
                        },
                        {
                            id: 'COMPLETE',
                            name: '已结束',
                        },
                    ],
                    controlConfig: {
                        placeholder: '请选择',
                        style: { width: 150 },
                    },
                },
            },
        },
        MaotaiPreSaleProductSelector: {
            apiPath: `/loader/admin/maotai-pre-sale/goods`,
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                },

                barCode: {
                    type: 'string',
                    displayName: '<<productCode>>',
                },
                goodsCode: {
                    type: 'string',
                    displayName: '<<goodCode>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<components.Products.name>>',
                    controlConfig: {
                        placeholder: '<<productNameRequired>>',
                    },
                },
                code: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<productCode>>',
                    controlConfig: {
                        displayName: '<<productCodeRequired>>',
                    },
                },
            },
        },
        /** 专题选择 */
        CustomDesignTopicSelector: {
            apiPath: '/loader/admin/topics/options',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<createTimeStr>>',
                },
                channels: {
                    type: 'array.channel.checkbox',
                    displayName: '<<syqd>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<common.keywords>>',
                },
            },
        },
        /** 新专题 */
        NewCustomDesignTopicSelector: {
            apiPath: '/loader/admin/pages/options',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<createTimeStr>>',
                },
                channels: {
                    type: 'array.channel.checkbox',
                    displayName: '<<syqd>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<common.keywords>>',
                },
                channels: {
                    type: 'string.options.select',
                    displayName: '<<syqd>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        {
                            id: ChannelType.Wechat,
                            name: '<<wxorapp>>',
                        },
                        {
                            id: ChannelType.Alipay,
                            name: '<<zfbxcx>>',
                        },
                    ],
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
            },
        },
        /** 话题选择 */
        CustomContentTopicEntity: {
            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
            apiPath: '/loader/admin/topics',
            properties: {
                name: {
                    type: 'string',
                    displayName: '话题名称',
                },
                plateNames: {
                    type: 'string',
                    displayName: '<<ssbk_1>>',
                },
                status: {
                    type: 'string.options.radio',
                    displayName: '<<enabledStatus>>',
                    options: [
                        { id: 'ENABLE', name: '启用' },
                        { id: 'DISABLE', name: '禁用' },
                    ],
                },
            },
            filters: {
                topicName: {
                    type: 'string',
                    displayName: '话题名称',
                },
            },
        },
        /** 选择话题激励 */
        CustomTopicPromotionEncourageSelector: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/admin/topic_activities/topicList',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
            },
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
        },
        /** 笔记列表查询 */
        CustomSelectContentNoteSelector: {
            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
            apiPath: '/loader/admin/notes',
            properties: {
                title: {
                    type: 'string.popover',
                    displayName: '<<bjbt>>',
                },
                authorNickName: {
                    type: 'string',
                    displayName: '<<zznc>>',
                },
                authorMobile: {
                    type: 'string',
                    displayName: '<<authorMobile>>',
                },
                contentType: {
                    type: 'string.options.select',
                    displayName: '<<bjlx>>',
                    options: [
                        {
                            id: 'PICTURE',
                            name: '<<tpbj>>',
                        },
                        {
                            id: 'VIDEO',
                            name: '<<spbj>>',
                        },
                    ],
                },
                publishTime: {
                    type: 'string',
                    displayName: '<<releaseTime>>',
                },
                detailStatus: {
                    type: 'string.options.select',
                    displayName: '<<bjzt>>',
                    options: CONTENT_NOTE_STATUS,
                },
                auditStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<auditStatus>>',
                },
            },
            filters: {
                authorMobile: {
                    type: 'string',
                    displayName: '<<authorMobile>>',
                },
                title: {
                    type: 'string',
                    displayName: '<<bjbt>>',
                },
                contentType: {
                    type: 'string.options.select',
                    displayName: '<<bjlx>>',
                    options: [
                        {
                            id: 'PICTURE',
                            name: '<<tpbj>>',
                        },
                        {
                            id: 'VIDEO',
                            name: '<<spbj>>',
                        },
                    ],
                },
                detailStatus: {
                    type: 'string.options.select',
                    displayName: '<<bjzt>>',
                    options: CONTENT_NOTE_STATUS,
                },
                auditStatus: {
                    type: 'string.options.select',
                    displayName: '<<auditStatus>>',
                    options: [
                        {
                            id: 'WAIT',
                            name: '<<wsh>>',
                        },
                        {
                            id: 'SUCCESS',
                            name: '<<shcg>>',
                        },
                        {
                            id: 'FAIL',
                            name: '<<shsb>>',
                        },
                    ],
                },
            },
        },
        /** 促销活动列表查询 */
        CustomDesignPromotionSelector: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/promotion/find',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
            },
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                fromTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                thruTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
            },
        },
        CustomDesignCouponSelector: {
            apiPath: '/loader/admin/coupon_promotions',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<couponName>>',
                },
                typeName: {
                    type: 'string',
                    displayName: '<<couponType>>',
                },
                sourceDesc: {
                    type: 'string',
                    displayName: '<<setCouponType>>',
                },
                startTime: {
                    type: 'string',
                    displayName: '生效开始时间',
                },
                endTime: {
                    type: 'string',
                    displayName: '生效结束时间',
                },
                code: {
                    type: 'string',
                    displayName: '券码',
                },
                consumeChannelDescription: {
                    type: 'string',
                    displayName: '核销渠道',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<common.keywords>>',
                },
            },
        },
        CustomDesignCouponPackageSelector: {
            apiPath: '/loader/admin/coupon_packages/select',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<couponGiftName>>',
                },
                startTime: {
                    type: 'string',
                    displayName: '生效开始时间',
                },
                endTime: {
                    type: 'string',
                    displayName: '生效结束时间',
                },
                executeStatus: {
                    type: 'string.options.radio',
                    displayName: '<<activityStatus>>',
                    options: [
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<common.keywords>>',
                },
            },
        },
        CustomDesignLiveInfoSelector: {
            apiPath: '/admin/mini_program/live_players',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                roomid: {
                    type: 'string',
                    displayName: '<<hd>>id',
                },
                startTime: {
                    type: 'string',
                    displayName: '<<kbsj>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<common.keywords>>',
                },
            },
        },
        CustomDesignCategorySelector: {
            apiPath: '/loader/admin/category',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<mc>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<common.keywords>>',
                },
            },
        },
        CustomDesignVirtualCategorySelector: {
            apiPath: '/loader/admin/virtual_category',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<mc>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<common.keywords>>',
                },
            },
        },
        CustomDesignCategoryNavSelector: {
            apiPath: '/loader/admin/guide',
            paramsFilter: ['categoryNav'],
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<mc>>',
                },
                level: {
                    type: 'string',
                    displayName: '<<dhcj>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<common.keywords>>',
                },
            },
        },
        CustomDesignCategoryNavProductSelector: {
            apiPath: '/loader/admin/guide',
            paramsFilter: ['categoryNavProduct'],
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<mc>>',
                },
                level: {
                    type: 'string',
                    displayName: '<<dhcj>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<common.keywords>>',
                },
            },
        },
        CustomDesignPresaleSelector: {
            apiPath: '/loader/admin/presales/options',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<common.keywords>>',
                },
            },
        },
        CustomDesignInviteSelector: {
            apiPath: '/loader/admin/invite',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.options.radio',
                    displayName: '<<activityStatus>>',
                    options: [
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<activityName>>',
                    style: { width: 130 },
                },
                subsiteId: {
                    type: 'string.options.select',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        style: { width: 160 },
                    },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<belongSubsite>>',
                    options: [
                        {
                            id: 'NOT_STARTED',
                            name: '<<notStarted>>',
                        },
                        {
                            id: 'STARTED',
                            name: '<<inprogress>>',
                        },
                        {
                            id: 'COMPLETE',
                            name: '<<end>>',
                        },
                    ],
                    controlConfig: {
                        style: { width: 100 },
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<ksjssj>>',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            },
        },
        CustomDesignLotteryCodeSelector: {
            apiPath: '/loader/admin/lottery_code/options',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<jpmc>>',
                },
                startTime: {
                    type: 'string',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
            filters: {
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: [
                        {
                            id: 'ALL',
                            name: '<<all>>',
                        },
                        {
                            id: 'NOT_STARTED',
                            name: '<<notStarted>>',
                        },
                        {
                            id: 'STARTED',
                            name: '<<inprogress>>',
                        },
                        {
                            id: 'COMPLETE',
                            name: '<<end>>',
                        },
                    ],
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<jpmc>>',
                },
            },
        },
        CustomDesignScratchCardSelector: {
            apiPath: '/loader/admin/scratch_card/options',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<jpmc>>',
                },
                startTime: {
                    type: 'string',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
            filters: {
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: [
                        {
                            id: 'ALL',
                            name: '<<all>>',
                        },
                        {
                            id: 'NOT_STARTED',
                            name: '<<notStarted>>',
                        },
                        {
                            id: 'STARTED',
                            name: '<<inprogress>>',
                        },
                        {
                            id: 'COMPLETE',
                            name: '<<end>>',
                        },
                    ],
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<jpmc>>',
                },
            },
        },
        CustomDesignServiceReservedSelector: {
            apiPath: '/loader/admin/service_reserved_activities',
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                    displayName: '<<fwmc>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<fwzt>>',
                },
                startTime: {
                    type: 'string',
                    displayName: '<<fwksrq>>',
                },
                endTime: {
                    type: 'string',
                    displayName: '<<fwjsrq>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<fwmc>>',
                    controlConfig: { placeholder: '<<qsrhdmc>>' },
                },
                subSiteId: {
                    type: 'string.options.select',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
            },
        },
        CustomDesignSubsiteSelector: {
            apiPath: '/admin/subsites/mine',
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                id: {
                    type: 'string',
                    format: 'title',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<common.keywords>>',
                },
            },
        },
        PlatformPageManageEntity: {
            apiPath: '/loader/admin/platform_home_pages',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<common.keywords>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                    displayName: '<<entities.PageManage.pagesName>>',
                    rules: [
                        { required: true, message: '<<rules.PageManage.nameRequired>>' },
                        { max: 16, message: '<<rules.PageManage.nameLength>>' },
                    ],
                },

                pageName: {
                    type: 'string.popover',
                    displayName: '<<entities.PageManage.pagesName>>',
                },

                enabled: {
                    type: 'boolean.switch',
                    displayName: '<<entities.PageManage.enabled>>',
                },
                template: {
                    type: 'pageTemplate',
                    displayName: '<<entities.PageManage.template>>',
                    source: {
                        apiPath: '/admin/templates',
                        loadOnInit: true,
                    },
                },
                createTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.createTime>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<entities.PageManage.lastedModifyTime>>',
                },
            },
        },
        DesignPlatformGoodsSelector: {
            apiPath: '/admin/goods',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                },
                barcode: {
                    type: 'string',
                    displayName: '商品编号',
                },
                goodsMvo: {
                    type: 'object',
                    properties: {
                        subsiteName: {
                            type: 'string',
                            displayName: '<<subsites>>',
                        },
                        merchantName: {
                            type: 'string',
                            displayName: '<<merchant>>',
                        },
                        csku: {
                            type: 'string',
                            displayName: '货号',
                        },
                    },
                },
            },
            filters: {
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsites>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchant>>',
                },
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<common.keywords>>',
                },
                codes: {
                    type: 'string',
                    displayName: '货号',
                },
            },
        },
        CustomDesignProductStyleSelector: {
            apiPath: '/loader/admin/product_style',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                },
                productInfo: {
                    type: 'object.picAndNameAndCode',
                    displayName: '<<productInformation>>',
                },
                productType: {
                    type: 'string.options.select',
                    displayName: '商品类型',
                    options: [
                        { id: 'ALL', name: '全部商品' },
                        { id: 'NORMAL', name: '普通商品' },
                        { id: 'COMBINE', name: '组合商品' },
                        { id: 'WEIGHT', name: '<<czsp>>' },
                    ],
                },
                goodsMvo: {
                    type: 'object',
                    properties: {
                        productName: {
                            type: 'string.tip',
                            displayName: '<<components.Products.name>>',
                        },
                        name: {
                            type: 'string',
                            displayName: '<<goodName>>',
                        },
                        subsiteName: {
                            type: 'string',
                            displayName: '<<subsiteName>>',
                        },
                        merchantName: {
                            type: 'string',
                            displayName: '<<merchantName>>',
                        },
                        spuCode: {
                            type: 'string',
                            displayName: '商品编号',
                        },
                        csku: {
                            type: 'string',
                            displayName: '货品编号',
                        },
                    },
                },
                barcode: {
                    type: 'string',
                    displayName: '<<spewm>>',
                },
                brand: {
                    type: 'string',
                    displayName: '<<brand>>',
                },
                brandName: {
                    type: 'string',
                    displayName: '<<brandName>>',
                },
            },
            filters: {
                keywords: {
                    type: 'string',
                    displayName: '<<keywords>>',
                    controlConfig: {
                        placeholder: '<<keywordsPlaceholder>>',
                        style: { width: 350 },
                    },
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                merchantId: {
                    type: 'string.filterOptions.autoComplete',
                    displayName: '专柜',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                        //专柜默认正序排序
                        params: {
                            sequence: 'code',
                            order: 'asc',
                        },
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<components.Products.name>>',
                    controlConfig: {
                        placeholder: '<<qsrgjzss>>',
                    },
                },
                code: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<productCode>>',
                    controlConfig: {
                        displayName: '<<productCodeRequired>>',
                    },
                },
                activityProductTypes: {
                    type: 'string.options.select',
                    displayName: '商品类型',
                    defaultValue: 'ALL',
                    options: [
                        {
                            id: 'ALL',
                            name: '全部商品',
                        },
                        {
                            id: 'NORMAL',
                            name: '普通商品',
                        },
                        {
                            id: 'COMBINE',
                            name: '组合商品',
                        },
                    ],
                    controlConfig: {
                        style: {
                            width: 170,
                        },
                    },
                },
            },
        },
        BrandsSelector: {
            apiPath: '/admin/brands',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '品牌名称（中）',
                },
                nameEn: {
                    type: 'string',
                    displayName: '品牌名称（英）',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<brandName>>',
                    controlConfig: {
                        placeholder: '<<qsrgjzss>>',
                    },
                },
            },
        },
        MerchantBigCodeSelector: {
            apiPath: '/loader/admin/merchant_big_codes',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                code: {
                    type: 'string',
                    displayName: '<<bigCode>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<bigCodeName>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsites>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchant>>',
                },
            },
            filters: {
                bigCode: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '商家码编码',
                    controlConfig: {
                        placeholder: '请输入商家码编码',
                        style: {
                            width: 170,
                        },
                    },
                },
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<bigCodeName>>',
                    controlConfig: {
                        placeholder: '请输入商家码名称',
                        style: {
                            width: 170,
                        },
                    },
                },
            },
        },
        TagSelector: {
            apiPath: '/loader/admin/merchantTag',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<tagName>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<tagName>>',
                    controlConfig: {
                        placeholder: '<<qsrgjzss>>',
                    },
                },
            },
        },
        CouponSelector: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
            apiPath: '/loader/admin/coupon_rules',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                code: {
                    type: 'string',
                    displayName: '<<qm_1>>',
                },
                typeName: {
                    type: 'string',
                    displayName: '<<couponType>>',
                },
                sourceDesc: {
                    type: 'string',
                    displayName: '<<setCouponType>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<couponName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<couponStartTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<couponEndTime>>',
                },
                consumeChannelDescription: {
                    type: 'string',
                    displayName: '<<usedChannelLimit>>',
                },
                amountValue: {
                    type: 'string',
                    displayName: '<<mz_1>>',
                },
                consumeEffectivePeriodRule: {
                    type: 'object',
                    properties: {
                        dynamicEffectivePeriod: {
                            type: 'string',
                            displayName: '<<dynamicEffectivePeriodDay>>',
                        },
                    },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<couponName>>',
                    controlConfig: {
                        placeholder: '<<qsryhqmc>>',
                    },
                },
                couponType: {
                    type: 'string.options.select',
                    displayName: '<<qlx>>',
                    options: [
                        { id: 'CASH', name: '<<cashCoupon>>' },
                        { id: 'GIFT', name: '<<gift>>' },
                        { id: 'FREIGHT', name: '<<freight>>' },
                        { id: 'PARKING', name: '<<parking>>' },
                        { id: 'LOTTERY', name: '<<lottery>>' },
                        { id: 'BALANCE', name: '<<balance>>' },
                        { id: 'EFUTURE_CASH', name: '<<efutureCash>>' },
                        { id: 'EFUTURE_BALANCE', name: '<<efutureBalance>>' },
                        { id: 'BFPLAT_COUPON', name: '<<bfplat>>' },
                    ],
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                    },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<executeStatus>>',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
            },
        },
        NewCouponSelector: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
            apiPath: '/loader/admin/coupon_rules',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                code: {
                    type: 'string',
                    displayName: '自定义券码',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<couponName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<couponStartTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<couponEndTime>>',
                },
                amountValue: {
                    type: 'string',
                    displayName: '<<mz_1>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '状态',
                    options: [
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<couponName>>',
                    controlConfig: {
                        placeholder: '<<qsryhqmc>>',
                    },
                },
                multiExecuteStatus: {
                    type: 'array.options.autoComplete',
                    displayName: '券规则状态',
                    options: [
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                    controlConfig: { style: { width: 265 } },
                },
            },
        },
        ShopSelector: {
            apiPath: '/loader/subsites/mine/merchants',
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API/admin`,
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                subSiteId: {
                    type: 'string',
                    format: 'title',
                },
                subSiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                merchantId: {
                    type: 'string',
                    format: 'title',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                merchantType: {
                    type: 'string',
                    displayName: '<<merchantType>>',
                },
                merchantStatus: {
                    type: 'string',
                    displayName: '<<businessStatus>>',
                },
                code: {
                    type: 'string',
                    displayName: '<<merchantCode>>',
                },
                merchantCategory: {
                    type: 'string',
                    displayName: '专柜分类',
                },
            },
            filters: {
                subSiteName: {
                    type: 'string.options.select',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { style: { width: 180 } },
                },
                merchantType: {
                    type: 'string.options.autoComplete',
                    displayName: '<<merchantType>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/template/merchant_type',
                    },
                    controlConfig: {
                        style: {
                            width: 180,
                        },
                        placeholder: '<<inputPlease>>',
                    },
                },
                merchantTagId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<merchantTag>>',
                    options: [],
                    source: {
                        apiPath: '/admin/merchant_tags?page=1&per_page=20',
                        dataType: 'paging',
                    },
                    controlConfig: {
                        style: {
                            width: 180,
                        },
                        placeholder: '<<inputPlease>>',
                    },
                },
                merchantName: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<merchantName>>',
                },
            },
        },
        DeliveryShopSelector: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: '/loader/admin/delivery_config/merchants',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                subSiteId: {
                    type: 'string',
                    format: 'title',
                },
                subSiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                merchantId: {
                    type: 'string',
                    format: 'title',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                merchantType: {
                    type: 'string',
                    displayName: '<<merchantType>>',
                },
                merchantStatus: {
                    type: 'string',
                    displayName: '<<businessStatus>>',
                },
                code: {
                    type: 'string',
                    displayName: '<<merchantCode>>',
                    sorter: true,
                },
                merchantCategory: {
                    type: 'string',
                    displayName: '专柜分类',
                },
                merchantCode: {
                    type: 'string',
                    displayName: '<<merchantCode>>',
                },
            },
            filters: {
                subSiteId: {
                    type: 'string.options.select',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { style: { width: 180 } },
                },
                merchantType: {
                    type: 'string.options.autoComplete',
                    displayName: '<<merchantType>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/template/merchant_type',
                    },
                    controlConfig: {
                        style: {
                            width: 180,
                        },
                        placeholder: '<<inputPlease>>',
                    },
                },
                merchantTagId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<merchantTag>>',
                    options: [],
                    source: {
                        apiPath: '/admin/merchant_tags?page=1&per_page=20',
                        dataType: 'paging',
                    },
                    controlConfig: {
                        style: {
                            width: 180,
                        },
                        placeholder: '<<inputPlease>>',
                    },
                },
                merchantName: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<merchantName>>',
                },
            },
        },
        CreditProductsSelector: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/credit_products',
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<components.Products.name>>',
                    controlConfig: {
                        placeholder: '<<spmc>>',
                    },
                },
                creditEshopProductType: {
                    type: 'string.options.select',
                    displayName: '<<productType>>',
                    options: [
                        { id: 'POINTS', name: '<<jfsp>>' },
                        { id: 'VIRTUAL_COUPON', name: '<<xnsp>>' },
                    ],
                    controlConfig: {
                        placeholder: '请选择',
                        style: { width: 150 },
                    },
                },
                keywords: {
                    type: 'string',
                    displayName: '关键词搜索',
                    controlConfig: {
                        placeholder: '搜索商品名称/编码,品牌名称进行查询',
                        style: { width: 300 },
                    },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '活动状态',
                    options: [
                        {
                            id: 'NOT_STARTED',
                            name: '未开始',
                        },
                        {
                            id: 'STARTED',
                            name: '进行中',
                        },
                        {
                            id: 'COMPLETE',
                            name: '已结束',
                        },
                    ],
                    controlConfig: {
                        placeholder: '请选择',
                        style: { width: 150 },
                    },
                },
            },
            properties: {
                ruleId: {
                    type: 'string',
                },
                ruleName: {
                    type: 'string',
                    displayName: '活动名称',
                },
                productInfo: {
                    type: 'object.picAndNameAndCode',
                    displayName: '商品信息',
                },
                executeStatus: {
                    type: 'string.options.radio',
                    displayName: '活动状态',
                    options: [
                        { id: 'NOT_STARTED', name: '未开始' },
                        { id: 'STARTED', name: '进行中' },
                        { id: 'COMPLETE', name: '已结束' },
                    ],
                },
                name: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                },
                subsite: {
                    type: 'object',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<subsiteName>>',
                        },
                    },
                },
                creditEshopProductType: {
                    type: 'string.options.select',
                    displayName: '<<productType>>',
                    options: [
                        { id: 'POINTS', name: '<<jfsp>>' },
                        { id: 'VIRTUAL_COUPON', name: '<<xnsp>>' },
                    ],
                },
            },
        },
        OnlineCouponSelector: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/admin/v2/coupon_definitions',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                code: {
                    type: 'string',
                    displayName: '<<couponBaCode>>',
                },
                typeDes: {
                    type: 'string',
                    displayName: '<<couponType>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<couponName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<couponStartTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<couponEndTime>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<couponName>>',
                    controlConfig: {
                        placeholder: '<<qsryhqmc>>',
                    },
                },
            },
        },
        CouponPackageSelector: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
            apiPath: '/admin/coupon_package_rules',
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                    displayName: '<<couponGiftName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<giftsxTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<lbjssj>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<couponGiftName>>',
                    controlConfig: {
                        placeholder: '<<qsryhqlbmc>>',
                    },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<executeStatus>>',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
            },
        },
        DevilerPointTagSelector: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/activity_tags',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<tagName>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<tagStatus>>',
                    options: POINT_TAG_STATUS,
                },
                sequence: {
                    type: 'string',
                    displayName: '<<sequence>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<tagName>>',
                    controlConfig: {
                        placeholder: '<<srbqmc>>',
                    },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<tagStatus>>',
                    controlConfig: {
                        style: {
                            width: 200,
                        },
                    },
                    options: POINT_TAG_STATUS,
                },
            },
        },
        /** 商场导览选择 */
        CustomSelectMerchantGuideSelector: {
            apiPath: '/admin/merchants_guide',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<pageName>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<common.keywords>>',
                },
            },
        },
        CustomDesignVirtualGoodsSelector: {
            apiPath: '/admin/goods?product_types=VIRTUAL_COUPON',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                },
                barcode: {
                    type: 'string',
                    displayName: '<<productCode>>',
                },
                goodsMvo: {
                    type: 'object',
                    properties: {
                        subsiteName: {
                            type: 'string',
                            displayName: '<<subsites>>',
                        },
                        merchantName: {
                            type: 'string',
                            displayName: '<<merchant>>',
                        },
                        csku: {
                            type: 'string',
                            displayName: '<<goodNum>>',
                        },
                    },
                },
            },
            filters: {
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsites>>',
                },
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<common.keywords>>',
                },
            },
        },
        SendOutChannelSelector: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/channels',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<qdmc>>',
                },
                description: {
                    type: 'string',
                    displayName: '<<qdsm>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<qdmc>>',
                    controlConfig: {
                        placeholder: '<<inputPlease>>',
                    },
                },
            },
        },
        MerchantBySubsitesSelector: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/merchant_by_subsites',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                code: {
                    type: 'string',
                    displayName: '<<merchantCode>>',
                },
                type: {
                    type: 'string',
                    displayName: '<<merchantType>>',
                },
                status: {
                    type: 'string',
                    displayName: '<<businessStatus>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<merchantName>>',
                },
            },
        },
        CustomDesignSharingRewardSelector: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/share_page_activities',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.options.radio',
                    displayName: '<<activityStatus>>',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'UN_START', name: '<<notStarted>>' },
                        { id: 'RUNNING', name: '<<inprogress>>' },
                        { id: 'END', name: '<<end>>' },
                    ],
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<activityName>>',
                    style: { width: 130 },
                },
                subsiteIds: {
                    type: 'string.options.select',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        style: { width: 160 },
                    },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'UN_START', name: '<<notStarted>>' },
                        { id: 'RUNNING', name: '<<inprogress>>' },
                        { id: 'END', name: '<<end>>' },
                    ],
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
            },
        },
        CustomDesignPaymentModeSelector: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
            apiPath: '/admin/payment_mode/password_modifiable',
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<zffs>>',
                },
                paymentMode: {
                    type: 'string',
                },
                useMemberPassword: {
                    type: 'string.formatter',
                    displayName: '<<mmxs>>',
                },
            },
        },
        CustomMagicShopMerchants: {
            apiPath: '/loader/admin/merchant_self',
            properties: {
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                merchantCodeQuantity: {
                    type: 'string',
                    displayName: '<<sjmsl>>',
                },
                code: {
                    type: 'string',
                    displayName: '<<merchantCode>>',
                },
                type: {
                    type: 'string.options.select',
                    displayName: '<<merchantType>>',
                    options: [
                        { id: '0', name: '<<selfOperated>>' },
                        { id: '1', name: '<<joint>>' },
                        { id: '2', name: '<<lease>>' },
                        { id: '3', name: '<<league>>' },
                        { id: '4', name: '<<merchantSelf>>' },
                        { id: '99', name: '<<other>>' },
                    ],
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<businessStatus>>',
                    options: [
                        { id: '0', name: '<<subsiteStatusDisable>>' },
                        { id: '1', name: '<<kd_1>>' },
                        { id: '2', name: '<<bd>>' },
                        { id: '3', name: '<<zt_1>>' },
                    ],
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<merchant>>',
                },
            },
        },
    },
};
