import React, { PureComponent, Fragment } from 'react';
import { services } from '@comall-backend-builder/core';
import { Spin, message, notification, Icon, Table, Popover, Button } from 'antd';
import { errorHandle, api, language } from '@comall-backend-builder/core/lib/services';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import classNames from 'classnames';
import { ImageZoom } from './image-zoom';
import './index.less';
import { cloneDeep } from 'lodash';

const prefix = 'selection-product-detail';

interface SelectionProductDetailProps {
    entity: Entity;
    params: any;
}

interface specItem {
    specKey: string;
    specValue: string | null;
    values: Array<valOption>;
}
interface valOption {
    optionValue: string;
    id: string;
    disabled: boolean;
    stock: number;
}
interface skuItem {
    barcode: string;
    costPrice: number;
    marketPrice: null | number;
    id: string | number;
    specList: specItem[];
    stock: number;
    totalSales: number | null;
    tenantAfterSalesRate?: number;
    tenantTotalAfterSales?: number;
    tenantTotalSales: number;
}
interface goodItem {
    brandName: string;
    goodsDetail: string;
    goodsDetailImage: string;
    goodsMainImages: Array<string>;
    id: string;
    goodsName: string;
    originName: string;
    skuList: skuItem[];
    skuOwner: string;
    specList: specItem[];
    spuNo: string;
    subscribeStatus: boolean;
    supplierId: number;
    supplierName: string;
    tradeType: string;
    isExpand: boolean;
    selectedSpecList: Array<string | null>;
    costPrice: number;
    maxCostPrice: number;
    marketPrice: number;
    totalSales: number;
    barcode: string;
}
interface responseSuccess {
    detailsList: goodItem[];
    recommendList: goodItem[];
}

enum tradeTypeVo {
    DOMESTIC_TRADE = 'domesticTrade',
    DUTY_PAID_IMPORT = 'dutyPaidImport',
    BONDED_DIRECT_SUPPLY = 'bondedDirectSupply',
    HONG_KONG_DIRECT = 'hongkongMail',
    OVERSEAS_DIRECT = 'overseasDirectMail',
}
export class SelectionProductDetail extends PureComponent<SelectionProductDetailProps> {
    state = {
        recommendList: [],
        activeMode: 'ALL',
        loading: false,
        isExpandAll: false,
        productList: [],
        activePicIndex: 0,
        showPicList: [],
        allPicList: [],
        picPage: 1,
        activeProduct: {
            barcode: '',
            id: '',
            skuList: [],
            specList: [],
            goodsDetail: '',
            originName: '',
            brandName: '',
        },
        buttonDisabled: false,
    };
    componentDidMount() {
        this.onGetProductDetail();
    }

    onGetProductDetail = async (searchParams: object = {}) => {
        const {
            params: { id },
        } = this.props;
        if (!id) {
            return;
        }
        this.setState({ loading: true });
        try {
            let res: responseSuccess = await services.api.get(
                {
                    spuNo: id,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                    apiPath: `/admin/goods/by_spu_no`,
                }
            );
            if (res) {
                res.detailsList =
                    res?.detailsList?.map((good: goodItem, index: number) => {
                        return {
                            ...good,
                            id: good.spuNo + index,
                            ...this.getProductPrices(good.skuList),
                            isExpand: true,
                        };
                    }) || [];
                res.recommendList =
                    res?.recommendList.map((good: goodItem, index: number) => {
                        return {
                            ...good,
                            id: good.spuNo + index,
                            ...this.getProductPrices(good.skuList),
                            isExpand: false,
                        };
                    }) || [];
                this.setState(
                    {
                        productList: res.detailsList,
                        recommendList: res.recommendList,
                        loading: false,
                        activeProduct: res.detailsList?.length ? res.detailsList[0] : [],
                        allPicList: res.detailsList?.length
                            ? res.detailsList[0].goodsMainImages
                            : [],
                    },
                    async () => {
                        this.getShowProductPics();
                    }
                );
            }
        } catch (error) {
            this.setState({ loading: false });
            throw error;
        }
    };
    private onClickExpand = (event: any, operateType: string, product: goodItem | null) => {
        if (event) {
            event.stopPropagation();
        }
        const { recommendList, isExpandAll } = this.state;
        let newList: goodItem[] = cloneDeep(recommendList);
        if (operateType === 'all') {
            newList =
                recommendList?.map((item: goodItem) => {
                    return {
                        ...item,
                        isExpand: isExpandAll ? false : true,
                    };
                }) || [];
            this.setState({ recommendList: newList, isExpandAll: !isExpandAll });
            return;
        }
        if (product && product?.id) {
            const activeProduct: any = newList.find((p: goodItem) => p.id === product.id);
            let isExpandAll: boolean = false;
            if (activeProduct) {
                activeProduct.isExpand = !activeProduct.isExpand;
            }
            const isExpandAllLens = newList.filter((p: goodItem) => p.isExpand);
            if (isExpandAllLens?.length === newList?.length) {
                isExpandAll = true;
            }
            this.setState({ recommendList: newList, isExpandAll });
        }
    };
    private onClickProduct = (product: goodItem) => {
        const { activeProduct } = this.state;
        if (product.id === activeProduct.id) {
            return;
        }
        this.setState(
            {
                activeProduct: product,
                picPage: 1,
                activePicIndex: 0,
                allPicList: product?.goodsMainImages || [],
            },
            () => {
                this.getShowProductPics();
            }
        );
    };
    onClickSubscribe = (event: any, product: goodItem, productType: string) => {
        const { productList, recommendList, buttonDisabled } = this.state;
        const newList =
            productType === 'product' ? cloneDeep(productList) : cloneDeep(recommendList);
        if (event) {
            event.stopPropagation();
        }
        const service = product.subscribeStatus ? 'put' : 'post';
        if (
            !product.subscribeStatus &&
            (product.tradeType === language.getText(tradeTypeVo.HONG_KONG_DIRECT) ||
                product.tradeType === language.getText(tradeTypeVo.OVERSEAS_DIRECT))
        ) {
            message.warning(language.getText('notSubscription'));
            return;
        }
        // 防止按钮连续点击
        if (!buttonDisabled) {
            this.setState({
                buttonDisabled: true,
            });
            api[service](
                {
                    spuNo: product.spuNo,
                    skuOwner: product.skuOwner,
                    supplierId: product.supplierId,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                    apiPath: '/admin/subscribe_goods',
                }
            )
                .then(() => {
                    const config = {
                        message: product.subscribeStatus
                            ? language.getText('cancelSubscriptionSuc')
                            : language.getText('subscriptionSuccess'),
                        description: '',
                        duration: 1,
                    };
                    notification.success(config);
                    const active: any = newList.find((p: goodItem) => p.id === product.id);
                    if (active) {
                        active.subscribeStatus = !product.subscribeStatus;
                    }
                    if (productType === 'product') {
                        this.setState({ productList: newList });
                    } else {
                        this.setState({ recommendList: newList });
                    }
                })
                .catch(errorHandle);
            setTimeout(() => {
                this.setState({
                    buttonDisabled: false,
                });
            }, 1000);
        }
    };

    private getProductPrices = (skuList: skuItem[]) => {
        let prices: Array<number> = [];
        let newSkuList =
            skuList?.map((sku: skuItem, index: number) => {
                prices.push(sku.costPrice);
                return {
                    ...sku,
                    id: index,
                };
            }) || [];
        let max = Math.max(...prices);
        let min = Math.min(...prices);
        let costPrice2 = max > min ? max : 0;
        return {
            skuList: newSkuList,
            costPrice: min,
            maxCostPrice: costPrice2,
        };
    };
    private getShowProductPics = () => {
        const { picPage, allPicList } = this.state;
        let showList = cloneDeep(allPicList);
        const index = picPage * 5 - 5;
        const endIndex =
            picPage > 1 && allPicList.length - 1 > picPage * 5 ? allPicList.length : picPage * 5;
        if (allPicList?.length > 5) {
            showList = showList.slice(index, endIndex);
        }
        this.setState({ showPicList: showList });
    };

    onGetImg = (item: string) => {
        const { allPicList } = this.state;
        const index = allPicList.findIndex((img) => img === item);
        this.setState({ activePicIndex: index });
    };
    getPicts = (operateType: string) => {
        const { picPage, allPicList } = this.state;
        const allLength = picPage * 5;
        if (
            (operateType === 'reduce' && picPage === 1) ||
            (operateType === 'add' && allLength > allPicList?.length)
        ) {
            message.warning(language.getText('notMore'));
            return;
        }
        const newPage = operateType === 'add' ? picPage + 1 : picPage - 1;
        this.setState({ picPage: newPage }, () => {
            this.getShowProductPics();
        });
    };
    getLogo = (product: goodItem) => {
        let logo = '';
        let supplierId = product.supplierId;
        switch (supplierId) {
            case 1:
                logo = require('../images/hn_logo.png');
                break;
            case 2:
                logo = require('../images/op_logo.jpg');
                break;
            case 3:
                logo = require('../images/xy_logo.jpg');
                break;
        }
        return logo;
    };

    //复制条码
    private onCopyBarCode = (text: string) => {
        let input = document.createElement('input');
        input.value = text;
        document.body.appendChild(input);
        input.select();
        if (document.execCommand('Copy')) {
            message.success(language.getText('copySuccess'));
        } else {
            message.warning(language.getText('copyError'));
        }
        document.body.removeChild(input);
    };

    private renderProductPics = () => {
        const { activePicIndex, showPicList, allPicList } = this.state;
        const innerClassNames = {
            wrap: `${prefix}__top__pics`,
            preview: `${prefix}__top__pics__preview`,
            movers: `${prefix}__top__pics__movers`,
            left: `${prefix}__top__pics__movers__left`,
            pics: `${prefix}__top__pics__movers__pics`,
            pic: `${prefix}__top__pics__movers__pics-img`,
            active: `${prefix}__top__pics__movers__pics-img__active`,
            right: `${prefix}__top__pics__movers__right`,
        };
        const left = require('../images/icon_left.png');
        const right = require('../images/icon_right.png');
        return (
            <div className={innerClassNames.wrap}>
                <div className={innerClassNames.preview}>
                    <ImageZoom zoom={2.35} lensSize={202}>
                        <img alt="" src={allPicList[activePicIndex]} />
                    </ImageZoom>
                </div>
                <div className={innerClassNames.movers}>
                    {allPicList?.length > 5 && (
                        <div
                            className={innerClassNames.left}
                            onClick={() => this.getPicts('reduce')}
                        >
                            <img alt="" src={left} />
                        </div>
                    )}
                    <div className={innerClassNames.pics}>
                        {showPicList.map((img, index) => (
                            <div
                                key={index}
                                onMouseEnter={this.onGetImg.bind(this, img)}
                                className={classNames(innerClassNames.pic, {
                                    [innerClassNames.active]: img === allPicList[activePicIndex],
                                })}
                            >
                                <img src={img} alt="" />
                            </div>
                        ))}
                    </div>
                    {allPicList?.length > 5 && (
                        <div className={innerClassNames.right} onClick={() => this.getPicts('add')}>
                            <img alt="" src={right} />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    private renderProductInfos = () => {
        const { activeProduct } = this.state;
        return (
            <div className={`${prefix}__top__right__infos`}>
                <div>
                    {language.getText('productArea')}
                    <span>{activeProduct?.originName}</span>
                </div>
                <div>
                    {language.getText('brand')}：<span>{activeProduct?.brandName}</span>
                </div>
            </div>
        );
    };

    private renderProductList = (productType: string) => {
        const { activeProduct, productList, recommendList } = this.state;
        const List = productType === 'product' ? productList : recommendList;
        const innerClassNames = {
            list: `${prefix}__top__right__list`,
            productWrap: `${prefix}__top__right__list__item`,
            pic: `${prefix}__top__right__list__item__pic`,
            infos: `${prefix}__top__right__list__item__others`,
            top: `${prefix}__top__right__list__item__others__top`,
            tag: `${prefix}__top__right__list__item__others__top-tag`,
            name: `${prefix}__top__right__list__item__others__top-name`,
            subscribe: `${prefix}__top__right__list__item__others__top-subscribe`,
            code: `${prefix}__top__right__list__item__code`,
            prices: `${prefix}__top__right__list__item__prices`,
            salesNum: `${prefix}__top__right__list__item__sales`,
            sizes: `${prefix}__top__right__list__item__sizes`,
            size: `${prefix}__top__right__list__item__sizes-item`,
            expandWrap: `${prefix}__top__right__list__item__expand-wrap`,
            expand: `${prefix}__top__right__list__item__expand`,
        };
        // const logo = require('../images/hn_logo.png');
        if (!List || !List?.length) {
            return null;
        }
        return (
            <div className={innerClassNames.list}>
                {List.map((product: goodItem, index) => (
                    <div
                        className={classNames(innerClassNames.productWrap, {
                            [`${prefix}__top__right__list__item__active`]:
                                product.id === activeProduct.id,
                        })}
                        key={index}
                        onClick={(e) => this.onClickProduct(product)}
                    >
                        <div className={innerClassNames.pic}>
                            <img src={this.getLogo(product)} alt="" />
                        </div>
                        <div className={innerClassNames.infos}>
                            <div className={innerClassNames.top}>
                                <div className={innerClassNames.name}>
                                    <span className={innerClassNames.tag}>
                                        {product?.tradeType}
                                    </span>
                                    {product?.goodsName}
                                </div>
                                <div
                                    className={classNames(innerClassNames.subscribe, {
                                        [`${prefix}__top__right__list__item__others__top-subscribe__cancel`]: product.subscribeStatus,
                                    })}
                                    onClick={(e) => this.onClickSubscribe(e, product, productType)}
                                >
                                    {product.subscribeStatus
                                        ? language.getText('cancelSubscription')
                                        : language.getText('productSubscription')}
                                </div>
                            </div>
                            <div className={innerClassNames.code}>
                                <div>
                                    {' '}
                                    {language.getText('productCode')}
                                    <span>{product?.spuNo}</span>
                                </div>
                            </div>
                            <div className={innerClassNames.expandWrap}>
                                <div className={innerClassNames.prices}>
                                    {language.getText('supplyPrice')}
                                    <span>
                                        {services.language.getText('monetaryUnit')}
                                        {product?.costPrice}
                                    </span>
                                    {product.maxCostPrice > 0 && (
                                        <span style={{ marginLeft: 0 }}>
                                            {' '}
                                            - {services.language.getText('monetaryUnit')}
                                            {product?.maxCostPrice}
                                        </span>
                                    )}
                                    <span
                                        style={{ fontSize: '11px', color: '#999', marginLeft: 10 }}
                                    >
                                        {language.getText('taxIncluded')}
                                    </span>
                                </div>
                                {productType === 'recommend' && (
                                    <div
                                        className={innerClassNames.expand}
                                        onClick={(e) => this.onClickExpand(e, 'product', product)}
                                    >
                                        {product.isExpand
                                            ? language.getText('putAway')
                                            : language.getText('launch')}
                                        <Icon
                                            className={classNames(`${prefix}__icon`, {
                                                [`${prefix}__icon__expand`]: !product.isExpand,
                                                [`${prefix}__icon__retract`]: product.isExpand,
                                            })}
                                            type="double-right"
                                        />
                                    </div>
                                )}
                            </div>
                            {product?.isExpand && <div>{this.renderSpecTable(product)}</div>}
                        </div>
                    </div>
                ))}
            </div>
        );
    };
    renderSpecTable = (product: goodItem) => {
        const list: skuItem[] = product.skuList;
        const columns: any = [
            {
                title: language.getText('style'),
                dataIndex: 'style',
                width: 160,
                key: 'style',
                fixed: 'left',
                render: (type: any, record: goodItem) => {
                    let value =
                        record?.specList
                            ?.map((spec: specItem) => {
                                return spec.specValue;
                            })
                            .join(',') || '';
                    if (value && value.length > 30) {
                        let text = value.substring(0, 30) + '....';
                        return (
                            <Popover placement={'right'} content={<div>{value}</div>}>
                                {text}
                            </Popover>
                        );
                    }
                    return <span>{value}</span>;
                },
            },
            {
                title: language.getText('supplyPrice'),
                dataIndex: 'costPrice',
                key: 'costPrice',
                render: (text: string) => {
                    return (
                        <span>
                            {services.language.getText('monetaryUnit')}
                            {text}
                        </span>
                    );
                },
            },
            {
                title: language.getText('canSaleNum'),
                dataIndex: 'stock',
                key: 'stock',
                render: (text: string) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: language.getText('supplyChainSales'),
                dataIndex: 'totalSales',
                key: 'totalSales',
                render: (text: string) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: language.getText('tenantTotalSales'),
                dataIndex: 'tenantTotalSales',
                key: 'tenantTotalSales',
                render: (text: string) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: language.getText('tenantAfterSalesRate'),
                dataIndex: 'tenantAfterSalesRate',
                key: 'tenantAfterSalesRate',
                width: 100,
                render: (text: string, row: skuItem) => {
                    if (row.tenantTotalAfterSales && row.tenantTotalAfterSales > 0) {
                        return (
                            <span>
                                {text}%（{row.tenantTotalAfterSales}）
                            </span>
                        );
                    }
                    return <span>{text}%</span>;
                },
            },
            {
                title: language.getText('barcode'),
                dataIndex: 'barcode',
                key: 'barcode',
                render: (text: string) => {
                    if (!text || text === '0') {
                        return null;
                    }
                    return (
                        <Popover
                            placement={'right'}
                            content={
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span style={{ marginRight: 10 }}>{text}</span>
                                    <Button onClick={() => this.onCopyBarCode(text)} type="link">
                                        {language.getText('copy')}
                                    </Button>
                                </div>
                            }
                        >
                            <Icon type="barcode" />
                        </Popover>
                    );
                },
            },
        ];
        return (
            <Table
                className="jq-product-detail-spce-table"
                pagination={false}
                rowKey={'id'}
                size="small"
                bordered={false}
                dataSource={list}
                columns={columns}
            />
        );
    };
    renderRecommend = () => {
        const { recommendList, isExpandAll } = this.state;
        if (!recommendList || !recommendList?.length) {
            return null;
        }
        return (
            <Fragment>
                <div className={`${prefix}__top__right__recommend`}>
                    <p>{language.getText('commonProductRecom')}</p>
                    <div
                        className={`${prefix}__top__right__types-expand`}
                        onClick={(e) => this.onClickExpand(e, 'all', null)}
                    >
                        {isExpandAll
                            ? language.getText('putAwayAll')
                            : language.getText('launchAll')}
                        <Icon
                            className={classNames(`${prefix}__icon`, {
                                [`${prefix}__icon__expand`]: !isExpandAll,
                                [`${prefix}__icon__retract`]: isExpandAll,
                            })}
                            type="double-right"
                        />
                    </div>
                </div>
                {this.renderProductList('recommend')}
            </Fragment>
        );
    };
    renderDetail = () => {
        const { activeProduct } = this.state;
        if (!activeProduct || !activeProduct?.goodsDetail) {
            return null;
        }
        return (
            <div className={`${prefix}__details`}>
                <div
                    className={`${prefix}__details__wrap`}
                    dangerouslySetInnerHTML={{ __html: activeProduct?.goodsDetail }}
                ></div>
            </div>
        );
    };

    render() {
        const { loading } = this.state;
        return (
            <Spin spinning={loading}>
                <div className={`${prefix}`}>
                    <div className={`${prefix}__top`}>
                        {this.renderProductPics()}
                        <div className={`${prefix}__top__right`}>
                            {this.renderProductInfos()}
                            {this.renderProductList('product')}
                            {this.renderRecommend()}
                        </div>
                    </div>
                    {this.renderDetail()}
                    {/* <div className={`${prefix}__bottom`}>
                        <Button route="goBack">返回</Button>
                    </div> */}
                </div>
            </Spin>
        );
    }
}
