import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { mapValues, map, forEach, get, defaults, debounce } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';

export interface CGBManageEditFormProps extends FormComponentProps {
    entity: Entity;
    fields: any;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    type: any;
    unmountComponent: () => void;
}
class CGBManageEditForm extends PureComponent<CGBManageEditFormProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }
    render() {
        const { entity, fields, wrappedComponentRef, onSubmit, type } = this.props;
        const executeStatus = fields.baseInfo && fields.baseInfo.executeStatus;
        let editFields: any = [
            { property: 'baseInfo.status' },
            { property: 'baseInfo.name' },
            { property: 'baseInfo.dateRange' },
            {
                property: 'rulesInfo.rules',
            },
            {
                property: 'products.goods',
                className: 'form-item-products',
            },
            {
                property: 'advertisement.activityPicture',
                extra: services.language.getText('jhzhdtfydbxs'),
            },
        ];
        if (type !== 'copy' && executeStatus === 'STARTED') {
            editFields = [
                {
                    property: 'baseInfo.status',
                    controlConfig: {
                        disabled: true,
                    },
                },
                { property: 'baseInfo.name', modifiable: false },
                {
                    property: 'baseInfo.dateRange',
                    modifiable: false,
                },
                {
                    property: 'rulesInfo.rules',
                    modifiable: false,
                },
                {
                    property: 'products.goods',
                    className: 'form-item-products',
                    modifiable: false,
                },
                {
                    property: 'advertisement.activityPicture',
                    modifiable: false,
                    extra: services.language.getText('jhzhdtfydbxs'),
                },
            ];
        }
        if (type !== 'copy' && executeStatus === 'COMPLETE') {
            editFields = [
                { property: 'baseInfo.status' },
                { property: 'baseInfo.name', modifiable: false },
                {
                    property: 'baseInfo.dateRange',
                },
                {
                    property: 'rulesInfo.rules',
                },
                {
                    property: 'products.goods',
                    className: 'form-item-products',
                },
                {
                    property: 'advertisement.activityPicture',
                    extra: services.language.getText('jhzhdtfydbxs'),
                },
            ];
        }
        let formProps = {
            entity,
            componentId: 'CGBManageEditFormContainer',
            wrappedComponentRef,
            onSubmit,
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: editFields,
            submit: { text: services.language.getText('common.save') },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: services.language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                let successMsg = services.language.getText('tghdbjcg');
                if (type === 'copy') {
                    successMsg = services.language.getText('tghdfzcg');
                }
                AntMessage.success(successMsg, () => services.behaviorHandle({ route: 'goBack' }));
            },
        };
        return createElement(ComponentsManager.get('ModifyForm'), formProps);
    }
}
function mapStateToProps(_state: any, props: any) {
    const { entity } = props;
    return {
        entity,
        fields: entity.fields || {},
    };
}
function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;
    let form: any;
    const isEmpty = (value: any) => value === null || value === undefined;

    /**
     * 提交前额外验证
     * @param entityFields
     */
    const validate = (entityFields: any) => {
        let goods: any = get(entityFields, 'products.goods', null);
        if (!goods || !goods.length) {
            AntMessage.warning(services.language.getText('qxzhdsp'));
            return false;
        } else {
            let hasValidate = true;
            forEach(goods, (i) => {
                if (isEmpty(i.groupPrice)) {
                    AntMessage.warning(i.name + services.language.getText('wpztymjj'));
                    hasValidate = false;
                    return false;
                }
                if (isEmpty(i.groupBuyPrice)) {
                    AntMessage.warning(i.name + services.language.getText('wpztymjj'));
                    hasValidate = false;
                    return false;
                }
                if (isEmpty(i.groupStock)) {
                    AntMessage.warning(i.name + services.language.getText('wpzsqtgkc'));
                    hasValidate = false;
                    return false;
                }
            });
            if (!hasValidate) {
                return false;
            }
        }
        return true;
    };
    return defaults(
        {
            onSubmit,
            onFieldChange,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: debounce((event: any, fields: any) => {
                let submit = true;

                const entityFields = mapValues(fields, (field, name) => {
                    return field.value;
                });
                let rules: any = get(entityFields, 'rulesInfo.rules', null);
                map(rules, (rule) => {
                    if (isEmpty(rule.ruleType) || isEmpty(rule.ruleCount + '')) {
                        submit = false;
                        AntMessage.warning(services.language.getText('qwsxggz'));
                        return;
                    }
                });
                let executeStatus: any = get(entityFields, 'baseInfo.executeStatus', null);
                if (props.type !== 'copy' && executeStatus === 'STARTED') {
                    AntMessage.warning(services.language.getText('jxzdhdbyxxg'));
                    return;
                }
                if (submit) {
                    if (entityFields) {
                        const hasValidate = validate(entityFields);
                        if (!hasValidate || !submit) {
                            return;
                        }
                        if (props.type === 'copy') {
                            entity.add(entityFields, params);
                        } else {
                            entity.modify(entityFields, params);
                        }
                    }
                }
                return false;
            }, 300),
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('CGBManageEditFormContainer'));
            },
        }
    );
}
export const CGBManageEditFormView = connect(
    mapStateToProps,
    mapDispatchToProps
)(CGBManageEditForm);
