import React, { PureComponent } from 'react';
import { Form, Modal, message, InputNumber, Button, Radio, DatePicker } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import moment from 'moment';
import { cloneDeep, remove } from 'lodash';
import { DateRangePicker } from '@comall-backend-builder/components-basis';
import {
    NewMallActivityRow,
    NewMallActivityRoundItem,
    NewMallActivityMemberCardLevel,
    MallActivityTicket,
} from '../types';
import { TimeRangePickerPlus } from '../..';
import {
    getRowDateTime,
    disabledDate,
    sortRoundsByStartDateTime,
    validateRoundsConflict,
    formatTime,
    cloneTicket,
    newTicket,
    newRound,
} from '../common';
import { NewMallActivityTickets } from '../new-mall-activity-tickets/new-mall-activity-tickets';

import '../index.less';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

interface NewMallActivityRoundsAddModalProps {
    onSubmit: (value: NewMallActivityRoundItem[]) => void;
    disabled?: boolean;
    row: NewMallActivityRow;
    isAdd: boolean;
    cardLevels: NewMallActivityMemberCardLevel[];
}

interface Range {
    start: string;
    end: string;
}

/**
 * 批量生成日期类型
 */
enum BatchAddRoundsType {
    /**
     * 每日一场
     */
    DateRange = 'DATE_RANGE',
    /**
     * 自定义日期
     */
    CustomDate = 'CUSTOM_DATE',
}

interface CustomDateTime {
    date?: string;
    startTime?: string;
    endTime?: string;
}

interface NewMallActivityRoundsAddModalStates
    extends Omit<NewMallActivityRoundItem, 'id' | 'rowKey' | 'enable'> {
    showModal: boolean;
    dateRange?: Range;
    batchType: BatchAddRoundsType;
    customDateTimeList: CustomDateTime[];
}
const labelCol = { span: 5 };

/**
 * 新商场活动批量生成场次弹窗
 */
class NewMallActivityRoundsAddModal extends PureComponent<
    NewMallActivityRoundsAddModalProps,
    NewMallActivityRoundsAddModalStates
> {
    constructor(props: NewMallActivityRoundsAddModalProps) {
        super(props);
        this.state = {
            showModal: false,
            tickets: [],
            batchType: BatchAddRoundsType.DateRange,
            customDateTimeList: [{}],
        };
    }
    /**
     * 打开弹窗
     */
    private showModal = () => {
        const { row, isAdd } = this.props;
        const { startDate, endDate, startTime, endTime } = getRowDateTime(row, isAdd);
        if (!startDate || !endDate || !startTime || !endTime) {
            message.warn(language.getText('qxszhdrqhhdsj'));
            return;
        }
        if (!row?.baseInfo?.merchants?.length) {
            message.warn(language.getText('qxxzzj'));
            return;
        }
        const now = moment();
        if (moment(endDate) < now) {
            message.warn(language.getText('hdydjsqdzhdjsrq'));
            return;
        }
        const roundStartDate = moment(startDate) < now ? now.format('YYYY-MM-DD') : startDate;
        const dateRange = {
            start: roundStartDate,
            end: endDate,
        };
        const tickets = [newTicket()];
        this.setState({
            showModal: true,
            dateRange,
            startTime: startTime.slice(0, 5),
            endTime: endTime.slice(0, 5),
            tickets,
            customDateTimeList: [{}],
            batchType: BatchAddRoundsType.DateRange,
        });
        if (isAdd) {
            this.setState({ roundStartDate, roundEndDate: endDate });
        }
    };
    /**
     * 关闭弹窗
     */
    private closeModal = () => {
        this.setState({
            dateRange: undefined,
            showModal: false,
            roundStartDate: undefined,
            roundEndDate: undefined,
            startTime: undefined,
            endTime: undefined,
            maxPurchaseTotal: undefined,
        });
    };
    /**
     * 日期区间变更
     */
    private onDateChange = (value?: Range) => {
        this.setState({ roundStartDate: value?.start, roundEndDate: value?.end });
        return value || {};
    };

    /**
     * 限购变更
     */
    private onMaxPurchaseTotalChange = (value?: number) => {
        this.setState({ maxPurchaseTotal: value });
    };
    /**
     * 票档变更
     */
    private onTicketsChange = (value: MallActivityTicket[] | undefined = []) => {
        this.setState({ tickets: value });
    };

    /**
     * 批量生成场次的类型变更
     */
    onTypeChange = (event: RadioChangeEvent) => {
        this.setState({ batchType: event.target.value });
    };
    /**
     * 时间区间变更
     */
    private onTimeChange = (value?: Range) => {
        this.setState({ startTime: value?.start, endTime: value?.end });
    };
    /**
     * 渲染场次日期时间区间
     */
    renderDateRange = () => {
        const { dateRange, roundStartDate, roundEndDate, startTime, endTime } = this.state;
        const dateValue =
            roundStartDate && roundEndDate
                ? { start: roundStartDate, end: roundEndDate }
                : undefined;
        return (
            <Form.Item labelAlign="left" labelCol={labelCol} label={language.getText('ccrq')}>
                <div className="new-mall-activity__batch-modal__flex-row">
                    <DateRangePicker
                        name="dateRange"
                        value={dateValue}
                        onChange={this.onDateChange}
                        range={dateRange}
                        allowClear={false}
                        style={{ width: 240, paddingRight: 10 }}
                    />
                    <TimeRangePickerPlus
                        placeholder={[
                            language.getText('selectPlease'),
                            language.getText('selectPlease'),
                        ]}
                        name="start"
                        value={{
                            start: startTime,
                            end: endTime,
                        }}
                        format="HH:mm"
                        style={{ width: 80 }}
                        allowClear={true}
                        onChange={this.onTimeChange}
                        allowSingle
                    />
                </div>
            </Form.Item>
        );
    };
    /**
     * 复制自定义场次日期时间
     */
    onCopyCustomRound = (item: CustomDateTime) => {
        const { customDateTimeList } = this.state;
        this.setState({ customDateTimeList: [...customDateTimeList, { ...item }] });
    };
    /**
     * 增加自定义场次日期时间
     */
    onAddCustomRound = () => {
        const { customDateTimeList } = this.state;
        this.setState({ customDateTimeList: [...customDateTimeList, {}] });
    };
    /**
     * 删除自定义场次日期时间
     */
    onDeleteCustomRound = (index: number) => {
        const { customDateTimeList } = this.state;
        const newList = cloneDeep(customDateTimeList);
        remove(newList, (_, idx) => idx === index);
        this.setState({ customDateTimeList: newList });
    };
    /**
     * 自定义场次日期时间变更
     */
    onCustomRoundChange = (index: number, obj: Partial<CustomDateTime>) => {
        const { customDateTimeList } = this.state;
        const newList = cloneDeep(customDateTimeList);
        newList[index] = Object.assign(newList[index], obj);
        this.setState({ customDateTimeList: newList });
    };

    /**
     * 渲染自定义场次日期时间
     */
    renderCustomRounds = () => {
        const { customDateTimeList } = this.state;
        const { isAdd } = this.props;
        return (
            <Form.Item labelAlign="left" labelCol={labelCol} label={language.getText('ccxx')}>
                <div className="new-mall-activity__batch-modal__flex-column">
                    {customDateTimeList.map((item, index) => {
                        return (
                            <div className="new-mall-activity__batch-modal__flex-row">
                                <DatePicker
                                    style={{ width: 125, marginRight: 10 }}
                                    className="mrp-10"
                                    placeholder={language.getText('qxzrq')}
                                    showToday={false}
                                    allowClear={false}
                                    disabledDate={(current) =>
                                        disabledDate(current, this.props.row, isAdd)
                                    }
                                    value={item.date ? moment(item.date) : undefined}
                                    onChange={(date) =>
                                        this.onCustomRoundChange(index, {
                                            date: date?.format('YYYY-MM-DD'),
                                        })
                                    }
                                />
                                <TimeRangePickerPlus
                                    placeholder={[
                                        language.getText('selectPlease'),
                                        language.getText('selectPlease'),
                                    ]}
                                    name="start"
                                    value={{
                                        start: item.startTime,
                                        end: item.endTime,
                                    }}
                                    format="HH:mm"
                                    style={{ width: 80 }}
                                    allowClear={true}
                                    onChange={(value?: Range) => {
                                        this.onCustomRoundChange(index, {
                                            startTime: value?.start,
                                            endTime: value?.end,
                                        });
                                    }}
                                    allowSingle
                                />
                                <Button onClick={() => this.onCopyCustomRound(item)} type="link">
                                    {language.getText('copy')}
                                </Button>
                                <Button onClick={() => this.onDeleteCustomRound(index)} type="link">
                                    {services.language.getText('common.delete')}
                                </Button>
                            </div>
                        );
                    })}
                    <Button onClick={this.onAddCustomRound} type="primary">
                        {language.getText('tjccxx')}
                    </Button>
                </div>
            </Form.Item>
        );
    };
    /**
     * 组装单个场次
     * @param dateTime 场次日期和时间
     * @returns 场次
     */
    private packageRound = (dateTime: CustomDateTime): NewMallActivityRoundItem => {
        const { maxPurchaseTotal, tickets } = this.state;
        const { date, startTime, endTime } = dateTime;
        return {
            ...newRound(),
            roundStartDate: date,
            roundEndDate: date,
            startTime: formatTime(startTime),
            endTime: formatTime(endTime),
            tickets: tickets.map(cloneTicket),
            maxPurchaseTotal,
        };
    };

    /**
     * 根据日期区间组装场次信息
     */
    private packageRoundsByDateRange = () => {
        const { roundEndDate, roundStartDate, startTime, endTime } = this.state;
        if (!roundEndDate || !roundStartDate || !startTime) {
            message.warn(language.getText('qszhdrqhsj'));
            return [];
        }
        const diffDays = moment(roundEndDate).diff(moment(roundStartDate), 'days') + 1;
        return Array.from({ length: diffDays }).map((_, index) => {
            const date = moment(roundStartDate)
                .add(index, 'days')
                .format('YYYY-MM-DD');
            return this.packageRound({ date, startTime, endTime });
        });
    };

    /**
     * 根据自定义日期集合组装场次信息
     */
    private packageRoundsByCustomDate = () => {
        const { customDateTimeList } = this.state;
        if (!customDateTimeList.length) {
            message.warn(language.getText('qtjccxx'));
            return [];
        }
        if (customDateTimeList.some((i) => !i.date || !i.startTime)) {
            message.warn(language.getText('qwztxccrqhkssj'));
            return [];
        }
        // 根据自定义日期集合循环生成场次，并对场次按照时间排序
        const rounds = sortRoundsByStartDateTime(customDateTimeList.map(this.packageRound));
        // 校验场次时间冲突
        if (rounds.some((item, index) => validateRoundsConflict(item, rounds[index - 1]))) {
            message.warn(language.getText('hdccdmgsjdbkzf'));
            return [];
        }
        return rounds;
    };
    /**
     * 提交批量生成场次
     */
    private onSubmit = () => {
        const { batchType } = this.state;
        let rounds: NewMallActivityRoundItem[] = [];
        if (batchType === BatchAddRoundsType.DateRange) {
            rounds = this.packageRoundsByDateRange();
        } else {
            rounds = this.packageRoundsByCustomDate();
        }
        if (!rounds.length) {
            return;
        }
        const { onSubmit } = this.props;
        onSubmit(rounds);
        this.closeModal();
    };
    render() {
        const { disabled, cardLevels, isAdd } = this.props;
        if (disabled) {
            return null;
        }
        const { showModal, maxPurchaseTotal, tickets, batchType } = this.state;
        const labelCol = { span: 5 };
        return (
            <>
                <Button onClick={this.showModal} style={{ marginLeft: 10 }} type="default">
                    {language.getText('plsccc')}
                </Button>
                <Modal
                    width={1000}
                    onOk={this.onSubmit}
                    onCancel={this.closeModal}
                    visible={showModal}
                    title={language.getText('plsccc')}
                >
                    <div className="new-mall-activity__batch-modal">
                        <Form.Item
                            labelAlign="left"
                            labelCol={labelCol}
                            label={language.getText('cczq')}
                        >
                            <Radio.Group value={batchType} onChange={this.onTypeChange}>
                                <Radio value={BatchAddRoundsType.DateRange}>
                                    {language.getText('mryc')}
                                </Radio>
                                <Radio value={BatchAddRoundsType.CustomDate}>
                                    {language.getText('zdycc')}
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                        {batchType === BatchAddRoundsType.DateRange && this.renderDateRange()}
                        {batchType === BatchAddRoundsType.CustomDate && this.renderCustomRounds()}
                        <Form.Item
                            labelAlign="left"
                            labelCol={labelCol}
                            label={language.getText('mccmyhxg')}
                        >
                            <InputNumber
                                value={maxPurchaseTotal}
                                min={0}
                                max={999999}
                                precision={0}
                                onChange={this.onMaxPurchaseTotalChange}
                            ></InputNumber>
                        </Form.Item>
                        <Form.Item
                            labelAlign="left"
                            labelCol={labelCol}
                            wrapperCol={{ span: 22 }}
                            label={language.getText('pdxx')}
                        >
                            <NewMallActivityTickets
                                value={tickets}
                                cardLevels={cardLevels}
                                onChange={this.onTicketsChange}
                                isAdd={isAdd}
                            />
                        </Form.Item>
                    </div>
                </Modal>
            </>
        );
    }
}

export { NewMallActivityRoundsAddModal };
