import React, { useEffect, useState } from 'react';
import { AutoReplyVo } from '../../../../containers/wechat/components/wechant-content';
import { get, noop } from 'lodash';
import { api } from '@comall-backend-builder/core/lib/services';
import { WechatOfficialAutoReplySettingPageData } from '../../../../containers/wechat/components/wechat-official-auto-reply-setting-page-data';
import { Button, Spin } from 'antd';

interface Props {
    appIdPath: string;
    row: any;
}
export default function WechatOfficialQrcodeReplyDisplay(props: Props) {
    const { row, appIdPath } = props;
    const appId = get(row, appIdPath);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [replyData, setReplyData] = useState<AutoReplyVo[]>([]);
    function loadData() {
        if (appId) {
            setLoading(true);
            api.get(
                { appId, replyType: 'SCAN_QR_CODE' },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                    apiPath: '/admin/wechat_official_account_reply_messages/by_app_id',
                }
            )
                .then((res: any) => {
                    if (res?.replyContents?.length) {
                        setErrorMessage('');
                        setReplyData(
                            res.replyContents.map((content: any) => ({
                                ...content,
                                messageContent: JSON.parse(content.messageContent),
                            }))
                        );
                    } else {
                        setErrorMessage('未配置默认回复内容');
                    }
                })
                .finally(() => {
                    setLoading(false);
                });
        } else {
            setErrorMessage('请先选择一个公众号');
        }
    }
    useEffect(loadData, [appId]);
    return (
        <Spin spinning={loading}>
            {errorMessage ? (
                <div style={{ color: '#f5222d' }}>
                    {errorMessage}
                    <Button type="link" onClick={loadData}>
                        刷新
                    </Button>
                </div>
            ) : (
                <div style={{ backgroundColor: '#f6f6f6' }}>
                    <div style={{ marginBottom: -20, padding: '4px 0' }}>
                        <Button type="link" onClick={loadData}>
                            刷新
                        </Button>
                    </div>
                    <WechatOfficialAutoReplySettingPageData
                        appId={appId}
                        inputOnly
                        replyData={replyData}
                        onChange={noop}
                        disabled
                    />
                </div>
            )}
        </Spin>
    );
}
