import React, { PureComponent, Fragment } from 'react';
import { Form, Input, Button, Icon, message, Avatar } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import { get } from 'lodash';
import { api } from '@comall-backend-builder/core/lib/services';
import { localStorage } from '@comall-backend-builder/core/lib/services';
import { SessionsLoader } from '../../../loaders/sessions-loader';
import { errorHandle } from '../../../services/error-handle';
import { AvatarModal } from '../../../components/avatar-modal';
import './BaseView.less';
import { services } from '@comall-backend-builder/core';
const FormItem = Form.Item;

interface BaseViewProps extends FormComponentProps {}
interface BaseViewState {
    currentUser: Record<string, any>;
    loading: boolean;
    uploadLoading: boolean;
    avatarModal: boolean;
}
class BaseView extends PureComponent<BaseViewProps, BaseViewState> {
    state = { currentUser: {}, loading: false, uploadLoading: false, avatarModal: false };
    componentDidMount() {
        this.setBaseInfo();
    }
    setBaseInfo = () => {
        const currentUser: Record<string, string> | null = localStorage.get('USER_INFO', null);
        const { form } = this.props;
        if (currentUser) {
            Object.keys(form.getFieldsValue()).forEach((key) => {
                const obj: Record<string, string | null> = {};
                obj[key] = currentUser[key] || null;
                form.setFieldsValue(obj);
            });
            this.setState({ currentUser });
        }
    };
    getAvatarURL() {
        const { currentUser } = this.state;
        if (currentUser) {
            const url = './images/avatar/avatar_1.png';
            return get(currentUser, 'avatarUrl', url);
        }
        return '';
    }

    handlerSubmit = (event: React.MouseEvent) => {
        const { form } = this.props;
        const data = form.getFieldsValue();

        form.validateFields((err) => {
            if (!err) {
                const { currentUser } = this.state;
                this.setState({ loading: true });
                api.put(
                    { ...currentUser, ...data },
                    {
                        apiPath: `/admin/user`,
                        apiRoot: `${ENV.AUTH_API_ROOT}/CAE-AUTHORIZATION`,
                    }
                )
                    .then(() => {
                        message.success(services.language.getText('gxcg'));
                        SessionsLoader.get().then((res) => {
                            localStorage.set('USER_INFO', res);
                            window.location.reload();
                        });
                    })
                    .catch(errorHandle)
                    .finally(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    };
    showAvatarModal = () => {
        this.setState({ avatarModal: true });
    };
    hideAvatarModal = () => {
        this.setState({ avatarModal: false });
    };
    avatarChange = (avatar: string) => {
        const { currentUser } = this.state;
        this.setState({ currentUser: { ...currentUser, avatarUrl: avatar } });
    };
    AvatarView = ({ avatar }: { avatar: string }) => {
        return (
            <Fragment>
                <div className="avatar">
                    <Avatar src={avatar} size={120} />
                </div>
                <div className="button-view">
                    <Button onClick={this.showAvatarModal}>
                        <Icon type="upload" />
                        更换头像
                    </Button>
                </div>
            </Fragment>
        );
    };
    render() {
        const {
            form: { getFieldDecorator },
        } = this.props;
        const { currentUser, loading, avatarModal } = this.state;
        return (
            <Fragment>
                <div className="title">基本设置</div>
                <div className="baseView">
                    <div className="left">
                        <Form layout="vertical">
                            <FormItem label="账号">
                                <span className="value-text">{get(currentUser, 'userName')}</span>
                            </FormItem>
                            <FormItem label="邮箱">
                                <span className="value-text">{get(currentUser, 'email')}</span>
                            </FormItem>
                            <FormItem label="全名">
                                {getFieldDecorator('realName', {
                                    rules: [
                                        {
                                            required: true,
                                            message: '请输入您的全名!',
                                        },
                                    ],
                                })(<Input placeholder="请输入您的全名" maxLength={100} />)}
                            </FormItem>
                            {/* <FormItem label="个人简介">
                                {getFieldDecorator('introduction')(
                                    <Input.TextArea
                                        placeholder="这个人很懒，什么都没有留下"
                                        rows={2}
                                        maxLength={200}
                                    />
                                )}
                            </FormItem> */}
                            <Button loading={loading} type="primary" onClick={this.handlerSubmit}>
                                更新信息
                            </Button>
                        </Form>
                    </div>
                    <div className="right">
                        {this.AvatarView({
                            avatar: this.getAvatarURL(),
                        })}
                    </div>
                </div>
                <AvatarModal
                    avatar={this.getAvatarURL()}
                    visible={avatarModal}
                    onCancel={this.hideAvatarModal}
                    onOk={this.avatarChange}
                />
            </Fragment>
        );
    }
}

export default Form.create()(BaseView);
