import React, { PureComponent } from 'react';
import { Table, Button } from 'antd';
import { cloneDeep, remove, forEach, filter, map } from 'lodash';
import { Shop } from '../../containers/select-shop';
import { services } from '@comall-backend-builder/core';
import './index.less';

export enum TagSelectedDisplayMode {
    /**
     * 普通（多选）
     */
    NORMAL = 'NORMAL',
    /**
     * 单选
     */
    SINGLE = 'SINGLE',
}

interface TagSelectedDisplayProps<T> {
    data: Array<Shop>;
    onChange: (data: Array<Shop>) => void;
    onRemove: (data: Array<Shop>) => void;
    disabled?: boolean;
    mode?: TagSelectedDisplayMode;
}
interface TagSelectedDisplayState {
    selectedRows: Array<Shop>;
}

/**
 * 标签展示和内容编辑
 */
class TagSelectedDisplay<T> extends PureComponent<
    TagSelectedDisplayProps<T>,
    TagSelectedDisplayState
> {
    getTableConfig = () => {
        const { disabled } = this.props;

        let newColumns = [
            {
                title: services.language.getText('tagName'),
                dataIndex: 'tagName',
                key: 'tagName',
            },
            {
                title: services.language.getText('merchantName'),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: services.language.getText('subsiteName'),
                dataIndex: 'subsiteName',
                key: 'subsiteName',
            },
        ];
        let config: any = {
            rowKey: 'id',
            scroll: {
                y: 400,
            },
            columns: [
                ...newColumns,
                {
                    title: services.language.getText('common.tableAction'),
                    className: 'action-column',
                    key: 'operation',
                    width: 100,
                    render: (i: any, row: any) => {
                        return !disabled ? (
                            <Button type={'link'} onClick={this.onRemove(row)}>
                                {services.language.getText('common.delete')}
                            </Button>
                        ) : null;
                    },
                },
            ],
            footer: () => this.renderFooter(),
            style: {
                marginTop: 20,
            },
        };
        return config;
    };

    renderFooter = () => {
        const { disabled, mode } = this.props;
        return !disabled && (!mode || mode !== TagSelectedDisplayMode.SINGLE) ? (
            <div className="product-selected-footer">
                <div className="setting-item">
                    <Button className="setting-item-ok" type="primary" onClick={this.onRemoveAll}>
                        {services.language.getText('batchDelete')}
                    </Button>
                </div>
            </div>
        ) : null;
    };

    onRemoveAll = () => {
        const { data, onRemove } = this.props;
        const { selectedRows } = this.state;

        if (selectedRows) {
            const results = cloneDeep(data);
            forEach(results, (brand: Shop) => {
                if (brand.select) {
                    brand.delete = true;
                }
            });
            remove(results, (brand) => brand.delete);
            onRemove(results);
        }
    };

    onRemove = (row: Shop) => {
        const { data, onRemove } = this.props;

        return () => {
            const result = cloneDeep(data);
            remove(result, (brand) => row.id === brand.id);
            onRemove(result);
        };
    };

    batchChangeValue = (field: string, value: any) => {};

    changeBatchInputValue = (field: string, value: any) => {};

    render() {
        const { data, onChange, mode } = this.props;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: data,
            rowSelection: {
                selectedRowKeys: new Array<string>(),
                onChange: (
                    selectedRowKeys: Array<number> | Array<string>,
                    selectedRows: Array<Shop>
                ) => {
                    this.setState({ selectedRows });
                    onChange(selectedRows);
                },
            },
        };

        if (data) {
            const selectedRowKeys = map(
                filter(data, (brand) => {
                    return brand.select ? true : false;
                }),
                'id'
            );
            this.setState({ selectedRows: data });
            tableProps.rowSelection.selectedRowKeys = selectedRowKeys;
        }

        if (mode && mode === TagSelectedDisplayMode.SINGLE) {
            delete tableProps.rowSelection;
        }
        return <Table {...tableProps} pagination={false}></Table>;
    }
}

export { TagSelectedDisplay };
