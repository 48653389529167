import React, { PureComponent } from 'react';
import { Button, Modal, Table } from 'antd';
import { map, remove, find } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

interface Props {
    currentData: any[];
    datasource: any[];
    onChange: (ids: number[]) => void;
}
interface State {
    visible: boolean;
    selectedIds: number[];
}

export default class CardLevelAddModal extends PureComponent<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
            selectedIds: [],
        };
    }

    componentWillReceiveProps(nextProps: any) {
        const { currentData: nextData } = nextProps;
        const { currentData } = this.props;
        if (
            (!currentData && nextData) ||
            (currentData && nextData && currentData.length !== nextData.length)
        ) {
            const selectedIds = nextData.map((i: any) => i.levelId);
            this.setState({ selectedIds });
        }
    }

    private toggleModal = () => {
        const { visible } = this.state;

        this.setState({
            visible: !visible,
        });
    };

    onTableRowSelect = (record: any, selected: boolean) => {
        const { selectedIds } = this.state;
        if (selected) {
            const rows = [...selectedIds];
            rows.push(record.id);
            this.setState({ selectedIds: rows });
        } else {
            const rows = [...selectedIds];
            remove(rows, (i) => {
                return i === record.id;
            });
            this.setState({ selectedIds: rows });
        }
    };
    onTableRowSelectAll = (selected: boolean, rows: any[]) => {
        const { datasource } = this.props;
        const { selectedIds } = this.state;
        if (selected) {
            const addRows = rows
                .filter((item: any) => {
                    return !selectedIds.includes(item.id);
                })
                .map((i: any) => i.id);
            this.setState({ selectedIds: [...selectedIds, ...addRows] });
        } else {
            const deleteIds: any = map(datasource, 'id');
            const deleteRows = selectedIds.filter((item: any) => {
                return !deleteIds.includes(item);
            });
            this.setState({ selectedIds: [...deleteRows] });
        }
    };

    private renderModal = () => {
        const { selectedIds } = this.state;
        const { datasource } = this.props;
        return (
            <Table
                rowSelection={{
                    type: 'checkbox',
                    selectedRowKeys: selectedIds,
                    onSelect: (record: any, selected: boolean, selectedRow: any) => {
                        this.onTableRowSelect(record, selected);
                    },
                    onSelectAll: (selected: boolean, rows: any[]) => {
                        this.onTableRowSelectAll(selected, rows);
                    },
                }}
                rowKey={(record: any) => record.id}
                columns={[
                    {
                        title: language.getText('djbm'),
                        dataIndex: 'code',
                        key: 'code',
                    },
                    {
                        title: language.getText('djmc'),
                        dataIndex: 'name',
                        key: 'name',
                    },
                    {
                        title: '默认等级',
                        dataIndex: 'isDefault',
                        key: 'isDefault',
                        render: (isDefault: boolean) => {
                            return <span>{isDefault ? '是' : '否'}</span>;
                        },
                    },
                    {
                        title: '等级状态',
                        dataIndex: 'status',
                        key: 'status',
                        render: (status: string) => {
                            return <span>{status === 'ENABLE' ? '启用' : '禁用'}</span>;
                        },
                    },
                ]}
                dataSource={datasource}
                pagination={false}
            />
        );
    };

    private onSubmit = () => {
        const { onChange } = this.props;
        const { selectedIds } = this.state;
        onChange(selectedIds);
        this.toggleModal();
    };

    private get showButton() {
        const { currentData, datasource } = this.props;
        if (!currentData || !datasource) {
            return false;
        }
        return !datasource.every((item: any) => {
            return Boolean(find(currentData, { levelId: item.id }));
        });
    }

    renderModalFooter = () => {
        return (
            <div className="buttons">
                <Button className="button" type="default" onClick={this.toggleModal}>
                    取消
                </Button>
                <Button className="button" onClick={this.onSubmit}>
                    保存
                </Button>
            </div>
        );
    };

    render() {
        const { visible } = this.state;
        const modalContent = this.renderModal();
        const modalProps = {
            width: 900,
            title: '添加等级',
            visible: visible,
            footer: this.renderModalFooter(),
            onCancel: this.toggleModal,
        };
        return (
            <div>
                {this.showButton ? (
                    <Button type="default" onClick={this.toggleModal} style={{ marginTop: 10 }}>
                        添加等级
                    </Button>
                ) : null}
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}
