import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    components: {
        ThirdRequestLogView: {
            component: 'Viewport',
        },
        ThirdRequestLogPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'ThirdRequestLogForm' }],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/third-request-log',
                    component: 'ThirdRequestLogView',
                    breadcrumbName: '<<thirdRequestLog>>',
                    privilege: {
                        path: 'thirdRequestLog',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'ThirdRequestLogPage' }],
                },
            ],
        },
    ],
};
