import * as React from 'react';

import { modes } from '@comall-backend-builder/types';
import { MerchantManagerCategorySelector } from './merchant-manager-category-selector';

export class MerchantManagerCategory extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(displayInfo: any) {
        return <MerchantManagerCategorySelector {...displayInfo} />;
    }
}
