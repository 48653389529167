import React from 'react';
import { connect } from 'react-redux';
import './index.less';
const prefix = 'red-package-rain-preview';
const innerClassNames = {
    box: `${prefix}__box`,
    underlay: `${prefix}__underlay`,
};
/**
 * 红包雨预览效果
 * @param props
 */
const popRecruitDistributorPreview = (props: any) => {
    const { preview = {} } = props;
    const backgroundImageUrl =
        preview &&
        preview.pictureInfo &&
        preview.pictureInfo.backgroundPicture &&
        preview.pictureInfo.backgroundPicture.length > 0
            ? preview.pictureInfo.backgroundPicture[0].path
            : '';
    const backgroundImage: any = {
        backgroundImage: backgroundImageUrl ? `url(${backgroundImageUrl})` : '',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
    };
    return (
        <div className={prefix}>
            {backgroundImage && <div className={innerClassNames.box} style={backgroundImage}></div>}
            <div className={innerClassNames.underlay}>
                <img
                    alt=""
                    width="100%"
                    src="./images/avatar/red-package-rain-preview-underlay.png"
                />
            </div>
        </div>
    );
};

const mapStateToProps = (_state: any, props: any) => {
    let preview = null;
    const entityId = props.entity.id;
    for (var compId in _state.components) {
        const comp = _state.components[compId];
        if (
            (comp.type === 'CreateForm' || comp.type === 'EditForm') &&
            comp.entityId === entityId
        ) {
            preview = comp.fields;
        }
    }
    return { preview: preview };
};

export const RedPackageRainPreview = connect(mapStateToProps)(popRecruitDistributorPreview);
