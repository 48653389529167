import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { ComplainDetail } from '../../../components';

export class ContentComplainDetailMode extends modes.ObjectMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(object: any, displayInfo: any) {
        if (object) {
            return <ComplainDetail value={object} {...displayInfo} />;
        } else {
            return super.getNotAvailableDisplayComponent(displayInfo);
        }
    }
}
