import { EntityButtonProps } from '../../../../components/entity-button/index';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { blanceRechargeOptions, orderStatusOptions, balanceOrderTypeOptions } from './types';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { isEmpty } from 'lodash';
const api = services.api;

export const config: Config = {
    entities: {
        CouponBalanceSaleEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/coupon_reports/coupon_balance',
            filters: {
                couponSubsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<qmd>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                externalCouponCode: {
                    type: 'string',
                    displayName: '<<qbm>>',
                },
                couponName: {
                    type: 'string.popover',
                    displayName: '<<qmc>>',
                },
                effectiveDateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<qjsyxq>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<czzt>>',
                    defaultValue: 'ALL',
                    options: blanceRechargeOptions,
                },
                orderSubsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<ddmd>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                orderType: {
                    type: 'string.options.select',
                    displayName: '<<ddlx>>',
                    defaultValue: 'ALL',
                    options: balanceOrderTypeOptions,
                },
                orderDateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<xdsj>>',
                },
            },
            properties: {
                couponSubsiteCode: {
                    type: 'string',
                    displayName: '<<qmdbm>>',
                },
                couponSubsiteName: {
                    type: 'string',
                    displayName: '<<qmd>>',
                },
                ssoUserId: {
                    type: 'string',
                    displayName: '<<memberId>>',
                },
                externalCouponCode: {
                    type: 'string',
                    displayName: '<<qbm>>',
                },
                couponName: {
                    type: 'string',
                    displayName: '<<qmc>>',
                },
                couponEndTime: {
                    type: 'string',
                    displayName: '<<jsyxq>>',
                },
                amount: {
                    type: 'string',
                    displayName: '<<mz_1>>',
                },
                quantity: {
                    type: 'string',
                    displayName: '<<couponQuantity>>',
                },
                totalAmount: {
                    type: 'string',
                    displayName: '<<xjje>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<czzt>>',
                    options: blanceRechargeOptions,
                },
                transactionId: {
                    type: 'string',
                    displayName: '<<czjydh>>',
                },
                payPoint: {
                    type: 'string',
                    displayName: '<<zfjf>>',
                },
                payAmount: {
                    type: 'string',
                    displayName: '<<zfje>>',
                },
                orderType: {
                    type: 'string.options.select',
                    displayName: '<<ddlx>>',
                    options: balanceOrderTypeOptions,
                },
                rechargeTime: {
                    type: 'string',
                    displayName: '<<zhbgsj>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                orderStatus: {
                    type: 'string.options.select',
                    displayName: '<<ddzt>>',
                    options: orderStatusOptions,
                },
                orderTime: {
                    type: 'string',
                    displayName: '<<xdsj>>',
                },
                orderSubsiteCode: {
                    type: 'string',
                    displayName: '<<ddmdbh>>',
                },
                orderSubsiteName: {
                    type: 'string',
                    displayName: '<<ddmd>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
            },
        },
    },
    components: {
        CouponBalanceSaleView: {
            component: 'Viewport',
            entity: 'CouponBalanceSaleEntity',
        },
        CouponBalanceSalePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'CouponBalanceSaleFilter',
                },
                { component: 'CouponBalanceSaleTable' },
            ],
        },
        CouponBalanceSaleFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'couponSubsiteId',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<selectPlease>>',
                    },
                },
                {
                    property: 'externalCouponCode',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<qsrqbm>>',
                    },
                },
                {
                    property: 'couponName',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<qsrqmc>>',
                    },
                },
                {
                    property: 'effectiveDateRange',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: ['<<startTime>>', '<<endTime>>'],
                    },
                },
                {
                    property: 'status',
                    controlConfig: {
                        style: { width: 240 },
                    },
                },
                {
                    property: 'orderSubsiteId',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<selectPlease>>',
                    },
                },
                {
                    property: 'orderNo',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<inputOrderNumber>>',
                    },
                },
                {
                    property: 'orderType',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<selectPlease>>',
                    },
                },
                {
                    property: 'orderDateRange',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: ['<<startTime>>', '<<endTime>>'],
                    },
                },
            ],
        },
        CouponBalanceSaleTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'EntityButton',
                    props: {
                        type: 'primary',
                        children: '<<exportSearchResult>>',
                        style: {
                            width: 110,
                            marginBottom: 24,
                            marginTop: 17,
                        },
                        onClick: (_: any, entityButtonProps: EntityButtonProps) => {
                            const params: any = Object.assign(
                                {},
                                entityButtonProps.entity.params,
                                entityButtonProps.entity.filters
                            );
                            if (!isEmpty(params.effectiveDateRange)) {
                                params.couponStartTime =
                                    params.effectiveDateRange.start + ' 00:00:00';
                                params.couponEndTime = params.effectiveDateRange.end + ' 23:59:59';
                                delete params.effectiveDateRange;
                            }
                            if (!isEmpty(params.orderDateRange)) {
                                params.orderStartTime = params.orderDateRange.start + ' 00:00:00';
                                params.orderEndTime = params.orderDateRange.end + ' 23:59:59';
                                delete params.orderDateRange;
                            }
                            if (params.status === 'ALL') {
                                delete params.status;
                            }
                            if (params.orderType === 'ALL') {
                                delete params.orderType;
                            }
                            params.couponSubsiteId =
                                params.couponSubsiteId && params.couponSubsiteId.id
                                    ? params.couponSubsiteId.id
                                    : params.couponSubsiteId;
                            params.orderSubsiteId =
                                params.orderSubsiteId && params.orderSubsiteId.id
                                    ? params.orderSubsiteId.id
                                    : params.orderSubsiteId;
                            const config = {
                                apiRoot: `${ENV.AUTH_API_ROOT}/DATA-STATISTICS`,
                                apiPath:
                                    '/admin/coupon_recharge_records/coupon_recharge_record.xls',
                            };

                            api.post(params, config)
                                .then(() => {
                                    AntMessage.success(services.language.getText('dataProcessing'));
                                })
                                .catch((error) => {
                                    services.errorHandle(error);
                                });
                        },
                    },
                },
                {
                    component: 'DataTable',
                    scroll: {
                        x: 3000,
                    },
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'couponSubsiteCode',
                        },
                        {
                            property: 'couponSubsiteName',
                        },
                        {
                            property: 'ssoUserId',
                        },
                        {
                            property: 'externalCouponCode',
                        },
                        {
                            property: 'couponName',
                            width: 180,
                        },
                        {
                            property: 'couponEndTime',
                        },
                        {
                            property: 'amount',
                        },
                        {
                            property: 'quantity',
                        },
                        {
                            property: 'totalAmount',
                        },
                        {
                            property: 'status',
                        },
                        {
                            property: 'transactionId',
                        },
                        {
                            property: 'payPoint',
                        },
                        {
                            property: 'payAmount',
                        },
                        {
                            property: 'rechargeTime',
                        },
                        {
                            property: 'orderType',
                        },
                        {
                            property: 'orderNo',
                        },
                        {
                            property: 'orderStatus',
                        },
                        {
                            property: 'orderTime',
                        },
                        {
                            property: 'orderSubsiteCode',
                        },
                        {
                            property: 'orderSubsiteName',
                        },
                        {
                            property: 'mobile',
                        },
                    ],
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/coupon-balance-sale-report',
                    component: 'CouponBalanceSaleView',
                    breadcrumbName: '<<balanceRechargeReport>>',
                    privilege: {
                        path: 'balanceRechargeReport',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'CouponBalanceSalePage' }],
                },
            ],
        },
    ],
};
