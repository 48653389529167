import React, { Component } from 'react';
import { Select, TimePicker } from 'antd';
import { map } from 'lodash';
import moment from 'moment';
import { services } from '@comall-backend-builder/core';

import './index.less';

interface SelectTimepickerValue {
    /**
     * 抽签时间类型
     */
    type?: string;
    /**
     * 抽签时间
     */
    drawTime?: string;
}

export interface SelectTimepickerProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: SelectTimepickerValue | null, name: string) => void;
    /**
     * 当前值
     */
    value?: SelectTimepickerValue;
    /**
     * 候选项集合
     */
    typeOptions?: Array<any>;
    defaultTypeValue?: string;
    defaultDrawTime?: string;
    tip?: string;
}
export class SelectTimepickerTip extends Component<SelectTimepickerProps> {
    constructor(props: any) {
        super(props);
        this.onTimeChange = this.onTimeChange.bind(this);
    }

    componentDidMount() {
        const { name, defaultDrawTime, onChange } = this.props;
        let { value, defaultTypeValue } = this.props;
        if (!value) {
            value = {};
        }
        value.type = defaultTypeValue;
        value.drawTime = defaultDrawTime;
        if (onChange) {
            onChange(value, name);
        }
    }

    onTypeChange(type: string) {
        let { name, value, onChange } = this.props;

        if (!value) {
            value = {};
        }
        value.type = type;
        if (onChange) {
            onChange(value, name);
        }
    }

    onTimeChange(event: any, timestring: string) {
        let { name, value, defaultTypeValue, onChange } = this.props;

        if (!value) {
            value = {};
        }
        if (!value.type) {
            value.type = defaultTypeValue;
        }
        value.drawTime = timestring;
        if (onChange) {
            onChange(value, name);
        }
    }

    render() {
        const { defaultTypeValue, typeOptions, tip, defaultDrawTime } = this.props;
        let { value } = this.props;
        let time;
        if (value && value.drawTime) {
            time = value.drawTime;
        } else {
            time = defaultDrawTime;
        }
        return (
            <div className="select-timepicker">
                <Select
                    className="type-selection"
                    placeholder={services.language.getText('selectPlease')}
                    defaultValue={defaultTypeValue}
                    value={(value && value.type) || defaultTypeValue}
                    onChange={(type: any) => this.onTypeChange(type)}
                >
                    {map(typeOptions, ({ id, name }) => (
                        <Select.Option key={id}>{name}</Select.Option>
                    ))}
                </Select>
                <TimePicker
                    minuteStep={60}
                    format="HH:mm"
                    value={moment(time, 'hh:mm')}
                    onChange={this.onTimeChange}
                ></TimePicker>

                <div className="item-text">{tip}</div>
            </div>
        );
    }
}
