import React, { PureComponent, CSSProperties } from 'react';
import { Icon, Modal } from 'antd';
import { TipsModalContent } from './tips-modal-content';
import './index.less';

export interface TipsModalProps {
    showIcon?: boolean;
    /**
     * 气泡内容
     */
    contentType: TipsModalContentType;
    /**
     * 图标类型
     */
    iconType?: string;
    /**
     * 图标样式
     */
    iconStyle?: any;
    text?: string;
    textColor?: string;
    title: string;
    width?: number;
    style?: CSSProperties;
}

interface TipsModalStates {
    showModal: boolean;
}

export enum TipsModalContentType {
    CarparkAmapPoiId = 'CarparkAmapPoiId',
    ExperienceExamplePicture = 'ExperienceExamplePicture',
    DouYingLaiKeyPicture = 'DouYingLaiKeyPicture',
    DoubleRuleParticipateCardinalityCalculate = 'DoubleRuleParticipateCardinalityCalculate',
    LoginTip = 'LoginTip',
    OrderAllowAfterSales = 'OrderAllowAfterSales',
    ExprienceDoubleRule = 'ExprienceDoubleRule',
    DeliveryReminder = 'DeliveryReminder',
    OrderMergeTabSequences = 'OrderMergeTabSequences',
    OpenInvoiceTabSequences = 'OpenInvoiceTabSequences',
    SubsiteSuspendTips = 'SubsiteSuspendTips',
    BalanceCouponQrCode = 'BalanceCouponQrCode',
    CommonSettingMerchantTypeTag = 'CommonSettingMerchantTypeTag',
    CommonSettingProductDetails = 'CommonSettingProductDetails',
    CheckinActivityRecordShare = 'CheckinActivityRecordShare',
    LoginMethod = 'LoginMethod',
    DoubleFactor = 'DoubleFactor',
    CommonSettingStyle1 = 'CommonSettingStyle1',
    CommonSettingStyle2 = 'CommonSettingStyle2',
    EstimatePrice = 'EstimatePrice',
    UploadWechat = 'UploadWechat',
    NoteComment = 'NoteComment',
    NewMallActivityType = 'NewMallActivityType',
    ScorePopup = 'ScorePopup',
    SubsiteServicePic = 'SubsiteServicePic',
    RewardsPopup = 'RewardsPopup',
    BigCodesDate = 'bigCodesDate',
    ShoppingListTips = 'ShoppingListTips',
    MerchantBigCodesTips = 'MerchantBigCodesTips',
    WeighingGoodsService = 'WeighingGoodsService',
    UserPrivacyAgreement = 'UserPrivacyAgreement',
    HelpCenterArticle = 'HelpCenterArticle',
    ProductDetailPagePriceDescription = 'ProductDetailPagePriceDescription',
    ServiceReservationRentalAgreement = 'ServiceReservationRentalAgreement',
    InvoiceIssuanceRules = 'InvoiceIssuanceRules',
    CheckoutPageInstructions = 'CheckoutPageInstructions',
    CardBindingPageInstructions = 'CardBindingPageInstructions',
    RealNameAuthenticationServiceAgreement = 'RealNameAuthenticationServiceAgreement',
    CheckoutPageUsageInstructions = 'CheckoutPageUsageInstructions',
    OfflineBalanceVoucherPaymentCodeInstructions = 'OfflineBalanceVoucherPaymentCodeInstructions',
    InvoiceTaxNumberExplanation = 'InvoiceTaxNumberExplanation',
    InvoiceNotice = 'InvoiceNotice',
    AuthorizationAgreement = 'AuthorizationAgreement',
    CrossBorderDataExplanation = 'CrossBorderDataExplanation',
    DepositPurchaseAuthorizationAgreement = 'DepositPurchaseAuthorizationAgreement',
    PersonalizedContentRecommendation = 'PersonalizedContentRecommendation',
    UserAgreement = 'UserAgreement',
    TermsOfServiceAndPrivacyPolicy = 'TermsOfServiceAndPrivacyPolicy',
    MemberCodeDisclaimer = 'MemberCodeDisclaimer',
    AccountCancellationNotice = 'AccountCancellationNotice',
    CardPurchasePageInstructions = 'CardPurchasePageInstructions',
    ViewVariable = 'ViewVariable',
}

/**
 * 气泡提示图标
 */
export class TipsModal extends PureComponent<TipsModalProps, TipsModalStates> {
    constructor(props: TipsModalProps) {
        super(props);
        this.state = { showModal: false };
    }
    handleModal = () => {
        const { showModal } = this.state;
        this.setState({ showModal: !showModal });
    };

    render() {
        const {
            title,
            width,
            contentType,
            iconType,
            iconStyle,
            text,
            textColor,
            showIcon,
            style,
        } = this.props;
        const { showModal } = this.state;
        return (
            <div style={style} className="tips-modal">
                <span onClick={this.handleModal} className="tips-modal-tips">
                    {showIcon && (
                        <Icon
                            className="tips-modal-icon"
                            style={iconStyle}
                            type={iconType ? iconType : 'question-circle'}
                        />
                    )}
                    {!!text && (
                        <span className="tips-modal-text" style={{ color: textColor }}>
                            {text}
                        </span>
                    )}
                </span>
                <Modal
                    title={title}
                    visible={showModal}
                    width={width || 800}
                    footer={null}
                    onCancel={this.handleModal}
                >
                    <TipsModalContent contentType={contentType} />
                </Modal>
            </div>
        );
    }
}
