import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
const prefix = 'open-box-preview';
const innerClassNames = {
    top: `${prefix}__top`,
    title: `${prefix}__title`,
    boxItem: `${prefix}__box-item`,
    box: `${prefix}__box`,
    ruleWrap: `${prefix}__rule-wrap`,
    goWrap: `${prefix}__go-wrap`,
    giftWrap: `${prefix}__gift-wrap`,
    buttonWrap: `${prefix}__button-wrap`,
    buttonWrapDefalut: `${prefix}__button-wrap__defalut`,
    tipWrap: `${prefix}__tip-wrap`,
    buttonWrapSetting: `${prefix}__button-wrap__setting`,
    buttonWrapSettingText: `${prefix}__button-wrap__setting__text`,
    audioOpen: `${prefix}__aduio-open`,
    audioClose: `${prefix}__aduio-close`,
};
/**
 * 全民分销预览效果
 * @param props
 */
const popRecruitDistributorPreview = (props: any) => {
    const { preview = {} } = props;
    const name = preview?.baseInfo.name || '';
    return (
        <div className={prefix}>
            <div className={innerClassNames.top}>
                <img alt="" width="100%" src="./images/avatar/theme-head.png" />
                <div className={innerClassNames.title}>{name}</div>
            </div>
            <div className={innerClassNames.boxItem}>{renderTop(preview)}</div>
            <div className={innerClassNames.boxItem} style={{ marginTop: '20px' }}>
                {renderBottom(preview)}
            </div>
        </div>
    );
};

const renderTop = (preview: any) => {
    const activityPictureUrl =
        preview &&
        preview.pictureInfo &&
        preview.pictureInfo.activityPicture &&
        preview.pictureInfo.activityPicture.length > 0
            ? preview.pictureInfo.activityPicture[0].path
            : '';
    const backgroundImage: any = {
        backgroundImage: activityPictureUrl ? `url(${activityPictureUrl})` : '',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
    };
    const rulePictureIconUrl =
        preview &&
        preview.pictureInfo &&
        preview.pictureInfo.rulePictureIcon &&
        preview.pictureInfo.rulePictureIcon.length > 0
            ? preview.pictureInfo.rulePictureIcon[0].path
            : '';
    const rulePictureAduioUrl =
        preview &&
        preview.pictureInfo &&
        preview.pictureInfo.audioUrl &&
        preview.pictureInfo.audioUrl.length > 0;
    const rulePictureAduioOpenUrl =
        preview &&
        preview.pictureInfo &&
        preview.pictureInfo.audioOpenPicture &&
        preview.pictureInfo.audioOpenPicture.length > 0
            ? preview.pictureInfo.audioOpenPicture[0].path
            : '';
    const rulePictureAduioCloseUrl =
        preview &&
        preview.pictureInfo &&
        preview.pictureInfo.audioClosePicture &&
        preview.pictureInfo.audioClosePicture.length > 0
            ? preview.pictureInfo.audioClosePicture[0].path
            : '';
    return (
        <div className={innerClassNames.box} style={backgroundImage}>
            {activityPictureUrl && rulePictureIconUrl && (
                <div className={innerClassNames.ruleWrap}>
                    <img alt="" src={rulePictureIconUrl} width={44} />
                </div>
            )}
            {rulePictureAduioUrl && rulePictureAduioOpenUrl && (
                <div className={innerClassNames.audioOpen}>
                    <img alt="" src={rulePictureAduioOpenUrl} width={44} />
                </div>
            )}
            {rulePictureAduioUrl && rulePictureAduioCloseUrl && (
                <div className={innerClassNames.audioClose}>
                    <img alt="" src={rulePictureAduioCloseUrl} width={44} />
                </div>
            )}
            {activityPictureUrl && (
                <div className={innerClassNames.goWrap}>
                    {renderBotton(preview, language.getText('djjr'))}
                </div>
            )}
        </div>
    );
};
const renderBotton = (preview: any, text: string) => {
    if (!preview || !preview.pictureInfo) {
        return null;
    }
    const buttonBackgroundStyle = preview.pictureInfo.buttonBackgroundStyle || 'NONE';
    const buttonColor = preview.pictureInfo.buttonColor || '#000';
    const buttonBackgroundColor = preview.pictureInfo.buttonBackgroundColor || '#fff';
    const buttonTextStyle: any = {
        color: buttonColor,
    };
    if (buttonBackgroundStyle === 'NONE') {
        const buttonWrapDefalutStyle: CSSProperties = {
            backgroundColor: buttonBackgroundColor,
        };
        return (
            <div className={innerClassNames.buttonWrapDefalut} style={buttonWrapDefalutStyle}>
                <div className={innerClassNames.buttonWrapSettingText} style={buttonTextStyle}>
                    {text}
                </div>
            </div>
        );
    }
    const buttonBackgroundPictureUrl =
        preview &&
        preview.pictureInfo &&
        preview.pictureInfo.buttonBackgroundPicture &&
        preview.pictureInfo.buttonBackgroundPicture.length > 0
            ? preview.pictureInfo.buttonBackgroundPicture[0].path
            : '';
    if (!buttonBackgroundPictureUrl) {
        return null;
    }
    const buttonWrapSettingStyle: any = {
        backgroundImage: buttonBackgroundPictureUrl ? `url(${buttonBackgroundPictureUrl})` : '',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
        backgroundPosition: 'center',
    };
    return (
        <div className={innerClassNames.buttonWrapSetting} style={buttonWrapSettingStyle}>
            <div className={innerClassNames.buttonWrapSettingText} style={buttonTextStyle}>
                {text}
            </div>
        </div>
    );
};
const renderBottom = (preview: any) => {
    const backgroundPictureUrl =
        preview &&
        preview.pictureInfo &&
        preview.pictureInfo.backgroundPicture &&
        preview.pictureInfo.backgroundPicture.length > 0
            ? preview.pictureInfo.backgroundPicture[0].path
            : '';
    const backgroundImage: any = {
        backgroundImage: backgroundPictureUrl ? `url(${backgroundPictureUrl})` : '',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
    };
    const giftBoxPictureUrl =
        preview &&
        preview.pictureInfo &&
        preview.pictureInfo.giftBoxPicture &&
        preview.pictureInfo.giftBoxPicture.length > 0
            ? preview.pictureInfo.giftBoxPicture[0].path
            : '';
    const tipColor: any = {
        color:
            preview && preview.pictureInfo && preview.pictureInfo.tipColor
                ? preview.pictureInfo.tipColor
                : '#000',
    };
    return (
        <div className={innerClassNames.box} style={backgroundImage}>
            <div className={innerClassNames.giftWrap}>
                <img src={giftBoxPictureUrl} alt="" width="375" />
                {backgroundPictureUrl && giftBoxPictureUrl && (
                    <div className={innerClassNames.buttonWrap}>
                        {renderBotton(preview, language.getText('zjjl'))}
                        {renderBotton(preview, language.getText('yqhy'))}
                    </div>
                )}
                {backgroundPictureUrl && giftBoxPictureUrl && (
                    <div className={innerClassNames.tipWrap} style={tipColor}>
                        <div>
                            {language.getText('sykhjh')}：
                            <span style={{ fontSize: '14px' }}>
                                2{language.getText('frequency')}
                            </span>
                        </div>
                        <div>{language.getText('ckhjh')}</div>
                    </div>
                )}
            </div>
        </div>
    );
};
const mapStateToProps = (_state: any, props: any) => {
    let preview = null;
    const entityId = props.entity.id;
    for (var compId in _state.components) {
        const comp = _state.components[compId];
        if (
            (comp.type === 'CreateForm' || comp.type === 'EditForm') &&
            comp.entityId === entityId
        ) {
            preview = comp.fields;
        }
    }
    return { preview: preview };
};

export const OpenBoxPreview = connect(mapStateToProps)(popRecruitDistributorPreview);
