import React, { PureComponent } from 'react';

/**
 * 短信模板
 */
interface MessageStyle {
    innerClass: any;
    top: any;
    bottom: any;
}
interface MessagesSubscriptioSmsProps {
    /**
     * 样式
     */
    messageStyle: MessageStyle;
    /**
     * 模板数据列表
     */
    messageList: any;
}

export class MessagesSubscriptioSms extends PureComponent<MessagesSubscriptioSmsProps> {
    render() {
        const {
            messageStyle: { innerClass, top, bottom },
            messageList,
        } = this.props;
        return (
            <div className={innerClass.content}>
                <div className={innerClass.emailItems}>
                    <div
                        style={{
                            whiteSpace: 'pre-line',
                        }}
                    >
                        {top && top.value}
                    </div>
                    {messageList.map((item: any) => {
                        return (
                            <>
                                {item && (
                                    <div
                                        key={item.name}
                                        className={innerClass.otherItem}
                                        style={{ marginBottom: 10 }}
                                    >
                                        <span
                                            className={innerClass.otherItemValue}
                                            style={{
                                                color: item && item.color ? item.color : '#000',
                                            }}
                                        >
                                            {item.value}
                                        </span>
                                    </div>
                                )}
                            </>
                        );
                    })}
                    <div
                        style={{
                            color: bottom && bottom.color ? bottom.color : '#D0021B',
                            whiteSpace: 'pre-line',
                        }}
                    >
                        {bottom && bottom.value}
                    </div>
                </div>
            </div>
        );
    }
}
