import React, { PureComponent } from 'react';
import { Button as AntButton } from 'antd';
import { services } from '@comall-backend-builder/core';

/**
 * 邀请有礼列表页面导出数据
 */
export class givingCouponSchedulesExportButton extends PureComponent<any, any> {
    render() {
        const { row } = this.props;
        let disabled = false;
        if (row.canExport !== undefined) {
            disabled = !row.canExport;
        }
        const url = disabled ? '' : row.resourceUrl;
        return (
            <AntButton disabled={disabled} type="link" style={{ padding: 0 }} className="span-link">
                <a href={url}>{services.language.getText('xzbb')}</a>
            </AntButton>
        );
    }
}
