import React, { PureComponent, createElement } from 'react';
import { message as AntMessage, Button, Modal as AntModal } from 'antd';
import { remove } from 'lodash';
import { connect } from 'react-redux';
import { services, ComponentsManager } from '@comall-backend-builder/core';
const api = services.api;
let SELECTED_ROW: any = [];

/**
 * 关联手机号
 */
class relationMobleManageTable extends PureComponent<any, any> {
    renderComponent() {
        const { entity, componentId } = this.props;
        let config = {
            component: 'DataTable',
            entity,
            componentId,
            loadFirstPage: true,
            rowKey: 'id',
            rowSelection: {
                onChange: () => {},
                onSelect: (record: any, selected: boolean) => {
                    this.onTableRowSelect(record, selected);
                },
                onSelectAll: (selected: boolean, rows: any) => {
                    this.onTableRowSelectAll(selected, rows);
                },
            },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'mobile',
                    align: 'center',
                },
            ],
            actionColumn: {
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                width: '50%',
                items: [
                    {
                        type: 'delete',
                        text: services.language.getText('common.delete'),
                        config: {
                            type: 'link',
                            tooltip: null,
                            title: services.language.getText('confirmDelete'),
                            okText: services.language.getText('common.ok'),
                            cancelText: services.language.getText('common.cancel'),
                        },
                    },
                ],
            },
            footer: () => {
                return (
                    <Button type="default" onClick={this.batchDeleteRelationMobile}>
                        {services.language.getText('batchDelete')}
                    </Button>
                );
            },
        };

        return createElement(ComponentsManager.get('DataTable'), config);
    }

    onTableRowSelect = (record: any, selected: boolean) => {
        if (selected) {
            SELECTED_ROW.push(record);
        } else {
            remove(SELECTED_ROW, (i: any) => {
                return i.id === record.id;
            });
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: any[]) => {
        if (selected) {
            SELECTED_ROW = rows;
        } else {
            SELECTED_ROW = [];
        }
    };

    batchDeleteRelationMobile = () => {
        const { entity } = this.props;
        if (!SELECTED_ROW.length) {
            AntMessage.error(services.language.getText('qxzsjh'));
            return;
        }

        AntModal.confirm({
            title: services.language.getText('tip'),
            content: services.language.getText('sxzdsjhsch'),
            onOk: () => {
                let ids = SELECTED_ROW.map((item: any) => item.id).join(',');
                api.delete(
                    {},
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                        apiPath: '/admin/enterprises/relation_mobile?ids=' + ids,
                    }
                ).then(
                    () => {
                        SELECTED_ROW = [];
                        entity.search();
                    },
                    (error) => {
                        services.errorHandle(error);
                    }
                );
            },
            okText: services.language.getText('common.confirm'),
            cancelText: services.language.getText('common.cancel'),
        });
    };

    render() {
        return <div>{this.renderComponent()}</div>;
    }
}

function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
        fields: entity.fields,
    };
}

export const RelationMobleManageTableView = connect(mapStateToProps)(relationMobleManageTable);
