/*
 * @Author: zhuhuajuan
 * @Date: 2020-7-14 14:09:41
 * @Description: 团购活动管理
 */
import { Config } from '@comall-backend-builder/core/lib/parser';
export const config: Config = {
    entities: {
        CGBManageEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COMMUNITY-GROUP-BUYING`,
            apiPath: '/loader/admin/activities',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<tgmc>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOTSTARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<ksjssj>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        status: {
                            type: 'boolean.switch',
                            displayName: '<<hdkg>>',
                            defaultValue: true,
                            rules: [{ required: true }],
                        },
                        name: {
                            type: 'string.tip',
                            displayName: '<<tgmc>>',
                            rules: [{ required: true, whitespace: true, message: '<<qsrsqtgmc>>' }],
                            controlConfig: {
                                placeholder: '<<qsrsqtgmc>>',
                                maxLength: 20,
                                tip: '<<hdmchzszqdhdlb>>',
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRange.tip',
                            displayName: '<<tgsj>>',
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                                tip: '<<hjgsqtghdddtyfhjbttz>>',
                            },
                            rules: [{ required: true, message: '<<qxztgsj>>' }],
                        },
                    },
                },
                products: {
                    type: 'object.subForm',
                    displayName: '<<productSetting>>',
                    properties: {
                        goods: {
                            type: 'array.CGBProducts',
                            displayName: '<<components.Tools.selectProduct>>',
                        },
                    },
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<tgmc>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                rulesInfo: {
                    type: 'object.subForm',
                    displayName: '<<limitSet>>',
                    properties: {
                        rules: {
                            type: 'array.UserRules',
                            displayName: '<<limitSet>>',
                            options: [
                                { id: 'EACH_ORDER', name: '<<md>>' },
                                { id: 'EACH_DAY', name: '<<everyDay>>' },
                                { id: 'EACH_WEEK', name: '<<everyWeek>>' },
                                { id: 'EACH_MONTH', name: '<<everyMonth>>' },
                                { id: 'EACH_YAER', name: '<<everyYear>>' },
                            ],
                        },
                    },
                },
                advertisement: {
                    type: 'object.subForm',
                    displayName: '<<hdxc>>',
                    properties: {
                        activityPicture: {
                            displayName: '<<hdtpz>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                        },
                    },
                },
            },
        },
        CGBConfig: {
            apiPath: '/loader/admin/config',
            properties: {
                id: { type: 'string' },
                baseConfig: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        name: {
                            type: 'string.tip',
                            displayName: '<<sqmc>>',
                            rules: [{ required: true, whitespace: true, message: '<<qsrsqmc>>' }],
                            controlConfig: {
                                placeholder: '<<qsrsqmc>>',
                                maxLength: 6,
                                style: { width: 300 },
                                tip: '<<kzdyzjtzdmc>>',
                            },
                        },
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            rules: [{ required: true, type: 'array' }],
                            extra: '<<tzsqsxzdydmd>>',
                        },
                    },
                },
                checkoutConfig: {
                    type: 'object.subForm',
                    displayName: '<<jssz>>',
                    properties: {
                        settleDays: {
                            type: 'number.tip',
                            displayName: '<<jssj>>',
                            rules: [
                                {
                                    required: true,
                                    type: 'number',
                                    min: 1,
                                    max: 365,
                                    message: '<<numberRange>>',
                                },
                            ],
                            controlConfig: {
                                addonbefore: '<<tgjsh>>',
                                addonafter: '<<tzdjslrjtz>>',
                                tip: '<<xtzdjtzjslr>>',
                            },
                        },
                        info: {
                            type: 'string.text.paragraph',
                            displayName: '<<jssm>>',
                            rules: [{ required: true, whitespace: true, max: 100 }],
                            controlConfig: {
                                rows: 4,
                                maxLength: 100,
                            },
                        },
                    },
                },
            },
        },
        SubsiteSelector: {
            apiPath: '/admin/subsites/mine',
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                address: {
                    type: 'string',
                    displayName: '<<subsiteAddress>>',
                },
                status: {
                    type: 'number.store.status',
                    displayName: '<<businessStatus>>',
                },
                isLeaf: {
                    type: 'boolean',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<subsiteName>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<businessStatus>>',
                    options: [
                        {
                            id: '1',
                            name: '<<subsiteStatusEnable>>',
                        },
                        {
                            id: '2',
                            name: '<<notBusiness>>',
                        },
                    ],
                    controlConfig: {
                        style: { width: 160 },
                    },
                },
            },
        },
        LeadersManage: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COMMUNITY-GROUP-BUYING`,
            apiPath: '/loader/admin/leaders',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<tzmc>>',
                    controlConfig: { placeholder: '<<qsrtzmc>>' },
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                    controlConfig: { placeholder: '<<qsrwsjh>>' },
                },
                subsiteName: {
                    type: 'object.option.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                    displayName: '<<tzmc>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<belongSubsite>>',
                },
                communityName: {
                    type: 'string',
                    displayName: '<<sqmc>>',
                },
                address: {
                    type: 'string',
                    displayName: '<<szdq>>',
                },
                createTimeStr: {
                    type: 'string',
                    displayName: '<<jrsj>>',
                },
                totalOrderAmount: {
                    type: 'string',
                    displayName: '<<xsjez>>',
                },
                profit: {
                    type: 'string',
                    displayName: '<<lr>>',
                },
                orderCount: {
                    type: 'string',
                    displayName: '<<dds>>',
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<status>>',
                    defaultValue: 'NORMAL',
                    options: [
                        { id: 'NORMAL', name: '<<zc_1>>' },
                        { id: 'ABNORMAL', name: '<<qt>>' },
                    ],
                },
            },
        },
    },
    components: {
        CGBView: {
            component: 'Viewport',
        },
        CGBPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'CGBTabs' }],
        },
        CGBTabs: {
            component: 'Tabs',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<tggl>>',
                    content: {
                        component: 'CGBManagePage',
                    },
                },
                {
                    title: '<<tzgl>>',
                    content: {
                        component: 'LeadersManagePage',
                    },
                },
                {
                    title: '<<zmtz>>',
                    content: {
                        component: 'CGBRecruitLeaderView',
                    },
                },
                {
                    title: '<<ruleSetting>>',
                    content: {
                        component: 'CGBConfigView',
                    },
                },
            ],
        },
        CGBManagePage: {
            entity: 'CGBManageEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'CGBManageFilter',
                },
                { component: 'CGBManageTable' },
            ],
        },
        CGBManageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 120 },
                    },
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        CGBManageTable: { component: 'CGBManageList' },
        CGBManageAddPage: {
            component: 'FlexLayout',
            entity: 'CGBManageEntity',
            direction: 'horizontal',
            items: [{ component: 'CGBManageAddFormView' }],
        },

        CGBManageEditPage: {
            component: 'FlexLayout',
            entity: 'CGBManageEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'CGBManageEditFormView' }],
        },
        CGBManageCopyPage: {
            component: 'FlexLayout',
            entity: 'CGBManageEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'CGBManageEditFormView', type: 'copy' }],
        },

        LeadersManagePage: {
            entity: 'LeadersManage',
            component: 'FlexLayout',
            direction: 'vertical',
            style: {
                padding: 10,
            },
            items: [
                {
                    component: 'LeadersFilter',
                },
                { component: 'LeadersManageTable' },
            ],
        },
        LeadersFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 24,
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'subsiteName',
                },
            ],
        },
        LeadersManageTable: { component: 'CgbLeadersContainers' },
        CGBRecruitLeaderView: {
            component: 'FlexLayout',
            items: [
                {
                    component: 'CGBRecruitLeader',
                },
            ],
        },
        CGBConfigView: {
            component: 'Card',
            entity: 'CGBConfig',
            loaderType: 'get',
            content: {
                component: 'CGBConfigFormView',
            },
        },
        CGBConfigForm: {
            component: 'ModifyForm',
            labelCol: 4,
            submit: {
                style: {
                    marginRight: 16,
                },
                text: '<<common.save>>',
            },
            fields: [
                {
                    property: 'baseConfig.name',
                    label: '<<sqmc>>',
                },
                {
                    property: 'baseConfig.subsites',
                    label: '<<otherSelectSubsite>>',
                },
                {
                    property: 'checkoutConfig.settleDays',
                    label: '<<jssj>>',
                },
                {
                    property: 'checkoutConfig.info',
                    label: '<<jssm>>',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/cgb-manage',
                    component: 'CGBView',
                    breadcrumbName: '<<tggl>>',
                    privilege: {
                        path: 'cgb',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'CGBManageAddPage',
                            breadcrumbName: '<<xztg>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'CGBManageEditPage',
                            breadcrumbName: '<<bjtg>>',
                        },
                        {
                            path: '/copy/:id',
                            component: 'CGBManageCopyPage',
                            breadcrumbName: '<<fztghd>>',
                        },
                        {
                            path: '/cgb-config',
                            breadcrumbName: '<<ruleSetting>>',
                            component: 'CGBConfigView',
                        },
                        {
                            path: '/cgb-leader',
                            breadcrumbName: '<<tzgl>>',
                            component: 'LeadersManagePage',
                        },
                        {
                            path: '/cgb-recruit-leader',
                            breadcrumbName: '<<zmtz>>',
                            component: 'CGBRecruitLeaderView',
                        },
                        { path: '/', component: 'CGBPage' },
                    ],
                },
            ],
        },
    ],
};
