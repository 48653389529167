import React, { Component } from 'react';
import { InputNumber, Radio, Button, Popover } from 'antd';
import { cloneDeep, isEqual, map } from 'lodash';
import { Coupon } from './pay-with-gift-reward-coupons';
import { PayWithGiftRewardCoupons } from './pay-with-gift-reward-coupons';
import { Activity, PayWithGiftRewardActivitys } from './pay-with-gift-reward-activitys';
import './index.less';
import { ImageUpload } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core';

export interface PayWithGiftRewardActivityRewardValue {
    id?: number;
    totalMoneyMin?: number;
    totalMoneyMax?: number;
    couponsEventMarketConfId?: number; //编辑页面回传
    couponsChecked: boolean;
    coupons: Array<Coupon>;
    distributionType?: PayWithGiftDistributionType;
    receiveDays?: number;
    maxAwardNumberEveryDay?: number;
    rewardPicture?: Array<{
        id: string;
        path: string;
    }>;
    activitysEventMarketConfId?: number; //编辑页面回传
    activitysChecked: boolean;
    activitys: Array<any>;
    activityRewardPicture?: Array<{
        id: string;
        path: string;
    }>;
    effectiveDays?: number;
}

export enum PayWithGiftConsumptionType {
    /**
     * 单次消费
     */
    SingleConsumption = 0,
    /**
     * 当日累计消费
     */
    AccumulatedDailyConsumption = 1,
    /**
     * 范围内消费
     */
    ConsumptionWithinTheScope = 2,
}

export enum ConsumptionWithinTheScope {
    ConsumptionWithinTheScopeOfTheDay = 0,
    ConsumptionWithinTheScopeOfTheActivity = 1,
}

export enum PayWithGiftDistributionType {
    /**
     * 系统自动发放
     */
    System = 0,
    /**
     * 用户手动领取
     */
    Member = 1,
}

export enum RewardItems {
    coupons = 'COUPON',
    activitys = 'ACTIVITY',
}

export interface PayWithGiftsRewardValue {
    type: number;
    secordType?: number;
    ladderConf: Array<PayWithGiftRewardActivityRewardValue>;
}

export interface EventMarketRewardProps {
    name: string;
    value: PayWithGiftsRewardValue;
    maxCount: number;
    disabled: boolean;
    onChange: (value: PayWithGiftsRewardValue) => void;
    options?: { code: string; name: string }[];
}

const defalutValue: PayWithGiftsRewardValue = {
    type: PayWithGiftConsumptionType.SingleConsumption,
    ladderConf: [
        {
            totalMoneyMin: undefined,
            totalMoneyMax: undefined,
            couponsChecked: false,
            coupons: [],
            distributionType: PayWithGiftDistributionType.Member,
            receiveDays: undefined,
            maxAwardNumberEveryDay: undefined,
            rewardPicture: undefined,
            activitysChecked: false,
            activitys: [],
            activityRewardPicture: undefined,
            effectiveDays: undefined,
        },
    ],
};
export class PayWithGiftsReward extends Component<EventMarketRewardProps> {
    static defaultProps = {
        maxCount: 3,
        disabled: false,
    };
    componentDidMount() {
        let { value, onChange } = this.props;
        if (window.location.href.includes('/add') && !value) {
            if (onChange) {
                onChange(defalutValue);
            }
        }
    }

    componentWillReceiveProps(nextProps: EventMarketRewardProps) {
        const currentSubsiteIds = this.getSubsiteIds(this.props);
        const nextSubsiteIds = this.getSubsiteIds(nextProps);
        if (!isEqual(nextSubsiteIds, currentSubsiteIds) && currentSubsiteIds) {
            this.onChangeValue();
        }
        const currentRange = this.getRange(this.props);
        const nextRange = this.getRange(nextProps);
        if (!isEqual(nextRange, currentRange) && currentRange) {
            this.onChangeValueRange(nextProps);
        }
    }

    getSubsiteIds = (props: any) => {
        const { row } = props;
        const subsiteIds = row.baseInfo && row.baseInfo.subsiteId ? row.baseInfo.subsiteId : null;
        return subsiteIds;
    };

    getRange = (props: any) => {
        const { row } = props;
        const subsiteIds = row.baseInfo && row.baseInfo.range ? row.baseInfo.range : null;
        return subsiteIds;
    };

    onChangeValue = () => {
        const { onChange } = this.props;
        onChange(defalutValue);
    };

    onChangeValueRange = (nextProps: any) => {
        const nextRange = this.getRange(nextProps);
        const { value, onChange } = this.props;
        const newValue = cloneDeep(value);
        if (
            newValue &&
            newValue.ladderConf &&
            newValue.ladderConf.length > 0 &&
            nextRange.join(',').includes(1)
        ) {
            const newLadderConf = newValue.ladderConf.map(
                (reward: PayWithGiftRewardActivityRewardValue) => {
                    reward.distributionType = PayWithGiftDistributionType.System;
                    return reward;
                }
            );
            newValue.ladderConf = newLadderConf;
            onChange(newValue);
        }
    };

    onCheckboxChange = (event: any, index: number, type: string) => {
        const { value, onChange } = this.props;
        const newValue = cloneDeep(value);
        let checkboxValue = event.target.checked;
        const item = newValue.ladderConf[index];
        if (type === RewardItems.coupons) {
            item.couponsChecked = checkboxValue;
        }
        if (type === RewardItems.activitys) {
            item.activitysChecked = checkboxValue;
        }
        if (onChange) {
            onChange(newValue);
        }
    };

    onChangeCoupon = (coupons: Array<Coupon>, index: number) => {
        const { value, onChange } = this.props;
        const newValue = cloneDeep(value);
        newValue.ladderConf[index].coupons = coupons;
        if (onChange) {
            onChange(newValue);
        }
    };

    renderCoupon = ({
        reward,
        index,
        isDisabled,
        couponsValue,
        subsiteIds,
    }: {
        reward: PayWithGiftRewardActivityRewardValue;
        index: number;
        isDisabled: boolean;
        couponsValue: any;
        subsiteIds: any;
    }) => {
        let imageControlInfo = {
            name: 'picture',
            value:
                reward.rewardPicture && reward.rewardPicture.length > 0
                    ? reward.rewardPicture[0].path
                    : undefined,
            accept: 'image/*',
            maxCount: 1,
            multiple: false,
            fileName: 'fileName',
            uploadType: 'single',
            uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
            mode: 'picture-card',
            maxSize: 1 * 1024,
            preview: {
                size: 500,
            },
            uploadLimitSizeErrorMessage:
                services.language.getText('pictures') +
                '{{file.name}}' +
                services.language.getText('pictureUploadLimit1'),
            limitTips: {
                style: {
                    display: 'none',
                },
            },
        };
        const onChangePicture = (value: any) => {
            this.onPictrueChange(value, index, 'rewardPicture');
            return {};
        };
        const currentRange = this.getRange(this.props);
        //编辑页面-奖励发放方式历史数据不可编辑 或 当拥有线上订单时不可编辑
        const distributionTypeDisabled =
            (isDisabled && reward.id) || (currentRange && currentRange.includes('1'));
        return (
            <div>
                <div className="reward-coupons">
                    <PayWithGiftRewardCoupons
                        index={index}
                        onChange={this.onChangeCoupon}
                        value={couponsValue}
                        subsiteIds={subsiteIds}
                        couponsChecked={reward.couponsChecked ? reward.couponsChecked : false}
                        onCheckboxChange={this.onCheckboxChange}
                        maxAwardNumberEveryDay={reward.maxAwardNumberEveryDay}
                        onMaxAwardNumberEveryDayChange={(value: any) =>
                            this.changeRewardItemValue(index, value, 'maxAwardNumberEveryDay')
                        }
                    />
                </div>
                {reward.couponsChecked && (
                    <div className="reward-content-item-bottom">
                        <div>
                            <span className="reward-content-item-bottom-title">
                                {services.language.getText('jlfffs')}：
                            </span>
                            <Radio.Group
                                disabled={distributionTypeDisabled}
                                onChange={(e) => {
                                    this.changeRewardItemValue(
                                        index,
                                        e.target.value,
                                        'distributionType'
                                    );
                                }}
                                value={reward.distributionType}
                            >
                                <Radio value={PayWithGiftDistributionType.Member}>
                                    {services.language.getText('yhzdlq')}
                                </Radio>
                                <Radio value={PayWithGiftDistributionType.System}>
                                    {services.language.getText('xtzdff')}
                                </Radio>
                            </Radio.Group>
                            <div className="type-tip" style={{ marginLeft: '100px' }}>
                                {services.language.getText('mqjzcxxddkzdlq')}
                            </div>
                        </div>
                        {reward.distributionType === 1 && (
                            <div>
                                <div>
                                    <span className="reward-content-item-bottom-title">
                                        {services.language.getText('lqsx')}：
                                    </span>
                                    {services.language.getText('jlff')}&nbsp;&nbsp;
                                    <InputNumber
                                        placeholder=""
                                        value={reward.receiveDays}
                                        min={1}
                                        max={999999}
                                        precision={0}
                                        onChange={(value: any) =>
                                            this.changeRewardItemValue(index, value, 'receiveDays')
                                        }
                                    ></InputNumber>
                                    &nbsp;&nbsp;{services.language.getText('tnklq')}
                                </div>
                                <div className="reward-content-item-bottom-picture">
                                    <span className="reward-content-item-bottom-title">
                                        {services.language.getText('hdt')}：
                                    </span>
                                    <div>
                                        <ImageUpload
                                            {...imageControlInfo}
                                            onChange={onChangePicture}
                                        />
                                        <div className="type-tip">
                                            {services.language.getText('tpccjyw638')}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    changeRewardItemValue = (
        rewardIndex: number,
        inputValue: number,
        type:
            | 'totalMoneyMin'
            | 'totalMoneyMax'
            | 'receiveDays'
            | 'distributionType'
            | 'maxAwardNumberEveryDay'
            | 'effectiveDays'
    ) => {
        const { value, onChange } = this.props;
        const newValue = cloneDeep(value);
        newValue.ladderConf[rewardIndex][type] = inputValue;
        onChange(newValue);
    };

    renderReward(reward: PayWithGiftRewardActivityRewardValue, index: number, isDisabled: boolean) {
        const subsiteIds = this.getSubsiteIds(this.props);
        const couponsValue: any = reward.coupons && reward.coupons.length > 0 ? reward.coupons : [];
        const activityValue: any =
            reward.activitys && reward.activitys.length > 0 ? reward.activitys : [];
        return (
            <div className="reward" key={index}>
                <div className="reward-title">
                    <div>
                        {services.language.getText('jt')}
                        {index + 1}:
                    </div>
                </div>
                <div className="reward-delete">
                    <Button
                        type="default"
                        className="reward-button"
                        onClick={this.onDeleteReward.bind(this, index)}
                    >
                        {services.language.getText('common.delete')}
                    </Button>
                </div>
                <div className="reward-content">
                    <div className="reward-content-item">
                        <div className="reward-content-item-title">
                            <span className="red-tip">*</span>&nbsp;
                            {services.language.getText('xfmj')}:
                        </div>
                        <div>
                            <InputNumber
                                placeholder={services.language.getText('thanLowConsumption')}
                                value={reward.totalMoneyMin}
                                min={0.01}
                                max={999999999.99}
                                precision={2}
                                onChange={(value: any) =>
                                    this.changeRewardItemValue(index, value, 'totalMoneyMin')
                                }
                                style={{ width: '125px' }}
                            ></InputNumber>
                            &nbsp;&nbsp; {services.language.getText('yuan')}
                            &nbsp;&nbsp;~&nbsp;&nbsp;
                            <InputNumber
                                placeholder={services.language.getText('underConsumption')}
                                value={reward.totalMoneyMax}
                                min={0.01}
                                max={999999999.99}
                                precision={2}
                                onChange={(value: any) =>
                                    this.changeRewardItemValue(index, value, 'totalMoneyMax')
                                }
                                style={{ width: '125px' }}
                            ></InputNumber>
                            &nbsp;&nbsp;{services.language.getText('yuan')}
                        </div>
                    </div>
                    <div className="reward-content-item">
                        <div className="reward-content-item-title">
                            <span className="red-tip">*</span>&nbsp;
                            {services.language.getText('jlnr')}：
                        </div>
                        <div className="reward-content-list">
                            <div style={{ marginBottom: 10 }}>
                                {this.renderCoupon({
                                    reward,
                                    index,
                                    isDisabled,
                                    couponsValue,
                                    subsiteIds,
                                })}
                            </div>
                            <div style={{ marginBottom: 10 }}>
                                {this.renderActivity({
                                    reward,
                                    index,
                                    isDisabled,
                                    activityValue,
                                    subsiteIds,
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    onChangeActivity = (activitys: Array<Activity>, index: number) => {
        let { value, onChange } = this.props;
        const newValue = cloneDeep(value);
        newValue.ladderConf[index].activitys = activitys;
        if (onChange) {
            onChange(newValue);
        }
    };

    onPictrueChange = (picture: any, rewardIndex: number, type: string) => {
        const { value, onChange } = this.props;
        const newValue = cloneDeep(value);
        if (type === 'rewardPicture') {
            newValue.ladderConf[rewardIndex].rewardPicture = picture;
        } else if (type === 'activityRewardPicture') {
            newValue.ladderConf[rewardIndex].activityRewardPicture = picture;
        }
        onChange(newValue);
        return {};
    };

    renderActivity = ({
        reward,
        index,
        isDisabled,
        activityValue,
        subsiteIds,
    }: {
        reward: PayWithGiftRewardActivityRewardValue;
        index: number;
        isDisabled: boolean;
        activityValue: any;
        subsiteIds: any;
    }) => {
        let imageControlInfo = {
            name: 'picture',
            value:
                reward.activityRewardPicture && reward.activityRewardPicture.length > 0
                    ? reward.activityRewardPicture[0].path
                    : undefined,
            accept: 'image/*',
            maxCount: 1,
            multiple: false,
            fileName: 'fileName',
            uploadType: 'single',
            uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
            mode: 'picture-card',
            maxSize: 1 * 1024,
            preview: {
                size: 500,
            },
            uploadLimitSizeErrorMessage:
                services.language.getText('pictures') +
                '{{file.name}}' +
                services.language.getText('pictureUploadLimit1'),
            limitTips: {
                style: {
                    display: 'none',
                },
            },
        };
        const onChangePicture = (value: any) => {
            this.onPictrueChange(value, index, 'activityRewardPicture');
            return {};
        };
        const currentRange = this.getRange(this.props);
        const isOnlyOnline =
            currentRange && currentRange.length === 1 && currentRange.join(',').includes(1)
                ? true
                : false;
        return (
            <div>
                <div className="reward-coupons">
                    <PayWithGiftRewardActivitys
                        index={index}
                        onChange={this.onChangeActivity}
                        value={activityValue}
                        subsiteIds={subsiteIds}
                        activityChecked={reward.activitysChecked ? reward.activitysChecked : false}
                        onCheckboxChange={this.onCheckboxChange}
                    />
                </div>
                {!isOnlyOnline && reward.activitysChecked && (
                    <div className="reward-content-item-bottom">
                        <div style={{ marginTop: '10px' }}>
                            <div>
                                <span className="reward-content-item-bottom-title">
                                    {services.language.getText('jlsx')}：
                                </span>
                                {services.language.getText('jlff')}&nbsp;&nbsp;
                                <InputNumber
                                    placeholder=""
                                    value={reward.effectiveDays}
                                    min={1}
                                    max={999999}
                                    precision={0}
                                    onChange={(value: any) =>
                                        this.changeRewardItemValue(index, value, 'effectiveDays')
                                    }
                                ></InputNumber>
                                &nbsp;&nbsp;{services.language.getText('tnksy')}
                            </div>
                            <div className="type-tip" style={{ marginLeft: '100px' }}>
                                {services.language.getText('fjldrjksjs')}
                            </div>
                        </div>
                        <div className="reward-content-item-bottom-picture">
                            <span className="reward-content-item-bottom-title">
                                <span className="red-tip">*</span>&nbsp;
                                {services.language.getText('lqrkt')}：
                            </span>
                            <div>
                                <ImageUpload {...imageControlInfo} onChange={onChangePicture} />
                                <div className="type-tip">
                                    {services.language.getText('yyzxpxqlwyhtglqrk')}&nbsp;&nbsp;
                                    <Popover
                                        placement="left"
                                        content={
                                            <img
                                                style={{ width: 400 }}
                                                alt={services.language.getText('preview')}
                                                src="./images/avatar/pay-with-gifts-reward-coupon.png"
                                            ></img>
                                        }
                                        trigger="hover"
                                    >
                                        <Button style={{ padding: 0 }} type="link">
                                            {services.language.getText('viewExample')}
                                        </Button>
                                    </Popover>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    };
    changeType = (e: any, typeName: string) => {
        const { value, onChange } = this.props;
        const typeValue = e.target.value;
        const newValue = cloneDeep(value);
        if (typeName === 'type') {
            newValue.type = typeValue;
            newValue.secordType =
                typeValue === PayWithGiftConsumptionType.ConsumptionWithinTheScope
                    ? ConsumptionWithinTheScope.ConsumptionWithinTheScopeOfTheDay
                    : undefined;
        } else if (typeName === 'secordType') {
            newValue.secordType = typeValue;
        }
        onChange(newValue);
    };

    addReward = () => {
        const { value, onChange } = this.props;
        const nextRange = this.getRange(this.props);
        let distributionType = PayWithGiftDistributionType.Member;
        if (nextRange.join(',').includes(1)) {
            distributionType = PayWithGiftDistributionType.System;
        }
        const newValue = cloneDeep(value);
        newValue.ladderConf.push({
            totalMoneyMin: undefined,
            totalMoneyMax: undefined,
            couponsChecked: false,
            coupons: [],
            distributionType: distributionType,
            receiveDays: undefined,
            maxAwardNumberEveryDay: undefined,
            rewardPicture: undefined,
            activitysChecked: false,
            activitys: [],
            activityRewardPicture: undefined,
            effectiveDays: undefined,
        });
        onChange(newValue);
    };

    onDeleteReward = (index: number) => {
        const { value, onChange } = this.props;
        const newValue = cloneDeep(value);
        newValue.ladderConf.splice(index, 1);
        onChange(newValue);
    };

    render() {
        let { value, disabled } = this.props;
        if (!value) {
            return null;
        }
        return (
            <div className="pay-with-gifts-reward">
                <Radio.Group
                    onChange={(value) => {
                        this.changeType(value, 'type');
                    }}
                    value={value.type}
                    disabled={disabled}
                >
                    <Radio value={PayWithGiftConsumptionType.SingleConsumption}>
                        {services.language.getText('dcxf')}
                    </Radio>
                    {/*<Radio value={PayWithGiftConsumptionType.AccumulatedDailyConsumption}>*/}
                    {/*    当日累计消费*/}
                    {/*</Radio>*/}
                    <Radio value={PayWithGiftConsumptionType.ConsumptionWithinTheScope}>
                        {services.language.getText('fwnxf')}
                    </Radio>
                </Radio.Group>
                <div className="type-tip">
                    <div>{services.language.getText('dcjlspdmczfdjeddmjjjl')}。</div>
                    {/*<div>累计奖励是判断多次支付的金额合计达到门槛于次日0点奖励。</div>*/}
                    <div>{services.language.getText('fwjlsgjdrdbxfjezjddnjjttjjffdytjdjl')}</div>
                </div>
                {value.type === PayWithGiftConsumptionType.ConsumptionWithinTheScope && (
                    <Radio.Group
                        onChange={(value) => {
                            this.changeType(value, 'secordType');
                        }}
                        value={value.secordType}
                        disabled={disabled}
                    >
                        <Radio value={ConsumptionWithinTheScope.ConsumptionWithinTheScopeOfTheDay}>
                            {services.language.getText('drfwnljxf')}
                        </Radio>
                        <Radio
                            value={ConsumptionWithinTheScope.ConsumptionWithinTheScopeOfTheActivity}
                        >
                            {services.language.getText('hdqjljxf')}
                        </Radio>
                    </Radio.Group>
                )}
                {value.type === PayWithGiftConsumptionType.ConsumptionWithinTheScope && (
                    <div className="type-tip">
                        <div>{services.language.getText('detqcxfedzxjs')}</div>
                        <div>{services.language.getText('hdqjljxffhmk')}</div>
                    </div>
                )}
                {map(value.ladderConf, (reward, index) =>
                    this.renderReward(reward, index, disabled)
                )}
                {value.ladderConf && value.ladderConf.length < 4 && (
                    <div>
                        <Button type="default" className="reward-button" onClick={this.addReward}>
                            {services.language.getText('zjjt')}
                        </Button>
                        <span style={{ color: '#ccc' }}>
                            &nbsp;&nbsp;{services.language.getText('zdzcsgjt')}
                        </span>
                    </div>
                )}
            </div>
        );
    }
}
