import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { get, defaults } from 'lodash';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { Button, Modal, message as AntMessage } from 'antd';
import { internalPurchaseActivityId } from '../goods-manage-button';
const api = services.api;
export interface InternalPurchaseGoodsAddState {
    visible: boolean;
}

/**
 * 添加/编辑商品
 */
class internalPurchaseGoodsHandleButton extends PureComponent<any, any> {
    state: InternalPurchaseGoodsAddState = {
        visible: false,
    };

    onSubmit = (event: any, fields: any) => {
        event.stopPropagation();
        const { getForm, row, entity } = this.props;

        const form = getForm();
        form.validateFields(['price', 'memberLimit', 'tagIds'], { force: true }).then(
            (entityFields: any) => {
                if (entityFields) {
                    const hasValidate = this.validate(entityFields);
                    if (hasValidate) {
                        let params: any = {};
                        params = entityFields;
                        params.tagIds = entityFields.tagIds
                            ? entityFields.tagIds.map((item: any) => item.id)
                            : null;
                        if (this.props.params.type === 'edit') {
                            params.id = row.id;
                            api.put(entityFields, {
                                apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                apiPath: '/admin/internal_purchase_goods/' + row.id,
                            }).then(
                                () => {
                                    AntMessage.success(
                                        services.language.getText('common.saveSuccess')
                                    );
                                    this.toggleModal();
                                    entity.search();
                                },
                                (error) => {
                                    services.errorHandle(error);
                                }
                            );
                        } else {
                            params.goodsIds = [row.goodsId];
                            params.activityId = internalPurchaseActivityId;
                            api.post(entityFields, {
                                apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                apiPath: '/admin/internal_purchase_goods',
                            }).then(
                                () => {
                                    AntMessage.success(
                                        services.language.getText('common.saveSuccess')
                                    );
                                    this.toggleModal();
                                },
                                (error) => {
                                    services.errorHandle(error);
                                }
                            );
                        }
                    }
                }
            }
        );

        return;
    };
    /**
     * 提交前额外验证
     * @param entityFields
     */
    validate = (entityFields: any) => {
        let price: any = get(entityFields, 'price', '');
        const reg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;
        if ((price && !reg.test(price)) || price === 0) {
            AntMessage.warning(services.language.getText('jzcszdy'));
            return false;
        }
        let memberLimit: any = get(entityFields, 'memberLimit', '');
        let numberReg = /^([1-9]\d*)$/;
        if ((memberLimit && !numberReg.test(memberLimit)) || memberLimit === 0) {
            AntMessage.warning('个人限购数请输入正整数');
            return false;
        }

        return true;
    };

    renderModal = () => {
        const { wrappedComponentRef, entity } = this.props;
        const { type } = this.props.params;

        let formProps = {
            entity,
            wrappedComponentRef: wrappedComponentRef,
            componentId: 'internalPurchaseGoodsHandleButton',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 14,
            style: { width: 620 },
            fields: [{ property: 'price' }, { property: 'memberLimit' }, { property: 'tagIds' }],
            submit: false,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        onClick: this.onSubmit,
                        text: '确定',
                        type: 'primary',
                        style: { marginLeft: 10 },
                    },
                    {
                        htmlType: 'button',
                        onClick: this.toggleModal,
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmit: this.onSubmit,
        };
        if (type === 'add') {
            return createElement(ComponentsManager.get('CreateForm'), formProps);
        } else {
            return createElement(ComponentsManager.get('ModifyForm'), formProps);
        }
    };

    toggleModal = () => {
        const { entity, row } = this.props;
        const { type } = this.props.params;
        const { visible } = this.state;
        if (type === 'edit' && !visible) {
            entity &&
                entity.setFields({
                    ...row,
                    tagIds: row.tags,
                });
        }
        this.setState({
            visible: !visible,
        });
    };

    render() {
        const { params, row } = this.props;
        const { type } = params;
        const { visible } = this.state;
        const modalProps = {
            width: 800,
            title:
                type === 'add'
                    ? services.language.getText('addProduct')
                    : services.language.getText('editProduct'),
            visible: this.state.visible,
            footer: null,
            onCancel: this.toggleModal,
        };

        return (
            <div style={{ display: 'inline-block' }}>
                <Button key={row.id + type} type={'link'} onClick={this.toggleModal}>
                    {type === 'add' ? '添加' : '编辑'}
                </Button>
                {visible ? (
                    <Modal {...modalProps}>
                        <div>
                            <div style={{ width: 620 }} className="ant-row ant-form-item">
                                <div className="ant-col ant-col-6 ant-form-item-label">
                                    {services.language.getText('spmc')}：
                                </div>
                                <div className="ant-col ant-col-14 ant-form-item-control-wrapper">
                                    <div className="ant-form-item-control">{row.name}</div>
                                </div>
                            </div>
                            {this.renderModal()}
                        </div>
                    </Modal>
                ) : null}
            </div>
        );
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit } = props;
    let form: any;

    return defaults(
        {
            onSubmit,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
        }
    );
}
function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
        fields: entity.fields,
    };
}

export const InternalPurchaseGoodsHandleButton = connect(
    mapStateToProps,
    mapDispatchToProps
)(internalPurchaseGoodsHandleButton);
