import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';

export const config: Config = {
    entities: {
        SubsiteBuyLimitEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: '/loader/admin/buy_limit_rules',
            paramsFilter: ['SUBSITE'],
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<applicableSubsite>>',
                },
                deliveryModes: {
                    type: 'array.options.autoComplete.properties',
                    displayName: '<<deliveryType>>',
                    options: [],
                    controlConfig: {
                        pageType: 'deliveryModes',
                        source: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                            apiPath: '/admin/delivery_modes',
                        },
                        placeholder: '<<selectPlease>>',
                        style: { width: 450 },
                        dependences: 'subsiteIds',
                        dependencesProperties: 'id',
                        paramsName: 'subsiteIds',
                    },
                    rules: [{ required: true }],
                },
                value: {
                    type: 'number.tip',
                    displayName: '<<qsje>>',
                    rules: [
                        {
                            required: true,
                            type: 'number',
                            min: 0,
                            precision: 2,
                            message: '<<priceErrorMessage>>',
                            validator: (rule: any, value: any) => {
                                var reg = /^(?!0+$)(?!0*\.0*$)\d{1,8}(\.\d{1,2})?$/;
                                if (value) {
                                    if (Number(value) >= 0.01 && reg.test(value)) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                                return true;
                            },
                        },
                    ],
                    controlConfig: {
                        style: { width: 200 },
                        addonafter: '<<yuan>>',
                        tip: '<<ddbmqsje>>',
                    },
                    extra: '<<qyngdspbzczxqsgz>>',
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<status>>',
                },
                tip: {
                    type: 'string.text.paragraph',
                    displayName: '<<qstsy>>',
                    extra: '<<xtmrtsw>>',
                    controlConfig: {
                        style: { width: 450 },
                    },
                },
                subsiteIds: {
                    type: 'array.subsites.selector',
                    displayName: '<<applicableSubsite>>',
                    rules: [{ required: true, type: 'array' }],
                },
            },
        },
    },
    components: {
        SubsiteBuyLimitView: {
            component: 'Viewport',
            entity: 'SubsiteBuyLimitEntity',
        },
        SubsiteBuyLimitPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'SubsiteBuyLimitFilter',
                },
                { component: 'SubsiteBuyLimitTable' },
            ],
        },
        SubsiteBuyLimitFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                },
            ],
        },
        SubsiteBuyLimitTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'Button',
                    text: '<<xzqsgz>>',
                    route: '/subsite-buy-limit/add',
                    type: 'primary',
                    icon: 'plus',
                    style: {
                        width: 140,
                    },
                    className: 'mt24 mb24',
                },
                {
                    component: 'DataTable',
                    componentId: 'SubsiteBuyLimitDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'deliveryModes',
                        },
                        {
                            property: 'value',
                        },
                        {
                            property: 'subsites',
                            displayConfig: {
                                countSuffix: '<<subsites>>',
                            },
                        },
                        {
                            property: 'status',
                            displayConfig: {
                                statusConfig: {
                                    true: {
                                        text: '<<enabled>>',
                                        status: 'success',
                                    },
                                    false: {
                                        text: '<<beforeClose>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: false },
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                            apiPath: '/admin/buy_limit_rules/:id/disabled',
                                            config: {
                                                content: '<<gzybgb>>',
                                                text: '<<common.close>>',
                                                style: { color: 'red' },
                                            },
                                            confirm: {
                                                text: '<<qrgbgz>>?',
                                                className: 'confirm-content',
                                            },
                                        },
                                        {
                                            params: { status: true },
                                            value: false,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                            apiPath: '/admin/buy_limit_rules/:id/enabled',
                                            config: {
                                                content: '<<gzybkq>>',
                                                text: '<<common.opend>>',
                                            },
                                            confirm: {
                                                text: '<<isAreadyOpend>><<rule>>?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                text: '<<common.edit>>',
                                type: 'link',
                                path: '/subsite-buy-limit/edit/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        SubsiteBuyLimitAddPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'SubsiteBuyLimitAddForm' }],
        },
        SubsiteBuyLimitAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'SubsiteBuyLimitEntity',
            labelCol: 4,
            controlCol: 20,
            style: { width: 950 },
            fields: [
                { property: 'subsiteIds' },
                { property: 'deliveryModes' },
                { property: 'value' },
                // { property: 'tip' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        SubsiteBuyLimitEditPage: {
            component: 'FlexLayout',
            entity: 'SubsiteBuyLimitEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'SubsiteBuyLimitEditForm' }],
        },
        SubsiteBuyLimitEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'SubsiteBuyLimitEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            style: { width: 950 },
            fields: [
                { property: 'subsiteIds' },
                { property: 'deliveryModes' },
                { property: 'value' },
                // { property: 'tip' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/subsite-buy-limit',
                    component: 'SubsiteBuyLimitView',
                    breadcrumbName: '<<subsiteBuyLimitRule>>',
                    privilege: {
                        path: 'subsiteBuyLimitRule',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'SubsiteBuyLimitEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        {
                            path: '/add',
                            component: 'SubsiteBuyLimitAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        { path: '/', component: 'SubsiteBuyLimitPage' },
                    ],
                },
            ],
        },
    ],
};
