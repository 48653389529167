import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { MemberLevelRule } from './member-level-rule';
import './index.less';
/**
 *  会员节日营销，会员等级奖励类型
 */
export class MemberLevelRuleMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <MemberLevelRule {...controlInfo} />;
    }
}
