import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { get } from 'lodash';
import { formatNumber } from '../components/wechat-public-total';
import { isNil } from 'lodash';
const api = services.api;

export const WechatPublicUserCountLoader: Loader = {
    async get(data, config: ApiRequestConfig) {
        if (data.dateRange) {
            data.startTime = data.dateRange.start;
            data.endTime = data.dateRange.end;
            delete data.dateRange;
        }
        if (!data.dateRange) {
            delete data.dateRange;
        }

        if (!data.wechatPublicId || get(data, 'wechatPublicId.id') === '0') {
            delete data.wechatPublicId;
        }
        if (data.wechatPublicId) {
            data.wechatPublicId = data.wechatPublicId.id;
        }
        let res: any = await api.get(data, {
            ...config,
            apiPath: '/admin/wechat_public_follows',
        });

        res.result = res.result.map((item: any) => {
            return {
                ...item,
                attentionCount: isNil(item.attentionCount)
                    ? '-'
                    : formatNumber(item.attentionCount),
            };
        });

        return res;
    },
};
