import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { get, forEach } from 'lodash';

export const config: Config = {
    entities: {
        BlindBoxEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
            apiPath: '/loader/admin/blind-box',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
            },
            properties: {
                editExecuteStatus: {
                    type: 'string',
                },
                activityInfo: {
                    type: 'object.subForm',
                    displayName: '<<basicSetting>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<activityNameRequired>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<mhccl>>',
                                maxLength: 20,
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<hdrq>>',
                            rules: [{ required: true, message: '<<qxzhdrq>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<sxmd_1>>',
                            rules: [{ required: true, type: 'array' }],
                            defaultValue: [],
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],
                            controlConfig: {
                                subsiteField: 'activityInfo.subsites',
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.activityInfo?.subsites?.length &&
                                        row.activityInfo.subsites
                                            .map((item: any) => item.id)
                                            .join(',');
                                    return {
                                        subsiteIds,
                                    };
                                },
                            },
                        },
                    },
                },
                ruleInfo: {
                    type: 'object.subForm',
                    displayName: '<<activityRule>>',
                    properties: {
                        identity: {
                            type: 'string.options.radio',
                            displayName: '<<cyyhsf>>',
                            defaultValue: 'ALL',
                            rules: [{ required: true }],
                            options: [
                                { id: 'ALL', name: '<<xlyhjkcy>>' },
                                { id: 'NEW_MEMBER', name: '<<jxxyhcy>>' },
                            ],
                            extra: '<<xyhwdtzcdyh>>',
                        },
                        activityRewardLimitType: {
                            type: 'string.options.radio',
                            displayName: '<<gmslxz>>',
                            defaultValue: 'NO',
                            options: [
                                { id: 'NO', name: '<<bxz>>' },
                                { id: 'YES', name: '<<xz>>' },
                            ],
                        },
                        activityRewardLimit: {
                            type: 'number.tip',
                            displayName: '<<limitCount>>',
                            rules: [
                                {
                                    type: 'number',
                                    message: '<<jzctxzzs>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^([1-9]\d*)$/;
                                        if (value && !reg.test(value)) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                max: 99999999,
                                style: { width: '150px' },
                                placeholder: '<<inputPlease>>',
                                addonbefore: '<<mgyhgmsl>>',
                                addonafter: '<<jian>>',
                                tip: '',
                            },
                        },
                        price: {
                            type: 'number.tip',
                            displayName: '<<mhsj>>',
                            rules: [
                                {
                                    required: true,
                                    type: 'number',
                                    min: 0,
                                    precision: 2,
                                    message: '<<priceErrorMessage>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(?!0+$)(?!0*\.0*$)\d{1,8}(\.\d{1,2})?$/;
                                        if (value) {
                                            if (Number(value) >= 0.01 && reg.test(value)) {
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        }
                                        return false;
                                    },
                                },
                            ],
                            controlConfig: {
                                max: 99999999,
                                style: { width: 200 },
                                addonafter: '<<yuan>>',
                                tip: '',
                            },
                            extra: '<<mhsjbbkpsf>>',
                        },
                    },
                },
                productInfo: {
                    type: 'object.subForm',
                    displayName: '<<mhsp>>',
                    properties: {
                        goods: {
                            type: 'array.PresaleProducts',
                            displayName: '<<ptsp>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<components.Tools.productSelectRequired>>',
                                },
                            ],
                            controlConfig: {
                                selectProductTypes: ['NORMAL'],
                                selectShelfStatus: 'ALL',
                                showBlindBoxSaleCount: true,
                                showBlindBoxWinningRate: false,
                            },
                        },
                    },
                },
                pictureInfo: {
                    type: 'object.subForm',
                    displayName: '<<pageDecoration>>',
                    properties: {
                        showPicture: {
                            displayName: '<<hdxct>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                maxSize: 3 * 1024,
                                multiple: true,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<picLimit500>>',
                        },
                        backgroundPicture: {
                            displayName: '<<mhbjt>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                maxSize: 3 * 1024,
                                multiple: true,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<iconPictureExtra>>',
                        },
                        introductionPicture: {
                            displayName: '<<mhjjt>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                maxSize: 3 * 1024,
                                multiple: true,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<picLimit210>>',
                        },
                        coverPicture: {
                            displayName: '<<mhfmt>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                maxSize: 3 * 1024,
                                multiple: true,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<pointPictureRule>>',
                        },
                        packagePicture: {
                            displayName: '<<mhbz>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                maxSize: 3 * 1024,
                                multiple: true,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<picLimit500>>',
                        },
                        rulePicture: {
                            displayName: '<<activityRule>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                maxSize: 3 * 1024,
                                multiple: true,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<picNolimitHeight>>',
                        },
                        sharePicture: {
                            displayName: '<<fxtp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                maxSize: 3 * 1024,
                                multiple: true,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<sharePictureExtra>>',
                        },
                        shareContent: {
                            type: 'string',
                            displayName: '<<fxwa>>',
                            controlConfig: {
                                placeholder: '<<qsrfxwa>>',
                                style: {
                                    width: 300,
                                },
                            },
                            rules: [
                                {
                                    required: true,
                                    max: 30,
                                    whitespace: true,
                                    message: '<<qsrfxwa30Rule>>',
                                },
                            ],
                        },
                    },
                },
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
        },
    },
    components: {
        BlindBoxView: {
            component: 'Viewport',
            entity: 'BlindBoxEntity',
        },
        BlindBoxPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'BlindBoxFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/blind-box/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'BlindBoxTable' },
            ],
        },
        BlindBoxFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 200 },
                        placeholder: '<<qsrhdmc>>',
                    },
                },
                {
                    property: 'subsiteIds',
                    controlConfig: {
                        style: { width: 200 },
                        placeholder: '<<subsiteNameRequired>>',
                    },
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        BlindBoxTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'name',
                            width: 180,
                        },
                        {
                            property: 'startTime',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'endTime',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'subsites',
                            width: 200,
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '250px',
                                },
                                countSuffix: '<<subsites>>',
                            },
                        },
                        {
                            property: 'executeStatus',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                                statusConfig: {
                                    NOT_STARTED: {
                                        text: '<<notStarted>>',
                                        status: 'success',
                                    },
                                    STARTED: {
                                        text: '<<inprogress>>',
                                        status: 'processing',
                                    },
                                    COMPLETE: {
                                        text: '<<end>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        fixed: 'right',
                        width: 220,
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: false },
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                            apiPath: '/admin/blind_box_activities/:id/disabled',
                                            config: {
                                                content: '<<hd>>{{row.name}}<<areadyClosed>>',
                                                text: '<<common.close>>',
                                            },
                                            confirm: {
                                                text: '<<isCloseActivity>>{{row.name}}?',
                                            },
                                        },
                                        {
                                            params: { status: true },
                                            value: false,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                            apiPath: '/admin/blind_box_activities/:id/enabled',
                                            config: {
                                                content: '<<hd>>{{row.name}}<<areadyOpend>>',
                                                text: '<<common.opend>>',
                                            },
                                            confirm: {
                                                text: '<<isAreadyOpend>><<hd>>{{row.name}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                text: '<<common.edit>>',
                                type: 'link',
                                path: '/menu-list/market/blind-box/edit/{{row.id}}',
                            },
                            {
                                type: 'link',
                                text: '<<ckhd>>',
                                path: '/menu-list/market/blind-box/detail/{{row.id}}',
                            },
                            {
                                type: 'component',
                                component: 'ExportHandlerButton',
                                text: '<<dcbb>>',
                                params: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/blind_box_activities/{{row.id}}/export_jobs',
                                },
                            },
                            {
                                type: 'link',
                                text: '<<viewData>>',
                                path: '/menu-list/market/blind-box/data/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        BlindBoxAddPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'BlindBoxSettingLeftView' }, { component: 'BlindBoxAddForm' }],
        },
        BlindBoxAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'BlindBoxEntity',
            labelCol: 4,
            controlCol: 20,
            style: { width: 650 },
            fields: [
                { property: 'activityInfo.name' },
                { property: 'activityInfo.dateRange' },
                { property: 'activityInfo.subsites' },
                { property: 'activityInfo.marketingSchedule' },
                { property: 'ruleInfo.identity' },
                { property: 'ruleInfo.activityRewardLimitType' },
                {
                    property: 'ruleInfo.activityRewardLimit',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.activityRewardLimitType') === 'YES',
                },
                { property: 'ruleInfo.price' },
                { property: 'productInfo.goods' },
                { property: 'pictureInfo.showPicture' },
                { property: 'pictureInfo.backgroundPicture' },
                { property: 'pictureInfo.introductionPicture' },
                { property: 'pictureInfo.coverPicture' },
                { property: 'pictureInfo.packagePicture' },
                { property: 'pictureInfo.rulePicture' },
                { property: 'pictureInfo.sharePicture' },
                { property: 'pictureInfo.shareContent' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const activityRewardLimitType = entity.ruleInfo.activityRewardLimitType;
                const activityRewardLimit = entity.ruleInfo.activityRewardLimit;
                if (activityRewardLimitType === 'YES' && !activityRewardLimit) {
                    AntMessage.error(services.language.getText('qsryhxgsl'));
                    return false;
                }
                const goods = entity.productInfo.goods;
                let hasValidate = true;
                forEach(goods, (i) => {
                    if (!i.quantity) {
                        AntMessage.warning(services.language.getText('qsrmhkmkc'));
                        hasValidate = false;
                        return false;
                    }
                    if (i.quantity !== null && i.quantity <= 0) {
                        AntMessage.warning(services.language.getText('mhkmkcqsrdy'));
                        hasValidate = false;
                        return false;
                    }
                });
                if (!hasValidate) {
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        BlindBoxEditPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'BlindBoxSettingLeftView' }, { component: 'BlindBoxEditForm' }],
        },
        BlindBoxEditForm: {
            component: 'FlexLayout',
            entity: 'BlindBoxEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'BlindBoxEditFormView' }],
        },
        BlindBoxDetailPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'BlindBoxSettingLeftView' }, { component: 'BlindBoxDetailForm' }],
        },
        BlindBoxDetailForm: {
            component: 'Detail',
            direction: 'horizontal',
            entity: 'BlindBoxEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            style: { width: 650 },
            fields: [
                { property: 'activityInfo.name' },
                { property: 'activityInfo.dateRange' },
                { property: 'activityInfo.subsites' },
                { property: 'activityInfo.marketingSchedule' },
                { property: 'ruleInfo.identity' },
                { property: 'ruleInfo.activityRewardLimitType' },
                {
                    property: 'ruleInfo.activityRewardLimit',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.activityRewardLimitType') === 'YES',
                },
                { property: 'ruleInfo.price' },
                {
                    property: 'productInfo.goods',
                    displayConfig: {
                        showBlindBoxSaleCount: true,
                        showBlindBoxWinningRate: true,
                    },
                },
                {
                    property: 'pictureInfo.showPicture',
                    displayConfig: {
                        style: {
                            width: 100,
                        },
                    },
                },
                {
                    property: 'pictureInfo.backgroundPicture',
                    displayConfig: {
                        style: {
                            width: 100,
                        },
                    },
                },
                {
                    property: 'pictureInfo.introductionPicture',
                    displayConfig: {
                        style: {
                            width: 100,
                        },
                    },
                },
                {
                    property: 'pictureInfo.coverPicture',
                    displayConfig: {
                        style: {
                            width: 100,
                        },
                    },
                },
                {
                    property: 'pictureInfo.packagePicture',
                    displayConfig: {
                        style: {
                            width: 100,
                        },
                    },
                },
                {
                    property: 'pictureInfo.rulePicture',
                    displayConfig: {
                        style: {
                            width: 100,
                        },
                    },
                },
                {
                    property: 'pictureInfo.sharePicture',
                    displayConfig: {
                        style: {
                            width: 100,
                        },
                    },
                },
                {
                    property: 'pictureInfo.shareContent',
                },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
        },
        BlindBoxDataPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'BindBoxDataView',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/blind-box',
                    component: 'BlindBoxView',
                    breadcrumbName: '<<mhyx>>',
                    privilege: {
                        path: 'blindBoxMarketing',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'BlindBoxAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'BlindBoxEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/detail/:id',
                            component: 'BlindBoxDetailPage',
                            breadcrumbName: '<<ckhd>>',
                        },
                        {
                            path: '/data/:id',
                            component: 'BlindBoxDataPage',
                            breadcrumbName: '<<hdsj>>',
                        },
                        { path: '/', component: 'BlindBoxPage' },
                    ],
                },
            ],
        },
    ],
};
