import React, { Component } from 'react';
import { CheckboxGroup } from '@comall-backend-builder/components-basis';
import { isEqual, get, intersection } from 'lodash';
import { services } from '@comall-backend-builder/core';

import { ItemVO, PageType } from '../index';

const { api } = services;

interface CreditEshopAssignDeliveryModesProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: any, name: string) => void;
    /**
     * 当前值
     */
    value: Array<string>;
    /**
     * 是否禁用
     */
    disabled?: boolean;
    row: any;
}

interface CreditEshopAssignDeliveryModesState {
    /**
     * checkbox项
     */
    options: Array<ItemVO>;
}

export class CreditEshopAssignDeliveryModes extends Component<
    CreditEshopAssignDeliveryModesProps,
    CreditEshopAssignDeliveryModesState
> {
    static defaultProps = {
        addDefaultSelectAll: true,
    };
    constructor(props: any) {
        super(props);
        this.state = {
            options: [],
        };
    }
    pageType = PageType.EDIT;
    isFirst = true;

    componentDidMount() {
        // 判断页面类型
        const arr = window.location.href.split('/');
        const params = arr[arr.length - 1];
        if (isNaN(Number(params))) {
            this.pageType = PageType.ADD;
        }
        const subsiteIds = this.getSubsiteIds(this.props);
        if (subsiteIds) {
            const deliveryModeType = get(this.props, 'row.showTag.deliveryModeType');
            this.loadData(subsiteIds, deliveryModeType);
        }
    }

    componentWillReceiveProps(nextProps: CreditEshopAssignDeliveryModesProps) {
        const nextSubsiteIds = this.getSubsiteIds(nextProps);
        const currentSubsiteIds = this.getSubsiteIds(this.props);
        const subsiteChanged = !isEqual(nextSubsiteIds, currentSubsiteIds);
        const nextDeliveryModeType = get(nextProps, 'row.showTag.deliveryModeType');
        const currentDeliveryModeType = get(this.props, 'row.showTag.deliveryModeType');
        if (nextSubsiteIds && subsiteChanged) {
            this.loadData(nextSubsiteIds, nextDeliveryModeType);
            return;
        }
        if (nextDeliveryModeType !== currentDeliveryModeType) {
            this.loadData(nextSubsiteIds, nextDeliveryModeType);
            return;
        }
        if (currentSubsiteIds && !nextSubsiteIds) {
            this.onChange([]);
        }
    }

    getSubsiteIds = (props: CreditEshopAssignDeliveryModesProps) => {
        const { row } = props;
        return get(row, 'subsiteId', [])
            .map((item: { id: string; name: string }) => item.id)
            .join(',');
    };

    loadData = (subsiteIds: string, type: string) => {
        const { value = [] } = this.props;
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: '/admin/delivery_modes',
        };
        api.get({ subsiteIds, deliveryRuleType: type }, config).then((res: any = []) => {
            this.setState({ options: res });
            if (this.pageType === PageType.ADD) {
                this.onChange(res.map((i: any) => i.id));
            } else {
                if (this.isFirst) {
                    this.isFirst = false;
                    this.onChange(
                        intersection(
                            value,
                            res.map((i: any) => i.id)
                        )
                    );
                } else {
                    this.onChange(res.map((i: any) => i.id));
                }
            }
        });
    };

    shouldComponentUpdate(nextProps: CreditEshopAssignDeliveryModesProps) {
        return !isEqual(this.props, nextProps);
    }
    onChange = (value: any) => {
        const { name, onChange } = this.props;
        if (onChange) {
            onChange(value, name);
        }
    };
    render() {
        if (!this.getSubsiteIds(this.props)) {
            return <span style={{ color: 'red' }}>请选择门店</span>;
        }
        let { name, value, disabled } = this.props;
        const { options } = this.state;

        return (
            <div>
                <CheckboxGroup
                    disabled={!!disabled}
                    options={options}
                    name={name}
                    value={value}
                    onChange={this.onChange}
                ></CheckboxGroup>
            </div>
        );
    }
}
