import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { MemberGiftManageName } from './member-gift-manage-name';

export class MemberGiftManageNameMode extends modes.StringMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <MemberGiftManageName {...displayInfo} />;
    }
}
