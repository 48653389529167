import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { forEach } from 'lodash';

export const config: Config = {
    entities: {
        StoredValueCardBuyActivityEntity: {
            apiPath: '/loader/admin/stored_value_card_buy_activity',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 200 } },
                },
                subsiteIds: {
                    type: 'string.options.autoComplete.plus',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 250 } },
                },
                rewardMethod: {
                    type: 'string.options.select',
                    displayName: '<<jlfs>>',
                    options: [
                        { id: 'ORDER_AMOUNT', name: '<<orderTotalAmount>>' },
                        { id: 'PRODUCT', name: '<<klb>>' },
                    ],
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 150 } },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: [
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 150 } },
                },
                paymentModeType: {
                    type: 'string.options.select',
                    displayName: '<<zffs>>',
                    options: [
                        { id: '2', name: '<<zxzf>>' },
                        { id: '7', name: '<<dgzz>>' },
                    ],
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 150 } },
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseInfo>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<activityNameRequired>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<inputPlease>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRange.tip',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                hideTime: true,
                                style: {
                                    width: 300,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<activityMerchant>>',
                            rules: [{ required: true }],
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],
                            controlConfig: {
                                subsiteField: 'baseInfo.subsites',
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.baseInfo?.subsites?.length &&
                                        row.baseInfo.subsites.map((item: any) => item.id).join(',');
                                    return {
                                        subsiteIds,
                                    };
                                },
                            },
                        },
                        paymentModeType: {
                            type: 'string.options.select',
                            displayName: '<<zffs>>',
                            defaultValue: '2',
                            options: [
                                { id: '2', name: '<<zxzf>>' },
                                { id: '7', name: '<<dgzz>>' },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                placeholder: '<<selectPlease>>',
                                style: { width: 150 },
                            },
                        },
                    },
                },
                rewardInfo: {
                    type: 'object.subForm',
                    displayName: '<<jlpz>>',
                    properties: {
                        rewardMethod: {
                            type: 'string.options.radio',
                            displayName: '<<jlfs>>',
                            defaultValue: 'ORDER_AMOUNT',
                            options: [
                                { id: 'ORDER_AMOUNT', name: '<<orderTotalAmount>>' },
                                { id: 'PRODUCT', name: '<<klb>>' },
                            ],
                            rules: [{ required: true }],
                            extra: '<<h_jlfs_tip>>',
                        },
                        rewardConfigs: {
                            type: 'array.storedValueCardReward',
                            displayName: '<<jlnr>>',
                        },
                    },
                },
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                rewardMethod: {
                    type: 'string.options.select',
                    displayName: '<<jlfs>>',
                    options: [
                        { id: 'ORDER_AMOUNT', name: '<<orderTotalAmount>>' },
                        { id: 'PRODUCT', name: '<<klb>>' },
                    ],
                },
                paymentModeType: {
                    type: 'string.options.select',
                    displayName: '<<zffs>>',
                    options: [
                        { id: '2', name: '<<zxzf>>' },
                        { id: '7', name: '<<dgzz>>' },
                    ],
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
        },
    },
    components: {
        StoredValueCardBuyActivityView: {
            component: 'Viewport',
            entity: 'StoredValueCardBuyActivityEntity',
        },
        StoredValueCardBuyActivityPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'StoredValueCardBuyActivityFilter',
                    span: 24,
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mb24',
                    style: {
                        marginTop: 18,
                    },
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/stored-value-card-buy-activity/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'StoredValueCardBuyActivityTable' },
            ],
        },
        StoredValueCardBuyActivityFilter: {
            component: 'FilterForm',
            direction: 'inline',
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'subsiteIds',
                },
                {
                    property: 'rewardMethod',
                },
                {
                    property: 'dateRange',
                },
                {
                    property: 'executeStatus',
                },
                {
                    property: 'paymentModeType',
                },
            ],
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 12,
                },
            },
            reset: true,
        },
        StoredValueCardBuyActivityTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'subsites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'rewardMethod',
                },
                {
                    property: 'paymentModeType',
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 170,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath:
                                        '/admin/stored_value_card_reward_activities/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath:
                                        '/admin/stored_value_card_reward_activities/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/stored-value-card-buy-activity/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/stored-value-card-buy-activity/copy/{{row.id}}',
                    },
                ],
            },
        },
        StoredValueCardBuyActivityAddPage: {
            component: 'Card',
            content: { component: 'StoredValueCardBuyActivityAddForm' },
        },
        StoredValueCardBuyActivityAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'StoredValueCardBuyActivityEntity',
            labelCol: 3,
            controlCol: 21,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'baseInfo.subsites' },
                { property: 'baseInfo.marketingSchedule' },
                { property: 'baseInfo.paymentModeType' },
                { property: 'rewardInfo.rewardMethod' },
                { property: 'rewardInfo.rewardConfigs' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                //获取累计签到奖励配置解析数据验证
                const rewardMethod = entity.rewardInfo.rewardMethod || 'ORDER_AMOUNT';
                const rewardConfigs = entity.rewardInfo.rewardConfigs;
                if (
                    !rewardConfigs ||
                    rewardConfigs.length === 0 ||
                    !rewardConfigs[0].rewards ||
                    rewardConfigs[0].rewards.length === 0
                ) {
                    AntMessage.warning(services.language.getText('qtjjlpz'));
                    return false;
                }
                if (rewardMethod !== 'ORDER_AMOUNT') {
                    //验证卡类别是否都添加完毕
                    let errorTip = '';
                    const productIds: Array<string> = [];
                    forEach(rewardConfigs, (config: any) => {
                        if (config.productId === -1) {
                            errorTip = services.language.getText('qxzklb');
                            return false;
                        }
                        if (productIds.includes(config.productId)) {
                            errorTip = services.language.getText('klbbnzf');
                            return false;
                        } else {
                            productIds.push(config.productId);
                        }
                    });
                    if (errorTip) {
                        AntMessage.warning(errorTip);
                        return false;
                    }
                }
                let errorTip = '';
                //开始验证规则
                forEach(rewardConfigs, (config: any) => {
                    let maxLowerValue = -1;
                    forEach(config.rewards, (reward: any) => {
                        if (
                            reward.lowerValue === undefined ||
                            reward.lowerValue === null ||
                            reward.lowerValue === ''
                        ) {
                            errorTip = services.language.getText('qsrmjje');
                            return false;
                        }
                        //验证必须大于上一档
                        if (reward.lowerValue > maxLowerValue) {
                            maxLowerValue = reward.lowerValue;
                        } else {
                            errorTip = services.language.getText('mjjexydxdysydje');
                            return false;
                        }
                        if (!reward.stepValue) {
                            errorTip = services.language.getText('qsrmmje');
                            return false;
                        }
                        //2.验证奖励项是否已填写或选择（必须选择其中一项）
                        let checkoutNum = 0;
                        if (reward.pointChecked && reward.pointChecked === true) {
                            checkoutNum = checkoutNum + 1;
                            if (!reward.point || reward.point === '') {
                                errorTip = services.language.getText('qtxxzhdjfz');
                                return false;
                            }
                        }
                        if (reward.couponsChecked && reward.couponsChecked === true) {
                            checkoutNum = checkoutNum + 1;
                            if (!reward.couponsValue || reward.couponsValue.length === 0) {
                                errorTip = services.language.getText('qxzyhq');
                                return false;
                            }
                        }
                        if (checkoutNum === 0) {
                            errorTip = services.language.getText('qgxjlx');
                            return false;
                        }
                    });
                });
                if (errorTip) {
                    AntMessage.warning(errorTip);
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        StoredValueCardBuyActivityEditPage: {
            component: 'Card',
            content: { component: 'StoredValueCardBuyActivityEditForm' },
        },
        StoredValueCardBuyActivityEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            loaderType: 'get',
            entity: 'StoredValueCardBuyActivityEntity',
            labelCol: 3,
            controlCol: 21,
            fields: [
                { property: 'baseInfo.name' },
                {
                    property: 'baseInfo.dateRange',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'baseInfo.subsites',
                    controlConfig: {
                        disabled: true,
                    },
                },
                { property: 'baseInfo.marketingSchedule' },
                {
                    property: 'baseInfo.paymentModeType',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'rewardInfo.rewardMethod',
                    controlConfig: {
                        disabled: true,
                    },
                },
                { property: 'rewardInfo.rewardConfigs' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                //获取累计签到奖励配置解析数据验证
                const rewardMethod = entity.rewardInfo.rewardMethod || 'ORDER_AMOUNT';
                const rewardConfigs = entity.rewardInfo.rewardConfigs;
                if (
                    !rewardConfigs ||
                    rewardConfigs.length === 0 ||
                    !rewardConfigs[0].rewards ||
                    rewardConfigs[0].rewards.length === 0
                ) {
                    AntMessage.warning(services.language.getText('qtjjlpz'));
                    return false;
                }
                if (rewardMethod !== 'ORDER_AMOUNT') {
                    //验证卡类别是否都添加完毕
                    let errorTip = '';
                    const productIds: Array<string> = [];
                    forEach(rewardConfigs, (config: any) => {
                        if (config.productId === -1) {
                            errorTip = services.language.getText('qxzklb');
                            return false;
                        }
                        if (productIds.includes(config.productId)) {
                            errorTip = services.language.getText('klbbnzf');
                            return false;
                        } else {
                            productIds.push(config.productId);
                        }
                    });
                    if (errorTip) {
                        AntMessage.warning(errorTip);
                        return false;
                    }
                }
                let errorTip = '';
                //开始验证规则
                forEach(rewardConfigs, (config: any) => {
                    let maxLowerValue = -1;
                    forEach(config.rewards, (reward: any) => {
                        if (
                            reward.lowerValue === undefined ||
                            reward.lowerValue === null ||
                            reward.lowerValue === ''
                        ) {
                            errorTip = services.language.getText('qsrmjje');
                            return false;
                        }
                        //验证必须大于上一档
                        if (reward.lowerValue > maxLowerValue) {
                            maxLowerValue = reward.lowerValue;
                        } else {
                            errorTip = services.language.getText('mjjexydxdysydje');
                            return false;
                        }
                        if (!reward.stepValue) {
                            errorTip = services.language.getText('qsrmmje');
                            return false;
                        }
                        //2.验证奖励项是否已填写或选择（必须选择其中一项）
                        let checkoutNum = 0;
                        if (reward.pointChecked && reward.pointChecked === true) {
                            checkoutNum = checkoutNum + 1;
                            if (!reward.point || reward.point === '') {
                                errorTip = services.language.getText('qtxxzhdjfz');
                                return false;
                            }
                        }
                        if (reward.couponsChecked && reward.couponsChecked === true) {
                            checkoutNum = checkoutNum + 1;
                            if (!reward.couponsValue || reward.couponsValue.length === 0) {
                                errorTip = services.language.getText('qxzyhq');
                                return false;
                            }
                        }
                        if (checkoutNum === 0) {
                            errorTip = services.language.getText('qgxjlx');
                            return false;
                        }
                    });
                });
                if (errorTip) {
                    AntMessage.warning(errorTip);
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        StoredValueCardBuyActivityCopyPage: {
            component: 'Card',
            content: { component: 'StoredValueCardBuyActivityCopyForm' },
        },
        StoredValueCardBuyActivityCopyForm: {
            component: 'CopyFormPlus',
            direction: 'horizontal',
            loaderType: 'get',
            entity: 'StoredValueCardBuyActivityEntity',
            labelCol: 3,
            controlCol: 21,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'baseInfo.subsites' },
                { property: 'baseInfo.marketingSchedule' },
                { property: 'baseInfo.paymentModeType' },
                { property: 'rewardInfo.rewardMethod' },
                { property: 'rewardInfo.rewardConfigs' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                //获取累计签到奖励配置解析数据验证
                const rewardMethod = entity.rewardInfo.rewardMethod || 'ORDER_AMOUNT';
                const rewardConfigs = entity.rewardInfo.rewardConfigs;
                if (
                    !rewardConfigs ||
                    rewardConfigs.length === 0 ||
                    !rewardConfigs[0].rewards ||
                    rewardConfigs[0].rewards.length === 0
                ) {
                    AntMessage.warning(services.language.getText('qtjjlpz'));
                    return false;
                }
                if (rewardMethod !== 'ORDER_AMOUNT') {
                    //验证卡类别是否都添加完毕
                    let errorTip = '';
                    const productIds: Array<string> = [];
                    forEach(rewardConfigs, (config: any) => {
                        if (config.productId === -1) {
                            errorTip = services.language.getText('qxzklb');
                            return false;
                        }
                        if (productIds.includes(config.productId)) {
                            errorTip = services.language.getText('klbbnzf');
                            return false;
                        } else {
                            productIds.push(config.productId);
                        }
                    });
                    if (errorTip) {
                        AntMessage.warning(errorTip);
                        return false;
                    }
                }
                let errorTip = '';
                //开始验证规则
                forEach(rewardConfigs, (config: any) => {
                    let maxLowerValue = -1;
                    forEach(config.rewards, (reward: any) => {
                        if (
                            reward.lowerValue === undefined ||
                            reward.lowerValue === null ||
                            reward.lowerValue === ''
                        ) {
                            errorTip = services.language.getText('qsrmjje');
                            return false;
                        }
                        //验证必须大于上一档
                        if (reward.lowerValue > maxLowerValue) {
                            maxLowerValue = reward.lowerValue;
                        } else {
                            errorTip = services.language.getText('mjjexydxdysydje');
                            return false;
                        }
                        if (!reward.stepValue) {
                            errorTip = services.language.getText('qsrmmje');
                            return false;
                        }
                        //2.验证奖励项是否已填写或选择（必须选择其中一项）
                        let checkoutNum = 0;
                        if (reward.pointChecked && reward.pointChecked === true) {
                            checkoutNum = checkoutNum + 1;
                            if (!reward.point || reward.point === '') {
                                errorTip = services.language.getText('qtxxzhdjfz');
                                return false;
                            }
                        }
                        if (reward.couponsChecked && reward.couponsChecked === true) {
                            checkoutNum = checkoutNum + 1;
                            if (!reward.couponsValue || reward.couponsValue.length === 0) {
                                errorTip = services.language.getText('qxzyhq');
                                return false;
                            }
                        }
                        if (checkoutNum === 0) {
                            errorTip = services.language.getText('qgxjlx');
                            return false;
                        }
                    });
                });
                if (errorTip) {
                    AntMessage.warning(errorTip);
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/stored-value-card-buy-activity',
                    component: 'StoredValueCardBuyActivityView',
                    breadcrumbName: '<<storedValueCardBuyActivity>>',
                    privilege: {
                        path: 'storedValueCardRewardActivity',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'StoredValueCardBuyActivityAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'StoredValueCardBuyActivityEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        {
                            path: '/copy/:id',
                            component: 'StoredValueCardBuyActivityCopyPage',
                            breadcrumbName: '<<copy>>',
                        },
                        { path: '/', component: 'StoredValueCardBuyActivityPage' },
                    ],
                },
            ],
        },
    ],
};
