import React from 'react';
import { ColumnProps } from 'antd/lib/table';
import { services } from '@comall-backend-builder/core';

const { getText } = services.language;

/**
 * 悬浮按钮
 */
export interface FloatingActionButton {
    /**
     * 悬浮按钮类型
     */
    type: FloatingActionType;
    name: string;
    /**
     * 是否勾选展示
     */
    visible: boolean;
    /**
     * 按钮图标
     */
    icon: FloatingActionIcon;
    /**
     * 自定义按钮内容-当type为CUSTOM自定义按钮时有此字段
     */
    link?: CustomFloatingActionButtonLink;
}

export interface FloatingActionIcon {
    custom: boolean;
    url?: string;
}

export enum FloatingActionType {
    /**
     * 回到顶部
     */
    ToTop = 0,
    /**
     * 在线客服
     */
    Contact = 1,
    /**
     * 购物车
     */
    Cart = 2,
    /**
     * 订单列表
     */
    OrderList = 3,
    /**
     * 三方客服
     */
    ThirdContact = 4,
    /**
     * 赠送好友
     */
    Transfer = 5,
    /**
     * 自定义按钮
     */
    Custom = 99,
}

export interface CustomFloatingActionButtonLink {
    linkType: string;
    linkParams: any;
}

export const defaultCustomButton: FloatingActionButton = {
    type: FloatingActionType.Custom,
    visible: false,
    icon: {
        custom: true,
        url: '',
    },
    name: '',
    link: {
        linkType: '',
        linkParams: '',
    },
};

const defaultValue: FloatingActionButton[] = [
    {
        type: FloatingActionType.ToTop,
        visible: false,
        name: 'hddb',
        icon: {
            custom: false,
        },
    },
    {
        type: FloatingActionType.Contact,
        visible: false,
        icon: {
            custom: false,
        },
        name: 'zxkf',
    },
    {
        type: FloatingActionType.Cart,
        visible: false,
        icon: {
            custom: false,
        },
        name: 'gwcy',
    },
    {
        type: FloatingActionType.OrderList,
        visible: false,
        icon: {
            custom: false,
        },
        name: 'ddlb',
    },
    {
        type: FloatingActionType.ThirdContact,
        visible: false,
        icon: {
            custom: false,
        },
        name: 'sfkf',
    },
    {
        type: FloatingActionType.Transfer,
        visible: false,
        icon: {
            custom: false,
        },
        name: 'zshy',
    },
];

export function getDefaultValue() {
    return defaultValue.map((i) => ({ ...i, name: getText(i.name) }));
}

export function getConfigDefaultValue() {
    return defaultValue.map((i) => ({ ...i, name: `<<${i.name}>>` }));
}

export type FloatingActionButtonTableRow = FloatingActionButton & {
    sequence: number;
};

export const FloatingActionButtonDefalutIcons = {
    [FloatingActionType.Cart]: 'https://b-puzhehei-cdn.co-mall.net/global/cart.png',
    [FloatingActionType.OrderList]: 'https://b-puzhehei-cdn.co-mall.net/global/ordersList.png',
    [FloatingActionType.ThirdContact]: 'https://b-puzhehei-cdn.co-mall.net/global/thirdContact.png',
    [FloatingActionType.ToTop]: 'https://b-puzhehei-cdn.co-mall.net/global/toTop.png',
    [FloatingActionType.Contact]: 'https://b-puzhehei-cdn.co-mall.net/global/contact.png',
    [FloatingActionType.Transfer]: 'https://b-puzhehei-cdn.co-mall.net/global/transfer-coupon.png',
    [FloatingActionType.Custom]: '',
};

const tableColumns: ColumnProps<FloatingActionButtonTableRow>[] = [
    {
        title: 'childWindow',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
    },
    {
        title: 'tplx',
        dataIndex: 'icon.custom',
        key: 'icon.custom',
        render: (value) => {
            return getText(value ? 'custom' : 'mr');
        },
        align: 'center',
    },
    {
        title: 'pictures',
        dataIndex: 'icon',
        key: 'icon.url',
        render: (icon, record) => {
            const url = icon.custom ? icon.url : FloatingActionButtonDefalutIcons[record.type];
            if (!url) {
                return <span></span>;
            }
            return <img alt="" style={{ width: '64px', height: '64px' }} src={url} />;
        },
        align: 'center',
    },
];
export function getTableColumns() {
    return tableColumns.map((c) => ({ ...c, title: getText(c.title as string) }));
}
export enum CustomLinkType {
    Navigator = 'navigator',
    Popup = 'popup',
}
