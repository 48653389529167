import React, { useCallback } from 'react';
import { copyToClipboard } from '../../services/utils';
import { Button, Col, Icon, Row } from 'antd';
import { services } from '@comall-backend-builder/core';

interface PaymentAllocationRecordVO {
    /**
     * 分账金额简述
     */
    allocationAmountDesc: string;
    /**
     * 分账信息
     */
    allocationInfos: {
        /**
         * 账户名称
         */
        accountName: string;
        /**
         * 账户号
         */
        accountNumber: string;
        /**
         * 账户类型
         */
        accountType: 'DOMESTIC' | 'FOREIGN';
        /**
         * 分账金额
         */
        amount: number;
        /**
         * 分账比例
         */
        ratio: number;
    }[];
    /**
     * 分账百分比简述
     */
    allocationRatioDesc: string;
    /**
     * 分账备注
     */
    allocationRemark: string;
    /**
     * 分账状态
     */
    allocationState: 'PROCESSING' | 'SUCCESS' | 'FAIL';
    /**
     * 分账时间
     */
    allocationTime: string;
    /**
     * 订单编号
     */
    orderNo: string;
    /**
     * 订单状态描述
     */
    orderStateDesc: string;
    /**
     * 三方订单号
     */
    outOrderNo: string;
    /**
     * 支付完成时间
     */
    paidTime: string;
    /**
     * 门店信息
     */
    subsiteVo: {
        /**
         * 门店id
         */
        subsiteId: number;
        /**
         * 门店名称
         */
        subsiteName: string;
    };
    /**
     * 订单分账总金额
     */
    totalAllocationAmount: number;
}
export function PaymentAllocationOrderListItem(props: PaymentAllocationRecordVO) {
    const {
        orderNo,
        outOrderNo,
        subsiteVo: { subsiteName },
        orderStateDesc,
        paidTime,
        totalAllocationAmount,
        allocationState,
        allocationTime,
        allocationRatioDesc,
        allocationAmountDesc,
    } = props;
    const onCopyOrderNo = useCallback(() => {
        copyToClipboard(orderNo);
    }, [orderNo]);
    const onCopyOutOrderNo = useCallback(() => {
        copyToClipboard(outOrderNo);
    }, [outOrderNo]);

    return (
        <div style={{ overflow: 'hidden' }}>
            <Col>
                <Row
                    gutter={[24, 24]}
                    style={{ backgroundColor: '#eee' }}
                    type="flex"
                    align="middle"
                >
                    <Col span={8}>
                        {services.language.getText('orderNumber')}:
                        <Button type="link" onClick={onCopyOrderNo}>
                            {orderNo}
                            <Icon type="copy" />
                        </Button>
                    </Col>
                    <Col span={8}>
                        外部订单号:
                        {outOrderNo ? (
                            <Button type="link" onClick={onCopyOutOrderNo}>
                                {outOrderNo}
                                <Icon type="copy" />
                            </Button>
                        ) : null}
                    </Col>
                    <Col span={4}>门店: {subsiteName}</Col>
                    <Col span={4}>分账状态: {getAllocationStateLabel(allocationState)}</Col>
                </Row>
                <Row
                    gutter={[24, 24]}
                    type="flex"
                    align="middle"
                    style={{ backgroundColor: '#fff' }}
                >
                    <Col span={8}>支付时间: {paidTime}</Col>
                    <Col span={8}>
                        分账总额（{services.language.getText('yuan')}）: {totalAllocationAmount}
                    </Col>
                    <Col span={8}>订单状态: {orderStateDesc}</Col>
                </Row>
                <Row
                    gutter={[24, 24]}
                    type="flex"
                    align="middle"
                    style={{ backgroundColor: '#fff' }}
                >
                    <Col span={8}>分账时间: {allocationTime}</Col>
                    <Col span={8}>分账比例: {allocationRatioDesc}</Col>
                    <Col span={8}>
                        分账金额（{services.language.getText('yuan')}）: {allocationAmountDesc}
                    </Col>
                </Row>
            </Col>
        </div>
    );
}

function getAllocationStateLabel(state: 'PROCESSING' | 'SUCCESS' | 'FAIL') {
    return (
        {
            PROCESSING: '分账中',
            SUCCESS: '已分账',
            FAIL: '分账失败',
        }[state] || '未知'
    );
}
