import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { SelectTag } from '../../../containers';
import { TagSelectedDisplay } from '../../../components';

export class TagsSelectorMode extends modes.ArrayMode {
    getDisplayComponent(value: any) {
        return (
            <TagSelectedDisplay
                data={value}
                onChange={() => {}}
                onRemove={() => {}}
                disabled={true}
            ></TagSelectedDisplay>
        );
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <SelectTag {...displayInfo} />;
    }
}
