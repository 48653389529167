import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { MallActivityCelable } from './mall-activity-celable';

export class ObjectMallActivityCelable extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <MallActivityCelable {...controlInfo} />;
    }
}
