import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { MerchantTagStatus } from '../config/merchant/merchant-manager/merchant-tag';

const api = services.api;
export const MerchantBigCodesLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = '/admin/merchant_big_codes';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/merchant_big_codes/${id}`;
        }
        if (!id) {
            if (!data.subsiteIds) {
                delete data.subsiteIds;
            }
            if (!data.merchantId) {
                delete data.merchantId;
            }
            if (data.allowPriceAdjustment === MerchantTagStatus.ALL) {
                delete data.allowPriceAdjustment;
            }
            if (data.enabled === MerchantTagStatus.ALL) {
                delete data.enabled;
            }
            if (data.supportInvoicing === MerchantTagStatus.ALL) {
                delete data.supportInvoicing;
            }
            if (data.allowDeposit === MerchantTagStatus.ALL) {
                delete data.allowDeposit;
            }
        }

        return await api.get(data, config).then((res: any) => {
            if (id) {
                const merchantInfo = {
                    merchantId: res?.merchantInfo?.id,
                    subSiteName: res?.subsiteInfo?.name,
                    merchantName: res?.merchantInfo?.name,
                    merchantType: res?.merchantInfo?.type,
                    merchantStatus:
                        res.merchantInfo?.status === 'PREPARE'
                            ? '筹备'
                            : res.merchantInfo?.status === 'OPEN'
                            ? '开店'
                            : '暂停',
                    ...res.merchantInfo,
                };
                const barCodes: any = [];
                res.barCodes &&
                    res.barCodes.forEach((e: any) => {
                        barCodes.push({ name: e });
                    });
                res.baseInfo = {
                    allowPriceAdjustment: res.allowPriceAdjustment ? 'true' : 'false',
                    bigCode: res.bigCode,
                    merchants: [merchantInfo],
                    name: res.name || '',
                    merchantBigName: res.bigCode,
                    price: res.price || '',
                    enabled: res.enabled ? 'true' : 'false',
                };
                res.settingInfo = {
                    allowDeposit: res.allowDeposit + '',
                    supportInvoicing: res.taxRateInfo?.supportInvoicing ? 'true' : 'false',
                    name: res?.taxRateInfo?.name,
                    taxCode: res?.taxRateInfo?.taxCode,
                    taxRate: {
                        exchangeAmount: res?.taxRateInfo?.taxRate,
                        exchangeRatio: res?.taxRateInfo?.taxRateType,
                    },
                    sequence: res.sequence,
                    settlementPrompt: res.settlementPrompt ? 'true' : 'false',
                    barCodes: barCodes,
                    pictures: res.pictureUrl
                        ? [
                              {
                                  id: res.pictureId,
                                  path: res.pictureUrl,
                              },
                          ]
                        : [],
                    orderDelayedSyncDate: res.orderDelayedSyncDate,
                };

                return res;
            } else {
                res.result = res.result.map((item: any) => {
                    item.baseInfo = {
                        bigCode: item.bigCode,
                        name: item.name,
                        merchantBigName: item.bigCode,
                        price: item.price ? Number(item.price) : '',
                        allowPriceAdjustment: item.allowPriceAdjustment,
                        subsiteName: item?.subsiteInfo?.name,
                        merchantName: item?.merchantInfo?.name,
                        merchantType: item?.merchantInfo?.type,
                        enabled: item.enabled ? 'true' : 'false',
                    };
                    item.settingInfo = {
                        pictures: item.pictureUrl
                            ? [
                                  {
                                      id: item.pictureId,
                                      path: item.pictureUrl,
                                  },
                              ]
                            : [
                                  {
                                      id: null,
                                      path: null,
                                  },
                              ],
                        supportInvoicing: item.supportInvoicing,
                        listAllowDepositValue: item.allowDeposit,
                        sequence: item.sequence,
                    };
                    item.subsiteId = item.subsiteInfo?.id;
                    item.merchantId = item.merchantInfo?.id;

                    return item;
                });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = '/admin/merchant_big_codes';
        const barCodesArrList: any[] = [];
        const barCodes = params?.settingInfo?.barCodes || [];
        barCodes.forEach((item: { name: any }) => {
            barCodesArrList.push(item.name);
        });

        const newparams = {
            allowPriceAdjustment: params?.baseInfo?.allowPriceAdjustment === 'true' ? true : false,
            barCodes: barCodesArrList,
            bigCode: params?.baseInfo?.bigCode,
            enabled: true,
            merchantId: params?.baseInfo?.merchants[0]?.merchantId
                ? parseInt(params?.baseInfo?.merchants[0]?.merchantId)
                : 0,
            name: params?.baseInfo?.name,
            pictureUrl:
                (params?.settingInfo?.pictures && params?.settingInfo?.pictures[0]?.path) || '',
            pictureId:
                (params?.settingInfo?.pictures && params?.settingInfo?.pictures[0]?.id) || '',
            price: params?.baseInfo?.price,
            sequence: params?.settingInfo?.sequence,
            taxRateInfo: {
                name: params?.settingInfo?.name,
                supportInvoicing: params?.settingInfo?.supportInvoicing === 'true' ? true : false,
                taxCode: params?.settingInfo?.taxCode,
                taxRate: params?.settingInfo?.taxRate?.exchangeAmount,
                taxRateType: params?.settingInfo?.taxRate?.exchangeRatio
                    ? params?.settingInfo?.taxRate?.exchangeRatio
                    : 'STANDARD',
            },
            allowDeposit: params.settingInfo?.allowDeposit,
            orderDelayedSyncDate: params.settingInfo?.orderDelayedSyncDate,
            settlementPrompt: params.settingInfo?.settlementPrompt === 'true' ? true : false,
        };
        if (newparams.taxRateInfo.taxRateType !== 'STANDARD') {
            delete newparams.taxRateInfo.taxRate;
        }
        if (params?.settingInfo?.supportInvoicing === 'false') {
            newparams.taxRateInfo.name = '';
            newparams.taxRateInfo.taxCode = '';
            delete newparams.taxRateInfo.taxRate;
            delete newparams.taxRateInfo.taxRateType;
        }

        return await api.post(newparams, config);
    },

    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = '/admin/merchant_big_codes';
        if (params.id) {
            config.apiPath = `/admin/merchant_big_codes/${params.id}`;
        }
        const barCodesArrList: any[] = [];
        const barCodes = params?.settingInfo?.barCodes || [];
        barCodes.forEach((item: { name: any }) => {
            barCodesArrList.push(item.name);
        });

        /* {
            allowPriceAdjustment: true,是否允许调整价格
            barCodes: [关联商品条码集合
              "string"
            ],
            bigCode: "ABC123",大码编码
            enabled: true,是否启用
            merchantId: 1001,专柜ID
            name: "大码A",大码名称
            pictureUrl: "https://example/image.jpg",图片URL
            price: 99.99,大码价格
            sequence: 1,排序序号
            taxRateInfo: {	
专柜商家码开票信息命
              name: 123456000001,开票名称
              supportInvoicing: true,是否支持开票 
              taxCode: 123456000001,税收分类编码
              taxRate: 13,税率
              taxRateType: "DUTY_FREE"税收分类免税类型 STANDARD, DUTY_FREE, NO_TAX, ZERO_RATE, EXPORT_TAX_REBATE
            }
          } */
        const newparams = {
            allowPriceAdjustment: params?.baseInfo?.allowPriceAdjustment === 'true' ? true : false,
            barCodes: barCodesArrList,
            bigCode: params?.baseInfo?.bigCode,
            enabled: params?.baseInfo?.enabled,
            merchantId: params?.baseInfo?.merchants[0]?.merchantId
                ? parseInt(params?.baseInfo?.merchants[0]?.merchantId)
                : 0,
            name: params?.baseInfo?.name,
            pictureUrl:
                (params?.settingInfo?.pictures && params?.settingInfo?.pictures[0]?.path) || '',
            pictureId:
                (params?.settingInfo?.pictures && params?.settingInfo?.pictures[0]?.id) || '',
            price: params?.baseInfo?.price,
            sequence: params?.settingInfo?.sequence,
            taxRateInfo: {
                name: params?.settingInfo?.name,
                supportInvoicing: params?.settingInfo?.supportInvoicing === 'true' ? true : false,
                taxCode: params?.settingInfo?.taxCode,
                taxRate: params?.settingInfo?.taxRate?.exchangeAmount,
                taxRateType: params?.settingInfo?.taxRate?.exchangeRatio
                    ? params?.settingInfo?.taxRate?.exchangeRatio
                    : 'STANDARD',
            },
            allowDeposit: params.settingInfo?.allowDeposit,
            orderDelayedSyncDate: params.settingInfo?.orderDelayedSyncDate,
            settlementPrompt: params.settingInfo?.settlementPrompt === 'true' ? true : false,
        };
        if (newparams.taxRateInfo.taxRateType !== 'STANDARD') {
            delete newparams.taxRateInfo.taxRate;
        }
        if (params?.settingInfo?.supportInvoicing === 'false') {
            newparams.taxRateInfo.name = '';
            newparams.taxRateInfo.taxCode = '';
            delete newparams.taxRateInfo.taxRate;
            delete newparams.taxRateInfo.taxRateType;
        }
        return await api.put(newparams, config);
    },

    delete: async function(params: any, config: any) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = '/admin/merchant_big_codes/batch_delete';
        params.bigCodeIds = [`${params.id}`];
        delete params.id;
        return await api.put(params, config);
    },
};
