import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { services } from '@comall-backend-builder/core';

export class StoreStatusMode extends modes.NumberMode {
    /**
     * 获取展示组件
     */
    getDisplayComponent(value: number): JSX.Element {
        return (
            <span
                title={
                    value === 1
                        ? services.language.getText('subsiteStatusEnable')
                        : services.language.getText('notBusiness')
                }
                className="goods-field-name"
            >
                {value === 1
                    ? services.language.getText('subsiteStatusEnable')
                    : services.language.getText('notBusiness')}
            </span>
        );
    }
}
