import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
export const CrossborderTradeConfigLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`;
        config.apiPath = '/admin/crossborder_trade_config';

        return await api.get(data, config).then((res: any) => {
            res.limitRule = {
                id: res.id,
            };
            return res;
        });
    },

    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`;
        config.apiPath = '/admin/crossborder_trade_config';
        params.id = params.limitRule.id;
        return await api.put(params, config);
    },
};
