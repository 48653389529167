export enum RegisterchannelEnum {
    CRM = 'CRM',
    MAGIC_WECHAT = 'MAGIC_WECHAT',
    MAGIC_ALIPAY = 'MAGIC_ALIPAY',
    MAGIC_ANDROID = 'MAGIC_ANDROID',
    MAGIC_IOS = 'MAGIC_IOS',
    MAGIC_PC = 'MAGIC_PC',
    MAGIC_BYTE_DANCE = 'MAGIC_BYTE_DANCE',
}

export interface FilterParams {
    mobile?: string;
    crmMemberId?: string;
    memberId?: string;
    cid?: string;
    registerSubsiteCode?: string;
    registerChannel?: RegisterchannelEnum;
    registerStartTime?: string;
    registerEndTime?: string;
    cardLevelId?: string;
    gender?: MemberInfoGender;
    memberStatus?: MemberInfoStatus;
    firstLoginSubsiteCode?: string;
    firstLoginStartTime?: string;
    firstLoginEndTime?: string;
    lastLoginStartTime?: string;
    lastLoginEndTime?: string;
    scanSource?: string;
    registerUtmMerchantName?: string;
    registerUtmGuideUserMobile?: string;
    wechatOfficialName?: string;
    wechatOfficialAccountFanStatus?: WechatOfficialAccountFanStatus;
    registerUtmTypeCode?: string;
    registerUtmItemName?: string;
    birthdayStartTime?: string;
    birthdayEndTime?: string;
    tagSubsiteId?: string;
    tagId?: string;
    memberTags?: string[];
    crowdId?: string;
    memberCrowds?: string[];
}

export interface Member {
    mobile: string;
    crmMemberId?: string;
    memberId: string;
    cid?: string;
    registerSubsiteName: string;
    registerTime: string;
    registerChannel: RegisterchannelEnum;
    point?: string;
    cardLevelName?: string;
    firstLoginSubsiteName: string;
    firstLoginTime: string;
    lastLoginTime: string;
    scanSource?: string;
    registerByGuideMerchantName?: string;
    registerByGuideName?: string;
    wechatOfficialAccounts?: string;
    memberStatus?: MemberInfoStatus;
}

export interface Subsite {
    id: string;
    name: string;
    code: string;
}

export interface SelectOption {
    id: string;
    name: string;
}

export interface SelecterProps {
    options: SelectOption[];
    placeholder?: string;
}

export enum MemberInfoGender {
    MAN = 'MAN',
    WOMAN = 'WOMAN',
    UNKNOWN = 'UNKNOWN',
}
export enum MemberInfoStatus {
    NORMAL = 'NORMAL',
    INVALID = 'INVALID',
    OFF = 'OFF',
    UNKNOWN = 'UNKNOWN',
}

export enum WechatOfficialAccountFanStatus {
    SUBSCRIBE = 'SUBSCRIBE',
    UN_SUBSCRIBE = 'UN_SUBSCRIBE',
}
