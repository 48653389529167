import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';
import { message } from 'antd';

const api = services.api;
export const OfflineOrderReviewLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        if (!isEmpty(data.dateRange)) {
            data.createStartTime = data.dateRange.start + ' 00:00:00';
            data.createEndTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        return await api.get(data, config).then((res: any) => {
            res.result = res.result.map((item: any) => {
                item.orderType = item.offlinePosOrder ? '线下订单' : '线上订单';
                item.path = item.offlinePosOrder ? 'offline-detail' : 'detail';
                return item;
            });
            return res;
        });
    },
};

export const offlineOrdersLoader: Loader = {
    get: async function(data, config) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`;
        config.apiPath = '/admin/orders';
        if (!isEmpty(data.memberMobile) || !isEmpty(data.receiverMobile)) {
            return await api.get(data, config).then((res: any) => {
                res.result = res.result.map((item: any) => {
                    return {
                        ...item,
                        subsiteName: item.subsite.name,
                        nickName: item.member.nickName,
                        mobile: item.member.mobile,
                        receiverMobile: item.orderDelivery?.mobile,
                    };
                });

                return res;
            });
        }
        message.warning('请输入搜索条件查询后再进行操作！');
        return {
            page: 1,
            perPage: 10,
            result: [],
            totalNum: 0,
            totalPage: 0,
        };
    },
};
