import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { remove, get, map } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Modal, Menu, message } from 'antd';
import { ProductCategorySelectMode } from '../select-product-category';
import { ProductImportConfig } from '../..';
import { ImportProductsModal } from '../../products-selector-plus/import-products-modal';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
// import { services } from '@comall-backend-builder/core';

// const api = services.api;
// const TreeNode = Tree.TreeNode;

const TableComponentId = 'ProductsSelectorDataTable';
const BrandsTableComponentId = 'BrandsSelectorDataTable';
const MerchantBigCodeComponentId = 'MerchantBigCodeSelectorDataTable';
let Entity: any = {};
let productsEntity: any = {};
let brandsEntity: any = {};
let merchantBigCodeEntity: any = {};

/**
 * 选择商品，品牌组件
 */
class productsCategorySelector extends PureComponent<{
    onChange: (value: { values: any[]; type: string }, overwrite?: boolean) => void;
    params?: any;
    buttonText?: string;
    requestStatus: string;
    rowSelectionType?: string;
    selectMode: string;
    activityProductType: string;
    disableBand?: boolean;
    isSupportMerchantBigCode?: boolean;
    disabled?: boolean;
    verifyTip?: string;
    importConfig?: ProductImportConfig;
    /**
     * 目前选中的tab
     */
    displayType?: string;
    filterFields?: any;
}> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('CustomDesignProductSelector');
        productsEntity = new Entity({});
        Entity = EntitiesManager.get('BrandsSelector');
        brandsEntity = new Entity({});
        Entity = EntitiesManager.get('MerchantBigCodeSelector');
        merchantBigCodeEntity = new Entity({});
    }
    dispatch: any = {};
    state = {
        visible: false,
        selectType: 'APPOINT_PRODUCT',
        categories: [],
        loadingCategories: false,
        virtualCategories: [],
        importModalVisible: false,
    };

    selectedRows: any[] = [];
    selectedBrandsRows: any[] = [];
    selectedMerchantBigCodeRows: any[] = [];
    selectNodes: any[] = [];

    onTableRowSelect = (record: any, selected: boolean) => {
        const { rowSelectionType } = this.props;
        if (rowSelectionType === 'radio') {
            this.selectedRows = [record];
        } else {
            if (selected) {
                this.selectedRows.push(record);
            } else {
                remove(this.selectedRows, (i) => {
                    return i.id === record.id;
                });
            }
        }
    };

    onBrandsTableRowSelect = (record: any, selected: boolean) => {
        if (selected) {
            this.selectedBrandsRows.push(record);
        } else {
            remove(this.selectedBrandsRows, (i) => {
                return i.id === record.id;
            });
        }
    };

    // getCategoryList = () => {
    //     this.setState({ loadingCategories: true });
    //     const apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
    //     const apiPath = '/admin/categories/tree';
    //     api.get({}, { apiRoot, apiPath }).then((res: any) => {
    //         this.setState({ categories: res, loadingCategories: false });
    //     });
    // };

    onTableRowSelectAll = (selected: boolean, rows: any[]) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            this.selectedRows = [];
        }
    };
    onBrandsTableRowSelectAll = (selected: boolean, rows: any[]) => {
        if (selected) {
            this.selectedBrandsRows = rows;
        } else {
            this.selectedBrandsRows = [];
        }
    };
    componentWillReceiveProps(nextProps: any) {
        if (
            nextProps.selectMode !== ProductCategorySelectMode.SUB_SITE &&
            this.props.selectMode === ProductCategorySelectMode.SUB_SITE
        ) {
            this.setState({ selectType: 'APPOINT_PRODUCT' });
            nextProps.onChange({ values: [], type: 'APPOINT_PRODUCT' });
        }
        if (nextProps.requestStatus === 'success' && this.props.requestStatus === 'pending') {
            const selectedRows = this.selectedRows;
            if (selectedRows && selectedRows.length > 0)
                setTimeout(() => {
                    this.dispatch(
                        actions.tableRowSelectionChangeAction(
                            TableComponentId,
                            map(selectedRows, 'id')
                        )
                    );
                }, 300);
        }

        //如果活动商品发生变化，则清空数据
        const nextActivityProductType = nextProps.activityProductType;
        const currentActivityProductType = this.props.activityProductType;
        if (nextActivityProductType !== currentActivityProductType) {
            // 如果选择模式发生变化，清空选中数据
            this.onClearAll();
        }
    }

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(productsEntity));
        this.dispatch(actions.unmountEntityAction(brandsEntity));
        this.dispatch(actions.unmountEntityAction(merchantBigCodeEntity));
    }

    onClearAll = () => {
        this.selectedRows = [];
        this.selectedBrandsRows = [];
        this.selectedMerchantBigCodeRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
        this.dispatch(actions.tableRowSelectionChangeAction(BrandsTableComponentId, []));
        this.dispatch(actions.tableRowSelectionChangeAction(MerchantBigCodeComponentId, []));
        this.setState({ selectType: 'APPOINT_PRODUCT' });
    };

    onOk = () => {
        const { onChange } = this.props;
        const { selectType } = this.state;
        if (onChange) {
            if (selectType === 'APPOINT_CATEGORY') {
                onChange({ values: this.selectNodes, type: selectType });
            } else if (selectType === 'APPOINT_BRAND') {
                onChange({ values: this.selectedBrandsRows, type: selectType });
            } else if (selectType === 'APPOINT_MERCHANT_BIG_CODE') {
                onChange({ values: this.selectedMerchantBigCodeRows, type: selectType });
            } else {
                onChange({ values: this.selectedRows, type: selectType });
            }
            this.toggleModal();
        }
    };

    getConfig = (
        entity: any,
        fields: any[],
        columns: any[],
        componentId: string,
        onTableRowSelect: Function,
        onTableRowSelectAll: Function
    ) => {
        let { params, rowSelectionType } = this.props;
        if (!rowSelectionType) {
            rowSelectionType = 'checkbox';
        }
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: params || {},
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'right' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        entity.pageChange(Object.assign({}, params, entity.paging, { page: 1 }));
                        entity.search(params);
                    },
                    fields,
                },
                {
                    component: 'DataTable',
                    componentId: componentId,

                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    rowSelection: {
                        type: rowSelectionType,
                        onSelect: (record: any, selected: boolean) => {
                            onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: any) => {
                            onTableRowSelectAll(selected, rows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    columns,
                },
            ],
        };
    };

    getProdutsConfig = () => {
        // let { filterFields } = this.props;

        const fields = [
            {
                property: 'keywords',
                className: 'product-category-selector-keywords',
            },
            {
                property: 'subsiteId',
            },
            {
                property: 'merchantId',
            },
        ];
        const columns = [
            {
                property: 'productInfo',
                width: 350,
            },
            {
                property: 'brandName',
            },
            {
                property: 'goodsMvo.subsiteName',
            },
            {
                property: 'goodsMvo.merchantName',
            },
        ];
        return this.getConfig(
            productsEntity,
            fields,
            columns,
            TableComponentId,
            this.onTableRowSelect,
            this.onTableRowSelectAll
        );
    };

    getBrandsConfig = () => {
        const fields = [
            {
                property: 'name',
            },
        ];
        const columns = [
            {
                property: 'name',
            },
            {
                property: 'nameEn',
            },
        ];
        return this.getConfig(
            brandsEntity,
            fields,
            columns,
            BrandsTableComponentId,
            this.onBrandsTableRowSelect,
            this.onBrandsTableRowSelectAll
        );
    };
    getMerchantBigCodeConfig = () => {
        const fields = [
            {
                property: 'bigCode',
            },
            {
                property: 'name',
            },
        ];
        const columns = [
            {
                property: 'code',
                displayConfig: {
                    className: 'ant-table-cell-ellipsis',
                    style: {
                        width: 130,
                    },
                },
            },
            {
                property: 'name',
                displayConfig: {
                    className: 'ant-table-cell-ellipsis',
                    style: {
                        width: 150,
                    },
                },
            },
            {
                property: 'subsiteName',
                displayConfig: {
                    className: 'ant-table-cell-ellipsis',
                    style: {
                        width: 130,
                    },
                },
            },
            {
                property: 'merchantName',
                displayConfig: {
                    className: 'ant-table-cell-ellipsis',
                    style: {
                        width: 130,
                    },
                },
            },
        ];
        return this.getConfig(
            merchantBigCodeEntity,
            fields,
            columns,
            MerchantBigCodeComponentId,
            this.onMerchantBigCodeTableRowSelect,
            this.onMerchantBigCodeTableRowSelectAll
        );
    };

    onMerchantBigCodeTableRowSelectAll = (selected: boolean, rows: any[]) => {
        if (selected) {
            this.selectedMerchantBigCodeRows = rows;
        } else {
            this.selectedMerchantBigCodeRows = [];
        }
    };

    onMerchantBigCodeTableRowSelect = (record: any, selected: boolean) => {
        if (selected) {
            this.selectedMerchantBigCodeRows.push(record);
        } else {
            remove(this.selectedMerchantBigCodeRows, (i) => {
                return i.id === record.id;
            });
        }
    };

    private checkSubsiteOrMerchantExist = () => {
        const { params, verifyTip = language.getText('qxxzmdhzj') } = this.props;
        const { subsiteIds, merchantIds } = params;
        if (
            (!subsiteIds || subsiteIds.length === 0) &&
            (!merchantIds || merchantIds.length === 0)
        ) {
            message.warning(verifyTip);
            return false;
        }
        return true;
    };

    toggleModal = () => {
        const { displayType } = this.props;
        let entity = productsEntity;
        if (displayType === 'APPOINT_BRAND') {
            entity = brandsEntity;
        } else if (displayType === 'APPOINT_MERCHANT_BIG_CODE') {
            entity = merchantBigCodeEntity;
        }
        if (this.checkSubsiteOrMerchantExist()) {
            const { params } = this.props;
            const { visible } = this.state;
            this.setState({ visible: !visible }, () => {
                if (!visible && entity.paging) {
                    entity.search({
                        ...entity.paging,
                        ...params,
                    });
                    // this.onClearAll();
                }
            });
        }
    };

    private showImportModal = () => {
        if (this.checkSubsiteOrMerchantExist()) {
            this.setState({ importModalVisible: true });
        }
    };

    private hideImportModal = () => {
        this.setState({ importModalVisible: false });
    };

    onSelectKey = (key: string) => {
        // const { categories, loadingCategories } = this.state;
        // if (key === 'APPOINT_CATEGORY' && categories.length === 0 && !loadingCategories) {
        //     this.getCategoryList();
        // }
        this.setState({
            selectType: key,
        });
    };

    onImportSuccess = (data: any) => {
        const { onChange } = this.props;
        const { selectType } = this.state;
        onChange({ values: data.products, type: selectType }, true);
        this.hideImportModal();
    };

    // onSelectNormalCategory = (selectedKeys: any, e: any) => {
    //     const lastNodes: any = [];
    //     console.log(e);
    //     const nodes = e.checkedNodes.map((node: any) => node.props);
    //     const newNodes: any = [];
    //     for (let i = 0; i < nodes.length; i++) {
    //         if (nodes[i].children) {
    //             newNodes.push(nodes[i].dataRef);
    //             nodes[i].children.forEach((item: any) => {
    //                 const index = nodes.findIndex((node: any) => node.key === item.key);
    //                 if (index !== -1) {
    //                     nodes.splice(index, 1);
    //                 }
    //             });
    //         } else {
    //             newNodes.push(nodes[i]);
    //         }
    //     }
    //     console.log(newNodes);
    //     forEach(e.checkedNodes, (node) => {
    //         if (!node.props || node.props.isLeaf) {
    //             const lastNode = { ...node.props };
    //             delete lastNode.isLeaf;
    //             lastNodes.push(lastNode);
    //         }
    //     });
    //     this.selectNodes = lastNodes;
    // };

    // renderTreeNodes = (data: any) => {
    //     return data.map((item: any) => {
    //         if (item.childrens) {
    //             return (
    //                 <TreeNode title={item.name} key={item.id} dataRef={item}>
    //                     {this.renderTreeNodes(item.childrens)}
    //                 </TreeNode>
    //             );
    //         }
    //         return <TreeNode key={item.id} title={item.name} isLeaf {...item} />;
    //     });
    // };
    // renderNormalCategoryTree = () => {
    //     const { categories, loadingCategories } = this.state;
    //     return loadingCategories ? (
    //         <Spin />
    //     ) : (
    //         <Tree checkable showLine onCheck={this.onSelectNormalCategory}>
    //             {this.renderTreeNodes(categories)}
    //         </Tree>
    //     );
    // };

    render() {
        const {
            buttonText = language.getText('xzsp_1'),
            selectMode,
            activityProductType,
            disableBand,
            isSupportMerchantBigCode,
            disabled,
            importConfig,
            params,
        } = this.props;
        if (disabled && activityProductType !== 'ALL') {
            return null;
        }

        const productsConfig = this.getProdutsConfig();
        const productsContent = createElement(
            ComponentsManager.get(productsConfig.component),
            productsConfig
        );
        const brandsConfig = this.getBrandsConfig();
        const brandsContent = createElement(
            ComponentsManager.get(brandsConfig.component),
            brandsConfig
        );
        const merchantBigCodeConfig = this.getMerchantBigCodeConfig();
        const merchantBigCodeContent = createElement(
            ComponentsManager.get(merchantBigCodeConfig.component),
            merchantBigCodeConfig
        );
        const modalProps = {
            width: 1000,
            title: language.getText('productSelectRequired'),
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };

        const { selectType, importModalVisible } = this.state;

        return (
            <div style={{ display: 'inline-block' }}>
                {activityProductType === 'ALL' ? (
                    <div>{language.getText('selectAllAttend')}</div>
                ) : (
                    <>
                        <Button type={'link'} onClick={this.toggleModal}>
                            {buttonText}
                        </Button>
                        {importConfig && (
                            <>
                                <Button type="link" onClick={this.showImportModal}>
                                    {services.language.getText('batchImport')}
                                </Button>
                                <ImportProductsModal
                                    visible={importModalVisible}
                                    importConfig={importConfig}
                                    onImportSuccess={this.onImportSuccess}
                                    closeModal={this.hideImportModal}
                                    activityId=""
                                    params={params}
                                />
                            </>
                        )}
                    </>
                )}

                <Modal {...modalProps}>
                    <div style={{ display: 'flex' }}>
                        <Menu
                            selectedKeys={[selectType]}
                            onClick={({ key }) => {
                                this.onSelectKey(key);
                            }}
                        >
                            <Menu.Item key="APPOINT_PRODUCT">{language.getText('zdsp')}</Menu.Item>
                            {selectMode === ProductCategorySelectMode.SUB_SITE && !disableBand && (
                                <Menu.Item key="APPOINT_BRAND">
                                    {language.getText('zdpp')}
                                </Menu.Item>
                            )}
                            {isSupportMerchantBigCode && (
                                <Menu.Item key="APPOINT_MERCHANT_BIG_CODE">
                                    {language.getText('zdsjm')}
                                </Menu.Item>
                            )}
                            {/* <Menu.Item key="APPOINT_CATEGORY">指定标准分类</Menu.Item>
                            <Menu.Item key="APPOINT_VIRTUALCATEGORY">指定虚拟分类</Menu.Item> */}
                        </Menu>
                        <div style={{ padding: '0 20px', width: '100%' }}>
                            {selectType === 'APPOINT_PRODUCT' && productsContent}
                            {selectType === 'APPOINT_BRAND' && brandsContent}
                            {selectType === 'APPOINT_MERCHANT_BIG_CODE' && merchantBigCodeContent}
                            {/* {selectType === 'APPOINT_CATEGORY' && this.renderNormalCategoryTree()} */}
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}

export const ProductsCategorySelector = connect((_state: any) => {
    let requestStatus = get(productsEntity, 'requestStatus', null);
    const result = { requestStatus };
    return result;
})(productsCategorySelector);
