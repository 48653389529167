import React, { PureComponent } from 'react';
import { Input, message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';

const { getText } = services.language;

interface PhoneProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: string, name: string) => void;
    /**
     * 当前值
     */
    value: string;
}

/**
 * 区号-电话
 */
export class Phone extends PureComponent<PhoneProps, {}> {
    changeInputValue = (field: string, changeValue: string) => {
        const { value, name, onChange } = this.props;
        //历史电话
        let areaCode = '';
        let phone = '';
        if (value) {
            const phoneArray = value.split('-');
            if (phoneArray.length > 0) {
                areaCode = phoneArray[0];
            }
            if (phoneArray.length > 1) {
                phone = phoneArray[1];
            }
        }
        var reg = /^[0-9]\d*$/;
        if (changeValue && !reg.test(changeValue)) {
            AntMessage.error(getText('pleaseInputNumber'));
            if (field === 'areaCode') {
                const newValue = `-${phone}`;
                onChange(newValue, name);
            }
            if (field === 'phone') {
                const newValue = `${areaCode}-`;
                onChange(newValue, name);
            }
            return;
        }
        //新电话
        if (field === 'areaCode') {
            const newValue = `${changeValue}-${phone}`;
            onChange(newValue, name);
        }
        if (field === 'phone') {
            const newValue = `${areaCode}-${changeValue}`;
            onChange(newValue, name);
        }
    };

    render() {
        const { value } = this.props;
        let areaCode = '';
        let phone = '';
        if (value) {
            const phoneArray = value.split('-');
            if (phoneArray.length > 0) {
                areaCode = phoneArray[0];
            }
            if (phoneArray.length > 1) {
                phone = phoneArray[1];
            }
        }
        return (
            <div>
                <Input
                    style={{ width: '80px' }}
                    value={areaCode}
                    placeholder={getText('qh_1')}
                    onChange={(e) => this.changeInputValue('areaCode', e.target.value)}
                ></Input>
                <span style={{ margin: '0 10px 0 10px' }}>-</span>
                <Input
                    style={{ width: '120px' }}
                    value={phone}
                    placeholder={getText('zjh_1')}
                    onChange={(e) => this.changeInputValue('phone', e.target.value)}
                ></Input>
            </div>
        );
    }
}
