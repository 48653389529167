import React, { PureComponent, createElement } from 'react';
import { message as AntMessage, Button, Popconfirm } from 'antd';
import { remove } from 'lodash';
import { connect } from 'react-redux';
import { services, ComponentsManager } from '@comall-backend-builder/core';
const api = services.api;
let SELECTED_ROW: any = [];

/**
 * 列表
 */
class merchantBigCodesTable extends PureComponent<any, any> {
    renderComponent() {
        const { entity, componentId } = this.props;
        let config = {
            component: 'DataTable',
            entity,
            componentId,
            loadFirstPage: true,
            rowKey: 'id',
            rowSelection: {
                onChange: () => {},
                onSelect: (record: any, selected: boolean) => {
                    this.onTableRowSelect(record, selected);
                },
                onSelectAll: (selected: boolean, rows: any) => {
                    this.onTableRowSelectAll(selected, rows);
                },
            },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'code',
                },
                {
                    property: 'name',
                },
                {
                    property: 'subsiteName',
                },
                {
                    property: 'merchantName',
                },
            ],
            actionColumn: {
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                fixed: 'right',
                items: [
                    {
                        type: 'delete',
                        text: services.language.getText('common.delete'),
                        config: {
                            type: 'link',
                            tooltip: null,
                            title: services.language.getText('confirmDelete'),
                            okText: services.language.getText('common.ok'),
                            cancelText: services.language.getText('common.cancel'),
                        },
                    },
                ],
            },
            footer: () => {
                return (
                    <Popconfirm
                        onConfirm={this.batchDeleteCodes}
                        title={services.language.getText('confirmDeleteColumn')}
                        okText={services.language.getText('common.ok')}
                        cancelText={services.language.getText('common.cancel')}
                    >
                        <Button className="setting-item-ok" type="default">
                            {services.language.getText('batchDelete')}
                        </Button>
                    </Popconfirm>
                );
            },
        };

        return createElement(ComponentsManager.get('DataTable'), config);
    }

    onTableRowSelect = (record: any, selected: boolean) => {
        if (selected) {
            SELECTED_ROW.push(record);
        } else {
            remove(SELECTED_ROW, (i: any) => {
                return i.id === record.id;
            });
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: any[]) => {
        if (selected) {
            SELECTED_ROW = rows;
        } else {
            SELECTED_ROW = [];
        }
    };

    batchDeleteCodes = () => {
        const { entity } = this.props;
        if (!SELECTED_ROW.length) {
            AntMessage.error(services.language.getText('qxzspm'));
            return;
        }
        let ids = SELECTED_ROW.map((item: any) => item.id).join(',');
        api.delete(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: '/admin/deduction_merchant_big_codes?ids=' + ids,
            }
        ).then(
            () => {
                SELECTED_ROW = [];
                entity.search();
            },
            (error) => {
                services.errorHandle(error);
            }
        );
    };

    render() {
        return <div>{this.renderComponent()}</div>;
    }
}

function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
        fields: entity.fields,
    };
}

export const MerchantBigCodesTableView = connect(mapStateToProps)(merchantBigCodesTable);
