import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export enum MerchantTagStatus {
    ENABLE = 'ENABLED',
    DISABLE = 'DISABLED',
    ALL = 'ALL',
}
export const config: Config = {
    entities: {
        merchantFacilityTagEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/facility_tag',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<tagName>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<tagStatus>>',
                    options: [
                        { id: MerchantTagStatus.ALL, name: '<<selectPlease>>' },
                        { id: MerchantTagStatus.ENABLE, name: '<<inUse>>' },
                        { id: MerchantTagStatus.DISABLE, name: '<<deactivated>>' },
                    ],
                },
                subsiteName: {
                    type: 'object.option.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
            },
            properties: {
                id: {
                    type: 'number',
                },
                name: {
                    displayName: '<<tagName>>',
                    type: 'string.maxLength',
                    rules: [
                        { required: true, message: '<<activityTagRequired>>' },
                        {
                            message: '<<activityTagNotEmpty>>',
                            validator: (rule: any, value: any) => {
                                if (value && !value.trim()) {
                                    return false;
                                }
                                return true;
                            },
                        },
                    ],
                    controlConfig: {
                        maxLength: 6,
                    },
                },
                sequence: {
                    displayName: '<<sequence>>',
                    type: 'number.integer',
                    rules: [{ required: true, message: '<<sequenceRequired>>' }],
                    controlConfig: {
                        placeholder: '<<sortTip>>',
                        max: 99999,
                        min: 0,
                    },
                    displayConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<categoryStatus>>',
                    options: [
                        { id: MerchantTagStatus.ENABLE, name: '<<inUse>>' },
                        { id: MerchantTagStatus.DISABLE, name: '<<deactivated>>' },
                    ],
                },
                subsiteIds: {
                    type: 'array.options.autoComplete',
                    displayName: '<<applicableSubsite>>',
                    options: [],
                    rules: [{ required: true, message: '<<applicableSubsiteRequired>>' }],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectSubsite>>',
                    },
                },
            },
        },
    },
    components: {
        merchantFacilityTagView: {
            component: 'Viewport',
            entity: 'merchantFacilityTagEntity',
        },
        merchantFacilityTagPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'merchantFacilityTagFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    style: {
                        paddingTop: 10,
                        paddingBottom: 10,
                    },
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'SetMerchantFacilityTagModal',
                                    triggerComponent: {
                                        component: 'Button',
                                        icon: 'plus',
                                        type: 'primary',
                                        text: '<<addTag>>',
                                        style: { marginBottom: '20px' },
                                    },
                                },
                            ],
                        },
                    ],
                },

                { component: 'merchantFacilityTagTable' },
            ],
        },
        merchantFacilityTagFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 120 },
                        placeholder: '<<activityTagRequired>>',
                    },
                },
                {
                    property: 'status',
                    controlConfig: {
                        style: { width: 120 },
                        placeholder: '<<selectPlease>>',
                    },
                },
                {
                    property: 'subsiteName',
                    controlConfig: {
                        style: { width: 120 },
                        placeholder: '<<subsiteNameRequired>>',
                    },
                },
            ],
        },
        merchantFacilityTagTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'sequence',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'subsites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'status',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                            apiPath: '/admin/facility_tag/:id/status',
                            handles: [
                                {
                                    params: { status: 'DISABLED' },
                                    value: MerchantTagStatus.ENABLE,
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: {
                                            color: 'red',
                                        },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClosed>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: 'ENABLED' },
                                    value: MerchantTagStatus.DISABLE,
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'component',
                        component: 'SetMerchantFacilityTagModal',
                        config: {
                            edit: true,
                            triggerComponent: {
                                component: 'Button',
                                type: 'link',
                                text: '<<common.edit>>',
                                style: { marginRight: '10px' },
                            },
                        },
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/merchant-facility-tag',
                    component: 'merchantFacilityTagView',
                    breadcrumbName: '<<facilityTag>>',
                    privilege: {
                        path: 'facilityTag',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'merchantFacilityTagPage' }],
                },
            ],
        },
    ],
};
