import React, { PureComponent } from 'react';
import { Row, Col, Input } from 'antd';
import get from 'lodash/get';
import { tools } from '@comall-backend-builder/design-components-basis';
import { ButtonConfig } from './index';
import './index.less';
import { services } from '@comall-backend-builder/core';

interface FlashProductMoreProps {
    value: ButtonConfig;
    selector: any;
    onChange: (args: any) => void;
    uploadAction: string;
    sourceRoot: { image?: string };
    linkTypes: Array<any>;
}

const LinkType = tools.LinkType;
const ColorPicker = tools.ColorPicker;

export default class FlashProductMore extends PureComponent<FlashProductMoreProps, any> {
    getValue = () => {
        const { value } = this.props;

        return {
            bgColor: get(value, 'bgColor', '#F3AA56'),
            title: get(value, 'title', services.language.getText('qdljf')),
            color: get(value, 'color', '#999999'),
            linkParams: get(value, 'linkParams', null),
            linkType: get(value, 'linkType', 'none'),
        };
    };

    changeTitle = (val: string) => {
        const { onChange } = this.props;
        let newValue = this.getValue();
        newValue.title = val;
        onChange({ ...newValue });
    };

    changeColor = (val: string, key: 'bgColor' | 'color') => {
        const { onChange } = this.props;
        let newValue = this.getValue();
        newValue[key] = val;
        onChange({ ...newValue });
    };

    changeLinkTypes = (val: { linkParams: string; linkType: string }) => {
        const { onChange } = this.props;
        let newValue = this.getValue();
        newValue.linkParams = val.linkParams;
        newValue.linkType = val.linkType;
        onChange({ ...newValue });
    };

    render() {
        const { value, selector, linkTypes } = this.props;
        return (
            <Row className="entry-item">
                <Row>
                    <Col span={3}>
                        <label className="field-name">底色:</label>
                    </Col>
                    <Col span={12} className="button-text-picker">
                        <ColorPicker
                            onChange={(val) => this.changeColor(val, 'bgColor')}
                            value={get(value, 'bgColor', '#999999')}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col span={4}>
                        <label className="field-name">按钮名称:</label>
                    </Col>
                    <Col span={8} className="button-input">
                        <Input
                            value={get(value, 'title', '更多')}
                            onChange={(e) => this.changeTitle(e.target.value)}
                        />
                    </Col>
                    <Col span={8} className="button-text-picker">
                        <ColorPicker
                            onChange={(val) => this.changeColor(val, 'color')}
                            value={get(value, 'color', '#999999')}
                        />
                    </Col>
                </Row>
                <Row className="link-row">
                    <Col span={18}>
                        <LinkType
                            linkTypes={linkTypes}
                            linkTypeMode={'cascader'}
                            selector={selector}
                            value={{
                                linkParams: get(value, 'linkParams', null),
                                linkType: get(value, 'linkType', 'none'),
                            }}
                            onChange={(value) => this.changeLinkTypes(value)}
                        />
                    </Col>
                </Row>
            </Row>
        );
    }
}
