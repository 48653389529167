import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import { MerchantSelecterPlus } from './merchant-selecter-plus';

export class MerchantSelectorPlusMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(displayInfo: any): JSX.Element {
        return <MerchantSelecterPlus {...displayInfo} />;
    }
}
