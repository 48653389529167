import React from 'react';
import { Progress } from 'antd';
import { get } from 'lodash';
import classnames from 'classnames';
import './index.less';
const checkPasswordMap = {
    weak: {
        title: '太短',
        description: '请至少输入8个字符，请不要使用容易被猜到的密码。',
        color: '#f5222d',
        percent: 30,
    },
    middle: {
        title: '一般',
        description: '密码强度一般，建议使用含大写字母、小写字母、数字和符号的密码组合',
        color: '#faad14',
        percent: 70,
    },
    strong: {
        title: '安全',
        description: '您的密码强度不错！定期更改密码会更安全哦~',
        color: '#52c41a',
        percent: 100,
    },
};
export const CheckPasswordComponent = (props: any) => {
    const type: 'weak' | 'strong' | 'middle' = props.type;
    const data: any = checkPasswordMap[type];
    return (
        <div className="check-password-component">
            <div className={classnames('status-title', type)}>强度：{get(data, 'title')}</div>
            <div className="status-bar">
                <Progress
                    strokeColor={get(data, 'color')}
                    percent={get(data, 'percent')}
                    showInfo={false}
                />
            </div>
            <div className="status-tips">{get(data, 'description')}</div>
        </div>
    );
};
