import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import isEmpty from 'lodash/isEmpty';
import { forEach } from 'lodash';

const api = services.api;

export const ServiceReservedConsumeLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = '/admin/service_reserved_activity_orders/consume_records';
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        return await api.get(data, config).then((res: any) => {
            const result = res.result;
            forEach(result, (item) => {
                let deposit = '免押金';
                if (item.pointAmount && Number(item.pointAmount) > 0) {
                    deposit = `${item.pointAmount}积分`;
                } else if (item.deposit && Number(item.deposit) > 0) {
                    deposit = `${item.deposit}${services.language.getText('yuan')}`;
                }
                item.deposit = deposit;
            });
            res.result = result;
            return res;
        });
    },
};
