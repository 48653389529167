import React from 'react';
import { connect } from 'react-redux';
import { services } from '@comall-backend-builder/core';
import './index.less';

/**
 * 活动左侧
 * @param props
 */
const mallActivitySettingLeftInfoView = () => {
    return (
        <div className="activity-left-info-preview">
            <img
                className="activity-left-info-img"
                alt={services.language.getText('preview')}
                src="./images/avatar/activity-left-info.png"
            ></img>
        </div>
    );
};

export const MallActivitySettingLeftInfoView = connect()(mallActivitySettingLeftInfoView);
