import { Entity, EntityRequestStatus } from '@comall-backend-builder/core/lib/parser';
import { Button, Checkbox, Col, Divider, Radio, Row, Spin, Table, message } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { ValidationRule } from 'antd/lib/form';
import { ColumnProps } from 'antd/lib/table';
import { cloneDeep, isNil } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import EditableCell from './components/editable-cell';
import { Option } from '@comall-backend-builder/core/lib/type';
import { actions } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

interface Props {
    entity: Entity;
    requestStatus: EntityRequestStatus;
}
enum LevelCalculationMethod {
    MonthlyLevelCalculation = 'MONTHLY_LEVEL_CALCULATION',
    AnnualLevelCalculation = 'ANNUAL_LEVEL_CALCULATION',
    HybridLevelCalculation = 'HYBRID_LEVEL_CALCULATION',
}

interface State {
    data?: ScrmLevelUpgradeDowngradeRuleListVO;
    monthlyExpenditureChecked: boolean;
    yearlyExpenditureChecked: boolean;
}
interface ScrmLevelUpgradeDowngradeRuleListVO {
    scrmLevelUpgradeDowngradeRuleViewVos: ScrmLevelUpgradeDowngradeRuleViewVo[];
    upgradeExpenditureType: 'AMOUNT' | 'POINT';
    levelCalculationMethod: LevelCalculationMethod;
}
export interface ScrmLevelUpgradeDowngradeRuleViewVo {
    scrmLevelUpgradeDowngradeRule?: {
        canDowngrade: boolean;
        id: number;
        maintainAnnualExpenditure: number;
        maintainMonth: number;
        upgradeAnnualExpenditure: number | null;
        upgradeDailyOrMonthlyExpenditure: number | null;
    };
    scrmLevelVo: {
        code: string;
        id: number;
        isDefault: boolean;
        name: string;
        status: 'ENABLE' | 'DISABLE' | 'DELETE';
    };
}

export interface EditableColumnProps
    extends Omit<ColumnProps<ScrmLevelUpgradeDowngradeRuleViewVo>, 'children'> {
    editable?: boolean;
    children?: EditableColumnProps[];
    rules?: ValidationRule[];
    type?: string;
    options?: Option[];
    config?: {
        getDisabled?(record: ScrmLevelUpgradeDowngradeRuleViewVo): boolean;
    };
}

class MemberLevelUpDownRuleTableContainer extends Component<Props, State> {
    state: State = {
        monthlyExpenditureChecked: false,
        yearlyExpenditureChecked: false,
    };

    componentDidMount() {
        this.loadData();
    }

    componentDidUpdate(prevProps: Props) {
        if (prevProps.requestStatus === 'pending' && this.props.requestStatus === 'success') {
            const { entity } = this.props;
            const levelCalculationMethod = (entity.result as ScrmLevelUpgradeDowngradeRuleListVO)
                .levelCalculationMethod;
            this.setState({
                data: entity.result as ScrmLevelUpgradeDowngradeRuleListVO,
                monthlyExpenditureChecked:
                    levelCalculationMethod === LevelCalculationMethod.MonthlyLevelCalculation ||
                    levelCalculationMethod === LevelCalculationMethod.HybridLevelCalculation,
                yearlyExpenditureChecked:
                    levelCalculationMethod === LevelCalculationMethod.AnnualLevelCalculation ||
                    levelCalculationMethod === LevelCalculationMethod.HybridLevelCalculation,
            });
            this.originData = entity.result as ScrmLevelUpgradeDowngradeRuleListVO;
        }
    }
    private originData: ScrmLevelUpgradeDowngradeRuleListVO | undefined;

    private convertColums = (col: EditableColumnProps, index: number): EditableColumnProps => {
        if (!col.editable) {
            return {
                ...col,
                children: col.children?.map(this.convertColums),
            };
        }
        return {
            ...col,
            onCell: (record) => ({
                ...col,
                record,
                onChange: this.onRowChange,
            }),
            children: col.children?.map(this.convertColums),
        };
    };

    private getColumns = () => {
        const { monthlyExpenditureChecked, yearlyExpenditureChecked } = this.state;
        const downgradeRule: EditableColumnProps = {
            title: language.getText('hdtj'),
            children: [],
        };
        if (monthlyExpenditureChecked) {
            downgradeRule.children?.push({
                title: language.getText('yxfe'),
                dataIndex: 'scrmLevelUpgradeDowngradeRule.upgradeDailyOrMonthlyExpenditure',
                editable: true,
                type: 'number.price',
            });
        }
        if (yearlyExpenditureChecked) {
            downgradeRule.children?.push({
                title: language.getText('nxfe'),
                dataIndex: 'scrmLevelUpgradeDowngradeRule.upgradeAnnualExpenditure',
                editable: true,
                type: 'number.price',
            });
        }
        const columns: EditableColumnProps[] = [
            {
                title: language.getText('djxx'),
                children: [
                    {
                        title: language.getText('bm_1'),
                        dataIndex: 'scrmLevelVo.code',
                        render: renderDisabledText,
                    },
                    {
                        title: language.getText('mc'),
                        dataIndex: 'scrmLevelVo.name',
                        render: renderDisabledText,
                    },
                    {
                        title: language.getText('mrdj'),
                        dataIndex: 'scrmLevelVo.isDefault',
                        render: (isDefault: boolean) => renderDisabledText(isDefault ? '是' : '否'),
                    },
                ],
            },
            {
                title: language.getText('bytj'),
                children: [
                    {
                        title: language.getText('jjcl'),
                        dataIndex: 'scrmLevelUpgradeDowngradeRule.canDowngrade',
                        editable: true,
                        type: 'string.options.select',
                        options: [
                            { id: 'false', name: language.getText('bjj') },
                            { id: 'true', name: language.getText('jj_1') },
                        ],
                        config: {
                            getDisabled(record: ScrmLevelUpgradeDowngradeRuleViewVo) {
                                return record.scrmLevelVo.isDefault;
                            },
                        },
                    },
                    {
                        title: language.getText('qjxfe'),
                        dataIndex: 'scrmLevelUpgradeDowngradeRule.maintainAnnualExpenditure',
                        editable: true,
                        type: 'number.integer',
                        config: {
                            getDisabled(record: ScrmLevelUpgradeDowngradeRuleViewVo) {
                                return !record.scrmLevelUpgradeDowngradeRule?.canDowngrade;
                            },
                        },
                    },
                    {
                        title: language.getText('jbyyf'),
                        dataIndex: 'scrmLevelUpgradeDowngradeRule.maintainMonth',
                        editable: true,
                        type: 'number.integer',
                        config: {
                            getDisabled(record: ScrmLevelUpgradeDowngradeRuleViewVo) {
                                return !record.scrmLevelUpgradeDowngradeRule?.canDowngrade;
                            },
                        },
                    },
                ],
            },
        ];
        if (monthlyExpenditureChecked || yearlyExpenditureChecked) {
            columns.splice(1, 0, downgradeRule);
        }
        return columns.map(this.convertColums);
    };

    private loadData() {
        const { entity } = this.props;
        entity.search({});
    }

    private validateMonthlyExpenditure() {
        const { data, monthlyExpenditureChecked } = this.state;
        if (!data) {
            return false;
        }
        if (!monthlyExpenditureChecked) {
            return true;
        }
        let lastVO: ScrmLevelUpgradeDowngradeRuleViewVo | undefined;
        for (const vo of data.scrmLevelUpgradeDowngradeRuleViewVos) {
            const { upgradeDailyOrMonthlyExpenditure } = vo.scrmLevelUpgradeDowngradeRule || {};
            const { name } = vo.scrmLevelVo;
            if (isNil(upgradeDailyOrMonthlyExpenditure)) {
                message.warn(`${name}${language.getText('dyxfebnwk')}`);
                return false;
            }
            if (
                lastVO?.scrmLevelUpgradeDowngradeRule &&
                vo.scrmLevelUpgradeDowngradeRule &&
                lastVO.scrmLevelUpgradeDowngradeRule.upgradeDailyOrMonthlyExpenditure! >=
                    vo.scrmLevelUpgradeDowngradeRule.upgradeDailyOrMonthlyExpenditure!
            ) {
                message.warn(
                    `${vo.scrmLevelVo.name}${language.getText('jdyxfxdy')}${
                        lastVO.scrmLevelVo.name
                    }${language.getText('jdyxfe')}`
                );
                return false;
            }
            lastVO = vo;
        }
        return true;
    }

    private validateAmountExpenditure() {
        const { data, yearlyExpenditureChecked } = this.state;
        if (!data) {
            return false;
        }
        if (!yearlyExpenditureChecked) {
            return true;
        }
        let lastVO: ScrmLevelUpgradeDowngradeRuleViewVo | undefined;
        for (const vo of data.scrmLevelUpgradeDowngradeRuleViewVos) {
            const { upgradeAnnualExpenditure } = vo.scrmLevelUpgradeDowngradeRule || {};
            const { name } = vo.scrmLevelVo;
            if (isNil(upgradeAnnualExpenditure)) {
                message.warn(`${name}${language.getText('dnxfebnwk')}`);
                return false;
            }
            if (
                lastVO?.scrmLevelUpgradeDowngradeRule &&
                vo.scrmLevelUpgradeDowngradeRule &&
                lastVO.scrmLevelUpgradeDowngradeRule.upgradeAnnualExpenditure! >=
                    vo.scrmLevelUpgradeDowngradeRule.upgradeAnnualExpenditure!
            ) {
                message.warn(
                    `${vo.scrmLevelVo.name}${language.getText('jdnxfxdy')}${
                        lastVO.scrmLevelVo.name
                    }${language.getText('dnxfe')}`
                );
                return false;
            }
            lastVO = vo;
        }
        return true;
    }

    private validateKeepGrade() {
        const { data } = this.state;
        if (!data) {
            return false;
        }
        for (const vo of data.scrmLevelUpgradeDowngradeRuleViewVos) {
            const { canDowngrade, maintainAnnualExpenditure, maintainMonth } =
                vo.scrmLevelUpgradeDowngradeRule || {};
            const { name } = vo.scrmLevelVo;
            if (isNil(canDowngrade)) {
                message.warn(`${name}${language.getText('jdjjclbnwk')}`);
                return false;
            } else {
                if (canDowngrade) {
                    if (!maintainAnnualExpenditure) {
                        message.warn(`${name}${language.getText('xszqjxfe')}`);
                        return false;
                    }
                    if (!maintainMonth) {
                        message.warn(`${name}${language.getText('xszbyyf')}`);
                        return false;
                    }
                }
            }
        }
        return true;
    }
    private validateMonthWithYear() {
        const { data } = this.state;
        if (!data) {
            return false;
        }
        for (const vo of data.scrmLevelUpgradeDowngradeRuleViewVos) {
            if (vo.scrmLevelUpgradeDowngradeRule) {
                const {
                    upgradeAnnualExpenditure,
                    upgradeDailyOrMonthlyExpenditure,
                } = vo.scrmLevelUpgradeDowngradeRule;
                const { name } = vo.scrmLevelVo;
                if (
                    upgradeAnnualExpenditure &&
                    upgradeDailyOrMonthlyExpenditure &&
                    upgradeDailyOrMonthlyExpenditure > upgradeAnnualExpenditure
                ) {
                    message.warn(`${name}${language.getText('yxfebndynxfe')}`);
                    return false;
                }
            }
        }
        return true;
    }

    private validate() {
        const { monthlyExpenditureChecked, yearlyExpenditureChecked } = this.state;
        if (!yearlyExpenditureChecked && !monthlyExpenditureChecked) {
            message.warn(language.getText('yxfehnxfezsxytxyl'));
            return false;
        }
        return (
            this.validateMonthlyExpenditure() &&
            this.validateAmountExpenditure() &&
            this.validateMonthWithYear() &&
            this.validateKeepGrade()
        );
    }

    private onReset = () => {
        this.setState({ data: this.originData });
    };

    private onSave = () => {
        const { entity } = this.props;
        const { data, monthlyExpenditureChecked, yearlyExpenditureChecked } = this.state;
        if (data && this.validate()) {
            const { upgradeExpenditureType, scrmLevelUpgradeDowngradeRuleViewVos } = data;
            const levelCalculationMethod =
                monthlyExpenditureChecked && yearlyExpenditureChecked
                    ? LevelCalculationMethod.HybridLevelCalculation
                    : monthlyExpenditureChecked
                    ? LevelCalculationMethod.MonthlyLevelCalculation
                    : LevelCalculationMethod.AnnualLevelCalculation;
            entity.modify(
                {
                    upgradeExpenditureType,
                    levelCalculationMethod,
                    scrmLevelUpgradeDowngradeRuleCommands: scrmLevelUpgradeDowngradeRuleViewVos.map(
                        ({
                            scrmLevelUpgradeDowngradeRule: {
                                id,
                                canDowngrade,
                                maintainAnnualExpenditure,
                                maintainMonth,
                                upgradeAnnualExpenditure,
                                upgradeDailyOrMonthlyExpenditure,
                            } = {},
                            scrmLevelVo: { id: scrmLevelId },
                        }) => {
                            if (
                                levelCalculationMethod ===
                                LevelCalculationMethod.HybridLevelCalculation
                            ) {
                                return {
                                    id,
                                    canDowngrade,
                                    maintainAnnualExpenditure,
                                    maintainMonth,
                                    upgradeAnnualExpenditure,
                                    upgradeDailyOrMonthlyExpenditure,
                                    scrmLevelId,
                                };
                            }
                            if (
                                levelCalculationMethod ===
                                LevelCalculationMethod.MonthlyLevelCalculation
                            ) {
                                return {
                                    id,
                                    canDowngrade,
                                    maintainAnnualExpenditure,
                                    maintainMonth,
                                    upgradeDailyOrMonthlyExpenditure,
                                    scrmLevelId,
                                };
                            }
                            if (
                                levelCalculationMethod ===
                                LevelCalculationMethod.AnnualLevelCalculation
                            ) {
                                return {
                                    id,
                                    canDowngrade,
                                    maintainAnnualExpenditure,
                                    maintainMonth,
                                    upgradeAnnualExpenditure,
                                    scrmLevelId,
                                };
                            }
                            return {};
                        }
                    ),
                },
                {},
                { action: actions.searchAction(entity, {}) }
            );
        }
    };

    private onMonthlyExpenditureCheckedChange = (e: CheckboxChangeEvent) => {
        this.setState({ monthlyExpenditureChecked: e.target.checked });
    };

    private onAmountExpenditureTypeCheckedChange = (e: CheckboxChangeEvent) => {
        this.setState({ yearlyExpenditureChecked: e.target.checked });
    };

    private onRowChange = (data: ScrmLevelUpgradeDowngradeRuleViewVo) => {
        const newData = cloneDeep(this.state.data);
        if (newData?.scrmLevelUpgradeDowngradeRuleViewVos) {
            const index = newData?.scrmLevelUpgradeDowngradeRuleViewVos.findIndex(
                ({ scrmLevelVo }) => scrmLevelVo.id === data.scrmLevelVo.id
            );
            const item = newData.scrmLevelUpgradeDowngradeRuleViewVos[index];
            if (
                data.scrmLevelUpgradeDowngradeRule?.canDowngrade &&
                typeof data.scrmLevelUpgradeDowngradeRule.canDowngrade !== undefined
            ) {
                data.scrmLevelUpgradeDowngradeRule.canDowngrade =
                    data.scrmLevelUpgradeDowngradeRule.canDowngrade.toString() === 'true';
            }
            if (data.scrmLevelUpgradeDowngradeRule?.canDowngrade === false) {
                data.scrmLevelUpgradeDowngradeRule.maintainMonth = 0;
                data.scrmLevelUpgradeDowngradeRule.maintainAnnualExpenditure = 0;
            }
            newData.scrmLevelUpgradeDowngradeRuleViewVos.splice(index, 1, {
                ...item,
                ...data,
            });
            this.setState({ data: newData });
        }
    };

    private get amountExpenditureTypeChecked() {
        const { data } = this.state;
        return data?.upgradeExpenditureType === 'AMOUNT';
    }

    render(): React.ReactNode {
        const { requestStatus } = this.props;
        const { data, monthlyExpenditureChecked, yearlyExpenditureChecked } = this.state;
        return (
            <Spin spinning={requestStatus === 'pending'}>
                <Divider />
                <Row type="flex" gutter={24}>
                    <Col span={4}>{language.getText('sjjpdbz')}</Col>
                    <Col>
                        <Radio checked={this.amountExpenditureTypeChecked}>
                            {language.getText('xfe')}
                        </Radio>
                    </Col>
                </Row>
                <Row type="flex" gutter={24} style={{ marginTop: 8, marginBottom: 24 }}>
                    <Col span={4}>{language.getText('hdtj')}</Col>
                    <Col>
                        <Checkbox
                            onChange={this.onMonthlyExpenditureCheckedChange}
                            checked={monthlyExpenditureChecked}
                        >
                            {language.getText('yxfe')}
                        </Checkbox>
                        <Checkbox
                            onChange={this.onAmountExpenditureTypeCheckedChange}
                            checked={yearlyExpenditureChecked}
                        >
                            {language.getText('nxfe')}
                        </Checkbox>
                    </Col>
                </Row>
                <Table
                    pagination={false}
                    columns={this.getColumns()}
                    dataSource={data?.scrmLevelUpgradeDowngradeRuleViewVos}
                    size="middle"
                    components={{
                        body: {
                            cell: EditableCell,
                        },
                    }}
                />
                <Divider />
                <Row gutter={24} type="flex" justify="center">
                    <Col span={2}>
                        <Button onClick={this.onReset} type="default">
                            {language.getText('common.reset')}
                        </Button>
                    </Col>
                    <Col span={2}>
                        <Button onClick={this.onSave} type="primary">
                            {language.getText('common.save')}
                        </Button>
                    </Col>
                </Row>
            </Spin>
        );
    }
}

function mapStateToProps(_state: unknown, ownProps: Omit<Props, 'requestStatus'>) {
    return {
        requestStatus: ownProps.entity.requestStatus,
    };
}
function renderDisabledText(text: string) {
    return <div style={{ color: '#999' }}>{text}</div>;
}
export const MemberLevelUpDownRuleTable = connect(mapStateToProps)(
    MemberLevelUpDownRuleTableContainer
);
