import assign from 'lodash/assign';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { services } from '@comall-backend-builder/core';
import { getTypeName } from './coupon/coupon-sale-activity-loader';
const { api } = services;

export const GroupBuyingLoader = {
    get: async (params: any, config: ApiRequestConfig) => {
        const { dateRange, subsiteName, ...restParams } = params;

        dateRange && assign(restParams, { startTime: dateRange.start, endTime: dateRange.end });
        subsiteName &&
            assign(restParams, { subsiteName: subsiteName.name ? subsiteName.name : subsiteName });

        config.apiPath = !params.id
            ? '/admin/group_buying_rules'
            : `/admin/group_buying_rules/${params.id}`;
        const res: any = await api.get(restParams, config);

        if (!params.id) {
            res.result.forEach((item: any) => {
                item.canEdit = item.status === 'ENABLE' ? true : false;
                item.groupSubsiteName = item.subsites;
                if (!item.subsites || !item.subsites.length) item.subsites = null;
            });

            return res;
        } else {
            const { groupBuyingProductType } = res;
            let field = groupBuyingProductType === 'COUPON' ? 'goods' : 'products';

            if (res.marketingSchedule) {
                const { startTime, endTime } = res.marketingSchedule || {};
                res.marketingSchedule = {
                    ...res.marketingSchedule,
                    time:
                        startTime && endTime
                            ? `${startTime}-${endTime}`
                            : services.language.getText('longTerm'),
                };
            }

            return {
                id: res.id,
                baseSettings: {
                    name: res.name,
                    dateRange: { start: res.startTime, end: res.endTime },
                    subsites: res.subsites,
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                },
                groupBuyingSettings: {
                    groupBuyingType: res.groupBuyingType,
                    groupBuyingLimit: res.groupBuyingLimit,
                    groupBuyingTime: res.groupBuyingTime,
                    quantity: res.quantity,
                    hasAutoGroupBuyingTime: res.hasAutoGroupBuyingTime ? ['1'] : [],
                    autoGroupBuyingTime: res.autoGroupBuyingTime,
                    hasAutoGroupBuyingQuantity: res.hasAutoGroupBuyingQuantity ? ['1'] : [],
                    autoGroupBuyingQuantity: res.autoGroupBuyingQuantity,
                },
                goodsSettings: {
                    [field]: res.groupBuyingProductList.map((item: any) => {
                        const restFields = { groupBuyingProductType };
                        switch (groupBuyingProductType) {
                            case 'GENERAL_PRODUCT':
                                assign(restFields, {
                                    id: item.productId,
                                    name: item.productName,
                                    goodsStock: item.realStockQuantity,
                                    goodsId: item.goodsId,
                                });
                                break;
                            case 'COUPON':
                                assign(restFields, {
                                    id: item.productId,
                                    name: item.productName,
                                    stock: item.realStockQuantity,
                                    batchNo: item.productCode,
                                    couponType: item.couponType,
                                    startTime: item.effectiveTime,
                                    endTime: item.invalidTime,
                                    saleRuleId: item.saleRuleId,
                                    goodsId: item.goodsId,
                                });
                                break;
                            case 'VIRTUAL_GOODS':
                                const virtualGoods = item.virtualGoodsSaleRuleDefinition;
                                assign(restFields, {
                                    id: item.goodsId,
                                    goodsId: item.goodsId,
                                    productId: item.productId,
                                    name: item.productName,
                                    goodsStock: item.realStockQuantity,
                                    apportionPrices:
                                        virtualGoods &&
                                        virtualGoods.apportionPrices.map((price: any) => {
                                            let couponTypeDesc = getTypeName(price.couponType);
                                            return {
                                                ...price,
                                                couponDefinitionName: price.couponName,
                                                couponTypeDesc,
                                            };
                                        }),
                                    virtualProductCouponDefinitionId:
                                        virtualGoods && virtualGoods.couponDefinitionId,
                                    virtualProductCouponDefinitionType:
                                        virtualGoods &&
                                        virtualGoods.apportionPrices &&
                                        virtualGoods.apportionPrices.length > 0
                                            ? 'COUPON_PACKAGE'
                                            : 'COUPON',
                                });
                                break;
                            default:
                                break;
                        }
                        return {
                            ...item,
                            ...restFields,
                            groupBuyingProductType,
                        };
                    }),
                    saleLimitType:
                        res.rules && res.rules.length > 0 && res.rules[0].ruleCount ? 'YES' : 'NO',
                    saleLimitNum: res.rules && res.rules.length > 0 ? res.rules[0].ruleCount : null,
                },
                groupBuyingAdvancedSettings: {
                    togetherFlag: res.togetherFlag ? ['1'] : [],
                },
            };
        }
    },

    post: async (params: any, config: ApiRequestConfig) => {
        config.apiPath = '/admin/group_buying_rules';
        return await api.post(params, config);
    },

    put: async (params: any, config: ApiRequestConfig) => {
        config.apiPath = `/admin/group_buying_rules/modify_rule/${params.id}`;
        return await api.put(params, config);
    },
};

export const GroupBuyingGoodsSelectorLoader = {
    get: async (params: any, config: ApiRequestConfig) => {
        const { groupBuyingProductType, ...restParams } = params;
        if (groupBuyingProductType === 'COUPON') {
            config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
            config.apiPath = `/admin/coupon_definitions`;
        } else {
            config.apiPath = `/admin/products`;
            if (groupBuyingProductType === 'VIRTUAL_GOODS') {
                restParams.productType = 'VIRTUAL_COUPON';
            }
        }

        const res: any = await api.get(restParams, config);
        res.result &&
            res.result.length &&
            res.result.forEach((item: any) => {
                item.groupBuyingProductType = groupBuyingProductType;
                item.brandName = item.brandVo?.name;
                item.isProduct = true;
            });

        return res;
    },
};

export const GroupBuyingActivityDataLoader = {
    get: async (params: { id: string }): Promise<any> => {
        const { id } = params;
        const apiRoot = `${ENV.AUTH_API_ROOT}/GROUP-BUYING`;
        const apiPath = `/admin/group_buying_rules/statistics/${id}`;
        return await api.get({}, { apiPath, apiRoot });
    },
};
