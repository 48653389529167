import * as React from 'react';
import { StringMode } from '@comall-backend-builder/types';
import { StringCascader } from './cascader';

export class StringCascaderMode extends StringMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <StringCascader {...controlInfo} />;
    }
}
