import { CouponStatus } from './couponType';
import { find } from 'lodash';

const EXCHANGE_STATUS_OPTIONS = [
    {
        id: CouponStatus.UNEXCHANGED,
        name: '待兑换',
    },
    {
        id: CouponStatus.EXCHANGED,
        name: '已兑换',
    },
    {
        id: CouponStatus.UNBOUND,
        name: '未绑定',
    },
    {
        id: CouponStatus.BOUND,
        name: '已绑定',
    },
    {
        id: CouponStatus.USED,
        name: '已使用',
    },
    {
        id: CouponStatus.CANCELED,
        name: '已作废',
    },
    {
        id: CouponStatus.FREEZE,
        name: '冻结',
    },
    {
        id: CouponStatus.PREBINDING,
        name: '预绑定',
    },
    {
        id: CouponStatus.INVALID,
        name: '无效',
    },
    {
        id: CouponStatus.TRANSFERRING,
        name: '赠送中',
    },
];

export function getCouponStatus(status: string) {
    const row: any = find(EXCHANGE_STATUS_OPTIONS, {
        id: status,
    });
    if (row) {
        return row.name;
    }
    return '';
}

export function formatPointAmount(pointAmount: number | string) {
    return parseFloat(Number(pointAmount).toFixed(2));
}
