import React, { PureComponent } from 'react';
import { EntitiesManager, services } from '@comall-backend-builder/core';
import { Menu, Dropdown, Button } from 'antd';
import { NewImportTable } from '../../components';

export class PromotionCouponManageImport extends PureComponent {
    render() {
        const Importprops: any = {
            stop: {
                triggerType: 'link',
                triggerText: services.language.getText('plty'),
                triggerStyle: { textAlign: 'center', color: 'rgb(0 0 0 / 65%)' },
                controlDownloadTemplate: {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                    apiPath: `/admin/coupons/canceled_by_excel_template.xlsx`,
                },
                specialTips: [],
                controlHandles: [
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
                        apiPath: '/admin/files/locations/94/upload',
                        type: 'primary',
                        text: services.language.getText('plty'),
                    },
                ],
            },
            cancel: {
                triggerType: 'link',
                controlTitle: services.language.getText('plhx'),
                triggerText: services.language.getText('plhx'),
                triggerStyle: { textAlign: 'center', color: 'rgb(0 0 0 / 65%)' },
                controlDownloadTemplate: {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                    apiPath: `/admin/coupons/consume_by_excel_template.xlsx`,
                },
                specialTips: [],
                controlHandles: [
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
                        apiPath: '/admin/files/locations/92/upload',
                        type: 'primary',
                        text: services.language.getText('plhx'),
                    },
                ],
            },
        };
        const entity: any = EntitiesManager.get('WhitelistManagerListEntity');
        const menu = (
            <Menu>
                <Menu.Item>
                    <NewImportTable
                        row={null}
                        entity={entity}
                        props={Importprops.stop}
                    ></NewImportTable>
                </Menu.Item>
                <Menu.Item>
                    <NewImportTable
                        row={null}
                        entity={entity}
                        props={Importprops.cancel}
                    ></NewImportTable>
                </Menu.Item>
            </Menu>
        );
        return (
            <>
                <Dropdown overlay={menu} placement="bottomCenter">
                    <Button>{services.language.getText('pldrsz')}</Button>
                </Dropdown>
            </>
        );
    }
}
