import React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { MaotaiPresalePurchaseMode } from '../../../containers';

export class MaotaiPresalePurchaseModeMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <MaotaiPresalePurchaseMode {...controlInfo} />;
    }
}
