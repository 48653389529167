import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { get } from 'lodash';

const api = services.api;
export const ContentNotesTopPicLoader: Loader = {
    get: async function(params: any, config: ApiRequestConfig) {
        config.apiPath = `/admin/notes/top_picture`;
        const data: any = await api.get(params, config);
        if (data && data.length) {
            const upload1 = data.find((i: any) => i.sequence === 1);
            const upload2 = data.find((i: any) => i.sequence === 2);
            const newData: any = {};
            if (upload1 && upload1.id && upload1.url) {
                newData.upload1 = [{ path: upload1.url, id: upload1.id }];
            }
            if (upload2 && upload2.id && upload2.url) {
                newData.upload2 = [{ path: upload2.url, id: upload2.id }];
            }
            return newData;
        }
        return { upload1: '', upload2: '' };
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiPath = `/admin/notes/top_picture`;
        const pictureList: any = [];
        const upload1 = get(params, 'upload1[0].path');
        const upload2 = get(params, 'upload2[0].path');

        if (upload1) {
            pictureList.push({
                id: params.upload1[0].id,
                url: params.upload1[0].path,
                sequence: 1,
            });
        }
        if (upload2) {
            pictureList.push({
                id: params.upload2[0].id,
                url: params.upload2[0].path,
                sequence: 2,
            });
        }

        return await api.post({ picture_list: pictureList }, config);
    },
};
