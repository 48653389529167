import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { CGBSelectProduct } from '../../../containers';
import { ProductSelectedDisplay } from '../../../components';

export class CGBProductSelectorMode extends modes.ArrayMode {
    getDisplayComponent(value: any) {
        return (
            <ProductSelectedDisplay
                data={value}
                modifiable={false}
                onChange={() => {}}
            ></ProductSelectedDisplay>
        );
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <CGBSelectProduct {...displayInfo} />;
    }
}
