import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
// import { map } from 'lodash';

const api = services.api;

export const StoredValueCardConfigLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-ASSETS`;
        config.apiPath = `/admin/stored_value_cards`;
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/stored_value_cards/${id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                const layout = JSON.parse(res.layout);
                res.baseConfig = {
                    // subsiteIds: res.subsites,
                    pageTitle: res.pageTitle,
                    showSubsiteWidget: res.showSubsiteWidget + '',
                    showMode: layout && layout.showMode ? layout.showMode : '1',
                };
                res.assetConfig = {
                    name: res.name,
                    balanceName: res.balanceName,
                    balanceDescription: res.balanceDescription,
                    backgroundPicture:
                        res.backgroundPicture && res.backgroundPicture.id
                            ? [
                                  {
                                      id: res.backgroundPicture.id,
                                      path: res.backgroundPicture.url,
                                  },
                              ]
                            : [
                                  {
                                      id: '0',
                                      path:
                                          'https://b-puzhehei-cdn.co-mall.net/global/stored-value-card/icon-card-background-new.png',
                                  },
                              ],
                    showPayCode: res.showPayCode + '',
                    showCard: res.showCard + '',
                    showCardName: res.showCardName,
                    showStyle: res.showStyle + '',
                };
                res.menusConfig = {
                    service: JSON.parse(res.service),
                };
                res.otherConfig = {
                    showSubsiteWhenPurchaseCard: res.showSubsiteWhenPurchaseCard + '',
                };
                // res.pageConfig = {
                //     advertisingSpace: res.advertisingSpace?.map((c: any) => {
                //         return {
                //             picture: [
                //                 {
                //                     id: c.picture.id,
                //                     path: c.picture.url,
                //                 },
                //             ],
                //             config: JSON.parse(c.config),
                //         };
                //     }),
                // };
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-ASSETS`;
        config.apiPath = '/admin/stored_value_cards';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-ASSETS`;
        config.apiPath = `/admin/stored_value_cards/${params.id}`;
        const newParams: any = dataParser(params);
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    // newParams.subsiteIds = map(params.baseConfig.subsiteIds, 'id');
    newParams.pageTitle = params.baseConfig.pageTitle;
    newParams.showSubsiteWidget = params.baseConfig.showSubsiteWidget;
    const layoutObject = {
        showMode: params.baseConfig.showMode,
    };
    newParams.layout = JSON.stringify(layoutObject);

    newParams.name = params.assetConfig.name;
    newParams.balanceName = params.assetConfig.balanceName;
    newParams.balanceDescription = params.assetConfig.balanceDescription;
    const pictureId = params.assetConfig.backgroundPicture[0].id;
    newParams.backgroundPicture =
        pictureId && pictureId !== '0'
            ? {
                  id: pictureId,
                  url: params.assetConfig.backgroundPicture[0].path,
              }
            : undefined;
    newParams.showPayCode = params.assetConfig.showPayCode;
    newParams.showCard = params.assetConfig.showCard;
    newParams.showCardName = params.assetConfig.showCardName;
    newParams.showStyle = params.assetConfig.showStyle;
    newParams.service = JSON.stringify(params.menusConfig.service);

    newParams.showSubsiteWhenPurchaseCard = params.otherConfig.showSubsiteWhenPurchaseCard;

    // newParams.advertisingSpace = params.pageConfig.advertisingSpace?.map((c: any) => {
    //     return {
    //         picture: {
    //             id: c.picture[0].id,
    //             url: c.picture[0].path,
    //         },
    //         config: JSON.stringify(c.config),
    //     };
    // });
    return newParams;
}
