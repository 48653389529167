export enum ModuleType {
    NavigationButtonGoldExchangePoint = 'NAVIGATION_BUTTON_GOLD_EXCHANGE_POINT',
}

export interface Menu<T> {
    moduleType: ModuleType;
    picture?: string;
    visible: boolean;
    config: T;
}

export const goldExchangePoint: Menu<null> = {
    moduleType: ModuleType.NavigationButtonGoldExchangePoint,
    visible: false,
    config: null,
};

export const defaultValue = [goldExchangePoint];
export type Value = typeof defaultValue;
