import React, { PureComponent } from 'react';
import { Button, Modal, Form, Input, Radio, InputNumber, message } from 'antd';
import PropTypes from 'prop-types';
import DragTable from '../drag-table';
import { cloneDeep } from 'lodash';
import { services } from '@comall-backend-builder/core';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

interface CustomFormItem {
    type: FormType;
    title: string;
    sequence: number;
    list?: string[];
    typeName?: string;
}
interface CustomFormProps {
    value: CustomFormItem[];
    onChange: (data: any) => void;
    disabled?: boolean;
}

const formItemLayout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};
const FormItem = Form.Item;
enum FormType {
    INPUT = 'INPUT',
    TEXTAREA = 'TEXTAREA',
    RADIO = 'RADIO',
    CHECKBOX = 'CHECKBOX',
}
enum FormTextType {
    INPUT = 'dxsrk',
    TEXTAREA = 'dxsrk_1',
    RADIO = 'dxk_1',
    CHECKBOX = 'dxk',
}

class OptionItemConfig extends React.Component {
    nameChange = (e: any, index: number) => {
        const { onChange, value } = this.props as any;
        const list = cloneDeep(value);
        list[index] = e.target.value;
        onChange(list);
    };
    remove = (index: number) => {
        const { onChange, value } = this.props as any;
        const list = value.filter((_: string, i: number) => i !== index);
        onChange(list);
    };
    add = () => {
        const { onChange, value } = this.props as any;
        const list = cloneDeep(value);
        onChange([...list, '']);
    };
    render() {
        const { value } = this.props as any;
        return (
            <div className="custom-edit-content">
                <div className="row">
                    <Button type="primary" disabled={value.length >= 10} onClick={this.add}>
                        {language.getText('tjxx')}
                    </Button>
                    {value.length >= 10 ? (
                        <span className="tip red">{language.getText('ydtjsx')}</span>
                    ) : (
                        <span className="tip">{services.language.getText('canTenItem')}</span>
                    )}
                </div>
                {value.map((i: string, index: number) => (
                    <div className="row">
                        <Input
                            key={index}
                            placeholder={language.getText('qsrxxbcg50gz')}
                            style={{ marginRight: 5 }}
                            maxLength={50}
                            value={i}
                            onChange={(e) => this.nameChange(e, index)}
                        />
                        <Button disabled={value.length <= 1} onClick={() => this.remove(index)}>
                            {services.language.getText('common.delete')}
                        </Button>
                    </div>
                ))}
            </div>
        );
    }
}

const CustomAddConfigForm = Form.create({ name: 'addCustomForm' })(
    class extends React.Component {
        static propTypes: {
            onSubmit: PropTypes.Requireable<(...args: any[]) => any>;
            value: any;
        };
        handleSubmit = (e: any) => {
            const { form, onSubmit } = this.props as any;
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSubmit(values);
                }
            });
        };
        handleReset = () => {
            const { form } = this.props as any;
            form.resetFields();
        };
        render() {
            const { form } = this.props as any;
            const { getFieldDecorator } = form;

            return (
                <Form ref="addFrom" {...formItemLayout} onSubmit={this.handleSubmit}>
                    <FormItem label={language.getText('mc')}>
                        {getFieldDecorator('title', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: language.getText('bmzdyxmcwbtx'),
                                },
                                {
                                    message: language.getText('mcbnwk'),
                                    validator: (rule: any, value: any) => {
                                        if (value && !value.trim()) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                        })(
                            <Input
                                placeholder={language.getText('qsrzdyxmc')}
                                maxLength={20}
                                size="small"
                            />
                        )}
                    </FormItem>
                    <FormItem label={language.getText('lx_1')}>
                        {getFieldDecorator('type', {
                            initialValue: FormType.INPUT,
                            rules: [
                                {
                                    required: true,
                                    message: services.language.getText('selectType'),
                                },
                            ],
                        })(
                            <Radio.Group>
                                <Radio value={FormType.INPUT}>{language.getText('dxsrk')}</Radio>
                                <Radio value={FormType.TEXTAREA}>
                                    {language.getText('dxsrk_1')}
                                </Radio>
                                <Radio value={FormType.RADIO}>{language.getText('dxk_1')}</Radio>
                                <Radio value={FormType.CHECKBOX}>{language.getText('dxk')}</Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                </Form>
            );
        }
    }
);

const CustomEditConfigForm = Form.create({
    name: 'editCustomForm',
    mapPropsToFields(props: any) {
        return !props.value
            ? {}
            : Object.entries(props.value).reduce((v0: any, [k, v]) => {
                  v0[k] = Form.createFormField({
                      value: v,
                  });
                  return v0;
              }, {});
    },
})(
    class extends React.Component {
        static propTypes: {
            onSubmit: PropTypes.Requireable<(...args: any[]) => any>;
            value: any;
        };
        handleSubmit = (e: any) => {
            const { form, onSubmit } = this.props as any;
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSubmit(values);
                }
            });
        };
        handleReset = () => {
            const { form } = this.props as any;
            form.resetFields();
        };

        render() {
            const { form } = this.props as any;
            const { getFieldDecorator, getFieldValue } = form;
            const type = getFieldValue('type');
            return (
                <Form ref="editFrom" {...formItemLayout} onSubmit={this.handleSubmit}>
                    <FormItem label={language.getText('lx_1')}>
                        {getFieldDecorator('typeName', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        })(<Input disabled />)}
                    </FormItem>
                    <FormItem label={language.getText('mc')}>
                        {getFieldDecorator('title', {
                            initialValue: '',
                            rules: [
                                {
                                    required: true,
                                    message: language.getText('bmzdyxmcwbtx'),
                                },
                                {
                                    message: language.getText('mcbnwk'),
                                    validator: (rule: any, value: any) => {
                                        if (value && !value.trim()) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                        })(<Input maxLength={20} placeholder={language.getText('qsrzdyxmc')} />)}
                    </FormItem>
                    <FormItem style={{ display: 'none' }} label={language.getText('lx_1')}>
                        {getFieldDecorator('type', {
                            initialValue: FormType.INPUT,
                            rules: [
                                {
                                    required: true,
                                    message: services.language.getText('selectType'),
                                },
                            ],
                        })(
                            <Radio.Group>
                                <Radio value={FormType.INPUT}>{language.getText('dxsrk')}</Radio>
                                <Radio value={FormType.TEXTAREA}>
                                    {language.getText('dxsrk_1')}
                                </Radio>
                                <Radio value={FormType.RADIO}>{language.getText('dxk_1')}</Radio>
                                <Radio value={FormType.CHECKBOX}>{language.getText('dxk')}</Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                    <FormItem style={{ display: 'none' }} label={language.getText('sequence')}>
                        {getFieldDecorator('sequence', {
                            initialValue: 1,
                            rules: [
                                {
                                    required: true,
                                    message: services.language.getText('selectType'),
                                },
                            ],
                        })(<InputNumber></InputNumber>)}
                    </FormItem>
                    {type !== FormType.INPUT &&
                        type !== FormType.TEXTAREA &&
                        getFieldValue('list') && (
                            <FormItem label={language.getText('xx')}>
                                {getFieldDecorator('list', {
                                    initialValue: [],
                                    rules: [
                                        {
                                            required: true,
                                            message: language.getText('xxwbtx'),
                                        },
                                        {
                                            message: language.getText('xxbnwk'),
                                            validator: (rule: any, value: any) => {
                                                let result = true;
                                                for (let index = 0; index < value.length; index++) {
                                                    const str = value[index];
                                                    if (!str || !str.trim()) {
                                                        result = false;
                                                        break;
                                                    }
                                                }
                                                return result;
                                            },
                                        },
                                        {
                                            message: services.language.getText('xxbnzf'),
                                            validator: (rule: any, value: any) => {
                                                let result = true;
                                                for (let index = 0; index < value.length; index++) {
                                                    const str = value[index];
                                                    if (
                                                        getFieldValue('list').filter(
                                                            (i: string) => i === str
                                                        ).length >= 2
                                                    ) {
                                                        result = false;
                                                        break;
                                                    }
                                                }
                                                return result;
                                            },
                                        },
                                    ],
                                })(<OptionItemConfig />)}
                            </FormItem>
                        )}
                </Form>
            );
        }
    }
);

export class CustomForm extends PureComponent<CustomFormProps> {
    dispatch: any = {};
    state = {
        addVisible: false,
        editVisible: false,
        editRow: null,
    };

    listenAddRef!: {
        handleSubmit: () => Promise<any>;
        handleReset: () => Promise<any>;
    };
    listenEditRef!: {
        handleSubmit: () => Promise<any>;
        handleReset: () => Promise<any>;
    };
    onAdd = (item: CustomFormItem) => {
        const { onChange, value } = this.props;
        if (item.type === FormType.RADIO || item.type === FormType.CHECKBOX) {
            item.list = [language.getText('xxy'), language.getText('xxe')];
        }
        item.typeName = language.getText(FormTextType[item.type]);
        if (value && value.length) {
            item.sequence = value.length + 1;
            onChange([...value, item]);
        } else {
            item.sequence = 1;
            onChange([item]);
        }
        this.toggleAddModal();
    };

    toggleAddModal = () => {
        const { value } = this.props;
        const { addVisible } = this.state;
        if (value && value.length >= 6) return message.warning(language.getText('ydtjsx'));
        this.setState({ addVisible: !addVisible });
        if (addVisible) {
            this.listenAddRef.handleReset();
        }
    };
    toggleEditModal = () => {
        const { editVisible } = this.state;
        this.setState({ editVisible: !editVisible });
        if (editVisible) {
            this.listenEditRef.handleReset();
        }
    };

    handleEdit = (row: CustomFormItem) => {
        const editRow = cloneDeep(row);
        editRow.typeName = language.getText(FormTextType[editRow.type]);
        this.setState({
            editRow,
            editVisible: true,
        });
    };

    onEditSubmit = (val: CustomFormItem) => {
        const { value, onChange } = this.props;
        let list = cloneDeep(value);
        const index = value.findIndex((i) => i.sequence === val.sequence);
        list[index] = { ...val };
        onChange(list);
        this.toggleEditModal();
    };

    onRemove = (sequence: number) => {
        const { value, onChange } = this.props;
        const result = value
            .filter((i) => sequence !== i.sequence)
            .map((item, index) => ({ ...item, sequence: index + 1 }));
        onChange && onChange(result);
    };
    onIndexChange = (data: any) => {
        const { onChange, disabled } = this.props;
        if (disabled) return;
        data = data.map((item: any, idx: number) => ({ ...item, sequence: idx + 1 }));
        this.setState({ value: data });
        onChange && onChange(data);
    };
    getTableConfig = () => {
        const { disabled } = this.props;

        let columns = [
            {
                title: services.language.getText('sequence'),
                dataIndex: 'sequence',
                key: 'sequence',
                width: 80,
            },
            {
                title: language.getText('zdyx'),
                dataIndex: 'title',
                key: 'title',
                width: 150,
            },

            {
                title: language.getText('lx_1'),
                dataIndex: 'type',
                key: 'type',
                width: 100,
                render: (type: FormType) => {
                    return <span>{language.getText(FormTextType[type])}</span>;
                },
            },
            {
                title: language.getText('content'),
                dataIndex: 'list',
                key: 'list',
                width: 150,
                render: (list: any[]) => {
                    return <>{list && list.length ? list.map((i) => <div>{i}</div>) : null}</>;
                },
            },
            {
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                key: 'operation',
                width: 180,
                render: (i: any, row: any) => {
                    return (
                        <>
                            <Button
                                type="link"
                                style={{ padding: 0 }}
                                onClick={() => this.handleEdit(row)}
                                disabled={disabled}
                            >
                                {language.getText('common.edit')}
                            </Button>

                            <Button
                                type="link"
                                onClick={() => this.onRemove(row.sequence)}
                                disabled={disabled}
                            >
                                {services.language.getText('common.delete')}
                            </Button>
                        </>
                    );
                },
            },
        ];

        let config: any = {
            rowKey: 'sequence',
            columns: columns,
        };

        return config;
    };
    render() {
        const { addVisible, editVisible, editRow } = this.state;
        const { value, disabled } = this.props;
        let tableConfig = this.getTableConfig();
        const tableProps = { ...tableConfig, dataSource: value };
        return (
            <>
                {!disabled && (
                    <div>
                        <div className="ant-form-extra">{language.getText('xtmrbmxxwxm')}</div>
                        <div className="ant-form-extra">
                            {language.getText('hxyqxx')}
                            <Button type="link" onClick={this.toggleAddModal}>
                                {language.getText('xzzdyx')}
                            </Button>
                        </div>
                    </div>
                )}

                {value && value.length ? (
                    <>
                        <DragTable
                            {...tableProps}
                            onUpdateData={this.onIndexChange}
                            pagination={false}
                        />
                        <span className="tip">{language.getText('tjdzdyx')}</span>
                    </>
                ) : null}

                <Modal
                    visible={addVisible}
                    title={language.getText('common.add')}
                    mask={false}
                    maskClosable
                    okText={services.language.getText('common.ok')}
                    cancelText={services.language.getText('common.cancel')}
                    width="630px"
                    onOk={() => {
                        this.listenAddRef.handleSubmit();
                    }}
                    onCancel={this.toggleAddModal}
                >
                    <CustomAddConfigForm
                        wrappedComponentRef={(ref: any) => (this.listenAddRef = ref)}
                        onSubmit={this.onAdd}
                    />
                </Modal>
                <Modal
                    visible={editVisible}
                    title={language.getText('common.edit')}
                    mask={false}
                    maskClosable
                    okText={services.language.getText('common.ok')}
                    cancelText={services.language.getText('common.cancel')}
                    width="630px"
                    onOk={() => {
                        this.listenEditRef.handleSubmit();
                    }}
                    onCancel={this.toggleEditModal}
                >
                    <CustomEditConfigForm
                        value={editRow}
                        wrappedComponentRef={(ref: any) => (this.listenEditRef = ref)}
                        onSubmit={this.onEditSubmit}
                    />
                </Modal>
            </>
        );
    }
}
