import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Tabs, Card, Row, Col } from 'antd';
import { services } from '@comall-backend-builder/core';

import './index.less';

const api = services.api;
const { TabPane } = Tabs;

interface CheckinDataReportState {
    operationalData: OperationalData;
    rewardData: RewardData;
}
interface OperationalData {
    activityId: string;
    activityName: string;
    checkinTotalMemberCount: string;
    newCheckinMemberCount: string;
    newCheckinMemberProportion: string;
    totalCheckinCount: string;
}
interface RewardData {
    activityId: string;
    activityName: string;
    couponTotalQuantity: string;
    totalGrowthValue: string;
    totalPoint: string;
    totalGoldValue: string;
}

class CheckinDataReport extends PureComponent<any, CheckinDataReportState> {
    state = {
        operationalData: {
            activityId: '',
            activityName: '',
            checkinTotalMemberCount: '',
            newCheckinMemberCount: '',
            newCheckinMemberProportion: '',
            totalCheckinCount: '',
        },
        rewardData: {
            activityId: '',
            activityName: '',
            couponTotalQuantity: '',
            totalGrowthValue: '',
            totalPoint: '',
            totalGoldValue: '',
        },
    };
    componentDidMount() {
        const { id } = this.props.params;
        const apiPath = '/admin/checkin/activities/:id/activity_data';
        const apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        api.get({ id }, { apiRoot: apiRoot, apiPath: apiPath }).then((res: any) => {
            const { operationalData, rewardData } = res;
            this.setState({
                operationalData,
                rewardData,
            });
        });
    }

    render() {
        const { operationalData, rewardData } = this.state;
        return (
            <Fragment>
                <Tabs type="card" className="checkin-report">
                    {!!operationalData && !!rewardData && (
                        <TabPane tab={services.language.getText('hdsj')}>
                            <div className="checkin-report-header">
                                <span className="checkin-report-activity-name">
                                    {operationalData.activityName}
                                </span>
                                <span className="checkin-report-activity-tips">
                                    {services.language.getText('sjmgzdgx')}
                                </span>
                            </div>
                            <Card
                                title={services.language.getText('yysj_1')}
                                type="inner"
                                style={{ marginBottom: 32 }}
                            >
                                <Row gutter={[16, 12]}>
                                    <Col span={6}>
                                        <div className="checkin-report-item">
                                            <div className="checkin-report-item-title">
                                                {services.language.getText('qdzyhs')}
                                            </div>
                                            <div className="checkin-report-item-content">
                                                {operationalData.checkinTotalMemberCount}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={6}>
                                        <div className="checkin-report-item">
                                            <div className="checkin-report-item-title">
                                                {services.language.getText('zljqdcs')}
                                            </div>
                                            <div className="checkin-report-item-content">
                                                {operationalData.totalCheckinCount}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={6}>
                                        <div className="checkin-report-item">
                                            <div className="checkin-report-item-title">
                                                {services.language.getText('xqdyhs')}
                                            </div>
                                            <div className="checkin-report-item-content">
                                                {operationalData.newCheckinMemberCount}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={6}>
                                        <div className="checkin-report-item">
                                            <div className="checkin-report-item-title">
                                                {services.language.getText('xqdyhzb')}
                                            </div>
                                            <div className="checkin-report-item-content">
                                                {operationalData.newCheckinMemberProportion}%
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                            <Card
                                title={services.language.getText('jlsj')}
                                type="inner"
                                style={{ marginBottom: 32 }}
                            >
                                <Row gutter={[16, 12]}>
                                    <Col span={6}>
                                        <div className="checkin-report-item">
                                            <div className="checkin-report-item-title">
                                                {services.language.getText('zjf')}
                                            </div>
                                            <div className="checkin-report-item-content">
                                                {rewardData.totalPoint}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={6}>
                                        <div className="checkin-report-item">
                                            <div className="checkin-report-item-title">
                                                {services.language.getText('zjyz')}
                                            </div>
                                            <div className="checkin-report-item-content">
                                                {rewardData.totalGrowthValue}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={6}>
                                        <div className="checkin-report-item">
                                            <div className="checkin-report-item-title">
                                                {services.language.getText('yhqzzs')}
                                            </div>
                                            <div className="checkin-report-item-content">
                                                {rewardData.couponTotalQuantity}
                                            </div>
                                        </div>
                                    </Col>
                                    <Col span={6}>
                                        <div className="checkin-report-item">
                                            <div className="checkin-report-item-title">
                                                {services.language.getText('zjbs')}
                                            </div>
                                            <div className="checkin-report-item-content">
                                                {rewardData.totalGoldValue}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </TabPane>
                    )}
                </Tabs>
            </Fragment>
        );
    }
}

function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
    };
}

export const CheckinDataReportView = connect(mapStateToProps)(CheckinDataReport);
