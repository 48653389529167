import React, { PureComponent } from 'react';
import { Form, Radio, Slider } from 'antd';
import { tools } from '@comall-backend-builder/design-components-basis';
import { ImageAdItem } from './image-ad-item';
import { ImageAdTips } from './image-ad-tips';
const EntryList = tools.EntryList;

const FormItem = Form.Item;
const RadioGroup = Radio.Group;

const MAX_ENTRY_COUNT = 10;

const FormItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};

export class CarouselView extends PureComponent<any> {
    WrapImageAdItem = (props: any) => {
        const { uploadAction, selector, sourceRoot } = this.props;
        return (
            <ImageAdItem
                {...props}
                sourceRoot={sourceRoot}
                selector={selector}
                uploadAction={uploadAction}
            />
        );
    };

    render() {
        const {
            form: { getFieldDecorator },
            value,
            linkTypes,
            linkTypeMode,
        } = this.props;
        const formItemLayout = FormItemLayout;
        //轮播图才展示填充方式
        const isCarousel = value.showStyle === '1';
        const isFill = isCarousel && value.fillStyle === '2';
        const isType1 = value.type === '1';
        const isType2 = value.type === '2';
        return (
            <Form className="editor-image-ad">
                <FormItem {...formItemLayout} label="选择模板">
                    {getFieldDecorator(
                        'showStyle',
                        {}
                    )(
                        <RadioGroup className="show-style">
                            <Radio value={'1'}>
                                <img
                                    className="select-img"
                                    src="./images/editor/image-ad-carousel.png"
                                    alt=""
                                />
                                <span className="select-title">轮播图</span>
                            </Radio>
                            <Radio value={'2'}>
                                <img
                                    className="select-img"
                                    src="./images/editor/image-ad-one-line-1.png"
                                    alt=""
                                />
                                <span className="select-title">一行一个</span>
                            </Radio>
                            <Radio value={'3'}>
                                <img
                                    className="select-img"
                                    src="./images/editor/image-ad-one-line-2.png"
                                    alt=""
                                />
                                <span className="select-title">一行两个</span>
                            </Radio>
                        </RadioGroup>
                    )}
                </FormItem>
                {isCarousel && (
                    <FormItem {...formItemLayout} label="展示样式">
                        {getFieldDecorator(
                            'type',
                            {}
                        )(
                            <RadioGroup className="fill-style">
                                <Radio value={'1'}>样式一</Radio>
                                <Radio value={'2'}>样式二</Radio>
                            </RadioGroup>
                        )}
                    </FormItem>
                )}
                {isCarousel && isType1 && (
                    <FormItem {...formItemLayout} label="填充方式">
                        {getFieldDecorator(
                            'fillStyle',
                            {}
                        )(
                            <RadioGroup className="fill-style">
                                <Radio value={'1'}>留白</Radio>
                                <Radio value={'2'}>填充</Radio>
                            </RadioGroup>
                        )}
                    </FormItem>
                )}
                {isFill && isType1 && (
                    <FormItem {...formItemLayout} label={'图片高度'}>
                        {getFieldDecorator('imageHeight')(<Slider max={225} min={100} />)}
                    </FormItem>
                )}
                {!isType2 && (
                    <FormItem {...formItemLayout} label="页面间距">
                        {getFieldDecorator('margin')(<Slider max={24} min={0} />)}
                    </FormItem>
                )}
                {!isCarousel && (
                    <FormItem {...formItemLayout} label="图片间距">
                        {getFieldDecorator('marginImage')(<Slider max={24} min={0} />)}
                    </FormItem>
                )}
                {!isCarousel && (
                    <FormItem {...formItemLayout} label="图片圆角">
                        {getFieldDecorator('borderRadius')(<Slider max={24} min={0} />)}
                    </FormItem>
                )}

                <FormItem>
                    {getFieldDecorator('entry', {
                        initialValue: [],
                    })(
                        <EntryList
                            linkTypes={linkTypes}
                            linkTypeMode={linkTypeMode}
                            maxCount={MAX_ENTRY_COUNT}
                            renderItem={this.WrapImageAdItem}
                            renderTips={ImageAdTips}
                        />
                    )}
                </FormItem>
                <p className="entry-tip">
                    {`每行最多添加 ${MAX_ENTRY_COUNT} 个商品广告位，可拖拽调整顺序。`}
                </p>
            </Form>
        );
    }
}

export const Carousel = tools.ConfigFormDecorator(CarouselView);
