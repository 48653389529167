import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { tools } from '@comall-backend-builder/design-components-basis';
import { customSelectorConfig, contentPopUpConfig } from '../../../applications/design/design';
import { POP_UP_CONTENT_PAGE_LINK_TYPES_LIST } from '../../../applications/design/constants/page-design';

const { LinkType } = tools;
const SELECT_CONFIG = { ...customSelectorConfig, ...contentPopUpConfig };
/**
 * 为了解决ObjectMode 给实体属性设置defaultValue无效
 * @param props
 */
const HocLinkType = (props: any) => {
    const newProps = { ...props };
    newProps.value = props.value || { linkType: 'none' };
    return (
        <LinkType
            selector={SELECT_CONFIG}
            linkTypeMode={'cascader'}
            linkTypes={POP_UP_CONTENT_PAGE_LINK_TYPES_LIST}
            {...newProps}
        />
    );
};

/**
 * 可视化广告位链接选择器
 */
export class LinkTypePopUpContent extends ObjectMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(object: any, displayInfo: any) {
        const { className } = displayInfo;
        if (!object) return <div></div>;
        const { linkParams, linkType } = object;
        let linkTypeText = '';
        let Detail = '';
        const tagetItem = POP_UP_CONTENT_PAGE_LINK_TYPES_LIST.find((i) => i.id === linkType);
        if (tagetItem) linkTypeText = tagetItem.name;
        if (linkParams) {
            Detail = linkParams.name;
        }
        return (
            <div className={className}>
                {linkTypeText || ''} {'  '}
                {Detail}
            </div>
        );
    }
    /**
     * 获取输入组件
     */

    public getControlComponent(controlInfo: any) {
        return <HocLinkType {...controlInfo}></HocLinkType>;
    }
}
