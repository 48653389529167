import React, { PureComponent } from 'react';
import { InputNumber, DatePicker, Select, message } from 'antd';
import moment from 'moment';
import {
    NewMallActivityTicketType,
    NewMallActivityRow,
    NewMallActivityRegistrationTimeType,
    NewMallActivityRegistrationTimeValue,
    NewMallActivityRegistrationTimeUnit,
    NewMallActivityType,
} from '../new-mall-activity/types';
import { getRowDateTime, isAfter } from '../new-mall-activity/common';

import './index.less';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';
import { filter, get } from 'lodash';
import {
    checkBusinessNotifications,
    checkNotificationLink,
} from '../../config/member/caring/birthday-caring';

const { Option } = Select;

interface NewMallActivityRegistrationTimeProps {
    value?: NewMallActivityRegistrationTimeValue;
    onChange?: (value?: NewMallActivityRegistrationTimeValue) => void;
    row?: NewMallActivityRow;
    type?: 'endTime';
    isActivityStarted?: boolean;
    mode?: 'BEGIN';
    disabled?: boolean;
}

export class NewMallActivityRegistrationTime extends PureComponent<
    NewMallActivityRegistrationTimeProps
> {
    static defaultProps: NewMallActivityRegistrationTimeProps = {
        value: {},
    };

    disabledDate = (current: moment.Moment | null) => {
        const { value, type } = this.props;
        const timeStr = value?.timeStr;
        if (type === 'endTime' && timeStr) {
            return !!(current && current < moment(timeStr));
        } else {
            return !!(current && current < moment().subtract(1, 'days'));
        }
    };

    onTimeTypeChange = (timeType: NewMallActivityRegistrationTimeType) => {
        const { value, onChange } = this.props;
        if (!value) {
            onChange && onChange({ timeType });
        } else {
            onChange && onChange({ ...value, timeType });
        }
    };
    onTimeChange = (date: moment.Moment | null) => {
        const { value, onChange } = this.props;

        const timeStr = moment(date || undefined).format('YYYY-MM-DD HH:mm:ss');
        if (!value) {
            onChange && onChange({ timeStr: timeStr });
        } else {
            onChange && onChange({ ...value, timeStr: timeStr });
        }
    };
    numChange = (num?: number) => {
        const { value, onChange } = this.props;
        if (!value) {
            onChange && onChange({ num });
        } else {
            onChange && onChange({ ...value, num });
        }
    };
    unitChange = (timeUnit: NewMallActivityRegistrationTimeUnit) => {
        const { value, onChange } = this.props;
        if (!value) {
            onChange && onChange({ timeUnit });
        } else {
            onChange && onChange({ ...value, timeUnit });
        }
    };
    private get isTicketTypeRound() {
        const { row } = this.props;
        const ticketType = row?.registrationInfo.ticketType;
        return ticketType === NewMallActivityTicketType.Round;
    }
    render() {
        const { value, mode, isActivityStarted } = this.props;
        const { timeType, timeUnit, timeStr, num } = value || {};
        let disabled = false;
        if (mode === 'BEGIN' && !this.isTicketTypeRound && isActivityStarted) {
            disabled = true;
        }
        const prefix = this.isTicketTypeRound ? language.getText('jvenue') : language.getText('hd');
        return (
            <div className="registration-time-components">
                {mode === 'BEGIN' ? (
                    <Select
                        style={{ width: '180px' }}
                        onChange={this.onTimeTypeChange}
                        optionLabelProp="label"
                        value={timeType}
                        disabled={disabled}
                    >
                        <Option
                            value={NewMallActivityRegistrationTimeType.DAY_TIME}
                            label={language.getText('zdrqsj')}
                            disabled={this.isTicketTypeRound && isActivityStarted}
                        >
                            {language.getText('zdrqsj')}
                        </Option>
                        <Option
                            value={NewMallActivityRegistrationTimeType.BEFORE_BEGIN}
                            label={`${prefix}${services.language.getText('ksq')}`}
                        >
                            {prefix}
                            {services.language.getText('ksq')}
                        </Option>
                    </Select>
                ) : (
                    <Select
                        style={{ width: '180px' }}
                        onChange={this.onTimeTypeChange}
                        optionLabelProp="label"
                        value={timeType}
                    >
                        <Option
                            value={NewMallActivityRegistrationTimeType.DAY_TIME}
                            label={language.getText('zdrqsj')}
                        >
                            {language.getText('zdrqsj')}
                        </Option>
                        <Option
                            value={NewMallActivityRegistrationTimeType.BEFORE_BEGIN}
                            label={`${prefix}${services.language.getText('ksq')}`}
                            disabled={!this.isTicketTypeRound && isActivityStarted}
                        >
                            {prefix}
                            {services.language.getText('ksq')}
                        </Option>

                        <Option
                            value={NewMallActivityRegistrationTimeType.AFTER_BEGIN}
                            label={`${prefix}${language.getText('ksh')}`}
                            disabled={!this.isTicketTypeRound && isActivityStarted}
                        >
                            {prefix}
                            {language.getText('ksh')}
                        </Option>
                        <Option
                            value={NewMallActivityRegistrationTimeType.BEFORE_END}
                            label={`${prefix}${language.getText('jsq')}`}
                        >
                            {prefix}
                            {language.getText('jsq')}
                        </Option>
                    </Select>
                )}
                {timeType === NewMallActivityRegistrationTimeType.DAY_TIME ? (
                    <DatePicker
                        disabled={disabled}
                        style={{ marginLeft: '20px' }}
                        format="YYYY-MM-DD HH:mm:ss"
                        showTime
                        value={timeStr ? moment(timeStr) : undefined}
                        onChange={this.onTimeChange}
                        placeholder={services.language.getText('selectPlease')}
                    />
                ) : (
                    <>
                        <InputNumber
                            value={num}
                            onChange={this.numChange}
                            placeholder={services.language.getText('inputPlease')}
                            style={{ width: '150px', marginLeft: '20px' }}
                            min={
                                timeUnit === NewMallActivityRegistrationTimeUnit.HOUR &&
                                mode !== 'BEGIN'
                                    ? 0
                                    : 1
                            }
                            max={99999999999}
                            step={1}
                            precision={0}
                        />
                        <Select
                            placeholder={services.language.getText('selectPlease')}
                            style={{ width: 90 }}
                            onChange={this.unitChange}
                            value={timeUnit}
                            disabled={disabled}
                        >
                            <Option value={NewMallActivityRegistrationTimeUnit.DAY}>
                                {language.getText('day')}
                            </Option>
                            <Option value={NewMallActivityRegistrationTimeUnit.HOUR}>
                                {language.getText('hour')}
                            </Option>
                        </Select>
                    </>
                )}
            </div>
        );
    }
}

/**
 * 校验报名开始结束时间
 * @description 宣传类活动无需校验
 * @description 报名开始日期为指定日期时，不能晚于活动结束时间
 * @description 报名开始结束日期都是指定日期时，报名开始日期不能晚于报名结束日期
 * @param row
 * @param isAdd 是否新增
 */
export function validateRegistrationTime(row: NewMallActivityRow, isAdd: boolean) {
    const {
        registrationInfo: { type, registrationStartTime, registrationEndTime },
    } = row;
    if (type === NewMallActivityType.Notification) {
        return false;
    }
    const { endDate, endTime } = getRowDateTime(row, isAdd);
    if (!endDate || !endTime) {
        return services.language.getText('qshdjsrqhsj');
    }
    if (!registrationStartTime || !registrationEndTime) {
        return language.getText('qsbmksjssj');
    }
    const { timeType: startTimeType, timeStr: startTimeStr } = registrationStartTime;
    const { timeType: endTimeType, timeStr: endTimeStr } = registrationEndTime;
    const isStartDayTime = startTimeType === NewMallActivityRegistrationTimeType.DAY_TIME;
    const isEndDayTime = endTimeType === NewMallActivityRegistrationTimeType.DAY_TIME;
    if (isStartDayTime) {
        if (!startTimeStr) {
            return language.getText('qsbmksrq');
        }
        if (isAfter(startTimeStr, `${endDate} ${endTime}`)) {
            return language.getText('bmkssjwyhdjssj');
        }
        if (isEndDayTime) {
            if (!endTimeStr) {
                return language.getText('qsbmjsrq');
            }
            if (isAfter(startTimeStr, endTimeStr)) {
                return language.getText('bmkssjbndybmjssj');
            }
        }
    }
    return false;
}

/**
 * 校验报名通知
 */
export function validateBusinessNotifications(row: any) {
    const businessNotifications = row.seniorSetting.businessNotifications;
    const participatNotifications = row.seniorSetting.participatNotifications;
    const registrationAbsentedNotifications = row.seniorSetting.registrationAbsentedNotifications;
    const registrationStartedNotifications = row.seniorSetting.registrationStartedNotifications;
    const selectedInfo = filter(businessNotifications, ['status', 1]);
    if (selectedInfo.length > 0) {
        for (let index = 0; index < selectedInfo.length; index++) {
            const item = selectedInfo[index];
            if (!checkBusinessNotifications(item)) {
                return false;
            }
            if (!checkNotificationLink(item)) {
                return false;
            }
        }
    }
    const participatSelectedInfo = filter(participatNotifications, ['status', 1]);
    if (participatSelectedInfo.length > 0) {
        for (let index = 0; index < participatSelectedInfo.length; index++) {
            const item = participatSelectedInfo[index];
            if (!checkBusinessNotifications(item)) {
                return false;
            }
            if (!checkNotificationLink(item)) {
                return false;
            }
        }
    }
    const registrationAbsentedSelectedInfo = filter(registrationAbsentedNotifications, [
        'status',
        1,
    ]);
    if (registrationAbsentedSelectedInfo.length > 0) {
        for (let index = 0; index < registrationAbsentedSelectedInfo.length; index++) {
            const item = registrationAbsentedSelectedInfo[index];
            if (!checkBusinessNotifications(item)) {
                return false;
            }
            if (!checkNotificationLink(item)) {
                return false;
            }
        }
    }
    const registrationStartedSelectedInfo = filter(registrationStartedNotifications, ['status', 1]);
    if (registrationStartedSelectedInfo.length > 0) {
        for (let index = 0; index < registrationStartedSelectedInfo.length; index++) {
            const item = registrationStartedSelectedInfo[index];
            if (!checkBusinessNotifications(item)) {
                return false;
            }
            if (!checkNotificationLink(item)) {
                return false;
            }
        }
    }
    if (
        selectedInfo.length ||
        participatSelectedInfo.length ||
        registrationAbsentedSelectedInfo.length ||
        registrationStartedSelectedInfo.length
    ) {
        const subscriptMessage = get(row, 'seniorSetting.subscriptMessage');
        if (!subscriptMessage || !subscriptMessage.num) {
            message.error(language.getText('hdcytxsjbnwk'));
            return false;
        }
    }
    return true;
}
