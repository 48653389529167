import React, { PureComponent } from 'react';
import { Form } from 'antd';
import { tools } from '@comall-backend-builder/design-components-basis';

import './index.less';
/**
 * 会员订单组件-配置表单
 */
class memberOrder extends PureComponent<any> {
    render() {
        return (
            <Form className="editor-member-order">
                <div className="tips">固定样式暂不支持配置</div>
            </Form>
        );
    }
}

export const MemberOrder = tools.ConfigFormDecorator(memberOrder);
