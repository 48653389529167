import React, { PureComponent } from 'react';
import { cloneDeep, forEach, find, uniqBy, isFunction } from 'lodash';
import { SelectMarketingSchedule } from './select-marketing-schedule';
import { message } from 'antd';
import { MarketingScheduleDisplay } from './marketing-schedule-display';
import { services } from '@comall-backend-builder/core';

export interface MarketingSchedule {
    merchantId: string;
    /**
     * 档期id
     */
    id: number;
    /**
     * 营销档期
     */
    name: string;
    /**
     * 门店名称
     */
    subsiteName: string;
    /**
     * 三方档期id
     */
    thirdId: string;
    /**
     * 档期时间
     */
    time: string;
    /**
     * 被选中的档期
     */
    select?: boolean;
    /**
     * 将要被删除的档期
     */
    delete?: boolean;
}

interface MerchantSelecterTickProps {
    onChange: (value: MarketingSchedule[] | undefined, name?: string) => void;
    value: MarketingSchedule[] | undefined;
    row: any;
    name: string;
    disabled: boolean;
    tips?: string;
    // subsiteField?: string;
    needDependencesMessage?: string;
    needDependencesText?: string;
    customFields?: {
        property: string;
    }[];
    customColumns?: {
        property: string;
    }[];
    customParams?: {
        [key: string]: any;
    };

    getParams: (row: any) => any;
}

interface MerchantSelecterStates {
    visible: boolean;
    //导入专柜弹窗是否展示
    importModalVisible: boolean;
    value: MarketingSchedule[] | undefined;
}

/**
 * 选择营销档期，表单上必须有单选门店组件
 */
export class MarketingScheduleSelector extends PureComponent<
    MerchantSelecterTickProps,
    MerchantSelecterStates
> {
    constructor(props: MerchantSelecterTickProps) {
        super(props);
        this.state = { visible: false, importModalVisible: false, value: [] };
    }
    componentWillReceiveProps(nextProps: MerchantSelecterTickProps) {
        const { getParams, row } = this.props;
        if (isFunction(getParams)) {
            const { subsiteIds: nextSubsites } = getParams(nextProps.row);
            const { subsiteIds: preSubsites } = getParams(row);
            this.handleSubsitesChange(nextSubsites, preSubsites);
        }
    }

    handleSubsitesChange = (nextSubsites: any, preSubsites: any) => {
        const { onChange } = this.props;
        if (!preSubsites) return;
        if (nextSubsites !== preSubsites) {
            onChange([]);
            return;
        }
    };

    /**
     * 选择营销档期
     * @param selectValues
     */
    onChange = (selectValues: MarketingSchedule[]) => {
        const { onChange } = this.props;
        if (onChange) {
            if (selectValues && selectValues.length === 0) {
                onChange([]);
            } else {
                onChange(selectValues);
            }
        }
    };

    /**
     * 添加营销档期弹窗确认后回调
     * @param selectValues 选中分站
     */
    onSelect = (selectValues: MarketingSchedule[]) => {
        const { name, onChange } = this.props;
        let newData: MarketingSchedule[] = [];

        if (!selectValues?.length) {
            newData = [];
        } else {
            newData = uniqBy(selectValues, 'id');
        }
        if (onChange) {
            onChange(newData, name);
        }
    };

    /**
     * 改变已选择的选中状态
     * @param selectValues
     */
    onChangeSelect = (selectValues: MarketingSchedule[]) => {
        const { onChange, value } = this.props;
        if (!value) return;
        const newData: MarketingSchedule[] = cloneDeep(value);
        forEach(newData, (market) => {
            const selectMarket = find(selectValues, (selectMarket) => {
                return selectMarket.id === market.id;
            });

            if (selectMarket) {
                market.select = true;
            } else {
                market.select = false;
            }
        });
        if (onChange) {
            if (newData && newData.length === 0) {
                onChange(undefined);
            } else {
                onChange(newData);
            }
        }
    };

    toggleModal = () => {
        const { visible } = this.state;
        if (!visible) {
            const { row, needDependencesMessage, getParams } = this.props;
            if (isFunction(getParams)) {
                const { subsiteIds } = getParams(row);
                if (subsiteIds) {
                    message.warn(
                        needDependencesMessage || services.language.getText('selectBeforeMerchant')
                    );
                    return;
                }
            }
        }
        this.setState({ visible: !visible });
    };

    toggleImportModal = () => {
        const { importModalVisible } = this.state;
        if (!importModalVisible) {
            const { row, needDependencesMessage, getParams } = this.props;
            if (isFunction(getParams)) {
                const { subsiteIds } = getParams(row);
                if (subsiteIds) {
                    message.warn(
                        needDependencesMessage || services.language.getText('selectBeforeMerchant')
                    );
                    return;
                }
            }
        }
        this.setState({ importModalVisible: !importModalVisible });
    };

    render() {
        const {
            value,
            disabled,
            row,
            tips,
            customFields,
            customColumns,
            customParams,
            needDependencesText,
            getParams,
        } = this.props;
        let params = {
            ...customParams,
        };
        if (isFunction(getParams)) {
            params = getParams(row);
        }
        const hasValue = value && value.length > 0;

        let selectDisabled = (!!needDependencesText && !params.subsiteIds) || disabled;
        let btnText =
            !!needDependencesText && !params.subsiteIds
                ? needDependencesText
                : services.language.getText('selectPlease');
        return (
            <div className="merchant-selecter-plus">
                <SelectMarketingSchedule
                    onChange={this.onSelect}
                    onCancel={this.toggleModal}
                    selectValues={value}
                    disabled={selectDisabled}
                    customFields={customFields}
                    btnText={btnText}
                    customColumns={customColumns}
                    params={params}
                />
                {!!tips && <div className="tips">{tips}</div>}

                {hasValue && (
                    <MarketingScheduleDisplay
                        data={value || []}
                        onChange={this.onChangeSelect}
                        onRemove={this.onChange}
                        disabled={disabled}
                    />
                )}
            </div>
        );
    }
}
