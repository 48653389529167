import React, { PureComponent } from 'react';
import { cloneDeep, findIndex, forEach, find, uniq } from 'lodash';
import {
    ShopSelectedDisplay,
    ShopSelector,
    DeliveryShopSelector,
    ShopSelectedDisplayMode,
} from '../../components';
import { message } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { language } from '@comall-backend-builder/core/lib/services';

export interface Shop {
    id: string;
    subSiteId: string;
    subSiteName: string;
    merchantId: string;
    merchantName: string;
    merchantType: string;
    merchantStatus: string;
    code?: string;
    /**
     * 被选中的专柜
     */
    select?: boolean;
    /**
     * 将要被删除的专柜
     */
    delete?: boolean;
}
export interface Property {
    property: string;
}

interface SelectShopProps<T> {
    value: any[];
    onChange: (v: any) => void;
    type: 'radio' | undefined;
    disabled?: boolean;
    /**
     * 只能选择唯一分站
     */
    uniqueSubsite?: boolean;
    valueColumns?: ColumnProps<T>[];
    formColumns?: Property[];
    fields?: Property[];
    displayMode?: ShopSelectedDisplayMode;
    fieldParams?: any;
    isDeliveryShop?: boolean;
    oldDataDeleteable: boolean;
}

export class SelectShop<T> extends PureComponent<
    SelectShopProps<T>,
    {
        value: Array<Shop>;
    }
> {
    static defaultProps = {
        disabled: false,
        oldDataDeleteable: true,
    };
    state = { value: new Array<Shop>() };

    /**
     * 选择分站
     * @param selectValues
     */
    onChange = (selectValues: Shop[]) => {
        const { onChange } = this.props;
        if (onChange) {
            if (selectValues && selectValues.length === 0) {
                onChange(undefined);
            } else {
                onChange(selectValues);
            }
        }
    };

    /**
     * 改变已选择分站选中状态
     * @param selectValues
     */
    onChangeSelect = (selectValues: Shop[]) => {
        const { onChange, value } = this.props;

        const newData: Shop[] = cloneDeep(value);
        forEach(newData, (shop) => {
            const selectShop = find(selectValues, (selectShop) => {
                return selectShop.id === shop.id;
            });

            if (selectShop) {
                shop.select = true;
            } else {
                shop.select = false;
            }
        });
        if (onChange) {
            if (newData && newData.length === 0) {
                onChange(undefined);
            } else {
                onChange(newData);
            }
        }
    };

    /**
     * 添加分站弹窗确认后回调
     * @param selectValues 选中分站
     */
    onSelect = (selectValues: Shop[]) => {
        const { value, type, uniqueSubsite } = this.props;
        let newData: Shop[] = [];

        if ('radio' !== type) {
            if (value) {
                newData = cloneDeep(value);
            }
            if (selectValues) {
                selectValues.forEach((selectShop: any) => {
                    const hasIndex = findIndex(value, (shop: any) => shop.id === selectShop.id);
                    if (hasIndex === -1) {
                        newData.unshift(selectShop);
                    }
                });
            }
        } else {
            newData = selectValues;
        }
        if (uniqueSubsite) {
            const selectedSubsiteIds = uniq(
                newData.map((row) => {
                    return row.subSiteId;
                })
            );
            if (selectedSubsiteIds.length > 1) {
                message.warn(language.getText('onlySelectCommonMerchant'));
                return;
            }
        }
        this.onChange(newData);
    };

    render() {
        const {
            value,
            type,
            disabled,
            uniqueSubsite,
            formColumns,
            valueColumns,
            fields,
            displayMode,
            fieldParams,
            isDeliveryShop,
            oldDataDeleteable,
        } = this.props;
        const hasValue = value && value.length > 0;
        return (
            <div>
                {!disabled && (
                    <div>
                        {isDeliveryShop ? (
                            <DeliveryShopSelector
                                type={type}
                                onChange={this.onSelect}
                                selectValues={value}
                                uniqueSubsite={uniqueSubsite}
                                columns={formColumns}
                                fields={fields}
                                params={fieldParams}
                            />
                        ) : (
                            <ShopSelector
                                type={type}
                                onChange={this.onSelect}
                                selectValues={value}
                                uniqueSubsite={uniqueSubsite}
                                columns={formColumns}
                                fields={fields}
                                params={fieldParams}
                            />
                        )}
                    </div>
                )}
                {hasValue && (
                    <ShopSelectedDisplay
                        data={value}
                        onChange={this.onChangeSelect}
                        onRemove={this.onChange}
                        disabled={disabled}
                        columns={valueColumns}
                        mode={displayMode}
                        oldDataDeleteable={oldDataDeleteable}
                    ></ShopSelectedDisplay>
                )}
            </div>
        );
    }
}
