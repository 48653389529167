import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import classNames from 'classnames';
import { isNil } from 'lodash';

import './index.less';

export class ValueRangeMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        const { expLower, expUpper } = displayInfo.row;
        const className = displayInfo.className,
            style = displayInfo.style;
        const hasValue = isNil(expLower) || isNil(expUpper);

        return hasValue ? (
            React.createElement(
                'span',
                { className: classNames('type-not-available', className), style: style },
                'NA'
            )
        ) : (
            <span>{`${expLower}-${expUpper}`}</span>
        );
    }
}
