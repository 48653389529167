import React, { PureComponent } from 'react';
import { Upload, Icon, message, Modal } from 'antd';
import { RcFile, UploadProps } from 'antd/lib/upload/interface';
import { api, language } from '@comall-backend-builder/core/lib/services';
import { remove, cloneDeep } from 'lodash';
import CropImageModal, { CustomOptions } from './CropImageModal';
import { services } from '@comall-backend-builder/core';

interface FileValue {
    /**
     * 文件id
     */
    id: string;
    /**
     * 文件路径地址
     */
    path: string;
}
interface ImageUploadAndCropProps extends Pick<UploadProps, 'accept'> {
    apiRoot: string;
    apiPath: string;
    name?: string;
    value?: FileValue[];
    fileName: string;
    maxCount?: number;
    maxSize?: number;
    customOptions?: CustomOptions;
    onChange?: (value: FileValue[], name?: string) => {};
}
interface ImageUploadAndCropState {
    cropVisible: boolean;
    currentFile?: RcFile;
    loading: boolean;
    previewImage: string;
    previewVisible: boolean;
    fileList: any[];
}

export class ImageUploadAndCrop extends PureComponent<
    ImageUploadAndCropProps,
    ImageUploadAndCropState
> {
    constructor(props: ImageUploadAndCropProps) {
        super(props);
        this.state = {
            cropVisible: false,
            currentFile: undefined,
            loading: false,
            previewImage: '',
            previewVisible: false,
            fileList: [],
        };
    }
    onBeforeUpload = (file: RcFile, fileList: RcFile[]) => {
        if (file) {
            if (this.verifyUpload(file))
                this.setState({
                    cropVisible: true,
                    currentFile: file,
                    fileList,
                });
        }
        return false;
    };
    onConfirm = (file?: RcFile) => {
        if (file) {
            this.customUpload(file);
            this.onModalCancel();
        }
    };
    verifyUpload = (file: RcFile) => {
        const { maxSize } = this.props;
        const rate = 1024;
        const maxLimitMb = 7;
        const maxLimitByte = maxSize ? maxSize * rate : maxLimitMb * rate * rate;
        if (file.size && file.size > maxLimitByte) {
            message.error(
                `${language.getText('tpdxcg')} ${maxSize ? Math.round(maxSize / rate) : 7}MB.`
            );
            return false;
        }
        const allowedEndFixes = ['png', 'jpg', 'jpeg', 'avif', 'webp'];
        const endFix = /.+\.(?<endFix>.+)/u.exec(file.name)?.groups?.endFix.toLowerCase();
        if (!(endFix && allowedEndFixes.includes(endFix))) {
            message.error(
                `${language.getText('zyxsc')} ${allowedEndFixes.join(', ')} ${language.getText(
                    'gsdtp'
                )}}`
            );
            return false;
        }
        return true;
    };
    customUpload = (file: RcFile) => {
        const { fileName, apiRoot, apiPath, onChange, name } = this.props;
        this.setState({
            loading: true,
        });
        api.upload(
            { files: file },
            {
                apiRoot,
                apiPath,
                fileName: fileName || 'files',
                progressCallBack: () => {},
            }
        )
            .then((res: any) => {
                this.setState({
                    loading: false,
                });
                if (onChange) {
                    onChange([res], name);
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                });
                if (err) {
                    message.error(
                        err.response?.body?.err_msg || services.language.getText('uoloadFail')
                    );
                }
            });
    };
    onModalCancel = () => {
        this.setState({
            currentFile: undefined,
            cropVisible: false,
        });
    };
    handlePreview = (id: string, path: string) => {
        this.setState({
            previewVisible: true,
            previewImage: path,
        });
    };

    handleDelete = (id: string) => {
        const { value, onChange } = this.props;
        if (value && value.length > 0) {
            const newValue = cloneDeep(value);
            remove(newValue, (item: any) => item.id === id);
            if (onChange) {
                onChange(newValue);
            }
        }
    };

    handleCancel = () => {
        this.setState({
            previewVisible: false,
        });
    };
    renderPreview = () => {
        const { value = [] } = this.props;
        const iconStyle = {
            color: '#FFFFFF',
            marginLeft: '5px',
            cursor: 'pointer',
        };
        return value.map((item) => {
            const style = {
                backgroundImage: `url(${item.path})`,
            };
            return (
                <div key={item.id}>
                    <div className="drag-upload-preview-image-container" style={style}>
                        <div className="drag-upload-preview-image-container-mask">
                            <Icon
                                type="eye"
                                style={iconStyle}
                                onClick={this.handlePreview.bind(this, item.id, item.path)}
                            />
                            <Icon
                                type="delete"
                                style={iconStyle}
                                onClick={this.handleDelete.bind(this, item.id)}
                            />
                        </div>
                    </div>
                </div>
            );
        });
    };
    render() {
        const { accept, customOptions, value = [], maxCount = 1 } = this.props;
        const { cropVisible, currentFile, loading, previewVisible, previewImage } = this.state;
        const uploadAccept = accept ? accept : 'image/*';
        const uploadButton = (
            <div>
                <Icon type={loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">{services.language.getText('upload')}</div>
            </div>
        );
        return (
            <div className="image-upload-and-crop">
                {this.renderPreview()}
                {value.length < maxCount ? (
                    <Upload
                        accept={uploadAccept}
                        listType="picture-card"
                        beforeUpload={this.onBeforeUpload}
                        showUploadList={false}
                    >
                        {uploadButton}
                    </Upload>
                ) : null}
                {currentFile ? (
                    <CropImageModal
                        modalVisible={cropVisible}
                        file={currentFile}
                        customOptions={customOptions}
                        onConfirm={this.onConfirm}
                        onModalCancel={this.onModalCancel}
                    />
                ) : null}
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="preview" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>
        );
    }
}
