import { Config } from '@comall-backend-builder/core/lib/parser';
import { api } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';
import { EntityButtonProps } from '../../../components';
import { ForwardDataCenterModal } from '../../../services';

export const config: Config = {
    entities: {
        ExpressCompanyEntity: {
            apiPath: '/loader/admin/express-company',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<wlgsmc>>',
                    controlConfig: {
                        placeholder: '<<qsrwlgsmc>>',
                        style: { width: 250 },
                    },
                },
                deliveryMode: {
                    type: 'string.options.select',
                    displayName: '<<deliveryType>>',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        {
                            id: 'EXPRESS',
                            name: '<<kd_2>>',
                        },
                        {
                            id: 'CITYWIDE',
                            name: '<<tcp>>',
                        },
                    ],
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                name: {
                    type: 'string',
                    displayName: '<<wlgsmc>>',
                },
                deliveryModeName: {
                    type: 'string',
                    displayName: '<<deliveryType>>',
                },
                sequence: {
                    type: 'string',
                    displayName: '<<xssx>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    defaultValue: 'true',
                    options: [
                        { id: 'true', name: '<<enabled>>' },
                        { id: 'false', name: '<<beforeClose>>' },
                    ],
                },
            },
        },
        ExpressCompanySettingEntity: {
            apiPath: '/loader/admin/express-company-setting',
            filters: {
                subsiteCode: {
                    type: 'string',
                    displayName: '<<subsiteCode>>',
                    controlConfig: { placeholder: '<<codeRequired>>' },
                },
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 200 },
                    },
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                subsiteCode: {
                    type: 'string',
                    displayName: '<<wzscmdbm>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<wzscmdmc>>',
                },
                code: {
                    type: 'string',
                    displayName: '<<wzscmdmc>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<wzscmdmc>>',
                },
            },
        },
    },
    components: {
        ExpressCompanyView: {
            component: 'Viewport',
            entity: 'ExpressCompanyEntity',
        },
        ExpressCompanyPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ExpressCompanyFilter',
                },
                { component: 'ExpressCompanyTable' },
            ],
        },
        ExpressCompanyFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 24,
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'deliveryMode',
                },
            ],
        },
        ExpressCompanyTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'ExpressCompanyDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: {
                        x: true,
                    },
                    columns: [
                        {
                            property: 'name',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                            },
                        },
                        {
                            property: 'deliveryModeName',
                        },
                        {
                            property: 'sequence',
                        },
                        {
                            property: 'status',
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        width: 200,
                        fixed: 'right',
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: false },
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                            apiPath: '/admin/express_companies/:id/disabled',
                                            config: {
                                                content: '{{row.name}}<<areadyClosed>>',
                                                text: '<<common.close>>',
                                            },
                                            confirm: {
                                                text: '<<isAreadyClose>>{{row.name}}?',
                                            },
                                        },
                                        {
                                            params: { status: true },
                                            value: false,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                            apiPath: '/admin/express_companies/:id/enabled',
                                            config: {
                                                content: '{{row.name}}<<areadyOpend>>',
                                                text: '<<common.opend>>',
                                            },
                                            confirm: {
                                                text: '<<isAreadyOpend>>{{row.name}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            // {
                            //     type: 'link',
                            //     text: '<<szmdgx>>',
                            //     path: '/express-company/setting/{{row.companyId}}',
                            // },
                        ],
                    },
                },
            ],
        },
        ExpressCompanySettingPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ExpressCompanySettingForm',
                },
            ],
        },
        ExpressCompanySettingForm: {
            component: 'FlexLayout',
            entity: 'ExpressCompanySettingEntity',
            direction: 'vertical',
            items: [
                {
                    component: 'ExpressCompanySettingFilter',
                    style: {
                        marginBottom: 16,
                    },
                },
                {
                    component: 'GridLayout',
                    style: { display: 'flex' },
                    items: [
                        {
                            component: 'ExpressCompanySettingModal',
                            triggerComponent: {
                                component: 'Button',
                                icon: 'plus',
                                type: 'primary',
                                text: '<<xzmddygx>>',
                                style: { margin: '0 25px 20px 0' },
                            },
                        },
                        {
                            component: 'ImportTable',
                            props: {
                                triggerType: 'primary',
                                triggerText: '<<batchImport>>',
                                triggerIcon: 'plus',
                                triggerStyle: {
                                    margin: '0 25px 20px 0',
                                },
                                controlDownloadTemplate: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                    apiPath: `/admin/express_companies/subsite_configs/template.xlsx`,
                                },
                                controlUploadApiPath: '/admin/files/locations/500/upload',
                                specialTips: ['<<qzqtxmdbm>>'],
                                controlHandles: [
                                    {
                                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                        apiPath:
                                            '/admin/express_companies/:companyId/subsite_configs/upload',
                                        method: 'post',
                                        type: 'primary',
                                        text: '<<xzdr>>',
                                    },
                                ],
                            },
                        },
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'EntityButton',
                                    props: {
                                        type: 'primary',
                                        children: '<<dcmdjg>>',
                                        onClick: async (
                                            _: any,
                                            entityButtonProps: EntityButtonProps
                                        ): Promise<void> => {
                                            try {
                                                const params: Record<string, any> = Object.assign(
                                                    {},
                                                    entityButtonProps.entity.params,
                                                    entityButtonProps.entity.filters
                                                );
                                                const config = {
                                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                                    apiPath:
                                                        '/admin/express_companies/:companyId/subsite_configs/export',
                                                };
                                                await api.get(params, config);
                                                ForwardDataCenterModal();
                                            } catch (err) {
                                                services.errorHandle(err);
                                            }
                                        },
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'ExpressCompanySettingTable',
                },
            ],
        },
        ExpressCompanySettingFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'subsiteCode',
                },
                {
                    property: 'subsiteIds',
                },
            ],
        },
        ExpressCompanySettingTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'ExpressCompanySettingDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: {
                        x: true,
                    },
                    columns: [
                        {
                            property: 'subsiteCode',
                        },
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'code',
                        },
                        {
                            property: 'name',
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        width: 110,
                        fixed: 'right',
                        items: [
                            {
                                type: 'component',
                                component: 'ExpressCompanySettingModal',
                                config: {
                                    edit: true,
                                    triggerComponent: {
                                        component: 'Button',
                                        type: 'link',
                                        text: '<<common.edit>>',
                                        style: { marginRight: '10px' },
                                    },
                                },
                            },
                            {
                                type: 'delete',
                                text: '<<common.delete>>',
                                config: {
                                    type: 'link',
                                    id: 'row.id',
                                    tooltip: null,
                                    title: '<<confirmDelete>>',
                                    okText: '<<common.ok>>',
                                    cancelText: '<<common.cancel>>',
                                },
                            },
                        ],
                    },
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/express-company',
                    component: 'ExpressCompanyView',
                    breadcrumbName: '<<expressCompany>>',
                    privilege: {
                        path: 'expressCompany',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/setting/:companyId',
                            component: 'ExpressCompanySettingPage',
                            breadcrumbName: '<<szmdgx>>',
                        },
                        { path: '/', component: 'ExpressCompanyPage' },
                    ],
                },
            ],
        },
    ],
};
