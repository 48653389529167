import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';

const api = services.api;
export const BargainActivityConfigsLoader: Loader = {
    get: async function(data, config) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/bargain_configs';
        return await api.get(data, config).then((res: any) => {
            res.result =
                res &&
                res.result.map((item: any) => {
                    item.subsiteName = item.subsite.name;
                    item.helpBargainCountEachMember = item.helpBargainCountEachMember
                        ? item.helpBargainCountEachMember
                        : '无限制';
                    return item;
                });
            return res;
        });
    },
};
