import * as React from 'react';
import { Button, Popover } from 'antd';
import { map } from 'lodash';
import classNames from 'classnames';

import { modes } from '@comall-backend-builder/types';
import { UploadFile, ImagePreview } from '@comall-backend-builder/components-basis';
import { language } from '@comall-backend-builder/core/lib/services';

function Upload(props: any) {
    let _uploadProps = {
        ...props,
    };
    if (_uploadProps.extra) delete _uploadProps.extra;
    let style = {
        maxWidth: 'auto',
    };
    return (
        <div>
            <UploadFile {..._uploadProps} />
            <div>
                <span style={{ color: '#ccc', fontSize: '14px' }}>{props.extra.tip}</span>
                <Popover
                    placement="bottom"
                    content={
                        <img
                            alt={language.getText('components.PageManage.preview')}
                            style={props.extra.style ? props.extra.style : style}
                            src={props.extra.img}
                        ></img>
                    }
                    trigger={props.extra.triggerType}
                >
                    <Button type="link">{props.extra.btnText}</Button>
                </Popover>
            </div>
        </div>
    );
}

export class ImageObjectArrayForTopPicMode extends modes.ArrayMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(array: any, displayInfo: any) {
        const { className, style, preview } = displayInfo;

        if (array && array.length) {
            // 原数据集array需要格式化，在preview中显示
            let formatArray: Array<any> = [];
            map(array, (item, key) => {
                formatArray.push({
                    id: item.id,
                    imageurl: item.path,
                    text: item.path,
                    component: 'Image',
                });
            });

            // 图片配置信息
            const imageConfig = {
                className: classNames('type-array mode-array-images', className),
                style: style || {},
            };

            // 预览组件配置信息
            const previewProps = {
                ...preview,
                type: 'modal',
                imageConfig,
                items: map(formatArray, 'imageurl'),
            };
            return <ImagePreview {...previewProps} />;
        } else {
            return this.getNotAvailableDisplayComponent(displayInfo);
        }
    }
    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            language.getText('notData')
        );
    }
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        let _controlInfo = Object.assign({}, controlInfo, {
            accept: controlInfo.accept ? controlInfo.accept : 'image/*',
            uploadType: 'multi',
            maxSize: controlInfo.maxSize ? controlInfo.maxSize : 2048,
        });

        return <Upload {..._controlInfo} />;
    }
}
