import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { defaults, get, forEach, isNull } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import './index.less';

export interface CheckinActivityAddFormViewProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
}

class checkinActivityAddFormView extends PureComponent<CheckinActivityAddFormViewProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }

    render() {
        const { entity, onSubmit, wrappedComponentRef } = this.props;
        let fields = [
            {
                property: 'baseInfo.name',
                controlConfig: {
                    style: { width: 300 },
                    placeholder: services.language.getText('yqdylhd'),
                },
            },
            { property: 'baseInfo.dateRange' },
            {
                property: 'baseInfo.subsiteIds',
                controlConfig: {
                    style: { width: 450 },
                },
                extra: services.language.getText('tmdjzckqygqdylhd'),
            },
            { property: 'baseInfo.marketingSchedule' },
            { property: 'baseInfo.recordShare' },

            {
                property: 'rewardInfo.id',
                className: 'property-hide',
            },
            {
                property: 'daysRewardRule',
            },
            {
                property: 'totalRewardRule',
                className: 'property-left',
            },
            {
                property: 'specialDayRewardRules',
                className: 'property-left',
                controlConfig: {
                    maxCount: 30,
                    rewardType: 'multi',
                    stepValueTextConfig: {
                        start: services.language.getText('xzrq_1'),
                        inputComponentType: 'DatePicker',
                    },
                },
            },
            {
                property: 'ladderPolling',
                visible: (values: any) =>
                    get(values, 'totalRewardRule.continuousCheckin') === false,
            },
            {
                property: 'pictureInfo.backgroundPicture',
                extra: services.language.getText('schjzszqdylsytb'),
            },
            { property: 'pictureInfo.description' },
            {
                property: 'pictureInfo.popupPicture',
                extra: services.language.getText('bszdcdtmrwbsd'),
            },
            {
                property: 'pictureInfo.sharePicture',
                extra: services.language.getText('tpccjywk'),
            },

            { property: 'pictureInfo.shareDescription' },
        ];

        let formProps = {
            entity,
            componentId: 'CheckinActivityAddFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'checkin-activity-add-form-container',
            direction: 'horizontal',
            labelCol: 5,
            controlCol: 19,
            fields: fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: services.language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: async (entityFields: any) => {
                //每日签到数据验证
                const daysRewardRule = get(entityFields, 'daysRewardRule', null);
                let hasValidate,
                    hasDaysRaward = false,
                    hasCumulativeReward = false;
                if (daysRewardRule && daysRewardRule.length > 0) {
                    hasValidate = true;
                    forEach(daysRewardRule, (reward: any) => {
                        let checkoutNum = 0;
                        if (reward.pointChecked && reward.pointChecked === true) {
                            checkoutNum = checkoutNum + 1;
                            if (!reward.point || reward.point === '') {
                                AntMessage.warning(services.language.getText('qtxxzhdjfz'));
                                hasValidate = false;
                                return false;
                            }
                        }
                        if (reward.experienceChecked && reward.experienceChecked === true) {
                            checkoutNum = checkoutNum + 1;
                            if (!reward.experience || reward.experience === '') {
                                AntMessage.warning(services.language.getText('qtxxzhdjyz'));
                                hasValidate = false;
                                return false;
                            }
                        }
                        if (reward.couponsChecked && reward.couponsChecked === true) {
                            checkoutNum = checkoutNum + 1;
                            if (!reward.couponsValue || reward.couponsValue.length === 0) {
                                AntMessage.warning(services.language.getText('qxzyhq'));
                                hasValidate = false;
                                return false;
                            }
                        }
                        if (reward.goldValueChecked && reward.goldValueChecked === true) {
                            checkoutNum = checkoutNum + 1;
                            if (!reward.goldValue || reward.goldValue === '') {
                                AntMessage.warning(services.language.getText('qtxxzhd'));
                                hasValidate = false;
                                return false;
                            }
                        }

                        if (checkoutNum > 0) {
                            hasDaysRaward = true;
                        }
                    });
                    if (!hasValidate) {
                        return false;
                    }
                }
                //获取累计签到奖励配置解析数据验证
                const checkinRewards = get(entityFields, 'totalRewardRule', null);

                if (
                    checkinRewards &&
                    checkinRewards.values.length > 0 &&
                    !isNull(checkinRewards.continuousCheckin)
                ) {
                    let maxStepValue = 0;
                    hasValidate = true;
                    forEach(checkinRewards.values, (reward: any) => {
                        let hasStepValue = false,
                            hasReward = false;
                        //1.验证累计天数限制（累计天数下一档需大于上一档）
                        if (reward.stepValue) {
                            hasStepValue = true;
                            if (reward.stepValue > maxStepValue) {
                                maxStepValue = reward.stepValue;
                            } else {
                                AntMessage.warning(services.language.getText('ljtsxydxdysydts'));
                                hasValidate = false;
                                return false;
                            }
                        }
                        //2.验证奖励项是否已填写或选择（必须选择其中一项）
                        let checkoutNum = 0;
                        if (reward.pointChecked && reward.pointChecked === true) {
                            checkoutNum = checkoutNum + 1;
                            if (!reward.point || reward.point === '') {
                                AntMessage.warning(services.language.getText('qtxxzhdz'));
                                hasValidate = false;
                                return false;
                            }
                        }
                        if (reward.experienceChecked && reward.experienceChecked === true) {
                            checkoutNum = checkoutNum + 1;
                            if (!reward.experience || reward.experience === '') {
                                AntMessage.warning(services.language.getText('qtxxzhdz'));
                                hasValidate = false;
                                return false;
                            }
                        }
                        if (reward.couponsChecked && reward.couponsChecked === true) {
                            checkoutNum = checkoutNum + 1;
                            if (!reward.couponsValue || reward.couponsValue.length === 0) {
                                AntMessage.warning(services.language.getText('qxzyhq'));
                                hasValidate = false;
                                return false;
                            }
                        }
                        if (reward.goldValueChecked && reward.goldValueChecked === true) {
                            checkoutNum = checkoutNum + 1;
                            if (!reward.goldValue || reward.experience === '') {
                                AntMessage.warning(services.language.getText('qtxxzhdz'));
                                hasValidate = false;
                                return false;
                            }
                        }
                        if (checkoutNum > 0) {
                            hasReward = true;
                        }

                        if (hasStepValue && !hasReward) {
                            AntMessage.warning(services.language.getText('qgxjlx'));
                            hasValidate = false;
                            return false;
                        }
                        if (!hasStepValue) {
                            AntMessage.warning(services.language.getText('lxqdts'));
                            hasValidate = false;
                            return false;
                        }
                        if (hasStepValue && hasReward) {
                            hasCumulativeReward = true;
                        }
                    });
                    // 验证是否至少设置了一中奖励方式
                    if (!hasDaysRaward && !hasCumulativeReward) {
                        AntMessage.warning(services.language.getText('mrqdgdjlhljqdewjl'));
                        hasValidate = false;
                        return false;
                    }
                    if (!hasValidate) {
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('CreateFormPlus'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange } = props;
    let form: any;

    return defaults(
        {
            onSubmit,
            onFieldChange,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('CheckinActivityAddFormContainer'));
            },
        }
    );
}
export const CheckinActivityAddFormView = connect(mapDispatchToProps)(checkinActivityAddFormView);
