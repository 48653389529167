import React, { Component } from 'react';
import { Radio, Input, Select, Checkbox, Collapse } from 'antd';
import { assign } from 'lodash';
import { DeliveryMode } from '../types';
import { formatDate } from '../../services';

import './index.less';
import { services } from '@comall-backend-builder/core';

const { Option } = Select;

/**
 * 一键叫单表单
 */
export class AutomaticDeliveryForm extends Component<any, any> {
    // 改变服务类型
    onChangeServiceType = (e: any) => {
        const { changeFormData, formData } = this.props;
        changeFormData(assign(formData, { serviceType: e.target.value }));
    };
    // 改变是否保价
    onChangeInsured = (checkValue: any) => {
        const { changeFormData, formData } = this.props;
        changeFormData(
            assign(formData, { insured: checkValue && checkValue.length ? true : false })
        );
    };
    // 改变是否预约取件
    onChangePickup = (checkValue: any) => {
        const pickup = checkValue && checkValue.length ? true : false;
        if (pickup) {
            this.computePickupTimeRanges();
        }
        const { changeFormData, formData } = this.props;
        changeFormData(assign(formData, { pickup }));
    };

    /**
     * 选中预约取件时，计算可选时间段
     */
    computePickupTimeRanges = () => {
        const { changeFormData, formData } = this.props;
        let today = new Date();
        const todayDate = formatDate(today, 'yyyy-MM-dd');

        const tomorrow = new Date(today.setDate(today.getDate() + 1));
        const tomorrowDate = formatDate(tomorrow, 'yyyy-MM-dd');

        const afterTomorrow = new Date(tomorrow.setDate(tomorrow.getDate() + 1));
        const afterTomorrowDate = formatDate(afterTomorrow, 'yyyy-MM-dd');

        const nowHours = today.getHours();
        // 今天的第一个选项（一小时内）
        let todayTimeRanges: any = [];
        const oneHourStart = new Date(today.setMinutes(today.getMinutes() + 5));
        const oneHourEnd = new Date(today.setMinutes(today.getMinutes() + 60));
        const oneHourRange =
            oneHourStart.getHours() +
            ':' +
            (oneHourStart.getMinutes() > 9
                ? oneHourStart.getMinutes()
                : '0' + oneHourStart.getMinutes()) +
            '-' +
            oneHourEnd.getHours() +
            ':' +
            (oneHourEnd.getMinutes() > 9 ? oneHourEnd.getMinutes() : '0' + oneHourEnd.getMinutes());
        let selectedPickupTimeRange = '';

        let startDate = todayDate + ' ' + (nowHours + 1) + ':00';
        let endDate = todayDate + ' 20:00';

        // 当天未超过19点才可选预约时段，否则当天无可选时段
        if (nowHours < 19) {
            let todayOtherRanges;

            if (nowHours >= 9) {
                todayOtherRanges = this.computeTimeRanges(startDate, endDate);
                todayTimeRanges.push({
                    name: services.language.getText('yxsn'),
                    value: oneHourRange,
                });
                selectedPickupTimeRange = oneHourRange;
            } else {
                startDate = todayDate + ' 09:00';
                todayOtherRanges = this.computeTimeRanges(startDate, endDate);
                selectedPickupTimeRange = todayOtherRanges[0].value;
            }

            todayTimeRanges.push(...todayOtherRanges);
        }
        // 明天可选时间段
        startDate = tomorrowDate + ' 09:00';
        endDate = tomorrowDate + ' 20:00';
        const tomorrowTimeRanges = this.computeTimeRanges(startDate, endDate);
        // 后天可选时间段
        startDate = afterTomorrowDate + ' 09:00';
        endDate = afterTomorrowDate + ' 20:00';
        const afterTomorrowTimeRanges = this.computeTimeRanges(startDate, endDate);
        formData.selectedPickupTimeRange = selectedPickupTimeRange;
        formData.pickupTimeRange.today = {
            name: services.language.getText('jt_1'),
            value: 'today',
            date: todayDate,
            timeRanges: todayTimeRanges,
        };
        formData.pickupTimeRange.tomorrow = {
            name: services.language.getText('mt'),
            value: 'tomorrow',
            date: tomorrowDate,
            timeRanges: tomorrowTimeRanges,
        };
        formData.pickupTimeRange.afterTomorrow = {
            name: services.language.getText('ht_1'),
            value: 'afterTomorrow',
            date: afterTomorrowDate,
            timeRanges: afterTomorrowTimeRanges,
        };
        formData.selectedPickupDay = 'today';
        changeFormData(formData);
    };
    /**
     * 生成两个时间之间的时间段（60分钟为一段）
     */
    computeTimeRanges = (startDate: string, endDate: string) => {
        // space=分钟
        // 输入两个时间startDate, endDate,还有时间间隔space(分钟)，返回两个时间之间每隔space的时间
        // 如：startDate="2022-07-06 9:00"，endDate="2022-07-06 12:00"，space=30
        const startTime = new Date(startDate).getTime(); // 将开始时间 转换成时间戳
        const endTime = new Date(endDate).getTime();
        let n = 1;
        let step = 0;
        let date = null;
        let newStartTime = 0;
        let attrTime = [];
        let time1 = `${
            new Date(startDate).getHours() === 0 ? '00' : new Date(startDate).getHours()
        }:${new Date(startDate).getMinutes() === 0 ? '00' : new Date(startDate).getMinutes()}`;
        let time2 = '';
        while (newStartTime < endTime) {
            step = Number(60) * n * 60 * 1000; // 将时间间隔转换为时间戳
            date = new Date(startTime + step); // 计算新时间
            newStartTime = new Date(startTime + step).getTime(); // 新时间的时间戳
            time2 = `${date.getHours() === 0 ? '00' : date.getHours()}:${
                date.getMinutes() === 0 ? '00' : date.getMinutes()
            }`;
            attrTime.push({ name: `${time1}-${time2}`, value: `${time1}-${time2}` });
            time1 = time2;
            n++;
        }
        return attrTime;
    };

    // 改变预约日期
    onChangePickupDay = (value: string) => {
        const { changeFormData, formData } = this.props;
        const { pickupTimeRange } = formData;
        const timeRanges = pickupTimeRange[value]?.timeRanges;
        changeFormData(
            assign(formData, {
                selectedPickupDay: value,
                selectedPickupTimeRange: timeRanges && timeRanges.length ? timeRanges[0].value : '',
            })
        );
    };

    // 改变预约时间段
    onChangePickupTimeRange = (value: string) => {
        const { changeFormData, formData } = this.props;
        changeFormData(assign(formData, { selectedPickupTimeRange: value }));
    };
    // 改变备注
    onChangeRemark = (e: any) => {
        const { changeFormData, formData } = this.props;
        changeFormData(assign(formData, { remark: e.target.value }));
    };

    render() {
        const {
            deliveryMode,
            expressCompanyList,
            formData,
            onChangeCompany,
            loadExpressCompany,
            batchDelivery = false,
        } = this.props;
        const { pickupTimeRange, selectedPickupDay, companyId } = formData;
        const timeRanges = pickupTimeRange[selectedPickupDay]?.timeRanges;
        return (
            <div className="automatic-delivery-form">
                <div className="automatic-delivery-form__form">
                    <span className="automatic-delivery-form__form-label">
                        {services.language.getText('deliveryType')}：
                    </span>
                    <div className="automatic-delivery-form__form-input">
                        <Select style={{ width: 300 }} disabled value={deliveryMode}>
                            <Option value={DeliveryMode.EXPRESS}>
                                {services.language.getText('kd_2')}
                            </Option>
                            <Option value={DeliveryMode.CITYWIDE}>
                                {services.language.getText('tcp')}
                            </Option>
                            <Option value={DeliveryMode.PICK_UP}>
                                {services.language.getText('zt')}
                            </Option>
                            <Option value={DeliveryMode.NONE}>
                                {services.language.getText('other')}
                            </Option>
                        </Select>
                    </div>
                </div>
                <div className="automatic-delivery-form__form">
                    <span className="automatic-delivery-form__form-label">
                        {services.language.getText('wlgs')}：
                    </span>
                    <div className="automatic-delivery-form__form-input">
                        <Select
                            autoClearSearchValue
                            showSearch
                            filterOption={false}
                            placeholder={services.language.getText('selectPlease')}
                            value={companyId}
                            onChange={onChangeCompany}
                            onSearch={loadExpressCompany}
                            onFocus={loadExpressCompany}
                            style={{ width: 300 }}
                        >
                            {expressCompanyList.map((i: any) => (
                                <Option key={i.companyId} value={i.companyId}>
                                    {i.companyName}
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <Collapse
                    bordered={false}
                    style={{ backgroundColor: '#fff', marginTop: '10px' }}
                    expandIconPosition="right"
                >
                    <Collapse.Panel
                        style={{ border: 0 }}
                        header={services.language.getText('bgtsyq')}
                        key="1"
                    >
                        <div>
                            {formData.serviceTypes && formData.serviceTypes.length ? (
                                <div className="automatic-delivery-form__form">
                                    <span className="automatic-delivery-form__form-label">
                                        {services.language.getText('fwlx')}：
                                    </span>
                                    <div className="automatic-delivery-form__form-input">
                                        <Radio.Group
                                            value={formData.serviceType}
                                            onChange={this.onChangeServiceType}
                                        >
                                            {formData.serviceTypes.map((item: any) => (
                                                <Radio key={item.id} value={item.code}>
                                                    {item.description}
                                                </Radio>
                                            ))}
                                        </Radio.Group>
                                    </div>
                                </div>
                            ) : null}
                            {!batchDelivery && (
                                <div className="automatic-delivery-form__form">
                                    <span className="automatic-delivery-form__form-label">
                                        {services.language.getText('bj_2')}：
                                    </span>
                                    <div className="automatic-delivery-form__form-input">
                                        <Checkbox.Group
                                            value={[formData.insured]}
                                            onChange={this.onChangeInsured}
                                        >
                                            <Checkbox value={true}>
                                                {services.language.getText('xybj')}
                                            </Checkbox>
                                        </Checkbox.Group>
                                    </div>
                                </div>
                            )}

                            <div className="automatic-delivery-form__form">
                                <span className="automatic-delivery-form__form-label">
                                    {services.language.getText('yyqj')}：
                                </span>
                                <div className="automatic-delivery-form__form-input">
                                    <Checkbox.Group
                                        value={[formData.pickup]}
                                        onChange={this.onChangePickup}
                                    >
                                        <Checkbox value={true}>
                                            {services.language.getText('zdljsj')}
                                        </Checkbox>
                                    </Checkbox.Group>
                                </div>
                            </div>
                            {formData.pickup && (
                                <div className="automatic-delivery-form__form">
                                    <span className="automatic-delivery-form__form-label">
                                        {services.language.getText('selectTime')}：
                                    </span>
                                    <div className="automatic-delivery-form__form-input">
                                        <Select
                                            style={{ width: 100 }}
                                            value={formData.selectedPickupDay}
                                            onChange={this.onChangePickupDay}
                                        >
                                            <Option value={pickupTimeRange.today.value}>
                                                {pickupTimeRange.today.name}
                                            </Option>
                                            <Option value={pickupTimeRange.tomorrow.value}>
                                                {pickupTimeRange.tomorrow.name}
                                            </Option>
                                            <Option value={pickupTimeRange.afterTomorrow.value}>
                                                {pickupTimeRange.afterTomorrow.name}
                                            </Option>
                                        </Select>
                                        <Select
                                            style={{ width: 130, marginLeft: '15px' }}
                                            value={formData.selectedPickupTimeRange}
                                            onChange={this.onChangePickupTimeRange}
                                        >
                                            {timeRanges &&
                                                timeRanges.map((item: any) => (
                                                    <Option key={item.value} value={item.value}>
                                                        {item.name}
                                                    </Option>
                                                ))}
                                        </Select>
                                    </div>
                                </div>
                            )}

                            {!batchDelivery && (
                                <div className="automatic-delivery-form__form">
                                    <span className="automatic-delivery-form__form-label">
                                        {services.language.getText('remark')}：
                                    </span>
                                    <div className="automatic-delivery-form__form-input">
                                        <Input
                                            onChange={this.onChangeRemark}
                                            value={formData.remark}
                                            maxLength={200}
                                        ></Input>
                                    </div>
                                </div>
                            )}
                        </div>
                    </Collapse.Panel>
                </Collapse>
            </div>
        );
    }
}
