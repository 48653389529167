import React, { Component } from 'react';
import { Table, Button } from 'antd';
import { cloneDeep, remove } from 'lodash';
import { Article } from './index';
import { services } from '@comall-backend-builder/core';

const { getText } = services.language;

interface ArticleSelectedProps {
    data: Array<Article>;
    onRemove: (data: Array<Article>) => void;
    disabled: boolean;
}

/**
 * 已选文章
 */
class ArticleSelected extends Component<ArticleSelectedProps> {
    selectedRows: Article[] = [];

    getTableConfig = () => {
        const { disabled } = this.props;
        let config: any = {
            rowKey: 'id',
            columns: [
                {
                    title: `${getText('wz_1')}ID`,
                    dataIndex: 'id',
                    key: 'name',
                    ellipsis: true,
                },
                {
                    title: getText('ywlx'),
                    dataIndex: 'businessTypeStr',
                    key: 'businessTypeStr',
                },
                {
                    title: getText('wzbt'),
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: getText('status'),
                    dataIndex: 'status',
                    key: 'status',
                    render: (status: number) => (
                        <span className="goods-field-name">
                            {status === 1 ? getText('beforeOpen') : getText('beforeClose')}
                        </span>
                    ),
                },

                {
                    title: getText('cjrq'),
                    dataIndex: 'createTime',
                    key: 'createTime',
                },
                {
                    title: getText('common.tableAction'),
                    className: 'action-column',
                    key: 'operation',
                    width: 100,
                    render: (i: any, row: any) => {
                        return !disabled ? (
                            <Button type={'link'} onClick={this.onRemove(row)}>
                                {getText('common.delete')}
                            </Button>
                        ) : null;
                    },
                },
            ],
            style: {
                marginTop: 20,
            },
        };
        return config;
    };

    onRemove = (row: Article) => {
        const { data, onRemove } = this.props;

        return () => {
            const result = cloneDeep(data);
            remove(result, (subsite) => row.id === subsite.id);
            onRemove(result);
        };
    };

    render() {
        const { data } = this.props;
        if (!data || data.length === 0) return '';
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: data,
        };

        return <Table {...tableProps} pagination={false}></Table>;
    }
}

export { ArticleSelected };
