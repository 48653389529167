import { forEach } from 'lodash';
import { services } from '@comall-backend-builder/core/';

export const mallActivityDesignPagesLoader = {
    get: async function(data: any, config: any) {
        config.apiPath = '/admin/pages/MALL_ACTIVITY';
        if (data.id) {
            config.apiPath = '/admin/pages/MALL_ACTIVITY/' + data.id;
        }

        return services.api.get(data, config).then((res: any) => {
            if (res && res.type) {
                res.type = { id: res.type };
            }
            if (res && res.result) {
                forEach(res.result, (i) => {
                    i.canDelete = !i.enabled;
                });
            }
            return res;
        });
    },
    post: function(data: any, config: any) {
        config.apiPath = '/admin/pages/MALL_ACTIVITY';
        return services.api.post(data, config);
    },
    put: function(data: any, config: any) {
        config.apiPath = '/admin/pages/MALL_ACTIVITY/' + data.id;
        return services.api.put(data, config);
    },
    delete: function(data: any, config: any) {
        config.apiPath = '/admin/pages/MALL_ACTIVITY/' + data.id;
        return services.api.delete(data, config);
    },
};
