import React, { PureComponent, createElement } from 'react';
import { Modal as AntModal, Button, message } from 'antd';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { isEqual, remove, map, get, forEach, cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { Shop } from '../../../../containers/select-shop';

const TableComponentId = 'MerchantPageSelector';
let TableComponentNo = 1;
let Entity: any = {};
let entity: any = {};

class selectMerchant extends PureComponent<{
    selectValues: any;
    onChange: (value: any[]) => void;
    btnText?: string;
    disabled: boolean;
    params: any;
    onCancel: () => void;
    customFields?: {
        property: string;
    }[];
    customColumns?: {
        property: string;
    }[];
    requestStatus: string;
}> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('MerchantPageSelector');
        entity = new Entity({});
        this.tableComponentId = TableComponentId + TableComponentNo;
        TableComponentNo++;
    }
    tableComponentId: string = '';
    state = { selectedRowKeys: [], visible: false };
    dispatch: any = {};
    selectedRows = new Array<Shop>();

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(entity));
    }
    componentWillReceiveProps(nextProps: any) {
        const { params: preParams } = this.props;
        const { params: nextParams } = nextProps;
        if (!isEqual(preParams, nextParams) && !!nextParams.subSiteName) {
            this.selectedRows = [];
            this.setSelection([]);
        }
    }

    setSelection = (rows: any) => {
        const selectedRows = rows;
        if (selectedRows && selectedRows.length > 0) {
            setTimeout(() => {
                this.dispatch(
                    actions.tableRowSelectionChangeAction(TableComponentId, map(selectedRows, 'id'))
                );
            }, 300);
        } else {
            this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
        }
    };

    onTableRowSelect = (record: Shop, selected: boolean) => {
        if (selected) {
            this.selectedRows.push(record);
        } else {
            remove(this.selectedRows, (r) => r.id === record.id);
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: Shop[]) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            this.selectedRows = [];
        }
    };
    toggleModal = () => {
        const { visible } = this.state;
        const { params } = this.props;
        if (!visible) {
            if (!params || !params.subSiteName) {
                message.warn(services.language.getText('selectBeforeMerchant'));
                return;
            }
        }
        //判断filters 当前是否有排序字段 没有则更新页面显示（解决同一组件多场景使用下！.filters.order页面排序却高亮的问题）
        if (entity && (!entity.filters || (entity.filters && !entity.filters.order))) {
            const store = builder.getStore();
            store.dispatch(
                actions.tableSortChangeAction(TableComponentId, {
                    field: undefined,
                    order: undefined,
                })
            );
        }
        this.setState({ visible: !visible }, () => {
            if (!visible && entity.paging) {
                entity.search({
                    ...entity.paging,
                    ...params,
                });
            }
            let selectedRows = cloneDeep(this.props.selectValues);
            this.selectedRows = selectedRows;
            this.setSelection(selectedRows);
        });
    };
    onCancel = () => {
        this.setState({ visible: false });
    };
    onOk = () => {
        const { onChange } = this.props;
        if (onChange) {
            forEach(this.selectedRows, (item) => {
                item.select = true;
            });
            onChange(this.selectedRows);
            this.onCancel();
        }
    };
    initEntityFilters = () => {
        entity.filtersChange({
            merchantType: undefined,
            merchantName: undefined,
            sorter: undefined,
        });
        entity.pageChange({ page: 1 });
        const store = builder.getStore();
        store.dispatch(actions.filtersChangeAction(entity, {}));
        store.dispatch(
            actions.tableSortChangeAction(TableComponentId, { field: undefined, order: undefined })
        );
    };

    getConfig = () => {
        const { params, customFields, customColumns, selectValues } = this.props;
        const values = cloneDeep(selectValues);
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: params || {},
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: {
                        textAlign: customFields && customFields.length > 0 ? 'left' : 'right',
                    },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        entity.search(params);
                    },
                    onReset: () => {
                        this.initEntityFilters();
                    },
                    fields:
                        customFields && customFields.length > 0
                            ? customFields
                            : [
                                  {
                                      property: 'merchantName',
                                  },
                              ],
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    key: Math.random(),
                    scroll: {
                        y: 400,
                    },
                    pagination: false,
                    rowKey: 'id',
                    rowSelection: {
                        type: 'checkbox',
                        selectedRowKeys: map(values, (value) => {
                            return value.id + '';
                        }),
                        onSelect: (record: Shop, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: Shop[]) => {
                            this.onTableRowSelectAll(selected, rows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    columns:
                        customColumns && customColumns.length > 0
                            ? customColumns
                            : [
                                  {
                                      property: 'merchantName',
                                  },
                                  {
                                      property: 'code',
                                  },
                                  {
                                      property: 'merchantType',
                                  },
                                  {
                                      property: 'merchantStatus',
                                  },
                              ],
                },
            ],
        };
    };
    render() {
        const { btnText, disabled } = this.props;
        const { visible } = this.state;
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 900,
            title: services.language.getText('selectSubsites'),
            visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.onCancel,
        };
        return (
            <div>
                <Button type="link" disabled={disabled} onClick={this.toggleModal}>
                    {btnText ? btnText : services.language.getText('selectSubsites')}
                </Button>
                <AntModal {...modalProps}>{modalContent}</AntModal>
            </div>
        );
    }
}
export const SelectMerchant = connect((_state: any) => {
    let requestStatus = get(entity, 'requestStatus', null);
    const result = { requestStatus };
    return result;
})(selectMerchant);
