import { ObjectFormat } from '@comall-backend-builder/types';
import { language } from '@comall-backend-builder/core/lib/services';

/**
 *  邀请有礼，被邀请人奖励
 */
export class ObjectActivityRewardInvitedFormat extends ObjectFormat {
    /**
     * 对数据进行校验
     */
    public validate(rule: any, value: any, callback: any) {
        try {
            callback();
        } catch (error) {
            callback(language.getText('validation.object.default'));
        }
    }
}
