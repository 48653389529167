import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import get from 'lodash/get';
import { Modal } from 'antd';
import { getUrlParam } from '../../../applications/cae/services/utils';
import { MaotaiPresaleConditionsDefaultValue } from '../../../containers/maotai-presale/maotai-presale-conditions';
import { MaotaiMessageTypes } from '../../../containers/maotai-presale/maotai-presale-message-type';

const statusOptions = [
    {
        name: '<<notStarted>>',
        id: 'NOT_STARTED',
    },
    {
        name: '<<inprogress>>',
        id: 'STARTED',
    },
    {
        name: '<<end>>',
        id: 'COMPLETE',
    },
];
export enum OperationResults {
    /**
     * 成功
     */
    SUCCESS = 'SUCCESS',
    /**
     * 失败
     */
    FAIL = 'FAIL',
    /**
     * 部分成功
     */
    SOME_SUCCESS = 'SOME_SUCCESS',
}

export const OPERATION_RESULTS_OPYIONS = [
    {
        id: OperationResults.SUCCESS,
        name: '<<success>>',
    },
    {
        id: OperationResults.FAIL,
        name: '<<sb_2>>',
    },
    {
        id: OperationResults.SOME_SUCCESS,
        name: '<<bfcg>>',
    },
];

export const config: Config = {
    entities: {
        maotaiPresaleEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
            apiPath: '/loader/admin/maotaiPresale',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsryhqlbmc>>' },
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        style: { width: 240 },
                    },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: statusOptions,
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
                mode: {
                    type: 'string.options.select',
                    displayName: '<<productType>>',
                    options: [
                        {
                            id: 'SINGLE',
                            name: '<<ftmt>>',
                        },
                        {
                            id: 'MULTIPLE',
                            name: '<<mtxlj>>',
                        },
                        {
                            id: 'OTHER',
                            name: '<<other>>',
                        },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                code: {
                    type: 'string',
                    displayName: '<<hdzbm>>',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string',
                    displayName: '<<acitivityBeginTime>>',
                },
                endTime: {
                    type: 'string',
                    displayName: '<<acitivityEndTime>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: statusOptions,
                },
                registrationCount: {
                    type: 'string',
                    displayName: '<<bmrs>>',
                },
                prizeCount: {
                    type: 'string',
                    displayName: '<<zqrs>>',
                },
                whitePrizeCount: {
                    type: 'string',
                    displayName: '<<bmdzqrs>>',
                },
                activityInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseInfo>>',
                    properties: {
                        code: {
                            type: 'string',
                            displayName: '<<hdzbm>>',
                            extra: '<<dghdsrxtdhdzbmh>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrhdzbm>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<tygbmdhdhjxsgxy>>',
                                maxLength: 20,
                            },
                        },
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrhdmc>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                            },
                        },
                        mode: {
                            type: 'string.options.radio',
                            displayName: '<<productType>>',
                            defaultValue: 'SINGLE',
                            options: [
                                {
                                    id: 'SINGLE',
                                    name: '<<ftmt>>',
                                },
                                {
                                    id: 'MULTIPLE',
                                    name: '<<mtxlj>>',
                                },
                                {
                                    id: 'OTHER',
                                    name: '<<other>>',
                                },
                            ],
                        },
                        dateRange: {
                            type: 'object.dateRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<acitivityTimeRequired>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                                format: 'YYYY-MM-DD',
                            },
                        },
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            rules: [
                                {
                                    required: true,
                                    type: 'array',
                                },
                            ],
                        },
                        goods: {
                            type: 'array.PresaleProducts',
                            displayName: '<<components.Tools.selectProduct>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<components.Tools.productSelectRequired>>',
                                },
                            ],
                            controlConfig: {
                                selectionType: 'checkbox',
                            },
                        },
                        description: {
                            type: 'string.richTextPlus',
                            displayName: '<<hdsm>>',
                            rules: [{ max: 20000, message: '<<notMoreThan2000>>' }],
                            controlConfig: {
                                placeholder: '<<descriptionRule>>',
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            extra: '<<descriptionExtra>>',
                        },
                    },
                },
                roundsInfo: {
                    type: 'object.subForm',
                    displayName: '<<ccgz>>',
                    properties: {
                        registerTimeRange: {
                            displayName: '<<hdbmsj>>',
                            type: 'object.dateTimeRangePlus',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        roundType: {
                            type: 'string.options.radio',
                            displayName: '<<cczq>>',
                            defaultValue: 'ROUND',
                            options: [
                                {
                                    id: 'ROUND',
                                    name: '<<mryc>>',
                                },
                                {
                                    id: 'CUSTOM',
                                    name: '<<zdycc>>',
                                },
                            ],
                        },
                        purchaseMode: {
                            type: 'object.maotaiPresalePurchaseMode',
                            displayName: '<<sgzq>>',
                        },
                        rounds: {
                            displayName: '<<ccmx>>',
                            type: 'array.maotaiPresaleRounds',
                        },
                        purchaseTime: {
                            type: 'number.tip',
                            displayName: '<<gmsj>>',
                            rules: [
                                {
                                    required: true,
                                },
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<inputThanZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^([1-9]\d*)$/;
                                        if (value && !reg.test(value)) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                addonbefore: '<<sgh>>',
                                addonafter: '<<tnxwcgm>>',
                                placeholder: '<<inputPlease>>',
                                maxLength: 10,
                                style: {
                                    width: 150,
                                },
                            },
                        },
                    },
                },
                registrationConditionsInfo: {
                    type: 'object.subForm',
                    displayName: '<<bmgz>>',
                    properties: {
                        conditions: {
                            displayName: '<<bmtj>>',
                            type: 'array.maotaiPresaleRegistrationConditions',
                            options: [],
                            source: {
                                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                                apiPath: '/loader/admin/maotaiCardLevels',
                                // dependences: ['activityInfo.subsites'],
                            },
                            defaultValue: MaotaiPresaleConditionsDefaultValue,
                        },
                    },
                },
                prizeInfo: {
                    type: 'object.subForm',
                    displayName: '<<sggz>>',
                    properties: {
                        prizeCondition: {
                            type: 'array.maotaiPresalePrizeConditions',
                            displayName: '<<sgtj>>',
                        },
                        memberLimitQuantity: {
                            type: 'number.tip',
                            displayName: '<<sgslxz>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<inputThanZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^([1-9]\d*)$/;
                                        if (
                                            value !== undefined &&
                                            value !== null &&
                                            (!reg.test(value) || value === 0)
                                        ) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                addonbefore: '<<hdqjmyhzdsg>>',
                                addonafter: '<<p>>',
                                style: { width: '140px' },
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                                placeholder: '<<inputPlease>>',
                            },
                        },
                        whiteQuantity: {
                            type: 'number.tip',
                            displayName: '<<zqbmdyhsgsl>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 0,
                                    message: '<<qsrdydy>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^([0-9]\d*)$/;
                                        if (
                                            value !== undefined &&
                                            value !== null &&
                                            !reg.test(value)
                                        ) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                addonbefore: '<<mchdmgzqbmdyhsg>>',
                                addonafter: '<<p>>',
                                style: { width: '140px' },
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                                placeholder: '<<inputPlease>>',
                            },
                        },
                        prizeQualityLimit: {
                            type: 'array.maotaiPresalePrizeQualityLimit',
                            displayName: '<<ptyhsgsl>>',
                            options: [],
                            rules: [
                                {
                                    required: true,
                                    message: '<<qtxmyhsgsl>>',
                                },
                            ],
                            source: {
                                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                                apiPath: '/loader/admin/maotaiCardLevels',
                                // dependences: ['activityInfo.subsites'],
                            },
                            defaultValue: [
                                {
                                    conditionType: 'LIMIT3',
                                    rules: [
                                        {
                                            num: '',
                                            conditionRules: [
                                                [{ conditionType: 'REALNAME', checked: true }],
                                            ],
                                        },
                                    ],
                                },
                            ],
                        },
                        drawType: {
                            type: 'string.options.radio',
                            displayName: '<<alccj>>',
                            defaultValue: 'AUTO',
                            options: [
                                {
                                    id: 'AUTO',
                                    name: '<<xtzdjsmlksgps>>',
                                },
                                {
                                    id: 'CUSTOM',
                                    name: '<<zdyszmlksgps>>',
                                },
                            ],
                        },
                        drawQuantitys: {
                            type: 'array.maotaiPresaleDrawQualityLimit',
                            displayName: '<<szcjcs>>',
                            options: [],
                            source: {
                                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                                apiPath: '/loader/admin/maotaiCardLevels',
                                // dependences: ['activityInfo.subsites'],
                            },
                        },
                    },
                },
                messageConfig: {
                    type: 'object.subForm',
                    displayName: '<<tzpz>>',
                    properties: {
                        messageType: {
                            type: 'array.maotaiPresaleMessageType',
                            displayName: '<<tzqd>>',
                            defaultValue: [
                                { type: MaotaiMessageTypes.MINIPROGRAM_SUBSCRIPTION },
                                { type: MaotaiMessageTypes.WECHAT_TEMPLATE },
                                { type: MaotaiMessageTypes.MESSAGE },
                            ],
                        },
                    },
                },
            },
        },
        maotaiPresaleForecastEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
            apiPath: '/loader/admin/maotaiPresale/forecast',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsryhqlbmc>>' },
                },
                subsiteId: {
                    type: 'array.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        style: { width: 240 },
                    },
                },

                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
                code: {
                    type: 'string',
                    displayName: '<<hdzbm>>',
                    controlConfig: { placeholder: '<<hdzbm>>' },
                },
            },
            properties: {
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                activityName: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                code: {
                    type: 'string',
                    displayName: '<<hdzbm>>',
                },
                activityStartTime: {
                    type: 'string',
                    displayName: '<<acitivityBeginTime>>',
                },
                activityEndTime: {
                    type: 'string',
                    displayName: '<<acitivityEndTime>>',
                },
                goodsName: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                },
                purchaseTime: {
                    type: 'string',
                    displayName: '<<cqsj>>',
                },
                addRegisterCount: {
                    type: 'string',
                    displayName: '<<bmrs>>',
                },
                prizeCount: {
                    type: 'string',
                    displayName: '<<fhzqtjrs>>',
                },
                maxPrizeCount: {
                    type: 'string',
                    displayName: '<<maxPriceCount>>',
                },
                quantity: {
                    type: 'string',
                    displayName: '<<cctfsl>>',
                },
            },
        },
        maotaiPresaleLimitRuleEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
            apiPath: '/loader/admin/maotaiPresale/limitRule',
            properties: {
                singleLimitSubsite: {
                    type: 'array.optionIds.checkbox.tip',
                    displayName: '<<bmxz>>',
                    options: [
                        {
                            id: 'true',
                            name: '<<maotaiPresaleAct>>',
                        },
                    ],
                },
                singlePrizeCondition: {
                    type: 'array.maotaiPresalePrizeConditions',
                    displayName: '<<sgxztj>>',
                },
                singlePrizeQualityLimit: {
                    type: 'array.maotaiPresaleQuantityRule',
                    displayName: '<<sgslxz>>',
                },
                singleDescription: {
                    type: 'string.richTextPlus',
                    displayName: '<<hdsm>>',
                    rules: [{ max: 20000, message: '<<notMoreThan2000>>' }],
                    controlConfig: {
                        placeholder: '<<descriptionRule>>',
                        toolbarId: 'descriptionRichText',
                        style: {
                            height: 300,
                        },
                        uploadConfig: {
                            apiPath: `/admin/images/upload`,
                            fileName: 'fileName',
                        },
                    },
                    extra: '<<descriptionExtra>>',
                },
                multipleLimitSubsite: {
                    type: 'array.optionIds.checkbox.tip',
                    displayName: '<<bmxz>>',
                    options: [
                        {
                            id: 'true',
                            name: '<<mgyhznzygmdxbmcg>>',
                        },
                    ],
                },
                multiplePrizeCondition: {
                    type: 'array.maotaiPresalePrizeConditions',
                    displayName: '<<sgxztj>>',
                },
                multiplePrizeQualityLimit: {
                    type: 'array.maotaiPresaleQuantityRule',
                    displayName: '<<sgslxz>>',
                },
                multipleDescription: {
                    type: 'string.richTextPlus',
                    displayName: '<<hdsm>>',
                    rules: [{ max: 20000, message: '<<notMoreThan2000>>' }],
                    controlConfig: {
                        placeholder: '<<descriptionRule>>',
                        toolbarId: 'descriptionRichText',
                        style: {
                            height: 300,
                        },
                        uploadConfig: {
                            apiPath: `/admin/images/upload`,
                            fileName: 'fileName',
                        },
                    },
                    extra: '<<descriptionExtra>>',
                },
                otherPrizeCondition: {
                    type: 'array.maotaiPresalePrizeConditions',
                    displayName: '<<sgxztj>>',
                },
                otherPrizeQualityLimit: {
                    type: 'array.maotaiPresaleQuantityRule',
                    displayName: '<<sgslxz>>',
                },
                otherDescription: {
                    type: 'string.richTextPlus',
                    displayName: '<<hdsm>>',
                    rules: [{ max: 20000, message: '<<notMoreThan2000>>' }],
                    controlConfig: {
                        placeholder: '<<descriptionRule>>e>>e>>e>>',
                        toolbarId: 'descriptionRichText',
                        style: {
                            height: 300,
                        },
                        uploadConfig: {
                            apiPath: `/admin/images/upload`,
                            fileName: 'fileName',
                        },
                    },
                    extra: '<<descriptionExtra>>',
                },
            },
        },
        maitaoWhitelistManagerListEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
            apiPath: '/admin/presale/listing/:id/WHITE',
            filters: {
                mobile: {
                    type: 'string.phone',
                    displayName: '<<phoneNum>>',
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        style: { width: 240 },
                    },
                },
            },
            properties: {
                mobile: {
                    type: 'string.phone',
                    displayName: '<<phoneNum>>',
                },
                subsiteCode: {
                    type: 'string',
                    displayName: '<<subsiteCode>>',
                },
                subsiteName: { type: 'string', displayName: '<<subsiteName>>' },
                createTime: {
                    type: 'string.date',
                    displayName: '<<drsj_1>>',
                },
            },
        },
        maitaoRegistrationWhitelistManagerListEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
            apiPath: '/admin/presale/listing/:id/REGISTRATION',
            filters: {
                mobile: {
                    type: 'string.phone',
                    displayName: '<<phoneNum>>',
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        style: { width: 240 },
                    },
                },
            },
            properties: {
                mobile: {
                    type: 'string.phone',
                    displayName: '<<phoneNum>>',
                },
                subsiteCode: {
                    type: 'string',
                    displayName: '<<subsiteCode>>',
                },
                subsiteName: { type: 'string', displayName: '<<subsiteName>>' },
                createTime: {
                    type: 'string.date',
                    displayName: '<<drsj_1>>',
                },
            },
        },
        maotaiBlacklistManagerListEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
            // apiPath: '/admin/presale/listing/:id/BLACK',
            apiPath: '/loader/admin/maotaiBlacklistManagerList',
            filters: {
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                idNumber: {
                    type: 'string',
                    displayName: '<<sfzh>>',
                },
                cid: {
                    type: 'string',
                    displayName: 'CID',
                },
            },
            properties: {
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                idNumber: {
                    type: 'string',
                    displayName: '<<sfzh>>',
                },
                cid: {
                    type: 'string',
                    displayName: 'CID',
                },
                createTime: {
                    type: 'string.date',
                    displayName: '<<drsj_1>>',
                },
            },
        },

        maotaiWhitelistManagerRecordEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
            apiPath: '/loader/admin/maotaiWhitelistManagerRecord',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<czsj>>',
                },
            },
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
                time: {
                    type: 'string.date',
                    displayName: '<<czsj>>',
                },
                importType: {
                    type: 'string',
                    displayName: '<<drfs>>',
                },
                optResult: {
                    type: 'string.options.select',
                    displayName: '<<czjg>>',
                    options: OPERATION_RESULTS_OPYIONS,
                },
                importResult: {
                    type: 'string',
                    displayName: '<<importResult>>',
                },
            },
        },
        maotaiRegistrationWhitelistManagerRecordEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
            apiPath: '/loader/admin/maotaiRegistrationWhitelistManagerRecord',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<czsj>>',
                },
            },
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
                time: {
                    type: 'string.date',
                    displayName: '<<czsj>>',
                },
                importType: {
                    type: 'string',
                    displayName: '<<drfs>>',
                },
                optResult: {
                    type: 'string.options.select',
                    displayName: '<<czjg>>',
                    options: OPERATION_RESULTS_OPYIONS,
                },
                importResult: {
                    type: 'string',
                    displayName: '<<importResult>>',
                },
            },
        },

        maotaiBlacklistManagerRecordEntity: {
            // apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
            // apiPath: '/loader/admin/blacklistManagerRecord',
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
            apiPath: '/loader/admin/maotaiBlacklistManagerRecord',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<czsj>>',
                },
            },
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
                time: {
                    type: 'string.date',
                    displayName: '<<czsj>>',
                },
                importType: {
                    type: 'string',
                    displayName: '<<drfs>>',
                },
                optResult: {
                    type: 'string.options.select',
                    displayName: '<<czjg>>',
                    options: OPERATION_RESULTS_OPYIONS,
                },
                importResult: {
                    type: 'string',
                    displayName: '<<importResult>>',
                },
            },
        },
    },
    components: {
        maotaiPresaleView: {
            component: 'Viewport',
            entity: 'maotaiPresaleEntity',
        },
        maotaiPresalePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'maotaiPresaleFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            style: {
                                display: 'flex',
                            },
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    route: '/maotai-presale/add',
                                },
                                {
                                    component: 'MaotaiPreSaleExtendImportButton',
                                },
                                {
                                    component: 'MaotaiPresaleLimitRuleButton',
                                },
                                {
                                    component: 'MaotaiRemark',
                                },
                                {
                                    component: 'Button',
                                    text: '<<yczqjg>>',
                                    type: 'primary',
                                    route: '/maotai-presale/forecast',
                                    style: {
                                        marginLeft: 10,
                                    },
                                },
                            ],
                        },
                    ],
                },
                { component: 'maotaiPresaleTable' },
            ],
        },
        MaotaiPresaleLimitRuleButton: {
            component: 'MaotaiPresaleLimitRule',
            entity: 'maotaiPresaleLimitRuleEntity',
        },
        maotaiPresaleForecastPage: {
            component: 'FlexLayout',
            entity: 'maotaiPresaleForecastEntity',
            direction: 'vertical',
            items: [
                {
                    component: 'maotaiPresaleForecastFilter',
                },
                {
                    component: 'Text',
                    text: ' <<maotaiPresaleForecastPage>>',
                    style: {
                        marginTop: 15,
                    },
                },
                { component: 'maotaiPresaleForecastTable' },
            ],
        },
        maotaiPresaleForecastFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'subsiteId',
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'code',
                },
            ],
        },
        maotaiPresaleForecastTable: {
            component: 'DataTable',
            loadFirstPage: true,
            scroll: {
                x: 1300,
            },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'subsiteName',
                    width: 150,
                },
                {
                    property: 'activityName',
                    width: 150,
                },
                {
                    property: 'code',
                    width: 150,
                },
                {
                    property: 'activityStartTime',
                    width: 150,
                },
                {
                    property: 'activityEndTime',
                    width: 150,
                },
                {
                    property: 'goodsName',
                    width: 100,
                },
                {
                    property: 'purchaseTime',
                    width: 150,
                },
                {
                    property: 'addRegisterCount',
                    width: 150,
                },
                {
                    property: 'prizeCount',
                    width: 150,
                },
                {
                    property: 'maxPrizeCount',
                    width: 150,
                },
                {
                    property: 'quantity',
                    width: 150,
                },
            ],
        },

        maotaiPresaleFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'status',
                    controlConfig: {
                        style: {
                            width: 150,
                        },
                    },
                },
                {
                    property: 'subsiteId',
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'mode',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
            ],
        },
        maotaiPresaleTable: {
            component: 'DataTable',
            loadFirstPage: true,
            scroll: {
                x: 1500,
            },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'code',
                    width: 100,
                },
                {
                    property: 'subsites',
                    width: 200,
                },
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'startTime',
                    width: 120,
                },
                {
                    property: 'endTime',
                    width: 120,
                },
                {
                    property: 'status',
                    width: 100,
                },
                {
                    property: 'registrationCount',
                    width: 100,
                },
                {
                    property: 'prizeCount',
                    width: 100,
                },
                {
                    property: 'whitePrizeCount',
                    width: 100,
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                width: 360,
                className: 'column-left action-column',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            statusKey: 'enable',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                                    apiPath: '/admin/presale/:id/disable',
                                    config: {
                                        content: '<<hd>>{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isCloseActivity>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                                    apiPath: '/admin/presale/:id/enable',
                                    config: {
                                        content: '<<hd>>{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>><<hd>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },

                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        // statusKey: 'row.canEdit',
                        path: '/maotai-presale/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copyActivity>>',
                        // statusKey: 'row.canEdit',
                        path: '/maotai-presale/copy/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'CopyLinkButton',
                        params: {
                            content: '<<copyLink>>',
                        },
                    },

                    // {
                    //     type: 'component',
                    //     component: 'MaotaiPresaleImportWhite',
                    // },
                    {
                        text: '<<bmbmdgl>>',
                        type: 'link',
                        path:
                            '/maotai-presale/registration-whitelist-manager/{{row.id}}?name={{row.name}}',
                    },
                    {
                        text: '<<zqbmdgl>>',
                        type: 'link',
                        path: '/maotai-presale/whitelist-manager/{{row.id}}?name={{row.name}}',
                    },
                    {
                        text: '<<hmdgl>>',
                        type: 'link',
                        path: '/maotai-presale/blacklist-manager/{{row.id}}?name={{row.name}}',
                    },
                ],
            },
        },
        maotaiPresaleAddPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'maotaiPresaleEntity',
            items: [{ component: 'MaotaiPresaleAddFormView' }],
        },
        maotaiPresaleEditPage: {
            component: 'FlexLayout',
            entity: 'maotaiPresaleEntity',
            direction: 'vertical',
            loaderType: 'get',
            items: [{ component: 'MaotaiPresaleEditFormView' }],
        },
        maotaiPresaleCopyPage: {
            component: 'FlexLayout',
            entity: 'maotaiPresaleEntity',
            direction: 'vertical',
            loaderType: 'get',
            items: [{ component: 'MaotaiPresaleCopyFormView' }],
        },
        maotaiWhitelistManagerPage: {
            component: 'Tabs',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<zqbmdgl>>',
                    content: {
                        component: 'maotaiWhitelistManagerList',
                    },
                },
                {
                    title: '<<importRecord>>',
                    content: {
                        component: 'maotaiWhitelistManagerRecord',
                    },
                },
            ],
        },
        maotaiRegistrationWhitelistManagerPage: {
            component: 'Tabs',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<bmbmdgl>>',
                    content: {
                        component: 'maotaiRegistrationWhitelistManagerList',
                    },
                },
                {
                    title: '<<importRecord>>',
                    content: {
                        component: 'maotaiRegistrationWhitelistManagerRecord',
                    },
                },
            ],
        },
        maotaiRegistrationWhitelistManagerList: {
            entity: 'maitaoRegistrationWhitelistManagerListEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'maotaiWhitelistManagerListFilter',
                },
                {
                    component: 'maotaiRegistrationWhitelistManagerListHandler',
                },
                {
                    component: 'maotaiWhitelistManagerListTable',
                },
            ],
        },
        maotaiWhitelistManagerList: {
            entity: 'maitaoWhitelistManagerListEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'maotaiWhitelistManagerListFilter',
                },
                {
                    component: 'maotaiWhitelistManagerListHandler',
                },
                {
                    component: 'maotaiWhitelistManagerListTable',
                },
            ],
        },
        maotaiWhitelistManagerListFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'mobile',
                },
                {
                    property: 'subsiteId',
                },
            ],
        },
        maotaiWhitelistManagerListHandler: {
            component: 'GridLayout',
            style: {
                marginBottom: 16,
            },
            items: [
                {
                    component: 'ImportTable',
                    props: {
                        triggerType: 'primary',
                        triggerText: '<<drbmd>>',
                        triggerIcon: 'plus',
                        controlDownloadTemplate: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                            apiPath: `/admin/presale/listing/download_template/WHITE?show_subsite=true`,
                        },
                        customCallback() {
                            Modal.info({
                                content: services.language.getText('qshqwdrjlzck'),
                            });
                        },
                        controlHandles: [
                            {
                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                                apiPath:
                                    '/admin/presale/listing/:id/WHITE/upload?importType=ADD&type=WHITE',
                                params: {
                                    importType: 'ADD',
                                    type: 'WHITE',
                                },
                                type: 'primary',
                                text: '<<xzdr>>',
                                paramsHandler(config: any, fileInfo: any) {
                                    const name = getUrlParam(window.location.hash, 'name');
                                    return {
                                        fileId: fileInfo.id,
                                        fileUrl: fileInfo.path,
                                        name,
                                        id: get(config, 'row.id', get(config, 'params.id')),
                                    };
                                },
                            },
                            {
                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                                apiPath:
                                    '/admin/presale/listing/:id/WHITE/upload?importType=COVER&type=WHITE',
                                params: {
                                    importType: 'COVER',
                                    type: 'WHITE',
                                    name: getUrlParam(window.location.hash, 'name'),
                                },
                                type: 'primary',
                                text: '<<fgdr>>',
                                paramsHandler(config: any, fileInfo: any) {
                                    const name = getUrlParam(window.location.hash, 'name');
                                    return {
                                        fileId: fileInfo.id,
                                        fileUrl: fileInfo.path,
                                        name,
                                        id: get(config, 'row.id', get(config, 'params.id')),
                                    };
                                },
                            },
                        ],
                    },
                    span: 4,
                },
                {
                    component: 'EntityButton',
                    props: {
                        type: 'primary',
                        text: '<<exportSearchResult>>',
                        onClick(_: any, config: any) {
                            const params = Object.assign(
                                {
                                    mobile: config.entity.filters?.mobile,
                                    subsiteId: config.entity.filters?.subsiteId?.id,
                                },
                                config.entity.params
                                // config.entity.filters
                            );
                            services.api
                                .download(params, {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                                    apiPath:
                                        '/admin/presale/listing/:id/WHITE/export?show_subsite=true',
                                })
                                .catch(errorHandle);
                        },
                    },
                    span: 4,
                },
            ],
        },
        maotaiRegistrationWhitelistManagerListHandler: {
            component: 'GridLayout',
            style: {
                marginBottom: 16,
            },
            items: [
                {
                    component: 'ImportTable',
                    props: {
                        triggerType: 'primary',
                        triggerText: '<<drbmd>>',
                        triggerIcon: 'plus',
                        controlDownloadTemplate: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                            apiPath: `/admin/presale/listing/download_template/WHITE?show_subsite=true`,
                        },
                        customCallback() {
                            Modal.info({
                                content: services.language.getText('qshqwdrjlzck'),
                            });
                        },
                        controlHandles: [
                            {
                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                                apiPath:
                                    '/admin/presale/listing/:id/REGISTRATION/upload?importType=ADD&type=REGISTRATION',
                                params: {
                                    importType: 'ADD',
                                    type: 'REGISTRATION',
                                },
                                type: 'primary',
                                text: '<<xzdr>>',
                                paramsHandler(config: any, fileInfo: any) {
                                    const name = getUrlParam(window.location.hash, 'name');
                                    return {
                                        fileId: fileInfo.id,
                                        fileUrl: fileInfo.path,
                                        name,
                                        id: get(config, 'row.id', get(config, 'params.id')),
                                    };
                                },
                            },
                            {
                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                                apiPath:
                                    '/admin/presale/listing/:id/REGISTRATION/upload?importType=COVER&type=REGISTRATION',
                                params: {
                                    importType: 'COVER',
                                    type: 'REGISTRATION',
                                    name: getUrlParam(window.location.hash, 'name'),
                                },
                                type: 'primary',
                                text: '<<fgdr>>',
                                paramsHandler(config: any, fileInfo: any) {
                                    const name = getUrlParam(window.location.hash, 'name');
                                    return {
                                        fileId: fileInfo.id,
                                        fileUrl: fileInfo.path,
                                        name,
                                        id: get(config, 'row.id', get(config, 'params.id')),
                                    };
                                },
                            },
                        ],
                    },
                    span: 4,
                },
                {
                    component: 'EntityButton',
                    props: {
                        type: 'primary',
                        text: '<<exportSearchResult>>',
                        onClick(_: any, config: any) {
                            const params = Object.assign(
                                {
                                    mobile: config.entity.filters?.mobile,
                                    subsiteId: config.entity.filters?.subsiteId?.id,
                                },
                                config.entity.params
                                // config.entity.filters
                            );
                            services.api
                                .download(params, {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                                    apiPath:
                                        '/admin/presale/listing/:id/REGISTRATION/export?show_subsite=true',
                                })
                                .catch(errorHandle);
                        },
                    },
                    span: 4,
                },
            ],
        },
        maotaiWhitelistManagerListTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'mobile',
                },
                {
                    property: 'subsiteCode',
                },
                {
                    property: 'subsiteName',
                },
                {
                    property: 'createTime',
                },
            ],
        },
        maotaiWhitelistManagerRecord: {
            entity: 'maotaiWhitelistManagerRecordEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'maotaiWhitelistManagerRecordFilter',
                },
                {
                    component: 'maotaiWhitelistManagerRecordTable',
                },
            ],
        },
        maotaiRegistrationWhitelistManagerRecord: {
            entity: 'maotaiRegistrationWhitelistManagerRecordEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'maotaiWhitelistManagerRecordFilter',
                },
                {
                    component: 'maotaiRegistrationWhitelistManagerRecordTable',
                },
            ],
        },
        maotaiWhitelistManagerRecordFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsrczr>>',
                    },
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        maotaiWhitelistManagerRecordTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'time',
                },
                {
                    property: 'importType',
                },
                {
                    property: 'optResult',
                },
                {
                    property: 'importResult',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                items: [
                    {
                        type: 'component',
                        component: 'ExportDataButtonFail',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                            apiPath:
                                // '/admin/reserve_list_activity/WHITE/{{row.id}}/failExport?show_subsite=true',
                                '/admin/presale/listing/{{row.id}}/WHITE/record_result/export/FAIL',
                            text: '<<dcsbqd>>',
                        },
                    },
                    {
                        type: 'component',
                        component: 'ExportDataButton',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                            apiPath:
                                // '/admin/reserve_list_activity/WHITE/{{row.id}}/export?show_subsite=true',
                                '/admin/presale/listing/{{row.id}}/WHITE/record_result/export/ALL',
                            text: '<<qbdc>>',
                        },
                    },
                ],
            },
        },
        maotaiRegistrationWhitelistManagerRecordTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'time',
                },
                {
                    property: 'importType',
                },
                {
                    property: 'optResult',
                },
                {
                    property: 'importResult',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                items: [
                    {
                        type: 'component',
                        component: 'ExportDataButtonFail',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                            apiPath:
                                '/admin/reserve_list_activity/REGISTRATION/{{row.id}}/failExport?show_subsite=true',
                            text: '<<dcsbqd>>',
                        },
                    },
                    {
                        type: 'component',
                        component: 'ExportDataButton',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                            apiPath:
                                '/admin/reserve_list_activity/REGISTRATION/{{row.id}}/export?show_subsite=true',
                            text: '<<qbdc>>',
                        },
                    },
                ],
            },
        },

        maotaiBlacklistManagerPage: {
            component: 'Tabs',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<hmdgl>>',
                    content: {
                        component: 'maotaiBlacklistManagerList',
                    },
                },
                {
                    title: '<<importRecord>>',
                    content: {
                        component: 'maotaiBlacklistManagerRecord',
                    },
                },
            ],
        },

        maotaiBlacklistManagerList: {
            entity: 'maotaiBlacklistManagerListEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'maotaiBlacklistManagerListFilter',
                },
                {
                    component: 'maotaiBlacklistManagerListHandler',
                },
                {
                    component: 'maotaiBlacklistManagerListTable',
                },
            ],
        },
        maotaiBlacklistManagerListFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },

            fields: [
                {
                    property: 'mobile',
                },
                {
                    property: 'idNumber',
                },
                {
                    property: 'cid',
                },
            ],
        },
        maotaiBlacklistManagerListHandler: {
            component: 'GridLayout',
            className: 'mt24 mb24',
            items: [
                {
                    component: 'ImportTable',
                    props: {
                        triggerType: 'primary',
                        triggerText: '<<drhmd>>',
                        triggerIcon: 'plus',
                        controlDownloadTemplate: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                            // apiPath: `/admin/reserve_draw_lots_activities/template.xlsx`,
                            apiPath: `/admin/presale/listing/download_template/BLACK`,
                        },
                        customCallback() {
                            Modal.info({
                                content: services.language.getText('qshqwdrjlzck'),
                            });
                        },
                        controlHandles: [
                            {
                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                                apiPath:
                                    // '/admin/reserve_draw_lots_activities/:id/upload?importType=ADD&type=BLACK',
                                    '/admin/presale/listing/:id/BLACK/upload?importType=ADD&type=BLACK',
                                params: {
                                    importType: 'ADD',
                                    type: 'BLACK',
                                },
                                type: 'primary',
                                text: '<<xzdr>>',
                                paramsHandler(config: any, fileInfo: any) {
                                    const name = getUrlParam(window.location.hash, 'name');

                                    return {
                                        fileId: fileInfo.id,
                                        fileUrl: fileInfo.path,
                                        name,
                                        id: get(config, 'row.id', get(config, 'params.id')),
                                    };
                                },
                            },
                            {
                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                                apiPath:
                                    // '/admin/reserve_draw_lots_activities/:id/upload?importType=COVER&type=BLACK',
                                    '/admin/presale/listing/:id/BLACK/upload?importType=COVER&type=BLACK',
                                params: {
                                    importType: 'COVER',
                                    type: 'BLACK',
                                },
                                type: 'primary',
                                text: '<<fgdr>>',
                                paramsHandler(config: any, fileInfo: any) {
                                    const name = getUrlParam(window.location.hash, 'name');
                                    return {
                                        fileId: fileInfo.id,
                                        fileUrl: fileInfo.path,
                                        name,
                                        id: get(config, 'row.id', get(config, 'params.id')),
                                    };
                                },
                            },
                        ],
                    },
                    span: 3,
                },
                {
                    component: 'EntityButton',
                    props: {
                        type: 'primary',
                        text: '<<exportSearchResult>>',
                        onClick(_: any, config: any) {
                            const params = Object.assign(
                                {},
                                config.entity.params,
                                config.entity.filters
                            );
                            services.api
                                .download(params, {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                                    // apiPath: '/admin/reserve_draw_lots_activities/:id/BLACK/export',
                                    apiPath: '/admin/presale/listing/:id/BLACK/export',
                                })
                                .catch(errorHandle);
                        },
                    },
                    span: 3,
                },
            ],
        },
        maotaiBlacklistManagerListTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'mobile',
                },
                {
                    property: 'idNumber',
                },
                {
                    property: 'cid',
                },
                {
                    property: 'createTime',
                },
            ],
        },
        maotaiBlacklistManagerRecord: {
            entity: 'maotaiBlacklistManagerRecordEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'maotaiBlacklistManagerRecordFilter',
                },
                {
                    component: 'maotaiBlacklistManagerRecordTable',
                },
            ],
        },
        maotaiBlacklistManagerRecordFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsrczr>>',
                    },
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        maotaiBlacklistManagerRecordTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'time',
                },
                {
                    property: 'importType',
                },
                {
                    property: 'optResult',
                },
                {
                    property: 'importResult',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                items: [
                    {
                        type: 'component',
                        component: 'ExportDataButtonFail',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                            // apiPath: '/admin/reserve_list_activity/BLACK/{{row.id}}/failExport',
                            apiPath:
                                '/admin/presale/listing/{{row.id}}/BLACK/record_result/export/FAIL',
                            text: '<<dcsbqd>>',
                        },
                    },
                    {
                        type: 'component',
                        component: 'ExportDataButton',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                            // apiPath: '/admin/reserve_list_activity/BLACK/{{row.id}}/export',
                            apiPath:
                                '/admin/presale/listing/{{row.id}}/BLACK/record_result/export/ALL',
                            text: '<<qbdc>>',
                        },
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/maotai-presale',
                    component: 'maotaiPresaleView',
                    breadcrumbName: '<<maotaiPresale>>',
                    privilege: {
                        path: 'maoTaiPresale',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'maotaiPresaleAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'maotaiPresaleEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/copy/:id',
                            component: 'maotaiPresaleCopyPage',
                            breadcrumbName: '<<copyActivity>>',
                        },
                        {
                            path: '/registration-whitelist-manager/:id',
                            component: 'maotaiRegistrationWhitelistManagerPage',
                        },
                        {
                            path: '/whitelist-manager/:id',
                            component: 'maotaiWhitelistManagerPage',
                        },
                        {
                            path: '/blacklist-manager/:id',
                            component: 'maotaiBlacklistManagerPage',
                        },
                        {
                            path: '/forecast',
                            component: 'maotaiPresaleForecastPage',
                        },
                        { path: '/', component: 'maotaiPresalePage' },
                    ],
                },
            ],
        },
    ],
};
