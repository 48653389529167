import React, { Component } from 'react';
import { InputNumber, Button } from 'antd';
import { cloneDeep, isEqual, map } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { Coupon, CollectionPointsRewardCoupons } from './collection-points-reward-coupons';
import './index.less';

export interface CollectionPointsRewardValue {
    consumeValue?: number;
    coupons: Array<Coupon>;
}

interface Props {
    name: string;
    value: Array<CollectionPointsRewardValue>;
    maxCount: number;
    onChange: (value: Array<CollectionPointsRewardValue>) => void;
    options?: { code: string; name: string }[];
}

const defalutValue: Array<CollectionPointsRewardValue> = [
    {
        consumeValue: undefined,
        coupons: [],
    },
];

export class CollectionPointsReward extends Component<Props> {
    static defaultProps = {
        maxCount: 3,
        disabled: false,
    };
    componentDidMount() {
        let { value, onChange } = this.props;
        if (window.location.href.includes('/add') && !value) {
            if (onChange) {
                onChange(defalutValue);
            }
        }
    }

    componentWillReceiveProps(nextProps: Props) {
        const currentSubsiteIds = this.getSubsiteIds(this.props);
        const nextSubsiteIds = this.getSubsiteIds(nextProps);
        if (!isEqual(nextSubsiteIds, currentSubsiteIds) && currentSubsiteIds) {
            this.onChangeValue();
        }
    }

    getSubsiteIds = (props: any) => {
        const { row } = props;
        const subsiteIds =
            row.baseInfo && row.baseInfo.subsites && row.baseInfo.subsites.length > 0
                ? map(row.baseInfo.subsites, 'id')
                : null;
        return subsiteIds;
    };

    onChangeValue = () => {
        const { onChange } = this.props;
        onChange(defalutValue);
    };

    onChangeCoupon = (coupons: Array<Coupon>, index: number) => {
        const { value, onChange } = this.props;
        const newValue = cloneDeep(value);
        newValue[index].coupons = coupons;
        if (onChange) {
            onChange(newValue);
        }
    };

    renderCoupon = ({
        index,
        couponsValue,
        subsiteIds,
    }: {
        index: number;
        couponsValue: any;
        subsiteIds: any;
    }) => {
        return (
            <div className="reward-coupons">
                <CollectionPointsRewardCoupons
                    index={index}
                    onChange={this.onChangeCoupon}
                    value={couponsValue}
                    subsiteIds={subsiteIds}
                />
            </div>
        );
    };

    changeRewardItemValue = (rewardIndex: number, inputValue: number, type: 'consumeValue') => {
        const { value, onChange } = this.props;
        const newValue = cloneDeep(value);
        newValue[rewardIndex][type] = inputValue;
        onChange(newValue);
    };

    renderReward(reward: CollectionPointsRewardValue, index: number) {
        const subsiteIds = this.getSubsiteIds(this.props);
        const couponsValue: any = reward.coupons && reward.coupons.length > 0 ? reward.coupons : [];
        return (
            <div className="reward" key={index}>
                <div className="reward-title">
                    <div>
                        {services.language.getText('jt')}
                        {index + 1}:
                    </div>
                </div>
                <div className="reward-delete">
                    <Button
                        type="default"
                        className="reward-button"
                        onClick={this.onDeleteReward.bind(this, index)}
                    >
                        {services.language.getText('common.delete')}
                    </Button>
                </div>
                <div className="reward-content">
                    <div className="reward-content-item">
                        <div className="reward-content-item-title">
                            <span className="red-tip">*</span>&nbsp;
                            {services.language.getText('dhgz')}:
                        </div>
                        <div>
                            {services.language.getText('jm')}&nbsp;&nbsp;
                            <InputNumber
                                value={reward.consumeValue}
                                min={0}
                                max={999999999}
                                precision={0}
                                onChange={(value: any) =>
                                    this.changeRewardItemValue(index, value, 'consumeValue')
                                }
                                style={{ width: '125px' }}
                            ></InputNumber>
                            &nbsp;&nbsp;{services.language.getText('jdkduyhq')}
                        </div>
                    </div>
                    <div className="reward-content-item">
                        <div className="reward-content-item-title" style={{ marginLeft: '-15px' }}>
                            <span className="red-tip">*</span>&nbsp;
                            {services.language.getText('dhyhq')}：
                        </div>
                        <div className="reward-content-list">
                            <div style={{ marginBottom: 10 }}>
                                {this.renderCoupon({
                                    index,
                                    couponsValue,
                                    subsiteIds,
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    addReward = () => {
        const { value, onChange } = this.props;
        const newValue = cloneDeep(value);
        newValue.push({
            consumeValue: undefined,
            coupons: [],
        });
        onChange(newValue);
    };

    onDeleteReward = (index: number) => {
        const { value, onChange } = this.props;
        const newValue = cloneDeep(value);
        newValue.splice(index, 1);
        onChange(newValue);
    };

    render() {
        let { value } = this.props;
        if (!value) {
            return null;
        }
        return (
            <div>
                <div>
                    <span style={{ color: '#ccc' }}>
                        &nbsp;&nbsp;{services.language.getText('zdzcsgjt')}
                    </span>
                </div>
                <div className="collection-points-reward">
                    {map(value, (reward, index) => this.renderReward(reward, index))}
                    {value && value.length < 4 && (
                        <div>
                            <Button
                                type="default"
                                className="reward-button"
                                onClick={this.addReward}
                            >
                                {services.language.getText('zjjt')}
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        );
    }
}
