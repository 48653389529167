import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { services } from '@comall-backend-builder/core';
import { isEmpty } from 'lodash';
import { message } from 'antd';

const api = services.api;

export const CouponManageLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        config.apiPath = ' /admin/coupons/all';
        /*  if (!isEmpty(data.bindingDateRange)) {
            data.bindingStartTime = data.bindingDateRange.start + ' 00:00:00';
            data.bindingEndTime = data.bindingDateRange.end + ' 23:59:59';
            delete data.bindingDateRange;
        }
        if (data.couponStatus === 'ALL') {
            delete data.couponStatus;
        }
        if (data.type === 'ALL') {
            delete data.type;
        } */
        if (!isEmpty(data.mobile) || !isEmpty(data.couponNo) || !isEmpty(data.name)) {
            const datas = await api.get(data, config);
            return datas;
        }
        message.warning('请输入搜索条件查询后再进行操作！');
        return {
            page: 1,
            perPage: 10,
            result: [],
            totalNum: 0,
            totalPage: 0,
        };
    },
};
