import React, { Component } from 'react';
import { Radio } from 'antd';
import { services } from '@comall-backend-builder/core';
import { ImageUpload } from '@comall-backend-builder/components-basis';
import { cloneDeep } from 'lodash';
import './index.less';

interface Props {
    onChange: (value: CollectionPointsPictureValue) => void;
    value: CollectionPointsPictureValue;
}

interface Picture {
    id: number;
    path: string;
}

const enum Type {
    /**
     * 默认
     */
    DEFALULT = 'DEFALULT',
    /**
     * 自定义
     */
    CUSTOM = 'CUSTOM',
}

//默认集点图标集合--需求多个，最后设计只有一个，后续添加
const defalutCollectedPictures = [
    {
        id: 0,
        path:
            'https://b-puzhehei-cdn.co-mall.net/global/market/collection-point/defalut-collected-pic.png',
    },
];
//默认未集点图标集合
const defalutUnCollectedPictures = [
    {
        id: 0,
        path:
            'https://b-puzhehei-cdn.co-mall.net/global/market/collection-point/defalut-un-collected-pic.png',
    },
];

interface CollectionPointsPictureValue {
    type: Type;
    defalutCollectedPicture?: Picture;
    defalutUnCollectedPicture?: Picture;
    customCollectedPicture?: Picture;
    customUnCollectedPicture?: Picture;
}

export class CollectionPointsPicture extends Component<Props, unknown> {
    componentDidMount() {
        const { value, onChange } = this.props;
        if (!value) {
            const defalutValue = {
                type: Type.DEFALULT,
                defalutCollectedPicture: defalutCollectedPictures[0],
                defalutUnCollectedPicture: defalutUnCollectedPictures[0],
                customCollectedPicture: undefined,
                customUnCollectedPicture: undefined,
            };
            onChange(defalutValue);
        }
    }

    changeType = (e: any) => {
        const { value, onChange } = this.props;
        const type = e.target.value;
        const newValue = cloneDeep(value);
        newValue.type = type;
        if (type === Type.DEFALULT) {
            newValue.defalutCollectedPicture = defalutCollectedPictures[0];
            newValue.defalutUnCollectedPicture = defalutUnCollectedPictures[0];
        } else if (type === Type.CUSTOM) {
            newValue.defalutCollectedPicture = undefined;
            newValue.defalutUnCollectedPicture = undefined;
        }
        onChange(newValue);
    };

    renderPicture = (pictureName: string) => {
        const { value } = this.props;
        let path = undefined;
        if (pictureName === 'customCollectedPicture' && value.customCollectedPicture) {
            path = value.customCollectedPicture.path;
        } else if (pictureName === 'customUnCollectedPicture' && value.customUnCollectedPicture) {
            path = value.customUnCollectedPicture.path;
        }
        const imageControlInfo = {
            name: 'picture',
            value: path,
            accept: 'image/*',
            maxCount: 1,
            multiple: false,
            fileName: 'fileName',
            uploadType: 'single',
            uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
            mode: 'picture-card',
            maxSize: 2 * 1024,
            preview: {
                size: 500,
            },
            uploadLimitSizeErrorMessage:
                services.language.getText('pictures') +
                '{{file.name}}' +
                services.language.getText('pictureUploadLimit1'),
            limitTips: {
                style: {
                    display: 'none',
                },
            },
        };
        const onChangePicture = (value: any) => {
            this.onPictrueChange(value, pictureName);
            return {};
        };
        return (
            <div className="picture-list">
                <ImageUpload {...imageControlInfo} onChange={onChangePicture} />
                <div className="picture-list-tip">
                    {services.language.getText('collectionPointPicSize')}
                </div>
            </div>
        );
    };

    onPictrueChange = (picture: any, pictureName: string) => {
        const { value, onChange } = this.props;
        const newValue = cloneDeep(value);
        if (pictureName === 'customCollectedPicture') {
            newValue.customCollectedPicture =
                picture && picture.length > 0 ? picture[0] : undefined;
        } else if (pictureName === 'customUnCollectedPicture') {
            newValue.customUnCollectedPicture =
                picture && picture.length > 0 ? picture[0] : undefined;
        }
        onChange(newValue);
        return {};
    };

    changeDefalutPicture = (index: number) => {
        const { value, onChange } = this.props;
        const newValue = cloneDeep(value);
        newValue.defalutCollectedPicture = defalutCollectedPictures[index];
        newValue.defalutUnCollectedPicture = defalutUnCollectedPictures[index];
        onChange(newValue);
    };

    render() {
        const { value } = this.props;
        if (!value) {
            return null;
        }
        return (
            <div>
                <div className="collection-points-picture">
                    <Radio.Group onChange={this.changeType} value={value.type}>
                        <Radio value={Type.DEFALULT}>
                            {services.language.getText('mrtb')}&nbsp;&nbsp;
                        </Radio>
                        <div className="picture-list">
                            {defalutCollectedPictures.map((pic, index) => {
                                const style = {
                                    border:
                                        pic.path === value.defalutCollectedPicture?.path
                                            ? '1px solid #1890FF'
                                            : undefined,
                                };
                                return (
                                    <img
                                        style={style}
                                        className="picture-item"
                                        alt=""
                                        src={pic.path}
                                        onClick={this.changeDefalutPicture.bind(this, index)}
                                    />
                                );
                            })}
                        </div>
                        <Radio value={Type.CUSTOM}>
                            {services.language.getText('zdytb')}&nbsp;&nbsp;
                        </Radio>
                        {value.type === Type.CUSTOM && this.renderPicture('customCollectedPicture')}
                    </Radio.Group>
                </div>
                <div style={{ display: 'flex' }}>
                    <div className="collection-points-picture-title">
                        <span className="red-tip">*</span>&nbsp;{services.language.getText('wjdtb')}
                        ：
                    </div>
                    <div className="collection-points-picture">
                        <Radio.Group disabled value={value.type}>
                            <Radio value={Type.DEFALULT}>
                                {services.language.getText('mrtb')}&nbsp;&nbsp;
                            </Radio>
                            <div className="picture-list">
                                {defalutUnCollectedPictures.map((pic) => {
                                    const style = {
                                        border:
                                            pic.path === value.defalutUnCollectedPicture?.path
                                                ? '1px solid #1890FF'
                                                : undefined,
                                    };
                                    return (
                                        <img
                                            style={style}
                                            className="picture-item"
                                            alt=""
                                            src={pic.path}
                                        />
                                    );
                                })}
                            </div>
                            <Radio value={Type.CUSTOM}>
                                {services.language.getText('zdytb')}&nbsp;&nbsp;
                            </Radio>
                            {value.type === Type.CUSTOM &&
                                this.renderPicture('customUnCollectedPicture')}
                        </Radio.Group>
                    </div>
                </div>
            </div>
        );
    }
}
