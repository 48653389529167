import React, { PureComponent, createElement } from 'react';
import builder, { actions } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { language } from '@comall-backend-builder/core/lib/services';

import { debounce, defaults, get, mapValues } from 'lodash';
import './index.less';
export interface RecruitDistributorFormProps extends FormComponentProps {
    id: string;
    show: string;
    entity: Entity;
    scope: string;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
}

class RecruitDistributorForm extends PureComponent<RecruitDistributorFormProps> {
    static defaultProps: any = {};
    componentWillUnmount() {
        this.props.unmountComponent();
    }
    renderModal = () => {
        const { entity, onSubmit, wrappedComponentRef, id = '', show = 'show' } = this.props;
        let fields = [
            { property: 'id', className: 'hidden' },
            { property: 'recruitDistributor.backgroundImage' },
            {
                property: 'recruitDistributor.show',
                controlConfig: {
                    onChange: (value: any, name: string) => {
                        const store = builder.getStore();

                        store.dispatch(
                            actions.formChangeAction(
                                'DataFormPlus-RecruitDistributorFormContainer',
                                name,
                                value
                            )
                        );
                    },
                },
            },
            { property: 'recruitDistributor.title', className: show === 'hidden' ? 'hidden' : '' },
            {
                property: 'recruitDistributor.image',
                className: show === 'hidden' ? 'hidden' : '',
            },
            {
                property: 'share.title',
            },
            {
                property: 'share.description',
            },
            {
                property: 'share.pic',
            },
            {
                property: 'share.pictureExample',
                displayConfig: {
                    showDownload: false,
                },
            },
        ];

        let formProps = {
            entity,
            componentId: 'RecruitDistributorFormContainer',
            wrappedComponentRef,
            onSubmit,
            direction: 'horizontal',
            style: { width: window.innerWidth < 1600 ? 660 : 860 },
            labelCol: 6,
            controlCol: 18,
            fields: fields,
            submit: true,
            onSubmitSuccess: () => {
                AntMessage.success(language.getText('common.saveSuccess'), () => {
                    !id && window.location.reload();
                });
            },
        };
        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    };

    render() {
        return <div className="recruit-distributor-review-agree-form">{this.renderModal()}</div>;
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;

    let id = get(_dispatch, 'components.DataFormPlus-RecruitDistributorFormContainer.fields.id');
    let show = get(
        _dispatch,
        'components.DataFormPlus-RecruitDistributorFormContainer.fields.recruitDistributor.show'
    );
    let form: any;
    return defaults(
        {
            onSubmit,
            onFieldChange,
            id,
            show,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: debounce((event: any, fields: any) => {
                const entityFields = mapValues(fields, (field, name) => {
                    return field.value;
                });
                if (entityFields) {
                    entity.modify(entityFields, params);
                }
                return false;
            }, 300),
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('RecruitDistributorFormContainer'));
            },
        }
    );
}

export const RecruitDistributorFormView = connect(mapDispatchToProps)(RecruitDistributorForm);
