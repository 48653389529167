export interface WechatMpnewsarticle {
    articleId: string;
    thumbUrl: string;
    title: string;
    updateTime: string;
    url: string;
    digest: string;
}

export interface WechatText {
    content: string;
    targetList?: Array<WechatTextTargetVo>;
}

export interface WechatTextTargetVo {
    href: string;
    target?: Object;
}

export interface WechatImage {
    mediaId: string;
    title: string;
    updateTime: string;
    url: string;
}

export interface WechatMiniprogrampage {
    title: string;
    appid: string;
    pagepath: string;
    thumbMediaId?: string;
    thumbMediaInfo?: WechatImage;
    target: {
        linkType: string;
        linkParams: Object;
        linkName: string;
        linkPath: string;
        subsiteId?: number;
        subsiteName?: string;
    };
}

export enum WechatMsgType {
    /**
     * 文本
     */
    TEXT = 'text',
    /**
     * 图片
     */
    IMAGE = 'image',
    /**
     * 小程序卡片
     */
    MINIPROGRAMPAGE = 'miniprogrampage',
    /**
     * 图文
     */
    MPNEWSARTICLE = 'mpnewsarticle',
}

export interface WechatMsg {
    /**
     * 回复消息类型
     */
    msgtype: WechatMsgType;
    /**
     * 文本传参
     */
    text?: WechatText;
    /**
     * 图片传惨
     */
    image?: WechatImage;
    /**
     * 小程序卡片传参
     */
    miniprogrampage?: WechatMiniprogrampage;
    /**
     * 图文传参
     */
    mpnewsarticle?: WechatMpnewsarticle;
}

export enum CrowdScopeEnum {
    ALL = 'ALL',
    MEMBER = 'MEMBER',
    NON_MEMBER = 'NON_MEMBER',
}

export interface AutoReplyVo {
    hide: boolean;
    crowdScope: CrowdScopeEnum | string;
    messageContent: Array<WechatMsg>;
}

export interface SubMenuChidrenVo {
    name: string;
    menuContent: MenuContent;
}
export interface SubMenuVo {
    name: string;
    menuContent: MenuContent;
    subMenus: Array<SubMenuChidrenVo>;
}

export interface MenuContent {
    type: MenuContentType;
    /**
     * 网页，跳转小程序传参(小程序微信url和pagePath都不为空，值一致)
     */
    url?: string;
    /**
     * 跳转小程序传参
     */
    appid?: string;
    pagepath?: string;
    target?: any;
    /**
     * 回复内容
     */
    replyContents?: Array<AutoReplyVo>;
    subsiteId?: number;
}

export enum MenuContentType {
    /**
     * 跳转小程序
     */
    MINIPROGRAM = 'miniprogram',
    /**
     * 跳转网页
     */
    VIEW = 'view',
    /**
     * 回复消息
     */
    CLICK = 'click',
}
