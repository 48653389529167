import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { get } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

export enum ServiceWifiStatus {
    ENABLE = 'ENABLED',
    DISABLE = 'DISABLED',
}

export const config: Config = {
    entities: {
        ServiceWifiEntity: {
            apiPath: '/loader/admin/wifi',
            properties: {
                id: { type: 'string', displayName: 'ID' },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                count: {
                    type: 'string',
                    displayName: '<<sbsl>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: ServiceWifiStatus.ENABLE, name: '<<beforeOpen>>' },
                        { id: ServiceWifiStatus.DISABLE, name: '<<beforeClose>>' },
                    ],
                },
                subsite: {
                    type: 'object.option.autoComplete',
                    displayName: '<<activityMerchant>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        style: {
                            width: 400,
                        },
                    },
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        subsite: {
                            type: 'object.option.autoComplete',
                            displayName: '<<activityMerchant>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        wifiConfigs: {
                            type: 'array.wifiEquipment',
                            displayName: '<<wifiDevice>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<zstjyt>>',
                                },
                            ],
                        },
                    },
                },
                otherInfo: {
                    type: 'object.subForm',
                    displayName: '<<tpgg>>',
                    properties: {
                        wifiPictureConfigs: {
                            type: 'array.carparkImageAds',
                            displayName: '<<tpgg>>',
                            controlConfig: {
                                newClient: true,
                                extra: {
                                    tip: '<<wifiPictureConfigExtra>>',
                                    img: './images/avatar/wifi-img.png',
                                    btnText: '<<viewDiagram>>',
                                    triggerType: 'hover',
                                    style: {
                                        width: 200,
                                    },
                                },
                            },
                        },
                    },
                },
            },
            filters: {
                subsiteIds: {
                    type: 'string.options.autoComplete.plus',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 240 },
                        allowClear: true,
                    },
                },
            },
        },
    },
    components: {
        ServiceWifiView: {
            component: 'Viewport',
            entity: 'ServiceWifiEntity',
        },
        ServiceWifiPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [{ component: 'ServiceWifiFilter' }, { component: 'ServiceWifiTable' }],
        },
        ServiceWifiFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteIds',
                },
            ],
        },
        ServiceWifiTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'ServiceWifiDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'count',
                        },
                        {
                            property: 'status',
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: false },
                                            value: ServiceWifiStatus.ENABLE,
                                            apiPath: '/admin/subsite_wifis/:id/disabled',
                                            config: {
                                                content: '<<beforeClose>>',
                                                text: '<<common.close>>',
                                                style: {
                                                    color: 'red',
                                                },
                                            },
                                            confirm: {
                                                text:
                                                    '<<isAreadyClose>>{{row.subsiteName}}门店配置?',
                                            },
                                        },
                                        {
                                            params: { status: true },
                                            value: ServiceWifiStatus.DISABLE,
                                            apiPath: '/admin/subsite_wifis/:id/enabled',
                                            config: {
                                                content: '<<beforeOpen>>',
                                                text: '<<common.opend>>',
                                            },
                                            confirm: {
                                                text:
                                                    '<<isAreadyOpend>>{{row.subsiteName}}门店配置?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                path: '/service-wifi/edit/{{row.id}}',
                            },
                            {
                                type: 'component',
                                component: 'PageNewPreviewCodeButton',
                                config: {
                                    codeTypes: 'all',
                                    codeModalType: 'item',
                                    codePageType: 'connectWifi',
                                    hideAlipay: true,
                                },
                            },
                        ],
                    },
                },
            ],
        },
        ServiceWifiEditPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'ServiceWifiEditPageForm' }],
        },
        ServiceWifiEditPageForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'ServiceWifiEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'baseInfo.subsite',
                    modifiable: false,
                },
                {
                    property: 'baseInfo.wifiConfigs',
                },
                {
                    property: 'otherInfo.wifiPictureConfigs',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: async (entity: any) => {
                const wifiConfigs = get(entity, 'baseInfo.wifiConfigs', []) || [];
                if (wifiConfigs && wifiConfigs.length === 0) {
                    AntMessage.warning(language.getText('zstjyt'));
                    return false;
                } else {
                    if (!wifiConfigs.every((item: any) => Boolean(item.name))) {
                        AntMessage.warning(language.getText('ssidNotEmpty'));
                        return false;
                    }
                    if (wifiConfigs.some((i: any) => i.name && i.name.length > 32)) {
                        AntMessage.warning(language.getText('ssidzd32gzf'));
                        return false;
                    }
                    if (wifiConfigs.some((i: any) => i.password && i.password.length > 16)) {
                        AntMessage.warning(language.getText('mmzd_1'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/service-wifi',
                    component: 'ServiceWifiView',
                    breadcrumbName: '<<wifi>>',
                    privilege: {
                        path: 'wifi',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'ServiceWifiEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        { path: '/', component: 'ServiceWifiPage' },
                    ],
                },
            ],
        },
    ],
};
