import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const SettingReasonLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/reason';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/reason/${id}`;
            data = {};
        }
        return await api.get(data, config).then((result: any) => {
            if (id) {
                result.reasonType = result.reasonType + '';
                result.enable = result.enable + '';
            }
            if (!result.result) {
                result.result = [];
            }
            return result;
        });
    },
};
