import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';

const api = services.api;
export const PaymentsDataPanelLoader: Loader = {
    get: async function(data, config) {
        data.merchantId = data.shopId;
        data.shopId = undefined;
        if (data.dateRange) {
            data.startTime = data.dateRange.start;
            data.endTime = data.dateRange.end;
            data.dateRange = undefined;
        }
        const apiRoot = `${ENV.AUTH_API_ROOT}/DATA-STATISTICS`;

        // 支付数据-概览统计
        const overviewStatisticsPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/orders/overview_statistics',
        });

        // 支付数据-销售统计数据
        const salesStatisticsPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/orders/sales_statistics',
        });

        // 支付订单数排行
        const paidOrderCountPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/orders/paid_order_count',
        });

        // 门店支付金额排行
        const subsitePaidAmountPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/orders/subsite_paid_amount',
        });

        // 门店支付单量排行
        const subsitePaidCountPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/orders/subsite_paid_count',
        });

        // 专柜支付金额占比
        const merchantPaidAmountPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/orders/merchant_paid_amount',
        });

        // 专柜支付单量占比
        const merchantPaidCountPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/orders/merchant_paid_count',
        });

        // 类目支付金额排行
        const categoryPaidAmountPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/orders/category_paid_amount',
        });

        // 类目支付单量排行
        const categoryPaidCountPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/orders/category_paid_count',
        });

        return Promise.all([
            overviewStatisticsPromise,
            salesStatisticsPromise,
            paidOrderCountPromise,
            subsitePaidAmountPromise,
            subsitePaidCountPromise,
            merchantPaidAmountPromise,
            merchantPaidCountPromise,
            categoryPaidAmountPromise,
            categoryPaidCountPromise,
        ]).then((res: any[]) => {
            return {
                overviewStatistics: res[0],
                salesStatistics: res[1],
                paidOrderCount: res[2],
                subsitePaidAmount: res[3],
                subsitePaidCount: res[4],
                merchantPaidAmount: res[5],
                merchantPaidCount: res[6],
                categoryPaidAmount: res[7],
                categoryPaidCount: res[8],
            };
        });
    },
};

export const ProductsDataPanelLoader: Loader = {
    get: async function(data, config) {
        data.merchantId = data.shopId;
        data.shopId = undefined;
        if (data.dateRange) {
            data.startTime = data.dateRange.start;
            data.endTime = data.dateRange.end;
            data.dateRange = undefined;
        }
        const apiRoot = `${ENV.AUTH_API_ROOT}/DATA-STATISTICS`;

        // 商品概览统计
        const overviewStatisticsPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/products/overview_statistics',
        });

        // 在售商品数/商品总销量每日统计
        const dailySalesPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/products/daily_sales',
        });

        // 支付总金额/成功退款金额每日统计
        const dailyPaymentPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/products/daily_payment',
        });

        // 商品销量TOP10
        const salesTopRankingPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/products/sales_top_ranking',
        });

        // 商品支付金额TOP10
        const paymentTopRankingPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/products/payment_top_ranking',
        });

        // 门店商品销量排行
        const subsiteSalesRankingPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/products/subsite_sales_ranking',
        });

        // 门店商品支付金额排行
        const subsitePaymentRankingPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/products/subsite_payment_ranking',
        });

        // 专柜商品销量占比
        const merchantSalesTopRankingPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/products/merchant_sales_top_ranking',
        });

        // 专柜支付金额占比
        const merchantPaymentTopRankingPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/products/merchant_payment_top_ranking',
        });

        // 类目商品销售排行
        const categorySalesRankingPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/products/category_sales_ranking',
        });

        // 类目支付单量排行
        const categoryPaymentRankingPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/products/category_payment_ranking',
        });

        return Promise.all([
            overviewStatisticsPromise,
            dailySalesPromise,
            dailyPaymentPromise,
            salesTopRankingPromise,
            paymentTopRankingPromise,
            subsiteSalesRankingPromise,
            subsitePaymentRankingPromise,
            merchantSalesTopRankingPromise,
            merchantPaymentTopRankingPromise,
            categorySalesRankingPromise,
            categoryPaymentRankingPromise,
        ]).then((res: any[]) => {
            return {
                overviewStatistics: res[0],
                dailySales: res[1],
                dailyPayment: res[2],
                salesTopRanking: res[3],
                paymentTopRanking: res[4],
                subsiteSalesRanking: res[5],
                subsitePaymentRanking: res[6],
                merchantSalesTopRanking: res[7],
                merchantPaymentTopRanking: res[8],
                categorySalesRanking: res[9],
                categoryPaymentRanking: res[10],
            };
        });
    },
};

export const RefundsDataPanelLoader: Loader = {
    get: async function(data, config) {
        data.merchantId = data.shopId;
        data.shopId = undefined;
        if (data.dateRange) {
            data.startTime = data.dateRange.start;
            data.endTime = data.dateRange.end;
            data.dateRange = undefined;
        }
        const apiRoot = `${ENV.AUTH_API_ROOT}/DATA-STATISTICS`;

        // 商品概览统计
        const overviewStatisticsPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/refunds/overview_statistics',
        });

        // 退款金额/成功退款金额每日统计
        const dailyAmountPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/refunds/daily_amount',
        });

        // 退款人数/退款商品数量每日统计
        const dailyMemberProductCountPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/refunds/daily_member_product_count',
        });

        // 退款商品金额TOP10
        const productAmountTopRankingPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/refunds/product_amount_top_ranking',
        });

        // 退款商品数TOP10
        const productCountTopRankingPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/refunds/product_count_top_ranking',
        });

        // 门店退款商品金额排行
        const subsiteAmountRankingPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/refunds/subsite_amount_ranking',
        });

        // 门店退款商品数排行
        const subsiteProductAmountRankingPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/refunds/subsite_product_count_ranking',
        });

        // 专柜退款商品数占比
        const merchantProductCountTopRankingPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/refunds/merchant_product_count_top_ranking',
        });

        // 专柜退款商品金额占比
        const merchantProductAmountTopRankingPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/refunds/merchant_amount_top_ranking',
        });

        // 类目退款商品金额排行
        const categoryAmountRankingPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/refunds/category_amount_ranking',
        });

        // 类目退款商品数排行
        const categoryProductCountRankingPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/refunds/category_product_count_ranking',
        });

        return Promise.all([
            overviewStatisticsPromise,
            dailyAmountPromise,
            dailyMemberProductCountPromise,
            productAmountTopRankingPromise,
            productCountTopRankingPromise,
            subsiteAmountRankingPromise,
            subsiteProductAmountRankingPromise,
            merchantProductCountTopRankingPromise,
            merchantProductAmountTopRankingPromise,
            categoryAmountRankingPromise,
            categoryProductCountRankingPromise,
        ]).then((res: any[]) => {
            return {
                overviewStatistics: res[0],
                dailyAmount: res[1],
                dailyMemberProductCount: res[2],
                productAmountTopRanking: res[3],
                productCountTopRanking: res[4],
                subsiteAmountRanking: res[5],
                subsiteProductAmountRanking: res[6],
                merchantProductCountTopRanking: res[7],
                merchantProductAmountTopRanking: res[8],
                categoryAmountRanking: res[9],
                categoryProductCountRanking: res[10],
            };
        });
    },
};

export const MembersDataPanelLoader: Loader = {
    get: async function(data, config) {
        if (data.dateRange) {
            data.startTime = data.dateRange.start;
            data.endTime = data.dateRange.end;
            data.dateRange = undefined;
        }
        const apiRoot = `${ENV.AUTH_API_ROOT}/DATA-STATISTICS`;

        // 客户数据-概览统计
        const overviewStatisticsPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/members/overview_statistics',
        });

        // 新增客户数据
        const newMemberPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/members/new_member',
        });

        // 累计用户数据
        const totalMemberPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/members/total_member',
        });

        // 门店新增客户数据
        const subsiteNewMemberPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/members/subsite_new_member',
        });

        // 门店累计用户数据
        const subsiteTotalMemberPromise = api.get(data, {
            apiRoot,
            apiPath: '/admin/members/subsite_total_member',
        });

        return Promise.all([
            overviewStatisticsPromise,
            newMemberPromise,
            totalMemberPromise,
            subsiteNewMemberPromise,
            subsiteTotalMemberPromise,
        ]).then((res: any[]) => {
            return {
                overviewStatistics: res[0],
                newMember: res[1],
                totalMember: res[2],
                subsiteNewMember: res[3],
                subsiteTotalMember: res[4],
            };
        });
    },
};

export const MiniprogramCodeDataListLoader: Loader = {
    get: async function(data, config) {
        data.merchantId = data.shopId;
        data.shopId = undefined;
        if (data.dateRange) {
            data.startTime = data.dateRange.start;
            data.endTime = data.dateRange.end;
            data.dateRange = undefined;
        }
        const apiRoot = `${ENV.AUTH_API_ROOT}/DATA-STATISTICS`;

        return await api.get(data, {
            apiRoot,
            apiPath: '/admin/mini_program_code_statistics',
        });
    },
};
