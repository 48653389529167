import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
const api = services.api;

export const PayWithGiftConfigsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/event_market_pageconfigs';
        data.type = 'PAY_AWARD';
        if (data.editSubsiteId) {
            data.subsiteId = data.editSubsiteId;
            config.apiPath = `/admin/event_market_pageconfigs/by_subsite_id`;
        }
        return await api.get(data, config).then((res: any) => {
            if (data.editSubsiteId) {
                const pageConfig = res.pageConfig ? JSON.parse(res.pageConfig) : undefined;
                res.baseInfo = {
                    subsiteId: res.subsiteId + '',
                    backgroundPicture:
                        pageConfig && pageConfig.backgroundPicture
                            ? [
                                  {
                                      id: pageConfig.backgroundPicture.id,
                                      path: pageConfig.backgroundPicture.url,
                                  },
                              ]
                            : undefined,
                };
            } else {
                res.result = res.result.map((item: any) => {
                    return {
                        ...item,
                        id: item.subsiteId,
                    };
                });
            }
            return res;
        });
    },

    put: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = `/admin/event_market_pageconfigs`;
        try {
            const params = transform(data);
            params.subsiteId = data.editSubsiteId;
            if (data.id) {
                params.id = data.id;
            }
            return await api.put(params, config);
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
};

function transform(data: any) {
    const params: any = {};
    params.type = 'PAY_AWARD';
    const pageConfig = {
        backgroundPicture:
            data.baseInfo.backgroundPicture && data.baseInfo.backgroundPicture.length > 0
                ? {
                      id: data.baseInfo.backgroundPicture[0].id,
                      url: data.baseInfo.backgroundPicture[0].path,
                  }
                : undefined,
    };
    params.pageConfig = JSON.stringify(pageConfig);
    return params;
}
