import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const DeliveryLogisticsCompaniesLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`;
        config.apiPath = '/admin/delivery_logistics_companies';
        if (data.id) {
            config.apiPath = '/admin/delivery_logistics_companies/:id';
        }

        return await api.get(data, config).then((res: any) => {
            if (data.id) {
                res.baseInfo = {
                    subsiteId: res.subsite ? res.subsite.id + '' : '',
                    merchantIds: res.merchants
                        ? res.merchants.map((m: any) => ({
                              ...m,
                              merchantId: m.id,
                              merchantName: m.name,
                              merchantType: m.type,
                              merchantStatus: m.status,
                          }))
                        : [],
                };
                res.sender = {
                    ...res.sender,
                    region: {
                        region: res.region,
                        address: res.sender.address,
                    },
                };

                return res;
            } else {
                res.result = res.result.map((item: any) => ({
                    ...item,
                    insuredDescription: item.insured.description,
                    subsiteName: item.subsite.name,
                }));
                return res;
            }
        });
    },
};
