import React, { PureComponent } from 'react';
import { SelectAutoComplete } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core';
import { get, map, forEach, find } from 'lodash';
import { message as AntMessage } from 'antd';

const api = services.api;

enum PageType {
    CashCouponRuleMemberLevel = 'CashCouponRuleMemberLevel',
    GiftCouponRuleMemberLevel = 'GiftCouponRuleMemberLevel',
    PackingCouponRuleMemberLevel = 'PackingCouponRuleMemberLevel',
    FrightCouponRuleMemberLevel = 'FrightCouponRuleMemberLevel',
    LotteryCouponRuleMemberLevel = 'LotteryCouponRuleMemberLevel',
    CouponPackageRuleMemberLevel = 'CouponPackageRuleMemberLevel',
    EfutureCouponRuleMemberLevel = 'EfutureCouponRuleMemberLevel',
    BfplatCouponRuleMemberLevel = 'BfplatCouponRuleMemberLevel',
}
/**
 * 支持添加编辑配置联动和自动匹配的下拉多选
 */
export class ArrayOptionsAutoCompletePropertiesCoupon extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        const dependencesValue = this.getDependencesValue(props);
        this.state = {
            //特殊详情页面
            pageType: props.pageType,
            // 当前的属性名
            name: props.name,
            // 依赖项的值
            dependencesValue: dependencesValue,
            //是否展示需要依赖的提示语
            showNeedDependencesMessage: true,
            //下拉框options值
            options: [],
        };
    }

    getDependencesValue = (props: any) => {
        const { row } = props;
        let dependencesValue = '';
        if (props.pageType === PageType.PackingCouponRuleMemberLevel) {
            dependencesValue = row && get(row, 'productInfo.subsites');
        } else if (
            props.pageType === PageType.FrightCouponRuleMemberLevel ||
            props.pageType === PageType.LotteryCouponRuleMemberLevel ||
            props.pageType === PageType.EfutureCouponRuleMemberLevel ||
            props.pageType === PageType.BfplatCouponRuleMemberLevel
        ) {
            dependencesValue = row && get(row, 'productInfo.subsite');
        } else if (
            props.pageType === PageType.CashCouponRuleMemberLevel ||
            props.pageType === PageType.GiftCouponRuleMemberLevel
        ) {
            const selectMode = row && get(row, 'productInfo.selectMode');
            if (selectMode === 'SUB_SITE') {
                dependencesValue = row && get(row, 'productInfo.subsite');
            }
            if (selectMode === 'MERCHANT') {
                dependencesValue = row && get(row, 'productInfo.shop');
            }
        } else if (props.pageType === PageType.CouponPackageRuleMemberLevel) {
            dependencesValue = row && get(row, 'baseInfo.subsiteIds');
        }
        return dependencesValue;
    };

    componentDidMount() {
        this.loadFirstVisible();
    }

    loadFirstVisible = () => {
        const nextDependencesValue = this.getDependencesValue(this.props);
        if (nextDependencesValue && nextDependencesValue.length > 0) {
            this.setState(
                {
                    options: [],
                    dependencesValue: nextDependencesValue,
                    showNeedDependencesMessage: false,
                },
                () => {
                    this.loadOptions(nextDependencesValue);
                }
            );
        }
    };

    // 当props发生变化时，更新待选项数据
    componentWillReceiveProps(nextProps: any) {
        const { row, name, onChange } = nextProps;
        const { dependencesValue } = this.state;
        const nextDependencesValue = this.getDependencesValue(nextProps);
        let showNeedDependencesMessage = false;
        // 如果props变更后，依赖项有值
        if (nextDependencesValue) {
            if (dependencesValue !== nextDependencesValue) {
                if (nextDependencesValue.length === 0) {
                    this.clearValue(nextProps);
                    return;
                }
                if (nextDependencesValue.length !== 1) {
                    if (
                        nextProps.pageType === PageType.CashCouponRuleMemberLevel ||
                        nextProps.pageType === PageType.GiftCouponRuleMemberLevel
                    ) {
                        //特殊页面处理-选择专柜-多个专柜是否不是同一门店；选择门店-是否选择了一个
                        const nextTypeselectMode = row && get(row, 'productInfo.selectMode');
                        const thisTypeselectMode =
                            this.props.row && get(this.props.row, 'productInfo.selectMode');
                        if (
                            thisTypeselectMode !== null &&
                            nextTypeselectMode !== thisTypeselectMode
                        ) {
                            this.clearValue(nextProps);
                            return;
                        }
                    }
                }
                //清空已选择的值
                if (dependencesValue) {
                    if (onChange) {
                        onChange(null, name);
                    }
                }
                //重新请求options
                this.setState(
                    {
                        options: [],
                        dependencesValue: nextDependencesValue,
                        showNeedDependencesMessage: showNeedDependencesMessage,
                    },
                    () => {
                        this.loadOptions(nextDependencesValue);
                    }
                );
            }
        } else if (dependencesValue) {
            this.clearValue(nextProps);
        }
    }

    clearValue = (nextProps: any) => {
        const { name, onChange } = nextProps;
        const { dependencesValue } = this.state;
        if (dependencesValue) {
            //清空已选择的值
            if (onChange) {
                onChange(null, name);
            }
        }
        // 清楚保存的依赖项的值
        this.setState({
            dependencesValue: null,
            options: [],
            showNeedDependencesMessage: true,
        });
    };

    loadOptions = (dependencesValue: any) => {
        const { pageType } = this.state;
        const { row } = this.props;
        let subsiteIds: string[] = [];
        if (
            pageType === PageType.PackingCouponRuleMemberLevel ||
            pageType === PageType.FrightCouponRuleMemberLevel ||
            pageType === PageType.LotteryCouponRuleMemberLevel ||
            pageType === PageType.CouponPackageRuleMemberLevel ||
            pageType === PageType.EfutureCouponRuleMemberLevel ||
            pageType === PageType.BfplatCouponRuleMemberLevel
        ) {
            subsiteIds = map(dependencesValue, 'id');
        } else if (
            pageType === PageType.CashCouponRuleMemberLevel ||
            pageType === PageType.GiftCouponRuleMemberLevel
        ) {
            const selectMode = row && get(row, 'productInfo.selectMode');
            if (selectMode === 'SUB_SITE') {
                subsiteIds = map(dependencesValue, 'id');
            }
            if (selectMode === 'MERCHANT' && dependencesValue.length > 0) {
                const allSubsiteIds = map(dependencesValue, 'subSiteId');
                const newSubsiteIds: string[] = [];
                forEach(allSubsiteIds, (subsiteId) => {
                    const ca = find(newSubsiteIds, subsiteId);
                    if (!ca) {
                        newSubsiteIds.push(subsiteId);
                    }
                });
                subsiteIds = newSubsiteIds;
            }
        }
        if (subsiteIds.length === 1) {
            //获取单门店会员等级
            api.get(
                {
                    subsiteId: subsiteIds,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                    apiPath: '/admin/store_card_levels?page=1&per_page=100&status=ENABLE',
                }
            ).then((response: any) => {
                const result: Array<any> = [];
                forEach(response.result, (level: any) => {
                    result.push({
                        id: level.cardLevelId,
                        name: level.name,
                    });
                });
                this.setState({
                    options: result,
                });
            });
        } else {
            //获取租户会员等级
            api.get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                    apiPath: '/admin/card_levels?status=ENABLE',
                }
            ).then((response: any) => {
                const result: Array<any> = [];
                forEach(response, (level: any) => {
                    result.push({
                        id: level.id,
                        name: level.name,
                    });
                });
                this.setState({
                    options: result,
                });
            });
        }
    };

    handleClick = () => {
        const { showNeedDependencesMessage } = this.state;
        if (showNeedDependencesMessage) {
            AntMessage.warning(services.language.getText('selectSubsite'));
            return false;
        }
    };

    render() {
        const props: any = this.props;
        let { options, showNeedDependencesMessage } = this.state;
        if (props.disabled) {
            showNeedDependencesMessage = true;
        }
        return (
            <div onClick={this.handleClick}>
                <SelectAutoComplete
                    mode="multiple"
                    {...props}
                    disabled={showNeedDependencesMessage}
                    options={options}
                />
            </div>
        );
    }
}
