import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { message, Select, Button, Progress } from 'antd';
import { TabKey } from '../index';
import { Subsite } from '../../common';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;

export enum AccountType {
    POINT = 'POINT',
    AMOUNT = 'AMOUNT',
    EXPERIENCE = 'EXPERIENCE',
    CARD_EXPERIENCE = 'CARD_EXPERIENCE',
    COMMISSION = 'COMMISSION',
    GOLD = 'GOLD',
    PARKING_POINT = 'PARKING_POINT',
    DISTRIBUTION_COMMISSION = 'DISTRIBUTION_COMMISSION',
    COUPON = 'COUPON',
}
interface MemberAccount {
    accountType: AccountType;
    balance: number;
    name: string;
}

interface MemberCardInfo {
    backgroundPictureUrl: string;
    cardNo: string;
    experience: number;
    level: number;
    levelId: string;
    levelName: string;
    status: string;
    subsiteId: string;
    accounts: MemberAccount[];
    couponCount: number;
    membershipCardLevelUpgradeInfoVo?: MembershipCardLevelUpgradeInfoVo;
}
interface MembershipCardLevelUpgradeInfoVo {
    currentExperienceValue: number;
    currentLevelId: number;
    currentLevelIsMaxLevel: boolean;
    currentLevelName: string;
    nextLevelInfo: NextLevelInfo;
}

interface NextLevelInfo {
    experienceValueThreshold: number;
    gainAnyExperienceUpgrade: boolean;
    levelId: number;
    levelName: string;
    upgradeNeedExperienceValue: number;
}
interface Props {
    memberCardInfo?: MemberCardInfo | null;
    registerSubsiteCode?: string;
    onChangeMemberCard: (params: { subsiteCode: string; subsiteId: string }) => void;
    onChangeTab: (key: TabKey) => void;
}
interface AmountItem extends MemberAccount {
    onPreview?: () => void;
}
interface State {
    subsites: Subsite[];
    currentValue?: string;
}

export default class MemberInfoCard extends PureComponent<Props, State> {
    state = {
        subsites: [] as Subsite[],
        currentValue: undefined,
    };

    componentDidMount(): void {
        this.loadSubsites();
    }

    private getAmountList: () => AmountItem[] = () => {
        const { memberCardInfo, onChangeTab } = this.props;
        let amountList: AmountItem[] = [];
        if (memberCardInfo) {
            const { accounts, couponCount } = memberCardInfo;
            amountList = (accounts || []).map((item: AmountItem) => {
                if (item.accountType === AccountType.POINT) {
                    item.onPreview = onChangeTab.bind(this, TabKey.Point);
                } else if (item.accountType === AccountType.EXPERIENCE) {
                    item.onPreview = onChangeTab.bind(this, TabKey.Experience);
                }
                return item;
            });
            amountList.unshift({
                accountType: AccountType.COUPON,
                balance: couponCount,
                name: services.language.getText('ksyyhq'),
                onPreview: onChangeTab.bind(this, TabKey.Coupon),
            });
        }
        return amountList;
    };

    private loadSubsites = async () => {
        const { onChangeMemberCard, registerSubsiteCode } = this.props;
        const subsites = await api.get<Subsite[]>(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: '/WEB-API/admin/defaults/subsite',
            }
        );
        if (subsites.length === 0) {
            message.error(language.getText('dqzhwmdqx'));
            return;
        }
        let currentSubsite: Subsite | undefined = undefined;
        if (registerSubsiteCode) {
            currentSubsite = subsites.find((item) => item.code === registerSubsiteCode);
        }
        if (!currentSubsite) {
            currentSubsite = subsites[0];
        }
        onChangeMemberCard({
            subsiteId: currentSubsite.id,
            subsiteCode: currentSubsite.code,
        });
        this.setState({
            subsites,
            currentValue: currentSubsite.id,
        });
    };

    private onChangeSubsite = (id: string) => {
        const { onChangeMemberCard } = this.props;
        const { subsites } = this.state;
        const current = (subsites || []).find((item) => item.id === id);
        if (!current) {
            return;
        }
        onChangeMemberCard({
            subsiteId: id,
            subsiteCode: current.code,
        });
        this.setState({
            currentValue: id,
        });
    };

    private renderSubsiteSelect = () => {
        const { subsites, currentValue } = this.state;
        return (
            <div className={`${prefix}__subsite`}>
                <div>{language.getText('subsites')}：</div>
                <Select
                    style={{ width: '220px' }}
                    showSearch
                    placeholder={services.language.getText('selectPlease')}
                    filterOption={(input, option: any) =>
                        (option.props.children || '').toLowerCase().indexOf(input.toLowerCase()) >=
                        0
                    }
                    value={currentValue}
                    onChange={this.onChangeSubsite}
                >
                    {subsites.map((item) => {
                        return (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        );
                    })}
                </Select>
            </div>
        );
    };

    private renderCardMain = () => {
        const { memberCardInfo } = this.props;
        const classes = {
            main: `${prefix}__main`,
            mainTitle: `${prefix}__main__title`,
            mainItem: `${prefix}__main__item`,
            mainItemKey: `${prefix}__main__item__key`,
            mainItemValue: `${prefix}__main__item__value`,
            mainItemBtn: `${prefix}__main__item__btn`,
            mainLeft: `${prefix}__main__left`,
            leftCard: `${prefix}__main__left__card`,
            leftCardNone: `${prefix}__main__left__card--none`,
            mainRight: `${prefix}__main__right`,
        };
        if (!memberCardInfo) {
            return null;
        }
        const cardStyle = {
            backgroundImage: `url(${memberCardInfo.backgroundPictureUrl})`,
        };
        const amountList = this.getAmountList();
        return (
            <div className={classes.main}>
                <div className={classes.mainLeft}>
                    <div className={classes.mainTitle}>{language.getText('hyk')}</div>
                    <div className={classes.mainItem}>
                        <div className={classes.mainItemKey}>{language.getText('hykmc')}：</div>
                        <div className={classes.mainItemValue}>
                            {memberCardInfo?.levelName || ''}
                        </div>
                    </div>
                    <div className={classes.mainItem}>
                        <div className={classes.mainItemKey}>{language.getText('hykh')}：</div>
                        <div className={classes.mainItemValue}>{memberCardInfo?.cardNo || ''}</div>
                    </div>
                    {memberCardInfo.backgroundPictureUrl ? (
                        <div className={classes.leftCard} style={cardStyle}></div>
                    ) : (
                        <div className={classes.leftCardNone}>{language.getText('dqmdwkthyk')}</div>
                    )}
                    {this.renderUpgradeInfo()}
                </div>
                <div className={classes.mainRight}>
                    <div className={classes.mainTitle}>{language.getText('hyzc')}</div>
                    {amountList.map(this.renderAmountItem)}
                </div>
            </div>
        );
    };
    private renderUpgradeInfo = () => {
        const { memberCardInfo } = this.props;
        if (!memberCardInfo?.membershipCardLevelUpgradeInfoVo) {
            return null;
        }
        const classes = {
            mainLeftProgress: `${prefix}__main__left__progress`,
            mainLeftProgressLeft: `${prefix}__main__left__progress__left`,
            mainLeftProgressText: `${prefix}__main__left__progress__text`,
        };
        const {
            currentExperienceValue,
            nextLevelInfo,
            currentLevelIsMaxLevel,
        } = memberCardInfo.membershipCardLevelUpgradeInfoVo;
        const percent = currentExperienceValue
            ? !currentLevelIsMaxLevel && nextLevelInfo?.experienceValueThreshold
                ? (currentExperienceValue / nextLevelInfo?.experienceValueThreshold) * 100
                : 100
            : 0;
        const percentText = currentExperienceValue
            ? !currentLevelIsMaxLevel && nextLevelInfo?.experienceValueThreshold
                ? `${currentExperienceValue} / ${nextLevelInfo?.experienceValueThreshold}`
                : currentExperienceValue
            : 0;
        return (
            <div className={classes.mainLeftProgress}>
                <div className={classes.mainLeftProgressLeft}>
                    <Progress percent={percent} showInfo={false} status="normal" />
                </div>
                <div className={classes.mainLeftProgressText}>{percentText}</div>
            </div>
        );
    };
    private renderAmountItem = (item: AmountItem) => {
        const classes = {
            item: `${prefix}__main__right__item`,
            itemKey: `${prefix}__main__right__item__key`,
            itemValue: `${prefix}__main__right__item__value`,
            itemButton: `${prefix}__main__right__item__button`,
        };
        return (
            <div className={classes.item}>
                <div className={classes.itemKey}>{item.name}</div>
                <div className={classes.itemValue}>{item.balance}</div>
                {item.onPreview ? (
                    <Button type="link" className={classes.itemButton} onClick={item.onPreview}>
                        {language.getText('common.view')}
                    </Button>
                ) : null}
            </div>
        );
    };

    render() {
        return (
            <div className={prefix}>
                {this.renderSubsiteSelect()}
                {this.renderCardMain()}
            </div>
        );
    }
}

const prefix = 'member-info-card';
