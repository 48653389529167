import React, { PureComponent } from 'react';
import { Popover as AntPopover, message as AntMessage, Button as AntButton } from 'antd';
import { services } from '@comall-backend-builder/core';
import QRCode from 'qrcode.react';

import './index.less';
import { isObject, isArray } from 'lodash';
const api = services.api;
const language = services.language;
// const tenantDomain = encodeURIComponent(ENV.TENANT_DOMAIN || window.location.origin);

/**
 * 新c可视化页面预览
 */
export class PageNewPreviewButton extends PureComponent<any, any> {
    static defaultProps = {
        extraParams: [],
    };
    copyLink = (url: any) => {
        return function() {
            api.post(
                {
                    link_url: encodeURI(url),
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-SHORTLINK`,
                    apiPath: '/admin/short_link/CAE',
                }
            ).then(
                (res) => {
                    let input = document.createElement('input');
                    input.value = res || url;
                    document.body.appendChild(input);
                    input.select();
                    if (document.execCommand('Copy')) {
                        AntMessage.success(services.language.getText('copySuccess'));
                    } else {
                        AntMessage.success(services.language.getText('copyError'));
                    }
                    document.body.removeChild(input);
                },
                (error) => {
                    // services.errorHandle(error);
                    AntMessage.success(services.language.getText('copyError'));
                }
            );
        };
    };
    formatUrls = () => {
        const { extraParams } = this.props;
        const {
            type,
            id,
            lastModifyTime,
            subsites,
            h5Domain,
            previewMode,
            merchantId,
            name,
        } = this.props.row;
        const time = (lastModifyTime + '').replace(' ', '');
        const path: any = {
            platformPage: '/#/pages/platform/index',
            storePage: '/#/pages/home/index',
            merchantPage: '/#/subpackages/merchant/index',
            customPage: '/#/subpackages/topic/index',
            memberCenterPage: '/#/pages/member-center/index',
            payResultPage: '/#/subpackages/pay-result/index',
            platformSearchPage: '/#/subpackages/search-dimension/index',
            storeSearchPage: '/#/subpackages/search-dimension/index',
            posReceiptsPage: '/#/subpackages/send-coupon/pages/electronic-bill-detail/index',
            creditEshopPage: '/#/subpackages/credit-shop/index',
            carparkPage: '/#/subpackages/parking-fee/index',
        };
        let url =
            'https://' +
            h5Domain +
            path[type] +
            `?id=${id}&name=${name}&previewMode=${previewMode}&rand=${time}`;
        if (type === 'merchantPage') {
            url = url + `&merchantId=${merchantId}`;
        }
        if (type === 'payResultPage') {
            let productType = name === language.getText('entityOrder') ? 0 : 1;
            url = url + `&productType=${productType}`;
        }
        if (subsites && subsites.length > 0) {
            url = url + `&subsites=${JSON.stringify(subsites.slice(0, 1))}`;
        }
        if (extraParams.length > 0) {
            extraParams.forEach((item: string) => {
                const param = this.props.row[item];
                if (param) {
                    url = url + `&${item}=${this.formatParams(param)}`;
                }
            });
        }
        return url;
    };
    formatParams = (data: any) => {
        if (isObject(data) || isArray(data)) {
            return JSON.stringify(data);
        } else {
            return data;
        }
    };
    renderContent = () => {
        const { h5Domain, previewMode } = this.props.row;
        const url = this.formatUrls();
        if (h5Domain && previewMode) {
            if (this.props.type === 'h5') {
                return (
                    <div className="new-preview-content">
                        <p className="info">
                            <AntButton
                                type="link"
                                style={{ padding: 0 }}
                                className="span-link"
                                onClick={this.copyLink(url)}
                            >
                                {language.getText('copyLink')}
                            </AntButton>
                        </p>
                    </div>
                );
            }
            return (
                <div className="new-preview-content">
                    <QRCode className="qr-code" value={url} />
                    <p className="info">{language.getText('mobileScanPreview')}</p>
                    <p className="info">
                        {language.getText('orClick')}
                        <AntButton
                            type="link"
                            style={{ padding: 0 }}
                            className="span-link"
                            onClick={this.copyLink(url)}
                        >
                            {language.getText('copyLink')}
                        </AntButton>
                        {language.getText('onMobileOpen')}
                    </p>
                </div>
            );
        } else {
            return (
                <div className="new-preview-content">
                    <p className="info">{language.getText('notConfigH5')}</p>
                </div>
            );
        }
    };

    onCopyLink = () => {
        const { previewMode } = this.props.row;
        if (this.props.type === 'h5' && previewMode !== 'OPEN_MINI_PROGRAM') {
            return AntMessage.success(services.language.getText('generateH5LandingPage'));
        }
        const url = this.formatUrls();
        return this.copyLink(url);
    };
    render() {
        const { type } = this.props;
        const { previewMode, decoration } = this.props.row;
        if (type === 'h5') {
            const classNameTxt =
                previewMode === 'OPEN_MINI_PROGRAM'
                    ? 'table-action-column-item copy-span-link'
                    : 'table-action-column-item';
            const url = this.formatUrls();
            const copyLinks = this.copyLink(url);
            if (previewMode === 'OPEN_MINI_PROGRAM') {
                return (
                    <span className={classNameTxt} onClick={copyLinks}>
                        {language.getText('copyH5Link')}
                    </span>
                );
            }
            return <span className={classNameTxt}>{language.getText('copyH5Link')}</span>;
        }
        if (!decoration && (type === 'platformSearchPage' || type === 'storeSearchPage')) {
            return (
                <span style={{ color: 'rgba(0, 0, 0, 0.25)' }}>
                    {language.getText('components.PageManage.preview')}
                </span>
            );
        }
        return (
            <AntPopover content={this.renderContent()}>
                <span className="table-action-column-item copy-span-link">
                    {language.getText('components.PageManage.preview')}
                </span>
            </AntPopover>
        );
    }
}
