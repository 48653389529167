import { Config } from '@comall-backend-builder/core/lib/parser';

export const MemberLevelUpDownGradeConfig: Config = {
    entities: {
        MemberLevelUpDownGradeRule: {
            apiPath: '/loader/admin/scrm_levels_upgrade_downgrade_rules',
            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/MAGIC-SCRM'),
            properties: {},
        },
    },
    components: {
        MemberLevelUpDownGradePage: {
            component: 'Card',
            entity: 'MemberLevelUpDownGradeRule',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'MemberLevelUpDownGradeIntro',
                        style: { marginBottom: 20 },
                    },
                    { component: 'MemberLevelUpDownRuleTable' },
                ],
            },
        },
        MemberLevelUpDownGradeIntro: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'Text',
                    text: '<<sjjgzsm>>',
                    style: {
                        marginBottom: 12,
                        fontWeight: 'bold',
                    },
                },
                {
                    component: 'FlexLayout',
                    direction: 'horizontal',
                    style: {
                        color: '#999',
                    },
                    items: [
                        {
                            component: 'Text',
                            text: '<<sj>>',
                            style: {
                                flexGrow: 0,
                                width: 60,
                            },
                        },
                        {
                            component: 'FlexLayout',
                            direction: 'vertical',
                            items: [
                                {
                                    component: 'Text',
                                    text: '<<sjwkjb>>',
                                },
                                {
                                    component: 'Text',
                                    text: '<<mtjsqytddsjbzdhy>>',
                                },
                                {
                                    component: 'Text',
                                    text: '<<hysjhgjbyyfjsxczxbygz>>',
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'FlexLayout',
                    direction: 'horizontal',
                    style: {
                        color: '#999',
                        marginTop: 8,
                    },
                    items: [
                        {
                            component: 'Text',
                            text: '<<bj>>',
                            style: {
                                flexGrow: 0,
                                width: 60,
                            },
                        },
                        {
                            component: 'Text',
                            text: '<<ddbytj>>',
                        },
                    ],
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/member-level-up-down-grade',
                    breadcrumbName: '<<scrmLevelsUpdownGrade>>',
                    component: 'Viewport',
                    privilege: {
                        path: 'scrmLevelsUpgradeDowngradeRule',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/',
                            component: 'MemberLevelUpDownGradePage',
                        },
                    ],
                },
            ],
        },
    ],
};
