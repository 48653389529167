import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { find, forEach, isEmpty } from 'lodash';
import moment from 'moment';

const api = services.api;

export const StoredValueCardBuyActivityLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = `/admin/stored_value_card_reward_activities`;
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/stored_value_card_reward_activities/${id}`;
        } else {
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                const subsites: any = find(res.activityVo.normalConditions, {
                    normalConditionType: 'SUB_SITE',
                });
                const paymentModeType: any = find(res.activityVo.normalConditions, {
                    normalConditionType: 'PAYMENT_MODE_TYPE',
                });
                res.id = res.activityVo.id;
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : services.language.getText('longTerm'),
                    };
                }
                res.baseInfo = {
                    name: res.activityVo.name,
                    dateRange: {
                        start: moment(res.activityVo.startTime).format('YYYY-MM-DD'),
                        end: moment(res.activityVo.endTime).format('YYYY-MM-DD'),
                    },
                    subsites: subsites.includeIds,
                    paymentModeType: paymentModeType
                        ? paymentModeType.includeIds[0].id + ''
                        : undefined,
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };
                //先获取商品集合
                const productIds: Array<{ id: number }> = [];
                forEach(res.storedValueCardRewardActivity.rewardConfigs, (config: any) => {
                    if (!productIds.includes(config.productId)) {
                        productIds.push(config.productId);
                    }
                });
                //按照商品的维度生成页面渲染数组
                const rewardConfigs: any[] = [];
                forEach(productIds, (id) => {
                    const findList =
                        res.storedValueCardRewardActivity.rewardConfigs.filter(
                            (config: any) => config.productId === id
                        ) || [];
                    if (findList.length > 0) {
                        const rewards: any[] = [];
                        forEach(findList, (reward) => {
                            if (
                                reward.assetRewards &&
                                reward.assetRewards.length > 0 &&
                                reward.assetRewards[0].rewardType === 'POINT' &&
                                reward.assetRewards[0].rewardValue
                            ) {
                                reward.pointChecked = true;
                                reward.point = reward.assetRewards[0].rewardValue;
                            }
                            if (reward.couponRewards && reward.couponRewards.length > 0) {
                                reward.couponsValue = reward.couponRewards.map(
                                    (couponReward: any) => {
                                        const rewardId = couponReward.id;
                                        const id = couponReward.couponDefinitionId;
                                        return {
                                            ...couponReward,
                                            rewardId: rewardId,
                                            name: couponReward.couponName,
                                            id: id,
                                            quantity: couponReward.count,
                                        };
                                    }
                                );
                                reward.couponsChecked = true;
                            }
                            rewards.push(reward);
                        });
                        rewardConfigs.push({
                            productId: Number(id),
                            rewards: rewards,
                        });
                    }
                });
                res.rewardInfo = {
                    rewardMethod:
                        res.storedValueCardRewardActivity.rewardConfigs[0].productId > 0
                            ? 'PRODUCT'
                            : 'ORDER_AMOUNT',
                    rewardConfigs: rewardConfigs,
                };
            } else {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        return item;
                    });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/stored_value_card_reward_activities';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = `/admin/stored_value_card_reward_activities/${params.id}`;
        const newParams: any = dataParser(params);
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    newParams.activityVo = {
        eventType: 'STORED_VALUE_CARD_REWARD',
        name: params.baseInfo.name,
        startTime: params.baseInfo.dateRange.start + ' 00:00:00',
        endTime: params.baseInfo.dateRange.end + ' 23:59:59',
        normalConditions: [
            {
                all: false,
                normalConditionType: 'SUB_SITE',
                includeIds: params.baseInfo.subsites,
            },
            {
                all: false,
                normalConditionType: 'PAYMENT_MODE_TYPE',
                includeIds: [{ id: params.baseInfo.paymentModeType }],
            },
        ],
    };
    const rewardConfigs: any[] = [];
    forEach(params.rewardInfo.rewardConfigs, (item) => {
        forEach(item.rewards, (reward) => {
            const assetRewards: any = [];
            let couponRewards: any = [];
            if (reward.pointChecked) {
                assetRewards.push({
                    name: reward.pointName,
                    rewardType: 'POINT',
                    rewardValue: reward.point,
                });
            }
            if (reward.couponsChecked && reward.couponsValue.length) {
                if (reward.couponsValue.length) {
                    couponRewards = reward.couponsValue.map((couponReward: any) => {
                        return {
                            ...couponReward,
                            couponDefinitionId: couponReward.id,
                            id: window.location.href.includes('/edit')
                                ? couponReward.rewardId || null
                                : null,
                            status: 'ENABLED',
                            count: couponReward.quantity ? couponReward.quantity : 1,
                            quantity: undefined,
                        };
                    });
                }
            }
            rewardConfigs.push({
                productId: item.productId ? item.productId : -1,
                id: reward.id ? reward.id : undefined,
                lowerValue: reward.lowerValue,
                stepValue: reward.stepValue,
                assetRewards: assetRewards,
                couponRewards: couponRewards,
            });
        });
    });

    newParams.storedValueCardRewardActivity = {
        rewardConfigs: rewardConfigs,
    };
    newParams.marketingScheduleId =
        (params.baseInfo?.marketingSchedule?.length && params.baseInfo.marketingSchedule[0].id) ||
        null;

    return newParams;
}
