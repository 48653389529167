// @ts-nocheck
import React, { PureComponent } from 'react';
import { Button, Modal, Upload, Icon, message, Modal as AntModal, Menu } from 'antd';
import { services } from '@comall-backend-builder/core';
import { ExportButton } from '@comall-backend-builder/components-basis';
import './index.less';

const { api } = services;
const { Dragger } = Upload;

/**
 * 优惠券商品导入按钮
 */
export class CouponRulesImportButton extends PureComponent<
    any,
    { fileList: any; showModal: boolean; statusMsg: string; selectMode: string }
> {
    state = {
        fileList: [],
        showModal: false,
        statusMsg: services.language.getText('uploadFileClickButton'),
        selectMode: 'GOODS',
    };

    toggleModal = () => {
        const { showModal } = this.state;
        this.setState({ showModal: !showModal, fileList: [] });
    };

    onImport = () => {
        const { selectMode } = this.state;
        this.setState({
            statusMsg: services.language.getText('importBeforeNotPage'),
        });
        const {
            row: { id },
            entity,
            type,
        } = this.props;
        const apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        const apiPath = `/admin/${type}_coupon_rules/${id}/dimensions`;
        const params = {
            template_type: selectMode,
        };
        api.put(params, { apiRoot, apiPath })
            .then(
                (res) => {
                    //开始走上传文件的接口
                    const { fileList } = this.state;
                    const formData = new FormData();
                    formData.append('files', fileList[0]);
                    formData.append(
                        'meta_data',
                        JSON.stringify({ couponRuleId: id, templateType: selectMode })
                    );
                    services.api
                        .upload(
                            { files: formData },
                            {
                                apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
                                apiPath: '/admin/files/locations/140/upload',
                                fileName: 'files',
                                progressCallBack: () => {},
                                useCustomData: true,
                            }
                        )
                        .then(() => {
                            let contentBefore = React.createElement(
                                'span',
                                null,
                                services.language.getText('fileUploading')
                            );
                            let contentAfter = React.createElement('span', null, '中查看');
                            let link = React.createElement(
                                'a',
                                {
                                    onClick: () => {
                                        services.behaviorHandle({
                                            route: '/report-download-center',
                                        });
                                        AntModal.destroyAll();
                                    },
                                },
                                services.language.getText('dataExportAndImport')
                            );
                            let content = React.createElement('div', {}, [
                                contentBefore,
                                link,
                                contentAfter,
                            ]);
                            AntModal.info({
                                content: content,
                            });

                            entity.search();
                            this.toggleModal();
                            //上传成功之后页面置空
                            this.setState({
                                selectMode: 'GOODS',
                                fileList: [],
                            });
                        })
                        .catch((err) => {
                            if (err) {
                                message.error(
                                    err.response?.body?.err_msg ||
                                        services.language.getText('uoloadFail')
                                );
                            }
                        });
                },
                (err) => {
                    message.error(err.response.body.err_msg);
                    this.setState({
                        statusMsg: services.language.getText('importFailPrivity'),
                    });
                }
            )
            .catch(() => {
                this.setState({
                    statusMsg: services.language.getText('importFail'),
                });
            });
    };

    handleOk = () => {
        const { fileList } = this.state;
        if (fileList && fileList.length > 0) {
            this.onImport();
        } else {
            message.error(services.language.getText('uploadFile'));
            return;
        }
    };

    onSelectKey = (key: string) => {
        this.setState({
            selectMode: key,
            fileList: [],
        });
    };

    render() {
        const { showModal, fileList, statusMsg, selectMode } = this.state;
        const { row, show } = this.props;
        let disabled = false;
        if (row.canImport !== undefined) {
            disabled = !row.canImport;
        }
        const uploadProps = {
            accept: '.xlsx,.xls,.csv',
            multiple: false,
            onRemove: () => {
                this.setState({ fileList: [] });
            },
            beforeUpload: (file: any) => {
                this.setState({
                    fileList: [file],
                });
                return false;
            },
            fileList,
        };
        const isShow = show(row);
        return (
            isShow && (
                <div className="coupon-rules-import-botton">
                    <Button
                        disabled={disabled}
                        type="link"
                        className="btn-data-statistics"
                        onClick={this.toggleModal}
                    >
                        {services.language.getText('importProduct')}
                    </Button>
                    <Modal
                        onCancel={this.toggleModal}
                        visible={showModal}
                        title={services.language.getText('batchImport')}
                        footer={[
                            <span className="coupon-rules-import-botton-status-msg">
                                {statusMsg}
                            </span>,
                            <Button key="submit" type="primary" onClick={this.handleOk}>
                                {services.language.getText('import')}
                            </Button>,
                            <Button key="back" onClick={this.toggleModal}>
                                {services.language.getText('back')}
                            </Button>,
                        ]}
                    >
                        <div className="coupon-rules-import-menu">
                            <Menu
                                selectedKeys={[selectMode]}
                                onClick={({ key }) => {
                                    this.onSelectKey(key);
                                }}
                            >
                                <Menu.Item key="GOODS">
                                    {services.language.getText('importProduct')}
                                </Menu.Item>
                                <Menu.Item key="BRAND">
                                    {services.language.getText('drpp')}
                                </Menu.Item>
                                <Menu.Item key="MERCHANT_BIG_CODE">
                                    {services.language.getText('importBarCode')}
                                </Menu.Item>
                            </Menu>
                            <div className="coupon-rules-import-menu-info">
                                <div>
                                    <Dragger {...uploadProps}>
                                        <p className="ant-upload-drag-icon">
                                            <Icon type="inbox" />
                                        </p>
                                        <p className="ant-upload-text">
                                            {services.language.getText('selectFile')}
                                        </p>
                                        <p className="ant-upload-hint">
                                            {services.language.getText('zckzm')}：.xlsx、.xls、.csv
                                        </p>
                                    </Dragger>
                                </div>
                                <div className="coupon-rules-import-botton-tips">
                                    <p className="coupon-rules-import-botton-tips-text">
                                        {services.language.getText('uploadIntro')}:
                                    </p>
                                    <p className="coupon-rules-import-botton-tips-text">
                                        {services.language.getText('uploadIntro1')}
                                    </p>
                                    <p className="coupon-rules-import-botton-tips-text">
                                        {services.language.getText('uploadIntro2')}
                                    </p>
                                    <p className="coupon-rules-import-botton-tips-text">
                                        {services.language.getText('uploadIntro3')}
                                    </p>
                                    <p className="coupon-rules-import-botton-tips-text">
                                        {services.language.getText('uploadIntro5')}
                                    </p>
                                </div>
                                <div className="upload-tips">
                                    <ExportButton
                                        type="link"
                                        text={
                                            selectMode === 'GOODS'
                                                ? services.language.getText('xzdrspmb')
                                                : selectMode === 'BRAND'
                                                ? services.language.getText('xzdrppmb')
                                                : services.language.getText('xzdrsjmmb')
                                        }
                                        showErrorMessage={true}
                                        params={
                                            selectMode === 'GOODS'
                                                ? { templateType: 'GOODS' }
                                                : selectMode === 'BRAND'
                                                ? { templateType: 'BRAND' }
                                                : { templateType: 'MERCHANT_BIG_CODE' }
                                        }
                                        source={{
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                                            apiPath: `/admin/import_templates`,
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            )
        );
    }
}
