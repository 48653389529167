import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
export const config: Config = {
    entities: {
        balanceDescription: {
            apiPath: '/loader/admin/balance-description',
            properties: {
                subsiteName: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                desc: {
                    type: 'string.richTextPlus',
                    displayName: '<<amountAccountsDesc>>',
                    controlConfig: {
                        toolbarId: 'descriptionRichText',
                        style: {
                            height: 300,
                        },
                        uploadConfig: {
                            apiPath: `/admin/images/upload`,
                            fileName: 'fileName',
                        },
                    },
                    rules: [
                        {
                            required: true,
                            max: 20000,
                            message: '<<qsryesm>>',
                        },
                    ],
                },
                subsites: {
                    type: 'array.options.autoComplete',
                    displayName: '<<otherSelectSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    rules: [{ required: true }],
                },
            },
        },
    },
    components: {
        BalanceDescriptionView: {
            component: 'Viewport',
        },
        BalanceDescriptionPage: {
            component: 'Card',
            entity: 'balanceDescription',
            className: 'card-table-content',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'GridLayout',
                        className: 'mb24',
                        items: [
                            {
                                component: 'Button',
                                type: 'primary',
                                text: '<<xzyesm>>',
                                icon: 'plus',
                                route: '/balance-description/add',
                            },
                        ],
                    },
                    {
                        component: 'BalanceDescriptionTable',
                    },
                ],
            },
        },
        BalanceDescriptionTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                { property: 'subsiteName', displayConfig: { countSuffix: '<<subsites>>' } },
                { property: 'desc' },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                items: [
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/balance-description/edit/{{row.id}}',
                    },
                    {
                        type: 'delete',
                        text: '<<common.delete>>',
                        config: {
                            type: 'link',
                            tooltip: null,
                            title: '<<confirmDelete>>',
                            okText: '<<common.ok>>',
                            cancelText: '<<common.cancel>>',
                        },
                    },
                ],
            },
        },
        BalanceDescriptionAddPage: {
            component: 'Card',
            entity: 'balanceDescription',
            content: {
                component: 'BalanceDescriptionAddForm',
            },
        },
        BalanceDescriptionAddForm: {
            component: 'CreateForm',
            labelCol: 4,
            controlCol: 16,
            style: { width: 900 },
            fields: [
                {
                    property: 'subsites',
                    extra: '<<tymdxjzcczytyesmsj>>',
                },
                {
                    property: 'desc',
                    extra: '<<txhzyemxcxyzs>>',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        BalanceDescriptionEditPage: {
            component: 'Card',
            content: {
                component: 'BalanceDescriptionEditForm',
            },
        },
        BalanceDescriptionEditForm: {
            component: 'ModifyForm',
            entity: 'balanceDescription',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 16,
            style: { width: 900 },
            fields: [
                {
                    property: 'subsites',
                    extra: '<<tymdxjzcczytyesmsj>>',
                },
                {
                    property: 'desc',
                    extra: '<<txhzyemxcxyzs>>',
                },
            ],
            submit: { text: '<<common.save>>' },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/balance-description',
                    breadcrumbName: '<<amountAccountsDesc>>',
                    component: 'BalanceDescriptionView',
                    childRoutes: [
                        {
                            path: '/add',
                            breadcrumbName: '<<xzyesm>>',
                            component: 'BalanceDescriptionAddPage',
                        },
                        {
                            path: '/edit/:id',
                            breadcrumbName: '<<bjyesm>>',
                            component: 'BalanceDescriptionEditPage',
                        },
                        {
                            path: '/',
                            component: 'BalanceDescriptionPage',
                        },
                    ],
                },
            ],
        },
    ],
};
