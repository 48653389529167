import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Card, Table, Tabs } from 'antd';
import { services } from '@comall-backend-builder/core';
import { PaginationConfig } from 'antd/lib/table/interface';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;
const { TabPane } = Tabs;

class bindBoxDataView extends PureComponent<any, { reportData: any | undefined }> {
    constructor(props: any) {
        super(props);
        this.state = {
            reportData: undefined,
        };
    }
    componentDidMount() {
        const { id } = this.props.params;
        const apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        const apiPath = '/admin/blind_box_activities/:id/statistics';
        api.get({ id }, { apiRoot: apiRoot, apiPath: apiPath }).then((res: any) => {
            this.setState({
                reportData: res,
            });
        });
    }

    renderData = () => {
        const { reportData } = this.state;
        return (
            <Card title={language.getText('mhhdzsj')} type="inner" style={{ marginBottom: 32 }}>
                <div className="blind-box-data-wrap">
                    <div className="blind-box-data-item blind-box-data-detail-item">
                        <div className="blind-box-data-item-title">
                            {language.getText('mhzdkmzs')}
                        </div>
                        <div className="blind-box-data-item-content">
                            {reportData.totalQuantity}
                        </div>
                    </div>
                    <div className="blind-box-data-item blind-box-data-detail-item">
                        <div className="blind-box-data-item-title">
                            {language.getText('yffzsl')}
                        </div>
                        <div className="blind-box-data-item-content">
                            {reportData.totalIssuedQuantity}
                        </div>
                    </div>
                    <div className="blind-box-data-item blind-box-data-detail-item">
                        <div className="blind-box-data-item-title">
                            {language.getText('sykmzs')}
                        </div>
                        <div className="blind-box-data-item-content">
                            {reportData.totalRemainingQuantity}
                        </div>
                    </div>
                    <div className="blind-box-data-item blind-box-data-detail-item">
                        <div className="blind-box-data-item-title">{language.getText('shsl')}</div>
                        <div className="blind-box-data-item-content">
                            {reportData.afterSalesQuantity}
                        </div>
                    </div>
                    <div className="blind-box-data-item blind-box-data-detail-item">
                        <div className="blind-box-data-item-title">{language.getText('xks')}</div>
                        <div className="blind-box-data-item-content">
                            {reportData.newMemberQuantity}
                        </div>
                    </div>
                </div>
            </Card>
        );
    };

    renderProductData = () => {
        const { reportData } = this.state;
        let tableConfig: any = {
            rowKey: 'id',
            columns: [
                {
                    title: services.language.getText('components.Products.name'),
                    dataIndex: 'productName',
                    key: 'productName',
                    width: 200,
                },
                {
                    title: services.language.getText('subsiteName'),
                    dataIndex: 'subsite.name',
                    key: 'subsite.name',
                    width: 180,
                },
                {
                    title: services.language.getText('productCode'),
                    dataIndex: 'productCode',
                    key: 'productCode',
                    width: 150,
                },
                {
                    title: services.language.getText('spyj'),
                    dataIndex: 'salesPrice',
                    key: 'salesPrice',
                    width: 100,
                },
                {
                    title: services.language.getText('spkc'),
                    dataIndex: 'stock',
                    key: 'stock',
                    width: 100,
                },
                {
                    title: services.language.getText('mhzdkms'),
                    dataIndex: 'quantity',
                    key: 'quantity',
                    width: 150,
                },
                {
                    title: services.language.getText('yffsl'),
                    dataIndex: 'issuedQuantity',
                    key: 'issuedQuantity',
                    width: 150,
                },
                {
                    title: services.language.getText('sykms'),
                    dataIndex: 'remainingQuantity',
                    key: 'remainingQuantity',
                    width: 150,
                },
            ],
            style: {
                marginTop: 20,
                maxHeight: '400px',
                overflow: 'hidden',
                overflowY: 'auto',
                overflowX: 'auto',
            },
        };
        const pagination = false as PaginationConfig | false;
        const tableProps = {
            ...tableConfig,
            dataSource: reportData.goods,
            pagination,
        };

        return <Table {...tableProps}></Table>;
    };

    render() {
        const { reportData } = this.state;
        if (!reportData) return null;
        return (
            <Fragment>
                <Tabs type="line" className="blind-box-data">
                    <TabPane tab={reportData.activityName}>
                        {this.renderData()}
                        {this.renderProductData()}
                    </TabPane>
                </Tabs>
            </Fragment>
        );
    }
}

function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
    };
}

export const BindBoxDataView = connect(mapStateToProps)(bindBoxDataView);
