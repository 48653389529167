import { ObjectMode } from '@comall-backend-builder/types';
import React from 'react';
import AutoCompleteAppidBySubsiteControl from './auto-complete-appid-by-subsite-control';
import AutoCompleteAppidBySubsiteDisplay from './auto-complete-appid-by-subsite-display';

export interface WechatOfficialAccount {
    appId: string;
    name: string;
}
export default class AutoCompleteAppIdBySubsiteObjectMode extends ObjectMode {
    public getControlComponent(controlInfo: any): JSX.Element {
        return <AutoCompleteAppidBySubsiteControl {...controlInfo} />;
    }
    public getAvailableDisplayComponent(value: any, displayInfo: any): JSX.Element {
        return <AutoCompleteAppidBySubsiteDisplay value={value} {...displayInfo} />;
    }
}
