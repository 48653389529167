import React, { PureComponent } from 'react';
import { cloneDeep, map } from 'lodash';
import { CouponSelector, CouponSelectedDisplay } from '../../components';
import { Coupon } from '../select-coupon';

export class SelectCoupons extends PureComponent<
    any,
    {
        value: Array<Coupon>;
    }
> {
    state = { value: new Array<Coupon>() };

    getSubsiteIds = (props: any) => {
        const { row } = props;
        const subsiteIds =
            row.baseInfo && row.baseInfo.subsiteIds ? map(row.baseInfo.subsiteIds, 'id') : null;
        return subsiteIds;
    };

    /**
     * 选择优惠券
     * @param selectValues
     */
    onChange = (selectValues: any[]) => {
        const { onChange } = this.props;
        if (onChange) {
            if (selectValues && selectValues.length === 0) {
                onChange(undefined);
            } else {
                onChange(
                    selectValues.map((item: any) => {
                        return {
                            ...item,
                            quantity: item.quantity ? item.quantity : 1,
                        };
                    })
                );
            }
        }
    };

    /**
     * 添加优惠券弹窗确认后回调
     * @param selectValues 选中优惠券
     */
    onSelect = (selectValues: Coupon[]) => {
        const { value } = this.props;
        let newData: Coupon[] = [];
        if (value) {
            newData = cloneDeep(value);
        }
        if (selectValues) {
            newData = selectValues;
        }
        this.onChange(newData);
    };

    render() {
        const { value, disabled } = this.props;
        const hasValue = value && value.length > 0;
        const subsiteIds = this.getSubsiteIds(this.props);
        return (
            <div>
                <CouponSelector
                    onChange={this.onSelect}
                    selectValues={value}
                    type="checkbox"
                    params={{ subsiteIds: subsiteIds }}
                />
                {hasValue && (
                    <CouponSelectedDisplay
                        data={value}
                        onRemove={this.onChange}
                        disabled={disabled}
                        canChangeQuantity={true}
                    ></CouponSelectedDisplay>
                )}
            </div>
        );
    }
}
