import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { forEach } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

export const merchantStyleGroupConfig: Config = {
    entities: {
        MerchantStyleGroupEntity: {
            apiPath: '/loader/admin/style-group',
            paramsFilter: ['MERCHANT'],
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<multiStyleProduct>>',
                    controlConfig: {
                        placeholder: '<<multiStyleProductRequired>>',
                    },
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 200 } },
                },
                merchantId: {
                    type: 'string.filterOptions.autoComplete',
                    displayName: '<<merchantName>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                        //专柜默认正序排序
                        params: {
                            sequence: 'code',
                            order: 'asc',
                        },
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 200 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                productName: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                    controlConfig: {
                        placeholder: '<<productNameRequired>>',
                    },
                },
                productCode: {
                    type: 'string',
                    displayName: '<<productCode>>',
                    controlConfig: {
                        displayName: '<<productCodeRequired>>',
                    },
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                productVos: {
                    type: 'array.popover',
                    displayName: '<<productVos>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherBaseInfo>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<multiStyleProduct>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<multiStyleProductRequired>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<multiStyleProductRequired>>',
                                maxLength: 20,
                                style: {
                                    width: 400,
                                },
                            },
                            extra: '<<multiStyleProductRequiredExtra>>',
                        },
                        merchant: {
                            type: 'array.shop.selector',
                            displayName: '<<selectSubsites>>',
                            rules: [{ required: true, message: '<<pleaseSelectMerchant>>' }],
                            controlConfig: {
                                type: 'radio',
                                fieldParams: {
                                    isContainsSelfMerchant: false,
                                },
                            },
                        },
                    },
                },
                styleGroupInfo: {
                    type: 'object.subForm',
                    displayName: '<<styleGroupInfo>>',
                    properties: {
                        optionList: {
                            type: 'array.styleGroup',
                            displayName: '<<optionList>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                pageType: 'merchant',
                            },
                        },
                    },
                },
                otherInfo: {
                    type: 'object.subForm',
                    displayName: '<<picTextSetting>>',
                    properties: {
                        masterDetails: {
                            type: 'string.options.radio',
                            displayName: '<<applyMainPictures>>',
                            defaultValue: 'false',
                            options: [
                                { id: 'false', name: '<<no>>' },
                                { id: 'true', name: '<<yes>>' },
                            ],
                            extra: '<<selectMainPic>>',
                        },
                    },
                },
            },
        },
    },
    components: {
        MerchantStyleGroupView: {
            component: 'Viewport',
            entity: 'MerchantStyleGroupEntity',
        },
        MerchantStyleGroupPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'MerchantStyleGroupFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<createMultiStyleProduct>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/merchant-style-group/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'MerchantStyleGroupTable' },
            ],
        },
        MerchantStyleGroupFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
                style: {
                    marginLeft: 16,
                },
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'productName',
                },
                {
                    property: 'productCode',
                },
            ],
        },
        MerchantStyleGroupTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'MerchantStyleGroupDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'name',
                            width: 180,
                        },
                        {
                            property: 'productVos',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '140px',
                                },
                                countSuffix: '<<components.ComponentSelector.products>>',
                            },
                        },
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'merchantName',
                        },
                    ],
                    actionColumn: {
                        key: 'operation',
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        items: [
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                path: '/merchant-style-group/edit/{{row.id}}',
                            },
                            {
                                type: 'delete',
                                text: '<<common.delete>>',
                                config: {
                                    type: 'link',
                                    id: 'row.id',
                                    tooltip: null,
                                    title: '<<confirmDelete>>',
                                    okText: '<<common.ok>>',
                                    cancelText: '<<common.cancel>>',
                                },
                            },
                        ],
                    },
                },
            ],
        },
        MerchantStyleGroupAddPage: {
            component: 'Card',
            content: { component: 'MerchantStyleGroupAddForm' },
        },
        MerchantStyleGroupAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'MerchantStyleGroupEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.merchant' },
                { property: 'baseInfo.name' },
                { property: 'styleGroupInfo.optionList' },
                { property: 'otherInfo.masterDetails' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.goBack>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const optionList = entity.styleGroupInfo.optionList;
                let hasValidate = true;
                if (!optionList || !optionList.length) {
                    AntMessage.warning(language.getText('setStyleInfo'));
                    hasValidate = false;
                    return false;
                }

                // 校验主款不能为空，不能全部款式为空
                let hasGoods = false;
                let hasMainGoods = false;
                forEach(optionList, (item) => {
                    if (item.goodsId) {
                        hasGoods = true;
                        if (item.mainGoods) {
                            hasMainGoods = true;
                        }
                    }
                });
                if (!hasGoods) {
                    AntMessage.warning(language.getText('multiStyleProductNotEmpty'));
                    hasValidate = false;
                    return false;
                }
                if (!hasMainGoods) {
                    AntMessage.warning(language.getText('mainProductNotEmpty'));
                    hasValidate = false;
                    return false;
                }

                if (!hasValidate) {
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        MerchantStyleGroupEditPage: {
            component: 'Card',
            content: { component: 'MerchantStyleGroupEditForm' },
        },
        MerchantStyleGroupEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'MerchantStyleGroupEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.merchant' },
                { property: 'baseInfo.name' },
                { property: 'styleGroupInfo.optionList' },
                { property: 'otherInfo.masterDetails' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.goBack>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const optionList = entity.styleGroupInfo.optionList;
                let hasValidate = true;
                if (!optionList || !optionList.length) {
                    AntMessage.warning(language.getText('setStyleInfo'));
                    hasValidate = false;
                    return false;
                }
                // 校验主款不能为空，不能全部款式为空
                let hasGoods = false;
                let hasMainGoods = false;
                forEach(optionList, (item) => {
                    if (item.goodsId) {
                        hasGoods = true;
                        if (item.mainGoods) {
                            hasMainGoods = true;
                        }
                    }
                });
                if (!hasGoods) {
                    AntMessage.warning(language.getText('multiStyleProductNotEmpty'));
                    hasValidate = false;
                    return false;
                }
                if (!hasMainGoods) {
                    AntMessage.warning(language.getText('mainProductNotEmpty'));
                    hasValidate = false;
                    return false;
                }
                if (!hasValidate) {
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/merchant-style-group',
                    component: 'MerchantStyleGroupView',
                    breadcrumbName: '<<subsiteStyleGroup>>',
                    privilege: {
                        path: 'merchantStyleGroup',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'MerchantStyleGroupAddPage',
                            breadcrumbName: '<<create>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'MerchantStyleGroupEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        { path: '/', component: 'MerchantStyleGroupPage' },
                    ],
                },
            ],
        },
    ],
};
