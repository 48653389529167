import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import get from 'lodash/get';
import { isEmpty } from 'lodash';

export const orderStatusMap: any = {
    WAIT_PAY: '待付款',
    WAIT_AUDIT: '待审核',
    AUDIT_REJECTED: '审核未通过',
    AUDIT_APPROVED: '审核通过',
    WAIT_CONSUME: '待参与',
    CONSUMED: '已完成',
    EXPIRED: '已过期',
    CANCELED: '已取消',
    CLOSED: '已关闭',
    AFTER_SALING: '售后中',
    PART_CONSUMED: ' bfhx',
};

const api = services.api;
export const MagicMallActivityRegistrationLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`;
        if (data.activityId) {
            config.apiPath = `/admin/mall_activities/registration_records?mallActivityId=${data.activityId}`;
        }
        if (data.recordId) {
            config.apiPath = `/admin/mall_activities/registration_records/${data.recordId}`;
        }
        if (!data.orderStatus) {
            data.searchStatus = 'ALL';
        } else {
            data.searchStatus = data.orderStatus;
        }
        if (!data.enable) {
            delete data.enable;
        } else if (data.enable === 'false') {
            data.enable = false;
        } else if (data.enable === 'true') {
            data.enable = true;
        }
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        if (!isEmpty(data.joinDate)) {
            data.startJoinDate = data.joinDate.start;
            data.endJoinDate = data.joinDate.end;
            delete data.joinDate;
        }
        delete data.orderStatus;

        return await api.get(data, config).then((res: any) => {
            if (data.activityId) {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        let orderStatus = item.status;
                        if (orderStatusMap[item.status]) {
                            orderStatus = services.language.getText(orderStatusMap[item.status]);
                        }
                        item.memberMobile = item.memberVo.mobile;
                        item.orderStatusStr = orderStatus;
                        item.subsiteName = item.subsite && item.subsite.name;
                        item.activityName = get(item, 'mallActivity.name');
                        item.orderNo = get(item, 'registrationPayment.orderNo') || '-';
                        item.orderAmount =
                            get(item, 'registrationPayment.cashPayableAmount') || '-';
                        item.pointDealPrice =
                            get(item, 'registrationPayment.pointPayableAmount') || '-';
                        item.orderCreateTime = get(item, 'createTime');
                        if (get(item, 'joinDate')) {
                            item.joinTime = `${get(item, 'joinDate')} ${get(
                                item,
                                'joinStartTime'
                            )}-${get(item, 'joinEndTime')}`;
                        } else {
                            item.joinTime = `${get(item, 'joinStartTime')}-${get(
                                item,
                                'joinEndTime'
                            )}`;
                        }
                        item.registrationTimeSnapshot = item.registrationTimeSnapshot || '-';

                        return item;
                    });
            }

            if (data.recordId) {
                let orderStatusStr = res.status;
                if (orderStatusMap[res.status]) {
                    orderStatusStr = services.language.getText(orderStatusMap[res.status]);
                }
                res.orderStatusStr = orderStatusStr;
            }

            return res;
        });
    },
};
