import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { find, forEach, isEmpty } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;

export const BlindBoxLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/blind_box_activities';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/blind_box_activities/${id}`;
        } else {
            data.eventType = 'BLIND_BOX';
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                const subsites: any = find(res.activityVo.normalConditions, {
                    normalConditionType: 'SUB_SITE',
                });
                const identity: any = find(res.activityVo.normalConditions, {
                    normalConditionType: 'NEW_MEMBER',
                });
                const goods: any[] = [];
                if (
                    res.activityVo.rewardConfig.activityRewards &&
                    res.activityVo.rewardConfig.activityRewards.length > 0
                ) {
                    forEach(res.activityVo.rewardConfig.activityRewards, (activityReward) => {
                        const conditionValue = activityReward.conditionValue;
                        forEach(activityReward.goodsRewards, (goodsReward: any) => {
                            goodsReward.rewardId = goodsReward.id;
                            goodsReward.id = goodsReward.goodsId;
                            goodsReward.subsiteId = goodsReward.subsite.id;
                            goodsReward.subsiteName = goodsReward.subsite.name;
                            goodsReward.merchantName = goodsReward.merchant.name;
                            goodsReward.goodsDeductPoint = goodsReward.pointPrice;
                            goodsReward.winningRate = conditionValue;
                            goods.push(goodsReward);
                        });
                    });
                }
                res.id = res.activityVo.id;
                res.editExecuteStatus = res.activityVo.executeStatus;
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : language.getText('longTerm'),
                    };
                }
                res.activityInfo = {
                    name: res.activityVo.name,
                    dateRange: {
                        start: res.activityVo.startTime,
                        end: res.activityVo.endTime,
                    },
                    subsites: subsites.includeIds,
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };
                res.ruleInfo = {
                    identity: identity ? 'NEW_MEMBER' : 'ALL',
                    activityRewardLimitType: res.activityVo.rewardConfig.activityRewardLimit
                        ? 'YES'
                        : 'NO',
                    activityRewardLimit: res.activityVo.rewardConfig.activityRewardLimit,
                    price: res.blindBoxActivity.price,
                };
                res.productInfo = {
                    goods: goods,
                };
                res.pictureInfo = {
                    showPicture: res.blindBoxActivity.pageShow.showPicture
                        ? [
                              {
                                  id: res.blindBoxActivity.pageShow.showPicture.id,
                                  path: res.blindBoxActivity.pageShow.showPicture.url,
                              },
                          ]
                        : null,
                    backgroundPicture: res.blindBoxActivity.pageShow.backgroundPicture
                        ? [
                              {
                                  id: res.blindBoxActivity.pageShow.backgroundPicture.id,
                                  path: res.blindBoxActivity.pageShow.backgroundPicture.url,
                              },
                          ]
                        : null,
                    introductionPicture: res.blindBoxActivity.pageShow.introductionPicture
                        ? [
                              {
                                  id: res.blindBoxActivity.pageShow.introductionPicture.id,
                                  path: res.blindBoxActivity.pageShow.introductionPicture.url,
                              },
                          ]
                        : null,
                    coverPicture: res.blindBoxActivity.pageShow.coverPicture
                        ? [
                              {
                                  id: res.blindBoxActivity.pageShow.coverPicture.id,
                                  path: res.blindBoxActivity.pageShow.coverPicture.url,
                              },
                          ]
                        : null,
                    packagePicture: res.blindBoxActivity.pageShow.packagePicture
                        ? [
                              {
                                  id: res.blindBoxActivity.pageShow.packagePicture.id,
                                  path: res.blindBoxActivity.pageShow.packagePicture.url,
                              },
                          ]
                        : null,
                    rulePicture: res.blindBoxActivity.pageShow.rulePicture
                        ? [
                              {
                                  id: res.blindBoxActivity.pageShow.rulePicture.id,
                                  path: res.blindBoxActivity.pageShow.rulePicture.url,
                              },
                          ]
                        : null,
                    sharePicture: res.activityVo.shareConfig.sharePicture
                        ? [
                              {
                                  id: res.activityVo.shareConfig.sharePicture.id,
                                  path: res.activityVo.shareConfig.sharePicture.url,
                              },
                          ]
                        : null,
                    shareContent: res.lottery.shareContent,
                };
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/blind_box_activities';
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = `/admin/blind_box_activities/${id}`;
        const newParams = dataParser(params);
        newParams.activityVo.id = id;
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    if (params.activityInfo) {
        newParams.activityVo = {
            eventType: 'BLIND_BOX',
            name: params.activityInfo.name,
            startTime: params.activityInfo.dateRange.start,
            endTime: params.activityInfo.dateRange.end,
            normalConditions: [
                {
                    all: false,
                    normalConditionType: 'SUB_SITE',
                    includeIds: params.activityInfo.subsites,
                },
            ],
        };
    }
    let rewardConfig: any = {};
    rewardConfig.rewardRule = 'RANDOM';
    if (params.ruleInfo) {
        if (params.ruleInfo.identity === 'NEW_MEMBER') {
            newParams.activityVo.normalConditions.push({
                all: false,
                normalConditionType: 'NEW_MEMBER',
                includeIds: [
                    {
                        id: 1,
                    },
                ],
            });
        }
        if (params.ruleInfo.activityRewardLimitType === 'YES') {
            rewardConfig.crowdScope = 'EACH';
            rewardConfig.activityRewardLimit = params.ruleInfo.activityRewardLimit;
        }
    }
    if (params.productInfo) {
        rewardConfig.activityRewards = [
            {
                goodsRewards: params.productInfo.goods.map((good: any) => {
                    //配合后端修改
                    //新增时：goods_rewards节点，货品id传goods_id字段，id为空
                    //编辑时：goods_rewards节点，货品id传goods_id字段，id为查询时获得的id
                    good.goodsId = good.id;
                    good.id = good.rewardId;
                    return good;
                }),
            },
        ];
    }
    newParams.activityVo.rewardConfig = rewardConfig;

    if (params.pictureInfo && params.ruleInfo) {
        newParams.blindBoxActivity = {
            pageShow: {
                showPicture:
                    params.pictureInfo.showPicture && params.pictureInfo.showPicture.length > 0
                        ? {
                              id: params.pictureInfo.showPicture[0].id,
                              url: params.pictureInfo.showPicture[0].path,
                          }
                        : null,
                backgroundPicture:
                    params.pictureInfo.backgroundPicture &&
                    params.pictureInfo.backgroundPicture.length > 0
                        ? {
                              id: params.pictureInfo.backgroundPicture[0].id,
                              url: params.pictureInfo.backgroundPicture[0].path,
                          }
                        : null,
                coverPicture:
                    params.pictureInfo.coverPicture && params.pictureInfo.coverPicture.length > 0
                        ? {
                              id: params.pictureInfo.coverPicture[0].id,
                              url: params.pictureInfo.coverPicture[0].path,
                          }
                        : null,
                introductionPicture:
                    params.pictureInfo.introductionPicture &&
                    params.pictureInfo.introductionPicture.length > 0
                        ? {
                              id: params.pictureInfo.introductionPicture[0].id,
                              url: params.pictureInfo.introductionPicture[0].path,
                          }
                        : null,
                packagePicture:
                    params.pictureInfo.packagePicture &&
                    params.pictureInfo.packagePicture.length > 0
                        ? {
                              id: params.pictureInfo.packagePicture[0].id,
                              url: params.pictureInfo.packagePicture[0].path,
                          }
                        : null,
                rulePicture:
                    params.pictureInfo.rulePicture && params.pictureInfo.rulePicture.length > 0
                        ? {
                              id: params.pictureInfo.rulePicture[0].id,
                              url: params.pictureInfo.rulePicture[0].path,
                          }
                        : null,
            },
            price: params.ruleInfo.price,
        };
        if (params.pictureInfo.sharePicture && params.pictureInfo.sharePicture[0]) {
            newParams.activityVo.shareConfig = {
                sharePicture: {
                    id: params.pictureInfo.sharePicture[0].id,
                    url: params.pictureInfo.sharePicture[0].path,
                },
                shareContent: params.pictureInfo.shareContent,
                status: true,
            };
        }
    }

    newParams.marketingScheduleId =
        (params.activityInfo?.marketingSchedule?.length &&
            params.activityInfo.marketingSchedule[0].id) ||
        null;
    return newParams;
}
