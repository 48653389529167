import { ObjectFormat } from '@comall-backend-builder/types';
import { isUndefined, isEmpty } from 'lodash';
import moment from 'moment';

/**
 * 月日范围
 *
 * 02-03 ~ 04-05
 */
export class ObjectMonthDayRangeFormat extends ObjectFormat {
    /**
     * 对数据进行校验
     */
    public validate(_rule: any, value: { from?: string; to?: string } | undefined, callback: any) {
        try {
            if (isUndefined(value) || (isEmpty(value.from) && isEmpty(value.to))) {
                callback();
                return;
            }
            if (typeof value !== 'object') {
                throw new Error('请输入正确的日期');
            }
            if (!value.from || !value.to) {
                throw new Error('日期不能为空');
            }
            const from = moment(value.from, 'MM-DD');
            const to = moment(value.to, 'MM-DD');
            if (from.isAfter(to)) {
                throw new Error('起始日期不能晚于截止日期');
            }
            callback();
        } catch (error) {
            callback(error);
        }
    }
}
