import React, { Component } from 'react';
import { Table, InputNumber, Button, message, Popconfirm, message as AntMessage } from 'antd';
import { cloneDeep, remove, find, forEach, some, get, findIndex, set } from 'lodash';
import { CouponPackageApportionPrice } from '../coupon-package-apportion-price';
import { GroupBuyingProductType } from '../group-buying-goods-selected-display';
import { services } from '@comall-backend-builder/core';

interface GroupBuyingProductSelectedDisplayProps {
    data: any[];
    onChange: (data: any[]) => void;
    modifiable: boolean;
    groupBuyingProductType?: keyof typeof GroupBuyingProductType;
}

interface GroupBuyingProductSelectedDisplayState {
    generalPrice: undefined | number;
    visible: boolean;
    settingGoods: object;
}

/**
 * 商品展示和内容编辑
 */
export class GroupBuyingProductSelectedDisplay extends Component<
    GroupBuyingProductSelectedDisplayProps,
    GroupBuyingProductSelectedDisplayState
> {
    state = {
        generalPrice: undefined,
        visible: false,
        settingGoods: {},
    };

    selectedRows: any[] = [];

    toggleModal = (good: any) => {
        const { visible } = this.state;
        this.setState({ visible: !visible, settingGoods: good });
    };

    getTableConfig = () => {
        let { modifiable, groupBuyingProductType, data = [] } = this.props;
        !groupBuyingProductType && (groupBuyingProductType = data[0]?.groupBuyingProductType);
        const hasPackage = some(
            data,
            (item) => get(item, 'virtualProductCouponDefinitionType') === 'COUPON_PACKAGE'
        );
        const columns: any[] = [
            {
                title: services.language.getText('components.Products.name'),
                dataIndex: 'productName',
                key: 'productName',
                width: 150,
                render: (i: string) => (
                    <span title={i} className="goods-field-name">
                        {i}
                    </span>
                ),
            },
            {
                title: services.language.getText('subsiteName'),
                dataIndex: 'subsiteName',
                key: 'subsiteName',
                width: 150,
                render: (i: string) => (
                    <span title={i} className="goods-field-name">
                        {i}
                    </span>
                ),
            },
            {
                title: services.language.getText('productCode'),
                dataIndex: 'productCode',
                key: 'productCode',
                width: 180,
                render: (i: string) => (
                    <span title={i} className="goods-field-name">
                        {i}
                    </span>
                ),
            },
            {
                title: services.language.getText('goodName'),
                dataIndex: 'name',
                key: 'name',
                width: 150,
                render: (i: string) => (
                    <span title={i} className="goods-field-name">
                        {i}
                    </span>
                ),
            },
            {
                title: services.language.getText('xsj'),
                dataIndex: 'salesPrice',
                key: 'salesPrice',
                width: 120,
                align: 'center',
            },
            {
                title: services.language.getText('sjkc'),
                dataIndex: 'goodsStock',
                key: 'goodsStock',
                align: 'center',
            },
            {
                title: services.language.getText('ptj'),
                dataIndex: 'generalPrice',
                key: 'generalPrice',
                align: 'center',
                // width: 150,
                render: (v: number, r: any) => {
                    return modifiable ? (
                        <InputNumber
                            value={v}
                            min={0.01}
                            placeholder={services.language.getText('ptj')}
                            onChange={(value: any) => this.changeValue('generalPrice', value, r.id)}
                        />
                    ) : (
                        v
                    );
                },
            },
        ];

        if (hasPackage) {
            columns.push({
                title: services.language.getText('ftj'),
                dataIndex: 'apportionPrice',
                key: 'apportionPrice',
                align: 'center',
                // width: 120,
                render: (i: any, record: any) => {
                    const isPackage =
                        record.virtualProductCouponDefinitionType === 'COUPON_PACKAGE';
                    const flag =
                        record.apportionPrices &&
                        record.apportionPrices.some(
                            (item: any) => !!item.apportionPrice || item.apportionPrice === 0
                        );
                    return isPackage ? (
                        <div>
                            <label>
                                <Button
                                    type="link"
                                    onClick={this.toggleModal.bind(this, record)}
                                    style={{ padding: '0' }}
                                >
                                    {flag
                                        ? services.language.getText('common.modify')
                                        : services.language.getText('settings')}
                                </Button>
                            </label>
                        </div>
                    ) : null;
                },
            });
        }

        let config: any = {
            rowKey: 'id',
            columns,
            scroll: {
                // x: groupBuyingProductType === 'COUPON' ? 1000 : 900,
                x: 1200,
                y: 500,
            },
        };
        if (modifiable) {
            config.rowSelection = {
                fixed: true,
                onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                    this.selectedRows = selectedRows;
                },
            };

            config.columns.push({
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                key: 'operation',
                fixed: 'right',
                width: 100,
                render: (i: any, row: any) => (
                    <Popconfirm
                        onConfirm={this.onRemove(row.id)}
                        title={services.language.getText('qrscztsjm')}
                        okText={services.language.getText('common.ok')}
                        cancelText={services.language.getText('common.cancel')}
                    >
                        <Button type="link">{services.language.getText('common.delete')}</Button>
                    </Popconfirm>
                ),
            });
        }
        return config;
    };

    renderFooter = () => {
        const { generalPrice } = this.state;

        return (
            <div className="product-selected-footer">
                <div className="batch-remove"></div>
                <div className="setting-item">
                    <Popconfirm
                        onConfirm={this.batchRemove()}
                        title={services.language.getText('confirmDeleteColumn')}
                        okText={services.language.getText('common.ok')}
                        cancelText={services.language.getText('common.cancel')}
                    >
                        <Button className="setting-item-ok" type="default">
                            {services.language.getText('batchDelete')}
                        </Button>
                    </Popconfirm>
                    <InputNumber
                        className="item-number"
                        style={{ width: 200 }}
                        placeholder={services.language.getText('plxgptptj')}
                        value={generalPrice}
                        min={0.01}
                        onChange={(e) => this.changeBatchInputValue('generalPrice', e)}
                    ></InputNumber>
                    <Popconfirm
                        onConfirm={this.batchChangeValue('generalPrice')}
                        title={services.language.getText('qrplxgxzdptptjm')}
                        okText={services.language.getText('common.ok')}
                        cancelText={services.language.getText('common.cancel')}
                    >
                        <Button className="setting-item-ok" type="default">
                            {services.language.getText('confirmEdit')}
                        </Button>
                    </Popconfirm>
                </div>
            </div>
        );
    };

    handleOk = (value: any) => {
        const { onChange, data } = this.props;

        const notExist = value.some(
            (item: any) => !item.apportionPrice && item.apportionPrice !== 0
        );
        if (notExist) {
            AntMessage.error(services.language.getText('yhqlbsmsqtxftj'));
        } else {
            const { settingGoods } = this.state;
            let newData = cloneDeep(data);
            const index = findIndex(newData, settingGoods);
            set(newData, `[${index}].apportionPrices`, value);
            onChange && onChange(newData);
            this.setState({ visible: false });
        }
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    onRemove = (id: string) => {
        const { data, onChange } = this.props;
        return () => {
            const result = cloneDeep(data);
            remove(result, (i) => id === i.id);
            onChange(result);
        };
    };

    batchRemove = () => {
        return () => {
            // @ts-ignore：
            const selectedRow = this.selectedRows;
            if (!selectedRow || selectedRow.length === 0) {
                message.info(services.language.getText('selectBatchDeleteColumn'));
                return;
            }
            const { data, onChange } = this.props;
            const result = cloneDeep(data);
            remove(result, (i) => !!find(selectedRow, (j) => j.id === i.id));
            onChange(result);
        };
    };

    batchChangeValueCancel = (field: string) => {
        return () => {
            // @ts-ignore：
            this.setState({ [field]: undefined });
        };
    };

    batchChangeValue = (field: string) => {
        return () => {
            // @ts-ignore：
            const fieldValue = this.state[field];
            const selectedRow = this.selectedRows;
            if (!selectedRow || selectedRow.length === 0) {
                message.info(services.language.getText('selectSetDataColumn'));
                return;
            }
            const { data, onChange } = this.props;
            const result = cloneDeep(data);
            forEach(result, (i) => {
                const item = find(selectedRow, (j) => i.id === j.id);
                item && (i[field] = fieldValue);
            });
            onChange(result);
        };
    };

    changeBatchInputValue = (field: 'generalPrice', value: any) => {
        // @ts-ignore：
        this.setState({ [field]: value });
    };

    changeValue = (field: string, value: any, id: string) => {
        const { data, onChange } = this.props;
        const result = cloneDeep(data);
        let item = find(result, (i) => id === i.id);
        if (item) {
            item[field] = value;
            onChange(result);
        }
    };

    render() {
        const { data, modifiable } = this.props;
        const { settingGoods, visible } = this.state;
        let tableConfig = this.getTableConfig();
        const tableProps = { ...tableConfig, dataSource: data };
        const hasPackage = some(
            data,
            (item) => get(item, 'virtualProductCouponDefinitionType') === 'COUPON_PACKAGE'
        );

        return (
            <div>
                <Table {...tableProps} pagination={false}></Table>
                {modifiable && this.renderFooter()}
                {hasPackage && (
                    <CouponPackageApportionPrice
                        data={[settingGoods]}
                        visible={visible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        title={services.language.getText('setSharePrice')}
                        field={'apportionPrices'}
                    />
                )}
            </div>
        );
    }
}
