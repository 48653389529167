import React, { Component, CSSProperties } from 'react';
import { DndProvider as DragDropContext, DragSource, DropTarget } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import classNames from 'classnames';
import { Col, DatePicker, Icon, message, Modal, Row } from 'antd';
import { cloneDeep } from 'lodash';
import { ImgaeUpload } from './image-upload';
import { services } from '@comall-backend-builder/core';
import { ImageAndOperatorItem } from './array-image-and-operator';
import { SubsiteLinkTypeSelector } from '../../object/subsite-link-type-selector/subsite-link-type-selector';
import './index.less';
import moment from 'moment';

let dragingIndex = -1;

export default class ImageAndOperatorDrag extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = { previewVisible: false, previewImage: '' };
    }

    componentWillReceiveProps(nextProps: any) {
        try {
            const { value, onChange, row } = this.props;
            const nextPriginalShowTime = nextProps?.row?.showTime;
            const originalShowTime = row?.showTime;

            const nextDefaultStartTime = nextPriginalShowTime?.start || null;
            const nextDefaultEndTime = nextPriginalShowTime?.end || null;
            const defaultStartTime = originalShowTime?.start || null;
            const defaultEndTime = originalShowTime?.end || null;

            if (
                onChange &&
                ((nextDefaultStartTime &&
                    defaultStartTime &&
                    nextDefaultStartTime !== defaultStartTime) ||
                    (nextDefaultEndTime && defaultEndTime && nextDefaultEndTime !== defaultEndTime))
            ) {
                if (value && value.length > 0) {
                    // 使用 date-fns 处理日期
                    const parseDate = (timeStr: string | number | Date) =>
                        timeStr ? new Date(timeStr) : null;
                    const newValue = cloneDeep(value || []);
                    newValue.forEach((item: any, index: number) => {
                        const { startTimes, endTimes } = item;
                        const defaultStart = parseDate(nextDefaultStartTime);
                        const defaultEnd = parseDate(nextDefaultEndTime);
                        if (startTimes) {
                            const startTime = parseDate(startTimes);

                            if (
                                startTime &&
                                ((defaultStart && startTime.getTime() < defaultStart.getTime()) ||
                                    (defaultEnd && startTime.getTime() > defaultEnd.getTime()))
                            ) {
                                item.startTimes = null;
                            }
                        }

                        if (endTimes) {
                            const endTime = parseDate(endTimes);
                            if (
                                endTime &&
                                ((defaultEnd && endTime.getTime() > defaultEnd.getTime()) ||
                                    (defaultStart && endTime.getTime() < defaultStart.getTime()))
                            ) {
                                item.endTimes = null;
                            }
                        }
                    });
                    onChange(newValue);
                }
            }
        } catch (error) {}
    }
    // 拖拽后更新数据
    moveRow = (dragIndex: number, hoverIndex: number) => {
        const { value, onChange } = this.props;
        if (!!value) {
            const dragRow = value[dragIndex];
            const newDataSource = update(value, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRow],
                ],
            });
            if (onChange) {
                onChange(newDataSource);
            }
        }
    };

    handlePreview = (path: string) => {
        this.setState({
            previewVisible: true,
            previewImage: path,
        });
    };

    handleCancel = () => {
        this.setState({
            previewVisible: false,
        });
    };

    handleChangeItem = (index: number, item: ImageAndOperatorItem) => {
        const { value, onChange } = this.props;
        if (value && value.length > 0) {
            const newValue = cloneDeep(value || []);
            newValue[index] = item;
            if (onChange) {
                onChange(newValue);
            }
        }
    };
    handleDeleteItem = (index: number) => {
        const { value, onChange } = this.props;
        if (value && value.length > 0) {
            const newValue = cloneDeep(value || []);
            newValue.splice(index, 1);
            if (onChange) {
                onChange(newValue);
            }
        }
    };
    render() {
        const { value = [], children, fileName, accept, uploadUrl, mode } = this.props;
        const { previewVisible, previewImage } = this.state;
        const uploadParam = {
            name: fileName || 'fileName',
            accept: accept || 'image/*',
            action: uploadUrl,
            listType: mode || 'picture-card',
        };
        return (
            <div>
                <DragDropContext backend={HTML5Backend}>
                    {value.map((item: any, index: number) => {
                        return (
                            <ImageAndOperatorDragItemTarget
                                item={item}
                                index={index}
                                uploadParam={uploadParam}
                                moveRow={this.moveRow}
                                handlePreview={this.handlePreview}
                                handleChangeItem={this.handleChangeItem}
                                handleDeleteItem={this.handleDeleteItem}
                                original={this.props}
                            />
                        );
                    })}
                    {children ? children : null}
                </DragDropContext>
                <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                    <img alt="preview" style={{ width: '100%' }} src={previewImage} />
                </Modal>
            </div>
        );
    }
}

class ImageAndOperatorDragItem extends Component<any, any> {
    onChangeImage = (pic: any) => {
        const { item, index, handleChangeItem } = this.props;
        if (handleChangeItem) {
            handleChangeItem(index, {
                ...item,
                pic: pic,
            });
        }
    };
    onChangeData = (date: any, type: string) => {
        const { handleChangeItem } = this.props;
        if (handleChangeItem) {
            // 处理 startTimes
            if (type === 'startTimes') {
                this.handleDateChange(date, 'startTimes');
            }

            // 处理 endTimes
            if (type === 'endTimes') {
                this.handleDateChange(date, 'endTimes');
            }
        }
    };

    // 抽象出处理日期变化的函数
    handleDateChange = (date: any, type: 'startTimes' | 'endTimes') => {
        const { item, index, handleChangeItem, original } = this.props;
        const defaultEndTime = original?.row?.showTime?.end || null;
        const defaultStartTime = original?.row?.showTime?.start;

        if (date) {
            let formattedDate = moment(date).format('YYYY-MM-DD HH:mm:ss');
            let isDateValid = true;

            if (type === 'startTimes' && item.endTimes) {
                isDateValid =
                    new Date(formattedDate).getTime() <= new Date(item.endTimes).getTime();
            } else if (type === 'endTimes' && item.startTimes) {
                isDateValid =
                    new Date(formattedDate).getTime() >= new Date(item.startTimes).getTime();
            }

            if (isDateValid) {
                let newItem = { ...item };

                if (type === 'startTimes') {
                    if (new Date(date).getTime() <= new Date(defaultStartTime).getTime()) {
                        newItem.startTimes = moment(defaultStartTime).format('YYYY-MM-DD HH:mm:ss');
                    } else if (
                        new Date(date).getTime() >=
                        new Date(newItem.endTimes || defaultEndTime).getTime()
                    ) {
                        newItem.startTimes = moment(newItem.endTimes || defaultEndTime).format(
                            'YYYY-MM-DD HH:mm:ss'
                        );
                    } else {
                        newItem.startTimes = formattedDate;
                    }
                    newItem.isStartNewValue = false;
                } else if (type === 'endTimes') {
                    if (new Date(date).getTime() >= new Date(defaultEndTime).getTime()) {
                        newItem.endTimes = moment(defaultEndTime).format('YYYY-MM-DD HH:mm:ss');
                    } else if (
                        new Date(date).getTime() <=
                        new Date(newItem.startTimes || defaultStartTime).getTime()
                    ) {
                        newItem.endTimes = moment(newItem.startTimes || defaultStartTime).format(
                            'YYYY-MM-DD HH:mm:ss'
                        );
                    } else {
                        newItem.endTimes = formattedDate;
                    }
                    newItem.isEndNewValue = false;
                }

                handleChangeItem(index, newItem);
            } else {
                const errorMsg = type === 'startTimes' ? 'kssjdyjssj' : 'kssjxyjssj';
                message.error(services.language.getText(errorMsg));
            }
        } else {
            let newItem = { ...item };

            if (type === 'startTimes') {
                newItem.startTimes = null;
                newItem.isStartNewValue = false;
            } else if (type === 'endTimes') {
                newItem.endTimes = null;
                newItem.isEndNewValue = false;
            }

            handleChangeItem(index, newItem);
        }
    };
    onDeleteImage = () => {
        const { item, index, handleChangeItem } = this.props;
        if (handleChangeItem) {
            handleChangeItem(index, {
                ...item,
                pic: undefined,
            });
        }
    };
    onChangeLink = (link: any, other: any) => {
        const { item, index, handleChangeItem } = this.props;
        if (handleChangeItem) {
            handleChangeItem(index, {
                ...item,
                operator: link,
            });
        }
    };
    renderLeft = () => {
        const { item, handlePreview, uploadParam } = this.props;
        const iconStyle = {
            color: '#FFFFFF',
            marginLeft: '5px',
            cursor: 'pointer',
        };
        const { pic } = item;
        const onPreview = handlePreview.bind(this, pic?.path);
        return (
            <div className={classes.left}>
                {pic && pic.id && pic.path ? (
                    <div
                        className={classes.leftPic}
                        style={{
                            backgroundImage: `url(${pic.path})`,
                        }}
                    >
                        <div className={classes.leftPicMask}>
                            <Icon type="eye" style={iconStyle} onClick={onPreview} />
                            <Icon type="delete" style={iconStyle} onClick={this.onDeleteImage} />
                        </div>
                    </div>
                ) : (
                    <ImgaeUpload uploadParam={uploadParam} onChange={this.onChangeImage} />
                )}
            </div>
        );
    };
    disabledDate = (current: any) => {
        const { item } = this.props;
        const defaultStartTime = this.props?.original?.row?.showTime?.start;
        const defaultEndTime = item.endTimes || this.props?.original?.row?.showTime?.end;
        return this.getDisabledDate(current, defaultStartTime, defaultEndTime);
    };
    disabledDates = (current: any) => {
        const { item } = this.props;
        const defaultStartTime = item.startTimes || this.props?.original?.row?.showTime?.start;
        const defaultEndTime = this.props?.original?.row?.showTime?.end;
        return this.getDisabledDate(current, defaultStartTime, defaultEndTime);
    };

    // 抽象出处理日期变化的函数
    getDisabledDate = (current: any, defaultStartTime: any, defaultEndTime: any) => {
        if (!current || !defaultStartTime || !defaultEndTime) {
            return false;
        }
        return (
            current &&
            (current <
                moment(defaultStartTime)
                    .subtract(1, 'days')
                    .endOf('day') ||
                current > moment(defaultEndTime).endOf('day'))
        );
    };

    renderTarget = () => {
        const { isOver, index, original, item, handleDeleteItem } = this.props;
        const itemClass = classNames(prefix, {
            'drop-over-downward': isOver && index > dragingIndex,
            'drop-over-upward': isOver && index < dragingIndex,
        });
        const { operator, startTimes, endTimes, isEndNewValue, isStartNewValue } = item;
        const iconStyle = {
            fontSize: '18px',
        };
        const onDelete = handleDeleteItem.bind(this, index);
        const defaultStartTime = this.props?.original?.row?.showTime?.start || null;
        const defaultEndTime = this.props?.original?.row?.showTime?.end || null;
        const startDisabled = Boolean(startTimes || defaultStartTime);
        const endDisabled = Boolean(endTimes || defaultEndTime);
        const style: CSSProperties = {
            backgroundColor: '#fff',
            marginBottom: '10px',
        };
        return (
            <div style={style}>
                <div className={itemClass}>
                    {this.renderLeft()}
                    <div>
                        <SubsiteLinkTypeSelector
                            {...original}
                            value={operator}
                            onChange={this.onChangeLink}
                        ></SubsiteLinkTypeSelector>
                    </div>
                    <Icon
                        className={classes.close}
                        type="close-circle"
                        theme="filled"
                        style={iconStyle}
                        onClick={onDelete}
                    />
                </div>
                <Row>
                    <Col span={4}>
                        &nbsp;&nbsp;&nbsp;{services.language.getText('effectiveTime')}
                    </Col>
                    <Col span={20}>
                        <DatePicker
                            disabled={!startDisabled}
                            disabledDate={this.disabledDate}
                            value={
                                startTimes
                                    ? moment(startTimes)
                                    : defaultStartTime && isStartNewValue
                                    ? moment(defaultStartTime)
                                    : null
                            }
                            format="YYYY-MM-DD HH:mm:ss"
                            showTime
                            onChange={(e) => {
                                this.onChangeData(e, 'startTimes');
                            }}
                            placeholder={services.language.getText('selectStartTime')}
                        />
                        -
                        <DatePicker
                            disabled={!endDisabled}
                            disabledDate={this.disabledDates}
                            value={
                                endTimes
                                    ? moment(endTimes)
                                    : defaultEndTime && isEndNewValue
                                    ? moment(defaultEndTime)
                                    : null
                            }
                            format="YYYY-MM-DD HH:mm:ss"
                            showTime
                            onChange={(e) => {
                                this.onChangeData(e, 'endTimes');
                            }}
                            placeholder={services.language.getText('selectEndTime')}
                        />
                    </Col>
                </Row>
            </div>
        );
    };
    render() {
        const { connectDragSource, connectDropTarget } = this.props;
        return connectDragSource(connectDropTarget(this.renderTarget()));
    }
}
const previewTarget = {
    drop(props: any, monitor: any) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;
        if (dragIndex === hoverIndex) {
            return;
        }
        props.moveRow(dragIndex, hoverIndex);

        monitor.getItem().index = hoverIndex;
    },
};
const previewSource = {
    beginDrag(props: any) {
        dragingIndex = props.index;
        return {
            index: props.index,
        };
    },
};
const ImageAndOperatorDragItemTarget = DropTarget(
    'ImageAndOperatorDrag',
    previewTarget,
    (connect, monitor) => ({
        connectDropTarget: connect.dropTarget(),
        isOver: monitor.isOver(),
    })
)(
    DragSource('ImageAndOperatorDrag', previewSource, (connect) => ({
        connectDragSource: connect.dragSource(),
    }))(ImageAndOperatorDragItem)
);

const prefix = 'image-and-operator-target';
const classes = {
    left: `${prefix}__left`,
    leftPic: `${prefix}__left__pic`,
    leftPicMask: `${prefix}__left__pic-mask`,
    close: `${prefix}__close`,
};
