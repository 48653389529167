import React, { PureComponent } from 'react';
import { Checkbox, Button, Input, Radio, Select, Modal, message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { get, find, cloneDeep } from 'lodash';
import { Textbox } from '@comall-backend-builder/components-basis';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
const api = services.api;

export enum MaotaiMessageTypes {
    /**
     * 小程序订阅
     */
    MINIPROGRAM_SUBSCRIPTION = 'MINIPROGRAM_SUBSCRIPTION',
    /**
     * WECHAT_TEMPLATE
     */
    WECHAT_TEMPLATE = 'WECHAT_TEMPLATE',
    /**
     * MESSAGE
     */
    MESSAGE = 'MESSAGE',
}

export interface MaotaiDrawMessageTypeProps {
    name: string;
    value: any[];
    onChange: (value: any[]) => void;
    subsites: any[];
}

export interface MaotaiDrawMessageTypeState {
    visible: boolean;
    subsiteId: undefined | string;
    data: any;
    templateList: any[];
}
export class MaotaiDrawMessageType extends PureComponent<
    MaotaiDrawMessageTypeProps,
    MaotaiDrawMessageTypeState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
            subsiteId: undefined,
            data: null,
            templateList: [],
        };
    }

    static defaultProps = {
        value: [],
        template: null,
        subsiteId: null,
    };

    shouldComponentUpdate(nextProps: any, state: any) {
        let subsiteId = get(nextProps, 'subsites[0].id');
        let lastSubsiteId = state.subsiteId;
        if (subsiteId) {
            subsiteId = parseInt(subsiteId);
        }
        if (lastSubsiteId) {
            lastSubsiteId = parseInt(lastSubsiteId);
        }

        if (subsiteId !== lastSubsiteId) {
            if (subsiteId) {
                this.setState({
                    subsiteId,
                });
                //  切换了门店后  需要覆盖默认模板。 初始化除外
                const isCover = typeof subsiteId === 'number' && typeof lastSubsiteId === 'number';

                this.getTemplates(subsiteId, isCover);
            }
        }

        return true;
    }

    getTemplates = (subsiteId: number, isCover: boolean) => {
        api.get(
            { scene: 'MAOTAI_ACTIVITY_DRAW_REWARD', page: 1, perPage: 10, subsiteId },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                apiPath: `/admin/wechat_templates`,
            }
        ).then((res: any) => {
            this.setState(
                {
                    templateList: res.result,
                },
                () => {
                    this.transformData(isCover);
                }
            );
        });
    };

    transformData = (isCover: boolean) => {
        const { value } = this.props;
        const { templateList } = this.state;
        if (!templateList.length) {
            this.setState({ data: null });
            return;
        }
        const defaultTemplate = templateList[0];
        const templateItem = value.find((i) => i.type === MaotaiMessageTypes.WECHAT_TEMPLATE);

        if (isCover) {
            // 使用模板默认数据
            this.transformDefaultData(defaultTemplate);
        } else if (get(templateItem, 'data')) {
            // 使用详情页返回的数据
            this.combineData(defaultTemplate, templateItem.data);
        } else {
            // 使用模板默认数据
            this.transformDefaultData(defaultTemplate);
        }
    };

    combineData = (defaultTemplate: any, sourceData: any) => {
        const data: any = cloneDeep(sourceData);
        const { templateList } = this.state;
        const { value, onChange } = this.props;
        if (!templateList.length) {
            this.setState({ data: null });
            return;
        }
        data.id = defaultTemplate.id;
        data.name = defaultTemplate.name;
        data.sceneRemark = defaultTemplate.sceneRemark;
        data.code = defaultTemplate.code;
        data.hTargetUrl = '';
        data.miniTargetUrl = '0';
        data.otherTargetUrl = '';
        if (data.targetEnum === 'H5') {
            data.hTargetUrl = data.targetUrl;
        }
        if (data.targetEnum === 'SELF_MINI_PROGRAM') {
            data.miniTargetUrl = data.targetUrl;
        }
        if (data.targetEnum === 'OTHER_MINI_PROGRAM') {
            data.otherTargetUrl = data.targetUrl;
        }

        const list = value.map((i) => {
            if (i.type === MaotaiMessageTypes.WECHAT_TEMPLATE) {
                return { ...i, data };
            }
            return i;
        });
        onChange(list);
        this.setState(
            {
                data,
            },
            () => {
                this.forceUpdate();
            }
        );
    };

    transformDefaultData = (sourceData: any) => {
        const data: any = cloneDeep(sourceData);
        const top = find(data.params, { name: 'top' });
        const bottom = find(data.params, { name: 'bottom' });
        const { value, onChange } = this.props;
        if (top) {
            data.top = top.value;
        }
        if (bottom) {
            data.bottom = bottom.value;
        }
        data.hTargetUrl = '';
        data.miniTargetUrl = '0';
        data.otherTargetUrl = '';
        if (data.targetEnum === 'H5') {
            data.hTargetUrl = data.targetUrl;
        }
        if (data.targetEnum === 'SELF_MINI_PROGRAM') {
            data.miniTargetUrl = data.targetUrl;
        }
        if (data.targetEnum === 'OTHER_MINI_PROGRAM') {
            data.otherTargetUrl = data.targetUrl;
        }
        //没有参数时，默认链接目标，我的中签页
        if (!data.targetEnum) {
            data.targetEnum = 'SELF_MINI_PROGRAM';
            data.miniTargetUrl = 'subpackages/maotai-pre-sale/pages/result-list/index';
        }
        const list = value.map((i) => {
            if (i.type === MaotaiMessageTypes.WECHAT_TEMPLATE) {
                return { ...i, data };
            }
            return i;
        });
        onChange(list);
        this.setState(
            {
                data,
            },
            () => {
                this.forceUpdate();
            }
        );
    };

    onSelect = (e: any) => {
        const {
            target: { value: checkedValue, checked },
        } = e;

        const { value, onChange } = this.props;
        const list = value.map((i) => {
            if (i.type === checkedValue) {
                return { ...i, checked };
            }
            return i;
        });
        onChange(list);
    };

    handleChange = (key: string, val: string) => {
        let { data } = this.state;
        data[key] = val;
        this.setState(
            {
                data: data,
            },
            () => {
                this.forceUpdate();
            }
        );
    };
    validate = () => {
        const { data } = this.state;
        // if (!data.top) {
        //     message.warning('请填写模板头部信息');
        //     return false;
        // }
        // if (!data.bottom) {
        //     message.warning('请填写模板尾部信息');
        //     return false;
        // }

        if (data.targetEnum === 'SELF_MINI_PROGRAM') {
            if (!data.miniTargetUrl || data.miniTargetUrl === '0') {
                message.warning(services.language.getText('selectLinkTarget'));
                return false;
            }
        }
        if (data.targetEnum === 'OTHER_MINI_PROGRAM') {
            if (!data.targetAppId) {
                message.warning(language.getText('fillInAppid'));
                return false;
            }
            if (!data.otherTargetUrl) {
                message.warning(language.getText('qtxymlj'));
                return false;
            }
        }
        return true;
    };

    handleSubmit = () => {
        const hasValidate = this.validate();
        const { data } = this.state;
        const { value, onChange } = this.props;
        if (hasValidate) {
            const list = value.map((i) => {
                if (i.type === MaotaiMessageTypes.WECHAT_TEMPLATE) {
                    return { ...i, data };
                }
                return i;
            });
            onChange(list);
            this.handleVisible();
        }
    };

    handleVisible = () => {
        const { visible } = this.state;
        this.setState({
            visible: !visible,
        });
    };

    onHandleTextBoxChange(type: string, valueString: any) {
        let { data } = this.state;
        data[type] = valueString;
        this.setState(
            {
                data: data,
            },
            () => {
                this.forceUpdate();
            }
        );
        return {};
    }

    renderModal = () => {
        const { visible, data } = this.state;
        return (
            data && (
                <Modal
                    destroyOnClose
                    visible={visible}
                    title={language.getText('bjmbxx')}
                    width="800px"
                    onCancel={this.handleVisible}
                    onOk={this.handleSubmit}
                >
                    <div className="wechat-templates-find-list-item-edit-view__flex">
                        <div className="wechat-templates-find-list-item-edit-view__title">
                            {language.getText('mbmc')}：
                        </div>
                        <div className="wechat-templates-find-list-item-edit-view__value">
                            {data.name}
                        </div>
                    </div>
                    <div className="wechat-templates-find-list-item-edit-view__flex">
                        <div className="wechat-templates-find-list-item-edit-view__title">
                            {language.getText('mbtbxx')}：
                        </div>
                        <div className="wechat-templates-find-list-item-edit-view__value">
                            <Textbox
                                prefixIcon=""
                                suffixIcon=""
                                type="textarea"
                                autoSize={{ minRows: 5, maxRows: 6 }}
                                name="textBox"
                                placeholder={services.language.getText('inputPlease')}
                                value={data.top}
                                onChange={(value) => this.onHandleTextBoxChange('top', value)}
                                maxLength={50}
                            />
                        </div>
                    </div>
                    <div className="wechat-templates-find-list-item-edit-view__flex">
                        <div className="wechat-templates-find-list-item-edit-view__title">
                            {language.getText('mbwbxx')}：
                        </div>
                        <div className="wechat-templates-find-list-item-edit-view__value">
                            <Textbox
                                prefixIcon=""
                                suffixIcon=""
                                type="textarea"
                                autoSize={{ minRows: 5, maxRows: 6 }}
                                name="textBox"
                                placeholder={services.language.getText('inputPlease')}
                                value={data.bottom}
                                onChange={(value) => this.onHandleTextBoxChange('bottom', value)}
                                maxLength={50}
                            />
                        </div>
                    </div>
                    <div className="wechat-templates-find-list-item-edit-view__flex">
                        <div className="wechat-templates-find-list-item-edit-view__title">
                            {language.getText('ljlx')}：
                        </div>
                        <div className="wechat-templates-find-list-item-edit-view__value">
                            <Radio.Group
                                value={data.targetEnum}
                                defaultValue={'H5'}
                                onChange={(e) => {
                                    this.handleChange('targetEnum', e.target.value);
                                }}
                            >
                                <Radio value={'H5'}>{language.getText('urlAddress')}</Radio>
                                <Radio value={'SELF_MINI_PROGRAM'}>
                                    {language.getText('ljmb')}
                                </Radio>
                                <Radio value={'OTHER_MINI_PROGRAM'}>
                                    {language.getText('tfxcx')}
                                </Radio>
                            </Radio.Group>
                        </div>
                    </div>
                    {data.targetEnum === 'H5' && (
                        <div className="wechat-templates-find-list-item-edit-view__flex">
                            <div className="wechat-templates-find-list-item-edit-view__title">
                                {language.getText('ljdz')}：
                            </div>
                            <div className="wechat-templates-find-list-item-edit-view__value">
                                <Input
                                    style={{ width: '300px' }}
                                    value={data.hTargetUrl}
                                    onChange={(e) =>
                                        this.handleChange('hTargetUrl', e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    )}
                    {data.targetEnum === 'SELF_MINI_PROGRAM' && (
                        <div className="wechat-templates-find-list-item-edit-view__flex">
                            <div className="wechat-templates-find-list-item-edit-view__title">
                                {language.getText('ljmb')}：
                            </div>
                            <div className="wechat-templates-find-list-item-edit-view__value">
                                <Select
                                    className="trans-action-selection"
                                    style={{ width: '130px' }}
                                    value={data.miniTargetUrl}
                                    placeholder={services.language.getText('selectPlease')}
                                    onChange={(transAction: any) =>
                                        this.handleChange('miniTargetUrl', transAction)
                                    }
                                >
                                    <Select.Option key="0">
                                        {language.getText('selectPlease')}
                                    </Select.Option>
                                    <Select.Option key="subpackages/maotai-pre-sale/pages/result-list/index">
                                        {language.getText('wdzqy')}
                                    </Select.Option>
                                </Select>
                            </div>
                        </div>
                    )}
                    {data.targetEnum === 'OTHER_MINI_PROGRAM' && (
                        <div className="wechat-templates-find-list-item-edit-view__flex">
                            <div className="wechat-templates-find-list-item-edit-view__title">
                                appid：
                            </div>
                            <div className="wechat-templates-find-list-item-edit-view__value">
                                <Input
                                    style={{ width: '300px' }}
                                    value={data.targetAppId}
                                    onChange={(e) =>
                                        this.handleChange('targetAppId', e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    )}
                    {data.targetEnum === 'OTHER_MINI_PROGRAM' && (
                        <div className="wechat-templates-find-list-item-edit-view__flex">
                            <div className="wechat-templates-find-list-item-edit-view__title">
                                {language.getText('ymlj')}：
                            </div>
                            <div className="wechat-templates-find-list-item-edit-view__value">
                                <Input
                                    style={{ width: '300px' }}
                                    value={data.otherTargetUrl || ''}
                                    onChange={(e) =>
                                        this.handleChange('otherTargetUrl', e.target.value)
                                    }
                                />
                            </div>
                        </div>
                    )}
                </Modal>
            )
        );
    };

    onEdit = () => {
        this.setState({
            visible: true,
        });
    };

    render() {
        const { value } = this.props;
        const { templateList } = this.state;
        const checkedMiniprogram = get(
            value.find((i) => i.type === MaotaiMessageTypes.MINIPROGRAM_SUBSCRIPTION),
            'checked'
        );
        const wechatTemplateData = get(
            value.find((i) => i.type === MaotaiMessageTypes.WECHAT_TEMPLATE),
            'data'
        );
        const checkedWechatTemplate = get(
            value.find((i) => i.type === MaotaiMessageTypes.WECHAT_TEMPLATE),
            'checked'
        );
        const checkedMessage = get(
            value.find((i) => i.type === MaotaiMessageTypes.MESSAGE),
            'checked'
        );

        return (
            <div className="maotai-presale-message-type">
                <div>
                    <span>{language.getText('maotaiMessageTitle')}</span>
                </div>
                <div className="maotai-presale-message-type-row">
                    <div className="maotai-presale-message-type-item">
                        <div className="maotai-presale-message-type-item-title">
                            <div>
                                <Checkbox
                                    checked={checkedMiniprogram}
                                    onChange={this.onSelect}
                                    value={MaotaiMessageTypes.MINIPROGRAM_SUBSCRIPTION}
                                ></Checkbox>
                                &nbsp;
                                <span>{language.getText('xcxdyxx')}</span>
                            </div>
                        </div>
                        <div className="maotai-presale-message-type-detail">
                            <div>
                                <div>{language.getText('maotaiMessageName')}</div>
                                <div>{language.getText('xtwccqhfsyxxx')}</div>
                            </div>
                            <div className="maotai-presale-message-type-line"></div>
                            <div>
                                <div>{language.getText('maotaiMessageActivity')}</div>
                                <div>{language.getText('maotaiMessageResult')}</div>
                                <div>{language.getText('maotaiMessageSubsite')}</div>
                                {/* <div>中签码：2xxcfA</div> */}
                                <div>{services.language.getText('wxtsqxdsfzrnddbuy')}</div>
                            </div>
                            <div className="maotai-presale-message-type-line"></div>
                            <div>
                                <div>
                                    {language.getText('xqtz')}：
                                    subpackages/maotai-pre-sale/pages/result-list/index
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="maotai-presale-message-type-item">
                        <div className="maotai-presale-message-type-item-title">
                            <div>
                                <Checkbox
                                    disabled={!templateList.length}
                                    checked={checkedWechatTemplate}
                                    onChange={this.onSelect}
                                    value={MaotaiMessageTypes.WECHAT_TEMPLATE}
                                ></Checkbox>
                                &nbsp;
                                <span>{language.getText('gzhmbxx')}</span>
                            </div>
                            <Button
                                className="maotai-presale-message-type-item-title-btn"
                                onClick={this.onEdit}
                                disabled={!templateList.length}
                                type="link"
                            >
                                {language.getText('common.edit')}
                            </Button>
                        </div>
                        <div className="maotai-presale-message-type-detail">
                            <div>
                                {language.getText('mc')}：{get(wechatTemplateData, 'name')}{' '}
                            </div>
                            <div>
                                {language.getText('bh_1')}：{get(wechatTemplateData, 'code')}{' '}
                            </div>
                            <div>{get(wechatTemplateData, 'sceneRemark')}</div>
                            <div className="maotai-presale-message-type-line"></div>
                            <div>
                                <div>{get(wechatTemplateData, 'top')}</div>
                                <div>{language.getText('maotaiMessageCp')}</div>
                                <div>{language.getText('maotaiMessageSuccess')}</div>
                                <div>{language.getText('maotaiMessageTime')}</div>
                                <div> {get(wechatTemplateData, 'bottom')}</div>
                            </div>
                            <div className="maotai-presale-message-type-line"></div>
                            <div>
                                <div>
                                    {language.getText('xqtz')}：
                                    {get(wechatTemplateData, 'targetEnum') === 'H5' &&
                                        get(wechatTemplateData, 'hTargetUrl')}
                                    {get(wechatTemplateData, 'targetEnum') ===
                                        'SELF_MINI_PROGRAM' &&
                                        get(wechatTemplateData, 'miniTargetUrl')}
                                    {get(wechatTemplateData, 'targetEnum') ===
                                        'OTHER_MINI_PROGRAM' &&
                                        get(wechatTemplateData, 'otherTargetUrl')}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="maotai-presale-message-type-item">
                        <div className="maotai-presale-message-type-item-title">
                            <div>
                                <Checkbox
                                    onChange={this.onSelect}
                                    value={MaotaiMessageTypes.MESSAGE}
                                    checked={checkedMessage}
                                ></Checkbox>
                                &nbsp;
                                <span>{language.getText('dxtz')}</span>
                            </div>
                        </div>
                        <div className="maotai-presale-message-type-detail">
                            <div>{services.language.getText('rnqwsgSuccess')}</div>
                            <div className="maotai-presale-message-type-line"></div>
                            <div className="maotai-presale-message-type-tips">
                                {language.getText('dxwgdddxwa')}
                            </div>
                        </div>
                    </div>
                </div>
                {this.renderModal()}
            </div>
        );
    }
}
