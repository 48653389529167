import React, { PureComponent, createElement, Fragment } from 'react';
// import { api } from '@comall-backend-builder/core/lib/services';
import { errorHandle } from '../../applications/cae/services/error-handle';
import PropTypes from 'prop-types';
import { ComponentsManager } from '@comall-backend-builder/core';
import { connect } from 'react-redux';
import { message as AntMessage, Modal, Form, InputNumber, DatePicker } from 'antd';
import { debounce, get } from 'lodash';
import moment from 'moment';
import './index.less';
import { api, language } from '@comall-backend-builder/core/lib/services';
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};
const ConsumeActivityForm = Form.create({ name: 'review' })(
    class extends React.Component {
        static propTypes: {
            waitConsumeQuantity: any;
            onSubmit: PropTypes.Requireable<(...args: any[]) => any>;
        };
        handleReset = () => {
            const { form } = this.props as any;
            form.resetFields();
        };
        handleSubmit = (e: any) => {
            const { form, onSubmit } = this.props as any;
            form.validateFields((err: any, values: any) => {
                onSubmit(values);
            });
        };
        render() {
            const { form, waitConsumeQuantity } = this.props as any;
            const { getFieldDecorator } = form;
            return (
                <Form ref="reviewFrom" {...formItemLayout} onSubmit={this.handleSubmit}>
                    <FormItem label={'核销数量'}>
                        {getFieldDecorator('waitConsumeQuantity', {
                            initialValue: waitConsumeQuantity || 1,
                            rules: [
                                { required: true, message: '请输入核销数量' },
                                {
                                    validator: (rule: any, value: any, callback: any) => {
                                        if (value && value > 0) {
                                            callback();
                                        } else {
                                            callback('请输入正整数');
                                        }
                                    },
                                },
                            ],
                        })(<InputNumber />)}
                    </FormItem>
                    <FormItem label={'核销时间'}>
                        {getFieldDecorator('consumeTime', {
                            initialValue: moment(new Date(), 'YYYY-MM-DD HH:mm:ss'),
                            rules: [{ required: true, message: '请选择日期' }],
                        })(
                            <DatePicker
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                                placeholder="选择日期"
                            />
                        )}
                    </FormItem>
                </Form>
            );
        }
    }
);

class ConsumeActivityView extends PureComponent<any> {
    state = { visible: false, reviewInfo: {} };
    openModal = () => {
        this.setState({ visible: true });
        this.loadData();
    };

    loadData = async () => {
        const { id } = this.props.row;
        try {
            let reviewInfo: any = await api.get(
                {},
                {
                    apiPath: `/admin/mall_activities/registration_records/${id}/review`,
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
                }
            );
            if (get(reviewInfo, 'memberRegistration.joinDayStartTime')) {
                const startTime = reviewInfo.memberRegistration.joinDayStartTime.split(':');
                reviewInfo.memberRegistration.startTime = startTime[0] + ':' + startTime[1];
            }

            if (get(reviewInfo, 'memberRegistration.joinDayEndTime')) {
                const endTime = reviewInfo.memberRegistration.joinDayEndTime.split(':');
                reviewInfo.memberRegistration.endTime = endTime[0] + ':' + endTime[1];
            }
            this.setState({ reviewInfo });
        } catch (error) {
            errorHandle(error);
            throw error;
        }
    };
    onSubmit = debounce(async (value: any) => {
        const { subsite } = this.props.row;
        const { reviewInfo } = this.state;
        const { entity, params: entityParams } = this.props;
        const subsiteId = subsite.id;
        // const merchantId = subsite.mchId;
        const consumeTime = value['consumeTime'].format('YYYY-MM-DD HH:mm:ss');
        const param = {
            couponDefinitionId: get(reviewInfo, 'couponDefinitionId'),
            consumeTime: consumeTime,
            orderId: get(reviewInfo, 'orderId'),
            type: 'MALL_ACTIVITY_NEW',
            quantity: value.waitConsumeQuantity,
            consumeType: 'MINI_PROGRAM_GUIDE',
            subsiteId,
            // merchantId,
        };
        try {
            await api.post(
                { ...param },
                {
                    apiPath: `/admin/v2/consume_orders`,
                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                }
            );
        } catch (error) {
            errorHandle(error);
            throw error;
        }
        AntMessage.success(language.getText('actionSuccess'));
        entity && entity.search(entityParams);
        this.listenRef.handleReset();
        this.setState({ visible: false });
    }, 500);
    //@ts-ignore
    listenRef: {
        handleSubmit: () => Promise<any>;
        handleReset: () => Promise<any>;
    };
    render() {
        const { triggerComponent, row } = this.props;
        const { reviewInfo, visible } = this.state;
        const buttonProps = {
            ...triggerComponent,
            onClick: this.openModal,
        };

        return (
            <Fragment>
                {row.status === 'AUDIT_APPROVED'
                    ? createElement(ComponentsManager.get('Button'), buttonProps)
                    : null}
                <Modal
                    visible={visible}
                    className="consume-activity-view"
                    title="核销活动"
                    mask={false}
                    maskClosable
                    okText={language.getText('common.ok')}
                    cancelText={language.getText('common.cancel')}
                    width="580px"
                    onOk={() => {
                        this.listenRef.handleSubmit();
                    }}
                    onCancel={() => {
                        this.listenRef.handleReset();
                        this.setState({ visible: false });
                    }}
                >
                    <div className="between row">
                        <span>下单人手机号：{get(reviewInfo, 'mobile') || ''}</span>
                    </div>
                    <div className="between row">
                        <span>
                            参与人手机号：{get(reviewInfo, 'memberRegistration.mobile') || ''}
                        </span>
                        <span>参与人姓名：{get(reviewInfo, 'memberRegistration.name') || ''}</span>
                    </div>
                    <div className="between row">
                        <span>报名人数：{get(row, 'quantity') || ''}</span>
                        {/* <span>可核销人数：{get(row, 'waitConsumeQuantity') || ''}</span> */}
                    </div>

                    <div className="row">
                        参与时间：{get(reviewInfo, 'memberRegistration.joinDate') || ''}{' '}
                        {get(reviewInfo, 'memberRegistration.startTime') || ''}-
                        {get(reviewInfo, 'memberRegistration.endTime') || ''}
                    </div>
                    <ConsumeActivityForm
                        wrappedComponentRef={(ref: any) => (this.listenRef = ref)}
                        onSubmit={this.onSubmit}
                        waitConsumeQuantity={get(row, 'waitConsumeQuantity')}
                    />
                </Modal>
            </Fragment>
        );
    }
}

function mapStateToProps(_state: any, props: any) {
    const { entity } = props;
    return { fields: entity.fields, entity };
}
export const ConsumeActivityButton = connect(mapStateToProps, null)(ConsumeActivityView);
