import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { remove, forEach, map, find, get } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Modal } from 'antd';

export interface BalanceCoupon {
    code: string;
    name: string;
    select: boolean;
    delete: boolean;
}

const TableComponentId = 'BalanceCouponSelectorTable';
let Entity: any = {};
let entity: any = {};
/**
 * 选择余额券
 */
class balanceCouponSelector extends PureComponent<{
    selectValues: BalanceCoupon[];
    onChange: (brands: BalanceCoupon[]) => void;
    requestStatus: string;
    params?: any;
    type?: 'checkbox' | 'radio';
}> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('ExternalCouponSelector');
        entity = new Entity({});
    }
    dispatch: any = {};
    state = {
        visible: false,
    };

    selectedRows = new Array<BalanceCoupon>();

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(entity));
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.requestStatus === 'success' && this.props.requestStatus === 'pending') {
            const selectedRows = this.selectedRows;
            if (selectedRows && selectedRows.length > 0) {
                setTimeout(() => {
                    this.dispatch(
                        actions.tableRowSelectionChangeAction(
                            TableComponentId,
                            map(selectedRows, (value) => {
                                return value.code + '';
                            })
                        )
                    );
                }, 300);
            } else {
                this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
            }
        }
    }

    onTableRowSelect = (record: BalanceCoupon, selected: boolean) => {
        const { type } = this.props;
        if (selected) {
            if ('radio' === type) {
                this.selectedRows = [record];
            } else {
                if (find(this.selectedRows, { code: record.code })) {
                    return;
                }
                this.selectedRows.push(record);
            }
        } else {
            remove(this.selectedRows, (i) => {
                return i.code === record.code;
            });
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: BalanceCoupon[], unSelectedRows: any) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            map(unSelectedRows, (row) => {
                remove(this.selectedRows, (i) => {
                    return i.code === row.code;
                });
            });
        }
    };

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
    };

    onOk = () => {
        const { onChange } = this.props;
        if (onChange) {
            forEach(this.selectedRows, (brand) => {
                brand.select = true;
            });

            onChange(this.selectedRows);
            this.toggleModal();
        }
    };

    getConfig = () => {
        const { selectValues } = this.props;

        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            items: [
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    pagination: false,
                    scroll: {
                        y: 400,
                    },
                    rowSelection: {
                        type: 'checkbox',
                        selectedRowKeys: map(selectValues, (value) => {
                            return value.code + '';
                        }),
                        onSelect: (record: BalanceCoupon, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (
                            selected: boolean,
                            rows: BalanceCoupon[],
                            unSelectedRows: BalanceCoupon[]
                        ) => {
                            this.onTableRowSelectAll(selected, rows, unSelectedRows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    columns: [
                        {
                            property: 'code',
                        },
                        {
                            property: 'name',
                        },
                    ],
                },
            ],
        };
    };

    toggleModal = () => {
        const { visible } = this.state;

        this.setState({ visible: !visible }, () => {
            if (!visible && entity.paging) {
                entity.search({
                    ...entity.paging,
                });
                this.onClearAll();
            }
        });
    };

    render() {
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 900,
            title: services.language.getText('xzyeqdkdqlx'),
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };

        return (
            <div style={{ display: 'inline-block' }}>
                <Button type="link" onClick={this.toggleModal}>
                    {services.language.getText('selectPlease')}
                </Button>
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}

export const BalanceCouponSelector = connect((_state: any) => {
    let requestStatus = get(entity, 'requestStatus', null);
    const result = { requestStatus };
    return result;
})(balanceCouponSelector);
