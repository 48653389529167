import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Row, Col, Table, Button, message } from 'antd';
import { get } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';
import {
    CarparkOrderChannelOptions,
    CarparkOrderChannel,
} from '../../config/member/member-service/carpark/carpark-order';

const { getText } = language;

interface CarparkOrder {
    baseInfo: CarparkOrderBaseInfo;
    memberInfo: CarparkOrderMemberInfo;
    paidInfo: CarparkOrderPaidInfo;
}

enum CarparkOrderStatus {
    CREATED = 'ycj',
    PAID = 'yzf_1',
    COMPLETED = 'ywc',
}

enum CarparkOrderType {
    TOUCHLESS_PARKING = 'touchlessParking',
    NORMAL = 'xsjf',
}

interface CarparkOrderBaseInfo {
    /**
     * 门店名称
     */
    subsiteName: string;
    /**
     * 车牌号
     */
    carNumber: string;

    /**
     * 入场时间
     */
    entryTime: string;

    /**
     * 订单号
     */
    orderNumber: string;

    /**
     * 支付时间
     */
    paidTime: string;

    /**
     * 停车时长
     */
    parkingTimeSec: number;

    parkingTime: string;

    /**
     * 支付方式
     */
    paymentMode: string;

    /**
     * 订单状态
     */
    status: keyof typeof CarparkOrderStatus;
    /**
     * 订单来源
     */
    channel: string;
    /**
     * 缴费类型
     */
    type: keyof typeof CarparkOrderType;
}

interface CarparkOrderMemberInfo {
    /**
     * 电子邮箱
     */
    email: string;

    /**
     * 会员等级字符串
     */
    memberLevelStr: string;

    /**
     * 用户手机
     */
    mobile: string;

    /**
     * 用户昵称
     */
    nickName: string;
}

interface CarparkOrderPaidInfo {
    /**
     * 优惠券抵扣
     */
    couponDiscount: string;

    /**
     * 会员消费抵扣
     */
    consumeDiscount: string;

    /**
     * 实付金额
     */
    payableAmount: string;

    /**
     * 积分抵扣
     */
    pointDiscount: string;

    /**
     * 应付金额
     */
    totalAmount: string;
    /**
     * 总停车费
     */
    totalParkingFee: string;
    /**
     * 剩余未付款金额
     */
    unPayAmount: string;
}

export interface CarparkOrderInfoProps {
    fields: CarparkOrder;
}

const getDiscountText = (amount: string, minutes: number) => {
    let str = '';
    const hasAmount = amount && Number(amount) > 0;
    const hasMinutes = minutes && minutes > 0;
    if (hasAmount) str += amount + getText('yuan');
    if (hasAmount && hasMinutes) str += '，';
    if (hasMinutes) str += minutes + getText('minutes');
    if (str === '') str = '-';
    return str;
};

class CarparkOrderInfoLayout extends Component<CarparkOrderInfoProps> {
    copyCouponNo = (text: any) => {
        let input = document.createElement('input');
        input.value = text;
        document.body.appendChild(input);
        input.select();
        if (document.execCommand('Copy')) {
            message.success(getText('copySuccess'));
        } else {
            message.warning(getText('copyError'));
        }
        document.body.removeChild(input);
    };
    virtualAssetTouchlessPayment = () => {
        const { fields } = this.props;
        if (!fields || !fields.baseInfo) {
            return '';
        }
        const { channel } = fields.baseInfo;
        if (channel !== CarparkOrderChannel.VIRTUAL_ASSET_TOUCHLESS) {
            return '';
        }
        const { totalParkingFee, unPayAmount } = fields.paidInfo;
        return `(${getText('totalParkingFee')}:${totalParkingFee}${getText('yuan')} ${getText(
            'unPayAmount'
        )}:${unPayAmount}${getText('yuan')})`;
    };
    render() {
        const { fields } = this.props;
        console.log('render fields', fields);
        const payStatus =
            fields.baseInfo && fields.baseInfo.status
                ? fields.baseInfo.status === 'CREATED'
                    ? getText('wzf')
                    : getText('yzf_1')
                : '-';
        const channelOption = CarparkOrderChannelOptions.find(
            (i) => fields.baseInfo && i.id === fields.baseInfo.channel
        );
        const channel = channelOption ? getText(channelOption.name.slice(2, -2)) : '-';
        const type =
            fields.baseInfo && fields.baseInfo.type
                ? getText(CarparkOrderType[fields.baseInfo.type])
                : '-';
        const columns = [
            {
                title: getText('couponName'),
                dataIndex: 'couponName',
                key: 'couponName',
            },
            {
                title: getText('couponValue') + '/' + getText('hour'),
                dataIndex: 'breaksValue',
                key: 'breaksValue',
            },
            {
                title: getText('qm_1'),
                dataIndex: 'couponNo',
                key: 'couponNo',
                render: (text: any, record: any) => (
                    <span>
                        <span>{text}</span>
                        <Button
                            type="link"
                            onClick={() => {
                                this.copyCouponNo(text);
                            }}
                        >
                            {getText('copy')}
                        </Button>
                    </span>
                ),
            },
        ];
        const balanceColumns = [
            {
                title: getText('qbm'),
                dataIndex: 'code',
                key: 'code',
            },
            {
                title: getText('qmc'),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: getText('jsrq'),
                dataIndex: 'endTime',
                key: 'endTime',
            },
            {
                title: getText('bgqje'),
                dataIndex: 'beforeAmount',
                key: 'beforeAmount',
            },
            {
                title: getText('bgje'),
                dataIndex: 'changeAmount',
                key: 'changeAmount',
            },
            {
                title: getText('jylsh'),
                dataIndex: 'transactionNo',
                key: 'transactionNo',
            },
            {
                title: getText('czsj'),
                dataIndex: 'createTime',
                key: 'createTime',
            },
            {
                title: getText('zfzt'),
                dataIndex: 'payStatus',
                key: 'payStatus',
                render: (text: any, record: any) => (
                    <span>
                        <span>
                            {text === 'SUCCESS'
                                ? getText('success')
                                : text === 'FAIL'
                                ? getText('sb_2')
                                : getText('cj')}
                        </span>
                    </span>
                ),
            },
            {
                title: getText('reason'),
                dataIndex: 'reason',
                key: 'reason',
            },
        ];
        return (
            <div>
                <Card
                    title={`${getText('ddjcxx')}(${get(fields, 'baseInfo.subsiteName') ||
                        '-'} - ${get(fields, 'baseInfo.subsiteCarparkName') || '-'})`}
                    type="inner"
                    style={{ marginBottom: 32 }}
                >
                    <Row gutter={[16, 12]}>
                        <Col span={8}>
                            <span>
                                {getText('orderNumber')}：
                                {get(fields, 'baseInfo.orderNumber') || '-'}
                            </span>
                        </Col>
                        <Col span={8}>
                            <span>
                                {getText('cph')}：{get(fields, 'baseInfo.carNumber') || '-'}
                            </span>
                        </Col>
                        <Col span={8}>
                            <span>
                                {getText('jcsj_1')}：{get(fields, 'baseInfo.entryTime') || '-'}
                            </span>
                        </Col>
                    </Row>
                    <Row gutter={[16, 12]}>
                        <Col span={8}>
                            <span>
                                {getText('jfsj')}：{get(fields, 'baseInfo.paidTime') || '-'}
                            </span>
                        </Col>
                        <Col span={8}>
                            <span>
                                {getText('ljsz')}：{get(fields, 'baseInfo.parkingTime') || '-'}
                            </span>
                        </Col>
                    </Row>
                    <Row gutter={[16, 12]}>
                        <Col span={8}>
                            <span>
                                {getText('zfzt')}：{payStatus}
                            </span>
                        </Col>
                        <Col span={8}>
                            <span>
                                {getText('carparkOrderChannel')}：{channel}
                            </span>
                        </Col>
                        <Col span={8}>
                            <span>
                                {getText('jflx_1')}：{type}
                            </span>
                        </Col>
                    </Row>
                    <Row gutter={[16, 12]}>
                        <Col span={24}>
                            <span>
                                {getText('zffs')} ：{get(fields, 'baseInfo.paymentMode') || '-'}
                            </span>
                        </Col>
                    </Row>
                </Card>
                <Card title={getText('mjxx')} type="inner" style={{ marginBottom: 32 }}>
                    <Row gutter={[16, 12]}>
                        <Col span={6}>
                            <span>
                                {getText('member')}：{get(fields, 'memberInfo.nickName') || '-'}
                            </span>
                        </Col>
                        <Col span={6}>
                            <span>
                                {getText('phoneNum')}：{get(fields, 'memberInfo.mobile') || '-'}
                            </span>
                        </Col>
                        <Col span={6}>
                            <span>
                                {getText('email')}：{get(fields, 'memberInfo.email') || '-'}
                            </span>
                        </Col>
                        <Col span={6}>
                            <span>
                                {getText('hydj')}：{get(fields, 'memberInfo.memberLevelStr') || '-'}
                            </span>
                        </Col>
                    </Row>
                </Card>
                <Card title={getText('yfxx')} type="inner" style={{ marginBottom: 32 }}>
                    <Row gutter={[16, 12]}>
                        <Col span={8}>
                            <span>
                                {getText('yjje')}：{get(fields, 'paidInfo.totalAmount')}
                                {getText('yuan')}
                                {this.virtualAssetTouchlessPayment()}
                            </span>
                        </Col>
                        <Col span={8}>
                            <span>
                                {getText('sjje_1')}：{get(fields, 'paidInfo.payableAmount')}
                                {getText('yuan')}
                            </span>
                        </Col>
                        <Col span={8}>
                            <span>
                                {getText('hydrxfjedk')}：
                                {getDiscountText(
                                    get(fields, 'paidInfo.consumeDiscount'),
                                    get(fields, 'paidInfo.consumeDiscountMinute')
                                )}
                            </span>
                        </Col>
                    </Row>
                    <Row gutter={[16, 12]}>
                        <Col span={8}>
                            <span>
                                {getText('tcqdk')}：
                                {getDiscountText(
                                    get(fields, 'paidInfo.couponDiscount'),
                                    get(fields, 'paidInfo.couponDiscountMinute')
                                )}
                            </span>
                        </Col>
                        <Col span={8}>
                            <span>
                                {getText('jfdk')}：{get(fields, 'paidInfo.pointDiscount')}
                            </span>
                        </Col>
                    </Row>
                    <Row gutter={[16, 12]}>
                        <Col span={24}>
                            <span>{getText('tcqmx')}：</span>
                        </Col>
                    </Row>
                    <Row gutter={[16, 12]}>
                        <Col span={24}>
                            <Table
                                dataSource={get(fields, 'paidInfo.couponInfoList')}
                                columns={columns}
                            ></Table>
                        </Col>
                    </Row>
                    <Row gutter={[16, 12]}>
                        <Col span={24}>
                            <span>{getText('yeqmx')}：</span>
                        </Col>
                    </Row>
                    <Row gutter={[16, 12]}>
                        <Col span={24}>
                            <Table
                                pagination={false}
                                dataSource={get(fields, 'paidInfo.balanceCouponDiscountInfoList')}
                                columns={balanceColumns}
                            ></Table>
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }
}

function mapStateToProps(_state: any, props: any) {
    const { entity } = props;
    return {
        fields: entity.fields || {},
    };
}

export const CarparkOrderInfo = connect(mapStateToProps)(CarparkOrderInfoLayout);
