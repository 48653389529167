import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;

export const ExperienceBenefitLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`;
        config.apiPath = '/admin/promotion';
        const { id } = data;
        if (data.executeStatus === 'ALL') {
            delete data.executeStatus;
        }
        if (data.channel === 'ALL') {
            delete data.channel;
        }
        const promotionType =
            config.paramsFilter && config.paramsFilter.length > 0 ? config.paramsFilter[0] : null;
        if (promotionType) {
            delete config.paramsFilter;
            data.promotionType = promotionType;
        }
        if (id) {
            config.apiPath = `/admin/promotion/${id}`;
        } else {
            data.rewardType = 'CARD_EXPERIENCE';
        }

        return await api.get(data, config).then((res: any) => {
            if (!id) {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        item.activityProductType =
                            item.channel === 'OFFLINE' ? '' : item.activityProductType;
                        item.canImport = item.enableImportProducts;
                        return item;
                    });
            } else {
                if (promotionType === 'BASE_RULE') {
                    res.baseInfo = {
                        name: res.name,
                    };
                    res.ruleInfo = {
                        selectMode:
                            res.subsites && res.subsites.length > 0 ? 'SUB_SITE' : 'MERCHANT',
                        merchants: res.merchants.map((merchant: any) => {
                            return {
                                ...merchant,
                                merchantId: merchant.id,
                                merchantName: merchant.merchantName,
                            };
                        }),
                        subsites: res.subsites,
                        channel:
                            res.channels && res.channels.length > 0 ? res.channels[0] : 'ONLINE',
                        activityProductType: res.activityProductType,
                        calcType: 'FULL_AMOUNT',
                        giveTime: 'RECEIVED',
                        reward: {
                            ladderValue:
                                res.rewards && res.rewards.length > 0 && res.rewards[0].lowerValue,
                            ladderAmount:
                                res.rewards && res.rewards.length > 0 && res.rewards[0].rewardValue,
                        },
                    };
                    if (res.products && res.products.length > 0) {
                        res.ruleInfo.products = {
                            type: 'APPOINT_PRODUCT',
                            values: res.products.map((item: any) => {
                                return {
                                    ...item,
                                    name: item.productName,
                                    id: item.productId,
                                    code: item.productCode,
                                };
                            }),
                        };
                    } else if (res.brands && res.brands.length > 0) {
                        res.ruleInfo.products = {
                            type: 'APPOINT_BRAND',
                            values: res.brands,
                        };
                    }
                } else if (promotionType === 'DOUBLE_RULE') {
                    res.baseInfo = {
                        name: res.name,
                    };
                    res.ruleInfo = {
                        subsites: res.subsites && res.subsites[0].id,
                        ruleType: 'MEMBER_LEVEL',
                        memberPointDoubleRule: {
                            memberLevelId: Number(res.memberLevel),
                            multiple:
                                res.rewards && res.rewards.length > 0 && res.rewards[0].rewardValue,
                        },
                    };
                }
            }
            return res;
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`;
        config.apiPath = `/admin/promotion`;
        const promotionType =
            config.paramsFilter && config.paramsFilter.length > 0 ? config.paramsFilter[0] : null;
        if (promotionType) {
            delete config.paramsFilter;
        }
        const newParams = dataParser(params, promotionType);
        newParams.id = id;
        return await api.put(newParams, config);
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`;
        config.apiPath = '/admin/promotion';
        const promotionType =
            config.paramsFilter && config.paramsFilter.length > 0 ? config.paramsFilter[0] : null;
        if (promotionType) {
            delete config.paramsFilter;
        }
        const newParams = dataParser(params, promotionType);
        return await api.post(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any, promotionType: string | null) {
    const newParams: any = {
        rewardType: 'CARD_EXPERIENCE',
        promotionType,
    };
    if (params.baseInfo) {
        newParams.name = params.baseInfo.name;
    }
    if (params.ruleInfo && promotionType === 'BASE_RULE') {
        newParams.calcType = params.ruleInfo.calcType;
        // newParams.rewardLimit = params.ruleInfo.rewardLimit;
        const selectMode = params.ruleInfo.selectMode;
        if (selectMode === 'SUB_SITE' && !isEmpty(params.ruleInfo.subsites)) {
            newParams.subsiteIds = params.ruleInfo.subsites.map((subsite: any) => subsite.id);
        }
        if (selectMode === 'MERCHANT' && !isEmpty(params.ruleInfo.merchants)) {
            newParams.merchantIds = params.ruleInfo.merchants.map(
                (merchants: any) => merchants.merchantId
            );
        }
        if (selectMode === 'TAG' && !isEmpty(params.ruleInfo.tags)) {
            newParams.merchantIds = params.ruleInfo.tags.map((tag: any) => tag.id);
        }
        if (params.ruleInfo.reward) {
            newParams.rewards = [
                {
                    lowerValue: params.ruleInfo.reward.ladderValue,
                    rewardValue: params.ruleInfo.reward.ladderAmount,
                },
            ];
        }
        newParams.channels = [params.ruleInfo.channel];
        if (params.ruleInfo.channel && params.ruleInfo.channel === 'ONLINE') {
            const products = params.ruleInfo.products;
            if (products && products.type) {
                newParams.rangeType = products.type;
            }
            if (products && products.values) {
                newParams.promotionScopeIds = products.values.map((value: any) => value.id);
            }
            newParams.activityProductType = params.ruleInfo.activityProductType;
        }
    }
    if (params.ruleInfo && promotionType === 'DOUBLE_RULE') {
        newParams.subsiteIds = [params.ruleInfo.subsites];
        const memberPointDoubleRule = params.ruleInfo.memberPointDoubleRule;
        if (memberPointDoubleRule) {
            newParams.memberLevel = memberPointDoubleRule.memberLevelId;
            newParams.rewards = [
                {
                    rewardValue: memberPointDoubleRule.multiple,
                },
            ];
        }
    }

    return newParams;
}
