import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import { MerchantSelecterTick } from './merchant-selector';

export class MerchantSelectorTickMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <MerchantSelecterTick {...displayInfo} />;
    }
}
