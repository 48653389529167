import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { map, get } from 'lodash';
const { api } = services;

function processToVM(data: any, id: any) {
    if (data.subsites) {
        data.subsiteName = data.subsites;
    }
    data.pic = data.picUrl ? [{ id: data.picId, path: data.picUrl }] : [];

    if (data.linkParams) {
        data.linkParams = JSON.parse(data.linkParams);
    }
    if (!data.scope) {
        data.scope = 'SUBSITE';
    }
    data.target = { linkType: data.linkType, linkParams: data.linkParams };
    if (data.scope === 'SUBSITE') {
        data.linkType = { linkType: data.linkType, linkParams: data.linkParams };
        data.homeType = data.targetShowPage ? data.targetShowPage.split(',') : ['HOME'];
        data.types = data.targetShowPage
            ? data.targetShowPage
                  .replace('HOME', '门店首页')
                  .replace('MEMBER_CENTER', '会员中心')
                  .replace('CHECK_OUT', '结算页')
            : '';
    } else if (data.scope === 'PLATFORM') {
        data.platformLinkType = { linkType: data.linkType, linkParams: data.linkParams };
        data.platformType = data.targetShowPage ? data.targetShowPage.split(',') : ['HOME'];
        data.types = data.targetShowPage
            ? data.targetShowPage
                  .replace('HOME', '平台首页')
                  .replace('MEMBER_CENTER', '会员中心')
                  .replace('CHECK_OUT', '结算页')
            : '';
        if (!id) {
            data.subsiteName = [{ id: '0', name: '平台首页' }];
            data.subsites = [{ id: '0', name: '平台首页' }];
        }
    }

    data.showTime = { start: data.startTime, end: data.endTime };
    data.pictureType = data.pictureType || 'SINGLE_IMAGE_FIXED';
    data.interval = '3';
    data.operator = [];
    if (data.pictureType === 'MULTIPLE_IMAGE_CAROUSEL' && data.pageConfig) {
        const config = JSON.parse(data.pageConfig);
        if (config && config.interval) {
            data.interval = config.interval;
        }
        if (config && config.operator) {
            data.operator = config.operator;
        }
    }
    return data;
}

function processToVO(data: any) {
    data.picId = 0;
    data.picUrl = get(data, 'pic', null);
    if (data.config === 'dayThreeTime') {
        data.second = 3;
    }
    const lineTypeKey = data.scope === 'SUBSITE' ? 'linkType' : 'platformLinkType';

    const target = data.target;

    const link = {
        linkType: !!target ? target.linkType : get(data, `${lineTypeKey}.linkType`, 'none'),
        linkParams: !!target ? target.linkParams : get(data, `${lineTypeKey}.linkParams`, null),
    };
    data.linkType = link.linkType;
    data.linkParams = link.linkParams ? JSON.stringify(link.linkParams) : null;
    data.startTime = get(data, 'showTime.start', null);
    data.endTime = get(data, 'showTime.end', null);
    data.status = 'ENABLED';

    if (data.scope === 'PLATFORM') {
        data.subsites = [];
        data.targetShowPage = data.platformType.join();
        delete data.platformType;
    } else if (data.scope === 'SUBSITE') {
        data.targetShowPage = data.homeType.join();
        delete data.homeType;
    }
    if (data.pictureType === 'MULTIPLE_IMAGE_CAROUSEL') {
        data.pageConfig = JSON.stringify({
            interval: data.interval,
            operator: data.operator,
        });
    }

    delete data.pic;
    delete data.showTime;
    delete data.platformLinkType;
    delete data.interval;
    delete data.operator;
    return data;
}
function processToVOs(data: any) {
    data.picId = 0;
    data.picUrl = get(data, 'pic', null);
    /* if (data.config === 'dayThreeTime') {
        data.second = 3;
    } */
    const lineTypeKey = data.scope === 'SUBSITE' ? 'linkType' : 'platformLinkType';

    const target = data.target;

    const link = {
        linkType: !!target ? target.linkType : get(data, `${lineTypeKey}.linkType`, 'none'),
        linkParams: !!target ? target.linkParams : get(data, `${lineTypeKey}.linkParams`, null),
    };
    data.linkType = link.linkType;
    data.linkParams = link.linkParams ? JSON.stringify(link.linkParams) : null;
    data.startTime = get(data, 'showTime.start', null);
    data.endTime = get(data, 'showTime.end', null);
    data.status = 'ENABLED';

    if (data.scope === 'PLATFORM') {
        data.subsites = [];
        data.targetShowPage = data.platformType.join();
        delete data.platformType;
    } else if (data.scope === 'SUBSITE') {
        data.targetShowPage = data.homeType.join();
        delete data.homeType;
    }
    if (data.pictureType === 'MULTIPLE_IMAGE_CAROUSEL') {
        data.pageConfig = JSON.stringify({
            interval: data.interval,
            operator: data.operator,
        });
    }

    delete data.pic;
    delete data.showTime;
    delete data.platformLinkType;
    delete data.interval;
    delete data.operator;
    return data;
}

function loaderGet(data: any, config: ApiRequestConfig) {
    if (data.showTime) {
        data.startTime = get(data, 'showTime.start', null);
        data.endTime = get(data, 'showTime.end', null);
        data.showTime = undefined;
    }
    const subsiteName = data.subsiteName;
    if (subsiteName) {
        data.subsiteName = subsiteName.name ? subsiteName.name : subsiteName;
    }
    return api.get(data, config).then((res: any) => {
        if (data.id) {
            return processToVM(res, data.id);
        }
        if (res && res.result) {
            map(res.result, (item) => {
                processToVM(item, data.id);
            });
        }
        return res;
    });
}

export const popUpAdsLoader = {
    get: function(data: any, config: ApiRequestConfig) {
        data.applicableChannels = 'ESHOP';
        return loaderGet(data, config);
    },
    put: function(data: any, config: ApiRequestConfig) {
        data.applicableChannels = 'ESHOP';
        return api.put(processToVO(data), config);
    },
    post: function(data: any, config: ApiRequestConfig) {
        data.applicableChannels = 'ESHOP';
        return api.post(processToVO(data), config);
    },
};

export const newPopUpAdsLoader = {
    get: function(data: any, config: ApiRequestConfig) {
        config.apiPath = '/admin/pop_up_ads';
        if (data.id) {
            config.apiPath = `/admin/pop_up_ads/${data.id}`;
        }
        data.applicableChannels = 'NEW_CLIENT';
        return loaderGet(data, config);
    },
    put: function(data: any, config: ApiRequestConfig) {
        const id = data.id;
        config.apiPath = `/admin/pop_up_ads/${id}`;
        data.applicableChannels = 'NEW_CLIENT';
        return api.put(processToVOs(data), config);
    },
    post: function(data: any, config: ApiRequestConfig) {
        config.apiPath = '/admin/pop_up_ads';
        data.applicableChannels = 'NEW_CLIENT';
        return api.post(processToVOs(data), config);
    },
    delete: function(data: any, config: ApiRequestConfig) {
        const id = data.id;
        config.apiPath = `/admin/pop_up_ads/${id}`;
        return api.delete({}, config);
    },
};
