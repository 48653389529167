import React, { PureComponent } from 'react';
import { api, errorHandle, language } from '@comall-backend-builder/core/lib/services';
import moment from 'moment';
import { Tabs as AntTabs, Row, Col, Switch, Spin, InputNumber, Button, message, Radio } from 'antd';
import { cloneDeep, isNil, toString } from 'lodash';
import { MerchantsSelector } from './merchants-selector';
import { WarnConfigs } from './warn-Configs';
import './index.less';

const AntTabPane = AntTabs.TabPane;
const prefix = 'subscribe-config-page';
// const configs: any = {
//     business: 'JIUQU_BUSINESS',
//     merchants: 'SUBSCRIBE_MERCHANT',
//     grounding: 'SUBSCRIBE_SHELF_STATU',
//     ratio: 'SALES_PRICE_FLOAT_RATIO',
// };
interface SubscribeConfigPageState {
    searchParams: object;
    activeKey: string;
    subscribeConfigs: subscribeConfigs[];
    subsites: subsite[];
    business: boolean;
    merchants: merchants[];
    grounding: boolean;
    ratio: number;
    ratioType: string;
    editRatio: number;
    loading: boolean;
    isEdit: boolean;
    activiteSubsiteConfigs: activiteSubsiteConfigs[];
    submitLoading: boolean;
    floatRatio: Ratio[];
    noticeEvent: NoticeEvent | undefined;
    noticeUsers: string[];
}
export interface NoticeEvent {
    threshold: {
        //成本价超过阈值
        checkbox: boolean;
        value: number;
        isEdit: boolean;
    };
    stock: {
        //已订阅商品库存不足
        checkbox: boolean;
        value: number;
        isEdit: boolean;
    };
    invalid: {
        //已订阅商品在供应链失效
        checkbox: boolean;
    };
    time: {
        //发送起始时间
        value: string;
        isEdit: boolean;
    };
    timeNum: {
        //发送间隔时间
        value: number;
        isEdit: boolean;
    };
    frequency: {
        //发送频次
        value: number;
        isEdit: boolean;
    };
    orderFailed: {
        //提交订单失败
        checkbox: boolean;
    };
    payFailed: {
        //支付失败
        checkbox: boolean;
    };
    declareFailed: {
        //报关失败
        checkbox: boolean;
    };
}
interface Ratio {
    categoryId: string;
    categoryName: string;
    ratio: number;
    isEdit: boolean;
}
interface subscribeConfigs {
    id: string;
    subsiteId: number;
    subsiteName: string;
    subscribeConfigs?: activiteSubsiteConfigs[];
}
interface merchants {
    id: string;
    merchantId: string;
    merchantName: string;
    merchantStatus: string;
    merchantType: string;
    subSiteId: string;
    subSiteName: string;
}
interface subsite {
    id: string;
    name: string;
}
interface activiteSubsiteConfigs {
    configValue: string;
    merchants: null | merchants[];
    subscribeConfigType: null | string;
    configList: any[];
}
export class SubscribeConfigPage extends PureComponent<any, SubscribeConfigPageState> {
    state = {
        searchParams: {},
        activeKey: '',
        subsites: [],
        subscribeConfigs: [],
        business: false, //开通九衢业务
        merchants: [], //专柜
        grounding: false, //订阅后自动上架
        ratio: 0, //浮动比例
        editRatio: 0, //编辑状态下的浮动比例
        ratioType: 'SALES_PRICE_FLOAT_RATIO',
        loading: false,
        isEdit: false, //浮动比例是否在编辑状态下
        activiteSubsiteConfigs: [],
        submitLoading: false,
        floatRatio: [], //按分类配置的浮动比例
        noticeEvent: undefined, //报警通知事件
        noticeUsers: [], //报警通知对象
    };

    componentDidMount() {
        this.getSubsites();
    }

    getSubsites = async () => {
        try {
            let res: subsite[] = await api.get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}`,
                    apiPath: '/WEB-API/admin/defaults/subsite',
                }
            );
            if (res?.length) {
                this.setState({ subsites: res, activeKey: res[0].id });
                await this.getSubsiteConfigs(res[0].id);
            }
        } catch (error) {
            throw error;
        }
    };
    getSubsiteConfigs = async (subsiteId: string) => {
        this.setState({ loading: true });
        try {
            let res: {
                subscribeConfigs: activiteSubsiteConfigs[];
                subsiteId: number;
                subsiteName: string;
            } = await api.get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                    apiPath: `/admin/subscribe_config/${subsiteId}`,
                }
            );
            if (res?.subscribeConfigs?.length) {
                this.setState({ activiteSubsiteConfigs: res.subscribeConfigs }, () => {
                    this.setFormValues(res.subscribeConfigs);
                });
            }
            this.setState({ loading: false });
        } catch (error) {
            this.setState({ loading: false });
            throw error;
        }
    };
    setFormValues = (activiteSubsiteConfigs: activiteSubsiteConfigs[]) => {
        const newNoticeEvent = {
            threshold: {},
            stock: {},
            invalid: {},
            time: {},
            timeNum: {},
            frequency: {},
            orderFailed: {},
            payFailed: {},
            declareFailed: {},
        } as NoticeEvent;
        activiteSubsiteConfigs?.forEach((config: activiteSubsiteConfigs) => {
            let type = config.subscribeConfigType;
            switch (type) {
                case 'JIUQU_BUSINESS': //是否开通九衢业务
                    let checked = config.configValue === '1';
                    this.setState({ business: checked });
                    break;
                case 'SUBSCRIBE_MERCHANT': //订阅专柜
                    let merchants = config?.merchants || [];
                    this.setState({ merchants });
                    break;
                case 'SUBSCRIBE_SHELF_STATU': //订阅后自动上架
                    let grounding = config.configValue === '1';
                    this.setState({ grounding });
                    break;
                case 'SALES_PRICE_FLOAT_RATIO_TYPE': //销售价随成本价浮动比例
                    this.setState({ ratioType: config?.configValue });
                    break;
                case 'SALE_PRICE_FLOAT_RATIO_CATEGORY': //销售价随成本价浮动比例
                    this.setState({ floatRatio: config?.configList || [] });
                    break;
                case 'SALES_PRICE_FLOAT_RATIO': //销售价随成本价浮动比例
                    this.setState({ ratio: Number(config?.configValue) });
                    break;
                case 'NOTICE_MOBILE': //报警通知对象
                    this.setState({ noticeUsers: config?.configList || [] });
                    break;
                default:
                    break;
            }
            if (type === 'NOTICE_GOODS_COST_PRICE_CHANGE_LARGE') {
                let checked = config.configValue === '1';
                newNoticeEvent.threshold.checkbox = checked;
                newNoticeEvent.threshold.isEdit = false;
            }
            if (type === 'NOTICE_GOODS_COST_PRICE_CHANGE_THRESHOLD') {
                newNoticeEvent.threshold.value = Number(config.configValue);
            }
            if (type === 'NOTICE_GOODS_STOCK_FEW') {
                let checked = config.configValue === '1';
                newNoticeEvent.stock.checkbox = checked;
                newNoticeEvent.stock.isEdit = false;
            }
            if (type === 'NOTICE_GOODS_STOCK_THRESHOLD') {
                newNoticeEvent.stock.value = Number(config.configValue);
            }
            if (type === 'NOTICE_GOODS_SUPPLIER_OFF_SHELF') {
                let checked = config.configValue === '1';
                newNoticeEvent.invalid.checkbox = checked;
            }
            if (type === 'NOTICE_GOODS_START_TIME') {
                newNoticeEvent.time.value = config.configValue;
                newNoticeEvent.time.isEdit = false;
            }
            if (type === 'NOTICE_GOODS_INTERVAL') {
                newNoticeEvent.timeNum.value = Number(config.configValue);
                newNoticeEvent.timeNum.isEdit = false;
            }
            if (type === 'NOTICE_GOODS_RATE') {
                newNoticeEvent.frequency.value = Number(config.configValue);
                newNoticeEvent.frequency.isEdit = false;
            }
            if (type === 'NOTICE_ORDER_CREATE_FAIL') {
                let checked = config.configValue === '1';
                newNoticeEvent.orderFailed.checkbox = checked;
            }
            if (type === 'NOTICE_ORDER_PAYMENT_FAIL') {
                let checked = config.configValue === '1';
                newNoticeEvent.payFailed.checkbox = checked;
            }
            if (type === 'NOTICE_ORDER_CUSTOM_FAIL') {
                let checked = config.configValue === '1';
                newNoticeEvent.declareFailed.checkbox = checked;
            }
        });
        this.setState({ noticeEvent: newNoticeEvent });
    };
    onSelectMerchants = (selectValues: merchants[], callback?: Function) => {
        if (!selectValues || selectValues?.length === 0) {
            message.error(language.getText('pleaseSelectMerchant'));
            return;
        }
        this.handleValueChange(selectValues, 'merchants', callback);
    };
    onClickRatios = (operateType: string, index: number, value?: any) => {
        const { floatRatio } = this.state;
        let newRatios = cloneDeep(floatRatio) as any;
        if (operateType === 'change') {
            newRatios[index].ratio = value;
        } else if (operateType === 'edit') {
            newRatios[index].isEdit = !newRatios[index].isEdit;
        } else {
            if (isNil(newRatios[index].ratio)) {
                message.warn(language.getText('inputRightThanZero'));
                return;
            }
            newRatios[index].isEdit = !newRatios[index].isEdit;
            const obj = {};
            newRatios.forEach((item: Ratio) => {
                Object.assign(obj, { [item.categoryId]: String(item.ratio) });
            });
            const value = JSON.stringify(obj);
            let configs = [
                {
                    configList: [],
                    subscribeConfigType: 'SALE_PRICE_FLOAT_RATIO_CATEGORY',
                    configValue: value,
                    merchants: null,
                },
            ];
            this.modifyConfigs(configs);
        }
        this.setState({ floatRatio: newRatios });
    };
    onClickRatio = (operateType: string, value?: any) => {
        const { ratio, isEdit } = this.state;
        if (operateType === 'change') {
            this.setState({ ratio: value });
        } else if (operateType === 'edit') {
            this.setState({ isEdit: !isEdit });
        } else if (operateType === 'submit') {
            if (isNil(ratio)) {
                message.warn(language.getText('inputRightThanZero'));
                return;
            }
            let configs = [
                {
                    configList: [],
                    subscribeConfigType: 'SALES_PRICE_FLOAT_RATIO',
                    configValue: String(ratio),
                    merchants: null,
                },
            ];
            this.setState({ isEdit: !isEdit });
            this.modifyConfigs(configs);
        } else if (operateType === 'ratioType') {
            let val = value.target.value;
            let configs = [
                {
                    configList: [],
                    subscribeConfigType: 'SALES_PRICE_FLOAT_RATIO_TYPE',
                    configValue: val,
                    merchants: null,
                },
            ];
            this.setState({ ratioType: val, isEdit: false });
            this.modifyConfigs(configs);
        }
    };

    onChangeNotice = (objType: string, operateType: string, paramsType: string, value?: any) => {
        const { noticeEvent } = this.state as any;
        let params = [
            {
                subscribeConfigType: paramsType,
                configList: [],
                configValue: '',
                merchants: null,
            },
        ] as any;
        let newNoticeEvent = Object.assign({}, noticeEvent);
        if (value && typeof value === 'object') {
            if (operateType === 'checkbox') {
                newNoticeEvent[objType][operateType] = value.target.checked;
                params[0].configValue = value.target.checked ? '1' : '0';
                this.modifyConfigs(params);
            } else {
                let time = moment(value).format('HH:mm');
                newNoticeEvent[objType][operateType] = time;
            }
        } else if (value && typeof value !== 'object') {
            if (operateType === 'isEdit') {
                newNoticeEvent[objType][operateType] = !noticeEvent[objType].isEdit;
                if (value === 'submit') {
                    params[0].configValue = toString(newNoticeEvent[objType].value);
                    this.modifyConfigs(params);
                }
            } else {
                newNoticeEvent[objType][operateType] = value;
            }
        }
        this.setState({ noticeEvent: newNoticeEvent });
    };
    onChangeUsers = (users: any[], operateType: string) => {
        let configs = [
            {
                subscribeConfigType: 'NOTICE_MOBILE',
                configList: [],
                configValue: users?.length ? JSON.stringify(users) : '',
                merchants: null,
            },
        ];
        this.modifyConfigs(configs);
    };
    handleValueChange = (value: any, valueType: string, callback?: Function) => {
        let configs = [
            {
                subscribeConfigType: '',
                configList: [],
                configValue: '',
                merchants: null,
            },
        ];

        if (valueType === 'business') {
            configs[0].subscribeConfigType = 'JIUQU_BUSINESS';
            configs[0].configValue = value ? '1' : '0';
            this.setState({ business: value });
        }
        if (valueType === 'grounding') {
            configs[0].subscribeConfigType = 'SUBSCRIBE_SHELF_STATU';
            configs[0].configValue = value ? '1' : '0';
            this.setState({ grounding: value });
        } else if (valueType === 'merchants') {
            const merchant = value && value.length ? value[0].merchantId : '';
            configs[0].subscribeConfigType = 'SUBSCRIBE_MERCHANT';
            configs[0].configValue = merchant;
            configs[0].merchants = value;
            this.setState({ merchants: value });
        }
        this.modifyConfigs(configs, callback);
    };
    modifyConfigs = (subscribeConfigs: activiteSubsiteConfigs[], callback?: Function) => {
        const { activeKey, activiteSubsiteConfigs } = this.state;
        if (!activeKey) {
            return;
        }
        if (callback) {
            this.setState({ submitLoading: true });
        } else {
            this.setState({ loading: true });
        }
        api.put(
            {
                subsiteId: activeKey,
                subscribeConfigs,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: '/admin/subscribe_config/update',
            }
        )
            .then(() => {
                if (callback) {
                    callback();
                }
                // this.setFormValues(subscribeConfigs);
                this.getSubsiteConfigs(activeKey);
                message.success(language.getText('common.modifySuccess'));
            })
            .catch((error: any) => {
                errorHandle(error);
                this.setFormValues(activiteSubsiteConfigs);
            })
            .finally(() => {
                this.setState({ loading: false, submitLoading: false });
            });
    };
    onChange = (activeKey: string) => {
        this.setState(
            {
                activeKey,
            },
            () => {
                this.getSubsiteConfigs(activeKey);
            }
        );
    };

    renderConfigs = () => {
        const {
            business,
            merchants,
            grounding,
            submitLoading,
            activeKey,
            activiteSubsiteConfigs,
            noticeEvent,
            noticeUsers,
            ratioType,
        } = this.state;
        if (!activiteSubsiteConfigs || !activiteSubsiteConfigs?.length) {
            return null;
        }
        const props = {
            params: {
                subSiteName: activeKey,
            },
            loading: submitLoading,
            type: 'radio',
        };
        return (
            <div className={`${prefix}__content`}>
                <div className={`${prefix}__sub-form-title`}>{language.getText('baseConfig')}</div>
                <Row className={`${prefix}__setting-item`}>
                    <Col span={4} className={`${prefix}__setting-item-label`}>
                        {language.getText('openTenant')}
                    </Col>
                    <Col span={20} className={`${prefix}__setting-item-control`}>
                        <Switch
                            onChange={(e) => this.handleValueChange(e, 'business')}
                            checked={business}
                        />
                    </Col>
                </Row>
                <Row className={`${prefix}__setting-item`}>
                    <Col span={4} className={`${prefix}__setting-item-label`}>
                        {language.getText('subscibeMerchant')}
                    </Col>
                    <Col span={20} className={`${prefix}__setting-item-control`}>
                        <MerchantsSelector
                            {...props}
                            onChange={this.onSelectMerchants}
                            selectValues={merchants}
                        />
                        <div className="ant-form-extra">
                            {language.getText('subscibeProductToMerchant')}
                        </div>
                    </Col>
                </Row>
                <Row className={`${prefix}__setting-item`}>
                    <Col span={4} className={`${prefix}__setting-item-label`}>
                        {language.getText('subscribeAfterUpShelf')}
                    </Col>
                    <Col span={20} className={`${prefix}__setting-item-control`}>
                        <Switch
                            onChange={(e) => this.handleValueChange(e, 'grounding')}
                            checked={grounding}
                        />
                    </Col>
                </Row>
                <Row className={`${prefix}__setting-item`}>
                    <Col span={4} className={`${prefix}__setting-item-label`}>
                        {language.getText('salePriceFloatRatio')}
                    </Col>
                    <Col span={20} className={`${prefix}__setting-item-control`}>
                        <Radio.Group
                            onChange={(value: any) => this.onClickRatio('ratioType', value)}
                            value={ratioType}
                        >
                            <Radio value={'SALES_PRICE_FLOAT_RATIO'}>
                                {language.getText('unifiedFloat')}
                            </Radio>
                            <Radio value={'SALE_PRICE_FLOAT_RATIO_CATEGORY'}>
                                {language.getText('toCategoryFloat')}
                            </Radio>
                        </Radio.Group>
                        {this.renderFloatRatio()}
                        <div className="ant-form-extra">
                            {language.getText('setNewSubscibeProduct')}
                        </div>
                    </Col>
                </Row>
                <WarnConfigs
                    noticeEvent={noticeEvent}
                    noticeUsers={noticeUsers}
                    onChangeNotice={this.onChangeNotice}
                    onChangeUsers={this.onChangeUsers}
                    subsiteId={activeKey}
                />
            </div>
        );
    };
    renderFloatRatio() {
        const { ratioType, isEdit, ratio, floatRatio } = this.state;
        if (ratioType === 'SALES_PRICE_FLOAT_RATIO') {
            return (
                <div
                    style={{ marginTop: 20, marginBottom: 5 }}
                    className={`${prefix}__setting-item-control__ratio`}
                >
                    <div className="title">{language.getText('allCategory')}</div>
                    {isEdit ? (
                        <InputNumber
                            type="number"
                            min={1}
                            precision={0}
                            max={99999}
                            value={ratio}
                            onChange={(value: any) => this.onClickRatio('change', value)}
                        />
                    ) : (
                        <div>{ratio}</div>
                    )}
                    <span>%</span>
                    <Button
                        type="primary"
                        size="small"
                        onClick={() => {
                            let type = isEdit ? 'submit' : 'edit';
                            this.onClickRatio(type);
                        }}
                    >
                        {isEdit ? language.getText('common.save') : language.getText('common.edit')}
                    </Button>
                </div>
            );
        }
        return (
            <div
                style={{ marginTop: 20, marginBottom: 5 }}
                className={`${prefix}__setting-item-control__ratios`}
            >
                {floatRatio?.map((item: Ratio, index: number) => (
                    <div key={item.categoryId} className={`${prefix}__setting-item-control__ratio`}>
                        <div className="title">{item.categoryName}</div>
                        {item.isEdit ? (
                            <InputNumber
                                type="number"
                                min={1}
                                precision={0}
                                max={99999}
                                value={item.ratio}
                                onChange={(value: any) =>
                                    this.onClickRatios('change', index, value)
                                }
                            />
                        ) : (
                            <div>{item.ratio}</div>
                        )}
                        <span>%</span>
                        <Button
                            type="primary"
                            size="small"
                            onClick={() => {
                                let type = item.isEdit ? 'submit' : 'edit';
                                this.onClickRatios(type, index);
                            }}
                        >
                            {item.isEdit
                                ? language.getText('common.save')
                                : language.getText('common.edit')}
                        </Button>
                    </div>
                ))}
            </div>
        );
    }
    render() {
        const { activeKey, subsites, loading } = this.state;

        return (
            <Spin spinning={loading}>
                <div className={prefix}>
                    <AntTabs
                        className={`${prefix}__tabs`}
                        activeKey={activeKey}
                        animated={false}
                        onChange={this.onChange}
                    >
                        {subsites.map((activeKeyVo: subsite) => {
                            return (
                                <AntTabPane tab={activeKeyVo?.name} key={activeKeyVo?.id}>
                                    <div></div>
                                </AntTabPane>
                            );
                        })}
                    </AntTabs>
                    <div className={`${prefix}__content-wrap`}> {this.renderConfigs()}</div>
                </div>
            </Spin>
        );
    }
}
