import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { mapValues, defaults, get, forEach, debounce } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import './index.less';

export interface CardCollectionAddFormViewProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
    data: any;
}

class cardCollectionAddFormView extends PureComponent<CardCollectionAddFormViewProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }

    render() {
        const { entity, onSubmit, wrappedComponentRef, data } = this.props;
        const { cardStandard, activityType } = data;
        let cardItemControlConfig;
        if (activityType === 'CARD_PIECE' && cardStandard === 'FORMAT_MIX') {
            cardItemControlConfig = {
                maxCount: 4,
                fixed: true,
            };
        } else if (activityType === 'CARD_PIECE' && cardStandard === 'FORMAT_MAX') {
            cardItemControlConfig = {
                maxCount: 9,
                fixed: true,
            };
        } else {
            cardItemControlConfig = {
                maxCount: 10,
            };
        }
        let fields = [
            {
                property: 'baseInfo.name',
                controlConfig: {
                    placeholder: services.language.getText('qsrhdmc'),
                    maxLength: 100,
                    style: { width: 450 },
                },
            },
            { property: 'baseInfo.dateRange' },
            {
                property: 'baseInfo.subsiteIds',
                controlConfig: {
                    style: { width: 450 },
                },
            },
            { property: 'baseInfo.marketingSchedule' },
            {
                property: 'cardConfig.id',
                className: 'property-hide',
            },
            {
                property: 'activityType',
                className: 'property-left',
            },
            {
                property: 'cardStandard',
                className: 'property-left',
                visible: (values: any) => {
                    return get(values, 'activityType') === 'CARD_PIECE';
                },
            },
            {
                property: 'cardBigPic',
                extra: services.language.getText('picLimit540'),
            },
            {
                property: 'cardItemConfig',
                controlConfig: cardItemControlConfig,
            },
            {
                property: 'ruleConfig',
            },
            {
                property: 'ruleConfig1',
            },
            {
                property: 'ruleConfig2',
            },
            {
                property: 'ruleConfig3',
            },
            {
                property: 'ruleConfig4',
            },
            {
                property: 'ruleConfig5',
            },
            {
                property: 'picConfig.id',
                className: 'property-hide',
            },
            {
                property: 'cardBg',
                extra: services.language.getText('picLimit750'),
            },
            {
                property: 'cardRulePic',
                extra: services.language.getText('picLimit140'),
            },
            {
                property: 'activityIntro',
                className: 'property-left',
                controlConfig: {
                    style: { width: 450 },
                    maxLength: 1000,
                },
            },
            {
                property: 'cardRewardPic',
                extra: services.language.getText('picLimit140'),
            },
            {
                property: 'cardRewardBtnPic',
                extra: services.language.getText('picLimit480'),
            },
            {
                property: 'cardRuleBtnPic',
                extra: services.language.getText('picLimit100'),
            },
            {
                property: 'cardPosterPic',
                extra: services.language.getText('picLimit600'),
            },
            {
                property: 'cardSharePic',
                extra: services.language.getText('picLimit420'),
            },
            {
                property: 'cardHelpPic',
            },
            {
                property: 'rewardInfo.id',
                className: 'property-hide',
            },
            {
                property: 'rewardLimit',
                className: 'property-left',
            },
        ];

        let formProps = {
            entity,
            componentId: 'CardCollectionAddFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'card-collection-add-form-container',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 820 },
            fields: fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: services.language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('CreateFormPlus'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;
    let form: any;

    /**
     * 提交前额外验证
     * @param entityFields
     */
    const validate = (entityFields: any) => {
        const { rewardLimit, activityType, cardItemConfig } = entityFields;
        // 奖品设置
        if (rewardLimit && rewardLimit.length) {
            let hasValidate = true;
            const hasValueArr = rewardLimit.filter((i: any) => i.rewardType);
            if (!hasValueArr.length) {
                AntMessage.warning(services.language.getText('jpszzbxxzyx'));
                return false;
            }
            forEach(rewardLimit, (item: any) => {
                if (item.rewardType === 'POINT' && (!item.point || !item.count)) {
                    AntMessage.warning(services.language.getText('qtxjpszxzhdjfhzfs'));
                    hasValidate = false;
                    return false;
                }
                if (
                    item.rewardType === 'COUPON' &&
                    (!item.couponsValue || !item.couponsValue.length)
                ) {
                    AntMessage.error(services.language.getText('qtxjpszxzhdyhqhzyhqlb'));
                    hasValidate = false;
                    return false;
                }
                if (item.rewardType === 'COUPON' && !item.coupons) {
                    AntMessage.warning(services.language.getText('qtxjpszxzhdyhqfs'));
                    hasValidate = false;
                    return false;
                }
            });
            if (!hasValidate) {
                return false;
            }
        }
        if (cardItemConfig && cardItemConfig.length) {
            const notEmptyArr = cardItemConfig.filter(
                (i: any) => i.percent || get(i, 'cardCollectionItemPicture.length')
            );
            if (!notEmptyArr.length) {
                AntMessage.warning(services.language.getText('kpszbnwk'));
                return false;
            }
        }

        if (activityType === 'CARD_COLLECTION') {
            let hasValidate = true;
            if (cardItemConfig && cardItemConfig.length) {
                forEach(cardItemConfig, (item: any) => {
                    if (
                        (!item.percent && get(item, 'cardCollectionItemPicture.length')) ||
                        (item.percent && !get(item, 'cardCollectionItemPicture.length'))
                    ) {
                        AntMessage.warning(services.language.getText('qtxwzkpszzdmyx'));
                        hasValidate = false;
                        return false;
                    }
                });

                if (!hasValidate) {
                    return false;
                }
            }
        }

        if (activityType === 'CARD_PIECE') {
            let hasValidate = true;
            if (cardItemConfig && cardItemConfig.length) {
                forEach(cardItemConfig, (item: any) => {
                    if (!item.percent || !get(item, 'cardCollectionItemPicture.length')) {
                        AntMessage.warning(services.language.getText('qtxwzkpszzdmyx'));
                        hasValidate = false;
                        return false;
                    }
                });

                if (!hasValidate) {
                    return false;
                }
            }
        }
        if (cardItemConfig && cardItemConfig.length) {
            const percentArr = cardItemConfig
                .filter((i: any) => i.percent)
                .map((i: any) => i.percent)
                .reduce((a: number, b: number) => a + b);
            if (percentArr !== 100) {
                AntMessage.warning(services.language.getText('kphqjlzhbdy'));
                return false;
            }
        }

        let checkedCount = 0;

        const ruleConfig1 = get(entityFields, 'ruleConfig1[0]');

        if (ruleConfig1.checked) {
            checkedCount++;
            if (!ruleConfig1.count2) {
                AntMessage.warning(services.language.getText('gz1bnwk'));
                return false;
            }
        }

        const ruleConfig2 = get(entityFields, 'ruleConfig2[0]');

        if (ruleConfig2.checked) {
            checkedCount++;
            if (!ruleConfig2.count2) {
                AntMessage.warning(services.language.getText('gz2bnwk'));
                return false;
            }
        }

        if (get(entityFields, 'ruleConfig3') && get(entityFields, 'ruleConfig3[0].checked')) {
            checkedCount++;
            let hasValidate = true;
            const emptyArr = entityFields.ruleConfig3.filter((i: any) => !i.count1 || !i.count2);
            if (emptyArr.length) {
                AntMessage.warning(services.language.getText('gz3bnwk'));
                return false;
            }
            const count1s = entityFields.ruleConfig3.map((i: any) => i.count1);
            for (let index = 0; index < count1s.length; index++) {
                if (index + 1 > count1s.length) return;
                const current = count1s[index];
                const next = count1s[index + 1];
                if (current >= next) {
                    hasValidate = false;
                    AntMessage.warning(services.language.getText('lxyqrsxydxdysydrs'));
                    break;
                }
            }
            if (!hasValidate) {
                return false;
            }
        }
        if (get(entityFields, 'ruleConfig4') && get(entityFields, 'ruleConfig4[0].checked')) {
            checkedCount++;
            let hasValidate = true;
            const emptyArr = entityFields.ruleConfig4.filter((i: any) => !i.count1 || !i.count2);
            if (emptyArr.length) {
                AntMessage.warning(services.language.getText('gz4bnwk'));
                return false;
            }
            const count1s = entityFields.ruleConfig4.map((i: any) => i.count1);
            for (let index = 0; index < count1s.length; index++) {
                if (index + 1 > count1s.length) return;
                const current = count1s[index];
                const next = count1s[index + 1];
                if (current >= next) {
                    hasValidate = false;
                    AntMessage.warning(services.language.getText('lxyqrsxydxdysydrs'));
                    break;
                }
            }
            if (!hasValidate) {
                return false;
            }
        }

        if (get(entityFields, 'ruleConfig5') && get(entityFields, 'ruleConfig5[0].checked')) {
            checkedCount++;
            let hasValidate = true;
            const emptyArr = entityFields.ruleConfig5.filter((i: any) => !i.count1 || !i.count2);
            if (emptyArr.length) {
                AntMessage.warning(services.language.getText('gz5bnwk'));
                return false;
            }
            const count1s = entityFields.ruleConfig5.map((i: any) => i.count1);
            for (let index = 0; index < count1s.length; index++) {
                if (index + 1 > count1s.length) return;
                const current = count1s[index];
                const next = count1s[index + 1];
                if (current >= next) {
                    hasValidate = false;
                    AntMessage.warning(services.language.getText('ljxfxydxdysydje'));
                    break;
                }
            }
            if (!hasValidate) {
                return false;
            }
        }

        if (!checkedCount) {
            AntMessage.warning(services.language.getText('qzsxzyzgz'));
            return false;
        }

        return true;
    };

    return defaults(
        {
            onSubmit,
            onFieldChange,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: debounce((event: any, fields: any) => {
                const entityFields = mapValues(fields, (field, name) => {
                    return field.value;
                });
                if (entityFields) {
                    const hasValidate = validate(entityFields);
                    if (hasValidate) {
                        entity.add(entityFields, params);
                    }
                }
            }, 300),
            data:
                get(
                    _dispatch,
                    'components.["DataFormPlus-CardCollectionAddFormContainer"].fields'
                ) || {},

            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(
                        actions.unmountComponentAction(
                            'DataFormPlus-CardCollectionAddFormContainer'
                        )
                    );
            },
        }
    );
}
export const CardCollectionAddFormView = connect(mapDispatchToProps)(cardCollectionAddFormView);
