import * as React from 'react';
import classNames from 'classnames';
import { map, find } from 'lodash';
import { ArrayMode } from '@comall-backend-builder/types';
import { MemberLevelParkBenefit } from '../../../components';
import { services } from '@comall-backend-builder/core';

export class ArrayMemberLevelParkBenefitMode extends ArrayMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(array: any, displayInfo: any) {
        const { className, style, options } = displayInfo;
        if (array && array.length > 0) {
            return (
                <div className={classNames('type-array', className)} style={style}>
                    {map(array, ({ userLevelId, consumptionAmount, coversParkFee }, index) => {
                        let userLevelIdOption = find(options, { id: userLevelId }) || {};
                        return (
                            <div key={index}>
                                <span>会员卡等级： {userLevelIdOption.name} </span>
                                <span>
                                    消费满{consumptionAmount}
                                    {services.language.getText('yuan')} ={' '}
                                </span>
                                <span>
                                    {coversParkFee}
                                    {services.language.getText('yuan')}停车费
                                </span>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return this.getNotAvailableDisplayComponent(displayInfo);
        }
    }
    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            '暂无数据'
        );
    }
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <MemberLevelParkBenefit {...controlInfo} />;
    }
}
