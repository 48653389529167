import { Empty as AntEmpty, Tree as AntTree } from 'antd';
import React, { CSSProperties, PureComponent } from 'react';
import { assign } from 'lodash';
import './index.less';

export interface TreeData {
    children: TreeData[];
    name: string;
    id: string;
    [key: string]: any;
}
interface SelectTreeProps {
    result?: TreeData[];
    selectedTreeNode?: TreeData | undefined;
    onSelect(selectedTreeNode: TreeData): void;
    style?: CSSProperties;
    treeProps?: any;
}
interface RoleManageTreeState {
    expandedKeys: string[];
}

const { TreeNode: AntTreeNode } = AntTree;

export class SelectTree extends PureComponent<SelectTreeProps, RoleManageTreeState> {
    static defaultProps = {
        onSelect() {},
    };

    constructor(props: SelectTreeProps) {
        super(props);
        this.state = {
            expandedKeys: [],
        };
    }
    componentDidMount() {
        if (this.props.result && this.props.result?.length) {
            const selectedTreeNodeObject = this.props.result[0];
            this.setState({
                expandedKeys: [selectedTreeNodeObject.id],
            });
        }
    }

    private onExpand = (expandedKeys: string[]) => {
        this.setState({ expandedKeys });
    };

    private onSelect = (selectedKeys: string[], e: any) => {
        const { onSelect } = this.props;
        const selectedNode = e.selectedNodes && e.selectedNodes.length ? e.selectedNodes[0] : {};
        const selectedTreeNodeObject = selectedNode.props ? selectedNode.props.dataRef : {};
        onSelect(selectedTreeNodeObject);
    };

    private renderTreeNode = (datas: TreeData[] = []) => {
        return datas.map((data) => (
            <AntTreeNode title={data.name} key={data.id} dataRef={data}>
                {this.renderTreeNode(data.children || [])}
            </AntTreeNode>
        ));
    };

    render() {
        const { result, style, selectedTreeNode, treeProps } = this.props;
        const { expandedKeys } = this.state;
        const windowHeight = window.outerHeight - 300;
        const wrapStyle = assign({}, style, { height: windowHeight });
        const selectedKeys = selectedTreeNode ? [selectedTreeNode.id] : [];
        return (
            <div className="select-tree" style={wrapStyle}>
                {result?.length ? (
                    <AntTree
                        onSelect={this.onSelect}
                        expandedKeys={expandedKeys}
                        onExpand={this.onExpand}
                        selectedKeys={selectedKeys}
                        {...treeProps}
                    >
                        {this.renderTreeNode(result)}
                    </AntTree>
                ) : (
                    <AntEmpty image={AntEmpty.PRESENTED_IMAGE_SIMPLE} />
                )}
            </div>
        );
    }
}
