interface SceneTargetOption {
    name: string;
    path: string;
}

export interface WechantTemplatesOption {
    value: string;
    label?: string;
    children?: WechantTemplatesOption[];
}

/**
 * 发表评价页
 */
const offlineOrderComment = {
    path:
        'sub-packages/offline-order-comment/pages/offline-order-comment/index?id={posReceiptId}&orderNo={orderNo}&posReceiptNo={posReceiptNo}&subsiteId={subsiteId}',
    name: '发表评价页',
};

/**
 * 积分商城页
 */
const creditShop = {
    path: 'subpackages/credit-shop/index?subsiteId={subsiteId}',
    name: '积分商城页',
};

/**
 * 积分明细页
 */
const pointInfo = {
    path: 'sub-packages/mine/pages/point-info/index?subsiteId={subsiteId}',
    name: '积分明细页',
};

/**
 * 停车缴费页
 */
const parkingFee = {
    path: 'subpackages/parking-fee/index?subsiteId={subsiteId}',
    name: '停车缴费页',
};

/**
 * 会员中心页
 */
const memberCenter = {
    path: 'pages/member-center/index?subsiteId={subsiteId}',
    name: '会员中心页',
};

/**
 * 我的优惠券
 */
const couponsCenter = {
    path: 'subpackages/coupons-center/index?subsiteId={subsiteId}',
    name: '我的优惠券',
};

/**
 * 会员权益页
 */
const memberCardLevelRights = {
    path: 'subpackages/member-card-level-rights/index?subsiteId={subsiteId}',
    name: '会员权益页',
};

/**
 * 首页
 */
const home = {
    path: 'pages/home/index?subsiteId={subsiteId}',
    name: '首页',
};

/**
 * 专题活动
 */
const topic = {
    path: 'subpackages/topic/index?subsiteId={subsiteId}',
    name: '专题活动',
};

/**
 * 专题活动
 */
const myBalanceCoupons = {
    path: 'subpackages/coupons-center/pages/my-balance-coupons/index?subsiteId={subsiteId}',
    name: '我的余额券',
};

const login = { path: 'subpackages/login/pages/login-choice/index', name: '注册登录页' };

export const manualOptions = [
    {
        value: 'pages/platform/index',
        label: '平台首页',
    },
    {
        value: 'pages/home/index?subsiteId={subsiteId}',
        label: '首页',
    },
    {
        value: 'subpackages/topic/index?subsiteId={subsiteId}',
        label: '专题活动',
    },
    {
        value: 'pages/member-center/index?subsiteId={subsiteId}',
        label: '会员中心页',
    },
    {
        value: 'subpackages/live-list/index?subsiteId={subsiteId}',
        label: '微信直播列表',
    },
    {
        value: 'plugin-private://wx2b03c6e691cd7370/pages/live-player-plugin?subsiteId={subsiteId}',
        label: '直播间',
    },
    {
        value: 'marketing',
        label: '营促销活动',
        children: [
            {
                value: 'subpackages/flash-sale/index?subsiteId={subsiteId}',
                label: '秒杀列表',
            },
            {
                value: 'subpackages/wheel-draw/pages/detail/index?subsiteId={subsiteId}',
                label: '大转盘抽奖',
            },
            {
                value: 'subpackages/invite-reward/pages/detail/index?subsiteId={subsiteId}',
                label: '邀请有礼',
            },
            {
                value: 'subpackages/sign-in-with-gift/index?subsiteId={subsiteId}',
                label: '签到有礼',
            },
            {
                value: 'subpackages/card-collection/index?subsiteId={subsiteId}',
                label: '集卡活动',
            },
            {
                value: 'subpackages/packet-rain/index?subsiteId={subsiteId}',
                label: '红包雨',
            },
            {
                value: 'subpackages/group-buying/pages/list/index?subsiteId={subsiteId}',
                label: '普通商品拼团列表',
            },
            {
                value: 'subpackages/group-buying/pages/virtual-list/index?subsiteId={subsiteId}',
                label: '虚拟商品拼团列表',
            },
            {
                value: 'subpackages/blind-box/pages/list/index?subsiteId={subsiteId}',
                label: '盲盒活动列表',
            },
            {
                value: 'subpackages/blind-box/pages/detail/index?subsiteId={subsiteId}',
                label: '盲盒活动',
            },
            {
                value:
                    'subpackages/activities/pages/presale-activities/index?mode=FULL_PAYMENT&subsiteId={subsiteId}',
                label: '全款预售',
            },
            {
                value:
                    'subpackages/activities/pages/presale-activities/index?mode=RESERVE&subsiteId={subsiteId}',
                label: '预约抢购',
            },
        ],
    },
    {
        value: 'credit',
        label: '积分活动',
        children: [
            {
                value: 'subpackages/credit-shop/index?subsiteId={subsiteId}',
                label: '积分商城',
            },
            {
                value: 'subpackages/scratch-card/index?subsiteId={subsiteId}',
                label: '积分刮刮卡',
            },
            {
                value: 'subpackages/lottery-code/index?subsiteId={subsiteId}',
                label: '积分抽奖码',
            },
            {
                value: 'subpackages/self-crediting/pages/main/index?subsiteId={subsiteId}',
                label: '自助积分',
            },
            {
                value: 'plugin://business-circle-point-plugin/index?subsiteId={subsiteId}',
                label: '商圈积分',
            },
        ],
    },
    {
        value: 'member-menu',
        label: '会员活动相关',
        children: [
            {
                value:
                    'subpackages/mall-activity/pages/activity-detail/index?subsiteId={subsiteId}',
                label: '商场活动',
            },
            {
                value:
                    'subpackages/mall-activity/pages/activity-calendar/index?subsiteId={subsiteId}',
                label: '商场活动日历',
            },
            {
                value: 'subpackages/mall-activity/index?type=list&subsiteId={subsiteId}',
                label: '商场活动列表',
            },
        ],
    },
    {
        value: 'service',
        label: '服务工具',
        children: [
            {
                value: 'subpackages/parking-fee/index?subsiteId={subsiteId}',
                label: '停车缴费',
            },
            {
                value:
                    'subpackages/parking-fee/pages/parking-fee-records/index?subsiteId={subsiteId}',
                label: '缴费记录',
            },
            {
                value: 'subpackages/coupons-center/index',
                label: '我的优惠券',
            },
            {
                value:
                    'subpackages/coupons-center/pages/my-balance-coupons/index?subsiteId={subsiteId}',
                label: '我的余额券',
            },
        ],
    },
];

/**
 * 模版类型
 */
export enum SceneType {
    /**
     * 消费成功通知
     */
    POS_RECEIPT_ORDER_COMMENT = 'POS_RECEIPT_ORDER_COMMENT',
    /**
     * 账户变更通知(原 积分变更提醒)
     */
    ACCOUNT_CHANGE_MESSAGE = 'ACCOUNT_CHANGE_MESSAGE',
    /**
     * 注册成功提醒
     */
    FOLLOW_OFFICIAL_ACCOUNT_REMIND = 'FOLLOW_OFFICIAL_ACCOUNT_REMIND',
    /**
     * 停车入场通知
     */
    PARKING_ENTRANCE_NOTICE = 'PARKING_ENTRANCE_NOTICE',
    /**
     * 会员卡等级变动提醒
     */
    MEMBER_CARD_LEVEL_CHANGE_REMIND = 'MEMBER_CARD_LEVEL_CHANGE_REMIND',
    /**
     * 业务到期通知(积分到期提醒)
     */
    BUSINESS_EXPIRE_REMIND = 'BUSINESS_EXPIRE_REMIND',
    /**
     * 营业状态
     */
    SUBSITE_BUSINESS_STATUS_CHANGE = 'SUBSITE_BUSINESS_STATUS_CHANGE',
    /**
     * 新订单通知
     */
    NEW_ORDER_REMIND = 'NEW_ORDER_REMIND',
    /**
     * 会员消费成功提醒
     */
    POS_RECEIPT_ORDER_PAYMENT_REMIND = 'POS_RECEIPT_ORDER_PAYMENT_REMIND',
    /**
     * 业务到期通知-手动(原 积分预到期提醒-手动)
     */
    BUSINESS_EXPIRE_MANUAL_REMIND = 'BUSINESS_EXPIRE_MANUAL_REMIND',
    /**
     * 报名成功
     */
    APPOINTMENT_SUCCESS_REMIND = 'APPOINTMENT_SUCCESS_REMIND',
    /**
     * 车辆出场通知
     */
    PARKING_DEPARTURE_NOTICE = 'PARKING_DEPARTURE_NOTICE',
    /**
     * 活动通知
     */
    ACTIVITY_NOTICE = 'ACTIVITY_NOTICE',
    /**
     * 余额券账户变更通知
     */
    MEMBER_ACCOUNT_BALANCE_CHANGE_MESSAGE = 'MEMBER_ACCOUNT_BALANCE_CHANGE_MESSAGE',
    /**
     * 手动添加
     */
    MANUAL_ADD = 'MANUAL_ADD',
}

/**
 * 模版类型对应可选链接
 */
export const targetOptions: Record<SceneType, SceneTargetOption[]> = {
    [SceneType.POS_RECEIPT_ORDER_COMMENT]: [offlineOrderComment],
    [SceneType.ACCOUNT_CHANGE_MESSAGE]: [creditShop, pointInfo, parkingFee, memberCenter],
    [SceneType.FOLLOW_OFFICIAL_ACCOUNT_REMIND]: [login],
    [SceneType.MEMBER_CARD_LEVEL_CHANGE_REMIND]: [
        memberCenter,
        couponsCenter,
        memberCardLevelRights,
        creditShop,
    ],
    [SceneType.PARKING_ENTRANCE_NOTICE]: [parkingFee, memberCenter, topic, home],
    [SceneType.BUSINESS_EXPIRE_REMIND]: [creditShop, pointInfo, parkingFee, topic],
    [SceneType.SUBSITE_BUSINESS_STATUS_CHANGE]: [memberCenter, home, topic],
    [SceneType.NEW_ORDER_REMIND]: [],
    [SceneType.POS_RECEIPT_ORDER_PAYMENT_REMIND]: [creditShop, couponsCenter, topic],
    [SceneType.BUSINESS_EXPIRE_MANUAL_REMIND]: [creditShop, pointInfo, parkingFee, topic],
    [SceneType.APPOINTMENT_SUCCESS_REMIND]: [memberCenter, home, topic],
    [SceneType.PARKING_DEPARTURE_NOTICE]: [memberCenter, parkingFee, topic, home],
    [SceneType.MEMBER_ACCOUNT_BALANCE_CHANGE_MESSAGE]: [myBalanceCoupons],
    [SceneType.MANUAL_ADD]: [creditShop, pointInfo, parkingFee, topic],
    [SceneType.ACTIVITY_NOTICE]: [creditShop, pointInfo, parkingFee, topic],
};

/**
 * 多层级选择器模板
 */
export const wechantTemplatesTargetOptions: Record<SceneType, WechantTemplatesOption[]> = {
    [SceneType.POS_RECEIPT_ORDER_COMMENT]: [
        {
            value:
                'sub-packages/offline-order-comment/pages/offline-order-comment/index?id={posReceiptId}&orderNo={orderNo}&posReceiptNo={posReceiptNo}&subsiteId={subsiteId}',
            label: '发表评价页',
        },
        {
            value: 'service',
            label: '服务工具',
            children: [
                {
                    value: 'subpackages/electronic-bill/pages/list/index?subsiteId={subsiteId}',
                    label: '电子小票',
                },
            ],
        },
    ],
    [SceneType.ACCOUNT_CHANGE_MESSAGE]: [
        {
            value: 'subpackages/credit-shop/index?subsiteId={subsiteId}',
            label: '积分商城页',
        },
        {
            value: 'sub-packages/mine/pages/point-info/index?subsiteId={subsiteId}',
            label: '积分明细页',
        },
        {
            value: 'subpackages/parking-fee/index?subsiteId={subsiteId}',
            label: '停车缴费页',
        },
        {
            value: 'pages/member-center/index?subsiteId={subsiteId}',
            label: '会员中心页',
        },
    ],
    [SceneType.FOLLOW_OFFICIAL_ACCOUNT_REMIND]: [
        { value: 'subpackages/login/pages/login-choice/index', label: '注册登录页' },
    ],
    [SceneType.MEMBER_CARD_LEVEL_CHANGE_REMIND]: [
        {
            value: 'pages/member-center/index?subsiteId={subsiteId}',
            label: '会员中心页',
        },
        {
            value: 'subpackages/coupons-center/index?subsiteId={subsiteId}',
            label: '我的优惠券',
        },
        {
            value: 'subpackages/member-card-level-rights/index?subsiteId={subsiteId}',
            label: '会员权益页',
        },
        {
            value: 'subpackages/credit-shop/index?subsiteId={subsiteId}',
            label: '积分商城页',
        },
    ],
    [SceneType.PARKING_ENTRANCE_NOTICE]: [
        {
            value: 'subpackages/parking-fee/index?subsiteId={subsiteId}',
            label: '停车缴费页',
        },
        {
            value: 'pages/member-center/index?subsiteId={subsiteId}',
            label: '会员中心页',
        },
        {
            value: 'subpackages/topic/index?subsiteId={subsiteId}',
            label: '专题活动',
        },
        {
            value: 'pages/home/index?subsiteId={subsiteId}',
            label: '首页',
        },
    ],
    [SceneType.BUSINESS_EXPIRE_REMIND]: [
        {
            value: 'subpackages/credit-shop/index?subsiteId={subsiteId}',
            label: '积分商城页',
        },
        {
            value: 'sub-packages/mine/pages/point-info/index?subsiteId={subsiteId}',
            label: '积分明细页',
        },
        {
            value: 'subpackages/parking-fee/index?subsiteId={subsiteId}',
            label: '停车缴费页',
        },
        {
            value: 'subpackages/topic/index?subsiteId={subsiteId}',
            label: '专题活动',
        },
    ],
    [SceneType.SUBSITE_BUSINESS_STATUS_CHANGE]: manualOptions,
    [SceneType.NEW_ORDER_REMIND]: [],
    [SceneType.POS_RECEIPT_ORDER_PAYMENT_REMIND]: [
        {
            value: 'subpackages/credit-shop/index?subsiteId={subsiteId}',
            label: '积分商城页',
        },
        {
            value: 'subpackages/coupons-center/index?subsiteId={subsiteId}',
            label: '我的优惠券',
        },
        {
            value: 'subpackages/topic/index?subsiteId={subsiteId}',
            label: '专题活动',
        },
        {
            value: 'pages/member-center/index?subsiteId={subsiteId}',
            label: '会员中心页',
        },
    ],
    [SceneType.BUSINESS_EXPIRE_MANUAL_REMIND]: manualOptions,
    [SceneType.APPOINTMENT_SUCCESS_REMIND]: manualOptions,
    [SceneType.PARKING_DEPARTURE_NOTICE]: [
        {
            value: 'pages/member-center/index?subsiteId={subsiteId}',
            label: '会员中心页',
        },
        {
            value: 'subpackages/parking-fee/index?subsiteId={subsiteId}',
            label: '停车缴费页',
        },
        {
            value: 'subpackages/topic/index?subsiteId={subsiteId}',
            label: '专题活动',
        },
        {
            value: 'pages/home/index?subsiteId={subsiteId}',
            label: '首页',
        },
    ],
    [SceneType.MEMBER_ACCOUNT_BALANCE_CHANGE_MESSAGE]: manualOptions,
    [SceneType.MANUAL_ADD]: manualOptions,
    [SceneType.ACTIVITY_NOTICE]: manualOptions,
};

export enum SendStatus {
    /**
     * 发送中
     */
    SENDING = 'SENDING',
    /**
     * 暂停发送
     */
    STOP = 'STOP',
    /**
     * 取消发送
     */
    CANCELED = 'CANCELED',
    /**
     * 已完成
     */
    COMPLETED = 'COMPLETED',
}

export const SendStatusText: Record<SendStatus, string> = {
    [SendStatus.SENDING]: '发送中',
    [SendStatus.STOP]: '发送中断',
    [SendStatus.CANCELED]: '已取消',
    [SendStatus.COMPLETED]: '已完成',
};

export const SendStatusTip: Record<SendStatus, string> = {
    [SendStatus.SENDING]: '',
    [SendStatus.STOP]:
        '发送中断，可稍后点击“继续发送”重试；如提示“超每日发送上限”需次日点击“继续发送”重试',
    [SendStatus.CANCELED]: '用户设置屏蔽接受消息或者取消关注，均会导致部分用户发送失败',
    [SendStatus.COMPLETED]: '用户设置屏蔽接受消息或者取消关注，均会导致部分用户发送失败',
};

export const specialOptions = [
    {
        value: 'subpackages/send-coupon/pages/coupon-details/index?couponRuleId={couponRuleId}',
        label: '优惠券详情',
    },
    {
        value: 'sub-packages/mine/pages/point-info/index?subsiteId={subsiteId}',
        label: '积分明细',
    },
    {
        value: 'subpackages/product-detail/index?productId={productId}&goodsId={goodsId}',
        label: '商品详情页',
    },
    {
        value:
            'subpackages/credit-shop/pages/credit-shop-details/index?saleRuleId={saleRuleId}&subsiteId={subsiteId}',
        label: '积分商城详情页',
    },
    {
        value: 'subpackages/sign-in-with-gift/index',
        label: '签到有礼页',
    },
    {
        value: 'subpackages/mall-activity/pages/order-detail/index?id={registrationRecordId}',
        label: '商场订单详情页',
    },
    {
        value:
            'subpackages/mall-activity/pages/activity-detail/index?id={activityId}&s={subsiteId}',
        label: '商场活动详情页',
    },
    {
        value: '/subpackages/self-crediting/pages/record/index',
        label: '小票记录页',
    },
    {
        value: 'sub-packages/invite/pages/design/index?type=invite&id={activityId}&sid={subsiteId}',
        label: '邀请有礼页',
    },
    {
        value: 'subpackages/market/bargain/pages/list/index?subsiteId={subsiteId}',
        label: '砍价页',
    },
    {
        value: 'subpackages/lottery-code/index?id={activityId}',
        label: '积分抽奖页',
    },
];

/**
 * 模版类型
 */
export enum PushAndSiteMailSceneType {
    /**
     * 线下消费成功通知
     */
    POS_RECEIPT_ORDER_COMMENT = 'POS_RECEIPT_ORDER_COMMENT',
    /**
     * 账户变更通知(原 积分变更提醒)
     */
    ACCOUNT_CHANGE_MESSAGE = 'ACCOUNT_CHANGE_MESSAGE',
    /**
     * 注册成功提醒
     */
    FOLLOW_OFFICIAL_ACCOUNT_REMIND = 'FOLLOW_OFFICIAL_ACCOUNT_REMIND',
    /**
     * 停车入场通知
     */
    PARKING_ENTRANCE_NOTICE = 'PARKING_ENTRANCE_NOTICE',
    /**
     * 会员卡等级变动提醒
     */
    MEMBER_CARD_LEVEL_CHANGE_REMIND = 'MEMBER_CARD_LEVEL_CHANGE_REMIND',
    /**
     * 业务到期通知(积分到期提醒)
     */
    BUSINESS_EXPIRE_REMIND = 'BUSINESS_EXPIRE_REMIND',
    /**
     * 营业状态
     */
    SUBSITE_BUSINESS_STATUS_CHANGE = 'SUBSITE_BUSINESS_STATUS_CHANGE',
    /**
     * 新订单消息通知
     */
    NEW_ORDER_REMIND = 'NEW_ORDER_REMIND',
    /**
     * 线下消费成功通知
     */
    POS_RECEIPT_ORDER_PAYMENT_REMIND = 'POS_RECEIPT_ORDER_PAYMENT_REMIND',
    /**
     * 业务到期通知-手动(原 积分预到期提醒-手动)
     */
    BUSINESS_EXPIRE_MANUAL_REMIND = 'BUSINESS_EXPIRE_MANUAL_REMIND',
    /**
     * 报名成功
     */
    APPOINTMENT_SUCCESS_REMIND = 'APPOINTMENT_SUCCESS_REMIND',
    /**
     * 车辆出场通知
     */
    PARKING_DEPARTURE_NOTICE = 'PARKING_DEPARTURE_NOTICE',
    /**
     * 活动通知
     */
    ACTIVITY_NOTICE = 'ACTIVITY_NOTICE',
    /**
     * 余额券账户变更通知
     */
    MEMBER_ACCOUNT_BALANCE_CHANGE_MESSAGE = 'MEMBER_ACCOUNT_BALANCE_CHANGE_MESSAGE',
    /**
     * 优惠券到账提醒
     */
    COUPON_RECEIPT_NOTICE = 'COUPON_RECEIPT_NOTICE',
    /**
     * 优惠券到期提醒
     */
    COUPON_EXPIRED_NOTICE = 'COUPON_EXPIRED_NOTICE',
    /**
     * 优惠券开抢提醒
     */
    COUPON_RECEIVE_REMIND = 'COUPON_RECEIVE_REMIND',
    /**
     * 积分商城商品开售提醒
     */
    CREDIT_ESHOP_SALE_REGULAR = 'CREDIT_ESHOP_SALE_REGULAR',
    /**
     * 积分变更提醒
     */
    POINT_ACCOUNT_CHANGE_MESSAGE = 'POINT_ACCOUNT_CHANGE_MESSAGE',
    /**
     * 自提码通知
     */
    ORDER_DOOR_BUSTER = 'ORDER_DOOR_BUSTER',
    /**
     * 商品开售提醒
     */
    GOODS_SALE_REGULAR = 'GOODS_SALE_REGULAR',
    /**
     * 签到提醒
     */
    CHECKIN_NOTICE = 'CHECKIN_NOTICE',
    /**
     * 商场活动报名结果通知
     */
    MALL_ACTIVITY_REGISTRATION_SUCCEEDED_MSG = 'MALL_ACTIVITY_REGISTRATION_SUCCEEDED_MSG',
    /**
     * 已预约活动开始提醒
     */
    MALL_ACTIVITY_PARTICIPATE_MSG = 'MALL_ACTIVITY_PARTICIPATE_MSG',
    /**
     *商场活动名额空缺提醒
     */
    MALL_ACTIVITY_REGISTRATION_ABSENTED_MSG = 'MALL_ACTIVITY_REGISTRATION_ABSENTED_MSG',
    /**
     * 商场活动报名开始提醒
     */
    MALL_ACTIVITY_REGISTRATION_STARTED_MSG = 'MALL_ACTIVITY_REGISTRATION_STARTED_MSG',
    /**
     *拍照积分审核结果通知
     */
    PHOTO_CREDITING_AUDIT = 'PHOTO_CREDITING_AUDIT',
    /**
     *生日赠礼通知
     */
    BIRTHDAY_GIFT_RECEIVE_REMIND = 'BIRTHDAY_GIFT_RECEIVE_REMIND',

    /**
     *邀请成功通知
     */
    INVITATION_REWARD_NOTICE = 'INVITATION_REWARD_NOTICE',
    /**
     *支付有礼通知
     */
    PAY_AWARD_RECEIVE_REMIND = 'PAY_AWARD_RECEIVE_REMIND',
    /**
     *预约抢购开始提醒
     */
    PRESALE_WECHAT_NOTICE = 'PRESALE_WECHAT_NOTICE',
    /**
     *拼团进度通知
     */
    GROUP_BUYING_PROGRESS = 'GROUP_BUYING_PROGRESS',
    /**
     *拼团成功通知
     */
    GROUP_BUYING_SUCCESS = 'GROUP_BUYING_SUCCESS',
    /**
     *抽奖结果通知
     */
    LOTTERY_CODE_AWARD_NOTICE = 'LOTTERY_CODE_AWARD_NOTICE',
    /**
     *砍价结果通知
     */
    BARGAIN_RECORD_RESULT_REMIND = 'BARGAIN_RECORD_RESULT_REMIND',
    /**
     *砍价进度通知
     */
    BARGAIN_RECORD_PROCESS_REMIND = 'BARGAIN_RECORD_PROCESS_REMIND',
    /**
     * 优惠券到账提醒
     */
    THIRD_COUPON_RECEIVE_REMIND = 'THIRD_COUPON_RECEIVE_REMIND',
    /**
     * 优惠券到期提醒
     */
    THIRD_COUPON_EXPIRED_NOTICE = 'THIRD_COUPON_EXPIRED_NOTICE',
    /**
     * 积分变更提醒
     */
    THIRD_POINT_ACCOUNT_CHANGE_MESSAGE = 'THIRD_POINT_ACCOUNT_CHANGE_MESSAGE',
    /**
     * 积分到期提醒
     */
    THIRD_BUSINESS_EXPIRE_REMIND = 'THIRD_BUSINESS_EXPIRE_REMIND',
    /**
     * 生日赠礼提醒
     */
    THIRD_BIRTHDAY_GIFT_RECEIVE_REMIND = 'THIRD_BIRTHDAY_GIFT_RECEIVE_REMIND',
    /**
     * 注册成功通知
     */
    THIRD_REGISTERED_SUCCESS_REMIND = 'THIRD_REGISTERED_SUCCESS_REMIND',
    /**
     * 支付有礼通知
     */
    THIRD_PAY_AWARD_RECEIVE_REMIND = 'THIRD_PAY_AWARD_RECEIVE_REMIND',
    /**
     * 会员卡等级变动提醒
     */
    THIRD_MEMBER_CARD_LEVEL_CHANGE_REMIND = 'THIRD_MEMBER_CARD_LEVEL_CHANGE_REMIND',
    /**
     * 会员到期提醒-保级成功
     */
    THIRD_MEMBER_EXPIRE_UPGRADED_REMIND = 'THIRD_MEMBER_EXPIRE_UPGRADED_REMIND',
    /**
     * 会员到期提醒-未保级
     */
    THIRD_MEMBER_EXPIRE_UNPROTECTED_REMIND = 'THIRD_MEMBER_EXPIRE_UNPROTECTED_REMIND',
}

/**
 * 站内信 push 不同场景的链接类型List
 * 如果是数组则从全部链接类型里过滤展示类型，如果返回的是string 则固定展示在页面返回的页面名称
 */
export const linkTypeList: any = {
    [PushAndSiteMailSceneType.COUPON_RECEIPT_NOTICE]: 'linkTypes.member_coupon',

    [PushAndSiteMailSceneType.COUPON_EXPIRED_NOTICE]: 'linkTypes.member_coupon',
    [PushAndSiteMailSceneType.COUPON_RECEIVE_REMIND]: 'couponDetails',
    [PushAndSiteMailSceneType.CREDIT_ESHOP_SALE_REGULAR]: 'jfscxqy',
    [PushAndSiteMailSceneType.NEW_ORDER_REMIND]: 'linkTypes.none',
    [PushAndSiteMailSceneType.ORDER_DOOR_BUSTER]: 'orderDetail',
    [PushAndSiteMailSceneType.GOODS_SALE_REGULAR]: 'spxqy',
    [PushAndSiteMailSceneType.MALL_ACTIVITY_REGISTRATION_SUCCEEDED_MSG]: 'scddxqy',
    [PushAndSiteMailSceneType.MALL_ACTIVITY_PARTICIPATE_MSG]: 'scddxqy',
    [PushAndSiteMailSceneType.MALL_ACTIVITY_REGISTRATION_ABSENTED_MSG]: 'schdxqy',
    [PushAndSiteMailSceneType.MALL_ACTIVITY_REGISTRATION_STARTED_MSG]: 'schdxqy',
    [PushAndSiteMailSceneType.PHOTO_CREDITING_AUDIT]: 'xpjly',
    [PushAndSiteMailSceneType.POINT_ACCOUNT_CHANGE_MESSAGE]: [
        ['credit', 'member.creditEshop'],
        ['service', 'service.myPoint', 'member.parking'],
    ],
    [PushAndSiteMailSceneType.BUSINESS_EXPIRE_REMIND]: [
        ['credit', 'member.creditEshop'],
        ['service', 'service.myPoint'],
        ['newTopic'],
    ],
    [PushAndSiteMailSceneType.POS_RECEIPT_ORDER_PAYMENT_REMIND]: [
        ['credit', 'member.creditEshop'],
        ['service', 'member.coupon'],
        ['newTopic'],
    ],
    [PushAndSiteMailSceneType.POS_RECEIPT_ORDER_COMMENT]: [
        ['credit', 'member.creditEshop'],
        ['service', 'member.coupon'],
        ['newTopic'],
    ],
    [PushAndSiteMailSceneType.CHECKIN_NOTICE]: 'linkTypes.checkinIndex',
    [PushAndSiteMailSceneType.BIRTHDAY_GIFT_RECEIVE_REMIND]: 'linkTypes.member_birthdayCaring',
    [PushAndSiteMailSceneType.MEMBER_CARD_LEVEL_CHANGE_REMIND]: [
        ['member'],
        ['service', 'member.coupon'],
        ['member.membership'],
        ['credit', 'member.creditEshop'],
    ],
    [PushAndSiteMailSceneType.INVITATION_REWARD_NOTICE]: 'yqyly',
    [PushAndSiteMailSceneType.PAY_AWARD_RECEIVE_REMIND]:
        'linkTypes.marketing_paymentRewardActivity',
    [PushAndSiteMailSceneType.PRESALE_WECHAT_NOTICE]: 'spxqy',
    [PushAndSiteMailSceneType.GROUP_BUYING_PROGRESS]: 'virtualProductOrder',
    [PushAndSiteMailSceneType.GROUP_BUYING_SUCCESS]: 'virtualProductOrder',
    [PushAndSiteMailSceneType.LOTTERY_CODE_AWARD_NOTICE]: 'lotteryCode',
    [PushAndSiteMailSceneType.BARGAIN_RECORD_RESULT_REMIND]:
        'linkTypes.marketing_bargainActivityList',
    [PushAndSiteMailSceneType.BARGAIN_RECORD_PROCESS_REMIND]:
        'linkTypes.marketing_bargainActivityList',
    [PushAndSiteMailSceneType.THIRD_COUPON_RECEIVE_REMIND]: [['service', 'member.coupon']],
    [PushAndSiteMailSceneType.THIRD_COUPON_EXPIRED_NOTICE]: [['service', 'member.coupon']],
    [PushAndSiteMailSceneType.THIRD_POINT_ACCOUNT_CHANGE_MESSAGE]: [
        ['service', 'service.myPoint', 'member.parking'],
        ['credit', 'member.creditEshop'],
    ],
    [PushAndSiteMailSceneType.THIRD_BUSINESS_EXPIRE_REMIND]: [
        ['credit', 'member.creditEshop'],
        ['service', 'service.myPoint'],
        ['newTopic'],
    ],
    [PushAndSiteMailSceneType.THIRD_BIRTHDAY_GIFT_RECEIVE_REMIND]: [
        ['member-menu', 'member.birthdayCaring'],
    ],
    [PushAndSiteMailSceneType.THIRD_REGISTERED_SUCCESS_REMIND]: [
        ['subsite'],
        ['member'],
        ['newTopic'],
    ],
    [PushAndSiteMailSceneType.THIRD_PAY_AWARD_RECEIVE_REMIND]: [
        ['service', 'member.coupon', 'service.myPoint'],
        ['subsite'],
        ['newTopic'],
    ],
    [PushAndSiteMailSceneType.THIRD_MEMBER_CARD_LEVEL_CHANGE_REMIND]: [
        ['member'],
        ['member.membership'],
    ],
    [PushAndSiteMailSceneType.THIRD_MEMBER_EXPIRE_UPGRADED_REMIND]: [
        ['member'],
        ['member.membership'],
        ['subsite'],
        ['newTopic'],
    ],
    [PushAndSiteMailSceneType.THIRD_MEMBER_EXPIRE_UNPROTECTED_REMIND]: [
        ['member'],
        ['member.membership'],
        ['subsite'],
        ['newTopic'],
    ],
};

export const pathRedirectType = [
    PushAndSiteMailSceneType.COUPON_RECEIPT_NOTICE,
    PushAndSiteMailSceneType.COUPON_EXPIRED_NOTICE,
    PushAndSiteMailSceneType.COUPON_RECEIVE_REMIND,
    PushAndSiteMailSceneType.CREDIT_ESHOP_SALE_REGULAR,
    PushAndSiteMailSceneType.NEW_ORDER_REMIND,
    PushAndSiteMailSceneType.ORDER_DOOR_BUSTER,
    PushAndSiteMailSceneType.GOODS_SALE_REGULAR,
    PushAndSiteMailSceneType.CHECKIN_NOTICE,
    PushAndSiteMailSceneType.MALL_ACTIVITY_REGISTRATION_SUCCEEDED_MSG,
    PushAndSiteMailSceneType.MALL_ACTIVITY_PARTICIPATE_MSG,
    PushAndSiteMailSceneType.MALL_ACTIVITY_REGISTRATION_ABSENTED_MSG,
    PushAndSiteMailSceneType.MALL_ACTIVITY_REGISTRATION_STARTED_MSG,
    PushAndSiteMailSceneType.PHOTO_CREDITING_AUDIT,
    PushAndSiteMailSceneType.BIRTHDAY_GIFT_RECEIVE_REMIND,
    PushAndSiteMailSceneType.INVITATION_REWARD_NOTICE,
    PushAndSiteMailSceneType.PAY_AWARD_RECEIVE_REMIND,
    PushAndSiteMailSceneType.PRESALE_WECHAT_NOTICE,
    PushAndSiteMailSceneType.GROUP_BUYING_PROGRESS,
    PushAndSiteMailSceneType.GROUP_BUYING_SUCCESS,
    PushAndSiteMailSceneType.LOTTERY_CODE_AWARD_NOTICE,
    PushAndSiteMailSceneType.BARGAIN_RECORD_RESULT_REMIND,
    PushAndSiteMailSceneType.BARGAIN_RECORD_PROCESS_REMIND,
];

export enum SpecialType {
    // 需要参数拼接 如：发表评论和电子小票
    ParameterConcatenation = 'parameterConcatenation',
    // 使用微信订阅地址直接跳转
    PathRedirect = 'pathRedirect',
}

export const disableEditing = [
    PushAndSiteMailSceneType.BIRTHDAY_GIFT_RECEIVE_REMIND,
    PushAndSiteMailSceneType.PAY_AWARD_RECEIVE_REMIND,
    PushAndSiteMailSceneType.MALL_ACTIVITY_REGISTRATION_SUCCEEDED_MSG,
    PushAndSiteMailSceneType.MALL_ACTIVITY_PARTICIPATE_MSG,
    PushAndSiteMailSceneType.MALL_ACTIVITY_REGISTRATION_ABSENTED_MSG,
    PushAndSiteMailSceneType.MALL_ACTIVITY_REGISTRATION_STARTED_MSG,
    PushAndSiteMailSceneType.LOTTERY_CODE_AWARD_NOTICE,
];
