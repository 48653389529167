import React, { PureComponent } from 'react';
import { Table as AntTable, Row as AntRow, Col as AntCol, Card as AntCard, Modal } from 'antd';
import { services } from '@comall-backend-builder/core';
import { map } from 'lodash';

interface State {
    info?: any;
    posInfo?: any;
    previewPictureVisible: boolean;
    previewPictureImage: string;
}

interface Props {
    params: any;
}

const api = services.api;
export class DepositAfterSaleDetail extends PureComponent<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            info: undefined,
            posInfo: undefined,
            previewPictureVisible: false,
            previewPictureImage: '',
        };
    }
    componentDidMount() {
        const id = this.props.params.id;
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`,
                apiPath: `/admin/deposit_order_service_applies/${id}`,
            }
        ).then((response: any) => {
            this.setState({
                info: response,
            });
            this.loadPosReceipts();
        });
    }

    loadPosReceipts = () => {
        const id = this.props.params.id;
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`,
                apiPath: `/admin/deposit_orders/${id}/pos_receipts`,
            }
        ).then((response: any) => {
            this.setState({
                posInfo: response,
            });
        });
    };

    getRefundStatus = (text: string) => {
        let refundStatusName = '';
        if (text === 'APPLYING') {
            refundStatusName = services.language.getText('wcl');
        } else if (text === 'CANCEL') {
            refundStatusName = services.language.getText('yqx');
        } else if (text === 'APPLY_SUCC') {
            refundStatusName = services.language.getText('approved');
        } else if (text === 'REFUND_SUCC') {
            refundStatusName = services.language.getText('tkcg');
        } else if (text === 'REFUND_FAIL') {
            refundStatusName = services.language.getText('tksb');
        } else if (text === 'REFUND_PART_SUCC') {
            refundStatusName = services.language.getText('tkbfcg');
        }
        return refundStatusName;
    };

    renderTable = () => {
        const { info } = this.state;
        if (!info) {
            return null;
        }
        const list = [
            {
                merchantBigCode: info.depositOrder.merchantBigCode,
                createTime: info.depositOrder.orderCreateTime,
                orderAmount: info.depositOrder.orderAmount,
                refundAmount: info.depositOrder.orderAmount,
                refundItemMode: '原路返回',
                interfaceStatus: info.refundInfo
                    ? this.getRefundStatus(info.refundInfo.interfaceStatus)
                    : '',
            },
        ];
        const tableProps = {
            loading: false,
            scroll: { x: true },
            columns: [
                {
                    title: services.language.getText('djsjm'),
                    key: 'merchantBigCode',
                    dataIndex: 'merchantBigCode',
                },
                {
                    title: services.language.getText('xdsj'),
                    key: 'createTime',
                    dataIndex: 'createTime',
                },
                {
                    title: services.language.getText('sfje'),
                    dataIndex: 'orderAmount',
                    key: 'orderAmount',
                },
                {
                    title: services.language.getText('tkje'),
                    dataIndex: 'refundAmount',
                    key: 'refundAmount',
                },
                {
                    title: services.language.getText('tkfs'),
                    dataIndex: 'refundItemMode',
                    key: 'refundItemMode',
                },
                {
                    title: services.language.getText('tkzt'),
                    dataIndex: 'interfaceStatus',
                    key: 'interfaceStatus',
                },
            ],
        };
        return (
            <AntRow style={{ marginBottom: 20 }}>
                <AntCard type="inner" title={services.language.getText('ddshxx')}>
                    <AntTable
                        style={{ overflowX: 'scroll' }}
                        rowKey={(record, index) => index.toString()}
                        dataSource={list}
                        {...tableProps}
                    />
                </AntCard>
            </AntRow>
        );
    };

    handlePicturePreview = (url: string) => {
        if (url) {
            this.setState({
                previewPictureImage: url,
                previewPictureVisible: true,
            });
        }
    };

    handlePictureCancel = () => this.setState({ previewPictureVisible: false });

    render() {
        const { info, previewPictureVisible, previewPictureImage } = this.state;
        if (!info) {
            return null;
        }
        return (
            <div className="coupon-order-info">
                <div>
                    <AntRow style={{ marginBottom: 20 }}>
                        <AntRow style={{ marginBottom: 20 }}>
                            <AntCard type="inner" title={services.language.getText('hyxx')}>
                                <AntRow style={{ marginBottom: 10 }}>
                                    <AntCol span={8}>
                                        {services.language.getText('hysjh')}：
                                        {info.depositOrder.memberInfo.mobile}
                                    </AntCol>
                                    <AntCol span={8}>
                                        {services.language.getText('nickName')}：
                                        {info.depositOrder.memberInfo.nickName}
                                    </AntCol>
                                </AntRow>
                            </AntCard>
                        </AntRow>
                        <AntCard type="inner" title={services.language.getText('refundInfo')}>
                            <AntRow style={{ marginBottom: 10 }}>
                                <AntCol span={8}>
                                    {services.language.getText('djdh')}：{info.depositOrder.orderNo}
                                </AntCol>
                                <AntCol span={8}>
                                    {services.language.getText('subsites')}：
                                    {info.depositOrder.subsite.name}
                                </AntCol>
                                <AntCol span={8}>
                                    {services.language.getText('merchant')}：
                                    {info.depositOrder.merchant.name}
                                </AntCol>
                            </AntRow>
                            <AntRow style={{ marginBottom: 10 }}>
                                <AntCol span={8}>
                                    {services.language.getText('xdsj')}：
                                    {info.depositOrder.orderCreateTime}
                                </AntCol>
                                <AntCol span={8}>
                                    {services.language.getText('kddg')}：
                                    {info.depositOrder.guideInfo.name}
                                </AntCol>
                                {info.depositOrder.paymentRecords &&
                                    info.depositOrder.paymentRecords.length > 0 && (
                                        <AntCol span={8}>
                                            {services.language.getText('zfptshddh')}：
                                            {info.depositOrder.paymentRecords[0].merchantTradeNo}
                                        </AntCol>
                                    )}
                            </AntRow>
                            <AntRow style={{ marginBottom: 10 }}>
                                <AntCol span={8}>
                                    {services.language.getText('afterSalesOrderNo')}：
                                    {info.serviceApplyNo}
                                </AntCol>
                                <AntCol span={8}>
                                    {services.language.getText('sqshsj')}：{info.createTime}
                                </AntCol>
                                <AntCol span={8}>
                                    {services.language.getText('shsqr')}：{info.applyUser}
                                </AntCol>
                            </AntRow>
                            <AntRow style={{ marginBottom: 10 }}>
                                {info.refundInfo && info.refundInfo.interfaceStatus && (
                                    <AntCol span={8}>
                                        {services.language.getText('shzt')}：
                                        {this.getRefundStatus(info.refundInfo.interfaceStatus)}
                                    </AntCol>
                                )}
                                {info.auditUser && (
                                    <AntCol span={8}>
                                        {services.language.getText('shr_1')}：{info.auditUser}
                                    </AntCol>
                                )}
                                {info.auditTime && (
                                    <AntCol span={8}>
                                        {services.language.getText('shsj')}：{info.auditTime}
                                    </AntCol>
                                )}
                            </AntRow>
                            <AntRow style={{ marginBottom: 10 }}>
                                <AntCol>
                                    {services.language.getText('sqyy')}：{info.reason}
                                </AntCol>
                            </AntRow>
                            <AntRow style={{ marginBottom: 10 }}>
                                <AntCol>
                                    {services.language.getText('khbz')}：{info.remark}
                                </AntCol>
                            </AntRow>
                            <AntRow style={{ marginBottom: 10 }}>
                                <AntCol>{services.language.getText('kfpz')}：</AntCol>
                                <AntCol span={20} style={{ display: 'flex' }}>
                                    {map(info.pictures, (picture) => {
                                        return (
                                            <span key={picture.id}>
                                                <img
                                                    style={{ marginRight: '10px' }}
                                                    width={104}
                                                    height={104}
                                                    alt={services.language.getText('khpz')}
                                                    src={picture.url}
                                                    onClick={this.handlePicturePreview.bind(
                                                        this,
                                                        picture.url
                                                    )}
                                                />
                                            </span>
                                        );
                                    })}
                                    <Modal
                                        visible={previewPictureVisible}
                                        footer={null}
                                        onCancel={this.handlePictureCancel}
                                    >
                                        <img
                                            alt="example"
                                            style={{ width: '100%' }}
                                            src={previewPictureImage}
                                        />
                                    </Modal>
                                </AntCol>
                            </AntRow>
                        </AntCard>
                    </AntRow>
                    {this.renderTable()}
                </div>
            </div>
        );
    }
}
