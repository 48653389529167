import React, { PureComponent } from 'react';
import { Select, Popover, Button } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services';

export class ArrayVirtualCategory extends PureComponent<any> {
    handleChange = (val: string[]) => {
        const { onChange } = this.props;
        onChange(val || []);
    };

    render() {
        const { value, options, extra } = this.props;
        let style = {
            maxWidth: 'auto',
        };
        return (
            <div>
                <Select
                    mode="multiple"
                    value={value}
                    placeholder={language.getText('selectPlease')}
                    style={{ width: 400 }}
                    onChange={this.handleChange}
                >
                    {!!options &&
                        options.map((o: any) => (
                            <Select.Option key={o.id.toString()} value={o.id.toString()}>
                                {o.name}
                            </Select.Option>
                        ))}
                </Select>
                {extra && extra.tip ? (
                    <div>
                        <span style={{ color: '#ccc', fontSize: '14px' }}>{extra.tip}</span>
                        <Popover
                            placement="bottom"
                            content={
                                <img
                                    alt={language.getText('components.PageManage.preview')}
                                    style={extra.style ? extra.style : style}
                                    src={extra.img}
                                ></img>
                            }
                            trigger={extra.triggerType}
                        >
                            <Button type="link">{extra.btnText}</Button>
                        </Popover>
                    </div>
                ) : null}
            </div>
        );
    }
}
