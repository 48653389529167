import React, { Component } from 'react';
import { Checkbox } from 'antd';
import { services } from '@comall-backend-builder/core';
import { ChannelType } from './';

const { Group } = Checkbox;

interface State {
    options: {
        label: string;
        value: ChannelType;
    }[];
}

export class ArrayChannelCheckbox extends Component<any, State> {
    constructor(props: any) {
        super(props);
        const channelOptions = [
            {
                id: ChannelType.Wechat,
                name: services.language.getText('wxorapp'),
            },
            {
                id: ChannelType.Alipay,
                name: services.language.getText('zfbxcx'),
            },
        ];
        this.state = {
            options: channelOptions.map((i) => ({
                label: i.name,
                value: i.id,
            })),
        };
    }

    componentDidMount(): void {
        const { isAdd, onChange } = this.props;
        const { options } = this.state;
        if (isAdd) {
            onChange(options.map((i) => i.value));
        }
    }

    render() {
        const { value, disabled, onChange } = this.props;
        const { options } = this.state;
        return (
            <div>
                <Group options={options} value={value} disabled={disabled} onChange={onChange} />
            </div>
        );
    }
}
