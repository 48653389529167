import React, { useCallback, useEffect, useState } from 'react';
import { Button, Icon, message, Modal, Spin } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { RcFile } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import classNames from 'classnames';
import { get, isFunction, isObject, isString, omit } from 'lodash';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { ModalProps } from 'antd/lib/modal';
import { errorHandle, interpolate } from '@comall-backend-builder/core/lib/services';
import { ForwardDataCenterModal } from '../../services';

export type PropsWithReturnValues<P = {}> = {
    [K in keyof P]: P[K] | ((config: BackendBuilderConfig<P>) => P[K]);
};

export interface BackendBuilderConfig<P = {}> {
    row: any;
    entity: Entity;
    entities?: Entity[];
    propsBeforeHandler?(
        config: BackendBuilderConfig<P>,
        props: PropsWithReturnValues<P>
    ): PropsWithReturnValues<P>;
    props: PropsWithReturnValues<P>;
    propsAfterHandler?(config: BackendBuilderConfig<P>, props: P): P;
}

/**
 * 将配置中的动态 props 转换为组件可用 props
 *
 * @param config 配置
 * @param propKeys  需要转换属性值对应的字段名列表（属性值原本就支持函数的无需转换）
 *
 * ```ts
 * const backendBuilderConfig = {
 *    entity: {
 *       value: 'Value',
 *    },
 *    props: {
 *        prop1: 'prop1Value',
 *        prop2: (config) => 'prop2' + config.entity.value,
 *        onEvent1: (v1, v2, config, props) => {
 *            console.log(config.entity.value) // undefined
 *            console.log(props.prop1) // undefined
 *            console.log(props.prop2) // undefined
 *        },
 *    }
 * }
 *
 * const props = backendBuilderConfigTransformToProps(backendBuilderConfig, ['prop1', 'prop2', 'onEvent1'])
 *
 * props ===  {
 *     prop1: 'prop1Value',
 *     prop2: 'prop2Value',
 *     onEvent1: (v1, v2, config, props) => {
 *         console.log(config.entity.value) // Value
 *         console.log(props.prop1) // prop1Value
 *         console.log(props.prop2) // prop2Value
 *     },
 * }
 * ```
 */
export function backendBuilderConfigTransformToProps<P = {}>(
    config: BackendBuilderConfig<P>,
    propKeys: (keyof P)[]
): P {
    let propsWithReturnValues = config.props;
    if (isFunction(config.propsBeforeHandler)) {
        propsWithReturnValues = config.propsBeforeHandler(config, propsWithReturnValues);
    }

    function transformValue(propsWithReturnValues: PropsWithReturnValues<P>, key: keyof P) {
        if (propsWithReturnValues.hasOwnProperty(key)) {
            const propOrReturnValueOrEvent = propsWithReturnValues[key];
            let value;
            if (isFunction(propOrReturnValueOrEvent)) {
                if (/^on[A-Z]/.test(key as string)) {
                    value = (...args: unknown[]) => {
                        propOrReturnValueOrEvent(...args.concat(config, props));
                    };
                } else {
                    value = propOrReturnValueOrEvent(config);
                }
            } else {
                value = propOrReturnValueOrEvent;
            }
            return {
                ...propsWithReturnValues,
                [key]: value,
            };
        }
        return propsWithReturnValues;
    }

    let props = propKeys.reduce(transformValue, propsWithReturnValues) as P;

    if (isFunction(config.propsAfterHandler)) {
        props = config.propsAfterHandler(config, props);
    }

    return props;
}

export interface ImportTableControlHandle extends Omit<ButtonProps, 'onClick'> {
    /**
     * 根路径
     */
    apiRoot: string;
    /**
     * 地址
     */
    apiPath: string;
    /**
     * 导入方法
     */
    method?: 'put' | 'post';
    /**
     * 额外参数
     */
    params?: Record<string, any>;
    /**
     * 按钮文字
     */
    text: string;
    /**
     * 请求参数处理程序
     */
    paramsHandler?(
        importTableConfig: BackendBuilderConfig<ImportTableProps>,
        fileInfo: ImportTableFileInfo
    ): Record<string, any>;
}

export interface ImportTableProps {
    /**
     * 按钮样式
     */
    triggerStyle?: ButtonProps['style'];
    /**
     * 按钮类名
     */
    triggerClassName?: ButtonProps['className'];
    /**
     * 按钮类型
     */
    triggerType?: ButtonProps['type'];
    /**
     * 按钮图标
     */
    triggerIcon?: ButtonProps['icon'];
    /**
     * 按钮文字
     */
    triggerText?: ButtonProps['children'];
    /**
     * 禁用按钮
     */
    triggerDisabled?: ButtonProps['disabled'];
    /**
     * 完整 ButtonProps 参数，外层的几个 trigger 参数不够用时可以使用此配置
     */
    triggerProps?: Omit<ButtonProps, 'onClick'>;
    /**
     * 弹窗标题
     */
    controlTitle?: string;
    /**
     * 关闭文字
     */
    closeTxt?: string;
    /**
     * 表格上传根路径
     */
    controlUploadApiRoot?: string;
    /**
     * 表格上传地址
     */
    controlUploadApiPath?: string;
    /**
     * 支持的文件后缀
     */
    controlUploadAccept?: string;
    /**
     * 导入规则
     */
    controlUploadRules?: (string | { text: string; color: 'red' | '' })[];
    /**
     * 示例图片
     */
    controlDisplayExample?: string;
    /**
     * 示例模板
     */
    controlDownloadTemplate?:
        | string
        | {
              apiRoot: string;
              apiPath: string;
          };
    specialTips?: Array<string>;
    /**
     * 导入方法组
     */
    controlHandles?: ImportTableControlHandle[];
    /**
     * 完整的 ModalProps 参数，可控制 Modal 的展示效果
     */
    controlProps?: Omit<ModalProps, 'visible' | 'footer' | 'onCancel'>;
    /**
     * 自定义回调弹窗
     */
    customCallback?: () => void;
}

interface ImportTableFileInfo {
    /**
     * 文件 id
     */
    id: string;
    /**
     * 文件路径
     */
    path: string;
    /**
     * 原始文件列表
     */
    fileList: [RcFile];
}

function useVisible(dafalueState = false) {
    const [state, change] = useState(dafalueState);

    const toggle = useCallback(
        () =>
            change((s) => {
                return !s;
            }),
        []
    );
    const show = useCallback(() => change(true), []);
    const hide = useCallback(() => change(false), []);

    return {
        state,
        toggle,
        show,
        hide,
    };
}

export const ImportTable: React.FC<BackendBuilderConfig<ImportTableProps>> = (config) => {
    const props = backendBuilderConfigTransformToProps(config, [
        'triggerStyle',
        'triggerClassName',
        'triggerType',
        'triggerIcon',
        'triggerText',
        'triggerDisabled',
        'triggerProps',
        'controlTitle',
        'controlUploadApiRoot',
        'controlUploadApiPath',
        'controlUploadAccept',
        'controlUploadRules',
        'controlDisplayExample',
        'controlDownloadTemplate',
        'specialTips',
        'controlHandles',
        'controlProps',
    ]);

    const {
        controlUploadApiRoot = `${ENV.AUTH_API_ROOT}/FILE`,
        controlUploadApiPath = '/admin/files/locations/40/upload',
        controlUploadAccept = '.xlsx,.xls,.csv',
        controlUploadRules = [
            services.language.getText('merchantBigCodeIntro1'),
            {
                text: services.language.getText('merchantBigCodeNote'),
                color: 'red',
            },
            services.language.getText('merchantBigCodeIntro2'),
            services.language.getText('merchantBigCodeIntro3'),
        ],
        controlHandles = [],
        triggerProps = {},
        controlProps = {},
    } = props;

    const controlVisible = useVisible();
    const uploadLoading = useVisible();
    const importLoading = useVisible();
    const [fileInfo, setFileInfo] = useState<ImportTableFileInfo>();

    function hidePop() {
        if (props.closeTxt) {
            message.success(props.closeTxt + services.language.getText('success'));
        }
        controlVisible.hide();
    }
    useEffect(() => {
        if (!controlVisible.state) {
            uploadLoading.hide();
            importLoading.hide();
            setFileInfo(undefined);
        }
    }, [controlVisible.state, importLoading, uploadLoading]);

    function handleBeforeUpload(file: RcFile): boolean {
        if (!uploadLoading.state) {
            uploadLoading.show();
            const uploadParams = {
                files: file,
            };
            const uploadConfig = {
                apiRoot: controlUploadApiRoot,
                apiPath: controlUploadApiPath,
                fileName: 'files',
                progressCallBack: () => {},
            };
            services.api
                .upload(uploadParams, uploadConfig)
                .then((response) => {
                    setFileInfo({
                        id: response.id,
                        path: response.path,
                        fileList: [file],
                    });
                })
                .catch((err) => {
                    message.error(
                        err.response?.body?.err_msg || services.language.getText('uoloadFail')
                    );
                })
                .finally(() => {
                    uploadLoading.hide();
                });
        }
        return false;
    }

    async function handleImportRequest(controlHandle: ImportTableControlHandle): Promise<void> {
        const { customCallback } = props;
        if (importLoading.state) return;
        if (!fileInfo) {
            message.error(services.language.getText('pleaseUploadFile'));
            return;
        }

        try {
            importLoading.show();
            const { method: importMethod = 'put' } = controlHandle;
            let importParams = controlHandle.paramsHandler
                ? controlHandle.paramsHandler(config, fileInfo)
                : {
                      id: get(config, 'row.id', get(config, 'params.id')),
                      fileId: fileInfo.id,
                      fileUrl: fileInfo.path,
                  };
            importParams = Object.assign(
                {},
                config.entity.params,
                controlHandle.params,
                importParams
            );
            const importConfig = {
                apiRoot: controlHandle.apiRoot,
                apiPath: interpolate(controlHandle.apiPath, config),
            };
            await services.api[importMethod](importParams, importConfig);
            controlVisible.hide();
            if (customCallback && isFunction(customCallback)) {
                customCallback();
            } else {
                //弹层提示
                ForwardDataCenterModal();
            }
        } catch (e) {
            let errString = e.response.body.message;
            let errMessage = errString.split(':');
            errMessage.length > 1 ? message.error(errMessage[1]) : message.error(errMessage[0]);
        } finally {
            importLoading.hide();
        }
    }

    const controlUploadEle = (
        <Dragger
            accept={controlUploadAccept}
            fileList={fileInfo?.fileList}
            beforeUpload={handleBeforeUpload}
            onRemove={() => setFileInfo(undefined)}
        >
            <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">{services.language.getText('selectFile')}</p>
            <p className="ant-upload-hint">
                {services.language.getText('supportExtension')}
                {controlUploadAccept}
            </p>
        </Dragger>
    );

    const controlDownloadTemplateEle =
        props.controlDownloadTemplate &&
        (isObject(props.controlDownloadTemplate) ? (
            <Button
                type="link"
                onClick={() => {
                    services.api
                        .download({}, props.controlDownloadTemplate as any)
                        .catch(errorHandle);
                }}
            >
                {services.language.getText('djxzpldrmb')}
            </Button>
        ) : (
            <Button type="link" download href={props.controlDownloadTemplate}>
                {services.language.getText('djxzpldrmb')}
            </Button>
        ));

    const controlTipsEle = (
        <div className="giving-coupon-schedules-tips" style={{ paddingTop: '10px' }}>
            {controlDownloadTemplateEle}
            <p className="giving-coupon-schedules-tips-text">
                {services.language.getText('importRules')}
            </p>
            {controlUploadRules.map((uploadRule) => {
                if (isString(uploadRule)) {
                    return (
                        <p className="giving-coupon-schedules-tips-text" key={uploadRule}>
                            {uploadRule}
                        </p>
                    );
                }
                return (
                    <p
                        className={classNames('giving-coupon-schedules-tips-text', {
                            [`giving-coupon-schedules-tips-text-${uploadRule.color}`]: uploadRule.color,
                        })}
                        key={uploadRule.text}
                    >
                        {uploadRule.text}
                    </p>
                );
            })}
            {props.specialTips &&
                props.specialTips.map((tip: string) => {
                    return (
                        <p className="giving-coupon-schedules-tips-text" key={tip}>
                            {tip}
                        </p>
                    );
                })}
            {props.controlDisplayExample && (
                <>
                    <p className="giving-coupon-schedules-tips-text-controlDownloadTemplate">
                        {services.language.getText('wjysmb')}：
                    </p>
                    <img
                        className="giving-coupon-schedules-img"
                        src={props.controlDisplayExample}
                        alt={services.language.getText('sltp')}
                    />
                </>
            )}
        </div>
    );

    const controlFooterEle = (
        <>
            {controlHandles.map((controlHandle) => (
                <Button
                    {...omit(controlHandle, 'apiRoot', 'apiPath', 'params')}
                    key={controlHandle.text}
                    onClick={() => handleImportRequest(controlHandle)}
                >
                    {controlHandle.text}
                </Button>
            ))}
            <Button onClick={hidePop}>{props.closeTxt || services.language.getText('back')}</Button>
        </>
    );

    const triggerEle = (
        <Button
            {...triggerProps}
            style={props.triggerStyle || triggerProps.style}
            className={props.triggerClassName || triggerProps.className}
            type={props.triggerType || triggerProps.type}
            icon={props.triggerIcon || triggerProps.icon}
            disabled={props.triggerDisabled || triggerProps.disabled}
            onClick={controlVisible.show}
        >
            {props.triggerText}
        </Button>
    );

    const controlTitle =
        props.controlTitle || controlProps.title || services.language.getText('batchImport');

    const controlEle = (
        <Modal
            {...controlProps}
            title={controlTitle}
            footer={controlFooterEle}
            visible={controlVisible.state}
            onCancel={controlVisible.hide}
        >
            <Spin
                spinning={uploadLoading.state}
                tip={services.language.getText('batchIscwwczqmport')}
            >
                <Spin
                    spinning={importLoading.state}
                    tip={services.language.getText('importBeforeNotPage')}
                >
                    {controlUploadEle}
                    {controlTipsEle}
                </Spin>
            </Spin>
        </Modal>
    );

    return (
        <>
            {triggerEle}
            {controlEle}
        </>
    );
};
