import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import find from 'lodash/find';
import get from 'lodash/get';
import { SecurityCode } from '../../src/config/settings/setting/security-setting';

const api = services.api;
export const SecurityConfigLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}`;
        config.apiPath = '/CAE-OAUTHSVR/security/configs';

        return await api.get(data, config).then((res: any) => {
            res.limitRule = {
                id: undefined,
            };
            const type = find(res, { code: 'LOGIN_MODE' });
            const allowedLogin = find(res, { code: 'ALLOWED_LOGIN_FAILURES_MAXIMUM_NUMBER' });
            const allowedLoginFaluresNumber = allowedLogin ? Number(allowedLogin.value) : 0;
            if (type) {
                if (type.value === SecurityCode.USERNAME_PASSWORD) {
                    return {
                        loginMode: [SecurityCode.USERNAME_PASSWORD],
                        doubleFactor: [],
                        allowedLoginFaluresNumber,
                    };
                } else if (type.value === SecurityCode.PHONE_VERIFICATION_CODE) {
                    return {
                        loginMode: [SecurityCode.PHONE_VERIFICATION_CODE],
                        doubleFactor: [],
                        allowedLoginFaluresNumber,
                    };
                } else if (type.value === SecurityCode.PHONE_OR_PASSWORD) {
                    return {
                        loginMode: [
                            SecurityCode.USERNAME_PASSWORD,
                            SecurityCode.PHONE_VERIFICATION_CODE,
                        ],
                        doubleFactor: [],
                        allowedLoginFaluresNumber,
                    };
                } else if (type.value === SecurityCode.PHONE_AND_PASSWORD) {
                    return {
                        loginMode: [
                            SecurityCode.USERNAME_PASSWORD,
                            SecurityCode.PHONE_VERIFICATION_CODE,
                        ],
                        doubleFactor: [SecurityCode.PHONE_AND_PASSWORD],
                        allowedLoginFaluresNumber,
                    };
                }
            }
        });
    },

    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}`;
        config.apiPath = '/CAE-OAUTHSVR/security/configs';
        const allowedLoginFaluresNumber = get(params, 'allowedLoginFaluresNumber');
        const loginMode = get(params, 'loginMode');
        const doubleFactor = get(params, 'doubleFactor');
        let loginModeValue = '';
        if (loginMode.length === 1) {
            loginModeValue = loginMode[0];
        } else {
            if (doubleFactor.includes(SecurityCode.PHONE_AND_PASSWORD)) {
                loginModeValue = SecurityCode.PHONE_AND_PASSWORD;
            } else {
                loginModeValue = SecurityCode.PHONE_OR_PASSWORD;
            }
        }
        const commands = [
            {
                code: 'ALLOWED_LOGIN_FAILURES_MAXIMUM_NUMBER',
                value: allowedLoginFaluresNumber,
            },
            {
                code: 'LOGIN_MODE',
                value: loginModeValue,
            },
        ];

        return await api.post(
            {
                commands,
            },
            config
        );
    },
};
