import { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { Modal as AntModal, message as AntMessage } from 'antd';
import { mapValues, get } from 'lodash';

/**
 * 社区团购-规则配置表单
 */
class presaleConfigFormView extends PureComponent<any, any> {
    render() {
        const { entity } = this.props;
        const prams = {
            id: get(entity, 'fields.id', null),
        };

        return createElement(ComponentsManager.get('CGBConfigForm'), {
            ...this.props,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'));
                entity.get();
            },
            onSubmit: (event: any, fields: any) => {
                function onOk() {
                    const entityFields = mapValues(fields, (field, name) => {
                        return field.value;
                    });
                    entity.modify(entityFields, prams);
                }
                AntModal.confirm({
                    title: '提示',
                    content: '确定保存修改？保存后将立即生效',
                    onOk: onOk,
                    okText: services.language.getText('common.confirm'),
                    cancelText: services.language.getText('common.cancel'),
                });
            },
        });
    }
}

const mapStateToProps = (_state: any, props: any) => ({
    fields: props.entity.fields,
});

export const PresaleConfigFormView = connect(mapStateToProps)(presaleConfigFormView);
