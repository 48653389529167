import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
import moment from 'moment';

export const config: Config = {
    entities: {
        PosReceiptEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-POS-RECEIPT`,
            apiPath: '/loader/admin/pos_receipts',
            filters: {
                subsiteIds: {
                    type: 'string.options.select',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                posReceiptNo: {
                    type: 'string',
                    displayName: '<<xpbh>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<xphysjh>>',
                },
                invoiceMobile: {
                    type: 'string',
                    displayName: '<<kprsjh>>',
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<xpsj>>',
                    defaultValue: {
                        start: moment().format('YYYY-MM-DD'),
                        end: moment().format('YYYY-MM-DD'),
                    },
                },
                invoiceStatus: {
                    type: 'string.options.select',
                    displayName: '<<fpzt>>',
                    options: [
                        { id: 'NOT_INVOICED', name: '<<noApply>>' },
                        { id: 'INVOICING', name: '<<kpz>>' },
                        { id: 'INVOICED', name: '<<ykp>>' },
                        { id: 'INVOICING_FAILED', name: '<<kpsb>>' },
                    ],
                },
                invoiceDateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<fpsqsj>>',
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                posReceiptNo: {
                    type: 'string',
                    displayName: '<<xpbh>>',
                },
                originPosReceiptNo: {
                    type: 'string',
                    displayName: '<<yxpbh>>',
                },
                subsite: {
                    type: 'object',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<subsiteName>>',
                        },
                    },
                },
                mobile: {
                    type: 'string',
                    displayName: '<<xphysjh>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<xpsj>>',
                },
                invoiceStatus: {
                    type: 'string.options.select',
                    displayName: '<<fpzt>>',
                    options: [
                        { id: 'NOT_INVOICED', name: '<<noApply>>' },
                        { id: 'INVOICING', name: '<<kpz>>' },
                        { id: 'INVOICED', name: '<<ykp>>' },
                        { id: 'INVOICING_FAILED', name: '<<kpsb>>' },
                    ],
                },
                invoiceMobile: {
                    type: 'string',
                    displayName: '<<kprsjh>>',
                },
                orderAmount: {
                    type: 'string',
                    displayName: '<<xfjey>>',
                },
                items: {
                    type: 'array.goods.popover',
                    displayName: '<<components.Products.name>>',
                },
            },
        },
    },
    components: {
        PosReceiptView: {
            component: 'Viewport',
            entity: 'PosReceiptEntity',
        },
        PosReceiptPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'PosReceiptFilter',
                },
                { component: 'PosReceiptTable' },
            ],
        },
        PosReceiptFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteIds',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'posReceiptNo',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'mobile',
                    controlConfig: {
                        placeholder: '<<qsrsjh>>',
                    },
                },
                {
                    property: 'invoiceMobile',
                    controlConfig: {
                        placeholder: '<<qsrsjh>>',
                    },
                },
                {
                    property: 'dateRange',
                },
                {
                    property: 'invoiceStatus',
                    controlConfig: {
                        style: { width: 150 },
                        allowClear: true,
                    },
                },
                {
                    property: 'invoiceDateRange',
                },
            ],
        },
        PosReceiptTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'mt24',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: { x: true },
                    columns: [
                        {
                            property: 'posReceiptNo',
                        },
                        {
                            property: 'originPosReceiptNo',
                        },
                        {
                            property: 'subsite.name',
                        },
                        {
                            property: 'mobile',
                        },
                        {
                            property: 'createTime',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '250px',
                                },
                            },
                        },
                        {
                            property: 'orderAmount',
                        },
                        {
                            property: 'items',
                        },
                        {
                            property: 'invoiceStatus',
                        },
                        {
                            property: 'invoiceMobile',
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        fixed: 'right',
                        width: 100,
                        items: [
                            {
                                type: 'link',
                                text: '<<common.view>>',
                                path: '/pos-eceipt/info/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        PosReceiptDetailPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            // entity: 'OfflineOrderCommentsEntity',
            // loaderType: 'get',
            items: [
                {
                    component: 'PosReceiptDetail',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/pos-eceipt',
                    component: 'PosReceiptView',
                    breadcrumbName: '<<posReceipt>>',
                    privilege: {
                        path: 'posReceipt',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/info/:id',
                            component: 'PosReceiptDetailPage',
                            breadcrumbName: '<<details>>',
                        },
                        { path: '/', component: 'PosReceiptPage' },
                    ],
                },
            ],
        },
    ],
};
