import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { Divider } from 'antd';
import MemberInfoPane, { MemberInfo, MemberInfoError } from '../member-info-pane/MemberInfoPane';
import MemberInfoCard from '../member-info-card/MemberInfoCard';
import { TimeHorizon } from '../member-info-charts/MemberInfoCharts';
import MemberInfoChartsCarousel from '../member-info-charts-carousel/MemberInfoChartsCarousel';
import MemberInfoTimeLine from '../member-info-time-line/MemberInfoTimeLine';
import { TabKey } from '../index';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;

interface Props {
    memberId: string;
    onChangeTab: (key: TabKey, pageParams?: any) => void;
}
interface State {
    memberInfo: MemberInfo | null;
    memberCardInfo: any;
    /**
     * 消费金额图表时间维度
     */
    expenseRecordTimeHorizon: TimeHorizon;
    /**
     * 到店记录图表时间维度
     */
    reachStoreTimeHorizon: TimeHorizon;
    dealRecordsChartData: any[];
    dealRecordsChartTitle: string;
    mallVisitRecordsChartData: any[];
    mallVisitRecordsChartTitle: string;
    appVisitRecordsChartData: any[];
    appVisitRecordsChartTitle: string;
    memberInfoError?: MemberInfoError;
    memberInfoLoading: boolean;
}

export default class OverViewPage extends PureComponent<Props, State> {
    state: State = {
        memberInfo: null,
        memberCardInfo: null,
        expenseRecordTimeHorizon: TimeHorizon.RecentOneYears,
        reachStoreTimeHorizon: TimeHorizon.RecentOneYears,
        dealRecordsChartData: [],
        dealRecordsChartTitle: '',
        mallVisitRecordsChartData: [],
        mallVisitRecordsChartTitle: '',
        appVisitRecordsChartData: [],
        appVisitRecordsChartTitle: '',
        memberInfoError: undefined,
        memberInfoLoading: true,
    };
    componentDidMount() {
        this.loadMemberInfo();
        this.loadMemberChartData();
    }

    private loadMemberInfo = async () => {
        try {
            const { memberId } = this.props;
            let res: any = await api.get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                    apiPath: `/admin/members/${memberId}`,
                }
            );
            this.setState({
                memberInfo: res,
                memberInfoLoading: false,
            });
        } catch (error) {
            const code = (error as any)?.response?.body?.code;
            this.setState({
                memberInfoError: code,
                memberInfoLoading: false,
            });
            services.errorHandle(error as any);
        }
    };
    private loadMemberCard = async (memberParams: any) => {
        try {
            const { memberId } = this.props;
            const cardInfo: any = await api.get(memberParams, {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                apiPath: `/admin/members/${memberId}/cards`,
            });
            const accountInfo: any = await api.get(memberParams, {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                apiPath: `/admin/members/${memberId}/accounts`,
            });
            this.setState({
                memberCardInfo: {
                    ...cardInfo,
                    ...accountInfo,
                },
            });
        } catch (error) {
            services.errorHandle(error as any);
        }
    };
    private loadMemberChartData = () => {
        this.loadDealRecords();
        this.loadMallVisitRecords();
        this.loadAppVisitRecords();
    };

    /**
     * 获取消费记录视图数据
     */
    private loadDealRecords = async () => {
        try {
            const { memberId } = this.props;
            const { expenseRecordTimeHorizon } = this.state;
            const sumInterval =
                expenseRecordTimeHorizon === TimeHorizon.RecentOneYears
                    ? 'CALENDER_MONTH'
                    : 'CALENDER_QUARTER';
            const timeRangeTypeEnum =
                expenseRecordTimeHorizon === TimeHorizon.RecentOneYears
                    ? 'RELATIVE_CALENDER_MONTH'
                    : 'RELATIVE_CALENDER_QUARTER';
            const res: any[] = await api.get(
                {
                    sumInterval,
                    timeRangeTypeEnum,
                    timeRangeFrom: -12,
                    timeRangeTo: 0,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                    apiPath: `/admin/members/${memberId}/deal_records/summary_view`,
                }
            );
            const dealRecordsChartData: any[] = [];
            let dealRecordsChartTitle = '';
            if (res && res.length > 0) {
                dealRecordsChartTitle = `${res[0].dealInterval}~` + language.getText('zj_1');
            }
            res.forEach((item: any) => {
                if (item.dealSubsites && item.dealSubsites.length > 0) {
                    item.dealSubsites.forEach((subsite: any) => {
                        dealRecordsChartData.push({
                            key: item.dealInterval,
                            value: subsite.dealAmount,
                            type: subsite.subsiteName,
                        });
                    });
                } else {
                    dealRecordsChartData.push({
                        key: item.dealInterval,
                        value: 0,
                    });
                }
            });
            this.setState({
                dealRecordsChartData,
                dealRecordsChartTitle,
            });
        } catch (error) {
            services.errorHandle(error as any);
        }
    };
    /**
     * 获取到店记录视图数据
     */
    private loadMallVisitRecords = async () => {
        try {
            const { memberId } = this.props;
            const { reachStoreTimeHorizon } = this.state;
            const sumInterval =
                reachStoreTimeHorizon === TimeHorizon.RecentOneYears
                    ? 'CALENDER_MONTH'
                    : 'CALENDER_QUARTER';
            const timeRangeTypeEnum =
                reachStoreTimeHorizon === TimeHorizon.RecentOneYears
                    ? 'RELATIVE_CALENDER_MONTH'
                    : 'RELATIVE_CALENDER_QUARTER';
            const res: any[] = await api.get(
                {
                    sumInterval,
                    timeRangeTypeEnum,
                    timeRangeFrom: -12,
                    timeRangeTo: 0,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                    apiPath: `/admin/members/${memberId}/mall_visit_records/summary_view`,
                }
            );
            const mallVisitRecordsChartData: any[] = [];
            let mallVisitRecordsChartTitle = '';
            if (res && res.length > 0) {
                mallVisitRecordsChartTitle = `${res[0].visitInterval}~` + language.getText('zj_1');
            }
            res.forEach((item: any) => {
                if (item.visitSubsites && item.visitSubsites.length > 0) {
                    item.visitSubsites.forEach((subsite: any) => {
                        mallVisitRecordsChartData.push({
                            key: item.visitInterval,
                            value: subsite.visitNumber,
                            type: subsite.subsiteName,
                        });
                    });
                } else {
                    mallVisitRecordsChartData.push({
                        key: item.visitInterval,
                        value: 0,
                    });
                }
            });
            this.setState({
                mallVisitRecordsChartData,
                mallVisitRecordsChartTitle,
            });
        } catch (error) {
            services.errorHandle(error as any);
        }
    };
    private loadAppVisitRecords = async () => {
        try {
            const { memberId } = this.props;
            const res: any[] = await api.get(
                {
                    sumNumber: 7,
                    timeRangeFrom: -12,
                    timeRangeTo: 0,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                    apiPath: `/admin/members/${memberId}/app_visit_records/summary_view`,
                }
            );
            let appVisitRecordsChartTitle = '';
            if (res && res.length > 0) {
                const startDate = (res[0].visitInterval || '').split('-')[0];
                appVisitRecordsChartTitle = `${startDate}~` + language.getText('zj_1');
            }

            this.setState({
                appVisitRecordsChartData: res,
                appVisitRecordsChartTitle,
            });
        } catch (error) {
            services.errorHandle(error as any);
        }
    };
    private onChangeTimeHorizon = (
        type: 'expenseRecordTimeHorizon' | 'reachStoreTimeHorizon',
        timeHorizon: TimeHorizon
    ) => {
        if (type === 'expenseRecordTimeHorizon') {
            this.setState(
                {
                    expenseRecordTimeHorizon: timeHorizon,
                },
                () => {
                    this.loadDealRecords();
                }
            );
        } else {
            this.setState(
                {
                    reachStoreTimeHorizon: timeHorizon,
                },
                () => {
                    this.loadMallVisitRecords();
                }
            );
        }
    };
    render() {
        const { onChangeTab, memberId } = this.props;
        const {
            memberInfo,
            memberCardInfo,
            expenseRecordTimeHorizon,
            reachStoreTimeHorizon,
            dealRecordsChartData,
            dealRecordsChartTitle,
            mallVisitRecordsChartData,
            mallVisitRecordsChartTitle,
            appVisitRecordsChartData,
            appVisitRecordsChartTitle,
            memberInfoError,
            memberInfoLoading,
        } = this.state;
        const registerSubsiteCode =
            memberInfo && memberInfo.registerSubsiteCode
                ? memberInfo.registerSubsiteCode
                : undefined;
        const classes = {
            main: `${prefix}`,
            left: `${prefix}__left`,
            right: `${prefix}__right`,
        };
        return (
            <div className={classes.main}>
                <div className={classes.left}>
                    <MemberInfoPane
                        memberInfo={memberInfo}
                        memberId={memberId}
                        memberInfoError={memberInfoError}
                    />
                    {memberInfoLoading ? null : (
                        <MemberInfoCard
                            memberCardInfo={memberCardInfo}
                            onChangeMemberCard={this.loadMemberCard}
                            onChangeTab={onChangeTab}
                            registerSubsiteCode={registerSubsiteCode}
                        />
                    )}
                </div>
                <div className={classes.right}>
                    <MemberInfoChartsCarousel
                        expenseRecordTimeHorizon={expenseRecordTimeHorizon}
                        reachStoreTimeHorizon={reachStoreTimeHorizon}
                        dealRecordsChartData={dealRecordsChartData}
                        dealRecordsChartTitle={dealRecordsChartTitle}
                        mallVisitRecordsChartData={mallVisitRecordsChartData}
                        mallVisitRecordsChartTitle={mallVisitRecordsChartTitle}
                        onChangeTimeHorizon={this.onChangeTimeHorizon}
                        onChangeTab={onChangeTab}
                        appVisitRecordsChartData={appVisitRecordsChartData}
                        appVisitRecordsChartTitle={appVisitRecordsChartTitle}
                    />
                    <Divider dashed style={{ margin: '10px 0' }} />
                    {memberInfoLoading ? null : (
                        <MemberInfoTimeLine
                            memberId={memberId}
                            registerSubsiteCode={registerSubsiteCode}
                        />
                    )}
                </div>
            </div>
        );
    }
}

const prefix = 'over-view-page';
