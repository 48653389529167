import React, { Component } from 'react';
import { Radio, Form, Tooltip, Icon, Input } from 'antd';
import { ShowActivitys } from './show-activitys';
import { ShowContent } from './show-content';
import { tools } from '@comall-backend-builder/design-components-basis';

import './index.less';
const ColorPicker = tools.ColorPicker;

const FormItemLayout = tools.FormItemLayout;
const ConfigFormDecorator = tools.ConfigFormDecorator;
const FormItem = Form.Item;
const RadioGroup = Radio.Group;

export interface IProductMore {
    pic: string;
    title: string;
    text: string;
    border: boolean;
    linkParams: any;
    linkType: string;
}
/**
 * 商品列表组件-配置信息
 *  */
class actitivtyRecommend extends Component<any> {
    render() {
        const { getFieldDecorator } = this.props.form;
        const { selector } = this.props;
        return (
            <Form className="editor-products">
                <FormItem {...FormItemLayout} label="标题">
                    {getFieldDecorator(
                        'title',
                        {}
                    )(<Input maxLength={20} className="show-style"></Input>)}
                </FormItem>
                <FormItem {...FormItemLayout} label="标题颜色">
                    {getFieldDecorator('titleColor', {})(<ColorPicker></ColorPicker>)}
                </FormItem>
                <FormItem {...FormItemLayout} label="展示样式">
                    {getFieldDecorator(
                        'showStyle',
                        {}
                    )(
                        <RadioGroup className="show-style">
                            {ENV.TENANT !== 'hxgc' ? <Radio value="1">横向滚动</Radio> : null}
                            {ENV.TENANT === 'hxgc' ? <Radio value="2">轮播展示</Radio> : null}
                            {ENV.TENANT === 'hxgc' ? <Radio value="3">一行两个</Radio> : null}
                        </RadioGroup>
                    )}
                </FormItem>
                <FormItem
                    {...FormItemLayout}
                    label={
                        <span>
                            商场活动&nbsp;
                            <Tooltip title="可拖动调整排列顺序">
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('entry', {
                        initialValue: [],
                    })(<ShowActivitys selector={selector} />)}
                </FormItem>
                <FormItem {...FormItemLayout} label="显示内容">
                    {getFieldDecorator('showContent', {
                        initialValue: [],
                    })(<ShowContent getFieldDecorator={getFieldDecorator} />)}
                </FormItem>
            </Form>
        );
    }
}

export const ActitivtyRecommend = ConfigFormDecorator(actitivtyRecommend);
