import { services } from '@comall-backend-builder/core';

export enum DepostOrderStatus {
    PAYMENT = 'PAYMENT',
    USED = 'USED',
    REFUNDING = 'REFUNDING',
    CANCELLED = 'CANCELLED',
}
export function getDepostOrderStatusName(status: DepostOrderStatus) {
    const DepostOrderStatusName: any = {
        [DepostOrderStatus.PAYMENT]: 'dsy',
        [DepostOrderStatus.USED]: 'ywc',
        [DepostOrderStatus.REFUNDING]: 'tkz',
        [DepostOrderStatus.CANCELLED]: 'yjtk',
    };
    return services.language.getText(DepostOrderStatusName[status]);
}

export enum DepostOrderChannel {
    WECHAT_MINI_PROGRAM = 'WECHAT_MINI_PROGRAM',
    ALIPAY_MINI_PROGRAM = 'ALIPAY_MINI_PROGRAM',
    IOS_APP = 'IOS_APP',
    ANDROID_APP = 'ANDROID_APP',
    H5 = 'H5',
}

export function getDepostOrderChannelName(channel: DepostOrderChannel) {
    const channelName: any = {
        [DepostOrderChannel.WECHAT_MINI_PROGRAM]: 'wxMiniP',
        [DepostOrderChannel.ALIPAY_MINI_PROGRAM]: 'aliMiniP',
        [DepostOrderChannel.IOS_APP]: 'iosApp',
        [DepostOrderChannel.ANDROID_APP]: 'androidApp',
        [DepostOrderChannel.H5]: 'h5',
    };
    return services.language.getText(channelName[channel]);
}
