import React, { PureComponent } from 'react';
import { cloneDeep } from 'lodash';
import { ContentTopicSelector, ContentTopicSelectedDisplay } from '../../components';

export interface ContentTopicType {
    id: string;
    /**
     * 版块名称
     */
    name: number;
    /**
     * 版块
     */
    plates: string;
    /**
     * 启用状态
     */
    status: number;
    /**
     * 被选中的话题
     */
    select?: boolean;
}

export class SelectContentTopic extends PureComponent<
    any,
    {
        value: Array<ContentTopicType>;
    }
> {
    state = { value: new Array<ContentTopicType>() };

    /**
     * 选择话题
     * @param selectValues
     */
    onChange = (selectValues: ContentTopicType[]) => {
        const { onChange } = this.props;
        if (onChange) {
            if (selectValues && selectValues.length === 0) {
                onChange(undefined);
            } else {
                onChange(selectValues);
            }
        }
    };

    /**
     * 添加分站弹窗确认后回调
     * @param selectValues 选中分站
     */
    onSelect = (selectValues: ContentTopicType[]) => {
        const { value } = this.props;
        let newData: ContentTopicType[] = [];
        if (value) {
            newData = cloneDeep(value);
        }
        if (selectValues) {
            newData = selectValues;
        }
        this.onChange(newData);
    };

    render() {
        const { value, disabled } = this.props;
        const hasValue = value && value.length > 0;

        return (
            <div>
                {!disabled && (
                    <ContentTopicSelector
                        onChange={this.onSelect}
                        selectValues={value}
                        type="radio"
                    />
                )}
                {hasValue && (
                    <ContentTopicSelectedDisplay
                        data={value}
                        onRemove={this.onChange}
                        disabled={disabled}
                    ></ContentTopicSelectedDisplay>
                )}
            </div>
        );
    }
}
