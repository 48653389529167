import { ComponentsManager } from '@comall-backend-builder/core';
import React, { createElement, FC } from 'react';
import { connect } from 'react-redux';
import './index.less';

const prefix = 'wechat-official-account-config-preview';
interface Props {
    config: {
        title?: string;
        description?: string;
        qrCodeUrl: string;
        headTitle: string;
        backgroundPictureUrl: { id: string; path: string }[];
    };
}
const WechatOfficialAccountConfigSimulator: FC<Props> = (props) => {
    const { config } = props;
    const isDescriptionEmpty =
        !config.description?.includes('<img') && !config.description?.replace(/<(.*?)>/gu, '');
    const backgroundImageUrl =
        config && config.backgroundPictureUrl && config.backgroundPictureUrl.length > 0
            ? config.backgroundPictureUrl[0].path
            : 'https://b-puzhehei-cdn.co-mall.net/global/wechat-official-account/wechat-official-account-popup-bg.png';
    const popupStyle = {
        backgroundImage: `url(${backgroundImageUrl})`,
    };
    return (
        <div>
            <div className={classes.popup} style={popupStyle}>
                <div className={classes.popupHeader}>
                    <div className={classes.headerTitle}>{config.headTitle}</div>
                    <img
                        className={classes.headerClose}
                        src="https://b-puzhehei-cdn.co-mall.net/global/btn_close_popup.png"
                        alt=""
                    />
                </div>
                <div className={classes.popupContent}>
                    <div className={classes.contentStore}>XXXXX的公众号</div>
                    <div className={classes.contentRow}>
                        <img
                            className={classes.contentLogo}
                            src="./images/editor/default-official-account-logo.png"
                            alt=""
                        />
                        <div className={classes.contentName}>公众号名称</div>
                        <div className={classes.contentButton}>查看</div>
                    </div>
                </div>
            </div>
            <div className={classes.container}>
                <div className={classes.header}>
                    <img alt="" src="./images/avatar/theme-head.png" />
                    <div className={classes.headerTit}>{config.headTitle}</div>
                </div>
                <div className={classes.content}>
                    <div className={classes.title}>{config.title || '关注公众号了解更多优惠'}</div>
                    <div className={classes.qrcode}>
                        <img src={config.qrCodeUrl} alt="" />
                    </div>
                    <div className={classes.button}>
                        <img src="./images/editor/finger-print.png" alt="" />
                        <span>长按二维码识别关注</span>
                    </div>
                </div>
                {isDescriptionEmpty ? (
                    <img
                        className={classes.defaultDescription}
                        src="./images/editor/default-official-account-description.png"
                        alt=""
                    />
                ) : (
                    createElement(ComponentsManager.get('RichTextPlus'), {
                        value: config.description,
                        disabled: true,
                        placeholder: '',
                        style: {
                            width: 373,
                        },
                    })
                )}
            </div>
        </div>
    );
};

const classes = {
    container: prefix,
    header: `${prefix}__header`,
    headerTit: `${prefix}__header__title`,
    content: `${prefix}__content`,
    title: `${prefix}__title`,
    qrcode: `${prefix}__qrcode`,
    button: `${prefix}__button`,
    description: `${prefix}__description`,
    defaultDescription: `${prefix}__default-description`,
    popup: `${prefix}__popup`,
    popupHeader: `${prefix}__popup__header`,
    headerTitle: `${prefix}__popup__header__title`,
    headerClose: `${prefix}__popup__header__close`,
    popupContent: `${prefix}__popup__content`,
    contentStore: `${prefix}__popup__content__store`,
    contentRow: `${prefix}__popup__content__row`,
    contentLogo: `${prefix}__popup__content__logo`,
    contentName: `${prefix}__popup__content__name`,
    contentButton: `${prefix}__popup__content__button`,
};

function mapStateToProps(_state: any, props: any) {
    const entityId = props.entity.id;
    for (const componentId in _state.components) {
        const component = _state.components[componentId];
        if (
            (component.type === 'CreateForm' || component.type === 'EditForm') &&
            component.entityId === entityId
        ) {
            return {
                config: component.fields,
            };
        }
    }
    return { config: {} };
}

export const WechatOfficialAccountConfigPreview = connect(mapStateToProps)(
    WechatOfficialAccountConfigSimulator
);
