import * as React from 'react';
import moment from 'moment';
import { ObjectMode } from '@comall-backend-builder/types';
import { MonthDayRangePicker } from '../../../components';

/**
 * 月日范围
 *
 * 02-03 ~ 04-05
 *
 * @example
 *
 * {
 *     from: '02-03',
 *     to: '04-05',
 * }
 *
 */
export class ObjectMonthDayRangeMode extends ObjectMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        let { from, to } = value || {};
        if (from && to) {
            const fromDisplay = moment(from).format('MMMDo');
            const toDisplay = moment(to).format('MMMDo');
            return super.getAvailableDisplayComponent(`${fromDisplay} ~ ${toDisplay}`, displayInfo);
        } else {
            return super.getNotAvailableDisplayComponent(displayInfo);
        }
    }

    /**
     * 获取输入组件，日期的选择区间
     */
    public getControlComponent(controlInfo: any) {
        return <MonthDayRangePicker {...controlInfo} />;
    }
}
