import { Config } from '@comall-backend-builder/core/lib/parser';
import { trim } from 'lodash';

export enum MerchantSelfStatus {
    /**
     * 筹备
     */
    PREPARE = 0,
    /**
     * 开启
     */
    OPEN = 1,
    /**
     * 闭店
     */
    CLOSE = 2,
    /**
     * 暂停
     */
    PAUSE = 3,
}
export enum MerchantSelfType {
    /**
     * 自营
     */
    SELF = 'SELF',
    /**
     * 联营
     */
    JOINT = 'JOINT',
    /**
     * 租赁
     */
    LEASE = 'LEASE',
    /**
     * 加盟
     */
    LEAGUE = 'LEAGUE',
    /**
     * 专柜自营
     */
    MERCHANT_SELF = 'MERCHANT_SELF',
    /**
     * 其他
     */
    OTHER = 'OTHER',
}
export enum MerchantSelfStatusValue {
    PREPARE = 'PREPARE',
    OPEN = 'OPEN',
    CLOSE = 'CLOSE',
    PAUSE = 'PAUSE',
}
export enum AddressType {
    SUBSITE_ADDRESS = 'SUBSITE_ADDRESS',
    MERCHANT_ADDRESS = 'MERCHANT_ADDRESS',
}

export const config: Config = {
    entities: {
        MerchantSelfEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/merchant_self',
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        subsite: {
                            type: 'object.option.autoComplete',
                            displayName: '<<belongSubsite>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        name: {
                            displayName: '<<merchantName>>',
                            type: 'string.maxLength',
                            rules: [
                                {
                                    required: true,
                                    message: '<<merchantNameRequired>>',
                                    validator: (rule: any, value: any) => {
                                        if (!value) {
                                            return false;
                                        }
                                        if (value && !trim(value)) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                placeholder: '<<inputPlease>>',
                                maxLength: 60,
                            },
                        },
                        nameEn: {
                            displayName: '<<nameEn>>',
                            type: 'string.maxLength',
                            rules: [
                                {
                                    message: '<<noSupportChinese>>',
                                    validator: (rule: any, value: any) => {
                                        if (value && /[\u4e00-\u9fa5]+/g.test(value)) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                placeholder: '<<inputPlease>>',
                                maxLength: 60,
                            },
                        },
                        code: {
                            displayName: '<<merchantCode>>',
                            type: 'string.maxLength',
                            rules: [
                                {
                                    required: true,
                                    message: '<<merchantCodeRequired>>',
                                    validator: (rule: any, value: any) => {
                                        if (!value) {
                                            return false;
                                        }
                                        if (value && !trim(value)) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                placeholder: '<<merchantCodeRules>>',
                                maxLength: 20,
                            },
                        },
                        type: {
                            type: 'string.options.select',
                            displayName: '<<merchantType>>',
                            defaultValue: MerchantSelfType.JOINT,
                            options: [
                                { id: MerchantSelfType.JOINT, name: '<<joint>>' },
                                { id: MerchantSelfType.MERCHANT_SELF, name: '<<merchantSelf>>' },
                                { id: MerchantSelfType.LEAGUE, name: '<<league>>' },
                                { id: MerchantSelfType.LEASE, name: '<<lease>>' },
                                { id: MerchantSelfType.OTHER, name: '<<other>>' },
                            ],
                            rules: [
                                {
                                    required: true,
                                    message: '<<merchantTypeRequired>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                placeholder: '<<selectPlease>>',
                            },
                        },
                        selfType: {
                            type: 'string.options.select',
                            displayName: '<<merchantType>>',
                            options: [{ id: MerchantSelfType.SELF, name: '<<merchantSelf>>' }],
                            rules: [
                                {
                                    required: true,
                                    message: '<<merchantTypeRequired>>',
                                },
                            ],
                            controlConfig: {
                                disabled: true,
                                style: {
                                    width: 400,
                                },
                                placeholder: '<<selectPlease>>',
                            },
                        },
                        logoPicture: {
                            type: 'array.imageObjectArrayForTopPic',
                            displayName: '<<merchantLogo>>',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                                extra: {
                                    tip: '<<logoPictureTip>>',
                                    img: './images/avatar/merchant-logo-preview.png',
                                    btnText: '<<viewDiagram>>',
                                    triggerType: 'hover',
                                    style: {
                                        width: 400,
                                    },
                                },
                            },
                        },
                        propagatePicture: {
                            type: 'array.imageObjectArrayForTopPic',
                            displayName: '<<promotionalImages>>',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                                extra: {
                                    tip: '<<propagatePictureLimit>>',
                                    img: './images/avatar/merchant-logo-preview.png',
                                    btnText: '<<viewDiagram>>',
                                    triggerType: 'hover',
                                    style: {
                                        width: 400,
                                    },
                                },
                            },
                        },
                        customerPhone: {
                            displayName: '<<servicePhone>>',
                            type: 'string',
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                maxLength: 20,
                            },
                        },
                        companyIntroduce: {
                            displayName: '<<merchantIntro>>',
                            type: 'string.text.paragraph',
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                rows: 4,
                                maxLength: 1000,
                            },
                        },
                        address: {
                            displayName: '<<merchantAddress>>',
                            type: 'string',
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        brands: {
                            // type: 'string.options.select',
                            type: 'string.options.autoComplete',
                            displayName: '<<brand>>',
                            options: [],
                            source: {
                                apiPath: '/admin/brands?page=1&per_page=1000',
                                dataType: 'paging',
                            },
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                placeholder: '<<selectPlease>>',
                            },
                        },
                        tagIds: {
                            type: 'array.merchantTagSelector',
                            displayName: '<<merchantTag>>',
                            options: [],
                            source: {
                                apiPath: '/loader/admin/merchant_tags',
                                dependences: ['baseInfo.subsite'],
                                loadOnInit: false,
                                dataType: 'paging',
                            },
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                placeholder: '<<selectPlease>>',
                            },
                        },
                        styleIds: {
                            type: 'array.merchantStyleSelector',
                            displayName: '<<merchantStyle>>',
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                placeholder: '<<selectPlease>>',
                            },
                        },
                        sequence: {
                            type: 'number',
                            displayName: '<<merchantSort>>',
                            controlConfig: {
                                min: 0,
                                max: 999999999,
                                precision: 0,
                                style: {
                                    width: 200,
                                },
                            },
                        },
                        showSid: {
                            type: 'string.options.radio',
                            displayName: '<<indoorMap>>',
                            defaultValue: 'false',
                            options: [
                                {
                                    id: 'true',
                                    name: '<<common.opend>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<common.close>>',
                                },
                            ],
                        },
                        floors: {
                            displayName: '<<merchantPosition>>',
                            type: 'array.arrayMerchantManagerFloors',
                            defaultValue: [],
                            options: [],
                            source: {
                                apiPath: '/loader/admin/subsites/get_floors',
                                dependences: ['baseInfo.subsite'],
                                loadOnInit: false,
                            },
                        },
                    },
                },
                manageSetting: {
                    type: 'object.subForm',
                    displayName: '<<merchantOperation>>',
                    properties: {
                        categoryIds: {
                            displayName: '<<businessCategory>>',
                            type: 'array.merchantManagerCategory',
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        industryId: {
                            type: 'object.option.select',
                            displayName: '<<mainIndustry>>',
                            options: [],
                            source: {
                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SHOP`,
                                apiPath: '/admin/industries',
                            },
                            controlConfig: {
                                style: {
                                    width: 300,
                                },
                            },
                        },
                        allowQuickBilling: {
                            // type: 'string.options.radio',
                            type: 'string.options.radio.plus',
                            displayName: '<<allowQuickBilling>>',
                            defaultValue: 'false',
                            controlConfig: {
                                mode: 'horizontal',
                                privilege: { path: 'merchantBigCodeManager', level: 'full' },
                                setOptions: [
                                    {
                                        value: 'true',
                                        name: '<<common.opend>>',
                                        tip: {
                                            text: '<<allowQuickBillingTip>>',
                                            hrefText: {
                                                target: '_blank',
                                                href: '/merchant-big-codes',
                                                text: '<<setMerchantCode>>',
                                            },
                                        },
                                    },
                                    {
                                        value: 'false',
                                        name: '<<common.close>>',
                                        tip: {
                                            text: '<<allowQuickBillingTip>>',
                                            hrefText: {
                                                target: '_blank',
                                                href: '/merchant-big-codes',
                                                text: '<<setMerchantCode>>',
                                            },
                                        },
                                    },
                                ],
                            },
                            /* options: [
                                {
                                    id: 'true',
                                    name:'<<common.opend>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<common.close>>',
                                },
                            ], */
                            // extra: `导购可以在导购端使用商家码,自定义商品价格开单。<a>111</a>`,
                        },
                        allowScanCashier: {
                            type: 'string.options.radio',
                            displayName: '<<cashierBusiness>>',
                            defaultValue: 'false',
                            options: [
                                {
                                    id: 'true',
                                    name: '<<common.opend>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<common.close>>',
                                },
                            ],
                            extra: '<<cashierBusinessExtra>>',
                        },
                        restaurantReservation: {
                            type: 'object.restaurantReservationSetting',
                            displayName: '<<restaurantReservation>>',
                        },
                        description: {
                            type: 'string.richTextPlus',
                            className: 'merchant-self-description',
                            displayName: '<<description>>',
                            rules: [
                                { whitespace: true, max: 20000, message: '<<notMoreThan2000>>' },
                            ],
                            controlConfig: {
                                placeholder: '<<descriptionRules>>',
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            extra: '<<descriptionExtra>>',
                        },
                        deliveryLogisticsSenderAddressEnum: {
                            type: 'string.options.radio',
                            displayName: '<<merchantDeliveryAddress>>',
                            defaultValue: AddressType.SUBSITE_ADDRESS,
                            options: [
                                { id: AddressType.SUBSITE_ADDRESS, name: '<<usingStoreAddress>>' },
                                {
                                    id: AddressType.MERCHANT_ADDRESS,
                                    name: '<<merchantSingleAddress>>',
                                },
                            ],
                        },
                        deliveryLogisticsSenderName: {
                            displayName: '<<contactName>>',
                            type: 'string.maxLength',
                            rules: [
                                {
                                    required: true,
                                    message: '<<contactNameRequired>>',
                                    validator: (rule: any, value: any) => {
                                        if (!value) {
                                            return false;
                                        }
                                        if (value && !trim(value)) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                placeholder: '<<inputPlease>>',
                                maxLength: 10,
                            },
                        },
                        deliveryLogisticsSenderContactNumber: {
                            displayName: '<<mobile>>',
                            type: 'string.maxLength',
                            rules: [
                                {
                                    required: true,
                                    message: '<<mobileRequired>>',
                                    validator: (rule: any, value: any) => {
                                        if (!value) {
                                            return false;
                                        }
                                        if (value && !trim(value)) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                                {
                                    message: '<<onlyNumber>>',
                                    validator: (rule: any, value: any) => {
                                        if (value && !/^[0-9]+$/.test(value)) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                placeholder: '<<inputPlease>>',
                                maxLength: 11,
                            },
                        },
                        deliveryLogisticsSenderRegionId: {
                            displayName: '<<selectRegion>>',
                            type: 'array.regionsSelector',
                            rules: [
                                {
                                    required: true,
                                    message: '<<selectRange>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        deliveryLogisticsSenderAddress: {
                            displayName: '<<detailAddress>>',
                            type: 'string.maxLength',
                            rules: [
                                {
                                    required: true,
                                    message: '<<detailAddressRequired>>',
                                    validator: (rule: any, value: any) => {
                                        if (!value) {
                                            return false;
                                        }
                                        if (value && !trim(value)) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                placeholder: '<<moreThan100>>',
                                maxLength: 100,
                            },
                        },
                        addressEnum: {
                            type: 'string.options.radio',
                            displayName: '<<afterSaleAddress>>',
                            defaultValue: AddressType.SUBSITE_ADDRESS,
                            options: [
                                { id: AddressType.SUBSITE_ADDRESS, name: '<<usingStoreAddress>>' },
                                {
                                    id: AddressType.MERCHANT_ADDRESS,
                                    name: '<<merchantAfterSaleAddress>>',
                                },
                            ],
                        },
                        afterSalesContacts: {
                            displayName: '<<contactName>>',
                            type: 'string.maxLength',
                            rules: [
                                {
                                    required: true,
                                    message: '<<contactNameRequired>>',
                                    validator: (rule: any, value: any) => {
                                        if (!value) {
                                            return false;
                                        }
                                        if (value && !trim(value)) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                placeholder: '<<inputPlease>>',
                                maxLength: 10,
                            },
                        },
                        afterSalesPhone: {
                            displayName: '<<mobile>>',
                            type: 'string.maxLength',
                            rules: [
                                {
                                    required: true,
                                    message: '请输入联系电话',
                                    validator: (rule: any, value: any) => {
                                        if (!value) {
                                            return false;
                                        }
                                        if (value && !trim(value)) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                                {
                                    message: '<<onlyNumber>>',
                                    validator: (rule: any, value: any) => {
                                        if (value && !/^[0-9]+$/.test(value)) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                placeholder: '<<inputPlease>>',
                                maxLength: 11,
                            },
                        },
                        afterSalesSubsiteId: {
                            displayName: '<<selectRegion>>',
                            type: 'array.regionsSelector',
                            rules: [
                                {
                                    required: true,
                                    message: '<<selectRange>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        afterSalesAddress: {
                            displayName: '<<detailAddress>>',
                            type: 'string.maxLength',
                            rules: [
                                {
                                    required: true,
                                    message: '<<detailAddressRequired>>',
                                    validator: (rule: any, value: any) => {
                                        if (!value) {
                                            return false;
                                        }
                                        if (value && !trim(value)) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                placeholder: '<<moreThan100>>',
                                maxLength: 100,
                            },
                        },
                        independentAfterSaleBackModes: {
                            type: 'string.options.radio',
                            displayName: '<<independentAfterSaleBackModes>>',
                            defaultValue: 'false',
                            rules: [{ required: true }],
                            options: [
                                { id: 'false', name: '<<merchantBackType>>' },
                                { id: 'true', name: '<<merchantIndependentType>>' },
                            ],
                        },
                        afterSaleBackModes: {
                            type: 'array.optionIds.checkbox',
                            displayName: '<<returnedGoodsMethod>>',
                            defaultValue: [],
                            options: [
                                {
                                    id: '3',
                                    name: '<<sendStore>>',
                                },
                                {
                                    id: '1',
                                    name: '<<deliveryMethodReturning>>',
                                },
                            ],
                        },
                    },
                },
                seniorSetting: {
                    type: 'object.subForm',
                    displayName: '<<seniorSetting>>',
                    collapsed: true,
                    properties: {
                        companyInfo: {
                            type: 'object.subForm',
                            displayName: '<<companyInfo>>',
                            properties: {
                                companyName: {
                                    displayName: '<<companyName>>',
                                    type: 'string',
                                    controlConfig: {
                                        style: {
                                            width: 400,
                                        },
                                        maxLength: 60,
                                    },
                                },
                                companyType: {
                                    displayName: '<<companyType>>',
                                    type: 'string',
                                    controlConfig: {
                                        style: {
                                            width: 400,
                                        },
                                        maxLength: 60,
                                    },
                                },
                                companyScale: {
                                    displayName: '<<companyScale>>',
                                    type: 'string',
                                    controlConfig: {
                                        style: {
                                            width: 400,
                                        },
                                        maxLength: 60,
                                    },
                                },
                                companyLegal: {
                                    displayName: '<<companyLegal>>',
                                    type: 'string',
                                    controlConfig: {
                                        style: {
                                            width: 400,
                                        },
                                        maxLength: 60,
                                    },
                                },
                                companyLegalIdNumber: {
                                    displayName: '<<companyLegalIdNumber>>',
                                    type: 'string',
                                    controlConfig: {
                                        style: {
                                            width: 400,
                                        },
                                        maxLength: 60,
                                    },
                                },
                                accountName: {
                                    displayName: '<<accountName>>',
                                    type: 'string',
                                    controlConfig: {
                                        style: {
                                            width: 400,
                                        },
                                        maxLength: 60,
                                    },
                                },
                                companyBankName: {
                                    displayName: '<<companyBankName>>',
                                    type: 'string',
                                    controlConfig: {
                                        style: {
                                            width: 400,
                                        },
                                        maxLength: 60,
                                    },
                                },
                                companyAccountNumber: {
                                    displayName: '<<companyAccountNumber>>',
                                    type: 'string',
                                    controlConfig: {
                                        style: {
                                            width: 400,
                                        },
                                        maxLength: 60,
                                    },
                                },
                                companyBusinessPicture: {
                                    displayName: '<<companyBusinessPicture>>',
                                    type: 'imageObjectArray',
                                    className: 'sigle-image-field',
                                    controlConfig: {
                                        maxCount: 1,
                                        multiple: false,
                                        fileName: 'fileName',
                                        uploadType: 'multi',
                                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                        mode: 'picture-card',
                                    },
                                    extra: '<<logoPictureTip>>',
                                },
                                taxRegistrationPicture: {
                                    displayName: '<<taxRegistrationPicture>>',
                                    type: 'imageObjectArray',
                                    className: 'sigle-image-field',
                                    controlConfig: {
                                        maxCount: 1,
                                        multiple: false,
                                        fileName: 'fileName',
                                        uploadType: 'multi',
                                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                        mode: 'picture-card',
                                    },
                                    extra: '<<logoPictureTip>>',
                                },
                                organCodeCertificatePicture: {
                                    displayName: '<<organCodeCertificatePicture>>',
                                    type: 'imageObjectArray',
                                    className: 'sigle-image-field',
                                    controlConfig: {
                                        maxCount: 1,
                                        multiple: false,
                                        fileName: 'fileName',
                                        uploadType: 'multi',
                                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                        mode: 'picture-card',
                                    },
                                    extra: '<<organCodeCertificatePictureExtra>>',
                                },
                                openLicensePicture: {
                                    displayName: '<<openLicensePicture>>',
                                    type: 'imageObjectArray',
                                    className: 'sigle-image-field',
                                    controlConfig: {
                                        maxCount: 1,
                                        multiple: false,
                                        fileName: 'fileName',
                                        uploadType: 'multi',
                                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                        mode: 'picture-card',
                                    },
                                    extra: '<<logoPictureTip>>',
                                },
                                legalFrontPicture: {
                                    displayName: '<<legalFrontPicture>>',
                                    type: 'imageObjectArray',
                                    className: 'sigle-image-field',
                                    controlConfig: {
                                        maxCount: 1,
                                        multiple: false,
                                        fileName: 'fileName',
                                        uploadType: 'multi',
                                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                        mode: 'picture-card',
                                    },
                                    extra: '<<logoPictureTip>>',
                                },
                                legalBackPicture: {
                                    displayName: '<<legalBackPicture>>',
                                    type: 'imageObjectArray',
                                    className: 'sigle-image-field',
                                    controlConfig: {
                                        maxCount: 1,
                                        multiple: false,
                                        fileName: 'fileName',
                                        uploadType: 'multi',
                                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                        mode: 'picture-card',
                                    },
                                    extra: '<<logoPictureTip>>',
                                },
                            },
                        },
                        operatorInfo: {
                            type: 'object.subForm',
                            displayName: '<<operatorInfo>>',
                            properties: {
                                operatorName: {
                                    displayName: '<<operatorName>>',
                                    type: 'string',
                                    controlConfig: {
                                        style: {
                                            width: 400,
                                        },
                                        maxLength: 60,
                                    },
                                },
                                operatorDepartment: {
                                    displayName: '<<operatorDepartment>>',
                                    type: 'string',
                                    controlConfig: {
                                        style: {
                                            width: 400,
                                        },
                                        maxLength: 60,
                                    },
                                },
                                email: {
                                    displayName: '<<email>>',
                                    type: 'string',
                                    controlConfig: {
                                        style: {
                                            width: 400,
                                        },
                                        maxLength: 225,
                                    },
                                },
                                phone: {
                                    displayName: '<<phone>>',
                                    type: 'string',
                                    controlConfig: {
                                        style: {
                                            width: 400,
                                        },
                                        maxLength: 20,
                                    },
                                },
                                operatorCertFrontPicture: {
                                    displayName: '<<operatorCertFrontPicture>>',
                                    type: 'imageObjectArray',
                                    className: 'sigle-image-field',
                                    controlConfig: {
                                        maxCount: 1,
                                        multiple: false,
                                        fileName: 'fileName',
                                        uploadType: 'multi',
                                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                        mode: 'picture-card',
                                    },
                                    extra: '<<logoPictureTip>>',
                                },
                                operatorCertBackPicture: {
                                    displayName: '<<operatorCertBackPicture>>',
                                    type: 'imageObjectArray',
                                    className: 'sigle-image-field',
                                    controlConfig: {
                                        maxCount: 1,
                                        multiple: false,
                                        fileName: 'fileName',
                                        uploadType: 'multi',
                                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                        mode: 'picture-card',
                                    },
                                    extra: '<<logoPictureTip>>',
                                },
                            },
                        },
                        otherInfo: {
                            type: 'object.subForm',
                            displayName: '<<other>>',
                            properties: {
                                serviceTagStylePicture: {
                                    type: 'array.imageObjectArrayForTopPic',
                                    displayName: '<<serviceTagStylePicture>>',
                                    className: 'sigle-image-field',
                                    controlConfig: {
                                        maxCount: 1,
                                        multiple: false,
                                        fileName: 'fileName',
                                        uploadType: 'multi',
                                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                        mode: 'picture-card',
                                        extra: {
                                            tip: '<<serviceTagStylePictureExtra>>',
                                            img: './images/avatar/merchant-service-tag.png',
                                            btnText: '<<viewDiagram>>',
                                            triggerType: 'hover',
                                            style: {
                                                width: 400,
                                            },
                                        },
                                    },
                                },
                                logoMiniPicture: {
                                    type: 'array.imageObjectArrayForTopPic',
                                    displayName: '<<logoMiniPicture>>',
                                    className: 'sigle-image-field',
                                    controlConfig: {
                                        maxCount: 1,
                                        multiple: false,
                                        fileName: 'fileName',
                                        uploadType: 'multi',
                                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                        mode: 'picture-card',
                                        extra: {
                                            tip: '<<logoMiniPictureExtra>>',
                                            img: './images/avatar/merchant-logo-mini.png',
                                            btnText: '<<viewDiagram>>',
                                            triggerType: 'hover',
                                            style: {
                                                width: 400,
                                            },
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        MerchantSelfView: {
            component: 'Viewport',
        },
        MerchantSelfAddPage: {
            component: 'FlexLayout',
            entity: 'MerchantSelfEntity',
            direction: 'horizontal',
            items: [{ component: 'MerchantSelfAddPageForm' }],
        },
        MerchantSelfEditPage: {
            component: 'FlexLayout',
            entity: 'MerchantSelfEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'MerchantSelfEditPageForm' }],
        },
        MerchantSelfLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SHOP`,
                    apiPath: '/admin/merchants/{id}/operation_logs',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/merchant-self',
                    component: 'MerchantSelfView',
                    breadcrumbName: '<<merchantManager>>',
                    privilege: {
                        path: 'storeInfo',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'MerchantSelfAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'MerchantSelfEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        {
                            path: '/logs/:id',
                            component: 'MerchantSelfLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        {
                            path: '/',
                            component: 'MerchantSelfPageView',
                        },
                    ],
                },
            ],
        },
    ],
};
