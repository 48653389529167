import React, { PureComponent, createElement } from 'react';
import { services } from '@comall-backend-builder/core';
import { errorHandle, language } from '@comall-backend-builder/core/lib/services';
import { ComponentsManager } from '@comall-backend-builder/core';
import { Button, Form, Icon, Input, Select, Table, Tooltip, Modal, Spin } from 'antd';
import PropTypes from 'prop-types';

const { confirm } = Modal;
const { api, navigation } = services;
const CREATION_METHOD = [
    { id: 'RULE_MODE', name: 'gzcj' },
    { id: 'IMPORT_MEMBER_MODE', name: 'drzdyh' },
];
const customModeStatus: any = {
    RULE_MODE: 'gzcj',
    IMPORT_MEMBER_MODE: 'drzdyh',
};

const CrowdCrowdCreateForm = Form.create({ name: 'searchForm' })(
    class extends React.Component {
        static propTypes: {
            onSearch: PropTypes.Requireable<(...args: any[]) => any>;
            subsites: [];
        };
        handleReset = () => {
            const { form } = this.props as any;
            form.resetFields();
        };
        handleSearch = (e: any) => {
            e.preventDefault();
            const { form, onSearch } = this.props as any;
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSearch(values);
                }
            });
        };
        render() {
            const { form, subsites } = this.props as any;
            const { getFieldDecorator } = form;
            return (
                <Form layout="inline" ref="searchFrom">
                    <Form.Item label={language.getText('peopleName')}>
                        {getFieldDecorator('crowdName')(
                            <Input
                                className="find-filter-width"
                                placeholder={services.language.getText('inputPlease')}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('subsites')}>
                        {getFieldDecorator('subsiteId')(
                            <Select
                                className="find-filter-width"
                                placeholder={services.language.getText('selectPlease')}
                            >
                                {subsites &&
                                    subsites.map((subsite: any) => {
                                        return (
                                            <Select.Option key={subsite.id} value={subsite.id}>
                                                {subsite.name}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('createType')}>
                        {getFieldDecorator('creationMode')(
                            <Select
                                className="find-filter-width"
                                placeholder={services.language.getText('selectPlease')}
                            >
                                {CREATION_METHOD.map((item: any) => {
                                    return (
                                        <Select.Option key={item.id} value={item.id}>
                                            {language.getText(item.name)}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.handleSearch}>
                            {language.getText('common.search')}
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="reset" onClick={this.handleReset}>
                            {language.getText('common.reset')}
                        </Button>
                    </Form.Item>
                </Form>
            );
        }
    }
);

export class CustomCrowdPage extends PureComponent {
    state = {
        datasource: [],
        searchParams: {},
        total: 0,
        current: 0,
        pagination: {
            page: 1,
            perPage: 10,
        },
        subsites: [],
        loading: false,
    };

    componentDidMount() {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: '/WEB-API/admin/defaults/subsite',
            }
        ).then((subsites) => {
            this.setState({
                subsites,
            });
        });
        this.onSearch();
    }

    onSearchFind = (searchParams: object = {}) => {
        this.setState(
            {
                pagination: {
                    page: 1,
                    perPage: 10,
                },
            },
            () => {
                this.onSearch(searchParams);
            }
        );
    };

    onSearch = async (searchParams: object = {}) => {
        this.setState({ searchParams: searchParams, loading: true });
        const { pagination } = this.state;
        try {
            let res: any = await services.api.get(
                {
                    ...searchParams,
                    ...pagination,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                    apiPath: '/admin/crowds',
                }
            );
            if (res && res.result) {
                this.setState({
                    datasource: res.result,
                    total: res.totalNum,
                    current: res.page,
                    loading: false,
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            throw error;
        }
    };
    pageChange = (pageVal: object = {}) => {
        const { searchParams } = this.state;
        this.setState({ pagination: { ...pageVal } }, () => {
            this.onSearch(searchParams);
        });
    };

    onDelete = (id: any) => {
        const { searchParams } = this.state;
        const _this = this;
        confirm({
            title: '',
            content: language.getText('schjbkhf'),
            onOk() {
                services.api
                    .put(
                        { id },
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                            apiPath: `/admin/crowds/${id}/delete`,
                        }
                    )
                    .then(() => {
                        _this.onSearch(searchParams);
                    })
                    .catch(errorHandle);
            },
            onCancel() {},
        });
    };
    renderBatchButton = (row: any) => {
        return createElement(ComponentsManager.get('Dropdown'), {
            content: {
                contentType: 'link',
                text: language.getText('yyrq'),
            },
            style: { display: 'inline-flex' },
            menuItems: [
                {
                    component: 'Button',
                    configs: {
                        text: language.getText('dxfq'),
                        type: 'link',
                        route: `/menu-list/coupon/giving-coupon/add?subsiteId=${row.subsiteId}&crowdId=${row.id}`,
                    },
                },
            ],
        });
    };
    goBatchManage = (id: string) => {
        navigation.goto(`/crowd-manage/batch-members/${id}`);
    };

    render() {
        const { datasource, pagination, total, current, subsites, loading } = this.state;
        return (
            <div className="combine-goods-find">
                <div>
                    <CrowdCrowdCreateForm onSearch={this.onSearchFind} subsites={subsites} />
                </div>
                <div className="combine-goods-add">
                    <Button type="primary" href="#/crowd-manage/add">
                        {services.language.getText('xzrq')}
                    </Button>
                </div>
                <Spin spinning={loading}>
                    <Table
                        scroll={{ x: true }}
                        rowKey={(record: any) => record.id}
                        pagination={{
                            pageSize: pagination.perPage,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            pageSizeOptions: ['10', '15', '20'],
                            total,
                            current,
                            showTotal(total: number) {
                                return services.interpolate(services.language.getText('total'), {
                                    total,
                                });
                            },
                            onChange: (page, perPage) => {
                                this.pageChange({ page, perPage });
                            },
                            onShowSizeChange: (page, perPage) => {
                                this.pageChange({ page, perPage });
                            },
                        }}
                        columns={[
                            {
                                title: services.language.getText('peopleName'),
                                dataIndex: 'crowdName',
                                key: 'crowdName',
                            },
                            {
                                title: services.language.getText('createType'),
                                dataIndex: 'creationMode',
                                key: 'creationMode',
                                render: (val: string) => {
                                    return <span>{language.getText(customModeStatus[val])}</span>;
                                },
                            },
                            {
                                title: () => (
                                    <span>
                                        {services.language.getText('peopleNum')}
                                        <Tooltip
                                            title={services.language.getText('limitPeopleCount')}
                                        >
                                            <Icon
                                                style={{ marginLeft: 8 }}
                                                type="question-circle"
                                            />
                                        </Tooltip>
                                    </span>
                                ),
                                dataIndex: 'peopleNumber',
                                key: 'peopleNumber',
                                render: (val: any, record) => {
                                    const userManageHref = `#/member-manage?crowdSubsiteId=${record.subsiteId}&crowdId=${record.id}`;
                                    return (
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={userManageHref}
                                            className="table-action-column-item"
                                        >
                                            {val}
                                        </a>
                                    );
                                },
                            },

                            {
                                title: services.language.getText('subsites'),
                                key: 'subsiteName',
                                dataIndex: 'subsiteName',
                            },
                            {
                                title: language.getText('createTimeStr'),
                                key: 'createTime',
                                dataIndex: 'createTime',
                            },
                            {
                                title: language.getText('bjsj'),
                                key: 'modifiedTime',
                                dataIndex: 'modifiedTime',
                            },
                            {
                                title: services.language.getText('common.tableAction'),
                                className: 'action-column',
                                dataIndex: 'id',
                                key: 'id',
                                fixed: 'right',
                                width: 300,
                                render: (val: any, row: any) => {
                                    const editHref = '#/crowd-manage/edit/' + val;
                                    const canImport: boolean =
                                        row.creationMode !== 'IMPORT_MEMBER_MODE';
                                    return (
                                        <div>
                                            <a href={editHref} className="table-action-column-item">
                                                {language.getText('common.edit')}
                                            </a>
                                            <Button
                                                style={{ padding: 0, marginRight: 10 }}
                                                type="link"
                                                disabled={canImport}
                                                onClick={() => this.goBatchManage(val)}
                                                className="table-action-column-item"
                                            >
                                                {language.getText('drsj')}
                                            </Button>
                                            <span
                                                className="find-choose-wrap"
                                                style={{ padding: 0, marginRight: 10 }}
                                            >
                                                {this.renderBatchButton(row)}
                                            </span>
                                            <Button
                                                type="link"
                                                style={{ padding: 0, marginRight: 0 }}
                                                onClick={() => this.onDelete(val)}
                                            >
                                                {services.language.getText('common.delete')}
                                            </Button>
                                        </div>
                                    );
                                },
                            },
                        ]}
                        dataSource={datasource}
                    />
                </Spin>
            </div>
        );
    }
}
