import React, { PureComponent } from 'react';
import { forEach, cloneDeep, findIndex, isEqual, isEmpty } from 'lodash';
import { ProductSelectedDisplayForSelfDelivery, ProductSelector } from '../../components';

/**
 * 自提规则选择商品
 */
export class SelfDeliverySelectProduct extends PureComponent<{
    value: any[];
    onChange: (v: any) => void;
    row: any;
}> {
    state = {
        value: [],
    };
    getSubsiteIds = (props: any) => {
        const { row } = props;
        const subsiteIds = row.baseInfo && row.baseInfo.subsite ? [row.baseInfo.subsite] : null;
        return subsiteIds;
    };
    componentWillReceiveProps(nextProps: any) {
        const nextSubsiteIds = this.getSubsiteIds(nextProps);
        const currentSubsiteIds = this.getSubsiteIds(this.props);
        if (
            !isEqual(nextSubsiteIds, currentSubsiteIds) &&
            nextSubsiteIds &&
            !isEmpty(nextProps.value)
        ) {
            this.processDataAfterSubsiteChange(nextSubsiteIds, nextProps);
        }
    }

    processDataAfterSubsiteChange = (subsiteIds: string[], props: any) => {
        const { value } = props;
        const newData: any[] = [];
        forEach(value, (i) => {
            if (subsiteIds.indexOf(i.subsiteId + '') > -1) {
                newData.push(i);
            }
        });
        this.onChange(newData);
    };

    processSelectedData = (data: any[]) => {
        let items: any[] = [];
        if (data && data.length) {
            forEach(data, (product) => {
                forEach(product.goodsVos, (goods: any) => {
                    items.push({
                        id: goods.id,
                        name: goods.name,
                        productName: product.name,
                        productCode: product.barcode,
                        goodsCode: goods.csku,
                        subsiteId: goods.subsiteId + '',
                        subsiteName: goods.subsiteName,
                        merchantName: goods.merchantName,
                        marketPrice: (+goods.marketPrice.value).toFixed(2),
                        salesPrice: (+goods.salesPrice.value).toFixed(2),
                        goodsDeductPoint: goods.points.value,
                        goodsStock: goods.stock,
                    });
                });
            });
        }
        return items;
    };

    onChange = (goods: any[]) => {
        const { onChange } = this.props;
        forEach(goods, (i) => {
            i.profit = (i.groupBuyPrice - i.groupPrice).toFixed(2);
        });
        onChange(goods);
    };

    onSelect = (values: any[]) => {
        const data = this.processSelectedData(values);
        let newData: any[] = [];
        const { value } = this.props;
        newData = cloneDeep(value || []);
        data.forEach((i: any) => {
            const hasIndex = findIndex(value, (o: any) => o.id === i.id);
            if (hasIndex === -1) {
                newData.unshift(i);
            }
        });
        this.onChange(newData);
    };

    render() {
        const { value } = this.props;
        const subsiteIds = this.getSubsiteIds(this.props);
        const hasValue = value && value.length > 0;
        return (
            <div className="cgb-select-product">
                <ProductSelector
                    onChange={this.onSelect}
                    rowSelectionType="checkbox"
                    params={{ subsiteIds: subsiteIds, productTypes: ['NORMAL,COMBINE'] }}
                />
                {hasValue && (
                    <ProductSelectedDisplayForSelfDelivery
                        modifiable={true}
                        data={value}
                        onChange={this.onChange}
                    ></ProductSelectedDisplayForSelfDelivery>
                )}
            </div>
        );
    }
}
