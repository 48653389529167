import React, { PureComponent, Fragment } from 'react';
import { findIndex, isEqual, remove, toString } from 'lodash';
import { Button, InputNumber, Popconfirm, message as AntMessage } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
// import { services } from '@comall-backend-builder/core';
import { privilege, language } from '@comall-backend-builder/core/lib/services';

import {
    CouponSelector,
    CouponType,
    EventCouponValueTypeEnum,
    CouponValueType,
    Coupon,
} from './select-coupon';
import { services } from '@comall-backend-builder/core';

interface NewCouponSelectorProps {
    onChange: (value: Array<Coupon>) => void;
    value: Array<Coupon> | undefined;
    disabled?: boolean;
    subsiteIds?: string;
    dependentSubsiteIds?: boolean;
    /**
     * 发放文案
     */
    grantText?: string;
    inputNumberProps?: InputNumberProps;
    multiEntity?: boolean;
}

interface NewCouponSelectorStates {
    noPrivilegeId?: string;
}

export class NewCouponSelector extends PureComponent<
    NewCouponSelectorProps,
    NewCouponSelectorStates
> {
    CouponSelector: any;
    constructor(props: NewCouponSelectorProps) {
        super(props);
        this.state = {};
    }
    componentWillReceiveProps(nextProps: NewCouponSelectorProps) {
        const { subsiteIds: currentSubsiteIds, onChange } = this.props;
        const { subsiteIds: nextSubsiteIds } = nextProps;
        /**
         * 门店id发生变化时 优惠券赠送重置
         */
        if (!currentSubsiteIds) return;
        if (!isEqual(nextSubsiteIds, currentSubsiteIds)) {
            onChange([]);
        }
    }
    modalRef = (ref: any) => {
        this.CouponSelector = ref;
    };

    coverValue = () => {
        const { value = [] } = this.props;
        return [...value];
    };

    setDisplayType = (coupons: Coupon[]) => {
        let displayType = 'selectCoupon';
        if (coupons.length && this.getCouponType(coupons[0]) === 'PACKAGE') {
            displayType = 'selectCouponPackage';
        }
        return displayType;
    };
    showModal = () => {
        const { subsiteIds } = this.props;
        if (!subsiteIds || subsiteIds.length === 0) {
            AntMessage.warning(services.language.getText('selectBeforeMerchant'));
            return;
        }
        this.CouponSelector.toggleModal();
    };
    getCouponType = (coupon: Coupon) => {
        return coupon.type || coupon.couponType;
    };
    getCouponValueType = (coupons: Array<Coupon>) => {
        if (!coupons?.length) {
            return null;
        }
        return this.getCouponType(coupons[0]) === 'PACKAGE'
            ? CouponValueType.Package
            : CouponValueType.Coupon;
    };
    changeCoupons = (coupons: Array<Coupon>) => {
        let value = this.coverValue();
        const type = this.getCouponValueType(value);
        const newType = this.getCouponValueType(coupons);
        if (newType !== type) {
            value = [];
        }
        if (type === CouponValueType.Coupon) {
            coupons.forEach((selectCuopon) => {
                const hasIndex = findIndex(
                    value,
                    (coupon) => toString(coupon.id) === toString(selectCuopon.id)
                );
                if (hasIndex === -1) {
                    value.push(selectCuopon);
                }
            });
        } else {
            value = [...coupons];
        }
        this.props.onChange(value);
    };
    changeStock = (index: number, value: number | undefined) => {
        const coupons = this.coverValue();
        coupons[index].quantity = value;
        this.props.onChange(coupons);
    };
    deleteCouponItem = (index: number) => {
        const coupons = this.coverValue();
        remove(coupons, (_, idx) => idx === index);
        this.props.onChange(coupons);
    };
    CheckCoupon = (item: Coupon) => {
        const { id } = item;
        let hasPrivilege = true;
        let route = '';
        const baseUrl = '#/menu-list/coupon';
        switch (this.getCouponType(item)) {
            case CouponType.PACKAGE:
                route = `${baseUrl}/coupon-package-rules/edit/${id}`;
                hasPrivilege = privilege.check('couponPackageRule', 'full');
                break;
            case CouponType.CASH:
                route = `${baseUrl}/cash-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('cashCouponRule', 'full');
                break;
            case CouponType.GIFT:
                route = `${baseUrl}/gift-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('giftCouponRule', 'full');
                break;
            case CouponType.FREIGHT:
                route = `${baseUrl}/freight-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('freightCouponRule', 'full');
                break;
            case CouponType.PARKING:
                route = `${baseUrl}/parking-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('parkingCouponRule', 'full');
                break;
            case CouponType.LOTTERY:
                route = `${baseUrl}/lottery-coupon/edit/${id}`;
                hasPrivilege = privilege.check('lotteryCouponRule', 'full');
                break;
            case CouponType.BALANCE:
                route = `${baseUrl}/balance-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('balanceCouponRule', 'full');
                break;

            default:
                break;
        }
        if (hasPrivilege && route) {
            window.open(`${route}`, '_blank');
        } else {
            this.setState({
                noPrivilegeId: id,
            });
        }
    };
    renderValue = (coupon: Coupon) => {
        const { couponValue, type } = coupon;
        const couponRuleType = type;

        if (couponRuleType === CouponType.GIFT) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__text">
                        {services.language.getText('presents')}
                    </span>
                </div>
            );
        } else if (couponRuleType === CouponType.LOTTERY) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__num">{couponValue.value}</span>
                </div>
            );
        } else if (couponRuleType === CouponType.PACKAGE) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__text">礼包</span>
                </div>
            );
        } else if (
            couponRuleType === CouponType.PARKING &&
            couponValue.type === EventCouponValueTypeEnum.No
        ) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__text">
                        {services.language.getText('qm_2')}
                    </span>
                </div>
            );
        }
        if (!couponValue || !couponValue.value) {
            return null;
        } else if (
            type === CouponType.PARKING &&
            couponValue.type === EventCouponValueTypeEnum.Hours
        ) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__num">{couponValue.value}</span>
                    <span className="event-coupon__symbol">
                        {services.language.getText('hour')}
                    </span>
                </div>
            );
        }
        return (
            <div className="event-coupon__price">
                <span className="event-coupon__symbol">
                    {services.language.getText('monetaryUnit')}
                </span>
                <span className="event-coupon__price__num">{couponValue.value}</span>
            </div>
        );
    };
    renderCoupon = (coupon: Coupon) => {
        const innerClassName = `${this.getCouponType(coupon)}-coupon-popup__list__item`;
        const bg = 'https://b-puzhehei-cdn.co-mall.net/global/event_coupon_bg.png';
        const style = {
            backgroundImage: `url(${bg})`,
        };

        return (
            <div className={innerClassName}>
                <div className="event-coupon" style={style}>
                    <div className="event-coupon__left">{this.renderValue(coupon)}</div>
                    <div className="event-coupon__right">
                        <div className="event-coupon__right__title">{coupon.name}</div>
                        <div className="event-coupon__right__time">
                            {coupon.startDate}-{coupon.endDate}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderCoupons = () => {
        const { value = [], disabled, grantText, inputNumberProps } = this.props;
        const { noPrivilegeId } = this.state;
        return value.map((item, index: number) => {
            const unitTxt =
                this.getCouponType(item) === CouponType.PACKAGE
                    ? services.language.getText('ge')
                    : services.language.getText('zhang');
            return (
                <div
                    key={item.id}
                    style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}
                >
                    <div>{this.renderCoupon(item)}</div>
                    <div style={{ minWidth: '150px', padding: '0 20px' }}>
                        {services.language.getText('stock')}：{item.leftStock}
                    </div>
                    <div style={{ width: '205px', flexShrink: 0 }}>
                        {grantText || services.language.getText('mrmcff')}：
                        <InputNumber
                            placeholder={services.language.getText('inputPlease')}
                            value={item.quantity}
                            min={0}
                            max={999999999}
                            disabled={disabled}
                            onChange={(value: number | undefined) => this.changeStock(index, value)}
                            {...inputNumberProps}
                        ></InputNumber>
                        {unitTxt}
                    </div>
                    <Popconfirm
                        title={`${services.language.getText('qrsc')}${item.name}?`}
                        onConfirm={() => this.deleteCouponItem(index)}
                        okText={services.language.getText('common.confirm')}
                        cancelText={language.getText('common.cancel')}
                    >
                        <Button disabled={disabled} type="link">
                            {services.language.getText('common.delete')}
                        </Button>
                    </Popconfirm>

                    <Button type="link" onClick={() => this.CheckCoupon(item)}>
                        {services.language.getText('ckqgz')}
                    </Button>
                    {noPrivilegeId === item.id && (
                        <div style={{ color: 'red', flexShrink: 0 }}>
                            {services.language.getText('zwckqgzqx')}
                        </div>
                    )}
                </div>
            );
        });
    };
    renderModal = () => {
        const { value, subsiteIds, dependentSubsiteIds, multiEntity } = this.props;
        return (
            <CouponSelector
                onRef={this.modalRef}
                selectValues={value}
                onChange={this.changeCoupons}
                params={{ subsiteIds }}
                dependentSubsiteIds={dependentSubsiteIds}
                multiEntity={multiEntity}
            />
        );
    };
    render() {
        const { disabled } = this.props;
        return (
            <Fragment>
                <Button disabled={disabled} type={'link'} onClick={this.showModal}>
                    {services.language.getText('xzyhq')}
                </Button>
                {this.renderCoupons()}
                {this.renderModal()}
            </Fragment>
        );
    }
}
