import * as React from 'react';
import classNames from 'classnames';
import { ArrayMode } from '@comall-backend-builder/types';
import { MerchantManagerFloors } from './merchant-manager-floors';
import { language } from '@comall-backend-builder/core/lib/services';

export class ArrayMerchantManagerFloors extends ArrayMode {
    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            language.getText('notData')
        );
    }
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <MerchantManagerFloors {...controlInfo} />;
    }
}
