import { language } from '@comall-backend-builder/core/lib/services';
import React from 'react';
import { ColumnProps } from 'antd/lib/table';

export const enum ThirdRequestLogType {
    INVOICE = 'INVOICE',
}

export interface ThirdRequestLogItem {
    type: ThirdRequestLogType;
    typeName: string;
    loaderConfig: {
        apiRoot: string;
        apiPath: string;
    };
    inputProps?: {
        required: boolean;
        filed: string;
        name: string;
        placeholder: string;
        value: string;
    };
    tableColumns: ColumnProps<any>[];
}

export function getThirdRequestLogListData() {
    const ThirdRequestLogListData: Array<ThirdRequestLogItem> = [
        {
            type: ThirdRequestLogType.INVOICE,
            typeName: '发票',
            loaderConfig: {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
                apiPath: `/admin/invoices/request_logs`,
            },
            inputProps: {
                required: true,
                filed: 'orderNo',
                name: '订单号',
                placeholder: '请输入',
                value: '',
            },
            tableColumns: [
                {
                    title: language.getText('jkms'),
                    dataIndex: 'description',
                    key: 'description',
                    width: 200,
                },
                {
                    title: language.getText('processStatus'),
                    dataIndex: 'result',
                    key: 'result',
                    render: (text: any) => {
                        if (text === '失败') {
                            return <div style={{ color: 'red' }}>{text}</div>;
                        }
                        return <div>{text}</div>;
                    },
                },
                {
                    title: language.getText('qqsj'),
                    dataIndex: 'time',
                    key: 'time',
                },
                {
                    title: language.getText('qqdz'),
                    dataIndex: 'url',
                    key: 'url',
                    width: 250,
                    render: (text: any) => {
                        return <div className="third-log-row-word-wrap">{text}</div>;
                    },
                },
                {
                    title: language.getText('qqbw'),
                    dataIndex: 'requestBody',
                    key: 'requestBody',
                    width: 300,
                    render: (text: any) => {
                        return <div className="third-log-row-word-wrap">{text}</div>;
                    },
                },
                {
                    title: language.getText('xxbw'),
                    dataIndex: 'responseBody',
                    key: 'responseBody',
                    width: 300,
                    render: (text: any) => {
                        return <div className="third-log-row-word-wrap">{text}</div>;
                    },
                },
                {
                    title: language.getText('xxzt'),
                    dataIndex: 'responseStatus',
                    key: 'responseStatus',
                },
            ],
        },
    ];
    return ThirdRequestLogListData;
}
