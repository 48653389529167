import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { forEach } from 'lodash';

export const subsiteStyleGroupConfig: Config = {
    entities: {
        SbusiteStyleGroupEntity: {
            apiPath: '/loader/admin/style-group',
            paramsFilter: ['SUBSITE'],
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<multiStyleProduct>>',
                    controlConfig: {
                        placeholder: '<<multiStyleProductRequired>>',
                    },
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 200 } },
                },
                productName: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                    controlConfig: {
                        placeholder: '<<productNameRequired>>',
                    },
                },
                productCode: {
                    type: 'string',
                    displayName: '<<productCode>>',
                    controlConfig: {
                        displayName: '<<productCodeRequired>>',
                    },
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                productVos: {
                    type: 'array.popover',
                    displayName: '<<productVos>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherBaseInfo>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<multiStyleProduct>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<multiStyleProductRequired>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<multiStyleProductRequired>>',
                                maxLength: 20,
                                style: {
                                    width: 400,
                                },
                            },
                            extra: '<<multiStyleProductRequiredExtra>>',
                        },
                        subsite: {
                            type: 'object.option.autoComplete',
                            displayName: '<<otherSelectSubsite>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [{ required: true }],
                            controlConfig: {
                                style: {
                                    width: 300,
                                },
                            },
                        },
                    },
                },
                styleGroupInfo: {
                    type: 'object.subForm',
                    displayName: '<<styleGroupInfo>>',
                    properties: {
                        optionList: {
                            type: 'array.styleGroup',
                            displayName: '<<optionList>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                pageType: 'subsite',
                            },
                        },
                    },
                },
                otherInfo: {
                    type: 'object.subForm',
                    displayName: '<<picTextSetting>>',
                    properties: {
                        masterDetails: {
                            type: 'string.options.radio',
                            displayName: '<<applyMainPictures>>',
                            defaultValue: 'false',
                            options: [
                                { id: 'false', name: '<<no>>' },
                                { id: 'true', name: '<<yes>>' },
                            ],
                            extra: '<<selectMainPic>>',
                        },
                    },
                },
            },
        },
    },
    components: {
        SubsiteStyleGroupView: {
            component: 'Viewport',
            entity: 'SbusiteStyleGroupEntity',
            params: { entry: 'subsite' },
        },
        SubsiteStyleGroupPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'SubsiteStyleGroupFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<createMultiStyleProduct>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/subsite-style-group/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'SubsiteStyleGroupTable' },
            ],
        },
        SubsiteStyleGroupFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
                style: {
                    marginLeft: 16,
                },
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'subsiteId',
                },
                {
                    property: 'productName',
                },
                {
                    property: 'productCode',
                },
            ],
        },
        SubsiteStyleGroupTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'SubsiteStyleGroupDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'name',
                            width: 180,
                        },
                        {
                            property: 'productVos',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '140px',
                                },
                                countSuffix: '<<components.Products.products>>',
                            },
                        },
                        {
                            property: 'subsiteName',
                        },
                    ],
                    actionColumn: {
                        key: 'operation',
                        className: 'action-column',
                        title: '<<common.tableAction>>',
                        items: [
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                path: '/subsite-style-group/edit/{{row.id}}',
                            },
                            {
                                type: 'delete',
                                text: '<<common.delete>>',
                                config: {
                                    type: 'link',
                                    id: 'row.id',
                                    tooltip: null,
                                    title: '<<confirmDelete>>',
                                    okText: '<<common.ok>>',
                                    cancelText: '<<common.cancel>>',
                                },
                            },
                        ],
                    },
                },
            ],
        },
        SubsiteStyleGroupAddPage: {
            component: 'Card',
            content: { component: 'SubsiteStyleGroupAddForm' },
        },
        SubsiteStyleGroupAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'SbusiteStyleGroupEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.subsite' },
                { property: 'baseInfo.name' },
                { property: 'styleGroupInfo.optionList' },
                { property: 'otherInfo.masterDetails' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.goBack>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const optionList = entity.styleGroupInfo.optionList;
                let hasValidate = true;
                if (!optionList || !optionList.length) {
                    AntMessage.warning(services.language.getText('setStyleInfo'));
                    hasValidate = false;
                    return false;
                }
                // 校验主款不能为空，不能全部款式为空
                let hasGoods = false;
                let hasMainGoods = false;
                forEach(optionList, (item) => {
                    if (item.goodsId) {
                        hasGoods = true;
                        if (item.mainGoods) {
                            hasMainGoods = true;
                        }
                    }
                });
                if (!hasGoods) {
                    AntMessage.warning(services.language.getText('multiStyleProductNotEmpty'));
                    hasValidate = false;
                    return false;
                }
                if (!hasMainGoods) {
                    AntMessage.warning(services.language.getText('mainProductNotEmpty'));
                    hasValidate = false;
                    return false;
                }

                if (!hasValidate) {
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        SubsiteStyleGroupEditPage: {
            component: 'Card',
            content: { component: 'SubsiteStyleGroupEditForm' },
        },
        SubsiteStyleGroupEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'SbusiteStyleGroupEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.subsite' },
                { property: 'baseInfo.name' },
                { property: 'styleGroupInfo.optionList' },
                { property: 'otherInfo.masterDetails' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.goBack>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const optionList = entity.styleGroupInfo.optionList;
                let hasValidate = true;
                if (!optionList || !optionList.length) {
                    AntMessage.warning(services.language.getText('setStyleInfo'));
                    hasValidate = false;
                    return false;
                }
                // 校验主款不能为空，不能全部款式为空
                let hasGoods = false;
                let hasMainGoods = false;
                forEach(optionList, (item) => {
                    if (item.goodsId) {
                        hasGoods = true;
                        if (item.mainGoods) {
                            hasMainGoods = true;
                        }
                    }
                });
                if (!hasGoods) {
                    AntMessage.warning(services.language.getText('multiStyleProductNotEmpty'));
                    hasValidate = false;
                    return false;
                }
                if (!hasMainGoods) {
                    AntMessage.warning(services.language.getText('mainProductNotEmpty'));
                    hasValidate = false;
                    return false;
                }
                if (!hasValidate) {
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/subsite-style-group',
                    component: 'SubsiteStyleGroupView',
                    breadcrumbName: '<<subsiteStyleGroup>>',
                    privilege: {
                        path: 'subsiteStyleGroup',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'SubsiteStyleGroupAddPage',
                            breadcrumbName: '<<create>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'SubsiteStyleGroupEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        { path: '/', component: 'SubsiteStyleGroupPage' },
                    ],
                },
            ],
        },
    ],
};
