import React, { Component } from 'react';
import { Table, Button, message as AntMessage } from 'antd';
import { cloneDeep, remove, forEach } from 'lodash';
import { Subsite } from '../../containers/cgb-config-select-subsite';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

interface SubsiteSelectedDisplayProps {
    data: Array<Subsite>;
    onRemove: (data: Array<Subsite>) => void;
    disabled: boolean;
}
interface SubsiteSelectedDisplayState {
    displayAll: boolean;
}

/**
 * 分站展示和内容编辑
 */
class SubsiteSelectedDisplay extends Component<
    SubsiteSelectedDisplayProps,
    SubsiteSelectedDisplayState
> {
    state: SubsiteSelectedDisplayState = {
        displayAll: false,
    };
    selectedRows: Subsite[] = [];

    getTableConfig = () => {
        const { disabled } = this.props;
        let config: any = {
            rowKey: 'id',
            columns: [
                {
                    title: language.getText('subsiteName'),
                    dataIndex: 'name',
                    key: 'name',
                    ellipsis: true,
                },
                {
                    title: language.getText('subsiteAddress'),
                    dataIndex: 'address',
                    key: 'address',
                },
                {
                    title: language.getText('businessStatus'),
                    dataIndex: 'status',
                    key: 'status',
                    render: (status: number) => (
                        <span
                            title={
                                status === 1
                                    ? language.getText('subsiteStatusEnable')
                                    : language.getText('notBusiness')
                            }
                            className="goods-field-name"
                        >
                            {status === 1
                                ? language.getText('subsiteStatusEnable')
                                : language.getText('notBusiness')}
                        </span>
                    ),
                },
                {
                    title: language.getText('common.tableAction'),
                    className: 'action-column',
                    key: 'operation',
                    width: 100,
                    render: (i: any, row: any) => {
                        return !disabled ? (
                            <Button type={'link'} onClick={this.onRemove(row)}>
                                {language.getText('common.delete')}
                            </Button>
                        ) : null;
                    },
                },
            ],
            footer: () => this.renderFooter(),
            style: {
                marginTop: 20,
            },
        };
        return config;
    };

    renderFooter = () => {
        const { disabled, data } = this.props;
        const { displayAll } = this.state;
        return (
            <div className="product-selected-footer">
                {data && data.length > 5 && (
                    <Button
                        className="table-footer-expand"
                        type="link"
                        onClick={this.handleDisplayChange}
                    >
                        {displayAll
                            ? language.getText('hideSubsite')
                            : language.getText('viewSubsite')}
                    </Button>
                )}
                {!disabled && (
                    <div className="setting-item">
                        <Button
                            className="setting-item-ok"
                            type="primary"
                            onClick={this.onRemoveAll}
                        >
                            {language.getText('allClear')}
                        </Button>
                    </div>
                )}
            </div>
        );
    };

    onRemoveAll = () => {
        const { data, onRemove } = this.props;

        const results = cloneDeep(data);
        let hasLeader = false;

        forEach(results, (subsite: Subsite) => {
            if (subsite.select) {
                if (subsite.hasLeaders) {
                    hasLeader = true;
                } else {
                    subsite.delete = true;
                }
            }
        });
        if (hasLeader) {
            AntMessage.error(language.getText('subsiteHasLeader'));
            return;
        }

        onRemove([]);
    };

    onRemove = (row: Subsite) => {
        const { data, onRemove } = this.props;

        return () => {
            if (row.hasLeaders) {
                AntMessage.error(language.getText('subsiteHasLeader'));
            } else {
                const result = cloneDeep(data);
                remove(result, (subsite) => row.id === subsite.id);
                onRemove(result);
            }
        };
    };

    handleDisplayChange = () => {
        const { displayAll } = this.state;
        this.setState({
            displayAll: !displayAll,
        });
    };

    render() {
        const { data } = this.props;
        if (!data || data.length === 0) return '';
        const { displayAll } = this.state;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: displayAll ? data : data && data.slice(0, 5),
        };

        return <Table {...tableProps} pagination={false}></Table>;
    }
}

export { SubsiteSelectedDisplay };
