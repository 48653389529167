import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { SalesElectronicCouponAfterSales } from './sales-electronic-coupon-after-sales';

export class SalesElectronicCouponAfterSalesMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <SalesElectronicCouponAfterSales {...controlInfo}/>
    }
}
