import React, { PureComponent, createElement } from 'react';
import { Modal, Button, Upload, Icon, message as AntMessage, Modal as AntModal } from 'antd';
import { ComponentsManager, services, actions, builder } from '@comall-backend-builder/core';
import { connect } from 'react-redux';
import { mapValues } from 'lodash';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const { Dragger } = Upload;

function mapStateToProps(_state: any, props: any) {
    const { entity } = props;
    return { fields: entity.fields };
}
export const ModalSetCouponRulesExchangeCode = connect(
    mapStateToProps,
    null
)((props: any) => {
    return createElement(ComponentsManager.get('CreateForm'), {
        ...props,
        componentId: `ModalSetCouponRulesExchangeCode-${props.row.id}`,
        style: {
            width: '400px',
        },
        fields: [
            { property: 'exchangeType', extra: '随机生成数字+英文12到20位兑换码' },
            { property: 'quantity' },
        ],
        onSubmit: async (event: any, fields: any) => {
            const { entity, params: entityParams, row, type, onCloseModal } = props;

            const entityFields = mapValues(fields, (field, _name) => {
                return field.value;
            });

            const params = { coupon_rule_id: row.id, quantity: entityFields.quantity };
            const apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;

            try {
                await services.api.post(params, {
                    apiRoot,
                    apiPath: `/admin/${type}_coupons/created_by_exchange_code`,
                });
            } catch (error) {
                services.errorHandle(error);
                throw error;
            }
            let contentBefore = React.createElement('span', null, '兑换码生成中，稍后可前往');
            let contentAfter = React.createElement('span', null, '中查看已生成的兑换码');
            let link = React.createElement(
                'a',
                {
                    onClick: () => {
                        services.behaviorHandle({
                            route: '/report-download-center',
                        });
                        AntModal.destroyAll();
                    },
                },
                services.language.getText('dataExportAndImport')
            );
            let content = React.createElement('div', {}, [contentBefore, link, contentAfter]);
            AntModal.info({
                content: content,
            });
            onCloseModal();
            entity && entity.search(entityParams);
        },
    });
});

export class CouponRulesExchangeCodeButton extends PureComponent<any, any> {
    state = {
        visible: false,
        confirmLoading: false,
        statusMsg: services.language.getText('uploadFileClickButton'),
        fileList: [],
    };

    closeModal = () => {
        this.setState({ visible: false });

        const { entity, row } = this.props;
        const isOnlinePrepare = row.source === 'ONLINE' && row.mode === 'PREPARE_COUPON_CODE'; //他方平台我方券
        const isOfflinePrepare = row.source === 'OFFLINE' && row.mode === 'PREPARE_COUPON_CODE'; //我方平台他方券
        if (isOnlinePrepare) {
            builder.getStore().dispatch(
                actions.formInitAction(`ModalSetCouponRulesExchangeCode-${row.id}`, {
                    type: 'CreateForm',
                    entityId: entity.id,
                    fields: { exchangeType: 'RANDOM' },
                })
            );
        }

        if (isOfflinePrepare) {
            this.setState({
                statusMsg: services.language.getText('uploadFileClickButton'),
                fileList: [],
            });
        }
    };
    handleSubmit = () => {
        this.listenRef.handleSubmit();
    };
    renderButton = () => {
        let { disable, row } = this.props;
        const isOnlinePrepare = row.source === 'ONLINE' && row.mode === 'PREPARE_COUPON_CODE'; //他方平台我方券
        const isOfflinePrepare = row.source === 'OFFLINE' && row.mode === 'PREPARE_COUPON_CODE'; //我方平台他方券
        let text = '';
        if (isOnlinePrepare) text = language.getText('redeCode');
        if (isOfflinePrepare) text = services.language.getText('sfq');
        const buttonConfig = {
            onClick: () => {
                this.setState({ visible: true });
            },
            text,
            type: 'link',
            disabled: disable(row),
        };
        return createElement(ComponentsManager.get('Button'), buttonConfig);
    };
    onImport = () => {
        this.setState({
            statusMsg: services.language.getText('importBeforeNotPage'),
        });
        const {
            row: { id },
            entity,
            params: entityParams,
        } = this.props;
        const { fileList } = this.state;
        const formData = new FormData();
        formData.append('files', fileList[0]);
        formData.append('meta_data', JSON.stringify({ couponRuleId: id }));
        services.api
            .upload(
                { files: formData },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
                    apiPath: '/admin/files/locations/35/upload',
                    fileName: 'files',
                    progressCallBack: () => {},
                    useCustomData: true,
                }
            )
            .then((res: any) => {
                let contentBefore = React.createElement(
                    'span',
                    null,
                    services.language.getText('fileUploading')
                );
                let contentAfter = React.createElement(
                    'span',
                    null,
                    services.language.getText('view')
                );
                let link = React.createElement(
                    'a',
                    {
                        onClick: () => {
                            services.behaviorHandle({
                                route: '/report-download-center',
                            });
                            AntModal.destroyAll();
                        },
                    },
                    services.language.getText('dataExportAndImport')
                );
                let content = React.createElement('div', {}, [contentBefore, link, contentAfter]);
                AntModal.info({
                    content: content,
                });
                entity.search(entityParams);
                this.closeModal();
            })
            .catch((err: any) => {
                AntMessage.error(err.response.body.err_msg);
                this.setState({
                    statusMsg: services.language.getText('importFail'),
                });
                throw err;
            });
    };
    handleOk = () => {
        const { fileList } = this.state;
        if (fileList && fileList.length > 0) {
            this.onImport();
        } else {
            AntMessage.error(services.language.getText('pleaseUploadFile'));
            return;
        }
    };
    listenRef!: {
        handleSubmit: () => Promise<any>;
    };
    render() {
        const { confirmLoading, visible, fileList, statusMsg } = this.state;
        const { row } = this.props;
        const importExampleImg = './images/editor/exchange-import-example.jpg';
        const uploadProps = {
            accept: '.xlsx,.xls,.csv',
            multiple: false,
            onRemove: (file: any) => {
                this.setState({ fileList: [] });
            },
            beforeUpload: (file: any) => {
                this.setState({
                    fileList: [file],
                });
                return false;
            },
            fileList,
        };
        const isOnlinePrepare = row.source === 'ONLINE' && row.mode === 'PREPARE_COUPON_CODE'; //他方平台我方券
        const isOfflinePrepare = row.source === 'OFFLINE' && row.mode === 'PREPARE_COUPON_CODE'; //我方平台他方券
        return isOnlinePrepare ? (
            <span style={{ marginRight: 10 }}>
                {this.renderButton()}
                <Modal
                    confirmLoading={confirmLoading}
                    title={services.language.getText('xzdhm')}
                    mask={true}
                    visible={visible}
                    maskClosable={true}
                    okText={services.language.getText('scdhm')}
                    cancelText={services.language.getText('common.cancel')}
                    onOk={this.handleSubmit}
                    onCancel={this.closeModal}
                >
                    <ModalSetCouponRulesExchangeCode
                        {...this.props}
                        onCloseModal={this.closeModal}
                        wrappedComponentRef={(ref: any) => (this.listenRef = ref)}
                    />
                </Modal>
            </span>
        ) : isOfflinePrepare ? (
            <span style={{ marginRight: 10 }}>
                {this.renderButton()}

                <Modal
                    onCancel={this.closeModal}
                    visible={visible}
                    title={services.language.getText('batchImport')}
                    footer={[
                        <span className="coupon-rules-exchange-coupon-code-status-msg">
                            {statusMsg}
                        </span>,
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            {services.language.getText('import')}
                        </Button>,
                        <Button key="back" onClick={this.closeModal}>
                            {services.language.getText('back')}
                        </Button>,
                    ]}
                >
                    <Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">{services.language.getText('selectFile')}</p>
                        <p className="ant-upload-hint">
                            {services.language.getText('zckzm')}：.xlsx、.xls、.csv
                        </p>
                    </Dragger>
                    <div className="coupon-rules-exchange-coupon-code-tips">
                        <p className="coupon-rules-exchange-coupon-code-tips-text">
                            {services.language.getText('drgz')}:
                        </p>
                        <p className="coupon-rules-exchange-coupon-code-tips-text">
                            {services.language.getText('merchantBigCodeIntro1')}
                        </p>
                        <p className="coupon-rules-exchange-coupon-code-tips-text-red">
                            {services.language.getText('btmcbxtxw')}
                        </p>
                        <p className="coupon-rules-exchange-coupon-code-tips-text">
                            {services.language.getText('merchantBigCodeIntro2')}
                        </p>
                        <p className="coupon-rules-exchange-coupon-code-tips-text">
                            {services.language.getText('uploadIntro3')}
                        </p>
                        <p className="coupon-rules-exchange-coupon-code-tips-text">
                            {services.language.getText('qzqtxsfqmqbkzf')}
                        </p>
                        <p className="coupon-rules-exchange-coupon-code-tips-text-template">
                            {services.language.getText('wjysmb')}：
                        </p>
                        <img
                            className="coupon-rules-exchange-coupon-code-img"
                            src={importExampleImg}
                            alt=""
                        />
                    </div>
                </Modal>
            </span>
        ) : null;
    }
}
