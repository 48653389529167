import React, { Component } from 'react';
import { CheckboxGroup } from '@comall-backend-builder/components-basis';
import { api } from '@comall-backend-builder/core/lib/services';
import { get } from 'lodash';

export interface ArrayAlipayOptionIdsCheckoutTipState {
    havaAlipay: boolean;
    rowDisabled: boolean;
}

export class ArrayAlipayOptionIdsCheckoutTip extends Component<
    any,
    ArrayAlipayOptionIdsCheckoutTipState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            havaAlipay: false,
            rowDisabled: false,
        };
        this.onChange = this.onChange.bind(this);
    }
    componentDidMount() {
        this.getAlipayMiniprogramAppId();
    }
    componentWillReceiveProps(nextProps: any) {
        const { row } = nextProps;
        if (row.baseInfo && !row.baseInfo.id) {
            const nextSource = get(nextProps, 'row.useRuleInfo.source', null);
            const thisSource = get(this.props, 'row.useRuleInfo.source', null);
            let nextMode = '';
            let thisMode = '';
            if (nextSource === 'ONLINE') {
                nextMode = get(nextProps, 'row.useRuleInfo.onlineMode', null);
                thisMode = get(this.props, 'row.useRuleInfo.onlineMode', null);
            }
            if (nextSource === 'OFFLINE') {
                nextMode = get(nextProps, 'row.useRuleInfo.offlineMode', null);
                thisMode = get(this.props, 'row.useRuleInfo.offlineMode', null);
            }
            const nextUseThreshold = get(nextProps, 'row.ruleInfo.useThreshold', null);
            const thisUseThreshold = get(this.props, 'row.ruleInfo.useThreshold', null);
            if (
                (nextSource !== null && nextSource !== thisSource) ||
                (nextMode !== null && nextMode !== thisMode) ||
                (nextUseThreshold != null && nextUseThreshold !== thisUseThreshold)
            ) {
                this.loadDisabled(nextProps);
            }
        }
    }

    getAlipayMiniprogramAppId = () => {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: `/admin/front_config`,
            }
        ).then((response: any) => {
            if (response && response.alipayMiniprogramAppId) {
                this.setState({
                    havaAlipay: true,
                });
            }
            this.loadDisabled(this.props);
        });
    };

    loadDisabled = (props: any) => {
        const { row, channelType } = props;
        const { havaAlipay } = this.state;
        if (channelType === 'ALIPAY' && !havaAlipay) {
            this.setDisabledAndClearValue();
            return;
        }
        //添加时切换添加控制，编辑时暂不添加
        if (row.baseInfo && !row.baseInfo.id) {
            if (row && row.useRuleInfo && row.useRuleInfo.source) {
                let mode = '';
                if (row.useRuleInfo.source === 'ONLINE') {
                    mode = get(props, 'row.useRuleInfo.onlineMode', null);
                }
                if (row.useRuleInfo.source === 'OFFLINE') {
                    mode = get(props, 'row.useRuleInfo.offlineMode', null);
                }
                const isOnlineGenerate =
                    row.useRuleInfo.source === 'ONLINE' && mode === 'GENERATE_COUPON_CODE'
                        ? true
                        : false; //平台内
                if (!isOnlineGenerate) {
                    this.setDisabledAndClearValue();
                    return;
                }
            }
            if (
                row &&
                row.ruleInfo &&
                row.ruleInfo.useThreshold &&
                row.ruleInfo.useThreshold === 'QUANTITY'
            ) {
                //件数满不可更改
                this.setDisabledAndClearValue();
                return;
            }
        }
        this.setState({
            rowDisabled: false,
        });
    };

    setDisabledAndClearValue = () => {
        const { name, onChange } = this.props;
        this.setState(
            {
                rowDisabled: true,
            },
            () => {
                onChange([], name);
            }
        );
    };

    onChange(value: any) {
        const { name, onChange } = this.props;

        if (onChange) {
            onChange(value, name);
        }
    }

    render() {
        const { value, name, options, tip, disabled } = this.props;
        const { rowDisabled } = this.state;
        let checkBoxDisabled = false;
        if (rowDisabled) {
            checkBoxDisabled = true;
        }
        if (disabled) {
            checkBoxDisabled = true;
        }
        return (
            <div>
                <CheckboxGroup
                    name={name}
                    onChange={this.onChange}
                    options={options}
                    value={value}
                    {...this.props}
                    disabled={checkBoxDisabled}
                />
                {tip && (
                    <div {...this.props} style={{ fontSize: 12, color: '#ccc', lineHeight: 1.5 }}>
                        {tip}
                    </div>
                )}
            </div>
        );
    }
}
