import React, { PureComponent } from 'react';
import { Form, Radio, Switch } from 'antd';
import { tools } from '@comall-backend-builder/design-components-basis';
import ButtonConfigForm from './button-config';
import { services } from '@comall-backend-builder/core';
const FormItem = Form.Item;
const UploadPicture = tools.UploadPicture;
/**
 * 吉麦隆首页会员卡组件-配置表单
 */

export interface ButtonConfig {
    bgColor: string;
    title: string;
    color: string;
    linkParams: any;
    linkType: string;
}
class MemberCard extends PureComponent<any> {
    productMoreChange = (value: ButtonConfig) => {
        const { getFieldsValue } = this.props.form;
        const { onChange } = this.props;
        onChange({ ...getFieldsValue(), button: value });
    };
    render() {
        const { form, sourceRoot, uploadAction, linkTypes, selector } = this.props;
        const { getFieldDecorator, getFieldValue } = form;
        const formItemLayout = tools.FormItemLayout;
        let showCreditLabel = '会员积分';
        if (ENV.TENANT === 'wfj') {
            showCreditLabel = '会员等级';
        }
        const { type } = this.props.value;
        return (
            <Form className="editor-member-card">
                {(ENV.TENANT === 'wfj' || ENV.TENANT === 'localhost') && (
                    <FormItem {...formItemLayout} label={'展示样式'}>
                        {getFieldDecorator('type', { initialValue: 'type1' })(
                            <Radio.Group>
                                <Radio value={'type1'}>样式一</Radio>
                                <Radio value={'type2'}>样式二</Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                )}
                {type === 'type2' ? (
                    <FormItem {...formItemLayout} label={services.language.getText('bgPic')}>
                        {getFieldDecorator('bgImage')(
                            <UploadPicture
                                className="image-uploader"
                                sourceRoot={sourceRoot}
                                uploadAction={uploadAction}
                            />
                        )}
                        <p className="upload-picture-tips">图片建议宽度为710px，高度为120px</p>
                    </FormItem>
                ) : (
                    <>
                        <FormItem {...formItemLayout} label={services.language.getText('bgPic')}>
                            {getFieldDecorator('bgImage')(
                                <UploadPicture
                                    className="image-uploader"
                                    sourceRoot={sourceRoot}
                                    uploadAction={uploadAction}
                                />
                            )}
                            <p className="upload-picture-tips">图片建议宽度为710px，高度为120px</p>
                        </FormItem>
                        <FormItem {...formItemLayout} label={'会员二维码'}>
                            {getFieldDecorator('showQrCode', { initialValue: 'show' })(
                                <Radio.Group>
                                    <Radio value={'show'}>
                                        {services.language.getText('display')}
                                    </Radio>
                                    <Radio value={'hide'}>
                                        {services.language.getText('hidden')}
                                    </Radio>
                                </Radio.Group>
                            )}
                        </FormItem>
                        <FormItem {...formItemLayout} label={showCreditLabel}>
                            {getFieldDecorator('showCredit', { initialValue: 'show' })(
                                <Radio.Group>
                                    <Radio value={'show'}>
                                        {services.language.getText('display')}
                                    </Radio>
                                    <Radio value={'hide'}>
                                        {services.language.getText('hidden')}
                                    </Radio>
                                </Radio.Group>
                            )}
                        </FormItem>
                        <FormItem {...formItemLayout} label={'入口按钮'}>
                            {getFieldDecorator('showButton', { valuePropName: 'checked' })(
                                <Switch />
                            )}
                        </FormItem>
                        {getFieldValue('showButton') && (
                            <FormItem {...formItemLayout} label={'按钮配制'}>
                                <ButtonConfigForm
                                    sourceRoot={sourceRoot}
                                    value={this.props.value.button}
                                    onChange={this.productMoreChange}
                                    selector={selector}
                                    uploadAction={uploadAction}
                                    linkTypes={linkTypes}
                                />
                            </FormItem>
                        )}
                    </>
                )}
            </Form>
        );
    }
}

export const HomeMemberCard = tools.ConfigFormDecorator(MemberCard);
