import React, { Component, createElement } from 'react';
import {
    Menu as AntMenu,
    Dropdown as AntDropdown,
    Button as AntButton,
    Icon as AntIcon,
} from 'antd';
import PropTypes from 'prop-types';
import { map, forEach, get } from 'lodash';
import { ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { privilege, interpolate } from '@comall-backend-builder/core/lib/services';

/**
 * Dropdown组件。
 *
 *  @param {Object} style -  组件样式
 *  @param {Object} dropdownConfig -  同antd的Dropdown组件配置
 *  @param {Object} content -  触发组件配置
 *  @param {Array} menuItems -  menu 操作项
 */

//是否显示dropdown按钮
const shouldShowDropdown = (dropdownConfig: any) => {
    let showDropdown = false;
    forEach(dropdownConfig, (item) => {
        if (!item.privilege) {
            showDropdown = true;
            return false;
        } else {
            const { path, level } = item.privilege;
            if (privilege.check(path, level)) {
                showDropdown = true;
                return false;
            }
        }
    });
    return showDropdown;
};

export class Dropdown extends Component<any, any> {
    static propTypes = {
        style: PropTypes.object,
        dropdownConfig: PropTypes.object,
        content: PropTypes.object,
        menuItems: PropTypes.array,
    };
    renderMenu = () => {
        const menu = <AntMenu>{this.renderMenuItem()}</AntMenu>;
        return menu;
    };
    renderMenuItem = () => {
        const { menuItems, entity, row } = this.props;
        return map(menuItems, (item, index) => {
            if (item.privilege) {
                const { path, level } = item.privilege;
                if (!privilege.check(path, level)) {
                    return undefined;
                }
            }
            if (item.statusKey) {
                const keyValue = get(row, item.statusKey);
                if (!keyValue) {
                    return undefined;
                }
            }
            let menuItemComponentProps = {
                ...item.configs,
                entity,
            };
            if (item.configs.route) {
                menuItemComponentProps.route = interpolate(item.configs.route, { row });
            }
            if (item.configs.onClick) {
                menuItemComponentProps.onClick = () => {
                    item.configs.onClick && item.configs.onClick(row);
                    return true;
                };
            }

            return (
                <AntMenu.Item key={index}>
                    {createElement(ComponentsManager.get(item.component), menuItemComponentProps)}
                </AntMenu.Item>
            );
        });
    };
    renderContent = () => {
        const {
            content: { contentType, text, showIcon },
        } = this.props;
        let element;
        switch (contentType) {
            case 'button':
                element = (
                    <AntButton>
                        {text}
                        {showIcon && <AntIcon type="down" />}
                    </AntButton>
                );
                break;
            case 'link':
                element = (
                    <span className="ant-dropdown-link ant-btn-link">
                        {text} <AntIcon type="down" />
                    </span>
                );
                break;
            default:
                element = null;
        }
        return element;
    };
    render() {
        const { dropdownConfig, style, menuItems } = this.props;
        const props = Object.assign({}, dropdownConfig, { overlay: this.renderMenu() });
        if (menuItems && !!menuItems.length && shouldShowDropdown(menuItems)) {
            return (
                <div style={style}>
                    <AntDropdown {...props}>{this.renderContent()}</AntDropdown>
                </div>
            );
        } else {
            return null;
        }
    }
}
