import React, { Component, createElement } from 'react';
import { find, get, isFunction } from 'lodash';
import {
    api,
    errorHandle,
    interpolate,
    globalConfig,
    privilege,
    language,
} from '@comall-backend-builder/core/lib/services';
import { ComponentsManager } from '@comall-backend-builder/core';
import { Modal as AntModal, message as AntMessage } from 'antd';
const RequestMethods = ['get', 'post', 'put', 'patch', 'delete'] as const;
type RequestMethod = typeof RequestMethods[number];
/**
 * 提供表格 actionColumns中状态改变按钮
 *  使用参考如下：
 component: 'ChangeStatusButton',
 statusKey: 'status',
 renderComponent: 'Button'
 privilege: {
    path:'',
    level:'',
 },
 handles: [
 {
    confirm:{
        title: '连锁品牌'
    },
    apiPath:'',
    params:{ status: 'ENABLED' },
    value: 'DISABLED',
    config:{ content: '启用' }
 },
 {
    confirm:{
        title: '连锁品牌'
    },
    apiPath:'',
    params:{ status: 'ENABLED' },
    value: 'DISABLED',
    config:{ content: '启用' }
 },
 ]
 */

export class ChangeStatusButton extends Component<any, any> {
    component: any = null;
    componentConfig: any = null;
    state = { disabled: false };
    render() {
        const componentPrivilege = this.props.privilege;
        if (componentPrivilege) {
            const { path, level } = componentPrivilege;
            if (!privilege.check(path, level)) {
                return null;
            }
        }
        let {
            row,
            handles,
            statusKey,
            entity,
            params,
            apiPath,
            apiRoot = globalConfig.get('api.root'),
            renderComponent,
            setLoaddingProps,
        } = this.props;
        let method: RequestMethod = 'put';
        statusKey = statusKey || 'status';

        let actionParams = { id: row.id };

        const putAction = () => {
            const { disabled } = this.state;
            if (disabled) return;
            this.setState({ disabled: true });
            if (setLoaddingProps) {
                const loadingText = setLoaddingProps(row);
                if (loadingText) {
                    const contentBefore = React.createElement('span', null, loadingText);
                    const loaddingDiv = React.createElement('div', {}, [contentBefore, null, null]);
                    AntModal.warning({
                        content: loaddingDiv,
                        okButtonProps: {
                            style: {
                                display: 'none',
                            },
                        },
                    });
                }
            }
            api[method](
                { ...actionParams, ...params },
                {
                    apiRoot,
                    apiPath: apiPath,
                }
            )
                .then(function(result) {
                    // 如果按钮有自定义提交成功的函数，执行自定义函数。否则执行默认提示逻辑
                    if (
                        componentConfig.onConfirmSuccess &&
                        isFunction(componentConfig.onConfirmSuccess)
                    ) {
                        // 在函数调用入参中，传入接口调用结果、行数据、实体
                        componentConfig.onConfirmSuccess(result, row, entity);
                    } else {
                        const content = interpolate(componentConfig.content, context);
                        AntMessage.success(`${content}` + language.getText('success'));
                    }
                    if (
                        componentConfig.onConfirmSearch &&
                        isFunction(componentConfig.onConfirmSearch)
                    ) {
                        // 在函数调用入参中，传入接口调用结果、行数据、实体
                        componentConfig.onConfirmSearch(result, row, entity);
                    } else {
                        entity.search(params);
                    }
                })
                .catch(errorHandle)
                .finally(() => {
                    AntModal.destroyAll();
                    this.setState({ disabled: false });
                });
        };

        const activeValue = get(row, statusKey);
        if (activeValue === undefined) {
            throw new Error(` ${statusKey} is not found in row.`);
        }
        const findHandle = find(handles, { value: activeValue });
        if (!findHandle) {
            this.component = null;
            return;
        }
        const componentConfig = findHandle.config;

        Object.assign(actionParams, findHandle.params);

        if (findHandle.apiRoot) {
            apiRoot = findHandle.apiRoot;
        }
        if (findHandle.apiPath) {
            apiPath = findHandle.apiPath;
        }
        if (findHandle.loadType) {
            method = findHandle.loadType;
        }
        if (findHandle.setLoaddingProps) {
            setLoaddingProps = findHandle.setLoaddingProps;
        }

        let context = {
            row: Object.assign({}, this.props.entity.params, row, componentConfig),
        };
        let confirmTitle = '';
        let okText = language.getText('common.confirm');
        let noText = language.getText('common.cancel');
        let content = '';
        let className = '';
        if (findHandle.confirm && findHandle.confirm.text) {
            confirmTitle = findHandle.confirm.text;
        }
        if (findHandle.confirm && findHandle.confirm.okText) {
            okText = findHandle.confirm.okText;
        }
        if (findHandle.confirm && findHandle.confirm.noText) {
            noText = findHandle.confirm.noText;
        }
        if (findHandle.confirm && findHandle.confirm.content) {
            content = findHandle.confirm.content;
        }
        if (findHandle.confirm && findHandle.confirm.className) {
            className = findHandle.confirm.className;
        }
        let title = interpolate(confirmTitle, context);
        apiRoot = interpolate(apiRoot, context);
        apiPath = interpolate(apiPath, context);
        this.componentConfig = {
            onClick: function() {
                if (confirmTitle) {
                    AntModal.confirm({
                        title,
                        okText: okText,
                        cancelText: noText,
                        className: className,
                        content: content,
                        onOk() {
                            putAction();
                        },
                    });
                } else {
                    putAction();
                }
            },
            ...this.props,
            ...componentConfig,
        };
        this.component = createElement(
            ComponentsManager.get(renderComponent),
            this.componentConfig
        );
        return this.component;
    }
}
