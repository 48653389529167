import React, { PureComponent, ReactNode, CSSProperties } from 'react';
import { Icon, Tooltip } from 'antd';

interface TextTipsProps {
    /**
     * 气泡内容
     */
    tips: string | ReactNode;
    tipsStyle?: CSSProperties;
    /**
     * 图标类型
     */
    type?: string;
    text: string;
    style?: CSSProperties;
}

/**
 * 文字带问号图标提示
 */
export class TextTips extends PureComponent<TextTipsProps, {}> {
    render() {
        const { tips, type = 'question-circle', text, style, tipsStyle } = this.props;
        return (
            <span style={style}>
                {text}
                <span style={{ fontSize: 12, marginLeft: 4 }}>
                    <Tooltip title={tips} overlayStyle={tipsStyle}>
                        <Icon type={type} />
                    </Tooltip>
                </span>
            </span>
        );
    }
}
