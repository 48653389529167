import React, { Component } from 'react';
import {
    AutoReplyVo,
    MenuContent,
    MenuContentType,
    SubMenuVo,
} from '../../components/wechant-content';
import { Input, Radio, Select } from 'antd';
import {
    LinkTypeEffectPathConfig,
    getNewLinkTypeEffectPathConfigList,
} from '../../../../components/link-type-effect-path/config';
import { newClientCustomSelectorConfig } from '../../../../applications/design/design';
import { LinkTypePlus } from '../../../../applications/design/components';
import { linkNameGen, linkPathGen, transformParams } from '../../../miniprogram-v2';
import { WechatOfficialAutoReplySettingPageData } from '../../components/wechat-official-auto-reply-setting-page-data';
import './index.less';
import { Subsite } from '../../../cgb-config-select-subsite';
import { services } from '@comall-backend-builder/core';

enum MiniType {
    /**
     * 我方
     */
    MINEMINIPROGRAM = 'MINEMINIPROGRAM',
    /**
     * 它方
     */
    MINIPROGRAM = 'MINIPROGRAM',
}

interface Props {
    currentSubsite?: any;
    appId: string;
    value: Array<SubMenuVo>;
    currentMenuContentIndex: number;
    currentMenuContentChidrenIndex: number;
    onChange: (value: Array<SubMenuVo>) => void;
    linkTypeSubsite: Subsite[];
}

interface State {
    linkTypes: LinkTypeEffectPathConfig[];
}

const defalutTarget = {
    linkType: '',
    linkParams: '',
    linkName: '',
    linkPath: '',
};
/**
 * 回复内容模块
 */
export class WechatCustomMenusSettingRightPage extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            linkTypes: getNewLinkTypeEffectPathConfigList(),
        };
    }
    onTitleChange = (e: any) => {
        const {
            currentMenuContentIndex,
            currentMenuContentChidrenIndex,
            value,
            onChange,
        } = this.props;
        const title = e.target.value;
        if (currentMenuContentIndex > -1 && currentMenuContentChidrenIndex > -1) {
            //修改二级名称
            value[currentMenuContentIndex].subMenus[currentMenuContentChidrenIndex].name = title;
        } else {
            //修改一级名称
            value[currentMenuContentIndex].name = title;
        }
        onChange(value);
    };

    onUrlChange = (e: any) => {
        const {
            currentMenuContentIndex,
            currentMenuContentChidrenIndex,
            value,
            onChange,
        } = this.props;
        const url = e.target.value;
        if (currentMenuContentIndex > -1 && currentMenuContentChidrenIndex > -1) {
            //修改二级名称
            value[currentMenuContentIndex].subMenus[
                currentMenuContentChidrenIndex
            ].menuContent.url = url;
        } else {
            //修改一级名称
            value[currentMenuContentIndex].menuContent.url = url;
        }
        onChange(value);
    };

    onRadioChange = (event: any) => {
        const {
            currentMenuContentIndex,
            currentMenuContentChidrenIndex,
            value,
            onChange,
        } = this.props;
        const type: MenuContentType = event.target.value;
        let defalutReplyContents: MenuContent = {
            type: MenuContentType.MINIPROGRAM,
            appid: '{miniprogram-appid}',
            url: '',
            pagepath: '',
            target: defalutTarget,
        };
        if (type === MenuContentType.VIEW) {
            defalutReplyContents = {
                type: MenuContentType.VIEW,
                url: '',
            };
        }
        if (type === MenuContentType.CLICK) {
            defalutReplyContents = {
                type: MenuContentType.CLICK,
                replyContents: [],
            };
        }
        if (currentMenuContentIndex > -1 && currentMenuContentChidrenIndex > -1) {
            //修改二级类型
            value[currentMenuContentIndex].subMenus[
                currentMenuContentChidrenIndex
            ].menuContent = defalutReplyContents;
        } else {
            //修改一级类型
            value[currentMenuContentIndex].menuContent = defalutReplyContents;
        }
        onChange(value);
    };

    findLinkTypeById = (
        id: string,
        linkTypes: LinkTypeEffectPathConfig[]
    ): LinkTypeEffectPathConfig | undefined => {
        let config;
        linkTypes.forEach((linkType) => {
            if (!linkType.children && linkType.id === id) {
                config = linkType;
            }
            if (linkType.children && linkType.children.length > 0) {
                linkType.children.forEach((it) => {
                    if (it.id === id) {
                        config = it;
                    }
                });
            }
        });
        return config;
    };

    changeTargetValue = (val: any) => {
        const { linkTypes } = this.state;
        const config = this.findLinkTypeById(val.linkType, linkTypes);
        if (config) {
            const valObj = { ...val };
            const paramsObj = transformParams(val.linkParams, config.paramsHandler);
            Object.assign(valObj, {
                linkName: linkNameGen(config.name, valObj.linkParams),
                linkPath: linkPathGen(config.basePath, config.params, paramsObj),
            });
            const {
                currentMenuContentIndex,
                currentMenuContentChidrenIndex,
                value,
                onChange,
            } = this.props;
            if (currentMenuContentIndex > -1 && currentMenuContentChidrenIndex > -1) {
                const sid =
                    value[currentMenuContentIndex].subMenus[currentMenuContentChidrenIndex]
                        .menuContent.subsiteId;
                value[currentMenuContentIndex].subMenus[
                    currentMenuContentChidrenIndex
                ].menuContent.target = valObj;
                value[currentMenuContentIndex].subMenus[
                    currentMenuContentChidrenIndex
                ].menuContent.url = `entrace/index?sid=${sid}&url=${encodeURIComponent(
                    valObj.linkPath
                )}`;
                value[currentMenuContentIndex].subMenus[
                    currentMenuContentChidrenIndex
                ].menuContent.pagepath = `entrace/index?sid=${sid}&url=${encodeURIComponent(
                    valObj.linkPath
                )}`;
            } else {
                const sid = value[currentMenuContentIndex].menuContent.subsiteId;
                value[currentMenuContentIndex].menuContent.target = valObj;
                value[
                    currentMenuContentIndex
                ].menuContent.url = `entrace/index?sid=${sid}&url=${encodeURIComponent(
                    valObj.linkPath
                )}`;
                value[
                    currentMenuContentIndex
                ].menuContent.pagepath = `entrace/index?sid=${sid}&url=${encodeURIComponent(
                    valObj.linkPath
                )}`;
            }
            onChange(value);
        }
    };

    onMiniInfoChange = (type: string, inputValue: any) => {
        const {
            currentMenuContentIndex,
            currentMenuContentChidrenIndex,
            value,
            onChange,
        } = this.props;
        if (currentMenuContentIndex > -1 && currentMenuContentChidrenIndex > -1) {
            if (type === 'target') {
                value[currentMenuContentIndex].subMenus[
                    currentMenuContentChidrenIndex
                ].menuContent.target =
                    inputValue === MiniType.MINEMINIPROGRAM ? defalutTarget : undefined;
                value[currentMenuContentIndex].subMenus[
                    currentMenuContentChidrenIndex
                ].menuContent.pagepath = '';
                value[currentMenuContentIndex].subMenus[
                    currentMenuContentChidrenIndex
                ].menuContent.url = '';
                value[currentMenuContentIndex].subMenus[
                    currentMenuContentChidrenIndex
                ].menuContent.appid =
                    inputValue === MiniType.MINEMINIPROGRAM ? '{miniprogram-appid}' : '';
            } else if (type === 'appid') {
                value[currentMenuContentIndex].subMenus[
                    currentMenuContentChidrenIndex
                ].menuContent.appid = inputValue;
            } else if (type === 'pagepath') {
                value[currentMenuContentIndex].subMenus[
                    currentMenuContentChidrenIndex
                ].menuContent.pagepath = inputValue;
            } else if (type === 'subsiteId') {
                value[currentMenuContentIndex].subMenus[
                    currentMenuContentChidrenIndex
                ].menuContent.subsiteId = inputValue;
            }
        } else {
            if (type === 'target') {
                value[currentMenuContentIndex].menuContent.target =
                    inputValue === MiniType.MINEMINIPROGRAM ? defalutTarget : undefined;
                value[currentMenuContentIndex].menuContent.pagepath = '';
                value[currentMenuContentIndex].menuContent.url = '';
                value[currentMenuContentIndex].menuContent.appid =
                    inputValue === MiniType.MINEMINIPROGRAM ? '{miniprogram-appid}' : '';
            } else if (type === 'appid') {
                value[currentMenuContentIndex].menuContent.appid = inputValue;
            } else if (type === 'pagepath') {
                value[currentMenuContentIndex].menuContent.pagepath = inputValue;
            } else if (type === 'subsiteId') {
                value[currentMenuContentIndex].menuContent.subsiteId = inputValue;
            }
        }

        onChange(value);
    };

    renderLink = (currentInfo: SubMenuVo) => {
        const { linkTypeSubsite = [], currentSubsite } = this.props;
        const { linkTypes: allLinktypes } = this.state;
        const target = currentInfo.menuContent.target;
        let subsiteId = currentInfo?.menuContent?.subsiteId
            ? currentInfo.menuContent.subsiteId
            : '';
        //单个门店时，默认选择
        if (
            linkTypeSubsite.length <= 1 &&
            !subsiteId &&
            !currentInfo?.menuContent?.target?.linkPath
        ) {
            subsiteId = Number(currentSubsite.id) || '';
            currentInfo.menuContent.subsiteId = Number(currentSubsite.id);
        }
        //切换门店不一致时 删除链接目标
        if (
            String(subsiteId) !== currentInfo?.menuContent?.target?.linkParams?.id &&
            currentInfo?.menuContent?.target?.linkType === 'subsite'
        ) {
            currentInfo.menuContent.target.linkPath = '';
            currentInfo.menuContent.target.linkParams = null;
        }
        const linkTypes = allLinktypes.filter(
            (item: any) => item.id !== 'none' && item.id !== 'miniprogram' && item.id !== 'urlLink'
        );
        const linkTypeSelector = newClientCustomSelectorConfig;
        const radioValue = target ? MiniType.MINEMINIPROGRAM : MiniType.MINIPROGRAM;
        return (
            <div>
                <div className="item">
                    <div className="item-title">
                        <span className="red">*&nbsp;</span>
                        {services.language.getText('ljlx')}：
                    </div>
                    <div className="item-radio">
                        <Radio.Group
                            value={radioValue}
                            onChange={(e) => this.onMiniInfoChange('target', e.target.value)}
                        >
                            <Radio value={MiniType.MINEMINIPROGRAM}>
                                {services.language.getText('xzxcxym')}
                            </Radio>
                            <Radio value={MiniType.MINIPROGRAM}>
                                {services.language.getText('txxcxlj')}
                            </Radio>
                        </Radio.Group>
                        <div className="item-radio-wrap">
                            {radioValue === MiniType.MINEMINIPROGRAM ? (
                                <div>
                                    <div className="right-link">
                                        <div className="link-item-field">
                                            <label className="field-name">
                                                {services.language.getText('mdxz')}：
                                            </label>
                                            <span className="link-type ant-cascader-picker link-type">
                                                <Select
                                                    style={{ minWidth: 150 }}
                                                    value={subsiteId}
                                                    onChange={(e: any) =>
                                                        this.onMiniInfoChange('subsiteId', e)
                                                    }
                                                >
                                                    {linkTypeSubsite.map((item: any) => (
                                                        <Select.Option
                                                            key={item.name}
                                                            value={item.id}
                                                        >
                                                            {item.name}
                                                        </Select.Option>
                                                    ))}
                                                </Select>
                                            </span>
                                        </div>
                                    </div>
                                    {subsiteId ? (
                                        <div className="right-link">
                                            <LinkTypePlus
                                                selector={linkTypeSelector as any}
                                                linkTypes={linkTypes}
                                                linkTypeMode="cascader"
                                                subsiteIds={[String(subsiteId)]}
                                                value={target ? target : defalutTarget}
                                                onChange={(val) => this.changeTargetValue(val)}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            ) : (
                                <div>
                                    <div className="item">
                                        <div className="item-radio-wrap-title item-radio-wrap-title-line">
                                            {services.language.getText('xcx')}APPID:
                                        </div>
                                        <Input
                                            className="item-input"
                                            required
                                            placeholder={`请输入小程序${services.language.getText(
                                                'xcx'
                                            )}APPID`}
                                            value={currentInfo.menuContent.appid}
                                            onChange={(e) =>
                                                this.onMiniInfoChange('appid', e.target.value)
                                            }
                                            width={400}
                                            maxLength={100}
                                        />
                                    </div>
                                    <div className="item">
                                        <div className="item-radio-wrap-title item-radio-wrap-title-line">
                                            {services.language.getText('xcxwz')}:
                                        </div>
                                        <Input
                                            className="item-input"
                                            required
                                            placeholder={services.language.getText('qsrxcxwz')}
                                            value={currentInfo.menuContent.pagepath}
                                            onChange={(e) =>
                                                this.onMiniInfoChange('pagepath', e.target.value)
                                            }
                                            width={400}
                                            maxLength={100}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    onChangeReplyDate = (replyData: Array<AutoReplyVo>) => {
        const {
            currentMenuContentIndex,
            currentMenuContentChidrenIndex,
            value,
            onChange,
        } = this.props;
        if (currentMenuContentIndex > -1 && currentMenuContentChidrenIndex > -1) {
            value[currentMenuContentIndex].subMenus[
                currentMenuContentChidrenIndex
            ].menuContent.replyContents = replyData;
        } else {
            value[currentMenuContentIndex].menuContent.replyContents = replyData;
        }
        onChange(value);
    };

    renderContent = (replyContents?: Array<AutoReplyVo>) => {
        if (!replyContents) {
            return null;
        }
        const { appId } = this.props;
        if (!appId) {
            return null;
        }
        return (
            <WechatOfficialAutoReplySettingPageData
                appId={appId}
                replyData={replyContents}
                onChange={this.onChangeReplyDate}
            />
        );
    };
    render() {
        const { currentMenuContentIndex, currentMenuContentChidrenIndex, value } = this.props;
        if (currentMenuContentIndex < 0) {
            return null;
        }
        let currentInfo: any = value[currentMenuContentIndex];
        if (
            currentMenuContentChidrenIndex === -1 &&
            currentInfo &&
            currentInfo.subMenus &&
            currentInfo.subMenus.length > 0
        ) {
            //编辑主菜单，如果下方有子菜单，那么它只能更改名称
            return (
                <div className="wechat-custom-menus-setting-right-page">
                    <div className="item">
                        <div className="item-title item-title-line">
                            <span className="red">*&nbsp;</span>
                            {services.language.getText('cdmc')}：
                        </div>
                        <Input
                            className="item-input"
                            required
                            placeholder={services.language.getText('qsrwz')}
                            value={currentInfo.name}
                            onChange={this.onTitleChange}
                            maxLength={5}
                        />
                    </div>
                </div>
            );
        } else if (currentMenuContentChidrenIndex > -1) {
            //子菜单
            currentInfo = value[currentMenuContentIndex].subMenus[currentMenuContentChidrenIndex];
        }
        if (!currentInfo) {
            return null;
        }
        return (
            <div className="wechat-custom-menus-setting-right-page">
                <div className="item">
                    <div className="item-title item-title-line">
                        <span className="red">*&nbsp;</span>
                        {services.language.getText('cdmc')}：
                    </div>
                    <Input
                        className="item-input"
                        required
                        placeholder={services.language.getText('qsrwz')}
                        value={currentInfo.name}
                        onChange={this.onTitleChange}
                        maxLength={
                            currentMenuContentIndex > -1 && currentMenuContentChidrenIndex > -1
                                ? 8
                                : 5
                        }
                    />
                </div>
                <div className="item">
                    <div className="item-title">
                        <span className="red">*&nbsp;</span>
                        {services.language.getText('cdgn')}：
                    </div>
                    <div className="item-radio">
                        <Radio.Group
                            value={currentInfo.menuContent.type}
                            onChange={this.onRadioChange}
                        >
                            <Radio value={MenuContentType.MINIPROGRAM}>
                                {services.language.getText('tzxcx')}
                            </Radio>
                            <Radio value={MenuContentType.VIEW}>
                                {services.language.getText('tzwy')}
                            </Radio>
                            <Radio value={MenuContentType.CLICK}>
                                {services.language.getText('hfxx_1')}
                            </Radio>
                        </Radio.Group>
                    </div>
                </div>
                {currentInfo.menuContent.type === MenuContentType.VIEW && (
                    <div className="item">
                        <div className="item-title item-radio-wrap-title-line">
                            <span className="red">*&nbsp;</span>
                            {services.language.getText('network')}：
                        </div>
                        <Input
                            className="item-input"
                            required
                            placeholder="https://"
                            value={currentInfo.menuContent.url}
                            onChange={this.onUrlChange}
                            width={400}
                        />
                    </div>
                )}
                {currentInfo.menuContent.type === MenuContentType.MINIPROGRAM && (
                    <div>{this.renderLink(currentInfo)}</div>
                )}
                {currentInfo.menuContent.type === MenuContentType.CLICK && (
                    <div className="item-reply">
                        {this.renderContent(currentInfo.menuContent.replyContents)}
                    </div>
                )}
            </div>
        );
    }
}
