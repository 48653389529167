import React, { PureComponent, Fragment, createElement } from 'react';
import { connect } from 'react-redux';
import { Button } from '@comall-backend-builder/components-basis';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { isEmpty } from 'lodash';
import { errorHandle } from '../../../applications/cae/services/error-handle';
import { ConsumeType } from '../../../config/promotion/coupon/coupon-consume-history';
import { ForwardDataCenterModal } from '../../../services';

const api = services.api;

class couponConsumeHistoryTable extends PureComponent<any, any> {
    renderTable = () => {
        const { entity, componentId } = this.props;
        let tableProps = {
            component: 'DataTable',
            entity,
            componentId,
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'couponConsumeOrder.channel',
                },
                {
                    property: 'couponConsumeOrder.method',
                    width: 120,
                },
                {
                    property: 'couponRule.name',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '420px',
                        },
                    },
                },
                {
                    property: 'couponData.couponNo',
                },
                {
                    property: 'couponConsumeOrder.mobile',
                },
                {
                    property: 'couponConsumeOrder.crmId',
                    displayConfig: {
                        style: {
                            minWidth: '140px',
                        },
                    },
                },
                {
                    property: 'couponConsumeOrder.subsite.name',
                    width: 160,
                },
                {
                    property: 'couponConsumeOrder.merchant.name',
                },
                {
                    property: 'couponConsumeOrder.userName',
                },
                { property: 'couponConsumeOrder.deviceNumber' },
                {
                    property: 'couponConsumeOrder.createTime',
                    width: 200,
                },
                {
                    property: 'couponConsumeOrder.orderNo',
                    width: 220,
                },
                {
                    property: 'couponConsumeOrder.requestId',
                    width: 220,
                },
            ],
        };
        return createElement(ComponentsManager.get('DataTable'), tableProps);
    };

    onClick = () => {
        const { entity } = this.props;
        let data = entity.filters;
        if (data && !isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        if (data && data.consumeMethod === ConsumeType.ALL) {
            data = {
                ...data,
                consumeMethod: undefined,
            };
        }
        api.get(data, {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
            apiPath: '/admin/consume_order_items/consume_order_items.excel',
            // customHeaders: { 'content-disposition': 'attachment;filename="filename.xls"' },
        })
            .then(() => {
                ForwardDataCenterModal();
            })
            .catch(errorHandle);
    };

    render() {
        return (
            <Fragment>
                <div className="batch-button">
                    <Button
                        style={{ width: 140, marginBottom: 8 }}
                        text={services.language.getText('dchxjl')}
                        type="primary"
                        icon="plus"
                        className="mt24"
                        onClick={this.onClick}
                    />
                </div>
                <div>{this.renderTable()}</div>
            </Fragment>
        );
    }
}
function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
    };
}

export const CouponConsumeHistoryTable = connect(mapStateToProps)(couponConsumeHistoryTable);
