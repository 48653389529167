import React, { PureComponent } from 'react';
import { Table, Button } from 'antd';
import { cloneDeep, remove } from 'lodash';
import { ContentTopicType } from '../../containers/select-content-topic';
import './index.less';
import { PaginationConfig } from 'antd/lib/table/interface';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

interface ContentTopicSelectedDisplayProps {
    data: Array<ContentTopicType>;
    onRemove: (data: Array<ContentTopicType>) => void;
    disabled?: boolean;
    xScrollWidth?: number;
}
interface ContentTopicSelectedDisplayState {
    selectedRows: Array<ContentTopicType>;
}

/**
 * 优惠券展示和内容编辑
 */
class ContentTopicSelectedDisplay extends PureComponent<
    ContentTopicSelectedDisplayProps,
    ContentTopicSelectedDisplayState
> {
    getTableConfig = () => {
        const { disabled, xScrollWidth } = this.props;
        let tableConfig: any = {
            rowKey: 'id',
            columns: [
                {
                    title: language.getText('htmc'),
                    dataIndex: 'name',
                    key: 'name',
                    width: 150,
                },
                {
                    title: language.getText('ssbk'),
                    dataIndex: 'plateNames',
                    key: 'plateNames',
                    width: 180,
                },
                {
                    title: language.getText('enabledStatus'),
                    dataIndex: 'status',
                    key: 'status',
                    render: (i: any, row: any) => {
                        const value =
                            i === 'ENABLE'
                                ? language.getText('enable')
                                : language.getText('disabled');
                        return <div>{value}</div>;
                    },
                    width: 180,
                },
                {
                    title: services.language.getText('common.tableAction'),
                    className: 'action-column',
                    key: 'operation',
                    width: 100,
                    render: (i: any, row: any) => {
                        return !disabled ? (
                            <Button
                                type={'link'}
                                onClick={this.onRemove(row)}
                                style={{ padding: '0' }}
                            >
                                {services.language.getText('common.delete')}
                            </Button>
                        ) : null;
                    },
                },
            ],
            style: {
                marginTop: 20,
            },
        };
        if (xScrollWidth && xScrollWidth > 0) {
            tableConfig.style = {
                width: xScrollWidth,
            };
            tableConfig.scroll = {
                x: true,
            };
        }
        return tableConfig;
    };

    onRemove = (row: ContentTopicType) => {
        const { data, onRemove } = this.props;

        return () => {
            const result = cloneDeep(data);
            remove(result, (coupon) => row.id === coupon.id);
            onRemove(result);
        };
    };

    batchChangeValue = (field: string, value: any) => {};

    changeBatchInputValue = (field: string, value: any) => {};

    render() {
        const { data } = this.props;
        const pagination = false as PaginationConfig | false;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: data,
            pagination,
        };

        return <Table {...tableProps}></Table>;
    }
}

export { ContentTopicSelectedDisplay };
