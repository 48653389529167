import React, { PureComponent } from 'react';
import { forEach, remove, find } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { Modal, Select, Table as AntTable } from 'antd';

const api = services.api;
const { Option } = Select;

interface CouponOrderAppliesSelectorProps {
    orderId: number;
    params?: any;
    selectValues: any[];
    onChange: (products: any[]) => void;
    disabled?: boolean;
    apiPath?: string;
    showCouponName?: boolean;
}

interface CouponOrderAppliesSelectorState {
    visible: boolean;
    data: any[];
}
/**
 * 选择优惠券
 */
export class CouponOrderAppliesSelector extends PureComponent<
    CouponOrderAppliesSelectorProps,
    CouponOrderAppliesSelectorState
> {
    static defaultProps = {
        disabled: false,
    };

    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
            data: [],
        };
    }

    dispatch: any = {};
    selectedRows = new Array<any>();

    componentWillUnmount() {
        //this.dispatch(actions.unmountEntityAction(entity));
    }

    onTableRowSelect = (record: any, selected: boolean) => {
        if (selected) {
            this.selectedRows.push(record);
        } else {
            remove(this.selectedRows, (i) => {
                return i.id === record.id;
            });
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: any[]) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            this.selectedRows = [];
        }
    };

    onOk = () => {
        const { onChange } = this.props;
        if (onChange) {
            forEach(this.selectedRows, (coupon) => {
                coupon.select = true;
            });
            onChange(this.selectedRows);
            this.toggleModal();
        }
    };

    onChangeRefundMoney = (e: any, record: any) => {
        this.selectedRows.map((item: any) => {
            if (record.id === item.couponId) {
                item.refundMoney = e;
            }
            return item;
        });
        const { data } = this.state;
        let item = find(data, { id: record.id }) || {};
        item.refundMoney = e;
        this.setState(
            {
                data: data,
            },
            () => {
                this.forceUpdate();
            }
        );
    };

    getTableConfig = () => {
        const { showCouponName } = this.props;
        let newColumns: any = [
            {
                title: services.language.getText('qbm'),
                key: 'couponNo',
                dataIndex: 'couponNo',
                render: (text: any) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: '券状态',
                key: 'couponStatus',
                dataIndex: 'couponStatus',
                render: (text: any, row: any) => {
                    let couponStatusStr = '';
                    if (text === 'ENABLED') {
                        couponStatusStr = '待使用';
                        if (row.expire) {
                            couponStatusStr += '已过期';
                        }
                    } else if (text === 'USED') {
                        couponStatusStr = '已使用';
                        if (row.expire) {
                            couponStatusStr += '已过期';
                        }
                    } else if (text === 'EXPIRED') {
                        couponStatusStr = '已过期';
                    } else if (text === 'REFUND_APPLY') {
                        couponStatusStr = '退款中';
                    } else if (text === 'REFUND_FINISH') {
                        couponStatusStr = '退款完成';
                    }
                    return couponStatusStr;
                },
            },
            {
                title: services.language.getText('clzt'),
                key: 'refundMoney',
                dataIndex: 'refundMoney',
                render: (text: any, row: any) => {
                    const couponStatus = row.couponStatus;
                    return couponStatus === 'USED' && !row.expire ? (
                        <Select
                            value={text}
                            style={{ width: '100px' }}
                            onChange={(e: any) => this.onChangeRefundMoney(e, row)}
                        >
                            <Option value="true">退款退券</Option>
                            <Option value="false">{services.language.getText('ztq')}</Option>
                        </Select>
                    ) : (
                        <Select value={text} style={{ width: '100px' }}>
                            <Option value="true">退款退券</Option>
                        </Select>
                    );
                },
            },
            {
                title: services.language.getText('zxxj'),
                key: 'executePrice',
                dataIndex: 'executePrice',
                render: (text: any) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: services.language.getText('zxjf'),
                key: 'pointExecutePrice',
                dataIndex: 'pointExecutePrice',
                render: (text: any) => {
                    return <span>{text}</span>;
                },
            },
        ];
        if (showCouponName) {
            newColumns.unshift({
                title: '门票名称',
                key: 'couponName',
                dataIndex: 'couponName',
                width: 280,
                render: (text: any) => {
                    return (
                        <span
                            style={{
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: 248,
                            }}
                        >
                            {text}
                        </span>
                    );
                },
            });
        }
        let config: any = {
            rowKey: 'id',
            columns: [...newColumns],
            scroll: {
                y: 400,
            },
            style: {
                marginTop: 20,
            },
        };
        return config;
    };

    renderCoupon = () => {
        const { data } = this.state;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: data,
            rowSelection: {
                onSelect: (record: any, selected: boolean) => {
                    this.onTableRowSelect(record, selected);
                },
                onSelectAll: (selected: boolean, rows: any) => {
                    this.onTableRowSelectAll(selected, rows);
                },
                onChange: () => {},
            },
        };

        return <AntTable {...tableProps} pagination={false}></AntTable>;
    };

    toggleModal = () => {
        const { visible } = this.state;
        this.setState({ visible: !visible });
    };

    getAllCoupon = () => {
        const { params, orderId, apiPath } = this.props;
        //获取详细信息
        api.get(
            {
                ...params,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                apiPath: apiPath ? apiPath : `/admin/orders/${orderId}/refundable/record`,
            }
        ).then((response: any) => {
            const data = response.map((item: any) => {
                item.id = item.couponId;
                item.refundMoney = 'true';
                return item;
            });
            this.setState(
                {
                    data: data,
                    visible: true,
                },
                () => {
                    this.forceUpdate();
                }
            );
        });
    };

    render() {
        const { disabled } = this.props;
        const modalContent = this.renderCoupon();
        const modalProps = {
            width: 1000,
            title: services.language.getText('shq'),
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };
        return (
            <div style={{ display: 'inline-block' }}>
                {!disabled && (
                    <span
                        style={{ marginLeft: '10px', color: '#1890ff', cursor: 'pointer' }}
                        onClick={this.getAllCoupon}
                    >
                        选择券
                    </span>
                )}
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}
