import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty, get, sortBy } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;
export const CardCollectionLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/card_collections';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/card_collections/${id}`;
        } else {
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : language.getText('longTerm'),
                    };
                }
                res.baseInfo = {
                    name: res.name,
                    dateRange: {
                        start: res.startTime,
                        end: res.endTime,
                    },
                    subsiteIds: res.subsites,
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };
                let ruleConfig3 = res.collectionTimeRules.filter(
                    (item: any) => item.type === 'INVITE_HELP'
                );
                let ruleConfig4 = res.collectionTimeRules.filter(
                    (item: any) => item.type === 'INVITE_REGISTER'
                );
                let ruleConfig5 = res.collectionTimeRules.filter(
                    (item: any) => item.type === 'CONSUMPTION'
                );

                res.ruleConfig1 = createRuleConfig(res.collectionTimeRules, 'FIRST_ACCESS', 1);
                res.ruleConfig2 = createRuleConfig(res.collectionTimeRules, 'FIRST_GIVE', 1);
                res.ruleConfig3 = createRuleConfig(
                    res.collectionTimeRules,
                    'INVITE_HELP',
                    ruleConfig3.length ? ruleConfig3.length : 1
                );
                res.ruleConfig4 = createRuleConfig(
                    res.collectionTimeRules,
                    'INVITE_REGISTER',
                    ruleConfig4.length ? ruleConfig4.length : 1
                );
                res.ruleConfig5 = createRuleConfig(
                    res.collectionTimeRules,
                    'CONSUMPTION',
                    ruleConfig5.length ? ruleConfig5.length : 1
                );

                if (res.pageConfig) {
                    const pageConfigJson = JSON.parse(res.pageConfig);
                    res.cardBg = [pageConfigJson.areaPicture];
                    res.cardBigPic = [pageConfigJson.bigPicture];
                    res.cardRewardBtnPic = [pageConfigJson.compositeButtonPicture];
                    res.cardRewardPic = [pageConfigJson.rewardTagPicture];
                    res.cardRuleBtnPic = [pageConfigJson.ruleAreaButtonPicture];
                    res.cardRulePic = [pageConfigJson.ruleTagPicture];
                } else {
                    res.cardBg = [res.collectionPictures.areaPicture];
                    res.cardBigPic = [res.collectionPictures.bigPicture];
                    res.cardRewardBtnPic = [res.collectionPictures.compositeButtonPicture];
                    res.cardRewardPic = [res.collectionPictures.rewardTagPicture];
                    res.cardRuleBtnPic = [res.collectionPictures.ruleAreaButtonPicture];
                    res.cardRulePic = [res.collectionPictures.ruleTagPicture];
                }
                res.cardPosterPic = [res.collectionPictures.sharePosterPicture];
                res.cardSharePic = [res.collectionPictures.shareSmallPicture];

                res.activityIntro = res.description;
                res.cardStandard = res.format || 'FORMAT_MIX';
                res.activityType = res.type;

                res.cardItemConfig = res.collectionPuzzles.map((i: any) => ({
                    percent: i.probability,
                    id: i.id,
                    amount: i.stock,
                    cardCollectionItemPicture: [{ id: i.pictureId, path: i.pictureUrl }],
                }));

                res.rewardLimit = res.collectionRewards.map((i: any) => {
                    if (i.type === 'POINT') {
                        return {
                            count: i.stock,
                            rewardType: 'POINT',
                            point: i.rewardValue,
                            couponsValue: [],
                        };
                    } else if (i.type === 'COUPON') {
                        return { coupons: i.stock, rewardType: 'COUPON', couponsValue: [i.coupon] };
                    } else {
                        return {};
                    }
                });
            }
            return res;
        });
    },

    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = `/admin/card_collections`;
        const newParams = tranformData(params);
        return await api.post(newParams, config);
    },

    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        if (params.id) {
            config.apiPath = `/admin/card_collections/${params.id}`;
        }
        const newParams = tranformData(params);

        return await api.put(newParams, config);
    },
};

function tranformData(params: any) {
    let newParams: any = {};
    if (params.baseInfo) {
        newParams.name = params.baseInfo.name;
        newParams.startTime = params.baseInfo.dateRange.start;
        newParams.endTime = params.baseInfo.dateRange.end;
        const subsiteIds = params.baseInfo.subsiteIds.map((subsite: any) => {
            return subsite.id;
        });
        newParams.subsiteIds = subsiteIds;
    }

    newParams.cardCollectionPictureCommand = {
        // 分享海报图片
        sharePosterPictureId: get(params, 'cardPosterPic[0].id'),
        // 分享小图
        shareSmallPictureId: get(params, 'cardSharePic[0].id'),
    };
    const pageConfig: any = {
        areaPicture: {
            id: params.cardBg[0].id,
            path: params.cardBg[0].path,
        },
        bigPicture: {
            id: params.cardBigPic[0].id,
            path: params.cardBigPic[0].path,
        },
        compositeButtonPicture: {
            id: params.cardRewardBtnPic[0].id,
            path: params.cardRewardBtnPic[0].path,
        },
        rewardTagPicture: {
            id: params.cardRewardPic[0].id,
            path: params.cardRewardPic[0].path,
        },
        ruleAreaButtonPicture: {
            id: params.cardRuleBtnPic[0].id,
            path: params.cardRuleBtnPic[0].path,
        },
        ruleTagPicture: {
            id: params.cardRulePic[0].id,
            path: params.cardRulePic[0].path,
        },
    };
    newParams.pageConfig = JSON.stringify(pageConfig);

    newParams.cardCollectionPuzzleCommand = params.cardItemConfig.map((i: any, index: number) => ({
        pictureId: i.cardCollectionItemPicture[0].id,
        probability: i.percent,
        sequence: index,
        id: window.location.href.includes('/edit') ? i.id : undefined,
        stock: i.amount,
    }));
    newParams.cardCollectionRewardCommand = params.rewardLimit
        .filter((i: any) => i.rewardType)
        .map((i: any) => {
            if (i.rewardType === 'POINT') {
                return {
                    stock: i.count,
                    rewardValue: i.point,
                    type: 'POINT',
                };
            } else if (i.rewardType === 'COUPON') {
                return {
                    definitionId: i.couponsValue[0].id,
                    quantity: i.couponsValue[0].quantity || 1,
                    stock: i.coupons,
                    type: 'COUPON',
                };
            } else {
                return {};
            }
        });

    const ruleFlatArr = [
        ...params.ruleConfig1.filter((c: any) => c.checked),
        ...params.ruleConfig2.filter((c: any) => c.checked),
        ...params.ruleConfig3.filter((c: any) => c.checked),
        ...params.ruleConfig4.filter((c: any) => c.checked),
        ...params.ruleConfig5.filter((c: any) => c.checked),
    ];

    newParams.cardCollectionTimesRuleCommand = ruleFlatArr.map((i: any) => ({
        type: i.type,
        conditionValue: i.count1 || 1,
        rewardValue: i.count2,
    }));

    newParams.description = params.activityIntro;
    newParams.format = params.cardStandard;
    newParams.type = params.activityType;
    newParams.marketingScheduleId =
        (params.baseInfo?.marketingSchedule?.length && params.baseInfo.marketingSchedule[0].id) ||
        null;
    return newParams;
}

function createRuleConfig(collectionTimeRules: any, type: string, length: number) {
    let rules = collectionTimeRules.filter((i: any) => i.type === type);
    rules = sortBy(rules, 'conditionValue');
    const checked = rules.length > 0;
    return Array.from({ length }).map((_, index) => {
        const rule = rules[index];
        return {
            ...rule,
            type,
            count1: rule?.conditionValue,
            count2: rule?.rewardValue,
            checked,
        };
    });
}
