import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { message } from 'antd';

const { getText } = services.language;

export const config: Config = {
    entities: {
        VirtualNumberSettingConfig: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PASSPORT`,
            apiPath: '/loader/admin/security_policy',
            properties: {
                registerSecurityPolicy: {
                    type: 'object.subForm',
                    displayName: '<<zcaqcl>>',
                    properties: {
                        id: {
                            type: 'string',
                        },
                        securityPolicyCondition: {
                            type: 'object.securityPolicyCondition',
                            displayName: '<<zcaqcl>>',
                        },
                        executionResult: {
                            type: 'string.pictureExampleButton',
                            displayName: '<<clzxjg>>',
                            controlConfig: {
                                extraText: '<<zcbcg>>',
                                buttonName: '<<tssl>>',
                                type: 'executionResult',
                            },
                        },
                        tip: {
                            type: 'string.text.paragraph',
                            displayName: '<<zcljtsy>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                maxLength: 60,
                            },
                        },
                    },
                },
                loginSecurityPolicy: {
                    type: 'object.subForm',
                    displayName: '<<dlaqcl>>',
                    properties: {
                        id: {
                            type: 'string',
                        },
                        securityPolicyCondition: {
                            type: 'object.securityPolicyCondition',
                            displayName: '<<dlaqcl>>',
                        },
                        executionResult: {
                            type: 'string.pictureExampleButton',
                            displayName: '<<clzxjg>>',
                            controlConfig: {
                                extraText: '<<wfdl>>',
                                buttonName: '<<tssl>>',
                                type: 'executionResult',
                            },
                        },
                        tip: {
                            type: 'string.text.paragraph',
                            displayName: '<<dlljtsy>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                maxLength: 60,
                            },
                        },
                        userLoginDuration: {
                            type: 'number.tip',
                            displayName: '<<yhqddlsx>>',
                            rules: [
                                {
                                    message: '<<jzcsrzzs>>',
                                    validator: (rule: any, value: any) => {
                                        if (value === undefined || value === null || value === '') {
                                            return true;
                                        }
                                        if (Number.isInteger(value) && value > 0) {
                                            return true;
                                        }
                                        return false;
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<inputPlease>>',
                                style: {
                                    width: 125,
                                },
                                addonbefore: '<<dyhycdlh>>',
                                addonafter: '<<thdlztsx>>',
                            },
                            extra: '<<h_yhqddlsx_tip>>',
                        },
                        multiSessionLogin: {
                            type: 'string.options.radio',
                            displayName: '<<tyzhdhhdl>>',
                            defaultValue: 'YES',
                            options: [
                                { id: 'YES', name: '<<allow>>' },
                                { id: 'NO', name: '<<notAllow>>' },
                            ],
                            extra: '<<h_tyzhdhhdl_tip>>',
                        },
                    },
                },
            },
        },
    },
    components: {
        VirtualNumberSettingView: {
            component: 'Viewport',
        },
        VirtualNumberSettingPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'VirtualNumberSecurityConfigForm' }],
        },
        VirtualNumberSecurityConfigForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'VirtualNumberSettingConfig',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            style: { width: 1000 },
            fields: [
                { property: 'registerSecurityPolicy.securityPolicyCondition' },
                { property: 'registerSecurityPolicy.executionResult' },
                { property: 'registerSecurityPolicy.tip' },
                { property: 'loginSecurityPolicy.securityPolicyCondition' },
                { property: 'loginSecurityPolicy.executionResult' },
                { property: 'loginSecurityPolicy.tip' },
                { property: 'loginSecurityPolicy.userLoginDuration' },
                { property: 'loginSecurityPolicy.multiSessionLogin' },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'submit',
                        text: '<<bc>>',
                    },
                ],
            },
            onValidate: (entity: any) => {
                const registerPolicy = entity.registerSecurityPolicy.securityPolicyCondition;
                const loginPolicy = entity.loginSecurityPolicy.securityPolicyCondition;
                if (
                    registerPolicy.status &&
                    !registerPolicy.virtualNumberStatus &&
                    !registerPolicy.channelStatus
                ) {
                    message.warning(getText('zcaqclzskqygaqclyz'));
                    return false;
                }
                if (
                    loginPolicy.status &&
                    !loginPolicy.virtualNumberStatus &&
                    !loginPolicy.channelStatus
                ) {
                    message.warning(getText('dlaqclzskqygaqclyz'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                message.success(getText('bccg'));
            },
        },
        VirtualNumberEditPage: {
            component: 'Card',
            style: { padding: 0 },
            content: { component: 'VirtualNumberEditForm' },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/virtual-number-setting',
                    component: 'VirtualNumberSettingView',
                    breadcrumbName: '<<memberSecuritySetting>>',
                    privilege: {
                        path: 'virtualNumberSetting',
                        level: 'full',
                    },
                    childRoutes: [
                        { path: '/virtual-number-edit', component: 'VirtualNumberEditPage' },
                        { path: '/', component: 'VirtualNumberSettingPage' },
                    ],
                },
            ],
        },
    ],
};
