import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { get } from 'lodash';
import { List as AntList, Radio, Icon, Select } from 'antd';
import { TagSelector } from './tags-selector-modal';
import {
    MonthDayRangePicker,
    NumberInputTip,
    DateTimeRangePickerPlus,
    ArrayOptionIdsCheckboxTip,
} from '../../../../components';
import { language } from '@comall-backend-builder/core/lib/services';

const RadioGroup = Radio.Group;

export enum MetricDayEnum {
    // 不限
    All = 'ALL',
    Last7 = 'LAST_7D',
    Last15 = 'LAST_15D',
    Last30 = 'LAST_30D',
    Last60 = 'LAST_60D',
    Last90 = 'LAST_90D',
    Last180 = 'LAST_180D',
}
const consumptionOptions = [
    {
        id: MetricDayEnum.Last7,
        name: '最近7天',
    },
    {
        id: MetricDayEnum.Last15,
        name: '最近15天',
    },
    {
        id: MetricDayEnum.Last30,
        name: '最近30天',
    },
    {
        id: MetricDayEnum.Last60,
        name: '最近60天',
    },
    {
        id: MetricDayEnum.Last90,
        name: '最近90天',
    },
    {
        id: MetricDayEnum.Last180,
        name: '最近180天',
    },
    {
        id: MetricDayEnum.All,
        name: '不限',
    },
];

export enum ConditionType {
    MemberSex = 'MEMBER_SEX',
    MemberBirthday = 'MEMBER_BIRTHDAY',
    MemberLevel = 'MEMBER_LEVEL',
    BindLicencePlate = 'BIND_LICENCE_PLATE',
    MemberTag = 'MEMBER_TAG',
    DealDay = 'DEAL_DAY',
    DealAmount = 'DEAL_AMOUNT',
    DealCount = 'DEAL_COUNT',
    VisitDay = 'VISIT_DAY',
    RegisterChannel = 'REGISTER_CHANNEL',
    RegisterSubsite = 'REGISTER_SUBSITE',
    RegisterTime = 'REGISTER_TIME',
}

export interface ListItem {
    value?: any;
    selectMode?: any;
    id: ConditionType;
}
interface ListProps {
    setList: Array<ListItem>;
    subsiteId: string;
    levelOptions: any[];
    onChange: (value: any, item: ListItem, valueType: string) => void;
    onDelete: (item: ListItem) => void;
}

export class List extends Component<ListProps, any> {
    static propTypes = {
        renderItem: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
        rowSelection: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
        header: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.object]),
    };

    state = {
        indeterminate: false,
        selectedRowKeys: [],
        checkAll: false,
    };

    componentWillMount() {
        // @ts-ignore
        this.props.onInit && this.props.onInit();
    }
    onItemChange = (value: any, item: ListItem, valueType: string) => {
        const { onChange } = this.props;
        if (onChange) {
            onChange(value, item, valueType);
        }
    };
    onDeleteItem = (item: ListItem) => {
        const { onDelete } = this.props;
        if (onDelete) {
            onDelete(item);
        }
    };

    renderItem = (data: ListItem) => {
        const { subsiteId, levelOptions } = this.props;
        return (
            <section className="pub-section">
                {data.id === 'MEMBER_SEX' && (
                    //性别
                    <div className="row-main">
                        <div className="label">性别：</div>
                        <div>
                            <RadioGroup
                                onChange={(event) =>
                                    this.onItemChange(event.target.value, data, 'value')
                                }
                                value={data.value}
                                className="fill-style"
                            >
                                <Radio value={'MAN'}>男</Radio>
                                <Radio value={'WOMAN'}>女</Radio>
                            </RadioGroup>
                            <div className="ant-form-extra">用户性别信息</div>
                        </div>
                    </div>
                )}
                {data.id === 'MEMBER_BIRTHDAY' && (
                    //生日
                    <div className="row-main">
                        <div className="label">生日：</div>
                        <div>
                            <RadioGroup
                                onChange={(event) =>
                                    this.onItemChange(event.target.value, data, 'selectMode')
                                }
                                value={data.selectMode}
                                className="fill-style"
                            >
                                <Radio value={'APPOINT_BIRTHDAY_DATE'}>指定生日日期</Radio>
                                <Radio value={'BIRTHDAY_LAST_DAY'}>生日距当前天数</Radio>
                                <Radio value={'APPOINT_BIRTH_DATE'}>指定出生日期</Radio>
                            </RadioGroup>
                            <div className="ant-form-extra" style={{ marginBottom: 15 }}>
                                生日在选定时间内的用户
                            </div>
                            {data?.selectMode && data.selectMode === 'APPOINT_BIRTHDAY_DATE' && (
                                // @ts-ignore
                                <MonthDayRangePicker
                                    value={data.value}
                                    onChange={(value) => this.onItemChange(value, data, 'value')}
                                />
                            )}
                            {data?.selectMode && data.selectMode === 'BIRTHDAY_LAST_DAY' && (
                                <NumberInputTip
                                    style={{ width: 150 }}
                                    value={data.value}
                                    onChange={(value: any) =>
                                        this.onItemChange(value, data, 'value')
                                    }
                                    min={1}
                                    precision={0}
                                    addonafter={'天'}
                                />
                            )}
                            {data?.selectMode && data.selectMode === 'APPOINT_BIRTH_DATE' && (
                                <DateTimeRangePickerPlus
                                    value={data.value}
                                    hideTime={true}
                                    onChange={(value: any) =>
                                        this.onItemChange(value, data, 'value')
                                    }
                                />
                            )}
                        </div>
                    </div>
                )}
                {data.id === 'MEMBER_LEVEL' && (
                    //等级
                    <div className="row-main">
                        <div className="label">等级：</div>
                        {!!subsiteId ? (
                            <div>
                                <ArrayOptionIdsCheckboxTip
                                    options={levelOptions}
                                    value={data.value}
                                    onChange={(value: any) =>
                                        this.onItemChange(value, data, 'value')
                                    }
                                />
                                <div className="ant-form-extra">用户当前门店下的会员等级</div>
                            </div>
                        ) : (
                            <div className="ant-form-extra">请先选择门店</div>
                        )}
                    </div>
                )}
                {data.id === 'BIND_LICENCE_PLATE' && (
                    //绑定车牌
                    <div className="row-main">
                        <div className="label">绑定车牌：</div>
                        <div>
                            <RadioGroup
                                onChange={(event) =>
                                    this.onItemChange(event.target.value, data, 'value')
                                }
                                value={data.value}
                                className="fill-style"
                            >
                                <Radio value={'BIND'}>已绑</Radio>
                                <Radio value={'NOT_BIND'}>未绑</Radio>
                            </RadioGroup>
                            <div className="ant-form-extra">用户与车牌的绑定关系</div>
                        </div>
                    </div>
                )}
                {data.id === 'MEMBER_TAG' && (
                    //标签
                    <div className="row-main">
                        <div className="label">标签：</div>
                        <div>
                            <TagSelector
                                selectValues={data.value}
                                onChange={(value) => this.onItemChange(value, data, 'value')}
                                subsiteId={subsiteId}
                            />
                        </div>
                    </div>
                )}
                {data.id === 'REGISTER_CHANNEL' && (
                    //注册渠道
                    <div className="row-main">
                        <div className="label">注册渠道：</div>
                        <div>
                            <RadioGroup
                                onChange={(event) =>
                                    this.onItemChange(event.target.value, data, 'value')
                                }
                                value={data.value}
                                className="fill-style"
                            >
                                <Radio value={'MAGIC'}>magic线上</Radio>
                                <Radio value={'CRM'}>CRM</Radio>
                            </RadioGroup>
                            <div className="ant-form-extra">通过以上渠道成为会员的用户</div>
                        </div>
                    </div>
                )}
                {data.id === 'REGISTER_SUBSITE' && (
                    //注册门店
                    <div className="row-main">
                        <div className="label">注册门店：</div>
                        <div>
                            <RadioGroup
                                onChange={(event) =>
                                    this.onItemChange(event.target.value, data, 'value')
                                }
                                value={data.value}
                                className="fill-style"
                            >
                                <Radio value={'CURRENT_STORE'}>当前门店</Radio>
                                <Radio value={'NOT_CURRENT_STORE'}>非当前门店</Radio>
                            </RadioGroup>
                            <div className="ant-form-extra">注册门店未当前门店的用户</div>
                        </div>
                    </div>
                )}
                {data.id === 'REGISTER_TIME' && (
                    //注册时间
                    <div className="row-main">
                        <div className="label">注册时间：</div>
                        <div>
                            <RadioGroup
                                onChange={(event) =>
                                    this.onItemChange(event.target.value, data, 'selectMode')
                                }
                                value={data.selectMode}
                                className="fill-style"
                            >
                                <Radio value={'APPOINT_REGISTER_DATE'}>指定注册时间</Radio>
                                <Radio value={'REGISTER_LAST_DAY'}>注册距当前天数</Radio>
                            </RadioGroup>
                            <div className="ant-form-extra" style={{ marginBottom: 15 }}>
                                注册时间在选定时间内的用户
                            </div>
                            {data?.selectMode && data.selectMode === 'REGISTER_LAST_DAY' && (
                                <NumberInputTip
                                    style={{ width: 150 }}
                                    value={data.value}
                                    onChange={(value: any) =>
                                        this.onItemChange(value, data, 'value')
                                    }
                                    min={1}
                                    precision={0}
                                    addonafter={'天'}
                                />
                            )}
                            {data?.selectMode && data.selectMode === 'APPOINT_REGISTER_DATE' && (
                                <DateTimeRangePickerPlus
                                    value={data.value}
                                    hideTime={true}
                                    onChange={(value: any) =>
                                        this.onItemChange(value, data, 'value')
                                    }
                                />
                            )}
                        </div>
                    </div>
                )}
                {data.id === 'DEAL_DAY' && (
                    // 最近消费时间
                    <div className="row-main">
                        <div className="label">最近消费时间：</div>
                        <div>
                            <Select
                                value={data.value?.lastDay}
                                style={{ width: '140px' }}
                                onChange={(value: any) =>
                                    this.onItemChange(
                                        {
                                            lastDay: value,
                                        },
                                        data,
                                        'value'
                                    )
                                }
                            >
                                {consumptionOptions &&
                                    consumptionOptions.map((option: any) => (
                                        <Select.Option key={option.id} value={option.id}>
                                            {option.name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </div>
                    </div>
                )}
                {data.id === 'DEAL_AMOUNT' && (
                    // 消费金额
                    <div className="row-main">
                        <div className="label">{language.getText('xfje')}：</div>
                        <div>
                            <div>
                                <Select
                                    value={data.value?.lastDay}
                                    style={{ width: '140px' }}
                                    onChange={(value: any) =>
                                        this.onItemChange(
                                            {
                                                ...data.value,
                                                lastDay: value,
                                            },
                                            data,
                                            'value'
                                        )
                                    }
                                >
                                    {consumptionOptions &&
                                        consumptionOptions.map((option: any) => (
                                            <Select.Option key={option.id} value={option.id}>
                                                {option.name}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </div>
                            <div style={{ display: 'flex', marginTop: 10 }}>
                                <NumberInputTip
                                    placeholder={language.getText('inputPlease')}
                                    type="text"
                                    addonafter={language.getText('yuan')}
                                    min={0}
                                    precision={0}
                                    style={{ width: 100 }}
                                    value={data.value?.min}
                                    onChange={(value: any) =>
                                        this.onItemChange(
                                            {
                                                ...data.value,
                                                min: value,
                                            },
                                            data,
                                            'value'
                                        )
                                    }
                                />
                                <div style={{ marginLeft: 45 }}>--</div>
                                <NumberInputTip
                                    placeholder={language.getText('inputPlease')}
                                    type="text"
                                    min={0}
                                    precision={0}
                                    style={{ width: 100, marginLeft: 5 }}
                                    addonafter={language.getText('yuan')}
                                    value={data.value?.max}
                                    onChange={(value: any) =>
                                        this.onItemChange(
                                            {
                                                ...data.value,
                                                max: value,
                                            },
                                            data,
                                            'value'
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                )}
                {data.id === 'DEAL_COUNT' && (
                    // 消费金额
                    <div className="row-main">
                        <div className="label">消费次数：</div>
                        <div>
                            <div>
                                <Select
                                    value={data.value?.lastDay}
                                    style={{ width: '140px' }}
                                    onChange={(value: any) =>
                                        this.onItemChange(
                                            {
                                                ...data.value,
                                                lastDay: value,
                                            },
                                            data,
                                            'value'
                                        )
                                    }
                                >
                                    {consumptionOptions &&
                                        consumptionOptions.map((option: any) => (
                                            <Select.Option key={option.id} value={option.id}>
                                                {option.name}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </div>
                            <div style={{ display: 'flex', marginTop: 10 }}>
                                <NumberInputTip
                                    placeholder={language.getText('inputPlease')}
                                    type="text"
                                    addonafter="次"
                                    min={0}
                                    precision={0}
                                    style={{ width: 100 }}
                                    value={data.value?.min}
                                    onChange={(value: any) =>
                                        this.onItemChange(
                                            {
                                                ...data.value,
                                                min: value,
                                            },
                                            data,
                                            'value'
                                        )
                                    }
                                />
                                <div style={{ marginLeft: 45 }}>--</div>
                                <NumberInputTip
                                    placeholder={language.getText('inputPlease')}
                                    type="text"
                                    min={0}
                                    precision={0}
                                    style={{ width: 100, marginLeft: 5 }}
                                    addonafter="次"
                                    value={data.value?.max}
                                    onChange={(value: any) =>
                                        this.onItemChange(
                                            {
                                                ...data.value,
                                                max: value,
                                            },
                                            data,
                                            'value'
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                )}
                {data.id === 'VISIT_DAY' && (
                    // 到店天数
                    <div className="row-main">
                        <div className="label">到店天数：</div>
                        <div>
                            <div>
                                <Select
                                    value={data.value?.lastDay}
                                    style={{ width: '140px' }}
                                    onChange={(value: any) =>
                                        this.onItemChange(
                                            {
                                                ...data.value,
                                                lastDay: value,
                                            },
                                            data,
                                            'value'
                                        )
                                    }
                                >
                                    {consumptionOptions &&
                                        consumptionOptions.map((option: any) => (
                                            <Select.Option key={option.id} value={option.id}>
                                                {option.name}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </div>
                            <div style={{ display: 'flex', marginTop: 10 }}>
                                <NumberInputTip
                                    placeholder={language.getText('inputPlease')}
                                    type="text"
                                    addonafter="天"
                                    min={0}
                                    precision={0}
                                    style={{ width: 100 }}
                                    value={data.value?.min}
                                    onChange={(value: any) =>
                                        this.onItemChange(
                                            {
                                                ...data.value,
                                                min: value,
                                            },
                                            data,
                                            'value'
                                        )
                                    }
                                />
                                <div style={{ marginLeft: 45 }}>--</div>
                                <NumberInputTip
                                    placeholder={language.getText('inputPlease')}
                                    type="text"
                                    min={0}
                                    precision={0}
                                    style={{ width: 100, marginLeft: 5 }}
                                    addonafter="天"
                                    value={data.value?.max}
                                    onChange={(value: any) =>
                                        this.onItemChange(
                                            {
                                                ...data.value,
                                                max: value,
                                            },
                                            data,
                                            'value'
                                        )
                                    }
                                />
                            </div>
                        </div>
                    </div>
                )}
                <div onClick={() => this.onDeleteItem(data)} className="delete">
                    <Icon type="close-circle" />
                </div>
            </section>
        );
    };

    componentWillReceiveProps(nextProps: any) {
        const currentSubsite = get(this.props, 'subsiteId', '');
        const nextSubsite = get(nextProps, 'subsiteId', '');
        if (currentSubsite && nextSubsite && currentSubsite !== nextSubsite) {
            const initTgs = {
                id: ConditionType.MemberTag,
                selectMode: '',
                value: [],
            };
            this.onItemChange([], initTgs, 'value');
        }
    }

    render() {
        let { setList } = this.props;
        if (setList && setList.length === 0) {
            const url = require('./custom_crowd_pic.jpg');
            return (
                <div className="no-rule-main">
                    <p>{language.getText('rqxtsmzyxtz')}</p>
                    <div className="no-rule-main-img-box">
                        <img className="no-rule-main-img" width="100%" alt="" src={url}></img>
                    </div>
                </div>
            );
        }
        return (
            <div>
                <AntList
                    className="rule-list"
                    itemLayout="horizontal"
                    dataSource={setList}
                    renderItem={(item) => (
                        <AntList.Item key={item.id} className="rule-li">
                            {this.renderItem(item)}
                        </AntList.Item>
                    )}
                />
            </div>
        );
    }
}
