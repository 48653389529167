import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { get, isUndefined } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

export enum ReceiveLimitEnum {
    NO_LIMIT = 'NO_LIMIT',
    CROWD_LIMIT = 'CROWD_LIMIT',
}

export const config: Config = {
    entities: {
        SalesElectronicCouponEntity: {
            apiPath: '/loader/admin/sales-electronic-coupon',
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        salesChannels: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '<<salesChannels>>',
                            options: [
                                {
                                    id: 'OWN',
                                    name: '<<selfChannel>>',
                                },
                                { id: 'DOUYIN', name: '<<douyin>>' },
                            ],
                            defaultValue: ['OWN'],
                            rules: [{ required: true }],
                            controlConfig: {
                                checkboxType: 'Multiple',
                                checkboxTypeDisabled: ['OWN'],
                                tip: [
                                    '<<salesChannelTip1>>',
                                    '<<salesChannelTip2>>',
                                    '<<salesChannelTip3>>',
                                ],
                            },
                        },
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<saleSubsites>>',
                            defaultValue: [],
                            rules: [{ required: true, message: '<<selectSaleSubsites>>' }],
                            controlConfig: {
                                type: 'radio',
                            },
                        },
                        coupon: {
                            type: 'object.salesElectronicCouponSelect',
                            displayName: '<<components.ComponentSelector.coupon>>',
                            defaultValue: [],
                            rules: [{ required: true, message: '<<selectCouponBag>>' }],
                            controlConfig: {
                                type: 'radio',
                                selectMode: 'DOUYIN',
                            },
                        },
                        name: {
                            type: 'string.salesElectronicCouponName',
                            displayName: '<<saleProductName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<selectSaleProductName>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                maxLength: 60,
                                seat: 'row.baseInfo.name',
                            },
                        },
                        salesPriceAndCouponPackagePrices: {
                            type: 'object.salesPriceAndCouponPackagePrices',
                            displayName: '<<price>>',
                            defaultValue: {
                                salesPrice: undefined,
                                couponPackagePrices: [],
                            },
                            rules: [{ required: true, message: '<<inputPrice>>' }],
                        },
                        marketPrice: {
                            type: 'number.price',
                            displayName: '<<marketPrice>>',
                            displayConfig: { addonafter: '<<yuan>>' },
                            rules: [
                                {
                                    message: '<<marketPriceRules>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(?!0+$)(?!0*\.0*$)\d{1,8}(\.\d{1,2})?$/;
                                        if (value) {
                                            if (Number(value) >= 0.01 && reg.test(value)) {
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        }
                                        if (value === 0) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<pleaseInputNumber>>',
                                style: { width: '150px' },
                                allowFloat: true,
                                rules: [
                                    {
                                        precision: 2,
                                        type: 'number',
                                        min: 0,
                                    },
                                ],
                            },
                        },
                        stock: {
                            type: 'number.salesElectronicCouponStock',
                            displayName: '<<maxSalesCount>>',
                            rules: [
                                {
                                    type: 'number',
                                    max: 100000000,
                                    message: '<<countSetHundredMillion>>',
                                },
                                { required: true, message: '<<selectMaxSalesCount>>' },
                            ],
                            controlConfig: {
                                tip: '<<selectedChanelTip>>',
                                addonafter: '<<zhang>>',
                                allowFloat: true,
                                rules: [
                                    {
                                        type: 'number',
                                        max: 100000000,
                                        precision: 0,
                                    },
                                ],
                            },
                        },
                        pictures: {
                            displayName: '<<productPic>>',
                            type: 'array.imgaesUploadSortable',
                            className: 'sigle-image-field',
                            rules: [{ required: true, message: '<<uploadProductPic>>' }],
                            defaultValue: [],
                            controlConfig: {
                                maxCount: 10,
                                fileName: 'files',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/locations/7/upload`,
                                mode: 'picture-card',
                                maxSize: 2 * 1024,
                                uploadLimitSizeErrorMessage:
                                    '<<pictures>>{{file.name}}<<pictureUploadLimit>>',
                            },
                            extra: '<<productPicExtra>>',
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],

                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.baseInfo?.subsites?.length &&
                                        row.baseInfo.subsites.map((item: any) => item.id).join(',');
                                    return {
                                        subsiteIds,
                                    };
                                },
                            },
                        },
                    },
                },
                settingInfo: {
                    type: 'object.subForm',
                    displayName: '<<saleRules>>',
                    properties: {
                        saleStatus: {
                            type: 'string.options.radio',
                            displayName: '<<saleTime>>',
                            options: [
                                { id: 'YES', name: '<<immeSale>>' },
                                { id: 'NO', name: '<<timingSale>>' },
                            ],
                            defaultValue: 'YES',
                        },
                        saleTime: {
                            type: 'string.dateTime',
                            displayName: '<<openSaleTime>>',
                            rules: [{ required: true, message: '<<openSaleTimeRequired>>' }],
                            controlConfig: {
                                placeholder: '<<selectPlease>>',
                                style: {
                                    width: 300,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        couponExpiredAutoShelfOff: {
                            type: 'string.options.radio',
                            displayName: '<<couponExpiredAutoShelfOff>>',
                            options: [
                                { id: 'true', name: '<<yes>>' },
                                { id: 'false', name: '<<no>>' },
                            ],
                            defaultValue: 'true',
                        },
                        receiveLimit: {
                            type: 'string.options.radio.plus',
                            displayName: '<<saleLimit>>',
                            defaultValue: 'NO_LIMIT',
                            rules: [{ required: true }],
                            controlConfig: {
                                mode: 'horizontal',
                                setOptions: [
                                    {
                                        value: ReceiveLimitEnum.NO_LIMIT,
                                        name: '<<allCanBuy>>',
                                    },
                                    {
                                        value: ReceiveLimitEnum.CROWD_LIMIT,
                                        name: '<<specifyBuy>>',
                                    },
                                ],
                            },
                        },
                        receiveLimitCrowds: {
                            type: 'array.receiveLimitationsCrowds',
                            displayName: '<<selectVipPeople>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                pageType: 'SalesElectronicCoupon',
                            },
                        },
                        afterSales: {
                            type: 'object.salesElectronicCouponAfterSales',
                            displayName: '<<afterService>>',
                            defaultValue: {
                                overdueAutoRefund: true,
                                anytimeRefund: true,
                                packageRefund: false,
                            },
                            controlConfig: {
                                tip: '<<douyinRules>>',
                                selectMode: 'DOUYIN',
                                seat: 'row.baseInfo.coupon',
                            },
                        },
                        limitQuantity: {
                            type: 'number.integerInputTitleTip',
                            displayName: '<<limit>>',
                            controlConfig: {
                                addonbefore: '<<limitUser>>',
                                addonafter: '<<jian>>',
                                allowFloat: true,
                                rules: [
                                    {
                                        type: 'number',
                                        max: 999999,
                                        precision: 0,
                                    },
                                ],
                            },
                        },
                        virtualCategoryIds: {
                            type: 'array.virtualCategory',
                            displayName: '<<visualProductCategory>>',
                            options: [],
                            source: {
                                apiPath: '/admin/virtual_categories/children?id=-1000',
                            },
                            controlConfig: {
                                extra: {
                                    tip: '<<configExtra>>',
                                    img: './images/avatar/sales-electronic-coupon.png',
                                    btnText: '<<viewExample>>',
                                    triggerType: 'hover',
                                },
                            },
                        },
                        limitStock: {
                            type: 'number.integerInputTitleTip',
                            displayName: '<<limitStock>>',
                            rules: [
                                {
                                    type: 'number',
                                    max: 100000000,
                                    message: '<<countSetHundredMillion>>',
                                },
                            ],
                            controlConfig: {
                                addonafter: '<<zhang>>',
                                allowFloat: true,
                                rules: [
                                    {
                                        type: 'number',
                                        max: 100000000,
                                        precision: 0,
                                    },
                                ],
                            },
                        },
                        initSalesVolume: {
                            type: 'number.integerInputTitleTip',
                            displayName: '<<initSaleVolume>>',
                            defaultValue: 100,
                            rules: [
                                {
                                    type: 'number',
                                    max: 100000000,
                                    message: '<<countSetHundredMillion>>',
                                },
                            ],
                            controlConfig: {
                                addonafter: '<<zhang>>',
                                allowFloat: true,
                                rules: [
                                    {
                                        type: 'number',
                                        max: 100000000,
                                        precision: 0,
                                    },
                                ],
                            },
                        },
                        mobileDetails: {
                            type: 'string.richTextPlus',
                            displayName: '<<productInfo>>',
                            controlConfig: {
                                fillSalesElectronicCouponInstructions: true,
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            rules: [
                                {
                                    max: 20000,
                                    message: '<<productInfoRequired>>',
                                },
                            ],
                        },
                    },
                },
            },
        },
    },
    components: {
        SalesElectronicCouponView: {
            component: 'Viewport',
        },
        SalesElectronicCouponPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'SalesElectronicCouponFind',
                },
            ],
        },
        SalesElectronicCouponAddPage: {
            component: 'Card',
            content: { component: 'SalesElectronicCouponAddForm' },
        },
        SalesElectronicCouponAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'SalesElectronicCouponEntity',
            labelCol: 4,
            controlCol: 20,
            style: { width: 1000 },
            fields: [
                { property: 'baseInfo.salesChannels' },
                { property: 'baseInfo.subsites' },
                { property: 'baseInfo.coupon' },
                { property: 'baseInfo.name' },
                { property: 'baseInfo.salesPriceAndCouponPackagePrices' },
                { property: 'baseInfo.marketPrice' },
                { property: 'baseInfo.stock' },
                { property: 'baseInfo.pictures' },
                {
                    property: 'baseInfo.marketingSchedule',
                },
                {
                    property: 'settingInfo.afterSales',
                },
                {
                    property: 'settingInfo.saleStatus',
                },
                {
                    property: 'settingInfo.saleTime',
                    visible: (values: any) => get(values, 'settingInfo.saleStatus') === 'NO',
                },
                {
                    property: 'settingInfo.couponExpiredAutoShelfOff',
                },
                { property: 'settingInfo.receiveLimit' },
                {
                    property: 'settingInfo.receiveLimitCrowds',
                    visible: (values: any) =>
                        get(values, 'settingInfo.receiveLimit') === ReceiveLimitEnum.CROWD_LIMIT,
                },
                {
                    property: 'settingInfo.couponExpiredAutoShelfOff',
                },
                {
                    property: 'settingInfo.limitQuantity',
                },
                {
                    property: 'settingInfo.virtualCategoryIds',
                },
                {
                    property: 'settingInfo.limitStock',
                },
                {
                    property: 'settingInfo.initSalesVolume',
                },
                {
                    property: 'settingInfo.mobileDetails',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const prices = get(entity, 'baseInfo.salesPriceAndCouponPackagePrices', {
                    salesPrice: undefined,
                    couponPackagePrices: [],
                });
                const coupon = get(entity, 'baseInfo.coupon[0]', {});
                if (!prices.salesPrice || Number(prices.salesPrice) === 0) {
                    AntMessage.warning(language.getText('inputPrice'));
                    return false;
                }
                if (coupon.type === 'PACKAGE' && prices.couponPackagePrices.length > 0) {
                    const isOk = prices.couponPackagePrices.some((item: any) => {
                        return isUndefined(item.apportionPrice);
                    });
                    if (isOk) {
                        AntMessage.warning(language.getText('selectSharePrice'));
                        return false;
                    }
                }

                const stock = get(entity, 'baseInfo.stock', {}) || 0;
                const limitStock = get(entity, 'settingInfo.limitStock', {}) || 0;
                if (Number(stock) < Number(limitStock)) {
                    AntMessage.warning(language.getText('stockRule'));
                    return false;
                }
                const receiveLimit = entity.settingInfo.receiveLimit;
                if (receiveLimit === ReceiveLimitEnum.CROWD_LIMIT) {
                    const receiveLimitCrowds = entity.settingInfo.receiveLimitCrowds;
                    if (!receiveLimitCrowds || receiveLimitCrowds.length === 0) {
                        AntMessage.warning(language.getText('selectApplyPeople'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        SalesElectronicCouponEditPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'SalesElectronicCouponEditForm' }],
        },
        SalesElectronicCouponEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'SalesElectronicCouponEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            style: { width: 1000 },
            fields: [
                {
                    property: 'baseInfo.salesChannels',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'baseInfo.subsites',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'baseInfo.coupon',
                    controlConfig: {
                        disabled: true,
                    },
                },
                { property: 'baseInfo.name' },
                {
                    property: 'baseInfo.salesPriceAndCouponPackagePrices',
                },
                { property: 'baseInfo.marketPrice' },
                { property: 'baseInfo.stock' },
                { property: 'baseInfo.pictures' },
                {
                    property: 'baseInfo.marketingSchedule',
                },
                {
                    property: 'settingInfo.afterSales',
                    controlConfig: {
                        seat: 'row.baseInfo.coupon',
                        isEditForm: true,
                    },
                },
                {
                    property: 'settingInfo.saleStatus',
                },
                {
                    property: 'settingInfo.saleTime',
                    visible: (values: any) => get(values, 'settingInfo.saleStatus') === 'NO',
                },
                {
                    property: 'settingInfo.couponExpiredAutoShelfOff',
                },
                {
                    property: 'settingInfo.receiveLimit',
                    controlConfig: {
                        disabled: true,
                        mode: 'horizontal',
                        setOptions: [
                            {
                                value: ReceiveLimitEnum.NO_LIMIT,
                                name: '<<allCanBuy>>',
                            },
                            {
                                value: ReceiveLimitEnum.CROWD_LIMIT,
                                name: '<<specifyBuy>>',
                            },
                        ],
                    },
                },
                {
                    property: 'settingInfo.receiveLimitCrowds',
                    visible: (values: any) =>
                        get(values, 'settingInfo.receiveLimit') === ReceiveLimitEnum.CROWD_LIMIT,
                },
                {
                    property: 'settingInfo.limitQuantity',
                },
                {
                    property: 'settingInfo.virtualCategoryIds',
                },
                {
                    property: 'settingInfo.limitStock',
                },
                {
                    property: 'settingInfo.initSalesVolume',
                },
                {
                    property: 'settingInfo.mobileDetails',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const prices = get(entity, 'baseInfo.salesPriceAndCouponPackagePrices', {
                    salesPrice: undefined,
                    couponPackagePrices: [],
                });
                const coupon = get(entity, 'baseInfo.coupon[0]', {});
                if (!prices.salesPrice || Number(prices.salesPrice) === 0) {
                    AntMessage.warning(language.getText('inputPrice'));
                    return false;
                }
                if (coupon.type === 'PACKAGE' && prices.couponPackagePrices.length > 0) {
                    const isOk = prices.couponPackagePrices.some((item: any) => {
                        return isUndefined(item.apportionPrice);
                    });
                    if (isOk) {
                        AntMessage.warning(language.getText('selectSharePrice'));
                        return false;
                    }
                }
                const stock = get(entity, 'baseInfo.stock');
                const limitStock = get(entity, 'settingInfo.limitStock');
                if (Number(stock) < Number(limitStock)) {
                    AntMessage.warning(language.getText('stockRule'));
                    return false;
                }
                const receiveLimit = entity.settingInfo.receiveLimit;
                if (receiveLimit === ReceiveLimitEnum.CROWD_LIMIT) {
                    const receiveLimitCrowds = entity.settingInfo.receiveLimitCrowds;
                    if (!receiveLimitCrowds || receiveLimitCrowds.length === 0) {
                        AntMessage.warning(language.getText('selectApplyPeople'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        SalesElectronicCouponCopyPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'SalesElectronicCouponEntity',
            loaderType: 'get',
            items: [{ component: 'SalesElectronicCouponCopyFormView' }],
        },

        SalesElectronicCouponLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'SalesElectronicLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                    apiPath: '/admin/virtual_products/audit_logs?goodsId={id}&salesChannel=DOUYIN',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/sales-electronic-coupon',
                    component: 'SalesElectronicCouponView',
                    breadcrumbName: '<<virtualProductConf>>',
                    privilege: {
                        path: 'virtualProductConf',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'SalesElectronicCouponAddPage',
                            breadcrumbName: '<<addVirtualProductConf>>',
                        },
                        {
                            path: '/edit/:productId/:id',
                            component: 'SalesElectronicCouponEditPage',
                            breadcrumbName: '<<editVirtualProductConf>>',
                        },
                        {
                            path: '/copy/:productId/:id',
                            component: 'SalesElectronicCouponCopyPage',
                            breadcrumbName: '<<copyVirtualProductConf>>',
                        },
                        {
                            path: '/logs/:id',
                            component: 'SalesElectronicCouponLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        {
                            path: '/',
                            component: 'SalesElectronicCouponPage',
                        },
                    ],
                },
            ],
        },
    ],
};
