export const imgMap = [
    './images/avatar/avatar_1.png',
    './images/avatar/avatar_2.png',
    './images/avatar/avatar_3.png',
    './images/avatar/avatar_4.png',
    './images/avatar/avatar_5.png',
    './images/avatar/avatar_6.png',
    './images/avatar/avatar_7.png',
    './images/avatar/avatar_8.png',
    './images/avatar/avatar_9.png',
    './images/avatar/avatar_10.png',
    './images/avatar/avatar_11.png',
    './images/avatar/avatar_12.png',
];
