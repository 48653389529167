// import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { api, errorHandle } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

import { EntityButtonProps } from '../../../components';
import isEmpty from 'lodash/isEmpty';

export enum OperationResults {
    /**
     * 成功
     */
    SUCCESS = 'SUCCESS',
    /**
     * 失败
     */
    FAIL = 'FAIL',
    /**
     * 部分成功
     */
    SOME_SUCCESS = 'SOME_SUCCESS',
}

export const OPERATION_RESULTS_OPYIONS = [
    {
        id: OperationResults.SUCCESS,
        name: '<<success>>',
    },
    {
        id: OperationResults.FAIL,
        name: '<<sb_2>>',
    },
    {
        id: OperationResults.SOME_SUCCESS,
        name: '<<bfcg>>',
    },
];

export const config: Config = {
    entities: {
        LotteryMaintainEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OPERATION-TOOL`,
            apiPath: '/loader/admin/presale_sign_up/activity',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<sxmd_1>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
            },
            properties: {
                id: {
                    type: 'number',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                activeTime: {
                    type: 'string',
                    displayName: '<<acitivityTime>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<activityMerchant>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                importRegistrationNum: {
                    type: 'string',
                    displayName: '<<drbmrs>>',
                },
            },
        },
        ApplyMessageEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OPERATION-TOOL`,
            apiPath: '/loader/admin/presale_sign_up/sign_up',
            filters: {
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                subsiteId: {
                    type: 'string.options.select',
                    displayName: '<<bmmd>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                subsiteCode: {
                    type: 'string',
                    displayName: '<<subsiteCode>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<bmmd>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<bmsj>>',
                },
                importTime: {
                    type: 'string',
                    displayName: '<<drsj_1>>',
                },
            },
        },
        ApplyMessageDownloadEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OPERATION-TOOL`,
            apiPath: '/loader/admin/presale_sign_up/sign_up/import_record',
            filters: {
                creatorName: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<czsj>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                createUserName: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<czsj>>',
                },
                optResult: {
                    type: 'string.options.select',
                    displayName: '<<czjg>>',
                    options: OPERATION_RESULTS_OPYIONS,
                },
                importResult: {
                    type: 'string',
                    displayName: '<<importResult>>',
                },
            },
        },
    },
    components: {
        LotteryMaintainView: {
            component: 'Viewport',
        },
        LotteryMaintainPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'LotteryMaintainEntity',
            items: [
                {
                    component: 'LotteryMaintainFilter',
                },
                {
                    component: 'LotteryMaintainTable',
                },
            ],
        },
        LotteryMaintainFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            className: 'mb24',
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsryhqlbmc>>',
                    },
                },
                {
                    property: 'subsiteId',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        LotteryMaintainTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'activeTime',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            minWidth: '180px',
                        },
                    },
                },
                {
                    property: 'subsites',
                    width: 200,
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '250px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
                {
                    property: 'importRegistrationNum',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                items: [
                    {
                        text: '<<bmgl>>',
                        type: 'link',
                        path: '/lottery-maintain/management/{{row.id}}',
                    },
                ],
            },
        },
        LotteryMaintainManagementPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'Tabs',
                    type: 'card',
                    cache: false,
                    animated: false,
                    items: [
                        {
                            title: '<<bmxx>>',
                            content: {
                                component: 'ApplyMessageManagementForm',
                            },
                        },
                        {
                            title: '<<importRecord>>',
                            content: {
                                component: 'ApplyMessageDownload',
                            },
                        },
                    ],
                },
            ],
        },
        ApplyMessageManagementForm: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'ApplyMessageEntity',
            items: [
                {
                    component: 'ApplyMessageManagementFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    style: {
                        paddingTop: 10,
                        paddingBottom: 10,
                    },
                    items: [
                        {
                            component: 'GridLayout',
                            style: {
                                display: 'flex',
                            },
                            items: [
                                {
                                    component: 'ImportTable',
                                    props: {
                                        triggerType: 'primary',
                                        triggerText: '<<drbmxx>>',
                                        triggerIcon: 'plus',
                                        controlDownloadTemplate: {
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OPERATION-TOOL`,
                                            apiPath: `/admin/presale_sign_up/template.xlsx`,
                                        },
                                        controlHandles: [
                                            {
                                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OPERATION-TOOL`,
                                                apiPath:
                                                    '/admin/presale_sign_up/:id/sign_up/import',
                                                params: {
                                                    importType: 'ADD',
                                                },
                                                type: 'primary',
                                                text: '<<xzdr>>',
                                            },
                                        ],
                                    },
                                    span: 4,
                                },
                                {
                                    component: 'EntityButton',
                                    props: {
                                        type: 'primary',
                                        children: '<<exportSearchResult>>',
                                        style: {
                                            float: 'right',
                                            width: 110,
                                            marginBottom: 10,
                                            marginLeft: 10,
                                        },
                                        onClick: (_: any, entityButtonProps: EntityButtonProps) => {
                                            const params: any = Object.assign(
                                                {},
                                                entityButtonProps.entity.params,
                                                entityButtonProps.entity.filters
                                            );

                                            if (!isEmpty(params.subsites)) {
                                                params.subsiteId = params.subsites;
                                                delete params.subsites;
                                            }

                                            const config = {
                                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OPERATION-TOOL`,
                                                apiPath: '/admin/presale_sign_up/sign_up/export',
                                            };
                                            api.download(params, config).catch(errorHandle);
                                        },
                                    },
                                    span: 4,
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'ApplyMessageManagementTable',
                },
            ],
        },
        ApplyMessageManagementFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'mobile',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsrsjh>>',
                    },
                },
                {
                    property: 'subsiteId',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        ApplyMessageManagementTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'mobile',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'subsiteCode',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'subsiteName',
                    width: 200,
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'importTime',
                },
            ],
        },
        ApplyMessageDownload: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'ApplyMessageDownloadEntity',
            items: [
                {
                    component: 'ApplyMessageDownloadFilter',
                },
                {
                    component: 'ApplyMessageDownloadTable',
                },
            ],
        },
        ApplyMessageDownloadFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'creatorName',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsrczr>>',
                    },
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        ApplyMessageDownloadTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'createUserName',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'createTime',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'optResult',
                },
                {
                    property: 'importResult',
                    width: 200,
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                items: [
                    {
                        type: 'component',
                        component: 'ExportDataButtonFail',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OPERATION-TOOL`,
                            apiPath:
                                '/admin/presale_sign_up/sign_up/import_record/fail/export/{{row.id}}',
                            text: '<<dcsbqd>>',
                        },
                    },
                    {
                        type: 'component',
                        component: 'ExportDataButton',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OPERATION-TOOL`,
                            apiPath:
                                '/admin/presale_sign_up/sign_up/import_record/all/export/{{row.id}}',
                            text: '<<qbdc>>',
                        },
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/lottery-maintain',
                    component: 'LotteryMaintainView',
                    breadcrumbName: '<<lotteryMaintain>>',
                    privilege: {
                        path: 'marketOperation',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/management/:id',
                            component: 'LotteryMaintainManagementPage',
                            breadcrumbName: '<<bmgl>>',
                        },
                        {
                            path: '/',
                            component: 'LotteryMaintainPage',
                        },
                    ],
                },
            ],
        },
    ],
};
