import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;
export const DepositOrderLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`;
        config.apiPath = '/admin/deposit_orders';
        if (data.status === 'ALL') {
            delete data.status;
        }
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        if (!isEmpty(data.amountRange)) {
            data.startOrderAmount = data.amountRange.start ? data.amountRange.start : undefined;
            data.endOrderAmount = data.amountRange.end ? data.amountRange.end : undefined;
            delete data.amountRange;
        }
        return await api.get(data, config).then((res: any) => {
            res.result = res.result.map((item: any) => {
                return {
                    ...item,
                    subsiteName: item.subsite.name,
                    merchantName: item.merchant.name,
                    merchantBigCode: item.items[0].merchantBigCode,
                    mobile: item.memberInfo.mobile,
                    canRefund:
                        item.serviceApplyInfo && item.serviceApplyInfo.serviceable ? true : false,
                    guideName: item.guideInfo.name,
                    merchantTradeNo:
                        item.paymentRecords && item.paymentRecords.length > 0
                            ? item.paymentRecords[0].merchantTradeNo
                            : '',
                };
            });
            return res;
        });
    },
};
