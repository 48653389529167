import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Card, Icon, Popover } from 'antd';
import { services } from '@comall-backend-builder/core';

import './index.less';

const api = services.api;

interface CouponData {
    /**
     * 名称
     */
    name: string;
    /**
     * 礼包发放数量
     */
    quantity: number;
    /**
     * 总发放张数
     */
    number: number;
    /**
     * 总核销张数
     */
    consumeNumber: number;
    /**
     * 发放优惠券数据
     */
    bindCoupons: Array<DetailInfo>;
    /**
     * 核销优惠券数据
     */
    consumeCoupons: Array<DetailInfo>;
}
interface DetailInfo {
    name: string;
    quantity: string;
}

class CouponPackageRulesReport extends PureComponent<any, { couponData: CouponData | undefined }> {
    state = {
        couponData: {
            name: '',
            quantity: 0,
            number: 0,
            consumeNumber: 0,
            bindCoupons: [],
            consumeCoupons: [],
        },
    };
    componentDidMount() {
        const { id } = this.props.params;
        const apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        const apiPath = '/admin/coupon_package_rules/:id/operational_data';
        api.get({ id }, { apiRoot: apiRoot, apiPath: apiPath }).then((res: any) => {
            if (res) {
                this.setState({
                    couponData: res,
                });
            }
        });
    }

    render() {
        const { couponData } = this.state;
        const operateTips = (
            <div>
                {services.language.getText('yysj_1')}&nbsp;
                <Popover
                    placement={'right'}
                    content={
                        <div style={{ maxWidth: 300 }}>{services.language.getText('lbffsl')}</div>
                    }
                >
                    <Icon type="question-circle-o" />
                </Popover>
            </div>
        );
        const grantTips = (
            <div>
                {services.language.getText('yhqffsj')} &nbsp;
                <Popover
                    placement={'right'}
                    content={
                        <div style={{ maxWidth: 400 }}>
                            {services.language.getText('zglbzdgqgzsffdsl')}
                        </div>
                    }
                >
                    <Icon type="question-circle-o" />
                </Popover>
            </div>
        );
        return (
            <Fragment>
                <div className="coupon-package-rules-report">
                    {!!couponData ? (
                        <div>
                            <div className="coupon-package-rules-report-header">
                                <span className="coupon-package-rules-report-activity-name">
                                    {couponData.name}
                                </span>
                                <span className="coupon-package-rules-report-activity-tips">
                                    {services.language.getText('sjmgzdgx')}
                                </span>
                            </div>
                            <Card title={operateTips} type="inner" style={{ marginBottom: 32 }}>
                                <div className="coupon-package-rules-report-wrap">
                                    <div className="coupon-package-rules-report-item coupon-package-rules-report-detail-item">
                                        <div className="coupon-package-rules-report-item-title">
                                            {services.language.getText('lbffsl')}
                                        </div>
                                        <div className="coupon-package-rules-report-item-content">
                                            {couponData.quantity}
                                        </div>
                                    </div>
                                    <div className="coupon-package-rules-report-item coupon-package-rules-report-detail-item">
                                        <div className="coupon-package-rules-report-item-title">
                                            {services.language.getText('zffzs')}
                                        </div>
                                        <div className="coupon-package-rules-report-item-content">
                                            {couponData.number}
                                        </div>
                                    </div>
                                    <div className="coupon-package-rules-report-item coupon-package-rules-report-detail-item">
                                        <div className="coupon-package-rules-report-item-title">
                                            {services.language.getText('zhxzs')}
                                        </div>
                                        <div className="coupon-package-rules-report-item-content">
                                            {couponData.consumeNumber}
                                        </div>
                                    </div>
                                </div>
                            </Card>
                            <Card title={grantTips} type="inner" style={{ marginBottom: 32 }}>
                                <div className="coupon-package-rules-report-wrap">
                                    {!!couponData.bindCoupons &&
                                        couponData.bindCoupons.map((item: DetailInfo, idx) => (
                                            <div
                                                className="coupon-package-rules-report-item coupon-package-rules-report-detail-item"
                                                key={`${idx}--bind-coupon`}
                                            >
                                                <div className="coupon-package-rules-report-item-title">
                                                    {item.name}
                                                </div>
                                                <div className="coupon-package-rules-report-item-content">
                                                    {item.quantity}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </Card>
                            <Card
                                title={services.language.getText('yhqhxsj')}
                                type="inner"
                                style={{ marginBottom: 32 }}
                            >
                                <div className="coupon-package-rules-report-wrap">
                                    {!!couponData.consumeCoupons &&
                                        couponData.consumeCoupons.map((item: DetailInfo, idx) => (
                                            <div
                                                className="coupon-package-rules-report-item coupon-package-rules-report-detail-item"
                                                key={`${idx}--consume-coupon`}
                                            >
                                                <div className="coupon-package-rules-report-item-title">
                                                    {item.name}
                                                </div>
                                                <div className="coupon-package-rules-report-item-content">
                                                    {item.quantity}
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </Card>
                        </div>
                    ) : (
                        ''
                    )}
                </div>
            </Fragment>
        );
    }
}

function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
    };
}

export const CouponPackageRulesReportView = connect(mapStateToProps)(CouponPackageRulesReport);
