import React, { PureComponent } from 'react';
import { Button, Form, Input, Select, Table } from 'antd';
import { services } from '@comall-backend-builder/core';
import { api } from '@comall-backend-builder/core/lib/services';
import { getThirdRequestLogListData, ThirdRequestLogItem, ThirdRequestLogType } from './common';
import { cloneDeep, find } from 'lodash';
import './index.less';
import { InvoiceRequestLogButtons } from '../components/invoice-request-log-button';

export interface ThirdRequestLogFormState {
    currentItem: ThirdRequestLogItem;
    data: Array<any>;
}

const language = services.language;

/**
 * 三方日志（后续会逐渐添加其它类型的日志查询）
 */
export class ThirdRequestLogForm extends PureComponent<any, ThirdRequestLogFormState> {
    constructor(props: any) {
        super(props);
        const thirdRequestLogListData = getThirdRequestLogListData();
        this.state = {
            currentItem: thirdRequestLogListData[0],
            data: [],
        };
    }

    onChangeCurrentType = (val: string) => {
        const thirdRequestLogListData = getThirdRequestLogListData();
        const item: any = find(thirdRequestLogListData, {
            type: val,
        });
        if (item) {
            this.setState({
                currentItem: item,
            });
        }
    };

    renderFilter = () => {
        const { currentItem } = this.state;
        const thirdRequestLogListData = getThirdRequestLogListData();
        return (
            <div>
                <Form layout="inline">
                    <Form.Item label={language.getText('ywlx')}>
                        <Select
                            style={{ width: '200px' }}
                            showSearch
                            placeholder={services.language.getText('selectPlease')}
                            optionFilterProp="name"
                            filterOption={(input, option: any) =>
                                (option.props.children || '')
                                    .toLowerCase()
                                    .indexOf(input.toLowerCase()) >= 0
                            }
                            value={currentItem ? currentItem.type : undefined}
                            onChange={this.onChangeCurrentType}
                        >
                            {thirdRequestLogListData &&
                                thirdRequestLogListData.map((log: any) => {
                                    return (
                                        <Select.Option key={log.type} value={log.type}>
                                            {log.typeName}
                                        </Select.Option>
                                    );
                                })}
                        </Select>
                    </Form.Item>
                    {this.renderInput()}
                    <Form.Item style={{ marginLeft: 'auto' }}>
                        <Button type="primary" shape="round" onClick={this.handleSearch}>
                            {language.getText('common.search')}
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="reset" shape="round" onClick={this.handleReset}>
                            {language.getText('common.reset')}
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        );
    };
    handleSearch = async () => {
        const { currentItem } = this.state;
        const params: any = {};
        if (currentItem.inputProps) {
            if (currentItem.inputProps.required && !currentItem.inputProps.value) {
                this.setState({
                    data: [],
                });
                return;
            }
            params[currentItem.inputProps.filed] = currentItem.inputProps.value;
        }
        const res: any = await api.get(params, {
            apiRoot: currentItem.loaderConfig.apiRoot,
            apiPath: currentItem.loaderConfig.apiPath,
        });
        if (res) {
            this.setState({
                data: res,
            });
        }
    };
    handleReset = () => {
        const newFirstItem = cloneDeep(getThirdRequestLogListData()[0]);
        if (newFirstItem.inputProps) {
            newFirstItem.inputProps.value = '';
        }
        this.setState({
            currentItem: cloneDeep(newFirstItem),
        });
    };
    renderInput = () => {
        const { currentItem } = this.state;
        if (!currentItem || !currentItem.inputProps) {
            return null;
        }
        return (
            <Form.Item label={currentItem.inputProps.name}>
                <Input
                    className="find-filter-width"
                    placeholder={currentItem.inputProps.placeholder}
                    value={currentItem.inputProps.value}
                    onInput={this.onInputValue}
                    style={{ width: '270px' }}
                />
            </Form.Item>
        );
    };
    onInputValue = (e: any) => {
        const value = e.target.value;
        const { currentItem } = this.state;
        const newCurrentItem = cloneDeep(currentItem);
        if (newCurrentItem.inputProps) {
            newCurrentItem.inputProps.value = value;
        }
        this.setState({ currentItem: newCurrentItem });
        return value;
    };

    getActionColumn = () => {
        const { currentItem } = this.state;
        if (currentItem.type === ThirdRequestLogType.INVOICE) {
            return {
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                dataIndex: 'id',
                key: 'id',
                fixed: 'right',
                width: 100,
                render: (val: any, row: any) => {
                    return (
                        <div>
                            <InvoiceRequestLogButtons row={row} onRefresh={this.handleSearch} />
                        </div>
                    );
                },
            };
        }
        return undefined;
    };
    getColumns = () => {
        const { currentItem } = this.state;
        const actionColumn = this.getActionColumn();
        const newColumns: Array<any> = cloneDeep(currentItem.tableColumns);
        if (actionColumn) {
            newColumns.push(actionColumn);
        }
        return newColumns;
    };
    renderTable = () => {
        const { data } = this.state;
        if (!data) {
            return null;
        }
        const columns = this.getColumns();
        const tableProps: any = {
            columns: columns,
            dataSource: data,
            scroll: { x: true },
            style: {
                margin: '20px 0 0 0',
                width: '100%',
            },
            pagination: false,
        };
        return <Table {...tableProps}></Table>;
    };
    render() {
        return (
            <div className="third-request-log-form">
                {this.renderFilter()}
                {this.renderTable()}
            </div>
        );
    }
}
