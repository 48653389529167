import React, { Fragment, PureComponent } from 'react';
import { Button } from '@comall-backend-builder/components-basis';
import { api, errorHandle } from '@comall-backend-builder/core/lib/services';
import { Modal, Checkbox, message as AntMessage } from 'antd';
import { ForwardDataCenterModal } from '../../../services';
import './index.less';

export class ExportBillButton extends PureComponent<any> {
    state = { visible: false, innerTypes: [] };

    openExportModal = () => {
        this.setState({ visible: true });
    };
    closeExportModal = () => {
        this.setState({ visible: false, innerTypes: [] });
    };
    exportBill = () => {
        const { innerTypes } = this.state;
        if (!innerTypes || innerTypes.length === 0) {
            AntMessage.warning('请选择要导出的报表');
            return;
        }
        const { entity } = this.props;
        const params: any = Object.assign({}, entity.params, entity.filters);

        if (params.dateRange) {
            params.startDate = params.dateRange.start;
            params.endDate = params.dateRange.end;
            delete params.dateRange;
        }
        if (!params.dateRange) {
            delete params.dateRange;
        }
        params.innerTypes = innerTypes;

        params.subsiteId =
            params.subsiteId && params.subsiteId.id ? params.subsiteId.id : params.subsiteId;
        params.merchantId =
            params.merchantId && params.merchantId.id ? params.merchantId.id : params.merchantId;

        const config = {
            apiRoot: this.props.params.apiRoot,
            apiPath: this.props.params.apiPath,
        };

        api.post(params, config)
            .then(() => {
                this.setState({ visible: false });
                ForwardDataCenterModal();
            })
            .catch((error) => {
                errorHandle(error);
            });
    };
    changeInnerTypes = (innerTypes: any) => {
        this.setState({ innerTypes });
    };
    render() {
        const { visible, innerTypes } = this.state;

        return (
            <Fragment>
                <div className="export-bill-button">
                    <Button text="导出" type="primary" onClick={this.openExportModal} />
                    <div className="export-tip">每日04:00更新数据～</div>
                </div>
                <Modal
                    visible={visible}
                    width={500}
                    title="选择要导出的报表"
                    onOk={this.exportBill}
                    onCancel={this.closeExportModal}
                >
                    <div className="export-items">
                        <Checkbox.Group value={innerTypes} onChange={this.changeInnerTypes}>
                            <Checkbox value="SUMMARY_FILE_NAME">汇总表</Checkbox>
                            <Checkbox value="ORDER_FILE_NAME">订单总表</Checkbox>
                            <Checkbox value="PAYMENT_RECORD_FILE_NAME">支付明细表</Checkbox>
                            <Checkbox value="ORDER_ITEM_FILE_NAME">商品明细表</Checkbox>
                        </Checkbox.Group>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}
