import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import { ActivityBlackImportButton } from '../../../components';

import './index.less';
/**
 * 抽奖码活动列表用户白名单导入及报表下载
 */
export class ActivityBlackImportMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        return (
            <div>
                <ActivityBlackImportButton {...displayInfo}></ActivityBlackImportButton>
            </div>
        );
    }
}
