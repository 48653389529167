import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;
export const couponOrderLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = '/admin/orders';
        const { id } = data;
        if (!id) {
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (data.orderType === 'ALL') {
                delete data.orderType;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
            if (!isEmpty(data.subsites)) {
                data.subsiteId = data.subsites;
                delete data.subsites;
            }
            if (!isEmpty(data.payTimeRange)) {
                data.paidStartTime = data.payTimeRange.start + ' 00:00:00';
                data.paidEndTime = data.payTimeRange.end + ' 23:59:59';
                delete data.payTimeRange;
            }
        } else {
            config.apiPath = `/admin/orders/${id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (!id) {
                res.result = res.result.map((item: any) => {
                    let couponNos: any = [];
                    if (item.couponListVos && item.couponListVos.length) {
                        item.couponListVos.forEach((coupon: any, index: number) => {
                            let name = '';
                            if (coupon.couponNos && coupon.couponNos.length > 0) {
                                name = coupon.couponNos[0].couponNo;
                            }
                            if (coupon.couponType && coupon.couponType === 'BALANCE') {
                                name = `面值:${coupon.amount}`;
                            }
                            couponNos.push({
                                id: index,
                                name: name,
                                values: coupon.couponNos,
                            });
                        });
                    }

                    return {
                        ...item,
                        couponName: item.couponListVos,
                        couponNo: item.couponListVos,
                        couponNos: couponNos,
                        name: couponNos.length ? couponNos[0].name : '',
                    };
                });
            }
            return res;
        });
    },
};

export const groupBuyingCouponOrderLoader = {
    get: async function(data: { orderId: string }): Promise<any> {
        const apiPath = `/admin/group_buying_rules/by_order_id`;
        const apiRoot = `${ENV.AUTH_API_ROOT}/GROUP-BUYING`;
        return await api.get(data, { apiPath, apiRoot });
    },
};

export const couponRechargeRecordsLoader = {
    get: async function(data: { orderId: string }): Promise<any> {
        const apiPath = `/admin/coupon_recharge_records`;
        const apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON/`;
        return await api.get(data, { apiPath, apiRoot });
    },
};
