import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const ExpressCompanyLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`;
        config.apiPath = '/admin/express_companies';
        if (data.deliveryMode === 'ALL') {
            delete data.deliveryMode;
        }
        return await api.get(data, config).then((res: any) => {
            res.result = res.result.map((item: any) => ({
                ...item,
                companyId: item.id,
            }));
            return res;
        });
    },
};
