import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { GoldClearRule } from './gold-clear-rule';

export class ObjectGoldClearRuleMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <GoldClearRule {...controlInfo} />;
    }
}
