import React, { PureComponent } from 'react';
import { Popover as AntPopover, message as AntMessage, Button as AntButton } from 'antd';
import { services } from '@comall-backend-builder/core';
import QRCode from 'qrcode.react';

import './index.less';
import { isObject, isArray } from 'lodash';

const language = services.language;
const tenantDomain = encodeURIComponent(ENV.TENANT_DOMAIN || window.location.origin);

/**
 * 可视化页面预览
 */
export class PagePreviewButton extends PureComponent<any, any> {
    static defaultProps = {
        extraParams: [],
    };
    copyLink = (url: any) => {
        return function() {
            let input = document.createElement('input');
            input.value = url;
            document.body.appendChild(input);
            input.select();
            if (document.execCommand('Copy')) {
                AntMessage.success(services.language.getText('copySuccess'));
            } else {
                AntMessage.success(services.language.getText('copyError'));
            }
            document.body.removeChild(input);
        };
    };
    formatParams = (data: any) => {
        if (isObject(data) || isArray(data)) {
            return JSON.stringify(data);
        } else {
            return data;
        }
    };
    renderContent = () => {
        const { extraParams } = this.props;
        const { type, id, lastModifyTime, subsites } = this.props.row;
        const time = (lastModifyTime + '').replace(' ', '');
        let url =
            ENV.DESIGN_CLIENT_URL +
            `?page=WYSIWG&mode=preview&type=${type}&id=${id}&rand=${time}&tenant=${tenantDomain}`;
        if (subsites && subsites.length > 0) {
            url = url + `&subsites=${JSON.stringify(subsites.slice(0, 1))}`;
        }
        if (extraParams.length > 0) {
            extraParams.forEach((item: string) => {
                const param = this.props.row[item];
                if (param) {
                    url = url + `&${item}=${this.formatParams(param)}`;
                }
            });
        }
        return (
            <div className="preview-content">
                <QRCode className="qr-code" value={url} />
                <p className="info">{language.getText('mobileScanPreview')}</p>
                <p className="info">
                    {language.getText('orClick')}
                    <AntButton
                        type="link"
                        style={{ padding: 0 }}
                        className="span-link"
                        onClick={this.copyLink(url)}
                    >
                        {language.getText('copyLink')}
                    </AntButton>
                    {language.getText('onMobileOpen')}
                </p>
            </div>
        );
    };
    render() {
        return (
            <AntPopover content={this.renderContent()}>
                <span className="table-action-column-item span-link ">
                    {language.getText('components.PageManage.preview')}
                </span>
            </AntPopover>
        );
    }
}
