import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import { StoredValueCardMenus } from './stored-value-card-menus';

export class ArrayStoredValueCardMenus extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <StoredValueCardMenus {...displayInfo} />;
    }
}
