import { builder } from '@comall-backend-builder/core';
import { ParkingInvoiceConfigsLoader } from './parking-invoice-configs-loader';
import { ParkingInvoicesLoader } from './parking-invoices-loader';
import { NormalInvoiceConfigsLoader } from './normal-invoice-configs-loader';
import { NormalInvoiceTypeLoader } from './normal-invoice-type-loader';
import { ReceiptInvoiceConfigsLoader } from './receipt-invoice-configs-loader';
import { MerchantsLoader } from './merchants-loader';
import { ParkingInvoicesLogsLoader } from './parking-invoices-logs-loader';
import { StoredValueCardInvoiceConfigsLoader } from './stored-value-card-invoice-configs-loader';

builder.registerLoader('/admin/parking_invoice_configs', ParkingInvoiceConfigsLoader);
builder.registerLoader('/admin/parking_invoices', ParkingInvoicesLoader);
builder.registerLoader('/admin/parking_invoices_logs', ParkingInvoicesLogsLoader);
builder.registerLoader('/admin/normal_invoice_configs', NormalInvoiceConfigsLoader);
builder.registerLoader('/admin/normal_invoice_type', NormalInvoiceTypeLoader);
builder.registerLoader('/admin/receipt_invoice_configs', ReceiptInvoiceConfigsLoader);
builder.registerLoader(
    '/admin/stored_value_card_invoice_configs',
    StoredValueCardInvoiceConfigsLoader
);
builder.registerLoader('/admin/merchants', MerchantsLoader);
