import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
const api = services.api;
export const DeliverySubsitesMerchantsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/subsites/mine/merchants';
        data.isContainsSelfMerchant = false;
        return await api.get(data, config).then((res: any) => {
            let result = res.map((item: any) => {
                return {
                    id: item.merchantId,
                    name: item.merchantName,
                };
            });
            return result;
        });
    },
};
