import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { forEach, get } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

export const config: Config = {
    entities: {
        CombineGoodsEntity: {
            apiPath: '/loader/admin/combine-goods',
            properties: {
                id: {
                    type: 'string',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<cobinName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<cobinNameRequired>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<cobinNameRule>>',
                                maxLength: 60,
                            },
                        },
                        code: {
                            type: 'string.tip',
                            displayName: '<<combinCode>>',
                            controlConfig: {
                                placeholder: '<<inputPlease>>',
                                maxLength: 20,
                                style: {
                                    width: 200,
                                },
                                addonbefore: 'zhsp',
                                addonafter: '',
                            },
                        },
                        categories: {
                            type: 'array.product.categories',
                            displayName: '<<combinCategory>>',
                            defaultValue: [],
                            rules: [{ required: true, message: '<<selectCombinCategory>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<selectPlease>>',
                            },
                        },
                        selectMode: {
                            type: 'string.options.radio',
                            displayName: '<<childProductOrigin>>',
                            options: [
                                { id: 'SUB_SITE', name: '<<subsiteProduct>>' },
                                { id: 'MERCHANT', name: '<<merchantProduct>>' },
                            ],
                            defaultValue: 'SUB_SITE',
                            rules: [{ required: true }],
                            extra: '<<combinOrigin>>',
                        },
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            defaultValue: [],
                            rules: [{ required: true, message: '<<selectCorreSubsite>>' }],
                            controlConfig: {
                                type: 'radio',
                            },
                        },
                        merchants: {
                            type: 'array.shop.selector',
                            displayName: '<<selectSubsites>>',
                            rules: [{ required: true, message: '<<selectCOrreMerchant>>' }],
                            controlConfig: {
                                type: 'radio',
                                fieldParams: {
                                    isContainsSelfMerchant: false,
                                },
                            },
                        },
                        brand: {
                            type: 'array.product.brands.selector',
                            displayName: '<<combinBrand>>',
                            rules: [
                                {
                                    required: true,
                                    type: 'array',
                                    message: '<<selectProductBrand>>',
                                },
                            ],
                            controlConfig: {
                                type: 'radio',
                            },
                        },
                    },
                },
                productInfo: {
                    type: 'object.subForm',
                    displayName: '<<productDetail>>',
                    properties: {
                        goods: {
                            type: 'array.product.goods.selector',
                            displayName: '<<components.Tools.selectProduct>>',
                            rules: [
                                {
                                    required: true,
                                    type: 'array',
                                    message: '<<components.Tools.productSelectRequired>>',
                                },
                            ],
                            controlConfig: {
                                type: 'checkbox',
                                pageType: 'COMBINE-GOODS',
                                filterGoodsParams: {
                                    productTypes: ['NORMAL'],
                                    shelfStatus: ['OFF_SHELF', 'SHELF', 'NONE'],
                                    auditStatus: ['SUCCESS'],
                                },
                            },
                        },
                    },
                },
                pictureInfo: {
                    type: 'object.subForm',
                    displayName: '<<imagePicConfig>>',
                    properties: {
                        isDanUsedCombineItemPictures: {
                            type: 'string.options.radio',
                            displayName: '<<isApplySinglePic>>',
                            options: [
                                { id: 'true', name: '<<yes>>' },
                                { id: 'false', name: '<<no>>' },
                            ],
                            defaultValue: 'false',
                        },
                        isDuoUsedCombineItemPictures: {
                            type: 'string.options.radio',
                            displayName: '<<isApplySinglePic>>',
                            options: [
                                { id: 'true', name: '<<yes>>' },
                                { id: 'false', name: '<<no>>' },
                            ],
                            defaultValue: 'false',
                            controlConfig: {
                                disabled: true,
                            },
                        },
                        pictures: {
                            displayName: '<<productPic>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 15,
                                multiple: false,
                                fileName: 'files',
                                uploadType: 'single',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/locations/7/upload`,
                                mode: 'picture-card',
                                maxSize: 100 * 1024,
                                uploadLimitSizeErrorMessage:
                                    '<<pictures>>{{file.name}}<<limit100MPic>>',
                            },
                            extra: '<<proposalSize>>',
                        },
                        video: {
                            displayName: '<<mainVideo>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                accept: 'video/*',
                                maxCount: 1,
                                multiple: false,
                                fileName: 'files',
                                uploadType: 'single',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/locations/7/upload`,
                                mode: 'text',
                                maxSize: 100 * 1024,
                                uploadLimitSizeErrorMessage:
                                    '<<video>>{{file.name}}<<limit100MVideo>>',
                            },
                            extra: '<<supportMp4>>',
                        },
                        isDanUsedCombineItemDetails: {
                            type: 'string.options.radio',
                            displayName: '<<isApplySingleDetail>>',
                            options: [
                                { id: 'true', name: '<<yes>>' },
                                { id: 'false', name: '<<no>>' },
                            ],
                            defaultValue: 'false',
                        },
                        isDuoUsedCombineItemDetails: {
                            type: 'string.options.radio',
                            displayName: '<<isApplySingleDetail>>',
                            options: [
                                { id: 'true', name: '<<yes>>' },
                                { id: 'false', name: '<<no>>' },
                            ],
                            defaultValue: 'false',
                            controlConfig: {
                                disabled: true,
                            },
                        },
                        details: {
                            type: 'string.richTextPlus',
                            displayName: '<<productInfo>>',
                            controlConfig: {
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            rules: [
                                {
                                    max: 20000,
                                    message: '<<productInfoRequired>>',
                                },
                            ],
                        },
                    },
                },
                settingInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherSet>>',
                    collapsed: true,
                    properties: {
                        saleTimeMode: {
                            type: 'string.options.radio',
                            displayName: '<<saleTime>>',
                            options: [
                                { id: 'YES', name: '<<buyStartSale>>' },
                                { id: 'NO', name: '<<timingStartSale>>' },
                            ],
                            defaultValue: 'YES',
                            extra: '<<messageSubscription>>',
                        },
                        saleTime: {
                            type: 'string.dateTime',
                            displayName: '<<saleDate>>',
                            rules: [{ required: true, message: '<<selectSaleDate>>' }],
                            controlConfig: {
                                placeholder: '<<selectPlease>>',
                                style: {
                                    width: 300,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        deliveryModeType: {
                            type: 'string.options.radio',
                            displayName: '<<productDeliveryManage>>',
                            defaultValue: 'UNIFIED',
                            options: [
                                {
                                    id: 'UNIFIED',
                                    name: '<<useSubsiteType>>',
                                },
                                {
                                    id: 'ASSIGN',
                                    name: '<<singleSetDeliveryType>>',
                                },
                            ],
                        },
                        subsiteMerchantDelivery: {
                            type: 'subsite.merchant.delivery',
                            displayName: '<<deliveryType>>',
                        },
                        limitMode: {
                            type: 'string.options.radio',
                            displayName: '<<limitRule>>',
                            defaultValue: 'NO',
                            options: [
                                {
                                    id: 'NO',
                                    name: '<<notLimit>>',
                                },
                                {
                                    id: 'YES',
                                    name: '<<limit>>',
                                },
                            ],
                        },
                        buyLimit: {
                            type: 'object.winningLotsNumber',
                            format: 'HH:mm',
                            displayName: '<<limitSet>>',
                            controlConfig: {
                                defaultTypeValue: 'DAY',
                                addonbefore: '',
                                addonafter: '<<jianAndPeople>>',
                                rules: [
                                    {
                                        type: 'number',
                                        min: 1,
                                    },
                                ],
                            },
                            options: [
                                { id: 'DAY', name: '<<everyDay2>>' },
                                { id: 'MONTH', name: '<<everyMonth>>' },
                                { id: 'YEAR', name: '<<everyYear>>' },
                            ],
                        },
                        afterSaleType: {
                            type: 'string.options.radio',
                            displayName: '<<afterSaleRule>>',
                            defaultValue: 'WHOLE',
                            options: [
                                { id: 'WHOLE', name: '<<notSuportSingleAfterSale>>' },
                                { id: 'PART', name: '<<suportSingleAfterSale>>' },
                            ],
                        },
                        showItems: {
                            type: 'string.options.radio',
                            displayName: '<<dispalyRule>>',
                            defaultValue: 'true',
                            options: [
                                { id: 'true', name: '<<detailDisplayCombin>>' },
                                { id: 'false', name: '<<detailHideCombin>>' },
                            ],
                        },
                        pictureExample: {
                            displayName: '<<displayRulePic>>',
                            type: 'string.pictureExampleButton',
                            controlConfig: {
                                buttonName: '<<clickView>>',
                                type: 'combineGoodsExample',
                            },
                        },
                        shelfStatus: {
                            type: 'string.options.radio',
                            displayName: '<<isUpShelf>>',
                            defaultValue: 'NONE',
                            options: [
                                { id: 'SHELF', name: '<<yes>>' },
                                { id: 'NONE', name: '<<no>>' },
                            ],
                            extra: '<<createSuccessContent>>',
                        },
                    },
                },
            },
        },
        ProductBrandsSelectorEntity: {
            apiPath: '/admin/brands',
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            properties: {
                id: {
                    type: 'string',
                    displayName: '<<brandId>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<brandName>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<brandName>>',
                },
            },
        },
    },
    components: {
        CombineGoodsView: {
            component: 'Viewport',
        },
        CombineGoodsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'CombineGoodsFind',
                },
            ],
        },
        CombineGoodsAddPage: {
            component: 'Card',
            content: { component: 'CombineGoodsAddForm' },
        },
        CombineGoodsAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'CombineGoodsEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.code' },
                { property: 'baseInfo.categories' },
                {
                    property: 'baseInfo.selectMode',
                },
                {
                    property: 'baseInfo.subsites',
                    visible: (values: any) => get(values, 'baseInfo.selectMode') === 'SUB_SITE',
                },
                {
                    property: 'baseInfo.merchants',
                    visible: (values: any) => get(values, 'baseInfo.selectMode') === 'MERCHANT',
                },
                {
                    property: 'baseInfo.brand',
                },
                {
                    property: 'productInfo.goods',
                },
                {
                    property: 'pictureInfo.isDanUsedCombineItemPictures',
                    visible: (values: any) =>
                        !get(values, 'productInfo.goods') ||
                        get(values, 'productInfo.goods').length <= 1,
                },
                {
                    property: 'pictureInfo.isDuoUsedCombineItemPictures',
                    visible: (values: any) =>
                        get(values, 'productInfo.goods') &&
                        get(values, 'productInfo.goods').length > 1,
                },
                {
                    property: 'pictureInfo.pictures',
                    visible: (values: any) => {
                        let visibleFlag = true;
                        if (
                            !get(values, 'productInfo.goods') ||
                            (get(values, 'productInfo.goods') &&
                                get(values, 'productInfo.goods').length <= 1)
                        ) {
                            visibleFlag =
                                get(values, 'pictureInfo.isDanUsedCombineItemPictures') &&
                                get(values, 'pictureInfo.isDanUsedCombineItemPictures') === 'false';
                        } else {
                            visibleFlag =
                                get(values, 'pictureInfo.isDuoUsedCombineItemPictures') &&
                                get(values, 'pictureInfo.isDuoUsedCombineItemPictures') === 'false';
                        }
                        return visibleFlag;
                    },
                },
                {
                    property: 'pictureInfo.video',
                    visible: (values: any) => {
                        let visibleFlag = true;
                        if (
                            !get(values, 'productInfo.goods') ||
                            (get(values, 'productInfo.goods') &&
                                get(values, 'productInfo.goods').length <= 1)
                        ) {
                            visibleFlag =
                                get(values, 'pictureInfo.isDanUsedCombineItemPictures') &&
                                get(values, 'pictureInfo.isDanUsedCombineItemPictures') === 'false';
                        } else {
                            visibleFlag =
                                get(values, 'pictureInfo.isDuoUsedCombineItemPictures') &&
                                get(values, 'pictureInfo.isDuoUsedCombineItemPictures') === 'false';
                        }
                        return visibleFlag;
                    },
                },
                {
                    property: 'pictureInfo.isDanUsedCombineItemDetails',
                    visible: (values: any) =>
                        !get(values, 'productInfo.goods') ||
                        get(values, 'productInfo.goods').length <= 1,
                },
                {
                    property: 'pictureInfo.isDuoUsedCombineItemDetails',
                    visible: (values: any) =>
                        get(values, 'productInfo.goods') &&
                        get(values, 'productInfo.goods').length > 1,
                },
                {
                    property: 'pictureInfo.details',
                    visible: (values: any) => {
                        let visibleFlag = true;
                        if (
                            !get(values, 'productInfo.goods') ||
                            (get(values, 'productInfo.goods') &&
                                get(values, 'productInfo.goods').length <= 1)
                        ) {
                            visibleFlag =
                                get(values, 'pictureInfo.isDanUsedCombineItemDetails') &&
                                get(values, 'pictureInfo.isDanUsedCombineItemDetails') === 'false';
                        } else {
                            visibleFlag =
                                get(values, 'pictureInfo.isDuoUsedCombineItemDetails') &&
                                get(values, 'pictureInfo.isDuoUsedCombineItemDetails') === 'false';
                        }
                        return visibleFlag;
                    },
                },
                {
                    property: 'settingInfo.saleTimeMode',
                },
                {
                    property: 'settingInfo.saleTime',
                    visible: (values: any) => get(values, 'settingInfo.saleTimeMode') === 'NO',
                },
                {
                    property: 'settingInfo.deliveryModeType',
                },
                {
                    property: 'settingInfo.subsiteMerchantDelivery',
                    visible: (values: any) =>
                        get(values, 'settingInfo.deliveryModeType') === 'ASSIGN',
                },
                {
                    property: 'settingInfo.limitMode',
                },
                {
                    property: 'settingInfo.buyLimit',
                    visible: (values: any) => get(values, 'settingInfo.limitMode') === 'YES',
                },
                {
                    property: 'settingInfo.afterSaleType',
                },
                {
                    property: 'settingInfo.showItems',
                },
                {
                    property: 'settingInfo.pictureExample',
                },
                {
                    property: 'settingInfo.shelfStatus',
                    visible: () => false,
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const goods = entity.productInfo.goods;
                let hasValidate = true;
                //1.校验商品组件数量和分摊价格是否已填写
                forEach(goods, (i) => {
                    if (!i.quantity) {
                        AntMessage.warning(language.getText('inputCombinNum'));
                        hasValidate = false;
                        return false;
                    }
                    if (i.price === undefined || i.price === null || i.price === '') {
                        AntMessage.warning(language.getText('selectSharePrice'));
                        hasValidate = false;
                        return false;
                    }
                });
                if (!hasValidate) {
                    return false;
                }
                //2.校验组合商品销售价
                let price = 0;
                goods.forEach((good: any) => {
                    if (good.price && good.quantity) {
                        price = price + good.price * good.quantity;
                    }
                });
                if (price === 0) {
                    AntMessage.warning(language.getText('combinProductNotZero'));
                    return false;
                }
                if (entity.settingInfo.saleTimeMode === 'NO' && !entity.settingInfo.saleTime) {
                    AntMessage.warning(language.getText('openSaleTimeRequired'));
                    return false;
                }
                //3.校验配送方式
                const subsiteMerchantDelivery = entity.settingInfo.subsiteMerchantDelivery;
                if (
                    entity.settingInfo.deliveryModeType === 'ASSIGN' &&
                    (!subsiteMerchantDelivery.delivery ||
                        subsiteMerchantDelivery.delivery.length === 0)
                ) {
                    AntMessage.warning(language.getText('selectDelivery'));
                    return false;
                }
                //4.校验限购数量
                if (entity.settingInfo.limitMode === 'YES' && !entity.settingInfo.buyLimit.number) {
                    AntMessage.warning(language.getText('inputLimitCount'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        CombineGoodsEditPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'CombineGoodsEditForm' }],
        },
        CombineGoodsEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'CombineGoodsEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.code' },
                { property: 'baseInfo.categories' },
                {
                    property: 'baseInfo.selectMode',
                },
                {
                    property: 'baseInfo.subsites',
                    visible: (values: any) => get(values, 'baseInfo.selectMode') === 'SUB_SITE',
                },
                {
                    property: 'baseInfo.merchants',
                    visible: (values: any) => get(values, 'baseInfo.selectMode') === 'MERCHANT',
                },
                {
                    property: 'baseInfo.brand',
                },
                {
                    property: 'productInfo.goods',
                },
                {
                    property: 'pictureInfo.isDanUsedCombineItemPictures',
                    visible: (values: any) =>
                        !get(values, 'productInfo.goods') ||
                        get(values, 'productInfo.goods').length <= 1,
                },
                {
                    property: 'pictureInfo.isDuoUsedCombineItemPictures',
                    visible: (values: any) =>
                        get(values, 'productInfo.goods') &&
                        get(values, 'productInfo.goods').length > 1,
                },
                {
                    property: 'pictureInfo.pictures',
                    visible: (values: any) => {
                        let visibleFlag = true;
                        if (
                            !get(values, 'productInfo.goods') ||
                            (get(values, 'productInfo.goods') &&
                                get(values, 'productInfo.goods').length <= 1)
                        ) {
                            visibleFlag =
                                get(values, 'pictureInfo.isDanUsedCombineItemPictures') &&
                                get(values, 'pictureInfo.isDanUsedCombineItemPictures') === 'false';
                        } else {
                            visibleFlag =
                                get(values, 'pictureInfo.isDuoUsedCombineItemPictures') &&
                                get(values, 'pictureInfo.isDuoUsedCombineItemPictures') === 'false';
                        }
                        return visibleFlag;
                    },
                },
                {
                    property: 'pictureInfo.video',
                    visible: (values: any) => {
                        let visibleFlag = true;
                        if (
                            !get(values, 'productInfo.goods') ||
                            (get(values, 'productInfo.goods') &&
                                get(values, 'productInfo.goods').length <= 1)
                        ) {
                            visibleFlag =
                                get(values, 'pictureInfo.isDanUsedCombineItemPictures') &&
                                get(values, 'pictureInfo.isDanUsedCombineItemPictures') === 'false';
                        } else {
                            visibleFlag =
                                get(values, 'pictureInfo.isDuoUsedCombineItemPictures') &&
                                get(values, 'pictureInfo.isDuoUsedCombineItemPictures') === 'false';
                        }
                        return visibleFlag;
                    },
                },
                {
                    property: 'pictureInfo.isDanUsedCombineItemDetails',
                    visible: (values: any) =>
                        !get(values, 'productInfo.goods') ||
                        get(values, 'productInfo.goods').length <= 1,
                },
                {
                    property: 'pictureInfo.isDuoUsedCombineItemDetails',
                    visible: (values: any) =>
                        get(values, 'productInfo.goods') &&
                        get(values, 'productInfo.goods').length > 1,
                },
                {
                    property: 'pictureInfo.details',
                    visible: (values: any) => {
                        let visibleFlag = true;
                        if (
                            !get(values, 'productInfo.goods') ||
                            (get(values, 'productInfo.goods') &&
                                get(values, 'productInfo.goods').length <= 1)
                        ) {
                            visibleFlag =
                                get(values, 'pictureInfo.isDanUsedCombineItemDetails') &&
                                get(values, 'pictureInfo.isDanUsedCombineItemDetails') === 'false';
                        } else {
                            visibleFlag =
                                get(values, 'pictureInfo.isDuoUsedCombineItemDetails') &&
                                get(values, 'pictureInfo.isDuoUsedCombineItemDetails') === 'false';
                        }
                        return visibleFlag;
                    },
                },
                {
                    property: 'settingInfo.saleTimeMode',
                },
                {
                    property: 'settingInfo.saleTime',
                    visible: (values: any) => get(values, 'settingInfo.saleTimeMode') === 'NO',
                },
                {
                    property: 'settingInfo.deliveryModeType',
                },
                {
                    property: 'settingInfo.subsiteMerchantDelivery',
                    visible: (values: any) =>
                        get(values, 'settingInfo.deliveryModeType') === 'ASSIGN',
                },
                {
                    property: 'settingInfo.limitMode',
                },
                {
                    property: 'settingInfo.buyLimit',
                    visible: (values: any) => get(values, 'settingInfo.limitMode') === 'YES',
                },
                {
                    property: 'settingInfo.afterSaleType',
                },
                {
                    property: 'settingInfo.showItems',
                },
                {
                    property: 'settingInfo.pictureExample',
                },
                {
                    property: 'settingInfo.shelfStatus',
                    visible: () => false,
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const goods = entity.productInfo.goods;
                let hasValidate = true;
                //1.校验商品组件数量和分摊价格是否已填写
                forEach(goods, (i) => {
                    if (!i.quantity) {
                        AntMessage.warning(language.getText('inputCombinNum'));
                        hasValidate = false;
                        return false;
                    }
                    if (i.price === undefined || i.price === null || i.price === '') {
                        AntMessage.warning(language.getText('selectSharePrice'));
                        hasValidate = false;
                        return false;
                    }
                });
                if (!hasValidate) {
                    return false;
                }
                //2.校验组合商品销售价
                let price = 0;
                goods.forEach((good: any) => {
                    if (good.price && good.quantity) {
                        price = price + good.price * good.quantity;
                    }
                });
                if (price === 0) {
                    AntMessage.warning(language.getText('combinProductNotZero'));
                    return false;
                }
                if (entity.settingInfo.saleTimeMode === 'NO' && !entity.settingInfo.saleTime) {
                    AntMessage.warning(language.getText('openSaleTimeRequired'));
                    return false;
                }
                //3.校验配送方式
                const subsiteMerchantDelivery = entity.settingInfo.subsiteMerchantDelivery;
                if (
                    entity.settingInfo.deliveryModeType === 'ASSIGN' &&
                    (!subsiteMerchantDelivery.delivery ||
                        subsiteMerchantDelivery.delivery.length === 0)
                ) {
                    AntMessage.warning(language.getText('selectDelivery'));
                    return false;
                }
                //4.校验限购数量
                if (entity.settingInfo.limitMode === 'YES' && !entity.settingInfo.buyLimit.number) {
                    AntMessage.warning(language.getText('inputLimitCount'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        CombineGoodsLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                    apiPath: '/admin/combine_goods/{id}/operation_logs',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/combine-goods',
                    component: 'CombineGoodsView',
                    breadcrumbName: '<<combineGoods>>',
                    privilege: {
                        path: 'combineGoods',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'CombineGoodsAddPage',
                            breadcrumbName: '<<addCombinProduct>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'CombineGoodsEditPage',
                            breadcrumbName: '<<editCombinProduct>>',
                        },
                        {
                            path: '/logs/:id',
                            component: 'CombineGoodsLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        {
                            path: '/',
                            component: 'CombineGoodsPage',
                        },
                    ],
                },
            ],
        },
    ],
};
