import React, { PureComponent } from 'react';
import { Table, Button, message as AntMessage, InputNumber } from 'antd';
import { cloneDeep, remove, set, find } from 'lodash';
import { Coupon } from '../../containers/select-coupon';
import { PaginationConfig } from 'antd/lib/table/interface';
import { CouponPackageApportionPrice } from '../coupon-package-apportion-price';
import { services } from '@comall-backend-builder/core';

interface CouponPackageSelectorDisplayProps {
    data: Array<Coupon>;
    onChange: (value: any) => void;
    disabled?: boolean;
    xScrollWidth?: number;
    disablePrice?: boolean;
    canChangeQuantity?: boolean;
    hideIssuedNum?: boolean;
}
interface CouponPackageSelectorDisplayState {
    selectedRows: Array<Coupon>;
    visible: boolean;
}

/**
 * 优惠券展示和内容编辑
 */
export class CouponPackageSelectorDisplay extends PureComponent<
    CouponPackageSelectorDisplayProps,
    CouponPackageSelectorDisplayState
> {
    constructor(props: CouponPackageSelectorDisplayProps) {
        super(props);
        this.state = { selectedRows: [], visible: false };
    }
    toggleModal = () => {
        const { visible } = this.state;

        this.setState({ visible: !visible });
    };
    onChangeQuantity = (value: any, record: any) => {
        const { data, onChange } = this.props;

        let newData = data.slice(0);
        let row = find(newData, { id: record.id });
        if (row) {
            row.quantity = value;
            onChange(newData);
        }
    };
    getTableConfig = () => {
        const {
            disabled,
            xScrollWidth,
            disablePrice,
            canChangeQuantity,
            hideIssuedNum,
        } = this.props;
        let tableConfig: any = {
            rowKey: 'id',
            columns: [
                {
                    title: services.language.getText('couponGiftName'),
                    dataIndex: 'name',
                    key: 'name',
                    width: 150,
                },
                {
                    title: services.language.getText('giftsxTime'),
                    dataIndex: 'startTime',
                    key: 'startTime',
                    width: 180,
                },
                {
                    title: services.language.getText('giftInvalidTime'),
                    dataIndex: 'endTime',
                    key: 'endTime',
                    width: 180,
                },
            ],
            style: {
                marginTop: 20,
            },
        };
        if (canChangeQuantity) {
            tableConfig.columns = [
                ...tableConfig.columns,
                {
                    title: services.language.getText('ffsl'),
                    dataIndex: 'quantity',
                    key: 'quantity',
                    render: (quantity: number, record: any) => {
                        return (
                            <InputNumber
                                min={1}
                                max={record.stock ? record.stock : 9999}
                                step={1}
                                precision={0}
                                value={quantity}
                                onChange={(value) => this.onChangeQuantity(value, record)}
                                disabled={disabled}
                            ></InputNumber>
                        );
                    },
                },
            ];
        }
        if (!hideIssuedNum) {
            tableConfig.columns = [
                ...tableConfig.columns,
                {
                    title: services.language.getText('yffsl'),
                    dataIndex: 'issuedNum',
                    key: 'issuedNum',
                    width: 180,
                    render: (issuedNum: any) => {
                        return <span>{issuedNum ? issuedNum : 0}</span>;
                    },
                },
            ];
        }
        if (!!disablePrice) {
            tableConfig.columns = [
                ...tableConfig.columns,
                {
                    title: services.language.getText('common.tableAction'),
                    className: 'action-column',
                    key: 'operation',
                    width: 100,
                    render: (i: any, row: any) => {
                        return (
                            <Button
                                type={'link'}
                                onClick={this.onRemove(row)}
                                style={{ padding: '0' }}
                                disabled={disabled}
                            >
                                {services.language.getText('common.delete')}
                            </Button>
                        );
                    },
                },
            ];
        } else {
            tableConfig.columns = [
                ...tableConfig.columns,
                {
                    title: services.language.getText('ftj'),
                    dataIndex: 'apportionPrice',
                    key: 'apportionPrice',
                    width: 180,
                    render: (i: any, record: any) => {
                        const flag =
                            record &&
                            record.couponInfo &&
                            record.couponInfo.some(
                                (item: any) => !!item.apportionPrice || item.apportionPrice === 0
                            );
                        return (
                            <div>
                                <label>
                                    <Button
                                        type="link"
                                        onClick={this.toggleModal}
                                        style={{ padding: '0' }}
                                    >
                                        {flag
                                            ? services.language.getText('common.modify')
                                            : services.language.getText('settings')}
                                    </Button>
                                </label>
                            </div>
                        );
                    },
                },
                {
                    title: services.language.getText('common.tableAction'),
                    className: 'action-column',
                    key: 'operation',
                    width: 100,
                    fixed: 'right',
                    render: (i: any, row: any) => {
                        return (
                            <Button
                                type={'link'}
                                onClick={this.onRemove(row)}
                                style={{ padding: '0' }}
                                disabled={disabled}
                            >
                                {services.language.getText('common.delete')}
                            </Button>
                        );
                    },
                },
            ];
        }

        if (xScrollWidth && xScrollWidth > 0) {
            tableConfig.style = {
                width: xScrollWidth,
            };
        }
        tableConfig.scroll = {
            x: true,
        };
        return tableConfig;
    };

    onRemove = (row: Coupon) => {
        const { data, onChange } = this.props;
        return () => {
            const result = cloneDeep(data);
            remove(result, (coupon) => row.id === coupon.id);
            onChange(result);
        };
    };

    handleOk = (value: any) => {
        const { onChange, data } = this.props;

        const notExist = value.some(
            (item: any) => !item.apportionPrice && item.apportionPrice !== 0
        );
        if (notExist) {
            AntMessage.error(services.language.getText('yhqlbsmsqtxftj'));
        } else {
            let newData = cloneDeep(data);
            set(newData, '[0].couponInfo', value);
            onChange && onChange(newData);
            this.setState({ visible: false });
        }
    };
    handleCancel = () => {
        this.setState({ visible: false });
    };

    render() {
        const { data } = this.props;
        const { visible } = this.state;
        const pagination = false as PaginationConfig | false;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: cloneDeep(data),
            pagination,
        };

        return (
            <div>
                <Table {...tableProps}></Table>
                <CouponPackageApportionPrice
                    data={data}
                    visible={visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    title={services.language.getText('setSharePrice')}
                />
            </div>
        );
    }
}
