import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
export const config: Config = {
    entities: {
        CouponServiceAppliesEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/couponServiceApplies',
            filters: {
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                    controlConfig: { placeholder: '<<subsiteNameRequired>>' },
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                    controlConfig: { placeholder: '<<inputOrderNumber>>' },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<xdsj>>',
                },
                orderType: {
                    type: 'string.options.select',
                    displayName: '<<ddlx>>',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'SALE_COUPON', name: '<<smyhq>>' },
                        { id: 'CREDIT_ESHOP', name: '<<creditEShop>>' },
                        { id: 'GROUP_BUYING_COUPON', name: '<<yhqptdd>>' },
                    ],
                },
                serviceApplyName: {
                    type: 'string',
                    displayName: '<<couponName>>',
                    controlConfig: { placeholder: '<<qsryhqmc>>' },
                },
                serviceApplyNo: {
                    type: 'string',
                    displayName: '<<afterSalesOrderNo>>',
                    controlConfig: { placeholder: '<<qsrshdh>>' },
                },
                applyUser: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                    controlConfig: { placeholder: '<<qsrhysjh>>' },
                },
                createTime: {
                    type: 'object.dateRangePlus',
                    displayName: '<<sqsj>>',
                },
                orderOrigin: {
                    type: 'string.options.select',
                    displayName: '<<orderChannel>>',
                    options: [
                        { id: 'WECHAT', name: '<<wxMiniP>>' },
                        { id: 'ALIPAY', name: '<<aliMiniP>>' },
                        { id: 'APP', name: 'APP' },
                        { id: 'DOUYIN', name: '<<douyinMiniP>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                subsite: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                goodsName: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                upstreamOrderId: {
                    type: 'string',
                    displayName: '<<upstreamOrderId>>',
                },
                serviceApplyNo: {
                    type: 'string',
                    displayName: '<<afterSalesOrderNo>>',
                },
                saleRuleName: {
                    type: 'string',
                    displayName: '<<tkyhq>>',
                },
                quantity: {
                    type: 'array.stringLineFeed',
                    displayName: '<<number>>',
                },
                couponNo: {
                    type: 'array.stringLineFeed',
                    displayName: '<<couponBaCode>>',
                },
                orderType: {
                    type: 'string.options.select',
                    displayName: '<<ddlx>>',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'SALE_COUPON', name: '<<smyhq>>' },
                        { id: 'CREDIT_ESHOP', name: '<<creditEShop>>' },
                        { id: 'GROUP_BUYING_COUPON', name: '<<yhqptdd>>' },
                    ],
                },
                orderOrigin: {
                    type: 'string.options.select',
                    displayName: '<<orderChannel>>',
                    options: [
                        { id: 'WECHAT', name: '<<wxMiniP>>' },
                        { id: 'ALIPAY', name: '<<aliMiniP>>' },
                        { id: 'APP', name: 'APP' },
                        { id: 'DOUYIN', name: '<<douyinMiniP>>' },
                    ],
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<auditStatus>>',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'APPLYING', name: '<<waiting>>' },
                        { id: 'CANCEL', name: '<<yqx>>' },
                        { id: 'APPLY_SUCC', name: '<<approved>>' },
                        { id: 'APPLY_FAIL', name: '<<shbtg>>' },
                    ],
                },
                entryType: {
                    type: 'string.options.select',
                    displayName: '<<shrk>>',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'MEMBER', name: '<<qtsq>>' },
                        { id: 'PLANTFORM', name: '<<htcj>>' },
                        { id: 'SYSTEM', name: '<<xtcj>>' },
                    ],
                },
                applyUser: {
                    type: 'number',
                    displayName: '<<sqr>>',
                },
                auditUser: {
                    type: 'string',
                    displayName: '<<shr_1>>',
                },
                refundStatus: {
                    type: 'string.options.select',
                    displayName: '<<tkdzt>>',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'APPLYING', name: '<<wcl>>' },
                        { id: 'CANCEL', name: '<<yqx>>' },
                        { id: 'APPLY_SUCC', name: '<<approved>>' },
                        { id: 'REFUND_SUCC', name: '<<tkcg>>' },
                        { id: 'REFUND_FAIL', name: '<<tksb>>' },
                        { id: 'REFUND_PART_SUCC', name: '<<tkbfcg>>' },
                    ],
                },
                createTime: {
                    type: 'number',
                    displayName: '<<sqsj>>',
                },
            },
        },
    },
    components: {
        CouponServiceAppliesView: {
            component: 'Viewport',
            entity: 'CouponServiceAppliesEntity',
        },
        CouponServiceAppliesPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'CouponServiceAppliesFilter',
                },
                {
                    component: 'GridLayout',
                    className: 'mt24',
                    style: {
                        marginBottom: 12,
                    },
                    items: [
                        {
                            component: 'ExportDataByFilterParamsButton',
                            params: {
                                text: '<<exportSearchResult>>',
                                style: {
                                    width: '120px',
                                },
                                apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                apiPath:
                                    '/admin/service_applies/download?order_types=SALE_COUPON,CREDIT_ESHOP,GROUP_BUYING_COUPON,COUPON_PACKAGE',
                                requestMethod: 'get',
                            },
                        },
                    ],
                },
                { component: 'CouponServiceAppliesTables' },
            ],
        },
        CouponServiceAppliesFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteName',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'orderNo',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'dateRange',
                },
                {
                    property: 'orderType',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'serviceApplyName',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'serviceApplyNo',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'applyUser',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'orderOrigin',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
            ],
        },
        CouponServiceAppliesTables: {
            component: 'Tabs',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<all>>',
                    content: {
                        component: 'CouponServiceAppliesTable',
                    },
                    params: {},
                },
                {
                    title: '<<waiting>>',
                    content: {
                        component: 'CouponServiceAppliesTable',
                    },
                    params: { status: 'APPLYING' },
                },
                {
                    title: '<<approved>>',
                    content: {
                        component: 'CouponServiceAppliesTable',
                    },
                    params: { status: 'APPLY_SUCC' },
                },
                {
                    title: '<<shbtg>>',
                    content: {
                        component: 'CouponServiceAppliesTable',
                    },
                    params: { status: 'APPLY_FAIL' },
                },
            ],
        },
        CouponServiceAppliesTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: { x: true },
                    columns: [
                        {
                            property: 'subsite',
                            width: 200,
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '200px',
                                },
                            },
                        },
                        {
                            property: 'goodsName',
                            width: 280,
                        },
                        {
                            property: 'orderNo',
                            width: 280,
                        },
                        {
                            property: 'upstreamOrderId',
                        },
                        {
                            property: 'serviceApplyNo',
                            width: 280,
                        },
                        {
                            property: 'saleRuleName',
                            width: 300,
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '300px',
                                },
                            },
                        },
                        {
                            property: 'quantity',
                            width: 150,
                            displayConfig: {
                                showField: 'count',
                            },
                        },
                        {
                            property: 'couponNo',
                            width: 300,
                            displayConfig: {
                                showField: 'couponNo',
                                displayCouponStatus: true,
                            },
                        },
                        {
                            property: 'orderType',
                            width: 150,
                            controlConfig: {
                                style: { width: 150 },
                            },
                        },
                        {
                            property: 'orderOrigin',
                        },
                        {
                            property: 'status',
                            width: 150,
                            controlConfig: {
                                style: { width: 150 },
                            },
                        },
                        {
                            property: 'entryType',
                            width: 150,
                        },
                        {
                            property: 'applyUser',
                            width: 150,
                        },
                        {
                            property: 'auditUser',
                            width: 150,
                        },
                        {
                            property: 'refundStatus',
                            width: 150,
                        },
                        {
                            width: 200,
                            property: 'createTime',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '200px',
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        fixed: 'right',
                        width: 300,
                        items: [
                            {
                                type: 'component',
                                component: 'CouponServiceAppliesApplyButton',
                            },
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                statusKey: 'row.canEdit',
                                path: '/coupon-service-applies/edit/{{row.id}}',
                            },
                            {
                                type: 'link',
                                text: '<<details>>',
                                path: '/coupon-service-applies/info/{{row.id}}/displayCouponStatus',
                            },
                        ],
                    },
                },
            ],
        },
        CouponServiceAppliesDetailPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'CouponServiceAppliesDetailFrom',
                },
            ],
        },
        CouponServiceAppliesEditPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'CouponServiceAppliesEditFrom',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/coupon-service-applies',
                    component: 'CouponServiceAppliesView',
                    breadcrumbName: '<<couponServiceApply>>',
                    privilege: {
                        path: 'couponServiceApply',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'CouponServiceAppliesEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        {
                            path: '/info/:id',
                            component: 'CouponServiceAppliesDetailPage',
                            breadcrumbName: '<<details>>',
                        },
                        { path: '/', component: 'CouponServiceAppliesPage' },
                    ],
                },
            ],
        },
    ],
};
