import React, { Component } from 'react';
import { Table, InputNumber, Button, Popconfirm } from 'antd';
import { cloneDeep, remove, find } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

interface GroupBuyingProductSelectedDisplayProps {
    data: any[];
    onChange: (data: any[]) => void;
    disabled?: boolean;
}

/**
 * 商品展示和内容编辑
 */
export class BargainGoodsSelectedDisplay extends Component<
    GroupBuyingProductSelectedDisplayProps,
    {}
> {
    state = {
        generalPrice: undefined,
        visible: false,
        settingGoods: {},
    };

    selectedRows: any[] = [];

    toggleModal = (good: any) => {
        const { visible } = this.state;
        this.setState({ visible: !visible, settingGoods: good });
    };

    getTableConfig = () => {
        const { disabled } = this.props;
        const columns: any[] = [
            {
                title: services.language.getText('components.Products.name'),
                dataIndex: 'productName',
                key: 'productName',
                width: 170,
                render: (i: string) => (
                    <span title={i} className="goods-field-name">
                        {i}
                    </span>
                ),
            },
            {
                title: services.language.getText('subsiteName'),
                dataIndex: 'subsiteName',
                key: 'subsiteName',
                width: 150,
                render: (i: string) => (
                    <span title={i} className="goods-field-name">
                        {i}
                    </span>
                ),
            },
            {
                title: services.language.getText('productCode'),
                dataIndex: 'productCode',
                key: 'productCode',
                width: 150,
                render: (i: string) => (
                    <span title={i} className="goods-field-name">
                        {i}
                    </span>
                ),
            },
            {
                title: services.language.getText('xsj'),
                dataIndex: 'salesPrice',
                key: 'salesPrice',
                width: 120,
                align: 'center',
            },
            {
                title: services.language.getText('sjkc'),
                dataIndex: 'goodsStock',
                key: 'goodsStock',
                align: 'center',
            },
            {
                title: services.language.getText('dj'),
                dataIndex: 'generalPrice',
                key: 'generalPrice',
                align: 'center',
                render: (v: number, r: any) => {
                    const salesPrice = r.salesPrice;
                    return (
                        <InputNumber
                            disabled={disabled}
                            value={v}
                            min={0.01}
                            max={salesPrice}
                            precision={2}
                            placeholder={services.language.getText('dj')}
                            onChange={(value: any) => this.changeValue('generalPrice', value, r.id)}
                        />
                    );
                },
            },
            {
                title: services.language.getText('kjzkc'),
                dataIndex: 'stock',
                key: 'stock',
                align: 'center',
                width: 150,
                render: (i: number, row: any) => {
                    const min = row.defalutStock ? row.defalutStock : 0; //只能比之前保存的值大
                    const max = window.location.href.includes('/edit') ? 999999999 : row.goodsStock;
                    return (
                        <InputNumber
                            placeholder={services.language.getText('kjzkc')}
                            value={i}
                            min={min}
                            max={max}
                            step={1}
                            precision={0}
                            onChange={(value: any) => this.changeValue('stock', value, row.id)}
                        ></InputNumber>
                    );
                },
            },
        ];

        let config: any = {
            rowKey: 'id',
            columns,
            scroll: {
                x: 1000,
                y: 500,
            },
        };
        if (!disabled) {
            config.columns.push({
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                key: 'operation',
                fixed: 'right',
                align: 'center',
                width: 100,
                render: (i: any, row: any) => {
                    return (
                        <Popconfirm
                            onConfirm={this.onRemove(row.id)}
                            title={services.language.getText('qrscztsjm')}
                            okText={language.getText('common.ok')}
                            cancelText={language.getText('common.cancel')}
                        >
                            <Button type="link">
                                {services.language.getText('common.delete')}
                            </Button>
                        </Popconfirm>
                    );
                },
            });
        }
        return config;
    };

    onRemove = (id: string) => {
        const { data, onChange } = this.props;
        return () => {
            const result = cloneDeep(data);
            remove(result, (i) => id === i.id);
            onChange(result);
        };
    };

    changeValue = (field: string, value: any, id: string) => {
        const { data, onChange } = this.props;
        const result = cloneDeep(data);
        let item = find(result, (i) => id === i.id);
        if (item) {
            item[field] = value;
            onChange(result);
        }
    };

    render() {
        const { data } = this.props;
        let tableConfig = this.getTableConfig();
        const tableProps = { ...tableConfig, dataSource: data };

        return (
            <div>
                <Table {...tableProps} pagination={false}></Table>
            </div>
        );
    }
}
