import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { find, isEmpty } from 'lodash';

const api = services.api;

export const WechatOfficialAccountsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/wechat_official_accounts';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/wechat_official_accounts/${id}`;
        } else {
            if (!isEmpty(data.subsite)) {
                data.subsiteId = data.subsite.id;
                delete data.subsite;
            }
        }
        if (id) {
            return await api.get(data, config);
        } else {
            const listResult: any = await api.get(data, config);
            if (listResult && listResult.result.length > 0) {
                const appIds = listResult.result.map((i: any) => i.appId);
                const authResult: any = await api.get(
                    {
                        appIds: appIds.join(','),
                    },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                        apiPath: '/admin/wechat_official_accounts/auth_record',
                    }
                );
                //获取授权状态
                listResult.result = listResult.result.map((item: any) => {
                    const authRecord: any = authResult
                        ? find(authResult, {
                              appId: item.appId,
                          })
                        : undefined;
                    return {
                        ...item,
                        authScope: authRecord ? authRecord.authScope : 'NONE',
                        subsiteName: item.subsites,
                    };
                });
                return listResult;
            }
            return listResult;
        }
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/wechat_official_accounts';
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/wechat_official_accounts/${id}`;
        const newParams = dataParser(params);
        newParams.id = id;
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    newParams.appId = params.appId.replace(/\s*/g, '');
    newParams.appSecret = params.appSecret.replace(/\s*/g, '');
    newParams.name = params.name.replace(/\s*/g, '');
    newParams.subsites = params.subsites;
    return newParams;
}
