import React, { Component } from 'react';
import { Table } from 'antd';
import { TableProps } from 'antd/lib/table';
import { DragableBodyRow } from '../drag-table-row';
import { DndProvider as DragDropContext } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';

interface DragTableProps<T> extends TableProps<T> {
    /**
     * 更新表单数据源
     */
    onUpdateData: (dataSource: T[]) => void;
    disableDragIndex?: number[];
}

/**
 * 公共的可拖拽排序的表格
 */
export default class DragTable<T> extends Component<DragTableProps<T>> {
    components = {
        body: {
            row: DragableBodyRow,
        },
    };

    // 拖拽后更新数据
    moveRow = (dragIndex: number, hoverIndex: number) => {
        const { dataSource, onUpdateData } = this.props;
        if (!!dataSource) {
            const dragRow = dataSource[dragIndex];
            const newDataSource = update(dataSource, {
                $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRow],
                ],
            });
            onUpdateData && onUpdateData(newDataSource);
        }
    };

    render() {
        const { disableDragIndex } = this.props;
        return (
            <DragDropContext backend={HTML5Backend}>
                <Table
                    {...this.props}
                    components={this.components}
                    onRow={(record, index) => ({
                        index,
                        moveRow: this.moveRow,
                        disableDragIndex: disableDragIndex,
                    })}
                />
            </DragDropContext>
        );
    }
}
