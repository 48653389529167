import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';

const api = services.api;
export const orderPickingLoader: Loader = {
    get: async function(data, config) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PICKING`;
        config.apiPath = '/admin/picking_lists';
        const { pickingListStatus } = data;
        if (pickingListStatus && pickingListStatus === 'ALL') {
            delete data.pickingListStatus;
        }
        return await api.get(data, config);
    },
};
