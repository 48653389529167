import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        GuidesApplyRecordsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/guides/wechatwork/apply_user_records',
            filters: {
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<loginName>>',
                },
                loginName: {
                    type: 'string',
                    displayName: '<<dlzh>>',
                },
                auditStatus: {
                    type: 'string.options.select',
                    displayName: '<<auditStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'WAITING', name: '<<waiting>>' },
                        { id: 'SUCCESS', name: '<<approved>>' },
                        { id: 'FAIL', name: '<<shbtg>>' },
                    ],
                },

                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<belongSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 150 } },
                },
                merchantId: {
                    type: 'string.filterOptions.autoComplete',
                    displayName: '<<belongMerchant>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 150 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                guideCode: {
                    type: 'string',
                    displayName: '<<ygbm>>',
                },
                loginName: {
                    type: 'string',
                    displayName: '<<dlzh>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                email: {
                    type: 'string',
                    displayName: '<<email>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<loginName>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<lsmd>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<lszj>>',
                },
                auditStatus: {
                    type: 'string.options.select',
                    displayName: '<<auditStatus>>',
                    options: [
                        { id: 'WAITING', name: '<<waiting>>' },
                        { id: 'SUCCESS', name: '<<approved>>' },
                        { id: 'FAIL', name: '<<shbtg>>' },
                    ],
                },
                createTime: {
                    type: 'string.date',
                    displayName: '<<sqsj>>',
                },
            },
        },
    },
    components: {
        GuidesApplyRecordsView: {
            component: 'Viewport',
            entity: 'GuidesApplyRecordsEntity',
        },
        GuidesApplyRecordsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'GuidesApplyRecordsFilter',
                },
                { component: 'GuidesApplyRecordsTable' },
            ],
        },
        GuidesApplyRecordsFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'mobile',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'mobile',
                    controlConfig: {
                        placeholder: '<<qsrsjh>>',
                    },
                },
                {
                    property: 'loginName',
                },
                {
                    property: 'auditStatus',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
            ],
        },
        GuidesApplyRecordsTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: {
                        x: 1500,
                    },
                    columns: [
                        {
                            property: 'guideCode',
                        },
                        {
                            property: 'loginName',
                        },
                        {
                            property: 'mobile',
                        },
                        {
                            property: 'email',
                        },
                        {
                            property: 'name',
                        },
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'merchantName',
                        },
                        {
                            property: 'auditStatus',
                        },
                        {
                            property: 'createTime',
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        width: 180,
                        items: [
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                statusKey: 'row.canEdit',
                                path: '/guides-apply-records/edit/{{row.id}}',
                            },
                            {
                                type: 'component',
                                text: '<<audity>>',
                                statusKey: 'row.canEdit',
                                component: 'AuditButton',
                                config: {
                                    confirm: {
                                        text: '审核通过',
                                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                        apiPath: `/admin/guides/wechatwork/apply_user_records/audit_status`,
                                        params: {
                                            auditStatus: 'SUCCESS',
                                        },
                                    },
                                    cancel: {
                                        text: '<<shbtg>>',
                                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                        apiPath: `/admin/guides/wechatwork/apply_user_records/audit_status`,
                                        params: {
                                            auditStatus: 'FAIL',
                                        },
                                    },
                                    content: {
                                        text: '<<yybz>>',
                                    },
                                },
                            },
                            {
                                type: 'link',
                                text: '<<common.view>>',
                                path: '/guides-apply-records/info/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        GuidesApplyRecordsEditPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'GuidesApplyRecordsEditForm',
                },
            ],
        },
        GuidesApplyRecordsInfoPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'GuidesApplyRecordsEditForm',
                    config: {
                        readOnly: true,
                    },
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/guides-apply-records',
                    component: 'GuidesApplyRecordsView',
                    breadcrumbName: '审核导购员信息',
                    privilege: {
                        path: 'applyUserRecord',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'GuidesApplyRecordsEditPage',
                            breadcrumbName: '编辑导购员信息',
                        },
                        {
                            path: '/info/:id',
                            component: 'GuidesApplyRecordsInfoPage',
                            breadcrumbName: '查看导购员信息',
                        },
                        { path: '/', component: 'GuidesApplyRecordsPage' },
                    ],
                },
            ],
        },
    ],
};
