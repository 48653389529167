import React, { PureComponent } from 'react';
import { Button, Modal, Upload, Icon, message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { ForwardDataCenterModal } from '../../services';
import './index.less';

const { Dragger } = Upload;

/**
 * 导入数据按钮
 */
export class LiveRoomProductImportButton extends PureComponent<
    any,
    { fileList: any[]; showModal: boolean }
> {
    state = {
        fileList: [],
        showModal: false,
    };

    toggleModal = () => {
        const { showModal } = this.state;
        this.setState({ showModal: !showModal });
    };

    onImport = () => {
        const {
            params: { apiRoot, apiPath },
            entity,
        } = this.props;
        const { fileList } = this.state;
        const formData = new FormData();
        formData.append('files', fileList[0]);
        services.api
            .upload(
                { files: formData },
                {
                    apiRoot,
                    apiPath,
                    fileName: 'files',
                    progressCallBack: () => {},
                    useCustomData: true,
                }
            )
            .then(() => {
                ForwardDataCenterModal();
                this.toggleModal();
                entity.search();
            })
            .catch((err) => {
                if (err) {
                    message.error(
                        err.response?.body?.err_msg || services.language.getText('uoloadFail')
                    );
                }
            });
    };

    handleOk = () => {
        const { fileList } = this.state;
        if (fileList.length) {
            this.onImport();
        } else {
            message.error(services.language.getText('pleaseUploadFile'));
            return;
        }
    };

    downloadTemplate = () => {
        services.api.download({}, this.props.params.templateSource);
    };

    render() {
        const {
            params: { text },
        } = this.props;
        const { showModal, fileList } = this.state;
        const uploadProps = {
            accept: '.xlsx,.xls,.csv',
            multiple: false,
            onRemove: () => this.setState({ fileList: [] }),
            beforeUpload: (file: any) => {
                this.setState({ fileList: [file] });
                return false;
            },
            fileList,
        };
        return (
            <>
                <Button type="primary" className="btn-data-statistics" onClick={this.toggleModal}>
                    {text}
                </Button>
                <Modal
                    onCancel={this.toggleModal}
                    visible={showModal}
                    title={services.language.getText('batchImport')}
                    footer={
                        <>
                            <Button key="submit" type="primary" onClick={this.handleOk}>
                                {services.language.getText('xzdr')}
                            </Button>
                            <Button key="back" onClick={this.toggleModal}>
                                {services.language.getText('back')}
                            </Button>
                        </>
                    }
                >
                    <Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">{services.language.getText('selectFile')}</p>
                        <p className="ant-upload-hint">
                            {services.language.getText('supportExtension')}.xlsx、.xls、.csv
                        </p>
                    </Dragger>
                    <Button
                        onClick={this.downloadTemplate}
                        className="import-button-template tag-modal-import-button-template"
                        type="link"
                        style={{ margin: 'auto', display: 'block' }}
                    >
                        {services.language.getText('clickDownloadTemplate')}
                    </Button>
                    <div className="giving-coupon-schedules-tips">
                        <p className="giving-coupon-schedules-tips-text">
                            {services.language.getText('importRules')}{' '}
                        </p>
                        <p className="giving-coupon-schedules-tips-text">
                            {services.language.getText('merchantBigCodeIntro1')}
                        </p>
                        <p className="giving-coupon-schedules-tips-text-red">
                            {services.language.getText('merchantBigCodeNote')}
                        </p>
                        <p className="giving-coupon-schedules-tips-text">
                            {services.language.getText('merchantBigCodeIntro2')}
                        </p>
                        <p className="giving-coupon-schedules-tips-text">
                            {services.language.getText('merchantBigCodeIntro3')}
                        </p>
                    </div>
                </Modal>
            </>
        );
    }
}
