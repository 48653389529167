import React, { PureComponent } from 'react';

import { Button, Modal, Form, Radio, message } from 'antd';
import { services } from '@comall-backend-builder/core';
import get from 'lodash/get';
import find from 'lodash/find';

import { CustomSelectSubsite } from '../custom-select-subsite-select';

const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const language = services.language;

const FormItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};

const options = [
    {
        label: '门店',
        value: 'home',
    },
    {
        label: '平台',
        value: 'platform',
    },
];

const DEFAULT_VALUE = {
    linkType: '',
    linkParams: {
        id: 0,
        type: '',
        name: '',
    },
};

export interface CustomSelectCouponProps {
    value: any;
    contentConfig: Record<string, any>;
    modalConfig: Record<string, any>;
    onChange(value: any): void;
}

class CustomEShopHome extends PureComponent<
    CustomSelectCouponProps,
    { visible: boolean; linkParams: any; type: string }
> {
    constructor(props: CustomSelectCouponProps) {
        super(props);
        const linkParams = get(props.value, 'linkParams') || DEFAULT_VALUE.linkParams;
        const type = get(props.value, 'linkParams.type') || 'home';

        this.state = {
            visible: false,
            linkParams: linkParams,
            type: type,
        };
    }

    visibleModalChange = (visible: boolean): void => {
        this.setState({
            visible,
        });
    };

    handleChange = (params: { id: number; name: string }): void => {
        const { linkParams } = this.state;

        this.setState({
            linkParams: { ...linkParams, ...params },
        });
    };

    onOk = () => {
        const { linkParams, type } = this.state;
        const { onChange } = this.props;

        if (!linkParams.id && type === 'home') return message.error('请选择门店');
        onChange && onChange({ ...linkParams, type });

        this.handleCancel();
    };

    handleShowModal = (): void => {
        this.visibleModalChange(true);
    };

    handleCancel = (): void => {
        this.visibleModalChange(false);
    };

    /** 门店、平台 模式切换 */
    radioChange = (type: string): void => {
        let linkParams = DEFAULT_VALUE.linkParams;
        linkParams.name = find(options, { value: type })?.label || '';

        this.setState({ type, linkParams });
    };

    render() {
        const { value } = this.props;
        const { visible, linkParams, type } = this.state;
        const linkName = get(value, 'linkParams.name');

        return (
            <span className="link-name">
                {linkName ? <span className="link-name-label">{linkName}</span> : '未选择'}

                <Button type="link" className="link-type-edit" onClick={this.handleShowModal}>
                    {linkName
                        ? language.getText('components.Tools.modify')
                        : language.getText('components.Tools.select')}
                </Button>
                <Modal
                    title="请选择"
                    width="800px"
                    visible={visible}
                    onOk={this.onOk}
                    onCancel={this.handleCancel}
                >
                    <Form {...FormItemLayout}>
                        <FormItem label={'适用范围'} required>
                            <RadioGroup
                                options={options}
                                value={type}
                                onChange={(e) => this.radioChange(e.target.value)}
                            />
                        </FormItem>

                        {type === 'home' ? (
                            <FormItem label={'选择门店'} required>
                                <CustomSelectSubsite
                                    value={{ ...linkParams }}
                                    onChange={this.handleChange}
                                />
                            </FormItem>
                        ) : null}
                    </Form>
                </Modal>
            </span>
        );
    }
}

export { CustomEShopHome };
