import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { defaults, find } from 'lodash';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { Modal, message as AntMessage, Button } from 'antd';
import { handleOrganizationTreeData, OrganizationType } from '../common';
import { SelectTree, TreeData } from '../../select-tree';
const api = services.api;
export interface HandleOrganizationState {
    showSelectChildrenOrganization: boolean;
    childrenTreeData: TreeData[];
}

/**
 * 添加/编辑组织机构
 */
class handleOrganizationModal extends PureComponent<any, any> {
    state: HandleOrganizationState = {
        showSelectChildrenOrganization: false,
        childrenTreeData: [],
    };

    onSubmit = (event: any) => {
        event.stopPropagation();
        const { getForm, currentHandleOrganization } = this.props;

        const form = getForm();
        // 'sequence'
        form.validateFields(['name'], { force: true }).then((entityFields: any) => {
            if (entityFields) {
                const hasValidate = true; //this.validate(entityFields);
                if (hasValidate) {
                    let params: any = {};
                    params = entityFields;
                    if (!params.sequence) {
                        params.sequence = 0;
                    }
                    if (this.props.type === 'edit') {
                        params = {
                            name: params.name ? params.name : currentHandleOrganization.originName,
                            sequence: params.sequence,
                            parentId: currentHandleOrganization.parentId,
                            remark: currentHandleOrganization.remark,
                            subordinateOrganizationIds:
                                currentHandleOrganization.subordinateOrganizationIds,
                            extendAttributes: currentHandleOrganization.extendAttributes,
                        };
                        api.put(params, {
                            apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
                            apiPath: '/organizations/' + currentHandleOrganization.id,
                        }).then(
                            () => {
                                AntMessage.success('编辑组织机构成功');
                                this.onOk();
                            },
                            (error) => {
                                services.errorHandle(error);
                            }
                        );
                    } else {
                        params.parentId = currentHandleOrganization.id;
                        // 组织类型
                        const organizationTypeObj = find(
                            currentHandleOrganization.extendAttributes,
                            {
                                attributeCode: 'organization_type',
                            }
                        );
                        const organizationType = organizationTypeObj
                            ? organizationTypeObj.attributeValue
                            : '';
                        const typeAttrValue =
                            organizationType === OrganizationType.TENANT
                                ? OrganizationType.SUBSITE_CUSTOM
                                : organizationType === OrganizationType.MERCHANT_CUSTOM
                                ? OrganizationType.MERCHANT_CUSTOM2
                                : OrganizationType.MERCHANT_CUSTOM;

                        // 所属门店id
                        const subsiteIdObj = find(currentHandleOrganization.extendAttributes, {
                            attributeCode: 'subsite_id',
                        });
                        const originIdObj = find(currentHandleOrganization.extendAttributes, {
                            attributeCode: 'origin_id',
                        });
                        const subsiteId = subsiteIdObj ? subsiteIdObj.attributeValue : '';
                        const originId = originIdObj ? originIdObj.attributeValue : '';
                        const subsiteIdAttrValue =
                            organizationType === OrganizationType.SUBSITE ? originId : subsiteId;

                        const extendAttributes = [
                            {
                                attributeCode: 'organization_type',
                                attributeValue: typeAttrValue,
                            },
                            {
                                attributeCode: 'subsite_id',
                                attributeValue: subsiteIdAttrValue,
                            },
                        ];
                        params.extendAttributes = extendAttributes;
                        api.post(params, {
                            apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
                            apiPath: '/organizations',
                        }).then(
                            () => {
                                AntMessage.success('添加组织机构成功');
                                this.onOk();
                            },
                            (error) => {
                                services.errorHandle(error);
                            }
                        );
                    }
                }
            }
        });

        return;
    };

    showSelectChildrenOrganization = () => {
        const { currentHandleOrganization } = this.props;
        api.get(
            { parentId: currentHandleOrganization.id },
            {
                apiPath: `/organizations`,
                apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
            }
        ).then((res: any) => {
            const treeData = handleOrganizationTreeData(res.result);
            this.setState({ childrenTreeData: treeData });
        });
        this.setState({ showSelectChildrenOrganization: true });
    };

    renderModal = () => {
        const { wrappedComponentRef, entity, type, currentHandleOrganization } = this.props;
        let modifiable = true;
        if (type === 'edit') {
            const organizationTypeObj = find(currentHandleOrganization.extendAttributes, {
                attributeCode: 'organization_type',
            });
            const organizationType = organizationTypeObj ? organizationTypeObj.attributeValue : '';
            if (
                organizationType === OrganizationType.SUBSITE ||
                organizationType === OrganizationType.MERCHANT
            ) {
                modifiable = false;
            }
        }
        let formProps = {
            entity,
            wrappedComponentRef: wrappedComponentRef,
            componentId: 'handleOrganizationModal',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 14,
            fields: [
                {
                    property: 'name',
                    modifiable: modifiable,
                },
                // { property: 'sequence' },
            ],
            submit: false,
            footer: null,
            onSubmit: this.onSubmit,
        };
        if (type === 'add') {
            return createElement(ComponentsManager.get('CreateForm'), formProps);
        } else {
            return createElement(ComponentsManager.get('ModifyForm'), formProps);
        }
    };

    onCancel = () => {
        const { onCancel } = this.props;
        onCancel && onCancel();
    };
    onOk = () => {
        const { onOk } = this.props;
        onOk && onOk();
    };

    onTreeSelect = () => {};

    renderModalFooter = () => {
        return (
            <div className="buttons">
                <Button className="button" type="default" onClick={this.onCancel}>
                    取消
                </Button>
                <Button className="button" type="default" onClick={this.onSubmit}>
                    保存
                </Button>
                {/* <Button
                    className="button"
                    type="primary"
                    onClick={this.showSelectChildrenOrganization}
                >
                    选择下级组织机构
                </Button> */}
            </div>
        );
    };

    render() {
        const { visible, type, selectedTreeNodeParents } = this.props;
        const { showSelectChildrenOrganization, childrenTreeData } = this.state;
        const modalProps = {
            width: 600,
            title: type === 'add' ? '新增自定义组织机构' : '编辑组织机构',
            visible: visible,
            footer: this.renderModalFooter(),
            onCancel: this.onCancel,
        };

        const treeProps = {
            checkable: true,
        };
        let parents = '';
        if (selectedTreeNodeParents && selectedTreeNodeParents.length) {
            if (type === 'edit') {
                selectedTreeNodeParents.pop();
            }
            selectedTreeNodeParents.forEach((item: any, index: number) => {
                if (index === selectedTreeNodeParents.length - 1) {
                    parents = parents + item.name;
                } else {
                    parents = parents + item.name + '>';
                }
            });
        }

        return (
            <div className="add-organization-modal">
                <Modal {...modalProps}>
                    <div>
                        {showSelectChildrenOrganization ? (
                            <div className="tree-view">
                                {childrenTreeData && childrenTreeData.length ? (
                                    <SelectTree
                                        onSelect={this.onTreeSelect}
                                        result={childrenTreeData}
                                        treeProps={treeProps}
                                    />
                                ) : (
                                    ''
                                )}
                            </div>
                        ) : (
                            <div>
                                <div className="form ant-form ant-form-horizontal">
                                    <div className="ant-row ant-form-item">
                                        <span className="ant-col ant-col-6 ant-form-item-label">
                                            上级组织机构：
                                        </span>
                                        <span
                                            className="ant-col ant-col-14 ant-form-item-label"
                                            style={{ textAlign: 'left' }}
                                        >
                                            {parents}
                                        </span>
                                    </div>
                                </div>
                                {this.renderModal()}
                            </div>
                        )}
                    </div>
                </Modal>
            </div>
        );
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit } = props;
    let form: any;

    return defaults(
        {
            onSubmit,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
        }
    );
}
function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
        fields: entity.fields,
    };
}

export const HandleOrganizationModal = connect(
    mapStateToProps,
    mapDispatchToProps
)(handleOrganizationModal);
