import React from 'react';
import { modes, formats } from '@comall-backend-builder/types';

import { get } from 'lodash';
import { DESIGN_PAGE_TYPES } from '../../constants/page-design';
export class OptionsStringMode extends modes.ObjectOptionSelectMode {
    /**
     * 获取有数据状态展示组件
     */
    getAvailableDisplayComponent(value: 'topic' | 'home', displayInfo: any) {
        const { className, style } = displayInfo;
        return (
            <span className={className} style={style}>
                {DESIGN_PAGE_TYPES[value]}
            </span>
        );
    }
}

export class OptionsStringFormat extends formats.ObjectFormat {
    /**
     * 将数据格式化为请求参数
     */
    formatParams(key: string, value: any) {
        return { [key]: get(value, 'id') };
    }
}
