import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { cloneDeep, find, findIndex, forEach, set } from 'lodash';
import { Input, Modal, message as AntMessage } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import { ImageUpload } from '@comall-backend-builder/components-basis';
import {
    MessageNotificationMethodValueItem,
    MsgChannelType,
} from '../../message-notification-method';
import { MessageParameterSelector } from '../../../../../../containers/wechat/components/message-parameter-selector';
import { PushAndSiteMailSceneType } from '../../../../../../containers/wechat/common';
import { newClientCustomSelectorConfig } from '../../../../../../applications/design/design';
import {
    getPathconfiglist,
    newLinkTypeEffectPathConfigList,
} from '../../../../../../components/link-type-effect-path/config';
import { LinkTypePlus } from '../../../../../../applications/design/components';
import './message-notification-push-and-sitemail-edit-modal.less';

interface Props {
    visible: boolean;
    template: MessageNotificationMethodValueItem;
    scene: PushAndSiteMailSceneType;
    landingPage: any;
    onChange: (template: MessageNotificationMethodValueItem) => void;
    closeModal: () => void;
}
interface State {
    editTemplate: MessageNotificationMethodValueItem;
    subsiteWechantName: string;
    logoUrl: string;
    appId: string;
}

export class MessageNotificationPushAndSitemailEditModal extends PureComponent<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            editTemplate: cloneDeep(props.template),
            subsiteWechantName: '',
            logoUrl: '',
            appId: '',
        };
    }

    validate = () => {
        const { editTemplate } = this.state;

        let hasValidate = true;
        forEach(editTemplate.params || [], (o: any) => {
            // 图片非必填所以不需要检验图片是否填写
            if (o.fieldType !== 'file') {
                if (!o.value) {
                    AntMessage.warning(`${services.language.getText('selectFillin')}${o.name}`);
                    hasValidate = false;
                    return false;
                }
            }
        });
        const { targetType, targetUrl } = editTemplate;
        if (!targetType) {
            AntMessage.warning(services.language.getText('qxzljlx'));
            return false;
        }
        const needUrl = ['newTopic', 'wheelDraw', 'redPackageRain', 'scratchCard', 'lotteryCode'];
        if (needUrl.includes(targetType) && !targetUrl) {
            AntMessage.warning(services.language.getText('selectLinkTarget'));
            return false;
        }
        return hasValidate;
    };

    handleSubmit = () => {
        const hasValidate = this.validate();
        if (!hasValidate) {
            return;
        }
        const { onChange, closeModal } = this.props;
        const { editTemplate } = this.state;
        onChange(editTemplate);
        closeModal();
    };

    getParams = () => {
        const { editTemplate } = this.state;
        let top, bottom;
        let messageList: any[] = [];
        let detailContentColor = '';
        if (editTemplate && editTemplate.params) {
            top = find(editTemplate.params, { name: 'top' });
            bottom = find(editTemplate.params, { name: 'bottom' });
            forEach(editTemplate.params, (param: any) => {
                if (param.name !== 'name' && param.name !== 'top' && param.name !== 'bottom') {
                    messageList.push(param);
                }
            });
            if (messageList.length > 0) {
                detailContentColor = messageList[0].color;
            }
        }
        return {
            top,
            bottom,
            messageList,
            detailContentColor,
        };
    };

    renderLeft = () => {
        const { editTemplate } = this.state;
        const { msgChannelType } = editTemplate;
        if (msgChannelType === MsgChannelType.SITEMAIL) {
            return this.renderSitemailLeftWrap();
        }
        return this.renderAppPushLeftWrap();
    };

    renderSitemailLeftWrap = () => {
        const { editTemplate } = this.state;
        const { messageList } = this.getParams();
        const content = messageList && messageList?.length > 1 ? messageList[1].value : '';
        const pic = messageList && messageList?.length > 2 ? messageList[2].value : '';
        return (
            <div className="wechat-templates-find-list-item-edit-view__left-wrap">
                <div className="wechat-templates-find-list-item-edit-view__sitemail">
                    <div className="wechat-templates-find-list-item-edit-view__sitemail__item">
                        <div className="wechat-templates-find-list-item-edit-view__sitemail__title">
                            {editTemplate && editTemplate.name
                                ? editTemplate.name
                                : services.language.getText('mbbt')}
                        </div>
                        {content || pic ? (
                            <div className="wechat-templates-find-list-item-edit-view__sitemail__content">
                                {pic ? (
                                    <div className="wechat-templates-find-list-item-edit-view__sitemail__content__left">
                                        <img
                                            className="img"
                                            alt={services.language.getText('xxtp')}
                                            src={pic.url}
                                        />
                                    </div>
                                ) : null}
                                <div className="wechat-templates-find-list-item-edit-view__sitemail__content__right">
                                    {content}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    };
    renderAppPushLeftWrap = () => {
        const { editTemplate } = this.state;
        const { messageList } = this.getParams();
        const pushBg = require('../../images/app-push-bg.png');
        const pushLogo = require('../../images/app-push-logo.png');
        const content = messageList && messageList?.length > 1 ? messageList[1].value : '';
        const pic = messageList && messageList?.length > 2 ? messageList[2].value : '';
        const style = {
            height: '808px',
            backgroundImage: `url('${pushBg}')`,
            backgroundSize: '375px auto',
            backgroundPosition: 'top left',
            backgroundRepeat: 'no-repeat',
        };
        return (
            <div className="wechat-templates-find-list-item-edit-view__left-wrap" style={style}>
                <div className="wechat-templates-find-list-item-edit-view__app-push">
                    <div className="wechat-templates-find-list-item-edit-view__app-push__item">
                        <img
                            alt="LOGO"
                            className="wechat-templates-find-list-item-edit-view__app-push__item__logo"
                            src={pushLogo}
                        />
                        <div className="wechat-templates-find-list-item-edit-view__app-push__item__right">
                            <div className="wechat-templates-find-list-item-edit-view__app-push__item__right__top">
                                <div className="wechat-templates-find-list-item-edit-view__app-push__item__right__top__name">
                                    {editTemplate && editTemplate.name
                                        ? editTemplate.name
                                        : services.language.getText('mbbt')}
                                </div>
                                <div className="wechat-templates-find-list-item-edit-view__app-push__item__right__top__time">
                                    周六 10:00
                                </div>
                            </div>
                            <div className="wechat-templates-find-list-item-edit-view__app-push__item__right__bottom">
                                <div className="wechat-templates-find-list-item-edit-view__app-push__item__right__bottom__content">
                                    {content}
                                </div>
                                {pic ? (
                                    <div className="wechat-templates-find-list-item-edit-view__app-push__item__right__bottom__pic">
                                        <img
                                            className="img"
                                            alt={services.language.getText('xxtp')}
                                            src={pic.url}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderRightWrap = () => {
        const { scene } = this.props;
        const { editTemplate } = this.state;
        if (!editTemplate) {
            return null;
        }
        const { messageList } = this.getParams();
        return (
            <div className="wechat-templates-find-list-item-edit-view__right-wrap">
                <div className="wechat-templates-find-list-item-edit-view__right-title">
                    {services.language.getText('nrpz')}
                </div>
                <div className="wechat-templates-find-list-item-edit-view__parameter">
                    <MessageParameterSelector params={{ scene: scene }} />
                    <div>{services.language.getText('qfzkydblmc')}</div>
                </div>
                {messageList &&
                    messageList.map((o: any, index: number) => {
                        return (
                            <div
                                className="wechat-templates-find-list-item-edit-view__flex"
                                key={index}
                            >
                                <div className="wechat-templates-find-list-item-edit-view__other-title">
                                    {o.name}：
                                </div>
                                <div className="wechat-templates-find-list-item-edit-view__value">
                                    {this.renderOther(o, index)}
                                    {o.info && (
                                        <div className="wechat-templates-find-list-item-edit-view__tip">
                                            {services.language.getText('example_2')}
                                            {o.info}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                {this.rendeLinkType()}
            </div>
        );
    };

    getLinkTypes = () => {
        const { scene } = this.props;
        if (scene === PushAndSiteMailSceneType.PAY_AWARD_RECEIVE_REMIND) {
            return getPathconfiglist(
                [
                    ['newTopic'],
                    ['marketing', 'wheelDraw', 'redPackageRain', 'marketing.paymentRewardActivity'],
                    ['credit', 'scratchCard', 'lotteryCode'],
                ],
                newLinkTypeEffectPathConfigList
            );
        }
        return undefined;
    };

    rendeLinkType = () => {
        const { landingPage } = this.props;
        const { editTemplate } = this.state;
        const linkTypes = (this.getLinkTypes()?.linkList as any) || [];
        const currentLinkType = {
            linkType: editTemplate.targetType,
            linkParams: editTemplate.targetUrl,
        };
        return (
            <div className="wechat-templates-find-list-item-edit-view__flex">
                <div className="wechat-templates-find-list-item-edit-view__title">
                    {services.language.getText('ljlx')}：
                </div>
                <div className="wechat-templates-find-list-item-edit-view__value">
                    {linkTypes && linkTypes.length ? (
                        <LinkTypePlus
                            selector={newClientCustomSelectorConfig as any}
                            linkTypes={linkTypes}
                            linkTypeMode="cascader"
                            value={currentLinkType}
                            onChange={this.changeTargetValue}
                        />
                    ) : (
                        landingPage.name
                    )}
                </div>
            </div>
        );
    };
    changeTargetValue = (val: any) => {
        const { editTemplate } = this.state;
        const newTempate = cloneDeep(editTemplate);
        newTempate.targetType = val.linkType;
        newTempate.targetUrl = val.linkParams;
        this.setState({
            editTemplate: newTempate,
        });
    };

    renderOther = (o: any, index: number) => {
        const { editTemplate } = this.state;
        if (!editTemplate) {
            return null;
        }
        const { messageList } = this.getParams();
        const picValue =
            messageList &&
            messageList.length > 2 &&
            messageList[2].value &&
            messageList[2].value.url;
        let _imageBgControlInfo = Object.assign(
            {},
            {
                name: 'messagePicture',
                accept: 'image/*',
                maxCount: 1,
                multiple: false,
                fileName: 'fileName',
                uploadType: 'single',
                value: picValue || '',
                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                mode: 'picture-card',
                preview: {
                    size: 500,
                },
                maxSize: 1 * 1024,
                limitTips: {
                    limitText: services.language.getText('siteMailImgLimit'),
                    limitUnit: ' ',
                    className: 'image-picker-tips-ext',
                    style: {
                        marginTop: 5,
                    },
                },
            }
        );

        return (
            <div className="wechat-templates-find-list-item-edit-view__other-wrap">
                {index === 0 && (
                    <Input
                        value={o.value}
                        onChange={(value: any) =>
                            this.onHandleOtherChange(o.name, value.target.value)
                        }
                    />
                )}
                {index === 1 && (
                    <TextArea
                        value={o.value}
                        onChange={(value: any) =>
                            this.onHandleOtherChange(o.name, value.target.value)
                        }
                    />
                )}
                {index === 2 && (
                    <ImageUpload
                        {..._imageBgControlInfo}
                        onChange={(value: any) => {
                            const pic =
                                value && value.length
                                    ? {
                                          id: value[0].id,
                                          url: value[0].path,
                                      }
                                    : null;
                            this.onHandleOtherChange(o.name, pic);
                            return {};
                        }}
                    />
                )}
            </div>
        );
    };

    onHandleOtherChange = (name: string, value: any) => {
        const { editTemplate } = this.state;
        const newTempate = cloneDeep(editTemplate);
        const index = findIndex(newTempate.params, { name: name });
        if (index > -1) {
            set(newTempate.params, index, { ...newTempate.params[index], value: value });
        }
        this.setState({
            editTemplate: newTempate,
        });
    };

    render() {
        const { visible, closeModal } = this.props;
        return (
            <div className={prefix}>
                <Modal
                    destroyOnClose
                    visible={visible}
                    title={services.language.getText('bjmbxx')}
                    width="1000px"
                    onCancel={closeModal}
                    onOk={this.handleSubmit}
                >
                    <div className="wechat-templates-find-list-item-edit-view__wrap">
                        {this.renderLeft()}
                        {this.renderRightWrap()}
                    </div>
                </Modal>
            </div>
        );
    }
}

const prefix = 'message-notification-push-and-sitemail-edit-modal';
