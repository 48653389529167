import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';

export const REASON_TYPE_OPTIONS = [
    {
        id: '0',
        name: '<<yhsqqxddyy>>',
    },
    {
        id: '1',
        name: '<<kfgbddyy>>',
    },
    {
        id: '2',
        name: '<<shsq>>',
    },
    {
        id: '3',
        name: '<<zxjy>>',
    },
    {
        id: '4',
        name: '<<jjshyy>>',
    },
    {
        id: '5',
        name: '<<jjysyy>>',
    },
    {
        id: '6',
        name: '<<yhjbyhdyy>>',
    },
    {
        id: '7',
        name: '<<yhjbbjdyy>>',
    },
    {
        id: '8',
        name: '<<yhjbpldyy>>',
    },
    {
        id: '9',
        name: '<<pzjfshbtgdyy>>',
    },
    {
        id: '10',
        name: '<<sydd>>',
    },
];

export const config: Config = {
    entities: {
        ParameterEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/reason',
            filters: {
                reason: {
                    type: 'string',
                    displayName: '<<yyzwmc>>',
                    controlConfig: {
                        placeholder: '<<qsryyzwmc>>',
                        style: { width: 250 },
                    },
                },
                reasonType: {
                    type: 'string.options.select',
                    displayName: '<<yylx>>',
                    options: REASON_TYPE_OPTIONS,
                    controlConfig: {
                        style: { width: 240 },
                    },
                },
                enable: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: '0', name: '<<enable>>' },
                        { id: '1', name: '<<common.close>>' },
                    ],
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
            },
            properties: {
                reason: {
                    displayName: '<<yyzwmc>>',
                    type: 'string',
                    rules: [{ required: true }, { max: 20, message: '<<h_qsryyzwmc_msg>>' }],
                    controlConfig: {
                        placeholder: '<<h_qsryyzwmc_msg>>',
                        maxLength: 20,
                    },
                },
                reasonTypeStr: {
                    type: 'string',
                    displayName: '<<yylx>>',
                },
                enable: {
                    type: 'string.statusBadge',
                    displayName: '<<enabledStatus>>',
                    defaultValue: '0',
                    options: [
                        {
                            id: '0',
                            name: '<<enable>>',
                        },
                        {
                            id: '1',
                            name: '<<disabled>>',
                        },
                    ],
                },
                sequnence: {
                    displayName: '<<sequence>>',
                    type: 'number.integer',
                    rules: [{ required: true, message: '<<sequenceRequired>>' }],
                    controlConfig: {
                        placeholder: '<<inputPlease>>',
                        max: 99999,
                        min: 0,
                    },
                },
                createTimeStr: {
                    type: 'string',
                    displayName: '<<createTimeStr>>',
                },
                reasonType: {
                    type: 'string.options.select',
                    displayName: '<<yylx>>',
                    options: REASON_TYPE_OPTIONS,
                    rules: [{ required: true, message: '<<qxzyylx>>' }],
                    controlConfig: {
                        style: { width: 220 },
                    },
                },
            },
        },
    },
    components: {
        ParameterView: {
            component: 'Viewport',
            entity: 'ParameterEntity',
        },
        ParameterPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ParameterFilter',
                },
                {
                    component: 'Button',
                    text: '<<xzyy>>',
                    route: '/parameter/add',
                    type: 'primary',
                    icon: 'plus',
                    style: {
                        width: 110,
                        marginBottom: 24,
                    },
                },
                { component: 'ParameterTable' },
            ],
        },
        ParameterFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 24,
            },
            fields: [
                {
                    property: 'reason',
                },
                {
                    property: 'reasonType',
                },
                {
                    property: 'enable',
                },
            ],
        },
        ParameterTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'ParameterDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: {
                        x: true,
                    },
                    columns: [
                        {
                            property: 'reason',
                        },
                        {
                            property: 'reasonTypeStr',
                        },
                        {
                            property: 'enable',
                            displayConfig: {
                                statusConfig: {
                                    0: {
                                        text: '<<enable>>',
                                        status: 'processing',
                                    },
                                    1: {
                                        text: '<<disabled>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                        {
                            property: 'sequnence',
                        },
                        {
                            property: 'createTimeStr',
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        width: 200,
                        fixed: 'right',
                        items: [
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                path: '/parameter/edit/{{row.id}}',
                            },
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    statusKey: 'enable',
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                    handles: [
                                        {
                                            params: { enable: 1 },
                                            value: 0,
                                            apiPath: '/admin/reason/:id/status',
                                            config: {
                                                content: '<<disabled>>',
                                                text: '<<disabled>>',
                                            },
                                            confirm: {
                                                text: '<<isDisabled>>{{row.reason}}?',
                                            },
                                        },
                                        {
                                            params: { enable: 0 },
                                            value: 1,
                                            apiPath: '/admin/reason/:id/status',
                                            config: { content: '<<enable>>', text: '<<enable>>' },
                                            confirm: {
                                                text: '<<isEnable>>{{row.reason}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'delete',
                                text: '<<common.delete>>',
                                config: {
                                    type: 'link',
                                    id: 'row.id',
                                    tooltip: null,
                                    title: '<<confirmDelete>>',
                                    okText: '<<common.ok>>',
                                    cancelText: '<<common.cancel>>',
                                },
                            },
                        ],
                    },
                },
            ],
        },
        ParameterAddPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'ParameterEntity',
            items: [
                {
                    component: 'ParameterAddForm',
                },
            ],
        },
        ParameterAddForm: {
            component: 'CreateForm',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: [
                { property: 'reasonType' },
                { property: 'reason' },
                { property: 'enable' },
                { property: 'sequnence' },
            ],
            submit: true,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        ParameterEditPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'ParameterEntity',
            loaderType: 'get',
            items: [
                {
                    component: 'ParameterEditForm',
                },
            ],
        },
        ParameterEditForm: {
            component: 'ModifyForm',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: [
                { property: 'reasonType' },
                { property: 'reason' },
                { property: 'enable' },
                { property: 'sequnence' },
            ],
            submit: true,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/parameter',
                    component: 'ParameterView',
                    breadcrumbName: '<<parameter>>',
                    privilege: {
                        path: 'parameter',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'ParameterEditPage',
                            breadcrumbName: '<<bjyy>>',
                        },
                        {
                            path: '/add',
                            component: 'ParameterAddPage',
                            breadcrumbName: '<<xzyy>>',
                        },
                        { path: '/', component: 'ParameterPage' },
                    ],
                },
            ],
        },
    ],
};
