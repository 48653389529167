import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { get } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

export enum StyleLabelStatus {
    ENABLE = 'ENABLED',
    DISABLE = 'DISABLED',
    ALL = 'ALL',
}
export const config: Config = {
    entities: {
        showStyleLabelEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/styel_label',
            properties: {
                id: {
                    type: 'number',
                },
                name: {
                    displayName: '<<tagName>>',
                    type: 'string.maxLength',
                    rules: [
                        { required: true, message: '<<activityTagRequired>>' },
                        {
                            message: '<<activityTagNotEmpty>>',
                            validator: (rule: any, value: any) => {
                                if (value && !value.trim()) {
                                    return false;
                                }
                                return true;
                            },
                        },
                    ],
                    controlConfig: {
                        maxLength: 6,
                    },
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                pictureUrl: {
                    type: 'string.image.picker',
                    displayName: '<<bqtp>>',
                },
                sequence: {
                    displayName: '<<sequence>>',
                    type: 'number',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<syzt>>',
                    options: [
                        { id: StyleLabelStatus.ENABLE, name: '<<inUse>>' },
                        { id: StyleLabelStatus.DISABLE, name: '<<deactivated>>' },
                    ],
                },
                subsiteIds: {
                    type: 'array.options.autoComplete',
                    displayName: '<<applicableSubsite>>',
                    options: [],
                    rules: [{ required: true, message: '<<applicableSubsiteRequired>>' }],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectSubsite>>',
                    },
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<bqxx>>',
                    properties: {
                        name: {
                            type: 'string.maxLength',
                            displayName: '<<tagName>>',
                            rules: [
                                {
                                    required: true,
                                    max: 6,
                                    whitespace: true,
                                    message: '<<activityTagRequired>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<tagNameRule2>>',
                                maxLength: 6,
                                style: { width: 450 },
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<effectiveTime>>',
                            rules: [{ required: true, message: '<<effectiveTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<sxmd_1>>',
                            defaultValue: [],
                            rules: [{ required: true, message: '<<qxzsxmd>>' }],
                        },
                        pictures: {
                            displayName: '<<bqtp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true, message: '<<qscbqtp>>' }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<limit200MPic>>',
                        },
                        sequence: {
                            type: 'number',
                            displayName: '<<sequence>>',
                            rules: [{ required: true, message: '<<qsrpx>>' }],
                            controlConfig: {
                                placeholder: '<<sortTip>>',
                                min: 0,
                                max: 99999,
                                precision: 0,
                                style: {
                                    width: 160,
                                },
                            },
                        },
                    },
                },
                associationInfo: {
                    type: 'object.subForm',
                    displayName: '<<glxx>>',
                    properties: {
                        merchants: {
                            type: 'array.merchants.selector.bySubsites',
                            displayName: '<<glzj>>',
                            defaultValue: [],
                            controlConfig: {
                                subsiteField: 'baseInfo.subsites',
                                needDependencesMessage: '<<qxxzsxmd>>',
                            },
                        },
                    },
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<tagName>>',
                },
                subsiteIds: {
                    type: 'string.options.autoComplete.plus',
                    displayName: '<<belongSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<tagStatus>>',
                    defaultValue: StyleLabelStatus.ALL,
                    options: [
                        { id: StyleLabelStatus.ALL, name: '<<selectPlease>>' },
                        { id: StyleLabelStatus.ENABLE, name: '<<inUse>>' },
                        { id: StyleLabelStatus.DISABLE, name: '<<deactivated>>' },
                    ],
                },
            },
        },
    },
    components: {
        ShowStyleLabelView: {
            component: 'Viewport',
            entity: 'showStyleLabelEntity',
        },
        ShowStyleLabelPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ShowStyleLabelFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addTag>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/show-style-label/add',
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'ShowStyleLabelTable',
                },
            ],
        },
        ShowStyleLabelFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 200 },
                        placeholder: '<<inputPlease>>',
                    },
                },
                {
                    property: 'subsiteIds',
                    controlConfig: {
                        style: { width: 200 },
                        placeholder: '<<selectPlease>>',
                    },
                },
                {
                    property: 'status',
                    controlConfig: {
                        style: { width: 200 },
                        placeholder: '<<selectPlease>>',
                    },
                },
            ],
        },
        ShowStyleLabelTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'subsites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'pictureUrl',
                    displayConfig: { style: { width: 180 } },
                },
                {
                    property: 'sequence',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'status',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            apiRoot: `${ENV.AUTH_API_ROOT}/SHOW`,
                            handles: [
                                {
                                    apiPath: '/admin/style_labels/:id/disabled',
                                    value: StyleLabelStatus.ENABLE,
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: {
                                            color: 'red',
                                        },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClosed>>{{row.name}}?',
                                    },
                                },
                                {
                                    apiPath: '/admin/style_labels/:id/enabled',
                                    value: StyleLabelStatus.DISABLE,
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/show-style-label/edit/{{row.id}}',
                    },
                ],
            },
        },
        ShowStyleLabelAddPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'ShowStyleLabelAddForm' }],
        },
        ShowStyleLabelAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'showStyleLabelEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'baseInfo.subsites' },
                { property: 'baseInfo.pictures' },
                { property: 'baseInfo.sequence' },
                { property: 'associationInfo.merchants' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entityFields: any) => {
                const subsites = get(entityFields, 'baseInfo.subsites', []) || [];
                const pictures = get(entityFields, 'baseInfo.pictures', []) || [];
                if (!subsites || subsites.length === 0) {
                    AntMessage.warning(language.getText('qxzsxmd'));
                    return false;
                }
                if (!pictures || pictures.length === 0) {
                    AntMessage.warning(language.getText('qscbqtp'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        ShowStyleLabelEditPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'ShowStyleLabelEditForm' }],
        },
        ShowStyleLabelEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'showStyleLabelEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'baseInfo.subsites' },
                { property: 'baseInfo.pictures' },
                { property: 'baseInfo.sequence' },
                { property: 'associationInfo.merchants' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entityFields: any) => {
                const subsites = get(entityFields, 'baseInfo.subsites', []) || [];
                const pictures = get(entityFields, 'baseInfo.pictures', []) || [];
                if (!subsites || subsites.length === 0) {
                    AntMessage.warning(language.getText('qxzsxmd'));
                    return false;
                }
                if (!pictures || pictures.length === 0) {
                    AntMessage.warning(language.getText('qscbqtp'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/show-style-label',
                    breadcrumbName: '<<showStyleLabel>>',
                    component: 'ShowStyleLabelView',
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'ShowStyleLabelAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'ShowStyleLabelEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        {
                            path: '/',
                            component: 'ShowStyleLabelPage',
                        },
                    ],
                    privilege: {
                        path: 'showStyleLabel',
                        level: 'full',
                    },
                },
            ],
        },
    ],
};
