import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import { find } from 'lodash';
import { StringRadioIllustrate } from '../../../components/string-radio-illustrate';
import { services } from '@comall-backend-builder/core';

/**
 * 单选框 下面带说明
 *
 */
export class RadioIllustrate extends modes.StringMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        if (displayInfo.readOnly) {
            let id = value + '';
            const current = find(displayInfo.options, { id });
            return (
                <div>
                    <div>{(current && current.name) || ''}</div>
                </div>
            );
        }
        const current = find(displayInfo.options, { id: value });
        const illustrate = displayInfo.name && JSON.parse(displayInfo.name);
        if (current) {
            return (
                <div>
                    <div>{current && current.name}</div>
                    <div style={{ color: 'rgb(204, 204, 204)', fontSize: 12 }}>
                        {illustrate && illustrate[current.id]}
                    </div>
                </div>
            );
        }
        return <div>{services.language.getText('notData')}</div>;
    }

    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <StringRadioIllustrate {...displayInfo} />;
    }
}
