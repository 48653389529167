import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach, isEmpty } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;
export const ReservationDetailsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = '/admin/service_reserved_activity_orders';
        data.orderType = 'SERVICE_RESERVED_ACTIVITY';
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        return await api.get(data, config).then((res: any) => {
            const result = res.result;
            forEach(result, (item, index) => {
                let canLender = false,
                    canRemand = false,
                    canCancel = false;
                switch (item.orderStatus) {
                    case 'PAYMENT':
                        canLender = true;
                        canCancel = true;
                        break;
                    case 'RECEIVE':
                        canRemand = true;
                        break;
                }
                item.canLender = canLender;
                item.canRemand = canRemand;
                item.canCancel = canCancel;
                item.status = 'enabled';
                let orderAmount = language.getText('myj');
                if (item.orderAmount && Number(item.orderAmount) > 0) {
                    orderAmount = `${item.orderAmount}` + language.getText('yuan');
                } else if (item.pointAmount && Number(item.pointAmount) > 0) {
                    orderAmount = `${item.pointAmount}` + language.getText('point');
                }
                item.orderAmount = orderAmount;
            });
            res.result = result;
            return res;
        });
    },
};
