import React, { PureComponent } from 'react';
import { Button as AntButton, message as AntMessage } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services';

export class CopyLinkButton extends PureComponent<any, any> {
    copyLink = (url: any) => {
        return function() {
            let input = document.createElement('input');
            input.value = url;
            document.body.appendChild(input);
            input.select();
            if (document.execCommand('Copy')) {
                AntMessage.success(language.getText('ljdzyfz'));
            } else {
                AntMessage.success(language.getText('copyError'));
            }
            document.body.removeChild(input);
        };
    };
    render() {
        const { row, params } = this.props;

        if (!row.needUrlLink || !row.urlLink) return '';
        return (
            <AntButton
                type="link"
                style={{ padding: 0 }}
                className="table-action-column-item span-link"
                onClick={this.copyLink(row.urlLink)}
            >
                {params?.content || language.getText('copyLink')}
            </AntButton>
        );
    }
}
