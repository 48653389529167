import React, { Component } from 'react';
import { InputNumber, Select, Checkbox } from 'antd';
import { clone, map, concat, isEmpty } from 'lodash';
import { services } from '@comall-backend-builder/core';

import './index.less';
import { ActivityRewardItem } from '../activity-reward-item';
import { Coupon } from '../../containers/select-coupon';

const api = services.api;

type ActivityRewardValue = {
    point?: number;
    pointChecked?: boolean;
    pointPercentChecked?: boolean;
    coupons?: number;
    couponsChecked?: boolean;
    experienceChecked?: boolean;
    experiencePercentChecked?: boolean;
    experience?: number;
    couponsValue?: Array<Coupon>;
    goldChecked?: boolean;
    gold?: number;
    goldTxtName?: string;
    pointMin?: number;
    pointMax?: number;
    experienceMin?: number;
    experienceMax?: number;
    doublePoint?: number;
    doubleExperience?: number;
    reward?: number;
    every?: number;
    condition?: number;
    result?: number;
    pointPercent?: number;
    experiencePercent?: number;
};

enum RewardItems {
    point = 'POINT',
    coupons = 'COUPON',
    count = 'COUNT',
    gold = 'GOLD',
    experience = 'EXPERIENCE',
    pointPercent = 'POINTPERCENT',
    experiencePercent = 'experiencePercent',
}

enum InputTypes {
    pointMin = 'pointMin',
    pointMax = 'pointMax',
    pointPercent = 'pointPercent',
    doublePoint = 'doublePoint',
    doubleExperience = 'doubleExperience',
    reward = 'reward',
    every = 'every',
    condition = 'condition',
    result = 'result',
    experiencePercent = 'experiencePercent',
    experienceMin = 'experienceMin',
    experienceMax = 'experienceMax',
}

export interface EventMarketRewardProps {
    name: string;
    value: Array<ActivityRewardValue> | null;
    maxCount: number;
    disabled: boolean;
    onChange: (value: Array<ActivityRewardValue> | null, name: string) => void;
    eventTypeId: eventTypes;
    options?: { code: string; name: string }[];
}

enum eventTypes {
    /**
     * 事件营销分类:会员注册
     */
    EVENT_MARKET_TYPE_MEMBER_REGISTER = '1',

    /**
     * 事件营销分类:会员登录
     */
    EVENT_MARKET_TYPE_MEMBER_LOGIN = '2',

    /**
     * 事件营销分类:订单奖励
     */
    EVENT_MARKET_TYPE_CREATE_ORDER = '3',

    /**
     * 事件营销分类:商品评论
     */
    EVENT_MARKET_REMARK_PRODUCT = '4',

    /**
     * 事件营销分类:会员验证
     */
    EVENT_MARKET_TYPE_CHECK_MEMBER = '5',

    /**
     * 事件营销分类:分享订单
     */
    EVENT_MARKET_TYPE_SHARE_ORDER = '6',

    /**
     * 事件营销分类:分享单品
     */
    EVENT_MARKET_TYPE_SHARE_PRODUCT = '7',

    /**
     * 事件营销分类:分享邀请码
     */
    EVENT_MARKET_TYPE_INVITE_REGISTER = '8',

    /**
     * 事件营销分类:分享文章
     */
    EVENT_MARKET_TYPE_SHARE_ARTICLE = '9',

    /**
     * 事件营销分类:支付奖励
     */
    EVENT_MARKET_TYPE_PAYMENT_REWARD = '10',
}

const SHOW_GOLD_LIST = [
    eventTypes.EVENT_MARKET_TYPE_MEMBER_REGISTER,
    eventTypes.EVENT_MARKET_TYPE_INVITE_REGISTER,
];
const SHOW_CONDITION_LIST = [
    eventTypes.EVENT_MARKET_TYPE_MEMBER_LOGIN,
    eventTypes.EVENT_MARKET_REMARK_PRODUCT,
    eventTypes.EVENT_MARKET_TYPE_SHARE_ORDER,
    eventTypes.EVENT_MARKET_TYPE_SHARE_PRODUCT,
    eventTypes.EVENT_MARKET_TYPE_SHARE_ARTICLE,
];
const SHOW_PERCENT_LIST = [eventTypes.EVENT_MARKET_TYPE_CREATE_ORDER];

const { Option } = Select;
export class EventMarketReward extends Component<EventMarketRewardProps> {
    static defaultProps = {
        maxCount: 3,
        disabled: false,
    };
    state = {
        showGold: false,
        goldText: services.language.getText('hellob'),
    };
    componentDidMount() {
        let { name, value, onChange } = this.props;
        this.getGoldText();
        if (!value) {
            let item: ActivityRewardValue = { couponsValue: [] };
            value = concat([], item);
            if (onChange) {
                onChange(value, name);
            }
        }
    }

    getGoldText = () => {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                apiPath: '/admin/activities/reward_items?event_type=SIGN_IN',
            }
        ).then((options: any) => {
            this.setState({
                showGold:
                    options &&
                    options.length &&
                    options.find((item: any) => item.code === RewardItems.gold),
                goldText:
                    options && options.length
                        ? options.find((item: any) => item.code === RewardItems.gold)?.name ||
                          services.language.getText('hellob')
                        : services.language.getText('hellob'),
            });
        });
    };

    onInputValueChange(event: any, index: number, type: InputTypes) {
        let { name, value, onChange } = this.props;
        let inputValue: number = event;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            if (type) {
                item[type] = inputValue;
            }
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: ActivityRewardValue = { couponsValue: [] };
            if (type) {
                item[type] = inputValue;
            }
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onCheckboxChange(event: any, index: number, type: string, txtName?: string) {
        let { name, value, onChange } = this.props;
        let checkboxValue = event.target.checked;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            if (type === RewardItems.point) {
                item.pointChecked = checkboxValue;
            }
            if (type === RewardItems.gold) {
                item.goldChecked = checkboxValue;
                item.goldTxtName = txtName;
            }
            if (type === RewardItems.experience) {
                item.experienceChecked = checkboxValue;
            }
            if (type === RewardItems.coupons) {
                item.couponsChecked = checkboxValue;
            }
            if (type === RewardItems.pointPercent) {
                item.pointPercentChecked = checkboxValue;
            }
            if (type === RewardItems.experiencePercent) {
                item.experiencePercentChecked = checkboxValue;
            }
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: ActivityRewardValue = { couponsValue: [] };
            if (type === RewardItems.point) {
                item.pointChecked = checkboxValue;
            }
            if (type === RewardItems.gold) {
                item.goldChecked = checkboxValue;
                item.goldTxtName = txtName;
            }
            if (type === RewardItems.experience) {
                item.experienceChecked = checkboxValue;
            }
            if (type === RewardItems.coupons) {
                item.couponsChecked = checkboxValue;
            }
            if (type === RewardItems.pointPercent) {
                item.pointPercentChecked = checkboxValue;
            }
            if (type === RewardItems.experiencePercent) {
                item.experiencePercentChecked = checkboxValue;
            }
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    render() {
        let { value, disabled } = this.props;
        return (
            <div className="event-market-avtivity-reward">
                {map(value, (reward, index) => this.renderReward(reward, index, disabled))}
                {value && value.length
                    ? null
                    : this.renderReward({ couponsValue: [] }, 0, disabled)}
            </div>
        );
    }

    onChangeCoupon = (products: Coupon[], index: number) => {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.couponsValue = products;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: ActivityRewardValue = { couponsValue: [] };
            item.couponsValue = products;
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    };

    renderCoupon = ({
        reward,
        index,
        isDisabled,
        couponsValue,
        subsiteIds,
    }: {
        reward: ActivityRewardValue;
        index: number;
        isDisabled: boolean;
        couponsValue: any;
        subsiteIds: any;
    }) => {
        return (
            <>
                <div>
                    <Checkbox
                        className="reward-content-item-checkbox"
                        onChange={(e) => this.onCheckboxChange(e, index, RewardItems.coupons)}
                        checked={reward.couponsChecked}
                        value="coupons"
                    >
                        {services.language.getText('yhq')}
                    </Checkbox>
                    <span className="reward-item-text">{services.language.getText('yhq')}</span>
                </div>
                <div className="reward-coupons">
                    <ActivityRewardItem
                        disabled={isDisabled}
                        index={index}
                        value={couponsValue}
                        subsiteIds={subsiteIds}
                        onChangeCoupon={this.onChangeCoupon}
                        type="checkbox"
                        packageSelectType="radio"
                        canChangeQuantity
                        showLeftStock
                    />
                </div>
            </>
        );
    };

    renderReward(reward: ActivityRewardValue, index: number, isDisabled: boolean) {
        const subsiteIds = this.getSubsiteIds(this.props);
        const { eventTypeId } = this.props;
        const { showGold, goldText } = this.state;
        let couponsValue: any;
        const paymentRewards: boolean = eventTypeId === eventTypes.EVENT_MARKET_TYPE_PAYMENT_REWARD;
        if (reward.couponsValue && reward.couponsValue.length > 0) {
            couponsValue = reward.couponsValue;
        }
        if (paymentRewards) {
            return (
                <div className="reward" key={index}>
                    <div className="reward-content-list">
                        {this.renderCoupon({ reward, index, isDisabled, couponsValue, subsiteIds })}
                    </div>
                </div>
            );
        }
        return (
            <div className="reward" key={index}>
                <div className="reward-content-list">
                    <div style={{ marginBottom: 10, width: 955 }}>
                        <Checkbox
                            className="reward-content-item-checkbox"
                            onChange={(e) => this.onCheckboxChange(e, index, RewardItems.point)}
                            checked={reward.pointChecked}
                            value="point"
                        >
                            {services.language.getText('point')}
                        </Checkbox>
                        <InputNumber
                            style={{ marginLeft: 5 }}
                            type="number"
                            disabled={isDisabled}
                            placeholder={services.language.getText('zdjl')}
                            min={1}
                            precision={0}
                            max={99999}
                            value={reward.pointMin}
                            onChange={(e) => this.onInputValueChange(e, index, InputTypes.pointMin)}
                        />
                        <span className="reward-item-text">~</span>
                        <InputNumber
                            disabled={isDisabled}
                            placeholder={services.language.getText('zgjl')}
                            type="number"
                            min={1}
                            precision={0}
                            max={99999}
                            value={reward.pointMax}
                            onChange={(e) => this.onInputValueChange(e, index, InputTypes.pointMax)}
                        />
                        <span className="reward-item-text">
                            {services.language.getText('point')}
                        </span>
                        <span className="reward-item-text">
                            {services.language.getText('sbjljq')}
                        </span>
                        <InputNumber
                            disabled={isDisabled}
                            type="number"
                            placeholder={services.language.getText('xt')}
                            min={1}
                            precision={0}
                            max={99999}
                            value={reward.doublePoint}
                            onChange={(e) =>
                                this.onInputValueChange(e, index, InputTypes.doublePoint)
                            }
                        />
                        <span className="reward-item-text">{services.language.getText('gr')}</span>
                        {SHOW_CONDITION_LIST.includes(eventTypeId) && (
                            <>
                                <span className="reward-item-text">
                                    {services.language.getText('m_2')}
                                </span>
                                <InputNumber
                                    disabled={isDisabled}
                                    type="number"
                                    placeholder={services.language.getText('xt')}
                                    min={1}
                                    precision={0}
                                    max={99999}
                                    value={reward.every}
                                    onChange={(e) =>
                                        this.onInputValueChange(e, index, InputTypes.every)
                                    }
                                />

                                <Select
                                    value={reward.condition ? reward.condition : 0}
                                    defaultValue={0}
                                    onChange={(e: any) =>
                                        this.onInputValueChange(e, index, InputTypes.condition)
                                    }
                                    style={{ width: 80, marginLeft: 15 }}
                                >
                                    <Option value={0}>{services.language.getText('yuan')}</Option>
                                    <Option value={1}>{services.language.getText('r_3')}</Option>
                                </Select>
                                <span className="reward-item-text">
                                    {services.language.getText('canGet')}
                                </span>
                                <InputNumber
                                    disabled={isDisabled}
                                    type="number"
                                    placeholder={services.language.getText('xt')}
                                    min={1}
                                    precision={0}
                                    max={99999}
                                    value={reward.result}
                                    onChange={(e) =>
                                        this.onInputValueChange(e, index, InputTypes.result)
                                    }
                                />
                                <span className="reward-item-text">
                                    {services.language.getText('cjl')}
                                </span>
                            </>
                        )}
                    </div>
                    <div
                        style={{
                            color: '#ccc',
                            margin: '0 0px 15px 136px',
                            lineHeight: '24px',
                        }}
                    >
                        <div>{services.language.getText('marketActivityTip1')}</div>
                        <div>{services.language.getText('marketActivityTip2')}</div>
                        <div>{services.language.getText('marketActivityTip3')}</div>
                    </div>

                    {SHOW_PERCENT_LIST.includes(eventTypeId) && (
                        <div style={{ marginBottom: 10 }}>
                            <Checkbox
                                className="reward-content-item-checkbox"
                                onChange={(e) =>
                                    this.onCheckboxChange(e, index, RewardItems.pointPercent)
                                }
                                checked={reward.pointPercentChecked}
                                value="pointPercent"
                            >
                                {services.language.getText('pointAndBaifen')}
                            </Checkbox>
                            <InputNumber
                                style={{ marginLeft: 5 }}
                                type="number"
                                disabled={isDisabled}
                                min={0}
                                precision={0}
                                max={99999}
                                value={reward.pointPercent}
                                onChange={(e) =>
                                    this.onInputValueChange(e, index, InputTypes.pointPercent)
                                }
                            />
                            <span className="reward-item-text">
                                % {services.language.getText('zfje')}
                            </span>
                        </div>
                    )}

                    {this.renderCoupon({ reward, index, isDisabled, couponsValue, subsiteIds })}

                    <div style={{ marginBottom: 10 }}>
                        <Checkbox
                            className="reward-content-item-checkbox"
                            onChange={(e) =>
                                this.onCheckboxChange(e, index, RewardItems.experience)
                            }
                            checked={reward.experienceChecked}
                            value="experience"
                        >
                            {services.language.getText('experienceManage')}
                        </Checkbox>
                        <InputNumber
                            style={{ marginLeft: 5 }}
                            type="number"
                            disabled={isDisabled}
                            placeholder={services.language.getText('zdjl')}
                            min={1}
                            precision={0}
                            max={99999}
                            value={reward.experienceMin}
                            onChange={(e) =>
                                this.onInputValueChange(e, index, InputTypes.experienceMin)
                            }
                        />
                        <span className="reward-item-text">~</span>
                        <InputNumber
                            disabled={isDisabled}
                            placeholder={services.language.getText('zgjl')}
                            type="number"
                            min={1}
                            precision={0}
                            max={99999}
                            value={reward.experienceMax}
                            onChange={(e) =>
                                this.onInputValueChange(e, index, InputTypes.experienceMax)
                            }
                        />
                        <span className="reward-item-text">
                            {services.language.getText('experienceManage')}
                        </span>
                        <span className="reward-item-text">
                            {services.language.getText('sbjljq')}
                        </span>
                        <InputNumber
                            disabled={isDisabled}
                            type="number"
                            min={1}
                            placeholder={services.language.getText('xt')}
                            precision={0}
                            max={99999}
                            value={reward.doubleExperience}
                            onChange={(e) =>
                                this.onInputValueChange(e, index, InputTypes.doubleExperience)
                            }
                        />
                        <span className="reward-item-text">{services.language.getText('gr')}</span>
                    </div>
                    <div
                        style={{
                            color: '#ccc',
                            margin: '0 0px 15px 136px',
                            lineHeight: '24px',
                        }}
                    >
                        <div>{services.language.getText('marketActivityTip4')}</div>
                        <div>{services.language.getText('marketActivityTip5')}</div>
                        <div>{services.language.getText('marketActivityTip3')}</div>
                    </div>
                    {SHOW_PERCENT_LIST.includes(eventTypeId) && (
                        <div style={{ marginBottom: 10 }}>
                            <Checkbox
                                className="reward-content-item-checkbox"
                                onChange={(e) =>
                                    this.onCheckboxChange(e, index, RewardItems.experiencePercent)
                                }
                                checked={reward.experiencePercentChecked}
                                value="experiencePercent"
                            >
                                {services.language.getText('experienceManageAndB')}
                            </Checkbox>
                            <InputNumber
                                style={{ marginLeft: 5 }}
                                type="number"
                                disabled={isDisabled}
                                min={0}
                                precision={0}
                                max={99999}
                                value={reward.experiencePercent}
                                onChange={(e) =>
                                    this.onInputValueChange(e, index, InputTypes.experiencePercent)
                                }
                            />
                            <span className="reward-item-text">
                                % {services.language.getText('zfje')}
                            </span>
                        </div>
                    )}

                    {SHOW_GOLD_LIST.includes(eventTypeId) && showGold && (
                        <div>
                            <Checkbox
                                className="reward-content-item-checkbox"
                                onChange={(e) =>
                                    this.onCheckboxChange(e, index, RewardItems.gold, goldText)
                                }
                                checked={reward.goldChecked}
                                value="gold"
                            >
                                {goldText}
                            </Checkbox>
                            <InputNumber
                                style={{ marginLeft: 5 }}
                                type="number"
                                disabled={isDisabled}
                                min={1}
                                precision={0}
                                max={99999}
                                value={reward.reward}
                                onChange={(e) =>
                                    this.onInputValueChange(e, index, InputTypes.reward)
                                }
                            />
                            <span className="reward-item-text">
                                {services.language.getText('jlz')}
                            </span>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    getSubsiteIds = (props: any) => {
        const { row } = props;
        const subsiteIds =
            row.baseInfo && row.baseInfo.subsiteIds ? map(row.baseInfo.subsiteIds, 'id') : null;
        return subsiteIds;
    };
}
