import React, { Component, createRef, CSSProperties } from 'react';
import BraftEditor, { EditorState, ExtendControlType } from 'braft-editor';
import {
    ContentBlock,
    ContentState,
    CompositeDecorator,
    DraftHandleValue,
    EditorState as DraftEditorState,
    Modifier,
    SelectionState,
    DraftRemovalDirection,
} from 'draft-js';
import { ContentUtils } from 'braft-utils';
import { WechatLinkModalForm } from './wechat-link-modal-form';
import WechatLinkMessage, { WechatLinkOptions } from './wechat-link-message';
import WechatUrlMessage, { WechatUrlOptions } from './wechat-url-message';
import WechatUrlModalForm from './wechat-url-modal-form';
import { WechatTextTargetVo } from '../wechant-content';
import { cloneDeep } from 'lodash';
import 'braft-editor/dist/index.css';
import { services } from '@comall-backend-builder/core';

export interface WechatTextEditorProps {
    value: string;
    onChange: (value: string) => void;
    targetList: Array<WechatTextTargetVo>;
    onChangeTargetList: (value: Array<WechatTextTargetVo>) => void;
    appId: string;
}
export interface WechatTextEditorStates {
    editorState: EditorState;
    linkModalFormVisible: boolean;
    urlModalFormVisible: boolean;
}

const maxLength = 1000;

/**
 * 动态创建订单调度台各个订单状态的tab
 */
export class WechatTextEditor extends Component<WechatTextEditorProps, WechatTextEditorStates> {
    static defaultProps: Partial<WechatTextEditorProps> = {
        value: '',
    };

    constructor(props: any) {
        super(props);
        this.state = {
            editorState: createEditorState(props.value, this.LinkDecorator),
            linkModalFormVisible: false,
            urlModalFormVisible: false,
        };
    }
    private draftEditorInstance = createRef<BraftEditor>();

    private LinkDecorator = new CompositeDecorator([
        {
            strategy: handleStrategy,
            component: this.Message,
        },
        {
            strategy: handleUrlStrategy,
            component: this.Url,
        },
    ]);
    private linkValue: Partial<WechatLinkOptions> = {};
    private linkFormMode: 'modify' | 'create' = 'create';

    private onEditorChange = (editorState: EditorState) => {
        const { onChange, value } = this.props;
        const newValue = editorState.getCurrentContent().getPlainText();
        if (newValue.length <= maxLength) {
            this.setState({ editorState });
            if (newValue !== value) {
                onChange(newValue);
            }
        } else {
            const sliceNewValue = newValue.slice(0, maxLength);
            const newEditorState = createEditorState(sliceNewValue, this.LinkDecorator);
            this.setState({ editorState: newEditorState });
            if (newValue !== value) {
                onChange(sliceNewValue);
            }
        }
    };

    private onLinkClick = (options: WechatLinkOptions) => {
        this.linkValue = options;
        this.linkFormMode = 'modify';
        this.showLinkModalForm();
    };

    private showLinkModalForm = () => {
        this.setState({ linkModalFormVisible: true });
    };

    private hideLinkModalForm = () => {
        this.setState({ linkModalFormVisible: false });
    };

    private onCreateLink(option: WechatLinkOptions) {
        const { onChange } = this.props;
        const { editorState } = this.state;
        const selection = editorState.getSelection();
        const link = this.createWechatLinkFromOptions(option);
        const newEditorState = ContentUtils.insertText(editorState, link) as EditorState;
        this.setState({
            // @ts-ignore
            editorState: DraftEditorState.forceSelection(
                newEditorState,
                SelectionState.createEmpty('').merge({
                    anchorKey: selection.getAnchorKey(),
                    anchorOffset: selection.getAnchorOffset() + link.length,
                    focusKey: selection.getFocusKey(),
                    focusOffset: selection.getFocusOffset() + link.length,
                })
            ),
        });
        const newValue = newEditorState.getCurrentContent().getPlainText();
        onChange(newValue);
    }

    private onModifyLink(option: WechatLinkOptions) {
        const { editorState } = this.state;
        const { onChange } = this.props;
        const contentState = editorState.getCurrentContent();
        const selection = editorState.getSelection();
        const block = contentState.getBlockForKey(selection.getFocusKey());
        const text = block.getText();
        const matches = text.matchAll(ANCHOR_REGEX);
        const selectionOffset = selection.getAnchorOffset();
        let match = matches.next();
        const newLink = this.createWechatLinkFromOptions(option);
        while (!match.done) {
            const start = match.value.index!;
            const end = start + match.value[0].length;
            if (isInRange(selectionOffset, [start, end])) {
                const newContentState = Modifier.replaceText(
                    contentState,
                    SelectionState.createEmpty('').merge({
                        anchorKey: selection.getAnchorKey(),
                        anchorOffset: start,
                        focusKey: selection.getFocusKey(),
                        focusOffset: end,
                    }),
                    newLink
                );
                const newEditorState = DraftEditorState.push(
                    editorState,
                    newContentState,
                    'insert-characters'
                );
                // @ts-ignore
                this.setState({ editorState: newEditorState });
                onChange(newContentState.getPlainText());
            }
            match = matches.next();
        }
    }
    private onDeleteLink = () => {
        const { onChange } = this.props;
        const { editorState } = this.state;
        const selection = editorState.getSelection();
        const contentState = editorState.getCurrentContent();
        const block = contentState.getBlockForKey(selection.getFocusKey());
        const text = block.getText();
        const matches = text.matchAll(ANCHOR_REGEX);
        let match = matches.next();
        while (!match.done) {
            const start = match.value.index!;
            const end = start + match.value[0].length;
            if (isInRange(selection.getAnchorOffset(), [start, end], 'backward')) {
                const newContentState = Modifier.removeRange(
                    contentState,
                    SelectionState.createEmpty('').merge({
                        anchorKey: selection.getAnchorKey(),
                        anchorOffset: start,
                        focusKey: selection.getFocusKey(),
                        focusOffset: end,
                    }),
                    'backward'
                );
                const newEditorState = DraftEditorState.push(
                    editorState,
                    newContentState,
                    'remove-range'
                );
                // @ts-ignore
                this.setState({ editorState: newEditorState });
                onChange(newContentState.getPlainText());
            }
            match = matches.next();
        }
    };

    private onCreatLinkClick = () => {
        this.linkFormMode = 'create';
        this.linkValue = {};
        this.showLinkModalForm();
    };

    private onLinkFormConfirm = (option: WechatLinkOptions) => {
        if (this.linkFormMode === 'create') {
            this.onCreateLink(option);
        } else if (this.linkFormMode === 'modify') {
            this.onModifyLink(option);
        }
        this.hideLinkModalForm();
    };
    private handleWechatLinkDeleteCommand(
        command: string,
        editorState: EditorState
    ): DraftHandleValue | void {
        if (['backspace', 'delete'].includes(command)) {
            const direction: DraftRemovalDirection =
                command === 'backspace' ? 'backward' : 'forward';
            const selection = editorState.getSelection();
            const contentState = editorState.getCurrentContent();
            const plainText = contentState.getPlainText();
            const selectionStartOffset = selection.getStartOffset();
            const selectionEndOffset = selection.getEndOffset();
            const matches = plainText.matchAll(ANCHOR_REGEX);
            let match = matches.next();
            while (!match.done) {
                let start = match.value.index!;
                let end = start + match.value[0].length;
                const range: Range = [start, end];
                if (
                    isInRange(selectionStartOffset, range, direction) ||
                    isInRange(selectionEndOffset, range, direction)
                ) {
                    start = Math.min(start, selectionStartOffset);
                    end = Math.max(end, selectionEndOffset);
                    const newContentState = Modifier.removeRange(
                        contentState,
                        SelectionState.createEmpty('').merge({
                            anchorKey: selection.getAnchorKey(),
                            anchorOffset: start,
                            focusKey: selection.getFocusKey(),
                            focusOffset: end,
                        }),
                        direction
                    );
                    const newEditorState = DraftEditorState.push(
                        editorState,
                        newContentState,
                        'remove-range'
                    );
                    this.setState({
                        // @ts-ignore
                        editorState: DraftEditorState.forceSelection(
                            newEditorState,
                            newContentState.getSelectionBefore()
                        ),
                    });
                    const { onChange } = this.props;
                    onChange(newContentState.getPlainText());
                    return 'handled';
                }
                match = matches.next();
            }
        }
    }
    private handleKeyCommand = (command: string, editorState: EditorState): DraftHandleValue => {
        return this.handleWechatLinkDeleteCommand(command, editorState) || 'not-handled';
    };

    private get Message() {
        return (props: any) => (
            <WechatLinkMessage onDelete={this.onDeleteLink} onClick={this.onLinkClick} {...props} />
        );
    }

    private get Url() {
        return (props: any) => (
            <WechatUrlMessage onDelete={this.onDeleteLink} onClick={this.onUrlClick} {...props} />
        );
    }

    private onCreatUrlClick = () => {
        this.linkFormMode = 'create';
        this.linkValue = {};
        this.showUrlModalForm();
    };

    private showUrlModalForm = () => {
        this.setState({ urlModalFormVisible: true });
    };

    private hideUrlModalForm = () => {
        this.setState({ urlModalFormVisible: false });
    };

    private onUrlFormConfirm = (option: WechatUrlOptions) => {
        if (this.linkFormMode === 'create') {
            this.onCreateUrl(option);
        } else if (this.linkFormMode === 'modify') {
            this.onModifyUrl(option);
        }
        this.hideUrlModalForm();
    };

    private onCreateUrl(option: WechatUrlOptions) {
        const { onChange } = this.props;
        const { editorState } = this.state;
        const selection = editorState.getSelection();
        const link = this.createWechatUrlFromOptions(option);
        const newEditorState = ContentUtils.insertText(editorState, link) as EditorState;
        this.setState({
            // @ts-ignore
            editorState: DraftEditorState.forceSelection(
                newEditorState,
                SelectionState.createEmpty('').merge({
                    anchorKey: selection.getAnchorKey(),
                    anchorOffset: selection.getAnchorOffset() + link.length,
                    focusKey: selection.getFocusKey(),
                    focusOffset: selection.getFocusOffset() + link.length,
                })
            ),
        });
        const newValue = newEditorState.getCurrentContent().getPlainText();
        onChange(newValue);
    }

    private onModifyUrl(option: WechatUrlOptions) {
        const { editorState } = this.state;
        const { onChange } = this.props;
        const contentState = editorState.getCurrentContent();
        const plainText = editorState.getCurrentContent().getPlainText();
        const matches = plainText.matchAll(ANCHOR_REGEX);
        const selection = editorState.getSelection();
        const selectionOffset = selection.getAnchorOffset();
        let match = matches.next();
        const newLink = this.createWechatUrlFromOptions(option);
        while (!match.done) {
            const start = match.value.index!;
            const end = start + match.value[0].length;
            if (isInRange(selectionOffset, [start, end])) {
                const newContentState = Modifier.replaceText(
                    contentState,
                    SelectionState.createEmpty('').merge({
                        anchorKey: selection.getAnchorKey(),
                        anchorOffset: start,
                        focusKey: selection.getFocusKey(),
                        focusOffset: end,
                    }),
                    newLink
                );
                const newEditorState = DraftEditorState.push(
                    editorState,
                    newContentState,
                    'insert-characters'
                );
                // @ts-ignore
                this.setState({ editorState: newEditorState });
                onChange(newContentState.getPlainText());
            }
            match = matches.next();
        }
    }

    private onUrlClick = (options: WechatLinkOptions) => {
        this.linkValue = options;
        this.linkFormMode = 'modify';
        this.showUrlModalForm();
    };

    private get extendControlsConfig(): ExtendControlType[] {
        return [
            {
                key: 'custom-mini',
                type: 'button',
                text: services.language.getText('xcxlj'),
                onClick: this.onCreatLinkClick,
            },
            {
                key: 'custom-url',
                type: 'button',
                text: services.language.getText('network'),
                onClick: this.onCreatUrlClick,
            },
        ];
    }
    private createWechatLinkFromOptions({ url, text, target }: WechatLinkOptions) {
        this.onSetTargetVoList(url, target);
        return `<a data-miniprogram-appid="{miniprogram-appid}" data-miniprogram-path="${url}">${text}</a>`;
    }
    private onSetTargetVoList = (url: string, target?: Object) => {
        //存储链接类型target信息
        const { onChangeTargetList, targetList } = this.props;
        const shareConditions: any = targetList
            ? targetList.find((t: any) => t.href === url)
            : undefined;
        const newtargetList = targetList ? cloneDeep(targetList) : [];
        if (!shareConditions) {
            newtargetList.push({
                href: url,
                target: target,
            });
        }
        if (onChangeTargetList) {
            onChangeTargetList(newtargetList);
        }
    };
    private createWechatUrlFromOptions({ url, text }: WechatUrlOptions) {
        return `<a  href="${url}">${text}</a>`;
    }

    private renderLength = () => {
        const { editorState } = this.state;
        const value = editorState.getCurrentContent().getPlainText();
        const style: CSSProperties = {
            position: 'absolute',
            bottom: '10px',
            right: '10px',
            color: value.length >= maxLength ? 'red' : '#000',
        };
        return (
            <div style={style}>
                <span>
                    {value.length}/{maxLength}
                </span>
            </div>
        );
    };

    render() {
        const { editorState, linkModalFormVisible, urlModalFormVisible } = this.state;
        const { targetList, appId } = this.props;
        return (
            <div>
                <div style={{ border: '1px solid #eee', position: 'relative' }}>
                    <BraftEditor
                        ref={this.draftEditorInstance}
                        controls={['emoji']}
                        value={editorState}
                        onChange={this.onEditorChange}
                        extendControls={this.extendControlsConfig}
                        handleKeyCommand={this.handleKeyCommand}
                        placeholder={services.language.getText('qsrwz')}
                    />
                    {this.renderLength()}
                    {/* 原计划该组件分别在control和WechatLinkMessage使用 */}
                    {/* 但是WechatLinkMessage中使用时会有focus冲突问题，只能提到editor外部了 */}
                    {linkModalFormVisible && (
                        <WechatLinkModalForm
                            visible={linkModalFormVisible}
                            onCancel={this.hideLinkModalForm}
                            onConfirm={this.onLinkFormConfirm}
                            value={this.linkValue}
                            targetList={targetList}
                            appId={appId}
                        />
                    )}
                    <WechatUrlModalForm
                        visible={urlModalFormVisible}
                        onCancel={this.hideUrlModalForm}
                        onConfirm={this.onUrlFormConfirm}
                        value={this.linkValue}
                    />
                </div>
            </div>
        );
    }
}

const APPID_ANCHOR_REGEX = /<a\b[^>]*appid\b[^>]*>.*?<\/a>/g;
const ANCHOR_REGEX = /<a.*?>.*?<\/a>/g;
type AnyFunction = (...args: any[]) => unknown;

function findWithRegex(regex: RegExp, text: string, callback: AnyFunction) {
    let matchArr, start;
    while ((matchArr = regex.exec(text)) !== null) {
        start = matchArr.index;
        callback(start, start + matchArr[0].length);
    }
}
function handleStrategy(contentBlock: ContentBlock, callback: AnyFunction) {
    findWithRegex(APPID_ANCHOR_REGEX, contentBlock.getText(), callback);
}
function handleUrlStrategy(contentBlock: ContentBlock, callback: AnyFunction) {
    findWithRegex(ANCHOR_REGEX, contentBlock.getText(), callback);
}

function createEditorState(value: string, decorator: CompositeDecorator): EditorState {
    const contentState = ContentState.createFromText(value);
    return ContentUtils.createEditorState(contentState, decorator);
}

type Range = [number, number];

function isInRange(position: number, range: Range, direction?: DraftRemovalDirection) {
    const [start, end] = range.sort((a, b) => a - b);
    if (direction === 'backward') {
        return position > start && position <= end;
    }
    if (direction === 'forward') {
        return position >= start && position < end;
    }
    return position > start && position < end;
}
