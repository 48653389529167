import { Button } from '@comall-backend-builder/components-basis';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { api, errorHandle, language } from '@comall-backend-builder/core/lib/services';
import { message as AntMessage, Popover, DatePicker } from 'antd';
import React, { Component } from 'react';

const { RangePicker } = DatePicker;
export interface ContentNoteTopProps {
    fields: any;
    row: any;
    entity: Entity;
    componentId: string;
}
interface ContentNoteTopState {
    visible: boolean;
    startTime: string;
    endTime: string;
}

export class ContentNoteTop extends Component<ContentNoteTopProps, ContentNoteTopState> {
    constructor(props: ContentNoteTopProps) {
        super(props);
        this.state = {
            visible: false,
            startTime: '',
            endTime: '',
        };
    }

    deleteTop = () => {
        const {
            entity,
            row: { id },
        } = this.props;
        api.delete(
            {},
            {
                apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
                apiPath: `/admin/notes/${id}/cancel_top`,
            }
        )
            .then(() => {
                AntMessage.success(language.getText('qxcg'));

                // 列表数据刷新
                entity.search({
                    ...entity.params,
                    ...entity.filters,
                });
            })
            .catch(errorHandle);
    };
    // 提交删除
    submit = () => {
        const {
            entity,
            row: { id },
        } = this.props;
        const { startTime, endTime } = this.state;
        const data: any = { noteId: id };
        if (startTime && endTime) {
            data.startTime = startTime;
            data.endTime = endTime;
        }
        api.post(data, {
            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
            apiPath: '/admin/notes/top',
        })
            .then(() => {
                AntMessage.success(language.getText('zdcg'));

                this.hide();

                entity.search({
                    ...entity.params,
                    ...entity.filters,
                });
            })
            .catch(errorHandle);
    };
    hide = () => {
        this.setState({
            visible: false,
        });
    };

    handleVisibleChange = (visible: boolean) => {
        this.setState({ visible });
    };
    renderTopForm = () => {
        return (
            <div>
                <RangePicker
                    showTime={{ format: 'HH:mm' }}
                    format="YYYY-MM-DD HH:mm"
                    placeholder={[language.getText('ksrq'), language.getText('jsrq')]}
                    onChange={this.onDateChange}
                />
                <div style={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <Button
                        type="primary"
                        style={{ marginTop: '10px' }}
                        onClick={this.submit}
                        text={language.getText('common.ok')}
                    />
                </div>
            </div>
        );
    };
    onDateChange = (val: any) => {
        let date = val.map((i: any) => i.format('YYYY-MM-DD HH:mm:ss'));
        this.setState({
            startTime: date[0],
            endTime: date[1],
        });
    };

    render() {
        const { visible } = this.state;
        const {
            row: { topType, auditStatus, detailStatus },
        } = this.props;
        return (
            <span>
                {topType === 'TOP' && (
                    <Button
                        type="link"
                        style={{ marginLeft: '10px' }}
                        onClick={this.deleteTop}
                        text={language.getText('qxzd')}
                    />
                )}
                {topType === 'NOT_TOP' && (
                    <Popover
                        content={this.renderTopForm()}
                        title={language.getText('qxzzdsjd')}
                        trigger="click"
                        visible={visible}
                        placement="bottomLeft"
                        onVisibleChange={this.handleVisibleChange}
                    >
                        <Button
                            type="link"
                            style={{ marginLeft: '10px' }}
                            disabled={auditStatus !== 'SUCCESS' || detailStatus !== 'NORMAL'}
                            text={language.getText('zd')}
                        />
                    </Popover>
                )}
            </span>
        );
    }
}
