import React, { PureComponent } from 'react';
import { forEach, cloneDeep, findIndex, map, isEqual, isEmpty } from 'lodash';
import {
    ProductCategorySelectedDisplay,
    ProductImportConfig,
    ProductsCategorySelector,
    DistributionProductSelectedDisplay,
} from '../../../components';

export enum ProductCategorySelectMode {
    SUB_SITE = 'SUB_SITE',
    MERCHANT = 'MERCHANT',
    TAG = 'TAG',
}

/**
 * 选择商品品牌组件
 */
export class SelectProductCategory extends PureComponent<{
    value: {
        values: any[];
        type: string;
    };
    onChange: (v: any) => void;
    row: any;
    productDisplaySelectValueType: string;
    subsiteSelectMode: string;
    disableBand?: boolean;
    isSupportMerchantBigCode?: boolean;
    disabled: boolean;
    selectProductTypes?: string[];
    verifyName?: string;
    verifyTip?: string;
    importConfig?: ProductImportConfig;
    memberIdentity?: string;
    filterFields?: any;
}> {
    state = {
        value: {
            values: [],
            type: 'APPOINT_PRODUCT',
        },
        displayType: 'APPOINT_PRODUCT',
    };

    componentDidMount() {
        const { value } = this.props;
        if (value && value.type) {
            this.setState({
                displayType: value.type,
            });
        }
    }

    getActivityProductType = (props: any) => {
        const { row } = props;
        let activityProductType = 'ALL';
        if (row.ruleInfo && row.ruleInfo.activityProductType) {
            activityProductType = row.ruleInfo.activityProductType;
        }
        if (row.productInfo && row.productInfo.activityProductType) {
            activityProductType = row.productInfo.activityProductType;
        }
        return activityProductType;
    };
    getSelectMode = (props: any) => {
        const { row, handleSelectMode } = props;
        if (handleSelectMode && typeof handleSelectMode === 'function') {
            return handleSelectMode(row);
        }
        let selectMode = '';
        if (row.ruleInfo && row.ruleInfo.selectMode) {
            selectMode = row.ruleInfo.selectMode;
        } else if (row.productInfo && row.productInfo.selectMode) {
            selectMode = row.productInfo.selectMode;
        } else if (this.props.subsiteSelectMode === 'ONLY_SUBSITE') {
            selectMode = ProductCategorySelectMode.SUB_SITE;
        }
        return selectMode;
    };
    getSubsiteIds = (props: any) => {
        const { row } = props;
        //门店单选时，取值subsiteId，多选时取subsites
        let subsiteIds;
        if (row.subsiteId || (row.ruleInfo && row.ruleInfo.subsiteId)) {
            subsiteIds = row.subsiteId ? row.subsiteId : row.ruleInfo.subsiteId;
        } else {
            let subsites = row.subsites;
            if (row.ruleInfo && row.ruleInfo.subsites) {
                subsites = row.ruleInfo.subsites;
            }
            if (row.productInfo && row.productInfo.subsite) {
                subsites = row.productInfo.subsite;
            }
            if (props.verifyName) {
                const verifyNameArr = props.verifyName.split('.');
                row[verifyNameArr[0]] &&
                    row[verifyNameArr[0]][verifyNameArr[1]] &&
                    (subsites = row[verifyNameArr[0]][verifyNameArr[1]]);
            }
            subsiteIds = subsites ? map(subsites, 'id') : null;
        }

        return subsiteIds;
    };
    getMerchantIds = (props: any) => {
        const { row } = props;
        let merchants = row.merchants;
        if (row.ruleInfo && row.ruleInfo.merchants) {
            merchants = row.ruleInfo.merchants;
        }
        if (row.productInfo && row.productInfo.shop) {
            merchants = row.productInfo.shop;
        }
        const merchantIds = merchants
            ? map(merchants, (merchant) => {
                  //部分组件id为"门店id,专柜id"组合，需要截取获得专柜id
                  let merchantId = merchant.id.split(',')[1];
                  //如果id不为组合时，直接取专柜id
                  if (!merchantId) {
                      merchantId = merchant.merchantId;
                  }
                  if (!merchantId) {
                      merchantId = merchant.id;
                  }
                  return merchantId;
              })
            : null;
        return merchantIds;
    };

    private getMerchants = () => {
        const { row } = this.props;
        let merchants = row.merchants;
        if (row.ruleInfo && row.ruleInfo.merchants) {
            merchants = row.ruleInfo.merchants;
        }
        if (row.productInfo && row.productInfo.shop) {
            merchants = row.productInfo.shop;
        }
        return map(merchants, (merchant) => {
            const subsiteId = merchant.subSiteId;
            //部分组件id为"门店id,专柜id"组合，需要截取获得专柜id
            let merchantId = merchant.id.split(',')[1];
            //如果id不为组合时，直接取专柜id
            if (!merchantId) {
                merchantId = merchant.merchantId;
            }
            if (!merchantId) {
                merchantId = merchant.id;
            }
            return { merchantId, subsiteId };
        });
    };

    private getMerchantsForTag = () => {
        const { row } = this.props;
        let tags = [];
        if (row.ruleInfo && row.ruleInfo.tags) {
            tags = row.ruleInfo.tags;
        }
        return map(tags, (tag) => ({ merchantId: tag.id, subsiteId: tag.subsiteId }));
    };

    getMerchantSubsiteIds = (props: any) => {
        const { row } = props;
        let merchants = row.merchants;
        if (row.ruleInfo && row.ruleInfo.merchants) {
            merchants = row.ruleInfo.merchants;
        }
        if (row.productInfo && row.productInfo.shop) {
            merchants = row.productInfo.shop;
        }
        const subsite = merchants
            ? map(merchants, (merchant) => {
                  let subsiteId = merchant.subsiteId || merchant.subSiteId || '';
                  //部分组件id为"门店id,专柜id"组合，需要截取获得门店id
                  let merchantId = merchant.id.split(',')[1];
                  if (merchantId) {
                      subsiteId = merchant.id.split(',')[0];
                  }

                  return subsiteId;
              }).filter((s) => s !== '')
            : null;
        const subsiteIds = Array.from(new Set(subsite));
        return subsiteIds;
    };

    getMerchantIdsByTag = (props: any) => {
        const { row } = props;
        let tags;
        if (row.ruleInfo && row.ruleInfo.tags) {
            tags = row.ruleInfo.tags;
        }
        const merchantIds = tags ? map(tags, (tag) => tag.id) : null;
        return merchantIds;
    };

    getSubsiteIdsByTag = (props: any) => {
        const { row } = props;
        let tags;
        if (row.ruleInfo && row.ruleInfo.tags) {
            tags = row.ruleInfo.tags;
        }
        const subsiteIds = tags ? map(tags, (tag) => tag.subsiteId) : null;
        return subsiteIds;
    };
    getSubsite = (props: any, type: string) => {
        const { row, verifyName } = props;
        const verifyNameArr = verifyName?.split('.');
        let merchantIds: any = [];
        if (row[verifyNameArr[0]] && row[verifyNameArr[0]][verifyNameArr[1]]) {
            const lastData = row[verifyNameArr[0]][verifyNameArr[1]];
            if (Array.isArray(lastData)) {
                lastData.forEach((item) => {
                    merchantIds.push(item[type]);
                });
            } else {
                merchantIds.push(lastData);
            }
        }
        return merchantIds;
    };

    componentWillReceiveProps(nextProps: any) {
        // 获取props变更前后的选择专柜/门店模式
        const nextSelectMode = this.getSelectMode(nextProps);
        const currentSelectMode = this.getSelectMode(this.props);
        const hasNextValue = nextProps.value && !isEmpty(nextProps.value.values);
        // 如果选择模式没有变化，并且当前模式是专柜或者标签模式，检查是否有变化
        if (nextSelectMode === currentSelectMode) {
            if (
                nextSelectMode === ProductCategorySelectMode.MERCHANT ||
                nextSelectMode === ProductCategorySelectMode.TAG
            ) {
                const nextMerchantIds =
                    nextSelectMode === ProductCategorySelectMode.MERCHANT
                        ? this.getMerchantIds(nextProps)
                        : this.getMerchantIdsByTag(nextProps);
                const currentMerchantIds =
                    nextSelectMode === ProductCategorySelectMode.MERCHANT
                        ? this.getMerchantIds(this.props)
                        : this.getMerchantIdsByTag(this.props);

                if (!isEmpty(currentMerchantIds) && isEmpty(nextMerchantIds)) {
                    // 如果选择专柜被清空，同时清空选中的数据
                    this.onChange({ values: [], type: 'APPOINT_PRODUCT' });
                } else if (
                    !isEqual(nextMerchantIds, currentMerchantIds) &&
                    nextMerchantIds &&
                    hasNextValue
                ) {
                    this.processDataAfterMerchantChange(nextMerchantIds, nextProps);
                }
            } else {
                const nextSubsiteIds = this.getSubsiteIds(nextProps);
                const currentSubsiteIds = this.getSubsiteIds(this.props);
                if (
                    !isEmpty(nextSubsiteIds) &&
                    !isEmpty(currentSubsiteIds) &&
                    !isEqual(nextSubsiteIds, currentSubsiteIds) &&
                    hasNextValue
                ) {
                    this.processDataAfterSubsiteChange(nextSubsiteIds, nextProps);
                }
                if (isEmpty(nextSubsiteIds) && hasNextValue) {
                    this.onChange({ values: [], type: 'APPOINT_PRODUCT' });
                }
            }
        } else {
            if (!!currentSelectMode && this.props.subsiteSelectMode !== 'ONLY_SUBSITE') {
                // 如果选择模式发生变化，清空选中数据
                this.onChange({ values: [], type: 'APPOINT_PRODUCT' });
            }
            if (!!currentSelectMode && this.props.subsiteSelectMode === 'ONLY_SUBSITE') {
                const currentMerchantIds = this.getMerchantIds(this.props);
                const nextMerchantIds = this.getMerchantIds(nextProps);

                if (!isEmpty(currentMerchantIds) && isEmpty(nextMerchantIds)) {
                    // 如果选择专柜被清空，同时清空选中的数据
                    this.onChange({ values: [], type: 'APPOINT_PRODUCT' });
                } else if (
                    !isEqual(nextMerchantIds, currentMerchantIds) &&
                    nextMerchantIds &&
                    hasNextValue
                ) {
                    this.processDataAfterMerchantChange(nextMerchantIds, nextProps);
                }
            }
        }
        //如果活动商品发生变化，则清空数据
        const nextActivityProductType = this.getActivityProductType(nextProps);
        const currentActivityProductType = this.getActivityProductType(this.props);
        if (
            currentActivityProductType !== 'ALL' &&
            nextActivityProductType !== currentActivityProductType
        ) {
            // 如果选择模式发生变化，清空选中数据
            this.onChange({ values: [], type: 'APPOINT_PRODUCT' });
        }
    }

    processDataAfterMerchantChange = (merchantIds: string[], props: any) => {
        const { value } = props;
        const newData: any[] = [];
        if (value && value.values) {
            forEach(value.values, (i) => {
                if (
                    merchantIds.indexOf(i.merchantId + '') > -1 ||
                    merchantIds.indexOf(i.merchantId) > -1
                ) {
                    newData.push(i);
                }
            });
        }

        this.onChange({ values: newData, type: value.type });
    };

    processDataAfterSubsiteChange = (subsiteIds: string[], props: any) => {
        const { value } = props;
        const newData: any[] = [];
        if (value && value.values) {
            forEach(value.values, (i) => {
                if (
                    subsiteIds.indexOf(i.subsiteId + '') > -1 ||
                    subsiteIds.indexOf(i.subsiteId) > -1
                ) {
                    newData.push(i);
                }
            });
        }

        this.onChange({ values: newData, type: value.type });
    };

    processSelectedProduct = (data: any[]) => {
        const { productDisplaySelectValueType } = this.props;
        let items: any[] = [];
        if (data && data.length) {
            if (productDisplaySelectValueType === 'goods') {
                //保存货品维度
                forEach(data, (product) => {
                    forEach(product.goodsVos, (goods: any) => {
                        items.push({
                            id: goods.id,
                            name: goods.name,
                            productName: product.name,
                            productCode: product.barcode,
                            goodsCode: goods.csku,
                            subsiteId: goods.subsiteId + '',
                            subsiteName: goods.subsiteName,
                            merchantName: goods.merchantName,
                            marketPrice: (+goods.marketPrice.value).toFixed(2),
                            salesPrice: (+goods.salesPrice.value).toFixed(2),
                            goodsStock: goods.stock,
                        });
                    });
                });
            } else {
                //保存商品维度
                forEach(data, (product) => {
                    items.push({
                        id: product.id,
                        name: product.name,
                        code: product.barcode,
                        merchantId: product.goodsMvo.merchantId,
                        merchantName: product.goodsMvo.merchantName,
                    });
                });
            }
        }
        return items;
    };
    //分销有礼-保存货品维度
    processSelectedProductDistributor = (data: any[]) => {
        const { memberIdentity } = this.props;
        let items: any[] = [];
        if (data && data.length) {
            //分销有礼-保存货品维度
            forEach(data, (product) => {
                forEach(product.goodsVos, (goods: any) => {
                    const salesPrice = goods.salesPrice.value;
                    const percentage = goods?.percentage ? Number(goods.percentage) / 100 : 0;
                    items.push({
                        id: goods.id,
                        name: goods.name,
                        productName: product.name,
                        productCode: product.barcode,
                        productStyleCode: goods.csku,
                        subsiteName: goods.subsiteName,
                        subsiteId: goods.subsiteId,
                        merchantName: goods.merchantName,
                        salesPrice,
                        stock: goods.stock,
                        commissionInfos: [
                            {
                                // identityLevel: '分销员',
                                identityLevelId: '1',
                                percentage,
                                memberIdentity: memberIdentity,
                            },
                        ],
                        commissionValue: ((salesPrice * percentage * 100) / 100).toFixed(2),
                    });
                });
            });
        }
        return items;
    };

    onDisplayChange = (values: any[]) => {
        const { displayType } = this.state;
        this.onChange({ values, type: displayType });
    };

    onChange = (value: { values: any[]; type: string }) => {
        const { onChange } = this.props;
        onChange(cloneDeep(value));
    };

    onSelect = (newValue: { values: any[]; type: string }, overwrite = false) => {
        this.setState({ displayType: newValue.type });
        const { memberIdentity } = this.props;

        let newData: any[] = [];
        if (newValue.type === 'APPOINT_PRODUCT') {
            newData =
                memberIdentity === 'DISTRIBUTOR'
                    ? this.processSelectedProductDistributor(newValue.values)
                    : this.processSelectedProduct(newValue.values);
        } else {
            newData =
                memberIdentity === 'DISTRIBUTOR'
                    ? this.processSelectedProductDistributor(newValue.values)
                    : newValue.values;
        }

        const { value } = this.props;
        const preType = value ? value.type : '';
        let data: any[] = [];
        if (value) {
            data = cloneDeep(value.values || []);
        }
        if (preType === newValue.type && !overwrite) {
            if (data) {
                data.forEach((i: any) => {
                    const hasIndex = findIndex(
                        newData,
                        (o: any) => o.id.toString() === i.id.toString()
                    );
                    if (hasIndex === -1) {
                        newData.push(i);
                    } else {
                        newData[hasIndex] = i;
                    }
                });
            }
        }

        this.onChange({ values: newData, type: newValue.type });
    };

    render() {
        const {
            value,
            productDisplaySelectValueType,
            disableBand,
            isSupportMerchantBigCode,
            disabled,
            selectProductTypes,
            verifyName,
            verifyTip,
            importConfig,
            memberIdentity,
            filterFields,
        } = this.props;
        const { displayType } = this.state;
        const hasValue = value && value.values.length > 0;
        //全民分销
        const distributor = memberIdentity && memberIdentity === 'DISTRIBUTOR';
        const selectMode = this.getSelectMode(this.props);
        const activityProductType = this.getActivityProductType(this.props);
        const params: any = {};
        if (selectProductTypes) {
            params.productTypes = selectProductTypes;
        }

        switch (selectMode) {
            case ProductCategorySelectMode.SUB_SITE:
                params.subsiteIds = this.getSubsiteIds(this.props);
                break;
            case ProductCategorySelectMode.MERCHANT:
                params.merchantIds = this.getMerchantIds(this.props);
                params.subsiteIds = this.getMerchantSubsiteIds(this.props);
                params.merchants = this.getMerchants();
                break;
            case ProductCategorySelectMode.TAG:
                params.merchantIds = this.getMerchantIdsByTag(this.props);
                params.subsiteIds = this.getSubsiteIdsByTag(this.props);
                params.merchants = this.getMerchantsForTag();
        }
        if (verifyName) {
            params.subsiteIds = this.getSubsite(this.props, 'subSiteId');
            verifyName.includes('merchants') &&
                (params.merchantIds = this.getSubsite(this.props, 'merchantId'));
        }
        return (
            <div className="cgb-select-product">
                <ProductsCategorySelector
                    selectMode={selectMode}
                    onChange={this.onSelect}
                    params={params}
                    verifyTip={verifyTip}
                    activityProductType={activityProductType}
                    disableBand={disableBand}
                    isSupportMerchantBigCode={isSupportMerchantBigCode}
                    disabled={disabled}
                    importConfig={importConfig}
                    displayType={displayType}
                    filterFields={filterFields}
                />
                {hasValue && activityProductType !== 'ALL' && !distributor && (
                    <ProductCategorySelectedDisplay
                        modifiable={!disabled}
                        data={value.values}
                        onChange={this.onDisplayChange}
                        type={value.type}
                        productDisplaySelectValueType={productDisplaySelectValueType}
                    ></ProductCategorySelectedDisplay>
                )}
                {hasValue && activityProductType !== 'ALL' && distributor && (
                    <DistributionProductSelectedDisplay
                        modifiable={true}
                        data={value.values}
                        onChange={this.onDisplayChange}
                        disabled={false}
                    ></DistributionProductSelectedDisplay>
                )}
            </div>
        );
    }
}
