import React from 'react';

import { ArrayMode } from '@comall-backend-builder/types';
import { MaotaiDrawQualityLimit } from '../../../containers';

export class MaotaiDrawQualityLimitMode extends ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <MaotaiDrawQualityLimit {...controlInfo} />;
    }
}
