import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const tableData = {
    page: 1,
    perPage: 10,
    totalNum: 2,
    totalPage: 1,
    result: [
        {
            id: 0,
            enabled: true,
            storeStatus: 1,
            link: {
                text: 123,
                url: `/8005/order/viewDetail/${123}`,
            },
            expLower: 1,
            expUpper: 10,
            statusBadge: 'NOT_STARTED',
            stringPopover:
                '很长的一段文字很长的一段文字很长的一段文字很长的一段文字很长的一段文字很长的一段文字',
            arrayPopover: [
                { id: 1, name: '哈哈' },
                { id: 2, name: '呵呵' },
                { id: 3, name: '吼吼' },
            ],
            refuseText: [],
        },
    ],
};
const formData = {};
export const ComponentsDemoLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const { id } = data;
        if (id) {
            return formData;
        } else {
            return tableData;
        }
    },

    post: async function(params, config: ApiRequestConfig) {
        console.log(params, config);
    },

    put: async function(params, config: ApiRequestConfig) {
        console.log(params, config);
    },
};
