import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { TipsModalContentType } from '../../../components';
import { extraElement } from '../../../services/extra-element';
import get from 'lodash/get';
import { language } from '@comall-backend-builder/core/lib/services';
enum IndoorMapProviderType {
    fengNiao = 'FENG_NIAO',
    qianMu = 'QIAN_MU',
    none = 'NONE',
}
export const config: Config = {
    entities: {
        SubsiteMessageEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/subsite',
            filters: {
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                    controlConfig: {
                        placeholder: '<<subsiteNameRequired>>',
                        style: { width: 200 },
                    },
                },

                status: {
                    type: 'string.options.select',
                    displayName: '<<subsiteStatus>>',
                    options: [
                        { id: 'ENABLE', name: '<<subsiteStatusEnable>>' },
                        { id: 'DISABLE', name: '<<subsiteStatusDisable>>' },
                    ],
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                code: {
                    type: 'string',
                    displayName: '<<subsiteCode>>',
                    controlConfig: {
                        placeholder: '<<codeRequired>>',
                        style: { width: 200 },
                    },
                },
            },
            properties: {
                code: {
                    type: 'string',
                    displayName: '<<subsiteCode>>',
                    rules: [
                        { required: true, message: '<<codeNotEmpty>>' },
                        // {
                        //     pattern: /^[A-Za-z0-9]{0,5}$/,
                        //     message: '请输入输入字母+数字，最多输入5位',
                        // },
                    ],

                    controlConfig: {
                        style: { width: 250 },
                        placeholder: '<<codeRules>>',
                        // maxLength: 5,
                    },
                },

                douyinPoiId: {
                    type: 'string.modalTips',
                    displayName: '<<dyId>>',
                    controlConfig: {
                        tipsModalConfig: {
                            contentType: TipsModalContentType.DouYingLaiKeyPicture,
                            showIcon: false,
                            text: '<<example>>',
                            textColor: '#1890ff',
                            title: '<<example>>',
                            width: 800,
                        },
                        style: {
                            width: 300,
                        },
                        placeholder: '<<dyId>>',
                        maxLength: 999,
                    },
                    extra: '<<qwdylksjht>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                    rules: [{ required: true, message: '<<subsiteNameNotEmpty>>' }],
                    controlConfig: {
                        style: { width: 250 },
                        placeholder: '<<subsiteNameRules>>',
                        maxLength: 20,
                    },
                },
                contactName: {
                    type: 'string',
                    displayName: '<<contactName>>',
                    rules: [{ required: true, message: '<<contactNameNotEmpty>>' }],
                    controlConfig: {
                        style: { width: 250 },
                        placeholder: '<<contactNameRequired>>',
                    },
                },
                mobile: {
                    type: 'string.phone',
                    displayName: '<<mobile>>',
                    controlConfig: {
                        placeholder: '<<mobileRequired>>',
                        maxLength: 20,
                        style: { width: 250 },
                    },
                },
                servicePhone: {
                    type: 'string.phone',
                    displayName: '<<servicePhone>>',
                    controlConfig: {
                        placeholder: '<<servicePhoneRequired>>',
                        maxLength: 20,
                        style: { width: 250 },
                    },
                },
                servicePic: {
                    type: 'imageObjectArray',
                    displayName: '客服企微',
                    className: 'sigle-image-field',
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        className: 'sigle-image-field',
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                    extra: extraElement({
                        tips: [
                            '可直接上传门店客服企业微信图片或客服微信，建议图片宽度为260px，高度为260px；大小不超过1M。 {modal}',
                        ],
                        tipsModal: {
                            contentType: TipsModalContentType.SubsiteServicePic,
                            showIcon: false,
                            text: '使用场景示例说明',
                            textColor: '#1890ff',
                            title: '',
                            width: 1000,
                        },
                    }),
                },
                indoorMapProvider: {
                    type: 'string.options.select',
                    displayName: '<<indoorMapProvider>>',
                    defaultValue: IndoorMapProviderType.none,
                    options: [
                        {
                            id: IndoorMapProviderType.fengNiao,
                            name: '<<indoorMapProviderFengNiao>>',
                        },
                        { id: IndoorMapProviderType.qianMu, name: '<<indoorMapProviderQianMu>>' },
                        { id: IndoorMapProviderType.none, name: '<<indoorMapProviderNone>>' },
                    ],
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                mapUrl: {
                    type: 'string',
                    displayName: '<<mapUrl>>',
                    rules: [{ required: true, message: '<<mapUrlNotEmpty>>' }],
                    controlConfig: {
                        style: { width: 700 },
                        placeholder: '<<mapUrlRequired>>',
                    },
                },
                mapId: {
                    type: 'string',
                    displayName: '<<mapId>>',
                    rules: [{ required: true, message: '<<mapIdNotEmpty>>' }],
                    controlConfig: {
                        style: { width: 250 },
                        placeholder: '<<mapIdRequired>>',
                    },
                },
                mapKey: {
                    type: 'string',
                    displayName: '<<mapKey>>',
                    rules: [{ required: true, message: '<<mapKeyRequired>>' }],
                    controlConfig: {
                        style: { width: 250 },
                        placeholder: '<<mapKeyRequired>>',
                    },
                },
                mapAppName: {
                    type: 'string',
                    displayName: '<<mapAppName>>',
                    rules: [{ required: true, message: '<<mapAppNameNotEmpty>>' }],
                    controlConfig: {
                        style: { width: 250 },
                        placeholder: '<<mapAppNameRequired>>',
                    },
                },
                mapThemeId: {
                    type: 'string',
                    displayName: '<<mapThemeId>>',
                    controlConfig: {
                        style: { width: 250 },
                        placeholder: '<<mapThemeIdRequired>>',
                    },
                    extra: '<<mapThemeIdExtra>>',
                },
                //门店图片
                pic: {
                    type: 'imageObjectArray',
                    displayName: '<<subsitePic>>',
                    className: 'sigle-image-field',
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        className: 'sigle-image-field',
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                    extra: '<<subsiteExtra>>',
                },
                logo: {
                    type: 'imageObjectArray',
                    displayName: '<<subsiteLogo>>',
                    className: 'sigle-image-field',
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        className: 'sigle-image-field',
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                        maxSize: 1 * 1024,
                    },
                    extra: '<<subsiteLogoExtra>>',
                },
                region: {
                    type: 'object.select.city.code',
                    displayName: '<<subsiteRegion>>',
                    rules: [{ required: true, message: '<<regionNotEmpty>>' }],
                    controlConfig: {
                        style: { width: 250 },
                        placeholder: '<<selectPlease>>',
                    },
                },
                address: {
                    type: 'string',
                    displayName: '<<subsiteAddress>>',
                    rules: [{ required: true, message: '<<subsiteNotEmpty>>' }],
                    controlConfig: {
                        style: { width: 250 },
                    },
                },
                subsitePosition: {
                    type: 'object.map.scalSelect',
                    displayName: '<<subsitePosition>>',
                    rules: [{ required: true, message: '<<subsitePositionNotEmpty>>' }],
                    controlConfig: {
                        style: { width: 250 },
                        placeholder: '<<selectPlease>>',
                    },
                },
                autoPosition: {
                    type: 'string.radioIllustrate',
                    displayName: '<<autoPosition>>',
                    name: 'subsite-autoPosition',
                    defaultValue: '1',
                    options: [
                        { id: '1', name: '<<allow>>' },
                        { id: '0', name: '<<notAllow>>' },
                    ],
                    rules: [{ required: true }],
                    controlConfig: {
                        illustrate: [],
                        style: { display: 'flex', paddingTop: 9 },
                    },
                    extra: '<<autoPositionExtra>>',
                },
                sequence: {
                    displayName: '<<sequence>>',
                    type: 'number.integer',
                    rules: [{ required: true, message: '<<sequenceRequired>>' }],
                    controlConfig: {
                        placeholder: '<<inputPlease>>',
                        max: 99999,
                        min: 0,
                    },
                    extra: '<<sequenceExtra>>',
                },
                format: {
                    type: 'string.options.select',
                    displayName: '<<format>>',
                    options: [
                        { id: 'SUPERMARKET', name: '<<market>>' },
                        { id: 'STORE', name: '<<store>>' },
                        { id: 'OUTLETS', name: '<<outlets>>' },
                        { id: 'HOME_APPLIANCES', name: '<<homeAppliances>>' },
                        { id: 'OTHER', name: '<<other>>' },
                    ],
                    rules: [{ required: true }],
                    controlConfig: {
                        style: { width: 250 },
                        placeholder: '<<selectPlease>>',
                    },
                },
                status: {
                    type: 'string.radioIllustrate',
                    displayName: '<<subsiteStatus>>',
                    name: 'subsite-status',
                    defaultValue: '1',
                    options: [
                        { id: '1', name: '<<subsiteStatusEnable>>' },
                        { id: '2', name: '<<subsiteStatusDisable>>' },
                    ],
                    controlConfig: {
                        illustrate: [],
                        style: { display: 'flex', paddingTop: 9 },
                    },
                },
                businessStatus: {
                    type: 'string.radioIllustrate',
                    displayName: '<<businessStatus>>',
                    name: 'subsite-businessStatus',
                    defaultValue: '1',
                    options: [
                        { id: '1', name: '<<normalBusinessOperations>>' },
                        { id: '2', name: '<<suspendOrderTaking>>' },
                    ],
                    controlConfig: {
                        illustrate: [],
                        style: { display: 'flex', paddingTop: 9 },
                    },
                },
                tips: {
                    type: 'string.modalTips',
                    displayName: '<<tips>>',
                    defaultValue: '<<tipsValue>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        tipsModalConfig: {
                            contentType: 'SubsiteSuspendTips',
                            showIcon: false,
                            text: '<<viewExample>>',
                            textColor: '#1890ff',
                            title: '<<viewExample>>',
                            width: 648,
                        },
                        style: {
                            width: 340,
                        },
                        placeholder: '<<exampleTipsValue>>',
                    },
                    extra: '<<tipsExtra>>',
                },
                businessTime: {
                    type: 'string',
                    displayName: '<<businessTime>>',
                    controlConfig: {
                        style: { width: 250 },
                    },
                },
                deliveryLogisticsSenderEnabled: {
                    type: 'string.radioIllustrate',
                    displayName: '<<deliveryAddress>>',
                    name: 'subsite-enabled',
                    defaultValue: 'false',
                    options: [
                        { id: 'false', name: '<<usingStoreAddress>>' },
                        { id: 'true', name: '<<custom>>' },
                    ],
                    controlConfig: {
                        illustrate: [],
                        style: { display: 'flex', paddingTop: 9 },
                    },
                },
                deliveryLogisticsSenderName: {
                    type: 'string',
                    displayName: '<<contactName>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        style: { width: 250 },
                        placeholder: '<<contactNameRequired>>',
                    },
                },
                deliveryLogisticsSenderContactNumber: {
                    type: 'string.phone',
                    displayName: '<<contactMobile>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        placeholder: '<<contactMobileRequired>>',
                        maxLength: 20,
                        style: { width: 250 },
                    },
                },
                deliveryLogisticsSenderRegionId: {
                    displayName: '<<selectRegion>>',
                    type: 'object.select.city.code',
                    rules: [{ required: true, message: '<<regionNotEmpty>>' }],
                    controlConfig: {
                        style: { width: 250 },
                        placeholder: '<<selectPlease>>',
                    },
                },
                deliveryLogisticsSenderAddress: {
                    type: 'string',
                    displayName: '<<detailAddress>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        style: { width: 250 },
                        placeholder: '<<detailAddressRequired>>',
                    },
                },
                enabled: {
                    type: 'string.radioIllustrate',
                    displayName: '<<afterSalesReturnAddress>>',
                    name: 'subsite-enabled',
                    defaultValue: 'false',
                    options: [
                        { id: 'false', name: '<<usingStoreAddress>>' },
                        { id: 'true', name: '<<custom>>' },
                    ],
                    controlConfig: {
                        illustrate: [],
                        style: { display: 'flex', paddingTop: 9 },
                    },
                },
                afterSalesContacts: {
                    type: 'string',
                    displayName: '<<contactName>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        style: { width: 250 },
                        placeholder: '<<contactNameRequired>>',
                    },
                },
                afterSalesPhone: {
                    type: 'string.phone',
                    displayName: '<<contactMobile>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        placeholder: '<<contactMobileRequired>>',
                        maxLength: 20,
                        style: { width: 250 },
                    },
                },
                afterSalesSubsite: {
                    displayName: '<<selectRegion>>',
                    type: 'object.select.city.code',
                    rules: [{ required: true, message: '<<regionNotEmpty>>' }],
                    controlConfig: {
                        style: { width: 250 },
                        placeholder: '<<selectPlease>>',
                    },
                },
                afterSalesAddress: {
                    type: 'string',
                    displayName: '<<detailAddress>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        style: { width: 250 },
                        placeholder: '<<detailAddressRequired>>',
                    },
                },
                subsiteNameStr: {
                    type: 'string.popover',
                    displayName: '<<subsiteName>>',
                },
                afterSaleBackModes: {
                    type: 'array.optionIds.checkbox',
                    displayName: '<<returnedGoodsMethod>>',
                    defaultValue: ['3', '1'],
                    rules: [{ required: true, message: '<<ChooseOneReturnMethod>>' }],
                    options: [
                        {
                            id: '3',
                            name: '<<sendStore>>',
                        },
                        {
                            id: '1',
                            name: '<<deliveryMethodReturning>>',
                        },
                    ],
                    extra: '<<returnedGoodsMethodExtra>>',
                },
            },
        },
        SubsiteMessageViewFloorEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/subsite_floor',
            properties: {
                name: {
                    displayName: '<<floorName>>',
                    type: 'string',
                    rules: [{ required: true }, { max: 8, message: '<<floorNameRequired>>' }],
                    controlConfig: {
                        placeholder: '<<floorNameRules>>',
                        maxLength: 8,
                    },
                },
                saleRange: {
                    displayName: '<<saleRange>>',
                    type: 'string',
                    controlConfig: {
                        placeholder: '<<saleRangeRequired>>',
                    },
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<status>>',
                    defaultValue: '1',
                    options: [
                        {
                            id: '1',
                            name: '<<enable>>',
                        },
                        {
                            id: '2',
                            name: '<<disabled>>',
                        },
                    ],
                },
                sequence: {
                    displayName: '<<sequence>>',
                    type: 'number.integer',
                    rules: [{ required: true, message: '<<sequenceRequired>>' }],
                    controlConfig: {
                        placeholder: '<<inputPlease>>',
                        max: 99999,
                        min: 0,
                    },
                },
                createTimeStr: {
                    type: 'string',
                    displayName: '<<createTimeStr>>',
                },
            },
        },
        BusinessMallsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/BUSINESS-MALL`,
            apiPath: '/loader/admin/business-malls',
            properties: {
                subsiteId: {
                    type: 'number',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsites>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseInfo>>',
                    properties: {
                        wxOpen: {
                            type: 'string.options.radio',
                            displayName: '<<wxBuiness>>',
                            options: [
                                { id: 'true', name: '<<opend>>' },
                                { id: 'false', name: '<<notOpened>>' },
                            ],
                        },
                        wxId: {
                            type: 'number',
                        },
                        aliId: {
                            type: 'number',
                        },
                        wxMallId: {
                            type: 'string',
                            displayName: '<<mallId>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<mallIdRequired>>',
                                    validator: (rule: any, value: any) => {
                                        if (!value || value.length > 10 || value.length < 8) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<wxMallIdRequired>>',
                            },
                            extra: '<<wxMallIdExtra>>',
                        },
                        version: {
                            type: 'string.options.radio',
                            displayName: '<<buinessVersion>>',
                            options: [
                                { id: 'V3', name: '<<versionThird>>' },
                                { id: 'V2', name: '<<versionSecond>>' },
                            ],
                        },
                        mallBrandId: {
                            type: 'string',
                            displayName: '<<brandId>>',
                            controlConfig: {
                                maxLength: 15,
                            },
                        },
                        popup: {
                            type: 'string.options.radio',
                            defaultValue: 'true',
                            displayName: '<<quickPointsPopup>>',
                            options: [
                                { id: 'true', name: '<<show>>' },
                                { id: 'false', name: '<<hide>>' },
                            ],
                            extra: extraElement({
                                tips: ['<<quickPointsPopupExtra>> {modal}'],
                                tipsModal: {
                                    contentType: TipsModalContentType.ScorePopup,
                                    showIcon: false,
                                    text: '<<example>>',
                                    textColor: '#1890ff',
                                    title: '',
                                    width: 600,
                                },
                            }),
                        },
                        mallGuide: {
                            type: 'object.businessGuide',
                            displayName: '<<configureBoot>>',
                        },

                        aliOpen: {
                            type: 'string.options.radio',
                            displayName: '<<aliBuiness>>',
                            options: [
                                { id: 'true', name: '<<opend>>' },
                                { id: 'false', name: '<<notOpened>>' },
                            ],
                        },
                        alipayMallId: {
                            type: 'string',
                            displayName: '<<mallId>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<mallIdRequired>>',
                                    validator: (rule: any, value: any) => {
                                        if (!value || value.length > 10 || value.length < 8) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<mallIdRequired>>',
                            },
                            extra: '<<aliMallIdExtra>>',
                        },
                    },
                },
                merchantInfo: {
                    type: 'object.subForm',
                    displayName: '<<merchantInfo>>',
                    properties: {
                        merchants: {
                            type: 'array.merchant.selector',
                            displayName: '<<merchants>>',
                            defaultValue: [],
                            controlConfig: {
                                subsiteField: 'subsiteId',
                                tips: '<<merchantTips>>',
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        SubsiteMessageView: {
            component: 'Viewport',
            entity: 'SubsiteMessageEntity',
        },
        SubsiteMessagePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'SubsiteMessageFilter',
                },
                {
                    component: 'GridLayout',
                    style: {
                        display: 'flex',
                        marginBottom: 24,
                    },
                    items: [
                        {
                            component: 'Button',
                            text: '<<addSubsite>>',
                            route: '/subsite/add',
                            type: 'primary',
                            icon: 'plus',
                            style: {
                                width: 110,
                                marginRight: 10,
                            },
                        },
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'ExportDataByFilterParamsButton',
                                    params: {
                                        text: '<<exportStoreInfo>>',
                                        style: {
                                            width: '120px',
                                        },
                                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                        apiPath: '/admin/subsite/export',
                                        requestMethod: 'post',
                                        target: '_blank',
                                    },
                                },
                            ],
                        },
                    ],
                },
                { component: 'SubsiteMessageTable' },
            ],
        },
        SubsiteMessageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 24,
            },
            fields: [
                {
                    property: 'subsiteName',
                },
                {
                    property: 'status',
                },
                {
                    property: 'code',
                },
            ],
        },
        SubsiteMessageTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'SubsiteMessageDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: {
                        x: true,
                    },
                    columns: [
                        { property: 'code' },
                        {
                            property: 'subsiteNameStr',
                            width: 180,
                        },
                        { property: 'format' },
                        { property: 'sequence' },
                        {
                            property: 'status',
                            displayConfig: {
                                readOnly: true,
                            },
                        },
                        {
                            property: 'businessStatus',
                            displayConfig: {
                                readOnly: true,
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        width: 200,
                        fixed: 'right',
                        items: [
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                path: '/subsite/edit/{{row.id}}',
                            },
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    statusKey: 'status',
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                    handles: [
                                        {
                                            params: { status: 'DISABLE' },
                                            value: '1',
                                            apiPath: '/admin/subsite/:id/status',
                                            config: {
                                                content: '<<subsiteStatusDisable>>',
                                                text: '<<subsiteStatusDisable>>',
                                            },
                                            confirm: {
                                                text: '<<isDisabled>>{{row.subsiteName}}?',
                                            },
                                        },
                                        {
                                            params: { status: 'ENABLE' },
                                            value: '2',
                                            apiPath: '/admin/subsite/:id/status',
                                            config: {
                                                content: '<<subsiteStatusEnable>>',
                                                text: '<<subsiteStatusEnable>>',
                                            },
                                            confirm: {
                                                text: '<<isEnable>>{{row.subsiteName}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'link',
                                text: '<<viewFloor>>',
                                path: '/subsite/view-floor/{{row.id}}',
                            },
                            {
                                type: 'link',
                                text: '<<businessMalls>>',
                                path: '/subsite/business-info/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        SubsiteMessageAddPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'SubsiteMessageEntity',
            items: [
                {
                    component: 'SubsiteMessageAddForm',
                },
            ],
        },
        SubsiteMessageAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 900 },
            fields: [
                { property: 'code' },
                { property: 'douyinPoiId' },
                { property: 'subsiteName' },
                { property: 'contactName' },
                { property: 'mobile' },
                { property: 'servicePhone' },
                { property: 'servicePic' },
                { property: 'indoorMapProvider' },
                {
                    property: 'mapId',
                    visible: (e: any) => {
                        return get(e, 'indoorMapProvider') === IndoorMapProviderType.fengNiao;
                    },
                },
                {
                    property: 'mapKey',
                    visible: (e: any) => {
                        return get(e, 'indoorMapProvider') === IndoorMapProviderType.fengNiao;
                    },
                },
                {
                    property: 'mapAppName',
                    visible: (e: any) => {
                        return get(e, 'indoorMapProvider') === IndoorMapProviderType.fengNiao;
                    },
                },
                {
                    property: 'mapThemeId',
                    visible: (e: any) => {
                        return get(e, 'indoorMapProvider') === IndoorMapProviderType.fengNiao;
                    },
                },
                {
                    property: 'mapUrl',
                    visible: (e: any) => {
                        return get(e, 'indoorMapProvider') === IndoorMapProviderType.qianMu;
                    },
                },
                { property: 'pic' },
                { property: 'logo' },
                { property: 'region' },
                { property: 'address' },
                { property: 'subsitePosition' },
                { property: 'autoPosition' },
                { property: 'sequence' },
                { property: 'format' },
                { property: 'status' },
                { property: 'businessStatus' },
                {
                    property: 'tips',
                    visible: (e: any) => {
                        return get(e, 'businessStatus') === '2';
                    },
                },
                { property: 'businessTime' },
                { property: 'deliveryLogisticsSenderEnabled' },
                {
                    property: 'deliveryLogisticsSenderName',
                    visible: (e: any) => {
                        return get(e, 'deliveryLogisticsSenderEnabled') === 'true';
                    },
                },
                {
                    property: 'deliveryLogisticsSenderContactNumber',
                    visible: (e: any) => {
                        return get(e, 'deliveryLogisticsSenderEnabled') === 'true';
                    },
                },
                {
                    property: 'deliveryLogisticsSenderRegionId',
                    visible: (e: any) => {
                        return get(e, 'deliveryLogisticsSenderEnabled') === 'true';
                    },
                },
                {
                    property: 'deliveryLogisticsSenderAddress',
                    visible: (e: any) => {
                        return get(e, 'deliveryLogisticsSenderEnabled') === 'true';
                    },
                },
                { property: 'enabled' },
                {
                    property: 'afterSalesContacts',
                    visible: (e: any) => {
                        return get(e, 'enabled') === 'true';
                    },
                },
                {
                    property: 'afterSalesPhone',
                    visible: (e: any) => {
                        return get(e, 'enabled') === 'true';
                    },
                },
                {
                    property: 'afterSalesSubsite',
                    visible: (e: any) => {
                        return get(e, 'enabled') === 'true';
                    },
                },
                {
                    property: 'afterSalesAddress',
                    visible: (e: any) => {
                        return get(e, 'enabled') === 'true';
                    },
                },
                { property: 'afterSaleBackModes' },
            ],
            onValidate: (entityFields: any) => {
                const pattern = /^[a-zA-Z0-9]{0,6}$/;
                const code = get(entityFields, 'code', null);
                if (!pattern.test(code)) {
                    AntMessage.error(language.getText('storeCodeRules'), 3);
                    return false;
                }
                return true;
            },
            submit: true,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
        },
        SubsiteMessageEditPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'SubsiteMessageEntity',
            loaderType: 'get',
            items: [
                {
                    component: 'SubsiteMessageEditForm',
                },
            ],
        },
        SubsiteMessageEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 900 },
            fields: [
                {
                    property: 'code',
                    controlConfig: {
                        disabled: true,
                        style: { width: 200 },
                    },
                },
                { property: 'douyinPoiId' },
                { property: 'subsiteName' },
                { property: 'contactName' },
                { property: 'mobile' },
                { property: 'servicePhone' },
                { property: 'servicePic' },
                { property: 'indoorMapProvider' },
                {
                    property: 'mapId',
                    visible: (e: any) => {
                        return get(e, 'indoorMapProvider') === IndoorMapProviderType.fengNiao;
                    },
                },
                {
                    property: 'mapKey',
                    visible: (e: any) => {
                        return get(e, 'indoorMapProvider') === IndoorMapProviderType.fengNiao;
                    },
                },
                {
                    property: 'mapAppName',
                    visible: (e: any) => {
                        return get(e, 'indoorMapProvider') === IndoorMapProviderType.fengNiao;
                    },
                },
                {
                    property: 'mapThemeId',
                    visible: (e: any) => {
                        return get(e, 'indoorMapProvider') === IndoorMapProviderType.fengNiao;
                    },
                },
                {
                    property: 'mapUrl',
                    visible: (e: any) => {
                        return get(e, 'indoorMapProvider') === IndoorMapProviderType.qianMu;
                    },
                },
                { property: 'pic' },
                { property: 'logo' },
                { property: 'region' },
                { property: 'address' },
                { property: 'subsitePosition' },
                { property: 'autoPosition' },
                { property: 'sequence' },
                { property: 'format' },
                { property: 'status' },
                { property: 'businessStatus' },
                {
                    property: 'tips',
                    visible: (e: any) => {
                        return get(e, 'businessStatus') === '2';
                    },
                },
                { property: 'businessTime' },
                { property: 'deliveryLogisticsSenderEnabled' },
                {
                    property: 'deliveryLogisticsSenderName',
                    visible: (e: any) => {
                        return get(e, 'deliveryLogisticsSenderEnabled') === 'true';
                    },
                },
                {
                    property: 'deliveryLogisticsSenderContactNumber',
                    visible: (e: any) => {
                        return get(e, 'deliveryLogisticsSenderEnabled') === 'true';
                    },
                },
                {
                    property: 'deliveryLogisticsSenderRegionId',
                    visible: (e: any) => {
                        return get(e, 'deliveryLogisticsSenderEnabled') === 'true';
                    },
                },
                {
                    property: 'deliveryLogisticsSenderAddress',
                    visible: (e: any) => {
                        return get(e, 'deliveryLogisticsSenderEnabled') === 'true';
                    },
                },
                { property: 'enabled' },
                {
                    property: 'afterSalesContacts',
                    visible: (e: any) => {
                        return get(e, 'enabled') === 'true';
                    },
                },
                {
                    property: 'afterSalesPhone',
                    visible: (e: any) => {
                        return get(e, 'enabled') === 'true';
                    },
                },
                {
                    property: 'afterSalesSubsite',
                    visible: (e: any) => {
                        return get(e, 'enabled') === 'true';
                    },
                },
                {
                    property: 'afterSalesAddress',
                    visible: (e: any) => {
                        return get(e, 'enabled') === 'true';
                    },
                },
                { property: 'afterSaleBackModes' },
            ],
            submit: true,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
        },
        SubsiteMessageViewFloorAddPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'SubsiteMessageViewFloorEntity',
            items: [
                {
                    component: 'SubsiteMessageViewFloorAddForm',
                },
            ],
        },
        SubsiteMessageViewFloorAddForm: {
            component: 'CreateForm',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: [
                { property: 'name' },
                { property: 'saleRange' },
                { property: 'status' },
                { property: 'sequence' },
            ],
            submit: true,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        SubsiteMessageViewFloorEditPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'SubsiteMessageViewFloorEntity',
            loaderType: 'get',
            items: [
                {
                    component: 'SubsiteMessageViewFloorEditForm',
                },
            ],
        },
        SubsiteMessageViewFloorEditForm: {
            component: 'ModifyForm',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: [
                { property: 'name' },
                { property: 'saleRange' },
                { property: 'status' },
                { property: 'sequence' },
            ],
            submit: true,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },

        BusinessMallsEditPage: {
            component: 'Card',
            content: { component: 'BusinessMallsEditForm' },
        },

        BusinessMallsEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'BusinessMallsEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'subsiteName',
                    controlConfig: { style: { width: '200px' }, disabled: true },
                    modifiable: false,
                },

                {
                    property: 'baseInfo.wxOpen',
                    controlConfig: {
                        style: { width: '200px' },
                    },
                },
                {
                    property: 'baseInfo.wxMallId',
                    controlConfig: {
                        style: { width: '200px' },
                    },
                    visible: (e: any) => {
                        return get(e, 'baseInfo.wxOpen') === 'true';
                    },
                },
                {
                    property: 'baseInfo.version',
                    controlConfig: {
                        style: { width: '200px' },
                    },
                    visible: (e: any) => {
                        return get(e, 'baseInfo.wxOpen') === 'true';
                    },
                },
                {
                    property: 'baseInfo.mallBrandId',
                    controlConfig: {
                        style: { width: '200px' },
                    },
                    visible: (e: any) => {
                        return (
                            get(e, 'baseInfo.wxOpen') === 'true' &&
                            get(e, 'baseInfo.version') === 'V3'
                        );
                    },
                },
                {
                    property: 'baseInfo.popup',
                    controlConfig: {
                        style: { width: '200px' },
                    },
                    visible: (e: any) => {
                        return (
                            get(e, 'baseInfo.wxOpen') === 'true' &&
                            get(e, 'baseInfo.version') === 'V3'
                        );
                    },
                },
                {
                    property: 'baseInfo.mallGuide',
                    visible: (e: any) => {
                        return get(e, 'baseInfo.wxOpen') === 'true';
                    },
                },
                {
                    property: 'baseInfo.aliOpen',
                    controlConfig: {
                        style: { width: '200px' },
                    },
                },
                {
                    property: 'baseInfo.alipayMallId',
                    controlConfig: {
                        style: { width: '200px' },
                    },
                    visible: (e: any) => {
                        return get(e, 'baseInfo.aliOpen') === 'true';
                    },
                },

                {
                    property: 'merchantInfo.merchants',
                },

                {
                    property: 'baseInfo.aliId',
                    className: 'hidden',
                },
                {
                    property: 'baseInfo.wxId',
                    className: 'hidden',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/subsite',
                    component: 'SubsiteMessageView',
                    breadcrumbName: '<<manageSubsiteInformation>>',
                    privilege: {
                        path: 'storeManager',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'SubsiteMessageEditPage',
                            breadcrumbName: '<<editSubsite>>',
                        },
                        {
                            path: '/add',
                            component: 'SubsiteMessageAddPage',
                            breadcrumbName: '<<addSubsite>>',
                        },
                        {
                            path: '/view-floor/:id',
                            component: 'SubsiteMessageViewFloorPage',
                            breadcrumbName: '<<manageFloor>>',
                        },
                        {
                            path: '/view-floor-add/:id',
                            component: 'SubsiteMessageViewFloorAddPage',
                            breadcrumbName: '<<addFloor>>',
                        },
                        {
                            path: '/view-floor-edit/:id',
                            component: 'SubsiteMessageViewFloorEditPage',
                            breadcrumbName: '<<editFloor>>',
                        },
                        {
                            path: '/business-info/:id',
                            component: 'BusinessMallsEditPage',
                            breadcrumbName: '<<businessMalls>>',
                        },
                        { path: '/', component: 'SubsiteMessagePage' },
                    ],
                },
            ],
        },
    ],
};
