import React, { PureComponent } from 'react';
import ImageAndOperatorDrag from './image-and-operator-drag';
import { Icon } from 'antd';
import { cloneDeep } from 'lodash';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

export interface ImageAndOperatorItem {
    pic?: {
        id: number;
        path: string;
    };
    operator: any;
    isEndNewValue?: boolean;
    isStartNewValue?: boolean;
}

interface ArrayImageAndOperatorProps {
    name: string;
    value?: ImageAndOperatorItem[];
    row: any;
    onChange?(value: ImageAndOperatorItem[], name: string): void;
    maxCount: number;
}

export class ArrayImageAndOperator extends PureComponent<ArrayImageAndOperatorProps, any> {
    private addItem = () => {
        const { value, name, onChange } = this.props;
        /* const showTime = this.props?.row?.showTime;
        if (!showTime) {
            message.error(language.getText('showTimeError'));
            return;
        } */
        const newValue = cloneDeep(value || []);
        newValue.push({
            pic: undefined,
            operator: undefined,
            isStartNewValue: true,
            isEndNewValue: true,
        });
        onChange && onChange(newValue, name);
    };

    private renderAddButton = () => {
        return (
            <div className="array-image-and-operator__add" onClick={this.addItem}>
                <Icon type="plus" />
                <div style={{ marginLeft: 10 }}>{language.getText('addPicTextNav')}</div>
            </div>
        );
    };

    render() {
        const { value = [], maxCount = 10 } = this.props;
        return (
            <div className="array-image-and-operator" style={{ width: '510px' }}>
                <ImageAndOperatorDrag {...this.props}>
                    {value.length < maxCount ? this.renderAddButton() : null}
                </ImageAndOperatorDrag>
            </div>
        );
    }
}
