import { PureComponent } from 'react';
import { Button as AntButton, message as AntMessage, Modal } from 'antd';
import React from 'react';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { api } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

export interface NewMallActivityAppliesApplyButtonProps {
    row: any;
    entity: Entity;
    params: any;
}

/**
 * 售后单审核
 */
export class NewMallActivityAppliesApplyButton extends PureComponent<
    NewMallActivityAppliesApplyButtonProps,
    {}
> {
    submit(auditStatus: 'SUCCESS' | 'FAIL') {
        const { row, entity, params } = this.props;
        if (auditStatus === 'SUCCESS') {
            Modal.confirm({
                title: '确认审核通过吗？',
                onOk: () => {
                    api.put(
                        {},
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                            apiPath: `/admin/mall_activity_service_applies/${row.id}/approve`,
                        }
                    ).then(() => {
                        AntMessage.success(services.language.getText('shtgcg'));
                        entity.search(params);
                    });
                },
            });
        }
        if (auditStatus === 'FAIL') {
            Modal.confirm({
                title: '确认拒绝审核通过吗？',
                onOk: () => {
                    api.put(
                        {},
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                            apiPath: `/admin/mall_activity_service_applies/${row.id}/reject`,
                        }
                    ).then(() => {
                        AntMessage.success('拒绝审核通过成功');
                        entity.search(params);
                    });
                },
            });
        }
    }

    render() {
        const { row } = this.props;
        const status = row.status;
        return status === 'APPLYING' ? (
            <span>
                <AntButton
                    type="link"
                    className="span-link"
                    style={{ padding: 0 }}
                    onClick={this.submit.bind(this, 'SUCCESS')}
                >
                    审核通过
                </AntButton>
                <AntButton
                    type="link"
                    className="span-link"
                    onClick={this.submit.bind(this, 'FAIL')}
                >
                    {services.language.getText('shbtg')}
                </AntButton>
            </span>
        ) : (
            <span style={{ display: 'inline-block' }}>
                <AntButton disabled type="link" className="span-link" style={{ padding: 0 }}>
                    审核通过
                </AntButton>
                <AntButton disabled type="link" className="span-link">
                    {services.language.getText('shbtg')}
                </AntButton>
            </span>
        );
    }
}
