import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { CreditCycleLimit } from './credit-cycle-limit';

export class ObjectCreditCycleLimitMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <CreditCycleLimit {...controlInfo} />;
    }
}
