import { Config } from '@comall-backend-builder/core/lib/parser';
import { ButtonProps } from 'antd/lib/button';
import { EntityButtonProps } from '../../components';
import { services } from '@comall-backend-builder/core';

/**
 * 数据类型
 */
export enum FileType {
    /**
     * 全部
     */
    ALL = 'ALL',
    /**
     * 线下核销优惠券
     */
    CONSUME = 'CONSUME',
    /**
     * 线下退优惠券
     */
    UNCONSUME = 'UNCONSUME',
}

/**
 * 数据处理结果
 */
export enum FileStatus {
    /**
     * 正在处理
     */
    PROCESSING = 'PROCESSING',
    /**
     * 处理完成
     */
    PROCESS_FINISHED = 'PROCESS_FINISHED',
    /**
     * 处理失败（系统异常）
     */
    PROCESS_FAILED = 'PROCESS_FAILED',
    /**
     * 处理完成（文件已过期）
     */
    FILE_EXPIRED = 'FILE_EXPIRED',
}

const FILE_STATUS_OPTIONS = [
    {
        id: FileStatus.PROCESSING,
        name: '<<processing>>',
    },
    {
        id: FileStatus.PROCESS_FINISHED,
        name: '<<processFinished>>',
    },
    {
        id: FileStatus.PROCESS_FAILED,
        name: '<<processFailed>>',
    },
    {
        id: FileStatus.FILE_EXPIRED,
        name: '<<wjygq>>',
    },
];

export const config: Config = {
    entities: {
        reportDownloadCenterEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
            apiPath: '/loader/admin/reportDownloadCenter',
            filters: {
                fileName: {
                    displayName: '<<fileName>>',
                    type: 'string.text.trim',
                    controlConfig: {
                        style: {
                            width: 160,
                        },
                        placeholder: '<<qsrwjmc>>',
                    },
                },
                dateRange: {
                    displayName: '<<createTimeStr>>',
                    type: 'object.dateRangePlus',
                    controlConfig: {
                        style: {
                            width: 260,
                        },
                        placeholder: ['<<selectStartTime>>', '<<selectEndTime>>'],
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                fileName: {
                    type: 'string',
                    displayName: '<<fileName>>',
                },
                creator: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<createTimeStr>>',
                },
                processEndTime: {
                    type: 'string',
                    displayName: '<<processEndTime>>',
                },
                processStatus: {
                    type: 'string.options.select',
                    displayName: '<<processStatus>>',
                    options: FILE_STATUS_OPTIONS,
                },
            },
        },
    },
    components: {
        reportDownloadCenterView: {
            component: 'Viewport',
        },
        reportDownloadCenterPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'tabs-content-viewport',
            entity: 'reportDownloadCenterEntity',
            items: [
                {
                    component: 'Tabs',
                    type: 'card',
                    cache: false,
                    animated: false,
                    items: [
                        {
                            title: '<<xzjl_1>>',
                            content: {
                                component: 'FlexLayout',
                                direction: 'vertical',
                                items: [
                                    {
                                        component: 'reportDownloadCenterFilter',
                                    },
                                    {
                                        component: 'reportDownloadCenterTable',
                                    },
                                ],
                            },
                        },
                    ],
                },
            ],
        },
        reportDownloadCenterFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                paddingBottom: 16,
            },
            fields: [
                {
                    property: 'fileName',
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        reportDownloadCenterTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'fileName',
                },
                {
                    property: 'creator',
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'processEndTime',
                },
                {
                    property: 'processStatus',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 260,
                items: [
                    {
                        type: 'component',
                        component: 'EntityButton',
                        config: {
                            props: {
                                type: 'link',
                                disabled: (entityButtonProps: EntityButtonProps) =>
                                    entityButtonProps.row.processStatus !==
                                    FileStatus.PROCESS_FINISHED,
                                children: '<<downloadData>>',
                            },
                            propsHandler(
                                buttonProps: ButtonProps,
                                entityButtonProps: EntityButtonProps
                            ): ButtonProps {
                                return {
                                    ...buttonProps,
                                    download: entityButtonProps.row.fileName,
                                    href: entityButtonProps.row.filePath,
                                };
                            },
                        },
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/report-download-center',
                    component: 'reportDownloadCenterView',
                    breadcrumbName: '<<reportDownloadCenter>>',
                    privilege: {
                        path: 'reportDownloadCenter',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/',
                            component: 'reportDownloadCenterPage',
                        },
                    ],
                },
            ],
        },
    ],
};
