import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { find } from 'lodash';

const api = services.api;

export const SubsiteCitywideDeliveryLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`;
        config.apiPath = '/admin/citywide_deliveries';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/citywide_deliveries/${id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.info = {
                    subsite: res.delivery.subsite,
                    name: res.delivery.name,
                    address:
                        '门店发货地址为“门店管理”栏目中设置的“门店地址”，请提前设置好门店地址。',
                    deliveryScope: {
                        scope: 'CUSTOM',
                        geofences:
                            res.freightRules && res.freightRules.length > 0
                                ? res.freightRules[0].deliveryScopes
                                : null,
                        isEdit: true,
                    },
                };
                res.freight = {
                    isFreightLadders: 'NO',
                };
                if (res.freightRules && res.freightRules.length > 0) {
                    const freightRule = res.freightRules[0];
                    if (
                        freightRule &&
                        freightRule.freightRanges &&
                        freightRule.freightRanges.length > 0
                    ) {
                        const freightRange = freightRule.freightRanges[0];
                        res.freight.baseFreight = freightRange.firstAmount;
                        if (freightRange.freightLadders && freightRange.freightLadders.length > 0) {
                            const weightFreightLadder: any = find(freightRange.freightLadders, {
                                type: 'WEIGHT',
                            });
                            const distanceFreightLadder: any = find(freightRange.freightLadders, {
                                type: 'DISTANCE',
                            });
                            res.freight.isFreightLadders = 'YES';
                            res.freight.firstLadderStartValue = {
                                ladderValue:
                                    weightFreightLadder && weightFreightLadder.startValue
                                        ? weightFreightLadder.startValue
                                        : null,
                                ladderAmount:
                                    distanceFreightLadder && distanceFreightLadder.startValue
                                        ? distanceFreightLadder.startValue
                                        : null,
                            };
                            res.freight.weightFreightLadder = {
                                ladderValue:
                                    weightFreightLadder && weightFreightLadder.ladderValue
                                        ? weightFreightLadder.ladderValue
                                        : null,
                                ladderAmount:
                                    weightFreightLadder && weightFreightLadder.ladderAmount
                                        ? weightFreightLadder?.ladderAmount
                                        : null,
                            };
                            res.freight.distanceFreightLadder = {
                                ladderValue:
                                    distanceFreightLadder && distanceFreightLadder.ladderValue
                                        ? distanceFreightLadder.ladderValue
                                        : null,
                                ladderAmount:
                                    distanceFreightLadder && distanceFreightLadder.ladderAmount
                                        ? distanceFreightLadder?.ladderAmount
                                        : null,
                            };
                        }
                    }
                }
                if (res.timeRule) {
                    if (res.timeRule.timePeriods && res.timeRule.timePeriods.length > 0) {
                        for (let i = 0; i < res.timeRule.timePeriods.length; i++) {
                            let time = res.timeRule.timePeriods[i];
                            if (time && time.startTime && time.endTime) {
                                time.start = time.startTime;
                                time.end = time.endTime;
                                time.num = time.orderPickingLimit;
                            }
                        }
                        res.timeRule.status = 'ENABLED';
                        res.timeRule.deliveryTimePeriods = res.timeRule.timePeriods;
                        res.timeRule.reservedTimeUnit = res.timeRule.reservedTime.unit;
                        res.timeRule.reservedTimeValue = res.timeRule.reservedTime.value;
                    } else {
                        res.timeRule = {
                            status: 'DISABLED',
                            reservedTimeUnit: 'HOUR',
                        };
                    }
                } else {
                    res.timeRule = {
                        status: 'DISABLED',
                        reservedTimeUnit: 'HOUR',
                    };
                }
            } else {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        item.id = item.delivery.id;
                        item.subsiteName = item.delivery.subsite.name;
                        item.name = item.delivery.name;
                        item.status = item.delivery.status;
                        item.executeStatus = item.delivery.status;

                        let baseFreight = '';
                        let scope = '';
                        if (item.freightRules && item.freightRules.length > 0) {
                            if (
                                item.freightRules[0].freightRanges &&
                                item.freightRules[0].freightRanges.length > 0
                            ) {
                                baseFreight = item.freightRules[0].freightRanges[0].firstAmount;
                            }
                            scope = item.freightRules[0].deliveryScopeDesc;
                        }
                        item.baseFreight = baseFreight;
                        item.scope = scope;
                        let freightLadderTexts = '';
                        if (item.freightLadderDesc && item.freightLadderDesc.length > 0) {
                            item.freightLadderDesc.map((text: any) => {
                                freightLadderTexts = freightLadderTexts + text;
                                return text;
                            });
                        } else {
                            freightLadderTexts = '无';
                        }
                        item.freightLadderTexts = freightLadderTexts;
                        return item;
                    });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`;
        config.apiPath = '/admin/citywide_deliveries';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`;
        if (params.id) {
            config.apiPath = `/admin/citywide_deliveries/${params.id}`;
        }
        const newParams: any = dataParser(params);
        newParams.id = params.id;
        return await api.put(newParams, config);
    },
};
/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    if (params.info) {
        newParams.delivery = {
            name: params.info.name,
            subsite: params.info.subsite,
        };
    }
    if (params.info && params.freight) {
        const freightLadders: any[] = [];
        if (params.freight.isFreightLadders === 'YES') {
            freightLadders.push({
                startValue: params.freight.firstLadderStartValue.ladderValue,
                ladderValue: params.freight.weightFreightLadder.ladderValue,
                ladderAmount: params.freight.weightFreightLadder.ladderAmount,
                type: 'WEIGHT',
            });
            freightLadders.push({
                startValue: params.freight.firstLadderStartValue.ladderAmount,
                ladderValue: params.freight.distanceFreightLadder.ladderValue,
                ladderAmount: params.freight.distanceFreightLadder.ladderAmount,
                type: 'DISTANCE',
            });
        }

        newParams.freightRules = [
            {
                deliveryScopes:
                    params.info.deliveryScope && params.info.deliveryScope.geofences
                        ? params.info.deliveryScope.geofences
                        : null,
                freightRanges: [
                    {
                        firstAmount: params.freight.baseFreight,
                        freightLadders: freightLadders.length > 0 ? freightLadders : null,
                    },
                ],
            },
        ];
    }
    if (params.timeRule && params.timeRule.status === 'ENABLED') {
        newParams.timeRule = {
            reservedTime: {
                unit: params.timeRule.reservedTimeUnit,
                value: params.timeRule.reservedTimeValue,
            },
            timePeriods: params.timeRule.timePeriods
                ? params.timeRule.timePeriods.map((timePeriod: any) => {
                      return {
                          startTime: timePeriod.start,
                          endTime: timePeriod.end,
                          orderPickingLimit: timePeriod.num,
                      };
                  })
                : null,
            topLimit: params.timeRule.topLimit,
        };
    }
    return newParams;
}
