import { EntityButtonProps } from '../../../../components/entity-button/index';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { ForwardDataCenterModal } from '../../../../services';
const api = services.api;

/**
 * 数据处理结果
 */
export enum FileStatus {
    /**
     * 全部
     */
    ALL = 'ALL',
    /**
     * 正在处理
     */
    PROCESSING = 'PROCESSING',
    /**
     * 处理完成
     */
    PROCESS_FINISHED = 'PROCESS_FINISHED',
    /**
     * 处理失败（系统异常）
     */
    PROCESS_FAILED = 'PROCESS_FAILED',
}

export enum CarparkOrderChannel {
    WECHAT_MINI_PROGRAM = 'WECHAT_MINI_PROGRAM',
    ALIPAY_MINI_PROGRAM = 'ALIPAY_MINI_PROGRAM',
    WECHAT_PAY_SCORE_TOUCHLESS = 'WECHAT_PAY_SCORE_TOUCHLESS',
    VIRTUAL_ASSET_TOUCHLESS = 'VIRTUAL_ASSET_TOUCHLESS',
}

export const CarparkOrderChannelOptions = [
    { id: CarparkOrderChannel.WECHAT_MINI_PROGRAM, name: '<<wxMiniP>>' },
    { id: CarparkOrderChannel.ALIPAY_MINI_PROGRAM, name: '<<aliMiniP>>' },
    { id: CarparkOrderChannel.WECHAT_PAY_SCORE_TOUCHLESS, name: '<<wechatPayScoreTouchless>>' },
    { id: CarparkOrderChannel.VIRTUAL_ASSET_TOUCHLESS, name: '<<virtualAssetTouchless>>' },
];
// const FILE_STATUS_OPTIONS = [
//     {
//         id: FileStatus.ALL,
//         name: '<<all>>',
//     },
//     {
//         id: FileStatus.PROCESSING,
//         name: '正在处理',
//     },
//     {
//         id: FileStatus.PROCESS_FINISHED,
//         name: '处理完成',
//     },
//     {
//         id: FileStatus.PROCESS_FAILED,
//         name: '处理失败（系统异常）',
//     },
// ];

export const config: Config = {
    entities: {
        carparkOrder: {
            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/CARPARK'),
            apiPath: '/loader/admin/carpark_orders',
            properties: {
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                orderNumber: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                carNumber: {
                    type: 'string',
                    displayName: '<<cph>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<xdr>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<xdsjh>>',
                },
                totalAmount: {
                    type: 'string',
                    displayName: '<<yjje>>',
                },
                payableAmount: {
                    type: 'string',
                    displayName: '<<sjje_1>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<ddzt>>',
                    options: [
                        {
                            id: 'CREATED',
                            name: '<<ycj>>',
                        },
                        {
                            id: 'PAID',
                            name: '<<yzf_1>>',
                        },
                        {
                            id: 'COMPLETED',
                            name: '<<ywc>>',
                        },
                    ],
                },
                entryTime: {
                    type: 'string',
                    displayName: '<<rcsj>>',
                },
                paidTime: {
                    type: 'string',
                    displayName: '<<jfsj>>',
                },
                parkingTime: {
                    type: 'string',
                    displayName: '<<ljsz>>',
                },
                channel: {
                    type: 'string.options.select',
                    displayName: '<<carparkOrderChannel>>',
                    options: CarparkOrderChannelOptions,
                },
                type: {
                    type: 'string.options.select',
                    displayName: '<<jflx_1>>',
                    options: [
                        { id: 'TOUCHLESS_PARKING', name: '<<touchlessParking>>' },
                        { id: 'NORMAL', name: '<<xsjf>>' },
                    ],
                },
                subsiteCarparkName: {
                    type: 'string',
                    displayName: '<<ccmc>>',
                },
            },
            filters: {
                subsiteName: {
                    type: 'object.option.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>' },
                },
                carNumber: {
                    type: 'string',
                    displayName: '<<cph>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                memberType: {
                    type: 'string.options.select',
                    displayName: '<<yhsf>>',
                    options: [
                        { id: 'MEMBER', name: '<<member>>' },
                        { id: 'NON_MEMBER', name: '<<fhy>>' },
                    ],
                },
                entryTime: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<rcsj>>',
                },
                channel: {
                    type: 'string.options.select',
                    displayName: '<<carparkOrderChannel>>',
                    defaultValue: 'ALL',
                    options: [{ id: 'ALL', name: '<<all>>' }, ...CarparkOrderChannelOptions],
                },
                payTime: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<jfsj>>',
                },
                orderNumber: {
                    type: 'string',
                    displayName: '<<ddh_1>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<ddzt>>',
                    options: [
                        {
                            id: 'CREATED',
                            name: '<<ycj>>',
                        },
                        {
                            id: 'PAID',
                            name: '<<yzf_1>>',
                        },
                    ],
                },
                subsiteCarparkName: {
                    type: 'string',
                    displayName: '<<ccmc>>',
                },
            },
        },
        ParkingInvoicesEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
            apiPath: '/loader/admin/parking_invoices',
            filters: {
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectSubsite>>',
                        style: { width: 300 },
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<sqsj>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<ddh_1>>',
                    controlConfig: {
                        placeholder: '<<qsrddh>>',
                        style: { width: 300 },
                    },
                },
                memberMobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                    controlConfig: {
                        placeholder: '<<qsrsjh>>',
                        style: { width: 300 },
                    },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<kpzt_1>>',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'INVOICING', name: '<<kpz>>' },
                        { id: 'INVOICED', name: '<<ykp>>' },
                        { id: 'CHANGED', name: '<<yhk>>' },
                        { id: 'CHANGING', name: '<<hkz>>' },
                        { id: 'INVOICING_FAILED', name: '<<kpsb>>' },
                    ],
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                memberType: {
                    type: 'string.options.select',
                    displayName: '<<yhsf>>',
                    options: [
                        { id: 'MEMBER', name: '<<member>>' },
                        { id: 'NON_MEMBER', name: '<<fhy>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                applyTime: {
                    type: 'string',
                    displayName: '<<sqsj>>',
                },
                invoiceTime: {
                    type: 'string',
                    displayName: '<<invoiceTime>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<kpzt_1>>',
                    options: [
                        { id: 'NOT_INVOICED', name: '<<wkp>>' },
                        { id: 'INVOICING', name: '<<kpz>>' },
                        { id: 'INVOICED', name: '<<ykp>>' },
                        { id: 'CHANGED', name: '<<yhk>>' },
                        { id: 'CHANGING', name: '<<hkz>>' },
                        { id: 'INVOICING_FAILED', name: '<<kpsb>>' },
                    ],
                },
                invoiceNumber: {
                    type: 'string',
                    displayName: '<<fphm>>',
                },
                invoiceCode: {
                    type: 'string',
                    displayName: '<<fpdm>>',
                },
                // mobile: {
                //     type: 'string',
                //     displayName: '收票手机号',
                // },
                memberMobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
                totalAmount: {
                    type: 'string',
                    displayName: '<<kpje>>',
                },
                invoiceType: {
                    type: 'string.options.select',
                    displayName: '<<kplx>>',
                    options: [
                        { id: 'NORMAL', name: '<<zzsdzptfp>>' },
                        { id: 'SPECIAL', name: '<<zzsdzzyfp>>' },
                    ],
                },
            },
        },
        ParkingInvoicesLogsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
            apiPath: '/loader/admin/parking_invoices_logs',
            properties: {
                id: {
                    type: 'string',
                },
                type: {
                    type: 'string.options.select',
                    displayName: '<<kplx>>',
                    options: [
                        { id: 'BLUE_INVOICE', name: '<<blueticket>>' },
                        { id: 'RED_INVOICE', name: '<<redticket>>' },
                    ],
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<kpzt_1>>',
                    options: [
                        { id: 'INVOICED', name: '<<ykp>>' },
                        { id: 'CHANGED', name: '<<ykp>>' },
                        { id: 'RED_FLUSHED', name: '<<ykp>>' },
                        { id: 'INVOICING_FAILED', name: '<<kpsb>>' },
                        { id: 'RED_FLUSHED_FAILED', name: '<<kpsb>>' },
                    ],
                },
                applyTime: {
                    type: 'string',
                    displayName: '<<sqsj>>',
                },
                serialNumber: {
                    type: 'string',
                    displayName: '<<sqlsh>>',
                },
                buyerType: {
                    type: 'string.options.select',
                    displayName: '<<fptt>>',
                    options: [
                        { id: 'PERSONAL', name: '<<fqydw>>' },
                        { id: 'COMPANY', name: '<<qydw>>' },
                    ],
                },
                buyerName: {
                    type: 'string',
                    displayName: '<<fpttmc>>',
                },
                buyerTaxNo: {
                    type: 'string',
                    displayName: '<<sh_1>>',
                },
                invoiceNumber: {
                    type: 'string',
                    displayName: '<<sdphm>>',
                },
                invoiceCode: {
                    type: 'string',
                    displayName: '<<fpdm>>',
                },
            },
        },
    },
    components: {
        CarParkOrderView: {
            component: 'Viewport',
            entity: 'carparkOrder',
        },
        CarparkOrderListPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'CarparkOrderFilter',
                },
                {
                    component: 'CarparkOrderTable',
                },
            ],
        },
        CarparkOrderFilter: {
            component: 'FilterForm',
            fieldRow: {},
            direction: 'inline',
            fieldCol: {
                span: 8,
            },
            labelCol: 6,
            fields: [
                {
                    property: 'subsiteName',
                    controlConfig: {
                        placeholder: '<<subsiteNameRequired>>',
                        style: {
                            width: 200,
                        },
                    },
                },
                {
                    property: 'carNumber',
                    controlConfig: {
                        placeholder: '<<qsrcph>>',
                    },
                },
                {
                    property: 'mobile',
                    controlConfig: {
                        placeholder: '<<qsrsjh>>',
                    },
                },
                {
                    property: 'memberType',
                    controlConfig: {
                        style: { width: 120 },
                    },
                },
                {
                    property: 'entryTime',
                    controlConfig: {
                        style: { width: '100%' },
                    },
                },
                {
                    property: 'payTime',
                    controlConfig: {
                        style: { width: '100%' },
                    },
                },
                {
                    property: 'orderNumber',
                    controlConfig: {
                        placeholder: '<<qsrddh>>',
                    },
                },
                {
                    property: 'channel',
                    controlConfig: {
                        style: { width: 120 },
                    },
                },
                {
                    property: 'status',
                    controlConfig: {
                        style: { width: 120 },
                    },
                },
                {
                    property: 'subsiteCarparkName',
                },
            ],
            footer: {
                style: {
                    textAlign: 'right',
                },
            },
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 16,
                },
            },
            reset: true,
        },
        CarparkOrderTablesPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'tabs-content-viewport',
            items: [{ component: 'carparkOrderTables' }],
        },
        carparkOrderTables: {
            component: 'TabsPlus',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<carparkOrder>>',
                    content: {
                        component: 'CarparkOrderListPage',
                    },
                    params: { orderStatus: '' },
                },
                {
                    title: '<<ckfp>>',
                    content: {
                        component: 'ParkingInvoicesPage',
                    },
                    params: {},
                },
            ],
        },
        ParkingInvoicesPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'ParkingInvoicesEntity',
            items: [
                {
                    component: 'ParkingInvoicesFilter',
                },
                { component: 'ParkingInvoicesTable' },
            ],
        },
        ParkingInvoicesFilter: {
            className: 'mb24',
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteIds',
                },
                {
                    property: 'dateRange',
                },
                {
                    property: 'memberType',
                    controlConfig: {
                        style: { width: 120 },
                    },
                },
                {
                    property: 'orderNo',
                },
                {
                    property: 'memberMobile',
                },
                {
                    property: 'status',
                },
            ],
        },
        ParkingInvoicesTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'subsiteName',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'applyTime',
                },
                {
                    property: 'invoiceTime',
                },
                {
                    property: 'status',
                },
                {
                    property: 'invoiceNumber',
                },
                {
                    property: 'invoiceCode',
                },
                {
                    property: 'totalAmount',
                },
                // {
                //     property: 'mobile',
                // },
                {
                    property: 'memberMobile',
                },
                {
                    property: 'invoiceType',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 300,
                items: [
                    {
                        type: 'component',
                        component: 'ParkingInvoicesButton',
                    },
                ],
            },
        },
        ParkingInvoicesLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'ParkingInvoicesLogsEntity',
            items: [{ component: 'ParkingInvoicesLogsTable' }, { component: 'PageBackFooter' }],
        },
        ParkingInvoicesLogsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: 1550 },
            style: {
                marginBottom: 16,
            },
            columns: [
                {
                    property: 'type',
                    width: 100,
                },
                {
                    property: 'status',
                    width: 100,
                },
                {
                    property: 'applyTime',
                    width: 200,
                },
                {
                    property: 'serialNumber',
                    width: 200,
                },
                {
                    property: 'buyerType',
                    width: 150,
                },
                {
                    property: 'buyerName',
                    width: 170,
                },
                {
                    property: 'buyerTaxNo',
                    width: 200,
                },
                {
                    property: 'invoiceNumber',
                    width: 230,
                },
                {
                    property: 'invoiceCode',
                    width: 200,
                },
            ],
        },
        CarparkOrderTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'EntityButton',
                    props: {
                        type: 'primary',
                        children: '<<exportSearchResult>>',
                        style: {
                            width: 110,
                        },
                        className: 'mt24 mb24',
                        onClick: (_: any, entityButtonProps: EntityButtonProps) => {
                            const params: any = Object.assign(
                                {},
                                entityButtonProps.entity.params,
                                entityButtonProps.entity.filters,
                                {
                                    exchangeStatus: entityButtonProps.entity.filters
                                        ? (entityButtonProps.entity.filters as any).redeemStatus
                                        : undefined,
                                    redeemStatus: undefined,
                                }
                            );
                            if (params.entryTime) {
                                params.startEntryTime = params.entryTime.start;
                                params.endEntryTime = params.entryTime.end;
                                delete params.entryTime;
                            }
                            if (params.payTime) {
                                params.startPayTime = params.payTime.start;
                                params.endPayTime = params.payTime.end;
                                delete params.payTime;
                            }
                            if (params.channel && params.channel === 'ALL') {
                                delete params.channel;
                            }
                            if (params.subsiteName) {
                                params.subsiteName = params.subsiteName.name
                                    ? params.subsiteName.name
                                    : params.subsiteName;
                            }

                            const config = {
                                apiRoot: `${ENV.AUTH_API_ROOT}/CARPARK`,
                                apiPath: '/admin/carpark_orders/export',
                            };

                            api.get(params, config)
                                .then(() => {
                                    ForwardDataCenterModal();
                                })
                                .catch((error) => {
                                    services.errorHandle(error);
                                });
                        },
                    },
                },
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: { x: true },
                    columns: [
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'orderNumber',
                        },
                        {
                            property: 'carNumber',
                            width: 150,
                        },
                        {
                            property: 'name',
                        },
                        {
                            property: 'mobile',
                        },
                        {
                            property: 'totalAmount',
                        },
                        {
                            property: 'payableAmount',
                        },
                        {
                            property: 'status',
                        },
                        {
                            property: 'entryTime',
                        },
                        {
                            property: 'paidTime',
                        },
                        {
                            property: 'parkingTime',
                            width: 200,
                        },
                        { property: 'type' },
                        {
                            property: 'channel',
                        },
                        {
                            property: 'subsiteCarparkName',
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        fixed: 'right',
                        items: [
                            {
                                type: 'link',
                                text: '<<ckxq>>',
                                path: '/carpark-order/info/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        CarparkOrderInfoPage: {
            component: 'Card',
            content: {
                component: 'CarparkOrderInfoContainer',
                entity: 'carparkOrder',
                loaderType: 'get',
            },
        },
        CarparkOrderInfoContainer: {
            component: 'CarparkOrderInfo',
            entity: 'carparkOrder',
            loaderType: 'get',
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/carpark-order',
                    component: 'CarParkOrderView',
                    breadcrumbName: '<<carparkOrder>>',
                    childRoutes: [
                        {
                            path: '/info/:id',
                            component: 'CarparkOrderInfoPage',
                        },
                        {
                            path: '/logs/:id',
                            breadcrumbName: '<<fpjl>>',
                            component: 'ParkingInvoicesLogsPage',
                        },
                        {
                            path: '/',
                            component: 'CarparkOrderTablesPage',
                        },
                    ],
                    privilege: {
                        path: 'carparkOrder',
                        level: 'full',
                    },
                },
            ],
        },
    ],
};
