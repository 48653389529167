import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
export const MerchantExpressDeliveryLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`;
        config.apiPath = '/admin/express_deliveries';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/express_deliveries/${id}`;
        } else {
            data.type = 'MERCHANT';
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.baseInfo = {
                    merchants: res.delivery.subsiteMerchant ? [res.delivery.subsiteMerchant] : null,
                };
                let isWeighTharging = 'NO';
                let freightRule;
                if (res.freightRules && res.freightRules.length > 0) {
                    freightRule = res.freightRules[0];
                    freightRule.deliveryScopesInfo = freightRule.deliveryScopes;
                    freightRule.deliveryScopes = [];
                    if (
                        freightRule &&
                        freightRule.freightRanges &&
                        freightRule.freightRanges.length > 0
                    ) {
                        const freightRange = freightRule.freightRanges[0];
                        if (freightRange.freightLadders && freightRange.freightLadders.length > 0) {
                            if (
                                freightRange.freightLadders[0].startValue != null &&
                                freightRange.freightLadders[0].ladderValue != null &&
                                freightRange.freightLadders[0].ladderAmount != null
                            ) {
                                isWeighTharging = 'YES';
                            }
                        }
                    }
                }
                res.freightInfo = {
                    name: res.delivery.name,
                    isWeighTharging: isWeighTharging,
                    isWeighThargingFreightRule:
                        isWeighTharging === 'YES'
                            ? freightRule
                            : {
                                  deliveryRuleId: freightRule.deliveryRuleId,
                                  deliveryScopes: [],
                                  deliveryScopesInfo: [],
                                  showDeliveryScopes: [],
                                  freightRanges: [
                                      {
                                          startAmount: null,
                                          endAmount: null,
                                          firstAmount: null,
                                          freightLadders: [
                                              {
                                                  startValue: null,
                                                  ladderValue: null,
                                                  ladderAmount: null,
                                                  type: 'WEIGHT',
                                              },
                                          ],
                                      },
                                  ],
                              },
                    noWeighThargingFreightRule:
                        isWeighTharging === 'NO'
                            ? freightRule
                            : {
                                  deliveryRuleId: freightRule.deliveryRuleId,
                                  deliveryScopes: [],
                                  deliveryScopesInfo: [],
                                  showDeliveryScopes: [],
                                  freightRanges: [
                                      {
                                          startAmount: null,
                                          endAmount: null,
                                          firstAmount: null,
                                          freightLadders: [
                                              {
                                                  startValue: null,
                                                  ladderValue: null,
                                                  ladderAmount: null,
                                                  type: 'WEIGHT',
                                              },
                                          ],
                                      },
                                  ],
                              },
                };
            } else {
                res.result = res.result.map((item: any) => {
                    item.id = item.delivery.id;
                    item.status = item.delivery.status;
                    return item;
                });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`;
        config.apiPath = '/admin/express_deliveries';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`;
        config.apiPath = `/admin/express_deliveries/${id}`;
        const newParams: any = dataParser(params);
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    if (params.baseInfo && params.freightInfo) {
        newParams.delivery = {
            name: params.freightInfo.name,
            subsite: {
                id: params.baseInfo.merchants[0].subSiteId,
                name: params.baseInfo.merchants[0].subSiteName,
            },
            merchant: {
                id: params.baseInfo.merchants[0].merchantId,
                name: params.baseInfo.merchants[0].merchantName,
            },
        };
    }
    if (params.freightInfo) {
        const isWeighTharging = params.freightInfo.isWeighTharging;
        let freightRule;
        if (isWeighTharging === 'YES') {
            freightRule = params.freightInfo.isWeighThargingFreightRule;
        } else {
            freightRule = params.freightInfo.noWeighThargingFreightRule;
        }
        newParams.freightRules = [freightRule];
    }
    return newParams;
}
