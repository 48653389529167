import React, { PureComponent, Fragment } from 'react';
import { Card, Row, Col } from 'antd';
import { services } from '@comall-backend-builder/core';

import './index.less';

const api = services.api;

interface CreditEshopReportViewState {
    data: StatisticalData;
}
interface StatisticalData {
    amount: number;
    memberCount: number;
    point: number;
    refundAmount: number;
    refundPoints: number;
    totalQuantity: number;
    totalRefundQuantity: number;
}

export class CreditEshopReportView extends PureComponent<any, CreditEshopReportViewState> {
    state = {
        data: {
            amount: 0,
            memberCount: 0,
            point: 0,
            refundAmount: 0,
            refundPoints: 0,
            totalQuantity: 0,
            totalRefundQuantity: 0,
        },
    };
    componentDidMount() {
        const { id } = this.props.params;
        const apiPath = '/admin/credit_products/:id/statistical_data';
        const apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        api.get({ id }, { apiRoot: apiRoot, apiPath: apiPath }).then((res: any) => {
            this.setState({
                data: res,
            });
        });
    }

    render() {
        const { data } = this.state;
        return (
            <Fragment>
                {!!data && (
                    <Card type="inner" style={{ marginBottom: 32 }}>
                        <Row gutter={[16, 12]}>
                            <Col span={4}>
                                <div className="credit-eshop-report-item">
                                    <div className="credit-eshop-report-item-title">
                                        {services.language.getText('dhzrs')}
                                    </div>
                                    <div className="credit-eshop-report-item-content">
                                        {data.memberCount}
                                    </div>
                                </div>
                            </Col>
                            <Col span={5}>
                                <div className="credit-eshop-report-item">
                                    <div className="credit-eshop-report-item-title">
                                        {services.language.getText('ydhspzs')}
                                    </div>
                                    <div className="credit-eshop-report-item-content">
                                        {data.totalQuantity}
                                    </div>
                                </div>
                            </Col>
                            <Col span={5}>
                                <div className="credit-eshop-report-item">
                                    <div className="credit-eshop-report-item-title">
                                        {services.language.getText('ydhspshzs')}
                                    </div>
                                    <div className="credit-eshop-report-item-content">
                                        {data.totalRefundQuantity}
                                    </div>
                                </div>
                            </Col>
                            <Col span={5}>
                                <div className="credit-eshop-report-item">
                                    <div className="credit-eshop-report-item-title">
                                        {services.language.getText('xsze')}
                                    </div>
                                    <div className="credit-eshop-report-item-content">
                                        {data.point}
                                        {services.language.getText('point')}+
                                        {services.language.getText('monetaryUnit')}
                                        {data.amount}
                                    </div>
                                </div>
                            </Col>
                            <Col span={5}>
                                <div className="credit-eshop-report-item">
                                    <div className="credit-eshop-report-item-title">
                                        {services.language.getText('tkze')}
                                    </div>
                                    <div className="credit-eshop-report-item-content">
                                        -{data.refundPoints}
                                        {services.language.getText('point')}+
                                        {services.language.getText('monetaryUnit')}
                                        {data.refundAmount}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Card>
                )}
            </Fragment>
        );
    }
}
