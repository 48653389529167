import React from 'react';
import { InputNumber } from 'antd';
import get from 'lodash/get';
import { ConditionType } from '../index';
import { MaotaiPresaleConditionsProps } from '../../index';
import { language } from '@comall-backend-builder/core/lib/services';

interface AgeProps extends MaotaiPresaleConditionsProps {
    endText: string;
    index: number;
}
export default class Age extends React.PureComponent<AgeProps> {
    conditionType = ConditionType.AGE;
    inputChange = (val: number | undefined) => {
        const { onChange, value, index } = this.props;
        const list = value.map((item, itemIndex) => {
            if (this.conditionType === item.conditionType && index === itemIndex) {
                return { ...item, conditionValue: { value: val } };
            }
            return item;
        });

        onChange(list);
    };
    render() {
        const { value, endText, index } = this.props;
        const formData = value.find(
            (i, itemIndex) => i.conditionType === this.conditionType && index === itemIndex
        );
        const val = get(formData, 'conditionValue.value', undefined);
        return (
            <div>
                <span>{language.getText('yhdnlm')}</span>&nbsp;
                <InputNumber
                    defaultValue={18}
                    onChange={this.inputChange}
                    value={val}
                    min={1}
                    precision={0}
                    max={999}
                ></InputNumber>
                &nbsp;
                <span>岁{endText}</span>
            </div>
        );
    }
}
