import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { Spin, Button } from 'antd';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const { navigation } = services;

const prefix = 'risk-commodity-list-page';

interface RiskCommodityListProps {
    entity: Entity;
    params: any;
}

interface specItem {
    specKey: string;
    specValue: string | null;
    values: Array<valOption>;
}
interface valOption {
    optionValue: string;
    id: string;
    disabled: boolean;
    stock: number;
}
interface skuItem {
    barcode: string;
    costPrice: number;
    marketPrice: null | number;
    id: string | number;
    specList: specItem[];
    stock: number;
    totalSales: number | null;
    tenantAfterSalesRate?: number;
    tenantTotalAfterSales?: number;
    tenantTotalSales: number;
}
interface goodItem {
    brandName: string;
    goodsDetail: string;
    goodsDetailImage: string;
    goodsMainImages: Array<string>;
    id: string;
    goodsName: string;
    originName: string;
    skuList: skuItem[];
    skuStr: string;
    skuOwner: string;
    specList: specItem[];
    spuNo: string;
    supplierId: number;
    supplierName: string;
    tradeType: string;
    costPrice: number;
    marketPrice: number;
    totalSales: number;
    barcode: string;
    riskMetricScore: number;
}

export class RiskCommodityList extends PureComponent<RiskCommodityListProps> {
    state = {
        list: [],
        loading: false,
    };
    componentDidMount() {
        this.onGetProductDetail();
    }

    onGetProductDetail = async () => {
        this.setState({ loading: true });
        try {
            let res: goodItem[] = await services.api.get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                    apiPath: `/admin/goods/sales_list`,
                }
            );
            let newList =
                res?.map((good: goodItem) => {
                    let skuStr =
                        good.specList
                            ?.map((spu: specItem) => {
                                return spu.specValue;
                            })
                            .join(',') || '';
                    return {
                        ...good,
                        skuStr,
                    };
                }) || [];
            this.setState({ list: newList, loading: false });
        } catch (error) {
            this.setState({ loading: false });
            throw error;
        }
    };
    onGoSelectionPool = () => {
        navigation.goto('/selection-pool');
    };
    onGoProductDetail = (spuNo: string) => {
        window.open(`#/selection-pool/detail/${spuNo}`, '_blank');
    };
    renderRiskGoods = () => {
        const { list } = this.state;
        if (!list || !list.length) {
            return null;
        }
        const innerClassNames = {
            list: `${prefix}__list`,
            productWrap: `${prefix}__list__item`,
            pic: `${prefix}__list__item__pic`,
            right: `${prefix}__list__item__right`,
            top: `${prefix}__list__item__top`,
            name: `${prefix}__list__item__right__name`,
            sku: `${prefix}__list__item__right__sku`,
            riskNum: `${prefix}__list__item__right__risk`,
            tagWrap: `${prefix}__list__item__right__tag-wrap`,
            tag: `${prefix}__list__item__right__tag`,
        };
        return (
            <div className={innerClassNames.list}>
                {list.map((good: goodItem, index: number) => (
                    <div
                        className={innerClassNames.productWrap}
                        key={index}
                        onClick={() => this.onGoProductDetail(good.spuNo)}
                    >
                        <div className={innerClassNames.pic}>
                            <img src={good.goodsMainImages[0]} alt="" />
                        </div>
                        <div className={innerClassNames.right}>
                            <div className={innerClassNames.name}>{good.goodsName}</div>
                            <span className={innerClassNames.sku}>
                                {language.getText('style')}：{good.skuStr}
                            </span>
                            <div className={innerClassNames.riskNum}>
                                {language.getText('rishIndex')}
                                <span>{good.riskMetricScore}</span>
                            </div>
                            <div className={innerClassNames.tagWrap}>
                                <div className={innerClassNames.tag}>{good.supplierName}</div>
                                <div className={innerClassNames.tag}>{good.tradeType}</div>
                            </div>
                        </div>
                        {index <= 2 && (
                            <div className={innerClassNames.top}>
                                <span>TOP</span>
                                <span>{index + 1}</span>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        );
    };

    render() {
        const { loading } = this.state;
        const riskPic = require('../images/risk_list_title.jpg');
        return (
            <Spin spinning={loading}>
                <div className={`${prefix}`}>
                    <Button
                        type="link"
                        className={`${prefix}__btn`}
                        onClick={this.onGoSelectionPool}
                    >
                        {language.getText('backSelectCategory')}
                    </Button>
                    <h2>{language.getText('productWare')}</h2>
                    <div className={`${prefix}__title`}>
                        <img src={riskPic} alt="" />
                    </div>
                    {this.renderRiskGoods()}
                </div>
            </Spin>
        );
    }
}
