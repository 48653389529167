// @ts-nocheck
import React, { PureComponent } from 'react';
import { Modal, Upload, Icon, Button, message as AntMessage } from 'antd';
import { ExportButton } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core';

const { Dragger } = Upload;
export class UploadFile extends PureComponent<any, any> {
    state = {
        visible: false,
        confirmLoading: false,
        statusMsg: services.language.getText('uploadFileClickButton'),
        resource: {
            resourceId: 0,
            resourceName: '',
            resourceUrl: '',
            type: 'IMPORT',
        },
        fileList: [],
    };
    onImport = (resource: any) => {
        this.setState({
            statusMsg: services.language.getText('importBeforeNotPage'),
        });
        const { entity, params: entityParams, enterpriseId } = this.props;
        const apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        const apiPath = `/admin/enterprises/relation_mobile/file/${enterpriseId}/import`;

        services.api
            .post(resource, { apiRoot, apiPath })
            .then((res) => {
                AntMessage.success('导入需一段时间，请稍后查看');
                entity.search(entityParams);
                this.closeModal();
            })
            .catch((err) => {
                AntMessage.error(err.response.body.err_msg);

                this.setState({
                    statusMsg: services.language.getText('importFail'),
                });
                throw err;
            });
    };
    handleOk = () => {
        const { resource } = this.state;
        if (resource && resource.resourceId) {
            this.onImport(resource);
        } else {
            AntMessage.error(services.language.getText('pleaseUploadFile'));
            return;
        }
    };
    closeModal = () => {
        this.props.onClose();
    };
    render() {
        const { fileList, statusMsg } = this.state;
        const importExampleImg = './images/editor/import_example.png';
        const uploadProps = {
            accept: '.xlsx,.xls',
            multiple: false,
            onRemove: (file: any) => {
                this.setState((state: any) => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                        resource: {
                            resourceId: 0,
                            resourceName: '',
                            resourceUrl: '',
                            type: 'IMPORT',
                        },
                    };
                });
            },
            beforeUpload: (file: any) => {
                const apiRoot = `${ENV.AUTH_API_ROOT}/FILE`;
                const apiPath = '/admin/files/locations/53/upload';
                services.api
                    .upload(
                        { files: file },
                        { apiRoot, apiPath, fileName: 'files', progressCallBack: () => {} }
                    )
                    .then((res: any) => {
                        const { name, path, id } = res;
                        this.setState({
                            resource: {
                                resourceId: id,
                                resourceName: name,
                                resourceUrl: path,
                                type: 'IMPORT',
                            },
                            fileList: [file],
                        });
                    })
                    .catch((err: any) => {
                        if (err) {
                            AntMessage.error(
                                err.response?.body?.err_msg ||
                                    services.language.getText('uoloadFail')
                            );
                        }
                    });
                return false;
            },
            fileList,
        };
        const { visible } = this.props;
        return (
            <Modal
                onCancel={this.closeModal}
                visible={visible}
                title={services.language.getText('batchImport')}
                footer={[
                    <span className="internal-purchase-mobile-status-msg">{statusMsg}</span>,
                    <Button key="submit" type="primary" onClick={this.handleOk}>
                        导入
                    </Button>,
                    <Button key="back" onClick={this.closeModal}>
                        {services.language.getText('back')}
                    </Button>,
                ]}
            >
                <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">{services.language.getText('selectFile')}</p>
                    <p className="ant-upload-hint">支持扩展名：.xlsx、.xls</p>
                </Dragger>
                <div className="internal-purchase-mobile-tips">
                    <p className="internal-purchase-mobile-tips-text">
                        {services.language.getText('importRules')}
                    </p>
                    <p className="internal-purchase-mobile-tips-text">
                        1.在文件中填写需要导入的信息（样式如图所示），然后上传该文件
                    </p>
                    <p className="giving-coupon-schedules-tips-text-red">
                        注：表头名称必须填写为“手机号码”（不含符号、空格等其他字符）,否则会导致执行错误
                    </p>
                    <p className="internal-purchase-mobile-tips-text">
                        {services.language.getText('merchantBigCodeIntro2')}
                    </p>
                    <p className="internal-purchase-mobile-tips-text">
                        3文件命名将作为导入数据文件名展示
                    </p>
                    <p className="internal-purchase-mobile-tips-text">
                        4.请正确填写商品/货品编码，若重复只视为一个商品/货品
                    </p>
                    <p className="internal-purchase-mobile-template">
                        {services.language.getText('wjysmb')}：
                    </p>
                    <img
                        className="internal-purchase-mobile-template-img"
                        src={importExampleImg}
                        alt=""
                    />
                </div>
                <div className="upload-tips">
                    <ExportButton
                        type="link"
                        text={services.language.getText('xzpldrmb')}
                        showErrorMessage={true}
                        source={{
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/enterprises/relation_mobile/file/export.xlsx',
                        }}
                    />
                </div>
            </Modal>
        );
    }
}
