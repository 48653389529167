import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { MerchantTagStatus } from '../config/merchant/merchant-manager/merchant-tag';
const api = services.api;
export const MerchantTagLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/merchant_tags';
        const { id } = data;
        if (!id) {
            if (!data.subsiteIds) {
                delete data.subsiteIds;
            }
            if (data.status === MerchantTagStatus.ALL) {
                delete data.status;
            }
            const subsiteName = data.subsiteName;
            if (subsiteName) {
                data.subsiteName = subsiteName.name ? subsiteName.name : subsiteName;
            }
        }

        return await api.get(data, config).then((res: any) => {
            if (!id) {
                res.result = res.result.map((item: any) => {
                    item.subsites = item.subsiteVos;
                    let status = '';
                    if (item.status === MerchantTagStatus.ENABLE) {
                        status = MerchantTagStatus.ENABLE;
                    } else {
                        status = MerchantTagStatus.DISABLE;
                    }
                    return { ...item, status };
                });
            }
            return res;
        });
    },
};
