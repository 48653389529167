import React, { PureComponent, Fragment } from 'react';
import {
    Row,
    Col,
    InputNumber,
    Button,
    Checkbox,
    TimePicker,
    Popconfirm,
    Table,
    message,
    Modal,
    Form,
    Input,
    Icon,
} from 'antd';
import { api, errorHandle, language } from '@comall-backend-builder/core/lib/services';
import moment from 'moment';
import { cloneDeep, find, remove, uniq } from 'lodash';
import { NoticeEvent } from './index';

import './index.less';
import { services } from '@comall-backend-builder/core';

const prefix = 'subscribe-config-page';
const noRequired = `${prefix}__setting-item-label__no-required`;
const formItemLayout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 18 },
        sm: { span: 14 },
    },
};
interface Props {
    noticeEvent: undefined | NoticeEvent;
    noticeUsers: string[];
    subsiteId: string;
    onChangeNotice: (type: string, operateType: string, paramsType: string, value?: any) => void;
    onChangeUsers: (list: any[], operateType: string) => void;
}
interface ShoppingGuideVo {
    phone: string;
    shoppingGuide: boolean;
}
export class WarnConfigs extends PureComponent<Props, any> {
    state = {
        visible: false,
        mobiles: '',
    };
    selectedRows: any[] = [];
    private openModal = () => {
        this.setState({ visible: true });
    };
    deleteSelected = (id: string) => {
        const { noticeUsers, onChangeUsers } = this.props;
        onChangeUsers(
            noticeUsers.filter((i: string) => i !== id),
            'delete'
        );
    };
    private sendNotice = async (id: string) => {
        const { subsiteId } = this.props;
        try {
            await api.post(
                {
                    subsiteId,
                    mobile: id,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                    apiPath: '/admin/business_remind/test',
                }
            );
            message.success(`消息已发送`);
        } catch (error) {
            errorHandle(error as any);
            throw error;
        }
    };
    batchRemove = () => {
        return () => {
            // @ts-ignore：
            const selectedRow = this.selectedRows;
            if (!selectedRow || selectedRow.length === 0) {
                message.info(services.language.getText('selectBatchDeleteColumn'));
                return;
            }
            const { noticeUsers, onChangeUsers } = this.props;
            const result = cloneDeep(noticeUsers);
            remove(result, (i) => !!find(selectedRow, (j) => j.id === i));
            onChangeUsers(result, 'remove');
            this.selectedRows = [];
        };
    };
    handleSubmit = async () => {
        const { mobiles } = this.state;
        const { onChangeUsers, noticeUsers } = this.props;
        let hasValidate = this.validate();
        if (hasValidate) {
            try {
                let res: ShoppingGuideVo[] = await api.get(
                    {
                        phones: mobiles,
                    },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}`,
                        apiPath:
                            '/WEB-API/admin/guides/shopping_guides/is_shopping_guide_user_by_phone',
                    }
                );
                if (res?.length) {
                    let noShoppingGuide = res.filter(
                        (item: ShoppingGuideVo) => !item.shoppingGuide
                    );
                    if (noShoppingGuide.length) {
                        let errorStr = noShoppingGuide
                            .map((mobile: ShoppingGuideVo) => mobile.phone)
                            .join(',');
                        message.warning(
                            `${language.getText('sjhxxzdgddlhcnjsdqywxtz')}${errorStr}）`
                        );
                        return;
                    }
                    const mobileArr = mobiles.split(',');
                    const users = uniq(noticeUsers.concat(mobileArr));
                    onChangeUsers(users, 'add');
                    this.closeModal();
                }
            } catch (error) {
                throw error;
            }
        }
    };
    validate = () => {
        const { mobiles } = this.state;
        if (!mobiles) {
            message.warning(language.getText('qsrsjh'));
            return false;
        }
        const regular = /^1\d{10}$/;
        const mobileArr = mobiles.split(',');
        if (mobileArr && mobileArr.length > 999) {
            message.warning(language.getText('mostFillInPhoneNum'));
            return false;
        }
        if (mobileArr && mobileArr.length) {
            for (const mobile of mobileArr) {
                if (mobile && !regular.test(mobile)) {
                    message.warning(services.language.getText('rightMobileInput'));
                    return false;
                }
            }
        }
        return true;
    };
    closeModal = () => {
        this.setState({ visible: false, mobiles: '' });
    };
    inputChange = (e: any) => {
        const { value } = e.target;
        this.setState({ mobiles: value });
    };
    renderAlarmEvent() {
        return (
            <Fragment>
                <Row>
                    <Col span={4} className={`${prefix}__setting-item-label ${noRequired}`}>
                        {language.getText('qywxbjtzsj')}
                    </Col>
                    <Col span={20} className={`${prefix}__setting-item-control`}>
                        {this.renderGoodConfigs()}
                        <div className="order"></div>
                        {this.renderOrderConfigs()}
                        <div className="order"></div>
                    </Col>
                </Row>
                <Row>
                    <Col span={4} className={`${prefix}__setting-item-label ${noRequired}`}>
                        {language.getText('qywxbjtzdx')}
                    </Col>
                    <Col span={20} className={`${prefix}__setting-item-control`}>
                        {this.renderNotices()}
                    </Col>
                </Row>
                <div className="order"></div>
            </Fragment>
        );
    }
    getTableConfig() {
        let configs = {
            rowKey: 'id',
            pagination: false,
            bordered: false,
            columns: [
                {
                    title: language.getText('phoneNum'),
                    dataIndex: 'mobile',
                    key: 'mobile',
                },
                {
                    title: services.language.getText('common.tableAction'),
                    className: 'action-column',
                    render: (text: any, record: any) => {
                        return (
                            <div>
                                <Popconfirm
                                    onConfirm={() => this.deleteSelected(record.id)}
                                    title={services.language.getText('qrscm')}
                                    okText={services.language.getText('common.ok')}
                                    cancelText={services.language.getText('common.cancel')}
                                >
                                    <Button style={{ paddingLeft: 0 }} type="link">
                                        {services.language.getText('common.delete')}
                                    </Button>
                                </Popconfirm>
                                <Button
                                    style={{ padding: 0 }}
                                    type="link"
                                    onClick={() => this.sendNotice(record.id)}
                                >
                                    {language.getText('fscstz')}
                                </Button>
                            </div>
                        );
                    },
                },
            ],
            rowSelection: {
                onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                    this.selectedRows = selectedRows;
                },
            },
        };
        return configs;
    }
    renderNotices() {
        const { noticeUsers } = this.props;
        let tableConfig = this.getTableConfig();
        let users =
            noticeUsers?.map((item: string) => {
                return {
                    id: item,
                    mobile: item,
                };
            }) || [];
        const tableProps = { ...tableConfig, dataSource: users };
        return (
            <div>
                <Button
                    type="primary"
                    icon="plus"
                    style={{ marginBottom: '12px' }}
                    onClick={this.openModal}
                >
                    {language.getText('tjsjh')}
                </Button>
                <Table {...tableProps} pagination={false} />
                {this.renderFooter()}
                <div className="ant-form-extra" style={{ marginTop: 20, marginBottom: 20 }}>
                    {language.getText('xzdgddlhcnjsdqywxtz')}
                </div>
            </div>
        );
    }
    renderFooter = () => {
        return (
            <div className="product-selected-footer">
                <div className="batch-remove">
                    <Popconfirm
                        onConfirm={this.batchRemove()}
                        title={services.language.getText('confirmDeleteColumn')}
                        okText={services.language.getText('common.ok')}
                        cancelText={services.language.getText('common.cancel')}
                    >
                        <Button className="setting-item-ok" type="default">
                            {services.language.getText('batchDelete')}
                        </Button>
                    </Popconfirm>
                </div>
            </div>
        );
    };
    renderGoodConfigs() {
        const { noticeEvent, onChangeNotice } = this.props;
        const HHmm = noticeEvent?.time?.value ? noticeEvent?.time?.value.split(':') : [];
        const timeValue =
            HHmm.length === 2
                ? moment()
                      .set('h', +HHmm[0])
                      .set('m', +HHmm[1])
                : undefined;
        return (
            <div className={`${prefix}__setting-item-control__wrap`}>
                <div style={{ fontWeight: 500 }}>
                    {language.getText('spxgbj')}
                    <Icon
                        style={{ paddingLeft: 5, color: 'rgb(247,157,19)' }}
                        type="exclamation-circle"
                        theme="filled"
                    />
                </div>
                <div className={`${prefix}__setting-item-control__item`}>
                    <Checkbox
                        checked={noticeEvent?.threshold?.checkbox}
                        onChange={(value: any) =>
                            onChangeNotice(
                                'threshold',
                                'checkbox',
                                'NOTICE_GOODS_COST_PRICE_CHANGE_LARGE',
                                value
                            )
                        }
                    >
                        {language.getText('noticeGoodsCostPrice')}
                    </Checkbox>
                    <div className="right">
                        <span className="title">{language.getText('bjfdcg')}</span>
                        <div className={`${prefix}__setting-item-control__ratio`}>
                            {noticeEvent?.threshold?.isEdit ? (
                                <InputNumber
                                    type="number"
                                    min={1}
                                    precision={0}
                                    max={99999}
                                    style={{ width: 80 }}
                                    value={noticeEvent?.threshold?.value}
                                    onChange={(value: any) =>
                                        onChangeNotice(
                                            'threshold',
                                            'value',
                                            'NOTICE_GOODS_COST_PRICE_CHANGE_THRESHOLD',
                                            value
                                        )
                                    }
                                />
                            ) : (
                                <div className="value">{noticeEvent?.threshold?.value}</div>
                            )}
                            <span>%</span>
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => {
                                    let type = noticeEvent?.threshold?.isEdit ? 'submit' : 'edit';
                                    onChangeNotice(
                                        'threshold',
                                        'isEdit',
                                        'NOTICE_GOODS_COST_PRICE_CHANGE_THRESHOLD',
                                        type
                                    );
                                }}
                            >
                                {noticeEvent?.threshold?.isEdit
                                    ? language.getText('common.save')
                                    : language.getText('common.edit')}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={`${prefix}__setting-item-control__item`}>
                    <Checkbox
                        checked={noticeEvent?.stock?.checkbox}
                        onChange={(value: any) =>
                            onChangeNotice('stock', 'checkbox', 'NOTICE_GOODS_STOCK_FEW', value)
                        }
                    >
                        {language.getText('ydyspbz')}
                    </Checkbox>
                    <div className="right">
                        <span className="title">{language.getText('spbz')}</span>
                        <div className={`${prefix}__setting-item-control__ratio`}>
                            {noticeEvent?.stock?.isEdit ? (
                                <InputNumber
                                    type="number"
                                    min={1}
                                    precision={0}
                                    max={99999}
                                    width={80}
                                    value={noticeEvent?.stock?.value}
                                    onChange={(value: any) =>
                                        onChangeNotice(
                                            'stock',
                                            'value',
                                            'NOTICE_GOODS_STOCK_THRESHOLD',
                                            value
                                        )
                                    }
                                />
                            ) : (
                                <div className="value">{noticeEvent?.stock?.value}</div>
                            )}
                            <span>{language.getText('jian')}</span>
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => {
                                    let type = noticeEvent?.stock?.isEdit ? 'submit' : 'edit';
                                    onChangeNotice(
                                        'stock',
                                        'isEdit',
                                        'NOTICE_GOODS_STOCK_THRESHOLD',
                                        type
                                    );
                                }}
                            >
                                {noticeEvent?.stock?.isEdit
                                    ? language.getText('common.save')
                                    : language.getText('common.edit')}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={`${prefix}__setting-item-control__item`}>
                    <Checkbox
                        checked={noticeEvent?.invalid?.checkbox}
                        onChange={(value: any) =>
                            onChangeNotice(
                                'invalid',
                                'checkbox',
                                'NOTICE_GOODS_SUPPLIER_OFF_SHELF',
                                value
                            )
                        }
                    >
                        {language.getText('noticeGoodsSupplierOffShelf')}
                    </Checkbox>
                </div>
                <div className={`${prefix}__setting-item-control__item`}>
                    <div className="right">
                        <span className="title">{language.getText('fsqssj')}</span>
                        <div className={`${prefix}__setting-item-control__ratio`}>
                            {noticeEvent?.time?.isEdit ? (
                                <TimePicker
                                    value={timeValue}
                                    allowClear={false}
                                    style={{ width: 100, marginRight: 10 }}
                                    format="HH:mm"
                                    onChange={(value: any) =>
                                        onChangeNotice(
                                            'time',
                                            'value',
                                            'NOTICE_GOODS_START_TIME',
                                            value
                                        )
                                    }
                                />
                            ) : (
                                <div className="val">{noticeEvent?.time?.value}</div>
                            )}
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => {
                                    let type = noticeEvent?.time?.isEdit ? 'submit' : 'edit';
                                    onChangeNotice(
                                        'time',
                                        'isEdit',
                                        'NOTICE_GOODS_START_TIME',
                                        type
                                    );
                                }}
                            >
                                {noticeEvent?.time?.isEdit
                                    ? language.getText('common.save')
                                    : language.getText('common.edit')}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={`${prefix}__setting-item-control__item`}>
                    <div className="right">
                        <span className="title">{language.getText('fsjgxs')}</span>
                        <div className={`${prefix}__setting-item-control__ratio`}>
                            {noticeEvent?.timeNum?.isEdit ? (
                                <InputNumber
                                    type="number"
                                    min={1}
                                    precision={0}
                                    width={80}
                                    style={{ marginRight: 10 }}
                                    max={24}
                                    value={noticeEvent?.timeNum?.value}
                                    onChange={(value: any) =>
                                        onChangeNotice(
                                            'timeNum',
                                            'value',
                                            'NOTICE_GOODS_INTERVAL',
                                            value
                                        )
                                    }
                                />
                            ) : (
                                <div className="val">{noticeEvent?.timeNum?.value}</div>
                            )}
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => {
                                    let type = noticeEvent?.timeNum?.isEdit ? 'submit' : 'edit';
                                    onChangeNotice(
                                        'timeNum',
                                        'isEdit',
                                        'NOTICE_GOODS_INTERVAL',
                                        type
                                    );
                                }}
                            >
                                {noticeEvent?.timeNum?.isEdit
                                    ? language.getText('common.save')
                                    : language.getText('common.edit')}
                            </Button>
                        </div>
                    </div>
                </div>
                <div className={`${prefix}__setting-item-control__item`}>
                    <div className="right">
                        <span className="title">{language.getText('fspc')}</span>
                        <div className={`${prefix}__setting-item-control__ratio`}>
                            {noticeEvent?.frequency?.isEdit ? (
                                <InputNumber
                                    type="number"
                                    min={1}
                                    precision={0}
                                    width={80}
                                    max={999999}
                                    style={{ marginRight: 10 }}
                                    value={noticeEvent?.frequency?.value}
                                    onChange={(value: any) =>
                                        onChangeNotice(
                                            'frequency',
                                            'value',
                                            'NOTICE_GOODS_RATE',
                                            value
                                        )
                                    }
                                />
                            ) : (
                                <div className="val">{noticeEvent?.frequency?.value}</div>
                            )}
                            <Button
                                type="primary"
                                size="small"
                                onClick={() => {
                                    let type = noticeEvent?.frequency?.isEdit ? 'submit' : 'edit';
                                    onChangeNotice(
                                        'frequency',
                                        'isEdit',
                                        'NOTICE_GOODS_RATE',
                                        type
                                    );
                                }}
                            >
                                {noticeEvent?.frequency?.isEdit
                                    ? language.getText('common.save')
                                    : language.getText('common.edit')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
    renderOrderConfigs() {
        const { noticeEvent, onChangeNotice } = this.props;
        return (
            <div className={`${prefix}__setting-item-control__wrap`}>
                <div style={{ fontWeight: 500 }}>
                    {language.getText('ddycbj')}
                    <Icon
                        style={{ paddingLeft: 5, color: 'rgb(247,157,19)' }}
                        type="exclamation-circle"
                        theme="filled"
                    />
                </div>
                <div className={`${prefix}__setting-item-control__item`}>
                    <Checkbox
                        checked={noticeEvent?.orderFailed?.checkbox}
                        onChange={(value: any) =>
                            onChangeNotice(
                                'orderFailed',
                                'checkbox',
                                'NOTICE_ORDER_CREATE_FAIL',
                                value
                            )
                        }
                    >
                        {language.getText('noticeOfderCreateFail')}
                    </Checkbox>
                </div>
                <div className={`${prefix}__setting-item-control__item`}>
                    <Checkbox
                        checked={noticeEvent?.payFailed?.checkbox}
                        onChange={(value: any) =>
                            onChangeNotice(
                                'payFailed',
                                'checkbox',
                                'NOTICE_ORDER_PAYMENT_FAIL',
                                value
                            )
                        }
                    >
                        {language.getText('paymentFail')}
                    </Checkbox>
                </div>
                <div className={`${prefix}__setting-item-control__item`}>
                    <Checkbox
                        checked={noticeEvent?.declareFailed?.checkbox}
                        onChange={(value: any) =>
                            onChangeNotice(
                                'declareFailed',
                                'checkbox',
                                'NOTICE_ORDER_CUSTOM_FAIL',
                                value
                            )
                        }
                    >
                        {language.getText('orderCustomFail')}
                    </Checkbox>
                </div>
            </div>
        );
    }
    renderModal() {
        const { visible, mobiles } = this.state;
        const modalProps = {
            width: 600,
            title: services.language.getText('tjsjh'),
            visible: visible,
            footer: null,
            onCancel: this.closeModal,
        };
        return (
            <div style={{ display: 'inline-block' }}>
                {visible ? (
                    <Modal {...modalProps}>
                        <Form {...formItemLayout}>
                            <Form.Item labelAlign={'right'} label={language.getText('tj')} required>
                                <Input.TextArea
                                    autoSize={{ minRows: 3, maxRows: 8 }}
                                    placeholder={services.language.getText('inputPlease')}
                                    value={mobiles}
                                    onChange={this.inputChange}
                                />
                                <div className="ant-form-extra">
                                    {services.language.getText('rtjdgsjhyyw')}
                                </div>
                            </Form.Item>
                            <Form.Item className="public-label" style={{ marginLeft: 95 }}>
                                <Button style={{ marginRight: 10 }} onClick={this.closeModal}>
                                    {language.getText('common.cancel')}
                                </Button>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    onClick={this.handleSubmit}
                                >
                                    {language.getText('common.submit')}
                                </Button>
                            </Form.Item>
                        </Form>
                    </Modal>
                ) : null}
            </div>
        );
    }
    render() {
        return (
            <Fragment>
                <div className={`${prefix}__sub-form-title`}>{language.getText('bjpz')}</div>
                {this.renderAlarmEvent()}
                {this.renderModal()}
            </Fragment>
        );
    }
}
