import { services } from '@comall-backend-builder/core/';
import { forEach, isEmpty } from 'lodash';
import { subsites } from '../containers/page-design';

export const MerchantPageLoader = {
    get: async function(data: any, config: any) {
        if (data.id) {
            config.apiPath = '/admin/merchant_pages/:id';
        } else {
            config.apiPath = '/admin/merchant_pages';
        }
        if (!isEmpty(subsites)) {
            data.subsiteIds = subsites
                .map((subsite: any) => {
                    return subsite.id;
                })
                .join(',');
        }
        return services.api.get(data, config).then((res: any) => {
            if (res && res.type) {
                res.type = { id: res.type };
            }
            if (res && res.result) {
                forEach(res.result, (i) => {
                    i.canDelete = !i.enabled;
                    i.pageName = i.name;
                });
            }
            return res;
        });
    },
    post: async function(data: any, config: any) {
        config.apiPath = '/admin/merchant_pages';
        let params = {
            enabled: data.enabled,
            template: data.template,
            name: data.name,
            merchantId: data.merchantSelect[0]['merchantId'],
            groupSubsiteName: data.merchantSelect[0]['merchantName'],
            subsites: [
                {
                    id: data.merchantSelect[0]['subSiteId'],
                    name: data.merchantSelect[0]['subSiteName'],
                },
            ],
            type: 'merchant',
        };
        return services.api.post(params, config);
    },
    put: async function(data: any, config: any) {
        config.apiPath = '/admin/merchant_pages/:id';
        let params = {
            id: data.id,
            enabled: data.enabled,
            template: data.template,
            name: data.name,
            merchantId: data.merchantSelect[0]['merchantId'],
            groupSubsiteName: data.merchantSelect[0]['merchantName'],
            subsites: [
                {
                    id: data.merchantSelect[0]['subSiteId'],
                    name: data.merchantSelect[0]['subSiteName'],
                },
            ],
            type: 'merchant',
        };
        return services.api.put(params, config);
    },
    delete: async function(data: any, config: any) {
        config.apiPath = '/admin/merchant_pages/:id';
        return services.api.delete(data, config);
    },
};
