import React, { Component } from 'react';
import { DragSource, DropTarget } from 'react-dnd';

let dragingIndex = -1;

class BodyRow extends Component<any, any> {
    render() {
        const {
            isOver,
            connectDragSource,
            connectDropTarget,
            moveRow,
            disableDragIndex,
            ...restProps
        } = this.props;
        if (
            disableDragIndex &&
            disableDragIndex.length &&
            disableDragIndex.includes(restProps.index)
        ) {
            return <tr {...restProps} />;
        }
        const style = { ...restProps.style, cursor: 'move' };
        let className = restProps.className;
        if (isOver) {
            if (restProps.index > dragingIndex) {
                className += ' drop-over-downward';
            }
            if (restProps.index < dragingIndex) {
                className += ' drop-over-upward';
            }
        }
        return connectDragSource(
            connectDropTarget(<tr {...restProps} className={className} style={style} />)
        );
    }
}

const rowSource = {
    beginDrag(props: any) {
        dragingIndex = props.index;
        return {
            index: props.index,
        };
    },
};

const rowTarget = {
    drop(props: any, monitor: any) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;

        // Don't replace items with themselves
        if (dragIndex === hoverIndex) {
            return;
        }

        // Time to actually perform the action
        props.moveRow(dragIndex, hoverIndex);

        // Note: we're mutating the monitor item here!
        // Generally it's better to avoid mutations,
        // but it's good here for the sake of performance
        // to avoid expensive index searches.
        monitor.getItem().index = hoverIndex;
    },
};

export const DragableBodyRow = DropTarget('row', rowTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
}))(
    DragSource('row', rowSource, (connect) => ({
        connectDragSource: connect.dragSource(),
    }))(BodyRow)
);
