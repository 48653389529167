import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { isEmpty } from 'lodash';

export const config: Config = {
    entities: {
        SocialIdentityTagPermissionsEntity: {
            apiPath: '/loader/admin/socialIdentityTagPermissions',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<tagName>>',
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                tagName: {
                    displayName: '<<tagName>>',
                    type: 'string',
                },
                tagTypeName: {
                    displayName: '<<bqlx>>',
                    type: 'string',
                },

                tagId: {
                    type: 'string.options.select',
                    displayName: '<<xzsjsfbq>>',
                    options: [],
                    rules: [{ required: true }],
                    source: {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`,
                        apiPath: '/admin/tags/by_type?type_code=SOCIAL_IDENTITY',
                    },
                },
                functionResourceIds: {
                    type: 'array.optionIds.checkbox.tip',
                    displayName: '<<gnqx>>',
                    defaultValue: [],
                    options: [],
                    source: {
                        apiRoot: `${ENV.AUTH_API_ROOT}/SHOW`,
                        apiPath: '/admin/social_identity_tag_functions/function_resources',
                    },
                },
                dataResourceIds: {
                    type: 'array.optionIds.checkbox.tip',
                    displayName: '<<sjqx>>',
                    defaultValue: [],
                    options: [],
                    source: {
                        apiRoot: `${ENV.AUTH_API_ROOT}/SHOW`,
                        apiPath: '/admin/social_identity_tag_functions/data_resources',
                    },
                },
            },
        },
    },
    components: {
        SocialIdentityTagPermissionsView: {
            component: 'Viewport',
            entity: 'SocialIdentityTagPermissionsEntity',
        },
        SocialIdentityTagPermissionsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                { component: 'SocialIdentityTagPermissionsFilter' },
                { component: 'SocialIdentityTagPermissionsTable' },
            ],
        },
        SocialIdentityTagPermissionsFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
            ],
        },
        SocialIdentityTagPermissionsTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'Button',
                    text: '<<xzbqqx>>',
                    route: '/socialIdentityTagPermissions/add',
                    type: 'primary',
                    icon: 'plus',
                    style: {
                        width: 140,
                    },
                    className: 'mt24 mb24',
                },
                {
                    component: 'DataTable',
                    componentId: 'SocialIdentityTagPermissionsDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'tagName',
                        },

                        {
                            property: 'tagTypeName',
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        items: [
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                path: '/socialIdentityTagPermissions/edit/{{row.id}}',
                            },

                            {
                                type: 'delete',
                                text: '<<common.delete>>',
                                config: {
                                    type: 'link',
                                    id: 'row.id',
                                    tooltip: null,
                                    title: '<<confirmDelete>>',
                                    okText: '<<common.ok>>',
                                    cancelText: '<<common.cancel>>',
                                },
                            },
                        ],
                    },
                },
            ],
        },
        SocialIdentityTagPermissionsEditPage: {
            component: 'Card',
            content: { component: 'SocialIdentityTagPermissionsEditForm' },
        },
        SocialIdentityTagPermissionsEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'SocialIdentityTagPermissionsEntity',
            loaderType: 'get',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: [
                {
                    property: 'tagId',
                    controlConfig: {
                        disabled: true,
                    },
                },
                { property: 'functionResourceIds' },
                { property: 'dataResourceIds' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const { functionResourceIds, dataResourceIds } = entity;
                if (isEmpty(functionResourceIds) && isEmpty(dataResourceIds)) {
                    AntMessage.warning(services.language.getText('zsgxyzqx'));
                    return false;
                }

                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        SocialIdentityTagPermissionsAddPage: {
            component: 'Card',
            content: { component: 'SocialIdentityTagPermissionsAddForm' },
        },
        SocialIdentityTagPermissionsAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'SocialIdentityTagPermissionsEntity',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: [
                { property: 'tagId' },
                { property: 'functionResourceIds' },
                { property: 'dataResourceIds' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const { functionResourceIds, dataResourceIds } = entity;
                if (isEmpty(functionResourceIds) && isEmpty(dataResourceIds)) {
                    AntMessage.warning(services.language.getText('zsgxyzqx'));
                    return false;
                }

                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/socialIdentityTagPermissions',
                    component: 'SocialIdentityTagPermissionsView',
                    breadcrumbName: '<<socialIdentityTagFunction>>',
                    privilege: {
                        path: 'socialIdentityTagFunction',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'SocialIdentityTagPermissionsAddPage',
                            breadcrumbName: '<<xzbqqx>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'SocialIdentityTagPermissionsEditPage',
                            breadcrumbName: '<<bjbqqx>>',
                        },
                        { path: '/', component: 'SocialIdentityTagPermissionsPage' },
                    ],
                },
            ],
        },
    ],
};
