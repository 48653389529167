import * as React from 'react';

import { ArrayMode } from '@comall-backend-builder/types';
import { FeedbackTypes } from './feedback-types';

/**
 *  反馈类型
 */
export class ArrayFeedbackTypesMode extends ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <FeedbackTypes {...controlInfo} />;
    }
}
