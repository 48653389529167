/*
 * @Author: zhuhuajuan
 * @Description: 会员等级管理
 */
import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';

export let config: Config = {
    components: {
        CardLevelView: {
            component: 'Viewport',
            entity: 'cardLevel',
        },
        CardLevelPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [{ component: 'CardLevelTable' }],
        },
        CardLevelTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'Button',
                    text: '新增会员等级',
                    route: '/card-level/add',
                    type: 'primary',
                    icon: 'plus',
                    style: {
                        width: 140,
                    },
                    className: 'mb24',
                },
                {
                    component: 'DataTable',
                    componentId: 'CardLevelDataTable',
                    loadFirstPage: true,
                    columns: [
                        {
                            property: 'baseInfo.code',
                        },
                        {
                            property: 'baseInfo.level',
                        },
                        {
                            property: 'baseInfo.name',
                        },
                        {
                            property: 'baseInfo.iconPicture',
                            displayConfig: {
                                style: {
                                    height: 36,
                                },
                            },
                        },
                        {
                            property: 'baseInfo.cardCoverPicture',
                            displayConfig: {
                                style: {
                                    height: 63,
                                },
                            },
                        },
                        {
                            property: 'baseInfo.status',
                            displayConfig: {
                                statusConfig: {
                                    ENABLE: {
                                        text: '<<enable>>',
                                        status: 'processing',
                                    },
                                    DISABLE: {
                                        text: '<<disabled>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        fixed: 'right',
                        width: 140,
                        items: [
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                path: '/card-level/edit/{{row.id}}',
                            },
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    statusKey: 'baseInfo.status',
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                                    handles: [
                                        {
                                            params: { status: 'DISABLE' },
                                            value: 'ENABLE',
                                            apiPath: '/admin/card_levels/:id/status',
                                            config: {
                                                content: '<<disabled>>',
                                                text: '<<disabled>>',
                                            },
                                            confirm: {
                                                text: '<<isDisabled>>{{row.name}}?',
                                            },
                                        },
                                        {
                                            params: { status: 'ENABLE' },
                                            value: 'DISABLE',
                                            apiPath: '/admin/card_levels/:id/status',
                                            config: { content: '<<enable>>', text: '<<enable>>' },
                                            confirm: {
                                                text: '<<isEnable>>{{row.name}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        },
        CardLevelAddPage: {
            component: 'Card',
            content: { component: 'CardLevelAddForm' },
        },
        CardLevelAddForm: {
            component: 'CreateForm',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 16,
            style: { width: 900 },
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.code' },
                { property: 'baseInfo.level' },
                {
                    property: 'baseInfo.iconPicture',
                    extra: '建议尺寸：136px * 62px，大小不超过1M，支持jpg、png、jpeg格式',
                },
                {
                    property: 'baseInfo.cardCoverPicture',
                    extra: '建议尺寸：600px * 380px，大小不超过1M，支持jpg、png、jpeg格式',
                },
                {
                    property: 'baseInfo.cardDiscountTag',
                },
                {
                    property: 'baseInfo.status',
                },
                {
                    property: 'rightInfo.rights',
                },
                {
                    property: 'rightInfo.description',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },

            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        CardLevelEditPage: {
            component: 'Card',
            content: { component: 'CardLevelEditForm' },
        },
        CardLevelEditForm: {
            component: 'ModifyForm',
            direction: 'horizontal',
            entity: 'cardLevel',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 16,
            style: { width: 900 },
            fields: [
                { property: 'baseInfo.code', modifiable: false },
                { property: 'baseInfo.level', modifiable: false },
                { property: 'baseInfo.name' },
                {
                    property: 'baseInfo.iconPicture',
                    extra: '建议尺寸：136px * 62px，大小不超过1M，支持jpg、png、jpeg格式',
                },
                {
                    property: 'baseInfo.cardCoverPicture',
                    extra: '建议尺寸：600px * 380px，大小不超过1M，支持jpg、png、jpeg格式',
                },
                {
                    property: 'baseInfo.cardDiscountTag',
                },
                {
                    property: 'baseInfo.status',
                },
                {
                    property: 'rightInfo.rights',
                },
                {
                    property: 'rightInfo.description',
                },
            ],
            submit: { text: '<<common.save>>' },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },

            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/card-level',
                    breadcrumbName: '会员卡等级',
                    component: 'CardLevelView',
                    privilege: {
                        path: 'cardLevel',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add/',
                            component: 'CardLevelAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'CardLevelEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        { path: '/', component: 'CardLevelPage' },
                    ],
                },
            ],
        },
    ],
};
