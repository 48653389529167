import React, { Component } from 'react';
import { Modal, Input, Button } from 'antd';
import CreateMerchantSelector from '../create-merchant-selector'; //创建店员下期上

import './index.less';
import { services } from '@comall-backend-builder/core';
const { TextArea } = Input;

interface AuditGuideUserModalProps {
    visible: boolean;
    onAudit: (val: any) => void;
    onCancel: () => void;
}

interface AuditGuideUserModalState {
    switchValue: boolean;
    reason: string;
}

export class AuditGuideUserModal extends Component<
    AuditGuideUserModalProps,
    AuditGuideUserModalState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            switchValue: true,
            reason: '',
        };
    }

    onReject = () => {
        const { reason } = this.state;

        this.props.onAudit({ result: false, reason });
    };

    onCancel = () => {
        this.props.onCancel();
    };

    onOk = () => {
        const { reason, switchValue } = this.state;

        this.props.onAudit({ result: true, reason, addMerchantUser: switchValue });
    };
    switchChange = (val: boolean) => {
        this.setState({ switchValue: val });
    };
    onInputChange = (e: any) => {
        const { value } = e.target;
        this.setState({
            reason: value,
        });
    };

    renderFooter = () => {
        return (
            <div>
                <Button onClick={this.onReject}>{services.language.getText('shbtg')}</Button>
                &nbsp;&nbsp;&nbsp;&nbsp;{' '}
                <Button type="primary" onClick={this.onOk}>
                    {services.language.getText('approved')}
                </Button>
            </div>
        );
    };

    render() {
        const { visible } = this.props;
        const { switchValue } = this.state;
        return (
            <Modal
                title={services.language.getText('audity')}
                width={800}
                visible={visible}
                className="role-selector-modal"
                onCancel={this.onCancel}
                footer={this.renderFooter()}
            >
                <div className="audit-guide-user-form">
                    <div className="audit-guide-user-form-item">
                        <div className="audit-guide-user-form-label">
                            {services.language.getText('reason')}:
                        </div>{' '}
                        <TextArea
                            onChange={this.onInputChange}
                            className="audit-guide-user-form-input"
                            rows={4}
                            placeholder={services.language.getText('inputPlease')}
                        ></TextArea>
                    </div>
                    <div className="audit-guide-user-form-item">
                        <div className="audit-guide-user-form-label">
                            {services.language.getText('cjzjdy')}:
                        </div>
                        <CreateMerchantSelector
                            value={switchValue}
                            onChange={this.switchChange}
                        ></CreateMerchantSelector>
                    </div>
                </div>
            </Modal>
        );
    }
}
