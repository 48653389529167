import * as React from 'react';
import { modes } from '@comall-backend-builder/types';

export class ListItemTexts extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        if (!value || value.length === 0) {
            return <div></div>;
        }
        if (typeof value === 'string') {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div>{value}</div>
                </div>
            );
        }
        return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
                {value.map((text: any, index: number) => {
                    if (typeof text === 'object' && displayInfo?.pageType === 'operationLogs') {
                        const styles =
                            value.length - 1 > index
                                ? {
                                      padding: '5px 0',
                                      borderBottom: '1px dashed #e8e8e8',
                                  }
                                : {
                                      paddingTop: '5px',
                                  };
                        return (
                            <div key={index} style={styles}>
                                {text.operationType}：{text.updateBefore}
                                {text.updateBefore ? ` --> ` : ''}
                                {text.updateAfter}
                            </div>
                        );
                    }
                    return <div key={index}>{text}</div>;
                })}
            </div>
        );
    }
}
