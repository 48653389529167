import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { find } from 'lodash';

import { AttributeCode, getAttributeValue } from '../containers/organization/common';

const api = services.api;
export const NewUserLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiPath = '/users';
        const { id } = data;
        if (!id) {
            if (data.status === 'ALL') {
                delete data.status;
            }
            return await api.get(data, config).then((res: any) => {
                if (res.result && res.result.length) {
                    res.result.forEach((item: any) => {
                        item.organizationName = item.organizationParentNamePath
                            ? item.organizationParentNamePath + item.organizationName
                            : item.organizationName;
                        const staffCodeObj = find(item.extendAttributes, {
                            attributeCode: 'staff_code',
                        });
                        const staffCode = staffCodeObj ? staffCodeObj.attributeValue : '';
                        item.staffCode = staffCode;
                        item.canEdit = item.status !== 'REVOKED';
                    });
                }

                return res;
            });
        } else {
            config.apiPath = `/users/${id}`;
            return await api.get({}, config).then(async (res: any) => {
                const staffCodeObj = find(res.extendAttributes, {
                    attributeCode: 'staff_code',
                });
                const wechatCardPictureObj = find(res.extendAttributes, {
                    attributeCode: 'wechat_work_qrcode',
                });
                const staffCode = staffCodeObj ? staffCodeObj.attributeValue : '';
                const wechatCardPicturePath = wechatCardPictureObj
                    ? wechatCardPictureObj.attributeValue
                    : '';
                if (res.status !== 'REVOKED') {
                    res.lastModifyTime = '';
                    res.lastModifyUserName = '';
                }
                res.baseInfo = {
                    phone: res.phone,
                    userName: res.userName,
                    password: res.password,
                    realName: res.realName,
                    staffCode: staffCode,
                };
                res.authInfo = {
                    organization: {
                        organizationId: res.organizationId,
                        organizationName: res.organizationParentNamePath
                            ? res.organizationParentNamePath + res.organizationName
                            : res.organizationName,
                    },
                    organizations: transformOrganizations(res.organizations),
                    roles: res.userRoles,
                };
                res.otherInfo = {
                    passwordValidityPeriod: res.passwordValidityPeriod,
                    email: res.email,
                    avatarUrl: res.avatarUrl ? [{ id: 1, path: res.avatarUrl }] : [],
                    introduction: res.introduction,
                    wechatCardPicture: wechatCardPicturePath
                        ? [{ id: 1, path: wechatCardPicturePath }]
                        : [],
                };
                res.avatarUrl = res.avatarUrl ? [{ id: 1, path: res.avatarUrl }] : [];
                try {
                    // 查询登录信息
                    const userLoginInfo: any = await api.get(
                        {},
                        { apiRoot: `${ENV.AUTH_API_ROOT}/CAE-OAUTHSVR`, apiPath: `/login` }
                    );
                    res.lastedLoginTime = userLoginInfo.currentLoginTime;
                } catch (error) {
                    console.log(error);
                }
                return res;
            });
        }
    },

    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/users';
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },

    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        if (params.id) {
            config.apiPath = `/admin/users/${params.id}`;
        }
        const newParams = dataParser(params);
        return await api.put(newParams, config);
    },
};

function transformOrganizations(organizations: any[] = []) {
    return organizations.map((i) => {
        const originalId = getAttributeValue(i, AttributeCode.ORGIN_ID);
        const organizationType: string = getAttributeValue(i, AttributeCode.ORGANIZATION_TYPE);
        const types: Record<string, string> = {
            subsite: 'SUBSITE',
            merchant: 'MERCHANT',
        };
        return {
            ...i,
            originalId,
            type: types[organizationType],
        };
    });
}

function getIdsByType(arr: { type: string; originalId: string }[], type: string) {
    return arr.filter((i) => i.type === type).map((i) => i.originalId);
}

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    const { baseInfo, authInfo, otherInfo } = params;

    let extendAttributes: any = [];
    if (!params.id) {
        extendAttributes = [{ attributeCode: 'user_type', attributeValue: 'STAFF' }];
        newParams.extendAttributes = extendAttributes;
    } else {
        if (params.extendAttributes) {
            params.extendAttributes.forEach((attr: any) => {
                if (
                    attr.attributeCode !== 'staff_code' &&
                    attr.attributeCode !== 'wechat_work_qrcode'
                ) {
                    extendAttributes.push(attr);
                }
            });
        }
    }

    if (baseInfo) {
        newParams.phone = baseInfo.phone;
        newParams.userName = baseInfo.userName;
        newParams.password = baseInfo.password;
        newParams.realName = baseInfo.realName;
        extendAttributes.push({
            attributeCode: 'staff_code',
            attributeValue: baseInfo.staffCode,
        });
    }
    if (authInfo) {
        newParams.organizationId = authInfo.organization.organizationId;
        const { organizations } = authInfo;
        newParams.organizationIds = [];
        if (organizations && organizations.length) {
            newParams.organizationIds = organizations.map((i: any) => i.id);
            newParams.subsiteIds = getIdsByType(organizations, 'SUBSITE');
            newParams.merchantIds = getIdsByType(organizations, 'MERCHANT');
        }
        newParams.roleIds =
            authInfo.roles && authInfo.roles.length ? authInfo.roles.map((i: any) => i.id) : '';
    }
    if (otherInfo) {
        newParams.passwordValidityPeriod = otherInfo.passwordValidityPeriod;
        newParams.email = otherInfo.email;
        newParams.avatarUrl =
            otherInfo.avatarUrl && otherInfo.avatarUrl.length ? otherInfo.avatarUrl[0].path : '';
        newParams.introduction = otherInfo.introduction;
        if (otherInfo.wechatCardPicture && otherInfo.wechatCardPicture.length) {
            extendAttributes.push({
                attributeCode: 'wechat_work_qrcode',
                attributeValue: otherInfo.wechatCardPicture[0].path,
            });
        }
    }
    newParams.extendAttributes = extendAttributes;
    if (!params.id) {
        newParams.status = 'ACTIVE';
    } else {
        newParams.status = params.status;
    }

    return newParams;
}
