import * as React from 'react';
import { ArrayMode } from '@comall-backend-builder/types';
import { RegionSelectSelect } from './region-select';

export class RegionSelectMode extends ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <RegionSelectSelect {...controlInfo} />;
    }
}
