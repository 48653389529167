import React, { Component } from 'react';
import { Input, Select } from 'antd';
import { message as AntMessage } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services';

const { Option } = Select;
/**
 * 抵扣规则值对象
 */
interface MerchantCodeTaxrateRuleValue {
    exchangeRatio: string;
    exchangeAmount: number;
}
interface MerchantCodeTaxrateRuleProps {
    onChange: (value: any) => void;
    value: MerchantCodeTaxrateRuleValue | undefined;
    isNoDefault: boolean | false; //是否显示默认值
    contentReg: boolean | false; //为true的话有值才校验数据格式,原校验格式不动(只加了为false的判断)
    companyText: string | '';
}

export class MerchantCodeTaxrateRule extends Component<MerchantCodeTaxrateRuleProps> {
    changeValue = (value: any) => {
        const currentValue = this.props.value ? this.props.value : {};
        const newValue = Object.assign({ ...currentValue }, { ...value });
        this.props.onChange(newValue);
    };

    onChangeExchangeRatio = (exchangeRatio: any) => {
        if (exchangeRatio !== 'STANDARD') {
            this.changeValue({ exchangeRatio, exchangeAmount: '' });
        } else {
            this.changeValue({ exchangeRatio });
        }
    };

    onChangeExchangeAmount = (e: any) => {
        this.changeValue({ exchangeAmount: e.target.value });
    };
    onValidExchangeAmount = (e: any) => {
        const { contentReg } = this.props;
        const exchangeAmount = e.target.value;
        const reg = /^[1-9][0-9]?$/;

        if (!reg.test(String(exchangeAmount)) && !contentReg) {
            AntMessage.warning(language.getText('taxRateAllowNumber1to99'));
            this.changeValue({ exchangeAmount: '' });
            return;
        }
        this.changeValue({ exchangeAmount });
    };

    render() {
        const { value, isNoDefault } = this.props;
        return (
            <div className="merchant-code-taxrate-rule">
                <div className="rule-item">
                    <Select
                        value={value && value.exchangeRatio ? value.exchangeRatio : 'STANDARD'}
                        defaultValue={'STANDARD'}
                        onChange={(e: any) => this.onChangeExchangeRatio(e)}
                        style={{ width: 120 }}
                    >
                        <Option value={'STANDARD'}>{language.getText('standardTaxRate')}</Option>
                        <Option value={'DUTY_FREE'}>{language.getText('dutyFree')}</Option>
                        <Option value={'NO_TAX'}>{language.getText('nonTaxtion')}</Option>
                        <Option value={'ZERO_RATE'}>{language.getText('zeroTaxRate')}</Option>
                        <Option value={'EXPORT_TAX_REBATE'}>
                            {language.getText('exportTaxRefund')}
                        </Option>
                    </Select>
                    <Input
                        className="item-input"
                        min={0}
                        max={99}
                        disabled={
                            value && value.exchangeRatio
                                ? value.exchangeRatio !== 'STANDARD'
                                : false
                        }
                        value={
                            value && value.exchangeAmount
                                ? value.exchangeAmount
                                : isNoDefault
                                ? undefined
                                : ''
                        }
                        onChange={this.onChangeExchangeAmount}
                        onBlur={this.onValidExchangeAmount}
                    />
                    <span className="item-text" style={{ marginLeft: 10 }}>
                        %
                    </span>
                </div>
            </div>
        );
    }
}
