import { Loader } from '@comall-backend-builder/core/lib/loaders';
import moment from 'moment';
import {
    LiveRoomProduct,
    LiveRoomProductForSubmit,
    validate,
} from '../../config/promotion/market/live-room-product';
import { services } from '@comall-backend-builder/core';
import { message } from 'antd';
import { isEmpty } from 'lodash';
const { api } = services;

export const LiveRoomProductLoader: Loader = {
    get: async function(data, config) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/live_stream';
        data.field = 'createTime';
        data.order = 'DESCEND';
        const result = await api.get<LiveRoomProduct>(data, config);
        return result;
    },
    post: async function(data, config) {
        try {
            validateAll(data.liveGoodsList);
        } catch (e) {
            setTimeout(() => {
                message.destroy();
                if (e instanceof Error) {
                    message.error(e.message);
                } else {
                    message.error(JSON.stringify(e));
                }
            });
            throw e;
        }
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/live_stream';
        await api.post(data, config);
    },
};

const validateAll = (value: LiveRoomProductForSubmit[]) => {
    if (!value || !Array.isArray(value) || value.length === 0) {
        throw new Error('未选择商品');
    } else {
        value.forEach((product, index) => {
            try {
                validate(product);
            } catch (e) {
                if (e instanceof Error) {
                    throw new Error(`第 ${index + 1} 个商品：${e.message}`);
                } else {
                    throw new Error(
                        `第 ${index + 1} 个商品：${(e as any).message || JSON.stringify(e)}`
                    );
                }
            }
        });
    }
};

export const LiveRoomBroadcastLoader: Loader = {
    get: async function(data, config) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-LIVE-BROADCAST`;
        config.apiPath = '/admin/live_broadcast';
        if (data?.id) {
            config.apiPath = `/admin/live_broadcast/${data.id}`;
        }
        if (!isEmpty(data.dateRange)) {
            data.liveStartTime = data.dateRange.start;
            data.liveEndTime = data.dateRange.end;
            delete data.dateRange;
        }
        const result = await api.get(data, config).then((res: any) => {
            if (data?.id) {
                const [startDate, endDate] = formatTimes(res.startTime, res.endTime);
                res.date = `${startDate} ${endDate}`;
                res.goodsInfos = res.goodsInfos?.length
                    ? res.goodsInfos.map((item: any, index: number) => {
                          item.key = index + 1;
                          item.id = index + 'i';
                          return item;
                      })
                    : [];
            }
            if (res?.result) {
                res.result =
                    res &&
                    res.result.map((item: any) => ({
                        ...item,
                        dateStr: formatTimes(item.startTime, item.endTime),
                        id: item.roomId,
                    }));
            }

            return res;
        });
        return result;
    },
};
function formatTimes(startTime: string, endTime: string) {
    // 格式
    // 20xx x月x日
    // x(小时):x(分钟)-20xx x月x日  x(小时):x(分钟)
    const startYear = moment(startTime).year();
    const endYear = moment(endTime).year();
    const startMonth = moment(startTime).month() + 1;
    const endMonth = moment(endTime).month() + 1;
    const startDay = moment(startTime).date();
    const endDay = moment(endTime).date();
    const nowYear = moment().year();
    const broadcastStartYearStr = startYear !== nowYear ? `${startYear} ` : ''; //开始年份不是当前年则开始日期带上年份
    const startDate = `${broadcastStartYearStr}${startMonth}月${startDay}日`;
    let endDate = '';
    if (endYear !== nowYear) {
        endDate = `${moment(startTime).format(
            'HH:mm'
        )} - ${endYear} ${endMonth}月${endDay}日 ${moment(endTime).format('HH:mm')}`;
    } else if (startMonth !== endMonth || startDay !== endDay) {
        //年份一样 开始时间月与结束时间月或日不一样
        endDate = `${moment(startTime).format('HH:mm')} - ${endMonth}月${endDay}日 ${moment(
            endTime
        ).format('HH:mm')}`;
    } else {
        // 开始时间年月日一样格式
        // x月x日
        // x(小时):x(分钟)-x(小时):x(分钟)
        endDate = `${moment(startTime).format('HH:mm')} - ${moment(endTime).format('HH:mm')}`;
    }
    return [startDate, endDate];
}
