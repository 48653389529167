import React, { Component } from 'react';
import { Select, InputNumber } from 'antd';

import './index.less';

enum CreditCycleLimitRuleType {
    /**
     * 每天
     */
    EACH_DAY = 'EACH_DAY',
    /**
     * 每周
     */
    EACH_WEEK = 'EACH_WEEK',
    /**
     * 每月
     */
    EACH_MONTH = 'EACH_MONTH',
    /**
     * 每年
     */
    EACH_YEAR = 'EACH_YEAR',
}

interface CreditCycleLimitValue {
    ruleType: CreditCycleLimitRuleType;
    limitCount?: number;
}

interface DayPeriodConditionProps {
    onChange: (value: CreditCycleLimitValue) => void;
    value: CreditCycleLimitValue;
    disabled: boolean;
}

const options = [
    { id: CreditCycleLimitRuleType.EACH_DAY, name: '每日' },
    { id: CreditCycleLimitRuleType.EACH_WEEK, name: '每周' },
    { id: CreditCycleLimitRuleType.EACH_MONTH, name: '每月' },
    { id: CreditCycleLimitRuleType.EACH_YEAR, name: '每年' },
];

export class CreditCycleLimit extends Component<DayPeriodConditionProps, any> {
    componentDidMount() {
        const { value, onChange } = this.props;
        if (!value) {
            const defaultValue = {
                ruleType: CreditCycleLimitRuleType.EACH_DAY,
            };
            onChange(defaultValue);
        }
    }

    onOptionChange = (ruleType: CreditCycleLimitRuleType) => {
        const { value, onChange } = this.props;
        onChange(Object.assign({}, value, { ruleType }));
    };
    onInput = (limitCount: number | undefined) => {
        const { value, onChange } = this.props;
        onChange(Object.assign({}, value, { limitCount }));
    };
    render() {
        const { value } = this.props;
        return (
            <div className="credit-cycle-limit">
                <span className="ant-input-group-wrapper">
                    <span className="ant-input-wrapper ant-input-group">
                        <span className="ant-input-group-addon">每个用户</span>
                        <Select value={value && value.ruleType} onSelect={this.onOptionChange}>
                            {options.map((o) => (
                                <Select.Option key={o.id} value={o.id}>
                                    {o.name}
                                </Select.Option>
                            ))}
                        </Select>
                        <span className="ant-input-group-addon middle">限购</span>
                        <InputNumber
                            min={1}
                            precision={0}
                            value={value && value.limitCount}
                            onChange={this.onInput}
                        />

                        <span className="ant-input-group-addon">件</span>
                    </span>
                </span>
            </div>
        );
    }
}
