import React, { Component } from 'react';
import { Button, InputNumber } from 'antd';
import classNames from 'classnames';
import { DeliveryFreightRuleRegionsSelector } from './delivery-freight-rule-regions-selector';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

export interface FreightRange {
    /**
     * 订单金额下限
     */
    startAmount: number;
    /**
     * 订单金额上限
     */
    endAmount: number;
    /**
     * 首费
     */
    firstAmount: number;
    /**
     * 区间运费
     */
    freightLadders: Array<any>;
}

export interface FreightLadder {
    /**
     * 阶梯运费(元)
     */
    ladderAmount: number;
    /**
     * 阶梯递增值，重量/距离/时间段/件数
     */
    ladderValue: number;
    /**
     * 阶梯起始值
     */
    startValue: number;
    /**
     * 阶梯类型，WEIGHT=重量，DISTANCE=距离，TIME=配送时间，QUANTITY=件数
     */
    type: string;
}

export interface FreightRule {
    /**
     * 选择的区域（改变）
     */
    deliveryScopes: Array<any>;
    /**
     * 编辑页面返回的选中详情回显（用于半选，选中状态的控制，初次加载使用）
     */
    deliveryScopesInfo: Array<any>;
    /**
     * 选择的区域渲染
     */
    showDeliveryScopes: Array<any>;
    /**
     * 配置的运费信息集合
     */
    freightRanges: Array<any>;
    /**
     * 以下两个字段是选用门店模板时后端返回的规则id，运费组id，需继续传给后端（前端交互无用）
     * deliveryRuleId
     * group
     */
    deliveryRuleId?: string;
    group?: string;
}

export interface DeliveryFreightRulesSetUpProps {
    row: any;
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 页面类型
     */
    pageType: string;
    /**
     * 是否使用重量计算
     */
    isWeighTharging: boolean;
    /**
     * value值
     */
    value: FreightRule;
    /**
     * onchange
     */
    onChange: (value: FreightRule, name: string) => void;
}

export class DeliveryFreightRulesSetUp extends Component<DeliveryFreightRulesSetUpProps, {}> {
    static defaultProps = {
        value: {
            freightRule: {
                deliveryScopes: [],
                deliveryScopesInfo: [],
                showDeliveryScopes: [],
                freightRanges: [
                    {
                        startAmount: null,
                        endAmount: null,
                        firstAmount: null,
                        freightLadders: [
                            {
                                startValue: null,
                                ladderValue: null,
                                ladderAmount: null,
                                type: 'WEIGHT',
                            },
                        ],
                    },
                ],
            },
        },
    };

    componentDidMount() {
        let { name, value, onChange } = this.props;
        if (!value) {
            const defalutValue = {
                deliveryScopes: [],
                deliveryScopesInfo: [],
                showDeliveryScopes: [],
                freightRanges: [
                    {
                        startAmount: null,
                        endAmount: null,
                        firstAmount: null,
                        freightLadders: [
                            {
                                startValue: null,
                                ladderValue: null,
                                ladderAmount: null,
                                type: 'WEIGHT',
                            },
                        ],
                    },
                ],
            };
            if (onChange) {
                onChange(defalutValue, name);
            }
        }
    }

    renderFreightRule = () => {
        const { row, isWeighTharging, value, pageType } = this.props;
        let rowspan = 0;
        if (value && value.freightRanges) {
            rowspan = value.freightRanges.length;
        }
        return (
            <div className="delivery-freight-rule-set-up">
                <table
                    className={classNames({
                        'delivery-freight-rule-table': true,
                        'no-weight-billing': !isWeighTharging,
                    })}
                    cellSpacing={0}
                    cellPadding={5}
                >
                    <tbody>
                        <tr className="table-tr">
                            <th className="table-td-width-area">{language.getText('kpsqy')}</th>
                            <th className="table-td-width-order">
                                {language.getText('orderAmountYuan')}
                            </th>
                            {isWeighTharging && (
                                <th className="table-td-width">{language.getText('szKg')}</th>
                            )}
                            <th className="table-td-width">{language.getText('yfYuan')}</th>
                            {isWeighTharging && (
                                <th className="table-td-width">{language.getText('xzKg')}</th>
                            )}
                            {isWeighTharging && (
                                <th className="table-td-width">{language.getText('xfYuan')}</th>
                            )}
                            <th className="table-td-width-delete"></th>
                        </tr>
                        <tr style={{ textAlign: 'left' }}>
                            <td rowSpan={rowspan} className="table-td-width-area">
                                <div className="table-td-width-area-button">
                                    <DeliveryFreightRuleRegionsSelector
                                        pageType={pageType}
                                        row={row}
                                        freightRule={value}
                                        showDeliveryScopes={value.showDeliveryScopes}
                                        changeValues={value.deliveryScopes}
                                        onChangeshowDeliveryScopes={this.onChangeshowDeliveryScopes}
                                        onChangeDeliveryScopes={this.onChangeDeliveryScopes}
                                    />
                                </div>
                            </td>
                            <td colSpan={6}>
                                {this.renderFreightRanges()}
                                <div>
                                    <Button
                                        className="delivery-freight-rule-button"
                                        onClick={this.addFreightRange}
                                    >
                                        {language.getText('zjjtyf')}
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    };

    renderFreightRanges = () => {
        const { value, isWeighTharging } = this.props;
        return (
            <div>
                {value.freightRanges &&
                    value.freightRanges.map((freightRange: FreightRange, index) => {
                        return (
                            <div className="table-flex" key={index}>
                                <div className="table-td-width-order table-flex">
                                    <InputNumber
                                        type="number"
                                        min={0}
                                        step={0.01}
                                        precision={2}
                                        value={freightRange.startAmount}
                                        style={{ marginRight: '10px' }}
                                        placeholder=""
                                        onChange={(changeValue: any) =>
                                            this.changeValue('startAmount', changeValue, index)
                                        }
                                    />
                                    {language.getText('to')}
                                    <InputNumber
                                        type="number"
                                        min={0}
                                        step={0.01}
                                        precision={2}
                                        value={freightRange.endAmount}
                                        style={{ marginLeft: '10px' }}
                                        placeholder=""
                                        onChange={(changeValue: any) =>
                                            this.changeValue('endAmount', changeValue, index)
                                        }
                                    />
                                </div>
                                {isWeighTharging && (
                                    <div className="table-td-width table-flex">
                                        <InputNumber
                                            type="number"
                                            min={0}
                                            step={0.001}
                                            precision={3}
                                            value={freightRange.freightLadders[0].startValue}
                                            placeholder=""
                                            onChange={(changeValue: any) =>
                                                this.changeValue('startValue', changeValue, index)
                                            }
                                        />
                                    </div>
                                )}
                                <div className="table-td-width table-flex">
                                    <InputNumber
                                        type="number"
                                        min={0}
                                        step={0.01}
                                        precision={2}
                                        value={freightRange.firstAmount}
                                        placeholder=""
                                        onChange={(changeValue: any) =>
                                            this.changeValue('firstAmount', changeValue, index)
                                        }
                                    />
                                </div>
                                {isWeighTharging && (
                                    <div className="table-td-width table-flex">
                                        <InputNumber
                                            type="number"
                                            min={0}
                                            step={0.001}
                                            precision={3}
                                            value={freightRange.freightLadders[0].ladderValue}
                                            placeholder=""
                                            onChange={(changeValue: any) =>
                                                this.changeValue('ladderValue', changeValue, index)
                                            }
                                        />
                                    </div>
                                )}
                                {isWeighTharging && (
                                    <div className="table-td-width table-flex">
                                        <InputNumber
                                            type="number"
                                            min={0}
                                            step={0.01}
                                            precision={2}
                                            value={freightRange.freightLadders[0].ladderAmount}
                                            placeholder=""
                                            onChange={(changeValue: any) =>
                                                this.changeValue('ladderAmount', changeValue, index)
                                            }
                                        />
                                    </div>
                                )}
                                <div className="table-td-width-delete table-flex">
                                    <span
                                        style={{
                                            marginLeft: '5px',
                                            color: '#1890ff',
                                            cursor: 'pointer',
                                        }}
                                        onClick={this.deleteFreightRange.bind(this, index)}
                                    >
                                        {language.getText('common.delete')}
                                    </span>
                                </div>
                            </div>
                        );
                    })}
            </div>
        );
    };

    changeValue = (field: string, changeValue: any, valueIndex: number) => {
        const { value, name, onChange } = this.props;
        value.freightRanges = value.freightRanges.map((range, i) => {
            if (i === valueIndex) {
                if (field === 'startAmount' || field === 'endAmount' || field === 'firstAmount') {
                    range[field] = changeValue;
                } else {
                    range.freightLadders[0][field] = changeValue;
                }
            }
            return range;
        });
        onChange(value, name);
    };

    addFreightRange = () => {
        const { value, name, onChange } = this.props;
        value.freightRanges.push({
            startAmount: null,
            endAmount: null,
            firstAmount: null,
            freightLadders: [
                {
                    startValue: null,
                    ladderValue: null,
                    ladderAmount: null,
                    type: 'WEIGHT',
                },
            ],
        });
        onChange(value, name);
    };

    deleteFreightRange = (freightRangeIndex: number) => {
        const { value, name, onChange } = this.props;
        const freightRanges = value.freightRanges;
        freightRanges.splice(freightRangeIndex, 1);
        if (freightRanges.length === 0) {
            freightRanges.push({
                startAmount: null,
                endAmount: null,
                firstAmount: null,
                freightLadders: [
                    {
                        startValue: null,
                        ladderValue: null,
                        ladderAmount: null,
                        type: 'WEIGHT',
                    },
                ],
            });
        }
        value.freightRanges = freightRanges;
        onChange(value, name);
    };

    onChangeshowDeliveryScopes = (regions: Array<any>) => {
        const { value, name, onChange } = this.props;
        if (value) {
            value.showDeliveryScopes = regions;
        }
        onChange(value, name);
    };

    onChangeDeliveryScopes = (regions: Array<any>) => {
        const { value, name, onChange } = this.props;
        if (value) {
            value.deliveryScopes = regions;
        }
        onChange(value, name);
    };

    render() {
        return this.renderFreightRule();
    }
}
