import { ApiRequestConfig, api } from '@comall-backend-builder/core/lib/services/api';

export const PrivilegesLoader = {
    get: async function(params: any, config: ApiRequestConfig) {
        return await new Promise((resolve, reject) => {
            config.apiPath = '/admin/user/menus';
            config.apiRoot = `${ENV.AUTH_API_ROOT}/CAE-AUTHORIZATION`;

            api.get(params, config).then((res: any) => {
                let permission = res.reduce((result: any, item: any) => {
                    return result.concat({ name: item.code, level: 'full' });
                }, []);
                if (permission.length === 0) {
                    permission.push({
                        guest: 'full',
                    });
                }
                resolve(permission);
            });
        });
    },
};
