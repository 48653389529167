import React, { Component } from 'react';
import { Textbox } from '@comall-backend-builder/components-basis';
import { TipsModal } from '../../../components';
import { modes } from '@comall-backend-builder/types';

export class StringInputWithTipsModalMode extends modes.StringMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <StringInputWithTipsModal {...displayInfo} />;
    }
}

class StringInputWithTipsModal extends Component<any, any> {
    render() {
        const { tipsModalConfig } = this.props;
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <Textbox {...(this.props as any)} type="text" />
                {tipsModalConfig && (
                    <div style={{ marginLeft: 5 }}>
                        <TipsModal {...tipsModalConfig} />
                    </div>
                )}
            </div>
        );
    }
}
