import React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import WechatOfficialAutoReplyView from './wechat-official-auto-reply-view';

export class WechatOfficialAutoReplyViewMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <WechatOfficialAutoReplyView {...displayInfo} />;
    }
}
