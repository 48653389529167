import React from 'react';
import classNames from 'classnames';
import { modes } from '@comall-backend-builder/types';
import { NumberInputTip } from '../../../components/number-input-tip';

export class NumberInputTipGroupBuying extends modes.NumberIntegerMode {
    getDisplayComponent(value: any, displayInfo: any): JSX.Element {
        const { name } = displayInfo;
        const fill = JSON.parse(name).fill;
        if (value) {
            let display = fill.replace('#value#', value);
            return (
                <span>
                    <span>{display}</span>
                </span>
            );
        } else {
            return this.getNotAvailableDisplayComponent(displayInfo);
        }
    }

    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        const { name } = displayInfo;
        const empty = JSON.parse(name).empty;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            empty
        );
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <NumberInputTip {...displayInfo} />;
    }
}
