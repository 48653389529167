import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { errorHandle, language } from '@comall-backend-builder/core/lib/services';
import {
    Button,
    Form,
    Icon,
    Input,
    Select,
    Table,
    Tooltip,
    Modal,
    Spin,
    Upload,
    message,
} from 'antd';
import PropTypes from 'prop-types';
import { ForwardDataCenterModal } from '../../services';

const { confirm } = Modal;
const { api } = services;
const { Dragger } = Upload;

const LabelCreateForm = Form.create({ name: 'searchForm' })(
    class extends React.Component {
        static propTypes: {
            onSearch: PropTypes.Requireable<(...args: any[]) => any>;
            subsites: [];
        };
        handleReset = () => {
            const { form } = this.props as any;
            form.resetFields();
        };
        handleSearch = (e: any) => {
            e.preventDefault();
            const { form, onSearch } = this.props as any;
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSearch(values);
                }
            });
        };
        render() {
            const { form, subsites } = this.props as any;
            const { getFieldDecorator } = form;
            return (
                <Form layout="inline" ref="searchFrom">
                    <Form.Item label={language.getText('tagName')}>
                        {getFieldDecorator('tagName')(
                            <Input
                                className="find-filter-width"
                                placeholder={services.language.getText('inputPlease')}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('subsiteName')}>
                        {getFieldDecorator('subsiteId')(
                            <Select
                                className="find-filter-width"
                                placeholder={services.language.getText('selectPlease')}
                            >
                                <Select.Option value="">{language.getText('all')}</Select.Option>
                                {subsites &&
                                    subsites.map((subsite: any) => {
                                        return (
                                            <Select.Option key={subsite.id} value={subsite.id}>
                                                {subsite.name}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.handleSearch}>
                            {language.getText('common.search')}
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="reset" onClick={this.handleReset}>
                            {language.getText('common.reset')}
                        </Button>
                    </Form.Item>
                </Form>
            );
        }
    }
);

export class LabelManagePage extends PureComponent {
    state = {
        datasource: [],
        searchParams: {},
        total: 0,
        current: 0,
        pagination: {
            page: 1,
            perPage: 10,
        },
        subsites: [],
        loading: false,
        fileList: [],
        showModal: false,
        statusMsg: services.language.getText('uploadFileClickButton'),
        activeId: undefined,
    };

    componentDidMount() {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: '/WEB-API/admin/defaults/subsite',
            }
        ).then((subsites) => {
            this.setState({
                subsites,
            });
        });
        this.onSearch();
    }

    onSearchFind = (searchParams: object = {}) => {
        this.setState(
            {
                pagination: {
                    page: 1,
                    perPage: 10,
                },
            },
            () => {
                this.onSearch(searchParams);
            }
        );
    };

    onSearch = async (searchParams: object = {}) => {
        this.setState({ searchParams: searchParams, loading: true });
        const { pagination } = this.state;
        try {
            let res: any = await services.api.get(
                {
                    ...searchParams,
                    ...pagination,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                    apiPath: '/admin/tags',
                }
            );
            if (res && res.result) {
                this.setState({
                    datasource: res.result,
                    total: res.totalNum,
                    current: res.page,
                    loading: false,
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            throw error;
        }
    };
    pageChange = (pageVal: object = {}) => {
        const { searchParams } = this.state;
        this.setState({ pagination: { ...pageVal } }, () => {
            this.onSearch(searchParams);
        });
    };

    onDelete = (id: any) => {
        const { searchParams } = this.state;
        const _this = this;
        confirm({
            title: '',
            content: language.getText('schjbkhf'),
            onOk() {
                services.api
                    .put(
                        { id },
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                            apiPath: `/admin/tags/${id}//delete`,
                        }
                    )
                    .then(() => {
                        _this.onSearch(searchParams);
                    })
                    .catch(errorHandle);
            },
            onCancel() {},
        });
    };

    toggleModal = () => {
        const { showModal } = this.state;
        this.setState({ showModal: !showModal });
    };

    openModal = (id: string) => {
        this.setState({ activeId: id });
        this.toggleModal();
    };
    cancelModal = () => {
        this.setState({ fileList: [], activeId: undefined });
        this.toggleModal();
    };

    onImport = () => {
        const { fileList, activeId, searchParams } = this.state;
        const apiRoot = `${ENV.AUTH_API_ROOT}/FILE`;
        const apiPath = '/admin/files/locations/8030/upload';
        const formData = new FormData();
        formData.append('files', fileList[0]);
        formData.append('meta_data', JSON.stringify({ tagId: activeId }));

        services.api
            .upload(
                { files: formData },
                {
                    apiRoot,
                    apiPath,
                    fileName: 'files',
                    progressCallBack: () => {},
                    useCustomData: true,
                }
            )
            .then(() => {
                ForwardDataCenterModal();
                this.toggleModal();
                this.onSearch(searchParams);
            })
            .catch((err) => {
                if (err) {
                    message.error(
                        err.response?.body?.err_msg || services.language.getText('uoloadFail')
                    );
                }
            });
    };

    handleOk = () => {
        const { fileList } = this.state;
        if (fileList.length) {
            this.onImport();
        } else {
            message.error(services.language.getText('pleaseUploadFile'));
            return;
        }
    };

    downloadTemplate = () => {
        services.api.download(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`,
                apiPath: '/admin/tags/download_template',
            }
        );
    };

    render() {
        const {
            datasource,
            pagination,
            total,
            current,
            subsites,
            loading,
            showModal,
            statusMsg,
            fileList,
        } = this.state;
        const uploadProps = {
            accept: '.xlsx,.xls,.csv',
            multiple: false,
            onRemove: (file: any) => {
                this.setState({ fileList: [] });
            },
            beforeUpload: (file: any) => {
                this.setState({
                    fileList: [file],
                });

                return false;
            },
            fileList,
        };
        return (
            <div className="combine-goods-find">
                <div>
                    <LabelCreateForm onSearch={this.onSearchFind} subsites={subsites} />
                </div>
                <div className="combine-goods-add">
                    <Button type="primary" href="#/label-manage/add">
                        {language.getText('addTags')}
                    </Button>
                </div>
                <Spin spinning={loading}>
                    <Table
                        scroll={{ x: true }}
                        rowKey={(record: any) => record.id}
                        pagination={{
                            pageSize: pagination.perPage,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            pageSizeOptions: ['10', '15', '20'],
                            total,
                            current,
                            showTotal(total: number) {
                                return services.interpolate(services.language.getText('total'), {
                                    total,
                                });
                            },
                            onChange: (page, perPage) => {
                                this.pageChange({ page, perPage });
                            },
                            onShowSizeChange: (page, perPage) => {
                                this.pageChange({ page, perPage });
                            },
                        }}
                        columns={[
                            {
                                title: language.getText('tagName'),
                                dataIndex: 'tagName',
                                key: 'tagName',
                            },
                            {
                                title: language.getText('bqlx'),
                                dataIndex: 'typeName',
                                key: 'typeName',
                            },
                            {
                                title: language.getText('rs_1'),
                                dataIndex: 'useCount',
                                key: 'useCount',
                                render: (val: any, record) => {
                                    const userManageHref = `#/member-manage?tagSubsiteId=${record.subsiteId}&tagId=${record.id}`;
                                    return (
                                        <a
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            href={userManageHref}
                                            className="table-action-column-item"
                                        >
                                            {val}
                                        </a>
                                    );
                                },
                            },

                            {
                                title: language.getText('subsites'),
                                key: 'subsiteName',
                                dataIndex: 'subsiteName',
                            },
                            {
                                title: language.getText('createTimeStr'),
                                key: 'createTime',
                                dataIndex: 'createTime',
                            },
                            {
                                title: language.getText('bjsj'),
                                key: 'lastModifiedTime',
                                dataIndex: 'lastModifiedTime',
                            },
                            {
                                title: () => <span>{language.getText('zjjskssj')}</span>,
                                key: 'calculateStartTime',
                                dataIndex: 'calculateStartTime',
                            },
                            {
                                title: () => (
                                    <span>
                                        {language.getText('zjjswcsj')}
                                        <Tooltip title={language.getText('zjycqbyhjssj')}>
                                            <Icon
                                                style={{ marginLeft: 8 }}
                                                type="question-circle"
                                            />
                                        </Tooltip>
                                    </span>
                                ),
                                key: 'calculateEndTime',
                                dataIndex: 'calculateEndTime',
                            },
                            {
                                title: () => (
                                    <span>
                                        {language.getText('zjdbsj')}
                                        <Tooltip title={language.getText('zjycxzbqrssj')}>
                                            <Icon
                                                style={{ marginLeft: 8 }}
                                                type="question-circle"
                                            />
                                        </Tooltip>
                                    </span>
                                ),
                                key: 'useTime',
                                dataIndex: 'useTime',
                            },
                            {
                                title: services.language.getText('common.tableAction'),
                                className: 'action-column',
                                dataIndex: 'id',
                                key: 'id',
                                fixed: 'right',
                                width: 200,
                                render: (val: any) => {
                                    const editHref = '#/label-manage/edit/' + val;
                                    return (
                                        <div>
                                            <a href={editHref} className="table-action-column-item">
                                                {language.getText('common.edit')}
                                            </a>
                                            <Button
                                                type="link"
                                                style={{ padding: 0, marginRight: 10 }}
                                                onClick={() => this.openModal(val)}
                                            >
                                                {language.getText('drsj')}
                                            </Button>
                                            <Button
                                                type="link"
                                                style={{ padding: 0, marginRight: 0 }}
                                                onClick={() => this.onDelete(val)}
                                            >
                                                {services.language.getText('common.delete')}
                                            </Button>
                                        </div>
                                    );
                                },
                            },
                        ]}
                        dataSource={datasource}
                    />
                </Spin>
                <Modal
                    onCancel={this.cancelModal}
                    visible={showModal}
                    title={services.language.getText('batchImport')}
                    footer={[
                        <span key="span" className="giving-coupon-schedules-status-msg">
                            {statusMsg}
                        </span>,
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            {language.getText('import')}
                        </Button>,
                        <Button key="back" onClick={this.cancelModal}>
                            {services.language.getText('back')}
                        </Button>,
                    ]}
                >
                    <Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">{services.language.getText('selectFile')}</p>
                        <p className="ant-upload-hint">
                            {services.language.getText('supportExtension')}.xlsx、.xls、.csv
                        </p>
                    </Dragger>
                    <Button
                        onClick={this.downloadTemplate}
                        className="import-button-template tag-modal-import-button-template"
                        type="link"
                    >
                        {services.language.getText('clickDownloadTemplate')}
                    </Button>
                    <div className="giving-coupon-schedules-tips">
                        <p className="giving-coupon-schedules-tips-text">
                            {services.language.getText('importRules')}
                        </p>
                        <p className="giving-coupon-schedules-tips-text">
                            {language.getText('couponIntro1')}
                        </p>
                        <p className="giving-coupon-schedules-tips-text-red">
                            {language.getText('couponIntro2')}
                        </p>
                        <p className="giving-coupon-schedules-tips-text">
                            {language.getText('uploadIntro2')}
                        </p>
                        <p className="giving-coupon-schedules-tips-text">
                            {language.getText('uploadIntro3')}
                        </p>
                    </div>
                </Modal>
            </div>
        );
    }
}
