import React, { PureComponent } from 'react';
import { Form, Radio, Modal, message, Popconfirm, Button } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import {
    MallActivityTicketPrice,
    NewMallActivityMemberCardLevel,
    TicketFieldShowModal,
} from '../types';
import { defaultMallActivityTicketPrice, validateTicketPrices } from '../common';

import { NewMallActivityTicketPricesTable } from './new-mall-activity-ticket-prices-table';

import '../index.less';
import { cloneDeep } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

interface NewMallActivityTicketPricesModalProps {
    onSubmit: (value: MallActivityTicketPrice[]) => void;
    cardLevels: NewMallActivityMemberCardLevel[];
}

interface NewMallActivityTicketPricesModalStates {
    showModal: boolean;
    ticketPrices: MallActivityTicketPrice[];
}

/**
 * 新商场活动票档价格编辑弹窗
 */
class NewMallActivityTicketPricesModal extends PureComponent<
    NewMallActivityTicketPricesModalProps,
    NewMallActivityTicketPricesModalStates
> {
    constructor(props: NewMallActivityTicketPricesModalProps) {
        super(props);
        this.state = {
            ticketPrices: [],
            showModal: false,
        };
    }
    public showModal: TicketFieldShowModal<MallActivityTicketPrice[]> = (value) => {
        this.setState({ ticketPrices: value, showModal: true });
    };

    private closeModal = () => {
        this.setState({ ticketPrices: [], showModal: false });
    };

    private onTicketPricesChange = (ticketPrices: MallActivityTicketPrice[]) => {
        this.setState({ ticketPrices });
    };

    private onPriceTypeChange = (e: RadioChangeEvent) => {
        const value = e.target.value;
        const { cardLevels } = this.props;
        if (value) {
            if (!cardLevels?.length) {
                message.warn(language.getText('qxxzzj'));
                return;
            }
            this.onTicketPricesChange(
                cardLevels.map(({ id }) => ({
                    ...defaultMallActivityTicketPrice,
                    memberCardLevelId: Number(id),
                }))
            );
        } else {
            this.onTicketPricesChange([{ ...defaultMallActivityTicketPrice }]);
        }
    };

    private onSubmit = () => {
        const { ticketPrices } = this.state;
        if (validateTicketPrices(ticketPrices)) {
            message.warn(language.getText('qwztxjgxx'));
            return;
        }
        const { onSubmit } = this.props;
        onSubmit(ticketPrices);
        this.closeModal();
    };

    private onClear = () => {
        const { ticketPrices } = this.state;
        const newValue = cloneDeep(ticketPrices).map(({ memberCardLevelId }) => ({
            ...defaultMallActivityTicketPrice,
            memberCardLevelId,
        }));
        this.onTicketPricesChange(newValue);
    };

    renderModalFooter = () => {
        return (
            <div className="new-mall-activity__flex">
                <div style={{ marginRight: 'auto' }}>
                    <Popconfirm
                        title={language.getText('qdqksyjghmymfmem')}
                        onConfirm={this.onClear}
                        okText={language.getText('common.ok')}
                        cancelText={language.getText('common.cancel')}
                    >
                        <Button type="link">{language.getText('common.clear')}</Button>
                    </Popconfirm>
                </div>
                <Button onClick={this.closeModal} type="default">
                    {language.getText('common.cancel')}
                </Button>
                <Button onClick={this.onSubmit} type="primary">
                    {language.getText('common.ok')}
                </Button>
            </div>
        );
    };

    render() {
        const { ticketPrices, showModal } = this.state;
        const { cardLevels } = this.props;
        const isCardLevelPrice = ticketPrices.some((item) => Number(item.memberCardLevelId) !== -1);
        return (
            <Modal
                width={600}
                footer={this.renderModalFooter()}
                onCancel={this.closeModal}
                visible={showModal}
                title={language.getText('szsj')}
            >
                <Form.Item labelCol={{ span: 3 }} label={language.getText('jglx')}>
                    <Radio.Group value={isCardLevelPrice} onChange={this.onPriceTypeChange}>
                        <Radio value={false}>{language.getText('jqbdjtygjg')}</Radio>
                        <Radio value={true}>{language.getText('jbtdjbtjg')}</Radio>
                    </Radio.Group>
                </Form.Item>
                <NewMallActivityTicketPricesTable
                    value={ticketPrices}
                    onChange={this.onTicketPricesChange}
                    cardLevels={cardLevels}
                />
            </Modal>
        );
    }
}

export { NewMallActivityTicketPricesModal };
