import { message as AntMessage } from 'antd';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';

enum ContentPlateStatus {
    /**
     * 启用
     */
    ENABLE = 'ENABLE',
    /**
     * 禁用
     */
    DISABLE = 'DISABLE',
}
export enum ContentPlateTypes {
    /**
     * 普通板块
     */
    GENERAL = 'GENERAL',
    /**
     * 宣传板块
     */
    PUBLICITY = 'PUBLICITY',
}

export const CONTENT_PLATE_TYPES_OPTIONS = [
    { id: ContentPlateTypes.GENERAL, name: '<<ptbk>>' },
    { id: ContentPlateTypes.PUBLICITY, name: '<<xcbk>>' },
];

const CONTENT_PLATE_STATUS_OPTIONS = [
    {
        id: ContentPlateStatus.ENABLE,
        name: '<<enable>>',
    },
    {
        id: ContentPlateStatus.DISABLE,
        name: '<<disabled>>',
    },
];

const CONTENT_PLATE_STATUS_CONFIG = {
    [ContentPlateStatus.ENABLE]: {
        text: '<<enable>>',
        status: 'success',
    },
    [ContentPlateStatus.DISABLE]: {
        text: '<<disabled>>',
        status: 'error',
    },
};

export const config: Config = {
    entities: {
        contentPlateEntity: {
            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
            apiPath: '/loader/admin/plates',
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<bkmc>>',
                    controlConfig: {
                        style: {
                            width: '200px',
                        },
                        maxLength: 10,
                    },
                    defaultValue: '',
                    rules: [
                        {
                            required: true,
                            whitespace: true,
                        },
                    ],
                },
                id: {
                    type: 'string',
                    displayName: '<<plateId>>',
                },
                type: {
                    type: 'string.options.select',
                    defaultValue: ContentPlateTypes.GENERAL,
                    displayName: '<<bklx>>',
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    controlConfig: {
                        style: {
                            width: 200,
                        },
                    },
                    options: CONTENT_PLATE_TYPES_OPTIONS,
                },
                cityIds: {
                    type: 'array.optionIds.checkbox',
                    displayName: '<<cs_2>>',
                    defaultValue: [],
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    options: [],
                    source: {
                        apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
                        apiPath: '/loader/admin/cities/available',
                    },
                },
                resource: {
                    type: 'imageObjectArray',
                    displayName: '<<bktp>>',
                    className: 'sigle-image-field',
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        fileName: 'files',
                        uploadType: 'single',
                        uploadUrl: `${ENV.AUTH_API_ROOT}/FILE/admin/files/locations/302/upload`,
                        mode: 'picture-card',
                        maxSize: 2 * 1024,
                        preview: {
                            size: 500,
                        },
                        uploadLimitSizeErrorMessage:
                            '<<pictures>>{{file.name}}<<pictureUploadLimit>>',
                    },
                    defaultValue: [],
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    extra: '<<resourceExtra>>',
                },
                sequence: {
                    type: 'number',
                    displayName: '<<sequence>>',
                    controlConfig: {
                        min: 1,
                    },
                    defaultValue: '1',
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<enabledStatus>>',
                    controlConfig: {
                        style: {
                            width: '200px',
                        },
                    },
                    defaultValue: ContentPlateStatus.ENABLE,
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    options: CONTENT_PLATE_STATUS_OPTIONS,
                },
            },
        },
    },
    components: {
        contentPlateView: {
            component: 'Viewport',
            entity: 'contentPlateEntity',
        },
        contentPlatePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzbk>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/content-plate/add',
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'contentPlateTable',
                },
            ],
        },
        contentPlateTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            display: 'block',
                            minWidth: 150,
                            height: 80,
                            lineHeight: '80px',
                        },
                    },
                },
                { property: 'type' },
                {
                    property: 'id',
                },
                {
                    property: 'cityIds',
                    displayConfig: {
                        className: 'custom-array-options-text',
                    },
                },
                {
                    property: 'resource',
                    displayConfig: {
                        style: {
                            width: 80,
                            height: 80,
                        },
                    },
                },
                {
                    property: 'sequence',
                },
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: CONTENT_PLATE_STATUS_CONFIG,
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 140,
                items: [
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/content-plate/edit/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'status',
                            renderComponent: 'Button',
                            type: 'link',
                            style: {
                                display: 'inline-flex',
                            },
                            handles: [
                                {
                                    apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
                                    apiPath: '/admin/plates/:id/status',
                                    params: {
                                        status: ContentPlateStatus.DISABLE,
                                    },
                                    value: ContentPlateStatus.ENABLE,
                                    config: {
                                        content: '<<disabled>>',
                                        text: '<<disabled>>',
                                        style: {
                                            color: '#f5222d',
                                        },
                                    },
                                    confirm: {
                                        text: '<<isDisabled>>{{row.name}}?',
                                        content: '<<jyhqtymzsjsdyx>>',
                                        className: 'confirm-content',
                                    },
                                },
                                {
                                    apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
                                    apiPath: '/admin/plates/:id/status',
                                    params: {
                                        status: ContentPlateStatus.ENABLE,
                                    },
                                    value: ContentPlateStatus.DISABLE,
                                    config: {
                                        content: '<<enable>>',
                                        text: '<<enable>>',
                                    },
                                    confirm: {
                                        text: '<<isEnable>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        contentPlateAddPage: {
            component: 'Card',
            content: {
                component: 'contentPlateAddForm',
            },
        },
        contentPlateAddForm: {
            component: 'CreateFormPlus',
            entity: 'contentPlateEntity',
            direction: 'vertical',
            style: {
                width: 500,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'resource',
                },
                { property: 'type' },
                {
                    property: 'cityIds',
                },
                {
                    property: 'sequence',
                },
                {
                    property: 'status',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: {
                            marginLeft: 10,
                        },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({
                        route: 'goBack',
                    })
                );
            },
        },
        contentPlateEditPage: {
            component: 'Card',
            content: {
                component: 'contentPlateEditForm',
            },
        },
        contentPlateEditForm: {
            component: 'ModifyFormPlus',
            entity: 'contentPlateEntity',
            loaderType: 'get',
            direction: 'vertical',
            style: {
                width: 500,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'resource',
                },
                { property: 'type' },
                {
                    property: 'cityIds',
                },
                {
                    property: 'sequence',
                },
                {
                    property: 'status',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: {
                            marginLeft: 10,
                        },
                    },
                ],
            },

            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({
                        route: 'goBack',
                    })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/content-plate',
                    breadcrumbName: '<<contentPlate>>',
                    component: 'contentPlateView',
                    privilege: {
                        path: 'showPlate',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'contentPlateAddPage',
                            breadcrumbName: '<<xzbk>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'contentPlateEditPage',
                            breadcrumbName: '<<bjbk>>',
                        },
                        {
                            path: '/',
                            component: 'contentPlatePage',
                        },
                    ],
                },
            ],
        },
    ],
};
