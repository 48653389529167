import { get } from 'lodash';
import { connect } from 'react-redux';
import React, { Component } from 'react';
import { Table, Popover } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { Button } from '@comall-backend-builder/components-basis';
import './index.less';
import { services } from '@comall-backend-builder/core';
interface LiveRoomDataDetailProps {
    fields: any;
}
export class LiveRoomDataDetailView extends Component<LiveRoomDataDetailProps> {
    render() {
        const { fields } = this.props;
        const goodsInfos = get(fields, 'goodsInfos', []);
        const productData: any = [
            {
                title: services.language.getText('productsData'),
                data: [
                    {
                        title: services.language.getText('productsData'),
                        value: get(fields, 'goodsTotal', ''),
                    },
                ],
            },
            {
                title: services.language.getText('zbjdds'),
                data: [
                    {
                        title: services.language.getText('xssj'),
                        value: get(fields, 'orderTotal', ''),
                    },
                    {
                        title: services.language.getText('spxsjs'),
                        value: get(fields, 'goodsMarketTotal', ''),
                    },
                    {
                        title: services.language.getText('spxse'),
                        value: get(fields, 'marketAmountTotal', ''),
                    },
                ],
            },
        ];
        const columns: ColumnProps<unknown>[] = [
            {
                title: services.language.getText('xh_1'),
                dataIndex: 'key',
                key: 'key',
            },
            {
                title: services.language.getText('productInformation'),
                dataIndex: 'info',
                key: 'info',
                render: (text: string, record: any) => {
                    const { priceType, price, price2, coverImg, name } = record;
                    // 价格类型，1：一口价（只需要传入price，price2不传）
                    // * 2：价格区间（price字段为左边界，price2字段为右边界，price和price2必传）
                    // * 3：显示折扣价（price字段为原价，price2字段为现价， price和price2必传）
                    let salesPrice =
                        priceType === 3 ? price2 : priceType === 2 ? `${price} - ${price2}` : price;
                    let marketPrice = price;
                    return (
                        <div className="info-box">
                            <img alt="" src={coverImg}></img>
                            <div className="product-info">
                                <div>{name}</div>
                                <div className="price">
                                    <span>
                                        {services.language.getText('monetaryUnit')}
                                        {salesPrice}
                                    </span>
                                    {priceType === 3 && (
                                        <span className="line">
                                            {services.language.getText('monetaryUnit')}
                                            {marketPrice}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </div>
                    );
                },
            },
            {
                title: services.language.getText('mdzj'),
                dataIndex: 'merchant',
                key: 'merchant',
                render: (text: string, record: any) => {
                    return (
                        <div className="merchant">
                            <div>{record.merchantName}</div>
                            <div>{record.subsiteName}</div>
                        </div>
                    );
                },
            },
            {
                title: services.language.getText('xsjs'),
                dataIndex: 'num',
                key: 'num',
                render: (text: string, record: any) => {
                    const { orderInfo } = record;
                    return (
                        <div className="number">
                            <span>{record.marketTotal}</span>
                            {orderInfo && orderInfo?.length && (
                                <Popover
                                    placement={'right'}
                                    content={
                                        <div style={{ maxWidth: 400 }}>
                                            {orderInfo.map((order: any) => (
                                                <div key={order.orderId}>
                                                    <a
                                                        href={`/8005/order/viewDetail/${order.orderId}`}
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                    >
                                                        {order.orderNo}
                                                    </a>
                                                </div>
                                            ))}
                                        </div>
                                    }
                                >
                                    <span className="view">
                                        {services.language.getText('xgdd')}
                                    </span>
                                </Popover>
                            )}
                        </div>
                    );
                },
            },
            {
                title: services.language.getText('xse'),
                dataIndex: 'marketAmount',
                key: 'marketAmount',
            },
        ];
        const pagination: any = {
            pageSize: 10,
        };

        return (
            <div className="live-room-current-detail">
                <div className="part-container">
                    <div className="title">{services.language.getText('zbsj_1')}</div>
                    <div className="item-content">
                        <div className="info">
                            {fields && fields.coverImg && <img src={fields.coverImg} alt=""></img>}
                            <div className="right">
                                <div className="name">
                                    <span>{get(fields, 'name') || ''}</span>
                                </div>
                                {get(fields, 'date') && (
                                    <div className="time">
                                        {`${services.language.getText('kbsj')}： ${get(
                                            fields,
                                            'date'
                                        )}`}
                                        &nbsp;&nbsp;&nbsp;&nbsp;{get(fields, 'liveStatusDes')}
                                    </div>
                                )}
                            </div>
                        </div>
                        {productData.map((item: any, index: number) => {
                            return (
                                <div className="product-data" key={index + 'i'}>
                                    <div className="item-content-title">{item.title}</div>
                                    <div className="num-main">
                                        {item.data.map((i: any, dex: number) => {
                                            return (
                                                <div className="num" key={dex}>
                                                    {i.title}
                                                    <span>{i.value}</span>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="part-container">
                    <div className="title">{services.language.getText('zbsp')}</div>
                    <div className="item-content">
                        <Table
                            pagination={pagination}
                            className="live-room-product-table"
                            rowKey={'id'}
                            bordered={false}
                            dataSource={goodsInfos}
                            columns={columns}
                        />
                    </div>
                </div>
                <div className="bakcBTn">
                    <Button route="goBack">{services.language.getText('back')}</Button>
                </div>
            </div>
        );
    }
}

function mapStateToProps(_state: any, props: any) {
    const { entity } = props;
    return {
        fields: entity.fields || {},
    };
}
export const LiveRoomCurrentDetail = connect(mapStateToProps)(LiveRoomDataDetailView);
