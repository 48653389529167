import React from 'react';
import { formats, modes } from '@comall-backend-builder/types';
import { isNil, isNaN, get } from 'lodash';
import { PageTemplateSelector } from '../../components';

export class PageTemplateMode extends modes.ObjectMode {
    /**
     * 获取有数据状态展示组件
     */
    getAvailableDisplayComponent(value: any, displayInfo: any) {
        const { className, style } = displayInfo;
        return (
            <span className={className} style={style}>
                {get(value, 'name')}
            </span>
        );
    }

    /**
     * 获取展示组件
     */
    getDisplayComponent(value: any, displayInfo: any) {
        if (value === undefined || value === null) {
            return this.getNotAvailableDisplayComponent(displayInfo);
        } else {
            return this.getAvailableDisplayComponent(value, displayInfo);
        }
    }

    /**
     * 获取输入组件
     */
    getControlComponent(controlInfo: any) {
        return <PageTemplateSelector {...controlInfo}></PageTemplateSelector>;
    }
}
export class PageTemplateFormat extends formats.ObjectFormat {
    /**
     * 对数据进行校验
     */
    validate(rule: any, value: any, callback: any) {
        callback();
    }

    /**
     * 对数据进行格式化
     */
    format(value: any) {
        if (isNil(value) || isNaN(value)) {
            return value.toString();
        } else {
            return '';
        }
    }

    /**
     * 将数据格式化为请求参数
     */
    formatParams(key: any, value: any) {
        return { [key]: value };
    }
}
