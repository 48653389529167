import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { Popover } from 'antd';
import {
    formatPrice,
    LiveRoomProduct,
    PRICE_TYPE,
} from '../../../../config/promotion/market/live-room-product';
import './index.less';
import { services } from '@comall-backend-builder/core';

export class LiveRoomProductMode extends ObjectMode {
    public getDisplayComponent(
        value: any,
        displayInfo: { row: LiveRoomProduct; [key: string]: any }
    ): JSX.Element {
        return <LiveRoomProductInfoCell {...{ value, displayInfo }} />;
    }
}

const LiveRoomProductInfoCell = ({
    value,
    displayInfo,
}: {
    value: any;
    displayInfo: { row: LiveRoomProduct };
}) => {
    const { row } = displayInfo;
    const price = {
        [PRICE_TYPE.一口价]: () =>
            `${services.language.getText('monetaryUnit')}${formatPrice(row.price)}`,
        [PRICE_TYPE.价格区间]: () =>
            `${services.language.getText('monetaryUnit')}${formatPrice(
                row.price
            )}-${services.language.getText('monetaryUnit')}${formatPrice(row.price2)}`,
        [PRICE_TYPE.折扣价]: () => (
            <>
                <span>{`${services.language.getText('monetaryUnit')}${formatPrice(
                    row.price2
                )}`}</span>
                <span style={{ textDecoration: 'line-through' }}>{`${services.language.getText(
                    'monetaryUnit'
                )}${formatPrice(row.price)}`}</span>
            </>
        ),
    }[row.priceType]?.();
    const ImgTag = 'img' as any;
    return (
        <div className="live-room-product-list-item-info">
            <ImgTag
                className="live-room-product-list-item-info__img"
                src={`${row.coverImgUrl}?_t=${Date.now()}`}
                alt=""
                referrerpolicy="no-referrer"
            />
            <div className="live-room-product-list-item-info__text">
                {row && row.name && row.name.length > 7 ? (
                    <Popover
                        style={{ zIndex: 1030 }}
                        className="group-popover"
                        placement="top"
                        content={
                            <div className="group-popover" style={{ maxWidth: 300 }}>
                                {row.name}
                            </div>
                        }
                    >
                        <span>{row.name.slice(0, 6)}...</span>
                    </Popover>
                ) : (
                    <div className="live-room-product-list-item-info__text-name">{row.name}</div>
                )}
                <div className="live-room-product-list-item-info__text-price">{price}</div>
            </div>
        </div>
    );
};
