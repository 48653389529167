import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { hexToRgba } from '../theme-style';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

const prefix = 'card-collection-preview';
const pagePrefix = 'card-collection';
const cardPrefix = 'card-collection-grid';

const innerClassNames = {
    top: `${prefix}__tpp`,
    title: `${prefix}__title`,
    box: `${prefix}__box`,
    ruler: `${prefix}__ruler`,
    rulerText: `${prefix}__ruler__text`,
};

const enum CardCollectionFormat {
    /**
     * 2*2
     */
    FormatMix = 'FORMAT_MIX',
    /**
     * 3*3
     */
    FormatMax = 'FORMAT_MAX',
}

/**
 * 活动类型
 */
const enum CardCollectionType {
    /**
     * 集卡
     */
    CardCollection = 'CARD_COLLECTION',
    /**
     * 拼图
     */
    CardPiece = 'CARD_PIECE',
}

/**
 * 集卡预览效果
 * @param props
 */
const popRecruitDistributorPreview = (props: any) => {
    const { preview = {} } = props;
    const backgroundImageUrl =
        preview && preview.cardBg && preview.cardBg.length > 0 ? preview.cardBg[0].path : '';
    const backgroundImage: any = {
        backgroundImage: backgroundImageUrl ? `url(${backgroundImageUrl})` : '',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
    };
    const name = preview?.baseInfo.name || '';
    return (
        <div className={prefix}>
            <div className={innerClassNames.top}>
                <img alt="" width="100%" src="./images/avatar/theme-head.png" />
                <div className={innerClassNames.title}>{name}</div>
            </div>
            {backgroundImage && (
                <div className={innerClassNames.box} style={backgroundImage}>
                    {renderRuler(preview)}
                    {renderCardCollectionGift()}
                    {renderCardCollectionContent(preview)}
                    {renderCardCollectionStrategy(preview)}
                </div>
            )}
        </div>
    );
};

/**
 * 获取集卡攻略
 */
const getCardCollectionStrategy = (preview: any) => {
    if (!preview) {
        return [];
    }
    const array: Array<any> = [];
    if (preview.ruleConfig1 && preview.ruleConfig1.length > 0 && preview.ruleConfig1[0].checked) {
        array.push({
            title: language.getText('mrcydk'),
            content:
                language.getText('mrscdlhd') +
                services.interpolate(language.getText('getNumeChance'), {
                    count: preview.ruleConfig1[0].count2 || '',
                }),
            img: 'https://b-puzhehei-cdn.co-mall.net/global/card-collection/checkin.png',
        });
    }
    if (preview.ruleConfig2 && preview.ruleConfig2.length > 0 && preview.ruleConfig2[0].checked) {
        array.push({
            title: language.getText('shykp'),
            content:
                language.getText('mrscshykp') +
                services.interpolate(language.getText('getNumeChance'), {
                    count: preview.ruleConfig2[0].count2 || '',
                }),
            img: 'https://b-puzhehei-cdn.co-mall.net/global/card-collection/give.png',
        });
    }
    if (preview.ruleConfig3 && preview.ruleConfig3.length > 0 && preview.ruleConfig3[0].checked) {
        const steps: any = [];
        preview.ruleConfig3.forEach((r: any) => {
            steps.push({
                reward: `${r.count2 || ''}` + services.language.getText('frequency'),
                condition: services.interpolate(services.language.getText('invateNumPeople'), {
                    num: r.count1,
                }),
            });
        });
        array.push({
            title: language.getText('yqhyzl'),
            content: language.getText('hdqjljxyslzlzkhddycsjkjh'),
            img: 'https://b-puzhehei-cdn.co-mall.net/global/card-collection/help.png',
            steps: steps,
            stepType: 1,
        });
    }
    if (preview.ruleConfig4 && preview.ruleConfig4.length > 0 && preview.ruleConfig4[0].checked) {
        const steps: any = [];
        preview.ruleConfig4.forEach((r: any) => {
            steps.push({
                reward: `${r.count2 || ''}` + services.language.getText('frequency'),
                condition: services.interpolate(services.language.getText('invateNumPeople'), {
                    num: r.count1,
                }),
            });
        });
        array.push({
            title: language.getText('yqxrzc'),
            content: language.getText('hdqjljyqxyslxrzckyhddycsjkjh'),
            img: 'https://b-puzhehei-cdn.co-mall.net/global/card-collection/register.png',
            steps: steps,
            stepType: 1,
        });
    }
    if (preview.ruleConfig5 && preview.ruleConfig5.length > 0 && preview.ruleConfig5[0].checked) {
        const steps: any = [];
        preview.ruleConfig5.forEach((r: any) => {
            steps.push({
                reward: `${r.count2 || ''}` + services.language.getText('frequency'),
                condition: `${r.count1 || ''}` + services.language.getText('yuan'),
            });
        });
        array.push({
            title: language.getText('hdqnljxf'),
            content: language.getText('hdqjljxfxyjekhddycsjkjh'),
            img: 'https://b-puzhehei-cdn.co-mall.net/global/card-collection/consumption.png',
            steps: steps,
            stepType: 2,
        });
    }
    return array;
};

const renderCardCollectionStrategy = (preview: any) => {
    const strategies = getCardCollectionStrategy(preview);
    const strategyprefix = 'card-collection-strategy';
    const innerClassNames = {
        content: `${pagePrefix}__strategy`,
        header: `${pagePrefix}__strategy__header`,

        info: `${strategyprefix}__info`,
        infoImage: `${strategyprefix}__info__image`,
        infoContent: `${strategyprefix}__info__content`,
        infoContentTitle: `${strategyprefix}__info__content__title`,
        infoContentText: `${strategyprefix}__info__content__text`,

        step: `${strategyprefix}__step`,
        stepLeftMain: classNames(`${strategyprefix}__step__left__main`),
        stepLeft: classNames(`${strategyprefix}__step__left`),
        stepLeftRange: classNames(`${strategyprefix}__step__range`),
        stepLeftRangeInner: classNames(`${strategyprefix}__step__range__inner`),
        stepRight: `${strategyprefix}__step__right`,

        item: classNames(`${strategyprefix}__step__left__item`),
        itemReward: `${strategyprefix}__step__left__item__reward`,
        itemDot: `${strategyprefix}__step__left__item__dot`,
        itemLine: `${strategyprefix}__step__left__item__line`,
        itemCondition: `${strategyprefix}__step__left__item__condition`,
        button: `${strategyprefix}__info__button`,
    };
    const headerStyle = {
        backgroundImage: `url(https://b-puzhehei-cdn.co-mall.net/global/card-collection/strategy-header-v2.png)`,
    };
    const rangeStyle = {
        backgroundColor: hexToRgba('#FF7C00', 0.1),
    };
    const rangeInnerStyle = {
        backgroundColor: hexToRgba('#FF7C00', 0.5),
    };
    const rangeLineStyle = {
        backgroundColor: hexToRgba('#FF7C00', 0.5),
        left: 0,
    };
    const rewardStyle = {
        backgroundColor: '#FF7C00',
    };
    const cardRuleBtnPic =
        preview && preview.cardRuleBtnPic && preview.cardRuleBtnPic.length > 0
            ? preview.cardRuleBtnPic[0].path
            : '';
    const buttonStyle = {
        backgroundImage: `url(${cardRuleBtnPic})`,
    };
    return (
        <div className={innerClassNames.content}>
            <div style={headerStyle} className={innerClassNames.header}>
                <span>{services.language.getText('jkgl')}</span>
            </div>
            {strategies &&
                strategies.map((props: any) => {
                    return (
                        <div className={strategyprefix}>
                            <div className={innerClassNames.info}>
                                <img alt="" className={innerClassNames.infoImage} src={props.img} />
                                <div className={innerClassNames.infoContent}>
                                    <div className={innerClassNames.infoContentTitle}>
                                        {props.title}
                                    </div>
                                    <div className={innerClassNames.infoContentText}>
                                        {props.content}
                                    </div>
                                </div>
                                <div className={innerClassNames.button} style={buttonStyle}>
                                    {services.language.getText('lq')}
                                </div>
                            </div>
                            {props.steps && props.steps.length > 0 && (
                                <div className={innerClassNames.step}>
                                    <div className={innerClassNames.stepLeftMain}>
                                        <div
                                            className={innerClassNames.stepLeftRange}
                                            style={rangeStyle}
                                        >
                                            <div
                                                className={innerClassNames.stepLeftRangeInner}
                                                style={rangeInnerStyle}
                                            ></div>
                                        </div>
                                        <div className={innerClassNames.stepLeft}>
                                            {props.steps &&
                                                props.steps.map((step: any, index: number) => {
                                                    const stepsLength: number =
                                                        props.steps?.length || 0;
                                                    const itemInnerClassNames = {
                                                        item: classNames(
                                                            `${strategyprefix}__step__left__item`,
                                                            {
                                                                [`${strategyprefix}__step__left__item__${stepsLength}`]:
                                                                    stepsLength > 0 &&
                                                                    stepsLength < 3,
                                                                [`${strategyprefix}__step__left__item__scroll`]:
                                                                    stepsLength > 3,
                                                            }
                                                        ),
                                                    };
                                                    return (
                                                        index < 3 && (
                                                            <div
                                                                className={itemInnerClassNames.item}
                                                            >
                                                                <span
                                                                    className={
                                                                        innerClassNames.itemReward
                                                                    }
                                                                    style={rewardStyle}
                                                                >
                                                                    {step.reward}
                                                                </span>
                                                                <span
                                                                    className={
                                                                        innerClassNames.itemDot
                                                                    }
                                                                    style={rewardStyle}
                                                                ></span>
                                                                <span
                                                                    className={
                                                                        innerClassNames.itemLine
                                                                    }
                                                                    style={rangeLineStyle}
                                                                ></span>
                                                                <span
                                                                    className={
                                                                        innerClassNames.itemCondition
                                                                    }
                                                                >
                                                                    {step.condition}
                                                                </span>
                                                            </div>
                                                        )
                                                    );
                                                })}
                                        </div>
                                    </div>
                                    <div className={innerClassNames.stepRight}>
                                        {props.stepType === 1 && (
                                            <span>
                                                {services.language.getText('yyq')}
                                                <span style={{ color: '#FF7C00' }}>1</span>人
                                            </span>
                                        )}
                                        {props.stepType === 2 && (
                                            <span>
                                                {services.language.getText('yxf')}
                                                <span style={{ color: '#FF7C00' }}>1</span>
                                                {services.language.getText('yuan')}
                                            </span>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}
        </div>
    );
};

const renderCardCollectionGift = () => {
    const innerClassNames = {
        gift: `${pagePrefix}__gift`,
        giftText: `${pagePrefix}__gift__text`,
    };
    const style = {
        backgroundImage: `url(https://b-puzhehei-cdn.co-mall.net/global/card-collection/gift.png)`,
    };
    return <div className={innerClassNames.gift} style={style}></div>;
};
const renderCardCollectionContent = (preview: any) => {
    const innerClassNames = {
        content: `${pagePrefix}__content`,
    };
    const style = {
        backgroundImage: `url(https://b-puzhehei-cdn.co-mall.net/global/card-collection/content.png)`,
    };
    return (
        <div className={innerClassNames.content} style={style}>
            {renderCardCollectionContentGrid(preview)}
            {renderCardCollectionContentButton(preview)}
            {renderCardCollectionContentPuzzles(preview)}
        </div>
    );
};

const renderCardCollectionContentGrid = (preview: any) => {
    const bigPicture =
        preview && preview.cardBigPic && preview.cardBigPic.length > 0
            ? preview.cardBigPic[0].path
            : '';
    const style: CSSProperties = {
        position: 'absolute',
        top: 63,
        left: 51,
        backgroundImage: `url(${bigPicture})`,
    };
    const type = preview?.activityType || 'CARD_COLLECTION';
    const format = preview?.activityType || 'FORMAT_MIX';
    const innerClassNames = {
        collectionMask: classNames(`${cardPrefix}__collection`),
        piece: classNames(`${cardPrefix}__piece`, {
            [`${cardPrefix}__piece--mix`]: format && format === CardCollectionFormat.FormatMix,
            [`${cardPrefix}__piece--max`]: format && format === CardCollectionFormat.FormatMax,
        }),
    };
    if (type === CardCollectionType.CardCollection) {
        return (
            <div className={cardPrefix} style={style}>
                <div className={innerClassNames.collectionMask}></div>
            </div>
        );
    }
    return (
        <div className={cardPrefix} style={style}>
            <div className={innerClassNames.piece}>
                {renderCardCollectionGridMaskLayer(preview)}
                {renderCardCollectionGridMaskLine(preview)}
            </div>
        </div>
    );
};

const renderCardCollectionGridMaskLayer = (preview: any) => {
    return (
        preview &&
        preview.cardItemConfig &&
        preview.cardItemConfig.map((item: any) => {
            const innerClassNames = {
                layer: `${cardPrefix}__piece__layer`,
            };
            return <div className={innerClassNames.layer} key={item.id}></div>;
        })
    );
};

const renderCardCollectionGridMaskLine = (preview: any) => {
    const format = preview?.cardStandard || 'FORMAT_MIX';
    const innerClassNames = {
        mixRow: `${cardPrefix}__piece--mix__row`,
        mixContainer: `${cardPrefix}__piece--mix__container`,
        maxRow: `${cardPrefix}__piece--max__row`,
        maxContainer: `${cardPrefix}__piece--max__container`,
    };
    if (format === CardCollectionFormat.FormatMix) {
        return (
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <div className={innerClassNames.mixRow}></div>
                <div className={innerClassNames.mixContainer}></div>
            </div>
        );
    }
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <div className={innerClassNames.maxRow} style={{ top: '33.33%' }}></div>
            <div className={innerClassNames.maxRow} style={{ top: '66.66%' }}></div>
            <div className={innerClassNames.maxContainer} style={{ left: '33.33%' }}></div>
            <div className={innerClassNames.maxContainer} style={{ left: '66.66%' }}></div>
        </div>
    );
};

const renderCardCollectionContentButton = (preview: any) => {
    const innerClassName = classNames(`${pagePrefix}__content__button`);
    const buttonText = services.language.getText('ljhc');
    const buttonSrc =
        preview && preview.cardRewardBtnPic && preview.cardRewardBtnPic.length > 0
            ? preview.cardRewardBtnPic[0].path
            : '';
    const style = {
        backgroundImage: `url(${buttonSrc})`,
    };
    return (
        <div className={innerClassName} style={style}>
            {buttonText}
        </div>
    );
};

const renderCardCollectionContentPuzzles = (preview: any) => {
    const innerClassNames = {
        puzzle: `${pagePrefix}__content__puzzle`,
        puzzleTitle: `${pagePrefix}__content__puzzle__title`,
        puzzleScroll: `${pagePrefix}__content__puzzle__scroll`,
        puzzleScrollWrap: `${pagePrefix}__content__puzzle__scroll__wrap`,
        item: `${pagePrefix}__content__puzzle__scroll__wrap__item`,
        itemTop: `${pagePrefix}__content__puzzle__scroll__wrap__item__top`,
        itemButton: `${pagePrefix}__content__puzzle__scroll__wrap__item__button`,
        itemBadge: `${pagePrefix}__content__puzzle__scroll__wrap__item__badge`,
    };
    const titleStyle = {
        backgroundImage: `url(https://b-puzhehei-cdn.co-mall.net/global/card-collection/puzzle-title.png)`,
    };
    const badgeDom = <div className={innerClassNames.itemBadge}>1</div>;
    return (
        <div className={innerClassNames.puzzle}>
            <div className={innerClassNames.puzzleTitle} style={titleStyle}>
                {services.language.getText('wdptsp')}
            </div>
            <div className={innerClassNames.puzzleScroll}>
                <div className={innerClassNames.puzzleScrollWrap}>
                    {preview &&
                        preview.cardItemConfig &&
                        preview.cardItemConfig.map((c: any) => {
                            const pictureUrl =
                                c.cardCollectionItemPicture &&
                                c.cardCollectionItemPicture.length > 0
                                    ? c.cardCollectionItemPicture[0].path
                                    : '';
                            if (!pictureUrl) {
                                return null;
                            }
                            const topStyle = {
                                backgroundImage: `url(${pictureUrl})`,
                            };
                            return (
                                <div className={innerClassNames.item}>
                                    <div className={innerClassNames.itemTop} style={topStyle}></div>
                                    <div className={innerClassNames.itemButton}>
                                        {services.language.getText('zs_4')}
                                    </div>
                                    {badgeDom}
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

const renderRuler = (preview: any) => {
    const description = preview?.activityIntro || '';
    if (!description) {
        return null;
    }
    return (
        <div className={innerClassNames.ruler}>
            <div className={innerClassNames.rulerText}>{services.language.getText('g')}</div>
            <div className={innerClassNames.rulerText}>{services.language.getText('z')}</div>
        </div>
    );
};

const mapStateToProps = (_state: any, props: any) => {
    let preview = null;
    const entityId = props.entity.id;
    for (var compId in _state.components) {
        const comp = _state.components[compId];
        if (
            (comp.type === 'CreateForm' || comp.type === 'EditForm') &&
            comp.entityId === entityId
        ) {
            preview = comp.fields;
        }
    }
    return { preview: preview };
};

export const CardCollectionPreview = connect(mapStateToProps)(popRecruitDistributorPreview);
