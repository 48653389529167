import React, { Component } from 'react';
import { InputNumber, Radio, Select } from 'antd';
import { services } from '@comall-backend-builder/core';

/**
 * 抵扣规则值对象
 */
interface PointValidRuleValue {
    clearType: string;
    fixedRule: {
        day: number;
        month: number;
        yearDelay: number;
    };
    graduallyRule: {
        dateValue: number;
    };
}
interface PointValidRuleProps {
    onChange: (value: any) => void;
    value: PointValidRuleValue | undefined;
    companyText: string | '';
}

export class PointValidRule extends Component<PointValidRuleProps, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            months: Array.from({ length: 12 }, (_, i) => i + 1),
            days: [],
        };
    }
    changeValue = (value: any) => {
        const currentValue = this.props.value ? this.props.value : {};
        const newValue = Object.assign({ ...currentValue }, { ...value });
        this.props.onChange(newValue);
    };

    onChangeExchangeRatio = (e: any) => {
        this.changeValue({ clearType: e.target.value });
    };

    onChangeExchangeAmount = (exchangeAmount: any) => {
        this.changeValue({ exchangeAmount });
    };
    handleChange = (type: string, value: any) => {
        if (type === 'dateValue') {
            let graduallyRule: any = this.props.value?.graduallyRule || {};
            graduallyRule[type] = value;
            this.changeValue({
                graduallyRule,
            });
        } else {
            let fixedRule: any = this.props.value?.fixedRule || {};
            fixedRule[type] = value;
            this.changeValue({
                fixedRule,
            });
        }
    };

    render() {
        const { value } = this.props;
        const { months } = this.state;
        const itemStyle = { padding: '5px 0' };
        const getDaysInMonth = (month: number) => {
            return new Date(Date.UTC(2024, month, 0)).getUTCDate();
        };
        const days = value?.fixedRule?.month
            ? Array.from({ length: getDaysInMonth(value.fixedRule.month) }, (_, i) => i + 1)
            : [];
        return (
            <div className="point-exchange-rule">
                <Radio.Group
                    value={value?.clearType ? value.clearType : 'EVER_VALID'}
                    defaultValue={'EVER_VALID'}
                    onChange={this.onChangeExchangeRatio}
                >
                    <div style={itemStyle}>
                        <Radio value={'EVER_VALID'}>{services.language.getText('yjyx')}</Radio>
                    </div>
                    <div style={itemStyle}>
                        <Radio value={'FIXED_CLEAR'}>
                            <span>{services.language.getText('chdksrqd')}</span>
                            <InputNumber
                                style={{ width: 60 }}
                                className="item-input"
                                disabled={value?.clearType !== 'FIXED_CLEAR'}
                                min={1}
                                max={10}
                                value={
                                    value?.fixedRule && value.fixedRule.yearDelay > 0
                                        ? value.fixedRule.yearDelay
                                        : undefined
                                }
                                onChange={this.handleChange.bind(this, 'yearDelay')}
                                // onBlur={this.onValidExchangeAmount}
                            />
                            <span>{services.language.getText('n_1')}</span>
                            <Select
                                defaultValue=""
                                disabled={value?.clearType !== 'FIXED_CLEAR'}
                                style={{ width: 80 }}
                                onChange={this.handleChange.bind(this, 'month')}
                                value={
                                    value?.fixedRule && value.fixedRule.month > 0
                                        ? value.fixedRule.month
                                        : ''
                                }
                            >
                                <Select.Option value={''}>
                                    {services.language.getText('selected')}
                                </Select.Option>
                                {months &&
                                    months.map((m: any) => {
                                        return <Select.Option value={m}>{m}</Select.Option>;
                                    })}
                            </Select>
                            <span> {services.language.getText('y_4')}</span>
                            <Select
                                defaultValue=""
                                disabled={value?.clearType !== 'FIXED_CLEAR'}
                                style={{ width: 80 }}
                                onChange={this.handleChange.bind(this, 'day')}
                                value={
                                    value?.fixedRule && value.fixedRule.day > 0
                                        ? value.fixedRule.day
                                        : ''
                                }
                            >
                                <Select.Option value={''}>
                                    {services.language.getText('selected')}
                                </Select.Option>
                                {days &&
                                    days.map((d) => {
                                        return <Select.Option value={d}>{d}</Select.Option>;
                                    })}
                            </Select>
                            <span> {services.language.getText('r_3')}</span>
                        </Radio>
                        <div>{services.language.getText('l_settings_1')}</div>
                    </div>
                    <div style={itemStyle}>
                        <Radio value={'GRADUALLY_CLEAR'}>
                            <span>{services.language.getText('mbjfyxqw')}</span>
                            <InputNumber
                                disabled={value?.clearType !== 'GRADUALLY_CLEAR'}
                                style={{ width: 60 }}
                                className="item-input"
                                min={1}
                                max={9999}
                                value={
                                    value?.graduallyRule && value.graduallyRule.dateValue > 0
                                        ? value.graduallyRule.dateValue
                                        : undefined
                                }
                                onChange={this.handleChange.bind(this, 'dateValue')}
                            />
                            <span> {services.language.getText('y_4')}</span>
                        </Radio>
                        <div>{services.language.getText('l_settings_2')}</div>
                    </div>
                </Radio.Group>
            </div>
        );
    }
}
