import React from 'react';
import { connect } from 'react-redux';
import './index.less';
import classNames from 'classnames';
import { services } from '@comall-backend-builder/core';
enum pointRuleType {
    Y = 'YES',
    N = 'NO',
}
const prefix = 'wfj-lottery-code-preview';
const innerClassNames = {
    top: `${prefix}__top`,
    title: `${prefix}__title`,
    box: `${prefix}__box`,
    goodsWrap: `${prefix}__goods_wrap`,
    goodsShare: `${prefix}__goods_share`,
    goodsRight: `${prefix}__goods_right`,
    goodsRightBox: `${prefix}__goods_right_box`,
    goodsGift: `${prefix}__goods_gift`,
    goodsGiftTxt: `${prefix}__goods_gift_txt`,
    GDcontent: `${prefix}__goods_details_content`,
    GDprice: `${prefix}__goods_details_price`,
    GDname: `${prefix}__goods_details_name`,
    TMbox: `${prefix}__time_box`,
    TM: `${prefix}__time`,
    TMjoin: `${prefix}__time_join`,
    TMexchange: `${prefix}__time_exchange`,
    LDCbox: `${prefix}__lucky_draw_code_box`,
    LDCtitle: `${prefix}__lucky_draw_code_title`,
    LDCTleft: `${prefix}__lucky_draw_code_title_left`,
    LDCTright: `${prefix}__lucky_draw_code_title_right`,
    LDCcontent: `${prefix}__lucky_draw_code_content`,
    LDCli: `${prefix}__lucky_draw_code_li`,
    ARbox: `${prefix}__active_rules_box`,
    ARtitle: `${prefix}__active_rules_title`,
    ARTleft: `${prefix}__active_rules_title_left`,
    ARTright: `${prefix}__active_rules_title_right`,
    ARcontent: `${prefix}__active_rules_content`,
    ARCpub: `${prefix}__active_rules_content_pub`,
    ARCbetween: `${prefix}__active_rules_content_between`,
    ARline: `${prefix}__active_rules_line`,
};
/**
 * 积分抽奖码预览效果
 * @param props
 */
const popPreview = (props: any) => {
    const { preview = {} } = props;

    const name = preview?.baseInfo.name || '';
    const boxStyle = { backgroundColor: '#ffffff' };
    return (
        <div style={boxStyle}>
            <div className={prefix}>
                <div className={innerClassNames.top}>
                    <img alt="" width="100%" src="./images/avatar/theme-head.png" />
                    <div className={innerClassNames.title}>{name}</div>
                </div>
                <div className={innerClassNames.box}>
                    {renderGoodsPicture(preview)}
                    {renderGoodsDetails(preview)}
                    {renderTimeBox(preview)}
                    {renderLuckyDrawCode()}
                    {renderActiveRules(preview)}
                </div>
            </div>
        </div>
    );
};
const renderGoodsPicture = (preview: any) => {
    let goodsImageUrl;
    if (preview?.prizeInfo?.picture?.length) {
        goodsImageUrl = preview.prizeInfo.picture[0]?.path;
    }
    const backgroundImage: any = {
        backgroundImage: goodsImageUrl ? `url(${goodsImageUrl})` : '',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
    };
    const shareImg = require('./images/share.png');
    const giftImg = require('./images/gift.png');
    return (
        <div className={innerClassNames.goodsWrap} style={backgroundImage}>
            <div className={innerClassNames.goodsShare}>
                <img alt="" src={shareImg} />
            </div>
            <div className={innerClassNames.goodsRight}>
                <div className={innerClassNames.goodsRightBox}>
                    <img className={innerClassNames.goodsGift} alt="" src={giftImg} />
                    <div className={innerClassNames.goodsGiftTxt}>
                        {services.language.getText('wdcj')}
                    </div>
                </div>
            </div>
        </div>
    );
};

const renderGoodsDetails = (preview: any) => {
    let goodsName = preview?.prizeInfo?.name
        ? preview.prizeInfo?.name
        : services.language.getText('jpmc');
    return (
        <div className={innerClassNames.GDcontent}>
            <div className={innerClassNames.GDprice}>
                {services.language.getText('cjj')} {services.language.getText('monetaryUnit')}
                <span>0</span>.00
            </div>
            <div className={innerClassNames.GDname}>{goodsName}</div>
        </div>
    );
};

const renderTimeBox = (preview: any) => {
    if (!preview) return;
    const pointNum = preview.numberInfo.pointRule === pointRuleType.Y;
    return (
        <div className={innerClassNames.TMbox}>
            <div className={innerClassNames.TM}>
                {services.language.getText('jhdjshs')} &nbsp;
                <span> 2 </span> {services.language.getText('day')}&nbsp;
                <span> 18 </span> {services.language.getText('s_5')}&nbsp;
                <span> 36 </span> {services.language.getText('fen')}&nbsp;
                <span> 20 </span> {services.language.getText('second')}
            </div>
            <div className={innerClassNames.TMjoin}> {services.language.getText('l_cycj_1')}</div>
            {pointNum && (
                <div className={innerClassNames.TMexchange}>
                    {services.language.getText('jfdhcjm')}（{preview.numberInfo.point || 0}
                    {services.language.getText('point')}）
                </div>
            )}
        </div>
    );
};
const renderLuckyDrawCode = () => {
    const list = ['E2345678', 'Q5678234', 'N0022002', '待获取'];
    const listMap = list.map((item) => {
        return (
            <div key={item} className={innerClassNames.LDCli}>
                {item}
            </div>
        );
    });
    return (
        <div className={innerClassNames.LDCbox}>
            <div className={innerClassNames.LDCtitle}>
                <div className={innerClassNames.LDCTleft}>{services.language.getText('wdcjm')}</div>
                <div className={innerClassNames.LDCTright}>
                    {services.language.getText('cqmydzqjhyd')}
                </div>
            </div>
            <div className={innerClassNames.LDCcontent}>{listMap}</div>
        </div>
    );
};
const processingTime = (params: string) => {
    const paramsArr = params.split(' ');
    const dateStrArr = paramsArr[0].split('-');
    const timeStr = paramsArr[1].slice(0, 5);
    const dateStr = `${Number(dateStrArr[1])}月${Number(dateStrArr[2])}日`;
    return {
        dateStr,
        timeStr,
    };
};

const renderActiveRules = (preview: any) => {
    if (!preview) {
        return;
    }
    const { baseInfo } = preview;
    const arrows = require('./images/arrow.png');
    const ARbox = classNames(innerClassNames.LDCbox, innerClassNames.ARbox);
    const ARtitle = classNames(innerClassNames.LDCtitle, innerClassNames.ARtitle);
    const ARTleft = classNames(innerClassNames.LDCTleft, innerClassNames.ARTleft);
    const ARTright = classNames(innerClassNames.LDCTright, innerClassNames.ARTright);
    const ARCbetween = classNames(innerClassNames.ARCpub, innerClassNames.ARCbetween);
    let startDate = 'x月x日',
        startTime = '02:00',
        endDate = 'x月x日',
        endTime = '16:00',
        publishDate = 'x月x日',
        publishTime = '18:00';
    if (baseInfo.dateRange) {
        const { dateStr: startD, timeStr: startT } = processingTime(baseInfo.dateRange.start);
        startDate = startD;
        startTime = startT;
        const { dateStr: endD, timeStr: endT } = processingTime(baseInfo.dateRange.end);
        endDate = endD;
        endTime = endT;
    }
    if (baseInfo.drawTime) {
        const { dateStr: publishD, timeStr: publishT } = processingTime(baseInfo.drawTime);
        publishDate = publishD;
        publishTime = publishT;
    }

    return (
        <div className={ARbox}>
            <div className={ARtitle}>
                <div className={ARTleft}>{services.language.getText('activityRule')}</div>
                <div className={ARTright}>
                    {services.language.getText('ckxq')}
                    <img alt="" src={arrows} />
                </div>
            </div>
            <div className={innerClassNames.ARcontent}>
                <div className={ARCbetween}>
                    <div>{startDate}</div>
                    <div>
                        {startTime}
                        {services.language.getText('ks')}
                    </div>
                </div>
                <div className={innerClassNames.ARline}>
                    <div></div>
                </div>
                <div className={ARCbetween}>
                    <div>{publishDate}</div>
                    <div>
                        {publishTime}
                        {services.language.getText('gbjg')}
                    </div>
                </div>
                <div className={innerClassNames.ARline}>
                    <div></div>
                </div>
                <div className={ARCbetween}>
                    <div>{endDate}</div>
                    <div>
                        {endTime}
                        {services.language.getText('end2')}
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (_state: any, props: any) => {
    let preview = null;
    const entityId = props.entity.id;
    for (var compId in _state.components) {
        const comp = _state.components[compId];
        if (
            (comp.type === 'CreateForm' || comp.type === 'EditForm') &&
            comp.entityId === entityId
        ) {
            preview = comp.fields;
        }
    }
    return { preview: preview };
};

export const WfjLotteryCodePreview = connect(mapStateToProps)(popPreview);
