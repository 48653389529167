import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import MemberCardLevelPointReward from './member-card-level-point-reward';

/**
 *  支付有礼，奖品设置
 */
export class MemberCardLevelPointRewardInfoMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <MemberCardLevelPointReward {...controlInfo} />;
    }
}
