import { Col, Icon, Popover, Row } from 'antd';
import Checkbox from 'antd/es/checkbox';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import React, { useCallback, useMemo } from 'react';

interface CheckboxTipsControlProps {
    value: boolean;
    onChange(value: boolean): void;
    tips?: string;
    label: string;
}
export default function CheckboxTipsControl(props: CheckboxTipsControlProps) {
    const { onChange, value, label, tips } = props;
    const onCheckboxChange = useCallback(
        (e: CheckboxChangeEvent) => {
            onChange(e.target.checked);
        },
        [onChange]
    );
    const tipsContent = useMemo(() => {
        if (!tips) {
            return null;
        }
        return (
            <div style={{ marginTop: -8, marginBottom: -8 }}>
                {tips.split(/\s/).map((char) => (
                    <div style={{ marginTop: 8, marginBottom: 8 }}>{char}</div>
                ))}
            </div>
        );
    }, [tips]);
    return (
        <Row>
            {tips && (
                <Col span={1}>
                    <Popover
                        trigger="hover"
                        content={tipsContent}
                        overlayStyle={{
                            maxWidth: 400,
                            wordBreak: 'break-all',
                        }}
                    >
                        <Icon type="question-circle" theme="filled" />
                    </Popover>
                </Col>
            )}
            <Checkbox checked={value} onChange={onCheckboxChange}>
                {label}
            </Checkbox>
        </Row>
    );
}
