import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { api } from '@comall-backend-builder/core/lib/services';

export const WechatOfficialAccountConfigWidgetLoader: Loader = {
    get(params, config) {
        if (params.id) {
            config.apiPath = `/admin/wechat_official_accounts/${params.id}/widget`;
            // delete params.id;
        }
        return api.get(params, config).then((res: any) => {
            if (res) {
                res.backgroundPictureUrl = res.backgroundPictureUrl
                    ? [
                          {
                              id: '0',
                              path: res.backgroundPictureUrl,
                          },
                      ]
                    : undefined;
            }
            return res;
        });
    },
    put(params, config) {
        const { id, widgetId } = params;
        params.backgroundPictureUrl =
            params && params.backgroundPictureUrl && params.backgroundPictureUrl.length > 0
                ? params.backgroundPictureUrl[0].path
                : '';
        if (id) {
            config.apiPath = `/admin/wechat_official_accounts/${id}/widget/${widgetId}`;
        }
        if (params.topicPages) {
            let topicPages: any = [];
            if (params.applyPages && params.applyPages.includes('TOPIC_PAGE')) {
                params.topicPages.forEach((t: any) => {
                    topicPages.push({
                        id: t.id,
                        name: t.name,
                        enabled: t.enabled,
                        subsites: t.subsites,
                    });
                });
            }
            params.topicPages = topicPages;
        } else {
            params.topicPages = [];
        }
        return api.put(params, config);
    },
};

export const WechatOfficialAccountConfigSubsiteOptionsLoader: Loader = {
    get(params, config) {
        if (params.id) {
            config.apiPath = `/admin/wechat_official_accounts/${params.id}/subsites`;
        }
        return api.get(params, config);
    },
};
export const WechatOfficialAccountConfigTopicOptionsLoader: Loader = {
    get(params, config) {
        config.apiPath = '/admin/pages/CUSTOM_PAGE';
        if (!params.perPage || !params.page) {
            params.page = 1;
            params.perPage = 10;
        }

        return api.get(params, config).then((res: any) => {
            if (res) {
                res.result.forEach((item: any) => {
                    item.id = String(item.id);
                });
            }
            return res;
        });
    },
};
