import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const CardLevelLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`;
        config.apiPath = '/admin/card_levels';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/card_levels/${id}`;
        }
        function processResult(item: any) {
            item.baseInfo = {
                code: item.code,
                name: item.name,
                level: item.level,
                status: item.status,
                iconPicture: item.iconId
                    ? [
                          {
                              id: item.iconId,
                              path: item.iconUrl || '',
                          },
                      ]
                    : [],
                cardCoverPicture: item.cardCoverId
                    ? [
                          {
                              id: item.cardCoverId,
                              path: item.cardCoverUrl || '',
                          },
                      ]
                    : [],
                cardDiscountTag: {
                    cardNameBackgroundColor: item.cardNameBackgroundColor || '#1c2566',
                    cardNameWordColor: item.cardNameWordColor || '#f9d96a',
                    memberDiscountBackgroundColor: item.memberDiscountBackgroundColor || '#f9d96a',
                    memberDiscountWordColor: item.memberDiscountWordColor || '#1c2566',
                },
            };
            if (item.rights) {
                item.rights.map((right: any) => {
                    right.iconPicture = [{ id: right.iconId, path: right.iconUrl || '' }];
                    return right;
                });
            }
            item.rightInfo = {
                rights: item.rights,
                description: item.description,
            };

            return item;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res = processResult(res);
            } else {
                if (res) {
                    res = res.map(processResult);
                }
            }

            return res;
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`;
        config.apiPath = `/admin/card_levels/${id}`;
        const newParams = dataParser(params);
        newParams.id = id;
        return await api.put(newParams, config);
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`;
        config.apiPath = `/admin/card_levels`;
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    if (params.baseInfo) {
        if (params.baseInfo.cardDiscountTag) {
            newParams = { ...params.baseInfo.cardDiscountTag };
        }
        newParams.name = params.baseInfo.name;
        newParams.status = params.baseInfo.status;
        newParams.code = params.baseInfo.code;
        newParams.level = params.baseInfo.level;
        const iconPicture = params.baseInfo.iconPicture;
        const cardCoverPicture = params.baseInfo.cardCoverPicture;
        newParams.iconId = iconPicture && iconPicture[0].id ? iconPicture[0].id : '';
        newParams.iconUrl = iconPicture && iconPicture[0].path ? iconPicture[0].path : '';
        newParams.cardCoverId =
            cardCoverPicture && cardCoverPicture[0].id ? cardCoverPicture[0].id : '';
        newParams.cardCoverUrl =
            cardCoverPicture && cardCoverPicture[0].path ? cardCoverPicture[0].path : '';
    }
    if (params.rightInfo) {
        newParams.rights = params.rightInfo.rights;
        newParams.description = params.rightInfo.description;
    }
    return newParams;
}
