import React, { Component } from 'react';
import { Input } from 'antd';
import './index.less';
export class StringInputMaxLength extends Component<any, any> {
    render() {
        const { maxLength, value } = this.props;
        let length = 0;
        if (value) {
            length = value.trim().length;
        }
        return (
            <div className="string-input-max-length">
                <Input className="input" {...this.props} />
                <span className="length-display">
                    {length}/{maxLength}
                </span>
            </div>
        );
    }
}
