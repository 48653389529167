import React from 'react';
import { Form, Select, Button, Input, DatePicker, Col, Row, Icon, Cascader } from 'antd';
import PropTypes from 'prop-types';
import { services } from '@comall-backend-builder/core';
import { last, cloneDeep, isEmpty, isEqual, find } from 'lodash';
import { ForwardDataCenterModal } from '../../../../services';
import { TextTips } from '../../../../components/text-tips';
import {
    RegisterchannelEnum,
    Subsite,
    FilterParams,
    SelectOption,
    MemberInfoGender,
    MemberInfoStatus,
    WechatOfficialAccountFanStatus,
} from '../../common';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;
const { RangePicker } = DatePicker;

interface State {
    expand: boolean;
    subsiteOptions: Subsite[];
    cardLevelsOptions: any[];
}

class MemberManageSearchForm extends React.Component<any, State> {
    static propTypes: {
        initialTagParams?: PropTypes.Requireable<{
            tagSubsiteId: string;
            tagId: string;
        }>;
        initialCrowdParams?: PropTypes.Requireable<{
            crowdSubsiteId: string;
            crowdId: string;
        }>;
        onSearch: PropTypes.Requireable<(...args: any[]) => any>;
    };

    state: State = {
        expand: false,
        subsiteOptions: [],
        cardLevelsOptions: [],
    };

    componentDidMount() {
        this.getSubsites();
        this.getCardLevels();
    }

    getSubsites = async () => {
        const subsites: Subsite[] = await api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: '/WEB-API/admin/defaults/subsite',
            }
        );
        this.setState({
            subsiteOptions: subsites,
        });
    };

    getCardLevels = async () => {
        const res: any[] = await api.get(
            {
                status: 'ENABLE',
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                apiPath: '/admin/levels',
            }
        );
        this.setState({
            cardLevelsOptions: res,
        });
    };

    getFieldsConfig = () => {
        const { expand, subsiteOptions, cardLevelsOptions } = this.state;
        const { initialTagParams, initialCrowdParams } = this.props;
        const genderOptions = [
            {
                id: MemberInfoGender.UNKNOWN,
                name: language.getText('wz_4'),
            },
            { id: MemberInfoGender.WOMAN, name: language.getText('n') },
            { id: MemberInfoGender.MAN, name: language.getText('n_2') },
        ];
        const memberStatusOptions = [
            { id: MemberInfoStatus.NORMAL, name: language.getText('zc_1') },
            { id: MemberInfoStatus.INVALID, name: language.getText('invalid') },
            { id: MemberInfoStatus.OFF, name: language.getText('off') },
        ];
        const basicsConfig = [
            {
                label: language.getText('sjhm'),
                key: 'mobile',
                display: true,
                fieldComponent: input(language.getText('qsrsjhm')),
                span: 6,
            },
            {
                label: <TextTips text="CRMID" tips={language.getText('jzzhywybs')} />,
                key: 'crmMemberId',
                display: true,
                fieldComponent: input(language.getText('jqsrcrmid')),
                span: 6,
            },
            {
                label: (
                    <TextTips
                        text={language.getText('memberId')}
                        tips={language.getText('jzxtzhywybs')}
                    />
                ),
                key: 'memberId',
                display: true,
                fieldComponent: input(language.getText('qsrhy')),
                span: 6,
            },
            {
                label: 'CID',
                key: 'cid',
                display: expand,
                fieldComponent: input(language.getText('qsrmdcid')),
                span: 6,
            },
        ];
        const registerConfig = [
            {
                label: language.getText('zcmd'),
                key: 'registerSubsiteId',
                display: expand,
                fieldComponent: subsiteSelecter(subsiteOptions),
            },
            {
                label: language.getText('zcqd'),
                key: 'registerChannel',
                display: expand,
                fieldComponent: () => {
                    const options = [
                        {
                            value: RegisterchannelEnum.CRM,
                            label: 'CRM',
                        },
                        {
                            value: 'MAGIC',
                            label: 'MAGIC',
                            children: [
                                {
                                    value: RegisterchannelEnum.MAGIC_WECHAT,
                                    label: language.getText('wxMiniP'),
                                },
                                {
                                    value: RegisterchannelEnum.MAGIC_ALIPAY,
                                    label: language.getText('aliMiniP'),
                                },
                                {
                                    value: RegisterchannelEnum.MAGIC_BYTE_DANCE,
                                    label: '抖音小程序',
                                },
                                {
                                    value: RegisterchannelEnum.MAGIC_ANDROID,
                                    label: language.getText('azkhd'),
                                },
                                {
                                    value: RegisterchannelEnum.MAGIC_IOS,
                                    label: language.getText('pgkhd'),
                                },
                                {
                                    value: RegisterchannelEnum.MAGIC_PC,
                                    label: language.getText('wyd'),
                                },
                            ],
                        },
                    ];
                    return (
                        <Cascader
                            options={options}
                            placeholder={services.language.getText('selectPlease')}
                            allowClear
                            changeOnSelect
                        ></Cascader>
                    );
                },
            },
            {
                label: language.getText('zcsj'),
                key: 'registeDate',
                display: expand,
                fieldComponent: dateRange(),
            },
        ];
        const infoConfig = [
            {
                label: (
                    <TextTips
                        text={language.getText('cardLevel')}
                        tips={language.getText('ccsxgyhzcmddqdj')}
                    />
                ),
                key: 'cardLevelId',
                display: expand,
                fieldComponent: selecter(cardLevelsOptions),
            },
            {
                label: language.getText('xb_1'),
                key: 'gender',
                display: expand,
                fieldComponent: selecter(genderOptions),
            },
            {
                label: language.getText('hyzt'),
                key: 'memberStatus',
                display: expand,
                fieldComponent: selecter(memberStatusOptions),
            },
            {
                label: language.getText('birthday'),
                key: 'birthdayDate',
                display: expand,
                fieldComponent: () => {
                    return <BirthdayCascader />;
                },
            },
        ];
        const behaviourConfig = [
            {
                label: language.getText('scdlmd'),
                key: 'firstLoginSubsiteId',
                display: expand,
                fieldComponent: subsiteSelecter(subsiteOptions),
            },
            {
                label: language.getText('scdlsj'),
                key: 'firstLoginTime',
                display: expand,
                fieldComponent: dateRange(),
            },
            {
                label: language.getText('zhdlsj'),
                key: 'lastLoginTime',
                display: expand,
                fieldComponent: dateRange(),
            },
        ];
        const scanSourceConfig = [
            {
                label: language.getText('smly'),
                key: 'miniProgram',
                display: expand,
                fieldComponent: () => <MiniProgramNameSelect />,
            },
            {
                label: language.getText('zxzj'),
                key: 'registerUtmMerchantName',
                display: expand,
                fieldComponent: input(),
            },
            {
                label: language.getText('zxdgy'),
                key: 'registerUtmGuideUserMobile',
                display: expand,
                fieldComponent: input(),
            },
        ];
        const otherConfig = [
            {
                label: language.getText('wechatOfficialAccount'),
                key: 'officialAccounts',
                display: expand,
                fieldComponent: () => <OfficialAccountSelect />,
            },
            {
                label: language.getText('bq'),
                key: 'memberTags',
                display: expand,
                fieldComponent: () => (
                    <MemberTagsSelect
                        initialTagParams={initialTagParams}
                        subsiteOptions={subsiteOptions}
                    />
                ),
            },
            {
                label: language.getText('peopleName'),
                key: 'memberCrowds',
                display: expand,
                fieldComponent: () => (
                    <MemberCrowdSelect
                        initialCrowdParams={initialCrowdParams}
                        subsiteOptions={subsiteOptions}
                    />
                ),
            },
        ];
        return [
            {
                key: 'basics',
                title: '',
                fields: basicsConfig,
            },
            {
                key: 'register',
                title: language.getText('zcxx'),
                fields: registerConfig,
            },
            {
                key: 'info',
                title: language.getText('baseInfo'),
                fields: infoConfig,
            },
            {
                key: 'behaviour',
                title: language.getText('fwxw'),
                fields: behaviourConfig,
            },
            {
                key: 'scanSource',
                title: language.getText('zxly'),
                fields: scanSourceConfig,
            },
            {
                key: 'other',
                title: language.getText('other'),
                fields: otherConfig,
            },
        ];
    };

    getFields() {
        const config = this.getFieldsConfig();
        const { getFieldDecorator } = this.props.form;
        const { expand } = this.state;
        return config.map((item: any) => {
            return (
                <>
                    {item.title && expand ? (
                        <Col span={24} key={item.key}>
                            <div className="member-manage-search-form__title">{item.title}</div>
                        </Col>
                    ) : null}

                    {item.fields.map((field: any) => {
                        return (
                            <Col
                                span={field.span || 8}
                                key={`field-${field.key}`}
                                style={{
                                    display: field.display ? 'block' : 'none',
                                }}
                            >
                                <Form.Item
                                    className="member-manage-search-form__item"
                                    label={field.label}
                                    labelCol={{ span: 8 }}
                                    labelAlign="right"
                                >
                                    {getFieldDecorator(field.key)(field.fieldComponent())}
                                </Form.Item>
                            </Col>
                        );
                    })}
                </>
            );
        });
    }

    getSearchParams = () => {
        let searchParams: FilterParams = {};
        this.props.form.validateFields((err: any, values: any) => {
            const {
                registerChannel,
                registeDate,
                firstLoginTime,
                lastLoginTime,
                officialAccounts,
                birthdayDate,
                memberTags,
                memberCrowds,
                miniProgram,
                ...others
            } = cloneDeep(values);
            searchParams = { ...others, ...officialAccounts };
            if (!err) {
                if (!isEmpty(registerChannel)) {
                    searchParams.registerChannel = last(registerChannel);
                }
                if (!isEmpty(registeDate)) {
                    let date = registeDate.map((i: any) => i.format('YYYY-MM-DD'));
                    searchParams.registerStartTime = date[0];
                    searchParams.registerEndTime = date[1];
                }
                if (!isEmpty(firstLoginTime)) {
                    let date = firstLoginTime.map((i: any) => i.format('YYYY-MM-DD'));
                    searchParams.firstLoginStartTime = date[0];
                    searchParams.firstLoginEndTime = date[1];
                }
                if (!isEmpty(lastLoginTime)) {
                    let date = lastLoginTime.map((i: any) => i.format('YYYY-MM-DD'));
                    searchParams.lastLoginStartTime = date[0];
                    searchParams.lastLoginEndTime = date[1];
                }
                if (!isEmpty(birthdayDate)) {
                    const { birthdayStartTime, birthdayEndTime } = birthdayDate;
                    if (birthdayStartTime && birthdayStartTime.length > 0) {
                        searchParams.birthdayStartTime = birthdayStartTime.join('-');
                        if (!birthdayEndTime) {
                            searchParams.birthdayEndTime = birthdayStartTime.join('-');
                            this.props.form.setFieldsValue({
                                birthdayDate: {
                                    birthdayStartTime: birthdayStartTime,
                                    birthdayEndTime: birthdayStartTime,
                                },
                            });
                        }
                    }
                    if (birthdayEndTime && birthdayEndTime.length > 0) {
                        searchParams.birthdayEndTime = birthdayEndTime.join('-');
                        if (!birthdayStartTime) {
                            searchParams.birthdayStartTime = birthdayEndTime.join('-');
                            this.props.form.setFieldsValue({
                                birthdayDate: {
                                    birthdayStartTime: birthdayEndTime,
                                    birthdayEndTime: birthdayEndTime,
                                },
                            });
                        }
                    }
                }
                if (!isEmpty(memberTags)) {
                    const [tagSubsiteId, tagId] = memberTags;
                    searchParams.tagSubsiteId = tagSubsiteId;
                    searchParams.tagId = tagId;
                }
                if (!isEmpty(memberCrowds)) {
                    const [, crowdId] = memberCrowds;
                    searchParams.crowdId = crowdId;
                }
                if (!isEmpty(miniProgram)) {
                    const { registerUtmTypeCode, registerUtmItemName } = miniProgram;
                    searchParams.registerUtmTypeCode = registerUtmTypeCode;
                    searchParams.registerUtmItemName = registerUtmItemName;
                }
            }
        });
        return searchParams;
    };

    handleSearch = (e: any) => {
        e.preventDefault();
        const { onSearch } = this.props;
        onSearch(this.getSearchParams());
    };

    handleReset = () => {
        this.props.form.resetFields();
    };

    toggle = () => {
        const { expand } = this.state;
        this.setState({ expand: !expand });
    };

    onExportData = async () => {
        const searchParams = this.getSearchParams();
        try {
            const config = {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                apiPath: '/admin/members/export',
            };

            await api.get(searchParams, config);
            ForwardDataCenterModal();
        } catch (err) {
            services.errorHandle(err as any);
        }
    };

    render() {
        const { expand } = this.state;
        return (
            <Form className="member-manage-search-form" onSubmit={this.handleSearch}>
                <Row type="flex">{this.getFields()}</Row>
                <Row type="flex">
                    <Col span={4}>
                        <Button type="primary" onClick={this.onExportData}>
                            {language.getText('exportSearchResult')}
                        </Button>
                    </Col>
                    <Col span={20} style={{ textAlign: 'right' }}>
                        <Button type="primary" htmlType="submit">
                            {language.getText('common.search')}
                        </Button>
                        <Button style={{ marginLeft: 8 }} onClick={this.handleReset}>
                            {language.getText('common.reset')}
                        </Button>
                        <Button
                            type="link"
                            style={{ marginLeft: 8, fontSize: 12 }}
                            onClick={this.toggle}
                        >
                            {expand ? language.getText('putAway') : language.getText('launch')}{' '}
                            <Icon type={expand ? 'up' : 'down'} />
                        </Button>
                    </Col>
                </Row>
            </Form>
        );
    }
}

const MemberManageFilter = Form.create({ name: 'memberManageSearch' })(MemberManageSearchForm);
export default MemberManageFilter;

function selecter(options: SelectOption[], placeholder = language.getText('selectPlease')) {
    return () => {
        return (
            <Select placeholder={placeholder} allowClear>
                {options.map((item) => {
                    return (
                        <Select.Option key={item.id} value={item.id}>
                            {item.name}
                        </Select.Option>
                    );
                })}
            </Select>
        );
    };
}
function subsiteSelecter(options: SelectOption[], placeholder = language.getText('selectPlease')) {
    return () => {
        return (
            <Select
                placeholder={placeholder}
                allowClear
                showSearch
                optionFilterProp="name"
                filterOption={(input, option: any) =>
                    (option.props.children || '').toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
            >
                {options.map((item) => {
                    return (
                        <Select.Option key={item.id} value={item.id}>
                            {item.name}
                        </Select.Option>
                    );
                })}
            </Select>
        );
    };
}
function input(placeholder = language.getText('inputPlease')) {
    return () => <Input placeholder={placeholder} />;
}
function dateRange() {
    return () => (
        <RangePicker
            placeholder={[
                services.language.getText('startTime'),
                services.language.getText('endTime'),
            ]}
        />
    );
}

class OfficialAccountSelect extends React.Component<
    any,
    {
        officialAccountsOptions?: SelectOption[];
    }
> {
    state = {
        officialAccountsOptions: [],
    };
    componentDidMount() {
        this.getOfficialAccounts();
    }
    getOfficialAccounts = async () => {
        const res: any = await api.get(
            {
                page: 1,
                perPage: 100,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: '/WEB-API/admin/wechat_official_accounts',
            }
        );
        this.setState({ officialAccountsOptions: res?.result });
    };

    onSelectStatus = (status: WechatOfficialAccountFanStatus) => {
        this.props.onChange({ wechatOfficialAccountFanStatus: status });
    };
    onSelectWechatOfficialAccount = (name: string) => {
        const { value } = this.props;
        this.props.onChange({ ...value, wechatOfficialName: name });
    };
    render() {
        const { officialAccountsOptions } = this.state;
        const { value } = this.props;
        const options = [
            {
                id: WechatOfficialAccountFanStatus.SUBSCRIBE,
                name: language.getText('ygz'),
            },
            {
                id: WechatOfficialAccountFanStatus.UN_SUBSCRIBE,
                name: language.getText('wgz'),
            },
        ];
        const disabled =
            value?.wechatOfficialAccountFanStatus !== WechatOfficialAccountFanStatus.SUBSCRIBE;
        return (
            <div className="member-manage-search-form__multistage">
                <Select
                    style={{ width: 150 }}
                    placeholder={services.language.getText('selectPlease')}
                    value={value?.wechatOfficialAccountFanStatus}
                    onChange={this.onSelectStatus}
                    allowClear
                >
                    {options.map((item: any) => {
                        return (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        );
                    })}
                </Select>
                <Select
                    placeholder={services.language.getText('selectPlease')}
                    value={value?.wechatOfficialName}
                    disabled={disabled}
                    onChange={this.onSelectWechatOfficialAccount}
                    allowClear
                >
                    {officialAccountsOptions?.map((item: any) => {
                        return (
                            <Select.Option key={item.id} value={item.name}>
                                {item.name}
                            </Select.Option>
                        );
                    })}
                </Select>
            </div>
        );
    }
}

class MiniProgramNameSelect extends React.Component<any, any> {
    onSelectType = (status: string) => {
        const { value } = this.props;
        this.props.onChange({ ...value, registerUtmTypeCode: status });
    };
    onChangeName = (name: string) => {
        const { value } = this.props;
        this.props.onChange({ ...value, registerUtmItemName: name });
    };
    render() {
        const { value } = this.props;
        const options = [
            {
                id: 'MINI_PROGRAM_CODE',
                name: language.getText('qdtgm'),
            },
            {
                id: 'WECHAT_OFFIACCOUNT_QR_MSG',
                name: language.getText('wechatOfficialParametricQrcode'),
            },
        ];
        return (
            <div className="member-manage-search-form__multistage">
                <Select
                    style={{ width: 200 }}
                    placeholder={services.language.getText('selectPlease')}
                    value={value?.registerUtmTypeCode}
                    onChange={this.onSelectType}
                    allowClear
                >
                    {options.map((item: any) => {
                        return (
                            <Select.Option key={item.id} value={item.id}>
                                {item.name}
                            </Select.Option>
                        );
                    })}
                </Select>
                <Input
                    placeholder={services.language.getText('inputPlease')}
                    value={value?.registerUtmItemName}
                    onChange={(e) => this.onChangeName(e.target.value)}
                />
            </div>
        );
    }
}
class BirthdayCascader extends React.Component<any, any> {
    birthdayStartTimeChange = (startTime: string[]) => {
        const { value } = this.props;
        this.props.onChange({
            ...value,
            birthdayStartTime: startTime && startTime.length ? startTime : undefined,
        });
    };

    birthdayEndTimeChange = (endTime: string[]) => {
        const { value } = this.props;
        this.props.onChange({
            ...value,
            birthdayEndTime: endTime && endTime.length ? endTime : undefined,
        });
    };

    render() {
        const { value } = this.props;
        return (
            <div className="member-manage-search-form__multistage">
                <div>
                    <Cascader
                        value={value?.birthdayStartTime}
                        options={monthOptions.map((month) => ({
                            value: month.value,
                            label: month.label,
                            children: Array.from({ length: month.dayNumber }, (_, index) => ({
                                value: String(index + 1).padStart(2, '0'),
                                label: String(index + 1).padStart(2, '0'),
                            })),
                        }))}
                        onChange={this.birthdayStartTimeChange}
                        placeholder={language.getText('ksrq')}
                        displayRender={(labels) => labels.join(' - ')}
                    />
                </div>
                <div className="member-manage-search-form__multistage__division">~</div>
                <div>
                    <Cascader
                        value={value?.birthdayEndTime}
                        options={monthOptions.map((month) => {
                            return {
                                value: month.value,
                                label: month.label,
                                children: Array.from({ length: month.dayNumber }, (_, index) => ({
                                    value: String(index + 1).padStart(2, '0'),
                                    label: String(index + 1).padStart(2, '0'),
                                })),
                            };
                        })}
                        onChange={this.birthdayEndTimeChange}
                        placeholder={language.getText('jsrq')}
                        displayRender={(labels) => labels.join(' - ')}
                    />
                </div>
            </div>
        );
    }
}

const monthOptions = [
    { value: '01', label: '01', dayNumber: 31 },
    { value: '02', label: '02', dayNumber: 29 },
    { value: '03', label: '03', dayNumber: 31 },
    { value: '04', label: '04', dayNumber: 30 },
    { value: '05', label: '05', dayNumber: 31 },
    { value: '06', label: '06', dayNumber: 30 },
    { value: '07', label: '07', dayNumber: 31 },
    { value: '08', label: '08', dayNumber: 31 },
    { value: '09', label: '09', dayNumber: 30 },
    { value: '10', label: '10', dayNumber: 31 },
    { value: '11', label: '11', dayNumber: 30 },
    { value: '12', label: '12', dayNumber: 31 },
];

class MemberTagsSelect extends React.Component<
    any,
    {
        options?: any[];
    }
> {
    state = {
        options: [],
    };

    componentWillReceiveProps(nextProps: any) {
        const { subsiteOptions } = this.props;
        const { subsiteOptions: nextSubsiteOptions } = nextProps;
        if (nextSubsiteOptions && !isEqual(nextSubsiteOptions, subsiteOptions)) {
            this.initTagOption(nextProps);
        }
    }

    initTagOption = async (props: any) => {
        const { subsiteOptions, initialTagParams } = props;
        const options = subsiteOptions.map((item: any) => {
            return {
                value: item.id,
                label: item.name,
                isLeaf: false,
            };
        });
        if (initialTagParams && initialTagParams.tagSubsiteId) {
            this.props.onChange([initialTagParams.tagSubsiteId, initialTagParams.tagId]);
            const childrenOption = await this.getTags(initialTagParams.tagSubsiteId);
            const subsite = find(options, { value: initialTagParams.tagSubsiteId });
            subsite.children = childrenOption;
            this.setState({
                options,
            });
        } else {
            this.setState({
                options,
            });
        }
    };

    getTags = async (subsiteId: string) => {
        try {
            const res: any = await api.get(
                {
                    page: 1,
                    perPage: 500,
                    subsiteId,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                    apiPath: '/admin/tags',
                }
            );
            return this.format(res.result) || [];
        } catch (error) {
            return [];
        }
    };

    format = (data: any[]) => {
        return data.map((item) => ({
            value: item.id,
            label: item.tagName,
            isLeaf: true,
        }));
    };

    onLoadData = async (selectedOptions: any) => {
        const { options } = this.state;
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;
        const childrenOption = await this.getTags(targetOption.value);
        targetOption.loading = false;
        targetOption.children = childrenOption;
        this.setState({
            options: [...options],
        });
    };

    onChange = (memberTags: string[]) => {
        this.props.onChange(memberTags);
    };

    render() {
        const { value } = this.props;
        const { options } = this.state;
        return (
            <div className="member-manage-search-form__multistage">
                <Cascader
                    value={value}
                    options={options}
                    loadData={this.onLoadData}
                    onChange={this.onChange}
                    changeOnSelect
                ></Cascader>
            </div>
        );
    }
}

class MemberCrowdSelect extends React.Component<
    any,
    {
        options?: any[];
    }
> {
    state = {
        options: [],
    };

    componentWillReceiveProps(nextProps: any) {
        const { subsiteOptions } = this.props;
        const { subsiteOptions: nextSubsiteOptions } = nextProps;
        if (nextSubsiteOptions && !isEqual(nextSubsiteOptions, subsiteOptions)) {
            this.initCrowdOption(nextProps);
        }
    }

    initCrowdOption = async (props: any) => {
        const { subsiteOptions, initialCrowdParams } = props;
        const options = subsiteOptions.map((item: any) => {
            return {
                value: item.id,
                label: item.name,
                isLeaf: false,
            };
        });
        if (initialCrowdParams && initialCrowdParams.crowdSubsiteId) {
            this.props.onChange([initialCrowdParams.crowdSubsiteId, initialCrowdParams.crowdId]);
            const childrenOption = await this.getCrowds(initialCrowdParams.crowdSubsiteId);
            const subsite = find(options, { value: initialCrowdParams.crowdSubsiteId });
            subsite.children = childrenOption;
            this.setState({
                options,
            });
        } else {
            this.setState({
                options,
            });
        }
    };

    getCrowds = async (subsiteId: string) => {
        try {
            const res: any = await api.get(
                {
                    page: 1,
                    perPage: 500,
                    subsiteId,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                    apiPath: '/admin/crowds',
                }
            );
            return this.format(res.result) || [];
        } catch (error) {
            return [];
        }
    };

    format = (data: any[]) => {
        return data.map((item) => ({
            value: item.id,
            label: item.crowdName,
            isLeaf: true,
        }));
    };

    onLoadData = async (selectedOptions: any) => {
        const { options } = this.state;
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;
        const childrenOption = await this.getCrowds(targetOption.value);
        targetOption.loading = false;
        targetOption.children = childrenOption;
        this.setState({
            options: [...options],
        });
    };

    onChange = (memberCrowds: string[]) => {
        if (memberCrowds && memberCrowds.length === 1) {
            return;
        }
        this.props.onChange(memberCrowds);
    };

    render() {
        const { value } = this.props;
        const { options } = this.state;
        return (
            <div className="member-manage-search-form__multistage">
                <Cascader
                    value={value}
                    options={options}
                    loadData={this.onLoadData}
                    onChange={this.onChange}
                ></Cascader>
            </div>
        );
    }
}
