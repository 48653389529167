import React, { Component } from 'react';
import { message, Button, Icon, Select, Popover, Radio } from 'antd';
import { cloneDeep, find, remove, isUndefined, get, filter, maxBy } from 'lodash';
import { services } from '@comall-backend-builder/core';

import DragTable from '../../../../components/drag-table';
import { GoodsSelectorModal } from '../../../../components/goods-selector-modal';

// import { StyleGroupProductSelector } from './style-group-product-selector';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const { api } = services;

interface Attribute {
    id?: number;
    name?: string;
}

interface StyleGroup {
    frontName: string | undefined;
    name?: string;
    code?: string;
    goodsId?: string;
    productId?: string;
    optionIds: number[];
    subsiteId: string;
    merchantId: string;
    sort?: number;
    id: number;
    mainGoods?: boolean;
}

interface StyleSetting extends Attribute {
    //完整的规格值
    allOptions?: Attribute[];
    //搜索实时的规格值
    options?: Attribute[];
    popoverVisible?: boolean;
    selectedOptions?: Attribute[];
    optionKeyword?: string;
}

export interface FeedbackTypeProps {
    value: StyleGroup[] | undefined;
    row: any;
    pageType: string;
    /**
     * 内容改变回调
     * @param value 新值
     */
    onChange: (value: StyleGroup[] | undefined) => void;
}

// let GoodsEntity: any = null;
// let goodsEntity: any = null;
export class StyleGroupSetting extends Component<FeedbackTypeProps, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            attributes: [],
            searchKeyword: '',
            styleSettings: [],
        };
        // GoodsEntity = EntitiesManager.get('GoodsSelectorEntity');
        // goodsEntity = new GoodsEntity({});
    }

    componentDidMount(): void {
        this.loadAttributes();
    }

    componentWillReceiveProps(nextProps: Readonly<FeedbackTypeProps>, nextContext: any): void {
        const { row, pageType } = this.props;
        const { row: nextRow } = nextProps;
        const attributeOptions = get(row, 'styleGroupInfo.attributeOptions');
        const nextAttributeOptions = get(nextRow, 'styleGroupInfo.attributeOptions');
        /**
         * 编辑时初始化styleSettings
         */
        if (!attributeOptions && nextAttributeOptions && nextAttributeOptions.length) {
            const styleSettings: StyleSetting[] = [];
            nextAttributeOptions.forEach((item: any) => {
                const newOptions = item.options.map((option: any) => {
                    return { id: option.optionId, name: option.optionValue, select: option.select };
                });
                const selectedOptions = filter(newOptions, { select: true });
                styleSettings.push({
                    id: item.attributeId,
                    name: item.backendlabel,
                    allOptions: newOptions,
                    options: newOptions,
                    selectedOptions: selectedOptions,
                });
            });
            this.setState({ styleSettings });
        }

        let subsiteId = get(row, 'baseInfo.subsite.id');
        let nexSubsiteId = get(nextRow, 'baseInfo.subsite.id');
        let merchantId: string = '';
        let nextMerchantId: string = '';
        /**
         * 门店或者专柜变化时清空商品
         */
        if (pageType === 'subsite' && subsiteId && subsiteId !== nexSubsiteId) {
            this.clearProduct(nextProps);
        }
        if (pageType === 'merchant') {
            const merchants = get(row, 'baseInfo.merchant');
            if (merchants && merchants.length) {
                merchantId = merchants[0].merchantId;
            }
            const nextMerchants = get(nextRow, 'baseInfo.merchant');
            if (nextMerchants && nextMerchants.length) {
                nextMerchantId = nextMerchants[0].merchantId;
            }
            if (merchantId && merchantId !== nextMerchantId) {
                this.clearProduct(nextProps);
            }
        }
    }

    clearProduct = (nextProps: any) => {
        let { value, onChange } = nextProps;
        if (value && value.length) {
            value = value.map((item: any) => {
                return {
                    ...item,
                    name: '',
                    code: '',
                    goodsId: '',
                    productId: '',
                };
            });
            onChange(value);
        }
    };

    /**
     * 加载规格项
     * @param name
     */
    loadAttributes = (name?: string) => {
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
            apiPath: '/admin/style_group/attributes',
        };
        api.get({ backendlabel: name }, config).then((res: any) => {
            this.setState({
                attributes: res,
                searchKeyword: name,
            });
        });
    };

    /**
     * 加载规格项的规格值
     */
    loadAttributeOptions = (
        attributeId: number,
        styleSettings: StyleSetting[],
        keyword?: string
    ) => {
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
            apiPath: '/admin/style_group/option',
        };
        api.get({ attributeId, name: keyword }, config).then((res: any) => {
            let item: any = find(styleSettings, { id: Number(attributeId) }) || {};
            item.options = res;
            if (!keyword) {
                item.allOptions = res;
            }
            item.optionKeyword = keyword;
            this.setState({ styleSettings });
        });
    };

    /**
     * 选中规格项
     */
    selectedStyle = (id: number, index?: number) => {
        const { styleSettings } = this.state;

        const newStyleSettings =
            styleSettings && styleSettings.length ? cloneDeep(styleSettings) : [];
        if (id === -1) {
            //id为-1，进行新增规则
            const { searchKeyword } = this.state;
            const config = {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: `/admin/style_group/attributes`,
            };

            api.post({ name: searchKeyword }, config).then((res: any) => {
                if (!isUndefined(index)) {
                    newStyleSettings[index] = res;
                } else {
                    newStyleSettings.push(res);
                }

                this.loadAttributeOptions(res.id, newStyleSettings);
                this.loadAttributes();
            });
        } else {
            const { attributes } = this.state;
            let item: any = find(attributes, { id: Number(id) }) || {};
            let hasItem: any = find(newStyleSettings, { id: Number(id) });
            if (hasItem) {
                message.error(language.getText('specialValueNoRepeat'));
                return;
            } else {
                if (!isUndefined(index)) {
                    newStyleSettings[index] = item;
                } else {
                    newStyleSettings.push(item);
                }
                this.loadAttributeOptions(id, newStyleSettings);
            }
        }
    };
    /**
     * 添加规格项
     */
    addStyleSettings = () => {
        const { styleSettings } = this.state;
        const newStyleSettings = styleSettings && styleSettings.length ? styleSettings : [{}];
        newStyleSettings.push({});
        this.setState({ StyleSettings: newStyleSettings });
    };

    /**
     * 确定选中规格值
     * @param id
     * @returns
     */
    addStyleOption = (id: number) => {
        const { styleSettings } = this.state;
        const newStyleSettings = styleSettings && styleSettings.length ? styleSettings : [];
        const item = find(newStyleSettings, { id });
        if (item) {
            const { currentSelectedOptionIds } = this.state;
            let newSelectedOptions =
                item.selectedOptions && item.selectedOptions.length ? item.selectedOptions : [];
            if (!currentSelectedOptionIds || !currentSelectedOptionIds.length) {
                message.error(language.getText('selectSpecialValue'));
                return;
            }
            let flag = false;
            currentSelectedOptionIds.forEach((optionId: string) => {
                const hasOption = find(item.allOptions, { id: Number(optionId) });
                const hasSelectedOption = find(item.selectedOptions, { id: Number(optionId) });
                if (hasSelectedOption) {
                    flag = true;
                }
                if (!hasSelectedOption && hasOption) {
                    newSelectedOptions.push(hasOption);
                }
            });
            if (flag) {
                message.error(language.getText('specialValueRepeat'));
            }
            item.selectedOptions = newSelectedOptions;
            item.popoverVisible = false;
            item.optionKeyword = '';
            this.setState({ currentSelectedOptionIds: [], styleSettings: newStyleSettings });
        }
    };

    deleteStyleSetting = (id: number) => {
        const { styleSettings } = this.state;
        const newStyleSettings = filter(styleSettings, function(i) {
            return i.id !== id;
        });
        this.setState({ styleSettings: newStyleSettings });
    };

    /**
     * 下拉框选中规格值
     * @param optionIds
     * @param styleId
     */
    selectedStyleOption = (optionIds: string[], id: number) => {
        const { styleSettings } = this.state;
        const item = find(styleSettings, { id });
        const newOptionIds: any[] = [];
        let addName = '';
        optionIds.forEach((optionId: string) => {
            const option = find(item.allOptions, { id: Number(optionId) });
            const searchOption = find(item.options, { id: Number(optionId) });
            /**
             * 如果选项不存在则新增
             */
            if (!option && !searchOption) {
                addName = optionId.trim();
            } else {
                newOptionIds.push(optionId);
            }
            if (!option && searchOption) {
                item.allOptions.push(searchOption);
            }
        });

        if (addName) {
            const config = {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: `/admin/style_group/${id}/options`,
            };
            api.put({ name: addName }, config).then((res: any) => {
                item.allOptions.push(res);
                item.options = item.allOptions;
                newOptionIds.push(String(res.id));

                this.setState({ currentSelectedOptionIds: newOptionIds, styleSettings });
            });
        } else {
            item.options = item.allOptions;
            this.setState({ currentSelectedOptionIds: newOptionIds });
        }
    };

    /**
     * 删除选中的规格值
     * @param id
     * @param option
     */
    deleteSelectedOption = (id: number, option: any) => {
        const { styleSettings } = this.state;
        const newStyleSettings = styleSettings && styleSettings.length ? styleSettings : [{}];
        const item = find(newStyleSettings, { id: Number(id) });
        if (item && item.selectedOptions) {
            remove(item.selectedOptions, option);
        }
        this.setState({ styleSettings: newStyleSettings });
    };

    /**
     * 切换规格值气泡框
     */
    toggleOptionPopover = (id: number) => {
        const { styleSettings } = this.state;
        const newStyleSettings = styleSettings && styleSettings.length ? styleSettings : [];
        const item = find(newStyleSettings, { id: Number(id) });
        if (item) {
            item.popoverVisible = !item.popoverVisible;
            this.setState({ styleSettings: newStyleSettings });
        }
    };

    /**
     * 生成一品多款组
     */
    updateStyleGroup = () => {
        const { onChange, row, pageType } = this.props;
        const { styleSettings } = this.state;
        if (pageType === 'subsite' && row && row.baseInfo && !row.baseInfo.subsite) {
            message.error(language.getText('selectSubsite'));
            return false;
        }
        if (pageType === 'merchant' && row && row.baseInfo && !row.baseInfo.merchant) {
            message.error(language.getText('pleaseSelectMerchant'));
            return false;
        }
        if (styleSettings && styleSettings.length > 0) {
            let styleGroup: StyleGroup[] = [];
            let flag = true;
            for (let index = 0; index < styleSettings.length; index++) {
                const styleSetting = styleSettings[index];
                if (
                    styleSetting.id &&
                    styleSetting.selectedOptions &&
                    styleSetting.selectedOptions.length
                ) {
                    styleGroup = this.handleOptions(styleGroup, styleSetting.selectedOptions);
                } else {
                    flag = false;

                    break;
                }
            }
            if (!flag) {
                message.error(language.getText('fillInAllSpecial'));
                return;
            }
            onChange(styleGroup);
        } else {
            message.error(language.getText('fillInSpecialItem'));
            return;
        }
    };
    handleOptions = (styleGroup: StyleGroup[], selectedOptions: Attribute[]) => {
        const { row, pageType } = this.props;
        let subsiteId = get(row, 'baseInfo.subsite.id');
        let merchantId: string = '';
        if (pageType === 'merchant' && row && row.baseInfo) {
            const merchants = get(row, 'baseInfo.merchant');
            if (merchants && merchants.length) {
                subsiteId = merchants[0].subSiteId;
                merchantId = merchants[0].merchantId;
            }
        }

        if (styleGroup && styleGroup.length) {
            const newStyleGroup: StyleGroup[] = [];
            let index = 1;

            styleGroup.forEach((groupItem) => {
                selectedOptions.forEach((item: Attribute) => {
                    const ids = groupItem.optionIds ? cloneDeep(groupItem.optionIds) : [];
                    if (item.id) {
                        ids.push(item.id);
                    }
                    const newItem = {
                        frontName: groupItem.frontName + '-' + item.name,
                        optionIds: ids,
                        subsiteId,
                        merchantId: merchantId ? merchantId : '0',
                        sort: index,
                        id: index,
                        mainGoods: index === 1,
                    };
                    newStyleGroup.push(newItem);
                    index++;
                });
            });
            return newStyleGroup;
        } else {
            selectedOptions.forEach((item: Attribute, index: number) => {
                const optionIds = item.id ? [item.id] : [];
                styleGroup.push({
                    frontName: item.name,
                    optionIds,
                    subsiteId,
                    merchantId: merchantId ? merchantId : '0',
                    sort: index + 1,
                    id: index + 1,
                    mainGoods: index === 0,
                });
            });
            return styleGroup;
        }
    };

    onSortChange = (data: any[]) => {};

    /**
     * 选择商品
     */
    productChange = (index: number, val: any) => {
        const { barcode, name, id, goodsMvo } = val;
        const { onChange, value } = this.props;
        const newValue = value && value.length ? value : [];
        for (let i = 0; i < newValue.length; i++) {
            if (i === index) {
                newValue[i] = {
                    ...newValue[i],
                    code: barcode,
                    name,
                    goodsId: id,
                    productId: goodsMvo?.productId,
                };
            }
        }
        onChange(newValue);
    };
    /**
     * 设置主款
     */
    setValueSort = (record: any) => {
        const { value, onChange } = this.props;
        const newValue = value && value.length ? cloneDeep(value) : [];
        // 如果非主款的sort也为1，则先为非主款调整sort
        if (record.sort === 1) {
            const maxRecord = maxBy(newValue, (o) => {
                return o.sort;
            });
            record.sort = maxRecord && maxRecord.sort ? maxRecord.sort + 1 : 999;
        }

        newValue.forEach((item) => {
            if (item.mainGoods) {
                //将主款的sort交换
                item.sort = record.sort;
            }
            /**
             * 设置新的主款,主款sort设置为1
             */
            if (item.id === record.id) {
                item.mainGoods = true;
                item.sort = 1;
            } else {
                item.mainGoods = false;
            }
        });
        onChange(newValue);
    };

    renderSelect = (styleOption?: any, index?: number) => {
        const { attributes, searchKeyword } = this.state;
        const hasSearchKeyword = find(attributes, { name: searchKeyword });
        return (
            <Select
                className="style-selector"
                placeholder={language.getText('selectPlease')}
                onChange={(id: number) => {
                    this.selectedStyle(id, index);
                }}
                value={styleOption?.id}
                autoClearSearchValue
                showSearch
                filterOption={false}
                onSearch={this.loadAttributes}
                onFocus={this.loadAttributes}
            >
                {!hasSearchKeyword && searchKeyword && (
                    <Select.Option value={-1}>{searchKeyword}</Select.Option>
                )}

                {attributes &&
                    attributes.map((attribute: any) => {
                        return (
                            <Select.Option key={attribute.id} value={attribute.id}>
                                {attribute.name}
                            </Select.Option>
                        );
                    })}
            </Select>
        );
    };

    onSearch = (attributeId: number, styleSettings: StyleSetting[], keywrods?: string) => {
        if (!keywrods) {
            let item: any = find(styleSettings, { id: Number(attributeId) }) || {};
            item.options = item.allOptions;
            item.optionKeyword = keywrods;
            this.setState({ styleSettings });
            return;
        }
        this.loadAttributeOptions(attributeId, styleSettings, keywrods);
    };

    renderAddStyleOption = (item: any) => {
        const { currentSelectedOptionIds, styleSettings } = this.state;

        const newStyleSettings = styleSettings && styleSettings.length ? styleSettings : [];
        if (item) {
            const hasSearchKeyword = find([...item.allOptions, ...item.options], {
                name: item.optionKeyword,
            });
            return (
                <div className="style-settings-popover">
                    <Select
                        style={{ width: '200px' }}
                        placeholder={language.getText('selectPlease')}
                        mode="multiple"
                        onChange={(ids: string[]) => {
                            this.selectedStyleOption(ids, item.id);
                        }}
                        autoClearSearchValue
                        showSearch
                        filterOption={false}
                        value={currentSelectedOptionIds}
                        onSearch={(keyword: string) => {
                            this.onSearch(item.id, newStyleSettings, keyword);
                        }}
                        onFocus={() => {
                            this.onSearch(item.id, newStyleSettings);
                        }}
                    >
                        {!hasSearchKeyword && item.optionKeyword && (
                            <Select.Option value={item.optionKeyword}>
                                {item.optionKeyword}
                            </Select.Option>
                        )}

                        {item.options &&
                            item.options.map((attribute: any) => {
                                return (
                                    <Select.Option key={attribute.id} value={String(attribute.id)}>
                                        {attribute.name}
                                    </Select.Option>
                                );
                            })}
                    </Select>
                    <Button
                        className="popover-btn"
                        type="default"
                        onClick={() => {
                            this.toggleOptionPopover(item.id);
                        }}
                    >
                        {language.getText('common.cancel')}
                    </Button>
                    <Button
                        className="popover-btn"
                        type="primary"
                        onClick={() => {
                            this.addStyleOption(item.id);
                        }}
                    >
                        {language.getText('common.add')}
                    </Button>
                </div>
            );
        } else {
            return null;
        }
    };

    renderTableDisplay = () => {
        const { value, row, pageType } = this.props;
        let subsiteId = get(row, 'baseInfo.subsite.id');
        let merchantId: string = '';
        if (pageType === 'merchant' && row && row.baseInfo) {
            const merchants = get(row, 'baseInfo.merchant');
            if (merchants && merchants.length) {
                subsiteId = merchants[0].subSiteId;
                merchantId = merchants[0].merchantId;
            }
        }

        const filterFields = ['keywords', 'subsiteId', 'merchantId', 'supplyChain', 'tradeType'];
        const columns = [
            'productInfo',
            'goodsMvo.stock',
            'goodsMvo.productStyleName',
            'goodsMvo.subsiteName',
            'goodsMvo.merchantName',
            'tradeType',
        ];

        const tableConfig = {
            rowKey: 'id',
            columns: [
                {
                    title: language.getText('styleSpecial'),
                    dataIndex: 'frontName',
                    key: 'frontName',
                },
                {
                    title: services.language.getText('components.Products.name'),
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: services.language.getText('productCode'),
                    dataIndex: 'code',
                    key: 'code',
                },

                {
                    title: services.language.getText('common.tableAction'),
                    key: 'action',
                    render: (text: any, record: any, index: number) => {
                        return (
                            <GoodsSelectorModal
                                modalTitle={services.language.getText('select')}
                                buttonText={services.language.getText('xzsp_1')}
                                params={{
                                    subsiteIds: subsiteId,
                                    merchantIds: merchantId ? merchantId : '0',
                                    shelfStatus: 'NONE,OFF_SHELF,SHELF',
                                }}
                                rowSelectionType="radio"
                                onChange={(item: any) => {
                                    const good = item?.length ? item[0] : item;
                                    this.productChange(index, good);
                                }}
                                filterFields={filterFields}
                                columns={columns}
                            />
                        );
                    },
                },
                {
                    title: language.getText('isMainStyle'),
                    dataIndex: 'mainGoods',
                    key: 'mainGoods',
                    render: (text: any, record: any, index: number) => {
                        return (
                            <div>
                                <Radio
                                    checked={text}
                                    onClick={() => {
                                        this.setValueSort(record);
                                    }}
                                ></Radio>
                            </div>
                        );
                    },
                },
            ],
        };
        const tableProps = { ...tableConfig, dataSource: value };
        return (
            <div>
                <DragTable
                    {...tableProps}
                    onUpdateData={this.onSortChange}
                    pagination={false}
                ></DragTable>
            </div>
        );
    };

    render() {
        const { value } = this.props;
        const { styleSettings } = this.state;
        return (
            <div className="style-settings">
                <div className="style-settings-wrap">
                    {styleSettings && styleSettings.length ? (
                        <div>
                            {styleSettings.map((styleSetting: any, index: number) => {
                                return (
                                    <div className="style-wrap" key={`wrap_${index}`}>
                                        <div className="style-selector-header">
                                            {this.renderSelect(styleSetting, index)}
                                            <Icon
                                                type="close"
                                                className="style-setting-delete"
                                                onClick={() => {
                                                    this.deleteStyleSetting(styleSetting.id);
                                                }}
                                            />
                                        </div>
                                        <div className="style-option-wrap">
                                            {styleSetting.selectedOptions &&
                                                styleSetting.selectedOptions.length > 0 &&
                                                styleSetting.selectedOptions.map(
                                                    (option: Attribute, index: number) => {
                                                        return (
                                                            <div
                                                                key={`option_${index}`}
                                                                className="option-item"
                                                            >
                                                                {option.name}
                                                                <Icon
                                                                    type="close"
                                                                    className="option-item-delete"
                                                                    onClick={() => {
                                                                        this.deleteSelectedOption(
                                                                            styleSetting.id,
                                                                            option
                                                                        );
                                                                    }}
                                                                />
                                                            </div>
                                                        );
                                                    }
                                                )}
                                        </div>
                                        {styleSetting.id && (
                                            <div className="style-option-add">
                                                <Popover
                                                    placement={'bottom'}
                                                    trigger="click"
                                                    content={this.renderAddStyleOption(
                                                        styleSetting
                                                    )}
                                                    visible={styleSetting.popoverVisible}
                                                >
                                                    <Button
                                                        type="link"
                                                        onClick={() => {
                                                            this.toggleOptionPopover(
                                                                styleSetting.id
                                                            );
                                                        }}
                                                    >
                                                        +{language.getText('addSpecialValue')}
                                                    </Button>
                                                </Popover>
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <div>
                            <div className="style-selector-header">{this.renderSelect()}</div>
                        </div>
                    )}
                    <Button
                        className="style-add-button"
                        type="default"
                        onClick={this.addStyleSettings}
                    >
                        {language.getText('addSpecialItem')}
                    </Button>
                </div>
                <div className="style-save-wrap">
                    <Button type="primary" onClick={this.updateStyleGroup}>
                        {language.getText('generateMultiStyleProduct')}
                    </Button>
                </div>
                {value && value.length && this.renderTableDisplay()}
            </div>
        );
    }
}
