import React, { PureComponent } from 'react';
import { Form, Radio } from 'antd';
import { tools } from '@comall-backend-builder/design-components-basis';
import { services } from '@comall-backend-builder/core';

const FormItem = Form.Item;
const UploadPicture = tools.UploadPicture;
const ColorPicker = tools.ColorPicker;
/**
 * 会员卡组件-配置表单
 */
class memberCover extends PureComponent<any> {
    render() {
        const { form, sourceRoot, uploadAction } = this.props;

        const { getFieldDecorator } = form;
        const formItemLayout = tools.FormItemLayout;

        return (
            <Form className="editor-member-card">
                <FormItem {...formItemLayout} label={services.language.getText('bgPic')}>
                    {getFieldDecorator('bgImage')(
                        <UploadPicture
                            className="image-uploader"
                            sourceRoot={sourceRoot}
                            uploadAction={uploadAction}
                        />
                    )}
                    <p className="upload-picture-tips">
                        图片建议宽度为750px，高度为{ENV.TENANT === 'hxgc' ? '226' : '140'}px
                    </p>
                </FormItem>
                {/* <FormItem {...formItemLayout} label={'卡面文字'}>
                    <div>
                        {getFieldDecorator(
                            'title',
                            {}
                        )(<Input maxLength={13} style={{ width: 250 }} />)}
                    </div>
                </FormItem> */}
                <FormItem {...formItemLayout} label={'等级名称'}>
                    {getFieldDecorator(
                        'levelShow',
                        {}
                    )(
                        <Radio.Group>
                            <Radio value={'show'}>{services.language.getText('display')}</Radio>
                            <Radio value={'hide'}>{services.language.getText('hidden')}</Radio>
                        </Radio.Group>
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label={'进度条'}>
                    {getFieldDecorator(
                        'progressBarShow',
                        {}
                    )(
                        <Radio.Group>
                            <Radio value={'show'}>{services.language.getText('display')}</Radio>
                            <Radio value={'hide'}>{services.language.getText('hidden')}</Radio>
                        </Radio.Group>
                    )}
                </FormItem>
                {/* {(ENV.TENANT === 'hxgc' || ENV.TENANT === 'localhost') && (
                    <FormItem {...formItemLayout} label={'会员码'}>
                        {getFieldDecorator(
                            'codeShow',
                            {}
                        )(
                            <Radio.Group>
                                 <Radio value={'show'}>{services.language.getText('display')}</Radio>
                                 <Radio value={'hide'}>{services.language.getText('hidden')}</Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                )} */}

                <FormItem {...formItemLayout} label={'进度条颜色'}>
                    {getFieldDecorator('progressBarColor', {})(<ColorPicker />)}
                </FormItem>
            </Form>
        );
    }
}

export const MemberCover = tools.ConfigFormDecorator(memberCover);
