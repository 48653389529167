import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { ArrayOptionsAutoCompletePropertiesCoupon } from './array-options-auto-complete-properties-coupon';

export class ArrayOptionsAutoCompletePropertiesCouponMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        if (value && value.length) {
            return (
                <div className="type-array mode-array-options-auto-complete">
                    {value.map((v: any) => {
                        return (
                            <div key={v.id} className="string-type">
                                {v.name}
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return super.getNotAvailableDisplayComponent(displayInfo);
        }
    }

    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <ArrayOptionsAutoCompletePropertiesCoupon {...displayInfo} />;
    }
}
