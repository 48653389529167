import React, { PureComponent } from 'react';
import { Button as AntButton, Checkbox, message as AntMessage, Modal as AntModal } from 'antd';
import { services } from '@comall-backend-builder/core';
import { errorHandle } from '../../../applications/cae/services/error-handle';
import {
    customSelectorConfig,
    newClientCustomSelectorConfig,
} from '../../../applications/design/design';
import {
    getStaffNewLinkTypeEffectPathConfigList,
    getMerchantNewLinkTypeEffectPathConfigList,
} from '../../../components/link-type-effect-path/config';
import { LinkTypePlus } from '../../../applications/design/components';
import get from 'lodash/get';
import { find, isEmpty, isNull, isString, isUndefined, map } from 'lodash';
import { SubsiteLinkTypeSelectorValue } from '../../../types/mode/object/subsite-link-type-selector/subsite-link-type-selector';

const api = services.api;

interface MiniProgramCodeDefaultLinkEditButtonState {
    changeTargetVisible: boolean; //修改目标页面
    changeTargetValue: SubsiteLinkTypeSelectorValue;
    updateGuideCodeLink: boolean;
}

export function linkNameGen(name: string, linkParams: any): string {
    return [name, linkParams && linkParams.name].filter(Boolean).join('-');
}

export function linkPathGen(basePath: string, params?: string | string[], paramsObj?: any): string {
    if (!params || !paramsObj) {
        return basePath;
    }

    const paramsStr = [params].flat(3).reduce((result, key) => {
        const value = paramsObj[key];
        if (isNull(value) || isUndefined(value) || (isString(value) && value.length === 0)) {
            return result;
        }
        return `${result}&${key}=${value}`;
    }, '');

    if (!paramsStr) {
        return basePath;
    }

    return /\?/.test(basePath) ? `${basePath}${paramsStr}` : `${basePath}?${paramsStr.slice(1)}`;
}

export function transformParams(linkParams: any, paramsHandler?: Function): any {
    if (!linkParams || !paramsHandler) {
        return linkParams;
    }

    return paramsHandler(linkParams);
}

/**
 * 导出数据
 */
export class MiniProgramCodeDefaultLinkEditButton extends PureComponent<
    any,
    MiniProgramCodeDefaultLinkEditButtonState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            changeTargetVisible: false,
            changeTargetValue: {
                linkType: '',
                linkParams: null,
                linkName: '',
                linkPath: '',
                needUrlLink: false,
            }, //批量修改目标页面target
            updateGuideCodeLink: false,
        };
    }

    staffNewLinkTypeEffectPathConfigList = getStaffNewLinkTypeEffectPathConfigList();
    merchantNewLinkTypeEffectPathConfigList = getMerchantNewLinkTypeEffectPathConfigList();

    getPathConfigList = () => {
        const { entity } = this.props;
        return entity.params.source === 'MERCHANT_CODE'
            ? this.merchantNewLinkTypeEffectPathConfigList
            : this.staffNewLinkTypeEffectPathConfigList;
    };

    submitChangeTarget = () => {
        const { changeTargetValue, updateGuideCodeLink } = this.state;
        const { entity, row } = this.props;
        const linkType = get(changeTargetValue, 'linkType');
        const linkParams = get(changeTargetValue, 'linkParams');
        const configKeys = Object.keys(customSelectorConfig) || [];
        let flag = true;
        const noLinkParams = ['subsite', 'newTopic'];
        map([...configKeys, ...noLinkParams], (item) => {
            if (linkType === item && isEmpty(linkParams)) {
                flag = false;
            }
        });
        if (!flag) {
            AntMessage.warning(services.language.getText('selectLinkTarget'));
            return false;
        }
        const apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        const apiPath = '/admin/mini_program_code_default_links/' + row.id;
        const params = {
            target: JSON.stringify(changeTargetValue),
            type: linkType,
            updateCodeLink: updateGuideCodeLink,
            ...entity.params,
        };
        api.put(params, { apiRoot, apiPath })
            .then(() => {
                AntMessage.success(services.language.getText('plxgmbycg'));
                this.setState({
                    changeTargetVisible: false,
                });
                entity.search();
            })
            .catch((error) => {
                errorHandle(error);
            });
    };

    onChangeUpdateGuideCodeLink = (value: any) => {
        this.setState({
            updateGuideCodeLink: value && value.length ? true : false,
        });
    };

    renderButtonsModal = () => {
        const { changeTargetVisible, changeTargetValue, updateGuideCodeLink } = this.state;
        const { row, entity } = this.props;
        if (!row) {
            return null;
        }
        const linkTypeSelector = newClientCustomSelectorConfig;
        const linkTypes = this.getPathConfigList();
        const pageType =
            entity.params && entity.params.source === 'MERCHANT_CODE'
                ? services.language.getText('zjm')
                : services.language.getText('ygm');
        return (
            <AntModal
                visible={changeTargetVisible}
                okText={services.language.getText('common.ok')}
                title={services.language.getText('qsylj')}
                onOk={this.submitChangeTarget}
                onCancel={this.hardleChangeTargetVisible}
                cancelButtonProps={{
                    onClick: this.hardleChangeTargetVisible,
                }}
            >
                <div className="change-target-modal">
                    <div className="content">
                        <div>
                            <span className="tip">*</span>
                            <span>&nbsp;{services.language.getText('mbldy')}：</span>
                        </div>
                        <div>
                            <LinkTypePlus
                                selector={linkTypeSelector as any}
                                linkTypes={linkTypes}
                                linkTypeMode="cascader"
                                value={changeTargetValue}
                                onChange={(val) => this.changeTargetValue(val)}
                                subsiteIds={[row.subsiteId]}
                            />
                            <div style={{ color: 'red' }}>
                                {services.interpolate(
                                    services.language.getText('newProducePageType'),
                                    { pageType }
                                )}
                            </div>
                            <Checkbox.Group
                                value={[updateGuideCodeLink]}
                                onChange={this.onChangeUpdateGuideCodeLink}
                            >
                                <Checkbox
                                    style={{ marginTop: 10, marginBottom: 5 }}
                                    value={true}
                                ></Checkbox>
                                &nbsp;&nbsp;
                                {services.interpolate(
                                    services.language.getText('alreadyProducePageTpe'),
                                    { pageType }
                                )}
                            </Checkbox.Group>
                        </div>
                    </div>
                </div>
            </AntModal>
        );
    };

    changeTargetValue = (val: any) => {
        const obj = find(this.getPathConfigList(), { id: val.linkType });
        if (obj) {
            const valObj = { ...val };
            const paramsObj = transformParams(val.linkParams, obj.paramsHandler);
            Object.assign(valObj, {
                linkName: linkNameGen(obj.name, valObj.linkParams),
                linkPath: linkPathGen(obj.basePath, obj.params, paramsObj),
            });
            this.setState({
                changeTargetValue: valObj,
            });
        }
    };

    hardleChangeTargetVisible = () => {
        const { row } = this.props;
        const { changeTargetVisible } = this.state;
        this.setState({
            changeTargetVisible: !changeTargetVisible,
            changeTargetValue: JSON.parse(row.target),
            updateGuideCodeLink: false,
        });
    };

    render() {
        const { row } = this.props;
        if (!row) {
            return null;
        }
        return (
            <div>
                <AntButton
                    type="link"
                    style={{ padding: 0 }}
                    className="span-link"
                    onClick={this.hardleChangeTargetVisible}
                >
                    {services.language.getText('common.modify')}
                </AntButton>
                {this.renderButtonsModal()}
            </div>
        );
    }
}
