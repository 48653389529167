import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { forEach, get } from 'lodash';

export const config: Config = {
    entities: {
        CollectionPointsEntity: {
            apiPath: '/loader/admin/collection_points',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: {
                        placeholder: '<<common.pleaseInput>>',
                        maxLength: 100,
                        style: { width: 300 },
                    },
                },
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 250 },
                    },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                { required: true },
                                {
                                    message: '<<qsrhdmc>>',
                                    validator: (rule: any, value: any) => {
                                        if (value && !value.trim()) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: 380 },
                                maxLength: 100,
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            rules: [{ required: true }],
                            displayName: '<<acitivityTime>>',
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<activityMerchant>>',
                            rules: [{ required: true }],
                        },
                        selectMode: {
                            type: 'string.options.radio',
                            displayName: '<<hdzj>>',
                            options: [
                                { id: '0', name: '<<qbzjcy>>' },
                                { id: '1', name: '<<zdzjcy>>' },
                                { id: '2', name: '<<zdzjbcy>>' },
                            ],
                            defaultValue: '0',
                            rules: [{ required: true }],
                            extra: '<<rxfffwdzxpxqr>>',
                        },
                        merchants: {
                            type: 'array.merchant.selector',
                            displayName: '<<applyMerchants>>',
                            defaultValue: [],
                            controlConfig: {
                                subsiteField: 'baseInfo.subsites',
                                tips: '',
                                hideSubsiteColume: false,
                                multipleSubsite: true,
                                customParams: {
                                    isContainsSelfMerchant: false,
                                },
                                customFields: [
                                    {
                                        property: 'merchantType',
                                    },
                                    {
                                        property: 'merchantName',
                                    },
                                ],
                                customColumns: [
                                    {
                                        property: 'subSiteName',
                                    },
                                    {
                                        property: 'merchantName',
                                    },
                                    {
                                        property: 'code',
                                        sorter: true,
                                    },
                                    {
                                        property: 'merchantType',
                                    },
                                    {
                                        property: 'merchantStatus',
                                    },
                                ],
                            },
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],

                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.baseInfo?.subsites?.length &&
                                        row.baseInfo.subsites.map((item: any) => item.id).join(',');
                                    return { subsiteIds };
                                },
                            },
                        },
                    },
                },
                obtainRuleInfo: {
                    type: 'object.subForm',
                    displayName: '<<obtainRule>>',
                    properties: {
                        orderScope: {
                            displayName: '<<xffw>>',
                            type: 'array.optionIds.checkbox.tip',
                            rules: [{ required: true, message: '<<zxffw>>' }],
                            defaultValue: [],
                            options: [
                                { id: 'NORMAL_ORDER', name: '<<xsswdd>>' },
                                { id: 'POS_RECEIPT', name: '<<xxdzxp>>' },
                                // { id: '', name: '<<sqxf>>' },
                            ],
                            // controlConfig: {
                            //     tip: ['<<rxzxxdzxp>>'],
                            // },
                            extra: '<<rxzxxdzxp>>',
                        },
                        timesRule: {
                            displayName: '<<jdgz>>',
                            type: 'object.collectionPointsTimesRule',
                            rules: [{ required: true }],
                            extra: '<<ddfssh>>',
                        },
                        limitEveryDay: {
                            type: 'number.tip',
                            displayName: '<<limitEveryDay>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<inputThanZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^([1-9]\d*)$/;
                                        if (
                                            value !== undefined &&
                                            value !== null &&
                                            (!reg.test(value) || value === 0)
                                        ) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '',
                                style: {
                                    width: 100,
                                },
                                addonbefore: '每人每天最多可集',
                                addonafter: '个点',
                            },
                            extra: '<<rwpzzwsx>>',
                        },
                    },
                },
                dhgzRuleInfo: {
                    type: 'object.subForm',
                    displayName: '<<dhgz>>',
                    properties: {
                        reward: {
                            displayName: '<<dhgz>>',
                            className: 'collection-point-reward',
                            type: 'array.collectionPointsReward',
                            rules: [{ required: true }],
                        },
                    },
                },
                pageRuleInfo: {
                    type: 'object.subForm',
                    displayName: '<<pageRule>>',
                    properties: {
                        backgroundPicture: {
                            type: 'array.imageObjectArrayForTopPic',
                            displayName: '<<hdbjt>>',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/market/collection-point/collection-point-new-background.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                maxSize: 2 * 1024,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                                extra: {
                                    tip: '<<collectionPointsBgSize>>',
                                },
                            },
                        },
                        collectedPointsPicture: {
                            type: 'object.collectionPointsPicture',
                            displayName: '<<yjdtb>>',
                            rules: [{ required: true }],
                        },
                        description: {
                            type: 'string.richTextPlus',
                            displayName: '<<activityRule>>',
                            rules: [{ max: 20000, message: '<<notMoreThan2000>>' }],
                            controlConfig: {
                                placeholder: '<<descriptionRule>>',
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            extra: '<<descriptionExtra>>',
                        },
                    },
                },
            },
        },
        CollectionPointsMemberPointsEntity: {
            apiPath: '/loader/admin/collection_points_member_points',
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 200 } },
                },
                merchantId: {
                    displayName: '<<merchantName>>',
                    type: 'string.filterOptions.autoComplete',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                        //专柜默认正序排序
                        params: {
                            sequence: 'code',
                            order: 'asc',
                        },
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 200 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                    controlConfig: {
                        placeholder: '<<common.pleaseInput>>',
                        maxLength: 100,
                        style: { width: 250 },
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<hdjlsj>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                    controlConfig: {
                        placeholder: '<<common.pleaseInput>>',
                        maxLength: 100,
                        style: { width: 250 },
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                activityName: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<hdjlsj>>',
                },
                rewardValue: {
                    type: 'string',
                    displayName: '<<jdsl>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                refundInfos: {
                    type: 'array.popover',
                    displayName: '<<shdbh>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
            },
        },
        CollectionPointsRewardRecordsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/collection_points_reward_records',
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 250 },
                    },
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                    controlConfig: {
                        placeholder: '<<common.pleaseInput>>',
                        maxLength: 100,
                        style: { width: 300 },
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<dhsj>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<dhdbh>>',
                    controlConfig: {
                        placeholder: '<<common.pleaseInput>>',
                        maxLength: 100,
                        style: { width: 300 },
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                activityName: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<dhsj>>',
                },
                quantity: {
                    type: 'string',
                    displayName: '<<dhsl_1>>',
                },
                consumeValue: {
                    type: 'string',
                    displayName: '<<xhyhs>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<xdrsjh>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<dhdbh>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
            },
        },
    },
    components: {
        CollectionPointsView: {
            component: 'Viewport',
        },
        CollectionPointsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'CollectionPointsEntity',
            className: 'table-content-viewport',
            style: {
                padding: '10px 15px',
            },
            items: [
                {
                    component: 'CollectionPointsFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/collection-points/add',
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'CollectionPointsTable',
                },
            ],
        },
        CollectionPointsFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'subsiteIds',
                },
                {
                    property: 'executeStatus',
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        CollectionPointsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'subsites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '200px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            scroll: { x: true },
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 220,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: 'DISABLE' },
                                    value: 'ENABLE',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/collection_points/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.baseInfo.name}}?',
                                        className: 'confirm-content',
                                    },
                                },
                                {
                                    params: { status: 'ENABLE' },
                                    value: 'DISABLE',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/collection_points/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/menu-list/market/collection-points/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/menu-list/market/collection-points/copy/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<hdjl_1>>',
                        path: '/menu-list/market/collection-points/activity/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<collectionPointsRewardsRecord>>',
                        path: '/menu-list/market/collection-points/exchange/{{row.id}}',
                    },
                    {
                        text: '<<common.log>>',
                        type: 'link',
                        path: '/menu-list/market/collection-points/logs/{{row.id}}',
                    },
                ],
            },
        },
        CollectionPointsAddPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'CollectionPointsEntity',
            items: [
                { component: 'CollectionPointsPreview' },
                { component: 'CollectionPointsAddForm' },
            ],
        },
        CollectionPointsAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            labelCol: 3,
            controlCol: 21,
            fields: [
                {
                    property: 'baseInfo.name',
                },
                {
                    property: 'baseInfo.dateRange',
                },
                {
                    property: 'baseInfo.subsites',
                },
                {
                    property: 'baseInfo.selectMode',
                },
                {
                    property: 'baseInfo.merchants',
                    visible: (values: any) => {
                        return get(values, 'baseInfo.selectMode') !== '0';
                    },
                },
                {
                    property: 'baseInfo.marketingSchedule',
                },
                {
                    property: 'obtainRuleInfo.orderScope',
                },
                {
                    property: 'obtainRuleInfo.timesRule',
                },
                {
                    property: 'obtainRuleInfo.limitEveryDay',
                },
                {
                    property: 'dhgzRuleInfo.reward',
                },
                {
                    property: 'pageRuleInfo.backgroundPicture',
                },
                { property: 'pageRuleInfo.collectedPointsPicture' },
                {
                    property: 'pageRuleInfo.description',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                //适用门店专柜
                const selectMode = entity.baseInfo.selectMode;
                const merchants = entity.baseInfo.merchants;
                if (selectMode !== '0' && (!merchants || merchants.length === 0)) {
                    AntMessage.error(services.language.getText('pleaseSelectMerchant'));
                    return false;
                }
                //集点规则
                const timesRule = entity.obtainRuleInfo.timesRule;
                if (timesRule && timesRule.type === 'CONSUMPTION' && !timesRule.consumptionCount) {
                    AntMessage.error(services.language.getText('qsrdbxfm'));
                    return false;
                }
                if (
                    timesRule &&
                    timesRule.type === 'CONSUMPTION_PER_FULL' &&
                    !timesRule.consumptionPerFullCount
                ) {
                    AntMessage.error(services.language.getText('qsrdbxfmm'));
                    return false;
                }
                //兑换规则
                const reward = entity.dhgzRuleInfo.reward;
                if (!reward || reward.length === 0) {
                    AntMessage.error(services.language.getText('qtjdhgz'));
                    return false;
                }
                let errorTip = '';
                let maxConditionValue = -1;
                forEach(reward, (r: any, index: number) => {
                    if (
                        r.consumeValue === undefined ||
                        r.consumeValue === null ||
                        r.consumeValue === ''
                    ) {
                        errorTip = services.language.getText('qtxdhgzdjmdgs');
                        return false;
                    }
                    if (r.consumeValue > maxConditionValue) {
                        maxConditionValue = r.consumeValue;
                    } else {
                        errorTip = services.interpolate(
                            services.language.getText('collectionPointsRewardjt'),
                            { index: index + 1 }
                        );
                        return false;
                    }
                    if (!r.coupons || r.coupons.length === 0) {
                        errorTip = services.language.getText('qxzyhq');
                        return false;
                    } else {
                        forEach(r.coupons, (c: any) => {
                            if (!c.quantity) {
                                errorTip = services.language.getText('qsrmrmtffgs');
                                return false;
                            }
                            //可输入0
                            if (
                                c.maxStock === undefined ||
                                c.maxStock === null ||
                                c.maxStock === ''
                            ) {
                                errorTip = services.language.getText('qsrhdqjzdffgs');
                                return false;
                            }
                        });
                    }
                });
                if (errorTip) {
                    AntMessage.error(errorTip);
                    return false;
                }
                //集点图标
                const collectedPointsPicture = entity.pageRuleInfo.collectedPointsPicture;
                if (
                    collectedPointsPicture.type === 'DEFALULT' &&
                    (!collectedPointsPicture.defalutCollectedPicture ||
                        !collectedPointsPicture.defalutUnCollectedPicture)
                ) {
                    AntMessage.error(services.language.getText('qxzmrtb'));
                    return false;
                }
                if (
                    collectedPointsPicture.type === 'CUSTOM' &&
                    (!collectedPointsPicture.customCollectedPicture ||
                        !collectedPointsPicture.customUnCollectedPicture)
                ) {
                    AntMessage.error(services.language.getText('qsczdytb'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        CollectionPointsEditPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'CollectionPointsEntity',
            loaderType: 'get',
            items: [
                { component: 'CollectionPointsPreview' },
                { component: 'CollectionPointsEditForm' },
            ],
        },
        CollectionPointsEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            labelCol: 3,
            controlCol: 21,
            fields: [
                {
                    property: 'baseInfo.name',
                },
                {
                    property: 'baseInfo.dateRange',
                },
                {
                    property: 'baseInfo.subsites',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'baseInfo.selectMode',
                },
                {
                    property: 'baseInfo.merchants',
                    visible: (values: any) => {
                        return get(values, 'baseInfo.selectMode') !== '0';
                    },
                },
                {
                    property: 'baseInfo.marketingSchedule',
                },
                {
                    property: 'obtainRuleInfo.orderScope',
                },
                {
                    property: 'obtainRuleInfo.timesRule',
                },
                {
                    property: 'obtainRuleInfo.limitEveryDay',
                },
                {
                    property: 'dhgzRuleInfo.reward',
                },
                {
                    property: 'pageRuleInfo.backgroundPicture',
                },
                { property: 'pageRuleInfo.collectedPointsPicture' },
                {
                    property: 'pageRuleInfo.description',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                //适用门店专柜
                const selectMode = entity.baseInfo.selectMode;
                const merchants = entity.baseInfo.merchants;
                if (selectMode !== '0' && (!merchants || merchants.length === 0)) {
                    AntMessage.error(services.language.getText('pleaseSelectMerchant'));
                    return false;
                }
                //集点规则
                const timesRule = entity.obtainRuleInfo.timesRule;
                if (timesRule && timesRule.type === 'CONSUMPTION' && !timesRule.consumptionCount) {
                    AntMessage.error(services.language.getText('qsrdbxfm'));
                    return false;
                }
                if (
                    timesRule &&
                    timesRule.type === 'CONSUMPTION_PER_FULL' &&
                    !timesRule.consumptionPerFullCount
                ) {
                    AntMessage.error(services.language.getText('qsrdbxfmm'));
                    return false;
                }
                //兑换规则
                const reward = entity.dhgzRuleInfo.reward;
                if (!reward || reward.length === 0) {
                    AntMessage.error(services.language.getText('qtjdhgz'));
                    return false;
                }
                let errorTip = '';
                let maxConditionValue = -1;
                forEach(reward, (r: any, index: number) => {
                    if (
                        r.consumeValue === undefined ||
                        r.consumeValue === null ||
                        r.consumeValue === ''
                    ) {
                        errorTip = services.language.getText('qtxdhgzdjmdgs');
                        return false;
                    }
                    if (r.consumeValue > maxConditionValue) {
                        maxConditionValue = r.consumeValue;
                    } else {
                        errorTip = services.interpolate(
                            services.language.getText('collectionPointsRewardjt'),
                            { index: index + 1 }
                        );
                        return false;
                    }
                    if (!r.coupons || r.coupons.length === 0) {
                        errorTip = services.language.getText('qxzyhq');
                        return false;
                    } else {
                        forEach(r.coupons, (c: any) => {
                            if (!c.quantity) {
                                errorTip = services.language.getText('qsrmrmtffgs');
                                return false;
                            }
                            //可输入0
                            if (
                                c.maxStock === undefined ||
                                c.maxStock === null ||
                                c.maxStock === ''
                            ) {
                                errorTip = services.language.getText('qsrhdqjzdffgs');
                                return false;
                            }
                        });
                    }
                });
                if (errorTip) {
                    AntMessage.error(errorTip);
                    return false;
                }
                //集点图标
                const collectedPointsPicture = entity.pageRuleInfo.collectedPointsPicture;
                if (
                    collectedPointsPicture.type === 'DEFALULT' &&
                    (!collectedPointsPicture.defalutCollectedPicture ||
                        !collectedPointsPicture.defalutUnCollectedPicture)
                ) {
                    AntMessage.error(services.language.getText('qxzmrtb'));
                    return false;
                }
                if (
                    collectedPointsPicture.type === 'CUSTOM' &&
                    (!collectedPointsPicture.customCollectedPicture ||
                        !collectedPointsPicture.customUnCollectedPicture)
                ) {
                    AntMessage.error(services.language.getText('qsczdytb'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        CollectionPointsCopyPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'CollectionPointsEntity',
            loaderType: 'get',
            items: [
                { component: 'CollectionPointsPreview' },
                { component: 'CollectionPointsCopyForm' },
            ],
        },
        CollectionPointsCopyForm: {
            component: 'CopyFormPlus',
            direction: 'horizontal',
            labelCol: 3,
            controlCol: 21,
            fields: [
                {
                    property: 'baseInfo.name',
                },
                {
                    property: 'baseInfo.dateRange',
                },
                {
                    property: 'baseInfo.subsites',
                },
                {
                    property: 'baseInfo.selectMode',
                },
                {
                    property: 'baseInfo.merchants',
                    visible: (values: any) => {
                        return get(values, 'baseInfo.selectMode') !== '0';
                    },
                },
                {
                    property: 'baseInfo.marketingSchedule',
                },
                {
                    property: 'obtainRuleInfo.orderScope',
                },
                {
                    property: 'obtainRuleInfo.timesRule',
                },
                {
                    property: 'obtainRuleInfo.limitEveryDay',
                },
                {
                    property: 'dhgzRuleInfo.reward',
                },
                {
                    property: 'pageRuleInfo.backgroundPicture',
                },
                { property: 'pageRuleInfo.collectedPointsPicture' },
                {
                    property: 'pageRuleInfo.description',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                //适用门店专柜
                const selectMode = entity.baseInfo.selectMode;
                const merchants = entity.baseInfo.merchants;
                if (selectMode !== '0' && (!merchants || merchants.length === 0)) {
                    AntMessage.error(services.language.getText('pleaseSelectMerchant'));
                    return false;
                }
                //集点规则
                const timesRule = entity.obtainRuleInfo.timesRule;
                if (timesRule && timesRule.type === 'CONSUMPTION' && !timesRule.consumptionCount) {
                    AntMessage.error(services.language.getText('qsrdbxfm'));
                    return false;
                }
                if (
                    timesRule &&
                    timesRule.type === 'CONSUMPTION_PER_FULL' &&
                    !timesRule.consumptionPerFullCount
                ) {
                    AntMessage.error(services.language.getText('qsrdbxfmm'));
                    return false;
                }
                //兑换规则
                const reward = entity.dhgzRuleInfo.reward;
                if (!reward || reward.length === 0) {
                    AntMessage.error(services.language.getText('qtjdhgz'));
                    return false;
                }
                let errorTip = '';
                let maxConditionValue = -1;
                forEach(reward, (r: any, index: number) => {
                    if (
                        r.consumeValue === undefined ||
                        r.consumeValue === null ||
                        r.consumeValue === ''
                    ) {
                        errorTip = services.language.getText('qtxdhgzdjmdgs');
                        return false;
                    }
                    if (r.consumeValue > maxConditionValue) {
                        maxConditionValue = r.consumeValue;
                    } else {
                        errorTip = services.interpolate(
                            services.language.getText('collectionPointsRewardjt'),
                            { index: index + 1 }
                        );
                        return false;
                    }
                    if (!r.coupons || r.coupons.length === 0) {
                        errorTip = services.language.getText('qxzyhq');
                        return false;
                    } else {
                        forEach(r.coupons, (c: any) => {
                            if (!c.quantity) {
                                errorTip = services.language.getText('qsrmrmtffgs');
                                return false;
                            }
                            //可输入0
                            if (
                                c.maxStock === undefined ||
                                c.maxStock === null ||
                                c.maxStock === ''
                            ) {
                                errorTip = services.language.getText('qsrhdqjzdffgs');
                                return false;
                            }
                        });
                    }
                });
                if (errorTip) {
                    AntMessage.error(errorTip);
                    return false;
                }
                //集点图标
                const collectedPointsPicture = entity.pageRuleInfo.collectedPointsPicture;
                if (
                    collectedPointsPicture.type === 'DEFALULT' &&
                    (!collectedPointsPicture.defalutCollectedPicture ||
                        !collectedPointsPicture.defalutUnCollectedPicture)
                ) {
                    AntMessage.error(services.language.getText('qxzmrtb'));
                    return false;
                }
                if (
                    collectedPointsPicture.type === 'CUSTOM' &&
                    (!collectedPointsPicture.customCollectedPicture ||
                        !collectedPointsPicture.customUnCollectedPicture)
                ) {
                    AntMessage.error(services.language.getText('qsczdytb'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        CollectionPointsLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                    apiPath: '/admin/collection_points/{id}/operation_logs',
                },
                { component: 'PageBackFooter' },
            ],
        },
        CollectionPointsMemberPointsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'CollectionPointsMemberPointsEntity',
            className: 'table-content-viewport',
            style: {
                padding: '10px 15px',
            },
            items: [
                {
                    component: 'CollectionPointsMemberPointsFilter',
                },
                {
                    component: 'CollectionPointsMemberPointsTable',
                },
            ],
        },
        CollectionPointsMemberPointsFilter: {
            component: 'FilterForm',
            direction: 'inline',
            style: {
                marginBottom: 20,
            },
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'dateRange',
                },
                {
                    property: 'orderNo',
                },
            ],
        },
        CollectionPointsMemberPointsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'activityName',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'rewardValue',
                },
                {
                    property: 'orderNo',
                },
                {
                    property: 'refundInfos',
                    displayConfig: {
                        popoverStyle: {
                            maxWidth: '250px',
                        },
                    },
                },
                {
                    property: 'subsiteName',
                },
                {
                    property: 'merchantName',
                },
            ],
            scroll: { x: true },
        },
        CollectionPointsRewardRecordsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'CollectionPointsRewardRecordsEntity',
            className: 'table-content-viewport',
            style: {
                padding: '10px 15px',
            },
            items: [
                {
                    component: 'CollectionPointsRewardRecordsFilter',
                },
                {
                    component: 'CollectionPointsRewardRecordsTable',
                },
            ],
        },
        CollectionPointsRewardRecordsFilter: {
            component: 'FilterForm',
            direction: 'inline',
            style: {
                marginBottom: 20,
            },
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'dateRange',
                },
                {
                    property: 'orderNo',
                },
            ],
        },
        CollectionPointsRewardRecordsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'activityName',
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'quantity',
                },
                {
                    property: 'consumeValue',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'orderNo',
                },
                {
                    property: 'subsiteName',
                },
            ],
            scroll: { x: true },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/collection-points',
                    component: 'CollectionPointsView',
                    breadcrumbName: '<<collectionPoints>>',
                    privilege: {
                        path: 'collectionPoint',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'CollectionPointsAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'CollectionPointsEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/copy/:id',
                            component: 'CollectionPointsCopyPage',
                            breadcrumbName: '<<copyActivity>>',
                        },
                        {
                            path: '/activity/:id',
                            component: 'CollectionPointsMemberPointsPage',
                            breadcrumbName: '<<hdjl_1>>',
                        },
                        {
                            path: '/exchange/:id',
                            component: 'CollectionPointsRewardRecordsPage',
                            breadcrumbName: '<<collectionPointsRewardsRecord>>',
                        },
                        {
                            path: '/logs/:id',
                            component: 'CollectionPointsLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        { path: '/', component: 'CollectionPointsPage' },
                    ],
                },
            ],
        },
    ],
};
