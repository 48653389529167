import { PureComponent } from 'react';
import { Button as AntButton, message as AntMessage, Modal } from 'antd';
import React from 'react';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { api, language } from '@comall-backend-builder/core/lib/services';
import {
    RewardStatus,
    LotteryStatus,
} from '../../config/content/encourage/topic-promotion-encourage';
export interface TopicEncourageActionButtonProps {
    row: any;
    entity: Entity;
    params: any;
}

enum ActionType {
    /*
     * 发放奖励
     */
    SEND = 'SEND',
    /*
     * 重新发放
     */
    RESEND = 'RESEND',
    /*
     * 剔除排名
     */
    DELETE = 'DELETE',
}
enum LotteryType {
    /*
     * 自动发放
     */
    AUTO = 'AUTO',
    /*
     * 人工发放
     */
    MANUAL = 'MANUAL',
}

export class TopicEncourageActionButton extends PureComponent<TopicEncourageActionButtonProps, {}> {
    submit(action: ActionType) {
        const { row, entity, params } = this.props;
        const id = row.id;
        if (action === ActionType.SEND) {
            Modal.confirm({
                title: language.getText('qrffjlm'),
                onOk: () => {
                    api.put(
                        {},
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: `/admin/topic_activities/issueRewardManual/${id}`,
                        }
                    ).then(() => {
                        AntMessage.success(language.getText('ffjlcg'));
                        entity.search(params);
                    });
                },
            });
        }
        if (action === ActionType.RESEND) {
            Modal.confirm({
                title: language.getText('qrzxffm'),
                onOk: () => {
                    api.put(
                        {},
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: `/admin/topic_activities/issueRewardManual/${id}`,
                        }
                    ).then(() => {
                        AntMessage.success(language.getText('zxffcg'));
                        entity.search(params);
                    });
                },
            });
        }
        if (action === ActionType.DELETE) {
            Modal.confirm({
                title: language.getText('qrtcpmm'),
                onOk: () => {
                    api.put(
                        {},
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: `/admin/topic_activities/eliminateRank/${id}`,
                        }
                    ).then(() => {
                        AntMessage.success(language.getText('tcpmcg'));
                        entity.search(params);
                    });
                },
            });
        }
    }

    render() {
        const { row } = this.props;

        const { lotteryStatusEnum, rewardStatusEnum, lotteryType } = row;

        if (lotteryStatusEnum === LotteryStatus.NOT_REWARDED) {
            return (
                <span>
                    <AntButton
                        type="link"
                        className="span-link"
                        style={{ padding: 0 }}
                        onClick={this.submit.bind(this, ActionType.DELETE)}
                    >
                        {language.getText('tcpm')}
                    </AntButton>
                </span>
            );
        } else if (
            lotteryStatusEnum === LotteryStatus.REWARDED &&
            rewardStatusEnum === RewardStatus.FAILISSUED
        ) {
            return (
                <span>
                    <AntButton
                        type="link"
                        className="span-link"
                        style={{ padding: 0 }}
                        onClick={this.submit.bind(this, ActionType.RESEND)}
                    >
                        {language.getText('zxff')}
                    </AntButton>
                </span>
            );
        } else if (
            lotteryStatusEnum === LotteryStatus.REWARDED &&
            rewardStatusEnum === RewardStatus.UNISSUED &&
            lotteryType === LotteryType.MANUAL
        ) {
            return (
                <span>
                    <AntButton
                        type="link"
                        className="span-link"
                        style={{ padding: 0 }}
                        onClick={this.submit.bind(this, ActionType.SEND)}
                    >
                        {language.getText('ffjl')}
                    </AntButton>
                </span>
            );
        } else {
            return null;
        }
    }
}
