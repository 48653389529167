import { BaseConfig } from '../interfaces';
import { password_validity_period } from '../constants';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { passwrodReg } from '../services/utils';
export let config: BaseConfig = {
    entities: {
        userManage: {
            apiRoot: `${ENV.AUTH_API_ROOT}/CAE-PRIVILEGE`,
            apiPath: '/loader/admin/users',
            properties: {
                id: { type: 'string' },
                userName: {
                    type: 'string',
                    displayName: '<<dlzh>>',
                    rules: [
                        { required: true, message: '<<qsrdlzh>>' },
                        {
                            pattern: /^[A-Za-z0-9`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]{4,32}$/,
                            message: '<<userNameRule>>',
                        },
                    ],
                    controlConfig: {
                        placeholder: '<<qsrdlzh>>',
                        maxLength: 32,
                    },
                },
                password: {
                    type: 'string.password',
                    displayName: '<<dlmm>>',
                    rules: [
                        { required: true, message: '<<qsrdlmm>>' },
                        {
                            pattern: passwrodReg,
                            message: '请输入8~16位密码，数字、字母、字符至少包含两种',
                        },
                    ],
                    controlConfig: {
                        placeholder: '<<qsrdlmm>>',
                        maxLength: 16,
                    },
                },
                realName: {
                    type: 'string',
                    displayName: '<<loginName>>',
                    rules: [
                        { max: 100 },
                        { required: true, whitespace: true, message: '请输入用户全名' },
                    ],
                    controlConfig: {
                        placeholder: '请输入用户全名',
                        maxLength: 100,
                    },
                },
                staffCode: {
                    type: 'string',
                    displayName: '<<ygbm>>',
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<accountStatus>>',
                    defaultValue: 'NORMAL',
                    options: [
                        { id: 'NORMAL', name: '启用' },
                        { id: 'ABNORMAL', name: '禁用' },
                    ],
                },
                phone: {
                    type: 'string.phone',
                    displayName: '<<phoneNum>>',
                    rules: [
                        {
                            pattern: /^[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、\d]{11,20}$/,
                            message: '<<qsrzqgsdsjh>>',
                        },
                    ],
                    controlConfig: {
                        placeholder: '<<qsrbdsjh>>',
                        maxLength: 20,
                    },
                },
                email: {
                    type: 'string',
                    displayName: '<<email>>',
                    rules: [
                        { type: 'email', max: 100, message: '请输入正确格式的邮箱' },
                        { required: true, message: '请输入邮箱' },
                    ],
                    controlConfig: {
                        placeholder: '请输入邮箱',
                        maxLength: 100,
                    },
                },
                createTime: {
                    type: 'string.date',
                    displayName: '<<createTimeStr>>',
                },
                createUserName: {
                    type: 'string',
                    displayName: '<<cjr>>',
                },
                avatarUrl: {
                    type: 'string.imageAvatar',
                    displayName: '用户头像',
                },
                passwordValidityPeriod: {
                    type: 'string.options.select',
                    displayName: '密码有效周期',
                    defaultValue: 'PERMANENT',
                    options: password_validity_period,
                },
                passwordValidityPeriodStatus: {
                    type: 'string.passwordValidityPeriodStatus',
                    displayName: '<<accountStatus>>',
                },
                introduction: {
                    type: 'string.introduction',
                    displayName: '用户介绍',
                    rules: [{ max: 500 }],
                    controlConfig: {
                        placeholder: '请输入用户介绍',
                        maxLength: 500,
                    },
                },
                lastLoginTime: {
                    type: 'string.lastLoginTime',
                    displayName: '最后登录日期',
                },
                isSendEmail: {
                    type: 'string.options.radio',
                    displayName: '发送账号开通邮件',
                    defaultValue: 'true',
                    options: [
                        { id: 'true', name: '发送' },
                        { id: 'false', name: '不发送' },
                    ],
                },
            },
            filters: {
                userName: {
                    type: 'string',
                    displayName: '<<loginName>>',
                },
                email: {
                    type: 'string',
                    displayName: '<<email>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '状态',
                    defaultValue: 'ALL',
                    options: [
                        {
                            id: 'ALL',
                            name: '全部',
                        },
                        {
                            id: 'NORMAL',
                            name: '启用',
                        },
                        {
                            id: 'ABNORMAL',
                            name: '禁用',
                        },
                    ],
                },
            },
        },
        RoleAssignment: {
            apiPath: '/loader/admin/users/:id/roles',
            apiRoot: `${ENV.AUTH_API_ROOT}/CAE-PRIVILEGE`,
            paramsFilter: ['id', 'roleIds'],
            properties: {
                roleIds: {
                    type: 'array.optionIds.customTransfer',
                    options: [],
                    source: {
                        apiRoot: `${ENV.AUTH_API_ROOT}/CAE-PRIVILEGE`,
                        apiPath: '/admin/roles/enable',
                    },
                },
            },
        },
    },
    components: {
        UserView: {
            component: 'Viewport',
            entity: 'userManage',
        },
        UserManagePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'UserManageFilter',
                },
                { component: 'UserManageTable' },
            ],
        },
        UserManageFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
                style: {
                    marginLeft: 16,
                },
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'userName',
                    controlConfig: { placeholder: '请输入账号或用户名' },
                },
                {
                    property: 'email',
                    controlConfig: { placeholder: '请输入邮箱' },
                },
                {
                    property: 'status',
                    controlConfig: {
                        style: { width: 180 },
                    },
                },
            ],
        },
        UserManageTable: {
            component: 'UserManageList',
        },
        UserInfoPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'userManage',
            loaderType: 'get',
            items: [
                {
                    component: 'Detail',
                    labelCol: 6,
                    controlCol: 18,
                    style: { width: 720 },
                    fields: [
                        {
                            property: 'userName',
                        },
                        {
                            property: 'realName',
                            label: '用户全名',
                        },
                        { property: 'staffCode' },
                        { property: 'avatarUrl' },
                        { property: 'email' },
                        { property: 'phone', label: '绑定手机号' },
                        { property: 'passwordValidityPeriod' },
                        {
                            property: 'status',
                            displayConfig: {
                                statusConfig: {
                                    NORMAL: {
                                        text: '启用',
                                        status: 'success',
                                    },
                                    ABNORMAL: {
                                        text: '禁用',
                                        status: 'error',
                                    },
                                },
                            },
                        },
                        { property: 'createTime', label: '账号创建日期' },
                        { property: 'createUserName' },
                        { property: 'lastLoginTime' },
                        { property: 'introduction' },
                    ],
                    footer: {
                        items: [
                            {
                                htmlType: 'button',
                                route: 'goBack',
                                type: 'primary',
                                text: '取消',
                            },
                        ],
                    },
                },
            ],
        },
        UserInfoEditPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'UserInfoEditForm' }],
        },
        UserPrivilegeManagementPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'UserPrivilegeManagement' }],
        },
        UserInfoEditForm: {
            component: 'ModifyForm',
            direction: 'horizontal',
            loaderType: 'get',
            entity: 'userManage',
            labelCol: 6,
            controlCol: 10,
            style: { width: 720 },
            fields: [
                { property: 'userName', controlConfig: { disabled: true } },
                { property: 'email' },
                { property: 'realName', label: '用户全名' },
                { property: 'staffCode' },
                { property: 'passwordValidityPeriod' },
                { property: 'phone' },
                { property: 'introduction' },
            ],
            submit: { text: '<<common.save>>' },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                message.success('用户编辑成功', () => services.behaviorHandle({ route: 'goBack' }));
            },
        },
        UserInfoAddPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'UserInfoAddForm' }],
        },
        UserInfoAddForm: {
            component: 'CreateForm',
            direction: 'horizontal',
            entity: 'userManage',
            labelCol: 6,
            controlCol: 10,
            style: { width: 720 },
            fields: [
                { property: 'userName' },
                { property: 'password' },
                { property: 'email' },
                { property: 'realName', label: '用户全名' },
                { property: 'staffCode' },
                { property: 'passwordValidityPeriod' },
                { property: 'isSendEmail' },
                { property: 'status' },
                { property: 'phone' },
                { property: 'introduction' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                message.success('用户新增成功', () => services.behaviorHandle({ route: 'goBack' }));
            },
        },
        ResetPasswordPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'ResetPasswordForm' }],
        },
        ResetPasswordForm: {
            component: 'ResetPassword',
            entity: 'userManage',
        },
        AssignRolePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'Text',
                    text: '登录账号： {{params.name}}',
                    style: {
                        marginBottom: 24,
                    },
                },
                { component: 'AssignRoleForm' },
            ],
        },
        AssignRoleForm: {
            component: 'ModifyForm',
            entity: 'RoleAssignment',
            loaderType: 'get',
            fields: [
                {
                    property: 'roleIds',
                    controlConfig: {
                        titles: ['角色列表', '该用户已有角色'],
                        operations: ['添加角色', '移除角色'],
                        locale: { itemUnit: '个', itemsUnit: '个' },
                        listStyle: {
                            minWidth: 300,
                        },
                    },
                },
            ],
            labelCol: 0,
            submit: { text: '<<common.save>>' },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                message.success('分配角色成功');
                services.navigation.goBack();
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/user-manage',
                    breadcrumbName: '管理用户',
                    category: '系统设置',
                    component: 'UserView',
                    privilege: {
                        path: 'user',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/user-info/:id',
                            component: 'UserInfoPage',
                            breadcrumbName: '用户详情',
                        },
                        {
                            path: '/user-info-edit/:id',
                            component: 'UserInfoEditPage',
                            breadcrumbName: '<<editUser>>',
                        },
                        {
                            path: '/user-info-add',
                            component: 'UserInfoAddPage',
                            breadcrumbName: '<<addUser>>',
                        },
                        {
                            path: '/reset-password/:id',
                            component: 'ResetPasswordPage',
                            breadcrumbName: '重置密码',
                        },
                        {
                            path: '/user-privilege-management/:id/:name',
                            component: 'UserPrivilegeManagementPage',
                            breadcrumbName: '关联门店',
                            title: '关联门店 【{{name}}】',
                        },
                        {
                            path: '/assign-role/:id/:name',
                            component: 'AssignRolePage',
                            breadcrumbName: '分配角色',
                        },
                        { path: '/', component: 'UserManagePage' },
                    ],
                },
            ],
        },
    ],
};
