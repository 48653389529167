import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach } from 'lodash';

const api = services.api;
export const ReceiptInvoiceConfigsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`;
        config.apiPath = '/admin/invoice_configs';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/invoice_configs/${id}`;
        } else {
            data.type = 'POS_RECEIPT';
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.baseInfo = {
                    subsiteId: res.subsite.id + '',
                };
                res.invoiceSystemInfo = {
                    invoiceSystem: res.invoiceThirdSystem,
                };
                res.otherInfo = {
                    invoiceMode: res.invoiceMode,
                    contents: res.contents,
                };
            } else {
                res.result = res.result.map((item: any) => {
                    let subsiteName = '';
                    if (item.subsites && item.subsites.length > 0) {
                        subsiteName = item.subsites[0].name;
                    }
                    item.subsiteName = subsiteName;
                    return item;
                });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`;
        config.apiPath = '/admin/invoice_configs';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`;
        config.apiPath = `/admin/invoice_configs/${id}`;
        const newParams: any = dataParser(params);
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    newParams = {
        type: 'POS_RECEIPT',
        ...params.baseInfo,
        invoiceTypes: ['NORMAL'],
        invoiceMode: params.otherInfo.invoiceMode,
        contents: params.otherInfo.contents,
    };
    let invoiceThirdSystemParams: any = {};
    const invoiceThirdSystem = params.invoiceSystemInfo.invoiceSystem;
    if (invoiceThirdSystem.params && invoiceThirdSystem.params.length > 0) {
        forEach(invoiceThirdSystem.params, (p) => {
            invoiceThirdSystemParams[p.field] = p.value;
        });
    }
    newParams.invoiceThirdSystem = {
        id: invoiceThirdSystem.id,
        params: JSON.stringify(invoiceThirdSystemParams),
    };
    return newParams;
}
