import { BaseConfig } from '../../../applications/cae/interfaces';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import get from 'lodash/get';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { contentPopUpConfig } from '../../../applications/design/design';

enum ContentTopicStatus {
    /**
     * 启用
     */
    ENABLE = 'ENABLE',
    /**
     * 禁用
     */
    DISABLE = 'DISABLE',
}

enum ExecuteStatus {
    /**
     * 未开始
     */
    NOT_STARTED = 'NOT_STARTED',
    /**
     * 进行中
     */
    STARTED = 'STARTED',
    /**
     * 已结束
     */
    COMPLETE = 'COMPLETE',
}

const CONTENT_TOPIC_STATUS_OPTIONS = [
    {
        id: ContentTopicStatus.ENABLE,
        name: '<<common.opend>>',
    },
    {
        id: ContentTopicStatus.DISABLE,
        name: '<<common.close>>',
    },
];

const CONTENT_TOPIC_EXECUTE_STATUS_OPTIONS = [
    {
        id: ExecuteStatus.NOT_STARTED,
        name: '<<notStarted>>',
    },
    {
        id: ExecuteStatus.STARTED,
        name: '<<inprogress>>',
    },
    {
        id: ExecuteStatus.COMPLETE,
        name: '<<end>>',
    },
];

const CONTENT_TOPIC__EXECUTE_STATUS_CONFIG = {
    [ExecuteStatus.NOT_STARTED]: {
        text: '<<notStarted>>',
        status: 'success',
    },
    [ExecuteStatus.STARTED]: {
        text: '<<inprogress>>',
        status: 'processing',
    },
    [ExecuteStatus.COMPLETE]: {
        text: '<<end>>',
        status: 'default',
    },
};

/** linkParams 不能为空 */
const noLinkParams = ['mallActivity', 'topic', 'topicContent', 'product'];

export let config: BaseConfig = {
    entities: {
        PopUpContent: {
            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
            apiPath: '/loader/admin/pop_up_content',
            properties: {
                name: {
                    type: 'string',
                    displayName: '展示标题',
                },
                showTime: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '展示时间',
                },
                startTime: {
                    type: 'string.dateTime',
                    displayName: '展示开始时间',
                },
                endTime: {
                    type: 'string.dateTime',
                    displayName: '展示结束时间',
                },
                citys: {
                    type: 'string',
                    displayName: '<<cs_2>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<status>>',
                    defaultValue: 'DISABLE',
                    options: CONTENT_TOPIC_EXECUTE_STATUS_OPTIONS,
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '展示标题',
                },
                showTime: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '展示时间',
                    controlConfig: {
                        style: { width: 380 },
                    },
                },
            },
        },
        PopUpContentHandle: {
            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
            apiPath: '/loader/admin/pop_up_content',
            properties: {
                name: {
                    type: 'string',
                    displayName: '展示标题',
                    rules: [
                        { required: true },
                        {
                            max: 16,
                            message: '广告标题不能超过16字符',
                        },
                    ],
                },
                showTime: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '展示时间',
                    rules: [{ required: true }],
                    controlConfig: {
                        style: { width: 380 },
                    },
                },
                popUpAdsCitys: {
                    type: 'array.options.autoComplete',
                    displayName: '适用城市',
                    defaultValue: [],
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    options: [],
                    source: {
                        apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
                        apiPath: '/loader/admin/cities/available',
                    },
                },
                type: {
                    type: 'string.options.select',
                    displayName: '展示位置',
                    options: [{ id: 'home', name: '首页' }],
                    rules: [{ required: true }],
                },
                crowd: {
                    type: 'string.options.select',
                    displayName: '投放人群',
                    options: [{ id: 'all', name: '<<all>>' }],
                    rules: [{ required: true }],
                },
                config: {
                    type: 'string.options.select',
                    displayName: '触发条件',
                    options: [
                        { id: 'FIRST', name: '首次进入' },
                        { id: 'EVERY_TIME', name: '每次进入' },
                    ],
                    rules: [{ required: true }],
                },
                pic: {
                    type: 'string.image.picker',
                    displayName: '弹窗广告',
                    rules: [{ required: true }],
                    controlConfig: {
                        fileName: 'fileName',
                        uploadType: 'single',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                        maxCount: 1,
                        maxSize: 1 * 1024, // 1M kb计算
                        limitTips: {
                            limitText: '建议图片宽度为750px，高度不超过1000px；大小不超过1M；',
                            limitUnit: ' ',
                            className: 'image-picker-tips-ext',
                        },
                    },
                },
                linkType: {
                    type: 'object.linkTypePopUpContent',
                    displayName: '链接配置',
                    defaultValue: { linkType: 'none', linkParams: {} },
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<status>>',
                    defaultValue: 'DISABLE',
                    options: CONTENT_TOPIC_STATUS_OPTIONS,
                },
            },
        },
    },
    components: {
        PopUpContentView: {
            component: 'Viewport',
            entity: 'PopUpContent',
        },
        PopUpContentPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    submit: {
                        text: '<<common.search>>',
                        style: {
                            marginRight: 16,
                        },
                    },
                    reset: {
                        text: '<<common.reset>>',
                    },
                    fields: [
                        {
                            property: 'name',
                        },
                        {
                            property: 'showTime',
                        },
                    ],
                },
                {
                    component: 'Button',
                    text: '新增广告',
                    route: '/pop-up-content/add',
                    type: 'primary',
                    icon: 'plus',
                    style: {
                        width: 110,
                    },
                    className: 'mt24 mb24',
                },
                { component: 'PopUpContentTable' },
            ],
        },

        PopUpContentTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return `共 ${total} 条记录`;
                },
            },
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'citys',
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        statusConfig: CONTENT_TOPIC__EXECUTE_STATUS_CONFIG,
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            type: 'link',
                            statusKey: 'status',
                            renderComponent: 'Button',
                            style: { display: 'inline-flex' },
                            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
                            handles: [
                                {
                                    value: ContentTopicStatus.DISABLE,
                                    apiPath: '/admin/pop_up_ads/:id/enable',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                                {
                                    value: ContentTopicStatus.ENABLE,
                                    apiPath: '/admin/pop_up_ads/:id/disable',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },

                    {
                        text: '<<common.edit>>',
                        type: 'link',
                        statusKey: 'row.canEdit',
                        path: '/pop-up-content/edit/{{row.id}}',
                    },
                    {
                        type: 'delete',
                        text: '删除',
                        statusKey: 'row.canDelete',
                        config: {
                            type: 'link',
                            id: 'row.id',
                            tooltip: null,
                            title: '<<confirmDelete>>',
                            okText: '<<common.ok>>',
                            cancelText: '<<common.cancel>>',
                        },
                    },
                ],
            },
        },

        PopUpContentAddPage: {
            entity: 'PopUpContentHandle',
            component: 'FlexLayout',
            items: [
                {
                    component: 'PopUpContentPreview',
                },
                {
                    component: 'PopUpContentAddForm',
                },
            ],
        },

        PopUpContentAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            style: { width: 600 },
            labelCol: 6,
            controlCol: 18,
            fields: [
                { property: 'name' },
                { property: 'showTime' },
                { property: 'popUpAdsCitys' },
                { property: 'type' },
                { property: 'crowd' },
                { property: 'config' },
                { property: 'pic' },
                {
                    property: 'linkType',
                    className: 'link-type-property',
                },
            ],
            submit: true,
            onValidate: (entity: any) => {
                const linkType = get(entity, 'linkType.linkType');
                const linkParams = get(entity, 'linkType.linkParams');
                const configKeys = Object.keys(contentPopUpConfig) || [];
                let flag = true;

                map([...configKeys, ...noLinkParams], (item) => {
                    if (item === linkType && isEmpty(linkParams)) {
                        AntMessage.error('链接目标不能为空');
                        flag = false;
                    }
                });

                return flag;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
        },

        PopUpContentEditPage: {
            component: 'FlexLayout',
            entity: 'PopUpContentHandle',
            loaderType: 'get',
            items: [
                { component: 'PopUpContentPreview' },
                {
                    component: 'PopUpContentEditForm',
                },
            ],
        },

        PopUpContentEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            style: { width: 600 },
            labelCol: 6,
            controlCol: 18,
            fields: [
                { property: 'name' },
                { property: 'showTime' },
                { property: 'popUpAdsCitys' },
                { property: 'type' },
                { property: 'crowd' },
                { property: 'config' },
                { property: 'pic' },
                {
                    property: 'linkType',
                    className: 'link-type-property',
                },
            ],
            submit: true,
            onValidate: (entity: any) => {
                const linkType = get(entity, 'linkType.linkType');
                const linkParams = get(entity, 'linkType.linkParams');
                const configKeys = Object.keys(contentPopUpConfig) || [];
                let flag = true;

                map([...configKeys, ...noLinkParams], (item) => {
                    if (item === linkType && isEmpty(linkParams)) {
                        AntMessage.error('链接目标不能为空');
                        flag = false;
                    }
                });

                return flag;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/pop-up-content',
                    breadcrumbName: '管理内容弹窗',
                    component: 'PopUpContentView',
                    privilege: {
                        path: 'showPopUpAds',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'PopUpContentEditPage',
                            breadcrumbName: '编辑内容弹窗',
                        },
                        {
                            path: '/add',
                            component: 'PopUpContentAddPage',
                            breadcrumbName: '新增内容弹窗',
                        },
                        { path: '/', component: 'PopUpContentPage' },
                    ],
                },
            ],
        },
    ],
};
