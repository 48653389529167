import React, { Component } from 'react';
import { InputNumber, Button, Popconfirm } from 'antd';
import { findIndex, remove, toString } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';
import {
    CollectionPointsRewardCouponSelector,
    CouponType,
    EventCouponValueTypeEnum,
} from './collection-points-reward-select-coupon';
import { services } from '@comall-backend-builder/core';

export interface Coupon {
    id: string;
    couponName: number;
    couponType: string;
    startDate: string;
    endDate: string;
    couponTypeDes: string;
    couponValue: any;
    quantity?: number;
    /**
     * 活动期间最多可发放数量
     */
    maxStock: number;
    /**
     * 活动期间最多可发放数量-剩余
     */
    stock?: number;
    pictureUrl: number;
    /**
     * 优惠券剩余库存
     */
    leftStock?: number;
    couponDefinitionId?: number;
    subsites: Array<any>;
    rewardId?: number;
}

interface ActivityRewardProps {
    index: number;
    subsiteIds: any;
    value: Array<Coupon> | null;
    /**
     * 是否禁用（禁用只限制修改阶梯值、赠删阶梯，不限制修改阶梯内奖励内容）
     */
    // disabled: boolean;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Array<Coupon>, index: number) => void;
}

export class CollectionPointsRewardCoupons extends Component<ActivityRewardProps, any> {
    static defaultProps = {
        disabled: false,
    };
    CollectionPointsRewardCouponSelector: any;

    componentDidMount() {
        let { value, onChange, index } = this.props;
        if (!value) {
            onChange([], index);
        }
    }
    onChooseCoupon = () => {
        this.CollectionPointsRewardCouponSelector.toggleModal();
    };
    showModel = (ref: any) => {
        this.CollectionPointsRewardCouponSelector = ref;
    };
    modalToCoupon = (item: any) => {
        return {
            ...item,
            id: item.id,
            couponDefinitionId: item.id,
            couponName: item.name || item.couponName,
            couponType: item.couponType || item.type,
            startDate: item.startDate,
            endDate: item.endDate,
            couponTypeDes: item.typeName || item.couponTypeDes,
            quantity: item.quantity || 1,
            stock: item.stock,
            maxStock: item.leftStock,
            leftStock: item.leftStock,
            couponValue: item.couponValue,
            subsites: item.subsites,
            pictureUrl:
                item.pictureConfigs && item.pictureConfigs.length > 0
                    ? item.pictureConfigs[0].url
                    : '',
        };
    };
    setDisplayType = (row: Coupon[]) => {
        let displayType = 'selectCoupon';
        if (row.length && row[0]?.couponType === 'PACKAGE') {
            displayType = 'selectCouponPackage';
        }
        return displayType;
    };
    changeCoupons = (coupons: Array<any>, type: string) => {
        //支持多个选择时
        let { value, onChange, index } = this.props;
        let newCoupons: any = value ? value : [];
        if (this.setDisplayType(newCoupons) !== type) {
            newCoupons = [];
        }
        if (type === 'selectCoupon') {
            coupons.forEach((selectCuopon: any) => {
                const hasIndex = findIndex(
                    newCoupons,
                    (coupon: any) => toString(coupon.id) === toString(selectCuopon.id)
                );
                if (hasIndex === -1) {
                    newCoupons.push(selectCuopon);
                }
            });
        } else {
            newCoupons = [...coupons];
        }
        newCoupons = newCoupons.map(this.modalToCoupon);
        if (onChange) {
            onChange(newCoupons, index);
        }
    };
    changeModal = () => {
        const { value, subsiteIds } = this.props;
        const params = {
            subsiteIds: subsiteIds,
        };
        return (
            <CollectionPointsRewardCouponSelector
                onRef={this.showModel}
                selectValues={value || []}
                onChange={this.changeCoupons}
                params={params}
            />
        );
    };

    onChangeCoupon = (products: Coupon[]) => {
        let { onChange, index } = this.props;
        if (onChange) {
            onChange(products, index);
        }
    };

    renderValue = (coupon: any) => {
        const { couponValue, couponType } = coupon;
        const couponRuleType = couponType;

        if (couponRuleType === CouponType.GIFT) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__text">
                        {services.language.getText('presents')}
                    </span>
                </div>
            );
        } else if (couponRuleType === CouponType.LOTTERY) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__num">{couponValue.value}</span>
                </div>
            );
        } else if (couponRuleType === CouponType.PACKAGE) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__text">
                        {services.language.getText('gifts')}
                    </span>
                </div>
            );
        } else if (
            couponRuleType === CouponType.PARKING &&
            couponValue.type === EventCouponValueTypeEnum.No
        ) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__text">
                        {services.language.getText('qm_2')}
                    </span>
                </div>
            );
        }
        if (
            couponType === CouponType.PARKING &&
            couponValue.type === EventCouponValueTypeEnum.Hours
        ) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__num">{couponValue.value}</span>
                    <span className="event-coupon__symbol">
                        {services.language.getText('hour')}
                    </span>
                </div>
            );
        }
        return (
            <div className="event-coupon__price">
                <span className="event-coupon__symbol">
                    {services.language.getText('monetaryUnit')}
                </span>
                <span className="event-coupon__price__num">
                    {couponValue ? couponValue.value : ''}
                </span>
            </div>
        );
    };
    renderCoupons = (coupon: any) => {
        const innerClassName = `${coupon.couponType}-coupon-popup__list__item`;
        const bg = 'https://b-puzhehei-cdn.co-mall.net/global/event_coupon_bg.png';
        const style = {
            backgroundImage: `url(${bg})`,
        };
        const subsitesName = coupon.subsites
            ? coupon.subsites.map((s: any) => s.name).join(',')
            : '';
        return (
            <div className={innerClassName}>
                <div className="event-coupon" style={style}>
                    <div className="event-coupon__left">{this.renderValue(coupon)}</div>
                    <div className="event-coupon__right" style={{ padding: '10px 0 10px 10px' }}>
                        <div className="event-coupon__right__title" title={coupon.couponName}>
                            {coupon.couponName}
                        </div>
                        <div className="event-coupon__right__time">
                            {coupon.startDate}-{coupon.endDate}
                        </div>
                        <div className="event-coupon__right__subsite ant-table-cell-ellipsis">
                            适用门店：{subsitesName}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    changeMaxStock = (couponIndex: number, valueNum: number) => {
        const { value, onChange, index } = this.props;
        if (!value) {
            return;
        }
        let rowItem: any = value[couponIndex];

        if (rowItem) {
            rowItem.maxStock = valueNum;
            if (onChange) {
                onChange(value, index);
            }
        }
    };
    changeQuantity = (couponIndex: number, valueNum: number) => {
        const { value, onChange, index } = this.props;
        if (!value) {
            return;
        }
        let rowItem: any = value[couponIndex];

        if (rowItem) {
            rowItem.quantity = valueNum;
            if (onChange) {
                onChange(value, index);
            }
        }
    };
    deleteCouponItem = (couponIndex: number) => {
        const { value, onChange, index } = this.props;
        if (!value) {
            return;
        }
        let rowItem: any = value[couponIndex];
        if (rowItem) {
            remove(value, function(rowItemCoupon: Coupon) {
                return rowItemCoupon.id === rowItem.id;
            });
            if (onChange) {
                onChange(value, index);
            }
        }
    };
    showCouponItem = () => {
        const { value } = this.props;
        const isEdit = window.location.href.includes('/edit');
        if (value) {
            return value.map((item: Coupon, couponIndex: number) => {
                const unitTxt =
                    item.couponType === CouponType.PACKAGE
                        ? services.language.getText('ge')
                        : services.language.getText('zhang');
                return (
                    <div
                        key={item.id}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: isEdit ? '10px 0 25px 0' : '10px 0',
                            overflow: 'scroll',
                            // maxWidth: '90%',
                        }}
                    >
                        <div>{this.renderCoupons(item)}</div>
                        <div style={{ padding: '0 20px', flexShrink: 0 }}>
                            {services.language.getText('stock')}：{item.leftStock}
                        </div>
                        <div style={{ flexShrink: 0 }}>
                            <span style={{ color: 'red', marginRight: '5px' }}>*</span>
                            {services.language.getText('mrmcff')}&nbsp;
                            <InputNumber
                                placeholder={services.language.getText('inputPlease')}
                                value={item.quantity}
                                min={1}
                                max={999999999}
                                onChange={(value: any) => this.changeQuantity(couponIndex, value)}
                                precision={0}
                            ></InputNumber>
                            &nbsp;&nbsp;{unitTxt}
                        </div>
                        <div style={{ marginLeft: '10px', flexShrink: 0, position: 'relative' }}>
                            <div>
                                <span style={{ color: 'red', marginRight: '5px' }}>*</span>
                                {services.language.getText('hdqjzdff')}&nbsp;
                                <InputNumber
                                    placeholder={services.language.getText('inputPlease')}
                                    value={item.maxStock}
                                    min={0}
                                    max={999999999}
                                    onChange={(value: any) =>
                                        this.changeMaxStock(couponIndex, value)
                                    }
                                    precision={0}
                                ></InputNumber>
                                &nbsp;&nbsp;{services.language.getText('f')}
                            </div>
                            {isEdit && (
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: '35px',
                                        left: '68px',
                                        display: 'flex',
                                        alignItems: 'baseline',
                                        width: '175px',
                                    }}
                                >
                                    {services.language.getText('sykc')}&nbsp;
                                    <InputNumber value={item.stock} disabled></InputNumber>
                                    &nbsp;&nbsp;{services.language.getText('f')}
                                </div>
                            )}
                        </div>
                        <Popconfirm
                            title={`${services.language.getText('qrsc')}${item.couponName}?`}
                            onConfirm={() => this.deleteCouponItem(couponIndex)}
                            okText="确认"
                            cancelText={language.getText('common.cancel')}
                        >
                            <Button type="link">
                                {services.language.getText('common.delete')}
                            </Button>
                        </Popconfirm>
                    </div>
                );
            });
        }
    };

    render() {
        return (
            <div className="comment-activity-reward">
                <Button type={'link'} onClick={this.onChooseCoupon} style={{ padding: 0 }}>
                    选择优惠券
                </Button>
                <div className="comment-activity-reward__wrap" style={{ marginLeft: '-92px' }}>
                    {this.showCouponItem()}
                    {this.changeModal()}
                </div>
            </div>
        );
    }
}
