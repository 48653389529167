import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import './index.less';
import { MerchantUserPhoneInput } from '../../../components';

export class MerchantUserPhoneInputMode extends modes.StringMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <MerchantUserPhoneInput {...controlInfo} />;
    }
}
