import { EntityButtonProps } from '../../../../components/entity-button/index';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { ForwardDataCenterModal } from '../../../../services';
import { isEmpty } from 'lodash';
const api = services.api;

const CouponActivityReportItem =
    ENV.TENANT === 'wfj'
        ? {
              component: 'PageTip',
              props: {
                  tips: ['<<dhxddsjzzc>>'],
                  style: {
                      width: '100%',
                      height: '380px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                  },
                  tipStyle: {
                      fontSize: '14px',
                  },
              },
          }
        : {
              component: 'DataTable',
              scroll: {
                  x: 2000,
              },
              loadFirstPage: true,
              pagination: {
                  pageSize: 10,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ['10', '15', '20'],
                  showTotal(total: number) {
                      return services.interpolate(services.language.getText('total'), {
                          total,
                      });
                  },
              },
              columns: [
                  {
                      property: 'activityName',
                      width: 180,
                  },
                  {
                      property: 'subsiteName',
                      width: 100,
                  },
                  {
                      property: 'couponName',
                      width: 180,
                  },
                  {
                      property: 'couponValue',
                      width: 100,
                  },
                  {
                      property: 'unitExecutePrice',
                      width: 100,
                  },
                  {
                      property: 'unitPointExecutePrice',
                      width: 100,
                  },
                  {
                      property: 'issueNum',
                      width: 100,
                  },
                  {
                      property: 'memberNum',
                      width: 100,
                  },
                  {
                      property: 'executePrice',
                      width: 100,
                  },
                  {
                      property: 'pointExecutePrice',
                      width: 100,
                  },
                  {
                      property: 'consumeNum',
                      width: 100,
                  },
                  {
                      property: 'consumeMemberNum',
                      width: 100,
                  },
                  {
                      property: 'consumePrice',
                      width: 100,
                  },
                  {
                      property: 'consumePointPrice',
                      width: 100,
                  },
                  {
                      property: 'consumeValue',
                      width: 100,
                  },
                  {
                      property: 'refundNum',
                      width: 100,
                  },
                  {
                      property: 'refundMemberNum',
                      width: 100,
                  },
                  {
                      property: 'refundPrice',
                      width: 100,
                  },
                  {
                      property: 'refundPointPrice',
                      width: 100,
                  },
                  {
                      property: 'refundValue',
                      width: 100,
                  },
              ],
          };

const CouponActivityReportSubmit =
    ENV.TENANT === 'wfj'
        ? {}
        : {
              submit: {
                  text: '<<common.search>>',
              },
              reset: {
                  text: '<<common.reset>>',
              },
          };

export const config: Config = {
    entities: {
        CouponActivityReportEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/coupon_reports/coupon_activity',
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<activityMerchant>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                activityName: {
                    type: 'string.text.trim',
                    displayName: '<<activityName>>',
                },
                couponName: {
                    type: 'string.text.trim',
                    displayName: '<<couponName>>',
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<hxrq>>',
                },
            },
            properties: {
                activityName: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<activityMerchant>>',
                },
                couponName: {
                    type: 'string.popover',
                    displayName: '<<couponName>>',
                },
                couponValue: {
                    type: 'string',
                    displayName: '<<mz_1>>',
                },
                unitExecutePrice: {
                    type: 'string',
                    displayName: '<<sxbj>>',
                },
                unitPointExecutePrice: {
                    type: 'string',
                    displayName: '<<sxjf>>',
                },
                issueNum: {
                    type: 'string',
                    displayName: '<<ffzs>>',
                },
                memberNum: {
                    type: 'string',
                    displayName: '<<lqrs>>',
                },
                executePrice: {
                    type: 'string',
                    displayName: '<<sqsy>>',
                },
                pointExecutePrice: {
                    type: 'string',
                    displayName: '<<zdhjf>>',
                },
                consumeNum: {
                    type: 'string',
                    displayName: '<<hxzs>>',
                },
                consumeMemberNum: {
                    type: 'string',
                    displayName: '<<hxrs>>',
                },
                consumePrice: {
                    type: 'string',
                    displayName: '<<hxbj>>',
                },
                consumePointPrice: {
                    type: 'string',
                    displayName: '<<hxjf>>',
                },
                consumeValue: {
                    type: 'string',
                    displayName: '<<hxzmz>>',
                },
                refundNum: {
                    type: 'string',
                    displayName: '<<tqzs>>',
                },
                refundMemberNum: {
                    type: 'string',
                    displayName: '<<tqrs>>',
                },
                refundPrice: {
                    type: 'string',
                    displayName: '<<tqfhbj>>',
                },
                refundPointPrice: {
                    type: 'string',
                    displayName: '<<tqfhjf>>',
                },
                refundValue: {
                    type: 'string',
                    displayName: '<<tqzmz>>',
                },
            },
        },
    },
    components: {
        CouponActivityReportView: {
            component: 'Viewport',
            entity: 'CouponActivityReportEntity',
        },
        CouponActivityReportPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'CouponActivityReportFilter',
                },
                { component: 'CouponActivityReportTable' },
            ],
        },
        CouponActivityReportFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            ...CouponActivityReportSubmit,
            fields: [
                {
                    property: 'subsiteId',
                    controlConfig: {
                        style: { width: 240 },
                    },
                },
                {
                    property: 'activityName',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<qsryhqlbmc>>',
                    },
                },
                {
                    property: 'couponName',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<qsryhqmc>>',
                    },
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: { width: 240 },
                    },
                },
            ],
        },
        CouponActivityReportTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'EntityButton',
                    props: {
                        type: 'primary',
                        children: '<<exportSearchResult>>',
                        style: {
                            width: 110,
                            marginBottom: 24,
                            marginTop: 17,
                        },
                        onClick: (_: any, entityButtonProps: EntityButtonProps) => {
                            const params: any = Object.assign(
                                {},
                                entityButtonProps.entity.params,
                                entityButtonProps.entity.filters
                            );
                            const config = {
                                apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                apiPath: '/admin/coupon_reports/coupon_activity/download',
                            };
                            if (!isEmpty(params.dateRange)) {
                                params.startTime = params.dateRange.start + ' 00:00:00';
                                params.endTime = params.dateRange.end + ' 23:59:59';
                                delete params.dateRange;
                            }
                            if (params?.subsiteId?.id) {
                                params.subsiteId = params.subsiteId.id;
                            }
                            api.get(params, config)
                                .then(() => {
                                    ForwardDataCenterModal();
                                })
                                .catch((error) => {
                                    services.errorHandle(error);
                                });
                        },
                    },
                },
                CouponActivityReportItem,
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/coupon-activity-report',
                    component: 'CouponActivityReportView',
                    breadcrumbName: '<<couponActivityReport>>',
                    privilege: {
                        path: 'couponActivityReport',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'CouponActivityReportPage' }],
                },
            ],
        },
    ],
};
