import * as React from 'react';

import { ArrayMode } from '@comall-backend-builder/types';
import { StoredValueCardReward } from './stored-value-card-reward';

/**
 *  储值卡奖励设置
 */
export class ArrayStoredValueCardRewardMode extends ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <StoredValueCardReward {...controlInfo} />;
    }
}
