import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
const api = services.api;
export const PrintSettingLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/print_configs/1';
        return await api.get(data, config).then((res: any) => {
            return {
                printConfig: {
                    ...res,
                    radioType: {
                        type: res.type,
                        fontSize: res.fontSize,
                    },
                },
            };
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/print_configs/1';
        let newParams: any = { id: 1 };
        if (params.printConfig) {
            newParams.subsiteIds = params.printConfig.subsites
                .map((item: { id: string; name: string }) => item.id)
                .join(',');
            newParams.type = params.printConfig.radioType.type;
            newParams.fontSize = params.printConfig.radioType.fontSize;
            newParams.remark = params.printConfig.remark;
        }
        return await api.put(newParams, config);
    },
};
