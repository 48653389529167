import { BaseConfig } from '../interfaces';
import { password_validity_period } from '../constants';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { get, mapValues } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';
const api = services.api;

export let config: BaseConfig = {
    entities: {
        NewUserManage: {
            apiPath: '/loader/cae/users',
            apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
            properties: {
                id: { type: 'string' },
                phone: {
                    type: 'string.phone',
                    displayName: '<<phoneNum>>',
                },
                userName: {
                    type: 'string',
                    displayName: '<<dlzh>>',
                },
                password: {
                    type: 'string.passwordPlus',
                    displayName: '<<dlmm>>',
                    rules: [{ required: true, message: '<<qsrxmm>>' }],
                    controlConfig: {
                        openCheck: true,
                        extraStatus: true,
                    },
                },
                confirmPassword: {
                    type: 'string.passwordPlus',
                    displayName: '<<xmmqr>>',
                    rules: [{ required: true, message: '<<qzcqrxmm>>' }],
                },
                realName: {
                    type: 'string',
                    displayName: '<<ygxm>>',
                },
                staffCode: {
                    type: 'string',
                    displayName: '<<ygbm>>',
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<accountStatus>>',
                    defaultValue: 'ACTIVE',
                    options: [
                        { id: 'ACTIVE', name: '<<enable>>' },
                        { id: 'INACTIVE', name: '<<disabled>>' },
                        { id: 'SUSPEND', name: '<<invalid>>' },
                        { id: 'REVOKED', name: '<<off>>' },
                    ],
                },
                organizationName: {
                    type: 'string',
                    displayName: '<<zzjg>>',
                },
                userRoles: {
                    type: 'array.popover',
                    displayName: '<<js_4>>',
                },
                organizations: {
                    type: 'array.popover',
                    displayName: '<<glmdzg>>',
                },
                createTime: {
                    type: 'string.date',
                    displayName: '<<createTimeStr>>',
                },
                createUserName: {
                    type: 'string',
                    displayName: '<<cjr>>',
                },
                extendAttributes: {
                    type: 'array.extendAttributes',
                    displayName: '<<kzsx>>',
                },
                avatarUrl: {
                    displayName: '<<tx_1>>',
                    type: 'imageObjectArray',
                    className: 'sigle-image-field',
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                    extra: '<<avatarExtra>>',
                },
                introduction: {
                    displayName: '<<remark>>',
                    type: 'string.text.paragraph',
                },
                lastModifyTime: {
                    type: 'string.date',
                    displayName: '<<zxsj_1>>',
                },
                lastModifyUserName: {
                    type: 'string',
                    displayName: '<<zxczr>>',
                },
                lastedLoginTime: {
                    type: 'string.date',
                    displayName: '<<zhdlsj>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<zhjcxx>>',
                    properties: {
                        phone: {
                            type: 'string.phone',
                            displayName: '<<phoneNum>>',
                            rules: [
                                { required: true, message: '<<qsrsjh>>' },
                                {
                                    pattern: /^[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、\d]{11,20}$/,
                                    message: '<<qsrzqgsdsjh>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrdlsjh>>',
                                maxLength: 20,
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        userName: {
                            type: 'string',
                            displayName: '<<dlzh>>',
                            rules: [
                                { required: true, message: '<<qsrdlzh>>' },
                                {
                                    pattern: /^[A-Za-z0-9`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、]{4,32}$/,
                                    message: '<<userNameRule>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrdlzh>>',
                                maxLength: 32,
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        password: {
                            type: 'string.passwordPlus',
                            displayName: '<<dlmm>>',
                            rules: [{ required: true, message: '<<qsrdlmm>>' }],
                            controlConfig: {
                                openCheck: true,
                                placeholder: '<<qsrdlmm>>',
                                extraStatus: true,
                                maxLength: 40,
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        realName: {
                            type: 'string',
                            displayName: '<<ygxm>>',
                            rules: [
                                { max: 100 },
                                { required: true, whitespace: true, message: '<<qsrygzsxm>>' },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrygzsxm>>',
                                maxLength: 100,
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        staffCode: {
                            type: 'string',
                            displayName: '<<ygbm>>',
                            controlConfig: {
                                placeholder: '<<qsrygbm>>',
                                maxLength: 100,
                                style: {
                                    width: 400,
                                },
                            },
                        },
                    },
                },
                authInfo: {
                    type: 'object.subForm',
                    displayName: '<<qxsz>>',
                    properties: {
                        organization: {
                            type: 'object.organization.selector',
                            displayName: '<<zzjg>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        organizations: {
                            type: 'array.organization.store',
                            displayName: '<<glmdzgsjqx>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        roles: {
                            type: 'array.role.selector',
                            displayName: '<<gljs>>',
                            rules: [{ required: true }],
                        },
                    },
                },
                otherInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherSetting>>',
                    properties: {
                        merchantUser: {
                            type: 'string.options.radio',
                            displayName: '<<zjygzs>>',
                            defaultValue: '0',
                            options: [
                                { id: '0', name: '<<bcj>>' },
                                { id: '1', name: '<<cjzjzsyg>>' },
                            ],
                            extra: '<<zjsyzkzsdyxx>>',
                        },
                        passwordValidityPeriod: {
                            type: 'string.options.select',
                            displayName: '<<mmyxzq>>',
                            defaultValue: 'PERMANENT',
                            options: password_validity_period,
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        email: {
                            type: 'string',
                            displayName: '<<dlyx>>',
                            rules: [{ type: 'email', max: 100, message: '<<qsrzqgsddlyx>>' }],
                            controlConfig: {
                                placeholder: '<<qsrdlyx>>',
                                maxLength: 100,
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        wechatCardPicture: {
                            displayName: '<<enterpriseQrcode>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<avatarExtra>>',
                        },
                        avatarUrl: {
                            displayName: '<<tx_1>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<avatarExtra>>',
                        },
                        introduction: {
                            displayName: '<<remark>>',
                            type: 'string.text.paragraph',
                            rules: [{ max: 400, message: '<<introductionRule>>' }],
                            controlConfig: {
                                placeholder: '<<qsrbz>>',
                                maxLength: 400,
                                rows: 4,
                                style: {
                                    width: 400,
                                },
                            },
                        },
                    },
                },
            },
            filters: {
                phone: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                realName: {
                    type: 'string',
                    displayName: '<<ygxm>>',
                },
                userName: {
                    type: 'string',
                    displayName: '<<dlzh>>',
                },
                roleName: {
                    type: 'string',
                    displayName: '<<js_4>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<accountStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        {
                            id: 'ALL',
                            name: '<<all>>',
                        },
                        { id: 'ACTIVE', name: '<<enable>>' },
                        { id: 'INACTIVE', name: '<<disabled>>' },
                        { id: 'SUSPEND', name: '<<invalid>>' },
                        { id: 'REVOKED', name: '<<off>>' },
                    ],
                },
                organizationName: {
                    type: 'string',
                    displayName: '<<zzjg>>',
                },
                organizationId: {
                    type: 'string',
                    displayName: '<<organizationId>>',
                },
            },
        },
        NewUserManageLog: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-EVENT-TRACKING`,
            apiPath: '/loader/admin/merchant-users-log',
            properties: {
                createTime: {
                    type: 'string.date',
                    displayName: '<<zxsj>>',
                },
                operationType: {
                    type: 'string',
                    displayName: '<<bgx>>',
                },
                updateBefore: {
                    type: 'string',
                    displayName: '<<bgq>>',
                },
                updateAfter: {
                    type: 'string',
                    displayName: '<<bgh>>',
                },
                createUser: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
            },
        },
    },
    components: {
        NewUserView: {
            component: 'Viewport',
            entity: 'NewUserManage',
        },
        NewUserManagePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'NewUserManageFilter',
                },
                { component: 'NewUserManageViewContainer' },
            ],
        },
        NewUserManageFilter: {
            component: 'FilterForm',
            direction: 'inline',
            style: {
                display: 'none',
            },
            fields: [],
        },
        NewUserManageTable: {
            component: 'NewUserManageList',
        },
        NewUserInfoPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'NewUserManage',
            loaderType: 'get',
            items: [
                {
                    component: 'Detail',
                    labelCol: 6,
                    controlCol: 18,
                    style: { width: 720 },
                    fields: [
                        { property: 'baseInfo.phone' },
                        { property: 'baseInfo.userName', modifiable: false },
                        { property: 'baseInfo.realName' },
                        { property: 'baseInfo.staffCode' },
                        {
                            property: 'status',
                            displayConfig: {
                                statusConfig: {
                                    ACTIVE: {
                                        text: '<<enable>>',
                                        status: 'success',
                                    },
                                    INACTIVE: {
                                        text: '<<disabled>>',
                                        status: 'error',
                                    },
                                    SUSPEND: {
                                        text: '<<invalid>>',
                                        status: 'error',
                                    },
                                    REVOKED: {
                                        text: '<<off>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                        { property: 'avatarUrl' },
                        { property: 'otherInfo.wechatCardPicture' },
                        { property: 'authInfo.organization', label: '<<sszzjg>>' },
                        { property: 'authInfo.organizations', label: '<<organizations>>' },
                        { property: 'authInfo.roles', label: '<<js_4>>' },
                        { property: 'otherInfo.passwordValidityPeriod' },
                        { property: 'otherInfo.email' },
                        { property: 'createTime', label: '<<shtgrq>>' },
                        { property: 'createUserName', label: '<<cjshr>>' },
                        { property: 'lastedLoginTime' },
                        { property: 'lastModifyTime' },
                        { property: 'lastModifyUserName' },
                        { property: 'introduction' },
                    ],
                    footer: {
                        items: [
                            {
                                htmlType: 'button',
                                route: 'goBack',
                                type: 'primary',
                                text: '<<common.cancel>>',
                            },
                        ],
                    },
                },
            ],
        },
        NewUserInfoEditPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'NewUserInfoEditForm' }],
        },
        NewUserInfoEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'NewUserManage',
            loaderType: 'get',
            labelCol: 6,
            controlCol: 18,
            style: { width: 800 },
            fields: [
                { property: 'baseInfo.phone' },
                { property: 'baseInfo.userName', modifiable: false },
                { property: 'baseInfo.realName' },
                { property: 'baseInfo.staffCode' },
                { property: 'authInfo.organization' },
                { property: 'authInfo.organizations' },
                { property: 'authInfo.roles' },
                { property: 'otherInfo.passwordValidityPeriod' },
                { property: 'otherInfo.email' },
                { property: 'otherInfo.wechatCardPicture' },
                { property: 'otherInfo.avatarUrl' },
                { property: 'otherInfo.introduction' },
                {
                    property: 'extendAttributes',
                    className: 'auth-item-hidden',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const organization = get(entity, 'authInfo.organization');
                let flag = true;
                if (!organization || !organization.organizationId) {
                    message.error(language.getText('qxzzzjg'));
                    flag = false;
                }
                return flag;
            },
            onSubmitSuccess: () => {
                message.success(language.getText('zhbjcg'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        NewUserInfoAddPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'NewUserInfoAddForm' }],
        },
        NewUserInfoAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'NewUserManage',
            labelCol: 6,
            controlCol: 18,
            style: { width: 800 },
            fields: [
                { property: 'baseInfo.phone' },
                { property: 'baseInfo.userName' },
                { property: 'baseInfo.password' },
                { property: 'baseInfo.realName' },
                { property: 'baseInfo.staffCode' },
                { property: 'authInfo.organization' },
                { property: 'authInfo.organizations' },
                { property: 'authInfo.roles' },
                { property: 'otherInfo.merchantUser' },
                { property: 'otherInfo.passwordValidityPeriod' },
                { property: 'otherInfo.email' },
                { property: 'otherInfo.wechatCardPicture' },
                { property: 'otherInfo.avatarUrl' },
                { property: 'otherInfo.introduction' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: async (entity: any) => {
                const organization = get(entity, 'authInfo.organization');
                const password = get(entity, 'baseInfo.password');
                const res: any = await api.get(
                    {
                        password,
                    },
                    {
                        apiPath: `/open_api/password_strength/verify`,
                        apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
                    }
                );
                if (!res.ok) {
                    message.error(res.msg);
                    return false;
                }
                let flag = true;
                if (!organization || !organization.organizationId) {
                    message.error(language.getText('qxzzzjg'));
                    flag = false;
                }
                return flag;
            },
            onSubmitSuccess: async (fields: any) => {
                const goBack = () => {
                    message.success(language.getText('zhxzcg'), () =>
                        services.behaviorHandle({ route: 'goBack' })
                    );
                };
                const entityFields = mapValues(fields, (field, name) => {
                    return field.value;
                });
                const createMerchantUser = get(entityFields, 'otherInfo.merchantUser');
                if (createMerchantUser !== '1') {
                    goBack();
                    return;
                }
                const organization = get(entityFields, 'authInfo.organization');
                if (organization.organizationType !== 'merchant') {
                    message.warning(language.getText('fzjzzjgzh'));
                    goBack();
                    return;
                }
                const mobile = get(entityFields, 'baseInfo.phone');
                const name = get(entityFields, 'baseInfo.realName');
                const wechatCardPicture = get(entityFields, 'otherInfo.wechatCardPicture[0]', null);
                const avatar = get(entityFields, 'otherInfo.avatarUrl[0]', null);
                const apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
                const apiPath = '/admin/merchant_users/by_mobile';
                const merchantUser = await api.get<any>(
                    { mobile, stateless: true },
                    { apiRoot, apiPath }
                );
                if (!merchantUser) {
                    api.post(
                        {
                            avatar,
                            name,
                            mobile,
                            wechatCardPicture,
                            subSiteId: organization.subsiteId,
                            merchantId: organization.originId,
                            status: true,
                        },
                        { apiRoot, apiPath: '/admin/merchant_users' }
                    );
                } else {
                    merchantUser.merchantId = organization.originId;
                    merchantUser.subSiteId = organization.subsiteId;
                    merchantUser.name = name;
                    merchantUser.status = merchantUser.status === 'ENABLED';
                    !!avatar && (merchantUser.avatar = avatar);
                    !!wechatCardPicture && (merchantUser.wechatCardPicture = wechatCardPicture);
                    api.put(merchantUser, {
                        apiRoot,
                        apiPath: `/admin/merchant_users/${merchantUser.id}`,
                    });
                }
                goBack();
            },
        },
        NewUserInfoLogPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'NewUserInfoLogTable',
                },
            ],
        },
        NewUserInfoLogTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'NewUserManageLog',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'createTime',
                        },
                        {
                            property: 'operationType',
                        },
                        {
                            property: 'updateBefore',
                        },
                        {
                            property: 'updateAfter',
                        },
                        {
                            property: 'createUser',
                        },
                    ],
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/new-user-manage',
                    breadcrumbName: '<<newUserManage>>',
                    component: 'NewUserView',
                    privilege: {
                        path: 'user_manage',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/user-add',
                            component: 'NewUserInfoAddPage',
                            breadcrumbName: '<<xzzh_1>>',
                        },
                        {
                            path: '/user-info/:id',
                            component: 'NewUserInfoPage',
                            breadcrumbName: '<<ckzh>>',
                        },
                        {
                            path: '/user-edit/:id',
                            component: 'NewUserInfoEditPage',
                            breadcrumbName: '<<bjzh>>',
                        },
                        {
                            path: '/user-log/:id',
                            component: 'NewUserInfoLogPage',
                            breadcrumbName: '<<operatorLog>>',
                        },
                        { path: '/', component: 'NewUserManagePage' },
                    ],
                },
            ],
        },
    ],
};
