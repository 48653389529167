import { Button } from '@comall-backend-builder/components-basis';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { api, errorHandle, language } from '@comall-backend-builder/core/lib/services';
import { Modal as AntModal, Input as AntInput, message as AntMessage } from 'antd';
import React, { Component } from 'react';
import { trim } from 'lodash';

export interface ContentNoteDeleteProps {
    fields: any;
    row: any;
    entity: Entity;
    componentId: string;
}
interface ContentNoteDeleteState {
    modalVisible: boolean;
    remark: string;
}
// 人工审核笔记 - 删除笔记操作
export class ContentNoteDelete extends Component<ContentNoteDeleteProps, ContentNoteDeleteState> {
    constructor(props: ContentNoteDeleteProps) {
        super(props);
        this.state = {
            modalVisible: false,
            remark: '',
        };
    }

    // 打开 删除笔记弹窗
    open = () => {
        this.setState({ modalVisible: true });
    };

    // 关闭 删除笔记弹窗
    close = () => {
        this.setState({ modalVisible: false, remark: '' });
    };

    // 填写删除备注
    inputChange = (e: any) => {
        const { value } = e.target;
        this.setState({ remark: value });
    };

    // 提交删除
    submit = () => {
        const { remark } = this.state;

        if (!trim(remark)) {
            AntMessage.success(language.getText('qtxbz'));
            return;
        }

        const {
            entity,
            row: { id },
        } = this.props;
        api.put(
            {
                deleteRemark: remark,
                id,
            },
            {
                apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
                apiPath: '/admin/notes/:id',
            }
        )
            .then(() => {
                AntMessage.success(language.getText('sccg'));

                this.close();

                // 列表数据刷新
                entity.search({
                    ...entity.params,
                    ...entity.filters,
                });
            })
            .catch(errorHandle);
    };

    render() {
        const { modalVisible, remark } = this.state;
        const {
            row: { detailStatus },
        } = this.props;
        return detailStatus === 'NORMAL' ? (
            <span>
                <Button type="link" onClick={this.open} text={language.getText('scbj')} />
                <AntModal
                    visible={modalVisible}
                    okText={language.getText('tjsc')}
                    cancelText={language.getText('qxcz')}
                    title={language.getText('scbjbz')}
                    onOk={this.submit}
                    onCancel={this.close}
                    cancelButtonProps={{
                        onClick: this.close,
                    }}
                >
                    <div>
                        <AntInput.TextArea
                            autoSize={{ minRows: 4, maxRows: 4 }}
                            onChange={this.inputChange}
                            value={remark}
                        />
                    </div>
                </AntModal>
            </span>
        ) : (
            <span style={{ color: 'rgba(0, 0, 0, 0.25)' }}>{language.getText('scbj')}</span>
        );
    }
}
