import React, { PureComponent } from 'react';
import { cloneDeep, findIndex, forEach, find } from 'lodash';
import { TagSelectedDisplay, TagSelector, TagSelectedDisplayMode } from '../../components';

interface Shop {
    id: string;
    subSiteId: string;
    subSiteName: string;
    merchantId: string;
    merchantName: string;
    merchantType: string;
    merchantStatus: string;
    code?: string;
    /**
     * 被选中的标签
     */
    select?: boolean;
    /**
     * 将要被删除的标签
     */
    delete?: boolean;
}
interface Property {
    property: string;
}

interface SelectTagProps<T> {
    value: any[];
    onChange: (v: any) => void;
    type: 'radio' | undefined;
    disabled?: boolean;
    displayMode?: TagSelectedDisplayMode;
}

export class SelectTag<T> extends PureComponent<
    SelectTagProps<T>,
    {
        value: Array<Shop>;
    }
> {
    static defaultProps = {
        disabled: false,
    };
    state = { value: new Array<Shop>() };

    /**
     * 选择标签
     * @param selectValues
     */
    onChange = (selectValues: Shop[]) => {
        const { onChange } = this.props;
        if (onChange) {
            if (selectValues && selectValues.length === 0) {
                onChange(undefined);
            } else {
                onChange(selectValues);
            }
        }
    };

    /**
     * 改变已选择标签选中状态
     * @param selectValues
     */
    onChangeSelect = (selectValues: Shop[]) => {
        const { onChange, value } = this.props;

        const newData: Shop[] = cloneDeep(value);
        forEach(newData, (brand) => {
            const selectBrand = find(selectValues, (selectBrand) => {
                return selectBrand.id === brand.id;
            });

            if (selectBrand) {
                brand.select = true;
            } else {
                brand.select = false;
            }
        });

        if (onChange) {
            if (newData && newData.length === 0) {
                onChange(undefined);
            } else {
                onChange(newData);
            }
        }
    };

    processSelectedTag = (data: any[]) => {
        let items: any[] = [];
        if (data && data.length) {
            forEach(data, (tag) => {
                forEach(tag.merchants, (merchants: any) => {
                    let tagItem = find(items, { id: merchants.id });
                    if (tagItem) {
                        tagItem.tagName = tagItem.tagName + ',' + tag.name;
                    } else {
                        items.push({
                            id: merchants.id,
                            name: merchants.name,
                            subsiteName: merchants.subsiteName,
                            tagName: tag.name,
                            select: true,
                            subsiteId: merchants.subsiteId,
                        });
                    }
                });
            });
        }
        return items;
    };

    /**
     * 添加标签弹窗确认后回调
     * @param selectValues 选中标签
     */
    onSelect = (selectValues: Shop[]) => {
        const { value, type } = this.props;
        const data = this.processSelectedTag(selectValues);
        let newData: Shop[] = [];

        if ('radio' !== type) {
            if (value) {
                newData = cloneDeep(value);
            }
            if (data) {
                data.forEach((selectBrand: any) => {
                    const hasIndex = findIndex(value, (brand: any) => brand.id === selectBrand.id);
                    if (hasIndex === -1) {
                        newData.unshift(selectBrand);
                    }
                });
            }
        } else {
            newData = data;
        }
        this.onChange(newData);
    };

    render() {
        const { value, type, disabled, displayMode } = this.props;
        const hasValue = value && value.length > 0;

        return (
            <div>
                {!disabled && (
                    <TagSelector type={type} onChange={this.onSelect} selectValues={value} />
                )}
                {hasValue && (
                    <TagSelectedDisplay
                        data={value}
                        onChange={this.onChangeSelect}
                        onRemove={this.onChange}
                        disabled={disabled}
                        mode={displayMode}
                    ></TagSelectedDisplay>
                )}
            </div>
        );
    }
}
