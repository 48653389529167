import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        OfflineOrderReviewEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
            apiPath: '/loader/admin/offline_payment_bill/manual_review_records',
            filters: {
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 200 } },
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<ddh_1>>',
                    controlConfig: { placeholder: '<<inputPlease>>', style: { width: 250 } },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<czrq>>',
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 300 } },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                createTime: {
                    type: 'string.date',
                    displayName: '<<czrq>>',
                },
                memberName: {
                    type: 'string',
                    displayName: '<<gkr>>',
                },
                memberMobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<ddh_1>>',
                },
                creatorName: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
                orderType: {
                    type: 'string',
                    displayName: '<<ddlx>>',
                },
            },
        },
        OfflineOrderEntity: {
            apiPath: '/loader/admin/order/list',
            filters: {
                memberMobile: {
                    type: 'string',
                    displayName: '<<xdrsjh>>',
                },
                receiverMobile: {
                    type: 'string',
                    displayName: '<<thrsjh>>',
                },
            },
            properties: {
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<ddh_1>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<xdsj>>',
                },
                orderAmount: {
                    type: 'string',
                    displayName: '<<orderAmount>>',
                },
                nickName: {
                    type: 'string',
                    displayName: '<<xdr>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<xdrsjh>>',
                },
                receiverMobile: {
                    type: 'string',
                    displayName: '<<thrsjh>>',
                },
            },
        },
    },
    components: {
        OfflineOrderReviewView: {
            component: 'Viewport',
            entity: 'OfflineOrderReviewEntity',
        },
        OfflineOrderReviewPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'OfflineOrderReviewFilter',
                    span: 24,
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            style: {
                                display: 'flex',
                            },
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xsddfh>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    route: '/offline-order-review/add',
                                    style: {
                                        marginRight: '20px',
                                    },
                                },
                                {
                                    component: 'Button',
                                    text: '<<xxddfh>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    route: '/offline-order-review/add-offline',
                                },
                            ],
                        },
                    ],
                },
                { component: 'OfflineOrderReviewTable' },
            ],
        },
        OfflineOrderReviewFilter: {
            component: 'FilterForm',
            direction: 'inline',
            fields: [
                {
                    property: 'subsiteIds',
                },
                {
                    property: 'orderNo',
                },
                {
                    property: 'dateRange',
                },
            ],
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 12,
                },
            },
            reset: true,
        },
        OfflineOrderReviewTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'createTime',
                },
                {
                    property: 'memberName',
                },
                {
                    property: 'memberMobile',
                },
                {
                    property: 'subsiteName',
                },
                {
                    property: 'orderType',
                },
                {
                    property: 'orderNo',
                },
                {
                    property: 'creatorName',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 220,
                items: [
                    {
                        type: 'link',
                        text: '<<common.view>>',
                        path: '/offline-order-review/{{row.path}}/{{row.id}}',
                    },
                ],
            },
        },
        OnlineOrderReviewAddPage: {
            component: 'Card',
            entity: 'OfflineOrderReviewEntity',
            content: { component: 'OnlineOrderReviewForm' },
        },
        OnlineOrderReviewDetailPage: {
            component: 'Card',
            entity: 'OfflineOrderReviewEntity',
            content: { component: 'OnlineOrderReviewForm' },
        },
        OfflineOrderReviewAddPage: {
            component: 'Card',
            entity: 'OfflineOrderReviewEntity',
            content: { component: 'OfflineOrderReviewForm' },
        },
        OfflineOrderReviewDetailPage: {
            component: 'Card',
            entity: 'OfflineOrderReviewEntity',
            content: { component: 'OfflineOrderReviewForm' },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/offline-order-review',
                    component: 'OfflineOrderReviewView',
                    breadcrumbName: '<<OfflineOrderReview>>',
                    privilege: {
                        path: 'offlinePaymentBillManualReview',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'OnlineOrderReviewAddPage',
                        },
                        {
                            path: '/add-offline',
                            component: 'OfflineOrderReviewAddPage',
                        },
                        {
                            path: '/detail/:id',
                            component: 'OnlineOrderReviewDetailPage',
                        },
                        {
                            path: '/offline-detail/:id',
                            component: 'OfflineOrderReviewDetailPage',
                        },
                        { path: '/', component: 'OfflineOrderReviewPage' },
                    ],
                },
            ],
        },
    ],
};
