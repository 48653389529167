import React, { PureComponent } from 'react';
import { Table, Button, message, Popconfirm } from 'antd';
import { cloneDeep, remove, find } from 'lodash';

import './index.less';
import { services } from '@comall-backend-builder/core';

interface ProductCategorySelectedDisplayProps {
    data: any[];
    onChange: (data: any[]) => void;
    modifiable: boolean;
    type: string;
    productDisplaySelectValueType?: string;
}

/**
 * 选择商品组件-选择完以商品的维度展示
 */
class ProductCategorySelectedDisplay extends PureComponent<
    ProductCategorySelectedDisplayProps,
    {}
> {
    selectedRows: any[] = [];

    getProductTableConfig = () => {
        const { modifiable } = this.props;
        let config: any = {
            rowKey: 'id',
            columns: [
                {
                    title: services.language.getText('components.Products.name'),
                    dataIndex: 'name',
                    key: 'name',
                    width: 150,
                    fixed: 'left' as const,
                    render: (i: string) => (
                        <span title={i} className="goods-field-name">
                            {i}
                        </span>
                    ),
                },
                {
                    title: services.language.getText('merchantName'),
                    dataIndex: 'merchantName',
                    key: 'merchantName',
                    width: 120,
                },
                {
                    title: services.language.getText('productCode'),
                    dataIndex: 'code',
                    key: 'code',
                    width: 100,
                    align: 'center' as const,
                },
            ],
            scroll: {
                x: 850,
                y: 500,
            },
        };
        if (modifiable) {
            config.columns.push({
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                key: 'operation',
                fixed: 'right',
                width: 100,
                render: (i: any, row: any) => {
                    return (
                        <Button type={'link'} onClick={this.onRemove(row.id)}>
                            {services.language.getText('common.delete')}
                        </Button>
                    );
                },
            });
            config.rowSelection = {
                onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                    this.selectedRows = selectedRows;
                },
            };
        }
        return config;
    };

    getGoodTableConfig = () => {
        const { modifiable } = this.props;
        let config: any = {
            rowKey: 'id',
            columns: [
                {
                    title: services.language.getText('components.Products.name'),
                    dataIndex: 'productName',
                    key: 'productName',
                    width: 170,
                    render: (i: string) => (
                        <span title={i} className="goods-field-name">
                            {i}
                        </span>
                    ),
                },
                {
                    title: services.language.getText('goodName'),
                    dataIndex: 'name',
                    key: 'name',
                    width: 170,
                    render: (i: string) => (
                        <span title={i} className="goods-field-name">
                            {i}
                        </span>
                    ),
                },
                {
                    title: services.language.getText('goodCode'),
                    dataIndex: 'goodsCode',
                    key: 'goodsCode',
                    width: 120,
                },
                {
                    title: services.language.getText('subsiteName'),
                    dataIndex: 'subsiteName',
                    key: 'subsiteName',
                    width: 120,
                },
                {
                    title: services.language.getText('merchantName'),
                    dataIndex: 'merchantName',
                    key: 'merchantName',
                    width: 120,
                },
                {
                    title: services.language.getText('xsj'),
                    dataIndex: 'salesPrice',
                    key: 'salesPrice',
                    width: 100,
                    align: 'center' as const,
                },
                {
                    title: '库存',
                    dataIndex: 'goodsStock',
                    key: 'goodsStock',
                    width: 100,
                    align: 'center' as const,
                },
            ],
            scroll: {
                x: 850,
                y: 500,
            },
        };
        if (modifiable) {
            config.columns.push({
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                key: 'operation',
                fixed: 'right',
                width: 100,
                render: (i: any, row: any) => {
                    return (
                        <Button type={'link'} onClick={this.onRemove(row.id)}>
                            {services.language.getText('common.delete')}
                        </Button>
                    );
                },
            });
            config.rowSelection = {
                onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                    this.selectedRows = selectedRows;
                },
            };
        }
        return config;
    };

    getBrandsTableConfig = () => {
        const { modifiable } = this.props;
        let config: any = {
            rowKey: 'id',
            columns: [
                {
                    title: services.language.getText('brandNameCn'),
                    dataIndex: 'name',
                    key: 'name',
                    width: 150,
                    render: (i: string) => (
                        <span title={i} className="goods-field-name">
                            {i}
                        </span>
                    ),
                },
                {
                    title: services.language.getText('brandNameEn'),
                    dataIndex: 'nameEn',
                    key: 'nameEn',
                    width: 150,
                    render: (i: string) => (
                        <span title={i} className="goods-field-name">
                            {i}
                        </span>
                    ),
                },
            ],
            scroll: {
                x: 500,
                y: 500,
            },
        };
        if (modifiable) {
            config.columns.push({
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                key: 'operation',
                fixed: 'right',
                width: 100,
                render: (i: any, row: any) => {
                    return (
                        <Button type={'link'} onClick={this.onRemove(row.id)}>
                            {services.language.getText('common.delete')}
                        </Button>
                    );
                },
            });
            config.rowSelection = {
                onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                    this.selectedRows = selectedRows;
                },
            };
        }
        return config;
    };

    getMerchantBigCodeTableeConfig = () => {
        const { modifiable } = this.props;
        let config: any = {
            rowKey: 'id',
            columns: [
                {
                    title: services.language.getText('bigCode'),
                    dataIndex: 'code',
                    key: 'code',
                    width: 150,
                    render: (i: string) => (
                        <span title={i} className="goods-field-name">
                            {i}
                        </span>
                    ),
                },
                {
                    title: services.language.getText('bigCodeName'),
                    dataIndex: 'name',
                    key: 'name',
                    width: 150,
                    render: (i: string) => (
                        <span title={i} className="goods-field-name">
                            {i}
                        </span>
                    ),
                },
                {
                    title: services.language.getText('subsites'),
                    dataIndex: 'subsiteName',
                    key: 'subsiteName',
                    width: 150,
                    render: (i: string) => (
                        <span title={i} className="goods-field-name">
                            {i}
                        </span>
                    ),
                },
                {
                    title: services.language.getText('merchant'),
                    dataIndex: 'merchantName',
                    key: 'merchantName',
                    width: 150,
                    render: (i: string) => (
                        <span title={i} className="goods-field-name">
                            {i}
                        </span>
                    ),
                },
            ],
            scroll: {
                x: 500,
                y: 500,
            },
        };
        if (modifiable) {
            config.columns.push({
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                key: 'operation',
                fixed: 'right',
                width: 100,
                render: (i: any, row: any) => {
                    return (
                        <Button type={'link'} onClick={this.onRemove(row.id)}>
                            {services.language.getText('common.delete')}
                        </Button>
                    );
                },
            });
            config.rowSelection = {
                onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                    this.selectedRows = selectedRows;
                },
            };
        }
        return config;
    };

    renderFooter = () => {
        return (
            <div className="product-style-selected-footer">
                <div className="batch-remove">
                    <Popconfirm
                        onConfirm={this.shareStockRemove()}
                        title={services.language.getText('confirmDeleteColumn')}
                        okText={services.language.getText('common.ok')}
                        cancelText={services.language.getText('common.cancel')}
                    >
                        <Button className="setting-item-ok" type="default">
                            {services.language.getText('batchDelete')}
                        </Button>
                    </Popconfirm>
                </div>
            </div>
        );
    };

    shareStockRemove = () => {
        return () => {
            // @ts-ignore：
            const selectedRow = this.selectedRows;
            if (!selectedRow || selectedRow.length === 0) {
                message.info(services.language.getText('selectBatchDeleteColumn'));
                return;
            }
            const { data, onChange } = this.props;
            const result = cloneDeep(data);
            remove(result, (i) => !!find(selectedRow, (j) => j.id === i.id));
            onChange(result);
        };
    };

    onRemove = (id: string) => {
        const { data, onChange } = this.props;
        return () => {
            const result = cloneDeep(data);
            remove(result, (i) => id === i.id);
            onChange(result);
        };
    };

    render() {
        const { data, modifiable, type, productDisplaySelectValueType } = this.props;
        let tableConfig;
        if (type === 'APPOINT_BRAND') {
            tableConfig = this.getBrandsTableConfig();
        } else if (type === 'APPOINT_MERCHANT_BIG_CODE') {
            tableConfig = this.getMerchantBigCodeTableeConfig();
        } else {
            if (productDisplaySelectValueType && productDisplaySelectValueType === 'goods') {
                //保存商品维度
                tableConfig = this.getGoodTableConfig();
            } else {
                //保存货品维度
                tableConfig = this.getProductTableConfig();
            }
        }
        const tableProps = { ...tableConfig, dataSource: data };
        return (
            <div className={'product-style-selected-display' + (modifiable ? 'modify' : '')}>
                <Table {...tableProps} pagination={false}></Table>
                {modifiable && this.renderFooter()}
            </div>
        );
    }
}

export { ProductCategorySelectedDisplay };
