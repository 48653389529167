import React, { Component } from 'react';
import { Button, message, message as AntMessage, Modal, Pagination, Upload } from 'antd';
import { services } from '@comall-backend-builder/core';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import { cloneDeep } from 'lodash';
import { WechatImage } from '../../components/wechant-content';
import './index.less';

interface Props {
    appId: string;
    visible: boolean;
    onConfrim: (value: WechatImage) => void;
    onCancel: () => void;
    editInfo?: WechatImage;
}

interface States {
    datasource: Array<WechatImage>;
    total: number;
    current: number;
    pagination: {
        page: number;
        perPage: number;
    };
    /**
     * 选择的图片
     */
    chooseWechatImage?: WechatImage;
}
const { api } = services;
export class WechatOfficialAutoReplySettingPageImageModal extends Component<Props, States> {
    constructor(props: any) {
        super(props);

        this.state = {
            datasource: [],
            total: 0,
            current: 0,
            pagination: {
                page: 1,
                perPage: 12,
            },
            chooseWechatImage: undefined,
        };
    }

    componentDidMount() {
        this.onSearch();
        //编辑回显
        const { editInfo } = this.props;
        if (editInfo) {
            this.setState({
                chooseWechatImage: cloneDeep(editInfo),
            });
        }
    }

    onSearch = async () => {
        const { appId } = this.props;
        const { pagination } = this.state;
        try {
            let res: any = await api.get(
                {
                    ...pagination,
                    appId: appId,
                    type: 'IMAGE',
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                    apiPath: '/admin/wechat_official_account_materials',
                }
            );
            if (res && res.result) {
                this.setState({
                    datasource: res.result,
                    total: res.totalNum,
                    current: res.page,
                });
            }
        } catch (error) {
            throw error;
        }
    };

    pageChange = (pageVal: any) => {
        this.setState({ pagination: { ...pageVal } }, () => {
            this.onSearch();
        });
    };

    OK = () => {
        const { onConfrim } = this.props;
        const { chooseWechatImage } = this.state;
        if (!chooseWechatImage) {
            AntMessage.warning(services.language.getText('qxztp'));
            return false;
        }
        onConfrim(chooseWechatImage);
    };

    onChangeSource = (chooseWechatImage: WechatImage) => {
        this.setState({
            chooseWechatImage: chooseWechatImage,
        });
    };

    delete = (source: WechatImage) => {
        Modal.confirm({
            content: services.language.getText('qrsfscgtp'),
            iconType: 'warning',
            okType: 'danger',
            onOk: () => {
                const { appId } = this.props;
                api.delete(
                    {},
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                        apiPath: `/admin/wechat_official_account_materials?app_id=${appId}&media_id=${source.mediaId}`,
                    }
                )
                    .then(() => {
                        this.onSearch();
                        //如果删除的是现在选中的，那么需要清空选中数据
                        const { chooseWechatImage } = this.state;
                        if (chooseWechatImage && chooseWechatImage.mediaId === source.mediaId) {
                            this.setState({
                                chooseWechatImage: undefined,
                            });
                        }
                    })
                    .catch(errorHandle);
            },
        });
    };

    renderList = () => {
        const { datasource, pagination, total, current, chooseWechatImage } = this.state;
        const paginationCongid = {
            pageSize: pagination.perPage,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['12', '24', '36'],
            total,
            current,
            showTotal(total: number) {
                return services.interpolate(services.language.getText('total'), {
                    total,
                });
            },
            onChange: (page: number | undefined, perPage: number | undefined) => {
                this.pageChange({ page, perPage });
            },
            onShowSizeChange: (page: number | undefined, perPage: number | undefined) => {
                this.pageChange({ page, perPage });
            },
        };

        const checkedImage = require('../images/icon-checked.png');
        const deleteImage = require('../images/icon-delete.png');

        return datasource && datasource.length > 0 ? (
            <div className="datasource">
                <div className="datasource-list">
                    {datasource &&
                        datasource.map((source, index) => {
                            const style = {
                                border:
                                    chooseWechatImage &&
                                    chooseWechatImage.mediaId === source.mediaId
                                        ? '1px solid #1890ff'
                                        : '1px solid #eee',
                            };
                            return (
                                <div
                                    className="datasource-item"
                                    style={style}
                                    onClick={this.onChangeSource.bind(this, source)}
                                    key={index}
                                >
                                    <img
                                        className="datasource-item-image"
                                        alt=""
                                        src={source.url}
                                    />
                                    {chooseWechatImage &&
                                        chooseWechatImage.mediaId === source.mediaId && (
                                            <img
                                                className="datasource-item-image-checked"
                                                alt=""
                                                src={checkedImage}
                                            />
                                        )}
                                    <div
                                        onClick={this.delete.bind(this, source)}
                                        className="datasource-item-image-delete"
                                    >
                                        <img width={20} height={20} alt="" src={deleteImage} />
                                    </div>
                                </div>
                            );
                        })}
                </div>
                <div className="datasource-pagination">
                    <Pagination {...paginationCongid} />
                </div>
            </div>
        ) : (
            <div className="empty-list">{services.language.getText('notData')}</div>
        );
    };

    renderAdd = () => {
        const { appId } = this.props;
        const fileList: any = [];
        const uploadProps = {
            accept: 'image/jpg, image/png, image/gif, image/jpeg',
            maxCount: 1,
            multiple: false,
            fileName: 'fileName',
            uploadType: 'multi',
            mode: 'picture-card',
            beforeUpload: (file: any) => {
                const maxSize = 10 * 1024 * 1000;
                if (file.size > maxSize) {
                    AntMessage.warning(services.language.getText('tpcc_10'));
                    return false;
                }
                message.config({ duration: 20 });
                message.info(services.language.getText('sctpz'));
                const formData = new FormData();
                formData.append('file', file);
                formData.append('app_id', appId);
                formData.append('media_file_type', 'IMAGE');
                services.api
                    .upload(
                        { files: formData },
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                            apiPath: '/admin/wechat_official_account_materials',
                            fileName: 'files',
                            progressCallBack: () => {},
                            useCustomData: true,
                        }
                    )
                    .then((res) => {
                        this.onAddSuccess();
                    })
                    .catch((err) => {
                        if (err) {
                            message.error(err.message || services.language.getText('uoloadFail'));
                        }
                    })
                    .finally(() => {
                        message.config({ duration: 3 });
                        message.destroy();
                    });
                return false;
            },
            fileList,
        };
        return (
            <div className="button-wrap">
                <Upload {...uploadProps}>
                    <Button type="primary">{services.language.getText('sctp')}</Button>
                </Upload>
                <div className="button-wrap-tip">{services.language.getText('uploadPicLimit')}</div>
            </div>
        );
    };

    onAddSuccess = async () => {
        const { appId } = this.props;
        let res: any = await api.get(
            {
                page: 1,
                perPage: 12,
                appId: appId,
                type: 'IMAGE',
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                apiPath: '/admin/wechat_official_account_materials',
            }
        );
        if (res && res.result && res.result.length > 0) {
            const { onConfrim } = this.props;
            const chooseWechatImage = res.result[0];
            onConfrim(chooseWechatImage);
        }
    };

    render() {
        const { visible, onCancel } = this.props;
        return (
            <Modal visible={visible} width={1000} title="图片" onCancel={onCancel} onOk={this.OK}>
                <div className="wechat-official-auto-reply-setting-page-image-modal">
                    {this.renderAdd()}
                    {this.renderList()}
                </div>
            </Modal>
        );
    }
}
