import React, { Component } from 'react';
import { Radio, Form, InputNumber, Select } from 'antd';
import { services } from '@comall-backend-builder/core';
import { ShowCategory } from './show-category';
import { ShowContent } from './show-content';
import { tools } from '@comall-backend-builder/design-components-basis';

import './index.less';

const language = services.language;
const FormItemLayout = tools.FormItemLayout;
const ConfigFormDecorator = tools.ConfigFormDecorator;
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const Option = Select.Option;

export interface IProductMore {
    pic: string;
    title: string;
    text: string;
    border: boolean;
    linkParams: any;
    linkType: string;
}
/**
 * 瀑布流商品列表组件-配置信息
 *  */
class waterfallProducts extends Component<any> {
    render() {
        const { getFieldDecorator } = this.props.form;
        const { selector, type } = this.props;
        return (
            <Form className="editor-category">
                <FormItem
                    {...FormItemLayout}
                    label={language.getText('components.Products.showStyle')}
                >
                    {getFieldDecorator(
                        'showStyle',
                        {}
                    )(
                        <RadioGroup className="show-style">
                            <Radio value={'1'}>
                                {language.getText('components.Products.list')}
                            </Radio>
                            <Radio value={'3'}>一行两个</Radio>
                            <Radio value={'4'}>一行三个</Radio>
                        </RadioGroup>
                    )}
                </FormItem>

                <FormItem
                    {...FormItemLayout}
                    label={<span>{language.getText('components.Products.products')}&nbsp;</span>}
                >
                    {type === 'merchant' ? (
                        <div>全部专柜商品</div>
                    ) : (
                        getFieldDecorator('entry', {
                            initialValue: [],
                        })(<ShowCategory selector={selector} />)
                    )}
                </FormItem>
                <FormItem {...FormItemLayout} label={<span>商品排序</span>}>
                    {type === 'merchant' ? (
                        <div>
                            {getFieldDecorator('merchantSortOrder', {
                                initialValue: '1',
                            })(
                                <Select style={{ width: '150px' }}>
                                    <Option value="1">销量从高到低</Option>
                                    <Option value="4">价格从高到低</Option>
                                    <Option value="3">价格从低到高</Option>
                                </Select>
                            )}
                        </div>
                    ) : (
                        <div>
                            {getFieldDecorator('sortOrder', {
                                initialValue: '0',
                            })(
                                <Select style={{ width: '150px' }}>
                                    <Option value="0">按分类中排序</Option>
                                    <Option value="1">销量从高到低</Option>
                                    <Option value="4">价格从高到低</Option>
                                    <Option value="3">价格从低到高</Option>
                                </Select>
                            )}
                        </div>
                    )}
                </FormItem>
                <FormItem {...FormItemLayout} label={'显示个数'}>
                    {getFieldDecorator(
                        'showCountRadio',
                        {}
                    )(
                        <RadioGroup className="show-style">
                            <Radio value={'all'}>全部</Radio>
                            <Radio value={'other'}>
                                {getFieldDecorator('showCount')(
                                    <InputNumber
                                        min={1}
                                        step={1}
                                        precision={0}
                                        placeholder="自定义"
                                        style={{ width: '100px' }}
                                    />
                                )}
                            </Radio>
                        </RadioGroup>
                    )}
                </FormItem>

                <FormItem
                    {...FormItemLayout}
                    label={language.getText('components.Products.content')}
                >
                    {getFieldDecorator('showContent', {
                        initialValue: [],
                    })(<ShowContent getFieldDecorator={getFieldDecorator} />)}
                </FormItem>
            </Form>
        );
    }
}

export const WaterfallProducts = ConfigFormDecorator(waterfallProducts);
