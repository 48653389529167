import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import {
    Button,
    message as AntMessage,
    Modal as AntModal,
    Popconfirm,
    Table,
    Checkbox,
    Spin,
} from 'antd';

import { ColumnProps } from 'antd/es/table';
import { connect } from 'react-redux';

import './index.less';

const {
    api,
    language: { getText },
} = services;
interface TableItem {
    title: string;
    dataIndex: string;
}
const options = [
    { label: 'loginName', value: 'memberName' },
    { label: 'shrxm', value: 'receiveName' },
    { label: 'shrsj', value: 'mobile' },
    { label: 'shdq', value: 'regionName' },
    { label: 'detailAddress', value: 'address' },
    { label: 'remark', value: 'remark' },
];
const defaultShowItem = ['memberName', 'receiveName', 'mobile', 'regionName', 'address', 'remark'];
const fixed = 'black-list';
export class blackListTable extends PureComponent<any, any> {
    state = {
        datasource: [],
        searchParams: {},
        total: 0,
        current: 0,
        pagination: {
            page: 1,
            perPage: 10,
        },
        modalVisible: false,
        showItem: defaultShowItem,
        isLoading: false,
    };

    componentDidMount() {
        const { pagination } = this.state;

        this.setState({
            isLoading: true,
        });
        api.get(
            { ...pagination },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: '/admin/blacklist',
            }
        )
            .then((res: any) => {
                const datasource = res.result;
                const total = res.totalNum;
                this.setState({
                    datasource,
                    total,
                    isLoading: false,
                });
            })
            .catch(errorHandle);
    }
    componentWillReceiveProps(nextProps: any) {
        this.setState({
            isLoading: nextProps.isLoading,
        });
        if (nextProps.result) {
            const { totalNum, perPage, page } = nextProps.paging;
            const pagination = {
                page,
                perPage,
            };
            const total = totalNum;
            const current = page;
            this.setState({
                datasource: nextProps.result,
                pagination,
                total,
                current,
            });
        }
    }
    onSearch = async () => {
        const { pagination } = this.state;
        const { page, perPage } = pagination;
        const { entity, filters } = this.props;
        const paging = {
            page,
            perPage,
        };
        entity.pageChange(Object.assign({}, entity.paging, paging));
        entity.search({ ...filters });
    };
    pageChange = (pageVal: { page: any; perPage?: number | undefined }) => {
        this.setState({ pagination: { ...pageVal }, current: pageVal.page }, () => {
            this.onSearch();
        });
    };

    showListItem = () => {
        this.setState({
            modalVisible: true,
        });
    };

    renderButtons = () => {
        const innerClassName = {
            btnBox: `${fixed}--btn_box`,
            li: `${fixed}--btn_li`,
        };
        return (
            <div className={innerClassName.btnBox}>
                <Button type="default" onClick={this.showListItem} className={innerClassName.li}>
                    {getText('xzl')}
                </Button>
            </div>
        );
    };
    changeItem = (checkedValues: any) => {
        this.setState({
            showItem: checkedValues || [],
        });
    };
    resetShowItem = () => {
        this.setState({
            showItem: defaultShowItem,
        });
    };
    renderButtonsModal = () => {
        const { modalVisible, showItem } = this.state;
        const innerClassName = { box: `${fixed}--checkbox_box`, btn: `${fixed}--checkbox_box_btn` };
        const realOptions = options.map((o) => ({ ...o, label: getText(o.label) }));
        return (
            <AntModal
                visible={modalVisible}
                title={getText('xzl')}
                width={200}
                onCancel={this.closeModal}
                footer={null}
            >
                <div>
                    <div className={innerClassName.box}>
                        <Checkbox.Group
                            options={realOptions}
                            value={showItem}
                            onChange={this.changeItem}
                        />
                    </div>
                    <div>
                        <Button className={innerClassName.btn} onClick={this.resetShowItem}>
                            {getText('hfmr')}
                        </Button>
                    </div>
                    <div>
                        <Button className={innerClassName.btn} onClick={this.resetShowItem}>
                            {getText('xsqbl')}
                        </Button>
                    </div>
                </div>
            </AntModal>
        );
    };

    closeModal = () => {
        const { modalVisible } = this.state;
        this.setState({
            modalVisible: !modalVisible,
        });
    };
    deleteItem = (id: any) => {
        api.delete(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: `/admin/blacklist/${id}`,
            }
        )
            .then(() => {
                AntMessage.success(getText('sccg'));
                const { entity, filters } = this.props;
                entity.pageChange(Object.assign({}, entity.paging, { page: 1 }));
                entity.search({ ...filters });
            })
            .catch(errorHandle);
    };
    setTableColumns = () => {
        const { showItem } = this.state;
        const columns: ColumnProps<TableItem>[] = [];
        if (showItem.includes('memberName')) {
            columns.push({
                title: getText('loginName'),
                dataIndex: 'memberName',
                key: 'memberName',
            });
        }
        if (showItem.includes('receiveName')) {
            columns.push({
                title: getText('shrmc'),
                dataIndex: 'receiveName',
                key: 'receiveName',
            });
        }
        if (showItem.includes('mobile')) {
            columns.push({
                title: services.language.getText('shrsjh'),
                dataIndex: 'mobile',
                key: 'mobile',
            });
        }
        if (showItem.includes('regionName')) {
            columns.push({
                title: getText('shdq'),
                key: 'regionName',
                dataIndex: 'regionName',
            });
        }
        if (showItem.includes('address')) {
            columns.push({
                title: getText('detailAddress'),
                dataIndex: 'address',
                key: 'address',
            });
        }
        if (showItem.includes('remark')) {
            columns.push({
                title: getText('remark'),
                key: 'remark',
                dataIndex: 'remark',
            });
        }
        columns.push({
            title: services.language.getText('common.tableAction'),
            dataIndex: 'id',
            key: 'id',
            fixed: 'right',
            width: 120,
            render: (val: any) => {
                const editHref = '#/blacklist/edit/' + val;
                return (
                    <div className="black-list--action_column">
                        <a href={editHref}>{getText('common.edit')}</a>
                        <Popconfirm
                            title={getText('confirmDelete')}
                            onConfirm={() => this.deleteItem(val)}
                            okText={services.language.getText('common.ok')}
                            cancelText={services.language.getText('common.cancel')}
                        >
                            <a href="/#">{getText('sc_1')}</a>
                        </Popconfirm>
                    </div>
                );
            },
        });

        return columns;
    };
    render() {
        const { datasource, pagination, total, current, isLoading } = this.state;
        return (
            <div className="combine-goods-find">
                {this.renderButtons()}
                {this.renderButtonsModal()}
                <Spin spinning={isLoading}>
                    <Table
                        scroll={{ x: true }}
                        rowKey={(record: any) => record.id}
                        pagination={{
                            pageSize: pagination.perPage,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            pageSizeOptions: ['10', '15', '20'],
                            total,
                            current,
                            showTotal(total: number) {
                                return services.interpolate(getText('total'), {
                                    total,
                                });
                            },
                            onChange: (page, perPage) => {
                                this.pageChange({ page, perPage });
                            },
                            onShowSizeChange: (page, perPage) => {
                                this.pageChange({ page, perPage });
                            },
                        }}
                        columns={this.setTableColumns()}
                        dataSource={datasource}
                    />
                </Spin>
            </div>
        );
    }
}

function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    const { result, filters, paging, isLoading } = entity;
    return {
        entity,
        filters,
        result,
        componentId,
        paging,
        isLoading,
    };
}

export const BlackListTable = connect(mapStateToProps)(blackListTable);
