import React, { PureComponent } from 'react';
import { Form, Radio, Modal, message, Popconfirm, Button } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import {
    MallActivityTicketPriceFreeRule,
    NewMallActivityMemberCardLevel,
    TicketFieldShowModal,
} from '../types';
import { defaultMallActivityTicketPriceFreeRule } from '../common';

import { NewMallActivityTicketPriceFreeRulesTable } from './new-mall-activity-ticket-price-free-rules-table';

import '../index.less';
import { cloneDeep } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

interface NewMallActivityTicketPriceFreeRulesModalProps {
    onSubmit: (value: MallActivityTicketPriceFreeRule[]) => void;
    cardLevels: NewMallActivityMemberCardLevel[];
}

interface NewMallActivityTicketPriceFreeRulesModalStates {
    showModal: boolean;
    ticketPriceFreeRules: MallActivityTicketPriceFreeRule[];
}

/**
 * 新商场活动票档免费额度弹窗
 */
class NewMallActivityTicketPriceFreeRulesModal extends PureComponent<
    NewMallActivityTicketPriceFreeRulesModalProps,
    NewMallActivityTicketPriceFreeRulesModalStates
> {
    constructor(props: NewMallActivityTicketPriceFreeRulesModalProps) {
        super(props);
        this.state = {
            ticketPriceFreeRules: [],
            showModal: false,
        };
    }
    public showModal: TicketFieldShowModal<MallActivityTicketPriceFreeRule[]> = (value) => {
        this.setState({ ticketPriceFreeRules: value, showModal: true });
    };

    private closeModal = () => {
        this.setState({ ticketPriceFreeRules: [], showModal: false });
    };

    private onTicketPriceRulesChange = (
        ticketPriceFreeRules: MallActivityTicketPriceFreeRule[]
    ) => {
        this.setState({ ticketPriceFreeRules });
    };

    private onRuleTypeChange = (e: RadioChangeEvent) => {
        const value = e.target.value;
        const { cardLevels } = this.props;
        if (value) {
            if (!cardLevels?.length) {
                message.warn(language.getText('qxxzzj'));
                return;
            }
            this.onTicketPriceRulesChange(
                cardLevels.map(({ id }) => ({
                    ...defaultMallActivityTicketPriceFreeRule,
                    memberCardLevelId: Number(id),
                }))
            );
        } else {
            this.onTicketPriceRulesChange([{ ...defaultMallActivityTicketPriceFreeRule }]);
        }
    };

    private onSubmit = () => {
        const { ticketPriceFreeRules } = this.state;
        const { onSubmit } = this.props;
        onSubmit(ticketPriceFreeRules);
        this.closeModal();
    };

    private onClear = () => {
        const { ticketPriceFreeRules } = this.state;
        const newValue = cloneDeep(ticketPriceFreeRules).map(({ memberCardLevelId }) => ({
            ...defaultMallActivityTicketPriceFreeRule,
            memberCardLevelId,
        }));
        this.onTicketPriceRulesChange(newValue);
    };

    renderModalFooter = () => {
        return (
            <div className="new-mall-activity__flex">
                <div style={{ marginRight: 'auto' }}>
                    <Popconfirm
                        title={services.language.getText('qdqksypzxxm')}
                        onConfirm={this.onClear}
                        okText={language.getText('common.ok')}
                        cancelText={language.getText('common.cancel')}
                    >
                        <Button type="link">{language.getText('common.clear')}</Button>
                    </Popconfirm>
                </div>
                <Button onClick={this.closeModal} type="default">
                    {language.getText('common.cancel')}
                </Button>
                <Button onClick={this.onSubmit} type="primary">
                    {language.getText('common.ok')}
                </Button>
            </div>
        );
    };

    render() {
        const { ticketPriceFreeRules, showModal } = this.state;
        const { cardLevels } = this.props;
        const isCardLevelRule = ticketPriceFreeRules.some(
            (item) => Number(item.memberCardLevelId) !== -1
        );
        return (
            <Modal
                width={600}
                footer={this.renderModalFooter()}
                onCancel={this.closeModal}
                visible={showModal}
                title={language.getText('szmrmymfme')}
            >
                <Form.Item labelCol={{ span: 3 }} label={language.getText('gzlx')}>
                    <Radio.Group value={isCardLevelRule} onChange={this.onRuleTypeChange}>
                        <Radio value={false}>{language.getText('jqbdjtyggz')}</Radio>
                        <Radio value={true}>{language.getText('jbtdjbtgz')}</Radio>
                    </Radio.Group>
                </Form.Item>
                <NewMallActivityTicketPriceFreeRulesTable
                    value={ticketPriceFreeRules}
                    onChange={this.onTicketPriceRulesChange}
                    cardLevels={cardLevels}
                />
            </Modal>
        );
    }
}

export { NewMallActivityTicketPriceFreeRulesModal };
