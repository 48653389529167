import React, { PureComponent } from 'react';
import { forEach, remove, find, map, cloneDeep } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { Button, InputNumber, message, Modal, Select, Table as AntTable } from 'antd';
import './index.less';

const api = services.api;
const { Option } = Select;

interface CouponOrderChooseAppliesSelectorProps {
    orderId: number;
    params?: any;
    selectValues: any[];
    onChange: (products: any[], packageNum?: number) => void;
    disabled?: boolean;
    row?: any;
}

interface CouponOrderChooseAppliesSelectorState {
    visible: boolean;
    parentData: undefined | any;
    data: any[]; //可勾选的优惠券列表
    packageNum: undefined | number;
    selectedRows: any[];
}
/**
 * 选择优惠券
 */
export class CouponOrderChooseAppliesSelector extends PureComponent<
    CouponOrderChooseAppliesSelectorProps,
    CouponOrderChooseAppliesSelectorState
> {
    static defaultProps = {
        disabled: false,
    };

    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
            parentData: undefined,
            data: [],
            packageNum: undefined,
            selectedRows: [],
        };
    }

    dispatch: any = {};

    componentWillUnmount() {
        //this.dispatch(actions.unmountEntityAction(entity));
    }

    onTableRowSelect = (record: any, selected: boolean) => {
        const { selectedRows } = this.state;
        const newSelectedRows = cloneDeep(selectedRows);
        if (selected) {
            newSelectedRows.push(record);
        } else {
            remove(newSelectedRows, (i) => {
                return i.id === record.id;
            });
        }
        this.setState({
            selectedRows: newSelectedRows,
        });
    };

    onTableRowSelectAll = (selected: boolean, rows: any[]) => {
        let selectedRows = [];
        if (selected) {
            selectedRows = rows;
        }
        this.setState({
            selectedRows,
        });
    };

    onOk = () => {
        const { onChange } = this.props;
        const { packageNum, selectedRows, parentData } = this.state;
        if (parentData && parentData.packageRefund && !packageNum) {
            message.error('请输入售后礼包数量');
            this.setState({
                selectedRows: [],
            });
            return;
        }
        if (!selectedRows || selectedRows.length === 0) {
            message.error('请选择优惠券');
            return;
        }
        if (onChange) {
            forEach(selectedRows, (coupon) => {
                coupon.select = true;
            });
            this.setState({
                selectedRows,
            });
            onChange(selectedRows, packageNum);
            this.toggleModal();
        }
    };

    onChangeRefundMoney = (e: any, record: any) => {
        const { selectedRows } = this.state;
        selectedRows.map((item: any) => {
            if (record.id === item.couponId) {
                item.refundMoney = e;
            }
            return item;
        });
        this.setState({
            selectedRows,
        });
        const { data } = this.state;
        let item = find(data, { id: record.id }) || {};
        item.refundMoney = e;
        this.setState(
            {
                data: data,
            },
            () => {
                this.forceUpdate();
            }
        );
    };

    getTableConfig = () => {
        let newColumns = [
            {
                title: '券名称',
                key: 'name',
                dataIndex: 'name',
                render: (text: any) => {
                    return (
                        <div style={{ width: '100px' }} className="ant-table-cell-ellipsis">
                            <span>{text}</span>
                        </div>
                    );
                },
            },
            {
                title: services.language.getText('qbm'),
                key: 'couponNo',
                dataIndex: 'couponNo',
                render: (text: any) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: '券状态',
                key: 'couponStatus',
                dataIndex: 'couponStatus',
                width: 150,
                render: (text: any, row: any) => {
                    let couponStatusStr = '';
                    if (text === 'ENABLED') {
                        couponStatusStr = '待使用';
                        if (row.expire) {
                            couponStatusStr += '已过期';
                        }
                    } else if (text === 'USED') {
                        couponStatusStr = '已使用';
                        if (row.expire) {
                            couponStatusStr += '已过期';
                        }
                    } else if (text === 'EXPIRED') {
                        couponStatusStr = '已过期';
                    } else if (text === 'REFUND_APPLY') {
                        couponStatusStr = '退款中';
                    } else if (text === 'REFUND_FINISH') {
                        couponStatusStr = '退款完成';
                    }
                    if (row.transferring) {
                        return <span style={{ color: 'red' }}>赠送中</span>;
                    }
                    if (row.transferred) {
                        return <span style={{ color: 'red' }}>{couponStatusStr}，赠送他人</span>;
                    }
                    return couponStatusStr;
                },
            },
            {
                title: services.language.getText('clzt'),
                key: 'refundMoney',
                dataIndex: 'refundMoney',
                render: (text: any, row: any) => {
                    const couponStatus = row.couponStatus;
                    const rechargeSuccess = row.rechargeSuccess;
                    if (row.rechargeSuccess === null) {
                        return couponStatus === 'USED' && !row.expire ? (
                            <Select
                                value={text}
                                style={{ width: '100px' }}
                                onChange={(e: any) => this.onChangeRefundMoney(e, row)}
                            >
                                <Option value="true">退款退券</Option>
                                <Option value="false">{services.language.getText('ztq')}</Option>
                            </Select>
                        ) : (
                            <Select value={text} style={{ width: '100px' }}>
                                <Option value="true">退款退券</Option>
                            </Select>
                        );
                    } else {
                        //三方余额券
                        return (
                            <Select value={text} style={{ width: '100px' }}>
                                <Option value="true">
                                    {rechargeSuccess ? '退款退券' : '仅退款'}
                                </Option>
                            </Select>
                        );
                    }
                },
            },
            {
                title: services.language.getText('zxxj'),
                key: 'executePrice',
                dataIndex: 'executePrice',
                render: (text: any) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: services.language.getText('zxjf'),
                key: 'pointExecutePrice',
                dataIndex: 'pointExecutePrice',
                render: (text: any) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: '结束有效期',
                key: 'endTime',
                dataIndex: 'endTime',
                render: (text: any) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: '三方券退券金额',
                key: 'amount',
                dataIndex: 'amount',
                render: (text: any, row: any) => {
                    if (text === null || row.rechargeSuccess === null) {
                        return null;
                    }
                    return <span>{text}</span>;
                },
            },
            {
                title: '三方券充值状态',
                key: 'rechargeSuccess',
                dataIndex: 'rechargeSuccess',
                render: (text: any) => {
                    if (text === null) {
                        return null;
                    }
                    return text ? <span>已充值</span> : <span>充值失败</span>;
                },
            },
        ];
        let config: any = {
            rowKey: 'id',
            columns: [...newColumns],
            scroll: {
                y: 400,
            },
            style: {
                marginTop: 20,
            },
        };
        return config;
    };

    renderCoupon = () => {
        const { data, selectedRows } = this.state;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: data,
            rowKey: 'id',
            rowSelection: {
                selectedRowKeys: map(selectedRows, (value) => {
                    return value.id;
                }),
                onSelect: (record: any, selected: boolean) => {
                    this.onTableRowSelect(record, selected);
                },
                onSelectAll: (selected: boolean, rows: any) => {
                    this.onTableRowSelectAll(selected, rows);
                },
                onChange: () => {},
                getCheckboxProps: (record: any) => {
                    if (record.transferring || record.transferred) {
                        return { disabled: true };
                    }
                    return {};
                },
            },
        };

        return (
            <div style={{ height: 500, overflowY: 'scroll' }}>
                {this.renderParent()}
                <AntTable {...tableProps} pagination={false}></AntTable>
            </div>
        );
    };

    renderParent = () => {
        const { parentData, packageNum } = this.state;
        return (
            parentData &&
            parentData.packageRefund && (
                <table
                    cellSpacing={0}
                    cellPadding={5}
                    className="coupon-order-choose-applies-selector"
                >
                    <tbody>
                        <tr className="title">
                            <th>{services.language.getText('giftName')}</th>
                            <th>礼包券及数量</th>
                            <th>购买数量</th>
                            <th>已售后数量</th>
                            <th>售后数量</th>
                        </tr>
                        <tr>
                            <th>
                                <div className="parent-name">
                                    <span className="ant-table-cell-ellipsis">
                                        {parentData.name}
                                    </span>
                                </div>
                            </th>
                            <th>
                                {parentData.couponRules &&
                                    parentData.couponRules.map((coupon: any) => {
                                        return (
                                            <div className="coupon-list">
                                                <div className="name">
                                                    <span className="ant-table-cell-ellipsis">
                                                        {coupon.name}
                                                    </span>
                                                </div>
                                                <div className="count">{coupon.quantity}张</div>
                                            </div>
                                        );
                                    })}
                            </th>
                            <th>{parentData.quantity}</th>
                            <th>{parentData.quantity - parentData.canRefundQuantity}</th>
                            <th>
                                <span
                                    style={{ color: 'red', marginRight: '10px', fontSize: '18px' }}
                                >
                                    *
                                </span>
                                <InputNumber
                                    placeholder={services.language.getText('inputPlease')}
                                    value={packageNum}
                                    style={{ width: '130px' }}
                                    min={1}
                                    max={999999}
                                    step={1}
                                    precision={0}
                                    onChange={(value: any) => this.handleChange(value)}
                                ></InputNumber>
                                <Button
                                    style={{ margin: '10px' }}
                                    key="submit"
                                    type="primary"
                                    onClick={this.confirmPackageNum}
                                >
                                    确定
                                </Button>
                            </th>
                        </tr>
                    </tbody>
                </table>
            )
        );
    };

    handleChange = (value: number) => {
        this.setState({
            packageNum: value,
        });
    };

    //礼包整退输入框点击确定后的事件
    confirmPackageNum = () => {
        const { parentData, data, packageNum } = this.state;
        if (!packageNum) {
            message.error('请输入售后礼包数量');
            this.setState({
                selectedRows: [],
            });
            return;
        }
        if (packageNum > parentData.canRefundQuantity) {
            message.error('您填写礼包售后数量超过可售后数量');
            this.setState({
                packageNum: undefined,
                selectedRows: [],
            });
            return;
        }
        const newSelectedRows: any[] = [];
        forEach(parentData.packageCouponRecords, (record, index: number) => {
            if (index <= packageNum - 1) {
                const couponIds = record.couponIds;
                forEach(couponIds, (couponId: number) => {
                    if (index <= packageNum) {
                        let item = find(data, { id: couponId }) || {};
                        newSelectedRows.push(item);
                    }
                });
            }
        });
        this.setState({
            selectedRows: newSelectedRows,
        });
    };

    toggleModal = () => {
        const { visible } = this.state;
        this.setState({ visible: !visible });
    };

    getAllCoupon = () => {
        const { params, orderId, row, selectValues } = this.props;
        api.get(
            {
                ...params,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                apiPath: `/admin/orders/${orderId}/item/${row.id}/refundable/records`,
            }
        ).then((response: any) => {
            const data = response.refundableCouponRecords.map((item: any) => {
                item.id = item.couponId;
                const selectValue = find(selectValues, { id: item.couponId }) || {};
                if (selectValue && selectValue.refundMoney) {
                    item.refundMoney = selectValue.refundMoney;
                } else {
                    item.refundMoney = 'true';
                }
                return item;
            });
            this.setState(
                {
                    parentData: response,
                    data: data,
                    visible: true,
                    packageNum: row.packageNum ? row.packageNum : undefined,
                    selectedRows: selectValues,
                },
                () => {
                    this.forceUpdate();
                }
            );
        });
    };

    renderTip = () => {
        const { data } = this.state;
        const findList = data.filter((item) => item.transferring || item.transferred);
        if (findList && findList.length > 0) {
            return (
                <div style={{ marginTop: '10px', color: 'red' }}>
                    注意：本单中“赠送中或已转赠”的优惠券，不能申请售后，如需售后，请先退回下单人账户
                </div>
            );
        }
        return null;
    };

    render() {
        const { disabled } = this.props;
        const modalContent = this.renderCoupon();
        const modalProps = {
            width: 1200,
            title: services.language.getText('shq'),
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };
        return (
            <div style={{ display: 'inline-block' }}>
                {!disabled && (
                    <span
                        style={{ marginLeft: '10px', color: '#1890ff', cursor: 'pointer' }}
                        onClick={this.getAllCoupon}
                    >
                        选择券
                    </span>
                )}
                <Modal {...modalProps}>
                    {modalContent}
                    {this.renderTip()}
                </Modal>
            </div>
        );
    }
}
