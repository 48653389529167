import React, { PureComponent } from 'react';
import {
    Button as AntButton,
    InputNumber,
    message as AntMessage,
    Modal as AntModal,
    Select,
    Radio,
} from 'antd';
import { services } from '@comall-backend-builder/core';
import { errorHandle } from '../../applications/cae/services/error-handle';
const { Option } = Select;
const {
    api,
    language: { getText },
} = services;

interface States {
    visible: boolean;
    time?: number;
    unit: TimeUnit;
    isScreenshot: boolean;
}

enum TimeUnit {
    /**
     * 小时
     */
    HOUR = 'HOUR',
    /**
     * 分钟
     */
    MINUTE = 'MINUTE',
    /**
     * 秒
     */
    SECOND = 'SECOND',
}
export class CouponQrcodeValidPeriodSetting extends PureComponent<any, States> {
    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
            unit: TimeUnit.MINUTE,
            isScreenshot: false,
        };
    }

    onClick = () => {
        api.get(
            {
                code: 'COUPON_QR_CODE_VALID_TIME',
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: '/admin/system_configs/page_config',
            }
        ).then((res: any) => {
            if (res && res.value) {
                const resJson = JSON.parse(res.value);
                this.setState({
                    unit: resJson.unit,
                    time: resJson.time,
                    isScreenshot: Boolean(resJson.isScreenshot),
                });
            }
            this.setState({
                visible: true,
            });
        });
    };

    onOK = () => {
        const { unit, time, isScreenshot } = this.state;
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: `/admin/system_configs/page_config`,
        };
        const value = time ? { unit: unit, time: time, isScreenshot } : { isScreenshot };
        const params = {
            code: 'COUPON_QR_CODE_VALID_TIME',
            name: getText('qewmyxqsz'),
            value: JSON.stringify(value),
        };
        api.put(params, config)
            .then(() => {
                AntMessage.success(getText('common.saveSuccess'));
                this.onCancel();
            })
            .catch(errorHandle);
    };
    onCancel = () => {
        this.setState({
            visible: false,
        });
    };

    unitChange = (val: any) => {
        this.setState({
            unit: val,
            time: undefined,
        });
    };

    numChange = (val: any) => {
        this.setState({
            time: val,
        });
    };

    getMax = (timeUnit: TimeUnit) => {
        const maxNum = {
            [TimeUnit.HOUR]: 24,
            [TimeUnit.MINUTE]: 60,
            [TimeUnit.SECOND]: 60,
        };
        return maxNum[timeUnit];
    };

    preventScreenshotsChange = (e: any) => {
        this.setState({
            isScreenshot: e.target.value,
        });
    };

    renderModal = () => {
        const { visible, unit, time, isScreenshot } = this.state;
        const max = this.getMax(unit);
        return (
            <AntModal
                width="669px"
                visible={visible}
                okText={getText('common.ok')}
                title={getText('ewmyxqsz')}
                onOk={this.onOK}
                onCancel={this.onCancel}
            >
                <div
                    className="modal-time-wrap"
                    style={{ display: 'flex', alignItems: 'baseline' }}
                >
                    <div>{getText('qewmyxqsz')}：</div>
                    <div style={{ flex: 1 }}>
                        <div>
                            <span>{getText('h_wdyhj_smyhqddewm')}&nbsp;&nbsp;</span>
                            <InputNumber
                                value={time}
                                onChange={this.numChange}
                                placeholder={getText('inputPlease')}
                                style={{ width: '100px' }}
                                min={1}
                                max={max}
                                step={1}
                                precision={0}
                            />
                            <Select
                                placeholder={getText('selectPlease')}
                                style={{ width: '90px' }}
                                onChange={this.unitChange}
                                value={unit}
                            >
                                <Option value={TimeUnit.HOUR}>{getText('hour')}</Option>
                                <Option value={TimeUnit.MINUTE}>{getText('minutes')}</Option>
                                <Option value={TimeUnit.SECOND}>{getText('second')}</Option>
                            </Select>
                            <span>&nbsp;&nbsp;{getText('yx_2')}</span>
                        </div>
                        <div style={{ marginTop: '10px', color: '#999' }}>
                            {getText('h_coupon_qrcode_setting_tip')}
                        </div>
                    </div>
                </div>
                <div
                    className="modal-time-wrap"
                    style={{ display: 'flex', alignItems: 'baseline', marginTop: 10 }}
                >
                    <div>券二维码防截屏：</div>
                    <div style={{ flex: 1 }}>
                        <div>
                            <Radio.Group
                                onChange={this.preventScreenshotsChange}
                                value={isScreenshot}
                            >
                                <Radio value={true}>开启</Radio>
                                <Radio value={false}>关闭</Radio>
                            </Radio.Group>
                        </div>
                        <div style={{ marginTop: '10px', color: '#999' }}>
                            优惠券二维码被用户截屏后，给出温馨提示；并刷新二维码，截图二维码失效，防止他人盗用。
                        </div>
                    </div>
                </div>
            </AntModal>
        );
    };

    render() {
        return (
            <div>
                <AntButton type="default" onClick={this.onClick} style={{ marginLeft: '10px' }}>
                    {getText('qewmyxq')}
                </AntButton>
                {this.renderModal()}
            </div>
        );
    }
}
