import React, { PureComponent } from 'react';
import { Button } from 'antd';
import { services } from '@comall-backend-builder/core';
import { SelectMallActivityList } from './select-mall-activity-list';

const language = services.language;

/**
 * 自定义可视化，商城列表活动选择器
 */
export class CustomSelectMallActivityList extends PureComponent<
    {
        value: any;
        contentConfig: Record<string, any>;
        modalConfig: Record<string, any>;
        onChange: (value: any) => void;
    },
    { visibleModal: boolean }
> {
    state = {
        visibleModal: false,
    };

    showModal = () => {
        this.setState({ visibleModal: true });
    };
    hideModal = () => {
        this.setState({ visibleModal: false });
    };
    onChange = (value: any) => {
        const { onChange } = this.props;
        this.hideModal();
        onChange(value);
    };

    render() {
        const {
            value: { linkParams },
            contentConfig,
            modalConfig,
        } = this.props;

        const { visibleModal } = this.state;
        const selectProps = {
            value: linkParams,
            onChange: this.onChange,
        };
        const hasValue = linkParams && linkParams.showCount;

        return (
            <span className="link-name">
                {hasValue ? <span className="link-name-label">商场活动列表</span> : '未选择'}
                <Button type={'link'} onClick={this.showModal} className="link-type-edit">
                    {hasValue
                        ? language.getText('components.Tools.modify')
                        : language.getText('components.Tools.select')}
                </Button>
                <SelectMallActivityList
                    contentConfig={contentConfig}
                    modalConfig={modalConfig}
                    showModal={visibleModal}
                    onCancel={this.hideModal}
                    {...selectProps}
                />
            </span>
        );
    }
}
