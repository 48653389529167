import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
export const config: Config = {
    entities: {
        ThumbsUpEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/notePromotionEncourage',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        eventType: {
                            type: 'string',
                            displayName: '<<sjlx>>',
                            defaultValue: 'LICKED',
                        },
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<activityNameRequired>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<xhyzcjl>>',
                                maxLength: 20,
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                    },
                },
                encourageInfo: {
                    type: 'object.subForm',
                    displayName: '<<jlsz>>',
                    properties: {
                        noteTypes: {
                            type: 'array.checkboxPlus',
                            displayName: '<<bdz_2>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                reqParams: { eventType: 'LICKED' },
                                reqConfig: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/activities/note_creation_types',
                                },
                            },
                        },
                        creationRewardType: {
                            type: 'string.options.radio',
                            displayName: '<<jlfw>>',
                            defaultValue: 'ALL_NOTE',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            options: [
                                { id: 'ALL_NOTE', name: '<<aqbnrjs>>' },
                                { id: 'EACH', name: '<<adtnrjs>>' },
                            ],
                            extra: '<<mzmjffjl>>',
                        },
                    },
                },
                rewardRule: {
                    type: 'string.options.radio',
                    displayName: '<<jllx>>',
                    defaultValue: 'CYCLE',
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    options: [
                        { id: 'CYCLE', name: '<<mmjl>>' },
                        { id: 'LADDER', name: '<<jtjl>>' },
                    ],
                },
                cycleRewardRule: {
                    type: 'object.rewardRules',
                    displayName: '<<cycleRewardRule>>',
                    controlConfig: {
                        reqConfig: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/activities/reward_items',
                        },
                        reqParams: { eventType: 'LICKED' },
                        rewardStartText: '<<mbdz>>',
                        baseConfig: {
                            isConditionsInput: true,
                            isItmesRadio: false,
                            isLadder: false,
                        },
                    },
                },
                ladderRewardRule: {
                    type: 'object.rewardRules',
                    displayName: '<<jtsz>>',
                    controlConfig: {
                        reqConfig: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/activities/reward_items',
                        },
                        reqParams: { eventType: 'LICKED' },
                        rewardStartText: '<<mbdz>>',
                        baseConfig: {
                            isConditionsInput: true,
                            isItmesRadio: false,
                            isLadder: true,
                            maxCount: 10,
                        },
                    },
                },
                rewardCalculation: {
                    type: 'array.optionIds.checkbox.tip',
                    displayName: '<<jljs>>',
                    defaultValue: ['AUTHOR_NOT_INCLUDED', 'ONCE_ONLY'],
                    options: [
                        { id: 'AUTHOR_NOT_INCLUDED', name: '<<bbhzzzjdz>>' },
                        { id: 'ONCE_ONLY', name: '<<pljjsycTip1>>' },
                    ],
                    extra: '<<rewardCalculationExtra2>>',
                },
                activityRewardLimit: {
                    type: 'number.tip',
                    displayName: '<<jlsx_1>>',
                    rules: [
                        {
                            type: 'number',
                            min: 1,
                            message: '<<inputThanZero>>',
                            validator: (rule: any, value: any) => {
                                var reg = /^([1-9]\d*)$/;
                                if (value && !reg.test(value)) {
                                    return false;
                                }
                                return true;
                            },
                        },
                    ],
                    controlConfig: {
                        addonbefore: '<<hdsjnhd>>',
                        addonafter: '<<bzhdjl_1>>',
                        tip: '',
                    },
                    extra: '<<dszmztjhdjl>>',
                },
                id: {
                    type: 'number.integer',
                },
                eventType: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
        },
        EvaluateEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/notePromotionEncourage',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        eventType: {
                            type: 'string',
                            displayName: '<<sjlx>>',
                            defaultValue: 'COMMENT',
                        },
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<activityNameRequired>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<xhyzcjl>>',
                                maxLength: 20,
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                    },
                },
                encourageInfo: {
                    type: 'object.subForm',
                    displayName: '<<jlsz>>',
                    properties: {
                        noteTypes: {
                            type: 'array.checkboxPlus',
                            displayName: '<<commentedOn>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                reqParams: { eventType: 'COMMENT' },
                                reqConfig: {
                                    apiPath: '/admin/activities/note_creation_types',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                },
                            },
                        },
                        creationRewardType: {
                            type: 'string.options.radio',
                            displayName: '<<jlfw>>',
                            defaultValue: 'ALL_NOTE',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            options: [
                                { id: 'ALL_NOTE', name: '<<aqbnrjs>>' },
                                { id: 'EACH', name: '<<adtnrjs>>' },
                            ],
                            extra: '<<mzmjffjl>>',
                        },
                    },
                },
                rewardRule: {
                    type: 'string.options.radio',
                    displayName: '<<jllx>>',
                    defaultValue: 'CYCLE',
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    options: [
                        { id: 'CYCLE', name: '<<mmjl>>' },
                        { id: 'LADDER', name: '<<jtjl>>' },
                    ],
                },
                cycleRewardRule: {
                    type: 'object.rewardRules',
                    displayName: '<<cycleRewardRule>>',
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    controlConfig: {
                        reqConfig: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/activities/reward_items',
                        },
                        reqParams: { eventType: 'COMMENT' },
                        rewardStartText: '<<mbpl>>',
                        baseConfig: {
                            isConditionsInput: true,
                            isItmesRadio: false,
                            isLadder: false,
                        },
                    },
                },
                ladderRewardRule: {
                    type: 'object.rewardRules',
                    displayName: '<<jtsz>>',
                    controlConfig: {
                        reqConfig: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/activities/reward_items',
                        },
                        reqParams: { eventType: 'COMMENT' },
                        rewardStartText: '<<mbpl>>',
                        baseConfig: {
                            isConditionsInput: true,
                            isItmesRadio: false,
                            isLadder: true,
                            maxCount: 10,
                        },
                    },
                },
                rewardCalculation: {
                    type: 'array.optionIds.checkbox.tip',
                    displayName: '<<jljs>>',
                    defaultValue: ['AUTHOR_NOT_INCLUDED', 'ONCE_ONLY'],
                    options: [
                        { id: 'AUTHOR_NOT_INCLUDED', name: '<<bbhzzzjpl>>' },
                        {
                            id: 'ONCE_ONLY',
                            name: '<<pljjsycTip3>>',
                        },
                    ],
                    extra: '<<commentedOnIntro1>> <<commentedOnIntro2>>',
                },
                activityRewardLimit: {
                    type: 'number.tip',
                    displayName: '<<jlsx_1>>',
                    rules: [
                        {
                            type: 'number',
                            min: 1,
                            message: '<<inputThanZero>>',
                            validator: (rule: any, value: any) => {
                                var reg = /^([1-9]\d*)$/;
                                if (value && !reg.test(value)) {
                                    return false;
                                }
                                return true;
                            },
                        },
                    ],
                    controlConfig: {
                        addonbefore: '<<hdsjnhd>>',
                        addonafter: '<<bzhdjl_1>>',
                        tip: '',
                    },
                    extra: '<<dszmztjhdjl>>',
                },
                id: {
                    type: 'number.integer',
                },
                eventType: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
        },
        CollectionEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/notePromotionEncourage',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        eventType: {
                            type: 'string',
                            displayName: '<<sjlx>>',
                            defaultValue: 'FAVORITES',
                        },
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<activityNameRequired>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<xhyzcjl>>',
                                maxLength: 20,
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                    },
                },
                encourageInfo: {
                    type: 'object.subForm',
                    displayName: '<<jlsz>>',
                    properties: {
                        noteTypes: {
                            type: 'array.checkboxPlus',
                            displayName: '<<bsz>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                reqParams: { eventType: 'FAVORITES' },
                                reqConfig: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/activities/note_creation_types',
                                },
                            },
                        },
                        creationRewardType: {
                            type: 'string.options.radio',
                            displayName: '<<jlfw>>',
                            defaultValue: 'ALL_NOTE',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            options: [
                                { id: 'ALL_NOTE', name: '<<aqbnrjs>>' },
                                { id: 'EACH', name: '<<adtnrjs>>' },
                            ],
                            extra: '<<creationRewardTypeExtra>>',
                        },
                    },
                },
                rewardRule: {
                    type: 'string.options.radio',
                    displayName: '<<jllx>>',
                    defaultValue: 'CYCLE',
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    options: [
                        { id: 'CYCLE', name: '<<mmjl>>' },
                        { id: 'LADDER', name: '<<jtjl>>' },
                    ],
                },
                cycleRewardRule: {
                    type: 'object.rewardRules',
                    displayName: '<<cycleRewardRule>>',
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    controlConfig: {
                        reqConfig: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/activities/reward_items',
                        },
                        reqParams: { eventType: 'FAVORITES' },
                        rewardStartText: '<<mbsz>>',
                        baseConfig: {
                            isConditionsInput: true,
                            isItmesRadio: false,
                            isLadder: false,
                        },
                    },
                },
                ladderRewardRule: {
                    type: 'object.rewardRules',
                    displayName: '<<jtsz>>',
                    controlConfig: {
                        reqConfig: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/activities/reward_items',
                        },
                        reqParams: { eventType: 'FAVORITES' },
                        rewardStartText: '<<mbsz>>',
                        baseConfig: {
                            isConditionsInput: true,
                            isItmesRadio: false,
                            isLadder: true,
                            maxCount: 10,
                        },
                    },
                },
                rewardCalculation: {
                    type: 'array.optionIds.checkbox.tip',
                    displayName: '<<jljs>>',
                    defaultValue: ['AUTHOR_NOT_INCLUDED', 'ONCE_ONLY'],
                    options: [
                        { id: 'AUTHOR_NOT_INCLUDED', name: '<<bbhzzzjdz>>' },
                        { id: 'ONCE_ONLY', name: '<<pljjsycTip1>>' },
                    ],
                    extra: '<<rewardCalculationExtra>>',
                },
                activityRewardLimit: {
                    type: 'number.tip',
                    displayName: '<<jlsx_1>>',
                    rules: [
                        {
                            type: 'number',
                            min: 1,
                            message: '<<inputThanZero>>',
                            validator: (rule: any, value: any) => {
                                var reg = /^([1-9]\d*)$/;
                                if (value && !reg.test(value)) {
                                    return false;
                                }
                                return true;
                            },
                        },
                    ],
                    controlConfig: {
                        addonbefore: '<<hdsjnhd>>',
                        addonafter: '<<bzhdjl_1>>',
                        tip: '',
                    },
                    extra: '<<dszmztjhdjl>>',
                },
                id: {
                    type: 'number.integer',
                },
                eventType: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
        },
    },
    components: {
        NotePromotionEncourageView: {
            component: 'Viewport',
        },
        NotePromotionEncouragePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'tabs-content-viewport',
            items: [{ component: 'NotePromotionEncourageTables' }],
        },
        NotePromotionEncourageTables: {
            component: 'Tabs',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<bdz_2>>',
                    content: {
                        component: 'ThumbsUpPage',
                    },
                },
                {
                    title: '<<commentedOn>>',
                    content: {
                        component: 'EvaluatePage',
                    },
                },
                {
                    title: '<<bsz>>',
                    content: {
                        component: 'CollectionPage',
                    },
                },
            ],
        },
        ThumbsUpPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'ThumbsUpEntity',
            items: [
                { component: 'ThumbsUpPageFilter', params: { eventType: 'LICKED' } },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzczjl>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/note-promotion-encourage/add-LICKED',
                                },
                            ],
                        },
                    ],
                },
                { component: 'NotePromotionEncourageTable', params: { eventType: 'LICKED' } },
            ],
        },
        EvaluatePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'EvaluateEntity',
            items: [
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    items: [
                        { component: 'EvaluatePageFilter', params: { eventType: 'COMMENT' } },
                        {
                            component: 'GridLayout',
                            className: 'mt24 mb24',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzczjl>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/note-promotion-encourage/add-COMMENT',
                                },
                            ],
                        },
                    ],
                },
                { component: 'NotePromotionEncourageTable', params: { eventType: 'COMMENT' } },
            ],
        },
        CollectionPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'CollectionEntity',
            items: [
                { component: 'CollectionPageFilter', params: { eventType: 'FAVORITES' } },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzczjl>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/note-promotion-encourage/add-FAVORITES',
                                },
                            ],
                        },
                    ],
                },
                { component: 'NotePromotionEncourageTable', params: { eventType: 'FAVORITES' } },
            ],
        },
        ThumbsUpPageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsrhdmc>>',
                    },
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        EvaluatePageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsrhdmc>>',
                    },
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        CollectionPageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsrhdmc>>',
                    },
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        NotePromotionEncourageTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'name',
                            width: 180,
                        },
                        {
                            property: 'startTime',
                        },
                        {
                            property: 'endTime',
                        },
                        {
                            property: 'executeStatus',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                                statusConfig: {
                                    NOT_STARTED: {
                                        text: '<<notStarted>>',
                                        status: 'success',
                                    },
                                    STARTED: {
                                        text: '<<inprogress>>',
                                        status: 'processing',
                                    },
                                    COMPLETE: {
                                        text: '<<end>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        width: 180,
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: false },
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}`,
                                            apiPath:
                                                '/AFFILIATE-MARKETING/admin/creation_activities/:id/disabled',
                                            config: {
                                                content: '{{row.name}}<<areadyClosed>>',
                                                text: '<<common.close>>',
                                            },
                                            confirm: {
                                                text: '<<isCloseActivity>>{{row.name}}?',
                                            },
                                        },
                                        {
                                            params: { status: true },
                                            value: false,
                                            apiRoot: `${ENV.AUTH_API_ROOT}`,
                                            apiPath:
                                                '/AFFILIATE-MARKETING/admin/creation_activities/:id/enabled',
                                            config: {
                                                content: '{{row.name}}<<areadyOpend>>',
                                                text: '<<common.opend>>',
                                            },
                                            confirm: {
                                                text: '<<isAreadyOpend>>{{row.name}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                statusKey: 'row.canEdit',
                                path: '/note-promotion-encourage/edit-{{row.eventType}}/{{row.id}}',
                            },
                            // {
                            //     type: 'component',
                            //     component: 'NotePromotionEncourageListExportButton',
                            // },
                        ],
                    },
                },
            ],
        },
        ThumbsUpAddPage: {
            component: 'FlexLayout',
            entity: 'ThumbsUpEntity',
            direction: 'horizontal',
            items: [{ component: 'NotePromotionEncourageAddFormView' }],
        },
        EvaluateAddPage: {
            component: 'FlexLayout',
            entity: 'EvaluateEntity',
            direction: 'horizontal',
            items: [{ component: 'NotePromotionEncourageAddFormView' }],
        },
        CollectionAddPage: {
            component: 'FlexLayout',
            entity: 'CollectionEntity',
            direction: 'horizontal',
            items: [{ component: 'NotePromotionEncourageAddFormView' }],
        },
        ThumbsUpEditPage: {
            component: 'FlexLayout',
            loaderType: 'get',
            entity: 'ThumbsUpEntity',
            direction: 'horizontal',
            items: [{ component: 'NotePromotionEncourageEditFormView' }],
        },
        EvaluateEditPage: {
            component: 'FlexLayout',
            loaderType: 'get',
            entity: 'EvaluateEntity',
            direction: 'horizontal',
            items: [{ component: 'NotePromotionEncourageEditFormView' }],
        },
        CollectionEditPage: {
            component: 'FlexLayout',
            loaderType: 'get',
            entity: 'CollectionEntity',
            direction: 'horizontal',
            items: [{ component: 'NotePromotionEncourageEditFormView' }],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/note-promotion-encourage',
                    component: 'NotePromotionEncourageView',
                    breadcrumbName: '<<notePromotionEncourage>>',
                    privilege: {
                        path: 'notePromotionEncourage',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add-LICKED',
                            component: 'ThumbsUpAddPage',
                            breadcrumbName: '<<xzczjl>>',
                        },
                        {
                            path: '/add-COMMENT',
                            component: 'EvaluateAddPage',
                            breadcrumbName: '<<xzczjl>>',
                        },
                        {
                            path: '/add-FAVORITES',
                            component: 'CollectionAddPage',
                            breadcrumbName: '<<xzczjl>>',
                        },
                        {
                            path: '/edit-LICKED/:id',
                            component: 'ThumbsUpEditPage',
                            breadcrumbName: '<<bjczjl>>',
                        },
                        {
                            path: '/edit-COMMENT/:id',
                            component: 'EvaluateEditPage',
                            breadcrumbName: '<<bjczjl>>',
                        },
                        {
                            path: '/edit-FAVORITES/:id',
                            component: 'CollectionEditPage',
                            breadcrumbName: '<<bjczjl>>',
                        },
                        { path: '/', component: 'NotePromotionEncouragePage' },
                    ],
                },
            ],
        },
    ],
};
