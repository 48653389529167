import { message, Modal } from 'antd';
import { find, isNumber, isPlainObject } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import get from 'lodash/get';
import { getUrlParam } from '../../../applications/cae/services/utils';

export enum OperationResults {
    /**
     * 成功
     */
    SUCCESS = 'SUCCESS',
    /**
     * 失败
     */
    FAIL = 'FAIL',
    /**
     * 部分成功
     */
    SOME_SUCCESS = 'SOME_SUCCESS',
}

export const OPERATION_RESULTS_OPYIONS = [
    {
        id: OperationResults.SUCCESS,
        name: '<<success>>',
    },
    {
        id: OperationResults.FAIL,
        name: '<<sb_2>>',
    },
    {
        id: OperationResults.SOME_SUCCESS,
        name: '<<bfcg>>',
    },
];

export const config: Config = {
    entities: {
        ReserveDrawLotsImportEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
            apiPath: '/loader/admin/reserveDrawLotsImportActivities',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<sxmd_1>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                dateRange: {
                    type: 'string',
                    displayName: '<<acitivityTime>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<sxmd_1>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                whiteListCount: {
                    type: 'string',
                    displayName: '<<hdbmd>>',
                },
                blackListCount: {
                    type: 'string',
                    displayName: '<<hdhmd>>',
                },
                activityInfo: {
                    type: 'object.subForm',
                    displayName: '<<hdsz>>',
                    properties: {
                        executeStatus: {
                            type: 'string',
                            displayName: '<<status>>',
                        },
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrhdmc>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<acitivityTimeRequired>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                                format: 'YYYY-MM-DD',
                            },
                        },
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            rules: [
                                {
                                    required: true,
                                    type: 'array',
                                },
                            ],
                        },
                        goods: {
                            type: 'array.PresaleProducts',
                            displayName: '<<components.Tools.selectProduct>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<components.Tools.productSelectRequired>>',
                                },
                            ],
                            controlConfig: {
                                selectionType: 'radio',
                            },
                        },
                        description: {
                            type: 'string.text.paragraph',
                            displayName: '<<gzsm>>',
                            rules: [
                                {
                                    max: 5000,
                                },
                            ],
                            controlConfig: {
                                rows: 4,
                                maxLength: 5000,
                            },
                        },
                    },
                },
                roundInfo: {
                    type: 'object.subForm',
                    displayName: '<<hdcc>>',
                    properties: {
                        period: {
                            type: 'string.options.radio',
                            displayName: '<<cczq>>',
                            defaultValue: 'DAY',
                            options: [
                                {
                                    id: 'DAY',
                                    name: '<<mryc>>',
                                },
                            ],
                        },
                        reserveTime: {
                            type: 'array.presaleTimepicker',
                            displayName: '<<bmsj>>',
                            className: 'presale-range-timepicker',
                            rules: [
                                {
                                    required: true,
                                    message: '<<qtxbmsj>>',
                                },
                            ],
                            controlConfig: {
                                format: 'HH:mm',
                            },
                            extra: '<<cybmdyhckycycj>>',
                        },
                        drawTime: {
                            type: 'string.time',
                            displayName: '<<xtzxcqsj>>',
                            rules: [
                                {
                                    required: true,
                                    displayName: '<<qtxxtzxcqsj>>',
                                },
                            ],
                            controlConfig: {
                                format: 'HH:mm',
                            },
                            extra: '<<xtdcgbmdyhjxzdcq>>',
                        },
                        purchaseTime: {
                            type: 'number.tip',
                            displayName: '<<gmsj>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<qtxgmsj>>',
                                },
                                {
                                    message: '<<qtxdydy>>',
                                    validator: (rule: any, value: any) => {
                                        if (!isNumber(value)) {
                                            return true;
                                        }
                                        return /^[1-9][0-9]*$/.test(String(value));
                                    },
                                },
                            ],
                            controlConfig: {
                                addonbefore: '<<cqjsh>>',
                                addonafter: '<<xsngm>>',
                                style: {
                                    width: '140px',
                                },
                                placeholder: '<<inputPlease>>',
                            },
                            extra: '<<pzhdgmsjzjzhbdcgdr>>',
                        },
                        ballotLimit: {
                            type: 'object.checkboxWithValueGroup',
                            displayName: '<<zqrs>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<qtxzqrs>>',
                                    validator: (rule: any, value: any) => {
                                        if (!isPlainObject(value)) {
                                            return false;
                                        }
                                        const keys = Object.keys(value).filter((key) =>
                                            Boolean(value[key] && value[key].checked)
                                        );
                                        if (!keys.length) {
                                            return false;
                                        }
                                        return keys.every((key) => isNumber(value[key].value));
                                    },
                                },
                                {
                                    message: '<<qtxdydydzzs>>',
                                    validator: (rule: any, value: any) => {
                                        if (!isPlainObject(value)) {
                                            return true;
                                        }
                                        const keys = Object.keys(value).filter((key) =>
                                            Boolean(value[key] && value[key].checked)
                                        );
                                        if (!keys.length) {
                                            return true;
                                        }
                                        return keys.every(
                                            (key) =>
                                                !isNumber(value[key].value) ||
                                                /^[1-9][0-9]*$/.test(String(value[key].value))
                                        );
                                    },
                                },
                            ],
                            controlConfig: {
                                properties: [
                                    {
                                        type: 'number.tip',
                                        key: 'whiteListCount',
                                        controlConfig: {
                                            min: 0,
                                            style: { width: '140px' },
                                            addonbefore: '<<bmdzq>>',
                                            addonafter: '<<people>>',
                                            rules: [
                                                {
                                                    type: 'number',
                                                },
                                            ],
                                            placeholder: '<<inputPlease>>',
                                        },
                                    },
                                    {
                                        type: 'number.tip',
                                        key: 'memberCount',
                                        controlConfig: {
                                            min: 0,
                                            style: { width: '140px' },
                                            addonbefore: '<<ptyhzq>>',
                                            addonafter: '<<people>>',
                                            rules: [
                                                {
                                                    type: 'number',
                                                },
                                            ],
                                            placeholder: '<<inputPlease>>',
                                        },
                                    },
                                ],
                            },
                        },
                        quantityLimit: {
                            type: 'number.tip',
                            displayName: '<<mcxg>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<qtxmcxg>>',
                                },
                                {
                                    message: '<<qtxdydy>>',
                                    validator: (rule: any, value: any) => {
                                        if (!isNumber(value)) {
                                            return true;
                                        }
                                        return /^[1-9][0-9]*$/.test(String(value));
                                    },
                                },
                            ],
                            controlConfig: {
                                addonbefore: '<<mchdmyhzdgm>>',
                                addonafter: '<<jian>>',
                                style: { width: '140px' },
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                                min: 1,
                                max: 10,
                                placeholder: '<<inputPlease>>',
                            },
                        },
                        maxDrawCount: {
                            type: 'number.tip',
                            displayName: '<<myhzqcs>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<qtxmyhzqcs>>',
                                },
                                {
                                    message: '<<qtxdydy>>',
                                    validator: (rule: any, value: any) => {
                                        if (!isNumber(value)) {
                                            return true;
                                        }
                                        return /^[1-9][0-9]*$/.test(String(value));
                                    },
                                },
                            ],
                            controlConfig: {
                                addonbefore: '<<hdqjmyhzq>>',
                                addonafter: '<<frequency>>',
                                style: { width: '140px' },
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                                min: 1,
                                max: 10,
                                placeholder: '<<inputPlease>>',
                            },
                            extra: '<<yhzhdqjmzzqcsh>>',
                        },
                    },
                },
                seniorInfo: {
                    type: 'object.subForm',
                    displayName: '<<seniorSetting>>',
                    collapsed: true,
                    properties: {
                        participateCondition: {
                            type: 'object.participateCondition',
                            displayName: '<<bmtj>>',
                            controlConfig: {
                                codes: [
                                    {
                                        code: 'MINREGISTERDAYS',
                                        tip: '<<dckbmcg>>',
                                    },
                                    {
                                        code: 'CONSUMPTIONT',
                                        tip: '<<zbsyhmyzsxf>>',
                                    },
                                    {
                                        code: 'CERTIFICATION',
                                    },
                                ],
                            },
                        },
                        receiptCertification: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '<<gmtj>>',
                            defaultValue: [],
                            options: [
                                {
                                    id: 'NEED',
                                    name: '<<shrxxwyhsmrzdxx>>',
                                },
                            ],
                            extra: '<<zshrxxywyh>>',
                        },
                    },
                },
            },
        },
        WhitelistManagerListEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
            apiPath: '/admin/reserve_draw_lots_activities/:id/WHITE',
            filters: {
                mobile: {
                    type: 'string.phone',
                    displayName: '<<phoneNum>>',
                },
            },
            properties: {
                mobile: {
                    type: 'string.phone',
                    displayName: '<<phoneNum>>',
                },
                createTime: {
                    type: 'string.date',
                    displayName: '<<drsj_1>>',
                },
            },
        },
        WhitelistManagerRecordEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
            apiPath: '/loader/admin/whitelistManagerRecord',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<czsj>>',
                },
            },
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
                time: {
                    type: 'string.date',
                    displayName: '<<czsj>>',
                },
                importType: {
                    type: 'string',
                    displayName: '<<drfs>>',
                },
                optResult: {
                    type: 'string.options.select',
                    displayName: '<<czjg>>',
                    options: OPERATION_RESULTS_OPYIONS,
                },
                importResult: {
                    type: 'string',
                    displayName: '<<importResult>>',
                },
            },
        },
        BlacklistManagerListEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
            apiPath: '/admin/reserve_draw_lots_activities/:id/BLACK',
            filters: {
                mobile: {
                    type: 'string.phone',
                    displayName: '<<phoneNum>>',
                },
            },
            properties: {
                mobile: {
                    type: 'string.phone',
                    displayName: '<<phoneNum>>',
                },
                createTime: {
                    type: 'string.date',
                    displayName: '<<drsj_1>>',
                },
            },
        },
        BlacklistManagerRecordEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
            apiPath: '/loader/admin/blacklistManagerRecord',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<czsj>>',
                },
            },
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
                time: {
                    type: 'string.date',
                    displayName: '<<czsj>>',
                },
                importType: {
                    type: 'string',
                    displayName: '<<drfs>>',
                },
                optResult: {
                    type: 'string.options.select',
                    displayName: '<<czjg>>',
                    options: OPERATION_RESULTS_OPYIONS,
                },
                importResult: {
                    type: 'string',
                    displayName: '<<importResult>>',
                },
            },
        },
    },
    components: {
        ReserveDrawLotsImportView: {
            component: 'Viewport',
        },
        ReserveDrawLotsImportPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            entity: 'ReserveDrawLotsImportEntity',
            items: [
                {
                    component: 'ReserveDrawLotsImportFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/reserve-draw-lots-import/add',
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'ReserveDrawLotsImportTable',
                },
            ],
        },
        ReserveDrawLotsImportFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsryhqlbmc>>',
                    },
                },
                {
                    property: 'subsiteIds',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        ReserveDrawLotsImportTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: { x: true },
                    columns: [
                        {
                            property: 'name',
                            width: 180,
                        },
                        {
                            property: 'dateRange',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'whiteListCount',
                        },
                        {
                            property: 'blackListCount',
                        },
                        {
                            property: 'executeStatus',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                                statusConfig: {
                                    NOTSTARTED: {
                                        text: '<<notStarted>>',
                                        status: 'success',
                                    },
                                    STARTED: {
                                        text: '<<inprogress>>',
                                        status: 'processing',
                                    },
                                    COMPLETE: {
                                        text: '<<end>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                        {
                            property: 'subsites',
                            width: 200,
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '250px',
                                },
                                countSuffix: '<<subsites>>',
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        fixed: 'right',
                        width: 300,
                        items: [
                            {
                                text: '<<bmdgl>>',
                                type: 'link',
                                path:
                                    '/menu-list/market/reserve-draw-lots-import/whitelist-manager/{{row.id}}',
                            },
                            {
                                text: '<<hmdgl>>',
                                type: 'link',
                                path:
                                    '/menu-list/market/reserve-draw-lots-import/blacklist-manager/{{row.id}}',
                            },
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: false },
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                                            apiPath:
                                                '/admin/reserve_draw_lots_activities/:id/disabled',
                                            config: {
                                                content: '<<hd>>{{row.name}}<<areadyClosed>>',
                                                text: '<<common.close>>',
                                            },
                                            confirm: {
                                                text: '<<isCloseActivity>>{{row.name}}?',
                                            },
                                        },
                                        {
                                            params: { status: true },
                                            value: false,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                                            apiPath:
                                                '/admin/reserve_draw_lots_activities/:id/enabled',
                                            config: {
                                                content: '<<hd>>{{row.name}}<<areadyOpend>>',
                                                text: '<<common.opend>>',
                                            },
                                            confirm: {
                                                text: '<<isAreadyOpend>><<hd>>{{row.name}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                text: '<<common.edit>>',
                                type: 'link',
                                path: '/menu-list/market/reserve-draw-lots-import/edit/{{row.id}}',
                            },
                            {
                                text: '<<common.log>>',
                                type: 'link',
                                path: '/menu-list/market/reserve-draw-lots-import/logs/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        ReserveDrawLotsImportAddPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'ReserveDrawLotsImportEntity',
            items: [
                {
                    component: 'ReserveDrawLotsImportAddForm',
                },
            ],
        },
        ReserveDrawLotsImportAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            style: { width: 920 },
            fields: [
                {
                    property: 'activityInfo.name',
                },
                {
                    property: 'activityInfo.dateRange',
                },
                {
                    property: 'activityInfo.subsites',
                },
                {
                    property: 'activityInfo.goods',
                },
                {
                    property: 'activityInfo.description',
                },
                {
                    property: 'roundInfo.period',
                },
                {
                    property: 'roundInfo.reserveTime',
                },
                {
                    property: 'roundInfo.drawTime',
                },
                {
                    property: 'roundInfo.purchaseTime',
                },
                {
                    property: 'roundInfo.ballotLimit',
                },
                {
                    property: 'roundInfo.quantityLimit',
                },
                {
                    property: 'roundInfo.maxDrawCount',
                },
                {
                    property: 'seniorInfo.participateCondition',
                },
                {
                    property: 'seniorInfo.receiptCertification',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                //校验报名条件配置输入值
                const participateCondition = entity.seniorInfo.participateCondition;
                if (participateCondition && participateCondition.length > 0) {
                    const minRegisterDays: any = find(participateCondition, {
                        code: 'MINREGISTERDAYS',
                    });
                    const consumption: any = find(participateCondition, { code: 'CONSUMPTIONT' });
                    if (minRegisterDays.checked && !minRegisterDays.value) {
                        message.error(services.language.getText('qtxbmtjxzxddyz'));
                        return false;
                    }
                    if (consumption.checked && !consumption.value) {
                        message.error(services.language.getText('qtxbmtjxzxddyz'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        ReserveDrawLotsImportEditPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ReserveDrawLotsImportEditForm',
                },
            ],
        },
        ReserveDrawLotsImportEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'ReserveDrawLotsImportEntity',
            labelCol: 4,
            controlCol: 20,
            style: { width: 920 },
            loaderType: 'get',
            fields: [
                {
                    property: 'activityInfo.name',
                },
                {
                    property: 'activityInfo.dateRange',
                },
                {
                    property: 'activityInfo.subsites',
                },
                {
                    property: 'activityInfo.goods',
                },
                {
                    property: 'activityInfo.description',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'roundInfo.period',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'roundInfo.reserveTime',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'roundInfo.drawTime',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'roundInfo.purchaseTime',
                    controlConfig: {
                        addonbefore: '<<cqjsh>>',
                        addonafter: '<<xsngm>>',
                        style: { width: '140px' },
                        placeholder: '<<inputPlease>>',
                        disabled: true,
                    },
                },
                {
                    property: 'roundInfo.ballotLimit',
                },
                {
                    property: 'roundInfo.quantityLimit',
                },
                {
                    property: 'roundInfo.maxDrawCount',
                },
                {
                    property: 'seniorInfo.participateCondition',
                },
                {
                    property: 'seniorInfo.receiptCertification',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                //校验报名条件配置输入值
                const participateCondition = entity.seniorInfo.participateCondition;
                if (participateCondition && participateCondition.length > 0) {
                    const minRegisterDays: any = find(participateCondition, {
                        code: 'MINREGISTERDAYS',
                    });
                    const consumption: any = find(participateCondition, { code: 'CONSUMPTIONT' });
                    if (minRegisterDays.checked && !minRegisterDays.value) {
                        message.error(services.language.getText('qtxbmtjxzxddyz'));
                        return false;
                    }
                    if (consumption.checked && !consumption.value) {
                        message.error(services.language.getText('qtxbmtjxzxddyz'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        WhitelistManagerPage: {
            component: 'Tabs',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<bmdgl>>',
                    content: {
                        component: 'WhitelistManagerList',
                    },
                },
                {
                    title: '<<importRecord>>',
                    content: {
                        component: 'WhitelistManagerRecord',
                    },
                },
            ],
        },
        WhitelistManagerList: {
            entity: 'WhitelistManagerListEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'WhitelistManagerListFilter',
                },
                {
                    component: 'WhitelistManagerListHandler',
                },
                {
                    component: 'WhitelistManagerListTable',
                },
            ],
        },
        WhitelistManagerListFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'mobile',
                },
            ],
        },
        WhitelistManagerListHandler: {
            component: 'GridLayout',
            style: {
                marginBottom: 16,
            },
            items: [
                {
                    component: 'ImportTable',
                    props: {
                        triggerType: 'primary',
                        triggerText: '<<drbmd>>',
                        triggerIcon: 'plus',
                        controlDownloadTemplate: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                            apiPath: `/admin/reserve_draw_lots_activities/template.xlsx`,
                        },
                        controlHandles: [
                            {
                                apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                                apiPath:
                                    '/admin/reserve_draw_lots_activities/:id/upload?importType=ADD&type=WHITE',
                                params: {
                                    importType: 'ADD',
                                    type: 'WHITE',
                                },
                                type: 'primary',
                                text: '<<xzdr>>',
                                paramsHandler(config: any, fileInfo: any) {
                                    const name = getUrlParam(window.location.hash, 'name');
                                    return {
                                        fileId: fileInfo.id,
                                        fileUrl: fileInfo.path,
                                        name,
                                        id: get(config, 'row.id', get(config, 'params.id')),
                                    };
                                },
                            },
                            {
                                apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                                apiPath:
                                    '/admin/reserve_draw_lots_activities/:id/upload?importType=COVER&type=WHITE',
                                params: {
                                    importType: 'COVER',
                                    type: 'WHITE',
                                    name: getUrlParam(window.location.hash, 'name'),
                                },
                                type: 'primary',
                                text: '<<fgdr>>',
                                paramsHandler(config: any, fileInfo: any) {
                                    const name = getUrlParam(window.location.hash, 'name');
                                    return {
                                        fileId: fileInfo.id,
                                        fileUrl: fileInfo.path,
                                        name,
                                        id: get(config, 'row.id', get(config, 'params.id')),
                                    };
                                },
                            },
                        ],
                    },
                    span: 4,
                },
                {
                    component: 'EntityButton',
                    props: {
                        type: 'primary',
                        text: '<<exportSearchResult>>',
                        onClick(_: any, config: any) {
                            const params = Object.assign(
                                {},
                                config.entity.params,
                                config.entity.filters
                            );
                            services.api
                                .download(params, {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                                    apiPath: '/admin/reserve_draw_lots_activities/:id/WHITE/export',
                                })
                                .catch(errorHandle);
                        },
                    },
                    span: 4,
                },
            ],
        },
        WhitelistManagerListTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'mobile',
                },
                {
                    property: 'createTime',
                },
            ],
        },
        WhitelistManagerRecord: {
            entity: 'WhitelistManagerRecordEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'WhitelistManagerRecordFilter',
                },
                {
                    component: 'WhitelistManagerRecordTable',
                },
            ],
        },
        WhitelistManagerRecordFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsrczr>>',
                    },
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        WhitelistManagerRecordTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'time',
                },
                {
                    property: 'importType',
                },
                {
                    property: 'optResult',
                },
                {
                    property: 'importResult',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                items: [
                    {
                        type: 'component',
                        component: 'ExportDataButtonFail',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                            apiPath: '/admin/reserve_list_activity/WHITE/{{row.id}}/failExport',
                            text: '<<dcsbqd>>',
                        },
                    },
                    {
                        type: 'component',
                        component: 'ExportDataButton',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                            apiPath: '/admin/reserve_list_activity/WHITE/{{row.id}}/export',
                            text: '<<qbdc>>',
                        },
                    },
                ],
            },
        },
        BlacklistManagerPage: {
            component: 'Tabs',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<hmdgl>>',
                    content: {
                        component: 'BlacklistManagerList',
                    },
                },
                {
                    title: '<<importRecord>>',
                    content: {
                        component: 'BlacklistManagerRecord',
                    },
                },
            ],
        },
        BlacklistManagerList: {
            entity: 'BlacklistManagerListEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'BlacklistManagerListFilter',
                },
                {
                    component: 'BlacklistManagerListHandler',
                },
                {
                    component: 'BlacklistManagerListTable',
                },
            ],
        },
        BlacklistManagerListFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },

            fields: [
                {
                    property: 'mobile',
                },
            ],
        },
        BlacklistManagerListHandler: {
            component: 'GridLayout',
            className: 'mt24 mb24',
            items: [
                {
                    component: 'ImportTable',
                    props: {
                        triggerType: 'primary',
                        triggerText: '<<drhmd>>',
                        triggerIcon: 'plus',
                        controlDownloadTemplate: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                            apiPath: `/admin/reserve_draw_lots_activities/template.xlsx`,
                        },
                        customCallback() {
                            Modal.info({
                                content: services.language.getText('qshqwdrjlzck'),
                            });
                        },
                        controlHandles: [
                            {
                                apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                                apiPath:
                                    '/admin/reserve_draw_lots_activities/:id/upload?importType=ADD&type=BLACK',
                                params: {
                                    importType: 'ADD',
                                    type: 'BLACK',
                                },
                                type: 'primary',
                                text: '<<xzdr>>',
                                paramsHandler(config: any, fileInfo: any) {
                                    const name = getUrlParam(window.location.hash, 'name');

                                    return {
                                        fileId: fileInfo.id,
                                        fileUrl: fileInfo.path,
                                        name,
                                        id: get(config, 'row.id', get(config, 'params.id')),
                                    };
                                },
                            },
                            {
                                apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                                apiPath:
                                    '/admin/reserve_draw_lots_activities/:id/upload?importType=COVER&type=BLACK',
                                params: {
                                    importType: 'COVER',
                                    type: 'BLACK',
                                },
                                type: 'primary',
                                text: '<<fgdr>>',
                                paramsHandler(config: any, fileInfo: any) {
                                    const name = getUrlParam(window.location.hash, 'name');
                                    return {
                                        fileId: fileInfo.id,
                                        fileUrl: fileInfo.path,
                                        name,
                                        id: get(config, 'row.id', get(config, 'params.id')),
                                    };
                                },
                            },
                        ],
                    },
                    span: 3,
                },
                {
                    component: 'EntityButton',
                    props: {
                        type: 'primary',
                        text: '<<exportSearchResult>>',
                        onClick(_: any, config: any) {
                            const params = Object.assign(
                                {},
                                config.entity.params,
                                config.entity.filters
                            );
                            services.api
                                .download(params, {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                                    apiPath: '/admin/reserve_draw_lots_activities/:id/BLACK/export',
                                })
                                .catch(errorHandle);
                        },
                    },
                    span: 3,
                },
            ],
        },
        BlacklistManagerListTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'mobile',
                },
                {
                    property: 'createTime',
                },
            ],
        },
        BlacklistManagerRecord: {
            entity: 'BlacklistManagerRecordEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'BlacklistManagerRecordFilter',
                },
                {
                    component: 'BlacklistManagerRecordTable',
                },
            ],
        },
        BlacklistManagerRecordFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsrczr>>',
                    },
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        BlacklistManagerRecordTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'time',
                },
                {
                    property: 'importType',
                },
                {
                    property: 'optResult',
                },
                {
                    property: 'importResult',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                items: [
                    {
                        type: 'component',
                        component: 'ExportDataButtonFail',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                            apiPath: '/admin/reserve_list_activity/BLACK/{{row.id}}/failExport',
                            text: '<<dcsbqd>>',
                        },
                    },
                    {
                        type: 'component',
                        component: 'ExportDataButton',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                            apiPath: '/admin/reserve_list_activity/BLACK/{{row.id}}/export',
                            text: '<<qbdc>>',
                        },
                    },
                ],
            },
        },
        ReserveDrawLotsImportLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                    apiPath: '/admin/reserve_draw_lots_activities/{id}/operation_logs',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/reserve-draw-lots-import',
                    component: 'ReserveDrawLotsImportView',
                    breadcrumbName: '<<yycq>>',
                    privilege: {
                        path: 'reserveDrawLotsImport',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'ReserveDrawLotsImportAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'ReserveDrawLotsImportEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/whitelist-manager/:id',
                            component: 'WhitelistManagerPage',
                        },
                        {
                            path: '/blacklist-manager/:id',
                            component: 'BlacklistManagerPage',
                        },
                        {
                            path: '/logs/:id',
                            component: 'ReserveDrawLotsImportLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        {
                            path: '/',
                            component: 'ReserveDrawLotsImportPage',
                        },
                    ],
                },
            ],
        },
    ],
};
