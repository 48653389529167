import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
export const GuidesCommissionLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const { status, clearingStatus, actualSettlementDateRange, clearingDateRange } = data;
        if (status && status === 'ALL') {
            delete data.status;
        }
        if (clearingStatus && clearingStatus === 'ALL') {
            delete data.clearingStatus;
        }
        if (actualSettlementDateRange) {
            data.actualSettlementStartTime = actualSettlementDateRange.start;
            data.actualSettlementEndTime = actualSettlementDateRange.end;
            delete data.actualSettlementDateRange;
        }
        if (clearingDateRange) {
            data.clearingStartTime = clearingDateRange.start;
            data.clearingEndTime = clearingDateRange.end;
            delete data.clearingDateRange;
        }
        const type =
            config.paramsFilter && config.paramsFilter.length > 0 ? config.paramsFilter[0] : null;
        if (type) {
            delete config.paramsFilter;
            data.type = type;
        }
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/distribution_goods_settlement_records';
        return await api.get(data, config).then((res: any) => {
            res.result = res.result.map((item: any) => {
                const {
                    clearingStatus,
                    orderCreateTime,
                    orderReceivedTime,
                    actualSettlementTime,
                    clearingTime,
                } = item;
                if (!clearingStatus) {
                    item.clearingStatus = 'NONE';
                }
                if (!orderCreateTime) {
                    item.orderCreateTime = '-';
                }
                if (!orderReceivedTime) {
                    item.orderReceivedTime = '-';
                }
                if (!actualSettlementTime) {
                    item.actualSettlementTime = '-';
                }
                if (!clearingTime) {
                    item.clearingTime = '-';
                }
                return item;
            });
            return res;
        });
    },
};
