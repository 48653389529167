import React, { PureComponent } from 'react';
import { Button, Modal, Row, Col, message, Input } from 'antd';
import { map, isEmpty, get } from 'lodash';
import { CustomSelectSubsite } from '../../applications/design/design/components/custom-select-subsite-select';
import { LinkTypePlus } from '../../applications/design/components';
import {
    customSelectorConfig,
    newClientCustomSelectorConfig,
} from '../../applications/design/design';
import {
    getNewLinkTypeEffectPathConfigList,
    LinkTypeEffectPathConfig,
} from '../../components/link-type-effect-path/config';
import {
    linkNameGen,
    linkPathGen,
    transformParams,
} from '../../containers/miniprogram-v2/mini-program-code-default-link-edit-button';
import './index.less';
import { services } from '@comall-backend-builder/core';

const { TextArea } = Input;
const DEFAULT_VALUE = {
    linkType: '',
    linkParams: null,
    linkName: '',
    linkPath: '',
    needUrlLink: false,
};

interface Subsite {
    id: string;
    name: string;
    subsiteId: string;
    code: string;
}
interface MiniProgramParamSettingProps {}
interface MiniProgramParamSettingState {
    showModal: boolean;
    currentSubsit?: Subsite;
    changeTargetValue: any;
    jsonString: string;
    linkTypes: LinkTypeEffectPathConfig[];
}

export class MiniProgramParamSetting extends PureComponent<
    MiniProgramParamSettingProps,
    MiniProgramParamSettingState
> {
    state: MiniProgramParamSettingState = {
        showModal: false,
        currentSubsit: undefined,
        changeTargetValue: DEFAULT_VALUE,
        jsonString: '',
        linkTypes: [],
    };
    componentDidMount(): void {
        this.setState({ linkTypes: getNewLinkTypeEffectPathConfigList() });
    }
    onShowModal = () => {
        this.setState({
            showModal: true,
        });
    };
    onCancel = () => {
        this.setState({
            showModal: false,
        });
    };
    handleSubsiteChange = (value: Subsite) => {
        this.setState({
            currentSubsit: value,
            changeTargetValue: DEFAULT_VALUE,
        });
    };
    findLinkTypeById = (
        id: string,
        linkTypes: LinkTypeEffectPathConfig[]
    ): LinkTypeEffectPathConfig | undefined => {
        let config;
        linkTypes.forEach((linkType) => {
            if (!linkType.children && linkType.id === id) {
                config = linkType;
            }
            if (linkType.children && linkType.children.length > 0) {
                linkType.children.forEach((it) => {
                    if (it.id === id) {
                        config = it;
                    }
                });
            }
        });
        return config;
    };
    changeTargetValue = (val: any) => {
        const { linkTypes } = this.state;
        const obj = this.findLinkTypeById(val.linkType, linkTypes);
        if (obj) {
            const valObj = { ...val };
            const paramsObj = transformParams(val.linkParams, obj.paramsHandler);
            Object.assign(valObj, {
                linkName: linkNameGen(obj.name, valObj.linkParams),
                linkPath: linkPathGen(obj.basePath, obj.params, paramsObj),
            });
            this.setState({
                changeTargetValue: valObj,
            });
        } else {
            this.setState({
                changeTargetValue: DEFAULT_VALUE,
            });
        }
    };
    onReset = () => {
        this.setState({
            currentSubsit: undefined,
            changeTargetValue: DEFAULT_VALUE,
            jsonString: '',
        });
    };
    createJson = () => {
        const { changeTargetValue, currentSubsit } = this.state;
        const linkType = get(changeTargetValue, 'linkType');
        const linkParams = get(changeTargetValue, 'linkParams');
        const configKeys = Object.keys(customSelectorConfig) || [];
        let flag = true;
        const noLinkParams = ['subsite', 'newTopic'];
        map([...configKeys, ...noLinkParams], (item) => {
            if (linkType === item && isEmpty(linkParams)) {
                flag = false;
            }
        });
        if (!flag) {
            message.warning(services.language.getText('selectLinkTarget'));
            return false;
        }
        const targetObj = {
            target: JSON.stringify(changeTargetValue),
            subsiteId: currentSubsit?.id,
            subsiteCode: currentSubsit?.code,
            type: linkType,
            url: changeTargetValue.linkPath,
            statisticType: 'CHANNEL',
            channelType: '',
            applicationVersion: 'V2',
        };
        this.setState({
            jsonString: JSON.stringify(targetObj),
        });
    };
    copyJson = () => {
        const { jsonString } = this.state;
        let input = document.createElement('input');
        input.value = jsonString;
        document.body.appendChild(input);
        input.select();
        if (document.execCommand('Copy')) {
            message.success(services.language.getText('copySuccess'));
        } else {
            message.warning(services.language.getText('copyError'));
        }
        document.body.removeChild(input);
    };
    renderModal = () => {
        const { showModal, currentSubsit, changeTargetValue, jsonString, linkTypes } = this.state;
        const linkTypeSelector = newClientCustomSelectorConfig;
        const linkType = get(changeTargetValue, 'linkType');
        const subsiteIds = currentSubsit ? [currentSubsit.id] : undefined;
        const disabled = !currentSubsit || !linkType;
        return (
            <Modal
                destroyOnClose
                visible={showModal}
                title={services.language.getText('ldycs')}
                width="500px"
                onCancel={this.onCancel}
                footer={null}
            >
                <div className="mini-program-param__modal">
                    <Row style={{ marginBottom: 12 }}>
                        <Col span={6}>{services.language.getText('otherSelectSubsite')}：</Col>
                        <Col span={18}>
                            <CustomSelectSubsite
                                needCode
                                value={currentSubsit}
                                onChange={this.handleSubsiteChange}
                            />
                        </Col>
                    </Row>
                    <Row style={{ marginBottom: 12 }}>
                        <Col span={6}>{services.language.getText('mbldy')}：</Col>
                        <Col span={18}>
                            {currentSubsit ? (
                                <LinkTypePlus
                                    selector={linkTypeSelector as any}
                                    linkTypes={linkTypes}
                                    linkTypeMode="cascader"
                                    value={changeTargetValue}
                                    onChange={this.changeTargetValue}
                                    subsiteIds={subsiteIds}
                                />
                            ) : (
                                <div>{services.language.getText('selectBeforeMerchant')}</div>
                            )}
                        </Col>
                    </Row>
                    <div className="mini-program-param__modal__buttons">
                        <Button disabled={disabled} type="primary" onClick={this.createJson}>
                            {services.language.getText('produceJson')}
                        </Button>
                        <Button style={{ marginLeft: 12 }} onClick={this.onReset}>
                            {services.language.getText('common.reset')}
                        </Button>
                    </div>
                    {jsonString ? (
                        <div>
                            <div>
                                <TextArea disabled rows={4} value={jsonString} />
                            </div>
                            <div className="mini-program-param__modal__buttons">
                                <Button type="primary" onClick={this.copyJson}>
                                    {services.language.getText('copy')}
                                </Button>
                            </div>
                        </div>
                    ) : null}
                </div>
            </Modal>
        );
    };
    render() {
        return (
            <div>
                <Button
                    type="primary"
                    style={{ marginBottom: '12px', marginLeft: '12px' }}
                    onClick={this.onShowModal}
                >
                    {services.language.getText('ldycs')}
                </Button>
                {this.renderModal()}
            </div>
        );
    }
}
