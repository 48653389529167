import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { PrivilegesLoader } from './privileges';
import { UserLoader } from './user-loader';
import { RoleLoader } from './role-loader';
import { SessionsLoader } from './sessions-loader';
import { UserRoleLoader } from './user-role-loader';
import { TenantLoader } from './tenant-loader';
import { MerchantUserLoader } from './merchant-user-loader';
import { MerchantRoleLoader } from './merchant-role-loader';
import { OrganizationLoader } from './organization-loader';
import { NewRoleLoader } from './new-role-loader';
import {
    OrganizationSubsiteLoader,
    OrganizationMerchantLoader,
    GuideUserAuditLoader,
} from './guide-user-loader';
import { NewUserLoader } from './new-user-loader';
import { MerchantUserLogLoader } from './merchant-user-log-loader';

const myLoaders = new Map<string, Loader>();

myLoaders.set('/privileges', PrivilegesLoader);

myLoaders.set('/admin/users', UserLoader);
myLoaders.set('/admin/roles', RoleLoader);
myLoaders.set('/admin/tenants', TenantLoader);
myLoaders.set('/admin/user', SessionsLoader);
myLoaders.set('/admin/users/:id/roles', UserRoleLoader);
myLoaders.set('/admin/merchant-users', MerchantUserLoader);
myLoaders.set('/admin/merchant-roles', MerchantRoleLoader);
myLoaders.set('/admin/merchant-users-log', MerchantUserLogLoader);

// cae权限重构
myLoaders.set('/cae/organizations', OrganizationLoader);
myLoaders.set('/cae/roles', NewRoleLoader);
myLoaders.set('/cae/audit-user', GuideUserAuditLoader);
myLoaders.set('/cae/organization-merchant', OrganizationMerchantLoader);
myLoaders.set('/cae/organization-subsite', OrganizationSubsiteLoader);
myLoaders.set('/cae/users', NewUserLoader);

export default myLoaders;
