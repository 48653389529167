import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import {
    chain,
    get,
    isNil,
    isEmpty,
    map,
    forIn,
    cloneDeep,
    snakeCase,
    camelCase,
    filter,
} from 'lodash';
import moment from 'moment';
import {
    NewMallActivityRoundItem,
    NewMallActivityRegistrationTimeType,
    NewMallActivityType,
    NewMallActivityTicketType,
} from '../components';
import { cloneRound, sortRoundsByStartDateTime } from '../components/new-mall-activity/common';
import { orderStatusMap } from './magic-mall-activity-registration-loader';
import { MsgChannelType } from '../types/mode/array/array-message-notification-method/message-notification-method';
import { PushAndSiteMailSceneType } from '../containers/wechat/common';

const api = services.api;

export const NewMallActivityLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`;
        config.apiPath = '/admin/v1/mall_activities';
        const { pageType, dateRange, ...params } = data;
        const { id } = params;
        if (id) {
            config.apiPath = `/admin/v1/mall_activities/${id}`;
        } else {
            if (!isEmpty(dateRange)) {
                params.activityStartDate = dateRange.start;
                params.activityEndDate = dateRange.end;
            }
        }
        return await api.get(params, config).then(async (res: any) => {
            if (id) {
                const {
                    basicInfo,
                    pageInfo,
                    messageNoticeRule,
                    ticketRounds,
                    ticketPurchaseCondition,
                    ticketCancelRule,
                    ticketPurchaseReward,
                    msgNotices,
                } = res;
                const isCopy = pageType === 'copy';
                const rounds = isCopy ? ticketRounds?.map(cloneRound) : ticketRounds;
                const {
                    mallActivityTime: { startTime, endTime, dailyStartTime, dailyEndTime },
                } = basicInfo;

                res.baseInfo = {
                    id,
                    name: basicInfo.name,
                    merchants: basicInfo.subsiteMerchants.map((item: any) => ({
                        ...item,
                        code: item.merchantCode,
                        subSiteId: item.subsiteId,
                        subSiteName: item.subsiteName,
                    })),

                    dateRange: { start: startTime, end: endTime },
                    dayTime: [
                        {
                            start: dailyStartTime,
                            end: dailyEndTime,
                        },
                    ],
                    phone: basicInfo.servicePhone || '',
                    address: basicInfo.address || '',
                    addressName: basicInfo.addressName || '',
                    merchantUserPhone: basicInfo.shoppingGuideMobile || '',
                    editStartTime: isCopy ? undefined : startTime,
                    editEndTime: isCopy ? undefined : endTime,
                    canShare: basicInfo.canShare ? ['true'] : [],
                    hideSetting: basicInfo.crowdDisplayCondition ? ['true'] : [],
                    tagIdList: basicInfo.activityTags,
                    crowdDisplayCondition: basicInfo.crowdDisplayCondition
                        ? basicInfo.crowdDisplayCondition
                        : 'INVISIBLE',
                    hideCrowdsStatus: basicInfo.blacklistHide ? 'blacklist' : 'appoint',
                    hideCrowds: basicInfo.hideCrowds,
                    showCrowds: basicInfo.showCrowds,
                };
                const { info, picture, sharePosterPicture } = pageInfo;
                res.mediaInfo = {
                    pictures: [{ id: picture.id, path: picture.url }],
                    info,
                };
                if (sharePosterPicture) {
                    res.mediaInfo.poster = [
                        {
                            id: sharePosterPicture.id,
                            path: sharePosterPicture.url,
                        },
                    ];
                }

                res.contentInfo = {
                    creator: basicInfo.initiatorMobile || '',
                    activityTopics: basicInfo.activityTopics,
                };
                let registrationInfo: any = {
                    type: basicInfo.type,
                };
                if (rounds.length && basicInfo.type !== NewMallActivityType.Notification) {
                    const ticketType = basicInfo.ticketType || NewMallActivityTicketType.Activity;
                    const {
                        basicCondition,
                        customExtensionCondition,
                        ticketPurchaseTimeLimit,
                        reject,
                        rejectActivityInfos,
                        roundDailyLimit,
                        roundTotalLimit,
                    } = ticketPurchaseCondition;
                    const isLimitSession = Boolean(roundDailyLimit || roundTotalLimit);
                    registrationInfo = {
                        ...registrationInfo,
                        ticketType: ticketType,
                        registrationStartTime: {
                            timeStr: ticketPurchaseTimeLimit.startTime,
                            timeUnit: ticketPurchaseTimeLimit.startTimeUnit,
                            num: ticketPurchaseTimeLimit.startTimeValue,
                            timeType: ticketPurchaseTimeLimit.startTimeType || 'DAY_TIME',
                        },
                        registrationEndTime: {
                            timeType: ticketPurchaseTimeLimit.endTimeType,
                            timeUnit: ticketPurchaseTimeLimit.endTimeUnit,
                            num: ticketPurchaseTimeLimit.endTimeValue,
                            timeStr: ticketPurchaseTimeLimit.endTime,
                        },
                        targetUserType: Boolean(basicCondition) ? 'SOME' : 'ANY',
                        targetUserLevels: (basicCondition?.memberCardLevels || []).map(
                            (item: any) => item.id
                        ),
                        targetUserSexuality: basicCondition?.gender,
                        customInfo: customExtensionCondition
                            ? JSON.parse(customExtensionCondition)
                            : undefined,
                        reviewType: basicInfo.ticketPurchaseAudit ? 'TRUE' : 'FALSE',
                        cancelableRule: {
                            cancelable: ticketCancelRule?.cancelable,
                            cancelableRule: {
                                duration: ticketCancelRule?.duration || undefined,
                                unit: ticketCancelRule?.timeUnit || 'HOUR',
                            },
                        },
                        excludeRule: !reject
                            ? 'NONE'
                            : rejectActivityInfos?.length
                            ? 'SOME'
                            : 'ALL',
                        excludeTypeActivity: rejectActivityInfos,
                        limitSession: isLimitSession ? 'YES' : 'NO',
                        limitSessionCount: isLimitSession
                            ? {
                                  dayCount: roundDailyLimit,
                                  activityCount: roundTotalLimit,
                              }
                            : undefined,
                        info: basicInfo.participationClause,
                        targetUserCrowdStatus: basicCondition?.nonPurchasableCrowds?.length
                            ? 'non'
                            : 'ok',
                        nonPurchasableCrowds: basicCondition?.nonPurchasableCrowds,
                        purchasableCrowds: basicCondition?.purchasableCrowds,
                    };
                    if (basicCondition?.birthdayStartDate) {
                        registrationInfo.targetUserBirthDay = {
                            from: basicCondition.birthdayStartDate,
                            to: basicCondition.birthdayEndDate,
                        };
                    }
                    if (ticketType === NewMallActivityTicketType.Activity) {
                        registrationInfo.activitySingleRoundTickets = rounds[0].tickets;
                        registrationInfo.maxPurchaseTotal = rounds[0].maxPurchaseTotal;
                    } else {
                        registrationInfo.activityRounds = rounds;
                    }
                }
                res.registrationInfo = registrationInfo;
                let rewardInfo: any = {
                    rewardDistributionTime: 'VERIFY_ACTIVITY',
                };
                if (ticketPurchaseReward) {
                    const { rewardItems, rewardName, sendRewardTouchPoint } = ticketPurchaseReward;
                    const reward = rewardItems?.map((item: any) => {
                        const data: any = {
                            type: {
                                checked: item.rewardType,
                            },
                            name: rewardName,
                        };
                        if (item.rewardType === 'COUPON') {
                            data.type.assetRewards = [
                                {
                                    rewardType: 'COUPON',
                                    rewardValue: [
                                        {
                                            // 优惠券详情
                                            ...item.couponInfo,
                                            type: item.couponInfo.couponType,
                                            id: item.couponInfo.couponDefinitionId,
                                            leftStock: item.leftStock,
                                        },
                                    ],
                                },
                            ];
                        }
                        return data;
                    });
                    rewardInfo = {
                        rewardDistributionTime: sendRewardTouchPoint || 'VERIFY_ACTIVITY',
                        reward,
                    };
                }
                res.rewardInfo = rewardInfo;
                let businessNotifications: any[] = [];
                let participatNotifications: any[] = [];
                let registrationAbsentedNotifications: any[] = [];
                let registrationStartedNotifications: any[] = [];
                if (msgNotices && msgNotices.length) {
                    msgNotices.forEach((item: any) => {
                        const currentNotifications =
                            item.businessNotifications && item.businessNotifications.length
                                ? item.businessNotifications.map((item: any) => {
                                      let content: any[] = [];
                                      if (item.content) {
                                          try {
                                              const newContent = JSON.parse(item.content);
                                              newContent.forEach((i: any) => {
                                                  const newItem: any = {};
                                                  forIn(i, (value, key) => {
                                                      newItem[camelCase(key)] = value;
                                                  });
                                                  if (
                                                      (item.msgChannelType ===
                                                          MsgChannelType.APP_PUSH ||
                                                          item.msgChannelType ===
                                                              MsgChannelType.SITEMAIL ||
                                                          item.msgChannelType ===
                                                              MsgChannelType.EMAIL) &&
                                                      newItem.fieldType === 'file' &&
                                                      newItem.value
                                                  ) {
                                                      newItem.value = JSON.parse(newItem.value);
                                                  }
                                                  content.push(newItem);
                                              });
                                          } catch (error) {
                                              content = item.content;
                                          }
                                      }
                                      return {
                                          id: item.id,
                                          status: item.status,
                                          msgChannelType: item.msgChannelType,
                                          scene: item.scene,
                                          targetType: item.targetType,
                                          targetUrl: item.targetUrl,
                                          params: content,
                                      };
                                  })
                                : [];
                        if (
                            item.scene ===
                            PushAndSiteMailSceneType.MALL_ACTIVITY_REGISTRATION_SUCCEEDED_MSG
                        ) {
                            businessNotifications = currentNotifications;
                        } else if (
                            item.scene === PushAndSiteMailSceneType.MALL_ACTIVITY_PARTICIPATE_MSG
                        ) {
                            participatNotifications = currentNotifications;
                        } else if (
                            item.scene ===
                            PushAndSiteMailSceneType.MALL_ACTIVITY_REGISTRATION_ABSENTED_MSG
                        ) {
                            registrationAbsentedNotifications = currentNotifications;
                        } else if (
                            item.scene ===
                            PushAndSiteMailSceneType.MALL_ACTIVITY_REGISTRATION_STARTED_MSG
                        ) {
                            registrationStartedNotifications = currentNotifications;
                        }
                    });
                }
                const { leadReminderTimeUnit, leadReminderTime } = messageNoticeRule;
                res.seniorSetting = {
                    businessNotifications,
                    participatNotifications,
                    registrationAbsentedNotifications,
                    registrationStartedNotifications,
                    // enableNotice: [enableNotice + ''],
                    subscriptMessage: {
                        timeUnit: leadReminderTimeUnit,
                        num: leadReminderTime,
                    },
                    marking: !isNil(basicInfo.memberTag)
                        ? {
                              check: true,
                              label: String(basicInfo.memberTag.id),
                          }
                        : undefined,
                };
                // 待确认
                // res.registrationStatus = res.mallActivityState.registrationStatus;

                res.mallActivityStatus = basicInfo.mallActivityStatus;
                res.enable = basicInfo.enable;
            } else {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        item.info = {
                            url: item.picture.url,
                            name: item.name,
                            startTime: item.mallActivityTime.startTime,
                            endTime: item.mallActivityTime.endTime,
                        };
                        item.subSites = item.subsiteInfos;
                        item.merchants = item.merchantInfos || [];
                        item.startTime = item.mallActivityTime.startTime;
                        item.endTime = item.mallActivityTime.endTime;
                        item.canEdit = item.status === 'NOT_STARTED';
                        item.mallActivityStatisticTotal = `${item.totalConsumeQuantity} / ${item.totalSalesQuantity} / ${item.totalTicketQuantity}`;
                        item.participationStatus = item.participationStatus || 'NULL';
                        if (item.participationStatus === 'NULL') {
                            item.mallActivityStatisticTotal = '- / - / -';
                        }
                        // 暂时禁用没用到的操作
                        item.disableAction = false;

                        return item;
                    });
            }

            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`;
        config.apiPath = '/admin/v1/mall_activities';
        let newParams: any = transformRequestData(params, 'post');

        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`;
        if (params.id) {
            config.apiPath = `/admin/v1/mall_activities/${params.id}`;
        }
        let newParams: any = transformRequestData(params, 'put');
        return await api.put(newParams, config);
    },
};

export function transformRequestData(params: any, mode: 'post' | 'put') {
    const isAdd = mode === 'post';
    const {
        baseInfo,
        mediaInfo,
        registrationInfo,
        contentInfo,
        rewardInfo,
        seniorSetting,
    } = params;
    const startTime = isAdd ? baseInfo.dateRange.start : baseInfo.editStartTime;
    const endTime = isAdd ? baseInfo.dateRange.end : baseInfo.editEndTime;
    const dailyStartTime = baseInfo.dayTime[0].start;
    const dailyEndTime = baseInfo.dayTime[0].end;
    if (!get(baseInfo, 'hideSetting[0]')) {
        // 隐藏设置不勾选则所有数据清空
        baseInfo.hideCrowds = [];
        baseInfo.showCrowds = [];
        baseInfo.hideCrowdsStatus = '';
    } else {
        if (baseInfo.crowdDisplayCondition === 'INVISIBLE') {
            // 选择所有人可见 则清空所有的人群集合（不可见、可见）
            baseInfo.hideCrowds = [];
            baseInfo.showCrowds = [];
            baseInfo.hideCrowdsStatus = '';
        } else if (baseInfo.crowdDisplayCondition === 'PART_CROWD_HIDE') {
            // 选择指定人群可见，清空指定人群不可见的集合
            baseInfo.showCrowds = [];
            // 选择了指定人群不可见中的仅黑名单中用户不可见
            if (baseInfo.hideCrowdsStatus === 'blacklist') {
                baseInfo.hideCrowds = [];
            }
        } else {
            // 选择指定人群可见，清空指定人群不可见的集合
            baseInfo.hideCrowds = [];
            baseInfo.hideCrowdsStatus = '';
        }
    }

    if (registrationInfo.targetUserCrowdStatus === 'ok') {
        // 不可购买的用户选择人群选择了指定人群可参与 则清空指定人群不可参与的数据集合
        registrationInfo.nonPurchasableCrowds = [];
    } else {
        // 不可购买的用户选择人群选择了指定人群不可参与 则清空指定人群可参与的数据集合
        registrationInfo.purchasableCrowds = [];
    }
    const basicInfo = {
        address: baseInfo.address,
        addressName: baseInfo.addressName,
        canShare: get(baseInfo, 'canShare[0]') === 'true' ? true : false,
        hide:
            get(baseInfo, 'hideSetting[0]') === 'true' &&
            baseInfo.crowdDisplayCondition === 'INVISIBLE'
                ? true
                : false,
        blacklistHide: baseInfo.hideCrowdsStatus === 'blacklist' ? true : false,
        hideCrowdIds: baseInfo.hideCrowds?.length
            ? baseInfo.hideCrowds.map((i: any) => i.id)
            : undefined,
        showCrowdIds: baseInfo.showCrowds?.length
            ? baseInfo.showCrowds.map((i: any) => i.id)
            : undefined,
        initiatorMemberId: contentInfo?.creator ? contentInfo?.creatorId : undefined,
        initiatorMobile: contentInfo?.creator,
        mallActivityTime: {
            startTime,
            endTime,
            dailyStartTime,
            dailyEndTime,
        },
        name: baseInfo.name,
        participationClause: registrationInfo.info,
        servicePhone: baseInfo.phone,
        shoppingGuideMobile: baseInfo.merchantUserPhone,
        subsiteMerchants: baseInfo.merchants.map((i: any) => ({
            merchantId: i.merchantId,
            subsiteId: i.subSiteId,
        })),
        tagId: seniorSetting?.marking?.check ? seniorSetting?.marking?.label : undefined,
        tagIds: baseInfo.tagIdList?.length ? baseInfo.tagIdList.map((i: any) => i.id) : undefined,
        ticketType:
            registrationInfo.type === NewMallActivityType.Notification
                ? null
                : registrationInfo?.ticketType,
        ticketPurchaseAudit: registrationInfo.reviewType === 'TRUE',
        topicIds: contentInfo?.activityTopics?.length
            ? contentInfo.activityTopics.map((i: any) => i.id)
            : undefined,
        type: registrationInfo.type,
    };

    // const enableNotice = seniorSetting.enableNotice?.includes('true');
    const enableNotice =
        filter(seniorSetting.businessNotifications || [], {
            status: 1,
            msgChannelType: MsgChannelType.WEAPP,
        }).length ||
        filter(seniorSetting.participatNotifications || [], {
            status: 1,
            msgChannelType: MsgChannelType.WEAPP,
        }).length ||
        filter(seniorSetting.registrationAbsentedNotifications || [], {
            status: 1,
            msgChannelType: MsgChannelType.WEAPP,
        }).length ||
        filter(seniorSetting.registrationStartedNotifications || [], {
            status: 1,
            msgChannelType: MsgChannelType.WEAPP,
        }).length;
    const messageNoticeRule = {
        enableNotice: Boolean(enableNotice),
        leadReminderTime: seniorSetting.subscriptMessage?.num,
        leadReminderTimeUnit: seniorSetting.subscriptMessage?.timeUnit,
    };
    let businessNotificationsScene = '';
    let participatNotificationsScene = '';
    let registrationAbsentedNotificationsScene = '';
    let registrationStartedNotificationsScene = '';
    const businessNotifications = map(
        cloneDeep(seniorSetting.businessNotifications),
        (item: any) => {
            const isSitemail =
                item.msgChannelType === MsgChannelType.APP_PUSH ||
                item.msgChannelType === MsgChannelType.SITEMAIL;
            const content = item.params.map((param: any) => {
                if (param.fieldType === 'file' && param.value && isSitemail) {
                    param.value = param.value.id;
                }
                if (
                    param.fieldType === 'file' &&
                    param.value &&
                    item.msgChannelType === MsgChannelType.EMAIL
                ) {
                    param.value = param.value.map((i: any) => i.id).join(',');
                }
                if (item.msgChannelType === MsgChannelType.SMS && !param.editable) {
                    param.value = '';
                }
                const newParam: any = {};
                forIn(param, (value, key) => {
                    newParam[snakeCase(key)] = value;
                });
                return newParam;
            });
            businessNotificationsScene = item.scene;
            return {
                content: JSON.stringify(content),
                id: item?.id,
                msgChannelType: item.msgChannelType,
                scene: item.scene,
                status: item.status,
                targetType: item.targetType,
                targetUrl: item.targetUrl,
            };
        }
    );
    const participatNotifications = map(
        cloneDeep(seniorSetting.participatNotifications),
        (item: any) => {
            const isSitemail =
                item.msgChannelType === MsgChannelType.APP_PUSH ||
                item.msgChannelType === MsgChannelType.SITEMAIL;
            const content = item.params.map((param: any) => {
                if (param.fieldType === 'file' && param.value && isSitemail) {
                    param.value = param.value.id;
                }
                if (
                    param.fieldType === 'file' &&
                    param.value &&
                    item.msgChannelType === MsgChannelType.EMAIL
                ) {
                    param.value = param.value.map((i: any) => i.id).join(',');
                }
                if (item.msgChannelType === MsgChannelType.SMS && !param.editable) {
                    param.value = '';
                }
                const newParam: any = {};
                forIn(param, (value, key) => {
                    newParam[snakeCase(key)] = value;
                });
                return newParam;
            });
            participatNotificationsScene = item.scene;
            return {
                content: JSON.stringify(content),
                id: item?.id,
                msgChannelType: item.msgChannelType,
                scene: item.scene,
                status: item.status,
                targetType: item.targetType,
                targetUrl: item.targetUrl,
            };
        }
    );
    const registrationAbsentedNotifications = map(
        cloneDeep(seniorSetting.registrationAbsentedNotifications),
        (item: any) => {
            const isSitemail =
                item.msgChannelType === MsgChannelType.APP_PUSH ||
                item.msgChannelType === MsgChannelType.SITEMAIL;
            const content = item.params.map((param: any) => {
                if (param.fieldType === 'file' && param.value && isSitemail) {
                    param.value = param.value.id;
                }
                if (
                    param.fieldType === 'file' &&
                    param.value &&
                    item.msgChannelType === MsgChannelType.EMAIL
                ) {
                    param.value = param.value.map((i: any) => i.id).join(',');
                }
                if (item.msgChannelType === MsgChannelType.SMS && !param.editable) {
                    param.value = '';
                }
                const newParam: any = {};
                forIn(param, (value, key) => {
                    newParam[snakeCase(key)] = value;
                });
                return newParam;
            });
            registrationAbsentedNotificationsScene = item.scene;
            return {
                content: JSON.stringify(content),
                id: item?.id,
                msgChannelType: item.msgChannelType,
                scene: item.scene,
                status: item.status,
                targetType: item.targetType,
                targetUrl: item.targetUrl,
            };
        }
    );
    const registrationStartedNotifications = map(
        cloneDeep(seniorSetting.registrationStartedNotifications),
        (item: any) => {
            const isSitemail =
                item.msgChannelType === MsgChannelType.APP_PUSH ||
                item.msgChannelType === MsgChannelType.SITEMAIL;
            const content = item.params.map((param: any) => {
                if (param.fieldType === 'file' && param.value && isSitemail) {
                    param.value = param.value.id;
                }
                if (
                    param.fieldType === 'file' &&
                    param.value &&
                    item.msgChannelType === MsgChannelType.EMAIL
                ) {
                    param.value = param.value.map((i: any) => i.id).join(',');
                }
                if (item.msgChannelType === MsgChannelType.SMS && !param.editable) {
                    param.value = '';
                }
                const newParam: any = {};
                forIn(param, (value, key) => {
                    newParam[snakeCase(key)] = value;
                });
                return newParam;
            });
            registrationStartedNotificationsScene = item.scene;
            return {
                content: JSON.stringify(content),
                id: item?.id,
                msgChannelType: item.msgChannelType,
                scene: item.scene,
                status: item.status,
                targetType: item.targetType,
                targetUrl: item.targetUrl,
            };
        }
    );
    const isNotifications =
        !businessNotifications.length &&
        !participatNotifications.length &&
        !registrationAbsentedNotifications.length &&
        !registrationStartedNotifications.length;
    const msgNotices = isNotifications
        ? []
        : [
              {
                  scene: businessNotificationsScene,
                  businessNotifications,
              },
              {
                  scene: participatNotificationsScene,
                  businessNotifications: participatNotifications,
              },
              {
                  scene: registrationAbsentedNotificationsScene,
                  businessNotifications: registrationAbsentedNotifications,
              },
              {
                  scene: registrationStartedNotificationsScene,
                  businessNotifications: registrationStartedNotifications,
              },
          ];

    const pageInfo = {
        info: mediaInfo.info,
        picture: {
            id: mediaInfo.pictures[0].id,
            url: mediaInfo.pictures[0].path,
        },
        sharePosterPicture: mediaInfo.poster?.length
            ? {
                  id: mediaInfo.poster[0].id,
                  url: mediaInfo.poster[0].path,
              }
            : undefined,
    };

    const ticketCancelRule = {
        cancelable: registrationInfo.cancelableRule?.cancelable,
        duration: registrationInfo.cancelableRule?.cancelableRule?.duration,
        timeUnit: registrationInfo.cancelableRule?.cancelableRule?.unit,
    };

    const needTicketPurchase = registrationInfo.type !== 'NOTIFICATION';

    const hasBasicCondition = registrationInfo.targetUserType === 'SOME';
    const reject = registrationInfo.excludeRule !== 'NONE';
    const isLimitSession = registrationInfo.limitSession === 'YES';

    const isDayTime = (timeType?: NewMallActivityRegistrationTimeType) => {
        return timeType === NewMallActivityRegistrationTimeType.DAY_TIME;
    };

    const { registrationStartTime: start, registrationEndTime: end } = registrationInfo;
    const ticketPurchaseCondition = needTicketPurchase
        ? {
              basicCondition: hasBasicCondition
                  ? {
                        birthdayStartDate: registrationInfo.targetUserBirthDay?.from,
                        birthdayEndDate: registrationInfo.targetUserBirthDay?.to,
                        gender: registrationInfo.targetUserSexuality,
                        memberCardLevelIds: registrationInfo.targetUserLevels,
                        nonPurchasableCrowdIds: registrationInfo.nonPurchasableCrowds?.length
                            ? registrationInfo.nonPurchasableCrowds.map((i: any) => i.id)
                            : undefined,
                        purchasableCrowdIds: registrationInfo.purchasableCrowds?.length
                            ? registrationInfo.purchasableCrowds.map((i: any) => i.id)
                            : undefined,
                    }
                  : undefined,
              customExtensionCondition: registrationInfo.customInfo?.length
                  ? JSON.stringify(params.registrationInfo.customInfo)
                  : undefined,
              reject,
              rejectActivityIds:
                  registrationInfo.excludeRule === 'SOME'
                      ? registrationInfo.excludeTypeActivity?.map((i: any) => i.id)
                      : [],
              roundDailyLimit: isLimitSession
                  ? registrationInfo.limitSessionCount?.dayCount
                  : undefined,
              roundTotalLimit: isLimitSession
                  ? registrationInfo.limitSessionCount?.activityCount
                  : undefined,
              ticketPurchaseTimeLimit: {
                  startTimeType: start?.timeType,
                  startTime: isDayTime(start?.timeType) ? start?.timeStr : undefined,
                  startTimeUnit: !isDayTime(start?.timeType) ? start?.timeUnit : undefined,
                  startTimeValue: !isDayTime(start?.timeType) ? start?.num : undefined,
                  endTimeType: end?.timeType,
                  endTime: isDayTime(end?.timeType) ? end?.timeStr : undefined,
                  endTimeUnit: !isDayTime(end?.timeType) ? end?.timeUnit : undefined,
                  endTimeValue: !isDayTime(end?.timeType) ? end?.num : undefined,
              },
          }
        : {};
    const hasReward = needTicketPurchase && rewardInfo?.reward?.length;
    const reward = rewardInfo?.reward?.[0];
    const ticketPurchaseReward = hasReward
        ? {
              rewardName: reward?.name,
              sendRewardTouchPoint: rewardInfo.rewardDistributionTime,
              rewardType: reward?.type?.checked,
              couponType:
                  reward?.type?.assetRewards?.[0]?.rewardValue?.[0]?.type ||
                  reward?.type?.assetRewards?.[0]?.rewardValue?.[0]?.couponType,
              couponId: reward?.type?.assetRewards?.[0]?.rewardValue?.[0]?.id,
          }
        : {};
    let ticketRounds: NewMallActivityRoundItem[] | undefined;
    if (
        [NewMallActivityType.Exhibition, NewMallActivityType.SignUp].includes(registrationInfo.type)
    ) {
        if (registrationInfo?.ticketType === NewMallActivityTicketType.Round) {
            ticketRounds = sortRoundsByStartDateTime(registrationInfo.activityRounds);
        } else if (registrationInfo?.ticketType === NewMallActivityTicketType.Activity) {
            ticketRounds = [
                {
                    enable: true,
                    endTime: dailyEndTime,
                    maxPurchaseTotal: registrationInfo.maxPurchaseTotal,
                    roundEndDate: endTime,
                    roundStartDate: startTime,
                    startTime: dailyStartTime,
                    tickets: registrationInfo.activitySingleRoundTickets,
                },
            ];
        }
    }

    const newParams = {
        basicInfo,
        messageNoticeRule,
        pageInfo,
        ticketCancelRule,
        ticketPurchaseCondition,
        ticketPurchaseReward,
        ticketRounds,
        msgNotices,
    };
    return newParams;
}

export const NewMallActivityUserCardLevelLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const { 'baseInfo.merchants': merchants } = data;
        // 业务上只允许选择同一分站下的门店
        const subsiteId = merchants && merchants[0] && merchants[0].subSiteId;
        if (!subsiteId) {
            return [];
        }
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`;
        config.apiPath = '/admin/store_card_levels';
        // 需要的是非分页数据
        const params = { subsiteId, page: 1, per_page: 500, status: 'ENABLE' };
        const res = await api.get<any>(params, config);
        return chain(res.result)
            .filter((card) => card.cardLevelId)
            .map((card) => ({ id: card.cardLevelId + '', name: card.name }))
            .unionBy('id')
            .value();
    },
};

export const MallActivityLimitCountTypeLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const { 'registrationInfo.session': session } = data;
        if (session === 'NO') {
            return [
                {
                    id: 'NO',
                    name: '无限制',
                },
                {
                    id: 'YES',
                    name: '活动期间限制人数',
                },
            ];
        } else if (session === 'YES') {
            return [
                {
                    id: 'NO',
                    name: '无限制',
                },
                {
                    id: 'YES',
                    name: '每场次限制人数',
                },
            ];
        } else {
            return [];
        }
    },
};

export const NewMallActivityTicketPurchaseRecordsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`;
        if (data.recordId) {
            config.apiPath = `/admin/v1/mall_activity/ticket_purchase_records/${data.recordId}`;
        }
        if (data.orderStatus === 'ALL') {
            delete data.orderStatus;
        }
        if (!isEmpty(data.dateRange)) {
            data.orderStartTime = data.dateRange.start + ' 00:00:00';
            data.orderEndTime = data.dateRange.end + ' 23:59:59';
        }
        if (!isEmpty(data.joinDate)) {
            data.joinStartDate = data.joinDate.start;
            data.joinEndDate = data.joinDate.end;
        }
        delete data.joinDate;
        delete data.dateRange;

        return await api.get(data, config).then((res: any) => {
            if (res?.page && res.result?.length) {
                res.result = res.result.map((item: any) => {
                    let orderStatus = item.orderStatus;
                    if (orderStatusMap[item.orderStatus]) {
                        orderStatus = orderStatusMap[item.orderStatus];
                    }
                    item.orderStatusStr = orderStatus;
                    let joinStart = moment(item.joinStartDate);
                    let joinEnd = moment(item.joinEndDate);
                    const joinEndTime = item.joinEndTime ? `~${item.joinEndTime}` : '';
                    if (joinEnd > joinStart) {
                        item.joinTime = `${item.joinStartDate}~${item.joinEndDate}`;
                    } else {
                        item.joinTime = `${item.joinStartDate} ${item.joinStartTime}${joinEndTime}`;
                    }
                    return item;
                });
            }

            if (data.recordId) {
                let orderStatusStr = res.status;
                if (orderStatusMap[res.status]) {
                    orderStatusStr = orderStatusMap[res.status];
                }
                let participateDate =
                    res.mallActivityInfo?.ticketType === NewMallActivityTicketType.Activity
                        ? `${res.ticketInfo.roundStartDate}至${res.ticketInfo.roundEndDate}`
                        : `${res.ticketInfo.roundStartDate} ${res.ticketInfo.roundStartTime}-${res.ticketInfo.roundEndTime}`;
                res.orderStatusStr = orderStatusStr;
                res.participateDate = participateDate;
            }
            return res;
        });
    },
};

export const NewMallActivitySettingLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        return await api.get(data, config).then((res: any) => {
            res.displayMallActivityStatus = res.displayMallActivityStatus ? 'true' : 'false';
            res.displayMallActivityStock = res.displayMallActivityStock ? 'true' : 'false';
            return res;
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        return await api.put(params, config);
    },
};
