import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { find, isEmpty } from 'lodash';
import moment from 'moment';

const api = services.api;

export const StoredValueCardActivityLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = `/admin/stored_value_card_activities`;
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/stored_value_card_activities/${id}`;
        } else {
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                const subsites: any = find(res.activityVo.normalConditions, {
                    normalConditionType: 'SUB_SITE',
                });
                res.id = res.activityVo.id;
                res.baseInfo = {
                    name: res.activityVo.name,
                    dateRange: {
                        start: moment(res.activityVo.startTime).format('YYYY-MM-DD'),
                        end: moment(res.activityVo.endTime).format('YYYY-MM-DD'),
                    },
                    subsites: subsites.includeIds,
                };
                res.useRulesInfo = {
                    products: res.storedValueCardActivity.products.map((product: any) => {
                        return {
                            ...product,
                            effectiveStartTime: product.effectiveStartTime
                                ? moment(product.effectiveStartTime).format('YYYY-MM-DD')
                                : undefined,
                            effectiveEndTime: product.effectiveEndTime
                                ? moment(product.effectiveEndTime).format('YYYY-MM-DD')
                                : undefined,
                            eventId: product.id, //保存的数据ID
                            id: Number(product.productId), //选择的类别id
                            styles: product.styles.map((s: any) => {
                                s.price =
                                    product.type === 'E_RECHARGE_STORED_VALUE_CARD'
                                        ? s.salesPrice
                                        : s.price;
                                return s;
                            }),
                            isHide: true,
                        };
                    }),
                };
            } else {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        return item;
                    });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/stored_value_card_activities';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = `/admin/stored_value_card_activities/${params.id}`;
        const newParams: any = dataParser(params);
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    newParams.activityVo = {
        eventType: 'STORED_VALUE_CARD',
        name: params.baseInfo.name,
        startTime: params.baseInfo.dateRange.start + ' 00:00:00',
        endTime: params.baseInfo.dateRange.end + ' 23:59:59',
        normalConditions: [
            {
                all: false,
                normalConditionType: 'SUB_SITE',
                includeIds: params.baseInfo.subsites,
            },
        ],
    };
    newParams.storedValueCardActivity = {
        products: params.useRulesInfo.products.map((product: any) => {
            const newStyles: any = product.styles.filter(
                (condition: any) => condition.status === true
            );
            return {
                ...product,
                id: product.eventId, //编辑将之前对应的数据id传回去
                effectiveStartTime: product.effectiveStartTime
                    ? product.effectiveStartTime + ' 00:00:00'
                    : undefined,
                effectiveEndTime: product.effectiveEndTime
                    ? product.effectiveEndTime + ' 23:59:59'
                    : undefined,
                styles: newStyles,
            };
        }),
    };
    return newParams;
}
