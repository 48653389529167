import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const FrontInfoLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/front_info/1';
        return await api.get(data, config).then((res: any) => {
            const {
                homeLogoFileUrl,
                homeLogoId,
                authorizeLogoFileUrl,
                authorizeLogoId,
                authorizeImgFileUrl,
                authorizeImgId,
                picDomain,
                agreementBackImgId,
                agreementBackImgFileUrl,
            } = res;
            res.homeLogo = [{ id: homeLogoId, path: `${picDomain}${homeLogoFileUrl}` }];
            res.authorizeLogo = [
                { id: authorizeLogoId, path: `${picDomain}${authorizeLogoFileUrl}` },
            ];
            res.authorizeImg = [{ id: authorizeImgId, path: `${picDomain}${authorizeImgFileUrl}` }];
            if (agreementBackImgFileUrl) {
                res.agreementBackImg = [
                    { id: agreementBackImgId, path: `${picDomain}${agreementBackImgFileUrl}` },
                ];
            } else {
                res.agreementBackImg = [];
            }
            return res;
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiPath = '/admin/front_info/1';
        const newParams: any = {
            homeLogoId: params.homeLogo[0].id,
            authorizeLogoId: params.authorizeLogo[0].id,
            authorizeImgId: params.authorizeImg[0].id,
            name: params.name,
        };
        if (params.agreementBackImg.length) {
            newParams.agreementBackImgId = params.agreementBackImg[0].id;
        }
        return await api.put(newParams, config);
    },
};
