import React, { Component, CSSProperties } from 'react';
import { Button, message as AntMessage, Modal, Pagination } from 'antd';
import { services } from '@comall-backend-builder/core';
import { cloneDeep } from 'lodash';
import { WechatMpnewsarticle } from '../../components/wechant-content';
import './index.less';

interface Props {
    appId: string;
    visible: boolean;
    onConfrim: (value: WechatMpnewsarticle) => void;
    onCancel: () => void;
    editInfo?: WechatMpnewsarticle;
}

interface States {
    datasource: Array<WechatMpnewsarticle>;
    total: number;
    current: number;
    pagination: {
        page: number;
        perPage: number;
    };
    /**
     * 选择的图文
     */
    chooseWechatMpnewsarticle?: WechatMpnewsarticle;
}
const { api } = services;
export class WechatOfficialAutoReplySettingPageMpnewsarticleModal extends Component<Props, States> {
    constructor(props: any) {
        super(props);

        this.state = {
            datasource: [],
            total: 0,
            current: 0,
            pagination: {
                page: 1,
                perPage: 6,
            },
            chooseWechatMpnewsarticle: undefined,
        };
    }

    componentDidMount() {
        this.onSearch();
        //编辑回显
        const { editInfo } = this.props;
        if (editInfo) {
            this.setState({
                chooseWechatMpnewsarticle: cloneDeep(editInfo),
            });
        }
    }

    onSearch = async () => {
        const { appId } = this.props;
        const { pagination } = this.state;
        try {
            let res: any = await api.get(
                {
                    ...pagination,
                    appId: appId,
                    type: 'NEWS',
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                    apiPath: '/admin/wechat_official_account_materials',
                }
            );
            if (res && res.result) {
                this.setState({
                    datasource: res.result,
                    total: res.totalNum,
                    current: res.page,
                });
            }
        } catch (error) {
            throw error;
        }
    };

    pageChange = (pageVal: any) => {
        this.setState({ pagination: { ...pageVal } }, () => {
            this.onSearch();
        });
    };

    OK = () => {
        const { onConfrim } = this.props;
        const { chooseWechatMpnewsarticle } = this.state;
        if (!chooseWechatMpnewsarticle) {
            AntMessage.warning(services.language.getText('qxztw'));
            return false;
        }
        onConfrim(chooseWechatMpnewsarticle);
    };

    onChangeSource = (chooseWechatMpnewsarticle: WechatMpnewsarticle) => {
        this.setState({
            chooseWechatMpnewsarticle: chooseWechatMpnewsarticle,
        });
    };

    onRefresh = () => {
        this.setState(
            {
                pagination: {
                    page: 1,
                    perPage: 6,
                },
            },
            () => {
                this.onSearch();
            }
        );
    };

    renderList = () => {
        const { datasource, pagination, total, current, chooseWechatMpnewsarticle } = this.state;
        const paginationCongid = {
            pageSize: pagination.perPage,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['6', '12', '18'],
            total,
            current,
            showTotal(total: number) {
                return services.interpolate(services.language.getText('total'), {
                    total,
                });
            },
            onChange: (page: number | undefined, perPage: number | undefined) => {
                this.pageChange({ page, perPage });
            },
            onShowSizeChange: (page: number | undefined, perPage: number | undefined) => {
                this.pageChange({ page, perPage });
            },
        };

        const checkedImage = require('../images/icon-checked.png');

        return (
            <div className="wechat-official-auto-reply-setting-page-mpnewsarticle-modal">
                <div className="find-filter">
                    <Button
                        className="find-filter-button"
                        htmlType="reset"
                        onClick={this.onRefresh}
                    >
                        {services.language.getText('refresh')}
                    </Button>
                </div>
                {datasource && datasource.length > 0 ? (
                    <div className="datasource">
                        <div className="datasource-list">
                            {datasource &&
                                datasource.map((source, index) => {
                                    let style: CSSProperties = {
                                        border: '1px solid #eee',
                                    };
                                    if (
                                        chooseWechatMpnewsarticle &&
                                        chooseWechatMpnewsarticle.articleId === source.articleId
                                    ) {
                                        style = {
                                            border: '1px solid #1990FF',
                                            background: '#DDEFFF',
                                        };
                                    }
                                    return (
                                        <div
                                            className="datasource-item"
                                            style={style}
                                            onClick={this.onChangeSource.bind(this, source)}
                                            key={index}
                                        >
                                            <div>
                                                <img
                                                    className="datasource-item-image"
                                                    alt=""
                                                    src={source.thumbUrl}
                                                />
                                                {chooseWechatMpnewsarticle &&
                                                    chooseWechatMpnewsarticle.articleId ===
                                                        source.articleId && (
                                                        <img
                                                            className="datasource-item-image-checked"
                                                            alt=""
                                                            src={checkedImage}
                                                        />
                                                    )}
                                            </div>
                                            <div className="datasource-item-info">
                                                <div className="datasource-item-title">
                                                    {source.title}
                                                </div>
                                                <div className="datasource-item-text">
                                                    {source.digest}
                                                </div>
                                                <div className="datasource-item-time">
                                                    {services.language.getText('gxsj_1')}
                                                    &nbsp;&nbsp;
                                                    <span className="datasource-item-time-bold">
                                                        {source.updateTime}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                        <div className="datasource-pagination">
                            <Pagination {...paginationCongid} />
                        </div>
                    </div>
                ) : (
                    <div className="empty-list">{services.language.getText('notData')}</div>
                )}
            </div>
        );
    };

    render() {
        const { visible, onCancel } = this.props;
        return (
            <Modal
                visible={visible}
                width={1000}
                title={services.language.getText('xztw')}
                onCancel={onCancel}
                onOk={this.OK}
            >
                <div>{this.renderList()}</div>
            </Modal>
        );
    }
}
