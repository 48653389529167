import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { api, navigation } from '@comall-backend-builder/core/lib/services';

export const SettingSubsiteMessageFloor: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const url = navigation.getHistory().location.pathname;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/subsite_floor';
        const { id } = data;
        if (url.includes('/subsite/view-floor/')) {
            data.subsiteId = id;
            delete data.id;
        } else {
            config.apiPath = `/admin/subsite_floor/${id}`;
        }
        return await api.get(data, config).then((result: any) => {
            result.status = result.status + '';
            return result;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        params.subsiteId = params.id;
        delete params.id;
        return await api.post(params, config);
    },
};
