import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { ExportFilterType } from '../../../containers/export-data-button-by-filter';
import get from 'lodash/get';

export const config: Config = {
    entities: {
        qrcodeRuleEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/qrcodeRule',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsryhqlbmc>>' },
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<cjewm>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [{ required: true, whitespace: true }],
                            controlConfig: {
                                placeholder: '<<qtxhdmc>>',
                                style: {
                                    width: 450,
                                },
                                maxLength: 20,
                            },
                        },
                        type: {
                            type: 'string.options.radio',
                            displayName: '<<ewmlx>>',
                            defaultValue: 'SUBSITE',
                            options: [
                                { id: 'SUBSITE', name: '<<mdewm>>' },
                                { id: 'MERCHANT', name: '<<zjewm>>' },
                            ],
                            rules: [{ required: true }],
                            extra: '<<mdewmdmdcs>>',
                        },
                        subsiteIds: {
                            type: 'array.options.autoComplete',
                            displayName: '<<otherSelectSubsite>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [{ required: true }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                            },
                            extra: '<<subsiteIdsExtra>>',
                        },
                        subSiteMerchantIds: {
                            type: 'array.shop.selector',
                            displayName: '<<selectSubsites>>',
                            rules: [{ required: true, type: 'array' }],
                            controlConfig: {
                                fieldParams: {
                                    isContainsSelfMerchant: false,
                                },
                            },
                            extra: '<<merchantIdsExtra>>',
                        },
                        linkType: {
                            type: 'string.options.select',
                            displayName: '<<smldy>>',
                            defaultValue: 'HOME',
                            options: [
                                {
                                    id: 'HOME',
                                    name: '<<sy_3>>',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                style: { width: 160 },
                            },
                        },
                        channel: {
                            type: 'string.options.select',
                            displayName: '<<tgqd>>',
                            defaultValue: 'OFFLINE',
                            options: [
                                {
                                    id: 'OFFLINE',
                                    name: '<<xxdt>>',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                style: { width: 160 },
                            },
                        },
                    },
                },
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                type: {
                    type: 'string',
                    displayName: '<<ewmlx>>',
                },
                linkType: {
                    type: 'string',
                    displayName: '<<smldym>>',
                },
                channel: {
                    type: 'string',
                    displayName: '<<tgqd>>',
                },
                urlZipUrl: {
                    type: 'string',
                    displayName: '<<ewmxzlj>>',
                },
            },
        },
    },
    components: {
        qrcodeRuleView: {
            component: 'Viewport',
            entity: 'qrcodeRuleEntity',
        },
        qrcodeRulePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'qrcodeRuleFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<cjewm>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/qrcode-rule/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'qrcodeRuleTable' },
            ],
        },
        qrcodeRuleFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
            ],
        },
        qrcodeRuleTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'type',
                    width: '200px',
                },
                {
                    property: 'linkType',
                    width: '200px',
                },
                {
                    property: 'channel',
                    width: '200px',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 260,
                items: [
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/menu-list/market/qrcode-rule/edit/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'qrcodeRuleDownloadButton',
                    },
                    {
                        type: 'component',
                        component: 'ExportDataButtonByFilter',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                            apiPath: '/admin/qr_code_rules/{{row.id}}/export.xls',
                            text: '<<dcsj_1>>',
                            filters: [ExportFilterType.TIME],
                        },
                    },
                ],
            },
        },
        qrcodeRuleAddPage: {
            component: 'Card',
            content: { component: 'qrcodeRuleAddForm' },
        },
        qrcodeRuleAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'qrcodeRuleEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.type' },
                {
                    property: 'baseInfo.subsiteIds',
                    visible: (values: any) => get(values, 'baseInfo.type') === 'SUBSITE',
                },
                {
                    property: 'baseInfo.subSiteMerchantIds',
                    visible: (values: any) => get(values, 'baseInfo.type') === 'MERCHANT',
                },
                { property: 'baseInfo.linkType' },
                { property: 'baseInfo.channel' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                let flag = true;
                if (entity.baseInfo.type === 'MERCHANT') {
                    if (entity.baseInfo.subSiteMerchantIds.length > 0) {
                        entity.baseInfo.subSiteMerchantIds.forEach((item: any) => {
                            if (item.merchantId === null) {
                                flag = false;
                            }
                        });
                    }
                }
                if (!flag) {
                    AntMessage.error(services.language.getText('pleaseSelectMerchant'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        qrcodeRuleEditPage: {
            component: 'Card',
            content: { component: 'qrcodeRuleEditForm' },
        },
        qrcodeRuleEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'qrcodeRuleEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'baseInfo.name',
                    controlConfig: {
                        disabled: true,
                        style: {
                            width: 450,
                        },
                    },
                },
                {
                    property: 'baseInfo.type',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'baseInfo.subsiteIds',
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                    visible: (values: any) => get(values, 'baseInfo.type') === 'SUBSITE',
                },
                {
                    property: 'baseInfo.subSiteMerchantIds',
                    visible: (values: any) => get(values, 'baseInfo.type') === 'MERCHANT',
                },
                {
                    property: 'baseInfo.linkType',
                    controlConfig: {
                        disabled: true,
                        style: { width: 160 },
                    },
                },
                {
                    property: 'baseInfo.channel',
                    controlConfig: {
                        disabled: true,
                        style: { width: 160 },
                    },
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                let flag = true;
                if (entity.baseInfo.type === 'MERCHANT') {
                    if (entity.baseInfo.subSiteMerchantIds.length > 0) {
                        entity.baseInfo.subSiteMerchantIds.forEach((item: any) => {
                            if (item.merchantId === null) {
                                flag = false;
                            }
                        });
                    }
                }
                if (!flag) {
                    AntMessage.error(services.language.getText('pleaseSelectMerchant'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/qrcode-rule',
                    component: 'qrcodeRuleView',
                    breadcrumbName: '<<ewmtg>>',
                    privilege: {
                        path: 'qrCodeRule',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'qrcodeRuleAddPage',
                            breadcrumbName: '<<cjewm>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'qrcodeRuleEditPage',
                            breadcrumbName: '<<bjewm>>',
                        },
                        { path: '/', component: 'qrcodeRulePage' },
                    ],
                },
            ],
        },
    ],
};
