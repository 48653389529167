import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { Button, Modal } from 'antd';
import { get, defaults, map, set } from 'lodash';
import { ComponentsManager, EntitiesManager, actions } from '@comall-backend-builder/core';
import { FormComponentProps } from 'antd/lib/form';

let Entity: any = {};
let entity: any = {};
const componentId = 'CardLevelBenefitForm';

export interface Props extends FormComponentProps {
    onSubmit: (e: any, fields: any) => void;
    onInit: (fields?: any) => void;
    getForm: () => any;
    onChangeBenefit: (data: any) => void;
    value: any;
}

class cardLevelBenefitModal extends PureComponent<Props, any> {
    constructor(props: any) {
        super(props);
        Entity = EntitiesManager.get('cardLevelBenefit');
        entity = new Entity({});
        this.state = {
            visible: false,
            fields: undefined,
        };
    }

    private onSubmit = (event: any, fields: any) => {
        event.stopPropagation();
        const { getForm, onChangeBenefit } = this.props;
        const form = getForm();
        form.validateFields(['benefitInfos', 'description'], { force: true }).then(
            (entityFields: any) => {
                if (entityFields) {
                    const benefitInfos = get(entityFields, 'benefitInfos', null);
                    const description: any = get(entityFields, 'description', null);
                    onChangeBenefit({
                        benefitInfos: benefitInfos || [],
                        description: description || '',
                    });
                    this.toggleModal();
                }
            }
        );

        return;
    };

    renderModal = () => {
        const { wrappedComponentRef } = this.props;
        let formProps = {
            entity,
            wrappedComponentRef: wrappedComponentRef,
            componentId: componentId,
            onSubmit: this.onSubmit,
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'benefitInfos',
                    controlConfig: {
                        editSequence: true,
                    },
                },
                {
                    property: 'description',
                },
            ],
            submit: false,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        onClick: this.onSubmit,
                        text: '确定',
                        type: 'primary',
                        style: { marginLeft: 10 },
                    },
                    {
                        htmlType: 'button',
                        onClick: this.toggleModal,
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
        };
        return createElement(ComponentsManager.get('CreateForm'), formProps);
    };

    private toggleModal = (initData?: any) => {
        const { visible } = this.state;
        if (initData) {
            this.props.onInit(initData);
        }
        this.setState({
            visible: !visible,
            fields: undefined,
        });
    };

    render() {
        const { value } = this.props;
        const { visible } = this.state;
        const modalContent = this.renderModal();
        const modalProps = {
            className: 'card-level-benefit-modal',
            width: 1000,
            title: '设置会员权益',
            visible: visible,
            footer: null,
            onCancel: this.toggleModal,
        };
        return (
            <div style={{ display: 'inline-block' }}>
                {value ? (
                    <span>
                        已设置会员权益
                        <Button type="link" onClick={this.toggleModal.bind(this, value)}>
                            修改
                        </Button>
                    </span>
                ) : (
                    <Button type="link" onClick={this.toggleModal}>
                        请设置会员权益
                    </Button>
                )}
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onInit, onSubmitStart } = props;
    let form: any;

    const handleInit = (fields?: any) => {
        let {
            id: entityId,
            metainfo: { properties },
            fields: fieldsFromProps,
        } = entity;
        const loaded = !!fieldsFromProps;

        if (!fields) {
            let fields: { [key: string]: any } = {};
            map(fieldsFromProps, (field) => {
                let { property } = field;

                // 从实体中获取属性配置，实体中的 path 为 style.properties.color，配置中 path 为 style.color
                let entityProperty = get(properties, property.replace('.', '.properties.'));
                if (entityProperty) {
                    set(fields, property, entityProperty.defaultValue || undefined);
                } else {
                    throw new Error(`Property ${property} not found in Entity ${entityId}`);
                }
            });

            _dispatch(
                actions.formInitAction(componentId, {
                    type: 'CreateForm',
                    entityId: entity.id,
                    fields,
                })
            );
        } else {
            setTimeout(() => {
                _dispatch(
                    actions.formInitAction(componentId, {
                        type: 'CreateForm',
                        entityId: entity.id,
                        fields: {
                            ...fields,
                        },
                        loaded,
                    })
                );
            });
        }
    };

    return defaults(
        {
            onSubmit,
            onInit,
            onSubmitStart,
        },
        {
            onInit: handleInit,
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
        }
    );
}

export const CardLevelBenefitModal = connect(null, mapDispatchToProps, null, {
    forwardRef: true,
})(cardLevelBenefitModal);
