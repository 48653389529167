import { forIn } from 'lodash';

import { builder } from '@comall-backend-builder/core';

import { errorHandle } from './services/error-handle';
import * as myConfigs from './config';
import * as myComponents from './components';
import * as myContainers from './containers';
import myTypes from './types';
import myLoaders from './loaders';
import { refreshToken } from './services/refresh-token';
import './services/api';
import './styles/index.less';
refreshToken.watch();

// Customized component example
forIn(myContainers, (component, name) => {
    builder.registerComponent(name, component);
});
// 注册本地组件
forIn(myComponents, (component, name) => {
    builder.registerComponent(name, component);
});
forIn(myConfigs, (config) => {
    builder.registerConfig(config);
});

myTypes.forEach((type, name) => {
    builder.registerType(name, type);
});
myLoaders.forEach((loader, path) => {
    builder.registerLoader(path, loader);
});

builder.replaceErrorHandle(errorHandle);
