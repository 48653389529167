import React, { PureComponent } from 'react';
import { Button, InputNumber, DatePicker } from 'antd';
import moment from 'moment';
import { map } from 'lodash';

import { TimeRangePickerPlus } from '..';

import './index.less';
import { services } from '@comall-backend-builder/core';

interface RegistrationTime {
    startDate?: string;
    startTime?: string;
    endTime?: string;
    quantity?: number;
}

export interface RegistrationTimePickerProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 格式化日期
     */
    dateFormat: string;
    /**
     * 格式化时间
     */
    timeFormat: string;
    /**
     * 当前值
     */
    value: RegistrationTime[];
    /**
     * 禁用状态
     */
    disabled: boolean;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: RegistrationTime[], name: string) => void;
}
export class RegistrationTimePicker extends PureComponent<RegistrationTimePickerProps> {
    static defaultProps = {
        dateFormat: 'YYYY-MM-DD',
        timeFormat: 'HH:mm:ss',
        value: [],
    };

    componentDidMount() {
        const { value } = this.props;

        if (value.length === 0) {
            this.handleAdd();
        }
    }

    disabledDate(current: moment.Moment | null) {
        return !!(current && current < moment().subtract(1, 'days'));
    }

    handleDateChange = (date: any, index: number) => {
        const { name, value, dateFormat, onChange } = this.props;
        const newValue = value.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    startDate: moment(date || undefined).format(dateFormat),
                };
            }
            return item;
        });
        onChange(newValue, name);
    };

    handleTimeChange = (time: any, index: number) => {
        const { name, value, onChange } = this.props;
        const newValue = value.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    startTime: time.start,
                    endTime: time.end,
                };
            }
            return item;
        });

        onChange(newValue, name);
    };

    hanldeQuantityChange = (quantity: number = 1, index: number) => {
        const { name, value, onChange } = this.props;
        const newValue = value.map((item, i) => {
            if (i === index) {
                return {
                    ...item,
                    quantity,
                };
            }
            return item;
        });
        onChange(newValue, name);
    };

    handleAdd = () => {
        const { name, value, onChange } = this.props;
        const newValue = [
            ...value,
            {
                startDate: undefined,
                startTime: undefined,
                endTime: undefined,
                quantity: undefined,
            },
        ];
        onChange && onChange(newValue, name);
    };

    handleDelete = (index: number) => {
        const { name, value, onChange } = this.props;
        const newValue = value.filter((_, i) => i !== index);
        onChange && onChange(newValue, name);
    };

    renderItem = (item: RegistrationTime, index: number) => {
        const { value, dateFormat, timeFormat, disabled } = this.props;
        const { startDate, startTime, endTime, quantity } = item;
        const complete = !!(startDate && startTime && endTime && quantity);
        const onlyFirst = index === 0 && value.length === 1;
        const last = value.length - 1 === index;

        const datePicker = (
            <DatePicker
                style={{ width: 125 }}
                className="mrp-10"
                placeholder="请选择日期"
                showToday={false}
                allowClear={false}
                format={dateFormat}
                disabledDate={this.disabledDate}
                value={startDate ? moment(startDate) : undefined}
                disabled={disabled}
                onChange={(value) => this.handleDateChange(value, index)}
            />
        );

        const timeRangePicker = (
            <TimeRangePickerPlus
                format={timeFormat}
                placeholder={[
                    services.language.getText('qxzsj'),
                    services.language.getText('qxzsj'),
                ]}
                name="start"
                value={{
                    start: startTime,
                    end: endTime,
                }}
                style={{ width: 120 }}
                allowClear={false}
                disabled={disabled}
                onChange={(value) => this.handleTimeChange(value, index)}
            />
        );

        const quantityInput = (
            <InputNumber
                className="mrp-10"
                placeholder={services.language.getText('inputPlease')}
                style={{ width: '100px' }}
                value={quantity}
                disabled={disabled}
                min={1}
                max={99999999999}
                step={1}
                onChange={(value) =>
                    this.hanldeQuantityChange(value ? Math.floor(value) : undefined, index)
                }
            />
        );

        const deleteButton = (
            <Button type="link" onClick={() => this.handleDelete(index)}>
                {services.language.getText('common.delete')}
            </Button>
        );

        const addButton = (
            <Button type="default" className="mrp-10" onClick={this.handleAdd}>
                {services.language.getText('tjcc')}
            </Button>
        );

        return (
            <div className="registration-time-picker-line" key={index}>
                <div className="registration-time-picker-content">
                    <div className="registration-time-picker-line">
                        <span>
                            {datePicker}
                            {timeRangePicker}
                            <span style={{ marginLeft: '10px' }}>
                                场次报名名额：{quantityInput}
                            </span>
                            {!disabled && <span>{!onlyFirst && deleteButton}</span>}
                        </span>
                    </div>
                    <div className="registration-time-picker-line">
                        {!disabled && <span>{complete && last && addButton}</span>}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { value } = this.props;

        return (
            <div className="registration-time-picker-components">
                <div>{map(value, this.renderItem)}</div>
            </div>
        );
    }
}
