import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import get from 'lodash/get';
import { LinkTypeSelectorBussinessType } from '../../../types/mode/object/subsite-link-type-selector/subsite-link-type-selector';
import { subsiteLinkTypeSelectorValidate } from '../../../types/format';

export const config: Config = {
    entities: {
        EfutureCouponSelector: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
            apiPath: '/admin/efuture_coupon_rules/coupon_types',
            filters: {
                code: {
                    type: 'string',
                    displayName: '<<qbm>>',
                    controlConfig: {
                        placeholder: '<<qsrqbm>>',
                        style: {
                            width: 200,
                        },
                    },
                },
                couponType: {
                    type: 'string.options.select',
                    displayName: '<<qlx>>',
                    options: [
                        { id: 'EFUTURE_CASH', name: '<<efutureCash>>' },
                        { id: 'EFUTURE_BALANCE', name: '<<efutureBalance>>' },
                    ],
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: {
                            width: 150,
                        },
                    },
                },
            },
            properties: {
                typeName: {
                    type: 'string',
                    displayName: '<<qlx>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<qmc>>',
                },
                code: {
                    type: 'string',
                    displayName: '<<qbm>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<symd>>',
                },
            },
        },
        EfutureCouponRulesEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
            apiPath: '/loader/admin/efuture_coupon_rules',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<couponName>>',
                    controlConfig: {
                        placeholder: '<<qsryhqmc>>',
                        style: {
                            width: 200,
                        },
                    },
                },
                code: {
                    type: 'string',
                    displayName: '<<couponBaCode>>',
                    controlConfig: {
                        placeholder: '<<qsryhqbm>>',
                        style: {
                            width: 200,
                        },
                    },
                },
                couponType: {
                    type: 'string.options.select',
                    displayName: '<<couponType>>',
                    options: [
                        { id: 'EFUTURE_CASH', name: '<<efutureCash>>' },
                        { id: 'EFUTURE_BALANCE', name: '<<efutureBalance>>' },
                    ],
                    controlConfig: {
                        style: {
                            width: 150,
                        },
                    },
                },
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<executeStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                    controlConfig: { style: { width: 150 } },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<couponTime>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        externalCoupon: {
                            type: 'object.efuture.coupon.choose',
                            displayName: '<<components.ComponentSelector.coupon>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                showChoose: true,
                            },
                        },
                        stock: {
                            type: 'object.couponRuleStock',
                            displayName: '<<stock>>',
                            rules: [{ required: true, message: '<<stockRequired>>' }],
                        },
                    },
                },
                productInfo: {
                    type: 'object.subForm',
                    displayName: '<<fqmd>>',
                    properties: {
                        subsite: {
                            type: 'array.subsites.selector',
                            displayName: '<<fqmd>>',
                            defaultValue: [],
                            rules: [{ required: true }],
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],

                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.productInfo?.subsite?.length &&
                                        row.productInfo.subsite
                                            .map((item: any) => item.id)
                                            .join(',');
                                    return { subsiteIds };
                                },
                            },
                        },
                    },
                },
                useRuleInfo: {
                    type: 'object.subForm',
                    displayName: '<<lqgz>>',
                    properties: {
                        receivePeriod: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<lqsj_1>>',
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                            },
                            extra: '<<jtgyhqzjsfklq>>',
                        },
                        receiveLimit: {
                            type: 'string.options.radio.plus',
                            displayName: '<<receiveLimit>>',
                            defaultValue: 'NO_LIMIT',
                            rules: [{ required: true }],
                            controlConfig: {
                                mode: 'horizontal',
                                setOptions: [
                                    {
                                        value: 'NO_LIMIT',
                                        name: '<<noLimit>>',
                                    },
                                    {
                                        value: 'MEMBER_LIMIT',
                                        name: '<<memberLimit>>',
                                        tip: {
                                            text: '<<memberLimitText>>',
                                        },
                                    },
                                    {
                                        value: 'TIME_LIMIT',
                                        name: '<<timeLimit>>',
                                        tip: {
                                            text: '<<timeLimitTip>>',
                                        },
                                    },
                                ],
                            },
                        },
                        memberLevels: {
                            type: 'array.options.autoComplete.properties.coupon',
                            displayName: '<<memberLevels>>',
                            options: [],
                            controlConfig: {
                                pageType: 'EfutureCouponRuleMemberLevel',
                                placeholder: '<<selectPlease>>',
                                style: { width: 450 },
                            },
                            extra: '<<memberLevelsTip>>',
                        },
                        registerMemberSource: {
                            type: 'string.options.radio.plus',
                            displayName: '<<registerMemberSource>>',
                            defaultValue: 'ONLINE',
                            rules: [{ required: true }],
                            controlConfig: {
                                setOptions: [
                                    {
                                        value: 'ONLINE',
                                        name: '<<registOnline>>',
                                        tip: {
                                            text: '<<registOnlineTip>>',
                                        },
                                    },
                                    {
                                        value: 'OFFLINE',
                                        name: '<<registOffline>>',
                                        tip: {
                                            text: '<<registTip>>',
                                        },
                                    },
                                ],
                            },
                        },
                        useLimitationsMode: {
                            type: 'string.options.radio',
                            displayName: '<<useLimitationsMode>>',
                            defaultValue: 'DATE',
                            options: [
                                {
                                    id: 'DATE',
                                    name: '<<useLimitationsModeDate>>',
                                },
                                {
                                    id: 'DAY',
                                    name: '<<useLimitationsModeDay>>',
                                },
                            ],
                            rules: [{ required: true }],
                        },
                        useLimitationsDate: {
                            type: 'object.dateRangePlus',
                            displayName: '<<useLimitationsDate>>',
                            rules: [{ required: true, message: '<<selectUseLimitationsDate>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                            },
                            extra: '<<useLimitationsDateExtra>>',
                        },
                        useLimitationsDay: {
                            type: 'number.tip',
                            displayName: '<<registDay>>',
                            rules: [
                                {
                                    type: 'number',
                                    required: true,
                                    message: '<<registDayMessage>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && (!reg.test(value) || value > 999)) {
                                            return false;
                                        }
                                        if (!value || value === 0) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '',
                                style: {
                                    width: 125,
                                },
                                addonbefore: '',
                                addonafter: '<<day>>',
                                min: 1,
                                max: 999,
                                tip: '',
                            },
                            extra: '<<registDayExtra>>',
                        },
                        receiveQuantityLimitType: {
                            type: 'string.options.radio',
                            displayName: '<<receiveQuantityLimitType>>',
                            defaultValue: 'NO_LIMIT',
                            options: [
                                {
                                    id: 'NO_LIMIT',
                                    name: '<<limitNumberNoLimit>>',
                                },
                                {
                                    id: 'EVERY_MEMBER_LIMIT',
                                    name: '<<everyMemberLimit>>',
                                },
                                {
                                    id: 'EVERY_MEMBER_DAILY_LIMIT',
                                    name: '<<everyMemberDailyLimit>>',
                                },
                            ],
                            rules: [{ required: true }],
                        },
                        target: {
                            type: 'object.subsiteLinkTypeSelector',
                            displayName: '<<target>>',
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        memberLimitQuantity: {
                            type: 'number',
                            displayName: '<<limitQuantity>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<inputThanZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^([1-9]\d*)$/;
                                        if (value && !reg.test(value)) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            displayConfig: { addonafter: '<<zhang>>' },
                        },
                    },
                },
                pictureInfo: {
                    type: 'object.subForm',
                    displayName: '<<qtp>>',
                    properties: {
                        couponPicture: {
                            displayName: '<<tppz>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<pzhzszwdyhqb>>',
                        },
                    },
                },
                info: {
                    type: 'object.couponInfo',
                    displayName: '<<couponInfo2>>',
                },
                typeName: {
                    type: 'string',
                    displayName: '<<qlx>>',
                },
                couponValue: {
                    type: 'string',
                    displayName: '<<mz_1>>',
                },
                code: {
                    type: 'string',
                    displayName: '<<qbm>>',
                },
                // startTime: {
                //     type: 'string.date',
                //     displayName: '<<couponStartTime>>',
                // },
                // endTime: {
                //     type: 'string.date',
                //     displayName: '<<couponEndTime>>',
                // },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<executeStatus>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
            },
        },
    },
    components: {
        EfutureCouponRulesView: {
            component: 'Viewport',
            entity: 'EfutureCouponRulesEntity',
        },
        EfutureCouponRulesPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'EfutureCouponRulesFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addCoupon>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/coupon/efuture-coupon-rules/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'EfutureCouponRulesTable' },
            ],
        },
        EfutureCouponRulesFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'code',
                },
                {
                    property: 'couponType',
                },
                {
                    property: 'executeStatus',
                },
                {
                    property: 'subsiteIds',
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        EfutureCouponRulesTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'info',
                    width: 360,
                },
                {
                    property: 'typeName',
                },
                {
                    property: 'couponValue',
                },
                {
                    property: 'code',
                },

                // {
                //     property: 'startTime',
                // },
                // {
                //     property: 'endTime',
                // },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            width: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                            NOT_FINISHED: {
                                text: '<<wjs>>',
                                status: 'processing',
                            },
                        },
                    },
                },
                {
                    property: 'subsites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 180,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                                    apiPath: '/admin/efuture_coupon_rules/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: '#f5222d' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                                    apiPath: '/admin/efuture_coupon_rules/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/menu-list/coupon/efuture-coupon-rules/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/menu-list/coupon/efuture-coupon-rules/copy/copydata/{{row.id}}',
                    },
                ],
            },
        },
        EfutureCouponRulesAddPage: {
            component: 'Card',
            content: { component: 'EfutureCouponRulesAddForm' },
        },
        EfutureCouponRulesAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'EfutureCouponRulesEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.externalCoupon' },
                {
                    property: 'baseInfo.stock',
                },
                { property: 'productInfo.subsite' },
                { property: 'productInfo.marketingSchedule' },
                {
                    property: 'useRuleInfo.receivePeriod',
                },
                { property: 'useRuleInfo.receiveLimit' },
                {
                    property: 'useRuleInfo.memberLevels',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === 'MEMBER_LIMIT',
                },
                {
                    property: 'useRuleInfo.registerMemberSource',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === 'TIME_LIMIT',
                },
                {
                    property: 'useRuleInfo.useLimitationsMode',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === 'TIME_LIMIT',
                },
                {
                    property: 'useRuleInfo.useLimitationsDate',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === 'TIME_LIMIT' &&
                        get(values, 'useRuleInfo.useLimitationsMode') === 'DATE',
                },
                {
                    property: 'useRuleInfo.useLimitationsDay',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === 'TIME_LIMIT' &&
                        get(values, 'useRuleInfo.useLimitationsMode') === 'DAY',
                },
                { property: 'useRuleInfo.receiveQuantityLimitType' },
                {
                    property: 'useRuleInfo.memberLimitQuantity',
                    extra: '<<limitGetqtxgx>>',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveQuantityLimitType') ===
                            'EVERY_MEMBER_LIMIT' ||
                        get(values, 'useRuleInfo.receiveQuantityLimitType') ===
                            'EVERY_MEMBER_DAILY_LIMIT',
                },
                {
                    property: 'useRuleInfo.target',
                    controlConfig: {
                        // applicationVersion: 'V2',
                        hideNeedUrlLink: true,
                        subsitePreRequired: true,
                        subsitePreRequiredMsg: '<<qxxzmdhzj>>',
                        bussinessType: LinkTypeSelectorBussinessType.Coupon,
                    },
                },
                { property: 'pictureInfo.couponPicture' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                //三方券
                const externalCoupon = entity.baseInfo.externalCoupon;
                if (!externalCoupon || !externalCoupon.code) {
                    AntMessage.warn(services.language.getText('qxzsfq'));
                    return false;
                }
                if (!externalCoupon.name) {
                    AntMessage.warn(services.language.getText('qsrqmc'));
                    return false;
                }
                if (!externalCoupon.couponValue) {
                    AntMessage.warn(services.language.getText('qsrqmz'));
                    return false;
                }
                if (!externalCoupon.startTime || !externalCoupon.endTime) {
                    AntMessage.warn(services.language.getText('effectiveTimeRequired'));
                    return false;
                }
                // 库存
                const stock = entity.baseInfo.stock.maxCount;
                // 需要填写库存
                if (!stock) {
                    AntMessage.warning(services.language.getText('kcsbkwk'));
                    return false;
                }
                const subsites = entity.productInfo.subsite;
                const isSubsitesEmpty = !subsites || !subsites.length;
                if (isSubsitesEmpty) {
                    AntMessage.warning(services.language.getText('selectSubsite'));
                    return false;
                }
                //领取数量限制
                const receiveQuantityLimitType = entity.useRuleInfo.receiveQuantityLimitType;
                //领取数量
                const memberLimitQuantity = entity.useRuleInfo.memberLimitQuantity;
                if (
                    (receiveQuantityLimitType === 'EVERY_MEMBER_LIMIT' ||
                        receiveQuantityLimitType === 'EVERY_MEMBER_DAILY_LIMIT') &&
                    (!memberLimitQuantity || Number(memberLimitQuantity) <= 0)
                ) {
                    AntMessage.warning(services.language.getText('qzqtxlqsl'));
                    return false;
                }
                //适用等级
                const receiveLimit = entity.useRuleInfo.receiveLimit;
                if (receiveLimit === 'MEMBER_LIMIT') {
                    const memberLevels = entity.useRuleInfo.memberLevels;
                    if (!memberLevels || memberLevels.length === 0) {
                        AntMessage.warning(services.language.getText('selectApplyLevel'));
                        return false;
                    }
                }
                const target = get(entity, 'useRuleInfo.target', {});
                if (!subsiteLinkTypeSelectorValidate(target || {})) {
                    return false;
                }
                // 领取时间校验
                const receivePeriod = get(entity, 'useRuleInfo.receivePeriod', undefined);
                if (receivePeriod) {
                    const dateRangeEnd = new Date(externalCoupon.endTime).getTime();
                    const receivePeriodEnd = new Date(receivePeriod.end).getTime();
                    if (receivePeriodEnd > dateRangeEnd) {
                        AntMessage.warn(services.language.getText('lqdjssjbnzqsxjssjzq'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        EfutureCouponRulesEditPage: {
            component: 'Card',
            content: { component: 'EfutureCouponRulesEditForm' },
        },
        EfutureCouponRulesEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'EfutureCouponRulesEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'baseInfo.externalCoupon',
                    controlConfig: {
                        showChoose: false,
                    },
                },
                {
                    property: 'baseInfo.stock',
                },
                { property: 'productInfo.subsite' },
                { property: 'productInfo.marketingSchedule' },
                {
                    property: 'useRuleInfo.receivePeriod',
                },
                {
                    property: 'useRuleInfo.receiveLimit',
                    controlConfig: {
                        disabled: true,
                        mode: 'horizontal',
                        setOptions: [
                            {
                                value: 'NO_LIMIT',
                                name: '<<noLimit>>',
                            },
                            {
                                value: 'MEMBER_LIMIT',
                                name: '<<memberLimit>>',
                                tip: {
                                    text: '<<memberLimitText>>',
                                },
                            },
                            {
                                value: 'TIME_LIMIT',
                                name: '<<timeLimit>>',
                                tip: {
                                    text: '<<timeLimitTip>>',
                                },
                            },
                        ],
                    },
                },
                {
                    property: 'useRuleInfo.memberLevels',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === 'MEMBER_LIMIT',
                    controlConfig: {
                        pageType: 'EfutureCouponRuleMemberLevel',
                        placeholder: '<<selectPlease>>',
                        style: { width: 450 },
                    },
                },
                {
                    property: 'useRuleInfo.registerMemberSource',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === 'TIME_LIMIT',
                },
                {
                    property: 'useRuleInfo.useLimitationsMode',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === 'TIME_LIMIT',
                },
                {
                    property: 'useRuleInfo.useLimitationsDate',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === 'TIME_LIMIT' &&
                        get(values, 'useRuleInfo.useLimitationsMode') === 'DATE',
                },
                {
                    property: 'useRuleInfo.useLimitationsDay',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === 'TIME_LIMIT' &&
                        get(values, 'useRuleInfo.useLimitationsMode') === 'DAY',
                },
                { property: 'useRuleInfo.receiveQuantityLimitType' },
                {
                    property: 'useRuleInfo.memberLimitQuantity',
                    extra: '<<limitGetqtxgx>>',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveQuantityLimitType') ===
                            'EVERY_MEMBER_LIMIT' ||
                        get(values, 'useRuleInfo.receiveQuantityLimitType') ===
                            'EVERY_MEMBER_DAILY_LIMIT',
                },
                {
                    property: 'useRuleInfo.target',
                    controlConfig: {
                        // applicationVersion: 'V2',
                        hideNeedUrlLink: true,
                        subsitePreRequired: true,
                        subsitePreRequiredMsg: '<<qxxzmdhzj>>',
                        bussinessType: LinkTypeSelectorBussinessType.Coupon,
                    },
                },
                { property: 'pictureInfo.couponPicture' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                //三方券
                const externalCoupon = entity.baseInfo.externalCoupon;
                if (!externalCoupon || !externalCoupon.code) {
                    AntMessage.warn(services.language.getText('qxzsfq'));
                    return false;
                }
                if (!externalCoupon.name) {
                    AntMessage.warn(services.language.getText('qsrqmc'));
                    return false;
                }
                if (!externalCoupon.couponValue) {
                    AntMessage.warn(services.language.getText('qsrqmz'));
                    return false;
                }
                if (!externalCoupon.startTime || !externalCoupon.endTime) {
                    AntMessage.warn(services.language.getText('effectiveTimeRequired'));
                    return false;
                }
                // // 库存
                // const stock = entity.baseInfo.stock.maxCount;
                // // 需要填写库存
                // if (!stock) {
                //     AntMessage.warning(services.language.getText('kcsbkwk'));
                //     return false;
                // }
                const subsites = entity.productInfo.subsite;
                const isSubsitesEmpty = !subsites || !subsites.length;
                if (isSubsitesEmpty) {
                    AntMessage.warning(services.language.getText('selectSubsite'));
                    return false;
                }
                //领取数量限制
                const receiveQuantityLimitType = entity.useRuleInfo.receiveQuantityLimitType;
                //领取数量
                const memberLimitQuantity = entity.useRuleInfo.memberLimitQuantity;
                if (
                    (receiveQuantityLimitType === 'EVERY_MEMBER_LIMIT' ||
                        receiveQuantityLimitType === 'EVERY_MEMBER_DAILY_LIMIT') &&
                    (!memberLimitQuantity || Number(memberLimitQuantity) <= 0)
                ) {
                    AntMessage.warning(services.language.getText('qzqtxlqsl'));
                    return false;
                }
                //适用等级
                const receiveLimit = entity.useRuleInfo.receiveLimit;
                if (receiveLimit === 'MEMBER_LIMIT') {
                    const memberLevels = entity.useRuleInfo.memberLevels;
                    if (!memberLevels || memberLevels.length === 0) {
                        AntMessage.warning(services.language.getText('selectApplyLevel'));
                        return false;
                    }
                }
                const target = get(entity, 'useRuleInfo.target', {});
                if (!subsiteLinkTypeSelectorValidate(target || {})) {
                    return false;
                }
                // 领取时间校验
                const receivePeriod = get(entity, 'useRuleInfo.receivePeriod', undefined);
                if (receivePeriod) {
                    const dateRangeEnd = new Date(externalCoupon.endTime).getTime();
                    const receivePeriodEnd = new Date(receivePeriod.end).getTime();
                    if (receivePeriodEnd > dateRangeEnd) {
                        AntMessage.warn(services.language.getText('lqdjssjbnzqsxjssjzq'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        EfutureCouponRulesCopyPage: {
            component: 'Card',
            content: { component: 'EfutureCouponRulesCopyForm' },
        },
        EfutureCouponRulesCopyForm: {
            component: 'CopyFormPlus',
            direction: 'horizontal',
            entity: 'EfutureCouponRulesEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'baseInfo.externalCoupon',
                },
                {
                    property: 'baseInfo.stock',
                },
                { property: 'productInfo.subsite' },
                { property: 'productInfo.marketingSchedule' },
                {
                    property: 'useRuleInfo.receivePeriod',
                },
                {
                    property: 'useRuleInfo.receiveLimit',
                    controlConfig: {
                        mode: 'horizontal',
                        setOptions: [
                            {
                                value: 'NO_LIMIT',
                                name: '<<noLimit>>',
                            },
                            {
                                value: 'MEMBER_LIMIT',
                                name: '<<memberLimit>>',
                                tip: {
                                    text: '<<memberLimitText>>',
                                },
                            },
                            {
                                value: 'TIME_LIMIT',
                                name: '<<timeLimit>>',
                                tip: {
                                    text: '<<timeLimitTip>>',
                                },
                            },
                        ],
                    },
                },
                {
                    property: 'useRuleInfo.memberLevels',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === 'MEMBER_LIMIT',
                    controlConfig: {
                        pageType: 'EfutureCouponRuleMemberLevel',
                        placeholder: '<<selectPlease>>',
                        style: { width: 450 },
                    },
                },
                {
                    property: 'useRuleInfo.registerMemberSource',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === 'TIME_LIMIT',
                },
                {
                    property: 'useRuleInfo.useLimitationsMode',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === 'TIME_LIMIT',
                },
                {
                    property: 'useRuleInfo.useLimitationsDate',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === 'TIME_LIMIT' &&
                        get(values, 'useRuleInfo.useLimitationsMode') === 'DATE',
                },
                {
                    property: 'useRuleInfo.useLimitationsDay',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === 'TIME_LIMIT' &&
                        get(values, 'useRuleInfo.useLimitationsMode') === 'DAY',
                },
                { property: 'useRuleInfo.receiveQuantityLimitType' },
                {
                    property: 'useRuleInfo.memberLimitQuantity',
                    extra: '<<limitGetqtxgx>>',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveQuantityLimitType') ===
                            'EVERY_MEMBER_LIMIT' ||
                        get(values, 'useRuleInfo.receiveQuantityLimitType') ===
                            'EVERY_MEMBER_DAILY_LIMIT',
                },
                {
                    property: 'useRuleInfo.target',
                    controlConfig: {
                        // applicationVersion: 'V2',
                        hideNeedUrlLink: true,
                        subsitePreRequired: true,
                        subsitePreRequiredMsg: '<<qxxzmdhzj>>',
                        bussinessType: LinkTypeSelectorBussinessType.Coupon,
                    },
                },
                { property: 'pictureInfo.couponPicture' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                //三方券
                const externalCoupon = entity.baseInfo.externalCoupon;
                if (!externalCoupon || !externalCoupon.code) {
                    AntMessage.warn(services.language.getText('qxzsfq'));
                    return false;
                }
                if (!externalCoupon.name) {
                    AntMessage.warn(services.language.getText('qsrqmc'));
                    return false;
                }
                if (!externalCoupon.couponValue) {
                    AntMessage.warn(services.language.getText('qsrqmz'));
                    return false;
                }
                if (!externalCoupon.startTime || !externalCoupon.endTime) {
                    AntMessage.warn(services.language.getText('effectiveTimeRequired'));
                    return false;
                }
                // 库存
                const stock = entity.baseInfo.stock.maxCount;
                // 需要填写库存
                if (!stock) {
                    AntMessage.warning(services.language.getText('kcsbkwk'));
                    return false;
                }
                const subsites = entity.productInfo.subsite;
                const isSubsitesEmpty = !subsites || !subsites.length;
                if (isSubsitesEmpty) {
                    AntMessage.warning(services.language.getText('selectSubsite'));
                    return false;
                }
                //领取数量限制
                const receiveQuantityLimitType = entity.useRuleInfo.receiveQuantityLimitType;
                //领取数量
                const memberLimitQuantity = entity.useRuleInfo.memberLimitQuantity;
                if (
                    (receiveQuantityLimitType === 'EVERY_MEMBER_LIMIT' ||
                        receiveQuantityLimitType === 'EVERY_MEMBER_DAILY_LIMIT') &&
                    (!memberLimitQuantity || Number(memberLimitQuantity) <= 0)
                ) {
                    AntMessage.warning(services.language.getText('qzqtxlqsl'));
                    return false;
                }
                //适用等级
                const receiveLimit = entity.useRuleInfo.receiveLimit;
                if (receiveLimit === 'MEMBER_LIMIT') {
                    const memberLevels = entity.useRuleInfo.memberLevels;
                    if (!memberLevels || memberLevels.length === 0) {
                        AntMessage.warning(services.language.getText('selectApplyLevel'));
                        return false;
                    }
                }
                const target = get(entity, 'useRuleInfo.target', {});
                if (!subsiteLinkTypeSelectorValidate(target || {})) {
                    return false;
                }
                // 领取时间校验
                const receivePeriod = get(entity, 'useRuleInfo.receivePeriod', undefined);
                if (receivePeriod) {
                    const dateRangeEnd = new Date(externalCoupon.endTime).getTime();
                    const receivePeriodEnd = new Date(receivePeriod.end).getTime();
                    if (receivePeriodEnd > dateRangeEnd) {
                        AntMessage.warn(services.language.getText('lqdjssjbnzqsxjssjzq'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/coupon/efuture-coupon-rules',
                    component: 'EfutureCouponRulesView',
                    breadcrumbName: '<<fjyhq>>',
                    privilege: {
                        path: 'eFutureCouponRule',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'EfutureCouponRulesAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'EfutureCouponRulesEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        {
                            path: '/copy/:type/:id',
                            component: 'EfutureCouponRulesCopyPage',
                            breadcrumbName: '<<copy>>',
                        },
                        { path: '/', component: 'EfutureCouponRulesPage' },
                    ],
                },
            ],
        },
    ],
};
