import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { get, trim, filter, last, map } from 'lodash';
import {
    AddressType,
    MerchantSelfStatus,
    MerchantSelfType,
} from '../../src/config/merchant/merchant-manager/merchant-self';

const api = services.api;
export const MerchantSelfLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-SHOP`;
        config.apiPath = '/admin/merchants';
        if (data.id) {
            config.apiPath = `/admin/merchants/${data.id}`;
        }

        return await api.get(data, config).then((res: any) => {
            if (data.id) {
                return resParser(res);
            }
            return res;
        });
    },
    post: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-SHOP`;
        config.apiPath = '/admin/merchants';
        const body = dataParser(data);

        return await api.post(body, config);
    },
    put: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-SHOP`;
        config.apiPath = `/admin/merchants/${data.id}`;

        const body = dataParser(data);
        return await api.put(body, config);
    },
};

export const MerchantBySubsitesLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-SHOP`;
        config.apiPath = '/admin/merchants/page';

        return await api.get(data, config).then((res: any) => {
            if (res && res.result) {
                res.result = res.result.map((i: any) => {
                    const typeOption = merchantSelfTypeOptions[i.type as MerchantSelfType];
                    let text = services.language.getText('kd_1');
                    if (i.status === MerchantSelfStatus.CLOSE) {
                        text = services.language.getText('bd');
                    } else if (i.status === MerchantSelfStatus.PAUSE) {
                        text = services.language.getText('zt_1');
                    } else if (i.status === MerchantSelfStatus.PREPARE) {
                        text = services.language.getText('subsiteStatusDisable');
                    }
                    return {
                        subsiteName: i.subsiteName,
                        id: i.id,
                        name: i.name,
                        code: i.code,
                        status: text,
                        type: typeOption || '',
                    };
                });
            }
            return res;
        });
    },
};

function dataParser(data: any) {
    let newParams: any = {};
    if (data.baseInfo) {
        const subsite = get(data, 'baseInfo.subsite', {});
        const logoPictures = get(data, 'baseInfo.logoPicture', []);
        const propagatePictures = get(data, 'baseInfo.propagatePicture', []);
        const showSid = get(data, 'baseInfo.showSid', 'false') === 'true';
        const floors = get(data, 'baseInfo.floors', []);
        const tagIds = get(data, 'baseInfo.tagIds', []) || [];
        const styleIds = get(data, 'baseInfo.styleIds', []) || [];
        newParams.subsiteId = subsite.id;
        newParams.subsiteName = subsite.name;
        newParams.name = trim(get(data, 'baseInfo.name', ''));
        newParams.nameEn = trim(get(data, 'baseInfo.nameEn', ''));
        newParams.code = trim(get(data, 'baseInfo.code', ''));
        newParams.type = get(data, 'baseInfo.type', '');
        newParams.address = data.baseInfo.address;
        if (logoPictures.length > 0) {
            const [pic] = logoPictures;
            newParams.logoPicId = pic.id;
        }
        if (propagatePictures.length > 0) {
            const [pic] = propagatePictures;
            newParams.propagatePicId = pic.id;
        }
        newParams.customerPhone = get(data, 'baseInfo.customerPhone', '');
        newParams.companyIntroduce = get(data, 'baseInfo.companyIntroduce', '');

        newParams.brandId = get(data, 'baseInfo.brands', '');
        newParams.tagIds = map(tagIds, (i) => {
            return i.key;
        });
        newParams.styleLabelIds = map(styleIds, (i) => {
            return i.key;
        });
        newParams.sequence = get(data, 'baseInfo.sequence', null);
        let currentFloors = map(floors, (item: any) => {
            item.merchantFloorAddress = trim(item.merchantFloorAddress);
            item.sid = trim(item.sid);
            if (!showSid) {
                item.sid = '';
            }
            return item;
        });
        currentFloors = filter(currentFloors, (item: any) => {
            return item.merchantFloorId || item.merchantFloorAddress || item.sequence || item.sid;
        });
        newParams.merchantFloors = currentFloors;
    }
    if (data.manageSetting) {
        const addressEnum = get(data, 'manageSetting.addressEnum', AddressType.SUBSITE_ADDRESS);
        const afterSalesContacts = get(data, 'manageSetting.afterSalesContacts', '');
        const afterSalesPhone = get(data, 'manageSetting.afterSalesPhone', '');
        const afterSalesSubsiteId = get(data, 'manageSetting.afterSalesSubsiteId', []);
        const afterSalesAddress = get(data, 'manageSetting.afterSalesAddress', '');
        const independentAfterSaleBackModes =
            get(data, 'manageSetting.independentAfterSaleBackModes', 'false') === 'true';
        newParams.independentAfterSaleBackModes = independentAfterSaleBackModes;
        newParams.afterSaleBackModes = independentAfterSaleBackModes
            ? get(data, 'manageSetting.afterSaleBackModes', [])
            : [];
        newParams.categoryIds = get(data, 'manageSetting.categoryIds', []);
        newParams.allowQuickBilling =
            get(data, 'manageSetting.allowQuickBilling', 'false') === 'true';
        newParams.addressEnum = addressEnum;
        newParams.allowScanCashier =
            get(data, 'manageSetting.allowScanCashier', 'false') === 'true';
        if (addressEnum === AddressType.MERCHANT_ADDRESS) {
            newParams.afterSalesConfigCommand = {
                afterSalesContacts,
                afterSalesPhone,
                afterSalesAddress,
                afterSalesSubsiteId: last(afterSalesSubsiteId),
            };
        }
        //发货地址
        const deliveryLogisticsSenderAddressEnum = get(
            data,
            'manageSetting.deliveryLogisticsSenderAddressEnum',
            AddressType.SUBSITE_ADDRESS
        );
        const deliveryLogisticsSenderName = get(
            data,
            'manageSetting.deliveryLogisticsSenderName',
            ''
        );
        const deliveryLogisticsSenderContactNumber = get(
            data,
            'manageSetting.deliveryLogisticsSenderContactNumber',
            ''
        );
        const deliveryLogisticsSenderRegionId = get(
            data,
            'manageSetting.deliveryLogisticsSenderRegionId',
            []
        );
        const deliveryLogisticsSenderAddress = get(
            data,
            'manageSetting.deliveryLogisticsSenderAddress',
            ''
        );
        if (deliveryLogisticsSenderAddressEnum === AddressType.MERCHANT_ADDRESS) {
            newParams.deliveryLogisticsSender = {
                name: deliveryLogisticsSenderName,
                contactNumber: deliveryLogisticsSenderContactNumber,
                address: deliveryLogisticsSenderAddress,
                regionId: last(deliveryLogisticsSenderRegionId),
            };
        }
        newParams.industryId = data.manageSetting.industryId
            ? data.manageSetting.industryId.id
            : undefined;
        const restaurantReservation = data.manageSetting.restaurantReservation;
        newParams.restaurantReservation =
            data.manageSetting.industryId &&
            data.manageSetting.industryId.code === 'Catering' &&
            restaurantReservation &&
            restaurantReservation.enable
                ? {
                      cardLevels: restaurantReservation.limitCardLevels
                          ? restaurantReservation.cardLevels
                          : undefined,
                      advanceReservationDays: restaurantReservation.limitReservationDay
                          ? restaurantReservation.advanceReservationDays
                          : undefined,
                      limitReservationDays: restaurantReservation.limitReservationDay
                          ? restaurantReservation.limitReservationDays
                          : undefined,
                      spaceTypes: restaurantReservation.spaceTypes,
                      times: restaurantReservation.times.map((time: any) => {
                          //后端传值需格式化成10:00:00
                          time.startTime =
                              time.startTime.split(':').length < 3
                                  ? time.startTime + ':00'
                                  : time.startTime;
                          time.endTime =
                              time.endTime.split(':').length < 3
                                  ? time.endTime + ':00'
                                  : time.endTime;
                          return time;
                      }),
                      description: data.manageSetting.description,
                  }
                : undefined;
    }
    if (data.seniorSetting) {
        if (data.seniorSetting.companyInfo) {
            const companyBusinessPicture = get(
                data,
                'seniorSetting.companyInfo.companyBusinessPicture',
                []
            );
            const taxRegistrationPicture = get(
                data,
                'seniorSetting.companyInfo.taxRegistrationPicture',
                []
            );
            const organCodeCertificatePicture = get(
                data,
                'seniorSetting.companyInfo.organCodeCertificatePicture',
                []
            );
            const openLicensePicture = get(
                data,
                'seniorSetting.companyInfo.openLicensePicture',
                []
            );
            const legalFrontPicture = get(data, 'seniorSetting.companyInfo.legalFrontPicture', []);
            const legalBackPicture = get(data, 'seniorSetting.companyInfo.legalBackPicture', []);
            newParams.companyName = get(data, 'seniorSetting.companyInfo.companyName', '');
            newParams.companyType = get(data, 'seniorSetting.companyInfo.companyType', '');
            newParams.companyScale = get(data, 'seniorSetting.companyInfo.companyScale', '');
            newParams.companyLegal = get(data, 'seniorSetting.companyInfo.companyLegal', '');
            newParams.companyLegalIdNumber = get(
                data,
                'seniorSetting.companyInfo.companyLegalIdNumber',
                ''
            );
            newParams.accountName = get(data, 'seniorSetting.companyInfo.accountName', '');
            newParams.companyBankName = get(data, 'seniorSetting.companyInfo.companyBankName', '');
            newParams.companyAccountNumber = get(
                data,
                'seniorSetting.companyInfo.companyAccountNumber',
                ''
            );
            if (companyBusinessPicture.length > 0) {
                const [pic] = companyBusinessPicture;
                newParams.companyBusinessPicId = pic.id;
            }
            if (taxRegistrationPicture.length > 0) {
                const [pic] = taxRegistrationPicture;
                newParams.taxRegistrationPicId = pic.id;
            }
            if (organCodeCertificatePicture.length > 0) {
                const [pic] = organCodeCertificatePicture;
                newParams.organCodeCertificatePicId = pic.id;
            }
            if (openLicensePicture.length > 0) {
                const [pic] = openLicensePicture;
                newParams.openLicensePicId = pic.id;
            }
            if (legalFrontPicture.length > 0) {
                const [pic] = legalFrontPicture;
                newParams.legalFrontPicId = pic.id;
            }
            if (legalBackPicture.length > 0) {
                const [pic] = legalBackPicture;
                newParams.legalBackPicId = pic.id;
            }
        }
        if (data.seniorSetting.operatorInfo) {
            const operatorCertFrontPicture = get(
                data,
                'seniorSetting.operatorInfo.operatorCertFrontPicture',
                []
            );
            const operatorCertBackPicture = get(
                data,
                'seniorSetting.operatorInfo.operatorCertBackPicture',
                []
            );
            newParams.operatorName = get(data, 'seniorSetting.operatorInfo.operatorName', '');
            newParams.operatorDepartment = get(
                data,
                'seniorSetting.operatorInfo.operatorDepartment',
                ''
            );
            newParams.email = get(data, 'seniorSetting.operatorInfo.email', '');
            newParams.phone = get(data, 'seniorSetting.operatorInfo.phone', '');
            if (operatorCertFrontPicture.length > 0) {
                const [pic] = operatorCertFrontPicture;
                newParams.operatorCertFrontPicId = pic.id;
            }
            if (operatorCertBackPicture.length > 0) {
                const [pic] = operatorCertBackPicture;
                newParams.operatorCertBackPicId = pic.id;
            }
        }
        if (data.seniorSetting.otherInfo) {
            const serviceTagStylePicture = get(
                data,
                'seniorSetting.otherInfo.serviceTagStylePicture',
                []
            );
            const logoMiniPicture = get(data, 'seniorSetting.otherInfo.logoMiniPicture', []);
            if (serviceTagStylePicture.length > 0) {
                const [pic] = serviceTagStylePicture;
                newParams.serviceTagStylePicId = pic.id;
            }
            if (logoMiniPicture.length > 0) {
                const [pic] = logoMiniPicture;
                newParams.logoMiniPicId = pic.id;
            }
        }
    }
    return newParams;
}
function resParser(res: any) {
    const newRes: any = {
        baseInfo: {},
        manageSetting: {},
        seniorSetting: {
            companyInfo: {},
            operatorInfo: {},
            otherInfo: {},
        },
    };
    newRes.baseInfo.subsite = {
        id: get(res, 'subsiteId', ''),
        name: get(res, 'subsiteName', ''),
    };
    newRes.baseInfo.name = get(res, 'name', '');
    newRes.baseInfo.nameEn = get(res, 'nameEn', '');
    newRes.baseInfo.code = get(res, 'code', '');
    newRes.baseInfo.type = get(res, 'type', '');
    newRes.baseInfo.selfType = get(res, 'type', '');
    newRes.baseInfo.logoPicture = getPicArray(
        get(res, 'logoPicId', ''),
        get(res, 'logoPicUrl', '')
    );
    newRes.baseInfo.propagatePicture = getPicArray(
        get(res, 'propagatePicId', ''),
        get(res, 'propagatePicUrl', '')
    );
    newRes.baseInfo.customerPhone = get(res, 'customerPhone', '');
    newRes.baseInfo.companyIntroduce = get(res, 'companyIntroduce', '');
    newRes.baseInfo.address = res.address;
    const brandId = get(res, 'brandId', '');
    const brandName = get(res, 'brandName', '');
    newRes.baseInfo.brands = brandId ? { id: brandId, name: brandName } : {};
    const merchantTagVos = get(res, 'merchantTagVos', []) || [];
    newRes.baseInfo.tagIds = map(merchantTagVos, (item: any) => {
        return {
            key: Number(item.tagId),
            label: item.tagName,
        };
    });
    const styleLabels = get(res, 'styleLabels', []) || [];
    newRes.baseInfo.styleIds = map(styleLabels, (item: any) => {
        return {
            key: item.id,
            label: item.name,
        };
    });
    newRes.baseInfo.sequence = get(res, 'sequence', '');
    const merchantFloorVos = get(res, 'merchantFloorVos', []);
    newRes.baseInfo.showSid = merchantFloorVos.some((i: any) => i.sid) ? 'true' : 'false';
    newRes.baseInfo.floors = map(merchantFloorVos, (item: any) => {
        if (item.sequence === 0) {
            item.sequence = undefined;
        }
        return item;
    });

    const merchantCategoryVos = get(res, 'merchantCategoryVos', []);
    const afterSalesConfigVo = get(res, 'afterSalesConfigVo');
    newRes.manageSetting.categoryIds = map(
        filter(merchantCategoryVos, (i: any) => i && i.categoryId !== 1),
        (i: any) => i.categoryId
    );
    newRes.manageSetting.allowQuickBilling = get(res, 'allowQuickBilling', false)
        ? 'true'
        : 'false';
    const addressEnum = get(res, 'addressEnum', AddressType.SUBSITE_ADDRESS);
    newRes.manageSetting.addressEnum = addressEnum;
    if (addressEnum === AddressType.MERCHANT_ADDRESS && afterSalesConfigVo) {
        const afterSalesSubsiteId: number[] = getNodeRegionVo(
            get(afterSalesConfigVo, 'nodeRegionVo', []),
            []
        );
        newRes.manageSetting.afterSalesContacts = get(afterSalesConfigVo, 'afterSalesContacts', '');
        newRes.manageSetting.afterSalesPhone = get(afterSalesConfigVo, 'afterSalesPhone', '');
        newRes.manageSetting.afterSalesAddress = get(afterSalesConfigVo, 'afterSalesAddress', '');
        newRes.manageSetting.afterSalesSubsiteId = afterSalesSubsiteId;
    }

    //发货地址
    newRes.manageSetting.deliveryLogisticsSenderAddressEnum = AddressType.SUBSITE_ADDRESS;
    const deliveryLogisticsSender = get(res, 'deliveryLogisticsSender');
    if (res.deliveryLogisticsSender) {
        newRes.manageSetting.deliveryLogisticsSenderAddressEnum = AddressType.MERCHANT_ADDRESS;
        const deliveryLogisticsSenderRegionId: number[] = getNodeRegionVo(
            get(deliveryLogisticsSender, 'nodeRegionVo', []),
            []
        );
        newRes.manageSetting.deliveryLogisticsSenderName = get(deliveryLogisticsSender, 'name', '');
        newRes.manageSetting.deliveryLogisticsSenderContactNumber = get(
            deliveryLogisticsSender,
            'contactNumber',
            ''
        );
        newRes.manageSetting.deliveryLogisticsSenderAddress = get(
            deliveryLogisticsSender,
            'address',
            ''
        );
        newRes.manageSetting.deliveryLogisticsSenderRegionId = deliveryLogisticsSenderRegionId;
    }
    newRes.manageSetting.independentAfterSaleBackModes = res.independentAfterSaleBackModes
        ? 'true'
        : 'false';
    newRes.manageSetting.afterSaleBackModes = res.independentAfterSaleBackModes
        ? res.afterSaleBackModes?.map((mode: number) => mode.toString())
        : [];
    newRes.manageSetting.allowScanCashier = res.allowScanCashier ? 'true' : 'false';
    //预订
    newRes.manageSetting.industryId = res.industry ? res.industry : undefined;
    if (res.restaurantReservation) {
        newRes.manageSetting.restaurantReservation = {
            enable: true,
            limitCardLevels:
                res.restaurantReservation.cardLevels &&
                res.restaurantReservation.cardLevels.length > 0
                    ? true
                    : false,
            cardLevels:
                res.restaurantReservation.cardLevels &&
                res.restaurantReservation.cardLevels.length > 0
                    ? res.restaurantReservation.cardLevels.map((id: number) => {
                          return id + '';
                      })
                    : undefined,
            limitReservationDay:
                res.restaurantReservation.advanceReservationDays !== null ? true : false,
            advanceReservationDays: res.restaurantReservation.advanceReservationDays,
            limitReservationDays: res.restaurantReservation.limitReservationDays,
            spaceTypes: res.restaurantReservation.spaceTypes,
            times: res.restaurantReservation.times,
        };
        newRes.manageSetting.description = res.restaurantReservation.description;
    }
    newRes.seniorSetting.companyInfo.companyName = get(res, 'companyName', '');
    newRes.seniorSetting.companyInfo.companyType = get(res, 'companyType', '');
    newRes.seniorSetting.companyInfo.companyScale = get(res, 'companyScale', '');
    newRes.seniorSetting.companyInfo.companyLegal = get(res, 'companyLegal', '');
    newRes.seniorSetting.companyInfo.companyLegalIdNumber = get(res, 'companyLegalIdNumber', '');
    newRes.seniorSetting.companyInfo.accountName = get(res, 'accountName', '');
    newRes.seniorSetting.companyInfo.companyBankName = get(res, 'companyBankName', '');
    newRes.seniorSetting.companyInfo.companyAccountNumber = get(res, 'companyAccountNumber', '');
    newRes.seniorSetting.companyInfo.companyBusinessPicture = getPicArray(
        get(res, 'companyBusinessPicId', ''),
        get(res, 'companyBusinessPicUrl', '')
    );
    newRes.seniorSetting.companyInfo.taxRegistrationPicture = getPicArray(
        get(res, 'taxRegistrationPicId', ''),
        get(res, 'taxRegistrationPicIdUrl', '')
    );
    newRes.seniorSetting.companyInfo.organCodeCertificatePicture = getPicArray(
        get(res, 'organCodeCertificatePicId', ''),
        get(res, 'organCodeCertificatePicIdUrl', '')
    );
    newRes.seniorSetting.companyInfo.openLicensePicture = getPicArray(
        get(res, 'openLicensePicId', ''),
        get(res, 'openLicensePicUrl', '')
    );
    newRes.seniorSetting.companyInfo.legalFrontPicture = getPicArray(
        get(res, 'legalFrontPicId', ''),
        get(res, 'legalFrontPicUrl', '')
    );
    newRes.seniorSetting.companyInfo.legalBackPicture = getPicArray(
        get(res, 'legalBackPicId', ''),
        get(res, 'legalBackPicUrl', '')
    );

    newRes.seniorSetting.operatorInfo.operatorName = get(res, 'operatorName', '');
    newRes.seniorSetting.operatorInfo.operatorDepartment = get(res, 'operatorDepartment', '');
    newRes.seniorSetting.operatorInfo.email = get(res, 'email', '');
    newRes.seniorSetting.operatorInfo.phone = get(res, 'phone', '');
    newRes.seniorSetting.operatorInfo.operatorCertFrontPicture = getPicArray(
        get(res, 'operatorCertFrontPicId', ''),
        get(res, 'operatorCertFrontPicUrl', '')
    );
    newRes.seniorSetting.operatorInfo.operatorCertBackPicture = getPicArray(
        get(res, 'operatorCertBackPicId', ''),
        get(res, 'operatorCertBackPicUrl', '')
    );

    newRes.seniorSetting.otherInfo.serviceTagStylePicture = getPicArray(
        get(res, 'serviceTagStylePicId', ''),
        get(res, 'serviceTagStylePicUrl', '')
    );
    newRes.seniorSetting.otherInfo.logoMiniPicture = getPicArray(
        get(res, 'logoMiniPicId', ''),
        get(res, 'logoMiniPicUrl', '')
    );
    return newRes;
}

function getPicArray(id: string, path: string) {
    if (id && path) {
        return [
            {
                id,
                path,
            },
        ];
    }
    return [];
}

export const SubsiteFloorsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        const id = get(data, "['baseInfo.subsite'].id", '');
        config.apiPath = `/admin/subsites/getFloors/${id}`;
        return await api.get({}, config);
    },
};

export const MerchantTagsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        const subsiteName = get(data, "['baseInfo.subsite'].name", '');
        config.apiPath = `/admin/merchant_tags?page=1&per_page=1000&subsite_name=${subsiteName}`;
        return await api.get({}, config);
    },
};

function getNodeRegionVo(node: any, list: number[]): number[] {
    list.push(node.id);
    if (node.childNode) {
        return getNodeRegionVo(node.childNode, list);
    } else {
        return list;
    }
}
const merchantSelfTypeOptions = {
    [MerchantSelfType.JOINT]: '联营',
    [MerchantSelfType.MERCHANT_SELF]: '自营',
    [MerchantSelfType.LEASE]: '租赁',
    [MerchantSelfType.LEAGUE]: '加盟',
    [MerchantSelfType.OTHER]: '其他',
    [MerchantSelfType.SELF]: '自营',
};
