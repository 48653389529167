import React, { PureComponent, createElement } from 'react';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { Menu, Dropdown, Button, Icon, message, Modal } from 'antd';
import { api, language } from '@comall-backend-builder/core/lib/services';
import { remove, map, get } from 'lodash';
import { SelectTree, TreeData } from '../../select-tree';
import { handleOrganizationTreeData } from '../../organization/common';
import { ResetPasswordModal } from '../reset-password-modal';
import { errorHandle } from '../../../services/error-handle';
import { BatchImportButton } from '../../../../../components/batch-import-button';
import { BatchRevokedModalContent } from './batch-revoked-modal-content';

import './index.less';
interface UserManageProps {
    entity: any;
    visible: boolean;
    avatar: string;
    onCancel: () => void;
    onOk: (avatar: string) => void;
}

interface UserManageState {
    //组织机构树
    treeData: TreeData[];
    //选中的组织机构树节点
    selectedTreeNode: TreeData | undefined;
    //重置密码弹框是否显示
    resetPwdModalVisible: boolean;
    //当前操作的用户
    currentHandleUser: any;
    // 表格中选中的组织
    selectedRows: any[];
}

export interface DistributionCommissionAccount {
    balance: string;
    id: string;
    mobile: string;
    realName: string;
}

export class NewUserManageViewContainer extends PureComponent<UserManageProps, UserManageState> {
    constructor(props: any) {
        super(props);
        this.state = {
            treeData: [],
            currentHandleUser: undefined,
            selectedTreeNode: undefined,
            resetPwdModalVisible: false,
            selectedRows: [],
        };
    }

    componentDidMount() {
        this.loadTreeData();
    }
    /**
     * 加载组织机构树形数据
     */
    loadTreeData = () => {
        api.get(
            {},
            {
                apiPath: `/organizations`,
                apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
            }
        ).then((res: any) => {
            const selectedTreeNode =
                res && res.result && res.result.length ? res.result[0] : undefined;
            const treeData = handleOrganizationTreeData(res.result);
            this.setState({ treeData, selectedTreeNode }, this.loadSelectedTreeNodeChildren);
        });
    };
    /**
     * 加载当前选中树的子级树
     */
    loadSelectedTreeNodeChildren = () => {
        const { entity } = this.props;
        const { selectedTreeNode } = this.state;
        if (selectedTreeNode) {
            delete entity.filters.phone;
            delete entity.filters.realName;
            delete entity.filters.userName;
            delete entity.filters.roleName;
            delete entity.filters.status;
            // delete entity.filters.organizationName;
            entity.filters.organizationId = selectedTreeNode.id;
            entity.search();
        }
    };

    onTreeSelect = (selectedTreeNode: TreeData) => {
        this.setState({ selectedTreeNode }, this.loadSelectedTreeNodeChildren);
    };

    showResetPwdModal = () => {
        this.setState({ resetPwdModalVisible: true });
    };

    onResetPwdModalOK = () => {
        this.loadTreeData();
        this.setState({ resetPwdModalVisible: false });
    };
    onResetPwdModalCancel = () => {
        this.setState({ resetPwdModalVisible: false });
    };

    onTableRowSelect = (record: any, selected: boolean) => {
        const { selectedRows } = this.state;
        if (selected) {
            selectedRows.push(record);
        } else {
            remove(selectedRows, (i: any) => {
                return i.id === record.id;
            });
        }
        this.setState({ selectedRows });
    };

    onTableRowSelectAll = (selected: boolean, rows: any[]) => {
        let { selectedRows } = this.state;
        if (selected) {
            selectedRows = rows;
        } else {
            selectedRows = [];
        }
        this.setState({ selectedRows });
    };
    /**
     * 批量操作
     */
    handleBatch = async (e: any) => {
        const { entity } = this.props;
        const { selectedRows } = this.state;
        if (!selectedRows || !selectedRows.length) {
            message.error(language.getText('qxxzczzh'));
            return;
        }
        let userIds = selectedRows.map((item: any) => item.id);
        if (e.key === 'REVOKED') {
            const confirm = await this.batchRevokedConfirm(userIds.join(','));
            if (!confirm) {
                return;
            }
        }
        api.put(
            { userIds, status: e.key },
            { apiPath: '/users/status_batch', apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY` }
        )
            .then(() => {
                const msg =
                    e.key === 'INACTIVE'
                        ? language.getText('pljycg')
                        : e.key === 'ACTIVE'
                        ? language.getText('plqycg')
                        : language.getText('plzxcg');
                this.setState({
                    selectedRows: [],
                });
                message.success(msg, () => {
                    entity.search();
                });
            })
            .catch(errorHandle);
    };
    onImportSuccess = () => {
        const { entity } = this.props;
        entity.search();
    };

    batchRevokedConfirm = (userIds: string) => {
        return new Promise<boolean>(async (resolve) => {
            const accounts = (
                await this.getUserDistributionCommissionAccounts(userIds)
            ).filter((i) => Boolean(Number(i.balance)));
            Modal.confirm({
                title: language.getText('zxzh'),
                content: <BatchRevokedModalContent accounts={accounts} />,
                onOk: () => {
                    resolve(true);
                },
                onCancel: () => {
                    resolve(false);
                },
                okText: services.language.getText('common.confirm'),
                cancelText: services.language.getText('common.cancel'),
                width: 602,
            });
        });
    };

    getUserDistributionCommissionAccounts = (userIds: string) => {
        const apiPath = '/admin/guides/shopping_guides/distribution_commission_accounts';
        const apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        return api.get<DistributionCommissionAccount[]>({ userIds }, { apiPath, apiRoot });
    };

    revoked = async (user: any) => {
        const accounts = await this.getUserDistributionCommissionAccounts(user.id);
        const balance = get(accounts, '[0].balance', 0);
        let content = services.language.getText('zxhzhjwfhf');
        if (Boolean(Number(balance))) {
            content = services.interpolate(language.getText('balanceNoUse'), { balance }) + content;
        }
        Modal.confirm({
            title: language.getText('zxzh'),
            content,
            onOk: () => {
                this.onRevokedModalOK(user);
            },
            okText: services.language.getText('common.confirm'),
            cancelText: services.language.getText('common.cancel'),
        });
    };

    onRevokedModalOK = (user: any) => {
        const { entity } = this.props;
        api.put(
            { status: 'REVOKED' },
            {
                apiPath: `/users/${user.id}/status`,
                apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
            }
        ).then((res: any) => {
            message.success(language.getText('zxcg'), () => {
                entity.search();
            });
        });
    };

    renderBatchImportButton = () => {
        const uploadConfig = {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/users/import',
        };
        const templateResource = {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/users/download_import_template',
        };
        return (
            <BatchImportButton
                onImportSuccess={this.onImportSuccess}
                uploadConfig={uploadConfig}
                templateResource={templateResource}
            />
        );
    };

    render() {
        const { treeData, selectedTreeNode, resetPwdModalVisible, currentHandleUser } = this.state;

        return (
            <div className="user-manage-view">
                {this.renderFilters()}
                <div className="data-view">
                    <div className="left-tree-view">
                        <div className="buttons">{this.renderAddBtn()}</div>
                        <div className="tree-wrap">
                            {treeData && treeData.length ? (
                                <SelectTree
                                    onSelect={this.onTreeSelect}
                                    result={treeData}
                                    selectedTreeNode={selectedTreeNode}
                                />
                            ) : (
                                ''
                            )}
                        </div>
                    </div>

                    <div className="right-table-view">
                        <div className="buttons">
                            {this.renderBatchButton()}
                            {this.renderBatchImportButton()}
                        </div>
                        {this.renderTable()}
                    </div>
                </div>
                {resetPwdModalVisible && (
                    <ResetPasswordModal
                        {...this.props}
                        visible={resetPwdModalVisible}
                        row={currentHandleUser}
                        onOk={this.onResetPwdModalOK}
                        onCancel={this.onResetPwdModalCancel}
                    />
                )}
            </div>
        );
    }

    renderAddBtn = () => {
        const btnProps: any = {
            text: language.getText('xjyg'),
            type: 'primary',
            route: '/new-user-manage/user-add',
        };
        return createElement(ComponentsManager.get('Button'), btnProps);
    };
    renderBatchButton = () => {
        const menu = (
            <Menu onClick={this.handleBatch}>
                <Menu.Item key="ACTIVE">{language.getText('plqy')}</Menu.Item>
                <Menu.Item key="INACTIVE">{language.getText('pljy')}</Menu.Item>
                <Menu.Item key="REVOKED">{language.getText('plzx')}</Menu.Item>
            </Menu>
        );
        return (
            <Dropdown overlay={menu} className="button">
                <Button>
                    {language.getText('batchAction')}
                    <Icon type="down" />
                </Button>
            </Dropdown>
        );
    };

    renderFilters = () => {
        const { entity } = this.props;
        let filterFormPlusProps = {
            entity,

            component: 'FilterForm',
            componentId: 'UserManageFilterFormPlus',
            direction: 'inline',
            submit: {
                text: services.language.getText('common.search'),
            },
            reset: {
                text: services.language.getText('common.reset'),
                style: {
                    marginLeft: 16,
                },
            },
            onSubmit: (e: any, value: any) => {
                delete entity.filters.organizationId;
                this.setState({
                    selectedTreeNode: undefined,
                });
                entity.pageChange(Object.assign({}, entity.filters, entity.paging, { page: 1 }));
                entity.search();
            },
            fields: [
                {
                    property: 'phone',
                },
                {
                    property: 'realName',
                },
                {
                    property: 'userName',
                },

                {
                    property: 'roleName',
                },
                {
                    property: 'status',
                    controlConfig: {
                        style: { width: 180 },
                    },
                },
                {
                    property: 'organizationName',
                },
            ],
        };
        return createElement(ComponentsManager.get('FilterForm'), filterFormPlusProps);
    };

    renderTable = () => {
        const { entity } = this.props;
        const { selectedRows } = this.state;
        let tableProps = {
            entity,
            componentId: 'NewUserManageTable',
            rowKey: 'id',
            loadFirstPage: false,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'phone',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                    },
                },
                {
                    property: 'userName',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                    },
                },
                {
                    property: 'realName',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                    },
                },
                {
                    property: 'staffCode',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                    },
                },
                {
                    property: 'status',
                    displayConfig: {
                        statusConfig: {
                            ACTIVE: {
                                text: language.getText('enable'),
                                status: 'success',
                            },
                            INACTIVE: {
                                text: language.getText('disabled'),
                                status: 'error',
                            },
                            SUSPEND: {
                                text: language.getText('invalid'),
                                status: 'error',
                            },
                            REVOKED: {
                                text: language.getText('off'),
                                status: 'default',
                            },
                        },
                    },
                },
                {
                    property: 'organizationName',
                },
                {
                    property: 'userRoles',
                },
                {
                    property: 'organizations',
                },
                {
                    property: 'createTime',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                    },
                },
                {
                    property: 'createUserName',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                    },
                },
            ],
            rowSelection: {
                selectedRowKeys: map(selectedRows, 'id'),
                onChange: () => {},
                onSelect: (record: any, selected: boolean) => {
                    this.onTableRowSelect(record, selected);
                },
                onSelectAll: (selected: boolean, rows: any) => {
                    this.onTableRowSelectAll(selected, rows);
                },
                getCheckboxProps: (record: any) => {
                    if (record.status === 'REVOKED') {
                        return { disabled: true };
                    }
                    return {};
                },
            },
            actionColumn: {
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'status',
                            renderComponent: 'Button',
                            apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: 'INACTIVE' },
                                    value: 'ACTIVE',
                                    apiPath: '/users/:id/status',
                                    config: {
                                        content: language.getText('disabled'),
                                        text: language.getText('disabled'),
                                    },
                                    confirm: {
                                        text: language.getText('isDisabled') + '{{row.userName}}?',
                                    },
                                },
                                {
                                    params: { status: 'ACTIVE' },
                                    value: 'SUSPEND',
                                    apiPath: '/users/:id/status',
                                    config: {
                                        content: language.getText('jd'),
                                        text: language.getText('jd'),
                                    },
                                    confirm: {
                                        text: language.getText('sfjd') + '{{row.userName}}?',
                                    },
                                },
                                {
                                    params: { status: 'ACTIVE' },
                                    value: 'INACTIVE',
                                    apiPath: '/users/:id/status',
                                    config: {
                                        content: language.getText('enable'),
                                        text: language.getText('enable'),
                                    },
                                    confirm: {
                                        text: language.getText('isEnable') + '{{row.userName}}?',
                                    },
                                },
                                {
                                    value: 'REVOKED',
                                    config: {
                                        style: { display: 'none' },
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'component',
                        component: 'Dropdown',
                        config: {
                            style: { display: 'inline-flex' },
                            content: {
                                contentType: 'link',
                                text: language.getText('common.more'),
                            },
                            menuItems: [
                                {
                                    component: 'Button',
                                    configs: {
                                        text: language.getText('common.view'),
                                        type: 'link',
                                        route: '/new-user-manage/user-info/{{row.id}}',
                                    },
                                },
                                {
                                    component: 'Button',
                                    statusKey: 'canEdit',
                                    configs: {
                                        text: language.getText('common.edit'),
                                        type: 'link',
                                        route: '/new-user-manage/user-edit/{{row.id}}',
                                    },
                                },
                                {
                                    component: 'Button',
                                    statusKey: 'canEdit',
                                    configs: {
                                        text: language.getText('zzmm'),
                                        type: 'link',
                                        onClick: (value: any) => {
                                            this.setState({
                                                currentHandleUser: value,
                                            });
                                            this.showResetPwdModal();
                                        },
                                    },
                                },
                                {
                                    component: 'Button',
                                    statusKey: 'canEdit',
                                    configs: {
                                        text: language.getText('off'),
                                        type: 'link',
                                        onClick: (value: any) => {
                                            this.revoked(value);
                                        },
                                    },
                                },
                                {
                                    component: 'Button',
                                    configs: {
                                        text: language.getText('common.log'),
                                        type: 'link',
                                        route: '/new-user-manage/user-log/{{row.id}}',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        };
        return createElement(ComponentsManager.get('DataTable'), tableProps);
    };
}
