import React, { PureComponent } from 'react';
import { Button as AntButton } from 'antd';
import { services } from '@comall-backend-builder/core';
import { errorHandle } from '../../applications/cae/services/error-handle';

const api = services.api;

/**
 * 导出失败清单
 */
export class ExportDataButtonFail extends PureComponent<any, any> {
    onClick = () => {
        const { params, row } = this.props;
        let { apiRoot, apiPath } = params;
        apiPath = apiPath.replace('{{row.id}}', row.id);
        apiPath = apiPath.replace('{{row.activityId}}', row.activityId);
        apiPath = apiPath.replace('{{row.couponId}}', row.couponId);

        api.download(
            {},
            {
                apiRoot: apiRoot,
                apiPath: apiPath,
                customHeaders: { 'content-disposition': 'attachment;filename="filename.xls"' },
            }
        ).catch(errorHandle);
    };
    render() {
        const { row, params } = this.props;

        if (row.canExport === undefined) {
            row.canExport = true;
        }

        let disabled = false;
        if (row?.optResult === 'SUCCESS') {
            disabled = true;
        }

        return row.canExport ? (
            <AntButton
                type="link"
                disabled={disabled}
                style={{ paddingRight: 10 }}
                className="span-link"
                onClick={this.onClick}
            >
                {params.text ? params.text : services.language.getText('dcbb')}
            </AntButton>
        ) : (
            ''
        );
    }
}
