import React, { Component } from 'react';
import { result, find } from 'lodash';
import classNames from 'classnames';

import { modes } from '@comall-backend-builder/types';
import { Select } from '@comall-backend-builder/components-basis';

export class StringOptionSelectPlusMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    public getAvailableDisplayComponent(value: any, displayInfo: any) {
        const { className, options, addonafter, addonbefore } = displayInfo;
        displayInfo = {
            ...displayInfo,
            className: classNames('type-string', className),
        };

        if (options) {
            // 下拉选择框，通过key，查找显示的value值
            value = result(
                find(options, function(option) {
                    return option.id === String(value);
                }),
                'name'
            );
        }
        return (
            <div>
                <span>
                    {addonbefore || ''} {value} {addonafter || ''}
                </span>
            </div>
        );
    }

    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        // 下拉选择框
        return <SelectPlus {...controlInfo} />;
    }
}

class SelectPlus extends Component<any, any> {
    render() {
        const { addonafter, addonbefore } = this.props;
        const props: any = this.props;
        return (
            <div>
                <span>{addonbefore || ''}</span>
                <Select
                    style={{ display: 'inline-block', width: '100px', margin: '0 5px' }}
                    {...props}
                />
                <span>{addonafter || ''}</span>
            </div>
        );
    }
}
