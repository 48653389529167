import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { defaults, get, isArray } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import './index.less';

export interface SlotMachinesAddFormProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
}

class slotMachinesAddFormView extends PureComponent<SlotMachinesAddFormProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }

    render() {
        const { entity, onSubmit, wrappedComponentRef } = this.props;

        let fields = [
            {
                property: 'baseInfo.name',
            },
            { property: 'baseInfo.dateRange' },
            { property: 'baseInfo.subsiteIds' },
            { property: 'baseInfo.couponRuleCode' },
            { property: 'baseInfo.marketingSchedule' },
            {
                property: 'pictureInfo.downloadSample',
                className: 'download-ample-hide-label',
            },
            { property: 'pictureInfo.backgroundPicture' },
            { property: 'pictureInfo.soltMachinePicture' },
            { property: 'pictureInfo.singleButtonPicture' },
            { property: 'pictureInfo.moreButtonPicture' },
            { property: 'pictureInfo.ruleDesc' },
            {
                property: 'prizeInfo.assetRewards',
                className: 'prize-info-asset-rewards',
            },
            {
                property: 'prizeInfo.finishWithNoPrize',
                className: 'prize-info-asset-finishWithNoPrize',
                visible: (values: any) => {
                    const assetRewards = get(values, 'prizeInfo.assetRewards') || [];
                    const findNoneReward = assetRewards.filter(
                        (item: any) => item.type.checked === 'NONE'
                    );
                    if (findNoneReward.length > 0) {
                        return true;
                    }
                    return false;
                },
            },
        ];

        let formProps = {
            entity,
            componentId: 'SlotMachinesAddFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'slot-machines-add-form-container',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            fields: fields,
            submit: true,
            items: {},
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: services.language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: async (entityFields: any) => {
                const assetRewards = get(entityFields, 'prizeInfo.assetRewards', null);
                if (!assetRewards || !isArray(assetRewards) || assetRewards.length <= 0) {
                    AntMessage.warning(services.language.getText('qtjjp'));
                    return false;
                }
                // 校验时间：开始时间需要大于当前时间
                if (+new Date(get(entityFields, 'baseInfo.dateRange.start')) <= +new Date()) {
                    AntMessage.error(services.language.getText('hdkssjywydqsj'));
                    return false;
                }

                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('CreateFormPlus'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange } = props;
    let form: any;

    return defaults(
        {
            onSubmit,
            onFieldChange,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('SlotMachinesAddFormContainer'));
            },
        }
    );
}
export const SlotMachinesAddFormView = connect(mapDispatchToProps)(slotMachinesAddFormView);
