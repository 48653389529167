import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        storeSelect: {
            apiPath: '/admin/subsites/mine',
            properties: {
                id: {
                    type: 'string',
                    displayName: '<<mdbh>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    controlConfig: {
                        placeholder: '<<subsiteNameRequired>>',
                    },
                    displayName: '<<subsiteName>>',
                },
            },
        },
        PickOrder: {
            apiPath: '/loader/admin/picking_lists',
        },
        waitingForDeliveryEntity: {
            apiPath: '/loader/admin/orders/reviewed',
            filters: {
                orderNo: {
                    type: 'string',
                    displayName: '<<ddh_1>>',
                    controlConfig: { placeholder: '<<qsrddh>>' },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsites>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchant>>',
                },
                orderNo: {
                    type: 'object.link',
                    displayName: '<<orderNumber>>',
                },
                orderType: {
                    type: 'string.options.select',
                    displayName: '<<ddlx>>',
                    options: [
                        {
                            id: 'ORDINARY',
                            name: '<<ptdd_1>>',
                        },
                        {
                            id: 'PRESALE',
                            name: '<<ysdd>>',
                        },
                        {
                            id: 'PRESALE_SHARE_STOCK',
                            name: '<<ysgxkcdd>>',
                        },
                        {
                            id: 'CGB',
                            name: '<<sqtgdd>>',
                        },
                        {
                            id: 'GROUP_BUYING',
                            name: '<<ptdd>>',
                        },
                        {
                            id: 'RESERVE',
                            name: '<<yyqgdd>>',
                        },
                        {
                            id: 'INTERNAL_PURCHASE',
                            name: '<<qynghddd>>',
                        },
                        {
                            id: 'BLIND_BOX',
                            name: '<<mhdd>>',
                        },
                    ],
                },
                memberMobile: {
                    type: 'string',
                    displayName: '<<member>>',
                },
                receiverName: {
                    type: 'string',
                    displayName: '<<shr>>',
                },
                receiverMobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                deliveryMode: {
                    type: 'string.options.select',
                    displayName: '<<deliveryType>>',
                    options: [
                        {
                            id: 'EXPRESS_DELIVERY',
                            name: '<<kd_2>>',
                        },
                        {
                            id: 'SELF_MENTION',
                            name: '<<zt>>',
                        },
                        {
                            id: 'CITYWIDE_DELIVERY',
                            name: '<<tcp>>',
                        },
                    ],
                },
                deliveryDate: {
                    type: 'string',
                    displayName: '<<sd_1>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<xdsj>>',
                },
                remark: {
                    type: 'string.popover',
                    displayName: '<<hybz>>',
                },
            },
        },
    },
    components: {
        OrderPickingAssistantPort: {
            component: 'Viewport',
        },
        OrderTabs: {
            component: 'OrderPickingTabs',
            entity: 'storeSelect',
            items: {
                waitingPick: 'OrderWaitingPickPane',
                picking: 'OrderPickingPane',
                finishPick: 'OrderPickedPane',
                cancelled: 'OrderCanceledPane',
                all: 'OrderAllPane',
                waitingDelivery: 'OrderWaitingDeliveryPane',
            },
        },
        OrderPickingAssistantPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'OrderTabs',
                },
            ],
        },
        OrderWaitingPickPane: {
            component: 'OrderTabsInterval',
            switchRefresh: true,
            entity: 'PickOrder',
            content: {
                component: 'DataList',
                renderItem: 'OrderPickItem',
                loadFirstPage: false,
                split: false,
                header: {
                    type: 'component',
                    component: 'BatchPrintPickOrder',
                },
                rowSelection: {
                    privilege: {
                        path: 'orderExamine',
                        level: 'full',
                    },
                },
                className: 'order-waiting-pick',
                pagination: {
                    pageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '15', '20'],
                    showTotal(total: any) {
                        return services.interpolate(services.language.getText('total'), { total });
                    },
                },
            },
        },
        OrderPickingPane: {
            component: 'OrderTabsInterval',
            switchRefresh: true,
            entity: 'PickOrder',
            content: {
                component: 'DataList',
                renderItem: 'OrderPickItem',
                loadFirstPage: false,
                className: 'order-picking',
                pagination: {
                    pageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '15', '20'],
                    showTotal(total: any) {
                        return services.interpolate(services.language.getText('total'), { total });
                    },
                },
            },
        },
        OrderPickedPane: {
            component: 'OrderTabsInterval',
            entity: 'PickOrder',
            switchRefresh: true,
            content: {
                component: 'DataList',
                loadFirstPage: false,
                className: 'order-waiting-pick',
                split: false,
                header: {
                    type: 'component',
                    component: 'OrderLackSwitch',
                },
                pagination: {
                    pageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '15', '20'],
                    showTotal(total: number) {
                        return services.interpolate(services.language.getText('total'), { total });
                    },
                },
                renderItem: 'OrderPickItem',
            },
        },
        OrderCanceledPane: {
            component: 'OrderTabsInterval',
            entity: 'PickOrder',
            switchRefresh: true,
            content: {
                component: 'DataList',
                loadFirstPage: false,
                className: 'order-picking',
                split: false,
                pagination: {
                    pageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '15', '20'],
                    showTotal(total: any) {
                        return services.interpolate(services.language.getText('total'), { total });
                    },
                },
                renderItem: 'OrderPickItem',
            },
        },
        OrderAllPane: {
            component: 'OrderTabsInterval',
            entity: 'PickOrder',
            switchRefresh: true,
            content: {
                component: 'DataList',
                loadFirstPage: false,
                className: 'order-waiting-pick',
                split: false,
                pagination: {
                    pageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '15', '20'],
                    showTotal(total: any) {
                        return services.interpolate(services.language.getText('total'), { total });
                    },
                },
                renderItem: 'OrderPickItem',
            },
        },
        OrderWaitingDeliveryPane: {
            component: 'OrderTabsInterval',
            entity: 'waitingForDeliveryEntity',
            switchRefresh: true,
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'OrderWaitingDeliveryFilter',
                    },
                    { component: 'OrderWaitingDeliveryTable' },
                ],
            },
        },
        OrderWaitingDeliveryFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'orderNo',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        OrderWaitingDeliveryTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            scroll: {
                x: 1000,
            },
            columns: [
                {
                    property: 'subsiteName',
                    width: 120,
                },
                {
                    property: 'merchantName',
                    width: 120,
                },
                {
                    property: 'orderNo',
                    width: 240,
                    displayConfig: {
                        target: '_blank',
                    },
                },
                {
                    property: 'orderType',
                    width: 150,
                },
                {
                    property: 'memberMobile',
                    width: 150,
                },
                {
                    property: 'receiverName',
                    width: 150,
                },
                {
                    property: 'receiverMobile',
                    width: 150,
                },
                {
                    property: 'deliveryMode',
                    width: 150,
                },
                {
                    property: 'deliveryDate',
                    width: 180,
                },
                {
                    property: 'createTime',
                    width: 200,
                },
                {
                    property: 'remark',
                    width: 180,
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 100,
                items: [
                    {
                        type: 'component',
                        component: 'DeliverButton',
                    },
                ],
            },
        },
        PickingListInfoPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'PickingListContent',
                },
            ],
        },
        PickingListContent: {
            component: 'PickingListInfo',
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/order-picking-assistant',
                    component: 'OrderPickingAssistantPort',
                    breadcrumbName: '<<pickingList>>',
                    privilege: {
                        path: 'pickingList',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/picking-list-info/:id',
                            component: 'PickingListInfoPage',
                            breadcrumbName: '<<jhdxq>>',
                        },
                        {
                            path: '/:status',
                            component: 'OrderPickingAssistantPage',
                        },
                        {
                            path: '/',
                            component: 'OrderPickingAssistantPage',
                        },
                    ],
                },
            ],
        },
    ],
};
