import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        RestaurantReservationEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/restaurant_reservation',
            filters: {
                subsiteId: {
                    type: 'string.options.select',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 200 },
                    },
                },
                merchantId: {
                    type: 'string.filterOptions.select',
                    displayName: '<<merchant>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 200 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                    },
                },
                reservationUserMobile: {
                    type: 'string',
                    displayName: '<<ydrsjh>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                useUserMobile: {
                    type: 'string',
                    displayName: '<<ycrsjh>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                reservationDateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<ycsj>>',
                    controlConfig: { style: { width: 300 } },
                },
                createDateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<xdsj>>',
                    controlConfig: { style: { width: 300 } },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<ydzt>>',
                    options: [
                        { id: 'CREATE', name: '<<dqr>>' },
                        { id: 'AUDITED', name: '<<yyd>>' },
                        { id: 'CANCELED', name: '<<yqx>>' },
                    ],
                },
                reservationUserName: {
                    type: 'string',
                    displayName: '<<ydrxm>>',
                },
                reservationUserMobile: {
                    type: 'string',
                    displayName: '<<ydrsjh>>',
                },
                totalCount: {
                    type: 'string',
                    displayName: '<<ycrs>>',
                },
                reservationTime: {
                    type: 'string',
                    displayName: '<<ycsj>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<xdsj>>',
                },
                spaceType: {
                    type: 'string.options.select',
                    displayName: '<<ydwz>>',
                    options: [
                        { id: 'HALL', name: '<<hall>>' },
                        { id: 'BOX', name: '<<privateRoom>>' },
                    ],
                },
                location: {
                    type: 'string',
                    displayName: '<<wzmc>>',
                },
                useUserName: {
                    type: 'string',
                    displayName: '<<ycrxm>>',
                },
                useUserMobile: {
                    type: 'string',
                    displayName: '<<ycrsjh>>',
                },
                remark: {
                    type: 'string.popover',
                    displayName: '<<bzxx>>',
                },
                auditRemark: {
                    type: 'string.popover',
                    displayName: '<<spyy>>',
                },
            },
        },
    },
    components: {
        RestaurantReservationView: {
            component: 'Viewport',
            entity: 'RestaurantReservationEntity',
        },
        RestaurantReservationPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'RestaurantReservationFilter',
                },
                {
                    component: 'RestaurantReservationAddButton',
                },
                { component: 'RestaurantReservationTable' },
            ],
        },
        RestaurantReservationFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'reservationUserMobile',
                },
                {
                    property: 'useUserMobile',
                },
                {
                    property: 'reservationDateRange',
                },
                {
                    property: 'createDateRange',
                },
            ],
        },
        RestaurantReservationTablesPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'tabs-content-viewport',
            items: [{ component: 'RestaurantReservationTables' }],
        },
        RestaurantReservationTables: {
            component: 'TabsPlus',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<all>>',
                    content: {
                        component: 'RestaurantReservationPage',
                    },
                    params: { status: '' },
                },
                {
                    title: '<<dqr>>',
                    content: {
                        component: 'RestaurantReservationPage',
                    },
                    params: { status: 'CREATE' },
                },
                {
                    title: '<<yyd>>',
                    content: {
                        component: 'RestaurantReservationPage',
                    },
                    params: { status: 'AUDITED' },
                },
                {
                    title: '<<yqx>>',
                    content: {
                        component: 'RestaurantReservationPage',
                    },
                    params: { status: 'CANCELED' },
                },
            ],
        },
        RestaurantReservationTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'subsiteName',
                },
                {
                    property: 'merchantName',
                },
                {
                    property: 'status',
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'reservationUserName',
                },
                {
                    property: 'reservationUserMobile',
                },
                {
                    property: 'totalCount',
                },
                {
                    property: 'reservationTime',
                },
                {
                    property: 'spaceType',
                },
                {
                    property: 'location',
                },
                {
                    property: 'useUserName',
                },
                {
                    property: 'useUserMobile',
                },
                {
                    property: 'remark',
                    displayConfig: {
                        style: {
                            maxWidth: '100px',
                        },
                    },
                },
                {
                    property: 'auditRemark',
                    displayConfig: {
                        style: {
                            maxWidth: '100px',
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 160,
                items: [
                    {
                        type: 'component',
                        component: 'RestaurantReservationListItemButtons',
                    },
                ],
            },
        },
        RestaurantReservationLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SHOP`,
                    apiPath: '/admin/restaurant_reservation_orders/{id}/operation_logs',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/restaurant-reservation',
                    component: 'RestaurantReservationView',
                    breadcrumbName: '<<reservation>>',
                    privilege: {
                        path: 'backEndRestaurantReservation',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/logs/:id',
                            component: 'RestaurantReservationLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        { path: '/', component: 'RestaurantReservationTablesPage' },
                    ],
                },
            ],
        },
    ],
};
