import React, { PureComponent } from 'react';
import { Table, InputNumber, Input, Checkbox } from 'antd';
import { TableProps } from 'antd/lib/table';
import { cloneDeep } from 'lodash';
import { TipsModal } from '../../../../components';
import { services } from '@comall-backend-builder/core';

const { getText } = services.language;

export enum OrderMergeTabSequencesItemType {
    /**
     * 购物小票
     */
    ShoppingReceipt = 'SHOPPING_RECEIPT',
    /**
     * 停车缴费
     */
    Parking = 'PARKING',
    /**
     * 卡券订单
     */
    SaleCoupon = 'SALE_COUPON',
    /**
     * 积分兑换
     */
    CreditEshop = 'CREDIT_ESHOP',
    /**
     * 商城活动
     */
    MallActivityNew = 'MALL_ACTIVITY_NEW',
    /**
     * 服务预约
     */
    ServiceReservedActivity = 'SERVICE_RESERVED_ACTIVITY',
    /**
     * 实物订单
     */
    Order = 'ORDER',
    /**
     * 订金订单
     */
    DepositOrder = 'DEPOSIT_ORDER',
    /**
     * 储值卡订单
     */
    StoredValueCard = 'STORED_VALUE_CARD',
}

interface DataItem {
    name: string;
    orderType: OrderMergeTabSequencesItemType;
    sequence?: number;
    status?: boolean;
    alias?: string;
}

interface OrderMergeTabSequencesData {
    id: number;
    name: string;
    code: string;
    value: Array<DataItem>;
}
interface OrderMergeTabSequencesProp {
    onChange: (value: OrderMergeTabSequencesData, name: string) => void;
    value: OrderMergeTabSequencesData;
    row: any;
    name: string;
    tipsModalConfig: any;
}
export class OrderMergeTabSequences extends PureComponent<OrderMergeTabSequencesProp, any> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    changeValue = (list: Array<DataItem>) => {
        const { onChange, name, value } = this.props;
        onChange({ ...value, value: list }, name);
    };

    cloneDataList = () => {
        const { value } = this.props;
        if (!value?.value.length) {
            return [];
        }
        return cloneDeep(value.value);
    };

    changeRowField<T extends keyof DataItem>(
        orderType: OrderMergeTabSequencesItemType,
        field: T,
        fieldValue: DataItem[T]
    ) {
        const newDataList = this.cloneDataList();
        const item = newDataList.find((i) => i.orderType === orderType);
        if (!item) {
            return;
        }
        item[field] = fieldValue;
        this.changeValue(newDataList);
    }

    private get tableProps(): TableProps<DataItem> | null {
        const { value: data } = this.props;
        if (!data) {
            return null;
        }
        const config: TableProps<DataItem> = {
            dataSource: data.value,
            pagination: false as const,
            rowKey: 'orderType',
            columns: [
                {
                    title: services.language.getText('display'),
                    dataIndex: 'status',
                    render: (status, { orderType }) => (
                        <Checkbox
                            onChange={(e) =>
                                this.changeRowField(orderType, 'status', e.target.checked)
                            }
                            checked={status}
                        />
                    ),
                },
                {
                    title: getText('ddlx'),
                    dataIndex: 'name',
                },
                {
                    title: getText('qzz'),
                    dataIndex: 'sequence',
                    render: (sequence, { orderType }) => (
                        <InputNumber
                            placeholder={services.language.getText('inputPlease')}
                            value={sequence}
                            style={{ width: '120px' }}
                            min={1}
                            max={999999999}
                            precision={0}
                            onChange={(value) => this.changeRowField(orderType, 'sequence', value)}
                        ></InputNumber>
                    ),
                },
                {
                    title: getText('zdymc'),
                    dataIndex: 'alias',
                    render: (customName, { orderType }) => (
                        <Input
                            placeholder={services.language.getText('inputPlease')}
                            value={customName}
                            style={{ width: '150px' }}
                            maxLength={6}
                            onChange={(e) =>
                                this.changeRowField(orderType, 'alias', e.target.value)
                            }
                        ></Input>
                    ),
                },
            ],
        };
        config.columns?.forEach((column) => {
            column.key = column.dataIndex;
        });
        return config;
    }

    render() {
        const { tipsModalConfig } = this.props;
        return (
            <div style={{ width: '600px' }}>
                <Table {...this.tableProps}></Table>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <p style={{ color: '#ccc', margin: 0, marginRight: '10px' }}>
                        {getText('qzzszyd')}
                    </p>
                    {tipsModalConfig && <TipsModal {...tipsModalConfig} />}
                </div>
            </div>
        );
    }
}
