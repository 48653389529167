import React, { PureComponent } from 'react';
import { message as AntMessage } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { isEmpty } from 'lodash';
import { StatisticType } from '../../config/promotion/market/miniprogram-v2';
import { EntityButton, EntityButtonProps } from '../../components';
import { services } from '@comall-backend-builder/core';

export class MiniprogramCopyOrDownloadButton extends PureComponent<any, any> {
    render() {
        const { row } = this.props;
        let disabled = isEmpty(row.merchantPathUrl);
        const entityButtonProps = {
            props: {
                type: 'link',
                style: {
                    paddingLeft: 0,
                },
                children: services.language.getText('fzlj'),
                onClick(_: any, entityButtonProps: EntityButtonProps) {
                    let input = document.createElement('input');
                    input.value = `/subpackages/miniprogram-code/index?mid=${row.id}`;
                    document.body.appendChild(input);
                    input.select();
                    if (document.execCommand('Copy')) {
                        AntMessage.success(services.language.getText('ljdzyfz_1'));
                    } else {
                        AntMessage.warning(services.language.getText('ljdzfzsb'));
                    }
                    document.body.removeChild(input);
                },
            },
        };
        const downloadProps = {
            props: {
                type: 'link',
                children: services.language.getText('downloadPath'),
                style: {
                    paddingLeft: 0,
                },
                disabled,
            },
            propsHandler(
                buttonProps: ButtonProps,
                entityButtonProps: EntityButtonProps
            ): ButtonProps {
                return {
                    ...buttonProps,
                    download: `${row.name}_${row.id}`,
                    href: row.merchantPathUrl,
                };
            },
        };
        const props: any =
            row.statisticType === StatisticType.MERCHANT ? downloadProps : entityButtonProps;

        return <EntityButton {...props} />;
    }
}
