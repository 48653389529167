import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { get, isEmpty } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;
export const PhotoCreditReviewLoader: Loader = {
    get: async function(data, config) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`;
        config.apiPath = '/admin/photo_crediting';
        if (data.status === 'ALL') {
            delete data.status;
        }
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        if (data.subsiteName && data.subsiteName.id) {
            data.subsiteId = data.subsiteName.id;
            delete data.subsiteName;
        }
        return await api.get(data, config).then((res: any) => {
            res.result =
                res &&
                res.result.map((item: any) => {
                    let statusText = '';
                    if (item.status === 'PROCESSING') {
                        statusText = '等待审核';
                        item.reason = '等待审核';
                    } else if (item.status === 'SUCCESS') {
                        statusText = '通过审核';
                        item.reason = '已通过审核';
                    } else if (item.status === 'FAIL') {
                        statusText = '未通过审核';
                    } else if (item.status === 'APPROVED') {
                        statusText = '执行成功';
                    } else if (item.status === 'EXCEPTION') {
                        statusText = language.getText('zxsb');
                    }
                    item.statusText = statusText;
                    item.imgUrl = item.image && item.image.imgUrl;

                    return item;
                });

            return res;
        });
    },
    put: async function(params, config) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`;
        config.apiPath = '/admin/v2/photo_crediting';
        const newParams = {
            ...params,
            subsiteId: get(params, 'subsiteId[0].subSiteId'),
            merchantCode: get(params, 'subsiteId[0].code'),
        };

        return await api.put(newParams, config);
    },
};
