import * as React from 'react';

import { ArrayMode } from '@comall-backend-builder/types';
import { MerchantBarCode } from './merchant-bar-code';

/**
 *  管理专柜商家码-商品条形
 */
export class ArrayMerchantBarCodeMode extends ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <MerchantBarCode {...controlInfo} />;
    }
}
