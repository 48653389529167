import React, { Fragment, PureComponent } from 'react';
import { Button } from '@comall-backend-builder/components-basis';
import { api, errorHandle } from '@comall-backend-builder/core/lib/services';
import { Modal, Select } from 'antd';
import { services } from '@comall-backend-builder/core';

const { getText } = services.language;

const { Option } = Select;
export class ExportCondition extends PureComponent<any> {
    state = { visible: false, winnerType: '', state: '' };

    openExportModal = () => {
        this.setState({ visible: true });
    };
    closeExportModal = () => {
        this.setState({ visible: false, winnerType: '', state: '' });
    };
    exportBill = () => {
        const { winnerType, state } = this.state;
        const { entity } = this.props;
        const params: any = Object.assign({}, entity.params, entity.filters);

        params.winnerType = winnerType;
        params.state = state;

        api.download(params, {
            apiRoot: this.props.params.apiRoot,
            apiPath: this.props.params.apiPath,
        }).catch(errorHandle);
    };
    changewinnerType = (winnerType: any) => {
        this.setState({ winnerType });
    };
    handleChange = (state: any) => {
        this.setState({ state });
    };
    render() {
        const { visible, winnerType, state } = this.state;

        return (
            <Fragment>
                <div className="export-button">
                    <Button
                        text={getText('dchddd')}
                        type="primary"
                        onClick={this.openExportModal}
                    />
                </div>
                <Modal
                    visible={visible}
                    width={500}
                    title={getText('dcdd')}
                    onOk={this.exportBill}
                    onCancel={this.closeExportModal}
                    cancelText={getText('back')}
                    okText={getText('dc')}
                >
                    <div className="export">
                        <div style={{ marginBottom: 20 }}>
                            {getText('zqrlx')}：
                            <Select
                                placeholder={getText('selectPlease')}
                                style={{ width: 200 }}
                                onChange={this.changewinnerType}
                                value={winnerType}
                            >
                                <Option value="NORMAL">{getText('ptzq')}</Option>
                                <Option value="WHITE_LIST">{getText('bmdzq')}</Option>
                            </Select>
                        </div>
                        <div>
                            {getText('ddzt')}：
                            <Select
                                placeholder={getText('selectPlease')}
                                style={{ width: 215 }}
                                value={state}
                                onChange={this.handleChange}
                            >
                                <Option value="1">{getText('dfk')}</Option>
                                <Option value="2">{getText('dfh')}</Option>
                                <Option value="3">{getText('fhwtg')}</Option>
                                <Option value="4">{getText('dfh_1')}</Option>
                                <Option value="6">{getText('yfh_1')}</Option>
                                <Option value="7">{getText('yqs')}</Option>
                                <Option value="8">{getText('yqxygb')}</Option>
                            </Select>
                        </div>
                    </div>
                </Modal>
            </Fragment>
        );
    }
}
