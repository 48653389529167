import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { getUrlParam } from '../../applications/cae/services/utils';

function getDefaultValue() {
    const subsiteId = getUrlParam(window.location.hash, 'subsiteId');
    return subsiteId
        ? {
              id: subsiteId,
          }
        : {};
}

export const config: Config = {
    entities: {
        MerchantBuyLimitEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: '/loader/admin/buy_limit_rules',
            paramsFilter: ['MERCHANT'],
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 200 } },
                },
                merchantId: {
                    // type: 'string.filterOptions.select',
                    type: 'string.options.autoComplete',
                    displayName: '<<merchantName>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 200 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                merchants: {
                    type: 'array.popover',
                    displayName: '<<applyMerchants>>',
                },
                deliveryModes: {
                    type: 'array.options.autoComplete.properties',
                    displayName: '<<deliveryType>>',
                    options: [],
                    controlConfig: {
                        source: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                            apiPath: '/admin/delivery_modes',
                        },
                        placeholder: '<<selectPlease>>',
                        style: { width: 450 },
                        dependences: 'merchantIds',
                        dependencesProperties: 'merchantId',
                        paramsName: 'merchantIds',
                    },
                    rules: [{ required: true }],
                },
                value: {
                    type: 'number.tip',
                    displayName: '<<qsje>>',
                    rules: [
                        {
                            required: true,
                            type: 'number',
                            min: 0,
                            precision: 2,
                            message: '<<priceErrorMessage>>',
                            validator: (rule: any, value: any) => {
                                var reg = /^(?!0+$)(?!0*\.0*$)\d{1,8}(\.\d{1,2})?$/;
                                if (value) {
                                    if (Number(value) >= 0.01 && reg.test(value)) {
                                        return true;
                                    } else {
                                        return false;
                                    }
                                }
                                return true;
                            },
                        },
                    ],
                    controlConfig: {
                        style: { width: 200 },
                        addonafter: '<<yuan>>',
                        tip: '<<ddbmqsje>>',
                    },
                    extra: '<<qyngdspbzczxqsgz>>',
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<status>>',
                },
                tip: {
                    type: 'string.text.paragraph',
                    displayName: '<<qstsy>>',
                    extra: '<<xtmrtsw>>',
                    controlConfig: {
                        style: { width: 450 },
                    },
                },
                merchantIds: {
                    type: 'array.shop.selector',
                    displayName: '<<applyMerchants>>',
                    defaultValue: [],
                    rules: [
                        {
                            message: '<<qxzsyzj>>',
                            validator: (rule: any, value: any) => {
                                if (value && value.length > 0) {
                                    return true;
                                } else {
                                    return false;
                                }
                            },
                        },
                    ],
                },
            },
        },
    },
    components: {
        MerchantBuyLimitView: {
            component: 'Viewport',
            entity: 'MerchantBuyLimitEntity',
        },
        MerchantBuyLimitPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'MerchantBuyLimitFilter',
                },
                { component: 'MerchantBuyLimitTable' },
            ],
        },
        MerchantBuyLimitFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                    defaultValue: getDefaultValue(),
                },
                {
                    property: 'merchantId',
                },
            ],
        },
        MerchantBuyLimitTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'Button',
                    text: '<<xzqsgz>>',
                    route: '/merchant-buy-limit/add',
                    type: 'primary',
                    icon: 'plus',
                    style: {
                        width: 140,
                    },
                    className: 'mt24 mb24',
                },
                {
                    component: 'DataTable',
                    componentId: 'MerchantBuyLimitDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'deliveryModes',
                        },
                        {
                            property: 'value',
                        },
                        {
                            property: 'merchants',
                            displayConfig: {
                                countSuffix: '<<merchant>>',
                            },
                        },
                        {
                            property: 'status',
                            displayConfig: {
                                statusConfig: {
                                    true: {
                                        text: '<<enabled>>',
                                        status: 'success',
                                    },
                                    false: {
                                        text: '<<beforeClose>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: false },
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                            apiPath: '/admin/buy_limit_rules/:id/disabled',
                                            config: {
                                                content: '<<gzybgb>>',
                                                text: '<<common.close>>',
                                                style: { color: 'red' },
                                            },
                                            confirm: {
                                                text: '<<qrgbgz>>?',
                                                className: 'confirm-content',
                                            },
                                        },
                                        {
                                            params: { status: true },
                                            value: false,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                            apiPath: '/admin/buy_limit_rules/:id/enabled',
                                            config: {
                                                content: '<<gzybkq>>',
                                                text: '<<common.opend>>',
                                            },
                                            confirm: {
                                                text: '<<isAreadyOpend>><<rule>>?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                text: '<<common.edit>>',
                                type: 'link',
                                path: '/merchant-buy-limit/edit/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        MerchantBuyLimitAddPage: {
            component: 'FlexLayout',
            entity: 'MerchantBuyLimitEntity',
            direction: 'horizontal',
            items: [{ component: 'MerchantBuyLimitAddForm' }],
        },
        MerchantBuyLimitAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'MerchantBuyLimitEntity',
            labelCol: 4,
            controlCol: 20,
            style: { width: 950 },
            fields: [
                {
                    property: 'merchantIds',
                    controlConfig: {
                        fieldParams: { isContainsSelfMerchant: false },
                    },
                },
                { property: 'deliveryModes' },
                { property: 'value' },
                // { property: 'tip' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        MerchantBuyLimitEditPage: {
            component: 'FlexLayout',
            entity: 'MerchantBuyLimitEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'MerchantBuyLimitEditForm' }],
        },
        MerchantBuyLimitEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'MerchantBuyLimitEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            style: { width: 950 },
            fields: [
                {
                    property: 'merchantIds',
                    controlConfig: {
                        fieldParams: { isContainsSelfMerchant: false },
                    },
                },
                { property: 'deliveryModes' },
                { property: 'value' },
                // { property: 'tip' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/merchant-buy-limit',
                    component: 'MerchantBuyLimitView',
                    breadcrumbName: '<<merchantBuyLimitRule>>',
                    privilege: {
                        path: 'merchantBuyLimitRule',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'MerchantBuyLimitEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        {
                            path: '/add',
                            component: 'MerchantBuyLimitAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        { path: '/', component: 'MerchantBuyLimitPage' },
                    ],
                },
            ],
        },
    ],
};
