import React, { PureComponent } from 'react';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { DesignPage } from '@comall-backend-builder/design-components-basis';
import { CustomComponentSelector } from '../../design/components/custom-component-selector';
import { CustomComponentSelectorMerchant } from '../../design/components/custom-component-selector-merchant';
import { WfjIndexComponentSelector } from '../../design/components/wfj-index-component-selector';
import { DESIGN_PAGE_LINK_TYPES_LIST } from '../../constants/page-design';
import { errorHandle } from '../../../cae/services/error-handle';

const tenantDomain = ENV.TENANT_DOMAIN || window.location.origin;

const api = services.api;
/**
 * 可视化-页面模板设计
 */
export class PageTemplateDesign extends PureComponent<any> {
    loadProfile = () => {
        const {
            params: { id },
        } = this.props;
        return api.get(
            { id },
            {
                apiPath: '/admin/templates/:id',
            }
        );
    };

    onSave = (data: any) => {
        api.put(data, { apiPath: '/admin/templates/:id' })
            .then(() => {
                const saveSuccess = services.language.getText('common.saveSuccess');
                message.success(saveSuccess);
                services.navigation.goBack();
            })
            .catch(errorHandle);
    };
    onCancel = () => {
        services.navigation.goBack();
    };
    getClientUrl = (profile: any, fixed: boolean) => {
        const { client } = this.props;
        let url = client + `?page=WYSIWG&mode=edit`;
        if (profile) {
            url = url + `&type=${profile.metainfo.type}&tenant=${encodeURIComponent(tenantDomain)}`;
        }
        return url;
    };
    render() {
        const submitProps = {
            onClick: this.onSave,
        };
        const cancelProps = {
            onClick: this.onCancel,
        };

        const {
            params: { type },
        } = this.props;

        if (type === 'merchant') {
            return (
                <DesignPage
                    clientUrl={this.getClientUrl}
                    loadProfile={this.loadProfile}
                    componentsSelector={(props) => <CustomComponentSelectorMerchant {...props} />}
                    submit={submitProps}
                    cancel={cancelProps}
                    verbose={process.env.NODE_ENV === 'development'}
                    isTemplate={true}
                    linkTypes={DESIGN_PAGE_LINK_TYPES_LIST}
                    linkTypeMode={'cascader'}
                    uploadAction={`${ENV.API_ROOT}/admin/images/upload`}
                ></DesignPage>
            );
        }

        if (type === 'home' && ENV.TENANT === 'wfj') {
            return (
                <DesignPage
                    clientUrl={this.getClientUrl}
                    loadProfile={this.loadProfile}
                    componentsSelector={(props) => <WfjIndexComponentSelector {...props} />}
                    submit={submitProps}
                    cancel={cancelProps}
                    verbose={process.env.NODE_ENV === 'development'}
                    isTemplate={false}
                    linkTypes={DESIGN_PAGE_LINK_TYPES_LIST}
                    linkTypeMode={'cascader'}
                    uploadAction={`${ENV.API_ROOT}/admin/images/upload`}
                ></DesignPage>
            );
        }

        const {
            params: { id },
            apiPath,
        } = this.props;
        return (
            <DesignPage
                clientUrl={this.getClientUrl}
                loadProfile={this.loadProfile}
                componentsSelector={(props) => (
                    <CustomComponentSelector {...props} apiPath={apiPath} currentPageID={id} />
                )}
                submit={submitProps}
                cancel={cancelProps}
                isTemplate={true}
                verbose={process.env.NODE_ENV === 'development'}
                linkTypes={DESIGN_PAGE_LINK_TYPES_LIST}
                linkTypeMode={'cascader'}
                uploadAction={`${ENV.API_ROOT}/admin/images/upload`}
            ></DesignPage>
        );
    }
}
