import React, { PureComponent, createElement } from 'react';
import { defaultsDeep, set, assign } from 'lodash';
import { Modal, message as AntMessage } from 'antd';
import { builder, actions, services } from '@comall-backend-builder/core';
import { ComponentFactory } from '@comall-backend-builder/core/lib/parser/component';
const language = services.language;

let SELECTED_ROW: any = [];
const getConfig = (componentName: string, newTopic?: boolean) => {
    const entity = newTopic ? 'NewCustomDesignTopicSelector' : 'CustomDesignTopicSelector';
    return {
        component: 'Card',
        componentId: componentName,
        params: {
            subsiteIds: '',
        },
        entity,
        content: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'FilterForm',
                    componentId: componentName + '-FilterForm',
                    direction: 'inline',
                    fields: [
                        {
                            property: 'channels',
                        },
                        {
                            property: 'name',
                        },
                    ],
                    submit: {
                        icon: 'search',
                        text: '',
                    },
                    style: {
                        marginBottom: 16,
                        float: 'right',
                    },
                },
                {
                    component: 'DataTable',
                    componentId: componentName + '-table',
                    loadFirstPage: true,
                    rowSelection: {
                        type: 'radio',
                        onChange: () => {},
                        onSelect: function() {
                            SELECTED_ROW = arguments[2];
                        },
                    },
                    pagination: {
                        pageSize: 10,
                    },
                    columns: [
                        {
                            property: 'name',
                        },
                        {
                            property: 'channels',
                        },
                    ],
                },
            ],
        },
    };
};

function generateDynamicSubsiteComponentName() {
    return 'Design-Topic-Panel-' + parseInt(Math.random() * 1e8 + '').toString(16);
}

function initDynamicComponent(
    contentConfig: any,
    componentName: any,
    newTopic?: boolean,
    subsiteIds?: string[]
) {
    let config = defaultsDeep(contentConfig, getConfig(componentName, newTopic));
    set(config, 'params.subsiteIds', subsiteIds);
    set(config, 'content.items[1].componentId', componentName + '-table');
    set(config, 'content.items[1].rowSelection', {
        type: 'radio',
        onChange: () => {},
        onSelect: function() {
            SELECTED_ROW = arguments[2];
        },
        onSelectAll: function() {
            SELECTED_ROW = arguments[2];
        },
    });

    return ComponentFactory(componentName, config);
}

/**
 * 可视化-配置链接-选择专题
 */

export class SelectTopic extends PureComponent<{
    contentConfig?: Record<string, any>;
    modalConfig?: Record<string, any>;
    onChange: (data: any) => void;
    onCancel: () => void;
    showModal: boolean;
    newTopic?: boolean;
    subsiteIds?: string[];
}> {
    renderComponent: any = null;
    dispatch: any = null;
    subsiteComponentName = '';
    constructor(props: any) {
        super(props);
        const { contentConfig, newTopic, subsiteIds } = props;
        this.subsiteComponentName = generateDynamicSubsiteComponentName();
        const dynamicComponent = initDynamicComponent(
            contentConfig,
            this.subsiteComponentName,
            newTopic,
            subsiteIds
        );
        this.renderComponent = createElement(dynamicComponent);
        this.dispatch = builder.getStore().dispatch;
    }
    componentWillReceiveProps(nextProps: any) {
        const { subsiteIds } = this.props;
        if (nextProps?.subsiteIds?.join(',') !== subsiteIds?.join(',')) {
            const { contentConfig, newTopic, subsiteIds } = nextProps;
            this.subsiteComponentName = generateDynamicSubsiteComponentName();
            const dynamicComponent = initDynamicComponent(
                contentConfig,
                this.subsiteComponentName,
                newTopic,
                subsiteIds
            );
            this.renderComponent = createElement(dynamicComponent);
        }
    }
    onOk = () => {
        if (!SELECTED_ROW.length) {
            AntMessage.error(language.getText('components.Tools.topicSelectRequired'));
            return;
        }

        const { onChange } = this.props;
        let linkParams = {};
        if (SELECTED_ROW.length) {
            const row = SELECTED_ROW[0];
            linkParams = {
                id: row['id'],
                name: row['name'],
                // subsites: JSON.stringify(row.subsites),
            };
        }
        onChange(linkParams);

        SELECTED_ROW = [];
        this.dispatch(actions.tableRowSelectionChangeAction('DesignTopicDataTable', []));
    };

    onCancel = () => {
        const { onCancel } = this.props;
        onCancel();
    };

    render() {
        const { modalConfig = {}, showModal } = this.props;
        let props = {
            onCancel: this.onCancel,
            onOk: this.onOk,
        };
        assign(
            props,
            { width: 800, visible: showModal, title: services.language.getText('qxzzt') },
            modalConfig
        );
        return <Modal {...props}>{this.renderComponent}</Modal>;
    }
}
