import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { find, get } from 'lodash';

const api = services.api;
export const WfjLotteryCodeLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/lottery_code_activities';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/lottery_code_activities/${id}`;
        } else {
            data.eventType = 'LOTTERY_CODE';
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.id = res.activityVo.id;
                res.editExecuteStatus = res.activityVo.executeStatus;
                res.editLotteryStatus = res.pageShow.lotteryStatus;
                const normalConditions = res.activityVo.normalConditions;
                const subsiteConditions: any = find(normalConditions, {
                    normalConditionType: 'SUB_SITE',
                });
                res.baseInfo = {
                    name: res.activityVo.name,
                    dateRange: {
                        start: res.activityVo.startTime,
                        end: res.activityVo.endTime,
                    },
                    drawTime: res.pageShow.drawTime,
                    description: res.activityVo.description,
                    remark: res.activityVo.remark,
                    subsiteIds:
                        subsiteConditions && subsiteConditions.includeIds
                            ? subsiteConditions.includeIds.map((item: any) => ({
                                  id: item.id + '',
                                  name: item.name,
                              }))
                            : undefined,
                    shareContent:
                        res.activityVo.shareConfig && res.activityVo.shareConfig.shareContent
                            ? res.activityVo.shareConfig.shareContent
                            : '',
                    shareAssistContent:
                        res.activityVo.shareConfig && res.activityVo.shareConfig.shareAssistContent
                            ? res.activityVo.shareConfig.shareAssistContent
                            : '',
                };
                const giveConditions: any = find(res.activityVo.exchangeConditions, {
                    exchangeMode: 'GIVE',
                });
                const shareConditions: any = find(res.activityVo.exchangeConditions, {
                    exchangeMode: 'SHARE',
                });
                const pointConditions: any = find(res.activityVo.exchangeConditions, {
                    exchangeMode: 'POINT_CONSUME',
                });
                const shareAssistConditions: any = find(res.activityVo.exchangeConditions, {
                    exchangeMode: 'SHARE_ASSIST',
                });
                const assistConditions: any = find(res.activityVo.exchangeConditions, {
                    exchangeMode: 'ASSIST',
                });
                res.numberInfo = {
                    activityRewardLimit: giveConditions ? giveConditions.exchangeLimit : '',
                    shareRewardLimit: shareConditions ? shareConditions.exchangeLimit : '',
                    shareAssistLimit: shareAssistConditions
                        ? shareAssistConditions.exchangeLimit
                        : '',
                    assistLimit: assistConditions ? assistConditions.exchangeLimit : '',
                    pointRule: pointConditions ? 'YES' : 'NO',
                    point:
                        pointConditions && pointConditions.consumeValue
                            ? pointConditions.consumeValue
                            : '',
                    pointExchangeLimit:
                        pointConditions && pointConditions.exchangeLimit
                            ? pointConditions.exchangeLimit
                            : '',
                };

                if (get(res, 'activityVo.rewardConfig.activityRewards[0].assetRewards.length')) {
                    const assetReward =
                        res.activityVo.rewardConfig.activityRewards[0].assetRewards[0];
                    res.prizeInfo = {
                        name: assetReward.name,
                        quantity: assetReward.quantity,
                        type: [{ checked: 'CUSTOM' }],
                    };
                    if (res.lottery.pageConfig) {
                        const { picture } = JSON.parse(res.lottery.pageConfig);
                        res.prizeInfo = {
                            ...res.prizeInfo,
                            picture: [
                                {
                                    id: picture.id || '',
                                    path: picture.url || '',
                                },
                            ],
                        };
                    } else {
                        res.prizeInfo = {
                            ...res.prizeInfo,
                            picture: [
                                {
                                    id: assetReward.picture.id || '',
                                    path: assetReward.picture.url || '',
                                },
                            ],
                        };
                    }
                }

                if (get(res, 'activityVo.rewardConfig.activityRewards[0].couponRewards.length')) {
                    const couponReward =
                        res.activityVo.rewardConfig.activityRewards[0].couponRewards[0];
                    res.prizeInfo = {
                        name: couponReward.name,

                        quantity: couponReward.quantity,
                        type: [
                            {
                                checked: 'COUPON',
                                assetRewards: [
                                    {
                                        rewardType: 'COUPON',
                                        rewardValue: [
                                            {
                                                ...couponReward,
                                                name: couponReward.couponName,
                                                id: couponReward.couponDefinitionId,
                                                quantity: couponReward.count,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    };
                    if (res.lottery.pageConfig) {
                        const { picture } = JSON.parse(res.lottery.pageConfig);
                        res.prizeInfo = {
                            ...res.prizeInfo,
                            picture: [
                                {
                                    id: picture.id || '',
                                    path: picture.url || '',
                                },
                            ],
                        };
                    } else {
                        res.prizeInfo = {
                            ...res.prizeInfo,
                            picture: [
                                {
                                    id: couponReward.picture.id || '',
                                    path: couponReward.picture.url || '',
                                },
                            ],
                        };
                    }
                }
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : services.language.getText('longTerm'),
                    };
                }
                res.otherInfo = {
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };
            } else {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        item.canExport =
                            item.lotteryStatus === 'REWARDED' && item.executeStatus === 'COMPLETE';
                        let lotteryStatusString = '';
                        if (item.lotteryStatus === 'REWARDED') {
                            lotteryStatusString = services.language.getText('ykj');
                        } else if (item.lotteryStatus === 'NOT_REWARDED') {
                            lotteryStatusString = '未开奖';
                        }
                        item.lotteryStatus = lotteryStatusString;
                        return item;
                    });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/lottery_code_activities';
        let newParams: any = {};
        if (params.baseInfo) {
            newParams.activityVo = {
                eventType: 'LOTTERY_CODE',
                name: params.baseInfo.name,
                startTime: params.baseInfo.dateRange.start,
                endTime: params.baseInfo.dateRange.end,
                description: params.baseInfo.description,
                remark: params.baseInfo.remark,
                normalConditions: [
                    {
                        all: false,
                        normalConditionType: 'SUB_SITE',
                        includeIds: params.baseInfo.subsiteIds,
                    },
                ],
                exchangeConditions: [
                    {
                        consumeValue: 0,
                        exchangeLimit: params.numberInfo.activityRewardLimit,
                        exchangeMode: 'GIVE',
                        normalConditionType: 'MEMBER_CARD_LEVEL',
                        period: 'ACTIVITY',
                        scopeId: -1,
                    },
                    {
                        consumeValue: 1,
                        exchangeLimit: params.numberInfo.shareRewardLimit,
                        exchangeMode: 'SHARE',
                        exchangeValue: 1,
                        normalConditionType: 'MEMBER_CARD_LEVEL',
                        period: 'ACTIVITY',
                        scopeId: -1,
                    },
                    {
                        consumeValue: 1,
                        exchangeLimit: params.numberInfo.shareAssistLimit,
                        exchangeMode: 'SHARE_ASSIST',
                        exchangeValue: 1,
                        normalConditionType: 'MEMBER_CARD_LEVEL',
                        period: 'ACTIVITY',
                        scopeId: -1,
                    },
                    {
                        consumeValue: 1,
                        exchangeLimit: params.numberInfo.assistLimit,
                        exchangeMode: 'ASSIST',
                        exchangeValue: 1,
                        normalConditionType: 'MEMBER_CARD_LEVEL',
                        period: 'ACTIVITY',
                        scopeId: -1,
                    },
                ],
            };
            if (params.numberInfo.pointRule === 'YES') {
                newParams.activityVo.exchangeConditions.push({
                    consumeValue:
                        params.numberInfo.pointRule === 'YES' ? params.numberInfo.point : 0,
                    exchangeLimit:
                        params.numberInfo.pointRule === 'YES'
                            ? params.numberInfo.pointExchangeLimit
                            : 0,
                    exchangeMode: 'POINT_CONSUME',
                    exchangeValue: 1,
                    normalConditionType: 'MEMBER_CARD_LEVEL',
                    period: 'ACTIVITY',
                    scopeId: -1,
                });
            }
            newParams.pageShow = {
                drawTime: params.baseInfo.drawTime,
            };
            newParams.activityVo.rewardConfig = {
                rewardRule: 'RANDOM',
            };
            newParams.activityVo.shareConfig = {
                shareContent: params.baseInfo.shareContent,
                shareAssistContent: params.baseInfo.shareAssistContent,
                sharePicture: undefined,
                status: true,
            };
        }
        if (params.prizeInfo) {
            var checked = params.prizeInfo.type[0].checked;
            var assetRewardsList = params.prizeInfo.type[0].assetRewards;
            if (checked === 'CUSTOM') {
                newParams.activityVo.rewardConfig.activityRewards = [
                    {
                        assetRewards: [
                            {
                                rewardType: 'CUSTOM',
                                rewardValue: 0,
                                name: params.prizeInfo.name,
                                picture: {
                                    id: params.prizeInfo.picture[0].id,
                                    url: params.prizeInfo.picture[0].path,
                                },
                                quantity: params.prizeInfo.quantity,
                            },
                        ],
                    },
                ];
            } else if (checked === 'COUPON') {
                // 仅支持单个商品
                const couponItem = assetRewardsList[0].rewardValue[0];
                newParams.activityVo.rewardConfig.activityRewards = [
                    {
                        couponRewards: [
                            {
                                count: couponItem.quantity ? couponItem.quantity : 1,
                                couponDefinitionId: couponItem.id,
                                name: params.prizeInfo.name,
                                picture: {
                                    id: params.prizeInfo.picture[0].id,
                                    url: params.prizeInfo.picture[0].path,
                                },
                                quantity: params.prizeInfo.quantity,
                            },
                        ],
                    },
                ];
            }
            const pageConfig = {
                picture: {
                    id: params.prizeInfo.picture[0].id,
                    url: params.prizeInfo.picture[0].path,
                },
            };
            newParams.lottery = {
                pageConfig: JSON.stringify(pageConfig),
            };
        }
        newParams.marketingScheduleId =
            (params.otherInfo?.marketingSchedule?.length &&
                params.otherInfo.marketingSchedule[0].id) ||
            null;

        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        if (params.id) {
            config.apiPath = `/admin/lottery_code_activities/${params.id}`;
        }
        let newParams: any = {};
        if (params.baseInfo) {
            newParams.activityVo = {
                id: params.id,
                name: params.baseInfo.name,
                eventType: 'LOTTERY_CODE',
                startTime: params.baseInfo.dateRange.start,
                endTime: params.baseInfo.dateRange.end,
                description: params.baseInfo.description,
                remark: params.baseInfo.remark,
                normalConditions: [
                    {
                        all: false,
                        normalConditionType: 'SUB_SITE',
                        includeIds: params.baseInfo.subsiteIds,
                    },
                ],
                exchangeConditions: [
                    {
                        consumeValue: 0,
                        exchangeLimit: params.numberInfo.activityRewardLimit,
                        exchangeMode: 'GIVE',
                        normalConditionType: 'MEMBER_CARD_LEVEL',
                        period: 'ACTIVITY',
                        scopeId: -1,
                    },
                    {
                        consumeValue: 1,
                        exchangeLimit: params.numberInfo.shareRewardLimit,
                        exchangeMode: 'SHARE',
                        exchangeValue: 1,
                        normalConditionType: 'MEMBER_CARD_LEVEL',
                        period: 'ACTIVITY',
                        scopeId: -1,
                    },
                    {
                        consumeValue: 1,
                        exchangeLimit: params.numberInfo.shareAssistLimit,
                        exchangeMode: 'SHARE_ASSIST',
                        exchangeValue: 1,
                        normalConditionType: 'MEMBER_CARD_LEVEL',
                        period: 'ACTIVITY',
                        scopeId: -1,
                    },
                    {
                        consumeValue: 1,
                        exchangeLimit: params.numberInfo.assistLimit,
                        exchangeMode: 'ASSIST',
                        exchangeValue: 1,
                        normalConditionType: 'MEMBER_CARD_LEVEL',
                        period: 'ACTIVITY',
                        scopeId: -1,
                    },
                ],
            };
            if (params.numberInfo.pointRule === 'YES') {
                newParams.activityVo.exchangeConditions.push({
                    consumeValue:
                        params.numberInfo.pointRule === 'YES' ? params.numberInfo.point : 0,
                    exchangeLimit:
                        params.numberInfo.pointRule === 'YES'
                            ? params.numberInfo.pointExchangeLimit
                            : 0,
                    exchangeMode: 'POINT_CONSUME',
                    exchangeValue: 1,
                    normalConditionType: 'MEMBER_CARD_LEVEL',
                    period: 'ACTIVITY',
                    scopeId: -1,
                });
            }
            newParams.pageShow = {
                drawTime: params.baseInfo.drawTime,
            };
            newParams.activityVo.rewardConfig = {
                rewardRule: 'RANDOM',
            };
        }
        if (params.prizeInfo) {
            var checked = params.prizeInfo.type[0].checked;
            var assetRewardsList = params.prizeInfo.type[0].assetRewards;
            if (checked === 'CUSTOM') {
                newParams.activityVo.rewardConfig.activityRewards = [
                    {
                        assetRewards: [
                            {
                                rewardType: 'CUSTOM',
                                rewardValue: 0,
                                name: params.prizeInfo.name,
                                picture: {
                                    id: params.prizeInfo.picture[0].id,
                                    url: params.prizeInfo.picture[0].path,
                                },
                                quantity: params.prizeInfo.quantity,
                            },
                        ],
                    },
                ];
            } else if (checked === 'COUPON') {
                // 仅支持单个商品
                const couponItem = assetRewardsList[0].rewardValue[0];
                newParams.activityVo.rewardConfig.activityRewards = [
                    {
                        couponRewards: [
                            {
                                count: couponItem.quantity ? couponItem.quantity : 1,
                                couponDefinitionId: couponItem.id,
                                name: params.prizeInfo.name,
                                picture: {
                                    id: params.prizeInfo.picture[0].id,
                                    url: params.prizeInfo.picture[0].path,
                                },
                                quantity: params.prizeInfo.quantity,
                            },
                        ],
                    },
                ];
            }
            const pageConfig = {
                picture: {
                    id: params.prizeInfo.picture[0].id,
                    url: params.prizeInfo.picture[0].path,
                },
            };
            newParams.lottery = {
                pageConfig: JSON.stringify(pageConfig),
            };
        }
        newParams.marketingScheduleId =
            (params.otherInfo?.marketingSchedule?.length &&
                params.otherInfo.marketingSchedule[0].id) ||
            null;
        return await api.put(newParams, config);
    },
};
