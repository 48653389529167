import React, { PureComponent } from 'react';
import { get } from 'lodash';
import { MerchantsSelector } from './merchants-selector';
import { MerchantsSelectorDisplay } from './merchants-selector-display';

export interface MerchantsSelectorItem {
    id: string;
    name: string;
    status: string;
    type: string;
}

export class MerchantsSelectorBySubsites extends PureComponent<any> {
    componentWillReceiveProps(nextProps: any) {
        const { subsiteField = '' } = this.props;
        const nextSubsites = get(nextProps.row, subsiteField);
        const preSubsites = get(this.props.row, subsiteField);
        this.handleSubsitesChange(nextSubsites, preSubsites);
    }

    handleSubsitesChange = (nextSubsites: any, preSubsites: any) => {
        const { onChange } = this.props;
        if (!preSubsites) return;
        if (nextSubsites !== preSubsites) {
            onChange([]);
            return;
        }
    };
    onChange = (selectValues: MerchantsSelectorItem[]) => {
        const { onChange } = this.props;
        if (onChange) {
            if (selectValues && selectValues.length === 0) {
                onChange([]);
            } else {
                onChange(selectValues || []);
            }
        }
    };

    onSelect = (selectValues: MerchantsSelectorItem[]) => {
        const { name, onChange } = this.props;
        if (onChange) {
            onChange(selectValues || [], name);
        }
    };

    render() {
        const { value, disabled } = this.props;
        const hasValue = value && value.length > 0;
        return (
            <div>
                <MerchantsSelector {...this.props} onChange={this.onSelect} selectValues={value} />
                {hasValue && (
                    <MerchantsSelectorDisplay
                        data={value || []}
                        onRemove={this.onChange}
                        disabled={disabled}
                    ></MerchantsSelectorDisplay>
                )}
            </div>
        );
    }
}
