import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { times, divide } from 'number-precision';

import { ContentType } from '../types/mode/array/carpark-page-content';

import moment from 'moment';

const api = services.api;

function convertTime(sec: number): string {
    const duration = moment.duration(sec, 's');
    const second = duration.seconds();
    const minute = duration.minutes();
    const hour = duration.hours();
    const day = duration.days();
    return `${day}天${hour}小时${minute}分${second}秒`;
}

function transformRequestData(params: any) {
    const {
        carouselChannel,
        carparkConfig: {
            checkoutType,
            amapPoiId,
            mchntId,
            carparkSupportAlipay,
            subsite,
            freeExitTime: originFreeExitTime,
            ...carparkConfig
        },
        pageConfig,
        otherConfig: { pageContent, pointAlias, menusConfig, buttonsConfig },
        id,
    } = params;
    carparkConfig.alipayCarparkConf = {
        amapPoiId,
        mchntId,
    };
    const channels = ['WECHAT_MINI_PROGRAM'];
    if (new Set(carparkSupportAlipay || []).has('support')) {
        channels.push('ALIPAY_MINI_PROGRAM');
    }
    let freeExitTime: number | null = null;
    if (Number(originFreeExitTime)) {
        freeExitTime = times(originFreeExitTime * 60);
    } else if (originFreeExitTime === 0) {
        freeExitTime = 0;
    }
    const carousels = pageConfig.carousels
        ?.filter((c: any) => !!(c.picture && c.picture.length && c.picture[0].path))
        .map((c: any) => {
            return {
                picture: {
                    id: c.picture[0].id,
                    url: c.picture[0].path,
                },
                config: JSON.stringify(c.config),
            };
        });
    const pageContents = Object.values(ContentType);
    const pageContentConfigs = pageContents.map((item: any) => {
        const config = item === 'PAGE_CONTENT_POINTS_DISPLAY' ? { pointAlias } : null;
        return {
            moduleType: item,
            visible: pageContent.includes(item),
            config,
        };
    });
    const moduleConfigs = menusConfig.map((item: any, index: number) => ({
        ...item,
        sequence: index,
    }));
    /**
     * 新增停车场 数据封装在集合里（为了给后端传参）
     */
    const createCarparkInfo = [
        {
            freeExitTime: carparkConfig.freeExitTime,
            name: carparkConfig.name,
            address: carparkConfig.address,
            mobile: carparkConfig.mobile,
        },
    ];
    carparkConfig.carparkInfos?.length &&
        carparkConfig.carparkInfos.forEach((item: any) => {
            item.freeExitTime = item.freeExitTimeConfig?.freeExitTime;
            delete item.freeExitTimeConfig;
        });

    const body = {
        id,
        carouselChannel,
        channels,
        checkoutType: checkoutType.type,
        showBoundCarNumberCount:
            pageConfig.showBoundCarNumberCountType === 'NUM'
                ? pageConfig.showBoundCarNumberCount || 2
                : 0,
        ...carparkConfig,
        subsiteId: subsite.id,
        freeExitTime,
        miniProgramJumpThirdPartySystemCheckoutConfig: checkoutType.config,
        pageConfig: {
            ...pageConfig,
            carousels,
        },
        carparkInfos: id ? carparkConfig.carparkInfos : createCarparkInfo,
        pageContentConfigs,
        moduleConfigs,
        navigationButtonConfigs: buttonsConfig,
    };
    return body;
}

function transformResponseData(params: any) {
    const {
        address,
        alipayCarparkConf,
        carparkSystemName,
        channels,
        id,
        mobile,
        name,
        pageConfig: originPageConfig,
        subsiteId,
        subsiteName,
        checkoutType,
        freeExitTimeConfig,
        pageContentConfigs,
        moduleConfigs,
        navigationButtonConfigs,
        miniProgramJumpThirdPartySystemCheckoutConfig,
        enabledTouchlessParking,
        touchlessParkingSystem,
        carparkInfos,
        showBoundCarNumberCount,
    } = params;

    const { tripartiteSystemSupport, freeExitTime } = freeExitTimeConfig || {};
    const { carousels, ...pageConfig } = originPageConfig || {};
    const carparkSupportAlipay = (channels || []).includes('ALIPAY_MINI_PROGRAM')
        ? ['support']
        : [];
    const pageContent = pageContentConfigs
        .filter((item: any) => item.visible)
        .map((item: any) => item.moduleType);
    const pointAlias = pageContentConfigs.find(
        (item: any) => item.moduleType === 'PAGE_CONTENT_POINTS_DISPLAY'
    )?.config?.pointAlias;
    const menusConfig = moduleConfigs.sort((a: any, b: any) => a.sequence - b.sequence);

    const data = {
        id,
        carparkConfig: {
            ...(alipayCarparkConf || {}),
            tripartiteSystemSupport: tripartiteSystemSupport ? 'true' : 'false',
            freeExitTime: freeExitTime ? divide(freeExitTime, 60) : 0,
            subsite: {
                id: subsiteId,
                name: subsiteName,
            },
            checkoutType: {
                type: checkoutType,
                config: miniProgramJumpThirdPartySystemCheckoutConfig,
            },
            carparkInfos: carparkInfos,
            carparkSystemName,
            carparkSupportAlipay,
            name,
            address,
            mobile,
            enabledTouchlessParking,
            touchlessParkingSystem,
        },
        pageConfig: {
            ...pageConfig,
            carousels: carousels?.map((c: any) => {
                return {
                    picture: [
                        {
                            id: c.picture.id,
                            path: c.picture.url,
                        },
                    ],
                    config: JSON.parse(c.config),
                };
            }),
            showBoundCarNumberCount: showBoundCarNumberCount || 0,
            showBoundCarNumberCountType: showBoundCarNumberCount ? 'NUM' : 'ALL',
        },
        otherConfig: {
            pageContent,
            pointAlias,
            menusConfig,
            buttonsConfig: navigationButtonConfigs,
        },
    };
    return data;
}

export const CarparkLoader: Loader = {
    post: async function(params, config: ApiRequestConfig) {
        const paramsFilter = config.paramsFilter;
        if (paramsFilter && paramsFilter.length) {
            params.carouselChannel = paramsFilter[0];
            delete config.paramsFilter;
        }
        return await api.post(transformRequestData(params), config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const paramsFilter = config.paramsFilter;
        if (paramsFilter && paramsFilter.length) {
            params.carouselChannel = paramsFilter[0];
            delete config.paramsFilter;
        }
        return await api.put(transformRequestData(params), config);
    },
    get: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        const subsiteName = params.subsiteName;
        if (subsiteName) {
            params.subsiteName = subsiteName.name ? subsiteName.name : subsiteName;
        }
        const paramsFilter = config.paramsFilter;
        if (paramsFilter && paramsFilter.length) {
            params.carouselChannel = paramsFilter[0];
            delete config.paramsFilter;
        }
        return await api.get(params, config).then((res: any) => {
            if (id) {
                res.status = res.status === 'ENABLE';
                return transformResponseData(res);
            } else {
                res.result = res.result.map((item: any) => {
                    item.carparkInfos.length > 0 &&
                        item.carparkInfos.forEach((item: any) => {
                            delete item.name;
                            item.name = item.carparkSystemName;
                        });
                    return {
                        ...item,
                        carparkExplain: item.pageConfig?.carparkExplain,
                    };
                });
            }
            return res;
        });
    },
};

export const CarparkOrderLoader: Loader = {
    get: async function(params, config: ApiRequestConfig) {
        const { entryTime, payTime, channel } = params;
        if (entryTime) {
            params.startEntryTime = entryTime.start;
            params.endEntryTime = entryTime.end;
            delete params.entryTime;
        }
        if (payTime) {
            params.startPayTime = payTime.start;
            params.endPayTime = payTime.end;
            delete params.payTime;
        }
        const subsiteName = params.subsiteName;
        if (subsiteName) {
            params.subsiteName = subsiteName.name ? subsiteName.name : subsiteName;
        }
        if (channel && channel === 'ALL') {
            delete params.channel;
        }
        let result: any = await api.get(params, config);
        if (params.id) {
            result.baseInfo.parkingTime = convertTime(result.baseInfo.parkingTimeSec);
            return result;
        } else {
            result.result.forEach((item: any) => {
                item.parkingTime = convertTime(item.parkingTimeSec);
            });
            return result;
        }
    },
};

export const CarNumberLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiPath = '/admin/car_numbers/page';
        const { bindTime } = data;
        if (bindTime) {
            data.startTime = bindTime.start;
            data.endTime = bindTime.end;
            delete data.bindTime;
        }

        return api.get(data, config).then((res: any) => {
            res.result = res.result.map((item: any) => {
                return {
                    ...item,
                    status: 'ENABLE',
                };
            });
            return res;
        });
    },
};
