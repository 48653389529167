import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach, map } from 'lodash';
import moment from 'moment';
import { ReceiveLimitEnum } from '../../config/promotion/coupon/cash-coupon-rules';

const api = services.api;

export const CouponPackageRulesLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        config.apiPath = '/admin/coupon_package_rules';
        const { id } = data;
        if (!id) {
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (data.subsiteIds && Array.isArray(data.subsiteIds) && data.subsiteIds.length) {
                data.subsiteIds = data.subsiteIds.map((item: any) => item.id).join(',');
            }
        } else {
            config.apiPath = `/admin/coupon_package_rules/${id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.id = res.couponRule.id;
                res.baseInfo = {
                    name: res.couponRule.name,
                    subsiteIds: res.couponRule.subsites.map((item: any) => ({
                        id: item.id + '',
                        name: item.name,
                    })),
                    dateRange: {
                        start: res.consumeEffectivePeriodRule.period.startTime,
                        end: res.consumeEffectivePeriodRule.period.endTime,
                    },
                };

                res.contentInfo = {
                    content: res.ruleItems.map((item: any) => {
                        item.id = item.couponRule.id;
                        item.name = item.couponRule.name;
                        item.batchNo = item.couponRule.code;
                        item.couponTypeDes = item.couponRule.typeName;
                        item.sourceDesc = item.couponRule.sourceDesc;
                        item.consumeChannelDescription = item.couponRule.consumeChannelDescription;
                        item.startTime = item.couponRule.startTime;
                        item.endTime = item.couponRule.endTime;
                        item.amountValue =
                            item.couponValue.type === 'AMOUNT' ? item.couponValue.value : '';
                        item.dynamicEffectivePeriod =
                            item.consumeEffectivePeriodRule.dynamicEffectivePeriod;
                        return item;
                    }),
                };
                res.grantInfo = {
                    condition: res.receiveRule.canBeReceiveWhenLackOfStock ? [] : ['ONCE_ONLY'],
                };
                let consumeRuleMemberLevels: any = [];
                let receiveRuleMemberLevels: any = [];
                forEach(res.consumeRule.dimensions, (dimension) => {
                    if (dimension.dimensionType === 'MEMBER_CARD_LEVEL') {
                        forEach(dimension.ids, (id: any) => {
                            consumeRuleMemberLevels.push({
                                id: id + '',
                            });
                        });
                    }
                });
                if (res.receiveRule.cardLevels && res.receiveRule.cardLevels.length > 0) {
                    forEach(res.receiveRule.cardLevels, (levelId: any) => {
                        receiveRuleMemberLevels.push({
                            id: levelId + '',
                        });
                    });
                }
                res.useRuleInfo = {
                    receivePeriod:
                        res.receiveRule.period.startTime && res.receiveRule.period.endTime
                            ? {
                                  start: res.receiveRule.period.startTime,
                                  end: res.receiveRule.period.endTime,
                              }
                            : undefined,
                    receiveLimit: res.receiveRule.receiveLimitType,
                    memberLevels: receiveRuleMemberLevels,
                    registerMemberSource: res.receiveRule.registerTimeLimit
                        ? res.receiveRule.registerTimeLimit.registerMemberSource
                        : 'ONLINE',
                    useLimitationsMode:
                        res.receiveRule.registerTimeLimit &&
                        res.receiveRule.registerTimeLimit.registerDays
                            ? 'DAY'
                            : 'DATE',
                    useLimitationsDay: res.receiveRule.registerTimeLimit?.registerDays || undefined,
                    useLimitationsDate: res.receiveRule.registerTimeLimit?.registerPeriod
                        ? {
                              start: moment(
                                  res.receiveRule.registerTimeLimit?.registerPeriod.startTime
                              ).format('YYYY-MM-DD'),
                              end: moment(
                                  res.receiveRule.registerTimeLimit?.registerPeriod.endTime
                              ).format('YYYY-MM-DD'),
                          }
                        : undefined,
                    receiveQuantityLimitType: res.receiveRule.receiveQuantityLimitType,
                    memberLimitQuantity:
                        res.receiveRule.receiveQuantityLimitType === 'EVERY_MEMBER_LIMIT' ||
                        res.receiveRule.receiveQuantityLimitType === 'EVERY_MEMBER_DAILY_LIMIT'
                            ? res.receiveRule.memberLimitQuantity
                            : null,
                    target: res.linkRule && res.linkRule.target && JSON.parse(res.linkRule.target),
                    receiveLimitCrowds: res.receiveRule.crowdLimits || [],
                };

                if (
                    res.pictureConfigs &&
                    res.pictureConfigs.length > 0 &&
                    res.pictureConfigs[0].pictureId
                ) {
                    res.pictureInfo = {
                        packImg: [
                            {
                                id: res.pictureConfigs[0].pictureId || '',
                                path: res.pictureConfigs[0].url || '',
                            },
                        ],
                    };
                }

                const seniorSetting: any = {
                    fourIdenticalCertification:
                        res.receiveRule.fourIdenticalCertification === true ? 'ENABLE' : 'DISABLE',
                };
                const guideRule = res.guideDistributionRule;
                if (guideRule) {
                    seniorSetting.guideProvide = guideRule.guideProvide ? 'ENABLE' : 'DISABLE';
                    seniorSetting.selectMode = guideRule.subSiteOrMerchant || 'SUB_SITE';
                    seniorSetting.shop = guideRule.subSiteMerchants || [];
                    seniorSetting.subsite = guideRule.subsites || [];
                } else {
                    seniorSetting.guideProvide = 'DISABLE';
                    seniorSetting.selectMode = 'SUB_SITE';
                    seniorSetting.shop = [];
                    seniorSetting.subsite = [];
                }
                res.seniorSetting = seniorSetting;
            } else {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        item.info = {
                            name: item.name,
                            url:
                                item?.pictureConfigs?.[0]?.url ||
                                `https://b-puzhehei-cdn.co-mall.net/global/icon_coupon_center_package.png`,
                            startTime: item.startTime,
                            endTime: item.endTime,
                        };
                        return item;
                    });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        config.apiPath = '/admin/coupon_package_rules';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        config.apiPath = `/admin/coupon_package_rules/${id}`;
        const newParams: any = dataParser(params);
        newParams.couponRule.id = params.id;
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    newParams.couponRule = {
        couponType: 'PACKAGE',
        name: params.baseInfo.name,
        subSiteOrMerchant: 'SUB_SITE',
        subsites: params.baseInfo.subsiteIds.map((item: { id: string; name: string }) => ({
            id: item.id,
        })),
    };
    if (
        params.baseInfo.dateRange &&
        params.baseInfo.dateRange.start &&
        params.baseInfo.dateRange.end
    ) {
        newParams.consumeEffectivePeriodRule = {
            period: {
                startTime: params.baseInfo.dateRange.start,
                endTime: params.baseInfo.dateRange.end,
            },
        };
    }
    if (params.pictureInfo && params.pictureInfo.packImg && params.pictureInfo.packImg[0]) {
        newParams.pictureConfigs = [
            {
                pictureId: params.pictureInfo.packImg[0].id,
                type: 'COVER',
            },
        ];
    }
    newParams.receiveRule = {
        canBeReceiveWhenLackOfStock:
            params.grantInfo.condition &&
            params.grantInfo.condition.length > 0 &&
            params.grantInfo.condition[0] === 'ONCE_ONLY'
                ? false
                : true,
        canBeReceived: true,
        memberLimitQuantity:
            params.useRuleInfo.receiveQuantityLimitType === 'EVERY_MEMBER_LIMIT' ||
            params.useRuleInfo.receiveQuantityLimitType === 'EVERY_MEMBER_DAILY_LIMIT'
                ? params.useRuleInfo.memberLimitQuantity
                : null,
        receiveQuantityLimitType: params.useRuleInfo.receiveQuantityLimitType,
        cardLevels:
            params.useRuleInfo.receiveLimit === ReceiveLimitEnum.MEMBER_LIMIT
                ? map(params.useRuleInfo.memberLevels, 'id')
                : null,
        registerTimeLimit:
            params.useRuleInfo.receiveLimit === ReceiveLimitEnum.TIME_LIMIT
                ? {
                      registerMemberSource: params.useRuleInfo.registerMemberSource,
                      registerDays:
                          params.useRuleInfo.useLimitationsMode === 'DAY'
                              ? params.useRuleInfo.useLimitationsDay
                              : undefined,
                      registerPeriod:
                          params.useRuleInfo.useLimitationsMode === 'DATE'
                              ? {
                                    startTime:
                                        params.useRuleInfo.useLimitationsDate.start + ' 00:00:00',
                                    endTime:
                                        params.useRuleInfo.useLimitationsDate.end + ' 23:59:59',
                                }
                              : undefined,
                  }
                : undefined,
        crowdIds:
            params.useRuleInfo.receiveLimit === ReceiveLimitEnum.CROWD_LIMIT
                ? params.useRuleInfo.receiveLimitCrowds.map((i: any) => i.id)
                : undefined,
        period: {
            endTime: params.useRuleInfo.receivePeriod?.end ?? null,
            startTime: params.useRuleInfo.receivePeriod?.start ?? null,
        },
    };
    // if (
    //     params.baseInfo.dateRange &&
    //     params.baseInfo.dateRange.start &&
    //     params.baseInfo.dateRange.end
    // ) {
    //     newParams.receiveRule.period = {
    //         endTime: params.baseInfo.dateRange.end,
    //         startTime: params.baseInfo.dateRange.start,
    //     };
    // }
    newParams.ruleItems = params.contentInfo.content.map((item: any) => {
        return {
            couponRule: item,
            quantity: item.quantity,
        };
    });
    if (params.useRuleInfo.target) {
        newParams.linkRule = {
            target: JSON.stringify(params.useRuleInfo.target),
        };
    }
    if (params.seniorSetting) {
        const shopArray: any = [];
        const subsiteArray: any[] = [];
        if (params.seniorSetting.shop && params.seniorSetting.shop.length > 0) {
            forEach(params.seniorSetting.shop, (shop) => {
                shopArray.push(`${shop.subSiteId},${shop.merchantId}`);
            });
        }
        if (params.seniorSetting.subsite && params.seniorSetting.subsite.length > 0) {
            forEach(params.seniorSetting.subsite, (subsite) => {
                subsiteArray.push(subsite);
            });
        }
        newParams.guideDistributionRule = {
            guideProvide: params.seniorSetting.guideProvide === 'ENABLE' ? true : false,
            subSiteOrMerchant: params.seniorSetting.selectMode,
            merchants: params.seniorSetting.selectMode === 'MERCHANT' ? shopArray : [],
            subsites: params.seniorSetting.selectMode === 'SUB_SITE' ? subsiteArray : [],
        };
    }
    const sendRules: any = [];
    if (params.seniorSetting && params.seniorSetting.guideProvide === 'ENABLE') {
        /* forEach(params.seniorSetting.alipayChannel, (channel) => {
            sendRules.push({
                channel: channel,
            });
        }); */
        newParams.receiveRule.fourIdenticalCertification =
            params.seniorSetting.fourIdenticalCertification === 'ENABLE' ? true : false;
    }
    newParams.sendRules = sendRules;
    return newParams;
}
