import React from 'react';
import { Input } from 'antd';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const DreamServiceUrlLink: React.FC<any> = (props) => {
    const { value, onChange } = props;
    const handleChange = (value: string) => {
        onChange({
            url: value,
        });
    };
    return (
        <div className="dream-service-link-form">
            <Input
                placeholder={language.getText('qsrljdz')}
                value={value?.linkParams?.url}
                onChange={(e) => handleChange(e.target.value)}
            />
        </div>
    );
};

export default DreamServiceUrlLink;
