import { Config } from '@comall-backend-builder/core/lib/parser';
export const config: Config = {
    entities: {
        OrderCommentsEntity: {
            apiPath: '/loader/admin/order-comments',
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                merchantId: {
                    type: 'string.filterOptions.autoComplete',
                    displayName: '<<merchant>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                mobile: {
                    type: 'string',
                    displayName: '<<plr>>',
                    controlConfig: { placeholder: '<<qsrplrsjh>>' },
                },
                productName: {
                    type: 'string',
                    displayName: '<<plsp>>',
                    controlConfig: { placeholder: '<<qsrplsp>>' },
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<ddh_1>>',
                    controlConfig: { placeholder: '<<qsrddh>>' },
                },
                status: {
                    type: 'string',
                    displayName: '<<status>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<pjsj>>',
                },
            },
        },
    },
    components: {
        OrderCommentsView: {
            component: 'Viewport',
        },

        OrderCommentsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'OrderCommentsEntity',
            className: 'tabs-content-viewport',
            items: [{ component: 'OrderCommentsContainer' }],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/order-comments',
                    component: 'OrderCommentsView',
                    breadcrumbName: '<<hypj>>',
                    privilege: {
                        path: 'reviewAudit',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'OrderCommentsPage' }],
                },
            ],
        },
    ],
};
