import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach, get, map, camelCase, forIn, cloneDeep, snakeCase, filter } from 'lodash';
import { MsgChannelType } from '../types/mode/array/array-message-notification-method/message-notification-method';

const api = services.api;
export const BirthdayCaringLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/birthday_caring_activities';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/birthday_caring_activities/${id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                const subsites = res.activity.normalConditions.find(
                    (i: any) => i.normalConditionType === 'SUB_SITE'
                );
                const subsiteId = subsites ? subsites.includeIds : undefined;
                let merchants = res.activity.normalConditions.find(
                    (i: any) => i.normalConditionType === 'MERCHANT'
                );
                merchants = merchants
                    ? merchants.includeIds.map((m: any) => ({
                          ...m,
                          id: `${m.subsiteId},${m.id}`,
                          subSiteName: m.subsiteName,
                          merchantId: m.id + '',
                          merchantName: m.name,
                          code: m.code,
                          merchantType: m.typeStr,
                          merchantStatus: m.statusStr,
                      }))
                    : [];
                res.baseInfo = {
                    id: res.activity.id,
                    name: res.activity.name,
                    subsiteId,
                    birthdayCaringType: res.birthdayCaringType,
                    frontDay: res.frontDay,
                };
                if (res.frontDay === 0) {
                    res.baseInfo.frontDay = '';
                }
                const rewardMode = res.rewardMode;
                const picture =
                    res.picture && !!res.picture.id
                        ? [
                              {
                                  id: res.picture.id,
                                  path: res.picture.url || '',
                              },
                          ]
                        : [];
                const rewardInfo: any = { rewardMode, merchants, picture };
                const activityRewards = get(res, 'activity.rewardConfig.activityRewards', []);
                if (rewardMode === 'RECEIVE') {
                    let promotionRuleType = '';
                    const memberLevel = [];
                    let levelName = '';
                    for (let i = 0; i < activityRewards.length; i++) {
                        const activityReward = activityRewards[i];
                        const conditionValue = activityReward.conditionValue;
                        if (conditionValue === 0) {
                            promotionRuleType = 'ALL';
                            levelName = '全部等级';
                        } else {
                            promotionRuleType = 'LEVEL';
                        }
                        const coupons = [];
                        for (let j = 0; j < activityReward.couponRewards.length; j++) {
                            const coupon = activityReward.couponRewards[j];
                            coupons.push({
                                batchNo: coupon.batchNo,
                                couponTypeDes: coupon.couponTypeDesc,
                                endTime: coupon.endTime,
                                id: coupon.couponDefinitionId,
                                sourceDesc: coupon.sourceDesc,
                                name: coupon.name,
                                quantity: coupon.count,
                                startTime: coupon.startTime,
                                stock: coupon.stock,
                                type: coupon.couponType,
                                consumeChannelDescription: coupon.consumeChannelDescription,
                            });
                        }
                        let experience = '';
                        if (activityReward.assetRewards && activityReward.assetRewards.length > 0) {
                            forEach(activityReward.assetRewards, (assetReward) => {
                                if (assetReward.rewardType === 'EXPERIENCE') {
                                    experience = assetReward.rewardValue;
                                }
                            });
                        }
                        memberLevel.push({
                            experience: experience,
                            coupons: coupons,
                            levelId: conditionValue,
                            levelName: levelName,
                        });
                    }
                    rewardInfo.receiveRule = {
                        memberLevel: memberLevel,
                        type: promotionRuleType,
                    };
                }
                if (rewardMode === 'SHOPPING_REWARD_DOUBLE') {
                    const unifiedReward = res.unifiedReward;
                    const rewardInfos: any[] = [];
                    forEach(activityRewards, (r: any) => {
                        const cardLevelId = r.conditionValue;
                        const pointDouble = r.assetRewards.find(
                            (i: any) => i.rewardType === 'POINT_DOUBLE'
                        );
                        const experienceDouble = r.assetRewards.find(
                            (i: any) => i.rewardType === 'EXPERIENCE_DOUBLE'
                        );
                        rewardInfos.push({
                            cardLevelId,
                            cardLevelName: !!cardLevelId ? undefined : '全部等级',
                            rewardValue: pointDouble ? pointDouble.rewardValue : undefined,
                            experienceValue: experienceDouble
                                ? experienceDouble.rewardValue
                                : undefined,
                        });
                    });
                    rewardInfo.shoppingRewardRule = { unifiedReward, rewardInfos };
                }
                res.rewardInfo = rewardInfo;
                const businessNotifications =
                    res.businessNotifications && res.businessNotifications.length
                        ? res.businessNotifications.map((item: any) => {
                              let content: any[] = [];
                              if (item.content) {
                                  try {
                                      const newContent = JSON.parse(item.content);
                                      newContent.forEach((i: any) => {
                                          const newItem: any = {};
                                          forIn(i, (value, key) => {
                                              newItem[camelCase(key)] = value;
                                          });
                                          if (
                                              (item.msgChannelType === MsgChannelType.APP_PUSH ||
                                                  item.msgChannelType === MsgChannelType.SITEMAIL ||
                                                  item.msgChannelType === MsgChannelType.EMAIL) &&
                                              newItem.fieldType === 'file' &&
                                              newItem.value
                                          ) {
                                              newItem.value = JSON.parse(newItem.value);
                                          }
                                          content.push(newItem);
                                      });
                                  } catch (error) {
                                      content = item.content;
                                  }
                              }
                              return {
                                  id: item.id,
                                  status: item.status,
                                  msgChannelType: item.msgChannelType,
                                  scene: item.scene,
                                  targetType: item.targetType,
                                  targetUrl: item.targetUrl,
                                  params: content,
                              };
                          })
                        : [];
                const activity = res.noticeTimes
                    ? res.noticeTimes.find((t: any) => t.type === 'ACTIVITY_START_TIME')
                    : undefined;
                const member = res.noticeTimes
                    ? res.noticeTimes.find((t: any) => t.type === 'MEMBER_BIRTHDAY')
                    : undefined;
                res.settingInfo = {
                    businessNotifications,
                    message: {
                        activityCheck: activity ? true : false,
                        activityDay: activity ? activity.advanceDays + '' : '0',
                        activityTime: activity ? activity.time + '' : '08:00',
                        memberCheck: member ? true : false,
                        memberDay: member ? member.advanceDays + '' : '0',
                        memberTime: member ? member.time + '' : '08:00',
                    },
                };
            } else {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        return {
                            ...item,
                            ...item.activityList,
                            unifiedReward: String(item.unifiedReward),
                            status: String(item.activityList.status),
                        };
                    });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/birthday_caring_activities';
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        if (params.id) {
            config.apiPath = `/admin/birthday_caring_activities/${params.id}`;
        }
        const newParams = dataParser(params);
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    const { baseInfo, rewardInfo } = params;
    const { rewardMode, merchants, receiveRule, shoppingRewardRule, picture } = rewardInfo;
    const normalConditions = [
        {
            normalConditionType: 'SUB_SITE',
            includeIds: baseInfo.subsiteId.map((subsite: any) => {
                return { id: Number(subsite.id) };
            }),
        },
    ];
    if (rewardMode === 'SHOPPING_REWARD_DOUBLE' && merchants && merchants.length) {
        normalConditions.push({
            normalConditionType: 'MERCHANT',
            includeIds: merchants.map((m: any) => ({ id: m.merchantId })),
        });
    }
    newParams.activityVo = {
        eventType: 'BIRTHDAY_CARING',
        name: baseInfo.name,
        normalConditions: normalConditions,
    };
    newParams.birthdayCaringType = baseInfo.birthdayCaringType;
    if (baseInfo.birthdayCaringType === 'FRONT_DAY') {
        newParams.frontDay = params.baseInfo.frontDay;
    }
    newParams.rewardMode = rewardMode;

    const activityRewardsArray: any = [];
    let rewardRule: string = 'CYCLE';
    if (rewardMode === 'RECEIVE') {
        forEach(receiveRule.memberLevel, (rule) => {
            const couponRewardsArray: any = [];
            forEach(rule.coupons, (coupon) => {
                couponRewardsArray.push({
                    couponDefinitionId: coupon.id,
                    count: coupon.quantity,
                });
            });
            const assetRewards = [];
            if (!!rule.experience) {
                assetRewards.push({
                    rewardType: 'EXPERIENCE',
                    rewardValue: rule.experience,
                });
            }
            activityRewardsArray.push({
                assetRewards,
                conditionValue: rule.levelId,
                couponRewards: couponRewardsArray,
            });
        });
        rewardRule = get(params, 'rewardInfo.receiveRule.type') === 'ALL' ? 'CYCLE' : 'LADDER';
    }

    if (rewardMode === 'SHOPPING_REWARD_DOUBLE') {
        const unifiedReward = get(shoppingRewardRule, 'unifiedReward');
        const rewardInfos = get(shoppingRewardRule, 'rewardInfos');
        const rewards = rewardInfos.filter((i: any) => !!i.rewardValue || i.experienceValue);
        forEach(rewards, (reward) => {
            const { rewardValue, experienceValue } = reward;
            const conditionValue = reward.cardLevelId;
            const assetRewards: any[] = [];
            rewardValue && assetRewards.push({ rewardType: 'POINT_DOUBLE', rewardValue });
            experienceValue &&
                assetRewards.push({
                    rewardType: 'EXPERIENCE_DOUBLE',
                    rewardValue: experienceValue,
                });
            activityRewardsArray.push({
                conditionValue,
                assetRewards,
            });
        });
        rewardRule = unifiedReward ? 'CYCLE' : 'LADDER';
    }

    newParams.activityVo.rewardConfig = {
        rewardRule,
        activityRewards: activityRewardsArray,
    };

    if (picture && picture.length > 0) {
        newParams.pictureVo = {
            id: picture[0].id,
            url: picture[0].path,
        };
    }
    if (
        rewardMode === 'RECEIVE' &&
        params.settingInfo?.businessNotifications &&
        params.settingInfo.businessNotifications.length
    ) {
        const businessNotifications = map(
            cloneDeep(params.settingInfo.businessNotifications),
            (item: any) => {
                const isSitemail =
                    item.msgChannelType === MsgChannelType.APP_PUSH ||
                    item.msgChannelType === MsgChannelType.SITEMAIL;
                const content = item.params.map((param: any) => {
                    if (param.fieldType === 'file' && param.value && isSitemail) {
                        param.value = param.value.id;
                    }
                    if (
                        param.fieldType === 'file' &&
                        param.value &&
                        item.msgChannelType === MsgChannelType.EMAIL
                    ) {
                        param.value = param.value.map((i: any) => i.id).join(',');
                    }
                    if (item.msgChannelType === MsgChannelType.SMS && !param.editable) {
                        param.value = '';
                    }
                    const newParam: any = {};
                    forIn(param, (value, key) => {
                        newParam[snakeCase(key)] = value;
                    });
                    return newParam;
                });
                return {
                    content: JSON.stringify(content),
                    id: item?.id,
                    msgChannelType: item.msgChannelType,
                    scene: item.scene,
                    status: item.status,
                    targetType: item.targetType,
                    targetUrl: item.targetUrl,
                };
            }
        );
        newParams.businessNotifications = businessNotifications;
        const noticeTimes = [];
        const pushTime = filter(params.settingInfo.businessNotifications, ['status', 1]).length;
        if (pushTime) {
            if (params.settingInfo.message.activityCheck) {
                noticeTimes.push({
                    type: 'ACTIVITY_START_TIME',
                    advanceDays: params.settingInfo.message.activityDay,
                    time: params.settingInfo.message.activityTime,
                });
            }
            if (params.settingInfo.message.memberCheck) {
                noticeTimes.push({
                    type: 'MEMBER_BIRTHDAY',
                    advanceDays: params.settingInfo.message.memberDay,
                    time: params.settingInfo.message.memberTime,
                });
            }
        }
        if (noticeTimes.length > 0) {
            newParams.noticeTimes = noticeTimes;
        }
    }
    return newParams;
}
