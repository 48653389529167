import { Config } from '@comall-backend-builder/core/lib/parser';
import moment from 'moment';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';

// 笔记类型
const CONTENT_NOTE_TYPE = [
    {
        id: 'PICTURE',
        name: '<<tpbj>>',
    },
    {
        id: 'VIDEO',
        name: '<<spbj>>',
    },
];

// 笔记状态
const CONTENT_NOTE_STATUS = [
    {
        id: 'NORMAL',
        name: '<<zc_1>>',
    },
    {
        id: 'SYSTEM_DELETED',
        name: '<<kfsc>>',
    },
    {
        id: 'DELETED',
        name: '<<zzsc>>',
    },
];
// 审核方式
const AUDIT_TYPES = [
    {
        id: 'AUTO',
        name: '<<js_2>>',
    },
    {
        id: 'MANUAL',
        name: '<<rs>>',
    },
];

// 审核状态
const CONTENT_NOTE_REVIEW_STATUS = [
    {
        id: 'WAIT',
        name: '<<wsh>>',
    },
    {
        id: 'SUCCESS',
        name: '<<shcg>>',
    },
    {
        id: 'FAIL',
        name: '<<shsb>>',
    },
];

// 置顶类型
const TOP_TYPE = [
    {
        id: 'TOP',
        name: '<<zd>>',
    },
    {
        id: 'NOT_TOP',
        name: '<<bzd>>',
    },
];

export const config: Config = {
    entities: {
        contentNote: {
            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
            apiPath: '/loader/admin/notes',
            properties: {
                title: {
                    type: 'string.popover',
                    displayName: '<<bjbt>>',
                },
                plateNames: {
                    type: 'string.popover',
                    displayName: '<<plate>>',
                },
                topicNames: {
                    type: 'string.popover',
                    displayName: '<<ht_2>>',
                },
                authorNickName: {
                    type: 'string',
                    displayName: '<<zznc>>',
                },
                authorId: {
                    type: 'string',
                    displayName: '<<authorId>>',
                },
                id: {
                    type: 'string',
                    displayName: '<<noteId>>',
                },
                authorMobile: {
                    type: 'string',
                    displayName: '<<authorMobile>>',
                },
                contentType: {
                    type: 'string.options.select',
                    displayName: '<<bjlx>>',
                    options: CONTENT_NOTE_TYPE,
                },
                publishTime: {
                    type: 'string',
                    displayName: '<<releaseTime>>',
                },
                editTime: {
                    type: 'string',
                    displayName: '<<bjsj>>',
                },
                deleteTime: {
                    type: 'string',
                    displayName: '<<deleteTime>>',
                },
                deleteMark: {
                    type: 'string',
                    displayName: '<<kfscbz>>',
                },
                detailStatus: {
                    type: 'string.options.select',
                    displayName: '<<bjzt>>',
                    options: CONTENT_NOTE_STATUS,
                },
                auditTypeEnum: {
                    type: 'string.options.select',
                    displayName: '<<shfs>>',
                    options: AUDIT_TYPES,
                },
                auditStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<auditStatus>>',
                },
                noteContent: {
                    type: 'object.content.complainDetail',
                    displayName: '<<bjnr>>',
                },
                autoAuditTime: {
                    type: 'string',
                    displayName: '<<zdshsj>>',
                },
                manualAuditTime: {
                    type: 'string',
                    displayName: '<<rgshsj>>',
                },
                manualAuditRemark: {
                    type: 'string',
                    displayName: '<<shbz>>',
                },
                autoAuditFailDetail: {
                    type: 'array.refuse.text',
                    displayName: '<<jsjsyy>>',
                },
                totalScore: {
                    type: 'number',
                    displayName: '<<totalScore>>',
                },
                cityName: {
                    type: 'string',
                    displayName: '<<cs_2>>',
                },
                topStartTime: {
                    type: 'string',
                    displayName: '<<zdkssj>>',
                },
                topEndTime: {
                    type: 'string',
                    displayName: '<<zdjssj>>',
                },
                firstPublishTime: {
                    type: 'string',
                    displayName: '<<scfbsj>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsites>>',
                },
            },
            filters: {
                authorMobile: {
                    type: 'string',
                    displayName: '<<authorMobile>>',
                },
                title: {
                    type: 'string',
                    displayName: '<<bjbt>>',
                },
                contentType: {
                    type: 'string.options.select',
                    displayName: '<<bjlx>>',
                    options: CONTENT_NOTE_TYPE,
                },
                detailStatus: {
                    type: 'string.options.select',
                    displayName: '<<bjzt>>',
                    options: CONTENT_NOTE_STATUS,
                },
                auditTypeEnum: {
                    type: 'string.options.select',
                    displayName: '<<shfs>>',
                    options: AUDIT_TYPES,
                },
                auditStatus: {
                    type: 'string.options.select',
                    displayName: '<<auditStatus>>',
                    options: CONTENT_NOTE_REVIEW_STATUS,
                },
                publishTime: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<releaseTime>>',
                },
                editTime: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<bjsj>>',
                },
                plateId: {
                    type: 'string.options.select',
                    displayName: '<<plate>>',
                    options: [],
                    source: {
                        apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
                        apiPath: '/loader/admin/plates/available',
                    },
                },
                topicName: {
                    type: 'string',
                    displayName: '<<ht_2>>',
                },
                cityId: {
                    type: 'string.options.select',
                    displayName: '<<cs_2>>',
                    options: [],
                    source: {
                        apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
                        apiPath: '/loader/admin/cities/available',
                    },
                },
                topType: {
                    type: 'string.options.select',
                    displayName: '<<zdlx>>',
                    options: TOP_TYPE,
                },
                topTime: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<zdsj>>',
                },
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
            },
        },
        topPicture: {
            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
            apiPath: '/loader/admin/notesTopPic',
            properties: {
                upload1: {
                    type: 'array.imageObjectArrayForTopPic',
                    displayName: '<<upload1>>',
                    className: 'sigle-image-field',
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                        extra: {
                            tip: '<<picLimit123>>',
                            img: './images/avatar/top-picture.png',
                            btnText: '<<viewExample>>',
                            triggerType: 'hover',
                        },
                    },
                },
                upload2: {
                    type: 'imageObjectArray',
                    displayName: '<<upload2>>',
                    extra: '<<upload2Extra>>',
                    className: 'sigle-image-field',
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                },
            },
        },
    },
    components: {
        ContentNoteView: {
            component: 'Viewport',
            entity: 'contentNote',
        },
        ContentNoteListPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ContentNoteFilter',
                },
                {
                    component: 'Button',
                    text: '<<glzdtb>>',
                    type: 'primary',
                    route: '/content-note/top-picture',
                    style: {
                        width: '130px',
                        marginBottom: '24px',
                    },
                },
                {
                    component: 'ContentNoteTable',
                },
            ],
        },
        ContentNoteFilter: {
            component: 'FilterForm',
            fieldRow: {},
            fieldCol: {
                span: 8,
            },
            labelCol: 6,
            fields: [
                {
                    property: 'authorMobile',
                },
                {
                    property: 'title',
                },
                {
                    property: 'contentType',
                },
                {
                    property: 'detailStatus',
                },
                {
                    property: 'auditTypeEnum',
                },
                {
                    property: 'auditStatus',
                },
                {
                    property: 'plateId',
                },
                {
                    property: 'topicName',
                },
                {
                    property: 'publishTime',
                    controlConfig: {
                        style: {
                            width: '100%',
                        },
                    },
                },
                {
                    property: 'editTime',
                    controlConfig: {
                        style: {
                            width: '100%',
                        },
                    },
                },
                {
                    property: 'cityId',
                    controlConfig: {
                        style: {
                            width: '100%',
                        },
                    },
                },
                {
                    property: 'topType',
                    controlConfig: {
                        style: {
                            width: '100%',
                        },
                    },
                },
                {
                    property: 'topTime',
                    controlConfig: {
                        style: {
                            width: '100%',
                        },
                    },
                },
                {
                    property: 'subsiteIds',
                    controlConfig: {
                        style: {
                            width: '100%',
                        },
                    },
                },
            ],
            footer: {
                style: {
                    textAlign: 'right',
                },
            },
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 8,
                },
            },
            reset: true,
        },
        ContentNoteTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'title',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'plateNames',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'topicNames',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'authorNickName',
                },
                {
                    property: 'authorId',
                },
                {
                    property: 'id',
                },
                {
                    property: 'authorMobile',
                },
                {
                    property: 'contentType',
                },
                {
                    property: 'publishTime',
                    sorter: (a: any, b: any) => {
                        let start: any = moment(a.publishTime, 'YYYYMMDDHHmmss').format(
                            'YYYYMMDDHHmmss'
                        );
                        let end: any = moment(b.publishTime, 'YYYYMMDDHHmmss').format(
                            'YYYYMMDDHHmmss'
                        );
                        return start - end;
                    },
                },
                {
                    property: 'cityName',
                },
                {
                    property: 'subsites',
                },
                {
                    property: 'detailStatus',
                },
                {
                    property: 'auditTypeEnum',
                },
                {
                    property: 'auditStatus',
                    displayConfig: {
                        statusConfig: {
                            WAIT: {
                                text: '<<wsh>>',
                                status: 'default',
                            },

                            SUCCESS: {
                                text: '<<shcg>>',
                                status: 'success',
                            },
                            FAIL: {
                                text: '<<shsb>>',
                                status: 'error',
                            },
                        },
                    },
                },
                {
                    property: 'autoAuditFailDetail',
                },
                {
                    property: 'totalScore',
                    sorter: (a: any, b: any) => a.totalScore - b.totalScore,
                },
                {
                    property: 'editTime',
                },
                {
                    property: 'topStartTime',
                },
                {
                    property: 'topEndTime',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 220,
                items: [
                    {
                        type: 'link',
                        text: '<<common.view>>',
                        path: '/content-note/detail/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'ContentNoteDelete',
                    },
                    {
                        type: 'component',
                        component: 'ContentNoteTop',
                    },
                ],
            },
        },
        ContentNoteDetailPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ContentNoteDetailWrap',
                },
            ],
        },
        ContentNoteDetailWrap: {
            component: 'ContentNoteDetail',
            entity: 'contentNote',
            loaderType: 'get',
        },
        ContentNoteTopPicPage: {
            component: 'Card',
            content: {
                component: 'ContentNoteTopPicEditForm',
            },
        },
        ContentNoteTopPicEditForm: {
            component: 'ModifyFormPlus',
            entity: 'topPicture',
            direction: 'horizontal',
            loaderType: 'get',
            style: {
                width: 800,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
            labelCol: 6,
            controlCol: 18,
            fields: [
                {
                    property: 'upload1',
                },
                {
                    property: 'upload2',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: {
                            marginLeft: 10,
                        },
                    },
                ],
            },
            onValidate: (entity: any) => {
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({
                        route: 'goBack',
                    })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/content-note',
                    breadcrumbName: '<<contentNote>>',
                    component: 'ContentNoteView',
                    childRoutes: [
                        {
                            path: '/detail/:id',
                            component: 'ContentNoteDetailPage',
                        },
                        {
                            path: '/top-picture',
                            component: 'ContentNoteTopPicPage',
                            breadcrumbName: '<<zdtb>>',
                        },
                        {
                            path: '/',
                            component: 'ContentNoteListPage',
                        },
                    ],
                    privilege: {
                        path: 'showNote',
                        level: 'full',
                    },
                },
            ],
        },
    ],
};
