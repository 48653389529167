import React, { Component } from 'react';
import { Radio } from 'antd';
import { services } from '@comall-backend-builder/core';
import { forEach, get, isEqual, map } from 'lodash';
import MemberCardLevelPointRewardTable from './member-card-level-point-reward-table';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const { api } = services;

interface MemberCardLevelPointRewardValue {
    unifiedReward: boolean;
    rewardInfos: RewardInfos[];
}

export interface RewardInfos {
    cardLevelId?: number;
    cardLevelName: string;
    rewardValue?: number;
    experienceValue?: number;
}

interface MemberCardLevelPointSettingProps {
    onChange: (value: any) => void;
    value: MemberCardLevelPointRewardValue | undefined;
    row: { baseInfo: { subsiteId: string } };
    subsiteField: string;
    enableExperienceValue?: boolean;
    rewardValueTitle?: string;
}

interface MemberCardLevelPointSettingStates {
    loading: boolean;
}

const defaultValue = {
    unifiedReward: true,
    rewardInfos: [
        {
            cardLevelName: 'qbdj',
        },
    ],
};

export default class MemberCardLevelPointReward extends Component<
    MemberCardLevelPointSettingProps,
    MemberCardLevelPointSettingStates
> {
    constructor(props: MemberCardLevelPointSettingProps) {
        super(props);
        this.state = {
            loading: false,
        };
        const { row, value, onChange } = props;
        if (!value) {
            onChange(defaultValue);
        }
        if (!row) return;
        const subsiteId = this.getSubsiteIds(props);
        if (value && !value.unifiedReward && subsiteId && subsiteId.length > 0) {
            this.updataMemberCardLevels(props, value);
        }
    }

    getSubsiteIds = (props: any) => {
        const { row, subsiteField, multipleSubsite } = props;
        const rowSubsiteId = get(row, subsiteField);
        if (rowSubsiteId) {
            let subsiteId = [rowSubsiteId];
            if (multipleSubsite) {
                subsiteId = map(get(row, subsiteField), 'id');
            }
            return subsiteId;
        }
        return [];
    };

    componentWillReceiveProps(nextProps: MemberCardLevelPointSettingProps) {
        const { value } = nextProps;
        if (!value) return;
        const nextSubsiteId = this.getSubsiteIds(nextProps);
        const preSubsiteId = this.getSubsiteIds(this.props);
        if (!value.unifiedReward && !isEqual(nextSubsiteId, preSubsiteId)) {
            this.updataMemberCardLevels(nextProps, value);
        }
    }

    updataMemberCardLevels = (
        props: MemberCardLevelPointSettingProps,
        value?: MemberCardLevelPointRewardValue
    ) => {
        const subsiteIds = this.getSubsiteIds(props);
        if (!subsiteIds || subsiteIds.length === 0) return;
        this.getMemberCardLevels(subsiteIds, value);
    };

    getMemberCardLevels = (subsiteId: string[], value?: MemberCardLevelPointRewardValue) => {
        if (subsiteId.length === 0) {
            return;
        }
        this.setState({ loading: true });
        let params = {};
        let config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
            apiPath: '/admin/card_levels?status=ENABLE',
        };
        if (subsiteId.length === 1) {
            params = {
                subsiteIds: subsiteId.join(','),
                page: 1,
                perPage: 100,
                status: 'ENABLE',
            };
            config = {
                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                apiPath: '/admin/store_card_levels/legal',
            };
        }
        function getValues(rewardInfos: RewardInfos[], cardLevelId: string) {
            const reward = rewardInfos.find((i) => Number(i.cardLevelId) === Number(cardLevelId));
            return reward
                ? { rewardValue: reward.rewardValue, experienceValue: reward.experienceValue }
                : {};
        }
        api.get(params, config).then((res: any) => {
            let memberLevelsData: any[] = [];
            if (subsiteId.length === 1) {
                memberLevelsData = res.result;
            } else {
                const result: Array<any> = [];
                forEach(res, (level: any) => {
                    result.push({
                        cardLevelId: level.id,
                        name: level.name,
                    });
                });
                memberLevelsData = result;
            }

            if (!memberLevelsData || memberLevelsData.length === 0) {
                this.changeRewardInfos([]);
            } else {
                const currentRewardInfos = value && value.rewardInfos ? value.rewardInfos : [];
                const rewardInfos: RewardInfos[] = memberLevelsData.map((c: any) => ({
                    cardLevelId: c.cardLevelId,
                    cardLevelName: c.name,
                    ...getValues(currentRewardInfos, c.cardLevelId),
                }));
                this.changeRewardInfos(rewardInfos);
            }
            this.setState({ loading: false });
        });
    };

    changeUnifiedReward = (e: any) => {
        const { value, onChange } = this.props;
        if (!value) return;
        const unifiedReward: boolean = e.target.value;
        const newValue = unifiedReward
            ? defaultValue
            : Object.assign({}, value, { unifiedReward, rewardInfos: [] });
        onChange && onChange(newValue);
        !unifiedReward && this.updataMemberCardLevels(this.props);
    };

    changeRewardInfos = (rewardInfos: RewardInfos[]) => {
        const { value, onChange } = this.props;
        if (!value) return;
        const newValue = Object.assign({}, value, { rewardInfos });
        onChange && onChange(newValue);
    };

    render() {
        const { value = defaultValue, enableExperienceValue, rewardValueTitle } = this.props;
        const { unifiedReward, rewardInfos } = value;
        const { loading } = this.state;
        const subsiteId = this.getSubsiteIds(this.props);
        const selectedSubsite = !!subsiteId;
        rewardInfos.map((item: any) => {
            item.cardLevelName =
                item.cardLevelName === 'qbdj'
                    ? language.getText(item.cardLevelName)
                    : item.cardLevelName;
            return item;
        });
        return (
            <div className="member-card-level-point-reward">
                <Radio.Group onChange={this.changeUnifiedReward} value={unifiedReward}>
                    <Radio value={true}>{language.getText('sydjtygz')}</Radio>
                    <Radio value={false}>{language.getText('btdjbtgz')}</Radio>
                </Radio.Group>
                {!unifiedReward && !selectedSubsite && (
                    <div className="tips">{language.getText('selectBeforeMerchant')}</div>
                )}
                <MemberCardLevelPointRewardTable
                    loading={loading}
                    data={rewardInfos}
                    enableExperienceValue={enableExperienceValue}
                    rewardValueTitle={rewardValueTitle}
                    onChange={this.changeRewardInfos}
                />
            </div>
        );
    }
}
