import React, { PureComponent } from 'react';
import { get } from 'lodash';
import { default as Avatar, AvatarProps as AntAvatarProps } from 'antd/lib/avatar';
import { CustomStyleComponentProps } from '@comall-backend-builder/components-basis/lib/component-props';
import { localStorage } from '@comall-backend-builder/core/lib/services';
import { SessionsLoader } from '../../loaders/sessions-loader';
import './index.less';
type AntdProps = Pick<AntAvatarProps, 'icon' | 'shape' | 'size' | 'src'> &
    CustomStyleComponentProps;

interface UserProfileMiniProps extends CustomStyleComponentProps {
    apiPath: string;
    avatar: AntdProps;
}
export class UserProfileMini extends PureComponent<UserProfileMiniProps, any> {
    state = {
        user: {
            avatar: './images/avatar/avatar_1.png',
            name: '',
        },
    };
    static defaultProps: Partial<UserProfileMiniProps> = {
        avatar: {
            style: { backgroundColor: '#91d5ff' },
            size: 'small',
            icon: 'user',
        },
    };
    componentDidMount() {
        SessionsLoader.get().then(
            (response: any) => {
                this.setState({
                    user: {
                        name: get(response, 'realName', ''),
                        avatar: get(response, 'avatarUrl', './images/avatar/avatar_1.png'),
                    },
                });
                localStorage.set('USER_INFO', response);
            },
            (error) => {}
        );
    }
    render() {
        const { user } = this.state;
        let { avatar: avatarProps, className, style } = this.props;
        let { avatar, name } = user;

        if (avatar) {
            avatarProps.src = avatar;
        }
        return (
            <div className={`profile-mini ${className}`} style={style}>
                <Avatar {...avatarProps} />
                <span className="profile-mini-name">{name}</span>
            </div>
        );
    }
}
