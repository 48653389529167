import React, { PureComponent } from 'react';
import { Table, Button, InputNumber } from 'antd';
import { cloneDeep, remove, find, debounce } from 'lodash';
import { Coupon } from '../../containers/select-coupon';
import './index.less';
import { PaginationConfig } from 'antd/lib/table/interface';
import { services } from '@comall-backend-builder/core';

interface CouponSelectedDisplayProps {
    data: Array<Coupon>;
    onRemove: (data: Array<Coupon>) => void;
    disabled?: boolean;
    canChangeQuantity?: boolean;
    canChangeQuantityTitle?: string;
    xScrollWidth?: number;
    hideIssuedNum?: boolean;
    showLeftStock?: boolean;
}
interface CouponSelectedDisplayState {
    selectedRows: Array<Coupon>;
}

/**
 * 优惠券展示和内容编辑
 */
class CouponSelectedDisplay extends PureComponent<
    CouponSelectedDisplayProps,
    CouponSelectedDisplayState
> {
    getTableConfig = () => {
        const {
            disabled,
            canChangeQuantity,
            canChangeQuantityTitle,
            xScrollWidth,
            hideIssuedNum,
            showLeftStock,
        } = this.props;
        let tableConfig: any = {
            rowKey: 'id',
            columns: [
                {
                    title: services.language.getText('couponName'),
                    dataIndex: 'name',
                    key: 'name',
                    width: 150,
                },
            ],
            style: {
                marginTop: 20,
            },
        };
        if (showLeftStock) {
            tableConfig.columns.push({
                title: services.language.getText('sykc'),
                dataIndex: 'leftStock',
                key: 'leftStock',
                render: (leftStock: any) => {
                    return <>{leftStock ? leftStock : 0}</>;
                },
            });
        }
        tableConfig.columns.push(
            {
                title: services.language.getText('couponBaCode'),
                dataIndex: 'batchNo',
                key: 'batchNo',
            },
            {
                title: services.language.getText('couponType'),
                dataIndex: 'couponTypeDesc',
                key: 'couponTypeDesc',
            },
            {
                title: services.language.getText('setCouponType'),
                dataIndex: 'sourceDesc',
                key: 'sourceDesc',
            },
            {
                title: services.language.getText('couponStartTime'),
                dataIndex: 'startTime',
                key: 'startTime',
            },
            {
                title: services.language.getText('couponEndTime'),
                dataIndex: 'endTime',
                key: 'endTime',
            },
            {
                title: services.language.getText('usedChannelLimit'),
                dataIndex: 'consumeChannelDescription',
                key: 'consumeChannelDescription',
            }
        );
        if (!hideIssuedNum) {
            tableConfig.columns.push({
                title: services.language.getText('yffsl'),
                dataIndex: 'issuedNum',
                key: 'issuedNum',
                render: (issuedNum: any) => {
                    return <>{issuedNum ? issuedNum : 0}</>;
                },
            });
        }
        if (canChangeQuantity) {
            let title = services.language.getText('ffsl');
            if (
                window.location.href.includes('/wheel-draw') ||
                window.location.href.includes('/scratchCard')
            ) {
                title = services.language.getText('ffslmf');
            } else if (canChangeQuantityTitle) {
                title = canChangeQuantityTitle;
            }
            tableConfig.columns.push({
                title: title,
                fixed: 'right',
                dataIndex: 'quantity',
                key: 'quantity',
                render: (quantity: number, record: any) => {
                    return (
                        <InputNumber
                            defaultValue={1}
                            min={1}
                            max={record.stock ? record.stock : 999}
                            step={1}
                            precision={0}
                            value={quantity ? quantity : 1}
                            onChange={(value) => this.onChangeQuantity(value, record)}
                            disabled={disabled}
                        ></InputNumber>
                    );
                },
            });
        }
        tableConfig.columns.push({
            title: services.language.getText('common.tableAction'),
            className: 'action-column',
            fixed: 'right',
            key: 'operation',
            width: 100,
            render: (i: any, row: any) => {
                return (
                    <Button
                        disabled={disabled}
                        type={'link'}
                        onClick={this.onRemove(row)}
                        style={{ padding: '0' }}
                    >
                        {services.language.getText('common.delete')}
                    </Button>
                );
            },
        });
        if (xScrollWidth && xScrollWidth > 0) {
            tableConfig.style = {
                width: xScrollWidth,
            };
        }
        tableConfig.scroll = {
            x: true,
        };
        return tableConfig;
    };

    onChangeQuantity = debounce((value: any, record: any) => {
        const { data, onRemove } = this.props;
        let newData = data.slice(0);
        let row = find(newData, { id: record.id });
        if (row) {
            row.quantity = value;
            onRemove(newData);
        }
    }, 100);

    onRemove = (row: any) => {
        const { data, onRemove } = this.props;
        return () => {
            const result = cloneDeep(data);
            remove(result, (coupon) => row.id === coupon.id);
            onRemove(result);
        };
    };

    batchChangeValue = (field: string, value: any) => {};

    changeBatchInputValue = (field: string, value: any) => {};

    render() {
        const { data } = this.props;
        const pagination = false as PaginationConfig | false;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: data,
            pagination,
        };

        return <Table {...tableProps}></Table>;
    }
}

export { CouponSelectedDisplay };
