import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach } from 'lodash';

const api = services.api;
export const DeliveryBenefitsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/delivery_benefits';
        const subsiteName = data.subsiteName;
        if (subsiteName) {
            data.subsiteName = subsiteName.name ? subsiteName.name : subsiteName;
        }
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/delivery_benefits/${id}`;
        }

        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.subsiteIds = res.subsites;
                res.baseInfo = {
                    name: res.name,
                    dateRange: {
                        start: res.startTime,
                        end: res.endTime,
                    },
                };
                res.rulesInfo = {
                    deliveryMode: res.deliveryMode.id.toString(),
                    rewardType: res.promotionRewardDtos[0].rewardType,
                    promotionConditionType: res.promotionConditionType,
                    createRewards: res.promotionRewardDtos.map((item: any) => {
                        return {
                            stepValue: item.lowerValue,
                            rewardValue: item.rewardValue,
                        };
                    }),
                };
            }
            return res;
        });
    },

    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/delivery_benefits';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },

    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        if (params.id) {
            config.apiPath = `/admin/delivery_benefits/${params.id}`;
        }
        const newParams: any = dataParser(params);
        newParams.id = params.id;
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    let createPromotionInfo: any = {},
        createRewardConfig: any = {},
        createDimensions: any = {
            SUBSITE: {
                dimensionIds: params.subsiteIds.map((item: any) => {
                    return item.id;
                }),
            },
            DELIVERY: { dimensionIds: [params.rulesInfo.deliveryMode] },
        };

    if (params.baseInfo) {
        createPromotionInfo.name = params.baseInfo.name;
        createPromotionInfo.startTime = params.baseInfo.dateRange.start;
        createPromotionInfo.endTime = params.baseInfo.dateRange.end;
    }
    if (params.rulesInfo) {
        createPromotionInfo.promotionConditionType = params.rulesInfo.promotionConditionType;
        createRewardConfig.rewardType = params.rulesInfo.rewardType;
        createRewardConfig.createRewards = params.rulesInfo.createRewards.map(
            (item: any, index: any) => {
                const reward = {
                    // stepValue: item.stepValue,
                    rewardValue: item.rewardValue,
                    moneyUnit: 'SUBTRACT',
                    rewardRangeType:
                        index === params.rulesInfo.createRewards.length - 1
                            ? 'OVER_RANGE'
                            : 'RANGE',
                    lowerValue: item.stepValue,
                    upperValue:
                        index === params.rulesInfo.createRewards.length - 1
                            ? null
                            : params.rulesInfo.createRewards[index + 1].stepValue,
                };
                return reward;
            }
        );
    }
    newParams = {
        createDimensions,
        createPromotionInfo,
        createRewardConfig,
    };
    return newParams;
}

export const DeliveryModesLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`;
        config.apiPath = '/admin/delivery_modes?';
        if (data.subsiteIds) {
            forEach(data.subsiteIds, (item, index) => {
                if (Number(index) === data.subsiteIds.length - 1) {
                    config.apiPath += `subsite_ids=${item.id}`;
                } else {
                    config.apiPath += `subsite_ids=${item.id}&`;
                }
            });
        } else if (data.merchantIds) {
            forEach(data.merchantIds, (item, index) => {
                if (Number(index) === data.merchantIds.length - 1) {
                    config.apiPath += `merchant_ids=${item.merchantId}`;
                } else {
                    config.apiPath += `merchant_ids=${item.merchantId}&`;
                }
            });
        } else {
            return [];
        }
        return await api.get({}, config).then((res: any) => {
            return res ? res : [];
        });
    },
};
