import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { Card, Tabs } from 'antd';
import { services } from '@comall-backend-builder/core';

import './index.less';

const api = services.api;
const { TabPane } = Tabs;

class cardCollectionReportView extends PureComponent<any> {
    state = {
        reportData: undefined,
    };
    componentDidMount() {
        const { id } = this.props.params;
        const apiPath = '/admin/card_collections/:id/activity_data';
        const apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        api.get({ id }, { apiRoot: apiRoot, apiPath: apiPath }).then((res: any) => {
            this.setState({
                reportData: res,
            });
        });
    }

    render() {
        const { reportData } = this.state;
        if (!reportData) return null;
        const {
            cardGiveCount,
            collectionSuccessCount,
            helpCount,
            registerCount,
            name,
        } = reportData as any;
        return (
            <Fragment>
                <Tabs type="card" className="card-collection-report">
                    {!!reportData ? (
                        <TabPane tab={name}>
                            <Card
                                title={services.language.getText('jkhdsj')}
                                type="inner"
                                style={{ marginBottom: 32 }}
                            >
                                <div className="card-collection-report-wrap">
                                    <div className="card-collection-report-item card-collection-report-detail-item">
                                        <div className="card-collection-report-item-title">
                                            {services.language.getText('jkcgrs')}
                                        </div>
                                        <div className="card-collection-report-item-content">
                                            {collectionSuccessCount || 0}
                                        </div>
                                    </div>

                                    <div className="card-collection-report-item card-collection-report-detail-item">
                                        <div className="card-collection-report-item-title">
                                            {services.language.getText('kpzscs')}
                                        </div>
                                        <div className="card-collection-report-item-content">
                                            {cardGiveCount || 0}
                                        </div>
                                    </div>
                                    <div className="card-collection-report-item card-collection-report-detail-item">
                                        <div className="card-collection-report-item-title">
                                            {services.language.getText('yqzlcs')}
                                        </div>
                                        <div className="card-collection-report-item-content">
                                            {helpCount || 0}
                                        </div>
                                    </div>
                                    <div className="card-collection-report-item card-collection-report-detail-item">
                                        <div className="card-collection-report-item-title">
                                            {services.language.getText('yqzcrs')}
                                        </div>
                                        <div className="card-collection-report-item-content">
                                            {registerCount || 0}
                                        </div>
                                    </div>
                                </div>
                            </Card>
                        </TabPane>
                    ) : (
                        ''
                    )}
                </Tabs>
            </Fragment>
        );
    }
}

function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
    };
}

export const CardCollectionReportView = connect(mapStateToProps)(cardCollectionReportView);
