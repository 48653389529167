import React, { Component } from 'react';
import { connect } from 'react-redux';
import { chunk, get } from 'lodash';
import './index.less';
import { Icon } from 'antd';
import { services } from '@comall-backend-builder/core';

const prefix = 'stored-value-card-config-preview';
/**
 * 页面配置左侧
 * @param props
 */
class storedValueCardConfigPreview extends Component<any, { themeStyle: any }> {
    private get showMenus() {
        const menusConfig = this.getField('menusConfig.service') || [];
        return menusConfig?.filter((item: any) => item.visible) || [];
    }

    getField = (field: string) => {
        const { preview } = this.props;
        return get(preview, field);
    };

    renderService = () => {
        const service = this.getField('menusConfig.service') || undefined;
        if (!service || service.length === 0) {
            return null;
        }
        const menus = this.showMenus;
        const groups = chunk(menus, 3);
        const innerClassNames = {
            serviceWrap: `${prefix}__service-wrap`,
            service: `${prefix}__service`,
            serviceItem: `${prefix}__service-item`,
            serviceItemImgWrap: `${prefix}__service-item-img-wrap`,
            serviceItemImg: `${prefix}__service-item-img`,
            serviceItemName: `${prefix}__service-item-name`,
        };
        return (
            <div className={innerClassNames.serviceWrap}>
                {groups.map((group: any) => (
                    <div className={innerClassNames.service}>
                        {group.map((s: any) => (
                            <div className={innerClassNames.serviceItem}>
                                <div className={innerClassNames.serviceItemImgWrap}>
                                    <img
                                        className={innerClassNames.serviceItemImg}
                                        alt=""
                                        src={s.picture ? s.picture.path : undefined}
                                    />
                                </div>
                                <div className={innerClassNames.serviceItemName}>{s.name}</div>
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        );
    };

    renderImageAds = () => {
        const advertisingSpace = this.getField('pageConfig.advertisingSpace');
        if (!advertisingSpace || advertisingSpace.length === 0) {
            return null;
        }
        const innerClassNames = {
            advWrap: `${prefix}__adv-wrap`,
            advWrapImage: `${prefix}__adv-wrap__img`,
        };
        return (
            <div className={innerClassNames.advWrap}>
                {advertisingSpace.map((adv: any) => {
                    return (
                        <div>
                            <img
                                alt=""
                                className={innerClassNames.advWrapImage}
                                src={
                                    adv.picture && adv.picture.length > 0
                                        ? adv.picture[0].path
                                        : undefined
                                }
                            />
                        </div>
                    );
                })}
            </div>
        );
    };

    renderHeader = () => {
        const showSubsiteWidget = this.getField('baseConfig.showSubsiteWidget');
        if (showSubsiteWidget === 'false') {
            return null;
        }
        return (
            <div className="store-header-container">
                <img
                    alt=""
                    src="https://b-puzhehei-cdn.co-mall.net/global/location.svg"
                    className="store-header-container__icon-pic"
                />
                <div className="store-header-container__store-header-text">
                    <span className="store-header-container__store-header-text__span">
                        {services.language.getText('mdzj_1')}
                    </span>
                    <span>
                        <Icon type="down" />
                    </span>
                </div>
            </div>
        );
    };

    renderAsset = () => {
        const name = this.getField('assetConfig.name');
        const balanceName = this.getField('assetConfig.balanceName');
        const balanceDescription = this.getField('assetConfig.balanceDescription');
        const backgroundPicture = this.getField('assetConfig.backgroundPicture');
        const showPayCode = this.getField('assetConfig.showPayCode');
        const showCard = this.getField('assetConfig.showCard');
        const showCardName = this.getField('assetConfig.showCardName');

        const backgroundImageUrl =
            backgroundPicture && backgroundPicture.length > 0 ? backgroundPicture[0].path : '';
        const backgroundImageStyle: any = {
            backgroundImage: backgroundImageUrl ? `url(${backgroundImageUrl})` : '',
            backgroundRepeat: 'no-repeat',
            backgroundSize: '100%',
        };
        const innerClassNames = {
            asset: `${prefix}__asset`,
            assetName: `${prefix}__asset__name`,
            assetCardWrap: `${prefix}__asset__card-wrap`,
            assetCard: `${prefix}__asset__card`,
            assetBalanceName: `${prefix}__asset__balance-name`,
            assetBalanceNameIcon: `${prefix}__asset__balance-name-icon`,
            assetPayMode: `${prefix}__asset__pay-mode`,
            assetPrice: `${prefix}__asset__price`,
            assetCardName: `${prefix}__asset__card-name`,
            assetCardArrowRight: `${prefix}__asset__card-arrow-right`,
        };
        const showMode = this.getField('baseConfig.showMode') || '1';
        return (
            <div className={innerClassNames.asset}>
                {name && showMode === '1' && (
                    <div className={innerClassNames.assetName}>{name}</div>
                )}
                <div className={innerClassNames.assetCard} style={backgroundImageStyle}>
                    {balanceName && (
                        <div className={innerClassNames.assetBalanceName}>
                            {balanceName}
                            {balanceDescription && (
                                <img
                                    alt=""
                                    src="https://b-puzhehei-cdn.co-mall.net/global/stored-value-card/icon-tip.png"
                                    className={innerClassNames.assetBalanceNameIcon}
                                />
                            )}
                        </div>
                    )}
                    {showPayCode === 'true' && (
                        <img
                            alt=""
                            className={innerClassNames.assetPayMode}
                            src="https://b-puzhehei-cdn.co-mall.net/global/stored-value-card/icon-paycode.png"
                        />
                    )}
                    <div className={innerClassNames.assetPrice}>0</div>
                    {showCard === 'true' && (
                        <div className={innerClassNames.assetCardName}>
                            {showCardName}
                            <img
                                alt=""
                                className={innerClassNames.assetCardArrowRight}
                                src="https://b-puzhehei-cdn.co-mall.net/global/stored-value-card/icon-arrow-right.png"
                            />
                        </div>
                    )}
                </div>
            </div>
        );
    };

    render() {
        const pageTitle = this.getField('baseConfig.pageTitle') || undefined;
        const showMode = this.getField('baseConfig.showMode') || '1';
        return (
            <div className={prefix}>
                <div className="theme-style-preview-item">
                    <img alt="" style={{ width: '100%' }} src="./images/avatar/theme-head.png" />
                    <div className="theme-style-preview-cart-title">
                        {pageTitle && pageTitle.length > 10
                            ? `${pageTitle.slice(0, 10)}...`
                            : pageTitle}
                    </div>
                </div>
                {this.renderHeader()}
                {showMode === '1' && (
                    <div>
                        {this.renderService()}
                        {this.renderAsset()}
                    </div>
                )}
                {showMode === '2' && (
                    <div>
                        {this.renderAsset()}
                        {this.renderService()}
                    </div>
                )}
                {/*{this.renderImageAds()}*/}
            </div>
        );
    }
}

const mapStateToProps = (_state: any, props: any) => {
    let preview = null;
    const entityId = props.entity.id;
    for (var compId in _state.components) {
        const comp = _state.components[compId];
        if (
            (comp.type === 'CreateForm' || comp.type === 'EditForm') &&
            comp.entityId === entityId
        ) {
            preview = comp.fields;
        }
    }

    return { preview: preview };
};

export const StoredValueCardConfigPreview = connect(mapStateToProps)(storedValueCardConfigPreview);
