import * as React from 'react';
import classNames from 'classnames';
import { map, find } from 'lodash';
import { ArrayMode } from '@comall-backend-builder/types';
import { MemberLevelParkUnbind } from '../../../components';
import { language } from '@comall-backend-builder/core/lib/services';

export class ArrayMemberLevelParkUnbindMode extends ArrayMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(array: any, displayInfo: any) {
        const { className, style, options } = displayInfo;
        if (array && array.length > 0) {
            return (
                <div className={classNames('type-array', className)} style={style}>
                    {map(array, ({ userLevelId, maxUnbindQuantity }, index) => {
                        let userLevelIdOption = find(options, { id: userLevelId }) || {};
                        return (
                            <div key={index}>
                                <span>
                                    {language.getText('cardLevel')}： {userLevelIdOption.name}{' '}
                                </span>
                                <span>
                                    {language.getText('jb_2')}
                                    {maxUnbindQuantity}
                                    {language.getText('cs_1')}
                                </span>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return this.getNotAvailableDisplayComponent(displayInfo);
        }
    }
    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            language.getText('notData')
        );
    }
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <MemberLevelParkUnbind {...controlInfo} />;
    }
}
