// @ts-nocheck
import React, { PureComponent } from 'react';
import { Modal, Upload, Icon, Button as AntButton, message } from 'antd';
import { ExportButton } from '@comall-backend-builder/components-basis';
import { api } from '@comall-backend-builder/core/lib/services';
import { errorHandle } from '../../../services/error-handle';
import './index.less';
export class UploadFile extends PureComponent<any, any> {
    state = { fileList: [], loading: false };
    handleOk = () => {
        const { fileList } = this.state;
        const { onClose } = this.props;
        if (fileList.length <= 0) {
            message.error(services.language.getText('qxzwj'));
            return;
        }
        this.setState({ loading: true });
        api.upload(
            { files: fileList[0] },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/CAE-PRIVILEGE`,
                apiPath: '/admin/users/excels',
                fileName: 'file',
                progressCallBack: () => {},
            }
        )
            .then((res) => {
                if (res.badUser === '1') {
                    this.getErrorExcel();
                } else {
                    message.success('导入成功');
                }
                onClose();
            })
            .catch(errorHandle)
            .finally(() => {
                this.setState({ loading: false });
            });
    };
    getErrorExcel = () => {
        api.download(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/CAE-PRIVILEGE`,
                apiPath: '/admin/users/bad_users.xlsx',
            }
        ).catch(errorHandle);
    };
    handleCancel = () => {
        this.props.onClose();
    };
    render() {
        const { fileList, loading } = this.state;
        const uploadProps = {
            onRemove: (file: any) => {
                this.setState((state: any) => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: (file: any) => {
                this.setState((state: any) => ({
                    fileList: [file],
                }));
                return false;
            },
            fileList,
        };
        const { visible } = this.props;
        return (
            <Modal
                visible={visible}
                title="导入用户"
                closable={false}
                footer={[
                    <AntButton
                        key="submit"
                        type="primary"
                        loading={loading}
                        onClick={this.handleOk}
                    >
                        提交
                    </AntButton>,
                    <AntButton key="back" onClick={this.handleCancel}>
                        {services.language.getText('back')}
                    </AntButton>,
                ]}
            >
                <div className="upload-tips">
                    下载
                    <ExportButton
                        type="link"
                        text="导入用户Excel模板"
                        showErrorMessage={true}
                        source={{
                            apiRoot: `${ENV.AUTH_API_ROOT}/CAE-PRIVILEGE`,
                            apiPath: '/admin/users/template.xlsx',
                        }}
                    />
                    ，按要求填写完毕后上传
                </div>
                <div>
                    {services.language.getText('upload')}：
                    <Upload {...uploadProps}>
                        <AntButton>
                            <Icon type="upload" />
                            选择文件
                        </AntButton>
                    </Upload>
                </div>
            </Modal>
        );
    }
}
