import React, { PureComponent } from 'react';
import { Form, Slider } from 'antd';
import { tools } from '@comall-backend-builder/design-components-basis';
import FormItem from 'antd/lib/form/FormItem';

class weixin extends PureComponent<any> {
    render() {
        const { form } = this.props;
        const { getFieldDecorator } = form;
        const formItemLayout = tools.FormItemLayout;
        return (
            <Form className="weixin-content">
                <FormItem {...formItemLayout}>
                    <p className="weixin-content-tips">
                        使用关注公众号组件前,注意授权小程序公众号组件
                    </p>
                    <p>每个页面仅支持添加一个公众号组件</p>
                </FormItem>
                <FormItem {...formItemLayout} label={'宽度比例:'}>
                    {getFieldDecorator('width', {
                        initialValue: 750,
                    })(<Slider min={600} max={750} step={1} />)}
                </FormItem>
            </Form>
        );
    }
}

export const WeiXin = tools.ConfigFormDecorator(weixin);
