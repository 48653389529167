import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { api } from '@comall-backend-builder/core/lib/services';

export const CouponUnConsumeLoader: Loader = {
    async get(data, config) {
        if (data.unconsumeTime) {
            data = {
                ...data,
                startTime: data.unconsumeTime.start,
                endTime: data.unconsumeTime.end,
                unconsumeTime: undefined,
            };
        }

        return api.get(data, {
            ...config,
            apiPath: '/admin/coupon_unconsume_records',
        });
    },
};
