import { ArrayFormat } from '@comall-backend-builder/types';
import { language } from '@comall-backend-builder/core/lib/services';

export class ArrayAndObjectFormat extends ArrayFormat {
    /**
     * 对数据进行校验
     */
    public validate(rule: any, value: any, callback: any) {
        try {
            if (typeof value !== 'object') {
                JSON.parse(value);
            }
            callback();
        } catch (error) {
            callback(language.getText('validation.object.default'));
        }
    }
}
