import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { CreditShopStock } from './credit-shop-stock';

export class CreditShopStockMode extends modes.ObjectMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <CreditShopStock {...displayInfo} />;
    }
}
