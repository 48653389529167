import React, { PureComponent } from 'react';
import './index.less';
/**
 * 可视化组件选择器
 */
export class CustomComponentSelector extends PureComponent<{ onSelect: (value: any) => void }> {
    baseComponents = [
        {
            id: 'cgbRecruitLeaderAdv',
            name: '招募团长',
        },
    ];

    onSelect = (id: string) => {
        return () => this.props.onSelect(id);
    };

    render() {
        const base = this.baseComponents;
        return (
            <div className="custom-component-selector">
                <div className="group">
                    <div className="group-content">
                        {base.map((i) => (
                            <div className="item" onClick={this.onSelect(i.id)}>
                                {i.name}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
