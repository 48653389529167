import { Entity } from '@comall-backend-builder/core/lib/parser';
import { get } from 'lodash';
import React from 'react';
import TopicsDisplayTable from './topic-display-table';
import TopicSelectoraModal from './topic-selector-modal';

interface Topic {
    id: string;
    name: string;
    subsites: any;
    enabled: boolean;
}
interface Props {
    value?: Topic[];
    onChange?(value: Topic[]): void;
    entity: Entity;
    row: any;
}

export function WechatOfficialAccountTopicSelector(props: Props) {
    const { entity, value, onChange, row } = props;
    const id = get(entity, 'params.id');
    const hasValue = Boolean(value?.length);
    const onRemove = (s: Topic) => {
        const newValue = value!.filter(({ id }) => String(id) !== String(s.id));
        onChange?.([...newValue]);
    };
    if (row.subsites && row.subsites.length === 0 && hasValue) {
        onChange?.([]);
    }
    return (
        <div>
            <TopicSelectoraModal id={id} value={value} onChange={onChange} />
            {hasValue ? <TopicsDisplayTable value={value} onRemove={onRemove} /> : null}
        </div>
    );
}
