import { Config } from '@comall-backend-builder/core/lib/parser';

export enum PrintTempalteType {
    /**
     * 模板一
     */
    TEMP1 = 'A4',
    /**
     * 模板二
     */
    TEMP2 = 'TICKET',
    /**
     * 模板三
     */
    TEMP3 = 'TICKET_INVOICE',
}

export const config: Config = {
    entities: {
        printSettingEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/printSetting',
            properties: {
                printConfig: {
                    type: 'object.subForm',
                    displayName: '<<printConfig>>',
                    properties: {
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            rules: [{ required: true }],
                        },
                        radioType: {
                            type: 'object.imageRadio.print',
                            displayName: '<<xzdymb>>',
                        },
                        remark: {
                            type: 'string',
                            displayName: '<<remark>>',
                            rules: [{ max: 200, message: '<<bzzd>>200<<z_6>>' }],
                            extra: '<<h_print_remark_tip>>',
                        },
                    },
                },
            },
        },
    },
    components: {
        printSettingView: {
            component: 'Viewport',
            entity: 'printSettingEntity',
            style: {
                padding: '0 32px 24px 32px',
            },
        },
        printSettingPage: {
            component: 'Card',
            entity: 'printSettingEntity',
            content: { component: 'PrintConfigFormView' },
        },
        printSettingEditForm: {
            component: 'ModifyForm',
            loaderType: 'get',
            direction: 'horizontal',
            entity: 'printSettingEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'printConfig.subsites',
                },
                { property: 'printConfig.radioType' },
                { property: 'printConfig.remark' },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'submit',
                        text: '<<common.save>>',
                        style: { marginLeft: 10, float: 'right' },
                    },
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10, float: 'right' },
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/printSetting',
                    component: 'printSettingView',
                    breadcrumbName: '<<printConfig>>',
                    privilege: {
                        path: 'printConfig',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'printSettingPage' }],
                },
            ],
        },
    ],
};
