import { Config } from '@comall-backend-builder/core/lib/parser';

export let config: Config = {
    components: {
        MenuListView: {
            component: 'Viewport',
            entity: 'privileges',
        },
        MenuListPage: {
            entity: 'privileges',
            loaderType: 'get',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'MeunList',
                    items: [
                        {
                            key: 'market',
                            title: '<<marketActivity>>',
                            privilege: {
                                path: 'market',
                                level: 'full',
                            },
                            parentItems: [
                                {
                                    key: 'attractTrafficNewCustomers',
                                    subTitle: '<<attractTrafficNewCustomers>>',
                                    items: [
                                        {
                                            key: 'invitationActivity',
                                            title: '<<yqyl>>',
                                            description: '<<hxk>>',
                                            iconClassName: 'icon-invitation',
                                            route: '/menu-list/market/invitation-activity',
                                            privilege: {
                                                path: 'invitationActivity',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'eventMarket',
                                            title: '<<sjyx>>',
                                            description: '<<cfbtsj>>',
                                            iconClassName: 'icon-event-market',
                                            route: '/menu-list/market/eventMarket',
                                            privilege: {
                                                path: 'eventMarket',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'followWechatOfficialAccount',
                                            title: '<<gzgzhyl>>',
                                            description: '<<gzgzhylxx>>',
                                            iconClassName: 'icon-wechat-official-account-manger',
                                            route: '/wechat-official-accounts',
                                            privilege: {
                                                path: 'followWechatOfficialAccount',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            type: 'item',
                                            key: '<<fxyl>>',
                                            title: '<<fxyl>>',
                                            description: '<<jlfxyh>>',
                                            iconClassName: 'icon-share-page',
                                            route: '/menu-list/market/share-page-activity',
                                            privilege: {
                                                path: 'sharePageActivity',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'bargainActivity',
                                            title: '<<kj>>',
                                            description: '<<bkydjgyd>>',
                                            iconClassName: 'icon-bargain',
                                            route: '/menu-list/market/bargain-activity',
                                            privilege: {
                                                path: 'bargain',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'groupBuyingRule',
                                            title: '<<pthd>>',
                                            description: '<<ydyhyqpyyqptgm>>',
                                            iconClassName: 'icon-group-buying',
                                            route: '/menu-list/market/group-buying',
                                            privilege: {
                                                path: 'groupBuyingRule',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'cgb',
                                            title: '<<communityGroupBuying>>',
                                            description: '<<sqbqd>>',
                                            iconClassName: 'icon-cgb',
                                            route: '/menu-list/market/cgb-manage',
                                            privilege: {
                                                path: 'cgb',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                },
                                {
                                    key: 'increaseSalesVolume',
                                    subTitle: '<<increaseSalesVolume>>',
                                    items: [
                                        {
                                            key: 'payAward',
                                            title: '<<zfyl>>',
                                            description: '<<gkfkhhdjl>>',
                                            iconClassName: 'icon-pay-with-gifts',
                                            route: '/menu-list/market/pay-with-gifts',
                                            privilege: {
                                                path: 'payAward',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'presale',
                                            title: '<<qkys>>',
                                            description: '<<sjyhgmyx>>',
                                            iconClassName: 'icon-presale',
                                            route: '/menu-list/market/full-payment-activities',
                                            privilege: {
                                                path: 'fullPaymentActivity',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'presaleActivity',
                                            title: '<<yyqg>>',
                                            description: '<<xyyhgm>>',
                                            iconClassName: 'icon-presale-activity',
                                            route: '/menu-list/market/presale-activities',
                                            privilege: {
                                                path: 'presaleActivity',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'reserveDrawLots',
                                            title: '<<yycq>>',
                                            description: '<<xbmhcq>>',
                                            iconClassName: 'icon-reserve-draw-lots',
                                            route: '/menu-list/market/reserve-draw-lots',
                                            privilege: {
                                                path: 'reserveDrawLots',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'reserveDrawLotsImport',
                                            title: '<<yycqdr>>',
                                            description: '<<xbmhcq>>',
                                            iconClassName: 'icon-reserve-draw-lots',
                                            route: '/menu-list/market/reserve-draw-lots-import',
                                            privilege: {
                                                path: 'reserveDrawLotsImport',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'collection-points',
                                            title: '<<collectionPoints>>',
                                            description: '<<collectionPointsTip>>',
                                            iconClassName: 'icon-collection-point',
                                            route: '/menu-list/market/collection-points',
                                            privilege: {
                                                path: 'collectionPoint',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                },
                                {
                                    key: 'marketing',
                                    subTitle: '<<marketing>>',
                                    items: [
                                        {
                                            key: 'miniprogram',
                                            title: '<<qdtgm>>',
                                            description: '<<hdtgdgxylgj>>',
                                            iconClassName: 'icon-qrcode-rule',
                                            route: '/menu-list/market/miniprogram',
                                            privilege: {
                                                path: 'miniProgramCode',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'miniProgramV2',
                                            title: '<<qdtgm>>',
                                            description: '<<hdtgdgxylgj>>',
                                            iconClassName: 'icon-qrcode-rule',
                                            route: '/menu-list/market/miniprogram-v2',
                                            privilege: {
                                                path: 'miniProgramCodeV2',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'qrcodeRule',
                                            title: '<<ewmtg>>',
                                            description: '<<yxdjxhtgyy>>',
                                            iconClassName: 'icon-qrcode-rule',
                                            route: '/menu-list/market/qrcode-rule',
                                            privilege: {
                                                path: 'qrCodeRule',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'liveStream',
                                            title: '<<zbj>>',
                                            description: '<<tgzbdh>>',
                                            iconClassName: 'icon-live-stream',
                                            route: '/menu-list/market/live-room-product',
                                            privilege: {
                                                path: 'liveStream',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'shoppingListUnionOrder',
                                            title: '<<dkxd>>',
                                            description: '<<dgywyhcjgwqd>>',
                                            iconClassName: 'icon-shopping-list',
                                            route: '/menu-list/market/shopping-list',
                                            privilege: {
                                                path: 'shoppingListUnionOrder',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'distribution',
                                            title: '<<qmfx>>',
                                            description: '<<yhfxsp>>',
                                            iconClassName: 'icon-distribution',
                                            route: '/menu-list/market/distribution',
                                            privilege: {
                                                path: 'distributionManager',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'guideDistribution',
                                            title: '<<dgfx>>',
                                            description: '<<dgyfxsp>>',
                                            iconClassName: 'icon-guide-distribution',
                                            route: '/menu-list/market/guide-distribution',
                                            privilege: {
                                                path: 'guideDistributionManager',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'internalPurchase',
                                            title: '<<internalPurChase>>',
                                            description: '<<whzqykznbflhd>>',
                                            iconClassName: 'icon-internal-purchase',
                                            route: '/menu-list/market/internal-purchase',
                                            privilege: {
                                                path: 'enterpriseInternalPurchase',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                },
                                {
                                    key: 'promoteInteractiveActivities',
                                    subTitle: '<<promoteInteractiveActivities>>',
                                    items: [
                                        {
                                            key: 'checkinActivity',
                                            title: '<<qdyl>>',
                                            description: '<<mrqdlqjfhjl>>',
                                            iconClassName: 'icon-checkin',
                                            route: '/menu-list/market/checkin-activity',
                                            privilege: {
                                                path: 'checkinActivity',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'improveInformation',
                                            title: '<<wsxxyl>>',
                                            description: '<<sjhyxx>>',
                                            iconClassName: 'icon-improve-information',
                                            route: '/menu-list/market/improve-information',
                                            privilege: {
                                                path: 'improveInformation',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'wheelDraw',
                                            title: '<<dzp>>',
                                            description: '<<yhbdzp>>',
                                            iconClassName: 'icon-wheel-draw',
                                            route: '/menu-list/market/wheel-draw',
                                            privilege: {
                                                path: 'wheelDraw',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'wheelDraw',
                                            title: '<<watchwordCouponCollection>>',
                                            description: '<<watchwordCouponCollectionDescription>>',
                                            iconClassName: 'icon-watch-word',
                                            route: '/menu-list/market/watchword',
                                            privilege: {
                                                path: 'watchword',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'openBox',
                                            title: '<<khyl>>',
                                            description: '<<yhcklh>>',
                                            iconClassName: 'icon-open-box',
                                            route: '/menu-list/market/open-box',
                                            privilege: {
                                                path: 'openBoxActivity',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'blindBoxMarketing',
                                            title: '<<mhyx>>',
                                            description: '<<czmsp>>',
                                            iconClassName: 'icon-blind-box',
                                            route: '/menu-list/market/blind-box',
                                            privilege: {
                                                path: 'blindBoxMarketing',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'cardCollectionActivity',
                                            title: '<<jkhd>>',
                                            description: '<<yhjkljl>>',
                                            iconClassName: 'icon-card-collection',
                                            route: '/menu-list/market/cardCollection',
                                            privilege: {
                                                path: 'cardCollectionActivity',
                                            },
                                        },
                                        {
                                            key: 'redPackageRain',
                                            title: '<<hby>>',
                                            description: '<<xshby>>',
                                            iconClassName: 'icon-red-package-rain',
                                            route: '/menu-list/market/red-package-rain',
                                            privilege: {
                                                path: 'redPackageRain',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'luckyRedPackage',
                                            title: '<<psqhb>>',
                                            description: '<<psqhbDes>>',
                                            iconClassName: 'icon-lucky-red-package',
                                            route: '/menu-list/market/lucky-red-package',
                                            privilege: {
                                                path: 'luckyRedPackage',
                                                level: 'full',
                                            },
                                        },
                                        {
                                            key: 'slotMachines',
                                            title: '<<lhj>>',
                                            description: '<<yhtglhjcj>>',
                                            iconClassName: 'icon-distribution',
                                            route: '/menu-list/market/slot-machines',
                                            privilege: {
                                                path: 'slotMachine',
                                                level: 'full',
                                            },
                                        },
                                    ],
                                },
                            ],
                        },
                        {
                            key: 'coupon',
                            title: '<<coupon>>',
                            privilege: {
                                path: 'coupon',
                                level: 'full',
                            },
                            items: [
                                {
                                    type: 'item',
                                    key: '<<cash>>',
                                    title: '<<cash>>',
                                    description: '<<cjxftsdcgmjz>>',
                                    iconClassName: 'icon-cash-coupon',
                                    route: '/menu-list/coupon/cash-coupon-rules',
                                    privilege: {
                                        path: 'cashCouponRule',
                                        level: 'full',
                                    },
                                },
                                {
                                    type: 'item',
                                    key: '<<gift>>',
                                    title: '<<gift>>',
                                    description: '<<xygkxf>>',
                                    iconClassName: 'icon-gift-coupon',
                                    route: '/menu-list/coupon/gift-coupon-rules',
                                    privilege: {
                                        path: 'giftCouponRule',
                                        level: 'full',
                                    },
                                },
                                {
                                    type: 'item',
                                    key: '<<freight>>',
                                    title: '<<freight>>',
                                    description: '<<jdgwmktsxfzhl>>',
                                    iconClassName: 'icon-freight-coupon',
                                    route: '/menu-list/coupon/freight-coupon-rules',
                                    privilege: {
                                        path: 'freightCouponRule',
                                        level: 'full',
                                    },
                                },
                                {
                                    type: 'item',
                                    key: '<<parking>>',
                                    title: '<<parking>>',
                                    description: '<<tcwyylsqxygkdd>>',
                                    iconClassName: 'icon-parking-coupon',
                                    route: '/menu-list/coupon/parking-coupon-rules',
                                    privilege: {
                                        path: 'parkingCouponRule',
                                        level: 'full',
                                    },
                                },
                                {
                                    type: 'item',
                                    key: '<<package>>',
                                    title: '<<package>>',
                                    description: '<<yhqdbff>>',
                                    iconClassName: 'icon-coupon-package',
                                    route: '/menu-list/coupon/coupon-package-rules',
                                    privilege: {
                                        path: 'couponPackageRule',
                                        level: 'full',
                                    },
                                },
                                {
                                    type: 'item',
                                    key: '<<dxfq>>',
                                    title: '<<dxfq>>',
                                    description: '<<jzqclgxzzzlyjzz>>',
                                    iconClassName: 'icon-giving-coupon',
                                    route: '/menu-list/coupon/giving-coupon',
                                    privilege: {
                                        path: 'givingCouponSchedule',
                                        level: 'full',
                                    },
                                },
                                {
                                    type: 'item',
                                    key: '<<consumeAndExchangeRecord>>',
                                    title: '<<consumeAndExchangeRecord>>',
                                    description: '<<yykkhxdyhqjl>>',
                                    iconClassName: 'icon-coupon-consume-history',
                                    route: '/menu-list/coupon/coupon-consume-history',
                                    privilege: {
                                        path: 'couponConsumeOrderItems',
                                        level: 'full',
                                    },
                                },
                                {
                                    type: 'item',
                                    key: '<<lottery>>',
                                    title: '<<lottery>>',
                                    description: '<<yylhjcjhd>>',
                                    iconClassName: 'icon-lottery-coupon',
                                    route: '/menu-list/coupon/lottery-coupon',
                                    privilege: {
                                        path: 'lotteryCouponRule',
                                        level: 'full',
                                    },
                                },
                                {
                                    type: 'item',
                                    key: '<<couponManage>>',
                                    title: '<<couponManage>>',
                                    description: '<<tyjqzhxyhq>>',
                                    iconClassName: 'icon-coupon-manage',
                                    route: '/menu-list/coupon/coupon-manage',
                                    privilege: {
                                        path: 'couponManager',
                                        level: 'full',
                                    },
                                },
                                {
                                    type: 'item',
                                    key: '<<balance>>',
                                    title: '<<balance>>',
                                    description: '<<zhczqhxcskhzxl>>',
                                    iconClassName: 'icon-balance-coupon',
                                    route: '/menu-list/coupon/balance-coupon-rules',
                                    privilege: {
                                        path: 'balanceCouponRule',
                                        level: 'full',
                                    },
                                },
                                {
                                    type: 'item',
                                    key: '<<bfplat>>',
                                    title: '<<bfplat>>',
                                    description: '<<bfplatTip>>',
                                    iconClassName: 'icon-bfplat-coupon',
                                    route: '/menu-list/coupon/bfplat-coupon-rules',
                                    privilege: {
                                        path: 'bfplatCouponRule',
                                        level: 'full',
                                    },
                                },
                                {
                                    type: 'item',
                                    key: '<<fjyhq>>',
                                    title: '<<fjyhq>>',
                                    description: '<<fjqldxsxxxfwj>>。',
                                    iconClassName: 'icon-fuji-coupon',
                                    route: '/menu-list/coupon/efuture-coupon-rules',
                                    privilege: {
                                        path: 'eFutureCouponRule',
                                        level: 'full',
                                    },
                                },
                                {
                                    type: 'item',
                                    key: '<<pzzzfm>>',
                                    title: '<<pzzzfm>>',
                                    description: '<<yypzzzyhqsfxfm>>',
                                    iconClassName: 'icon-transfer-coupon',
                                    route: '/menu-list/coupon/coupon-transfer-cover',
                                    privilege: {
                                        path: 'memberAssetsTransferConfig',
                                        level: 'full',
                                    },
                                },
                                //优惠券整合之后以下功能magic注释
                                // {
                                //     type: 'item',
                                //     key: 'couponSaleActivity',
                                //     title: '卖券活动',
                                //     route: '/menu-list/coupon/coupon-sale-activity',
                                //     privilege: {
                                //         path: 'saleCouponActivity',
                                //         level: 'full',
                                //     },
                                // },
                                // {
                                //     type: 'item',
                                //     key: 'saleCouponSort',
                                //     title: '卖券分类',
                                //     route: '/menu-list/coupon/coupon-sale-categories',
                                //     privilege: {
                                //         path: 'saleCouponCategory',
                                //         level: 'full',
                                //     },
                                // },
                                // {
                                //     type: 'item',
                                //     key: '线上优惠券',
                                //     title: '线上优惠券',
                                //     route: '/menu-list/coupon/couponPro',
                                //     privilege: {
                                //         path: 'couponPro',
                                //         level: 'full',
                                //     },
                                // },
                                // {
                                //     type: 'item',
                                //     key: '优惠券批次',
                                //     title: '优惠券批次',
                                //     route: '/menu-list/coupon/couponBatch',
                                //     privilege: {
                                //         path: 'couponBatch',
                                //         level: 'full',
                                //     },
                                // },
                                // {
                                //     type: 'item',
                                //     key: '优惠券数据',
                                //     title: '优惠券数据',
                                //     route: '/menu-list/coupon/coupon',
                                //     privilege: {
                                //         path: 'couponData',
                                //         level: 'full',
                                //     },
                                // },
                            ],
                        },
                    ],
                },
            ],
        },
    },

    // 路由定义
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list',
                    component: 'MenuListView',
                    breadcrumbName: '',
                    childRoutes: [{ path: '/:key', component: 'MenuListPage' }],
                },
            ],
        },
    ],
};
