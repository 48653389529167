import React, { PureComponent, CSSProperties } from 'react';
import {
    privilege as cmPrivilege,
    PrivilegeLevel,
} from '@comall-backend-builder/core/lib/services';
import { Button, message as AntMessage } from 'antd';

/**
 * 校验权限跳转按钮
 */
export class PrivilegeButton extends PureComponent<{
    route: string;
    privilegeInfo: {
        path: string;
        level: keyof typeof PrivilegeLevel;
    };
    text: string;
    style?: CSSProperties;
    type?: 'default' | 'link' | 'ghost' | 'primary' | 'dashed' | 'danger';
    message?: string;
}> {
    warning = () => {
        const { message } = this.props;
        AntMessage.warning(message || '您没有权限');
    };

    render() {
        const {
            route,
            privilegeInfo: { path, level },
            text,
            style,
            type = 'primary',
        } = this.props;
        if (cmPrivilege.check(path, level)) {
            return (
                <Button style={style} type={type}>
                    <a target="_blank" rel="noopener noreferrer" href={route}>
                        {text}
                    </a>
                </Button>
            );
        }
        return (
            <Button style={style} onClick={this.warning} type={type}>
                {text}
            </Button>
        );
    }
}
