import { language } from '@comall-backend-builder/core/lib/services';
import { Button, Checkbox, Col, Row, message as AntdMessage } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { get } from 'lodash';
import React, { Component } from 'react';

interface Props {
    value: boolean;
    onChange(value: boolean): void;
    appIdPath: string;
    appNamePath: string;
    row: any;
}
export default class WechatOfficalUseDefaultReplyInput extends Component<Props> {
    static defaultProps = {
        value: true,
    };
    private onGotoEditDefaultReply = () => {
        const { appIdPath, row, appNamePath } = this.props;
        const appId = get(row, appIdPath);
        const appName = get(row, appNamePath);
        if (!appId) {
            AntdMessage.warn('请先选择一个关联公众号的门店');
        } else {
            window.open(
                `#/wechat-official-parametric-qrcode/?appId=${appId}&appName=${appName}#default-reply-setting`,
                '_blank'
            );
        }
    };
    private onChange = (e: CheckboxChangeEvent) => {
        const { onChange } = this.props;
        onChange(e.target.checked);
    };
    render() {
        const { value } = this.props;
        return (
            <div>
                <Row>
                    <Col span={5}>
                        <Checkbox checked={value} onChange={this.onChange}>
                            {language.getText('syewmmrhf')}
                        </Checkbox>
                    </Col>
                    <Col span={19}>
                        <Button hidden={!value} type="link" onClick={this.onGotoEditDefaultReply}>
                            编辑默认回复内容
                        </Button>
                    </Col>
                </Row>
                <Row style={{ fontSize: 12, color: '#999' }}>
                    用户扫码后回复内容为设置的默认回复内容，取消勾选可设置该二维码的自定义回复内容
                </Row>
            </div>
        );
    }
}
