import { tools } from '@comall-backend-builder/design-components-basis';
const ColorPicker = tools.ColorPicker;

/**
 * 重写选择颜色组件，适配在form中使用
 * 解决无法设置默认颜色以及重置按钮不好用的问题
 * 在controlConfig中设置originalColor属性可设置默认颜色
 */
export class ColorPickerPlus extends ColorPicker {
    componentDidMount() {
        const { originalColor, value, params, onChange } = this.props as any;
        // 当didmount时大部分情况value为空，先将配置的默认颜色设置为初始色
        this.originalColor = value ? value : originalColor ? originalColor : null;
        // 如果props中没有params.id，说明是新增页面，此时将组件在form中的value设置为默认颜色
        if (!params || !params.id) {
            this.originalColor && onChange(this.originalColor);
        }
    }

    componentWillReceiveProps(nextProps: any) {
        const { value } = this.props;
        const { value: newValue, params } = nextProps;
        // 当进入编辑页面，value从空值变为有值时，此时value是接口返回的数据。将value设置为初始色，重置按钮可恢复为该颜色
        if (!value && !!newValue && params && params.id) {
            this.originalColor = newValue;
        }
    }
}
