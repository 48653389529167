import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach, isEmpty, map } from 'lodash';
import moment from 'moment';

const api = services.api;

export const BfplatCouponRulesLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        config.apiPath = `/admin/bfplat_coupon_rules`;
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/bfplat_coupon_rules/${id}`;
        } else {
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.baseInfo = {
                    externalCoupon: {
                        code: res.code,
                        name: res.name,
                        typeName: res.typeName,
                        value: res.couponValue,
                        principal: res.principal,
                        startTime: res.startTime,
                        endTime: res.endTime,
                        thresholdDescription: res.thresholdDescription,
                    },
                    stock: {
                        maxCount: res.stock,
                        changeCount: '',
                        surplusCount: res.leftStock,
                        defalutMaxCount: res.stock,
                        defalutSurplusCount: res.leftStock,
                    },
                };
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : services.language.getText('longTerm'),
                    };
                }
                res.productInfo = {
                    subsite: res.subsites,
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };
                let receiveRuleMemberLevels: any = []; //领取限制
                if (res.receiveRule.cardLevels && res.receiveRule.cardLevels.length > 0) {
                    forEach(res.receiveRule.cardLevels, (levelId: any) => {
                        receiveRuleMemberLevels.push({
                            id: levelId + '',
                        });
                    });
                }
                let receiveLimit = 'NO_LIMIT';
                if (receiveRuleMemberLevels.length > 0) {
                    receiveLimit = 'MEMBER_LIMIT';
                } else if (res.receiveRule.registerTimeLimit) {
                    receiveLimit = 'TIME_LIMIT';
                }
                res.useRuleInfo = {
                    receivePeriod:
                        res.receiveRule.period.startTime && res.receiveRule.period.endTime
                            ? {
                                  start: res.receiveRule.period.startTime,
                                  end: res.receiveRule.period.endTime,
                              }
                            : undefined,
                    receiveLimit: receiveLimit,
                    memberLevels: receiveRuleMemberLevels,
                    registerMemberSource: res.receiveRule.registerTimeLimit
                        ? res.receiveRule.registerTimeLimit.registerMemberSource
                        : 'ONLINE',
                    useLimitationsMode:
                        res.receiveRule.registerTimeLimit &&
                        res.receiveRule.registerTimeLimit.registerDays
                            ? 'DAY'
                            : 'DATE',
                    useLimitationsDay: res.receiveRule.registerTimeLimit?.registerDays || undefined,
                    useLimitationsDate: res.receiveRule.registerTimeLimit?.registerPeriod
                        ? {
                              start: moment(
                                  res.receiveRule.registerTimeLimit?.registerPeriod.startTime
                              ).format('YYYY-MM-DD'),
                              end: moment(
                                  res.receiveRule.registerTimeLimit?.registerPeriod.endTime
                              ).format('YYYY-MM-DD'),
                          }
                        : undefined,
                    receiveQuantityLimitType: res.receiveRule.receiveQuantityLimitType,
                    memberLimitQuantity:
                        res.receiveRule.receiveQuantityLimitType === 'EVERY_MEMBER_LIMIT' ||
                        res.receiveRule.receiveQuantityLimitType === 'EVERY_MEMBER_DAILY_LIMIT'
                            ? res.receiveRule.memberLimitQuantity
                            : null,
                    target: res.linkRule && res.linkRule.target && JSON.parse(res.linkRule.target),
                };
                if (
                    res.pictureConfigs &&
                    res.pictureConfigs.length > 0 &&
                    res.pictureConfigs[0].pictureId
                ) {
                    res.pictureInfo = {
                        couponPicture: [
                            {
                                id: res.pictureConfigs[0].pictureId,
                                path: res.pictureConfigs[0].url,
                            },
                        ],
                    };
                }
                res.instructions = {
                    info: res.instructions,
                };
            } else {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        item.info = {
                            name: item.name,
                            url:
                                item?.pictureConfigs?.[0]?.url ||
                                'https://b-puzhehei-cdn.co-mall.net/global/icon_coupon_center_bfplat_coupon.png',
                            startTime: item.startTime,
                            endTime: item.endTime,
                        };
                        item.couponValue = item.couponValue.value;
                        return item;
                    });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        config.apiPath = '/admin/bfplat_coupon_rules';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        config.apiPath = `/admin/bfplat_coupon_rules/${id}`;
        const newParams: any = dataParser(params);
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    const newParams: any = {
        code: params.baseInfo.externalCoupon.code,
        name: params.baseInfo.externalCoupon.name,
        instructions: params.instructions.info,
        couponType: 'BFPLAT_COUPON',
        typeName: params.baseInfo.externalCoupon.typeName,
        couponValue: params.baseInfo.externalCoupon.value,
        principal: params.baseInfo.externalCoupon.principal,
        startTime: params.baseInfo.externalCoupon.startTime,
        endTime: params.baseInfo.externalCoupon.endTime,
        thresholdDescription: params.baseInfo.externalCoupon.thresholdDescription,
        stock: params.baseInfo.stock.maxCount,
        subsites: params.productInfo.subsite,
        receiveRule: {
            canBeReceiveWhenLackOfStock: false,
            canBeReceived: true,
            memberLimitQuantity:
                params.useRuleInfo.receiveQuantityLimitType === 'EVERY_MEMBER_LIMIT' ||
                params.useRuleInfo.receiveQuantityLimitType === 'EVERY_MEMBER_DAILY_LIMIT'
                    ? params.useRuleInfo.memberLimitQuantity
                    : null,
            orderLimitQuantity: null,
            period: {
                endTime: params.useRuleInfo.receivePeriod?.end ?? null,
                startTime: params.useRuleInfo.receivePeriod?.start ?? null,
            },
            receiveQuantityLimitType: params.useRuleInfo.receiveQuantityLimitType,
            cardLevels:
                params.useRuleInfo.receiveLimit === 'MEMBER_LIMIT'
                    ? map(params.useRuleInfo.memberLevels, 'id')
                    : null,
            registerTimeLimit:
                params.useRuleInfo.receiveLimit === 'TIME_LIMIT'
                    ? {
                          registerMemberSource: params.useRuleInfo.registerMemberSource,
                          registerDays:
                              params.useRuleInfo.useLimitationsMode === 'DAY'
                                  ? params.useRuleInfo.useLimitationsDay
                                  : undefined,
                          registerPeriod:
                              params.useRuleInfo.useLimitationsMode === 'DATE'
                                  ? {
                                        startTime:
                                            params.useRuleInfo.useLimitationsDate.start +
                                            ' 00:00:00',
                                        endTime:
                                            params.useRuleInfo.useLimitationsDate.end + ' 23:59:59',
                                    }
                                  : undefined,
                      }
                    : undefined,
        },
    };
    if (params.useRuleInfo.target) {
        newParams.linkRule = {
            target: JSON.stringify(params.useRuleInfo.target),
        };
    }
    if (
        params.pictureInfo &&
        params.pictureInfo.couponPicture &&
        params.pictureInfo.couponPicture[0]
    ) {
        newParams.pictureConfigs = [
            {
                pictureId: params.pictureInfo.couponPicture[0].id,
                type: 'COVER',
            },
        ];
    }
    newParams.marketingScheduleId =
        (params.productInfo?.marketingSchedule?.length &&
            params.productInfo.marketingSchedule[0].id) ||
        null;
    return newParams;
}
