import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    components: {
        SubsiteMessageViewFloorPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'SubsiteMessageViewFloorEntity',
            items: [
                {
                    component: 'subsiteAddFloorButton',
                },
                { component: 'SubsiteMessageViewFloorTable' },
            ],
        },
        SubsiteMessageViewFloorTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'SubsiteMessageViewFloorDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: {
                        x: true,
                    },
                    columns: [
                        {
                            property: 'name',
                        },
                        {
                            property: 'saleRange',
                        },
                        {
                            property: 'status',
                            displayConfig: {
                                statusConfig: {
                                    1: {
                                        text: '<<enable>>',
                                        status: 'processing',
                                    },
                                    2: {
                                        text: '<<disabled>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                        {
                            property: 'sequence',
                        },
                        {
                            property: 'createTimeStr',
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        width: 200,
                        fixed: 'right',
                        items: [
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                path: '/subsite/view-floor-edit/{{row.id}}',
                            },
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    statusKey: 'status',
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                    handles: [
                                        {
                                            params: { status: 'DISABLE' },
                                            value: 1,
                                            apiPath: '/admin/subsite_floor/{{row.id}}/status',
                                            config: {
                                                content: '<<disabled>>',
                                                text: '<<disabled>>',
                                            },
                                            confirm: {
                                                text: '是否禁用{{row.reason}}?',
                                            },
                                        },
                                        {
                                            params: { status: 'ENABLE' },
                                            value: 2,
                                            apiPath: '/admin/subsite_floor/{{row.id}}/status',
                                            config: { content: '<<enable>>', text: '<<enable>>' },
                                            confirm: {
                                                text: '是否启用{{row.reason}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'delete',
                                text: '<<common.delete>>',
                                config: {
                                    type: 'link',
                                    id: 'row.id',
                                    tooltip: null,
                                    title: '<<confirmDelete>>',
                                    okText: '<<common.ok>>',
                                    cancelText: '<<common.cancel>>',
                                },
                            },
                        ],
                    },
                },
            ],
        },
    },
};
