import React, { PureComponent, createElement } from 'react';
import { forEach, map, remove, get, mapValues } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Modal, message as AntMessage } from 'antd';
import { ContentTopicType } from '../../containers/select-content-topic';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;
const TableComponentId = 'ContentTopicSelectorTable';
let ContentTopicEntity: any = {};
let contentTopicEntity: any = {};
enum ContentTopicStatus {
    /**
     * 启用
     */
    ENABLE = 'ENABLE',
    /**
     * 禁用
     */
    DISABLE = 'DISABLE',
}
enum ContentPlateTypes {
    /**
     * 普通板块
     */
    GENERAL = 'GENERAL',
    /**
     * 宣传板块
     */
    PUBLICITY = 'PUBLICITY',
}

/**
 * 选择话题
 */
export class ContentTopicSelector extends PureComponent<{
    selectValues: ContentTopicType[];
    onChange: (products: ContentTopicType[]) => void;
    params?: any;
    type?: string;
    buttonProps?: any;
}> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        ContentTopicEntity = EntitiesManager.get('contentTopicEntity');
        contentTopicEntity = new ContentTopicEntity({});
    }
    dispatch: any = {};
    state = { selectModalVisible: false, createModalVisible: false };

    selectedRows = new Array<ContentTopicType>();

    componentWillUnmount() {
        //this.dispatch(actions.unmountEntityAction(entity));
    }

    onTableRowSelect = (record: ContentTopicType, selected: boolean) => {
        const { type } = this.props;
        if (selected) {
            if ('radio' === type) {
                this.selectedRows = [record];
            } else {
                this.selectedRows.push(record);
            }
        } else {
            remove(this.selectedRows, (i) => {
                return i.id === record.id;
            });
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: ContentTopicType[]) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            this.selectedRows = [];
        }
    };

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
    };

    onSelectOk = () => {
        const { onChange } = this.props;
        if (onChange) {
            forEach(this.selectedRows, (subsite) => {
                subsite.select = true;
            });

            onChange(this.selectedRows);
            this.toggleModalSelectTopic();
        }
    };

    onCreateOk = () => {
        contentTopicEntity.add();
    };

    getSelectConfig = () => {
        const { params, selectValues, type } = this.props;
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: contentTopicEntity,
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'left' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        contentTopicEntity.search(params);
                    },
                    fields: [
                        {
                            property: 'plateId',
                        },
                        {
                            property: 'topicName',
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    rowSelection: {
                        type: type ? type : 'radio',
                        selectedRowKeys: map(selectValues, 'id'),
                        onSelect: (
                            record: ContentTopicType,
                            selected: boolean,
                            selectedRow: any
                        ) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: ContentTopicType[]) => {
                            this.onTableRowSelectAll(selected, rows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    columns: [
                        {
                            property: 'name',
                        },
                        {
                            property: 'plateNames',
                            displayConfig: {
                                className: 'custom-array-options-text',
                            },
                        },
                        {
                            property: 'status',
                            displayConfig: {
                                statusConfig: {
                                    [ContentTopicStatus.ENABLE]: {
                                        text: language.getText('enable'),
                                        status: 'success',
                                    },
                                    [ContentTopicStatus.DISABLE]: {
                                        text: language.getText('disabled'),
                                        status: 'error',
                                    },
                                },
                            },
                        },
                    ],
                },
            ],
        };
    };

    getCreateConfig = () => {
        return {
            component: 'CreateFormPlus',
            entity: contentTopicEntity,
            direction: 'vertical',
            style: {
                width: 500,
                marginLeft: 'auto',
                marginRight: 'auto',
            },
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'resource',
                },
                {
                    property: 'plateType',
                },
                {
                    property: 'generalPlateIds',
                    visible: (values: any) =>
                        get(values, 'plateType') === ContentPlateTypes.GENERAL,
                },
                {
                    property: 'publicityPlateIds',
                    visible: (values: any) =>
                        get(values, 'plateType') === ContentPlateTypes.PUBLICITY,
                },
                {
                    property: 'sequence',
                },
                {
                    property: 'status',
                },
            ],
            submit: true,
            onSubmit: (event: any, fields: any) => {
                const entityFields = mapValues(fields, (field) => {
                    return field.value;
                });
                if (entityFields) {
                    contentTopicEntity.add(entityFields);
                }
                event.stopPropagation();
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        onClick: this.toggleModalCreateTopic,
                        text: language.getText('common.cancel'),
                        type: 'default',
                        style: {
                            marginLeft: 10,
                        },
                    },
                ],
            },
            onValidate: (entity: any) => {
                if (
                    get(entity, 'plateType') === ContentPlateTypes.GENERAL &&
                    !get(entity, 'generalPlateIds.length')
                ) {
                    AntMessage.warning(language.getText('qgxptbk'));
                    return false;
                } else if (
                    get(entity, 'plateType') === ContentPlateTypes.PUBLICITY &&
                    !get(entity, 'publicityPlateIds.length')
                ) {
                    AntMessage.warning(language.getText('qgxxcbk'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'));
                this.toggleModalCreateTopic();
            },
        };
    };

    toggleModalSelectTopic = () => {
        const { selectModalVisible } = this.state;
        this.setState({ selectModalVisible: !selectModalVisible }, () => {
            if (!selectModalVisible && contentTopicEntity.paging) {
                contentTopicEntity.search({
                    ...contentTopicEntity.paging,
                });
                this.onClearAll();
            }
        });
    };

    toggleModalCreateTopic = () => {
        const { createModalVisible } = this.state;
        contentTopicEntity.setFields({});
        this.setState({ createModalVisible: !createModalVisible });
    };

    getAllCoupon = () => {
        const { onChange } = this.props;
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: '/admin/subsites/mine',
            }
        ).then((response: any) => {
            if (onChange) {
                forEach(response.result, (subsite) => {
                    subsite.select = true;
                });
                onChange(response.result);
            }
        });
    };

    render() {
        const selectConfig = this.getSelectConfig();
        const createConfig = this.getCreateConfig();
        const selectModalContent = createElement(
            ComponentsManager.get(selectConfig.component),
            selectConfig
        );
        const createModalContent = createElement(
            ComponentsManager.get(createConfig.component),
            createConfig
        );
        const selectModalProps = {
            width: 900,
            title: language.getText('xzht_1'),
            visible: this.state.selectModalVisible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onSelectOk,
            onCancel: this.toggleModalSelectTopic,
        };

        const createModalProps = {
            width: 900,
            title: language.getText('cjht'),
            visible: this.state.createModalVisible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            destroyOnClose: true,
            onCancel: this.toggleModalCreateTopic,
            footer: null,
        };

        return (
            <div style={{ display: 'inline-block' }}>
                <Button
                    type="primary"
                    onClick={this.toggleModalSelectTopic}
                    style={{ marginRight: 10 }}
                >
                    {language.getText('zdht')}
                </Button>
                <Button type="primary" onClick={this.toggleModalCreateTopic}>
                    {language.getText('cjht')}
                </Button>
                <Modal {...selectModalProps}>{selectModalContent}</Modal>
                <Modal {...createModalProps}>{createModalContent}</Modal>
            </div>
        );
    }
}
