import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import './index.less';
import { services } from '@comall-backend-builder/core';

export class ListItemProductInfo extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getDisplayComponent(object: any, displayInfo: any) {
        return (
            object && (
                <div className="list-item-product-info">
                    {object.img && (
                        <div className="img-wrap">
                            <img className="img" alt="" src={object.img} />
                        </div>
                    )}
                    {object.name && (
                        <div className="info-wrap">
                            <div className="name">
                                <span> {object.name}</span>
                            </div>
                            <div className="style">{object.styleName}</div>
                            {object.price && (
                                <div className="price-wrap">
                                    <span>{object.priceName ? object.priceName + '：' : null}</span>
                                    <span className="price-text">
                                        {services.language.getText('monetaryUnit')}
                                        {object.price}
                                    </span>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            )
        );
    }
}
