import React from 'react';
import { connect } from 'react-redux';
import './index.less';
import { services } from '@comall-backend-builder/core';
const prefix = 'scratch-card-preview';
const innerClassNames = {
    top: `${prefix}__tpp`,
    title: `${prefix}__title`,
    box: `${prefix}__box`,
    ruler: `${prefix}__ruler`,
    rulerText: `${prefix}__ruler__text`,
    shareBtn: `${prefix}__share-btn`,
    contentWrap: `${prefix}__content-wrap`,
    content: `${prefix}__content`,
    pending: `${prefix}__content__pending`,
    rewardWrap: `${prefix}__reward-wrap`,
    reward: `${prefix}__reward`,
    footer: `${prefix}__footer`,
    footerTitle: `${prefix}__footer__title`,
    footerList: `${prefix}__footer__list`,
    footerItem: `${prefix}__footer__item`,
};
/**
 * 全民分销预览效果
 * @param props
 */
const popRecruitDistributorPreview = (props: any) => {
    const { preview = {} } = props;
    const backgroundImageUrl =
        preview &&
        preview.pictureInfo &&
        preview.pictureInfo.backgroundPicture &&
        preview.pictureInfo.backgroundPicture.length > 0
            ? preview.pictureInfo.backgroundPicture[0].path
            : '';
    const backgroundImage: any = {
        backgroundImage: backgroundImageUrl ? `url(${backgroundImageUrl})` : '',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
    };
    const name = preview?.baseInfo.name || '';
    return (
        <div className={prefix}>
            <div className={innerClassNames.top}>
                <img alt="" width="100%" src="./images/avatar/theme-head.png" />
                <div className={innerClassNames.title}>{name}</div>
            </div>
            <div className={innerClassNames.box} style={backgroundImage}>
                {renderRuler(preview)}
                {renderScratchArea(backgroundImageUrl)}
                {renderFooterArea(preview)}
            </div>
        </div>
    );
};
const renderScratchArea = (backgroundImageUrl: any) => {
    if (!backgroundImageUrl) {
        return null;
    }
    const bgUrl = require('./images/scratch-ticket-bg.png');
    const pendingUrl = require('./images/scratch-ticket-start.png');
    const style: any = {
        backgroundImage: `url(${bgUrl})`,
    };
    const pendingStyle: any = {
        backgroundImage: `url(${pendingUrl})`,
    };
    return (
        <div className={innerClassNames.contentWrap}>
            <div className={innerClassNames.content} style={style}>
                <div className={innerClassNames.pending} style={pendingStyle}></div>
            </div>
        </div>
    );
};
const renderFooterArea = (preview: any) => {
    const backgroundImageUrl =
        preview &&
        preview.pictureInfo &&
        preview.pictureInfo.backgroundPicture &&
        preview.pictureInfo.backgroundPicture.length > 0
            ? preview.pictureInfo.backgroundPicture[0].path
            : '';
    if (!backgroundImageUrl) {
        return null;
    }
    if (!preview || !preview.pictureInfo || !preview.pictureInfo.showRewardRecord) {
        return null;
    }
    const bgUrl = require('./images/scratch-ticket-reward-new.png');
    const style: any = {
        backgroundImage: `url(${bgUrl})`,
    };
    const showRewardRecord = preview.pictureInfo.showRewardRecord.includes('true') ? true : false;
    return (
        showRewardRecord && (
            <div>
                <div className={innerClassNames.rewardWrap}>
                    <div className={innerClassNames.reward}>
                        {services.language.getText('l_nhkcj_1')}
                    </div>
                </div>
                <div className={innerClassNames.footer} style={style}>
                    <div className={innerClassNames.footerTitle}>
                        {services.language.getText('zjmd')}
                    </div>
                    <div className={innerClassNames.footerList}>
                        <div className={innerClassNames.footerItem}>
                            <div>182****7899</div>
                            <div>{services.language.getText('jp')}1</div>
                        </div>
                        <div
                            className={innerClassNames.footerItem}
                            style={{ backgroundColor: ' #fff' }}
                        >
                            <div>182****7898</div>
                            <div>{services.language.getText('jp')}2</div>
                        </div>
                        <div className={innerClassNames.footerItem}>
                            <div>182****7897</div>
                            <div>{services.language.getText('jp')}3</div>
                        </div>
                    </div>
                </div>
            </div>
        )
    );
};
const renderRuler = (preview: any) => {
    const description =
        preview && preview.pictureInfo && preview.pictureInfo.description
            ? preview.pictureInfo.description
            : '';
    if (!description) {
        return null;
    }
    return (
        <div className={innerClassNames.ruler}>
            <div className={innerClassNames.rulerText}>{services.language.getText('g')}</div>
            <div className={innerClassNames.rulerText}>{services.language.getText('z')}</div>
        </div>
    );
};

const mapStateToProps = (_state: any, props: any) => {
    let preview = null;
    const entityId = props.entity.id;
    for (var compId in _state.components) {
        const comp = _state.components[compId];
        if (
            (comp.type === 'CreateForm' || comp.type === 'EditForm') &&
            comp.entityId === entityId
        ) {
            preview = comp.fields;
        }
    }
    return { preview: preview };
};

export const ScratchCardPreview = connect(mapStateToProps)(popRecruitDistributorPreview);
