import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { EntityButtonProps } from '../../../components';
import get from 'lodash/get';
import { ReceiveLimitEnum } from './cash-coupon-rules';

const api = services.api;

export const config: Config = {
    entities: {
        LotteryCouponRulesEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
            apiPath: '/loader/admin/lottery_coupon_rules',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<cjqmc>>',
                    controlConfig: { placeholder: '请输入抽奖券名称' },
                },
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<executeStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                    controlConfig: { style: { width: 150 } },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<couponTime>>',
                },
            },
            properties: {
                info: {
                    type: 'object.couponInfo',
                    displayName: '<<couponInfo2>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<executeStatus>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<applicableSubsite>>',
                },
            },
        },
        LotteryCouponRulesEditEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
            apiPath: '/loader/admin/lottery_coupon_rules',
            properties: {
                id: {
                    type: 'string',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<cjqmc>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrcjqmc>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<qsrcjqmcLimit>>',
                                maxLength: 20,
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<effectiveTime>>',
                            rules: [{ required: true, message: '<<effectiveTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        dynamicEffectivePeriod: {
                            type: 'number.tip',
                            displayName: '<<dynamicEffectivePeriod>>',
                            rules: [
                                {
                                    message: '<<dynamicEffectivePeriodRule>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (
                                            value !== undefined &&
                                            value !== null &&
                                            (!reg.test(value) || value > 366 || value === 0)
                                        ) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<period>>',
                                style: {
                                    width: 125,
                                },
                                addonbefore: '<<bindCoupon>>',
                                addonafter: '<<effectDay>>',
                                tip: '',
                            },
                            extra: '<<effectExtra>>',
                        },
                        batchNo: {
                            type: 'string.tip',
                            displayName: '<<batchNo>>',
                            rules: [
                                {
                                    required: false,
                                    whitespace: true,
                                },
                            ],
                            controlConfig: {
                                style: { width: '300px' },
                                placeholder: '<<inputPlease>>',
                            },
                            extra: '<<batchNoExtra>>',
                        },
                        stock: {
                            type: 'object.couponRuleStock',
                            displayName: '<<stock>>',
                            rules: [{ required: true, message: '<<stockRequired>>' }],
                        },
                    },
                },
                productInfo: {
                    type: 'object.subForm',
                    displayName: '<<productSetting>>',
                    properties: {
                        subsite: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            defaultValue: [],
                            rules: [{ required: true }],
                        },
                        remark: {
                            type: 'string',
                            displayName: '<<remark>>',
                        },
                    },
                },
                ruleInfo: {
                    type: 'object.subForm',
                    displayName: '<<preferentialRules>>',
                    properties: {
                        useThreshold: {
                            type: 'string.options.radio',
                            displayName: '<<useThreshold>>',
                            defaultValue: 'NOCONDITION',
                            options: [{ id: 'NOCONDITION', name: '<<nocondition>>' }],
                            rules: [{ required: true }],
                        },
                        couponValue: {
                            type: 'number',
                            displayName: '<<cjcs>>',
                            controlConfig: {
                                placeholder: '<<pleaseInputNumber>>',
                                style: { width: '150px' },
                            },
                            rules: [
                                { required: true },
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<inputThanZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^[1-9]\d*$/;
                                        if (!reg.test(value)) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                        },
                    },
                },
                useRuleInfo: {
                    type: 'object.subForm',
                    displayName: '<<useRuleInfo>>',
                    properties: {
                        receivePeriod: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<lqsj>>',
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                            },
                            extra: '<<jtgyhqzjsfklq>>',
                        },
                        receiveLimit: {
                            type: 'string.options.radio.plus',
                            displayName: '<<receiveLimit>>',
                            defaultValue: 'NO_LIMIT',
                            rules: [{ required: true }],
                            controlConfig: {
                                mode: 'horizontal',
                                setOptions: [
                                    {
                                        value: ReceiveLimitEnum.NO_LIMIT,
                                        name: '<<noLimit>>',
                                    },
                                    {
                                        value: ReceiveLimitEnum.MEMBER_LIMIT,
                                        name: '<<memberLimit>>',
                                        tip: {
                                            text: '<<memberLimitText>>',
                                        },
                                    },
                                    {
                                        value: ReceiveLimitEnum.CROWD_LIMIT,
                                        name: '<<crowdLimit>>',
                                    },
                                    {
                                        value: ReceiveLimitEnum.TIME_LIMIT,
                                        name: '<<timeLimit>>',
                                        tip: {
                                            text: '<<timeLimitTip>>',
                                        },
                                    },
                                ],
                            },
                        },
                        receiveLimitCrowds: {
                            type: 'array.receiveLimitationsCrowds',
                            displayName: '<<selectVipPeople>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                pageType: 'LotteryCouponRuleMemberLevel',
                            },
                        },
                        useLimitations: {
                            type: 'string.options.radio.plus',
                            displayName: '<<useLimit>>',
                            defaultValue: 'NO_LIMIT',
                            rules: [{ required: true }],
                            controlConfig: {
                                mode: 'horizontal',
                                setOptions: [
                                    {
                                        value: 'NO_LIMIT',
                                        name: '<<noLimitAllUse>>',
                                    },
                                    {
                                        value: 'MEMBER_LIMIT',
                                        name: '<<memberLimit>>',
                                        tip: {
                                            text: '<<memberLimitTip2>>',
                                        },
                                    },
                                ],
                            },
                        },
                        memberLevels: {
                            type: 'array.options.autoComplete.properties.coupon',
                            displayName: '<<memberLevels>>',
                            options: [],
                            controlConfig: {
                                pageType: 'LotteryCouponRuleMemberLevel',
                                placeholder: '<<selectPlease>>',
                                style: { width: 450 },
                            },
                            extra: '<<memberLevelsTip>>',
                        },
                        registerMemberSource: {
                            type: 'string.options.radio.plus',
                            displayName: '<<registerMemberSource>>',
                            defaultValue: 'ONLINE',
                            rules: [{ required: true }],
                            controlConfig: {
                                setOptions: [
                                    {
                                        value: 'ONLINE',
                                        name: '<<registOnline>>',
                                        tip: {
                                            text: '<<registOnlineTip>>',
                                        },
                                    },
                                    {
                                        value: 'OFFLINE',
                                        name: '<<registOffline>>',
                                        tip: {
                                            text: '<<registTip>>',
                                        },
                                    },
                                ],
                            },
                        },
                        useLimitationsMode: {
                            type: 'string.options.radio',
                            displayName: '<<useLimitationsMode>>',
                            defaultValue: 'DATE',
                            options: [
                                {
                                    id: 'DATE',
                                    name: '<<useLimitationsModeDate>>',
                                },
                                {
                                    id: 'DAY',
                                    name: '<<useLimitationsModeDay>>',
                                },
                            ],
                            rules: [{ required: true }],
                        },
                        useLimitationsDate: {
                            type: 'object.dateRangePlus',
                            displayName: '<<useLimitationsDate>>',
                            rules: [{ required: true, message: '<<selectUseLimitationsDate>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                            },
                            extra: '<<useLimitationsDateExtra>>',
                        },
                        useLimitationsDay: {
                            type: 'number.tip',
                            displayName: '<<registDay>>',
                            rules: [
                                {
                                    type: 'number',
                                    required: true,
                                    message: '<<registDayMessage>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && (!reg.test(value) || value > 999)) {
                                            return false;
                                        }
                                        if (!value || value === 0) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '',
                                style: {
                                    width: 125,
                                },
                                addonbefore: '',
                                addonafter: '<<day>>',
                                min: 1,
                                max: 999,
                                tip: '',
                            },
                            extra: '<<registDayExtra>>',
                        },
                        receiveQuantityLimitType: {
                            type: 'string.options.radio',
                            displayName: '<<receiveQuantityLimitType>>',
                            defaultValue: 'NO_LIMIT',
                            options: [
                                {
                                    id: 'NO_LIMIT',
                                    name: '<<mrlqcs>>',
                                },
                                {
                                    id: 'EVERY_MEMBER_LIMIT',
                                    name: '<<mgyhlqsl>>',
                                },
                                {
                                    id: 'EVERY_MEMBER_DAILY_LIMIT',
                                    name: '<<everyMemberDailyLimit>>',
                                },
                            ],
                            rules: [{ required: true }],
                        },
                        memberLimitQuantity: {
                            type: 'number',
                            displayName: '<<limitQuantity>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<inputThanZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^[1-9]\d*$/;
                                        if (!reg.test(value)) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            displayConfig: { addonafter: '<<zhang>>' },
                        },
                    },
                },
                instructions: {
                    type: 'object.subForm',
                    displayName: '<<instructions>>',
                    properties: {
                        info: {
                            type: 'string.text.paragraph',
                            displayName: '<<instructions>>',
                            rules: [{ required: true, whitespace: true, max: 2000 }],
                            controlConfig: {
                                rows: 4,
                                maxLength: 2000,
                            },
                        },
                    },
                },
                pictureInfo: {
                    type: 'object.subForm',
                    displayName: '<<tpsz>>',
                    properties: {
                        couponPicture: {
                            displayName: '<<tppz>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<pzhzszwdyhqb>>',
                        },
                    },
                },
            },
        },
    },
    components: {
        LotteryCouponRulesView: {
            component: 'Viewport',
            entity: 'LotteryCouponRulesEntity',
        },
        LotteryCouponRulesPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'LotteryCouponFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addCoupon>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/coupon/lottery-coupon/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'LotteryCouponRulesTable' },
            ],
        },
        LotteryCouponFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'executeStatus',
                },
                {
                    property: 'subsiteIds',
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        LotteryCouponRulesTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'info',
                    width: 360,
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
                {
                    property: 'subsites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 200,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                                    apiPath: '/admin/lottery_coupon_rules/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: '#f5222d' },
                                    },
                                    confirm: {
                                        text: '<<isCloseActivity>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                                    apiPath: '/admin/lottery_coupon_rules/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/menu-list/coupon/lottery-coupon/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/menu-list/coupon/lottery-coupon/copy/copydata/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<viewData>>',
                        path: '/menu-list/coupon/lottery-coupon/details/lottery/{{row.id}}',
                    },
                    {
                        text: '<<common.log>>',
                        type: 'link',
                        path: '/menu-list/coupon/lottery-coupon/logs/{{row.id}}',
                    },
                ],
            },
        },
        LotteryCouponRulesAddPage: {
            component: 'Card',
            content: { component: 'LotteryCouponRulesAddForm' },
        },
        LotteryCouponRulesAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'LotteryCouponRulesEditEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'baseInfo.dynamicEffectivePeriod' },
                { property: 'baseInfo.batchNo' },
                {
                    property: 'baseInfo.stock',
                },
                { property: 'productInfo.subsite' },
                { property: 'productInfo.remark' },
                { property: 'ruleInfo.useThreshold' },
                { property: 'ruleInfo.couponValue' },
                {
                    property: 'useRuleInfo.receivePeriod',
                },
                { property: 'useRuleInfo.receiveLimit' },
                {
                    property: 'useRuleInfo.memberLevels',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.MEMBER_LIMIT,
                },
                {
                    property: 'useRuleInfo.useLimitations',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.MEMBER_LIMIT,
                },
                {
                    property: 'useRuleInfo.receiveLimitCrowds',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.CROWD_LIMIT,
                },
                {
                    property: 'useRuleInfo.registerMemberSource',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT,
                },
                {
                    property: 'useRuleInfo.useLimitationsMode',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT,
                },
                {
                    property: 'useRuleInfo.useLimitationsDate',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT &&
                        get(values, 'useRuleInfo.useLimitationsMode') === 'DATE',
                },
                {
                    property: 'useRuleInfo.useLimitationsDay',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT &&
                        get(values, 'useRuleInfo.useLimitationsMode') === 'DAY',
                },
                { property: 'useRuleInfo.receiveQuantityLimitType' },
                {
                    property: 'useRuleInfo.memberLimitQuantity',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveQuantityLimitType') ===
                            'EVERY_MEMBER_LIMIT' ||
                        get(values, 'useRuleInfo.receiveQuantityLimitType') ===
                            'EVERY_MEMBER_DAILY_LIMIT',
                },
                { property: 'instructions.info' },
                { property: 'pictureInfo.couponPicture' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                //领取数量限制
                const receiveQuantityLimitType = entity.useRuleInfo.receiveQuantityLimitType;
                //领取数量
                const memberLimitQuantity = entity.useRuleInfo.memberLimitQuantity;
                if (
                    (receiveQuantityLimitType === 'EVERY_MEMBER_LIMIT' ||
                        receiveQuantityLimitType === 'EVERY_MEMBER_DAILY_LIMIT') &&
                    (!memberLimitQuantity || Number(memberLimitQuantity) <= 0)
                ) {
                    AntMessage.warning(services.language.getText('qzqtxlqsl'));
                    return false;
                }

                // 库存
                const stock = entity.baseInfo.stock.maxCount;
                // 需要填写库存
                if (!stock) {
                    AntMessage.warning(services.language.getText('kcsbkwk'));
                    return false;
                }

                //适用等级
                const receiveLimit = entity.useRuleInfo.receiveLimit;
                if (receiveLimit === ReceiveLimitEnum.MEMBER_LIMIT) {
                    const memberLevels = entity.useRuleInfo.memberLevels;
                    if (!memberLevels || memberLevels.length === 0) {
                        AntMessage.warning(services.language.getText('selectApplyLevel'));
                        return false;
                    }
                }
                if (receiveLimit === ReceiveLimitEnum.CROWD_LIMIT) {
                    const receiveLimitCrowds = entity.useRuleInfo.receiveLimitCrowds;
                    if (!receiveLimitCrowds || receiveLimitCrowds.length === 0) {
                        AntMessage.warning(services.language.getText('selectApplyPeople'));
                        return false;
                    }
                }
                // 领取时间校验
                const receivePeriod = get(entity, 'useRuleInfo.receivePeriod', undefined);
                const dateRange = get(entity, 'baseInfo.dateRange', {});
                if (receivePeriod) {
                    const dateRangeEnd = new Date(dateRange.end).getTime();
                    const receivePeriodEnd = new Date(receivePeriod.end).getTime();
                    if (receivePeriodEnd > dateRangeEnd) {
                        AntMessage.warn(services.language.getText('lqdjssjbnzqsxjssjzq'));
                        return false;
                    }
                }
                return true;
            },

            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        LotteryCouponRulesEditPage: {
            component: 'Card',
            content: { component: 'LotteryCouponRulesEditForm' },
        },
        LotteryCouponRulesEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'LotteryCouponRulesEditEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'baseInfo.dynamicEffectivePeriod' },
                {
                    property: 'baseInfo.batchNo',
                    controlConfig: {
                        style: { width: '178px' },
                        disabled: true,
                    },
                },
                {
                    property: 'baseInfo.stock',
                },
                {
                    property: 'productInfo.subsite',
                },
                { property: 'productInfo.remark' },
                { property: 'ruleInfo.useThreshold' },
                { property: 'ruleInfo.couponValue' },
                {
                    property: 'useRuleInfo.receivePeriod',
                },
                {
                    property: 'useRuleInfo.receiveLimit',
                    controlConfig: {
                        disabled: true,
                        mode: 'horizontal',
                        setOptions: [
                            {
                                value: ReceiveLimitEnum.NO_LIMIT,
                                name: '<<noLimit>>',
                            },
                            {
                                value: ReceiveLimitEnum.MEMBER_LIMIT,
                                name: '<<memberLimit>>',
                                tip: {
                                    text: '<<memberLimitText>>',
                                },
                            },
                            {
                                value: ReceiveLimitEnum.CROWD_LIMIT,
                                name: '<<crowdLimit>>',
                            },
                            {
                                value: ReceiveLimitEnum.TIME_LIMIT,
                                name: '<<timeLimit>>',
                                tip: {
                                    text: '<<timeLimitTip>>',
                                },
                            },
                        ],
                    },
                },
                {
                    property: 'useRuleInfo.memberLevels',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.MEMBER_LIMIT,
                },
                {
                    property: 'useRuleInfo.useLimitations',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.MEMBER_LIMIT,
                },
                {
                    property: 'useRuleInfo.receiveLimitCrowds',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.CROWD_LIMIT,
                },
                {
                    property: 'useRuleInfo.registerMemberSource',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT,
                },
                {
                    property: 'useRuleInfo.useLimitationsMode',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT,
                },
                {
                    property: 'useRuleInfo.useLimitationsDate',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT &&
                        get(values, 'useRuleInfo.useLimitationsMode') === 'DATE',
                },
                {
                    property: 'useRuleInfo.useLimitationsDay',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT &&
                        get(values, 'useRuleInfo.useLimitationsMode') === 'DAY',
                },
                { property: 'useRuleInfo.receiveQuantityLimitType' },
                {
                    property: 'useRuleInfo.memberLimitQuantity',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveQuantityLimitType') ===
                            'EVERY_MEMBER_LIMIT' ||
                        get(values, 'useRuleInfo.receiveQuantityLimitType') ===
                            'EVERY_MEMBER_DAILY_LIMIT',
                },
                { property: 'instructions.info' },
                { property: 'pictureInfo.couponPicture' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                //领取数量限制
                const receiveQuantityLimitType = entity.useRuleInfo.receiveQuantityLimitType;
                //领取数量
                const memberLimitQuantity = entity.useRuleInfo.memberLimitQuantity;
                if (
                    (receiveQuantityLimitType === 'EVERY_MEMBER_LIMIT' ||
                        receiveQuantityLimitType === 'EVERY_MEMBER_DAILY_LIMIT') &&
                    (!memberLimitQuantity || Number(memberLimitQuantity) <= 0)
                ) {
                    AntMessage.warning(services.language.getText('qzqtxlqsl'));
                    return false;
                }
                // // 库存
                // const stock = entity.baseInfo.stock.maxCount;
                // // 需要填写库存
                // if (!stock) {
                //     AntMessage.warning(services.language.getText('kcsbkwk'));
                //     return false;
                // }
                //适用等级
                const receiveLimit = entity.useRuleInfo.receiveLimit;
                if (receiveLimit === ReceiveLimitEnum.MEMBER_LIMIT) {
                    const memberLevels = entity.useRuleInfo.memberLevels;
                    if (!memberLevels || memberLevels.length === 0) {
                        AntMessage.warning(services.language.getText('selectApplyLevel'));
                        return false;
                    }
                }
                if (receiveLimit === ReceiveLimitEnum.CROWD_LIMIT) {
                    const receiveLimitCrowds = entity.useRuleInfo.receiveLimitCrowds;
                    if (!receiveLimitCrowds || receiveLimitCrowds.length === 0) {
                        AntMessage.warning(services.language.getText('selectApplyPeople'));
                        return false;
                    }
                }
                // 领取时间校验
                const receivePeriod = get(entity, 'useRuleInfo.receivePeriod', undefined);
                const dateRange = get(entity, 'baseInfo.dateRange', {});
                if (receivePeriod) {
                    const dateRangeEnd = new Date(dateRange.end).getTime();
                    const receivePeriodEnd = new Date(receivePeriod.end).getTime();
                    if (receivePeriodEnd > dateRangeEnd) {
                        AntMessage.warn(services.language.getText('lqdjssjbnzqsxjssjzq'));
                        return false;
                    }
                }
                return true;
            },
            onSetSubmitConfirmProps: () => {
                return {
                    title: services.language.getText('wxts'),
                    content: services.language.getText('yscdwsyyhqjaxghdgzzx'),
                    okText: services.language.getText('rxxg'),
                    cancelText: services.language.getText('common.cancel'),
                };
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        LotteryCouponRulesDetailsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'Tabs',
                    type: 'card',
                    cache: false,
                    animated: false,
                    items: [
                        {
                            title: '<<yhqsj>>',
                            content: {
                                component: 'LotteryCouponRulesDetailsReport',
                            },
                        },
                    ],
                },
            ],
        },
        LotteryCouponRulesDetailsReport: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'CouponRulesDetailsReportEntity',
            items: [
                {
                    component: 'LotteryCouponRulesDetailsReportFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    style: {
                        paddingTop: 10,
                        paddingBottom: 16,
                    },
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'EntityButton',
                                    props: {
                                        type: 'primary',
                                        children: '<<dcyhqsj>>',
                                        onClick: (_: any, entityButtonProps: EntityButtonProps) => {
                                            const params: any = Object.assign(
                                                {},
                                                entityButtonProps.entity.params,
                                                entityButtonProps.entity.filters,
                                                {
                                                    exchangeStatus: entityButtonProps.entity.filters
                                                        ? (entityButtonProps.entity.filters as any)
                                                              .redeemStatus
                                                        : undefined,
                                                    redeemStatus: undefined,
                                                }
                                            );
                                            const config = {
                                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                                                apiPath:
                                                    '/admin/lottery_coupons/lottery_coupons.excel',
                                            };
                                            params.couponRuleId = params.id;

                                            delete params.type;
                                            delete params.id;

                                            api.get(params, config)
                                                .then(() => {
                                                    AntMessage.success(
                                                        services.language.getText('dataSCLoading')
                                                    );
                                                })
                                                .catch((error) => {
                                                    services.errorHandle(error);
                                                });
                                        },
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'LotteryCouponRulesDetailsReportTable',
                },
            ],
        },
        LotteryCouponRulesDetailsReportFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'mobile',
                },
                {
                    property: 'couponNo',
                },
                {
                    property: 'couponStatus',
                },
            ],
        },
        LotteryCouponRulesDetailsReportTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'couponNo',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'status',
                },
                {
                    property: 'bindingTime',
                    width: 200,
                },
                {
                    property: 'endTime',
                    width: 200,
                },
            ],
        },

        LotteryCouponRulesCopyPage: {
            component: 'Card',
            content: { component: 'LotteryCouponRulesCopyForm' },
        },
        LotteryCouponRulesCopyForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'LotteryCouponRulesEditEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'baseInfo.dynamicEffectivePeriod' },
                {
                    property: 'baseInfo.batchNo',
                    controlConfig: {
                        style: { width: '178px' },
                    },
                },
                {
                    property: 'baseInfo.stock',
                },
                {
                    property: 'productInfo.subsite',
                },
                { property: 'productInfo.remark' },
                { property: 'ruleInfo.useThreshold' },
                { property: 'ruleInfo.couponValue' },
                {
                    property: 'useRuleInfo.receivePeriod',
                },
                {
                    property: 'useRuleInfo.receiveLimit',
                    controlConfig: {
                        mode: 'horizontal',
                        setOptions: [
                            {
                                value: ReceiveLimitEnum.NO_LIMIT,
                                name: '<<noLimit>>',
                            },
                            {
                                value: ReceiveLimitEnum.MEMBER_LIMIT,
                                name: '<<memberLimit>>',
                                tip: {
                                    text: '<<memberLimitText>>',
                                },
                            },
                            {
                                value: ReceiveLimitEnum.CROWD_LIMIT,
                                name: '<<crowdLimit>>',
                            },
                            {
                                value: ReceiveLimitEnum.TIME_LIMIT,
                                name: '<<timeLimit>>',
                                tip: {
                                    text: '<<timeLimitTip>>',
                                },
                            },
                        ],
                    },
                },
                {
                    property: 'useRuleInfo.memberLevels',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.MEMBER_LIMIT,
                },
                {
                    property: 'useRuleInfo.useLimitations',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.MEMBER_LIMIT,
                },
                {
                    property: 'useRuleInfo.receiveLimitCrowds',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.CROWD_LIMIT,
                },
                {
                    property: 'useRuleInfo.registerMemberSource',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT,
                },
                {
                    property: 'useRuleInfo.useLimitationsMode',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT,
                },
                {
                    property: 'useRuleInfo.useLimitationsDate',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT &&
                        get(values, 'useRuleInfo.useLimitationsMode') === 'DATE',
                },
                {
                    property: 'useRuleInfo.useLimitationsDay',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT &&
                        get(values, 'useRuleInfo.useLimitationsMode') === 'DAY',
                },
                { property: 'useRuleInfo.receiveQuantityLimitType' },
                {
                    property: 'useRuleInfo.memberLimitQuantity',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveQuantityLimitType') ===
                            'EVERY_MEMBER_LIMIT' ||
                        get(values, 'useRuleInfo.receiveQuantityLimitType') ===
                            'EVERY_MEMBER_DAILY_LIMIT',
                },
                { property: 'instructions.info' },
                { property: 'pictureInfo.couponPicture' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                //领取数量限制
                const receiveQuantityLimitType = entity.useRuleInfo.receiveQuantityLimitType;
                //领取数量
                const memberLimitQuantity = entity.useRuleInfo.memberLimitQuantity;
                if (
                    (receiveQuantityLimitType === 'EVERY_MEMBER_LIMIT' ||
                        receiveQuantityLimitType === 'EVERY_MEMBER_DAILY_LIMIT') &&
                    (!memberLimitQuantity || Number(memberLimitQuantity) <= 0)
                ) {
                    AntMessage.warning(services.language.getText('qzqtxlqsl'));
                    return false;
                }
                // 库存
                const stock = entity.baseInfo.stock.maxCount;
                // 需要填写库存
                if (!stock) {
                    AntMessage.warning(services.language.getText('kcsbkwk'));
                    return false;
                }
                //适用等级
                const receiveLimit = entity.useRuleInfo.receiveLimit;
                if (receiveLimit === ReceiveLimitEnum.MEMBER_LIMIT) {
                    const memberLevels = entity.useRuleInfo.memberLevels;
                    if (!memberLevels || memberLevels.length === 0) {
                        AntMessage.warning(services.language.getText('selectApplyLevel'));
                        return false;
                    }
                }
                if (receiveLimit === ReceiveLimitEnum.CROWD_LIMIT) {
                    const receiveLimitCrowds = entity.useRuleInfo.receiveLimitCrowds;
                    if (!receiveLimitCrowds || receiveLimitCrowds.length === 0) {
                        AntMessage.warning(services.language.getText('selectApplyPeople'));
                        return false;
                    }
                }
                // 领取时间校验
                const receivePeriod = get(entity, 'useRuleInfo.receivePeriod', undefined);
                const dateRange = get(entity, 'baseInfo.dateRange', {});
                if (receivePeriod) {
                    const dateRangeEnd = new Date(dateRange.end).getTime();
                    const receivePeriodEnd = new Date(receivePeriod.end).getTime();
                    if (receivePeriodEnd > dateRangeEnd) {
                        AntMessage.warn(services.language.getText('lqdjssjbnzqsxjssjzq'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        LotteryCouponRulesLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                    apiPath: '/admin/operation_logs/LOTTERY/{id}',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/coupon/lottery-coupon',
                    component: 'LotteryCouponRulesView',
                    breadcrumbName: '<<lottery>>',
                    privilege: {
                        path: 'lotteryCouponRule',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'LotteryCouponRulesAddPage',
                            breadcrumbName: '<<xzcjq>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'LotteryCouponRulesEditPage',
                            breadcrumbName: '<<bjkq>>',
                        },
                        {
                            path: '/copy/:type/:id',
                            component: 'LotteryCouponRulesCopyPage',
                            breadcrumbName: '<<copyLottery>>',
                        },
                        {
                            path: '/details/:type/:id',
                            component: 'LotteryCouponRulesDetailsPage',
                            breadcrumbName: '<<viewData>>',
                        },
                        {
                            path: '/logs/:id',
                            component: 'LotteryCouponRulesLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        { path: '/', component: 'LotteryCouponRulesPage' },
                    ],
                },
            ],
        },
    ],
};
