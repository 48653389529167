import React, { PureComponent } from 'react';
import { TableRowSelection, TableProps, ColumnProps } from 'antd/lib/table';
import { Button, message } from 'antd';
import {
    FloatingActionButton,
    FloatingActionType,
    getTableColumns,
    defaultCustomButton,
    FloatingActionButtonTableRow,
} from './common';
import { FloatingActionButtonsSettingModal } from './setting-modal';
import DragTable from '../drag-table';
import { cloneDeep, remove } from 'lodash';
import { services } from '@comall-backend-builder/core';

const { getText } = services.language;

export interface FloatingActionButtonsSettingProps {
    onChange: (data: Array<FloatingActionButton>) => void;
    /**
     * 当前值
     */
    value: Array<FloatingActionButton>;
    /**
     * 是否禁用
     */
    disabled?: boolean;
    getSubsiteIds: (row: any) => string[];
    row: any;
}

interface FloatingActionButtonsSettingState {
    showModal: boolean;
    editIndex: number;
    editValue: FloatingActionButton;
}

export class FloatingActionButtonsSetting extends PureComponent<
    FloatingActionButtonsSettingProps,
    FloatingActionButtonsSettingState
> {
    state = {
        showModal: false,
        editIndex: -1,
        editValue: defaultCustomButton,
    };

    onChange = (data: Array<FloatingActionButton>) => {
        const { onChange } = this.props;
        onChange(data);
    };

    private get subsiteIds() {
        const { getSubsiteIds, row } = this.props;
        return getSubsiteIds(row);
    }

    onAddButton = () => {
        if (!this.subsiteIds.length) {
            message.warn(services.language.getText('selectBeforeMerchant'));
            return;
        }
        this.setState({
            showModal: true,
        });
    };
    onEditButton = (editValue: FloatingActionButton, editIndex: number) => {
        this.setState({
            showModal: true,
            editValue,
            editIndex,
        });
    };

    onDeleteButton = (delIndex: number) => {
        const { value } = this.props;
        const newValue = cloneDeep(value);
        remove(newValue, (i, index) => delIndex === index);
        this.onChange(newValue);
    };

    onModalSubmit = (button: FloatingActionButton) => {
        const { value } = this.props;
        const { editIndex } = this.state;
        if (editIndex > -1) {
            const newValue = cloneDeep(value);
            newValue[editIndex] = button;
            this.onChange(newValue);
        } else {
            this.onChange([...value, button]);
        }
        this.closeModal();
    };
    closeModal = () => {
        this.setState({
            showModal: false,
            editValue: cloneDeep(defaultCustomButton),
            editIndex: -1,
        });
    };

    renderActionColumn = (
        i: FloatingActionButtonTableRow,
        button: FloatingActionButtonTableRow,
        index: number
    ) => {
        const { disabled } = this.props;
        return (
            <div className="operation-wrap" style={{ height: '75px' }}>
                <Button
                    type="link"
                    onClick={() => this.onEditButton(button, index)}
                    disabled={disabled}
                >
                    {getText('common.edit')}
                </Button>
                {button.type === FloatingActionType.Custom && (
                    <Button
                        type={'link'}
                        onClick={() => this.onDeleteButton(index)}
                        disabled={disabled}
                    >
                        {getText('common.delete')}
                    </Button>
                )}
            </div>
        );
    };

    onRowSelectionChange = (rowKeys: number[] | string[]) => {
        const { value } = this.props;
        const newValue = [...value].map((item, index) => ({
            ...item,
            visible: (rowKeys as number[]).includes(index),
        }));
        this.onChange(newValue);
    };
    getTableConfig = () => {
        const { value } = this.props;
        const selectedQuantity = value.filter(
            (item) => item.visible && item.type !== FloatingActionType.ToTop
        ).length;
        const selectedRowKeys = value
            .map((i, index) => ({ visible: i.visible, index }))
            .filter((i) => i.visible)
            .map((i) => i.index);
        const rowSelection: TableRowSelection<FloatingActionButtonTableRow> = {
            onChange: this.onRowSelectionChange,
            selectedRowKeys,
            columnTitle: ' ',
            getCheckboxProps: (record) => {
                if (
                    record.type !== FloatingActionType.ToTop &&
                    selectedQuantity >= 9 &&
                    !record.visible
                ) {
                    return { disabled: true };
                }
                return {};
            },
        };
        const columns: ColumnProps<FloatingActionButtonTableRow>[] = [
            ...getTableColumns(),
            {
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                key: 'operation',
                render: this.renderActionColumn,
            },
        ];
        const dataSource = value.map((item, index) => ({ ...item, sequence: index }));
        const config: TableProps<FloatingActionButtonTableRow> = {
            rowKey: 'sequence',
            columns,
            scroll: { x: true },
            rowSelection,
            dataSource,
        };
        return config;
    };

    renderTable = () => {
        const tableProps = this.getTableConfig();
        return (
            <div className="reward-setting-display">
                <DragTable
                    {...tableProps}
                    onUpdateData={this.onChange}
                    pagination={false}
                    disableDragIndex={[0]}
                />
            </div>
        );
    };

    render() {
        const { value, disabled } = this.props;
        const { showModal, editValue } = this.state;
        const hasValue = value && value.length > 0;
        const subsiteIds = this.subsiteIds;
        return (
            <div>
                {!disabled && (
                    <Button
                        type="primary"
                        style={{ marginBottom: '12px' }}
                        onClick={this.onAddButton}
                    >
                        {getText('tjzck')}
                    </Button>
                )}
                <div style={{ color: '#ccc' }}>{getText('chddb')}</div>
                {hasValue && this.renderTable()}
                <FloatingActionButtonsSettingModal
                    visible={showModal}
                    button={editValue}
                    onSubmit={this.onModalSubmit}
                    onCancel={this.closeModal}
                    subsiteIds={subsiteIds}
                />
            </div>
        );
    }
}
