import React, { PureComponent } from 'react';
import { Button } from 'antd';
import { SelectPlatformGoods } from '../select-platform-goods';
import { services } from '@comall-backend-builder/core';

const language = services.language;

export class PlatformGoodsSelector extends PureComponent<
    {
        value: any;
        contentConfig: Record<string, any>;
        modalConfig: Record<string, any>;
        onChange: (value: any) => void;
        type: 'Single' | 'Multi';
    },
    { visibleModal: boolean }
> {
    state = {
        visibleModal: false,
    };

    showModal = () => {
        this.setState({ visibleModal: true });
    };
    hideModal = () => {
        this.setState({ visibleModal: false });
    };
    onChange = (value: any) => {
        const { onChange } = this.props;
        this.hideModal();
        onChange(value);
    };

    render() {
        const {
            value: { linkParams },
            contentConfig,
            modalConfig,
            type,
        } = this.props;

        const { visibleModal } = this.state;
        const hasSingleTarget = linkParams && linkParams.name;
        const selectProps = {
            value: linkParams,
            onChange: this.onChange,
        };
        return (
            <span className="link-name">
                {type === 'Single' &&
                    (hasSingleTarget ? (
                        <span className="link-name-label">{linkParams.name}</span>
                    ) : (
                        '未选择'
                    ))}
                <Button type={'link'} onClick={this.showModal} className="link-type-edit">
                    {hasSingleTarget
                        ? language.getText('components.Tools.modify')
                        : language.getText('components.Tools.selectPlatformGoods')}
                </Button>
                <SelectPlatformGoods
                    contentConfig={contentConfig}
                    modalConfig={modalConfig}
                    type={type}
                    showModal={visibleModal}
                    onCancel={this.hideModal}
                    {...selectProps}
                />
            </span>
        );
    }
}
