import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import get from 'lodash/get';
import { language } from '@comall-backend-builder/core/lib/services';

/**
 * 专柜/门店选择模式
 */
enum MerchantSelectorMode {
    /**
     * 按门店选择
     */
    SUBSITE = 'SUB_SITE',
    /**
     * 按专柜选择
     */
    MERCHANT = 'MERCHANT',
    /**
     * 按标签选择
     */
    TAG = 'TAG',
}

export const config: Config = {
    entities: {
        HiBeiBenefitBaseRuleEntity: {
            // apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
            apiPath: '/loader/admin/promotion/hibei',
            paramsFilter: ['BASE_RULE'],
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsrhdmc>>' },
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<applicableSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 150 } },
                },
                merchantId: {
                    displayName: '<<applyMerchants>>',
                    type: 'string.filterOptions.autoComplete',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                        //专柜默认正序排序
                        params: {
                            sequence: 'code',
                            order: 'asc',
                        },
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 150 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                activityProductType: {
                    type: 'string.options.select',
                    displayName: '<<cysp>>',
                    options: [
                        { id: 'ALL', name: '<<allProduct>>' },
                        { id: 'INCLUDE_PRODUCT', name: '<<includeProduct>>' },
                        { id: 'EXCLUDE_PRODUCT', name: '<<excludeProduct>>' },
                    ],
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<applicableSubsite>>',
                },
                merchants: {
                    type: 'array.popover',
                    displayName: '<<applyMerchants>>',
                },
                status: {
                    type: 'string',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherBaseInfo>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrhdmc>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                            },
                        },
                    },
                },
                ruleInfo: {
                    type: 'object.subForm',
                    displayName: '<<activityRule>>',
                    properties: {
                        selectMode: {
                            type: 'string.options.radio',
                            displayName: '<<applicableSubsiteAndMerchant>>',
                            options: [
                                { id: 'SUB_SITE', name: '<<subsiteSelect>>' },
                                { id: 'MERCHANT', name: '<<merchantSelect>>' },
                                { id: 'TAG', name: '<<selectTag>>' },
                            ],
                            defaultValue: 'MERCHANT',
                            rules: [{ required: true }],
                        },
                        merchants: {
                            type: 'array.shop.selector',
                            displayName: '<<selectSubsites>>',
                            defaultValue: [],
                        },
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            defaultValue: [],
                        },
                        tags: {
                            type: 'array.tags.selector',
                            displayName: '<<xzbq>>',
                            defaultValue: [],
                        },
                        activityProductType: {
                            type: 'string.options.radio',
                            displayName: '<<activityProduct>>',
                            defaultValue: 'ALL',
                            options: [
                                { id: 'ALL', name: '<<allProduct>>' },
                                { id: 'INCLUDE_PRODUCT', name: '<<includeProduct>>' },
                                { id: 'EXCLUDE_PRODUCT', name: '<<excludeProduct>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        products: {
                            type: 'object.productCategory.selector',
                            displayName: '<<components.Tools.selectProduct>>',
                            controlConfig: {
                                selectProductTypes: ['NORMAL,COMBINE'],
                            },
                        },
                        calcType: {
                            type: 'string.options.radio',
                            displayName: '<<jltj>>',
                            defaultValue: 'FULL_AMOUNT',
                            options: [{ id: 'FULL_AMOUNT', name: '<<dcxfmm>>' }],
                            rules: [{ required: true }],
                        },
                        giveTime: {
                            type: 'string.options.radio',
                            displayName: '<<zssj>>',
                            defaultValue: 'RECEIVED',
                            options: [{ id: 'RECEIVED', name: '<<qrsh>>' }],
                            rules: [{ required: true }],
                        },
                        reward: {
                            type: 'object.PointRewardContent',
                            displayName: '<<yhnr>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                reqConfig: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                                    apiPath: '/admin/gold_exchange_rules',
                                },
                            },
                        },
                        // rewardLimit: {
                        //     type: 'number.tip.groupBuying',
                        //     displayName: '奖励上限',
                        //     name: JSON.stringify({
                        //         fill: '每人最高参与#value#次',
                        //         empty: '未设置奖励上限',
                        //     }),
                        //     controlConfig: {
                        //         style: { width: 150 },
                        //         min: 1,
                        //         addonbefore: '每人最高参与',
                        //         addonafter: '<<frequency>>',
                        //         tip: '',
                        //     },
                        //     rules: [{ required: true }],
                        // },
                    },
                },
            },
        },
        HiBeiBenefitMultipleRuleEntity: {
            apiPath: '/loader/admin/promotion/hibei_double_promotions',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsrhdmc>>' },
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<applicableSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 150 } },
                },
                merchantId: {
                    displayName: '<<applyMerchants>>',
                    type: 'string.filterOptions.autoComplete',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                        //专柜默认正序排序
                        params: {
                            sequence: 'code',
                            order: 'asc',
                        },
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 150 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                enable: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'true', name: '<<beforeOpen>>' },
                        { id: 'false', name: '<<beforeClose>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                subsite: {
                    type: 'string',
                    displayName: '<<applicableSubsite>>',
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                enable: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: [
                        { id: 'true', name: '<<beforeOpen>>' },
                        { id: 'false', name: '<<beforeClose>>' },
                    ],
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherBaseInfo>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrhdmc>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                            },
                        },
                    },
                },
                ruleInfo: {
                    type: 'object.subForm',
                    displayName: '<<activityRule>>',
                    properties: {
                        subsites: {
                            type: 'string.options.select',
                            displayName: '<<otherSelectSubsite>>',
                            defaultValue: [],
                            options: [],
                            rules: [{ required: true, message: '<<applicableSubsiteRequired>>' }],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            controlConfig: {
                                placeholder: '<<selectSubsite>>',
                            },
                        },
                        ruleType: {
                            type: 'string.options.radio',
                            displayName: '<<syrq>>',
                            defaultValue: 'MEMBER_LEVEL',
                            options: [{ id: 'MEMBER_LEVEL', name: '<<azhydjjb>>' }],
                            rules: [{ required: true }],
                        },
                        memberPointDoubleRule: {
                            type: 'object.MemberPointDoubleRule',
                            displayName: '<<hydj>>',
                            options: [],
                            source: {
                                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                                apiPath: '/loader/admin/cardLevelsPointBenefit',
                                dependences: ['ruleInfo.subsites'],
                            },
                            rules: [{ required: true }],
                            controlConfig: {
                                reqConfig: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                                    apiPath: '/admin/gold_exchange_rules',
                                },
                                returnCardLevelId: true,
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        HiBeiBenefitView: {
            component: 'Viewport',
        },
        HiBeiBenefitPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            style: {
                padding: '12px 32px 24px 32px',
            },
            items: [{ component: 'MemberBenefitTabs' }],
        },
        HiBeiBenefitBaseRulePage: {
            entity: 'HiBeiBenefitBaseRuleEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                { component: 'HiBeiBenefitBaseRuleFilter' },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/hibei-benefit/base-rule/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'HiBeiBenefitBaseRuleTable' },
            ],
        },
        HiBeiBenefitBaseRuleFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            labelCol: 6,
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
            ],
        },
        HiBeiBenefitBaseRuleTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'activityProductType',
                },
                {
                    property: 'subsites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'merchants',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<merchant>>',
                    },
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            width: '100px',
                        },
                        statusConfig: {
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 320,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'status',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    value: true,
                                    params: { status: false },
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
                                    apiPath: '/admin/promotion/:id/status',
                                    config: {
                                        content: '{{row.name}}<<beforeClose>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                        className: 'confirm-content',
                                    },
                                },
                                {
                                    value: false,
                                    params: { status: true },
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
                                    apiPath: '/admin/promotion/:id/status',
                                    config: {
                                        content: '{{row.name}}<<beforeOpen>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/hibei-benefit/base-rule/edit/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'NewImportButton',
                        statusKey: 'row.canImport',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
                            apiPath: '/admin/files/locations/801/upload',
                            text: '<<importProduct>>',
                            templateSource: {
                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
                                apiPath: '/admin/promotion/export.xlsx',
                            },
                            specialTips: ['<<rxtmddsphfgzqdrdsp>>'],
                        },
                    },
                ],
            },
        },
        HiBeiBenefitBaseRuleAddPage: {
            component: 'Card',
            content: { component: 'HiBeiBenefitBaseRuleAddForm' },
        },
        HiBeiBenefitBaseRuleAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'HiBeiBenefitBaseRuleEntity',
            labelCol: 4,
            controlCol: 20,
            style: { width: 720 },
            fields: [
                {
                    property: 'baseInfo.name',
                },
                {
                    property: 'ruleInfo.selectMode',
                },
                {
                    property: 'ruleInfo.subsites',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.selectMode') === MerchantSelectorMode.SUBSITE,
                },
                {
                    property: 'ruleInfo.merchants',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.selectMode') === MerchantSelectorMode.MERCHANT,
                },
                {
                    property: 'ruleInfo.tags',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.selectMode') === MerchantSelectorMode.TAG,
                },
                {
                    property: 'ruleInfo.activityProductType',
                },
                {
                    property: 'ruleInfo.products',
                },
                {
                    property: 'ruleInfo.calcType',
                },
                {
                    property: 'ruleInfo.giveTime',
                },
                {
                    property: 'ruleInfo.reward',
                },
                // {
                //     property: 'ruleInfo.rewardLimit',
                // },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const ruleInfo = entity.ruleInfo;
                if (ruleInfo) {
                    const selectMode = ruleInfo.selectMode;
                    switch (selectMode) {
                        case MerchantSelectorMode.SUBSITE:
                            if (!ruleInfo.subsites || ruleInfo.subsites.length === 0) {
                                AntMessage.error(services.language.getText('selectSubsite'));
                                return false;
                            }
                            break;
                        case MerchantSelectorMode.MERCHANT:
                            if (!ruleInfo.merchants || ruleInfo.merchants.length === 0) {
                                AntMessage.error(services.language.getText('pleaseSelectMerchant'));
                                return false;
                            }
                            break;
                        case MerchantSelectorMode.TAG:
                            if (!ruleInfo.tags || ruleInfo.tags.length === 0) {
                                AntMessage.error(language.getText('qxzbq'));
                                return false;
                            }
                            break;
                    }
                    const reward = ruleInfo.reward;
                    if (!reward || !reward.ladderValue || !reward.ladderAmount) {
                        AntMessage.error(language.getText('qwztxyhnr'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: (fields: any, res: any) => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        HiBeiBenefitBaseRuleEditPage: {
            component: 'Card',
            content: { component: 'HiBeiBenefitBaseRuleEditForm' },
        },

        HiBeiBenefitBaseRuleEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'HiBeiBenefitBaseRuleEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            style: { width: 720 },
            fields: [
                {
                    property: 'baseInfo.name',
                },
                {
                    property: 'ruleInfo.selectMode',
                },
                {
                    property: 'ruleInfo.subsites',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.selectMode') === MerchantSelectorMode.SUBSITE,
                },
                {
                    property: 'ruleInfo.merchants',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.selectMode') === MerchantSelectorMode.MERCHANT,
                },
                {
                    property: 'ruleInfo.tags',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.selectMode') === MerchantSelectorMode.TAG,
                },
                {
                    property: 'ruleInfo.activityProductType',
                },
                {
                    property: 'ruleInfo.products',
                },
                {
                    property: 'ruleInfo.calcType',
                },
                {
                    property: 'ruleInfo.giveTime',
                },
                {
                    property: 'ruleInfo.reward',
                },
                // {
                //     property: 'ruleInfo.rewardLimit',
                // },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const ruleInfo = entity.ruleInfo;
                if (ruleInfo) {
                    const selectMode = ruleInfo.selectMode;
                    switch (selectMode) {
                        case MerchantSelectorMode.SUBSITE:
                            if (!ruleInfo.subsites || ruleInfo.subsites.length === 0) {
                                AntMessage.error(services.language.getText('selectSubsite'));
                                return false;
                            }
                            break;
                        case MerchantSelectorMode.MERCHANT:
                            if (!ruleInfo.merchants || ruleInfo.merchants.length === 0) {
                                AntMessage.error(services.language.getText('pleaseSelectMerchant'));
                                return false;
                            }
                            break;
                        case MerchantSelectorMode.TAG:
                            if (!ruleInfo.tags || ruleInfo.tags.length === 0) {
                                AntMessage.error(language.getText('qxzbq'));
                                return false;
                            }
                            break;
                    }
                    const reward = ruleInfo.reward;
                    if (!reward || !reward.ladderValue || !reward.ladderAmount) {
                        AntMessage.error(language.getText('qwztxyhnr'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },

        HiBeiBenefitMultipleRulePage: {
            entity: 'HiBeiBenefitMultipleRuleEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                { component: 'HiBeiBenefitMultipleRuleFilter' },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/hibei-benefit/multiple-rule/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'HiBeiBenefitMultipleRuleTable' },
            ],
        },
        HiBeiBenefitMultipleRuleFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            labelCol: 6,
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'enable',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
            ],
        },
        HiBeiBenefitMultipleRuleTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'subsite',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                    width: 150,
                },
                {
                    property: 'enable',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            width: '100px',
                        },
                        statusConfig: {
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 320,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'enable',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
                                    apiPath: '/admin/shopping_reward_double_promotions/:id/disable',
                                    config: {
                                        content: '{{row.name}}<<beforeClose>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                        className: 'confirm-content',
                                    },
                                },
                                {
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
                                    apiPath: '/admin/shopping_reward_double_promotions/:id/enable',
                                    config: {
                                        content: '{{row.name}}<<beforeOpen>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                                {
                                    value: 'COMPLETE_OPEN',
                                    config: {
                                        text: '<<common.close>>',
                                        disabled: true,
                                    },
                                },
                                {
                                    value: 'COMPLETE_CLOSE',
                                    config: {
                                        text: '<<common.opend>>',
                                        disabled: true,
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/hibei-benefit/multiple-rule/edit/{{row.id}}',
                    },
                ],
            },
        },
        HiBeiBenefitMultipleRuleAddPage: {
            component: 'Card',
            content: { component: 'HiBeiBenefitMultipleRuleAddForm' },
        },
        HiBeiBenefitMultipleRuleAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'HiBeiBenefitMultipleRuleEntity',
            labelCol: 4,
            controlCol: 20,
            style: { width: 720 },
            fields: [
                {
                    property: 'baseInfo.name',
                },
                {
                    property: 'ruleInfo.subsites',
                },
                {
                    property: 'ruleInfo.ruleType',
                },
                {
                    property: 'ruleInfo.memberPointDoubleRule',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const ruleInfo = entity.ruleInfo;
                if (ruleInfo) {
                    const memberPointDoubleRule = ruleInfo.memberPointDoubleRule;
                    if (
                        !memberPointDoubleRule ||
                        !memberPointDoubleRule.memberLevelId ||
                        !memberPointDoubleRule.multiple
                    ) {
                        AntMessage.error(language.getText('qwztxtxhydjxx'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: (fields: any, res: any) => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        HiBeiBenefitMultipleRuleEditPage: {
            component: 'Card',
            content: { component: 'HiBeiBenefitMultipleRuleEditForm' },
        },

        HiBeiBenefitMultipleRuleEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'HiBeiBenefitMultipleRuleEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            style: { width: 720 },
            fields: [
                {
                    property: 'baseInfo.name',
                },
                {
                    property: 'ruleInfo.subsites',
                },
                {
                    property: 'ruleInfo.ruleType',
                },
                {
                    property: 'ruleInfo.memberPointDoubleRule',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const ruleInfo = entity.ruleInfo;
                if (ruleInfo) {
                    const memberPointDoubleRule = ruleInfo.memberPointDoubleRule;
                    if (
                        !memberPointDoubleRule ||
                        !memberPointDoubleRule.memberLevelId ||
                        !memberPointDoubleRule.multiple
                    ) {
                        AntMessage.error(language.getText('qwztxtxhydjxx'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/hibei-benefit',
                    component: 'HiBeiBenefitView',
                    breadcrumbName: '<<hiBeiRightsAndInterests>>',
                    privilege: {
                        path: 'hiBeiRightsAndInterests',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/base-rule/add',
                            component: 'HiBeiBenefitBaseRuleAddPage',
                            breadcrumbName: '<<xzjcgz>>',
                        },
                        {
                            path: '/base-rule/edit/:id',
                            component: 'HiBeiBenefitBaseRuleEditPage',
                            breadcrumbName: '<<bjjcgz>>',
                        },
                        {
                            path: '/multiple-rule/add',
                            component: 'HiBeiBenefitMultipleRuleAddPage',
                            breadcrumbName: '<<xzjbgz>>',
                        },
                        {
                            path: '/multiple-rule/edit/:id',
                            component: 'HiBeiBenefitMultipleRuleEditPage',
                            breadcrumbName: '<<bjjbgz>>',
                        },
                        { path: '/', component: 'HiBeiBenefitPage' },
                    ],
                },
            ],
        },
    ],
};
