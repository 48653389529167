import React, { PureComponent } from 'react';
import { Button, Modal, Input, Table, message } from 'antd';
import { map, remove, cloneDeep } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { ValueItem } from './receive-limit-crowds-select';
import { TextTips } from '../../../../components/text-tips';
import './index.less';

const { api } = services;

export default class CrowdsSelector extends PureComponent<
    {
        value: ValueItem[];
        getSubsiteIds: () => string[];
        onChange: (v: ValueItem[]) => void;
    },
    {
        visible: boolean;
        selectedRows: ValueItem[];
        name: string;
        total: number;
        pagination: {
            page: number;
            perPage: number;
        };
        loading: boolean;
        datasource: any[];
    }
> {
    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
            name: '',
            total: 0,
            pagination: {
                page: 1,
                perPage: 10,
            },
            loading: false,
            datasource: [],
            selectedRows: [],
        };
    }
    onOk = () => {
        const { selectedRows } = this.state;
        this.props.onChange(selectedRows);
        this.toggleModal();
    };
    toggleModal = () => {
        const { visible } = this.state;
        const { getSubsiteIds } = this.props;
        if (!visible && getSubsiteIds().length === 0) {
            message.warning(services.language.getText('qxxzmdhzj'));
            return;
        }
        this.setState(
            {
                visible: !visible,
            },
            () => {
                if (visible) {
                    this.setState({
                        datasource: [],
                        pagination: {
                            page: 1,
                            perPage: 10,
                        },
                        name: '',
                    });
                } else {
                    this.setState({
                        selectedRows: this.props.value || [],
                    });
                    this.loadData();
                }
            }
        );
    };
    loadData = async () => {
        const { name, pagination } = this.state;
        const subsiteIds = this.props.getSubsiteIds().join(',');
        try {
            const res: any = await api.get(
                {
                    ...pagination,
                    crowdName: name || undefined,
                    subsiteIds,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                    apiPath: '/admin/crowds',
                }
            );
            this.setState({
                datasource: res.result,
                total: res.totalNum,
                loading: false,
            });
        } catch (error) {
            services.errorHandle(error as any);
        }
    };
    onChangeNameFilter = (e: any) => {
        const { value } = e.target;
        this.setState({
            name: value,
        });
    };
    onSearch = () => {
        this.setState(
            {
                pagination: {
                    page: 1,
                    perPage: 10,
                },
            },
            this.loadData
        );
    };
    onReset = () => {
        this.setState({
            name: '',
        });
    };
    pageChange = (pageVal: any) => {
        this.setState({ pagination: { ...pageVal } }, () => {
            this.loadData();
        });
    };
    onTableRowSelect = (record: any, selected: boolean) => {
        const { selectedRows } = this.state;
        const rows = cloneDeep(selectedRows);
        if (selected) {
            rows.push(this.transformValue(record));
            this.setState({ selectedRows: rows });
        } else {
            remove(rows, (i) => {
                return i.id === record.id;
            });
            this.setState({ selectedRows: rows });
        }
    };
    onTableRowSelectAll = (selected: boolean, rows: any[]) => {
        const { selectedRows } = this.state;
        const selectedRowIds = map(selectedRows, 'id');
        if (selected) {
            const addRows = rows
                .filter((item: any) => {
                    return !selectedRowIds.includes(item.id);
                })
                .map(this.transformValue);
            this.setState({ selectedRows: [...selectedRows, ...addRows] });
        } else {
            this.setState({ selectedRows: [] });
        }
    };
    transformValue = (record: any) => {
        return {
            id: record.id,
            name: record.crowdName,
            peopleNumber: record.peopleNumber,
            subsiteName: record.subsiteName,
            creationMode: record.creationMode,
        };
    };
    renderModalContent = () => {
        const { name, loading, pagination, total, datasource, selectedRows } = this.state;
        return (
            <div className={prefix}>
                <div className={`${prefix}__filter`}>
                    <span>{services.language.getText('peopleName')}：</span>
                    <Input
                        placeholder={services.language.getText('inputPlease')}
                        onChange={this.onChangeNameFilter}
                        value={name}
                        style={{ width: 200, marginRight: 10 }}
                    />
                    <Button type="primary" style={{ marginRight: 10 }} onClick={this.onSearch}>
                        {services.language.getText('common.search')}
                    </Button>
                    <Button onClick={this.onReset}>
                        {services.language.getText('common.reset')}
                    </Button>
                </div>
                <div className={`${prefix}__table`}>
                    <Table
                        rowSelection={{
                            type: 'checkbox',
                            selectedRowKeys: map(selectedRows, 'id'),
                            onSelect: (record: any, selected: boolean, selectedRow: any) => {
                                this.onTableRowSelect(record, selected);
                            },
                            onSelectAll: (selected: boolean, rows: any[]) => {
                                this.onTableRowSelectAll(selected, rows);
                            },
                        }}
                        rowKey={(record: any) => record.id}
                        loading={loading}
                        pagination={{
                            current: pagination.page,
                            pageSize: pagination.perPage,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            pageSizeOptions: ['10', '15', '20'],
                            total,
                            showTotal(total: number) {
                                return services.interpolate(services.language.getText('total'), {
                                    total,
                                });
                            },
                            onChange: (page, perPage) => {
                                this.pageChange({ page, perPage });
                            },
                            onShowSizeChange: (page, perPage) => {
                                this.pageChange({ page, perPage });
                            },
                        }}
                        columns={[
                            {
                                title: services.language.getText('peopleName'),
                                dataIndex: 'crowdName',
                            },
                            {
                                title: services.language.getText('createType'),
                                dataIndex: 'creationMode',
                                render: (value: string) => {
                                    return value === 'RULE_MODE'
                                        ? services.language.getText('gzcj')
                                        : services.language.getText('drzdyh');
                                },
                            },
                            {
                                title: services.language.getText('subsites'),
                                dataIndex: 'subsiteName',
                            },
                            {
                                title: (
                                    <TextTips
                                        text={services.language.getText('peopleNum')}
                                        tips={services.language.getText('limitPeopleCount')}
                                    />
                                ),
                                dataIndex: 'peopleNumber',
                            },
                        ]}
                        dataSource={datasource}
                    />
                </div>
            </div>
        );
    };
    render() {
        const modalProps = {
            width: 900,
            title: services.language.getText('xzrq_2'),
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };
        return (
            <div>
                <Button type="link" onClick={this.toggleModal}>
                    {services.language.getText('selectPlease')}
                </Button>
                <Modal {...modalProps}>{this.renderModalContent()}</Modal>
            </div>
        );
    }
}

const prefix = 'crowds-selector';
