import React, { Component } from 'react';
import { InputNumber } from 'antd';
import './index.less';
import { forEach, isFunction, cloneDeep } from 'lodash';

export class NumberInputTip extends Component<any, any> {
    render() {
        const newProps: any = cloneDeep(this.props);
        const { tip, rules, style, row, disabled } = this.props;
        let { addonafter, addonbefore, max, min } = this.props;
        if (isFunction(addonafter)) {
            addonafter = addonafter(row);
        }
        if (isFunction(addonbefore)) {
            addonbefore = addonbefore(row);
        }
        if (isFunction(max)) {
            newProps.max = max(row);
        }
        if (isFunction(min)) {
            newProps.min = min(row);
        }
        let ruleInfo = {};
        if (rules && rules.length) {
            forEach(rules, (item) => {
                Object.assign(ruleInfo, item);
            });
        }
        return (
            <div className="number-input-tip">
                <span className="ant-input-group-wrapper" style={style}>
                    <span className="ant-input-wrapper ant-input-group">
                        {addonbefore && (
                            <span className="ant-input-group-addon">{addonbefore}</span>
                        )}
                        <InputNumber disabled={disabled} {...ruleInfo} {...newProps} />
                        {addonafter && <span className="ant-input-group-addon">{addonafter}</span>}
                    </span>
                </span>
                {tip && (
                    // <div {...this.props} style={{ fontSize: 12, color: '#ccc' }}>
                    //     {tip}
                    // </div>
                    <div className="ant-form-extra">{tip}</div>
                )}
            </div>
        );
    }
}
