/**
 * 消息群发
 */
import { Config } from '@comall-backend-builder/core/lib/parser';
import get from 'lodash/get';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { SendMemberMode } from '../../types/mode/object/member-selector/config-select-member';

export const config: Config = {
    entities: {
        /** 列表entity */
        MessageBatchEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
            apiPath: '/loader/admin/messages_batch',
            filters: {
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<sendTime>>',
                    controlConfig: {
                        placeholder: ['<<startTime>>', '<<endTime>>'],
                    },
                },
                subject: {
                    displayName: '<<xxbt>>',
                    type: 'string',
                },
                sendStatusEnum: {
                    type: 'string.options.select',
                    displayName: '<<fszt>>',
                    options: [
                        {
                            id: 'WAIT_SEND',
                            name: '<<notStarted>>',
                        },
                        {
                            id: 'SENDING',
                            name: '<<inprogress>>',
                        },
                        {
                            id: 'SENDED',
                            name: '<<end>>',
                        },
                        {
                            id: 'CLOSE',
                            name: '<<beforeClose>>',
                        },
                    ],
                },
            },
            properties: {
                subject: {
                    type: 'string',
                    displayName: '<<xxbt>>',
                },
                content: {
                    type: 'string',
                    displayName: '<<xxnr>>',
                },
                type: {
                    type: 'string.options.select',
                    displayName: '<<messageType>>',
                    options: [
                        {
                            id: 'MARKETING',
                            name: '<<ycxtz>>',
                        },
                        {
                            id: 'VOICE',
                            name: '<<fwtz>>',
                        },
                    ],
                },
                createTime: {
                    type: 'string',
                    displayName: '<<createTimeStr>>',
                },
                creator: {
                    type: 'string',
                    displayName: '<<cjr>>',
                },
                regularSendTime: {
                    type: 'string',
                    displayName: '<<sendTime>>',
                },
                sendStatusEnum: {
                    type: 'string.options.select',
                    displayName: '<<fszt>>',
                    options: [
                        {
                            id: 'WAIT_SEND',
                            name: '<<notStarted>>',
                        },
                        {
                            id: 'SENDING',
                            name: '<<inprogress>>',
                        },
                        {
                            id: 'SENDED',
                            name: '<<end>>',
                        },
                        {
                            id: 'CLOSE',
                            name: '<<beforeClose>>',
                        },
                    ],
                },
                sendStatus: {
                    type: 'string.options.select',
                    displayName: '<<fszt>>',
                    options: [
                        {
                            id: 'WAIT_SEND',
                            name: '<<notStarted>>',
                        },
                        {
                            id: 'SENDING',
                            name: '<<inprogress>>',
                        },
                        {
                            id: 'SENDED',
                            name: '<<end>>',
                        },
                        {
                            id: 'CLOSE',
                            name: '<<beforeClose>>',
                        },
                    ],
                },
                sendTime: {
                    type: 'string',
                    displayName: '<<sendTime>>',
                },
                creatorStr: {
                    type: 'string',
                    displayName: '<<cjr>>',
                },
            },
        },
        MessageTagEnity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/message_page/tags',
            filters: {
                subsiteName: { type: 'string', displayName: '<<subsiteName>>' },
                cardLevelName: { type: 'string', displayName: '<<hykmc>>' },
            },
            properties: {
                subsiteName: { type: 'string', displayName: '<<subsiteName>>' },
                cardLevelName: { type: 'string', displayName: '<<hykmc>>' },
                status: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'ENABLE', name: '<<enable>>' },
                        { id: 'DISABLE', name: '<<disabled>>' },
                    ],
                },
            },
        },
        /** 新增、修改 entity */
        MessageBatchHandleEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
            apiPath: '/loader/admin/messages_batch',
            properties: {
                subject: {
                    type: 'string',
                    displayName: '<<xxbt>>',
                    controlConfig: {
                        maxLength: 21,
                        placeholder: '<<qsrxxbt>>',
                    },
                    rules: [
                        {
                            required: true,
                            max: 20,
                            message: '<<qsrxxbtbcg>>',
                        },
                    ],
                },
                content: {
                    type: 'string.text.paragraph',
                    displayName: '<<xxnr>>',
                    controlConfig: {
                        rows: 4,
                        maxLength: 51,
                        placeholder: '<<qsrxxnr>>',
                    },
                    rules: [
                        {
                            required: true,
                            whitespace: true,
                            max: 50,
                            message: '<<qsrxxnr>>',
                        },
                    ],
                },
                pic: {
                    type: 'imageObjectArray',
                    displayName: '<<xxtp>>',
                    controlConfig: {
                        fileName: 'fileName',
                        uploadType: 'single',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                        maxCount: 1,
                        maxSize: 1 * 1024,
                        limitTips: {
                            limitText: '<<picLimitTips>>',
                            limitUnit: ' ',
                            className: 'image-picker-tips-ext',
                        },
                    },
                },
                type: {
                    type: 'string.options.select',
                    displayName: '<<messageType>>',
                    options: [
                        { id: 'VOICE', name: '<<fwtz>>' },
                        { id: 'MARKETING', name: '<<ycxtz>>' },
                    ],
                    rules: [{ required: true }],
                },
                tags: {
                    type: 'object.member.selector',
                    displayName: '<<qfdx>>',
                    rules: [{ required: true }],
                    defaultValue: { mode: SendMemberMode.ALL },
                },
                linkType: {
                    type: 'object.linkTypePopUpContent',
                    displayName: '<<linkConfig>>',
                    defaultValue: { linkType: 'none', linkParams: {} },
                },
                linkTypeNew: {
                    type: 'object.subsiteLinkTypeSelector',
                    displayName: '<<linkConfig>>',
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                        hideNeedUrlLink: true,
                    },
                },
                sendTimeEnum: {
                    type: 'string.options.select',
                    displayName: '<<fsfs>>',
                    options: [
                        { id: 'IMMEDIATELY_SEND', name: '<<ljfs>>' },
                        { id: 'REGULAR_SEND', name: '<<dsfs>>' },
                    ],
                    rules: [{ required: true }],
                },
                regularSendTime: {
                    type: 'string.dateTime',
                    displayName: '<<sendTime>>',
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                        range: {
                            start: new Date(),
                        },
                    },
                },
                offlineMessageSaveTime: {
                    type: 'string.options.select',
                    displayName: '<<lxxxblsz>>',
                    options: [
                        { id: '24', name: '<<yt>>' },
                        { id: '1', name: '<<yxs>>' },
                    ],
                    rules: [{ required: true }],
                },
            },
        },
    },
    components: {
        MessageBatchView: {
            component: 'Viewport',
            entity: 'MessageBatchEntity',
        },
        MessageBatchPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    submit: {
                        text: '<<common.search>>',
                        style: {
                            marginRight: 16,
                        },
                    },
                    reset: {
                        text: '<<common.reset>>',
                    },
                    style: {
                        marginBottom: 16,
                    },
                    fields: [
                        {
                            property: 'dateRange',
                        },
                        {
                            property: 'subject',
                        },
                        {
                            property: 'sendStatusEnum',
                            controlConfig: {
                                style: { width: 180 },
                            },
                        },
                    ],
                },
                {
                    component: 'Button',
                    text: '<<xjqf>>',
                    route: '/messageBatch/add',
                    type: 'primary',
                    icon: 'plus',
                    style: {
                        width: 110,
                        marginBottom: 10,
                    },
                },
                { component: 'MessageBatchTable' },
            ],
        },

        MessageBatchTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'subject',
                },
                {
                    property: 'content',
                },
                {
                    property: 'type',
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'creatorStr',
                },
                {
                    property: 'sendTime',
                },
                {
                    property: 'sendStatus',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 100,
                items: [
                    {
                        type: 'component',
                        component: 'MessageBatchButton',
                    },
                ],
            },
        },

        MessageBatchAddPage: {
            entity: 'MessageBatchHandleEntity',
            component: 'FlexLayout',
            items: [
                {
                    component: 'MessageBatchView',
                },
                {
                    component: 'MessageBatchAddForm',
                },
            ],
        },
        MessageBatchEditPage: {
            entity: 'MessageBatchHandleEntity',
            component: 'FlexLayout',
            items: [
                {
                    component: 'MessageBatchView',
                },
                {
                    component: 'MessageBatchEditForm',
                },
            ],
        },
        MessageBatchInfoPage: {
            entity: 'MessageBatchHandleEntity',
            component: 'FlexLayout',
            items: [
                {
                    component: 'MessageBatchInfoView',
                },
            ],
        },
        MessageBatchInfoView: {
            component: 'Detail',
            entity: 'MessageBatchHandleEntity',
            loaderType: 'get',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 820 },
            fields: [
                { property: 'subject' },
                { property: 'content' },
                { property: 'pic' },
                { property: 'type' },
                { property: 'tags' },
                { property: 'linkTypeNew' },
                { property: 'sendTimeEnum' },
                {
                    property: 'regularSendTime',
                    visible: (values: any) => get(values, 'sendTimeEnum') === 'REGULAR_SEND',
                },
                { property: 'offlineMessageSaveTime' },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'primary',
                    },
                ],
            },
        },
        MessageBatchAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            style: { width: 600 },
            labelCol: 6,
            controlCol: 18,
            fields: [
                { property: 'subject' },
                { property: 'content' },
                { property: 'pic' },
                { property: 'type' },
                { property: 'tags' },
                { property: 'linkType' },
                { property: 'sendTimeEnum' },
                {
                    property: 'regularSendTime',
                    visible: (values: any) => get(values, 'sendTimeEnum') === 'REGULAR_SEND',
                },
                { property: 'offlineMessageSaveTime' },
            ],
            submit: true,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
        },
        MessageBatchEditForm: {
            entity: 'MessageBatchHandleEntity',
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            loaderType: 'get',
            style: { width: 600 },
            labelCol: 6,
            controlCol: 18,
            fields: [
                { property: 'subject' },
                { property: 'content' },
                { property: 'pic' },
                { property: 'type' },
                { property: 'tags' },
                { property: 'linkType' },
                { property: 'sendTimeEnum' },
                {
                    property: 'regularSendTime',
                    visible: (values: any) => get(values, 'sendTimeEnum') === 'REGULAR_SEND',
                },
                { property: 'offlineMessageSaveTime' },
            ],
            submit: true,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('editSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/messageBatch',
                    component: 'MessageBatchView',
                    breadcrumbName: '<<messageBatch>>',
                    privilege: {
                        path: 'messageBatch',
                        level: 'full',
                    },
                    childRoutes: [
                        { path: '/add', component: 'MessageBatchAddPage' },
                        { path: '/edit/:id', component: 'MessageBatchEditPage' },
                        {
                            path: '/info/:id',
                            component: 'MessageBatchInfoPage',
                            breadcrumbName: '<<details>>',
                        },
                        { path: '/', component: 'MessageBatchPage' },
                    ],
                },
            ],
        },
    ],
};
