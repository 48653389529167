import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { find, forEach, map } from 'lodash';
const api = services.api;
export const ScratchCardLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/scratch_card_activities';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/scratch_card_activities/${id}`;
        } else {
            data.eventType = 'SCRATCH_CARD';
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.id = res.activityVo.id;
                res.editExecuteStatus = res.activityVo.executeStatus;
                res.pageShowId = res.pageShow.id;
                res.shareConfigId = res.activityVo.shareConfig.id;

                const normalConditions = res.activityVo.normalConditions;
                const exchangeConditions = res.activityVo.exchangeConditions;
                const subsiteConditions: any = find(normalConditions, {
                    normalConditionType: 'SUB_SITE',
                });
                const memberLevelConditions: any = find(normalConditions, {
                    normalConditionType: 'MEMBER_CARD_LEVEL',
                });
                const unifiedRewardConditions: any = exchangeConditions.filter(
                    (condition: any) => condition.exchangeMode === 'GIVE'
                );
                const unifiedRewardFlaseConditions: any = exchangeConditions.filter(
                    (condition: any) =>
                        condition.exchangeMode === 'GIVE' && condition.scopeId !== -1
                );
                const shareConditions: any = find(exchangeConditions, {
                    exchangeMode: 'SHARE',
                });
                const pointConditions: any = find(exchangeConditions, {
                    exchangeMode: 'POINT_CONSUME',
                });
                const rewardInfosMemberIds: number[] = [];
                if (unifiedRewardConditions) {
                    forEach(unifiedRewardConditions, (condition: any) => {
                        if (!rewardInfosMemberIds.includes(condition.scopeId)) {
                            rewardInfosMemberIds.push(condition.scopeId);
                        }
                    });
                }
                const rewardInfos: Array<any> = [];
                if (rewardInfosMemberIds) {
                    forEach(rewardInfosMemberIds, (memberId: number) => {
                        const rewardValue: any = find(exchangeConditions, {
                            exchangeMode: 'GIVE',
                            period: 'ACTIVITY',
                            scopeId: memberId,
                        });
                        const experienceValue: any = find(exchangeConditions, {
                            exchangeMode: 'GIVE',
                            period: 'DAY',
                            scopeId: memberId,
                        });
                        let cardLevelName = '全部参与用户等级';
                        if (memberId !== -1 && memberLevelConditions.includeIds) {
                            const level: any = find(memberLevelConditions.includeIds, {
                                id: memberId,
                            });
                            if (level) {
                                cardLevelName = level.name;
                            }
                        }
                        rewardInfos.push({
                            cardLevelId: memberId,
                            cardLevelName: cardLevelName,
                            rewardValue: rewardValue ? rewardValue.exchangeLimit : '',
                            experienceValue: experienceValue ? experienceValue.exchangeLimit : '',
                        });
                    });
                }
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : services.language.getText('longTerm'),
                    };
                }
                res.baseInfo = {
                    name: res.activityVo.name,
                    dateRange: {
                        start: res.activityVo.startTime,
                        end: res.activityVo.endTime,
                    },
                    subsiteIds: subsiteConditions ? subsiteConditions.includeIds : undefined,
                    shareStatus: res.activityVo.shareConfig.status + '',
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };
                const memberLevel = {
                    unifiedLevel:
                        memberLevelConditions && !memberLevelConditions.all ? false : true,
                    levelInfos:
                        memberLevelConditions && memberLevelConditions.includeIds
                            ? memberLevelConditions.includeIds.map((cardLevel: any) => {
                                  return {
                                      cardLevelId: cardLevel.id,
                                      cardLevelName: cardLevel.name,
                                  };
                              })
                            : [],
                    unifiedReward:
                        unifiedRewardFlaseConditions && unifiedRewardFlaseConditions.length > 0
                            ? false
                            : true,
                    rewardInfos:
                        rewardInfos && rewardInfos.length > 0
                            ? rewardInfos
                            : [
                                  {
                                      cardLevelId: -1,
                                      cardLevelName: '全部参与用户等级',
                                      rewardValue: '',
                                      experienceValue: '',
                                  },
                              ],
                    editSubsiteId:
                        subsiteConditions && subsiteConditions.includeIds
                            ? map(subsiteConditions.includeIds, 'id')
                            : undefined,
                };
                res.ruleInfo = {
                    memberLevel: memberLevel,
                    shareReward: {
                        list: shareConditions
                            ? [
                                  {
                                      shareType: shareConditions.period || 'ACTIVITY',
                                      limit: shareConditions.exchangeLimit,
                                  },
                              ]
                            : [],
                    },
                    pointExchange: {
                        list: pointConditions
                            ? [
                                  {
                                      limit: pointConditions.exchangeLimit,
                                      point: pointConditions.consumeValue,
                                  },
                              ]
                            : [],
                    },
                    periodLimit: {
                        list:
                            res.activityVo.rewardConfig &&
                            res.activityVo.rewardConfig.activityRewardLimit
                                ? [
                                      {
                                          limit: res.activityVo.rewardConfig.activityRewardLimit,
                                      },
                                  ]
                                : [],
                    },
                    remark: res.activityVo.remark,
                };
                let picturenPageShow = res.pageShow;
                if (res.lottery && res.lottery.pageConfig) {
                    const pageConfigJson = JSON.parse(res.lottery.pageConfig);
                    picturenPageShow = pageConfigJson;
                }
                res.pictureInfo = {
                    backgroundPicture: [
                        {
                            id: picturenPageShow.backgroundPicture.id || '',
                            path: picturenPageShow.backgroundPicture.url || '',
                        },
                    ],
                    showRewardRecord: res.pageShow.showRewardRecord + '',
                    sharePicture: [
                        {
                            id: res.activityVo.shareConfig.sharePicture.id || '',
                            path: res.activityVo.shareConfig.sharePicture.url || '',
                        },
                    ],
                    shareContent: res.activityVo.shareConfig.shareContent,
                    description: res.activityVo.description,
                };
                const assetRewards = [];
                if (
                    res.activityVo.rewardConfig.activityRewards &&
                    res.activityVo.rewardConfig.activityRewards.length > 0
                ) {
                    const activityRewardList = res.activityVo.rewardConfig.activityRewards;
                    // 是否是复制
                    const isCopy = window.location.href.includes('/copy');
                    for (let i = 0; i < activityRewardList.length; i++) {
                        const activityReward = activityRewardList[i];
                        for (let j = 0; j < activityReward.couponRewards.length; j++) {
                            const coupon = activityReward.couponRewards[j];
                            const couponReward: any = coupon;
                            if (isCopy) {
                                couponReward.issuedNum = 0;
                                couponReward.issuedCount = 0;
                                couponReward.inventory = couponReward.quantity || 0;
                                delete couponReward.id;
                            }

                            couponReward.conditionValue = activityReward.conditionValue;
                            couponReward.quantityType = coupon.quantity ? 'YES' : 'NO';
                            couponReward.rewardPeriod.memberRewardLimitType = coupon.rewardPeriod
                                .memberRewardLimit
                                ? 'YES'
                                : 'NO';
                            couponReward.rewardPeriod.periodQuantityType = coupon.rewardPeriod
                                .periodQuantity
                                ? 'YES'
                                : 'NO';
                            couponReward.picture = {
                                id: coupon.picture.id || '',
                                path: coupon.picture.url || '',
                            };
                            couponReward.type = {
                                checked: 'COUPON',
                                assetRewards: [
                                    {
                                        rewardType: 'COUPON',
                                        rewardValue: [
                                            {
                                                ...coupon,
                                                name: couponReward.couponName,
                                                id: coupon.couponDefinitionId,
                                                quantity: coupon.count,
                                            },
                                        ],
                                    },
                                ],
                            };
                            assetRewards.push(couponReward);
                        }
                        for (let j = 0; j < activityReward.assetRewards.length; j++) {
                            const reward = activityReward.assetRewards[j];
                            let assetReward: any = reward;
                            if (isCopy) {
                                assetReward.inventory = assetReward.quantity || 0;
                                assetReward.issuedCount = 0;
                                assetReward.issuedNum = 0;
                                delete assetReward.id;
                            }
                            assetReward.conditionValue = activityReward.conditionValue;
                            assetReward.quantityType = reward.quantity ? 'YES' : 'NO';
                            assetReward.rewardPeriod.memberRewardLimitType = reward.rewardPeriod
                                .memberRewardLimit
                                ? 'YES'
                                : 'NO';
                            assetReward.rewardPeriod.periodQuantityType = reward.rewardPeriod
                                .periodQuantity
                                ? 'YES'
                                : 'NO';
                            assetReward.picture = {
                                id: reward.picture.id || '',
                                path: reward.picture.url || '',
                            };
                            assetReward.type = {
                                checked: reward.rewardType,
                                assetRewards: [
                                    {
                                        rewardType: reward.rewardType,
                                        rewardValue: reward.rewardValue,
                                    },
                                ],
                            };
                            assetRewards.push(assetReward);
                        }
                    }
                    assetRewards.sort(function(a, b) {
                        if (a.sequence < b.sequence) {
                            return -1;
                        } else if (a.sequence === b.sequence) {
                            return 0;
                        } else {
                            return 1;
                        }
                    });
                }
                res.prizeInfo = {
                    assetRewards: assetRewards,
                    finishWithNoPrize: res.lottery.finishWithNoPrize ? 'true' : undefined,
                };
            } else {
                if (!id) {
                    res.result =
                        res &&
                        res.result.map((item: any) => {
                            item.eventType = 'SCRATCH_CARD';
                            item.canExport =
                                item.executeStatus === 'STARTED' ||
                                item.executeStatus === 'COMPLETE';
                            return item;
                        });
                }
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/scratch_card_activities';

        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        if (params.id) {
            config.apiPath = `/admin/scratch_card_activities/${params.id}`;
        }
        const newParams = dataParser(params);
        newParams.activityVo.id = params.id;
        newParams.activityVo.shareConfig.id = params.shareConfigId;
        newParams.pageShow.id = params.pageShowId;
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    if (params.baseInfo && params.ruleInfo) {
        newParams.activityVo = {
            eventType: 'SCRATCH_CARD',
            name: params.baseInfo.name,
            startTime: params.baseInfo.dateRange.start,
            endTime: params.baseInfo.dateRange.end,
            remark: params.ruleInfo.remark,
            normalConditions: [
                {
                    all: false,
                    normalConditionType: 'SUB_SITE',
                    includeIds: params.baseInfo.subsiteIds,
                },
            ],
            shareConfig: {
                shareContent: params.pictureInfo.shareContent,
                sharePicture:
                    params.pictureInfo.sharePicture && params.pictureInfo.sharePicture[0]
                        ? {
                              id: params.pictureInfo.sharePicture[0].id,
                              url: params.pictureInfo.sharePicture[0].path,
                          }
                        : undefined,
                status: params.baseInfo.shareStatus.includes('true') ? true : false,
            },
            description: params.pictureInfo.description,
        };
        //规则设置
        const exchangeConditions: Array<any> = [];
        if (params.ruleInfo.memberLevel) {
            const memberLevel = params.ruleInfo.memberLevel;
            //保存选择的用户等级
            if (memberLevel.unifiedLevel) {
                newParams.activityVo.normalConditions.push({
                    all: true,
                    normalConditionType: 'MEMBER_CARD_LEVEL',
                });
            } else {
                newParams.activityVo.normalConditions.push({
                    all: false,
                    normalConditionType: 'MEMBER_CARD_LEVEL',
                    includeIds: memberLevel.levelInfos.map((levelInfo: any) => {
                        return {
                            id: levelInfo.cardLevelId,
                            name: levelInfo.cardLevelName,
                        };
                    }),
                });
            }
            //保存获得抽奖次数规则
            const rewardInfos = memberLevel.rewardInfos;
            forEach(rewardInfos, (rewardInfo) => {
                //初始抽奖次数
                exchangeConditions.push({
                    scopeId: rewardInfo.cardLevelId,
                    exchangeMode: 'GIVE',
                    exchangeLimit: rewardInfo.rewardValue,
                    period: 'ACTIVITY',
                    consumeValue: 0,
                    normalConditionType: 'MEMBER_CARD_LEVEL',
                });
                //每天额外获得抽奖次数
                exchangeConditions.push({
                    scopeId: rewardInfo.cardLevelId,
                    exchangeMode: 'GIVE',
                    exchangeLimit: rewardInfo.experienceValue,
                    period: 'DAY',
                    consumeValue: 0,
                    normalConditionType: 'MEMBER_CARD_LEVEL',
                });
            });
            //保存分享获得抽奖次数
            const shareStatusFlag = params.baseInfo.shareStatus.includes('true') ? true : false;
            if (
                shareStatusFlag &&
                params.ruleInfo.shareReward &&
                params.ruleInfo.shareReward.list &&
                params.ruleInfo.shareReward.list.length > 0
            ) {
                exchangeConditions.push({
                    scopeId: -1,
                    exchangeMode: 'SHARE',
                    exchangeLimit: params.ruleInfo.shareReward.list[0].limit,
                    exchangeValue: 1,
                    period: params.ruleInfo.shareReward.list[0].shareType,
                    consumeValue: 1,
                    normalConditionType: 'MEMBER_CARD_LEVEL',
                });
            }
            //保存积分兑换抽奖次数
            if (
                params.ruleInfo.pointExchange &&
                params.ruleInfo.pointExchange.list &&
                params.ruleInfo.pointExchange.list.length > 0
            ) {
                exchangeConditions.push({
                    scopeId: -1,
                    exchangeMode: 'POINT_CONSUME',
                    exchangeLimit: params.ruleInfo.pointExchange.list[0].limit,
                    exchangeValue: 1,
                    period: 'ACTIVITY',
                    consumeValue: params.ruleInfo.pointExchange.list[0].point,
                    normalConditionType: 'MEMBER_CARD_LEVEL',
                });
            }
            newParams.activityVo.exchangeConditions = exchangeConditions;
        }
    }
    if (params.pictureInfo) {
        const pageConfig: any = {
            backgroundPicture: {
                id: params.pictureInfo.backgroundPicture[0].id,
                url: params.pictureInfo.backgroundPicture[0].path,
            },
        };
        let finishWithNoPrize = false;
        const assetRewards = params.prizeInfo.assetRewards || [];
        const findNoneReward = assetRewards.filter((item: any) => item.type.checked === 'NONE');
        if (
            findNoneReward.length > 0 &&
            params.prizeInfo.finishWithNoPrize &&
            params.prizeInfo.finishWithNoPrize.includes('true')
        ) {
            finishWithNoPrize = true;
        }
        newParams.lottery = {
            pageConfig: JSON.stringify(pageConfig),
            finishWithNoPrize,
        };
        newParams.pageShow = {
            showRewardRecord: params.pictureInfo.showRewardRecord,
        };
    }
    if (
        params.prizeInfo &&
        params.prizeInfo.assetRewards &&
        params.prizeInfo.assetRewards.length > 0
    ) {
        const assetRewardsInfo = [];
        // const couponRewardsInfo = [];
        for (let i = 0; i < params.prizeInfo.assetRewards.length; i++) {
            const assetReward = params.prizeInfo.assetRewards[i];
            var checked = assetReward.type.checked; //奖品项最后选中的类型
            var assetRewardsList = assetReward.type.assetRewards;
            if (checked === 'COUPON') {
                for (let j = 0; j < assetRewardsList.length; j++) {
                    const assetRewardsItem = assetRewardsList[j];
                    if (assetRewardsItem.rewardType === checked) {
                        //奖品项最终的输入
                        let couponDefinitionId;
                        let count;
                        if (
                            assetRewardsItem.rewardValue &&
                            assetRewardsItem.rewardValue.length > 0
                        ) {
                            couponDefinitionId = assetRewardsItem.rewardValue[0].id;
                            count = assetRewardsItem.rewardValue[0].quantity;
                        }
                        let raward = {
                            conditionValueMax: 0,
                            conditionValue: assetReward.conditionValue || null,
                            couponRewards: [
                                {
                                    count: count ? count : 1,
                                    couponDefinitionId: couponDefinitionId,
                                    name: assetReward.name,
                                    picture: assetReward.picture,
                                    quantity:
                                        assetReward.quantityType === 'YES'
                                            ? assetReward.quantity
                                            : null,
                                    sequence: assetReward.sequence,
                                    tip: assetReward.tip,
                                    id: window.location.href.includes('/edit')
                                        ? assetReward.id || null
                                        : null,
                                    rewardPeriod: {
                                        crowdScope: 'ALL',
                                        memberRewardLimit:
                                            assetReward?.rewardPeriod?.memberRewardLimit || null,
                                        period: 'DAY',
                                        periodQuantity:
                                            assetReward?.rewardPeriod?.periodQuantity || null,
                                    },
                                    status: assetReward.status,
                                },
                            ],
                        };
                        assetRewardsInfo.push(raward);
                    }
                }
            } else {
                if (checked === 'NONE') {
                    let raward = {
                        conditionValueMax: 0,
                        conditionValue: assetReward.conditionValue || null,
                        assetRewards: [
                            {
                                rewardType: checked,
                                name: assetReward.name,
                                picture: assetReward.picture,
                                quantity:
                                    assetReward.quantityType === 'YES'
                                        ? assetReward.quantity
                                        : null,
                                sequence: assetReward.sequence,
                                tip: assetReward.tip,
                                id: window.location.href.includes('/edit')
                                    ? assetReward.id || null
                                    : null,
                                status: assetReward.status,
                            },
                        ],
                    };
                    assetRewardsInfo.push(raward);
                } else {
                    if (assetRewardsList && assetRewardsList.length > 0) {
                        for (let j = 0; j < assetRewardsList.length; j++) {
                            const assetRewardsItem = assetRewardsList[j];
                            let raward = {
                                conditionValueMax: 0,
                                conditionValue: assetReward.conditionValue || null,
                                assetRewards: [
                                    {
                                        rewardType: checked,
                                        name: assetReward.name,
                                        picture: assetReward.picture,
                                        quantity:
                                            assetReward.quantityType === 'YES'
                                                ? assetReward.quantity
                                                : null,
                                        rewardValue: assetRewardsItem.rewardValue,
                                        sequence: assetReward.sequence,
                                        tip: assetReward.tip,
                                        id: window.location.href.includes('/edit')
                                            ? assetReward.id || null
                                            : null,
                                        rewardPeriod: {
                                            crowdScope: 'ALL',
                                            memberRewardLimit:
                                                assetReward?.rewardPeriod?.memberRewardLimit ||
                                                null,
                                            period: 'DAY',
                                            periodQuantity:
                                                assetReward?.rewardPeriod?.periodQuantity || null,
                                        },
                                        status: assetReward.status,
                                    },
                                ],
                            };
                            assetRewardsInfo.push(raward);
                        }
                    }
                }
            }
        }

        newParams.activityVo.rewardConfig = {
            rewardRule: 'RANDOM',
            crowdScope: 'EACH',
            activityRewardLimit:
                params.ruleInfo.periodLimit &&
                params.ruleInfo.periodLimit.list &&
                params.ruleInfo.periodLimit.list.length > 0
                    ? params.ruleInfo.periodLimit.list[0].limit
                    : undefined,
            activityRewards: assetRewardsInfo,
        };
        newParams.marketingScheduleId =
            (params.baseInfo?.marketingSchedule?.length &&
                params.baseInfo.marketingSchedule[0].id) ||
            null;
        /* newParams.activityVo.rewardConfig.activityRewards = [
            {
                assetRewards: assetRewardsInfo.length > 0 ? assetRewardsInfo : null,
                couponRewards: couponRewardsInfo.length > 0 ? couponRewardsInfo : null,
            },
        ]; */
    }

    return newParams;
}
