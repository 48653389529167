import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { SelectProductBrands } from './select-product-brands';
import { ProductBrandsSelectDisplay } from './product-brands-select-display';

export class ProductBrandsSelectorMode extends modes.ArrayMode {
    getDisplayComponent(value: any) {
        return (
            <ProductBrandsSelectDisplay
                data={value}
                onRemove={() => {}}
                disabled={true}
            ></ProductBrandsSelectDisplay>
        );
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <SelectProductBrands {...displayInfo} />;
    }
}
