import * as React from 'react';
import classNames from 'classnames';
import { ObjectMode } from '@comall-backend-builder/types';
import { RegistrationPrice } from '../../../components';
import { services } from '@comall-backend-builder/core';

export class ObjectRegistrationPriceMode extends ObjectMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(object: any, displayInfo: any) {
        const { className, style } = displayInfo;
        const { pointDealPrice, orderAmount } = object;
        if (typeof pointDealPrice === 'number' && typeof orderAmount === 'number') {
            let priceStr = '';
            if (pointDealPrice === 0 && orderAmount === 0) {
                priceStr = '0';
            } else if (pointDealPrice && orderAmount) {
                priceStr = `${pointDealPrice}积分+${orderAmount}${services.language.getText(
                    'yuan'
                )}`;
            } else if (!pointDealPrice && orderAmount) {
                priceStr = `${orderAmount}${services.language.getText('yuan')}`;
            } else if (pointDealPrice && !orderAmount) {
                priceStr = `${pointDealPrice}积分`;
            }
            return (
                <div
                    className={classNames('type-object mode-object-winning-lots-number', className)}
                    style={style}
                >
                    <span>{priceStr}</span>
                </div>
            );
        } else {
            return this.getNotAvailableDisplayComponent(displayInfo);
        }
    }

    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            '暂无数据'
        );
    }

    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <RegistrationPrice {...controlInfo} />;
    }
}
