import React, { PureComponent } from 'react';
import { map, get, isEqual, isUndefined } from 'lodash';
import { CouponSelector } from './coupon-select';
import { CouponPackageSelector } from './coupon-package-select';
import { CouponPackageSelectorDisplay } from './coupon-selected-display';

export interface SalesElectronicCoupon {
    id: string;
    name: string;
    batchNo: string;
    couponType: string;
    source: string;
    sourceDesc: string;
    mode: string;
    couponValue: string;
    dynamicEffectivePeriod: string;
    startTime: string;
    endTime: string;
    executeStatus: string;
}

export class SalesElectronicCouponSelect extends PureComponent<any> {
    componentWillReceiveProps(nextProps: any) {
        const currentSubsite = get(this.props, 'row.baseInfo.subsites');
        const nextSubsite = get(nextProps, 'row.baseInfo.subsites', []);
        if (!isUndefined(currentSubsite) && !isEqual(currentSubsite, nextSubsite)) {
            this.props.onChange([]);
        }
        const currentSalesChannels = get(this.props, 'row.baseInfo.salesChannels');
        const nextSalesChannels = get(nextProps, 'row.baseInfo.salesChannels', []);
        if (
            !isUndefined(currentSalesChannels) &&
            !isEqual(currentSalesChannels, nextSalesChannels)
        ) {
            this.props.onChange([]);
        }
        const creditCurrentSubsite = get(this.props, 'row.subsiteId');
        const creditNextSubsite = get(nextProps, 'row.subsiteId', []);
        if (
            !isUndefined(creditCurrentSubsite) &&
            !isEqual(creditCurrentSubsite, creditNextSubsite)
        ) {
            this.props.onChange([]);
        }
    }

    getSubsiteIds = (props: any) => {
        const { row, getSubsiteIds } = props;
        if (typeof getSubsiteIds === 'function') {
            return getSubsiteIds(props);
        }
        const subsiteIds =
            row.baseInfo && row.baseInfo.subsites ? map(row.baseInfo.subsites, 'id') : null;
        return subsiteIds;
    };

    render() {
        const { onChange, type, value, disabled, selectMode = '', row } = this.props;
        const hasValue = value && value.length > 0;
        const subsiteIds = this.getSubsiteIds(this.props);
        const params: any = {
            subsiteIds: subsiteIds,
            executeStatus: 'NOT_FINISHED',
        };
        let CouponPackageShow = true;
        if (selectMode) {
            const salesChannels = row?.baseInfo?.salesChannels || '';
            if (salesChannels.includes(selectMode)) {
                CouponPackageShow = false;
                params.couponTypes = 'CASH,GIFT,FREIGHT,PARKING,BFPLAT_COUPON';
            }
        }
        return (
            <div className="sales-electronic-coupon-select">
                <div>
                    <CouponSelector
                        buttonProps={{ disabled }}
                        onChange={onChange}
                        selectValues={value}
                        type={type}
                        params={params}
                    ></CouponSelector>
                    {CouponPackageShow && (
                        <CouponPackageSelector
                            buttonProps={{ disabled }}
                            onChange={onChange}
                            selectValues={value}
                            type={type}
                            params={params}
                        ></CouponPackageSelector>
                    )}
                </div>
                {hasValue && (
                    <CouponPackageSelectorDisplay
                        data={value}
                        disabled={disabled}
                        onChange={onChange}
                    />
                )}
            </div>
        );
    }
}
