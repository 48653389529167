import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { ThemeStyleAdvancedSelect } from './theme-style-advanced-selector';

export class ObjectThemeStylenAdvancedSelectorMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <ThemeStyleAdvancedSelect {...controlInfo} />;
    }
}
