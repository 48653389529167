import { isEmpty } from 'lodash';
import { services } from '@comall-backend-builder/core/';
import { subsites } from '../containers/page-design';
import { merchantId } from '../../../containers/design-merchant';

export const NormalProductsLoader = {
    get: async function(data: any, config: any) {
        config.apiPath = `/admin/products`;
        if (!data.productType) {
            data.productTypes = 'NORMAL,WEIGHT,COMBINE,VIRTUAL_COUPON';
            delete data.productType;
        }
        if (merchantId >= 0) {
            data.merchantId = merchantId;
        }
        if (!isEmpty(subsites)) {
            data.subsiteIds = subsites
                .map((subsite: any) => {
                    return subsite.id;
                })
                .join(',');
        }
        const res: any = await services.api.get(data, config);
        res.result &&
            res.result.length &&
            res.result.forEach((item: any) => {
                item.brandName = item.brandVo?.name;
                item.isProduct = true;
            });
        return res;
    },
};
