import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
import moment from 'moment';
// import { services } from '@comall-backend-builder/core';

// const api = services.api;
export enum ConsumeType {
    /**
     * 全部
     */
    ALL = 'ALL',
    /**
     * 电商端核销
     */
    E_SHOP_MEMBER = 'E_SHOP_MEMBER',
    /**
     * 店员扫一扫
     */
    E_SHOP_SCAN_CONSUME = 'E_SHOP',
    /**
     * 导购端核销
     */
    GUIDE = 'GUIDE',

    /**
     * POS核销
     */
    POS = 'POS',
    /**
     * 运营后台核销
     */
    MANAGE = 'MANAGE',
}

export const CONSUME_TYPE_OPYIONS = [
    {
        id: ConsumeType.ALL,
        name: '<<all>>',
    },
    {
        id: ConsumeType.E_SHOP_MEMBER,
        name: '<<dsdhx>>',
    },
    // {
    //     id: ConsumeType.E_SHOP_SCAN_CONSUME,
    //     name: '<<dysys>>',
    // },
    {
        id: ConsumeType.GUIDE,
        name: '<<dgdhx>>',
    },
    {
        id: ConsumeType.POS,
        name: '<<poshx>>',
    },
    {
        id: ConsumeType.MANAGE,
        name: '<<yyhthx>>',
    },
];

export const config: Config = {
    entities: {
        CouponConsumeHistoryEntity: {
            apiPath: '/loader/admin/coupon_consume_history',
            filters: {
                subsiteIds: {
                    type: 'string.options.select',
                    displayName: '<<hxmd>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                couponName: {
                    type: 'string',
                    displayName: '<<couponName>>',
                },
                mobile: {
                    type: 'string.text.trim',
                    displayName: '<<hysjh>>',
                    rules: [
                        {
                            validator: (rule: any, value: any, callback: any) => {
                                if (value && value.trim()) {
                                    if (!/^[0-9]*$/.test(value.trim())) {
                                        return callback(services.language.getText('jzcsrsz'));
                                    }
                                }
                                callback();
                            },
                        },
                    ],
                },
                couponNo: {
                    type: 'string',
                    displayName: '<<yhqqh>>',
                },
                channel: {
                    type: 'string.options.select',
                    displayName: '<<usedChannelLimit>>',
                    options: [
                        {
                            id: 'OFFLINE',
                            name: '<<xxhx>>',
                        },
                        {
                            id: 'ONLINE',
                            name: '<<xshx>>',
                        },
                    ],
                },
                consumeMethod: {
                    type: 'string.options.select',
                    displayName: '<<hxfs>>',
                    defaultValue: undefined,
                    options: CONSUME_TYPE_OPYIONS,
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<ksjssj>>',
                    defaultValue: {
                        start: moment()
                            .subtract(30, 'days')
                            .format('YYYY-MM-DD'),
                        end: moment().format('YYYY-MM-DD'),
                    },
                },
                requestId: {
                    type: 'string',
                    displayName: '<<xxhxlsh>>',
                },
            },
            properties: {
                couponConsumeOrder: {
                    type: 'object',
                    properties: {
                        channel: {
                            type: 'string',
                            displayName: '<<usedChannelLimit>>',
                        },
                        method: {
                            type: 'string',
                            displayName: '<<hxfs>>',
                        },
                        mobile: {
                            type: 'string',
                            displayName: '<<hysjh>>',
                        },
                        crmId: {
                            type: 'string',
                            displayName: '<<crmId>>',
                        },
                        orderNo: {
                            type: 'string',
                            displayName: '<<jylsh>>',
                        },
                        subsite: {
                            type: 'object',
                            properties: {
                                name: {
                                    type: 'string',
                                    displayName: '<<hxmd>>',
                                },
                            },
                        },
                        merchant: {
                            type: 'object',
                            properties: {
                                name: {
                                    type: 'string',
                                    displayName: '<<hxzj>>',
                                },
                            },
                        },
                        userName: {
                            type: 'string',
                            displayName: '<<hxdy>>',
                        },
                        createTime: {
                            type: 'string',
                            displayName: '<<hxsj>>',
                        },
                        requestId: {
                            type: 'string',
                            displayName: '<<xxhxlsh>>',
                        },
                        deviceNumber: {
                            type: 'string',
                            displayName: 'POS核销终端号',
                        },
                    },
                },
                couponData: {
                    type: 'object',
                    properties: {
                        couponNo: {
                            type: 'string',
                            displayName: '<<couponNum>>',
                        },
                    },
                },
                couponRule: {
                    type: 'object',
                    properties: {
                        name: {
                            type: 'string.popover',
                            displayName: '<<couponName>>',
                        },
                    },
                },
            },
        },
        CouponTransferRecordEntity: {
            apiPath: '/loader/admin/coupon_transfer_record',
            filters: {
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<zsrq>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<transferMobile>>',
                },
                receiverMobile: {
                    type: 'string',
                    displayName: '<<receiverMobile>>',
                },
            },
            properties: {
                createTime: {
                    type: 'string',
                    displayName: '<<date>>',
                },
                couponNo: {
                    type: 'string',
                    displayName: '<<yhqqm>>',
                },
                couponName: {
                    type: 'string',
                    displayName: '<<couponName>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<transferMobile>>',
                },
                receiverMobile: {
                    type: 'string',
                    displayName: '<<receiverMobile>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<zszt>>',
                    options: [
                        {
                            id: 'TRANSFERRING',
                            name: '<<transferring>>',
                        },
                        {
                            id: 'WITHDRAWN',
                            name: '<<withdrawn>>',
                        },
                        {
                            id: 'RECEIVED',
                            name: '<<received>>',
                        },
                        {
                            id: 'RECEIVE_FAILED',
                            name: '<<receiveFailed>>',
                        },
                        {
                            id: 'REJECTED',
                            name: '<<rejected_2>>',
                        },
                        {
                            id: 'EXPIRED',
                            name: '<<ygq>>',
                        },
                    ],
                },
                receiveTime: {
                    type: 'string',
                    displayName: '<<receiveTime>>',
                },
            },
        },
    },
    components: {
        CouponConsumeHistoryView: {
            component: 'Viewport',
        },
        CouponConsumeHistoryPageView: {
            component: 'FlexLayout',
            direction: 'vertical',
            style: {
                padding: 20,
            },
            items: [{ component: 'CouponConsumeHistoryPageTabs' }],
        },
        CouponConsumeHistoryPageTabs: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<mallActivityVerification>>',
                    content: {
                        component: 'CouponConsumeHistoryPage',
                    },
                },
                {
                    title: '<<exchangeRecord>>',
                    content: {
                        component: 'CouponTransferRecordPage',
                    },
                },
            ],
        },
        CouponConsumeHistoryPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'CouponConsumeHistoryEntity',
            className: 'table-content-viewport',
            style: {
                padding: '10px 15px',
            },
            items: [
                {
                    component: 'CouponConsumeHistoryFilter',
                },
                { component: 'CouponConsumeHistoryTable' },
            ],
        },
        CouponConsumeHistoryFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteIds',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'couponName',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'mobile',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'couponNo',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'requestId',
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
                {
                    property: 'channel',
                    controlConfig: {
                        style: { width: 100 },
                    },
                },
                {
                    property: 'consumeMethod',
                    controlConfig: {
                        style: { width: 120 },
                    },
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
            ],
        },
        CouponTransferRecordPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'CouponTransferRecordEntity',
            className: 'table-content-viewport',
            style: {
                padding: '10px 15px',
            },
            items: [
                {
                    component: 'CouponTransferRecordFilter',
                },
                {
                    component: 'CouponTransferRecordTable',
                },
            ],
        },
        CouponTransferRecordFilter: {
            component: 'FilterForm',
            direction: 'inline',
            style: {
                marginBottom: 16,
            },
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'dateRange',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'receiverMobile',
                },
            ],
        },
        CouponTransferRecordTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'createTime',
                },
                {
                    property: 'couponNo',
                },
                {
                    property: 'couponName',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'receiverMobile',
                },
                {
                    property: 'status',
                },
                {
                    property: 'receiveTime',
                },
            ],
            scroll: { x: true },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/coupon/coupon-consume-history',
                    component: 'CouponConsumeHistoryView',
                    breadcrumbName: '<<consumeAndExchangeRecord>>',
                    privilege: {
                        path: 'couponConsumeOrderItems',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'CouponConsumeHistoryPageView' }],
                },
            ],
        },
    ],
};
