import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { Checkbox, Input } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { find, forEach, cloneDeep } from 'lodash';
import { PushAndSiteMailSceneType } from '../../../../../../containers/wechat/common';
import { MessagesSubscriptioWechatAndAlipay } from '../../../../../../containers/wechat/messages-subscription-find-list-item/message-subscription-wechat-alipay';
import { MessagesSubscriptioEmail } from '../../../../../../containers/wechat/messages-subscription-find-list-item/message-subscription-email';
import { MessagesSubscriptioPushAndSiteMail } from '../../../../../../containers/wechat/messages-subscription-find-list-item/message-subscription-push-sitemail';
import { MessageNotificationItemEdit } from '../message-notification-item-edit/message-notification-item-edit';
import {
    MessageNotificationMethodValueItem,
    MsgChannelType,
    LandingPage,
} from '../../message-notification-method';
import { getI18NLinkNameById } from '../../../../../../components/link-type-effect-path/config';
import './message-notification-item.less';

const { TextArea } = Input;

interface MessageNotificationItemProps {
    template: MessageNotificationMethodValueItem;
    scene: PushAndSiteMailSceneType;
    onChange: (template: MessageNotificationMethodValueItem) => void;
    landingPage: LandingPage;
}
interface MessageNotificationItemState {}

export class MessageNotificationItem extends PureComponent<
    MessageNotificationItemProps,
    MessageNotificationItemState
> {
    onChangeMsgChecked = (e: CheckboxChangeEvent) => {
        const { template, onChange } = this.props;
        const status = e.target.checked ? 1 : 0;
        onChange({
            ...template,
            status,
        });
    };
    renderHeader = () => {
        const { template } = this.props;
        const typeName = {
            [MsgChannelType.WEAPP]: services.language.getText('wxxcxdyxx'),
            [MsgChannelType.WECHAT_OFFICIAL_ACCOUNT]: services.language.getText('wxgzhmbxx'),
            [MsgChannelType.ALIPAY_SUBSCRIBE_MSG]: services.language.getText('zfbxcxdyxx'),
            [MsgChannelType.EMAIL]: services.language.getText('mail'),
            [MsgChannelType.SITEMAIL]: services.language.getText('siteEmail'),
            [MsgChannelType.SMS]: services.language.getText('dx'),
            [MsgChannelType.APP_PUSH]: 'Apppush',
        };
        const templateDisabled = template.msgStatus === 'DISABLE';
        const checked = template.status === 1;
        return (
            <div className={classes.header}>
                <Checkbox
                    disabled={templateDisabled}
                    checked={checked}
                    onChange={this.onChangeMsgChecked}
                >
                    {typeName[template.msgChannelType]}
                </Checkbox>
            </div>
        );
    };

    renderTip = () => {
        const { template } = this.props;
        if (template.msgChannelType === MsgChannelType.WEAPP) {
            return (
                <div className={classes.tip}>{services.language.getText('yhaxdydyhcckfsxx')}</div>
            );
        }
        return null;
    };

    renderContent = () => {
        const { template: oldtemplate, landingPage, scene } = this.props;
        const goImage = require('../../images/icon-miniprogram-right.png');
        const miniImage = require('../../images/icon-miniprogram.png');
        const template = cloneDeep(oldtemplate);
        const { msgChannelType } = template;
        let top, bottom;
        let messageList: any[] = [];
        if (template.params) {
            top = find(template.params, { name: 'top' });
            bottom = find(template.params, { name: 'bottom' });
            forEach(template.params, (param: any) => {
                if (param.name !== 'name' && param.name !== 'top' && param.name !== 'bottom') {
                    if (param.fieldType === 'file' && param.value) {
                        param.value = JSON.stringify(param.value);
                    }
                    messageList.push(param);
                }
            });
        }
        // 如果是微信消息订阅 、支付宝、微信公众号模板消息
        const isWechantAndAlipay =
            msgChannelType === MsgChannelType.WEAPP ||
            msgChannelType === MsgChannelType.WECHAT_OFFICIAL_ACCOUNT ||
            msgChannelType === MsgChannelType.ALIPAY_SUBSCRIBE_MSG;
        const messageStyle = {
            innerClass: classes,
            top,
            bottom,
            goImage,
            miniImage,
        };
        if (isWechantAndAlipay) {
            let landingPageName = landingPage.name;
            if (scene === PushAndSiteMailSceneType.PAY_AWARD_RECEIVE_REMIND) {
                landingPageName = this.getLinkName(template.targetUrl);
            }
            return (
                <MessagesSubscriptioWechatAndAlipay
                    messageStyle={messageStyle}
                    messageList={messageList}
                    msgChannelType={msgChannelType}
                    name={template.name}
                    landingPage={landingPageName}
                />
            );
        }
        // 邮件
        if (msgChannelType === MsgChannelType.EMAIL) {
            return (
                <MessagesSubscriptioEmail messageStyle={messageStyle} messageList={messageList} />
            );
        }

        // 短信
        if (msgChannelType === MsgChannelType.SMS) {
            return this.renderSms(messageList);
        }

        // 站内信和Apppush
        if (
            msgChannelType === MsgChannelType.APP_PUSH ||
            msgChannelType === MsgChannelType.SITEMAIL
        ) {
            let landingPageName = landingPage.name;
            if (scene === PushAndSiteMailSceneType.PAY_AWARD_RECEIVE_REMIND) {
                landingPageName = template.targetType
                    ? getI18NLinkNameById(template.targetType)
                    : '';
            }
            return (
                <MessagesSubscriptioPushAndSiteMail
                    messageStyle={messageStyle}
                    messageList={messageList}
                    name={template.name}
                    landingPageName={landingPageName}
                />
            );
        }
        return null;
    };

    getLinkName = (url: string | null) => {
        const links = [
            {
                value: 'subpackages/topic/index',
                label: services.language.getText('zthd'),
            },
            {
                value: 'subpackages/wheel-draw/pages/detail/index',
                label: services.language.getText('dzpcj'),
            },
            {
                value: 'subpackages/packet-rain/index',
                label: services.language.getText('hby'),
            },
            {
                value:
                    'subpackages/market/payment-reward/pages/detail/index?type=PAY_AWARD&subsiteId={$subsiteId}',
                label: services.language.getText('zfyl'),
            },
            {
                value: 'subpackages/scratch-card/index',
                label: services.language.getText('scratchCard'),
            },
            {
                value: 'subpackages/lottery-code/index',
                label: services.language.getText('lotteryCode'),
            },
        ];
        if (!url) {
            return '';
        }
        return links.find((i) => url.includes(i.value))?.label || '';
    };

    getSmsRemark = () => {
        const { scene } = this.props;
        if (scene === PushAndSiteMailSceneType.BIRTHDAY_GIFT_RECEIVE_REMIND) {
            return [
                services.language.getText('blzihzddqhdmm'),
                services.language.getText('rgablzdzwfzddqdymc'),
                services.language.getText('alzjdyhndsrl'),
            ];
        } else if (scene === PushAndSiteMailSceneType.PAY_AWARD_RECEIVE_REMIND) {
            return [
                services.language.getText('zfylwasl'),
                services.language.getText('zfylshortLink'),
                services.language.getText('zfylcount'),
                services.language.getText('zfylnumber'),
            ];
        } else if (scene === PushAndSiteMailSceneType.LOTTERY_CODE_AWARD_NOTICE) {
            return [services.language.getText('jfcjmbl')];
        }
    };

    renderSms = (messageList: any) => {
        const { template } = this.props;
        const [content = { name: '', value: '' }] = messageList;
        const editDisabled = template.msgStatus === 'DISABLE';
        const remark = this.getSmsRemark();
        return (
            <div>
                <div className={classes.content} style={{ padding: 12 }}>
                    <div>
                        {content.editable ? (
                            <TextArea
                                disabled={editDisabled}
                                onChange={(event: any) => {
                                    const value = event.target.value;
                                    this.onChangeSms(value);
                                }}
                                value={content.value}
                                rows={4}
                                placeholder="请输入"
                                maxLength={500}
                                style={{ width: 400 }}
                            ></TextArea>
                        ) : (
                            <div>{content.value}</div>
                        )}
                    </div>
                </div>
                <div className={classes.tip} style={{ marginTop: '10px' }}>
                    <div>
                        {services.language.getText('dxzfs')}
                        <span style={{ color: '#1890ff' }}>{content.value.length}</span>
                        {services.language.getText('cgqsgzfadtfs')}
                    </div>
                    {remark?.map((item: any) => {
                        return <div>{item}</div>;
                    })}
                </div>
            </div>
        );
    };

    onChangeSms = (text: string) => {
        const { template, onChange } = this.props;
        const newTempate = cloneDeep(template);
        const [content] = newTempate.params || [];
        if (content) {
            content.value = text;
            newTempate.params[0] = content;
        }
        onChange(newTempate);
    };

    renderEdit = () => {
        const { template, scene, landingPage, onChange } = this.props;
        if (template.msgChannelType === MsgChannelType.SMS) {
            return null;
        }
        return (
            <MessageNotificationItemEdit
                template={template}
                scene={scene}
                onChange={onChange}
                landingPage={landingPage}
            />
        );
    };

    render() {
        const { template } = this.props;
        if (!template) {
            return null;
        }
        return (
            <div className={prefix}>
                {this.renderHeader()}
                {this.renderTip()}
                {this.renderContent()}
                {this.renderEdit()}
            </div>
        );
    }
}

const prefix = 'message-notification-item';
const classes = {
    header: `${prefix}__header`,
    tip: `${prefix}__tip`,
    content: `${prefix}__content`,
    contentTitle: `${prefix}__content__title`,
    otherItems: `${prefix}__other-items`,
    otherItem: `${prefix}__other-item`,
    otherItemTitle: `${prefix}__other-item__title`,
    otherItemValue: `${prefix}__other-item__value`,
    otherPicItemValue: `${prefix}__other-item__pic-value`,
    wechat: `${prefix}__wechat`,
    emailItems: `${prefix}__email-items`,
    mailItems: `${prefix}__mail-items`,
    richText: `${prefix}__rich-text`,
};
