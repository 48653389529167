import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
export const CgbLeadersLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiPath = '/admin/leaders';
        const subsiteName = data.subsiteName;
        if (subsiteName) {
            data.subsiteName = subsiteName.name ? subsiteName.name : subsiteName;
        }
        return await api.get(data, config);
    },
};
