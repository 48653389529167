import React, { PureComponent } from 'react';
import './index.less';

const categoryNav = './images/editor/categoryNav.png';
class CustomSelectCategoryNav extends PureComponent<any, any> {
    render() {
        return (
            <div className="category-nav">
                <img alt="分组导航" className="category-nav-img" src={categoryNav} />
                <div className="category-nav-text">自动读取后台配置的分类导航数据</div>
            </div>
        );
    }
}

export { CustomSelectCategoryNav };
