import React, { Component } from 'react';
import { map } from 'lodash';
import {
    Row as AntRow,
    Card as AntCard,
    Col as AntCol,
    Table as AntTable,
    Modal,
    Popover,
} from 'antd';
import { api } from '@comall-backend-builder/core/lib/services';
import { Button } from '@comall-backend-builder/components-basis';

import { CouponOrderAppliesSelectorDisplay } from '../../components/coupon-order-applies-selector-display';
import {
    RefundOrderMode,
    RefundOrderStatus,
    REFUND_ORDER_MODE_TO_TEXT,
    REFUND_ORDER_STATUS_TO_TEXT,
} from '../../config/member/member-service/mall-activity/mall-activity-refund-order';
import { services } from '@comall-backend-builder/core';

export interface MallActivityAfterSaleInfoProps {
    params: any;
}

export interface MallActivityAfterSaleInfoState {
    data: any | null;
    previewPictureVisible: boolean;
    previewPictureImage: string;
}

export class MallActivityAfterSaleInfo extends Component<
    MallActivityAfterSaleInfoProps,
    MallActivityAfterSaleInfoState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: null,
            previewPictureVisible: false,
            previewPictureImage: '',
        };
    }

    componentDidMount() {
        const { params } = this.props;
        const id = params.id;
        //获取详细信息
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                apiPath: `/admin/mall_activity_service_applies/${id}`,
            }
        ).then((response: any) => {
            if (response) {
                response.serviceApplyItems = response.serviceApplyItems.map((item: any) => {
                    api.get(
                        {
                            definitionId: item.definitionId,
                        },
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                            apiPath: `/admin/mall_activity_service_applies/${id}/record`,
                        }
                    ).then((coupons: any) => {
                        item.coupons = coupons;
                        this.setState({
                            data: response,
                        });
                    });
                    return item;
                });
            }
        });
    }

    handlePicturePreview = (url: string) => {
        if (url) {
            this.setState({
                previewPictureImage: url,
                previewPictureVisible: true,
            });
        }
    };

    handlePictureCancel = () => this.setState({ previewPictureVisible: false });

    //加载会员信息
    renderMemberInfo = () => {
        const { data } = this.state;
        return (
            <AntCard type="inner" title={services.language.getText('hyxx')}>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('name')}:</AntCol>
                    <AntCol span={5}>{data.member.name}</AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('phoneNum')}:</AntCol>
                    <AntCol span={5}>{data.member.mobile}</AntCol>
                </AntRow>
            </AntCard>
        );
    };

    renderApplyInfo = () => {
        const { data } = this.state;
        return (
            <AntCard type="inner" title={services.language.getText('shsqxx')}>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('orderNumber')}:</AntCol>
                    <AntCol span={5}>{data.orderNo}</AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('sqlx')}:</AntCol>
                    <AntCol span={5}>{services.language.getText('thtk')}</AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('sqyy')}:</AntCol>
                    <AntCol span={5}>{data.reason.reason}</AntCol>
                </AntRow>
            </AntCard>
        );
    };

    renderServiceInfo = () => {
        const { data, previewPictureVisible, previewPictureImage } = this.state;
        return (
            <AntCard type="inner" title={services.language.getText('kfjl')}>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('kfbz')}:</AntCol>
                    <AntCol span={15}>{data.remark}</AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('kfpz')}:</AntCol>
                    <AntCol span={20} style={{ display: 'flex' }}>
                        {map(data.pictures, (picture) => {
                            return (
                                <span key={picture.id}>
                                    <img
                                        style={{ marginRight: '10px' }}
                                        width={104}
                                        height={104}
                                        alt={services.language.getText('khpz')}
                                        src={picture.url}
                                        onClick={this.handlePicturePreview.bind(this, picture.url)}
                                    />
                                </span>
                            );
                        })}
                        <Modal
                            visible={previewPictureVisible}
                            footer={null}
                            onCancel={this.handlePictureCancel}
                        >
                            <img
                                alt="example"
                                style={{ width: '100%' }}
                                src={previewPictureImage}
                            />
                        </Modal>
                    </AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('zrgs')}:</AntCol>
                    <AntCol span={5}>{data.responsibility.name}</AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('auditStatus')}:</AntCol>
                    <AntCol span={5}>
                        {data.status === 'APPLYING' && (
                            <span>{services.language.getText('waiting')}</span>
                        )}
                        {data.status === 'APPLY_SUCC' && (
                            <span>{services.language.getText('approved')}</span>
                        )}
                        {data.status === 'APPLY_FAIL' && (
                            <span>{services.language.getText('shbtg')}</span>
                        )}
                        {data.status === 'CANCEL' && (
                            <span>{services.language.getText('yqx')}</span>
                        )}
                    </AntCol>
                </AntRow>
            </AntCard>
        );
    };

    renderCouponInfo = () => {
        const { data } = this.state;
        const serviceApplyItems = data.serviceApplyItems;
        const tableProps = serviceApplyItems
            ? {
                  loading: false,
                  columns: [
                      {
                          title: services.language.getText('activityName'),
                          key: 'saleRuleName',
                          dataIndex: 'saleRuleName',
                          render: (text: any) => {
                              return <span>{text}</span>;
                          },
                      },
                      {
                          title: services.language.getText('cllx'),
                          key: 'refundMoney',
                          dataIndex: 'refundMoney',
                          render: (text: any) => {
                              let refundMoneyName = text.join(' ');
                              refundMoneyName = refundMoneyName.replace('true', '退款退券');
                              refundMoneyName = refundMoneyName.replace(
                                  'false',
                                  services.language.getText('ztq')
                              );
                              return <span>{refundMoneyName}</span>;
                          },
                      },
                      //   {
                      //       title: '执行现金',
                      //       key: 'executePrice',
                      //       dataIndex: 'executePrice',
                      //       render: (text: any) => {
                      //           return <span>{text}</span>;
                      //       },
                      //   },
                      //   {
                      //       title: '执行积分',
                      //       key: 'pointExecutePrice',
                      //       dataIndex: 'pointExecutePrice',
                      //       render: (text: any) => {
                      //           return <span>{text}</span>;
                      //       },
                      //   },
                      {
                          title: services.language.getText('gmsl'),
                          key: 'originQuantity',
                          dataIndex: 'originQuantity',
                          render: (text: any) => {
                              return <span>{text}</span>;
                          },
                      },
                      {
                          title: services.language.getText('shsl'),
                          key: 'quantity',
                          dataIndex: 'quantity',
                          render: (text: any, row: any) => {
                              const hasCoupons = row.coupons && row.coupons.length > 0;
                              return hasCoupons ? (
                                  <div>
                                      <label>
                                          {row.coupons.length}
                                          <Popover
                                              placement={'right'}
                                              title={services.language.getText('ckyhq')}
                                              content={
                                                  <CouponOrderAppliesSelectorDisplay
                                                      disabled={true}
                                                      orderId={row.orderId}
                                                      id={row.id}
                                                      onChange={() => {}}
                                                      data={row.coupons}
                                                  />
                                              }
                                          >
                                              <span
                                                  style={{
                                                      marginLeft: '10px',
                                                      color: '#1890ff',
                                                  }}
                                              >
                                                  查看
                                              </span>
                                          </Popover>
                                      </label>
                                  </div>
                              ) : null;
                          },
                      },
                      {
                          title: services.language.getText('tkxj'),
                          key: 'refundPrice',
                          dataIndex: 'refundPrice',
                          render: (text: any) => {
                              return <span>{text}</span>;
                          },
                      },
                      {
                          title: services.language.getText('tkjf'),
                          key: 'pointRefundPrice',
                          dataIndex: 'pointRefundPrice',
                          render: (text: any) => {
                              return <span>{text}</span>;
                          },
                      },
                  ],
              }
            : {};
        return serviceApplyItems ? (
            <AntCard type="inner" title={services.language.getText('yhqshxx')}>
                <AntTable dataSource={serviceApplyItems} {...tableProps} />
            </AntCard>
        ) : null;
    };

    renderRefundInfo = () => {
        const { data } = this.state;
        const serviceApplyRefunds = data.serviceApplyRefunds;
        const tableProps = serviceApplyRefunds
            ? {
                  loading: false,
                  columns: [
                      {
                          title: services.language.getText('tkfs'),
                          key: 'refundMode',
                          dataIndex: 'refundMode',
                          render: (text: RefundOrderMode) => {
                              return <span>{REFUND_ORDER_MODE_TO_TEXT[text]}</span>;
                          },
                      },
                      {
                          title: services.language.getText('tkje'),
                          key: 'refundMoney',
                          dataIndex: 'refundMoney',
                          render: (text: any) => {
                              return <span>{text}</span>;
                          },
                      },
                      {
                          title: services.language.getText('tkjf'),
                          key: 'refundPointMoney',
                          dataIndex: 'refundPointMoney',
                          render: (text: any) => {
                              return <span>{text}</span>;
                          },
                      },
                      {
                          title: services.language.getText('zffs'),
                          key: 'paymentModeName',
                          dataIndex: 'paymentModeName',
                          render: (text: any) => {
                              return <span>{text}</span>;
                          },
                      },
                      {
                          title: services.language.getText('clzt'),
                          key: 'refundStatus',
                          dataIndex: 'refundStatus',
                          render: (text: RefundOrderStatus) => {
                              return REFUND_ORDER_STATUS_TO_TEXT[text];
                          },
                      },
                  ],
              }
            : {};
        return serviceApplyRefunds ? (
            <AntCard type="inner" title={services.language.getText('tkclxx')}>
                <AntTable dataSource={serviceApplyRefunds} {...tableProps} />
            </AntCard>
        ) : null;
    };

    render() {
        const { data } = this.state;
        return data ? (
            <div className="coupon-service-applies-edit-form">
                <AntCard>
                    <AntRow style={{ marginBottom: 20 }}>{this.renderMemberInfo()}</AntRow>
                    <AntRow style={{ marginBottom: 20 }}>{this.renderApplyInfo()}</AntRow>
                    <AntRow style={{ marginBottom: 20 }}>{this.renderServiceInfo()}</AntRow>
                    <AntRow style={{ marginBottom: 20 }}>{this.renderCouponInfo()}</AntRow>
                    <AntRow style={{ marginBottom: 20 }}>{this.renderRefundInfo()}</AntRow>
                </AntCard>
                <div style={{ textAlign: 'center', marginTop: '30px' }}>
                    <Button route="goBack">{services.language.getText('back')}</Button>
                </div>
            </div>
        ) : null;
    }
}
