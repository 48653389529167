import React, { Component } from 'react';
import './index.less';

interface tipItem {
    type?: 'string' | 'link';
    text: string;
    onClick?: (props: any) => void;
}

interface Props {
    tips?: tipItem[][];
}

export class StringTips extends Component<Props> {
    renderTipLine = (tipItems: tipItem[]) => {
        return <div className={`${prefix}__line`}>{tipItems.map(this.renderItem)}</div>;
    };

    renderItem = (item: tipItem) => {
        if (item.type === 'link') {
            return (
                <span
                    className={`${prefix}__line__link`}
                    onClick={item.onClick?.bind(this, this.props)}
                >
                    {item.text}
                </span>
            );
        } else {
            return <span className={`${prefix}__line__text`}>{item.text}</span>;
        }
    };

    render() {
        const { tips } = this.props;
        return <div className={prefix}>{tips?.map(this.renderTipLine)}</div>;
    }
}

const prefix = 'string-tips-extra';
