import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { Select, Icon } from 'antd';
import { cloneDeep } from 'lodash';
import { errorHandle } from '../../applications/cae/services/error-handle';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const { Option } = Select;

export class SelectMallActivityTags extends PureComponent<any, any> {
    state = {
        seletcted: [],
        inputValue: '',
        list: [],
        loading: false,
    };

    onSelect = async (val: any) => {
        const { value } = this.props;

        val = val.substring(0, 10);
        if (!value || !value.find((i: any) => i.name === val)) {
            // 没有被选中，再去校验、创建
            this.setState({ loading: true });
            const params = this.getParams();

            try {
                let detailRes: any = await services.api.get(
                    { name: val, type: 'MALL_ACTIVITY' },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        apiPath: '/admin/activity_tags/by_name',
                    }
                );

                if (!detailRes) {
                    // 不存在此标签  就创建
                    services.api
                        .post(
                            {
                                name: val,
                                type: 'MALL_ACTIVITY',
                                ...params,
                            },
                            {
                                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                apiPath: '/admin/activity_tags',
                            }
                        )
                        .then((id) => {
                            this.setValues({ id, name: val });
                        });
                } else {
                    //  存在此标签  就直接选中
                    this.setValues({ id: detailRes.id, name: val });
                }
            } catch (error) {
                errorHandle(error as any);
            }
        }
    };

    setValues = (val: any) => {
        const { id, name } = val;
        const { onChange, value } = this.props;
        if (!value) {
            onChange && onChange([{ id, name }]);
            this.setState({
                seletcted: [name],
                loading: false,
            });
        } else {
            const newValue = [...cloneDeep(value), { id, name }];
            onChange && onChange(newValue);
            this.setState({
                seletcted: newValue.map((i) => i.name),
                loading: false,
            });
        }
    };
    onDeselect = (val: any) => {
        const { value, onChange } = this.props;
        const newValues = [...cloneDeep(value)].filter((i) => i.name !== val);
        onChange && onChange(newValues);
        this.setState({
            seletcted: newValues.map((i) => i.name),
            loading: false,
        });
    };
    onSearch = (v: any) => {
        this.setState({
            inputValue: v,
        });
    };
    onBlur = () => {
        this.setState({
            inputValue: '',
        });
    };

    onFocus = async () => {
        this.setState({ loading: true });
        const params = this.getParams();

        try {
            let res: any = await services.api.get(
                { page: 1, perPage: 100, ...params },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: '/admin/activity_tags',
                }
            );
            this.setState({ list: res.result, loading: false });
        } catch (error) {
            throw error;
        }
    };

    getParams = () => {
        const { getParams } = this.props;

        if (typeof getParams === 'function') {
            return getParams(this.props);
        }

        return {};
    };

    removeTag = (id: any) => {
        const { value, onChange } = this.props;
        const newValues = cloneDeep(value).filter((i: any) => Number(i.id) !== Number(id));
        this.setState({
            seletcted: newValues.map((i: any) => i.name),
        });
        onChange && onChange(newValues);
    };
    render() {
        const { value, style, disabled } = this.props;
        const { list, loading, inputValue, seletcted } = this.state;
        return (
            <div>
                <div className="select-mall-activity-tag">
                    <Select
                        disabled={disabled}
                        style={style}
                        loading={loading}
                        onSearch={this.onSearch}
                        onBlur={this.onBlur}
                        onFocus={this.onFocus}
                        mode="tags"
                        value={seletcted}
                        filterOption={false}
                        placeholder={language.getText('activityTagRequired')}
                        onSelect={this.onSelect}
                        onDeselect={this.onDeselect}
                    >
                        {list.map((i: any, index: number) => (
                            <Option key={i.name}>{i.name}</Option>
                        ))}
                    </Select>
                    {inputValue.length > 10 && (
                        <span style={{ color: '#f5222d', marginLeft: 5 }}>
                            {language.getText('bcg10gzcghbjq')}
                        </span>
                    )}
                </div>
                <div
                    className="mall-activity-tag-selected"
                    style={{ width: style.width ? style.width : '450px' }}
                >
                    {!!(value && value.length) &&
                        value.map((i: any) => {
                            return (
                                <div className="tags">
                                    <span>{i.name}</span>
                                    <span
                                        className="close"
                                        onClick={() => {
                                            this.removeTag(i.id);
                                        }}
                                    >
                                        <Icon type="close" />
                                    </span>
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    }
}
