import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { api } from '@comall-backend-builder/core/lib/services';
import { isPlainObject } from 'lodash';

export const PaymentRemittanceOrderLoader: Loader = {
    get(params, config) {
        if (!params.id) {
            const { payTime, remittanceTime, remittanceStatus } = params;
            if (remittanceTime) {
                params.applyRemittanceStartTime = remittanceTime.start + ' 00:00:00';
                params.applyRemittanceEndTime = remittanceTime.end + ' 23:59:59';
                delete params.remittanceTime;
            }
            if (payTime) {
                params.orderPaidStartTime = payTime.start + ' 00:00:00';
                params.orderPaidEndTime = payTime.end + ' 23:59:59';
                delete params.payTime;
            }
            if (!remittanceStatus) {
                params.remittanceStatus = params.status;
            }
            delete params.status;
        }
        return api.get(params, config);
    },
};

export const PaymentRemittanceOrderExportLoader: Loader = {
    get(params, config) {
        if (!params.id) {
            const { payTime, remittanceTime, subsiteIds, remittanceStatus } = params;
            if (remittanceTime) {
                params.applyRemittanceStartTime = remittanceTime.start;
                params.applyRemittanceEndTime = remittanceTime.end;
                delete params.remittanceTime;
            }
            if (payTime) {
                params.orderPaidStartTime = payTime.start;
                params.orderPaidEndTime = payTime.end;
                delete params.payTime;
            }
            if (isPlainObject(subsiteIds)) {
                params.subsiteIds = subsiteIds.id;
            }
            if (!remittanceStatus) {
                params.remittanceStatus = params.status;
            }
            delete params.status;
        }
        return api.post(params, config);
    },
};
