import React, { PureComponent } from 'react';
import { forEach, cloneDeep, findIndex, map } from 'lodash';
import { GoodsSelectorModal } from '../../../../components/goods-selector-modal';
import { ProductGoodsSelectDisplay } from './product-goods-select-display';
import { services } from '@comall-backend-builder/core';

/**
 * 选择货品
 */
export class SelectProductGoods extends PureComponent<{
    value: any[];
    onChange: (v: any) => void;
    row: any;
    type: 'checkbox' | 'radio';
    disabled: boolean;
    filterGoodsParams?: any;
    pageType?: string;
}> {
    static defaultProps = {
        disabled: false,
    };
    state = {
        value: [],
    };
    getSubsiteIds = (props: any) => {
        const { row } = props;
        let subsites = row.subsites;
        if (row.baseInfo && row.baseInfo.subsites) {
            subsites = row.baseInfo.subsites;
        }
        const subsiteIds = subsites ? map(subsites, 'id') : null;
        return subsiteIds;
    };
    getMerchantIds = (props: any) => {
        const { row } = props;
        let merchants = row.merchants;
        if (row.baseInfo && row.baseInfo.merchants) {
            merchants = row.baseInfo.merchants;
        }
        const merchantIds = merchants
            ? map(merchants, (merchant) => {
                  //部分组件id为"门店id,专柜id"组合，需要截取获得专柜id
                  let merchantId = merchant.id.split(',')[1];
                  //如果id不为组合时，直接取专柜id
                  if (!merchantId) {
                      merchantId = merchant.merchantId;
                  }
                  if (!merchantId) {
                      merchantId = merchant.id;
                  }
                  return merchantId;
              })
            : null;
        return merchantIds;
    };

    getMerchantSubsiteIds = (props: any) => {
        const { row } = props;
        let merchants = row.merchants;
        if (row.baseInfo && row.baseInfo.merchants) {
            merchants = row.baseInfo.merchants;
        }
        const subsite = merchants
            ? map(merchants, (merchant) => {
                  let subsiteId = merchant.subsiteId || merchant.subSiteId || '';
                  //部分组件id为"门店id,专柜id"组合，需要截取获得门店id
                  let merchantId = merchant.id.split(',')[1];
                  if (merchantId) {
                      subsiteId = merchant.id.split(',')[0];
                  }
                  return subsiteId;
              }).filter((s) => s !== '')
            : null;
        const subsiteIds = Array.from(new Set(subsite));
        return subsiteIds;
    };
    getSelectMode = (props: any) => {
        const { row } = props;
        let selectMode = '';
        if (row.baseInfo && row.baseInfo.selectMode) {
            selectMode = row.baseInfo.selectMode;
        }
        return selectMode;
    };
    componentWillReceiveProps(nextProps: any) {
        const { pageType } = this.props;
        if (pageType && pageType === 'COMBINE-GOODS') {
            const nextSelectMode = this.getSelectMode(nextProps);
            const currentSelectMode = this.getSelectMode(this.props);
            // 如果选择模式没有变化，并且当前模式是专柜或者标签模式，检查是否有变化
            if (nextSelectMode === currentSelectMode) {
                if (nextSelectMode === 'MERCHANT') {
                    let nextMerchantIds: any = this.getMerchantIds(nextProps);
                    let currentMerchantIds: any = this.getMerchantIds(this.props);
                    if (nextMerchantIds) {
                        nextMerchantIds = nextMerchantIds.join(',');
                    }
                    if (currentMerchantIds) {
                        currentMerchantIds = currentMerchantIds.join(',');
                    }
                    if (nextMerchantIds !== currentMerchantIds) {
                        this.processDataAfterMerchantChange(nextMerchantIds, nextProps);
                    }
                } else {
                    let nextSubsiteIds: any = this.getSubsiteIds(nextProps);
                    let currentSubsiteIds: any = this.getSubsiteIds(this.props);
                    if (nextSubsiteIds) {
                        nextSubsiteIds = nextSubsiteIds.join(',');
                    }
                    if (currentSubsiteIds) {
                        currentSubsiteIds = currentSubsiteIds.join(',');
                    }
                    if (nextSubsiteIds !== currentSubsiteIds) {
                        this.processDataAfterSubsiteChange(nextSubsiteIds, nextProps);
                    }
                }
            } else {
                if (!!currentSelectMode) {
                    // 如果选择模式发生变化，清空选中数据
                    this.onChange([]);
                }
            }
        }
    }

    processDataAfterMerchantChange = (merchantIds: string[], props: any) => {
        const { value } = props;
        const newData: any[] = [];
        if (value && merchantIds) {
            forEach(value, (i) => {
                if (
                    merchantIds.indexOf(i.merchantId + '') > -1 ||
                    merchantIds.indexOf(i.merchantId) > -1
                ) {
                    newData.push(i);
                }
            });
        }
        this.onChange(newData);
    };

    processDataAfterSubsiteChange = (subsiteIds: string[], props: any) => {
        const { value } = props;
        const newData: any[] = [];
        if (value && subsiteIds) {
            forEach(value, (i) => {
                if (
                    subsiteIds.indexOf(i.subsiteId + '') > -1 ||
                    subsiteIds.indexOf(i.subsiteId) > -1
                ) {
                    newData.push(i);
                }
            });
        }
        this.onChange(newData);
    };

    processSelectedData = (data: any[]) => {
        let items: any[] = [];
        if (data && data.length) {
            forEach(data, (good) => {
                items.push({
                    id: good.id,
                    name: good.name,
                    salesPrice: (+good.goodsMvo.originPrice.value).toFixed(2),
                    stock: good.goodsMvo.stock,
                    productId: good.goodsMvo.productId,
                    productStyleName: good.goodsMvo.productStyleName,
                    merchantId: good.goodsMvo.merchantId,
                    subsiteId: good.goodsMvo.subsiteId,
                    code: good.goodsMvo.spuCode,
                    marketPrice: (+good.goodsMvo.marketPrice.value).toFixed(2),
                });
            });
        }
        return items;
    };

    onChange = (goods: any[]) => {
        const { onChange } = this.props;
        onChange(goods);
    };

    onSelect = (values: any[]) => {
        const data = this.processSelectedData(values);
        let newData: any[] = [];
        const { type } = this.props;
        if (type === 'radio') {
            newData = data;
        } else {
            const { value } = this.props;
            newData = cloneDeep(value || []);
            data.forEach((i: any) => {
                const hasIndex = findIndex(value, (o: any) => o.id === i.id);
                if (hasIndex === -1) {
                    newData.unshift(i);
                }
            });
        }
        this.onChange(newData);
    };

    render() {
        const { value, disabled, type, filterGoodsParams, pageType } = this.props;
        const hasValue = value && value.length > 0;
        const newParams = { ...filterGoodsParams };
        const selectMode = this.getSelectMode(this.props);
        switch (selectMode) {
            case 'SUB_SITE':
                newParams.subsiteIds = this.getSubsiteIds(this.props);
                if (pageType && pageType === 'COMBINE-GOODS') {
                    newParams.merchantIds = 0;
                }
                break;
            case 'MERCHANT':
                newParams.merchantIds = this.getMerchantIds(this.props);
                newParams.subsiteIds = this.getMerchantSubsiteIds(this.props);
                break;
        }
        return (
            <div className="select-product-goods">
                {!disabled && (
                    <GoodsSelectorModal
                        modalTitle={services.language.getText('select')}
                        buttonText={services.language.getText('xzsp_1')}
                        params={newParams}
                        rowSelectionType={type}
                        onChange={this.onSelect}
                        needSubsites={true}
                    />
                )}
                {hasValue && (
                    <ProductGoodsSelectDisplay
                        modifiable={!disabled}
                        data={value}
                        onChange={this.onChange}
                        pageType={pageType}
                    />
                )}
            </div>
        );
    }
}
