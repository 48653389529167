import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { CitywideDeliveryFreightLadder } from '../../../components';
import { services } from '@comall-backend-builder/core';

export class CitywideDeliveryFreightLadderMode extends ObjectMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(object: any, displayInfo: any) {
        return (
            object && (
                <div>
                    <span>
                        {' '}
                        {object.ladderValue} kg，{services.language.getText('fyzj')}
                        {object.ladderAmount} {services.language.getText('yuan')}
                    </span>
                </div>
            )
        );
    }

    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <CitywideDeliveryFreightLadder {...controlInfo} />;
    }
}
