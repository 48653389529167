import React, { Component } from 'react';
import { Input, Select } from 'antd';
import { find } from 'lodash';
import { api } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';
import './index.less';

interface Param {
    field: string;
    name: string;
    value: string;
    description: string;
    required: boolean;
}
interface InvoiceSystemVo {
    id: string;
    name: string;
    params: Array<Param> | null;
}
interface InvoiceSystemStates {
    systems: Array<InvoiceSystemVo>;
}

interface InvoiceSystemProps {
    onChange: (value: InvoiceSystemVo) => void;
    value: InvoiceSystemVo;
    row: any;
    searchParams: object;
}

export class InvoiceSystem extends Component<InvoiceSystemProps, InvoiceSystemStates> {
    constructor(props: InvoiceSystemProps) {
        super(props);
        this.state = {
            systems: [],
        };
    }
    componentDidMount() {
        this.loadSystems();
        this.setDefalutValue();
    }
    loadSystems = () => {
        const { searchParams } = this.props;
        api.get(
            { ...searchParams },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
                apiPath: `/admin/invoice_third_systems`,
            }
        ).then((response: any) => {
            this.setState(
                {
                    systems: response,
                },
                () => {
                    this.setDefalutValue();
                }
            );
        });
    };

    setDefalutValue = () => {
        const { value, onChange } = this.props;
        const { systems } = this.state;
        if (!value && systems) {
            const defaultValue = systems[0];
            onChange && onChange(defaultValue);
        } else if (!value) {
            const defaultValue: InvoiceSystemVo = {
                id: '',
                name: '',
                params: null,
            };
            onChange && onChange(defaultValue);
        }
    };

    handleCurrentChange = (value: any) => {
        const { onChange } = this.props;
        const { systems } = this.state;
        const currentSystems = find(systems, {
            id: value,
        });
        if (currentSystems) {
            onChange(currentSystems);
        }
    };

    onConditionValueChange = (event: any, field: string) => {
        const { value, onChange } = this.props;
        let inputValue = event.target.value;
        if (value) {
            let changeParamValue = find(value.params, (i) => field === i.field);
            if (changeParamValue) {
                changeParamValue.value = inputValue;
                onChange && onChange(value);
            }
        }
    };

    render() {
        const { value } = this.props;
        const { systems } = this.state;
        return (
            <div className="invoice-system">
                <Select
                    value={value ? value.id : undefined}
                    onSelect={this.handleCurrentChange}
                    className="select"
                    placeholder={services.language.getText('selectPlease')}
                >
                    {systems &&
                        systems.map((option) => {
                            return (
                                <Select.Option key={option.id} value={option.id}>
                                    {option.name}
                                </Select.Option>
                            );
                        })}
                </Select>
                {value &&
                    value.params &&
                    value.params.map((param: Param) => {
                        return (
                            <div className="param-dev" key={param.field}>
                                {param.required && <span className="red-tip">*</span>}
                                {param.name}:
                                <Input
                                    value={param.value}
                                    className="param-input"
                                    placeholder={param.description}
                                    onChange={(e) => this.onConditionValueChange(e, param.field)}
                                />
                            </div>
                        );
                    })}
            </div>
        );
    }
}
