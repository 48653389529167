import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { PointValidRule } from './point-valid-rule';
import './index.less';
/**
 *  积分有效期规则
 */
export class PointValidRuleMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <PointValidRule {...controlInfo} />;
    }
}
