import React, { PureComponent } from 'react';
import { ReceiptInfo, GoodsInfo, OrderItemType, OrderType } from '../../../services/order-print';
import { formatPointAmount } from '../../../types/format/utils';
import { services } from '@comall-backend-builder/core';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const formatQuantity = (quantity: number) => {
    const quantities = quantity.toString().split('.');
    if (quantities.length < 2) {
        return quantity;
    } else {
        return quantities[0] + '.' + quantities[1].slice(0, 3);
    }
};

const row = {
    display: 'flex',
    height: '30px',
};
const col = {
    width: '33%',
};

const footerItem = {
    flex: '1',
    height: '30px',
    lineHeight: '30px',
};

const border = { border: '1px solid #999' };

const getWeightQuantity = (quantity: number, displayUnit: 'G' | 'KG' | 'PIECE') => {
    const value = displayUnit === 'KG' ? formatQuantity(Number(quantity) / 1000) : quantity;
    const unit = displayUnit === 'PIECE' ? '' : displayUnit === 'KG' ? 'kg' : 'g';
    return value + '' + unit;
};

export class PickingReceiptTemplate extends PureComponent<
    {
        order: ReceiptInfo;
    },
    any
> {
    renderOrderInfo = (order: ReceiptInfo) => {
        let goodsAmount = order.paymentInfo.goodsAmount;
        const payablePoint = order.paymentInfo.payablePoint;
        goodsAmount =
            payablePoint && order.orderType === OrderType.CreditEshop
                ? `${formatPointAmount(payablePoint)}${language.getText(
                      'point'
                  )}${goodsAmount}${language.getText('yuan')}`
                : `${goodsAmount}${language.getText('yuan')}`;
        return (
            <div style={{ padding: '0, 10px', fontSize: '16px' }}>
                <div style={row}>
                    <div style={col}>
                        {services.language.getText('ddh_1')}：{order.orderNo}
                    </div>
                    <div style={col}>
                        {services.language.getText('xdsj')}：{order.createTime}
                    </div>
                    <div style={col}>
                        {services.language.getText('glmd')}：{order.subsite.name}
                    </div>
                </div>
                <div style={row}>
                    <div style={col}>
                        {services.language.getText('shr')}：{order.deliveryInfo.receiverName}
                    </div>
                    <div style={col}>
                        {services.language.getText('shrsjh')}：{order.deliveryInfo.mobile}
                    </div>
                    <div style={col}>
                        {services.language.getText('deliveryType')}：
                        {order.deliveryInfo.deliveryModeName}
                    </div>
                </div>
                <div style={row}>
                    <div style={col}>
                        {services.language.getText('yfje_1')}：{order.paymentInfo.payableAmount}
                        {services.language.getText('yuan')}
                    </div>
                    <div style={col}>
                        {services.language.getText('spze')}：{goodsAmount}
                    </div>
                    <div style={col}>
                        {services.language.getText('cxyh')}：
                        {order.paymentInfo.promotionDiscountAmount}
                        {language.getText('yuan')}
                    </div>
                </div>
                <div style={row}>
                    <div style={col}>
                        {services.language.getText('hykzk')}：
                        {order.paymentInfo.memberCardDiscountAmount}
                        {language.getText('yuan')}
                    </div>
                    <div style={col}>
                        {services.language.getText('tenantDeliverMoney')}：
                        {order.paymentInfo.freightAmount}
                        {language.getText('yuan')}&nbsp;&nbsp;( 运费优惠
                        {order.paymentInfo.freightFeeDiscount}
                        {language.getText('yuan')})
                    </div>
                    <div style={col}>
                        {services.language.getText('yhqzk')}：{order.paymentInfo.couponAmount}
                        {language.getText('yuan')}
                    </div>
                </div>
                <div style={row}>
                    <div style={col}>
                        {services.language.getText('zfyh')}：
                        {order.paymentInfo.totalPaymentRecordDiscountAmount}
                        {language.getText('yuan')}
                    </div>
                    <div style={col}>
                        {services.language.getText('yfje_2')}：{order.paymentInfo.paidAmount}
                        {language.getText('yuan')}
                    </div>
                    <div style={col}>
                        {services.language.getText('shdz')}：{order.deliveryInfo.address}
                    </div>
                </div>
                <div style={row}>
                    {order.deliveryInfo.deliveryMode === 'SELF_MENTION' && (
                        <div style={col}>
                            {services.language.getText('ztm')}：
                            {order.deliveryInfo.selfExtractingCode || ''}
                        </div>
                    )}
                    {order.deliveryInfo.deliveryMode === 'CITYWIDE_DELIVERY' && (
                        <div style={col}>
                            {services.language.getText('yjsdsj')}：
                            {order.deliveryInfo.expectedDeliveryTime || ''}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    renderGoodsTable = (goods: GoodsInfo[], isGift?: boolean) => {
        return (
            <div style={{ marginTop: '10px', fontSize: '13px' }}>
                <div style={{ fontWeight: 'bolder', fontSize: '16px', marginBottom: '5px' }}>
                    {isGift
                        ? services.language.getText('zpxx')
                        : services.language.getText('productInformation')}
                </div>
                <table
                    cellSpacing={0}
                    cellPadding={5}
                    style={{
                        border: '1px solid #999',
                        borderCollapse: 'collapse',
                        textAlign: 'center',
                        width: '100%',
                    }}
                >
                    <tbody>
                        <tr>
                            <th style={{ width: '8%', border: '1px solid #999' }}>
                                {services.language.getText('xlh')}
                            </th>
                            <th style={border}>{services.language.getText('productCode')}</th>
                            <th style={border}>{services.language.getText('goodBarCode')}</th>
                            <th style={border}>{services.language.getText('spmc')}</th>
                            <th style={border}>{services.language.getText('number')}</th>
                            <th style={border}>{services.language.getText('specifications')}</th>
                            <th style={border}>{services.language.getText('price')}</th>
                            <th style={border}>{services.language.getText('xj_2')}</th>
                        </tr>
                        {goods.map((item, index) => {
                            let price = item.executePrice;
                            const hasPrice = !!Number(item.executePrice);
                            const hasPoint = !!Number(item.executePointPrice);
                            if (hasPrice && hasPoint) {
                                price =
                                    item.executePointPrice +
                                    language.getText('point') +
                                    '+' +
                                    item.executePrice;
                            } else if (hasPoint) {
                                price = item.executePointPrice + language.getText('point');
                            }
                            return (
                                <tr style={{ textAlign: 'left' }} key={index}>
                                    <td style={{ textAlign: 'center', border: '1px solid #999' }}>
                                        {index + 1}
                                    </td>
                                    <td style={border}>{item.productCode}</td>
                                    <td style={border}>{item.barcode || ''}</td>
                                    <td style={border}>{item.productName}</td>
                                    <td style={border}>{item.quantity}</td>
                                    <td style={border}>{item.styleName}</td>
                                    <td style={border}>{price}</td>
                                    <td style={border}>{item.totalAmountStr}</td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    renderWeightGoodsTable = (goods: GoodsInfo[]) => {
        return (
            <div style={{ marginTop: '10px', fontSize: '16px' }}>
                <div style={{ fontWeight: 'bolder', fontSize: '13px', marginBottom: '5px' }}>
                    {language.getText('czpxx')}
                </div>
                <table
                    cellSpacing={0}
                    cellPadding={5}
                    style={{
                        border: '1px solid #999',
                        borderCollapse: 'collapse',
                        textAlign: 'center',
                        width: '100%',
                    }}
                >
                    <tbody>
                        <tr>
                            <th style={{ width: '8%', border: '1px solid #999' }}>
                                {services.language.getText('xlh')}
                            </th>
                            <th style={border}>{services.language.getText('productCode')}</th>
                            <th style={border}>{services.language.getText('goodBarCode')}</th>
                            <th style={border}>{services.language.getText('spmc')}</th>
                            <th style={border}>{services.language.getText('price')}</th>
                            <th style={border}>{services.language.getText('xdzl')}</th>
                            <th style={border}>{services.language.getText('xdje')}</th>
                            <th style={border}>{services.language.getText('sz_3')}</th>
                            <th style={border}>{services.language.getText('cz_2')}</th>
                            <th style={border}>{services.language.getText('cj_1')}</th>
                            <th style={border}>{services.language.getText('xj_2')}</th>
                        </tr>
                        {goods.map((item, index) => {
                            return (
                                <tr style={{ textAlign: 'left' }} key={index}>
                                    <td style={{ textAlign: 'center', border: '1px solid #999' }}>
                                        {index + 1}
                                    </td>
                                    <td style={border}>{item.productCode}</td>
                                    <td style={border}>{item.barcode || ''}</td>
                                    <td style={border}>{item.productName}</td>
                                    <td style={border}>{item.executePrice}/kg</td>
                                    <td style={border}>
                                        {getWeightQuantity(item.needPickQuantity, item.displayUnit)}
                                    </td>
                                    <td style={border}>{item.totalAmountStr}</td>
                                    <td style={border}>
                                        {getWeightQuantity(
                                            item.actualPickQuantity,
                                            item.displayUnit
                                        )}
                                    </td>
                                    <td style={border}>
                                        {getWeightQuantity(
                                            item.quantityDifference,
                                            item.displayUnit
                                        )}
                                    </td>
                                    <td style={border}>-{item.priceDifference}</td>
                                    <td style={border}>
                                        {item.weightProductActualAfterFoldingPrice}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        );
    };

    render() {
        const { order } = this.props;
        const giftGoods = order.goodsList.filter(
            (goods) => goods.orderItemType === OrderItemType.Gift
        );
        const normalGoods = order.goodsList.filter(
            (goods) => goods.orderItemType !== OrderItemType.Gift && goods.productType === 'NORMAL'
        );
        const weightGoods = order.goodsList.filter(
            (goods) => goods.orderItemType !== OrderItemType.Gift && goods.productType === 'WEIGHT'
        );
        return (
            <div className="print-paper">
                <div
                    style={{
                        textAlign: 'center',
                        marginTop: '30px',
                        fontSize: '32px',
                        fontWeight: 'bold',
                        lineHeight: '36px',
                    }}
                >
                    {language.getText('fhd')}
                </div>

                <img
                    src={order.orderNoCode}
                    style={{
                        width: '300px',
                        height: '130px',
                        marginTop: '-43px',
                        float: 'right',
                    }}
                    alt=""
                />
                <div style={{ clear: 'both' }}></div>
                {this.renderOrderInfo(order)}
                {normalGoods && normalGoods.length > 0 && this.renderGoodsTable(normalGoods)}
                {weightGoods && weightGoods.length > 0 && this.renderWeightGoodsTable(weightGoods)}
                {giftGoods && giftGoods.length > 0 && this.renderGoodsTable(giftGoods, true)}
                <div
                    style={{
                        lineHeight: '24px',
                        fontSize: '16px',
                        margin: '30px 0',
                    }}
                >
                    <div>
                        {language.getText('hysjh')}：{order.mobile}
                    </div>
                    <div>
                        {language.getText('hydj')}：{order.memberCardLevelName}
                    </div>
                    <div>
                        {language.getText('khbz')}：{order.remark}
                    </div>
                </div>
                <div style={row}>
                    <div style={footerItem}>{language.getText('fjy')}:</div>
                    <div style={footerItem}>{language.getText('fsy')}:</div>
                    <div style={footerItem}>{language.getText('kfy')}:</div>
                    <div style={footerItem}>{language.getText('kdy')}:</div>
                </div>
                <div style={row}>
                    <div style={footerItem}>{services.language.getText('kddh')}:</div>
                    <div style={footerItem}>{language.getText('hpzl')}:</div>
                    <div style={footerItem}>{language.getText('ztm')}:</div>
                </div>
                {!!order.printInfo.remark && (
                    <div style={{ marginTop: '20px' }}>【{order.printInfo.remark}】</div>
                )}
                <div style={{ pageBreakAfter: 'always' }} />
            </div>
        );
    }
}
