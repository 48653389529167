import React, { ReactNode, useRef, useState } from 'react';
import { message, Modal, Radio, Input, Button, Tooltip, Spin } from 'antd';
import { api } from '@comall-backend-builder/core/lib/services';
import {
    LiveRoomProduct,
    AUDIT_STATUS,
    PRICE_TYPE,
    validate,
    LiveRoomProductForSubmit,
    formatPrice,
} from '../../config/promotion/market/live-room-product';
import './index.less';
import { services } from '@comall-backend-builder/core';

const { confirm: modalConfirm } = Modal;

/**
 * 直播间商品--操作列
 */
export const LiveRoomProductWarehousingTableOperations = (props: {
    row: LiveRoomProduct;
    [key: string]: any;
}) => {
    const ref = useRef<{ getProductData: any; getPriceData: any }>({
        getProductData: null,
        getPriceData: null,
    });
    const { row, entity } = props;
    const { auditStatus } = row;
    const submit = async () => {
        if (await confirm(services.language.getText('qrtjsh'))) {
            try {
                await api.post(
                    {},
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                        apiPath: `/admin/live_stream/${row.id}/audit`,
                    }
                );
            } catch (e) {
                if (e instanceof Error) {
                    message.error(e.message);
                } else {
                    message.error(JSON.stringify(e));
                }
            } finally {
                entity.search();
            }
        }
    };
    const copyLink = copy(row.pageUrl);
    const remove = async () => {
        if (await confirm(services.language.getText('qrscm'))) {
            const apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
            const apiPath = `/admin/live_stream/${row.id}/delete`;
            try {
                await api.put({}, { apiRoot, apiPath });
            } catch (e) {
                if (e instanceof Error) {
                    message.error(e.message);
                } else {
                    message.error(JSON.stringify(e));
                }
            } finally {
                entity.search();
            }
        }
    };
    const edit = async () => {
        const data = await ref.current.getProductData();
        const apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        const apiPath = `/admin/live_stream/${row.id}`;
        try {
            await api.put(data, { apiRoot, apiPath }).catch((e) => {
                throw new Error(e.response.body.message);
            });
            message.success(services.language.getText('common.modifySuccess'));
        } catch (e) {
            message.error(e instanceof Error ? e.message : JSON.stringify(e));
        } finally {
            entity.search();
        }
    };
    const editPrice = async () => {
        const data = await ref.current.getPriceData();
        const apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        const apiPath = `/admin/live_stream/${row.id}/price`;
        try {
            await api.put(data, { apiRoot, apiPath }).catch((e) => {
                throw new Error(e.response.body.message);
            });
            message.success(services.language.getText('common.modifysuccess'));
        } catch (e) {
            message.error(e instanceof Error ? e.message : JSON.stringify(e));
        } finally {
            entity.search();
        }
    };
    return (
        <>
            <div className="live-room-list-operation">
                <div
                    className="live-room-list-operation__link"
                    hidden={AUDIT_STATUS.未审核 !== auditStatus}
                    onClick={submit}
                >
                    {services.language.getText('tjsh')}
                </div>
                <div
                    className="live-room-list-operation__link"
                    hidden={[AUDIT_STATUS.审核中, AUDIT_STATUS.已入库].includes(auditStatus)}
                    onClick={edit}
                >
                    {services.language.getText('common.edit')}
                </div>
                <div
                    className="live-room-list-operation__link"
                    hidden={AUDIT_STATUS.已入库 !== auditStatus}
                >
                    <MiniprogramCode product={row} />
                </div>
                <div
                    className="live-room-list-operation__link"
                    hidden={AUDIT_STATUS.已入库 !== auditStatus}
                    onClick={copyLink}
                >
                    {services.language.getText('copyLink')}
                </div>
                <div
                    className="live-room-list-operation__link"
                    hidden={AUDIT_STATUS.已入库 !== auditStatus}
                    onClick={editPrice}
                >
                    {services.language.getText('xgjg')}
                </div>
                <div
                    className="live-room-list-operation__link"
                    hidden={AUDIT_STATUS.审核中 === auditStatus}
                    onClick={remove}
                >
                    {services.language.getText('common.delete')}
                </div>
            </div>
            <EditProductModal
                getRef={(getter) => (ref.current.getProductData = getter)}
                row={row}
            />
            <EditPriceModal getRef={(getter) => (ref.current.getPriceData = getter)} row={row} />
        </>
    );
};

type EditModalProps = {
    getRef: (getter: () => Promise<any>) => void;
    row: LiveRoomProductForSubmit;
};

const EditProductModal = (props: EditModalProps) => {
    return (
        <EntityModal
            {...props}
            getData={({ name, price, price2, priceType }) => ({ name, price, price2, priceType })}
            title={services.language.getText('editProduct')}
            hasNameProperty={true}
        />
    );
};
const EditPriceModal = (props: EditModalProps) => {
    return (
        <EntityModal
            {...props}
            getData={({ price, price2, priceType }) => ({ price, price2, priceType })}
            title={services.language.getText('xgjg')}
            hasNameProperty={false}
        />
    );
};

interface ProductModal {
    getRef: (getter: () => Promise<any>) => void;
    row: LiveRoomProductForSubmit;
    getData: (row: LiveRoomProductForSubmit) => LiveRoomProductForSubmit;
    title: string;
    hasNameProperty: boolean;
}

const EntityModal = <T,>(props: ProductModal) => {
    const [opened, setOpened] = useState(false);
    const [modalKey, setModalKey] = useState(Date.now());
    const openModal = () => {
        setOpened(true);
        setModalKey(Date.now());
    };
    const { row: originRow, getRef, getData, title, hasNameProperty } = props;
    const row = transPrice({ ...originRow });
    const dataRef = useRef(row);
    const data = dataRef.current;
    const resolveRef = useRef<any>();
    const refresh = ((setTime) => () => setTime(Date.now()))(useState(Date.now())[1]);
    getRef(() => {
        const [resolve, promise] = resolvePromise();
        resolveRef.current = resolve;
        openModal();
        return promise;
    });
    const onOk = () => {
        const product = transPrice(getData(data));
        try {
            validate(product);
            resolveRef.current(product);
            setOpened(false);
        } catch (e) {
            if (e instanceof Error) message.error(e.message);
        }
    };
    const onCancel = () => {
        dataRef.current = row;
        setOpened(false);
    };
    const createInputDom = (key: 'price' | 'price2') => {
        return (
            <Input
                key={key}
                type="number"
                defaultValue={`${typeof data[key] === 'number' ? data[key] || '' : ''}`}
                onChange={(e) => {
                    try {
                        data[key] = formatPrice(e.target.value);
                    } catch (e) {}
                }}
            />
        );
    };
    const priceDom = createInputDom('price');
    const price2Dom = createInputDom('price2');
    return (
        <Modal
            key={modalKey}
            visible={opened}
            title={title}
            footer={
                <>
                    <Button type="primary" onClick={onOk}>
                        {services.language.getText('tjsh')}
                    </Button>
                    <Button type="default" onClick={onCancel}>
                        {services.language.getText('common.cancel')}
                    </Button>
                </>
            }
        >
            <div className="live-room-product-modal">
                {hasNameProperty ? (
                    <>
                        <div className="live-room-product-modal__label">
                            {services.language.getText('spmc')}:
                        </div>
                        <div className="live-room-product-modal__value">
                            <Input
                                defaultValue={data.name?.substring(0, 14)}
                                onChange={(e) => {
                                    data.name = e.target.value;
                                }}
                                maxLength={14}
                            />
                        </div>
                    </>
                ) : null}

                <div className="live-room-product-modal__label">
                    {services.language.getText('jglx')}:
                </div>
                <div className="live-room-product-modal__value">
                    <Radio.Group
                        defaultValue={data.priceType}
                        onChange={(e) => {
                            data.priceType = e.target.value;
                            data.price = 0;
                            data.price2 = 0;
                            refresh();
                        }}
                    >
                        {Object.keys(PRICE_TYPE).map((key) => {
                            return (
                                <Radio value={(PRICE_TYPE as any)[key]} key={key}>
                                    {key}
                                </Radio>
                            );
                        })}
                    </Radio.Group>
                </div>
                <div className="live-room-product-modal__label">
                    {services.language.getText('price')}:
                </div>
                <div className="live-room-product-modal__value">
                    {data.priceType === PRICE_TYPE.一口价 ? (
                        <>
                            {priceDom}
                            {services.language.getText('yuan')}
                        </>
                    ) : null}
                    {data.priceType === PRICE_TYPE.价格区间 ? (
                        <>
                            {priceDom}
                            {services.language.getText('yuan')}-{price2Dom}
                            {services.language.getText('yuan')}
                        </>
                    ) : null}
                    {data.priceType === PRICE_TYPE.折扣价 ? (
                        <>
                            {services.language.getText('marketPrice')}
                            {priceDom}
                            {services.language.getText('newPrice')}
                            {price2Dom}
                            {services.language.getText('yuan')}
                        </>
                    ) : null}
                </div>
            </div>
        </Modal>
    );
};

const resolvePromise = function<T = any>(): [typeof resolve, typeof promise] {
    let resolve: (data: T) => void = () => {};
    const promise = new Promise<T>((r) => {
        resolve = r;
    });
    return [resolve, promise];
};

const confirm = async (content: string) => {
    const result = await new Promise<boolean>((resolve) => {
        modalConfirm({
            content,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: () => resolve(true),
            onCancel: () => resolve(false),
        });
    });
    return result;
};

const loadImage = async (row: LiveRoomProduct) => {
    const { page, scene } = row;
    const type = page.split('/').slice(-2)[0];
    const apiRoot = `${ENV.API_ROOT}`;
    const apiPath = '/wechat/jhcode';
    const result = await api.get<any>({ scene, page, type }, { apiRoot, apiPath });
    let errmsg;
    try {
        errmsg = JSON.parse(Buffer.from(result.image, 'base64').toString()).errmsg;
    } catch (e) {
        return `data:image/jpg;base64,${result.image}`;
    }
    throw new Error(errmsg);
};

const MiniprogramCode = (props: { product: LiveRoomProduct }) => {
    const [image, setImage] = useState('');
    const [errmsg, setErrmsg] = useState('');
    const ref = useRef(false);
    const { product } = props;
    let tooltipContent: ReactNode;
    if (errmsg) {
        tooltipContent = <span>{services.language.getText('hqxcxmsb')}</span>;
    } else if (image) {
        tooltipContent = <img src={image} style={{ width: `150px` }} alt="" />;
    } else {
        tooltipContent = <Spin />;
    }
    return (
        <Tooltip
            title={<div className="live-room-list-operation__tooltip">{tooltipContent}</div>}
            onVisibleChange={async (visible) => {
                if (visible && ref.current === false && (ref.current = true)) {
                    try {
                        const img = await loadImage(product);
                        setImage(img);
                    } catch (e) {
                        if (e instanceof Error) setErrmsg(e.message);
                    }
                }
            }}
        >
            {services.language.getText('spyl')}
        </Tooltip>
    );
};

const transPrice = (row: LiveRoomProductForSubmit) => {
    if (row.price) {
        row.price = formatPrice(row.price);
    }
    if (row.price2) {
        row.price2 = formatPrice(row.price2);
    }
    return row;
};

const copy = (url: any) => {
    return function() {
        let input = document.createElement('input');
        input.value = url;
        document.body.appendChild(input);
        input.select();
        if (document.execCommand('Copy')) {
            message.success(services.language.getText('copySuccess'));
        } else {
            message.warning(services.language.getText('copyError'));
        }
        document.body.removeChild(input);
    };
};
