import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { Form, Select, Button, DatePicker, Table, Input, Popover } from 'antd';
import PropTypes from 'prop-types';
import { isArray, isEmpty } from 'lodash';
import moment from 'moment';
import { TimeHorizon } from '../member-info-charts/MemberInfoCharts';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;
const { RangePicker } = DatePicker;

interface Props {
    memberId: string;
    pageParams?: any;
}
interface State {
    subsites: any[];
    total: number;
    pagination: {
        page: number;
        perPage: number;
    };
    searchParams: any;
    datasource: any[];
    loading: boolean;
}
const marketingTypeValue = {
    ONLINE: 'magicOrder',
    MALL: 'sqdd',
    POS: 'posReceipt',
};
let initDateRange: moment.Moment[] | undefined = undefined;

const CollectionCreateForm = Form.create({ name: 'searchForm' })(
    class extends React.Component {
        static propTypes: {
            onSearch: PropTypes.Requireable<(...args: any[]) => any>;
            subsites: [];
        };
        state = {
            subsiteSelect: '',
            merchantOptions: [],
        };
        handleSearch = (e: any) => {
            e.preventDefault();
            const { form, onSearch } = this.props as any;
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSearch(values);
                }
            });
        };
        handleReset = () => {
            const { form } = this.props as any;
            form.resetFields();
            this.setState({ merchantOptions: [] });
        };
        handleChange = (value: string) => {
            this.setState({
                subsiteSelect: value,
            });
            this.getMerchantData(value);
        };
        getMerchantData = async (subsiteId: string) => {
            const res = await api.get<any[]>(
                {
                    subSiteName: subsiteId,
                    sequence: 'code',
                    order: 'asc',
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: '/admin/subsites/mine/merchants',
                }
            );
            this.setState({
                merchantOptions: res,
            });
        };
        render() {
            const { form, subsites } = this.props as any;
            const { subsiteSelect, merchantOptions } = this.state;
            const { getFieldDecorator } = form;
            return (
                <Form layout="inline">
                    <Form.Item label={language.getText('subsites')}>
                        {getFieldDecorator('subsiteId')(
                            <Select
                                style={{ width: '200px' }}
                                showSearch
                                placeholder={services.language.getText('selectPlease')}
                                optionFilterProp="name"
                                filterOption={(input, option: any) =>
                                    (option.props.children || '')
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={this.handleChange}
                            >
                                {subsites &&
                                    subsites.map((subsite: any) => {
                                        return (
                                            <Select.Option key={subsite.id} value={subsite.id}>
                                                {subsite.name}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('merchant')}>
                        {getFieldDecorator('merchantId')(
                            <Select
                                style={{ width: '200px' }}
                                showSearch
                                placeholder={services.language.getText('selectPlease')}
                                optionFilterProp="merchantName"
                                filterOption={(input, option: any) =>
                                    (option.props.children || '')
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                disabled={!merchantOptions.length || !subsiteSelect}
                            >
                                {merchantOptions &&
                                    merchantOptions.map((merchant: any) => {
                                        return (
                                            <Select.Option
                                                key={merchant.merchantId}
                                                value={merchant.merchantId}
                                            >
                                                {merchant.merchantName}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('xfsj')}>
                        {getFieldDecorator('dateRange', {
                            initialValue: initDateRange,
                        })(
                            <RangePicker
                                placeholder={[
                                    services.language.getText('startTime'),
                                    services.language.getText('endTime'),
                                ]}
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                                style={{ width: '300px' }}
                                className="find-filter-width"
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('ddh_1')}>
                        {getFieldDecorator('orderNo')(
                            <Input
                                placeholder={services.language.getText('inputPlease')}
                                style={{ width: 190 }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item style={{ marginLeft: 'auto' }}>
                        <Button type="primary" shape="round" onClick={this.handleSearch}>
                            {language.getText('common.search')}
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="reset" shape="round" onClick={this.handleReset}>
                            {language.getText('common.reset')}
                        </Button>
                    </Form.Item>
                </Form>
            );
        }
    }
);

export default class MemberInfoExpenseRecordPage extends PureComponent<Props, State> {
    state: State = {
        subsites: [],
        total: 0,
        pagination: {
            page: 1,
            perPage: 10,
        },
        searchParams: {},
        datasource: [],
        loading: true,
    };

    componentDidMount() {
        const { pageParams } = this.props;
        if (pageParams && pageParams.timeHorizon) {
            if (pageParams.timeHorizon === TimeHorizon.RecentOneYears) {
                initDateRange = [
                    moment(moment().format('YYYY-MM')).subtract(11, 'months'),
                    moment(),
                ];
            } else {
                const currentTime = moment();
                // 获取当前季度的第一个月份
                const currentQuarterStartMonth = Math.floor(currentTime.month() / 3) * 3;
                // 创建当前季度的第一天日期
                const firstDayOfCurrentQuarter = moment([
                    currentTime.year(),
                    currentQuarterStartMonth,
                    1,
                ]);

                initDateRange = [moment(firstDayOfCurrentQuarter).subtract(36, 'months'), moment()];
            }
        } else {
            initDateRange = undefined;
        }
        this.getSubsites();
        this.setState(
            {
                searchParams: {
                    dateRange: initDateRange,
                },
            },
            () => {
                this.onSearchData();
            }
        );
    }

    private getSubsites = async () => {
        const subsites: any[] = await api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: '/WEB-API/admin/defaults/subsite',
            }
        );
        this.setState({
            subsites,
        });
    };

    private onChangeParams = (searchParams: any) => {
        this.setState(
            {
                searchParams,
                pagination: {
                    page: 1,
                    perPage: 10,
                },
            },
            () => {
                this.onSearchData();
            }
        );
    };

    private onSearchData = async () => {
        try {
            const { memberId } = this.props;
            const { searchParams, pagination } = this.state;
            this.setState({
                loading: true,
            });
            const { subsiteId, merchantId, orderNo } = searchParams || {};
            const params: any = {
                ...pagination,
                subsiteId,
                merchantId,
                orderNo,
            };
            if (searchParams && !isEmpty(searchParams.dateRange)) {
                let date = searchParams?.dateRange.map((i: any) => i.format('YYYY-MM-DD HH:mm:ss'));
                params.startTime = date[0];
                params.endTime = date[1];
            }
            const res: any = await api.get(params, {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                apiPath: `/admin/members/${memberId}/deal_records`,
            });

            this.setState({
                datasource: res.result,
                total: res.totalNum,
                loading: false,
            });
        } catch (error) {
            services.errorHandle(error as any);
        }
    };

    pageChange = (pageVal: any) => {
        this.setState({ pagination: { ...pageVal } }, () => {
            this.onSearchData();
        });
    };

    private renderFilter = () => {
        const classes = {
            filter: `${prefix}__filter`,
        };
        const { subsites } = this.state;
        return (
            <div className={classes.filter}>
                <CollectionCreateForm onSearch={this.onChangeParams} subsites={subsites} />
            </div>
        );
    };

    private renderOrderNo = (row: any) => {
        if (row.originCategory === 'ONLINE') {
            return (
                <a
                    href={`/8005/order/viewDetail/${row.originId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {row.orderNo}
                </a>
            );
        } else if (row.originCategory === 'POS') {
            return (
                <a
                    href={`#/pos-eceipt/info/${row.originId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {row.orderNo}
                </a>
            );
        } else {
            return <span>{row.orderNo}</span>;
        }
    };

    private renderServiceApply = (row: any) => {
        if (
            !row.serviceApplyNos ||
            !isArray(row.serviceApplyNos) ||
            row.serviceApplyNos.length === 0
        ) {
            return null;
        }
        const { serviceApplyNos, serviceApplyIds } = row;
        if (row.originCategory === 'ONLINE') {
            return serviceApplyNos.map((item: string, index: number) => {
                if (item) {
                    return (
                        <a
                            href={`/8006/afterSalesApply/detail/${serviceApplyIds[index]}`}
                            target="_blank"
                            rel="noopener noreferrer"
                            style={{ marginRight: 8 }}
                        >
                            {item}
                        </a>
                    );
                }
                return null;
            });
        } else {
            return row.serviceApplyNos.map((i: string) => <span>{i}</span>);
        }
    };

    renderPopover = (
        value: {
            productCode: string;
            productName: string;
            quantity: number;
        }[],
        row: any
    ) => {
        return (
            <div>
                {value && value.length > 0 ? value[0].productName : ''}{' '}
                {value && value.length > 1 && (
                    <Popover
                        placement={'right'}
                        content={
                            <div style={{ maxWidth: 400 }}>
                                {value.map((item: any, index: number) => (
                                    <div
                                        key={`${item.productCode}-${index}`}
                                        style={{ marginBottom: 10 }}
                                    >
                                        <span style={{ marginRight: '5px' }}>
                                            {item.productName}
                                        </span>
                                        <span> *{item.quantity}</span>
                                    </div>
                                ))}
                            </div>
                        }
                    >
                        <span className="group-tips">
                            {services.interpolate(language.getText('inAllNumProduct'), {
                                num: value.length,
                            })}
                        </span>
                    </Popover>
                )}
            </div>
        );
    };
    private renderTable = () => {
        const { pagination, total, datasource, loading } = this.state;
        return (
            <div className={`${prefix}__table`}>
                <Table
                    rowClassName={(_, index: number) => {
                        if (index % 2) {
                            return `${prefix}__table__bg`;
                        }
                        return '';
                    }}
                    scroll={{ x: true }}
                    loading={loading}
                    rowKey={(record: any) => record.id}
                    pagination={{
                        current: pagination.page,
                        pageSize: pagination.perPage,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        total,
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                        onChange: (page, perPage) => {
                            this.pageChange({ page, perPage });
                        },
                        onShowSizeChange: (page, perPage) => {
                            this.pageChange({ page, perPage });
                        },
                    }}
                    columns={[
                        {
                            title: services.language.getText('subsites'),
                            dataIndex: 'subsiteName',
                            width: 200,
                        },
                        {
                            title: services.language.getText('merchant'),
                            dataIndex: 'merchantName',
                        },
                        {
                            title: services.language.getText('xfsjly'),
                            dataIndex: 'originCategory',
                            render: (val: keyof typeof marketingTypeValue) => (
                                <span>{language.getText(marketingTypeValue[val])}</span>
                            ),
                        },
                        {
                            title: services.language.getText('ddlx'),
                            dataIndex: 'marketingTypeName',
                        },
                        {
                            title: services.language.getText('xfjey'),
                            dataIndex: 'dealAmount',
                            align: 'center',
                            render: (val: number, row: any) => (
                                <span>
                                    {row.costPoint
                                        ? `${row.costPoint}${language.getText(
                                              'point'
                                          )}+${val}${language.getText('yuan')}`
                                        : `${val}${language.getText('yuan')}`}
                                </span>
                            ),
                        },
                        {
                            title: services.language.getText('jshjey'),
                            dataIndex: 'refundAmount',
                            align: 'center',
                            render: (val: number, row: any) => (
                                <span>
                                    {row.refundPoint
                                        ? `${row.refundPoint}${language.getText(
                                              'point'
                                          )}+${val}${language.getText('yuan')}`
                                        : `${val}${language.getText('yuan')}`}
                                </span>
                            ),
                        },
                        {
                            title: services.language.getText('orderNumber'),
                            dataIndex: 'orderNo',
                            width: 230,
                            render: (val, row: any) => <span>{this.renderOrderNo(row)}</span>,
                        },
                        {
                            title: services.language.getText('components.Products.name'),
                            dataIndex: 'orderItems',
                            width: 200,
                            render: this.renderPopover,
                        },
                        {
                            title: services.language.getText('afterSalesOrderNo'),
                            dataIndex: 'serviceApplyNos',
                            width: 200,
                            render: (val, row: any) => <span>{this.renderServiceApply(row)}</span>,
                        },
                        {
                            title: language.getText('shsp'),
                            dataIndex: 'refundItems',
                            width: 200,
                            render: this.renderPopover,
                        },
                        {
                            title: services.language.getText('xfsj'),
                            dataIndex: 'dealTime',
                        },
                    ]}
                    dataSource={datasource}
                />
            </div>
        );
    };

    render() {
        return (
            <div className={prefix}>
                {this.renderFilter()}
                {this.renderTable()}
            </div>
        );
    }
}

const prefix = 'member-info-expense-record-page';
