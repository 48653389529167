import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
export const SelfCreditingLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`;
        config.apiPath = '/admin/self_crediting';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/self_crediting/${id}`;
        } else {
            if (data.channel === 'ALL') {
                delete data.channel;
            }
            const subsiteName = data.subsiteName;
            if (subsiteName) {
                data.subsiteName = subsiteName.name ? subsiteName.name : subsiteName;
            }
        }

        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.ruleConfig = {
                    subsites: res.subsites.map((item: any) => ({
                        id: item.id + '',
                        name: item.name,
                    })),
                    description: res.description,
                    type: res.method,
                };
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`;
        config.apiPath = '/admin/self_crediting';
        let newParams: any = {};

        if (params.ruleConfig) {
            newParams.subsiteIds = params.ruleConfig.subsites
                .map((item: { id: string; name: string }) => item.id)
                .join(',');
            newParams.description = params.ruleConfig.description;
            newParams.type = params.ruleConfig.type.join(',');
        }
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`;
        config.apiPath = `/admin/self_crediting/${id}`;
        let newParams: any = {};

        if (params.ruleConfig) {
            newParams.subsiteIds = params.ruleConfig.subsites
                .map((item: { id: string; name: string }) => item.id)
                .join(',');
            newParams.description = params.ruleConfig.description;
            newParams.type = params.ruleConfig.type.join(',');
        }
        return await api.put(newParams, config);
    },
};
