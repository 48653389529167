import React, { PureComponent } from 'react';
import { Table, Button, message, Popconfirm, InputNumber, Modal } from 'antd';
import { cloneDeep, remove, find } from 'lodash';
import { plus, times } from 'number-precision';
import { language } from '@comall-backend-builder/core/lib/services';

interface ProductGoodsSelectDisplayProps {
    data: any[];
    onChange: (data: any[]) => void;
    modifiable: boolean;
    pageType?: string;
}

/**
 * 货品展示和内容编辑
 */
class ProductGoodsSelectDisplay extends PureComponent<ProductGoodsSelectDisplayProps> {
    state = { visible: false };
    selectedRows: any[] = [];

    getTableConfig = () => {
        const { modifiable, pageType } = this.props;
        let config: any = {
            rowKey: 'id',
            scroll: {
                x: 900,
                y: 400,
            },
            columns: [
                {
                    title: language.getText('childProductName'),
                    dataIndex: 'name',
                    key: 'name',
                    width: 200,
                    render: (i: string) => (
                        <span title={i} className="goods-field-name">
                            {i}
                        </span>
                    ),
                },
                {
                    title: language.getText('specifications'),
                    dataIndex: 'productStyleName',
                    key: 'productStyleName',
                    width: 150,
                },
            ],
        };
        if (pageType && pageType === 'COMBINE-GOODS') {
            config.columns.splice(
                1,
                0,
                {
                    title: language.getText('productCode'),
                    dataIndex: 'code',
                    key: 'code',
                    width: 150,
                },
                {
                    title: language.getText('goodId'),
                    dataIndex: 'id',
                    key: 'id',
                    width: 150,
                }
            );
            config.columns.push({
                title: language.getText('combinNum'),
                dataIndex: 'quantity',
                key: 'quantity',
                width: 150,
                render: (i: number, row: any) => {
                    const stock = row.stock;
                    //如果可卖数等于0 则输入不校验
                    if (stock === 0) {
                        return (
                            <InputNumber
                                value={i}
                                min={1}
                                step={1}
                                precision={0}
                                onChange={(value: any) =>
                                    this.changeValue('quantity', value, row.id)
                                }
                            ></InputNumber>
                        );
                    }
                    return (
                        <InputNumber
                            value={i}
                            min={1}
                            max={stock}
                            step={1}
                            precision={0}
                            onChange={(value: any) => this.changeValue('quantity', value, row.id)}
                        ></InputNumber>
                    );
                },
            });
            config.columns.push({
                title: language.getText('hasSharePriceYuan'),
                dataIndex: 'price',
                key: 'price',
                align: 'center',
                width: 150,
                render: (i: number, row: any) => {
                    return (
                        <span>
                            <InputNumber
                                value={i}
                                min={0}
                                max={999999}
                                step={1}
                                precision={2}
                                onChange={(value: any) => this.changeValue('price', value, row.id)}
                            ></InputNumber>
                        </span>
                    );
                },
            });
        }
        config.columns.push({
            title: language.getText('priceYuan'),
            dataIndex: 'salesPrice',
            key: 'salesPrice',
            width: 150,
        });
        if (pageType && pageType === 'COMBINE-GOODS') {
            config.columns.push({
                title: language.getText('salePriceYuan'),
                dataIndex: 'marketPrice',
                key: 'marketPrice',
                width: 150,
            });
        }
        config.columns.push({
            title: language.getText('saleCount'),
            dataIndex: 'stock',
            key: 'stock',
            width: 150,
        });
        if (modifiable) {
            config.columns.push({
                title: language.getText('common.tableAction'),
                key: 'operation',
                fixed: 'right' as const,
                width: 100,
                render: (i: any, row: any) => {
                    return (
                        <Button type={'link'} onClick={this.onRemove(row.id)}>
                            {language.getText('common.delete')}
                        </Button>
                    );
                },
            });
            config.rowSelection = {
                fixed: true,
                onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                    this.selectedRows = selectedRows;
                },
            };
        }
        return config;
    };

    changeValue = (field: string, value: any, id: string) => {
        const { data, onChange } = this.props;
        const result = cloneDeep(data);
        let item = find(result, (i) => id === i.id);
        if (item) {
            item[field] = value;
            onChange(result);
        }
    };

    renderFooter = () => {
        return (
            <div style={{ marginTop: '10px' }}>
                <Popconfirm
                    onConfirm={this.batchRemove()}
                    title={language.getText('confirmDeleteColumn')}
                    okText={language.getText('common.ok')}
                    cancelText={language.getText('common.cancel')}
                >
                    <Button type="default">{language.getText('batchDelete')}</Button>
                </Popconfirm>
            </div>
        );
    };

    renderCombineGoodsFooter = () => {
        const { data } = this.props;
        let price = 0;
        let marketPrice = 0;
        let num = 0;
        let numArray: Array<number> = [];
        data.forEach((good: any) => {
            //0也要计算
            if (
                good.price !== undefined &&
                good.price !== null &&
                good.price !== '' &&
                good.quantity !== undefined &&
                good.quantity !== null &&
                good.quantity !== ''
            ) {
                price = price + good.price * good.quantity;
            }
            if (
                good.marketPrice !== undefined &&
                good.marketPrice !== null &&
                good.marketPrice !== '' &&
                good.quantity !== undefined &&
                good.quantity !== null &&
                good.quantity !== ''
            ) {
                marketPrice = plus(marketPrice, times(good.marketPrice, good.quantity));
            }
            if (
                good.stock !== undefined &&
                good.stock !== null &&
                good.stock !== '' &&
                good.quantity !== undefined &&
                good.quantity !== null &&
                good.quantity !== ''
            ) {
                const minGoodNum = good.stock / good.quantity;
                numArray.push(minGoodNum);
            }
        });
        if (numArray.length > 0) {
            const numSort = numArray.sort((a, b) => {
                return a - b;
            });
            num = parseInt(numSort[0].toString());
        }
        return (
            <div>
                <div className="batch-remove">
                    {language.getText('combinProductSalePrice')}
                    {price.toFixed(2)}
                </div>
                <div style={{ display: 'flex' }}>
                    <div>{language.getText('underlinedPrice')}</div>
                    <div>
                        <div>{marketPrice.toFixed(2)}</div>
                        <div style={{ fontSize: 14, lineHeight: 1.5, color: '#ccc' }}>
                            {language.getText('sumMarketPricesGoods')}
                        </div>
                    </div>
                </div>
                <div className="batch-remove">
                    {language.getText('combinProductSaleNum')}
                    {num}
                </div>
            </div>
        );
    };

    onRemove = (id: string) => {
        const { data, onChange } = this.props;
        return () => {
            const result = cloneDeep(data);
            remove(result, (i) => id === i.id);
            onChange(result);
        };
    };

    batchRemove = () => {
        return () => {
            const selectedRow = this.selectedRows;
            if (!selectedRow || selectedRow.length === 0) {
                message.info(language.getText('selectBatchDeleteColumn'));
                return;
            }
            const { data, onChange } = this.props;
            const result = cloneDeep(data);
            remove(result, (i) => !!find(selectedRow, (j) => j.id === i.id));
            onChange(result);
        };
    };

    toggle = () => {
        this.setState({ visible: !this.state.visible });
    };

    renderExampleButton = () => {
        const picUrl = require('./market-price.png');
        return (
            <>
                <Button onClick={this.toggle} type="link">
                    {language.getText('underlinePricePic')}
                </Button>
                <Modal
                    zIndex={9999}
                    footer={null}
                    title={language.getText('picRelation')}
                    onCancel={this.toggle}
                    visible={this.state.visible}
                    width="600px"
                >
                    <div>
                        <img src={picUrl} width="100%" alt="" />
                    </div>
                </Modal>
            </>
        );
    };

    render() {
        const { data, modifiable, pageType } = this.props;
        let tableConfig = this.getTableConfig();
        const tableProps = { ...tableConfig, dataSource: data };
        return (
            <div className={'product-select-display-for-presale ' + (modifiable ? 'modify' : '')}>
                <Table {...tableProps} pagination={false}></Table>
                {modifiable && this.renderFooter()}
                {pageType && pageType === 'COMBINE-GOODS' && this.renderCombineGoodsFooter()}
                {pageType && pageType === 'COMBINE-GOODS' && this.renderExampleButton()}
            </div>
        );
    }
}

export { ProductGoodsSelectDisplay };
