import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { isEmpty } from 'lodash';
import { message } from 'antd';

const emptyData = {
    page: 1,
    perPage: 10,
    result: [],
    totalNum: 0,
    totalPage: 0,
};

const api = services.api;
export const OfflinePaymentPaymentResultLoader: Loader = {
    get: async function(data, config) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`;
        config.apiPath = '/admin/offline_payment/payment_result';
        if (isEmpty(data?.date)) {
            message.warning('请选择交易日期');
            return emptyData;
        }
        if (isEmpty(data?.bankCardNo) && isEmpty(data?.outTradeNo)) {
            message.warning('银行卡号或交易流水号至少需要输入一项');
            return emptyData;
        }
        data.startTime = data.date;
        data.endTime = data.date;
        return await api.get(data, config).then((res: any) => {
            res.result = res.result.map((item: any) => {
                item.reviewable = item.reviewable ? 'true' : 'false';
                return item;
            });
            return res;
        });
    },
};
