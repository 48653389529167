import { Config } from '@comall-backend-builder/core/lib/parser';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { TipsModalContentType } from '../../../../components';
import { ContentType as PageContentType } from '../../../../types/mode/array/carpark-page-content';
import {
    ModuleType as MenusModuleType,
    moduleNames as menusModuleNames,
    VisibilitySetting,
    BookParkingSpaceType,
    ModuleType,
} from '../../../../components/carpark-menus-config/common';
import get from 'lodash/get';
import { message as AntMessage } from 'antd';
import { isArray } from 'lodash';
import { checkLinkTypeParamsRequired } from '../../../../types/format';
import { checkTenantPrivilege } from '../../../../services/tenant-privilege';
import { language } from '@comall-backend-builder/core/lib/services';

const isCheckoutTypeInternal = (values: any) =>
    get(values, 'carparkConfig.checkoutType.type') === 'INTERNAL';
const carparkSupportAlipay = (values: any) =>
    isCheckoutTypeInternal(values) &&
    new Set(get(values, 'carparkConfig.carparkSupportAlipay', [])).has('support');
const hasCarparkSystem = (values: any) =>
    isCheckoutTypeInternal(values) && Boolean(values?.carparkConfig?.carparkSystemName);
const hasParkingId = (values: any) => get(values, 'carparkConfig.parkingId');

const checkLinkTypeParamsJson = (json: string | null) => {
    if (!json) {
        return false;
    }
    const config = JSON.parse(json) || {};
    return config.linkType && checkLinkTypeParamsRequired(config.linkType, config.linkParams);
};

const touchlessParkingSystemIllustrate: { [str: string]: any } = {
    WECHAT_PAY_SCORE_TOUCHLESS: '<<wxzfwzfffsdbdczyhtg>>',
    VIRTUAL_ASSET_TOUCHLESS: '',
};

/**
 * @param isAdd 是否是新增页面
 * @returns
 */
const formValidate = (entity: any, isAdd: boolean) => {
    const {
        carparkConfig,
        pageConfig,
        otherConfig: { pageContent, pointAlias, menusConfig },
    } = entity;
    const { tripartiteSystemSupport, freeExitTime, checkoutType } = carparkConfig;
    const isInternal = isCheckoutTypeInternal(entity);
    // 三方结算缴费时需要设置链接目标
    if (!isInternal && !checkLinkTypeParamsJson(checkoutType.config)) {
        AntMessage.error(language.getText('qszjsfssfljmb'));
        return false;
    }
    // 编辑页有对接车场系统时，判断免费离场时间是否需要设置
    if (
        hasCarparkSystem(entity) &&
        tripartiteSystemSupport === 'false' &&
        !freeExitTime &&
        freeExitTime !== 0
    ) {
        AntMessage.error(language.getText('qszlcdjssz'));
        return false;
    }
    //编辑页面有车场系统 需要判断车辆名称必填(内部结算缴费)
    if (isInternal && carparkConfig?.carparkInfos && carparkConfig?.carparkInfos.length) {
        const carparkInfoHasName = carparkConfig?.carparkInfos.find((item: any) => !item.name);
        if (carparkInfoHasName) {
            AntMessage.error(services.language.getText('carparkNameRequire'));
            return false;
        }
    }

    // 校验支付宝车场配置是否完整
    if (carparkSupportAlipay(entity)) {
        // 新增页面才校验
        if (isAdd && !carparkConfig.name) {
            AntMessage.error(services.language.getText('qsrccmc'));
            return false;
        }
        // 新增页面才校验
        if (isAdd && !carparkConfig.address) {
            AntMessage.error(language.getText('qsrccjtdz'));
            return false;
        }
        // 新增页面才校验
        if (isAdd && !carparkConfig.mobile) {
            AntMessage.error(language.getText('qsrcckfdh'));
            return false;
        }
        // if (!carparkConfig.mchntId) {
        //     AntMessage.error(language.getText('qsrccskf'));
        //     return false;
        // }
        // if (!carparkConfig.amapPoiId) {
        //     AntMessage.error(language.getText('qsrcc'));
        //     return false;
        // }
    }
    // 校验广告配置
    if (pageConfig.carousels && isArray(pageConfig.carousels)) {
        let flag = false;

        pageConfig.carousels.forEach((it: any) => {
            const { config } = it;
            if (config && !checkLinkTypeParamsRequired(config.linkType, config.linkParams)) {
                flag = true;
            }
        });
        if (flag) {
            const defalutValidMessage = language.getText('qxztpggljmb');
            AntMessage.error(defalutValidMessage);
            return false;
        }
    }
    // 展示积分需设置积分文案
    if (pageContent?.includes(PageContentType.PointsDisplay) && !pointAlias?.trim()) {
        AntMessage.error(language.getText('qsrjfzswa'));
        return false;
    }
    // 至少选择一个查询缴费的入口
    if (
        !pageContent?.includes(PageContentType.QueryPayment) &&
        !menusConfig.find((config: any) => config.moduleType === MenusModuleType.QueryPayment)
            ?.visible
    ) {
        AntMessage.error(language.getText('qszcxjf'));
        return false;
    }
    // 检查勾选了的功能是否都上传了图标
    const missingIconItem = [...menusConfig].find((item) => item.visible && !item.picture);
    if (missingIconItem) {
        let name =
            language.getText(menusModuleNames[missingIconItem.moduleType as MenusModuleType]) ||
            language.getText('jbdjf');
        if (missingIconItem.moduleType === ModuleType.Custom) {
            name = missingIconItem.name;
        }

        AntMessage.error(services.interpolate(language.getText('setNamePic'), { name }));
        return false;
    }
    // 勾选寻找车辆时检查是否配置链接目标
    const findVehicle = menusConfig.find(
        (item: any) => item.moduleType === MenusModuleType.FindVehicle
    );
    if (
        findVehicle?.visible &&
        findVehicle?.config?.type === 'EXTERNAL' &&
        !checkLinkTypeParamsJson(findVehicle?.config?.content)
    ) {
        AntMessage.error(language.getText('qszxzclljmb'));
        return false;
    }
    // 检查勾选开具发票且外部跳转时是否设置了跳转目标
    const carparkOrderInvoice = menusConfig.find(
        (item: any) => item.moduleType === MenusModuleType.CarparkOrderInvoice
    );
    if (
        carparkOrderInvoice?.visible &&
        carparkOrderInvoice?.config?.channel === 'EXTERNAL_INVOICE' &&
        !checkLinkTypeParamsJson(carparkOrderInvoice?.config?.content)
    ) {
        AntMessage.error(language.getText('qszkjfpsfljmb'));
        return false;
    }
    // 检查勾选预约车位且外部跳转时是否设置了跳转目标
    const bookParkingSpace = menusConfig.find(
        (item: any) => item.moduleType === MenusModuleType.BookParkingSpace
    );
    if (bookParkingSpace?.visible) {
        if (
            bookParkingSpace?.config?.visibilitySetting ===
                VisibilitySetting.VISIBLE_MEMBER_CARD_LEVEL &&
            !bookParkingSpace?.config?.visibleMemberCardLevel?.length
        ) {
            AntMessage.error(language.getText('setBookParkingCardLevel'));
            return false;
        }
        if (
            bookParkingSpace?.config?.type === BookParkingSpaceType.EXTERNAL &&
            !checkLinkTypeParamsJson(bookParkingSpace?.config?.content)
        ) {
            AntMessage.error(language.getText('setBookParkingLinkType'));
            return false;
        }
    }
    // 勾选自定义菜单时检查是否填写了菜单名称
    const customHasName = menusConfig.find(
        (item: any) => item.moduleType === MenusModuleType.Custom && !item.name
    );
    if (customHasName?.visible) {
        AntMessage.error(language.getText('fillInMenuName'));
        return false;
    }

    // 勾选自定义菜单时检查是否配置链接目标
    const customHasLink = menusConfig.find(
        (item: any) =>
            item.moduleType === MenusModuleType.Custom &&
            !checkLinkTypeParamsJson(item?.config?.content)
    );
    if (customHasLink?.visible) {
        const name = customHasLink?.name;
        AntMessage.error(services.interpolate(language.getText('setCustomMenuLinkType'), { name }));
        return false;
    }

    // 勾选菜单不能超过6个
    const CheckedMenusConfig =
        menusConfig && menusConfig.filter((item: any) => item.visible === true);
    if (CheckedMenusConfig && CheckedMenusConfig.length > 6) {
        AntMessage.error(language.getText('maxSelectSix'));
        return false;
    }

    return true;
};

export const config: Config = {
    entities: {
        NewSubsiteCarparkConfig: {
            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/CARPARK'),
            apiPath: '/loader/admin/carparks',
            paramsFilter: ['NEW_FRONT_CLIENT'],
            properties: {
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                carparkExplain: {
                    type: 'string.text.paragraph',
                    displayName: '<<jfsm>>',
                },
                carparkInfos: {
                    type: 'array.popover',
                    displayName: '<<ccxt>>',
                },
                carparkConfig: {
                    type: 'object.subForm',
                    displayName: '<<ccsz>>',
                    collapsed: true,
                    defaultOpen: true,
                    properties: {
                        subsite: {
                            type: 'object.option.select',
                            displayName: '<<sxmd>>',
                            options: [],
                            source: {
                                apiRoot: ENV.API_ROOT.replace('/WEB-API', '/CARPARK'),
                                apiPath: '/admin/carparks/subsites',
                            },
                            rules: [{ required: true }],
                        },
                        carparkInfos: {
                            type: 'array.carparkModuleInfo',
                            displayName: '<<ccxt>>',
                        },
                        checkoutType: {
                            type: 'object.carparkCheckoutType',
                            displayName: '<<jsfs>>',
                        },
                        carparkSystemName: {
                            type: 'string',
                            displayName: '<<ccxt>>',
                            controlConfig: {
                                style: {
                                    width: 300,
                                },
                            },
                        },
                        tripartiteSystemSupport: {
                            type: 'string.options.radio',
                            displayName: '<<lcdjs>>',
                            defaultValue: 'true',
                            options: [
                                { id: 'true', name: '<<djsyccwz>>' },
                                { id: 'false', name: '<<zdypz>>' },
                            ],
                        },
                        freeExitTime: {
                            type: 'number.integerInputTitleTip',
                            displayName: '<<djssz>>',
                            controlConfig: {
                                addonafter: '<<minutes>>',
                                rules: [
                                    {
                                        type: 'number',
                                        min: 0,
                                        max: 999999,
                                    },
                                ],
                                width: 100,
                            },
                        },
                        carparkSupportAlipay: {
                            type: 'array.carparkSupportAlipay',
                            displayName: '<<aliMiniP>>',
                        },
                        name: {
                            type: 'string',
                            displayName: '<<ccmc>>',
                            extra: '<<rccjkyfhzyccfhzwzzymzs>>',
                            controlConfig: {
                                style: {
                                    width: 300,
                                },
                            },
                        },
                        address: {
                            type: 'string',
                            displayName: '<<ccjtdz>>',
                            controlConfig: {
                                style: {
                                    width: 300,
                                },
                            },
                        },
                        mobile: {
                            type: 'string',
                            displayName: '<<cckfdh>>',
                            rules: [
                                {
                                    whitespace: true,
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 300,
                                },
                            },
                        },
                        mchntId: {
                            type: 'string.questionTips',
                            displayName: '<<ccskf>>',
                            controlConfig: {
                                questionTipsConfig: {
                                    content: '<<mchntIdContent>>',
                                    iconStyle: {
                                        color: '#1890ff',
                                    },
                                    placement: 'top',
                                },
                                style: {
                                    width: 300,
                                },
                            },
                        },
                        amapPoiId: {
                            type: 'string.modalTips',
                            displayName: '<<amapPoiId>>',
                            controlConfig: {
                                tipsModalConfig: {
                                    contentType: TipsModalContentType.CarparkAmapPoiId,
                                    showIcon: true,
                                    iconStyle: {
                                        color: '#1890ff',
                                    },
                                    text: '<<rhcx>>',
                                    textColor: '#1890ff',
                                    title: '<<amapPoiIdTitle>>',
                                },
                                style: {
                                    width: 300,
                                },
                            },
                            extra: '<<zysjqjstxjxg>>',
                        },
                        parkingId: {
                            type: 'string',
                            displayName: 'parkingID',
                            controlConfig: {
                                style: {
                                    width: 300,
                                },
                            },
                        },
                        enabledTouchlessParking: {
                            type: 'boolean.checkbox.tips',
                            displayName: '<<touchlessParking>>',
                            defaultValue: false,
                            extra: '<<qmqkthzcgx>>',
                            controlConfig: {
                                // tips: '<<wxzfwzfffsdbdczyhtg>>',
                                label: '<<enabledTouchlessParking>>',
                            },
                        },
                        touchlessParkingSystem: {
                            type: 'string.radioIllustrate',
                            displayName: '无感缴费渠道',
                            name: JSON.stringify(touchlessParkingSystemIllustrate),
                            options: [
                                {
                                    id: 'WECHAT_PAY_SCORE_TOUCHLESS',
                                    name: '<<wechatPayScoreTouchless>>',
                                },
                                {
                                    id: 'VIRTUAL_ASSET_TOUCHLESS',
                                    name: '<<virtualAssetTouchless>>',
                                },
                            ],
                            controlConfig: {
                                disabled: true,
                                illustrate: touchlessParkingSystemIllustrate,
                                style: { display: 'flex', flexDirection: 'column', paddingTop: 9 },
                            },
                        },
                    },
                },
                pageConfig: {
                    type: 'object.subForm',
                    displayName: '<<components.PageDesign.pageSetting>>',
                    collapsed: true,
                    defaultOpen: true,
                    properties: {
                        carousels: {
                            type: 'array.carparkImageAds',
                            displayName: '<<tpgg>>',
                            defaultValue: [
                                {
                                    picture: [
                                        {
                                            id: '0',
                                            path:
                                                'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/carpark/carpark_default_banner.png',
                                        },
                                    ],
                                },
                            ],
                            controlConfig: {
                                newClient: true,
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        carparkExplain: {
                            type: 'string.text.paragraph',
                            displayName: '<<tcjfsm>>',
                        },
                        showBoundCarNumberCountType: {
                            type: 'string.options.radio',
                            displayName: '<<bindCards>>',
                            defaultValue: 'ALL',
                            options: [
                                { id: 'ALL', name: '<<bindCardsAll>>' },
                                { id: 'NUM', name: '<<bindCardsNum>>' },
                            ],
                        },
                        showBoundCarNumberCount: {
                            type: 'number.tip',
                            defaultValue: 2,
                            extra: '<<xsrdydyos>>',
                            displayName: '<<cardsNum>>',
                            rules: [
                                { required: true },
                                {
                                    pattern: /^[0-9]*[02468]$/,
                                    message: '<<xsrdydyos>>',
                                },
                            ],
                            controlConfig: {
                                addonbefore: '',
                                addonafter: '<<liang>>',
                                style: { width: '80px' },
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                                min: 2,
                                max: 98,
                                placeholder: '<<inputPlease>>',
                            },
                        },
                    },
                },
                otherConfig: {
                    type: 'object.subForm',
                    displayName: '<<seniorSetting>>',
                    collapsed: true,
                    defaultOpen: true,
                    properties: {
                        pageContent: {
                            type: 'array.carparkPageContent',
                            displayName: '<<nrqy>>',
                            extra: '<<qlxkhjlmqyyccwcdj>>',
                        },
                        pointAlias: {
                            type: 'string.carparkPointAlias',
                            displayName: '<<jfzswa>>',
                        },
                        menusConfig: {
                            type: 'array.carparkMenusConfig',
                            displayName: '<<gncd>>',
                            controlConfig: {
                                getSubsite: (row: any) => {
                                    return get(row, 'carparkConfig.subsite');
                                },
                            },
                        },
                        buttonsConfig: {
                            type: 'array.carparkButtonsConfig',
                            displayName: '<<buttonsConfig>>',
                        },
                    },
                },
            },
            filters: {
                subsiteName: {
                    type: 'object.option.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
            },
        },
    },
    components: {
        NewSubsiteCarparkView: {
            component: 'Viewport',
            entity: 'NewSubsiteCarparkConfig',
        },
        NewSubsiteCarparkPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'NewSubsiteCarparkHeader',
                },
                {
                    component: 'NewSubsiteCarparkTable',
                },
            ],
        },
        NewSubsiteCarparkHeader: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'GridLayout',
                    items: [
                        {
                            component: 'NewSubsiteCarparkFilter',
                            span: 24,
                        },
                        {
                            component: 'Button',
                            text: '<<common.add>>',
                            icon: 'plus',
                            type: 'primary',
                            route: '/new-subsite-carpark/create',
                            span: 4,
                            style: {
                                marginRight: 24,
                            },
                            className: 'mt24 mb24',
                        },
                    ],
                },
            ],
        },
        NewSubsiteCarparkFilter: {
            component: 'FilterForm',
            direction: 'inline',
            fields: [
                {
                    property: 'subsiteName',
                },
            ],
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 12,
                },
            },
            reset: true,
        },
        NewSubsiteCarparkTable: {
            component: 'DataTable',
            loadFirstPage: true,
            scroll: {
                x: true,
            },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'subsiteName',
                    width: 240,
                },
                {
                    property: 'carparkExplain',
                },
                {
                    property: 'carparkInfos',
                    displayConfig: {
                        countSuffix: '<<cc_2>>',
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 190,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'status',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/CARPARK'),
                            handles: [
                                {
                                    params: { status: 'DISABLE' },
                                    value: 'ENABLE',
                                    apiPath: '/admin/carparks/:id/status/disable',
                                    loadType: 'post',
                                    config: {
                                        content: '<<common.close>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.subsiteName}}?',
                                    },
                                },
                                {
                                    params: { status: 'ENABLE' },
                                    value: 'DISABLE',
                                    apiPath: '/admin/carparks/:id/status/enable',
                                    loadType: 'post',
                                    config: {
                                        content: '<<common.opend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.subsiteName}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/new-subsite-carpark/edit/{{row.id}}',
                    },

                    {
                        type: 'component',
                        component: 'CustomLink',
                        text: '<<components.PageManage.decorate>>',
                        privilege: {
                            path: 'carparkPage',
                            level: 'full',
                        },
                        config: {
                            url: `#/carpark-page?subsiteName={{row.subsiteName}}`,
                            target: '_blank',
                        },
                    },
                    {
                        type: 'component',
                        component: 'PageNewPreviewCodeButton',
                        config: {
                            codeTypes: 'all',
                            codeModalType: 'item',
                            codePageType: 'parkingPage',
                        },
                    },
                ],
            },
        },
        NewSubsiteCarparkCreatePage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'NewSubsiteCarparkConfig',
            items: [
                { component: 'NewSubsiteCarparkPreview' },
                { component: 'NewSubsiteCarparkCreateForm' },
            ],
        },
        NewSubsiteCarparkCreateForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            style: {
                width: 700,
            },
            fields: [
                {
                    property: 'carparkConfig.subsite',
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
                {
                    property: 'carparkConfig.checkoutType',
                },
                {
                    property: 'carparkConfig.name',
                    visible: isCheckoutTypeInternal,
                },
                {
                    property: 'carparkConfig.address',
                    visible: isCheckoutTypeInternal,
                },
                {
                    property: 'carparkConfig.mobile',
                    visible: isCheckoutTypeInternal,
                },
                // {
                //     property: 'carparkConfig.carparkSupportAlipay',
                //     visible: isCheckoutTypeInternal,
                // },
                {
                    property: 'carparkConfig.mchntId',
                    visible: carparkSupportAlipay,
                },
                {
                    property: 'carparkConfig.amapPoiId',
                    visible: carparkSupportAlipay,
                },
                {
                    property: 'carparkConfig.enabledTouchlessParking',
                    visible: (value: any) => {
                        return (
                            isCheckoutTypeInternal(value) &&
                            checkTenantPrivilege('wechatPayScoreParking')
                        );
                    },
                },
                {
                    property: 'pageConfig.carousels',
                    width: 200,
                    rules: [{ required: false }],
                },
                {
                    property: 'pageConfig.carparkExplain',
                    label: '<<tcjfsm>>',
                    controlConfig: {
                        placeholder: '<<qsrtccsm>>',
                    },
                },
                {
                    property: 'pageConfig.showBoundCarNumberCountType',
                },
                {
                    property: 'pageConfig.showBoundCarNumberCount',
                    visible: (values: any) =>
                        get(values, 'pageConfig.showBoundCarNumberCountType') === 'NUM',
                },
                {
                    property: 'otherConfig.pageContent',
                },
                {
                    property: 'otherConfig.pointAlias',
                    visible: (values: any) =>
                        get(values, 'otherConfig.pageContent', []).includes(
                            'PAGE_CONTENT_POINTS_DISPLAY'
                        ),
                },
                {
                    property: 'otherConfig.menusConfig',
                },
                // {
                //     property: 'otherConfig.buttonsConfig',
                // },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => formValidate(entity, true),
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        NewSubsiteCarparkEditPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'NewSubsiteCarparkConfig',
            loaderType: 'get',
            items: [
                { component: 'NewSubsiteCarparkPreview' },
                { component: 'NewSubsiteCarparkEditForm' },
            ],
        },
        NewSubsiteCarparkEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            style: {
                width: 700,
            },
            fields: [
                {
                    property: 'carparkConfig.subsite',
                    modifiable: false,
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
                {
                    property: 'carparkConfig.checkoutType',
                },
                {
                    property: 'carparkConfig.carparkInfos',
                    visible: isCheckoutTypeInternal,
                },
                {
                    property: 'carparkConfig.mchntId',
                    visible: hasParkingId,
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'carparkConfig.amapPoiId',
                    visible: hasParkingId,
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'carparkConfig.parkingId',
                    visible: hasParkingId,
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'carparkConfig.enabledTouchlessParking',
                    visible: (value: any) => {
                        return (
                            isCheckoutTypeInternal(value) &&
                            checkTenantPrivilege('wechatPayScoreParking')
                        );
                    },
                },
                {
                    property: 'carparkConfig.touchlessParkingSystem',
                    visible: (value: any) =>
                        isCheckoutTypeInternal(value) &&
                        checkTenantPrivilege('wechatPayScoreParking') &&
                        get(value, 'carparkConfig.enabledTouchlessParking'),
                },
                {
                    property: 'pageConfig.carousels',
                    width: 200,
                    rules: [{ required: false }],
                },
                {
                    property: 'pageConfig.carparkExplain',
                    label: '<<tcjfsm>>',
                    controlConfig: {
                        placeholder: '<<qsrtccsm>>',
                    },
                },
                {
                    property: 'pageConfig.showBoundCarNumberCountType',
                },
                {
                    property: 'pageConfig.showBoundCarNumberCount',
                    visible: (values: any) =>
                        get(values, 'pageConfig.showBoundCarNumberCountType') === 'NUM',
                },
                {
                    property: 'otherConfig.pageContent',
                },
                {
                    property: 'otherConfig.pointAlias',
                    visible: (values: any) =>
                        get(values, 'otherConfig.pageContent', []).includes(
                            'PAGE_CONTENT_POINTS_DISPLAY'
                        ),
                },
                {
                    property: 'otherConfig.menusConfig',
                },
                // {
                //     property: 'otherConfig.buttonsConfig',
                // },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => formValidate(entity, false),
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/new-subsite-carpark',
                    component: 'NewSubsiteCarparkView',
                    breadcrumbName: '<<yardManagement>>',
                    childRoutes: [
                        {
                            path: '/create',
                            component: 'NewSubsiteCarparkCreatePage',
                        },
                        {
                            path: '/edit/:id',
                            component: 'NewSubsiteCarparkEditPage',
                        },
                        {
                            path: '/',
                            component: 'NewSubsiteCarparkPage',
                        },
                    ],
                    privilege: {
                        path: 'newCSubsiteCarpark',
                        level: 'full',
                    },
                },
            ],
        },
    ],
};
