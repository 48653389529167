import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { assign } from 'lodash';

const api = services.api;
export const PointSettingLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`;
        config.apiPath = '/admin/point_rule';
        return await api.get(data, config).then((res: any) => {
            return {
                baseInfo: {
                    exchangeRule: {
                        exchangeRatio: res.exchangeRatio ? parseInt(res.exchangeRatio) : 1,
                        exchangeAmount: res.exchangeAmount ? Number(res.exchangeAmount) : 1,
                    },
                },
                ruleInfo: {
                    deductionType: res.deductionType,
                    productRange: res.productRange,
                    minUseCount: res.minUseCount ? parseInt(res.minUseCount) : '',
                    maxUseCount: res.maxUseCount ? parseInt(res.maxUseCount) : '',
                    largestPercentage: res.largestPercentage ? parseInt(res.largestPercentage) : '',
                    remark: res.remark,
                },
            };
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`;
        config.apiPath = '/admin/point_rule';
        let newParams: any = { id: 8 };
        if (params.baseInfo && params.baseInfo.exchangeRule) {
            newParams.exchangeRatio = params.baseInfo.exchangeRule.exchangeRatio;
            newParams.exchangeAmount = params.baseInfo.exchangeRule.exchangeAmount;
        }
        if (params.ruleInfo) {
            assign(newParams, params.ruleInfo);
        }
        return await api.put(newParams, config);
    },
};
