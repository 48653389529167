/**
 * 券状态
 */
export enum CouponStatus {
    /**
     * 待兑换
     */
    UNEXCHANGED = 'UNEXCHANGED',
    /**
     * 已兑换
     */
    EXCHANGED = 'EXCHANGED',
    /**
     * 未绑定
     */
    UNBOUND = 'UNBOUND',
    /**
     * 已绑定
     */
    BOUND = 'BOUND',
    /**
     * 已使用
     */
    USED = 'USED',
    /**
     * 已作废
     */
    CANCELED = 'CANCELED',
    /**
     * 已失效
     */
    INVALID = 'INVALID',
    /**
     * 已冻结
     */
    FREEZE = 'FREEZE',
    /**
     * 预绑定
     */
    PREBINDING = 'PREBINDING',
    /**
     * 赠送中
     */
    TRANSFERRING = 'TRANSFERRING',
}
