import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach, isEmpty } from 'lodash';

const api = services.api;
export const CustomCrowdBatchLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        if (data.id) {
            data.crowdId = data.id;
        }
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`;
        config.apiPath = '/admin/crowd_members';
        return await api.get(data, config).then((res: any) => {
            if (res && !isEmpty(res.result)) {
                forEach(res.result, (item) => {
                    item.importResult = `全部（${item.totalCount}）失败（${item.failCount}）`;
                });
            }
            return res;
        });
    },
};
