import React from 'react';
import { Select } from 'antd';
import get from 'lodash/get';
import { ConditionType } from '../index';
import { MaotaiPresaleConditionsProps } from '../../index';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

interface LevelProps extends MaotaiPresaleConditionsProps {
    options?: any[];
    endText: string;
    index: number;
}

export default class Level extends React.PureComponent<LevelProps> {
    conditionType = ConditionType.LEVEL;
    onSelectCardLevel = (val: any[], ...options: any) => {
        const selectedValue = val
            ? val.map((i: any) => ({
                  id: i.key,
                  name: i.label,
              }))
            : [];
        const { onChange, value, index } = this.props;
        let list;
        if (
            !value.find(
                (item, itemIndex) =>
                    item.conditionType === this.conditionType && index === itemIndex
            )
        ) {
            list = [
                ...value,
                { conditionType: this.conditionType, conditionValue: { value: selectedValue } },
            ];
        } else {
            list = value.map((item, itemIndex) => {
                if (this.conditionType === item.conditionType && index === itemIndex) {
                    return { ...item, conditionValue: { value: selectedValue } };
                }
                return item;
            });
        }

        onChange(list);
    };
    render() {
        const { value, options, endText } = this.props;
        const formData = value.find((i) => i.conditionType === this.conditionType);
        const val = get(formData, 'conditionValue.value', []).map((i: any) => ({
            key: i.id,
            label: i.name,
        }));
        return (
            <div>
                <span>{language.getText('cardLevel')}</span>&nbsp;
                <Select
                    onChange={this.onSelectCardLevel}
                    placeholder={services.language.getText('selectPlease')}
                    style={{ width: 200 }}
                    mode="multiple"
                    value={val}
                    labelInValue
                    optionFilterProp="name"
                >
                    {!!options &&
                        options.map((o) => (
                            <Select.Option key={o.id} value={o.id}>
                                {o.name}
                            </Select.Option>
                        ))}
                </Select>
                &nbsp;
                <span>{endText}</span>
            </div>
        );
    }
}
