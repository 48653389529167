import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';

const onValidate = (entity: any) => {
    const { deductionUnit, minUse, maxUse } = entity;
    if (minUse % deductionUnit !== 0) {
        AntMessage.error('<<zssyfzbxwzxdkdwdbs>>');
        return false;
    }
    if (maxUse % deductionUnit !== 0) {
        AntMessage.error('<<zdsyfzbxwzxdkdwdbs>>');
        return false;
    }
    if (minUse > maxUse) {
        AntMessage.error('<<zdsyfzxydydyzxsyfz>>');
        return false;
    }
    return true;
};

export const config: Config = {
    entities: {
        PointExchangeRuleEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/POINT-EXCHANGE-COUPON`,
            apiPath: '/loader/admin/pointExchangeRule',
            filters: {
                subsiteId: {
                    type: 'object.option.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                subsiteName: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                subsites: {
                    type: 'array.subsites.selector',
                    displayName: '<<otherSelectSubsite>>',
                    rules: [{ required: true }],
                },
                pointUnitDesc: {
                    type: 'string.richTextPlus',
                    displayName: '<<dhsm>>',
                },
                deductionUnit: {
                    type: 'number.tip',
                    displayName: '<<zxdkdw>>',
                    controlConfig: {
                        style: {
                            width: 150,
                        },
                        addonafter: '<<point>>',
                    },
                    rules: [
                        {
                            required: true,
                        },
                        {
                            message: '<<inputThanZero>>',
                            validator: (rule: any, value: any) => {
                                var reg = /^\+?[1-9]\d*$/;
                                if (reg.test(value)) {
                                    return true;
                                } else {
                                    return false;
                                }
                            },
                        },
                    ],
                },
                minUse: {
                    type: 'number.tip',
                    displayName: '<<zssy>>',
                    controlConfig: {
                        style: {
                            width: 150,
                        },
                        addonafter: '<<point>>',
                    },
                    rules: [
                        {
                            required: true,
                        },
                        {
                            message: '<<inputThanZero>>',
                            validator: (rule: any, value: any) => {
                                var reg = /^\+?[1-9]\d*$/;
                                if (reg.test(value)) {
                                    return true;
                                } else {
                                    return false;
                                }
                            },
                        },
                    ],
                },
                maxUse: {
                    type: 'number.tip',
                    displayName: '<<zdsy>>',
                    controlConfig: {
                        style: {
                            width: 150,
                        },
                        addonafter: '<<point>>',
                    },
                    rules: [
                        {
                            required: true,
                        },
                        {
                            message: '<<inputThanZero>>',
                            validator: (rule: any, value: any) => {
                                var reg = /^\+?[1-9]\d*$/;
                                if (reg.test(value)) {
                                    return true;
                                } else {
                                    return false;
                                }
                            },
                        },
                    ],
                },
                description: {
                    type: 'string.richTextPlus',
                    displayName: '<<dhgz>>',
                    controlConfig: {
                        toolbarId: 'descriptionRichText',
                        style: {
                            height: 300,
                        },
                        uploadConfig: {
                            apiPath: `/admin/images/upload`,
                            fileName: 'fileName',
                        },
                    },
                },
            },
        },
    },
    components: {
        PointExchangeRuleView: {
            component: 'Viewport',
            entity: 'PointExchangeRuleEntity',
        },
        PointExchangeRulePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'PointExchangeRuleFilter',
                },
                {
                    component: 'Button',
                    text: '<<xzdhgz>>',
                    route: '/point-exchange-rule/add',
                    type: 'primary',
                    icon: 'plus',
                    style: {
                        width: 150,
                    },
                    className: 'mt24 mb24',
                },
                { component: 'PointExchangeRuleTable' },
            ],
        },
        PointExchangeRuleFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                },
            ],
        },
        PointExchangeRuleTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'IntegralDescriptionDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'subsiteName',
                            displayConfig: {
                                countSuffix: '<<subsites>>',
                                style: {
                                    maxWidth: '200px',
                                },
                            },
                        },
                        {
                            property: 'pointUnitDesc',
                            displayConfig: {
                                className: 'point-exchange-rule-desc',
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        items: [
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                path: '/point-exchange-rule/edit/{{row.id}}',
                            },
                            {
                                type: 'delete',
                                text: '<<common.delete>>',
                                statusKey: 'row.canDelete',
                                config: {
                                    type: 'link',
                                    id: 'row.id',
                                    tooltip: null,
                                    title: '<<confirmDelete>>',
                                    okText: '<<common.ok>>',
                                    cancelText: '<<common.cancel>>',
                                },
                            },
                        ],
                    },
                },
            ],
        },
        PointExchangeRuleAddPage: {
            component: 'Card',
            content: { component: 'PointExchangeRuleAddForm' },
        },
        PointExchangeRuleAddForm: {
            component: 'CreateFormPlus',
            entity: 'PointExchangeRuleEntity',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 16,
            style: { width: 900 },
            fields: [
                {
                    property: 'subsites',
                },
                { property: 'deductionUnit' },
                { property: 'minUse' },
                { property: 'maxUse' },
                { property: 'description' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        PointExchangeRuleEditPage: {
            component: 'Card',
            content: { component: 'PointExchangeRuleEditForm' },
        },
        PointExchangeRuleEditForm: {
            component: 'ModifyFormPlus',
            entity: 'PointExchangeRuleEntity',
            direction: 'horizontal',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 16,
            style: { width: 900 },
            fields: [
                {
                    property: 'subsites',
                },
                { property: 'deductionUnit' },
                { property: 'minUse' },
                { property: 'maxUse' },
                { property: 'description' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/point-exchange-rule',
                    component: 'PointExchangeRuleView',
                    breadcrumbName: '<<pointExchangeBalanceCouponRule>>',
                    privilege: {
                        path: 'pointExchangeBalanceCouponRule',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'PointExchangeRuleAddPage',
                            breadcrumbName: '<<common.add>>',
                        },

                        {
                            path: '/edit/:id',
                            component: 'PointExchangeRuleEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },

                        { path: '/', component: 'PointExchangeRulePage' },
                    ],
                },
            ],
        },
    ],
};
