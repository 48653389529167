import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { get } from 'lodash';
const api = services.api;

const systemTags = ['administrator', 'tenant_administrator', 'predefined'];

export const NewRoleLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiPath = '/roles';

        const { id } = data;
        if (!id) {
            if (data.status === 'ALL') {
                delete data.status;
            }
            return await api.get(data, config).then((res: any) => {
                res.result.forEach((item: any) => {
                    delete item.children;
                    const roleTypeData = item.extendAttributes.find(
                        (i: any) => i.attributeCode === 'role_type'
                    );
                    const roleType = get(roleTypeData, 'attributeValue');

                    item.canDelete = !systemTags.includes(roleType); //  判断是否可删除
                    // 是否可禁用,
                    item.disable = !['administrator', 'tenant_administrator'].includes(roleType);
                });
                return res;
            });
        } else {
            config.apiPath = `/roles/${id}`;
            return await api.get({}, config).then(async (res: any) => {
                res.baseInfo = {
                    name: res.name,
                    remark: res.remark,
                    sequence: res.sequence,
                };
                res.parentPath = res.parentPath || '';
                const parentIdsRequest = res.parentPath
                    .split(',')
                    .filter((i: any) => i && i !== '0') // 0 是 系统管理员
                    .map((i: string) => api.get({}, { ...config, apiPath: `/roles/${i}` }));

                try {
                    // 查询角色有的权限
                    const privilege: string[] = await api.get(
                        {},
                        { ...config, apiPath: `/roles/${id}/privilege_set` }
                    );
                    const parentDetail = await Promise.all(parentIdsRequest);
                    res.baseInfo.parentName = parentDetail.map((i: any) => i.name).join('>');
                    res.authInfo = {
                        privilegeSetIds: privilege.map((i: any) => i.privilegeSetId),
                    };
                } catch (error) {
                    console.log(error);
                }
                return res;
            });
        }
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiPath = `/roles`;
        const newParams = {
            name: params.baseInfo.name,
            parentId: params.parentId,
            privilegeSetIds: params.authInfo.privilegeSetIds || [],
            remark: params.baseInfo.remark,
            // sequence: params.baseInfo.sequence,
            sequence: 0,
            extendAttributes: [{ attributeCode: 'role_type', attributeValue: 'custom' }],
        };
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        try {
            const { id } = params;
            config.apiPath = `/roles/${id}`;
            const newParams = {
                name: params.baseInfo.name,
                privilegeSetIds: params.authInfo.privilegeSetIds || [],
                remark: params.baseInfo.remark,
                // sequence: params.baseInfo.sequence,
                sequence: 0,
                extendAttributes: params.extendAttributes,
            };
            return await api.put(newParams, config);
        } catch (error) {
            console.error(error);
        }
    },
    delete: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiPath = `/roles/${id}`;
        return await api.delete(params, config);
    },
};
