import React, { PureComponent } from 'react';
import { Table, Button } from 'antd';
import cloneDeep from 'lodash/cloneDeep';
import remove from 'lodash/remove';
import { PaginationConfig } from 'antd/lib/table/interface';
import { GoodsActivity } from '..';
import { isEmpty } from 'lodash';
import { services } from '@comall-backend-builder/core';

interface GoodsActivityDisplayProps {
    data: Array<GoodsActivity> | any;
    onRemove: (data: Array<GoodsActivity>) => void;
    disabled?: boolean;
}
interface GoodsActivityDisplayState {
    selectedRows: Array<GoodsActivity>;
}

class GoodsDisplay extends PureComponent<GoodsActivityDisplayProps, GoodsActivityDisplayState> {
    getTableConfig = () => {
        const { disabled } = this.props;
        let tableConfig: any = {
            rowKey: 'saleRuleGoodsId',
            columns: [
                {
                    title: services.language.getText('components.Products.name'),
                    dataIndex: 'saleRuleGoodsName',
                    key: 'saleRuleGoodsName',
                },
                {
                    title: '标签名称',
                    dataIndex: 'tagName',
                    key: 'tagName',
                },
                {
                    title: services.language.getText('subsiteName'),
                    dataIndex: 'subsite.name',
                    key: 'subsite.name',
                },
                {
                    title: services.language.getText('common.tableAction'),
                    className: 'action-column',
                    key: 'operation',
                    width: 100,
                    render: (i: any, row: any) => {
                        return !disabled ? (
                            <Button
                                type={'link'}
                                onClick={this.onRemove(row)}
                                style={{ padding: '0' }}
                            >
                                {services.language.getText('common.delete')}
                            </Button>
                        ) : (
                            <Button
                                type={'link'}
                                onClick={this.onRemove(row)}
                                style={{ padding: '0' }}
                                disabled
                            >
                                {services.language.getText('common.delete')}
                            </Button>
                        );
                    },
                },
            ],
            style: {
                marginTop: 20,
            },
        };
        return tableConfig;
    };

    onRemove = (row: GoodsActivity) => {
        const { data, onRemove } = this.props;

        return () => {
            const result = cloneDeep(data);
            remove(result, (coupon: any) => {
                return row.saleRuleGoodsId === coupon.saleRuleGoodsId;
            });
            onRemove(result);
        };
    };

    render() {
        const { data } = this.props;
        const pagination = false as PaginationConfig | false;
        let tableConfig = this.getTableConfig();
        data &&
            data.length > 0 &&
            data.forEach((item: any) => {
                if (!item.tagName || isEmpty(item.tagName)) {
                    item.tagName = services.language.getText('wbdbq');
                }
            });
        const tableProps = {
            ...tableConfig,
            dataSource: data,
            pagination,
        };

        return <Table {...tableProps}></Table>;
    }
}

export { GoodsDisplay };
