import { Config } from '@comall-backend-builder/core/lib/parser';
import { EntityButtonProps } from '../../../components';
import { ForwardDataCenterModal } from '../../../services';
import { services } from '@comall-backend-builder/core';
import { api } from '@comall-backend-builder/core/lib/services';
import { isEmpty } from 'lodash';

export const config: Config = {
    entities: {
        DeliveryPackageEntity: {
            apiPath: '/loader/admin/delivery-package',
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                merchantId: {
                    type: 'string.filterOptions.autoComplete',
                    displayName: '<<merchantName>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                        //专柜默认正序排序
                        params: {
                            sequence: 'code',
                            order: 'asc',
                        },
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                expressNumber: {
                    type: 'string',
                    displayName: '<<ydh>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<ydsj>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<ydzt_1>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<selectPlease>>' },
                        { id: 'true', name: '<<success>>' },
                        { id: 'false', name: '<<common.cancel>>' },
                    ],
                },
                companyName: {
                    type: 'string',
                    displayName: '<<wlgs>>',
                },
                companyAccount: {
                    type: 'string',
                    displayName: '<<wlzh>>',
                },
                logisticsBillGenerationMethod: {
                    type: 'string.options.select',
                    displayName: '<<ydscfs>>',
                    options: [
                        { id: 'AUTOMATIC', name: '<<zdjd>>' },
                        { id: 'MANUAL', name: '<<sdtd>>' },
                    ],
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                deliveryLogisticsBillId: {
                    type: 'number.integer',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                expressNumber: {
                    type: 'string',
                    displayName: '<<ydh>>',
                },
                logisticsBillGenerationMethod: {
                    type: 'string.options.select',
                    displayName: '<<ydscfs>>',
                    options: [
                        { id: 'AUTOMATIC', name: '<<zdjd>>' },
                        { id: 'MANUAL', name: '<<sdtd>>' },
                    ],
                },
                companyName: {
                    type: 'string',
                    displayName: '<<wlgs>>',
                },
                companyAccount: {
                    type: 'string',
                    displayName: '<<wlzh>>',
                },
                packageSequenceName: {
                    type: 'string',
                    displayName: '<<bg>>',
                },
                insuredFee: {
                    type: 'string',
                    displayName: '<<bjje>>',
                },
                orders: {
                    type: 'array.orders.popover',
                    displayName: '<<orderNumber>>',
                },
                ordersMerchant: {
                    type: 'array.popover.list',
                    displayName: '<<ddzj>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<scsj_1>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<ydzt_1>>',
                    defaultValue: 'true',
                    options: [
                        { id: 'true', name: '<<success>>' },
                        { id: 'false', name: '<<common.cancel>>' },
                        { id: 'disabled', name: '<<success>>' },
                    ],
                },
            },
        },
    },
    components: {
        DeliveryPackageView: {
            component: 'Viewport',
        },
        DeliveryPackagePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'DeliveryPackageEntity',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'DeliveryPackageFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'EntityButton',
                                    props: {
                                        type: 'primary',
                                        children: '<<exportSearchResult>>',
                                        onClick: async (
                                            _: any,
                                            entityButtonProps: EntityButtonProps
                                        ): Promise<void> => {
                                            try {
                                                const params: Record<string, any> = Object.assign(
                                                    {},
                                                    entityButtonProps.entity.params,
                                                    entityButtonProps.entity.filters
                                                );
                                                if (params?.subsiteId?.id) {
                                                    params.subsiteId = params.subsiteId.id;
                                                }
                                                if (params?.merchantId?.id) {
                                                    params.merchantId = params.merchantId.id;
                                                }
                                                if (params.status === 'ALL') {
                                                    delete params.status;
                                                }
                                                if (!isEmpty(params.dateRange)) {
                                                    params.startTime =
                                                        params.dateRange.start + ' 00:00:00';
                                                    params.endTime =
                                                        params.dateRange.end + ' 23:59:59';
                                                    delete params.dateRange;
                                                }
                                                const config = {
                                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                                    apiPath:
                                                        '/admin/delivery_packages/delivery_packages.excel',
                                                };
                                                await api.get(params, config);
                                                ForwardDataCenterModal();
                                            } catch (err) {
                                                services.errorHandle(err);
                                            }
                                        },
                                    },
                                },
                            ],
                        },
                    ],
                },
                { component: 'DeliveryPackageTable' },
            ],
        },
        DeliveryPackageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'expressNumber',
                },
                {
                    property: 'orderNo',
                },
                {
                    property: 'dateRange',
                },
                {
                    property: 'status',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'companyName',
                },
                {
                    property: 'companyAccount',
                },
                {
                    property: 'logisticsBillGenerationMethod',
                },
            ],
        },
        DeliveryPackageTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'mt24',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'DeliveryPackageTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: {
                        x: true,
                    },
                    columns: [
                        { property: 'subsiteName' },
                        { property: 'expressNumber' },
                        { property: 'logisticsBillGenerationMethod' },
                        { property: 'companyName' },
                        { property: 'companyAccount' },
                        { property: 'packageSequenceName' },
                        { property: 'insuredFee' },
                        { property: 'orders' },
                        { property: 'ordersMerchant' },
                        { property: 'createTime' },
                        { property: 'status' },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        width: 200,
                        fixed: 'right',
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    statusKey: 'status',
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex', marginRight: 0 },
                                    handles: [
                                        {
                                            value: 'disabled',
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                            apiPath: '/admin/delivery_logistics_bills/:id',
                                            config: {
                                                content: '<<ydyqx>>',
                                                text: '<<qxjd>>',
                                                disabled: true,
                                            },
                                            confirm: {
                                                text: '<<sfqxyd>>',
                                                className: 'confirm-content',
                                            },
                                        },
                                        {
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                            apiPath: '/admin/delivery_logistics_bills/:id',
                                            loadType: 'delete',
                                            config: {
                                                content: '<<ydyqx>>',
                                                text: '<<qxjd>>',
                                            },
                                            confirm: {
                                                text: '<<sfqxyd>>',
                                                className: 'confirm-content',
                                            },
                                        },
                                        {
                                            value: false,
                                            config: {
                                                text: '',
                                                disabled: true,
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'component',
                                component: 'DeliveryPackageButton',
                            },
                        ],
                    },
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/delivery-package',
                    component: 'DeliveryPackageView',
                    breadcrumbName: '<<deliveryPackagesLogisticsBill>>',
                    privilege: {
                        path: 'deliveryPackagesLogisticsBill',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'DeliveryPackagePage' }],
                },
            ],
        },
    ],
};
