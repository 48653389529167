import React, { PureComponent } from 'react';
import { Input, Button, Popconfirm, message as AntMessage, Modal, Table, Form } from 'antd';
import { services } from '@comall-backend-builder/core';
import PropTypes from 'prop-types';
import { map } from 'lodash';
import './index.less';

const { getText } = services.language;

interface LogoutAgreementProps {
    name: string;
    onChange: (value: LogoutAgreementValue, name: string) => void;
    value?: LogoutAgreementValue;
}

interface LogoutAgreementValue {
    agreementPrefix: string;
    agreementSuffix: string;
    id: string;
    name: string;
}

export interface PrivacyAgreement {
    id: string;
    name: string;
    positionName: string;
}

const defaultValue = {
    agreementPrefix: '',
    agreementSuffix: '',
    id: '',
    name: '',
};

export class LogoutAgreement extends PureComponent<LogoutAgreementProps, any> {
    state = {
        searchParams: {},
        pagination: {
            page: 1,
            perPage: 10,
        },
        selectedRows: new Array<PrivacyAgreement>(),
        showModal: false,
        datasource: [],
        total: 0,
    };
    tableConfig = {
        rowKey: 'id',
        columns: [
            {
                title: getText('ywlx'),
                dataIndex: 'businessTypeStr',
                key: 'businessTypeStr',
            },
            {
                title: getText('wzbt'),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: getText('status'),
                dataIndex: 'status',
                key: 'status',
                render: (status: number) => {
                    return (
                        <span className="goods-field-name">
                            {status === 1 ? getText('beforeOpen') : getText('beforeClose')}
                        </span>
                    );
                },
            },
            {
                title: getText('cjrq'),
                dataIndex: 'createTime',
                key: 'createTime',
            },
        ],
    };
    private onAgreementPrefixChange = (e: any) => {
        let { name, value = defaultValue, onChange } = this.props;
        const agreementPrefix = e.target.value;
        onChange({ ...value, agreementPrefix }, name);
    };
    private onAgreementSuffixChange = (e: any) => {
        let { name, value = defaultValue, onChange } = this.props;
        const agreementSuffix = e.target.value;
        onChange({ ...value, agreementSuffix }, name);
    };
    private deleteProtocol = () => {
        let { name, value = defaultValue, onChange } = this.props;
        onChange({ ...value, id: '', name: '' }, name);
    };
    private openModal = () => {
        this.setState({ showModal: true }, () => {
            this.onSearch();
        });
    };
    onSearch = async (searchParams: object = {}) => {
        this.setState({ searchParams: searchParams });
        const { pagination } = this.state;
        try {
            let res: any = await services.api.get(
                { ...searchParams, ...pagination },
                {
                    apiPath: '/admin/logout_agreement',
                    apiRoot: ENV.API_ROOT,
                }
            );
            this.setState({ datasource: res.result, total: res.totalNum });
        } catch (error) {
            throw error;
        }
    };
    closeModal = () => {
        this.setState({ showModal: false });
    };
    toggleModal = () => {
        const { showModal, selectedRows } = this.state;

        const { name, value = defaultValue, onChange } = this.props;
        this.setState({ showModal: !showModal }, () => {
            if (selectedRows && selectedRows.length > 0) {
                const [item] = selectedRows;
                onChange(
                    {
                        ...value,
                        id: item.id,
                        name: item.name,
                    },
                    name
                );
            } else {
                AntMessage.warning(getText('qxzxy'));
            }
        });
    };
    onTableRowSelect = (record: PrivacyAgreement) => {
        const rows = [];
        rows.push(record);
        this.setState({ selectedRows: rows });
    };
    pageChange = (pageVal: object = {}) => {
        const { searchParams } = this.state;
        this.setState({ pagination: { ...pageVal } }, () => {
            this.onSearch(searchParams);
        });
    };
    render() {
        const { value = defaultValue } = this.props;
        const { showModal, selectedRows, datasource, total } = this.state;
        return (
            <div>
                <div className="logout-agreement">
                    <div className="rule-content">
                        <div className="rule-count-input">
                            <Input
                                style={{
                                    width: '400px',
                                }}
                                value={value?.agreementPrefix}
                                placeholder={services.language.getText('inputPlease')}
                                onChange={this.onAgreementPrefixChange}
                            />
                        </div>

                        <div className="rule-count-input">
                            {value?.name ? (
                                <Popconfirm
                                    onConfirm={this.deleteProtocol}
                                    title={getText('sfscgxy')}
                                    okText={services.language.getText('common.ok')}
                                    cancelText={services.language.getText('common.cancel')}
                                >
                                    <div className="add-rule-close">
                                        {value.name.length > 6
                                            ? value.name.substring(0, 6) + '...'
                                            : value.name}
                                        &nbsp;x
                                    </div>
                                </Popconfirm>
                            ) : (
                                <div className="rule-button">
                                    <Button type="link" onClick={this.openModal}>
                                        {getText('tjxy')}
                                    </Button>
                                </div>
                            )}
                        </div>
                        <div className="rule-count-input">
                            <Input
                                style={{
                                    width: '400px',
                                }}
                                value={value?.agreementSuffix}
                                placeholder={services.language.getText('inputPlease')}
                                onChange={this.onAgreementSuffixChange}
                            />
                        </div>
                    </div>
                </div>
                <Modal
                    onCancel={this.closeModal}
                    footer={[
                        <Button type="primary" onClick={this.toggleModal}>
                            {getText('common.ok')}
                        </Button>,
                    ]}
                    visible={showModal}
                    title={getText('xy_1')}
                    width={900}
                >
                    <div className="clerk-search">
                        <CommonCreateForm onSearch={this.onSearch} />
                    </div>
                    <Table
                        rowSelection={{
                            type: 'radio',
                            selectedRowKeys: map(selectedRows, 'id'),
                            onSelect: (record: PrivacyAgreement) => {
                                this.onTableRowSelect(record);
                            },
                        }}
                        dataSource={datasource}
                        pagination={{
                            pageSize: 10,
                            total,
                            onChange: (page, perPage) => {
                                this.pageChange({ page, perPage });
                            },
                        }}
                        {...this.tableConfig}
                    ></Table>
                </Modal>
            </div>
        );
    }
}

const CommonCreateForm = Form.create({ name: 'commonForm' })(
    class extends React.Component {
        static propTypes: {
            onSearch: PropTypes.Requireable<(...args: any[]) => any>;
        };
        handleReset = () => {
            const { form } = this.props as any;
            form.resetFields();
        };
        handleSearch = (e: any) => {
            e.preventDefault();
            const { form, onSearch } = this.props as any;
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSearch(values);
                }
            });
        };
        render() {
            const { form } = this.props as any;
            const { getFieldDecorator } = form;
            return (
                <Form layout="inline" ref="commonForm">
                    <Form.Item label={getText('wzmc_1')}>
                        {getFieldDecorator('name')(
                            <Input placeholder={getText('wzmcInputPlease')} />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.handleSearch}>
                            {getText('cx')}
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="reset" onClick={this.handleReset}>
                            {getText('common.reset')}
                        </Button>
                    </Form.Item>
                </Form>
            );
        }
    }
);
