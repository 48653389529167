import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import get from 'lodash/get';
const api = services.api;
export const SocialIdentityTagPermissionsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/SHOW`;
        if (!data.id) {
            config.apiRoot = `${ENV.AUTH_API_ROOT}/SHOW`;
            config.apiPath = '/admin/social_identity_tag_functions';

            if (!data.name) delete data.name;
            return await api.get(data, config);
        } else {
            config.apiPath = `/admin/social_identity_tag_functions/${data.id}`;
            const res: any = await api.get(data, config);
            const result = {
                tagId: get(res, 'tagId'),
                functionResourceIds: res.functionResourceIds.map((i: any) => i + ''),
                dataResourceIds: res.dataResourceIds.map((i: any) => i + ''),
            };
            return result;
        }
    },
    post: async function(params: any, config: any) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/SHOW`;
        config.apiPath = `/admin/social_identity_tag_functions`;
        const { functionResourceIds, dataResourceIds, tagId } = params;
        const data: any = {
            functionResourceIds,
            dataResourceIds,
            tagId,
        };

        return await api.post(data, config);
    },
    put: async function(params: any, config: any) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/SHOW`;
        config.apiPath = `/admin/social_identity_tag_functions/${params.id}`;
        const { functionResourceIds, dataResourceIds } = params;
        const data: any = {
            functionResourceIds,
            dataResourceIds,
        };
        return await api.put(data, config);
    },
    delete: async function(params: any, config: any) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/SHOW`;
        config.apiPath = `/admin/social_identity_tag_functions/${params.id}`;
        return await api.delete(params, config);
    },
};
