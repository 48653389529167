import React, { Component } from 'react';
import { Checkbox, Button, Modal, Row, Col, message } from 'antd';
import { DndProvider as DragDropContext } from 'react-dnd';
import update from 'immutability-helper';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ImageUpload } from '@comall-backend-builder/components-basis';
import { DragSortBox } from '../drag-sort-box';
import { debounce, cloneDeep, set } from 'lodash';
import { services } from '@comall-backend-builder/core';

import { Value, ModuleType, Menu, defaultValue } from './common';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
const api = services.api;

interface Props {
    value: Value;
    onChange: (value: Value) => void;
    row: any;
}

interface State {
    showModal: boolean;
    modalType: ModuleType;
    localValue: Value;
    moduleNames: Record<ModuleType, string>;
    moduleTips: Record<ModuleType, string>;
}

/**
 * 停车页面配置悬浮按钮
 */
export class CarparkButtonsConfig extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        const { value, onChange } = this.props;
        this.state = {
            showModal: false,
            modalType: ModuleType.NavigationButtonGoldExchangePoint,
            localValue: value || defaultValue,
            moduleNames: {
                [ModuleType.NavigationButtonGoldExchangePoint]: '',
            },
            moduleTips: {
                [ModuleType.NavigationButtonGoldExchangePoint]: '',
            },
        };
        if (!value) {
            onChange(defaultValue);
        }
    }

    componentDidMount() {
        this.getGoldAlias();
    }
    getGoldAlias = async () => {
        const res: any = await api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                apiPath: '/admin/gold_exchange_rules',
            }
        );
        const { goldAlias } = res;
        this.setState({
            moduleNames: {
                [ModuleType.NavigationButtonGoldExchangePoint]: `${goldAlias}${language.getText(
                    'djf'
                )}`,
            },
            moduleTips: {
                [ModuleType.NavigationButtonGoldExchangePoint]: `${language.getText(
                    'pz'
                )}${goldAlias}${language.getText('djfqqxqrsfyktcyw')}`,
            },
        });
    };

    onValueChange = (newValue: Value) => {
        const { onChange } = this.props;
        onChange(newValue);
    };

    onItemCheck = (index: number) => {
        const { value } = this.props;
        const newValue = cloneDeep(value);
        newValue[index].visible = !newValue[index].visible;
        this.onValueChange(newValue);
    };

    onChangePosition = debounce((hoverItem: any, dragItem: any) => {
        const { value } = this.props;
        const newValue = update(value, {
            $splice: [
                [dragItem.index, 1],
                [hoverItem.index, 0, value[dragItem.index]],
            ],
        });
        this.onValueChange(newValue);
    }, 100);

    getModuleValue = (moduleType: ModuleType) => {
        const { localValue } = this.state;
        return localValue?.find((item) => item.moduleType === moduleType);
    };
    onLocalValueChange = (path: string, value: any, moduleType: ModuleType) => {
        const { localValue } = this.state;
        const moduleValue = cloneDeep(this.getModuleValue(moduleType));
        if (!moduleValue) {
            return;
        }
        set(moduleValue, path, value);
        this.setState({
            localValue: localValue.map((i) => (i.moduleType === moduleType ? moduleValue : i)),
        });
    };
    onPictrueChange = (picture: any, moduleType: ModuleType) => {
        this.onLocalValueChange(
            'picture',
            picture?.length ? picture[0].path : undefined,
            moduleType
        );
        return {};
    };
    renderCommonModalContent = (moduleType: ModuleType) => {
        return <ModalRow label="图标">{this.renderImageUpload(moduleType)}</ModalRow>;
    };

    renderImageUpload = (moduleType: ModuleType) => {
        let imageControlInfo = {
            name: 'picture',
            value: this.getModuleValue(moduleType)?.picture,
            accept: 'image/*',
            maxCount: 1,
            multiple: false,
            fileName: 'fileName',
            uploadType: 'single',
            uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
            mode: 'picture-card',
            maxSize: 1 * 1024,
            preview: {
                size: 500,
            },
            uploadLimitSizeErrorMessage:
                services.language.getText('pictures') +
                '{{file.name}}' +
                services.language.getText('pictureUploadLimit1'),
            limitTips: {
                style: {
                    display: 'none',
                },
            },
        };
        const onChange = (value: any, name: any) => this.onPictrueChange(value, moduleType);

        return <ImageUpload {...imageControlInfo} onChange={onChange} />;
    };

    modalContent = {
        [ModuleType.NavigationButtonGoldExchangePoint]: this.renderCommonModalContent,
    };
    renderModalContent = () => {
        const { modalType } = this.state;
        return this.modalContent[modalType](modalType);
    };
    showModal = (modalType: ModuleType) => {
        const { value } = this.props;
        this.setState({ showModal: true, modalType, localValue: cloneDeep(value) });
    };
    closeModal = () => {
        this.setState({ showModal: false });
    };
    onModalConfirm = () => {
        const { modalType, localValue } = this.state;
        const moduleValue = this.getModuleValue(modalType);
        if (!moduleValue?.picture) {
            message.warn(language.getText('qsctb'));
            return;
        }
        this.onValueChange(localValue);
        this.closeModal();
    };

    renderModal = () => {
        const { showModal, modalType } = this.state;
        const { moduleNames } = this.state;
        return (
            <Modal
                title={moduleNames[modalType]}
                onCancel={this.closeModal}
                onOk={this.onModalConfirm}
                visible={showModal}
            >
                {this.renderModalContent()}
            </Modal>
        );
    };

    renderItem = (item: Menu<any>, index: number) => {
        const { moduleType, visible } = item;
        const { moduleNames, moduleTips } = this.state;
        const name = moduleNames[moduleType];
        const tips = moduleTips[moduleType];
        const showModal = () => this.showModal(moduleType);
        return (
            <div className="carpark-buttons-config__item">
                <div className="carpark-buttons-config__item__content">
                    <Checkbox checked={visible} onChange={() => this.onItemCheck(index)} />
                    <span className="carpark-buttons-config__item__name">{name}</span>
                    <Button
                        onClick={showModal}
                        className="carpark-buttons-config__item__setting"
                        type="link"
                    >
                        {language.getText('settings')}
                    </Button>
                </div>
                {Boolean(tips) && <div className="carpark-buttons-config__item__tips">{tips}</div>}
            </div>
        );
    };

    render() {
        const { value = defaultValue } = this.props;
        return (
            <DragDropContext backend={HTML5Backend}>
                <div className="carpark-buttons-config">
                    {value.map((item, index) => (
                        <DragSortBox
                            type="CARPARK_BUTTON"
                            data={{ index }}
                            hover={this.onChangePosition}
                        >
                            {this.renderItem(item, index)}
                        </DragSortBox>
                    ))}
                </div>
                {this.renderModal()}
            </DragDropContext>
        );
    }
}

class ModalRow extends Component<{ label: string; hidden?: boolean }> {
    render() {
        const { label, hidden, children } = this.props;
        return (
            <Row hidden={hidden} className="carpark-buttons-config__modal__row">
                <Col span={6}>{label}：</Col>
                <Col span={18}>{children}</Col>
            </Row>
        );
    }
}
