import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;

export const ExperienceRuleELoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-CAPITAL-RULE`;
        config.apiPath = '/admin/experience_rules';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/experience_rules/${id}`;
        }
        const subsiteId = data.subsiteId;
        if (subsiteId) {
            data.subsiteId = subsiteId.id ? subsiteId.id : subsiteId;
        }
        return await api.get(data, config).then((res: any) => {
            if (!id) {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        item.subsiteName = item.subsites;
                        return item;
                    });
            } else {
                res.doubleRuleParticipateCardinalityCalculate = res.doubleRuleParticipateCardinalityCalculate
                    ? 'true'
                    : 'false';
            }
            return res;
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-CAPITAL-RULE`;
        config.apiPath = `/admin/experience_rules/${id}`;
        const newParams = dataParser(params);
        newParams.id = id;
        return await api.put(newParams, config);
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-CAPITAL-RULE`;
        config.apiPath = `/admin/experience_rules`;
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
    delete: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-CAPITAL-RULE`;
        config.apiPath = `/admin/experience_rules/${id}`;
        const newParams = dataParser(params);
        newParams.id = id;
        return await api.delete(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    if (!isEmpty(params.subsites)) {
        params.subsiteIds = params.subsites.map((subsite: any) => {
            return subsite.id;
        });
        delete params.subsites;
    }

    return params;
}
