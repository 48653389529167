import React, { PureComponent } from 'react';
import { cloneDeep, map, findIndex, isEqual } from 'lodash';
import { CouponSelectorWithPackageDisplay, CouponSelectorWithPackage } from '../../components';

interface Coupon {
    id: string;
    // type: string;
    name: number;
    startTime: string;
    endTime: string;
    quantity?: number;
    /**
     * 被选中的优惠券
     */
    select?: boolean;
}

export class SelectCouponWithPackage extends PureComponent<
    any,
    {
        value: Array<Coupon>;
    }
> {
    static defaultProps = {
        type: 'radio',
    };

    state = { value: new Array<Coupon>() };

    componentWillReceiveProps(nextProps: any) {
        const { clearValueOnSubsiteChange } = nextProps;
        if (!clearValueOnSubsiteChange) {
            return;
        }
        const preSubsiteIds = this.getSubsiteIds(this.props);
        const nextSubsiteIds = this.getSubsiteIds(nextProps);
        if (preSubsiteIds && preSubsiteIds.length && !isEqual(preSubsiteIds, nextSubsiteIds)) {
            this.onChange([]);
        }
    }

    getSubsiteIds = (props: any) => {
        const { row, getSubsiteIds } = props;
        if (typeof getSubsiteIds === 'function') {
            return getSubsiteIds(props);
        }
        const subsiteIds =
            row.baseInfo && row.baseInfo.subsites ? map(row.baseInfo.subsites, 'id') : null;
        return subsiteIds;
    };

    /**
     * 选择优惠券
     * @param selectValues
     */
    onChange = (selectValues: Coupon[]) => {
        const { onChange } = this.props;
        if (onChange) {
            if (selectValues && selectValues.length === 0) {
                onChange(undefined);
            } else {
                onChange(selectValues);
            }
        }
    };

    /**
     * 添加分站弹窗确认后回调
     * @param selectValues 选中分站
     */
    onSelect = (selectValues: Coupon[]) => {
        const { value, canChangeQuantity, type } = this.props;
        let newData: Coupon[] = [];
        if (value) {
            newData = cloneDeep(value);
        }
        if (selectValues) {
            if (type === 'radio') {
                newData = selectValues;
            } else {
                newData = [];
                selectValues.forEach((selectCuopon: any) => {
                    const hasIndex = findIndex(
                        newData,
                        (coupon: any) => coupon.id === selectCuopon.id
                    );
                    if (hasIndex === -1) {
                        newData.push(selectCuopon);
                    }
                });
            }
        }
        if (canChangeQuantity && typeof canChangeQuantity === 'boolean') {
            newData = newData.map((item: any) => {
                return {
                    ...item,
                    quantity: 1,
                };
            });
        }
        this.onChange(newData);
    };

    render() {
        const {
            value,
            disabled,
            packageSelectType,
            disablePrice,
            canChangeQuantity,
            canChangeQuantityTitle,
            type,
            checkSubsiteOnSelect,
        } = this.props;
        const hasValue = value && value.length > 0;
        const subsiteIds = this.getSubsiteIds(this.props);

        return (
            <div>
                {!disabled && (
                    <CouponSelectorWithPackage
                        onChange={this.onSelect}
                        selectValues={value}
                        type={type}
                        packageSelectType={packageSelectType || type}
                        params={{ subsiteIds: subsiteIds }}
                        checkSubsiteOnSelect={checkSubsiteOnSelect}
                    />
                )}
                {hasValue && (
                    <CouponSelectorWithPackageDisplay
                        canChangeQuantity={
                            typeof canChangeQuantity === 'boolean' ? canChangeQuantity : false
                        }
                        canChangeQuantityTitle={canChangeQuantityTitle}
                        disablePrice={disablePrice}
                        data={value}
                        onChange={this.onChange}
                        disabled={disabled}
                        xScrollWidth={640}
                        checkSubsiteOnSelect={checkSubsiteOnSelect}
                    />
                )}
            </div>
        );
    }
}
