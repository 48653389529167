export const password_validity_period = [
    {
        id: 'PERMANENT',
        name: '长期',
    },
    {
        id: 'FIFTEEN_DAYS',
        name: '15天',
    },
    {
        id: 'A_MONTH',
        name: '1个月',
    },
    {
        id: 'THREE_MONTHS',
        name: '3个月',
    },
    {
        id: 'SIX_MONTHS',
        name: '6个月',
    },
    {
        id: 'A_YEAR',
        name: '1年',
    },
];
