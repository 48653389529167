import React, { Component } from 'react';
import { InputNumber, Select, Button, message } from 'antd';
import { find, clone, isEqual, map, concat, isEmpty } from 'lodash';

import './index.less';
import { services } from '@comall-backend-builder/core';

type RulesValue = {
    /**
     * 会员等级id
     */
    userLevelId?: string;
    /**
     * 限购件数
     */
    ruleCount?: number;
};

export interface MemberLevelRulesProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Array<RulesValue> | null, name: string) => void;
    /**
     * 当前值
     */
    value: Array<RulesValue> | null;
    /**
     * 候选项集合
     */
    options: Array<any>;
    typeTitle?: string;
    defaultValue?: string;
}
export class MemberLevelRules extends Component<MemberLevelRulesProps> {
    onUserLevelIdChange(userLevelId: string, index: number) {
        let { name, value, onChange } = this.props;

        if (value !== null && !isEmpty(value)) {
            let userRule = find(value, { userLevelId });
            if (userRule) {
                message.warning(services.language.getText('userRuleLimit'));
                return;
            }
            let item = clone(value[index]);
            item.userLevelId = userLevelId;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            if (isEmpty(userLevelId)) {
                message.warning(services.language.getText('qxzyhxglx'));
                return;
            }
            let item: RulesValue = {};
            item.userLevelId = userLevelId;
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onRuleCountChange(event: any, index: number) {
        let { name, value, onChange } = this.props;
        let ruleCount = event;
        if (!isEmpty(ruleCount) && ruleCount < 1) {
            ruleCount = 1;
        }
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.ruleCount = ruleCount;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            if (isEmpty(ruleCount)) {
                message.warning('请输入最多购买件数');
                return;
            }
            let item: RulesValue = {};
            item.ruleCount = ruleCount;
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onAddRule() {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(value.length, 0, {});
        }

        if (onChange) {
            onChange(value, name);
        }
    }

    onRemoveRule(index: number) {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(index, 1);
            if (!value.length) {
                value = null;
            }
        }

        if (onChange) {
            onChange(value, name);
        }
    }

    shouldComponentUpdate(nextProps: MemberLevelRulesProps) {
        return !isEqual(this.props, nextProps);
    }

    render() {
        let { value, typeTitle } = this.props;

        return (
            <div className="member-level-rules">
                {typeTitle ? <div>{typeTitle}</div> : ''}
                {map(value, (rule, index) => this.renderRule(rule, index))}
                {value && value.length ? null : this.renderRule({}, 0)}
                <Button type="default" className="add-rule-button" onClick={() => this.onAddRule()}>
                    添加会员等级
                </Button>
            </div>
        );
    }

    renderRule(rule: RulesValue, index: number) {
        const { options, defaultValue } = this.props;
        const selectOption = options.filter(
            (option) => Number(option.id) === Number(rule.userLevelId)
        );
        const ruleLevelName = selectOption && selectOption.length > 0 && selectOption[0].name;

        return (
            <div className="rule" key={index}>
                <div className="rule-content">
                    <span className="item-text">会员等级限购：</span>
                    <Select
                        className="rule-type-selection"
                        placeholder={services.language.getText('selectPlease')}
                        value={ruleLevelName || defaultValue}
                        onChange={(userLevelId: any) =>
                            this.onUserLevelIdChange(userLevelId, index)
                        }
                    >
                        {map(options, ({ id, name }) => (
                            <Select.Option key={id}>{name}</Select.Option>
                        ))}
                    </Select>
                    <span className="item-text">最多购买</span>
                    <div className="rule-count-input">
                        <InputNumber
                            type="number"
                            min={1}
                            value={rule.ruleCount}
                            onChange={(e) => this.onRuleCountChange(e, index)}
                        />
                    </div>
                    <span className="item-text">件</span>
                    {!isEmpty(rule) && (
                        <Button
                            type="default"
                            className="remove-rule-button"
                            onClick={() => this.onRemoveRule(index)}
                        >
                            {services.language.getText('common.delete')}
                        </Button>
                    )}
                </div>
            </div>
        );
    }
}
