import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { api, ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { map } from 'lodash';

export const AnnualWelfareDescriptionLoader: Loader = {
    async get(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/articles/ANNUAL_WELFARE';
        if (id) {
            config.apiPath = `/admin/articles/ANNUAL_WELFARE/${id}`;
        }
        return api.get(params, config).then((res: any) => {
            if (!id) {
                res.result = res.result.map((item: any) => ({
                    ...item,
                    subsiteName: item.subsites,
                    img: item.imgUrl && [{ path: item.imgUrl, id: item.imgId || '' }],
                }));
            } else {
                res.img = res.imgUrl && [{ path: res.imgUrl, id: res.imgId }];
            }
            return res;
        });
    },
    async post(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/articles/ANNUAL_WELFARE';
        if (params.subsites) {
            params.subsiteIds = map(params.subsites, 'id').toString();
            delete params.subsites;
        }
        if (params.img && params.img.length) {
            const imgUrl = params.img[0].path;
            const imgId = Number(params.img[0].id);
            params.imgUrl = imgUrl;
            params.imgId = imgId;
        }
        return api.post(params, config);
    },
    async put(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/articles/ANNUAL_WELFARE/${id}`;
        if (params.img && params.img.length) {
            const imgUrl = params.img[0].path;
            const imgId = Number(params.img[0].id);
            params.imgUrl = imgUrl;
            params.imgId = imgId;
            delete params.img;
        }
        if (params.subsites) {
            params.subsiteIds = map(params.subsites, 'id').toString();
            delete params.subsites;
        }
        delete params.id;
        return api.put(params, config);
    },
    async delete(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/articles/ANNUAL_WELFARE/${id}`;
        return api.delete(params, config);
    },
};
