import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach, find, isEmpty } from 'lodash';

const api = services.api;

export const ReserveActivitiesLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`;
        config.apiPath = '/admin/reserve_activities';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/reserve_activities/${id}`;
        } else {
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.id = res.activity.id;
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : services.language.getText('longTerm'),
                    };
                }
                res.activityInfo = {
                    executeStatus: res.activity.executeStatus,
                    mode: 'RESERVE',
                    name: res.activity.name,
                    dateRange: {
                        start: res.activity.startTime,
                        end: res.activity.endTime,
                    },
                    reserveType: res.activity.reserveType,
                    description: res.activity.description,
                    subsites: res.subsites,
                    goods: res.goods.map((item: any) => {
                        item.merchantName = item.merchant.name;
                        item.subsiteName = item.subsite.name;
                        item.subsiteId = item.subsite.id;
                        item.goodsDeductPoint = item.pointPrice;
                        return item;
                    }),
                    maxOrderCount: res.limitCondition.maxOrderCount,
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };
                const round = res.rounds[0];
                res.roundInfo = {
                    period: round.period,
                    periodValue: round.periodValue,
                    time: [
                        {
                            start: round.startTime,
                            end: round.endTime,
                        },
                    ],
                    reserveTime: [
                        {
                            start: round.reserveStartTime,
                            end: round.reserveEndTime,
                        },
                    ],
                    memberLimit: round.memberLimit,
                    quantityLimit: round.quantityLimit,
                };

                res.seniorInfo = {
                    participateCondition: [
                        {
                            code: 'MINREGISTERDAYS',
                            checked: res.participateCondition.minRegisterDays ? true : false,
                            value: res.participateCondition.minRegisterDays,
                        },
                        {
                            code: 'CONSUMPTIONT',
                            checked: res.participateCondition.consumptionCount ? true : false,
                            value: res.participateCondition.consumptionCount,
                        },
                        {
                            code: 'CERTIFICATION',
                            checked: res.participateCondition.certification,
                        },
                    ],
                    receiptCertification: res.participateCondition.receiptCertification
                        ? ['NEED']
                        : [],
                };
            } else {
                if (!id) {
                    res.result =
                        res &&
                        res.result.map((item: any) => {
                            item.id = item.activity.id;
                            item.mode = item.activity.mode;
                            item.name = item.activity.name;
                            item.startTime = item.activity.startTime;
                            item.endTime = item.activity.endTime;
                            item.executeStatus = item.activity.executeStatus;
                            item.status = item.activity.status;
                            return item;
                        });
                }
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`;
        config.apiPath = '/admin/reserve_activities';
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`;
        config.apiPath = `/admin/reserve_activities/${id}`;

        const newParams = dataParser(params);
        newParams.id = id;
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    //活动设置
    if (params.activityInfo) {
        newParams.activity = {
            mode: 'RESERVE',
            name: params.activityInfo.name,
            startTime: params.activityInfo.dateRange.start,
            endTime: params.activityInfo.dateRange.end,
            reserveType: params.activityInfo.reserveType,
            description: params.activityInfo.description,
        };
        const subsites = new Array<{ id: string }>();
        forEach(params.activityInfo.subsites, (subsite) => {
            subsites.push({
                id: subsite.id,
            });
        });
        newParams.subsites = subsites;
        newParams.goods = params.activityInfo.goods;
        newParams.limitCondition = {
            maxOrderCount: params.activityInfo.maxOrderCount,
        };
        newParams.marketingScheduleId =
            (params.activityInfo?.marketingSchedule?.length &&
                params.activityInfo.marketingSchedule[0].id) ||
            null;
    }
    //活动场次
    if (params.roundInfo) {
        newParams.rounds = [
            {
                period: params.roundInfo.period,
                periodValue: 1,
                startTime: params.roundInfo.time[0].start,
                endTime: params.roundInfo.time[0].end,
                reserveStartTime: params.roundInfo.reserveTime[0].start,
                reserveEndTime: params.roundInfo.reserveTime[0].end,
                memberLimit: params.roundInfo.memberLimit,
                quantityLimit: params.roundInfo.quantityLimit,
            },
        ];
    }
    //高级设置
    if (params.seniorInfo) {
        const participateCondition = params.seniorInfo.participateCondition;
        const minRegisterDays: any = find(participateCondition, { code: 'MINREGISTERDAYS' });
        const consumption: any = find(participateCondition, { code: 'CONSUMPTIONT' });
        const certification: any = find(participateCondition, { code: 'CERTIFICATION' });
        newParams.participateCondition = {
            minRegisterDays:
                minRegisterDays && minRegisterDays.checked ? minRegisterDays.value : null,
            consumptionCount: consumption && consumption.checked ? consumption.value : null,
            certification: certification && certification.checked ? true : false,
            receiptCertification: Boolean(
                params.seniorInfo.receiptCertification &&
                    params.seniorInfo.receiptCertification.length
            ),
        };
    }
    return newParams;
}
