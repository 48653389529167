import React, { Component } from 'react';
import {
    Row as AntRow,
    InputNumber,
    Button,
    Checkbox,
    Popconfirm,
    Input,
    Select,
    message as AntMessage,
} from 'antd';
import {
    clone,
    map,
    concat,
    isEmpty,
    get,
    cloneDeep,
    findIndex,
    toString,
    remove,
    some,
} from 'lodash';
import { services } from '@comall-backend-builder/core';
import { language, privilege } from '@comall-backend-builder/core/lib/services';

import {
    CouponSelector,
    CouponType,
    EventCouponValueTypeEnum,
} from '../../types/mode/array/event-market-pay-coupon/select-coupon';

import './index.less';
const { api } = services;
interface Coupon {
    id: string;
    couponName: number;
    couponType: string;
    startTime: string;
    endTime: string;
    batchNo: string;
    couponTypeDes: string;
    cooperationPatternDesc: string;
    count?: number;
    stock: number;
    apportionPrice?: number;
    leftStock?: number;
    couponDefinitionId?: number;
}
type ActivityRewardValue = {
    /**
     * 会员等级
     */
    levelId?: number;
    levelName?: string;
    /**
     * 积分
     */
    pointChecked?: boolean;
    point?: number;
    pointName?: string;
    /**
     * HI币
     */
    goldChecked?: boolean;
    gold?: number;
    goldName?: string;
    /**
     * 经验值
     */
    experienceChecked?: boolean;
    experience?: number;
    experienceName?: string;
    /**
     * Hi贝
     */
    goldValueChecked?: boolean;
    goldValue?: number;
    goldValueName?: string;
    /**
     * 优惠券
     */
    couponsChecked?: boolean;
    couponsValue?: any;
    couponsName?: string;
    /**
     * 自定义
     */
    customChecked?: boolean;
    custom?: string;
    customName?: string;
};

/**
 * 奖励的类型，multi: 阶梯奖励, single: 单个奖励
 */
enum RewardType {
    multi = 'multi',
    single = 'single',
}

/**
 * 后端接口返回的目前支持的选项
 */
enum RewardItems {
    point = 'POINT',
    gold = 'GOLD',
    experience = 'EXPERIENCE',
    coupons = 'COUPON',
    picture = 'PICTURE',
    custom = 'CUSTOM',
    Hi = 'HI',
}

interface ActivityRewardProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    value: Array<ActivityRewardValue> | null;

    /**
     * 奖励的类型，multi: 阶梯奖励, single: 单个奖励
     */
    rewardType: 'multi' | 'single';

    /**
     * 候选项集合
     */
    options: Array<any>;
    /**
     * 是否是范围值
     */
    isRange?: boolean;

    /**
     * 阶梯值文本配置
     */
    stepValueTextConfig?: {
        /**
         * 起始文本
         */
        start: string;
        /**
         * 结束文本
         */
        end: string;
        /**
         * 阶梯值单位
         */
        unit: string;
    };
    /**
     * 奖励项文本配置
     */
    rewardTextConfig?: {
        /**
         * 起始文本
         */
        start?: string;
        /**
         * 结束文本
         */
        end?: string;
    };
    /**
     * 是否禁用（禁用只限制修改阶梯值、赠删阶梯，不限制修改阶梯内奖励内容）
     */
    disabled: boolean;

    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Array<ActivityRewardValue> | null, name: string) => void;
}

export class CommentActivityReward extends Component<ActivityRewardProps, any> {
    static defaultProps = {
        rewardType: 'multi',
        disabled: false,
    };
    CouponSelector: any;
    constructor(props: any) {
        super(props);
        this.state = {
            memberLevels: [],
            filterSubsiteId: null,
            rewardIndex: 0,
            goNextpage: null,
            changeCrowdScopeNum: -1,
        };
    }

    componentDidMount() {
        let { name, value, onChange } = this.props;
        this.setState({ filterSubsiteId: { subsiteIds: null } });
        if (!value) {
            let item: ActivityRewardValue = { couponsValue: [] };
            value = concat([], item);
            if (onChange) {
                onChange(value, name);
            }
        }
    }
    componentWillReceiveProps(nextProps: any) {
        const currentSubsiteId = get(this.props, 'row.ruleInfo.subsiteId');
        const nextSubsiteId = get(nextProps, 'row.ruleInfo.subsiteId');

        if (currentSubsiteId !== nextSubsiteId) {
            const filterSubsiteId = {
                subsiteIds: [nextSubsiteId],
            };
            this.setState({ filterSubsiteId }, () => {
                this.getMemberLevels(nextSubsiteId);
            });
        }
        const currentRuleType = get(this.props, 'row.ruleInfo.crowdScope');
        const nextRuleType = get(nextProps, 'row.ruleInfo.crowdScope');
        if (currentRuleType !== nextRuleType) {
            const { changeCrowdScopeNum } = this.state;
            this.setState({ changeCrowdScopeNum: changeCrowdScopeNum + 1 }, () => {
                if (changeCrowdScopeNum > 0) {
                    this.resetPage();
                }
            });
        }
    }
    resetPage = () => {
        let { name, onChange } = this.props;

        this.setState(
            {
                rewardIndex: 0,
                goNextpage: null,
            },
            () => {
                if (onChange) {
                    onChange([{ couponsValue: [] }], name);
                }
            }
        );
    };
    getMemberLevels = (subsiteId: any) => {
        const defaultMemberLevels = [{ id: 0, name: language.getText('qbdj') }];

        api.get(
            {
                subsiteId: subsiteId,
                page: 1,
                perPage: 100,
                status: 'ENABLE',
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                apiPath: '/admin/store_card_levels',
            }
        ).then((res: any) => {
            const data = res.result;
            const memberLevels = data && data.length > 0 ? data : cloneDeep(defaultMemberLevels);
            const { changeCrowdScopeNum } = this.state;
            this.setState({ memberLevels, changeCrowdScopeNum: changeCrowdScopeNum + 1 }, () => {
                if (changeCrowdScopeNum > 0) {
                    this.resetPage();
                }
            });
        });
    };
    onAddReward() {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(value.length, 0, { couponsValue: [] });
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onRemoveReward = (index: number) => {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(index, 1);
            if (!value.length) {
                let item: ActivityRewardValue = { couponsValue: [] };
                value = concat([], item);
            }
        } else {
            let item: ActivityRewardValue = { couponsValue: [] };
            value = concat([], item);
        }

        if (onChange) {
            onChange(value, name);
        }
    };

    onInputValueChange(event: any, index: number, type: string) {
        let { name, value, onChange } = this.props;
        let inputValue = event;
        var reg = /^\+?[0-9]\d*$/;
        if (
            type !== RewardItems.custom &&
            inputValue !== null &&
            inputValue !== '' &&
            !reg.test(inputValue)
        ) {
            AntMessage.warning(services.language.getText('inputNumber'));
            inputValue = null;
        }
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            if (type === RewardItems.point) {
                item.point = inputValue;
            }
            if (type === RewardItems.gold) {
                item.gold = inputValue;
            }
            if (type === RewardItems.experience) {
                item.experience = inputValue;
            }
            if (type === RewardItems.custom) {
                inputValue = event.target.value;
                item.custom = inputValue;
            }
            if (type === RewardItems.Hi) {
                item.goldValue = inputValue;
            }
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: ActivityRewardValue = { couponsValue: [] };
            if (type === RewardItems.point) {
                item.point = inputValue;
            }
            if (type === RewardItems.gold) {
                item.gold = inputValue;
            }
            if (type === RewardItems.experience) {
                item.experience = inputValue;
            }
            if (type === RewardItems.custom) {
                inputValue = event.target.value;
                item.custom = inputValue;
            }
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onCheckboxChange(event: any, index: number, type: string, checkboxName: string) {
        let { name, value, onChange } = this.props;
        let checkboxValue = event.target.checked;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            if (type === RewardItems.point) {
                item.pointChecked = checkboxValue;
                item.pointName = checkboxName;
            }
            if (type === RewardItems.gold) {
                item.goldChecked = checkboxValue;
                item.goldName = checkboxName;
            }
            if (type === RewardItems.experience) {
                item.experienceChecked = checkboxValue;
                item.experienceName = checkboxName;
            }
            if (type === RewardItems.coupons) {
                item.couponsChecked = checkboxValue;
                item.couponsName = checkboxName;
            }
            if (type === RewardItems.custom) {
                item.customChecked = checkboxValue;
                item.customName = checkboxName;
            }
            if (type === RewardItems.Hi) {
                item.goldValueChecked = checkboxValue;
                item.goldValueName = checkboxName;
            }
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: ActivityRewardValue = { couponsValue: [] };
            if (type === RewardItems.point) {
                item.pointChecked = checkboxValue;
                item.pointName = checkboxName;
            }
            if (type === RewardItems.gold) {
                item.goldChecked = checkboxValue;
                item.goldName = checkboxName;
            }
            if (type === RewardItems.experience) {
                item.experienceChecked = checkboxValue;
                item.experienceName = checkboxName;
            }
            if (type === RewardItems.coupons) {
                item.couponsChecked = checkboxValue;
                item.couponsName = checkboxName;
            }
            if (type === RewardItems.custom) {
                item.customChecked = checkboxValue;
                item.customName = checkboxName;
            }
            if (type === RewardItems.Hi) {
                item.goldValueChecked = checkboxValue;
                item.goldValueName = checkboxName;
            }
            value = concat([], item);
        }

        if (onChange) {
            onChange(value, name);
        }
    }
    onRewardIndex = (index: number) => {
        this.setState(
            {
                rewardIndex: index,
            },
            () => {
                this.CouponSelector.toggleModal();
            }
        );
    };
    showModel = (ref: any) => {
        this.CouponSelector = ref;
    };
    setDisplayType = (row: Coupon[]) => {
        let displayType = 'selectCoupon';
        if (row.length && row[0]?.couponType === 'PACKAGE') {
            displayType = 'selectCouponPackage';
        }
        return displayType;
    };
    modalToCoupon = (item: any) => {
        return {
            id: item.id,
            couponDefinitionId: item.id,
            couponName: item.name || item.couponName,
            couponType: item.couponType || item.type,
            startTime: item.startTime,
            endTime: item.endTime,
            startDate: item.startDate,
            endDate: item.endDate,
            batchNo: item.code || item.batchNo,
            couponTypeDes: item.typeName || item.couponTypeDes,
            cooperationPatternDesc:
                (item.cooperationPattern && item.cooperationPattern.description) ||
                item.cooperationPatternDesc,
            consumeChannelDescription: item.consumeChannelDescription,
            count: item.count || 1,
            stock: item.stock,
            leftStock: item.leftStock,
            couponValue: item.couponValue,
            consumeRule: item.consumeRule,
        };
    };
    changeCoupons = (coupons: Array<any>, type: string) => {
        const { rewardIndex } = this.state;
        let { name, value, onChange } = this.props;
        let newData = value ? value : [];
        let row: any = newData[rewardIndex];
        let newCoupons: any = row.couponsValue ? [...row.couponsValue] : [];
        if (this.setDisplayType(newCoupons) !== type) {
            newCoupons = [];
        }
        if (type === 'selectCoupon') {
            coupons.forEach((selectCuopon: any) => {
                const hasIndex = findIndex(
                    newCoupons,
                    (coupon: any) => toString(coupon.id) === toString(selectCuopon.id)
                );
                if (hasIndex === -1) {
                    newCoupons.push(selectCuopon);
                }
            });
        } else {
            newCoupons = [...coupons];
        }
        row.couponsValue = newCoupons.map(this.modalToCoupon);
        if (onChange) {
            onChange(newData, name);
        }
    };
    changeModal = () => {
        const { rewardIndex, filterSubsiteId } = this.state;
        const { value } = this.props;
        if (!value || !value[rewardIndex]?.couponsValue) return;
        return (
            <CouponSelector
                onRef={this.showModel}
                selectValues={value[rewardIndex].couponsValue}
                rewardIndex={rewardIndex}
                onChange={this.changeCoupons}
                params={filterSubsiteId}
            />
        );
    };

    render() {
        let { value, rewardType, disabled } = this.props;
        const crowdScope = get(this.props, 'row.ruleInfo.crowdScope');
        const channel = get(this.props, 'row.ruleInfo.channel');
        //同一规则
        let setCrowdScope = crowdScope;
        if (channel === 'OFFLINE') {
            //线下订单 只有同一规则
            setCrowdScope = 'CYCLE';
        }
        const showButton = setCrowdScope !== 'CYCLE';
        return (
            <div className="comment-activity-reward">
                <div className="comment-activity-reward__wrap">
                    {map(value, (reward, index) => this.renderReward(reward, index, disabled))}
                    {value && value.length
                        ? null
                        : this.renderReward({ couponsValue: [] }, 0, disabled)}
                </div>
                {rewardType === RewardType.multi && showButton && !disabled && (
                    <Button
                        type="default"
                        className="reward-add-reward-button"
                        onClick={() => this.onAddReward()}
                    >
                        {language.getText('addRule')}
                    </Button>
                )}
                {this.changeModal()}
            </div>
        );
    }

    onChangeCoupon = (products: Coupon[], index: number) => {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.couponsValue = products;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: ActivityRewardValue = { couponsValue: [] };
            item.couponsValue = products;
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    };

    onChangeLevel = (index: number, levelId: any, options: any) => {
        let { name, value, onChange } = this.props;
        const selectId = value && some(value, { levelId });
        if (selectId) {
            AntMessage.warning(language.getText('hydjbnzf'));
            return;
        }
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.levelId = levelId;
            item.levelName = options.props.children;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: ActivityRewardValue = { couponsValue: [] };
            item.levelId = levelId;
            item.levelName = options.props.children;
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    };
    renderReward(reward: ActivityRewardValue, index: number, isDisabled: boolean) {
        const { options, rewardType, value } = this.props;
        if (!value) return;
        let point: any;
        let gold: any;
        let experience: any;
        let coupons: any;
        let custom: any;
        let Hi: any;
        map(options, (option, index) => {
            if (option.code === RewardItems.point) {
                point = option;
            } else if (option.code === RewardItems.gold) {
                gold = option;
            } else if (option.code === RewardItems.experience) {
                experience = option;
            } else if (option.code === RewardItems.coupons) {
                coupons = option;
            } else if (option.code === RewardItems.custom) {
                custom = option;
            } else if (option.code === RewardItems.Hi) {
                Hi = option;
            }
        });
        const crowdScope = get(this.props, 'row.ruleInfo.crowdScope');
        const channel = get(this.props, 'row.ruleInfo.channel');

        //同一规则
        let allRule = crowdScope;
        if (channel === 'OFFLINE') {
            //线下订单 只有同一规则
            allRule = 'CYCLE';
        }
        const { memberLevels } = this.state;
        const onChangeLevel = this.onChangeLevel.bind(this, index);
        const detelBtn = allRule !== 'CYCLE';

        return (
            <div className="comment-activity-reward-item" key={index}>
                <div className="rule-setting-wrap">
                    {allRule === 'CYCLE' && (
                        <div className="member-levels">{language.getText('qbdj')}</div>
                    )}
                    {allRule === 'LADDER' && (
                        <div className="member-levels">
                            <div>{services.language.getText('xzdj')}</div>
                            <Select
                                value={reward.levelId}
                                style={{ width: '140px' }}
                                onChange={onChangeLevel}
                            >
                                <Select.Option value={0}>
                                    {language.getText('selectPlease')}
                                </Select.Option>
                                {memberLevels &&
                                    memberLevels.map((option: any) => (
                                        <Select.Option
                                            key={option.cardLevelId}
                                            value={option.cardLevelId}
                                        >
                                            {option.name}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </div>
                    )}
                    <AntRow>
                        <div className="reward-content">
                            {rewardType === RewardType.multi &&
                                detelBtn &&
                                !isDisabled &&
                                value?.length > 1 && (
                                    <Popconfirm
                                        onConfirm={this.onRemoveReward.bind(this, index)}
                                        title={services.language.getText('sfscggz')}
                                        okText={services.language.getText('common.ok')}
                                        cancelText={services.language.getText('common.cancel')}
                                    >
                                        <Button
                                            type="default"
                                            className="reward-remove-reward-button"
                                        >
                                            {language.getText('scdj')}
                                        </Button>
                                    </Popconfirm>
                                )}
                        </div>
                    </AntRow>
                </div>
                <div className="reward-content-content">
                    <div className="reward-content-title">{language.getText('zsnr')}</div>
                    <div className="reward-content-list">
                        {point && (
                            <div className="reward-content-item">
                                <Checkbox
                                    className="reward-content-item-checkbox"
                                    onChange={(e) =>
                                        this.onCheckboxChange(
                                            e,
                                            index,
                                            RewardItems.point,
                                            point.name
                                        )
                                    }
                                    checked={reward.pointChecked}
                                    value="point"
                                    disabled={isDisabled}
                                >
                                    {point.name}
                                </Checkbox>
                                <InputNumber
                                    type="number"
                                    min={1}
                                    value={reward.point}
                                    onChange={(e) =>
                                        this.onInputValueChange(e, index, RewardItems.point)
                                    }
                                    disabled={isDisabled}
                                />
                                <span className="reward-item-text">{point.name}</span>
                            </div>
                        )}
                        {gold && (
                            <div className="reward-content-item">
                                <Checkbox
                                    className="reward-content-item-checkbox"
                                    onChange={(e) =>
                                        this.onCheckboxChange(e, index, RewardItems.gold, gold.name)
                                    }
                                    checked={reward.goldChecked}
                                    value="point"
                                    disabled={isDisabled}
                                >
                                    {gold.name}
                                </Checkbox>
                                <InputNumber
                                    type="number"
                                    min={1}
                                    value={reward.gold}
                                    onChange={(e) =>
                                        this.onInputValueChange(e, index, RewardItems.gold)
                                    }
                                    disabled={isDisabled}
                                />
                                <span className="reward-item-text">{gold.name}</span>
                            </div>
                        )}
                        {experience && (
                            <div className="reward-content-item">
                                <Checkbox
                                    className="reward-content-item-checkbox"
                                    onChange={(e) =>
                                        this.onCheckboxChange(
                                            e,
                                            index,
                                            RewardItems.experience,
                                            experience.name
                                        )
                                    }
                                    checked={reward.experienceChecked}
                                    value="experience"
                                >
                                    {experience.name}
                                </Checkbox>
                                <InputNumber
                                    type="number"
                                    min={1}
                                    value={reward.experience}
                                    onChange={(e) =>
                                        this.onInputValueChange(e, index, RewardItems.experience)
                                    }
                                />
                                <span className="reward-item-text">
                                    {language.getText('value')}
                                </span>
                            </div>
                        )}
                        {Hi && (
                            <div className="reward-content-item">
                                <Checkbox
                                    className="reward-content-item-checkbox"
                                    onChange={(e) =>
                                        this.onCheckboxChange(e, index, RewardItems.Hi, Hi.name)
                                    }
                                    checked={reward.goldValueChecked}
                                    value="experience"
                                >
                                    {Hi.name}
                                </Checkbox>

                                <InputNumber
                                    type="number"
                                    min={1}
                                    value={reward.goldValue}
                                    onChange={(e) =>
                                        this.onInputValueChange(e, index, RewardItems.Hi)
                                    }
                                />
                                <span className="reward-item-text">
                                    {language.getText('hellob')}
                                </span>
                            </div>
                        )}
                    </div>
                    {coupons && (
                        <div className="reward-content-list">
                            <div className="reward-content-item">
                                <Checkbox
                                    className="reward-content-item-checkbox"
                                    onChange={(e) =>
                                        this.onCheckboxChange(
                                            e,
                                            index,
                                            RewardItems.coupons,
                                            coupons.name
                                        )
                                    }
                                    checked={reward.couponsChecked}
                                    value="coupons"
                                    disabled={isDisabled}
                                >
                                    {coupons.name}
                                </Checkbox>
                            </div>
                            <div className="reward-coupons">
                                <Button type={'link'} onClick={() => this.onRewardIndex(index)}>
                                    {language.getText('xzyhq')}
                                </Button>
                                {this.showCouponItem(index)}
                            </div>
                        </div>
                    )}
                    {custom && (
                        <div className="reward-content-list">
                            <div className="reward-content-custom-item">
                                <Checkbox
                                    className="reward-content-item-checkbox"
                                    onChange={(e) =>
                                        this.onCheckboxChange(
                                            e,
                                            index,
                                            RewardItems.custom,
                                            custom.name
                                        )
                                    }
                                    checked={reward.customChecked}
                                    value="custom"
                                >
                                    {custom.name}
                                </Checkbox>
                                <Input
                                    className="reward-content-item-text"
                                    placeholder={language.getText('trdwzjzszyqylhdyz')}
                                    type="text"
                                    maxLength={20}
                                    value={reward.custom}
                                    onChange={(e) =>
                                        this.onInputValueChange(e, index, RewardItems.custom)
                                    }
                                />
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
    CheckCoupon = (item: Coupon) => {
        const { id } = item;
        let hasPrivilege = true;
        let route = '';
        const baseUrl = '#/menu-list/coupon';
        switch (item.couponType) {
            case CouponType.PACKAGE:
                route = `${baseUrl}/coupon-package-rules/edit/${id}`;
                hasPrivilege = privilege.check('couponPackageRule', 'full');
                break;
            case CouponType.CASH:
                route = `${baseUrl}/cash-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('cashCouponRule', 'full');
                break;
            case CouponType.GIFT:
                route = `${baseUrl}/gift-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('giftCouponRule', 'full');
                break;
            case CouponType.FREIGHT:
                route = `${baseUrl}/freight-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('freightCouponRule', 'full');
                break;
            case CouponType.PARKING:
                route = `${baseUrl}/parking-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('parkingCouponRule', 'full');
                break;
            case CouponType.LOTTERY:
                route = `${baseUrl}/lottery-coupon/edit/${id}`;
                hasPrivilege = privilege.check('lotteryCouponRule', 'full');
                break;
            case CouponType.BALANCE:
                route = `${baseUrl}/balance-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('balanceCouponRule', 'full');
                break;

            default:
                break;
        }
        if (hasPrivilege && route) {
            window.open(`${route}`, '_blank');
        } else {
            this.setState({
                goNextpage: {
                    id,
                    hasPrivilege,
                },
            });
        }
    };
    renderValue = (coupon: any) => {
        const { couponValue, couponType } = coupon;
        const couponRuleType = couponType;

        if (couponRuleType === CouponType.GIFT) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__text">
                        {services.language.getText('presents')}
                    </span>
                </div>
            );
        } else if (couponRuleType === CouponType.LOTTERY) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__num">{couponValue.value}</span>
                </div>
            );
        } else if (couponRuleType === CouponType.PACKAGE) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__text">
                        {services.language.getText('gifts')}
                    </span>
                </div>
            );
        } else if (
            couponRuleType === CouponType.PARKING &&
            couponValue.type === EventCouponValueTypeEnum.No
        ) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__text">
                        {services.language.getText('qm_2')}
                    </span>
                </div>
            );
        }
        if (!couponValue || !couponValue.value) {
            return null;
        } else if (
            couponType === CouponType.PARKING &&
            couponValue.type === EventCouponValueTypeEnum.Hours
        ) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__num">{couponValue.value}</span>
                    <span className="event-coupon__symbol">{language.getText('hour')}</span>
                </div>
            );
        }
        return (
            <div className="event-coupon__price">
                <span className="event-coupon__symbol">
                    {services.language.getText('monetaryUnit')}
                </span>
                <span className="event-coupon__price__num">{couponValue.value}</span>
            </div>
        );
    };
    renderCoupons = (coupon: any) => {
        const innerClassName = `${coupon.couponType}-coupon-popup__list__item`;
        const bg = 'https://b-puzhehei-cdn.co-mall.net/global/event_coupon_bg.png';
        const style = {
            backgroundImage: `url(${bg})`,
        };
        return (
            <div className={innerClassName}>
                <div className="event-coupon" style={style}>
                    <div className="event-coupon__left">{this.renderValue(coupon)}</div>
                    <div className="event-coupon__right">
                        <div className="event-coupon__right__title">{coupon.couponName}</div>
                        <div className="event-coupon__right__time">
                            {coupon.startDate}-{coupon.endDate}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    changeStock = (rowIndex: number, couponIndex: number, valueNum: number) => {
        const { value, name, onChange } = this.props;
        const newData = value ? value : [];
        let rowDate: any = newData[rowIndex];
        let rowItem: any = rowDate.couponsValue[couponIndex];

        if (rowItem) {
            rowItem.count = valueNum;
            if (onChange) {
                onChange(newData, name);
            }
        }
    };
    deleteCouponItem = (rowIndex: number, couponIndex: number) => {
        const { value, name, onChange } = this.props;
        const newData = value ? value : [];
        let rowDate: any = newData[rowIndex];
        let rowItem: any = rowDate.couponsValue[couponIndex];

        if (rowItem) {
            remove(rowDate.couponsValue, function(rowItemCoupon: Coupon) {
                return rowItemCoupon.id === rowItem.id;
            });
            if (onChange) {
                onChange(newData, name);
            }
        }
    };
    showCouponItem = (index: number) => {
        const { value } = this.props;
        if (value?.length && value[index].couponsValue.length) {
            const row = value[index].couponsValue;
            const { goNextpage } = this.state;
            return row.map((item: Coupon, couponIndex: number) => {
                const unitTxt =
                    item.couponType === CouponType.PACKAGE
                        ? services.language.getText('ge')
                        : services.language.getText('zhang');
                return (
                    <div
                        key={item.id}
                        style={{ display: 'flex', alignItems: 'center', padding: '10px 0' }}
                    >
                        <div>{this.renderCoupons(item)}</div>
                        <div style={{ minWidth: '150px', padding: '0 20px' }}>
                            {language.getText('stock')}：{item.leftStock}
                        </div>
                        <div style={{ width: '205px', flexShrink: 0 }}>
                            {services.language.getText('mrmcff')}：
                            <InputNumber
                                placeholder={services.language.getText('inputPlease')}
                                value={item.count}
                                min={0}
                                max={999999999}
                                onChange={(value: any) =>
                                    this.changeStock(index, couponIndex, value)
                                }
                            ></InputNumber>
                            {unitTxt}
                        </div>
                        <Popconfirm
                            title={`${services.language.getText('qrsc')}${item.couponName}?`}
                            onConfirm={() => this.deleteCouponItem(index, couponIndex)}
                            okText={language.getText('common.confirm')}
                            cancelText={services.language.getText('common.cancel')}
                        >
                            <Button type="link">
                                {services.language.getText('common.delete')}
                            </Button>
                        </Popconfirm>

                        <Button type="link" onClick={() => this.CheckCoupon(item)}>
                            {language.getText('ckqgz')}
                        </Button>
                        {goNextpage && !goNextpage.hasPrivilege && goNextpage.id === item.id && (
                            <div style={{ color: 'red', flexShrink: 0 }}>
                                {language.getText('zwckqgzqx')}
                            </div>
                        )}
                    </div>
                );
            });
        }
    };
}
