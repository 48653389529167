import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;
export const DepositOrderRefundLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`;
        config.apiPath = '/admin/deposit_order_refund_items';
        if (data.status === 'ALL') {
            delete data.status;
        }
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        data.refundQueryType = 'DEPOSIT_REFUND';
        return await api.get(data, config).then((res: any) => {
            res.result = res.result.map((item: any) => {
                return {
                    ...item,
                    canRefund: item.interfaceStatus === 'REFUND_FAIL',
                };
            });
            return res;
        });
    },
};
