import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { PushAndSiteMailSceneType } from '../../../containers/wechat/common';

export const config: Config = {
    entities: {
        LotteryCodeEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/lotteryCode',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsrhdmc>>' },
                },
                subSiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<sxmd_1>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                    controlConfig: { style: { width: 150 } },
                },
                drawMethod: {
                    type: 'string.options.select',
                    displayName: '<<kjfs>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'AUTOMATIC', name: '<<zdkj>>' },
                        { id: 'EXTERNAL', name: '<<wbkj>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                editExecuteStatus: {
                    type: 'string',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherBaseInfo>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [{ required: true, whitespace: true, message: '<<qsrhdmc>>' }],
                            controlConfig: {
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        drawTime: {
                            type: 'string.dateTime',
                            displayName: '<<kjsj>>',
                            rules: [{ required: true, message: '<<qxzkjsj>>' }],
                            controlConfig: {
                                placeholder: '<<kjsj>>',
                                style: {
                                    width: 300,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                            extra: '<<kjsjjywyhdyjssj>>',
                        },
                        drawMethod: {
                            type: 'string.options.radio',
                            displayName: '<<kjfs>>',
                            defaultValue: 'AUTOMATIC',
                            options: [
                                {
                                    id: 'AUTOMATIC',
                                    name: '<<zdkj>>',
                                },
                                {
                                    id: 'EXTERNAL',
                                    name: '<<wbkj>>',
                                },
                            ],
                            extra: '<<zkjsjqkdrxzjmdjxfg>>',
                            rules: [{ required: true }],
                        },
                        subsiteIds: {
                            type: 'array.subsites.selector',
                            displayName: '<<sxmd_1>>',
                            rules: [{ required: true }],
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],
                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.baseInfo?.subsiteIds?.length &&
                                        row.baseInfo.subsiteIds
                                            .map((item: any) => item.id)
                                            .join(',');
                                    return {
                                        subsiteIds,
                                    };
                                },
                            },
                        },
                        userWhiteListScope: {
                            type: 'string.options.radio',
                            displayName: '<<yhfw>>',
                            defaultValue: 'ALL',
                            options: [
                                {
                                    id: 'ALL',
                                    name: '<<sy>>',
                                },
                                {
                                    id: 'PART',
                                    name: '<<bf>>',
                                },
                            ],
                            extra: '<<l_zxzbfyhcys_1>>',
                            rules: [{ required: true }],
                        },
                        noticeType: {
                            type: 'string.options.radio',
                            displayName: '<<zjtz>>',
                            defaultValue: 'OFFLINE',
                            options: [
                                {
                                    id: 'OFFLINE',
                                    name: '<<xxrgtz>>',
                                },
                                {
                                    id: 'ONLINE',
                                    name: '<<xsxttz>>',
                                },
                            ],
                            rules: [{ required: true }],
                        },
                        businessNotifications: {
                            type: 'array.messageNotificationMethod',
                            displayName: '<<tzfs>>',
                            controlConfig: {
                                scene: PushAndSiteMailSceneType.LOTTERY_CODE_AWARD_NOTICE,
                            },
                        },
                        shareContent: {
                            type: 'string',
                            displayName: '<<fxwa>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrfxwa50Rule>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrfxwa>>',
                                maxLength: 50,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        shareAssistContent: {
                            type: 'string',
                            displayName: '<<fxzlwa>>',
                            rules: [
                                {
                                    whitespace: true,
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrfxzlwa>>',
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        description: {
                            type: 'string.richTextPlus',
                            displayName: '<<activityRule>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    max: 20000,
                                    message: '<<l_notMoreThan2000_2>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<descriptionRule>>',
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            extra: '<<descriptionExtra>>',
                        },
                        remark: {
                            type: 'string.text.paragraph',
                            displayName: '<<hdbz>>',
                            rules: [
                                {
                                    whitespace: true,
                                    max: 500,
                                    message: '<<remarkMessage>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrhdbz>>',
                                rows: 4,
                                maxLength: 500,
                                style: {
                                    width: 720,
                                },
                            },
                        },
                    },
                },
                numberInfo: {
                    type: 'object.subForm',
                    displayName: '<<cjmsl>>',
                    collapsed: true,
                    properties: {
                        activityRewardLimit: {
                            type: 'number.tip',
                            displayName: '<<cszscjmsl>>',
                            displayConfig: { addonafter: '<<zhang>>' },
                            rules: [
                                {
                                    required: true,
                                    message: '<<cszscjmslbnwk>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(\+?[0-9]*)$/;
                                        if (reg.test(value)) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '0',
                                addonafter: '<<zhang>>',
                                style: { width: '120px' },
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                            },
                        },
                        shareRewardLimit: {
                            type: 'number.tip',
                            displayName: '<<fxhdcjmsx>>',
                            displayConfig: { addonafter: '<<zhang>>' },
                            rules: [
                                {
                                    required: true,
                                    message: '<<fxhdcjmsxbnwk>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(\+?[0-9]*)$/;
                                        if (reg.test(value)) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '0',
                                addonafter: '<<zhang>>',
                                style: { width: '120px' },
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                            },
                        },
                        shareAssistLimit: {
                            type: 'number.tip',
                            displayName: '<<fxbzlhdcjmsx>>',
                            displayConfig: { addonafter: '<<zhang>>' },
                            rules: [
                                {
                                    required: true,
                                    message: '<<fxbzlhdcjmsxbnwk>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(\+?[0-9]*)$/;
                                        if (reg.test(value)) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '0',
                                addonafter: '<<zhang>>',
                                max: 999999,
                                style: { width: '120px' },
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                            },
                        },
                        assistLimit: {
                            type: 'number.tip',
                            displayName: '<<whyzlhdcjmsx>>',
                            displayConfig: { addonafter: '<<zhang>>' },
                            rules: [
                                {
                                    required: true,
                                    message: '<<whyzlhdcjmsxbnwk>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(\+?[0-9]*)$/;
                                        if (reg.test(value)) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '0',
                                addonafter: '<<zhang>>',
                                max: 999999,
                                style: { width: '120px' },
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                            },
                        },
                        pointRule: {
                            type: 'string.options.radio',
                            displayName: '<<jfdhcjm>>',
                            defaultValue: 'NO',
                            options: [
                                {
                                    id: 'NO',
                                    name: '<<bzc>>',
                                },
                                {
                                    id: 'YES',
                                    name: '<<zc_2>>',
                                },
                            ],
                            rules: [{ required: true }],
                        },
                        point: {
                            type: 'number.tip',
                            displayName: '<<mccjxh>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 0,
                                    message: '<<inputThanZero>>',
                                },
                            ],
                            controlConfig: {
                                style: { width: '120px' },
                                placeholder: '0',
                                addonafter: '<<point>>',
                                tip: '',
                            },
                        },
                        pointExchangeLimit: {
                            type: 'number.tip',
                            displayName: '<<zddh>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 0,
                                    message: '<<inputThanZero>>',
                                },
                            ],
                            controlConfig: {
                                style: { width: '120px' },
                                placeholder: '0',
                                addonafter: '<<frequency>>',
                                tip: '',
                            },
                        },
                        refundPoint: {
                            type: 'string.options.radio',
                            displayName: '<<wzjthjf>>',
                            defaultValue: 'false',
                            options: [
                                {
                                    id: 'false',
                                    name: '<<bth>>',
                                },
                                {
                                    id: 'true',
                                    name: '<<th>>',
                                },
                            ],
                        },
                    },
                },
                prizeInfo: {
                    type: 'object.subForm',
                    displayName: '<<jpsz>>',
                    collapsed: true,
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<jpmc>>',
                            rules: [{ required: true, whitespace: true, message: '<<qsrjpmc>>' }],
                            controlConfig: {
                                placeholder: '<<qsrjpmc>>',
                                maxLength: 100,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        type: {
                            type: 'object.rewardRules',
                            displayName: '<<jplx>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                reqConfig: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/activities/reward_items',
                                },
                                reqParams: { eventType: 'LOTTERY_CODE' },
                                baseConfig: {
                                    isConditionsInput: false,
                                    isItmesRadio: true,
                                    isLadder: false,
                                },
                            },
                        },
                        picture: {
                            displayName: '<<jptp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/lottery-code/lottery-code.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<pictureLimitExtra>>',
                        },
                        quantity: {
                            type: 'number.integer',
                            displayName: '<<jpsl>>',
                            displayConfig: { addonafter: '<<frequency>>' },
                            rules: [
                                {
                                    type: 'number',
                                    min: 0,
                                    message: '<<quantityErrorMessage>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^([0-9]\d*)$/;
                                        if (value && !reg.test(value)) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrsl_1>>',
                                style: {
                                    width: 120,
                                },
                            },
                            extra: '<<jpsljwkjsl>>',
                        },
                        receiveRewardTime: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<ljsj>>',
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                    },
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                subSites: {
                    type: 'array.popover',
                    displayName: '<<sxmd_1>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                drawTime: {
                    type: 'string.date',
                    displayName: '<<kjsj>>',
                },
                drawMethod: {
                    type: 'string.options.select',
                    displayName: '<<kjfs>>',
                    options: [
                        { id: 'AUTOMATIC', name: '<<zdkj>>' },
                        { id: 'EXTERNAL', name: '<<wbkj>>' },
                    ],
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                lotteryStatus: {
                    type: 'string',
                    displayName: '<<kjzt>>',
                },
                whiteUserListFileUrl: {
                    type: 'string.lotteryCodeImportExport',
                    displayName: '<<yhfw>>',
                    controlConfig: {
                        importExportType: 'WHITE_LIST',
                    },
                },
                blackUserListFileUrl: {
                    type: 'string.lotteryCodeImportExport',
                    displayName: '<<hmd>>',
                    controlConfig: {
                        importExportType: 'BLACK_LIST',
                    },
                },
            },
        },
    },
    components: {
        LotteryCodeView: {
            component: 'Viewport',
            entity: 'LotteryCodeEntity',
        },
        LotteryCodePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'LotteryCodeFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/lottery-code/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'LotteryCodeTable' },
            ],
        },
        LotteryCodeFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 250 },
                    },
                },
                {
                    property: 'subSiteId',
                    controlConfig: {
                        style: { width: 250 },
                    },
                },
                {
                    property: 'drawMethod',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
            ],
        },
        LotteryCodeTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: {
                x: true,
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'subSites',
                    width: 200,
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '250px',
                        },
                        propertyName: `subSites`,
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'startTime',
                    width: 200,
                },
                {
                    property: 'endTime',
                    width: 200,
                },
                {
                    property: 'drawTime',
                    width: 200,
                },
                {
                    property: 'drawMethod',
                    width: 100,
                },
                {
                    property: 'whiteUserListFileUrl',
                    width: 180,
                },
                {
                    property: 'blackUserListFileUrl',
                    width: 180,
                },
                {
                    property: 'executeStatus',
                    width: 100,
                    displayConfig: {
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
                {
                    property: 'lotteryStatus',
                    width: 100,
                },
            ],
            actionColumn: {
                fixed: 'right',
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 220,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}`,
                                    apiPath:
                                        '/AFFILIATE-MARKETING/admin/lottery_code_activities/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isCloseActivity>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}`,
                                    apiPath:
                                        '/AFFILIATE-MARKETING/admin/lottery_code_activities/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/lottery-code/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/lottery-code/copy/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'ExportHandlerButton',
                        text: '<<dcbb>>',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/lottery_code_activities/{{row.id}}/export_jobs',
                        },
                    },
                    {
                        type: 'component',
                        component: 'NewImportButton',
                        statusKey: 'row.canImport',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
                            apiPath: '/admin/files/locations/65/upload',
                            text: '<<drzjmd>>',
                            templateSource: {
                                apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                apiPath: '/admin/import_templates?template_type=WINNER_LIST',
                            },
                            specialTips: ['4.<<l_rdcdrhfgzqdrdzjm_1>>'],
                        },
                    },
                ],
            },
        },
        LotteryCodeAddPage: {
            component: 'FlexLayout',
            entity: 'LotteryCodeEntity',
            direction: 'horizontal',
            items: [
                { component: 'WfjLotteryCodePreview' },
                { component: 'LotteryCodeAddFormView' },
            ],
        },
        LotteryCodeEditPage: {
            component: 'FlexLayout',
            entity: 'LotteryCodeEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [
                { component: 'WfjLotteryCodePreview' },
                { component: 'LotteryCodeEditFormView' },
            ],
        },
        LotteryCodeCopyPage: {
            component: 'FlexLayout',
            entity: 'LotteryCodeEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [
                { component: 'WfjLotteryCodePreview' },
                { component: 'LotteryCodeCopyFormView' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/lottery-code',
                    component: 'LotteryCodeView',
                    breadcrumbName: '<<lotteryCode>>',
                    privilege: {
                        path: 'lotteryCode',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'LotteryCodeAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'LotteryCodeEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/copy/:id',
                            component: 'LotteryCodeCopyPage',
                            breadcrumbName: '<<copyActivity>>',
                        },
                        { path: '/', component: 'LotteryCodePage' },
                    ],
                },
            ],
        },
    ],
};
