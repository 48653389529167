import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { get, isArray } from 'lodash';

export const config: Config = {
    entities: {
        MemberUpgradeGiftEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
            apiPath: '/loader/admin/member-upgrade-gift',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
                subSiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                subSites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            controlConfig: {
                                style: {
                                    width: 300,
                                },
                                maxLength: 20,
                                placeholder: '<<inputPlease>>',
                            },
                            rules: [
                                {
                                    required: true,
                                    max: 20,
                                    whitespace: true,
                                    message: '<<activityNameRequired>>',
                                },
                            ],
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 300,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subsiteIds: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            rules: [{ required: true }],
                        },
                        limitCrowds: {
                            type: 'array.receiveLimitationsCrowds',
                            displayName: '<<selectVipPeople>>',
                            controlConfig: {
                                pageType: 'CouponPackageRuleMemberLevel',
                            },
                        },
                        receiveType: {
                            type: 'string.options.radio.plus',
                            displayName: '<<jllqfs>>',
                            defaultValue: 'IMMEDIATELY',
                            controlConfig: {
                                setOptions: [
                                    {
                                        value: 'IMMEDIATELY',
                                        name: '<<sjhljff>>',
                                        tip: {
                                            text: '<<sjhljffTip>>',
                                        },
                                    },
                                    {
                                        value: 'USER_CLAIM',
                                        name: '<<yhzdlq>>',
                                        tip: {
                                            text: '<<yhzdlqTip>>',
                                        },
                                    },
                                ],
                            },
                            rules: [{ required: true }],
                        },
                    },
                },
                giftInfo: {
                    type: 'object.subForm',
                    displayName: '<<sjl>>',
                    properties: {
                        rewards: {
                            displayName: '<<lp>>',
                            type: 'array.upgradeGift',
                            rules: [{ required: true }],
                            controlConfig: {
                                getSubsiteIds: (row: any) => {
                                    return get(row, 'baseInfo.subsiteIds', [])
                                        .map((item: any) => item.id)
                                        .join(',');
                                },
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        MemberUpgradeGiftView: {
            component: 'Viewport',
            entity: 'MemberUpgradeGiftEntity',
        },
        MemberUpgradeGiftPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'MemberUpgradeGiftFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/member-upgrade-gift/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'MemberUpgradeGiftPageTable' },
            ],
        },
        MemberUpgradeGiftFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<cx>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsrhdmc>>',
                    },
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'subSiteIds',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        MemberUpgradeGiftPageTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'name',
                            width: 180,
                        },
                        {
                            property: 'startTime',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'endTime',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'subSites',
                            width: 200,
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '250px',
                                },
                                countSuffix: '<<subsites>>',
                            },
                        },
                        {
                            property: 'executeStatus',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                                statusConfig: {
                                    NOT_STARTED: {
                                        text: '<<notStarted>>',
                                        status: 'success',
                                    },
                                    STARTED: {
                                        text: '<<inprogress>>',
                                        status: 'processing',
                                    },
                                    COMPLETE: {
                                        text: '<<end>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<cz>>',
                        className: 'action-column',
                        width: 210,
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: false },
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                            apiPath: '/admin/upgrade_gift_activities/:id/disabled',
                                            config: {
                                                content: '<<hd>>{{row.name}}<<areadyClosed>>',
                                                text: '<<gb>>',
                                            },
                                            confirm: {
                                                text: '<<isCloseActivity>>{{row.name}}?',
                                            },
                                        },
                                        {
                                            params: { status: true },
                                            value: false,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                            apiPath: '/admin/upgrade_gift_activities/:id/enabled',
                                            config: {
                                                content: '<<hd>>{{row.name}}<<areadyOpend>>',
                                                text: '<<kq>>',
                                            },
                                            confirm: {
                                                text: '<<isAreadyOpend>><<hd>>{{row.name}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                text: '<<common.edit>>',
                                type: 'link',
                                path: '/member-upgrade-gift/edit/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        MemberUpgradeGiftAddPage: {
            component: 'Card',
            content: { component: 'MemberUpgradeGiftAddForm' },
        },
        MemberUpgradeGiftAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'MemberUpgradeGiftEntity',
            labelCol: 3,
            controlCol: 21,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'baseInfo.subsiteIds' },
                { property: 'baseInfo.limitCrowds' },
                { property: 'baseInfo.receiveType' },
                { property: 'giftInfo.rewards' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<qx>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                if (!entity.giftInfo.rewards || entity.giftInfo.rewards.length === 0) {
                    AntMessage.warn(services.language.getText('qtxsjllpgz'));
                    return false;
                }
                let flag = true;
                let msg = '';
                entity.giftInfo.rewards.forEach((item: any) => {
                    if (!item.cardLevelId) {
                        flag = false;
                        msg = services.language.getText('qxzlphddj');
                        return;
                    }
                    if (
                        (!item.assetRewards || item.assetRewards.length === 0) &&
                        !item.couponRewards
                    ) {
                        flag = false;
                        msg = services.language.getText('sjlzsnrzsxzyg');
                        return;
                    }
                    if (item.assetRewards && item.assetRewards.length > 0) {
                        const hasValue = item.assetRewards.every((assetReward: any) => {
                            return Boolean(assetReward.rewardValue);
                        });
                        if (!hasValue) {
                            flag = false;
                            msg = services.language.getText('qtxsjlzsnrz');
                            return;
                        }
                    }
                    if (isArray(item.couponRewards) && item.couponRewards.length === 0) {
                        flag = false;
                        msg = services.language.getText('qxzsjlzsnryhq');
                        return;
                    }
                    if (item.couponRewards) {
                        const hasCouponValue = item.couponRewards.every((couponReward: any) => {
                            return Boolean(couponReward.quantity);
                        });
                        if (!hasCouponValue) {
                            flag = false;
                            msg = services.language.getText('qtxsjlzsnryhqsl');
                            return;
                        }
                    }
                });
                if (!flag) {
                    AntMessage.warn(msg);
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('bccg'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        MemberUpgradeGiftEditPage: {
            component: 'Card',
            content: { component: 'MemberUpgradeGiftEditForm' },
        },
        MemberUpgradeGiftEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'MemberUpgradeGiftEntity',
            loaderType: 'get',
            labelCol: 3,
            controlCol: 21,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'baseInfo.subsiteIds' },
                { property: 'baseInfo.limitCrowds' },
                { property: 'baseInfo.receiveType' },
                { property: 'giftInfo.rewards' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<qx>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                if (!entity.giftInfo.rewards || entity.giftInfo.rewards.length === 0) {
                    AntMessage.warn(services.language.getText('qtxsjllpgz'));
                    return false;
                }
                let flag = true;
                let msg = '';
                entity.giftInfo.rewards.forEach((item: any) => {
                    if (!item.cardLevelId) {
                        flag = false;
                        msg = services.language.getText('qxzlphddj');
                        return;
                    }
                    if (
                        (!item.assetRewards || item.assetRewards.length === 0) &&
                        !item.couponRewards
                    ) {
                        flag = false;
                        msg = services.language.getText('sjlzsnrzsxzyg');
                        return;
                    }
                    if (item.assetRewards && item.assetRewards.length > 0) {
                        const hasValue = item.assetRewards.every((assetReward: any) => {
                            return Boolean(assetReward.rewardValue);
                        });
                        if (!hasValue) {
                            flag = false;
                            msg = services.language.getText('qtxsjlzsnrz');
                            return;
                        }
                    }
                    if (isArray(item.couponRewards) && item.couponRewards.length === 0) {
                        flag = false;
                        msg = services.language.getText('qxzsjlzsnryhq');
                        return;
                    }
                    if (item.couponRewards) {
                        const hasCouponValue = item.couponRewards.every((couponReward: any) => {
                            return Boolean(couponReward.quantity);
                        });
                        if (!hasCouponValue) {
                            flag = false;
                            msg = services.language.getText('qtxsjlzsnryhqsl');
                            return;
                        }
                    }
                });
                if (!flag) {
                    AntMessage.warn(msg);
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('bccg'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/member-upgrade-gift',
                    component: 'MemberUpgradeGiftView',
                    breadcrumbName: '<<hysjl>>',
                    privilege: {
                        path: 'memberv2UpgradeGift',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'MemberUpgradeGiftAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'MemberUpgradeGiftEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/',
                            component: 'MemberUpgradeGiftPage',
                        },
                    ],
                },
            ],
        },
    ],
};
