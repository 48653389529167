import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { forEach, get, filter } from 'lodash';
import { PayWithGiftRewardActivityRewardValue } from '../../../types/mode/object/pay-with-gifts-reward/pay-with-gifts-reward';
import { PushAndSiteMailSceneType } from '../../../containers/wechat/common';
import {
    checkBusinessNotifications,
    checkNotificationLink,
} from '../../../config/member/caring/birthday-caring';
export const config: Config = {
    entities: {
        PayWithGiftsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/pay-with-gifts',
            filters: {
                eventName: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsryhqlbmc>>', maxLength: 100 },
                },
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                    },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                    controlConfig: {
                        style: { width: 170 },
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                rangesName: {
                    type: 'string',
                    displayName: '<<xffw>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<jbgzsz>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                { required: true },
                                {
                                    message: '<<qsrhdmc>>',
                                    validator: (rule: any, value: any) => {
                                        if (value && !value.trim()) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: 380 },
                                maxLength: 100,
                            },
                        },
                        time: {
                            type: 'object.dateTimeRangePlus',
                            rules: [{ required: true }],
                            displayName: '<<acitivityTime>>',
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subsiteId: {
                            type: 'string.options.select',
                            displayName: '<<activityMerchant>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [
                                {
                                    required: true,
                                    message: '<<selectSubsite>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 380,
                                },
                                placeholder: '<<selectPlease>>',
                                allowClear: true,
                                showSearch: true,
                                optionFilterProp: 'children',
                                filterOption: (input: any, option: any) =>
                                    (option?.name ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase()),
                            },
                        },
                        selectMode: {
                            type: 'string.options.radio',
                            displayName: '<<hdzj>>',
                            options: [
                                { id: '0', name: '<<qbzjcy>>' },
                                { id: '1', name: '<<zdzjcy>>' },
                                { id: '2', name: '<<zdzjbcy>>' },
                            ],
                            defaultValue: '0',
                            rules: [{ required: true }],
                        },
                        merchants: {
                            type: 'array.merchant.selector.tick',
                            displayName: '<<selectSubsites>>',
                            defaultValue: [],
                            controlConfig: {
                                subsiteField: 'baseInfo.subsiteId',
                                needDependencesText: '<<qxxzmdhzxzzj>>',
                                hideSubsiteColume: false,
                                customFields: [
                                    {
                                        property: 'merchantType',
                                    },
                                    {
                                        property: 'merchantName',
                                    },
                                ],
                                customColumns: [
                                    {
                                        property: 'subSiteName',
                                    },
                                    {
                                        property: 'merchantName',
                                    },
                                    {
                                        property: 'code',
                                        sorter: true,
                                    },
                                    {
                                        property: 'merchantType',
                                    },
                                    {
                                        property: 'merchantStatus',
                                    },
                                ],
                            },
                            rules: [
                                {
                                    required: true,
                                    message: '<<pleaseSelectMerchant>>',
                                },
                            ],
                        },
                        range: {
                            displayName: '<<xffw>>',
                            type: 'array.optionIds.checkbox.tip',
                            rules: [{ required: true, message: '<<zxffw>>' }],
                            defaultValue: [],
                            options: [
                                { id: '1', name: '<<xsswdd>>' },
                                { id: '0', name: '<<xxdzxp>>' },
                                { id: '2', name: '<<sqxf>>' },
                            ],
                            controlConfig: {
                                tip: ['<<xtqjxxtdjxfsj>>', '<<qlxkhcgjljxzx>>'],
                            },
                        },
                        reward: {
                            displayName: '<<xftj>>',
                            type: 'array.payWithGiftsReward',
                        },
                        limits: {
                            displayName: '<<jlffxz>>',
                            type: 'object.payWithGiftsLimits',
                            rules: [{ required: true }],
                        },
                        businessNotifications: {
                            type: 'array.messageNotificationMethod',
                            displayName: '<<tzfs>>',
                            controlConfig: {
                                scene: PushAndSiteMailSceneType.PAY_AWARD_RECEIVE_REMIND,
                                title: '<<khxmzgtzqddcftj>>',
                            },
                        },
                        recover: {
                            displayName: '<<jlzh>>',
                            type: 'string.options.radio',
                            rules: [{ required: true }],
                            defaultValue: '1',
                            options: [
                                {
                                    id: '0',
                                    name: '<<tkhjlhs>>',
                                },
                                {
                                    id: '1',
                                    name: '<<tkhjlbhs>>',
                                },
                            ],
                            extra: '<<rxxddshwdjyzxdglgxs>>',
                        },
                        remark: {
                            type: 'string.richTextPlus',
                            displayName: '<<activityRule>>',
                            rules: [{ max: 20000, message: '<<notMoreThan2000>>' }],
                            controlConfig: {
                                placeholder: '<<descriptionRule>>',
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            extra: '<<descriptionExtra>>',
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],

                            controlConfig: {
                                subsiteField: 'baseInfo.subsiteId',
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    return { subsiteIds: row?.baseInfo?.subsiteId };
                                },
                            },
                        },
                    },
                },
            },
        },
        PayWithGiftConfigsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/event_market_page_configs',
            filters: {
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<zxsz>>',
                    properties: {
                        subsiteId: {
                            type: 'string.options.select',
                            displayName: '<<activityMerchant>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            controlConfig: {
                                style: {
                                    width: 380,
                                },
                                placeholder: '<<selectPlease>>',
                                allowClear: true,
                                showSearch: true,
                                optionFilterProp: 'children',
                                filterOption: (input: any, option: any) =>
                                    (option?.name ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase()),
                            },
                        },
                        backgroundPicture: {
                            displayName: '<<hdt>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://puzhehei.oss-cn-beijing.aliyuncs.com/global/magic-backend/share-page-activity/default-background.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                maxSize: 2 * 1024,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'single',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<payGiftPicLimitExtra>>',
                        },
                    },
                },
            },
        },
    },
    components: {
        PayWithGiftsView: {
            component: 'Viewport',
        },
        PayWithGiftsViewPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            style: {
                padding: 20,
            },
            items: [{ component: 'PayWithGiftsViewPageTabs' }],
        },
        PayWithGiftsViewPageTabs: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<mallActivitySetting>>',
                    content: {
                        component: 'PayWithGiftsPage',
                    },
                },
                {
                    title: '<<hdldypz>>',
                    content: {
                        component: 'PayWithGiftConfigsPage',
                    },
                },
            ],
        },
        PayWithGiftsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'PayWithGiftsEntity',
            className: 'table-content-viewport',
            style: {
                padding: '10px 15px',
            },
            items: [
                {
                    component: 'PayWithGiftsFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/pay-with-gifts/add',
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'PayWithGiftsTable',
                },
            ],
        },
        PayWithGiftsFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'eventName',
                },
                {
                    property: 'subsiteIds',
                },
                {
                    property: 'status',
                },
            ],
        },
        PayWithGiftsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'subsites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '200px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'rangesName',
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            scroll: { x: true },
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 220,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'status',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                    apiPath: '/admin/event_markets/:id/status',
                                    config: {
                                        content: '<<common.close>>',
                                        text: '<<common.close>>',
                                        style: { color: '#f5222d' },
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                    apiPath: '/admin/event_markets/:id/status',
                                    config: {
                                        content: '<<common.opend>>',
                                        text: '<<common.opend>>',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/menu-list/market/pay-with-gifts/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/menu-list/market/pay-with-gifts/copy/{{row.id}}',
                    },
                    {
                        text: '<<common.log>>',
                        type: 'link',
                        path: '/menu-list/market/pay-with-gifts/logs/{{row.id}}',
                    },
                    // {
                    //     type: 'link',
                    //     text: '<<reportData>>',
                    //     path: '/menu-list/market/pay-with-gifts/check/{{row.id}}',
                    // },
                ],
            },
        },
        PayWithGiftsAddPage: {
            component: 'Card',
            content: { component: 'PayWithGiftsAddForm' },
        },
        PayWithGiftsAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'PayWithGiftsEntity',
            labelCol: 2,
            controlCol: 22,
            fields: [
                {
                    property: 'baseInfo.name',
                    controlConfig: {
                        style: { width: 380 },
                        maxLength: 100,
                    },
                },
                {
                    property: 'baseInfo.time',
                },
                {
                    property: 'baseInfo.subsiteId',
                },
                {
                    property: 'baseInfo.selectMode',
                },
                {
                    property: 'baseInfo.merchants',
                    visible: (values: any) => {
                        return get(values, 'baseInfo.selectMode') !== '0';
                    },
                },
                {
                    property: 'baseInfo.range',
                },
                {
                    property: 'baseInfo.reward',
                },
                {
                    property: 'baseInfo.limits',
                },
                {
                    property: 'baseInfo.businessNotifications',
                },
                {
                    property: 'baseInfo.recover',
                },
                {
                    property: 'baseInfo.remark',
                },
                {
                    property: 'baseInfo.marketingSchedule',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const range = entity.baseInfo.range;
                const isOnlyOnline =
                    range && range.length === 1 && range.join(',').includes(1) ? true : false;
                const rewardVo = entity.baseInfo.reward;
                if (!rewardVo.ladderConf || rewardVo.ladderConf.length === 0) {
                    AntMessage.error(services.language.getText('zspzygjt'));
                    return false;
                }
                let maxConditionValue = 0;
                let errorTip = '';
                forEach(
                    rewardVo.ladderConf,
                    (reward: PayWithGiftRewardActivityRewardValue, index: any) => {
                        if (!reward.totalMoneyMin || !reward.totalMoneyMax) {
                            errorTip = services.interpolate(
                                services.language.getText('gjtxfmjdje'),
                                { index: index + 1 }
                            );
                            return false;
                        }
                        if (reward.totalMoneyMin > reward.totalMoneyMax) {
                            errorTip = services.interpolate(
                                services.language.getText('gjtzgxfxydyzdxfdje'),
                                { index: index + 1 }
                            );
                            return false;
                        }
                        if (reward.totalMoneyMin > maxConditionValue) {
                            maxConditionValue = reward.totalMoneyMax;
                        } else {
                            errorTip = services.interpolate(
                                services.language.getText('gjtzdxfxydysgjtdzgxf'),
                                { index: index + 1 }
                            );
                            return false;
                        }
                        if (!reward.couponsChecked && !reward.activitysChecked) {
                            errorTip = services.interpolate(
                                services.language.getText('gjtqzsgxygjlx'),
                                { index: index + 1 }
                            );
                            return false;
                        }
                        if (reward.couponsChecked) {
                            if (!reward.coupons || !reward.coupons.length) {
                                errorTip = services.interpolate(
                                    services.language.getText('saveFailToResult'),
                                    {
                                        num: index + 1,
                                    }
                                );
                                return false;
                            }
                            if (!reward.coupons[0].quantity) {
                                errorTip = services.interpolate(
                                    services.language.getText('saveFailToSendNum'),
                                    {
                                        num: index + 1,
                                    }
                                );
                                return false;
                            }
                            // if (
                            //     reward.distributionType &&
                            //     reward.distributionType === 1 &&
                            //     (!reward.rewardPicture ||
                            //         reward.rewardPicture.length === 0 ||
                            //         !reward.rewardPicture[0].id)
                            // ) {
                            //     errorTip = `保存失败，第${index + 1}个阶梯请上传用户领取入口图`;
                            //     return false;
                            // }
                        }
                        if (reward.activitysChecked) {
                            if (!reward.activitys || !reward.activitys.length) {
                                errorTip = services.interpolate(
                                    services.language.getText('gjtqxzcjhd'),
                                    { index: index + 1 }
                                );
                                return false;
                            }
                            if (!reward.activitys[0].quantity) {
                                errorTip = services.interpolate(
                                    services.language.getText('gjtqtxcjhdmrhdcs'),
                                    { index: index + 1 }
                                );
                                return false;
                            }
                            if (
                                !isOnlyOnline &&
                                (!reward.activityRewardPicture ||
                                    reward.activityRewardPicture.length === 0 ||
                                    !reward.activityRewardPicture[0].id)
                            ) {
                                errorTip = services.interpolate(
                                    services.language.getText('djgjtqscyh'),
                                    { index: index + 1 }
                                );
                                return false;
                            }
                        }
                    }
                );
                if (errorTip) {
                    AntMessage.error(errorTip);
                    return false;
                }
                const limitsVo = entity.baseInfo.limits;
                if (limitsVo.type === '0' && !limitsVo.dayLimitCount) {
                    AntMessage.error(services.language.getText('qsrmrmtffcs'));
                    return false;
                }
                if (limitsVo.type === '1' && !limitsVo.activityLimitCount) {
                    AntMessage.error(services.language.getText('qsrmrzhdqjffcs'));
                    return false;
                }
                const selectedInfo = filter(entity.baseInfo.businessNotifications || [], [
                    'status',
                    1,
                ]);
                if (selectedInfo.length > 0) {
                    for (let index = 0; index < selectedInfo.length; index++) {
                        const item = selectedInfo[index];
                        if (!checkBusinessNotifications(item)) {
                            return false;
                        }
                        if (!checkNotificationLink(item)) {
                            return false;
                        }
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        PayWithGiftsEditPage: {
            component: 'FlexLayout',
            entity: 'PayWithGiftsEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'PayWithGiftsEditForm' }],
        },
        PayWithGiftsEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'PayWithGiftsEntity',
            loaderType: 'get',
            labelCol: 2,
            controlCol: 22,
            fields: [
                {
                    property: 'baseInfo.name',
                    controlConfig: {
                        style: { width: 380 },
                        maxLength: 100,
                    },
                },
                {
                    property: 'baseInfo.time',
                },
                {
                    property: 'baseInfo.subsiteId',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'baseInfo.selectMode',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'baseInfo.merchants',
                    controlConfig: {
                        disabled: true,
                    },
                    visible: (values: any) => {
                        return get(values, 'baseInfo.selectMode') !== '0';
                    },
                },
                {
                    property: 'baseInfo.range',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'baseInfo.reward',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'baseInfo.limits',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'baseInfo.businessNotifications',
                },
                {
                    property: 'baseInfo.recover',
                },
                {
                    property: 'baseInfo.remark',
                },
                {
                    property: 'baseInfo.marketingSchedule',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const range = entity.baseInfo.range;
                const isOnlyOnline =
                    range && range.length === 1 && range.join(',').includes(1) ? true : false;
                const rewardVo = entity.baseInfo.reward;
                if (!rewardVo.ladderConf || rewardVo.ladderConf.length === 0) {
                    AntMessage.error(services.language.getText('zspzygjt'));
                    return false;
                }
                let maxConditionValue = 0;
                let errorTip = '';
                forEach(
                    rewardVo.ladderConf,
                    (reward: PayWithGiftRewardActivityRewardValue, index: any) => {
                        if (!reward.totalMoneyMin || !reward.totalMoneyMax) {
                            errorTip = services.interpolate(
                                services.language.getText('gjtxfmjdje'),
                                { index: index + 1 }
                            );
                            return false;
                        }
                        if (reward.totalMoneyMin > reward.totalMoneyMax) {
                            errorTip = services.interpolate(
                                services.language.getText('gjtzgxfxydyzdxfdje'),
                                { index: index + 1 }
                            );
                            return false;
                        }
                        if (reward.totalMoneyMin > maxConditionValue) {
                            maxConditionValue = reward.totalMoneyMax;
                        } else {
                            errorTip = services.interpolate(
                                services.language.getText('gjtzdxfxydysgjtdzgxf'),
                                { index: index + 1 }
                            );
                            return false;
                        }
                        if (!reward.couponsChecked && !reward.activitysChecked) {
                            errorTip = services.interpolate(
                                services.language.getText('gjtqzsgxygjlx'),
                                { index: index + 1 }
                            );
                            return false;
                        }
                        if (reward.couponsChecked) {
                            if (!reward.coupons || !reward.coupons.length) {
                                errorTip = services.interpolate(
                                    services.language.getText('saveFailToResult'),
                                    {
                                        num: index + 1,
                                    }
                                );
                                return false;
                            }
                            if (!reward.coupons[0].quantity) {
                                errorTip = services.interpolate(
                                    services.language.getText('saveFailToSendNum'),
                                    {
                                        num: index + 1,
                                    }
                                );
                                return false;
                            }
                            // if (
                            //     reward.distributionType &&
                            //     reward.distributionType === 1 &&
                            //     (!reward.rewardPicture ||
                            //         reward.rewardPicture.length === 0 ||
                            //         !reward.rewardPicture[0].id)
                            // ) {
                            //     errorTip = `保存失败，第${index + 1}个阶梯请上传用户领取入口图`;
                            //     return false;
                            // }
                        }
                        if (reward.activitysChecked) {
                            if (!reward.activitys || !reward.activitys.length) {
                                errorTip = services.interpolate(
                                    services.language.getText('gjtqxzcjhd'),
                                    { index: index + 1 }
                                );
                                return false;
                            }
                            if (!reward.activitys[0].quantity) {
                                errorTip = services.interpolate(
                                    services.language.getText('gjtqtxcjhdmrhdcs'),
                                    { index: index + 1 }
                                );
                                return false;
                            }
                            if (
                                !isOnlyOnline &&
                                (!reward.activityRewardPicture ||
                                    reward.activityRewardPicture.length === 0 ||
                                    !reward.activityRewardPicture[0].id)
                            ) {
                                errorTip = services.interpolate(
                                    services.language.getText('djgjtqscyh'),
                                    { index: index + 1 }
                                );
                                return false;
                            }
                        }
                    }
                );
                if (errorTip) {
                    AntMessage.error(errorTip);
                    return false;
                }
                const limitsVo = entity.baseInfo.limits;
                if (limitsVo.type === '0' && !limitsVo.dayLimitCount) {
                    AntMessage.error(services.language.getText('qsrmrmtffcs'));
                    return false;
                }
                if (limitsVo.type === '1' && !limitsVo.activityLimitCount) {
                    AntMessage.error(services.language.getText('qsrmrzhdqjffcs'));
                    return false;
                }
                const selectedInfo = filter(entity.baseInfo.businessNotifications || [], [
                    'status',
                    1,
                ]);
                if (selectedInfo.length > 0) {
                    for (let index = 0; index < selectedInfo.length; index++) {
                        const item = selectedInfo[index];
                        if (!checkBusinessNotifications(item)) {
                            return false;
                        }
                        if (!checkNotificationLink(item)) {
                            return false;
                        }
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        PayWithGiftsCopyPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'PayWithGiftsCopyForm' }],
        },
        PayWithGiftsCopyForm: {
            component: 'CopyFormPlus',
            direction: 'horizontal',
            entity: 'PayWithGiftsEntity',
            loaderType: 'get',
            labelCol: 2,
            controlCol: 22,
            fields: [
                {
                    property: 'baseInfo.name',
                    controlConfig: {
                        style: { width: 380 },
                        maxLength: 100,
                    },
                },
                {
                    property: 'baseInfo.time',
                },
                {
                    property: 'baseInfo.subsiteId',
                },
                {
                    property: 'baseInfo.selectMode',
                },
                {
                    property: 'baseInfo.merchants',
                    visible: (values: any) => {
                        return get(values, 'baseInfo.selectMode') !== '0';
                    },
                },
                {
                    property: 'baseInfo.range',
                },
                {
                    property: 'baseInfo.reward',
                },
                {
                    property: 'baseInfo.limits',
                },
                {
                    property: 'baseInfo.businessNotifications',
                },
                {
                    property: 'baseInfo.recover',
                },
                {
                    property: 'baseInfo.remark',
                },
                {
                    property: 'baseInfo.marketingSchedule',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const range = entity.baseInfo.range;
                const isOnlyOnline =
                    range && range.length === 1 && range.join(',').includes(1) ? true : false;
                const rewardVo = entity.baseInfo.reward;
                if (!rewardVo.ladderConf || rewardVo.ladderConf.length === 0) {
                    AntMessage.error(services.language.getText('zspzygjt'));
                    return false;
                }
                let maxConditionValue = 0;
                let errorTip = '';
                forEach(
                    rewardVo.ladderConf,
                    (reward: PayWithGiftRewardActivityRewardValue, index: any) => {
                        if (!reward.totalMoneyMin || !reward.totalMoneyMax) {
                            errorTip = services.interpolate(
                                services.language.getText('gjtxfmjdje'),
                                { index: index + 1 }
                            );
                            return false;
                        }
                        if (reward.totalMoneyMin > reward.totalMoneyMax) {
                            errorTip = services.interpolate(
                                services.language.getText('gjtzgxfxydyzdxfdje'),
                                { index: index + 1 }
                            );
                            return false;
                        }
                        if (reward.totalMoneyMin > maxConditionValue) {
                            maxConditionValue = reward.totalMoneyMax;
                        } else {
                            errorTip = services.interpolate(
                                services.language.getText('gjtzdxfxydysgjtdzgxf'),
                                { index: index + 1 }
                            );
                            return false;
                        }
                        if (reward.couponsChecked) {
                            if (!reward.coupons || !reward.coupons.length) {
                                errorTip = services.interpolate(
                                    services.language.getText('saveFailToResult'),
                                    { num: index + 1 }
                                );
                                return false;
                            }
                            if (!reward.coupons[0].quantity) {
                                errorTip = services.interpolate(
                                    services.language.getText('saveFailToSendNum'),
                                    { num: index + 1 }
                                );
                                return false;
                            }
                            // if (
                            //     reward.distributionType &&
                            //     reward.distributionType === 1 &&
                            //     (!reward.rewardPicture ||
                            //         reward.rewardPicture.length === 0 ||
                            //         !reward.rewardPicture[0].id)
                            // ) {
                            //     errorTip = `保存失败，第${index + 1}个阶梯请上传用户领取入口图`;
                            //     return false;
                            // }
                        }
                        if (!reward.couponsChecked && !reward.activitysChecked) {
                            errorTip = services.interpolate(
                                services.language.getText('gjtqzsgxygjlx'),
                                { index: index + 1 }
                            );
                            return false;
                        }
                        if (reward.activitysChecked) {
                            if (!reward.activitys || !reward.activitys.length) {
                                errorTip = services.interpolate(
                                    services.language.getText('gjtqxzcjhd'),
                                    { index: index + 1 }
                                );
                                return false;
                            }
                            if (!reward.activitys[0].quantity) {
                                errorTip = services.interpolate(
                                    services.language.getText('gjtqtxcjhdmrhdcs'),
                                    { index: index + 1 }
                                );
                                return false;
                            }
                            if (
                                !isOnlyOnline &&
                                (!reward.activityRewardPicture ||
                                    reward.activityRewardPicture.length === 0 ||
                                    !reward.activityRewardPicture[0].id)
                            ) {
                                errorTip = services.interpolate(
                                    services.language.getText('djgjtqscyh'),
                                    { index: index + 1 }
                                );
                                return false;
                            }
                        }
                    }
                );
                if (errorTip) {
                    AntMessage.error(errorTip);
                    return false;
                }
                const limitsVo = entity.baseInfo.limits;
                if (limitsVo.type === '0' && !limitsVo.dayLimitCount) {
                    AntMessage.error(services.language.getText('qsrmrmtffcs'));
                    return false;
                }
                if (limitsVo.type === '1' && !limitsVo.activityLimitCount) {
                    AntMessage.error(services.language.getText('qsrmrzhdqjffcs'));
                    return false;
                }
                const selectedInfo = filter(entity.baseInfo.businessNotifications || [], [
                    'status',
                    1,
                ]);
                if (selectedInfo.length > 0) {
                    for (let index = 0; index < selectedInfo.length; index++) {
                        const item = selectedInfo[index];
                        if (!checkBusinessNotifications(item)) {
                            return false;
                        }
                        if (!checkNotificationLink(item)) {
                            return false;
                        }
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        PayWithGiftsCheckPage: {
            component: 'Card',
            content: { component: 'PayWithGiftsCheckView' },
        },
        PayWithGiftsCheckView: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'Tabs',
                    type: 'card',
                    cache: false,
                    animated: false,
                    items: [
                        {
                            title: '<<hdsj>>',
                            content: { component: 'EventMarketPayCheckView' },
                        },
                    ],
                },
            ],
        },
        PayWithGiftsLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: '/admin/event_markets/{id}/operation_logs',
                },
                { component: 'PageBackFooter' },
            ],
        },
        PayWithGiftConfigsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'PayWithGiftConfigsEntity',
            className: 'table-content-viewport',
            style: {
                padding: '10px 15px',
            },
            items: [
                {
                    component: 'PayWithGiftConfigsFilter',
                },
                {
                    component: 'PayWithGiftConfigsTable',
                },
            ],
        },
        PayWithGiftConfigsFilter: {
            component: 'FilterForm',
            direction: 'inline',
            style: {
                marginBottom: 20,
            },
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteIds',
                },
            ],
        },
        PayWithGiftConfigsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'subsiteName',
                },
                // {
                //     property: 'backgroundPictureUrl',
                // },
            ],
            scroll: { x: true },
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 220,
                fixed: 'right',
                items: [
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path:
                            '/menu-list/market/pay-with-gifts/edit-pay-with-gifts-config/{{row.subsiteId}}',
                    },
                    {
                        type: 'component',
                        component: 'PageNewPreviewCodeButton',
                        config: {
                            codeTypes: 'all',
                            codeModalType: 'item',
                            codePageType: 'paymentRewardActivity',
                        },
                    },
                ],
            },
        },
        PayWithGiftConfigEditPage: {
            component: 'FlexLayout',
            entity: 'PayWithGiftConfigsEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [
                { component: 'PayWithGiftConfigPreview' },
                { component: 'PayWithGiftConfigEditForm' },
            ],
        },
        PayWithGiftConfigEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            style: { width: 600 },
            labelCol: 6,
            controlCol: 18,
            fields: [
                {
                    property: 'id',
                    className: 'hidden',
                },
                {
                    property: 'subsiteName',
                    className: 'hidden',
                },
                {
                    property: 'baseInfo.subsiteId',
                    controlConfig: {
                        disabled: true,
                    },
                },
                { property: 'baseInfo.backgroundPicture' },
            ],
            submit: true,
            onValidate: (entity: any) => {
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/pay-with-gifts',
                    component: 'PayWithGiftsView',
                    breadcrumbName: '<<zfyl>>',
                    privilege: {
                        path: 'payAward',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'PayWithGiftsAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'PayWithGiftsEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/copy/:id',
                            component: 'PayWithGiftsCopyPage',
                            breadcrumbName: '<<copyActivity>>',
                        },
                        {
                            path: '/check/:id',
                            component: 'PayWithGiftsCheckPage',
                            breadcrumbName: '<<viewData>>',
                        },
                        {
                            path: '/logs/:id',
                            component: 'PayWithGiftsLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        {
                            path: '/edit-pay-with-gifts-config/:editSubsiteId',
                            component: 'PayWithGiftConfigEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        { path: '/', component: 'PayWithGiftsViewPage' },
                    ],
                },
            ],
        },
    ],
};
