import React, { Component } from 'react';
import {
    Button,
    DatePicker,
    Input,
    InputNumber,
    message as AntMessage,
    Modal,
    Radio,
    Select,
} from 'antd';
import moment from 'moment';
import { Textbox } from '@comall-backend-builder/components-basis';
import { SpaceType } from '../restaurant-reservation-list-item-buttons';
import { api, language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

export interface States {
    subsites: any[];
    merchants: any[];
    spaceTypes: Array<SpaceType>;
    subsiteId?: string;
    merchantId?: string;
    visible: boolean;
    reservationTime: string;
    reservationUserMobile: string;
    reservationUserName: string;
    reservationUserSex: string;
    useUserMobile: string;
    useUserName: string;
    spaceType?: SpaceType;
    totalCount: number;
    remark: string;
    location: string;
    sendMsg: boolean;
}

export class RestaurantReservationAddButton extends Component<any, States> {
    constructor(props: any) {
        super(props);
        this.state = {
            subsites: [],
            merchants: [],
            spaceTypes: [],
            visible: false,
            reservationTime: '',
            reservationUserMobile: '',
            reservationUserName: '',
            reservationUserSex: 'MAN',
            useUserMobile: '',
            useUserName: '',
            spaceType: undefined,
            totalCount: 1,
            remark: '',
            location: '',
            sendMsg: true,
        };
    }

    componentDidMount() {
        this.getSubsites();
    }

    getSubsites = () => {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: '/admin/subsites/mine',
            }
        ).then((response: any) => {
            this.setState({
                subsites: response.result,
            });
        });
    };

    handleChangeSubsite = (value: string) => {
        this.setState({
            subsiteId: value,
            merchantId: undefined,
        });
        this.getMerchantData(value);
    };

    handleChangeMerchant = (value: string) => {
        this.setState({
            merchantId: value,
        });
        this.getSpaceTypes(value);
    };

    getMerchantData = async (subsiteId: string) => {
        const res = await api.get<any[]>(
            {
                subSiteName: subsiteId,
                sequence: 'code',
                order: 'asc',
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: '/admin/subsites/mine/merchants',
            }
        );
        this.setState({
            merchants: res,
        });
    };

    getSpaceTypes = (merchantId?: string) => {
        api.get(
            {
                id: merchantId,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SHOP`,
                apiPath: `/admin/merchants/${merchantId}`,
            }
        ).then((res: any) => {
            //取专柜配置的和该条单子选中的类型并集
            const spaceTypes =
                res.restaurantReservation && res.restaurantReservation.spaceTypes.length > 0
                    ? res.restaurantReservation.spaceTypes
                    : [];
            this.setState({
                visible: true,
                spaceTypes,
                spaceType: spaceTypes.length > 0 ? spaceTypes[0] : undefined,
            });
        });
    };

    disabledDate(current: moment.Moment | null) {
        return !!(current && current < moment().subtract(1, 'days'));
    }

    onTimeChange = (val: any) => {
        const timeStr = moment(val || undefined).format('YYYY-MM-DD HH:mm:ss');
        this.setState({
            reservationTime: timeStr,
        });
    };

    isPhone(phone?: string): boolean {
        const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (!phone) {
            return false;
        }
        return reg.test(phone);
    }

    handleSubmit = (audited: boolean) => {
        const { entity, params } = this.props;
        const {
            reservationTime,
            spaceType,
            totalCount,
            subsiteId,
            merchantId,
            reservationUserName,
            reservationUserMobile,
            useUserMobile,
            useUserName,
            remark,
            location,
            reservationUserSex,
            sendMsg,
        } = this.state;
        if (!subsiteId) {
            AntMessage.warning(services.language.getText('selectSubsite'));
            return;
        }
        if (!merchantId) {
            AntMessage.warning(language.getText('pleaseSelectMerchant'));
            return;
        }
        if (!totalCount) {
            AntMessage.warning(language.getText('qtxycrs'));
            return;
        }
        if (!reservationTime) {
            AntMessage.warning(language.getText('qxzycsj'));
            return;
        }
        if (!spaceType) {
            AntMessage.warning(language.getText('qxzydwz'));
            return;
        }
        if (!reservationUserName) {
            AntMessage.warning(language.getText('qtxyyrxm'));
            return;
        }
        if (!reservationUserMobile) {
            AntMessage.warning(language.getText('qtxyyrsjh'));
            return;
        }
        if (!this.isPhone(reservationUserMobile)) {
            AntMessage.warning(language.getText('qtxzqdyyrsjh'));
            return;
        }
        if (useUserMobile && !this.isPhone(useUserMobile)) {
            AntMessage.warning(language.getText('qtxzqdycrsjh'));
            return;
        }
        const changeParams = {
            audited,
            info: {
                location,
                reservationTime,
                reservationUserMobile,
                reservationUserName,
                reservationUserSex,
                useUserMobile,
                useUserName,
                spaceType,
                totalCount,
            },
            merchantId,
            remark,
            sendMsg,
            subsiteId,
        };
        //新增
        api.post(
            {
                ...changeParams,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SHOP`,
                apiPath: '/admin/restaurant_reservation_orders',
            }
        ).then(
            () => {
                AntMessage.success(language.getText('xzcg'));
                entity.search(params);
                this.setState({
                    visible: false,
                });
            },
            (error) => {
                services.errorHandle(error);
            }
        );
    };
    onCancel = () => {
        this.setState({
            visible: false,
        });
    };
    onAdd = () => {
        this.setState({
            visible: true,
            subsiteId: undefined,
            merchantId: undefined,
            reservationTime: '',
            reservationUserMobile: '',
            reservationUserName: '',
            reservationUserSex: 'MAN',
            useUserMobile: '',
            useUserName: '',
            spaceType: undefined,
            totalCount: 1,
            remark: '',
            location: '',
        });
    };

    renderFooter = () => {
        return (
            <div>
                <Button onClick={this.handleSubmit.bind(this, false)}>
                    {language.getText('qrdz')}
                </Button>
                <Button type="primary" onClick={this.handleSubmit.bind(this, true)}>
                    {language.getText('qrdzbshtg')}
                </Button>
            </div>
        );
    };

    renderModal = () => {
        const {
            subsites,
            merchants,
            visible,
            spaceTypes,
            totalCount,
            spaceType,
            reservationTime,
            reservationUserName,
            reservationUserMobile,
            reservationUserSex,
            useUserName,
            useUserMobile,
            remark,
            location,
            sendMsg,
        } = this.state;
        return (
            <Modal
                destroyOnClose
                visible={visible}
                title={language.getText('xzydjl')}
                width="570px"
                onCancel={this.onCancel}
                footer={this.renderFooter()}
            >
                <div className="restaurant-reservation-modal">
                    <div className="item">
                        <div className="item-title">
                            <span className="red">*&nbsp;</span>
                            {language.getText('otherSelectSubsite')}：
                        </div>
                        <div className="item-content">
                            <Select
                                style={{ width: '200px' }}
                                showSearch
                                placeholder={services.language.getText('selectPlease')}
                                optionFilterProp="name"
                                filterOption={(input, option: any) =>
                                    (option.props.children || '')
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                onChange={this.handleChangeSubsite}
                            >
                                {subsites &&
                                    subsites.map((subsite: any) => {
                                        return (
                                            <Select.Option key={subsite.id} value={subsite.id}>
                                                {subsite.name}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-title">
                            <span className="red">*&nbsp;</span>
                            {language.getText('selectSubsites')}：
                        </div>
                        <div className="item-content">
                            <Select
                                style={{ width: '200px' }}
                                showSearch
                                placeholder={services.language.getText('selectPlease')}
                                optionFilterProp="merchantName"
                                filterOption={(input, option: any) =>
                                    (option.props.children || '')
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                                disabled={!merchants.length || !merchants}
                                onChange={this.handleChangeMerchant}
                            >
                                {merchants &&
                                    merchants.map((merchant: any) => {
                                        return (
                                            <Select.Option
                                                key={merchant.merchantId}
                                                value={merchant.merchantId}
                                            >
                                                {merchant.merchantName}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-title">
                            <span className="red">*&nbsp;</span>
                            {language.getText('ycrs')}：
                        </div>
                        <div className="item-content">
                            <InputNumber
                                style={{ width: 100 }}
                                placeholder={services.language.getText('inputPlease')}
                                min={1}
                                max={100}
                                step={1}
                                precision={0}
                                value={totalCount}
                                onChange={(value: any) => {
                                    this.onChangeValue('totalCount', value);
                                }}
                            ></InputNumber>
                            &nbsp;&nbsp;{language.getText('people')}
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-title">
                            <span className="red">*&nbsp;</span>
                            {language.getText('ycsj')}：
                        </div>
                        <div className="item-content">
                            <DatePicker
                                style={{ width: '150px' }}
                                format="YYYY-MM-DD HH:mm:ss"
                                showTime
                                value={reservationTime ? moment(reservationTime) : undefined}
                                onChange={this.onTimeChange}
                                placeholder={services.language.getText('selectPlease')}
                                disabledDate={this.disabledDate}
                            />
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-title">
                            <span className="red">*&nbsp;</span>
                            {language.getText('ydwz')}：
                        </div>
                        <div className="item-content">
                            {spaceTypes && spaceTypes.length > 0 ? (
                                <Radio.Group
                                    value={spaceType}
                                    onChange={(value: any) => {
                                        this.onChangeValue('spaceType', value.target.value);
                                    }}
                                >
                                    {spaceTypes.map((s: SpaceType) => {
                                        const spaceTypes = {
                                            [SpaceType.HALL]: language.getText('hall'),
                                            [SpaceType.BOX]: language.getText('privateRoom'),
                                        };
                                        return <Radio value={s}>{spaceTypes[s]}</Radio>;
                                    })}
                                </Radio.Group>
                            ) : (
                                <span style={{ color: 'rgba(0, 0, 0, 0.3)' }}>
                                    {language.getText('qxzykqctydfwdzj')}
                                </span>
                            )}
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-title">{language.getText('wzmc')}：</div>
                        <div className="item-content">
                            <Input
                                style={{ width: 200 }}
                                placeholder={services.language.getText('inputPlease')}
                                value={location}
                                onInput={(data: any) =>
                                    this.onChangeValue('location', data.target.value)
                                }
                                maxLength={50}
                            />
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-title">
                            <span className="red">*&nbsp;</span>
                            {language.getText('ydrxm')}：
                        </div>
                        <div className="item-content">
                            <Input
                                style={{ width: 200 }}
                                placeholder={services.language.getText('inputPlease')}
                                value={reservationUserName}
                                onInput={(data: any) =>
                                    this.onChangeValue('reservationUserName', data.target.value)
                                }
                                maxLength={50}
                            />
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-title">
                            <span className="red">*&nbsp;</span>
                            {language.getText('ydrsjh')}：
                        </div>
                        <div className="item-content">
                            <Input
                                style={{ width: 200 }}
                                placeholder={services.language.getText('inputPlease')}
                                value={reservationUserMobile}
                                onInput={(data: any) =>
                                    this.onChangeValue('reservationUserMobile', data.target.value)
                                }
                                maxLength={50}
                            />
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-title">
                            <span className="red">*&nbsp;</span>
                            {language.getText('ydrxb')}：
                        </div>
                        <div className="item-content">
                            <Radio.Group
                                value={reservationUserSex}
                                onChange={(value: any) => {
                                    this.onChangeValue('reservationUserSex', value.target.value);
                                }}
                            >
                                <Radio value={'MAN'}>{language.getText('xs_2')}</Radio>
                                <Radio value={'WOMAN'}>{language.getText('ns')}</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-title">{language.getText('ycrxm')}：</div>
                        <div className="item-content">
                            <Input
                                style={{ width: 200 }}
                                placeholder={services.language.getText('inputPlease')}
                                value={useUserName}
                                onInput={(data: any) =>
                                    this.onChangeValue('useUserName', data.target.value)
                                }
                                maxLength={50}
                            />
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-title">{language.getText('ycrsjh')}：</div>
                        <div className="item-content">
                            <Input
                                style={{ width: 200 }}
                                placeholder={services.language.getText('inputPlease')}
                                value={useUserMobile}
                                onInput={(data: any) =>
                                    this.onChangeValue('useUserMobile', data.target.value)
                                }
                                maxLength={50}
                            />
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-title">{language.getText('remark')}：</div>
                        <div className="item-content">
                            <Textbox
                                prefixIcon=""
                                suffixIcon=""
                                type="textarea"
                                autoSize={{ minRows: 4, maxRows: 4 }}
                                style={{
                                    width: '400px',
                                }}
                                name="textBox"
                                placeholder={language.getText('qsrqrdxx')}
                                value={remark}
                                onChange={(value: any) => this.onChangeValue('remark', value)}
                            />
                        </div>
                    </div>
                    <div className="item">
                        <div className="item-title">{language.getText('dxtz')}：</div>
                        <div className="item-content">
                            <Radio.Group
                                value={sendMsg}
                                onChange={(value: any) => {
                                    this.onChangeValue('sendMsg', value.target.value);
                                }}
                            >
                                <Radio value={true}>{language.getText('fs_1')}</Radio>
                                <Radio value={false}>{language.getText('bfs')}</Radio>
                            </Radio.Group>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    onChangeValue(name: string, inputValue: any) {
        this.setState({
            [name]: inputValue,
        } as Pick<States, 'reservationTime' | 'reservationUserMobile' | 'reservationUserName' | 'reservationUserSex' | 'useUserMobile' | 'useUserName' | 'spaceType' | 'totalCount' | 'remark' | 'location' | 'sendMsg'>);
        return {};
    }

    render() {
        return (
            <div>
                <Button type="primary" onClick={this.onAdd} style={{ margin: '15px 0' }}>
                    {language.getText('xzydjl')}
                </Button>
                {this.renderModal()}
            </div>
        );
    }
}
