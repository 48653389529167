import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { CardLevelRightsDisplay } from '../../../components';
import { CardLevelRights } from '../../../containers';

/**
 * 会员卡权益
 */
export class CardLevelRightsMode extends modes.ArrayMode {
    getDisplayComponent(value: any) {
        return (
            <CardLevelRightsDisplay
                data={value}
                modifiable={false}
                onChange={() => {}}
            ></CardLevelRightsDisplay>
        );
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <CardLevelRights {...displayInfo} />;
    }
}
