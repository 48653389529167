import { Config } from '@comall-backend-builder/core/lib/parser';

export const SelectionPoolConfig: Config = {
    entities: {
        SelectionPoolEntity: {
            apiPath: '/loader/admin/sales-electronic-coupon',
            properties: {},
        },
    },
    components: {
        SelectionPoolView: {
            component: 'Viewport',
        },
        SelectionPoolPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport-no-padding',
            items: [
                {
                    component: 'SelectionPoolFind',
                },
            ],
        },
        SelectionPoolDetailPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport-no-padding',
            items: [
                {
                    component: 'SelectionProductDetail',
                },
            ],
        },
        SelectionPoolRiskCommodityListPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport-no-padding',
            items: [
                {
                    component: 'RiskCommodityList',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/selection-pool',
                    component: 'SelectionPoolView',
                    breadcrumbName: '<<selectCategory>>',
                    privilege: {
                        path: 'goodsPool',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/detail/:id',
                            component: 'SelectionPoolDetailPage',
                        },
                        {
                            path: '/risk-goods',
                            component: 'SelectionPoolRiskCommodityListPage',
                        },
                        {
                            path: '/',
                            component: 'SelectionPoolPage',
                        },
                    ],
                },
            ],
        },
    ],
};
