import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import './index.less';
const api = services.api;

/**
 * bi数据展示页面
 */
export class BiIframePage extends PureComponent<{
    params?: any;
}> {
    state = { urls: [] };
    readonly initIframeHeight = window.innerHeight - 150;
    componentDidMount() {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: this.props.params.apiPath,
            }
        ).then((response: any) => {
            this.setState({
                urls: response,
            });
        });
    }

    render() {
        const { urls } = this.state;
        return (
            <div style={{ height: '100%' }}>
                {urls && urls.length > 0 && (
                    <div style={{ height: '100%' }}>
                        {urls.map((url, index) => {
                            return (
                                <iframe
                                    key={index}
                                    src={url}
                                    frameBorder="0"
                                    width="100%"
                                    height="100%"
                                    name="iframe"
                                    title={url}
                                />
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
}
