import React, { Component } from 'react';
import { CheckboxGroup } from '@comall-backend-builder/components-basis';
import { TipsModal } from '../../../components';
import { modes } from '@comall-backend-builder/types';

export class ArrayCheckboxWithTipsModalMode extends modes.StringMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <CheckboxWithTipsModal {...displayInfo} />;
    }
}

class CheckboxWithTipsModal extends Component<any, any> {
    onChange(value: any) {
        const { name, onChange } = this.props;

        if (onChange) {
            onChange(value, name);
        }
    }
    render() {
        const { value, name, options, tipsModalConfig } = this.props;
        if (tipsModalConfig.type === 'extra') {
            const {
                extraConfig: { before, after },
            } = tipsModalConfig;
            return (
                <div>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '9px' }}>
                        <CheckboxGroup
                            name={name}
                            onChange={this.onChange}
                            options={options}
                            value={value}
                            {...(this.props as any)}
                        />
                    </div>
                    {tipsModalConfig && (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: 5,
                                color: '#ccc',
                            }}
                        >
                            {before && <span>{before}</span>}
                            <TipsModal {...tipsModalConfig} />
                            {after && <span>{after}</span>}
                        </div>
                    )}
                </div>
            );
        }
        return (
            <div style={{ display: 'flex', alignItems: 'center', marginTop: '9px' }}>
                <CheckboxGroup {...(this.props as any)} />
                {tipsModalConfig && (
                    <div style={{ marginLeft: 5 }}>
                        <TipsModal {...tipsModalConfig} />
                    </div>
                )}
            </div>
        );
    }
}
