import React, { PureComponent } from 'react';
import { cloneDeep, get, isEqual } from 'lodash';
import { MerchantsSelector } from './merchants-selector';
import { MerchantsSelectorDisplay } from './merchants-selector-display';
import { Button, message, Radio } from 'antd';
import './index.less';
import { UploadFile } from '../../array/merchant-selecter-plus/upload-file';
import { services } from '@comall-backend-builder/core';

export interface MerchantsSelectorItem {
    id: string;
    name: string;
    status: string;
    type: string;
    subSiteId: string;
}

interface DeliveryLogisticsConfigsMerchantsVo {
    selectMode: DeliveryLogisticsConfigsMerchantsSelectMode;
    merchants: MerchantsSelectorItem[];
}

export enum DeliveryLogisticsConfigsMerchantsSelectMode {
    True = 'true',
    Fales = 'false',
}

interface DeliveryLogisticsConfigsMerchantProps {
    row: any;
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    value: DeliveryLogisticsConfigsMerchantsVo;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: DeliveryLogisticsConfigsMerchantsVo) => void;
    /**
     * 提示
     */
    merchantTip?: string;
}
const defalutValue: DeliveryLogisticsConfigsMerchantsVo = {
    selectMode: DeliveryLogisticsConfigsMerchantsSelectMode.True,
    merchants: [],
};

export interface DeliveryLogisticsConfigsMerchantState {
    importModalVisible: boolean;
}
const subsiteFiled = 'baseInfo.subsite';
export class DeliveryLogisticsConfigsMerchants extends PureComponent<
    DeliveryLogisticsConfigsMerchantProps,
    DeliveryLogisticsConfigsMerchantState
> {
    constructor(props: any) {
        super(props);
        this.state = { importModalVisible: false };
    }
    componentDidMount() {
        const { value } = this.props;
        if (!value) {
            this.onChange(defalutValue);
        } else {
            //当改变门店，初次加载上次遗留的其他门店专柜如果不是新门店的则清空
            const preSubsites = get(this.props.row, subsiteFiled);
            if (
                preSubsites &&
                preSubsites.id &&
                value.merchants &&
                value.merchants.length > 0 &&
                !isEqual(preSubsites.id, value.merchants[0].subSiteId)
            ) {
                this.onChange(defalutValue);
            }
        }
    }

    componentWillReceiveProps(nextProps: any) {
        const nextSubsites = get(nextProps.row, subsiteFiled);
        const preSubsites = get(this.props.row, subsiteFiled);
        if (nextSubsites && preSubsites && !isEqual(nextSubsites, preSubsites)) {
            this.onChange(defalutValue);
        }
    }

    onChangeMerchants = (selectValues: MerchantsSelectorItem[]) => {
        const { value } = this.props;
        const newValue = value;
        newValue.merchants = selectValues && selectValues.length === 0 ? [] : selectValues;
        this.onChange(newValue);
    };

    onSelectMerchants = (selectValues: MerchantsSelectorItem[]) => {
        const { value } = this.props;
        const newValue = value;
        newValue.merchants = selectValues;
        this.onChange(newValue);
    };

    onChange(value: any) {
        const { onChange } = this.props;
        if (onChange) {
            onChange(cloneDeep(value));
        }
    }

    onChangeSelectMode = (mode: any) => {
        const { value } = this.props;
        const newValue = value;
        newValue.selectMode = mode;
        newValue.merchants = [];
        this.onChange(newValue);
    };

    renderSelectMode = () => {
        const { value, merchantTip } = this.props;
        if (!value) {
            return null;
        }
        return (
            <div>
                <Radio.Group
                    onChange={(e) => {
                        this.onChangeSelectMode(e.target.value);
                    }}
                    value={value.selectMode}
                >
                    <Radio value={DeliveryLogisticsConfigsMerchantsSelectMode.True}>
                        {services.language.getText('qbzj')}
                    </Radio>
                    <Radio value={DeliveryLogisticsConfigsMerchantsSelectMode.Fales}>
                        {services.language.getText('zdzj')}
                    </Radio>
                </Radio.Group>
                {merchantTip && <div className="ant-form-extra">{merchantTip}</div>}
            </div>
        );
    };

    toggleImportModal = () => {
        const { importModalVisible } = this.state;
        if (!importModalVisible) {
            const { row } = this.props;
            const subsites = get(row, subsiteFiled);
            if (!subsites || !subsites.id) {
                message.warn(services.language.getText('selectBeforeMerchant'));
                return;
            }
        }
        this.setState({ importModalVisible: !importModalVisible });
    };

    onImportSelectMerchants = (selectValues: MerchantsSelectorItem[], isImprt?: boolean) => {
        const { value } = this.props;
        const newValue = value;
        newValue.merchants = selectValues;
        this.onChange(newValue);
        if (!isImprt) {
            this.toggleImportModal();
        }
    };

    renderMerchants = () => {
        const { value, row } = this.props;
        if (!value) {
            return null;
        }
        const hasValue = value.merchants && value.merchants.length > 0;
        const uploadFileProps = {
            visible: this.state.importModalVisible,
            onClose: this.toggleImportModal,
            ...this.props,
        };
        const subsites = get(row, subsiteFiled);
        const subsitesIds = subsites ? subsites.id : undefined;
        return (
            <div>
                {value && value.selectMode === DeliveryLogisticsConfigsMerchantsSelectMode.Fales && (
                    <div className="merchants-wrap">
                        <div>
                            <span>
                                <span className="tip">*</span>
                                {services.language.getText('selectSubsites')}：
                            </span>
                            <MerchantsSelector
                                {...this.props}
                                onChange={this.onSelectMerchants}
                                selectValues={value.merchants}
                            />
                            <Button type="link" onClick={this.toggleImportModal}>
                                {services.language.getText('pldrzj')}
                            </Button>
                        </div>
                        <UploadFile
                            {...uploadFileProps}
                            subsites={subsitesIds}
                            onChange={this.onImportSelectMerchants}
                        />
                        {hasValue && (
                            <div className="merchants-display">
                                <MerchantsSelectorDisplay
                                    data={value.merchants || []}
                                    onRemove={this.onChangeMerchants}
                                ></MerchantsSelectorDisplay>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    render() {
        return (
            <div className="delivery-logistics-configs-merchant">
                {this.renderSelectMode()}
                {this.renderMerchants()}
            </div>
        );
    }
}
