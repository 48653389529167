import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import { CarparkMenusConfig } from '../../../../components';

export class CarparkMenusConfigMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <CarparkMenusConfig {...displayInfo} />;
    }
}
