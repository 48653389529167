import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { get } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

enum RewardTypeVal {
    point = 1,
    coupons = 2,
    experience = 3,
    pointPercent = 4,
    experiencePercent = 5,
    couponPackage = 6,
    gold = 10,
}

const api = services.api;

export const eventMarketTypeLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        return ENV.TENANT === 'wfj'
            ? [
                  { id: '1', name: '新人有礼' },
                  { id: '10', name: '支付奖励' },
              ]
            : [
                  { id: '1', name: '新人有礼' },
                  //   { id: '2', name: '会员登录' },
                  //   { id: '3', name: '订单奖励' },
                  //   { id: '4', name: '商品评论' },
                  //   { id: '5', name: '会员验证' },
                  //   { id: '6', name: '分享订单' },
                  //   { id: '7', name: '分享单品' },
                  //   //   { id: '8', name: '分享邀请码' },
                  //   { id: '9', name: '分享文章' },
                  { id: '10', name: '支付奖励' },
              ];
    },
};
export const eventMarketLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/event_markets';
        let goldValueAlias = '金币';
        if (data.id) {
            config.apiPath = `/admin/event_markets/${data.id}`;
            const resData: any[] = await api.get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                    apiPath: '/admin/activities/reward_items?event_type=SIGN_IN',
                }
            );
            if (resData && resData.length) {
                goldValueAlias = resData.find((item: any) => item.code === 'GOLD')?.name || '金币';
            }
        } else {
            if (data.status === 'ALL') {
                delete data.status;
            }
            if (!data.eventType || data.eventType === '10') {
                data.menuCode = 'eventMarket';
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (data.id) {
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : language.getText('longTerm'),
                    };
                }
                res.baseInfo = {
                    name: res.name,
                    typeId: res.typeId + '',
                    time: { start: res.startTime, end: res.endTime },
                    status: res.status + '',
                    sequence: res.sequence,
                    subsiteIds: res.subsites,
                    awardAction: '',
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };
                res.rewardConfig = {
                    reward: [
                        {
                            couponsValue: [],
                        },
                    ],
                };
                if (res.baseInfo.typeId === '1') {
                    const registerModeItem = res.conditionValues
                        ? res.conditionValues.find((i: any) => i.conditionId === 25)
                        : undefined;
                    res.rewardConfig.registerMode = registerModeItem ? registerModeItem.value : '0';
                }
                if (res.baseInfo.typeId === '10') {
                    res.baseInfo.subsiteIds = [];
                    res.baseInfo.subsiteId = String(res.subsites[0].id);
                    res.baseInfo.merchants = res.subsites[0].subSiteMerchants;
                }
                res.description = {
                    remark: res.remark,
                };
                if (res.baseInfo.typeId === '1') {
                    //会员注册图片保存在pageConfig新字端中，兼容以前老数据读取
                    if (res.pageConfig && res.rewardConfig.registerMode) {
                        const pageConfigJson = JSON.parse(res.pageConfig);
                        res.description.registerEventPicture = pageConfigJson.registerEventPicture;
                        if (res.rewardConfig.registerMode === '0') {
                            res.description.registerEventUseButton =
                                pageConfigJson.registerEventUseButton;
                            res.description.systemPictures = pageConfigJson.backgroundPicture
                                ? [
                                      {
                                          id: pageConfigJson.backgroundPicture.id || '',
                                          path: pageConfigJson.backgroundPicture.url || '',
                                      },
                                  ]
                                : undefined;
                        }
                        if (res.rewardConfig.registerMode === '1') {
                            res.description.auserPictures = pageConfigJson.backgroundPicture
                                ? [
                                      {
                                          id: pageConfigJson.backgroundPicture.id || '',
                                          path: pageConfigJson.backgroundPicture.url || '',
                                      },
                                  ]
                                : undefined;
                            res.rewardConfig.target = pageConfigJson.target;
                        }
                    } else {
                        if (get(res, 'picture.id') && get(res, 'picture.path')) {
                            if (res.rewardConfig.registerMode === '0') {
                                res.description.systemPictures = [
                                    {
                                        id: get(res, 'picture.id'),
                                        path: get(res, 'picture.path'),
                                    },
                                ];
                            }
                            if (res.rewardConfig.registerMode === '1') {
                                res.description.auserPictures = [
                                    {
                                        id: get(res, 'picture.id'),
                                        path: get(res, 'picture.path'),
                                    },
                                ];
                            }
                        }
                    }
                } else {
                    if (get(res, 'picture.id') && get(res, 'picture.path')) {
                        res.description.pictures = [
                            {
                                id: get(res, 'picture.id'),
                                path: get(res, 'picture.path'),
                            },
                        ];
                    }
                }
                res.conditionConfig = {};
                if (res.conditionValues) {
                    const { result, every, condition } = parseConditionToRewardConfig(
                        res.baseInfo.typeId,
                        res.conditionValues
                    );
                    res.rewardConfig.reward[0].every = every;
                    res.rewardConfig.reward[0].condition = condition;
                    res.rewardConfig.reward[0].result = result;
                    let memberSource = res.conditionValues.filter(
                        (i: any) => String(i.conditionId) === '28'
                    );
                    if (memberSource && memberSource.length) {
                        const [memberSourceItem] = memberSource;
                        res.rewardConfig.memberSource = memberSourceItem.value;
                    } else {
                        res.rewardConfig.memberSource = '1';
                    }
                    if (res.baseInfo.typeId === '10') {
                        res.conditionConfig = parseConditionToConditionConfig(res.conditionValues);
                        let merchantMode = res.conditionValues.filter(
                            (i: any) => String(i.conditionId) === '27'
                        );
                        if (merchantMode?.length) {
                            res.baseInfo.selectMode = merchantMode[0].value;
                        }
                        const firstEventMarketConf =
                            res.ladderConf &&
                            res.ladderConf.length > 0 &&
                            res.ladderConf[0].eventMarketConfList &&
                            res.ladderConf[0].eventMarketConfList.length > 0
                                ? res.ladderConf[0].eventMarketConfList[0]
                                : undefined;
                        if (firstEventMarketConf) {
                            res.conditionConfig.claimTime = firstEventMarketConf.receiveDays;
                            res.conditionConfig.grantMode =
                                firstEventMarketConf.distributionType + '';
                            res.conditionConfig.pictureEntrance = firstEventMarketConf.rewardPicture
                                ? [
                                      {
                                          id: 1,
                                          path: firstEventMarketConf.rewardPicture,
                                      },
                                  ]
                                : undefined;
                        }
                    }
                }
                if (res.notificationContent) {
                    res.conditionConfig.notification = {
                        channel: !!res.notificationChannel ? true : false,
                        content: res.notificationContent,
                        // agreement: !!res.notificationChannel ? true : false,
                    };
                }
                if (!res.notificationContent && res.baseInfo.typeId === '10') {
                    res.conditionConfig.notification = undefined;
                }
                if (res.conf) {
                    res.conf.forEach((i: any) => {
                        if (i.awardType === RewardTypeVal.point) {
                            // 积分
                            res.rewardConfig.reward[0].pointChecked = true;
                            res.rewardConfig.reward[0].pointMin = i.minAwardIntegral;
                            res.rewardConfig.reward[0].pointMax = i.maxAwardIntegral;
                            if (i.doubleIntegral) {
                                res.rewardConfig.reward[0].doublePoint = i.doubleIntegral;
                            }
                        } else if (
                            i.awardType === RewardTypeVal.coupons ||
                            i.awardType === RewardTypeVal.couponPackage
                        ) {
                            // 优惠券
                            res.rewardConfig.reward[0].couponsChecked = true;
                            res.rewardConfig.reward[0].couponsValue = i.coupons;
                        } else if (i.awardType === RewardTypeVal.experience) {
                            //经验值
                            res.rewardConfig.reward[0].experienceChecked = true;
                            res.rewardConfig.reward[0].experienceMin = i.minAwardExp;
                            res.rewardConfig.reward[0].experienceMax = i.maxAwardExp;
                            if (i.doubleExp) {
                                res.rewardConfig.reward[0].doubleExperience = i.doubleExp;
                            }
                        } else if (i.awardType === RewardTypeVal.pointPercent) {
                            // 积分百分比
                            res.rewardConfig.reward[0].pointPercentChecked = true;
                            res.rewardConfig.reward[0].pointPercent = i.percentIntegral;
                        } else if (i.awardType === RewardTypeVal.experiencePercent) {
                            // 经验百分比
                            res.rewardConfig.reward[0].experiencePercentChecked = true;
                            res.rewardConfig.reward[0].experiencePercent = i.percentExp;
                        } else if (i.awardType === RewardTypeVal.gold) {
                            // 金币
                            res.rewardConfig.reward[0].goldChecked = true;
                            res.rewardConfig.reward[0].reward = i.minAwardIntegral;
                            res.rewardConfig.reward[0].goldTxtName = goldValueAlias;
                        }
                    });
                }
                if (res.ladderConf) {
                    res.rewardConfig.ladderConf = res.ladderConf.map((item: any) => ({
                        id: item.id,
                        totalMoneyMin: item.totalMoneyMin,
                        totalMoneyMax: item.totalMoneyMax,
                        couponsEventMarketConfId: item.eventMarketConfList[0].id,
                        coupons: item.eventMarketConfList[0].coupons.map((coupon: any) => ({
                            ...coupon,
                            type: coupon.couponType,
                            couponTypeDes: coupon.couponTypeDesc,
                        })),
                    }));
                }
            }
            if (res && res.result && res.result.length) {
                res.result = res.result.map((item: any) => {
                    if (item.typeId.toString() === '1') {
                        item.eventTypeName = '新人有礼';
                    }
                    return item;
                });
            }
            return res;
        });
    },
    delete: async function(data, config: ApiRequestConfig) {
        const { id } = data;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/event_markets/${id}`;

        return await api.delete(data, config);
    },

    post: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/event_markets';

        try {
            const params = transform(data);
            return await api.post(params, config);
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    put: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/event_markets/${data.id}`;

        try {
            const params = transform(data);
            return await api.put(params, config);
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
};

function transform(data: any) {
    const isEdit = window.location.href.includes('/edit');
    const params: any = {};
    const { baseInfo, description, rewardConfig, conditionConfig } = data;

    params.typeId = baseInfo.typeId;
    params.name = baseInfo.name;
    params.startTime = baseInfo.time.start;
    params.endTime = baseInfo.time.end;
    if (baseInfo.typeId === '10') {
        let subsiteId: any = baseInfo.subsiteId;
        params.subsiteIds = [subsiteId];
    } else {
        params.subsiteIds = baseInfo.subsiteIds.map((i: any) => i.id);
    }
    params.subsiteMerchants =
        baseInfo.merchants && baseInfo.merchants.length
            ? baseInfo.merchants.map((item: any) => ({
                  subsiteId: item.subSiteId,
                  merchantId: item.merchantId,
              }))
            : [];
    params.status = baseInfo.status;
    params.sequence = baseInfo.sequence;
    params.remark = description.remark;
    if (conditionConfig) {
        params.notificationChannel =
            conditionConfig.notification && conditionConfig.notification.channel ? 'SMS' : '';
        params.notificationContent =
            conditionConfig.notification && conditionConfig.notification.content
                ? conditionConfig.notification.content
                : '';
    }
    const registerMode = rewardConfig.registerMode;
    if (params.typeId === '1') {
        const pageConfig: any = {
            registerEventPicture: description.registerEventPicture,
        };
        if (registerMode === '0') {
            if (description.systemPictures?.length) {
                pageConfig.backgroundPicture = {
                    id: description.systemPictures[0].id,
                    url: description.systemPictures[0].path,
                };
            }
            pageConfig.registerEventUseButton = description.registerEventUseButton;
        }
        if (registerMode === '1') {
            if (description.auserPictures?.length) {
                pageConfig.backgroundPicture = {
                    id: description.auserPictures[0].id,
                    url: description.auserPictures[0].path,
                };
            }
            pageConfig.target = rewardConfig.target;
        }
        params.pageConfig = JSON.stringify(pageConfig);
    } else {
        if (description.pictures && description.pictures.length) {
            params.pictureId = description.pictures[0].id;
        }
    }
    if (baseInfo.typeId !== '1' || (baseInfo.typeId === '1' && registerMode === '0')) {
        const eventMarketConfList: any[] = [];
        const reward = rewardConfig.reward[0];
        if (reward.pointChecked) {
            const { pointMin, pointMax, doublePoint } = reward;
            const item: any = {
                minAwardIntegral: pointMin,
                maxAwardIntegral: pointMax,
                awardType: RewardTypeVal.point,
            };

            if (doublePoint) {
                item.doubleIntegral = doublePoint;
            }
            eventMarketConfList.push(item);
        }

        if (reward.pointPercentChecked) {
            const { pointPercent } = reward;
            eventMarketConfList.push({
                percentIntegral: pointPercent,
                awardType: RewardTypeVal.pointPercent,
            });
        }

        if (reward.experienceChecked) {
            const { experienceMin, experienceMax, doubleExperience } = reward;
            const item: any = {
                minAwardExp: experienceMin,
                maxAwardExp: experienceMax,
                awardType: RewardTypeVal.experience,
            };
            if (doubleExperience) {
                item.doubleExp = doubleExperience;
            }
            eventMarketConfList.push(item);
        }

        if (reward.experiencePercentChecked) {
            const { experiencePercent } = reward;
            eventMarketConfList.push({
                percentExp: experiencePercent,
                awardType: RewardTypeVal.experiencePercent,
            });
        }
        if (reward.goldChecked) {
            eventMarketConfList.push({
                minAwardIntegral: reward.reward,
                awardType: RewardTypeVal.gold,
            });
        }

        if (reward.couponsChecked) {
            if (reward.couponsValue[0].couponType === 'PACKAGE') {
                eventMarketConfList.push({
                    coupons: [{ quantity: 1, batchNo: reward.couponsValue[0].code }],
                    awardType: RewardTypeVal.couponPackage,
                });
            } else {
                const coupons = reward.couponsValue.map((i: any) => ({
                    id: i.id,
                    batchNo: i.batchNo,
                    quantity: i.quantity ? i.quantity : 1,
                }));
                eventMarketConfList.push({
                    coupons,
                    awardType: RewardTypeVal.coupons,
                });
            }
        }

        if (baseInfo.typeId === '5') {
            eventMarketConfList.forEach((i) => (i.awardAction = baseInfo.awardAction));
        }
        params.eventMarketConfList = eventMarketConfList;
    }
    params.ladderConf =
        baseInfo.typeId === '10' && rewardConfig.ladderConf
            ? rewardConfig.ladderConf.map((item: any) => ({
                  id: isEdit && item.id ? item.id : undefined,
                  totalMoneyMin: item.totalMoneyMin,
                  totalMoneyMax: item.totalMoneyMax,
                  eventMarketConfList: item.coupons.map((i: any) => ({
                      id:
                          isEdit && item.couponsEventMarketConfId
                              ? item.couponsEventMarketConfId
                              : undefined,
                      awardType:
                          i.type === 'PACKAGE'
                              ? RewardTypeVal.couponPackage
                              : RewardTypeVal.coupons,
                      coupons: [
                          {
                              id: i.id,
                              batchNo: i.batchNo,
                              quantity: i.quantity ? i.quantity : 1,
                          },
                      ],
                      distributionType: conditionConfig.grantMode,
                      receiveDays: conditionConfig.claimTime,
                      rewardPicture:
                          conditionConfig.grantMode === '1' &&
                          conditionConfig.pictureEntrance &&
                          conditionConfig.pictureEntrance.length
                              ? conditionConfig.pictureEntrance[0].path
                              : '',
                  })),
              }))
            : [];
    params.marketingScheduleId =
        (baseInfo?.marketingSchedule?.length && baseInfo.marketingSchedule[0].id) || null;
    params.eventMarketConditionValueVolist = tranformCondition(data);
    return params;
}

function tranformCondition({ baseInfo, rewardConfig, conditionConfig }: any) {
    const typeId = baseInfo.typeId;
    let conditionList: any = [];
    if (typeId === '1') {
        const { registerMode } = rewardConfig;
        conditionList = [
            {
                conditionId: 25,
                value: registerMode,
            },
            {
                conditionId: 30,
                value: 'eventMarket',
            },
            {
                conditionId: 28,
                value: rewardConfig.memberSource,
            },
        ];
        return conditionList;
    }
    if (typeId === '10') {
        const {
            range,
            condition,
            time,
            threshold,
            limits,
            count,
            cycle,
            recover,
        } = conditionConfig;
        const { selectMode } = baseInfo;
        conditionList = [
            {
                conditionId: 19,
                value: condition,
            },
            {
                conditionId: 20,
                value: time,
            },
            {
                conditionId: 21,
                value: threshold,
            },
            {
                conditionId: 22,
                value: limits,
            },
            {
                conditionId: 23,
                value: count,
            },
            {
                conditionId: 24,
                value: cycle,
            },
            {
                conditionId: 26,
                value: recover,
            },
            {
                conditionId: 27,
                value: selectMode,
            },
            {
                conditionId: 30,
                value: 'eventMarket',
            },
        ];
        if (condition === '2') {
            conditionList.push({
                conditionId: 31,
                value: 0,
            });
        }
        if (range.length) {
            range.forEach((v: string) => {
                conditionList.push({
                    conditionId: 18,
                    value: v,
                });
            });
        }
        return conditionList;
    }
    const reward = rewardConfig.reward[0];
    if (!reward.every || !reward.condition || !reward.result) {
        return [];
    }

    const condition = 'd';
    const { every, result } = reward;

    if (typeId === '2') {
        conditionList = [
            {
                conditionId: 1,
                value: every,
            },
            {
                conditionId: 2,
                value: condition,
            },
            {
                conditionId: 3,
                value: result,
            },
            {
                conditionId: 30,
                value: 'eventMarket',
            },
        ];
    }

    if (['4', '6', '7', '9'].includes(typeId)) {
        conditionList = [
            {
                conditionId: 15,
                value: every,
            },
            {
                conditionId: 16,
                value: condition,
            },
            {
                conditionId: 17,
                value: result,
            },
            {
                conditionId: 30,
                value: 'eventMarket',
            },
        ];
    }

    return conditionList;
}

function parseConditionToRewardConfig(typeId: string, list: any[]) {
    if (list && list.length) {
        let every, result, condition;
        if (typeId === '2') {
            every = findValueById(1, list);
            condition = findValueById(2, list);
            result = findValueById(3, list);
        }
        if (['4', '6', '7', '9'].includes(typeId)) {
            every = findValueById(15, list);
            condition = findValueById(16, list);
            result = findValueById(17, list);
        }

        if (every && condition && result) {
            return { every, condition: 1, result };
        } else {
            return { every: '', condition: 0, result: '' };
        }
    }

    return { every: '', condition: 0, result: '' };
}
function findValueById(id: number, list: any[]) {
    const item = list.find((i) => i.conditionId === id);
    return item ? item.value : null;
}

function parseConditionToConditionConfig(conditionValues: any) {
    const conditionConfig: any = {
        '18': 'range',
        '19': 'condition',
        '20': 'time',
        '21': 'threshold',
        '22': 'limits',
        '23': 'count',
        '24': 'cycle',
        '26': 'recover',
    };
    const result: any = {};
    for (let item of conditionValues) {
        if (['19', '20', '21', '22', '23', '24', '26'].includes(String(item.conditionId))) {
            result[conditionConfig[item.conditionId]] = item.value;
        }
        if (String(item.conditionId) === '18') {
            if (!result[conditionConfig[item.conditionId]]) {
                result[conditionConfig[item.conditionId]] = [item.value];
            } else {
                result[conditionConfig[item.conditionId]].push(item.value);
            }
        }
    }
    return result;
}
