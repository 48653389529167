import React, { Component } from 'react';
import { Radio } from 'antd';
import { NumberInputTip } from '../../../../components';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

interface StackedUseValue {
    state: boolean;
    count?: number;
}
interface StackedUseProps {
    /**
     * 选中值
     */
    value: StackedUseValue;
    /**
     * 内容改变回调
     * @param value 新值
     */
    onChange: (value: StackedUseValue) => void;
}

export class StackedUse extends Component<StackedUseProps> {
    onStateChange = (e: any) => {
        const { value, onChange } = this.props;
        onChange && onChange({ state: e.target.value, count: value?.count });
    };
    onCountChange = (val?: number) => {
        const { value, onChange } = this.props;
        onChange && onChange({ state: value.state, count: val });
    };
    render() {
        const { value } = this.props;

        return (
            <div className="stacked-use">
                <Radio.Group
                    value={value ? value.state : true}
                    defaultValue={true}
                    onChange={this.onStateChange}
                >
                    <span>
                        <Radio value={false}>{language.getText('bdjsy')}</Radio>
                    </span>
                    <span>
                        <Radio value={true}>
                            <span>{language.getText('kdjsy')}</span>
                            <NumberInputTip
                                style={{ width: '150px', marginLeft: '10px' }}
                                onChange={this.onCountChange}
                                min={2}
                                max={10}
                                precision={0}
                                value={value?.count}
                                addonafter="张"
                            />
                        </Radio>
                    </span>
                </Radio.Group>
            </div>
        );
    }
}
