import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { chain, get, isString, isNil } from 'lodash';
import { RegistrationPriceType } from '../components/registration-price';
import { RegistrationTimeType } from '../components';

const api = services.api;

export const MallActivityLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`;
        config.apiPath = '/admin/mall_activities';
        const { id } = data;
        if (data.subsiteId && data.subsiteId.length > 0) {
            const subsiteId = data.subsiteId
                .map((item: any) => {
                    if (isString(item)) {
                        return item;
                    } else {
                        return item.id;
                    }
                })
                .join(',');
            data.subsiteId = subsiteId;
        } else {
            delete data.subsiteId;
        }
        if (id) {
            config.apiPath = `/admin/mall_activities/${id}`;
        }
        return await api.get(data, config).then(async (res: any) => {
            if (id) {
                res.baseInfo = {
                    id,
                    name: res.mallActivity.name,
                    merchants: res.mallActivity.subSiteMerchants,

                    dateRange: { start: res.mallActivity.startTime, end: res.mallActivity.endTime },
                    dayTime: [
                        {
                            start: res.mallActivity.dailyStartTime,
                            end: res.mallActivity.dailyEndTime,
                        },
                    ],
                    phone: res.mallActivity.servicePhone || '',
                    address: res.mallActivity.address || '',
                    addressName: res.mallActivity.addressName || '',
                    merchantUserPhone: res.mallActivity.shoppingGuideMobile || '',
                    editStartTime: res.mallActivity.startTime,
                    editEndTime: res.mallActivity.endTime,
                    canShare: res.mallActivity.canShare ? ['true'] : [],
                    hide: res.mallActivity.hide ? ['true'] : [],
                };

                if (res.mallActivity.tags && res.mallActivity.tags.length) {
                    res.baseInfo.tagIdList = res.mallActivity.tags;
                }

                res.mediaInfo = {
                    pictures: [
                        { id: res.mallActivity.pictureId, path: res.mallActivity.pictureUrl },
                    ],
                    info: res.mallActivity.info || '',
                };

                if (
                    res.mallActivity.sharePosterPictureId &&
                    res.mallActivity.sharePosterPictureUrl
                ) {
                    res.mediaInfo.poster = [
                        {
                            id: res.mallActivity.sharePosterPictureId,
                            path: res.mallActivity.sharePosterPictureUrl,
                        },
                    ];
                }

                res.contentInfo = {
                    creator: res.mallActivity.initiatorMobile || '',
                    activityTopics: res.mallActivity.topics || [],
                };
                if (res.registrationInfo) {
                    res.registrationInfo = {
                        ...res.registrationInfo,
                        registrationType: 'YES',
                        registrationStartTime: {
                            timeStr: res.registrationInfo.startTime,
                            timeUnit: res.registrationInfo.startTimeUnit,
                            num: res.registrationInfo.startTimeValue,
                            timeType: res.registrationInfo.startTimeType || 'DAY_TIME',
                        },
                        registrationEndTime: {
                            timeType: res.registrationInfo.endTimeType,
                            timeUnit: res.registrationInfo.endTimeUnit,
                            num: res.registrationInfo.endTimeValue,
                            timeStr: res.registrationInfo.endTime,
                        },
                        totalQuantity: res.registrationInfo.maxQuantity,
                        limitCountType: res.registrationInfo.memberMaxQuantity ? 'YES' : 'NO',
                        limitCount: res.registrationInfo.memberMaxQuantity,
                        info: res.registrationInfo.info || '',
                        reviewType: res.registrationInfo.audit ? 'TRUE' : 'FALSE',
                    };
                    // 场次报名
                    if (res.registrationInfo.rounds && res.registrationInfo.rounds.length) {
                        /*  const { roundStatisticMap } = await api.get(
                            {},
                            {
                                ...config,
                                apiPath: `/admin/mall_activities/${id}/registration_statistic`,
                            }
                        );
 */
                        res.registrationInfo.session = 'YES';
                        res.registrationInfo.registrationTimes = res.registrationInfo.rounds.map(
                            (i: any) => ({
                                ...i,
                                quantity: i.roundMaxQuantity,
                                // min: roundStatisticMap[i.id] || 0,
                                min: i.registrationQuantity,
                            })
                        );
                    } else {
                        res.registrationInfo.session = 'NO';
                    }
                    if (
                        res.registrationInfo.memberMaxRound ||
                        res.registrationInfo.memberMaxRoundPerDay
                    ) {
                        res.registrationInfo.limitSession = 'YES';
                        res.registrationInfo.limitSessionCount = {
                            dayCount: res.registrationInfo.memberMaxRoundPerDay,
                            activityCount: res.registrationInfo.memberMaxRound,
                        };
                    } else {
                        res.registrationInfo.limitSession = 'NO';
                    }
                    // 报名价格
                    // if (res.registrationInfo.payList && res.registrationInfo.payList.length) {
                    //     res.registrationInfo.registrationPrice = {
                    //         type: RegistrationPriceType.PAY,
                    //     };
                    //     res.registrationInfo.payList.forEach((i: any) => {
                    //         if (i.payType === 'POINT') {
                    //             res.registrationInfo.registrationPrice.integral = i.payValue;
                    //         }
                    //         if (i.payType === 'GOLD') {
                    //             res.registrationInfo.registrationPrice.gold = i.payValue;
                    //         }
                    //         if (i.payType === 'CASH') {
                    //             res.registrationInfo.registrationPrice.price = i.payValue;
                    //         }
                    //     });
                    // } else {
                    //     res.registrationInfo.registrationPrice = {
                    //         type: RegistrationPriceType.NONE,
                    //     };
                    // }
                    // 会员等级付费处理
                    if (res.registrationInfo.payList && res.registrationInfo.payList.length) {
                        const freeLimitList: any = res.registrationInfo.freeLimitList;
                        res.registrationInfo.priceType = RegistrationPriceType.PAY;
                        const payList: any[] = [];
                        res.registrationInfo.payList.forEach((item: any) => {
                            const index = payList.findIndex(
                                (i: any) => i.mcCardLevelId === item.mcCardLevelId
                            );
                            if (index >= 0) {
                                const currentItem = payList[index];
                                const payItem = {
                                    ...currentItem,
                                    payCash:
                                        item.payType === 'CASH'
                                            ? item.payValue
                                            : currentItem.payCash,
                                    payPoint:
                                        item.payType === 'POINT'
                                            ? item.payValue
                                            : currentItem.payPoint,
                                };
                                payList.splice(index, 1, payItem);
                            } else {
                                const quota =
                                    freeLimitList && freeLimitList.length > 0
                                        ? freeLimitList.find(
                                              (i: any) => i.mcCardLevelId === item.mcCardLevelId
                                          )?.quota || 0
                                        : 0;
                                payList.push({
                                    mcCardLevelId: item.mcCardLevelId,
                                    payCash: item.payType === 'CASH' ? item.payValue : 0,
                                    payPoint: item.payType === 'POINT' ? item.payValue : 0,
                                    payGold: 0,
                                    quota: quota,
                                });
                            }
                        });
                        res.registrationInfo.payList = {
                            separateLevel:
                                payList.findIndex((i: any) => i.mcCardLevelId === -1) < 0,
                            list: payList,
                        };
                    } else {
                        res.registrationInfo.priceType = RegistrationPriceType.NONE;
                        // 会员等级付费规则设置默认值，防止从免费切换到付费时会员等级付费规则时没有默认设置
                        res.registrationInfo.payList = {
                            separateLevel: false,
                            list: [
                                {
                                    mcCardLevelId: -1,
                                    payCash: 0,
                                    payPoint: 0,
                                    payGold: 0,
                                    quota: 0,
                                },
                            ],
                        };
                    }
                    // 取消报名数据
                    res.registrationInfo.cancelableRule = {
                        cancelable: res.mallActivity.cancelable,
                        cancelableRule: res.mallActivity.cancelableRule || {
                            duration: undefined,
                            unit: 'HOUR',
                        },
                    };
                    // 指定用户
                    if (res.registrationInfo.condition) {
                        res.registrationInfo.targetUserType = 'SOME';
                        if (
                            res.registrationInfo.condition.birthdayStartDate &&
                            res.registrationInfo.condition.birthdayEndDate
                        ) {
                            res.registrationInfo.targetUserBirthDay = {
                                from: res.registrationInfo.condition.birthdayStartDate,
                                to: res.registrationInfo.condition.birthdayEndDate,
                            };
                        }
                        if (get(res, 'registrationInfo.condition.memberCardLevelVos.length')) {
                            res.registrationInfo.targetUserLevels = res.registrationInfo.condition.memberCardLevelVos.map(
                                (i: any) => ({ ...i, id: String(i.memberCardLevelId) })
                            );
                        }
                        if (res.registrationInfo.condition.gender) {
                            res.registrationInfo.targetUserSexuality =
                                res.registrationInfo.condition.gender;
                        }
                    } else {
                        res.registrationInfo.targetUserType = 'ANY';
                    }
                    // 自定义项
                    if (res.registrationInfo.extension) {
                        res.registrationInfo.customInfo = JSON.parse(
                            res.registrationInfo.extension
                        );
                    }
                    if (res.registrationInfo.rewards && res.registrationInfo.rewards.length) {
                        res.rewardInfo = {
                            reward: res.registrationInfo.rewards.map((i: any) => {
                                const data: any = {
                                    type: {
                                        checked: i.rewardType,
                                    },
                                    name: res.registrationInfo.rewardName,
                                };
                                if (i.rewardType === 'COUPON') {
                                    data.type.checked = 'COUPON';
                                    data.type.assetRewards = [
                                        {
                                            rewardType: 'COUPON',
                                            rewardValue: [
                                                {
                                                    // 优惠券详情
                                                    ...i.couponRewardVo,
                                                    type: i.couponRewardVo.couponType,
                                                    id: i.couponRewardVo.couponDefinitionId,
                                                    leftStock:
                                                        i.couponRuleDetailVo.couponRule.leftStock,
                                                },
                                            ],
                                        },
                                    ];
                                }

                                return data;
                            }),
                            rewardDistributionTime: res.registrationInfo.rewardDistributionTime
                                ? res.registrationInfo.rewardDistributionTime
                                : 'VERIFY_ACTIVITY',
                        };
                    } else {
                        res.rewardInfo = {
                            rewardDistributionTime: 'VERIFY_ACTIVITY',
                        };
                    }

                    if (res.registrationInfo.reject) {
                        res.registrationInfo.excludeRule = 'YES';
                        if (
                            res.registrationInfo.rejectActivityIds &&
                            res.registrationInfo.rejectActivityIds.length
                        ) {
                            res.registrationInfo.excludeType = 'SOME';
                            res.registrationInfo.excludeTypeActivity = res.registrationInfo.rejectActivityList.map(
                                (i: any) => ({ ...i, ...i.mallActivityState })
                            );
                        } else {
                            res.registrationInfo.excludeType = 'ALL';
                        }
                    } else {
                        res.registrationInfo.excludeRule = 'NO';
                    }
                } else {
                    res.registrationInfo = {
                        registrationType: 'NO',
                    };
                }

                res.seniorSetting = {
                    enableNotice: [res.mallActivity.messageNoticeRule.enableNotice + ''],
                    subscriptMessage: res.mallActivity.messageNoticeRule.enableNotice
                        ? {
                              timeUnit: res.mallActivity.messageNoticeRule.startReminderUnit,
                              num: res.mallActivity.messageNoticeRule.startReminderDuration,
                          }
                        : undefined,
                    marking: !isNil(res.mallActivity?.tagId)
                        ? {
                              check: true,
                              label: String(res.mallActivity.tagId),
                          }
                        : undefined,
                };

                res.registrationStatus = res.mallActivityState.registrationStatus;
                res.mallActivityStatus = res.mallActivityState.mallActivityStatus;
                res.enable = res.mallActivityState.enable;
            } else {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        if (!item.mallActivityState.registrationStatus) {
                            item.registrationStatus = 'NONE';
                        }
                        item.mallActivityStatisticTotal = `${item.mallActivityStatistic.joinQuantity} / ${item.mallActivityStatistic.registrationQuantity} / ${item.mallActivityStatistic.maxQuantity}`;
                        let registrationStatus = '非报名';
                        if (item.mallActivityState.registrationStatus === 'NOT_STARTED') {
                            registrationStatus = '未开始';
                        } else if (item.mallActivityState.registrationStatus === 'STARTED') {
                            registrationStatus = '进行中';
                        } else if (item.mallActivityState.registrationStatus === 'COMPLETE') {
                            registrationStatus = '已结束';
                        } else {
                            item.mallActivityStatisticTotal = '- / - / -';
                        }
                        item.registrationStatus = registrationStatus;
                        item.subSites = item.mallActivity.subsites;
                        item.mallActivity?.subSiteMerchants &&
                            item.mallActivity.subSiteMerchants.forEach((item: any) => {
                                item.name = item.merchantName;
                            });
                        item.merchants = item.mallActivity.subSiteMerchants || [];
                        item.mallActivityStatus = item.mallActivityState.mallActivityStatus;
                        item.startTime = item.mallActivity.startTime;
                        item.endTime = item.mallActivity.endTime;
                        item.canEdit = item.mallActivityState.mallActivityStatus === 'NOT_STARTED';
                        // 暂时禁用没用到的操作
                        item.disableAction = false;
                        item.status = item.mallActivityState.enable;
                        item.id = item.mallActivity.id;
                        item.info = {
                            url: item.mallActivity.pictureUrl,
                            name: item.mallActivity.name,
                            startTime: item.mallActivity.startTime,
                            endTime: item.mallActivity.endTime,
                        };
                        item.name = item.mallActivity.name;
                        return item;
                    });
            }

            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`;
        config.apiPath = '/admin/mall_activities';
        let newParams: any = transformRequestData(params, 'post');

        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`;
        if (params.id) {
            config.apiPath = `/admin/mall_activities/${params.id}`;
        }
        let newParams: any = transformRequestData(params, 'put');
        return await api.put(newParams, config);
    },
};

export function transformRequestData(params: any, mode: 'post' | 'put') {
    const newParams: any = {};
    if (params.baseInfo) {
        newParams.mallActivity = {
            name: params.baseInfo.name,
            subSiteMerchantIds: params.baseInfo.merchants.map((i: any) => i.id),
            pictureId: params.mediaInfo.pictures[0].id,
            pictureUrl: params.mediaInfo.pictures[0].path,
            startTime:
                mode === 'post' ? params.baseInfo.dateRange.start : params.baseInfo.editStartTime,
            endTime: mode === 'post' ? params.baseInfo.dateRange.end : params.baseInfo.editEndTime,
            dailyStartTime: params.baseInfo.dayTime[0].start,
            dailyEndTime: params.baseInfo.dayTime[0].end,
            address: params.baseInfo.address,
            addressName: params.baseInfo.addressName,
            servicePhone: get(params, 'baseInfo.phone'),
            shoppingGuideMobile: get(params, 'baseInfo.merchantUserPhone'),
            info: params.mediaInfo.info,
            canShare: get(params, 'baseInfo.canShare[0]') === 'true' ? true : false,
            hide: get(params, 'baseInfo.hide[0]') === 'true' ? true : false,
        };

        if (get(params, 'mediaInfo.poster[0]')) {
            newParams.mallActivity.sharePosterPictureId = params.mediaInfo.poster[0].id;
            newParams.mallActivity.sharePosterPictureUrl = params.mediaInfo.poster[0].path;
        }
        if (get(params, 'baseInfo.tagIdList.length')) {
            newParams.mallActivity.tagIds = params.baseInfo.tagIdList.map((i: any) => i.id);
        }
    }
    if (params.contentInfo) {
        if (get(params, 'contentInfo.activityTopics.length')) {
            newParams.mallActivity.topicIds = params.contentInfo.activityTopics.map(
                (i: any) => i.id
            );
        }
        if (get(params, 'contentInfo.creator')) {
            newParams.mallActivity.initiatorMemberId = params.contentInfo.creatorId;
            newParams.mallActivity.initiatorMobile = params.contentInfo.creator;
        }
    }
    if (params.registrationInfo && params.registrationInfo.registrationType === 'YES') {
        const endTimeType = params.registrationInfo.registrationEndTime.timeType;
        const startTimeType = params.registrationInfo.registrationStartTime.timeType;
        newParams.registrationInfo = {
            endTimeType,
            startTimeType,
        };

        if (startTimeType === RegistrationTimeType.DAY_TIME) {
            newParams.registrationInfo.startTime = get(
                params,
                'registrationInfo.registrationStartTime.timeStr'
            );
        } else {
            newParams.registrationInfo.startTimeUnit = get(
                params,
                'registrationInfo.registrationStartTime.timeUnit'
            );
            newParams.registrationInfo.startTimeValue = get(
                params,
                'registrationInfo.registrationStartTime.num'
            );
        }
        if (endTimeType === RegistrationTimeType.DAY_TIME) {
            newParams.registrationInfo.endTime = get(
                params,
                'registrationInfo.registrationEndTime.timeStr'
            );
        } else {
            newParams.registrationInfo.endTimeUnit = get(
                params,
                'registrationInfo.registrationEndTime.timeUnit'
            );
            newParams.registrationInfo.endTimeValue = get(
                params,
                'registrationInfo.registrationEndTime.num'
            );
        }
        if (params.registrationInfo.session === 'YES') {
            // 按场次报名
            const registrationTimes = params.registrationInfo.registrationTimes;
            const rounds = registrationTimes.map((i: any) => {
                const data: any = {
                    startDate: i.startDate,
                    roundMaxQuantity: i.quantity,
                    startTime: i.startTime.substring(0, 5),
                    endTime: i.endTime.substring(0, 5),
                    enable: i.enable,
                };
                if (i.id && mode === 'put') {
                    data.id = i.id;
                }
                return data;
            });
            newParams.registrationInfo.rounds = rounds;
        } else {
            newParams.registrationInfo.maxQuantity = params.registrationInfo.totalQuantity;
        }
        if (params.registrationInfo.limitCountType === 'YES') {
            // 每用户限购
            newParams.registrationInfo.memberMaxQuantity = params.registrationInfo.limitCount;
        }
        // 每用户限制场次
        if (params.registrationInfo.limitSession === 'YES') {
            if (get(params, 'registrationInfo.limitSessionCount.dayCount')) {
                newParams.registrationInfo.memberMaxRoundPerDay =
                    params.registrationInfo.limitSessionCount.dayCount;
            }
            if (get(params, 'registrationInfo.limitSessionCount.activityCount')) {
                newParams.registrationInfo.memberMaxRound =
                    params.registrationInfo.limitSessionCount.activityCount;
            }
        }
        // if (params.registrationInfo.registrationPrice.type === RegistrationPriceType.PAY) {
        //     // 报名价格
        //     const resultArr = [];
        //     if (params.registrationInfo.registrationPrice.integral) {
        //         resultArr.push({
        //             payType: 'POINT',
        //             payValue: params.registrationInfo.registrationPrice.integral,
        //         });
        //     }
        //     if (params.registrationInfo.registrationPrice.gold) {
        //         resultArr.push({
        //             payType: 'GOLD',
        //             payValue: params.registrationInfo.registrationPrice.gold,
        //         });
        //     }
        //     if (params.registrationInfo.registrationPrice.price) {
        //         resultArr.push({
        //             payType: 'CASH',
        //             payValue: params.registrationInfo.registrationPrice.price,
        //         });
        //     }

        //     newParams.registrationInfo.payList = resultArr;
        // }
        if (params.registrationInfo.targetUserType === 'SOME') {
            // 参与用户身份限制
            newParams.registrationInfo.condition = {};
            // 生日
            if (params.registrationInfo.targetUserBirthDay) {
                newParams.registrationInfo.condition.birthdayStartDate =
                    params.registrationInfo.targetUserBirthDay.from;
                newParams.registrationInfo.condition.birthdayEndDate =
                    params.registrationInfo.targetUserBirthDay.to;
            }
            // 会员等级
            if (
                params.registrationInfo.targetUserLevels &&
                params.registrationInfo.targetUserLevels.length
            ) {
                newParams.registrationInfo.condition.memberCardLevelIds = params.registrationInfo.targetUserLevels.map(
                    (i: any) => i.id
                );
            }
            // 性别
            if (params.registrationInfo.targetUserSexuality) {
                newParams.registrationInfo.condition.gender =
                    params.registrationInfo.targetUserSexuality;
            }
        }
        if (params.registrationInfo.customInfo && params.registrationInfo.customInfo.length) {
            newParams.registrationInfo.extension = JSON.stringify(
                params.registrationInfo.customInfo
            );
        }
        if (params.registrationInfo.info) {
            newParams.registrationInfo.info = params.registrationInfo.info;
        }
        // 报名审核
        newParams.registrationInfo.audit =
            params.registrationInfo.reviewType === 'TRUE' ? true : false;
        if (params.rewardInfo.reward && params.rewardInfo.reward.length) {
            newParams.registrationInfo.rewards = params.rewardInfo.reward.map((i: any) => {
                if (i.type.checked === 'CUSTOM') {
                    return {
                        rewardName: '无奖品或者线下发放奖励',
                        rewardType: 'CUSTOM',
                    };
                }
                if (i.type.checked === 'COUPON') {
                    return {
                        rewardName: get(i, 'type.assetRewards[0].rewardValue[0].name'),
                        rewardType: 'COUPON',
                        rewardId: get(i, 'type.assetRewards[0].rewardValue[0].id'),
                        couponType:
                            get(i, 'type.assetRewards[0].rewardValue[0].type') ||
                            get(i, 'type.assetRewards[0].rewardValue[0].couponType'),
                    };
                } else {
                    return {};
                }
            });
            newParams.registrationInfo.rewardName = params.rewardInfo.reward[0].name;
            newParams.registrationInfo.rewardDistributionTime =
                params.rewardInfo.rewardDistributionTime;
        } else {
            newParams.registrationInfo.rewards = [];
            newParams.registrationInfo.rewardName = '';
        }
        if (params.registrationInfo.excludeRule === 'YES') {
            newParams.registrationInfo.reject = true;
            if (params.registrationInfo.excludeType === 'SOME') {
                newParams.registrationInfo.rejectActivityIds = params.registrationInfo.excludeTypeActivity.map(
                    (i: any) => i.id
                );
            }
        } else {
            newParams.registrationInfo.reject = false;
        }
        // 限制会员等级
        const priceType = get(params, 'registrationInfo.priceType');
        const payList = get(params, 'registrationInfo.payList');
        if (priceType === RegistrationPriceType.PAY && payList) {
            newParams.registrationInfo.separateLevel = payList.separateLevel;
            const list = payList.list || [];
            const freeLimitList: any = [];
            const regPayList: any = [];
            list.forEach((item: any) => {
                freeLimitList.push({
                    mcCardLevelId: item.mcCardLevelId,
                    periodUnit: 'MONTH',
                    periodValue: 1,
                    quota: item.quota || 0,
                });
                regPayList.push({
                    mcCardLevelId: item.mcCardLevelId,
                    payType: 'CASH',
                    payValue: item.payCash || 0,
                });
                regPayList.push({
                    mcCardLevelId: item.mcCardLevelId,
                    payType: 'POINT',
                    payValue: item.payPoint || 0,
                });
            });
            newParams.registrationInfo.freeLimitList = freeLimitList;
            newParams.registrationInfo.payList = regPayList;
        }
        // 报名取消
        const cancelableRule = get(params, 'registrationInfo.cancelableRule');
        if (cancelableRule) {
            newParams.mallActivity.cancelable = cancelableRule.cancelable;
            newParams.mallActivity.cancelableRule = cancelableRule.cancelable
                ? cancelableRule.cancelableRule
                : null;
        }
    } else {
        newParams.registrationInfo = null;
    }
    if (params.seniorSetting) {
        const enableNotice = get(params, 'seniorSetting.enableNotice', []).includes('true');
        const subscriptMessage = get(params, 'seniorSetting.subscriptMessage');
        newParams.mallActivity.messageNoticeRule = {
            enableNotice: enableNotice,
            startReminderDuration: enableNotice ? subscriptMessage.num : undefined,
            startReminderUnit: enableNotice ? subscriptMessage.timeUnit : undefined,
        };
        const marking = get(params, 'seniorSetting.marking', undefined);
        if (!isNil(marking)) {
            newParams.mallActivity.tagId = marking.check ? marking.label : '';
        }
    }
    return newParams;
}

export const MallActivityUserCardLevelLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const { 'baseInfo.merchants': merchants } = data;
        // 业务上只允许选择同一分站下的门店
        const subsiteId = merchants && merchants[0] && merchants[0].subSiteId;

        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`;
        config.apiPath = '/admin/store_card_levels';
        // 需要的是非分页数据
        const params = { subsiteId, page: 1, per_page: 500, status: 'ENABLE' };
        const res = await api.get<any>(params, config);
        return chain(res.result)
            .filter((card) => card.cardLevelId)
            .map((card) => ({ id: card.cardLevelId + '', name: card.name }))
            .unionBy('id')
            .value();
    },
};

export const MallActivityLimitCountTypeLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const { 'registrationInfo.session': session } = data;
        if (session === 'NO') {
            return [
                {
                    id: 'NO',
                    name: '无限制',
                },
                {
                    id: 'YES',
                    name: '活动期间限制人数',
                },
            ];
        } else if (session === 'YES') {
            return [
                {
                    id: 'NO',
                    name: '无限制',
                },
                {
                    id: 'YES',
                    name: '每场次限制人数',
                },
            ];
        } else {
            return [];
        }
    },
};
