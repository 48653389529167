import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { CardLevelItems } from './card-level-items';

export default class CardLevelItemsMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(displayInfo: any) {
        return <CardLevelItems {...displayInfo} />;
    }
}
