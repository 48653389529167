import React, { PureComponent, createElement } from 'react';
import { remove, forEach, map, find } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Modal } from 'antd';
import './index.less';

export enum EfutureCouponType {
    EFUTURE_CASH = 'EFUTURE_CASH',
    EFUTURE_BALANCE = 'EFUTURE_BALANCE',
}

export interface EfutureCoupon {
    /**
     * 券编码
     */
    code: string;
    /**
     * 券名称
     */
    name: string;
    /**
     * 券类型
     */
    couponType?: EfutureCouponType;
    typeName: string;
    /**
     * 券面值
     */
    couponValue?: number;
    /**
     * 开始时间
     */
    startTime: string;
    /**
     * 结束时间
     */
    endTime: string;
    /**
     * 门槛说明
     */
    thresholdDescription: string;
    /**
     * 被选中
     */
    select?: boolean;
}

const TableComponentId = 'EfutureCouponSelectorTable';
let Entity: any = {};
let entity: any = {};

/**
 * 选择
 */
export class EfutureCouponSelector extends PureComponent<{
    selectValues: EfutureCoupon[];
    onChange: (products: EfutureCoupon[]) => void;
    type: 'radio' | undefined;
}> {
    dispatch: any = {};
    state = { visible: false };
    selectedRows = new Array<EfutureCoupon>();

    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('EfutureCouponSelector');
        entity = new Entity({});
    }

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(entity));
    }

    onTableRowSelect = (record: EfutureCoupon, selected: boolean) => {
        const { type } = this.props;
        if (selected) {
            if ('radio' === type) {
                this.selectedRows = [record];
            } else {
                if (find(this.selectedRows, { code: record.code })) {
                    return;
                }
                this.selectedRows.push(record);
            }
        } else {
            remove(this.selectedRows, (i) => {
                return i.code === record.code;
            });
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: EfutureCoupon[]) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            this.selectedRows = [];
        }
    };

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
    };

    onOk = () => {
        const { onChange } = this.props;
        if (onChange) {
            forEach(this.selectedRows, (subsite) => {
                subsite.select = true;
            });
            onChange(this.selectedRows);
            this.toggleModal();
        }
    };

    getConfig = () => {
        const { selectValues, type } = this.props;
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'right' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    fields: [
                        {
                            property: 'code',
                        },
                        {
                            property: 'couponType',
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    rowSelection: {
                        type: type ? type : 'checkbox',
                        selectedRowKeys: map(selectValues, 'id'),
                        onSelect: (record: EfutureCoupon, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: EfutureCoupon[]) => {
                            this.onTableRowSelectAll(selected, rows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    columns: [
                        {
                            property: 'typeName',
                        },
                        {
                            property: 'name',
                        },
                        {
                            property: 'code',
                        },
                        {
                            property: 'subsites',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '140px',
                                },
                                countSuffix: services.language.getText('allSubsites'),
                            },
                        },
                    ],
                },
            ],
        };
    };

    toggleModal = () => {
        const { visible } = this.state;

        this.setState({ visible: !visible }, () => {
            if (!visible && entity.paging) {
                entity.search({
                    ...entity.paging,
                });
                this.onClearAll();
            }
        });
    };

    render() {
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 1000,
            title: services.language.getText('xzsfq'),
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };

        return (
            <div className="efuture-coupon-selector">
                <span className="efuture-coupon-selector__button" onClick={this.toggleModal}>
                    {services.language.getText('xzsfq')}
                </span>
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}
