import { Modal } from 'antd';
import React, { MouseEventHandler, ReactNode, useCallback } from 'react';

export interface WechatLinkOptions {
    url: string;
    text: string;
    target?: any;
}
interface Props {
    onChange(message: string): void;
    onClick?(option: WechatLinkOptions): void;
    onDelete?(): void;
    decoratedText: string;
    children?: ReactNode;
}
const HREF_REGEX = /(?<=data-miniprogram-path=")(.*?)(?=")/;
const APP_ID_REGEX = /(?<=data-miniprogram-appid=")(.*?)(?=")/;
const TEXT_REGEX = /(?<=<a.*>)(.*?)(?=<\/a>)/;
export default function WechatLinkMessage(props: Props) {
    const { onClick, onDelete, decoratedText, children } = props;
    const url = decoratedText.match(HREF_REGEX)?.[0] || '';
    const appId = decoratedText.match(APP_ID_REGEX)?.[0] || '';
    const text = decoratedText.match(TEXT_REGEX)?.[0] || '';
    const onLinkClick = () => {
        onClick?.({
            text,
            url,
        });
    };
    const onDeleteLink = useCallback<MouseEventHandler<HTMLSpanElement>>(
        (e) => {
            e.stopPropagation();
            Modal.confirm({
                content: '确定要删除该链接吗？',
                iconType: 'warning',
                okType: 'danger',
                onOk() {
                    onDelete?.();
                },
            });
        },
        [onDelete]
    );
    const miniTextImage = require('../images/icon-text-mini.png');
    return (
        <span>
            <span
                style={{
                    padding: '0 8px',
                    color: '#1890ff',
                    border: '1px solid #1890ff',
                    cursor: 'pointer',
                    borderRadius: '4px',
                }}
                onClick={onLinkClick}
                contentEditable={false}
            >
                {appId ? (
                    <span>
                        <img
                            style={{ margin: '-2px 4px 0 0' }}
                            width={16}
                            height={16}
                            alt=""
                            src={miniTextImage}
                        />
                    </span>
                ) : null}
                {text}
                <span onClick={onDeleteLink} style={{ marginLeft: '4px' }}>
                    X
                </span>
            </span>
            <span hidden>{children}</span>
        </span>
    );
}
