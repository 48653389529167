import { services, builder } from '@comall-backend-builder/core';
import { get } from 'lodash';
import Cookies from 'js-cookie';
import * as actions from '@comall-backend-builder/core/lib/actions';
const localStorage = services.localStorage;
const api = services.api;
const TIME_CRITICAL = 36000000;
const TIME_MIN = 300000;
const TIME_WATCH_RATE = 120000;
let timer: any = null;

const REDIRECT_URI = window.location.origin;

const watch = () => {
    //过期时间戳
    let expiresTime: any = localStorage.get('expiresTime');
    //距离过期的时间间隔
    let timeout: number = expiresTime - new Date().getTime();
    if (timer) {
        window.clearTimeout(timer);
    }
    if (timeout > 0) {
        timer = window.setTimeout(() => {
            const isLoggedIn = services.privilege.hasPrivilege();
            if (isLoggedIn) {
                let waitTimeLimit = Math.min(10000, generateTimeLimitByTimeout(timeout) / 2);
                window.setTimeout(() => {
                    dispatchRefreshTask()
                        .then((response: any = {}) => {
                            let user = { ...localStorage.get<any>('user'), ...response };
                            localStorage.set('user', user);
                            localStorage.set(
                                'expiresTime',
                                new Date().getTime() + get(response, 'expiresIn', 0) * 1000
                            );
                            Cookies.remove('token');
                            Cookies.set('token', get(response, 'accessToken', null));
                            builder.getStore().dispatch(actions.loginSuccessAction(user));
                        })
                        .finally(() => {
                            watch();
                        });
                }, waitTimeLimit);
            } else {
                watch();
            }
        }, generateTimeLimitByTimeout(timeout));
    } else {
        timer = loopMonitoring(TIME_WATCH_RATE);
    }
};

function loopMonitoring(timeLimit: number) {
    return window.setTimeout(() => {
        watch();
    }, timeLimit);
}

function generateTimeLimitByTimeout(timeout: number) {
    if (timeout > TIME_CRITICAL) {
        return TIME_CRITICAL - TIME_MIN;
    } else if (timeout > TIME_MIN) {
        return Math.min(timeout / 2, TIME_MIN);
    } else {
        return timeout / 2;
    }
}

function dispatchRefreshTask() {
    const user = localStorage.get('user');
    const token = window.btoa(`${ENV.CLIENT_ID}:${ENV.CLIENT_SECRET}`);
    let customHeaders = {
        Authorization: `Basic ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    return api.post(
        { refreshToken: get(user, 'refreshToken') },
        { apiPath: '/oauth/token?grant_type=refresh_token', apiRoot: ENV.AUTH_ROOT, customHeaders }
    );
}

const clearLoginInfo = () => {
    localStorage.remove('user');
    localStorage.remove('expiresTime');
    const loginPath = `/oauth/authorize?response_type=code&client_id=${ENV.CLIENT_ID}`;
    window.location.href = `${ENV.AUTH_ROOT}${loginPath}&redirect_uri=${REDIRECT_URI}`;
};

const logout = () => {
    const token = window.btoa(`${ENV.CLIENT_ID}:${ENV.CLIENT_SECRET}`);
    let customHeaders = {
        Authorization: `Basic ${token}`,
        'Content-Type': 'application/x-www-form-urlencoded',
    };
    const user: any = localStorage.get('user');
    const accessToken = get(user, 'accessToken', null);
    const apiPath = `/oauth/token?access_token=${accessToken}`;
    api.delete({}, { apiPath, apiRoot: ENV.AUTH_ROOT, customHeaders }).then(() => {
        builder.getStore().dispatch(actions.logoutSuccessAction());
        refreshToken.clearLoginInfo();
    });
};
export const refreshToken = {
    watch,
    clearLoginInfo,
    logout,
};
