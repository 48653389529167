import React, { Component, createElement } from 'react';
import { connect } from 'react-redux';
import { get, isEmpty } from 'lodash';
import { Button, Icon, message, Modal } from 'antd';
import { localStorage, api, language } from '@comall-backend-builder/core/lib/services';
import {
    actions,
    builder,
    ComponentsManager,
    EntitiesManager,
    services,
} from '@comall-backend-builder/core';
import { Shop } from '../../../select-shop';

const TableComponentId = 'MarketingCalendarPageSettingStoreSelectorTable';
let Entity: any = {};
let entity: any = {};

const marketingCalendarPageSettingStoreName = 'marketingCalendarPageSettingStoreSelector';
const hasStoreInfo = () => !!localStorage.get(marketingCalendarPageSettingStoreName);

export class MarketingCalendarPageSettingStoreSelector extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('storeSelect');
        entity = new Entity({});
        this.state = {
            visible: false,
            hasSubsite: hasStoreInfo(),
        };
    }

    dispatch: any = {};
    selectSubsiteInfo = {
        id: '',
        name: '',
    };

    componentDidMount() {
        let store: any = localStorage.get(marketingCalendarPageSettingStoreName);
        const { refresh } = this.props;
        if (store && store.id) {
            this.setState({
                hasSubsite: true,
            });
        } else {
            api.get({}, { apiPath: '/admin/subsites/mine' }).then((res: any) => {
                if (res && res.result.length > 0) {
                    localStorage.set(marketingCalendarPageSettingStoreName, {
                        id: res.result[0].id,
                        name: res.result[0].name,
                    });
                    this.setState(
                        {
                            hasSubsite: true,
                        },
                        () => {
                            refresh();
                        }
                    );
                }
            });
        }
    }

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(entity));
    }

    componentWillReceiveProps(nextProps: any) {
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
    }

    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    hideModal = () => {
        this.selectSubsiteInfo = {
            id: '',
            name: '',
        };
        this.setState({
            visible: false,
        });
    };

    confirmHandle = () => {
        const { refresh } = this.props;
        if (isEmpty(this.selectSubsiteInfo) || isEmpty(this.selectSubsiteInfo.id)) {
            message.warning(services.language.getText('qzsxzygmd'));
            return;
        }
        localStorage.set(marketingCalendarPageSettingStoreName, {
            id: this.selectSubsiteInfo.id,
            name: this.selectSubsiteInfo.name,
        });
        this.hideModal();
        refresh();
    };

    onTableRowSelect = (record: any, selected: boolean) => {
        if (selected) {
            this.selectSubsiteInfo = record;
        } else {
            this.selectSubsiteInfo = {
                id: '',
                name: '',
            };
        }
    };

    render() {
        const { visible, hasSubsite } = this.state;
        const { entity } = this.props;
        if (entity.params && entity.params.status) {
            delete entity.params.status;
        }
        const store: any = localStorage.get(marketingCalendarPageSettingStoreName) || {};
        return (
            <div className="wechat-template-find-store-selector">
                <span>{services.language.getText('subsiteName')}： </span>
                <Button onClick={this.showModal}>
                    {hasSubsite && store.name ? store.name : language.getText('selectSubsite')}
                    <Icon type="down" style={{ marginLeft: 8 }} />
                </Button>
                <Modal
                    title={services.language.getText('otherSelectSubsite')}
                    className="store-selector"
                    width={800}
                    visible={visible}
                    onCancel={this.hideModal}
                    cancelButtonProps={{ disabled: true }}
                    footer={[
                        <Button key="back" onClick={this.hideModal}>
                            {services.language.getText('qx')}
                        </Button>,
                        <Button key="submit" type="primary" onClick={this.confirmHandle}>
                            {services.language.getText('common.ok')}
                        </Button>,
                    ]}
                >
                    <div>
                        {createElement(ComponentsManager.get('FilterFormPlus'), {
                            style: {
                                width: '98%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                marginBottom: 8,
                            },
                            direction: 'inline',
                            entity,
                            submit: {
                                text: services.language.getText('common.search'),
                                style: { marginRight: -32 },
                            },
                            fields: [
                                {
                                    property: 'name',
                                    controlConfig: {
                                        style: { width: 300 },
                                    },
                                },
                            ],
                        })}
                        {createElement(ComponentsManager.get('DataTable'), {
                            componentId: TableComponentId,
                            loadFirstPage: true,
                            entity,
                            columns: [{ property: 'name' }],
                            rowSelection: {
                                type: 'radio',
                                selectedRowKeys: store ? store.id : undefined,
                                onSelect: (record: Shop, selected: boolean) => {
                                    this.onTableRowSelect(record, selected);
                                },
                                onChange: () => {},
                            },
                            pagination: {
                                pageSize: 10,
                                showSizeChanger: true,
                                showQuickJumper: true,
                                pageSizeOptions: ['10', '15', '20'],
                                showTotal(total: string) {
                                    return `共 ${total} 条记录`;
                                },
                            },
                        })}
                    </div>
                </Modal>
            </div>
        );
    }
}

export const marketingCalendarPageSettingStoreSelector = connect((_state: any) => {
    let requestStatus = get(entity, 'requestStatus', null);
    const result = { requestStatus };
    return result;
})(MarketingCalendarPageSettingStoreSelector);
