import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach, find, isEmpty } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;

export const FullPaymentActivitiesLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`;
        config.apiPath = '/admin/full_payment_activities';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/full_payment_activities/${id}`;
        } else {
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
            if (!isEmpty(data.resaleDate)) {
                data.resaleStartDate = data.resaleDate.start;
                data.resaleEndDate = data.resaleDate.end;
                delete data.resaleDate;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                const round = res.rounds[0];
                res.id = res.activity.id;
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : language.getText('longTerm'),
                    };
                }
                res.activityInfo = {
                    mode: 'FULL_PAYMENT',
                    name: res.activity.name,
                    dateRange: {
                        start: res.activity.startTime,
                        end: res.activity.endTime,
                    },
                    subsites: res.subsites,
                    stockMode: res.activity.stockMode,
                    goods:
                        res.activity.stockMode === 'GOODS_STOCK' && res.goods
                            ? res.goods.map((item: any) => {
                                  item.merchantName = item.merchant.name;
                                  item.subsiteName = item.subsite.name;
                                  item.subsiteId = item.subsite.id;
                                  item.goodsDeductPoint = item.pointPrice;
                                  return item;
                              })
                            : null,
                    products:
                        res.activity.stockMode === 'SHARE_STOCK' && res.products
                            ? res.products.map((item: any) => {
                                  item.id = item.productId;
                                  item.merchantName = item.merchant && item.merchant.name;
                                  return item;
                              })
                            : null,
                    participateCondition: [
                        {
                            code: 'QUANTITYLIMIT',
                            checked: round.quantityLimit ? true : false,
                            value: round.quantityLimit,
                        },
                        {
                            code: 'QUANTITYLIMITPERORDER',
                            checked: round.quantityLimitPerOrder ? true : false,
                            value: round.quantityLimitPerOrder,
                        },
                    ],
                    resaleDate: res.activity.resaleDate,
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };
                if (res.activity.deliveryStartDate && res.activity.deliveryEndDate) {
                    res.activityInfo.deliveryDateRange = {
                        start: res.activity.deliveryStartDate,
                        end: res.activity.deliveryEndDate,
                    };
                }
            } else {
                if (!id) {
                    res.result =
                        res &&
                        res.result.map((item: any) => {
                            item.id = item.activity.id;
                            item.mode = item.activity.mode;
                            item.name = item.activity.name;
                            item.startTime = item.activity.startTime;
                            item.endTime = item.activity.endTime;
                            item.executeStatus = item.activity.executeStatus;
                            item.status = item.activity.status;
                            item.resaleDate = item.activity.resaleDate;
                            return item;
                        });
                }
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`;
        config.apiPath = '/admin/full_payment_activities';
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`;
        config.apiPath = `/admin/full_payment_activities/${id}`;

        const newParams = dataParser(params);
        newParams.id = id;
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    if (params.activityInfo) {
        newParams.activity = {
            mode: 'FULL_PAYMENT',
            name: params.activityInfo.name,
            startTime: params.activityInfo.dateRange.start,
            endTime: params.activityInfo.dateRange.end,
            deliveryStartDate:
                params.activityInfo.deliveryDateRange &&
                params.activityInfo.deliveryDateRange.start,
            deliveryEndDate:
                params.activityInfo.deliveryDateRange && params.activityInfo.deliveryDateRange.end,
            stockMode: params.activityInfo.stockMode,
            resaleDate: params.activityInfo.resaleDate
                ? params.activityInfo.resaleDate.split(' ')[0]
                : undefined,
        };
        const subsites = new Array<{ id: string }>();
        forEach(params.activityInfo.subsites, (subsite) => {
            subsites.push({
                id: subsite.id,
            });
        });
        newParams.subsites = subsites;
        if (params.activityInfo.stockMode === 'GOODS_STOCK') {
            newParams.goods = params.activityInfo.goods;
            newParams.products = null;
        } else if (params.activityInfo.stockMode === 'SHARE_STOCK') {
            newParams.goods = null;
            newParams.products = params.activityInfo.products;
        }
        const participateCondition = params.activityInfo.participateCondition;
        const quantityLimit: any = find(participateCondition, { code: 'QUANTITYLIMIT' });
        const quantityLimitPerOrder: any = find(participateCondition, {
            code: 'QUANTITYLIMITPERORDER',
        });
        newParams.rounds = [
            {
                quantityLimit: quantityLimit && quantityLimit.checked ? quantityLimit.value : null,
                quantityLimitPerOrder:
                    quantityLimitPerOrder && quantityLimitPerOrder.checked
                        ? quantityLimitPerOrder.value
                        : null,
            },
        ];
        newParams.limitCondition = {};
        newParams.participateCondition = {};
        newParams.marketingScheduleId =
            (params.activityInfo?.marketingSchedule?.length &&
                params.activityInfo.marketingSchedule[0].id) ||
            null;
    }
    return newParams;
}
