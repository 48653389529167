import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { AffiliateMarketingPointExchange } from './affiliate-marketing-point-exchange';

export class AffiliateMarketingPointExchangeMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <AffiliateMarketingPointExchange {...controlInfo} />;
    }
}
