import React, { Component } from 'react';
import {
    default as AntTimePicker,
    TimePickerProps as AntTimePickerProps,
} from 'antd/lib/time-picker';
import { range } from 'lodash';
import moment from 'moment';

import { globalConfig } from '@comall-backend-builder/core';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

/**
 * 实际组件暴露的 Props
 *
 * 是由转发的 antd Props 组合组件自身的 Props 组成的
 */

export interface TimeRangePickerPlusProps
    extends Pick<
        AntTimePickerProps,
        | 'className'
        | 'style'
        | 'format'
        | 'disabled'
        | 'allowClear'
        | 'minuteStep'
        | 'hourStep'
        | 'secondStep'
        | 'hideDisabledOptions'
        | 'use12Hours'
    > {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;

    /**
     * 内容改变回调，由props传入
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange?: (value: any, name: string) => void;
    /**
     * 当前选择的时间，与antd value格式不同
     */
    value?: {
        start?: string;
        end?: string;
    };

    /**
     * 可选时间范围
     */
    range?: {
        start: string;
        end: string;
    };

    /**
     * 输入框提示文字，与antd placeholder格式不同
     */
    placeholder?: string[];

    startDisabledHours?: () => number[];
    startDisabledMinutes?: (selectedHour: number) => number[];
    startDisabledSeconds?: (selectedHour: number, selectedMinute: number) => number[];
    endDisabledHours?: () => number[];
    endDisabledMinutes?: (selectedHour: number) => number[];
    endDisabledSeconds?: (selectedHour: number, selectedMinute: number) => number[];
    allowSingle?: boolean;
}

export class TimeRangePickerPlus extends Component<TimeRangePickerPlusProps, never> {
    /**
     * 全局配置月份格式，默认为 HH:mm
     */
    format: string;

    /**
     * 开始时间
     */
    startString: string;

    /**
     * 结束时间
     */
    endString: string;

    constructor(props: TimeRangePickerPlusProps) {
        super(props);
        this.endDisabledHours = this.endDisabledHours.bind(this);
        this.endDisabledMinutes = this.endDisabledMinutes.bind(this);
        this.endDisabledSeconds = this.endDisabledSeconds.bind(this);
        this.startDisabledHours = this.startDisabledHours.bind(this);
        this.startDisabledMinutes = this.startDisabledMinutes.bind(this);
        this.startDisabledSeconds = this.startDisabledSeconds.bind(this);
        this.format = props.format || globalConfig.get('format.timeRange') || '';
        this.startString = '';
        this.endString = '';
    }
    onTimeChange(status: 'start' | 'end', _time: moment.Moment, timeString: string) {
        const { onChange, name, range: limit, format, minuteStep, allowSingle } = this.props;
        if (status === 'start') {
            this.startString = timeString;
        } else if (status === 'end') {
            this.endString = timeString;
        }
        if (allowSingle && onChange) {
            onChange({ start: this.startString, end: this.endString }, name);
            return;
        }
        let defaultStart = limit?.start || '00:00:00';
        const end = format === 'HH:mm' && minuteStep === 30 ? '23:30:00' : '23:59:59';
        let defaultEnd = limit?.end || end;
        if (onChange) {
            const timeRange = {
                start: this.startString || moment(defaultStart, 'HH:mm:ss').format(this.format),
                end: this.endString || moment(defaultEnd, 'HH:mm:ss').format(this.format),
            };
            onChange(timeRange, name);
        }
    }
    startDisabledHours() {
        const { value, range: limit } = this.props;
        let endTime: string | undefined;
        if (value) {
            endTime = value.end;
        }
        let startLimit = moment(limit?.start, this.format).hour() || 0;

        let endTimeHour = moment(endTime, this.format).hour();

        return endTime
            ? range(endTimeHour + 1, 24).concat(range(0, startLimit))
            : range(0, startLimit);
    }
    startDisabledMinutes(selectedHour: number) {
        const { value, range: limit } = this.props;
        let endTime: string | undefined;
        if (value) {
            endTime = value.end;
        }
        let startLimit = moment(limit?.start, this.format);

        const end = moment(endTime, this.format);

        let disabledMinutes: number[] = [];

        if (selectedHour === end.hour()) {
            if (selectedHour === startLimit.hour()) {
                disabledMinutes = range(end.minute() + 1, 60).concat(range(0, startLimit.minute()));
            } else {
                disabledMinutes = range(end.minute() + 1, 60);
            }
        } else if (selectedHour === startLimit.hour()) {
            disabledMinutes = range(0, startLimit.minute());
        } else {
            disabledMinutes = [];
        }

        return disabledMinutes;
    }
    startDisabledSeconds(selectedHour: number, selectedMinute: number) {
        const { value, range: limit } = this.props;
        let endTime: string | undefined;
        if (value) {
            endTime = value.end;
        }
        const end = moment(endTime, this.format);
        const startLimit = moment(limit?.start, this.format);
        let disabledSeconds: number[] = [];
        if (selectedHour !== end.hour() && selectedHour !== startLimit.hour()) {
            disabledSeconds = [];
        } else if (selectedHour === end.hour() && selectedHour === startLimit.hour()) {
            if (selectedMinute === end.minute() && selectedMinute === startLimit.minute()) {
                disabledSeconds = range(0, startLimit.second()).concat(range(end.second() + 1, 60));
            } else if (selectedMinute === end.minute()) {
                disabledSeconds = range(end.second() + 1, 60);
            } else if (selectedMinute === startLimit.minute()) {
                disabledSeconds = range(0, startLimit.second());
            } else {
                disabledSeconds = [];
            }
        } else {
            if (selectedHour === end.hour()) {
                disabledSeconds =
                    selectedMinute === end.minute() ? range(end.second() + 1, 60) : [];
            } else {
                disabledSeconds =
                    selectedMinute === startLimit.minute() ? range(0, startLimit.second()) : [];
            }
        }
        return disabledSeconds;
    }
    endDisabledHours() {
        const { value, range: limit } = this.props;
        let startTime: string | undefined;
        if (value) {
            startTime = value.start;
        }
        const start = moment(startTime, this.format).hour();
        const endLimit = moment(limit?.end, this.format).hour() || 23;
        return startTime
            ? range(0, start).concat(range(endLimit + 1, 24))
            : range(endLimit + 1, 24);
    }
    endDisabledMinutes(selectedHour: number) {
        const { value, range: limit } = this.props;
        let startTime: string | undefined;
        if (value) {
            startTime = value.start;
        }
        const start = moment(startTime, this.format);
        const endLimit = moment(limit?.end, this.format);
        let disabledMinutes: number[] = [];
        if (selectedHour === start.hour()) {
            if (selectedHour === endLimit.hour()) {
                disabledMinutes = range(0, start.minute()).concat(range(endLimit.minute() + 1, 60));
            } else {
                disabledMinutes = range(0, start.minute());
            }
        } else if (selectedHour === endLimit.hour()) {
            disabledMinutes = range(endLimit.minute() + 1, 60);
        } else {
            disabledMinutes = [];
        }

        return disabledMinutes;
    }
    endDisabledSeconds(selectedHour: number, selectedMinute: number) {
        const { value, range: limit } = this.props;
        let startTime: string | undefined;
        if (value) {
            startTime = value.start;
        }
        const start = moment(startTime, this.format);
        const endLimit = moment(limit?.end, this.format);
        let disabledSeconds: number[] = [];
        if (selectedHour !== start.hour() && selectedHour !== endLimit.hour()) {
            disabledSeconds = [];
        } else if (selectedHour === start.hour() && selectedHour === endLimit.hour()) {
            if (selectedMinute === start.minute() && selectedMinute === endLimit.minute()) {
                disabledSeconds = range(endLimit.second() + 1, 60).concat(range(0, start.second()));
            } else if (selectedMinute === start.minute()) {
                disabledSeconds = range(0, start.second());
            } else if (selectedMinute === endLimit.minute()) {
                disabledSeconds = range(endLimit.second() + 1, 60);
            } else {
                disabledSeconds = [];
            }
        } else {
            if (selectedHour === start.hour()) {
                disabledSeconds = selectedMinute === start.minute() ? range(0, start.second()) : [];
            } else {
                disabledSeconds =
                    selectedMinute === endLimit.minute() ? range(endLimit.second() + 1, 60) : [];
            }
        }
        return disabledSeconds;
    }
    render() {
        const {
            minuteStep,
            hourStep,
            secondStep,
            hideDisabledOptions,
            startDisabledHours,
            startDisabledMinutes,
            startDisabledSeconds,
            endDisabledHours,
            endDisabledMinutes,
            endDisabledSeconds,
            use12Hours,
            style,
            allowClear,
            ...restProps
        } = this.props;
        const props = {
            format: this.format,
            minuteStep,
            hourStep,
            secondStep,
            hideDisabledOptions,
            use12Hours,
            allowClear,
            style,
            ...restProps,
        };
        let { value, placeholder } = this.props;
        let startTime: moment.Moment | undefined;
        let endTime: moment.Moment | undefined;
        if (value && (value.start || value.end)) {
            let { start, end } = value;
            this.startString = start || '';
            this.endString = end || '';
            startTime = start ? moment(start, this.format) : undefined;
            endTime = end ? moment(end, this.format) : undefined;
        }

        let placeholderStart = services.language.getText(
            'components.TimeRangePicker.placeholder.start'
        );
        let placeholderEnd = services.language.getText(
            'components.TimeRangePicker.placeholder.end'
        );

        // 未设置 placeholder ，则使用默认
        if (placeholder && placeholder.length > 1) {
            placeholderStart = placeholder[0];
            placeholderEnd = placeholder[1];
        }

        return (
            <div>
                <AntTimePicker
                    {...props}
                    value={startTime}
                    onChange={this.onTimeChange.bind(this, 'start')}
                    disabledHours={startDisabledHours || this.startDisabledHours}
                    disabledMinutes={startDisabledMinutes || this.startDisabledMinutes}
                    disabledSeconds={startDisabledSeconds || this.startDisabledSeconds}
                    placeholder={placeholderStart}
                />
                <span style={{ margin: '0 10px' }}>{language.getText('to')}</span>
                <AntTimePicker
                    {...props}
                    value={endTime}
                    onChange={this.onTimeChange.bind(this, 'end')}
                    disabledHours={endDisabledHours || this.endDisabledHours}
                    disabledMinutes={endDisabledMinutes || this.endDisabledMinutes}
                    disabledSeconds={endDisabledSeconds || this.endDisabledSeconds}
                    placeholder={placeholderEnd}
                />
            </div>
        );
    }
}
