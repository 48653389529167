import React, { Component } from 'react';
import { InputNumber } from 'antd';
import { forEach } from 'lodash';

import './index.less';
import { services } from '@comall-backend-builder/core';

type CitywideDeliveryFreightLadderValue = {
    /**
     * 阶梯数
     */
    ladderValue?: number;
    /**
     * 阶梯金额
     */
    ladderAmount?: number;
};

export interface CitywideDeliveryFreightLadderProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: CitywideDeliveryFreightLadderValue | null, name: string) => void;
    /**
     * 当前值
     */
    value: CitywideDeliveryFreightLadderValue;
    rules: any;
    ladderValueAddbefore: string;
    ladderValueAddAfter: string;
    ladderAmountAddbefore: string;
    ladderAmountAddAfter: string;
    ladderValuePrecision: number;
    ladderAmountPrecision: number;
}
export class CitywideDeliveryFreightLadder extends Component<CitywideDeliveryFreightLadderProps> {
    onLadderValueChange(ladderValue: any) {
        let { name, value, onChange } = this.props;
        if (!value) {
            value = {};
        }
        value.ladderValue = ladderValue;
        if (onChange) {
            onChange(value, name);
        }
    }

    onLadderAmountChange(ladderAmount: any) {
        let { name, value, onChange } = this.props;
        if (!value) {
            value = {};
        }
        value.ladderAmount = ladderAmount;
        if (onChange) {
            onChange(value, name);
        }
    }

    render() {
        const {
            value,
            rules,
            ladderValueAddbefore,
            ladderValueAddAfter,
            ladderAmountAddbefore,
            ladderAmountAddAfter,
            ladderValuePrecision,
            ladderAmountPrecision,
        } = this.props;
        let ruleInfo = {};
        if (rules && rules.length) {
            forEach(rules, (item) => {
                Object.assign(ruleInfo, item);
            });
        }
        return (
            <div className="citywide-delivery-freight-ladder">
                <div className="content">
                    <span className="item-text">{ladderValueAddbefore}</span>
                    <div className="number-input">
                        <InputNumber
                            placeholder={services.language.getText('inputPlease')}
                            value={value && value.ladderValue}
                            min={0}
                            max={999999}
                            step={1}
                            precision={ladderValuePrecision ? ladderValuePrecision : 0}
                            style={{ width: '130px' }}
                            onChange={(value: any) => {
                                this.onLadderValueChange(value);
                            }}
                            {...ruleInfo}
                        ></InputNumber>
                    </div>
                    <span className="item-text">{ladderValueAddAfter}</span>
                    <span className="item-text">{ladderAmountAddbefore}</span>
                    <div className="number-input">
                        <InputNumber
                            placeholder={services.language.getText('inputPlease')}
                            value={value && value.ladderAmount}
                            min={0}
                            max={999999}
                            step={1}
                            precision={ladderAmountPrecision ? ladderAmountPrecision : 0}
                            style={{ width: '130px' }}
                            onChange={(value: any) => {
                                this.onLadderAmountChange(value);
                            }}
                            {...ruleInfo}
                        ></InputNumber>
                    </div>
                    <span className="item-text">{ladderAmountAddAfter}</span>
                </div>
            </div>
        );
    }
}
