import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
const api = services.api;
export const SubsitesMerchantsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/subsites/mine/merchants';
        return await api.get(data, config).then((res: any) => {
            let result = res.map((item: any) => {
                return {
                    id: item.merchantId,
                    name: item.merchantName,
                };
            });

            if (data.merchantIds || data.merchantIds === 0) {
                if (!Array.isArray(data.merchantIds)) {
                    data.merchantIds = data.merchantIds.toString();
                    data.merchantIds = data.merchantIds.includes(',')
                        ? data.merchantIds.split(',')
                        : [data.merchantIds];
                }
                data.merchantIds = data.merchantIds.map((id: any) => id.toString());

                result = result && result.filter((i: any) => data.merchantIds.includes(i.id));
            }
            return result;
        });
    },
};

export const SubsiteLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/defaults/subsite';
        return await api.get(data, config).then((res: any) => {
            if (data.subsiteIds) {
                if (!Array.isArray(data.subsiteIds)) {
                    data.subsiteIds = data.subsiteIds.toString();
                    data.subsiteIds = data.subsiteIds.includes(',')
                        ? data.subsiteIds.split(',')
                        : [data.subsiteIds];
                }
                data.subsiteIds = data.subsiteIds.map((id: any) => id.toString());
                res = res && res.filter((i: any) => data.subsiteIds.includes(i.id));
            }
            return res;
        });
    },
};
