import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { LogisticsMethod } from '../../containers/order/manage-order/types';

const api = services.api;

export const DeliveryLogisticsConfigsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`;
        config.apiPath = '/admin/delivery_logistics_configs';
        if (data.id) {
            config.apiPath = '/admin/delivery_logistics_configs/:id';
        }

        return await api.get(data, config).then((res: any) => {
            if (data.id) {
                res.baseInfo = {
                    subsite: res.subsite,
                    subsiteMerchants: {
                        selectMode:
                            res.subsiteMerchants && res.subsiteMerchants.length > 0
                                ? 'false'
                                : 'true',
                        merchants: res.subsiteMerchants ? res.subsiteMerchants : [],
                    },
                };
                res.insured.defaultAutomaticBiz = {
                    miniProgramOrderAutomatic: (res.defaultAutomaticBiz || []).includes(
                        'MINI_PROGRAM_ORDER'
                    ),
                    shoppingListAutomatic: (res.defaultAutomaticBiz || []).includes(
                        'SHOPPING_LIST'
                    ),
                };
                return res;
            } else {
                res.result = res.result.map((item: any) => {
                    const expressCompanies = [];
                    if (item.expressCompanies && item.expressCompanies.length > 0) {
                        const automaticList = item.expressCompanies.filter(
                            (item: any) =>
                                item.logisticsBillGenerationMethods &&
                                item.logisticsBillGenerationMethods.includes(
                                    LogisticsMethod.AUTOMATIC
                                )
                        );
                        const manualList = item.expressCompanies.filter(
                            (item: any) =>
                                item.logisticsBillGenerationMethods &&
                                item.logisticsBillGenerationMethods.includes(LogisticsMethod.MANUAL)
                        );
                        if (automaticList && automaticList.length > 0) {
                            expressCompanies.push({
                                name: '自动叫单',
                                contents: automaticList.map((a: any) => {
                                    return a.companyName;
                                }),
                            });
                        }
                        if (manualList && manualList.length > 0) {
                            expressCompanies.push({
                                name: '手动填单',
                                contents: manualList.map((a: any) => {
                                    return a.companyName;
                                }),
                            });
                        }
                    }
                    const cityWideCompanies = [];
                    if (item.cityWideCompanies && item.cityWideCompanies.length > 0) {
                        const automaticList = item.cityWideCompanies.filter(
                            (item: any) =>
                                item.logisticsBillGenerationMethods &&
                                item.logisticsBillGenerationMethods.includes(
                                    LogisticsMethod.AUTOMATIC
                                )
                        );
                        const manualList = item.cityWideCompanies.filter(
                            (item: any) =>
                                item.logisticsBillGenerationMethods &&
                                item.logisticsBillGenerationMethods.includes(LogisticsMethod.MANUAL)
                        );
                        if (automaticList && automaticList.length > 0) {
                            cityWideCompanies.push({
                                name: '自动叫单',
                                contents: automaticList.map((a: any) => {
                                    return a.companyName;
                                }),
                            });
                        }
                        if (manualList && manualList.length > 0) {
                            cityWideCompanies.push({
                                name: '手动填单',
                                contents: manualList.map((a: any) => {
                                    return a.companyName;
                                }),
                            });
                        }
                    }
                    return {
                        ...item,
                        insuredDescription: item.insured.description,
                        subsiteName: item.subsite?.name,
                        subsiteId: item.subsite?.id,
                        expressCompanies: expressCompanies,
                        cityWideCompanies: cityWideCompanies,
                    };
                });
                return res;
            }
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`;
        config.apiPath = '/admin/delivery_logistics_configs';
        params.subsiteId = params.baseInfo.subsite.id;
        if (
            params.baseInfo.subsiteMerchants &&
            params.baseInfo.subsiteMerchants.selectMode === 'false'
        ) {
            params.merchantIds = params.baseInfo.subsiteMerchants.merchants.map(
                (m: any) => m.merchantId
            );
        }
        if (params.insured && params.insured.defaultAutomaticBiz) {
            const defaultAutomaticBiz = [];
            if (params.insured.defaultAutomaticBiz.miniProgramOrderAutomatic) {
                defaultAutomaticBiz.push('MINI_PROGRAM_ORDER');
            }
            if (params.insured.defaultAutomaticBiz.shoppingListAutomatic) {
                defaultAutomaticBiz.push('SHOPPING_LIST');
            }
            params.defaultAutomaticBiz = defaultAutomaticBiz;
            delete params.insured.defaultAutomaticBiz;
        } else {
            params.defaultAutomaticBiz = ['MINI_PROGRAM_ORDER', 'SHOPPING_LIST'];
        }
        delete params.baseInfo;
        return api.post(params, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`;
        config.apiPath = '/admin/delivery_logistics_configs/' + params.id;
        delete params.id;
        params.subsiteId = params.baseInfo.subsite.id;
        if (
            params.baseInfo.subsiteMerchants &&
            params.baseInfo.subsiteMerchants.selectMode === 'false'
        ) {
            params.merchantIds = params.baseInfo.subsiteMerchants.merchants.map(
                (m: any) => m.merchantId
            );
        }
        if (params.insured && params.insured.defaultAutomaticBiz) {
            const defaultAutomaticBiz = [];
            if (params.insured.defaultAutomaticBiz.miniProgramOrderAutomatic) {
                defaultAutomaticBiz.push('MINI_PROGRAM_ORDER');
            }
            if (params.insured.defaultAutomaticBiz.shoppingListAutomatic) {
                defaultAutomaticBiz.push('SHOPPING_LIST');
            }
            params.defaultAutomaticBiz = defaultAutomaticBiz;
            delete params.insured.defaultAutomaticBiz;
        } else {
            params.defaultAutomaticBiz = ['MINI_PROGRAM_ORDER', 'SHOPPING_LIST'];
        }
        delete params.baseInfo;
        return api.put(params, config);
    },
};
