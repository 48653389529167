import React, { Component } from 'react';
import { Switch } from 'antd';
import './index.less';
import { services } from '@comall-backend-builder/core';
const api = services.api;
const { getText } = services.language;

interface SwitchForm {
    searchAuditBroadcast: boolean;
    waitSendBroadcast: boolean;
    refundFailBroadcast: boolean;
    serviceApplyBroadcast: boolean;
}

interface VoiceSettingFormState {
    switchForm: SwitchForm;
}

/**
 * 语音播报
 */
export class VoiceSettingForm extends Component<any, VoiceSettingFormState> {
    state: VoiceSettingFormState = {
        switchForm: {
            searchAuditBroadcast: false,
            waitSendBroadcast: false,
            serviceApplyBroadcast: false,
            refundFailBroadcast: false,
        },
    };

    componentDidMount() {
        this.loadData();
    }

    loadData = async () => {
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/broadcast_configs/1',
        };
        const res = await api.get<SwitchForm>({}, config);

        this.setState({
            switchForm: res,
        });
    };

    handleSwitchChange = (switchName: string, checked: boolean) => {
        const { switchForm } = this.state;
        const newSwitchForm = {
            ...switchForm,
            [switchName]: checked,
        };

        this.setState(() => ({
            switchForm: newSwitchForm,
        }));

        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/broadcast_configs/1',
        };
        api.put(newSwitchForm, config);
    };

    render() {
        const { switchForm } = this.state;

        return (
            <div className="voice">
                <div className="voice-order">
                    <h3 style={{ fontWeight: 900 }}>{getText('ddbb')}</h3>
                    <div className="voice-order-review">
                        <span style={{ marginRight: '30px' }}>{getText('fhwtgyybb')}：</span>
                        <Switch
                            checkedChildren={getText('kai')}
                            unCheckedChildren={getText('guan')}
                            onChange={this.handleSwitchChange.bind(this, 'searchAuditBroadcast')}
                            checked={switchForm.searchAuditBroadcast}
                        />
                    </div>
                    <div className="voice-order-delivery">
                        <span style={{ marginRight: '30px' }}>{getText('dfhddyybb')}：</span>
                        <Switch
                            checkedChildren={getText('kai')}
                            unCheckedChildren={getText('guan')}
                            onChange={this.handleSwitchChange.bind(this, 'waitSendBroadcast')}
                            checked={switchForm.waitSendBroadcast}
                        />
                    </div>
                </div>
                <div className="voice-aftersales">
                    <h3 style={{ fontWeight: 900 }}>{getText('shdbb')}</h3>
                    <span style={{ marginRight: '43px' }}>{getText('xshdyybb')}：</span>
                    <Switch
                        checkedChildren={getText('kai')}
                        unCheckedChildren={getText('guan')}
                        onChange={this.handleSwitchChange.bind(this, 'serviceApplyBroadcast')}
                        checked={switchForm.serviceApplyBroadcast}
                    />
                </div>
                <div className="voice-refund">
                    <h3 style={{ fontWeight: 900 }}>{getText('tkdbb')}</h3>
                    <span style={{ marginRight: '15px' }}>{getText('tksbddyybb')}：</span>
                    <Switch
                        checkedChildren={getText('kai')}
                        unCheckedChildren={getText('guan')}
                        onChange={this.handleSwitchChange.bind(this, 'refundFailBroadcast')}
                        checked={switchForm.refundFailBroadcast}
                    />
                </div>
            </div>
        );
    }
}
