import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { MessageNotificationMethod } from './message-notification-method';

export class MessageNotificationMethodMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any) {
        return <MessageNotificationMethod {...displayInfo} />;
    }
}
