import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { SelectContentTopic, DisplayContentTopic } from '../../../containers';

export class ContentTopicSelectorMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <SelectContentTopic {...displayInfo} />;
    }
    /**
     * 获取有数据状态展示组件
     */
    getAvailableDisplayComponent(value: any, displayInfo: any) {
        return <DisplayContentTopic {...displayInfo} />;
    }
    /**
     * 获取无数据状态展示组件
     */
    getNotAvailableDisplayComponent(displayInfo: any) {
        return <div></div>;
    }
    /**
     * 获取展示组件
     */
    getDisplayComponent(array: any, displayInfo: any) {
        return <DisplayContentTopic {...displayInfo} />;
    }
}
