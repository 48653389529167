import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        OfflineOrderCommentsCustomizationEntity: {
            apiPath: '/loader/admin/offline-order-comments-customization',
            filters: {
                subsite: {
                    type: 'object.option.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                    },
                },

                receiptNo: {
                    type: 'string',
                    displayName: '<<xpbh>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                dateRange: {
                    type: 'object.dateRange',
                    displayName: '<<xzrq_1>>',
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                nickName: {
                    type: 'string',
                    displayName: '<<nickName>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<pjsj>>',
                },
                score: {
                    type: 'string',
                    displayName: '<<totalScore>>',
                },
                receiptNo: {
                    type: 'string',
                    displayName: '<<xpbh>>',
                },
                satisfactionStatus: {
                    type: 'string',
                    displayName: '<<myd>>',
                },
                followUpStatus: {
                    type: 'string',
                    displayName: '<<jshf>>',
                },
                followUpMobile: {
                    type: 'string',
                    displayName: '<<hfdh>>',
                },
            },
        },
    },
    components: {
        OfflineOrderCommentsCustomizationView: {
            component: 'Viewport',
        },
        OfflineOrderCommentsCustomizationPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'OfflineOrderCommentsCustomizationEntity',
            items: [
                {
                    component: 'OfflineOrderCommentsCustomizationFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    style: {
                        flexDirection: 'row',
                    },
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'ExportDataByFilterParamsButton',
                            params: {
                                text: '<<exportSearchResult>>',
                                style: {
                                    width: '120px',
                                },
                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COMMENT`,
                                apiPath: '/admin/custom_comments/export',
                                type: 'offline-order-comments-customization',
                                requestMethod: 'get',
                                target: '_blank',
                            },
                        },
                    ],
                },
                { component: 'OfflineOrderCommentsCustomizationTable' },
            ],
        },
        OfflineOrderCommentsCustomizationFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsite',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'receiptNo',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        OfflineOrderCommentsCustomizationTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'OfflineOrderCommentsCustomizationDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: {
                        x: true,
                    },
                    columns: [
                        {
                            property: 'nickName',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'mobile',
                        },
                        {
                            property: 'subsiteName',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'createTime',
                        },
                        {
                            property: 'score',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'satisfactionStatus',
                            // title: '满意度',
                            // displayConfig: {
                            //     statusConfig: {
                            //         PRAISE: {
                            //             text: '满意',
                            //             status: 'success',
                            //         },
                            //         POOR: {
                            //             text: '不满意',
                            //             status: 'error',
                            //         },
                            //     },
                            // },
                        },
                        {
                            property: 'followUpStatus',
                            // displayConfig: {
                            //     statusConfig: {
                            //         'true': {
                            //             text: '意愿',
                            //             status: 'success',
                            //         },
                            //         'false': {
                            //             text: '不意愿',
                            //             status: 'error',
                            //         },
                            //     },
                            // },
                        },
                        {
                            property: 'followUpMobile',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                    ],
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/offline-order-comments-customization',
                    component: 'OfflineOrderCommentsCustomizationView',
                    breadcrumbName: '<<offlineOrderCommentsCustomization>>',
                    privilege: {
                        path: 'posReceiptOrderCustomComment',
                        level: 'full',
                    },
                    childRoutes: [
                        { path: '/', component: 'OfflineOrderCommentsCustomizationPage' },
                    ],
                },
            ],
        },
    ],
};
