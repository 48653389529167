import React, { Component, createElement } from 'react';
import { remove, forEach, map, find } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Modal, Radio, Icon } from 'antd';
import { SelectMemberValue, Member, SendMemberMode, SendMemberFile } from './config-select-member';
import { UploadFile } from './upload-file';
import './index.less';

const TableComponentId = 'SubsiteSelectorTable';
let Entity: any = {};
let entity: any = {};
const RadioGroup = Radio.Group;

/**
 * 选择会员
 */
export class MemberSelector extends Component<{
    value: SelectMemberValue;
    onChange: (value: SelectMemberValue) => void;
    params?: any;
    disabled: boolean;
}> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('MessageTagEnity');
        entity = new Entity({});
    }
    dispatch: any = {};
    state = { visible: false, uploadVisible: false };

    selectedRows = new Array<Member>();

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(entity));
    }

    onTableRowSelect = (record: any, selected: boolean) => {
        if (selected) {
            if (find(this.selectedRows, { id: record.id })) {
                return;
            }
            this.selectedRows.push(record);
        } else {
            remove(this.selectedRows, (i: any) => {
                return i.id === record.id;
            });
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: Member[]) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            this.selectedRows = [];
        }
    };

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
    };

    onOk = () => {
        const { onChange, value } = this.props;
        if (onChange) {
            forEach(this.selectedRows, (subsite) => {
                subsite.select = true;
            });
            const newValue: any = value ? value : { mode: SendMemberMode.ALL };

            newValue.list = this.selectedRows;
            onChange(newValue);
            this.toggleModal();
        }
    };

    getConfig = () => {
        const { params, value } = this.props;

        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: params || {},
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'right' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    fields: [
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'cardLevelName',
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    rowSelection: {
                        selectedRowKeys: map(value ? value.list : [], 'id'),
                        onSelect: (record: Member, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: Member[]) => {
                            this.onTableRowSelectAll(selected, rows);
                        },
                        onChange: () => {},
                    },
                    rowKey: 'id',
                    loadFirstPage: true,
                    columns: [
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'cardLevelName',
                        },
                        {
                            property: 'status',
                        },
                    ],
                },
            ],
        };
    };

    toggleModal = () => {
        const { visible } = this.state;
        const { params } = this.props;

        this.setState({ visible: !visible }, () => {
            if (!visible && entity.paging) {
                entity.search({
                    ...entity.paging,
                    ...params,
                });
                this.onClearAll();
            }
        });
    };

    toggleUploadFile = () => {
        const { uploadVisible } = this.state;
        this.setState({ uploadVisible: !uploadVisible });
    };

    onModeChange = (e: any) => {
        const { onChange } = this.props;
        const newValue: SelectMemberValue = {
            mode: e.target.value,
            list: [],
            file: null,
        };
        onChange(newValue);
    };
    onFileChange = (file: SendMemberFile) => {
        const { onChange, value } = this.props;
        value.file = file;
        this.toggleUploadFile();
        onChange(value);
    };
    onDeleteFile = () => {
        const { onChange, value } = this.props;
        value.file = null;
        onChange(value);
    };

    render() {
        const { value, disabled } = this.props;
        const { visible, uploadVisible } = this.state;
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 900,
            title: '选择会员',
            visible: visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };
        const uploadFileProps = {
            visible: uploadVisible,
            onClose: this.toggleUploadFile,
            ...this.props,
        };
        const modeValue = value && value.mode ? value.mode : SendMemberMode.ALL;

        return (
            <div>
                <RadioGroup onChange={this.onModeChange} value={modeValue} disabled={disabled}>
                    <Radio value={SendMemberMode.ALL}>全部会员</Radio>
                    <Radio value={SendMemberMode.LEVEL}>指定会员卡等级</Radio>
                    <Radio value={SendMemberMode.MOBILE}>指定会员</Radio>
                </RadioGroup>
                {!disabled && value && value.mode === SendMemberMode.LEVEL && (
                    <div>
                        <Button type="link" onClick={this.toggleModal}>
                            添加会员卡等级
                        </Button>
                    </div>
                )}
                {value && value.mode === SendMemberMode.MOBILE && (
                    <div>
                        {value.file && value.file.fileName ? (
                            <div style={{ display: 'inline' }}>
                                {value.file.fileName}
                                {!disabled && (
                                    <Icon
                                        style={{ marginLeft: '10px' }}
                                        type="delete"
                                        onClick={this.onDeleteFile}
                                    />
                                )}
                            </div>
                        ) : (
                            <Button type="link" onClick={this.toggleUploadFile}>
                                批量导入会员
                            </Button>
                        )}
                    </div>
                )}
                <Modal {...modalProps}>{modalContent}</Modal>
                <UploadFile {...uploadFileProps} onChange={this.onFileChange} />
            </div>
        );
    }
}
