import { Config } from '@comall-backend-builder/core/lib/parser';
import { api, errorHandle } from '@comall-backend-builder/core/lib/services';
import { EntityButtonProps } from '../../../components';
import isEmpty from 'lodash/isEmpty';
import { services } from '@comall-backend-builder/core';

export enum OperationResults {
    /**
     * 成功
     */
    SUCCESS = 'SUCCESS',
    /**
     * 失败
     */
    FAIL = 'FAIL',
    /**
     * 部分成功
     */
    SOME_SUCCESS = 'SOME_SUCCESS',
}

export const OPERATION_RESULTS_OPYIONS = [
    {
        id: OperationResults.SUCCESS,
        name: '<<success>>',
    },
    {
        id: OperationResults.FAIL,
        name: '<<sb_2>>',
    },
    {
        id: OperationResults.SOME_SUCCESS,
        name: '<<bfcg>>',
    },
];

export const config: Config = {
    entities: {
        SignOrdertEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OPERATION-TOOL`,
            apiPath: '/loader/admin/draw_lots_appoint',
            filters: {
                activityName: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<sxmd_1>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
            },
            properties: {
                id: {
                    type: 'number',
                },
                activityName: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                activeTime: {
                    type: 'string',
                    displayName: '<<acitivityTime>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<activityMerchant>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
        },
        SignOrdertMessageEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OPERATION-TOOL`,
            apiPath: '/loader/admin/sign_order',
            filters: {
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                subsiteId: {
                    type: 'string.options.select',
                    displayName: '<<ddmd>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                phone: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<ddmd>>',
                },
                orderTime: {
                    type: 'string',
                    displayName: '<<xdrq>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                importTime: {
                    type: 'string',
                    displayName: '<<drsj_1>>',
                },
            },
        },
        SignOrdertMessageDownloadEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OPERATION-TOOL`,
            apiPath: '/loader/admin/sign_order_import',
            filters: {
                createName: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<czsj>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                creatorName: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<czsj>>',
                },
                optResult: {
                    type: 'string.options.select',
                    displayName: '<<czjg>>',
                    options: OPERATION_RESULTS_OPYIONS,
                },
                resultDetail: {
                    type: 'string',
                    displayName: '<<importResult>>',
                },
            },
        },
    },
    components: {
        SignOrdertView: {
            component: 'Viewport',
        },
        SignOrdertPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'SignOrdertEntity',
            items: [
                {
                    component: 'SignOrdertFilter',
                },
                {
                    component: 'SignOrdertTable',
                },
            ],
        },
        SignOrdertFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'activityName',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsryhqlbmc>>',
                    },
                },
                {
                    property: 'subsiteId',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        SignOrdertTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'activityName',
                    width: 180,
                },
                {
                    property: 'activeTime',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            minWidth: '120px',
                        },
                    },
                },
                {
                    property: 'subsites',
                    width: 200,
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '250px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                items: [
                    {
                        text: '<<storedValueCardOrder>>',
                        type: 'link',
                        path: '/sign-order/orderManagement/{{row.id}}',
                    },
                ],
            },
        },
        SignOrdertManagementPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'Tabs',
                    type: 'card',
                    cache: false,
                    animated: false,
                    items: [
                        {
                            title: '<<ddxx>>',
                            content: {
                                component: 'SignOrdertMessageForm',
                            },
                        },
                        {
                            title: '<<importRecord>>',
                            content: {
                                component: 'SignOrdertDownload',
                            },
                        },
                    ],
                },
            ],
        },
        SignOrdertMessageForm: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'SignOrdertMessageEntity',
            items: [
                {
                    component: 'SignOrdertMessageFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    style: {
                        paddingTop: 10,
                        paddingBottom: 10,
                    },
                    items: [
                        {
                            component: 'GridLayout',
                            style: {
                                display: 'flex',
                            },
                            items: [
                                {
                                    component: 'ImportTable',
                                    props: {
                                        triggerType: 'primary',
                                        triggerText: '<<drddxx>>',
                                        triggerIcon: 'plus',
                                        controlDownloadTemplate: {
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OPERATION-TOOL`,
                                            apiPath: `/admin/sign_order/template.xlsx`,
                                        },
                                        controlHandles: [
                                            {
                                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OPERATION-TOOL`,
                                                apiPath: '/admin/sign_order/:id/upload',
                                                params: {
                                                    importType: 'ADD',
                                                },
                                                type: 'primary',
                                                text: '<<xzdr>>',
                                            },
                                        ],
                                    },
                                    span: 4,
                                },
                                {
                                    component: 'EntityButton',
                                    props: {
                                        type: 'primary',
                                        children: '<<exportSearchResult>>',
                                        style: {
                                            width: 110,
                                            marginBottom: 10,
                                            marginLeft: 10,
                                        },
                                        onClick: (_: any, entityButtonProps: EntityButtonProps) => {
                                            const params: any = Object.assign(
                                                {},
                                                entityButtonProps.entity.params,
                                                entityButtonProps.entity.filters
                                            );

                                            if (!isEmpty(params.subsites)) {
                                                params.subsiteId = params.subsites;
                                                delete params.subsites;
                                            }

                                            const config = {
                                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OPERATION-TOOL`,
                                                apiPath: '/admin/sign_order/:id/export_query',
                                            };
                                            api.download(params, config).catch(errorHandle);
                                        },
                                    },
                                    span: 4,
                                },
                                {
                                    component: 'ExportCondition',
                                    params: {
                                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OPERATION-TOOL`,
                                        apiPath: '/admin/sign_order/:id/export_order',
                                    },
                                    span: 4,
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'SignOrdertMessageTable',
                },
            ],
        },
        SignOrdertMessageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'mobile',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsrsjh>>',
                    },
                },
                {
                    property: 'subsiteId',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        SignOrdertMessageTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'phone',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'subsiteName',
                    width: 200,
                },
                {
                    property: 'orderTime',
                },
                {
                    property: 'orderNo',
                },
                {
                    property: 'importTime',
                },
            ],
        },
        SignOrdertDownload: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'SignOrdertMessageDownloadEntity',
            items: [
                {
                    component: 'SignOrdertDownloadFilter',
                },
                {
                    component: 'SignOrdertDownloadTable',
                },
            ],
        },
        SignOrdertDownloadFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'createName',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsrczr>>',
                    },
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        SignOrdertDownloadTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'creatorName',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'createTime',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'optResult',
                },
                {
                    property: 'resultDetail',
                    width: 200,
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                component: 'FlexLayout',
                align: 'around',
                items: [
                    {
                        type: 'component',
                        component: 'ExportDataButtonFail',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OPERATION-TOOL`,
                            apiPath: '/admin/sign_order_import/{{row.id}}/export_fail',
                            text: '<<dcsbqd>>',
                        },
                    },
                    {
                        type: 'component',
                        component: 'ExportDataButton',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OPERATION-TOOL`,
                            apiPath: '/admin/sign_order_import/{{row.id}}/export_all',
                            text: '<<qbdc>>',
                        },
                        style: {
                            float: 'right',
                            width: 110,
                            marginBottom: 10,
                            marginLeft: 10,
                        },
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/sign-order',
                    component: 'SignOrdertView',
                    breadcrumbName: '<<signOrder>>',
                    privilege: {
                        path: 'drawLotsOrderSign',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/orderManagement/:id',
                            component: 'SignOrdertManagementPage',
                            breadcrumbName: '<<storedValueCardOrder>>',
                        },
                        {
                            path: '/',
                            component: 'SignOrdertPage',
                        },
                    ],
                },
            ],
        },
    ],
};
