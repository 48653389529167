import { Modal } from 'antd';
import React, { MouseEventHandler, ReactNode, useCallback } from 'react';

export interface WechatUrlOptions {
    url: string;
    text: string;
}
interface Props {
    onChange(message: string): void;
    onClick?(option: WechatUrlOptions): void;
    onDelete?(): void;
    decoratedText: string;
    children?: ReactNode;
}
const HREF_REGEX = /(?<=href=")(.*?)(?=")/;
// const APP_ID_REGEX = /(?<=data-miniprogram-appid=")(.*?)(?=")/;
const TEXT_REGEX = /(?<=<a.*>)(.*?)(?=<\/a>)/;
export default function WechatUrlMessage(props: Props) {
    const { onClick, onDelete, decoratedText, children } = props;
    const url = decoratedText.match(HREF_REGEX)?.[0] || '';
    const text = decoratedText.match(TEXT_REGEX)?.[0] || '';
    const onUrlClick = () => {
        onClick?.({
            text,
            url,
        });
    };
    const onDeleteLink = useCallback<MouseEventHandler<HTMLSpanElement>>(
        (e) => {
            e.stopPropagation();
            Modal.confirm({
                content: '确定要删除该链接吗？',
                iconType: 'warning',
                okType: 'danger',
                onOk() {
                    onDelete?.();
                },
            });
        },
        [onDelete]
    );
    return (
        <span>
            <span
                style={{
                    padding: '0 8px',
                    color: '#1890ff',
                    border: '1px solid #1890ff',
                    cursor: 'pointer',
                    borderRadius: '4px',
                }}
                onClick={onUrlClick}
                contentEditable={false}
            >
                {text}
                <span onClick={onDeleteLink} style={{ marginLeft: '4px', fontSize: '10px' }}>
                    X
                </span>
            </span>
            <span hidden>{children}</span>
        </span>
    );
}
