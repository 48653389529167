import React, { FunctionComponent, forwardRef, useCallback, useMemo, useState } from 'react';
import { default as AntdTransfer, TransferProps as AntdTransferProps } from 'antd/lib/transfer';
import { map } from 'lodash';
import { ArrayOptionIdsStransferMode } from '@comall-backend-builder/types';
import { TransferProps } from '@comall-backend-builder/components-basis/lib/transfer';

export class CustomTransferMode extends ArrayOptionIdsStransferMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: TransferProps) {
        return <Transfer {...controlInfo} />;
    }
}

const Transfer: FunctionComponent<TransferProps> = forwardRef<AntdTransfer, TransferProps>(
    (props, ref) => {
        const {
            className,
            listStyle,
            operations,
            showSearch,
            locale,
            titles,
            renderContent,
            filterOption: costiomFilter,
            onChange,
            options,
            name,
            value,
        } = props;

        const [selectedKeys, setSelectedKeys] = useState<string[]>([]);

        const render: AntdTransferProps['render'] = useCallback(
            (option) => (renderContent ? renderContent(option) : option.title),
            [renderContent]
        );

        const filterOption: AntdTransferProps['filterOption'] = useCallback(
            (inputValue, item) =>
                costiomFilter
                    ? costiomFilter(inputValue, item)
                    : item.title.indexOf(inputValue) > -1,
            [costiomFilter]
        );

        const dataSource = useMemo(
            () => map(options, ({ id, name }) => ({ key: id, title: name })),
            [options]
        );

        const handleChange: AntdTransferProps['onChange'] = useCallback(
            (targetKeys) => {
                onChange && onChange(targetKeys, name);
            },
            [name, onChange]
        );

        const handleSelectChange: AntdTransferProps['onSelectChange'] = useCallback(
            (sourceSelectedKeys, targetSelectedKeys) => {
                setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
            },
            []
        );

        let Props = {
            ref,
            className,
            listStyle,
            operations,
            locale,
            titles,
            showSearch,
            render,
            targetKeys: value,
            dataSource,
            selectedKeys,
            filterOption,
            onChange: handleChange,
            onSelectChange: handleSelectChange,
        };

        return <AntdTransfer {...Props}></AntdTransfer>;
    }
);
