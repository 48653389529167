import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { ProductSelectedDisplayForSelfDelivery } from '../../../components';
import { SelfDeliverySelectProduct } from '../../../containers';

/**
 * 预售选择商品
 */
export class SelfDeliveryProductSelectorMode extends modes.ArrayMode {
    getDisplayComponent(value: any) {
        return (
            <ProductSelectedDisplayForSelfDelivery
                data={value}
                modifiable={false}
                onChange={() => {}}
            ></ProductSelectedDisplayForSelfDelivery>
        );
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <SelfDeliverySelectProduct {...displayInfo} />;
    }
}
