import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { api } from '@comall-backend-builder/core/lib/services';

export const TouchlessParkingAssetAuthorizationLoader: Loader = {
    get(params, config) {
        if (params.modifyTime) {
            params.modifyStartTime = params.modifyTime.start;
            params.modifyEndTime = params.modifyTime.end;
            delete params.modifyTime;
        }
        return api.get(params, config);
    },
};
