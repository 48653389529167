import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { ProductSelectedDisplayForPresale } from '../../../components';
import { PresaleSelectProduct } from '../../../containers';

/**
 * 预售选择商品
 */
export class PresaleProductSelectorMode extends modes.ArrayMode {
    getDisplayComponent(value: any, displayInfo: any): JSX.Element {
        const {
            showQuantityLimit,
            showPresalePrice,
            showBlindBoxSaleCount,
            showBlindBoxWinningRate,
            showFilter,
        } = displayInfo;
        return (
            <ProductSelectedDisplayForPresale
                showQuantityLimit={showQuantityLimit}
                showPresalePrice={showPresalePrice}
                showBlindBoxSaleCount={showBlindBoxSaleCount}
                showBlindBoxWinningRate={showBlindBoxWinningRate}
                data={value}
                modifiable={false}
                onChange={() => {}}
                showFilter={showFilter}
            ></ProductSelectedDisplayForPresale>
        );
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <PresaleSelectProduct {...displayInfo} />;
    }
}
