import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { PictureExampleButton } from '../../../components';
export class PictureExampleButtonMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    getDisplayComponent(value: any, displayInfo: any) {
        return <PictureExampleButton {...displayInfo} />;
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <PictureExampleButton {...displayInfo} />;
    }
}
