import React, { PureComponent } from 'react';
import { Row, Col, Input } from 'antd';
import get from 'lodash/get';
import { services } from '@comall-backend-builder/core';
import { tools } from '@comall-backend-builder/design-components-basis';
import { PresaleMoreConfig } from './index';
import './index.less';

interface PresaleMoreProps {
    value: PresaleMoreConfig;
    selector: any;
    onChange: (args: any) => void;
    uploadAction: string;
    sourceRoot: { image?: string };
    linkTypes: Array<any>;
}

const language = services.language;
const UploadPicture = tools.UploadPicture;
const LinkType = tools.LinkType;
const ColorPicker = tools.ColorPicker;

export default class PresaleMore extends PureComponent<PresaleMoreProps, any> {
    onUpload = (path: string) => {
        this.changeImage(path);
    };

    getValue = () => {
        const { value } = this.props;

        return {
            image: get(value, 'image', null),
            title: get(value, 'title', '更多'),
            color: get(value, 'color', '#999999'),
            linkParams: get(value, 'linkParams', null),
            linkType: get(value, 'linkType', 'none'),
        };
    };

    changeImage = (val: string) => {
        const { onChange } = this.props;
        let newValue = this.getValue();
        newValue.image = val;
        onChange({ ...newValue });
    };

    changeTitle = (val: string) => {
        const { onChange } = this.props;
        let newValue = this.getValue();
        newValue.title = val;
        onChange({ ...newValue });
    };

    changeColor = (val: string) => {
        const { onChange } = this.props;
        let newValue = this.getValue();
        newValue.color = val;
        onChange({ ...newValue });
    };

    changeLinkTypes = (val: { linkParams: string; linkType: string }) => {
        const { onChange } = this.props;
        let newValue = this.getValue();
        newValue.linkParams = val.linkParams;
        newValue.linkType = val.linkType;
        onChange({ ...newValue });
    };

    render() {
        const { value, selector, uploadAction, linkTypes, sourceRoot } = this.props;
        return (
            <Row className="entry-item">
                <Col className="entry-item-image" span={5}>
                    <UploadPicture
                        sourceRoot={sourceRoot}
                        value={get(value, 'image', '')}
                        uploadAction={uploadAction}
                        onChange={this.onUpload}
                    />
                </Col>
                <Col span={18}>
                    <label className="field-name">
                        {language.getText('components.Presale.moreTitle')}
                    </label>
                    <Input
                        value={get(value, 'title', '更多')}
                        className="link-input"
                        onChange={(e) => this.changeTitle(e.target.value)}
                    />
                </Col>
                <Col span={18} offset={5}>
                    <ColorPicker
                        onChange={this.changeColor}
                        value={get(value, 'color', '#999999')}
                    />
                </Col>
                <Col span={18} offset={5}>
                    <LinkType
                        linkTypes={linkTypes}
                        linkTypeMode={'cascader'}
                        selector={selector}
                        value={{
                            linkParams: get(value, 'linkParams', null),
                            linkType: get(value, 'linkType', 'none'),
                        }}
                        onChange={(value) => this.changeLinkTypes(value)}
                    />
                </Col>
            </Row>
        );
    }
}
