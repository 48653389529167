import React, { PureComponent, createElement } from 'react';
import { ComponentsManager } from '@comall-backend-builder/core';
import { Button } from 'antd';
export class RoleStatusButton extends PureComponent<any, any> {
    render() {
        const { row } = this.props;

        let buttonProps = {
            ...this.props,
            statusKey: 'status',
            renderComponent: 'Button',
            type: 'link',
            style: { display: 'inline-flex' },
            handles: [
                {
                    params: { status: 'INACTIVE' },
                    value: 'ACTIVE',
                    apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
                    apiPath: '/roles/:id/status',
                    config: {
                        content: '角色{{row.name}}已被禁用',
                        text: '禁用',
                        style: { color: 'red' },
                    },
                    confirm: {
                        text: '确认禁用角色{{row.name}}?',
                        className: 'confirm-content',
                    },
                },
                {
                    params: { status: 'ACTIVE' },
                    value: 'INACTIVE',
                    apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
                    apiPath: '/roles/:id/status',
                    config: {
                        content: '角色{{row.name}}已被启用',
                        text: '启用',
                    },
                    confirm: {
                        text: '是否启用角色{{row.name}}?',
                    },
                },
            ],
        };
        const textObj: any = {
            ACTIVE: '禁用',
            INACTIVE: '启用',
        };
        return row.disable ? (
            createElement(ComponentsManager.get('ChangeStatusButton'), buttonProps)
        ) : (
            <Button type="link" style={{ paddingLeft: 0 }} disabled>
                {textObj[row.status]}
            </Button>
        );
    }
}
