import React, { useCallback, useEffect, useState } from 'react';
import { Button, Icon, message, Modal, Spin } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { RcFile } from 'antd/lib/upload';
import Dragger from 'antd/lib/upload/Dragger';
import classNames from 'classnames';
import { isFunction, isObject, isString, omit } from 'lodash';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { ModalProps } from 'antd/lib/modal';
import { errorHandle, interpolate } from '@comall-backend-builder/core/lib/services';
import { ForwardDataCenterModal } from '../../services';

/**
 * 后台目前导入功能组件ImportTable是先上传文件走upload接口，然后点击确定将上传后的id传给业务接口完成交互。
 * 后续后端会慢慢调整为上传一个文件，不走upload，点击确定将文件传到业务接口，
 * 所以后台目前新增了NewImportTable组件，方便大家以后直接替换。
 */

export type NewPropsWithReturnValues<P = {}> = {
    [K in keyof P]: P[K] | ((config: NewBackendBuilderConfig<P>) => P[K]);
};

export interface NewBackendBuilderConfig<P = {}> {
    row: any;
    entity: Entity;
    entities?: Entity[];
    propsBeforeHandler?(
        config: NewBackendBuilderConfig<P>,
        props: NewPropsWithReturnValues<P>
    ): NewPropsWithReturnValues<P>;
    props: NewPropsWithReturnValues<P>;
    propsAfterHandler?(config: NewBackendBuilderConfig<P>, props: P): P;
}

/**
 * 将配置中的动态 props 转换为组件可用 props
 *
 * @param config 配置
 * @param propKeys  需要转换属性值对应的字段名列表（属性值原本就支持函数的无需转换）
 */
export function newBackendBuilderConfigTransformToProps<P = {}>(
    config: NewBackendBuilderConfig<P>,
    propKeys: (keyof P)[]
): P {
    let propsWithReturnValues = config.props;
    if (isFunction(config.propsBeforeHandler)) {
        propsWithReturnValues = config.propsBeforeHandler(config, propsWithReturnValues);
    }

    function transformValue(propsWithReturnValues: NewPropsWithReturnValues<P>, key: keyof P) {
        if (propsWithReturnValues.hasOwnProperty(key)) {
            const propOrReturnValueOrEvent = propsWithReturnValues[key];
            let value;
            if (isFunction(propOrReturnValueOrEvent)) {
                if (/^on[A-Z]/.test(key as string)) {
                    value = (...args: unknown[]) => {
                        propOrReturnValueOrEvent(...args.concat(config, props));
                    };
                } else {
                    value = propOrReturnValueOrEvent(config);
                }
            } else {
                value = propOrReturnValueOrEvent;
            }
            return {
                ...propsWithReturnValues,
                [key]: value,
            };
        }
        return propsWithReturnValues;
    }

    let props = propKeys.reduce(transformValue, propsWithReturnValues) as P;

    if (isFunction(config.propsAfterHandler)) {
        props = config.propsAfterHandler(config, props);
    }

    return props;
}

export interface NewImportTableControlHandle extends Omit<ButtonProps, 'onClick'> {
    /**
     * 根路径
     */
    apiRoot: string;
    /**
     * 地址
     */
    apiPath: string;
    /**
     * 额外参数
     */
    params?: Record<string, any>;
    /**
     * 按钮文字
     */
    text: string;
    /**
     * 请求参数处理程序
     */
    paramsHandler?(
        importTableConfig: NewBackendBuilderConfig<NewImportTableProps>,
        fileInfo: ImportTableFileInfo
    ): Record<string, any>;
}

export interface NewImportTableProps {
    /**
     * 按钮样式
     */
    triggerStyle?: ButtonProps['style'];
    /**
     * 按钮类名
     */
    triggerClassName?: ButtonProps['className'];
    /**
     * 按钮类型
     */
    triggerType?: ButtonProps['type'];
    /**
     * 按钮图标
     */
    triggerIcon?: ButtonProps['icon'];
    /**
     * 按钮文字
     */
    triggerText?: ButtonProps['children'];
    /**
     * 禁用按钮
     */
    triggerDisabled?: ButtonProps['disabled'];
    /**
     * 完整 ButtonProps 参数，外层的几个 trigger 参数不够用时可以使用此配置
     */
    triggerProps?: Omit<ButtonProps, 'onClick'>;
    /**
     * 弹窗标题
     */
    controlTitle?: string;
    /**
     * 支持的文件后缀
     */
    controlUploadAccept?: string;
    /**
     * 导入规则
     */
    controlUploadRules?: (string | { text: string; color: 'red' | '' })[];
    /**
     * 示例图片
     */
    controlDisplayExample?: string;
    /**
     * 示例模板
     */
    controlDownloadTemplate?:
        | string
        | {
              apiRoot: string;
              apiPath: string;
          };
    specialTips?: Array<string>;
    /**
     * 导入方法组
     */
    controlHandles?: NewImportTableControlHandle[];
    /**
     * 完整的 ModalProps 参数，可控制 Modal 的展示效果
     */
    controlProps?: Omit<ModalProps, 'visible' | 'footer' | 'onCancel'>;
}

interface ImportTableFileInfo {
    /**
     * 原始文件列表
     */
    fileList: [RcFile];
}

function useVisible(dafalueState = false) {
    const [state, change] = useState(dafalueState);

    const toggle = useCallback(() => change((s) => !s), []);
    const show = useCallback(() => change(true), []);
    const hide = useCallback(() => change(false), []);

    return {
        state,
        toggle,
        show,
        hide,
    };
}

export const NewImportTable: React.FC<NewBackendBuilderConfig<NewImportTableProps>> = (config) => {
    const props = newBackendBuilderConfigTransformToProps(config, [
        'triggerStyle',
        'triggerClassName',
        'triggerType',
        'triggerIcon',
        'triggerText',
        'triggerDisabled',
        'triggerProps',
        'controlTitle',
        'controlUploadAccept',
        'controlUploadRules',
        'controlDisplayExample',
        'controlDownloadTemplate',
        'specialTips',
        'controlHandles',
        'controlProps',
    ]);

    const {
        controlUploadAccept = '.xlsx,.xls,.csv',
        controlUploadRules = [
            services.language.getText('merchantBigCodeIntro1'),
            {
                text: services.language.getText('merchantBigCodeNote'),
                color: 'red',
            },
            services.interpolate(services.language.getText('controlUploadAccept'), {
                controlUploadAccept,
            }),
            services.language.getText('merchantBigCodeIntro3'),
        ],
        controlHandles = [],
        triggerProps = {},
        controlProps = {},
    } = props;

    const controlVisible = useVisible();
    const uploadLoading = useVisible();
    const importLoading = useVisible();
    const [fileInfo, setFileInfo] = useState<ImportTableFileInfo>();

    useEffect(() => {
        if (!controlVisible.state) {
            uploadLoading.hide();
            importLoading.hide();
            setFileInfo(undefined);
        }
    }, [controlVisible.state, importLoading, uploadLoading]);

    function handleBeforeUpload(file: RcFile): boolean {
        setFileInfo({
            fileList: [file],
        });
        return false;
    }

    async function handleImportRequest(controlHandle: NewImportTableControlHandle): Promise<void> {
        if (importLoading.state) return;
        if (!fileInfo) {
            message.error(services.language.getText('pleaseUploadFile'));
            return;
        }

        try {
            importLoading.show();
            const formData = new FormData();
            formData.append('files', fileInfo.fileList[0]);
            services.api
                .upload(
                    { files: formData },
                    {
                        apiRoot: controlHandle.apiRoot,
                        apiPath: interpolate(controlHandle.apiPath, config),
                        fileName: 'files',
                        progressCallBack: () => {},
                        useCustomData: true,
                    }
                )
                .then(() => {
                    controlVisible.hide();
                    //弹层提示
                    ForwardDataCenterModal();
                })
                .catch((err) => {
                    if (err) {
                        message.error(
                            err.response?.body?.err_msg || services.language.getText('uoloadFail')
                        );
                    }
                })
                .finally(() => {
                    uploadLoading.hide();
                });
        } catch (e) {
            let errString = e.response.body.message;
            let errMessage = errString.split(':');
            errMessage.length > 1 ? message.error(errMessage[1]) : message.error(errMessage[0]);
        } finally {
            importLoading.hide();
        }
    }

    const controlUploadEle = (
        <Dragger
            accept={controlUploadAccept}
            fileList={fileInfo?.fileList}
            beforeUpload={handleBeforeUpload}
            onRemove={() => setFileInfo(undefined)}
        >
            <p className="ant-upload-drag-icon">
                <Icon type="inbox" />
            </p>
            <p className="ant-upload-text">{services.language.getText('selectFile')}</p>
            <p className="ant-upload-hint">
                {services.language.getText('supportExtension')}
                {controlUploadAccept}
            </p>
        </Dragger>
    );

    const controlDownloadTemplateEle =
        props.controlDownloadTemplate &&
        (isObject(props.controlDownloadTemplate) ? (
            <Button
                type="link"
                onClick={() => {
                    services.api
                        .download({}, props.controlDownloadTemplate as any)
                        .catch(errorHandle);
                }}
            >
                {services.language.getText('djxzpldrmb')}
            </Button>
        ) : (
            <Button type="link" download href={props.controlDownloadTemplate}>
                {services.language.getText('djxzpldrmb')}
            </Button>
        ));

    const controlTipsEle = (
        <div className="giving-coupon-schedules-tips" style={{ paddingTop: '10px' }}>
            {controlDownloadTemplateEle}
            <p className="giving-coupon-schedules-tips-text">
                {services.language.getText('drgz')}:
            </p>
            {controlUploadRules.map((uploadRule) => {
                if (isString(uploadRule)) {
                    return (
                        <p className="giving-coupon-schedules-tips-text" key={uploadRule}>
                            {uploadRule}
                        </p>
                    );
                }
                return (
                    <p
                        className={classNames('giving-coupon-schedules-tips-text', {
                            [`giving-coupon-schedules-tips-text-${uploadRule.color}`]: uploadRule.color,
                        })}
                        key={uploadRule.text}
                    >
                        {uploadRule.text}
                    </p>
                );
            })}
            {props.specialTips &&
                props.specialTips.map((tip: string) => {
                    return (
                        <p className="giving-coupon-schedules-tips-text" key={tip}>
                            {tip}
                        </p>
                    );
                })}
            {props.controlDisplayExample && (
                <>
                    <p className="giving-coupon-schedules-tips-text-controlDownloadTemplate">
                        {services.language.getText('wjysmb')}：
                    </p>
                    <img
                        className="giving-coupon-schedules-img"
                        src={props.controlDisplayExample}
                        alt={services.language.getText('sltp')}
                    />
                </>
            )}
        </div>
    );

    const controlFooterEle = (
        <>
            {controlHandles.map((controlHandle) => (
                <Button
                    {...omit(controlHandle, 'apiRoot', 'apiPath', 'params')}
                    key={controlHandle.text}
                    onClick={() => handleImportRequest(controlHandle)}
                >
                    {controlHandle.text}
                </Button>
            ))}
            <Button onClick={controlVisible.toggle}>
                {services.language.getText('common.goBack')}
            </Button>
        </>
    );

    const triggerEle = (
        <Button
            {...triggerProps}
            style={props.triggerStyle || triggerProps.style}
            className={props.triggerClassName || triggerProps.className}
            type={props.triggerType || triggerProps.type}
            icon={props.triggerIcon || triggerProps.icon}
            disabled={props.triggerDisabled || triggerProps.disabled}
            onClick={controlVisible.show}
        >
            {props.triggerText}
        </Button>
    );

    const controlTitle =
        props.controlTitle || controlProps.title || services.language.getText('batchImport');

    const controlEle = (
        <Modal
            {...controlProps}
            title={controlTitle}
            footer={controlFooterEle}
            visible={controlVisible.state}
            onCancel={controlVisible.hide}
        >
            <Spin spinning={uploadLoading.state} tip={services.language.getText('scwwczq')}>
                <Spin
                    spinning={importLoading.state}
                    tip={services.language.getText('importBeforeNotPage')}
                >
                    {controlUploadEle}
                    {controlTipsEle}
                </Spin>
            </Spin>
        </Modal>
    );

    return (
        <div>
            {triggerEle}
            {controlEle}
        </div>
    );
};
