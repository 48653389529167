import React, { PureComponent } from 'react';
import { cloneDeep, get, forEach, find, uniqBy } from 'lodash';
import { SelectMerchant } from './select-merchant';
import { message } from 'antd';
import { ShopSelectedDisplay } from '../../../../components';
import { Shop } from '../../../../containers/select-shop';
import { services } from '@comall-backend-builder/core';

interface MerchantSelecterTickProps {
    onChange: (value: Shop[] | undefined, name?: string) => void;
    value: Shop[] | undefined;
    row: any;
    name: string;
    disabled: boolean;
    tips?: string;
    subsiteField: string;
    needDependencesMessage?: string;
    needDependencesText?: string;
    customFields?: {
        property: string;
    }[];
    customColumns?: {
        property: string;
    }[];
    customParams?: {
        [key: string]: any;
    };
    hideSubsiteColume?: boolean;
}

interface MerchantSelecterStates {
    visible: boolean;
    //导入专柜弹窗是否展示
    importModalVisible: boolean;
    value: Shop[] | undefined;
}

/**
 * 选择专柜组件，表单上必须有单选门店组件
 */
export class MerchantSelecterTick extends PureComponent<
    MerchantSelecterTickProps,
    MerchantSelecterStates
> {
    constructor(props: MerchantSelecterTickProps) {
        super(props);
        this.state = { visible: false, importModalVisible: false, value: [] };
    }
    componentWillReceiveProps(nextProps: MerchantSelecterTickProps) {
        const { subsiteField = '' } = this.props;
        const nextSubsites = get(nextProps.row, subsiteField);
        const preSubsites = get(this.props.row, subsiteField);
        this.handleSubsitesChange(nextSubsites, preSubsites);
    }

    handleSubsitesChange = (nextSubsites: any, preSubsites: any) => {
        const { onChange } = this.props;
        if (!preSubsites) return;
        if (nextSubsites !== preSubsites) {
            onChange([]);
            return;
        }
    };

    /**
     * 选择专柜
     * @param selectValues
     */
    onChange = (selectValues: Shop[]) => {
        const { onChange } = this.props;
        if (onChange) {
            if (selectValues && selectValues.length === 0) {
                onChange([]);
            } else {
                onChange(selectValues);
            }
        }
    };

    /**
     * 添加分站弹窗确认后回调
     * @param selectValues 选中分站
     */
    onSelect = (selectValues: Shop[], isImprt?: boolean) => {
        const { name, onChange } = this.props;
        let newData: Shop[] = [];
        if (!selectValues?.length) {
            newData = [];
        } else {
            newData = uniqBy(selectValues, 'merchantId');
        }
        if (onChange) {
            onChange(newData, name);
        }
    };

    /**
     * 改变已选择分站选中状态
     * @param selectValues
     */
    onChangeSelect = (selectValues: Shop[]) => {
        const { onChange, value } = this.props;
        if (!value) return;
        const newData: Shop[] = cloneDeep(value);
        forEach(newData, (shop) => {
            const selectShop = find(selectValues, (selectShop) => {
                return selectShop.merchantId === shop.merchantId;
            });

            if (selectShop) {
                shop.select = true;
            } else {
                shop.select = false;
            }
        });
        if (onChange) {
            if (newData && newData.length === 0) {
                onChange(undefined);
            } else {
                onChange(newData);
            }
        }
    };

    toggleModal = () => {
        const { visible } = this.state;
        if (!visible) {
            const { row, subsiteField, needDependencesMessage } = this.props;
            const subsites = get(row, subsiteField);
            if (!subsites || subsites.length === 0) {
                message.warn(
                    needDependencesMessage || services.language.getText('selectBeforeMerchant')
                );
                return;
            }
        }
        this.setState({ visible: !visible });
    };

    toggleImportModal = () => {
        const { importModalVisible } = this.state;
        if (!importModalVisible) {
            const { row, subsiteField, needDependencesMessage } = this.props;
            const subsites = get(row, subsiteField);
            if (!subsites || subsites.length === 0) {
                message.warn(
                    needDependencesMessage || services.language.getText('selectBeforeMerchant')
                );
                return;
            }
        }
        this.setState({ importModalVisible: !importModalVisible });
    };

    render() {
        const {
            value,
            disabled,
            row,
            tips,
            subsiteField,
            customFields,
            customColumns,
            customParams,
            hideSubsiteColume = true,
            needDependencesText,
        } = this.props;
        const subsites = get(row, subsiteField);
        const params = { ...customParams, subSiteName: subsites ? subsites : undefined };
        const hasValue = value && value.length > 0;

        let selectDisabled = (!!needDependencesText && !subsites) || disabled;
        let btnText =
            !!needDependencesText && !subsites
                ? needDependencesText
                : services.language.getText('selectSubsites');
        return (
            <div className="merchant-selecter-plus">
                <SelectMerchant
                    onChange={this.onSelect}
                    onCancel={this.toggleModal}
                    selectValues={value}
                    disabled={selectDisabled}
                    customFields={customFields}
                    btnText={btnText}
                    customColumns={customColumns}
                    params={params}
                />
                {!!tips && <div className="tips">{tips}</div>}

                {hasValue && (
                    <ShopSelectedDisplay
                        data={value || []}
                        onChange={this.onChangeSelect}
                        onRemove={this.onChange}
                        disabled={disabled}
                        hideSubsiteColume={hideSubsiteColume}
                    ></ShopSelectedDisplay>
                )}
            </div>
        );
    }
}
