import React, { PureComponent } from 'react';
import { Button, message as AntMessage, Modal, Tabs } from 'antd';
import { services } from '@comall-backend-builder/core';
import classnames from 'classnames';
import { language, privilege } from '@comall-backend-builder/core/lib/services';
import './index.less';

export interface MarketingCalendarPageSettingActivityButtonStates {
    handleModal: boolean;
    currentTaKey: string;
}

export class MarketingCalendarPageSettingActivityButton extends PureComponent<
    any,
    MarketingCalendarPageSettingActivityButtonStates
> {
    constructor(props: any) {
        super(props);
        this.state = {
            handleModal: false,
            currentTaKey: this.getNavList()[0].key,
        };
    }

    getNavList = () => {
        const list = [
            {
                key: 'attractTrafficNewCustomers',
                subTitle: '<<attractTrafficNewCustomers>>',
                secondSubs: [
                    {
                        title: '',
                        items: [
                            {
                                key: 'invitationActivity',
                                title: '<<yqyl>>',
                                description: '<<hxk>>',
                                iconClassName: 'icon-invitation',
                                route: '/menu-list/market/invitation-activity',
                                privilege: {
                                    path: 'invitationActivity',
                                    level: 'full',
                                },
                            },
                            {
                                type: 'item',
                                key: '<<fxyl>>',
                                title: '<<fxyl>>',
                                description: '<<jlfxyh>>',
                                iconClassName: 'icon-share-page',
                                route: '/menu-list/market/share-page-activity',
                                privilege: {
                                    path: 'sharePageActivity',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'eventMarket',
                                title: '<<sjyx>>',
                                description: '<<cfbtsj>>',
                                iconClassName: 'icon-event-market',
                                route: '/menu-list/market/eventMarket',
                                privilege: {
                                    path: 'eventMarket',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'groupBuyingRule',
                                title: '<<pthd>>',
                                description: '<<ydyhyqpyyqptgm>>',
                                iconClassName: 'icon-group-buying',
                                route: '/menu-list/market/group-buying',
                                privilege: {
                                    path: 'groupBuyingRule',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'bargainActivity',
                                title: '<<kj>>',
                                description: '<<bkydjgyd>>',
                                iconClassName: 'icon-bargain',
                                route: '/menu-list/market/bargain-activity',
                                privilege: {
                                    path: 'bargain',
                                    level: 'full',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                key: 'increaseSalesVolume',
                subTitle: '<<increaseSalesVolumeOther>>',
                secondSubs: [
                    {
                        title: 'yhq',
                        items: [
                            {
                                type: 'item',
                                key: '<<cash>>',
                                title: '<<cash>>',
                                description: '<<cjxftsdcgmjz>>',
                                iconClassName: 'icon-cash-coupon',
                                route: '/menu-list/coupon/cash-coupon-rules',
                                privilege: {
                                    path: 'cashCouponRule',
                                    level: 'full',
                                },
                            },
                            {
                                type: 'item',
                                key: '<<gift>>',
                                title: '<<gift>>',
                                description: '<<xygkxf>>',
                                iconClassName: 'icon-gift-coupon',
                                route: '/menu-list/coupon/gift-coupon-rules',
                                privilege: {
                                    path: 'giftCouponRule',
                                    level: 'full',
                                },
                            },
                            {
                                type: 'item',
                                key: '<<freight>>',
                                title: '<<freight>>',
                                description: '<<jdgwmktsxfzhl>>',
                                iconClassName: 'icon-freight-coupon',
                                route: '/menu-list/coupon/freight-coupon-rules',
                                privilege: {
                                    path: 'freightCouponRule',
                                    level: 'full',
                                },
                            },
                            {
                                type: 'item',
                                key: '<<parking>>',
                                title: '<<parking>>',
                                description: '<<tcwyylsqxygkdd>>',
                                iconClassName: 'icon-parking-coupon',
                                route: '/menu-list/coupon/parking-coupon-rules',
                                privilege: {
                                    path: 'parkingCouponRule',
                                    level: 'full',
                                },
                            },
                            {
                                type: 'item',
                                key: '<<balance>>',
                                title: '<<balance>>',
                                description: '<<zhczqhxcskhzxl>>',
                                iconClassName: 'icon-balance-coupon',
                                route: '/menu-list/coupon/balance-coupon-rules',
                                privilege: {
                                    path: 'balanceCouponRule',
                                    level: 'full',
                                },
                            },
                            {
                                type: 'item',
                                key: '<<bfplat>>',
                                title: '<<bfplat>>',
                                description: '<<bfplatTip>>',
                                iconClassName: 'icon-bfplat-coupon',
                                route: '/menu-list/coupon/bfplat-coupon-rules',
                                privilege: {
                                    path: 'bfplatCouponRule',
                                    level: 'full',
                                },
                            },
                            {
                                type: 'item',
                                key: '<<fjyhq>>',
                                title: '<<fjyhq>>',
                                description: '<<fjqldxsxxxfwj>>',
                                iconClassName: 'icon-fuji-coupon',
                                route: '/menu-list/coupon/efuture-coupon-rules',
                                privilege: {
                                    path: 'eFutureCouponRule',
                                    level: 'full',
                                },
                            },
                            {
                                type: 'item',
                                key: '<<dxfq>>',
                                title: '<<dxfq>>',
                                description: '<<jzqclgxzzzlyjzz>>',
                                iconClassName: 'icon-giving-coupon',
                                route: '/menu-list/coupon/giving-coupon',
                                privilege: {
                                    path: 'givingCouponSchedule',
                                    level: 'full',
                                },
                            },
                        ],
                    },
                    {
                        title: 'promotionActivity',
                        items: [
                            {
                                key: 'virtualProductConf',
                                title: '<<virtualProductConf>>',
                                description: '<<virtualProductConfDes>>',
                                iconClassName: 'icon-visual-product',
                                route: '/sales-electronic-coupon',
                                privilege: {
                                    path: 'virtualProductConf',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'timelimitActivity',
                                title: '<<timelimitActivity>>',
                                description: '<<skillDescription>>',
                                iconClassName: 'icon-limit-activity',
                                route: '/timelimit-activity',
                                privilege: {
                                    path: 'timelimitActivity',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'promotionPrice',
                                title: '<<promotionPrice>>',
                                description: '<<promotionPriceDescription>>',
                                iconClassName: 'icon-promotion-price ',
                                route: '/promotionPrice',
                                privilege: {
                                    path: 'promotionPrice',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'reduction',
                                title: '<<reductionOther>>',
                                description: '<<reductionDescription>>',
                                iconClassName: 'icon-reduction-other',
                                route: '/promotion',
                                privilege: {
                                    path: 'reduction',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'fixedPrice',
                                title: '<<fixedPrice>>',
                                description: '<<fixedPriceDesciption>>',
                                iconClassName: 'icon-fixed-price',
                                route: '/fixed-price',
                                privilege: {
                                    path: 'fixedPrice',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'presale',
                                title: '<<qkys>>',
                                description: '<<sjyhgmyx>>',
                                iconClassName: 'icon-presale',
                                route: '/menu-list/market/full-payment-activities',
                                privilege: {
                                    path: 'fullPaymentActivity',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'presaleActivity',
                                title: '<<yyqg>>',
                                description: '<<xyyhgm>>',
                                iconClassName: 'icon-presale-activity',
                                route: '/menu-list/market/presale-activities',
                                privilege: {
                                    path: 'presaleActivity',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'reserveDrawLots',
                                title: '<<yycq>>',
                                description: '<<xbmhcq>>',
                                iconClassName: 'icon-reserve-draw-lots',
                                route: '/menu-list/market/reserve-draw-lots',
                                privilege: {
                                    path: 'reserveDrawLots',
                                    level: 'full',
                                },
                            },
                        ],
                    },
                    {
                        title: 'paymentMarketing',
                        items: [
                            {
                                key: 'payAward',
                                title: '<<zfyl>>',
                                description: '<<gkfkhhdjl>>',
                                iconClassName: 'icon-pay-with-gifts',
                                route: '/menu-list/market/pay-with-gifts',
                                privilege: {
                                    path: 'payAward',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'collection-points',
                                title: '<<collectionPoints>>',
                                description: '<<collectionPointsTip>>',
                                iconClassName: 'icon-collection-point',
                                route: '/menu-list/market/collection-points',
                                privilege: {
                                    path: 'collectionPoint',
                                    level: 'full',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                key: 'marketing',
                subTitle: '<<marketing>>',
                secondSubs: [
                    {
                        title: '',
                        items: [
                            {
                                key: 'miniProgramV2',
                                title: '<<qdtgm>>',
                                description: '<<hdtgdgxylgj>>',
                                iconClassName: 'icon-qrcode-rule',
                                route: '/menu-list/market/miniprogram-v2',
                                privilege: {
                                    path: 'miniProgramCodeV2',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'shoppingListUnionOrder',
                                title: '<<dkxd>>',
                                description: '<<dgywyhcjgwqd>>',
                                iconClassName: 'icon-shopping-list',
                                route: '/menu-list/market/shopping-list',
                                privilege: {
                                    path: 'shoppingListUnionOrder',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'distribution',
                                title: '<<qmfx>>',
                                description: '<<yhfxsp>>',
                                iconClassName: 'icon-distribution',
                                route: '/menu-list/market/distribution',
                                privilege: {
                                    path: 'distributionManager',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'internalPurchase',
                                title: '<<internalPurChase>>',
                                description: '<<whzqykznbflhd>>',
                                iconClassName: 'icon-internal-purchase',
                                route: '/menu-list/market/internal-purchase',
                                privilege: {
                                    path: 'enterpriseInternalPurchase',
                                    level: 'full',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                key: 'promoteInteractiveActivities',
                subTitle: '<<promoteInteractiveActivities>>',
                secondSubs: [
                    {
                        title: '<<newFission>>',
                        items: [
                            {
                                key: 'cardCollectionActivity',
                                title: '<<jkhd>>',
                                description: '<<yhjkljl>>',
                                iconClassName: 'icon-card-collection',
                                route: '/menu-list/market/cardCollection',
                                privilege: {
                                    path: 'cardCollectionActivity',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'watchword',
                                title: '<<watchwordCouponCollection>>',
                                description: '<<watchwordCouponCollectionDescription>>',
                                iconClassName: 'icon-watch-word',
                                route: '/menu-list/market/watchword',
                                privilege: {
                                    path: 'watchword',
                                    level: 'full',
                                },
                            },
                        ],
                    },
                    {
                        title: '<<enhanceStickiness>>',
                        items: [
                            {
                                key: 'checkinActivity',
                                title: '<<qdyl>>',
                                description: '<<mrqdlqjfhjl>>',
                                iconClassName: 'icon-checkin',
                                route: '/menu-list/market/checkin-activity',
                                privilege: {
                                    path: 'checkinActivity',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'improveInformation',
                                title: '<<wsxxyl>>',
                                description: '<<sjhyxx>>',
                                iconClassName: 'icon-improve-information',
                                route: '/menu-list/market/improve-information',
                                privilege: {
                                    path: 'improveInformation',
                                    level: 'full',
                                },
                            },
                        ],
                    },
                    {
                        title: '<<interactiveGames>>',
                        items: [
                            {
                                key: 'wheelDraw',
                                title: '<<dzp>>',
                                description: '<<yhbdzp>>',
                                iconClassName: 'icon-wheel-draw',
                                route: '/menu-list/market/wheel-draw',
                                privilege: {
                                    path: 'wheelDraw',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'redPackageRain',
                                title: '<<hby>>',
                                description: '<<xshby>>',
                                iconClassName: 'icon-red-package-rain',
                                route: '/menu-list/market/red-package-rain',
                                privilege: {
                                    path: 'redPackageRain',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'luckyRedPackage',
                                title: '<<psqhb>>',
                                description: '<<psqhbDes>>',
                                iconClassName: 'icon-lucky-red-package',
                                route: '/menu-list/market/lucky-red-package',
                                privilege: {
                                    path: 'luckyRedPackage',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'slotMachines',
                                title: '<<lhj>>',
                                description: '<<yhtglhjcj>>',
                                iconClassName: 'icon-distribution',
                                route: '/menu-list/market/slot-machines',
                                privilege: {
                                    path: 'slotMachine',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'openBox',
                                title: '<<khyl>>',
                                description: '<<yhcklh>>',
                                iconClassName: 'icon-open-box',
                                route: '/menu-list/market/open-box',
                                privilege: {
                                    path: 'openBoxActivity',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'blindBoxMarketing',
                                title: '<<mhyx>>',
                                description: '<<czmsp>>',
                                iconClassName: 'icon-blind-box',
                                route: '/menu-list/market/blind-box',
                                privilege: {
                                    path: 'blindBoxMarketing',
                                    level: 'full',
                                },
                            },
                        ],
                    },
                ],
            },
            {
                key: 'hynx',
                subTitle: '<<hynx>>',
                secondSubs: [
                    {
                        title: '',
                        items: [
                            {
                                key: 'birthdayCaring',
                                title: '<<birthdayCaring>>',
                                description: '<<birthdayCaringDesc>>',
                                iconClassName: 'icon-birthday',
                                route: '/birthday-caring',
                                privilege: {
                                    path: 'birthdayCaring',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'festivalMarketing',
                                title: '<<festivalMarketing>>',
                                description: '<<festivalMarketingDesc>>',
                                iconClassName: 'icon-festival-marketing',
                                route: '/festival-marketing',
                                privilege: {
                                    path: 'festivalMarketing',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'memberCardDiscount',
                                title: '<<memberCardDiscount>>',
                                description: '<<memberCardDiscountDesc>>',
                                iconClassName: 'icon-member-card-discount',
                                route: '/member_card_discount_activities',
                                privilege: {
                                    path: 'memberCardDiscount',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'creditEShop',
                                title: '<<creditEShop>>',
                                description: '<<creditEShopDesc>>',
                                iconClassName: 'icon-credit-shop',
                                route: '/credit-shop',
                                privilege: {
                                    path: 'creditEShop',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'scratchCard',
                                title: '<<scratchCard>>',
                                description: '<<scratchCardDesc>>',
                                iconClassName: 'icon-scratch-card',
                                route: '/scratchCard',
                                privilege: {
                                    path: 'scratchCard',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'lotteryCode',
                                title: '<<lotteryCode>>',
                                description: '<<lotteryCodeDesc>>',
                                iconClassName: 'icon-lottery-code',
                                route: '/lottery-code',
                                privilege: {
                                    path: 'lotteryCode',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'pointRightsAndInterests',
                                title: '<<pointRightsAndInterests>>',
                                description: '<<pointRightsAndInterestsDesc>>',
                                iconClassName: 'icon-send-score',
                                route: '/point-benefit',
                                privilege: {
                                    path: 'pointRightsAndInterests',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'storedValueCardBuyActivity',
                                title: '<<storedValueCardBuyActivity>>',
                                description: '<<storedValueCardBuyActivityDesc>>',
                                iconClassName: 'icon-stored-activity',
                                route: '/stored-value-card-buy-activity',
                                privilege: {
                                    path: 'storedValueCardRewardActivity',
                                    level: 'full',
                                },
                            },
                            {
                                key: 'storedValueCardActivity',
                                title: '<<storedValueCardActivity>>',
                                description: '<<storedValueCardActivityDesc>>',
                                iconClassName: 'icon-money-card',
                                route: '/stored-value-card-activity',
                                privilege: {
                                    path: 'storedValueCardActivity',
                                    level: 'full',
                                },
                            },
                        ],
                    },
                ],
            },
        ];
        return list;
    };

    getName = (value: string) => {
        const title = value.replace('<<', '').replace('>>', '');
        return services.language.getText(title);
    };

    showModal = () => {
        this.setState({
            handleModal: true,
            currentTaKey: this.getNavList()[0].key,
        });
    };

    hideModal = () => {
        this.setState({
            handleModal: false,
        });
    };

    changeTab = (key: string) => {
        this.setState({
            currentTaKey: key,
        });
    };

    goTarget = (item: any) => {
        if (privilege.check(item.privilege.path, 'full')) {
            window.open(`/#${item.route}`);
        } else {
            AntMessage.warning(language.getText('noActivityPrivile'));
        }
    };

    renderTabs = () => {
        const { currentTaKey } = this.state;
        const navList = this.getNavList();
        return (
            <Tabs activeKey={currentTaKey} onTabClick={this.changeTab} animated={false}>
                {navList.map((menu: any) => {
                    return (
                        <Tabs.TabPane
                            tab={this.getName(menu.subTitle)}
                            key={menu.key}
                        ></Tabs.TabPane>
                    );
                })}
            </Tabs>
        );
    };

    renderContent = () => {
        const { currentTaKey } = this.state;
        const navList = this.getNavList();
        const vo = navList.find((item) => item.key === currentTaKey);
        if (!vo || !vo.secondSubs) {
            return null;
        }
        return (
            <div>
                {vo.secondSubs.map((secondSub: any) => {
                    return (
                        <div>
                            <div className="menu-second-sub-title">
                                {this.getName(secondSub.title)}
                            </div>
                            <div className="menu-list">
                                {secondSub.items.map((item: any, itemIndex: number) => {
                                    return (
                                        <div
                                            key={itemIndex}
                                            className="menu-item"
                                            onClick={() => {
                                                this.goTarget(item);
                                            }}
                                        >
                                            <div
                                                className={classnames('icon', item.iconClassName)}
                                            />
                                            <div>
                                                <div className="menu-title">
                                                    {this.getName(item.title)}
                                                </div>
                                                <div className="menu-description">
                                                    {this.getName(item.description)}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };

    renderModal = () => {
        const { handleModal } = this.state;
        return (
            <Modal
                title={services.language.getText('cjhd_1')}
                className="store-selector"
                width={930}
                visible={handleModal}
                onCancel={this.hideModal}
                cancelButtonProps={{ disabled: true }}
                footer={[
                    <Button key="back" onClick={this.hideModal}>
                        {services.language.getText('common.close')}
                    </Button>,
                ]}
            >
                <div>
                    {this.renderTabs()}
                    {this.renderContent()}
                </div>
            </Modal>
        );
    };

    render() {
        return (
            <div>
                <Button type={'primary'} style={{ marginLeft: '10px' }} onClick={this.showModal}>
                    {language.getText('cjhd_1')}
                </Button>
                {this.renderModal()}
            </div>
        );
    }
}
