import React, { PureComponent } from 'react';
import { Button, Modal, Form, message, Radio, InputNumber, Checkbox } from 'antd';
import { services } from '@comall-backend-builder/core';
import get from 'lodash/get';

import { CustomSelectSubsite } from '../custom-select-subsite-select';

const language = services.language;
const FormItem = Form.Item;
const RadioGroup = Radio.Group;
const CheckboxGroup = Checkbox.Group;

const FormItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};

const DEFAULT_VALUE = {
    linkType: '',
    linkParams: {
        id: 0,
        name: '',
        showStatus: ['STARTED', 'NOTSTARTED', 'COMPLETE'],
        showCount: 'ALL',
        count: '',
    },
};

interface activity {
    showStatus?: any;
    showCount?: string;
    count?: any;
}

interface subSite {
    id?: number;
    name?: string;
}

interface activityProps {
    value: any;
    contentConfig: Record<string, any>;
    modalConfig: Record<string, any>;
    onChange: (value: any) => void;
}

/**
 * 自定义可视化，商城列表活动选择器
 */
export class CustomSelectMallActivityListPlus extends PureComponent<
    activityProps,
    { visibleModal: boolean; linkParams: activity & subSite }
> {
    constructor(props: activityProps) {
        super(props);
        const linkParams = get(props.value, 'linkParams') || DEFAULT_VALUE.linkParams;

        this.state = {
            visibleModal: false,
            linkParams: linkParams,
        };
    }

    showModal = () => {
        this.setState({ visibleModal: true });
    };

    hideModal = () => {
        this.setState({ visibleModal: false });
    };

    /** 门店选择 */
    handleChange = (value: subSite) => {
        const { linkParams } = this.state;

        this.setState({ linkParams: { ...linkParams, ...value } });
    };

    /** 商场活动信息选择 */
    activityOnChange = (value: activity) => {
        const { linkParams } = this.state;

        this.setState({ linkParams: { ...linkParams, ...value } });
    };

    /** 弹窗点击确实， form 上送数据 */
    onSubmit = () => {
        const { linkParams } = this.state;
        const { onChange } = this.props;

        if (!linkParams.showStatus || !linkParams.showStatus.length) {
            return message.error('请配置显示的活动类型');
        }
        if (linkParams.showCount === 'COUNT' && !linkParams.count) {
            return message.error('请配置显示个数');
        }
        if (!linkParams.id) {
            return message.error('请选择门店');
        }

        onChange && onChange({ ...linkParams });
        this.hideModal();
    };

    render() {
        const { value } = this.props;

        const { visibleModal, linkParams } = this.state;
        const subSiteName = get(value, 'linkParams.name');

        return (
            <span className="link-name">
                {subSiteName ? <span className="link-name-label">{subSiteName}</span> : '未选择'}
                <Button type={'link'} onClick={this.showModal} className="link-type-edit">
                    {subSiteName
                        ? language.getText('components.Tools.modify')
                        : language.getText('components.Tools.select')}
                </Button>

                <Modal
                    title="请选择"
                    width="800px"
                    visible={visibleModal}
                    onOk={this.onSubmit}
                    onCancel={this.hideModal}
                >
                    <Form {...FormItemLayout}>
                        <FormItem label={'选择门店'} required>
                            <CustomSelectSubsite value={linkParams} onChange={this.handleChange} />
                        </FormItem>
                        <FormItem label={'显示个数'}>
                            <RadioGroup
                                onChange={(event) =>
                                    this.activityOnChange({
                                        showCount: event.target.value,
                                        count: 0,
                                    })
                                }
                                value={linkParams.showCount}
                            >
                                <Radio value={'ALL'}>全部</Radio>
                                <Radio value={'COUNT'}>
                                    <>
                                        <InputNumber
                                            onChange={(value) =>
                                                this.activityOnChange({ count: value })
                                            }
                                            min={1}
                                            precision={0}
                                            max={999999}
                                            size="small"
                                            value={Number(linkParams.count)}
                                        />
                                        &nbsp; 个
                                    </>
                                </Radio>
                            </RadioGroup>
                        </FormItem>
                        <FormItem label={'显示活动'}>
                            <CheckboxGroup
                                onChange={(checkedValue) => {
                                    this.activityOnChange({ showStatus: checkedValue });
                                }}
                                value={linkParams.showStatus}
                            >
                                <Checkbox
                                    style={{ marginTop: 10, marginBottom: 5 }}
                                    value="STARTED"
                                >
                                    报名进行中
                                </Checkbox>
                                <br />

                                <Checkbox style={{ marginBottom: 5 }} value="NOTSTARTED">
                                    报名未开始
                                </Checkbox>
                                <br />

                                <Checkbox style={{ marginBottom: 5 }} value="COMPLETE">
                                    报名已结束
                                </Checkbox>
                            </CheckboxGroup>
                        </FormItem>
                    </Form>
                </Modal>
            </span>
        );
    }
}
