import React, { Component } from 'react';
import { Table, Button } from 'antd';
import { cloneDeep, remove, forEach } from 'lodash';
import { Brand } from './select-product-brands';
import { language } from '@comall-backend-builder/core/lib/services';

interface ProductBrandsSelectDisplayProps {
    data: Array<Brand>;
    onRemove: (data: Array<Brand>) => void;
    disabled: boolean;
}
interface ProductBrandsSelectDisplayState {
    displayAll: boolean;
}

/**
 * 品牌展示和内容编辑
 */
class ProductBrandsSelectDisplay extends Component<
    ProductBrandsSelectDisplayProps,
    ProductBrandsSelectDisplayState
> {
    state: ProductBrandsSelectDisplayState = {
        displayAll: false,
    };
    selectedRows: Brand[] = [];

    getTableConfig = () => {
        const { disabled } = this.props;
        let config: any = {
            scroll: {
                y: 400,
            },
            rowKey: 'id',
            columns: [
                {
                    title: language.getText('brandId'),
                    dataIndex: 'id',
                    key: 'id',
                    ellipsis: true,
                },
                {
                    title: language.getText('brandName'),
                    dataIndex: 'name',
                    key: 'name',
                    ellipsis: true,
                },
                {
                    title: language.getText('common.tableAction'),
                    key: 'operation',
                    width: 100,
                    render: (i: any, row: any) => {
                        return !disabled ? (
                            <Button type={'link'} onClick={this.onRemove(row)}>
                                {language.getText('common.delete')}
                            </Button>
                        ) : null;
                    },
                },
            ],
            footer: () => this.renderFooter(),
            style: {
                marginTop: 20,
            },
        };
        return config;
    };

    renderFooter = () => {
        const { disabled, data } = this.props;
        const { displayAll } = this.state;
        return (
            <div className="product-selected-footer">
                {data && data.length > 5 && (
                    <Button
                        className="table-footer-expand"
                        type="link"
                        onClick={this.handleDisplayChange}
                    >
                        {displayAll ? language.getText('hideBrand') : language.getText('viewBrand')}
                    </Button>
                )}
                {!disabled && (
                    <div className="setting-item">
                        <Button
                            className="setting-item-ok"
                            type="primary"
                            onClick={this.onRemoveAll}
                        >
                            {language.getText('allClear')}
                        </Button>
                    </div>
                )}
            </div>
        );
    };

    onRemoveAll = () => {
        const { data, onRemove } = this.props;
        const results = cloneDeep(data);
        forEach(results, (brand: Brand) => {
            if (brand.select) {
                brand.delete = true;
            }
        });
        onRemove([]);
    };

    onRemove = (row: Brand) => {
        const { data, onRemove } = this.props;

        return () => {
            const result = cloneDeep(data);
            remove(result, (brand) => row.id === brand.id);
            onRemove(result);
        };
    };

    handleDisplayChange = () => {
        const { displayAll } = this.state;
        this.setState({
            displayAll: !displayAll,
        });
    };

    render() {
        const { data } = this.props;
        if (!data || data.length === 0) return '';
        const { displayAll } = this.state;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: displayAll ? data : data && data.slice(0, 5),
        };

        return <Table {...tableProps} pagination={false}></Table>;
    }
}

export { ProductBrandsSelectDisplay };
