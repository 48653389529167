import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { get, isNil } from 'lodash';
import { message as AntMessage } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services';

enum LabelTypeMode {
    /**
     * 手工标签
     */
    MANUAL = 'MANUAL',
    /**
     * 条件标签
     */
    CONDITION = 'CONDITION',
}

enum ConditionTypeMode {
    /**
     * 消费条件
     */
    CONSUMPTION = 'CONSUMPTION',
    /**
     * 商品偏好
     */
    PREFERENCE_PRODUCT = 'PREFERENCE_PRODUCT',
    /**
     * 行为条件
     */
    BEHAVIOR = 'BEHAVIOR',
}

export const config: Config = {
    entities: {
        LabelManageEntity: {
            apiPath: '/loader/admin/tags',
            properties: {
                id: {
                    type: 'number.integer',
                },
                tagName: {
                    type: 'string.tip',
                    displayName: '<<tagName>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        placeholder: '<<tagNameRule3>>',
                        maxLength: 30,
                        style: {
                            width: 400,
                        },
                    },
                },
                typeName: {
                    type: 'string',
                    displayName: '<<bqlx>>',
                },
                subsiteId: {
                    type: 'string.options.select',
                    displayName: '<<applicableSubsite>>',
                    options: [],
                    rules: [{ required: true, message: '<<applicableSubsiteRequired>>' }],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectSubsite>>',
                        style: {
                            width: 400,
                        },
                    },
                },
                labelType: {
                    type: 'string.options.radio',
                    displayName: '<<bqlx>>',
                    defaultValue: 'MANUAL',
                    rules: [{ required: true }],
                    options: [
                        { id: 'MANUAL', name: '<<sgbq>>' },
                        { id: 'CONDITION', name: '<<tjbq>>' },
                    ],
                },
                remark: {
                    displayName: '<<remark>>',
                    type: 'string.text.paragraph',
                    controlConfig: {
                        placeholder: '<<qsrbz>>',
                        rows: 6,
                        style: {
                            width: 400,
                        },
                    },
                },
                conditionType: {
                    type: 'string.options.radio',
                    displayName: '<<tjlx>>',
                    defaultValue: 'CONSUMPTION',
                    rules: [{ required: true }],
                    options: [
                        { id: 'CONSUMPTION', name: '<<xftj>>' },
                        { id: 'PREFERENCE_PRODUCT', name: '<<spph>>' },
                        { id: 'BEHAVIOR', name: '<<xwtj>>' },
                    ],
                },
                consumptionConditions: {
                    type: 'object.participateCondition',
                    displayName: '<<xftj>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        codes: [
                            {
                                code: 'DISSIPATE',
                            },
                            {
                                code: 'CONSUMPTIONAMOUNT',
                            },
                            {
                                code: 'DEALCOUNT',
                            },
                        ],
                    },
                },
                receiptCertification: {
                    type: 'array.optionIds.checkbox.tip',
                    displayName: '<<spph>>',
                    defaultValue: [],
                    options: [
                        {
                            id: 'CONSUMPTION_MERCHANT',
                            name: '<<lsgmgyxzjdsp>>',
                        },
                    ],
                    rules: [{ required: true }],
                },
                merchants: {
                    type: 'array.merchant.selector.tick',
                    displayName: '<<selectSubsites>>',
                    className: 'merchant-show',
                    defaultValue: [],
                    controlConfig: {
                        subsiteField: 'subsiteId',
                        needDependencesText: '<<qxxzmdhzxzzj>>',
                        hideSubsiteColume: true,
                        customFields: [
                            {
                                property: 'merchantType',
                            },
                            {
                                property: 'merchantTagId',
                            },
                            {
                                property: 'merchantName',
                            },
                        ],
                        customColumns: [
                            {
                                property: 'merchantName',
                            },
                            {
                                property: 'code',
                                sorter: true,
                            },
                            {
                                property: 'merchantType',
                            },
                            {
                                property: 'merchantStatus',
                            },
                        ],
                    },
                },
                behavioralConditions: {
                    type: 'object.participateCondition',
                    displayName: '<<xwtj>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        codes: [
                            {
                                code: 'BEHAVIOR',
                            },
                        ],
                    },
                },
            },
        },
    },
    components: {
        LabelManageView: {
            component: 'Viewport',
            entity: 'LabelManageEntity',
        },
        LabelManagePageView: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'LabelManagePage',
                },
            ],
        },
        LabelManageEditPage: {
            component: 'Card',
            content: { component: 'LabelManageEditForm' },
        },
        LabelManageEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'LabelManageEntity',
            loaderType: 'get',
            labelCol: 6,
            controlCol: 18,
            fields: [
                {
                    property: 'tagName',
                },
                {
                    property: 'subsiteId',
                    controlConfig: {
                        disabled: true,
                        style: {
                            width: 400,
                        },
                    },
                },
                {
                    property: 'labelType',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'remark',
                    visible: (values: any) => get(values, 'labelType') === LabelTypeMode.MANUAL,
                },
                {
                    property: 'conditionType',
                    visible: (values: any) => get(values, 'labelType') === LabelTypeMode.CONDITION,
                },
                {
                    property: 'consumptionConditions',
                    visible: (values: any) =>
                        get(values, 'labelType') === LabelTypeMode.CONDITION &&
                        get(values, 'conditionType') === ConditionTypeMode.CONSUMPTION,
                },
                {
                    property: 'receiptCertification',
                    visible: (values: any) =>
                        get(values, 'labelType') === LabelTypeMode.CONDITION &&
                        get(values, 'conditionType') === ConditionTypeMode.PREFERENCE_PRODUCT,
                },
                {
                    property: 'merchants',
                    visible: (values: any) =>
                        get(values, 'labelType') === LabelTypeMode.CONDITION &&
                        get(values, 'conditionType') === ConditionTypeMode.PREFERENCE_PRODUCT &&
                        get(values, 'receiptCertification')?.length,
                },
                {
                    property: 'behavioralConditions',
                    visible: (values: any) =>
                        get(values, 'labelType') === LabelTypeMode.CONDITION &&
                        get(values, 'conditionType') === ConditionTypeMode.BEHAVIOR,
                },
            ],
            submit: { text: '<<common.save>>' },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                let labelType = get(entity, 'labelType');
                let conditionType = get(entity, 'conditionType');
                //条件标签-消费条件
                if (
                    labelType === LabelTypeMode.CONDITION &&
                    conditionType === ConditionTypeMode.CONSUMPTION
                ) {
                    let consumptionConditions = get(entity, 'consumptionConditions', []) || [];
                    let checkArr = consumptionConditions.filter((item: any) => item.checked);
                    let submitStatus = true;
                    let errorText = language.getText('qwsxftjxx');
                    for (let i = 0; i < checkArr.length; i++) {
                        if (!checkArr[i].selectValue && checkArr[i].code === 'DISSIPATE') {
                            submitStatus = false;
                        }
                        if (
                            checkArr[i].code === 'CONSUMPTIONAMOUNT' ||
                            checkArr[i].code === 'DEALCOUNT'
                        ) {
                            if (
                                !checkArr[i].selectValue ||
                                isNil(checkArr[i]?.otherValues?.min) ||
                                isNil(checkArr[i]?.otherValues?.max)
                            ) {
                                submitStatus = false;
                            }
                            if (
                                checkArr[i].otherValues &&
                                checkArr[i].otherValues.max &&
                                checkArr[i].otherValues.min &&
                                Number(checkArr[i].otherValues.max) <
                                    Number(checkArr[i].otherValues.min)
                            ) {
                                errorText = language.getText('szdzxzbndyzdz');
                                submitStatus = false;
                            }
                        }
                    }
                    if (!checkArr.length) {
                        AntMessage.warning(language.getText('qzsxzyzxftj'));
                        return false;
                    }
                    if (!submitStatus) {
                        AntMessage.warning(errorText);
                        return false;
                    }
                }
                //条件标签-商品偏好
                if (
                    labelType === LabelTypeMode.CONDITION &&
                    conditionType === ConditionTypeMode.PREFERENCE_PRODUCT
                ) {
                    if (
                        (entity.receiptCertification && !entity.receiptCertification.length) ||
                        (entity.merchants && !entity.merchants.length)
                    ) {
                        AntMessage.warning(language.getText('qwsspphxx'));
                        return false;
                    }
                }
                let errorText = '';
                //条件标签-行为条件
                if (
                    labelType === LabelTypeMode.CONDITION &&
                    conditionType === ConditionTypeMode.BEHAVIOR
                ) {
                    let behavioralConditions = get(entity, 'behavioralConditions', []) || [];
                    let behavioralArr = behavioralConditions.filter((item: any) => item.checked);
                    for (let i = 0; i < behavioralArr.length; i++) {
                        if (behavioralArr[i].code === 'BEHAVIOR') {
                            if (
                                !behavioralArr[i].selectValue ||
                                isNil(behavioralArr[i]?.otherValues?.min) ||
                                isNil(behavioralArr[i]?.otherValues?.max)
                            ) {
                                errorText = language.getText('qwsxwtjxx');
                            }
                            if (
                                behavioralArr[i].otherValues &&
                                behavioralArr[i].otherValues.max &&
                                behavioralArr[i].otherValues.min &&
                                Number(behavioralArr[i].otherValues.max) <
                                    Number(behavioralArr[i].otherValues.min)
                            ) {
                                errorText = language.getText('szdzxzbndyzdz');
                            }
                        }
                    }
                    if (!behavioralArr.length) {
                        AntMessage.warning(services.language.getText('qxzxwtj'));
                        return false;
                    }
                    if (errorText) {
                        AntMessage.warning(errorText);
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        LabelManageAddPage: {
            component: 'Card',
            content: { component: 'LabelManageAddForm' },
        },
        LabelManageAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'LabelManageEntity',
            labelCol: 6,
            controlCol: 18,
            fields: [
                {
                    property: 'tagName',
                },
                { property: 'subsiteId' },
                { property: 'labelType' },
                {
                    property: 'remark',
                    visible: (values: any) => get(values, 'labelType') === LabelTypeMode.MANUAL,
                },
                {
                    property: 'conditionType',
                    visible: (values: any) => get(values, 'labelType') === LabelTypeMode.CONDITION,
                },
                {
                    property: 'consumptionConditions',
                    visible: (values: any) =>
                        get(values, 'labelType') === LabelTypeMode.CONDITION &&
                        get(values, 'conditionType') === ConditionTypeMode.CONSUMPTION,
                },
                {
                    property: 'receiptCertification',
                    visible: (values: any) =>
                        get(values, 'labelType') === LabelTypeMode.CONDITION &&
                        get(values, 'conditionType') === ConditionTypeMode.PREFERENCE_PRODUCT,
                },
                {
                    property: 'merchants',
                    visible: (values: any) =>
                        get(values, 'labelType') === LabelTypeMode.CONDITION &&
                        get(values, 'conditionType') === ConditionTypeMode.PREFERENCE_PRODUCT &&
                        get(values, 'receiptCertification')?.length,
                },
                {
                    property: 'behavioralConditions',
                    visible: (values: any) =>
                        get(values, 'labelType') === LabelTypeMode.CONDITION &&
                        get(values, 'conditionType') === ConditionTypeMode.BEHAVIOR,
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                let labelType = get(entity, 'labelType');
                let conditionType = get(entity, 'conditionType');
                //条件标签-消费条件
                if (
                    labelType === LabelTypeMode.CONDITION &&
                    conditionType === ConditionTypeMode.CONSUMPTION
                ) {
                    let consumptionConditions = get(entity, 'consumptionConditions', []) || [];
                    let checkArr = consumptionConditions.filter((item: any) => item.checked);
                    let submitStatus = true;
                    let errorText = language.getText('qwsxftjxx');
                    for (let i = 0; i < checkArr.length; i++) {
                        if (!checkArr[i].selectValue && checkArr[i].code === 'DISSIPATE') {
                            submitStatus = false;
                        }
                        if (
                            checkArr[i].code === 'CONSUMPTIONAMOUNT' ||
                            checkArr[i].code === 'DEALCOUNT'
                        ) {
                            if (
                                !checkArr[i].selectValue ||
                                isNil(checkArr[i]?.otherValues?.min) ||
                                isNil(checkArr[i]?.otherValues?.max)
                            ) {
                                submitStatus = false;
                            }
                            if (
                                checkArr[i].otherValues &&
                                checkArr[i].otherValues.max &&
                                checkArr[i].otherValues.min &&
                                Number(checkArr[i].otherValues.max) <
                                    Number(checkArr[i].otherValues.min)
                            ) {
                                errorText = language.getText('szdzxzbndyzdz');
                                submitStatus = false;
                            }
                        }
                    }
                    if (!checkArr.length) {
                        AntMessage.warning(language.getText('qzsxzyzxftj'));
                        return false;
                    }
                    if (!submitStatus) {
                        AntMessage.warning(errorText);
                        return false;
                    }
                }
                //条件标签-商品偏好
                if (
                    labelType === LabelTypeMode.CONDITION &&
                    conditionType === ConditionTypeMode.PREFERENCE_PRODUCT
                ) {
                    if (
                        (entity.receiptCertification && !entity.receiptCertification.length) ||
                        (entity.merchants && !entity.merchants.length)
                    ) {
                        AntMessage.warning(language.getText('qwsspphxx'));
                        return false;
                    }
                }

                let errorText = '';
                //条件标签-行为条件
                if (
                    labelType === LabelTypeMode.CONDITION &&
                    conditionType === ConditionTypeMode.BEHAVIOR
                ) {
                    let behavioralConditions = get(entity, 'behavioralConditions', []) || [];
                    let behavioralArr = behavioralConditions.filter((item: any) => item.checked);
                    for (let i = 0; i < behavioralArr.length; i++) {
                        if (behavioralArr[i].code === 'BEHAVIOR') {
                            if (
                                !behavioralArr[i].selectValue ||
                                isNil(behavioralArr[i]?.otherValues?.min) ||
                                isNil(behavioralArr[i]?.otherValues?.max)
                            ) {
                                errorText = language.getText('qwsxwtjxx');
                            }
                            if (
                                behavioralArr[i].otherValues &&
                                behavioralArr[i].otherValues.max &&
                                behavioralArr[i].otherValues.min &&
                                Number(behavioralArr[i].otherValues.max) <
                                    Number(behavioralArr[i].otherValues.min)
                            ) {
                                errorText = language.getText('szdzxzbndyzdz');
                            }
                        }
                    }
                    if (!behavioralArr.length) {
                        AntMessage.warning(services.language.getText('qxzxwtj'));
                        return false;
                    }
                    if (errorText) {
                        AntMessage.warning(errorText);
                        return false;
                    }
                }

                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(language.getText('actionSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/label-manage',
                    component: 'LabelManageView',
                    breadcrumbName: '<<labelManage>>',
                    privilege: {
                        path: 'memberv2Tag',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'LabelManageAddPage',
                            breadcrumbName: '<<addTags>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'LabelManageEditPage',
                            breadcrumbName: '<<editTag>>',
                        },
                        { path: '/', component: 'LabelManagePageView' },
                    ],
                },
            ],
        },
    ],
};
