import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { api } from '@comall-backend-builder/core/lib/services';

export const SubsiteMineSourceLoader: Loader = {
    get(params, config) {
        params.page = 1;
        params.perPage = 10000;
        config.apiPath = '/admin/v1/subsites/mine';
        return api.get(params, config).then((res: any) => res.result);
    },
};
