import React, { PureComponent } from 'react';
import { Input, Form } from 'antd';
import { tools } from '@comall-backend-builder/design-components-basis';
import './index.less';
import { services } from '@comall-backend-builder/core';

const FormItem = Form.Item;
/**
 * 会员分销组件-配置表单
 */
class memberDistribution extends PureComponent<any> {
    render() {
        const { form } = this.props;

        const { getFieldDecorator } = form;
        const formItemLayout = tools.FormItemLayout;
        return (
            <Form className="editor-member-distribution">
                <FormItem {...formItemLayout} label="显示设置">
                    <div className="field-tips">仅分销员可见</div>
                </FormItem>
                <FormItem {...formItemLayout} label={'菜单名称'}>
                    <div>
                        {getFieldDecorator(
                            'title',
                            {}
                        )(<Input maxLength={10} style={{ width: 190 }} />)}
                    </div>
                </FormItem>
                <FormItem {...formItemLayout} label={'引导文案'}>
                    <div>
                        {getFieldDecorator(
                            'secondary',
                            {}
                        )(<Input maxLength={10} style={{ width: 190 }} />)}
                    </div>
                </FormItem>
                <div className="distribution-tips">{services.language.getText('cymzswgdys')}</div>
            </Form>
        );
    }
}

export const MemberDistribution = tools.ConfigFormDecorator(memberDistribution);
