import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { MallActivitySelect } from '../../../containers';
import { ActivitySelectedDisplay } from '../../../components';

export class MallActivityMode extends modes.ArrayMode {
    getDisplayComponent(value: any) {
        return (
            <ActivitySelectedDisplay
                data={value}
                onRemove={() => {}}
                disabled={true}
            ></ActivitySelectedDisplay>
        );
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <MallActivitySelect {...displayInfo} />;
    }
}
