import React, { PureComponent, createElement } from 'react';
import { map, isEqual, isEmpty, isNull } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Modal } from 'antd';
import './index.less';

interface Value {
    id: string;
    name: string;
    select: boolean;
}

const TableComponentId = 'TopicSeletorTable';
let Entity: any = {};
let entity: any = {};

/**
 * 选择专题页
 */
export class TopicSeletor extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('TopicManageEntity');
        entity = new Entity();
        this.state = {
            value: null,
            visible: false,
        };
    }

    componentWillReceiveProps(nextProps: any) {
        const nextSubsiteIds = this.getSubsiteIds(nextProps);
        const currentSubsiteIds = this.getSubsiteIds(this.props);
        if (!isEqual(nextSubsiteIds, currentSubsiteIds) && !isEmpty(nextProps.value)) {
            this.processDataAfterSubsiteChange(nextSubsiteIds, nextProps);
        }
    }

    processDataAfterSubsiteChange = (subsiteIds: string[] | null, props: any) => {
        const { value, onChange } = props;
        if (isNull(subsiteIds)) {
            this.setState({ value: null });
            onChange && onChange(null);
        } else if (value) {
            const index = value?.subsites?.findIndex((subsite: any) => {
                return !!subsiteIds.find((id) => id === subsite.id);
            });
            if (index === -1) {
                this.setState({ value: null });
                onChange && onChange(null);
            }
        }
    };
    getSubsiteIds = (props: any) => {
        const { row } = props;
        const subsiteIds =
            row && row.baseInfo && row.baseInfo.subsites ? map(row.baseInfo.subsites, 'id') : null;
        return subsiteIds;
    };
    dispatch: any = {};

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(entity));
    }

    onTableRowSelect = (record: Value, selected: boolean) => {
        this.setState({
            value: record,
        });
    };

    onOk = () => {
        const { onChange } = this.props;
        const { value } = this.state;
        if (onChange) {
            onChange(value);
            this.toggleModal();
        }
    };

    getConfig = () => {
        const { selectValues, getParams } = this.props;
        let params: any = {};
        if (getParams && typeof getParams === 'function') {
            params = getParams(this.props);
        }
        const subsiteIds = this.getSubsiteIds(this.props);
        if (subsiteIds) {
            params.subsiteIds = subsiteIds;
        }
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: params,
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    // style: { textAlign: 'right' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        entity.search(params);
                    },
                    fields: [
                        {
                            property: 'name',
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    scroll: {
                        y: 600,
                    },
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    rowSelection: {
                        type: 'radio',
                        selectedRowKeys: map(selectValues, 'id'),
                        onSelect: (record: Value, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    columns: [
                        {
                            property: 'name',
                        },
                        {
                            property: 'channels',
                        },
                    ],
                },
            ],
        };
    };

    toggleModal = () => {
        const { visible } = this.state;
        const { params } = this.props;
        const subsiteIds = this.getSubsiteIds(this.props);
        if (subsiteIds) {
            params.subsiteIds = subsiteIds;
        }
        this.setState({ visible: !visible }, () => {
            if (!visible && entity.paging) {
                entity.paging.page = 1;
                entity.search({
                    ...entity.paging,
                    ...params,
                });
            }
        });
    };

    render() {
        const { value } = this.props;
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 900,
            title: services.language.getText('xzzty'),
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };

        return (
            <div className="topic-selector" style={{ display: 'inline-block' }}>
                <span>{value ? value.name : services.language.getText('notSelect')}</span>
                <span onClick={this.toggleModal} className="select-text">
                    {!!value
                        ? services.language.getText('common.modify')
                        : services.language.getText('selected')}
                </span>
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}
