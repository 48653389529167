import { SelectAutoComplete } from '@comall-backend-builder/components-basis';
import { LabeledValue } from 'antd/lib/select';
import { isArray, isNil, map } from 'lodash';
export class SelectAutoCompletePlus extends SelectAutoComplete {
    handleChange(value: LabeledValue | LabeledValue[] | undefined): void {
        const { name, onChange, options } = this.props;
        // @ts-ignore
        if (onChange) {
            let newValue: any = [];
            if (isArray(value)) {
                newValue = map(value, ({ key }) => options.find(({ id }) => id === key));
            } else if (!isNil(value)) {
                newValue = options.find(({ id }) => id === value.key);
            }
            onChange(newValue, name);
        }
    }
}
