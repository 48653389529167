import React, { Component } from 'react';
import { Row as AntRow, InputNumber, Button, Checkbox, Popconfirm, Select } from 'antd';
import { clone, map, concat, isEmpty, isEqual, cloneDeep } from 'lodash';
import { api, language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';
import { Coupon } from '../../../../containers';
import { StoredValueCardActivityRewardItem } from './stored-value-card-activity-reward-item';
import './index.less';

const { getText } = services.language;

const { Option } = Select;

interface StoredValueCardRewardObject {
    productId: number;
    /**
     * 奖励内容
     */
    rewards: StoredValueCardRewardValue[];
}

interface StoredValueCardRewardValue {
    /**
     * 门槛值
     */
    lowerValue?: number;
    /**
     * 阶梯值
     */
    stepValue?: number;
    /**
     * 积分
     */
    pointChecked?: boolean;
    point?: number;
    /**
     * 优惠券
     */
    couponsChecked?: boolean;
    couponsValue?: any;
    id?: string;
}

interface ActivityRewardCheckinStates {
    options: any[];
}

/**
 * 奖励内容枚举
 */
enum RewardItems {
    stepValue = 'STEPVALUE',
    lowerValue = 'LOWERVALUE',
    point = 'POINT',
    coupons = 'COUPON',
}

export interface ActivityRewardCheckinProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    value: StoredValueCardRewardObject[] | null;

    /**
     * 是否禁用（禁用只限制修改阶梯值、赠删阶梯，不限制修改阶梯内奖励内容）
     */
    disabled: boolean;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: StoredValueCardRewardObject[] | null) => void;
    /**
     * 可添加的最大数量
     */
    maxCount?: number | undefined;
}

const defalutValue = {
    productId: -1,
    rewards: [
        {
            lowerValue: undefined,
            stepValue: undefined,
            pointChecked: false,
            point: undefined,
            couponsChecked: false,
            couponsValue: [],
        },
    ],
};

export class StoredValueCardReward extends Component<
    ActivityRewardCheckinProps,
    ActivityRewardCheckinStates
> {
    constructor(props: any) {
        super(props);

        this.state = {
            options: [],
        };
    }

    componentDidMount() {
        let { value } = this.props;
        if (!value) {
            this.onChangeDefalutValue();
        }
        this.loadProducts();
    }

    loadProducts = () => {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: `/admin/stored_value_card_products`,
            }
        ).then((response: any) => {
            if (response && response.result) {
                this.setState({
                    options: response.result,
                });
            }
        });
    };

    componentWillReceiveProps(nextProps: any) {
        const nextRewardMethod = this.getRewardMethod(nextProps);
        const currentRewardMethod = this.getRewardMethod(this.props);
        if (
            !isEmpty(currentRewardMethod) &&
            !isEmpty(nextRewardMethod) &&
            !isEqual(nextRewardMethod, currentRewardMethod)
        ) {
            this.onChangeDefalutValue();
        }
    }

    onChangeDefalutValue = () => {
        const { onChange } = this.props;
        onChange(cloneDeep([defalutValue]));
    };

    onAddReward(iindex: number) {
        let { value, onChange } = this.props;
        if (!value) return;
        let rewards = value[iindex].rewards;
        rewards.push({
            lowerValue: undefined,
            stepValue: undefined,
            pointChecked: false,
            point: undefined,
            couponsChecked: false,
            couponsValue: [],
        });
        value[iindex].rewards = rewards;
        if (onChange) {
            onChange(cloneDeep(value));
        }
    }

    onRemoveReward = (iindex: number, jindex: number) => {
        let { value, onChange } = this.props;
        if (!value) return;
        let rewards = value[iindex].rewards;
        rewards.splice(jindex, 1);
        if (onChange) {
            onChange(cloneDeep(value));
        }
    };

    onInputValueChange(event: any, iindex: number, jindex: number, type: string) {
        let { value, onChange } = this.props;
        if (!value) return;
        let rewards = value[iindex].rewards;
        let inputValue = event;
        // var reg = /^\+?[0-9]\d*$/;
        // if (inputValue !== null && inputValue !== '' && !reg.test(inputValue)) {
        //    AntMessage.warning(services.language.getText('inputNumber'));
        //     inputValue = null;
        // }
        if (rewards !== null && !isEmpty(rewards)) {
            let item = clone(rewards[jindex]);
            if (type === RewardItems.lowerValue) {
                item.lowerValue = inputValue;
            }
            if (type === RewardItems.stepValue) {
                item.stepValue = inputValue;
            }
            if (type === RewardItems.point) {
                item.point = inputValue;
            }
            rewards = concat([], rewards);
            rewards.splice(jindex, 1, item);
        } else {
            let item: StoredValueCardRewardValue = { couponsValue: [] };
            if (type === RewardItems.lowerValue) {
                item.lowerValue = inputValue;
            }
            if (type === RewardItems.stepValue) {
                item.stepValue = inputValue;
            }
            if (type === RewardItems.point) {
                item.point = inputValue;
            }
            rewards = concat([], item);
        }
        value[iindex].rewards = rewards;
        if (onChange) {
            onChange(cloneDeep(value));
        }
    }

    onCheckboxChange(event: any, iindex: number, jindex: number, type: string) {
        let { value, onChange } = this.props;
        if (!value) return;
        let rewards = value[iindex].rewards;
        let checkboxValue = event.target.checked;
        if (rewards !== null && !isEmpty(rewards)) {
            let item = clone(rewards[jindex]);
            if (type === RewardItems.point) {
                item.pointChecked = checkboxValue;
            }
            if (type === RewardItems.coupons) {
                item.couponsChecked = checkboxValue;
            }
            rewards = concat([], rewards);
            rewards.splice(jindex, 1, item);
        } else {
            let item: StoredValueCardRewardValue = { couponsValue: [] };
            if (type === RewardItems.point) {
                item.pointChecked = checkboxValue;
            }
            if (type === RewardItems.coupons) {
                item.couponsChecked = checkboxValue;
            }
            rewards = concat([], item);
        }
        value[iindex].rewards = rewards;
        if (onChange) {
            onChange(cloneDeep(value));
        }
    }

    onChangeCoupon = (products: Coupon[], iindex: number, jindex: number) => {
        let { value, onChange } = this.props;
        if (!value) return;
        const rewards = value[iindex].rewards;
        rewards[jindex].couponsValue = products;
        if (onChange) {
            onChange(cloneDeep(value));
        }
    };

    renderReward(reward: StoredValueCardRewardValue, iindex: number, jindex: number) {
        // const subsiteIds = this.getSubsiteIds(this.props); //产品不要根据门店查询
        const rewardMethod = this.getRewardMethod(this.props);
        const style = {
            marginLeft: rewardMethod === 'ORDER_AMOUNT' ? '0' : '63px',
        };
        return (
            <div className="stored-value-card-reward-item" key={jindex} style={style}>
                <AntRow>
                    <div className="reward-content">
                        <div className="reward-setting">
                            <span className="reward-item-text">{getText('ddmjje')}：</span>
                            <div className="reward-count-input">
                                <InputNumber
                                    type="number"
                                    min={0}
                                    max={999999.99}
                                    step={0.01}
                                    precision={2}
                                    value={reward.lowerValue}
                                    onChange={(e) =>
                                        this.onInputValueChange(
                                            e,
                                            iindex,
                                            jindex,
                                            RewardItems.lowerValue
                                        )
                                    }
                                />
                            </div>
                            <span className="reward-item-text">{getText('mm_1')}</span>
                            <div className="reward-count-input">
                                <InputNumber
                                    type="number"
                                    min={0.01}
                                    max={999999.99}
                                    step={0.01}
                                    precision={2}
                                    value={reward.stepValue}
                                    onChange={(e) =>
                                        this.onInputValueChange(
                                            e,
                                            iindex,
                                            jindex,
                                            RewardItems.stepValue
                                        )
                                    }
                                />
                            </div>
                            <span className="reward-item-text">{getText('khdyxjl')}</span>
                        </div>
                        <Popconfirm
                            onConfirm={this.onRemoveReward.bind(this, iindex, jindex)}
                            title={services.language.getText('sfscggz')}
                            okText={language.getText('common.ok')}
                            cancelText={language.getText('common.cancel')}
                        >
                            <Button type="default" className="reward-remove-reward-button">
                                {getText('scgz')}
                            </Button>
                        </Popconfirm>
                    </div>
                </AntRow>

                {/*<div className="reward-content-list">*/}
                {/*    <div className="reward-content-item">*/}
                {/*        <Checkbox*/}
                {/*            className="reward-content-item-checkbox"*/}
                {/*            onChange={(e) =>*/}
                {/*                this.onCheckboxChange(e, iindex, jindex, RewardItems.point)*/}
                {/*            }*/}
                {/*            checked={reward.pointChecked}*/}
                {/*            value="point"*/}
                {/*        >*/}
                {/*            积分*/}
                {/*        </Checkbox>*/}
                {/*        <span className="reward-item-text">送</span>*/}
                {/*        <InputNumber*/}
                {/*            type="number"*/}
                {/*            min={1}*/}
                {/*            value={reward.point}*/}
                {/*            onChange={(e) =>*/}
                {/*                this.onInputValueChange(e, iindex, jindex, RewardItems.point)*/}
                {/*            }*/}
                {/*        />*/}
                {/*        <span className="reward-item-text">积分</span>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="reward-content-list">
                    <div className="reward-content-item">
                        <Checkbox
                            className="reward-content-item-checkbox"
                            onChange={(e) =>
                                this.onCheckboxChange(e, iindex, jindex, RewardItems.coupons)
                            }
                            checked={reward.couponsChecked}
                            value="coupons"
                        >
                            {getText('yhq')}
                        </Checkbox>
                    </div>
                    <div className="reward-coupons">
                        <StoredValueCardActivityRewardItem
                            iindex={iindex}
                            jindex={jindex}
                            value={reward.couponsValue || []}
                            subsiteIds=""
                            onChangeCoupon={this.onChangeCoupon}
                            type="checkbox"
                            packageSelectType="radio"
                            canChangeQuantity
                            hideIssuedNum
                            showLeftStock
                        />
                    </div>
                </div>
            </div>
        );
    }

    getSubsiteIds = (props: any) => {
        const { row } = props;
        const subsiteIds =
            row.baseInfo && row.baseInfo.subsiteIds ? map(row.baseInfo.subsiteIds, 'id') : null;
        return subsiteIds;
    };

    getRewardMethod = (props: any) => {
        const { row } = props;
        const rewardMethod = row?.rewardInfo?.rewardMethod;
        return rewardMethod;
    };

    onChangeProductId = (productId: number, iindex: number) => {
        let { value, onChange } = this.props;
        if (value) {
            value[iindex].productId = productId;
            onChange(cloneDeep(value));
        }
    };
    onAddItem = () => {
        const { value, onChange } = this.props;
        if (value) {
            value.splice(value.length, 0, { ...defalutValue });
            onChange(cloneDeep(value));
        }
    };

    onRemoveItem = (iindex: number) => {
        let { value, onChange } = this.props;
        if (!value) return;
        value.splice(iindex, 1);
        if (onChange) {
            onChange(cloneDeep(value));
        }
    };

    render() {
        let { value } = this.props;
        const { options } = this.state;
        const rewardMethod = this.getRewardMethod(this.props);
        const style = {
            marginLeft: rewardMethod === 'ORDER_AMOUNT' ? '0' : '63px',
        };
        return (
            <div className="stored-value-card-reward">
                {value &&
                    value.map((item, iindex) => {
                        return (
                            <div key={iindex} className="item">
                                {rewardMethod === 'PRODUCT' && (
                                    <div className="item-top">
                                        <div>
                                            <span className="red">*</span>
                                            {getText('klb')}：
                                        </div>
                                        <Select
                                            value={item.productId > 0 ? item.productId : undefined}
                                            style={{ width: '200px' }}
                                            onChange={(e: any) => this.onChangeProductId(e, iindex)}
                                            placeholder={getText('qxzmyklb')}
                                        >
                                            {map(options, (item) => {
                                                return (
                                                    <Option key={item.id} value={item.id}>
                                                        {item.name}
                                                    </Option>
                                                );
                                            })}
                                        </Select>
                                        <Popconfirm
                                            onConfirm={this.onRemoveItem.bind(this, iindex)}
                                            title={getText('sfscgklb')}
                                            okText={language.getText('common.ok')}
                                            cancelText={language.getText('common.cancel')}
                                        >
                                            <Button
                                                type="default"
                                                className="reward-remove-reward-button"
                                            >
                                                {getText('scklb')}
                                            </Button>
                                        </Popconfirm>
                                    </div>
                                )}
                                {item.rewards.map((reward, jindex) => {
                                    return <div>{this.renderReward(reward, iindex, jindex)}</div>;
                                })}
                                <Button
                                    style={style}
                                    type="default"
                                    className="reward-add-reward-button"
                                    onClick={() => this.onAddReward(iindex)}
                                >
                                    {getText('addRule')}
                                </Button>
                            </div>
                        );
                    })}
                {rewardMethod === 'PRODUCT' && (
                    <Button
                        type="default"
                        className="reward-add-reward-button"
                        onClick={() => this.onAddItem()}
                    >
                        {getText('tjklb')}
                    </Button>
                )}
            </div>
        );
    }
}
