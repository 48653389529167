import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { InvoiceSystem } from './invoice-system';

export class InvoiceSystemMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <InvoiceSystem {...controlInfo} />;
    }
}
