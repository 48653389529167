import React from 'react';

import { ArrayMode } from '@comall-backend-builder/types';
import { MaotaiPresaleConditions } from '../../../containers';

export class MaotaiPresaleConditionsMode extends ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <MaotaiPresaleConditions {...controlInfo} />;
    }
}
