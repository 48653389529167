import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;

export const MemberCardDiscountLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const { id } = data;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`;
        config.apiPath = '/admin/member_card_discount_activities';
        if (id) {
            config.apiPath = `/admin/member_card_discount_activities/${id}`;
        }
        if (data.executeStatus === 'ALL') {
            delete data.executeStatus;
        }
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }

        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.baseInfo = {
                    name: res.name,
                    dateRange: {
                        start: res.startTime,
                        end: res.endTime,
                    },
                    effectivePeriodType: res.effectivePeriod.effectivePeriodType,
                    dayPeriodCondition: res.effectivePeriod.dayPeriodCondition,
                    weekPeriodCondition: res.effectivePeriod.weekPeriodCondition
                        ? res.effectivePeriod.weekPeriodCondition.values.map((v: any) =>
                              v.toString()
                          )
                        : [],
                };
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : services.language.getText('longTerm'),
                    };
                }
                res.ruleInfo = {
                    selectMode: res.subsites && res.subsites.length > 0 ? 'SUB_SITE' : 'MERCHANT',
                    merchants: res.merchants.map((merchant: any) => {
                        return {
                            ...merchant,
                            merchantId: merchant.id,
                            merchantName: merchant.merchantName,
                        };
                    }),
                    subsites: res.subsites,
                    activityProductType: res.conditionGoods.goodsConditionType,
                    memberCardDiscountInfos: res.memberCardDiscountInfos.map((item: any) => {
                        return {
                            ...item,
                            name: item.memberCardLevelName,
                        };
                    }),
                    restrictionsType: res.restrictionCondition.restrictionConditionType,
                    restrictions: res.restrictionCondition.value,
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };
                if (
                    res.conditionGoods &&
                    res.conditionGoods.goods &&
                    res.conditionGoods.goods.length > 0
                ) {
                    res.ruleInfo.products = {
                        type: 'APPOINT_PRODUCT',
                        values: res.conditionGoods.goods.map((item: any) => {
                            return {
                                ...item,
                                name: item.productName,
                                id: item.id,
                                code: item.productCode,
                            };
                        }),
                    };
                }
            }
            return res;
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`;
        config.apiPath = `/admin/member_card_discount_activities/${id}`;
        const newParams = dataParser(params);
        return await api.put(newParams, config);
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`;
        config.apiPath = '/admin/member_card_discount_activities';
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    const { baseInfo, ruleInfo } = params;
    const newParams: any = {};
    if (baseInfo) {
        newParams.name = baseInfo.name;
        newParams.startTime = baseInfo.dateRange.start;
        newParams.endTime = baseInfo.dateRange.end;
        newParams.effectivePeriod = {
            effectivePeriodType: baseInfo.effectivePeriodType,
            dayPeriodCondition: baseInfo.dayPeriodCondition,
            weekPeriodCondition: {
                values: baseInfo.weekPeriodCondition,
            },
        };
    }
    if (ruleInfo) {
        newParams.conditionGoods = {
            goodsConditionType: ruleInfo.activityProductType,
            goodsIds:
                ruleInfo.products && ruleInfo.products.values
                    ? ruleInfo.products.values.map((value: any) => value.id)
                    : [],
        };
        const selectMode = params.ruleInfo.selectMode;
        if (selectMode === 'SUB_SITE' && !isEmpty(params.ruleInfo.subsites)) {
            newParams.subsiteIds = params.ruleInfo.subsites.map((subsite: any) => subsite.id);
        }
        if (selectMode === 'MERCHANT' && !isEmpty(params.ruleInfo.merchants)) {
            newParams.merchants = params.ruleInfo.merchants.map((merchant: any) => ({
                subsiteId: merchant.subSiteId,
                merchantId: merchant.merchantId,
            }));
        }
        if (selectMode === 'TAG' && !isEmpty(params.ruleInfo.tags)) {
            newParams.merchants = params.ruleInfo.tags.map((tag: any) => ({
                subsiteId: tag.subsiteId,
                merchantId: tag.id,
            }));
        }
        newParams.restrictionCondition = {
            restrictionConditionType: ruleInfo.restrictionsType,
            value: ruleInfo.restrictionsType === 'MONEY' ? ruleInfo.restrictions : null,
        };
        newParams.memberCardDiscountInfos = ruleInfo.memberCardDiscountInfos;
        newParams.marketingScheduleId =
            (ruleInfo.marketingSchedule?.length && ruleInfo.marketingSchedule[0].id) || null;
    }
    return newParams;
}
