import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';

import { TipsModalContentType } from '../../../components';

const illustrates: { [str: string]: any } = {
    SUPERPOSITION: '<<jzjcgzzsrtsfhdgjbgz>>',
    OPTIMAL: '<<jzjcgzzszxsqgbsgzzx>>',
};

export const config: Config = {
    entities: {
        ExperienceRuleEntity: {
            apiPath: '/loader/admin/experience_rules',
            filters: {
                subsiteId: {
                    type: 'object.option.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                subsiteName: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                    rules: [{ required: true }],
                },
                subsites: {
                    type: 'array.subsites.selector',
                    displayName: '<<otherSelectSubsite>>',
                    rules: [{ required: true }],
                },
                desc: {
                    type: 'string.richTextPlus',
                    displayName: '<<jyzsm>>',
                    controlConfig: {
                        toolbarId: 'descriptionRichText',
                        style: {
                            height: 300,
                        },
                        uploadConfig: {
                            apiPath: `/admin/images/upload`,
                            fileName: 'fileName',
                        },
                    },
                    rules: [
                        {
                            max: 20000,
                            message: '<<notMoreThan2000>>',
                        },
                    ],
                },
                doubleRuleParticipateCardinalityCalculate: {
                    type: 'string.radioWithModalTip',
                    displayName: '<<jcgzbhdjjb>>',
                    defaultValue: 'false',
                    options: [
                        {
                            id: 'false',
                            name: '<<bbh>>',
                        },
                        {
                            id: 'true',
                            name: '<<bh>>',
                        },
                    ],
                    controlConfig: {
                        tipsModalConfig: {
                            contentType: TipsModalContentType.ExprienceDoubleRule,
                            showIcon: true,
                            title: '<<jbgzsmjdyjsgs>>',
                            width: 648,
                        },
                    },
                    rules: [{ required: true }],
                },
                coefficientRuleExecuteType: {
                    type: 'string.radioIllustrate',
                    displayName: '<<gwsjyzjbgz>>',
                    defaultValue: 'OPTIMAL',
                    name: JSON.stringify(illustrates),
                    options: [
                        { id: 'SUPERPOSITION', name: '<<jbdj>>' },
                        { id: 'OPTIMAL', name: '<<hcqy>>' },
                    ],
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    controlConfig: {
                        illustrate: illustrates,
                        style: { display: 'flex', flexDirection: 'column', paddingTop: 9 },
                    },
                },
                navigationExpreienceBenefit: {
                    type: 'string.navigationLink',
                    displayName: '<<experienceRightsAndInterests>>',
                    controlConfig: {
                        text: '>><<qsz_1>>',
                        href: '#/experience-benefit',
                    },
                },
                navigationBirthdayCaring: {
                    type: 'string.navigationLink',
                    displayName: '<<birthdayCaring>>',
                    controlConfig: {
                        text: '>><<qsz_1>>',
                        href: '#/birthday-caring',
                    },
                },
                navigationFestivalMarketing: {
                    type: 'string.navigationLink',
                    displayName: '<<festivalMarketing>>',
                    controlConfig: {
                        text: '>><<qsz_1>>',
                        href: '#/festival-marketing',
                    },
                },
            },
        },
    },
    components: {
        ExperienceRuleView: {
            component: 'Viewport',
            entity: 'ExperienceRuleEntity',
        },
        ExperienceRulePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'ExperienceRuleFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'horizontal',
                    justify: 'justify',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'Button',
                            text: '<<xzjyzgz>>',
                            route: '/experience-rule/add',
                            type: 'primary',
                            icon: 'plus',
                            style: {
                                width: 150,
                                flex: 0,
                            },
                        },
                        {
                            component: 'NavigationLink',
                            text: '<<szjyzzdymc>>',
                            href: '#/common-setting',
                            privilege: {
                                path: 'commonSetting',
                                level: 'full',
                            },
                            style: {},
                        },
                    ],
                },
                { component: 'ExperienceRuleTable' },
            ],
        },
        ExperienceRuleFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                },
            ],
        },
        ExperienceRuleTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'ExperienceRuleDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'subsiteName',
                            displayConfig: {
                                countSuffix: '<<subsites>>',
                                style: {
                                    maxWidth: '200px',
                                },
                            },
                        },
                        // {
                        //     property: 'desc',
                        //     displayConfig: {
                        //         style: {
                        //             maxWidth: 500,
                        //             height: 80,
                        //         },
                        //     },
                        // },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        items: [
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                path: '/experience-rule/edit/{{row.id}}',
                            },
                            {
                                type: 'delete',
                                text: '<<common.delete>>',
                                config: {
                                    type: 'link',
                                    tooltip: null,
                                    title: '<<confirmDelete>>',
                                    okText: '<<common.ok>>',
                                    cancelText: '<<common.cancel>>',
                                },
                            },
                        ],
                    },
                },
            ],
        },
        ExperienceRuleAddPage: {
            component: 'Card',
            content: { component: 'ExperienceRuleAddForm' },
        },
        ExperienceRuleAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 16,
            style: { width: 900 },
            fields: [
                {
                    property: 'subsites',
                    extra: '<<xzdmdwjyzgydmd>>',
                },
                { property: 'desc' },
                {
                    property: 'doubleRuleParticipateCardinalityCalculate',
                },
                {
                    property: 'coefficientRuleExecuteType',
                },
                {
                    property: 'navigationExpreienceBenefit',
                },
                {
                    property: 'navigationBirthdayCaring',
                },
                {
                    property: 'navigationFestivalMarketing',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },

            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        ExperienceRuleEditPage: {
            component: 'Card',
            content: { component: 'ExperienceRuleEditForm' },
        },
        ExperienceRuleEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'ExperienceRuleEntity',
            loaderType: 'get',
            labelCol: 6,
            controlCol: 16,
            style: { width: 900 },
            fields: [
                {
                    property: 'subsites',
                    extra: '<<xzdmdwjyzgydmd>>',
                },
                { property: 'desc' },
                {
                    property: 'doubleRuleParticipateCardinalityCalculate',
                },
                {
                    property: 'coefficientRuleExecuteType',
                },
                {
                    property: 'navigationExpreienceBenefit',
                },
                {
                    property: 'navigationBirthdayCaring',
                },
                {
                    property: 'navigationFestivalMarketing',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/experience-rule',
                    component: 'ExperienceRuleView',
                    breadcrumbName: '<<experienceRule>>',
                    privilege: {
                        path: 'experienceRule',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add/',
                            component: 'ExperienceRuleAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'ExperienceRuleEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },

                        { path: '/', component: 'ExperienceRulePage' },
                    ],
                },
            ],
        },
    ],
};
