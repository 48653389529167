import React from 'react';
import { connect } from 'react-redux';
import './index.less';
import { get, isNull } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';
const prefix = 'checkin-activity-preview';
const innerClassNames = {
    top: `${prefix}__tpp`,
    title: `${prefix}__title`,
    box: `${prefix}__box`,
    calendar: `${prefix}__calendar`,
    reward: `${prefix}__reward`,
    rewardTitle: `${prefix}__reward__title`,
    rewardList: `${prefix}__reward__list`,
    rewardItem: `${prefix}__reward__item`,
    rewardItemTitle: `${prefix}__reward__item__title`,
    rewardItemIcon: `${prefix}__reward__item__icon`,
    rewardItemValue: `${prefix}__reward__item__value`,
};
/**
 * 全民分销预览效果
 * @param props
 */
const popRecruitDistributorPreview = (props: any) => {
    const { preview = {} } = props;
    const backgroundImageUrl =
        preview &&
        preview.pictureInfo &&
        preview.pictureInfo.backgroundPicture &&
        preview.pictureInfo.backgroundPicture.length > 0
            ? preview.pictureInfo.backgroundPicture[0].path
            : '';
    const backgroundImage: any = {
        backgroundImage: backgroundImageUrl ? `url(${backgroundImageUrl})` : '',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '100%',
    };
    const name = preview?.baseInfo.name || '';
    return (
        <div className={prefix}>
            <div className={innerClassNames.top}>
                <img alt="" width="100%" src="./images/avatar/theme-head.png" />
                <div className={innerClassNames.title}>{name}</div>
            </div>
            {backgroundImage && (
                <div className={innerClassNames.box} style={backgroundImage}>
                    {rednerCalendar()}
                    {renderSignList(preview)}
                </div>
            )}
        </div>
    );
};

const rednerCalendar = () => {
    const url = require('./checkin-activity-calendar.png');
    return <img className={innerClassNames.calendar} src={url} alt="" />;
};

const renderSignList = (preview: any) => {
    if (!preview || isNull(preview?.continuousCheckin)) {
        return null;
    }
    return (
        <div className={innerClassNames.reward}>
            <div className={innerClassNames.rewardTitle}>
                {preview.continuousCheckin ? language.getText('lx_2') : language.getText('lj_1')}
                签到获得以下奖励
            </div>
            {preview.values && preview.values.length > 0 ? (
                <div className={innerClassNames.rewardList}>
                    {preview.values.map((item: any, index: number) => {
                        return renderReward(preview.continuousCheckin, item, index);
                    })}
                </div>
            ) : null}
        </div>
    );
};

const formatData = (reward: any) => {
    const {
        point,
        experience,
        goldValue,
        goldValueTxtName = language.getText('jb'),
        couponsValue = [],
        pointChecked,
        experienceChecked,
        goldValueChecked,
        couponsChecked,
    } = reward;
    const moneyIconImage =
        'https://puzhehei.oss-cn-beijing.aliyuncs.com/global/icon-signin-money.png';
    const pointIconImage =
        'https://puzhehei.oss-cn-beijing.aliyuncs.com/global/icon-signin-point.png';
    const giftIconImage =
        'https://puzhehei.oss-cn-beijing.aliyuncs.com/global/icon-signin-gift.png';
    if (
        couponsChecked &&
        couponsValue.length > 0 &&
        (pointChecked || experienceChecked || goldValueChecked)
    ) {
        return {
            icon: giftIconImage,
            text: language.getText('zhjl'),
        };
    }
    if (pointChecked) {
        return {
            icon: pointIconImage,
            text: `${point}` + language.getText('point'),
        };
    }
    if (experienceChecked) {
        return {
            icon: pointIconImage,
            text: `${experience}` + language.getText('experienceManage'),
        };
    }
    if (goldValueChecked) {
        return {
            icon: pointIconImage,
            text: `${goldValue}${goldValueTxtName}`,
        };
    }
    if (couponsChecked && couponsValue.length > 0) {
        let totalCount = 0;
        couponsValue.forEach((it: any) => (totalCount += Number(it.quantity)));
        return {
            icon: moneyIconImage,
            text: services.interpolate(language.getText('hasMuchCoupon'), { totalCount }),
        };
    }
    return { icon: '', text: '' };
};

const renderReward = (continuousCheckin: boolean, item: any, index: number) => {
    const { icon, text } = formatData(item);
    return (
        <div className={innerClassNames.rewardItem}>
            <div className={innerClassNames.rewardItemTitle}>
                {continuousCheckin ? language.getText('lx_2') : language.getText('lj_1')}
                {language.getText('qd')}
                {item.stepValue}
                {language.getText('day')}
            </div>
            <img src={icon} className={innerClassNames.rewardItemIcon} alt="" />
            <div className={innerClassNames.rewardItemValue}>{text}</div>
        </div>
    );
};

const mapStateToProps = (_state: any, props: any) => {
    let preview = null;
    const entityId = props.entity.id;
    for (var compId in _state.components) {
        const comp = _state.components[compId];
        if (
            (comp.type === 'CreateForm' || comp.type === 'EditForm') &&
            comp.entityId === entityId
        ) {
            preview = comp.fields;
        }
    }
    if (preview) {
        const totalRewardRule: any = get(preview, 'totalRewardRule', {
            continuousCheckin: null,
            values: [],
        });
        preview = {
            ...preview,
            continuousCheckin: totalRewardRule.continuousCheckin,
            values: totalRewardRule.values,
        };
    }
    return { preview };
};

export const CheckinActivityPreview = connect(mapStateToProps)(popRecruitDistributorPreview);
