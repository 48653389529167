import { Config } from '@comall-backend-builder/core/lib/parser';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';
import get from 'lodash/get';

enum ArticleStatus {
    /**
     * 启用
     */
    ENABLED = 1,
    /**
     * 禁用
     */
    DISABLED = 0,
}

export const config: Config = {
    entities: {
        ArticleManageEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/article_manage',
            filters: {
                subSiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                businessTypeId: {
                    type: 'array.article.type',
                    displayName: '<<ywlx>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<wzmc_1>>',
                    controlConfig: { placeholder: '<<wzmcInputPlease>>' },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                    displayName: '<<wzmc_1>>',
                },
                businessTypeStr: {
                    type: 'string',
                    displayName: '<<ywlx>>',
                },
                sequence: {
                    type: 'string',
                    displayName: '<<sx>>',
                },
                subSites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                fromTimeStr: {
                    type: 'string',
                    displayName: '<<sxkssj>>',
                },
                thruTimeStr: {
                    type: 'string',
                    displayName: '<<sxjssj>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<status>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<wzmc_1>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<wzmcInputPlease>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 300,
                                },
                            },
                        },
                        businessType: {
                            type: 'array.article.type',
                            displayName: '<<ywlx>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                showtip: true,
                                style: {
                                    width: 300,
                                },
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<effectiveTime>>',
                            controlConfig: {
                                style: {
                                    width: 300,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subSiteIds: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            rules: [{ required: true }],
                        },
                        version: {
                            type: 'string',
                            displayName: '<<versions>>',
                            extra: '<<versionsTip>>',
                            controlConfig: {
                                showtip: true,
                                style: {
                                    width: 300,
                                },
                            },
                        },
                        sequence: {
                            displayName: '<<wzsx>>',
                            type: 'number.integerInputTitleTip',
                            defaultValue: 1,
                            controlConfig: {
                                rules: {
                                    type: 'number',
                                    min: 1,
                                },
                                width: 300,
                            },
                            extra: '<<wzsxSortTip>>',
                        },
                        content: {
                            type: 'string.richTextPlus',
                            displayName: '<<wznr>>',
                            controlConfig: {
                                placeholder: '<<wznrInputPlease>>',
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            rules: [
                                { required: true },
                                {
                                    max: 20000,
                                    message: '<<wznr_notMoreThan2000_1>>',
                                },
                            ],
                        },
                        contentText: {
                            type: 'string.text.paragraph',
                            displayName: '<<wznr>>',
                            rules: [
                                { required: true },
                                {
                                    whitespace: true,
                                    max: 2000,
                                    message: '<<wznr_notMoreThan2000_1>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<wznrInputPlease>>',
                                rows: 4,
                                maxLength: 600,
                                style: {
                                    width: 720,
                                },
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        ArticleManageView: {
            component: 'Viewport',
            entity: 'ArticleManageEntity',
        },
        ArticleManagePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'ArticleManageFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<common.add>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/article-manager/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'ArticleManageTable' },
            ],
        },
        ArticleManageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subSiteIds',
                    controlConfig: {
                        style: { width: 250 },
                    },
                },
                {
                    property: 'businessTypeId',
                    controlConfig: {
                        style: { width: 250 },
                    },
                },
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 250 },
                    },
                },
            ],
        },
        ArticleManageTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                page: 1,
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'name',
                    with: 300,
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '300px',
                        },
                    },
                },
                {
                    property: 'businessTypeStr',
                },
                {
                    property: 'sequence',
                },
                {
                    property: 'subSites',
                    width: 200,
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'fromTimeStr',
                },
                {
                    property: 'thruTimeStr',
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'default',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'success',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'error',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 200,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'status',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                            handles: [
                                {
                                    value: ArticleStatus.ENABLED,
                                    apiPath: '/admin/management_article/update_status',
                                    params: { status: ArticleStatus.DISABLED },
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isCloseArticle>>《{{row.name}}》?',
                                    },
                                },
                                {
                                    value: ArticleStatus.DISABLED,
                                    apiPath: '/admin/management_article/update_status',
                                    params: { status: ArticleStatus.ENABLED },
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isOpendArticle>>《{{row.name}}》?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/article-manager/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<common.log>>',
                        path: '/article-manager/logs/{{row.id}}',
                    },
                ],
            },
        },
        ArticleManageLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: '/admin/operation_logs/MANAGEMENT_ARTICLE/{id}',
                },
                { component: 'PageBackFooter' },
            ],
        },
        ArticleManageAddPage: {
            component: 'Card',
            content: { component: 'ArticleManageAddFormView' },
        },
        ArticleManageAddFormView: {
            component: 'CreateFormPlus',
            entity: 'ArticleManageEntity',
            direction: 'horizontal',
            style: { width: 1000 },
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.subSiteIds' },
                { property: 'baseInfo.businessType' },
                { property: 'baseInfo.name' },
                {
                    property: 'baseInfo.version',
                    visible: (e: any) => {
                        const types: string[] = get(e, 'baseInfo.businessType', []);
                        return types.includes('20003');
                    },
                },
                {
                    property: 'baseInfo.dateRange',
                },
                { property: 'baseInfo.sequence' },
                {
                    property: 'baseInfo.content',
                    visible: (e: any) => {
                        const types: string[] = get(e, 'baseInfo.businessType', []);
                        return !types.includes('20006');
                    },
                },
                {
                    property: 'baseInfo.contentText',
                    visible: (e: any) => {
                        const types: string[] = get(e, 'baseInfo.businessType', []);
                        return types.includes('20006');
                    },
                },
            ],
            submit: true,
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
        },
        ArticleManageEditPage: {
            component: 'Card',
            content: { component: 'ArticleManageEditFormView' },
        },
        ArticleManageEditFormView: {
            component: 'ModifyFormPlus',
            entity: 'ArticleManageEntity',
            loaderType: 'get',
            direction: 'horizontal',
            style: { width: 1000 },
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.subSiteIds' },
                { property: 'baseInfo.businessType' },
                { property: 'baseInfo.name' },
                {
                    property: 'baseInfo.version',
                    visible: (e: any) => {
                        const types: string[] = get(e, 'baseInfo.businessType', []);
                        return types.includes('20003');
                    },
                },
                {
                    property: 'baseInfo.dateRange',
                },
                { property: 'baseInfo.sequence' },
                {
                    property: 'baseInfo.content',
                    visible: (e: any) => {
                        const types: string[] = get(e, 'baseInfo.businessType', []);
                        return !types.includes('20006');
                    },
                },
                {
                    property: 'baseInfo.contentText',
                    visible: (e: any) => {
                        const types: string[] = get(e, 'baseInfo.businessType', []);
                        return types.includes('20006');
                    },
                },
            ],
            submit: true,
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/article-manager',
                    component: 'ArticleManageView',
                    breadcrumbName: '<<glwz>>',
                    privilege: {
                        path: 'managementArticle',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'ArticleManageAddPage',
                            breadcrumbName: '<<addArticle>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'ArticleManageEditPage',
                            breadcrumbName: '<<editArticle>>',
                        },
                        {
                            path: '/logs/:id',
                            component: 'ArticleManageLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        {
                            path: '/',
                            component: 'ArticleManagePage',
                        },
                    ],
                },
            ],
        },
    ],
};
