import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { StringOptionAutoCompletePLusMode } from './option-auto-complete-plus';
export class OptionAutoCompletePlusMode extends modes.StringMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <StringOptionAutoCompletePLusMode {...displayInfo} />;
    }
}
