import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty, get, forEach, isArray } from 'lodash';

const api = services.api;
const pathKey = 'CYBERSHOP-PRESALE';
const path = '/admin/presales';

export const PreSaleListLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/${pathKey}`;
        config.apiPath = path;
        const { id } = data;
        function processResult(item: any) {
            const activityTimes = item.activityTimes ? item.activityTimes : [];
            for (let i = 0; i < activityTimes.length; i++) {
                let time = activityTimes[i];
                if (time && time.startTime && time.endTime) {
                    time.start = time.startTime;
                    time.end = time.endTime;
                } else {
                    activityTimes.splice(i, 1);
                }
            }

            item.activityTimes = activityTimes;

            item.baseInfo = {
                name: item.name,
                status: item.status,
                dateRange: {
                    start: item.startTime,
                    end: item.endTime,
                },
            };
            if (!item.rulesInfo) {
                item.rulesInfo = {};
            }
            if (item.userLimitRules) {
                item.limitUser = {};
                forEach(item.userLimitRules, (userRule, index) => {
                    let key = userRule.ruleType === 'EACH_ORDER' ? 'eachOrder' : 'eachPresale';
                    item.limitUser[key] = userRule.ruleCount;
                });
            }
            if (item.userLevelLimitRules) {
                item.limitUserLevel = item.userLevelLimitRules;
            }
            if (!item.activityInfo) {
                item.activityInfo = {};
            }

            if (item.presaleMode) {
                item.activityInfo.presaleMode = item.presaleMode;
            }
            if (item.draw) {
                const draw = item.draw;
                item.activityInfo.id = get(draw, 'id') || '';
                item.activityMode = get(draw, 'activityMode') || '';
                item.extraActivityMode = [];
                if (get(draw, 'drawLotsStatus') === 'DRAW_LOTS') {
                    item.extraActivityMode.push('DRAW_LOTS');
                    item.ballotDate = {
                        type: get(draw, 'drawType'),
                        drawTime: get(draw, 'drawTime'),
                    };
                    item.ballotedPeopleCount = {
                        type: get(draw, 'checkinType'),
                        number: get(draw, 'maxCheckinQuantity') || '',
                    };
                    item.perballotedCount = get(draw, 'perUserLimit') || '';
                }
                if (get(draw, 'signUpStatus') === 'SIGN_UP') {
                    item.extraActivityMode.push('SIGN_UP');
                    item.certification = get(draw, 'realNameAuthentication') || '';
                    item.minPointThreshold = get(draw, 'minPointThreshold') || '';
                    item.registeredDays = get(draw, 'registeredDays') || '';
                    item.monthlyConsumedTimes = get(draw, 'monthlyConsumedTimes') || '';
                }

                item.advertisement = {
                    activityPicture: [
                        {
                            id: get(draw, 'activityPictureId'),
                            path: get(draw, 'activityPictureUrl') || '',
                        },
                    ],
                };
                if (item.draw.rulePictureId) {
                    item.advertisement.rulePicture = [
                        {
                            id: get(draw, 'rulePictureId'),
                            path: get(draw, 'rulePictureUrl') || '',
                        },
                    ];
                }

                if (item.draw.limitType) {
                    item.rulesInfo.limitRules = get(draw, 'limitType');
                }
            }
            if (item.settlement) {
                const settlement = item.settlement;
                let orderRules = [];

                if (settlement.usePoint) {
                    orderRules.push('USE_POINT');
                }
                if (settlement.useCoupon) {
                    orderRules.push('USE_COUPON');
                }
                item.checkoutRules = {
                    orderRules: orderRules,
                    id: settlement.id,
                };

                if (get(settlement, 'deliveryTime') > 0) {
                    item.checkoutRules.rules = {
                        pickupTime: get(settlement, 'deliveryTime'),
                    };
                }
            }
            return item;
        }

        if (!id) {
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
            return await api.get(data, config).then((res: any) => {
                if (res && res.result) {
                    res.result = res.result.map(processResult);
                }
                return res;
            });
        } else {
            config.apiRoot = `${ENV.AUTH_API_ROOT}/${pathKey}`;
            config.apiPath = `${path}/${id}`;
            return await api.get({}, config).then(processResult);
        }
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/${pathKey}`;
        config.apiPath = path;
        const newParams = dataParser(params);

        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;

        config.apiRoot = `${ENV.AUTH_API_ROOT}/${pathKey}`;
        config.apiPath = `${path}/${id}`;

        const newParams = dataParser(params);
        newParams.id = id;
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    if (params.baseInfo) {
        newParams.name = params.baseInfo.name;
        newParams.status = true; // params.baseInfo.status;
        newParams.startTime = params.baseInfo.dateRange.start;
        newParams.endTime = params.baseInfo.dateRange.end;
    }
    // 活动场次
    if (params.activityTimes) {
        forEach(params.activityTimes, (item) => {
            item.startTime = item.start;
            item.endTime = item.end;
        });
        newParams.activityTimes = params.activityTimes;
    }

    // 活动规则
    if (params.activityInfo) {
        const activityInfo = params.activityInfo;
        newParams.presaleMode = activityInfo.presaleMode;
        newParams.goods = params.goods;
        newParams.subsites = params.subsites;
        if (!newParams.draw) {
            newParams.draw = {};
        }
        if (activityInfo.id) {
            newParams.draw.id = activityInfo.id;
        }
        newParams.draw.activityMode = params.activityMode;
        // 活动模式
        if (params.extraActivityMode) {
            let extraActivityMode = params.extraActivityMode;
            if (isArray(extraActivityMode) && extraActivityMode.length > 0) {
                if (extraActivityMode.length === 2) {
                    newParams.draw.signUpStatus = 'SIGN_UP';
                    newParams.draw.drawLotsStatus = 'DRAW_LOTS';
                } else if (extraActivityMode.length === 1 && extraActivityMode[0] === 'SIGN_UP') {
                    newParams.draw.signUpStatus = 'SIGN_UP';
                    newParams.draw.drawLotsStatus = 'NON_DRAW_LOTS';
                } else {
                    newParams.draw.signUpStatus = 'NON_SIGN_UP';
                    newParams.draw.drawLotsStatus = 'DRAW_LOTS';
                }
                newParams.draw.activityMode = 'REGISTRATION_DRAW';
            } else {
                newParams.draw.activityMode = 'NON_REGISTRATION_DRAW';
                newParams.draw.signUpStatus = 'NON_SIGN_UP';
                newParams.draw.drawLotsStatus = 'NON_DRAW_LOTS';
            }
        }
        // 抽签时间
        if (
            newParams.draw.drawLotsStatus &&
            newParams.draw.drawLotsStatus === 'DRAW_LOTS' &&
            params.ballotDate
        ) {
            newParams.draw.drawTime = params.ballotDate.drawTime;
            newParams.draw.drawType = params.ballotDate.type;
        }
        // 中签人数
        if (
            newParams.draw.drawLotsStatus &&
            newParams.draw.drawLotsStatus === 'DRAW_LOTS' &&
            params.ballotedPeopleCount
        ) {
            newParams.draw.checkinType = params.ballotedPeopleCount.type;
            newParams.draw.maxCheckinQuantity = params.ballotedPeopleCount.number;
        }
        // 每用户中签次数
        if (
            newParams.draw.drawLotsStatus &&
            newParams.draw.drawLotsStatus === 'DRAW_LOTS' &&
            params.perballotedCount
        ) {
            newParams.draw.perUserLimit = params.perballotedCount;
        }
        // 注册时间门槛
        if (
            newParams.draw.signUpStatus &&
            newParams.draw.signUpStatus === 'SIGN_UP' &&
            params.registeredDays >= 0
        ) {
            newParams.draw.registeredDays = params.registeredDays;
        }
        // 月消费次数门槛
        if (
            newParams.draw.signUpStatus &&
            newParams.draw.signUpStatus === 'SIGN_UP' &&
            params.monthlyConsumedTimes >= 0
        ) {
            newParams.draw.monthlyConsumedTimes = params.monthlyConsumedTimes;
        }
        // 报名积分门槛
        if (
            newParams.draw.signUpStatus &&
            newParams.draw.signUpStatus === 'SIGN_UP' &&
            params.minPointThreshold
        ) {
            newParams.draw.minPointThreshold = params.minPointThreshold;
        }
        // 实名认证
        if (
            newParams.draw.signUpStatus &&
            newParams.draw.signUpStatus === 'SIGN_UP' &&
            params.certification
        ) {
            newParams.draw.realNameAuthentication = params.certification;
        }

        // 活动图片
        if (params.advertisement) {
            const activityPic = params.advertisement.activityPicture;
            const rulePicture = params.advertisement.rulePicture;
            newParams.draw.activityPictureId =
                activityPic && activityPic[0].id ? activityPic[0].id : '';
            newParams.draw.activityPictureUrl =
                activityPic && activityPic[0].path ? activityPic[0].path : '';
            if (rulePicture && rulePicture.length > 0) {
                newParams.draw.rulePictureId = rulePicture[0].id ? rulePicture[0].id : '';
                newParams.draw.rulePictureUrl = rulePicture[0].path ? rulePicture[0].path : '';
            }
        }
    }

    // 结算
    if (params.checkoutRules) {
        const checkoutRules = params.checkoutRules;
        if (!newParams.settlement) {
            newParams.settlement = {};
        }
        if (checkoutRules.id) {
            newParams.settlement.id = checkoutRules.id;
        }

        if (!newParams.presaleModeDetail) {
            newParams.presaleModeDetail = {};
        }

        forEach(checkoutRules.orderRules, (item) => {
            switch (item) {
                case 'USE_POINT':
                    newParams.settlement.usePoint = true;
                    break;
                case 'USE_COUPON':
                    newParams.settlement.useCoupon = true;
                    break;
            }
        });
        if (checkoutRules.rules) {
            newParams.settlement.deliveryTime = checkoutRules.rules.pickupTime;
            newParams.settlement.deliveryTimeLimit = 0;
        }
    }
    // 限购条件
    if (params.rulesInfo.limitRules) {
        if (!newParams.draw) {
            newParams.draw = {};
        }
        newParams.draw.limitType = params.rulesInfo.limitRules;
    }
    // 用户限购规则
    if (params.rulesInfo.limitRules === 'LIMIT_USER' && params.limitUser) {
        const limitUser = params.limitUser;
        const rules = [];
        for (let key in limitUser) {
            let item = {
                ruleCount: limitUser[key],
                ruleType: key === 'eachOrder' ? 'EACH_ORDER' : 'EACH_PRESALE',
            };
            rules.push(item);
        }
        newParams.userLimitRules = rules;
    }
    // 会员等级限购
    if (params.rulesInfo.limitRules === 'LIMIT_USER_LEVEL' && params.limitUserLevel) {
        const limitUserLevel = params.limitUserLevel;
        if (Array.isArray(limitUserLevel) && limitUserLevel.length > 0) {
            newParams.userLevelLimitRules = limitUserLevel;
        }
    }

    return newParams;
}
