import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { BirthdayMemberLevelCouponRule } from '../../../../components/birthday-member-level-coupon-rule';

/**
 *  会员生日营销，会员等级奖励类型
 */
export class BirthdayMemberLevelCouponRuleMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <BirthdayMemberLevelCouponRule {...controlInfo} />;
    }
}
