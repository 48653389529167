import React from 'react';
import { modes } from '@comall-backend-builder/types';
import classNames from 'classnames';
export class IntroductionMode extends modes.StringParagraphMode {
    /**
     * 获取展示组件
     */
    getNotAvailableDisplayComponent(displayInfo: any): JSX.Element {
        const { className, style } = displayInfo;
        return (
            <span className={classNames('type-not-available', className)} style={style}>
                无
            </span>
        );
    }
}
