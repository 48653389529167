import * as React from 'react';
import { ImageUpload } from '@comall-backend-builder/components-basis';
import { get } from 'lodash';
import { services } from '@comall-backend-builder/core';

export const SingleImageUpload = (props: {
    value: string | undefined;
    onChange: (value: any, name: string) => {};
}) => {
    const imageControlInfo = {
        name: 'picture',
        accept: 'image/jpg, image/png',
        maxCount: 1,
        multiple: false,
        fileName: 'files',
        uploadType: 'single',
        uploadUrl: `${ENV.AUTH_API_ROOT}/FILE/admin/files/locations/109/upload`,
        mode: 'picture-card',
        maxSize: 1 * 1024,
        preview: {
            size: 500,
        },
        limitTips: {
            style: {
                display: 'none',
            },
        },
        uploadLimitSizeErrorMessage:
            services.language.getText('pictures') +
            '{{file.name}}' +
            services.language.getText('pictureUploadLimit1'),
    };
    const { value, onChange } = props;
    const onImageChange = (value: any, name: any) => {
        onChange(get(value, '[0].path', undefined), name);
        return {};
    };
    return <ImageUpload {...imageControlInfo} value={value} onChange={onImageChange} />;
};
