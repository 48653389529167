import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const ExternalCouponRulesLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        config.apiPath = `/admin/external_coupon_rules`;
        return await api.get(data, config).then((res: any) => {
            const newData: Array<any> = res
                ? res.filter((i: any) => {
                      let find = true;
                      if (data.name && !i.name.includes(data.name)) {
                          find = false;
                      }
                      if (data.code && !i.code.includes(data.code)) {
                          find = false;
                      }
                      return find;
                  })
                : [];
            return newData;
        });
    },
};
