import React, { Component, createElement } from 'react';
import { connect } from 'react-redux';
import { Modal, Menu, Dropdown, Button as AntButton, Icon as AntIcon } from 'antd';
import { remove, get, map, cloneDeep, findIndex, forEach, isEqual, isEmpty } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import {
    GroupBuyingGoodsSelectedDisplay,
    GroupBuyingProductType,
} from '../group-buying-goods-selected-display';

const TableComponentId = 'GroupBuyingGoodsSelectorDataTable';
let entity: any = {};

type Props = {
    onChange: (products: any[]) => void;
    requestStatus: string;
    value: any[];
    row: any;
    result: any[];
    fields: any;
};

type State = {
    visible: boolean;
    groupBuyingProductType: keyof typeof GroupBuyingProductType;
};
/**
 * 选择商品
 */
class GroupBuyingGoodsSelectorLayout extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = { visible: false, groupBuyingProductType: 'GENERAL_PRODUCT' };
        this.dispatch = builder.getStore().dispatch;
        entity = new (EntitiesManager.get('GroupBuyingGoodsSelector'))({});
    }
    // 是否已经切换商品类型
    hasChangeGroupBuyingProductType = false;
    dispatch: any = {};
    selectedRows: any[] = [];

    componentWillReceiveProps(nextProps: any) {
        const { result = [], fields } = nextProps;
        const nextSubsiteIds = this.getSubsiteIds(nextProps);
        const currentSubsiteIds = this.getSubsiteIds(this.props);
        const { groupBuyingProductType } = this.state;
        if (
            fields &&
            fields.goodsSettings &&
            fields.goodsSettings.goods &&
            fields.goodsSettings.goods.length
        ) {
            // 编辑、详情加载时更新goodsType
            if (!result.length && !this.hasChangeGroupBuyingProductType) {
                this.setState({
                    groupBuyingProductType: fields.goodsSettings.goods[0].groupBuyingProductType,
                });
            }
        }

        if (nextProps.requestStatus === 'success' && this.props.requestStatus === 'pending') {
            const { selectedRows = [] } = this;

            if (selectedRows.length)
                setTimeout(() => {
                    this.dispatch(
                        actions.tableRowSelectionChangeAction(
                            TableComponentId,
                            map(selectedRows, 'id')
                        )
                    );
                }, 300);
        }

        if (
            !isEqual(nextSubsiteIds, currentSubsiteIds) &&
            nextSubsiteIds &&
            !isEqual(currentSubsiteIds, [null]) &&
            !isEmpty(nextProps.value)
        ) {
            groupBuyingProductType === 'COUPON'
                ? this.onChange([])
                : this.processDataAfterSubsiteChange(nextSubsiteIds, nextProps);
        }
    }

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(entity));
    }

    getSubsiteIds = (props: any) => {
        const {
            row: { baseSettings = {} },
        } = props;
        const subsiteIds = baseSettings.subsites ? map(baseSettings.subsites, 'id') : [null];
        return subsiteIds;
    };

    processDataAfterSubsiteChange = (subsiteIds: string[], props: any) => {
        const { value } = props;
        const newData: any[] = [];
        forEach(value, (i) => {
            if (subsiteIds.indexOf(i.subsiteId) > -1) {
                newData.push(i);
            }
        });
        this.onChange(newData);
    };

    onChange = (goods: any[]) => {
        this.props.onChange(goods);
    };

    processSelectedData = (data: any[]) => {
        let items: any[] = [];
        if (data && data.length) {
            forEach(data, (product) => {
                forEach(product.goodsVos, (goods: any) => {
                    items.push({
                        id: goods.id,
                        productId: product.id,
                        name: goods.name,
                        productName: product.name,
                        productCode: product.barcode,
                        goodsCode: goods.csku,
                        subsiteId: goods.subsiteId + '',
                        subsiteName: goods.subsiteName,
                        merchantName: goods.merchantName,
                        marketPrice: (+goods.marketPrice.value).toFixed(2),
                        salesPrice: (+goods.salesPrice.value).toFixed(2),
                        goodsDeductPoint: goods.points.value,
                        goodsStock: goods.stock,
                        groupBuyingProductType: this.state.groupBuyingProductType,
                    });
                });
            });
        }
        return items;
    };

    onSelect = (values: any[]) => {
        const { value = [] } = this.props;
        const { groupBuyingProductType } = this.state;

        let newData: any[];

        // 如果切换商品类型或者只能单选的，数据要覆盖
        if (groupBuyingProductType === 'COUPON') {
            newData = [...values];
        } else {
            const data = this.processSelectedData(cloneDeep(values));
            if (
                data.some((o: any) =>
                    value.some((v: any) => v.groupBuyingProductType !== o.groupBuyingProductType)
                )
            ) {
                newData = [...data];
            } else {
                newData = cloneDeep(value || []);
                data.forEach((i: any) => {
                    const hasIndex = findIndex(value, (o: any) => o.id === i.id);
                    if (hasIndex === -1) {
                        newData.unshift(i);
                    }
                });
            }
        }
        this.setState({
            groupBuyingProductType: newData[0]?.groupBuyingProductType,
        });
        this.onChange(newData);
    };

    onTableRowSelect = (record: any, selected: boolean) => {
        if (selected) {
            this.state.groupBuyingProductType !== 'COUPON'
                ? this.selectedRows.push(record)
                : (this.selectedRows = [record]);
        } else {
            remove(this.selectedRows, (i) => {
                return i.id === record.id;
            });
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: any[]) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            this.selectedRows = [];
        }
    };

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
    };

    onOk = () => {
        this.onSelect(this.selectedRows);
        this.toggleModal();
    };

    getConfig = () => {
        const params = this.getParams();
        const { groupBuyingProductType } = this.state;
        const columns =
            groupBuyingProductType === 'GENERAL_PRODUCT'
                ? [
                      { property: 'goodsMvo.productName' },
                      { property: 'goodsMvo.name' },
                      { property: 'goodsMvo.subsiteName' },
                      { property: 'goodsMvo.merchantName' },
                      { property: 'goodsMvo.spuCode' },
                      { property: 'goodsMvo.csku' },
                  ]
                : [
                      { property: 'name', title: '优惠券名称' },
                      { property: 'batchNo' },
                      { property: 'couponTypeDes' },
                      { property: 'sourceDesc' },
                      { property: 'startTime' },
                      { property: 'endTime' },
                  ];

        return {
            component: 'FlexLayout',
            direction: 'vertical',
            params,
            entity,
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'right' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        entity.pageChange(Object.assign({}, entity.paging, { page: 1 }));
                        entity.search(params);
                    },
                    fields: [{ property: 'name' }],
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    rowSelection: {
                        fixed: true,
                        type: groupBuyingProductType === 'COUPON' ? 'radio' : 'checkbox',
                        onSelect: (record: any, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: any) => {
                            this.onTableRowSelectAll(selected, rows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    columns,
                },
            ],
        };
    };

    getParams = () => {
        const { row } = this.props;
        const { groupBuyingProductType } = this.state;
        const subsiteIds =
            row.baseSettings && row.baseSettings.subsites
                ? map(row.baseSettings.subsites, 'id')
                : null;

        return { subsiteIds, groupBuyingProductType };
    };

    toggleModal = () => {
        const params = this.getParams();
        const { visible } = this.state;

        this.setState({ visible: !visible }, () => {
            if (!visible) {
                const { page = 1, perPage = 10 } = entity.paging || {};

                entity.search({ ...params, page, perPage });
                this.onClearAll();
            }
        });
    };

    handleSelectGoodsType = ({ key }: any) => {
        this.hasChangeGroupBuyingProductType = true;
        this.setState({ groupBuyingProductType: key }, this.toggleModal);
    };

    renderGoodsSelector = () => {
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 900,
            title: `请选择${GroupBuyingProductType[this.state.groupBuyingProductType]}`,
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };

        const menu = (
            <Menu onClick={this.handleSelectGoodsType}>
                {Object.entries(GroupBuyingProductType).map(([key, value]) =>
                    value !== GroupBuyingProductType.VIRTUAL_GOODS ? (
                        <Menu.Item key={key}>{value}</Menu.Item>
                    ) : null
                )}
            </Menu>
        );

        return (
            <div style={{ display: 'inline-block' }}>
                <Dropdown overlay={menu}>
                    <AntButton>
                        请选择
                        <AntIcon type="down" />
                    </AntButton>
                </Dropdown>
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    };

    render() {
        const { value = [] } = this.props;
        const { groupBuyingProductType } = this.state;

        return (
            <>
                {this.renderGoodsSelector()}
                {value.length ? (
                    <GroupBuyingGoodsSelectedDisplay
                        modifiable={true}
                        data={value}
                        onChange={this.onChange}
                        groupBuyingProductType={groupBuyingProductType}
                    />
                ) : null}
            </>
        );
    }
}

function mapStateTopProps(_state: any, props: any) {
    const { entity: parentEntity } = props;

    return {
        requestStatus: get(entity, 'requestStatus', null),
        result: entity.result,
        fields: parentEntity.fields,
    };
}

export const GroupBuyingGoodsSelectorContainer = connect(mapStateTopProps)(
    GroupBuyingGoodsSelectorLayout
);
