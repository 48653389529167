import React, { Component } from 'react';
import { get } from 'lodash';

export class FooterInfo extends Component<any, any> {
    render() {
        let { fields } = this.props;
        const frameCopyRight = get(fields, 'frameCopyRight');

        return fields ? <div>{frameCopyRight}</div> : <div>Powered by Co-Mall</div>;
    }
}
