import React, { Component } from 'react';
import { Button, message, Select, Checkbox, InputNumber } from 'antd';
import { NewCouponSelector } from '../../../../components/new-coupon-selector';
import { Coupon } from '../../../../components/new-coupon-selector/select-coupon';
import { cloneDeep, uniqueId, forEach, find, remove } from 'lodash';
import { services } from '@comall-backend-builder/core';
import './index.less';

const { api, language } = services;
const { Option } = Select;

enum AssetRewardRewardType {
    POINT = 'POINT',
    EXPERIENCE = 'EXPERIENCE',
}

type RewardType = AssetRewardRewardType | 'COUPON';

export interface AssetRewards {
    rewardType: AssetRewardRewardType;
    rewardValue?: number | undefined;
}

interface UpgradeGiftValue {
    id: string;
    cardLevelId?: string;
    assetRewards: Array<AssetRewards>;
    couponRewards?: Array<Coupon>;
}

interface UpgradeGiftProps {
    name: string;
    value?: UpgradeGiftValue[];
    disabled: boolean;
    getSubsiteIds?: (row: Record<string, any>) => string;
    onChange: (value: UpgradeGiftValue[], name: string) => void;
    row: Record<string, any>;
}

const defaultValue = {
    cardLevelId: undefined,
    assetRewards: [],
};

interface UpgradeGiftState {
    dependencesValue: string;
    cardLevelOptions: { id: string; name: string }[];
    cardLevelDisabled: boolean;
}

interface RenderRewardConfig {
    name: string;
    value: number | undefined;
    selected: boolean;
    disabled: boolean;
    max?: number;
    onSelect: (event: any) => void;
    onInput: (value: number | undefined) => void;
}
interface RenderCouponsConfig {
    name: string;
    value: Coupon[] | undefined;
    selected: boolean;
    disabled: boolean;
    onSelect: (event: any) => void;
}

export class UpgradeGift extends Component<UpgradeGiftProps, UpgradeGiftState> {
    constructor(props: UpgradeGiftProps) {
        super(props);
        const dependencesValue = this.getDependencesValue(props);
        this.state = {
            dependencesValue: dependencesValue,
            cardLevelOptions: [],
            cardLevelDisabled: true,
        };
    }

    componentDidMount() {
        this.loadFirstVisible();
    }

    componentWillReceiveProps(nextProps: UpgradeGiftProps) {
        const { dependencesValue } = this.state;
        const nextDependencesValue = this.getDependencesValue(nextProps);
        // 如果props变更后，依赖项有值
        if (nextDependencesValue) {
            if (dependencesValue !== nextDependencesValue) {
                if (dependencesValue) {
                    this.clearValue(nextProps);
                }
                this.setState(
                    {
                        dependencesValue: nextDependencesValue,
                        cardLevelOptions: [],
                        cardLevelDisabled: false,
                    },
                    () => {
                        this.loadOptions(nextDependencesValue);
                    }
                );
            }
        } else if (dependencesValue) {
            this.clearValue(nextProps);
        }
    }

    getDependencesValue = (props: UpgradeGiftProps) => {
        const { row, getSubsiteIds } = props;
        let dependencesValue = (row && getSubsiteIds && getSubsiteIds(row)) || '';
        return dependencesValue;
    };
    loadFirstVisible = () => {
        const { row, getSubsiteIds } = this.props;
        let dependencesValue = (row && getSubsiteIds && getSubsiteIds(row)) || '';
        if (dependencesValue) {
            this.setState(
                {
                    cardLevelOptions: [],
                    dependencesValue,
                    cardLevelDisabled: false,
                },
                () => {
                    this.loadOptions(dependencesValue);
                }
            );
        }
    };
    loadOptions = (subsiteIds: string) => {
        const params = {
            subsiteIds,
        };
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
            apiPath: '/admin/level_plans/shared_card_levels',
        };
        api.get(params, config).then((res: any) => {
            const result: Array<{
                id: string;
                name: string;
            }> = [];
            forEach(res, (level: any) => {
                result.push({
                    id: level.id.toString(),
                    name: level.name,
                });
            });
            this.setState({
                cardLevelOptions: result,
            });
        });
    };
    clearValue = (nextProps: UpgradeGiftProps) => {
        const { name, onChange, value = [] } = nextProps;
        const newValue = cloneDeep(value);
        newValue.map((item) => {
            item.cardLevelId = undefined;
            return item;
        });
        onChange(newValue, name);
        this.setState({
            dependencesValue: '',
            cardLevelOptions: [],
            cardLevelDisabled: true,
        });
    };
    private addGift = () => {
        const { value = [], onChange, name } = this.props;
        const newValue = cloneDeep(value);
        newValue.push({
            ...defaultValue,
            id: uniqueId('gifi_'),
        });
        onChange(newValue, name);
    };
    private cardLevelChange = (record: UpgradeGiftValue, cardLevelId: string) => {
        const { name, onChange, value = [] } = this.props;
        const newValue = cloneDeep(value);
        const currentValue = find(newValue, { id: record.id });
        if (currentValue) {
            currentValue.cardLevelId = cardLevelId;
        }
        onChange(newValue, name);
    };
    private onHandleSelect = () => {
        const { cardLevelDisabled } = this.state;
        if (cardLevelDisabled) {
            message.warning(language.getText('selectSubsite'));
            return false;
        }
    };
    private getRewards = (record: UpgradeGiftValue) => {
        const { disabled } = this.props;
        const { assetRewards = [], couponRewards } = record;
        const findReward = (type: AssetRewardRewardType) =>
            assetRewards.find((item) => item.rewardType === type);
        const config = (valueType: AssetRewardRewardType) => {
            const reward = findReward(valueType);
            const selected = Boolean(reward);
            const onSelect = (e: any) =>
                this.onCheckboxChange(e.target.checked, record.id, valueType);
            const onInput = (value: number | undefined) =>
                this.onInputValueChange(value, record.id, valueType);
            return {
                name:
                    valueType === AssetRewardRewardType.EXPERIENCE
                        ? language.getText('czz')
                        : language.getText('point'),
                value: reward?.rewardValue,
                selected,
                disabled,
                max: valueType === AssetRewardRewardType.POINT ? 999999 : undefined,
                onSelect,
                onInput,
            };
        };
        return {
            point: config(AssetRewardRewardType.POINT),
            experience: config(AssetRewardRewardType.EXPERIENCE),
            coupons: {
                name: language.getText('yhq'),
                value: couponRewards,
                selected: Boolean(couponRewards),
                disabled,
                onSelect: (e: any) => this.onCheckboxChange(e.target.checked, record.id, 'COUPON'),
            },
        };
    };
    private onCheckboxChange = (checked: boolean, recordId: string, rewardType: RewardType) => {
        const { name, onChange, value = [] } = this.props;
        const newValue = cloneDeep(value);
        const currentValue = find(newValue, { id: recordId });
        if (currentValue) {
            if (rewardType === 'COUPON') {
                currentValue.couponRewards = checked ? [] : undefined;
            } else if (checked) {
                currentValue.assetRewards.push({ rewardType });
            } else {
                remove(currentValue.assetRewards, (item) => item.rewardType === rewardType);
            }
        }
        onChange(newValue, name);
    };
    private onInputValueChange = (
        number: number | undefined,
        recordId: string,
        rewardType: RewardType
    ) => {
        const { name, onChange, value = [] } = this.props;
        const newValue = cloneDeep(value);
        const currentValue = find(newValue, { id: recordId });
        if (currentValue) {
            const reward = currentValue.assetRewards.find((item) => item.rewardType === rewardType);
            if (reward) {
                reward.rewardValue = number;
            }
        }
        onChange(newValue, name);
    };

    onChangeCoupon = (recordId: string, coupons: Coupon[]) => {
        const { name, onChange, value = [] } = this.props;
        const newValue = cloneDeep(value);
        const currentValue = find(newValue, { id: recordId });
        const couponRewards = coupons.map((item) => {
            if (item.type === 'PACKAGE') {
                item.quantity = 1;
            }
            return item;
        });
        if (currentValue) {
            currentValue.couponRewards = couponRewards;
        }
        onChange(newValue, name);
    };

    private renderReward = (reward: RenderRewardConfig | null) => {
        if (!reward) {
            return null;
        }
        const { value, name, selected, disabled, max, onSelect, onInput } = reward;
        return (
            <div className="reward-content-item">
                <Checkbox
                    className="reward-content-item-checkbox"
                    onChange={onSelect}
                    checked={selected}
                    value="point"
                    disabled={disabled}
                >
                    {name}
                </Checkbox>
                <InputNumber
                    type="number"
                    min={1}
                    max={max}
                    precision={0}
                    value={value}
                    onChange={onInput}
                    disabled={disabled || !selected}
                />
                <span className="reward-item-text">{name}</span>
            </div>
        );
    };

    renderCoupons = (coupon: RenderCouponsConfig | null, recordId: string) => {
        if (!coupon) {
            return null;
        }
        const { value, name, selected, disabled, onSelect } = coupon;
        const isPackage = value ? value.some((c) => c.type === 'PACKAGE') : false;
        return (
            <div className="reward-content-item" key={`newCouponSelector-${recordId}`}>
                <Checkbox
                    className="reward-content-item-checkbox"
                    onChange={onSelect}
                    checked={selected}
                    value="point"
                    disabled={disabled}
                >
                    {name}
                </Checkbox>
                <NewCouponSelector
                    disabled={disabled || !selected}
                    multiEntity
                    value={value}
                    grantText={language.getText('fafang')}
                    onChange={this.onChangeCoupon.bind(this, recordId)}
                    inputNumberProps={{
                        min: 1,
                        precision: 0,
                        disabled: isPackage,
                    }}
                />
            </div>
        );
    };
    private onDeleteGift = (recordId: string) => {
        const { name, onChange, value = [] } = this.props;
        const newValue = cloneDeep(value);
        remove(newValue, (item) => item.id === recordId);
        onChange(newValue, name);
    };

    private renderTable = () => {
        const { value = [] } = this.props;
        if (!value || value.length === 0) {
            return null;
        }
        return value.map(this.renderRewardItem);
    };
    private renderRewardItem = (reward: UpgradeGiftValue) => {
        const { value = [] } = this.props;
        const { cardLevelOptions, cardLevelDisabled } = this.state;
        const { point, experience, coupons } = this.getRewards(reward);
        return (
            <div className="comment-activity-reward-item" key={reward.id}>
                <div className="rule-setting-wrap">
                    <div className={`${prefix}__card-level`}>
                        <div>{language.getText('hddj')}</div>
                        <div
                            onClick={this.onHandleSelect}
                            style={{ marginBottom: 10, marginTop: 10 }}
                        >
                            <Select
                                allowClear
                                value={reward.cardLevelId}
                                style={{ width: 160 }}
                                onChange={this.cardLevelChange.bind(this, reward)}
                                disabled={cardLevelDisabled}
                            >
                                {cardLevelOptions.map((item) => {
                                    const disabled = Boolean(find(value, { cardLevelId: item.id }));
                                    return (
                                        <Option key={item.id} value={item.id} disabled={disabled}>
                                            {item.name}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </div>
                        <Button onClick={this.onDeleteGift.bind(this, reward.id)}>
                            {language.getText('scdj')}
                        </Button>
                    </div>
                </div>
                <div className="reward-content-content">
                    <div className={`${prefix}__reward`}>
                        <div>{language.getText('zsnr')}</div>
                        <div className={`${prefix}__reward__list`}>
                            {this.renderReward(point)}
                            {this.renderReward(experience)}
                        </div>
                        {coupons && (
                            <div className={`${prefix}__reward__list`}>
                                {this.renderCoupons(coupons, reward.id)}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className={prefix} style={{ width: 1000 }}>
                {this.renderTable()}
                <Button onClick={this.addGift}>{language.getText('addRule')}</Button>
            </div>
        );
    }
}

const prefix = 'upgrade-gift';
