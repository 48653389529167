import React, { Component } from 'react';
import { Radio, InputNumber, Button } from 'antd';
import { cloneDeep, isNil } from 'lodash';
import { api, language } from '@comall-backend-builder/core/lib/services';
import './index.less';
interface MemberCardLevelPointRewardValue {
    scopeId: string;
    exchangeMode: string;
    exchangeLimit: string;
    period: string;
    consumeValue: number;
    normalConditionType: string | undefined;
}
interface OpenBoxDrawLimitRuleProps {
    onChange: (value: any) => void;
    value: MemberCardLevelPointRewardValue | undefined;
}
const defaultValue = {
    scopeId: '-1',
    exchangeMode: 'EXTRA_GAIN',
    exchangeLimit: undefined,
    period: 'ACTIVITY',
    consumeValue: 1,
    normalConditionType: '',
};

export class OpenBoxDrawLimitRule extends Component<OpenBoxDrawLimitRuleProps, any> {
    constructor(props: OpenBoxDrawLimitRuleProps) {
        super(props);
        this.state = {
            expand: props?.value?.normalConditionType || props?.value?.exchangeLimit ? true : false,
            isActivateCard: true,
        };
    }
    componentDidMount() {
        const { value, onChange } = this.props;
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: '/AFFILIATE-MARKETING/admin/open_box_activities/crm_service_status',
            }
        ).then((response: any) => {
            this.setState(
                {
                    isActivateCard: isNil(response) ? false : response,
                },
                () => {
                    if (
                        value &&
                        value.normalConditionType &&
                        value.normalConditionType === 'CRM_NEW_MEMBER' &&
                        !response
                    ) {
                        onChange && onChange(cloneDeep(defaultValue));
                        this.setState({ expand: false });
                    }
                }
            );
        });
    }
    changeUnifiedReward = (e: any) => {
        const { value, onChange } = this.props;
        const unifiedReward: string = e.target.value;
        let newValue = {
            scopeId: '1',
            exchangeMode: 'EXTRA_GAIN',
            exchangeLimit: value?.exchangeLimit || undefined,
            period: 'ACTIVITY',
            consumeValue: 1,
            normalConditionType: unifiedReward,
        };
        onChange && onChange(cloneDeep(newValue));
    };

    onLimitChange = (e: any) => {
        const { value, onChange } = this.props;
        let newValue = {
            scopeId: '1',
            exchangeMode: 'EXTRA_GAIN',
            exchangeLimit: e,
            period: 'ACTIVITY',
            consumeValue: 1,
            normalConditionType: value?.normalConditionType,
        };
        onChange && onChange(cloneDeep(newValue));
    };
    harderChangeSetting = () => {
        const { onChange } = this.props;
        const { expand, isActivateCard } = this.state;
        const value = !expand
            ? {
                  scopeId: '1',
                  exchangeMode: 'EXTRA_GAIN',
                  exchangeLimit: undefined,
                  period: 'ACTIVITY',
                  consumeValue: 1,
                  normalConditionType: isActivateCard ? 'CRM_NEW_MEMBER' : 'KM_NEW_MEMBER',
              }
            : defaultValue;
        onChange(value);
        this.setState({ expand: !expand });
    };
    render() {
        const { value = defaultValue } = this.props;
        const { normalConditionType, exchangeLimit } = value;
        const { expand, isActivateCard } = this.state;
        const structureList = [
            {
                title: language.getText('xzcyhewkhdcjcs'),
                value: 'CRM_NEW_MEMBER',
                tip: language.getText('addAndRegister'),
            },
            {
                title: language.getText('scdlyhewkhdcjcs'),
                value: 'KM_NEW_MEMBER',
                tip: language.getText('jscdlxcxhzcdl'),
            },
        ];
        if (!isActivateCard && structureList.length > 1) {
            structureList.splice(0, 1);
        }
        const radioClass = !isActivateCard ? 'radio-hiddend' : '';
        return (
            <div className="open-box-draw">
                <Button
                    type="primary"
                    onClick={this.harderChangeSetting}
                    style={{ marginBottom: 5 }}
                >
                    {expand ? language.getText('clearSetting') : language.getText('settings')}
                </Button>
                {expand && (
                    <div>
                        <Radio.Group
                            onChange={this.changeUnifiedReward}
                            value={normalConditionType}
                        >
                            {structureList.map((item, index) => (
                                <div key={index} style={{ marginBottom: 10 }}>
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Radio className={radioClass} value={item.value}>
                                            {item.title}
                                        </Radio>
                                        {normalConditionType === item.value && (
                                            <div>
                                                <InputNumber
                                                    onChange={this.onLimitChange}
                                                    precision={0}
                                                    min={1}
                                                    max={999999}
                                                    size="small"
                                                    value={
                                                        exchangeLimit
                                                            ? parseInt(exchangeLimit)
                                                            : undefined
                                                    }
                                                />
                                                &nbsp;{language.getText('frequency')}
                                            </div>
                                        )}
                                    </div>
                                    <span
                                        className="ant-form-extra"
                                        style={{
                                            display: 'block',
                                            marginLeft: !isActivateCard ? 10 : 25,
                                        }}
                                    >
                                        {item.tip}
                                    </span>
                                </div>
                            ))}
                        </Radio.Group>
                    </div>
                )}
            </div>
        );
    }
}
