import React, { PureComponent } from 'react';
import { Button, Modal, Upload, Icon, message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { ForwardDataCenterModal } from '../../../services';
import './index.less';
import 'antd/dist/antd.css';

const { Dragger } = Upload;

/**
 * 导入数据按钮
 */
export class memberTagImportButton extends PureComponent<
    any,
    { fileList: any[]; showModal: boolean; statusMsg: string }
> {
    state = {
        fileList: [],
        showModal: false,
        statusMsg: services.language.getText('uploadFileClickButton'),
    };

    toggleModal = () => {
        const { showModal } = this.state;
        this.setState({ showModal: !showModal });
    };

    onImport = () => {
        const {
            row: { id },
            entity,
        } = this.props;
        const { fileList } = this.state;
        const apiRoot = `${ENV.AUTH_API_ROOT}/FILE`;
        const apiPath = '/admin/files/locations/73/upload';
        const formData = new FormData();
        formData.append('files', fileList[0]);
        formData.append('meta_data', JSON.stringify({ tagId: id }));

        services.api
            .upload(
                { files: formData },
                {
                    apiRoot,
                    apiPath,
                    fileName: 'files',
                    progressCallBack: () => {},
                    useCustomData: true,
                }
            )
            .then(() => {
                ForwardDataCenterModal();
                this.toggleModal();
                entity.search();
            })
            .catch((err) => {
                if (err) {
                    message.error(
                        err.response?.body?.err_msg || services.language.getText('uoloadFail')
                    );
                }
            });
    };

    handleOk = () => {
        const { fileList } = this.state;
        if (fileList.length) {
            this.onImport();
        } else {
            message.error(services.language.getText('pleaseUploadFile'));
            return;
        }
    };

    downloadTemplate = () => {
        services.api.download(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`,
                apiPath: '/admin/tags/download_template',
            }
        );
    };

    render() {
        const { showModal, fileList, statusMsg } = this.state;
        const { row } = this.props;
        let disabled = false;
        if (row.canImport !== undefined) {
            disabled = !row.canImport;
        }
        const importExampleImg = './images/editor/import_example.png';
        const uploadProps = {
            accept: '.xlsx,.xls,.csv',
            multiple: false,
            onRemove: (file: any) => {
                this.setState({ fileList: [] });
            },
            beforeUpload: (file: any) => {
                this.setState({
                    fileList: [file],
                });

                return false;
            },
            fileList,
        };
        return (
            <div className="giving-coupon-schedules-data-statistics">
                <Button
                    disabled={disabled}
                    type="link"
                    className="btn-data-statistics"
                    onClick={this.toggleModal}
                >
                    {services.language.getText('drsj')}
                </Button>
                <Modal
                    onCancel={this.toggleModal}
                    visible={showModal}
                    title={services.language.getText('batchImport')}
                    footer={[
                        <span className="giving-coupon-schedules-status-msg">{statusMsg}</span>,
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            {services.language.getText('import')}
                        </Button>,
                        <Button key="back" onClick={this.toggleModal}>
                            {services.language.getText('back')}
                        </Button>,
                    ]}
                >
                    <Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">{services.language.getText('selectFile')}</p>
                        <p className="ant-upload-hint">
                            {services.language.getText('supportExtension')}.xlsx、.xls、.csv
                        </p>
                    </Dragger>
                    <Button
                        onClick={this.downloadTemplate}
                        className="import-button-template tag-modal-import-button-template"
                        type="link"
                    >
                        {services.language.getText('clickDownloadTemplate')}
                    </Button>
                    <div className="giving-coupon-schedules-tips">
                        <p className="giving-coupon-schedules-tips-text">
                            {services.language.getText('importRules')}
                        </p>
                        <p className="giving-coupon-schedules-tips-text">
                            {services.language.getText('zwjztxxydrdxx')}
                        </p>
                        <p className="giving-coupon-schedules-tips-text-red">
                            {services.language.getText('couponIntro2')}
                        </p>
                        <p className="giving-coupon-schedules-tips-text">
                            {services.language.getText('merchantBigCodeIntro2')}
                        </p>
                        <p className="giving-coupon-schedules-tips-text">
                            {services.language.getText('uploadIntro3')}
                        </p>
                        <p className="giving-coupon-schedules-tips-text-template">
                            {services.language.getText('wjysmb')}：
                        </p>
                        <img
                            className="giving-coupon-schedules-img"
                            src={importExampleImg}
                            alt=""
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}
