import React, { Component } from 'react';
import { api } from '@comall-backend-builder/core/lib/services';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { get } from 'lodash';
import {
    isSimplicityTheme,
    isClassicTheme,
    hexToRgba,
    getColorByTheme,
    getButtonStyleByTheme,
} from '../theme-style';

import { RegistrationTimeType } from '../../components';
import './index.less';
import { services } from '@comall-backend-builder/core';

const TIME_UNIT: any = {
    DAY: '天',
    HOUR: '小时',
};

const GENDER: any = {
    MAN: '男性用户',
    WOMAN: '女性用户',
    UNKNOWN: ' 未知',
};

/**
 * 活动左侧
 * @param props
 */
class mallActivityLeftInfoView extends Component<any, { themeStyle: any }> {
    constructor(props: any) {
        super(props);
        this.state = {
            themeStyle: {},
        };
    }
    componentDidMount(): void {
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/theme_style',
        };
        api.get({}, config).then((res: any) => {
            if (res.id) {
                this.setState({ themeStyle: JSON.parse(res.config) });
            }
        });
    }

    /**
     * 渲染价格
     */
    renderPrice() {
        const { preview = {} } = this.props;
        const { themeStyle } = this.state;
        const secondaryColor = getColorByTheme(themeStyle, 'secondary');
        const payInfo =
            preview?.registrationInfo?.payList && preview.registrationInfo.payList.list.length
                ? preview.registrationInfo.payList.list[0]
                : undefined;
        const style = {
            color: secondaryColor ? secondaryColor : '#ab8e66',
        };
        if (payInfo) {
            return (
                <div className="activity-left-info-preview__desc-price" style={style}>
                    {payInfo.payPoint ? (
                        <span>
                            <span className="activity-left-info-preview__desc-price-number">
                                {payInfo.payPoint}
                            </span>
                            <span className="activity-left-info-preview__desc-price-unit">
                                积分
                            </span>
                        </span>
                    ) : (
                        ''
                    )}

                    {payInfo.payPoint && payInfo.payCash ? (
                        <span className="activity-left-info-preview__desc-price-unit">+</span>
                    ) : (
                        ''
                    )}
                    {payInfo.payCash ? (
                        <span>
                            <span className="activity-left-info-preview__desc-price-number">
                                {payInfo.payCash}
                            </span>
                            <span className="activity-left-info-preview__desc-price-unit">
                                {services.language.getText('yuan')}
                            </span>
                        </span>
                    ) : (
                        ''
                    )}
                </div>
            );
        }
        return null;
    }

    /**
     * 渲染标签
     */
    renderTags() {
        const { preview } = this.props;
        const { themeStyle } = this.state;
        const primaryColor = getColorByTheme(themeStyle, 'primary');
        if (preview?.baseInfo?.tagIdList && preview?.baseInfo?.tagIdList.length) {
            const tagStyle: any = isSimplicityTheme(themeStyle)
                ? {
                      border: `1px solid ${hexToRgba('#695993', 0.5)}`,
                      backgroundColor: 'transparent',
                      color: primaryColor,
                      borderRadius: 4,
                  }
                : {
                      backgroundColor: hexToRgba(themeStyle?.color?.primary, 0.1),
                      color: primaryColor,
                  };
            return (
                <div className="activity-left-info-preview__tags">
                    {preview.baseInfo.tagIdList.map((item: any) => {
                        return (
                            <div
                                key={item.id}
                                className="activity-left-info-preview__tags__tag"
                                style={tagStyle}
                            >
                                {item.name}
                            </div>
                        );
                    })}
                </div>
            );
        }
        return null;
    }

    renderBanner() {
        const { preview = {} } = this.props;
        return (
            <div className="activity-left-info-preview__banner">
                {preview && preview.baseInfo.canShare && preview.baseInfo.canShare[0] === 'true' && (
                    <div className="activity-left-info-preview__banner-share-icon">
                        <img
                            alt=""
                            className="activity-left-info-preview__banner-share"
                            src="./images/avatar/share-icon.png"
                        ></img>
                    </div>
                )}

                <img
                    className="activity-left-info-preview__banner-img"
                    alt=""
                    src={
                        preview && preview.mediaInfo.pictures && preview.mediaInfo.pictures.length
                            ? preview.mediaInfo.pictures[0].path
                            : './images/avatar/mall-activity-banner.png'
                    }
                ></img>
            </div>
        );
    }
    renderInfo() {
        const { preview = {} } = this.props;
        const { themeStyle } = this.state;
        return (
            <div className="activity-left-info-preview__desc">
                {this.renderPrice()}
                <span className="activity-left-info-preview__desc-title">
                    {preview && preview.baseInfo && preview.baseInfo.name
                        ? preview.baseInfo.name
                        : '活动名称'}
                </span>
                {this.renderTags()}

                {preview?.registrationInfo?.registrationType === 'YES' &&
                    preview?.registrationInfo?.totalQuantity && (
                        <div className="activity-left-info-preview__count">
                            <span className="activity-left-info-preview__count-text">
                                报名人数：0/
                                {preview.registrationInfo.totalQuantity}
                            </span>
                        </div>
                    )}
                {isClassicTheme(themeStyle) && (
                    <div className="activity-left-info-preview__divider"></div>
                )}
            </div>
        );
    }
    /**
     * 渲染地址信息
     */
    renderAddress() {
        const { preview = {} } = this.props;
        const { themeStyle } = this.state;

        const txtStyle = { color: '#000' };
        const style = isSimplicityTheme(themeStyle)
            ? {
                  borderTop: '1px solid #000',
              }
            : {};
        return (
            <div className="activity-left-info-preview__location" style={style}>
                <span className="activity-left-info-preview__location-title">
                    {preview?.baseInfo.addressName}
                </span>
                {preview?.baseInfo.address && (
                    <div className="activity-left-info-preview__address">
                        <div className="activity-left-info-preview__address-text">
                            <span className="activity-left-info-preview__address-content">
                                {preview?.baseInfo.address || ''}
                            </span>
                        </div>
                        <div className="activity-left-info-preview__address-service">
                            <div className="activity-left-info-preview__address-service-code">
                                <img
                                    alt=""
                                    className="activity-left-info-preview__address-service-img"
                                    src="./images/avatar/activity-detail-icon-wechat.png"
                                ></img>
                                <span
                                    className="activity-left-info-preview__address-service-text"
                                    style={txtStyle}
                                >
                                    咨询导购
                                </span>
                            </div>
                            <div className="activity-left-info-preview__address-service-phone">
                                <img
                                    alt=""
                                    className="activity-left-info-preview__address-service-img"
                                    src="./images/avatar/activity-detail-icon-phone.png"
                                ></img>
                                <span
                                    className="activity-left-info-preview__address-service-text"
                                    style={txtStyle}
                                >
                                    联系客服
                                </span>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    renderInitiator() {
        const { preview } = this.props;

        const { themeStyle } = this.state;
        const style = isSimplicityTheme(themeStyle)
            ? {
                  borderTop: '1px solid #000',
              }
            : {};
        const boxStyle = isSimplicityTheme(themeStyle)
            ? {
                  border: '1px solid #000',
                  borderRadius: '8px',
              }
            : {
                  background:
                      '#FFFFFF linear-gradient(270deg, rgba(243,240,255,0.48) 0%, rgba(250,243,250,0.49) 48%, rgba(255,239,241,0.6) 100%)',
                  borderRadius: '8px',
              };
        const buttonStyle = getButtonStyleByTheme(themeStyle);

        if (!preview || !preview.contentInfo || !preview.contentInfo.creator) return;

        return (
            <div className="activity-left-info-preview__creator" style={style}>
                <span className="activity-left-info-preview__creator-title">活动发起人</span>
                <div className="activity-left-info-preview__creator-box" style={boxStyle}>
                    <div className="activity-left-info-preview__creator-detail">
                        <div className="activity-left-info-preview__creator-detail-user">
                            <img
                                alt=""
                                src="./images/avatar/mall-activity-avatar.png"
                                className="activity-left-info-preview__creator-detail-user-avatar"
                            ></img>
                            <span className="activity-left-info-preview__creator-detail-user-name">
                                {'用户昵称'}
                            </span>
                        </div>
                        <div
                            className="activity-left-info-preview__creator-detail-follow"
                            style={buttonStyle}
                        >
                            <span className="activity-left-info-preview__creator-detail-follow-icon">
                                +
                            </span>
                            <span className="activity-left-info-preview__creator-detail-follow-text">
                                关注
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    renderTabs() {
        const { themeStyle } = this.state;
        const tabStyle = isClassicTheme(themeStyle)
            ? {}
            : {
                  top: -2,
              };
        const innerStyle = isSimplicityTheme(themeStyle)
            ? {
                  borderTop: '1px solid #000',
                  borderColor: '#000',
              }
            : {};
        return (
            <div className="activity-left-info-preview__tabs" style={tabStyle}>
                <div className="activity-left-info-preview__tabs-wrap" style={innerStyle}>
                    {['活动须知', '活动介绍'].map((item, index) => {
                        const innerClassName = classNames(`activity-left-info-preview__tab`, {
                            [`activity-left-info-preview__tab--select`]: index === 0,
                        });
                        const tabItemClassName = `activity-left-info-preview__tab__item`;
                        const tabItemCurClassName = `activity-left-info-preview__tab__item__cur`;
                        let curStyle: any =
                            index === 0
                                ? {
                                      backgroundColor: getColorByTheme(themeStyle, 'primary'),
                                  }
                                : {};
                        if (isSimplicityTheme(themeStyle)) {
                            curStyle = {
                                ...curStyle,
                                bottom: '10px',
                                width: '4px',
                                borderRadius: '2px',
                                backgroundColor: index === 0 ? '#000' : 'transparent',
                            };
                        }
                        return (
                            <div className={innerClassName} key={index}>
                                <div className={tabItemClassName}>{item}</div>
                                <div className={tabItemCurClassName} style={curStyle}></div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    renderActivityTime() {
        const { preview } = this.props;

        const startTime = (get(preview, 'baseInfo.dateRange.start') || '').replace(/-/g, '.');
        const endTime = (get(preview, 'baseInfo.dateRange.end') || '').replace(/-/g, '.');
        const dayTime = get(preview, 'baseInfo.dayTime')
            ? get(preview, 'baseInfo.dayTime')[0]
            : undefined;
        const dailyStartTime = (get(dayTime, 'start') || '').substring(0, 5);
        const dailyEndTime = (get(dayTime, 'end') || '').substring(0, 5);

        const endTimeType = preview?.registrationInfo?.registrationEndTime?.timeType;
        const startTimeType = preview?.registrationInfo?.registrationStartTime?.timeType;

        let regisStartTime = '';
        let regisEndTime = '';
        let startTimeUnit = '';
        let startTimeValue = '';
        let endTimeUnit = '';
        let endTimeValue = '';
        if (startTimeType === RegistrationTimeType.DAY_TIME) {
            regisStartTime = get(preview, 'registrationInfo.registrationStartTime.timeStr') || '';
        } else {
            startTimeUnit = get(preview, 'registrationInfo.registrationStartTime.timeUnit');
            startTimeValue = get(preview, 'registrationInfo.registrationStartTime.num');
        }
        if (endTimeType === RegistrationTimeType.DAY_TIME) {
            regisEndTime = get(preview, 'registrationInfo.registrationEndTime.timeStr');
        } else {
            endTimeUnit = get(preview, 'registrationInfo.registrationEndTime.timeUnit');
            endTimeValue = get(preview, 'registrationInfo.registrationEndTime.num');
        }

        const timeType = get(preview, 'registrationInfo.session') === 'YES' ? '场次' : '活动';
        switch (startTimeType) {
            case 'DAY_TIME':
                regisStartTime = (regisStartTime || '').replace(/\\-/g, '.');
                break;
            case 'BEFORE_BEGIN':
                regisStartTime = `${timeType}${services.language.getText('ksq')}`;
                break;
            case 'AFTER_BEGIN':
                regisStartTime = `${timeType}开始后`;
                break;
            case 'BEFORE_END':
                regisStartTime = `${timeType}结束前`;
                break;
            default:
                break;
        }
        switch (endTimeType) {
            case 'DAY_TIME':
                regisEndTime = (regisEndTime || '').replace(/\\-/g, '.');
                break;
            case 'BEFORE_BEGIN':
                regisEndTime = `${timeType}${services.language.getText('ksq')}`;
                break;
            case 'AFTER_BEGIN':
                regisEndTime = `${timeType}开始后`;
                break;
            case 'BEFORE_END':
                regisEndTime = `${timeType}结束前`;
                break;
            default:
                break;
        }
        if (startTimeValue) {
            regisStartTime = `${regisStartTime}${startTimeValue}${TIME_UNIT[startTimeUnit]}`;
        }
        const registeStartTime = regisStartTime.replace(/-/g, '.');

        if (endTimeValue) {
            regisEndTime = `${regisEndTime}${endTimeValue}${TIME_UNIT[endTimeUnit]}`;
        }
        const registeEndTimeTxt = regisEndTime.replace(/-/g, '.');

        let birthdayStartDate = '';
        let birthdayEndDate = '';
        let gender = '';
        let memberCardLevels = '';
        if (get(preview, 'registrationInfo.targetUserType') === 'SOME') {
        } // 生日
        if (preview?.registrationInfo?.targetUserBirthDay) {
            birthdayStartDate = preview?.registrationInfo?.targetUserBirthDay?.from;
            birthdayEndDate = preview?.registrationInfo?.targetUserBirthDay?.to;
        }
        // 会员等级
        if (preview?.registrationInfo?.targetUserLevels?.length) {
            memberCardLevels = preview?.registrationInfo?.targetUserLevels
                .map((i: any) => i.name)
                .join('、');
        }
        // 性别
        if (preview?.registrationInfo?.targetUserSexuality) {
            gender = preview?.registrationInfo?.targetUserSexuality;
        }

        const birthString =
            birthdayStartDate && birthdayEndDate
                ? `生日在${birthdayStartDate.split('-')[0]}月${birthdayStartDate.split('-')[1]}日-${
                      birthdayEndDate.split('-')[0]
                  }月${birthdayEndDate.split('-')[1]}日之间的用户`
                : '';

        return (
            <div className="activity-left-info-preview__tips">
                <span className="activity-left-info-preview__content-title">活动须知</span>
                <span className="activity-left-info-preview__tips-title">报名时间：</span>
                <span className="activity-left-info-preview__tips-val">
                    {preview?.registrationInfo?.registrationType === 'YES'
                        ? ` ${registeStartTime} - ${registeEndTimeTxt}`
                        : '不需要报名'}
                </span>
                <span className="activity-left-info-preview__tips-title">活动时间</span>
                <span className="activity-left-info-preview__tips-val">
                    {startTime === endTime ? startTime : `${startTime}-${endTime}`} {dailyStartTime}
                    -{dailyEndTime}
                </span>
                {memberCardLevels && (
                    <div>
                        <span className="activity-left-info-preview__tips-title">
                            报名限制等级:
                        </span>
                        <span className="activity-left-info-preview__tips-val">
                            {memberCardLevels}用户可参与
                        </span>
                    </div>
                )}
                {birthString && (
                    <div>
                        <span className="activity-left-info-preview__tips-title">
                            报名限制生日范围:
                        </span>
                        <span className="activity-left-info-preview__tips-val">{birthString}</span>
                    </div>
                )}
                {gender && (
                    <div>
                        <span className="activity-left-info-preview__tips-title">
                            报名限制用户身份:
                        </span>
                        <span className="activity-left-info-preview__tips-val">
                            {GENDER[gender]}
                        </span>
                    </div>
                )}
            </div>
        );
    }

    renderActivityInfo() {
        const { preview } = this.props;
        const innerClassNames = {
            wrap: `activity-left-info-preview`,
            header: `activity-left-info-preview__header`,
            headerTitle: `activity-left-info-preview__header-title`,
            headerNone: `activity-left-info-preview__header-none`,
            body: `activity-left-info-preview__body`,
            divider: `activity-left-info-preview__divider`,
        };
        const id = `activity-left-info-preview`;
        return (
            <div className={innerClassNames.wrap} id={id}>
                <div className={innerClassNames.divider}></div>
                <div className={innerClassNames.header}>
                    <div className={innerClassNames.headerTitle}>活动介绍</div>
                    {!preview?.mediaInfo?.info && (
                        <div className={innerClassNames.headerNone}>暂无介绍</div>
                    )}
                </div>
                <div
                    className={innerClassNames.body}
                    dangerouslySetInnerHTML={{ __html: preview?.mediaInfo?.info }}
                ></div>
            </div>
        );
    }
    renderButton() {
        const { preview = {} } = this.props;
        const { themeStyle } = this.state;
        const buttonStyle = getButtonStyleByTheme(themeStyle);
        if (preview?.registrationInfo?.registrationType === 'YES') {
            return (
                <div className="activity-left-info-preview__bottom">
                    <div className="activity-left-info-preview__btn" style={buttonStyle}>
                        <span className="activity-left-info-preview__btn-text">立即报名</span>
                    </div>
                </div>
            );
        }
        return null;
    }
    render() {
        const { themeStyle } = this.state;

        return (
            <div className="activity-left-info-preview">
                {this.renderBanner()}
                {this.renderInfo()}
                {this.renderAddress()}
                {this.renderInitiator()}
                {isClassicTheme(themeStyle) && (
                    <div className="activity-left-info-preview__divider"></div>
                )}
                {this.renderTabs()}
                {this.renderActivityTime()}
                {this.renderActivityInfo()}
                {this.renderButton()}
            </div>
        );
    }
}

const mapStateToProps = (_state: any, props: any) => {
    let preview = null;
    const entityId = props.entity.id;
    for (var compId in _state.components) {
        const comp = _state.components[compId];
        if (
            (comp.type === 'CreateForm' || comp.type === 'EditForm') &&
            comp.entityId === entityId
        ) {
            preview = comp.fields;
        }
    }

    return { preview: preview };
};

export const MallActivityLeftInfoView = connect(mapStateToProps)(mallActivityLeftInfoView);
