import React, { PureComponent } from 'react';
import { Row, Checkbox } from 'antd';
import { cloneDeep } from 'lodash';

// 会员中心服务项
export class ServiceItem extends PureComponent<{
    sourceRoot?: {
        image?: string;
    };
    items?: Array<any>;
    index?: number;
    onChange?: (value: any) => void;
    item?: any;
    uploadAction?: string;
    linkTypes: Array<{ id: string; name: string }>;
    effectiveTime?: { start: string; end: string };
    selector: any;
}> {
    state = {
        uploadLoading: false,
    };
    change = () => {
        const { item, items, index, onChange } = this.props;
        item.checked = !item.checked;
        let newItems: any = cloneDeep(items);
        newItems.splice(index, 1, item);
        onChange && onChange(newItems);
    };

    render() {
        const { item } = this.props;
        return (
            <Row className="entry-item">
                <label>
                    <Checkbox checked={item.checked} onChange={this.change}></Checkbox>
                    <span className="service-item-name">{item.title}</span>
                </label>
            </Row>
        );
    }
}
