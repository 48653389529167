import { createElement, PureComponent } from 'react';
import {
    mapValues,
    defaults,
    forEach,
    some,
    isFunction,
    get,
    reduce,
    clone,
    debounce,
    forIn,
    isEmpty,
    map,
    set,
} from 'lodash';

import { connect } from 'react-redux';
import { ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { getField } from '@comall-backend-builder/components-basis/lib/form';
import { actions, builder, services } from '@comall-backend-builder/core';
import { Modal as AntModal } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services';

let COMPONENT_UUID: string;
let uuids: any = {};
let submitStatus = false;

/**
 * 扩展 CreateForm、ModifyForm 组件，支持 field.visible 动态设置field是否展示，onValidate提交前校验
 */
class dataFormPlus extends PureComponent<any> {
    constructor(props: any) {
        super(props);
        if (props.componentId) {
            COMPONENT_UUID = 'DataFormPlus-' + props.componentId;
        } else {
            COMPONENT_UUID = 'DataFormPlus-' + services.uuid();
        }
        uuids[props.componentId] = COMPONENT_UUID;
    }

    inited = false;
    needCheckModifiable = false;

    componentWillUnmount() {
        this.props.unmountComponent();
    }

    getConfig() {
        const { fields, fieldValues, entity, isAdd, ...rest } = this.props;
        const properties = entity && entity.metainfo ? entity.metainfo.properties : null;
        let newFields: any[] = [];
        const filterFields = this.inited && some(fields, (i) => i.visible);
        if (filterFields) {
            forEach(fields, (field) => {
                if (field.visible) {
                    if (
                        typeof field.visible === 'function' &&
                        field.visible.call(null, fieldValues || {})
                    ) {
                        newFields.push(field);
                    }
                    if (typeof field.visible === 'boolean' && field.visible) {
                        newFields.push(field);
                    }
                } else {
                    newFields.push(field);
                }
            });
        } else {
            newFields = fields;
        }
        this.needCheckModifiable &&
            forEach(newFields, (field) => {
                if (isFunction(field.modifiable)) {
                    const disabled = !field.modifiable.call(null, fieldValues || {});
                    const propertyPath = field.property.split('.').join('.properties.');
                    const controlConfig = get(properties, propertyPath + '.controlConfig');
                    field.controlConfig = Object.assign({}, controlConfig, field.controlConfig, {
                        disabled,
                    });
                }
            });
        if (!this.inited) {
            this.needCheckModifiable = some(fields, (i) => isFunction(i.modifiable));
        }
        this.inited = true;
        forEach(newFields, (field) => {
            const propertyPath = field.property.split('.').join('.properties.');
            const controlConfig = get(properties, propertyPath + '.controlConfig');
            field.controlConfig = Object.assign({}, controlConfig, field.controlConfig, {
                changeField: rest.changeFiled,
                isAdd,
            });
        });
        return {
            ...rest,
            entity,
            fields: newFields,
            componentId: COMPONENT_UUID,
        };
    }

    render() {
        const config = this.getConfig();
        const { formType } = this.props;
        return createElement(ComponentsManager.get(formType), config);
    }
}

export function getSubmitStatus() {
    return submitStatus;
}

export function changeSubmitStatus(status: boolean) {
    submitStatus = status;
}

function mapStateToProps(_state: any) {
    const fieldValues =
        _state.components[COMPONENT_UUID] && _state.components[COMPONENT_UUID].fields;
    return { fieldValues };
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const {
        onSubmit,
        entity,
        params,
        onValidate,
        onSubmitSuccess,
        componentId,
        onSubmitError,
        submit,
        formType,
        onSetSubmitConfirmProps,
        loadDefaultValue,
        fields: fieldsFromProps,
        onFieldChange,
        isAdd = true,
    } = props;

    let modifyFormSubmitProps = submit;
    const isModifyForm = formType === 'ModifyForm';

    if (!isAdd) {
        if (
            modifyFormSubmitProps === true ||
            modifyFormSubmitProps?.text === language.getText('common.submit')
        ) {
            modifyFormSubmitProps = { text: language.getText('common.save') };
        } else if (modifyFormSubmitProps?.text === language.getText('common.submit')) {
            modifyFormSubmitProps.text = language.getText('common.save');
        }
    }
    const onInit = async () => {
        let {
            id: entityId,
            metainfo: { properties },
            fields: entityFields,
        } = entity;
        let fields: { [key: string]: any } = {};
        map(fieldsFromProps, (field) => {
            let { property } = field;
            // 从实体中获取属性配置，实体中的 path 为 style.properties.color，配置中 path 为 style.color
            let entityProperty = get(properties, property.replace(/\./g, '.properties.'));
            if (entityProperty) {
                const value = isModifyForm
                    ? get(entityFields, property)
                    : entityProperty.defaultValue || undefined;
                set(fields, property, value);
            } else {
                throw new Error(`Property ${property} not found in Entity ${entityId}`);
            }
        });
        const state: any = {
            type: isModifyForm ? 'EditForm' : 'CreateForm',
            entityId: entity.id,
            fields,
        };
        if (isModifyForm) {
            state.loaded = !!entityFields;
        }
        _dispatch(actions.formInitAction(uuids[componentId], state));
        if (!isModifyForm && isFunction(loadDefaultValue)) {
            const values = await loadDefaultValue();
            if (!isEmpty(values)) {
                forIn(values, (value: any, name: any) => {
                    _dispatch(actions.formChangeAction(uuids[componentId], name, value));
                });
            }
        }
    };
    return defaults(
        {
            onSubmit,
            onFieldChange: onFieldChange?.bind(null, componentId, uuids),
            isAdd,
        },
        {
            onSubmit: debounce((event: any, fields: any) => {
                if (submitStatus) return;
                changeSubmitStatus(true);
                const entityFields = mapValues(fields, (field, name) => {
                    return field.value;
                });

                function commitConfirm(): void {
                    if (onSetSubmitConfirmProps) {
                        const submitConfirmProps = onSetSubmitConfirmProps(entityFields);
                        if (submitConfirmProps) {
                            const confirmProps = {
                                ...submitConfirmProps,
                                onOk: () => {
                                    return commit();
                                },
                                onCancel: () => {
                                    changeSubmitStatus(false);
                                },
                            };
                            AntModal.confirm(confirmProps);
                        } else {
                            return commit();
                        }
                    } else {
                        return commit();
                    }
                }

                function commit(): void {
                    const formType = props.formType;
                    if (formType === 'CreateForm') {
                        entity.add(entityFields, params);
                    }
                    if (formType === 'ModifyForm') {
                        entity.modify(entityFields, params);
                    }
                }

                if (entityFields) {
                    // onValidate 不存在时无需校验，提交
                    if (!onValidate) {
                        return commitConfirm();
                    }

                    const result = onValidate(entityFields);

                    if (result) {
                        // 校验结果为真且不为 promise，提交
                        if (!isFunction(result.then)) {
                            return commitConfirm();
                        }
                        // 校验结果为 promise，且 promise 返回值为真，提交
                        result.then((_result?: boolean) =>
                            _result ? commitConfirm() : changeSubmitStatus(false)
                        );
                    } else {
                        changeSubmitStatus(false);
                    }
                }
            }, 300),
            unmountComponent: () => {
                builder.getStore().dispatch(actions.unmountComponentAction(uuids[componentId]));
                delete uuids[componentId];
                changeSubmitStatus(false);
            },
            onSubmitSuccess: (fields: any, submitType: any) => {
                setTimeout(() => {
                    changeSubmitStatus(false);
                }, 3100);
                if (onSubmitSuccess) {
                    onSubmitSuccess(fields, submitType, entity);
                }
            },
            onSubmitError: (fields: any, submitType: any) => {
                setTimeout(() => {
                    changeSubmitStatus(false);
                }, 3100);
                if (onSubmitError) {
                    onSubmitError(fields, submitType, entity);
                }
            },
            onInit,
            // 重新加载属性候选值
            onReloadOptions: (fieldName: any, fields: any) => {
                //? 因为在这个场景下reload时fields可能已经不在dataTable上了
                //? 会导致修改被依赖字段未触发获取依赖方获取数据
                //? 所以这里改成从entity上取需要的参数
                const isSubform = fieldName.split('.').length > 1;
                const fieldNamePath = isSubform
                    ? fieldName.replace(/\./g, '.properties.')
                    : fieldName;
                const field = get(entity, `metainfo.properties.${fieldNamePath}`);

                if (!field) return;
                const sourceDefination = field.source;
                let dependences = sourceDefination.dependences;
                let params = reduce(
                    dependences,
                    (values, dependence) => {
                        values[dependence] = getField(dependence, fields).value;
                        return values;
                    },
                    props.params ? clone(props.params) : {}
                );
                entity.loadPropertyOptions(fieldName, sourceDefination, params);
            },
            // 表单域改变
            changeFiled: (name: any, value: any) => {
                _dispatch(actions.formChangeAction(uuids[componentId], name, value));
            },
            submit: formType === 'ModifyForm' ? modifyFormSubmitProps : submit,
        }
    );
}
export const DataFormPlus = connect(mapStateToProps, mapDispatchToProps)(dataFormPlus);
