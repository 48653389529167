import React, { PureComponent } from 'react';
import { message as AntMessage, Button, Select, InputNumber, Modal, Input } from 'antd';
import { isArray, map } from 'lodash';
import { services } from '@comall-backend-builder/core';
import './index.less';

const api = services.api;

const POINT_TRANS_ACTION = [
    {
        id: 'IN',
        name: 'zj_2',
    },
    {
        id: 'OUT',
        name: 'js_1',
    },
];

/**
 * 会员个人信息-金币明细-修改金币
 */
export class MemberGoldDetailEdit extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            availableGold: 0,
            cumulativeGold: 0,
            showModal: false,
            transType: 'IN',
            rewardValue: 1,
            remake: '',
            loading: false,
        };
    }
    componentDidMount() {
        this.loadData();
    }
    private loadData = () => {
        const { params } = this.props;
        const id = params.id;
        //获取详细信息
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`,
                apiPath: `/admin/gold_accounts/${id}/statistics`,
            }
        ).then((res: any) => {
            this.setState({
                availableGold: res.availableGold,
                cumulativeGold: res.cumulativeGold,
            });
        });
    };

    private renderEditModal = () => {
        const { transType, rewardValue, remake } = this.state;
        return (
            <div className="edit-modal">
                <div className="modal-row">
                    <span className="row-title">
                        <span className="required">*</span>
                        {services.language.getText('xgjb')}
                    </span>
                    <Select
                        className="trans-action-selection"
                        style={{ width: 130 }}
                        value={transType}
                        placeholder={services.language.getText('selectPlease')}
                        onChange={(transType: any) => this.selectedTransAction(transType)}
                    >
                        {isArray(POINT_TRANS_ACTION) &&
                            map(POINT_TRANS_ACTION, ({ id, name }) => (
                                <Select.Option key={id}>
                                    {services.language.getText(name)}
                                </Select.Option>
                            ))}
                    </Select>
                    <div className="number-input">
                        <InputNumber
                            placeholder={services.language.getText('inputPlease')}
                            min={1}
                            max={999999}
                            step={1}
                            precision={0}
                            style={{ width: '130px' }}
                            value={rewardValue}
                            onChange={(value: any) => {
                                this.changeTransAmount(value);
                            }}
                        ></InputNumber>
                    </div>
                </div>
                <div className="edit-tip">{services.language.getText('qsrclwzzs')}</div>
                <div className="modal-row">
                    <span className="row-title">
                        <span className="required">*</span>
                        {services.language.getText('remark')}：
                    </span>
                    <Input.TextArea
                        value={remake}
                        style={{ width: 500 }}
                        placeholder={services.language.getText('jzdsrxbzzjyy')}
                        rows={4}
                        maxLength={50}
                        onChange={this.changeRemark}
                    />
                </div>
            </div>
        );
    };
    private selectedTransAction = (transType: any) => {
        this.setState({ transType });
    };
    private changeTransAmount = (rewardValue: number) => {
        this.setState({ rewardValue });
    };
    private changeRemark = (e: any) => {
        this.setState({ remake: e.target.value });
    };
    private onConfirmEditGold = () => {
        const { params, entity } = this.props;
        const id = params.id;
        const { transType, rewardValue, remake, loading } = this.state;
        if (!transType) {
            AntMessage.warn(services.language.getText('qxzjblx'));
            return;
        }
        if (!rewardValue) {
            AntMessage.warn(services.language.getText('qtxjb'));
            return;
        }
        const reg = /^\+?[0-9]\d*$/;
        if (!reg.test(rewardValue)) {
            AntMessage.warn(services.language.getText('qtxdydy'));
            return;
        }
        if (!remake) {
            AntMessage.warn(services.language.getText('qtxbz'));
            return;
        }
        if (remake && remake.length > 50) {
            AntMessage.warn(services.language.getText('bzzdsr'));
        }
        this.setState({ loading: true });
        if (loading) {
            return;
        }
        api.put(
            { transType, rewardValue, remake },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`,
                apiPath: `/admin/gold_accounts/${id}/change`,
            }
        )
            .then(
                () => {
                    AntMessage.success(services.language.getText('common.modifySuccess'), () => {
                        this.onCancel();
                        entity.search();
                        this.loadData();
                        this.setState({ transType: 'IN', rewardValue: 1, remake: '' });
                    });
                },
                () => {
                    AntMessage.error(services.language.getText('modifyFailWait'), () => {
                        this.onCancel();
                        entity.search();
                        this.loadData();
                        this.setState({ transType: 'IN', rewardValue: 1, remake: '' });
                    });
                }
            )
            .finally(() => {
                this.setState({ loading: false });
            });
    };
    private onCancel = () => {
        this.setState({ showModal: false });
    };
    private openModal = () => {
        this.setState({ showModal: true });
    };

    render() {
        const { availableGold, cumulativeGold, showModal } = this.state;
        const props = {
            width: 720,
            visible: showModal,
            title: services.language.getText('xgjb'),
            onCancel: this.onCancel,
            onOk: this.onConfirmEditGold,
        };
        return (
            <div className="member-gold-trans-log-view">
                <div className="member-golds-wrap">
                    <div className="member-golds-item">
                        <div>{cumulativeGold}</div>
                        <div>{services.language.getText('ljjb')}</div>
                    </div>
                    <div className="member-golds-item member-golds-edit">
                        <div className="">
                            <span>{availableGold}</span>
                            <div>{services.language.getText('kyjb')}</div>
                        </div>
                        <Button
                            className="member-golds-edit-button"
                            type="default"
                            onClick={this.openModal}
                        >
                            {services.language.getText('xgjb')}
                        </Button>
                    </div>
                </div>
                <Modal {...props}>
                    <div>{this.renderEditModal()}</div>
                </Modal>
            </div>
        );
    }
}
