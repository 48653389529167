/*
 * @Author: zhuhuajuan
 * @Description: 停车权益
 */
import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

// 实体
const memberBenefit = {
    apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`,
    apiPath: '/loader/admin/memberBenefits',
    properties: {
        id: { type: 'string', displayName: 'ID' },
        subsiteName: {
            type: 'string',
            displayName: '<<subsiteName>>',
        },
        typeName: {
            type: 'string',
            displayName: '<<qylx>>',
        },
        subsiteId: {
            type: 'string.options.select',
            displayName: '<<otherSelectSubsite>>',
            controlConfig: { style: { width: 240 } },
            options: [],
            source: {
                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`,
                apiPath: `/admin/member_benefits/subsites?type=CARPARK_BENEFITS`,
            },
            rules: [{ required: true }],
        },
        baseInfo: {
            type: 'object.subForm',
            displayName: '<<baseInfo>>',
        },
        benefitInfo: {
            type: 'object.subForm',
            displayName: '<<tcqydkgz>>',
            properties: {
                enableConsumeDeductions: {
                    type: 'string.options.radio',
                    displayName: '<<hyqy>>',
                    defaultValue: 'false',
                    options: [
                        {
                            id: 'false',
                            name: '<<bsy>>',
                        },
                        {
                            id: 'true',
                            name: '<<sy_1>>',
                        },
                    ],
                },
                deductionType: {
                    type: 'string.options.radio',
                    displayName: '<<dklx>>',
                    defaultValue: 'SPECIFY_VALUE',
                    options: [
                        {
                            id: 'SPECIFY_VALUE',
                            name: '<<tcje>>',
                        },
                        {
                            id: 'HOUR',
                            name: '<<tcsz>>',
                        },
                    ],
                },
                consumeDeductionBenefits: {
                    type: 'object.carparkDeductionRules',
                    displayName: '<<dkgz>>',
                    options: [],
                    source: {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                        apiPath: '/loader/admin/storeCardLevelsBenefit',
                        dependences: ['subsiteId'],
                        params: {
                            loadDependences: 'true',
                        },
                    },
                },
                canUseCoupons: {
                    type: 'string.options.radio',
                    displayName: '<<parking>>',
                    defaultValue: 'true',
                    options: [
                        {
                            id: 'false',
                            name: '<<bsy>>',
                        },
                        {
                            id: 'true',
                            name: '<<sy_1>>',
                        },
                    ],
                },
                deductionTimePeriod: {
                    type: 'object.timeRangePlus',
                    displayName: '<<yhsd>>',
                    extra: '<<tcqsxsjndmryhsd>>',
                },
                stackedUse: {
                    type: 'object.stackedUse',
                    displayName: '<<tcqdjsy>>',
                    defaultValue: {
                        state: true,
                        count: 10,
                    },
                    extra: '<<yhkytssydztcq>>',
                },
                enableDiscountLimit: {
                    type: 'string.options.radio',
                    displayName: '<<mryhsx>>',
                    defaultValue: 'false',
                    options: [
                        {
                            id: 'false',
                            name: '<<bxz>>',
                        },
                        {
                            id: 'true',
                            name: '<<xz>>',
                        },
                    ],
                },
                discountLimit: {
                    type: 'object.discountLimit',
                    displayName: '<<yhsxsz>>',
                },
                enablePointDeduction: {
                    type: 'string.options.radio',
                    displayName: '<<jfdk>>',
                    defaultValue: 'false',
                    options: [
                        {
                            id: 'false',
                            name: '<<bsy>>',
                        },
                        {
                            id: 'true',
                            name: '<<sy_1>>',
                        },
                    ],
                },
                pointDeduction: {
                    type: 'object.pointDeduction',
                    displayName: '<<pointRuleSet>>',
                },
                enableBalanceCouponBenefits: {
                    type: 'string.options.radio',
                    displayName: '<<yeqdk>>',
                    defaultValue: 'false',
                    options: [
                        {
                            id: 'false',
                            name: '<<bsy>>',
                        },
                        {
                            id: 'true',
                            name: '<<sy_1>>',
                        },
                    ],
                },
                balanceCoupons: {
                    type: 'array.balanceCouponsMode',
                    displayName: '<<yeqdklx>>',
                    defaultValue: [],
                },
            },
        },
        bindBenefitInfo: {
            type: 'object.subForm',
            displayName: '<<bdyjbcpqy>>',
            properties: {
                maxBindQuantity: {
                    type: 'number.integerInputTitleTip',
                    displayName: '<<maxBindQuantity>>',
                    displayConfig: {
                        addonbefore: '<<yhkybd>>',
                        addonafter: '<<gcp>>',
                    },
                    controlConfig: {
                        addonbefore: '<<yhkybd>>',
                        addonafter: '<<gcp>>',
                        rules: [
                            {
                                type: 'number',
                            },
                        ],
                    },
                },
                enableLimitUnbind: {
                    type: 'string.options.radio',
                    displayName: '<<jbcpcs>>',
                    defaultValue: 'false',
                    options: [
                        {
                            id: 'false',
                            name: '<<bxzjbcs>>',
                        },
                        {
                            id: 'true',
                            name: '<<xzjbcs>>',
                        },
                    ],
                },
                unbindPeriod: {
                    type: 'string.options.radio',
                    displayName: '<<jbzq>>',
                    defaultValue: 'MONTH',
                    options: [
                        {
                            id: 'MONTH',
                            name: '<<myjb>>',
                        },
                        {
                            id: 'YEAR',
                            name: '<<mnjb>>',
                        },
                    ],
                },
                unbindBenefits: {
                    type: 'array.MemberLevelParkUnbind',
                    displayName: '<<jbgz_1>>',
                    options: [],
                    source: {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                        apiPath: '/loader/admin/storeCardLevelsBenefit',
                        dependences: ['subsiteId'],
                        params: {
                            loadDependences: 'true',
                        },
                    },
                },
            },
        },
    },
    filters: {
        subsiteName: {
            type: 'object.option.autoComplete',
            displayName: '<<subsiteName>>',
            options: [],
            source: {
                apiPath: '/admin/defaults/subsite',
            },
            controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
        },
    },
};

export let config: Config = {
    entities: {
        memberBenefit,
    },
    components: {
        MemberBenefitView: {
            component: 'Viewport',
            entity: 'memberBenefit',
        },
        MemberBenefitPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [{ component: 'MemberBenefitFilter' }, { component: 'MemberBenefitTable' }],
        },
        MemberBenefitFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteName',
                },
            ],
        },
        MemberBenefitTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'Button',
                    text: '<<xzqy>>',
                    route: '/member-benefit/add',
                    type: 'primary',
                    icon: 'plus',
                    style: {
                        width: 140,
                    },
                    className: 'mt24 mb24',
                },
                {
                    component: 'DataTable',
                    componentId: 'MemberBenefitDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'typeName',
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        items: [
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                path: '/member-benefit/edit/{{row.id}}',
                            },
                            {
                                text: '<<common.log>>',
                                type: 'link',
                                path: '/member-benefit/logs/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        MemberBenefitAddPage: {
            component: 'FlexLayout',
            entity: 'memberBenefit',
            direction: 'horizontal',
            items: [{ component: 'MemberBenefitAddForm' }],
        },
        MemberBenefitEditPage: {
            component: 'FlexLayout',
            entity: 'memberBenefit',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'MemberBenefitEditForm' }],
        },
        MemberBenefitLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`,
                    apiPath: '/admin/member_benefits/{id}/operation_logs',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/member-benefit',
                    breadcrumbName: '<<memberBenefits>>',
                    component: 'MemberBenefitView',
                    privilege: {
                        path: 'memberBenefits',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add/',
                            component: 'MemberBenefitAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'MemberBenefitEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        {
                            path: '/logs/:id',
                            component: 'MemberBenefitLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        { path: '/', component: 'MemberBenefitPage' },
                    ],
                },
            ],
        },
    ],
};
