import { PureComponent, createElement } from 'react';
import { services, actions, builder, Loader } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage, Modal } from 'antd';
import { get, reduce, clone, mapValues, cloneDeep, isNil } from 'lodash';
import { getField } from '@comall-backend-builder/components-basis/lib/form';
import { connect } from 'react-redux';
import moment from 'moment';
import { FormComponentProps } from 'antd/lib/form';
import { RegistrationPriceType } from '../../components/registration-price';
import { transformRequestData } from '../../loaders/mall-activity-loader';
import { ErrorInfo } from '../../applications/cae/services/error-handle';
const api = services.api;

export interface MallActivityEditFormProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
    saleLimitType: any;
    data: any;
    onReloadOptions: (fieldName: any, fields: any) => void;
}
enum SessionType {
    /**
     * 否
     */
    NO = 'NO',
    /**
     * 是
     */
    YES = 'YES',
}

class mallActivityEditFormView extends PureComponent<MallActivityEditFormProps> {
    state = { haveShow: false };
    componentDidMount() {
        const apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`;
        const apiPath = `/admin/mall_activities/have_show`;
        services.api.get({}, { apiRoot, apiPath }).then((res: any) => {
            if (res) {
                this.setState({ haveShow: true });
            }
        });
    }

    componentWillUnmount() {
        const { unmountComponent } = this.props;
        if (unmountComponent) {
            unmountComponent();
        }
    }

    render() {
        const { entity, onSubmit, wrappedComponentRef, data, onReloadOptions } = this.props;
        const { haveShow } = this.state;

        const isRegistrationStarted = data.registrationStatus !== 'NOT_STARTED';
        const isActivityStarted = data.mallActivityStatus !== 'NOT_STARTED';

        const needRegistration = get(data, 'registrationInfo.registrationType') === 'YES';
        const endTime = get(data, 'baseInfo.editEndTime');
        let disableEditStartTime = isActivityStarted;
        const bySession = needRegistration && get(data, 'registrationInfo.session') === 'YES';
        const dayTime = get(data, 'baseInfo.dayTime[0]');
        const dateRange = get(data, 'baseInfo.dateRange');
        const registrationTimes = get(data, 'registrationInfo.registrationTimes', []);
        let fields = [
            {
                property: 'baseInfo.name',
                controlConfig: {
                    placeholder: services.language.getText('activityNameRequired100'),
                    maxLength: 100,
                    style: {
                        width: 450,
                    },
                },
            },
            {
                property: 'baseInfo.editStartTime',
                controlConfig: {
                    disabled: !bySession && disableEditStartTime,
                    format: 'YYYY-MM-DD',
                    range: {
                        end: endTime ? endTime : undefined,
                    },
                    onChange(val: any) {
                        if (val && bySession) {
                            let startData = dateRange?.start;
                            if (startData && val < startData) {
                                const store = builder.getStore();
                                const diffDays = moment(startData).diff(moment(val), 'days');
                                const newRegistrationTimes: any = cloneDeep(registrationTimes);
                                for (let i = 0; i < diffDays; i++) {
                                    const item: any = {
                                        startDate: moment(val, 'YYYY-MM-DD')
                                            .subtract(0 - i, 'days')
                                            .format('YYYY-MM-DD'),
                                        enable: true,
                                    };
                                    if (dayTime) {
                                        item.startTime = dayTime.start;
                                        item.endTime = dayTime.end;
                                    }
                                    //避免多次修改结束时间导致带出重复场次数据
                                    let index = newRegistrationTimes.findIndex(
                                        (i: any) => i.startDate === item.startDate
                                    );
                                    if (index === -1) {
                                        newRegistrationTimes.push(item);
                                    }
                                }
                                store.dispatch(
                                    actions.formChangeAction(
                                        'DataFormPlus-MallActivityEditFormContainer',
                                        'registrationInfo.registrationTimes',
                                        newRegistrationTimes
                                    )
                                );
                            }
                        }
                    },
                },
            },
            {
                property: 'baseInfo.editEndTime',
                controlConfig: {
                    format: 'YYYY-MM-DD',
                    range: {
                        start: new Date(),
                    },
                    onChange(val: any) {
                        if (val && bySession) {
                            let endData = dateRange?.end;
                            if (endData && val > endData) {
                                const store = builder.getStore();
                                const diffDays = moment(val).diff(moment(endData), 'days');
                                //因为原选择的日期已生成场次 这里自动带出的场景往后延一天；
                                const end = moment(endData, 'YYYY-MM-DD')
                                    .subtract(-1, 'days')
                                    .format('YYYY-MM-DD');
                                const newRegistrationTimes: any = cloneDeep(registrationTimes);
                                for (let i = 0; i < diffDays; i++) {
                                    const item: any = {
                                        startDate: moment(end, 'YYYY-MM-DD')
                                            .subtract(0 - i, 'days')
                                            .format('YYYY-MM-DD'),
                                        enable: true,
                                    };
                                    if (dayTime) {
                                        item.startTime = dayTime.start;
                                        item.endTime = dayTime.end;
                                    }
                                    //避免多次修改结束时间导致带出重复场次数据
                                    let index = newRegistrationTimes.findIndex(
                                        (i: any) => i.startDate === item.startDate
                                    );
                                    if (index === -1) {
                                        newRegistrationTimes.push(item);
                                    }
                                }
                                store.dispatch(
                                    actions.formChangeAction(
                                        'DataFormPlus-MallActivityEditFormContainer',
                                        'registrationInfo.registrationTimes',
                                        newRegistrationTimes
                                    )
                                );
                            }
                        }
                    },
                },
            },
            {
                property: 'baseInfo.dayTime',
                controlConfig: {
                    onChange(val: any) {
                        if (val && val[0] && dateRange && bySession) {
                            const startTime = get(data, 'baseInfo.editStartTime');
                            const endTime = get(data, 'baseInfo.editEndTime');
                            if (startTime < dateRange.start || endTime > dateRange.end) {
                                const store = builder.getStore();
                                const newRegistrationTimes: any = cloneDeep(registrationTimes)?.map(
                                    (item: any) => {
                                        return {
                                            ...item,
                                            startTime: !!item.id ? item.startTime : val[0].start,
                                            endTime: !!item.id ? item.endTime : val[0].end,
                                            enable: !!item.id ? item.enable : true,
                                        };
                                    }
                                );
                                store.dispatch(
                                    actions.formChangeAction(
                                        'DataFormPlus-MallActivityEditFormContainer',
                                        'registrationInfo.registrationTimes',
                                        newRegistrationTimes
                                    )
                                );
                            }
                        }
                    },
                },
            },
            {
                property: 'baseInfo.merchants',
                controlConfig: {
                    uniqueSubsite: true,
                },
            },

            {
                property: 'baseInfo.addressName',
                controlConfig: {
                    placeholder: '请输入活动地点名称，不超过100个字',
                    maxLength: 100,
                    style: {
                        width: 450,
                    },
                },
            },
            {
                property: 'baseInfo.address',
                controlConfig: {
                    placeholder: '请输入详细地址，不超过100个字',
                    maxLength: 100,
                    style: {
                        width: 450,
                    },
                },
            },
            {
                property: 'baseInfo.phone',
                controlConfig: {
                    placeholder: '请输入咨询电话',
                    maxLength: 20,
                    style: {
                        width: 450,
                    },
                },
            },
            {
                property: 'baseInfo.merchantUserPhone',
                controlConfig: {
                    placeholder: '请输入店员手机号',
                    style: {
                        width: 450,
                    },
                },
            },

            {
                property: 'baseInfo.tagIdList',
                modifiable: (v: any) => get(v, 'baseInfo.merchants.length'),
            },
            {
                property: 'baseInfo.canShare',
            },
            {
                property: 'baseInfo.hide',
            },
            {
                property: 'mediaInfo.pictures',
                controlConfig: {
                    maxCount: 1,
                    multiple: false,
                    fileName: 'fileName',
                    uploadType: 'multi',
                    uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                    mode: 'picture-card',
                },
            },
            {
                property: 'mediaInfo.poster',
                controlConfig: {
                    maxCount: 1,
                    multiple: false,
                    fileName: 'fileName',
                    uploadType: 'multi',
                    uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                    mode: 'picture-card',
                },
            },
            {
                property: 'mediaInfo.info',
            },
            {
                property: 'registrationInfo.registrationType',
                controlConfig: {
                    disabled: true,
                },
            },
            {
                property: 'registrationInfo.session',
                visible: () => needRegistration,
                controlConfig: {
                    disabled: true,
                },
            },
            {
                property: 'registrationInfo.registrationStartTime',
                visible: () => needRegistration,
                controlConfig: {
                    disabled: isRegistrationStarted,
                    mode: 'BEGIN',
                    isActivityStarted: isRegistrationStarted,
                },
            },
            {
                property: 'registrationInfo.registrationEndTime',
                visible: () => needRegistration,
                controlConfig: {
                    disabled: isRegistrationStarted,
                    type: 'endTime',
                    isActivityStarted: isRegistrationStarted,
                },
            },
            {
                property: 'registrationInfo.registrationTimes',
                visible: (values: any) =>
                    needRegistration && get(values, 'registrationInfo.session') === 'YES',
                controlConfig: {
                    disabled: isRegistrationStarted,
                },
            },
            {
                property: 'registrationInfo.totalQuantity',
                visible: (values: any) =>
                    needRegistration && get(values, 'registrationInfo.session') === 'NO',
                controlConfig: {
                    style: {
                        width: 120,
                    },
                    min: 1,
                    max: 999999999,
                    addonafter: '人',
                    tip: '',
                },
            },
            {
                property: 'registrationInfo.limitCountType',
                visible: () => needRegistration,
            },
            {
                property: 'registrationInfo.limitCount',
                visible: (values: any) =>
                    needRegistration && get(values, 'registrationInfo.limitCountType') === 'YES',
                controlConfig: {
                    min: 1,
                    max: (row: any) => {
                        return get(row, 'registrationInfo.totalQuantity') || 999999999;
                    },
                    style: {
                        width: 120,
                    },
                    addonafter: '人',
                    tip: '',
                },
            },
            {
                property: 'registrationInfo.limitSession',
                visible: (values: any) =>
                    needRegistration && get(values, 'registrationInfo.session') === 'YES',
            },
            {
                property: 'registrationInfo.limitSessionCount',
                visible: (values: any) =>
                    needRegistration &&
                    get(values, 'registrationInfo.session') === 'YES' &&
                    get(values, 'registrationInfo.limitSession') === 'YES',
            },
            {
                property: 'registrationInfo.priceType',
                visible: () => needRegistration,
            },
            {
                property: 'registrationInfo.payList',
                visible: (values: any) =>
                    needRegistration &&
                    get(values, 'registrationInfo.priceType') === RegistrationPriceType.PAY,
            },
            {
                property: 'registrationInfo.targetUserType',
                visible: () => needRegistration,
            },
            {
                property: 'registrationInfo.targetUserLevels',
                visible: (values: any) =>
                    needRegistration && get(values, 'registrationInfo.targetUserType') === 'SOME',
                modifiable: (v: any) => get(v, 'baseInfo.merchants.length'),
            },
            {
                property: 'registrationInfo.targetUserBirthDay',
                visible: (values: any) =>
                    needRegistration && get(values, 'registrationInfo.targetUserType') === 'SOME',
                controlConfig: {
                    style: {
                        width: 200,
                    },
                },
            },
            {
                property: 'registrationInfo.targetUserSexuality',
                visible: (values: any) =>
                    needRegistration && get(values, 'registrationInfo.targetUserType') === 'SOME',
                controlConfig: {
                    placeholder: '请选择性别',
                    style: {
                        width: 200,
                    },
                },
            },
            {
                property: 'registrationInfo.customInfo',
                visible: () => needRegistration,
                controlConfig: {},
            },
            {
                property: 'registrationInfo.info',
                visible: () => needRegistration,
                controlConfig: {
                    placeholder: '请输入报名须知',
                    rows: 4,
                    maxLength: 500,
                    style: {
                        width: 720,
                    },
                },
            },
            {
                property: 'registrationInfo.reviewType',
                visible: () => needRegistration,
            },
            {
                property: 'registrationInfo.cancelableRule',
                visible: () => needRegistration,
            },

            {
                property: 'registrationInfo.excludeRule',
                visible: () => needRegistration,
            },
            {
                property: 'registrationInfo.excludeType',
                visible: (values: any) =>
                    needRegistration && get(values, 'registrationInfo.excludeRule') === 'YES',
            },
            {
                property: 'registrationInfo.excludeTypeActivity',
                visible: (values: any) =>
                    needRegistration &&
                    get(values, 'registrationInfo.excludeRule') === 'YES' &&
                    get(values, 'registrationInfo.excludeType') === 'SOME',
            },
            {
                property: 'contentInfo.creator',
                visible: () => haveShow,
                controlConfig: {
                    style: {
                        width: 450,
                    },
                },
            },
            {
                property: 'contentInfo.activityTopics',
                visible: () => haveShow,
                controlConfig: {
                    style: {
                        width: 450,
                    },
                },
            },
            {
                property: 'rewardInfo.reward',
                visible: () => needRegistration,
                controlConfig: {
                    eventType: 'MALL_ACTIVITY',
                    canChangeQuantity: false,
                },
            },
            {
                property: 'rewardInfo.rewardDistributionTime',
                visible: (values: any) =>
                    needRegistration &&
                    get(values, 'rewardInfo.reward') &&
                    get(values, 'rewardInfo.reward').length,
            },
            {
                property: 'seniorSetting.enableNotice',
            },
            {
                property: 'seniorSetting.subscriptMessage',
                visible: (values: any) =>
                    get(values, 'seniorSetting.enableNotice', []).includes('true'),
            },
            {
                property: 'seniorSetting.marking',
            },
        ];

        const formProps = {
            entity,
            componentId: 'MallActivityEditFormContainer',
            wrappedComponentRef,
            loaderType: 'get',
            onSubmit,
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            style: {
                minWidth: '750px',
            },
            fields: fields,
            submit: true,
            onReloadOptions,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '取消',
                        type: 'default',
                        disabled: false,
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: async (entity: any) => {
                if (!get(entity, 'baseInfo.pictures[0].id')) {
                    AntMessage.error('请上传活动图片');
                    return false;
                }

                if (!get(entity, 'baseInfo.poster[0].id')) {
                    AntMessage.error(services.language.getText('qscfxhb'));
                    return false;
                }
                if (get(entity, 'baseInfo.phone') && get(entity, 'baseInfo.phone').trim()) {
                    let merchantUsers = await Loader.load('get', {
                        params: { mobile: get(entity, 'baseInfo.merchantUserPhone').trim() },
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        apiPath: '/admin/merchant_users/by_mobile',
                    });
                    if (!merchantUsers) {
                        AntMessage.error('该店员不存在');
                        return false;
                    } else if (!merchantUsers.wechatCardPicture) {
                        AntMessage.error('导购员没有上传名片');
                        return false;
                    }
                }
                const startTime = get(entity, 'registrationInfo.registrationStartTime.timeStr');
                const endTime = get(entity, 'registrationInfo.registrationEndTime.timeStr');
                if (
                    get(entity, 'registrationInfo.registrationStartTime.timeType') === 'DAY_TIME' &&
                    startTime &&
                    get(entity, 'registrationInfo.registrationEndTime.timeType') === 'DAY_TIME' &&
                    endTime
                ) {
                    // const startTime = +newDate()
                    if (+new Date(endTime) < +new Date(startTime)) {
                        AntMessage.error('报名开始时间不能大于报名结束时间');
                        return false;
                    }
                }

                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };

        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    }
}

function mapStateToProps(_dispatch: any, props: any) {
    const { entity, params } = props;

    const data =
        get(_dispatch, 'components.["DataFormPlus-MallActivityEditFormContainer"].fields') || {};

    return {
        data,
        unmountComponent: () => {
            builder
                .getStore()
                .dispatch(
                    actions.unmountComponentAction('DataFormPlus-MallActivityEditFormContainer')
                );
        },
        onSubmit: async (event: any, fields: any) => {
            const entityFields = mapValues(fields, (field) => {
                return field.value;
            });
            const newData: any = cloneDeep(entityFields); // 将不可拓展对象变成可拓展
            const needRegistration = get(newData, 'registrationInfo.registrationType') === 'YES';

            if (needRegistration && newData.registrationInfo.session === SessionType.YES) {
                // 过滤掉什么也没填的项
                newData.registrationInfo.registrationTimes = newData.registrationInfo.registrationTimes.filter(
                    (item: any) => item.startDate || item.startTime || item.endTime || item.quantity
                );
                // 统计完整项的数量
                const completeCount = newData.registrationInfo.registrationTimes.reduce(
                    (count: number, item: any) =>
                        item.startDate && item.startTime && item.endTime && item.quantity
                            ? count + 1
                            : count,
                    0
                );

                if (
                    completeCount === 0 ||
                    completeCount !== newData.registrationInfo.registrationTimes.length
                ) {
                    AntMessage.error('报名场次日期、时间、名额不能为空');
                    return;
                }

                //由后端校验，前端暂不实现
                // 获取第一场和最后一场报名场次的日期
                // let firstRegistrationTime = 0;
                // let lastRegistrationTime = 0;
                // let confirm = false;
                // newData.registrationInfo.registrationTimes.forEach((item: any) => {
                //     const itemStartTime = new Date(item.startDate + ' ' + item.startTime).getTime();
                //     const itemEndTime = new Date(item.startDate + ' ' + item.endTime).getTime();
                //     if (!firstRegistrationTime || firstRegistrationTime > itemStartTime) {
                //         firstRegistrationTime = itemStartTime;
                //     }
                //     if (!lastRegistrationTime || lastRegistrationTime < itemEndTime) {
                //         lastRegistrationTime = itemEndTime;
                //     }
                // });

                // const startTime = new Date(
                //     newData.baseInfo.editStartTime + ' ' + newData.baseInfo.dayTime[0].start
                // ).getTime();
                // const endTime = new Date(
                //     newData.baseInfo.editEndTime + ' ' + newData.baseInfo.dayTime[0].end
                // ).getTime();
                // //活动开始日期晚于第一场报名场次日期或者活动结束时间晚早于最后一场报名时间，提示

                // if (startTime > firstRegistrationTime || endTime < lastRegistrationTime) {
                //     confirm = true;
                // }
            }

            const enableNotice = get(newData, 'seniorSetting.enableNotice', []).includes('true');
            const subscriptMessage = get(newData, 'seniorSetting.subscriptMessage');
            if (enableNotice && (!subscriptMessage || !subscriptMessage.num)) {
                AntMessage.error('活动参与提醒时间不能为空');
                return;
            }

            if (get(newData, 'contentInfo.creator') && get(newData, 'contentInfo.creator').trim()) {
                let userInfo = await Loader.load('get', {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                    apiPath: `/admin/members/mobile/${get(newData, 'contentInfo.creator')}`,
                }).catch(services.errorHandle);
                if (!userInfo) {
                    AntMessage.error('活动发起人手机号没有注册');
                    return;
                }
                newData.contentInfo.creatorId = userInfo.id;
            }
            const marking = get(newData, 'seniorSetting.marking', undefined);
            if (!isNil(marking) && marking?.check && !marking.label) {
                AntMessage.error('请选择要打标的标签');
                return false;
            }

            try {
                const { msg, pass } = await api.put<any>(transformRequestData(newData, 'put'), {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
                    apiPath: `/admin/mall_activities/${newData.baseInfo.id}/check`,
                });
                if (pass) {
                    if (newData) {
                        entity.modify(newData, params);
                    }
                } else {
                    Modal.confirm({
                        title: msg,
                        okText: services.language.getText('common.confirm'),
                        cancelText: services.language.getText('common.cancel'),
                        onOk: () => {
                            if (newData) {
                                entity.modify(newData, params);
                            }
                        },
                    });
                }
            } catch (error) {
                services.errorHandle(error as ErrorInfo);
            }
        },
        // 重新加载属性候选值
        onReloadOptions: (fieldName: any, fields: any) => {
            //? 因为在这个场景下reload时fields可能已经不在dataTable上了
            //? 会导致修改被依赖字段未触发获取依赖方获取数据
            //? 所以这里改成从entity上取需要的参数
            const isSubform = fieldName.split('.').length > 1;
            const fieldNamePath = isSubform ? fieldName.replace(/\./g, '.properties.') : fieldName;
            const field = get(entity, `metainfo.properties.${fieldNamePath}`);

            if (!field) return;
            const sourceDefination = field.source;
            let dependences = sourceDefination.dependences;
            let params = reduce(
                dependences,
                (values, dependence) => {
                    values[dependence] = getField(dependence, fields).value;
                    return values;
                },
                props.params ? clone(props.params) : {}
            );
            entity.loadPropertyOptions(fieldName, sourceDefination, params);
        },
    };
}
export const MallActivityEditFormView = connect(mapStateToProps)(mallActivityEditFormView);
