import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { find } from 'lodash';

const api = services.api;
export const StoredValueSettingLoader: Loader = {
    get: async function(data, config) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-ASSETS`;
        config.apiPath = '/admin/stored_value_card_configs';
        const res: any = await api.get(data, config);
        if (res) {
            const showCardPayCode: any = find(res, {
                param: 'showCardPayCode',
            });
            const showTransfer: any = find(res, {
                param: 'showTransfer',
            });
            const enablePStoredValueCard: any = find(res, {
                param: 'enablePStoredValueCard',
            });
            const pStoredValueCardAlias: any = find(res, {
                param: 'pStoredValueCardAlias',
            });
            const pStoredValueCardDescription: any = find(res, {
                param: 'pStoredValueCardDescription',
            });
            const eStoredValueCardAlias: any = find(res, {
                param: 'eStoredValueCardAlias',
            });
            const eStoredValueCardDescription: any = find(res, {
                param: 'eStoredValueCardDescription',
            });
            const enableCertification: any = find(res, {
                param: 'enableCertification',
            });
            const certificationAmount: any = find(res, {
                param: 'certificationAmount',
            });
            const rechargeAmountLowerLimit: any = find(res, {
                param: 'rechargeAmountLowerLimit',
            });
            const rechargeAmountUpperLimit: any = find(res, {
                param: 'rechargeAmountUpperLimit',
            });
            const supportInvoiceMail: any = find(res, {
                param: 'supportInvoiceMail',
            });
            // const afterSale: any = find(res, {
            //     param: 'afterSale',
            // });
            // const wholeAfterSale: any = find(res, {
            //     param: 'wholeAfterSale',
            // });
            res.showCardPayCode = showCardPayCode ? showCardPayCode.value : 'false';
            res.showTransfer = showTransfer ? showTransfer.value : 'false';
            res.enablePStoredValueCard = enablePStoredValueCard
                ? enablePStoredValueCard.value
                : false;
            res.pStoredValueCardAlias = pStoredValueCardAlias ? pStoredValueCardAlias.value : '';
            res.pStoredValueCardDescription = pStoredValueCardDescription
                ? pStoredValueCardDescription.value
                : '';
            res.eStoredValueCardAlias = eStoredValueCardAlias ? eStoredValueCardAlias.value : '';
            res.eStoredValueCardDescription = eStoredValueCardDescription
                ? eStoredValueCardDescription.value
                : '';
            res.enableCertification = enableCertification ? enableCertification.value : 'false';
            res.certificationAmount = certificationAmount ? certificationAmount.value : '';
            res.rechargeAmountLowerLimit = rechargeAmountLowerLimit
                ? rechargeAmountLowerLimit.value
                : '';
            res.rechargeAmountUpperLimit = rechargeAmountUpperLimit
                ? rechargeAmountUpperLimit.value
                : '';
            res.supportInvoiceMail = supportInvoiceMail ? supportInvoiceMail.value : 'false';
            // res.afterSale = afterSale ? afterSale.value : false;
            // res.wholeAfterSale = wholeAfterSale ? wholeAfterSale.value : false;
        }
        return res;
    },
    put: async function(params, config) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-ASSETS`;
        config.apiPath = '/admin/stored_value_card_configs';
        const arrry: Array<{
            param: string;
            value: string;
        }> = [
            {
                param: 'showCardPayCode',
                value: params.showCardPayCode,
            },
            {
                param: 'showTransfer',
                value: params.showTransfer,
            },
            {
                param: 'enablePStoredValueCard',
                value: params.enablePStoredValueCard,
            },
            {
                param: 'pStoredValueCardAlias',
                value: params.pStoredValueCardAlias,
            },
            {
                param: 'pStoredValueCardDescription',
                value: params.pStoredValueCardDescription,
            },
            {
                param: 'eStoredValueCardAlias',
                value: params.eStoredValueCardAlias,
            },
            {
                param: 'eStoredValueCardDescription',
                value: params.eStoredValueCardDescription,
            },
            {
                param: 'enableCertification',
                value: params.enableCertification,
            },
            {
                param: 'certificationAmount',
                value: params.certificationAmount ? params.certificationAmount : '',
            },
            {
                param: 'rechargeAmountLowerLimit',
                value: params.rechargeAmountLowerLimit ? params.rechargeAmountLowerLimit : '',
            },
            {
                param: 'rechargeAmountUpperLimit',
                value: params.rechargeAmountUpperLimit ? params.rechargeAmountUpperLimit : '',
            },
            {
                param: 'supportInvoiceMail',
                value: params.supportInvoiceMail,
            },
            // {
            //     param: 'afterSale',
            //     value: params.afterSale,
            // },
            // {
            //     param: 'wholeAfterSale',
            //     value: params.wholeAfterSale,
            // },
        ];
        return await api.post(arrry, config);
    },
};
