import React, { Component } from 'react';
import { Input, Button, message, Popconfirm } from 'antd';
import { clone, isEqual, map, concat, isEmpty } from 'lodash';
import { services } from '@comall-backend-builder/core';

import './index.less';

type RulesValue = {
    /**
     * 消费金额
     */
    stepValue?: number;
    /**
     * 减免金额
     */
    rewardValue?: number;
};

export interface FullReductionLadderProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Array<RulesValue> | null, name: string) => void;
    /**
     * 当前值
     */
    value: Array<RulesValue> | null;
    /**
     * 是否禁止删除和新增规则
     */
    disabled: boolean;
}

/**
 * 消费满减邮费规则阶梯组件
 */
export class FullReductionLadder extends Component<FullReductionLadderProps> {
    componentDidMount() {
        let { name, value, onChange } = this.props;
        if (!value) {
            value = [{}];
        }
        if (onChange) {
            onChange(value, name);
        }
    }
    onStepValueChange(event: any, index: number) {
        let { name, value, onChange } = this.props;
        let stepValue = event.target.value;
        if (!isEmpty(stepValue) && stepValue < 0) {
            message.warning(services.language.getText('qtxbxydje'));
            return;
        }
        if (isNaN(Number(stepValue))) {
            return;
        }
        if (stepValue && stepValue > 1000000) {
            message.warning(services.language.getText('jezdzw'));
            return;
        }
        if (/\s/.test(stepValue)) {
            return;
        }
        let stepValueArr = stepValue.split('.');
        if (stepValueArr.length > 1) {
            stepValueArr = stepValueArr.slice(0, 2);
            if (stepValueArr[1].length >= 2) {
                stepValueArr[1] = stepValueArr[1].substr(0, 2);
                stepValue = stepValueArr.join('.');
            }
        }
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.stepValue = stepValue;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            if (isEmpty(stepValue)) {
                message.warning(services.language.getText('qsrddje'));
                return;
            }
            let item: RulesValue = {};
            item.stepValue = stepValue;
            value = concat([], item);
        }

        if (onChange) {
            onChange(value, name);
        }
    }

    onRewardValueChange(event: any, index: number) {
        let { name, value, onChange } = this.props;
        let rewardValue = event.target.value;
        if (!isEmpty(rewardValue) && rewardValue <= 0) {
            message.warning(services.language.getText('qtxbxydje'));
            return;
        }
        if (rewardValue && rewardValue > 1000000) {
            message.warning(services.language.getText('jezdzw'));
            return;
        }
        if (isNaN(Number(rewardValue))) {
            return;
        }
        if (/\s/.test(rewardValue)) {
            return;
        }
        let rewardValueArr = rewardValue.split('.');
        if (rewardValueArr.length > 1) {
            rewardValueArr = rewardValueArr.slice(0, 2);
            if (rewardValueArr[1].length >= 2) {
                rewardValueArr[1] = rewardValueArr[1].substr(0, 2);
                rewardValue = rewardValueArr.join('.');
            }
        }
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.rewardValue = rewardValue;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            if (isEmpty(rewardValue)) {
                message.warning(services.language.getText('qsrljje'));
                return;
            }
            let item: RulesValue = {};
            item.rewardValue = rewardValue;
            value = concat([], item);
        }

        if (onChange) {
            onChange(value, name);
        }
    }

    onAddRule() {
        let { name, value, onChange } = this.props;
        if (!value) {
            value = [{}];
        }
        if (value !== null && !isEmpty(value)) {
            if (value.length >= 7) {
                message.warning(services.language.getText('suppoert7Count'));
                return;
            }
            value = concat([], value);
            value.splice(value.length, 0, {});
        }

        if (onChange) {
            onChange(value, name);
        }
    }

    onRemoveRule(index: number) {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(index, 1);
            if (!value.length) {
                value = [{}];
            }
        }

        if (onChange) {
            onChange(value, name);
        }
    }

    shouldComponentUpdate(nextProps: FullReductionLadderProps) {
        return !isEqual(this.props, nextProps);
    }

    render() {
        let { value, disabled } = this.props;
        disabled = disabled ? disabled : false;
        return (
            <div className="full-reduction-ladder">
                {map(value, (rule, index) => this.renderRule(rule, index, disabled))}
                {value && value.length ? null : this.renderRule({}, 0, disabled)}
                {(!value || value.length < 7) && !disabled && (
                    <Button
                        type="default"
                        className="add-rule-button"
                        onClick={() => this.onAddRule()}
                    >
                        {services.language.getText('addRule')}
                    </Button>
                )}
            </div>
        );
    }

    renderRule(rule: RulesValue, index: number, disabled: boolean) {
        return (
            <div className="rule" key={index}>
                <div className="rule-content">
                    <span className="item-text">{services.language.getText('orderAmount')}≥ </span>
                    <div className="rule-count-input">
                        <Input
                            value={rule.stepValue}
                            placeholder={services.language.getText('pleaseInputNumber')}
                            onChange={(e) => this.onStepValueChange(e, index)}
                        />
                    </div>
                    <span className="item-text">{services.language.getText('yflj')}</span>
                    <div className="rule-park-fee-input">
                        <Input
                            addonAfter={services.language.getText('yuan')}
                            value={rule.rewardValue}
                            placeholder={services.language.getText('pleaseInputNumber')}
                            onChange={(e) => this.onRewardValueChange(e, index)}
                        />
                    </div>
                    {!disabled && (
                        <Popconfirm
                            onConfirm={this.onRemoveRule.bind(this, index)}
                            title={services.language.getText('sfscggz')}
                            okText={services.language.getText('common.ok')}
                            cancelText={services.language.getText('common.cancel')}
                        >
                            <Button type="default" className="remove-rule-button">
                                {services.language.getText('common.delete')}
                            </Button>
                        </Popconfirm>
                    )}
                </div>
            </div>
        );
    }
}
