import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message } from 'antd';
import { get, isNil } from 'lodash';

const { getText } = services.language;

/**
 * 专柜/门店选择模式
 */
enum MerchantSelectorMode {
    /**
     * 按门店选择
     */
    SUBSITE = 'SUB_SITE',
    /**
     * 按专柜选择
     */
    MERCHANT = 'MERCHANT',
    /**
     * 按标签选择
     */
    TAG = 'TAG',
}
export const config: Config = {
    entities: {
        DistributorDistributionActivityEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/distribution_activities_new',
            paramsFilter: ['DISTRIBUTOR'],
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsrhdmc>>' },
                },
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<applicableSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'UN_START', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<basicSetting>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [{ required: true, whitespace: true, message: '<<qsrhdmc>>' }],
                            controlConfig: {
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                    },
                },
                // selectedSubsites: {
                //     type: 'array.subsites.selector',
                //     displayName: '<<otherSelectSubsite>>',
                //     defaultValue: [],
                //     rules: [{ required: true, message: '<<selectSubsite>>' }],
                // },
                ruleInfo: {
                    type: 'object.subForm',
                    displayName: '<<activityRule>>',
                    properties: {
                        selectMode: {
                            type: 'string.options.radio',
                            displayName: '<<applicableSubsiteAndMerchant>>',
                            options: [
                                { id: 'SUB_SITE', name: '<<subsiteSelect>>' },
                                { id: 'MERCHANT', name: '<<merchantSelect>>' },
                                { id: 'TAG', name: '<<selectTag>>' },
                            ],
                            defaultValue: 'MERCHANT',
                            rules: [{ required: true }],
                        },
                        merchants: {
                            type: 'array.shop.selector',
                            displayName: '<<selectSubsites>>',
                            defaultValue: [],
                        },
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            defaultValue: [],
                        },
                        tags: {
                            type: 'array.tags.selector',
                            displayName: '<<xzbq>>',
                            defaultValue: [],
                        },
                        activityProductType: {
                            type: 'string.options.radio',
                            displayName: '<<activityProduct>>',
                            defaultValue: 'INCLUDE_PRODUCT',
                            options: [
                                { id: 'ALL', name: '<<allProduct>>' },
                                { id: 'INCLUDE_PRODUCT', name: '<<includeProduct>>' },
                                { id: 'EXCLUDE_PRODUCT', name: '<<excludeProduct>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        goodsDistributionInfoList: {
                            type: 'object.productCategory.selector',
                            displayName: '<<activityProduct>>',
                            controlConfig: {
                                disableBand: true,
                                productDisplaySelectValueType: 'goods',
                                memberIdentity: 'DISTRIBUTOR',
                                needSubsites: true,
                                filterFields: [{ property: 'code' }, { property: 'name' }],
                                importConfig: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                                    apiPath: '/admin/products/data_parse_by_excel',
                                    activityType: 'DISTRIBUTION',
                                    importFileSource: {
                                        apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
                                        apiPath: '/admin/files/locations/135/upload',
                                    },
                                    templateSource: {
                                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                                        apiPath: '/admin/products/download_distribution_template',
                                    },
                                },
                            },
                        },
                    },
                },
            },
        },
        DistributorCommissionEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/distribution_goods_settlement_records',
            paramsFilter: ['DISTRIBUTOR'],
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<applicableSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                memberName: {
                    type: 'string',
                    displayName: '<<fxymc>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                memberMobile: {
                    type: 'string',
                    displayName: '<<fxysjh>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<jszt>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'WAITING', name: '<<djs_1>>' },
                        { id: 'COMPLETED', name: '<<alreadyCheckout>>' },
                        { id: 'CANCELLED', name: '<<ycx>>' },
                    ],
                },
                actualSettlementDateRange: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<jssj>>',
                },
                clearingStatus: {
                    type: 'string.options.select',
                    displayName: '<<dzzt>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'WAITING', name: '<<wdz>>' },
                        { id: 'SUCCESS', name: '<<ydz_1>>' },
                        { id: 'FAIL', name: '<<dzyc>>' },
                    ],
                },
                clearingDateRange: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<dzsj>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                memberName: {
                    type: 'string',
                    displayName: '<<fxymc>>',
                },
                memberMobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                goodsName: {
                    type: 'string',
                    displayName: '<<sp>>',
                },
                goodsAfterFoldingPrice: {
                    type: 'string',
                    displayName: '<<cjje_1>>',
                },
                commissionAmount: {
                    type: 'string',
                    displayName: '<<yjsy>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<jszt>>',
                    options: [
                        { id: 'WAITING_ORDER_RECEIVE', name: '<<dqs>>' },
                        { id: 'ORDER_AFTER_SALE', name: '<<shz>>' },
                        { id: 'WAITING', name: '<<djs_1>>' },
                        { id: 'COMPLETED', name: '<<alreadyCheckout>>' },
                        { id: 'CANCELLED', name: '<<ycx>>' },
                    ],
                },
                clearingStatus: {
                    type: 'string.options.select',
                    displayName: '<<dzzt>>',
                    options: [
                        { id: 'WAITING', name: '<<wdz>>' },
                        { id: 'SUCCESS', name: '<<ydz_1>>' },
                        { id: 'PROCESSING', name: '<<clz>>' },
                        { id: 'FAIL', name: '<<dzyc>>' },
                        { id: 'NONE', name: '-' },
                    ],
                },
                orderCreateTime: {
                    type: 'string.date',
                    displayName: '<<xdsj>>',
                },
                orderReceivedTime: {
                    type: 'string.date',
                    displayName: '<<qssj>>',
                },
                actualSettlementTime: {
                    type: 'string.date',
                    displayName: '<<jssj>>',
                },
                clearingTime: {
                    type: 'string.date',
                    displayName: '<<dzsj>>',
                },
            },
        },
        DistributorSettlementConfig: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/distribution_commission_clearing_rules/member_identities/DISTRIBUTOR',
            properties: {
                settlementDelayDays: {
                    type: 'number.tip.groupBuying',
                    displayName: '<<yjjssj>>',
                    name: JSON.stringify({
                        fill: '<<tzdjsyj>>',
                        empty: '<<wszyjjssj>>',
                    }),
                    rules: [
                        {
                            required: true,
                        },
                        {
                            validator(
                                rule: any,
                                value: number,
                                callback: (message?: string) => void
                            ) {
                                if (!value) {
                                    callback();
                                } else {
                                    const v = value + '';

                                    if (!/^\+?[1-9]\d*$/.test(v) && v !== '0') {
                                        callback(getText('qsrdydy'));
                                    } else if (value > 9999) {
                                        callback(getText('zdzcsz999t'));
                                    } else {
                                        callback();
                                    }
                                }
                            },
                        },
                    ],
                    controlConfig: {
                        style: { width: 150 },
                        min: 0,
                        max: 9999,
                        placeholder: '<<inputPlease>>',
                        addonbefore: '<<qsh_1>>',
                        addonafter: '<<zdjsyj>>',
                        tip: '<<wzshdndspjsyj>>',
                    },
                },
                clearingDay: {
                    type: 'number.tip.groupBuying',
                    displayName: '<<yjdzsj>>',
                    name: JSON.stringify({
                        fill: '<<rkffsyjsdyj>>',
                        empty: '<<wszyjtxsj>>',
                    }),
                    rules: [
                        {
                            required: true,
                        },
                        {
                            validator(
                                rule: any,
                                value: number,
                                callback: (message?: string) => void
                            ) {
                                if (!value) {
                                    callback();
                                } else {
                                    const v = value + '';

                                    if (!/^\+?[1-9]\d*$/.test(v)) {
                                        callback(getText('inputThanZero'));
                                    } else if (value > 28) {
                                        callback(getText('zdzcsz28r'));
                                    } else {
                                        callback();
                                    }
                                }
                            },
                        },
                    ],
                    controlConfig: {
                        style: { width: 150 },
                        min: 1,
                        max: 28,
                        placeholder: '<<inputPlease>>',
                        addonbefore: '<<everyMonth>>',
                        addonafter: '<<clearingDaybefore>>',
                        tip: '<<rffsyzryjsdqbyj>>',
                    },
                },
            },
        },
        DistributorWithdrawalRule: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-WITHDRAWAL`,
            apiPath: '/admin/withdrawal_rule',
            properties: {
                minWithdrawalAmount: {
                    type: 'number.tip',
                    displayName: '<<zdtxed>>',
                    controlConfig: {
                        style: { width: 200 },
                        min: 0,
                        max: 999999,
                        placeholder: '<<qsrzdtxed>>',
                        addonafter: '<<yuan>>',
                    },
                    /*  rules: [
                        {
                            required: true,
                            message: '请填写最低提现额度',
                        },
                    ], */
                },
                taxRate: {
                    type: 'number.tip',
                    displayName: '<<taxRate>>',
                    controlConfig: {
                        style: { width: 200 },
                        min: 0,
                        max: 99,
                        placeholder: '<<qsrsl>>',
                        addonafter: '%',
                    },
                },
                explanation: {
                    type: 'string.richTextPlus',
                    displayName: '<<txsm>>',
                    extra: '<<explanationExtra>>',
                    controlConfig: {
                        toolbarId: 'explanationRichText',
                        style: {
                            height: 300,
                        },
                        uploadConfig: {
                            apiPath: `/admin/images/upload`,
                            fileName: 'fileName',
                        },
                    },
                },
            },
        },
        MemberIdentityApplyRecordsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`,
            apiPath: '/loader/admin/members/identities/apply_records',
            filters: {
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                name: {
                    type: 'string',
                    displayName: '<<name>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                applyName: {
                    type: 'string',
                    displayName: '<<fxyxm>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                auditStatus: {
                    type: 'string.options.select',
                    displayName: '<<auditStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'WAITING', name: '<<waiting>>' },
                        { id: 'SUCCESS', name: '<<approved>>' },
                        { id: 'FAIL', name: '<<shbtg>>' },
                    ],
                },
                signStatus: {
                    type: 'string.options.select',
                    displayName: '<<qyzt>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'SIGNED', name: '<<yqy>>' },
                        { id: 'NOT_OPEN_ACCOUNT', name: '<<wkh>>' },
                        { id: 'WAITING_OPEN_ACCOUNT', name: '<<dkh>>' },
                        { id: 'WAITING_SIGN', name: '<<dqy>>' },
                        { id: 'OPEN_ACCOUNT_FAIL', name: '<<khsb>>' },
                        { id: 'SIGN_FAIL', name: '<<qysb>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                userInfo: {
                    type: 'object.subForm',
                    displayName: '<<fxysqxx>>',
                    properties: {
                        types: {
                            type: 'string',
                            displayName: '<<pageName>>',
                            defaultValue: 'record',
                        },
                        name: {
                            type: 'string',
                            displayName: '<<fxyxm>>',
                        },
                        nameEn: {
                            type: 'string',
                            displayName: '<<ywm>>',
                        },
                        mobile: {
                            type: 'string',
                            displayName: '<<phoneNum>>',
                        },
                        email: {
                            type: 'string',
                            displayName: '<<dzyx>>',
                        },
                        auditsStatus: {
                            type: 'string.options.radio',
                            displayName: '<<auditStatus>>',
                            options: [
                                { id: 'SUCCESS', name: '<<approved>>' },
                                { id: 'FAIL', name: '<<shbtg>>' },
                            ],
                            rules: [
                                {
                                    required: true,
                                    message: '<<qxzshzt>>',
                                },
                            ],
                        },
                        remark: {
                            type: 'string.text.paragraph',
                            displayName: '<<yybz>>',

                            controlConfig: {
                                placeholder: '<<yyzzlt>>',
                            },
                        },
                        auditStatus: {
                            type: 'string.options.select',
                            displayName: '<<auditStatus>>',
                            options: [
                                { id: 'ALL', name: '<<all>>' },
                                { id: 'WAITING', name: '<<waiting>>' },
                                { id: 'SUCCESS', name: '<<approved>>' },
                                { id: 'FAIL', name: '<<shbtg>>' },
                            ],
                        },
                    },
                },
                memberId: {
                    type: 'string',
                    displayName: '<<memberId>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<name>>',
                },
                applyName: {
                    type: 'string',
                    displayName: '<<fxyxm>>',
                },
                nameEn: {
                    type: 'string',
                    displayName: '<<ywm>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                email: {
                    type: 'string',
                    displayName: '<<dzyx>>',
                },
                bankNo: {
                    type: 'string',
                    displayName: '<<yxkh>>',
                },
                idCardFrontUrl: {
                    type: 'array.images',
                    displayName: '<<sfzrxy>>',
                },
                idCardBackUrl: {
                    type: 'array.images',
                    displayName: '<<sfzghy>>',
                },
                idCard: {
                    type: 'string',
                    displayName: '<<sfzh>>',
                },
                idCardEndTime: {
                    type: 'string',
                    displayName: '<<sfzyxqjsrq>>',
                },
                idCardStartTime: {
                    type: 'string',
                    displayName: '<<sfzyxqksrq>>',
                },
                signStatus: {
                    type: 'string.options.select',
                    displayName: '<<qyzt>>',
                    options: [
                        { id: 'SIGNED', name: '<<yqy>>' },
                        { id: 'NOT_OPEN_ACCOUNT', name: '<<wkh>>' },
                        { id: 'WAITING_OPEN_ACCOUNT', name: '<<dkh>>' },
                        { id: 'WAITING_SIGN', name: '<<dqy>>' },
                        { id: 'OPEN_ACCOUNT_FAIL', name: '<<khsb>>' },
                        { id: 'SIGN_FAIL', name: '<<qysb>>' },
                    ],
                },
                auditStatus: {
                    type: 'string.options.select',
                    displayName: '<<auditStatus>>',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'WAITING', name: '<<waiting>>' },
                        { id: 'SUCCESS', name: '<<approved>>' },
                        { id: 'FAIL', name: '<<shbtg>>' },
                    ],
                },
                createTime: {
                    type: 'string.date',
                    displayName: '<<sqsj>>',
                },
                remark: {
                    type: 'string.text.paragraph',
                    displayName: '<<yybz>>',

                    controlConfig: {
                        placeholder: '<<yyzzlt>>',
                    },
                },
            },
        },

        MemberDistributorEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`,
            apiPath: '/loader/admin/members/distributor',
            filters: {
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                name: {
                    type: 'string',
                    displayName: '<<fxyxm>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                distributorId: {
                    type: 'string',
                    displayName: '<<distributorId>>',
                },
                memberId: {
                    type: 'string',
                    displayName: '<<memberId>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                memberName: {
                    type: 'string',
                    displayName: '<<fxyxm>>',
                },
                memberNameEn: {
                    type: 'string',
                    displayName: '<<ywm>>',
                },
                email: {
                    type: 'string',
                    displayName: '<<email>>',
                },
                group: {
                    type: 'string',
                    displayName: '<<ssfz>>',
                },
            },
        },
        DistributionActivityWithdrawalRecordEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/distribution_activity_commission_withdrawal_records',
            filters: {
                memberName: {
                    type: 'string',
                    displayName: '<<fxymc>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                commissionId: {
                    type: 'string',
                    displayName: '<<commissionId>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                subsiteName: {
                    type: 'object.option.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                memberName: {
                    type: 'string',
                    displayName: '<<fxymc>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                commissionId: {
                    type: 'string',
                    displayName: '<<commissionId>>',
                },
                withdrawalAmount: {
                    type: 'string',
                    displayName: '<<txje>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<czmd>>',
                },

                status: {
                    type: 'string',
                    displayName: '<<txzt>>',
                },
                wechatTransactionNumber: {
                    type: 'string',
                    displayName: '<<wxlsh>>',
                },
                transactionId: {
                    type: 'string',
                    displayName: '<<sflsh>>',
                },
                createTime: {
                    type: 'string.date',
                    displayName: '<<txsj>>',
                },
            },
        },
        RecruitDistributorEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/page/distributor',
            properties: {
                id: {
                    type: 'number',
                },
                recruitDistributor: {
                    type: 'object.subForm',
                    displayName: '<<components.PageDesign.pageSetting>>',
                    placeholder: '',
                    properties: {
                        backgroundImage: {
                            type: 'string.image.picker',
                            displayName: '<<xctp>>',
                            controlConfig: {
                                fileName: 'fileName',
                                uploadType: 'single',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                                maxCount: 1,
                                limitTips: {
                                    limitText: '<<backgroundImagelimit2>>',
                                    limitUnit: ' ',
                                    className: 'image-picker-tips-ext',
                                },
                            },
                            rules: [{ required: true, message: '<<qxzymbj>>' }],
                        },
                        show: {
                            type: 'string.options.radio',
                            displayName: '<<dzxy>>',
                            defaultValue: 'show',
                            options: [
                                { id: 'show', name: '<<show>>' },
                                { id: 'hidden', name: '<<hide>>' },
                            ],
                            rules: [{ required: true, message: '<<qtxdzxy>>' }],
                        },
                        title: {
                            type: 'string',
                            displayName: '<<xymc>>',
                            controlConfig: {
                                placeholder: '<<qsrxymc>>',
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        image: {
                            type: 'string.image.picker',
                            displayName: '<<xytp>>',
                            controlConfig: {
                                fileName: 'fileName',
                                uploadType: 'single',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                                maxCount: 1,
                                limitTips: {
                                    limitText: '<<backgroundImagelimit2>>',
                                    limitUnit: ' ',
                                    className: 'image-picker-tips-ext',
                                },
                            },
                        },
                    },
                },
                share: {
                    type: 'object.subForm',
                    displayName: '<<fxxcx>>',
                    properties: {
                        title: {
                            type: 'string',
                            displayName: '<<fxbt>>',
                            controlConfig: {
                                placeholder: '<<qsrfxbt>>',
                                maxLength: 12,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        description: {
                            type: 'string',
                            displayName: '<<fxms>>',
                            controlConfig: {
                                placeholder: '<<qsrfxms>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        pic: {
                            type: 'string.image.picker',
                            displayName: '<<fxtp>>',
                            controlConfig: {
                                fileName: 'fileName',
                                uploadType: 'single',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                                maxCount: 1,
                                limitTips: {
                                    limitText: '<<picLimitText>>',
                                    limitUnit: ' ',
                                    className: 'image-picker-tips-ext',
                                },
                            },
                        },
                        pictureExample: {
                            displayName: '<<fxylxg>>',
                            type: 'string.pictureExampleButton',
                            controlConfig: {
                                buttonName: '<<clickView>>',
                                type: 'recruitDistributorShareExample',
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        DistributionView: {
            component: 'Viewport',
        },
        DistributionPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'tabs-content-viewport',
            items: [{ component: 'DistributionTabs' }],
        },
        DistributionTabs: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<zmfxy>>',
                    content: {
                        component: 'RecruitDistributorPage',
                    },
                },
                {
                    title: '<<shfxyxx>>',
                    content: {
                        component: 'MemberIdentityApplyRecordsPage',
                    },
                },
                {
                    title: '<<fxyxxjl>>',
                    content: {
                        component: 'MemberDistributorPage',
                    },
                },
                {
                    title: '<<szfxgz>>',
                    content: {
                        component: 'DistributorSettlementConfigView',
                    },
                },
                {
                    title: '<<sztxgz>>',
                    content: {
                        component: 'DistributorWithdrawalRuleView',
                    },
                },
                {
                    title: '<<glfxhd>>',
                    content: {
                        component: 'DistributorDistributionActivityPage',
                    },
                },
                {
                    title: '<<glyj>>',
                    content: {
                        component: 'DistributorCommissionPage',
                    },
                },
                {
                    title: '<<txcgjl>>',
                    content: {
                        component: 'DistributionActivityWithdrawalRecordPage',
                    },
                },
            ],
        },

        DistributorSettlementConfigView: {
            component: 'Card',
            entity: 'DistributorSettlementConfig',
            loaderType: 'get',
            content: {
                component: 'DistributorSettlementConfigForm',
            },
        },
        DistributorSettlementConfigForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            loaderType: 'get',
            labelCol: 3,
            controlCol: 15,
            style: { width: 1200 },
            fields: [
                { property: 'settlementDelayDays' },
                {
                    property: 'clearingDay',
                },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'submit',
                        text: '<<common.save>>',
                    },
                ],
            },
            onSubmitSuccess: () => {
                message.success(getText('common.saveSuccess'));
            },
        },
        DistributorWithdrawalRuleView: {
            component: 'Card',
            entity: 'DistributorWithdrawalRule',
            loaderType: 'get',
            content: {
                component: 'DistributorWithdrawalRuleForm',
            },
        },
        DistributorWithdrawalRuleForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            loaderType: 'get',
            labelCol: 3,
            controlCol: 15,
            style: { width: 1200 },
            fields: [
                { property: 'minWithdrawalAmount' },
                { property: 'taxRate' },
                { property: 'explanation' },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'submit',
                        text: '<<common.save>>',
                    },
                ],
            },
            onSubmitSuccess: () => {
                message.success(getText('common.saveSuccess'));
            },
        },
        DistributorDistributionActivityPage: {
            entity: 'DistributorDistributionActivityEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DistributorDistributionActivityFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/distribution/activity/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'DistributorDistributionActivityTable' },
            ],
        },
        DistributorDistributionActivityFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'subsiteIds',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'dateRange',
                },
                {
                    property: 'status',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
            ],
        },
        DistributorDistributionActivityTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: {
                x: 1300,
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'startTime',
                    width: 120,
                },
                {
                    property: 'endTime',
                    width: 120,
                },
                {
                    property: 'subsites',
                    width: 150,
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '250px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'status',
                    width: 80,
                    displayConfig: {
                        statusConfig: {
                            UN_START: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 180,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'enabled',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                    apiPath: '/admin/distribution_activities/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isCloseActivity>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                    apiPath: '/admin/distribution_activities/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        // statusKey: 'row.canEdit',
                        path: '/menu-list/market/distribution/activity/edit/{{row.id}}',
                    },
                    // {
                    //     type: 'link',
                    //     text: '<<common.view>>',
                    //     path: '/menu-list/market/distribution/activity/info/{{row.id}}',
                    // },
                ],
            },
        },
        DistributorDistributionActivityAddPage: {
            component: 'FlexLayout',
            entity: 'DistributorDistributionActivityEntity',
            direction: 'horizontal',
            items: [{ component: 'DistributorDistributionActivityAddFormView' }],
        },
        DistributorDistributionActivityAddFormView: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            labelCol: 3,
            controlCol: 15,
            style: { width: 1200 },
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'ruleInfo.selectMode' },
                // { property: 'selectedSubsites' },
                {
                    property: 'ruleInfo.subsites',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.selectMode') === MerchantSelectorMode.SUBSITE,
                },
                {
                    property: 'ruleInfo.merchants',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.selectMode') === MerchantSelectorMode.MERCHANT,
                },
                {
                    property: 'ruleInfo.tags',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.selectMode') === MerchantSelectorMode.TAG,
                },
                {
                    property: 'ruleInfo.activityProductType',
                    visible: () => false,
                },
                { property: 'ruleInfo.goodsDistributionInfoList' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                let flag = true;
                if (entity.ruleInfo?.goodsDistributionInfoList?.values?.length > 0) {
                    entity.ruleInfo.goodsDistributionInfoList.values.forEach((item: any) => {
                        if (item.commissionInfos && item.commissionInfos.length > 0) {
                            item.commissionInfos.forEach((info: any) => {
                                if (isNil(info.percentage)) flag = false;
                            });
                        } else {
                            flag = false;
                        }
                    });
                } else {
                    flag = false;
                    message.error(getText('qtjhdsp'));
                    return;
                }
                if (!flag) {
                    message.error(getText('qwztxfybl'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                message.success(getText('hdxzcg'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        DistributorDistributionActivityEditPage: {
            component: 'FlexLayout',
            entity: 'DistributorDistributionActivityEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'DistributorDistributionActivityEditFormView' }],
        },
        DistributorDistributionActivityEditFormView: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            loaderType: 'get',
            labelCol: 3,
            controlCol: 15,
            style: { width: 1200 },
            fields: [
                { property: 'baseInfo.name' },
                {
                    property: 'baseInfo.dateRange',
                    controlConfig: {
                        disabled: true,
                    },
                },
                { property: 'ruleInfo.selectMode' },
                // { property: 'selectedSubsites' },
                {
                    property: 'ruleInfo.subsites',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.selectMode') === MerchantSelectorMode.SUBSITE,
                },
                {
                    property: 'ruleInfo.merchants',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.selectMode') === MerchantSelectorMode.MERCHANT,
                },
                {
                    property: 'ruleInfo.tags',
                    visible: (values: any) =>
                        get(values, 'ruleInfo.selectMode') === MerchantSelectorMode.TAG,
                },
                {
                    property: 'ruleInfo.activityProductType',
                    visible: () => false,
                },
                { property: 'ruleInfo.goodsDistributionInfoList' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any, ...other: any) => {
                let flag = true;
                if (entity.ruleInfo?.goodsDistributionInfoList?.values?.length > 0) {
                    entity.ruleInfo.goodsDistributionInfoList.values.forEach((item: any) => {
                        if (item.commissionInfos && item.commissionInfos.length > 0) {
                            item.commissionInfos.forEach((info: any) => {
                                if (isNil(info.percentage)) flag = false;
                            });
                        } else {
                            flag = false;
                        }
                    });
                } else {
                    flag = false;
                    message.error(getText('qtjhdsp'));
                    return;
                }
                if (!flag) {
                    message.error(getText('qwztxfybl'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                message.success(getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        DistributionInfoPage: {
            component: 'Card',
            content: { component: 'DistributionInfoView' },
        },

        DistributionInfoView: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'MemberIdentityApplyRecordsEntity',
            loaderType: 'get',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: [
                { property: 'userInfo.name', modifiable: false },
                { property: 'userInfo.mobile', modifiable: false },
                { property: 'userInfo.nameEn', modifiable: false },
                { property: 'userInfo.auditStatus', label: '<<auditStatus>>', modifiable: false },
                { property: 'userInfo.remark', label: '<<reason>>', modifiable: false },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                    },
                ],
            },
        },
        DistributionRecordInfoPage: {
            component: 'Card',
            content: { component: 'DistributionRecordInfoView' },
        },
        DistributionRecordInfoView: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'MemberIdentityApplyRecordsEntity',
            loaderType: 'get',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: [
                {
                    property: 'userInfo.types',
                    className: 'hidden',
                },
                {
                    property: 'userInfo.name',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                { property: 'userInfo.mobile', modifiable: false },
                { property: 'userInfo.nameEn', modifiable: false },
                { property: 'userInfo.auditStatus', label: '<<auditStatus>>', modifiable: false },
                { property: 'userInfo.remark', label: '<<reason>>', modifiable: false },
                // { property: 'idCardEndTime', modifiable: false },
                // { property: 'signStatus', modifiable: false },
                {
                    property: 'auditStatus',
                    className: 'hidden',
                },
                {
                    property: 'remark',
                    className: 'hidden',
                },
            ],

            submit: {
                text: '<<common.save>>',
                style: {
                    marginRight: 10,
                },
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                    },
                ],
            },
            onSubmitSuccess: () => {
                message.success(getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        DistributorDistributionActivityInfoViewPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'DistributorDistributionActivityInfoView' }],
        },
        DistributorDistributionActivityInfoView: {
            component: 'Detail',

            direction: 'horizontal',
            entity: 'DistributorDistributionActivityEntity',
            loaderType: 'get',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: [
                { property: 'name' },
                {
                    property: 'dateRange',
                },
                { property: 'selectMode' },
                {
                    property: 'selectedSubsites',
                    visible: (values: any) =>
                        get(values, 'selectMode') === MerchantSelectorMode.SUBSITE,
                },
                {
                    property: 'merchants',
                    visible: (values: any) =>
                        get(values, 'selectMode') === MerchantSelectorMode.MERCHANT,
                },
                {
                    property: 'goodsDistributionInfoList',
                },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
        },
        DistributorCommissionPage: {
            entity: 'DistributorCommissionEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DistributorCommissionFilter',
                },
                { component: 'DistributorCommissionTable' },
            ],
        },
        DistributorCommissionFilter: {
            component: 'FilterFormPlus',
            className: 'mb24',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'memberName',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'memberMobile',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'orderNo',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'status',
                    controlConfig: {
                        style: { width: 100 },
                    },
                },
                {
                    property: 'actualSettlementDateRange',
                    controlConfig: {
                        style: { width: 400 },
                    },
                },
                {
                    property: 'clearingStatus',
                    controlConfig: {
                        style: { width: 100 },
                    },
                },
                {
                    property: 'clearingDateRange',
                    controlConfig: {
                        style: { width: 400 },
                    },
                },
            ],
        },
        DistributorCommissionTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: {
                x: 1300,
            },
            columns: [
                {
                    property: 'subsiteName',
                    width: 180,
                },
                {
                    property: 'orderNo',
                    width: 180,
                },
                {
                    property: 'memberName',
                    width: 150,
                },
                {
                    property: 'memberMobile',
                    width: 200,
                },
                {
                    property: 'goodsName',
                    width: 200,
                },
                {
                    property: 'goodsAfterFoldingPrice',
                    width: 150,
                },
                {
                    property: 'commissionAmount',
                    width: 150,
                },
                {
                    property: 'status',
                    width: 100,
                },
                {
                    property: 'clearingStatus',
                    width: 100,
                },
                {
                    property: 'orderCreateTime',
                    width: 200,
                },
                {
                    property: 'orderReceivedTime',
                    width: 200,
                },
                {
                    property: 'actualSettlementTime',
                    width: 200,
                },
                {
                    property: 'clearingTime',
                    width: 200,
                },
            ],
        },
        RecruitDistributorPage: {
            component: 'FlexLayout',
            // direction: 'vertical',
            entity: 'RecruitDistributorEntity',
            loaderType: 'get',
            items: [
                { component: 'recruitDistributorPreview' },
                {
                    component: 'RecruitDistributorFormView',
                },
            ],
        },
        MemberIdentityApplyRecordsPage: {
            entity: 'MemberIdentityApplyRecordsEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'MemberIdentityApplyRecordsFilter',
                },
                { component: 'MemberIdentityApplyRecordsTable' },
            ],
        },
        MemberIdentityApplyRecordsFilter: {
            component: 'FilterFormPlus',
            className: 'mb24',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'mobile',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'applyName',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'auditStatus',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                /*  {
                    property: 'signStatus',
                    controlConfig: {
                        style: { width: 200 },
                    },
                }, */
            ],
        },
        MemberIdentityApplyRecordsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: {
                x: 1300,
            },
            columns: [
                {
                    property: 'memberId',
                    width: 120,
                },
                {
                    property: 'mobile',
                    width: 120,
                },
                {
                    property: 'applyName',
                    width: 100,
                },
                /* {
                    property: 'email',
                    width: 180,
                },
                {
                    property: 'nameEn',
                    width: 100,
                }, 
                
                {
                    property: 'signStatus',
                    width: 80,
                },
                */
                {
                    property: 'auditStatus',
                    width: 80,
                },
                {
                    property: 'createTime',
                    width: 120,
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 180,
                fixed: 'right',
                items: [
                    {
                        text: '<<common.edit>>',
                        type: 'link',
                        statusKey: 'row.canAudit',
                        path: '/menu-list/market/distribution/edit/{{row.id}}',
                    },
                    {
                        type: 'component',
                        text: '<<audity>>',
                        statusKey: 'row.canAudit',
                        component: 'AuditButton',
                        config: {
                            confirm: {
                                text: '<<approved>>',
                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`,
                                apiPath: `/admin/members/identities/apply_records/{id}/audit`,
                                params: {
                                    auditStatus: 'SUCCESS',
                                },
                            },
                            cancel: {
                                text: '<<shbtg>>',
                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`,
                                apiPath: `/admin/members/identities/apply_records/{id}/audit`,
                                params: {
                                    auditStatus: 'FAIL',
                                },
                            },
                            content: {
                                text: '<<yybz>>',
                            },
                            remarkName: 'remark',
                        },
                    },
                    {
                        type: 'link',
                        // statusKey: 'row.canAudit',
                        text: '<<common.view>>',
                        path: '/menu-list/market/distribution/process/info/{{row.id}}',
                    },
                ],
            },
        },
        MemberDistributorPage: {
            entity: 'MemberDistributorEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'MemberDistributorFilter',
                },
                { component: 'MemberDistributorTable' },
            ],
        },
        MemberDistributorFilter: {
            component: 'FilterFormPlus',
            className: 'mb24',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'mobile',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
            ],
        },
        MemberDistributorTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: {
                x: 1000,
            },
            columns: [
                {
                    property: 'distributorId',
                    width: 120,
                },
                {
                    property: 'memberId',
                    width: 120,
                },
                {
                    property: 'mobile',
                    width: 120,
                },
                {
                    property: 'memberName',
                    width: 100,
                },
                // {
                //     property: 'memberNameEn',
                //     width: 100,
                // },
                // {
                //     property: 'email',
                //     width: 180,
                // },

                {
                    property: 'group',
                    width: 80,
                },
            ],

            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 140,
                fixed: 'right',
                items: [
                    {
                        type: 'link',
                        // statusKey: 'row.canAudit',
                        text: '<<common.edit>>',
                        path: '/menu-list/market/distribution/record/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        // statusKey: 'row.canAudit',
                        text: '<<common.view>>',
                        path: '/menu-list/market/distribution/process/info/{{row.id}}?type=record',
                    },
                ],
            },
        },
        DistributionActivityWithdrawalRecordPage: {
            entity: 'DistributionActivityWithdrawalRecordEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DistributionActivityWithdrawalRecordFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'ExportDataByFilterParamsButton',
                                    params: {
                                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                        apiPath:
                                            '/admin/distribution_activity_commission_withdrawal_records/download',
                                        requestMethod: 'post',
                                    },
                                },
                            ],
                        },
                    ],
                },
                { component: 'DistributionActivityWithdrawalRecordTable' },
            ],
        },
        DistributionActivityWithdrawalRecordFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'memberName',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'mobile',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'commissionId',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'subsiteName',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
            ],
        },
        DistributionActivityWithdrawalRecordTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: {
                x: 1000,
            },
            columns: [
                {
                    property: 'memberName',
                    width: 120,
                },
                {
                    property: 'mobile',
                    width: 150,
                },
                {
                    property: 'commissionId',
                    width: 150,
                },
                {
                    property: 'withdrawalAmount',
                    width: 100,
                },
                {
                    property: 'subsiteName',
                    width: 120,
                },
                {
                    property: 'status',
                    width: 100,
                },
                {
                    property: 'transactionId',
                    width: 250,
                },
                {
                    property: 'wechatTransactionNumber',
                    width: 250,
                },
                {
                    property: 'createTime',
                    width: 180,
                },
            ],
        },

        DistributionEditPage: {
            component: 'Card',
            entity: 'MemberIdentityApplyRecordsEntity',
            loaderType: 'get',
            content: {
                component: 'DistributionEditForm',
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/distribution',
                    component: 'DistributionView',
                    breadcrumbName: '<<qmfx>>',
                    privilege: {
                        path: 'distributionManager',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/activity/add',
                            component: 'DistributorDistributionActivityAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/activity/edit/:id',
                            component: 'DistributorDistributionActivityEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/activity/info/:id',
                            component: 'DistributorDistributionActivityInfoViewPage',
                            breadcrumbName: '<<ckhd>>',
                        },
                        {
                            path: '/process/info/:id',
                            component: 'DistributionInfoPage',
                            breadcrumbName: '<<common.view>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'DistributionEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        {
                            path: '/record/edit/:id',
                            component: 'DistributionRecordInfoPage',
                            breadcrumbName: '<<common.edit>>',
                        },

                        { path: '/', component: 'DistributionPage' },
                    ],
                },
            ],
        },
    ],
};
