import React from 'react';
import { Badge } from 'antd';
import { modes } from '@comall-backend-builder/types';
export class PasswordValidityPeriodStatusMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    getAvailableDisplayComponent(value: string, displayConfig: any): JSX.Element {
        const { row } = displayConfig;
        if (row.status === 'NORMAL') {
            if (value === 'EXPIRED') return <Badge status="error" text="过期/启用" />;
            return <Badge status="success" text="启用" />;
        } else if (row.status === 'ABNORMAL') {
            if (value === 'EXPIRED') return <Badge status="error" text="过期/禁用" />;
            return <Badge status="default" text="禁用" />;
        }
        return <span>-</span>;
    }
}
