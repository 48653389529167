import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty, find, uniqueId } from 'lodash';

const api = services.api;

export const MemberUpgradeGiftLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/upgrade_gift_activities';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/upgrade_gift_activities/${id}`;
        } else {
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                let subsiteIds = [];
                let limitCrowds = [];
                if (res.normalConditions && res.normalConditions.length) {
                    const normalConditionsSubsites = find(res.normalConditions, {
                        normalConditionType: 'SUB_SITE',
                    });
                    const normalConditionsCrowds = find(res.normalConditions, {
                        normalConditionType: 'CROWD',
                    });
                    subsiteIds = normalConditionsSubsites
                        ? normalConditionsSubsites.includeIds
                        : [];
                    limitCrowds = normalConditionsCrowds
                        ? normalConditionsCrowds.includeIds.map((item: any) => {
                              item.id = item.crowdId;
                              return item;
                          })
                        : [];
                }
                res.baseInfo = {
                    name: res.name,
                    dateRange: {
                        start: res.startTime,
                        end: res.endTime,
                    },
                    subsiteIds,
                    limitCrowds,
                    receiveType: res.receiveType,
                };
                res.giftInfo = {
                    rewards: res.activityRewards.map((item: any) => {
                        return {
                            id: uniqueId('gifi_old_'),
                            cardLevelId: item.conditionValue.toString(),
                            assetRewards: item.assetRewards,
                            couponRewards:
                                item.couponRewards && item.couponRewards.length
                                    ? item.couponRewards.map((item: any) => {
                                          item.id = item?.couponDefinitionId?.toString() || '';
                                          item.quantity = item.count;
                                          item.type = item.couponType;
                                          return item;
                                      })
                                    : undefined,
                        };
                    }),
                };
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/upgrade_gift_activities';
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = `/admin/upgrade_gift_activities/${id}`;
        const newParams = dataParser(params);
        return await api.put(newParams, config);
    },
};

function dataParser(params: any) {
    const newParams: any = {};
    if (params.baseInfo) {
        newParams.name = params.baseInfo.name;
        newParams.startTime = params.baseInfo.dateRange.start;
        newParams.endTime = params.baseInfo.dateRange.end;
        newParams.subsiteIds = params.baseInfo.subsiteIds?.map((item: any) => ({
            id: item.id,
            name: item.name,
        }));
        newParams.crowdIds = params.baseInfo.limitCrowds?.map((item: any) => ({
            id: item.id,
            name: item.name,
        }));
        newParams.receiveType = params.baseInfo.receiveType;
    }
    if (params.giftInfo) {
        newParams.activityRewards = params.giftInfo.rewards.map((item: any) => {
            return {
                conditionValue: item.cardLevelId,
                assetRewards: item.assetRewards,
                couponRewards: item.couponRewards?.map((coupon: any) => ({
                    couponDefinitionId: coupon.id,
                    count: coupon.quantity,
                })),
            };
        });
    }
    return newParams;
}
