import { builder } from '@comall-backend-builder/core';
import { InternalPurchaseEnterpriseLoader } from './internal-purchase-enterprise';
import { InternalPurchaseActivityLoader } from './internal-purchase-activity';
import { InternalPurchaseGoodsLoader } from './internal-purchase-goods';
import { InternalPurchaseMobileLoader } from './internal-purchase-mobiles';
import { CommentActivityLoader } from './comment-activity';
import { LuckyRedPackageLoader } from './lucky-red-package-loader';
import { SharePageActivityLoader } from './share-page-activity-loader';
import { PaymentRewardLoader } from './payment-reward-loader';
import { LiveRoomProductLoader, LiveRoomBroadcastLoader } from './live-room-product-loader';
import { PayWithGiftsLoader } from './pay-with-gifts-loader';
import { PayWithGiftConfigsLoader } from './pay-with-gift-configs-loader';
import { GuideShoppingConfigsLoader } from './guide-shopping-configs-loader';
import {
    CollectionPointsLoader,
    CollectionPointsMemberPointLoader,
    CollectionPointsRewardRecordsLoader,
} from './collection-points-loader';

builder.registerLoader('/admin/enterprises', InternalPurchaseEnterpriseLoader);
builder.registerLoader('/admin/internal_purchase_activities', InternalPurchaseActivityLoader);
builder.registerLoader('/admin/internal_purchase_goods', InternalPurchaseGoodsLoader);
builder.registerLoader('/admin/enterprises/relation_mobile', InternalPurchaseMobileLoader);
builder.registerLoader('/admin/comment_activity', CommentActivityLoader);
builder.registerLoader('/admin/lucky_red_package', LuckyRedPackageLoader);
builder.registerLoader('/admin/share_page_activities', SharePageActivityLoader);
builder.registerLoader('/admin/payment_reward', PaymentRewardLoader);
builder.registerLoader('/admin/live_room_product', LiveRoomProductLoader);
builder.registerLoader('/admin/live_broadcast', LiveRoomBroadcastLoader);
builder.registerLoader('/admin/pay-with-gifts', PayWithGiftsLoader);
builder.registerLoader('/admin/event_market_page_configs', PayWithGiftConfigsLoader);
builder.registerLoader('/admin/guide_shopping_configs', GuideShoppingConfigsLoader);
builder.registerLoader('/admin/collection_points', CollectionPointsLoader);
builder.registerLoader('/admin/collection_points_member_points', CollectionPointsMemberPointLoader);
builder.registerLoader(
    '/admin/collection_points_reward_records',
    CollectionPointsRewardRecordsLoader
);
