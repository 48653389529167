/*
 * @Author: zhuhuajuan
 * @Date: 2020-8-228 10:09:41
 * @Description: 会员基本信息管理
 */
import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { api } from '@comall-backend-builder/core/lib/services';
import { ButtonProps } from 'antd/lib/button';

import { EntityButtonProps } from '../../../components';
import { ForwardDataCenterModal } from '../../../services';

/**
 * 数据处理结果
 */
export enum FileStatus {
    /**
     * 全部
     */
    ALL = 'ALL',
    /**
     * 正在处理
     */
    PROCESSING = 'PROCESSING',
    /**
     * 处理完成
     */
    PROCESS_FINISHED = 'PROCESS_FINISHED',
    /**
     * 处理失败（系统异常）
     */
    PROCESS_FAILED = 'PROCESS_FAILED',
}

const FILE_STATUS_OPTIONS = [
    {
        id: FileStatus.ALL,
        name: '<<all>>',
    },
    {
        id: FileStatus.PROCESSING,
        name: '<<processing>>',
    },
    {
        id: FileStatus.PROCESS_FINISHED,
        name: '<<processFinished>>',
    },
    {
        id: FileStatus.PROCESS_FAILED,
        name: '<<processFailed>>',
    },
];

const STATUS_OPTIONS = [
    {
        id: 'ALL',
        name: '<<all>>',
    },
    {
        id: 'SUBSCRIBE',
        name: '<<ygz>>',
    },
    {
        id: 'UN_SUBSCRIBE',
        name: '<<wgz>>',
    },
];

const TRIPARTITE_REGISTERED_OPTIONS = [
    {
        id: 'all',
        name: '<<all>>',
    },
    {
        id: 'false',
        name: '<<yes>>',
    },
    {
        id: 'true',
        name: '<<no>>',
    },
];

export let config: Config = {
    entities: {
        memberInfo: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
            apiPath: '/loader/admin/members',
            properties: {
                id: { type: 'string', displayName: '<<memberId>>' },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<grxx>>',
                    properties: {
                        avatarUrl: {
                            type: 'string.image.picker',
                            displayName: '<<tx_1>>',
                        },
                        name: {
                            type: 'string',
                            displayName: '<<name>>',
                        },
                        birthday: {
                            type: 'string',
                            displayName: '<<csrq>>',
                        },
                        gender: {
                            type: 'string.options.select',
                            displayName: '<<xb_1>>',
                            defaultValue: 'UNKNOWN',
                            options: [
                                {
                                    id: 'UNKNOWN',
                                    name: '<<wz_4>>',
                                },
                                { id: 'WOMAN', name: '<<n>>' },
                                { id: 'MAN', name: '<<n_2>>' },
                            ],
                        },
                        region: {
                            type: 'string',
                            displayName: '<<szdq>>',
                        },
                        address: {
                            type: 'string',
                            displayName: '<<detailAddress>>',
                        },
                        postCode: {
                            type: 'string',
                            displayName: '<<yb>>',
                        },
                    },
                },
                tagInfo: {
                    type: 'object.subForm',
                    displayName: '<<bqxx>>',
                    properties: {
                        tags: {
                            type: 'array.memberTypeTags',
                            displayName: '<<tagInfo>>',
                        },
                    },
                },
                accountInfo: {
                    type: 'object.subForm',
                    displayName: '<<zhxx>>',
                    properties: {
                        loginName: {
                            type: 'string',
                            displayName: '<<loginName>>',
                        },
                        mobile: {
                            type: 'string',
                            displayName: '<<sjhm>>',
                        },
                        levelName: {
                            type: 'string',
                            displayName: '<<hydj>>',
                        },
                        pointAccountBalance: {
                            type: 'string',
                            displayName: '<<point>>',
                        },
                        expAccountBalance: {
                            type: 'string',
                            displayName: '<<experienceManage>>',
                        },
                        couponCount: {
                            type: 'string',
                            displayName: '<<components.ComponentSelector.coupon>>',
                        },
                    },
                },
                otherInfo: {
                    type: 'object.subForm',
                    displayName: '<<qtxx>>',
                    properties: {
                        registrationSource: {
                            type: 'string.options.radio',
                            displayName: '<<zcqd>>',
                            options: [
                                { id: 'ANDROID', name: '<<azydd>>' },
                                { id: 'IOS', name: '<<jiosydd>>' },
                                { id: 'SMALL_PROGRAM', name: '<<wxMiniP>>' },
                                { id: 'WECHAT', name: '<<wxd>>' },
                                { id: 'PC', name: '<<wyd>>' },
                                { id: 'ALIPAY_SMALL_PROGRAM', name: '<<zfb>>' },
                                { id: 'BYTE_DANCE_SMALL_PROGRAM', name: '抖音小程序' },
                            ],
                        },
                        utmTypeName: {
                            type: 'string',
                            displayName: '<<smlx>>',
                        },
                        scanSource: {
                            type: 'string',
                            displayName: '<<smly>>',
                        },
                        regIp: {
                            type: 'string',
                            displayName: '<<regIp>>',
                        },
                        regSubsite: {
                            type: 'string',
                            displayName: '<<xszcmd>>',
                        },
                        status: {
                            type: 'string.statusBadge',
                            displayName: '<<accountStatus>>',
                            defaultValue: 'NORMAL',
                            options: [
                                { id: 'NORMAL', name: '<<enable>>' },
                                { id: 'INVALID', name: '<<disabled>>' },
                                { id: 'OFF', name: '<<off>>' },
                            ],
                        },
                        email: {
                            type: 'string',
                            displayName: '<<email>>',
                        },
                        memberFirstLoginTime: {
                            type: 'string',
                            displayName: '<<xsscsysj>>',
                        },
                        regTime: {
                            type: 'string',
                            displayName: '<<xszcsj>>',
                        },
                        memberLoginIp: {
                            type: 'string',
                            displayName: '<<zhdl>>',
                        },
                        memberLoginTime: {
                            type: 'string',
                            displayName: '<<xszhdlsj>>',
                        },
                        cid: {
                            type: 'string',
                            displayName: 'CID',
                        },
                        sourceDepartment: {
                            type: 'string',
                            displayName: '<<zxbm>>',
                        },
                        sourceMerchant: {
                            type: 'string',
                            displayName: '<<zxbm>>',
                        },
                        sourceMerchantWorker: {
                            type: 'string',
                            displayName: '<<zxdgy>>',
                        },
                        mobileValidated: {
                            type: 'string.options.radio',
                            displayName: '<<yzzt>>',
                            options: [
                                { id: 'true', name: '<<sjyz>>' },
                                { id: 'false', name: '<<wyz>>' },
                            ],
                        },
                        plateNo: {
                            type: 'string',
                            displayName: '<<cpxx>>',
                        },
                        receivingAddress: {
                            type: 'string',
                            displayName: '<<cyshdz>>',
                        },
                        tripartiteRegTime: {
                            type: 'string',
                            displayName: '<<xxzcsj>>',
                        },
                        tripartiteRegSubsiteName: {
                            type: 'string',
                            displayName: '<<xxzcmd>>',
                        },
                    },
                },

                loginName: {
                    type: 'string',
                    displayName: '<<loginName>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<sjhm>>',
                },
                nickname: {
                    type: 'string',
                    displayName: '<<nickName>>',
                },

                levelName: {
                    type: 'string',
                    displayName: '<<hydj>>',
                },
                tagName: {
                    type: 'string',
                    displayName: '<<hybq>>',
                },
                pointAccountBalance: {
                    type: 'string',
                    displayName: '<<point>>',
                },
                mobileValidated: {
                    type: 'string.options.radio',
                    displayName: '<<yzzt>>',
                    options: [
                        { id: 'true', name: '<<sjyz>>' },
                        { id: 'false', name: '<<wyz>>' },
                    ],
                },
                regTime: {
                    type: 'string',
                    displayName: '<<xszcsj>>',
                },
                registrationSource: {
                    type: 'string.options.radio',
                    displayName: '<<zcqd>>',
                    options: [
                        { id: 'ANDROID', name: '<<azydd>>' },
                        { id: 'IOS', name: '<<jiosydd>>' },
                        { id: 'SMALL_PROGRAM', name: '<<wxMiniP>>' },
                        // { id: 'WECHAT', name: '微信端' },
                        { id: 'PC', name: '<<wyd>>' },
                        { id: 'ALIPAY_SMALL_PROGRAM', name: '<<zfb>>' },
                        { id: 'BYTE_DANCE_SMALL_PROGRAM', name: '抖音小程序' },
                    ],
                },
                utmTypeName: {
                    type: 'string',
                    displayName: '<<smlx>>',
                },
                scanSource: {
                    type: 'string',
                    displayName: '<<smly>>',
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<accountStatus>>',
                    defaultValue: 'NORMAL',
                    options: [
                        { id: 'NORMAL', name: '<<enable>>' },
                        { id: 'INVALID', name: '<<disabled>>' },
                        { id: 'OFF', name: '<<off>>' },
                    ],
                },
                registrationSubsite: {
                    type: 'string',
                    displayName: '<<xszcmd>>',
                },
                tripartiteRegTime: {
                    type: 'string',
                    displayName: '<<xxzcsj>>',
                },
                tripartiteRegSubsite: {
                    type: 'string',
                    displayName: '<<xxzcmd>>',
                },
                wechatOfficialAccountFanStatus: {
                    type: 'string.options.select',
                    displayName: '<<sfgzgzh>>',
                    options: STATUS_OPTIONS,
                },
                tripartiteRegistered: {
                    type: 'string.options.select',
                    displayName: '<<xxxyh>>',
                    options: TRIPARTITE_REGISTERED_OPTIONS,
                    controlConfig: {
                        style: { width: '120px' },
                    },
                },
                wechatOfficialAccounts: {
                    type: 'array.popover',
                    displayName: '<<gzhmc>>',
                },
                firstUseTime: {
                    type: 'string',
                    displayName: '<<xsscsysj>>',
                },
                lastUseTime: {
                    type: 'string',
                    displayName: '<<xszhdlsj>>',
                },
                cid: {
                    type: 'string',
                    displayName: 'CID',
                },
                sourceDepartment: {
                    type: 'string',
                    displayName: '<<zxbm>>',
                },
                sourceMerchant: {
                    type: 'string',
                    displayName: '<<zxbm>>',
                },
                sourceMerchantWorker: {
                    type: 'string',
                    displayName: '<<zxdgy>>',
                },
            },
            filters: {
                mobile: {
                    type: 'string',
                    displayName: '<<sjhm>>',
                },
                loginName: {
                    type: 'string',
                    displayName: '<<loginName>>',
                },
                nickname: {
                    type: 'string',
                    displayName: '<<nickName>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<name>>',
                },
                tagName: {
                    type: 'string',
                    displayName: '<<hybq>>',
                },
                registrationSource: {
                    type: 'string.options.select',
                    displayName: '<<zcqd>>',
                    defaultValue: 'ALL',
                    options: [
                        {
                            id: 'ALL',
                            name: '<<all>>',
                        },
                        { id: 'ANDROID', name: '<<azydd>>' },
                        { id: 'IOS', name: '<<jiosydd>>' },
                        { id: 'SMALL_PROGRAM', name: '<<wxMiniP>>' },
                        { id: 'PC', name: '<<wyd>>' },
                        { id: 'ALIPAY_SMALL_PROGRAM', name: '<<zfb>>' },
                        { id: 'BYTE_DANCE_SMALL_PROGRAM', name: '抖音小程序' },
                    ],
                    controlConfig: {
                        style: { width: '120px' },
                    },
                },
                utmType: {
                    type: 'string.options.select',
                    displayName: '<<smlx>>',
                    options: [
                        { id: 'MINI_PROGRAM_CODE', name: '<<qdtgm>>' },
                        {
                            id: 'WECHAT_OFFIACCOUNT_QR_MSG',
                            name: '<<wechatOfficialParametricQrcode>>',
                        },
                    ],
                    controlConfig: {
                        style: { width: '130px' },
                    },
                },
                scanSource: {
                    type: 'string',
                    displayName: '<<smly>>',
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<xszcsj>>',
                },
                subsiteName: {
                    type: 'object.option.autoComplete',
                    displayName: '<<xszcmd>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                tripartiteDateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<xxzcsj>>',
                },
                tripartiteRegSubsite: {
                    type: 'string',
                    displayName: '<<xxzcmd>>',
                },
                cid: {
                    type: 'string',
                    displayName: 'CID',
                },
                firstUseTime: {
                    type: 'object.dateRangePlus',
                    displayName: '<<xsscsysj>>',
                },
                lastUseTime: {
                    type: 'object.dateRangePlus',
                    displayName: '<<xszhdlsj>>',
                },
                sourceDepartment: {
                    type: 'string',
                    displayName: '<<zxbm>>',
                },
                sourceMerchant: {
                    type: 'string',
                    displayName: '<<zxbm>>',
                },
                sourceMerchantWorker: {
                    type: 'string',
                    displayName: '<<zxdgy>>',
                },
                wechatOfficialAccountFanStatus: {
                    type: 'string.options.select',
                    displayName: '<<sfgzgzh>>',
                    options: STATUS_OPTIONS,
                    controlConfig: {
                        style: { width: '120px' },
                    },
                },
                wechatOfficialAccountName: {
                    type: 'string',
                    displayName: '<<gzhmc>>',
                },
                tripartiteRegistered: {
                    type: 'string.options.select',
                    displayName: '<<xxxyh>>',
                    options: TRIPARTITE_REGISTERED_OPTIONS,
                    controlConfig: {
                        style: { width: '120px' },
                    },
                },
            },
        },
        MemberInfoCouponEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/coupons/:id/coupons',
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<couponName>>',
                },
                couponNo: {
                    type: 'string',
                    displayName: '<<couponNum>>',
                },
                type: {
                    type: 'string.options.select',
                    displayName: '<<qlx>>',
                    options: [
                        { id: 'CASH', name: '<<cashCoupon>>' },
                        { id: 'GIFT', name: '<<gift>>' },
                        { id: 'PARKING', name: '<<parking>>' },
                        { id: 'FREIGHT', name: '<<freight>>' },
                        { id: 'LOTTERY', name: '<<lottery>>' },
                        { id: 'BALANCE', name: '<<balance>>' },
                        { id: 'EFUTURE_CASH', name: '<<efutureCash>>' },
                        { id: 'EFUTURE_BALANCE', name: '<<efutureBalance>>' },
                        { id: 'BFPLAT_COUPON', name: '<<bfplat>>' },
                    ],
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<executeStatus>>',
                },
                sourceType: {
                    type: 'string',
                    displayName: '<<hqly>>',
                },
                channelName: {
                    type: 'string',
                    displayName: '<<sfqd>>',
                },
                bindingTime: {
                    type: 'string',
                    displayName: '<<bindingTime>>',
                },
                validTime: {
                    type: 'string',
                    displayName: '<<dynamicEffectivePeriod>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<couponName>>',
                },
                couponNo: {
                    type: 'string',
                    displayName: '<<couponNum>>',
                },
                couponStatus: {
                    type: 'string.options.select',
                    displayName: '<<executeStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'PREBINDING', name: '<<jybd>>' },
                        { id: 'ENABLED', name: '<<dsy>>' },
                        { id: 'USED', name: '<<ysy_1>>' },
                        { id: 'EXPIRED', name: '<<ygq>>' },
                        { id: 'INVALID', name: '<<ysx>>' },
                        { id: 'FREEZE', name: '<<ydj>>' },
                        { id: 'TRANSFERRING', name: '<<zsz_1>>' },
                    ],
                },
                type: {
                    type: 'string.options.select',
                    displayName: '<<qlx>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'CASH', name: '<<cashCoupon>>' },
                        { id: 'GIFT', name: '<<gift>>' },
                        { id: 'PARKING', name: '<<parking>>' },
                        { id: 'FREIGHT', name: '<<freight>>' },
                        { id: 'LOTTERY', name: '<<lottery>>' },
                    ],
                },
                sourceType: {
                    type: 'string.options.select',
                    displayName: '<<hqly>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'ACTIVITY', name: '<<mflq>>' },
                        { id: 'REGISTER_AWARD', name: '<<zcjl>>' },
                        { id: 'SIGN_IN', name: '<<qdyl>>' },
                        { id: 'LUCK_DRAW_GIVE', name: '<<cjzs>>' },
                        { id: 'GIVING_COUPON_SCHEDULE', name: '<<dxfq>>' },
                        { id: 'BIRTHDAY_GIVE', name: '<<birthdayCaring>>' },
                        { id: 'INVITATION', name: '<<yqyl>>' },
                        { id: 'REDEEM_CODE', name: '<<redeCode>>' },
                        { id: 'SALE_COUPON', name: '<<smyhq>>' },
                        { id: 'MALL_ACTIVITY', name: '<<mallActivity>>' },
                        { id: 'CREDIT_ESHOP', name: '<<creditEShop>>' },
                        { id: 'GROUP_BUYING_COUPON', name: '<<pthd>>' },
                        { id: 'CARD_COLLECTION', name: '<<jkzs>>' },
                        { id: 'COLLECTION_POINT', name: '<<collectionPointsCoupon>>' },
                        { id: 'GUIDE_COUPON', name: '<<dgfq>>' },
                        { id: 'LUCKY_RED_PACKAGE', name: '<<psqhb>>' },
                        { id: 'PURCHASE', name: '<<gm_1>>' },
                        { id: 'OTHER', name: '<<other>>' },
                        { id: 'TRANSFER', name: '<<zs_4>>' },
                        {
                            id: 'FOLLOW_WECHAT_OFFICIAL_ACCOUNT',
                            name: '<<gzgzhyl>>',
                        },
                        {
                            id: 'WATCHWORD',
                            name: '<<watchwordCouponCollection>>',
                        },
                    ],
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<dynamicEffectivePeriod>>',
                },
            },
        },
        MemberInfoDownloadEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
            apiPath: '/admin/download?businessType=MEMBER_BASIC_INFO&creator=1',
            properties: {
                id: {
                    type: 'string',
                },
                fileName: {
                    type: 'string',
                    displayName: '<<fileName>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<createTimeStr>>',
                },
                processStatus: {
                    type: 'string.options.select',
                    displayName: '<<processStatus>>',
                    options: FILE_STATUS_OPTIONS,
                },
            },
        },
        MemberPointTransLogEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/members/:id/points',
            properties: {
                id: {
                    type: 'string',
                },
                transTime: {
                    type: 'string',
                    displayName: '<<fssj>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<fsmd>>',
                },
                transActionRemark: {
                    type: 'string',
                    displayName: '<<categoryName>>',
                },
                transAmount: {
                    type: 'string',
                    displayName: '<<transAmount>>',
                },
                desc: {
                    type: 'string',
                    displayName: '<<bdyy>>',
                },
                associateOrder: {
                    type: 'string',
                    displayName: '<<ddh_1>>',
                },
                operator: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
                remark: {
                    type: 'string',
                    displayName: '<<remark>>',
                },
            },
            filters: {
                subsite: {
                    type: 'object.option.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<jfbgsj>>',
                },
            },
        },
    },
    components: {
        MemberView: {
            component: 'Viewport',
            entity: 'memberInfo',
        },
        MemberPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            style: {
                padding: '12px 32px 24px 32px',
            },
            items: [
                {
                    component: 'Tabs',
                    type: 'card',
                    cache: false,
                    animated: false,
                    items: [
                        {
                            title: '<<newMemberInfo>>',
                            content: {
                                component: 'MemberInfoReport',
                            },
                        },
                        {
                            title: '<<downloadData>>',
                            content: {
                                component: 'MemberInfoDownload',
                            },
                        },
                    ],
                },
            ],
        },
        MemberInfoReport: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'MemberFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'EntityButton',
                                    props: {
                                        type: 'primary',
                                        children: '<<exportSearchResult>>',
                                        onClick: async (
                                            _: any,
                                            entityButtonProps: EntityButtonProps
                                        ): Promise<void> => {
                                            try {
                                                const params: Record<string, any> = Object.assign(
                                                    {},
                                                    entityButtonProps.entity.params,
                                                    entityButtonProps.entity.filters
                                                );

                                                if (params.dateRange) {
                                                    params.startTime = `${params.dateRange.start} 00:00:00`;
                                                    params.endTime = `${params.dateRange.end} 23:59:59`;
                                                    params.dateRange = undefined;
                                                }
                                                if (params.tripartiteDateRange) {
                                                    params.tripartiteStartTime = `${params.tripartiteDateRange.start} 00:00:00`;
                                                    params.tripartiteEndTime = `${params.tripartiteDateRange.end} 23:59:59`;
                                                    params.tripartiteDateRange = undefined;
                                                }
                                                if (params.subsiteName) {
                                                    params.subsiteName = params.subsiteName.name;
                                                }

                                                if (params.registrationSource === FileStatus.ALL) {
                                                    params.registrationSource = undefined;
                                                }

                                                if (params.tripartiteRegistered === 'all') {
                                                    delete params.tripartiteRegistered;
                                                }

                                                const config = {
                                                    apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                                                    apiPath: '/admin/members/download',
                                                };

                                                await api.get(params, config);
                                                ForwardDataCenterModal();
                                            } catch (err) {
                                                services.errorHandle(err as any);
                                            }
                                        },
                                    },
                                },
                            ],
                        },
                    ],
                },
                { component: 'MemberTable' },
            ],
        },
        MemberFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'mobile',
                    controlConfig: { placeholder: '<<qsrsjhm>>' },
                },
                {
                    property: 'loginName',
                    controlConfig: { placeholder: '<<qsryhm>>' },
                },
                {
                    property: 'nickname',
                    controlConfig: { placeholder: '<<qsrhync>>' },
                },
                {
                    property: 'registrationSource',
                },
                {
                    property: 'utmType',
                },
                {
                    property: 'scanSource',
                },
                {
                    property: 'dateRange',
                },
                {
                    property: 'tagName',
                    controlConfig: { placeholder: '<<qsrhybq>>' },
                },
                {
                    property: 'subsiteName',
                },
                {
                    property: 'cid',
                },
                {
                    property: 'firstUseTime',
                },
                {
                    property: 'lastUseTime',
                },
                {
                    property: 'sourceDepartment',
                },
                {
                    property: 'sourceMerchant',
                },
                {
                    property: 'sourceMerchantWorker',
                },
                {
                    property: 'tripartiteRegSubsite',
                    controlConfig: { placeholder: '<<subsiteNameRequired>>' },
                },
                {
                    property: 'tripartiteDateRange',
                },
                {
                    property: 'wechatOfficialAccountFanStatus',
                },
                {
                    property: 'wechatOfficialAccountName',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                {
                    property: 'tripartiteRegistered',
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: '120px' },
                    },
                },
            ],
        },
        MemberTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'MembersDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: {
                        x: true,
                    },
                    columns: [
                        {
                            property: 'loginName',
                        },
                        {
                            property: 'mobile',
                        },
                        {
                            property: 'nickname',
                        },
                        {
                            property: 'id',
                        },
                        {
                            property: 'levelName',
                        },
                        {
                            property: 'tagName',
                        },
                        { property: 'pointAccountBalance' },
                        {
                            property: 'mobileValidated',
                        },
                        {
                            property: 'firstUseTime',
                        },
                        {
                            property: 'regTime',
                        },
                        {
                            property: 'lastUseTime',
                        },
                        {
                            property: 'registrationSource',
                        },
                        {
                            property: 'registrationSubsite',
                        },
                        {
                            property: 'utmTypeName',
                        },
                        {
                            property: 'scanSource',
                        },
                        {
                            property: 'tripartiteRegSubsite',
                        },
                        {
                            property: 'tripartiteRegTime',
                        },
                        {
                            property: 'tripartiteRegistered',
                        },
                        {
                            property: 'status',
                            displayConfig: {
                                statusConfig: {
                                    NORMAL: {
                                        text: '<<enable>>',
                                        status: 'processing',
                                    },
                                    INVALID: {
                                        text: '<<invalid>>',
                                        status: 'default',
                                    },
                                    OFF: {
                                        text: '<<off>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                        {
                            property: 'cid',
                        },
                        {
                            property: 'sourceDepartment',
                        },
                        {
                            property: 'sourceMerchant',
                        },
                        {
                            property: 'sourceMerchantWorker',
                        },
                        {
                            property: 'wechatOfficialAccountFanStatus',
                        },
                        {
                            property: 'wechatOfficialAccounts',
                            displayConfig: {
                                countSuffix: '<<wechatOfficialAccount>>',
                                replaceEmpty: '<<wgz>>',
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '240px',
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        fixed: 'right',
                        items: [
                            {
                                type: 'link',
                                text: '<<common.view>>',
                                path: '/member/info/{{row.id}}',
                            },
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    statusKey: 'status',
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: 'INVALID' },
                                            value: 'NORMAL',
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                                            apiPath: '/admin/members/:id/status',
                                            config: { content: '<<invalid>>', text: '<<invalid>>' },
                                            confirm: {
                                                text: '<<sfdj>>{{row.loginName}}?',
                                            },
                                        },
                                        {
                                            params: { status: 'NORMAL' },
                                            value: 'INVALID',
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                                            apiPath: '/admin/members/:id/status',
                                            config: { content: '<<jd>>', text: '<<jd>>' },
                                            confirm: {
                                                text: '<<sfjd>>{{row.loginName}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'component',
                                component: 'SetMemberTagModal',
                                config: {
                                    triggerComponent: {
                                        component: 'Button',
                                        type: 'link',
                                        text: '<<bq>>',
                                        style: { marginRight: '10px' },
                                    },
                                },
                            },
                        ],
                    },
                },
            ],
        },
        MemberInfoDownload: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'MemberInfoDownloadEntity',
            items: [
                {
                    component: 'MemberInfoDownloadTable',
                },
            ],
        },
        MemberInfoDownloadTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'fileName',
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'processStatus',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 260,
                items: [
                    {
                        type: 'component',
                        component: 'EntityButton',
                        config: {
                            props: {
                                type: 'link',
                                disabled: (entityButtonProps: EntityButtonProps) =>
                                    entityButtonProps.row.processStatus !==
                                    FileStatus.PROCESS_FINISHED,
                                children: '<<downloadData>>',
                            },
                            propsHandler(
                                buttonProps: ButtonProps,
                                entityButtonProps: EntityButtonProps
                            ): ButtonProps {
                                return {
                                    ...buttonProps,
                                    download: entityButtonProps.row.fileName,
                                    href: entityButtonProps.row.filePath,
                                };
                            },
                        },
                    },
                ],
            },
        },
        MemberInfoCoupon: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'MemberInfoCouponEntity',
            items: [
                { component: 'MemberInfoCouponFilter' },
                { component: 'MemberInfoCouponTable' },
            ],
        },
        MemberInfoCouponFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: { placeholder: '<<qsryhqmc>>' },
                },
                {
                    property: 'couponNo',
                    controlConfig: { placeholder: '<<qsrqh>>' },
                },
                {
                    property: 'couponStatus',
                    controlConfig: { style: { width: 160 } },
                },
                {
                    property: 'type',
                    controlConfig: { style: { width: 160 } },
                },
                {
                    property: 'sourceType',
                    controlConfig: { style: { width: 160 } },
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        MemberInfoCouponTable: {
            component: 'DataTable',
            loadFirstPage: true,
            scroll: { x: true },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'couponNo',
                },
                {
                    property: 'type',
                },
                {
                    property: 'status',
                    width: 100,
                    displayConfig: {
                        statusConfig: {
                            ENABLED: {
                                text: '<<dsy>>',
                                status: 'default',
                            },
                            PREBINDING: {
                                text: '<<jybd>>',
                                status: 'default',
                            },
                            USED: {
                                text: '<<ysy_1>>',
                                status: 'success',
                            },
                            EXPIRED: {
                                text: '<<ygq>>',
                                status: 'error',
                            },
                            INVALID: {
                                text: '<<ysx>>',
                                status: 'error',
                            },
                            FREEZE: {
                                text: '<<ydj>>',
                                status: 'warn',
                            },
                            TRANSFERRING: {
                                text: '<<zsz_1>>',
                                status: 'default',
                            },
                        },
                    },
                },
                {
                    property: 'sourceType',
                },
                {
                    property: 'channelName',
                },
                {
                    property: 'bindingTime',
                    width: 200,
                },
                {
                    property: 'validTime',
                    width: 360,
                },
            ],
        },
        MemberInfoPage: {
            component: 'Card',
            content: { component: 'MemberInfoView' },
        },
        MemberInfoComponent: {
            component: 'Detail',
            entity: 'memberInfo',
            loaderType: 'get',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: [
                {
                    property: 'baseInfo.avatarUrl',
                    displayConfig: {
                        style: {
                            width: 120,
                        },
                    },
                },
                { property: 'baseInfo.name' },
                { property: 'baseInfo.birthday' },
                { property: 'baseInfo.gender' },
                { property: 'baseInfo.region' },
                { property: 'baseInfo.address' },
                { property: 'baseInfo.postCode' },
                { property: 'tagInfo.tags' },
                { property: 'accountInfo.loginName' },
                { property: 'accountInfo.mobile' },
                { property: 'accountInfo.levelName' },
                { property: 'accountInfo.pointAccountBalance' },
                { property: 'accountInfo.expAccountBalance' },
                { property: 'accountInfo.couponCount' },
                { property: 'otherInfo.registrationSource' },
                { property: 'otherInfo.utmTypeName' },
                { property: 'otherInfo.scanSource' },
                { property: 'otherInfo.regIp' },
                { property: 'otherInfo.regSubsite' },
                {
                    property: 'otherInfo.status',
                    displayConfig: {
                        statusConfig: {
                            NORMAL: {
                                text: '<<enable>>',
                                status: 'processing',
                            },
                            INVALID: {
                                text: '<<disabled>>',
                                status: 'default',
                            },
                            OFF: {
                                text: '<<off>>',
                                status: 'default',
                            },
                        },
                    },
                },
                { property: 'otherInfo.email' },
                { property: 'otherInfo.memberFirstLoginTime' },
                { property: 'otherInfo.regTime' },
                { property: 'otherInfo.memberLoginIp' },
                { property: 'otherInfo.memberLoginTime' },
                { property: 'otherInfo.cid' },
                { property: 'otherInfo.sourceDepartment' },
                { property: 'otherInfo.sourceMerchant' },
                { property: 'otherInfo.sourceMerchantWorker' },
                { property: 'otherInfo.mobileValidated' },
                { property: 'otherInfo.plateNo' },
                { property: 'otherInfo.receivingAddress' },
                { property: 'otherInfo.tripartiteRegSubsiteName' },
                { property: 'otherInfo.tripartiteRegTime' },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'primary',
                    },
                ],
            },
        },
        MemberInfoView: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'Tabs',
                    type: 'card',
                    cache: false,
                    animated: false,
                    items: [
                        {
                            title: '<<grxx>>',
                            content: { component: 'MemberInfoComponent' },
                        },
                        {
                            title: '<<yhq>>',
                            content: {
                                component: 'MemberInfoCoupon',
                            },
                        },
                        {
                            title: '<<jfmx>>',
                            content: {
                                component: 'MemberPointTransLogPage',
                            },
                        },
                    ],
                },
            ],
        },
        MemberPointTransLogPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'MemberPointTransLogEntity',
            items: [{ component: 'MemberPointTransLogView' }],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/member',
                    breadcrumbName: '<<newMemberInfo>>',
                    component: 'MemberView',
                    privilege: {
                        path: 'newMemberInfo',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/info/:id',
                            component: 'MemberInfoPage',
                            breadcrumbName: '<<common.view>>',
                        },

                        { path: '/', component: 'MemberPage' },
                    ],
                },
            ],
        },
    ],
};

/**
 * 金币详情
 */

// 1. 定义菜单
config.components?.MemberInfoView?.items[0].items.push({
    title: '<<jbmx>>',
    content: { component: 'MemberGoldDetailPage' },
});
// 2. 定义实体
config.entities!.MemberGoldDetailEntity = {
    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`,
    apiPath: '/loader/admin/gold_accounts',
    properties: {
        accountId: { type: 'string' },
        transTime: { type: 'string', displayName: '<<fssj>>' },
        subsiteName: { type: 'string', displayName: '<<fsmd>>' },
        transActionRemark: { type: 'string', displayName: '<<categoryName>>' },
        transAmount: { type: 'string', displayName: '<<bdjb>>' },
        associateOrder: { type: 'string', displayName: '<<ddh_1>>' },
        operator: { type: 'string', displayName: '<<lastModifiedUser>>' },
        remark: { type: 'string', displayName: '<<remark>>' },
    },
    filters: {
        transAction: {
            type: 'string.options.select',
            displayName: '<<categoryName>>',
            options: [],
            source: { apiPath: '/loader/admin/gold_accounts/trans_action_type' },
            controlConfig: { style: { width: 300 } },
        },
        dateRange: {
            type: 'object.dateRangePlus',
            displayName: '<<fssj>>',
            controlConfig: { style: { width: 300 } },
        },
    },
};
// 3. 定义组件
config.components &&
    Object.assign(config.components, {
        MemberGoldDetailPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'MemberGoldDetailEntity',
            items: [
                { component: 'MemberGoldDetailEdit' },
                { component: 'MemberGoldDetailFilter' },
                { component: 'MemberGoldDetailTable' },
            ],
        },
        MemberGoldDetailFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: { text: '<<common.search>>' },
            reset: { text: '<<common.reset>>' },
            style: { marginBottom: 16 },
            fields: [{ property: 'transAction' }, { property: 'dateRange' }],
        },
        MemberGoldDetailTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                { property: 'transTime' },
                { property: 'subsiteName' },
                { property: 'transActionRemark' },
                { property: 'transAmount' },
                { property: 'associateOrder' },
                { property: 'operator' },
                { property: 'remark', width: 300 },
            ],
        },
    });

/**
 * 支付授权
 */

// 定义菜单
config.components?.MemberInfoView?.items[0].items.push({
    title: '<<zfsq>>',
    content: { component: 'PaymentAuthorizationPage' },
});
// 定义组件
config.components &&
    Object.assign(config.components, {
        PaymentAuthorizationPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'PaymentAuthorizationDetail' }],
        },
    });
