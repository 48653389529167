import React, { Component } from 'react';
import { forEach, isEqual, remove, cloneDeep } from 'lodash';
import { Modal, Button, Input, message, List, Descriptions, InputNumber } from 'antd';
import {
    PickingListsOrders,
    ORDER_STATUS_ENUM,
    PickingListsOrderItems,
    ProductType,
} from '../../../services/order-print';
import './index.less';
import { services } from '@comall-backend-builder/core';

interface ScanGoodsProps {
    /**
     * 订单列表数据
     */
    pickingListOrders: PickingListsOrders[];
    /**
     * 修改订单内容函数
     */
    onSubmit: (pickingListOrders: PickingListsOrders[]) => void;
    handleQuantityAndPriceDifference: (
        item: PickingListsOrderItems,
        order: PickingListsOrders,
        actualPickQuantity: number
    ) => Promise<PickingListsOrderItems>;
    status: ORDER_STATUS_ENUM;
}

/**
 * 拣货货品
 */
type PickingGoods = PickingListsOrderItems & {
    /**
     * 本次待拣货总数量
     */
    totalMissingQuantity: number;
    /**
     * 本次拣货总数量
     */
    totalPickQuantity: number;
    /**
     * 本次待拣货每个订单的信息
     */
    orderInfos: { orderId: string; id: string; missingQuantity: number; pickQuantity: number }[];
};

interface ScanGoodsStates {
    /**
     * 待拣货商品集合
     */
    pickingGoods: PickingGoods[];
    /**
     * 扫码的商品集合
     */
    basket: PickingGoods[];
    /**
     * 弹窗展示隐藏
     */
    visible: boolean;
    /**
     * 扫描的条形码
     */
    barCode?: string;
}

export class ScanGoods extends Component<ScanGoodsProps, ScanGoodsStates> {
    constructor(props: ScanGoodsProps) {
        super(props);
        this.state = {
            pickingGoods: [],
            basket: [],
            visible: false,
            barCode: '',
        };
    }
    componentDidMount() {
        const { pickingListOrders } = this.props;
        if (pickingListOrders) {
            this.ordersToPickingGoods(pickingListOrders);
        }
    }
    componentWillReceiveProps(props: ScanGoodsProps) {
        const { pickingListOrders: nextOrders } = props;
        const { pickingListOrders: prevOrders } = this.props;
        if (nextOrders && !isEqual(nextOrders, prevOrders)) {
            this.ordersToPickingGoods(nextOrders);
        }
    }

    /**
     * 处理订单信息为待拣货货品列表
     */
    ordersToPickingGoods = (orders: PickingListsOrders[]) => {
        const pickingGoods: PickingGoods[] = [];
        forEach(orders, (order) => {
            forEach(order.orderItems, (item) => {
                const originItem = pickingGoods.find((i) => i.goods.barCode === item.goods.barCode);
                const missingQuantity = item.needPickQuantity - (item.actualPickQuantity || 0);
                if (originItem) {
                    originItem.totalMissingQuantity += missingQuantity;
                    originItem.orderInfos.push({
                        orderId: order.id,
                        id: item.id,
                        missingQuantity,
                        pickQuantity: 0,
                    });
                    return;
                }
                pickingGoods.push({
                    ...item,
                    totalMissingQuantity: missingQuantity,
                    totalPickQuantity: 0,
                    orderInfos: [
                        { orderId: order.id, id: item.id, missingQuantity, pickQuantity: 0 },
                    ],
                });
            });
        });
        this.setState({ pickingGoods });
    };

    /**
     * 变更待拣货商品拣货数量
     */
    changePickQuantity = (basketItem: PickingGoods, quantity: number) => {
        basketItem.totalPickQuantity = quantity;
        let num = quantity;
        forEach(basketItem.orderInfos, (item) => {
            if (!num) return;
            item.pickQuantity = num;
            if (item.pickQuantity > item.missingQuantity) {
                num = item.pickQuantity - item.missingQuantity;
                item.pickQuantity = item.missingQuantity;
            } else {
                num = 0;
            }
        });
        return basketItem;
    };

    /**
     * 找到货品并添加货品到扫码商品集合
     * @returns 订单中是否存在该货品
     */
    findGoods = () => {
        const { barCode, pickingGoods, basket } = this.state;
        if (!barCode || !barCode.length) {
            message.warn('请输入货品条码');
            return false;
        }
        const basketItem = basket.find((i) => i.goods.barCode === barCode);
        if (basketItem) {
            if (basketItem.totalPickQuantity >= basketItem.totalMissingQuantity) {
                message.warn('此商品已达本单最大可拣货数量。条形码：' + barCode);
                return true;
            }
            this.changePickQuantity(basketItem, basketItem.totalPickQuantity + 1);
            return true;
        }
        const orderItem = pickingGoods.find((i) => i.goods.barCode === barCode);
        if (orderItem) {
            if (orderItem.totalMissingQuantity <= 0) {
                message.warn('此商品已达本单最大可拣货数量。条形码：' + barCode);
                return true;
            }
            if (orderItem.goods.productType === ProductType.WEIGHT) {
                message.warn('暂不支持扫码拣货称重品');
                return true;
            }
            this.setState({ basket: [...basket, this.changePickQuantity(orderItem, 1)] });
            return true;
        }
        message.warn('该拣货单内无此商品');
        return false;
    };

    get showButton() {
        const { status } = this.props;
        return status === ORDER_STATUS_ENUM.PICKING;
    }

    onBarCodeChange = (e: any) => {
        const barCode = e.target.value;
        if (barCode && barCode.length > 12) {
            this.setState({ barCode }, this.addGoodsToBasket);
        } else {
            this.setState({ barCode });
        }
    };

    addGoodsToBasket = () => {
        if (this.findGoods()) {
            this.setState({ barCode: '' });
        }
    };

    onInputNumber = (item: PickingGoods, value: number | undefined) => {
        if (!value) {
            return;
        }
        const { basket } = this.state;
        const newBasket = cloneDeep(basket);
        const updateItem = newBasket.find((i) => i.goods.barCode === item.goods.barCode);
        if (updateItem) {
            this.changePickQuantity(updateItem, value);
            this.setState({ basket: newBasket });
            return value;
        }
    };

    onDelete = (item: PickingGoods) => {
        const { basket } = this.state;
        const newBasket = [...basket];
        remove(newBasket, (i) => i.goods.barCode === item.goods.barCode);
        this.setState({ basket: newBasket });
    };
    clear = () => {
        this.setState({ basket: [] });
    };
    onConfirm = async () => {
        const { basket } = this.state;
        const { pickingListOrders, onSubmit, handleQuantityAndPriceDifference } = this.props;
        const orders = cloneDeep(pickingListOrders);
        const handleItem = async (orderId: string, id: string, pickQuantity: number) => {
            const order = orders.find((o) => o.id === orderId);
            if (!order) {
                return;
            }
            const orderItem = order.orderItems.find((i) => i.id === id);
            if (!orderItem) {
                return;
            }
            orderItem.isPicking = true;
            await handleQuantityAndPriceDifference(
                orderItem,
                order,
                (orderItem.actualPickQuantity || 0) + pickQuantity
            );
        };
        const promiseList: Array<Promise<void>> = [];
        forEach(basket, (item) => {
            const { orderInfos } = item;
            forEach(orderInfos, ({ orderId, id, pickQuantity }) => {
                promiseList.push(handleItem(orderId, id, pickQuantity));
            });
        });
        await Promise.all(promiseList);
        onSubmit(orders);
        this.toggleModal();
    };

    toggleModal = async () => {
        const { visible, basket } = this.state;
        if (visible && basket.length) {
            this.clear();
        }
        this.setState({ visible: !visible });
    };

    renderModalInput = () => {
        const { visible, barCode } = this.state;
        if (!visible) {
            return null;
        }
        return (
            <div className="scan-goods-modal-top">
                <span>{services.language.getText('txm')}：</span>
                <Input
                    className="modal-input"
                    autoFocus
                    value={barCode}
                    onChange={this.onBarCodeChange}
                    allowClear
                />
                <Button type="primary" onClick={this.addGoodsToBasket}>
                    确认
                </Button>
            </div>
        );
    };

    renderBasket = () => {
        const { basket } = this.state;
        return (
            <List itemLayout="horizontal" dataSource={basket} renderItem={this.renderBasketItem} />
        );
    };

    renderBasketItem = (item: PickingGoods) => {
        return (
            <List.Item>
                <Descriptions layout="horizontal" column={1}>
                    <Descriptions.Item label="商品名称">{item.goods.name}</Descriptions.Item>
                    {!!item.goods.styleName && (
                        <Descriptions.Item label="规格">{item.goods.styleName}</Descriptions.Item>
                    )}
                    <Descriptions.Item label="条形码">{item.goods.barCode}</Descriptions.Item>
                </Descriptions>
                <div className="scan-goods-controller">
                    <Button
                        onClick={this.onDelete.bind(this, item)}
                        className="item-delete"
                        type="primary"
                        size="small"
                    >
                        {services.language.getText('common.delete')}
                    </Button>
                    <div className="item-quantity">
                        <span>{services.language.getText('yjsl')}：</span>
                        <InputNumber
                            precision={0}
                            className="item-quantity-input"
                            min={1}
                            max={item.totalMissingQuantity}
                            value={item.totalPickQuantity}
                            onChange={(value) => {
                                this.onInputNumber(item, value);
                            }}
                        />
                    </div>
                </div>
            </List.Item>
        );
    };

    renderModalFooter = () => {
        return (
            <div>
                <Button onClick={this.clear}>清空</Button>
                <Button type="primary" onClick={this.onConfirm}>
                    保存
                </Button>
            </div>
        );
    };

    render() {
        const { visible } = this.state;
        if (!this.showButton) {
            return null;
        }
        return (
            <div className="scan-goods">
                <Button type="primary" className="scan-goods-button" onClick={this.toggleModal}>
                    扫码核对标品
                </Button>
                <Modal
                    width={720}
                    onCancel={this.toggleModal}
                    visible={visible}
                    title="请扫描商品条形码"
                    footer={this.renderModalFooter()}
                    maskClosable={false}
                >
                    {this.renderModalInput()}
                    {this.renderBasket()}
                </Modal>
            </div>
        );
    }
}
