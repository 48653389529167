import React, { Component } from 'react';
import { InputNumber } from 'antd';
import { services } from '@comall-backend-builder/core';

interface AmountRangeValue {
    start: string;
    end: string;
}
interface AmountRangeProps {
    value: AmountRangeValue | null;
    disabled: boolean;
    onChange: (value: AmountRangeValue | null) => void;
}

export class AmountRange extends Component<AmountRangeProps, {}> {
    onChange = (inputValue: string, fields: keyof AmountRangeValue) => {
        let { value, onChange } = this.props;
        if (!value) {
            value = {
                start: '',
                end: '',
            };
        }
        value[fields] = inputValue;
        if (value.start && value.end && value.start >= value.end) {
            value.end = '';
        }
        onChange(value);
    };
    render() {
        const { value } = this.props;
        return (
            <div>
                <InputNumber
                    value={Number(value?.start) || undefined}
                    min={0.01}
                    max={999999999.99}
                    precision={2}
                    onBlur={(num) => this.onChange(num.target.value + '', 'start')}
                    placeholder={services.language.getText('common.pleaseInput')}
                />
                <span>&nbsp;~&nbsp;</span>
                <InputNumber
                    value={Number(value?.end) || undefined}
                    min={0.01}
                    max={999999999.99}
                    precision={2}
                    onBlur={(num) => this.onChange(num.target.value + '', 'end')}
                    placeholder={services.language.getText('common.pleaseInput')}
                />
            </div>
        );
    }
}
