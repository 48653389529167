import { PureComponent, createElement } from 'react';
import { ComponentsManager } from '@comall-backend-builder/core';

export class MagicActivityAfterSaleButton extends PureComponent<any, any> {
    render() {
        const { row, link } = this.props;
        const { id, canRefund } = row;
        let path = `/mall-activity/apply/${id}`;
        if (!!link) {
            path = `${link}/${id}`;
        }
        return createElement(ComponentsManager.get('Button'), {
            text: '售后',
            type: 'link',
            disabled: !canRefund,
            route: path,
            style: {
                marginLeft: 5,
            },
        });
    }
}
