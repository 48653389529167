import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;
export const CGBManageLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiPath = '/admin/activities';
        const { id } = data;
        if (!id) {
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        } else {
            config.apiPath = `/admin/activities/${id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.baseInfo = {
                    name: res.name,
                    status: res.status,
                    dateRange: {
                        start: res.startTime,
                        end: res.endTime,
                    },
                    executeStatus: res.executeStatus,
                };
                res.rulesInfo = { rules: res.rules };
                res.goodsInfo = { goods: res.goods };
                res.products = { goods: res.goods };
                res.advertisement = {
                    activityPicture: [
                        {
                            id: res.imgId,
                            path: res.imgUrl || '',
                        },
                    ],
                };
            } else {
                res.result = res.result.map((item: any) => {
                    item.baseInfo = {
                        name: item.name,
                        status: item.status,
                        dateRange: {
                            start: item.startTime,
                            end: item.endTime,
                        },
                    };
                    item.ruleInfo = item.rules;
                    item.goodsInfo = item.goods;
                    item.products = { goods: item.goods };
                    let nowTime = new Date().getTime();
                    let startTime = new Date(item.startTime).getTime();
                    item.canEdit = !(nowTime > startTime && item.executeStatus === 'COMPLETE');
                    return item;
                });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        let newParams: any = {};
        if (params.baseInfo) {
            newParams.name = params.baseInfo.name;
            newParams.status = params.baseInfo.status;
            newParams.startTime = params.baseInfo.dateRange.start;
            newParams.endTime = params.baseInfo.dateRange.end;
        }
        if (params.rulesInfo) {
            newParams.rules = params.rulesInfo.rules;
        }
        if (params.products) {
            newParams.goods = params.products.goods;
        }
        if (params.advertisement) {
            newParams.img_id = params.advertisement.activityPicture[0].id;
            newParams.img_url = params.advertisement.activityPicture[0].path;
        }
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        let newParams: any = {};
        if (params.baseInfo) {
            newParams.id = params.id;
            newParams.name = params.baseInfo.name;
            newParams.status = params.baseInfo.status;
            newParams.startTime = params.baseInfo.dateRange.start;
            newParams.endTime = params.baseInfo.dateRange.end;
        }
        if (params.rulesInfo) {
            newParams.rules = params.rulesInfo.rules;
        }
        if (params.products) {
            newParams.goods = params.products.goods;
        }
        if (params.advertisement) {
            newParams.img_id = params.advertisement.activityPicture[0].id;
            newParams.img_url = params.advertisement.activityPicture[0].path;
        }
        return await api.put(newParams, config);
    },
};
