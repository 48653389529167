import React, { useState } from 'react';
import { Button } from 'antd';
import { services } from '@comall-backend-builder/core';

import { SelectVirtualGood, SelectVirtualGoodValue } from './select-virtual-good';

export interface CustomSelectVirtualGoodValue {
    linkType: string;
    linkParams?: SelectVirtualGoodValue;
}

export interface CustomSelectVirtualGoodProps {
    value?: CustomSelectVirtualGoodValue;
    contentConfig?: Record<string, any>;
    modalConfig?: Record<string, any>;
    onChange?(value: SelectVirtualGoodValue): void;
}

const language = services.language;

const DEFAULT_VALUE: CustomSelectVirtualGoodValue = {
    linkType: '',
    linkParams: {
        id: 0,
        goodsId: 0,
        name: '',
        subsiteId: '',
    },
};

export const CustomSelectVirtualGood: React.FC<CustomSelectVirtualGoodProps> = (props) => {
    const { value = DEFAULT_VALUE, contentConfig, modalConfig, onChange } = props;
    const [visibleModal, setVisibleModal] = useState(false);

    const linkName = value.linkParams ? value.linkParams.name : '';

    function visibleModalChange(): void {
        setVisibleModal((value) => !value);
    }

    function handleChange(linkParams: SelectVirtualGoodValue): void {
        visibleModalChange();

        onChange && onChange(linkParams);
    }

    function handleShowModal(): void {
        visibleModalChange();
    }

    function handleCancel(): void {
        visibleModalChange();
    }

    return (
        <span className="link-name">
            {linkName ? <span className="link-name-label">{linkName}</span> : '未选择'}
            <Button type="link" className="link-type-edit" onClick={handleShowModal}>
                {linkName
                    ? language.getText('components.Tools.modify')
                    : language.getText('components.Tools.select')}
            </Button>
            <SelectVirtualGood
                visible={visibleModal}
                value={value.linkParams}
                contentConfig={contentConfig}
                modalConfig={modalConfig}
                onChange={handleChange}
                onCancel={handleCancel}
            />
        </span>
    );
};
