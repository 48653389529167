import React, { Component } from 'react';
import { Modal, Input, Button } from 'antd';

import './index.less';
import { services } from '@comall-backend-builder/core';
const { TextArea } = Input;

interface DistributionModalProps {
    visible: boolean;
    onAudit: (val: any) => void;
    onCancel: () => void;
}

interface DistributionModalState {
    reason: string;
}

export class DistributionModal extends Component<DistributionModalProps, DistributionModalState> {
    constructor(props: any) {
        super(props);
        this.state = {
            reason: '',
        };
    }

    onReject = () => {
        const { reason } = this.state;

        this.props.onAudit({ result: false, reason });
    };

    onCancel = () => {
        this.props.onCancel();
    };

    onOk = () => {
        const { reason } = this.state;

        this.props.onAudit({ result: true, reason });
    };

    onInputChange = (e: any) => {
        const { value } = e.target;
        this.setState({
            reason: value,
        });
    };

    renderFooter = () => {
        return (
            <div>
                <Button onClick={this.onReject}>{services.language.getText('shbtg')}</Button>
                &nbsp;&nbsp;&nbsp;&nbsp;{' '}
                <Button type="primary" onClick={this.onOk}>
                    审核通过
                </Button>
            </div>
        );
    };

    render() {
        const { visible } = this.props;
        return (
            <Modal
                title={services.language.getText('audity')}
                width={800}
                visible={visible}
                className="role-selector-modal"
                onCancel={this.onCancel}
                footer={this.renderFooter()}
            >
                <div className="audit-guide-user-form">
                    <div className="audit-guide-user-form-item">
                        <div className="audit-guide-user-form-label">原因:</div>{' '}
                        <TextArea
                            onChange={this.onInputChange}
                            className="audit-guide-user-form-input"
                            rows={4}
                            placeholder={services.language.getText('inputPlease')}
                        ></TextArea>
                    </div>
                </div>
            </Modal>
        );
    }
}
