import { EntityButtonProps } from '../../../../components/entity-button/index';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { orderStatusOptions, orderTypeOptions } from './types';
import { services } from '@comall-backend-builder/core';
import { isEmpty } from 'lodash';
import { ForwardDataCenterModal } from '../../../../services';
const api = services.api;

export const config: Config = {
    entities: {
        CouponPackageSaleEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/coupon_reports/coupon_package',
            filters: {
                subsiteCode: {
                    type: 'string',
                    displayName: '<<subsiteCode>>',
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<xdsj>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<ddzt>>',
                    defaultValue: 'ALL',
                    options: orderStatusOptions,
                },
                couponName: {
                    type: 'string.popover',
                    displayName: '<<couponName>>',
                },
                couponPackageName: {
                    type: 'string.popover',
                    displayName: '<<couponGiftName>>',
                },
                orderType: {
                    type: 'string.options.select',
                    displayName: '<<ddlx>>',
                    defaultValue: 'ALL',
                    options: orderTypeOptions,
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
            },
            properties: {
                id: { type: 'string' },
                subsiteCode: {
                    type: 'string',
                    displayName: '<<subsiteCode>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                tradeNo: {
                    type: 'string',
                    displayName: '<<zfjylsh>>',
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<ddzt>>',
                },
                orderCreateTime: {
                    type: 'string',
                    displayName: '<<xdsj>>',
                },
                couponPackageName: {
                    type: 'string',
                    displayName: '<<yhqbmc>>',
                },
                couponName: {
                    type: 'string',
                    displayName: '<<couponName>>',
                },
                quantity: {
                    type: 'string',
                    displayName: '<<couponQuantity>>',
                },
                pointDealPrice: {
                    type: 'string',
                    displayName: '<<ddzfjf>>',
                },
                dealPrice: {
                    type: 'string',
                    displayName: '<<orderTotalAmount>>',
                },
                orderType: {
                    type: 'string.options.select',
                    displayName: '<<ddlx>>',
                    options: orderTypeOptions,
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
            },
        },
        CouponPackageSaleOrderInfoEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/couponOrder',
            filters: {
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                subsites: {
                    type: 'string.options.select',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                orderStatus: {
                    type: 'string.options.select',
                    displayName: '<<ddzt>>',
                    defaultValue: 'ALL',
                    options: orderStatusOptions,
                },
                orderType: {
                    type: 'string.options.select',
                    displayName: '<<ddlx>>',
                    options: orderTypeOptions,
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<ksjssj>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                goodsName: {
                    type: 'string',
                    displayName: '<<goodName>>',
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                goodsName: {
                    type: 'string',
                    displayName: '<<goodName>>',
                },
                quantity: {
                    type: 'string',
                    displayName: '<<number>>',
                },
                orderStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<ddzt>>',
                },
                orderCreateTime: {
                    type: 'string',
                    displayName: '<<xdsj>>',
                },
                orderAmount: {
                    type: 'string',
                    displayName: '<<xjzje>>',
                },
                pointAmount: {
                    type: 'string',
                    displayName: '<<jfze>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                orderType: {
                    type: 'string.options.select',
                    displayName: '<<ddlx>>',
                    options: orderTypeOptions,
                },
            },
        },
    },
    components: {
        CouponPackageSaleView: {
            component: 'Viewport',
            entity: 'CouponPackageSaleEntity',
        },
        CouponPackageSalePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'CouponPackageSaleFilter',
                },
                { component: 'CouponPackageSaleTable' },
            ],
        },
        CouponPackageSaleFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteCode',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<codeRequired>>',
                    },
                },
                {
                    property: 'subsiteId',
                    controlConfig: {
                        style: { width: 240 },
                    },
                },
                {
                    property: 'orderNo',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<inputOrderNumber>>',
                    },
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: ['<<startTime>>', '<<endTime>>'],
                    },
                },
                {
                    property: 'status',
                    controlConfig: {
                        style: { width: 240 },
                    },
                },
                {
                    property: 'couponName',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<qsryhqmc>>',
                    },
                },
                {
                    property: 'couponPackageName',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<qsryhqlbmc>>',
                    },
                },
                {
                    property: 'orderType',
                    controlConfig: {
                        style: { width: 240 },
                    },
                },
                {
                    property: 'mobile',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<qsrhysjh>>',
                    },
                },
            ],
        },
        CouponPackageSaleTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'EntityButton',
                    props: {
                        type: 'primary',
                        children: '<<exportSearchResult>>',
                        style: {
                            width: 110,
                            marginBottom: 24,
                            marginTop: 17,
                        },
                        onClick: (_: any, entityButtonProps: EntityButtonProps) => {
                            const params: any = Object.assign(
                                {},
                                entityButtonProps.entity.params,
                                entityButtonProps.entity.filters,
                                {
                                    exchangeStatus: entityButtonProps.entity.filters
                                        ? (entityButtonProps.entity.filters as any).redeemStatus
                                        : undefined,
                                    redeemStatus: undefined,
                                }
                            );
                            if (!isEmpty(params.dateRange)) {
                                params.startTime = params.dateRange.start + ' 00:00:00';
                                params.endTime = params.dateRange.end + ' 23:59:59';
                                delete params.dateRange;
                            }
                            if (params.status === 'ALL') {
                                delete params.status;
                            }
                            if (params.orderType === 'ALL') {
                                delete params.orderType;
                            }

                            const config = {
                                apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                apiPath: '/admin/coupon_reports/coupon_package/download',
                            };

                            api.get(params, config)
                                .then(() => {
                                    ForwardDataCenterModal();
                                })
                                .catch((error) => {
                                    services.errorHandle(error);
                                });
                        },
                    },
                },
                {
                    component: 'DataTable',
                    scroll: {
                        x: true,
                    },
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'subsiteCode',
                        },
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'orderNo',
                        },
                        {
                            property: 'tradeNo',
                        },
                        {
                            property: 'status',
                            displayConfig: {
                                statusConfig: {
                                    CREATE: {
                                        text: '<<dfk>>',
                                        status: 'warning',
                                    },
                                    PAYMENT: {
                                        text: '<<dsy>>',
                                        status: 'processing',
                                    },
                                    RECEIVE: {
                                        text: '<<ywc>>',
                                        status: 'success',
                                    },
                                    CANCEL: {
                                        text: '<<yqx>>',
                                        status: 'error',
                                    },
                                    CLOSE: {
                                        text: '<<beforeClose>>',
                                        status: 'error',
                                    },
                                },
                            },
                        },
                        {
                            property: 'orderCreateTime',
                            width: 200,
                        },
                        {
                            property: 'couponPackageName',
                            width: 180,
                        },
                        {
                            property: 'couponName',
                            width: 180,
                        },
                        {
                            property: 'quantity',
                        },
                        {
                            property: 'pointDealPrice',
                        },
                        {
                            property: 'dealPrice',
                        },
                        {
                            property: 'orderType',
                            width: 150,
                        },
                        {
                            property: 'mobile',
                            width: 150,
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        width: 100,
                        fixed: 'right',
                        items: [
                            {
                                type: 'link',
                                text: '<<details>>',
                                path:
                                    '/coupon-package-sale-report/info/{{row.orderId}}/{{row.orderType}}',
                            },
                        ],
                    },
                },
            ],
        },
        CouponPackageSaleOrderInfoPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'CouponPackageSaleOrderInfoEntity',
            loaderType: 'get',
            items: [
                {
                    component: 'CouponOrderInfo',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/coupon-package-sale-report',
                    component: 'CouponPackageSaleView',
                    breadcrumbName: '<<couponPackageOrderReport>>',
                    privilege: {
                        path: 'couponPackageOrderReport',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/info/:id/:orderType',
                            component: 'CouponPackageSaleOrderInfoPage',
                            breadcrumbName: '<<yhqddxq>>',
                        },
                        { path: '/', component: 'CouponPackageSalePage' },
                    ],
                },
            ],
        },
    ],
};
