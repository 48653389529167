import { builder } from '@comall-backend-builder/core';
import { couponSaleLoader } from './coupon-sales-loader';
import { couponBalanceSaleLoader } from './coupon-balance-sales-loader';
import { couponPackageSaleLoader } from './coupon-package-sales-loader';
import { couponRefundLoader } from './coupon-refund-loader';
import { couponConsumeLoader } from './coupon-consume-loader';
import { merchantBillLoader } from './merchant-bill';
import { couponMerchantLoader } from './coupon-merchant-loader';
import { couponActivityLoader } from './coupon-activity-loader';
import { pointExchangeRecordLoader } from './point-exchange-record-loader';
import { DeliveryLogisticsBillReportLoader } from './delivery-logistics-bill-report-loader';
import { MarketingScheduleManagementLoader } from './marketing-schedule-management-loader';

builder.registerLoader('/admin/coupon_reports/coupon', couponSaleLoader);
builder.registerLoader('/admin/coupon_reports/coupon_balance', couponBalanceSaleLoader);
builder.registerLoader('/admin/coupon_reports/coupon_package', couponPackageSaleLoader);
builder.registerLoader('/admin/coupon_reports/refund_report', couponRefundLoader);
builder.registerLoader('/admin/coupon_reports/coupon_consume', couponConsumeLoader);
builder.registerLoader('/admin/merchant_bill', merchantBillLoader);
builder.registerLoader('/admin/coupon_reports/coupon_merchant', couponMerchantLoader);
builder.registerLoader('/admin/coupon_reports/coupon_activity', couponActivityLoader);
builder.registerLoader('/admin/point_exchange_record', pointExchangeRecordLoader);
builder.registerLoader('/admin/delivery-logistics-bill-report', DeliveryLogisticsBillReportLoader);
builder.registerLoader('/admin/marketing_schedule_management', MarketingScheduleManagementLoader);
