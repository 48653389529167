import React, { Component } from 'react';
import { Checkbox, Select } from 'antd';
import { api, language } from '@comall-backend-builder/core/lib/services';
import { get } from 'lodash';
import { getSupportMemberMarking } from '../../../../services';
import { services } from '@comall-backend-builder/core';

export interface ObjectMemberMarkingContentValue {
    check: boolean;
    label: string;
}
export interface ObjectMemberMarkingContentState {
    labelOptions: Array<any>;
    checkBoxDisabled: boolean;
}
const defaultValue: ObjectMemberMarkingContentValue = {
    check: false,
    label: '',
};

export class ObjectMemberMarkingContent extends Component<any, ObjectMemberMarkingContentState> {
    constructor(props: any) {
        super(props);
        this.state = {
            labelOptions: [],
            checkBoxDisabled: false,
        };
    }
    componentDidMount() {
        this.getSubsitesLabels();
        this.changeDefault();
    }
    supportMemberMarking = getSupportMemberMarking();
    componentWillReceiveProps(nextProps: any) {
        if (nextProps && nextProps.row && nextProps.row.baseInfo) {
            const currentSubsite = get(this.props, 'row.baseInfo.merchants', []);
            const nextSubsite = get(nextProps, 'row.baseInfo.merchants', []);
            const currentSubsiteIds: any =
                currentSubsite && currentSubsite.length ? currentSubsite[0].subSiteId : '';
            const nextSubsiteIds: any =
                nextSubsite && nextSubsite.length ? nextSubsite[0].subSiteId : '';
            if (currentSubsiteIds !== nextSubsiteIds) {
                this.getSubsitesLabels(nextProps);
            }
        }
    }

    getSubsitesLabels = (props?: any) => {
        const { value } = this.props;
        const valueKey = props ? props : this.props;
        const subsites = get(valueKey, 'row.baseInfo.merchants', []) || [];
        const id = subsites && subsites.length ? subsites[0].subSiteId : '';
        if (!id) {
            this.setState({ checkBoxDisabled: true });
            return;
        }
        this.setState({ checkBoxDisabled: false });
        api.get(
            {
                subsiteIds: id,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                apiPath: `/admin/tags/subsite_tag`,
            }
        ).then((response: any) => {
            let options: any = [];
            response.forEach((item: any) => {
                options = options.concat(item.tagInfo);
            });
            this.setState(
                {
                    labelOptions: options,
                },
                () => {
                    if (value && value.label) {
                        const selectRow = options.filter((item: any) => item.id === value.label);
                        if (!selectRow.length) {
                            this.onSelectValueChange('');
                        }
                    }
                }
            );
        });
    };

    changeDefault = () => {
        if (!this.supportMemberMarking) {
            const { onChange, name } = this.props;
            onChange(
                {
                    ...defaultValue,
                },
                name
            );
        }
    };

    onChange = (event: any) => {
        const { name, onChange, value } = this.props;
        const currentValue = value || defaultValue;
        onChange(
            {
                ...currentValue,
                check: event.target.checked,
            },
            name
        );
    };

    onSelectValueChange = (event: any) => {
        const { name, onChange, value } = this.props;
        const currentValue = value || defaultValue;
        onChange(
            {
                ...currentValue,
                label: event,
            },
            name
        );
    };

    render() {
        const { value } = this.props;
        const { labelOptions, checkBoxDisabled } = this.state;
        const checked = value && value.check;
        const label = value && value.label ? value.label : undefined;
        const disabled = !this.supportMemberMarking || checkBoxDisabled;
        return (
            <div>
                <Checkbox onChange={this.onChange} disabled={disabled} checked={checked}>
                    {language.getText('jcyghddhydbq')}
                </Checkbox>
                {!checkBoxDisabled && checked ? (
                    <div>
                        <div className="ant-form-extra">{language.getText('jdzhcyghddhysx')}</div>
                        <div>
                            <span>{language.getText('bq')}：</span>
                            <Select
                                value={label}
                                disabled={disabled}
                                style={{ width: '140px', marginTop: 10 }}
                                placeholder={services.language.getText('selectPlease')}
                                onChange={(e: any) => this.onSelectValueChange(e)}
                            >
                                {labelOptions &&
                                    labelOptions.map((option: any) => (
                                        <Select.Option key={option.id} value={option.id}>
                                            {option.tagName}
                                        </Select.Option>
                                    ))}
                            </Select>
                        </div>
                    </div>
                ) : null}
                {disabled && (
                    <div className="ant-form-extra">
                        {checkBoxDisabled
                            ? language.getText('selectBeforeMerchant')
                            : services.language.getText('contactManager')}
                    </div>
                )}
            </div>
        );
    }
}
