import React, { PureComponent } from 'react';
import { Button as AntButton, message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { ForwardDataCenterModal } from '../../services';

const api = services.api;

/**
 * 导出数据
 */
export class ExportHandlerButton extends PureComponent<any, any> {
    onClick = () => {
        const { params, row } = this.props;
        let { apiRoot, apiPath } = params;
        apiPath = apiPath.replace('{{row.id}}', row.id);
        api.post({}, { apiRoot, apiPath })
            .then((res) => {
                ForwardDataCenterModal();
            })
            .catch((err) => {
                console.log('err');
                AntMessage.error(err.response.body.err_msg);
                throw err;
            });
    };
    render() {
        const { row, params } = this.props;

        if (row.canExport === undefined) {
            row.canExport = true;
        }

        return row.canExport ? (
            <AntButton
                type="link"
                style={{ marginRight: '10px', padding: 0 }}
                className="span-link"
                onClick={this.onClick}
            >
                {params.text ? params.text : services.language.getText('dcsj_1')}
            </AntButton>
        ) : (
            ''
        );
    }
}
