import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { Button, Modal, Radio, message as AntMessage } from 'antd';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
const api = services.api;

interface States {
    visible: boolean;
    isOpen: boolean;
}
export class MemberCardDiscountCombinePromotionsButton extends PureComponent<any, States> {
    state = {
        visible: false,
        isOpen: false,
    };

    onSetting = async () => {
        const res: any[] = await api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
                apiPath: '/admin/member_card_discount_combine_promotions',
            }
        );
        if (res && res.length > 0) {
            this.setState({
                isOpen: true,
            });
        }
        this.setState({
            visible: true,
        });
    };

    onSubmit = () => {
        const { isOpen } = this.state;
        const params = isOpen
            ? [
                  {
                      promotionType: 'SINGLE_PROMOTION',
                  },
                  {
                      promotionType: 'MULTI_PROMOTION',
                  },
              ]
            : [];
        api.post(params, {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
            apiPath: '/admin/member_card_discount_combine_promotions',
        }).then(
            () => {
                AntMessage.success(services.language.getText('settingSucess'));
                this.onCancel();
            },
            (error) => {
                services.errorHandle(error);
            }
        );
    };

    onCancel = () => {
        this.setState({
            visible: false,
        });
    };

    onChangeValue = (value: boolean) => {
        this.setState({
            isOpen: value,
        });
    };

    renderModal = () => {
        const { visible, isOpen } = this.state;
        return (
            <Modal
                destroyOnClose
                visible={visible}
                title={language.getText('yhdjsz')}
                width="630px"
                onCancel={this.onCancel}
                onOk={this.onSubmit}
                className="member-card-discount-combine-promotions-button-modal"
            >
                <div className="item">
                    <div className="item-title">{language.getText('yhdjgz')}：</div>
                    <div className="item-content">
                        <Radio.Group
                            value={isOpen}
                            onChange={(value: any) => {
                                this.onChangeValue(value.target.value);
                            }}
                        >
                            <Radio value={true}>{language.getText('kdjd')}</Radio>
                            <div className="item-tip">{language.getText('zxhyzkh')}</div>
                            <Radio value={false}>{language.getText('dcxhc')}</Radio>
                            <div className="item-tip">{services.language.getText('dpcxgz')}</div>
                        </Radio.Group>
                    </div>
                </div>
            </Modal>
        );
    };

    render() {
        return (
            <div className={prefix} style={{ display: 'inline-block' }}>
                <Button
                    style={{ margin: '16px 0 24px 10px' }}
                    onClick={this.onSetting}
                    type="primary"
                    icon="setting"
                >
                    {language.getText('yhdjsz')}
                </Button>
                {this.renderModal()}
            </div>
        );
    }
}

const prefix = 'member-card-discount-level-styles';
