import React, { Component } from 'react';
import { Table, InputNumber } from 'antd';
import { cloneDeep } from 'lodash';
import { RewardInfos } from './member-card-level-point-reward';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

interface MemberCardLevelPointRewardTableProps {
    onChange: (value: any) => void;
    data: RewardInfos[] | undefined;
    loading?: boolean;
    enableExperienceValue?: boolean;
    rewardValueTitle?: string;
}

export default class MemberCardLevelPointRewardTable extends Component<
    MemberCardLevelPointRewardTableProps,
    {}
> {
    changeValue = (
        record: RewardInfos,
        value: number,
        field: 'rewardValue' | 'experienceValue'
    ) => {
        const { data, onChange } = this.props;
        if (!data) return;
        const newData = cloneDeep(data);
        newData.forEach((r) => {
            if (r.cardLevelId === record.cardLevelId) {
                r[field] = value;
            }
        });
        onChange && onChange(newData);
    };

    getTableProps = () => {
        const {
            data,
            loading = false,
            enableExperienceValue = false,
            rewardValueTitle = language.getText('jffb'),
        } = this.props;
        const experienceColumn = enableExperienceValue
            ? {
                  title: language.getText('jyzfb'),
                  dataIndex: 'experienceValue',
                  key: 'experienceValue',
                  align: 'center' as const,
                  render: (experienceValue: any, record: any) => {
                      return (
                          <div style={{ alignItems: 'center' }}>
                              <InputNumber
                                  placeholder={services.language.getText('inputPlease')}
                                  value={experienceValue}
                                  min={1.01}
                                  precision={2}
                                  max={999999999}
                                  onChange={(value: any) =>
                                      this.changeValue(record, value, 'experienceValue')
                                  }
                              ></InputNumber>
                              &nbsp;&nbsp;{language.getText('jmultiple')}
                          </div>
                      );
                  },
              }
            : {};
        return {
            dataSource: data,
            pagination: false as const,
            loading,
            columns: [
                {
                    title: language.getText('hydj'),
                    dataIndex: 'cardLevelName',
                    key: 'cardLevelName',
                    align: 'center' as const,
                },
                {
                    title: rewardValueTitle,
                    dataIndex: 'rewardValue',
                    key: 'rewardValue',
                    align: 'center' as const,
                    render: (rewardValue: any, record: any) => {
                        return (
                            <div style={{ alignItems: 'center' }}>
                                <InputNumber
                                    placeholder={services.language.getText('inputPlease')}
                                    value={rewardValue}
                                    min={1.01}
                                    precision={2}
                                    max={999999999}
                                    onChange={(value: any) =>
                                        this.changeValue(record, value, 'rewardValue')
                                    }
                                ></InputNumber>
                                &nbsp;&nbsp;{language.getText('jmultiple')}
                            </div>
                        );
                    },
                },
                experienceColumn,
            ],
        };
    };

    render() {
        const tableProps = this.getTableProps();
        return <Table {...tableProps}></Table>;
    }
}
