import { services } from '@comall-backend-builder/core/';
import { isEmpty, forEach } from 'lodash';

export const NewPageTemplateLoader = {
    get: async function(data: any, config: any) {
        return services.api.get(data, config).then((res: any) => {
            if (res && res.type) {
                res.type = { id: res.type };
            }
            if (res && !isEmpty(res.preview)) {
                res.preview = res.preview.id === '0' ? [] : [res.preview]
            }
            if (res && !isEmpty(res.result)) {
                forEach(res.result, (item) => {
                    item.preview = item.preview.id === '0' ? [] : [item.preview]
                })
            }
            return res;
        });
    },
    post: async function(data: any, config: any) {
        const preview = isEmpty(data?.preview) ? null : data.preview[0];
        const params = {
            ...data,
            preview
        }
        return services.api.post(params, config);
    },
    put: async function(data: any, config: any) {
        config.apiPath = '/admin/decorate/templates/:id';
        const preview = isEmpty(data?.preview) ? null : data.preview[0];
        const params = {
            ...data,
            preview
        }
        return services.api.put(params, config);
    },
    delete: async function(data: any, config: any) {
        config.apiPath = '/admin/decorate/templates/:id';

        data.applicableChannels = 'NEW_CLIENT'

        return services.api.delete(data, config);
    },
};
