import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { ProductCategorySelectMode } from '../../../components/product-category-select/select-product-category';
import get from 'lodash/get';

export const config: Config = {
    entities: {
        GoldDeductionEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
            apiPath: '/loader/admin/payment_mode_rule',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsrhdmc>>' },
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<applicableSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 200 } },
                },
                merchantId: {
                    displayName: '<<applyMerchants>>',
                    type: 'string.filterOptions.autoComplete',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                        //专柜默认正序排序
                        params: {
                            sequence: 'code',
                            order: 'asc',
                        },
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 200 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: [
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                goodsAstrict: {
                    type: 'string.options.select',
                    displayName: '<<cysp>>',
                    options: [
                        { id: '0', name: '<<qbspcy>>' },
                        { id: '1', name: '<<includeProduct>>' },
                        { id: '2', name: '<<excludeProduct>>' },
                    ],
                },
                subsite: {
                    type: 'string',
                    displayName: '<<applicableSubsite>>',
                },
                merchants: {
                    type: 'array.popover',
                    displayName: '<<applyMerchants>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                lastModifiedTime: {
                    type: 'string.date',
                    displayName: '<<xgsj>>',
                },

                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherBaseInfo>>',
                    properties: {
                        eventType: {
                            type: 'string',
                            displayName: '<<hdlx>>',
                        },
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [{ required: true, whitespace: true, message: '<<qsrhdmc>>' }],
                            controlConfig: {
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                            },
                        },
                    },
                },
                ruleInfo: {
                    type: 'object.subForm',
                    displayName: '<<activityRule>>',
                    properties: {
                        subsiteId: {
                            type: 'string.options.select',
                            displayName: '<<applicableSubsite>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [
                                {
                                    required: true,
                                    message: '<<selectSubsite>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<selectPlease>>',
                                allowClear: true,
                                showSearch: true,
                                optionFilterProp: 'children',
                                filterOption: (input: any, option: any) =>
                                    (option?.name ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase()),
                            },
                        },
                        merchants: {
                            type: 'array.merchant.selector.tick',
                            displayName: '<<applyMerchants>>',
                            defaultValue: [],

                            controlConfig: {
                                subsiteField: 'ruleInfo.subsiteId',
                                tips: '<<bxzzjzbgzsyysxmdxsyzj>>',
                                hideSubsiteColume: true,
                                customFields: [
                                    {
                                        property: 'merchantType',
                                    },
                                    {
                                        property: 'merchantTagId',
                                    },
                                    {
                                        property: 'merchantName',
                                    },
                                ],
                                customColumns: [
                                    {
                                        property: 'merchantName',
                                    },
                                    {
                                        property: 'merchantType',
                                    },
                                    {
                                        property: 'code',
                                        sorter: true,
                                    },
                                    {
                                        property: 'merchantStatus',
                                    },
                                ],
                            },
                            rules: [
                                {
                                    required: false,
                                },
                            ],
                        },
                        activityProductType: {
                            type: 'string.options.radio',
                            displayName: '<<activityProduct>>',
                            defaultValue: 'ALL',
                            options: [
                                { id: 'ALL', name: '<<allProduct>>' },
                                { id: 'INCLUDE_PRODUCT', name: '<<includeProduct>>' },
                                { id: 'EXCLUDE_PRODUCT', name: '<<excludeProduct>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        products: {
                            type: 'object.productCategory.selector',
                            displayName: '<<components.Tools.selectProduct>>',
                            controlConfig: {
                                selectProductTypes: ['NORMAL,COMBINE'],
                                subsiteSelectMode: 'ONLY_SUBSITE',
                                disableBand: true,
                                handleSelectMode: (row: any) => {
                                    const merchants = get(row, 'ruleInfo.merchants');
                                    if (merchants && merchants.length > 0) {
                                        return ProductCategorySelectMode.MERCHANT;
                                    }
                                    return ProductCategorySelectMode.SUB_SITE;
                                },
                            },
                        },
                        deductionItems: {
                            type: 'string.options.radio',
                            displayName: '<<dkxm>>',
                            defaultValue: '1',
                            rules: [{ required: true }],
                            options: [{ id: '1', name: '<<spje>>' }],
                        },
                        goldExchangeLimit: {
                            type: 'number.tip',
                            displayName: '<<jzdsy>>',
                            controlConfig: {
                                addonafter: '<<jb>>',
                                tip: '',
                                style: {
                                    width: 130,
                                },
                                max: 999999,
                                min: 1,
                            },
                            rules: [
                                {
                                    required: false,
                                    type: 'number',
                                    min: 1,
                                    message: '<<zdsyjbsljzcdzzs>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(0|\+?[1-9][0-9]*)$/;
                                        if (value && !reg.test(value)) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            ],
                        },
                        deductionProportion: {
                            type: 'number.tip',
                            displayName: '<<dkddjebl>>',
                            controlConfig: {
                                addonafter: '%',
                                tip: '',
                                style: {
                                    width: 130,
                                },
                                max: 100,
                                min: 1,
                            },
                            rules: [
                                {
                                    required: false,
                                    type: 'number',
                                    min: 1,
                                    message: '<<dkddjebljzcdzzs>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(0|\+?[1-9][0-9]*)$/;
                                        if (value && !reg.test(value)) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            ],
                        },
                    },
                },
            },
        },
    },
    components: {
        GoldDeductionView: {
            component: 'Viewport',
            entity: 'GoldDeductionEntity',
        },
        GoldDeductionPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'GoldDeductionFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/gold-deduction/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'GoldDeductionTable' },
            ],
        },
        GoldDeductionFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
            ],
        },
        GoldDeductionTable: {
            component: 'DataTable',
            loadFirstPage: true,
            scroll: {
                x: 1800,
            },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'goodsAstrict',
                },
                {
                    property: 'subsite',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'merchants',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                        countSuffix: '<<merchant>>',
                    },
                },
                {
                    property: 'executeStatus',
                },
                {
                    property: 'lastModifiedTime',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 220,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'status',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    value: true,
                                    params: { status: false },
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                                    apiPath: '/admin/payment_mode_rule/:id/status/COMPLETE',
                                    config: {
                                        content: '{{row.name}}<<beforeClose>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                        className: 'confirm-content',
                                    },
                                },
                                {
                                    value: false,
                                    params: { status: true },
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                                    apiPath: '/admin/payment_mode_rule/:id/status/STARTED',
                                    config: {
                                        content: '{{row.name}}<<beforeOpen>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/gold-deduction/edit/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'ImportButton',
                        statusKey: 'row.canImport',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                            apiPath: '/admin/payment_mode_rule/import/{{row.id}}',
                            text: '<<importProduct>>',
                            templateSource: {
                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                                apiPath: '/admin/payment_mode_rule/export.xlsx',
                            },
                            importFileSource: {
                                apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
                                apiPath: '/admin/files/locations/62/upload',
                            },
                            specialTips: ['<<rxtmddsphfgzqdrdsp>>'],
                        },
                    },
                ],
            },
        },
        GoldDeductionAddPage: {
            component: 'Card',
            content: { component: 'GoldDeductionAddForm' },
        },
        GoldDeductionAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'GoldDeductionEntity',
            componentId: 'GoldDeductionAddFormContainer',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'ruleInfo.subsiteId' },
                { property: 'ruleInfo.merchants' },
                { property: 'ruleInfo.activityProductType' },
                { property: 'ruleInfo.products' },
                { property: 'ruleInfo.deductionItems' },
                { property: 'ruleInfo.goldExchangeLimit' },
                { property: 'ruleInfo.deductionProportion' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        GoldDeductionEditPage: {
            component: 'Card',
            content: { component: 'GoldDeductionEditForm' },
        },
        GoldDeductionEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'GoldDeductionEntity',
            componentId: 'GoldDeductionEditFormContainer',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'ruleInfo.subsiteId' },
                { property: 'ruleInfo.merchants' },
                { property: 'ruleInfo.activityProductType' },
                { property: 'ruleInfo.products' },
                { property: 'ruleInfo.deductionItems' },
                { property: 'ruleInfo.goldExchangeLimit' },
                { property: 'ruleInfo.deductionProportion' },
            ],
            submit: { text: '<<common.save>>' },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/gold-deduction',
                    component: 'GoldDeductionView',
                    breadcrumbName: '<<goldDeduction>>',
                    privilege: {
                        path: 'goldDeduction',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'GoldDeductionAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'GoldDeductionEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        { path: '/', component: 'GoldDeductionPage' },
                    ],
                },
            ],
        },
    ],
};
