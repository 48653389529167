import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import get from 'lodash/get';
import { forEach } from 'lodash';
import { ReceiveLimitEnum } from './cash-coupon-rules';

export const config: Config = {
    entities: {
        ParkingCouponRulesEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
            apiPath: '/loader/admin/parking_coupon_rules',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<tcqmc>>',
                    controlConfig: { placeholder: '<<qsrtcqmc>>' },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<executeStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                    controlConfig: { style: { width: 150 } },
                },
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<couponTime>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<tcqmc>>',
                            rules: [{ required: true, whitespace: true, message: '<<qsrtcqmc>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<parkCouponRequired>>',
                                maxLength: 30,
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<effectiveTime>>',
                            rules: [{ required: true, message: '<<effectiveTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        dynamicEffectivePeriod: {
                            type: 'number.tip',
                            displayName: '<<dynamicEffectivePeriod>>',
                            rules: [
                                {
                                    message: '<<dynamicEffectivePeriodRule>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (
                                            value !== undefined &&
                                            value !== null &&
                                            (!reg.test(value) || value > 366 || value === 0)
                                        ) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<period>>',
                                style: {
                                    width: 125,
                                },
                                addonbefore: '<<bindCoupon>>',
                                addonafter: '<<effectDay>>',
                                tip: '',
                            },
                            extra: '<<effectExtra>>',
                        },
                        batchNo: {
                            type: 'string.tip',
                            displayName: '<<batchNo>>',
                            rules: [
                                {
                                    required: false,
                                    whitespace: true,
                                },
                            ],
                            controlConfig: {
                                style: { width: '300px' },
                                placeholder: '<<inputPlease>>',
                            },
                            extra: '<<batchNoExtra>>',
                        },
                        stock: {
                            type: 'object.couponRuleStock',
                            displayName: '<<stock>>',
                            rules: [{ required: true, message: '<<stockRequired>>' }],
                        },
                    },
                },
                productInfo: {
                    type: 'object.subForm',
                    displayName: '<<productSetting>>',
                    properties: {
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            rules: [{ required: true, type: 'array' }],
                        },
                        remark: {
                            type: 'string',
                            displayName: '<<remark>>',
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],
                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.productInfo?.subsites?.length &&
                                        row.productInfo.subsites
                                            .map((item: any) => item.id)
                                            .join(',');
                                    return { subsiteIds };
                                },
                            },
                        },
                    },
                },
                ruleInfo: {
                    type: 'object.subForm',
                    displayName: '<<preferentialRules>>',
                    properties: {
                        parkingValueType: {
                            type: 'string.options.radio',
                            displayName: '<<jmlx>>',
                            defaultValue: 'AMOUNT',
                            options: [
                                { id: 'AMOUNT', name: '<<couponValue>>' },
                                { id: 'HOURS', name: '<<jmxs>>' },
                                { id: 'NO', name: '<<qm_2>>' },
                            ],
                            rules: [{ required: true }],
                            extra: '<<parkingValueTypeExtra>>',
                        },
                        couponValueAmount: {
                            type: 'number.price',
                            displayName: '<<couponValue>>',
                            displayConfig: { addonafter: '<<yuan>>' },
                            rules: [
                                {
                                    message: '<<jmjejzcdxsdhlw>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(?!0+$)(?!0*\.0*$)\d{1,8}(\.\d{1,2})?$/;
                                        if (value) {
                                            if (Number(value) >= 0.01 && reg.test(value)) {
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                addonafter: '<<yuan>>',
                                placeholder: '<<pleaseInputNumber>>',
                                style: { width: '150px' },
                                rules: [
                                    {
                                        type: 'number',
                                        min: 0,
                                    },
                                ],
                            },
                        },
                        couponValueHours: {
                            type: 'number.price',
                            displayName: '<<jmxs>>',
                            displayConfig: { addonafter: '<<hour>>' },
                            rules: [
                                {
                                    required: false,
                                    message: '<<jmxsbkwk>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(?!0+$)(?!0*\.0*$)\d{1,8}(\.\d{1,2})?$/;
                                        if (value) {
                                            if (Number(value) >= 0.01 && reg.test(value)) {
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                addonafter: '<<hour>>',
                                placeholder: '<<pleaseInputNumber>>',
                                style: { width: '150px' },
                                rules: [
                                    {
                                        type: 'number',
                                        min: 0,
                                    },
                                ],
                            },
                        },
                    },
                },
                useRuleInfo: {
                    type: 'object.subForm',
                    displayName: '<<useRuleInfo>>',
                    properties: {
                        receivePeriod: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<receivePeriod>>',
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                            },
                            extra: '<<receivePeriodExtra>>',
                        },
                        receiveLimit: {
                            type: 'string.options.radio.plus',
                            displayName: '<<receiveLimit>>',
                            defaultValue: 'NO_LIMIT',
                            rules: [{ required: true }],
                            controlConfig: {
                                mode: 'horizontal',
                                setOptions: [
                                    {
                                        value: ReceiveLimitEnum.NO_LIMIT,
                                        name: '<<noLimit>>',
                                    },
                                    {
                                        value: ReceiveLimitEnum.MEMBER_LIMIT,
                                        name: '<<memberLimit>>',
                                        tip: {
                                            text: '<<memberLimitText>>',
                                        },
                                    },
                                    {
                                        value: ReceiveLimitEnum.CROWD_LIMIT,
                                        name: '<<crowdLimit>>',
                                    },
                                    {
                                        value: ReceiveLimitEnum.TIME_LIMIT,
                                        name: '<<timeLimit>>',
                                        tip: {
                                            text: '<<timeLimitTip>>',
                                        },
                                    },
                                ],
                            },
                        },
                        receiveLimitCrowds: {
                            type: 'array.receiveLimitationsCrowds',
                            displayName: '<<selectVipPeople>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                pageType: 'PackingCouponRuleMemberLevel',
                            },
                        },
                        useLimitations: {
                            type: 'string.options.radio.plus',
                            displayName: '<<useLimit>>',
                            defaultValue: 'NO_LIMIT',
                            rules: [{ required: true }],
                            controlConfig: {
                                mode: 'horizontal',
                                setOptions: [
                                    {
                                        value: 'NO_LIMIT',
                                        name: '<<noLimitAllUse>>',
                                    },
                                    {
                                        value: 'MEMBER_LIMIT',
                                        name: '<<memberLimit>>',
                                        tip: {
                                            text: '<<memberLimitTip2>>',
                                        },
                                    },
                                ],
                            },
                        },
                        memberLevels: {
                            type: 'array.options.autoComplete.properties.coupon',
                            displayName: '<<memberLevels>>',
                            options: [],
                            controlConfig: {
                                pageType: 'PackingCouponRuleMemberLevel',
                                placeholder: '<<selectPlease>>',
                                style: { width: 450 },
                            },
                            extra: '<<memberLevelsTip>>',
                        },
                        registerMemberSource: {
                            type: 'string.options.radio.plus',
                            displayName: '<<registerMemberSource>>',
                            defaultValue: 'ONLINE',
                            rules: [{ required: true }],
                            controlConfig: {
                                setOptions: [
                                    {
                                        value: 'ONLINE',
                                        name: '<<registOnline>>',
                                        tip: {
                                            text: '<<registOnlineTip>>',
                                        },
                                    },
                                    {
                                        value: 'OFFLINE',
                                        name: '<<registOffline>>',
                                        tip: {
                                            text: '<<registTip>>',
                                        },
                                    },
                                ],
                            },
                        },
                        useLimitationsMode: {
                            type: 'string.options.radio',
                            displayName: '<<useLimitationsMode>>',
                            defaultValue: 'DATE',
                            options: [
                                {
                                    id: 'DATE',
                                    name: '<<useLimitationsModeDate>>',
                                },
                                {
                                    id: 'DAY',
                                    name: '<<useLimitationsModeDay>>',
                                },
                            ],
                            rules: [{ required: true }],
                        },
                        useLimitationsDate: {
                            type: 'object.dateRangePlus',
                            displayName: '<<useLimitationsDate>>',
                            rules: [{ required: true, message: '<<selectUseLimitationsDate>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                            },
                            extra: '<<useLimitationsDateExtra>>',
                        },
                        useLimitationsDay: {
                            type: 'number.tip',
                            displayName: '<<registDay>>',
                            rules: [
                                {
                                    type: 'number',
                                    required: true,
                                    message: '<<registDayMessage>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && (!reg.test(value) || value > 999)) {
                                            return false;
                                        }
                                        if (!value || value === 0) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '',
                                style: {
                                    width: 125,
                                },
                                addonbefore: '',
                                addonafter: '<<day>>',
                                min: 1,
                                max: 999,
                                tip: '',
                            },
                            extra: '<<registDayExtra>>',
                        },
                        receiveQuantityLimitType: {
                            type: 'string.options.radio',
                            displayName: '<<receiveQuantityLimitType>>',
                            defaultValue: 'NO_LIMIT',
                            options: [
                                {
                                    id: 'NO_LIMIT',
                                    name: '<<limitNumberNoLimit>>',
                                },
                                {
                                    id: 'EVERY_MEMBER_LIMIT',
                                    name: '<<everyMemberLimit>>',
                                },
                                {
                                    id: 'EVERY_MEMBER_DAILY_LIMIT',
                                    name: '<<everyMemberDailyLimit>>',
                                },
                            ],
                            rules: [{ required: true }],
                        },
                        memberLimitQuantity: {
                            type: 'number',
                            displayName: '<<limitQuantity>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<inputThanZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^([1-9]\d*)$/;
                                        if (value && !reg.test(value)) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            displayConfig: { addonafter: '<<zhang>>' },
                        },
                        // showChannels: {
                        //     type: 'array.optionIds.checkbox.tip',
                        //      displayName: '<<showChannels>>',
                        //     defaultValue: ['PRODUCT'],
                        //     options: [{ id: 'PRODUCT', name: '<<showChannelsProduct>>' }],
                        //     extra: '<<showChannelsExtra>>',
                        // },
                        usedChannelLimit: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '<<usedChannelLimit>>',
                            defaultValue: ['3', '2'],
                            options: [
                                { id: '3', name: '<<xstcjfhx>>', disabled: true },
                                { id: '2', name: '<<xxhx>>' },
                            ] as any,
                            rules: [{ required: true }],
                            extra: `<<usedChannelLimitExtra>>`,
                        },
                        source: {
                            type: 'string.options.radio',
                            displayName: '<<setCouponType>>',
                            defaultValue: 'ONLINE',
                            options: [
                                {
                                    id: 'ONLINE',
                                    name: '<<xcxzq>>',
                                },
                            ],
                            rules: [{ required: true }],
                        },
                        onlineMode: {
                            type: 'string.options.radio',
                            displayName: '<<scqfs>>',
                            defaultValue: 'GENERATE_COUPON_CODE',
                            options: [
                                {
                                    id: 'GENERATE_COUPON_CODE',
                                    name: '<<xtsjscq>>',
                                },
                                {
                                    id: 'PREPARE_COUPON_CODE',
                                    name: '<<prepageCouponCode>>',
                                },
                            ],
                            rules: [{ required: true }],
                            extra: `<<onlineModeExtra>>`,
                        },
                        offlineMode: {
                            type: 'string.options.radio',
                            displayName: '<<scqfs>>',
                            defaultValue: 'GENERATE_COUPON_CODE',
                            options: [
                                {
                                    id: 'GENERATE_COUPON_CODE',
                                    name: '<<xtsjsc>>',
                                },
                                {
                                    id: 'PREPARE_COUPON_CODE',
                                    name: '<<drsfqm>>',
                                },
                            ],
                            rules: [{ required: true }],
                            extra: `<<onlineModeExtra>>`,
                        },
                        couponChannelStocks: {
                            type: 'array.sendOutChannel',
                            displayName: '<<sffsqd>>',
                            rules: [{ type: 'array' }],
                            controlConfig: {
                                style: {
                                    width: 950,
                                },
                            },
                        },
                    },
                },
                pictureInfo: {
                    type: 'object.subForm',
                    displayName: '<<tcqtp>>',
                    properties: {
                        couponPicture: {
                            displayName: '<<tppz>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<pzhzszwdyhqb>>',
                        },
                    },
                },
                instructions: {
                    type: 'object.subForm',
                    displayName: '<<instructions>>',
                    properties: {
                        info: {
                            type: 'string.text.paragraph',
                            displayName: '<<instructions>>',
                            rules: [{ required: true, whitespace: true, max: 2000 }],
                            controlConfig: {
                                rows: 4,
                                maxLength: 2000,
                            },
                        },
                    },
                },
                principalSetting: {
                    type: 'object.subForm',
                    displayName: '<<principalSetting>>',
                    properties: {
                        costPrice: {
                            type: 'number.price',
                            displayName: '<<cashMoney>>',
                            defaultValue: '0',
                            rules: [
                                {
                                    required: true,
                                    message: '<<bjbkwk>>',
                                    validator: (rule: any, value: any) => {
                                        if (value === 0 || value === '0') {
                                            return true;
                                        } else {
                                            var reg = /^(?!0+$)(?!0*\.0*$)\d{1,8}(\.\d{1,2})?$/;
                                            if (Number(value) >= 0.01 && reg.test(value)) {
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        }
                                    },
                                },
                            ],
                        },
                        point: {
                            type: 'number.price',
                            displayName: '<<point>>',
                            defaultValue: '0',
                            rules: [
                                {
                                    required: true,
                                    message: '<<pointRequired>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(0|\+?[1-9][0-9]*)$/;
                                        if (reg.test(value)) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                        },
                    },
                },
                seniorSetting: {
                    type: 'object.subForm',
                    displayName: '<<seniorSetting>>',
                    collapsed: true,
                    properties: {
                        advanceTime: {
                            type: 'string.options.selectPlus',
                            displayName: '<<yhqgq>>',
                            options: [
                                { id: '1', name: '1' },
                                { id: '2', name: '2' },
                                { id: '3', name: '3' },
                                { id: '4', name: '4' },
                                { id: '5', name: '5' },
                                { id: '6', name: '6' },
                                { id: '7', name: '7' },
                                { id: '8', name: '8' },
                                { id: '9', name: '9' },
                                { id: '10', name: '10' },
                                { id: '11', name: '11' },
                                { id: '12', name: '12' },
                                { id: '13', name: '13' },
                                { id: '14', name: '14' },
                            ],
                            controlConfig: {
                                addonbefore: '<<yhqgqq>>',
                                addonafter: '<<ttx>>',
                                allowClear: true,
                            },
                            extra: '<<jyszdtsxyyhqdyxq>>',
                        },
                        guideProvide: {
                            type: 'string.options.radio',
                            displayName: '<<guideProvide>>',
                            defaultValue: 'DISABLE',
                            options: [
                                { id: 'ENABLE', name: '<<yes>>' },
                                { id: 'DISABLE', name: '<<no>>' },
                            ],
                        },
                        shop: {
                            type: 'array.shop.selector',
                            displayName: '<<selectSubsites>>',
                            defaultValue: [],
                        },
                        subsite: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            defaultValue: [],
                        },
                        selectMode: {
                            type: 'string.options.radio',
                            displayName: '<<merchantAndSubsiteSelect>>',
                            options: [
                                { id: 'SUB_SITE', name: '<<subsiteSelect>>' },
                                { id: 'MERCHANT', name: '<<merchantSelect>>' },
                            ],
                            defaultValue: 'MERCHANT',
                            rules: [{ required: true }],
                        },
                        isTransfer: {
                            type: 'string.options.radio.hasLink',
                            displayName: '<<sfkzz>>',
                            defaultValue: 'false',
                            controlConfig: {
                                setOptions: [
                                    { value: 'true', name: '<<yes>>' },
                                    { value: 'false', name: '<<no>>' },
                                ],
                                JumpPath: '#/menu-list/coupon/coupon-transfer-cover',
                                JumpText: 'toConfigureGiftCover',
                            },
                        },
                        transferCount: {
                            type: 'number.tip',
                            displayName: '<<zdkzz>>',
                            rules: [
                                {
                                    required: true,
                                    type: 'number',
                                    min: 1,
                                    max: 9999,
                                    message: '<<qsrdyZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^([1-9]\d*)$/;
                                        if (
                                            value &&
                                            reg.test(value) &&
                                            value > 0 &&
                                            value < 10000
                                        ) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: '150px' },
                                placeholder: '<<inputPlease>>',
                                addonbefore: '',
                                addonafter: '<<frequency>>',
                                tip: '',
                            },
                        },
                    },
                },
                info: {
                    type: 'object.couponInfo',
                    displayName: '<<couponInfo2>>',
                },
                // startTime: {
                //     type: 'string.date',
                //     displayName: '<<couponStartTime>>',
                // },
                // endTime: {
                //     type: 'string.date',
                //     displayName: '<<couponEndTime>>',
                // },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<applicableSubsite>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<executeStatus>>',
                },
                quantity: {
                    type: 'number.integer',
                    displayName: '<<couponQuantity>>',
                    rules: [{ required: true }],
                    controlConfig: {
                        addonafter: '<<zhang>>',
                        placeholder: '<<pleaseInputNumber>>',
                        style: { width: '150px' },
                        max: 100000000,
                        min: 1,
                    },
                },
                exchangeType: {
                    type: 'string.options.radio',
                    displayName: '<<redeCode>>',
                    defaultValue: 'RANDOM',
                    options: [
                        {
                            id: 'RANDOM',
                            name: '<<random>>',
                        },
                    ],
                    rules: [{ required: true }],
                },
            },
        },
    },
    components: {
        ParkingCouponRulesView: {
            component: 'Viewport',
            entity: 'ParkingCouponRulesEntity',
        },
        ParkingCouponRulesPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'ParkingCouponRulesFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addCoupon>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/coupon/parking-coupon-rules/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'ParkingCouponRulesTable' },
            ],
        },
        ParkingCouponRulesFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'executeStatus',
                },
                {
                    property: 'subsiteIds',
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        ParkingCouponRulesTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'info',
                    width: 360,
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
                {
                    property: 'subsites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 310,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                                    apiPath: '/admin/parking_coupon_rules/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: '#f5222d' },
                                    },
                                    confirm: {
                                        text: '<<isCloseActivity>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                                    apiPath: '/admin/parking_coupon_rules/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/menu-list/coupon/parking-coupon-rules/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/menu-list/coupon/parking-coupon-rules/copy/copydata/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<viewData>>',
                        path: '/menu-list/coupon/parking-coupon-rules/details/parking/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'CouponRulesExchangeCodeButton',
                        config: {
                            disable: (row: any) => row.executeStatus === 'COMPLETE',
                            type: 'parking',
                        },
                    },
                    {
                        type: 'component',
                        component: 'CouponRulesChannlesInfoButton',
                        config: {
                            type: 'parking',
                        },
                    },
                    {
                        text: '<<common.log>>',
                        type: 'link',
                        path: '/menu-list/coupon/parking-coupon-rules/logs/{{row.id}}',
                    },
                ],
            },
        },
        ParkingCouponRulesAddPage: {
            component: 'Card',
            content: { component: 'ParkingCouponRulesAddForm' },
        },
        ParkingCouponRulesAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'ParkingCouponRulesEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'baseInfo.dynamicEffectivePeriod' },
                { property: 'baseInfo.batchNo' },
                {
                    property: 'baseInfo.stock',
                },
                { property: 'productInfo.subsites' },
                { property: 'productInfo.remark' },
                { property: 'productInfo.marketingSchedule' },
                {
                    property: 'ruleInfo.parkingValueType',
                    extra: '<<qgjtccdjjtqk>>',
                },
                {
                    property: 'ruleInfo.couponValueAmount',
                    visible: (values: any) => get(values, 'ruleInfo.parkingValueType') === 'AMOUNT',
                },
                {
                    property: 'ruleInfo.couponValueHours',
                    visible: (values: any) => get(values, 'ruleInfo.parkingValueType') === 'HOURS',
                },
                {
                    property: 'useRuleInfo.receivePeriod',
                },
                { property: 'useRuleInfo.receiveLimit' },
                {
                    property: 'useRuleInfo.memberLevels',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.MEMBER_LIMIT,
                },
                {
                    property: 'useRuleInfo.useLimitations',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.MEMBER_LIMIT,
                },
                {
                    property: 'useRuleInfo.receiveLimitCrowds',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.CROWD_LIMIT,
                },
                {
                    property: 'useRuleInfo.registerMemberSource',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT,
                },
                {
                    property: 'useRuleInfo.useLimitationsMode',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT,
                },
                {
                    property: 'useRuleInfo.useLimitationsDate',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT &&
                        get(values, 'useRuleInfo.useLimitationsMode') === 'DATE',
                },
                {
                    property: 'useRuleInfo.useLimitationsDay',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT &&
                        get(values, 'useRuleInfo.useLimitationsMode') === 'DAY',
                },
                { property: 'useRuleInfo.receiveQuantityLimitType' },
                {
                    property: 'useRuleInfo.memberLimitQuantity',
                    extra: '<<limitGetqtxgx>>',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveQuantityLimitType') ===
                            'EVERY_MEMBER_LIMIT' ||
                        get(values, 'useRuleInfo.receiveQuantityLimitType') ===
                            'EVERY_MEMBER_DAILY_LIMIT',
                },
                // {
                //     property: 'useRuleInfo.showChannels',
                // },
                {
                    property: 'useRuleInfo.usedChannelLimit',
                },
                {
                    property: 'useRuleInfo.source',
                },
                {
                    property: 'useRuleInfo.onlineMode',
                    visible: (values: any) => get(values, 'useRuleInfo.source') === 'ONLINE',
                },
                {
                    property: 'useRuleInfo.offlineMode',
                    visible: (values: any) => get(values, 'useRuleInfo.source') === 'OFFLINE',
                },
                {
                    property: 'useRuleInfo.couponChannelStocks',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.source') === 'ONLINE' &&
                        get(values, 'useRuleInfo.onlineMode') === 'GENERATE_COUPON_CODE',
                },
                { property: 'instructions.info' },
                { property: 'pictureInfo.couponPicture' },
                { property: 'seniorSetting.advanceTime' },
                {
                    property: 'seniorSetting.guideProvide',
                },
                {
                    property: 'seniorSetting.selectMode',
                    visible: (values: any) =>
                        get(values, 'seniorSetting.guideProvide') === 'ENABLE',
                },
                {
                    property: 'seniorSetting.shop',
                    visible: (values: any) =>
                        get(values, 'seniorSetting.selectMode') === 'MERCHANT' &&
                        get(values, 'seniorSetting.guideProvide') === 'ENABLE',
                    controlConfig: {
                        fieldParams: {
                            isContainsSelfMerchant: false,
                        },
                        fields: [
                            { property: 'subSiteName' },
                            { property: 'merchantTagId' },
                            { property: 'merchantName' },
                        ],
                        formColumns: [
                            {
                                property: 'subSiteName',
                            },
                            {
                                property: 'merchantName',
                            },
                            {
                                property: 'code',
                                sorter: true,
                            },
                            {
                                property: 'merchantType',
                            },
                            {
                                property: 'merchantStatus',
                            },
                        ],
                    },
                },
                {
                    property: 'seniorSetting.subsite',
                    visible: (values: any) =>
                        get(values, 'seniorSetting.selectMode') === 'SUB_SITE' &&
                        get(values, 'seniorSetting.guideProvide') === 'ENABLE',
                },
                { property: 'seniorSetting.isTransfer' },
                {
                    property: 'seniorSetting.transferCount',
                    visible: (values: any) => get(values, 'seniorSetting.isTransfer') === 'true',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                //领取数量限制
                const receiveQuantityLimitType = entity.useRuleInfo.receiveQuantityLimitType;
                //领取数量
                const memberLimitQuantity = entity.useRuleInfo.memberLimitQuantity;
                if (
                    (receiveQuantityLimitType === 'EVERY_MEMBER_LIMIT' ||
                        receiveQuantityLimitType === 'EVERY_MEMBER_DAILY_LIMIT') &&
                    (!memberLimitQuantity || Number(memberLimitQuantity) <= 0)
                ) {
                    AntMessage.warning(services.language.getText('qzqtxlqsl'));
                    return false;
                }

                //减免类型
                const parkingValueType = entity.ruleInfo.parkingValueType;
                //减免金额
                const couponValueAmount = entity.ruleInfo.couponValueAmount;
                //减免小时
                const couponValueHours = entity.ruleInfo.couponValueHours;
                if (
                    parkingValueType === 'AMOUNT' &&
                    (!couponValueAmount || Number(couponValueAmount) <= 0)
                ) {
                    AntMessage.warning(services.language.getText('qzqtxjmje'));
                    return false;
                }
                if (
                    parkingValueType === 'HOURS' &&
                    (!couponValueHours || Number(couponValueHours) <= 0)
                ) {
                    AntMessage.warning(services.language.getText('qzqtxjmxs'));
                    return false;
                }
                //适用等级
                const receiveLimit = entity.useRuleInfo.receiveLimit;
                if (receiveLimit === ReceiveLimitEnum.MEMBER_LIMIT) {
                    const memberLevels = entity.useRuleInfo.memberLevels;
                    if (!memberLevels || memberLevels.length === 0) {
                        AntMessage.warning(services.language.getText('selectApplyLevel'));
                        return false;
                    }
                }
                if (receiveLimit === ReceiveLimitEnum.CROWD_LIMIT) {
                    const receiveLimitCrowds = entity.useRuleInfo.receiveLimitCrowds;
                    if (!receiveLimitCrowds || receiveLimitCrowds.length === 0) {
                        AntMessage.warning(services.language.getText('selectApplyPeople'));
                        return false;
                    }
                }
                // 库存
                const stock = entity.baseInfo.stock.maxCount;
                // 需要填写库存
                if (!stock) {
                    AntMessage.warning(services.language.getText('kcsbkwk'));
                    return false;
                }
                //判断核销渠道最少选择一项
                const usedChannelLimit = entity.useRuleInfo.usedChannelLimit;
                if (!usedChannelLimit || !usedChannelLimit.length) {
                    AntMessage.warn(services.language.getText('qzsxzyxhxqd'));
                    return false;
                }
                //三方发送渠道，开启状态下，库存类型，库存输入值不能为空
                if (
                    entity.useRuleInfo.couponChannelStocks &&
                    entity.useRuleInfo.couponChannelStocks.length > 0
                ) {
                    let hasStockModelValidate = true;
                    let hasQuantityValidate = true;
                    forEach(entity.useRuleInfo.couponChannelStocks, (i) => {
                        if (!i.stockModel || i.stockModel === '') {
                            hasStockModelValidate = false;
                            return false;
                        }
                        if (
                            i.stockModel === 'INDEPENDENT' &&
                            (!i.maxCouponQuantity || i.maxCouponQuantity === '')
                        ) {
                            hasQuantityValidate = false;
                            return false;
                        }
                    });
                    if (!hasStockModelValidate) {
                        AntMessage.warn(services.language.getText('qxzqdkclx'));
                        return false;
                    }
                    if (!hasQuantityValidate) {
                        AntMessage.warn(services.language.getText('qsrqdkc'));
                        return false;
                    }
                }
                //导购发券
                if (entity.seniorSetting && entity.seniorSetting.guideProvide) {
                    const guideProvide = entity.seniorSetting.guideProvide;
                    if (guideProvide && guideProvide === 'ENABLE') {
                        const guideMerchants = get(entity, 'seniorSetting.shop');
                        const guideSubsiteIds = get(entity, 'seniorSetting.subsite');
                        const isguideMerchantsEmpty = !guideMerchants || !guideMerchants.length;
                        const isguideSubsiteIdsEmpty = !guideSubsiteIds || !guideSubsiteIds.length;
                        if (isguideMerchantsEmpty && isguideSubsiteIdsEmpty) {
                            AntMessage.warn(services.language.getText('dgfqgjmdxzhzgjzjxzbndwk'));
                            return false;
                        }
                        return true;
                    }
                }
                // 领取时间校验
                const receivePeriod = get(entity, 'useRuleInfo.receivePeriod', undefined);
                const dateRange = get(entity, 'baseInfo.dateRange', {});
                if (receivePeriod) {
                    const dateRangeEnd = new Date(dateRange.end).getTime();
                    const receivePeriodEnd = new Date(receivePeriod.end).getTime();
                    if (receivePeriodEnd > dateRangeEnd) {
                        AntMessage.warn(services.language.getText('lqdjssjbnzqsxjssjzq'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        ParkingCouponRulesEditPage: {
            component: 'Card',
            content: { component: 'ParkingCouponRulesEditForm' },
        },
        ParkingCouponRulesEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'ParkingCouponRulesEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                {
                    property: 'baseInfo.dateRange',
                },
                {
                    property: 'baseInfo.dynamicEffectivePeriod',
                    controlConfig: {
                        placeholder: '<<period>>',
                        style: {
                            width: 125,
                        },
                        addonbefore: '<<bindCoupon>>',
                        addonAfter: '<<effectDay>>',
                        tip: '',
                    },
                },
                {
                    property: 'baseInfo.batchNo',
                    controlConfig: {
                        style: { width: '178px' },
                        disabled: true,
                    },
                },
                {
                    property: 'baseInfo.stock',
                },
                {
                    property: 'productInfo.subsites',
                    controlConfig: {
                        disabled: true,
                    },
                },
                { property: 'productInfo.remark' },
                { property: 'productInfo.marketingSchedule' },
                {
                    property: 'ruleInfo.parkingValueType',
                    extra: '<<qgjtccdjjtqk>>',
                },
                {
                    property: 'ruleInfo.couponValueAmount',
                    visible: (values: any) => get(values, 'ruleInfo.parkingValueType') === 'AMOUNT',
                },
                {
                    property: 'ruleInfo.couponValueHours',
                    visible: (values: any) => get(values, 'ruleInfo.parkingValueType') === 'HOURS',
                },
                {
                    property: 'useRuleInfo.receivePeriod',
                },
                {
                    property: 'useRuleInfo.receiveLimit',
                    controlConfig: {
                        disabled: true,
                        mode: 'horizontal',
                        setOptions: [
                            {
                                value: ReceiveLimitEnum.NO_LIMIT,
                                name: '<<noLimit>>',
                            },
                            {
                                value: ReceiveLimitEnum.MEMBER_LIMIT,
                                name: '<<memberLimit>>',
                                tip: {
                                    text: '<<memberLimitText>>',
                                },
                            },
                            {
                                value: ReceiveLimitEnum.CROWD_LIMIT,
                                name: '<<crowdLimit>>',
                            },
                            {
                                value: ReceiveLimitEnum.TIME_LIMIT,
                                name: '<<timeLimit>>',
                                tip: {
                                    text: '<<timeLimitTip>>',
                                },
                            },
                        ],
                    },
                },
                {
                    property: 'useRuleInfo.memberLevels',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.MEMBER_LIMIT,
                },
                {
                    property: 'useRuleInfo.useLimitations',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.MEMBER_LIMIT,
                },
                {
                    property: 'useRuleInfo.receiveLimitCrowds',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.CROWD_LIMIT,
                },
                {
                    property: 'useRuleInfo.registerMemberSource',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT,
                },
                {
                    property: 'useRuleInfo.useLimitationsMode',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT,
                },
                {
                    property: 'useRuleInfo.useLimitationsDate',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT &&
                        get(values, 'useRuleInfo.useLimitationsMode') === 'DATE',
                },
                {
                    property: 'useRuleInfo.useLimitationsDay',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT &&
                        get(values, 'useRuleInfo.useLimitationsMode') === 'DAY',
                },
                { property: 'useRuleInfo.receiveQuantityLimitType' },
                {
                    property: 'useRuleInfo.memberLimitQuantity',
                    extra: '<<limitGetqtxgx>>',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveQuantityLimitType') ===
                            'EVERY_MEMBER_LIMIT' ||
                        get(values, 'useRuleInfo.receiveQuantityLimitType') ===
                            'EVERY_MEMBER_DAILY_LIMIT',
                },
                // {
                //     property: 'useRuleInfo.showChannels',
                // },
                {
                    property: 'useRuleInfo.usedChannelLimit',
                },
                {
                    property: 'useRuleInfo.source',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'useRuleInfo.onlineMode',
                    controlConfig: {
                        disabled: true,
                    },
                    visible: (values: any) => get(values, 'useRuleInfo.source') === 'ONLINE',
                },
                {
                    property: 'useRuleInfo.offlineMode',
                    controlConfig: {
                        disabled: true,
                    },
                    visible: (values: any) => get(values, 'useRuleInfo.source') === 'OFFLINE',
                },
                {
                    property: 'useRuleInfo.couponChannelStocks',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.source') === 'ONLINE' &&
                        get(values, 'useRuleInfo.onlineMode') === 'GENERATE_COUPON_CODE',
                },
                { property: 'instructions.info' },
                { property: 'pictureInfo.couponPicture' },
                { property: 'seniorSetting.advanceTime' },
                {
                    property: 'seniorSetting.guideProvide',
                },
                {
                    property: 'seniorSetting.selectMode',
                    visible: (values: any) =>
                        get(values, 'seniorSetting.guideProvide') === 'ENABLE',
                },
                {
                    property: 'seniorSetting.shop',
                    visible: (values: any) =>
                        get(values, 'seniorSetting.selectMode') === 'MERCHANT' &&
                        get(values, 'seniorSetting.guideProvide') === 'ENABLE',
                    controlConfig: {
                        fieldParams: {
                            isContainsSelfMerchant: false,
                        },
                        fields: [
                            { property: 'subSiteName' },
                            { property: 'merchantTagId' },
                            { property: 'merchantName' },
                        ],
                        formColumns: [
                            {
                                property: 'subSiteName',
                            },
                            {
                                property: 'merchantName',
                            },
                            {
                                property: 'code',
                                sorter: true,
                            },
                            {
                                property: 'merchantType',
                            },
                            {
                                property: 'merchantStatus',
                            },
                        ],
                    },
                },
                {
                    property: 'seniorSetting.subsite',
                    visible: (values: any) =>
                        get(values, 'seniorSetting.selectMode') === 'SUB_SITE' &&
                        get(values, 'seniorSetting.guideProvide') === 'ENABLE',
                },
                { property: 'seniorSetting.isTransfer' },
                {
                    property: 'seniorSetting.transferCount',
                    visible: (values: any) => get(values, 'seniorSetting.isTransfer') === 'true',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                ///领取数量限制
                const receiveQuantityLimitType = entity.useRuleInfo.receiveQuantityLimitType;
                //领取数量
                const memberLimitQuantity = entity.useRuleInfo.memberLimitQuantity;
                if (
                    (receiveQuantityLimitType === 'EVERY_MEMBER_LIMIT' ||
                        receiveQuantityLimitType === 'EVERY_MEMBER_DAILY_LIMIT') &&
                    (!memberLimitQuantity || Number(memberLimitQuantity) <= 0)
                ) {
                    AntMessage.warning(services.language.getText('qzqtxlqsl'));
                    return false;
                }

                //减免类型
                const parkingValueType = entity.ruleInfo.parkingValueType;
                //减免金额
                const couponValueAmount = entity.ruleInfo.couponValueAmount;
                //减免小时
                const couponValueHours = entity.ruleInfo.couponValueHours;
                if (
                    parkingValueType === 'AMOUNT' &&
                    (!couponValueAmount || Number(couponValueAmount) <= 0)
                ) {
                    AntMessage.warning(services.language.getText('qzqtxjmje'));
                    return false;
                }
                if (
                    parkingValueType === 'HOURS' &&
                    (!couponValueHours || Number(couponValueHours) <= 0)
                ) {
                    AntMessage.warning(services.language.getText('qzqtxjmxs'));
                    return false;
                }
                //适用等级
                const receiveLimit = entity.useRuleInfo.receiveLimit;
                if (receiveLimit === ReceiveLimitEnum.MEMBER_LIMIT) {
                    const memberLevels = entity.useRuleInfo.memberLevels;
                    if (!memberLevels || memberLevels.length === 0) {
                        AntMessage.warning(services.language.getText('selectApplyLevel'));
                        return false;
                    }
                }
                if (receiveLimit === ReceiveLimitEnum.CROWD_LIMIT) {
                    const receiveLimitCrowds = entity.useRuleInfo.receiveLimitCrowds;
                    if (!receiveLimitCrowds || receiveLimitCrowds.length === 0) {
                        AntMessage.warning(services.language.getText('selectApplyPeople'));
                        return false;
                    }
                }
                // // 库存
                // const stock = entity.baseInfo.stock.maxCount;
                // // 需要填写库存
                // if (!stock) {
                //     AntMessage.warning(services.language.getText('kcsbkwk'));
                //     return false;
                // }
                //判断核销渠道最少选择一项
                const usedChannelLimit = entity.useRuleInfo.usedChannelLimit;
                if (!usedChannelLimit || !usedChannelLimit.length) {
                    AntMessage.warn(services.language.getText('qzsxzyxhxqd'));
                    return false;
                }
                //三方发送渠道，开启状态下，库存类型，库存输入值不能为空
                if (
                    entity.useRuleInfo.couponChannelStocks &&
                    entity.useRuleInfo.couponChannelStocks.length > 0
                ) {
                    let hasStockModelValidate = true;
                    let hasQuantityValidate = true;
                    forEach(entity.useRuleInfo.couponChannelStocks, (i) => {
                        if (!i.stockModel || i.stockModel === '') {
                            hasStockModelValidate = false;
                            return false;
                        }
                        if (
                            i.stockModel === 'INDEPENDENT' &&
                            (!i.maxCouponQuantity || i.maxCouponQuantity === '')
                        ) {
                            hasQuantityValidate = false;
                            return false;
                        }
                    });
                    if (!hasStockModelValidate) {
                        AntMessage.warn(services.language.getText('qxzqdkclx'));
                        return false;
                    }
                    if (!hasQuantityValidate) {
                        AntMessage.warn(services.language.getText('qsrqdkc'));
                        return false;
                    }
                }
                //导购发券
                if (entity.seniorSetting && entity.seniorSetting.guideProvide) {
                    const guideProvide = entity.seniorSetting.guideProvide;
                    if (guideProvide && guideProvide === 'ENABLE') {
                        const guideMerchants = get(entity, 'seniorSetting.shop');
                        const guideSubsiteIds = get(entity, 'seniorSetting.subsite');
                        const isguideMerchantsEmpty = !guideMerchants || !guideMerchants.length;
                        const isguideSubsiteIdsEmpty = !guideSubsiteIds || !guideSubsiteIds.length;
                        if (isguideMerchantsEmpty && isguideSubsiteIdsEmpty) {
                            AntMessage.warn(services.language.getText('dgfqgjmdxzhzgjzjxzbndwk'));
                            return false;
                        }
                        return true;
                    }
                }
                // 领取时间校验
                const receivePeriod = get(entity, 'useRuleInfo.receivePeriod', undefined);
                const dateRange = get(entity, 'baseInfo.dateRange', {});
                if (receivePeriod) {
                    const dateRangeEnd = new Date(dateRange.end).getTime();
                    const receivePeriodEnd = new Date(receivePeriod.end).getTime();
                    if (receivePeriodEnd > dateRangeEnd) {
                        AntMessage.warn(services.language.getText('lqdjssjbnzqsxjssjzq'));
                        return false;
                    }
                }
                return true;
            },
            onSetSubmitConfirmProps: () => {
                return {
                    title: services.language.getText('wxts'),
                    content: services.language.getText('yscdwsyyhqjaxghdgzzx'),
                    okText: services.language.getText('rxxg'),
                    cancelText: services.language.getText('common.cancel'),
                };
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        ParkingCouponRulesCopyPage: {
            component: 'Card',
            content: { component: 'ParkingCouponRulesCopyForm' },
        },
        ParkingCouponRulesCopyForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'ParkingCouponRulesEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                {
                    property: 'baseInfo.dateRange',
                },
                {
                    property: 'baseInfo.dynamicEffectivePeriod',
                    controlConfig: {
                        placeholder: '<<period>>',
                        style: {
                            width: 125,
                        },
                        addonbefore: '<<bindCoupon>>',
                        addonAfter: '<<effectDay>>',
                        tip: '',
                    },
                },
                {
                    property: 'baseInfo.batchNo',
                    controlConfig: {
                        style: { width: '178px' },
                    },
                },
                {
                    property: 'baseInfo.stock',
                },
                {
                    property: 'productInfo.subsites',
                },
                { property: 'productInfo.remark' },
                { property: 'productInfo.marketingSchedule' },
                {
                    property: 'ruleInfo.parkingValueType',
                    extra: '<<qgjtccdjjtqk>>',
                },
                {
                    property: 'ruleInfo.couponValueAmount',
                    visible: (values: any) => get(values, 'ruleInfo.parkingValueType') === 'AMOUNT',
                },
                {
                    property: 'ruleInfo.couponValueHours',
                    visible: (values: any) => get(values, 'ruleInfo.parkingValueType') === 'HOURS',
                },
                {
                    property: 'useRuleInfo.receivePeriod',
                },
                {
                    property: 'useRuleInfo.receiveLimit',
                    controlConfig: {
                        mode: 'horizontal',
                        setOptions: [
                            {
                                value: ReceiveLimitEnum.NO_LIMIT,
                                name: '<<noLimit>>',
                            },
                            {
                                value: ReceiveLimitEnum.MEMBER_LIMIT,
                                name: '<<memberLimit>>',
                                tip: {
                                    text: '<<memberLimitText>>',
                                },
                            },
                            {
                                value: ReceiveLimitEnum.CROWD_LIMIT,
                                name: '<<crowdLimit>>',
                            },
                            {
                                value: ReceiveLimitEnum.TIME_LIMIT,
                                name: '<<timeLimit>>',
                                tip: {
                                    text: '<<timeLimitTip>>',
                                },
                            },
                        ],
                    },
                },
                {
                    property: 'useRuleInfo.memberLevels',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.MEMBER_LIMIT,
                },
                {
                    property: 'useRuleInfo.useLimitations',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.MEMBER_LIMIT,
                },
                {
                    property: 'useRuleInfo.receiveLimitCrowds',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.CROWD_LIMIT,
                },
                {
                    property: 'useRuleInfo.registerMemberSource',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT,
                },
                {
                    property: 'useRuleInfo.useLimitationsMode',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT,
                },
                {
                    property: 'useRuleInfo.useLimitationsDate',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT &&
                        get(values, 'useRuleInfo.useLimitationsMode') === 'DATE',
                },
                {
                    property: 'useRuleInfo.useLimitationsDay',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveLimit') === ReceiveLimitEnum.TIME_LIMIT &&
                        get(values, 'useRuleInfo.useLimitationsMode') === 'DAY',
                },
                { property: 'useRuleInfo.receiveQuantityLimitType' },
                {
                    property: 'useRuleInfo.memberLimitQuantity',
                    extra: '<<limitGetqtxgx>>',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.receiveQuantityLimitType') ===
                            'EVERY_MEMBER_LIMIT' ||
                        get(values, 'useRuleInfo.receiveQuantityLimitType') ===
                            'EVERY_MEMBER_DAILY_LIMIT',
                },
                // {
                //     property: 'useRuleInfo.showChannels',
                // },
                {
                    property: 'useRuleInfo.usedChannelLimit',
                },
                {
                    property: 'useRuleInfo.source',
                },
                {
                    property: 'useRuleInfo.onlineMode',
                    visible: (values: any) => get(values, 'useRuleInfo.source') === 'ONLINE',
                },
                {
                    property: 'useRuleInfo.offlineMode',
                    visible: (values: any) => get(values, 'useRuleInfo.source') === 'OFFLINE',
                },
                {
                    property: 'useRuleInfo.couponChannelStocks',
                    visible: (values: any) =>
                        get(values, 'useRuleInfo.source') === 'ONLINE' &&
                        get(values, 'useRuleInfo.onlineMode') === 'GENERATE_COUPON_CODE',
                },
                { property: 'instructions.info' },
                { property: 'pictureInfo.couponPicture' },
                { property: 'seniorSetting.advanceTime' },
                {
                    property: 'seniorSetting.guideProvide',
                },
                {
                    property: 'seniorSetting.selectMode',
                    visible: (values: any) =>
                        get(values, 'seniorSetting.guideProvide') === 'ENABLE',
                },
                {
                    property: 'seniorSetting.shop',
                    visible: (values: any) =>
                        get(values, 'seniorSetting.selectMode') === 'MERCHANT' &&
                        get(values, 'seniorSetting.guideProvide') === 'ENABLE',
                    controlConfig: {
                        fieldParams: {
                            isContainsSelfMerchant: false,
                        },
                        fields: [
                            { property: 'subSiteName' },
                            { property: 'merchantTagId' },
                            { property: 'merchantName' },
                        ],
                        formColumns: [
                            {
                                property: 'subSiteName',
                            },
                            {
                                property: 'merchantName',
                            },
                            {
                                property: 'code',
                                sorter: true,
                            },
                            {
                                property: 'merchantType',
                            },
                            {
                                property: 'merchantStatus',
                            },
                        ],
                    },
                },
                {
                    property: 'seniorSetting.subsite',
                    visible: (values: any) =>
                        get(values, 'seniorSetting.selectMode') === 'SUB_SITE' &&
                        get(values, 'seniorSetting.guideProvide') === 'ENABLE',
                },
                { property: 'seniorSetting.isTransfer' },
                {
                    property: 'seniorSetting.transferCount',
                    visible: (values: any) => get(values, 'seniorSetting.isTransfer') === 'true',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                ///领取数量限制
                const receiveQuantityLimitType = entity.useRuleInfo.receiveQuantityLimitType;
                //领取数量
                const memberLimitQuantity = entity.useRuleInfo.memberLimitQuantity;
                if (
                    (receiveQuantityLimitType === 'EVERY_MEMBER_LIMIT' ||
                        receiveQuantityLimitType === 'EVERY_MEMBER_DAILY_LIMIT') &&
                    (!memberLimitQuantity || Number(memberLimitQuantity) <= 0)
                ) {
                    AntMessage.warning(services.language.getText('qzqtxlqsl'));
                    return false;
                }

                //减免类型
                const parkingValueType = entity.ruleInfo.parkingValueType;
                //减免金额
                const couponValueAmount = entity.ruleInfo.couponValueAmount;
                //减免小时
                const couponValueHours = entity.ruleInfo.couponValueHours;
                if (
                    parkingValueType === 'AMOUNT' &&
                    (!couponValueAmount || Number(couponValueAmount) <= 0)
                ) {
                    AntMessage.warning(services.language.getText('qzqtxjmje'));
                    return false;
                }
                if (
                    parkingValueType === 'HOURS' &&
                    (!couponValueHours || Number(couponValueHours) <= 0)
                ) {
                    AntMessage.warning(services.language.getText('qzqtxjmxs'));
                    return false;
                }
                //适用等级
                const receiveLimit = entity.useRuleInfo.receiveLimit;
                if (receiveLimit === ReceiveLimitEnum.MEMBER_LIMIT) {
                    const memberLevels = entity.useRuleInfo.memberLevels;
                    if (!memberLevels || memberLevels.length === 0) {
                        AntMessage.warning(services.language.getText('selectApplyLevel'));
                        return false;
                    }
                }
                if (receiveLimit === ReceiveLimitEnum.CROWD_LIMIT) {
                    const receiveLimitCrowds = entity.useRuleInfo.receiveLimitCrowds;
                    if (!receiveLimitCrowds || receiveLimitCrowds.length === 0) {
                        AntMessage.warning(services.language.getText('selectApplyPeople'));
                        return false;
                    }
                }
                // 库存
                const stock = entity.baseInfo.stock.maxCount;
                // 需要填写库存
                if (!stock) {
                    AntMessage.warning(services.language.getText('kcsbkwk'));
                    return false;
                }
                //判断核销渠道最少选择一项
                const usedChannelLimit = entity.useRuleInfo.usedChannelLimit;
                if (!usedChannelLimit || !usedChannelLimit.length) {
                    AntMessage.warn(services.language.getText('qzsxzyxhxqd'));
                    return false;
                }
                //三方发送渠道，开启状态下，库存类型，库存输入值不能为空
                if (
                    entity.useRuleInfo.couponChannelStocks &&
                    entity.useRuleInfo.couponChannelStocks.length > 0
                ) {
                    let hasStockModelValidate = true;
                    let hasQuantityValidate = true;
                    forEach(entity.useRuleInfo.couponChannelStocks, (i) => {
                        if (!i.stockModel || i.stockModel === '') {
                            hasStockModelValidate = false;
                            return false;
                        }
                        if (
                            i.stockModel === 'INDEPENDENT' &&
                            (!i.maxCouponQuantity || i.maxCouponQuantity === '')
                        ) {
                            hasQuantityValidate = false;
                            return false;
                        }
                    });
                    if (!hasStockModelValidate) {
                        AntMessage.warn(services.language.getText('qxzqdkclx'));
                        return false;
                    }
                    if (!hasQuantityValidate) {
                        AntMessage.warn(services.language.getText('qsrqdkc'));
                        return false;
                    }
                }
                //导购发券
                if (entity.seniorSetting && entity.seniorSetting.guideProvide) {
                    const guideProvide = entity.seniorSetting.guideProvide;
                    if (guideProvide && guideProvide === 'ENABLE') {
                        const guideMerchants = get(entity, 'seniorSetting.shop');
                        const guideSubsiteIds = get(entity, 'seniorSetting.subsite');
                        const isguideMerchantsEmpty = !guideMerchants || !guideMerchants.length;
                        const isguideSubsiteIdsEmpty = !guideSubsiteIds || !guideSubsiteIds.length;
                        if (isguideMerchantsEmpty && isguideSubsiteIdsEmpty) {
                            AntMessage.warn(services.language.getText('dgfqgjmdxzhzgjzjxzbndwk'));
                            return false;
                        }
                        return true;
                    }
                }
                // 领取时间校验
                const receivePeriod = get(entity, 'useRuleInfo.receivePeriod', undefined);
                const dateRange = get(entity, 'baseInfo.dateRange', {});
                if (receivePeriod) {
                    const dateRangeEnd = new Date(dateRange.end).getTime();
                    const receivePeriodEnd = new Date(receivePeriod.end).getTime();
                    if (receivePeriodEnd > dateRangeEnd) {
                        AntMessage.warn(services.language.getText('lqdjssjbnzqsxjssjzq'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        ParkingCouponRulesLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                    apiPath: '/admin/operation_logs/PARKING/{id}',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/coupon/parking-coupon-rules',
                    component: 'ParkingCouponRulesView',
                    breadcrumbName: '<<parking>>',
                    privilege: {
                        path: 'parkingCouponRule',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'ParkingCouponRulesAddPage',
                            breadcrumbName: '<<xztcq>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'ParkingCouponRulesEditPage',
                            breadcrumbName: '<<bjkq>>',
                        },
                        {
                            path: '/copy/:type/:id',
                            component: 'ParkingCouponRulesCopyPage',
                            breadcrumbName: '<<fztcq>>',
                        },
                        {
                            path: '/details/:type/:id',
                            component: 'CouponRulesDetailsPage',
                            breadcrumbName: '<<viewData>>',
                        },
                        {
                            path: '/channels/:type/:id',
                            component: 'CouponRulesChannelsPage',
                            breadcrumbName: '<<sffqqdtj>>',
                        },
                        {
                            path: '/logs/:id',
                            component: 'ParkingCouponRulesLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        { path: '/', component: 'ParkingCouponRulesPage' },
                    ],
                },
            ],
        },
    ],
};
