import React, { ReactNode } from 'react';
import { services } from '@comall-backend-builder/core';
import { Button, Table, Badge, Modal, Pagination as AntPagination } from 'antd';
import { BadgeProps } from 'antd/lib/badge';
import { ColumnProps } from 'antd/lib/table';
import { find } from 'lodash';
import { registerChannelOptions } from '../member-info-detail-page/member-info-pane/MemberInfoPane';
import { RegisterchannelEnum, FilterParams, MemberInfoStatus, Member, Subsite } from '../common';
import MemberManageFilter from './member-manage-filter/MemberManageFilter';
import { TextTips, GroupPopover } from '../../../components';
import { getUrlParam } from '../../../applications/cae/services/utils';
import MemberAddTagButton from './member-add-tag-button/MemberAddTagButton';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const { navigation, api } = services;

interface Pagination {
    page: number;
    perPage?: number;
}

interface State {
    total: number;
    searchParams: FilterParams;
    datasource: Member[];
    loading: boolean;
    pagination: Pagination;
    initialTagParams?: {
        tagSubsiteId: string;
        tagId: string;
    };
    subsites: Subsite[];
    initialCrowdParams?: {
        crowdSubsiteId: string;
        crowdId: string;
    };
    sorter?: {
        field: string;
        order: 'ascend' | 'descend';
    };
    sortEnum?: MemberSortEnum;
}

enum MemberSortEnum {
    /**
     * 注册时间升序
     */
    RegisterTimeAsc = 'REGISTER_TIME_ASC',
    /**
     * 注册时间降序
     */
    RegisterTimeDesc = 'REGISTER_TIME_DESC',
    /**
     * 首次登陆时间升序
     */
    FirstLoginTimeAsc = 'FIRST_LOGIN_TIME_ASC',
    FirstLoginTimeDesc = 'FIRST_LOGIN_TIME_DESC',
    /**
     * 上次登录时间升序
     */
    LastLoginTimeAsc = 'LAST_LOGIN_TIME_ASC',
    LastLoginTimeDesc = 'LAST_LOGIN_TIME_DESC',
}
const MemberSortObj = {
    registerTime: {
        ascend: MemberSortEnum.RegisterTimeAsc,
        descend: MemberSortEnum.RegisterTimeDesc,
    },
    firstLoginTime: {
        ascend: MemberSortEnum.FirstLoginTimeAsc,
        descend: MemberSortEnum.FirstLoginTimeDesc,
    },
    lastLoginTime: {
        ascend: MemberSortEnum.LastLoginTimeAsc,
        descend: MemberSortEnum.LastLoginTimeDesc,
    },
};

export class MemberManageReport extends React.Component<any, State> {
    state: State = {
        total: 0,
        pagination: {
            page: 1,
            perPage: 10,
        },
        searchParams: {},
        datasource: [],
        loading: false,
        initialTagParams: undefined,
        subsites: [],
        sorter: {
            field: 'firstLoginTime',
            order: 'descend',
        },
    };

    componentDidMount() {
        const tagSubsiteId = getUrlParam(window.location.hash, 'tagSubsiteId');
        const tagId = getUrlParam(window.location.hash, 'tagId');
        const crowdSubsiteId = getUrlParam(window.location.hash, 'crowdSubsiteId');
        const crowdId = getUrlParam(window.location.hash, 'crowdId');
        this.getSubsites();
        const searchParams: FilterParams = {};
        let initialTagParams = undefined;
        let initialCrowdParams = undefined;
        if (tagSubsiteId && tagId) {
            searchParams.tagSubsiteId = tagSubsiteId;
            searchParams.tagId = tagId;
            initialTagParams = {
                tagSubsiteId,
                tagId,
            };
        }
        if (crowdSubsiteId && crowdId) {
            searchParams.crowdId = crowdId;
            initialCrowdParams = {
                crowdSubsiteId,
                crowdId,
            };
        }
        this.setState(
            {
                searchParams,
                initialTagParams,
                initialCrowdParams,
            },
            this.onSearchData
        );
    }

    getSubsites = async () => {
        const subsites: Subsite[] = await api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: '/WEB-API/admin/defaults/subsite',
            }
        );
        this.setState({
            subsites,
        });
    };

    private onSearchData = async () => {
        try {
            const { searchParams, pagination, sorter } = this.state;
            this.setState({
                loading: true,
            });
            let sortEnum: MemberSortEnum | undefined = undefined;
            if (sorter) {
                const typeObj = MemberSortObj[sorter.field as keyof typeof MemberSortObj];
                sortEnum = typeObj ? typeObj[sorter.order] : undefined;
            }
            const params = {
                ...pagination,
                ...searchParams,
                sortEnum,
            };

            const res: any = await api.get(params, {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                apiPath: '/admin/members',
            });
            this.setState({
                datasource: res.result,
                total: res.totalNum,
                loading: false,
            });
        } catch (error) {
            services.errorHandle(error as any);
        }
    };

    onSearch = (params: FilterParams) => {
        this.setState(
            {
                searchParams: params,
                pagination: {
                    page: 1,
                    perPage: 10,
                },
            },
            this.onSearchData
        );
    };

    pageChange = (pagination: Pagination) => {
        this.setState({ pagination }, this.onSearchData);
    };

    onNavigationToInfo = (id: string) => {
        navigation.goto(`/member-manage/info/${id}`);
    };
    onNavigationToLog = (id: string) => {
        navigation.goto(`/member-manage/logs/${id}`);
    };

    onChangeStatus = async (row: Member) => {
        Modal.warning({
            content: `${language.getText('sf_1')}${
                row.memberStatus === MemberInfoStatus.NORMAL
                    ? language.getText('invalid')
                    : language.getText('jd')
            }${row.mobile}?`,
            okText: services.language.getText('common.confirm'),
            cancelText: services.language.getText('common.cancel'),
            onOk: async () => {
                await api.put(
                    {
                        status: row.memberStatus === 'NORMAL' ? 'INVALID' : 'NORMAL',
                    },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                        apiPath: `/admin/members/${row.memberId}/status`,
                    }
                );
                this.onSearchData();
            },
            onCancel() {},
        });
    };

    private renderTable = () => {
        const { datasource, loading, subsites, sorter } = this.state;
        const registerTimeSort =
            sorter && sorter.field === 'registerTime' ? sorter.order : undefined;
        const firstLoginTimeSort =
            sorter && sorter.field === 'firstLoginTime' ? sorter.order : undefined;
        const lastLoginTimeSort =
            sorter && sorter.field === 'lastLoginTime' ? sorter.order : undefined;
        const miniProgramNameNode: ReactNode = (
            <div>
                <div>{language.getText('yhtgsqdtgm')}</div>
                <div>{language.getText('zcsdtgjkmc')}</div>
            </div>
        );
        const statusOption: {
            [str: string]: BadgeProps;
        } = {
            [MemberInfoStatus.NORMAL]: {
                text: language.getText('zc_1'),
                status: 'processing',
            },
            [MemberInfoStatus.INVALID]: {
                text: language.getText('invalid'),
                status: 'default',
            },
            [MemberInfoStatus.OFF]: {
                text: language.getText('off'),
                status: 'default',
            },
        };
        const columns: ColumnProps<Member>[] = [
            {
                title: language.getText('sjhm'),
                dataIndex: 'mobile',
                width: 150,
                fixed: 'left',
            },
            {
                title: 'CRMID',
                dataIndex: 'crmMemberId',
            },
            {
                title: language.getText('memberId'),
                dataIndex: 'memberId',
            },
            {
                title: language.getText('zcmd'),
                dataIndex: 'registerSubsiteName',
            },
            {
                title: language.getText('zcsj'),
                dataIndex: 'registerTime',
                sorter: true,
                sortOrder: registerTimeSort,
            },
            {
                title: language.getText('zcqd'),
                dataIndex: 'registerChannel',
                render: (val: RegisterchannelEnum) => {
                    const source = find(registerChannelOptions, { id: val });
                    return <span>{(source && language.getText(source.name)) || ''}</span>;
                },
            },
            {
                title: (
                    <TextTips
                        text={language.getText('point')}
                        tips={language.getText('cczsgyhzcmdxjfzz')}
                    />
                ),
                dataIndex: 'point',
            },
            {
                title: (
                    <TextTips
                        text={language.getText('cardLevel')}
                        tips={language.getText('rdjqfmdcczsgyhzcmddqdj')}
                    />
                ),
                dataIndex: 'cardLevelName',
            },
            {
                title: (
                    <TextTips
                        text={language.getText('scdlmd')}
                        tips={language.getText('jyhscdlsdmd')}
                    />
                ),
                dataIndex: 'firstLoginSubsiteName',
            },
            {
                title: (
                    <TextTips
                        text={language.getText('scdlsj')}
                        tips={language.getText('jasmineyhscdlsdsj')}
                    />
                ),
                dataIndex: 'firstLoginTime',
                sorter: true,
                sortOrder: firstLoginTimeSort,
            },
            {
                title: language.getText('zhdlsj'),
                dataIndex: 'lastLoginTime',
                sorter: true,
                sortOrder: lastLoginTimeSort,
            },
            {
                title: language.getText('smlx'),
                dataIndex: 'registerUtmTypeName',
            },
            {
                title: (
                    <TextTips
                        text={language.getText('smly')}
                        tips={miniProgramNameNode}
                        tipsStyle={{ maxWidth: '300px' }}
                    />
                ),
                dataIndex: 'registerUtmItemName',
            },
            {
                title: language.getText('zxzj'),
                dataIndex: 'registerUtmMerchantName',
            },
            {
                title: language.getText('zxdgy'),
                dataIndex: 'registerUtmGuideUserMobile',
            },
            {
                title: language.getText('gzgzh'),
                dataIndex: 'wechatOfficialAccounts',
                render: (val: any[]) => {
                    const list = val.map((item: any) => ({ id: item.appid, name: item.name }));
                    return (
                        <GroupPopover
                            list={list}
                            replaceEmpty={language.getText('wgz')}
                            countSuffix={language.getText('wechatOfficialAccount')}
                        />
                    );
                },
            },
            {
                title: language.getText('hyzt'),
                dataIndex: 'memberStatus',
                width: 100,
                render: (val: MemberInfoStatus) => (
                    <span>
                        <Badge {...statusOption[val]} />
                    </span>
                ),
            },
            {
                title: services.language.getText('common.tableAction'),
                fixed: 'right',
                className: 'action-column',
                render: (_, row) => (
                    <div>
                        <Button
                            className="table-action-column-item"
                            type="link"
                            onClick={this.onNavigationToInfo.bind(this, row.memberId)}
                        >
                            {language.getText('common.view')}
                        </Button>
                        <MemberAddTagButton
                            key={`add-tag-${row.memberId}`}
                            memberId={row.memberId}
                            subsites={subsites}
                        />
                        {row.memberStatus === MemberInfoStatus.NORMAL ||
                        row.memberStatus === MemberInfoStatus.INVALID ? (
                            <Button
                                className="table-action-column-item"
                                type="link"
                                onClick={this.onChangeStatus.bind(this, row)}
                            >
                                {row.memberStatus === 'NORMAL'
                                    ? language.getText('invalid')
                                    : language.getText('jd')}
                            </Button>
                        ) : null}
                        <Button
                            className="table-action-column-item"
                            type="link"
                            onClick={this.onNavigationToLog.bind(this, row.memberId)}
                        >
                            {language.getText('common.log')}
                        </Button>
                    </div>
                ),
            },
        ];
        return (
            <div className={`${prefix}__table`}>
                <Table
                    rowClassName={(_, index: number) => {
                        if (index % 2) {
                            return `${prefix}__table__bg`;
                        }
                        return '';
                    }}
                    scroll={{ x: true }}
                    loading={loading}
                    rowKey={(record) => `${prefix}-${record.memberId}`}
                    pagination={false}
                    columns={columns}
                    dataSource={datasource}
                    onChange={this.handleTableChange}
                />
            </div>
        );
    };

    private handleTableChange = (pagination: any, filters: any, tableSorter: any) => {
        const { sorter } = this.state;
        if (tableSorter && tableSorter.columnKey) {
            if (sorter && sorter.field === tableSorter.columnKey) {
                this.setState(
                    {
                        sorter: {
                            field: sorter.field,
                            order: sorter.order === 'ascend' ? 'descend' : 'ascend',
                        },
                        pagination: {
                            page: 1,
                            perPage: 10,
                        },
                    },
                    this.onSearchData
                );
            } else {
                this.setState(
                    {
                        sorter: {
                            field: tableSorter.columnKey,
                            order: 'descend',
                        },
                        pagination: {
                            page: 1,
                            perPage: 10,
                        },
                    },
                    this.onSearchData
                );
            }
        }
    };

    private renderFooter = () => {
        const { pagination, total, subsites, searchParams } = this.state;
        const paginationProps = {
            current: pagination.page,
            pageSize: pagination.perPage,
            showSizeChanger: true,
            // showQuickJumper: true,
            pageSizeOptions: ['10', '15', '20'],
            total,
            showTotal(total: number) {
                return services.interpolate(services.language.getText('total'), {
                    total,
                });
            },
            onChange: (page: number, perPage?: number) => {
                this.pageChange({ page, perPage });
            },
            onShowSizeChange: (page: number, perPage?: number) => {
                this.pageChange({ page: 1, perPage });
            },
        };
        return (
            <div className={`${prefix}__footer`}>
                <div className={`${prefix}__footer__left`}>
                    <MemberAddTagButton
                        type="batch"
                        subsites={subsites}
                        searchParams={searchParams}
                    />
                </div>
                <div className={`${prefix}__footer__right`}>
                    <AntPagination {...paginationProps} />
                </div>
            </div>
        );
    };

    render() {
        const { initialTagParams, initialCrowdParams } = this.state;
        return (
            <div>
                <MemberManageFilter
                    initialTagParams={initialTagParams}
                    initialCrowdParams={initialCrowdParams}
                    onSearch={this.onSearch}
                />
                {this.renderTable()}
                {this.renderFooter()}
            </div>
        );
    }
}

const prefix = 'member-manage-report';
