import React, { Component } from 'react';
import { Radio } from 'antd';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

export class ThemeStyleNavigationSelect extends Component<any, any> {
    componentDidMount() {
        const { params, onChange } = this.props;
        if (!params || !params.id) {
            const value = {
                backgroundColor: '#ffffff',
            };
            onChange(value);
        }
    }
    onChange = (color: string, type: 'backgroundColor') => {
        const { value, onChange } = this.props;
        const newValue = Object.assign({}, value);
        newValue[type] = color;
        onChange(newValue);
    };
    render() {
        const {
            value = {
                backgroundColor: '#ffffff',
            },
        } = this.props;
        return (
            <div className="theme-style-selector">
                <div className="row">
                    <div className="content">
                        <Radio.Group
                            className="fill-style"
                            value={value.backgroundColor}
                            onChange={(e: any) => {
                                this.onChange(e.target.value, 'backgroundColor');
                            }}
                        >
                            <Radio value={'#ffffff'}>{language.getText('fcjsfg')}</Radio>
                            <Radio value={'transparent'}>{language.getText('cjsfg')}</Radio>
                        </Radio.Group>
                    </div>
                </div>
            </div>
        );
    }
}
