import React, { PureComponent, createElement } from 'react';
import { forEach, map, remove } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Modal, message } from 'antd';
import { isArray } from 'lodash';

interface CouponPackage {
    id: string;
    name: number;
    startTime: string;
    endTime: string;
    /**
     * 礼包中的优惠券信息
     */
    couponInfo: Array<CouponPackageItems>;
    /**
     * 被选中的优惠券礼包
     */
    select?: boolean;
    /**
     * 优惠券礼包
     */
    couponType: string;
}

interface CouponPackageItems {
    batchNo: string;
    couponDefinitionId: number;
    couponDefinitionName: string;
    couponTypeDesc: string;
    quantity: number;
}

interface CouponPackageSelectorProps {
    selectValues: CouponPackage[];
    onChange: (products: CouponPackage[]) => void;
    params?: any;
    type?: string;
    buttonProps?: any;
    checkSubsiteOnSelect?: boolean;
}

interface CouponPackageSelectorState {
    visible: boolean;
}

const TableComponentId = 'CouponPackageSelectorTable';
let Entity: any = {};
let entity: any = {};

/**
 * 选择优惠券
 */
export class CouponPackageSelector extends PureComponent<
    CouponPackageSelectorProps,
    CouponPackageSelectorState
> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('CouponPackageSelector');
        entity = new Entity({});
        this.state = { visible: false };
    }

    dispatch: any = {};

    selectedRows: Array<CouponPackage> = [];

    componentWillUnmount() {
        //this.dispatch(actions.unmountEntityAction(entity));
    }

    onTableRowSelect = (record: CouponPackage, selected: boolean) => {
        const { type } = this.props;
        if (selected) {
            if ('radio' === type || !type) {
                this.selectedRows = [record];
            } else {
                this.selectedRows.push(record);
            }
        } else {
            remove(this.selectedRows, (i) => {
                return i.id === record.id;
            });
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: CouponPackage[]) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            this.selectedRows = [];
        }
    };

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
    };

    onOk = () => {
        const { onChange } = this.props;
        if (onChange) {
            forEach(this.selectedRows, (coupon) => {
                coupon.select = true;
                coupon.couponType = 'PACKAGE';
            });

            if (isArray(this.selectedRows) && this.selectedRows.length > 0) {
                onChange(this.selectedRows);
                this.toggleModal();
            }
        }
    };

    getConfig = () => {
        const { params, selectValues, type } = this.props;

        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: params || {},
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'right' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        entity.search(params);
                    },
                    fields: [
                        {
                            property: 'name',
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    rowSelection: {
                        type: type ? type : 'radio',
                        selectedRowKeys: map(selectValues, 'id'),
                        onSelect: (record: CouponPackage, selected: boolean, selectedRow: any) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: CouponPackage[]) => {
                            this.onTableRowSelectAll(selected, rows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    columns: [
                        {
                            property: 'name',
                        },
                        {
                            property: 'startTime',
                        },
                        {
                            property: 'endTime',
                        },
                        {
                            property: 'executeStatus',
                        },
                    ],
                },
            ],
        };
    };

    toggleModal = () => {
        const { visible } = this.state;
        const { params, checkSubsiteOnSelect } = this.props;
        if (!visible && checkSubsiteOnSelect && (!params.subsiteIds || !params.subsiteIds.length)) {
            message.warn(services.language.getText('selectBeforeMerchant'));
            return;
        }

        this.setState({ visible: !visible }, () => {
            if (!visible && entity.paging) {
                entity.search({
                    ...entity.paging,
                    ...params,
                });
                this.onClearAll();
            }
        });
    };

    render() {
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 900,
            title: services.language.getText('selectCouponGift'),
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };
        const btnProps = Object.assign(
            {},
            { text: services.language.getText('tjyhqlb'), type: 'primary' },
            this.props.buttonProps
        );

        return (
            <div style={{ display: 'inline-block' }}>
                <Button {...btnProps} onClick={this.toggleModal}>
                    {btnProps.text}
                </Button>
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}
