import { Config } from '@comall-backend-builder/core/lib/parser';

export const CapitalFlowConfig: Config = {
    entities: {},
    components: {
        CapitalFlowView: {
            component: 'Viewport',
        },
        CapitalFlowPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'JQCapitalFlowPage',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/capital-flows',
                    component: 'CapitalFlowView',
                    breadcrumbName: '<<CapitalFlows>>',
                    privilege: {
                        path: 'supplyChainFinancialTransaction',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/',
                            component: 'CapitalFlowPage',
                        },
                    ],
                },
            ],
        },
    ],
};
