import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;
export const ParkingInvoicesLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`;
        config.apiPath = '/admin/invoices';
        data.orderType = 'PARKING';
        if (!isEmpty(data.dateRange)) {
            data.applyStartTime = data.dateRange.start + ' 00:00:00';
            data.applyEndTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        if (data.status === 'ALL') {
            delete data.status;
        }
        return await api.get(data, config).then((res: any) => {
            res.result = res.result.map((item: any) => {
                item.subsiteName = item.subsite && item.subsite.name;
                item.mobile = item.buyer && item.buyer.mobile;
                return item;
            });
            return res;
        });
    },
};
