import React, { PureComponent } from 'react';
import { Button } from 'antd';
import { Column, ColumnConfig } from '@ant-design/charts';
import { TabKey } from '../index';
import './index.less';
import { services } from '@comall-backend-builder/core';

export enum TimeHorizon {
    RecentOneYears = 1,
    RecentThreeYears = 2,
}

interface ChartItem {
    key: string;
    value: number;
    type: string;
}
interface Props {
    timeHorizon: TimeHorizon;
    dealRecordsChartData: ChartItem[];
    dealRecordsChartTitle: string;
    mallVisitRecordsChartData: ChartItem[];
    mallVisitRecordsChartTitle: string;
    onChangeTimeHorizon: (timeHorizon: TimeHorizon) => void;
    onChangeTab: (key: TabKey, pageParams?: any) => void;
}
interface State {}
export default class MemberInfoCharts extends PureComponent<Props, State> {
    private rednerHeader = () => {
        const { timeHorizon, onChangeTimeHorizon } = this.props;
        const classes = {
            head: `${prefix}__head`,
        };
        const oneType = timeHorizon === TimeHorizon.RecentOneYears ? 'primary' : 'default';
        const threeType = timeHorizon === TimeHorizon.RecentThreeYears ? 'primary' : 'default';
        return (
            <div className={classes.head}>
                <Button
                    type={oneType}
                    onClick={onChangeTimeHorizon.bind(this, TimeHorizon.RecentOneYears)}
                >
                    近一年
                </Button>
                <Button
                    type={threeType}
                    onClick={onChangeTimeHorizon.bind(this, TimeHorizon.RecentThreeYears)}
                    style={{ marginLeft: '8px' }}
                >
                    近三年
                </Button>
            </div>
        );
    };

    private getExpenseConfig: () => ColumnConfig = () => {
        const { dealRecordsChartData } = this.props;
        const isValid = dealRecordsChartData.some((i) => i.value);
        return {
            data: dealRecordsChartData,
            autoFit: true,
            isStack: true,
            xField: 'key',
            yField: 'value',
            seriesField: 'type',
            colorField: 'type',
            maxColumnWidth: 20,
            minColumnWidth: 20,
            legend: {
                position: 'top-right',
                layout: 'horizontal',
            },
            tooltip: {
                formatter: (datum) => {
                    return {
                        name: datum.type || '消费金额',
                        value: datum.value,
                    };
                },
            },
            yAxis: isValid
                ? undefined
                : {
                      nice: true,
                      max: 8000,
                  },
        };
    };

    private renderExpenseRecord = () => {
        const {
            onChangeTab,
            dealRecordsChartTitle,
            timeHorizon,
            dealRecordsChartData,
        } = this.props;
        const config = this.getExpenseConfig();
        const isValid = dealRecordsChartData.some((i) => i.value);
        return (
            <div className={`${chartClasses.chart} ${prefix}__chart-border`}>
                <div className={chartClasses.chartTitle}>
                    <div className={chartClasses.titleText}>
                        消费金额
                        <span className={chartClasses.titleTextSecondary}>
                            (单位：{services.language.getText('yuan')})
                        </span>
                    </div>
                    <div className={chartClasses.titleRight}>
                        <span className={chartClasses.titleRightText}>{dealRecordsChartTitle}</span>
                        <Button
                            type="link"
                            onClick={onChangeTab.bind(this, TabKey.ExpenseRecord, { timeHorizon })}
                        >
                            查看
                        </Button>
                    </div>
                </div>
                <div className={chartClasses.chartMain}>
                    <Column {...config} />
                    {this.renderEmpty(isValid)}
                </div>
            </div>
        );
    };

    private renderEmpty = (isValid: boolean) => {
        if (isValid) {
            return null;
        }
        return <div className={chartClasses.chartMainEmpty}>暂无数据</div>;
    };

    private getReachStore: () => ColumnConfig = () => {
        const { mallVisitRecordsChartData } = this.props;
        const isValid = mallVisitRecordsChartData.some((i) => i.value);
        return {
            data: mallVisitRecordsChartData,
            autoFit: true,
            isStack: true,
            xField: 'key',
            yField: 'value',
            seriesField: 'type',
            colorField: 'type',
            maxColumnWidth: 20,
            minColumnWidth: 20,
            legend: {
                position: 'top-right',
                layout: 'horizontal',
            },
            tooltip: {
                formatter: (datum) => {
                    return {
                        name: datum.type || '到店天数',
                        value: datum.value,
                    };
                },
            },
            yAxis: isValid
                ? undefined
                : {
                      nice: true,
                      max: 30,
                  },
        };
    };

    private renderReachStore = () => {
        const { mallVisitRecordsChartTitle, mallVisitRecordsChartData } = this.props;
        const config = this.getReachStore();
        const isValid = mallVisitRecordsChartData.some((i) => i.value);
        return (
            <div className={chartClasses.chart}>
                <div className={chartClasses.chartTitle}>
                    <div className={chartClasses.titleText}>到店天数</div>
                    <div className={chartClasses.titleRight}>
                        <span className={chartClasses.titleRightText}>
                            {mallVisitRecordsChartTitle}
                        </span>
                    </div>
                </div>
                <div className={chartClasses.chartMain}>
                    <Column {...config} />
                    {this.renderEmpty(isValid)}
                </div>
            </div>
        );
    };

    render() {
        return (
            <div className={prefix}>
                {this.rednerHeader()}
                {this.renderExpenseRecord()}
                {this.renderReachStore()}
            </div>
        );
    }
}

const prefix = 'member-info-charts';
const chartClasses = {
    chart: `${prefix}__chart`,
    chartTitle: `${prefix}__chart__title`,
    titleText: `${prefix}__chart__title__text`,
    titleTextSecondary: `${prefix}__chart__title__text__secondary`,
    titleRight: `${prefix}__chart__title__right`,
    titleRightText: `${prefix}__chart__title__right__text`,
    chartMain: `${prefix}__chart__main`,
    chartMainEmpty: `${prefix}__chart__main__empty`,
};
