import React, { PureComponent } from 'react';
import { map, remove, isEmpty } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { errorHandle, language } from '@comall-backend-builder/core/lib/services';
import PropTypes from 'prop-types';
import { Button, notification, Table, Form, Input, Select, DatePicker } from 'antd';
import './index.less';
const { api } = services;

interface goodItem {
    barcode: string;
    brandName: string;
    categoryName: string;
    id: string;
    skuOwner: string;
    spuNo: string;
    subscribeMerchantId: string;
    subscribeMerchantName: string;
    subscribeSubsiteId: string;
    subscribeSubsitename: string;
    supplierId: string;
    tradeTypeName: string;
}
interface responseSuccess {
    page: number;
    perPage: number;
    result: goodItem[];
    totalNum: number;
    totalPage: number;
}
const tradeModes: Array<{ id: string; name: string }> = [
    {
        id: 'DOMESTIC_TRADE',
        name: 'domesticTrade',
    },
    {
        id: 'DUTY_PAID_IMPORT',
        name: 'dutyPaidImport',
    },
    {
        id: 'BONDED_DIRECT_SUPPLY',
        name: 'bondedDirectSupply',
    },
    {
        id: 'HONG_KONG_DIRECT',
        name: 'hongkongMail',
    },
    {
        id: 'OVERSEAS_DIRECT',
        name: 'overseasDirectMail',
    },
];

const CollectionCreateForm = Form.create({ name: 'searchForm' })(
    class extends React.Component {
        static propTypes: {
            onSearch: PropTypes.Requireable<(...args: any[]) => any>;
            onReset: any;
        };
        handleReset = () => {
            const { form, onReset } = this.props as any;
            form.resetFields();
            onReset();
        };
        handleSearch = (e: any) => {
            e.preventDefault();
            const { form, onSearch } = this.props as any;
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSearch(values);
                }
            });
        };
        render() {
            const { form } = this.props as any;
            const { getFieldDecorator } = form;
            return (
                <Form layout="inline" ref="searchFrom">
                    <Form.Item label={language.getText('components.Products.name')}>
                        {getFieldDecorator('spuName')(
                            <Input
                                style={{ width: '210px' }}
                                className="find-filter-width"
                                placeholder={language.getText('productNameRequired')}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('productCode')}>
                        {getFieldDecorator('spuNo')(
                            <Input
                                className="find-filter-width"
                                placeholder={language.getText('productSpuCodeRequired')}
                                style={{ width: '210px' }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('productBrand')}>
                        {getFieldDecorator('brandName')(
                            <Input
                                className="find-filter-width"
                                placeholder={language.getText('productBrandRequired')}
                                style={{ width: '180px' }}
                            />
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('tradeType')}>
                        {getFieldDecorator('tradeType')(
                            <Select
                                style={{ width: '180px' }}
                                placeholder={language.getText('selectPlease')}
                            >
                                {tradeModes.map((mode: { id: string; name: string }) => (
                                    <Select.Option key={mode.id} value={mode.id}>
                                        {language.getText(mode.name)}
                                    </Select.Option>
                                ))}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('subscribeTime')}>
                        {getFieldDecorator('dateRange')(
                            <DatePicker.RangePicker allowClear={true} />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.handleSearch}>
                            {language.getText('common.search')}
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="reset" onClick={this.handleReset}>
                            {language.getText('common.reset')}
                        </Button>
                    </Form.Item>
                </Form>
            );
        }
    }
);

export class UserSubscribesPage extends PureComponent {
    state = {
        datasource: [],
        searchParams: {},
        total: 0,
        current: 0,
        pagination: {
            page: 1,
            perPage: 10,
        },
        loading: false,
        selectedRows: new Array<goodItem>(),
        //防止按钮连续点击
        buttonDisabled: false,
    };

    componentDidMount() {
        this.onSearch();
    }

    onSearchFind = (searchParams: object = {}) => {
        this.setState(
            {
                pagination: {
                    page: 1,
                    perPage: 10,
                },
            },
            () => {
                this.onSearch(searchParams);
            }
        );
    };

    onSearch = async (searchParams: any = {}) => {
        if (searchParams && !isEmpty(searchParams.dateRange)) {
            let date = searchParams?.dateRange.map((i: any) => i.format('YYYY-MM-DD'));
            searchParams.startTime = date[0];
            searchParams.endTime = date[1];
            delete searchParams.dateRange;
        }
        this.setState({ searchParams: searchParams, loading: true });
        const { pagination } = this.state;
        try {
            let res: responseSuccess = await services.api.get(
                {
                    ...searchParams,
                    ...pagination,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                    apiPath: '/admin/subscribe_goods',
                }
            );
            if (res && res.result) {
                this.setState({
                    datasource: res.result,
                    total: res.totalNum,
                    current: res.page,
                    loading: false,
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            throw error;
        }
    };
    onReset = () => {
        this.setState({ searchParams: {} });
    };
    onClickUnSubscribe = (item: goodItem) => {
        const { buttonDisabled } = this.state;
        if (!buttonDisabled) {
            this.setState({
                buttonDisabled: true,
            });
            api.put(
                {
                    spuNo: item.spuNo,
                    skuOwner: item.skuOwner,
                    subscribeSubsiteId: item.subscribeSubsiteId,
                    supplierId: item.supplierId,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                    apiPath: '/admin/subscribe_goods',
                }
            )
                .then(() => {
                    const { searchParams } = this.state;
                    const config = {
                        message: language.getText('cancelSubscriptionSuc'),
                        description: '',
                        duration: 1,
                    };
                    notification.success(config);
                    setTimeout(() => {
                        this.onSearch(searchParams);
                    }, 500);
                })
                .catch(errorHandle);
            setTimeout(() => {
                this.setState({
                    buttonDisabled: false,
                });
            }, 1000);
        }
    };
    pageChange = (pageVal: object = {}) => {
        const { searchParams } = this.state;
        this.setState({ pagination: { ...pageVal } }, () => {
            this.onSearch(searchParams);
        });
    };
    onTableRowSelect = (record: goodItem, selected: boolean) => {
        const { selectedRows } = this.state;
        if (selected) {
            const rows = [...selectedRows];
            rows.push(record);
            this.setState({ selectedRows: rows });
        } else {
            const rows = [...selectedRows];
            remove(rows, (i) => {
                return i.id === record.id;
            });
            this.setState({ selectedRows: rows });
        }
    };
    onTableRowSelectAll = (selected: boolean, rows: goodItem[]) => {
        const { selectedRows, datasource } = this.state;
        const selectedRowIds = map(selectedRows, 'id');
        if (selected) {
            const addRows = rows.filter((item: goodItem) => {
                return !selectedRowIds.includes(item.id);
            });
            this.setState({ selectedRows: [...selectedRows, ...addRows] });
        } else {
            const deleteIds: Array<string> = map(datasource, 'id');
            const deleteRows = selectedRows.filter((item: goodItem) => {
                return !deleteIds.includes(item.id);
            });
            this.setState({ selectedRows: [...deleteRows] });
        }
    };
    render() {
        const { datasource, pagination, total, current, loading } = this.state;

        return (
            <div className="user-subscribes-page">
                <div style={{ marginBottom: 10 }}>
                    <CollectionCreateForm onReset={this.onReset} onSearch={this.onSearchFind} />
                </div>
                <Table
                    scroll={{ x: 1600 }}
                    // rowSelection={{
                    //     fixed: true,
                    //     type: 'checkbox',
                    //     selectedRowKeys: map(selectedRows, 'id'),
                    //     onSelect: (record: any, selected: boolean, selectedRow: any) => {
                    //         this.onTableRowSelect(record, selected);
                    //     },
                    //     onSelectAll: (selected: boolean, rows: any[]) => {
                    //         this.onTableRowSelectAll(selected, rows);
                    //     },
                    // }}
                    rowKey={(record: goodItem) => record.id}
                    loading={loading}
                    pagination={{
                        pageSize: pagination.perPage,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '30', '50', '100'],
                        total,
                        current,
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                        onChange: (page, perPage) => {
                            this.pageChange({ page, perPage });
                        },
                        onShowSizeChange: (page, perPage) => {
                            this.pageChange({ page, perPage });
                        },
                    }}
                    columns={[
                        {
                            title: language.getText('productSpu'),
                            dataIndex: 'spuNo',
                            key: 'spuNo',
                        },
                        {
                            title: language.getText('tradeType'),
                            dataIndex: 'tradeTypeName',
                            key: 'tradeTypeName',
                        },
                        {
                            title: language.getText('productBrand'),
                            key: 'brandName',
                            dataIndex: 'brandName',
                        },
                        {
                            title: services.language.getText('components.Products.name'),
                            dataIndex: 'goodsName',
                            key: 'goodsName',
                        },
                        {
                            title: language.getText('productCategory'),
                            dataIndex: 'categoryName',
                            key: 'categoryName',
                        },
                        {
                            title: language.getText('subscibeSubsite'),
                            key: 'subscribeSubsitename',
                            dataIndex: 'subscribeSubsitename',
                        },
                        {
                            title: language.getText('subscibeMerchant'),
                            key: 'subscribeMerchantName',
                            dataIndex: 'subscribeMerchantName',
                        },
                        {
                            title: language.getText('subscribeTime'),
                            key: 'createTime',
                            dataIndex: 'createTime',
                        },
                        {
                            title: services.language.getText('common.tableAction'),
                            className: 'action-column',
                            dataIndex: 'id',
                            key: 'id',
                            fixed: 'right',
                            width: 100,
                            render: (val: string, record: goodItem) => {
                                return (
                                    <Button
                                        type="link"
                                        onClick={() => this.onClickUnSubscribe(record)}
                                    >
                                        {language.getText('cancelSubscription')}
                                    </Button>
                                );
                            },
                        },
                    ]}
                    dataSource={datasource}
                />
            </div>
        );
    }
}
