import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
const api = services.api;
export const GoldExchangeRules: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`;
        config.apiPath = '/admin/gold_exchange_rules';
        let deductionConfig = {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
            apiPath: '/admin/gold_rule',
        };
        const res: any = await api.get(data, config);
        if (res) {
            res.baseInfo = {
                description: res.description,
                goldAlias: res.goldAlias ? res.goldAlias : 'Hi贝',
            };
            res.exchangeRule = {
                exchangeParkingPointValue: res.exchangeParkingPointValue,
                exchangePointValue: res.exchangePointValue,
            };
            res.clearRuleInfo = {
                clearRule: res.clearRule,
                expirationDate: res.expirationDate,
            };
            const deductionData: any = await api.get(data, deductionConfig);
            if (deductionData) {
                res.deductionRule = {
                    exchangeRule: {
                        exchangeRatio: deductionData.goldCoinCount,
                        exchangeAmount: deductionData.moneyValue,
                    },
                    description: deductionData.deductionDesc,
                };
            } else {
                res.deductionRule = {
                    exchangeRule: {
                        exchangeRatio: undefined,
                        exchangeAmount: undefined,
                    },
                    description: '',
                };
            }
        }
        return res;
    },
    put: async (params: any, config: ApiRequestConfig) => {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`;
        config.apiPath = '/admin/gold_exchange_rules';
        const { deductionRule } = params;
        const newParams = {
            ...params.baseInfo,
            ...params.exchangeRule,
            ...params.clearRuleInfo,
        };
        const deductionConfig = {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
            apiPath: '/admin/gold_rule',
        };
        const deductionParams = {
            goldCoinCount: parseInt(deductionRule?.exchangeRule?.exchangeRatio) || '',
            moneyValue: parseFloat(deductionRule?.exchangeRule?.exchangeAmount) || '',
            deductionDesc: deductionRule?.description || '',
        };
        await api.put(deductionParams, deductionConfig);

        return await api.put(newParams, config);
    },
};
