import * as React from 'react';
import { ArrayMode } from '@comall-backend-builder/types';
import { OpenInvoiceTabSequences } from './open-invoice-tab-sequences';

export class OpenInvoiceTabSequencesMode extends ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <OpenInvoiceTabSequences {...controlInfo} />;
    }
}
