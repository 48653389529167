import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { GiftProductSelect } from './gift-product-select';

export class GiftProductSelectMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <GiftProductSelect {...controlInfo} />;
    }
}
