import React, { PureComponent, createElement } from 'react';
import { defaultsDeep, set, assign } from 'lodash';
import { Modal, message as AntMessage } from 'antd';
import { ComponentsManager, builder, actions } from '@comall-backend-builder/core';

let SELECTED_ROW: any = [];

const getConfig = () => {
    return {
        component: 'Card',
        entity: 'MallActivityDesignManage',
        content: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'FilterForm',
                    componentId: 'DesignActivityPageFilterForm',
                    direction: 'inline',
                    fields: [
                        {
                            property: 'name',
                        },
                    ],
                    submit: {
                        icon: 'search',
                        text: '',
                    },
                    style: {
                        marginBottom: 16,
                        float: 'right',
                    },
                },
                {
                    component: 'DataTable',
                    componentId: 'DesignActivityPageDataTable',
                    loadFirstPage: true,
                    rowSelection: {
                        type: 'radio',
                        onChange: () => {},
                        onSelect: function() {
                            SELECTED_ROW = arguments[2];
                        },
                    },
                    pagination: {
                        pageSize: 10,
                    },
                    columns: [
                        {
                            property: 'name',
                        },
                    ],
                },
            ],
        },
    };
};

function initDynamicComponent(contentConfig: any) {
    const componentName = 'Design-Activity-Page-Panel';
    if (!ComponentsManager.has(componentName)) {
        let config = defaultsDeep(contentConfig, getConfig());
        set(config, 'content.items[1].componentId', 'DesignActivityPageDataTable');
        set(config, 'content.items[1].rowSelection', {
            type: 'radio',
            onChange: () => {},
            onSelect: function() {
                SELECTED_ROW = arguments[2];
            },
            onSelectAll: function() {
                SELECTED_ROW = arguments[2];
            },
        });
        ComponentsManager.register(componentName, config);
    }
}

/**
 * 可视化-配置链接-选择商城活动页
 */

export class SelectPage extends PureComponent<{
    contentConfig?: Record<string, any>;
    modalConfig?: Record<string, any>;
    onChange: (data: any) => void;
    onCancel: () => void;
    showModal: boolean;
}> {
    renderComponent: any = null;
    dispatch: any = null;

    constructor(props: any) {
        super(props);
        const { contentConfig } = props;
        initDynamicComponent(contentConfig);
        this.renderComponent = createElement(ComponentsManager.get('Design-Activity-Page-Panel'));
        this.dispatch = builder.getStore().dispatch;
    }

    onOk = () => {
        if (!SELECTED_ROW.length) {
            AntMessage.error('至少选择一个活动');
            return;
        }

        const { onChange } = this.props;
        let linkParams = {};
        if (SELECTED_ROW.length) {
            const row = SELECTED_ROW[0];
            linkParams = {
                id: row['id'],
                name: row['name'],
                subsites: JSON.stringify(row.subsites),
            };
        }
        onChange(linkParams);

        SELECTED_ROW = [];
        this.dispatch(actions.tableRowSelectionChangeAction('DesignActivityPageDataTable', []));
    };

    onCancel = () => {
        const { onCancel } = this.props;
        onCancel();
    };

    render() {
        const { modalConfig = {}, showModal } = this.props;
        let props = {
            onCancel: this.onCancel,
            onOk: this.onOk,
        };
        assign(props, { width: 800, visible: showModal, title: '请选择商城活动页' }, modalConfig);
        return <Modal {...props}>{this.renderComponent}</Modal>;
    }
}
