import React, { PureComponent } from 'react';
import { Popover, Tag } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services';

interface Item {
    id: string | number;
    name: string;
}

interface GroupPopoverProps {
    list?: Item[];
    countSuffix?: string;
    replaceEmpty?: string;
}

export class GroupPopover extends PureComponent<GroupPopoverProps, {}> {
    render() {
        const { list, countSuffix, replaceEmpty = '' } = this.props;
        if (!list?.length) {
            return replaceEmpty;
        }
        return (
            <div>
                {list[0].name}{' '}
                {list.length > 1 ? (
                    <Popover
                        placement={'right'}
                        content={
                            <div style={{ maxWidth: 400 }}>
                                {list.map((item) => (
                                    <Tag
                                        key={item.id}
                                        style={{
                                            maxWidth: 400,
                                            marginBottom: 10,
                                            whiteSpace: 'normal',
                                            wordBreak: 'break-word',
                                            wordWrap: 'break-word',
                                        }}
                                    >
                                        {item.name}
                                    </Tag>
                                ))}
                            </div>
                        }
                    >
                        <span className="group-tips">
                            {language.getText('inAll')}
                            {list.length}
                            {language.getText('ge')}
                            {countSuffix ? countSuffix : ''}
                        </span>
                    </Popover>
                ) : null}
            </div>
        );
    }
}
