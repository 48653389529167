import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';

import { LinkTypeEffectPathValue } from '../../components/link-type-effect-path';

export interface MiniprogramAddFormProps extends FormComponentProps {
    entity: Entity;
    applicationVersion?: string;
    getForm: () => any;
    onSubmit: (e: any, fields: any) => void;
}

class miniprogramAddForm extends PureComponent<MiniprogramAddFormProps> {
    static defaultProps: any = {};
    render() {
        const { entity, onSubmit, applicationVersion, wrappedComponentRef } = this.props;

        let fields = [
            {
                property: 'name',
            },
            {
                property: 'subsiteId',
            },
            {
                property: 'target',
                controlConfig: {
                    applicationVersion: applicationVersion,
                    onChange: (value: LinkTypeEffectPathValue, name: string) => {
                        const store = builder.getStore();

                        store.dispatch(
                            actions.formChangeAction('MiniprogramAddFormContainer', name, value)
                        );
                        store.dispatch(
                            actions.formChangeAction(
                                'MiniprogramAddFormContainer',
                                'url',
                                value.linkPath
                            )
                        );
                        store.dispatch(
                            actions.formStatusChangeAction(
                                'MiniprogramAddFormContainer',
                                'url',
                                true
                            )
                        );
                    },
                    style: {
                        width: 450,
                    },
                },
            },
            {
                property: 'url',
            },
            {
                property: 'channel',
            },
            {
                property: 'channelType',
            },
        ];

        let formProps = {
            entity,
            componentId: 'MiniprogramAddFormContainer',
            wrappedComponentRef,
            onSubmit,
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            style: { width: 820 },
            fields: fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('CreateForm'), formProps);
    }
}

export const MiniprogramAddFormView = connect()(miniprogramAddForm);
