import React, { PureComponent, createElement, Fragment } from 'react';
import { api, language } from '@comall-backend-builder/core/lib/services';
import { errorHandle } from '../../applications/cae/services/error-handle';
import PropTypes from 'prop-types';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { connect } from 'react-redux';
import { message as AntMessage, Modal, Form, Radio, Input } from 'antd';
import { get } from 'lodash';
import './index.less';
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const ReviewMallActivityForm = Form.create({ name: 'review' })(
    class extends React.Component {
        static propTypes: {
            onSubmit: PropTypes.Requireable<(...args: any[]) => any>;
        };
        handleReset = () => {
            const { form } = this.props as any;
            form.resetFields();
        };
        handleSubmit = (e: any) => {
            const { form, onSubmit } = this.props as any;
            form.validateFields((err: any, values: any) => {
                const { auditStatus, auditMark } = values;
                if (auditStatus === 'REJECTED' && !auditMark.trim()) {
                    return AntMessage.error('请填写审核不通过的原因~');
                }

                onSubmit(values);
            });
        };
        render() {
            const { form } = this.props as any;
            const { getFieldDecorator, getFieldValue } = form;
            return (
                <Form ref="reviewFrom" {...formItemLayout} onSubmit={this.handleSubmit}>
                    <FormItem label={'审核结果'}>
                        {getFieldDecorator('auditStatus', {
                            initialValue: 'APPROVED',
                            rules: [{ required: true }],
                        })(
                            <Radio.Group>
                                <Radio value={'APPROVED'}>审核通过</Radio>
                                <Radio value={'REJECTED'}>
                                    {services.language.getText('shbtg')}
                                </Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                    {getFieldValue('auditStatus') === 'REJECTED' && (
                        <FormItem label={'原因'}>
                            {getFieldDecorator('auditMark', {
                                initialValue: '',
                            })(
                                <Input.TextArea
                                    maxLength={500}
                                    placeholder="请填写审核不通过的原因，审核不通过的原因会展示给用户~   不超过500个字"
                                    autoSize={{ minRows: 3, maxRows: 6 }}
                                />
                            )}
                        </FormItem>
                    )}
                </Form>
            );
        }
    }
);

class ReviewMallActivityView extends PureComponent<any> {
    state = { visible: false, reviewInfo: {} };
    openModal = () => {
        this.setState({ visible: true });
        this.loadData();
    };

    loadData = async () => {
        const { id } = this.props.row;
        try {
            let reviewInfo: any = await api.get(
                {},
                {
                    apiPath: `/admin/mall_activities/registration_records/${id}/review`,
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
                }
            );
            if (get(reviewInfo, 'memberRegistration.joinDayStartTime')) {
                const startTime = reviewInfo.memberRegistration.joinDayStartTime.split(':');
                reviewInfo.memberRegistration.startTime = startTime[0] + ':' + startTime[1];
            }

            if (get(reviewInfo, 'memberRegistration.joinDayEndTime')) {
                const endTime = reviewInfo.memberRegistration.joinDayEndTime.split(':');
                reviewInfo.memberRegistration.endTime = endTime[0] + ':' + endTime[1];
            }
            this.setState({ reviewInfo });
        } catch (error) {
            errorHandle(error);
            throw error;
        }
    };
    onSubmit = async (value: any) => {
        const { id } = this.props.row;
        const { entity, params: entityParams } = this.props;

        try {
            await api.put(
                { ...value },
                {
                    apiPath: `/admin/mall_activities/registration_records/${id}/audit`,
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
                }
            );
        } catch (error) {
            errorHandle(error);
            throw error;
        }
        AntMessage.success(services.language.getText('actionSuccess'));
        entity && entity.search(entityParams);
        this.listenRef.handleReset();
        this.setState({ visible: false });
    };
    //@ts-ignore
    listenRef: {
        handleSubmit: () => Promise<any>;
        handleReset: () => Promise<any>;
    };
    render() {
        const { triggerComponent, row } = this.props;
        const { reviewInfo, visible } = this.state;
        const buttonProps = {
            ...triggerComponent,
            onClick: this.openModal,
        };

        let extension = null;
        if (get(reviewInfo, 'memberRegistration.extension')) {
            extension = JSON.parse(get(reviewInfo, 'memberRegistration.extension'));
        }

        const showAudit = row.status === 'WAIT_AUDIT';

        return (
            <Fragment>
                {showAudit ? createElement(ComponentsManager.get('Button'), buttonProps) : null}
                <Modal
                    visible={visible}
                    className="review-activity-view"
                    title="报名审核"
                    mask={false}
                    maskClosable
                    okText={language.getText('common.ok')}
                    cancelText={language.getText('common.cancel')}
                    width="580px"
                    onOk={() => {
                        this.listenRef.handleSubmit();
                    }}
                    onCancel={() => {
                        this.listenRef.handleReset();
                        this.setState({ visible: false });
                    }}
                >
                    <div className="between row">
                        <span>下单人手机号：{get(reviewInfo, 'mobile') || ''}</span>
                        <span>下单人卡等级：{get(reviewInfo, 'levelName') || ''}</span>
                    </div>
                    <div className="between row">
                        <span>
                            参与者手机号：{get(reviewInfo, 'memberRegistration.mobile') || ''}
                        </span>
                        <span>参与者姓名：{get(reviewInfo, 'memberRegistration.name') || ''}</span>
                    </div>
                    <div className="row">
                        参与时间：{get(reviewInfo, 'memberRegistration.joinDate') || ''}{' '}
                        {get(reviewInfo, 'memberRegistration.startTime') || ''}-
                        {get(reviewInfo, 'memberRegistration.endTime') || ''}
                    </div>
                    {extension &&
                        extension.map((item: any) => {
                            return (
                                <>
                                    <div className="row">
                                        {item.title}：
                                        {typeof item.value === 'string'
                                            ? item.value
                                            : item.value.join('、')}
                                    </div>
                                </>
                            );
                        })}
                    <div className="between row">
                        <span>
                            下单人报名成功次数：
                            {typeof get(reviewInfo, 'registrationCount') === 'number'
                                ? get(reviewInfo, 'registrationCount')
                                : ''}
                        </span>
                        <span>
                            未参与活动次数：
                            {typeof get(reviewInfo, 'unJoinCount') === 'number'
                                ? get(reviewInfo, 'unJoinCount')
                                : ''}
                        </span>
                    </div>
                    <ReviewMallActivityForm
                        wrappedComponentRef={(ref: any) => (this.listenRef = ref)}
                        onSubmit={this.onSubmit}
                    />
                </Modal>
            </Fragment>
        );
    }
}

function mapStateToProps(_state: any, props: any) {
    const { entity } = props;
    return { fields: entity.fields, entity };
}
export const ReviewMagicActivityButton = connect(mapStateToProps, null)(ReviewMallActivityView);
