import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import isNull from 'lodash/isNull';
import { isArray } from 'lodash';

export const config: Config = {
    entities: {
        SharePageActivityEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/share_page_activities',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'UN_START', name: '<<notStarted>>' },
                        { id: 'RUNNING', name: '<<inprogress>>' },
                        { id: 'END', name: '<<end>>' },
                    ],
                },
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                endTime: {
                    type: 'string',
                    displayName: '<<endTime>>',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string',
                    displayName: '<<startTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherBaseInfo>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [{ required: true, whitespace: true, message: '<<qsrhdmc>>' }],
                            controlConfig: {
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            defaultValue: [],
                            rules: [{ required: true, message: '<<selectSubsite>>' }],
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],

                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.baseInfo?.subsites?.length &&
                                        row.baseInfo.subsites.map((item: any) => item.id).join(',');
                                    return { subsiteIds };
                                },
                            },
                        },
                    },
                },
                ruleInfo: {
                    type: 'object.subForm',
                    displayName: '<<gzxx>>',
                    properties: {
                        pageInfo: {
                            type: 'object.TopicSelector',
                            displayName: '<<xzfxzt>>',
                            rules: [{ required: true, message: '<<qxzfxzt>>' }],
                            extra: '<<qxzzcfxdzty>>',
                        },
                        rewards: {
                            type: 'array.sharePageActivityReward',
                            displayName: '<<xzjlxw>>',
                            rules: [{ required: true, message: '<<qszjlxw>>' }],
                        },
                    },
                },
                pageConfig: {
                    type: 'object.subForm',
                    displayName: '<<pageDecoration>>',
                    properties: {
                        backgroundPicture: {
                            displayName: '<<hdbjt>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://puzhehei.oss-cn-beijing.aliyuncs.com/global/magic-backend/share-page-activity/default-background.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'single',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<picLimit2540>>',
                        },
                        description: {
                            displayName: '<<activityRule>>',
                            type: 'string.richTextPlus',
                            rules: [{ max: 20000, message: '<<notMoreThan2000>>' }],
                            controlConfig: {
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        SharePageActivityView: {
            component: 'Viewport',
            entity: 'SharePageActivityEntity',
        },
        SharePageActivityPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'SharePageActivityFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/share-page-activity/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'SharePageActivityTable' },
            ],
        },
        SharePageActivityFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 180 },
                    },
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: { width: 320 },
                    },
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'subsiteIds',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
            ],
        },
        SharePageActivityTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: {
                x: 1300,
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'startTime',
                    width: 200,
                },
                {
                    property: 'endTime',
                    width: 200,
                },
                {
                    property: 'subsites',
                    width: 150,
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            UN_START: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            RUNNING: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            END: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                    width: 120,
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 220,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: 'DISABLED' },
                                    value: 'ENABLED',
                                    apiRoot: `${ENV.AUTH_API_ROOT}`,
                                    apiPath:
                                        '/AFFILIATE-MARKETING/admin/share_page_activities/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isCloseActivity>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: 'ENABLED' },
                                    value: 'DISABLED',
                                    apiRoot: `${ENV.AUTH_API_ROOT}`,
                                    apiPath:
                                        '/AFFILIATE-MARKETING/admin/share_page_activities/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.view>>',
                        path: '/menu-list/market/share-page-activity/info/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'ExportHandlerButton',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath:
                                '/admin/share_page_activities/{{row.id}}/reward_records/export',
                        },
                    },
                ],
            },
        },
        SharePageActivityAddPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'SharePageActivityEntity',
            items: [
                { component: 'SharePageActivityPreview' },
                { component: 'SharePageActivityAddForm' },
            ],
        },
        SharePageActivityAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.dateRange' },
                { property: 'baseInfo.subsites' },
                { property: 'baseInfo.marketingSchedule' },
                { property: 'ruleInfo.pageInfo' },
                { property: 'ruleInfo.rewards' },
                { property: 'pageConfig.backgroundPicture' },
                { property: 'pageConfig.description' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                // 根据门店/专柜选择
                const rewards = entity.ruleInfo.rewards;
                if (!rewards || rewards.length === 0) {
                    AntMessage.warn(services.language.getText('qszjlxw'));
                    return false;
                }
                const picture = entity.pageConfig.backgroundPicture;
                if (!picture || !picture.length) {
                    AntMessage.warn(services.language.getText('qschdbjtp'));
                    return false;
                }
                let valid = true;
                let validTip = '';
                for (let i = 0; i < rewards.length; i++) {
                    if (!rewards[i].rewardItem.stepValue) {
                        validTip = services.language.getText('qszjltj');
                        valid = false;
                        break;
                    }
                    if (isNull(rewards[i].rewardItem.point)) {
                        validTip = services.language.getText('qwztxjljf');
                        valid = false;
                        break;
                    }
                    if (
                        isNull(rewards[i].rewardItem.coupons) ||
                        (isArray(rewards[i].rewardItem.coupons) &&
                            rewards[i].rewardItem.coupons.length === 0)
                    ) {
                        validTip = services.language.getText('qxzyhq');
                        valid = false;
                        break;
                    }
                    if (!rewards[i].rewardItem.point && !rewards[i].rewardItem.coupons) {
                        validTip = services.language.getText('zsxzyzjl');
                        valid = false;
                        break;
                    }
                    if (isNull(rewards[i].activityRewardLimit)) {
                        if (!rewards[i].activityPeriod) {
                            validTip = services.language.getText('qxzjlcsxzzq');
                            valid = false;
                            break;
                        }
                        if (!rewards[i].activityPeriodRewardLimit) {
                            validTip = services.language.getText('qszjlcsxz');
                            valid = false;
                            break;
                        }
                    }
                    if (
                        isNull(rewards[i].activityPeriod) &&
                        isNull(rewards[i].activityPeriodRewardLimit)
                    ) {
                        if (!rewards[i].activityRewardLimit) {
                            validTip = services.language.getText('qszjlcsxz');
                            valid = false;
                            break;
                        }
                    }
                }
                if (!valid) {
                    AntMessage.warn(validTip);
                    return false;
                }

                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        SharePageActivityDetailPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'SharePageActivityEntity',
            loaderType: 'get',
            items: [
                { component: 'SharePageActivityPreview' },
                { component: 'SharePageActivityDetailView' },
            ],
        },
        SharePageActivityDetailView: {
            component: 'ModifyFormPlus',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name', modifiable: false },
                { property: 'baseInfo.dateRange', modifiable: false },
                { property: 'baseInfo.subsites', modifiable: false },
                { property: 'baseInfo.marketingSchedule', modifiable: false },
                { property: 'ruleInfo.pageInfo', modifiable: false },
                { property: 'ruleInfo.rewards', modifiable: false },
                { property: 'pageConfig.backgroundPicture', modifiable: false },
                { property: 'pageConfig.description', modifiable: false },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'primary',
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/share-page-activity',
                    component: 'SharePageActivityView',
                    breadcrumbName: '<<fxyl>>',
                    privilege: {
                        path: 'sharePageActivity',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'SharePageActivityAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/info/:id',
                            component: 'SharePageActivityDetailPage',
                            breadcrumbName: '<<hdxq>>',
                        },
                        { path: '/', component: 'SharePageActivityPage' },
                    ],
                },
            ],
        },
    ],
};
