import React, { useCallback } from 'react';
import { copyToClipboard } from '../../services/utils';
import { Button, Col, Icon, Popover, Row } from 'antd';
import { services } from '@comall-backend-builder/core';

export interface PaymentRemittanceRecordVO {
    /**
     * 申请汇款时间
     */
    applyRemittanceTime: string;
    /**
     * 汇款记录Id
     */
    id: string;
    /**
     * 订单编号
     */
    orderNo: string;
    /**
     * 三方订单号
     */
    outOrderNo: string;
    /**
     * 支付完成时间
     */
    paidTime: string;
    /**
     * 汇款备注
     */
    remark: string;
    /**
     * 汇款金额
     */
    remittanceAmount: string;
    /**
     * 汇款状态【PENDING:待处理,PROCESSING:处理中,SUCCESS:成功,FAIL:失败】
     */
    remittanceStatus: 'PENDING' | 'PROCESSING' | 'SUCCESS' | 'FAIL';
    subsite: {
        /**
         * 门店Id
         */
        subsiteId: string;
        /**
         * 门店名称
         */
        subsiteName: string;
    };
}
export function PaymentRemittanceOrderListItem(props: PaymentRemittanceRecordVO) {
    const {
        orderNo,
        outOrderNo,
        paidTime,
        remittanceStatus,
        remittanceAmount,
        subsite: { subsiteName },
        applyRemittanceTime,
        remark,
    } = props;
    const onCopyOrderNo = useCallback(() => {
        copyToClipboard(orderNo);
    }, [orderNo]);
    const onCopyOutOrderNo = useCallback(() => {
        copyToClipboard(outOrderNo);
    }, [outOrderNo]);

    return (
        <div style={{ overflow: 'hidden' }}>
            <Col>
                <Row
                    gutter={[24, 24]}
                    style={{ backgroundColor: '#eee' }}
                    type="flex"
                    align="middle"
                >
                    <Col span={8}>
                        {services.language.getText('orderNumber')}:
                        <Button type="link" onClick={onCopyOrderNo}>
                            {orderNo}
                            <Icon type="copy" />
                        </Button>
                    </Col>
                    <Col span={8}>
                        外部订单号:
                        {outOrderNo ? (
                            <Button type="link" onClick={onCopyOutOrderNo}>
                                {outOrderNo}
                                <Icon type="copy" />
                            </Button>
                        ) : null}
                    </Col>
                    <Col span={4}>门店: {subsiteName}</Col>
                    <Col span={4}>
                        {services.language.getText('hkzt')}:{' '}
                        {getRemittanceStateLabel(remittanceStatus)}
                        {remark && remittanceStatus === 'FAIL' ? (
                            <Popover content={remark}>
                                &nbsp;&nbsp;
                                <Icon type="question-circle" />
                            </Popover>
                        ) : null}
                    </Col>
                </Row>
                <Row
                    gutter={[24, 24]}
                    type="flex"
                    align="middle"
                    style={{ backgroundColor: '#fff' }}
                >
                    <Col span={8}>支付时间: {paidTime}</Col>
                    <Col span={8}>
                        汇款金额（{services.language.getText('yuan')}）: {remittanceAmount}
                    </Col>
                    <Col span={8}>
                        {services.language.getText('remittanceTime')}: {applyRemittanceTime}
                    </Col>
                </Row>
            </Col>
        </div>
    );
}

function getRemittanceStateLabel(state: 'PENDING' | 'PROCESSING' | 'SUCCESS' | 'FAIL') {
    return (
        {
            PENDING: '待处理',
            PROCESSING: '处理中',
            SUCCESS: '成功',
            FAIL: '失败',
        }[state] || '未知'
    );
}
