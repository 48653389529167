import React, { PureComponent } from 'react';
export interface PageTipProps {
    props?: any;
}

/**
 * 支持多个提示文本展示
 */
export class PageTip extends PureComponent<PageTipProps> {
    render() {
        const { props } = this.props;
        const { style, tips, tipStyle } = props;
        return (
            <div style={style}>
                {tips &&
                    tips.map((tip: any) => {
                        return <div style={tipStyle}>{tip}</div>;
                    })}
            </div>
        );
    }
}
