import React, { PureComponent } from 'react';
import { Cascader, Input } from 'antd';
import { services } from '@comall-backend-builder/core';

const { api } = services;

export interface RegionSelectSelectStates {
    options: any;
}
interface RegionSelectValue {
    //当前选中的地区层级名称数组
    regionNames?: any;
    //当前选中的地区层级数组
    regionIds?: any;
    //详细地址
    address?: string;
    // 是否还有子级
    hasChildren?: boolean;
}
export interface RegionOption {
    hasChild: boolean;
    id: number;
    level: number;
    name: string;
    parentId: number;
    parentPath: string;
    sequence: number;
}
export class RegionSelectSelect extends PureComponent<
    {
        disabled: boolean | undefined;
        value: RegionSelectValue;
        onChange: (value: RegionSelectValue | undefined) => void;
        style: any;
        showAddressInput: boolean;
    },
    RegionSelectSelectStates
> {
    constructor(props: any) {
        super(props);
        this.state = {
            options: [],
        };
    }
    async componentDidMount() {
        const { value } = this.props;
        if (value && value.regionIds) {
            this.loadAllData(value.regionIds);
        } else {
            const res = await this.loadData(0);
            this.setState({
                options: this.format(res),
            });
        }
    }

    componentWillReceiveProps(nextProps: any) {
        const { value } = this.props;
        const { regionIds = [] } = value || {};
        const { value: nextValue } = nextProps;
        const { regionIds: nextRegionIds = [] } = nextValue || {};
        if (regionIds.length === 0 && nextRegionIds.length > 0) {
            this.loadAllData(nextProps.value.regionIds);
        }
    }

    loadData = (id: number) => {
        return api.get<RegionOption[]>(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: `/admin/regions/${id}/children`,
            }
        );
    };

    loadAllData = async (ids: number[]) => {
        const [parent, ...children] = await Promise.all([
            this.loadData(0),
            ...ids.map((id) => this.loadData(id)),
        ]);
        const options = this.format(parent).map((item: any) => {
            return this.getRegionTree(item, 0, ids, children);
        });
        this.setState({
            options,
        });
    };

    getRegionTree = (item: any, index: number, ids: number[], children: RegionOption[][]): any => {
        if (item.value === ids[index]) {
            const current = this.format(children[index]);
            item.children = current.map((el) => {
                return this.getRegionTree(el, index + 1, ids, children);
            });
            return item;
        } else {
            return item;
        }
    };

    format = (data: RegionOption[]) => {
        return data.map((region) => ({
            value: region.id,
            label: region.name,
            isLeaf: !region.hasChild,
            loading: false,
        }));
    };
    onLoadData = async (selectedOptions: any) => {
        const { options } = this.state;
        const targetOption = selectedOptions[selectedOptions.length - 1];
        targetOption.loading = true;
        const res = await this.loadData(targetOption.value);
        const childrenOption = this.format(res);
        targetOption.children = childrenOption;
        this.setState(
            {
                options: [...options],
            },
            () => {
                targetOption.loading = false;
            }
        );
    };

    onRegionChange = (regionIds: any[], selectedOptions?: any[] | undefined) => {
        let { onChange, value } = this.props;
        if (!value) {
            value = {};
        }
        if (regionIds && regionIds.length === 0) {
            value.regionIds = undefined;
            value.hasChildren = true;
        } else {
            value.regionIds = regionIds;
            value.regionNames =
                selectedOptions && selectedOptions.length
                    ? selectedOptions.map((i) => i.label)
                    : [];
            value.hasChildren = true;
            if (selectedOptions && selectedOptions.length) {
                value.hasChildren = !selectedOptions[selectedOptions.length - 1].isLeaf;
            }
        }
        onChange && onChange(value);
    };

    onAddressChange = (e: any) => {
        let { onChange, value } = this.props;
        if (!value) {
            value = {};
        }
        value.address = e.target.value;
        onChange && onChange(value);
    };

    render() {
        const { options } = this.state;
        const { value, style, showAddressInput } = this.props;
        return (
            <div>
                <Cascader
                    value={value?.regionIds}
                    options={options}
                    loadData={this.onLoadData}
                    onChange={this.onRegionChange}
                    style={style}
                    changeOnSelect
                    placeholder="请选择地区"
                />
                {showAddressInput && (
                    <div>
                        <Input
                            style={style}
                            value={value?.address}
                            placeholder="请输入详细地址"
                            maxLength={50}
                            onInput={this.onAddressChange}
                        ></Input>
                    </div>
                )}
            </div>
        );
    }
}
