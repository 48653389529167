import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { StoredValueCardAmountDefinitions } from './stored-value-card-amount-definitions';

export class ArrayStoredValueCardAmountDefinitionsMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <StoredValueCardAmountDefinitions {...displayInfo} />;
    }
}
