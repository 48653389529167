import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const InternalPurchaseEnterpriseLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        let paramsFilter = config.paramsFilter;

        let isEmployees =
            paramsFilter && paramsFilter.length > 0 && paramsFilter[0] === 'employees';
        if (data.id && isEmployees) {
            delete config.paramsFilter;
            config.apiPath = '/admin/enterprises/:id/employees';
        }

        let statusFilter = paramsFilter && paramsFilter.length > 0 && paramsFilter[0] === 'status';
        if (statusFilter) {
            data = { status: true };
        }
        return api.get(data, config).then((res: any) => {
            if (!data.id) {
                res.forEach((item: any) => {
                    item.employeeCountLinkObj = {
                        text: item.employeeCount ? item.employeeCount : '0',
                        url:
                            '#/menu-list/market/internal-purchase/enterprise/' +
                            item.id +
                            '/employees',
                    };
                    item.status = item.status + '';
                    item.id = item.id + '';
                    item.canManageRelationMobile =
                        item.certificationPattern &&
                        item.certificationPattern.indexOf('MOBILE') > -1;
                });
                return res;
            } else if (isEmployees) {
                return res;
            } else {
                if (res.mailboxSuffixes && res.mailboxSuffixes.length > 0) {
                    res.mailboxSuffixes = res.mailboxSuffixes.map((item: any) => {
                        if (item.indexOf('@') === 0) {
                            return (item = item.substring(1));
                        } else {
                            return item;
                        }
                    });
                }
                return res;
            }
        });
    },
    post: async function(data: any, config: any) {
        if (data.certificationInterval === 0) {
            data.certificationInterval = null;
        }
        data.mailboxSuffixes =
            data.mailboxSuffixes &&
            data.mailboxSuffixes.map((item: any) => {
                return (item = '@' + item);
            });
        return await api.post(data, config);
    },
    put: async function(data: any, config: any) {
        if (data.certificationInterval === 0) {
            data.certificationInterval = null;
        }
        data.mailboxSuffixes =
            data.mailboxSuffixes &&
            data.mailboxSuffixes.map((item: any) => {
                return (item = '@' + item);
            });
        return await api.put(data, config);
    },
};
