export enum ModuleType {
    MyVehicle = 'MY_VEHICLE',
    QueryPayment = 'QUERY_PAYMENT',
    CarparkOrder = 'CARPARK_ORDER',
    FindVehicle = 'FIND_VEHICLE',
    CarparkOrderInvoice = 'CARPARK_ORDER_INVOICE',
    BookParkingSpace = 'BOOK_PARKING_SPACE',
    Custom = 'CUSTOM',
}

export const moduleNames = {
    [ModuleType.MyVehicle]: 'wdcl',
    [ModuleType.QueryPayment]: 'cxjf',
    [ModuleType.CarparkOrder]: 'carparkOrder',
    [ModuleType.FindVehicle]: 'xzcl',
    [ModuleType.CarparkOrderInvoice]: 'kjfp',
    [ModuleType.BookParkingSpace]: 'bookingParking',
    [ModuleType.Custom]: 'noNamed',
};
export const moduleTips = {
    [ModuleType.MyVehicle]: '',
    [ModuleType.QueryPayment]: '',
    [ModuleType.CarparkOrder]: '',
    [ModuleType.FindVehicle]: '',
    [ModuleType.CarparkOrderInvoice]: 'pzkjfpxszkjfs',
    [ModuleType.BookParkingSpace]: '',
    [ModuleType.Custom]: '',
};
export const moduleDefaultIcon = {
    [ModuleType.MyVehicle]:
        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/carpark/my_vehicle.png',
    [ModuleType.QueryPayment]:
        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/carpark/query_payment.png',
    [ModuleType.CarparkOrder]:
        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/carpark/carpark_order.png',
    [ModuleType.FindVehicle]:
        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/carpark/find_vehicle.png',
    [ModuleType.CarparkOrderInvoice]:
        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/carpark/carpark_order_invoice.png',
    [ModuleType.BookParkingSpace]:
        'https://puzhehei.oss-cn-beijing.aliyuncs.com/global/book_parking_space.jpg',
    [ModuleType.Custom]: '',
};
export const moduleDefaultIconSimplicity = {
    [ModuleType.MyVehicle]:
        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/carpark/my_vehicle_simplicity.png',
    [ModuleType.QueryPayment]:
        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/carpark/query_payment_simplicity.png',
    [ModuleType.CarparkOrder]:
        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/carpark/carpark_order_simplicity.png',
    [ModuleType.FindVehicle]:
        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/carpark/find_vehicle_simplicity.png',
    [ModuleType.CarparkOrderInvoice]:
        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/carpark/carpark_order_invoice_simplicity.png',
    [ModuleType.BookParkingSpace]:
        'https://puzhehei.oss-cn-beijing.aliyuncs.com/global/book_parking_space_simplicity.jpg',
    [ModuleType.Custom]: '',
};

export interface Menu<T> {
    moduleType: ModuleType;
    picture?: string;
    visible: boolean;
    config: T;
    name?: string;
}

export const myCars: Menu<null> = {
    moduleType: ModuleType.MyVehicle,
    visible: true,
    config: null,
};
export const checkout: Menu<null> = {
    moduleType: ModuleType.QueryPayment,
    visible: false,
    config: null,
};
export const orderRecords: Menu<null> = {
    moduleType: ModuleType.CarparkOrder,
    visible: true,
    config: null,
};
export enum InvoiceChannelType {
    INTERNAL_INVOICE = 'INTERNAL_INVOICE',
    EXTERNAL_INVOICE = 'EXTERNAL_INVOICE',
}
export enum FindVehicleType {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
}
export enum BookParkingSpaceType {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
}
export enum VisibilitySetting {
    UNRESTRICTED = 'UNRESTRICTED',
    VISIBLE_MEMBER_CARD_LEVEL = 'VISIBLE_MEMBER_CARD_LEVEL',
}
export type CarparkOrderInvoiceConfig = { channel: InvoiceChannelType; content?: string };
export type FindVehicleConfig = { type: FindVehicleType; content?: string };
export type BookParkingSpaceConfig = {
    type: BookParkingSpaceType;
    content?: string;
    visibilitySetting: VisibilitySetting;
    visibleMemberCardLevel?: string[];
};
export const carparkOrderInvoice: Menu<CarparkOrderInvoiceConfig> = {
    moduleType: ModuleType.CarparkOrderInvoice,
    // sequence: 4,
    visible: false,
    config: {
        channel: InvoiceChannelType.INTERNAL_INVOICE,
    },
};
export const findVehicle: Menu<FindVehicleConfig> = {
    moduleType: ModuleType.FindVehicle,
    visible: false,
    config: {
        type: FindVehicleType.INTERNAL,
    },
};
export const bookParkingSpace: Menu<BookParkingSpaceConfig> = {
    moduleType: ModuleType.BookParkingSpace,
    visible: false,
    config: {
        type: BookParkingSpaceType.EXTERNAL,
        visibilitySetting: VisibilitySetting.UNRESTRICTED,
    },
};
export type CustomMenuConfig = {
    type: BookParkingSpaceType;
    content?: string;
    visibilitySetting: VisibilitySetting;
    visibleMemberCardLevel?: string[];
};
export const customMenu: Menu<CustomMenuConfig> = {
    moduleType: ModuleType.BookParkingSpace,
    visible: false,
    name: '',
    config: {
        type: BookParkingSpaceType.EXTERNAL,
        visibilitySetting: VisibilitySetting.UNRESTRICTED,
    },
};
export const defaultValue = [
    myCars,
    checkout,
    orderRecords,
    findVehicle,
    carparkOrderInvoice,
    bookParkingSpace,
];
export type Value = typeof defaultValue;
