import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach, isEmpty } from 'lodash';

const api = services.api;
export const MerchantBigCodesLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = '/admin/deduction_merchant_big_codes';
        return await api.get(data, config).then((res: any) => {
            if (res && !isEmpty(res.result)) {
                forEach(res.result, (item) => {
                    //自营专柜展示-
                    item.merchantName = item.merchantId === 0 ? '-' : item.merchantName;
                    item.name = item.name || '';
                });
            }
            return res;
        });
    },
    delete: async function(params: any, config: any) {
        let ids = '';
        if (params.id) {
            ids = params.id;
        } else {
            ids = params.ids.join(',');
        }
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = `/admin/deduction_merchant_big_codes?ids=${ids}`;
        return await api.delete(params, config);
    },
};
