import React, { PureComponent } from 'react';
import { ActivitySelectedDisplay, ActivitySelector } from '../../components';

export class MallActivitySelect extends PureComponent<
    any,
    {
        value: Array<any>;
    }
> {
    state = { value: new Array<any>() };

    /**
     * 选择分站
     * @param selectValues
     */
    onChange = (selectValues: any[]) => {
        const { onChange } = this.props;
        if (onChange) {
            if (selectValues && selectValues.length === 0) {
                onChange(undefined);
            } else {
                onChange(selectValues);
            }
        }
    };

    /**
     * 添加分站弹窗确认后回调
     * @param selectValues 选中分站
     */
    onSelect = (selectValues: any[]) => {
        this.onChange(selectValues);
    };

    render() {
        const { value, disabled } = this.props;
        return (
            <div>
                {!disabled && <ActivitySelector onChange={this.onSelect} selectValues={value} />}
                <ActivitySelectedDisplay
                    data={value}
                    onRemove={this.onChange}
                    disabled={disabled}
                ></ActivitySelectedDisplay>
            </div>
        );
    }
}
