import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { mapValues, get, defaults, debounce, trim, forEach } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import {
    AddressType,
    MerchantSelfType,
} from '../../config/merchant/merchant-manager/merchant-self';
import { RegionOption } from '../../types/mode/array/array-regions-selector/regions-select';
import { language } from '@comall-backend-builder/core/lib/services';

const { api } = services;

export interface MerchantSelfEditPageFormViewProps extends FormComponentProps {
    entity: Entity;
    fields: any;
    getForm: () => any;
    params: any;
    location: any;
    onSubmit: (e: any, fields: any) => void;
    type: any;
    unmountComponent: () => void;
}
class MerchantSelfEditPageFormView extends PureComponent<MerchantSelfEditPageFormViewProps> {
    state = { haveShow: false };

    componentDidMount() {
        const apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        const apiPath = `/admin/tenant_configs/have_show`;
        api.get({}, { apiRoot, apiPath }).then((res: any) => {
            if (res) {
                this.setState({ haveShow: true });
            }
        });
    }
    componentWillUnmount() {
        this.props.unmountComponent();
    }

    render() {
        const { haveShow } = this.state;
        const { entity, wrappedComponentRef, onSubmit } = this.props;

        let formProps = {
            entity,
            componentId: 'MerchantSelfEditPageFormContainer',
            wrappedComponentRef,
            onSubmit,
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            fields: [
                {
                    property: 'baseInfo.subsite',
                    modifiable: false,
                },
                {
                    property: 'baseInfo.name',
                },
                {
                    property: 'baseInfo.nameEn',
                },
                {
                    property: 'baseInfo.code',
                },
                {
                    property: 'baseInfo.type',
                    visible: (values: any) => {
                        return get(values, 'baseInfo.type') !== MerchantSelfType.SELF;
                    },
                },
                {
                    property: 'baseInfo.selfType',
                    visible: (values: any) => {
                        return get(values, 'baseInfo.type') === MerchantSelfType.SELF;
                    },
                },
                {
                    property: 'baseInfo.logoPicture',
                },
                {
                    property: 'baseInfo.propagatePicture',
                },
                {
                    property: 'baseInfo.customerPhone',
                },
                {
                    property: 'baseInfo.companyIntroduce',
                },
                {
                    property: 'baseInfo.address',
                },
                {
                    property: 'baseInfo.brands',
                },
                {
                    property: 'baseInfo.tagIds',
                },
                {
                    property: 'baseInfo.styleIds',
                    visible: (values: any) => {
                        return haveShow;
                    },
                },
                {
                    property: 'baseInfo.sequence',
                },
                {
                    property: 'baseInfo.showSid',
                },
                {
                    property: 'baseInfo.floors',
                },

                {
                    property: 'manageSetting.categoryIds',
                },
                {
                    property: 'manageSetting.industryId',
                },
                {
                    property: 'manageSetting.allowQuickBilling',
                    className: 'hidden',
                },
                {
                    property: 'manageSetting.allowScanCashier',
                },
                {
                    property: 'manageSetting.restaurantReservation',
                    visible: (values: any) => {
                        const industryId = get(values, 'manageSetting.industryId');
                        return industryId && industryId.code === 'Catering' ? true : false;
                    },
                },
                {
                    property: 'manageSetting.description',
                    visible: (values: any) => {
                        const industryId = get(values, 'manageSetting.industryId');
                        const restaurantReservation = get(
                            values,
                            'manageSetting.restaurantReservation'
                        );
                        return industryId &&
                            industryId.code === 'Catering' &&
                            restaurantReservation &&
                            restaurantReservation.enable
                            ? true
                            : false;
                    },
                },
                {
                    property: 'manageSetting.deliveryLogisticsSenderAddressEnum',
                },
                {
                    property: 'manageSetting.deliveryLogisticsSenderName',
                    visible: (values: any) => {
                        return (
                            get(
                                values,
                                'manageSetting.deliveryLogisticsSenderAddressEnum',
                                AddressType.SUBSITE_ADDRESS
                            ) === AddressType.MERCHANT_ADDRESS
                        );
                    },
                },
                {
                    property: 'manageSetting.deliveryLogisticsSenderContactNumber',
                    visible: (values: any) => {
                        return (
                            get(
                                values,
                                'manageSetting.deliveryLogisticsSenderAddressEnum',
                                AddressType.SUBSITE_ADDRESS
                            ) === AddressType.MERCHANT_ADDRESS
                        );
                    },
                },
                {
                    property: 'manageSetting.deliveryLogisticsSenderRegionId',
                    visible: (values: any) => {
                        return (
                            get(
                                values,
                                'manageSetting.deliveryLogisticsSenderAddressEnum',
                                AddressType.SUBSITE_ADDRESS
                            ) === AddressType.MERCHANT_ADDRESS
                        );
                    },
                },
                {
                    property: 'manageSetting.deliveryLogisticsSenderAddress',
                    visible: (values: any) => {
                        return (
                            get(
                                values,
                                'manageSetting.deliveryLogisticsSenderAddressEnum',
                                AddressType.SUBSITE_ADDRESS
                            ) === AddressType.MERCHANT_ADDRESS
                        );
                    },
                },
                {
                    property: 'manageSetting.addressEnum',
                },
                {
                    property: 'manageSetting.afterSalesContacts',
                    visible: (values: any) => {
                        return (
                            get(
                                values,
                                'manageSetting.addressEnum',
                                AddressType.SUBSITE_ADDRESS
                            ) === AddressType.MERCHANT_ADDRESS
                        );
                    },
                },
                {
                    property: 'manageSetting.afterSalesPhone',
                    visible: (values: any) => {
                        return (
                            get(
                                values,
                                'manageSetting.addressEnum',
                                AddressType.SUBSITE_ADDRESS
                            ) === AddressType.MERCHANT_ADDRESS
                        );
                    },
                },
                {
                    property: 'manageSetting.afterSalesSubsiteId',
                    visible: (values: any) => {
                        return (
                            get(
                                values,
                                'manageSetting.addressEnum',
                                AddressType.SUBSITE_ADDRESS
                            ) === AddressType.MERCHANT_ADDRESS
                        );
                    },
                },
                {
                    property: 'manageSetting.afterSalesAddress',
                    visible: (values: any) => {
                        return (
                            get(
                                values,
                                'manageSetting.addressEnum',
                                AddressType.SUBSITE_ADDRESS
                            ) === AddressType.MERCHANT_ADDRESS
                        );
                    },
                },
                {
                    property: 'manageSetting.independentAfterSaleBackModes',
                },
                {
                    property: 'manageSetting.afterSaleBackModes',
                    visible: (values: any) => {
                        return (
                            get(values, 'manageSetting.independentAfterSaleBackModes') === 'true'
                        );
                    },
                },
                {
                    property: 'seniorSetting.companyInfo.companyName',
                },
                {
                    property: 'seniorSetting.companyInfo.companyType',
                },
                {
                    property: 'seniorSetting.companyInfo.companyScale',
                },
                {
                    property: 'seniorSetting.companyInfo.companyLegal',
                },
                {
                    property: 'seniorSetting.companyInfo.companyLegalIdNumber',
                },
                {
                    property: 'seniorSetting.companyInfo.accountName',
                },
                {
                    property: 'seniorSetting.companyInfo.companyBankName',
                },
                {
                    property: 'seniorSetting.companyInfo.companyAccountNumber',
                },
                {
                    property: 'seniorSetting.companyInfo.companyBusinessPicture',
                },
                {
                    property: 'seniorSetting.companyInfo.taxRegistrationPicture',
                },
                {
                    property: 'seniorSetting.companyInfo.organCodeCertificatePicture',
                },
                {
                    property: 'seniorSetting.companyInfo.openLicensePicture',
                },
                {
                    property: 'seniorSetting.companyInfo.legalFrontPicture',
                },
                {
                    property: 'seniorSetting.companyInfo.legalBackPicture',
                },
                {
                    property: 'seniorSetting.operatorInfo.operatorName',
                },
                {
                    property: 'seniorSetting.operatorInfo.operatorDepartment',
                },
                {
                    property: 'seniorSetting.operatorInfo.email',
                },
                {
                    property: 'seniorSetting.operatorInfo.phone',
                },
                {
                    property: 'seniorSetting.operatorInfo.operatorCertFrontPicture',
                },
                {
                    property: 'seniorSetting.operatorInfo.operatorCertBackPicture',
                },
                {
                    property: 'seniorSetting.otherInfo.serviceTagStylePicture',
                },
                {
                    property: 'seniorSetting.otherInfo.logoMiniPicture',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;
    let form: any;

    const validate = async (entity: any) => {
        const subsite = get(entity, 'baseInfo.subsite', {});
        const showSid = get(entity, 'baseInfo.showSid', 'false') === 'true';
        const floors = get(entity, 'baseInfo.floors', []);
        const addressEnum = get(entity, 'manageSetting.addressEnum', AddressType.SUBSITE_ADDRESS);
        const afterSalesContacts = get(entity, 'manageSetting.afterSalesContacts', '');
        const afterSalesPhone = get(entity, 'manageSetting.afterSalesPhone', '');
        const afterSalesSubsiteId = get(entity, 'manageSetting.afterSalesSubsiteId', []);
        const afterSalesAddress = get(entity, 'manageSetting.afterSalesAddress', '');
        const independentAfterSaleBackModes = get(
            entity,
            'manageSetting.independentAfterSaleBackModes',
            ''
        );
        const afterSaleBackModes = get(entity, 'manageSetting.afterSaleBackModes', []);
        if (!subsite.id) {
            AntMessage.warning(language.getText('selectMerchant'));
            return false;
        }
        if (showSid && !floors.some((item: any) => trim(item.sid))) {
            AntMessage.warning(language.getText('fillInIndoorSid'));
            return false;
        }
        if (independentAfterSaleBackModes === 'true' && !afterSaleBackModes.length) {
            AntMessage.warning(language.getText('atLeastOneBackType'));
            return false;
        }
        //验证预订服务
        const restaurantReservation = get(entity, 'manageSetting.restaurantReservation');
        if (restaurantReservation && restaurantReservation.enable) {
            if (
                restaurantReservation.limitCardLevels &&
                (!restaurantReservation.cardLevels || restaurantReservation.cardLevels.length === 0)
            ) {
                AntMessage.warning(language.getText('selectLimitLevels'));
                return false;
            }
            if (
                restaurantReservation.limitReservationDay &&
                (restaurantReservation.advanceReservationDays === null ||
                    restaurantReservation.limitReservationDays === null)
            ) {
                AntMessage.warning(language.getText('fillInDateDays'));
                return false;
            }
            if (
                !restaurantReservation.spaceTypes ||
                restaurantReservation.spaceTypes.length === 0
            ) {
                AntMessage.warning(language.getText('selectBookPosition'));
                return false;
            }
            let isEmptyTimes = false;
            forEach(restaurantReservation.times, (time) => {
                if (!time.startTime || !time.endTime) {
                    isEmptyTimes = true;
                }
            });
            if (isEmptyTimes) {
                AntMessage.warning(language.getText('fillInBusinessTime'));
                return false;
            }
            const endTime =
                restaurantReservation.times[0].endTime.split(':').length < 3
                    ? restaurantReservation.times[0].endTime + ':00'
                    : restaurantReservation.times[0].endTime;
            const startTime =
                restaurantReservation.times[1].startTime.split(':').length < 3
                    ? restaurantReservation.times[1].startTime + ':00'
                    : restaurantReservation.times[1].startTime;
            //现将时间格式化，偶尔出现14：30：00或14：30的情况
            if (endTime > startTime) {
                AntMessage.warning(language.getText('businessTimeNotOverlap'));
                return false;
            }
        }
        //验证发货地址
        const deliveryLogisticsSenderAddressEnum = get(
            entity,
            'manageSetting.deliveryLogisticsSenderAddressEnum',
            AddressType.SUBSITE_ADDRESS
        );
        const deliveryLogisticsSenderName = get(
            entity,
            'manageSetting.deliveryLogisticsSenderName',
            ''
        );
        const deliveryLogisticsSenderContactNumber = get(
            entity,
            'manageSetting.deliveryLogisticsSenderContactNumber',
            ''
        );
        const deliveryLogisticsSenderRegionId = get(
            entity,
            'manageSetting.deliveryLogisticsSenderRegionId',
            []
        );
        const deliveryLogisticsSenderAddress = get(
            entity,
            'manageSetting.deliveryLogisticsSenderAddress',
            ''
        );
        if (deliveryLogisticsSenderAddressEnum === AddressType.MERCHANT_ADDRESS) {
            if (!deliveryLogisticsSenderName) {
                AntMessage.warning(language.getText('contactNameRequired'));
                return false;
            }
            if (!deliveryLogisticsSenderContactNumber) {
                AntMessage.warning(language.getText('mobileRequired'));
                return false;
            }
            if (deliveryLogisticsSenderRegionId.length === 0) {
                AntMessage.warning(language.getText('selectRange'));
                return false;
            }
            const lastDeliveryLogisticsSenderRegionId =
                deliveryLogisticsSenderRegionId[deliveryLogisticsSenderRegionId.length - 1];
            const res: any = await api.get<RegionOption[]>(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: `/admin/regions/${lastDeliveryLogisticsSenderRegionId}/children`,
                }
            );
            if (res.length > 0) {
                AntMessage.warning(language.getText('selectLasterRegion'));
                return false;
            }
            if (!deliveryLogisticsSenderAddress) {
                AntMessage.warning(language.getText('detailAddressRequired'));
                return false;
            }
        }
        //验证售后地址
        if (addressEnum === AddressType.MERCHANT_ADDRESS) {
            if (!afterSalesContacts) {
                AntMessage.warning(language.getText('contactNameRequired'));
                return false;
            }
            if (!afterSalesPhone) {
                AntMessage.warning(language.getText('mobileRequired'));
                return false;
            }
            if (afterSalesSubsiteId.length === 0) {
                AntMessage.warning(language.getText('selectRange'));
                return false;
            }
            const lastAfterSalesSubsiteId = afterSalesSubsiteId[afterSalesSubsiteId.length - 1];
            const res: any = await api.get<RegionOption[]>(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: `/admin/regions/${lastAfterSalesSubsiteId}/children`,
                }
            );
            if (res.length > 0) {
                AntMessage.warning(language.getText('selectLasterRegion'));
                return false;
            }
            if (!afterSalesAddress) {
                AntMessage.warning(language.getText('detailAddressRequired'));
                return false;
            }
        }
        return true;
    };

    return defaults(
        {
            onSubmit,
            onFieldChange,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: debounce(async (event: any, fields: any) => {
                const entityFields = mapValues(fields, (field, name) => {
                    return field.value;
                });
                if (entityFields) {
                    const hasValidate = await validate(entityFields);
                    if (hasValidate) {
                        entity.modify(entityFields, params);
                    }
                }
            }, 300),
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('MerchantSelfEditPageFormContainer'));
            },
        }
    );
}
export const MerchantSelfEditPageForm = connect(mapDispatchToProps)(MerchantSelfEditPageFormView);
