import React from 'react';
import { Button as AntButton } from 'antd';
import { ButtonProps } from 'antd/lib/button';
import { isFunction } from 'lodash';
import { BackendBuilderConfig, backendBuilderConfigTransformToProps } from '..';

export type EntityButtonProps = BackendBuilderConfig<
    ButtonProps & {
        text?: string;
    }
> & {
    text?: string;
    /**
     * @deprecated 请使用 propsBeforeHandler 或者 propsAfterHandler 处理 props
     */
    propsHandler: (buttonProps: ButtonProps, entityButtonProps: EntityButtonProps) => ButtonProps;
};

/**
 * 支持完整的 antd ButtonProps。
 *
 * 事件除了支持接收 event 外，还支持接收完整的 props。
 */
export const EntityButton: React.FC<EntityButtonProps> = (entityButtonProps) => {
    const { propsHandler, text } = entityButtonProps;
    let buttonProps = backendBuilderConfigTransformToProps(entityButtonProps, [
        'type',
        'href',
        'download',
        'disabled',
        'onClick',
    ]);

    if (isFunction(propsHandler)) {
        buttonProps = propsHandler(buttonProps, entityButtonProps) || buttonProps;
    }

    return (
        <AntButton {...buttonProps}>{text || buttonProps.children || buttonProps.text}</AntButton>
    );
};
