import { Config } from '@comall-backend-builder/core/lib/parser';
export const config: Config = {
    components: {
        MarketingCalendarView: {
            component: 'Viewport',
            entity: 'MarketingScheduleManagementEntity',
        },

        MarketingCalendarPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'MarketingCalendarPageSetting' }],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/marketing-calendar',
                    component: 'MarketingCalendarView',
                    breadcrumbName: '<<yxrl>>',
                    privilege: {
                        path: 'marketingCalendar',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'MarketingCalendarPage' }],
                },
            ],
        },
    ],
};
