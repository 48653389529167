import React, { PureComponent } from 'react';
import './index.less';

/**
 * 可视化组件选择器
 */
export class MallActivityComponentSelector extends PureComponent<any> {
    baseComponents = [
        {
            id: 'mallActivity.list',
            name: '商场活动列表',
        },
        {
            id: 'imageAd',
            name: '图片广告',
        },
        {
            id: 'mallActivity.recommend',
            name: '推荐商场活动',
        },
    ];
    marketingComponents: any = [];
    othersComponents: any = [];

    onSelect = (id: string) => {
        return () => this.props.onSelect(id);
    };

    render() {
        const base = this.baseComponents;
        const marketing = this.marketingComponents;
        const others = this.othersComponents;

        return (
            <div className="custom-component-selector">
                <div className="group">
                    <div className="group-title">基础组件</div>
                    <div className="group-content">
                        {base.map((i: any) => (
                            <div key={i.id} className="item" onClick={this.onSelect(i.id)}>
                                {i.name}
                            </div>
                        ))}
                    </div>
                </div>
                {!!marketing.length && (
                    <div className="group">
                        <div className="group-title">营销组件</div>
                        <div className="group-content">
                            {marketing.map((i: any) => (
                                <div key={i.id} className="item" onClick={this.onSelect(i.id)}>
                                    {i.name}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {!!others.length && (
                    <div className="group">
                        <div className="group-title">其他组件</div>
                        <div className="group-content">
                            {others.map((i: any) => (
                                <div key={i.id} className="item" onClick={this.onSelect(i.id)}>
                                    {i.name}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
