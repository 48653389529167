import builder from '@comall-backend-builder/core';
import { PaymentAllocationConfigLoader } from './payment-allocation-configs-loader';
import {
    PaymentAllocationOrderExportLoader,
    PaymentAllocationOrderLoader,
} from './payment-allocation-order-loader';

import {
    PaymentRemittanceOrderLoader,
    PaymentRemittanceOrderExportLoader,
} from './payment-remittance-order-loader';

builder.registerLoader('/admin/payment_allocation_configs', PaymentAllocationConfigLoader);
builder.registerLoader('/admin/payment_allocation_records', PaymentAllocationOrderLoader);
builder.registerLoader('/admin/payment_allocation_refund_records', PaymentAllocationOrderLoader);
builder.registerLoader(
    '/admin/payment_allocation_refund_records/export',
    PaymentAllocationOrderExportLoader
);
builder.registerLoader(
    '/admin/payment_allocation_records/export',
    PaymentAllocationOrderExportLoader
);
builder.registerLoader('/admin/overseas_remittance_records', PaymentRemittanceOrderLoader);
builder.registerLoader(
    '/admin/overseas_remittance_records/export',
    PaymentRemittanceOrderExportLoader
);
