import { CustomStyleComponentProps } from '@comall-backend-builder/components-basis';
import { InputNumber as AntInputNumber } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import React, { useCallback } from 'react';

interface NumberboxPlusProps
    extends Pick<
            InputNumberProps,
            | 'min'
            | 'max'
            | 'step'
            | 'formatter'
            | 'value'
            | 'name'
            | 'disabled'
            | 'placeholder'
            | 'precision'
        >,
        CustomStyleComponentProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: number | undefined, name: string) => {};
}
export default function NumberboxPlus(props: NumberboxPlusProps) {
    const {
        className,
        style,
        name,
        value,
        placeholder,
        disabled,
        onChange,
        min,
        max,
        step,
        formatter,
        precision,
    } = props;
    const numberboxProps = {
        className,
        style,
        name,
        value,
        placeholder,
        disabled,
        min,
        max,
        step,
        precision,
        formatter,
    };
    const handleChange = useCallback(
        (value: number | undefined) => {
            if (onChange) {
                onChange(value, name);
            }
        },
        [onChange, name]
    );
    return <AntInputNumber {...numberboxProps} onChange={handleChange} />;
}
