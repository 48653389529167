import React, { PureComponent } from 'react';
import { Form, Modal, message } from 'antd';
import { DateTimeRangePicker } from '@comall-backend-builder/components-basis';
import { MallActivityTicketPromotionPrice, TicketFieldShowModal } from '../types';
import { validateTicketPromotionPrice } from '../common';
import { NumberInputTip } from '../..';

import '../index.less';
import { language } from '@comall-backend-builder/core/lib/services';

interface NewMallActivityTicketPromotionPriceModalProps {
    onSubmit: (value: MallActivityTicketPromotionPrice) => void;
}

interface Range {
    start: string;
    end: string;
}

interface NewMallActivityTicketPromotionPriceModalStates {
    showModal: boolean;
    ticketPromotionPrice?: MallActivityTicketPromotionPrice;
}
const labelCol = { span: 5 };

/**
 * 新商场活动设置活动价弹窗
 */
class NewMallActivityTicketPromotionPriceModal extends PureComponent<
    NewMallActivityTicketPromotionPriceModalProps,
    NewMallActivityTicketPromotionPriceModalStates
> {
    constructor(props: NewMallActivityTicketPromotionPriceModalProps) {
        super(props);
        this.state = {
            showModal: false,
        };
    }
    /**
     * 打开弹窗
     */
    public showModal: TicketFieldShowModal<MallActivityTicketPromotionPrice> = (value) => {
        this.setState({ ticketPromotionPrice: value, showModal: true });
    };

    private closeModal = () => {
        this.setState({ ticketPromotionPrice: undefined, showModal: false });
    };
    changeTicketPromotionPrice = (obj: Partial<MallActivityTicketPromotionPrice>) => {
        const { ticketPromotionPrice = {} } = this.state;
        this.setState({ ticketPromotionPrice: Object.assign({}, ticketPromotionPrice, obj) });
    };
    /**
     * 日期区间变更
     */
    private onDateTimeChange = (value?: Range) => {
        this.changeTicketPromotionPrice({ startTime: value?.start, endTime: value?.end });
        return value || {};
    };

    /**
     * 渲染场次日期时间区间
     */
    renderDateTimeRange = () => {
        const { ticketPromotionPrice = {} } = this.state;
        const { startTime, endTime } = ticketPromotionPrice;
        const dateValue = startTime && endTime ? { start: startTime, end: endTime } : undefined;
        return (
            <Form.Item labelAlign="left" labelCol={labelCol} label={language.getText('jgrq')}>
                <div className="new-mall-activity__batch-modal__flex-row">
                    <DateTimeRangePicker
                        name="dateTimeRange"
                        value={dateValue}
                        onChange={this.onDateTimeChange}
                        allowClear={false}
                        style={{ width: 450, paddingRight: 10 }}
                    />
                </div>
            </Form.Item>
        );
    };

    private onSubmit = () => {
        const { ticketPromotionPrice } = this.state;
        if (!ticketPromotionPrice) {
            return;
        }
        const result = validateTicketPromotionPrice(ticketPromotionPrice);
        if (result) {
            message.warn(result);
            return;
        }
        const { onSubmit } = this.props;
        onSubmit(ticketPromotionPrice);
        this.closeModal();
    };
    renderPrice = () => {
        const { ticketPromotionPrice = {} } = this.state;
        const { pointPrice, salePrice } = ticketPromotionPrice;
        const inputStyle = {
            maxWidth: '120px',
        };
        return (
            <Form.Item labelAlign="left" labelCol={labelCol} label={language.getText('hdj')}>
                <div className="new-mall-activity__batch-modal__flex-row">
                    <NumberInputTip
                        value={pointPrice}
                        min={0}
                        max={999999}
                        style={inputStyle}
                        precision={2}
                        addonafter={language.getText('point')}
                        onChange={(num: number) =>
                            this.changeTicketPromotionPrice({ pointPrice: num })
                        }
                    ></NumberInputTip>
                    <span style={{ margin: '0 5px' }}>+</span>
                    <NumberInputTip
                        value={salePrice}
                        style={inputStyle}
                        min={0}
                        max={999999}
                        precision={2}
                        addonafter={language.getText('yuan')}
                        onChange={(num: number) =>
                            this.changeTicketPromotionPrice({ salePrice: num })
                        }
                    ></NumberInputTip>
                </div>
            </Form.Item>
        );
    };
    render() {
        const { showModal } = this.state;
        return (
            <Modal
                width={700}
                onOk={this.onSubmit}
                onCancel={this.closeModal}
                visible={showModal}
                title={language.getText('szhdj')}
            >
                <div className="new-mall-activity__batch-modal">
                    {this.renderPrice()}
                    {this.renderDateTimeRange()}
                </div>
            </Modal>
        );
    }
}

export { NewMallActivityTicketPromotionPriceModal };
