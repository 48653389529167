import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const MemberDistributorLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`;
        config.apiPath = '/admin/members/distributor';
        return await api.get(data, config).then((res: any) => {
            res.result = res.result.map((item: any) => {
                item.email = item.email ? item.email : '';
                item.mobile = item.mobile ? item.mobile : '';
                item.memberName = item.memberName ? item.memberName : '';
                item.memberNameEn = item.memberNameEn ? item.memberNameEn : '';
                item.group = item.group ? item.group : '';
                item.distributorId = item.distributorId ? item.distributorId : '';
                return item;
            });
            return res;
        });
    },
};

export const MembersIdentitiesApplyLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const { auditStatus, signStatus } = data;
        if (auditStatus && auditStatus === 'ALL') {
            delete data.auditStatus;
        }
        if (signStatus && signStatus === 'ALL') {
            delete data.signStatus;
        }

        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`;
        config.apiPath = '/admin/members/identities/apply_records';
        if (data.id) {
            config.apiPath = '/admin/members/identities/apply_records/' + data.id;
        }
        return await api.get(data, config).then((res: any) => {
            if (data.id) {
                res.canAudit = res.auditStatus === 'WAITING';
                res.idCardFrontUrl = res.idCardFrontUrl ? [res.idCardFrontUrl] : [];
                res.idCardBackUrl = res.idCardBackUrl ? [res.idCardBackUrl] : [];
                res.userInfo = {
                    // types: 'record',
                    name: res.applyName,
                    nameEn: res.nameEn,
                    mobile: res.memberAccountMobile,
                    email: res.email,
                    auditsStatus: res.auditsStatus,
                    remark: res.remark,
                    auditStatus: res.auditStatus,
                };
                /* res.processInfo = {
                    auditsStatus: res.auditsStatus,
                    auditRemark: res.auditRemark,
                    auditStatus: res.auditStatus,
                }; */
                return res;
            }
            res.result = res.result.map((item: any) => {
                item.canAudit = item.auditStatus === 'WAITING';
                item.email = item.email ? item.email : '';
                item.mobile = item.mobile ? item.mobile : '';
                item.name = item.name ? item.name : '';
                item.nameEn = item.nameEn ? item.nameEn : '';
                item.signStatus = item.signStatus ? item.signStatus : 'NOT_SIGN';
                return item;
            });
            return res;
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id, userInfo } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`;
        config.apiPath = `/admin/member_benefits`;
        if (params.id) {
            config.apiPath = `/admin/members/identities/apply_records/${params.id}`;
        }
        const newParams: any = {
            id: id,
            applyName: userInfo.name,
        };
        return await api.put(newParams, config);
    },
};
