import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        AuthorizeMemberManagementEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/member/authorize_merchants',
            filters: {
                loginName: {
                    type: 'string',
                    displayName: '<<loginName>>',
                    controlConfig: { placeholder: '<<inputPlease>>', style: { width: 150 } },
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                    controlConfig: { placeholder: '<<inputPlease>>', style: { width: 150 } },
                },
                nickName: {
                    type: 'string',
                    displayName: '<<nickName>>',
                    controlConfig: { placeholder: '<<inputPlease>>', style: { width: 150 } },
                },
                memberId: {
                    type: 'string',
                    displayName: '<<memberId>>',
                    controlConfig: { placeholder: '<<inputPlease>>', style: { width: 150 } },
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                merchantId: {
                    type: 'string.filterOptions.autoComplete',
                    displayName: '<<merchant>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                        //专柜默认正序排序
                        params: {
                            sequence: 'code',
                            order: 'asc',
                        },
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<accountStatus>>',
                    options: [
                        { id: 'NORMAL', name: '<<enable>>' },
                        { id: 'INVALID', name: '<<invalid>>' },
                        { id: 'OFF', name: '<<off>>' },
                    ],
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 150 } },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<dateRange>>',
                    controlConfig: {
                        placeholder: ['<<selectStartTime>>', '<<selectEndTime>>'],
                        style: { width: 300 },
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                loginName: {
                    type: 'string',
                    displayName: '<<loginName>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                nickName: {
                    type: 'string',
                    displayName: '<<nickName>>',
                },
                memberId: {
                    type: 'string',
                    displayName: '<<memberId>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<accountStatus>>',
                    options: [
                        { id: 'NORMAL', name: '<<enable>>' },
                        { id: 'INVALID', name: '<<invalid>>' },
                        { id: 'OFF', name: '<<off>>' },
                    ],
                },
                subsite: {
                    type: 'object',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<subsites>>',
                        },
                        merchant: {
                            type: 'object',
                            properties: {
                                name: {
                                    type: 'string',
                                    displayName: '<<merchant>>',
                                },
                            },
                        },
                    },
                },
                authorizeTime: {
                    type: 'string',
                    displayName: '<<dateRange>>',
                },
            },
        },
    },
    components: {
        AuthorizeMemberManagementView: {
            component: 'Viewport',
            entity: 'AuthorizeMemberManagementEntity',
        },
        AuthorizeMemberManagementPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'AuthorizeMemberManagementFilter',
                },
                {
                    component: 'GridLayout',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'ExportDataByFilterParamsButton',
                            params: {
                                text: '<<exportSearchResult>>',
                                style: {
                                    width: '120px',
                                },
                                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                apiPath: '/admin/member/authorize_merchants/export',
                                type: 'authorize-member-management',
                                requestMethod: 'get',
                                target: '_blank',
                            },
                        },
                    ],
                },
                { component: 'AuthorizeMemberManagementTable' },
            ],
        },
        AuthorizeMemberManagementFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                { property: 'loginName' },
                { property: 'mobile' },
                { property: 'nickName' },
                { property: 'memberId' },
                { property: 'subsiteId' },
                { property: 'merchantId' },
                { property: 'status' },
                { property: 'dateRange' },
            ],
        },
        AuthorizeMemberManagementTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: {
                x: 1400,
            },
            columns: [
                {
                    property: 'loginName',
                    width: 150,
                },
                {
                    property: 'mobile',
                    width: 150,
                },
                {
                    property: 'nickName',
                    width: 120,
                },
                {
                    property: 'memberId',
                    width: 150,
                },
                {
                    property: 'subsite.name',
                    width: 150,
                },
                {
                    property: 'subsite.merchant.name',
                    width: 150,
                },
                {
                    property: 'authorizeTime',
                    width: 150,
                },
                {
                    property: 'status',
                    width: 100,
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/authorize-member-management',
                    component: 'AuthorizeMemberManagementView',
                    breadcrumbName: '<<authorizeMemberManagement>>',
                    privilege: {
                        path: 'authorizeMemberManagement',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'AuthorizeMemberManagementPage' }],
                },
            ],
        },
    ],
};
