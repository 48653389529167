import { Config } from '@comall-backend-builder/core/lib/parser';
import { api } from '@comall-backend-builder/core/lib/services';
import { errorHandle } from '../../../applications/cae/services/error-handle';
import { services } from '@comall-backend-builder/core';

export const config: Config = {
    entities: {
        WheelDrawEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/wheelDraw',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsryhqlbmc>>' },
                },
                subSiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<sxmd_1>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                    controlConfig: { style: { width: 150 } },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                editExecuteStatus: {
                    type: 'string',
                },
                pageShowId: {
                    type: 'number',
                },
                shareConfigId: {
                    type: 'number',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherBaseInfo>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [{ required: true, whitespace: true, message: '<<qsrhdmc>>' }],
                            controlConfig: {
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRange.tip',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subsiteIds: {
                            type: 'array.subsites.selector',
                            displayName: '<<sxmd_1>>',
                            rules: [{ required: true }],
                        },
                        shareStatus: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '<<fxsz>>',
                            defaultValue: ['true'],
                            options: [
                                {
                                    id: 'true',
                                    name: '<<yxjghdfxjhy>>',
                                },
                            ],
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],
                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.baseInfo?.subsiteIds?.length &&
                                        row.baseInfo.subsiteIds
                                            .map((item: any) => item.id)
                                            .join(',');
                                    return { subsiteIds };
                                },
                            },
                        },
                    },
                },
                ruleInfo: {
                    type: 'object.subForm',
                    displayName: '<<ruleSetting>>',
                    collapsed: true,
                    properties: {
                        memberLevel: {
                            type: 'object.affiliateMarketing.memberLevel',
                            displayName: '<<cyyhhydj>>',
                            extra: '<<rhdsxmdwdmd>>',
                        },
                        shareReward: {
                            type: 'object.affiliateMarketing.shareReward',
                            displayName: '<<fxhdcjcs>>',
                        },
                        pointExchange: {
                            type: 'object.affiliateMarketing.pointExchange',
                            displayName: '<<jfdhcjcs>>',
                        },
                        periodLimit: {
                            type: 'object.affiliateMarketing.periodLimit',
                            displayName: '<<zjcsxz>>',
                            extra: '<<jpbkjfhyhq>>',
                        },
                        remark: {
                            type: 'string.text.paragraph',
                            displayName: '<<hdbz>>',
                            rules: [
                                {
                                    whitespace: true,
                                    max: 500,
                                    message: '<<remarkMessage>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrhdbz>>',
                                rows: 4,
                                maxLength: 500,
                                style: {
                                    width: 720,
                                },
                            },
                        },
                    },
                },
                pictureInfo: {
                    type: 'object.subForm',
                    displayName: '<<pageDecoration>>',
                    collapsed: true,
                    properties: {
                        downloadSample: {
                            type: 'string.entityButton',
                            displayName: '<<ckzxyl>>',
                            controlConfig: {
                                props: {
                                    type: 'primary',
                                    children: '<<ckzxyl>>',
                                    onClick() {
                                        api.download(
                                            {},
                                            {
                                                apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                                apiPath:
                                                    '/admin/wheel_draw_activities/example_files',
                                                customHeaders: {
                                                    'content-disposition':
                                                        'attachment;filename="filename.zip"',
                                                },
                                            }
                                        ).catch(errorHandle);
                                    },
                                },
                            },
                        },
                        backgroundPicture: {
                            displayName: '<<hdbjt>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/wheel-draw/background-picture.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<iconPictureExtra>>',
                        },
                        wheelPicture: {
                            type: 'array.imageObjectArrayForTopPic',
                            displayName: '<<zpt>>',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/wheel-draw/wheel-picture.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                                extra: {
                                    tip: '<<wheelPictureExtra>>',
                                    img: './images/avatar/wheel-draw.png',
                                    btnText: '<<viewDiagram>>',
                                    triggerType: 'hover',
                                },
                            },
                        },
                        pointPicture: {
                            type: 'array.imageObjectArrayForTopPic',
                            displayName: '<<zzant>>',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/wheel-draw/point-picture.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                                extra: {
                                    tip: '<<pointPictureRule>>',
                                    img: './images/avatar/point-picture.jpeg',
                                    btnText: '<<viewDiagram>>',
                                    triggerType: 'hover',
                                },
                            },
                        },
                        showRewardRecord: {
                            type: 'string.options.radio',
                            displayName: '<<zjmd>>',
                            defaultValue: 'true',
                            rules: [{ required: true }],
                            options: [
                                { id: 'true', name: '<<show>>' },
                                { id: 'false', name: '<<hide>>' },
                            ],
                        },
                        rewardPicture: {
                            type: 'imageObjectArray',
                            displayName: '<<jldctp>>',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/wheel_draw_reward_popup_bg.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<rewardPictureExtra>>',
                        },
                        sharePicture: {
                            displayName: '<<fxtp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<sharePictureExtra>>',
                        },
                        shareContent: {
                            type: 'string',
                            displayName: '<<fxwa>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrfxwa50Rule>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrfxwa>>',
                                maxLength: 50,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        description: {
                            type: 'string.richTextPlus',
                            displayName: '<<activityRule>>',
                            rules: [
                                { whitespace: true, max: 20000, message: '<<notMoreThan2000>>' },
                            ],
                            controlConfig: {
                                placeholder: '<<descriptionRule>>',
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            extra: '<<showContentExtra>>',
                        },
                    },
                },
                prizeInfo: {
                    type: 'object.subForm',
                    displayName: '<<jpsz>>',
                    collapsed: true,
                    properties: {
                        assetRewards: {
                            type: 'array.rewardSetting',
                            displayName: '<<jpsz>>',
                            controlConfig: {
                                eventType: 'WHEEL_DRAW',
                            },
                        },
                        finishWithNoPrize: {
                            type: 'array.optionIds.checkbox',
                            displayName: '<<byxyhjxcj>>',
                            defaultValue: [],
                            options: [
                                {
                                    name: '<<djpzsx>>',
                                    id: 'true',
                                },
                            ],
                            extra: '<<yhzccjhtsyh>>',
                        },
                    },
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                subSites: {
                    type: 'array.popover',
                    displayName: '<<sxmd_1>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
        },
    },
    components: {
        WheelDrawView: {
            component: 'Viewport',
            entity: 'WheelDrawEntity',
        },
        WheelDrawPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'WheelDrawFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/wheel-draw/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'WheelDrawTable' },
            ],
        },
        WheelDrawFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'subSiteId',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        WheelDrawTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'subSites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'startTime',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'endTime',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}`,
                                    apiPath:
                                        '/AFFILIATE-MARKETING/admin/wheel_draw_activities/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isCloseActivity>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}`,
                                    apiPath:
                                        '/AFFILIATE-MARKETING/admin/wheel_draw_activities/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/menu-list/market/wheel-draw/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/menu-list/market/wheel-draw/copy/copydata/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'ExportHandlerButton',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/wheel_draw_activities/{{row.id}}.xls',
                        },
                    },
                ],
            },
        },
        WheelDrawAddPage: {
            component: 'FlexLayout',
            entity: 'WheelDrawEntity',
            direction: 'horizontal',
            items: [{ component: 'WheelDrawPreview' }, { component: 'WheelDrawAddFormView' }],
        },
        WheelDrawEditPage: {
            component: 'FlexLayout',
            entity: 'WheelDrawEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'WheelDrawPreview' }, { component: 'WheelDrawEditFormView' }],
        },
        WheelDrawCopyPage: {
            component: 'FlexLayout',
            entity: 'WheelDrawEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'WheelDrawPreview' }, { component: 'WheelDrawCopyFormView' }],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/wheel-draw',
                    component: 'WheelDrawView',
                    breadcrumbName: '<<dzphd>>',
                    privilege: {
                        path: 'wheelDraw',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'WheelDrawAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'WheelDrawEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/copy/:type/:id',
                            component: 'WheelDrawCopyPage',
                            breadcrumbName: '<<copyActivity>>',
                        },
                        { path: '/', component: 'WheelDrawPage' },
                    ],
                },
            ],
        },
    ],
};
