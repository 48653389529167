import { Config } from '@comall-backend-builder/core/lib/parser';
import { EntityButtonProps } from '../../../components';
import isEmpty from 'lodash/isEmpty';
import { api, errorHandle } from '@comall-backend-builder/core/lib/services';
import { ForwardDataCenterModal } from '../../../services';
import { services } from '@comall-backend-builder/core';
export const config: Config = {
    entities: {
        StoredValueCardOrderEntity: {
            apiPath: '/loader/admin/stored_value_card_order',
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 200 } },
                },
                status: {
                    type: 'array.options.autoComplete',
                    displayName: '<<ddzt>>',
                    options: [
                        { id: '1', name: '<<yxd>>' },
                        { id: '2', name: '<<yfk>>' },
                        { id: '3', name: '<<yfh>>' },
                        { id: '4', name: '<<yfh_1>>' },
                        { id: '5', name: '<<yqs>>' },
                        { id: '6', name: '<<yqx>>' },
                        { id: '7', name: '<<beforeClose>>' },
                    ],
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 330 },
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<ddrq>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<ddh_1>>',
                },
                memberMobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                receiveDate: {
                    type: 'object.dateRangePlus',
                    displayName: '<<qsrq>>',
                },
                paymentModeType: {
                    type: 'string.options.select',
                    displayName: '<<zffs>>',
                    options: [
                        { id: '2', name: '<<zxzf>>' },
                        { id: '7', name: '<<dgzz>>' },
                    ],
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 150 },
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsites>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<ddh_1>>',
                },
                orderItemsName: {
                    type: 'array.popover.list',
                    displayName: '<<goodName>>',
                },
                paymentModeType: {
                    type: 'string.options.select',
                    displayName: '<<zffs>>',
                    options: [
                        { id: '2', name: '<<zxzf>>' },
                        { id: '7', name: '<<dgzz>>' },
                    ],
                },
                paymentBillTradeNo: {
                    type: 'string',
                    displayName: '<<shdh>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<xdsj>>',
                },
                orderStatus: {
                    type: 'string.options.select',
                    displayName: '<<ddzt>>',
                    options: [
                        { id: '1', name: '<<yxd>>' },
                        { id: '2', name: '<<yfk>>' },
                        { id: '3', name: '<<yfh>>' },
                        { id: '4', name: '<<yfh_1>>' },
                        { id: '5', name: '<<yqs>>' },
                        { id: '6', name: '<<yqx>>' },
                        { id: '7', name: '<<beforeClose>>' },
                    ],
                },
                orderAmount: {
                    type: 'string',
                    displayName: '<<orderAmount>>',
                },
                paymentStatusName: {
                    type: 'string',
                    displayName: '<<zfzt>>',
                },
                memberMobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                receiveTime: {
                    type: 'string',
                    displayName: '<<qssj>>',
                },
                cancelTime: {
                    type: 'string',
                    displayName: '<<qxsj>>',
                },
            },
        },
    },
    components: {
        StoredValueCardOrderView: {
            component: 'Viewport',
            entity: 'StoredValueCardOrderEntity',
        },
        StoredValueCardOrderPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'StoredValueCardOrderFilter',
                    span: 24,
                },
                {
                    component: 'GridLayout',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'EntityButton',
                            props: {
                                type: 'primary',
                                children: '<<dcsj_1>>',
                                style: {
                                    width: 110,
                                },
                                onClick: (_: any, entityButtonProps: EntityButtonProps) => {
                                    const params: Record<string, any> = Object.assign(
                                        {},
                                        entityButtonProps.entity.params,
                                        entityButtonProps.entity.filters
                                    );
                                    if (!isEmpty(params.subsiteId)) {
                                        params.subsiteId = params.subsiteId.id;
                                    }
                                    if (!isEmpty(params.dateRange)) {
                                        params.startOrderCreateDateTime =
                                            params.dateRange.start + ' 00:00:00';
                                        params.endOrderCreateDateTime =
                                            params.dateRange.end + ' 23:59:59';
                                        delete params.dateRange;
                                    }
                                    if (!isEmpty(params.receiveDate)) {
                                        params.receiveStartTime =
                                            params.receiveDate.start + ' 00:00:00';
                                        params.receiveEndTime =
                                            params.receiveDate.end + ' 23:59:59';
                                        delete params.receiveDate;
                                    }
                                    params.status =
                                        params.status && params.status.length > 0
                                            ? params.status.map((status: any) => {
                                                  return status.id;
                                              })
                                            : undefined;
                                    params.orderTypeCodes = [
                                        'eStoredValueCard',
                                        'pStoredValueCard',
                                        'eRechargestoredValueCard',
                                    ];
                                    const config = {
                                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`,
                                        apiPath: '/admin/orders/export',
                                    };

                                    api.post(params, config)
                                        .then(() => {
                                            ForwardDataCenterModal();
                                        })
                                        .catch((error) => {
                                            errorHandle(error);
                                        });
                                },
                            },
                        },
                    ],
                },
                { component: 'StoredValueCardOrderTable' },
            ],
        },
        StoredValueCardOrderFilter: {
            component: 'FilterForm',
            direction: 'inline',
            fields: [
                {
                    property: 'subsiteId',
                },
                {
                    property: 'status',
                },
                {
                    property: 'dateRange',
                },
                {
                    property: 'orderNo',
                },
                {
                    property: 'memberMobile',
                },
                {
                    property: 'receiveDate',
                },
                {
                    property: 'paymentModeType',
                },
            ],
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 12,
                },
            },
            reset: true,
        },
        StoredValueCardOrderTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'subsiteName',
                },
                {
                    property: 'orderNo',
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'orderItemsName',
                },
                {
                    property: 'orderStatus',
                },
                {
                    property: 'orderAmount',
                },
                {
                    property: 'paymentModeType',
                },
                {
                    property: 'paymentStatusName',
                },
                {
                    property: 'paymentBillTradeNo',
                },
                {
                    property: 'memberMobile',
                },
                {
                    property: 'receiveTime',
                },
                {
                    property: 'cancelTime',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 100,
                items: [
                    {
                        type: 'link',
                        text: '<<details>>',
                        path: '/stored-value-card-order/info/{{row.id}}/{{row.orderNo}}',
                    },
                ],
            },
        },
        StoredValueCardOrderInfoPage: {
            component: 'Card',
            content: { component: 'StoredValueCardOrderInfo' },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/stored-value-card-order',
                    component: 'StoredValueCardOrderView',
                    breadcrumbName: '<<storedValueCardOrder>>',
                    privilege: {
                        path: 'storedValueCardOrderManage',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/info/:id/:orderNo',
                            component: 'StoredValueCardOrderInfoPage',
                            breadcrumbName: '<<details>>',
                        },
                        { path: '/', component: 'StoredValueCardOrderPage' },
                    ],
                },
            ],
        },
    ],
};
