import React, { Component } from 'react';
import { Input, Radio, Button, Popconfirm } from 'antd';
import { RewardRule } from '../../containers';
import { cloneDeep, isEmpty, findIndex, isArray, find, map, concat, slice } from 'lodash';
import { ActivityRewardItem } from '../activity-reward-item';
import { Coupon } from '../../containers/select-coupon';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { services } from '@comall-backend-builder/core';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

type RewardRulesValue = {
    /**
     * 奖励满足的条件值
     */
    conditionValue?: number;
    /**
     * 奖励项
     */
    assetRewards?: Array<AssetReward>;
    /**
     * 被选中的奖励项
     */
    checked?: RewardType;
};

type RewardItems = {
    code: RewardType;
    name: string;
    value?: string;
};

type AssetReward = {
    /**
     * 虚拟资产奖励类型
     */
    rewardType: RewardType;
    /**
     * 奖励值
     */
    rewardValue: number | Array<Coupon & { issuedNum?: number }>;
};

type BaseConfig = {
    /**
     * 条件输入框是否展示
     */
    isConditionsInput: boolean;
    /**
     * 奖励项是否为单选框
     */
    isItmesRadio: boolean;
    /**
     * 是否为阶梯
     */
    isLadder: boolean;
    /**
     * 最大阶梯数量
     */
    maxCount: number;
};

enum PageType {
    ADD = 'ADD',
    EDIT = 'EDIT',
}

export enum RewardType {
    /**
     * 积分
     */
    POINT = 'POINT',
    /**
     * 经验值
     */
    EXPERIENCE = 'EXPERIENCE',
    /**
     * 金币
     */
    GOLD = 'GOLD',
    /**
     * 优惠券
     */
    COUPON = 'COUPON',
    /**
     * 未中奖
     */
    NONE = 'NONE',
    /**
     * 自定义
     */
    CUSTOM = 'CUSTOM',
}
enum EventType {
    /**
     * 大转盘
     */
    WHEEL_DRAW = 'WHEEL_DRAW',
    /**
     * 刮刮卡
     */
    SCRATCH_CARD = 'SCRATCH_CARD',
    /**
     * 红包雨
     */
    RED_PACKAGE_RAIN = 'RED_PACKAGE_RAIN',
    /**
     * 老虎机
     */
    SLOT_MACHINE = 'SLOT_MACHINE',
    /**
     * 商城活动
     */
    MALL_ACTIVITY = 'MALL_ACTIVITY',
    /**
     * 开盒有礼
     */
    OPEN_BOX = 'OPEN_BOX',
    /**
     * 积分抽奖码
     */
    LOTTERY_CODE = 'LOTTERY_CODE',
}
const { api } = services;

export interface RewardRulesProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: RewardRulesValue[] | undefined, name: string) => void;
    /**
     * 当前值
     */
    value: RewardRulesValue[] | undefined;
    /**
     * 基本配置
     */
    baseConfig: BaseConfig;
    /**
     * 请求配置
     */
    reqConfig: ApiRequestConfig;
    /**
     * 请求参数
     */
    reqParams?: any;
    /**
     * 是否禁用
     */
    disabled?: boolean;
    /**
     * 奖励类型
     */
    rewardRule?: RewardRule;
    /**
     * 奖励条件开始文字
     */
    rewardStartText?: string;
    /**
     * 奖励条件结束文字
     */
    rewardEndText?: string;
    /**
     * 奖励条件单位
     */
    rewardUnit?: string;
    /**
     * 门店id集合
     */
    subsiteIds?: Array<String>;
    /**
     * 是否可编辑优惠券数量
     */
    canChangeQuantity?: boolean;
    /**
     * 原始数据
     */
    row: any;
}

export class RewardRules extends Component<RewardRulesProps, { rewardItems: Array<RewardItems> }> {
    static defaultProps = {
        rewardStartText: '',
        rewardEndText: '',
        rewardUnit: '',
        baseConfig: {
            isConditionsInput: true,
            isItmesRadio: false,
            isLadder: false,
            maxCount: 10,
        },
        canChangeQuantity: true,
    };

    constructor(props: any) {
        super(props);
        this.state = {
            rewardItems: [],
        };
    }
    pageType = PageType.EDIT;

    componentDidMount() {
        setTimeout(this.loadData, 300);
        // 判断页面类型
        const arr = window.location.href.split('/');
        const params = arr[arr.length - 1];
        if (isNaN(Number(params))) {
            this.pageType = PageType.ADD;
        }
    }

    loadData = () => {
        const { reqParams, reqConfig, onChange, name } = this.props;
        let { value } = this.props;
        api.get<Array<RewardItems>>(!!reqParams ? reqParams : {}, reqConfig).then((res) => {
            if (!!res) {
                this.setState(
                    {
                        rewardItems: res,
                    },
                    () => {
                        const { rewardItems } = this.state;

                        value = value || [{}];

                        if (value[0].checked) {
                            return;
                        }
                        if (rewardItems && rewardItems.length) {
                            const checked = rewardItems[0].code;
                            value[0] = { ...value[0], checked };
                            onChange && onChange(value, name);
                        }
                    }
                );
            }
        });
    };

    onConditionValueChange = (event: any, index: number) => {
        let conditionValue = Number(event.target.value);
        const { value, name, onChange } = this.props;
        if (!isEmpty(conditionValue) && conditionValue <= 0) {
            return;
        }
        if (isNaN(conditionValue)) {
            return;
        }
        let newValue = cloneDeep(value);
        if (!!newValue) {
            newValue[index].conditionValue = conditionValue;
        }
        onChange && onChange(newValue, name);
    };

    onRewardValueChange = (event: any, type: RewardType, index: number) => {
        let rewardValue = Number(event.target.value);
        const {
            name,
            onChange,
            value,
            baseConfig: { isConditionsInput },
        } = this.props;
        if (!isEmpty(rewardValue) && rewardValue <= 0) {
            return;
        }
        if (isNaN(rewardValue)) {
            return;
        }
        let rewardRulesValue: RewardRulesValue | undefined = undefined;
        let newValue: RewardRulesValue[] | undefined = [];

        if (isEmpty(value)) {
            rewardRulesValue = { assetRewards: [{ rewardType: type, rewardValue }] };
            if (isConditionsInput) {
                rewardRulesValue.conditionValue = 1;
            }
            newValue[0] = rewardRulesValue;
        } else {
            if (!!value) {
                rewardRulesValue = cloneDeep(value[index]);
                newValue = cloneDeep(value);

                if (isArray(rewardRulesValue.assetRewards)) {
                    const idx = findIndex(
                        rewardRulesValue.assetRewards,
                        (item) => item.rewardType === type
                    );
                    if (idx > -1) {
                        rewardRulesValue.assetRewards[idx] = { rewardType: type, rewardValue };
                    } else {
                        rewardRulesValue.assetRewards.push({ rewardType: type, rewardValue });
                    }
                } else {
                    rewardRulesValue.assetRewards = [{ rewardType: type, rewardValue }];
                }
                newValue[index] = rewardRulesValue;
            }
        }
        onChange && onChange(newValue, name);
    };

    onChangeRadio = (event: any, index: number) => {
        let checked = event.target.value;
        let { value, name, onChange } = this.props;

        let newValue = cloneDeep(value);
        if (!!newValue) {
            newValue[index].checked = checked;
        } else {
            newValue = [{ checked }];
        }
        onChange && onChange(newValue, name);
    };

    onChangeCoupon = (coupons: Coupon[], index: number) => {
        let { name, value, onChange } = this.props;
        const type = RewardType.COUPON;
        let newValue = cloneDeep(value);

        if (!!newValue) {
            if (isArray(newValue[index].assetRewards)) {
                const idx = findIndex(
                    newValue[index].assetRewards,
                    (item) => item.rewardType === type
                );
                if (idx > -1) {
                    newValue[index].assetRewards![idx] = { rewardType: type, rewardValue: coupons };
                } else {
                    newValue[index].assetRewards!.push({ rewardType: type, rewardValue: coupons });
                }
            } else {
                newValue[index].assetRewards = [{ rewardType: type, rewardValue: coupons }];
            }
        } else {
            newValue = [];
            newValue[0] = {
                assetRewards: [{ rewardType: RewardType.COUPON, rewardValue: coupons }],
            };
        }
        onChange && onChange(newValue, name);
    };

    onAddReward = () => {
        const { name, value, onChange } = this.props;
        const { rewardItems } = this.state;
        let checked = RewardType.NONE;
        if (rewardItems[0]) {
            checked = rewardItems[0].code;
        }
        const assetRewards = map(rewardItems, (item) => {
            let rewardValue: any = 0;
            if (item.code === RewardType.COUPON) {
                rewardValue = [];
            }
            return {
                rewardType: item.code,
                rewardValue: rewardValue,
            };
        });

        const newValue: any = concat(value, { assetRewards, checked });

        onChange && onChange(newValue, name);
    };

    onRemoveReward = (index: number) => {
        let { name, value, onChange } = this.props;
        if (!!value && value.length && value.length > 1) {
            value.splice(index, 1);
        }
        onChange && onChange(value, name);
    };

    renderItem = (value: RewardRulesValue, index: number = 0) => {
        let {
            rewardStartText,
            rewardEndText,
            rewardUnit,
            disabled,
            value: propsValue,
            baseConfig: { isItmesRadio, isConditionsInput, isLadder },
            row,
            reqParams,
        } = this.props;
        rewardStartText = rewardStartText ? rewardStartText : language.getText('mbdz');
        rewardEndText = rewardEndText ? rewardEndText : language.getText('hdyxjl');
        rewardUnit = rewardUnit ? rewardUnit : language.getText('ge');
        const { rewardItems } = this.state;

        let radioGroupValue = '';
        if (rewardItems && rewardItems.length > 0) {
            radioGroupValue = rewardItems[0].code;
        }
        let newRewardsItems: Array<RewardItems> = [];
        if (!!value && !!value.assetRewards) {
            newRewardsItems = rewardItems.map((item) => {
                const index = findIndex(
                    value.assetRewards,
                    (reward) => reward.rewardType === item.code
                );
                if (index > -1 && !!value.assetRewards) {
                    item.value =
                        value.assetRewards[index].rewardValue > 0
                            ? value.assetRewards[index].rewardValue + ''
                            : '';
                }
                return item;
            });
        }
        let disabledStatus = Boolean(disabled) || Boolean(row.id);
        if (
            reqParams?.eventType === EventType.WHEEL_DRAW ||
            reqParams?.eventType === EventType.SCRATCH_CARD ||
            reqParams?.eventType === EventType.OPEN_BOX ||
            reqParams?.eventType === EventType.LOTTERY_CODE
        ) {
            disabledStatus = Boolean(disabled);
        }
        return (
            <div key={index} className="rewards-wrap">
                <div className="title-wrap">
                    {isConditionsInput && (
                        <div className="condition-wrap">
                            {rewardStartText && <span>{rewardStartText}</span>}
                            <Input
                                disabled={!!disabled}
                                value={
                                    value && value.conditionValue ? value.conditionValue : undefined
                                }
                                className="condition-input"
                                addonAfter={rewardUnit}
                                onChange={(e) => this.onConditionValueChange(e, index)}
                            />
                            {rewardEndText && <span>{rewardEndText}</span>}
                        </div>
                    )}
                    <div>
                        {isLadder && propsValue && propsValue.length > 1 && !disabled && (
                            <Popconfirm
                                onConfirm={this.onRemoveReward.bind(this, index)}
                                title={services.language.getText('sfscggz')}
                                okText={services.language.getText('common.ok')}
                                cancelText={services.language.getText('common.cancel')}
                            >
                                <Button type="default" className="reward-remove-reward-button">
                                    {language.getText('scgz')}
                                </Button>
                            </Popconfirm>
                        )}
                    </div>
                </div>
                <div style={{ marginTop: -5 }}>
                    {isItmesRadio ? (
                        <div className="radio-select-wrap">
                            {rewardItems.map((item) => (
                                <div className="asset-rewards-radio-wrap" key={item.code}>
                                    {reqParams?.eventType === EventType.MALL_ACTIVITY ? (
                                        <div
                                            style={{
                                                display: 'block',
                                                height: '50px',
                                                lineHeight: '50px',
                                                marginRight: '10px',
                                                wordSpacing: 'nowrap',
                                                wordBreak: 'keep-all',
                                            }}
                                        >
                                            {item.name}
                                        </div>
                                    ) : (
                                        <Radio.Group
                                            disabled={disabledStatus}
                                            onChange={(e) => this.onChangeRadio(e, index)}
                                            value={
                                                value && value.checked
                                                    ? value.checked
                                                    : radioGroupValue
                                            }
                                        >
                                            <Radio
                                                style={{
                                                    display: 'block',
                                                    height: '50px',
                                                    lineHeight: '50px',
                                                }}
                                                value={item.code}
                                            >
                                                {item.name}
                                            </Radio>
                                        </Radio.Group>
                                    )}

                                    <div className="asset-rewards-extra" key={item.code}>
                                        {this.renderRewardsItemExtra(value, item.code, index)}
                                    </div>
                                </div>
                            ))}
                        </div>
                    ) : this.pageType === PageType.EDIT ? (
                        <div>
                            {newRewardsItems.map((item) => (
                                <div className="asset-rewards-inputs-wrap" key={item.code}>
                                    <span>{item.name}</span>
                                    <Input
                                        className="asset-rewards-input"
                                        addonBefore={language.getText('song')}
                                        value={item.value}
                                        addonAfter={item.name}
                                        onChange={(e) =>
                                            this.onRewardValueChange(e, item.code, index)
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div>
                            {rewardItems.map((item) => (
                                <div className="asset-rewards-inputs-wrap" key={item.code}>
                                    <span>{item.name}</span>
                                    <Input
                                        className="asset-rewards-input"
                                        addonBefore={language.getText('song')}
                                        value={item.value}
                                        addonAfter={item.name}
                                        onChange={(e) =>
                                            this.onRewardValueChange(e, item.code, index)
                                        }
                                    />
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            </div>
        );
    };

    renderRewardsItemExtra = (value: RewardRulesValue, type: RewardType, index: number) => {
        const { disabled, subsiteIds, canChangeQuantity } = this.props;

        let RewardsItemExtra: JSX.Element;
        let currentAssetReward: AssetReward | undefined;
        if (value && value.assetRewards) {
            currentAssetReward = find(value.assetRewards, (item) => item.rewardType === type);
        }

        switch (type) {
            case RewardType.COUPON:
                RewardsItemExtra = (
                    <ActivityRewardItem
                        index={index}
                        disabled={disabled}
                        value={
                            currentAssetReward ? (currentAssetReward.rewardValue as Coupon[]) : []
                        }
                        subsiteIds={subsiteIds}
                        onChangeCoupon={this.onChangeCoupon}
                        type="radio"
                        canChangeQuantity={canChangeQuantity}
                        hideIssuedNum={false}
                        showLeftStock
                    />
                );
                break;
            case RewardType.POINT:
                RewardsItemExtra = (
                    <div>
                        <Input
                            disabled={disabled}
                            value={
                                currentAssetReward ? currentAssetReward.rewardValue + '' : undefined
                            }
                            className="condition-input"
                            onChange={(e) => this.onRewardValueChange(e, type, index)}
                        />
                        <span>{language.getText('point')}</span>
                    </div>
                );
                break;
            default:
                RewardsItemExtra = <span></span>;
                break;
        }

        return <div>{RewardsItemExtra}</div>;
    };

    render() {
        const {
            value,
            disabled,
            baseConfig: { isLadder, maxCount, isItmesRadio },
        } = this.props;
        const tempValue = value ? slice(value, 0, 1) : [];

        return (
            <div className="reward-rules">
                {!isItmesRadio
                    ? map(isLadder ? value : tempValue, (item, index) =>
                          this.renderItem(item, index)
                      )
                    : this.renderItem(tempValue[0] ? tempValue[0] : {}, 0)}
                {isLadder && value && value.length < maxCount && !disabled && (
                    <Button
                        type="primary"
                        className="reward-add-reward-button"
                        onClick={() => this.onAddReward()}
                    >
                        {language.getText('tjjtgz')}
                    </Button>
                )}
            </div>
        );
    }
}
