import { connect } from 'react-redux';

import { Info } from '../../components/info';

// fixme: any
const mapStateToProps = (_state: any, props: any) => ({
    fields: props.entity.result,
});

export const InfoContainer = connect(mapStateToProps)(Info);
