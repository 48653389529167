import React, { PureComponent } from 'react';
import { Button, Modal, Upload, Icon, message, Modal as AntModal } from 'antd';
import { services } from '@comall-backend-builder/core';
import { ProductImportConfig } from './index';

import './index.less';
import 'antd/dist/antd.css';
import { isEqual } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

const { api } = services;
const { Dragger } = Upload;

interface Resource {
    resourceId: number;
    resourceName: string;
    resourceUrl: string;
    type: string;
}

interface Props {
    importConfig: ProductImportConfig;
    visible: boolean;
    closeModal: () => void;
    onImportSuccess: (data: any) => void;
    activityId: string | null;
    params: any;
}
const EMPTY_RESOURCE = {
    resourceId: 0,
    resourceName: '',
    resourceUrl: '',
    type: 'IMPORT',
};
/**
 * 导入excal弹窗
 */
export class ImportProductsModal extends PureComponent<
    Props,
    { fileList: any; statusMsg: string; resource: Resource }
> {
    state = {
        fileList: [],
        statusMsg: language.getText('uploadFileClickButton'),
        resource: EMPTY_RESOURCE,
    };

    componentDidUpdate(prevProps: Props) {
        if (!isEqual(prevProps.params, this.props.params)) {
            this.setState({ fileList: [], resource: EMPTY_RESOURCE });
        }
    }

    downloadTemplate = () => {
        const {
            importConfig: { templateSource },
        } = this.props;
        if (!templateSource) return;
        api.download(
            {},
            {
                ...templateSource,
            }
        );
    };

    onImport = (resource: any) => {
        this.setState({
            statusMsg: language.getText('importBeforeNotPage'),
        });
        const { onImportSuccess } = this.props;
        const { importConfig, activityId, params } = this.props;
        let { apiRoot, apiPath, activityType } = importConfig;
        const importParams = { ...resource, ...params };
        if (activityType) {
            importParams.activityType = activityType;
        }
        // 如果编辑页面使用此组件导入时需要入参详情id，固定使用activityId字段作为入参
        if (activityId) {
            importParams.activityId = activityId;
        }

        api.post(importParams, { apiRoot, apiPath })
            .then(
                (res: any) => {
                    onImportSuccess && onImportSuccess(res);
                    AntModal.info({
                        content: (
                            <div>
                                {/* <span>{`本次共导入${res.totalCount}条数据，其中${res.successCount}条成功，${res.failCount}条失败，可前往`}</span> */}
                                <span>
                                    {services.interpolate(
                                        language.getText('reportDownloadCenterTip'),
                                        {
                                            totalCount: res.totalCount,
                                            successCount: res.successCount,
                                            failCount: res.failCount,
                                        }
                                    )}
                                </span>
                                <a href="/#/report-download-center" target="_blank">
                                    {language.getText('dataExportAndImport')}
                                </a>
                                <span>{language.getText('view')}</span>
                            </div>
                        ),
                    });
                },
                (err) => {
                    message.error(err.response.body.err_msg || err.response.body.message);
                    this.setState({
                        statusMsg: language.getText('importFailPrivity'),
                    });
                }
            )
            .catch(() => {
                this.setState({
                    statusMsg: language.getText('againImport'),
                });
            });
    };

    closeModal = () => {
        const { closeModal } = this.props;
        closeModal && closeModal();
    };

    handleOk = () => {
        const { resource } = this.state;
        if (!resource.resourceId) {
            message.error(language.getText('pleaseUploadFile'));
            return;
        }
        this.onImport(resource);
    };

    render() {
        const { fileList, statusMsg } = this.state;
        const {
            importConfig: { importFileSource },
            visible,
        } = this.props;
        const uploadProps = {
            accept: '.xlsx,.xls,.csv',
            multiple: false,
            onRemove: (file: any) => {
                this.setState((state: any) => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();

                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                        resource: {
                            resourceId: 0,
                            resourceName: '',
                            resourceUrl: '',
                            type: 'IMPORT',
                        },
                        statusMsg: language.getText('uploadFileClickButton'),
                    };
                });
            },
            beforeUpload: (file: any) => {
                api.upload(
                    { files: file },
                    {
                        apiRoot: importFileSource.apiRoot,
                        apiPath: importFileSource.apiPath,
                        fileName: 'files',
                        progressCallBack: () => {},
                    }
                )
                    .then((res) => {
                        const { name, path, id } = res;
                        this.setState({
                            resource: {
                                resourceId: id,
                                resourceName: name,
                                resourceUrl: path,
                                type: 'IMPORT',
                            },
                            fileList: [file],
                        });
                    })
                    .catch((err) => {
                        if (err) {
                            message.error(
                                err.response?.body?.err_msg || language.getText('uoloadFail')
                            );
                        }
                    });
                return false;
            },
            fileList,
        };
        return (
            <div className="import-button-data-statistics">
                <Modal
                    onCancel={this.closeModal}
                    visible={visible}
                    title={language.getText('batchImport')}
                    footer={[
                        <span className="import-button-status-msg">{statusMsg}</span>,
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            {language.getText('import')}
                        </Button>,
                        <Button key="back" onClick={this.closeModal}>
                            {language.getText('common.goBack')}
                        </Button>,
                    ]}
                >
                    <Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">{language.getText('selectFile')}</p>
                        <p className="ant-upload-hint">
                            {language.getText('supportExtension')}.xlsx、.xls、.csv
                        </p>
                    </Dragger>
                    <div>
                        <Button
                            onClick={this.downloadTemplate}
                            className="import-button-template"
                            type="link"
                        >
                            {language.getText('clickDownloadTemplate')}
                        </Button>
                        <div className="import-button-tips">
                            <p className="import-button-tips-text">
                                {language.getText('importRules')}
                            </p>
                            <p className="import-button-tips-text">
                                {language.getText('merchantBigCodeIntro1')}
                            </p>
                            <p className="import-button-tips-text-red">
                                {language.getText('merchantBigCodeNote')}
                            </p>
                            <p className="import-button-tips-text">
                                {language.getText('merchantBigCodeIntro2')}
                            </p>
                            <p className="import-button-tips-text">
                                {language.getText('merchantBigCodeIntro3')}
                            </p>
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }
}
