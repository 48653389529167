import React from 'react';
import get from 'lodash/get';
import { ConditionType } from '../index';
import { MaotaiPresaleConditionsProps } from '../../index';
import { RegionSelectSelect } from '../../../../../types/mode/object/region-select/region-select';

interface RegionProps extends MaotaiPresaleConditionsProps {
    middleText: string;
    frontText: string;
    index: number;
}
export default class Region extends React.PureComponent<RegionProps> {
    componentDidMount() {}
    conditionType = ConditionType.REGION;
    change = (val: any) => {
        const { onChange, value, index } = this.props;
        const list = value.map((item, itemIndex) => {
            if (this.conditionType === item.conditionType && index === itemIndex) {
                return {
                    ...item,
                    conditionValue: {
                        value: {
                            province: get(val, 'regionIds[0]'),
                            provinceName: get(val, 'regionNames[0]'),
                            city: get(val, 'regionIds[1]'),
                            cityName: get(val, 'regionNames[1]'),
                            district: get(val, 'regionIds[2]'),
                            districtName: get(val, 'regionNames[2]'),
                        },
                    },
                };
            }
            return item;
        });

        onChange(list);
    };
    render() {
        const { value, middleText, index, frontText } = this.props;
        const formData = value.find(
            (i, itemIndex) => i.conditionType === this.conditionType && index === itemIndex
        );
        const province = get(formData, 'conditionValue.value.province', '');
        const city = get(formData, 'conditionValue.value.city', '');
        const district = get(formData, 'conditionValue.value.district', '');
        const regionIds: any[] = province ? [province, city, district].filter(Boolean) : [];
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{frontText}</span>&nbsp; &nbsp;
                <RegionSelectSelect
                    onChange={this.change}
                    showAddressInput={false}
                    disabled={undefined}
                    value={{ regionIds }}
                    style={undefined}
                ></RegionSelectSelect>
                &nbsp; &nbsp;
                <span>{middleText}</span>
            </div>
        );
    }
}
