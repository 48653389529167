import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { find, get, forIn, camelCase, map, cloneDeep, snakeCase } from 'lodash';
import { MsgChannelType } from '../types/mode/array/array-message-notification-method/message-notification-method';

const api = services.api;
export const LotteryCodeLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/lottery_code_activities';
        const { id, drawMethod } = data;
        if (id) {
            config.apiPath = `/admin/lottery_code_activities/${id}`;
        } else {
            data.eventType = 'LOTTERY_CODE';
            if (drawMethod && drawMethod === 'ALL') {
                delete data.drawMethod;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.id = res.activityVo.id;
                res.editExecuteStatus = res.activityVo.executeStatus;
                res.editLotteryStatus = res.pageShow.lotteryStatus;
                const normalConditions = res.activityVo.normalConditions;
                const subsiteConditions: any = find(normalConditions, {
                    normalConditionType: 'SUB_SITE',
                });
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : services.language.getText('longTerm'),
                    };
                }
                const businessNotifications =
                    res.businessNotifications && res.businessNotifications.length
                        ? res.businessNotifications.map((item: any) => {
                              let content: any[] = [];
                              if (item.content) {
                                  try {
                                      const newContent = JSON.parse(item.content);
                                      newContent.forEach((i: any) => {
                                          const newItem: any = {};
                                          forIn(i, (value, key) => {
                                              newItem[camelCase(key)] = value;
                                          });
                                          if (
                                              (item.msgChannelType === MsgChannelType.APP_PUSH ||
                                                  item.msgChannelType === MsgChannelType.SITEMAIL ||
                                                  item.msgChannelType === MsgChannelType.EMAIL) &&
                                              newItem.fieldType === 'file' &&
                                              newItem.value
                                          ) {
                                              newItem.value = JSON.parse(newItem.value);
                                          }
                                          content.push(newItem);
                                      });
                                  } catch (error) {
                                      content = item.content;
                                  }
                              }
                              return {
                                  id: item.id,
                                  status: item.status,
                                  msgChannelType: item.msgChannelType,
                                  scene: item.scene,
                                  targetType: item.targetType,
                                  targetUrl: item.targetUrl,
                                  params: content,
                              };
                          })
                        : [];
                res.baseInfo = {
                    name: res.activityVo.name,
                    dateRange: {
                        start: res.activityVo.startTime,
                        end: res.activityVo.endTime,
                    },
                    drawMethod: res.pageShow.drawMethod,
                    drawTime: res.pageShow.drawTime,
                    description: res.activityVo.description,
                    remark: res.activityVo.remark,
                    subsiteIds:
                        subsiteConditions && subsiteConditions.includeIds
                            ? subsiteConditions.includeIds.map((item: any) => ({
                                  id: item.id + '',
                                  name: item.name,
                                  address: item.address,
                                  status: item.status,
                              }))
                            : undefined,
                    shareContent:
                        res.activityVo.shareConfig && res.activityVo.shareConfig.shareContent
                            ? res.activityVo.shareConfig.shareContent
                            : '',
                    // shareAssistContent: res.lottery.shareAssistContent,
                    userWhiteListScope: res.activityVo.userWhiteListScope,
                    noticeType: res.lottery.noticeType,
                    // noticeChannels: res.lottery.noticeChannels,
                    businessNotifications,
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };
                const giveConditions: any = find(res.activityVo.exchangeConditions, {
                    exchangeMode: 'GIVE',
                });
                const shareConditions: any = find(res.activityVo.exchangeConditions, {
                    exchangeMode: 'SHARE',
                });
                const pointConditions: any = find(res.activityVo.exchangeConditions, {
                    exchangeMode: 'POINT_CONSUME',
                });
                res.numberInfo = {
                    activityRewardLimit: giveConditions ? giveConditions.exchangeLimit : '',
                    shareRewardLimit: shareConditions ? shareConditions.exchangeLimit : '',
                    // shareAssistLimit: res.lottery.shareAssistLimit,
                    // assistLimit: res.lottery.assistLimit,
                    pointRule: pointConditions ? 'YES' : 'NO',
                    point:
                        pointConditions && pointConditions.consumeValue
                            ? pointConditions.consumeValue
                            : '',
                    pointExchangeLimit:
                        pointConditions && pointConditions.exchangeLimit
                            ? pointConditions.exchangeLimit
                            : '',
                    refundPoint: res.lottery.refundPoint ? 'true' : 'false',
                };

                if (get(res, 'activityVo.rewardConfig.activityRewards[0].assetRewards.length')) {
                    const assetReward =
                        res.activityVo.rewardConfig.activityRewards[0].assetRewards[0];
                    res.prizeInfo = {
                        name: assetReward.name,
                        quantity: assetReward.quantity,
                        receiveRewardTime: res.pageShow.receiveRewardStartTime && {
                            start: get(res, 'pageShow.receiveRewardStartTime') || '',
                            end: get(res, 'pageShow.receiveRewardEndTime') || '',
                        },
                        type: [{ checked: 'CUSTOM' }],
                    };
                    if (res.lottery.pageConfig) {
                        const { picture } = JSON.parse(res.lottery.pageConfig);
                        res.prizeInfo = {
                            ...res.prizeInfo,
                            picture: [
                                {
                                    id: picture.id || '',
                                    path: picture.url || '',
                                },
                            ],
                        };
                    } else {
                        res.prizeInfo = {
                            ...res.prizeInfo,
                            picture: [
                                {
                                    id: assetReward.picture.id || '',
                                    path: assetReward.picture.url || '',
                                },
                            ],
                        };
                    }
                }

                if (get(res, 'activityVo.rewardConfig.activityRewards[0].couponRewards.length')) {
                    const couponReward =
                        res.activityVo.rewardConfig.activityRewards[0].couponRewards[0];
                    res.prizeInfo = {
                        name: couponReward.name,
                        quantity: couponReward.quantity,
                        receiveRewardTime: res.pageShow.receiveRewardStartTime && {
                            start: get(res, 'pageShow.receiveRewardStartTime') || '',
                            end: get(res, 'pageShow.receiveRewardEndTime') || '',
                        },
                        type: [
                            {
                                checked: 'COUPON',
                                assetRewards: [
                                    {
                                        rewardType: 'COUPON',
                                        rewardValue: [
                                            {
                                                ...couponReward,
                                                name: couponReward.couponName,
                                                id: couponReward.couponDefinitionId,
                                                quantity: couponReward.count,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    };
                    if (res.lottery.pageConfig) {
                        const { picture } = JSON.parse(res.lottery.pageConfig);
                        res.prizeInfo = {
                            ...res.prizeInfo,
                            picture: [
                                {
                                    id: picture.id || '',
                                    path: picture.url || '',
                                },
                            ],
                        };
                    } else {
                        res.prizeInfo = {
                            ...res.prizeInfo,
                            picture: [
                                {
                                    id: couponReward.picture.id || '',
                                    path: couponReward.picture.url || '',
                                },
                            ],
                        };
                    }
                }
            } else {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        item.canExport = true;
                        item.canImport =
                            item.drawMethod === 'EXTERNAL' && item.lotteryStatus === 'NOT_REWARDED';
                        let lotteryStatusString = '';
                        if (item.lotteryStatus === 'REWARDED') {
                            lotteryStatusString = services.language.getText('ykj');
                        } else if (item.lotteryStatus === 'NOT_REWARDED') {
                            lotteryStatusString = '未开奖';
                        }
                        item.lotteryStatus = lotteryStatusString;
                        return item;
                    });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/lottery_code_activities';
        let newParams: any = {};
        if (params.baseInfo) {
            newParams.activityVo = {
                eventType: 'LOTTERY_CODE',
                name: params.baseInfo.name,
                startTime: params.baseInfo.dateRange.start,
                endTime: params.baseInfo.dateRange.end,
                description: params.baseInfo.description,
                remark: params.baseInfo.remark,
                normalConditions: [
                    {
                        all: false,
                        normalConditionType: 'SUB_SITE',
                        includeIds: params.baseInfo.subsiteIds,
                    },
                ],
                userWhiteListScope: params.baseInfo.userWhiteListScope,
                exchangeConditions: [
                    {
                        consumeValue: 0,
                        exchangeLimit: params.numberInfo.activityRewardLimit,
                        exchangeMode: 'GIVE',
                        normalConditionType: 'MEMBER_CARD_LEVEL',
                        period: 'ACTIVITY',
                        scopeId: -1,
                    },
                    {
                        consumeValue: 1,
                        exchangeLimit: params.numberInfo.shareRewardLimit,
                        exchangeMode: 'SHARE',
                        exchangeValue: 1,
                        normalConditionType: 'MEMBER_CARD_LEVEL',
                        period: 'ACTIVITY',
                        scopeId: -1,
                    },
                ],
            };
            if (params.numberInfo.pointRule === 'YES') {
                newParams.activityVo.exchangeConditions.push({
                    consumeValue:
                        params.numberInfo.pointRule === 'YES' ? params.numberInfo.point : 0,
                    exchangeLimit:
                        params.numberInfo.pointRule === 'YES'
                            ? params.numberInfo.pointExchangeLimit
                            : 0,
                    exchangeMode: 'POINT_CONSUME',
                    exchangeValue: 1,
                    normalConditionType: 'MEMBER_CARD_LEVEL',
                    period: 'ACTIVITY',
                    scopeId: -1,
                });
            }
            newParams.pageShow = {
                drawMethod: params.baseInfo.drawMethod,
                drawTime: params.baseInfo.drawTime,
            };

            if (params.prizeInfo.type[0].checked !== 'COUPON') {
                newParams.pageShow.receiveRewardStartTime =
                    params.prizeInfo.receiveRewardTime.start;
                newParams.pageShow.receiveRewardEndTime = params.prizeInfo.receiveRewardTime.end;
            }
            newParams.activityVo.rewardConfig = {
                rewardRule: 'RANDOM',
            };
            newParams.activityVo.shareConfig = {
                shareContent: params.baseInfo.shareContent,
                sharePicture: undefined,
                status: true,
            };
            newParams.lottery = {
                // shareAssistContent: params.baseInfo.shareAssistContent,
                // shareAssistLimit: params.numberInfo.shareAssistLimit,
                // assistLimit: params.numberInfo.assistLimit,
                refundPoint: params.numberInfo.refundPoint === 'true' ? true : false,
                noticeType: params.baseInfo.noticeType,
                // noticeChannels:
                //     params.baseInfo.noticeType === 'ONLINE' ? params.baseInfo.noticeChannels : [],
            };
            newParams.marketingScheduleId =
                (params.baseInfo?.marketingSchedule?.length &&
                    params.baseInfo.marketingSchedule[0].id) ||
                null;
            if (
                params.baseInfo.noticeType === 'ONLINE' &&
                params.baseInfo?.businessNotifications &&
                params.baseInfo.businessNotifications.length
            ) {
                const businessNotifications = map(
                    cloneDeep(params.baseInfo.businessNotifications),
                    (item: any) => {
                        const isSitemail =
                            item.msgChannelType === MsgChannelType.APP_PUSH ||
                            item.msgChannelType === MsgChannelType.SITEMAIL;
                        const content = item.params.map((param: any) => {
                            if (param.fieldType === 'file' && param.value && isSitemail) {
                                param.value = param.value.id;
                            }
                            if (
                                param.fieldType === 'file' &&
                                param.value &&
                                item.msgChannelType === MsgChannelType.EMAIL
                            ) {
                                param.value = param.value.map((i: any) => i.id).join(',');
                            }
                            if (item.msgChannelType === MsgChannelType.SMS && !param.editable) {
                                param.value = '';
                            }
                            const newParam: any = {};
                            forIn(param, (value, key) => {
                                newParam[snakeCase(key)] = value;
                            });
                            return newParam;
                        });
                        return {
                            content: JSON.stringify(content),
                            id: item?.id,
                            msgChannelType: item.msgChannelType,
                            scene: item.scene,
                            status: item.status,
                            targetType: item.targetType,
                            targetUrl: item.targetUrl,
                        };
                    }
                );
                newParams.businessNotifications = businessNotifications;
            }
        }
        if (params.prizeInfo) {
            var checked = params.prizeInfo.type[0].checked;
            var assetRewardsList = params.prizeInfo.type[0].assetRewards;
            if (checked === 'CUSTOM') {
                newParams.activityVo.rewardConfig.activityRewards = [
                    {
                        assetRewards: [
                            {
                                rewardType: 'CUSTOM',
                                rewardValue: 0,
                                name: params.prizeInfo.name,
                                picture: {
                                    id: params.prizeInfo.picture[0].id,
                                    url: params.prizeInfo.picture[0].path,
                                },
                                quantity:
                                    params.baseInfo.drawMethod === 'AUTOMATIC'
                                        ? params.prizeInfo.quantity
                                        : null,
                            },
                        ],
                    },
                ];
            } else if (checked === 'COUPON') {
                // 仅支持单个商品
                const couponItem = assetRewardsList[0].rewardValue[0];
                newParams.activityVo.rewardConfig.activityRewards = [
                    {
                        couponRewards: [
                            {
                                count: couponItem.quantity ? couponItem.quantity : 1,
                                couponDefinitionId: couponItem.id,
                                name: params.prizeInfo.name,
                                picture: {
                                    id: params.prizeInfo.picture[0].id,
                                    url: params.prizeInfo.picture[0].path,
                                },
                                quantity:
                                    params.baseInfo.drawMethod === 'AUTOMATIC'
                                        ? params.prizeInfo.quantity
                                        : null,
                            },
                        ],
                    },
                ];
            }
            const pageConfig = {
                picture: {
                    id: params.prizeInfo.picture[0].id,
                    url: params.prizeInfo.picture[0].path,
                },
            };
            newParams.lottery = {
                ...newParams.lottery,
                pageConfig: JSON.stringify(pageConfig),
            };
        }
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        if (params.id) {
            config.apiPath = `/admin/lottery_code_activities/${params.id}`;
        }
        let newParams: any = {};
        if (params.baseInfo) {
            newParams.activityVo = {
                id: params.id,
                name: params.baseInfo.name,
                eventType: 'LOTTERY_CODE',
                startTime: params.baseInfo.dateRange.start,
                endTime: params.baseInfo.dateRange.end,
                description: params.baseInfo.description,
                remark: params.baseInfo.remark,
                normalConditions: [
                    {
                        all: false,
                        normalConditionType: 'SUB_SITE',
                        includeIds: params.baseInfo.subsiteIds,
                    },
                ],
                userWhiteListScope: params.baseInfo.userWhiteListScope,
                exchangeConditions: [
                    {
                        consumeValue: 0,
                        exchangeLimit: params.numberInfo.activityRewardLimit,
                        exchangeMode: 'GIVE',
                        normalConditionType: 'MEMBER_CARD_LEVEL',
                        period: 'ACTIVITY',
                        scopeId: -1,
                    },
                    {
                        consumeValue: 1,
                        exchangeLimit: params.numberInfo.shareRewardLimit,
                        exchangeMode: 'SHARE',
                        exchangeValue: 1,
                        normalConditionType: 'MEMBER_CARD_LEVEL',
                        period: 'ACTIVITY',
                        scopeId: -1,
                    },
                ],
            };
            if (params.numberInfo.pointRule === 'YES') {
                newParams.activityVo.exchangeConditions.push({
                    consumeValue:
                        params.numberInfo.pointRule === 'YES' ? params.numberInfo.point : 0,
                    exchangeLimit:
                        params.numberInfo.pointRule === 'YES'
                            ? params.numberInfo.pointExchangeLimit
                            : 0,
                    exchangeMode: 'POINT_CONSUME',
                    exchangeValue: 1,
                    normalConditionType: 'MEMBER_CARD_LEVEL',
                    period: 'ACTIVITY',
                    scopeId: -1,
                });
            }
            newParams.pageShow = {
                drawMethod: params.baseInfo.drawMethod,
                drawTime: params.baseInfo.drawTime,
                receiveRewardStartTime:
                    params.prizeInfo.receiveRewardTime && params.prizeInfo.receiveRewardTime.start,
                receiveRewardEndTime:
                    params.prizeInfo.receiveRewardTime && params.prizeInfo.receiveRewardTime.end,
            };
            newParams.activityVo.rewardConfig = {
                rewardRule: 'RANDOM',
            };
            newParams.activityVo.shareConfig = {
                shareContent: params.baseInfo.shareContent,
                sharePicture: undefined,
                status: true,
            };
            newParams.lottery = {
                // shareAssistContent: params.baseInfo.shareAssistContent,
                // shareAssistLimit: params.numberInfo.shareAssistLimit,
                // assistLimit: params.numberInfo.assistLimit,
                refundPoint: params.numberInfo.refundPoint === 'true' ? true : false,
                noticeType: params.baseInfo.noticeType,
                // noticeChannels:
                //     params.baseInfo.noticeType === 'ONLINE' ? params.baseInfo.noticeChannels : [],
            };
            if (
                params.baseInfo.noticeType === 'ONLINE' &&
                params.baseInfo?.businessNotifications &&
                params.baseInfo.businessNotifications.length
            ) {
                const businessNotifications = map(
                    cloneDeep(params.baseInfo.businessNotifications),
                    (item: any) => {
                        const isSitemail =
                            item.msgChannelType === MsgChannelType.APP_PUSH ||
                            item.msgChannelType === MsgChannelType.SITEMAIL;
                        const content = item.params.map((param: any) => {
                            if (param.fieldType === 'file' && param.value && isSitemail) {
                                param.value = param.value.id;
                            }
                            if (
                                param.fieldType === 'file' &&
                                param.value &&
                                item.msgChannelType === MsgChannelType.EMAIL
                            ) {
                                param.value = param.value.map((i: any) => i.id).join(',');
                            }
                            if (item.msgChannelType === MsgChannelType.SMS && !param.editable) {
                                param.value = '';
                            }
                            const newParam: any = {};
                            forIn(param, (value, key) => {
                                newParam[snakeCase(key)] = value;
                            });
                            return newParam;
                        });
                        return {
                            content: JSON.stringify(content),
                            id: item?.id,
                            msgChannelType: item.msgChannelType,
                            scene: item.scene,
                            status: item.status,
                            targetType: item.targetType,
                            targetUrl: item.targetUrl,
                        };
                    }
                );
                newParams.businessNotifications = businessNotifications;
            }
        }
        if (params.prizeInfo) {
            var checked = params.prizeInfo.type[0].checked;
            var assetRewardsList = params.prizeInfo.type[0].assetRewards;
            if (checked === 'CUSTOM') {
                newParams.activityVo.rewardConfig.activityRewards = [
                    {
                        assetRewards: [
                            {
                                rewardType: 'CUSTOM',
                                rewardValue: 0,
                                name: params.prizeInfo.name,
                                picture: {
                                    id: params.prizeInfo.picture[0].id,
                                    url: params.prizeInfo.picture[0].path,
                                },
                                quantity:
                                    params.baseInfo.drawMethod === 'AUTOMATIC'
                                        ? params.prizeInfo.quantity
                                        : null,
                            },
                        ],
                    },
                ];
            } else if (checked === 'COUPON') {
                // 仅支持单个商品
                const couponItem = assetRewardsList[0].rewardValue[0];
                newParams.activityVo.rewardConfig.activityRewards = [
                    {
                        couponRewards: [
                            {
                                count: couponItem.quantity ? couponItem.quantity : 1,
                                couponDefinitionId: couponItem.id,
                                name: params.prizeInfo.name,
                                picture: {
                                    id: params.prizeInfo.picture[0].id,
                                    url: params.prizeInfo.picture[0].path,
                                },
                                quantity:
                                    params.baseInfo.drawMethod === 'AUTOMATIC'
                                        ? params.prizeInfo.quantity
                                        : null,
                            },
                        ],
                    },
                ];
            }
            const pageConfig = {
                picture: {
                    id: params.prizeInfo.picture[0].id,
                    url: params.prizeInfo.picture[0].path,
                },
            };
            newParams.lottery = {
                ...newParams.lottery,
                pageConfig: JSON.stringify(pageConfig),
            };
        }
        newParams.marketingScheduleId =
            (params.baseInfo?.marketingSchedule?.length &&
                params.baseInfo.marketingSchedule[0].id) ||
            null;

        return await api.put(newParams, config);
    },
};
