import React, { PureComponent } from 'react';
import { cloneDeep, findIndex } from 'lodash';
import { BalanceCouponSelector, BalanceCoupon } from './balance-coupon-selector';
import { BalanceCouponsDisplay } from './balance-coupons-display';

export class ArrayBalanceCoupons extends PureComponent<any> {
    onChange = (selectValues: BalanceCoupon[]) => {
        const { onChange } = this.props;
        if (onChange) {
            if (selectValues && selectValues.length === 0) {
                onChange([]);
            } else {
                onChange(selectValues);
            }
        }
    };

    /**
     * 确认后回调
     */
    onSelect = (selectValues: BalanceCoupon[]) => {
        let newData: BalanceCoupon[] = [];
        const { value } = this.props;
        newData = cloneDeep(value || []);
        selectValues.forEach((i: any) => {
            const hasIndex = findIndex(value, (o: any) => o.code === i.code);
            if (hasIndex === -1) {
                newData.unshift(i);
            }
        });
        this.onChange(newData);
    };

    render() {
        const { value, disabled, type } = this.props;
        return (
            <div>
                {!disabled && (
                    <BalanceCouponSelector
                        onChange={this.onSelect}
                        selectValues={value}
                        type={type}
                    />
                )}
                <BalanceCouponsDisplay data={value} onRemove={this.onChange} disabled={disabled} />
            </div>
        );
    }
}
