import React, { Component } from 'react';
import { Button } from 'antd';
import { UploadFile } from './upload-file';

import './index.less';
import { services } from '@comall-backend-builder/core';

interface BatchDeliveryButtonsState {
    visible: boolean;
}

/**
 * 批量发货-上传文件按钮
 */
export class BatchDeliveryButtons extends Component<any, BatchDeliveryButtonsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
        };
    }

    toggleModal = () => {
        this.setState({
            visible: !this.state.visible,
        });
    };

    render() {
        let { visible } = this.state;

        const uploadFileProps = {
            visible: visible,
            onClose: this.toggleModal,
            ...this.props,
        };
        return (
            <div style={{ display: 'inline' }}>
                <Button type="primary" style={{ padding: ' 0 10px' }} onClick={this.toggleModal}>
                    {services.language.getText('drwj')}
                </Button>
                <UploadFile {...uploadFileProps} />
            </div>
        );
    }
}
