import React, { PureComponent } from 'react';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { DesignPage } from '@comall-backend-builder/design-components-basis';
import { CustomComponentSelectorContentService } from '../../applications/design/design/components/custom-component-selector-content-service';
import { DESIGN_PAGE_LINK_TYPES_LIST } from '../../applications/design/constants/content-page-design';
const api = services.api;

const tenantDomain = ENV.TENANT_DOMAIN || window.location.origin;

/**
 * 默认内容服务首页配置
 */
const defaultMemberServiceProfile = {
    metainfo: {
        title: '',
        default: false,
        type: 'member',
    },
    components: {},

    root: {
        share: {
            title: '',
            description: '',
            pic: '',
        },
        backgroundColor: '#f6f6f6',
        items: [],
    },
};

/**
 * 可视化--内容服务首页设计器
 */
export class DesignContentServicePage extends PureComponent<any> {
    loadProfile = () => {
        const {
            params: { id },
            apiPath,
        } = this.props;
        return api.get({ id }, { apiPath: apiPath }).then((res: any) => {
            if (!res.config) {
                return {
                    ...res,
                    config: JSON.stringify(defaultMemberServiceProfile),
                    type: 'contentService',
                };
            } else {
                const newConfig = JSON.parse(res.config);
                if (newConfig.root && typeof newConfig.root.backgroundColor !== 'string') {
                    newConfig.root.backgroundColor =
                        newConfig.root.backgroundColor.color || '#f6f6f6';
                }
                res.config = JSON.stringify(newConfig);
            }
            return res;
        });
    };
    onSave = (data: any) => {
        const { apiPath } = this.props;
        api.put(data, { apiPath: apiPath }).then(() => {
            const saveSuccess = services.language.getText('common.saveSuccess');
            message.success(saveSuccess);
            services.navigation.goBack();
        });
    };
    onCancel = () => {
        services.navigation.goBack();
    };

    getClientUrl = (profile: any, fixed: boolean) => {
        const { client } = this.props;
        let url = client + `?page=WYSIWG&mode=edit`;
        if (profile) {
            url =
                url + `&type=contentService&fixed=false&tenant=${encodeURIComponent(tenantDomain)}`;
        }

        return url;
    };
    render() {
        const submitProps = {
            onClick: this.onSave,
        };
        const cancelProps = {
            onClick: this.onCancel,
        };
        return (
            <DesignPage
                clientUrl={this.getClientUrl}
                loadProfile={this.loadProfile}
                componentsSelector={(props) => <CustomComponentSelectorContentService {...props} />}
                submit={submitProps}
                cancel={cancelProps}
                isTemplate={false}
                linkTypes={DESIGN_PAGE_LINK_TYPES_LIST}
                verbose={process.env.NODE_ENV === 'development'}
                linkTypeMode={'cascader'}
                uploadAction={`${ENV.API_ROOT}/admin/images/upload`}
            ></DesignPage>
        );
    }
}
