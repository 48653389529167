import React, { Component } from 'react';
import { Popover, Button, Select, message } from 'antd';
import { language, privilege } from '@comall-backend-builder/core/lib/services';

const { Option } = Select;

export class MerchantTagSelector extends Component<any> {
    onChange = (value: { key: string; value: string }[]) => {
        const { name, onChange } = this.props;
        onChange(value, name);
    };

    onGoToTagPage = () => {
        if (privilege.check('subsiteTag', 'full')) {
            window.open('#/merchant-tag');
        } else {
            message.warning(language.getText('noPerationPermission'));
        }
    };
    render() {
        const { options, style, value, placeholder } = this.props;
        return (
            <div>
                <Select
                    onChange={this.onChange}
                    mode="multiple"
                    value={value}
                    style={style}
                    placeholder={placeholder || language.getText('selectPlease')}
                    labelInValue
                    optionFilterProp="name"
                    filterOption={(input, option: any) =>
                        (option.props.children || '').toLowerCase().indexOf(input.toLowerCase()) >=
                        0
                    }
                >
                    {options.map((item: any) => (
                        <Option value={item.id} key={item.id}>
                            {item.name}
                        </Option>
                    ))}
                </Select>
                <Button
                    type="link"
                    className="btn-data-down"
                    style={{ marginLeft: '10px' }}
                    onClick={this.onGoToTagPage}
                >
                    {language.getText('createTag')}
                </Button>
                <span style={{ margin: '0 5px', color: '#1890ff' }}>|</span>
                <Popover
                    placement="left"
                    content={
                        <img
                            style={{ width: 400 }}
                            alt={language.getText('components.PageManage.preview')}
                            src="./images/avatar/merchant-tag-preview.png"
                        ></img>
                    }
                    trigger="hover"
                >
                    <Button style={{ padding: 0 }} type="link">
                        {language.getText('viewExample')}
                    </Button>
                </Popover>
            </div>
        );
    }
}
