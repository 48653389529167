import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { get, defaults, map, set, find, isEmpty, isNumber, isNil } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Modal, message as AntMessage } from 'antd';
import { Reward } from '../reward-setting-display';
import { FormComponentProps } from 'antd/lib/form';
import { RewardType as RewardTypeEnum } from '../reward-rules';

import './index.less';

let Entity: any = {};
let entity: any = {};
const componentId = 'RewardSettingAddForm';

export interface RewardSettingFormProps extends FormComponentProps {
    onAdd: (data: Reward) => void;
    onEdit: (data: Reward) => void;
    data: any;
    onSubmit: (e: any, fields: any) => void;
    onInit: (fields?: Reward) => void;
    getForm: () => any;
    subsiteIds: Array<string>;
    eventType?: string;
    row?: any;
    executeStatus: string;
    /**
     * 当前活动是否开启
     */
    isOpen?: boolean;
    /**
     * 当前活动时间是否已到
     */
    isBegin?: boolean;
    editable?: boolean;
    onClose?(): void;
    clearValue?(): void;
}

export interface RewardSettingFormState {
    visible: boolean;
    fields?: Reward;
}

/*
 *事件类型 LICKED-被点赞|COMMENT-被评论|FAVORITES-被收藏|WHEEL_DRAW-大转盘|BIRTHDAY_CARING-生日礼|LOTTERY_CODE=抽奖码|SCRATCH_CARD=刮刮卡|RED_PACKAGE_RAIN=红包雨
 */
enum EventType {
    /**
     * 大转盘
     */
    WHEEL_DRAW = 'WHEEL_DRAW',
    /**
     * 刮刮卡
     */
    SCRATCH_CARD = 'SCRATCH_CARD',
    /**
     * 红包雨
     */
    RED_PACKAGE_RAIN = 'RED_PACKAGE_RAIN',
    /**
     * 老虎机
     */
    SLOT_MACHINE = 'SLOT_MACHINE',
    /**
     * 商城活动
     */
    MALL_ACTIVITY = 'MALL_ACTIVITY',
    /**
     * 开盒有礼
     */
    OPEN_BOX = 'OPEN_BOX',
}
/**
 * 添加奖励
 */
class rewardSettingForm extends PureComponent<RewardSettingFormProps, RewardSettingFormState> {
    constructor(props: any) {
        super(props);
        Entity = EntitiesManager.get('RewardEntity');
        entity = new Entity({});
    }

    state: RewardSettingFormState = {
        visible: false,
        fields: undefined,
    };

    open = (fields: Reward) => {
        this.props.onInit(fields);
        this.setState({
            fields,
            visible: true,
        });
    };
    onSubmit = (event: any, fields: any) => {
        event.stopPropagation();
        const { onAdd, onEdit, getForm, eventType, data } = this.props;
        const form = getForm();
        let validFields = ['name', 'type', 'picture', 'quantity', 'tip'];
        if (eventType === EventType.WHEEL_DRAW || eventType === EventType.SCRATCH_CARD) {
            validFields = [
                'name',
                'type',
                'picture',
                'quantity',
                'tip',
                'periodQuantityType',
                'periodQuantity',
                'memberRewardLimitType',
                'memberRewardLimit',
                'conditionValue',
                'quantityType',
                'rewardPeriod',
            ];
        }
        if (eventType === EventType.OPEN_BOX) {
            validFields = [
                'name',
                'type',
                'picture',
                'quantity',
                'periodQuantityType',
                'periodQuantity',
                'memberRewardLimitType',
                'memberRewardLimit',
                'conditionValue',
                'quantityType',
                'rewardPeriod',
            ];
        }
        if (eventType === EventType.RED_PACKAGE_RAIN) {
            validFields = [
                'name',
                'type',
                'quantityType',
                'quantity',
                'picture',
                'tip',
                'conditionValue',
            ];
        }
        if (eventType === EventType.SLOT_MACHINE) {
            validFields = ['id', 'name', 'type', 'picture', 'quantity'];
        }
        if (eventType === EventType.MALL_ACTIVITY) {
            validFields = ['name', 'type'];
        }
        form.validateFields(validFields, { force: true }).then((entityFields: any) => {
            if (entityFields) {
                const typeArr = get(entityFields, 'type', undefined);
                let type = typeArr ? typeArr[0] : undefined;
                const checked = get(type, 'checked', undefined);
                if (!checked) {
                    type.checked = type ? type.assetRewards[0].rewardType : undefined;
                }
                entityFields.type = type;
                const hasValidate = this.validate(entityFields, data);

                if (hasValidate) {
                    if (eventType !== EventType.MALL_ACTIVITY) {
                        entityFields.picture = entityFields.picture[0];
                        const oldItem =
                            data && data.find((item: any) => item.id === form?.getFieldsValue().id);
                        entityFields.inventory =
                            entityFields.quantity -
                            (oldItem && oldItem.issuedNum ? oldItem.issuedNum : 0); //添加时默认为填写数量
                    }

                    if (Array.isArray(entityFields.type.assetRewards)) {
                        entityFields.type.assetRewards = entityFields.type.assetRewards.filter(
                            (item: any) => item.rewardType === entityFields.type.checked
                        );
                    }
                    if (this.state.fields) {
                        onEdit &&
                            onEdit({
                                ...this.state.fields,
                                ...entityFields,
                            });
                    } else {
                        if (
                            eventType === EventType.WHEEL_DRAW ||
                            eventType === EventType.SCRATCH_CARD ||
                            eventType === EventType.OPEN_BOX ||
                            eventType === EventType.RED_PACKAGE_RAIN
                        ) {
                            entityFields.status = 'ENABLED';
                        }
                        onAdd && onAdd(entityFields);
                    }
                    this.toggleModal();
                }
            }
        });
        return;
    };
    /**
     * 提交前额外验证
     * @param entityFields
     */
    validate = (entityFields: any, data: any) => {
        const { eventType } = this.props;
        let type: any = get(entityFields, 'type', undefined);
        let quantity: any = get(entityFields, 'quantity', undefined);
        let quantityType: any = get(entityFields, 'quantityType', undefined);
        let rewardPeriod: any = get(entityFields, 'rewardPeriod', undefined);

        if (
            rewardPeriod &&
            rewardPeriod?.periodQuantityType === 'YES' &&
            (rewardPeriod?.periodQuantity === null ||
                rewardPeriod?.periodQuantity === '' ||
                rewardPeriod?.periodQuantity === undefined)
        ) {
            AntMessage.warning(services.language.getText('qsrmrfjsl'));
            return false;
        }
        if (
            rewardPeriod &&
            rewardPeriod.memberRewardLimitType === 'YES' &&
            (rewardPeriod.memberRewardLimit === null ||
                rewardPeriod.memberRewardLimit === '' ||
                rewardPeriod.memberRewardLimit === undefined)
        ) {
            AntMessage.warning(services.language.getText('qsrmyhkzjsl'));
            return false;
        }
        const url = window.location.href;
        let editStatus = '';
        if (url && url.indexOf('/market/slot-machines/') > -1) {
            if (url.indexOf('/add') > -1) {
                editStatus = 'add';
            } else if (url.indexOf('/edit') > -1) {
                editStatus = 'edit';
            }
        }
        let oldQuantity: any;
        if (eventType === EventType.SLOT_MACHINE && editStatus === 'edit') {
            let id: any = get(entityFields, 'id', undefined);
            let oldItem: any = data.find(function(obj: any) {
                return obj.id === id && !isNil(id);
            });
            oldQuantity = get(oldItem, 'quantity', undefined);
        }

        if (eventType === EventType.MALL_ACTIVITY) {
            if (type.checked === RewardTypeEnum.COUPON) {
                if (!type.assetRewards || !type.assetRewards.length) {
                    AntMessage.warning(services.language.getText('qxzyhq'));
                    return false;
                }
            }

            return true;
        }

        if (
            (eventType === EventType.WHEEL_DRAW || eventType === EventType.SCRATCH_CARD) &&
            type.checked === RewardTypeEnum.COUPON
        ) {
            // if (!quantityType || quantityType === 'NO') {
            if (
                quantity === null ||
                quantity === '' ||
                quantity === undefined ||
                quantityType === 'NO'
            ) {
                AntMessage.warning(services.language.getText('qxzjpsl'));
                return false;
            }
            // }
        }

        if (
            (eventType === EventType.WHEEL_DRAW || eventType === EventType.SCRATCH_CARD) &&
            type.checked !== RewardTypeEnum.NONE
        ) {
            if (
                quantityType &&
                quantityType === 'YES' &&
                (quantity === null || quantity === '' || quantity === undefined)
            ) {
                AntMessage.warning(services.language.getText('qxzjpsl'));
                return false;
            }
        }
        if (!type) {
            return false;
        } else {
            if (type.checked !== RewardTypeEnum.NONE) {
                if (
                    eventType === EventType.SLOT_MACHINE &&
                    type.checked === RewardTypeEnum.CUSTOM
                ) {
                    // 活动进行时，校验奖品修改的数量是否大于之前添加的数量
                    if (editStatus === 'add') {
                        return true;
                    }
                    if (
                        editStatus === 'edit' &&
                        ((quantity && oldQuantity && quantity > oldQuantity) || isNil(oldQuantity))
                    ) {
                        return true;
                    } else {
                        AntMessage.warning(services.language.getText('xgdjpslbxdyyszdsl'));
                        return false;
                    }
                }
                let result: any = [];
                result = find(type.assetRewards, (item) => type.checked === item.rewardType);
                if (result) {
                    if (isNumber(result.rewardValue)) {
                        if (result.rewardValue < 1) {
                            AntMessage.warning(services.language.getText('qtxzqdjplxx'));
                            return false;
                        }
                    } else if (isEmpty(result.rewardValue)) {
                        AntMessage.warning(services.language.getText('qtxzqdjplxx'));
                        return false;
                    }
                } else {
                    AntMessage.warning(services.language.getText('qtxzqdjplxx'));
                    return false;
                }
            }
            if (eventType === EventType.SLOT_MACHINE && type.checked === RewardTypeEnum.NONE) {
                // 活动进行时，校验奖品修改的数量是否大于之前添加的数量
                if (editStatus === 'add') {
                    return true;
                }
                if (
                    editStatus === 'edit' &&
                    ((quantity && oldQuantity && quantity > oldQuantity) || isNil(oldQuantity))
                ) {
                    return true;
                } else {
                    AntMessage.warning(services.language.getText('xgdjpslbxdyyszdsl'));
                    return false;
                }
            }

            return true;
        }
    };

    renderModal = () => {
        const {
            wrappedComponentRef,
            subsiteIds,
            getForm,
            data,
            editable,
            executeStatus,
        } = this.props;
        let { eventType, isOpen, isBegin } = this.props;
        const disabled = !editable;

        /**
         * 活动开始以后奖品数量就不允许继续修改，否则后端会有bug，未来后端bug修复后再放开
         * https://jira.co-mall.com/browse/SAAS-23712
         */
        let quantityDisabled =
            disabled ||
            (executeStatus && executeStatus !== 'NOT_STARTED' && getForm()?.getFieldsValue().id);
        if (!eventType) {
            eventType = EventType.WHEEL_DRAW;
        }
        let fields: any = [
            { property: 'id', className: 'hidden' },
            { property: 'name', controlConfig: { disabled } },
            {
                property: 'type',
                controlConfig: {
                    reqConfig: {
                        apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                        apiPath: '/admin/activities/reward_items',
                    },
                    reqParams: { eventType: eventType },
                    baseConfig: {
                        isConditionsInput: false,
                        isItmesRadio: true,
                        isLadder: false,
                    },
                    subsiteIds: subsiteIds,
                    disabled,
                },
            },
            {
                property: 'picture',
                extra: services.language.getText('tpccjyw300'),
                controlConfig: {
                    maxCount: 1,
                    multiple: false,
                    className: 'sigle-image-field',
                    fileName: 'fileName',
                    uploadType: 'multi',
                    uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                    mode: 'picture-card',
                    disabled,
                },
            },
            {
                property: 'quantity',
                controlConfig: {
                    style: { width: 300 },
                    disabled: quantityDisabled,
                },
                rules: [
                    {
                        required: true,
                        type: 'number',
                        message: services.language.getText('jpslbnwk'),
                        validator: (rule: any, value: any) => {
                            var reg = /^(0|\+?[1-9][0-9]*)$/;
                            var regLength = /^([0-9]{1,9})?$/g;
                            if (reg.test(value) && regLength.test(value)) {
                                return true;
                            } else {
                                return false;
                            }
                        },
                    },
                    {
                        async validator(rules: any, value: any) {
                            const rewardId = getForm().getFieldValue('id');
                            if (data) {
                                const currentRow = data.find(({ id }: any) => id === rewardId)!;
                                if (currentRow) {
                                    const { issuedNum } = currentRow;
                                    if (!isNil(issuedNum) && issuedNum > value) {
                                        throw new Error(services.language.getText('jpslbnxyyffsl'));
                                    }
                                }
                            }
                        },
                    },
                ],
            },
            {
                property: 'tip',
                controlConfig: {
                    disabled,
                },
            },
        ];

        if (eventType === EventType.WHEEL_DRAW || eventType === EventType.SCRATCH_CARD) {
            const typeChecked =
                getForm()?.getFieldsValue()?.type &&
                getForm()?.getFieldsValue()?.type[0].checked === 'NONE';
            const quantityTypeDisabled = typeChecked
                ? typeChecked
                : getForm()?.getFieldsValue()?.quantityType === 'NO';
            quantityDisabled = disabled;
            const typeDisabled =
                disabled ||
                (executeStatus &&
                    executeStatus !== 'NOT_STARTED' &&
                    getForm()?.getFieldsValue().id);
            fields = [
                { property: 'id', className: 'hidden' },
                { property: 'name', controlConfig: { disabled } },
                {
                    property: 'type',
                    controlConfig: {
                        reqConfig: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/activities/reward_items',
                        },
                        reqParams: { eventType: eventType },
                        baseConfig: {
                            isConditionsInput: false,
                            isItmesRadio: true,
                            isLadder: false,
                        },
                        subsiteIds: subsiteIds,
                        disabled: typeDisabled,
                    },
                },
                {
                    property: 'quantityType',
                    className: typeChecked
                        ? 'quantity-disabled'
                        : quantityTypeDisabled
                        ? ''
                        : 'quantity-type-show',
                    controlConfig: {
                        style: { width: 100 },
                        disabled: quantityDisabled,
                    },
                },
                {
                    property: 'quantity',
                    className: quantityTypeDisabled ? 'quantity-disabled' : 'quantity-show',
                    controlConfig: {
                        placeholder: services.language.getText('qsrsl_1'),
                        style: { width: 200 },
                        disabled: quantityDisabled,
                    },
                    rules: [
                        {
                            required: quantityTypeDisabled ? false : true,
                            type: 'number',
                            message: services.language.getText('jpslbnwk'),
                            validator: (rule: any, value: any) => {
                                var reg = /^(0|\+?[1-9][0-9]*)$/;
                                var regLength = /^([0-9]{1,9})?$/g;
                                if (quantityTypeDisabled) {
                                    return true;
                                }
                                if (reg.test(value) && regLength.test(value)) {
                                    return true;
                                } else {
                                    return false;
                                }
                            },
                        },
                        {
                            async validator(rules: any, value: any) {
                                const rewardId = getForm().getFieldValue('id');
                                if (data && !quantityTypeDisabled) {
                                    const currentRow = data.find(({ id }: any) => id === rewardId)!;
                                    if (currentRow) {
                                        const { issuedNum } = currentRow;
                                        if (!isNil(issuedNum) && issuedNum > value) {
                                            throw new Error(
                                                services.language.getText('jpslbnxyyffsl')
                                            );
                                        }
                                    }
                                }
                            },
                        },
                    ],
                },
                {
                    property: 'picture',
                    extra: services.language.getText('tpccjyw300'),
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        className: 'sigle-image-field',
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                        disabled,
                    },
                },
                {
                    property: 'tip',
                    controlConfig: {
                        disabled,
                    },
                },

                {
                    property: 'conditionValue',
                    controlConfig: {
                        disabled,
                        max: 100,
                        addonafter: '%',
                        style: { width: 100 },
                        placeholder: services.language.getText('qsrsl_1'),
                    },
                },
                {
                    property: 'rewardPeriod',
                    className: typeChecked ? 'quantity-disabled' : '',
                    controlConfig: {
                        disabled: quantityDisabled,
                    },
                },
            ];
        }

        if (eventType === EventType.OPEN_BOX) {
            const typeChecked =
                getForm()?.getFieldsValue()?.type &&
                getForm()?.getFieldsValue()?.type[0].checked === 'NONE';
            const quantityTypeDisabled = typeChecked
                ? typeChecked
                : getForm()?.getFieldsValue()?.quantityType === 'NO';
            quantityDisabled = disabled;
            const typeDisabled =
                disabled ||
                (executeStatus &&
                    executeStatus !== 'NOT_STARTED' &&
                    getForm()?.getFieldsValue().id);
            fields = [
                { property: 'id', className: 'hidden' },
                { property: 'name', controlConfig: { disabled } },
                {
                    property: 'type',
                    controlConfig: {
                        reqConfig: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/activities/reward_items',
                        },
                        reqParams: { eventType: eventType },
                        baseConfig: {
                            isConditionsInput: false,
                            isItmesRadio: true,
                            isLadder: false,
                        },
                        subsiteIds: subsiteIds,
                        disabled: typeDisabled,
                    },
                },
                {
                    property: 'quantityType',
                    className: typeChecked
                        ? 'quantity-disabled'
                        : quantityTypeDisabled
                        ? ''
                        : 'quantity-type-show',
                    controlConfig: {
                        style: { width: 100 },
                        disabled: quantityDisabled,
                    },
                },
                {
                    property: 'quantity',
                    className: quantityTypeDisabled ? 'quantity-disabled' : 'quantity-show',
                    controlConfig: {
                        placeholder: services.language.getText('qsrsl_1'),
                        style: { width: 200 },
                        disabled: quantityDisabled,
                    },
                    rules: [
                        {
                            required: quantityTypeDisabled ? false : true,
                            type: 'number',
                            message: services.language.getText('jpslbnwk'),
                            validator: (rule: any, value: any) => {
                                var reg = /^(0|\+?[1-9][0-9]*)$/;
                                var regLength = /^([0-9]{1,9})?$/g;
                                if (quantityTypeDisabled) {
                                    return true;
                                }
                                if (reg.test(value) && regLength.test(value)) {
                                    return true;
                                } else {
                                    return false;
                                }
                            },
                        },
                        {
                            async validator(rules: any, value: any) {
                                const rewardId = getForm().getFieldValue('id');
                                if (data && !quantityTypeDisabled) {
                                    const currentRow = data.find(({ id }: any) => id === rewardId)!;
                                    if (currentRow) {
                                        const { issuedNum } = currentRow;
                                        if (!isNil(issuedNum) && issuedNum > value) {
                                            throw new Error(
                                                services.language.getText('jpslbnxyyffsl')
                                            );
                                        }
                                    }
                                }
                            },
                        },
                    ],
                },
                {
                    property: 'picture',
                    extra: services.language.getText('tpccjyw300'),
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        className: 'sigle-image-field',
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                        disabled,
                    },
                },
                {
                    property: 'conditionValue',
                    controlConfig: {
                        disabled,
                        max: 100,
                        addonafter: '%',
                        style: { width: 100 },
                        placeholder: services.language.getText('qsrsl_1'),
                    },
                },
                {
                    property: 'rewardPeriod',
                    className: typeChecked ? 'quantity-disabled' : '',
                    controlConfig: {
                        disabled: quantityDisabled,
                    },
                },
            ];
        }

        if (eventType === EventType.SLOT_MACHINE) {
            const disabled = isBegin || (!isBegin && isOpen) || !editable;
            fields = [
                {
                    property: 'id',
                    className: 'slot-machine-reward-id',
                    controlConfig: {
                        disabled,
                    },
                },
                {
                    property: 'name',
                    controlConfig: {
                        disabled,
                    },
                },
                {
                    property: 'type',
                    controlConfig: {
                        reqConfig: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/activities/reward_items',
                        },
                        reqParams: { eventType: eventType },
                        baseConfig: {
                            isConditionsInput: false,
                            isItmesRadio: true,
                            isLadder: false,
                        },
                        subsiteIds: subsiteIds,
                        disabled,
                    },
                },
                {
                    property: 'picture',
                    extra: services.language.getText('pictureExtra'),
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        className: 'sigle-image-field',
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                        disabled: !editable,
                    },
                },
                {
                    property: 'quantity',
                    controlConfig: {
                        style: { width: 300 },
                        disabled: quantityDisabled,
                    },
                },
            ];
        }

        if (eventType === EventType.MALL_ACTIVITY) {
            fields = [
                {
                    property: 'name',
                    controlConfig: {
                        disabled,
                    },
                },
                {
                    property: 'type',
                    controlConfig: {
                        reqConfig: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
                            apiPath: '/admin/mall_activities/reward_types',
                        },
                        reqParams: { eventType: eventType },
                        baseConfig: {
                            isConditionsInput: false,
                            isItmesRadio: true,
                            isLadder: false,
                        },
                        canChangeQuantity: false,
                        subsiteIds: subsiteIds,
                        disabled,
                    },
                },
            ];
        }
        if (eventType === EventType.RED_PACKAGE_RAIN) {
            const typeChecked =
                getForm()?.getFieldsValue()?.type &&
                getForm()?.getFieldsValue()?.type[0].checked === 'NONE';
            const quantityTypeDisabled = typeChecked
                ? typeChecked
                : getForm()?.getFieldsValue()?.quantityType === 'NO';
            quantityDisabled = disabled;
            const typeDisabled =
                disabled ||
                (executeStatus &&
                    executeStatus !== 'NOT_STARTED' &&
                    getForm()?.getFieldsValue().id);
            fields = [
                { property: 'id', className: 'hidden' },
                { property: 'name', controlConfig: { disabled } },
                {
                    property: 'type',
                    controlConfig: {
                        reqConfig: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/activities/reward_items',
                        },
                        reqParams: { eventType: eventType },
                        baseConfig: {
                            isConditionsInput: false,
                            isItmesRadio: true,
                            isLadder: false,
                        },
                        subsiteIds: subsiteIds,
                        disabled: typeDisabled,
                    },
                },
                {
                    property: 'quantityType',
                    className: typeChecked
                        ? 'quantity-disabled'
                        : quantityTypeDisabled
                        ? ''
                        : 'quantity-type-show',
                    controlConfig: {
                        style: { width: 100 },
                        disabled: quantityDisabled,
                    },
                },
                {
                    property: 'quantity',
                    className: quantityTypeDisabled ? 'quantity-disabled' : 'quantity-show',
                    controlConfig: {
                        placeholder: services.language.getText('qsrsl_1'),
                        style: { width: 200 },
                        disabled: quantityDisabled,
                    },
                    rules: [
                        {
                            required: quantityTypeDisabled ? false : true,
                            type: 'number',
                            message: services.language.getText('jpslbnwk'),
                            validator: (rule: any, value: any) => {
                                var reg = /^(0|\+?[1-9][0-9]*)$/;
                                var regLength = /^([0-9]{1,9})?$/g;
                                if (quantityTypeDisabled) {
                                    return true;
                                }
                                if (reg.test(value) && regLength.test(value)) {
                                    return true;
                                } else {
                                    return false;
                                }
                            },
                        },
                        {
                            async validator(rules: any, value: any) {
                                const rewardId = getForm().getFieldValue('id');
                                if (data && !quantityTypeDisabled) {
                                    const currentRow = data.find(({ id }: any) => id === rewardId)!;
                                    if (currentRow) {
                                        const { issuedNum } = currentRow;
                                        if (!isNil(issuedNum) && issuedNum > value) {
                                            throw new Error(
                                                services.language.getText('jpslbnxyyffsl')
                                            );
                                        }
                                    }
                                }
                            },
                        },
                    ],
                },
                {
                    property: 'picture',
                    extra: services.language.getText('tpccjyw300'),
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        className: 'sigle-image-field',
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                        disabled,
                    },
                },
                {
                    property: 'tip',
                    controlConfig: {
                        disabled,
                    },
                },

                {
                    property: 'conditionValue',
                    extra: '',
                    controlConfig: {
                        disabled,
                        max: 100,
                        addonafter: '%',
                        style: { width: 100 },
                        placeholder: services.language.getText('qsrsl_1'),
                    },
                },
            ];
        }
        if (
            eventType === EventType.WHEEL_DRAW ||
            eventType === EventType.SCRATCH_CARD ||
            eventType === EventType.OPEN_BOX ||
            eventType === EventType.RED_PACKAGE_RAIN
        ) {
            entity.metainfo.properties.quantityType.displayName = services.language.getText(
                'jpzfsxz'
            );
        }
        let formProps = {
            entity,
            wrappedComponentRef: wrappedComponentRef,
            componentId: componentId,
            onSubmit: this.onSubmit,
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 14,
            style: { width: 620 },
            fields: fields,
            submit: false,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        onClick: this.onSubmit,
                        text: services.language.getText('common.ok'),
                        type: 'primary',
                        style: { marginLeft: 10 },
                    },
                    {
                        htmlType: 'button',
                        onClick: this.toggleModal,
                        text: services.language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
        };
        return createElement(ComponentsManager.get('CreateForm'), formProps);
    };

    toggleModal = () => {
        const { visible } = this.state;
        this.setState(
            {
                visible: !visible,
                fields: undefined,
            },
            () => {
                const { visible } = this.state;
                if (!visible) {
                    this.props.onInit();
                    this.props.onClose?.();
                }
            }
        );
    };

    render() {
        let { eventType, isOpen, isBegin, data, clearValue } = this.props;

        if (!eventType) {
            eventType = EventType.WHEEL_DRAW;
        }
        const modalContent = this.renderModal();
        const modalProps = {
            width: 1000,
            title: services.language.getText('tjjp'),
            visible: this.state.visible,
            footer: null,
            onCancel: this.toggleModal,
            destroyOnClose: true,
        };

        const url = window.location.href;
        let pageCheck = true;
        if (url && url.indexOf('/market/slot-machines/edit/') > -1) {
            if (!isBegin && !isOpen) {
                pageCheck = true;
            } else {
                pageCheck = false;
            }
        }
        const hasValue = data && data.length;
        return (
            <div className="reward-setting">
                {pageCheck && eventType !== EventType.MALL_ACTIVITY && (
                    <div className="button-wrap">
                        <div>
                            <Button type="primary" size="large" onClick={this.toggleModal}>
                                {services.language.getText('tjjp')}
                            </Button>
                        </div>
                        {(eventType === EventType.WHEEL_DRAW ||
                            eventType === EventType.SCRATCH_CARD ||
                            eventType === EventType.OPEN_BOX) && (
                            <div className="tip">
                                <div>
                                    <span>{services.language.getText('ktddzsx')}</span>
                                </div>
                                <div>
                                    <span>{services.language.getText('mgjpsszdzjglzhbxdy')}</span>
                                </div>
                                {eventType === EventType.WHEEL_DRAW && (
                                    <div>
                                        <span>{services.language.getText('jpplsxbxydzp')} </span>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                )}
                {pageCheck && eventType === EventType.MALL_ACTIVITY && (
                    <div className="button-wrap">
                        {hasValue ? (
                            <Button type="primary" onClick={clearValue}>
                                {services.language.getText('clearSetting')}
                            </Button>
                        ) : (
                            <Button type="primary" onClick={this.toggleModal}>
                                {services.language.getText('settings')}
                            </Button>
                        )}
                    </div>
                )}
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onInit, onSubmitStart } = props;
    let form: any;

    const handleInit = (fields?: any) => {
        let {
            id: entityId,
            metainfo: { properties },
            fields: fieldsFromProps,
        } = entity;
        const loaded = !!fieldsFromProps;
        if (!fields) {
            let fields: { [key: string]: any } = {};
            map(fieldsFromProps, (field) => {
                let { property } = field;

                // 从实体中获取属性配置，实体中的 path 为 style.properties.color，配置中 path 为 style.color
                let entityProperty = get(properties, property.replace('.', '.properties.'));
                if (entityProperty) {
                    set(fields, property, entityProperty.defaultValue || undefined);
                } else {
                    throw new Error(`Property ${property} not found in Entity ${entityId}`);
                }
            });
            _dispatch(
                actions.formInitAction(componentId, {
                    type: 'CreateForm',
                    entityId: entity.id,
                    fields,
                })
            );
        } else {
            setTimeout(() => {
                _dispatch(
                    actions.formInitAction(componentId, {
                        type: 'CreateForm',
                        entityId: entity.id,
                        fields: {
                            ...fields,
                            type: [fields.type],
                            picture: [fields.picture],
                        },
                        loaded,
                    })
                );
            });
        }
    };

    return defaults(
        {
            onSubmit,
            onInit,
            onSubmitStart,
        },
        {
            onInit: handleInit,
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
        }
    );
}

function mapStateToProps(_dispatch: any, props: any) {
    const { row } = props;
    let subsiteIds = get(row, 'baseInfo.subsiteIds', undefined);
    subsiteIds = map(subsiteIds, (item: any) => item.id);
    return defaults({
        subsiteIds,
    });
}

export const RewardSettingForm = connect(mapStateToProps, mapDispatchToProps, null, {
    forwardRef: true,
})(rewardSettingForm);
