import React, { Component } from 'react';
import {
    Row as AntRow,
    InputNumber,
    Button,
    message,
    Checkbox,
    Popconfirm,
    message as AntMessage,
} from 'antd';
import { clone, map, concat, isEmpty, isNull } from 'lodash';
import { Radio } from 'antd';

import './index.less';
import { ActivityRewardItem } from '../../../../components/activity-reward-item';
import { Coupon } from '../../../../containers/select-coupon';
import { QuestionPoprver } from '../../../../components';
import { language } from '@comall-backend-builder/core/lib/services';

const RadioGroup = Radio.Group;

interface ActivityRewardCheckinObject {
    /**
     * 是否连续签到（否则为累计签到）
     */
    continuousCheckin: boolean | null;
    /**
     * 奖励内容
     */
    values: ActivityRewardCheckinValue[];
}

type ActivityRewardCheckinValue = {
    /**
     * 签到阶梯值
     */
    stepValue?: number;
    /**
     * 积分
     */
    pointChecked?: boolean;
    point?: number;
    /**
     * 经验值
     */
    experienceChecked?: boolean;
    experience?: number;
    /**
     * 优惠券
     */
    couponsChecked?: boolean;
    couponsValue?: any;
    /**
     * Hi贝
     */
    goldValueChecked?: boolean;
    goldValue?: number;
    goldValueTxtName?: string;
    id?: string;
};

/**
 * 奖励内容枚举
 */
enum RewardItems {
    stepValue = 'STEPVALUE',
    point = 'POINT',
    experience = 'EXPERIENCE',
    coupons = 'COUPON',
    Hi = 'HI',
    Gold = 'GOLD',
}

export interface ActivityRewardCheckinProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    value: ActivityRewardCheckinObject | null;

    /**
     * 是否禁用（禁用只限制修改阶梯值、赠删阶梯，不限制修改阶梯内奖励内容）
     */
    disabled: boolean;
    options?: { code: string; name: string }[];

    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: ActivityRewardCheckinObject | null, name: string) => void;
    /**
     * 可添加的最大数量
     */
    maxCount?: number | undefined;
}
export class ActivityRewardCheckin extends Component<ActivityRewardCheckinProps> {
    static defaultProps = {
        disabled: false,
    };
    componentDidMount() {
        let { name, value, onChange } = this.props;
        if (!value) {
            let item: ActivityRewardCheckinValue = { couponsValue: [] };
            value = {
                values: concat([], item),
                continuousCheckin: null,
            };
            if (onChange) {
                onChange(value, name);
            }
        }
    }

    onAddReward() {
        let { name, value, onChange, maxCount = 7 } = this.props;
        if (!value) return;
        let values = value.values;
        if (values !== null && !isEmpty(values)) {
            if (values.length >= maxCount) {
                message.warning(
                    `${language.getText('mostSupport')}${maxCount}${language.getText('notAdd')}`
                );
                return;
            }
            values = concat([], values);
            values.splice(values.length, 0, { couponsValue: [] });
        }
        value.values = values;
        if (onChange) {
            onChange(value, name);
        }
    }

    onRemoveReward = (index: number) => {
        let { name, value, onChange } = this.props;
        if (!value) return;
        let values = value.values;
        if (values !== null && !isEmpty(values)) {
            values = concat([], values);
            values.splice(index, 1);
            if (!values.length) {
                let item: ActivityRewardCheckinValue = { couponsValue: [] };
                values = concat([], item);
            }
        } else {
            let item: ActivityRewardCheckinValue = { couponsValue: [] };
            values = concat([], item);
        }
        value.values = values;
        if (onChange) {
            onChange(value, name);
        }
    };

    onInputValueChange(event: any, index: number, type: string) {
        let { name, value, onChange } = this.props;
        if (!value) return;
        let values = value.values;
        let inputValue = event;
        var reg = /^\+?[0-9]\d*$/;
        if (inputValue !== null && inputValue !== '' && !reg.test(inputValue)) {
            AntMessage.warning(language.getText('inputNumber'));
            inputValue = null;
        }
        if (values !== null && !isEmpty(values)) {
            let item = clone(values[index]);
            if (type === RewardItems.stepValue) {
                item.stepValue = inputValue;
            }
            if (type === RewardItems.point) {
                item.point = inputValue;
            }
            if (type === RewardItems.experience) {
                item.experience = inputValue;
            }
            if (type === RewardItems.Hi) {
                item.goldValue = inputValue;
            }
            values = concat([], values);
            values.splice(index, 1, item);
        } else {
            let item: ActivityRewardCheckinValue = { couponsValue: [] };
            if (type === RewardItems.stepValue) {
                item.stepValue = inputValue;
            }
            if (type === RewardItems.point) {
                item.point = inputValue;
            }
            if (type === RewardItems.experience) {
                item.experience = inputValue;
            }
            if (type === RewardItems.Hi) {
                item.goldValue = inputValue;
            }
            values = concat([], item);
        }
        value.values = values;
        if (onChange) {
            onChange(value, name);
        }
    }

    onCheckboxChange(event: any, index: number, type: string, txtName?: string) {
        let { name, value, onChange } = this.props;
        if (!value) return;
        let values = value.values;
        let checkboxValue = event.target.checked;
        if (values !== null && !isEmpty(values)) {
            let item = clone(values[index]);
            if (type === RewardItems.point) {
                item.pointChecked = checkboxValue;
            }
            if (type === RewardItems.experience) {
                item.experienceChecked = checkboxValue;
            }
            if (type === RewardItems.coupons) {
                item.couponsChecked = checkboxValue;
            }
            if (type === RewardItems.Hi) {
                item.goldValueChecked = checkboxValue;
                item.goldValueTxtName = txtName;
            }
            values = concat([], values);
            values.splice(index, 1, item);
        } else {
            let item: ActivityRewardCheckinValue = { couponsValue: [] };
            if (type === RewardItems.point) {
                item.pointChecked = checkboxValue;
            }
            if (type === RewardItems.experience) {
                item.experienceChecked = checkboxValue;
            }
            if (type === RewardItems.coupons) {
                item.couponsChecked = checkboxValue;
            }
            if (type === RewardItems.Hi) {
                item.goldValueChecked = checkboxValue;
                item.goldValueTxtName = txtName;
            }
            values = concat([], item);
        }
        value.values = values;
        if (onChange) {
            onChange(value, name);
        }
    }

    onRadioChange = (e: any) => {
        let { name, value, onChange } = this.props;
        if (value && value.continuousCheckin === e.target.value) return;
        let item: ActivityRewardCheckinValue = { couponsValue: [] };
        const newValue = {
            continuousCheckin: e.target.value,
            values: value ? value.values : concat([], item),
        };
        if (onChange) {
            onChange(newValue, name);
        }
    };

    render() {
        let { value, disabled, maxCount = 7 } = this.props;
        let values = value ? value.values : [];
        let continuousCheckin = value ? value.continuousCheckin : null;
        return (
            <div className="activity-reward">
                <RadioGroup
                    disabled={disabled}
                    onChange={this.onRadioChange}
                    value={continuousCheckin}
                >
                    <Radio value={null}>{language.getText('wewjl')}</Radio>
                    <Radio value={false}>{language.getText('ljqd')}</Radio>
                    <Radio value={true}>
                        {language.getText('lxqd')}
                        <QuestionPoprver
                            style={{ display: 'inline' }}
                            content="<<jscclxtshdqhzxjs>>"
                        />
                    </Radio>
                </RadioGroup>
                {!isNull(continuousCheckin) &&
                    map(values, (reward, index) => this.renderReward(reward, index, disabled))}
                {(values && values.length) || isNull(continuousCheckin)
                    ? null
                    : this.renderReward({ couponsValue: [] }, 0, disabled)}
                {!isNull(continuousCheckin) && values && values.length < maxCount && (
                    <Button
                        type="default"
                        className="reward-add-reward-button"
                        onClick={() => this.onAddReward()}
                    >
                        {language.getText('addRule')}
                    </Button>
                )}
            </div>
        );
    }

    onChangeCoupon = (products: Coupon[], index: number) => {
        let { name, value, onChange } = this.props;
        if (!value) return;
        let values = value.values;
        if (values !== null && !isEmpty(values)) {
            let item = clone(values[index]);
            item.couponsValue = products;
            values = concat([], values);
            values.splice(index, 1, item);
        } else {
            let item: ActivityRewardCheckinValue = { couponsValue: [] };
            item.couponsValue = products;
            values = concat([], item);
        }
        value.values = values;
        if (onChange) {
            onChange(value, name);
        }
    };

    renderReward(reward: ActivityRewardCheckinValue, index: number, isDisabled: boolean) {
        const subsiteIds = this.getSubsiteIds(this.props);
        const { value, options } = this.props;
        const continuousCheckin = value ? value.continuousCheckin : true;
        const showGold = Boolean(
            options && options.length > 0 && options.find((item) => item.code === RewardItems.Gold)
        );
        const goldText =
            options && options.length
                ? options.find((item) => item.code === RewardItems.Gold)?.name ||
                  language.getText('hellob')
                : language.getText('hellob');
        const disabled =
            !isEmpty(reward) && ((reward.id && !isDisabled) || !reward.id) ? false : true;
        return (
            <div className="activity-reward" key={index}>
                <AntRow>
                    <div className="reward-content">
                        <div className="reward-setting">
                            <span className="reward-item-text">
                                {continuousCheckin
                                    ? language.getText('lx_2')
                                    : language.getText('lj_1')}
                                {language.getText('qd')}
                            </span>
                            <div className="reward-count-input">
                                <InputNumber
                                    disabled={disabled}
                                    type="number"
                                    min={1}
                                    value={reward.stepValue}
                                    onChange={(e) =>
                                        this.onInputValueChange(e, index, RewardItems.stepValue)
                                    }
                                />
                            </div>
                            <span className="reward-item-text">{language.getText('thdyxjl')}</span>
                        </div>
                        {disabled ? null : (
                            <Popconfirm
                                onConfirm={this.onRemoveReward.bind(this, index)}
                                title={language.getText('sfscggz')}
                                okText={language.getText('common.ok')}
                                cancelText={language.getText('common.cancel')}
                            >
                                <Button type="default" className="reward-remove-reward-button">
                                    {language.getText('scgz')}
                                </Button>
                            </Popconfirm>
                        )}
                    </div>
                </AntRow>

                <div className="reward-content-list">
                    <div className="reward-content-item">
                        <Checkbox
                            className="reward-content-item-checkbox"
                            onChange={(e) => this.onCheckboxChange(e, index, RewardItems.point)}
                            checked={reward.pointChecked}
                            value="point"
                        >
                            {language.getText('point')}
                        </Checkbox>
                        <span className="reward-item-text">{language.getText('song')}</span>
                        <InputNumber
                            type="number"
                            min={1}
                            value={reward.point}
                            onChange={(e) => this.onInputValueChange(e, index, RewardItems.point)}
                        />
                        <span className="reward-item-text">{language.getText('point')}</span>
                    </div>
                    <div className="reward-content-item">
                        <Checkbox
                            className="reward-content-item-checkbox"
                            onChange={(e) =>
                                this.onCheckboxChange(e, index, RewardItems.experience)
                            }
                            checked={reward.experienceChecked}
                            value="experience"
                        >
                            {language.getText('experienceManage')}
                        </Checkbox>
                        <span className="reward-item-text">{language.getText('song')}</span>
                        <InputNumber
                            type="number"
                            min={1}
                            value={reward.experience}
                            onChange={(e) =>
                                this.onInputValueChange(e, index, RewardItems.experience)
                            }
                        />
                        <span className="reward-item-text">{language.getText('value')}</span>
                    </div>
                    {showGold && (
                        <div className="reward-content-item">
                            <Checkbox
                                className="reward-content-item-checkbox"
                                onChange={(e) =>
                                    this.onCheckboxChange(e, index, RewardItems.Hi, goldText)
                                }
                                checked={reward.goldValueChecked}
                                value="experience"
                            >
                                {goldText}
                            </Checkbox>
                            <span className="reward-item-text">{language.getText('song')}</span>
                            <InputNumber
                                type="number"
                                min={1}
                                value={reward.goldValue}
                                onChange={(e) => this.onInputValueChange(e, index, RewardItems.Hi)}
                            />
                            <span className="reward-item-text">{goldText}</span>
                        </div>
                    )}
                </div>
                <div className="reward-content-list">
                    <div className="reward-content-item">
                        <Checkbox
                            className="reward-content-item-checkbox"
                            onChange={(e) => this.onCheckboxChange(e, index, RewardItems.coupons)}
                            checked={reward.couponsChecked}
                            value="coupons"
                        >
                            {language.getText('yhj')}
                        </Checkbox>
                    </div>
                    <div className="reward-coupons">
                        <ActivityRewardItem
                            index={index}
                            value={reward.couponsValue || []}
                            subsiteIds={subsiteIds}
                            onChangeCoupon={this.onChangeCoupon}
                            type="checkbox"
                            packageSelectType="radio"
                            canChangeQuantity
                        />
                    </div>
                </div>
            </div>
        );
    }

    getSubsiteIds = (props: any) => {
        const { row } = props;
        const subsiteIds =
            row.baseInfo && row.baseInfo.subsiteIds ? map(row.baseInfo.subsiteIds, 'id') : null;
        return subsiteIds;
    };
}
