import React, { PureComponent } from 'react';
import { Table, InputNumber, Button, message, Popconfirm, Icon, Tooltip } from 'antd';
import { cloneDeep, remove, find, forEach, isNumber } from 'lodash';
import { services } from '@comall-backend-builder/core';
import './index.less';

interface ProductSelectedDisplayProps {
    data: any[];
    onChange: (data: any[]) => void;
    modifiable: boolean;
}

interface ProductSelectedDisplayState {
    groupPrice: undefined | number;
    groupBuyPrice: undefined | number;
    groupStock: undefined | number;
}

/**
 * 商品展示和内容编辑
 */
class ProductSelectedDisplay extends PureComponent<
    ProductSelectedDisplayProps,
    ProductSelectedDisplayState
> {
    state = {
        groupPrice: undefined,
        groupBuyPrice: undefined,
        groupStock: undefined,
    };
    selectedRows: any[] = [];

    getTableConfig = () => {
        const { modifiable } = this.props;
        let config: any = {
            rowKey: 'id',
            columns: [
                {
                    title: services.language.getText('components.Products.name'),
                    dataIndex: 'productName',
                    key: 'productName',
                    width: 150,
                    fixed: 'left' as const,
                    render: (i: string) => (
                        <span title={i} className="goods-field-name">
                            {i}
                        </span>
                    ),
                },
                {
                    title: services.language.getText('goodName'),
                    dataIndex: 'name',
                    key: 'name',
                    width: 150,
                    render: (i: string) => (
                        <span title={i} className="goods-field-name">
                            {i}
                        </span>
                    ),
                },
                {
                    title: services.language.getText('productCode'),
                    dataIndex: 'productCode',
                    key: 'productCode',
                    width: 120,
                },
                {
                    title: services.language.getText('goodCode'),
                    dataIndex: 'goodsCode',
                    key: 'goodsCode',
                    width: 120,
                },
                {
                    title: services.language.getText('subsiteName'),
                    dataIndex: 'subsiteName',
                    key: 'subsiteName',
                    width: 120,
                },
                {
                    title: services.language.getText('merchantName'),
                    dataIndex: 'merchantName',
                    key: 'merchantName',
                    width: 120,
                },
                {
                    title: services.language.getText('marketPrice'),
                    dataIndex: 'marketPrice',
                    key: 'marketPrice',
                    width: 100,
                },
                {
                    title: services.language.getText('tzghj'),
                    key: 'groupPrice',
                    dataIndex: 'groupPrice',
                    width: 120,
                    align: 'center' as const,
                    render: (v: number, r: any) => {
                        return modifiable ? (
                            <InputNumber
                                value={v}
                                min={0.01}
                                onChange={(value: any) =>
                                    this.changeValue('groupPrice', value, r.id)
                                }
                            ></InputNumber>
                        ) : (
                            v
                        );
                    },
                },
                {
                    title: services.language.getText('tymjj'),
                    key: 'groupBuyPrice',
                    dataIndex: 'groupBuyPrice',
                    width: 120,
                    align: 'center' as const,
                    render: (v: number, r: any) => {
                        return modifiable ? (
                            <InputNumber
                                value={v}
                                min={0.01}
                                onChange={(value: any) =>
                                    this.changeValue('groupBuyPrice', value, r.id)
                                }
                            ></InputNumber>
                        ) : (
                            v
                        );
                    },
                },
                {
                    title: services.language.getText('profitProduct'),
                    dataIndex: 'profit',
                    key: 'profit',
                    width: 100,
                    align: 'center' as const,
                    render: (v: number) => {
                        return isNumber(+v) ? (+v).toFixed(2) : '';
                    },
                },
                {
                    title: () => (
                        <div>
                            {services.language.getText('sqtgkc')}{' '}
                            <Tooltip title={services.language.getText('hdbchjsdkc')}>
                                <Icon type="question-circle" />
                            </Tooltip>
                        </div>
                    ),
                    key: 'groupStock',
                    dataIndex: 'groupStock',
                    width: 120,
                    align: 'center' as const,
                    render: (v: number, r: any) => {
                        return modifiable ? (
                            <InputNumber
                                value={v}
                                min={1}
                                step={1}
                                precision={0}
                                max={r.groupLeftStock}
                                onChange={(value: any) =>
                                    this.changeValue('groupStock', value, r.id)
                                }
                            ></InputNumber>
                        ) : (
                            v
                        );
                    },
                },
                {
                    title: services.language.getText('sjkc'),
                    dataIndex: 'groupLeftStock',
                    key: 'groupLeftStock',
                    width: 100,
                    align: 'center' as const,
                },
            ],
            scroll: {
                x: 1900,
                y: 500,
            },
        };
        if (modifiable) {
            config.rowSelection = {
                onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                    this.selectedRows = selectedRows;
                },
            };
            config.columns.push({
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                key: 'operation',
                fixed: 'right' as const,
                width: 100,
                render: (i: any, row: any) => (
                    <Button type={'link'} onClick={this.onRemove(row.id)}>
                        {services.language.getText('common.delete')}
                    </Button>
                ),
            });
        }
        return config;
    };

    renderFooter = () => {
        const { groupPrice, groupBuyPrice, groupStock } = this.state;
        return (
            <div className="product-selected-footer">
                <div className="batch-remove">
                    <Popconfirm
                        onConfirm={this.batchRemove()}
                        title={services.language.getText('confirmDeleteColumn')}
                        okText={services.language.getText('common.ok')}
                        cancelText={services.language.getText('common.cancel')}
                    >
                        <Button className="setting-item-ok" type="default">
                            {services.language.getText('batchDelete')}
                        </Button>
                    </Popconfirm>
                </div>
                <div className="setting-item">
                    {services.language.getText('plsz')}：
                    <InputNumber
                        className="item-number"
                        placeholder={services.language.getText('tzghj')}
                        value={groupPrice}
                        min={0.01}
                        onChange={(e) => this.changeBatchInputValue('groupPrice', e)}
                    ></InputNumber>
                    <Button
                        onClick={this.batchChangeValue('groupPrice')}
                        className="setting-item-ok"
                        type="default"
                    >
                        {services.language.getText('common.ok')}
                    </Button>
                    <Button onClick={this.batchChangeValueCancel('groupPrice')}>
                        {services.language.getText('common.cancel')}
                    </Button>
                </div>
                <div className="setting-item">
                    {services.language.getText('plsz')}：
                    <InputNumber
                        className="item-number"
                        placeholder={services.language.getText('tymjj')}
                        value={groupBuyPrice}
                        min={0.01}
                        onChange={(e) => this.changeBatchInputValue('groupBuyPrice', e)}
                    ></InputNumber>
                    <Button
                        onClick={this.batchChangeValue('groupBuyPrice')}
                        className="setting-item-ok"
                        type="default"
                    >
                        {services.language.getText('common.ok')}
                    </Button>
                    <Button onClick={this.batchChangeValueCancel('groupBuyPrice')}>
                        {services.language.getText('common.cancel')}
                    </Button>
                </div>
                <div className="setting-item">
                    {services.language.getText('plsz')}：
                    <InputNumber
                        className="item-number"
                        placeholder={services.language.getText('sqtgkc')}
                        value={groupStock}
                        min={1}
                        step={1}
                        precision={0}
                        onChange={(e) => this.changeBatchInputValue('groupStock', e)}
                    ></InputNumber>
                    <Button
                        onClick={this.batchChangeValue('groupStock')}
                        className="setting-item-ok"
                        type="default"
                    >
                        {services.language.getText('common.ok')}
                    </Button>
                    <Button onClick={this.batchChangeValueCancel('groupStock')}>
                        {services.language.getText('common.cancel')}
                    </Button>
                </div>
            </div>
        );
    };

    onRemove = (id: string) => {
        const { data, onChange } = this.props;
        return () => {
            const result = cloneDeep(data);
            remove(result, (i) => id === i.id);
            onChange(result);
        };
    };

    batchRemove = () => {
        return () => {
            // @ts-ignore：
            const selectedRow = this.selectedRows;
            if (!selectedRow || selectedRow.length === 0) {
                message.info(services.language.getText('selectBatchDeleteColumn'));
                return;
            }
            const { data, onChange } = this.props;
            const result = cloneDeep(data);
            remove(result, (i) => !!find(selectedRow, (j) => j.id === i.id));
            onChange(result);
        };
    };
    batchChangeValueCancel = (field: string) => {
        return () => {
            // @ts-ignore：
            this.setState({ [field]: undefined });
        };
    };

    batchChangeValue = (field: string) => {
        return () => {
            // @ts-ignore：
            const fieldValue = this.state[field];
            const selectedRow = this.selectedRows;
            if (!selectedRow || selectedRow.length === 0) {
                message.info(services.language.getText('selectSetDataColumn'));
                return;
            }
            const { data, onChange } = this.props;
            const result = cloneDeep(data);
            forEach(result, (i) => {
                const item = find(selectedRow, (j) => i.id === j.id);
                if (item) {
                    if (field === 'groupStock' && fieldValue > i.groupLeftStock) {
                        i[field] = i.groupLeftStock;
                    } else {
                        i[field] = fieldValue;
                    }
                }
            });
            onChange(result);
        };
    };

    changeBatchInputValue = (field: 'groupPrice' | 'groupBuyPrice' | 'groupStock', value: any) => {
        // @ts-ignore：
        this.setState({ [field]: value });
    };

    changeValue = (field: string, value: any, id: string) => {
        const { data, onChange } = this.props;
        const result = cloneDeep(data);
        let item = find(result, (i) => id === i.id);
        if (item) {
            item[field] = value;
            onChange(result);
        }
    };

    render() {
        const { data, modifiable } = this.props;
        let tableConfig = this.getTableConfig();
        const tableProps = { ...tableConfig, dataSource: data };
        return (
            <div className={'product-select-display ' + (modifiable ? 'modify' : '')}>
                <Table {...tableProps} pagination={false}></Table>
                {modifiable && this.renderFooter()}
            </div>
        );
    }
}

export { ProductSelectedDisplay };
