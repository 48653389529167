import React, { Fragment, PureComponent } from 'react';
import { Calendar, Icon, message as AntMessage } from 'antd';
// @ts-ignore
import calendar from 'js-calendar-converter';
import { Moment } from 'moment';
import moment from 'moment';
import classNames from 'classnames';
import { services } from '@comall-backend-builder/core';
import {
    api,
    language,
    localStorage,
    privilege as servicePrivilege,
} from '@comall-backend-builder/core/lib/services';
import { EntitiesManager } from '@comall-backend-builder/core';
import { cloneDeep, unionBy } from 'lodash';
import { MarketingCalendarPageSettingStoreSelector } from './marketing-calendar-page-setting-store-selector/index';
import { MarketingCalendarPageSettingMarketButton } from './marketing-calendar-page-setting-market-button';
import { MarketingCalendarPageSettingActivityButton } from './marketing-calendar-page-setting-activity-button';
import './index.less';
import { ActivityScheduleType, goActivityTarget } from './target';

interface Schedules {
    activities: Activitys[];
}

interface Activitys {
    /**
     * 活动ID
     */
    activityId: number;
    /**
     * 活动名称
     */
    activityName: string;
    /**
     * 活动类型
     */
    activityType: string;
    /**
     * 活动类型名称
     */
    activityTypeName: string;
    /**
     * 活动结束时间
     */
    endTime: string;
    /**
     * 档期ID
     */
    marketingScheduleId: number;
    /**
     * 档期名称
     */
    marketingScheduleName: string;
    /**
     * 活动开始时间
     */
    startTime: string;
}
/**
 * 活动日历
 */
interface ActivityListDate {
    /**
     * 活动
     */
    activities: Activitys[];
    /**
     * 日期
     */
    date: string;
}

/**
 * 档期日历
 */
interface SchedulesListDate {
    /**
     * 档期
     */
    schedules: Schedules[];
    /**
     * 日期
     */
    date: string;
}

export interface MarketingCalendarPageSettingStates {
    /**
     * 开始渲染日历（在改变updateLocale之后渲染，周日才会展示在前面）
     */
    isStartLoadingCalendar: boolean;
    /**
     * 目前的时间
     */
    value: Moment;

    /**
     * 选择查询日历的状态
     */
    type: CalendarType;

    /**
     * 活动日历
     */
    activityListDate: ActivityListDate[];

    /**
     * 档期日历
     */
    scheduleListDate: SchedulesListDate[];

    /**
     * 选中当前的活动详情
     */
    activityDetail: any;
}
export enum CalendarType {
    ACTIVITY = 'ACTIVITY',
    SCHEDULE = 'SCHEDULE',
}

let Entity: any = {};
let entity: any = {};

export class MarketingCalendarPageSetting extends PureComponent<
    any,
    MarketingCalendarPageSettingStates
> {
    constructor(props: any) {
        super(props);
        Entity = EntitiesManager.get('storeSelect');
        entity = new Entity({});
        this.state = {
            isStartLoadingCalendar: false,
            value: moment(new Date()),
            type: CalendarType.SCHEDULE,
            activityListDate: [],
            scheduleListDate: [],
            activityDetail: undefined,
        };
    }

    componentDidMount() {
        moment.updateLocale('zh-cn', { week: { dow: 0 } });
        let store: any = localStorage.get('marketingCalendarPageSettingStoreSelector');
        this.setState({
            isStartLoadingCalendar: true,
        });
        let subsiteId = (store && store.id) || 0;
        this.getListData(subsiteId);
    }

    componentDidUpdate(nextProps: any, preState: any) {
        const { value } = this.state;
        if (
            this.state.activityListDate !== preState.activityListDate ||
            this.state.scheduleListDate !== preState.scheduleListDate
        ) {
            this.onShowDetail(value);
        }
    }

    componentWillUnmount() {
        moment.updateLocale('zh-cn', { week: { dow: 1 } });
    }

    /**
     * 获取档期/活动的数据
     * @param subsiteId 门店ID
     */
    getListData = async (subsiteId: number) => {
        const { value, type } = this.state;
        const newValue = cloneDeep(value);
        const currentFirstDay = newValue.startOf('month').format('YYYY-MM-DD HH:mm:ss');
        const currentLastDay = newValue.endOf('month').format('YYYY-MM-DD HH:mm:ss');
        const params = {
            subsiteId,
            startTime: currentFirstDay,
            endTime: currentLastDay,
        };
        services.loading.open();
        //默认查询的是活动日历
        if (type === CalendarType.ACTIVITY) {
            //查询活动日历
            await api
                .get(
                    {
                        ...params,
                    },
                    {
                        apiPath: `/admin/marketing_schedules/activity_calendar`,
                        apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                    }
                )
                .then((res: any) => {
                    services.loading.close();
                    this.setState({
                        activityListDate: res,
                    });
                });
        } else {
            //查询档期日历
            await api
                .get(
                    {
                        ...params,
                    },
                    {
                        apiPath: `/admin/marketing_schedules/calendar`,
                        apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                    }
                )
                .then((res: any) => {
                    services.loading.close();
                    this.setState({
                        scheduleListDate: res,
                    });
                });
        }
    };

    /**
     * 跳转到活动页面
     */
    goTarget = (type: ActivityScheduleType, activityId: number) => {
        const { privilege, route, pageType } = goActivityTarget(type);
        if (servicePrivilege.check(privilege.path, privilege.level)) {
            if (type === ActivityScheduleType.SLOT_MACHINE) {
                //老虎机
                window.open(`/#${route}/${pageType}/${activityId}/COMPLETE`);
            } else {
                window.open(`/#${route}/${pageType}/${activityId}`);
            }
        } else {
            AntMessage.warning(language.getText('noActivityPrivile'));
        }
    };

    /**
     * 改变日期事件
     */
    onChangeDate = (selectDate?: Moment) => {
        if (selectDate) {
            const date = moment(selectDate).format('YYYY-MM-DD');
            this.setState(
                {
                    value: selectDate,
                },
                () => {
                    this.refresh();
                    const listDate = this.getCurrentActivity(selectDate);
                    const month = selectDate.month() + 1;
                    const info: any = calendar.solar2lunar(
                        selectDate.year(),
                        month,
                        selectDate.date()
                    );
                    this.setState({
                        activityDetail: {
                            date,
                            listDate,
                            info,
                        },
                    });
                }
            );
        }
    };

    /**
     * 自定义头部内容
     */
    headerRender = (date: Moment, onChange: any) => {
        const { type, value } = this.state;
        let currentYear = date.format('YYYY年');
        let currentMonth = date.format('M月');
        const next = () => {
            let newMonth = moment(date).add(1, 'months');
            this.setState(
                {
                    value: newMonth,
                },
                () => {
                    this.refresh();
                }
            );
        };
        const prev = () => {
            let prevMonth = moment(date).subtract(1, 'months');
            this.setState(
                {
                    value: prevMonth,
                },
                () => {
                    this.refresh();
                }
            );
        };
        const dangqiStyle = {
            borderRadius: '5px 0 0 5px',
            borderRight: '0',
            borderColor: type === CalendarType.SCHEDULE ? '#4ba9ff' : '',
            backgroundColor: type === CalendarType.SCHEDULE ? '#e8f4ff' : '',
            color: type === CalendarType.SCHEDULE ? '#4ba9ff' : '',
        };
        const activityStyle = {
            borderRadius: '0 5px 5px 0',
            borderColor: type === CalendarType.ACTIVITY ? '#4ba9ff' : '',
            backgroundColor: type === CalendarType.ACTIVITY ? '#e8f4ff' : '',
            color: type === CalendarType.ACTIVITY ? '#4ba9ff' : '',
        };
        const currentDate = moment(value).format('YYYY-MM-DD');
        const today = moment().format('YYYY-MM-DD');
        const isDisalbed = currentDate === today;

        return (
            <div className="marketing-calendar-page-setting__top__date-wrap">
                <div onClick={prev}>
                    <Icon type="left" theme="outlined" />
                </div>
                <div className="marketing-calendar-page-setting__top__date-wrap__time">
                    <span>{currentYear} </span>
                    <span>{currentMonth}</span>
                </div>
                <div onClick={next}>
                    <Icon type="right" theme="outlined" />
                </div>
                <div
                    className={classNames(
                        'marketing-calendar-page-setting__top__date-wrap__today',
                        {
                            disabled: isDisalbed,
                        }
                    )}
                    onClick={() => this.toToday(isDisalbed)}
                >
                    {language.getText('backToday')}
                </div>
                <div className="marketing-calendar-page-setting__top__date-wrap__tabs">
                    <div
                        style={dangqiStyle}
                        className="marketing-calendar-page-setting__top__date-wrap__tab"
                        onClick={this.changeType.bind(this, CalendarType.SCHEDULE)}
                    >
                        {language.getText('accordingSchedule')}
                    </div>
                    <div
                        style={activityStyle}
                        className="marketing-calendar-page-setting__top__date-wrap__tab"
                        onClick={this.changeType.bind(this, CalendarType.ACTIVITY)}
                    >
                        {language.getText('accordingActivities')}
                    </div>
                </div>
            </div>
        );
    };

    /**
     * 切换营销活动方式
     * @param type 活动状态
     */
    changeType = (type: CalendarType) => {
        this.setState(
            {
                type,
            },
            () => {
                this.refresh();
            }
        );
    };

    /**
     * 返回今日 如果当前日期和选择日期一致 则不在执行
     */
    toToday = (isDisalbed: boolean) => {
        if (isDisalbed) {
            return;
        }
        this.setState(
            {
                value: moment(),
            },
            () => {
                this.refresh();
            }
        );
    };

    /**
     * @param value 当前展示日历的日期
     * @returns 返回当前日期活动列表
     */
    getCurrentActivity = (value: Moment) => {
        const { type, activityListDate, scheduleListDate } = this.state;
        const date = moment(value).format('YYYY-MM-DD');
        let listDate: any = [];

        if (type === CalendarType.ACTIVITY) {
            // 活动
            activityListDate.forEach((item: any) => {
                const itemDay = item.date;
                if (date === itemDay) {
                    const activities = cloneDeep(item.activities);
                    activities.length &&
                        activities.map((a: any) => {
                            a.content = a.activityName;
                            return a;
                        });
                    listDate = [...activities];
                }
            });
        } else {
            // 营销档期
            scheduleListDate.forEach((item: any) => {
                const itemDay = item.date;
                if (date === itemDay) {
                    const schedules = cloneDeep(item.schedules);
                    schedules.length &&
                        schedules.map((s: any) => {
                            s.content = s.marketingScheduleName;
                            s.isExpand = true;
                            return s;
                        });
                    listDate = [...schedules];
                }
            });
        }
        listDate = unionBy(listDate, 'content');
        return listDate;
    };
    refresh = () => {
        let store: any = localStorage.get('marketingCalendarPageSettingStoreSelector');
        if (store && store.id) {
            this.getListData(store.id);
        }
    };

    /**
     * 查看日期详情
     */
    onShowDetail = async (selectDate?: Moment) => {
        if (!selectDate) {
            return;
        }
        const date = moment(selectDate).format('YYYY-MM-DD');
        const listDate = this.getCurrentActivity(selectDate);
        const month = selectDate.month() + 1;
        const info: any = calendar.solar2lunar(selectDate.year(), month, selectDate.date());
        this.setState({
            activityDetail: {
                date,
                listDate,
                info,
            },
        });
    };

    /**
     * 左侧日历
     */
    renderLeft = () => {
        const { value, type } = this.state;
        const dateCellRender = (date: Moment) => {
            let listDate = this.getCurrentActivity(date);
            // 按活动在日期内但已结束的活动不展示
            if (type === CalendarType.ACTIVITY) {
                listDate = listDate.filter((item: any) => item.enabled === true);
            }
            const month = date.month() + 1;
            const info: any = calendar.solar2lunar(date.year(), month, date.date()); //当天信息(比如是否是节假日);
            const currentMonth = moment(value).month() + 1; //页面选中的月份
            return (
                <div className="marketing-calendar-page-setting__calendar-date">
                    <div
                        className={classNames(
                            'marketing-calendar-page-setting__calendar-date__day',
                            { 'no-current-month': info && info.cMonth !== currentMonth }
                        )}
                    >
                        {info && info.cDay === 1 && info.cMonth === currentMonth && (
                            <span>
                                {info.cMonth}
                                {language.getText('y_4')}
                            </span>
                        )}
                        {info && info.cDay && (
                            <span>
                                {info.cDay}
                                {language.getText('r_3')}
                            </span>
                        )}
                    </div>
                    <div className="marketing-calendar-page-setting__calendar-date__tag">
                        {info && info.Term && <span>{info.Term}</span>}
                        {info && info.festival && <span>{info.festival}</span>}
                        {info && info.lunarFestival && <span>{info.lunarFestival}</span>}
                    </div>
                    {listDate.map((item: any, index: number) => {
                        return (
                            <>
                                {index < 4 && (
                                    <div
                                        className="marketing-calendar-page-setting__calendar-date__content"
                                        key={item.content}
                                    >
                                        {item.content}
                                    </div>
                                )}
                            </>
                        );
                    })}
                </div>
            );
        };
        return (
            <div className="marketing-calendar-page-setting__center__left">
                <Calendar
                    value={value}
                    headerRender={({ value, onChange }) => this.headerRender(value, onChange)}
                    locale={moment}
                    dateCellRender={dateCellRender}
                    onChange={this.onChangeDate}
                />
            </div>
        );
    };

    /**
     * 按活动（右侧详情）
     */
    renderActivity = (listDate: any) => {
        return (
            <Fragment>
                <div className="marketing-calendar-page-setting__center__right-title">
                    {language.getText('allActivities')} ({listDate.length})
                </div>
                {listDate.map((item: any) => {
                    return (
                        <div
                            className="marketing-calendar-page-setting__center__right-item"
                            key={item.activityName}
                        >
                            <div className="marketing-calendar-page-setting__center__right-col">
                                <span
                                    className={classNames('name', {
                                        'special-name': item.enabled,
                                    })}
                                    onClick={() => {
                                        this.goTarget(item.activityType, item.activityId);
                                    }}
                                >
                                    {item.activityName}
                                </span>
                                {item.activityTypeName && (
                                    <span className="type-name">{item.activityTypeName}</span>
                                )}
                            </div>
                            <div className="marketing-calendar-page-setting__center__right-col">
                                {item.startTime && item.endTime && (
                                    <span className="time">
                                        {item.startTime &&
                                            moment(item.startTime).format('YYYY-MM-DD')}{' '}
                                        {item.endTime && moment(item.endTime).format('YYYY-MM-DD')}
                                    </span>
                                )}
                                {!item.startTime && !item.endTime && (
                                    <span className="time">{language.getText('longTerm')}</span>
                                )}
                                <span className="schedule-name">{item.marketingScheduleName}</span>
                            </div>
                        </div>
                    );
                })}
            </Fragment>
        );
    };

    /**
     * 切换展开和收起
     * @param index 索引
     * @param isExpand 是否展开
     */
    toggleActivity = (index: number, isExpand: boolean) => {
        const {
            activityDetail: { listDate },
        } = this.state;
        this.setState({
            activityDetail: {
                ...this.state.activityDetail,
                listDate: listDate.map((item: any, i: number) => {
                    if (index === i) {
                        item.isExpand = isExpand;
                    }
                    return item;
                }),
            },
        });
    };

    /**
     * 按档期
     */
    renderSchedules = () => {
        const { activityDetail } = this.state;
        // 按活动数量降序排序
        let newListDate = activityDetail.listDate.sort((item1: any, item2: any) => {
            return item2.activities?.length - item1.activities?.length;
        });

        return (
            <Fragment>
                {newListDate?.length > 0 &&
                    newListDate.map((item: any, index: number) => {
                        const editInfo = {
                            id: item.marketingScheduleId,
                            name: item.marketingScheduleName,
                            type: CalendarType.SCHEDULE,
                            length: item.activities?.length,
                        };
                        return (
                            <div key={item.date}>
                                <div className="marketing-calendar-page-setting__center__right-title schedules">
                                    <span className="marketing-calendar-page-setting__center__right-btnmarket">
                                        <MarketingCalendarPageSettingMarketButton
                                            isEdit={true}
                                            refresh={this.refresh}
                                            editInfo={editInfo}
                                        />
                                    </span>
                                    {item.activities?.length > 0 && item.isExpand && (
                                        <span
                                            className="marketing-calendar-page-setting__center__right-traggle"
                                            onClick={() => this.toggleActivity(index, false)}
                                        >
                                            {language.getText('putAway')}
                                        </span>
                                    )}
                                    {item.activities?.length > 0 && !item.isExpand && (
                                        <span
                                            className="marketing-calendar-page-setting__center__right-traggle"
                                            onClick={() => this.toggleActivity(index, true)}
                                        >
                                            {language.getText('launch')}
                                        </span>
                                    )}
                                </div>
                                {item.isExpand &&
                                    item.activities?.length > 0 &&
                                    item.activities.map((activity: any, index: number) => {
                                        return (
                                            <div className="marketing-calendar-page-setting__center__right-item">
                                                <div className="marketing-calendar-page-setting__center__right-col">
                                                    <span
                                                        className={classNames('name', {
                                                            'special-name': activity.enabled,
                                                        })}
                                                        onClick={() => {
                                                            this.goTarget(
                                                                activity.activityType,
                                                                activity.activityId
                                                            );
                                                        }}
                                                    >
                                                        {activity.activityName}
                                                    </span>
                                                    {activity.activityTypeName && (
                                                        <span className="type-name">
                                                            {activity.activityTypeName}
                                                        </span>
                                                    )}
                                                </div>
                                                <div className="marketing-calendar-page-setting__center__right-col">
                                                    <span className="time">
                                                        {moment(activity.startTime).format(
                                                            'YYYY-MM-DD'
                                                        )}{' '}
                                                        {language.getText('to')}{' '}
                                                        {moment(activity.endTime).format(
                                                            'YYYY-MM-DD'
                                                        )}
                                                    </span>
                                                </div>
                                            </div>
                                        );
                                    })}
                            </div>
                        );
                    })}
            </Fragment>
        );
    };

    /**
     * 右侧选中天的活动详情
     */
    renderRight = () => {
        const { activityDetail, type } = this.state;
        const listDate = activityDetail?.listDate;

        const showDate = moment(activityDetail?.date).format('YYYY.MM');
        const week = moment(activityDetail?.date).format('dddd');
        const showDay = moment(activityDetail?.date).format('DD');
        return (
            <div className="marketing-calendar-page-setting__center__right">
                <div className="marketing-calendar-page-setting__center__right-content">
                    <div className="marketing-calendar-page-setting__center__right-header">
                        <div>
                            <span className="marketing-calendar-page-setting__center__right-day">
                                {showDay}
                            </span>
                            <span className="marketing-calendar-page-setting__center__right-date">
                                {showDate}
                            </span>
                            <span className="marketing-calendar-page-setting__center__right-week">
                                {week}
                            </span>
                        </div>
                        <div>
                            <span className="marketing-calendar-page-setting__center__right-festival">
                                {activityDetail?.info?.festival ||
                                    activityDetail?.info?.Term ||
                                    activityDetail?.info?.lunarFestival}
                            </span>
                        </div>
                    </div>
                    <div className="marketing-calendar-page-setting__center__right-activity">
                        <>
                            {type === CalendarType.ACTIVITY &&
                                listDate?.length > 0 &&
                                this.renderActivity(listDate)}
                        </>
                    </div>

                    <div className="marketing-calendar-page-setting__center__right-activity">
                        <>
                            {type === CalendarType.SCHEDULE &&
                                listDate?.length > 0 &&
                                this.renderSchedules()}
                        </>
                    </div>
                </div>
            </div>
        );
    };

    render() {
        const { isStartLoadingCalendar } = this.state;
        if (!isStartLoadingCalendar) {
            return null;
        }

        return (
            <div className="marketing-calendar-page-setting">
                <div className="marketing-calendar-page-setting__top">
                    <MarketingCalendarPageSettingStoreSelector
                        refresh={this.refresh}
                        entity={entity}
                    />
                    <MarketingCalendarPageSettingMarketButton
                        isEdit={false}
                        refresh={this.refresh}
                    />
                    <MarketingCalendarPageSettingActivityButton />
                </div>
                <div className="marketing-calendar-page-setting__center">
                    {this.renderLeft()}
                    {this.renderRight()}
                </div>
            </div>
        );
    }
}
