import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { cloneDeep, find, forEach } from 'lodash';
import { Input, Modal, message as AntMessage } from 'antd';
import { MessageNotificationMethodValueItem } from '../../message-notification-method';
import { UploadFileAll } from '../../../../../../components/upload-file-all';
import { MessageParameterSelector } from '../../../../../../containers/wechat/components/message-parameter-selector';
import { PushAndSiteMailSceneType } from '../../../../../../containers/wechat/common';
import { RichTextPlus } from '../../../../../../components';
import './message-notification-email-edit-modal.less';

interface Props {
    visible: boolean;
    template: MessageNotificationMethodValueItem;
    scene: PushAndSiteMailSceneType;
    landingPage: any;
    onChange: (template: MessageNotificationMethodValueItem) => void;
    closeModal: () => void;
}
interface State {
    editTemplate: MessageNotificationMethodValueItem;
    subsiteWechantName: string;
    logoUrl: string;
    appId: string;
}

export class MessageNotificationEmailEditModal extends PureComponent<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            editTemplate: cloneDeep(props.template),
            subsiteWechantName: '',
            logoUrl: '',
            appId: '',
        };
    }

    handleSubmit = () => {
        const { onChange, closeModal } = this.props;
        const { editTemplate } = this.state;
        let msg = '';
        let flag = true;
        forEach(editTemplate.params, (param: any) => {
            if (param.fieldType === 'string' && !param.value) {
                msg = services.language.getText('requestTheme');
                flag = false;
            }
        });
        if (!flag) {
            AntMessage.error(msg);
            return;
        }
        onChange(editTemplate);
        closeModal();
    };

    getParams = () => {
        const { editTemplate } = this.state;
        let top, bottom;
        let messageList: any[] = [];
        let detailContentColor = '';
        if (editTemplate && editTemplate.params) {
            top = find(editTemplate.params, { name: 'top' });
            bottom = find(editTemplate.params, { name: 'bottom' });
            forEach(editTemplate.params, (param: any) => {
                if (param.name !== 'name' && param.name !== 'top' && param.name !== 'bottom') {
                    messageList.push(param);
                }
            });
            if (messageList.length > 0) {
                detailContentColor = messageList[0].color;
            }
        }
        return {
            top,
            bottom,
            messageList,
            detailContentColor,
        };
    };
    changeTheme = (e: any) => {
        this.commonHandleChange(e.target.value, 'string');
    };
    changeFile = (file: any) => {
        const { editTemplate } = this.state;
        if (!editTemplate) {
            return;
        }
        let newTemplate = cloneDeep(editTemplate);
        forEach(newTemplate.params, (item: any) => {
            if (item.fieldType === 'file') {
                item.value = [...item.value, file];
            }
        });
        this.setState({
            editTemplate: newTemplate,
        });
    };
    /**
     * 删除附件
     */
    removeFile = (file: any) => {
        const { editTemplate } = this.state;
        if (!editTemplate) {
            return;
        }
        let newTemplate = cloneDeep(editTemplate);
        forEach(newTemplate.params, (item: any) => {
            if (item.fieldType === 'file') {
                item.value = item.value.filter((o: any) => {
                    return o.id !== file.id;
                });
            }
        });
        this.setState({
            editTemplate: newTemplate,
        });
    };

    /**
     * 修改邮件正文
     */
    changeRichText = (value: any) => {
        this.commonHandleChange(value, 'mediumtext');
    };

    commonHandleChange = (value: any, name: string) => {
        const { editTemplate } = this.state;
        if (!editTemplate) {
            return;
        }
        let newTemplate = cloneDeep(editTemplate);
        forEach(newTemplate.params, (item: any) => {
            if (item.fieldType === name) {
                item.value = value;
            }
        });
        this.setState({
            editTemplate: newTemplate,
        });
    };

    render() {
        const { visible, closeModal, scene } = this.props;
        const { messageList } = this.getParams();
        const params = {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: `/FILE/admin/files/locations/1300/upload`,
        };
        const richText: any = {
            toolbarId: 'descriptionRichText',
            style: {
                height: 300,
            },
            uploadConfig: {
                apiPath: `/admin/images/upload`,
                fileName: 'fileName',
            },
            onChange: this.changeRichText,
        };
        return (
            <Modal
                visible={visible}
                width={1000}
                className="message-subscription-email-modal"
                title={services.language.getText('editMessageContent')}
                onCancel={closeModal}
                onOk={this.handleSubmit}
            >
                <div className="message-subscription-email-item">
                    <MessageParameterSelector params={{ scene: scene }} />
                    <div>{services.language.getText('qfzkydblmc')}</div>
                </div>
                {messageList &&
                    messageList.length > 0 &&
                    messageList.map((item: any) => {
                        return (
                            <div key={item.id}>
                                <div className="message-subscription-email-item">
                                    {item.fieldType === 'string' && (
                                        <div style={{ color: 'red' }}>*</div>
                                    )}
                                    <div className="message-subscription-email-label">
                                        {item.name}：
                                    </div>
                                    {item.fieldType === 'string' && (
                                        <Input
                                            className="message-subscription-email-input"
                                            placeholder={services.language.getText('requestTheme')}
                                            value={item.value}
                                            onChange={this.changeTheme}
                                        />
                                    )}
                                    {item.fieldType === 'file' && (
                                        <UploadFileAll
                                            params={params}
                                            onAddFile={this.changeFile}
                                            removeFile={this.removeFile}
                                            fileList={item.value}
                                        />
                                    )}
                                    {item.fieldType === 'mediumtext' && (
                                        <RichTextPlus {...richText} value={item.value} />
                                    )}
                                </div>
                            </div>
                        );
                    })}
            </Modal>
        );
    }
}
