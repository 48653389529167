import React, { Component } from 'react';
import { InputNumber, Button, Popconfirm, Checkbox } from 'antd';
import {
    // findIndex,
    // toString,
    remove,
} from 'lodash';
import { language, privilege } from '@comall-backend-builder/core/lib/services';

import {
    PayWithGiftRewardCouponSelector,
    CouponType,
    EventCouponValueTypeEnum,
} from './pay-with-gift-reward-select-coupon';
import { RewardItems } from './pay-with-gifts-reward';
import { services } from '@comall-backend-builder/core';

export interface Coupon {
    id: string;
    couponName: number;
    couponType: string;
    startTime: string;
    endTime: string;
    batchNo: string;
    couponTypeDes: string;
    cooperationPatternDesc: string;
    quantity?: number;
    stock: number;
    apportionPrice?: number;
    leftStock?: number;
    couponDefinitionId?: number;
}

// @ts-ignore
interface ActivityRewardProps {
    index: number;
    subsiteIds: any;
    value: Array<Coupon> | null;
    /**
     * 是否禁用（禁用只限制修改阶梯值、赠删阶梯，不限制修改阶梯内奖励内容）
     */
    disabled: boolean;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Array<Coupon>, index: number) => void;
    couponsChecked: boolean;
    onCheckboxChange: (value: any, index: number, type: string) => void;
    maxAwardNumberEveryDay?: number;
    onMaxAwardNumberEveryDayChange: (value: any) => void;
}

export class PayWithGiftRewardCoupons extends Component<ActivityRewardProps, any> {
    static defaultProps = {
        disabled: false,
    };
    PayWithGiftRewardCouponSelector: any;
    constructor(props: any) {
        super(props);
        this.state = {
            goNextpage: null,
        };
    }

    componentDidMount() {
        let { value, onChange, index } = this.props;
        if (!value) {
            onChange([], index);
        }
    }

    onChooseCoupon = () => {
        this.PayWithGiftRewardCouponSelector.toggleModal();
    };
    showModel = (ref: any) => {
        this.PayWithGiftRewardCouponSelector = ref;
    };
    // setDisplayType = (row: Coupon[]) => {
    //     let displayType = 'selectCoupon';
    //     if (row.length && row[0]?.couponType === 'PACKAGE') {
    //         displayType = 'selectCouponPackage';
    //     }
    //     return displayType;
    // };
    modalToCoupon = (item: any) => {
        return {
            ...item,
            id: item.id,
            couponDefinitionId: item.id,
            couponName: item.name || item.couponName,
            couponType: item.couponType || item.type,
            startTime: item.startTime,
            endTime: item.endTime,
            startDate: item.startDate,
            endDate: item.endDate,
            batchNo: item.code || item.batchNo,
            couponTypeDes: item.typeName || item.couponTypeDes,
            cooperationPatternDesc:
                (item.cooperationPattern && item.cooperationPattern.description) ||
                item.cooperationPatternDesc,
            consumeChannelDescription: item.consumeChannelDescription,
            quantity: item.quantity || 1,
            stock: item.stock,
            leftStock: item.leftStock,
            couponValue: item.couponValue,
            consumeRule: item.consumeRule,
        };
    };
    changeCoupons = (coupons: Array<any>, type: string) => {
        //支持多个选择时
        // let { value, onChange, index } = this.props;
        // let newCoupons: any = value ? value : [];
        // if (this.setDisplayType(newCoupons) !== type) {
        //     newCoupons = [];
        // }
        // if (type === 'selectCoupon') {
        //     coupons.forEach((selectCuopon: any) => {
        //         const hasIndex = findIndex(
        //             newCoupons,
        //             (coupon: any) => toString(coupon.id) === toString(selectCuopon.id)
        //         );
        //         if (hasIndex === -1) {
        //             newCoupons.push(selectCuopon);
        //         }
        //     });
        // } else {
        //     newCoupons = [...coupons];
        // }
        // newCoupons = newCoupons.map(this.modalToCoupon);
        // if (onChange) {
        //     onChange(newCoupons, index);
        // }
        //支持单个选择时
        let { onChange, index } = this.props;
        let newCoupons: any = [...coupons];
        newCoupons = newCoupons.map(this.modalToCoupon);
        if (onChange) {
            onChange(newCoupons, index);
        }
    };
    changeModal = () => {
        const { value, subsiteIds } = this.props;
        const params = {
            subsiteIds: subsiteIds,
        };
        return (
            <PayWithGiftRewardCouponSelector
                onRef={this.showModel}
                selectValues={value || []}
                rewardIndex={0}
                onChange={this.changeCoupons}
                params={params}
            />
        );
    };

    onChangeCoupon = (products: Coupon[]) => {
        let { onChange, index } = this.props;
        if (onChange) {
            onChange(products, index);
        }
    };

    CheckCoupon = (item: Coupon) => {
        const { id } = item;
        let hasPrivilege = true;
        let route = '';
        const baseUrl = '#/menu-list/coupon';
        switch (item.couponType) {
            case CouponType.PACKAGE:
                route = `${baseUrl}/coupon-package-rules/edit/${id}`;
                hasPrivilege = privilege.check('couponPackageRule', 'full');
                break;
            case CouponType.CASH:
                route = `${baseUrl}/cash-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('cashCouponRule', 'full');
                break;
            case CouponType.GIFT:
                route = `${baseUrl}/gift-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('giftCouponRule', 'full');
                break;
            case CouponType.FREIGHT:
                route = `${baseUrl}/freight-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('freightCouponRule', 'full');
                break;
            case CouponType.PARKING:
                route = `${baseUrl}/parking-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('parkingCouponRule', 'full');
                break;
            case CouponType.LOTTERY:
                route = `${baseUrl}/lottery-coupon/edit/${id}`;
                hasPrivilege = privilege.check('lotteryCouponRule', 'full');
                break;
            case CouponType.BALANCE:
                route = `${baseUrl}/balance-coupon-rules/edit/${id}`;
                hasPrivilege = privilege.check('balanceCouponRule', 'full');
                break;

            default:
                break;
        }
        if (hasPrivilege && route) {
            window.open(`${route}`, '_blank');
        } else {
            this.setState({
                goNextpage: {
                    id,
                    hasPrivilege,
                },
            });
        }
    };
    renderValue = (coupon: any) => {
        const { couponValue, couponType } = coupon;
        const couponRuleType = couponType;

        if (couponRuleType === CouponType.GIFT) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__text">
                        {services.language.getText('presents')}
                    </span>
                </div>
            );
        } else if (couponRuleType === CouponType.LOTTERY) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__num">{couponValue.value}</span>
                </div>
            );
        } else if (couponRuleType === CouponType.PACKAGE) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__text">
                        {services.language.getText('gifts')}
                    </span>
                </div>
            );
        } else if (
            couponRuleType === CouponType.PARKING &&
            couponValue.type === EventCouponValueTypeEnum.No
        ) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__text">
                        {services.language.getText('qm_2')}
                    </span>
                </div>
            );
        }
        if (!couponValue || !couponValue.value) {
            return null;
        } else if (
            couponType === CouponType.PARKING &&
            couponValue.type === EventCouponValueTypeEnum.Hours
        ) {
            return (
                <div className="event-coupon__price">
                    <span className="event-coupon__price__num">{couponValue.value}</span>
                    <span className="event-coupon__symbol">小时</span>
                </div>
            );
        }
        return (
            <div className="event-coupon__price">
                <span className="event-coupon__symbol">
                    {services.language.getText('monetaryUnit')}
                </span>
                <span className="event-coupon__price__num">{couponValue.value}</span>
            </div>
        );
    };
    renderCoupons = (coupon: any) => {
        const innerClassName = `${coupon.couponType}-coupon-popup__list__item`;
        const bg = 'https://b-puzhehei-cdn.co-mall.net/global/event_coupon_bg.png';
        const style = {
            backgroundImage: `url(${bg})`,
        };
        return (
            <div className={innerClassName}>
                <div className="event-coupon" style={style}>
                    <div className="event-coupon__left">{this.renderValue(coupon)}</div>
                    <div className="event-coupon__right">
                        <div className="event-coupon__right__title" title={coupon.couponName}>
                            {coupon.couponName}
                        </div>
                        <div className="event-coupon__right__time">
                            {coupon.startDate}-{coupon.endDate}
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    changeStock = (couponIndex: number, valueNum: number) => {
        const { value, onChange, index } = this.props;
        if (!value) {
            return;
        }
        let rowItem: any = value[couponIndex];

        if (rowItem) {
            rowItem.quantity = valueNum;
            if (onChange) {
                onChange(value, index);
            }
        }
    };
    deleteCouponItem = (couponIndex: number) => {
        const { value, onChange, index, onMaxAwardNumberEveryDayChange } = this.props;
        if (!value) {
            return;
        }
        let rowItem: any = value[couponIndex];
        if (rowItem) {
            remove(value, function(rowItemCoupon: Coupon) {
                return rowItemCoupon.id === rowItem.id;
            });
            if (onChange) {
                onChange(value, index);
                onMaxAwardNumberEveryDayChange(undefined);
            }
        }
    };

    onMaxAwardNumberEveryDayChange = (value: any) => {
        const { onMaxAwardNumberEveryDayChange } = this.props;
        onMaxAwardNumberEveryDayChange(value);
    };
    showCouponItem = () => {
        const { value, maxAwardNumberEveryDay } = this.props;
        if (value) {
            // const { goNextpage } = this.state;
            return value.map((item: Coupon, couponIndex: number) => {
                const unitTxt =
                    item.couponType === CouponType.PACKAGE
                        ? services.language.getText('ge')
                        : services.language.getText('zhang');
                return (
                    <div
                        key={item.id}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            padding: '10px 0',
                            overflow: 'scroll',
                            // maxWidth: '90%',
                        }}
                    >
                        <div>{this.renderCoupons(item)}</div>
                        <div style={{ padding: '0 20px', flexShrink: 0 }}>
                            库存：{item.leftStock}
                        </div>
                        <div style={{ flexShrink: 0 }}>
                            <span style={{ color: 'red', marginRight: '5px' }}>*</span>
                            {services.language.getText('mrmcff')}&nbsp;
                            <InputNumber
                                placeholder={services.language.getText('inputPlease')}
                                value={item.quantity}
                                min={1}
                                max={999999999}
                                onChange={(value: any) => this.changeStock(couponIndex, value)}
                                precision={0}
                            ></InputNumber>
                            &nbsp;&nbsp;{unitTxt}
                        </div>
                        <div style={{ marginLeft: '10px', flexShrink: 0 }}>
                            每天最多发放&nbsp;
                            <InputNumber
                                placeholder={services.language.getText('inputPlease')}
                                value={maxAwardNumberEveryDay}
                                min={1}
                                max={999999999}
                                onChange={(value: any) =>
                                    this.onMaxAwardNumberEveryDayChange(value)
                                }
                                precision={0}
                            ></InputNumber>
                            &nbsp;&nbsp;份
                        </div>
                        <Popconfirm
                            title={`${services.language.getText('qrsc')}${item.couponName}?`}
                            onConfirm={() => this.deleteCouponItem(couponIndex)}
                            okText="确认"
                            cancelText={language.getText('common.cancel')}
                        >
                            <Button type="link">
                                {services.language.getText('common.delete')}
                            </Button>
                        </Popconfirm>

                        {/*<Button type="link" onClick={() => this.CheckCoupon(item)}>*/}
                        {/*    查看券规则*/}
                        {/*</Button>*/}
                        {/*{goNextpage && !goNextpage.hasPrivilege && goNextpage.id === item.id && (*/}
                        {/*    <div style={{ color: 'red', flexShrink: 0 }}>暂无查看券规则权限</div>*/}
                        {/*)}*/}
                    </div>
                );
            });
        }
    };

    render() {
        const { index, couponsChecked, onCheckboxChange } = this.props;
        return (
            <div className="comment-activity-reward">
                <Checkbox
                    className="reward-content-item-checkbox"
                    onChange={(e) => onCheckboxChange(e, index, RewardItems.coupons)}
                    checked={couponsChecked}
                    value="coupons"
                >
                    优惠券
                </Checkbox>
                {couponsChecked && (
                    <Button type={'link'} onClick={this.onChooseCoupon} style={{ padding: 0 }}>
                        选择优惠券
                    </Button>
                )}
                {couponsChecked && (
                    <div className="comment-activity-reward__wrap">
                        {this.showCouponItem()}
                        {this.changeModal()}
                    </div>
                )}
            </div>
        );
    }
}
