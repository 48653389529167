import React, { PureComponent, createElement, Fragment } from 'react';

import {
    ComponentsManager,
    services,
    Loader,
    builder,
    actions,
} from '@comall-backend-builder/core';
import { connect } from 'react-redux';
import { message as AntMessage, Modal } from 'antd';
import { mapValues } from 'lodash';

const { getText } = services.language;

function mapStateToProps(_state: any, props: any) {
    const { entity } = props;
    return { fields: entity.fields, ...props };
}

const ModalEditFilterWordForm = connect(
    mapStateToProps,
    null
)((props: any) => {
    return createElement(ComponentsManager.get('ModifyForm'), {
        ...props,
        componentId: `ModalEditFilterWordForm-${props.row.id}`,
        style: {
            width: '550px',
        },
        labelCol: 4,
        fields: [{ property: 'filterWord' }, { property: 'id', className: 'hidden' }],
        onSubmit: (event: any, fields: any) => {
            props.onClose();
            const entityFields = mapValues(fields, (field, _name) => {
                return field.value;
            });
            const id = entityFields['id'];
            const params = {
                filterWord: entityFields['filterWord'],
            };
            Loader.load('put', {
                params,
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: `/admin/filter_word/${id}`,
            })
                .then(() => {
                    const { entity, params: entityParams } = props;
                    entity && entity.search(entityParams);
                    AntMessage.success(getText('actionSuccess'), 1.5);
                })
                .catch(services.errorHandle);
        },
    });
});

const ModalCreateFilterWordForm = connect(
    mapStateToProps,
    null
)((props: any) => {
    return createElement(ComponentsManager.get('CreateForm'), {
        ...props,
        componentId: `ModalCreateFilterWordForm`,
        style: {
            width: '550px',
        },
        labelCol: 4,
        fields: [{ property: 'filterWord' }],
        onSubmit: (event: any, fields: any) => {
            props.onClose();
            const entityFields = mapValues(fields, (field, _name) => {
                return field.value;
            });

            const params = {
                filterWord: entityFields['filterWord'],
            };
            Loader.load('post', {
                params,
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: '/admin/filter_word',
            })
                .then(() => {
                    const { entity, params: entityParams } = props;
                    entity && entity.search(entityParams);
                    AntMessage.success(getText('actionSuccess'), 1.5);

                    let { fields: entityFields } = entity;
                    const loaded = !!entityFields;
                    builder.getStore().dispatch(
                        actions.formInitAction('ModalCreateFilterWordForm', {
                            type: 'EditForm',
                            entityId: entity.id,
                            fields: { filterWord: '' },
                            loaded,
                        })
                    );
                })
                .catch(services.errorHandle);
        },
    });
});

const ModalCreateBatchFilterWordForm = connect(
    mapStateToProps,
    null
)((props: any) => {
    return createElement(ComponentsManager.get('CreateForm'), {
        ...props,
        componentId: `ModalCreateBatchFilterWordForm`,
        style: {
            width: '550px',
        },
        labelCol: 4,
        fields: [
            {
                property: 'batchName',
            },
        ],
        onSubmit: (event: any, fields: any) => {
            props.onClose();
            const entityFields = mapValues(fields, (field, _name) => {
                return field.value;
            });

            const params = {
                filterWord: entityFields['batchName'],
            };
            Loader.load('post', {
                params,
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: '/admin/filter_word/batch',
            })
                .then((res: any) => {
                    if (res.message) {
                        AntMessage.warning(res.message);
                    }
                    const { entity, params: entityParams } = props;
                    entity && entity.search(entityParams);
                    AntMessage.success(getText('actionSuccess'), 1.5);

                    let { fields: entityFields } = entity;
                    const loaded = !!entityFields;
                    builder.getStore().dispatch(
                        actions.formInitAction('ModalCreateBatchFilterWordForm', {
                            type: 'EditForm',
                            // entityId: entity.id,
                            fields: { batchName: '' },
                            loaded,
                        })
                    );
                })
                .catch(services.errorHandle);
        },
    });
});
export class SetFilterWordModal extends PureComponent<any> {
    state = { visible: false };
    openModal = () => {
        this.setState({ visible: true });
        const { edit } = this.props;
        if (edit) {
            const { entity, row } = this.props;
            entity &&
                entity.setFields({
                    ...row,
                });
        }
    };

    //@ts-ignore
    listenRef: {
        handleSubmit: () => Promise<any>;
    };
    render() {
        const { edit, triggerComponent, batchAdd } = this.props;
        const buttonProps = {
            ...triggerComponent,
            onClick: this.openModal,
        };
        let title = '';
        if (batchAdd) {
            title = services.language.getText('pltjmgz');
        } else {
            title = !!edit ? getText('bjmgz') : getText('xzmgz');
        }
        return (
            <Fragment>
                {createElement(ComponentsManager.get('Button'), buttonProps)}
                <Modal
                    visible={this.state.visible}
                    title={title}
                    mask={false}
                    maskClosable
                    okText={services.language.getText('common.ok')}
                    cancelText={services.language.getText('common.cancel')}
                    width="580px"
                    onOk={() => {
                        this.listenRef.handleSubmit();
                    }}
                    onCancel={() => {
                        const { entity, row, edit } = this.props;
                        let { fields: entityFields } = entity;
                        const loaded = !!entityFields;
                        if (batchAdd) {
                            builder.getStore().dispatch(
                                actions.formInitAction('ModalCreateBatchFilterWordForm', {
                                    type: 'EditForm',
                                    entityId: entity.id,
                                    fields: { batchName: '' },
                                    loaded,
                                })
                            );
                        } else if (!!edit) {
                            builder.getStore().dispatch(
                                actions.formInitAction(`ModalEditFilterWordForm-${row.id}`, {
                                    type: 'EditForm',
                                    entityId: entity.id,
                                    fields: { ...row },
                                    loaded,
                                })
                            );
                        } else {
                            builder.getStore().dispatch(
                                actions.formInitAction('ModalCreateFilterWordForm', {
                                    type: 'EditForm',
                                    entityId: entity.id,
                                    fields: { filterWord: '' },
                                    loaded,
                                })
                            );
                        }

                        this.setState({ visible: false });
                    }}
                >
                    {batchAdd ? (
                        <ModalCreateBatchFilterWordForm
                            {...this.props}
                            wrappedComponentRef={(ref: any) => (this.listenRef = ref)}
                            onClose={() => {
                                this.setState({ visible: false });
                            }}
                        />
                    ) : edit ? (
                        <ModalEditFilterWordForm
                            {...this.props}
                            wrappedComponentRef={(ref: any) => (this.listenRef = ref)}
                            onClose={() => {
                                this.setState({ visible: false });
                            }}
                        />
                    ) : (
                        <ModalCreateFilterWordForm
                            {...this.props}
                            wrappedComponentRef={(ref: any) => (this.listenRef = ref)}
                            onClose={() => {
                                this.setState({ visible: false });
                            }}
                        />
                    )}
                </Modal>
            </Fragment>
        );
    }
}
