import React, { PureComponent, createElement } from 'react';
import { remove, forEach, map, find, isEmpty } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Modal, message } from 'antd';
import { PointTag, PointTagsProperty } from '../../containers/select-point-tag';
import { GoodsActivity } from '../../components';

const TableComponentId = 'PointTagSelectorTable';
let Entity: any = {};
let entity: any = {};

/**
 * 积分商城限购--选择标签，  Modal
 */
export class PointTagSelector extends PureComponent<{
    selectValues: any;
    onChange: (products: GoodsActivity[]) => void;
    params?: any;
    type?: 'radio';
    columns?: PointTagsProperty[];
    fields?: PointTagsProperty[];
    subsites?: string;
    disabled: any;
}> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('DevilerPointTagSelector');
        entity = new Entity({});
    }
    dispatch: any = {};
    state = { visible: false };

    selectedRows = new Array<GoodsActivity>();

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(entity));
    }

    onTableRowSelect = (record: any, selected: boolean) => {
        const { type } = this.props;
        if (selected) {
            if ('radio' === type) {
                this.selectedRows = [record];
            } else {
                if (find(this.selectedRows, { id: record.id })) {
                    return;
                }
                this.selectedRows.push(record);
            }
        } else {
            remove(this.selectedRows, (i) => {
                return i.id === record.id;
            });
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: any) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            this.selectedRows = [];
        }
    };

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
    };

    onOk = () => {
        const { onChange } = this.props;
        if (onChange) {
            forEach(this.selectedRows, (subsite) => {
                subsite.select = true;
            });
            onChange(this.selectedRows);
            this.toggleModal();
        }
    };

    getConfig = () => {
        const { params, selectValues, type, fields, columns } = this.props;
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: params || {},
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    // style: { textAlign: 'right' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        entity.search(params);
                    },
                    fields:
                        fields && fields.length
                            ? [...fields]
                            : [
                                  {
                                      property: 'name',
                                  },
                                  {
                                      property: 'status',
                                  },
                              ],
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    scroll: {
                        y: 400,
                    },
                    rowSelection: {
                        type: type ? type : 'checkbox',
                        selectedRowKeys: map(selectValues, 'id'),
                        onSelect: (record: PointTag, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: PointTag[]) => {
                            this.onTableRowSelectAll(selected, rows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns:
                        columns && columns.length
                            ? [...columns]
                            : [
                                  {
                                      property: 'name',
                                  },
                                  {
                                      property: 'sequence',
                                  },
                                  {
                                      property: 'status',
                                  },
                              ],
                },
            ],
        };
    };

    toggleModal = () => {
        const { visible } = this.state;
        const { params, subsites } = this.props;
        if (!subsites || (subsites && isEmpty(subsites))) {
            message.error(services.language.getText('selectBeforeMerchant'));
            return;
        }

        this.setState({ visible: !visible }, () => {
            if (!visible && entity.paging) {
                entity.search({
                    ...entity.paging,
                    ...params,
                });
                this.onClearAll();
            }
        });
    };

    render() {
        const { disabled } = this.props;
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 900,
            title: services.language.getText('xzbq'),
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };

        return (
            <div style={{ display: 'inline-block' }}>
                {!disabled ? (
                    <div>
                        <Button type="primary" onClick={this.toggleModal}>
                            + {services.language.getText('xzbq')}
                        </Button>
                        <Modal {...modalProps}>{modalContent}</Modal>
                    </div>
                ) : (
                    <Button type="primary" onClick={this.toggleModal} disabled>
                        + {services.language.getText('xzbq')}
                    </Button>
                )}
            </div>
        );
    }
}
