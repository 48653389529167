import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
const api = services.api;
export const RoleLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiPath = '/admin/roles';
        const { id } = data;
        if (!id) {
            if (data.status === 'ALL') {
                delete data.status;
            }
            return await api.get(data, config);
        } else {
            config.apiPath = `/admin/roles/${id}`;
            return await api.get({}, config);
        }
    },
};
