import React, { Component } from 'react';
import { InputNumber, Button, message, Popconfirm, message as AntMessage } from 'antd';
import { clone, isEqual, map, concat, isEmpty, forEach } from 'lodash';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

type RulesValue = {
    /**
     * 邀请好友成功次数
     */
    inviteValue?: number;
    /**
     * 获得抽奖机会
     */
    rewardValue?: number;
};

export interface AffiliateMarkingInviteFriendsrProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Array<RulesValue> | null, name: string) => void;
    /**
     * 当前值
     */
    value: Array<RulesValue> | null;
    /**
     * 是否禁止删除和新增规则
     */
    disabled: boolean;
}

/**
 * 邀请好友阶梯组件
 */
export class AffiliateMarkingInviteFriends extends Component<AffiliateMarkingInviteFriendsrProps> {
    harderChangeSetting = () => {
        const { onChange, name, value } = this.props;
        if (value && value.length > 0) {
            onChange(null, name);
        } else {
            onChange([{}], name);
        }
    };
    onInviteValueChange(inputValue: any, index: number) {
        let { name, value, onChange } = this.props;
        if (!isEmpty(inputValue) && inputValue <= 0) {
            message.warning(language.getText('qtxdydy'));
            return;
        }
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.inviteValue = inputValue;
            value = concat([], value);
            value.splice(index, 1, item);
            if (onChange) {
                onChange(value, name);
            }
        }
    }

    onRewardValueChange(inputValue: any, index: number) {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.rewardValue = inputValue;
            value = concat([], value);
            value.splice(index, 1, item);
            if (onChange) {
                onChange(value, name);
            }
        }
    }

    onAddRule() {
        let { name, value, onChange } = this.props;
        if (value && value.length > 0) {
            let hasValidate = true;
            forEach(value, (reward: any) => {
                if (!reward.inviteValue) {
                    hasValidate = false;
                }
                if (!reward.rewardValue) {
                    hasValidate = false;
                }
            });
            if (!hasValidate) {
                AntMessage.warning(language.getText('qjysxxtxwz'));
                return false;
            }
        } else {
            value = [{}];
        }
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(value.length, 0, {});
        }

        if (onChange) {
            onChange(value, name);
        }
    }

    onRemoveRule(index: number) {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(index, 1);
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    shouldComponentUpdate(nextProps: AffiliateMarkingInviteFriendsrProps) {
        return !isEqual(this.props, nextProps);
    }

    render() {
        let { value, disabled } = this.props;
        disabled = disabled ? disabled : false;
        return (
            <div className="affiliate-marketing-invite-friends">
                <div>
                    <Button type="primary" onClick={this.harderChangeSetting}>
                        {value && value.length > 0
                            ? services.language.getText('clearSetting')
                            : services.language.getText('settings')}
                    </Button>
                </div>
                {map(value, (rule, index) => this.renderRule(rule, index, disabled))}
                {value && value.length > 0 && !disabled && (
                    <Button
                        type="default"
                        className="add-rule-button"
                        onClick={() => this.onAddRule()}
                    >
                        {language.getText('addRule')}
                    </Button>
                )}
            </div>
        );
    }

    renderRule(rule: RulesValue, index: number, disabled: boolean) {
        return (
            <div className="rule" key={index}>
                <div className="rule-content">
                    <span className="item-text">{language.getText('yqhycg')}</span>
                    <div className="rule-count-input">
                        <InputNumber
                            min={1}
                            max={999999}
                            maxLength={6}
                            step={1}
                            precision={0}
                            value={rule.inviteValue}
                            placeholder={services.language.getText('inputPlease')}
                            onChange={(e) => this.onInviteValueChange(e, index)}
                        />
                    </div>
                    <span className="item-text"> {language.getText('gjhd')} </span>
                    <div className="rule-count-input">
                        <InputNumber
                            min={1}
                            max={999999}
                            maxLength={6}
                            step={1}
                            precision={0}
                            value={rule.rewardValue}
                            placeholder={services.language.getText('inputPlease')}
                            onChange={(e) => this.onRewardValueChange(e, index)}
                        />
                    </div>
                    <span className="item-text"> {language.getText('ccjjh')}</span>
                    {!disabled && (
                        <Popconfirm
                            onConfirm={this.onRemoveRule.bind(this, index)}
                            title={services.language.getText('sfscggz')}
                            okText={language.getText('common.ok')}
                            cancelText={language.getText('common.cancel')}
                        >
                            <span style={{ color: '#1890ff' }}>
                                {language.getText('common.delete')}
                            </span>
                        </Popconfirm>
                    )}
                </div>
            </div>
        );
    }
}
