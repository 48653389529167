import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { SaleSelectProduct } from '../../../containers';
import { SaleProductSelectedDisplay } from '../../../components';

export class SaleProductSelectorMode extends modes.ArrayMode {
    getDisplayComponent(value: any) {
        return (
            <SaleProductSelectedDisplay
                data={value}
                modifiable={false}
                onChange={() => {}}
            ></SaleProductSelectedDisplay>
        );
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <SaleSelectProduct {...displayInfo} />;
    }
}
