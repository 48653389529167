import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { SchedulerMethods } from '../../../components';

export class SchedulerMethodsMode extends ObjectMode {
    public getControlComponent(controlInfo: any) {
        return <SchedulerMethods {...controlInfo} />;
    }
}
