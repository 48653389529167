import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { FileType } from '../config/report-data/report/coupon-code/coupon-download';

const api = services.api;

export const CouponDownloadLoader: Loader = {
    async get(data, config: ApiRequestConfig) {
        if (data.dataType === FileType.ALL) {
            data = {
                ...data,
                dataType: undefined,
            };
        }

        return api.get(data, {
            ...config,
            apiPath: '/admin/coupon_consume_reports/download_data',
        });
    },
};
