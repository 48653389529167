import React from 'react';
import { Button } from 'antd';
import './index.less';
import { NumberInputTip } from '../../../../components';
import UserConditionModal from '../userConditionModal';

import { ConditionType } from '../../maotai-presale-conditions/conditonItem';
import { services } from '@comall-backend-builder/core';

export interface ConditionsItem {
    conditionValue: any;
    conditionType: ConditionType;
}

export default class Rule extends React.Component<any> {
    state = { visible: false, conditionRules: [], editParentRuleIndex: null };

    onAddRule = () => {
        const { value, onChange } = this.props;
        const data = value.map((i: any) => {
            if (i.conditionType === 'LIMIT3') {
                return {
                    ...i,
                    rules: [
                        ...i.rules,
                        {
                            num: '',
                            conditionRules: [[{ conditionType: 'REALNAME', checked: true }]],
                        },
                    ],
                };
            }
            return i;
        });
        onChange(data);
    };
    onDeleteRule = (index: number) => {
        const { value, onChange } = this.props;
        const data = value.map((i: any) => {
            if (i.conditionType === 'LIMIT3') {
                const rules = i.rules.filter((_: any, i: number) => i !== index);
                return { ...i, rules };
            }
            return i;
        });
        onChange(data);
    };
    numChange = (val: number, index: number) => {
        const { value, onChange } = this.props;
        const data = value.map((i: any) => {
            if (i.conditionType === 'LIMIT3') {
                const rules = i.rules.map((item: any, itemIndex: number) => {
                    if (index === itemIndex) {
                        return { ...item, num: val };
                    }
                    return item;
                });
                return { ...i, rules };
            }
            return i;
        });
        onChange(data);
    };

    onOpen = (conditionRules: ConditionsItem[][], editParentRuleIndex: number) => {
        this.setState({ conditionRules, editParentRuleIndex }, () => {
            this.setState({
                visible: true,
            });
        });
    };
    onOkClick = (val: any) => {
        const { value, onChange } = this.props;

        const data = value.map((i: any) => {
            if (i.conditionType === 'LIMIT3') {
                const rules = i.rules.map((item: any, itemIndex: number) => {
                    if (this.state.editParentRuleIndex === itemIndex) {
                        return { ...item, conditionRules: val };
                    }
                    return item;
                });
                return { ...i, rules };
            }
            return i;
        });

        onChange(data);
        this.setState({ visible: false });
    };

    onCloseClick = () => {
        this.setState({ visible: false });
    };
    render() {
        const { value } = this.props;
        const { visible, conditionRules } = this.state;
        const list = value.find((i: any) => i.conditionType === 'LIMIT3')?.rules || [];
        return (
            <div className="maotai-presale-prize-rule">
                {list.map((item: any, index: number) => {
                    return (
                        <div className="maotai-presale-prize-rule-item" key={index}>
                            <span className="maotai-presale-prize-rule-index">
                                {services.language.getText('rule')}
                                {index + 1}:
                            </span>
                            <NumberInputTip
                                min={0}
                                precision={0}
                                max={999999999}
                                value={item.num}
                                style={{ width: 120 }}
                                addonbefore={services.language.getText('mchdqhdqjbttjyhsgsldxz')}
                                addonafter={services.language.getText('p')}
                                onChange={(val: number) => {
                                    this.numChange(val, index);
                                }}
                            ></NumberInputTip>
                            <Button
                                className="maotai-presale-prize-rule-condition"
                                onClick={() => {
                                    this.onOpen(item.conditionRules || [], index);
                                }}
                                type="link"
                            >
                                {services.language.getText('xzrytj')}
                            </Button>
                            <Button
                                disabled={list.length === 1}
                                className="maotai-presale-prize-rule-delete"
                                onClick={() => {
                                    this.onDeleteRule(index);
                                }}
                            >
                                {services.language.getText('common.delete')}
                            </Button>
                        </div>
                    );
                })}
                <div className="maotai-presale-prize-rule-bottom">
                    <Button className="maotai-presale-prize-rule-add" onClick={this.onAddRule}>
                        {services.language.getText('addRule')}
                    </Button>
                </div>
                <UserConditionModal
                    onOk={this.onOkClick}
                    onClose={this.onCloseClick}
                    visible={visible}
                    conditionRules={conditionRules}
                    {...this.props}
                ></UserConditionModal>
            </div>
        );
    }
}
