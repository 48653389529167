import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import { UpgradeGift } from './upgrade-gift';

export class ArrayUpgradeGiftMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(displayInfo: any) {
        return <UpgradeGift {...displayInfo} />;
    }
}
