import React, { PureComponent } from 'react';
import { Form } from 'antd';
import { tools } from '@comall-backend-builder/design-components-basis';

import './index.less';
/**
 * 专柜详情组件 - 配置专柜介绍
 */
class merchantIntro extends PureComponent<any> {
    render() {
        return (
            <Form className="editor-merchant-intro">
                <div className="tips">展示后台配置的专柜介绍内容</div>
            </Form>
        );
    }
}

export const MerchantIntro = tools.ConfigFormDecorator(merchantIntro);
