import React, { PureComponent, createElement } from 'react';
import { Button, Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { services, ComponentsManager, EntitiesManager } from '@comall-backend-builder/core';
import { Entity } from '@comall-backend-builder/core/lib/parser/entity';

const {
    language: { getText },
} = services;

let NewMallActivitySettingEntity: typeof Entity | undefined;

/**
 * 审核按钮
 */
export class NewMallActivitySettingButton extends PureComponent<any, { showModal: boolean }> {
    constructor(props: any) {
        super(props);
        NewMallActivitySettingEntity = EntitiesManager.get('NewMallActivitySetting');
        this.entity = new NewMallActivitySettingEntity({});
        this.state = {
            showModal: false,
        };
    }
    entity: Entity;

    openModal = () => {
        this.setState({ showModal: true });
        this.entity.get({});
    };

    closeModal = () => {
        this.setState({ showModal: false });
    };

    getConfig = () => {
        return {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: this.entity,
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'displayMallActivityStock' },
                { property: 'displayMallActivityStatus' },
            ],
            submit: true,
            onSubmitSuccess: () => {
                this.closeModal();
            },
        };
    };

    render() {
        const { showModal } = this.state;
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps: ModalProps = {
            width: 900,
            title: getText('settings'),
            visible: showModal,
            onCancel: this.closeModal,
            footer: null,
        };
        return (
            <div>
                <Button style={{ marginLeft: '5px' }} type="default" onClick={this.openModal}>
                    {getText('settings')}
                </Button>
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}
