import React, { PureComponent } from 'react';

import { Button, Modal, Form, message } from 'antd';
import { services } from '@comall-backend-builder/core';
import get from 'lodash/get';

import { CustomSelectSubsite } from '../custom-select-subsite-select';
import { CustomSelectCategory } from './virtual-category-modal';

const FormItem = Form.Item;

const language = services.language;

const FormItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 20 },
    },
};

const DEFAULT_VALUE = {
    linkType: '',
    linkParams: {
        id: '',
        name: '',
    },
};

export interface CustomSelectCouponProps {
    value: any;
    contentConfig: Record<string, any>;
    modalConfig: Record<string, any>;
    onChange(value: any): void;
}

interface CustomSelectCouponState {
    visible: boolean;
    linkParams: any;
}

class CustomVirtualCategory extends PureComponent<
    CustomSelectCouponProps,
    CustomSelectCouponState
> {
    constructor(props: CustomSelectCouponProps) {
        super(props);
        const linkParams = get(props.value, 'linkParams') || DEFAULT_VALUE.linkParams;

        this.state = {
            visible: false,
            linkParams: linkParams,
        };
    }

    visibleModalChange = (visible: boolean): void => {
        this.setState({ visible });
    };

    /** 参数设置 */
    handleChange = (params: any, type: any): void => {
        let { linkParams } = this.state;
        if (type === 'subSite') {
            linkParams = {
                ...linkParams,
                id: params.id,
                subsiteId: params.id,
                name: params.name,
                categoryId: '',
                categoryName: '',
            };
        } else if (type === 'category') {
            linkParams = { ...linkParams, categoryId: params.id, categoryName: params.name };
        }

        this.setState({ linkParams });
    };

    onOk = () => {
        const { linkParams } = this.state;
        const { onChange } = this.props;

        if (linkParams.id === '' || linkParams.id === undefined) return message.error('请选择门店');

        if (linkParams.categoryId === '' || linkParams.categoryId === undefined)
            return message.error('请选择虚拟分类');

        onChange && onChange({ ...linkParams });

        this.handleCancel();
    };

    handleShowModal = (): void => {
        this.visibleModalChange(true);
    };

    handleCancel = (): void => {
        this.visibleModalChange(false);
    };

    render() {
        const { visible, linkParams } = this.state;
        const linkName = get(linkParams, 'name');
        const subSiteId = get(linkParams, 'subsiteId');
        const categoryName = get(linkParams, 'categoryName');
        const categoryId = get(linkParams, 'categoryId');

        return (
            <span className="link-name">
                {linkName ? <span className="link-name-label">{linkName}</span> : '未选择'}

                <Button type="link" className="link-type-edit" onClick={this.handleShowModal}>
                    {linkName
                        ? language.getText('components.Tools.modify')
                        : language.getText('components.Tools.select')}
                </Button>
                <Modal
                    title="请选择"
                    width="800px"
                    visible={visible}
                    onOk={this.onOk}
                    onCancel={this.handleCancel}
                >
                    <Form {...FormItemLayout}>
                        <FormItem label={'选择门店'} required>
                            <CustomSelectSubsite
                                value={{ name: linkName, id: subSiteId, subsiteId: subSiteId }}
                                onChange={(value) => this.handleChange(value, 'subSite')}
                            />
                        </FormItem>
                        <FormItem label={'虚拟分类'} required>
                            <CustomSelectCategory
                                type="virtualCategory"
                                {...this.props}
                                onChange={(value) => this.handleChange(value, 'category')}
                                value={{
                                    linkParams: {
                                        name: categoryName,
                                        id: categoryId,
                                        subsiteId: subSiteId,
                                    },
                                }}
                            />
                        </FormItem>
                    </Form>
                </Modal>
            </span>
        );
    }
}

export { CustomVirtualCategory };
