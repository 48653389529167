import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { CarparkInvoiceConfig } from '../../../components';

export class ObjectCarparkInvoiceConfigMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <CarparkInvoiceConfig {...controlInfo}></CarparkInvoiceConfig>;
    }
}
