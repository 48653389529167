import React, { PureComponent } from 'react';
import { SelectAutoComplete } from '@comall-backend-builder/components-basis';
import { message as AntMessage } from 'antd';
import { connect } from 'react-redux';

/**
 * filter中支持依赖数据联动和自动匹配的下拉单选
 */
export class filterAutoCompleteSelect extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        const {
            entity: {
                filters,
                metainfo: { filters: filter },
            },

            needDependences,
        } = props;
        let config = {};
        if (filter && filter[props.name]) {
            // 保存当前filter的source配置
            config = filter[props.name].source;
        }
        const dependencesValue = filters && filters[props.dependences];

        this.state = {
            config,
            // 依赖项的值
            dependencesValue: filters && filters[props.dependences],
            // 当前filter的属性名
            name: props.name,
            // 依赖项的属性名
            dependences: props.dependences,
            // 依赖变动时重新请求数据入参名称，如果未设置，默认入参名称为依赖项的属性名
            paramsName: props.paramsName ? props.paramsName : props.dependences,
            //是否展示需要依赖的提示语
            showNeedDependencesMessage: needDependences && !dependencesValue ? true : false,
        };
    }

    // 当props发生变化时，更新待选项数据
    componentWillReceiveProps(nextProps: any) {
        const {
            entity,
            entity: { filters: nextFilters },
            needDependences,
        } = nextProps;

        const { dependencesValue, dependences, name, paramsName, config } = this.state;

        let showNeedDependencesMessage = false;
        const nextDependencesValue = nextFilters && nextFilters[dependences];
        if (nextProps.name === name) {
            // 如果props变更后，依赖项有值
            if (nextFilters && nextDependencesValue) {
                // 如果变更前依赖项没有值，或者变更后值和变更前不同，重新请求待选项数据
                if (!dependencesValue || dependencesValue !== nextDependencesValue) {
                    // 请求待选项数据
                    entity.loadFilterOptions(name, config, {
                        [paramsName]: nextDependencesValue.id
                            ? nextDependencesValue.id
                            : nextDependencesValue,
                        merchantIds: nextProps.params?.merchantIds,
                    });
                    // 将已选选项置空
                    entity.filtersChange({ [name]: null });

                    // 保存新的依赖项的值
                    this.setState({
                        dependencesValue: nextDependencesValue,
                        showNeedDependencesMessage,
                    });
                }
            } else if (dependencesValue) {
                // 如果变更后依赖项没有值，变更前有值
                // 不带参数请求待选项数据
                entity.loadFilterOptions(name, config, {});

                // 将已选选项置空
                entity.filtersChange({ [name]: null });

                if (needDependences && !nextDependencesValue) {
                    showNeedDependencesMessage = true;
                }
                // 清楚保存的依赖项的值
                this.setState({
                    dependencesValue: null,
                    showNeedDependencesMessage,
                });
            }
        }
    }

    setSelectState = () => {};

    handleClick = () => {
        const { needDependencesMessage } = this.props;
        const { showNeedDependencesMessage } = this.state;
        if (showNeedDependencesMessage) {
            AntMessage.warning(needDependencesMessage);
            return false;
        }
    };

    render() {
        const props: any = this.props;
        const { dependencesValue, showNeedDependencesMessage, paramsName } = this.state;

        return (
            <div onClick={this.handleClick}>
                <SelectAutoComplete
                    disabled={showNeedDependencesMessage}
                    {...props}
                    params={{
                        [paramsName]:
                            dependencesValue && dependencesValue.id
                                ? dependencesValue.id
                                : dependencesValue,
                        merchantIds: props.params?.merchantIds,
                    }}
                />
            </div>
        );
    }
}

function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
        fields: entity.fields,
    };
}

export const FilterAutoCompleteSelect = connect(mapStateToProps, null)(filterAutoCompleteSelect);
