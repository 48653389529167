import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { ACTIVITY_TAG_TYPE_OPTIONS, MallActivityTagStatus } from '../config/cms/mall-activity-tag';
import { language } from '@comall-backend-builder/core/lib/services';
const api = services.api;
export const MallActivityTagLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/activity_tags';
        const { id } = data;
        if (!id) {
            if (!data.subsiteIds) {
                delete data.subsiteIds;
            }
            if (data.status === MallActivityTagStatus.ALL) {
                delete data.status;
            }
        }

        return await api.get(data, config).then((res: any) => {
            if (!id) {
                res.result = res.result.map((item: any) => {
                    item.subsites = item.subsiteVos;
                    item.type = ACTIVITY_TAG_TYPE_OPTIONS.filter((option) => {
                        return option.id === item.type;
                    });
                    item.type =
                        item.type &&
                        item.type.map((t: any) => {
                            if (t.name === '<<mallActivity>>') {
                                t.name = language.getText('mallActivity');
                            } else if (t.name === '<<creditEshop>>') {
                                t.name = language.getText('creditEshop');
                            } else if (t.name === '<<internalPurChase>>') {
                                t.name = language.getText('internalPurChase');
                            }
                            return t;
                        });

                    let status = '';
                    if (item.status === MallActivityTagStatus.ENABLE) {
                        status = MallActivityTagStatus.ENABLE;
                    } else {
                        status = MallActivityTagStatus.DISABLE;
                    }
                    return { ...item, status };
                });
            }
            return res;
        });
    },
};
