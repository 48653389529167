import React from 'react';
import classNames from 'classnames';
import { ObjectMode } from '@comall-backend-builder/types';
import { ObjectNotificatonContentCheckboxTip } from './object-notificaton-content-checkbox-tip';

export class ObjectNotificatonContentCheckboxTipModeMode extends ObjectMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(object: any, displayInfo: any) {
        return object && object.channel ? (
            <div>
                <p>短信</p>
                <p>{object.content}</p>
            </div>
        ) : (
            <p>未配置</p>
        );
    }

    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            '暂无数据'
        );
    }

    /**
     * 获取输入组件
     */
    getControlComponent(controlInfo: any): JSX.Element {
        return <ObjectNotificatonContentCheckboxTip {...controlInfo} />;
    }
}
