import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    components: {
        MessagesSubscriptionView: {
            component: 'Viewport',
        },
        MessagesSubscriptionPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'MessagesSubscriptionPageView',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/subscription-message',
                    component: 'MessagesSubscriptionView',
                    breadcrumbName: '<<subscriptionMessage>>',
                    privilege: {
                        path: 'subscriptionMessage',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/:status',
                            component: 'MessagesSubscriptionPage',
                        },
                        {
                            path: '/',
                            component: 'MessagesSubscriptionPage',
                        },
                    ],
                },
            ],
        },
    ],
};
