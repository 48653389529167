import { PureComponent, createElement } from 'react';
import { ComponentsManager } from '@comall-backend-builder/core';

export class MallActivituAfterSaleButton extends PureComponent<any, any> {
    render() {
        const { row } = this.props;
        const { id, canRefund, orderStatus, orderAmount, pointDealPrice } = row;
        const path = `/mall-activity-registration/apply/${id}`;
        // 非付款类商场活动，不需要显示了“售后”操作项
        return (orderStatus === 'RECEIVE' || orderStatus === 'PAYMENT') &&
            (orderAmount || pointDealPrice)
            ? createElement(ComponentsManager.get('Button'), {
                  text: '售后',
                  type: 'link',
                  disabled: !canRefund,
                  route: path,
                  style: {
                      marginLeft: 5,
                  },
              })
            : null;
    }
}
