import React from 'react';

import { Modal, message } from 'antd';
import { RichText } from '@comall-backend-builder/components-richtext';
import { get, isEqual } from 'lodash';
import { VideoModalContent } from './video-modal-content';
import { language } from '@comall-backend-builder/core/lib/services';

export class RichTextPlus extends RichText {
    constructor(props: any) {
        super(props);
        this.richTextModules.toolbar.handlers.video = this.videoHandler;
    }

    componentWillReceiveProps(nextProps: any) {
        const { fillSalesElectronicCouponInstructions, autoFillDependencies } = nextProps;
        // 售卖电子卡券填充选择优惠券的使用说明
        if (fillSalesElectronicCouponInstructions) {
            const currentCoupon = get(this.props, 'row.baseInfo.coupon', []);
            const nextCoupon = get(nextProps, 'row.baseInfo.coupon', []);
            const nextMobileDetails = get(nextProps, 'row.settingInfo.mobileDetails', '');
            if (
                !isEqual(currentCoupon, nextCoupon) &&
                !nextMobileDetails &&
                nextCoupon &&
                nextCoupon.length > 0
            ) {
                const [coupon] = nextCoupon;
                const text = get(coupon, 'couponRule.instructions', '');
                if (text) {
                    nextProps.onChange(text);
                }
            }
        }
        if (autoFillDependencies) {
            const currentReceiveExplain = get(this.props, autoFillDependencies, '');
            const nextReceiveExplain = get(nextProps, autoFillDependencies, '');
            if (!isEqual(currentReceiveExplain, nextReceiveExplain)) {
                nextProps.onChange(nextReceiveExplain);
            }
        }
    }

    videoUrl: string | undefined;

    onVideoUrlChange = (url?: string) => {
        this.videoUrl = url;
    };

    clearVideoUrl = () => {
        this.videoUrl = undefined;
    };

    handleVideo = () => {
        return new Promise((resolve, reject) => {
            if (!this.videoUrl) {
                message.warning(language.getText('videoAddressOrUploadVideo'));
                reject();
                return;
            }
            const editor = this.quillRef.getEditor();
            const range = editor.getSelection(true);
            const cursor = range.index;
            editor.insertEmbed(cursor, 'video', this.videoUrl);
            editor.setSelection(cursor + 2);
            resolve(null);
        }).finally(() => {
            this.clearVideoUrl();
        });
    };

    videoHandler = () => {
        Modal.confirm({
            content: <VideoModalContent onChange={this.onVideoUrlChange} />,
            icon: null,
            okText: language.getText('common.ok'),
            cancelText: language.getText('common.cancel'),
            title: language.getText('uploadVideo'),
            onCancel: this.clearVideoUrl,
            onOk: this.handleVideo,
        });
    };
}
