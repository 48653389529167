import React, { PureComponent } from 'react';
import { formatPointAmount } from '../../../types/format/utils';
import { ReceiptInfo, OrderItemType, OrderType } from '../../../services/order-print';

import './index.less';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

const formatQuantity = (quantity: number) => {
    const quantities = quantity.toString().split('.');
    if (quantities.length < 2) {
        return quantity;
    } else {
        return quantities[0] + '.' + quantities[1].slice(0, 3);
    }
};

const flexContainer = {
    display: 'flex',
    justifyContent: 'space-between',
};

const getWeightQuantity = (quantity: number, displayUnit: 'G' | 'KG' | 'PIECE') => {
    const value = displayUnit === 'KG' ? formatQuantity(Number(quantity) / 1000) : quantity;
    const unit = displayUnit === 'PIECE' ? '' : displayUnit === 'KG' ? 'kg' : 'g';
    return value + '' + unit;
};

export class PickingReceiptTicketTemplate extends PureComponent<
    {
        order: ReceiptInfo;
    },
    any
> {
    renderTicket = (type: 'customer' | 'merchant') => {
        const { order } = this.props;
        const giftGoods = order.goodsList.filter(
            (goods) => goods.orderItemType === OrderItemType.Gift
        );
        const normalGoods = order.goodsList.filter(
            (goods) => goods.orderItemType !== OrderItemType.Gift && goods.productType === 'NORMAL'
        );
        const weightGoods = order.goodsList.filter(
            (goods) => goods.orderItemType !== OrderItemType.Gift && goods.productType === 'WEIGHT'
        );
        let goodsAmount = order.paymentInfo.goodsAmount;
        const payablePoint = order.paymentInfo.payablePoint;
        goodsAmount =
            payablePoint && order.orderType === OrderType.CreditEshop
                ? `${formatPointAmount(payablePoint)}${language.getText(
                      'point'
                  )}+${services.language.getText('monetaryUnit')}${goodsAmount}`
                : `${services.language.getText('monetaryUnit')}${goodsAmount}`;
        return (
            <div
                className="print-paper"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    fontSize: '12px',
                    // width: '375px',
                }}
            >
                <div
                    style={{
                        textAlign: 'left',
                        marginTop: '10px',
                        marginBottom: '10px',
                        fontSize: '13px',
                    }}
                >
                    {type === 'merchant'
                        ? services.language.getText('shlbkdwtk')
                        : language.getText('gkl')}
                </div>
                <div
                    style={{
                        paddingTop: '10px',
                        paddingBottom: '5px',
                        textAlign: 'center',
                        borderBottom: '1px solid #000',
                    }}
                >
                    {order.subsite.name}
                </div>
                <div
                    style={{
                        fontSize: '16px',
                        padding: '10px 5px',
                        lineHeight: '20px',
                        borderBottom: '1px solid #000',
                    }}
                >
                    <div>
                        {services.language.getText('deliveryType')}：{' '}
                        {order.deliveryInfo.deliveryModeName}
                    </div>
                    {order.deliveryInfo.deliveryMode === 'SELF_MENTION' && (
                        <div>
                            {services.language.getText('ztm')}：{' '}
                            {order.deliveryInfo.selfExtractingCode || ''}
                        </div>
                    )}
                    {order.deliveryInfo.deliveryMode === 'CITYWIDE_DELIVERY' && (
                        <div>
                            {services.language.getText('pssj')}：{' '}
                            {order.deliveryInfo.expectedDeliveryTime || ''}
                        </div>
                    )}
                </div>
                <div>
                    {language.getText('orderNumber')}： {order.orderNo}
                </div>
                <div>
                    {services.language.getText('xdsj')}： {order.createTime}
                </div>
                <img
                    style={{
                        width: '300px',
                        height: '90px',
                        alignSelf: 'center',
                    }}
                    src={order.orderNoCode}
                    alt=""
                />
                <div
                    style={{
                        padding: '10px 5px',
                        borderTop: '1px dashed #000',
                        borderBottom: '1px dashed #000',
                        textAlign: 'left',
                    }}
                >
                    <div>
                        {language.getText('hysjh')}：{order.mobile}
                    </div>
                    <div>
                        {language.getText('hydj')}：{order.memberCardLevelName}
                    </div>
                    <div style={{ fontSize: '16px' }}>
                        {language.getText('khbz')}： {order.remark}
                    </div>
                </div>
                {normalGoods && normalGoods.length > 0 && (
                    <div
                        style={{
                            borderBottom: '1px dashed #000',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span
                            style={{
                                width: '120px',
                                textAlign: 'center',
                            }}
                        >
                            {language.getText('pm')}
                        </span>
                        <span>{language.getText('specifications')}</span>
                        <span>{language.getText('number')}</span>
                        <span>{language.getText('dj_1')}</span>
                        <span>{language.getText('hj')}</span>
                    </div>
                )}
                {normalGoods.map((item, index) => {
                    let price = item.executePrice;
                    const hasPrice = !!Number(item.executePrice);
                    const hasPoint = !!Number(item.executePointPrice);
                    if (hasPrice && hasPoint) {
                        price =
                            item.executePointPrice +
                            language.getText('point') +
                            '+' +
                            item.executePrice;
                    } else if (hasPoint) {
                        price = item.executePointPrice + language.getText('point');
                    }
                    return (
                        <div
                            style={{
                                width: '100%',
                                marginBottom: '5px',
                            }}
                        >
                            <div style={{ width: '100%' }}>
                                {index + 1}&nbsp;&nbsp;{item.productName}
                            </div>
                            <div style={flexContainer}>
                                <span
                                    style={{
                                        width: '180px',
                                        textAlign: 'right',
                                    }}
                                >
                                    {item.styleName}
                                </span>
                                <span>
                                    {item.quantity}
                                    {item.displayUnit === 'PIECE' ? '件' : ''}
                                </span>
                                <span>{price}</span>
                                <span>{item.weightProductActualAfterFoldingPrice}</span>
                            </div>
                            <div>
                                {services.language.getText('goodBarCode')}：
                                {item.pickingListOrderItemBarcode}
                            </div>
                            <div>
                                {services.language.getText('productCode')}：{item.productCode}
                            </div>
                        </div>
                    );
                })}
                {weightGoods && weightGoods.length > 0 && (
                    <div
                        style={{
                            borderTop: '1px dashed #000',
                            borderBottom: '1px dashed #000',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span
                            style={{
                                width: '120px',
                                textAlign: 'center',
                            }}
                        >
                            {language.getText('pm')}
                        </span>
                        <span>{language.getText('dj_1')}</span>
                        <span>{language.getText('zl_3')}</span>
                        <span>{language.getText('sf')}</span>
                        <span>{language.getText('sz_3')}</span>
                        <span>{language.getText('cz_2')}</span>
                        <span>{language.getText('hj')}</span>
                    </div>
                )}
                {weightGoods.map((item, index) => {
                    return (
                        <div
                            style={{
                                width: '100%',
                                marginBottom: '5px',
                            }}
                        >
                            <div style={{ width: '100%' }}>
                                {index + 1}&nbsp;&nbsp;{item.productName}
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <span></span>
                                <span>{item.executePrice}/kg</span>
                                <span>
                                    {getWeightQuantity(item.needPickQuantity, item.displayUnit)}
                                </span>
                                <span>{item.totalAmountStr}</span>
                                <span>
                                    {getWeightQuantity(item.actualPickQuantity, item.displayUnit)}
                                </span>
                                <span>
                                    {getWeightQuantity(item.quantityDifference, item.displayUnit)}
                                </span>
                                <span>{item.weightProductActualAfterFoldingPrice}</span>
                            </div>
                            <div>
                                {services.language.getText('goodBarCode')}：
                                {item.pickingListOrderItemBarcode}
                            </div>
                            <div>
                                {services.language.getText('productCode')}：{item.productCode}
                            </div>
                        </div>
                    );
                })}

                {/* 赠品信息 */}
                {giftGoods && giftGoods.length > 0 && (
                    <div
                        style={{
                            borderTop: '1px dashed #000',
                            borderBottom: '1px dashed #000',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'space-between',
                        }}
                    >
                        <span
                            style={{
                                width: '120px',
                                textAlign: 'center',
                            }}
                        >
                            {language.getText('pm')}
                        </span>
                        <span>{language.getText('specifications')}</span>
                        <span>{language.getText('number')}</span>
                        <span>{language.getText('dj_1')}</span>
                        <span>{language.getText('hj')}</span>
                    </div>
                )}
                {giftGoods.map((item, index) => {
                    let price = item.executePrice;
                    const hasPrice = !!Number(item.executePrice);
                    const hasPoint = !!Number(item.executePointPrice);
                    if (hasPrice && hasPoint) {
                        price =
                            item.executePointPrice +
                            language.getText('point') +
                            '+' +
                            item.executePrice;
                    } else if (hasPoint) {
                        price = item.executePointPrice + language.getText('point');
                    }
                    return (
                        <div
                            style={{
                                width: '100%',
                                marginBottom: '5px',
                            }}
                        >
                            <div style={{ width: '100%' }}>
                                {index + 1}&nbsp;&nbsp;{item.productName}
                            </div>
                            <div style={flexContainer}>
                                <span
                                    style={{
                                        width: '180px',
                                        textAlign: 'right',
                                    }}
                                >
                                    {item.styleName}
                                </span>
                                <span>
                                    {item.quantity}
                                    {item.displayUnit === 'PIECE' ? language.getText('jian') : ''}
                                </span>
                                <span>{price}</span>
                                <span>{item.weightProductActualAfterFoldingPrice}</span>
                            </div>
                            <div>
                                {services.language.getText('goodBarCode')}：
                                {item.pickingListOrderItemBarcode}
                            </div>
                            <div>
                                {services.language.getText('productCode')}：{item.productCode}
                            </div>
                        </div>
                    );
                })}
                <div
                    style={{
                        lineHeight: '20px',
                        borderTop: '1px dashed #000',
                    }}
                >
                    <div style={flexContainer}>
                        <span>{language.getText('spzsl')}：</span>
                        <span>{order.totalGoodsQuantity}件</span>
                    </div>
                    <div style={flexContainer}>
                        <span>{language.getText('spje')}：</span>
                        <span>{goodsAmount}</span>
                    </div>
                    <div style={flexContainer}>
                        <span>{services.language.getText('yfje')}：</span>
                        <span>
                            {services.language.getText('monetaryUnit')}
                            {order.paymentInfo.totalFreightAmount}
                        </span>
                    </div>
                    <div style={flexContainer}>
                        <span>{language.getText('yfyhje')}：</span>
                        <span>
                            {services.language.getText('monetaryUnit')}
                            {order.paymentInfo.freightFeeDiscount}
                        </span>
                    </div>
                    <div style={flexContainer}>
                        <span>{services.language.getText('cxyh')}：</span>
                        <span>
                            {services.language.getText('monetaryUnit')}
                            {order.paymentInfo.promotionDiscountAmount}
                        </span>
                    </div>
                    <div style={flexContainer}>
                        <span>{services.language.getText('hykzk')}：</span>
                        <span>
                            {services.language.getText('monetaryUnit')}
                            {order.paymentInfo.memberCardDiscountAmount}
                        </span>
                    </div>
                </div>
                <div
                    style={{
                        textAlign: 'center',
                    }}
                >
                    ------------------------------{language.getText('zffs')}
                    ------------------------------
                </div>
                <div
                    style={{
                        borderBottom: '1px dashed #000',
                    }}
                >
                    <div style={flexContainer}>
                        <span>{language.getText('jfzf')}：</span>
                        <span>
                            {order.paymentInfo.paidPoint}
                            {language.getText('point')}
                        </span>
                    </div>
                    {!!order.paymentInfo.paymentRecords &&
                        order.paymentInfo.paymentRecords.map((record) => {
                            return (
                                <div style={flexContainer}>
                                    <span>{record.paymentModeName}：</span>
                                    <span>
                                        {services.language.getText('monetaryUnit')}
                                        {record.amount}
                                    </span>
                                </div>
                            );
                        })}
                    <div style={flexContainer}>
                        <span>{services.language.getText('zfyh')}：</span>
                        <span>
                            {services.language.getText('monetaryUnit')}
                            {order.paymentInfo.totalPaymentRecordDiscountAmount}
                        </span>
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            fontSize: '15px',
                            fontWeight: 'bold',
                        }}
                    >
                        <span>{language.getText('zxzf')}：</span>
                        <span>
                            {services.language.getText('monetaryUnit')}
                            {order.paymentInfo.paidAmount}
                        </span>
                    </div>
                    {!!Number(order.totalPriceDifference) && (
                        <div style={flexContainer}>
                            <span>{language.getText('cjje')}：</span>
                            <span>
                                {services.language.getText('monetaryUnit')}-
                                {order.totalPriceDifference}
                            </span>
                        </div>
                    )}
                </div>
                <div
                    style={{
                        borderBottom: '1px solid #000',
                    }}
                >
                    <div>
                        {language.getText('shrdz')}： {order.deliveryInfo.address}
                    </div>
                    <div>
                        {services.language.getText('shrsjh')}： {order.deliveryInfo.mobile}
                    </div>
                    <div>
                        {language.getText('shrxm')}： {order.deliveryInfo.receiverName}
                    </div>
                </div>
                <div
                    style={{
                        padding: '5px',
                        fontSize: '14px',
                        lineHeight: '25px',
                        textAlign: 'center',
                    }}
                >
                    <div>{order.subsite.name}</div>
                    <div>{order.subsite.address}</div>
                    <div>{order.subsite.mobile}</div>
                </div>
                {type === 'merchant' && (
                    <div
                        style={{
                            width: '100%',
                            margin: '15px 0',
                            borderBottom: '4px solid #000',
                        }}
                    ></div>
                )}
            </div>
        );
    };

    render() {
        return (
            <div>
                {this.renderTicket('merchant')}
                {this.renderTicket('customer')}
            </div>
        );
    }
}
