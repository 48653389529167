import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
export const PointValidLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-SCRM`;
        config.apiPath = '/admin/scrm_member_account_clear_rules';
        data.accountType = 'POINT';
        return await api.get(data, config).then((res: any) => {
            return {
                rule: {
                    clearType: res.clearType || 'EVER_VALID',
                    fixedRule: res.fixedRule || {},
                    graduallyRule: res.graduallyRule || {},
                },
                notification: res.notification || undefined,
            };
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-SCRM`;
        config.apiPath = '/admin/scrm_member_account_clear_rules';
        const newParams: any = {};
        if (params.rule) {
            if (params.rule.clearType === 'EVER_VALID') {
                newParams.accountClearType = 'EVER_VALID';
            } else if (params.rule.clearType === 'FIXED_CLEAR') {
                newParams.accountClearType = 'FIXED_CLEAR';
                newParams.fixedRule = {
                    day: params.rule.fixedRule.day,
                    month: params.rule.fixedRule.month,
                    yearDelay: params.rule.fixedRule.yearDelay,
                };
            } else if (params.rule.clearType === 'GRADUALLY_CLEAR') {
                newParams.accountClearType = 'GRADUALLY_CLEAR';
                newParams.graduallyRule = {
                    dateValue: params.rule.graduallyRule.dateValue,
                };
            }
        } else {
            newParams.accountClearType = 'EVER_VALID';
        }
        newParams.notification = params.notification;
        newParams.accountType = 'POINT';

        return await api.put(newParams, config);
    },
};
