import { PureComponent, createElement } from 'react';
import { services, actions, builder, Loader } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { get, reduce, clone, mapValues, cloneDeep, isNil, filter } from 'lodash';
import { getField } from '@comall-backend-builder/components-basis/lib/form';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import {
    NewMallActivityTicketType,
    validateNewMallActivityRounds,
    validateRegistrationTime,
    validateBusinessNotifications,
} from '../../components';
import { language } from '@comall-backend-builder/core/lib/services';
import { changeSubmitStatus, getSubmitStatus } from '../../components/data-form-plus';

// import './index.less';

interface NewMallActivityAddFormViewProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
    saleLimitType: any;
    data: any;
    onReloadOptions: (fieldName: any, fields: any) => void;
    formComponent?: string;
}
// enum SessionType {
//     /**
//      * 否
//      */
//     NO = 'NO',
//     /**
//      * 是
//      */
//     YES = 'YES',
// }

const ComponentName = 'NewMallActivityAddFormContainer';
const FormComponentId = `DataFormPlus-${ComponentName}`;

class newMallActivityAddFormView extends PureComponent<NewMallActivityAddFormViewProps> {
    state = { haveShow: false };
    componentDidMount() {
        const apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`;
        const apiPath = `/admin/mall_activities/have_show`;
        services.api.get({}, { apiRoot, apiPath }).then((res: any) => {
            if (res) {
                this.setState({ haveShow: true });
            }
        });
    }

    componentWillUnmount() {
        const { unmountComponent } = this.props;
        if (unmountComponent) {
            unmountComponent();
        }
    }

    changeFormField = (fieldName: string, value: any) => {
        const store = builder.getStore();
        store.dispatch(actions.formChangeAction(FormComponentId, fieldName, value));
    };

    render() {
        const {
            entity,
            onSubmit,
            wrappedComponentRef,
            data,
            onReloadOptions,
            formComponent = 'CreateFormPlus',
        } = this.props;
        const { haveShow } = this.state;
        const isNotNotification = get(data, 'registrationInfo.type') !== 'NOTIFICATION';
        const { addressName, address, phone } = data?.baseInfo || {};
        let fields = [
            {
                property: 'baseInfo.name',
            },
            {
                property: 'baseInfo.dateRange',
                controlConfig: {
                    format: 'YYYY-MM-DD',
                    style: {
                        width: 450,
                    },
                    range: {
                        start: new Date(),
                    },
                },
            },
            {
                property: 'baseInfo.dayTime',
            },
            {
                property: 'baseInfo.merchants',
                controlConfig: {
                    uniqueSubsite: true,
                    onChange: (merchants: any) => {
                        const merchant = merchants?.[0];
                        if (!merchant) {
                            return;
                        }
                        if (!addressName) {
                            this.changeFormField('baseInfo.addressName', merchant.merchantName);
                        }
                        if (!address && merchant.merchantAddress) {
                            this.changeFormField('baseInfo.address', merchant.merchantAddress);
                        }
                        if (!phone && merchant.merchantCustomerServicePhone) {
                            this.changeFormField(
                                'baseInfo.phone',
                                merchant.merchantCustomerServicePhone
                            );
                        }
                    },
                },
            },
            {
                property: 'baseInfo.addressName',
            },
            {
                property: 'baseInfo.address',
            },
            {
                property: 'baseInfo.phone',
            },
            {
                property: 'baseInfo.merchantUserPhone',
            },
            {
                property: 'baseInfo.tagIdList',
                modifiable: (v: any) => get(v, 'baseInfo.merchants.length'),
            },
            {
                property: 'baseInfo.canShare',
            },
            {
                property: 'baseInfo.hideSetting',
            },
            {
                property: 'baseInfo.crowdDisplayCondition',
                visible: (values: any) => get(values, 'baseInfo.hideSetting[0]') === 'true',
            },
            {
                property: 'baseInfo.hideCrowdsStatus',
                visible: (values: any) =>
                    get(values, 'baseInfo.hideSetting[0]') === 'true' &&
                    get(values, 'baseInfo.crowdDisplayCondition') === 'PART_CROWD_HIDE',
            },

            {
                property: 'baseInfo.hideCrowds',
                visible: (values: any) =>
                    get(values, 'baseInfo.hideSetting[0]') === 'true' &&
                    get(values, 'baseInfo.crowdDisplayCondition') === 'PART_CROWD_HIDE' &&
                    get(values, 'baseInfo.hideCrowdsStatus') === 'appoint',
            },
            {
                property: 'baseInfo.showCrowds',
                visible: (values: any) =>
                    get(values, 'baseInfo.hideSetting[0]') === 'true' &&
                    get(values, 'baseInfo.crowdDisplayCondition') === 'PART_CROWD_SHOW',
            },
            {
                property: 'mediaInfo.pictures',
            },
            {
                property: 'mediaInfo.poster',
            },
            {
                property: 'mediaInfo.info',
            },
            {
                property: 'registrationInfo.type',
            },
            {
                property: 'registrationInfo.targetUserType',
                visible: () => isNotNotification,
                controlConfig: {
                    onChange: (value: string) => {
                        if (value === 'ANY') {
                            this.changeFormField('registrationInfo.targetUserLevels', []);
                        }
                    },
                },
            },
            {
                property: 'registrationInfo.targetUserLevels',
                visible: (values: any) =>
                    isNotNotification && get(values, 'registrationInfo.targetUserType') === 'SOME',
                modifiable: (v: any) => get(v, 'baseInfo.merchants.length'),
            },
            {
                property: 'registrationInfo.targetUserBirthDay',
                visible: (values: any) =>
                    isNotNotification && get(values, 'registrationInfo.targetUserType') === 'SOME',
            },
            {
                property: 'registrationInfo.targetUserSexuality',
                visible: (values: any) =>
                    isNotNotification && get(values, 'registrationInfo.targetUserType') === 'SOME',
            },
            {
                property: 'registrationInfo.targetUserCrowdStatus',
                visible: (values: any) =>
                    isNotNotification && get(values, 'registrationInfo.targetUserType') === 'SOME',
            },
            {
                property: 'registrationInfo.purchasableCrowds',
                visible: (values: any) =>
                    isNotNotification &&
                    get(values, 'registrationInfo.targetUserType') === 'SOME' &&
                    get(values, 'registrationInfo.targetUserCrowdStatus') === 'ok',
            },
            {
                property: 'registrationInfo.nonPurchasableCrowds',
                visible: (values: any) =>
                    isNotNotification &&
                    get(values, 'registrationInfo.targetUserType') === 'SOME' &&
                    get(values, 'registrationInfo.targetUserCrowdStatus') === 'non',
            },
            {
                property: 'registrationInfo.ticketType',
                visible: () => isNotNotification,
            },
            {
                property: 'registrationInfo.registrationStartTime',
                visible: () => isNotNotification,
                controlConfig: {
                    mode: 'BEGIN',
                },
            },
            {
                property: 'registrationInfo.registrationEndTime',
                visible: () => isNotNotification,
            },
            {
                property: 'registrationInfo.activityRounds',
                visible: (values: any) =>
                    isNotNotification &&
                    get(values, 'registrationInfo.ticketType') === NewMallActivityTicketType.Round,
            },
            {
                property: 'registrationInfo.activitySingleRoundTickets',
                visible: (values: any) =>
                    isNotNotification &&
                    get(values, 'registrationInfo.ticketType') ===
                        NewMallActivityTicketType.Activity,
            },
            {
                property: 'registrationInfo.maxPurchaseTotal',
                visible: (values: any) =>
                    isNotNotification &&
                    get(values, 'registrationInfo.ticketType') ===
                        NewMallActivityTicketType.Activity,
            },
            {
                property: 'registrationInfo.limitSession',
                visible: (values: any) =>
                    isNotNotification &&
                    get(values, 'registrationInfo.ticketType') === NewMallActivityTicketType.Round,
            },
            {
                property: 'registrationInfo.limitSessionCount',
                visible: (values: any) =>
                    isNotNotification &&
                    get(values, 'registrationInfo.ticketType') ===
                        NewMallActivityTicketType.Round &&
                    get(values, 'registrationInfo.limitSession') === 'YES',
            },
            {
                property: 'registrationInfo.customInfo',
                visible: () => isNotNotification,
            },
            {
                property: 'registrationInfo.info',
                visible: () => isNotNotification,
            },
            {
                property: 'registrationInfo.reviewType',
                visible: () => isNotNotification,
            },
            {
                property: 'registrationInfo.cancelableRule',
                visible: () => isNotNotification,
            },

            {
                property: 'registrationInfo.excludeRule',
                visible: () => isNotNotification,
            },
            {
                property: 'registrationInfo.excludeTypeActivity',
                visible: (values: any) =>
                    isNotNotification && get(values, 'registrationInfo.excludeRule') === 'SOME',
            },
            {
                property: 'contentInfo.creator',
                visible: () => haveShow,
            },
            {
                property: 'contentInfo.activityTopics',
                visible: () => haveShow,
            },
            {
                property: 'rewardInfo.reward',
                visible: () => isNotNotification,
            },
            {
                property: 'rewardInfo.rewardDistributionTime',
                visible: (values: any) =>
                    isNotNotification &&
                    get(values, 'rewardInfo.reward') &&
                    get(values, 'rewardInfo.reward').length,
            },
            {
                property: 'seniorSetting.businessNotifications',
            },
            {
                property: 'seniorSetting.participatNotifications',
            },
            {
                property: 'seniorSetting.registrationAbsentedNotifications',
            },
            {
                property: 'seniorSetting.registrationStartedNotifications',
            },
            {
                property: 'seniorSetting.subscriptMessage',
                visible: (values: any) => {
                    return (
                        filter(values.seniorSetting.businessNotifications || [], ['status', 1])
                            .length ||
                        filter(values.seniorSetting.participatNotifications || [], ['status', 1])
                            .length ||
                        filter(values.seniorSetting.registrationAbsentedNotifications || [], [
                            'status',
                            1,
                        ]).length ||
                        filter(values.seniorSetting.registrationStartedNotifications || [], [
                            'status',
                            1,
                        ]).length
                    );
                },
            },
            {
                property: 'seniorSetting.marking',
            },
        ];

        const formProps = {
            entity,
            componentId: ComponentName,
            wrappedComponentRef,
            onSubmit,
            direction: 'horizontal',
            labelCol: 3,
            controlCol: 21,
            style: {
                minWidth: '750px',
            },
            fields: fields,
            submit: true,
            onReloadOptions,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.navigation.goBack()
                );
            },
        };
        return createElement(ComponentsManager.get(formComponent), formProps);
    }
}

function mapStateToProps(_dispatch: any, props: any) {
    const { entity, params } = props;

    const data = get(_dispatch, `components.["${FormComponentId}"].fields`) || {};
    async function onValidate(fields: any) {
        const entityFields = mapValues(fields, (field) => {
            return field.value;
        });
        const newData: any = cloneDeep(entityFields); // 将不可拓展对象变成可拓展
        const validateResult =
            validateNewMallActivityRounds(newData, true) || validateRegistrationTime(newData, true);
        if (validateResult) {
            AntMessage.error(validateResult);
            return false;
        }

        // const enableNotice = get(newData, 'seniorSetting.enableNotice', []).includes('true');
        // const subscriptMessage = get(newData, 'seniorSetting.subscriptMessage');
        // if (enableNotice && (!subscriptMessage || !subscriptMessage.num)) {
        //     AntMessage.error(language.getText('hdcytxsjbnwk'));
        //     return false;
        // }

        if (get(newData, 'contentInfo.creator') && get(newData, 'contentInfo.creator').trim()) {
            let userInfo = await Loader.load('get', {
                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                apiPath: `/admin/members/mobile/${get(newData, 'contentInfo.creator')}`,
            }).catch(services.errorHandle);
            if (!userInfo) {
                AntMessage.error(language.getText('hdfqrsjhmyzc'));
                return false;
            }
            newData.contentInfo.creatorId = userInfo.id;
        }
        const marking = get(entity, 'seniorSetting.marking', undefined);
        if (!isNil(marking) && marking?.check && !marking.label) {
            AntMessage.error(language.getText('qxzydbdbq'));
            return false;
        }
        return newData;
    }
    return {
        data,
        unmountComponent: () => {
            builder.getStore().dispatch(actions.unmountComponentAction(FormComponentId));
        },
        onSubmit: async (event: any, fields: any) => {
            if (getSubmitStatus()) {
                return;
            }
            changeSubmitStatus(true);
            const newData = await onValidate(fields);
            if (!newData) {
                changeSubmitStatus(false);
                return;
            }
            const validateNotification = validateBusinessNotifications(newData);
            if (!validateNotification) {
                changeSubmitStatus(false);
                return;
            }
            entity.add(newData, params);
        },
        // 重新加载属性候选值
        onReloadOptions: (fieldName: any, fields: any) => {
            //? 因为在这个场景下reload时fields可能已经不在dataTable上了
            //? 会导致修改被依赖字段未触发获取依赖方获取数据
            //? 所以这里改成从entity上取需要的参数
            const isSubform = fieldName.split('.').length > 1;
            const fieldNamePath = isSubform ? fieldName.replace(/\./g, '.properties.') : fieldName;
            const field = get(entity, `metainfo.properties.${fieldNamePath}`);

            if (!field) return;
            const sourceDefination = field.source;
            let dependences = sourceDefination.dependences;
            let params = reduce(
                dependences,
                (values, dependence) => {
                    values[dependence] = getField(dependence, fields).value;
                    return values;
                },
                props.params ? clone(props.params) : {}
            );
            entity.loadPropertyOptions(fieldName, sourceDefination, params);
        },
    };
}
export const NewMallActivityAddFormView = connect(mapStateToProps)(newMallActivityAddFormView);
