import React, { PureComponent, createElement } from 'react';
import { remove, map, cloneDeep, uniqBy, isEqual, get } from 'lodash';
import { connect } from 'react-redux';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Modal, message } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services';

const TableComponentId = 'TagSelectorTable';
let TableComponentNo = 1;
let Entity: any = {};
let entity: any = {};

interface orderProps {
    id: string;
    tagName: string;
    subsiteId: string;
    typeName: string;
}

/**
 * 选择订单
 */
export class orderSelector extends PureComponent<{
    selectValues?: orderProps[];
    onChange?: (order: orderProps[]) => void;
    params?: any;
    requestStatus: string;
    type?: 'radio';
    subsiteId?: string;
}> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('OfflineOrderEntity');
        entity = new Entity({});
        this.tableComponentId = TableComponentId + TableComponentNo;
        TableComponentNo++;
    }
    tableComponentId: string = '';
    dispatch: any = {};
    state = { visible: false };

    selectedRows = new Array<orderProps>();

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(entity));
    }

    componentWillReceiveProps(nextProps: any) {
        //在选中列表中删除数据后更新弹窗选中状态
        if (!isEqual(nextProps.selectValues, this.props.selectValues)) {
            this.selectedRows = nextProps?.selectValues || [];
            this.setSelection(nextProps?.selectValues || []);
        }
        if (nextProps.requestStatus === 'success' && this.props.requestStatus === 'pending') {
            const selectedRows = this.selectedRows;
            if (selectedRows && selectedRows.length > 0) {
                setTimeout(() => {
                    this.dispatch(
                        actions.tableRowSelectionChangeAction(
                            TableComponentId,
                            map(selectedRows, (value) => {
                                return value.id;
                            })
                        )
                    );
                }, 300);
            } else {
                this.dispatch(actions.tableRowSelectionChangeAction(this.tableComponentId, []));
            }
        }
    }

    setSelection = (rows: any) => {
        const selectedRows = rows;
        if (selectedRows && selectedRows.length > 0) {
            setTimeout(() => {
                this.dispatch(
                    actions.tableRowSelectionChangeAction(TableComponentId, map(selectedRows, 'id'))
                );
            }, 300);
        } else {
            this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
        }
    };

    onTableRowSelect = (record: orderProps, selected: boolean) => {
        if (selected) {
            this.selectedRows = [record];
        } else {
            remove(this.selectedRows, (i) => {
                return i.id === record.id;
            });
        }
    };

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
    };

    onOk = () => {
        const { onChange } = this.props;
        if (onChange) {
            const rows = cloneDeep(this.selectedRows);
            const nRow = uniqBy(rows, 'id');
            onChange(nRow);
            this.toggleModal();
        }
    };

    getConfig = () => {
        const { type, subsiteId, selectValues } = this.props;
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: {
                subsiteId,
                status: '1',
                orderTypeCodes: 'eStoredValueCard,pStoredValueCard',
                paymentModeType: '7',
            },
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'right' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    fields: [
                        {
                            property: 'memberMobile',
                        },
                        {
                            property: 'receiverMobile',
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    scroll: {
                        x: 800,
                    },
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    rowSelection: {
                        type: type ? type : 'radio',
                        selectedRowKeys: map(selectValues, (value) => {
                            return value.id;
                        }),
                        onSelect: (record: orderProps, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: false,
                    columns: [
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'orderNo',
                        },
                        {
                            property: 'createTime',
                        },
                        {
                            property: 'orderAmount',
                        },
                        {
                            property: 'nickName',
                        },
                        {
                            property: 'mobile',
                        },
                        {
                            property: 'receiverMobile',
                        },
                    ],
                    locale: { emptyText: language.getText('qsrsstjjxsx') },
                },
            ],
        };
    };

    toggleModal = () => {
        const { visible } = this.state;
        const { subsiteId } = this.props;
        if (!subsiteId) {
            message.error(services.language.getText('selectBeforeMerchant'));
            return;
        }
        this.setState({ visible: !visible }, () => {
            if (!visible && entity.paging) {
                entity.search({
                    ...entity.paging,
                    subsiteId,
                    status: '1',
                    orderTypeCodes: 'eStoredValueCard,pStoredValueCard',
                    paymentModeType: '7',
                });
                this.onClearAll();
            }
            let selectedRows = cloneDeep(this.props?.selectValues || []);
            this.selectedRows = selectedRows;
            this.setSelection(selectedRows);
        });
    };

    render() {
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            zIndex: 1,
            width: 1100,
            title: language.getText('xzdd_1'),
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };

        return (
            <div style={{ display: 'inline-block' }}>
                <Button type="primary" onClick={this.toggleModal}>
                    {language.getText('xzdd_1')}
                </Button>
                <Modal className="tag-modal" {...modalProps}>
                    {modalContent}
                </Modal>
            </div>
        );
    }
}
export const OrderSelector = connect((_state: any) => {
    let requestStatus = get(entity, 'requestStatus', null);
    const result = { requestStatus };
    return result;
})(orderSelector);
