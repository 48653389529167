import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { FilterAutoCompleteSelect } from './filter-auto-complete-select';

export class FilterAutoCompleteSelectorMode extends modes.StringMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <FilterAutoCompleteSelect key={displayInfo.name} {...displayInfo} />;
    }
}

/**
 * 导出一份返回数据未object的type
 */
export class FilterAutoCompleteSelectorObjectMode extends modes.ObjectMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <FilterAutoCompleteSelect key={displayInfo.name} {...displayInfo} />;
    }
}
