import React, { Component, createElement, Fragment } from 'react';
import { Button, Tabs as AntTabs, Popover } from 'antd';
import { navigation, api, localStorage } from '@comall-backend-builder/core/lib/services';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { find, forEach } from 'lodash';
import { WechatTemplatesFindStoreSelector } from '../wechat-templates-find-store-selector';
import { WechatTemplatesFindListItemEditModal } from '../components/wechat-templates-find-list-item-edit-modal';
import './index.less';

const AntTabPane = AntTabs.TabPane;

//是否有门店数据
const hasStoreInfo = () => !!localStorage.get('wechantTemplatesStore');

const getStoreId = () => {
    let storeInfo: any = localStorage.get('wechantTemplatesStore');
    if (storeInfo && storeInfo.id) {
        return storeInfo.id;
    } else {
        return '';
    }
};

interface ActiveKey {
    tab: string;
    tabDescription: string;
}

export interface WechatTemplatesFindStates {
    activeKey: string;
    activeKeyList: Array<ActiveKey> | undefined;
    addModalVisible: boolean;
}

/**
 * 动态创建订单调度台各个订单状态的tab
 */
export class WechatTemplatesFind extends Component<any, WechatTemplatesFindStates> {
    constructor(props: any) {
        super(props);
        //初始选中tab
        const { params } = props;
        let activeKey = null;
        if (params && params.status) {
            activeKey = params.status;
        } else {
            activeKey = 'ALL';
        }
        this.state = {
            activeKey,
            activeKeyList: undefined,
            addModalVisible: false,
        };
    }

    fetchActiveKeyList() {
        this.setState({
            activeKeyList: undefined,
        });
        let fetch = () => {
            if (!hasStoreInfo()) {
                return;
            }
            api.get(
                { subsiteId: hasStoreInfo() ? getStoreId() : '' },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                    apiPath: '/admin/wechat_template_scenes',
                }
            ).then((res: any) => {
                //获取tab集合去重
                const tabs: Array<ActiveKey> = [];
                if (res) {
                    forEach(res, (a) => {
                        const ca =
                            a.tab &&
                            find(tabs, {
                                tab: a.tab,
                            });
                        if (!ca) {
                            tabs.push({
                                tab: a.tab,
                                tabDescription: a.tabDescription,
                            });
                        }
                    });
                    tabs.unshift({
                        tab: 'ALL',
                        tabDescription: services.language.getText('all'),
                    });
                }
                this.setState({
                    activeKeyList: tabs,
                });
            });
        };
        if (this.timer) {
            clearInterval(this.timer);
        }
        this.timer = setInterval(fetch, 60 * 1000);
        fetch();
    }

    componentDidMount() {
        //发送请求，获取到订单状态
        if (typeof this.props.params.status === 'undefined') {
            const history = navigation.getHistory();
            history.replace({ pathname: '/wechat-templates/' + this.state.activeKey });
        }
        this.fetchActiveKeyList();
    }

    timer: any;

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    createPanes = () => {
        const props = this.props;
        const { entities } = props;
        const { activeKeyList, activeKey } = this.state;
        const fetchActiveKeyList = this.fetchActiveKeyList.bind(this);
        if (!activeKeyList) {
            return null;
        }
        let addButton = this.creatAddButton();
        return activeKeyList.map((activeKeyVo) => {
            let dynamic = ComponentsManager.get('WechatTemplatesListPane');
            let component = createElement(dynamic, {
                params: {
                    tab: activeKeyVo.tab,
                    subsiteId: getStoreId(),
                },
                isActive: activeKey === activeKeyVo.tab,
                refresh: fetchActiveKeyList,
                entities,
            });
            return (
                <AntTabPane tab={activeKeyVo.tabDescription} key={activeKeyVo.tab}>
                    {addButton}
                    {component}
                </AntTabPane>
            );
        });
    };

    onChange = (activeKey: any, keyword?: string) => {
        this.setState({ activeKey });
        const history = navigation.getHistory();
        if (keyword) {
            history.replace({
                pathname: '/wechat-templates/' + activeKey + `?keyword=${keyword}`,
            });
        } else {
            history.replace({
                pathname: '/wechat-templates/' + activeKey,
            });
        }

        this.fetchActiveKeyList();
    };

    handleVisible = () => {
        const { addModalVisible } = this.state;
        this.setState({
            addModalVisible: !addModalVisible,
        });
    };

    creatAddButton = () => {
        const { addModalVisible } = this.state;
        const { activeKey } = this.state;
        const fetchActiveKeyList = this.fetchActiveKeyList.bind(this);
        if (activeKey && activeKey === 'MANUAL') {
            return (
                <div>
                    <Button
                        type="primary"
                        onClick={this.handleVisible}
                        style={{ marginBottom: '15px' }}
                    >
                        {services.language.getText('xzmb')}
                    </Button>
                    {addModalVisible && (
                        <WechatTemplatesFindListItemEditModal
                            refreshList={fetchActiveKeyList}
                            handleVisible={this.handleVisible}
                            visible={addModalVisible}
                        />
                    )}
                </div>
            );
        }
        return null;
    };

    render() {
        if (!this.state) {
            return null;
        }
        let panes = this.createPanes();
        const tipsImage = require('../components/images/tips-image.png');
        const demoUrl = require('../components/images/wechat-demo.png');
        const { activeKey, activeKeyList } = this.state;
        const { entity, entities } = this.props;
        return (
            <Fragment>
                <WechatTemplatesFindStoreSelector
                    refresh={this.fetchActiveKeyList.bind(this)}
                    entity={entity}
                    entities={entities}
                />
                <div className="wechat-templates-find-content-wrap">
                    <div>{services.language.getText('syxz')}</div>
                    <div>{services.language.getText('officialTemplateIntro1')}</div>
                    <div>
                        {services.language.getText('officialTemplateIntro2')}
                        <Popover
                            placement="bottom"
                            content={
                                <img
                                    style={{ width: 1200 }}
                                    alt={services.language.getText('preview')}
                                    src={tipsImage}
                                ></img>
                            }
                            trigger="hover"
                        >
                            <Button style={{ padding: 0 }} type="link">
                                {services.language.getText('viewExample')}
                            </Button>
                        </Popover>
                    </div>
                    <div>{services.language.getText('officialTemplateIntro3')}</div>
                    <div>
                        {services.language.getText('wxOffcialAdjustRule')}
                        <Popover
                            placement="left"
                            content={
                                <img
                                    style={{ width: 400 }}
                                    alt={services.language.getText('preview')}
                                    src={demoUrl}
                                ></img>
                            }
                            trigger="hover"
                        >
                            <Button style={{ padding: 0 }} type="link">
                                {services.language.getText('viewExample')}
                            </Button>
                        </Popover>
                    </div>
                </div>
                {activeKeyList && activeKeyList.length === 0 && (
                    <div className="wechat-templates-find-tip-wrap">
                        <div className="wechat-templates-find-tip-wrap__title">
                            {services.language.getText('dqmdwgldgzh')}
                        </div>
                        <div className="wechat-templates-find-tip-wrap__button">
                            <Button type="primary" href="#/wechat-official-accounts">
                                {services.language.getText('qsq')}
                            </Button>
                        </div>
                    </div>
                )}
                {activeKeyList && activeKeyList.length > 0 && (
                    <AntTabs
                        className="wechat-templates-find-tabs-wrap"
                        activeKey={activeKey}
                        animated={false}
                        onChange={this.onChange}
                    >
                        {panes}
                    </AntTabs>
                )}
            </Fragment>
        );
    }
}
