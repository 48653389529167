import { Config } from '@comall-backend-builder/core/lib/parser';
import { getUrlParam } from '../../../../applications/cae/services/utils';
import { services } from '@comall-backend-builder/core';

function getDefaultValue() {
    return getUrlParam(window.location.hash, 'subsiteName') || '';
}

export const config: Config = {
    components: {
        CarparkPageView: {
            component: 'Viewport',
            entity: 'CarparkPageEntity',
        },
        CarparkPageDesign: {
            component: 'Card',
            content: {
                component: 'PageDesignMerchant',
                client: ENV.DESIGN_CLIENT_URL,
                apiPath: '/admin/pages/:type/:id',
                type: 'CARPARK_PAGE',
            },
        },
        CarparkPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'GridLayout',
                    items: [
                        {
                            component: 'FilterForm',
                            direction: 'inline',
                            style: {
                                marginBottom: 20,
                            },
                            submit: {
                                text: '<<common.search>>',
                                style: {
                                    marginRight: 16,
                                },
                            },
                            reset: {
                                text: '<<common.reset>>',
                            },
                            fields: [
                                {
                                    property: 'name',
                                    defaultValue: getDefaultValue(),
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'CarparkPageTable',
                },
            ],
        },
        CarparkPageTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'subsites',
                    displayConfig: {
                        format: (value: any[]) => {
                            return value.length > 0 ? value[0].name : '';
                        },
                    },
                },

                {
                    property: 'enabled',
                },
                {
                    property: 'createTime',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            type: 'link',
                            statusKey: 'enabled',
                            renderComponent: 'Button',
                            style: { display: 'inline-flex' },
                            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/WEB-API'),
                            handles: [
                                {
                                    value: false,
                                    apiPath: '/admin/pages/update/enabled',
                                    params: {
                                        enabled: 'enabled',
                                    },
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                                {
                                    value: true,
                                    apiPath: '/admin/pages/update/enabled',
                                    params: {
                                        enabled: 'disable',
                                    },
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'component',
                        component: 'CustomLink',
                        text: '<<components.PageManage.decorate>>',
                        config: {
                            url: `/design-editor/?type=CARPARK_PAGE&id={{row.id}}`,
                            target: '_blank',
                        },
                    },
                    {
                        type: 'component',
                        component: 'PageNewPreviewButton',
                        config: {
                            type: 'all',
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.log>>',
                        path: '/carpark-page/log/{{row.id}}/DESIGN_PAGE',
                    },
                ],
            },
        },
        CarparkLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'OperationLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-EVENT-TRACKING`,
                    apiPath: '/admin/operation_logs/v2/page',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/carpark-page',
                    component: 'CarparkPageView',
                    breadcrumbName: '<<tcjf_1>>',
                    privilege: {
                        path: 'merchantPageNew',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/design/:id',
                            component: 'CarparkPageDesign',
                        },
                        {
                            path: '/edit/:id',
                            component: 'CarparkPageEdit',
                        },
                        {
                            path: '/log/:business_id/:business_type',
                            component: 'CarparkLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        {
                            path: '/',
                            component: 'CarparkPage',
                        },
                    ],
                },
            ],
        },
    ],
};
