import { builder } from '@comall-backend-builder/core';
import { List } from './list';
import { PagePreviewButton } from './page-preview-button';
import { PageNewPreviewButton } from './page-new-preview-button';
import { PageTemplateListItem } from './page-template-list-item';
import { CGBLeaderSetup } from './cgb-leader-setup';
import { PageTemplateSelector } from './page-template-selector';
import { MerchantSelector } from './merchant-selector';
import { LinkType as LinkTypePlus } from './link-type';

export {
    List,
    PagePreviewButton,
    PageNewPreviewButton,
    PageTemplateListItem,
    PageTemplateSelector,
    MerchantSelector,
    LinkTypePlus,
};

builder.registerComponent('PageTemplateListItem', PageTemplateListItem);
builder.registerComponent('PagePreviewButton', PagePreviewButton);
builder.registerComponent('PageNewPreviewButton', PageNewPreviewButton);
builder.registerComponent('CGBLeaderSetup', CGBLeaderSetup);
