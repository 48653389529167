import React, { Component } from 'react';
import { Menu, Dropdown, Button as AntButton, Icon as AntIcon } from 'antd';
import { cloneDeep, forEach, get, isEqual } from 'lodash';
import { BargainProductsSelectedDisplay } from './bargain-products-selected-display';
import { BargainGoodsSelectedDisplay } from './bargain-goods-selected-display';
import { GroupBuyingProductType, GoodsSelectorModal } from '../../../../components';
import { services } from '@comall-backend-builder/core';

interface ObjectBargainProductOrderGoodsSelectValue {
    products: any[];
    goods: any[];
}

type Props = {
    onChange: (value: ObjectBargainProductOrderGoodsSelectValue) => void;
    requestStatus: string;
    value: ObjectBargainProductOrderGoodsSelectValue;
    row: any;
    result: any[];
    fields: any;
    disabled?: boolean;
};

/**
 * 选择商品
 */
export class ObjectBargainProductOrderGoodsSelect extends Component<Props, {}> {
    componentDidMount() {
        const { value, onChange } = this.props;
        if (!value) {
            onChange({
                goods: [],
                products: [],
            });
        }
    }

    componentWillReceiveProps(nextProps: any) {
        const { onChange } = nextProps;
        const nextSubsiteIds = this.getSubsiteIds(nextProps);
        const currentSubsiteIds = this.getSubsiteIds(this.props);
        if (currentSubsiteIds && nextSubsiteIds && !isEqual(nextSubsiteIds, currentSubsiteIds)) {
            onChange({
                goods: [],
                products: [],
            });
        }
    }

    getSubsiteIds = (props: any) => {
        const subsiteId = get(props, 'row.baseInfo.subsites');
        return subsiteId;
    };

    onSelectGoods = (selectValues: any[]) => {
        const { value, onChange } = this.props;
        const data = this.processSelectedData(cloneDeep(selectValues));
        value.goods = data;
        value.products = [];
        onChange(value);
    };

    onSelectProducts = (selectValues: any[]) => {
        const { value, onChange } = this.props;
        const data = this.processSelectedData(cloneDeep(selectValues));
        value.products = data;
        value.goods = [];
        onChange(value);
    };

    onChangeGoods = (goods: any[]) => {
        const { value, onChange } = this.props;
        value.goods = goods;
        value.products = [];
        onChange(value);
    };

    onChangeProducts = (products: any[]) => {
        const { value, onChange } = this.props;
        value.products = products;
        value.goods = [];
        onChange(value);
    };

    processSelectedData = (data: any[]) => {
        let items: any[] = [];
        if (data && data.length) {
            forEach(data, (product) => {
                forEach(product.goodsVos, (goods: any) => {
                    items.push({
                        id: goods.id,
                        name: goods.name,
                        productId: goods.productId,
                        productName: goods.productName,
                        productCode: product.barcode,
                        goodsCode: goods.csku,
                        subsiteId: goods.subsiteId + '',
                        subsiteName: goods.subsiteName,
                        merchantName: goods.merchantName,
                        marketPrice: (+goods.marketPrice.value).toFixed(2),
                        salesPrice: (+goods.salesPrice.value).toFixed(2),
                        goodsDeductPoint: goods.points.value,
                        goodsStock: goods.stock,
                    });
                });
            });
        }
        return items;
    };

    render() {
        const { value, disabled } = this.props;
        if (!value) {
            return null;
        }
        const subsite = this.getSubsiteIds(this.props);
        if (!subsite) {
            return <div>{services.language.getText('selectBeforeMerchant')}</div>;
        }
        const normalFilterFields = [
            'keywords',
            'subsiteId',
            'merchantId',
            'supplyChain',
            'tradeType',
        ];
        const normalColumns = [
            'productInfo',
            'goodsMvo.stock',
            'goodsMvo.productStyleName',
            'goodsMvo.subsiteName',
            'goodsMvo.merchantName',
            'tradeType',
        ];
        const visualFilterFields = ['keywords', 'subsiteId', 'merchantId'];
        const visualColumns = [
            'productInfo',
            'brandName',
            'goodsMvo.subsiteName',
            'goodsMvo.merchantName',
        ];
        const productParams = {
            subsiteIds: subsite,
            shelfStatus: 'SHELF',
            productTypes: ['NORMAL'],
            field: 'createTime',
            order: 'DESCEND',
        };
        const goodsParams = {
            subsiteIds: subsite,
            shelfStatus: 'SHELF',
            productTypes: 'VIRTUAL_COUPON',
            field: 'createTime',
            order: 'DESCEND',
        };
        const menu = (
            <Menu>
                {Object.entries(GroupBuyingProductType).map(([key, v]) =>
                    v !== GroupBuyingProductType.COUPON ? (
                        <Menu.Item key={key}>
                            <GoodsSelectorModal
                                buttonText={v}
                                onChange={
                                    v === GroupBuyingProductType.GENERAL_PRODUCT
                                        ? this.onSelectProducts
                                        : this.onSelectGoods
                                }
                                filterFields={
                                    v === GroupBuyingProductType.GENERAL_PRODUCT
                                        ? normalFilterFields
                                        : visualFilterFields
                                }
                                columns={
                                    v === GroupBuyingProductType.GENERAL_PRODUCT
                                        ? normalColumns
                                        : visualColumns
                                }
                                rowSelectionType="radio"
                                params={
                                    v === GroupBuyingProductType.GENERAL_PRODUCT
                                        ? productParams
                                        : goodsParams
                                }
                            />
                        </Menu.Item>
                    ) : null
                )}
            </Menu>
        );
        return (
            <div>
                {!disabled && (
                    <Dropdown overlay={menu}>
                        <AntButton>
                            {services.language.getText('selectPlease')}
                            <AntIcon type="down" />
                        </AntButton>
                    </Dropdown>
                )}
                <div style={{ marginBottom: '10px' }}>
                    {value.products.length > 0 && (
                        <BargainProductsSelectedDisplay
                            data={value.products}
                            onChange={this.onChangeProducts}
                            disabled={disabled}
                        />
                    )}
                    {value.goods.length > 0 && (
                        <BargainGoodsSelectedDisplay
                            data={value.goods}
                            onChange={this.onChangeGoods}
                            disabled={disabled}
                        />
                    )}
                </div>
            </div>
        );
    }
}
