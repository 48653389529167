import React, { Component } from 'react';
import { get, isEqual } from 'lodash';
import { Input } from 'antd';

// import { ProductSelector } from '../../../../components';
// import { language } from '@comall-backend-builder/core/lib/services';

export class SalesElectronicCouponName extends Component<any, any> {
    componentWillReceiveProps(nextProps: any) {
        const { seat } = nextProps;
        if (seat.includes('baseInfo')) {
            const currentCoupon = get(this.props, 'row.baseInfo.coupon', []);
            const nextCoupon = get(nextProps, 'row.baseInfo.coupon', []);
            const nextName = get(nextProps, 'row.baseInfo.name', '');
            if (
                !isEqual(currentCoupon, nextCoupon) &&
                !nextName &&
                nextCoupon &&
                nextCoupon.length > 0
            ) {
                const [coupon] = nextCoupon;
                this.props.onChange(coupon.names || coupon.name);
            }
        } else {
            const nextProductType = get(nextProps, 'row.productType');
            if (nextProductType === 'POINTS') {
                const currentGift = get(this.props, 'row.gift', {});
                const nextGift = get(nextProps, 'row.gift', {});
                if (!isEqual(currentGift, nextGift) && nextGift && nextGift.name) {
                    this.props.onChange(nextGift.names || nextGift.name);
                }
                return;
            }
            const currentCoupon = get(this.props, 'row.coupon', []);
            const nextCoupon = get(nextProps, 'row.coupon', []);
            if (!isEqual(currentCoupon, nextCoupon) && nextCoupon && nextCoupon.length > 0) {
                const [coupon] = nextCoupon;
                this.props.onChange(coupon.names || coupon.name);
            }
        }
    }
    onSelect = (products: any) => {
        const { changeField, onChange } = this.props;
        if (!changeField || !products.length) {
            return;
        }
        const [
            {
                name,
                brandVo,
                pictures,
                goodsMvo: { weight },
                categories,
            },
        ] = products;
        onChange(name);
        changeField('images', pictures);
        changeField('weight', weight);
        changeField('brand', brandVo ? [brandVo] : []);
        changeField(
            'categories',
            categories.map((item: any) => {
                return parseInt(item.id);
            })
        );
    };

    // showSelectProduct = () => {
    //     const { row } = this.props;
    //     if (row.productType === 'POINTS') {
    //         return true;
    //     }
    //     return false;
    // };

    render() {
        // const showProductSelector = this.showSelectProduct();
        return (
            <div>
                <Input className="input" {...this.props} />
                {/* {showProductSelector && (
                    <div style={{ display: 'inline-block' }}>
                        <ProductSelector
                            onChange={this.onSelect}
                            rowSelectionType="radio"
                            buttonText={language.getText('applyProductInfo')}
                        />
                    </div>
                )} */}
            </div>
        );
    }
}
