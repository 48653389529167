import React, { PureComponent } from 'react';
import { Button, Modal, Select, Input, message as AntMessage, message, Popconfirm } from 'antd';
import { services } from '@comall-backend-builder/core';
import { api, language } from '@comall-backend-builder/core/lib/services';
import { cloneDeep } from 'lodash';
import { DateTimeRangePickerPlus } from '../../../../components';
import './index.less';
import { CalendarType } from '..';

const { Option } = Select;

export interface MarketingCalendarPageSettingMarket {
    subsiteId: string;
    name: string;
    date: any;
    thirdId: string;
}
export interface MarketingCalendarPageSettingMarketButtonStates {
    handleModal: boolean;
    subsites: Array<any>;
    inputInfo: MarketingCalendarPageSettingMarket;
}
export interface MarketingCalendarPageSettingMarketButtonProps {
    refresh: () => void;
    isEdit: boolean;
    editInfo?: any;
}
const defalutInputInfo: MarketingCalendarPageSettingMarket = {
    subsiteId: '',
    name: '',
    date: undefined,
    thirdId: '',
};
export class MarketingCalendarPageSettingMarketButton extends PureComponent<
    MarketingCalendarPageSettingMarketButtonProps,
    MarketingCalendarPageSettingMarketButtonStates
> {
    constructor(props: any) {
        super(props);
        this.state = {
            handleModal: false,
            subsites: [],
            inputInfo: defalutInputInfo,
        };
    }
    hideModal = () => {
        this.setState({
            handleModal: false,
        });
    };
    showModal = () => {
        const { handleModal } = this.state;
        const { isEdit, editInfo } = this.props;
        if (!handleModal) {
            api.get({}, { apiPath: '/admin/subsites/mine' }).then((res: any) => {
                if (res && res.result.length > 0) {
                    this.setState({
                        subsites: res.result,
                    });
                }
            });
        }
        //编辑时
        if (isEdit && editInfo) {
            api.get(
                {},
                {
                    apiRoot: ENV.API_ROOT.replace('/WEB-API', '/AFFILIATE-MARKETING'),
                    apiPath: `/admin/marketing_schedules/${editInfo.id}`,
                }
            ).then((res: any) => {
                this.setState({
                    inputInfo: {
                        ...res,
                        date:
                            res.startTime && res.endTime
                                ? {
                                      start: res.startTime,
                                      end: res.endTime,
                                  }
                                : null,
                        subsiteId: res.subsite?.id,
                    },
                });
            });
        }
        this.setState({
            handleModal: true,
        });
    };

    confirmHandle = async () => {
        const { inputInfo } = this.state;
        const { refresh, isEdit, editInfo } = this.props;
        if (!inputInfo.subsiteId) {
            AntMessage.warning(language.getText('selectSubsite'));
            return false;
        }
        if (!inputInfo.name) {
            AntMessage.warning(language.getText('pleaseMarketingName'));
            return false;
        }
        const apiRoot = ENV.API_ROOT.replace('/WEB-API', '/AFFILIATE-MARKETING');
        const params = {
            subsite: {
                id: inputInfo.subsiteId,
            },
            name: inputInfo.name,
            startTime: inputInfo.date ? inputInfo.date.start : undefined,
            endTime: inputInfo.date ? inputInfo.date.end : undefined,
            thirdId: inputInfo.thirdId,
        };
        if (isEdit) {
            api.put(params, {
                apiRoot,
                apiPath: `/admin/marketing_schedules/${editInfo.id}`,
            }).then(() => {
                message.success(services.language.getText('common.saveSuccess'));
                refresh();
                this.hideModal();
            });
        } else {
            api.post(params, {
                apiRoot,
                apiPath: `/admin/marketing_schedules`,
            }).then(() => {
                message.success(services.language.getText('common.saveSuccess'));
                refresh();
                this.hideModal();
                this.setState({
                    subsites: [],
                    inputInfo: defalutInputInfo,
                });
            });
        }
    };

    delete = () => {
        const { refresh, editInfo } = this.props;
        const apiRoot = ENV.API_ROOT.replace('/WEB-API', '/AFFILIATE-MARKETING');
        api.delete(
            {},
            {
                apiRoot,
                apiPath: `/admin/marketing_schedules/${editInfo.id}`,
            }
        ).then(
            () => {
                message.success(services.language.getText('sccg'));
                refresh();
                this.hideModal();
            },
            (error) => {
                services.errorHandle(error);
            }
        );
    };

    onChangeInputInfo = (type: string, value: string) => {
        const { inputInfo } = this.state;
        const newInputInfo = cloneDeep(inputInfo);
        // @ts-ignore
        newInputInfo[type] = value;
        this.setState({
            inputInfo: newInputInfo,
        });
    };

    renderAddMarketModal = () => {
        const { handleModal, subsites, inputInfo } = this.state;
        const { isEdit } = this.props;
        return (
            <Modal
                title={`${
                    isEdit ? language.getText('common.edit') : language.getText('cj')
                }${language.getText('yxdq')}`}
                className="store-selector"
                width={800}
                visible={handleModal}
                onCancel={this.hideModal}
                cancelButtonProps={{ disabled: true }}
                footer={[
                    <div>
                        <Button onClick={this.hideModal}>{services.language.getText('qx')}</Button>
                        {isEdit && (
                            <Popconfirm
                                onConfirm={this.delete}
                                title={language.getText('confirmDelete')}
                                okText={language.getText('common.ok')}
                                cancelText={language.getText('common.cancel')}
                            >
                                <Button>{services.language.getText('common.delete')}</Button>
                            </Popconfirm>
                        )}
                        <Button type="primary" onClick={this.confirmHandle}>
                            {services.language.getText('common.ok')}
                        </Button>
                    </div>,
                ]}
            >
                <div>
                    <div className="marketing-calendar-page-setting-market-button__right-wrap">
                        <div className="marketing-calendar-page-setting-market-button__flex">
                            <div className="marketing-calendar-page-setting-market-button__title">
                                <span className="red-tip">*</span>
                                {language.getText('otherSelectSubsite')}：
                            </div>
                            <div className="marketing-calendar-page-setting-market-button__value">
                                <Select
                                    showSearch
                                    optionFilterProp="children"
                                    value={inputInfo.subsiteId}
                                    className="find-filter-width"
                                    placeholder={language.getText('otherSelectSubsite')}
                                    onChange={(value: any) => {
                                        this.onChangeInputInfo('subsiteId', value);
                                    }}
                                    style={{ width: '200px' }}
                                    filterOption={(input: any, option: any) => {
                                        if (!option || !option.props) return false;
                                        return option.props.children.includes(input);
                                    }}
                                >
                                    {subsites &&
                                        subsites.map((subsite: any) => {
                                            return (
                                                <Option key={subsite.id} value={subsite.id}>
                                                    {subsite.name}
                                                </Option>
                                            );
                                        })}
                                </Select>
                            </div>
                        </div>
                        <div className="marketing-calendar-page-setting-market-button__flex">
                            <div className="marketing-calendar-page-setting-market-button__title">
                                <span className="red-tip">*</span>
                                {language.getText('yxdqmc')}：
                            </div>
                            <div className="marketing-calendar-page-setting-market-button__value">
                                <Input
                                    placeholder={language.getText('inputPlease')}
                                    value={inputInfo.name}
                                    onChange={(value: any) =>
                                        this.onChangeInputInfo('name', value.target.value)
                                    }
                                    maxLength={40}
                                />
                            </div>
                        </div>
                        <div className="marketing-calendar-page-setting-market-button__flex">
                            <div className="marketing-calendar-page-setting-market-button__title">
                                {language.getText('dqsj')}：
                            </div>
                            <div className="marketing-calendar-page-setting-market-button__value">
                                <DateTimeRangePickerPlus
                                    value={inputInfo.date}
                                    onChange={(value: any) => this.onChangeInputInfo('date', value)}
                                />
                            </div>
                        </div>
                        <div className="marketing-calendar-page-setting-market-button__flex">
                            <div className="marketing-calendar-page-setting-market-button__title">
                                {language.getText('thirdActivityId')}：
                            </div>
                            <div className="marketing-calendar-page-setting-market-button__value">
                                <Input
                                    placeholder={language.getText('inputPlease')}
                                    value={inputInfo.thirdId}
                                    onChange={(value: any) =>
                                        this.onChangeInputInfo('thirdId', value.target.value)
                                    }
                                    maxLength={200}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    };

    render() {
        const { isEdit, editInfo } = this.props;
        const edit = require('../icon/icon-edit.png');
        return (
            <div>
                {isEdit && editInfo ? (
                    <div
                        className="marketing-calendar-page-setting-market-button__activity-name"
                        onClick={this.showModal}
                    >
                        {editInfo.name}
                        {editInfo.type === CalendarType.SCHEDULE && (
                            <span>
                                {` `}({editInfo.length})
                            </span>
                        )}
                        {editInfo.type === CalendarType.SCHEDULE && (
                            <img
                                className="marketing-calendar-page-setting-market-button__edit"
                                alt={services.language.getText('common.edit')}
                                src={edit}
                            ></img>
                        )}
                    </div>
                ) : (
                    <Button
                        type={'primary'}
                        style={{ marginLeft: '10px' }}
                        onClick={this.showModal}
                    >
                        {language.getText('createMarketingSchedule')}
                    </Button>
                )}
                {this.renderAddMarketModal()}
            </div>
        );
    }
}
