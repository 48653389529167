import { Config } from '@comall-backend-builder/core/lib/parser';
import {
    WechatOfficialAccountFunctionType,
    createChecker,
    createValidator,
    createWechatOfficialAccountRecordsFunctionsAuth,
} from '../../types/format/object/auto-complete-appid-by-subsite';
import moment from 'moment';
import { AutoReplyVo } from '../../containers/wechat/components/wechant-content';
import { services } from '@comall-backend-builder/core';

const wechatOfficialAccountFunctionCheckTypes = [
    WechatOfficialAccountFunctionType.AccountService,
    WechatOfficialAccountFunctionType.Notification,
    WechatOfficialAccountFunctionType.ResourceManager,
];
const wechatOfficialAccountChecker = createChecker(
    createWechatOfficialAccountRecordsFunctionsAuth(wechatOfficialAccountFunctionCheckTypes)
);
const wechatOfficialAccountFunctionValidator = createValidator(wechatOfficialAccountChecker);

export const config: Config = {
    entities: {
        WechatOfficialAccountQrcodeRules: {
            apiPath: '/loader/admin/wechat_official_account_qrcode_rules',
            // apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
            properties: {
                wechatOfficialAccountName: {
                    displayName: '<<wechatOfficialAccount>>',
                    type: 'string',
                },
                name: {
                    displayName: '<<ewmmc>>',
                    type: 'string',
                },
                permanentDesc: {
                    displayName: '<<ewmlx>>',
                    type: 'string',
                },
                totalScanCount: {
                    displayName: '<<ljsmcs>>',
                    type: 'string',
                },
                totalScanUserCount: {
                    displayName: '<<ljsmrs>>',
                    type: 'string',
                },
                totalFollowCount: {
                    displayName: '<<ljxzgzs>>',
                    type: 'string',
                },
                openMiniProgramPercentage: {
                    displayName: '<<ljdkxcxrszb>>',
                    type: 'string',
                },
                replyEffectTime: {
                    displayName: '<<hfsx>>',
                    type: 'string',
                },
                replyStartTime: {
                    displayName: '<<hfkssj>>',
                    type: 'string',
                },
                replyEndTime: {
                    displayName: '<<hfsxsj>>',
                    type: 'string',
                },
                replyStatus: {
                    displayName: '<<hfsxzt>>',
                    type: 'string.options.select',
                    options: [
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                createTime: {
                    displayName: '<<createTimeStr>>',
                    type: 'string',
                },
                subsite: {
                    displayName: '<<belongSubsite>>',
                    type: 'string',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                merchant: {
                    type: 'string',
                    displayName: '<<belongMerchant>>',
                },
                baseInfo: {
                    displayName: '<<basicSetting>>',
                    type: 'object.subForm',
                    properties: {
                        subsite: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            defaultValue: [],
                            rules: [{ required: true }],
                            controlConfig: {
                                type: 'radio',
                            },
                        },
                        merchant: {
                            type: 'array.shop.selector',
                            displayName: '<<selectSubsites>>',
                            defaultValue: [],
                            controlConfig: {
                                type: 'radio',
                            },
                            rules: [{ required: true }],
                        },
                        subsiteId: {
                            displayName: '<<otherSelectSubsite>>',
                            type: 'string.options.autoComplete',
                            options: [],
                            rules: [{ required: true }],
                            source: {
                                apiPath: '/loader/source/admin/v1/subsites/mine',
                            },
                            controlConfig: {
                                placeholder: '<<qxzygmd>>',
                                style: {
                                    width: 300,
                                },
                            },
                        },
                        wechatOfficialAccount: {
                            type: 'object.autoCompleteAppIdBySubsite',
                            displayName: '<<wechatOfficialAccount>>',
                            controlConfig: {
                                placeholder: '<<qxzyggzh>>',
                            },
                            rules: [{ validator: wechatOfficialAccountFunctionValidator }],
                        },
                        name: {
                            type: 'string',
                            displayName: '<<ewmmc>>',
                            controlConfig: {
                                placeholder: '<<qsrewmmc>>',
                                style: {
                                    width: 300,
                                },
                                maxLength: 64,
                            },
                            rules: [{ required: true }],
                        },
                        effectiveType: {
                            displayName: '<<ewmlx>>',
                            type: 'string.options.radio',
                            defaultValue: 'eternal',
                            options: [
                                { id: 'eternal', name: '<<yj_3>>' },
                                { id: 'temporary', name: '<<ls>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        effectiveTypeDisplay: {
                            displayName: '<<ewmlx>>',
                            type: 'string',
                            modifiable: false,
                        },
                        effectiveDays: {
                            type: 'number.tip',
                            displayName: '<<lsmyxq>>',
                            defaultValue: null,
                            rules: [{ required: true }],
                            controlConfig: {
                                addonbefore: '<<cjh>>',
                                addonAfter: '<<effectDay>>',
                                placeholder: '<<effectDay_30>>',
                                min: 1,
                                max: 30,
                                precision: 0,
                                style: {
                                    width: 156,
                                },
                                tips: '<<gqhyhjwfsmjrgzh_1>>',
                            },
                        },
                        replyEffectiveType: {
                            displayName: '<<ewmhfxxsxsj>>',
                            type: 'string.options.radio',
                            defaultValue: 'eternal',
                            options: [
                                { id: 'eternal', name: '<<longTerm>>' },
                                { id: 'temporary', name: '<<ls>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        replyDateRange: {
                            type: 'object.dateTimeRange',
                            displayName: '<<zdysxsj>>',
                            rules: [
                                { required: true },
                                {
                                    validator(_rule: any, value: any, callback: Function) {
                                        if (value) {
                                            const { start, end } = value;
                                            if (moment(start).isSameOrAfter(end)) {
                                                callback(services.language.getText('jssjbxdykssj'));
                                                return;
                                            }
                                        }
                                        callback();
                                    },
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        dimensionType: {
                            type: 'string.options.radio',
                            displayName: '<<statisticType>>',
                            options: [
                                { id: 'SUBSITE', name: '<<subsiteSelect>>' },
                                { id: 'MERCHANT', name: '<<merchantSelect>>' },
                            ],
                            defaultValue: 'SUBSITE',
                            rules: [{ required: true }],
                        },
                    },
                },
                content: {
                    displayName: '<<hfnr>>',
                    type: 'object.subForm',
                    properties: {
                        useDefaultReply: {
                            type: 'boolean.wechatOfficalUseDefaultReplyBooleanMode',
                            displayName: '<<mrhf>>',
                            defaultValue: true,
                            controlConfig: {
                                appIdPath: 'baseInfo.wechatOfficialAccount.appId',
                                appNamePath: 'baseInfo.wechatOfficialAccount.name',
                            },
                        },
                        replyContents: {
                            displayName: '<<zdyhf>>',
                            type: 'array.wechatOfficialQRcodeReply',
                            defaultValue: [],
                            controlConfig: {
                                appIdPath: 'baseInfo.wechatOfficialAccount.appId',
                                appNamePath: 'baseInfo.wechatOfficialAccount.name',
                            },
                            rules: [
                                {
                                    validator(
                                        _rule: any,
                                        value: AutoReplyVo[],
                                        callback: Function
                                    ) {
                                        // 检查是否有回复未选择人群
                                        if (value.some(({ crowdScope }) => !crowdScope)) {
                                            callback(services.language.getText('qxzhfrq'));
                                        }
                                        // 检查人群重复
                                        const template = new Map<string, boolean>();
                                        const names: Record<string, string> = {
                                            ALL: services.language.getText('qbfs'),
                                            MEMBER: services.language.getText('hyfs'),
                                            NON_MEMBER: services.language.getText('fhyfs'),
                                        };
                                        for (let index = 0; index < value.length; index++) {
                                            const { crowdScope } = value[index];
                                            if (template.has(crowdScope)) {
                                                callback(
                                                    services.interpolate(
                                                        services.language.getText('hfrq_1'),
                                                        {
                                                            crowdScope: names[crowdScope],
                                                        }
                                                    )
                                                );
                                                return;
                                            } else {
                                                template.set(crowdScope, true);
                                            }
                                        }
                                        // 检查只有人群没有回复内容
                                        if (
                                            value.some(
                                                ({ messageContent = [] }) => !messageContent.length
                                            )
                                        ) {
                                            callback(
                                                services.language.getText('mghfrqzsytjyghfnr')
                                            );
                                        }
                                        callback();
                                    },
                                },
                            ],
                        },
                        defaultReplyContents: {
                            displayName: ' ',
                            type: 'array.wechatOfficialQRcodeReply',
                            defaultValue: [],
                            colon: false,
                            displayConfig: {
                                appIdPath: 'baseInfo.wechatOfficialAccount.appId',
                            },
                        },
                    },
                },
            },
            filters: {
                officialAccountName: {
                    displayName: '<<wechatOfficialAccount>>',
                    type: 'string',
                },
                name: {
                    displayName: '<<ewmmc>>',
                    type: 'string',
                },
                permanent: {
                    displayName: '<<ewmlx>>',
                    type: 'string.options.select',
                    options: [
                        { id: 'true', name: '<<yj_3>>' },
                        { id: 'false', name: '<<ls>>' },
                    ],
                },
                replyStatus: {
                    displayName: '<<hfsxzt>>',
                    type: 'string.options.select',
                    options: [
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<belongSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/loader/source/admin/v1/subsites/mine',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 150 } },
                },
                merchantId: {
                    type: 'string.filterOptions.autoComplete',
                    displayName: '<<belongMerchant>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 150 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
            },
        },
    },
    components: {
        WechatOfficialParametricQrcodePage: {
            component: 'TabsPlus',
            type: 'card',
            defaultActiveKey: 'channel',
            cache: false,
            animated: false,
            basePath: '/wechat-official-parametric-qrcode',
            items: [
                {
                    title: '<<wechatOfficialParametricQrcode>>',
                    content: {
                        component: 'WechatOfficialParametricQrcodeDistributionChannelPage',
                    },
                    params: { dimensionTypes: 'CHANNEL' },
                    key: 'channel',
                },
                {
                    title: '<<mdzjgzhewm>>',
                    content: {
                        component: 'WechatOfficialParametricQrcodeSubsiteMerchantPage',
                    },
                    params: { dimensionTypes: 'SUBSITE,MERCHANT' },
                    key: 'subsite-merchant',
                },
                {
                    title: '<<ewmmrhfsz>>',
                    content: {
                        component: 'WechatOfficialParametricQrcodeDefaultReplyPage',
                    },
                    key: 'default-reply-setting',
                },
            ],
        },
        WechatOfficialParametricQrcodeDistributionChannelPage: {
            entity: 'WechatOfficialAccountQrcodeRules',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'WechatOfficialParametricQrcodeDistributionChannelFilter',
                },
                {
                    component: 'WechatOfficialParametricQrcodeDistributionChannelTable',
                },
            ],
        },
        WechatOfficialParametricQrcodeDistributionChannelFilter: {
            component: 'FilterForm',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
                style: {
                    marginLeft: 16,
                },
            },
            direction: 'inline',
            labelCol: 8,
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'officialAccountName',
                    controlConfig: {
                        style: {
                            width: 120,
                        },
                    },
                },
                {
                    property: 'name',
                },
                {
                    property: 'permanent',
                    controlConfig: {
                        style: {
                            width: 120,
                        },
                    },
                },
                {
                    property: 'replyStatus',
                    controlConfig: {
                        style: {
                            width: 120,
                        },
                    },
                },
            ],
        },
        WechatOfficialParametricQrcodeDefaultReplyPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'WechatOfficialParametricQrcodeDefaultReplySettingPage',
                    entity: 'storeSelect',
                },
            ],
        },
        WechatOfficialParametricQrcodeDistributionChannelAddPage: {
            component: 'WechatOfficalParametricQrcodeDistributionChannelForm',
            entity: 'WechatOfficialAccountQrcodeRules',
            config: {
                component: 'CreateFormPlus',
            },
        },
        WechatOfficialParametricQrcodeDistributionChannelEditPage: {
            component: 'WechatOfficalParametricQrcodeDistributionChannelForm',
            entity: 'WechatOfficialAccountQrcodeRules',
            loaderType: 'get',
            config: {
                component: 'ModifyFormPlus',
            },
        },
        WechatOfficialParametricQrcodeSubsiteMerchantPage: {
            entity: 'WechatOfficialAccountQrcodeRules',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                { component: 'WechatOfficialParametricQrcodeSubsiteMerchantFilter' },
                { component: 'WechatOfficialParametricQrcodeSubsiteMerchantTable' },
            ],
        },
        WechatOfficialParametricQrcodeSubsiteMerchantFilter: {
            component: 'FilterForm',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
                style: {
                    marginLeft: 16,
                },
            },
            direction: 'inline',
            labelCol: 8,
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'officialAccountName',
                    controlConfig: {
                        style: {
                            width: 120,
                        },
                    },
                },
                { property: 'name' },
                { property: 'subsiteId' },
                { property: 'merchantId' },
                {
                    property: 'replyStatus',
                    controlConfig: {
                        style: {
                            width: 120,
                        },
                    },
                },
            ],
        },
        WechatOfficialParametricQrcodeEditSubsitMerchantePage: {
            component: 'WechatOfficalParametricQrcodeSubsiteMerchantForm',
            config: {
                component: 'ModifyFormPlus',
            },
            entity: 'WechatOfficialAccountQrcodeRules',
            loaderType: 'get',
        },
        WechatOfficialParametricQrcodeAddSubsiteMerchantPage: {
            component: 'WechatOfficalParametricQrcodeSubsiteMerchantForm',
            config: {
                component: 'CreateFormPlus',
            },
            entity: 'WechatOfficialAccountQrcodeRules',
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/wechat-official-parametric-qrcode',
                    component: 'Viewport',
                    breadcrumbName: '<<wechatOfficialParametricQrcode>>',
                    privilege: {
                        path: 'qrCodeRules',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add-distribution-channel',
                            breadcrumbName: '<<xzgzhewm>>',
                            component: 'WechatOfficialParametricQrcodeDistributionChannelAddPage',
                        },
                        {
                            path: '/edit-distribution-channel/:id',
                            breadcrumbName: '<<bjgzhewm>>',
                            component: 'WechatOfficialParametricQrcodeDistributionChannelEditPage',
                        },
                        {
                            path: '/add-subsite-merchant-qrcode',
                            breadcrumbName: '<<addSubsite>>/<<zjewm>>',
                            component: 'WechatOfficialParametricQrcodeAddSubsiteMerchantPage',
                        },
                        {
                            path: '/edit-subsite-merchant-qrcode/:id',
                            breadcrumbName: '<<addSubsite>>/<<zjewm>>',
                            component: 'WechatOfficialParametricQrcodeEditSubsitMerchantePage',
                        },
                        {
                            path: '/',
                            component: 'WechatOfficialParametricQrcodePage',
                        },
                    ],
                },
            ],
        },
    ],
};
