import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    components: {
        biDatartView: {
            component: 'Viewport',
        },
        biDatartPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'BiDatartPage',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/bi-datart',
                    component: 'biDatartView',
                    breadcrumbName: '<<biDatart>>',
                    privilege: {
                        path: 'biDatart',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/',
                            component: 'biDatartPage',
                        },
                    ],
                },
            ],
        },
    ],
};
