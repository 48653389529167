import React, { Component } from 'react';
import { Table, Button, InputNumber, message as AntMessage } from 'antd';
import { cloneDeep, remove, find } from 'lodash';
import { Subsite } from '../../containers/cgb-config-select-subsite';
import './index.less';
import { services } from '@comall-backend-builder/core';

const { getText } = services.language;

interface GiftSubsiteSelectedProps {
    data: Array<Subsite>;
    onRemove: (data: Array<Subsite>) => void;
    onChange: (data: Array<Subsite>) => void;
    disabled: boolean;
}
interface GiftSubsiteSelectedState {
    displayAll: boolean;
}

/**
 * 分站展示和内容编辑
 */
class GiftSubsiteSelected extends Component<GiftSubsiteSelectedProps, GiftSubsiteSelectedState> {
    state: GiftSubsiteSelectedState = {
        displayAll: false,
    };
    selectedRows: Subsite[] = [];

    getTableConfig = () => {
        const { disabled } = this.props;
        let config: any = {
            rowKey: 'id',
            columns: [
                {
                    title: services.language.getText('subsiteName'),
                    dataIndex: 'name',
                    key: 'name',
                    ellipsis: true,
                },
                {
                    title: getText('szkdsl'),
                    dataIndex: 'stock',
                    key: 'stock',
                    render: (stock: any, record: any) => {
                        return (
                            <div style={{ width: 120 }}>
                                <InputNumber
                                    placeholder={getText('qsrkdsl')}
                                    value={stock}
                                    min={0}
                                    max={9999999}
                                    onChange={(e: any) => this.changeStock(record, e)}
                                    style={{ width: 120 }}
                                ></InputNumber>
                            </div>
                        );
                    },
                },
                {
                    title: services.language.getText('common.tableAction'),
                    className: 'action-column',
                    key: 'operation',
                    width: 100,
                    render: (i: any, row: any) => {
                        return !disabled ? (
                            <Button type={'link'} onClick={this.onRemove(row)}>
                                {services.language.getText('common.delete')}
                            </Button>
                        ) : null;
                    },
                },
            ],
            footer: () => this.renderFooter(),
            style: {
                marginTop: 20,
            },
        };
        return config;
    };

    renderFooter = () => {
        const { data } = this.props;
        const { displayAll } = this.state;
        return (
            <div className="product-selected-footer">
                {data && data.length > 5 && (
                    <Button
                        className="table-footer-expand"
                        type="link"
                        onClick={this.handleDisplayChange}
                    >
                        {displayAll ? getText('hideSubsite') : getText('viewSubsite')}
                    </Button>
                )}
            </div>
        );
    };

    onRemove = (row: Subsite) => {
        const { data, onRemove } = this.props;

        return () => {
            const result = cloneDeep(data);
            remove(result, (subsite) => row.id === subsite.id);
            onRemove(result);
        };
    };

    /**
     * 设置可兑数量
     */
    changeStock = (record: any, e: any) => {
        const value = e;
        const { data, onChange } = this.props;
        let newData = data ? data.slice(0) : [];
        let row: any = find(newData, { stock: record.stock });
        if (row) {
            var reg = /^(0|\+?[1-9][0-9]*)$/;
            if (value && reg.test(value)) {
                row.stock = value;
                onChange(newData);
            } else {
                AntMessage.warning(services.language.getText('inputNumber'));
                row.stock = '';
                onChange(newData);
                return false;
            }
        }
    };

    handleDisplayChange = () => {
        const { displayAll } = this.state;
        this.setState({
            displayAll: !displayAll,
        });
    };

    render() {
        let { data } = this.props;
        if (!data || data.length === 0) return '';
        const { displayAll } = this.state;
        let tableConfig = this.getTableConfig();
        data = data.map((item: any) => {
            if (!item.stock) {
                item.stock = 0;
            }
            if (!item.goodsId) {
                item.goodsId = 0;
            }
            return item;
        });
        const tableProps = {
            ...tableConfig,
            dataSource: displayAll ? data : data && data.slice(0, 5),
        };

        return <Table {...tableProps} pagination={false}></Table>;
    }
}

export { GiftSubsiteSelected };
