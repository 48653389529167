import { Config } from '@comall-backend-builder/core/lib/parser';
import { api, errorHandle } from '@comall-backend-builder/core/lib/services';
import isEmpty from 'lodash/isEmpty';
import { EntityButtonProps } from '../../../components';
import { ForwardDataCenterModal } from '../../../services';
import { services } from '@comall-backend-builder/core';

export let config: Config = {
    entities: {
        DeliveryLogisticsBillReportEntity: {
            apiPath: '/loader/admin/delivery-logistics-bill-report',
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                expressNumber: {
                    type: 'string',
                    displayName: '<<ydh>>',
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<ydsj>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<ydzt_1>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<selectPlease>>' },
                        { id: 'true', name: '<<success>>' },
                        { id: 'false', name: '<<common.cancel>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                expressNumber: {
                    type: 'string',
                    displayName: '<<ydh>>',
                },
                expressCompanyName: {
                    type: 'string',
                    displayName: '<<wlgs>>',
                },

                insuredFee: {
                    type: 'string',
                    displayName: '<<bjje>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<scsj_1>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<ydzt_1>>',
                    defaultValue: 'true',
                    options: [
                        { id: 'true', name: '<<success>>' },
                        { id: 'false', name: '<<common.cancel>>' },
                    ],
                },
            },
        },
    },
    components: {
        DeliveryLogisticsBillReportView: {
            component: 'Viewport',
            entity: 'DeliveryLogisticsBillReportEntity',
        },
        DeliveryLogisticsBillReportPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [{ component: 'DeliveryLogisticsBillReportTable' }],
        },
        DeliveryLogisticsBillReportTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DeliveryLogisticsBillReportFilter',
                },
                {
                    component: 'GridLayout',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'EntityButton',
                            props: {
                                type: 'primary',
                                children: '<<dcsj_1>>',
                                style: {
                                    width: 110,
                                },
                                onClick: (_: any, entityButtonProps: EntityButtonProps) => {
                                    const params: Record<string, any> = Object.assign(
                                        {},
                                        entityButtonProps.entity.params,
                                        entityButtonProps.entity.filters
                                    );
                                    if (params.subsiteId) {
                                        params.subsiteId = params.subsiteId.id;
                                    }
                                    if (!isEmpty(params.dateRange)) {
                                        params.startTime = params.dateRange.start + ' 00:00:00';
                                        params.endTime = params.dateRange.end + ' 23:59:59';
                                        delete params.dateRange;
                                    }
                                    if (params.status === 'ALL') {
                                        delete params.status;
                                    }

                                    const config = {
                                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                        apiPath:
                                            '/admin/delivery_logistics_bills/delivery_logistics_bills.excel',
                                    };

                                    api.get(params, config)
                                        .then(() => {
                                            ForwardDataCenterModal();
                                        })
                                        .catch((error) => {
                                            errorHandle(error);
                                        });
                                },
                            },
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: 'DeliveryLogisticsBillReportDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'expressCompanyName',
                        },
                        {
                            property: 'expressNumber',
                        },
                        {
                            property: 'insuredFee',
                        },
                        {
                            property: 'createTime',
                        },
                        {
                            property: 'status',
                        },
                    ],
                },
            ],
        },
        DeliveryLogisticsBillReportFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                },
                {
                    property: 'expressNumber',
                },
                {
                    property: 'dateRange',
                },
                {
                    property: 'status',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/delivery-logistics-bill-report',
                    breadcrumbName: '<<deliveryLogisticsBillReport>>',
                    component: 'DeliveryLogisticsBillReportView',
                    privilege: {
                        path: 'deliveryLogisticsBillReport',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'DeliveryLogisticsBillReportPage' }],
                },
            ],
        },
    ],
};
