import React, { Component } from 'react';
import { InputNumber } from 'antd';
import { cloneDeep, get, isEqual } from 'lodash';
import { services } from '@comall-backend-builder/core';

interface CreditShopStockValue {
    maxCount: string;
    changeCount: string;
    surplusCount: string;
    defalutMaxCount: string;
    defalutSurplusCount: string;
    maxSaleNumberEveryDay: string;
}

interface CreditShopStockProps {
    row: any;
    /**
     * 选中值
     */
    value: CreditShopStockValue;
    /**
     * 内容改变回调
     * @param value 新值
     */
    onChange: (value: CreditShopStockValue) => void;
}

export class CreditShopStock extends Component<CreditShopStockProps, {}> {
    componentDidMount() {
        const { value, onChange } = this.props;
        if (!value) {
            onChange({
                maxCount: '',
                changeCount: '',
                surplusCount: '',
                defalutMaxCount: '',
                defalutSurplusCount: '',
                maxSaleNumberEveryDay: '',
            });
        }
    }

    componentWillReceiveProps(nextProps: any) {
        const { seat, value, onChange } = nextProps;
        if (window.location.href.includes('/edit') || window.location.href.includes('/copy')) {
            if (value.maxCount && value.surplusCount) {
                this.setState({
                    defalutMaxCount: value.defalutMaxCount,
                    defalutSurplusCount: value.surplusCount,
                });
            }
            return;
        }
        if (seat.includes('baseInfo')) {
            const currentCoupon = get(this.props, 'row.baseInfo.coupon', []);
            const nextCoupon = get(nextProps, 'row.baseInfo.coupon', []);
            if (!isEqual(currentCoupon, nextCoupon) && nextCoupon && nextCoupon.length > 0) {
                const [coupon] = nextCoupon;
                onChange({
                    maxCount: coupon.leftStock,
                    changeCount: '',
                    surplusCount: '',
                    maxSaleNumberEveryDay: '',
                });
            }
        } else {
            const currentCoupon = get(this.props, 'row.coupon', []);
            const nextCoupon = get(nextProps, 'row.coupon', []);
            if (!isEqual(currentCoupon, nextCoupon) && nextCoupon && nextCoupon.length > 0) {
                const [coupon] = nextCoupon;
                onChange({
                    maxCount: coupon.leftStock,
                    changeCount: '',
                    surplusCount: '',
                    maxSaleNumberEveryDay: '',
                });
            }
        }
    }

    changeValue = (inputValue: any) => {
        const { value, onChange } = this.props;
        const newValue: any = cloneDeep(value);
        if (inputValue && newValue.defalutSurplusCount + inputValue >= 0) {
            newValue.changeCount = inputValue;
            newValue.maxCount = newValue.defalutMaxCount + inputValue;
            newValue.surplusCount = newValue.defalutSurplusCount + inputValue;
            onChange(newValue);
        } else {
            newValue.changeCount = '';
            newValue.maxCount = newValue.defalutMaxCount;
            newValue.surplusCount = newValue.defalutSurplusCount;
            onChange(newValue);
        }
    };
    changeMaxCountValue = (inputValue: any) => {
        const { value, onChange } = this.props;
        const newValue: any = cloneDeep(value);
        newValue.maxCount = inputValue || '';
        onChange(newValue);
    };

    changeMaxSaleNumberEveryDay = (inputValue: any) => {
        const { value, onChange } = this.props;
        const newValue: any = cloneDeep(value);
        newValue.maxSaleNumberEveryDay = inputValue || '';
        onChange(newValue);
    };

    renderMaxSaleNumberEveryDay = () => {
        const { value } = this.props;
        return (
            <div
                style={{
                    margin: '30px 0 0px -112px',
                    color: 'rgba(0, 0, 0, 0.85)',
                }}
            >
                <span>{services.language.getText('spmrxds')}：</span>
                <span>{services.language.getText('mspmrzdkdh')}&nbsp;&nbsp;</span>
                <InputNumber
                    placeholder=""
                    style={{ width: '150px' }}
                    value={
                        value.maxSaleNumberEveryDay !== ''
                            ? parseInt(value.maxSaleNumberEveryDay)
                            : undefined
                    }
                    min={1}
                    max={100000000}
                    step={1}
                    precision={0}
                    onChange={(value: any) => this.changeMaxSaleNumberEveryDay(value)}
                ></InputNumber>
                <span>&nbsp;&nbsp;{services.language.getText('jian')}</span>
                <div style={{ margin: '0px 0 0 112px', color: '#ccc', lineHeight: '24px' }}>
                    {services.language.getText('gspmtdhddh')}
                </div>
            </div>
        );
    };
    render() {
        const { value } = this.props;
        if (!value) {
            return null;
        }
        const isAddCopy =
            window.location.href.includes('/add') || window.location.href.includes('/copy');
        if (isAddCopy) {
            return (
                <div>
                    <div>
                        <InputNumber
                            placeholder={services.language.getText('zdksz1yz')}
                            style={{ width: '150px' }}
                            value={value.maxCount ? parseInt(value.maxCount) : undefined}
                            min={0}
                            max={100000000}
                            step={1}
                            precision={0}
                            onChange={(value: any) => this.changeMaxCountValue(value)}
                        ></InputNumber>
                    </div>
                    {this.renderMaxSaleNumberEveryDay()}
                </div>
            );
        }
        const tip = services.language.getText('rsjkczsr');
        return (
            <div>
                <div>
                    <InputNumber
                        style={{ width: '150px' }}
                        disabled={true}
                        value={value.maxCount !== '' ? parseInt(value.maxCount) : undefined}
                    ></InputNumber>
                </div>
                <div
                    style={{
                        margin: '30px 0 0px -116px',
                        color: 'rgba(0, 0, 0, 0.85)',
                    }}
                >
                    <span>{services.language.getText('jkdhsl')}：</span>
                    <InputNumber
                        placeholder={services.language.getText('zdksz1yz')}
                        style={{ width: '150px' }}
                        value={value.changeCount !== '' ? parseInt(value.changeCount) : undefined}
                        max={100000000}
                        step={1}
                        precision={0}
                        onChange={(value: any) => this.changeValue(value)}
                    ></InputNumber>
                    <div style={{ margin: '0px 0 0 115px', color: '#ccc', lineHeight: '24px' }}>
                        {tip}
                    </div>
                </div>
                <div
                    style={{
                        margin: '30px 0 0px -112px',
                        color: 'rgba(0, 0, 0, 0.85)',
                    }}
                >
                    <span>{services.language.getText('sykdhsl')}：</span>
                    <InputNumber
                        style={{ width: '150px' }}
                        disabled={true}
                        value={value.surplusCount !== '' ? parseInt(value.surplusCount) : undefined}
                    ></InputNumber>
                </div>
                {this.renderMaxSaleNumberEveryDay()}
            </div>
        );
    }
}
