import React, { PureComponent } from 'react';
import { Checkbox, Popover, Icon } from 'antd';
import { services } from '@comall-backend-builder/core';
import { find, forEach } from 'lodash';
import { FreightRule } from '../../delivery-freight-rule-set-up';

const { api } = services;

export interface Region {
    /**
     * 地区id
     */
    id: number;
    /**
     * 地区名称
     */
    name: string;
    /**
     * 父级id
     */
    parentId: number;
    /**
     * 是否含有地区子级
     */
    hasChild: boolean;
    /**
     * 几级
     */
    level: number;
    /**
     * 地区子级
     */
    children?: any;
    /**
     * 是否选中,未选中，半选
     */
    selectStatus?: RegionSelectStatus;
    /**
     * 可用,不可用的情况下置灰 true/false
     */
    availableStatus: boolean;
    /**
     * 只读(代表这个子级下面已经有其他规则用了，所有只能用户自己去下级选择)
     */
    readOnly: boolean;
}

export enum RegionSelectStatus {
    /**
     * 全选
     */
    ALL = 'ALL',
    /**
     * 半选
     */
    PART = 'PART',
    /**
     * 未选中
     */
    NO = 'NO',
}

interface DeliveryFreightRuleRegionProps {
    row: any;
    freightRule: FreightRule;
    parentRegion: Region;
    onChangeShowRegions: (region: Region) => void;
    onChangeRegions: (region: Region) => void;
}

/**
 * 选择专柜
 */
export class DeliveryFreightRuleRegion extends PureComponent<DeliveryFreightRuleRegionProps, {}> {
    /**
     * 读取子级内容
     * @param id
     */
    onGetChildrenRegions = (parentRegion: any, secondRegion?: any, thirdRegion?: any) => {
        const { row, onChangeShowRegions, freightRule } = this.props;
        let id = 0;
        let getFlag = false; //请求过一次 不会再次请求
        if (
            parentRegion &&
            parentRegion.hasChild &&
            parentRegion.children.length === 0 &&
            !secondRegion &&
            !thirdRegion
        ) {
            id = parentRegion.id;
            getFlag = true;
        }
        if (
            secondRegion &&
            secondRegion.hasChild &&
            secondRegion.children.length === 0 &&
            !thirdRegion
        ) {
            id = secondRegion.id;
            getFlag = true;
        }
        if (thirdRegion && thirdRegion.hasChild && thirdRegion.children.length === 0) {
            id = thirdRegion.id;
            getFlag = true;
        }
        if (getFlag) {
            api.get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: `/admin/regions/${id}/children`,
                }
            ).then((response: any) => {
                //1.获取到下一级信息 并且之前保存的选中状态回显
                const getCheckedIds = this.getCheckedValue();
                if (getCheckedIds) {
                    response = response.map((region: any) => {
                        region.selectStatus = RegionSelectStatus.NO;
                        region.availableStatus = true;
                        region.children = [];
                        if (getCheckedIds) {
                            let crow: any = find(getCheckedIds, { id: region.id + '' });
                            if (crow) {
                                region.selectStatus = crow.selectStatus;
                            }
                        }
                        return region;
                    });
                }
                //2.获取用户之前已选择的规则城市，置灰
                let subsiteId;
                let merchantId;
                if (row.baseInfo) {
                    if (row.baseInfo.subsite) {
                        subsiteId = row.baseInfo.subsite.id;
                    }
                    if (row.baseInfo.merchants) {
                        subsiteId = row.baseInfo.merchants[0].subSiteId;
                        merchantId = row.baseInfo.merchants[0].merchantId;
                    }
                }
                api.get(
                    {
                        subsiteId: subsiteId,
                        merchantId: merchantId,
                        deliveryRuleId: freightRule.deliveryRuleId,
                        group: freightRule.group,
                        parentRegionId: id,
                    },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                        apiPath: `/admin/express_deliveries/regions`,
                    }
                ).then((expressDeliveriesResponse: any) => {
                    //3.处理选中，未选中，置灰状态
                    response = response.map((region: any) => {
                        let irow: any = find(expressDeliveriesResponse, { id: region.id + '' });
                        if (irow) {
                            region.availableStatus = irow.availableStatus;
                            region.readOnly = irow.readOnly;
                        }
                        return region;
                    });
                    //4.开始对应赋值
                    if (
                        parentRegion &&
                        parentRegion.hasChild &&
                        parentRegion.children.length === 0
                    ) {
                        parentRegion.children = response;
                        parentRegion.children = parentRegion.children.map((i: any) => {
                            if (
                                parentRegion.selectStatus === RegionSelectStatus.ALL ||
                                parentRegion.selectStatus === RegionSelectStatus.NO
                            ) {
                                i.selectStatus = parentRegion.selectStatus;
                            }
                            return i;
                        });
                    }
                    if (
                        secondRegion &&
                        secondRegion.hasChild &&
                        secondRegion.children.length === 0
                    ) {
                        parentRegion.children = parentRegion.children.map((i: any) => {
                            if (i.id === secondRegion.id) {
                                i.children = response;
                                i.children = i.children.map((j: any) => {
                                    if (
                                        i.selectStatus === RegionSelectStatus.ALL ||
                                        i.selectStatus === RegionSelectStatus.NO
                                    ) {
                                        j.selectStatus = i.selectStatus;
                                    }
                                    return j;
                                });
                            }
                            return i;
                        });
                    }
                    if (thirdRegion && thirdRegion.hasChild && thirdRegion.children.length === 0) {
                        parentRegion.children = parentRegion.children.map((i: any) => {
                            if (i.id === secondRegion.id) {
                                i.children = i.children.map((j: any) => {
                                    if (j.id === thirdRegion.id) {
                                        j.children = response;
                                        j.children = j.children.map((k: any) => {
                                            if (
                                                j.selectStatus === RegionSelectStatus.ALL ||
                                                j.selectStatus === RegionSelectStatus.NO
                                            ) {
                                                k.selectStatus = j.selectStatus;
                                            }
                                            return k;
                                        });
                                    }
                                    return j;
                                });
                            }
                            return i;
                        });
                    }
                    onChangeShowRegions(parentRegion);
                });
            });
        }
    };

    /**
     * 获取之前选中的内容
     */
    getCheckedValue = () => {
        const { freightRule } = this.props;
        const checkendIds: Array<any> = [];
        if (freightRule.deliveryScopesInfo && freightRule.deliveryScopesInfo.length > 0) {
            forEach(freightRule.deliveryScopesInfo, (parentRegion) => {
                forEach(parentRegion.children, (secondRegion) => {
                    checkendIds.push({
                        id: secondRegion.id,
                        selectStatus: secondRegion.selectStatus,
                    });
                    forEach(secondRegion.children, (thirdRegion) => {
                        checkendIds.push({
                            id: thirdRegion.id,
                            selectStatus: thirdRegion.selectStatus,
                        });
                        forEach(thirdRegion.children, (fourRegion) => {
                            checkendIds.push({
                                id: fourRegion.id,
                                selectStatus: fourRegion.selectStatus,
                            });
                        });
                    });
                });
            });
        }
        return checkendIds;
    };

    /**
     * 加载二级
     */
    onLoadParentRegionChildren = () => {
        const { parentRegion } = this.props;
        return (
            parentRegion.hasChild && (
                <Popover
                    placement={'bottom'}
                    overlayStyle={{
                        maxWidth: ' 400px',
                        zIndex: 1000,
                        // maxHeight: '200px',
                        overflowY: 'auto',
                        overflowX: 'auto',
                    }}
                    content={
                        <div>
                            {parentRegion.children.map((secondRegion: Region) => {
                                return (
                                    <div style={{ margin: '5px' }} key={secondRegion.id}>
                                        <Checkbox
                                            disabled={!secondRegion.availableStatus}
                                            checked={
                                                secondRegion.selectStatus === RegionSelectStatus.ALL
                                                    ? true
                                                    : false
                                            }
                                            indeterminate={
                                                secondRegion.selectStatus ===
                                                RegionSelectStatus.PART
                                            }
                                            value={secondRegion.id}
                                            onClick={this.setSelectRegion.bind(
                                                this,
                                                parentRegion,
                                                secondRegion,
                                                null,
                                                null
                                            )}
                                        >
                                            <span>{secondRegion.name}</span>
                                        </Checkbox>
                                        {this.onLoadSecondRegionChildren(
                                            parentRegion,
                                            secondRegion
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    }
                >
                    {parentRegion.availableStatus && (
                        <span
                            onMouseEnter={this.onGetChildrenRegions.bind(
                                this,
                                parentRegion,
                                null,
                                null
                            )}
                        >
                            <Icon type="down" />
                        </span>
                    )}
                </Popover>
            )
        );
    };

    /**
     * 加载三级
     */
    onLoadSecondRegionChildren = (parentRegion: any, secondRegion: any) => {
        return (
            secondRegion.hasChild && (
                <Popover
                    placement={'right'}
                    overlayStyle={{
                        maxWidth: ' 400px',
                        zIndex: 1000,
                        maxHeight: '500px',
                        overflow: 'hidden',
                        overflowY: 'auto',
                        overflowX: 'auto',
                    }}
                    content={
                        <div>
                            {secondRegion.children.map((thirdRegion: Region) => {
                                return (
                                    <div style={{ margin: '5px' }} key={thirdRegion.id}>
                                        <Checkbox
                                            disabled={!thirdRegion.availableStatus}
                                            checked={
                                                thirdRegion.selectStatus === RegionSelectStatus.ALL
                                                    ? true
                                                    : false
                                            }
                                            indeterminate={
                                                thirdRegion.selectStatus === RegionSelectStatus.PART
                                            }
                                            value={thirdRegion.id}
                                            onClick={this.setSelectRegion.bind(
                                                this,
                                                parentRegion,
                                                secondRegion,
                                                thirdRegion,
                                                null
                                            )}
                                        >
                                            <span>{thirdRegion.name}</span>
                                        </Checkbox>
                                        {this.onLoadThirdRegionChildren(
                                            parentRegion,
                                            secondRegion,
                                            thirdRegion
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    }
                >
                    {secondRegion.availableStatus && (
                        <span
                            onMouseEnter={this.onGetChildrenRegions.bind(
                                this,
                                parentRegion,
                                secondRegion,
                                null
                            )}
                        >
                            <Icon type="right" />
                        </span>
                    )}
                </Popover>
            )
        );
    };

    /**
     * 加载四级
     */
    onLoadThirdRegionChildren = (parentRegion: any, secondRegion: any, thirdRegion: any) => {
        return (
            thirdRegion.hasChild && (
                <Popover
                    placement={'right'}
                    overlayStyle={{
                        maxWidth: ' 400px',
                        zIndex: 1000,
                        maxHeight: '500px',
                        overflow: 'hidden',
                        overflowY: 'auto',
                        overflowX: 'auto',
                    }}
                    content={
                        <div>
                            {thirdRegion.children.map((fourRegion: Region) => {
                                return (
                                    <div style={{ margin: '5px' }} key={fourRegion.id}>
                                        <Checkbox
                                            disabled={!fourRegion.availableStatus}
                                            checked={
                                                fourRegion.selectStatus === RegionSelectStatus.ALL
                                                    ? true
                                                    : false
                                            }
                                            indeterminate={
                                                fourRegion.selectStatus === RegionSelectStatus.PART
                                            }
                                            value={fourRegion.id}
                                            onClick={this.setSelectRegion.bind(
                                                this,
                                                parentRegion,
                                                secondRegion,
                                                thirdRegion,
                                                fourRegion
                                            )}
                                        >
                                            {fourRegion.name}
                                        </Checkbox>
                                    </div>
                                );
                            })}
                        </div>
                    }
                >
                    {thirdRegion.availableStatus && (
                        <span
                            onMouseEnter={this.onGetChildrenRegions.bind(
                                this,
                                parentRegion,
                                secondRegion,
                                thirdRegion
                            )}
                        >
                            <Icon type="right" />
                        </span>
                    )}
                </Popover>
            )
        );
    };

    /**
     * 点击任意一级选中按钮处理
     */
    setSelectRegion = (
        parentRegion: any,
        secondRegion?: any,
        thirdRegion?: any,
        fourRegion?: any
    ) => {
        const { onChangeShowRegions, onChangeRegions } = this.props;
        //改变第一级选中状态
        if (parentRegion && !secondRegion && !thirdRegion && !fourRegion) {
            if (parentRegion.readOnly) {
                return;
            }
            const changeCheckStatus =
                parentRegion.selectStatus === RegionSelectStatus.NO
                    ? RegionSelectStatus.ALL
                    : RegionSelectStatus.NO;
            parentRegion.selectStatus = changeCheckStatus;
            parentRegion.children = parentRegion.children.map((i: any) => {
                i.selectStatus = changeCheckStatus;
                i.children = i.children.map((j: any) => {
                    j.selectStatus = changeCheckStatus;
                    j.children = j.children.map((k: any) => {
                        k.selectStatus = changeCheckStatus;
                        return k;
                    });
                    return j;
                });
                return i;
            });
        }
        //改变第二级选中状态
        if (parentRegion && secondRegion && !thirdRegion && !fourRegion) {
            if (secondRegion.readOnly) {
                return;
            }
            const changeCheckStatus =
                secondRegion.selectStatus === RegionSelectStatus.NO
                    ? RegionSelectStatus.ALL
                    : RegionSelectStatus.NO;
            secondRegion.selectStatus = changeCheckStatus;
            secondRegion.children = secondRegion.children.map((i: any) => {
                i.selectStatus = changeCheckStatus;
                i.children = i.children.map((j: any) => {
                    j.selectStatus = changeCheckStatus;
                    return j;
                });
                return i;
            });
            const parentRegionCheckStatus = this.getRegionCheckStatus(parentRegion);
            parentRegion.selectStatus = parentRegionCheckStatus;
        }
        //改变第三级选中状态
        if (parentRegion && secondRegion && thirdRegion && !fourRegion) {
            if (thirdRegion.readOnly) {
                return;
            }
            const changeCheckStatus =
                thirdRegion.selectStatus === RegionSelectStatus.NO
                    ? RegionSelectStatus.ALL
                    : RegionSelectStatus.NO;
            thirdRegion.selectStatus = changeCheckStatus;
            thirdRegion.children = thirdRegion.children.map((i: any) => {
                i.selectStatus = changeCheckStatus;
                return i;
            });
            const secondRegionRegionCheckStatus = this.getRegionCheckStatus(secondRegion);
            secondRegion.selectStatus = secondRegionRegionCheckStatus;
            const parentRegionCheckStatus = this.getRegionCheckStatus(parentRegion);
            parentRegion.selectStatus = parentRegionCheckStatus;
        }
        //改变第四级选中状态
        if (parentRegion && secondRegion && thirdRegion && fourRegion) {
            if (fourRegion.readOnly) {
                return;
            }
            const changeCheckStatus =
                fourRegion.selectStatus === RegionSelectStatus.NO
                    ? RegionSelectStatus.ALL
                    : RegionSelectStatus.NO;
            fourRegion.selectStatus = changeCheckStatus;
            const thirdRegionRegionCheckStatus = this.getRegionCheckStatus(thirdRegion);
            thirdRegion.selectStatus = thirdRegionRegionCheckStatus;
            const secondRegionRegionCheckStatus = this.getRegionCheckStatus(secondRegion);
            secondRegion.selectStatus = secondRegionRegionCheckStatus;
            const parentRegionCheckStatus = this.getRegionCheckStatus(parentRegion);
            parentRegion.selectStatus = parentRegionCheckStatus;
        }
        onChangeRegions(parentRegion);
        onChangeShowRegions(parentRegion);
    };

    /**
     * 计算各级别的选中状态
     * @param region
     */
    getRegionCheckStatus(region: any) {
        let checkouStatus = RegionSelectStatus.NO;
        const checkedNum = region.children.reduce(
            (count: number, item: any) =>
                item.selectStatus === RegionSelectStatus.ALL ? count + 1 : count,
            0
        );
        const nocheckedNum = region.children.reduce(
            (count: number, item: any) =>
                item.selectStatus === RegionSelectStatus.NO ? count + 1 : count,
            0
        );
        if (checkedNum === region.children.length) {
            checkouStatus = RegionSelectStatus.ALL;
        } else if (nocheckedNum === region.children.length) {
            checkouStatus = RegionSelectStatus.NO;
        } else {
            checkouStatus = RegionSelectStatus.PART;
        }
        return checkouStatus;
    }

    render() {
        const { parentRegion } = this.props;
        return (
            <span style={{ display: 'inline-block', margin: '5px', width: '161px' }}>
                <Checkbox
                    disabled={!parentRegion.availableStatus}
                    checked={parentRegion.selectStatus === RegionSelectStatus.ALL ? true : false}
                    indeterminate={parentRegion.selectStatus === RegionSelectStatus.PART}
                    value={parentRegion.id}
                    onClick={this.setSelectRegion.bind(this, parentRegion, null, null, null)}
                >
                    <span>{parentRegion.name}</span>
                </Checkbox>
                {this.onLoadParentRegionChildren()}
            </span>
        );
    }
}
