import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { services } from '@comall-backend-builder/core';
import find from 'lodash/find';
import { message as AntMessage } from 'antd';
import './index.less';

const { privilege, language } = services;
/**
 * 子级菜单页
 */
export class meunList extends PureComponent<any, any> {
    getValidMenus = () => {
        const { items, params, fields } = this.props;
        if (params) {
            let key = params.key;
            let currentMenus = find(items, { key: key });
            const allMenus = fields;
            // 如果菜单页面没有副标题 例如菜单中的营促销-优惠券促销
            if (currentMenus && currentMenus.items) {
                let childrenMenus = currentMenus.items;
                let validMenus: any = [];
                childrenMenus.map((menu: any) => {
                    if (find(allMenus, { name: menu.privilege.path, level: 'full' })) {
                        validMenus.push(menu);
                    }
                    return menu;
                });
                return { type: 'NOSUBTITLE', list: validMenus };
            }
            // 如果菜单页面有副标题 例如菜单中的营促销-营销活动
            if (currentMenus && currentMenus.parentItems) {
                let childrenMenus = currentMenus.parentItems;
                let validParentMenus: any = [];
                childrenMenus.forEach((parentMenu: any) => {
                    const items = parentMenu.items.filter((menu: any) => {
                        return find(allMenus, { name: menu.privilege.path, level: 'full' });
                    });
                    if (items.length) {
                        validParentMenus.push({ subTitle: parentMenu.subTitle, items });
                    }
                });
                return { type: 'SUBTITLE', list: validParentMenus };
            }
            return;
        }
    };

    goTarget = (route: string) => {
        if (route === '/wechat-official-accounts') {
            if (!privilege.check('wechatOfficialAccountManger', 'full')) {
                AntMessage.warning(language.getText('glgzhqx'));
                return;
            }
        }
        services.navigation.goto(route);
    };

    render() {
        let childrenMenus = this.getValidMenus();
        return (
            <>
                {/**页面无副标题 例如菜单中的营促销-优惠券促销*/}
                {childrenMenus && childrenMenus.type === 'NOSUBTITLE' && (
                    <div className="menu-list">
                        {childrenMenus.list &&
                            childrenMenus.list.length > 0 &&
                            childrenMenus.list.map((menu: any) => {
                                return (
                                    <div
                                        key={menu.key}
                                        className="menu-item"
                                        onClick={() => {
                                            this.goTarget(menu.route);
                                        }}
                                    >
                                        <div className={classnames('icon', menu.iconClassName)} />
                                        <div>
                                            <div className="menu-title">{menu.title}</div>{' '}
                                            <div className="menu-description">
                                                {menu.description}
                                            </div>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                )}
                {/**页面有副标题 例如菜单中的营促销-营销活动 */}
                {childrenMenus && childrenMenus.type === 'SUBTITLE' && (
                    <div className="parent-menu-list">
                        {childrenMenus.list &&
                            childrenMenus.list.length > 0 &&
                            childrenMenus.list.map((menu: any) => {
                                return (
                                    <div className="sub-menu-list">
                                        <div className="sub-title">{menu.subTitle}</div>
                                        <div className="menu-list">
                                            {menu.items &&
                                                menu.items.length > 0 &&
                                                menu.items.map((item: any) => {
                                                    return (
                                                        <div
                                                            key={menu.key}
                                                            className="menu-item"
                                                            onClick={() => {
                                                                this.goTarget(item.route);
                                                            }}
                                                        >
                                                            <div
                                                                className={classnames(
                                                                    'icon',
                                                                    item.iconClassName
                                                                )}
                                                            />
                                                            <div>
                                                                <div className="menu-title">
                                                                    {item.title}
                                                                </div>{' '}
                                                                <div className="menu-description">
                                                                    {item.description}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                )}
            </>
        );
    }
}

const mapStateToProps = (_state: any, props: any) => ({
    fields: props.entity.fields,
});

export const MeunList = connect(mapStateToProps)(meunList);
