import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { message as AntMessage } from 'antd';
import { ComponentsManager } from '@comall-backend-builder/core';

/**
 * 门店会员等级列表弹层筛选
 */
class storeCardLevelFilter extends PureComponent<any, any> {
    state = { visible: false };

    componentDidMount() {
        const { entity, params } = this.props;
        const subsiteId = entity.filters && entity.filters.subsiteId;
        const paging = Object.assign({}, entity.paging, { page: 1 });
        if (subsiteId) {
            entity.search({ ...params, ...paging });

            this.setState({ visible: false });
        }
    }

    // toggleModalVisible = () => {
    //     this.setState({ visible: !this.state.visible });
    // };

    renderSelectorList = () => {
        const { entity } = this.props;
        const config = {
            entity,
            direction: 'inline',
            submit: {
                text: '确定',
            },
            onSubmit: this.search,
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'subsiteId',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
            ],
        };

        return React.createElement(ComponentsManager.get('FilterForm'), config);
    };

    search = (e: any) => {
        e.stopPropagation();
        const { entity, params } = this.props;
        const subsiteId = entity.filters && entity.filters.subsiteId;
        const paging = Object.assign({}, entity.paging, { page: 1 });
        if (!subsiteId) {
            AntMessage.error('请选择门店');
            return;
        } else {
            entity.search({ ...params, ...paging });

            this.setState({ visible: false });
        }
    };

    render() {
        return (
            <Fragment>
                {this.renderSelectorList()}
                {/* <AntModal
                    title={'请选择门店'}
                    onOk={this.toggleModalVisible}
                    onCancel={this.toggleModalVisible}
                    footer={[
                        <Button key="submit" type="primary" onClick={this.search}>
                            确定
                        </Button>,
                    ]}
                    visible={this.state.visible}
                    width={600}
                    closable={false}
                    keyboard={false}
                    maskClosable={false}
                >
                    {this.renderSelectorList()}
                </AntModal> */}
            </Fragment>
        );
    }
}
function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
    };
}
export const StoreCardLevelFilter = connect(mapStateToProps)(storeCardLevelFilter);
