import { Config } from '@comall-backend-builder/core/lib/parser';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { api } from '@comall-backend-builder/core/lib/services';
import { map } from 'lodash';
import { errorHandle } from '../../applications/cae/services/error-handle';
import { ActivityTagType } from '../cms/mall-activity-tag';

const Illustrates: { [str: string]: any } = {
    ONLY_NEW: 'option2的说明',
    NOT_LIMITED: 'option1的说明',
};

export const config: Config = {
    entities: {
        ComponentsDemoEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/components-demo',
            filters: {
                subsiteId: {
                    type: 'string.options.select',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 120 } },
                },
                merchantId: {
                    type: 'string.filterOptions.select',
                    displayName: '<<merchant>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    },
                    controlConfig: {
                        placeholder: '根据选择的门店动态加载专柜选项',
                        style: { width: 250 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                stringPopover: {
                    type: 'string.popover',
                    displayName: '文字气泡',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '200px',
                        },
                    },
                },
                storeStatus: {
                    type: 'number.store.status',
                    displayName: '门店营业状态',
                },
                lotteryCodeImportExport: {
                    type: 'string.lotteryCodeImportExport',
                    displayName: '用户范围/黑名单',
                    controlConfig: {
                        importExportType: 'WHITE_LIST',
                    },
                },
                statusBadge: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                valueRange: {
                    displayName: '区间',
                    type: 'string.valueRange',
                },
                link: {
                    type: 'object.link',
                    displayName: '链接',
                },
                arrayPopover: {
                    type: 'array.popover',
                    displayName: '数组气泡',
                },
                refuseText: {
                    type: 'array.refuse.text',
                    displayName: '拒审原因',
                },
                number: {
                    type: 'object.subForm',
                    displayName: '数字',
                    properties: {
                        integerInputTitleTip: {
                            type: 'number.integerInputTitleTip',
                            displayName: '前后文本正整数输入框',
                            rules: [{ required: true }],
                            displayConfig: {
                                addonbefore: 'addonbefore',
                                addonafter: 'addonafter',
                            },
                            controlConfig: {
                                addonbefore: 'addonbefore',
                                addonafter: 'addonafter',
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                            },
                        },
                        tipGroupBuying: {
                            type: 'number.tip.groupBuying',
                            displayName: '前后文本+tip数字输入框',
                            name: JSON.stringify({
                                fill: 'addonbefore#value#addonafter',
                                empty: '未设置****',
                            }),
                            rules: [
                                {
                                    required: true,
                                },
                                {
                                    validator(
                                        rule: any,
                                        value: number,
                                        callback: (message?: string) => void
                                    ) {
                                        if (!value) {
                                            callback();
                                        } else {
                                            const v = value + '';

                                            if (!/^\+?[1-9]\d*$/.test(v) && v !== '0') {
                                                callback(services.language.getText('qsrdydy'));
                                            } else if (value > 9999) {
                                                callback('最大支持设置9999');
                                            } else {
                                                callback();
                                            }
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: 150 },
                                min: 0,
                                max: 9999,
                                placeholder: '<<inputPlease>>',
                                addonbefore: 'addonbefore',
                                addonafter: 'addonafter',
                                tip: 'tip',
                            },
                        },
                        tip: {
                            type: 'number.tip',
                            displayName: '前后文本+tip+extra数字输入框',
                            rules: [
                                {
                                    message: '仅支持输入正整数，最大可设置366',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && (!reg.test(value) || value > 366)) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<inputPlease>>',
                                style: {
                                    width: 125,
                                },
                                addonbefore: 'addonbefore',
                                addonafter: 'addonafter',
                                tip: 'tip',
                            },
                            extra: 'extra',
                        },
                        presaleTitleTip: {
                            type: 'number.presaleTitleTip',
                            displayName: '预售-组合数字输入框',
                            displayConfig: {
                                eachPresaleConfig: {
                                    addonbefore: 'addonbefore',
                                    addonafter: 'addonafter',
                                },
                                eachOrderConfig: {
                                    addonbefore: 'addonbefore',
                                    addonafter: 'addonafter',
                                },
                            },
                            controlConfig: {
                                configInfo: [
                                    {
                                        addonbefore: 'addonbefore',
                                        addonafter: 'addonafter',
                                        type: 'EACH_PRESALE',
                                        rules: [
                                            {
                                                type: 'number',
                                            },
                                        ],
                                    },
                                    {
                                        addonbefore: 'addonbefore',
                                        addonafter: 'addonafter',
                                        type: 'EACH_ORDER',
                                        rules: [
                                            {
                                                type: 'number',
                                            },
                                        ],
                                    },
                                ],
                            },
                        },
                    },
                },
                string: {
                    type: 'object.subForm',
                    displayName: '字符',
                    collapsed: true,
                    properties: {
                        entityButton: {
                            type: 'string.entityButton',
                            displayName: '自定义按钮',
                            controlConfig: {
                                props: {
                                    type: 'primary',
                                    children: '查看装修样例',
                                    onClick() {
                                        api.download(
                                            {},
                                            {
                                                apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                                apiPath:
                                                    '/admin/wheel_draw_activities/example_files',
                                                customHeaders: {
                                                    'content-disposition':
                                                        'attachment;filename="filename.zip"',
                                                },
                                            }
                                        ).catch(errorHandle);
                                    },
                                },
                            },
                        },
                        maxLength: {
                            displayName: '<<xzzfzdwbsrk>>',
                            type: 'string.maxLength',
                            rules: [
                                { required: true },
                                {
                                    message: '<<xxEmpty>>',
                                    validator: (rule: any, value: any) => {
                                        if (value && !value.trim()) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                maxLength: 6,
                            },
                        },
                        tip: {
                            type: 'string.tip',
                            displayName: 'tip文本输入框',
                            rules: [
                                {
                                    required: false,
                                    whitespace: true,
                                    message: '4～30个字长',
                                },
                            ],
                            controlConfig: {
                                style: { width: '300px' },
                                tip: 'tip',
                                placeholder: '4～30个字长',
                                minLength: 4,
                                maxLength: 30,
                            },
                        },
                        optionsRadioPlus: {
                            type: 'string.options.radio.plus',
                            displayName: '单选升级版(纵向)',
                            defaultValue: 'TEMP1',
                            controlConfig: {
                                setOptions: [
                                    {
                                        value: 'TEMP1',
                                        name: '文字1',
                                    },
                                    {
                                        value: 'TEMP2',
                                        name: '文字2',
                                        tip: {
                                            text: '提示：请点击',
                                            hrefText: {
                                                href: '/merchant-delivery',
                                                text: '跳转链接',
                                            },
                                        },
                                    },
                                    {
                                        value: '文字',
                                        name: '文字3',
                                        tip: {
                                            text: '提示：快去',
                                            hrefText: {
                                                href: '/merchant-delivery',
                                                text: '点点吧',
                                            },
                                        },
                                    },
                                ],
                            },
                            rules: [{ required: true }],
                        },
                        radioIllustrate: {
                            type: 'string.radioIllustrate',
                            displayName: '<<dgsmdxk>>',
                            defaultValue: 'NOT_LIMITED',
                            name: JSON.stringify(Illustrates),
                            options: [
                                { id: 'NOT_LIMITED', name: 'option1' },
                                { id: 'ONLY_NEW', name: 'option2' },
                            ],
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                illustrate: Illustrates,
                                style: { display: 'flex', paddingTop: 9 },
                            },
                        },
                        imageRadio: {
                            type: 'string.imageRadio',
                            displayName: '图片单选',
                            defaultValue: 'TEMP1',
                            options: [
                                { id: 'TEMP1', name: '图片一' },
                                { id: 'TEMP2', name: '图片二' },
                                { id: 'TEMP3', name: '图片三' },
                            ],
                            controlConfig: {
                                images: {
                                    TEMP1: '../../images/editor/print-template-one.png',
                                    TEMP2: '../../images/editor/print-template-two.jpg',
                                    TEMP3: '../../images/editor/print-template-three.jpg',
                                },
                                style: { display: 'flex', paddingTop: 9 },
                                tips: ['<<templateIntro1>>', '<<templateIntro2>>'],
                            },
                        },
                        stringDesc: {
                            type: 'string.stringDesc',
                            displayName: '文本描述',
                            controlConfig: {
                                value: '描述——不可编辑',
                                style: {
                                    color: '#AAAAAA',
                                },
                            },
                        },
                        color: {
                            type: 'string.color',
                            displayName: '色值选择器',
                        },
                        pictureExampleButton: {
                            displayName: '查看示例图及下载',
                            type: 'string.pictureExampleButton',
                            controlConfig: {
                                type: 'cardCollection',
                                showDownload: true,
                                downloadApiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                downloadApiPath: '/admin/card_collections/example_files',
                            },
                        },
                        selectPlus: {
                            type: 'string.options.selectPlus',
                            displayName: '<<yhqgq>>',
                            options: [
                                { id: 'value1', name: 'value1' },
                                { id: 'value2', name: 'value2' },
                                { id: 'value3', name: 'value3' },
                            ],
                            controlConfig: {
                                addonbefore: 'addonbefore',
                                addonafter: 'addonafter',
                                allowClear: true,
                            },
                            extra: 'extra',
                        },
                        phoneInput: {
                            type: 'string.phone.input',
                            displayName: '电话',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '请输入电话',
                                },
                            ],
                        },
                    },
                },
                object: {
                    type: 'object.subForm',
                    displayName: '对象',
                    collapsed: true,
                    properties: {
                        activityRewardInvited: {
                            type: 'object.activityRewardInvited',
                            displayName: '邀请有礼-被邀请人奖励',
                            options: [],
                            source: {
                                apiPath: '/loader/admin/activityRewardItems',
                                paramsFilter: ['INVITE'],
                            },
                        },
                        birthdayMemberLevelCouponRuleMode: {
                            type: 'object.birthdayMemberLevelCouponRuleMode',
                            displayName: '生日营销-会员等级规则',
                        },
                        memberLevelRule: {
                            type: 'object.memberLevelRule',
                            displayName: '节日营销-会员等级规则',
                            controlConfig: {
                                rewardType: 'POINT',
                            },
                        },
                        pointExchangeRule: {
                            type: 'object.PointExchangeRule',
                            displayName: '积分抵扣规则',
                            rules: [{ required: true }],
                            extra: 'extra',
                        },
                        citywideDeliveryFreightLadder: {
                            type: 'object.citywideDeliveryFreightLadder',
                            displayName: '同城配-配送重量每增加',
                            extra:
                                '运费按增加计算：实际配送重量大于起送重量，不满增加重量，运费按增加计算，依次类推',
                            controlConfig: {
                                ladderValueAddbefore: '',
                                ladderValueAddAfter: 'kg，',
                                ladderAmountAddbefore: '费用增加',
                                ladderAmountAddAfter: '<<yuan>>',
                            },
                        },
                        complainDetail: {
                            type: 'object.content.complainDetail',
                            displayName: '举报内容',
                        },
                        dateTimeRangeTip: {
                            type: 'object.dateTimeRange.tip',
                            displayName: '时间范围+tip',
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                                tip: 'tip',
                            },
                            rules: [{ required: true, message: '<<qxzsj>>' }],
                        },
                        linkTypeEffectPath: {
                            type: 'object.linkTypeEffectPath',
                            displayName: '页面选择器',
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        linkTypePopUpContent: {
                            type: 'object.linkTypePopUpContent',
                            displayName: '链接配置',
                            defaultValue: { linkType: 'none', linkParams: {} },
                        },
                        linkType: {
                            type: 'object.linkType',
                            displayName: '链接配置',
                            defaultValue: { linkType: 'none', linkParams: {} },
                        },
                        memberLevelUpRule: {
                            displayName: '会员成长体系-获得条件',
                            type: 'object.memberLevelUpRule',
                            rules: [{ required: true }],
                            defaultValue: {
                                accessConditionType: 'ExperienceValue',
                                level: 0,
                                expLower: 0,
                                expUpper: 0,
                                totalNote: 0,
                                videoNote: 0,
                                topicNote: 0,
                                totalLiked: 0,
                                relation: 'OR',
                                totalCollect: 0,
                            },
                        },
                        memberPointDoubleRule: {
                            type: 'object.MemberPointDoubleRule',
                            displayName: '积分权益-会员等级',
                            options: [],
                            source: {
                                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                                apiPath: '/loader/admin/cardLevelsPointBenefit',
                                // dependences: ['ruleInfo.subsites'],
                            },
                            rules: [{ required: true }],
                            controlConfig: {
                                rewardText: '倍Hi贝',
                            },
                        },
                        monthDayRange: {
                            type: 'object.monthDayRange',
                            displayName: '日期范围-月日',
                        },
                        participateCondition: {
                            type: 'object.participateCondition',
                            displayName: '预约抢购-预约条件',
                            controlConfig: {
                                codes: [
                                    {
                                        code: 'MINREGISTERDAYS',
                                        tip: '<<hdtksjs>>',
                                    },
                                    {
                                        code: 'CONSUMPTIONT',
                                        tip: '<<zcyhbxmyxfcsdd>>',
                                    },
                                    {
                                        code: 'CERTIFICATION',
                                    },
                                ],
                            },
                        },
                        pointDeduction: {
                            type: 'object.pointDeduction',
                            displayName: '停车权益-积分抵扣规则',
                        },
                        pointRewardContent: {
                            type: 'object.PointRewardContent',
                            displayName: '积分权益-优惠内容',
                            rules: [{ required: true }],
                            controlConfig: {
                                rewardText: 'Hi贝',
                            },
                        },
                        registrationPrice: {
                            type: 'object.registrationPrice',
                            displayName: '活动配置-报名价格',
                            defaultValue: { type: 'NONE', price: '', integral: '' },
                            rules: [{ required: true }],
                        },
                        rewardRules: {
                            type: 'object.rewardRules',
                            displayName: '互动激励-奖励规则',
                            options: [],
                            controlConfig: {
                                reqConfig: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/activities/reward_items',
                                },
                                reqParams: { eventType: 'ONLINE' },
                                rewardStartText: '连续登陆',
                                rewardUnit: '天',
                                baseConfig: {
                                    isConditionsInput: true,
                                    isItmesRadio: false,
                                    isLadder: true,
                                },
                            },
                        },
                        schedulerMethods: {
                            type: 'object.schedulerMethods',
                            displayName: '定向发券-执行方式',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },
                        selectTimepicker: {
                            name: 'selectTimepicker',
                            type: 'object.selectTimepicker',
                            displayName: '预售活动-抽签时间',
                            options: [{ id: 'EACH_DAY', name: '每天' }],
                            rules: [
                                {
                                    message: '抽签时间不能为空',
                                    validator: (rule: any, value: any) => {
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                defaultTypeValue: 'EACH_DAY',
                                defaultDrawTime: '00:00',
                                typeOptions: [{ id: 'EACH_DAY', name: '每天' }],
                                tip: '开始抽签',
                            },
                        },
                        servicePrice: {
                            type: 'object.servicePrice',
                            displayName: '服务配置-押金',
                            defaultValue: { type: 'NONE', price: '' },
                            rules: [{ required: true }],
                        },
                        winningLotsNumber: {
                            type: 'object.winningLotsNumber',
                            format: 'HH:mm',
                            displayName: '预售活动-中签人数',
                            controlConfig: {
                                defaultTypeValue: 'EACH_DAY',
                                addonbefore: '最多中签',
                                addonafter: '人',
                                tip: '配置后，在活动期间内每天会有多少用户中签',
                                rules: [
                                    {
                                        type: 'number',
                                        min: 1,
                                    },
                                ],
                            },
                            options: [{ id: 'EACH_DAY', name: '每天' }],
                        },
                        TopicSelector: {
                            type: 'object.TopicSelector',
                            displayName: '选择专题',
                            rules: [{ required: true, message: '请选择分享专题' }],
                        },
                        sharePageActivityReward: {
                            type: 'array.sharePageActivityReward',
                            displayName: '分享有礼-选择奖励行为',
                            rules: [{ required: true, message: '<<qszjlxw>>' }],
                        },
                        activityRewardCheckin: {
                            type: 'object.activityRewardCheckin',
                            displayName: '签到有礼-额外奖励',
                        },
                        dayPeriodCondition: {
                            type: 'object.dayPeriodCondition',
                            displayName: '月周期范围',
                        },
                        subsiteMerchantDelivery: {
                            type: 'subsite.merchant.delivery',
                            displayName: '门店或者专柜的配送方式-多选',
                        },
                    },
                },
                array: {
                    type: 'object.subForm',
                    displayName: '数组',
                    collapsed: true,
                    properties: {
                        groupBuyingCheckbook: {
                            type: 'array.groupBuyingCheckbook',
                            displayName: '拼团活动-复选框',
                            name: '在商品详情页展示未拼成的团，用户点击可参与拼团',
                            defaultValue: ['1'],
                            options: [
                                { id: '1', name: '在商品详情页展示未拼成的团，用户点击可参与拼团' },
                            ],
                        },
                        activityReward: {
                            type: 'array.activityReward',
                            displayName: '签到有礼-奖励阶梯',
                            options: [],
                            source: {
                                apiPath: '/loader/admin/activityRewardItems',
                                paramsFilter: ['TOPIC_ACTIVITY'],
                            },
                            rules: [{ required: true }],

                            controlConfig: {
                                isRange: true,
                                maxCount: 3,
                                rewardType: 'multi',
                                stepValueTextConfig: {
                                    start: 'TOP第',
                                    end: '获得以下奖励',
                                    unit: '名',
                                },
                                rewardTextConfig: {
                                    start: ' ',
                                },
                            },
                        },
                        cardCollectionItemConfig: {
                            type: 'array.cardCollectionItemConfig',
                            displayName: '集卡活动-卡片设置',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },
                        cardCollectionReward: {
                            type: 'array.cardCollectionReward',
                            displayName: '集卡活动-奖品设置',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                maxCount: 10,
                            },
                        },
                        cardCollectionRuleConfig: {
                            type: 'array.cardCollectionRuleConfig',
                            displayName: '集卡活动-集卡规则',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                title: '每日参与活动领取',
                                hideCount1: true,
                                type: 'FIRST_ACCESS',
                            },
                        },
                        cardLevelRights: {
                            type: 'array.cardLevelRights',
                            displayName: '会员权益-权益项',
                        },
                        CGBProducts: {
                            type: 'array.CGBProducts',
                            displayName: '团购管理-选择商品',
                        },
                        checkboxPlus: {
                            type: 'array.checkboxPlus',
                            displayName: '复选框Plus',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                reqParams: { eventType: 'LICKED' },
                                reqConfig: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/activities/note_creation_types',
                                },
                            },
                        },
                        ContentTopicSelector: {
                            type: 'array.ContentTopicSelector',
                            displayName: '内容-选择话题',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },
                        couponSelectorWithPackage: {
                            type: 'array.couponSelectorWithPackage',
                            controlConfig: {
                                disablePrice: true,
                                type: 'checkbox',
                                packageSelectType: 'radio',
                                canChangeQuantity: true,
                                getSubsiteIds(props: any) {
                                    const { row } = props;
                                    const subsiteIds =
                                        row.baseInfo && row.baseInfo.subsiteIds
                                            ? map(row.baseInfo.subsiteIds, 'id')
                                            : null;
                                    return subsiteIds;
                                },
                            },
                            displayName: '选择优惠券',
                            rules: [{ required: true, type: 'array' }],
                        },
                        couponSelector: {
                            type: 'array.coupon.selector',
                            displayName: '积分商场-选择兑换对象',
                            rules: [{ type: 'array' }],
                            controlConfig: {
                                getParams(props: any) {
                                    const { row } = props;
                                    const subsiteIds =
                                        row.subsiteId && row.subsiteId
                                            ? map(row.subsiteId, 'id').join(',')
                                            : null;
                                    return { subsiteIds };
                                },
                            },
                        },
                        CreditShopMemberLimit: {
                            type: 'array.CreditShopMemberLimit',
                            displayName: '会员卡限制规则',
                            defaultValue: [],
                            options: [],
                            source: {
                                apiPath: '/loader/admin/creditShop/userCardLevels',
                                // dependences: ['subsiteId'],
                            },
                        },
                        customForm: {
                            type: 'array.customForm',
                            displayName: '商场活动-自定义报名信息',
                            defaultValue: [],
                        },
                        DistributionProducts: {
                            type: 'array.DistributionProducts',
                            displayName: '<<activityProduct>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<components.Tools.productSelectRequired>>',
                                },
                            ],
                            controlConfig: {
                                getParams(props: any) {
                                    const { row } = props;
                                    const subsiteIds = row.selectedSubsites
                                        ? map(row.selectedSubsites, 'id')
                                        : null;
                                    return { subsiteIds };
                                },
                                memberIdentity: 'DISTRIBUTOR',
                            },
                        },
                        eventMarcketReward: {
                            displayName: '事件营销-奖品设置',
                            rules: [{ required: true, message: '<<sequenceRequired>>' }],
                            type: 'array.eventMarcketReward',
                        },
                        FullReductionLadder: {
                            type: 'array.FullReductionLadder',
                            displayName: '运费优惠-门槛设置',
                            rules: [{ required: true }],
                        },
                        geofences: {
                            type: 'array.geofences',
                            displayName: '<<psfw>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },
                        groupBuyingGoods: {
                            type: 'array.groupBuyingGoods',
                            displayName: '拼团商品-选择货品',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },
                        groupBuyingProducts: {
                            type: 'array.groupBuyingProducts',
                            displayName: '拼团商品-选择商品',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },
                        KeywordsProducts: {
                            type: 'array.KeywordsProducts',
                            displayName: '搜索置顶-选择商品',
                            rules: [
                                {
                                    required: true,
                                    message: '<<components.Tools.productSelectRequired>>',
                                },
                            ],
                            controlConfig: {
                                getParams(props: any) {
                                    const { row } = props;
                                    const subsiteIds = row.selectedSubsites
                                        ? map(row.selectedSubsites, 'id')
                                        : null;
                                    return { subsiteIds };
                                },
                                memberIdentity: 'DISTRIBUTOR',
                            },
                        },
                        mailboxSuffix: {
                            type: 'array.mailboxSuffix',
                            displayName: '企业内购-邮箱后缀',
                            rules: [{ required: true }],
                            extra: '用于企业用户接收验证码，例：@co-mall.com',
                        },
                        activityTagSelector: {
                            displayName: '活动标签',
                            type: 'array.activityTag.selector',
                            controlConfig: {
                                getParams(props: any) {
                                    const { row } = props;
                                    const subsiteIds =
                                        row.merchants && row.merchants.length
                                            ? map(row.merchants, 'subSiteId').join(',')
                                            : undefined;
                                    return {
                                        type: ActivityTagType.MALL_ACTIVITY,
                                        subsiteIds,
                                    };
                                },
                            },
                        },
                        MemberLevelParkBenefit: {
                            type: 'array.MemberLevelParkBenefit',
                            displayName: '停车权益-当日消费金额抵扣规则',
                            options: [],
                            source: {
                                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                                apiPath: '/loader/admin/storeCardLevelsBenefit',
                                // dependences: ['subsiteId'],
                            },
                        },
                        MemberLevelParkUnbind: {
                            type: 'array.MemberLevelParkUnbind',
                            displayName: '停车权益-解绑规则',
                            options: [],
                            source: {
                                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                                apiPath: '/loader/admin/storeCardLevelsBenefit',
                                // dependences: ['subsiteId'],
                            },
                        },
                        MemberLevelRules: {
                            type: 'array.MemberLevelRules',
                            displayName: '预售活动-按会员等级限购',
                            options: [],
                            source: {
                                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                                apiPath: '/admin/members_levels',
                            },
                        },
                        memberSelector: {
                            type: 'object.member.selector',
                            displayName: '消息群发-群发对象',
                            rules: [{ required: true }],
                        },
                        optionIdsCheckboxTip: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '复选框&tip',
                            defaultValue: ['CONTINUOUS', 'CYCLIC'],
                            options: [
                                {
                                    id: 'CONTINUOUS',
                                    name:
                                        '用户断登后重新计算连续登陆天数，并重新从最低档开始重新发放奖励',
                                },
                                {
                                    id: 'CYCLIC',
                                    name: '用户连续登陆获得最高阶梯奖励后，重新计算并循环发放奖励',
                                },
                            ],
                        },
                        PresaleProducts: {
                            type: 'array.PresaleProducts',
                            displayName: '预售-选择商品',
                            controlConfig: {
                                showPresalePrice: true,
                                showQuantityLimit: true,
                            },
                        },
                        productCategorySelector: {
                            type: 'object.productCategory.selector',
                            displayName: '选择商品&分类',
                        },
                        productStyleSelector: {
                            type: 'array.productStyle.selector',
                            displayName: '选择商品&款式',
                            extra:
                                '提醒：剩余库存为用户可以购买的商品数量；如修改共享库存数，则剩余库存数=编辑后共享库存数 - 原共享库存数；',
                        },

                        registrationTimePicker: {
                            type: 'array.registrationTimePicker',
                            displayName: '商场活动配置-活动场次',
                            className: 'presale-range-timepicker',
                            rules: [
                                {
                                    message: '活动场次的每个时间段不可重复',
                                    validator: (rule: any, value: any) => {
                                        const completeStartWithEnd = value
                                            .filter(
                                                (item: any) =>
                                                    item.startDate && item.startTime && item.endTime
                                            )
                                            .map((item: any) => ({
                                                start: `${item.startDate} ${item.startTime}`,
                                                end: `${item.startDate} ${item.endTime}`,
                                            }))
                                            .sort((a: any, b: any) => (a.start > b.start ? 1 : -1));

                                        return completeStartWithEnd.every(
                                            (item: any, index: number) => {
                                                if (index === 0) {
                                                    return true;
                                                }

                                                const prev = completeStartWithEnd[index - 1];

                                                return item.start >= prev.end;
                                            }
                                        );
                                    },
                                },
                            ],
                        },
                        rewardSetting: {
                            type: 'array.rewardSetting',
                            displayName: '<<jpsz>>',
                            extra: '添加的奖品项，可拖动调整顺序',
                            controlConfig: {
                                eventType: 'SCRATCH_CARD',
                            },
                        },
                        SaleProducts: {
                            type: 'array.SaleProducts',
                            displayName: '秒杀活动-活动商品',
                            controlConfig: {
                                getParams(props: any) {
                                    const { row } = props;
                                    const subsiteIds =
                                        row.ruleConfig && row.ruleConfig.subsites
                                            ? map(row.ruleConfig.subsites, 'id')
                                            : null;
                                    return { subsiteIds };
                                },
                            },
                        },
                        salesPlansProducts: {
                            type: 'array.salesPlansProducts',
                            displayName: '销售计划-活动商品',
                            controlConfig: {
                                showQuantityLimit: true,
                            },
                        },
                        SelfDeliveryProducts: {
                            type: 'array.SelfDeliveryProducts',
                            displayName: '门店自提-选择商品',
                        },
                        shopSelector: {
                            type: 'array.shop.selector',
                            displayName: '<<selectSubsites>>',
                            defaultValue: [],
                        },
                        subsitesSelector: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            rules: [{ required: true, type: 'array' }],
                        },
                        tagsSelector: {
                            type: 'array.tags.selector',
                            displayName: '<<xzbq>>',
                            defaultValue: [],
                        },
                        presaleTimepicker: {
                            type: 'array.presaleTimepicker',
                            displayName: '预售-预约时间',
                            className: 'presale-range-timepicker',
                            rules: [
                                {
                                    required: true,
                                    message: '<<qtxyysj>>',
                                },
                            ],
                        },
                        UserRules: {
                            type: 'array.UserRules',
                            displayName: '<<limitSet>>',
                            options: [
                                { id: 'EACH_ORDER', name: '<<md>>' },
                                { id: 'EACH_DAY', name: '<<everyDay>>' },
                                { id: 'EACH_WEEK', name: '<<everyWeek>>' },
                                { id: 'EACH_MONTH', name: '<<everyMonth>>' },
                                { id: 'EACH_YAER', name: '<<everyYear>>' },
                            ],
                        },
                        virtualProductSelector: {
                            type: 'array.virtualProductSelector',
                            displayName: '选择虚拟商品',
                            rules: [{ required: true, type: 'array' }],
                            controlConfig: {
                                getParams(props: any) {
                                    const { row } = props;
                                    const subsiteIds =
                                        row.subsiteId && row.subsiteId
                                            ? map(row.subsiteId, 'id').join(',')
                                            : null;
                                    return { subsiteIds };
                                },
                            },
                        },
                        paymentRewards: {
                            type: 'array.paymentRewards',
                            displayName: '支付有礼-奖品设置',
                        },
                        selectMemberCardWithDiscount: {
                            type: 'array.selectMemberCardWithDiscount',
                            displayName: '会员卡等级折扣设置',
                            rules: [{ required: true }],
                        },
                        productCategories: {
                            type: 'array.product.categories',
                            displayName: '选择商品分类-可选择任意一级',
                            rules: [{ required: true }],
                        },
                        productBrands: {
                            type: 'array.product.brands.selector',
                            displayName: '选择商品品牌',
                            rules: [{ required: true }],
                        },
                        productGoods: {
                            type: 'array.product.goods.selector',
                            displayName: '货品弹层，确认货品组件',
                            rules: [{ required: true }],
                            controlConfig: {
                                selectGoodsTypes: ['NORMAL'],
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        ComponentsDemoView: {
            component: 'Viewport',
            className: 'table-content-viewport',
            entity: 'ComponentsDemoEntity',
        },
        ComponentsDemoPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ComponentsDemoFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '新增',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/components-demo/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'ComponentsDemoTable' },
            ],
        },
        ComponentsDemoFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
            ],
        },
        ComponentsDemoTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            scroll: {
                x: 1000,
            },
            columns: [
                {
                    property: 'stringPopover',
                    width: 150,
                },
                {
                    property: 'storeStatus',
                    width: 120,
                },
                {
                    property: 'lotteryCodeImportExport',
                    width: 180,
                },
                {
                    property: 'statusBadge',
                    width: 120,
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
                {
                    property: 'valueRange',
                    width: 150,
                },
                {
                    property: 'arrayPopover',
                    width: 150,
                },
                {
                    property: 'link',
                    width: 150,
                    displayConfig: {
                        target: '_blank',
                    },
                },
                { property: 'refuseText' },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 180,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'enabled',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                    apiPath: '/admin/product_search_keywords/:id/disabled',
                                    config: {
                                        content: '{{row.keyword}}已被禁用',
                                        text: '<<disabled>>',
                                    },
                                    confirm: {
                                        text: '是否禁用关键词{{row.keyword}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                    apiPath: '/admin/product_search_keywords/:id/enabled',
                                    config: {
                                        content: '{{row.keyword}}已被开启',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>关键词{{row.keyword}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'delete',
                        text: '<<common.delete>>',
                        statusKey: 'row.canDelete',
                        config: {
                            type: 'link',
                            tooltip: null,
                            title: '<<confirmDelete>>',
                            okText: '<<common.ok>>',
                            cancelText: '<<common.cancel>>',
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/components-demo/edit/{{row.id}}',
                    },
                ],
            },
        },
        ComponentsDemoAddPage: {
            component: 'FlexLayout',
            entity: 'ComponentsDemoEntity',
            direction: 'horizontal',
            items: [{ component: 'ComponentsDemoAddFormView' }],
        },
        ComponentsDemoAddFormView: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 1000 },
            fields: [
                { property: 'number.integerInputTitleTip' },
                { property: 'number.tipGroupBuying' },
                { property: 'number.tip' },
                { property: 'number.presaleTitleTip' },
                { property: 'string.entityButton' },
                { property: 'string.maxLength' },
                { property: 'string.tip' },
                { property: 'string.optionsRadioPlus' },
                { property: 'string.radioIllustrate' },
                { property: 'string.imageRadio' },
                { property: 'string.stringDesc' },
                { property: 'string.color' },
                { property: 'string.pictureExampleButton' },
                { property: 'string.selectPlus' },
                { property: 'string.phoneInput' },
                { property: 'object.activityRewardInvited' },
                { property: 'object.birthdayMemberLevelCouponRuleMode' },
                { property: 'object.memberLevelRule' },
                { property: 'object.pointExchangeRule' },
                { property: 'object.citywideDeliveryFreightLadder' },
                // 只有展示组件，新增页面不要
                // { property: 'object.complainDetail' },
                { property: 'object.dateTimeRangeTip' },
                { property: 'object.linkTypeEffectPath' },
                { property: 'object.linkTypePopUpContent' },
                { property: 'object.linkType' },
                { property: 'object.memberLevelUpRule' },
                { property: 'object.memberPointDoubleRule' },
                { property: 'object.monthDayRange' },
                { property: 'object.participateCondition' },
                { property: 'object.pointDeduction' },
                { property: 'object.pointRewardContent' },
                // defaultValue无效，页面报错，原因未知
                // { property: 'object.registrationPrice' },
                { property: 'object.rewardRules' },
                { property: 'object.schedulerMethods' },
                { property: 'object.selectTimepicker' },
                { property: 'object.servicePrice' },
                { property: 'object.winningLotsNumber' },
                { property: 'object.TopicSelector' },
                { property: 'object.sharePageActivityReward' },
                { property: 'object.activityRewardCheckin' },
                { property: 'object.dayPeriodCondition' },
                { property: 'object.subsiteMerchantDelivery' },
                { property: 'array.groupBuyingCheckbook' },
                { property: 'array.activityReward' },
                { property: 'array.cardCollectionItemConfig' },
                { property: 'array.cardCollectionReward' },
                { property: 'array.cardCollectionRuleConfig' },
                { property: 'array.cardLevelRights' },
                { property: 'array.CGBProducts' },
                { property: 'array.checkboxPlus' },
                { property: 'array.ContentTopicSelector' },
                { property: 'array.couponSelectorWithPackage' },
                { property: 'array.couponSelector' },
                { property: 'array.CreditShopMemberLimit' },
                { property: 'array.customForm' },
                { property: 'array.DistributionProducts' },
                { property: 'array.eventMarcketReward' },
                { property: 'array.FullReductionLadder' },
                { property: 'array.geofences' },
                { property: 'array.groupBuyingGoods' },
                { property: 'array.groupBuyingProducts' },
                { property: 'array.KeywordsProducts' },
                { property: 'array.mailboxSuffix' },
                { property: 'array.activityTagSelector' },
                { property: 'array.MemberLevelParkBenefit' },
                { property: 'array.MemberLevelParkUnbind' },
                { property: 'array.MemberLevelRules' },
                { property: 'array.memberSelector' },
                { property: 'array.optionIdsCheckboxTip' },
                { property: 'array.PresaleProducts' },
                { property: 'array.productCategorySelector' },
                { property: 'array.productStyleSelector' },
                { property: 'array.registrationTimePicker' },
                { property: 'array.rewardSetting' },
                { property: 'array.SaleProducts' },
                { property: 'array.salesPlansProducts' },
                { property: 'array.SelfDeliveryProducts' },
                { property: 'array.shopSelector' },
                { property: 'array.subsitesSelector' },
                { property: 'array.tagsSelector' },
                { property: 'array.presaleTimepicker' },
                { property: 'array.UserRules' },
                { property: 'array.virtualProductSelector' },
                { property: 'array.paymentRewards' },
                { property: 'array.selectMemberCardWithDiscount' },
                { property: 'array.productCategories' },
                { property: 'array.productBrands' },
                { property: 'array.productGoods' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                message.success('新增成功', () => services.behaviorHandle({ route: 'goBack' }));
            },
        },
        ComponentsDemoEditPage: {
            component: 'FlexLayout',
            entity: 'ComponentsDemoEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'ComponentsDemoEditFormView' }],
        },
        ComponentsDemoEditFormView: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            loaderType: 'get',
            labelCol: 6,
            controlCol: 15,
            style: { width: 800 },
            fields: [{ property: 'number.integerInputTitleTip' }],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/components-demo',
                    component: 'ComponentsDemoView',
                    breadcrumbName: '组件Demo',
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'ComponentsDemoAddPage',
                            breadcrumbName: '新增页面',
                        },
                        {
                            path: '/edit/:id',
                            component: 'ComponentsDemoEditPage',
                            breadcrumbName: '编辑页面',
                        },
                        { path: '/', component: 'ComponentsDemoPage' },
                    ],
                },
            ],
        },
    ],
};
