import React, { Component } from 'react';
import { Tree } from 'antd';
import { services } from '@comall-backend-builder/core';
import { isArray } from 'lodash';
import './index.less';

const api = services.api;

export interface MerchantManagerCategorySelectorState {
    categoriesData: Array<any>;
    expandedKeys?: Array<string>;
    allKeys?: Array<string>;
}

export class MerchantManagerCategorySelector extends Component<
    any,
    MerchantManagerCategorySelectorState
> {
    constructor(props: any) {
        super(props);

        this.state = {
            categoriesData: [],
            expandedKeys: [],
            allKeys: [],
        };
    }
    componentDidMount() {
        this.loadCategories();
    }

    loadCategories = () => {
        //获取分类树
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: `/admin/category_tax_rates`,
            }
        ).then((response: any) => {
            let categoriesData = [response];
            const keys: string[] = [];

            categoriesData.forEach((item, i) => {
                this.format(item, [i], keys);
            });
            this.setState({
                categoriesData: categoriesData,
                allKeys: keys,
            });
        });
    };

    format = (data: any, indexs: number[], keys: string[]) => {
        if (data.children && isArray(data.children) && data.children.length) {
            data.children.forEach((item: any, i: number) => {
                this.format(item, [...indexs, i], keys);
            });
        }
        let title = data.name;

        data.title = title;
        data.key = data.id;
        if (indexs.length < 5) {
            keys.push(data.id);
        }
    };

    checkHandle = (checkedKeys: any) => {
        const { onChange, name } = this.props;
        onChange(checkedKeys, name);
    };

    render() {
        const { disabled, value } = this.props;
        const { categoriesData } = this.state;
        return (
            <div className="merchant-manager-category-selector">
                <Tree
                    disabled={disabled}
                    checkable
                    showLine
                    showIcon
                    treeData={categoriesData}
                    checkedKeys={value}
                    onCheck={this.checkHandle}
                />
            </div>
        );
    }
}
