import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { find, get, forEach } from 'lodash';
import moment from 'moment';

export const config: Config = {
    entities: {
        FullPaymentActivitiesEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
            apiPath: '/loader/admin/full-payment-activities',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<applicableSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOTSTARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                resaleDate: {
                    type: 'object.dateRangePlus',
                    displayName: '<<zxsrq>>',
                },
            },
            properties: {
                activityInfo: {
                    type: 'object.subForm',
                    displayName: '<<activityRule>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<selectActivityName>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<applicableSubsite>>',
                            rules: [{ required: true, type: 'array' }],
                        },
                        stockMode: {
                            type: 'string.options.radio',
                            displayName: '<<kcms>>',
                            defaultValue: 'GOODS_STOCK',
                            options: [
                                { id: 'GOODS_STOCK', name: '<<symdkc>>' },
                                { id: 'SHARE_STOCK', name: '<<sygxkc>>' },
                            ],
                            rules: [{ required: true }],
                            extra: '<<rxzsymdkc>><<rxzsygxkc>>',
                        },
                        goods: {
                            type: 'array.PresaleProducts',
                            displayName: '<<components.Tools.selectProduct>>',
                            controlConfig: {
                                selectProductTypes: ['NORMAL', 'COMBINE'],
                                showPresalePrice: true,
                                showQuantityLimit: true,
                                showFilter: true,
                                filterFields: [
                                    'activityProductTypes',
                                    'keywords',
                                    'subsiteId',
                                    'merchantId',
                                ],
                            },
                        },
                        products: {
                            type: 'array.productStyle.selector',
                            displayName: '<<components.Tools.selectProduct>>',
                            controlConfig: {
                                showFilter: true,
                                filterFields: [
                                    'activityProductTypes',
                                    'keywords',
                                    'subsiteId',
                                    'merchantId',
                                ],
                            },
                            extra: '<<sykcwyhkygmdspsl>>',
                        },
                        participateCondition: {
                            type: 'object.participateCondition',
                            displayName: '<<xgtj>>',
                            controlConfig: {
                                codes: [
                                    {
                                        code: 'QUANTITYLIMIT',
                                    },
                                    {
                                        code: 'QUANTITYLIMITPERORDER',
                                    },
                                ],
                            },
                        },
                        deliveryDateRange: {
                            type: 'object.dateRangePlus',
                            displayName: '<<fhsj>>',
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                            extra: '<<rpzztsj>>',
                        },
                        resaleDate: {
                            type: 'string.dateTime',
                            displayName: '<<zxsrq>>',
                            controlConfig: {
                                placeholder: '<<zdzxsrq>>',
                                showTime: false,
                                format: 'YYYY-MM-DD',
                                style: {
                                    width: 300,
                                },
                            },
                            extra: '<<dqkysywyhxyxtdywyxshdjds>>',
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],

                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.activityInfo?.subsites?.length &&
                                        row.activityInfo?.subsites
                                            .map((item: any) => item.id)
                                            .join(',');
                                    return { subsiteIds };
                                },
                            },
                        },
                    },
                },
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                resaleDate: {
                    type: 'string.date',
                    displayName: '<<zxsrq>>',
                },
            },
        },
    },
    components: {
        FullPaymentActivitiesView: {
            component: 'Viewport',
            entity: 'FullPaymentActivitiesEntity',
        },
        FullPaymentActivitiesPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'FullPaymentActivitiesFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/full-payment-activities/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'FullPaymentActivitiesTable' },
            ],
        },
        FullPaymentActivitiesFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<activityNameRequired>>',
                    },
                },
                {
                    property: 'subsiteIds',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'resaleDate',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        FullPaymentActivitiesTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'name',
                            width: 180,
                        },
                        {
                            property: 'startTime',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'endTime',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'subsites',
                            width: 200,
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '250px',
                                },
                                countSuffix: '<<subsites>>',
                            },
                        },
                        {
                            property: 'executeStatus',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                                statusConfig: {
                                    NOTSTARTED: {
                                        text: '<<notStarted>>',
                                        status: 'success',
                                    },
                                    STARTED: {
                                        text: '<<inprogress>>',
                                        status: 'processing',
                                    },
                                    COMPLETE: {
                                        text: '<<end>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                        {
                            property: 'resaleDate',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        width: 210,
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: false },
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                                            apiPath: '/admin/full_payment_activities/:id/disabled',
                                            config: {
                                                content: '<<hd>>{{row.name}}<<areadyClosed>>',
                                                text: '<<common.close>>',
                                            },
                                            confirm: {
                                                text: '<<isCloseActivity>>{{row.name}}?',
                                            },
                                        },
                                        {
                                            params: { status: true },
                                            value: false,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                                            apiPath: '/admin/full_payment_activities/:id/enabled',
                                            config: {
                                                content: '<<hd>>{{row.name}}<<areadyOpend>>',
                                                text: '<<common.opend>>',
                                            },
                                            confirm: {
                                                text: '<<isAreadyOpend>><<hd>>{{row.name}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                text: '<<common.edit>>',
                                type: 'link',
                                path: '/menu-list/market/full-payment-activities/edit/{{row.id}}',
                            },
                            {
                                type: 'component',
                                text: '<<importProduct>>',
                                statusKey: 'row.canImport',
                                component: 'FullPaymentActivitiesImportButton',
                            },
                            {
                                text: '<<common.log>>',
                                type: 'link',
                                path: '/menu-list/market/full-payment-activities/logs/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        FullPaymentActivitiesAddPage: {
            component: 'Card',
            content: { component: 'FullPaymentActivitiesAddForm' },
        },
        FullPaymentActivitiesAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'FullPaymentActivitiesEntity',
            labelCol: 3,
            controlCol: 21,
            fields: [
                { property: 'activityInfo.name' },
                { property: 'activityInfo.dateRange' },
                { property: 'activityInfo.subsites' },
                { property: 'activityInfo.marketingSchedule' },
                { property: 'activityInfo.stockMode' },
                {
                    property: 'activityInfo.goods',
                    visible: (values: any) =>
                        get(values, 'activityInfo.stockMode') === 'GOODS_STOCK',
                },
                {
                    property: 'activityInfo.products',
                    visible: (values: any) =>
                        get(values, 'activityInfo.stockMode') === 'SHARE_STOCK',
                },
                { property: 'activityInfo.participateCondition' },
                { property: 'activityInfo.deliveryDateRange' },
                { property: 'activityInfo.resaleDate' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                //校验限购条件配置输入值
                const participateCondition = entity.activityInfo.participateCondition;
                if (participateCondition && participateCondition.length > 0) {
                    const quantityLimit: any = find(participateCondition, {
                        code: 'QUANTITYLIMIT',
                    });
                    const quantityLimitPerOrder: any = find(participateCondition, {
                        code: 'QUANTITYLIMITPERORDER',
                    });
                    if (quantityLimit.checked && !quantityLimit.value) {
                        AntMessage.error(services.language.getText('qtxxgtjxzxddyz'));
                        return false;
                    }
                    if (quantityLimitPerOrder.checked && !quantityLimitPerOrder.value) {
                        AntMessage.error(services.language.getText('qtxxgtjxzxddyz'));
                        return false;
                    }
                    if (
                        quantityLimit.checked &&
                        quantityLimit.value &&
                        quantityLimitPerOrder.checked &&
                        quantityLimitPerOrder.value &&
                        parseInt(quantityLimit.value) < parseInt(quantityLimitPerOrder.value)
                    ) {
                        AntMessage.error(services.language.getText('myhhdqjgmdslydydymdgmdsl'));
                        return false;
                    }
                }
                //校验共享库存数量
                const stockMode = entity.activityInfo.stockMode;
                if (stockMode === 'SHARE_STOCK') {
                    const products = entity.activityInfo.products;
                    let hasValidate = true;
                    forEach(products, (i) => {
                        if (i.stock === null) {
                            AntMessage.warning(services.language.getText('qtxgxkc'));
                            hasValidate = false;
                            return false;
                        }
                        if (i.presalePrice !== null && i.presalePrice <= 0) {
                            AntMessage.warning(services.language.getText('ysjqsrdy'));
                            hasValidate = false;
                            return false;
                        }
                        if (i.quantityLimit !== null && i.quantityLimit <= 0) {
                            AntMessage.warning(services.language.getText('myhxgqsrdy'));
                            hasValidate = false;
                            return false;
                        }
                    });
                    if (!hasValidate) {
                        return false;
                    }
                } else if (stockMode === 'GOODS_STOCK') {
                    const goods = entity.activityInfo.goods;
                    let hasValidate = true;
                    forEach(goods, (i) => {
                        if (i.presalePrice !== null && i.presalePrice <= 0) {
                            AntMessage.warning(services.language.getText('ysjqsrdy'));
                            hasValidate = false;
                            return false;
                        }
                        if (i.quantityLimit !== null && i.quantityLimit <= 0) {
                            AntMessage.warning(services.language.getText('myhxgqsrdy'));
                            hasValidate = false;
                            return false;
                        }
                    });
                    if (!hasValidate) {
                        return false;
                    }
                }
                const resaleDate = entity.activityInfo.resaleDate;
                const startTime = entity.activityInfo.dateRange.start;
                if (
                    resaleDate &&
                    moment(resaleDate)
                        .startOf('day')
                        .diff(moment(startTime).startOf('day'), 'days') <= 0
                ) {
                    AntMessage.warning(services.language.getText('zxsrqsjxwyhdkssj'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        FullPaymentActivitiesEditPage: {
            component: 'Card',
            content: { component: 'FullPaymentActivitiesEditForm' },
        },
        FullPaymentActivitiesEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'FullPaymentActivitiesEntity',
            loaderType: 'get',
            labelCol: 3,
            controlCol: 21,
            fields: [
                { property: 'activityInfo.name' },
                { property: 'activityInfo.dateRange' },
                { property: 'activityInfo.subsites' },
                { property: 'activityInfo.marketingSchedule' },
                {
                    property: 'activityInfo.stockMode',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'activityInfo.goods',
                    visible: (values: any) =>
                        get(values, 'activityInfo.stockMode') === 'GOODS_STOCK',
                },
                {
                    property: 'activityInfo.products',
                    visible: (values: any) =>
                        get(values, 'activityInfo.stockMode') === 'SHARE_STOCK',
                },
                { property: 'activityInfo.participateCondition' },
                { property: 'activityInfo.deliveryDateRange' },
                { property: 'activityInfo.resaleDate' },
            ],
            submit: true,
            onValidate: (entity: any) => {
                //校验限购条件配置输入值
                const participateCondition = entity.activityInfo.participateCondition;
                if (participateCondition && participateCondition.length > 0) {
                    const quantityLimit: any = find(participateCondition, {
                        code: 'QUANTITYLIMIT',
                    });
                    const quantityLimitPerOrder: any = find(participateCondition, {
                        code: 'QUANTITYLIMITPERORDER',
                    });
                    if (quantityLimit.checked && !quantityLimit.value) {
                        AntMessage.error(services.language.getText('qtxxgtjxzxddyz'));
                        return false;
                    }
                    if (quantityLimitPerOrder.checked && !quantityLimitPerOrder.value) {
                        AntMessage.error(services.language.getText('qtxxgtjxzxddyz'));
                        return false;
                    }
                    if (
                        quantityLimit.checked &&
                        quantityLimit.value &&
                        quantityLimitPerOrder.checked &&
                        quantityLimitPerOrder.value &&
                        parseInt(quantityLimit.value) < parseInt(quantityLimitPerOrder.value)
                    ) {
                        AntMessage.error(services.language.getText('myhhdqjgmdslydydymdgmdsl'));
                        return false;
                    }
                }
                //校验共享库存数量
                const stockMode = entity.activityInfo.stockMode;
                if (stockMode === 'SHARE_STOCK') {
                    const products = entity.activityInfo.products;
                    let hasValidate = true;
                    forEach(products, (i) => {
                        if (i.stock === null) {
                            AntMessage.warning(services.language.getText('qtxgxkc'));
                            hasValidate = false;
                            return false;
                        }
                        if (i.presalePrice !== null && i.presalePrice <= 0) {
                            AntMessage.warning(services.language.getText('ysjqsrdy'));
                            hasValidate = false;
                            return false;
                        }
                        if (i.quantityLimit !== null && i.quantityLimit <= 0) {
                            AntMessage.warning(services.language.getText('myhxgqsrdy'));
                            hasValidate = false;
                            return false;
                        }
                    });
                    if (!hasValidate) {
                        return false;
                    }
                } else if (stockMode === 'GOODS_STOCK') {
                    const goods = entity.activityInfo.goods;
                    let hasValidate = true;
                    forEach(goods, (i) => {
                        if (i.presalePrice !== null && i.presalePrice <= 0) {
                            AntMessage.warning(services.language.getText('ysjqsrdy'));
                            hasValidate = false;
                            return false;
                        }
                        if (i.quantityLimit !== null && i.quantityLimit <= 0) {
                            AntMessage.warning(services.language.getText('myhxgqsrdy'));
                            hasValidate = false;
                            return false;
                        }
                    });
                    if (!hasValidate) {
                        return false;
                    }
                }
                const resaleDate = entity.activityInfo.resaleDate;
                const startTime = entity.activityInfo.dateRange.start;
                if (
                    resaleDate &&
                    moment(resaleDate)
                        .startOf('day')
                        .diff(moment(startTime).startOf('day'), 'days') <= 0
                ) {
                    AntMessage.warning(services.language.getText('zxsrqsjxwyhdkssj'));
                    return false;
                }
                return true;
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        FullPaymentActivitiesLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                    apiPath: '/admin/full_payment_activities/{id}/operation_logs',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/full-payment-activities',
                    component: 'FullPaymentActivitiesView',
                    breadcrumbName: '<<qkys>>',
                    privilege: {
                        path: 'fullPaymentActivity',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'FullPaymentActivitiesAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'FullPaymentActivitiesEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/logs/:id',
                            component: 'FullPaymentActivitiesLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        { path: '/', component: 'FullPaymentActivitiesPage' },
                    ],
                },
            ],
        },
    ],
};
