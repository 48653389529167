import React, { PureComponent } from 'react';
// import { services } from '@comall-backend-builder/core';
// import { debounce, cloneDeep } from 'lodash';
import { InputNumber, Select } from 'antd';
import { services } from '@comall-backend-builder/core';

import './index.less';

const { Option } = Select;
export interface RewardReriodState {
    data: any;
}
export class RewardReriod extends PureComponent<any, RewardReriodState> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: {},
        };
    }

    handleChange = (key: string, val: string) => {
        const { onChange, value } = this.props;
        let { data } = this.state;
        data[key] = val;
        const datas = { ...value, ...data };
        if (val === 'NO' && key === 'periodQuantityType' && datas.periodQuantity) {
            datas.periodQuantity = '';
        }
        if (val === 'NO' && key === 'memberRewardLimitType' && datas.memberRewardLimit) {
            datas.memberRewardLimit = '';
        }
        this.setState(
            {
                data: datas,
            },
            () => {
                onChange(datas);
            }
        );
    };
    render() {
        const { value = {}, disabled } = this.props;
        const { data } = this.state;
        const datas = { ...value, ...data };
        return (
            <div className="reward-reriod">
                <div className="reward-reriod-item">
                    {services.language.getText('mrfjsl')}：
                    <Select
                        className="fill-style"
                        value={datas.periodQuantityType || 'NO'}
                        defaultValue={'NO'}
                        style={{ width: '100px', marginRight: 10 }}
                        onChange={(e: any) => this.handleChange('periodQuantityType', e)}
                        disabled={disabled}
                    >
                        <Option value={'NO'}>{services.language.getText('bxz')}</Option>
                        <Option value={'YES'}>{services.language.getText('xz')}</Option>
                    </Select>
                    {datas.periodQuantityType === 'YES' && (
                        <InputNumber
                            disabled={disabled}
                            min={1}
                            placeholder={services.language.getText('inputPlease')}
                            precision={0}
                            max={999999}
                            style={{ width: 100 }}
                            value={datas.periodQuantity}
                            onChange={(e: any) => this.handleChange('periodQuantity', e)}
                        />
                    )}
                </div>

                <div className="reward-reriod-item">
                    {services.language.getText('myhkzjsl')}：
                    <Select
                        disabled={disabled}
                        className="fill-style"
                        value={datas.memberRewardLimitType || 'NO'}
                        defaultValue={'NO'}
                        style={{ width: '100px', marginRight: 10 }}
                        onChange={(e: any) => this.handleChange('memberRewardLimitType', e)}
                    >
                        <Option value={'NO'}>{services.language.getText('bxz')}</Option>
                        <Option value={'YES'}>{services.language.getText('xz')}</Option>
                    </Select>
                    {datas.memberRewardLimitType === 'YES' && (
                        <InputNumber
                            disabled={disabled}
                            min={1}
                            placeholder={services.language.getText('inputPlease')}
                            precision={0}
                            max={999999}
                            style={{ width: 100 }}
                            value={datas.memberRewardLimit}
                            onChange={(e: any) => this.handleChange('memberRewardLimit', e)}
                        />
                    )}
                </div>
            </div>
        );
    }
}
