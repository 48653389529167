import { Config } from '@comall-backend-builder/core/lib/parser';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

/**
 *
 * @param isAdd 是否为新增/编辑页面
 */
const formValidate = (entity: any, isAdd: boolean) => {
    const {
        rulesInfo: { couponReward },
    } = entity;
    const coupon = couponReward.length && couponReward[0];
    if (isAdd && coupon?.rewardPeriod?.periodQuantity > coupon?.leftStock) {
        message.error(language.getText('watchwordNumAddError'));
        return false;
    }
    if (!isAdd && coupon.rewardPeriod.periodQuantity < coupon.issuedNum) {
        message.error(language.getText('watchwordNumEditError'));
        return false;
    }
    if (
        coupon.rewardPeriod.periodQuantity === null &&
        coupon.rewardPeriod.periodQuantity === undefined
    ) {
        message.error(language.getText('watchwordNumPlaceholder'));
        return false;
    }
    return true;
};
export const config: Config = {
    entities: {
        WatchwordEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/watchword',
            properties: {
                subSites: {
                    type: 'array.popover',
                    displayName: '<<applicableSubsite>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: [
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherBaseInfo>>',
                    properties: {
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            rules: [{ required: true, type: 'array' }],
                        },
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],
                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.baseInfo?.subsites?.length &&
                                        row.baseInfo.subsites.map((item: any) => item.id).join(',');
                                    return { subsiteIds };
                                },
                            },
                        },
                    },
                },
                rulesInfo: {
                    type: 'object.subForm',
                    displayName: '<<ruleSetting>>',
                    properties: {
                        watchword: {
                            type: 'string',
                            displayName: '<<watchwordContent>>',
                            rules: [
                                {
                                    required: true,
                                },
                                {
                                    pattern: /^[A-Za-z¥！@～%]*([\u4e00-\u9fa5][A-Za-z¥！!@～~%]*)*$/,
                                    message: '<<watchwordErrorMessage>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<watchwordMessage>>',
                            },
                            extra: '<<watchwordExtra>>',
                        },
                        couponReward: {
                            type: 'array.bargain.couponsSelector',
                            displayName: '<<yhq>>',
                            controlConfig: {
                                inputDisabled: true,
                                isOnlyOne: true,
                                sendCoupon: {
                                    addonBefore: '<<activitySendNum>>',
                                    addonAfter: '<<f>>',
                                },
                                residueCoupon: {
                                    addonBefore: '<<sysl>>',
                                    addonAfter: '<<f>>',
                                },
                            },
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },
                        memberRewardLimit: {
                            type: 'number.tip',
                            displayName: '<<receiveLimit>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<inputThanZero>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^([1-9]\d*)$/;
                                        if (
                                            value !== undefined &&
                                            value !== null &&
                                            (!reg.test(value) || value === 0)
                                        ) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                addonbefore: '<<everyBodyActivityReceive>>',
                                addonafter: '<<frequency>>',
                                style: { width: '80px' },

                                min: 1,
                            },
                        },
                        watchwordEntrance: {
                            type: 'string.options.radio',
                            displayName: '<<displayWatchwordEntry>>',
                            defaultValue: 'SHOW',
                            className: 'watchword-entrance',
                            controlConfig: {
                                style: {
                                    width: 600,
                                },
                            },
                            options: [
                                { id: 'SHOW', name: '<<showWatchwordEntry>>' },
                                { id: 'HIDE', name: '<<hiddenWatchwordEntry>>' },
                            ],
                        },
                    },
                },

                pageRule: {
                    type: 'object.subForm',
                    displayName: '<<pageRule>>',
                    properties: {
                        activityBackgroundPicture: {
                            displayName: '<<hdbjt>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'single',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<watchwordBackgroundImageExtra>>',
                        },
                        buttonBackgroundPicture: {
                            displayName: '<<anbjt>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/watchword/btn-default.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'single',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<watchwordBtnImageExtra>>',
                        },
                        // shareSmallPicture: {
                        //     displayName: '<<fxxt>>',
                        //     type: 'imageObjectArray',
                        //     className: 'sigle-image-field',
                        //     rules: [{ required: true }],
                        //     controlConfig: {
                        //         maxCount: 1,
                        //         multiple: false,
                        //         fileName: 'fileName',
                        //         uploadType: 'multi',
                        //         uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        //         mode: 'picture-card',
                        //     },
                        //     extra: '<<watchwordPictureExtra>>',
                        // },
                        description: {
                            type: 'string.richTextPlus',
                            displayName: '<<activityRule>>',
                            rules: [{ max: 20000, message: '<<notMoreThan2000>>' }],
                            controlConfig: {
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                        },
                    },
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { style: { width: 260 } },
                },
                subsiteName: {
                    type: 'object.option.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 260 } },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                    controlConfig: {
                        style: {
                            width: 260,
                        },
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
            },
        },
    },
    components: {
        WatchwordView: {
            component: 'Viewport',
            entity: 'WatchwordEntity',
        },
        WatchwordPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'WatchwordHeader',
                },
                {
                    component: 'WatchwordTable',
                },
            ],
        },
        WatchwordHeader: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'GridLayout',
                    items: [
                        {
                            component: 'WatchwordFilter',
                            span: 24,
                        },
                        {
                            component: 'Button',
                            text: '<<addActivity>>',
                            icon: 'plus',
                            type: 'primary',
                            route: '/menu-list/market/watchword/create',
                            span: 4,
                            style: {
                                marginRight: 24,
                            },
                            className: 'mt24 mb24',
                        },
                    ],
                },
            ],
        },
        WatchwordFilter: {
            component: 'FilterForm',
            direction: 'inline',
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'subsiteName',
                },
                {
                    property: 'executeStatus',
                },
                {
                    property: 'dateRange',
                },
            ],
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 12,
                },
            },
            reset: true,
        },
        WatchwordTable: {
            component: 'DataTable',
            loadFirstPage: true,
            scroll: {
                x: true,
            },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'subSites',
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'executeStatus',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 190,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/watchword_activities/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/watchword_activities/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/menu-list/market/watchword/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/menu-list/market/watchword/copy/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<common.log>>',
                        path: '/menu-list/market/watchword/logs/{{row.id}}',
                    },
                ],
            },
        },
        WatchwordCreatePage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'WatchwordEntity',
            items: [{ component: 'WatchwordPreview' }, { component: 'WatchwordCreateForm' }],
        },
        WatchwordCreateForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            style: {
                width: 700,
            },
            fields: [
                {
                    property: 'baseInfo.subsites',
                },
                {
                    property: 'baseInfo.name',
                },
                {
                    property: 'baseInfo.dateRange',
                },
                {
                    property: 'baseInfo.marketingSchedule',
                },
                {
                    property: 'rulesInfo.watchword',
                },
                {
                    property: 'rulesInfo.couponReward',
                },
                {
                    property: 'rulesInfo.memberRewardLimit',
                },
                {
                    property: 'rulesInfo.watchwordEntrance',
                },
                {
                    property: 'pageRule.activityBackgroundPicture',
                },
                {
                    property: 'pageRule.buttonBackgroundPicture',
                },
                {
                    property: 'pageRule.description',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => formValidate(entity, true),
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        WatchwordEditPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'WatchwordEntity',
            loaderType: 'get',
            items: [{ component: 'WatchwordPreview' }, { component: 'WatchwordEditForm' }],
        },
        WatchwordEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            style: {
                width: 700,
            },
            fields: [
                {
                    property: 'baseInfo.subsites',
                    controlConfig: { disabled: true },
                },
                {
                    property: 'baseInfo.name',
                },
                {
                    property: 'baseInfo.dateRange',
                },
                {
                    property: 'baseInfo.marketingSchedule',
                },
                {
                    property: 'rulesInfo.watchword',
                },
                {
                    property: 'rulesInfo.couponReward',
                },
                {
                    property: 'rulesInfo.memberRewardLimit',
                },
                {
                    property: 'rulesInfo.watchwordEntrance',
                },
                {
                    property: 'pageRule.activityBackgroundPicture',
                },
                {
                    property: 'pageRule.buttonBackgroundPicture',
                },
                {
                    property: 'pageRule.description',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => formValidate(entity, false),
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        WatchwordCopyPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'WatchwordEntity',
            loaderType: 'get',
            items: [{ component: 'WatchwordPreview' }, { component: 'WatchwordCopyForm' }],
        },
        WatchwordCopyForm: {
            component: 'CopyFormPlus',
            direction: 'horizontal',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            style: {
                width: 700,
            },
            fields: [
                {
                    property: 'baseInfo.subsites',
                },
                {
                    property: 'baseInfo.name',
                },
                {
                    property: 'baseInfo.dateRange',
                },
                {
                    property: 'baseInfo.marketingSchedule',
                },
                {
                    property: 'rulesInfo.watchword',
                },
                {
                    property: 'rulesInfo.couponReward',
                },
                {
                    property: 'rulesInfo.memberRewardLimit',
                },
                {
                    property: 'rulesInfo.watchwordEntrance',
                },
                {
                    property: 'pageRule.activityBackgroundPicture',
                },
                {
                    property: 'pageRule.buttonBackgroundPicture',
                },
                {
                    property: 'pageRule.description',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => formValidate(entity, true),
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        WatchwordLogPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                    apiPath: '/admin/watchword_activities/{id}/operation_logs',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/watchword',
                    component: 'WatchwordView',
                    breadcrumbName: '<<watchwordCouponCollection>>',
                    childRoutes: [
                        {
                            path: '/create',
                            component: 'WatchwordCreatePage',
                        },
                        {
                            path: '/edit/:id',
                            component: 'WatchwordEditPage',
                        },
                        {
                            path: '/copy/:id',
                            component: 'WatchwordCopyPage',
                        },
                        {
                            path: '/logs/:id',
                            component: 'WatchwordLogPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        {
                            path: '/',
                            component: 'WatchwordPage',
                        },
                    ],
                    privilege: {
                        path: 'watchword',
                        level: 'full',
                    },
                },
            ],
        },
    ],
};
