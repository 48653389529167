import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty, map } from 'lodash';

const api = services.api;
export const ImproveInformationLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/improve_information';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/improve_information/${id}`;
        } else if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                const {
                    name,
                    assetRewards,
                    couponRewards,
                    normalConditions,
                    startTime: start,
                    endTime: end,
                } = res;
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : services.language.getText('longTerm'),
                    };
                }
                res.baseInfo = {
                    name,
                    dateRange: {
                        start,
                        end,
                    },
                    subsiteIds: res.subsites,
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };
                const normalConditionsIds = normalConditions[0]?.includeIds;
                const rewards = {
                    assetRewards,
                    couponRewards: couponRewards?.length ? couponRewards : undefined,
                };
                res.rewardConfig = { normalConditionsIds, rewards };
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/improve_information';
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = `/admin/improve_information/${params.id}`;
        const newParams = dataParser(params);
        return await api.put(newParams, config);
    },
};

const dataParser = (params: any) => {
    const {
        baseInfo: {
            dateRange: { start, end },
            marketingSchedule,
            ...baseInfo
        },
        rewardConfig: {
            normalConditionsIds,
            rewards: { assetRewards, couponRewards },
        },
    } = params;
    const subsiteIncludeIds = map(params.baseInfo.subsiteIds, 'id');
    return {
        eventType: 'IMPROVE_INFORMATION',
        ...baseInfo,
        startTime: start,
        endTime: end,
        assetRewards,
        couponRewards: couponRewards?.map((coupon: any) => ({
            id: coupon.id,
            quantity: coupon.quantity,
        })),
        normalConditions: [
            {
                includeIds: normalConditionsIds,
                normalConditionType: 'MEMBER_INFORMATION',
            },
            {
                includeIds: subsiteIncludeIds,
                normalConditionType: 'SUB_SITE',
            },
        ],
        marketingScheduleId: (marketingSchedule?.length && marketingSchedule[0].id) || null,
    };
};
