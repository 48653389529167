import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    components: {
        DataPanelsMembersView: {
            component: 'Viewport',
        },
        DataPanelsMembersPage: {
            entity: 'DataPanelsMembersEntity',
            component: 'FlexLayout',
            loaderType: 'get',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'GridLayout',
                    className: 'mb24',
                    items: [
                        {
                            component: 'DataPanelsMembersFilter',
                        },
                    ],
                },
                {
                    component: 'DataPanelsMembersCard',
                },
            ],
        },
        DataPanelsMembersFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                },
                // {
                //     property: 'shopId',
                // },
                {
                    property: 'dateRange',
                },
            ],
        },
        DataPanelsMembersCard: {
            component: 'Card',
            content: {
                component: 'FlexLayout',
                direction: 'vertical',
                items: [
                    {
                        component: 'DataPanelTitle',
                        title: '<<membersData>>',
                    },
                    {
                        component: 'DataPanelsMembersCardView',
                    },
                ],
            },
        },
    },
    entities: {
        DataPanelsMembersEntity: {
            apiPath: '/loader/admin/members-data-panel',
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                // shopId: {
                //     type: 'string.filterOptions.select',
                //     displayName: '<<merchant>>',
                //     options: [],
                //     source: {
                //         apiPath: '/loader/admin/subsites/mine/merchants',
                //         apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                //     },
                //     controlConfig: {
                //         placeholder: '<<selectPlease>>',
                //         style: { width: 170 },
                //         dependences: 'subsiteId',
                //         paramsName: 'subSiteName',
                //     },
                // },
                dateRange: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<date>>',
                },
            },
            properties: {
                name: {
                    type: 'string',
                    displayName: '<<mc>>',
                },
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/data-panels-members',
                    component: 'DataPanelsMembersView',
                    breadcrumbName: '<<membersData>>',
                    privilege: {
                        path: 'membersData',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'DataPanelsMembersPage' }],
                },
            ],
        },
    ],
};
