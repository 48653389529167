import React from 'react';

import { modes } from '@comall-backend-builder/types';
import { StringPasswordPlus } from '../../../components/string-password-plus';

export class StringPasswordPlusMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    public getAvailableDisplayComponent(value: any, displayInfo: any) {
        return <span>{value}</span>;
    }
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <StringPasswordPlus {...controlInfo} />;
    }
}
