import { Config } from '@comall-backend-builder/core/lib/parser';
import { forEach, isEmpty, map, get } from 'lodash';
import { ActivityTagType } from '../../../cms/mall-activity-tag';
import {
    NewMallActivityRegistrationTimeType,
    TipsModalContentType,
    NewMallActivityType,
    NewMallActivityTicketType,
    NewMallActivityRegistrationTimeValue,
} from '../../../../components';
import isNil from 'lodash/isNil';
import { TimeUnit } from '../../../../types/mode/object/mall-activity-subscript-message-setting/mall-activity-subscript-message-setting';
import { extraElement } from '../../../../services/extra-element';
import { extraForward } from '../../../../services/extra-forward';
import { services } from '@comall-backend-builder/core';
import { PushAndSiteMailSceneType } from '../../../../containers/wechat/common';

export enum CrowdDisplayConditionEnum {
    INVISIBLE = 'INVISIBLE', //所有人不可见
    PART_CROWD_SHOW = 'PART_CROWD_SHOW', //指定人群可见
    PART_CROWD_HIDE = 'PART_CROWD_HIDE', //指定人群不可见
}

const hideCrowdsIllustrate: { [str: string]: any } = {
    appoint: '<<qlxkhcgjljxkt>>',
    blacklist: '<<qzlbzdrhmd>>',
};

const targetUserCrowdIllustrate: { [str: string]: any } = {
    ok: '',
    non: '<<qlxkhcgjljxkt>>',
};

const components: Config['components'] = {
    MagicMallActivityAfterSalePage: {
        component: 'FlexLayout',
        entity: 'MallActivityAfterSaleEntity',
        direction: 'vertical',
        items: [
            {
                component: 'MallActivityAfterSaleFilter',
            },
            { component: 'MagicMallActivityAfterSaleTables' },
        ],
    },
    MagicMallActivityAfterSaleTables: {
        component: 'Tabs',
        cache: false,
        animated: false,
        items: [
            {
                title: '<<all>>',
                content: {
                    component: 'MagicMallActivityAfterSaleTable',
                },
                params: { status: '' },
            },
            {
                title: '<<waiting>>',
                content: {
                    component: 'MagicMallActivityAfterSaleTable',
                },
                params: { status: 'APPLYING' },
            },
            {
                title: '<<rejected>>',
                content: {
                    component: 'MagicMallActivityAfterSaleTable',
                },
                params: { status: 'APPLY_FAIL' },
            },
            {
                title: '<<approved>>',
                content: {
                    component: 'MagicMallActivityAfterSaleTable',
                },
                params: { status: 'APPLY_SUCC' },
            },
        ],
    },
    MagicMallActivityAfterSaleTable: {
        component: 'FlexLayout',
        direction: 'vertical',

        items: [
            {
                component: 'DataTable',
                loadFirstPage: true,
                scroll: {
                    x: 1300,
                },
                pagination: {
                    pageSize: 10,
                    showSizeChanger: true,
                    showQuickJumper: true,
                    pageSizeOptions: ['10', '15', '20'],
                    showTotal(total: number) {
                        return services.interpolate(services.language.getText('total'), {
                            total,
                        });
                    },
                },
                columns: [
                    {
                        property: 'subsiteName',
                        width: 160,
                        displayConfig: {
                            className: 'ant-table-cell-ellipsis',
                            style: {
                                maxWidth: '160px',
                            },
                        },
                    },
                    {
                        property: 'saleRuleName',
                        width: 180,
                    },
                    {
                        property: 'orderNo',
                        width: 200,
                    },
                    {
                        property: 'serviceApplyNo',
                        width: 205,
                    },
                    {
                        property: 'quantity',
                        width: 80,
                    },

                    {
                        property: 'priceAmount',
                        width: 120,
                    },
                    {
                        property: 'pointAmount',
                        width: 80,
                    },
                    {
                        property: 'statusName',
                        width: 120,
                    },
                    {
                        property: 'entryTypeName',
                        width: 120,
                    },
                    {
                        property: 'applyUser',
                        width: 120,
                    },
                    {
                        property: 'auditUser',
                        width: 120,
                    },
                    {
                        property: 'refundStatusName',
                        width: 120,
                    },
                    {
                        property: 'createTime',
                        width: 120,
                    },
                ],
                actionColumn: {
                    title: '<<common.tableAction>>',
                    className: 'action-column',
                    fixed: 'right',
                    width: 250,
                    items: [
                        {
                            type: 'component',
                            component: 'MallActivityAppliesApplyButton',
                        },
                        {
                            type: 'link',
                            text: '<<details>>',
                            path: '/new-mall-activity/after-sale/info/{{row.id}}',
                        },
                    ],
                },
            },
        ],
    },
};

const GENDER_OPTIONS = [
    {
        id: 'MAN',
        name: '<<n_2>>',
    },
    {
        id: 'WOMAN',
        name: '<<n>>',
    },
];
const REGISTRATION_STATUS_OPTIONS = [
    {
        id: 'STARTED',
        name: '<<inprogress>>',
    },
    {
        id: 'COMPLETE',
        name: '<<end>>',
    },
    {
        id: 'NOT_STARTED',
        name: '<<notStarted>>',
    },
];

const EXECUTE_STATUS_OPTIONS = [
    {
        id: 'STARTED',
        name: '<<inprogress>>',
    },
    {
        id: 'COMPLETE',
        name: '<<end>>',
    },
    {
        id: 'NOT_STARTED',
        name: '<<notStarted>>',
    },
];

const MALL_ACTIVITY_TYPE = [
    {
        id: NewMallActivityType.Notification,
        name: '<<xclhd>>',
    },
    {
        id: NewMallActivityType.SignUp,
        name: '<<bmlhd>>',
    },
    {
        id: NewMallActivityType.Exhibition,
        name: '<<mplhd>>',
    },
];

export const config: Config = {
    entities: {
        NewMallActivityEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
            apiPath: '/loader/admin/newMallActivity',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsrhdmc>>' },
                },
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 150 } },
                },
                merchantId: {
                    type: 'string.filterOptions.autoComplete',
                    displayName: '<<merchant>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'subsiteIds',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                participationStatus: {
                    type: 'string.options.select',
                    displayName: '<<cyzt>>',
                    options: REGISTRATION_STATUS_OPTIONS,
                },
                type: {
                    type: 'string.options.select',
                    displayName: '<<hdlx>>',
                    options: MALL_ACTIVITY_TYPE,
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: EXECUTE_STATUS_OPTIONS,
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                info: {
                    type: 'object.mallActivityInfo',
                    displayName: '<<hdxx>>',
                },
                type: {
                    type: 'string.options.select',
                    displayName: '<<hdlx>>',
                    options: MALL_ACTIVITY_TYPE,
                },
                startTime: {
                    displayName: '<<startTime>>',
                    type: 'string',
                },
                endTime: {
                    displayName: '<<endTime>>',
                    type: 'string',
                },
                subSites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                merchants: {
                    type: 'array.popover',
                    displayName: '<<merchantName>>',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                participationStatus: {
                    type: 'string.options.select',
                    displayName: '<<cyzt>>',
                    options: REGISTRATION_STATUS_OPTIONS,
                },
                enable: {
                    type: 'boolean',
                    displayName: '<<kqzt>>',
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                blackUserListFileUrl: {
                    type: 'string.activityBlackImportExport',
                    displayName: '<<hmd>>',
                },

                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<hdxx>>',
                    collapsed: true,
                    defaultOpen: true,
                    properties: {
                        name: {
                            type: 'string.text.paragraph',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrhdmc>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '',
                                maxLength: 100,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        merchants: {
                            type: 'array.shop.selector',
                            displayName: '<<cymdjzj>>',
                            rules: [{ required: true, type: 'array' }],
                        },

                        dateRange: {
                            type: 'object.dateRangePlus',
                            displayName: '<<hdrq>>',
                            rules: [{ required: true, message: '<<qxzhdrq>>' }],
                            extra: '<<hddksjsrq>>',
                        },
                        editStartTime: {
                            type: 'string.date',
                            displayName: '<<hdksrq>>',
                            rules: [{ required: true, message: '<<qxzksrq>>' }],
                        },
                        editEndTime: {
                            type: 'string.date',
                            displayName: '<<hdjsrq>>',
                            rules: [{ required: true, message: '<<qxzjsrq>>' }],
                        },
                        dayTime: {
                            type: 'array.presaleTimepicker',
                            displayName: '<<acitivityTime>>',
                            className: 'presale-range-timepicker',
                            rules: [
                                {
                                    required: true,
                                    message: '<<acitivityTimeRequired>>',
                                    validator: (rule: any, value: any) => {
                                        let result: Array<boolean> = [];
                                        if (value !== null && !isEmpty(value)) {
                                            // 如果是交叉时间 就提示
                                            forEach(value, (outerItem, outerIndex) => {
                                                forEach(value, (item, index) => {
                                                    if (
                                                        !isEmpty(outerItem) &&
                                                        !isEmpty(item) &&
                                                        outerIndex !== index
                                                    ) {
                                                        let valueStart = new Date(
                                                            '2020-01-01 ' + outerItem.start
                                                        ).getTime();
                                                        let valueEnd = new Date(
                                                            '2020-01-01 ' + outerItem.end
                                                        ).getTime();
                                                        let itemStart = new Date(
                                                            '2020-01-01 ' + item.start
                                                        ).getTime();
                                                        let itemEnd = new Date(
                                                            '2020-01-01 ' + item.end
                                                        ).getTime();

                                                        if (valueStart - itemEnd > 0) {
                                                            result.push(true);
                                                        } else if (valueEnd - itemStart < 0) {
                                                            result.push(true);
                                                        } else {
                                                            result.push(false);
                                                        }
                                                    }
                                                });
                                            });
                                        } else {
                                            return false;
                                        }
                                        if (result.indexOf(false) > -1) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            extra: '<<hdmtdksjssj>>',
                        },
                        addressName: {
                            type: 'string.text.paragraph',
                            displayName: '<<hdddmc>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                placeholder: '<<qsrhdddmc>>',
                                maxLength: 100,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        address: {
                            type: 'string.text.paragraph',
                            displayName: '<<detailAddress>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                placeholder: '<<jqsrxxdzbcg10z>>',
                                maxLength: 100,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        phone: {
                            type: 'string',
                            displayName: '<<servicePhone>>',
                            rules: [
                                {
                                    pattern: /^[`~!@#$%^&*()_\-+=<>?:"{}|,./;'\\[\]·~！@#￥%……&*（）——\-+={}|《》？：“”【】、；‘'，。、\d]{11,20}$/,
                                    message: '<<qsrzqgsdsjh>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrzxdh>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        merchantUserPhone: {
                            type: 'string.merchantUserPhoneInput',
                            displayName: '<<zxdg>>',
                            controlConfig: {
                                placeholder: '<<pleaseClerkMobile>>',
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        tagIdList: {
                            type: 'array.MallActivityTag.selector',
                            displayName: '<<activityTag>>',
                            controlConfig: {
                                placeholder: '<<activityTagRequired>>',
                                style: {
                                    width: '450px',
                                },
                                getParams(props: any) {
                                    const { row } = props;
                                    const subsiteIds = get(row, 'baseInfo.merchants.length')
                                        ? map(row.baseInfo.merchants, 'id').join(',')
                                        : undefined;
                                    return {
                                        type: ActivityTagType.MALL_ACTIVITY,
                                        subsiteIds,
                                    };
                                },
                            },
                        },

                        canShare: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '<<fxsz>>',
                            defaultValue: ['true'],
                            options: [{ id: 'true', name: '<<yxjghdfxjhy>>' }],
                        },
                        hideSetting: {
                            type: 'array.optionIds.checkbox.tip',
                            displayName: '<<yzsz>>',
                            defaultValue: ['false'],
                            options: [{ id: 'true', name: '<<ghdzhdlb>>' }],
                        },
                        crowdDisplayCondition: {
                            type: 'string.options.radio',
                            displayName: '<<xzlx>>',
                            defaultValue: CrowdDisplayConditionEnum.INVISIBLE,
                            options: [
                                {
                                    id: CrowdDisplayConditionEnum.INVISIBLE,
                                    name: '<<syrdyzbkj>>',
                                },
                                {
                                    id: CrowdDisplayConditionEnum.PART_CROWD_HIDE,
                                    name: '<<zdrqyzbkj>>',
                                },
                                {
                                    id: CrowdDisplayConditionEnum.PART_CROWD_SHOW,
                                    name: '<<zdrqkj>>',
                                },
                            ],
                            extra: '<<hdlbyExtra>>',
                        },
                        hideCrowdsStatus: {
                            type: 'string.radioIllustrate',
                            displayName: '<<bkjrq>>',
                            name: JSON.stringify(hideCrowdsIllustrate),
                            defaultValue: 'appoint',
                            options: [
                                {
                                    id: 'blacklist',
                                    name: '<<jhmdzyhbkj>>',
                                },
                                {
                                    id: 'appoint',
                                    name: '<<zdrqyzbkj>>',
                                },
                            ],
                            controlConfig: {
                                illustrate: hideCrowdsIllustrate,
                                forwardText: '<<crowdManage>>',
                                forwardPath: '#/crowd-manage',
                                forwardPrivilege: 'memberv2Crowds',
                                style: { display: 'flex', flexDirection: 'column', paddingTop: 9 },
                            },
                        },
                        hideCrowds: {
                            type: 'array.receiveLimitationsCrowds',
                            displayName: '<<yzbkjrq>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                pageType: 'NewMallActivity',
                            },
                        },
                        showCrowds: {
                            type: 'array.receiveLimitationsCrowds',
                            displayName: '<<kjrq>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                pageType: 'NewMallActivity',
                            },
                            extra: extraForward({
                                tips: ['<<qlxkhcgjljxkt>>'],
                                forwardText: '<<crowdManage>>',
                                forwardPath: '#/crowd-manage',
                                forwardPrivilege: 'memberv2Crowds',
                            }),
                        },
                    },
                },
                mediaInfo: {
                    type: 'object.subForm',
                    displayName: '<<pageDecoration>>',
                    collapsed: true,
                    properties: {
                        pictures: {
                            displayName: '<<hdtp>>',
                            type: 'array.imageUploadAndCrop',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                fileName: 'files',
                                apiRoot: ENV.API_ROOT.replace('/WEB-API', '/FILE'),
                                apiPath: '/admin/files/locations/5/upload',
                                mode: 'picture-card',
                                maxSize: 1 * 1024,
                                customOptions: {
                                    cropBoxWidth: 750,
                                    cropBoxHeight: 422,
                                },
                            },
                            extra: '<<jjyccbcg1m>>',
                        },
                        poster: {
                            displayName: '<<fxhb>>',
                            type: 'array.imageUploadAndCrop',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                fileName: 'files',
                                apiRoot: ENV.API_ROOT.replace('/WEB-API', '/FILE'),
                                apiPath: '/admin/files/locations/5/upload',
                                mode: 'picture-card',
                                maxSize: 1 * 1024,
                                customOptions: {
                                    cropBoxWidth: 560,
                                    cropBoxHeight: 560,
                                },
                            },
                            extra: '<<posterLimitExtra>>',
                        },
                        info: {
                            type: 'string.richTextPlus',
                            displayName: '<<hdxq>>',
                            rules: [{ max: 20000, message: '<<notMoreThan2000>>' }],
                            controlConfig: {
                                placeholder: '<<descriptionRule>>',
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            extra: '<<infoExtra>>',
                        },
                    },
                },
                registrationInfo: {
                    type: 'object.subForm',
                    displayName: '<<ruleSetting>>',
                    collapsed: true,
                    properties: {
                        type: {
                            type: 'string.options.radio',
                            displayName: '<<hdlx>>',
                            defaultValue: 'NOTIFICATION',
                            rules: [{ required: true }],
                            options: MALL_ACTIVITY_TYPE,
                            extra: extraElement({
                                tips: [
                                    '<<activityTypeExtra1>>',
                                    '<<activityTypeExtra2>>',
                                    '<<activityTypeExtra3>>',
                                ],
                                tipsModal: {
                                    contentType: TipsModalContentType.NewMallActivityType,
                                    showIcon: false,
                                    text: '<<viewExample>>',
                                    textColor: '#1890ff',
                                    title: '',
                                    width: 453,
                                },
                            }),
                        },
                        ticketType: {
                            type: 'string.options.radio',
                            displayName: '<<cyfs>>',
                            defaultValue: NewMallActivityTicketType.Activity,
                            rules: [{ required: true }],
                            options: [
                                {
                                    id: NewMallActivityTicketType.Activity,
                                    name: '<<gmhhdqjdkcy>>',
                                },
                                {
                                    id: NewMallActivityTicketType.Round,
                                    name: '<<accbmgm>>',
                                },
                            ],
                        },
                        activityRounds: {
                            type: 'object.newMallAcitivityRounds',
                            displayName: '<<hdcc>>',
                        },
                        activitySingleRoundTickets: {
                            type: 'object.newMallAcitivityTickets',
                            displayName: '<<pdxx>>',
                        },
                        registrationStartTime: {
                            type: 'object.newMallActivityRegistrationTime',
                            defaultValue: {
                                timeType: NewMallActivityRegistrationTimeType.DAY_TIME,
                            },
                            displayName: '<<bmkssj>>',
                            rules: [
                                { required: true },
                                {
                                    validator: (
                                        _: any,
                                        value: NewMallActivityRegistrationTimeValue
                                    ) => {
                                        if (
                                            value.timeType ===
                                            NewMallActivityRegistrationTimeType.DAY_TIME
                                        ) {
                                            if (!value.timeStr) {
                                                return false;
                                            }
                                        } else {
                                            if (!value.num || !value.timeUnit) {
                                                return false;
                                            }
                                        }
                                        return true;
                                    },
                                    message: '<<bmkssjbt>>',
                                },
                            ],
                            controlConfig: {
                                mode: 'BEGIN',
                            },
                            extra: '<<rgxzaccbm>>',
                        },
                        registrationEndTime: {
                            defaultValue: {
                                timeType: NewMallActivityRegistrationTimeType.DAY_TIME,
                            },
                            type: 'object.newMallActivityRegistrationTime',
                            displayName: '<<bmjssj>>',
                            rules: [
                                { required: true },
                                {
                                    validator: (
                                        _: any,
                                        value: NewMallActivityRegistrationTimeValue
                                    ) => {
                                        if (
                                            value.timeType ===
                                            NewMallActivityRegistrationTimeType.DAY_TIME
                                        ) {
                                            if (!value.timeStr) {
                                                return false;
                                            }
                                        } else {
                                            if (isNil(value.num) || isNil(value.timeUnit)) {
                                                return false;
                                            }
                                        }
                                        return true;
                                    },
                                    message: '<<bmjssjbt>>',
                                },
                            ],
                        },
                        limitSession: {
                            type: 'string.options.radio',
                            displayName: '<<xgcc>>',
                            defaultValue: 'NO',
                            rules: [{ required: true }],
                            options: [
                                {
                                    id: 'NO',
                                    name: '<<wxz>>',
                                },
                                {
                                    id: 'YES',
                                    name: '<<xzcc>>',
                                },
                            ],
                        },
                        maxPurchaseTotal: {
                            type: 'number.integerInputTitleTip',
                            displayName: '<<xggmsl>>',
                            controlConfig: {
                                addonbefore: '<<hdqjmyhkbm>>',
                                addonafter: '<<zhang>>',
                                rules: [
                                    {
                                        type: 'number',
                                        min: 1,
                                        max: 999999,
                                    },
                                ],
                                width: 100,
                            },
                            extra: '<<bszdbbxg>>',
                        },
                        limitSessionCount: {
                            type: 'object.limitSessionCount',
                            displayName: '<<myhxzbmcc>>',
                            rules: [
                                { required: true },
                                {
                                    message: '<<qtxccgz>>',
                                    validator: (rule: any, value: any) => {
                                        if (get(value, 'activityCount') || get(value, 'dayCount')) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                        },
                        targetUserType: {
                            type: 'string.options.radio',
                            displayName: '<<cyyhsfxz>>',
                            defaultValue: 'ANY',
                            rules: [{ required: true }],
                            options: [
                                {
                                    id: 'ANY',
                                    name: '<<bxz>>',
                                },
                                {
                                    id: 'SOME',
                                    name: '<<xzcydrq>>',
                                },
                            ],
                            extra: '<<targetUserTypeExtra>>',
                        },
                        targetUserLevels: {
                            type: 'array.optionIds.select',
                            displayName: '<<kdj_1>>',
                            options: [],
                            source: {
                                apiPath: '/loader/admin/newMallActivity/userCardLevels',
                                dependences: ['baseInfo.merchants'],
                            },
                            controlConfig: {
                                placeholder: '<<qxzyhdj>>',
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        targetUserBirthDay: {
                            type: 'object.monthDayRange',
                            displayName: '<<srfw>>',
                            controlConfig: {
                                style: {
                                    width: 200,
                                },
                            },
                        },
                        targetUserSexuality: {
                            type: 'string.options.select',
                            displayName: '<<xb_1>>',
                            options: GENDER_OPTIONS,
                            controlConfig: {
                                placeholder: '<<qxzxb>>',
                                style: {
                                    width: 200,
                                },
                                allowClear: true,
                            },
                        },
                        targetUserCrowdStatus: {
                            type: 'string.radioIllustrate',
                            displayName: '<<xzrq_2>>',
                            name: JSON.stringify(targetUserCrowdIllustrate),
                            defaultValue: 'ok',
                            options: [
                                {
                                    id: 'ok',
                                    name: '<<zdrqkcy>>',
                                },
                                {
                                    id: 'non',
                                    name: '<<zdrqbkcy>>',
                                },
                            ],
                            controlConfig: {
                                illustrate: targetUserCrowdIllustrate,
                                forwardText: '<<crowdManage>>',
                                forwardPath: '#/crowd-manage',
                                forwardPrivilege: 'memberv2Crowds',
                                style: { display: 'flex', flexDirection: 'column', paddingTop: 9 },
                            },
                        },
                        nonPurchasableCrowds: {
                            type: 'array.receiveLimitationsCrowds',
                            displayName: '<<bkcyrq>>',
                            controlConfig: {
                                pageType: 'NewMallActivity',
                            },
                        },
                        purchasableCrowds: {
                            type: 'array.receiveLimitationsCrowds',
                            displayName: '<<kcyrq>>',
                            controlConfig: {
                                pageType: 'NewMallActivity',
                            },
                        },
                        customInfo: {
                            type: 'array.customForm',
                            displayName: '<<zdycyxxsz>>',
                            defaultValue: [],
                        },
                        info: {
                            type: 'string.richTextPlus',
                            displayName: '<<cyxz>>',
                            controlConfig: {
                                placeholder: '<<qsrbmxz>>',
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                        },
                        reviewType: {
                            type: 'string.options.radio',
                            displayName: '<<shcyzg>>',
                            defaultValue: 'FALSE',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            options: [
                                {
                                    id: 'FALSE',
                                    name: '<<bxysh>>',
                                },
                                {
                                    id: 'TRUE',
                                    name: '<<xshddhfkcj>>',
                                },
                            ],
                            extra: '<<jxzbxyshgmjkcyhd>>',
                        },
                        cancelableRule: {
                            type: 'object.mallActivityCelable',
                            // displayName: '允许订单在参与前取消',
                            displayName: '<<qxgz>>',
                            rules: [
                                { required: true },
                                {
                                    validator: (rule: any, value: any, cb: any) => {
                                        if (value.cancelable && !value.cancelableRule.duration) {
                                            return false;
                                        }
                                        return true;
                                    },
                                    message: '<<qsrqxsj>>',
                                },
                            ],
                            defaultValue: {
                                cancelable: false,
                                cancelableRule: {
                                    duration: undefined,
                                    unit: 'HOUR',
                                },
                            },
                            extra: '<<jqxgzdzfdddzkz>>',
                        },

                        excludeRule: {
                            type: 'string.options.radio',
                            displayName: '<<hcgz>>',
                            defaultValue: 'NONE',
                            rules: [{ required: true }],
                            options: [
                                {
                                    id: 'NONE',
                                    name: '<<bhc>>',
                                },
                                {
                                    id: 'ALL',
                                    name: '<<qbhdjpcchd>>',
                                },
                                {
                                    id: 'SOME',
                                    name: '<<bfhdjpcchd>>',
                                },
                            ],
                            extra: extraElement({
                                tips: [
                                    '<<excludeRuleExtra1>>',
                                    '<<excludeRuleExtra2>>',
                                    '<<excludeRuleExtra3>>',
                                ],
                            }),
                        },
                        excludeTypeActivity: {
                            rules: [{ required: true }],
                            type: 'array.activities.selector',
                            displayName: '<<xzhd>>',
                        },
                    },
                },
                contentInfo: {
                    type: 'object.subForm',
                    displayName: '<<nrxgsz>>',
                    collapsed: true,
                    properties: {
                        creator: {
                            type: 'object.activityCreatorPhoneInput',
                            displayName: '<<hdfqr>>',
                            extra: '<<hdfqrbxsnrptdzcyh>>',
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        creatorId: {
                            type: 'string',
                            displayName: '<<creatorId>>',
                        },
                        activityTopics: {
                            type: 'array.MallActivityTopic.selector',
                            displayName: '<<hdht>>',
                            defaultValue: [],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                            },
                        },
                    },
                },
                rewardInfo: {
                    type: 'object.subForm',
                    displayName: '<<jpsz>>',
                    collapsed: true,
                    properties: {
                        rewardDistributionTime: {
                            type: 'string.options.radio',
                            displayName: '<<jpff>>',
                            defaultValue: 'VERIFY_ACTIVITY',
                            rules: [{ required: true }],
                            options: [
                                {
                                    id: 'VERIFY_ACTIVITY',
                                    name: '<<hxhdhff>>',
                                },
                                {
                                    id: 'TICKET_PURCHASE_SUCCESS',
                                    name: '<<bmcghff>>',
                                },
                            ],
                            extra: '<<rewardDistributionTimeExtra>>',
                        },
                        reward: {
                            type: 'array.rewardSetting',
                            displayName: '<<hdjp>>',
                            controlConfig: {
                                eventType: 'MALL_ACTIVITY',
                                canChangeQuantity: false,
                            },
                        },
                    },
                },
                seniorSetting: {
                    type: 'object.subForm',
                    displayName: '<<seniorSetting>>',
                    collapsed: true,
                    properties: {
                        // enableNotice: {
                        //     type: 'array.optionIds.checkbox.tip',
                        //     displayName: '<<JQMessageNotify>>',
                        //     defaultValue: ['true'],
                        //     options: [{ id: 'true', name: '<<kqxxtz>>' }],
                        //     extra: '<<seniorSettingExtra1>><<seniorSettingExtra2>>',
                        // },
                        businessNotifications: {
                            type: 'array.messageNotificationMethod',
                            displayName: '<<tzfs>>',
                            defaultValue: [],
                            controlConfig: {
                                scene:
                                    PushAndSiteMailSceneType.MALL_ACTIVITY_REGISTRATION_SUCCEEDED_MSG,
                                title: '<<shtzyh>>',
                                showCollapse: true,
                            },
                        },
                        participatNotifications: {
                            type: 'array.messageNotificationMethod',
                            colon: false,
                            displayName: ' ',
                            defaultValue: [],
                            controlConfig: {
                                scene: PushAndSiteMailSceneType.MALL_ACTIVITY_PARTICIPATE_MSG,
                                title: '<<bmcgtzyh>>',
                                showCollapse: true,
                            },
                        },
                        registrationAbsentedNotifications: {
                            type: 'array.messageNotificationMethod',
                            colon: false,
                            displayName: ' ',
                            defaultValue: [],
                            controlConfig: {
                                scene:
                                    PushAndSiteMailSceneType.MALL_ACTIVITY_REGISTRATION_ABSENTED_MSG,
                                title: '<<kqtzyh>>',
                                showCollapse: true,
                            },
                        },
                        registrationStartedNotifications: {
                            type: 'array.messageNotificationMethod',
                            colon: false,
                            displayName: ' ',
                            defaultValue: [],
                            controlConfig: {
                                scene:
                                    PushAndSiteMailSceneType.MALL_ACTIVITY_REGISTRATION_STARTED_MSG,
                                title: '<<bmqtzyh>>',
                                showCollapse: true,
                            },
                        },
                        subscriptMessage: {
                            type: 'object.mallActivity.subscriptMessage.setting',
                            displayName: '<<subscriptMessage>>',
                            defaultValue: {
                                timeUnit: TimeUnit.HOUR,
                                num: 24,
                            },
                            extra: '<<subscriptMessageExtra>>',
                        },
                        marking: {
                            type: 'object.memberMarking',
                            displayName: '<<tbdbq>>',
                        },
                    },
                },
                mallActivityStatisticTotal: {
                    type: 'string',
                    displayName: '<<mallActivityStatisticTotal>>',
                },
            },
        },
        NewMallActivitySetting: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
            apiPath: '/loader/admin/v1/mall_activities/display_config/display_config',
            properties: {
                displayMallActivityStock: {
                    displayName: '<<display_mall_activity_stock>>',
                    type: 'string.options.radio',
                    defaultValue: 'true',
                    options: [
                        { id: 'true', name: '<<display_mall_activity_stock_true>>' },
                        { id: 'false', name: '<<display_mall_activity_stock_false>>' },
                    ],
                    extra: '<<display_mall_activity_stock_tip>>',
                },
                displayMallActivityStatus: {
                    displayName: '<<display_mall_activity_status>>',
                    type: 'string.options.radio',
                    defaultValue: 'true',
                    options: [
                        { id: 'true', name: '<<display_mall_activity_status_true>>' },
                        { id: 'false', name: '<<display_mall_activity_status_false>>' },
                    ],
                    extra: '<<display_mall_activity_status_tip>>',
                },
            },
        },
    },
    components: {
        NewMallActivityView: {
            component: 'Viewport',
            className: 'tabs-content-viewport',
        },
        NewMallActivityTabs: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<hdsz>>',
                    content: {
                        component: 'NewMallActivityTablePage',
                    },
                },
                {
                    title: '<<hdjl_1>>',
                    content: {
                        component: 'NewMallActivityAllTicketPurchaseRecordsPage',
                    },
                },
                {
                    title: '<<shd>>',
                    content: {
                        component: 'MagicMallActivityAfterSalePage',
                    },
                },
                {
                    title: '<<mallActivityRefund>>',
                    content: {
                        component: 'MallActivityRefundOrderPage',
                    },
                },
                {
                    title: '<<mallActivityVerification>>',
                    content: {
                        component: 'MallActivityVerificationPage',
                    },
                },
            ],
        },
        NewMallActivityPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'NewMallActivityTabs' }],
        },
        NewMallActivityTablePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'NewMallActivityEntity',
            items: [
                {
                    component: 'NewMallActivityFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            style: {
                                display: 'flex',
                            },
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<cjhd_1>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 2,
                                    route: '/new-mall-activity/add',
                                },
                                {
                                    type: 'component',
                                    component: 'PageNewPreviewCodeButton',
                                    codeTypes: 'all',
                                    codeModalType: 'list',
                                    codePageType: 'mallActivityListPage',
                                    buttonTitle: '<<tghdlb>>',
                                },
                                {
                                    type: 'component',
                                    component: 'NewMallActivitySettingButton',
                                },
                            ],
                        },
                    ],
                },
                { component: 'NewMallActivityTable' },
            ],
        },
        NewMallActivityFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'subsiteIds',
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'participationStatus',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'type',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'status',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        NewMallActivityAddPage: {
            component: 'FlexLayout',
            entity: 'NewMallActivityEntity',
            direction: 'horizontal',
            items: [{ component: 'NewMallActivityAddFormView' }],
        },
        NewMallActivityEditPage: {
            component: 'FlexLayout',
            entity: 'NewMallActivityEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'NewMallActivityEditFormView' }],
        },
        NewMallActivityCopyPage: {
            component: 'FlexLayout',
            entity: 'NewMallActivityEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'NewMallActivityCopyFormView' }],
        },
        NewMallActivityCopyFormView: {
            component: 'NewMallActivityAddFormView',
            formComponent: 'CopyFormPlus',
        },
        ...components,
        NewMallActivityAllTicketPurchaseRecordsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'NewMallActivityTicketPurchaseRecordsEntity',
            items: [
                {
                    component: 'NewMallActivityAllTicketPurchaseRecordsFilter',
                },
                { component: 'NewMallActivityTicketPurchaseRecordsTables' },
            ],
        },
        NewMallActivityAllTicketPurchaseRecordsFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'mallActivityName',
                    controlConfig: {
                        style: { width: 250 },
                    },
                },
                {
                    property: 'subsiteId',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'createOrderMemberMobile',
                    controlConfig: {
                        style: { width: 250 },
                    },
                },
                {
                    property: 'joinMobile',
                    controlConfig: {
                        style: { width: 250 },
                    },
                },
                {
                    property: 'joinName',
                    controlConfig: {
                        style: { width: 250 },
                    },
                },
                {
                    property: 'orderNo',
                    controlConfig: {
                        style: { width: 250 },
                    },
                },
                {
                    property: 'ticketName',
                    controlConfig: {
                        style: { width: 250 },
                    },
                },
                {
                    property: 'dateRange',
                },
                {
                    property: 'joinDate',
                },
            ],
        },
        NewMallActivityLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
                    apiPath: '/admin/operation_logs/MALL_ACTIVITY/{id}',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/new-mall-activity',
                    component: 'NewMallActivityView',
                    breadcrumbName: '<<newMallActivity>>',
                    privilege: {
                        path: 'newMallActivity',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'NewMallActivityAddPage',
                            breadcrumbName: '<<cjhd_1>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'NewMallActivityEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/copy/:id/:pageType',
                            component: 'NewMallActivityCopyPage',
                            breadcrumbName: '<<copyActivity>>',
                        },
                        {
                            path: '/record/:mallActivityId',
                            component: 'NewMallActivityTicketPurchaseRecordsPage',
                            breadcrumbName: '<<hdjl_1>>',
                        },
                        {
                            path: '/record-detail/:recordId',
                            component: 'NewMallActivityTicketPurchaseRecordsDetailPage',
                            breadcrumbName: '<<bmddxq>>',
                        },
                        {
                            path: '/apply/:recordId',
                            component: 'NewMallActivityRegistrationApplyPage',
                            breadcrumbName: '<<sh_2>>',
                        },
                        {
                            path: '/after-sale/info/:id',
                            component: 'NewMallActivityAfterSaleDetailPage',
                            breadcrumbName: '<<details>>',
                        },
                        {
                            path: '/logs/:id',
                            component: 'NewMallActivityLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        { path: '/', component: 'NewMallActivityPage' },
                    ],
                },
            ],
        },
    ],
};
