import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

enum ConsumeChannel {
    /**
     * 导购端
     */
    GUIDE = 'GUIDE',
    /**
     * 后台
     */
    BACKEND = 'BACKEND',
}

const CONSUME_CHANNEL_OPYIONS = [
    {
        id: ConsumeChannel.BACKEND,
        name: '<<hthx>>',
    },
    {
        id: ConsumeChannel.GUIDE,
        name: '<<dgdhx>>',
    },
];

const CONSUME_TYPE_OPTIONS = [
    {
        id: 'RECEIVE',
        name: '<<jc_1>>',
    },
    {
        id: 'CLOSE',
        name: '<<gh>>',
    },
];

export const config: Config = {
    entities: {
        ServiceReservedOrderConsumeRecordsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/service_reserved_activity_orders/consume_records',
            filters: {
                productName: {
                    type: 'string',
                    displayName: '<<fwmc>>',
                },
                subsiteId: {
                    type: 'string.options.select',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                memberMobile: {
                    type: 'string.text.trim',
                    displayName: '<<phoneNum>>',
                    rules: [
                        {
                            validator: (rule: any, value: any, callback: any) => {
                                if (value && value.trim()) {
                                    if (!/^[0-9]*$/.test(value.trim())) {
                                        return callback(services.language.getText('jzcsrsz'));
                                    }
                                }
                                callback();
                            },
                        },
                    ],
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<yyddh>>',
                },
                servicePlaceName: {
                    type: 'string',
                    displayName: '<<jjcghdd>>',
                },
                consumerName: {
                    type: 'string',
                    displayName: '<<hxr>>',
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<hxsj>>',
                },
            },
            properties: {
                product: {
                    type: 'object',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<fwmc>>',
                        },
                    },
                },
                member: {
                    type: 'object',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<yyrxm>>',
                        },
                        mobile: {
                            type: 'string',
                            displayName: '<<yyrsjh>>',
                        },
                    },
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<yyddh>>',
                },
                consumeUser: {
                    type: 'object',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<hxr>>',
                        },
                    },
                },
                consumeChannel: {
                    type: 'string.options.select',
                    displayName: '<<usedChannelLimit>>',
                    options: CONSUME_CHANNEL_OPYIONS,
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<czlx>>',
                    options: CONSUME_TYPE_OPTIONS,
                },
                createTime: {
                    type: 'string',
                    displayName: '<<czsj>>',
                },
                subsite: {
                    type: 'object',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<subsiteName>>',
                        },
                    },
                },
                deposit: {
                    type: 'string',
                    displayName: '<<yj_1>>',
                },
                leaseTime: {
                    type: 'string',
                    displayName: '<<zjsj>>',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<fwmc>>',
                },
                servicePlaceName: {
                    type: 'string',
                    displayName: '<<zjdd>>',
                },
                quantity: {
                    type: 'string',
                    displayName: '<<number>>',
                },
            },
        },
    },
    components: {
        ServiceReservedOrderConsumeRecordsView: {
            component: 'Viewport',
            entity: 'ServiceReservedOrderConsumeRecordsEntity',
        },
        ServiceReservedOrderConsumeRecordsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'ServiceReservedOrderConsumeRecordsFilter',
                },
                {
                    component: 'GridLayout',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'ExportDataByFilterParamsButton',
                            params: {
                                text: '<<dchxjl>>',
                                style: {
                                    width: '120px',
                                },
                                apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                apiPath:
                                    '/admin/service_reserved_activity_orders/consume_records/export',
                                requestMethod: 'get',
                                target: '_blank',
                            },
                        },
                    ],
                },
                { component: 'ServiceReservedOrderConsumeRecordsTable' },
            ],
        },
        ServiceReservedOrderConsumeRecordsFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'productName',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'subsiteId',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'memberMobile',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'consumerName',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'servicePlaceName',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'orderNo',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                    },
                },
            ],
        },
        ServiceReservedOrderConsumeRecordsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            scroll: { x: true },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'product.name',
                    width: 180,
                },
                {
                    property: 'member.name',
                    width: '100px',
                },
                {
                    property: 'member.mobile',
                },
                {
                    property: 'orderNo',
                },
                {
                    property: 'consumeUser.name',
                },
                {
                    property: 'consumeChannel',
                    width: '120px',
                },
                {
                    property: 'status',
                },
                {
                    property: 'servicePlaceName',
                },
                {
                    property: 'quantity',
                },
                {
                    property: 'createTime',
                    width: '200px',
                },
                {
                    property: 'subsite.name',
                    width: '120px',
                },
                {
                    property: 'deposit',
                    width: '100px',
                },
                // {
                //     property: 'leaseTime',
                //     width: '120px',
                // },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/service-reserved-consume-records',
                    component: 'ServiceReservedOrderConsumeRecordsView',
                    breadcrumbName: '<<mallActivityVerification>>',
                    privilege: {
                        path: 'serviceReservedOrderConsumeRecords',
                        level: 'full',
                    },
                    childRoutes: [
                        { path: '/', component: 'ServiceReservedOrderConsumeRecordsPage' },
                    ],
                },
            ],
        },
    ],
};
