import React from 'react';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { ButtonProps } from 'antd/lib/button';
import { Modal as AntModal, message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { get, map, isNil } from 'lodash';
import { EntityButtonProps } from '../../../components';
import { ProductsSelectType } from '../../../components/products-selector-plus';
import { language } from '@comall-backend-builder/core/lib/services';

export enum SupApportionType {
    //促销承担方式
    NONE = 'NONE', // 无
    RPICE_DIFFERENCE = 'RPICE_DIFFERENCE', // 售价差
}
export enum limitType {
    // 限购方式
    PER = 'PER', // 每货品限购
    MORE = 'MORE', //多货品限购
}
export enum activityStatus {
    NOTSTARTED = 'NOTSTARTED',
    STARTED = 'STARTED',
    COMPLETE = 'COMPLETE',
}
/**
 * 批量操作功能
 * salePrice: 批量导入价格
 * saleStock: 批量导入库存
 */
enum batchType {
    salePrice = 'batchSalePrice',
    saleStock = 'batchSaleStock',
}
const activityStatusOptions = [
    {
        id: activityStatus.NOTSTARTED,
        name: '<<notStarted>>',
    },
    {
        id: activityStatus.STARTED,
        name: '<<inprogress>>',
    },
    {
        id: activityStatus.COMPLETE,
        name: '<<end>>',
    },
];

/**
 * 数据处理结果
 */
export enum FileStatus {
    /**
     * 正在处理
     */
    PROCESSING = 'PROCESSING',
    /**
     * 处理完成
     */
    PROCESS_FINISHED = 'PROCESS_FINISHED',
    /**
     * 处理失败（系统异常）
     */
    PROCESS_FAILED = 'PROCESS_FAILED',
}

const FILE_STATUS_OPTIONS = [
    {
        id: FileStatus.PROCESSING,
        name: '<<processing>>',
    },
    {
        id: FileStatus.PROCESS_FINISHED,
        name: '<<processFinished>>',
    },
    {
        id: FileStatus.PROCESS_FAILED,
        name: '<<processFailed>>',
    },
];

let goBackStatus = false;
let timer: any;

export const config: Config = {
    entities: {
        TimeLimitActivityEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/timelimitActivity',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                subsiteName: {
                    type: 'object.option.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>' },
                },

                frontRuleStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: activityStatusOptions,
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
            },
            properties: {
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                perPersonLimitNum: {
                    type: 'number',
                    displayName: '<<perPersonLimitNum>>',
                },
                perOrderLimitNum: {
                    type: 'number',
                    displayName: '<<everyLimit>>',
                },
                startTime: {
                    type: 'string',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string',
                    displayName: '<<endTime>>',
                },
                supApportionType: {
                    type: 'string.options.radio',
                    displayName: '<<supApportionType>>',
                    options: [
                        { id: SupApportionType.NONE, name: '<<none>>' },
                        { id: SupApportionType.RPICE_DIFFERENCE, name: '<<priceDiff>>' },
                    ],
                },
                supApportionRatio: {
                    type: 'number',
                    displayName: '<<supApportionRatio>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                    controlConfig: {
                        placeholder: '<<subsiteNameRequired>>',
                    },
                },
                frontRuleStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: activityStatusOptions,
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                { required: true },
                                {
                                    validator: (_rule: any, value: any, callback: any) => {
                                        if (value && !value.trim()) {
                                            return callback(
                                                language.getText('activityNameNotEmpty')
                                            );
                                        }
                                        callback();
                                    },
                                },
                            ],

                            controlConfig: {
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                                style: {
                                    width: 386,
                                },
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                style: {
                                    width: 386,
                                },
                            },
                        },
                    },
                },
                ruleConfig: {
                    type: 'object.subForm',
                    displayName: '<<ruleSetting>>',
                    properties: {
                        supApportionType: {
                            type: 'string.options.radio',
                            displayName: '<<supApportionType>>',
                            rules: [{ required: true }],
                            defaultValue: SupApportionType.NONE,
                            options: [
                                { id: SupApportionType.NONE, name: '<<none>>' },
                                { id: SupApportionType.RPICE_DIFFERENCE, name: '<<priceDiff>>' },
                            ],
                        },
                        supApportionRatio: {
                            type: 'number.percentage',
                            displayName: '<<supApportionRatio>>',
                            rules: [
                                { required: true, message: '<<supApportionRadio>>' },
                                {
                                    validator: (rule: any, value: any, callback: any) => {
                                        if (!/^([0-1])(\.\d{1,4})?$/.test(String(value))) {
                                            return callback(language.getText('supportZeroToOne'));
                                        }
                                        callback();
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<pleaseInputNumber>>',
                                style: { width: '150px' },
                                max: 1,
                                min: 0,
                            },
                        },
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<activityMerchant>>',
                            rules: [{ required: true, type: 'array' }],
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],

                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.ruleConfig?.subsites?.length &&
                                        row.ruleConfig.subsites
                                            .map((item: any) => item.id)
                                            .join(',');
                                    return { subsiteIds };
                                },
                            },
                        },
                        goods: {
                            type: 'array.SaleProducts',
                            displayName: '<<activityProduct>>',
                            controlConfig: {
                                filterFields: [
                                    'activityProductTypes',
                                    'keywords',
                                    'subsiteId',
                                    'merchantId',
                                ],
                                getParams(props: any) {
                                    const { row } = props;
                                    const subsiteIds =
                                        row.ruleConfig && row.ruleConfig.subsites
                                            ? map(row.ruleConfig.subsites, 'id')
                                            : null;
                                    const shelfStatus = 'ALL';
                                    return { subsiteIds, shelfStatus };
                                },
                                needSubsites: true,
                                selectTypes: [
                                    ProductsSelectType.APPOINT_PRODUCT,
                                    ProductsSelectType.IMPORT,
                                ],
                                bathTypeConfig: [batchType.salePrice, batchType.saleStock],
                                importConfig: {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                    apiPath: '/admin/timelimit/data_parse_by_excel',
                                    importFileSource: {
                                        apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
                                        apiPath: '/admin/files/locations/36/upload',
                                    },
                                    templateSource: {
                                        apiRoot: `${ENV.AUTH_API_ROOT}`,
                                        apiPath: '/WEB-API/admin/timelimit/download_template',
                                    },
                                },
                            },
                        },
                    },
                },
                limitConfig: {
                    type: 'object.subForm',
                    displayName: '<<limitSet>>',
                    properties: {
                        limitType: {
                            type: 'string.options.radio',
                            displayName: '<<limitType>>',
                            rules: [{ required: true }],
                            defaultValue: limitType.PER,
                            options: [
                                { id: limitType.PER, name: '<<singleLimitProduct>>' },
                                { id: limitType.MORE, name: '<<moreLimitProduct>>' },
                            ],
                        },
                        perOrderLimitNum: {
                            displayName: '<<everyLimit>>',
                            type: 'number.integer',
                            rules: [{ required: true, message: '<<limitNumberRequired>>' }],
                            controlConfig: {
                                addonafter: '<<zhang>>',
                                placeholder: '<<notLimitZero>>',
                                style: { width: '150px' },
                                max: 999999,
                                min: 0,
                            },
                        },
                        perPersonLimitNum: {
                            displayName: '<<perPersonLimitNum>>',
                            type: 'number.integer',
                            rules: [{ required: true, message: '<<limitNumberRequired>>' }],
                            controlConfig: {
                                placeholder: '<<notLimitZero>>',
                                style: { width: '150px' },
                                max: 999999,
                                min: 0,
                            },
                        },
                    },
                },
            },
        },
        importResultDownloadEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
            apiPath: '/admin/download',
            properties: {
                id: {
                    type: 'string',
                },
                fileName: {
                    type: 'string',
                    displayName: '<<fileName>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<createTimeStr>>',
                },
                processEndTime: {
                    type: 'string',
                    displayName: '<<processEndTime>>',
                },
                processStatus: {
                    type: 'string.options.select',
                    displayName: '<<processStatus>>',
                    options: FILE_STATUS_OPTIONS,
                },
            },
        },
    },
    components: {
        TimeLimitActivityView: {
            component: 'Viewport',
            entity: 'TimeLimitActivityEntity',
        },
        TimeLimitActivityPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'TimeLimitActivityFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'horizontal',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'Button',
                            text: '<<addTimelimitActivity>>',
                            type: 'primary',
                            icon: 'plus',
                            route: '/timelimit-activity/add',
                            style: { flex: 0 },
                        },
                    ],
                },
                { component: 'TimeLimitActivityTable' },
            ],
        },
        TimeLimitActivityFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<activityNameRequired>>',
                    },
                },
                {
                    property: 'subsiteName',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'frontRuleStatus',
                    controlConfig: {
                        style: { width: 100 },
                    },
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                        placeholder: ['<<selectStartTime>>', '<<selectEndTime>>'],
                    },
                },
            ],
        },
        AddTimeLimitActivityPage: {
            component: 'Card',
            content: { component: 'AddTimeLimitActivityForm' },
        },
        AddTimeLimitActivityForm: {
            component: 'CreateFormPlus',
            entity: 'TimeLimitActivityEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'baseInfo.name',
                },
                {
                    property: 'baseInfo.dateRange',
                },
                {
                    property: 'ruleConfig.supApportionType',
                    visible: (values: any) => {
                        return ENV.TENANT === 'xhd';
                    },
                },
                {
                    property: 'ruleConfig.supApportionRatio',
                    visible: (values: any) => {
                        return (
                            get(values, 'ruleConfig.supApportionType') ===
                                SupApportionType.RPICE_DIFFERENCE && ENV.TENANT === 'xhd'
                        );
                    },
                },
                {
                    property: 'ruleConfig.subsites',
                },
                {
                    property: 'ruleConfig.marketingSchedule',
                },
                {
                    property: 'ruleConfig.goods',
                },
                {
                    property: 'limitConfig.limitType',
                },
                {
                    property: 'limitConfig.perOrderLimitNum',
                },
                {
                    property: 'limitConfig.perPersonLimitNum',
                },
            ],
            onValidate: (entity: any) => {
                let flag = true;
                let goods = get(entity, 'ruleConfig.goods');

                if (goods && goods.length) {
                    for (const item of goods) {
                        if (isNil(item.salePrice)) {
                            flag = false;
                            AntMessage.error(language.getText('salePriceNotEmpty'));
                            break;
                        }
                        if (isNil(item.saleStock)) {
                            flag = false;
                            AntMessage.error(language.getText('stockNotEmpty'));
                            break;
                        }
                    }
                }
                if (!flag) {
                    return false;
                }
                goBackStatus = false;
                timer = setTimeout(() => {
                    if (!goBackStatus) {
                        goBackStatus = true;
                        // 弹出提示框，返回上一页
                        let contentBefore = React.createElement(
                            'span',
                            null,
                            language.getText('activitySubmitToViewData')
                        );
                        let contentAfter = React.createElement(
                            'span',
                            null,
                            language.getText('view')
                        );
                        let link = React.createElement(
                            'a',
                            {
                                href: '/#/report-download-center',
                                target: '_blank',
                            },
                            language.getText('dataExportAndImport')
                        );
                        let content = React.createElement('div', {}, [
                            contentBefore,
                            link,
                            contentAfter,
                        ]);
                        AntModal.info({
                            content: content,
                            onOk: () => {
                                AntModal.destroyAll();
                                AntMessage.success(services.language.getText('common.saveSuccess'));
                                services.behaviorHandle({
                                    route: 'goBack',
                                });
                            },
                        });
                    }
                }, 10000);
                return true;
            },
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: (fields: any, submitType: any, entity: any) => {
                if (!goBackStatus) {
                    goBackStatus = true;
                    // 弹出提示框，返回上一页
                    let contentBefore = React.createElement(
                        'span',
                        null,
                        `${language.getText('bmitEdit')}${
                            entity.created ? entity.created.totalCount : ''
                        }${language.getText('bmitEditContent1')},${language.getText(
                            'bmitEditContent2'
                        )}${entity.created ? entity.created.successCount : ''}${language.getText(
                            'bmitEditContent3'
                        )},${entity.created ? entity.created.failCount : ''}${language.getText(
                            'bmitEditContent4'
                        )}`
                    );
                    let contentAfter = React.createElement('span', null, language.getText('view'));
                    let link = React.createElement(
                        'a',
                        {
                            href: '/#/report-download-center',
                            target: '_blank',
                        },
                        language.getText('dataExportAndImport')
                    );
                    let content = React.createElement('div', {}, [
                        contentBefore,
                        link,
                        contentAfter,
                    ]);
                    AntModal.info({
                        content: content,
                        onOk: () => {
                            AntModal.destroyAll();
                            AntMessage.success(services.language.getText('common.saveSuccess'));
                            services.behaviorHandle({
                                route: 'goBack',
                            });
                        },
                    });
                }
                if (timer) {
                    clearTimeout(timer);
                }
            },
            onSubmitError: () => {
                if (timer) {
                    clearTimeout(timer);
                }
            },
        },
        EditTimeLimitActivityPage: {
            component: 'Card',
            content: { component: 'EditTimeLimitActivityForm' },
        },
        EditTimeLimitActivityForm: {
            component: 'ModifyFormPlus',
            entity: 'TimeLimitActivityEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'baseInfo.name',
                },
                {
                    property: 'baseInfo.dateRange',
                },
                {
                    property: 'ruleConfig.supApportionType',
                    visible: (values: any) => {
                        return ENV.TENANT === 'xhd';
                    },
                },
                {
                    property: 'ruleConfig.supApportionRatio',
                    visible: (values: any) => {
                        return (
                            get(values, 'ruleConfig.supApportionType') ===
                                SupApportionType.RPICE_DIFFERENCE && ENV.TENANT === 'xhd'
                        );
                    },
                },
                {
                    property: 'ruleConfig.subsites',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'ruleConfig.marketingSchedule',
                },
                {
                    property: 'ruleConfig.goods',
                },
                {
                    property: 'limitConfig.limitType',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'limitConfig.perOrderLimitNum',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'limitConfig.perPersonLimitNum',
                    controlConfig: {
                        disabled: true,
                    },
                },
            ],
            onValidate: (entity: any) => {
                let flag = true;
                let goods = get(entity, 'ruleConfig.goods');

                if (goods && goods.length) {
                    for (const item of goods) {
                        if (isNil(item.salePrice)) {
                            flag = false;
                            AntMessage.error(language.getText('salePriceNotEmpty'));
                            break;
                        }
                        if (isNil(item.saleStock)) {
                            flag = false;
                            AntMessage.error(language.getText('stockNotEmpty'));
                            break;
                        }
                    }
                }
                if (!flag) {
                    return false;
                }
                goBackStatus = false;
                timer = setTimeout(() => {
                    if (!goBackStatus) {
                        goBackStatus = true;
                        // 弹出提示框，返回上一页
                        let contentBefore = React.createElement(
                            'span',
                            null,
                            language.getText('activitySubmitToViewData')
                        );
                        let contentAfter = React.createElement(
                            'span',
                            null,
                            language.getText('view')
                        );
                        let link = React.createElement(
                            'a',
                            {
                                href: '/#/report-download-center',
                                target: '_blank',
                            },
                            language.getText('dataExportAndImport')
                        );
                        let content = React.createElement('div', {}, [
                            contentBefore,
                            link,
                            contentAfter,
                        ]);
                        AntModal.info({
                            content: content,
                            onOk: () => {
                                AntModal.destroyAll();
                                AntMessage.success(services.language.getText('common.saveSuccess'));
                                services.behaviorHandle({
                                    route: 'goBack',
                                });
                            },
                        });
                    }
                }, 10000);
                return true;
            },
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: (fields: any, submitType: any, entity: any) => {
                if (!goBackStatus) {
                    goBackStatus = true;
                    // 弹出提示框，返回上一页
                    let contentBefore = React.createElement(
                        'span',
                        null,
                        `${language.getText('bmitEdit')}${
                            entity.created ? entity.created.totalCount : ''
                        }${language.getText('bmitEditContent1')}，${language.getText(
                            'bmitEditContent2'
                        )}${entity.created ? entity.created.successCount : ''}${language.getText(
                            'bmitEditContent3'
                        )}，${entity.created ? entity.created.failCount : ''}${language.getText(
                            'bmitEditContent4'
                        )}`
                    );
                    let contentAfter = React.createElement('span', null, language.getText('view'));
                    let link = React.createElement(
                        'a',
                        {
                            href: '/#/report-download-center',
                            target: '_blank',
                        },
                        language.getText('dataExportAndImport')
                    );
                    let content = React.createElement('div', {}, [
                        contentBefore,
                        link,
                        contentAfter,
                    ]);
                    AntModal.info({
                        content: content,
                        onOk: () => {
                            AntModal.destroyAll();
                            AntMessage.success(services.language.getText('common.saveSuccess'));
                            services.behaviorHandle({
                                route: 'goBack',
                            });
                        },
                    });
                }
                if (timer) {
                    clearTimeout(timer);
                }
            },
            onSubmitError: () => {
                if (timer) {
                    clearTimeout(timer);
                }
            },
        },
        importResultPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'Tabs',
                    type: 'card',
                    cache: false,
                    animated: false,
                    items: [
                        {
                            title: '<<downloadData>>',
                            content: {
                                component: 'importResultDownload',
                            },
                        },
                    ],
                },
            ],
        },

        importResultDownload: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'importResultDownloadEntity',
            items: [
                {
                    component: 'importResultDownloadTable',
                },
            ],
        },

        importResultDownloadTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'fileName',
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'processEndTime',
                },
                {
                    property: 'processStatus',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 260,
                items: [
                    {
                        type: 'component',
                        component: 'EntityButton',
                        config: {
                            props: {
                                type: 'link',
                                disabled: (entityButtonProps: EntityButtonProps) =>
                                    entityButtonProps.row.processStatus !==
                                    FileStatus.PROCESS_FINISHED,
                                children: '<<downloadData>>',
                            },
                            propsHandler(
                                buttonProps: ButtonProps,
                                entityButtonProps: EntityButtonProps
                            ): ButtonProps {
                                return {
                                    ...buttonProps,
                                    download: entityButtonProps.row.fileName,
                                    href: entityButtonProps.row.filePath,
                                };
                            },
                        },
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/timelimit-activity',
                    component: 'TimeLimitActivityView',
                    breadcrumbName: '<<timelimitActivity>>',
                    privilege: {
                        path: 'timelimitActivity',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'AddTimeLimitActivityPage',
                            breadcrumbName: '<<addTimelimitActivity>>',
                        },
                        {
                            path: '/import-result/:businessType/:businessId',
                            component: 'importResultPage',
                            breadcrumbName: '<<importResult>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'EditTimeLimitActivityPage',
                            breadcrumbName: '<<editTimelimitActivity>>',
                        },
                        { path: '/', component: 'TimeLimitActivityPage' },
                    ],
                },
            ],
        },
    ],
};
