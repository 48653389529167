import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { remove, forEach, map, find, get } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Modal } from 'antd';
import { Brand } from './select-product-brands';
import { language } from '@comall-backend-builder/core/lib/services';

const TableComponentId = 'ProductBrandsSelectorTable';
let Entity: any = {};
let entity: any = {};
/**
 * 选择品牌
 */
class productBrandsSelector extends PureComponent<{
    selectValues: Brand[];
    onChange: (brands: Brand[]) => void;
    requestStatus: string;
    params?: any;
    type?: 'checkbox' | 'radio';
}> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('ProductBrandsSelectorEntity');
        entity = new Entity({});
    }
    dispatch: any = {};
    state = {
        visible: false,
    };

    selectedRows = new Array<Brand>();

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(entity));
    }

    componentWillReceiveProps(nextProps: any) {
        if (nextProps.requestStatus === 'success' && this.props.requestStatus === 'pending') {
            const selectedRows = this.selectedRows;
            if (selectedRows && selectedRows.length > 0) {
                setTimeout(() => {
                    this.dispatch(
                        actions.tableRowSelectionChangeAction(
                            TableComponentId,
                            map(selectedRows, (value) => {
                                return value.id + '';
                            })
                        )
                    );
                }, 300);
            } else {
                this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
            }
        }
    }

    onTableRowSelect = (record: Brand, selected: boolean) => {
        const { type } = this.props;
        if (selected) {
            if ('radio' === type) {
                this.selectedRows = [record];
            } else {
                if (find(this.selectedRows, { id: record.id })) {
                    return;
                }
                this.selectedRows.push(record);
            }
        } else {
            remove(this.selectedRows, (i) => {
                return i.id === record.id;
            });
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: Brand[], unSelectedRows: any) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            map(unSelectedRows, (row) => {
                remove(this.selectedRows, (i) => {
                    return i.id === row.id;
                });
            });
        }
    };

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
    };

    onOk = () => {
        const { onChange } = this.props;
        if (onChange) {
            forEach(this.selectedRows, (brand) => {
                brand.select = true;
            });

            onChange(this.selectedRows);
            this.toggleModal();
        }
    };

    getConfig = () => {
        const { params, selectValues, type } = this.props;

        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: params || {},
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'right' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    fields: [
                        {
                            property: 'name',
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    rowSelection: {
                        type: type ? type : 'checkbox',
                        selectedRowKeys: map(selectValues, (value) => {
                            return value.id + '';
                        }),
                        onSelect: (record: Brand, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (
                            selected: boolean,
                            rows: Brand[],
                            unSelectedRows: Brand[]
                        ) => {
                            this.onTableRowSelectAll(selected, rows, unSelectedRows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    columns: [
                        {
                            property: 'id',
                        },
                        {
                            property: 'name',
                        },
                    ],
                },
            ],
        };
    };

    toggleModal = () => {
        const { visible } = this.state;
        const { params } = this.props;

        this.setState({ visible: !visible }, () => {
            if (!visible && entity.paging) {
                entity.filtersChange({
                    name: undefined,
                });
                entity.search({
                    ...entity.paging,
                    ...params,
                });
            }
        });
    };

    render() {
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 900,
            title: language.getText('selectBrand'),
            visible: this.state.visible,
            okText: language.getText('common.ok'),
            cancelText: language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };

        return (
            <div style={{ display: 'inline-block' }}>
                <Button type="link" onClick={this.toggleModal}>
                    {language.getText('selectBrand')}
                </Button>
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}

export const ProductBrandsSelector = connect((_state: any) => {
    let requestStatus = get(entity, 'requestStatus', null);
    const result = { requestStatus };
    return result;
})(productBrandsSelector);
