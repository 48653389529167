import React, { useState } from 'react';
import { Button } from 'antd';
import { services } from '@comall-backend-builder/core';

import { SelectProduct, SelectProductValue } from './select-product';

export interface MaotaiSelectProductValue {
    linkType: string;
    linkParams?: SelectProductValue;
}

export interface MaotaiSelectProductProps {
    value?: MaotaiSelectProductValue;
    contentConfig?: Record<string, any>;
    modalConfig?: Record<string, any>;
    subsiteIds?: string[];
    onChange?(value: SelectProductValue): void;
}

const language = services.language;

const DEFAULT_VALUE: MaotaiSelectProductValue = {
    linkType: '',
    linkParams: {
        productId: 0,
        goodsId: 0,
        activityId: 0,
        productName: '',
    },
};

export const MaotaiSelectProduct: React.FC<MaotaiSelectProductProps> = (props) => {
    const { value = DEFAULT_VALUE, contentConfig, modalConfig, onChange, subsiteIds } = props;
    const [visibleModal, setVisibleModal] = useState(false);

    const linkName = value.linkParams ? value.linkParams.productName : '';

    function visibleModalChange(): void {
        setVisibleModal((value) => !value);
    }

    function handleChange(linkParams: SelectProductValue): void {
        visibleModalChange();

        onChange && onChange(linkParams);
    }

    function handleShowModal(): void {
        visibleModalChange();
    }

    function handleCancel(): void {
        visibleModalChange();
    }

    return (
        <span className="link-name">
            {linkName ? (
                <span className="link-name-label">{linkName}</span>
            ) : (
                language.getText('notSelect')
            )}
            <Button type="link" className="link-type-edit" onClick={handleShowModal}>
                {linkName
                    ? language.getText('components.Tools.modify')
                    : language.getText('components.Tools.select')}
            </Button>
            <SelectProduct
                visible={visibleModal}
                value={value.linkParams}
                contentConfig={contentConfig}
                modalConfig={modalConfig}
                onChange={handleChange}
                onCancel={handleCancel}
                subsiteIds={subsiteIds}
            />
        </span>
    );
};
