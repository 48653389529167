import React, { CSSProperties } from 'react';
import { connect } from 'react-redux';
import { remove } from 'lodash';
import { FloatingActionButtonSize } from '../../applications/design/configs/decorate/floating-action-button';
import {
    FloatingActionButton,
    FloatingActionType,
    FloatingActionButtonDefalutIcons,
} from '../floating-action-buttons-setting/common';
import './index.less';
import { FloatingActionButtonsIcon } from '../../types/mode/object/floating-action-control-icon';
import { services } from '@comall-backend-builder/core';

interface FloatingActionButtonConfig {
    buttons: FloatingActionButton[];
    controlIcon: FloatingActionButtonsIcon;
    size: FloatingActionButtonSize;
    subWinNum: number;
}

interface FloatingActionButtonPreviewProps {
    config: FloatingActionButtonConfig;
}
const contorlIcon =
    'https://puzhehei.oss-cn-beijing.aliyuncs.com/global/floating-action-button/menu.png';

const sizePx = {
    [FloatingActionButtonSize.Small]: '44px',
    [FloatingActionButtonSize.Base]: '56px',
    [FloatingActionButtonSize.Big]: '64px',
};

const floatingActionButtonPreview = (props: FloatingActionButtonPreviewProps) => {
    if (!props || !props.config || !props.config.buttons) {
        return null;
    }
    const {
        config: { buttons, controlIcon, size, subWinNum = 2 },
    } = props;
    const list = [...buttons].filter((item) => item.visible);
    const [toTop] = remove(list, (item) => item.type === FloatingActionType.ToTop);
    const px = sizePx[size];
    const style = { height: px, width: px, marginTop: '16px' };
    const isBtnOpen = list.length > subWinNum;
    return (
        <div className="floating-action-button-preview">
            <img
                className="floating-action-button-preview-background-img"
                alt={services.language.getText('preview')}
                src="./images/avatar/home-preview.png"
            ></img>
            <div className="floating-action-button-preview-list">
                {renderButton(toTop, style)}
                {list.length <= subWinNum && list.map((item) => renderButton(item, style))}
                {isBtnOpen && list.length > 1 && renderControlIcon(controlIcon, style)}
            </div>
            {list.length > subWinNum && renderMenu(list)}
        </div>
    );
};

const renderButton = (button: FloatingActionButton, style: CSSProperties) => {
    if (!button || !button.visible) {
        return null;
    }
    const {
        icon: { custom, url },
        type,
    } = button;
    const imageUrl = custom ? url : FloatingActionButtonDefalutIcons[type];
    return <img alt="" style={style} src={imageUrl} />;
};
const renderControlIcon = (icon: FloatingActionButtonsIcon, style: CSSProperties) => {
    const { custom, activeUrl } = icon;
    const imageUrl = custom ? activeUrl : contorlIcon;
    return <img alt="" style={style} src={imageUrl} />;
};

const renderMenu = (buttons: FloatingActionButton[]) => {
    const iconStyle = { height: '36px', width: '36px' };
    return (
        <div className="floating-action-button-preview-menu">
            {buttons.map((button) => (
                <div className="floating-action-button-preview-menu-item">
                    {renderButton(button, iconStyle)}
                    <div className="floating-action-button-preview-menu-item-name">
                        {button.name}
                    </div>
                </div>
            ))}
        </div>
    );
};

const mapStateToProps = (_state: any, props: any) => {
    let config = null;
    const entityId = props.entity.id;
    for (var compId in _state.components) {
        const comp = _state.components[compId];
        if (
            (comp.type === 'CreateForm' || comp.type === 'EditForm') &&
            comp.entityId === entityId
        ) {
            config = comp.fields['config'];
        }
    }
    return { config };
};

export const FloatingActionButtonPreview = connect(mapStateToProps)(floatingActionButtonPreview);
