import * as React from 'react';

import { ArrayMode } from '@comall-backend-builder/types';
import { StyleGroupSetting } from './style-group';

/**
 *  规格设置
 */
export class ArrayStyleGroupMode extends ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <StyleGroupSetting {...controlInfo} />;
    }
}
