import { services } from '@comall-backend-builder/core';
const api = services.api;

const originGenerateHeaders = api.generateHeaders;
const tenantDomain = ENV.TENANT_DOMAIN || window.location.origin;

/**
 * 自定义请求 Header
 */
services.swizzle(api, {
    generateHeaders(customHeaders: any) {
        if (!customHeaders) {
            customHeaders = {};
        }
        customHeaders['X-Tenant-Domain'] = tenantDomain;
        customHeaders['Tenant-Domain'] = tenantDomain;
        return originGenerateHeaders(customHeaders);
    },
});

export interface PaginationResult<Result> {
    page: number;
    perPage: number;
    result: Result[];
    totalNum: number;
    totalPage: number;
}
