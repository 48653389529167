import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { Badge as AntBadge } from 'antd';
import { Select } from '@comall-backend-builder/components-basis';

/**
 * 状态类型
 */

type BadgeStatus = 'success' | 'error' | 'default' | 'processing' | 'warning';

// 设置color后，status无效
type StatusConfig = {
    [str: string]: {
        color?: string;
        text?: string;
        status?: BadgeStatus;
    };
};
type DisplayConfig = {
    statusConfig: StatusConfig;
    [str: string]: any;
};
export class StatusBadgeMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    getAvailableDisplayComponent(value: string, displayConfig: DisplayConfig): JSX.Element {
        const { statusConfig, row, ...props } = displayConfig;
        if (statusConfig) {
            return <AntBadge {...statusConfig[value]} {...props} />;
        } else {
            return <span {...props}>{value}</span>;
        }
    }
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        // 下拉选择框
        return <Select {...controlInfo} />;
    }
}
