import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { api, errorHandle, language } from '@comall-backend-builder/core/lib/services';
import { DatePicker, Button, Table, Select, Modal, Form } from 'antd';
import PropTypes from 'prop-types';
import './index.less';
import { services } from '@comall-backend-builder/core';
const { RangePicker } = DatePicker;

interface GetRes {
    id: string;
    rule_list: any[];
    status: string;
}
const prefix = 'remittance-manager-items';

const CommonCreateForm = Form.create({ name: 'commonForm' })(
    class extends React.Component {
        static propTypes: {
            onSearch: PropTypes.Requireable<(...args: any[]) => any>;
            subsite: any[];
        };
        handleReset = () => {
            const { form } = this.props as any;
            form.resetFields();
        };
        handleSearch = (e: any) => {
            e.preventDefault();
            const { form, onSearch } = this.props as any;
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSearch(values);
                }
            });
        };
        render() {
            const { form, subsite = [] } = this.props as any;
            const { getFieldDecorator } = form;
            return (
                <Form
                    labelCol={{ span: 4 }}
                    wrapperCol={{ span: 16 }}
                    // layout="inline"
                    ref="commonForm"
                >
                    <Form.Item label={services.language.getText('otherSelectSubsite')}>
                        {getFieldDecorator('subsite', {
                            rules: [
                                {
                                    required: true,
                                    message: services.language.getText('selectSubsite') + '!',
                                },
                            ],
                        })(
                            <Select
                                style={{ width: 280 }}
                                placeholder={services.language.getText('selectPlease')}
                            >
                                {subsite.map((item: any) => {
                                    return (
                                        <Select.Option key={item.id} value={item.id}>
                                            {item.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={services.language.getText('xzdhkddrq')}>
                        {getFieldDecorator('time', {
                            rules: [
                                {
                                    required: true,
                                    message: services.language.getText('qxzdhkddrq'),
                                },
                            ],
                        })(<RangePicker style={{ width: 280 }} />)}
                    </Form.Item>
                    <div style={{ paddingLeft: '16.7%' }}>
                        <Form.Item>
                            <Button type="primary" onClick={this.handleSearch}>
                                {services.language.getText('hqdhkddsj')}
                            </Button>
                            &nbsp;&nbsp;
                            <Button htmlType="reset" onClick={this.handleReset}>
                                {services.language.getText('common.reset')}
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            );
        }
    }
);
/**
 * 用户资料管理
 */
class remittanceManagerItem extends PureComponent<any, any> {
    state = {
        subsite: [],
        recordsData: [],
        visible: false,
        exportVisible: false,
        searchParams: {
            subsiteId: 0,
            startTime: '',
            endTime: '',
        },
        remittanceVisible: false,
        uploadModalVisible: false,
        current: 0,
        total: 0,
        pendingRemittanceInfo: {
            overseasRemittanceRecordIds: [],
            paidEndDate: '',
            paidStartDate: '',
            totalAmount: 0,
            totalQuantity: 0,
        },
        pagination: {
            page: 1,
            perPage: 10,
        },
    };

    componentDidMount() {
        this.loadSubsiteInfo();
        this.getOperatingRecords();
    }
    //获取选择的门店
    private loadSubsiteInfo = async () => {
        const subsiteListResult: any = await api.get({}, { apiPath: '/admin/v1/subsites/mine' });
        if (subsiteListResult && subsiteListResult.result && subsiteListResult.result.length > 0) {
            this.setState({
                subsite: subsiteListResult.result,
            });
        }
    };
    //导出明细
    private getExport = (i: any) => {
        api.post(
            {
                overseasRemittanceRecordIds: i.overseasRemittanceRecordIds.join(','),
            },
            {
                apiPath: '/admin/overseas_remittance_records/export',
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
            }
        ).then(
            () => {
                this.setState({
                    exportVisible: true,
                });
            },
            (error) => {
                services.errorHandle(error);
            }
        );
    };
    // 获取待汇操作历史
    private getOperatingRecords(searchParams: object = {}) {
        this.setState({ searchParams: searchParams, loading: true });
        const { pagination } = this.state;
        api.get(
            {
                ...searchParams,
                ...pagination,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                apiPath: `/admin/overseas_remittance/operating_records`,
            }
        )
            .then((data: any) => {
                if (data.result && data.result.length > 0) {
                    this.setState({
                        recordsData: data.result,
                        total: data.totalNum,
                        current: data.page,
                    });
                }
            })
            .catch((error) => {
                errorHandle(error);
            });
    }
    pageChange = (pageVal: object = {}) => {
        const { searchParams } = this.state;
        this.setState({ pagination: { ...pageVal } }, () => {
            this.getOperatingRecords(searchParams);
        });
    };
    private getTableConfig = () => {
        let config: any = {
            rowKey: 'id',
            columns: [
                {
                    title: services.language.getText('czsj'),
                    dataIndex: 'createTime',
                    key: 'createTime',
                },
                {
                    title: services.language.getText('ddzfrq'),
                    dataIndex: 'orderPaidEndDate',
                    key: 'orderPaidEndDate',
                    render: (v: string, i: any) => {
                        return `${i.orderPaidEndDate} ~ ${i.orderPaidStartDate} `;
                    },
                },
                {
                    title: services.language.getText('hzje'),
                    dataIndex: 'remittanceAmount',
                    key: 'remittanceAmount',
                },
                {
                    title: services.language.getText('common.tableAction'),
                    dataIndex: 'opt',
                    key: 'opt',
                    render: (v: string, i: any) => {
                        return (
                            // eslint-disable-next-line jsx-a11y/anchor-is-valid
                            <a
                                onClick={() => this.getExport(i)}
                                type="link"
                                // eslint-disable-next-line no-script-url
                                href="javascript:void(0)"
                            >
                                {services.language.getText('dcmx')}
                            </a>
                        );
                    },
                },
            ],
        };
        return config;
    };
    private onSearch = async (searchParams: any = {}) => {
        if (searchParams?.time) {
            let date = searchParams?.time.map((i: any) => i.format('YYYY-MM-DD'));

            const searchParam = {
                subsiteId: searchParams.subsite,
                orderPaidStartDate: date[0],
                orderPaidEndDate: date[1],
            };
            try {
                let res: any = await api.get(
                    { ...searchParam },
                    {
                        apiPath: '/admin/overseas_remittance/pending_remittance_info',
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                    }
                );
                this.setState({ pendingRemittanceInfo: res });
            } catch (error) {
                throw error;
            }
        }
    };

    private onExportClose = () => {
        this.setState({
            exportVisible: false,
        });
    };
    private onRemittance = () => {
        this.setState({
            remittanceVisible: true,
        });
    };
    private onOk = () => {
        const { pendingRemittanceInfo } = this.state;
        this.setState({
            remittanceVisible: false,
        });
        api.post(
            {
                overseasRemittanceRecordIds: pendingRemittanceInfo.overseasRemittanceRecordIds,
                paidStartDate: pendingRemittanceInfo.paidStartDate,
                paidEndDate: pendingRemittanceInfo.paidEndDate,
            },
            {
                apiPath: '/admin/overseas_remittance',
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
            }
        ).then(
            () => {
                this.getOperatingRecords();
                this.setState({
                    remittanceVisible: false,
                    visible: true,
                    pendingRemittanceInfo: {},
                });
            },
            (error) => {
                services.errorHandle(error);
            }
        );
    };
    private onClose = () => {
        this.setState({
            remittanceVisible: false,
        });
    };
    private onVisibleClose = () => {
        this.setState({
            visible: false,
        });
    };
    render() {
        const {
            recordsData,
            subsite,
            visible,
            remittanceVisible,
            total,
            current,
            pagination,
            pendingRemittanceInfo,
            exportVisible,
        } = this.state;
        let tableConfig = this.getTableConfig();
        const tableProps = { ...tableConfig, dataSource: recordsData };

        const className = {
            legend: `${prefix}-legend`,
            tips: `${prefix}-tips`,
            order: `${prefix}-order`,
            data: `${prefix}-order-data`,
            label: `${prefix}-order-data-label`,
            wrapper: `${prefix}-order-data-wrapper`,
        };
        return (
            <div className="remittance-manager-items">
                <div className={className.legend}>{services.language.getText('xzdhkddtj')}</div>

                <div className="clerk-search">
                    <CommonCreateForm onSearch={this.onSearch} subsite={subsite} />
                </div>
                <div className={className.legend}>{services.language.getText('dhkddsj')}</div>
                <div className={className.order}>
                    <div className={className.data}>
                        <div className={className.label}>
                            {services.language.getText('ddzfrq')}：
                        </div>
                        <div className={className.wrapper}>
                            {pendingRemittanceInfo.paidStartDate &&
                            pendingRemittanceInfo.paidEndDate
                                ? `${pendingRemittanceInfo.paidStartDate} ～ ${pendingRemittanceInfo.paidEndDate}`
                                : '-'}
                        </div>
                    </div>
                    <div className={className.data}>
                        <div className={className.label}>{services.language.getText('ddsl')}：</div>
                        <div className={className.wrapper}>
                            {pendingRemittanceInfo?.totalQuantity || 0}
                        </div>
                    </div>
                    <div className={className.data}>
                        <div className={className.label}>
                            {services.language.getText('dddhkzje')}：
                        </div>
                        <div className={className.wrapper}>
                            {pendingRemittanceInfo?.totalAmount || 0}
                        </div>
                    </div>
                    <div className={className.data}>
                        <div className={className.label}></div>
                        <div className={className.wrapper}>
                            {pendingRemittanceInfo?.totalQuantity > 0 ? (
                                <div className={className.tips}>
                                    {services.language.getText('hktjcghjddyxcl')}
                                </div>
                            ) : null}
                            <Button
                                type="primary"
                                onClick={this.onRemittance}
                                disabled={pendingRemittanceInfo?.totalQuantity <= 0}
                            >
                                {language.getText('dysddsqhk')}
                            </Button>
                        </div>
                    </div>
                </div>

                <div className={className.legend}>{services.language.getText('dhczls')}</div>
                <Table
                    className="remittance-manager-items-table"
                    style={{ width: '90%' }}
                    {...tableProps}
                    pagination={{
                        pageSize: pagination.perPage,
                        showQuickJumper: true,
                        total,
                        current,
                        showTotal(total: number) {
                            return services.interpolate(language.getText('total'), {
                                total,
                            });
                        },
                        onChange: (page, perPage) => {
                            this.pageChange({ page, perPage });
                        },
                        onShowSizeChange: (page, perPage) => {
                            this.pageChange({ page, perPage });
                        },
                    }}
                ></Table>
                <Modal
                    visible={remittanceVisible}
                    title={language.getText('remittance')}
                    closable={false}
                    footer={[
                        <Button key="submit" type="primary" onClick={this.onOk}>
                            {language.getText('common.ok')}
                        </Button>,
                        <Button key="back" onClick={this.onClose}>
                            {language.getText('common.cancel')}
                        </Button>,
                    ]}
                >
                    <p>
                        {language.getText('determineOn')}
                        {pendingRemittanceInfo.paidStartDate && pendingRemittanceInfo.paidEndDate
                            ? `${pendingRemittanceInfo.paidStartDate} ～ ${pendingRemittanceInfo.paidEndDate}`
                            : '-'}
                        ， {language.getText('inAll')} {pendingRemittanceInfo.totalQuantity}
                        {language.getText('remittanceIndividualOrders')}
                    </p>
                    <p>
                        {language.getText('totalAmountRemitted')}
                        {pendingRemittanceInfo.totalAmount
                            ? pendingRemittanceInfo.totalAmount.toFixed(2)
                            : 0}
                        {language.getText('yuan')}
                    </p>

                    <div className={className.tips}>
                        {services.language.getText('hktjcghjddyxcl')}
                    </div>
                    <div className={className.tips} style={{ color: 'red' }}>
                        {services.language.getText('sqtjbkch')}
                    </div>
                </Modal>
                <Modal
                    visible={visible}
                    title={language.getText('applicationSubmitted')}
                    closable={false}
                    footer={[
                        <Button key="submit" type="primary" onClick={this.onVisibleClose}>
                            {language.getText('common.ok')}
                        </Button>,
                    ]}
                >
                    <p>
                        {language.getText('applicationSubmittedAnd')}
                        <a target="_blank" href="/#/remittance-order-manager">
                            {language.getText('manageRemittance')}
                        </a>
                        {language.getText('orderDetails')}
                    </p>
                </Modal>
                <Modal
                    visible={exportVisible}
                    title=""
                    closable={false}
                    width={400}
                    footer={[
                        <Button key="submit" type="primary" onClick={this.onExportClose}>
                            {language.getText('know')}
                        </Button>,
                    ]}
                >
                    <p>
                        {language.getText('subsiteExportInfo')}
                        <a target="_blank" href="#/report-download-center">
                            {language.getText('dataExportAndImport')}
                        </a>
                        {language.getText('common.view')}
                    </p>
                </Modal>
            </div>
        );
    }
}
function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
    };
}

export const RemittanceManagerItem = connect(mapStateToProps)(remittanceManagerItem);
