import React, { PureComponent } from 'react';
import { isNull } from 'lodash';
import { CouponSelectorWithPackageDisplay } from '../../../../components/coupon-selector-with-package-display';
import { SharePageReward, EVENT_TYPE, ActivityRewardProps } from './share-page-activity-rewards';
import './index.less';
import { services } from '@comall-backend-builder/core';

const periodText = {
    DAY: 'everyDay',
    WEEK: 'everyWeek',
    MONTH: 'everyMonth',
};

/**
 * 分享有礼奖励
 */
export class SharePageActivityRewardsDisplay extends PureComponent<ActivityRewardProps> {
    renderReward = (reward: SharePageReward | null | undefined) => {
        if (!reward) return null;
        const {
            rewardItem: { point, stepValue, coupons },
            activityPeriod,
            activityPeriodRewardLimit,
            activityRewardLimit,
        } = reward;
        const limitHide =
            isNull(activityPeriod) &&
            isNull(activityPeriodRewardLimit) &&
            isNull(activityRewardLimit);
        const radioValue =
            isNull(activityPeriod) && isNull(activityPeriodRewardLimit)
                ? 'all_time'
                : 'every_reduce';
        return (
            <div className="activity-reward">
                <div className="reward-item">
                    <div className="reward-title">{services.language.getText('fxrjl')}：</div>
                    <div className="reward-step">
                        <span>
                            {reward.eventType === EVENT_TYPE.SHARE_PAGE_MEMBER_REGISTER
                                ? services.language.getText('zcyh')
                                : services.language.getText('access')}
                            {services.interpolate(services.language.getText('getAfterLottery'), {
                                stepValue,
                            })}
                        </span>
                    </div>
                    {!!point && (
                        <div className="reward-content-item">
                            <span className="reward-content-item-checkbox">
                                {services.language.getText('point')}
                            </span>
                            <span className="reward-item-text">
                                {services.interpolate(services.language.getText('sPoint'), {
                                    point,
                                })}
                            </span>
                        </div>
                    )}
                    {!!coupons && coupons.length > 0 && (
                        <div className="reward-content-item">
                            <div className="reward-content-item-checkbox">
                                {services.language.getText('yhq')}
                            </div>
                            <div className="reward-coupons">
                                <CouponSelectorWithPackageDisplay
                                    data={coupons || []}
                                    disabled
                                    disablePrice
                                    canChangeQuantity
                                    xScrollWidth={640}
                                />
                            </div>
                        </div>
                    )}
                </div>
                {!limitHide && (
                    <div className="reward-item">
                        <div className="reward-title">{services.language.getText('jlxz')}：</div>
                        <div>{services.language.getText('xdmrffjlcs')}</div>
                        <div className="reward-tip">
                            {services.language.getText('jygxxdcsygbqzfx')}
                        </div>
                    </div>
                )}
                {!limitHide && (
                    <div className="reward-item">
                        <div className="reward-title">{services.language.getText('csxz')}：</div>
                        {radioValue === 'all_time' ? (
                            <div>
                                <span>
                                    {services.interpolate(
                                        services.language.getText('xzhdyxqnmrzdffjl'),
                                        { activityRewardLimit }
                                    )}
                                </span>
                            </div>
                        ) : (
                            <div>
                                {services.language.getText('xzmr')}
                                {activityPeriod &&
                                    services.language.getText(periodText[activityPeriod])}
                                ，{services.language.getText('zdffjl')}
                                {activityPeriodRewardLimit}
                                {services.language.getText('frequency')}
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    };

    render() {
        const { value } = this.props;
        const visitor = value
            ? value.find((reward) => reward.eventType === EVENT_TYPE.SHARE_PAGE_VISITOR_ACCESS)
            : null;
        const register = value
            ? value.find((reward) => reward.eventType === EVENT_TYPE.SHARE_PAGE_MEMBER_REGISTER)
            : null;
        return (
            <div className="share-page-activity-rewards" style={{ display: 'inline-block' }}>
                {!!visitor && <div>{services.language.getText('afkfjl')}</div>}
                {!!visitor && this.renderReward(visitor)}
                {!!register && <div>{services.language.getText('axyhzcfjl')}</div>}
                {!!register && this.renderReward(register)}
            </div>
        );
    }
}
