import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { trim } from 'lodash';
import { Input as AntInput, Button as AntButton, Row as AntRow, Col as AntCol } from 'antd';
import { services } from '@comall-backend-builder/core';

class codeScanner extends PureComponent<any, any> {
    state = {
        value: '',
    };

    focusInput() {
        const pickInput: any = this.refs.pickInput;
        pickInput && pickInput.input.focus();
    }

    componentWillReceiveProps(nextProps: any) {
        const { activeKey } = this.props;
        const { activeKey: nextActiveKey } = nextProps;
        if (activeKey === nextActiveKey) {
            return;
        } else {
            this.focusInput();
        }
    }

    onSearch = (pickingListNumber: any) => {
        this.setState({
            loading: true,
        });
        const { onSearch } = this.props;
        if (onSearch) {
            onSearch('all', pickingListNumber);
        }
        this.setState({
            loading: false,
        });
    };

    onChange = (e: any) => {
        const { value } = e.target;
        this.setState({
            value: trim(value),
        });
    };

    enterHandle = () => {
        const { value } = this.state;
        if (value) {
            this.onSearch(value);
        } else {
            this.onSearch('');
        }
        this.focusInput();
    };
    render() {
        const { value } = this.state;
        let scanInputProps = {
            ref: 'pickInput',
            onPressEnter: this.enterHandle,
            value: value,
            autoFocus: true,
            placeholder: services.language.getText('smsrjhdh'),
            onChange: this.onChange,
        };
        let scanButtonProps = {
            type: 'primary',
            style: {
                width: 85,
            },
            onClick: this.enterHandle,
        };
        return (
            <AntRow className="code-scan-panel" style={{ zIndex: 2 }}>
                <AntCol span={18}>
                    <AntInput {...scanInputProps} />
                </AntCol>
                <AntCol span={6}>
                    <AntButton {...(scanButtonProps as any)}>查询</AntButton>
                </AntCol>
            </AntRow>
        );
    }
}

export const CodeScanner = connect((state, props: any) => {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
    };
})(codeScanner);
