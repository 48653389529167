import React, { PureComponent, createRef } from 'react';
import { Carousel, Icon, Button } from 'antd';
import { Column, ColumnConfig } from '@ant-design/charts';
import { TabKey } from '../index';
import './index.less';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

export enum TimeHorizon {
    RecentOneYears = 1,
    RecentThreeYears = 2,
}

interface ChartItem {
    key: string;
    value: number;
    type: string;
}
type TimeHorizonType = 'expenseRecordTimeHorizon' | 'reachStoreTimeHorizon';
interface Props {
    expenseRecordTimeHorizon: TimeHorizon;
    reachStoreTimeHorizon: TimeHorizon;
    dealRecordsChartData: ChartItem[];
    dealRecordsChartTitle: string;
    mallVisitRecordsChartData: ChartItem[];
    mallVisitRecordsChartTitle: string;
    appVisitRecordsChartData: any[];
    appVisitRecordsChartTitle: string;
    onChangeTimeHorizon: (type: TimeHorizonType, timeHorizon: TimeHorizon) => void;
    onChangeTab: (key: TabKey, pageParams?: any) => void;
}
interface State {}
export default class MemberInfoChartsCarousel extends PureComponent<Props, State> {
    private carouselRef = createRef<Carousel>();

    private onPrev = () => {
        this.carouselRef.current?.prev();
    };

    private onNext = () => {
        this.carouselRef.current?.next();
    };

    private rednerHeader = (type: TimeHorizonType, timeHorizon: TimeHorizon) => {
        const { onChangeTimeHorizon } = this.props;
        const classes = {
            head: `${prefix}__head`,
        };
        const oneType = timeHorizon === TimeHorizon.RecentOneYears ? 'primary' : 'default';
        const threeType = timeHorizon === TimeHorizon.RecentThreeYears ? 'primary' : 'default';
        return (
            <div className={classes.head}>
                <Button
                    type={oneType}
                    onClick={onChangeTimeHorizon.bind(this, type, TimeHorizon.RecentOneYears)}
                >
                    {language.getText('jyn')}
                </Button>
                <Button
                    type={threeType}
                    onClick={onChangeTimeHorizon.bind(this, type, TimeHorizon.RecentThreeYears)}
                    style={{ marginLeft: '8px' }}
                >
                    {language.getText('jsn')}
                </Button>
            </div>
        );
    };

    private getExpenseConfig: () => ColumnConfig = () => {
        const { dealRecordsChartData } = this.props;
        const isValid = dealRecordsChartData.some((i) => i.value);
        return {
            data: dealRecordsChartData,
            autoFit: true,
            isStack: true,
            xField: 'key',
            yField: 'value',
            seriesField: 'type',
            colorField: 'type',
            maxColumnWidth: 20,
            minColumnWidth: 20,
            legend: {
                position: 'top-right',
                layout: 'horizontal',
            },
            tooltip: {
                formatter: (datum) => {
                    return {
                        name: datum.type || language.getText('xfje'),
                        value: datum.value,
                    };
                },
            },
            yAxis: isValid
                ? undefined
                : {
                      nice: true,
                      max: 8000,
                  },
        };
    };

    private renderExpenseRecord = () => {
        const {
            onChangeTab,
            dealRecordsChartTitle,
            expenseRecordTimeHorizon,
            dealRecordsChartData,
        } = this.props;
        const config = this.getExpenseConfig();
        const isValid = dealRecordsChartData.some((i) => i.value);
        return (
            <div className={chartClasses.chart}>
                <div className={chartClasses.chartTitle}>
                    <div className={chartClasses.titleText}>
                        {language.getText('xfje')}
                        <span className={chartClasses.titleTextSecondary}>
                            ({language.getText('jdwy')})
                        </span>
                    </div>
                    <div className={chartClasses.titleRight}>
                        <span className={chartClasses.titleRightText}>{dealRecordsChartTitle}</span>
                        <Button
                            type="link"
                            onClick={onChangeTab.bind(this, TabKey.ExpenseRecord, {
                                timeHorizon: expenseRecordTimeHorizon,
                            })}
                        >
                            {language.getText('common.view')}
                        </Button>
                    </div>
                </div>
                <div className={chartClasses.chartMain}>
                    <Column {...config} />
                    {this.renderEmpty(isValid)}
                </div>
            </div>
        );
    };

    private getReachStore: () => ColumnConfig = () => {
        const { mallVisitRecordsChartData } = this.props;
        const isValid = mallVisitRecordsChartData.some((i) => i.value);
        return {
            data: mallVisitRecordsChartData,
            autoFit: true,
            isStack: true,
            xField: 'key',
            yField: 'value',
            seriesField: 'type',
            colorField: 'type',
            maxColumnWidth: 20,
            minColumnWidth: 20,
            legend: {
                position: 'top-right',
                layout: 'horizontal',
            },
            tooltip: {
                formatter: (datum) => {
                    return {
                        name: datum.type || language.getText('ddts'),
                        value: datum.value,
                    };
                },
            },
            yAxis: isValid
                ? undefined
                : {
                      nice: true,
                      max: 30,
                  },
        };
    };

    private renderReachStore = () => {
        const { mallVisitRecordsChartTitle, mallVisitRecordsChartData } = this.props;
        const config = this.getReachStore();
        const isValid = mallVisitRecordsChartData.some((i) => i.value);
        return (
            <div className={chartClasses.chart}>
                <div className={chartClasses.chartTitle}>
                    <div className={chartClasses.titleText}>{language.getText('ddts')}</div>
                    <div className={chartClasses.titleRight}>
                        <span className={chartClasses.titleRightText}>
                            {mallVisitRecordsChartTitle}
                        </span>
                    </div>
                </div>
                <div className={chartClasses.chartMain}>
                    <Column {...config} />
                    {this.renderEmpty(isValid)}
                </div>
            </div>
        );
    };

    private getVisitRecord: () => ColumnConfig = () => {
        const { appVisitRecordsChartData } = this.props;
        const isValid = appVisitRecordsChartData.some((i) => i.visitNumber);
        return {
            data: appVisitRecordsChartData,
            autoFit: true,
            xField: 'visitInterval',
            yField: 'visitNumber',
            colorField: 'visitInterval',
            color: '#6395FA',
            maxColumnWidth: 20,
            minColumnWidth: 20,
            appendPadding: [0, 16, 0, 0],
            xAxis: {
                label: {
                    formatter: (text: string) => {
                        return text.split('-').join('\n');
                    },
                },
            },
            yAxis: isValid
                ? undefined
                : {
                      nice: true,
                      max: 20,
                  },
            meta: {
                visitInterval: {
                    alias: services.language.getText('date'),
                },
                visitNumber: {
                    alias: language.getText('cs_1'),
                },
            },
        };
    };

    private renderVisitRecord = () => {
        const { mallVisitRecordsChartTitle, appVisitRecordsChartData } = this.props;
        const config = this.getVisitRecord();
        const isValid = appVisitRecordsChartData.some((i) => i.visitNumber);
        return (
            <div className={chartClasses.chart}>
                <div className={chartClasses.chartTitle}>
                    <div className={chartClasses.titleText}>{language.getText('zfwcs')}</div>
                    <div className={chartClasses.titleRight}>
                        <span className={chartClasses.titleRightText}>
                            {mallVisitRecordsChartTitle}
                        </span>
                    </div>
                </div>
                <div className={chartClasses.chartMainMax}>
                    <Column {...config} />
                    {this.renderEmpty(isValid)}
                </div>
            </div>
        );
    };

    private renderEmpty = (isValid: boolean) => {
        if (isValid) {
            return null;
        }
        return <div className={chartClasses.chartMainEmpty}>{language.getText('notData')}</div>;
    };

    private renderExpenseRecordSlide = () => {
        const { expenseRecordTimeHorizon } = this.props;
        return (
            <div className={chartClasses.slide}>
                {this.rednerHeader('expenseRecordTimeHorizon', expenseRecordTimeHorizon)}
                {this.renderExpenseRecord()}
            </div>
        );
    };

    private renderReachStoreSlide = () => {
        const { reachStoreTimeHorizon } = this.props;
        return (
            <div className={chartClasses.slide}>
                {this.rednerHeader('reachStoreTimeHorizon', reachStoreTimeHorizon)}
                {this.renderReachStore()}
            </div>
        );
    };

    private renderVisitRecordSlide = () => {
        return <div className={chartClasses.slide}>{this.renderVisitRecord()}</div>;
    };
    render() {
        return (
            <div className={prefix}>
                <div className={chartClasses.title}>{language.getText('zhhyd')}</div>
                <div className={chartClasses.container}>
                    <div className={chartClasses.left} onClick={this.onPrev}>
                        <Icon type="left" />
                    </div>
                    <div className={chartClasses.main}>
                        <Carousel ref={this.carouselRef} autoplay={false} dots={false}>
                            {this.renderExpenseRecordSlide()}
                            {this.renderReachStoreSlide()}
                            {this.renderVisitRecordSlide()}
                        </Carousel>
                    </div>
                    <div className={chartClasses.right} onClick={this.onNext}>
                        <Icon type="right" />
                    </div>
                </div>
            </div>
        );
    }
}

const prefix = 'member-info-charts-carousel';
const chartClasses = {
    title: `${prefix}__title`,
    container: `${prefix}__container`,
    left: `${prefix}__left`,
    main: `${prefix}__main`,
    right: `${prefix}__right`,
    slide: `${prefix}__main__slide`,
    chart: `${prefix}__chart`,
    chartTitle: `${prefix}__chart__title`,
    titleText: `${prefix}__chart__title__text`,
    titleTextSecondary: `${prefix}__chart__title__text__secondary`,
    titleRight: `${prefix}__chart__title__right`,
    titleRightText: `${prefix}__chart__title__right__text`,
    chartMain: `${prefix}__chart__main`,
    chartMainMax: `${prefix}__chart__main ${prefix}__chart__main--max`,
    chartMainEmpty: `${prefix}__chart__main__empty`,
};
