import React from 'react';
import './index.less';

export class WechatCardManagePreview extends React.Component {
    render() {
        const previewImage = require('./preview.png');
        return (
            <div className={prefix}>
                <img className={`${prefix}__img`} src={previewImage} alt="" />
            </div>
        );
    }
}

const prefix = 'wechat-card-manage-preview';
