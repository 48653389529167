import React, { Fragment, PureComponent } from 'react';
import { message as AntMessage } from 'antd';
import forEach from 'lodash/forEach';
import { Button } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core';
import { UploadFileModal, GoodsSelectorModal } from '../../../components';
const api = services.api;

export class PointDeductionProductButton extends PureComponent<any> {
    state = { uploadModalVisible: false, goodsModalVisible: false };

    handleUpload = () => {
        this.setState({ uploadModalVisible: true });
    };
    onClose = () => {
        this.setState({ uploadModalVisible: false });
    };

    handleAddGoods = () => {
        this.setState({ goodsModalVisible: true });
    };
    onCloseGoodsModal = () => {
        const { entity } = this.props;
        this.setState({ goodsModalVisible: false });
        entity.search();
    };
    onAddGoods = (goods: any) => {
        let goodsIds: any = [];
        forEach(goods, (good: any) => {
            goodsIds.push(good.id);
        });
        api.post(
            { goodsIds },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: '/admin/deduction_product',
            }
        ).then(
            () => {
                AntMessage.success(services.language.getText('addSuccess'));
                this.onCloseGoodsModal();
            },
            (error) => {
                services.errorHandle(error);
            }
        );
    };
    render() {
        const { entity } = this.props;
        const { uploadModalVisible } = this.state;
        const uploadFileProps = {
            entity: entity,
            visible: uploadModalVisible,
            onClose: this.onClose,
            ...this.props,
            params: {
                apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
                apiPath: '/admin/files/locations/63/upload',
                text: services.language.getText('importProduct'),
                templateSource: {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                    apiPath: '/admin/deduction_product/export.xlsx',
                },
            },
        };

        return (
            <Fragment>
                <div className="batch-button">
                    <GoodsSelectorModal
                        modalTitle={services.language.getText('select')}
                        buttonText={services.language.getText('addProduct')}
                        icon="plus"
                        type="primary"
                        onChange={this.onAddGoods}
                    />
                    <Button
                        type="default"
                        style={{
                            marginLeft: 15,
                            paddingLeft: '15px',
                            paddingRight: '15px',
                        }}
                        onClick={this.handleUpload}
                    >
                        {services.language.getText('importProduct')}
                    </Button>
                </div>
                <UploadFileModal {...uploadFileProps} />
            </Fragment>
        );
    }
}
