import React, { PureComponent } from 'react';
import { Menu } from 'antd';
import BaseView from './components/base';
import SecurityView from './components/security';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
const { Item } = Menu;
interface AccountSettingState {
    selectKey: string;
    menuMap: {
        [key: string]: string;
    };
}
export class AccountSetting extends PureComponent<any, AccountSettingState> {
    constructor(props: any) {
        super(props);
        const menuMap = { base: language.getText('baseSetting'), security: '安全设置' };
        this.state = {
            menuMap,
            selectKey: 'base',
        };
    }
    getMenu = () => {
        const { menuMap } = this.state;
        return Object.keys(menuMap).map((item) => <Item key={item}>{menuMap[item]}</Item>);
    };
    selectKey = (key: string) => {
        this.setState({
            selectKey: key,
        });
    };
    getRightTitle = () => {
        const { selectKey, menuMap } = this.state;
        return menuMap[selectKey];
    };
    renderChildren = () => {
        const { selectKey } = this.state;
        switch (selectKey) {
            case 'base':
                return <BaseView />;
            case 'security':
                return <SecurityView />;
            default:
                break;
        }

        return null;
    };
    render() {
        const { selectKey } = this.state;
        return (
            <div className="account-setting">
                <div className="left-menu">
                    <Menu
                        mode={'inline'}
                        selectedKeys={[selectKey]}
                        onClick={({ key }) => this.selectKey(key)}
                    >
                        {this.getMenu()}
                    </Menu>
                </div>
                <div className="right-content">{this.renderChildren()}</div>
            </div>
        );
    }
}
