import React, { PureComponent } from 'react';
import { MessageModal } from '@comall-backend-builder/components-basis';
import { api, language } from '@comall-backend-builder/core/lib/services';
import {
    GetPrintOrders,
    PickingLists,
    GetReceiptInfo,
    ReceiptInfo,
} from '../../../services/order-print';
import { Countdown } from '../../../components/order-picking-assistant/countdown/index';
import { PickOrdersTemplate, PickReceiptTemplate } from '../../../components';
import { ORDER_STATUS, ORDER_STATUS_ENUM } from '../../../services/constant';

import './index.less';
import { services } from '@comall-backend-builder/core';

/**
 * 拣货单列表项
 */
interface OrderListInfo {
    /**
     * 倒计时
     */
    countdown: {
        /**
         * 倒计时时间(秒)
         */
        pickTime: string;
        /**
         * 倒计时总时间
         */
        totalPickTime: string;
        /**
         * 是否超时
         */
        countdownType: 'NO_TIMEOUT' | 'TIMEOUT';
    };
    /**
     * 结束拣货时间
     */
    finishPickTime: string;
    /**
     * 拣货单ID
     */
    id: string;
    /**
     * 拣货单编号
     */
    pickingListNo: string;
    /**
     * 关联订单集合
     */
    pickingListOrders: {
        orderId: string;
        orderNo: string;
        remark: string;
    }[];
    /**
     * 开始拣货时间
     */
    startPickTime: string;
    /**
     * 拣货单状态
     */
    status: ORDER_STATUS_ENUM;
    /**
     * 拣货单门店及专柜信息
     */
    subsite: {
        id: string;
        name: string;
        merchant: {
            id: string;
            name: string;
        };
    };
    /**
     * 是否缺货配单
     */
    lack: boolean;
    /**
     * 最后操作人的信息
     */
    user: {
        id: string;
        name: string;
    };
}

interface OrderPickItemStates {
    /**
     * 是否正在打印
     */
    printLoading: boolean;
    /**
     * 倒计时毫秒
     */
    time: number;
    /**
     * 倒计时标题
     */
    title: string;
    /**
     * 倒计时状态
     */
    status: string;
    /**
     * 倒计时颜色
     */
    color: string;
    /**
     * 总拣货时间
     */
    totalPickTime: number;
    /**
     * 打印模版
     */
    pickingListTemplate: JSX.Element;
    /**
     * 拣货单打印数据
     */
    orderPrintData: PickingLists[];
    /**
     * 小票打印数据
     */
    receiptData: ReceiptInfo[];
}

interface OrderPickItemProps extends OrderListInfo {
    /**
     * 其他参数
     */
    parentProps: any;
}

/**
 * 拣货小助手 --- 待拣货、拣货中 列表项模板组件
 */
export class OrderPickItem extends PureComponent<OrderPickItemProps, OrderPickItemStates> {
    state = {
        printLoading: false,
        time: 0,
        title: '',
        status: 'disabled',
        color: '',
        pickingListTemplate: <p></p>,
        orderPrintData: [],
        totalPickTime: 0,
        receiptData: [],
    };
    constructor(props: any) {
        super(props);

        this.printReceipt = this.printReceipt.bind(this);
        this.setPickOrderRef = this.setPickOrderRef.bind(this);
        this.setPickReceiptRef = this.setPickReceiptRef.bind(this);
    }
    PickOrderRef: any = null;
    PickReceiptRef: any = null;

    onTimeChange = (second: number) => {
        if (second < 300 && second >= 299) {
            this.setState({
                color: 'red',
            });
        } else if (second <= 0) {
            this.setState({
                color: 'red',
                title: services.language.getText('jhcs'),
                status: 'timeout',
            });
        }
    };

    PickingOrder(ids: number[]) {
        return api.put(
            { ids },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PICKING`,
                apiPath: `/admin/picking_lists/batch_picking`,
            }
        );
    }

    //*开始拣货并打印
    changeStatusAndPrintPickingList(props: OrderPickItemProps) {
        const _this = this;
        const {
            parentProps: { entity, refresh },
        } = _this.props;
        //打印商家联
        function unAssemblePrint() {
            GetPrintOrders([props.id]).then((data) => {
                _this.setState({ orderPrintData: data }, () => {
                    if (_this.PickOrderRef) _this.PickOrderRef.onPrint();
                    entity.search();
                    refresh();
                });
            });
        }

        if (props.status === 'WAITING_PICK') {
            this.PickingOrder([Number(props.id)]).then(() => {
                unAssemblePrint();
            });
        } else if (props.status === 'PICKING') {
            unAssemblePrint();
        }
    }

    componentWillReceiveProps(props: OrderPickItemProps) {
        const {
            countdown: { pickTime: prevPickTime },
        } = this.props;
        const {
            countdown: { pickTime },
        } = props;
        if (prevPickTime !== pickTime) {
            this.handleCountdown(props);
        }
    }
    componentDidMount() {
        this.handleCountdown(this.props);
    }
    handleCountdown = (props: OrderPickItemProps) => {
        const {
            countdown: { pickTime, totalPickTime, countdownType },
            status,
        } = props;
        const time = countdownType === 'TIMEOUT' ? -Number(pickTime) : Number(pickTime);
        const countdownData: {
            time: number;
            color: string;
            title: string;
            status: string;
            totalPickTime: number;
        } = {
            time: time,
            color: 'green',
            title: '',
            status: 'disabled',
            totalPickTime: Number(totalPickTime),
        };
        if (status === ORDER_STATUS_ENUM.PICKING) {
            if (time <= 300) countdownData.color = 'red';
            countdownData.title = time > 0 ? '拣货剩余' : '拣货超时';
            countdownData.status = time > 0 ? 'countdown' : 'timeout';
        } else if (status === ORDER_STATUS_ENUM.FINISH_PICK) {
            if (time >= 0) countdownData.title = '完成';
            if (time < 0) {
                countdownData.title = services.language.getText('cswc');
                countdownData.color = 'red';
            }
        } else if (status === ORDER_STATUS_ENUM.CANCELLED) {
            countdownData.time = 0;
        }
        this.setState({ ...countdownData });
    };

    //打印小票
    printReceipt() {
        const { pickingListOrders } = this.props;
        this.setState({
            printLoading: true,
        });
        const ids = pickingListOrders.map((order) => order.orderId);
        GetReceiptInfo(ids).then((res) => {
            //调试验证打印内容
            // AntModal.info({ content: <ReceiptTemplate data={data} />, width: 800 });
            this.setState({ receiptData: res }, () => {
                this.PickReceiptRef.onPrint();
                this.setState({
                    printLoading: false,
                });
            });
        });
    }

    setPickOrderRef(ref: any) {
        this.PickOrderRef = ref;
    }

    setPickReceiptRef(ref: any) {
        this.PickReceiptRef = ref;
    }

    render() {
        const props = this.props;
        const {
            printLoading = false,
            time,
            title,
            status,
            color,
            totalPickTime,
            orderPrintData,
            receiptData,
        } = this.state;
        const href = `#/order-picking-assistant/picking-list-info/${props.id}`;
        return (
            <div className="picking-list-wrap">
                <div className="picking-list-header">
                    <span className="picking-list-label picking-list-number">拣货单号：</span>
                    <a href={href}>{props.pickingListNo}</a>
                    <span
                        className={`picking-list-status${
                            props.status === ORDER_STATUS_ENUM.CANCELLED ? '-red' : ''
                        }`}
                    >
                        {ORDER_STATUS[props.status]}
                    </span>
                </div>
                <div className="picking-list">
                    <div className="picking-list-countdown">
                        <Countdown
                            onTimeChange={this.onTimeChange}
                            time={time}
                            title={title}
                            status={status}
                            color={color}
                            total={totalPickTime}
                        />
                    </div>
                    <div className="picking-list-info">
                        <div className="picking-list-info-item">
                            <span className="picking-list-label">
                                {services.language.getText('gldd')}:
                            </span>
                            <div className="order-number-list">
                                {props.pickingListOrders.map((order) => (
                                    <div key={order.orderId} className="order-number">
                                        <a
                                            href={`/8005/order/viewDetail/${order.orderId}`}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            {order.orderNo}
                                        </a>
                                        {order.remark && (
                                            <div className="order-remark-title">会员备注:</div>
                                        )}
                                        {order.remark && (
                                            <div className="order-remark-content">
                                                {order.remark}
                                            </div>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="picking-list-info-item">
                            <span className="picking-list-label">所属门店：</span>
                            <span>{props.subsite.name}</span>
                        </div>
                        {props.subsite.merchant && props.subsite.merchant.id !== '0' && (
                            <div className="picking-list-info-item">
                                <span className="picking-list-label">所属专柜：</span>
                                <span>{props.subsite.merchant.name}</span>
                            </div>
                        )}
                    </div>
                    <div className="picking-list-button">
                        <div className="button-wrapper">
                            {props.status === ORDER_STATUS_ENUM.FINISH_PICK && (
                                <MessageModal
                                    mode="confirm"
                                    maskClosable={true}
                                    okText={language.getText('common.ok')}
                                    cancelText={language.getText('common.cancel')}
                                    triggerComponent={{
                                        component: 'Button',
                                        text: services.language.getText('dyxp'),
                                        type: 'primary',
                                        loading: printLoading,
                                        style: {
                                            marginRight: 20,
                                        },
                                    }}
                                    title={`是否打印拣货单${props.pickingListNo}的小票？`}
                                    onConfirm={this.printReceipt}
                                    onCancel={() => {}}
                                />
                            )}
                            {(props.status === 'PICKING' || props.status === 'WAITING_PICK') && (
                                <MessageModal
                                    mode="confirm"
                                    maskClosable={true}
                                    okText={language.getText('common.ok')}
                                    cancelText={language.getText('common.cancel')}
                                    triggerComponent={{
                                        component: 'Button',
                                        text:
                                            props.status === 'WAITING_PICK'
                                                ? '开始拣货并打印拣货单'
                                                : '打印拣货单',
                                        type: 'primary',
                                        loading: printLoading,
                                        style: {
                                            marginRight: 20,
                                        },
                                    }}
                                    title={
                                        props.status === 'WAITING_PICK'
                                            ? `是否开始拣货${props.pickingListNo}？`
                                            : `是否打印拣货单${props.pickingListNo}？`
                                    }
                                    onConfirm={() => {
                                        this.changeStatusAndPrintPickingList(props);
                                    }}
                                    onCancel={() => {}}
                                />
                            )}
                        </div>
                    </div>
                </div>
                <div className="picking-list-footer">
                    <div className="picking-list-info-item">
                        <span className="picking-list-label">
                            拣货员：{props.user && props.user.name ? props.user.name : '未分配'}
                        </span>
                        {props.lack && <span className="picking-list-info-lack">存在缺货配</span>}
                    </div>
                </div>
                <PickOrdersTemplate
                    setWrappedInstance={this.setPickOrderRef}
                    data={orderPrintData}
                />
                <PickReceiptTemplate
                    setWrappedInstance={this.setPickReceiptRef}
                    data={receiptData}
                />
            </div>
        );
    }
}
