import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isNil } from 'lodash';
const api = services.api;
export const LabelManageLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`;
        return await api.get(data, config).then((res: any) => {
            res.labelType = !isNil(res.tagRule) ? 'CONDITION' : 'MANUAL';
            if (!isNil(res.tagRule)) {
                let tagRule = JSON.parse(res.tagRule);
                res.conditionType = tagRule.conditionType;
                //消费条件
                if (tagRule.conditionType === 'CONSUMPTION') {
                    const consumptionConditions: any = [
                        {
                            code: 'DISSIPATE',
                            tip: '',
                            selectValue: '7',
                            checked: false,
                        },
                        {
                            code: 'CONSUMPTIONAMOUNT',
                            tip: '',
                            selectValue: '7',
                            checked: false,
                            otherValues: {
                                min: '',
                                max: ' ',
                            },
                        },
                        {
                            code: 'DEALCOUNT',
                            tip: '',
                            selectValue: '7',
                            checked: false,
                            otherValues: {
                                min: '',
                                max: ' ',
                            },
                        },
                    ];
                    if (tagRule?.conditions.length) {
                        tagRule.conditions.forEach((item: any) => {
                            if (item.conditionName === 'LAST_CONSUMPTION_TIME') {
                                //最近消费时间
                                consumptionConditions[0] = {
                                    code: 'DISSIPATE',
                                    tip: '',
                                    selectValue: item.lastDay,
                                    checked: item.lastDay ? true : false,
                                };
                            }
                            if (item.conditionName === 'CONSUMPTION_MONEY') {
                                //消费金额
                                consumptionConditions[1] = {
                                    code: 'CONSUMPTIONAMOUNT',
                                    tip: '',
                                    selectValue: item.lastDay,
                                    checked: item.lastDay ? true : false,
                                    otherValues: {
                                        min: isNil(item?.min) ? undefined : item.min,
                                        max: isNil(item?.max) ? undefined : item.max,
                                    },
                                };
                            }
                            if (item.conditionName === 'DEAL_COUNT') {
                                //消费次数
                                consumptionConditions[2] = {
                                    code: 'DEALCOUNT',
                                    tip: '',
                                    selectValue: item.lastDay,
                                    checked: item.lastDay ? true : false,
                                    otherValues: {
                                        min: isNil(item?.min) ? undefined : item.min,
                                        max: isNil(item?.max) ? undefined : item.max,
                                    },
                                };
                            }
                        });
                    }
                    res.consumptionConditions = consumptionConditions;
                    res.receiptCertification = [];
                    res.merchants = [];
                }
                //商品偏好
                if (tagRule.conditionType === 'PREFERENCE_PRODUCT') {
                    res.receiptCertification =
                        tagRule?.conditions && tagRule?.conditions.length
                            ? tagRule.conditions[0].conditionName
                            : [];
                    res.merchants =
                        tagRule?.conditions && tagRule?.conditions.length
                            ? tagRule.conditions[0].value
                            : [];
                }

                //行为条件
                if (tagRule.conditionType === 'BEHAVIOR') {
                    const behavioralConditions: any = [
                        {
                            code: 'BEHAVIOR',
                            tip: '',
                            selectValue: '7',
                            checked: false,
                        },
                    ];
                    if (tagRule?.conditions.length) {
                        tagRule.conditions.forEach((item: any) => {
                            if (item.conditionName === 'TO_STORE_DAYS') {
                                behavioralConditions[0] = {
                                    code: 'BEHAVIOR',
                                    tip: '',
                                    selectValue: item.lastDay,
                                    checked: item.lastDay ? true : false,
                                    otherValues: {
                                        min: isNil(item?.min) ? undefined : item.min,
                                        max: isNil(item?.max) ? undefined : item.max,
                                    },
                                };
                            }
                        });
                    }
                    res.behavioralConditions = behavioralConditions;
                    res.receiptCertification = [];
                    res.merchants = [];
                }
            }
            return res;
        });
    },
    post: async function(data: any, config: any) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`;
        config.apiPath = `/admin/tags`;
        try {
            const params = dataParser(data);
            return await api.post(params, config);
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
    put: async function(data: any, config: any) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`;
        config.apiPath = `/admin/tags/${data.id}`;
        try {
            const params = dataParser(data);
            return await api.put(params, config);
        } catch (error) {
            console.error(error);
            throw error;
        }
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    const newParams: any = {
        tagName: params.tagName,
        subsiteId: params.subsiteId,
    };
    //手工标
    if (params.labelType === 'MANUAL') {
        newParams.remark = params?.remark || '';
        newParams.tagRule = null;
    }
    //条件标签
    if (params.labelType === 'CONDITION') {
        const tagRule: any = {
            conditionType: params.conditionType,
        };
        const conditions = [];
        //消费条件
        if (params.conditionType === 'CONSUMPTION') {
            const consumptionList = params?.consumptionConditions || [];
            const consumptionConditions = consumptionList.filter((item: any) => item.checked);
            consumptionConditions.forEach((item: any) => {
                let obj = {
                    conditionName:
                        item.code === 'DISSIPATE'
                            ? 'LAST_CONSUMPTION_TIME'
                            : item.code === 'CONSUMPTIONAMOUNT'
                            ? 'CONSUMPTION_MONEY'
                            : 'DEAL_COUNT',
                    lastDay: item.checked ? item.selectValue : '',
                } as any;
                if (item.code !== 'DISSIPATE') {
                    obj.min = !isNil(item?.otherValues?.min) ? item?.otherValues?.min : '';
                    obj.max = !isNil(item?.otherValues?.max) ? item?.otherValues?.max : '';
                }
                conditions.push(obj);
            });
        }
        //商品偏好
        if (params.conditionType === 'PREFERENCE_PRODUCT') {
            let obj = {} as any;
            if (params?.receiptCertification?.length) {
                obj.conditionName = 'CONSUMPTION_MERCHANT';
            }
            if (params?.merchants?.length) {
                obj.value = params?.merchants;
            } else {
                obj.value = [];
            }
            conditions.push(obj);
        }
        //行为条件
        if (params.conditionType === 'BEHAVIOR') {
            const consumptionList = params?.behavioralConditions || [];
            const behavioralConditions = consumptionList.filter((item: any) => item.checked);
            behavioralConditions.forEach((item: any) => {
                let obj = {
                    conditionName: item.code === 'BEHAVIOR' ? 'TO_STORE_DAYS' : '',
                    lastDay: item.checked ? item.selectValue : '',
                } as any;
                if (item.code === 'BEHAVIOR') {
                    obj.min = !isNil(item?.otherValues?.min) ? item?.otherValues?.min : '';
                    obj.max = !isNil(item?.otherValues?.max) ? item?.otherValues?.max : '';
                }
                conditions.push(obj);
            });
        }
        tagRule.conditions = conditions;
        newParams.tagRule = JSON.stringify(tagRule);
    }

    return newParams;
}
