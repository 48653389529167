import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { PaymentRewards } from './payment-rewards';

/**
 *  事件营销，支付奖励，选择优惠券
 */
export class EventMarketPayCouponMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <PaymentRewards {...controlInfo} />;
    }
}
