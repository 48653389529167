import React, { PureComponent } from 'react';
import { Input } from 'antd';
import { services, Loader } from '@comall-backend-builder/core';

import { debounce } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';
export class MerchantUserPhoneInput extends PureComponent<any> {
    checkPhone: any;
    constructor(props: any) {
        super(props);
        this.checkPhone = debounce(this.check, 500);
    }
    state = {
        value: '',
        errorText: '',
        hasError: false,
    };
    onInput = (e: any) => {
        const { onChange } = this.props;
        this.setState({
            value: e.target.value,
        });
        this.checkPhone();
        onChange && onChange({ mobile: e.target.value });
    };
    check = () => {
        Loader.load('get', {
            params: { mobile: this.state.value },
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/merchant_users/by_mobile',
        })
            .then((res: any) => {
                if (!res) {
                    this.setState({ hasError: true, errorText: language.getText('gdybcz') });
                } else if (!res.wechatCardPicture) {
                    this.setState({ hasError: true, errorText: language.getText('dgymyscmp') });
                } else {
                    this.setState({ hasError: false, errorText: '' });
                }
            })
            .catch(services.errorHandle);
    };
    render() {
        const { hasError, errorText } = this.state;
        return (
            <div>
                <div>
                    <Input onInput={this.onInput} {...this.props}></Input>
                    {hasError && (
                        <span style={{ color: '#f5222d', marginLeft: '5px' }}>{errorText}</span>
                    )}
                </div>
                <div className="ant-form-extra">
                    <span>
                        {language.getText('yhdzsdywxewm')}
                        <a href="#/merchant-user" target="_blank">
                            {language.getText('cjxdy')}
                        </a>
                    </span>
                </div>
            </div>
        );
    }
}
