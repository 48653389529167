import React from 'react';
import { services } from '@comall-backend-builder/core';

import { errorHandle } from '@comall-backend-builder/core/lib/services';
import { Tree as AntdTree } from 'antd';
const { TreeNode: AntdTreeNode } = AntdTree;

interface TreeNode {
    name: string;
    id: string;
    parentId: string;
    children: TreeNode[];
}
interface PrivilegeTreeProps {
    onChange: (value: string[]) => void;
    value: string[];
    disabled?: boolean;
}

export class RolePrivilege extends React.Component<PrivilegeTreeProps> {
    state = {
        options: [],
        leafHash: {},
    };
    componentDidMount() {
        services.api
            .get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
                    apiPath: `/privilege_set/mine`,
                }
            )
            .then((options) => {
                const leafHash = {};
                this.setLeafHash(options as TreeNode[], leafHash);
                this.setState({ options, leafHash });
            })
            .catch(errorHandle);
    }
    // 拿到叶子节点
    setLeafHash = (tree: any, leafHash: any) => {
        for (let key in tree) {
            const item = tree[key];
            if (item.children && item.children.length) {
                this.setLeafHash(item.children, leafHash);
            } else {
                leafHash[item.id] = item;
            }
        }
    };

    checkHandle = (checkedKeys: any, { halfCheckedKeys }: any) => {
        const { onChange } = this.props;
        onChange([...checkedKeys, ...halfCheckedKeys]);
    };

    renderTreeNodes = (treeNodes: TreeNode[]) => {
        return treeNodes.map(({ id, name, children }) => (
            <AntdTreeNode key={id} title={name}>
                {children ? this.renderTreeNodes(children) : undefined}
            </AntdTreeNode>
        ));
    };

    render(): React.ReactNode {
        const { options, leafHash } = this.state as any;
        const { value, disabled } = this.props;

        const ids = value && value.length ? value.filter((i) => leafHash[i]) : [];

        return (
            <div>
                <AntdTree
                    disabled={disabled}
                    checkable
                    selectable={false}
                    autoExpandParent={false}
                    checkedKeys={ids}
                    onCheck={this.checkHandle}
                >
                    {this.renderTreeNodes(options)}
                </AntdTree>
            </div>
        );
    }
}
