import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { uniqueId, get } from 'lodash';

const api = services.api;

export const ServiceWifiLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/subsite_wifis';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/subsite_wifis/${id}`;
        }

        return await api.get(data, config).then((res: any) => {
            if (id) {
                res = resParser(res);
            } else {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        item.subsite = item.subsiteVo;
                        item.subsiteName = item.subsiteVo ? item.subsiteVo.name : '';
                        item.id = item.subsiteVo ? item.subsiteVo.id : '';
                        return item;
                    });
            }
            return res;
        });
    },
    put: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/subsite_wifis/${data.id}`;

        const body = dataParser(data);
        return await api.put(body, config);
    },
};

function resParser(params: any) {
    const newParams: any = {
        baseInfo: {},
        otherInfo: {},
    };
    const wifiPictureConfigs = (params.wifiPictureConfigs || []).map((c: any) => {
        let config = {};
        try {
            config = JSON.parse(c.config);
        } catch (error) {
            config = {};
        }
        return {
            picture: [
                {
                    id: c.picture.id,
                    path: c.picture.url,
                },
            ],
            config: config,
        };
    });
    const wifiConfigs = (params.wifiConfigs || []).map((i: any) => {
        i.id = uniqueId('id_');
        return i;
    });
    newParams.subsite = params.subsiteVo;
    newParams.baseInfo.subsite = params.subsiteVo;
    newParams.baseInfo.wifiConfigs = wifiConfigs;
    newParams.otherInfo.wifiPictureConfigs = wifiPictureConfigs;
    return newParams;
}

function dataParser(data: any) {
    const newParams: any = {};
    if (data.baseInfo) {
        const subsite = get(data, 'baseInfo.subsite', {}) || {};
        const wifiConfigs = get(data, 'baseInfo.wifiConfigs', []) || [];
        newParams.subsiteId = subsite.id;
        newParams.wifiConfigs = wifiConfigs.map((i: any) => ({
            name: i.name,
            password: i.password,
        }));
    }
    if (data.otherInfo) {
        const wifiPictureConfigs = get(data, 'otherInfo.wifiPictureConfigs', []) || [];
        newParams.wifiPictureConfigs = wifiPictureConfigs
            .filter((c: any) => !!(c.picture && c.picture.length && c.picture[0].path))
            .map((c: any) => {
                return {
                    picture: {
                        id: c.picture[0].id,
                        url: c.picture[0].path,
                    },
                    config: JSON.stringify(c.config),
                };
            });
    }
    return newParams;
}
