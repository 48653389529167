import React, { Component } from 'react';
import { Input, message } from 'antd';
import { forEach, isEmpty } from 'lodash';

import './index.less';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

type PointDeductionValue = {
    /**
     * 积分最小抵扣单位
     */
    point?: number;
    /**
     * 抵扣金额
     */
    deductionAmount?: number;
};

export interface PointDeductionProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: PointDeductionValue | null, name: string) => void;
    /**
     * 当前值
     */
    value: PointDeductionValue;
    rules: any;
}
export class PointDeduction extends Component<PointDeductionProps> {
    onDeuctionAmountChange(event: any) {
        let { name, value, onChange } = this.props;
        let deductionAmount = event.target.value;
        if (isNaN(Number(deductionAmount))) {
            return;
        }
        if (/\s/.test(deductionAmount)) {
            return;
        }
        if (!isEmpty(deductionAmount) && deductionAmount <= 0) {
            message.warning(language.getText('qtxdydje'));
            return;
        }

        if (deductionAmount && deductionAmount > 1000000) {
            message.warning(services.language.getText('jezdzw'));
            return;
        }

        let amountArr = deductionAmount.split('.');
        if (amountArr.length > 1) {
            amountArr = amountArr.slice(0, 2);
            if (amountArr[1].length >= 2) {
                amountArr[1] = amountArr[1].substr(0, 2);
                deductionAmount = amountArr.join('.');
            }
        }
        if (!value) {
            value = {};
        }
        value.deductionAmount = deductionAmount;

        if (onChange) {
            onChange(value, name);
        }
    }

    onPointChange(event: any) {
        let { name, value, onChange } = this.props;
        let point = event.target.value;
        if (isNaN(Number(point))) {
            return;
        }
        if (/\s/.test(point)) {
            return;
        }
        if (!isEmpty(point) && point <= 0) {
            message.warning(language.getText('qtxdydy'));
            return;
        }

        if (!value) {
            value = {};
        }
        value.point = point;

        if (onChange) {
            onChange(value, name);
        }
    }

    render() {
        const { value, rules } = this.props;
        let ruleInfo = {};
        if (rules && rules.length) {
            forEach(rules, (item) => {
                Object.assign(ruleInfo, item);
            });
        }
        return (
            <div className="point-deduction">
                <div className="point-deduction-content">
                    <span className="item-text">
                        {language.getText('zxdkdw')}&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <div className="number-input">
                        <Input
                            addonAfter={language.getText('point')}
                            value={value && value.point}
                            onChange={this.onPointChange.bind(this)}
                            {...ruleInfo}
                        />
                    </div>
                    <span className="item-amount-text">
                        {value && value.point} {language.getText('point')} =&nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
                    <div className="number-input">
                        <Input
                            addonAfter={language.getText('ytcf')}
                            value={value && value.deductionAmount}
                            onChange={this.onDeuctionAmountChange.bind(this)}
                            {...ruleInfo}
                        />
                    </div>
                </div>
            </div>
        );
    }
}
