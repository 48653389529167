import React, { Component } from 'react';
import { Checkbox } from 'antd';
import { find } from 'lodash';
import { Select } from '@comall-backend-builder/components-basis';
import { SelectProps } from '@comall-backend-builder/components-basis/lib/select';

interface Option {
    /**
     * 候选项id
     */
    id: number;
    /**
     * 候选项显示名称
     */
    name: string;
}
interface TargetHideUserLevelsValue {
    checked: boolean;
    userLevels: Array<Option>;
}
interface TargetHideUserLevelsProps extends Omit<SelectProps, 'onChange' | 'value'> {
    /**
     * 选中值
     */
    value: TargetHideUserLevelsValue;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: TargetHideUserLevelsValue, name: string) => void;
}

export class TargetHideUserLevels extends Component<TargetHideUserLevelsProps> {
    changeCheck = (e: any) => {
        const { onChange, name, value } = this.props;
        const checked = e.target.checked;
        onChange({ checked: checked, userLevels: checked && value ? value.userLevels : [] }, name);
    };
    render() {
        const { value, options, onChange } = this.props;
        const userLevels = value ? value.userLevels : null;
        //手动将options的id转为string类型
        const newOptions = options.map((item: any) => {
            return {
                ...item,
                id: String(item.id),
            };
        });
        const control: any = Object.assign({}, this.props, {
            options: newOptions,
            mode: 'multiple',
            value: userLevels ? userLevels.map(({ id }) => id) : null,
            onChange: (changeValue: string[], name: string) => {
                const selected = changeValue.map((id) => find(newOptions, { id })!);
                onChange({ checked: value.checked, userLevels: selected }, name);
            },
        });

        return (
            <div>
                <Checkbox checked={value && value.checked} onChange={this.changeCheck}>
                    特定用户不可见
                </Checkbox>
                {value && value.checked && (
                    <div>
                        <div>请选择需要隐藏的会员身份</div>
                        <Select {...control} />
                    </div>
                )}
            </div>
        );
    }
}
