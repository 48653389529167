import React from 'react';
import { Button, Tree as AntdTree, Modal, Input, Checkbox, Table } from 'antd';
import { services } from '@comall-backend-builder/core';
import { errorHandle } from '@comall-backend-builder/core/lib/services';
import { cloneDeep } from 'lodash';
import './index.less';

const { TreeNode: AntdTreeNode } = AntdTree;
const { Search } = Input;

interface TreeNode {
    name: string;
    id: string;
    parentId: string;
    remark: string;
    children: TreeNode[];
}

interface RoleSelectorProps {
    onChange: (val: any) => void;
    value: TreeNode[];
    disabled: boolean;
    hideRemark?: boolean;
}
interface TreeNode {
    id: string;
    remark: string;
    name: string;
    children: TreeNode[];
}
interface RoleSelectorState {
    list: TreeNode[];
    options: TreeNode[];
    visible: boolean;
    loading: boolean;
}

export class RoleSelector extends React.Component<RoleSelectorProps, RoleSelectorState> {
    constructor(props: any) {
        super(props);
        this.state = {
            list: [],
            visible: false,
            options: [],
            loading: false,
        };
    }

    static defaultProps = {
        value: [],
    };

    loadTree = async (name?: string) => {
        this.setState({ loading: true });

        try {
            const res: any = await services.api.get(
                { name },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
                    apiPath: `/roles`,
                }
            );

            this.setState({ options: res.result, loading: false });
        } catch (error) {
            errorHandle(error as any);
            this.setState({ loading: false });
        }
    };

    onOpen = async () => {
        const { value } = this.props;

        this.loadTree();
        this.setState({ visible: true, list: value });
    };
    onCheckChange = (e: any) => {
        const { list } = this.state;
        const { checked, value } = e.target;
        const data = cloneDeep(list);
        const { id } = value;
        if (checked) {
            data.push(value);
            this.setState({
                list: data,
            });
        } else {
            this.setState({
                list: data.filter((i) => i.id !== id),
            });
        }
    };

    renderTreeNodes = (treeNodes: TreeNode[]) => {
        const { list } = this.state;
        return treeNodes.map((item) => {
            const checked = !!list.find((i) => i.id === item.id);
            const name = item.name.length > 12 ? item.name.substr(0, 13) + '...' : item.name;
            const title = (
                <div>
                    {name}&nbsp;&nbsp;
                    <Checkbox
                        checked={checked}
                        value={item}
                        onChange={this.onCheckChange}
                    ></Checkbox>
                </div>
            );
            return (
                <AntdTreeNode key={item.id} title={title}>
                    {item.children ? this.renderTreeNodes(item.children) : undefined}
                </AntdTreeNode>
            );
        });
    };

    renderTree = () => {
        const { disabled } = this.props;
        const { options } = this.state;

        return options && options.length ? (
            <AntdTree selectable={false} autoExpandParent={true} disabled={disabled}>
                {this.renderTreeNodes(options)}
            </AntdTree>
        ) : (
            <div className="role-selector-empty">{services.language.getText('zwkfpdjs')}</div>
        );
    };
    onSearchChange = (value: any) => {
        this.loadTree(value);
    };
    onCancel = () => {
        this.setState({
            list: [],
            visible: false,
        });
    };
    clear = () => {
        this.setState({ list: [] });
    };
    onDelete = (id: string) => {
        const { list } = this.state;
        this.setState({
            list: list.filter((i: any) => i.id !== id),
        });
    };
    renderPreview = () => {
        const { list } = this.state;
        return (
            <div className="role-selector-preview">
                <div className="role-selector-preview-list">
                    <div>
                        {services.language.getText('yxjs')}({list.length})
                    </div>{' '}
                    <Button type="link" onClick={this.clear}>
                        {services.language.getText('common.clear')}
                    </Button>
                </div>
                {list.map((i) => (
                    <div key={i.id} className="role-selector-preview-list">
                        <div>{i.name}</div>{' '}
                        <Button onClick={() => this.onDelete(i.id)} type="link">
                            {services.language.getText('common.delete')}
                        </Button>
                    </div>
                ))}
            </div>
        );
    };
    renderDisplay = () => {
        const { value, disabled, hideRemark } = this.props;
        const columns = [
            {
                title: services.language.getText('jsmc'),
                dataIndex: 'name',
                key: 'name',
            },
        ];
        if (!hideRemark) {
            columns.push({
                title: services.language.getText('jsms'),
                dataIndex: 'remark',
                key: 'remark',
            });
        }
        return (
            !!(value && value.length) && (
                <Table
                    className="role-selector-table"
                    pagination={false}
                    rowKey={'id'}
                    bordered={false}
                    dataSource={value}
                    columns={[
                        ...columns,
                        {
                            title: services.language.getText('common.tableAction'),
                            className: 'action-column',
                            render: (text, record) => {
                                return (
                                    <Button
                                        type="link"
                                        disabled={disabled}
                                        onClick={() => this.deleteSelected(record.id)}
                                    >
                                        {services.language.getText('common.delete')}
                                    </Button>
                                );
                            },
                        },
                    ]}
                />
            )
        );
    };
    deleteSelected = (id: string) => {
        const { value, onChange } = this.props;
        onChange(value.filter((i) => i.id !== id));
    };
    onOk = () => {
        const { onChange } = this.props;
        const { list } = this.state;
        const data = cloneDeep(list);
        data.forEach((i: any) => {
            delete i.children;
        });
        onChange(data);
        this.onCancel();
    };
    render() {
        const { value, disabled } = this.props;
        const { visible, loading } = this.state;
        const btnText =
            value && value.length
                ? services.language.getText('xgjs')
                : services.language.getText('xzjs');
        return (
            <div className="role-selector">
                {!disabled && (
                    <Button type="link" onClick={this.onOpen} disabled={disabled}>
                        {btnText}
                    </Button>
                )}
                <Modal
                    title={services.language.getText('gljs')}
                    width={800}
                    visible={visible}
                    className="role-selector-modal"
                    onCancel={this.onCancel}
                    onOk={this.onOk}
                >
                    <div className="role-selector-container">
                        <div className="role-selector-container-left">
                            <Search
                                className="role-selector-search"
                                onSearch={this.onSearchChange}
                                placeholder={services.language.getText('qsrjsmc')}
                            />
                            {loading ? (
                                <span className="role-selector-loading">
                                    {services.language.getText('jzz')}...
                                </span>
                            ) : (
                                this.renderTree()
                            )}
                        </div>
                        <div className="role-selector-container-right">{this.renderPreview()}</div>
                    </div>
                </Modal>
                {this.renderDisplay()}
            </div>
        );
    }
}
