import React, { PureComponent } from 'react';
import { cloneDeep, findIndex, some, get } from 'lodash';
import { CouponSelectorWithPackage, CouponSelectorWithPackageDisplay } from '..';
import { Coupon } from '../../containers/select-coupon';
import './index.less';

export interface ActivityRewardItemProps {
    index: number;
    value: Coupon[];
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChangeCoupon: (products: Coupon[], index: number) => void;
    subsiteIds: any;
    type?: 'checkbox' | 'radio';
    packageSelectType?: 'checkbox' | 'radio';
    disablePrice?: boolean;
    /**
     * 是否可以配置优惠券发放张数
     */
    canChangeQuantity?: boolean;
    disabled?: boolean;
    hideIssuedNum?: boolean;
    /**
     * 展示剩余库存
     */
    showLeftStock?: boolean;
}

export class ActivityRewardItem extends PureComponent<ActivityRewardItemProps, {}> {
    state = { value: new Array<Coupon>() };
    static defaultProps = {
        type: 'checkbox',
        canChangeQuantity: false,
        disablePrice: true,
        disabled: false,
    };
    /**
     * 选择优惠券
     * @param selectValues
     */
    onChange = (selectValues: Coupon[]) => {
        const { onChangeCoupon, index } = this.props;
        if (onChangeCoupon) {
            if (selectValues && selectValues.length === 0) {
                onChangeCoupon([], index);
            } else {
                onChangeCoupon(selectValues, index);
            }
        }
    };

    /**
     * 添加分站弹窗确认后回调
     * @param selectValues 选中分站
     */
    onSelect = (selectValues: Coupon[]) => {
        const { value, type, canChangeQuantity } = this.props;
        let newData: Coupon[] = [];
        if (value) {
            newData = cloneDeep(value);
        }
        if (selectValues) {
            if (type === 'radio') {
                newData = selectValues;
            } else {
                // 如果之前选择的或者新选择的是优惠券礼包，newData置空
                const isSelectPackage = some(
                    selectValues,
                    (item) => get(item, 'couponType') === 'PACKAGE'
                );
                const isPackageNow = some(newData, (item) => get(item, 'couponType') === 'PACKAGE');
                if (isPackageNow || isSelectPackage) {
                    newData = [];
                }
                selectValues.forEach((selectCuopon: any) => {
                    const hasIndex = findIndex(
                        newData,
                        (coupon: any) => coupon.id === selectCuopon.id
                    );
                    if (hasIndex === -1) {
                        newData.push(selectCuopon);
                    }
                });
            }
        }
        if (canChangeQuantity && typeof canChangeQuantity === 'boolean') {
            newData = newData.map((item: any) => {
                return {
                    ...item,
                    quantity: 1,
                };
            });
        }
        this.onChange(newData);
    };

    render() {
        const {
            value,
            subsiteIds,
            type,
            packageSelectType,
            disablePrice,
            canChangeQuantity,
            disabled,
            hideIssuedNum,
            showLeftStock,
        } = this.props;

        const hasValue = value && value.length > 0;
        return (
            <div className="activity-reward-item">
                <CouponSelectorWithPackage
                    onChange={this.onSelect}
                    selectValues={value}
                    type={type}
                    disabled={disabled}
                    packageSelectType={packageSelectType || type}
                    params={{ subsiteIds }}
                />
                {hasValue && (
                    <CouponSelectorWithPackageDisplay
                        data={value}
                        disabled={disabled}
                        disablePrice={disablePrice}
                        onChange={this.onChange}
                        canChangeQuantity={canChangeQuantity}
                        xScrollWidth={640}
                        hideIssuedNum={hideIssuedNum}
                        showLeftStock={showLeftStock}
                    />
                )}
            </div>
        );
    }
}
