import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { mapValues, defaults, get, forEach, isEmpty, isNull, debounce } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

export enum Enable {
    TRUE = 'true',
    FALSE = 'false',
}

export interface MemberBenefitAddFormProps extends FormComponentProps {
    enableConsumeDeductions: Enable;
    enablePointDeduction: Enable;
    enableLimitUnbind: Enable;
    enableDiscountLimit: Enable;
    canUseCoupons: Enable;
    entity: Entity;
    enableBalanceCouponBenefits: Enable;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
}

class memberBenefitAddForm extends PureComponent<MemberBenefitAddFormProps> {
    static defaultProps: any = {
        enableConsumeDeductions: Enable.FALSE,
        enablePointDeduction: Enable.FALSE,
        enableLimitUnbind: Enable.FALSE,
        enableBalanceCouponBenefits: Enable.FALSE,
    };
    componentWillUnmount() {
        this.props.unmountComponent();
    }
    render() {
        const { entity, onSubmit, wrappedComponentRef } = this.props;
        let {
            enableConsumeDeductions,
            enablePointDeduction,
            enableLimitUnbind,
            enableDiscountLimit,
            canUseCoupons,
            enableBalanceCouponBenefits,
        } = this.props;
        if (!enableConsumeDeductions) {
            enableConsumeDeductions = Enable.FALSE;
        }
        if (!enablePointDeduction) {
            enablePointDeduction = Enable.FALSE;
        }
        if (!enableLimitUnbind) {
            enableLimitUnbind = Enable.FALSE;
        }
        if (!enableDiscountLimit) {
            enableDiscountLimit = Enable.FALSE;
        }
        if (!canUseCoupons) {
            canUseCoupons = Enable.FALSE;
        }
        if (!enableBalanceCouponBenefits) {
            enableBalanceCouponBenefits = Enable.FALSE;
        }
        let fields: any = [
            { property: 'baseInfo' },
            { property: 'subsiteId' },
            { property: 'benefitInfo.enableConsumeDeductions' },
            {
                property: 'benefitInfo.deductionType',
                className: enableConsumeDeductions === Enable.TRUE ? '' : 'property-hide',
            },
            {
                property: 'benefitInfo.consumeDeductionBenefits',
                className: enableConsumeDeductions === Enable.TRUE ? '' : 'property-hide',
            },
            { property: 'benefitInfo.canUseCoupons' },
            {
                property: 'benefitInfo.deductionTimePeriod',
                className: canUseCoupons === Enable.TRUE ? '' : 'property-hide',
            },
            {
                property: 'benefitInfo.stackedUse',
                className: canUseCoupons === Enable.TRUE ? '' : 'property-hide',
            },
            { property: 'benefitInfo.enableDiscountLimit' },
            {
                property: 'benefitInfo.discountLimit',
                className: enableDiscountLimit === Enable.TRUE ? '' : 'property-hide',
            },
            { property: 'benefitInfo.enablePointDeduction' },
            {
                property: 'benefitInfo.pointDeduction',
                className: enablePointDeduction === Enable.TRUE ? '' : 'property-hide',
            },
            { property: 'benefitInfo.enableBalanceCouponBenefits' },
            {
                property: 'benefitInfo.balanceCoupons',
                className: enableBalanceCouponBenefits === Enable.TRUE ? '' : 'property-hide',
            },
            { property: 'bindBenefitInfo.maxBindQuantity' },
            { property: 'bindBenefitInfo.enableLimitUnbind' },
            {
                property: 'bindBenefitInfo.unbindPeriod',
                className: enableLimitUnbind === Enable.TRUE ? '' : 'property-hide',
            },
            {
                property: 'bindBenefitInfo.unbindBenefits',
                className: enableLimitUnbind === Enable.TRUE ? '' : 'property-hide',
            },
        ];

        let formProps = {
            entity,
            componentId: 'MemberBenefitAddFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'member-benefit-add-form-container',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            style: { width: 950 },
            fields: fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('CreateForm'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;
    let form: any;

    let enableConsumeDeductions = get(
        _dispatch,
        'components.MemberBenefitAddFormContainer.fields.benefitInfo.enableConsumeDeductions',
        Enable.FALSE
    );
    let enablePointDeduction = get(
        _dispatch,
        'components.MemberBenefitAddFormContainer.fields.benefitInfo.enablePointDeduction',
        Enable.FALSE
    );
    let enableLimitUnbind = get(
        _dispatch,
        'components.MemberBenefitAddFormContainer.fields.bindBenefitInfo.enableLimitUnbind',
        Enable.FALSE
    );
    let enableDiscountLimit = get(
        _dispatch,
        'components.MemberBenefitAddFormContainer.fields.benefitInfo.enableDiscountLimit',
        Enable.FALSE
    );
    let canUseCoupons = get(
        _dispatch,
        'components.MemberBenefitAddFormContainer.fields.benefitInfo.canUseCoupons',
        Enable.FALSE
    );
    let enableBalanceCouponBenefits = get(
        _dispatch,
        'components.MemberBenefitAddFormContainer.fields.benefitInfo.enableBalanceCouponBenefits',
        Enable.FALSE
    );

    /**
     * 提交前额外验证
     * @param entityFields
     */
    const validate = (entityFields: any) => {
        const consumeDeductionBenefits: any = get(
            entityFields,
            'benefitInfo.consumeDeductionBenefits',
            null
        );
        const discountLimit: any = get(entityFields, 'benefitInfo.discountLimit', null);
        const pointDeduction: any = get(entityFields, 'benefitInfo.pointDeduction', null);
        const unbindBenefits: any = get(entityFields, 'bindBenefitInfo.unbindBenefits', null);
        const enableBalanceCouponBenefits: Enable = get(
            entityFields,
            'benefitInfo.enableBalanceCouponBenefits',
            null
        );
        const canUseCoupons: Enable = get(entityFields, 'benefitInfo.canUseCoupons', null);
        const stackedUse: {
            state: boolean;
            count?: number;
        } = get(entityFields, 'benefitInfo.stackedUse', null);
        const balanceCoupons: any[] = get(entityFields, 'benefitInfo.balanceCoupons', []);
        if (enableConsumeDeductions === Enable.TRUE) {
            if (!consumeDeductionBenefits) {
                AntMessage.warning(language.getText('qtxdkgzs'));
                return false;
            }
            const { limitMemberLevel, consumeDeductions } = consumeDeductionBenefits;
            if (!consumeDeductions || consumeDeductions.length === 0) {
                AntMessage.warning(language.getText('qwztxdkgz'));
                return false;
            }
            if (limitMemberLevel && !!consumeDeductions.find((c: any) => !c.memberLevelId)) {
                AntMessage.warning(language.getText('qxzhykdj'));
                return false;
            }
            const validateRules = !consumeDeductions.find((c: any) => {
                const rules = c.deductionRules;
                return (
                    !rules ||
                    rules.length === 0 ||
                    !!rules.find((r: any) => {
                        return !r.deductionValue || (!r.amount && r.amount !== 0);
                    })
                );
            });
            if (!validateRules) {
                AntMessage.warning(language.getText('qwztxdkgz'));
                return false;
            }
            let hasRepeat = false;
            for (const item of consumeDeductions) {
                const amountArr = item.deductionRules.map((d: any) => d.amount);
                const amountSet = new Set(amountArr);
                if (amountSet.size !== amountArr.length) {
                    hasRepeat = true;
                    break;
                }
            }
            if (hasRepeat) {
                AntMessage.warning(language.getText('xthykdjqtxbtxfjegz'));
                return false;
            }
        }

        if (enableDiscountLimit === Enable.TRUE) {
            if (!discountLimit || !discountLimit.limitValue) {
                AntMessage.warning(language.getText('qtxyhsx'));
                return false;
            }
        }

        if (enablePointDeduction === Enable.TRUE) {
            if (!pointDeduction) {
                AntMessage.warning(language.getText('qtxjfdkgz'));
                return false;
            }
            if (!pointDeduction.point || !pointDeduction.deductionAmount) {
                AntMessage.warning(language.getText('qtxwzdjfdkgz'));
                return false;
            }
        }
        if (enableLimitUnbind === Enable.TRUE) {
            if (isNull(unbindBenefits)) {
                AntMessage.warning(language.getText('qtxjbgz'));
                return false;
            }
            let isComplete = true;
            forEach(unbindBenefits, (item) => {
                if (
                    isEmpty(item) ||
                    !item.memberLevelId ||
                    (!item.maxUnbindQuantity && item.maxUnbindQuantity !== 0)
                ) {
                    isComplete = false;
                }
            });
            if (!isComplete) {
                AntMessage.warning(language.getText('qtxwzdjbgz'));
                return false;
            }
        }

        if (enableBalanceCouponBenefits === Enable.TRUE && balanceCoupons.length === 0) {
            AntMessage.warning(language.getText('qxzzsxzygkydyeqdkqlx'));
            return false;
        }

        if (canUseCoupons === Enable.TRUE && stackedUse && stackedUse.state) {
            if (!stackedUse.count) {
                AntMessage.warning(language.getText('qsrtcqdjsyzs'));
                return false;
            }
            if (stackedUse.count <= 1) {
                AntMessage.warning(language.getText('tcqdjsyzsbxwdy'));
                return false;
            }
            if (stackedUse.count > 10) {
                AntMessage.warning(language.getText('tcqdjsyzsbkcg'));
                return false;
            }
        }

        return true;
    };

    return defaults(
        {
            onSubmit,
            onFieldChange,
            enableConsumeDeductions,
            enablePointDeduction,
            enableLimitUnbind,
            enableDiscountLimit,
            canUseCoupons,
            enableBalanceCouponBenefits,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: debounce((event: any, fields: any) => {
                const entityFields = mapValues(fields, (field, name) => {
                    return field.value;
                });
                if (entityFields) {
                    const hasValidate = validate(entityFields);
                    if (hasValidate) {
                        entity.add(entityFields, params);
                    }
                }
            }, 300),
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('MemberBenefitAddFormContainer'));
            },
        }
    );
}
export const MemberBenefitAddForm = connect(mapDispatchToProps)(memberBenefitAddForm);
