import React, { PureComponent } from 'react';
import { Modal, InputNumber, Table, message as AntMessage } from 'antd';
import { cloneDeep, forEach, map } from 'lodash';
import { ColumnProps } from 'antd/lib/table';
import { services } from '@comall-backend-builder/core';

enum computedType {
    ADD = 'ADD',
    SUBTRACT = 'SUBTRACT',
    MULTIPLY = 'MULTIPLY',
    DIVIDE = 'DIVIDE',
}

export const floatComputed = (function() {
    /*
     * 判断obj是否为一个整数
     */
    function isInteger(obj: number) {
        return Math.floor(obj) === obj;
    }
    /*
     * 将一个浮点数转成整数，返回整数和倍数。如 3.14 >> 314，倍数是 100
     * @param floatNum {number} 小数
     * @return {object}
     *   {times:100, num: 314}
     */
    function toInteger(floatNum: number) {
        var ret = { times: 1, num: 0 };
        if (isInteger(floatNum)) {
            ret.num = floatNum;
            return ret;
        }
        var strfi = floatNum + '';
        var dotPos = strfi.indexOf('.');
        var len = strfi.substr(dotPos + 1).length;
        var times = Math.pow(10, len);
        var intNum = Number(floatNum.toString().replace('.', ''));
        ret.times = times;
        ret.num = intNum;
        return ret;
    }

    function operation(a: number, b: number, digits: number, op: computedType) {
        var o1 = toInteger(a);
        var o2 = toInteger(b);
        var n1 = o1.num;
        var n2 = o2.num;
        var t1 = o1.times;
        var t2 = o2.times;
        var max = t1 > t2 ? t1 : t2;
        var result = null;
        switch (op) {
            case computedType.ADD:
                if (t1 === t2) {
                    // 两个小数位数相同
                    result = n1 + n2;
                } else if (t1 > t2) {
                    // o1 小数位 大于 o2
                    result = n1 + n2 * (t1 / t2);
                } else {
                    // o1 小数位 小于 o2
                    result = n1 * (t2 / t1) + n2;
                }
                return result / max;
            case computedType.SUBTRACT:
                if (t1 === t2) {
                    result = n1 - n2;
                } else if (t1 > t2) {
                    result = n1 - n2 * (t1 / t2);
                } else {
                    result = n1 * (t2 / t1) - n2;
                }
                return result / max;
            case computedType.MULTIPLY:
                result = (n1 * n2) / (t1 * t2);
                return result;
            case computedType.DIVIDE:
                result = (n1 / n2) * (t2 / t1);
                return result;
        }
    }
    function add(a: number, b: number, digits: number) {
        return operation(a, b, digits, computedType.ADD);
    }
    function subtract(a: number, b: number, digits: number) {
        return operation(a, b, digits, computedType.SUBTRACT);
    }
    function multiply(a: number, b: number, digits: number) {
        return operation(a, b, digits, computedType.MULTIPLY);
    }
    function divide(a: number, b: number, digits: number) {
        return operation(a, b, digits, computedType.DIVIDE);
    }
    return {
        add: add,
        subtract: subtract,
        multiply: multiply,
        divide: divide,
    };
})();

interface CreditShopExchangeApportionProps {
    visible: boolean;
    onOk: (value: any) => void;
    onCancel: () => void;
    memberLevelRewardInfo: any;
    data: any;
}

interface CreditShopExchangeApportionState {
    newData: any;
}

export class CreditShopExchangeApportion extends PureComponent<
    CreditShopExchangeApportionProps,
    CreditShopExchangeApportionState
> {
    constructor(props: any) {
        super(props);
        this.state = { newData: [] };
    }
    componentDidMount() {
        const { data } = this.props;
        if (data) {
            this.setState({
                newData: cloneDeep(data),
            });
        }
    }
    onCancel = () => {
        const { onCancel } = this.props;
        onCancel && onCancel();
    };
    onChangePrice = (type: string, value: any, record: any) => {
        const { newData } = this.state;
        if (value < 0) {
            value = '';
        }
        const newCouponData = map(newData, (item) => {
            if (record.id === item.id) {
                item[type] = value;
            }
            return item;
        });
        this.setState({ newData: newCouponData });
    };
    onOk = () => {
        const { onOk, memberLevelRewardInfo } = this.props;
        const { newData } = this.state;
        //校验
        let hasApportionPrice = true,
            totalApportionPoints = 0,
            totalApportionPrice = 0;
        forEach(newData, (item) => {
            if (item.apportionPoints === null || item.apportionPoints === '') {
                hasApportionPrice = false;
            } else {
                totalApportionPoints += (+item.apportionPoints || 0) * item.quantity;
            }
            if (
                memberLevelRewardInfo.salesPrice &&
                (item.apportionPrice === null || item.apportionPrice === '')
            ) {
                hasApportionPrice = false;
            } else {
                totalApportionPrice = floatComputed.add(
                    totalApportionPrice,
                    floatComputed.multiply(+item.apportionPrice || 0, item.quantity, 2),
                    2
                );
            }
        });
        if (!hasApportionPrice) {
            AntMessage.warning('优惠券礼包售卖时请填写分摊价');
            return false;
        }
        if (
            totalApportionPoints !== (+memberLevelRewardInfo.points || 0) ||
            totalApportionPrice !== (+memberLevelRewardInfo.salesPrice || 0)
        ) {
            AntMessage.warning('分摊价总和不等于售卖价格');
            return false;
        }
        onOk && onOk(newData);
    };
    renderTable = () => {
        const { newData } = this.state;
        const columns: ColumnProps<any>[] = [
            {
                title: services.language.getText('couponName'),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: services.language.getText('qlx'),
                dataIndex: 'type',
                key: 'type',
            },
            {
                title: services.language.getText('ffsl'),
                dataIndex: 'quantity',
                key: 'quantity',
            },
            {
                title: services.language.getText('ftj'),
                dataIndex: 'apportionPoints',
                key: 'apportionPoints',
                render: (apportionPoints: any, record: any) => {
                    return (
                        <div>
                            <InputNumber
                                value={apportionPoints}
                                step={0.01}
                                placeholder={services.language.getText('inputPlease')}
                                onChange={(value: any) =>
                                    this.onChangePrice('apportionPoints', value, record)
                                }
                            />
                            <span>{services.language.getText('point')} + </span>
                            <InputNumber
                                value={record.apportionPrice}
                                step={0.01}
                                placeholder={services.language.getText('inputPlease')}
                                onChange={(value: any) => {
                                    const newValue =
                                        value === undefined ? undefined : (+value || 0).toFixed(2);
                                    this.onChangePrice('apportionPrice', newValue, record);
                                }}
                            />
                            <span> {services.language.getText('yuan')}</span>
                        </div>
                    );
                },
            },
        ];

        return <Table scroll={{ x: true }} columns={columns} dataSource={newData} rowKey={'id'} />;
    };

    render() {
        const { visible, memberLevelRewardInfo } = this.props;
        let contentTitle = services.language.getText('tydhj');
        if (memberLevelRewardInfo.cardLevelName) {
            contentTitle = memberLevelRewardInfo.cardLevelName;
        }
        contentTitle += '： ';
        let content = '';
        content += memberLevelRewardInfo.points + '积分';
        if (memberLevelRewardInfo.salesPrice) {
            content += ' + ' + memberLevelRewardInfo.salesPrice + services.language.getText('yuan');
        }
        content += '';
        return (
            <Modal
                visible={visible}
                width={900}
                title="设置分摊价格"
                onCancel={this.onCancel}
                onOk={this.onOk}
            >
                <div style={{ marginBottom: '20px', fontWeight: 500, color: 'rgba(0,0,0,.85)' }}>
                    <span>
                        {contentTitle}
                        {content}
                    </span>
                </div>
                <div style={{ marginBottom: '20px', color: '#ccc' }}>
                    <span>说明：单张券数量*分摊的积分价+…={memberLevelRewardInfo.points}积分</span>
                    {memberLevelRewardInfo && memberLevelRewardInfo.salesPrice ? (
                        <span>
                            &nbsp;&nbsp;单张券数量*分摊的现金价+…={memberLevelRewardInfo.salesPrice}
                            {services.language.getText('yuan')}
                        </span>
                    ) : null}
                </div>
                {this.renderTable()}
            </Modal>
        );
    }
}
