import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const ServiceReservedRefundOrderLoader: Loader = {
    async get(data, config: ApiRequestConfig) {
        if (data.dateRange) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        if (!data.dateRange) {
            delete data.dateRange;
        }
        if (data.subsites) {
            data.subsiteId = data.subsites;
            delete data.subsites;
        }
        if (!data.projectName) {
            delete data.projectName;
        }

        return api
            .get(data, {
                ...config,
                apiPath: '/admin/service_reserved_activity_refunds',
            })
            .then((res: any) => {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        item.canRefund = item.interfaceStatus === 'REFUND_FAIL';
                        let refundMoney = '免押金';
                        if (item.refundPointValue && Number(item.refundPointValue) > 0) {
                            refundMoney = `${item.refundPointValue}积分`;
                        } else if (item.refundMoney && Number(item.refundMoney) > 0) {
                            refundMoney = `${item.refundMoney}${services.language.getText('yuan')}`;
                        }
                        item.refundMoney = refundMoney;
                        return item;
                    });
                return res;
            });
    },
};
