import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { MemberPointDoubleRule } from '../../../components';
import { language } from '@comall-backend-builder/core/lib/services';

export class MemberPointDoubleRuleMode extends ObjectMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(object: any, displayInfo: any) {
        return (
            object && (
                <div>
                    <span>
                        {' '}
                        {object.ladderValue} {language.getText('song')}
                        {object.ladderAmount} {language.getText('bjf')}
                    </span>
                </div>
            )
        );
    }

    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <MemberPointDoubleRule {...controlInfo} />;
    }
}
