import React, { FC, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

interface Props {
    type: string;
    data: Record<string, any>;
    hover: (dragItem: any, hoverItem: any) => void;
}

export const DragSortBox: FC<Props> = (props) => {
    const { type, data = {}, children, hover } = props;
    const ref = useRef<HTMLDivElement>(null);

    const [{ isDragging }, drag] = useDrag({
        item: { type, ...data },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });
    const [, drop] = useDrop({
        accept: type,
        hover: (dragItem) => {
            hover(data, dragItem);
        },
    });
    drag(drop(ref));
    return (
        <div
            ref={ref}
            style={{
                opacity: isDragging ? 0.5 : 1,
                cursor: 'move',
            }}
        >
            {children}
        </div>
    );
};
