import { Config } from '@comall-backend-builder/core/lib/parser';
import { TipsModalContentType } from '../../../components';
import { services } from '@comall-backend-builder/core';

export const config: Config = {
    entities: {
        checkinActivityEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/checkin/activities',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsrhdmc>>' },
                },
                timeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: [
                        {
                            id: 'ALL',
                            name: '<<all>>',
                        },
                        {
                            id: 'UNSTART',
                            name: '<<notStarted>>',
                        },
                        {
                            id: 'RUNNING',
                            name: '<<inprogress>>',
                        },
                        {
                            id: 'END',
                            name: '<<end>>',
                        },
                    ],
                    controlConfig: {
                        style: { width: 170 },
                    },
                },
                subsiteName: {
                    type: 'object.option.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                subsiteName: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },

                subsites: {
                    type: 'array.options.select',
                    className: 'custom-array-options',
                    displayName: '<<otherSelectSubsite>>',
                    options: [],
                },
                timeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                status: {
                    type: 'string',
                    displayName: '<<kqzt>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            controlConfig: {
                                placeholder: '<<yqdylhd>>',
                            },
                            rules: [
                                {
                                    required: true,
                                    max: 20,
                                    whitespace: true,
                                    message: '<<activityNameRequired>>',
                                },
                            ],
                        },
                        dateRange: {
                            type: 'object.dateTimeRange.tip',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                hideTime: true,
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subsiteIds: {
                            type: 'array.subsites.selector',
                            displayName: '<<otherSelectSubsite>>',
                            rules: [{ required: true }],
                        },
                        recordShare: {
                            type: 'string.options.radio.plus',
                            displayName: '<<yhcyxz>>',
                            defaultValue: 'false',
                            controlConfig: {
                                setOptions: [
                                    {
                                        value: 'false',
                                        name: '<<amdwdqd>>',
                                        tip: {
                                            text: '<<yhkzhdpzdmdfbjxqd>>',
                                        },
                                    },
                                    {
                                        value: 'true',
                                        name: '<<ayhwdqd>>',
                                        tip: {
                                            text: '<<yhzryyjhdmdqdcgh>>',
                                            tipsModalConfig: {
                                                type: 'extra',
                                                contentType:
                                                    TipsModalContentType.CheckinActivityRecordShare,
                                                showIcon: false,
                                                text: '<<viewExample>>',
                                                textColor: '#1890ff',
                                                title: '',
                                                width: 1048,
                                            },
                                        },
                                    },
                                ],
                            },
                            rules: [{ required: true }],
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],

                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.baseInfo?.subsiteIds?.length &&
                                        row.baseInfo.subsiteIds
                                            .map((item: any) => item.id)
                                            .join(',');
                                    return { subsiteIds };
                                },
                            },
                        },
                    },
                },
                rewardInfo: {
                    type: 'object.subForm',
                    displayName: '<<ruleSetting>>',
                    properties: {
                        id: {
                            type: 'string',
                        },
                    },
                },
                daysRewardRule: {
                    type: 'array.activityReward',
                    displayName: '<<mrqdgdjl>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/activityRewardItems',
                        paramsFilter: ['CHECKIN_DAYS'],
                    },
                    controlConfig: {
                        maxCount: 1,
                        rewardType: 'single',
                    },
                },
                totalRewardRule: {
                    type: 'object.activityRewardCheckin',
                    displayName: '<<ewjl>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/activityRewardItems',
                        paramsFilter: ['CHECKIN_DAYS'],
                    },
                    controlConfig: {
                        maxCount: 31,
                    },
                },
                specialDayRewardRules: {
                    type: 'array.activitySignInReward',
                    displayName: '<<tsrqjl>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/activityRewardItems',
                        paramsFilter: ['CHECKIN_SPECIAL'],
                    },
                },
                ladderPolling: {
                    type: 'array.optionIds.checkbox.tip',
                    displayName: '<<ccljts>>',
                    defaultValue: [],
                    options: [
                        {
                            id: 'true',
                            name: '<<cczgljtsh>>',
                        },
                    ],
                    controlConfig: {
                        tip: '<<yhhdzgjtjlh>>',
                    },
                },
                pictureInfo: {
                    type: 'object.subForm',
                    displayName: '<<pageDecoration>>',
                    properties: {
                        backgroundPicture: {
                            displayName: '<<hdbjt>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://puzhehei.oss-cn-beijing.aliyuncs.com/global/magic-backend/checkin-activity/background-picture.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'single',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                        },
                        description: {
                            type: 'string.text.paragraph',
                            displayName: '<<activityRule>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    max: 1000,
                                    message: '<<qsrhdsm>>',
                                },
                            ],
                            controlConfig: {
                                rows: 4,
                                maxLength: 1000,
                            },
                        },
                        popupPicture: {
                            displayName: '<<qddcbjt>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://puzhehei.oss-cn-beijing.aliyuncs.com/global/magic-backend/checkin-activity/popup-picture.png',
                                },
                            ],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'single',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                        },
                        sharePicture: {
                            displayName: '<<fxxt>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                        },
                        shareDescription: {
                            type: 'string',
                            displayName: '<<fxwa>>',
                            controlConfig: { placeholder: '<<gwyqdkhdhl>>' },
                            rules: [
                                {
                                    required: true,
                                    max: 30,
                                    whitespace: true,
                                    message: '<<qsrfxwa30Rule>>',
                                },
                            ],
                        },
                    },
                },
            },
        },
    },
    components: {
        checkinActivityView: {
            component: 'Viewport',
            entity: 'checkinActivityEntity',
        },
        checkinActivityPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'checkinActivityFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzqdyl>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/checkin-activity/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'checkinActivityTable' },
            ],
        },
        checkinActivityFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'timeStatus',
                },
                {
                    property: 'subsiteName',
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        checkinActivityTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'startTime',
                    width: '200px',
                },
                {
                    property: 'endTime',
                    width: '200px',
                },
                {
                    property: 'subsiteName',
                    displayConfig: {
                        countSuffix: '<<subsites>>',
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },

                {
                    property: 'timeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            UNSTART: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            RUNNING: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            END: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 220,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            //statusKey: 'status',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: 'DISABLED' },
                                    value: 'ENABLED',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/checkin/activities/:id/status',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                        className: 'confirm-content',
                                    },
                                },
                                {
                                    params: { status: 'ENABLED' },
                                    value: 'DISABLED',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/checkin/activities/:id/status',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/menu-list/market/checkin-activity/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/menu-list/market/checkin-activity/copy/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<reportData>>',
                        path: '/menu-list/market/checkin-activity/report/{{row.id}}',
                    },
                    {
                        text: '<<common.log>>',
                        type: 'link',
                        path: '/menu-list/market/checkin-activity/logs/{{row.id}}',
                    },
                ],
            },
        },
        checkinActivityAddPage: {
            component: 'FlexLayout',
            entity: 'checkinActivityEntity',
            direction: 'horizontal',
            items: [
                { component: 'CheckinActivityPreview' },
                { component: 'CheckinActivityAddFormView' },
            ],
        },
        checkinActivityEditPage: {
            component: 'FlexLayout',
            entity: 'checkinActivityEntity',
            direction: 'horizontal',
            loaderType: 'get',
            items: [
                { component: 'CheckinActivityPreview' },
                { component: 'CheckinActivityEditFormView' },
            ],
        },
        checkinActivityCopyPage: {
            component: 'FlexLayout',
            entity: 'checkinActivityEntity',
            direction: 'horizontal',
            loaderType: 'get',
            items: [
                { component: 'CheckinActivityPreview' },
                { component: 'CheckinActivityCopyFormView' },
            ],
        },
        checkinActivityReportPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'CheckinDataReportView',
                },
            ],
        },
        checkinActivityLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ActivityLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                    apiPath: '/admin/checkin/activities/{id}/operation_logs',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/checkin-activity',
                    component: 'checkinActivityView',
                    breadcrumbName: '<<qdyl>>',
                    privilege: {
                        path: 'checkinActivity',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'checkinActivityAddPage',
                            breadcrumbName: '<<xzqdyl>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'checkinActivityEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        {
                            path: '/copy/:id',
                            component: 'checkinActivityCopyPage',
                            breadcrumbName: '<<copyActivity>>',
                        },
                        {
                            path: '/report/:id',
                            component: 'checkinActivityReportPage',
                            breadcrumbName: '<<viewData>>',
                        },
                        {
                            path: '/logs/:id',
                            component: 'checkinActivityLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        { path: '/', component: 'checkinActivityPage' },
                    ],
                },
            ],
        },
    ],
};
