import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        RemittanceOrderManager: {
            apiPath: '/loader/admin/overseas_remittance_records',
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
            filters: {
                subsiteIds: {
                    type: 'string.options.autoComplete.plus',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/loader/source/admin/v1/subsites/mine',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 240 },
                        allowClear: true,
                    },
                },
                payTime: {
                    type: 'object.dateRangePlus',
                    displayName: '<<zfsj>>',
                    startField: 'orderPaidStartTime',
                    endField: 'orderPaidEndTime',
                },
                remittanceTime: {
                    type: 'object.dateRangePlus',
                    displayName: '<<remittanceTime>>',
                    startField: 'applyRemittanceStartTime',
                    endField: 'applyRemittanceEndTime',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                outOrderNo: {
                    type: 'string',
                    displayName: '<<wbddh>>',
                },
            },
        },
    },
    components: {
        RemittanceOrderManagerViewPort: {
            component: 'Viewport',
        },
        RemittanceOrderManagerQueryPage: {
            entity: 'RemittanceOrderManager',
            component: 'TabsPlus',
            type: 'card',
            defaultActiveKey: 'PENDING',
            cache: false,
            animated: false,
            basePath: '/remittance-order-manager',
            items: [
                {
                    title: '<<dhk>>',
                    params: { remittanceStatus: 'PENDING' },
                    content: {
                        component: 'RemittancePendingOrderManagerTab',
                    },
                    key: 'PENDING',
                },
                {
                    title: '<<clz>>',
                    params: { remittanceStatus: 'PROCESSING' },
                    content: {
                        component: 'RemittanceProcessingOrderManagerTab',
                    },
                    key: 'PROCESSING',
                },
                {
                    title: '<<ywc>>',
                    params: { remittanceStatus: 'SUCCESS' },
                    content: {
                        component: 'RemittanceSuccessOrderManagerTab',
                    },
                    key: 'SUCCESS',
                },
                {
                    title: '<<hksb>>',
                    params: { remittanceStatus: 'FAIL' },
                    content: {
                        component: 'RemittanceFailOrderManagerTab',
                    },
                    key: 'FAIL',
                },
            ],
        },
        RemittancePendingOrderManagerTab: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                { component: 'RemittanceOrderManagerFilter' },
                { component: 'RemittancePendingOrderManagerButtonGroup' },
                { component: 'PaymentRemittancePendingRecord' },
            ],
        },
        RemittanceProcessingOrderManagerTab: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                { component: 'RemittanceOrderManagerFilter' },
                { component: 'RemittanceProcessingOrderManagerButtonGroup' },
                { component: 'RemittanceOrderManagerDataList' },
            ],
        },
        RemittanceSuccessOrderManagerTab: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                { component: 'RemittanceOrderManagerFilter' },
                { component: 'RemittanceSuccessOrderManagerButtonGroup' },
                { component: 'RemittanceOrderManagerDataList' },
            ],
        },
        RemittanceFailOrderManagerTab: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                { component: 'RemittanceOrderManagerFilter' },
                { component: 'RemittanceFailOrderManagerButtonGroup' },
                { component: 'PaymentRemittancePendingRecord' },
            ],
        },
        RemittanceOrderManagerFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                { property: 'subsiteIds' },
                { property: 'payTime' },
                { property: 'remittanceTime' },
                { property: 'orderNo' },
                { property: 'outOrderNo' },
            ],
        },
        RemittancePendingOrderManagerButtonGroup: {
            component: 'GridLayout',
            style: { marginTop: 24, marginBottom: 24 },
            items: [
                {
                    component: 'ExportDataByFilterParamsButton',
                    params: {
                        text: '<<exportSearchResult>>',
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                        apiPath: '/loader/admin/overseas_remittance_records/export',
                        type: 'remittance-order-manager',
                        remittanceStatus: 'PENDING',
                        requestMethod: 'post',
                        target: '_blank',
                    },
                    span: 4,
                },
            ],
        },
        RemittanceProcessingOrderManagerButtonGroup: {
            component: 'GridLayout',
            style: { marginTop: 24, marginBottom: 24 },
            items: [
                {
                    component: 'ExportDataByFilterParamsButton',
                    params: {
                        text: '<<exportSearchResult>>',
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                        apiPath: '/loader/admin/overseas_remittance_records/export',
                        type: 'remittance-order-manager',
                        remittanceStatus: 'PROCESSING',
                        requestMethod: 'post',
                        target: '_blank',
                    },
                    span: 4,
                },
            ],
        },
        RemittanceSuccessOrderManagerButtonGroup: {
            component: 'GridLayout',
            style: { marginTop: 24, marginBottom: 24 },
            items: [
                {
                    component: 'ExportDataByFilterParamsButton',
                    params: {
                        text: '<<exportSearchResult>>',
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                        apiPath: '/loader/admin/overseas_remittance_records/export',
                        type: 'remittance-order-manager',
                        remittanceStatus: 'SUCCESS',
                        requestMethod: 'post',
                        target: '_blank',
                    },
                    span: 4,
                },
            ],
        },
        RemittanceFailOrderManagerButtonGroup: {
            component: 'GridLayout',
            style: { marginTop: 24, marginBottom: 24 },
            items: [
                {
                    component: 'ExportDataByFilterParamsButton',
                    params: {
                        text: '<<exportSearchResult>>',
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                        apiPath: '/loader/admin/overseas_remittance_records/export',
                        type: 'remittance-order-manager',
                        remittanceStatus: 'FAIL',
                        requestMethod: 'post',
                        target: '_blank',
                    },
                    span: 4,
                },
            ],
        },
        RemittanceOrderManagerDataList: {
            component: 'DataList',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            grid: { gutter: 24 },
            renderItem: 'PaymentRemittanceOrderListItem',
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/remittance-order-manager',
                    component: 'RemittanceOrderManagerViewPort',
                    breadcrumbName: '<<paymentAllocation>>  /  <<remittanceOrderManager>>',
                    privilege: {
                        path: 'paymentRemittanceOrders',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/',
                            component: 'RemittanceOrderManagerQueryPage',
                        },
                    ],
                },
            ],
        },
    ],
};
