export const zhCN = {
    entities: {
        PageManage: {
            pagesType: '页面类型',
            pagesName: '页面名称',
            enabled: '启用',
            template: '模板来源',
            createTime: '创建时间',
            lastedModifyTime: '修改时间',
        },
        TemplateManage: {
            templateName: '模板名称',
            templateType: '模板类型',
            sorting: '排序',
            previewImage: '预览图',
            enabled: '启用',
            FixedPageStructure: '固定页面结构',
            isDefault: '默认模板',
        },
    },
    rules: {
        PageManage: {
            nameRequired: '请输入页面名称.',
            nameLength: '最多可输入16个字符.',
            nameLongLength: '最多可输入100个字符.',
        },
        TemplateManage: {
            nameRequired: '请输入模板名称.',
            nameLength: '最多可输入16个字符.',
        },
        MerchantPageManage: {
            nameRequired: '请选择专柜.',
        },
    },
    components: {
        NavMenu: {
            operationsManagement: '运营管理',
            homePagesManagement: '管理首页',
            topicPagesManagement: '管理专题活动',
            merchantPageManagement: '管理首页',
            templatesPageManagement: '管理模板',
            language: '多语言',
        },
        PageManage: {
            createPage: '创建页面',
            setting: '设置',
            decorate: '装饰',
            preview: '预览',
            delete: '删除',
            setPage: '设置页面',
        },
        TopicManage: {
            createPage: '创建页面',
            setting: '设置',
            decorate: '装饰',
            preview: '预览',
            delete: '删除',
            setPage: '设置页面',
        },
        TemplateManage: {
            createTemplate: '新建模板',
            setTemplate: '设置模板',
            decorate: '装饰模板',
            preview: '预览模板',
            select: '选择模板',
        },
        PageDesign: {
            settingInformation: '点击设置页面信息',
            removeConfirm: '确定移除当前组件吗',
            addComponent: '新增组件',
            pageSetting: '页面设置',
        },
        PageDesignRoot: {
            shareInformation: '分享信息',
            basicInformation: '基础信息',
            title: '标题',
            description: '描述',
            image: '图片',
            backgroundColor: '背景',
            miniCart: '小购物车',
            backToTop: '回到顶部',
            backgroundImage: '背景图',
            topNavigation: '顶部导航栏',
        },
        ComponentSelector: {
            title: '标题',
            text: '文本',
            nav: '导航',
            products: '商品',
            imageAd: '图片广告',
            divider: '辅助线',
            space: '辅助空白',
            noticeBar: '通知栏',
            searchBar: '搜索框',
            showcase: '橱窗',
            coupon: '优惠券',
        },
        Title: {
            content: '标题内容',
            color: '标题颜色',
            size: '标题大小',
            contentRequired: '请输入标题内容.',
            align: '对齐方式',
            left: '左对齐',
            right: '右对齐',
            center: '居中',
        },
        Divider: {
            style: '边框样式',
            color: '边框颜色',
            margin: '边距',
            none: '无边距',
            solid: '实线',
            dashed: '虚线',
            dotted: '点线',
            space: '左右留白',
        },
        ImageAd: {
            selectTemplate: '选择模板',
            fillStyle: '填充方式',
            pageMargin: '页面边距',
            imageMargin: '图片边距',
            borderRadius: '图片圆角',
            blank: '留白',
            fill: '填充',
            addImage: '新增广告位',
            imageSize: '图片宽度建议为710px',
            tips: '最多添加 {{max}} 个商品广告位，可拖拽调整顺序。',
        },
        Nav: {
            name: '导航名称',
            selectType: '选择类型',
            number: '导航数量',
            graphic: '图文导航',
            text: '文字导航',
            style: '导航样式',
            unfold: '展开（展示全部）',
            fold: '折叠（展示一行）',
            color: '导航颜色',
            pageMargin: '页面边距',
            addNav: '新增分类导航项',
            imageSize: '图片尺寸建议为 80px*80px',
        },
        NoticeBar: {
            color: '字体颜色',
            backgroundColor: '背景颜色',
            icon: '左侧图片',
            content: '通知信息',
            addNoticeBar: '新增通知消息',
            tips: '最多添加 {{max}} 个通知信息，可拖拽调整顺序。',
        },
        SearchBar: {
            keywords: '搜索热词',
            shape: '框体样式',
            square: '方形',
            round: '圆形',
            backgroundColor: '背景颜色',
            shapeColor: '框体颜色',
            textAlign: '文本位置',
            left: '居左',
            center: '居中',
            textColor: '文本颜色',
            addSearchWord: '新增搜索热词',
            searchTips: '搜索框默认展示第一个热词，其他搜索词将以标签形式显示在搜索页中',
            tips: '最多添加 {{max}} 个搜索热词，可拖拽调整顺序。',
        },
        Space: {
            space: '空白高度',
        },
        Text: {
            contentSet: '内容设置',
            content: '文字内容',
            color: '文字颜色',
            size: '文字大小',
            textAlign: '对齐方式',
            left: '左对齐',
            center: '居中',
            right: '右对齐',
            targetLink: '跳转链接',
            contentRequired: '请输入内容.',
        },
        Products: {
            showStyle: '展示样式',
            products: '商品',
            content: '显示内容',
            buy: '购买按钮',
            name: '商品名称',
            price: '商品价格',
            list: '列表',
            card: '卡片',
            scroll: '横向滚动',
            dragTips: '可拖动右侧商品调整排列顺序',
        },
        Tools: {
            reset: '重置',
            effectiveTime: '生效时间',
            linkType: '链接类型',
            linkTarget: '链接目标',
            modify: '修改',
            select: '选择',
            selectProduct: '选择商品',
            set: '设置',
            productSelectRequired: '请选择商品',
            topicSelectRequired: '请选择专题',
            selectCoupon: '选择优惠券',
            selectCouponPackage: '选择优惠券礼包',
            couponSelectRequired: '请选择优惠券',
            couponSelectPackageRequired: '请选择优惠券礼包',
            liveInfoSelectRequired: '请选择直播间',
            categoryNavProductSelectRequired: '请选择分类导航商品',
            categoryNavSelectRequired: '请选择分类导航',
            categorySelectRequired: '请选择分类',
            multiSubsiteSelectRequired: '请选择门店定位',
            subsiteSelectRequired: '请选择指定门店',
            presaleSelectRequired: '请选择预售活动',
            selectPlatformGoods: '选择商品',
        },
        Coupon: {
            addMode: '添加方式',
            manual: '手动添加',
            backgroundColor: '底色',
            couponBaseImage: '优惠券底图',
            couponBaseImageSize: '图片尺寸建议为1420px*300px',
            drawButtonBaseImage: '立即领取底图',
            drawButtonBaseImageSize: '图片尺寸建议为72px*25px',
            showContent: '显示内容',
            name: '优惠券名称',
            amount: '优惠券金额',
            lowerLimit: '优惠券消费下限',
            drawProgress: '领取进度',
        },
        FlashProducts: {
            title: '标题',
            titleImageSize: '图片尺寸建议为300px*88px',
            more: '更多',
            moreConfig: '更多配置',
            moreTitle: '文字:',
            backgroudImage: '底图',
            backgroudImageSize: '图片尺寸建议为750px*480px',
            showContent: '显示内容',
            productName: '商品名称',
            salePrice: '秒杀价',
            marketPrice: '市场价',
            countDown: '秒杀场次倒计时',
        },
        Presale: {
            title: '标题',
            titleImageSize: '图片尺寸建议为300px*88px',
            more: '更多',
            moreConfig: '更多配置',
            moreTitle: '文字:',
            backgroudImage: '底图',
            backgroudImageSize: '图片尺寸建议为750px*480px',
            showContent: '显示内容',
            productName: '商品名称',
            salePrice: '预售价',
            marketPrice: '市场价',
        },
        GroupBuying: {
            add: '新增拼团活动',
            close: '关闭',
        },
        PlatformGoods: {
            showStyle: '展示样式',
            products: '商品',
            content: '显示内容',
            buy: '购买按钮',
            name: '商品名称',
            price: '商品价格',
            list: '列表',
            card: '卡片',
            scroll: '横向滚动',
            dragTips: '可拖动右侧商品调整排列顺序',
        },
        Auth: {
            login: '登录',
        },
        RichText: {
            tooltips: {
                header: '标题',
                bold: '粗体',
                italic: '斜体',
                underline: '下划线',
                strike: '删除线',
                blockquote: '引用',
                size: '字号',
                header1: '标题1',
                header2: '标题2',
                listordered: '有序列表',
                listbullet: '无序列表',
                scriptsub: '下标',
                scriptsuper: '上标',
                directionrtl: '文本方向',
                color: '颜色',
                background: '背景色',
                font: '字体',
                align: '对齐方式',
                link: '链接',
                image: '图片',
                video: '视频',
                clean: '清除样式',
                'indent-1': '退回缩进',
                'indent+1': '缩进',
                'code-block': '代码块',
            },
        },
        DetailView: {
            back: '返回',
        },
        Button: {
            search: '搜索',
            export: '导出',
            cancel: '取消',
            modify: '修改',
            create: '创建',
            view: '查看',
            submit: '提交',
            delete: '删除',
            save: '保存',
            select: '选择',
            reset: '重置',
        },
        Table: {
            ActionColumn: {
                view: '查看',
                modify: '修改',
                delete: '删除',
                link: '链接',
            },
            selected: '已选择',
            loaded: '已加载',
        },
        Form: {
            required: '不能为空',
        },
        DateRangePicker: {
            placeholder: {
                start: '开始日期',
                end: '结束日期',
            },
        },
        DateTimeRangePicker: {
            placeholder: {
                start: '开始时间',
                end: '结束时间',
            },
        },
        MonthRangePicker: {
            placeholder: {
                start: '开始月份',
                end: '结束月份',
            },
        },
        TimeRangePicker: {
            placeholder: {
                start: '开始时间',
                end: '结束时间',
            },
        },
        UploadFile: {
            moveRight: '右移',
            moveLeft: '左移',
            toUpload: '点击上传',
            dragToUpload: '点击或者拖拽文件到该区域进行上传',
            upload: '上传',
            uploadCompleteMessage: '上传成功{{success}}个，上传失败{{error}}个',
            uploadLimitSizeErrorMessage: '超出上传大小限制，上传失败',
            uploadLimitErrorMessage: '一次最多只能上传{{maxCount}}张图片',
            uploadLimitText: '最多上传',
            uploadLimitUnit: '张',
            preview: '预览',
        },
    },
    common: {
        add: '新增',
        edit: '编辑',
        keywords: '关键字',
        tableAction: '操作',
        search: '查询',
        reset: '重置',
        cancel: '取消',
        submit: '提交',
        ok: '确定',
        confirm: '确认',
        save: '保存',
        goBack: '返回',
        addSuccess: '新增成功',
        saveSuccess: '保存成功',
        modifySuccess: '修改成功',
        upload: '上传',
        view: '查看',
        close: '关闭',
        opend: '开启',
        delete: '删除',
        log: '日志',
        clear: '清空',
        modify: '修改',
        more: '更多',
        confrim: '是',
        pleaseSelect: '请选择',
        pleaseInput: '请输入',
        copyrights: 'Powered by Co-Mall',
    },
    monetaryUnit: '￥',
    keywordsSearch: '关键词搜索',
    keywordsPlaceholder: '搜索商品名称//编码,品牌名称进行查询',
    decorationPage: '装修页面',
    managePlatformHomepage: '管理平台首页',
    managePopup: '管理弹窗',
    manageContentPopup: '管理内容弹窗',
    manageSpecialActivity: '管理专题活动',
    manageMallActivity: '管理商场活动',
    designMember: '管理会员中心',
    designContentMember: '管理内容会员中心',
    designContentService: '管理内容服务首页',
    guideManager: '配置营销分类',
    guide: '配置营销分类',
    article: '管理文章',
    articleCategory: '管理文章类型',
    articleManager: '管理文章',
    appSection: '管理文章配置',
    decorate: '装修',
    shoppingPage: '商城页面',
    platformPage: '平台首页',
    storePage: '门店首页',
    merchantPageNew: '专柜首页',
    customPage: '专题首页',
    memberCenterPage: '会员中心',
    genericComponent: '通用组件',
    customStoreTabbar: '门店底部导航',
    floatingActionButton: '悬浮窗',
    newClientPopUpAds: '管理弹窗',
    splashAds: '开屏广告',
    searchPage: '搜索页',
    platformSearchPage: '平台搜索页',
    storeSearchPage: '门店搜索页',
    payResultPage: '支付结果页',
    posReceiptsPage: '电子小票详情',
    themeStyle: '主题风格',
    merchant: '专柜',
    merchantUserManager: '专柜用户管理',
    merchantManager: '管理专柜',
    merchantBigCodeManager: '管理专柜商家码',
    cashDepositDetail: '查询保证金',
    merchantConfig: '装修专柜',
    merchantUser: '管理店员',
    merchantManageUser: ' 专柜-管理店员',
    selfMerchantVirtualCategory: '配置专柜营销分类',
    arbitrationBillManager: '管理仲裁单',
    authorizeMemberManagement: '管理专柜会员',
    merchantManagerFacility: '管理设施',
    editFacility: '编辑设施',
    addFacility: '新增设施',
    facilityTag: '设施标签',
    categories: '类目',
    brand: '品牌',
    category: '标准分类',
    virtualCategory: '虚拟分类',
    specification: '管理商品规格',
    numberAndWeight: '数量/重量',
    specifications: '规格',
    goodId: '货品ID',
    productinfo: '商品资料中心',
    createSelfProduct: '管理门店商品',
    goods: '管理商品经营',
    weightGoods: '管理称重商品',
    hby: '红包雨',
    subsiteStyleGroup: '管理一品多款',
    virtualProductConf: '管理虚拟商品',
    addVirtualProductConf: '新增虚拟商品',
    editVirtualProductConf: '编辑虚拟商品',
    copyVirtualProductConf: '复制虚拟商品',
    combineGoods: '管理组合商品',
    inventoryRule: '设置库存展示规则',
    goodsShelf: '管理商品上下架',
    createMerchantProduct: '管理专柜商品',
    merchantProductInfoMananger: '商品资料中心',
    merchantGoodsManager: '商品经营',
    auditMerchantProduct: '审核专柜商品',
    merchantProductInfo: '专柜商品资料',
    merchantGoods: '专柜商品经营',
    promotionTag: '管理商品图标',
    tagShow: '展示商品图标',
    virtualProductVirtualCategory: '虚拟商品营销分类',
    virtualCouponProductVirtualCategory: '优惠券营销分类',
    salesPlan: '销售计划',
    JQSelectionPool: '九衢选品池',
    selectionPool: '选品池',
    mySubscriptions: '我的订阅',
    subscriptionConfig: '订阅配置',
    JQSaleBill: '订单流水明细',
    JQMessageNotify: '消息通知',
    CapitalFlows: '资金流水',
    promotion: '营促销',
    promotionActivity: '促销活动',
    promotionPrice: '特价活动',
    merchantPromotionPrice: '专柜特价活动',
    timelimitActivity: '秒杀活动',
    discount: '满减//满赠',
    reduction: '单品//多品促销',
    present: '管理赠品',
    jsGroup: '结算促销',
    promotionGroup: '促销组合',
    promotionPriority: '促销优先级',
    fixedPrice: '打包一口价',
    coupon: '优惠券促销',
    marketActivity: '营销活动',
    maotaiPresale: '茅台预售',
    order: '订单',
    orderExamine: '订单过审',
    orderManager: '管理订单',
    orderLogistics: '批量发货',
    orderProcessBill: '取消关闭单',
    saleCouponOrder: '虚拟商品订单',
    deliveryPackagesLogisticsBill: '管理包裹运单',
    posReceipt: '电子小票',
    afterSale: '管理售后',
    refund: '管理退款',
    couponServiceApply: '虚拟商品售后单',
    saleCouponRefund: '虚拟商品退款单',
    exceptionRefundManager: '管理异常订单',
    exceptionRefund: '异常订单退款',
    paymentAllocation: '管理分账汇款',
    allocationConfigManager: '管理分账配置',
    allocationConfigManagerViewPort: '管理分账汇款  //  管理分账配置',
    allocationOrderManager: '分账订单明细',
    remittanceManager: '管理汇款',
    remittanceOrderManager: '汇款订单明细',
    picking: '拣货',
    pickingList: '拣货助手',
    delivery: '配送',
    deliveryyfgl: '配送运费管理',
    subsiteDelivery: '管理门店运费规则',
    merchantDelivery: '管理专柜运费规则',
    subsiteBuyLimitRule: '管理门店起送规则',
    merchantBuyLimitRule: '管理专柜起送规则',
    deliveryLogisticsConfigs: '管理门店配送公司',
    deliveryBenefit: '运费优惠',
    member: '会员',
    memberInfo: '会员360视图',
    newMemberInfo: '会员基本信息',
    cardLevel: '会员卡等级',
    storeCardLevel: '门店会员卡等级',
    memberLevelRule: '获取经验值',
    memberTag: '会员标签管理',
    socialIdentityTagFunction: '社交身份标签权限',
    memberProfileItem: '会员资料管理',
    member360: '新会员360',
    memberManage: '会员管理',
    labelManage: '标签管理',
    crowdManage: '人群管理',
    levelSystem: '等级体系',
    memberCardLevelManage: '会员卡等级',
    subsiteMemberCardLevelManage: '门店会员卡等级',
    memberLevel: '会员成长体系',
    scrmLevelsUpdownGrade: '会员升降级规则',
    publicSentiment: '管理舆情',
    reviewAudit: '会员评论',
    feedback: '管理客户建议',
    feedbacks: '意见反馈',
    offlineOrderComments: '线下订单评价',
    offlineOrderCommentsCustomization: '线下订单问卷',
    commentActivity: '评论有礼',
    caring: '会员关怀',
    birthdayCaring: '生日营销',
    festivalMarketing: '节日营销',
    annualWelfareDesc: '年终回馈',
    amountAccountsDesc: '余额说明',
    memberCardDiscount: '会员折扣',
    memberService: '会员服务',
    carpark: '智慧停车',
    newCSubsiteCarpark: '页面配置',
    carparkOrder: '缴费记录',
    memberBenefits: '停车权益',
    TouchlessParkingAuthRecord: '无感停车授权',
    memberCarNumber: '会员车牌号',
    mallActivity: '商场活动',
    mallActivitySetting: '活动配置',
    mallActivityRegistration: '报名记录',
    mallActivityServiceApply: '报名售后单',
    mallActivityRefund: '退款单',
    mallActivityVerification: '核销记录',
    serviceReserved: '服务预约',
    serviceReservedSetting: '服务配置',
    serviceReservedOrder: '预约订单',
    serviceReservedRefundOrder: '服务预约退款单',
    wifi: 'Wi-Fi一键连',
    newMallActivity: '新商场活动',
    reservation: '餐厅预订',
    pointManage: '积分生态',
    creditEShop: '积分商城',
    scratchCard: '积分刮刮卡',
    lotteryCode: '积分抽奖码',
    pointExplain: '积分规则',
    selfCrediting: '自助积分',
    photoCreditReview: '拍照积分审核',
    pointRightsAndInterests: '购物送积分',
    pointRuleSet: '积分抵扣规则',
    pointDeductionProductSet: '积分抵扣商品',
    purchaseLimitation: '积分商城限购',
    pointExchangeBalanceCouponRule: '积分兑换余额券规则',
    pointClearRule: '积分有效期设置',
    hiBeiManage: '金币生态',
    goldExchangePoint: '金币规则',
    hiBeiRightsAndInterests: '购物送金币',
    goldDeduction: '金币抵扣',
    experienceManage: '经验值',
    experienceManageAndB: '经验值(百分比)',
    experienceRule: '经验值规则',
    experienceRightsAndInterests: '购物送经验值',
    memberStoredValueCard: '会员储值',
    storedValueCardParamConfig: '储值卡设置',
    storedValueCardProduct: '卡类别设置',
    storedValueCardActivity: '售卖储值卡',
    storedValueCardBuyActivity: '购卡营销活动',
    storedValueCardOrder: '订单管理',
    OfflineOrderReview: '线下支付订单复核',
    memberGiftManage: '会员礼品管理',
    manageGift: '管理礼品',
    message: '消息',
    subscriptionMessage: '消息推送',
    messageType: '消息类型',
    messageChannel: '管理消息通道',
    messageTemplate: '管理消息模板',
    batchMessage: '群发消息',
    messageBatchTemplate: '群发活动模板',
    messageHistory: '历史消息记录',
    messageBatch: '消息群发',
    reportData: '数据',
    report: '管理报表',
    wechatPublicUserCount: '公众号用户统计',
    reportManager: '销售报表',
    ServiceApplyReport: '售后报表',
    couponCode: '优惠券报表',
    couponConsume: '线下核销优惠券',
    couponUnConsume: '线下退优惠券',
    couponDownload: '下载优惠券数据',
    couponReport: '卡券报表',
    miniProgramChargesDashboard: '小程序收款报表',
    couponOrderReport: '优惠券售卖报表',
    balanceRechargeReport: '余额券售卖报表',
    couponPackageOrderReport: '优惠券礼包售卖报表',
    couponRefundReport: '优惠券退款报表',
    couponConsumeReport: '优惠券核销报表',
    couponMerchantReport: '优惠券店铺分析报表',
    couponActivityReport: '优惠券活动分析报表',
    pointExchangeRecord: '积分兑换余额券报表',
    financeReport: '财务报表',
    orderStatement: '结算账单',
    merchantSaleBill: '销售账单',
    merchantBookkeepingBill: '记账账单',
    deliveryLogisticsBillReport: '物流运单报表',
    dataPanel: '数据面板',
    paymentsData: '支付数据',
    miniProgramCodeDatas: '渠道推广数据',
    productsData: '商品数据',
    refundsData: '售后数据',
    membersData: '客户数据',
    reportDownloadCenter: '导入//导出记录',
    biDatart: '数据罗盘',
    operatorLog: '操作日志',
    search: '搜索',
    productSearch: '搜索置顶',
    hotWordsSearch: '搜索热词',
    escapeWord: '管理转译词',
    authority: '权限',
    userPrivilege: '管理门店权限',
    userManage: '管理用户',
    roleManage: '管理角色',
    merchantAuthority: '管理专柜权限',
    merchantUserManage: '管理用户',
    applyUserRecord: '审核导购员信息',
    organizationManage: '组织机构管理',
    newRoleManage: '角色管理',
    newUserManage: '账号管理',
    auditGuideUser: '审核导购账号',
    content: '内容',
    complain: '审核举报笔记',
    ontentScore: '管理笔记评分',
    contentNote: '管理笔记内容',
    showStyleLabel: '管理风格标签',
    contentPlate: '管理笔记板块',
    contentTopic: '管理板块话题',
    encourage: '用户激励',
    wan: '万',
    notePromotionEncourage: '创作激励',
    topicPromotionEncourage: '话题激励',
    activationPromotionEncourage: '促活激励',
    interactivePromotionEncourage: '互动激励',
    fissionPromotionEncourage: '裂变激励',
    wechatOfficialAccount: '公众号',
    wechatOfficialAccountManger: '管理公众号',
    wechatOfficialAccountFan: '管理公众号粉丝',
    wechatTemplate: '模板消息',
    addSuccessCount: '共成功添加{{successCount}}个 / 失败',
    followReplyMessage: '关注公众号回复',
    wechatOfficialParametricQrcode: '公众号二维码',
    customizeMenus: '自定义菜单',
    settings: '设置',
    l_settings_1: '如：设置“2”年07月31日，则表示，2024年1月26日产生的积分，会在2026年7月31日失效',
    l_settings_2: '如：设置13个月则表示，2024年1月26日产生的积分，会在2025年2月26日失效',
    deliverySettings: '配送设置',
    region: '管理配送地区',
    expressCompany: '管理物流公司',
    deliveryMode: '管理配送方式',
    orderTest: '订单修改测试',
    parameter: '管理订单参数',
    filterWord: '管理敏感字',
    blacklist: '管理黑名单',
    systemUserManagemen: '系统用户管理',
    paymentModeManagement: '管理支付方式',
    appVersion: '管理APP版本',
    hotVersion: '热部署',
    versionUpdate: '强制更新',
    manageLogo: '管理个性化设置',
    backinfo: '管理后台个性化',
    frontinfo: '商城名称及logo',
    merchantSystemConfig: '管理专柜参数',
    picture: '管理图片',
    albumConfig: '管理图片类型',
    album: '管理图片',
    operations: '管理运维',
    promotionActivityMaintain: '营销活动维护',
    lotteryMaintain: '抽签报名维护',
    drawLotsAppoint: '抽签中签指定',
    drawLotsReplenish: '抽签中签补充',
    signOrder: '抽签订单签收',
    cacheStats: 'Memcache监控',
    primaryKey: 'zookeeper主键初始化',
    requestLimit: '请求限制',
    createIndex: '创建全部索引',
    orderPaymentManager: '管理订单支付状态',
    invoiceSetting: '设置发票',
    posReceiptInvoiceConfig: '电子小票发票配置',
    department: '管理部门',
    settingMore: '设置更多',
    orderSetting: '设置交易',
    printConfig: '设置打印',
    productAuditRules: '审核专柜商品',
    broadcastConfig: '设置语音播报',
    commonSetting: '通用设置',
    memberSecuritySetting: '用户账号安全设置',
    virtualNumberSetting: '用户账号安全设置',
    virtualNumberWhiteList: '账号安全白名单',
    securityConfig: '后台安全设置',
    MemberAssetsSetting: '会员资产设置',
    CrossborderTradeConfig: '设置跨境购交易',
    demoPage: '自定义组件DEMO',
    manageSubsiteInformation: '管理门店信息',
    editSubsite: '编辑门店',
    addSubsite: '新增门店',
    manageFloor: '管理楼层',
    addFloor: '新增楼层',
    editFloor: '编辑楼层',
    businessMalls: '商圈信息',
    subsiteName: '门店名称',
    subsiteStatus: '门店状态',
    subsiteStatusEnable: '营业',
    notBusiness: '未营业',
    subsiteStatusDisable: '筹备',
    subsiteCode: '门店编码',
    contactName: '联系人',
    mobile: '联系电话',
    servicePhone: '客服电话',
    indoorMapProvider: '室内导航服务商',
    indoorMapProviderFengNiao: '蜂鸟',
    indoorMapProviderQianMu: '千目',
    indoorMapProviderNone: '无',
    mapUrl: '跳转地址',
    subsiteNameRequired: '请输入门店名称',
    codeRequired: '请输入门店编码',
    codeNotEmpty: '门店编码不能为空',
    codeRules: '限制输入字母+数字，最多输入6位',
    subsiteNameNotEmpty: '门店名称不能为空',
    subsiteNameRules: '请输入门店名称，最多输入20字',
    contactNameNotEmpty: '联系人不能为空',
    contactNameRequired: '请输入联系人',
    mobileRequired: '请输入联系电话',
    servicePhoneRequired: '请输入客服电话',
    servicePhoneNotEmpty: '客服电话不能为空',
    mapUrlNotEmpty: '跳转地址不能为空',
    mapUrlRequired: '请输入跳转地址',
    mapId: '地图ID',
    mapIdRequired: '请输入地图ID',
    mapIdNotEmpty: '地图ID不能为空',
    mapKey: '地图KEY值',
    mapKeyRequired: '请输入地图KEY值',
    mapKeyNotEmpty: '地图KEY值不能为空',
    mapAppName: '地图名称',
    mapAppNameRequired: '请输入地图名称',
    mapAppNameNotEmpty: '地图名称不能为空',
    mapThemeId: '地图主题ID',
    mapThemeIdRequired: '请输入地图主题ID',
    mapThemeIdExtra: '如地图已设置主题，请正确填写主题ID',
    subsitePic: '门店图片',
    subsiteExtra: '建议图片宽度为274px，高度不超过174px；大小不超过1M；',
    subsiteLogo: '门店logo',
    subsiteLogoExtra: '用于在聚合码中显示。建议图片100*100方图；大小不超过1M；jpg格式',
    subsiteRegion: '门店区域',
    regionNotEmpty: '门店区域不能为空',
    productSpu: '商品SPU',
    productSpuCodeRequired: '请输入商品spu编码',
    selectPlease: '请选择',
    notSelect: '未选择',
    subsiteAddress: '门店地址',
    subsiteNotEmpty: '门店地址不能为空',
    subsitePosition: '门店坐标',
    subsitePositionNotEmpty: '门店坐标不能为空',
    autoPosition: '自动定位',
    allow: '允许',
    notAllow: '不允许',
    yhdw: '用户定位',
    mryhdw: '默认定位门店',
    dmryhdw: '当用户定位与最近门店超出指定距离，用户定位门店取指定默认门店',
    dwjzjmd: '定位距最近门店',
    mrmd: '默认门店',
    gl_1: '公里',
    autoPositionExtra:
        '根据用户定位允许被作为定位门店自动被选中并展示，不允许，则用户需手动切换门店',
    sequence: '排序',
    sequenceRequired: '请输入排序值',
    inputPlease: '请输入',
    sequenceExtra: '前台门店列表根据排序值判断门店的排序，排序值越大展示越靠前，排序值不允许重复',
    format: '经营业态',
    market: '超市',
    store: '百货',
    outlets: '奥莱',
    homeAppliances: '家电',
    other: '其他',
    businessStatus: '营业状态',
    normalBusinessOperations: '正常营业',
    suspendOrderTaking: '暂停接单',
    tips: '提示文案',
    tipsValue: '非常抱歉，因配送能力有限，今日订单已满',
    viewExample: '查看示例',
    ysrzrq: '延时入账日期',
    qxzysrzrq: '请选择延时入账日期',
    viewExample_dkxd:
        '代客下单场景中，商品需延时入账（例如预售，会员权益指定日期发放等，则导购在开单时填写预售日期，到指定日期转为销售单）；{modal}',
    exampleTipsValue: '如：非常抱歉，因配送能力有限，今日订单已满。',
    tipsExtra: '当用户提交订单时，会显示配置的文案提示。',
    businessTime: '营业时间',
    deliveryAddress: '门店发货地址',
    usingStoreAddress: '使用门店地址',
    custom: '自定义',
    contactMobile: '联系人电话',
    contactMobileRequired: '请输入联系人电话',
    selectRegion: '选择区域',
    selectRegionNotEmpty: '门店区域不能为空',
    detailAddress: '详细地址',
    detailAddressRequired: '请输入详细地址',
    afterSalesReturnAddress: '商品售后寄回地址',
    returnedGoodsMethod: '退货商品返还方式选择',
    ChooseOneReturnMethod: '至少选择一种返还方式',
    sendStore: '送回到店',
    deliveryMethodReturning: '快递方式寄回',
    returnedGoodsMethodExtra:
        '若专柜需使用独立的返还方式，可在管理专柜中的”退货商品返还方式“内设置独立返还方式',
    floorName: '楼层名称',
    floorNameRequired: '请输入楼层信息',
    floorNameRules: '请输入楼层信息，最多输入8位',
    saleRange: '经营范围',
    saleRangeRequired: '请输入经营范围',
    status: '状态',
    enable: '启用',
    disabled: '禁用',
    createTimeStr: '创建时间',
    subsites: '门店',
    baseInfo: '基础信息',
    baseConfig: '基础配置',
    otherBaseInfo: '基本信息',
    wxBuiness: '微信商圈',
    opend: '已开通',
    notOpened: '未开通',
    mallId: '商圈商户号',
    mallIdRequired: '请输入8～10位商户号',
    wxMallIdExtra: '申请商圈时所对应商户的微信商户号',
    aliMallIdExtra: '申请商圈时支付宝分配的商圈id',
    buinessVersion: '商圈版本',
    versionThird: '新版3.0',
    versionSecond: '2.0',
    brandId: '品牌ID',
    quickPointsPopup: '快速积分弹窗',
    show: '展示',
    hide: '不展示',
    quickPointsPopupExtra:
        '未授权商圈的会员可在小程序平台首页、首页、会员中心、积分明细、积分商城、停车缴费首页看到引导开通弹窗。',
    example: '示例',
    configureBoot: '配置引导',
    aliBuiness: '支付宝商圈',
    merchantInfo: '圈店专柜设置',
    merchants: '已圈专柜',
    merchantTips: '请确认该专柜已成功添加至所在门店商圈后勾选，已选专柜在快速积分列表中展示',
    exportStoreInfo: '导出门店信息',
    total: '共 {{total}} 条记录',
    isDisabled: '是否禁用',
    isEnable: '是否启用',
    viewFloor: '查看楼层',
    storeCodeRules: '门店编码格式错误，只能输入字母+数字，最多输入6位',
    subsiteExportInfo: '数据正在处理中，请稍后前往',
    dataExportAndImport: '数据-导入/导出记录',
    applicationSubmitted: '汇款申请已提交',
    applicationSubmittedAnd: '汇款申请已提交，可在',
    manageRemittance: '管理分账汇款 &gt; 汇款订单明细',
    orderDetails: '中查看汇款信息订单明细',
    know: '知道了',
    remittance: '汇款',
    inAll: '共',
    ge: '个',
    determineOn: '确定对',
    sendStatus: '发送状态：定时发送',
    remittanceIndividualOrders: '笔订单进行汇款',
    yuan: '元',
    giftBoxCartoonPictureExtra:
        '图片尺寸建议为750px * 600px，图片大小不能超过3MB，动画长度建议小于3秒',
    picLimit750: '图片尺寸建议为750px*3120px',
    picLimit140: '图片尺寸建议为140px*56px',
    picLimit480: '图片尺寸建议为480px*88px',
    picLimit100: '图片尺寸建议为100px*44px',
    picLimit600: '图片尺寸建议为600px*740px',
    picLimit750_2: '图片尺寸建议为750px * 600px',
    picLimit420: '图片尺寸建议为420px*336px',
    picLimit250: '建议尺寸:750 * 250 大小:不超过1M 格式:JPG,PNG,JPEG',
    picLimit300: '图片尺寸建议为宽300px*高390px',
    picLimit320: '图片尺寸建议为宽686px*高320px',
    picLimit128: '图片尺寸建议为宽100px*高128px',
    picLimit1000: '图片尺寸建议为宽750px*高1000px',
    picLimit550: '图片尺寸建议为宽550px*高156px',
    picLimit2540: '图片建议尺寸：宽750px*高2540px',
    picLimit210: '图片尺寸建议为420px*210px',
    picLimit1640: '图片尺寸建议为750px * 1640px',
    picLimit88: '图片尺寸建议为88px * 88px',
    picLimit680: '图片尺寸建议为680px * 960px',
    picLimit160: '图片尺寸建议为300px * 160px',
    picLimit520: '图片尺寸建议为520px * 720px',
    picLimit123: '图片尺寸建议为123px * 60px，该图标显示在发现板块置顶笔记的左上方,',
    totalAmountRemitted: '待汇款总金额：',
    decorationTemplate: '装修模板',
    creditEshop: '积分商城',
    internalPurChase: '企业内购',
    inUse: '使用中',
    deactivated: '已停用',
    activityTag: '活动标签',
    applicableActivity: '适用活动',
    tagName: '标签名称',
    editTag: '编辑标签',
    addTags: '新增标签',
    activityTagRequired: '请输入标签名称',
    activityTagNotEmpty: '标签名称不能为空',
    applicableActivityRequired: '请选择适用活动',
    sortTip: '数值越大越靠前',
    sortTip2: '数值越大排序越靠前',
    tagStatus: '标签状态',
    applicableSubsite: '适用门店',
    applicableSubsiteAndMerchant: '适用门店//专柜',
    applicableSubsiteRequired: '请选择适用门店',
    selectSubsite: '请选择门店',
    otherSelectSubsite: '选择门店',
    tagIntro: '标签说明',
    addTag: '添加标签',
    picLimit540: '图片尺寸建议为540px*540px',
    enterNoMoreThan50Characters: '请输入50个字符以内',
    tagIntroRequired: '请输入标签说明',
    createActivityTags: '创建活动标签',
    viewData: '查看数据',
    beforeOpen: '已开启',
    beforeClose: '已关闭',
    areadyClosed: '已被关闭',
    areadyOpend: '已被开启',
    isAreadyClose: '是否关闭',
    isAreadyClosed: '是否关闭标签',
    isAreadyOpend: '是否开启',
    confirmDelete: '确定删除吗 ?',
    copyLink: '复制链接',
    mobileScanPreview: '手机扫码预览',
    orClick: '或点击',
    onMobileOpen: '在手机打开',
    entityOrder: '实体订单',
    notConfigH5: '暂未配置h5站点',
    copySuccess: '复制成功',
    copyError: '复制失败',
    copyH5Link: '复制H5链接',
    generateH5LandingPage: '请生成H5落地页',
    success: '成功',
    miniProgramPath: '小程序路径',
    officialTemplateIntro1:
        '1、使用微信公众号模板消息需先绑定认证服务号，并于微信公众号后台开通“模板消息”功能；',
    officialTemplateIntro2:
        '2、类目模板消息请先确认公众号已添加对应服务类目（服务类目：生活服务>百货//超市//便利店；交通服务>停车服务；工具>预约//报名；工具>信息查询；商业服务>软件//建站//技术开发）',
    officialTemplateIntro3:
        '3、每个认证服务号或小程序最多可同时启用25个模板ID（公众号相同的模板ID计为1个；小程序相同的模板ID且字段及顺序相同时计为1个）；公众号或小程序已添加的模板一旦达到25个，可能无法正常推送消息，此时请商家进入微信公众号后台删除部分不启用的模板。',
    wxShortChain: '微信小程序短链',
    aliShortChain: '支付宝小程序短链',
    qrCode: '二维码',
    recommendGoodItems: '给你推荐了一款可「砍价购」的好物',
    bargainingBenefits: '砍价享',
    poster: '海报',
    downLoading: '下载中',
    clickSavePic: '右击保存图片',
    downLoad: '下载',
    printQrcode: '打印二维码',
    copy: '复制',
    advertisingH5ToWx: '投放三方平台h5链接(唤起微信小程序)',
    advertisingH5ToAli: '投放三方平台h5链接(唤起支付宝小程序)',
    parkingPayment: '-停车缴费',
    payGift: '-支付有礼',
    notSupportedPromotion: '暂时不支持该页面推广',
    defaultChannel: '默认渠道',
    generating: '生成中',
    aggregationCode: '聚合码',
    wxCode: '微信小程序码',
    aliCode: '支付宝小程序码',
    generalization: '推广',
    typeError: '缺少type参数',
    distributionCenter: '分销中心',
    viewCheckoutTotal: '查看结算金额',
    communityGroupBuying: '社区团购',
    service: '服务',
    onlineCoupon: '线上优惠券',
    offlineCoupon: '线下优惠券',
    subsitesOrAll: '门店//门店群',
    muchSubsites: '个门店',
    hideSubsite: '隐藏门店',
    viewSubsite: '查看门店',
    allClear: '全部清空',
    subsiteHasLeader: '门店下已有团长，不支持关闭门店招募团长',
    allSubsites: '全部门店',
    addFooterNav: '新增底部导航',
    setFooterNav: '设置底部导航',
    subsiteFooterNav: '门店底部导航',
    small: '小',
    middle: '中',
    big: '大',
    productSelectRequired: '请选择商品',
    selectPageRange: '请选择页面范围',
    setCustomIcon: '请设置主窗口自定义图标',
    pleaseSetTheMainWindowCustomNum: '请输入子窗口最多显示数量',
    selectedLeastWindow: '至少勾选一个子窗口',
    displayTitle: '展示标题',
    displayStartTime: '展示开始时间',
    displayEndTime: '展示结束时间',
    displayTitleRequired: '展示标题不能超过16字符',
    dispalyTime: '展示时间',
    applyPage: '应用页面',
    pageDecoration: '页面装修',
    mainWindow: '主窗口',
    mainWindowExtra:
        '当主窗口中子窗口数量超过上限（除回到顶部），手机端页面显示主窗口收起效果，点击展开，再次点击收起',
    childWindow: '子窗口',
    childWindowExtra:
        '回到顶部悬浮窗始终展示在页面上，不会被收纳到主窗口中。悬浮窗默认半透明展示，点击高亮，如果屏幕暂停不动，悬浮窗恢复半透明。',
    buttonSize: '按钮大小',
    buttonSizeExtra:
        '小中大对应的尺寸分别是44px、56px、64px。仅影响页面中悬浮图标的尺寸，不影响子窗口中的图标尺寸。',
    inprogress: '进行中',
    notStarted: '未开始',
    end: '已结束',
    activityStatus: '活动状态',
    addFloatAction: '新增悬浮窗',
    editFloatAction: '编辑悬浮窗',
    copyFloatAction: '复制悬浮窗',
    applicableScope: '适用范围',
    platform: '平台',
    advTitleRequired: '广告标题不能超过16字符',
    popupAdv: '弹窗广告',
    displayPosition: '展示位置',
    checkoutPage: '结算页',
    configRequired: '每个用户每天固定展示3次',
    triggerConditions: '触发条件',
    triggerNumber: '触发次数',
    targetAudience: '投放人群',
    all: '全部',
    linkConfig: '链接配置',
    picNumber: '图片数量',
    singleFixed: '单图固定',
    multScroll: '多图轮播',
    carouselInterval: '轮播间隔',
    advPic: '广告图片',
    pictures: '图片',
    thanLimit: '超出限制',
    resourceExtra: '图片格式：.jpg，图片尺寸：200px*200px，图片大小不能超过 2MB',
    topicResourceExtra: '图片格式：.jpg，图片尺寸：700px*400px，图片大小不能超过 2MB',
    pictureUploadLimit1: '超出限制，最大上传 1M 图片',
    pictureUploadLimit: '超出限制，最大上传 2M 图片',
    picturesExtra: '最多可添加20张图片，可拖动排序，建议宽度750px，高度不超过1000px，大小不超过1M',
    addAdv: '新增广告',
    editAdv: '编辑广告',
    copyAdv: '复制广告',
    picLimitTips: '建议图片宽度为750px，高度不超过1000px；大小不超过1M；',
    linkTypeShort: '转链并生成短链接',
    selectPromotionalStore: '请先选择推广门店',
    addPicTextNav: '添加图文导航',
    advName: '广告名称',
    advTitleNot20Character: '广告标题不能超过20字符',
    advNameExtra: '该内容不展示在前端，仅便于后台查看',
    showTime: '投放时间',
    selectShowTime: '请选择投放时间',
    dispalyRule: '展示规则',
    displayFrequency: '展示频次',
    customFrequency: '自定义频次',
    data: '数据',
    wxMiniP: '微信小程序',
    aliMiniP: '支付宝小程序',
    contentType: '内容类型',
    APP: 'APP',
    video: '视频',
    productNumJian: '商品数量(件)',
    addContent: '添加内容',
    pictureNotMore3Pic: '最多添加 3 张图片，建议尺寸750px * 1600px',
    uploadVideo: '上传视频',
    videoLimit: '超出限制，最大上传 5M 视频',
    videoExtra: '支持MP4视频格式，大小不超过5M，建议时限20s内',
    bargainButtonPictureExtra: '建议尺寸：宽550px, 高88px。',
    multiCustom: '多图随机',
    position: '跳过按钮位置',
    rightTop: '右上',
    rightBotton: '右下',
    leftTop: '左上',
    leftBotton: '左下',
    countDown: '跳过倒计时',
    subWinNum: '子窗口最多显示数量',
    second: '秒',
    everyDay: '每天',
    eachTime: '每次进入',
    permanent: '永久一次',
    frequency: '次',
    browse: '浏览',
    click: '点击',
    frequencyNum: '次数：',
    people: '人',
    peopleNum: '人数：',
    notData: '暂无数据',
    mostSupport: '最多可支持',
    notAdd: '档，不可再添加',
    clearSetting: '清空设置',
    carparkTips: '尺寸：宽750*300；格式：jpg、jpeg；大小：小于1M',
    tpccjyw300: '图片尺寸建议为300px*300px',
    pictureUploadLimit2: '超出限制，最大上传',
    mPictures: 'M 图片',
    isDeletePictures: '是否删除该图片广告',
    addAdvs: '增加广告',
    createSearchPage: '新建搜索页',
    page: '页面',
    seniorSetting: '高级设置',
    topNav: '顶部导航',
    pageStyle: '页面风格',
    settingToWxMiniP: '此设置仅对小程序页面生效',
    themeStyleTips: '如：黑卡会员享8折，不超过20字',
    layoutStyle: '布局样式',
    classical: '经典',
    simplicity: '简约',
    fontStyle: '字体主题色',
    highLightButton: '高亮按钮',
    pageBackground: '页面背景',
    backgroundImagelimit2: '建议图片宽度为750px，高度不限；大小不超过1M；格式:JPG,PNG,JPEG',
    backgroundImgLimit: '建议图片宽度为750px，大小不超过1M',
    pictureLimitExtra: '图片尺寸建议为750px * 800px',
    mainColor: '主色：',
    applyIconFont: '应用于高亮字体，标签，图标',
    auxiliaryColor: '辅色：',
    applyPriceFont: '应用于价格字体',
    displayEffect: '展示效果：',
    solidColor: '纯色',
    gradientColor: '渐变色',
    color: '颜色：',
    foregroundColor: '前景色：',
    backgroundColor: '背景色：',
    gradientType: '渐变方式：',
    portrait: '纵向',
    transverse: '横向',
    selfOperated: '自营',
    addCart: '加入购物车',
    buy: '立即购买',
    decrement: '满减',
    folding: '满折',
    limit: '限购',
    topicActivity: '专题活动页',
    display: '显示',
    noDisplay: '不显示',
    selectDisplayTip: '选择显示，将在活动商品列表展示活动倒计时',
    addActivity: '新增活动',
    editActivity: '编辑活动',
    copyActivity: '复制活动',
    themeStyleExplain: '针对展示灵活的页面，可选择展示或者隐藏页面标题',
    belongSubsite: '所属门店',
    belongMerchant: '所属专柜',
    baseSetting: '基本设置',
    merchantName: '专柜名称',
    merchantNameRequired: '请输入专柜名称',
    nameEn: '英文名称',
    noSupportChinese: '不支持输入中文字符',
    merchantCode: '专柜编码',
    merchantCodeRequired: '请输入专柜编码',
    merchantCodeRules: '请输入，最多输入20个字符',
    merchantType: '专柜类型',
    merchantStatus: '专柜状态',
    merchantTypeRequired: '请选择专柜类型',
    joint: '联营',
    merchantSelf: '自营',
    league: '加盟',
    lease: '租赁',
    merchantLogo: '专柜logo',
    viewDiagram: '查看示意图',
    logoPictureTip: '建议尺寸140*140, 2M以下',
    promotionalImages: '宣传图',
    propagatePictureLimit: '建议尺寸750*400， 2M以下',
    merchantIntro: '专柜介绍',
    merchantAddress: '专柜详细地址',
    merchantTag: '专柜标签',
    merchantSort: '专柜排序',
    merchantStyle: '专柜风格',
    indoorMap: '室内地图',
    merchantPosition: '专柜位置',
    merchantOperation: '专柜经营',
    businessCategory: '经营类目',
    mainIndustry: '主营行业',
    allowQuickBilling: '导购端快速开单业务',
    allowQuickBillingTip: '导购可以在导购端使用商家码,自定义商品价格开单。',
    setMerchantCode: '设置专柜商家码',
    cashierBusiness: '买单收银业务',
    cashierBusinessExtra: '用户扫专柜商家码(二维码),填写消费金额，买单收银。',
    restaurantReservation: '开启预订服务',
    description: '预订须知',
    showContentExtra: '图片尺寸建议传宽度750px，高度不限，建议字号选择为14px',
    descriptionPlaceholder: '您可输入活动规则，活动图片等信息',
    notMoreThan2000: '不能超过20000个字',
    l_notMoreThan2000_1: '请输入兑换说明，不能超过20000个字',
    l_notMoreThan2000_2: '请输入活动规则，不能超过20000个字',
    l_notMoreThan2000_3: '自助积分指南不能为空, 不能超过20000个字',
    descriptionRules: '您可输入活动规则，活动图片等信息',
    descriptionExtra: '建议传宽度为375px的图片；建议字号选择为14px',
    merchantDeliveryAddress: '发货地址',
    merchantSingleAddress: '专柜独立配置发货地址',
    onlyNumber: '仅限输入数字',
    merchantAfterSaleAddress: '专柜独立配置售后地址',
    selectRange: '请选择区域',
    description2Extra:
        '图片尺寸建议传宽度686px，高度不限，建议字号选择为14px，{帮砍人数}、{商品底价}、{发起次数}均是变量，取该砍价活动所配置的值，最多1000个字',
    moreThan100: '请输入，最多输入100个字符',
    moreThan120: '最多120个字符',
    afterSaleAddress: '售后地址',
    merchantBackType: '使用门店返还方式',
    merchantIndependentType: '使用专柜独立返还方式',
    independentAfterSaleBackModes: '退货商品返还方式',
    companyInfo: '企业相关',
    companyName: '企业全称',
    companyType: '企业类型',
    companyScale: '企业规模',
    companyLegal: '企业法人',
    companyLegalIdNumber: '企业法人证件号',
    accountName: '企业开户名称',
    companyBankName: '企业开户行',
    companyAccountNumber: '企业银行账号',
    companyBusinessPicture: '企业工商执照',
    taxRegistrationPicture: '企业税务登记证',
    organCodeCertificatePicture: '组织机构代码证',
    organCodeCertificatePictureExtra: '建议尺寸122*30， 2M以下',
    openLicensePicture: '银行开户许可证',
    legalFrontPicture: '法人证件扫描件正面',
    legalBackPicture: '法人证件扫描件反面',
    operatorInfo: '负责人相关',
    operatorName: '运营者姓名',
    name: '姓名',
    operatorDepartment: '运营者部门//职位',
    email: '邮箱',
    phone: '手机',
    phoneNum: '手机号',
    operatorCertFrontPicture: '运营者证件扫描件正面',
    operatorCertBackPicture: '运营者证件扫描件反面',
    serviceTagStylePicture: '专柜服务图标（专柜页）',
    serviceTagStylePictureExtra: '建议尺寸宽度350px， 2M以下',
    logoMiniPicture: '专柜服务图标（商场导览）',
    logoMiniPictureExtra: '建议尺寸宽度122px， 2M以下',
    noPerationPermission: '无操作权限',
    createTag: '新建标签',
    createStyle: '新建风格',
    onlyValidTenants: '仅对开通内容板块的租户有效',
    userChooseNotes: '用户选择风格喜好，基于用户喜好推荐指定风格专柜的笔记',
    createFloor: '添加楼层',
    floors: '楼层',
    floorPosition: '楼层具体位置',
    positionSort: '位置排序',
    indoorSid: '室内地图SID',
    hall: '大厅',
    privateRoom: '包间',
    bookableLocation: '可预订位置：',
    controlUserChooseTime: '配置后，控制用户在前端可选的时间',
    lunch: '午餐',
    dinner: '晚餐',
    to: '至',
    videoAddressOrUploadVideo: '请填写视频地址或上传视频',
    selectMerchant: '请选择所属门店',
    atLeastOneBackType: '至少选择一种商品返还方式',
    fillInIndoorSid: '请填写室内地图SID',
    selectLimitLevels: '请选择限制可预订的会员等级',
    fillInDateDays: '请填写可预订日期的天数',
    selectBookPosition: '请选择可预订的位置',
    fillInBusinessTime: '请填写完整的营业时间',
    businessTimeNotOverlap: '营业时间不能重合',
    selectLasterRegion: '请选择最后一级区域',
    createSuccess: '创建成功',
    productType: '商品类型',
    bigCode: '商家码',
    bigCodeRequired: '请输入商家码',
    bigCodeName: '商家码名称',
    bigCodeNameRequired: '请输入商家码名称',
    selectBeforeMerchant: '请先选择门店',
    allowPriceAdjustment: '导购开单售价模式',
    allowPriceAdjustmentExtra:
        '自定义售价-导购可添加任意价格售卖，如有原价则售价不可超过原价；按原价售卖-导购不可更改售价，只能按原价售卖，原价必填且大于0。',
    customPrice: '自定义售价',
    sellOriginalPrice: '按原价售卖',
    isSupportInvoice: '是否支持开票',
    yes: '是',
    no: '否',
    basicSetting: '基础设置',
    selectSubsites: '选择专柜',
    bigCodeOriginPrice: '商家码原价',
    priceRules: '价格格式错误，不可为0，价格最大值为9999999.99，仅保留2小位小数',
    pleaseInputNumber: '请输入数字',
    otherSetting: '其他设置',
    invoiceName: '开票名称',
    invoiceNameRequired: '请输入开票名称',
    taxCode: '税收分类编码',
    taxCodeRequired: '请输入税收分类编码',
    sequenceValue: '排序值',
    barCodes: '商品条码',
    taxRate: '税率',
    taxRateRequired: '请填写税率',
    taxRateMessageError: '开单售价模式为按原价售卖时，商家码原价必填不可为0',
    productPictures: '商品图',
    productPicturesExtra: '尺寸建议750X750（正方形模式），大小2M以下，最多1张',
    addMerchatCode: '新增商家码',
    editMerchantCode: '编辑商家码',
    batchImport: '批量导入',
    import: '导入',
    couponIntro1: '1.在文件中填写需要导入的信息（样式如图所示），然后上传该文件',
    couponIntro2: '注：表头名称必须填写为“手机号码”（不含符号、空格等其他字符）,否则会导致执行错误',
    merchantBigCodeIntro1: '1.在文件中填写需要导入的信息（样式见附件），然后上传该文件',
    merchantBigCodeIntro2:
        '2.当前仅支持xlxs、xls、csv格式文件（建议文件大小在20M以内且行数在10万以内，请使用Office2010及以上版本，否则可能出现乱码',
    merchantBigCodeIntro3: '3.请严格参考模版内的信息进行填写',
    merchantBigCodeIntro4:
        '4.批量导入如商家码在专柜内已存在，则会覆盖对应商家码，专柜内商家码未在专柜内创建，则新增商家码；',
    merchantBigCodeNote: '注：导入的内容格式正确，不含空格等其他特殊字符，否则会导入失败',
    batchDelete: '批量删除',
    confirmDeleteColumn: '确认删除选中的数据列吗',
    selectBatchDeleteColumn: '请选择需要批量删除的数据行',
    taxRateAllowNumber1to99: '标准税率只允许正整数1-99',
    standardTaxRate: '标准税率',
    dutyFree: '免税',
    controlUploadAccept:
        '2.当前仅支持{{controlUploadAccept}}格式文件（建议文件大小在20M以内且行数在10万以内，请使用Office2010及以上版本，否则可能出现乱码',
    nonTaxtion: '不征税',
    zeroTaxRate: '零税率',
    exportTaxRefund: '出口退税',
    set20ProductBarCode: '1个商家码最多设置20个商品条码',
    barCodeNotEmpty: '商品条码不能为空',
    barCodeNoHasSpace: '商品条码不能包含汉字，空格',
    barCodeNoRepeat: '商品条码不能重复',
    selectDeleteBarCode: '请选择要删除的条形码',
    tip: '提示',
    isDeleteSelectedBarCode: '是否删除已选择的条形码？',
    inputAddBarCode: '请输入需要新增商品条码',
    addBarCode: '新增商品条码',
    has13StandardCode: '用于快速识别商品所标记的唯一编码，比如：69开头的13位标准码',
    batchDeleteBarCode: '批量删除商品条码',
    productBarCode: '商品条码',
    uploadFileClickButton: '请上传文件成功后，点击导入按钮',
    uoloadFail: '上传失败',
    pleaseUploadFile: '请上传文件',
    selectFile: '选择文件',
    importBarCode: '导入商家码',
    supportExtension: '支持扩展名：',
    clickDownloadTemplate: '点击下载批量导入模版',
    importRules: '导入规则：',
    fileUploading: '文件正在上传，稍后可前往',
    toView: '中查看',
    categoryStatus: '分类状态',
    actionSuccess: '操作成功',
    createMerchantViewTag: '创建专柜时可将该标签关联到专柜下，用户在商场导览中可看到该标签',
    pleaseSelectMerchant: '请选择专柜',
    pleaseRightMobile: '请输入正确的手机号',
    pleaseClerkMobile: '请输入店员手机号',
    pleaseClerkName: '请输入店员名称',
    clerkAvater: '店员头像',
    avatarExtra: '建议图片宽度为260px，高度为260px；大小不超过1M；',
    enterpriseQrcode: '企业微信二维码',
    addClerk: '新增店员',
    enabledStatus: '启用状态',
    editUser: '编辑用户',
    addUser: '新增用户',
    loginName: '用户名',
    nickName: '会员昵称',
    memberId: '会员ID',
    accountStatus: '账号状态',
    invalid: '冻结',
    off: '注销',
    dateRange: '加入专柜时间',
    orderNumber: '订单编号',
    orderTypeName: '单据类型',
    serviceApplyNo: '逆向单号',
    tradeTypeName: '贸易方式',
    supplierName: '供应链来源',
    inputOrderNumber: '请输入订单编号',
    selectStartTime: '请选择开始时间',
    selectEndTime: '请选择结束时间',
    exportSearchResult: '导出查询结果',
    exportRecord: '导出记录',
    facilityName: '设施名称',
    facilityRules: '请输入设备名称，不超过20个字',
    facilityPosition: '设施位置描述',
    selectFillin: '请填写',
    belongFloor: '所在楼层',
    floorBelongPosition: '楼层位置',
    facilityPictures: '设施宣传图',
    iconPictureExtra: '图片尺寸建议为750px * 1334px',
    iconPicture: '设施宣传图不能为空',
    tagToFacility: '创建设施时可将该标签关联到设施下，用户在设施导览中可按标签筛选设施',
    multiStyleProduct: '一品多款组名称',
    multiStyleProductRequired: '请输入一品多款组名称',
    multiStyleProductRequiredExtra:
        '不会展示到前台，仅用于后台管理时使用，自定义适合理解、方便查询的名字。一般为其中一个商品名',
    productNameRequired: '请输入商品名称',
    productCode: '商品编码',
    productCodeRequired: '请输入商品编码',
    activityName: '活动名称',
    activityNameNotEmpty: '活动名称不能为空',
    productVos: '关联商品',
    styleGroupInfo: '规格信息',
    optionList: '规格设置',
    picTextSetting: '图文设置',
    applyMainPictures: '引用主款主图文',
    selectMainPic:
        '选择引用主款图片则表示该一品多款组下使用同一套主图及文描内容，同时，选择后主款图文将覆盖组内全部商品内图文，请慎重选择',
    createMultiStyleProduct: '新建一品多款组',
    setStyleInfo: '请设置规格信息',
    multiStyleProductNotEmpty: '一品多款组中商品不可为空',
    mainProductNotEmpty: '主款商品不能为空',
    create: '新建',
    salesChannels: '售卖渠道',
    selfChannel: '自有渠道（微信、支付宝、APP）',
    douyin: '抖音',
    salesChannelTip1: '自有渠道指微、支付宝、APP，商品创建后在该渠道不上架不展示；',
    salesChannelTip2:
        '抖音：勾选后商品才会同步到抖音的来客商家后台，需要先审核，审核通过后才可上架，上架后才可在抖音小程序及直播、短视频中露出;',
    salesChannelTip3:
        '所有的抖音产生的订单退款时都需要商家审核，请及时审核信息，避免降低小程序的信用分，导致小程序出问题',
    saleSubsites: '售卖门店',
    ruleSetting: '规则设置',
    selectSaleSubsites: '请选择售卖门店',
    selectCouponBag: '请选择优惠券//优惠券礼包',
    saleProductName: '售卖商品名称',
    selectSaleProductName: '请输入售卖商品名称',
    price: '价格',
    newPrice: '元，现价',
    inputPrice: '请输入价格',
    marketPrice: '市场价',
    marketPriceRules: '市场价大于0，支持到小数点后两位',
    maxSalesCount: '最大可售库存',
    countSetHundredMillion: '库存支持最多设置一亿',
    selectMaxSalesCount: '请输入最大可售库存',
    selectedChanelTip:
        '若勾选多个渠道上架，则库存在多个渠道共用，即一个品在多个渠道共同售卖出1000张优惠券',
    zhang: '张',
    productPic: '商品图片',
    uploadProductPic: '请上传商品图片',
    productPicExtra:
        '尺寸建议750x750,大小2M以下,最多10张 (可拖拽图片调整显示顺序 ),支持jpg、png格式',
    saleRules: '售卖规则',
    saleTime: '开售时间',
    immeSale: '立即售卖',
    timingSale: '定时售卖',
    openSaleTime: '开售时间',
    openSaleTimeRequired: '请选择开售时间',
    saleLimit: '售卖限制',
    allCanBuy: '不限制，所有人可购买',
    specifyBuy: '指定会员人群购买',
    selectVipPeople: '选择会员人群',
    afterService: '售后服务',
    douyinRules:
        '在抖音渠道售卖的品，默认过期退、随时退，不支持配置；在自有渠道售卖的品可支持如下配置',
    jian: '件',
    orderData: '订单数据',
    orderTotal: '订单总数',
    orderTotalAmount: '订单总金额',
    salesCount: '商品总销量',
    memberCount: '下单人数',
    toEndOnly: '距活动结束仅剩',
    limitUser: '每用户限购',
    visualProductCategory: '虚拟商品营销分类',
    configExtra: '配置后，将会展示在前端售卖优惠券列表页的分类处。',
    limitStock: '锁定库存',
    initSaleVolume: '初始销量',
    productInfo: '商品详情',
    productInfoRequired: '请输入商品详情，不能超过20000个字',
    selectSharePrice: '请输入分摊价格',
    stockRule: '锁定库存不能大于库存',
    selectApplyPeople: '请选择适用人群',
    cash: '代金券',
    gift: '礼品券',
    freight: '运费券',
    parking: '停车券',
    lottery: '抽奖券',
    package: '优惠券礼包',
    balance: '长益余额券',
    bfplat: '长益编码券',
    efutureCash: '富基面值券',
    efutureBalance: '富基余额券',
    bfplatTip: '长益券联动，线上线下通享，促进转化',
    selectDouyinProduct: '请选择抖音商品',
    batchShelvesSuccess: '批量下架成功',
    batchListingSuccess: '批量上架成功',
    batchTimingUpShelf: '批量定时上架',
    batchTimingDownShelf: '批量定时下架',
    selfChannelUpShelf: '自有渠道上架',
    selfChannelDownShelf: '自有渠道下架',
    downDownShelf: '抖音渠道下架',
    channelUpShelf: '渠道上架',
    channelDownShelf: '渠道下架',
    batchAction: '批量操作',
    timingUpShelf: '定时上架',
    timingDownShelf: '定时下架',
    time: '时间',
    productInformation: '商品信息',
    channelProductId: '渠道商品id',
    auditStatus: '审核状态',
    upAndDownShelfStatus: '上下架状态',
    douyinUpShelf: '抖音渠道上架',
    approved: '审核通过',
    rejected: '审核未通过',
    auditing: '审核中',
    waiting: '待审核',
    currentTimePassTiming: '定时时间应晚于当前时间',
    batchTiming: '批量定时',
    upShelf: '上架',
    offShelf: '下架',
    couponInfo: '优惠券信息',
    couponContent: '券内容',
    auditFailResult: '审核失败原因',
    auditLog: '审核日志',
    saleStock: '可售库存',
    timingUpShelfTime: '定时上架时间',
    timingDownShelfTime: '定时下架时间',
    createSaleRule: '新建售卖规则',
    notListed: '未上架',
    houniao: '候鸟',
    alreadyShelf: '已上架',
    alreadyOffShelf: '已下架',
    saleCouponName: '售卖优惠券名称',
    selectSaleCouponName: '请输入售卖优惠券名称',
    couponRuleName: '优惠券规则名称',
    selectCouponRuleName: '请输入优惠券规则名称',
    couponType: '优惠券类型',
    allChannel: '全部渠道',
    selfOwnChannel: '自有渠道',
    allAuditStatus: '全部审核状态',
    timeSetSuccess: '时间设置成功',
    couponManage: '优惠券管理',
    couponManageWarn: '抱歉，您无优惠券促销栏目权限',
    refresh: '刷新',
    selectCouponGift: '选择优惠券礼包',
    couponGiftName: '优惠券礼包名称',
    giftsxTime: '礼包生效时间',
    giftInvalidTime: '礼包失效时间',
    couponBaCode: '优惠券编码',
    setCouponType: '制券方式',
    setOnline: '线上制券',
    setOffline: '线下制券',
    couponStartTime: '券生效时间',
    couponEndTime: '券失效时间',
    applyProductInfo: '引用商品资料',
    beforeSalePrice: '请先设置售卖价格',
    setSharePrice: '设置分摊价',
    notPrivilege: '您无权限',
    peopleName: '人群名称',
    createType: '创建方式',
    limitPeopleCount: '因数据实时变化，故此处人数为预估，以实际执行人数为准',
    contactManager: '如需使用请联系客户经理开通',
    salesElectronicTip:
        '支持券包中所有券均未使用，整券包售后。用户申请售后时不可退券包里的单张券。过期系统自动退时需券包中所有单张券均过期才会退款',
    giftRefund: '礼包整退',
    cobinName: '组合商品名称',
    cobinNameRequired: '请输入组合商品名称',
    cobinNameRule: '请输入组合商品名称，不超过60字',
    combinCode: '组合商品编码',
    productCategory: '商品分类',
    combinCategory: '组合商品分类',
    selectCombinCategory: '请选择组合商品分类',
    childProductOrigin: '子商品来源',
    subsiteProduct: '门店商品',
    merchantProduct: '专柜商品',
    combinOrigin: '指组合中的单品来源。组合后的组合商品也将属于该门店//专柜',
    selectCorreSubsite: '请选择关联门店',
    selectCorreSubsiteAndMerchant: '请选择关联门店/专柜',
    selectCOrreMerchant: '请选择关联专柜',
    productBrand: '商品品牌',
    tradeType: '贸易类型',
    subscibeSubsite: '订阅门店',
    subscibeMerchant: '订阅专柜',
    subscibeProductToMerchant: '所订阅的商品数据将保存在所选专柜中',
    productBrandRequired: '请输入商品品牌',
    combinBrand: '组合商品品牌',
    selectProductBrand: '请选择组合商品品牌',
    productDetail: '商品明细',
    imagePicConfig: '图文配置',
    isApplySinglePic: '是否引用单品图片',
    limit100MPic: '超出限制，最大上传 100M 图片',
    limit200MPic: '图片尺寸建议为200px*200px， 图片大小不能超过2MB',
    proposalSize: '建议图片宽度为750 X 750像素，最多上传15张',
    mainVideo: '主图视频',
    limit100MVideo: '超出限制，最大上传 100M 视频',
    supportMp4: '支持MP4视频格式，大小超过100M，建议时限20s内，建议视频尺寸与商品图片一致',
    isApplySingleDetail: '是否引用单品详情',
    otherSet: '更多设置',
    buyStartSale: '立即开售',
    timingStartSale: '定时开售',
    messageSubscription: '定时开售前5分钟给用户发送提醒，但需先开启订阅消息',
    saleDate: '开售日期',
    selectSaleDate: '请选择开售日期',
    productDeliveryManage: '商品配送方式管理',
    useSubsiteType: '使用门店//专柜配送方式',
    singleSetDeliveryType: '为商品单独设置配送方式',
    deliveryType: '配送方式',
    limitRule: '限购规则',
    notLimit: '不限购',
    limitSet: '限购设置',
    limitType: '限购方式',
    everyLimit: '每单限购',
    perPersonLimitNum: '每人限购',
    perPersonLimitNumRequired: '请输入限购数量',
    limitNumberRequired: '请输入限购数量',
    notLimitZero: '不限购填零',
    singleLimitProduct: '每货品限购',
    moreLimitProduct: '多货品限购',
    everyDay2: '每日',
    everyWeek: '每周',
    everyMonth: '每月',
    everyYear: '每年',
    notSuportSingleAfterSale: '不支持单品售后',
    afterSaleRule: '售后规则',
    jianAndPeople: '件//人',
    suportSingleAfterSale: '支持单品售后',
    detailDisplayCombin: '详情页展示组合明细',
    detailHideCombin: '详情页隐藏组合明细',
    displayRulePic: '展示规则图例',
    clickView: '点击查看',
    isUpShelf: '是否立即上架',
    createSuccessContent: '创建成功后，该组合商品直接为已上架状态',
    brandName: '品牌名称',
    brandNameCn: '品牌名称（中）',
    brandNameEn: '品牌名称（英）',
    hideBrand: '隐藏品牌',
    viewBrand: '查看品牌',
    combinNum: '组合数量',
    childProductName: '子商品名称',
    inputCombinNum: '请输入组合数量',
    combinProductNotZero: '组合商品销售价不可为0',
    selectDelivery: '请选择配送方式',
    inputLimitCount: '请输入限购数',
    addCombinProduct: '新增组合商品',
    editCombinProduct: '编辑组合商品',
    onlySelectCommonMerchant: '只能选择同一门店下的专柜',
    selectSubsiteOrMerchant: '选择门店或专柜',
    specifyMerchantOrSubsite: '指定门店及专柜',
    selectBrand: '选择品牌',
    hasSharePriceYuan: '分摊价格(元)',
    priceYuan: '销售价(元)',
    salePriceYuan: '市场价(元)',
    saleCount: '可卖数',
    keywords: '关键词',
    enabled: '已启用',
    inputKeywords: '请输入关键词',
    keywordsRequired: '请输入关键词，不超过20个字',
    plate: '板块',
    plateId: '板块ID',
    selectPlate: '选择板块',
    contPlate: '内容板块',
    commePlate: '电商板块',
    selectPage: '选择页面',
    addKeywords: '新增关键词',
    afterDelete: '已删除',
    isCloseKeywords: '是否关闭关键词',
    selectLinkTarget: '请选择链接目标',
    keywordsSuccess: '关键词新增成功',
    editKeywords: '编辑关键词',
    alreadyEnabled: '已被禁用',
    isEnabledKeywords: '是否禁用关键词',
    fillinAllSortRule: '请完整填写排序规则',
    goodName: '货品名称',
    goodCode: '货品编码',
    inputNumber: '请输入正整数',
    translationKey: '转译词键',
    translationKeyRequired: '请输入转译词键',
    escapeWordValue: '转译词值',
    escapeWordValueRequired: '请输入转译词值!多个以逗号隔开',
    addTranslationKey: '新增转译词',
    editTranslationKey: '编辑转译词',
    editSuccess: '编辑成功',
    downLoadingWaitMoment: '下载中，请稍后',
    clickPicViewRelation: '点击查看图片对应关系',
    clickDownActivityExample: '点击下载活动图片示例',
    picRelation: '图片对应关系',
    maximumWinningLots: '最多中签 {{maximumWinningLots}} 人',
    subsiteNonDeleveryType: '该门店下暂无配送方式',
    merchanteNonDeleveryType: '该专柜下暂无配送方式',
    combinProductSalePrice: '组合商品销售价（元）：',
    underlinedPrice: '划线价（元）：',
    sumMarketPricesGoods: '指组内子商品市场价之和',
    combinProductSaleNum: '组合商品可卖数：',
    underlinePricePic: '划线价示意图',
    productInventoryRule: '实物商品库存规则',
    visualInventoryRule: '虚拟商品库存规则',
    setInventoryRule: '设置库存展示规则',
    inventoryAdequate: '库存充足',
    inventoryNervous: '库存紧张',
    atLeastOneData: '最少保存一条数据！',
    fillInInventory: '请填写库存',
    inventorySupport: '库存仅支持>0的数字',
    selectInventoryUpper: '请填写库存上限',
    selectInventoryLower: '请填写库存下限',
    inventoryLowerNoThanInventoryUpper: '库存下限不能大于等于库存上限',
    selectHintLanguage: '请选择或输入提示语',
    upperAndLowerLimits: '上下限存在重叠区间',
    openAndClose: '开启/关闭',
    kai: '开',
    guan: '关',
    setInventory: '设置库存预警',
    inventoryDownLimit: '库存下限',
    inventoryUpLimit: '库存上限',
    hintLanguageType: '提示语类型',
    inventoryNum: '库存数',
    hintLanguage: '提示语',
    selectCustomHint: '请选择/自定义提示语内容',
    actualInventoryQuantity: '实际库存数=可卖数-锁定数',
    addRule: '添加规则',
    hasMuchCoupon: '优惠券{{totalCount}}张',
    rewardPicLimit: '图片尺寸建议为宽72px*高80p',
    specialValueNoRepeat: '规格项不能重复',
    selectSpecialValue: '请选择规格值',
    specialValueRepeat: '规格值重复',
    fillInAllSpecial: '请填写完整规格项',
    fillInSpecialItem: '请填写规格项',
    styleSpecial: '款式规格',
    addSpecialValue: '添加规格值',
    addSpecialItem: '添加规格项',
    isMainStyle: '是否主款',
    generateMultiStyleProduct: '生成一品多款组',
    acitivityTime: '活动时间',
    acitivityTimeRequired: '请选择活动时间',
    acitivityBeginTime: '活动开始时间',
    acitivityEndTime: '活动结束时间',
    activityRule: '活动规则',
    selectActivityName: '输入活动名称',
    activityNameRequired100: '请输入活动名称，不超过100个字',
    activityNameRequired: '请输入活动名称，不超过20个字',
    inventoryDimension: '库存维度',
    cycle: '周期',
    inventoryCycle: '库存周期',
    activityProduct: '活动商品',
    developPlan: '制定计划',
    editPlan: '编辑计划',
    isCloseActivity: '是否关闭活动',
    importProduct: '导入商品',
    importBeforeNotPage: '导入未完成之前，不要关闭页面，否则可能数据错误',
    importFail: '导入失败,请重新导入!',
    uploadFile: '请上传文件',
    uploadIntro: '上传说明',
    uploadIntro1: '1.在文件中填写需要导入的信息，然后上传该文件',
    uploadIntro2:
        '2.当前仅支持xlxs、xls、csv（建议文件大小在20M以内且行数在10万以内，请使用Office 2010及以上版本，否则可能出现乱码',
    uploadIntro3: '3.文件命名将作为导入数据文件名展示',
    uploadIntro4: '4.请正确填写商品编码，如重新导入，则会覆盖之前导入的商品；',
    downloadTemplate: '下载导入模板',
    selectCategory: '选品池',
    selectClassify: '选择分类',
    domesticTrade: '国内贸易',
    dutyPaidImport: '一般贸易',
    bondedDirectSupply: '保税直供',
    hongkongMail: '香港直邮',
    overseasDirectMail: '海外直邮',
    notSubscription: '直邮商品暂不能订阅，如需订阅请联系科码运营',
    cancelSubscriptionSuc: '取消订阅成功',
    subscriptionSuccess: '订阅成功',
    notMore: '没有更多啦～',
    productArea: '产地：',
    cancelSubscription: '取消订阅',
    productSubscription: '商品订阅',
    supplyPrice: '供货价',
    taxIncluded: '(已含税)',
    putAway: '收起',
    putAwayAll: '收起全部',
    launch: '展开',
    launchAll: '展开全部',
    style: '款式',
    canSaleNum: '可售数',
    supplyChainSales: '供应链销量',
    tenantTotalSales: '九衢销量',
    openTenant: '开通九衢业务',
    tenantAfterSalesRate: '九衢售后率（售后数量）',
    barcode: '国际条形码',
    commonProductRecom: '相同商品推荐',
    rishIndex: '风险指数',
    backSelectCategory: '返回选品池',
    productWare: '商品榜单',
    subscribeTime: '订阅时间',
    inputRightThanZero: '请输入正确的大于0的整数',
    subscribeAfterUpShelf: '订阅后自动上架',
    salePriceFloatRatio: '销售价随成本价浮动比例',
    unifiedFloat: '统一浮动',
    toCategoryFloat: '根据分类浮动',
    setNewSubscibeProduct: '设定后新订阅的商品使用当前的定价比例',
    allCategory: '全部分类',
    alarmEvents: '报警事件',
    noticeGoodsCostPrice: '成本价变更超过阈值',
    noticeGoodsStockFew: '订阅商品库存不足',
    noticeGoodsSupplierOffShelf: '已订阅商品在供应链端失效',
    noticeOfderCreateFail: '提交订单失败',
    paymentFail: '支付失败',
    orderCustomFail: '支付单报关失败',
    sendTime: '发送时间',
    startTime: '开始时间',
    endTime: '结束时间',
    alarmTime: '报警时间',
    alarmContent: '报警内容',
    bookkeepTime: '记账时间',
    number: '数量',
    supApportionType: '促销承担方式',
    supApportionRadio: '请输入促销承担百分比',
    priceDiff: '售价差',
    none: '无',
    supportZeroToOne: '仅支持输入0～1数字并且支持四位小数',
    supApportionRatio: '供应商促销承担值',
    singlePrice: '单价(含税)',
    couponAmount: '优惠券抵扣金额',
    pointAmount: '积分抵扣金额',
    tenantPayableFreight: '应付运费',
    tenantFreightDiscountAmount: '运费优惠',
    tenantDeliverMoney: '实付运费',
    paidAmount: '商品实际交易金额',
    costIncludeTax: '成本价是否含税',
    costPrice: '成本价',
    costTaxMoney: '不含税成本价增值税费',
    dutyDifferenceMoney: '跨境综合税差价',
    dutyMoney: '跨境综合税实际税费',
    supplierDeliverMoney: '供应链实际运费',
    revenueSharingRatio: '科码分成比例',
    revenueSharingAmount: '科码分成金额',
    returnGoodsCost: '退款时扣除金额',
    returnFreight: '商品退货运费',
    createTime: '记账时间',
    advertisingExpenses: '推广服务费',
    discountAmount: '优惠金额',
    tenantName: '租户名称',
    statusName: '封账状态',
    transactionTypeName: '收支类型',
    lastModifiedTime: '入账时间',
    paymentNo: '订单支付交易号',
    afterSalesOrderNo: '售后单号',
    orderPaidAmount: '订单实际交易金额',
    categoryName: '交易类型',
    transactionAmount: '交易金额',
    accountBalance: '账户余额',
    transactionReceiptFileUrl: '付款凭证',
    transferRemark: '付款备注',
    processing: '正在处理',
    processFinished: '处理完成',
    processFailed: '处理失败（系统异常)',
    activityMerchant: '活动门店',
    fileName: '文件名称',
    processEndTime: '处理结束时间',
    processStatus: '处理结果',
    addTimelimitActivity: '新增秒杀活动',
    salePriceNotEmpty: '秒杀价不可为空',
    stockNotEmpty: '库存不可为空',
    activitySubmitToViewData: '活动已提交，货品数据结果可前往',
    view: '中查看',
    bmitEdit: '本次共编辑',
    bmitEditContent1: '条货品',
    bmitEditContent2: '其中',
    bmitEditContent3: '条成功',
    bmitEditContent4: '条失败，可前往',
    downloadData: '下载数据',
    importResult: '导入结果',
    select: '选择货品',
    editTimelimitActivity: '编辑秒杀活动',
    reportDownloadCenterTip:
        '本次共导入{{totalCount}}条数据，其中{{successCount}}条成功，{{failCount}}条失败，可前往',
    importFailPrivity: '导入失败,权限不足!',
    againImport: '导入失败,请重新导入!',
    flashPrice: '秒杀价',
    flashStock: '秒杀库存',
    setFlashPrice: '设置秒杀价',
    flashPriceRequired: '请输入秒杀价',
    adjustFlashPrice: '调整秒杀可卖库存',
    currentSaleStock: '当前可卖库存',
    goodNum: '货号',
    goodBarCode: '货品条码',
    profit: '参考毛利',
    batchEdit: '批量修改',
    batchEditSeleced: '确认批量修改选中的{{name}}吗？',
    confirmEdit: '确定修改',
    selectSetDataColumn: '请选择需要批量设置的数据行',
    rewardInfos: '条件设置',
    productPoolSetting: '商品池设置',
    productPoolSettingExtra: '建议所选商品销售价不要相差10倍以上，否则可能导致负毛利',
    saveFail: '保存失败，请填写完整的条件设置',
    failResult: '保存失败，任选件数下一档需大于上一档',
    failResult2: '保存失败，打包金额下一档需大于上一档',
    tagNameRule: '请输入标签名称，不超过10个字',
    tagNameRule2: '请输入标签名称，不超过6个字',
    tagNameRule3: '请输入标签名称，最多30个字符',
    tagNageExtra: '显示在详情页、活动商品列表页、购物车列表页的活动标签',
    topPic: '顶部图片',
    topPicExtra: '图片建议宽750，上传后将展示在活动商品列表头部',
    countDown2: '倒计时',
    cashCouponName: '代金券名称',
    cashCouponNameRule: '请输入代金券名称，不超过30个字',
    cashCouponNameRequired: '请输入代金券名称',
    executeStatus: '券状态',
    stackScope: '叠加规则',
    noStack: '不可叠加',
    otherStack: '可与其他券叠加',
    selfStack: '可与自身券叠加',
    couponTime: '券时间',
    effectiveTime: '生效时间',
    effectiveTimeRequired: '请选择生效时间',
    dynamicEffectivePeriod: '有效期',
    dynamicEffectivePeriodDay: '有效期(天)',
    dynamicEffectivePeriodRule: '仅支持输入正整数，最大可设置366天',
    period: '不填写不限制',
    bindCoupon: '绑定卡券',
    bindCards: '绑定车辆展示',
    bindCardsAll: '全部展示',
    bindCardsNum: '限制展示',
    cardsNum: '限制数量',
    effectDay: '天内有效',
    effectDay_30: '最多30天',
    effectExtra:
        '有效期按自然天计算。举例：如设置绑定卡券5天以后失效，用户7月10日15:00领取优惠券，则该优惠券的可以时间为7月14日23:59:59不可用。',
    batchNo: '自定义券码',
    batchNoExtra: '如不填写系统自动生成，保存后不支持修改',
    stock: '库存',
    stockRequired: '请输入库存',
    productSetting: '商品设置',
    merchantAndSubsiteSelect: '按门店//专柜选择',
    subsiteSelect: '按门店选择',
    merchantSelect: '按专柜选择',
    allProduct: '全部商品',
    includeProduct: '指定商品参与',
    excludeProduct: '指定商品不参与',
    noneProduct: '无商品',
    activityProductTypeExtra:
        '全部商品包括所选门店和专柜的所有商品；若设置部分商品不参加，则默认其余商品可用该券；无商品代表该券使用时由人工判断是否可用；',
    remark: '备注',
    preferentialRules: '优惠规则',
    couponValue: '减免金额',
    couponValueRule: '减免金额不可为空，并且仅支持到小数点后两位',
    useThreshold: '使用门槛',
    nocondition: '无使用门槛',
    money: '订单金额满',
    quantity: '订单件数满',
    orderAmount: '订单金额',
    orderAmountYuan: '订单金额(元)',

    orderAmountRequired: '订单金额仅支持到小数点后两位',
    usedLowestLimitQuantityValue: '订单件数',
    inputThanZero: '请输入大于0的正整数',
    useRuleInfo: '领取和使用规则',
    receivePeriod: '优惠券领取//发放时间',
    receivePeriodExtra:
        '不配置，则不限券领取//发放时间，若配置则控制券在商品详情页或导购端是否展示，及通过优惠券组件是否可领券',
    noLimit: '不限制，所有人可领',
    noLimitAllUse: '不限制，所有人可用',
    memberLimit: '按会员卡等级限制',
    memberLimitText: '针对用户所在门店下等级，限制领取此优惠券',
    receiveLimit: '领取限制',
    crowdLimit: '按会员人群限制',
    timeLimit: '按注册时间限制',
    timeLimitTip: '针对用户的注册的时间范围，限制领取此优惠券',
    useLimit: '使用限制',
    addCoupon: '新增优惠券',
    memberLimitTip2: '按领取限制会员卡等级，限制使用此优惠券',
    memberLevels: '适用等级',
    memberLevelsTip: '若适用门店为多门店，请保证多个门店下卡等级一致，若不一致会有用户领取不到奖励',
    registerMemberSource: '限制注册人群',
    registOnline: '小程序注册新用户(线上新用户)',
    registOnlineTip: '在小程序未注册过的用户为线上新用户',
    registOffline: 'CRM注册新用户(线下新用户)',
    registTip: '在小程序上未注册过的，同时在三方渠道未注册过的用户',
    useLimitationsMode: '注册时间限制方式',
    useLimitationsModeDate: '按注册时间范围限制',
    useLimitationsModeDay: '按注册时间N天内限制',
    useLimitationsDate: '注册时间范围',
    selectUseLimitationsDate: '请选择注册时间范围',
    useLimitationsDateExtra: '在该时间范围内注册小程序的用户，可领取此优惠券',
    registDay: '注册天数',
    registDayMessage: '请输入注册天数，仅支持输入正整数，最小可设置1天，最大可设置999天',
    day: '天',
    registDayExtra:
        '自注册时间向后推N天内，均可领取此张优惠券。例如配置3天，用户于5月1日进行注册，那么在5月4日24点前均可领取',
    receiveQuantityLimitType: '领取数量限制',
    limitNumberNoLimit: '不限制，每用户领取数量',
    everyMemberLimit: '限制，每用户领取数量',
    everyMemberDailyLimit: '限制，每个用户每天领取数量',
    target: '领取后跳转的页面',
    limitQuantity: '领取数量',
    showChannels: '用户免费领取',
    showChannelsProduct: '用户可在商品详情页免费领取',
    showChannelsExtra: '用户可在商品详情页免费领取（秒杀、拼团商品除外）',
    usedChannelLimit: '核销渠道',
    usedChannelLimitExtra:
        '券只能在勾选的核销渠道使用，不勾选不能使用。线下到店核销场景指可线下Pos//店员扫一扫//导购核销使用',
    stackable: '优惠券叠加使用规则',
    stackablefalse: '不可叠加',
    stackableTrue: '允许叠加',
    stackableExtra:
        '不可叠加券不可与自身和其他优惠券一起使用，结算时只能使用1张优惠券；可叠加与同样勾选叠加优惠券且和自身券可一起叠加使用，自身券叠数量可编辑。',

    filter: '筛选',
    submitTime: '提交时间',
    qtxccgz: '请填写场次规则',
    dcjlspdmczfdjeddmjjjl: '单次奖励是判断每次支付的金额达到门槛即奖励',
    wxtsqxdsfzrnddbuy: '温馨提示：请携带身份证在x日内到店购买，逾期将取消购买资格',
    tkhjlhs: '退款后奖励回收',
    qgxbyqrjlx: '请勾选被邀请人奖励项，最少选一项',
    smewmyl: '扫描二维码预览',
    qsrfxwa: '请输入分享文案',
    qsrfxwa50Rule: '请输入分享文案，不超过50个字',
    qsrfxwa30Rule: '请输入分享文案，不超过30个字',
    confirmgq: '确认改签',
    dghdsrxtdhdzbmh:
        '多个活动输入相同的活动组编码后，系统认为这些活动在一个组内，用户在组内多个门店报名，只会在一个门店进行申购。',
    xzdjccxt: '选择对接车场系统',
    pzjhmLogo: '配置聚合码logo',
    afterSaleSetting: '售后设置',
    xpzdyyyydydzmd: '对接物流公司时，需配置打印员用于打印电子面单',
    qsrhxmdbm: '请输入核销门店编码',
    setMessageWfxzxrq: '已设置全部粉丝人群回复消息，无法新增新人群',
    skip: '跳过',
    xtzdqrshAfter: '发货后#value#天时，系统自动确认收货',
    xtzdqrsh: '天时，系统自动确认收货',
    yhnrxx:
        '修改后【优惠券库存减少、有效期、使用门槛、优惠内容信息】无法在微信卡包或支付宝卡包处同步做修改，导致卡包处展示的信息和实际不一致，请提前周知用户。确定修改吗？',
    qxqkptxg: '请先清空普通限购，再配置等级限购',
    hfdh: '回访电话',
    gjhd: '次，共计获得',
    rgxzaccbm:
        '报名开始时间不能晚于报名结束时间，如果选择按场次报名，那么选择活动的开始和结束时，是针对场次',
    tjzcdh: '添加子菜单后，一级菜单的内容将清除，确定添加子菜单？',
    ftjzhylbdmjbd: '分摊价总和与礼包单卖价不等',
    qtxlxdld: '请填写连续登陆第X天获得以下奖励的数值',
    szmdyzjdyfgx: '设置门店与专柜的运费关系',
    mrmcff: '每人每次发放',
    zjsj: '租借时间',
    standCategoryConfig: '标准分类，已配置',
    xxbnzf: '选项不能重复',
    deductionRuleExtra:
        '如您的规则为：最小抵扣单位100金币=1元，即100金币=1元，则用户每次必须使用100金币的整数倍(即100金币，200金币...以此类推)',
    xfm1000dk2ytcf:
        '如您的规则为：消费满1000元 抵扣2元停车费，若用户当日消费2600元，只会扣减用户1000元抵扣2元的停车费',
    qpzwzdztsj: '请配置完整的自提时间',
    bfsq: '部分授权',
    selectTime: '选择时间',
    zdysj: '自定义时间',
    qxzsyzj: '请选择适用专柜',
    windowName: '窗口名称',
    qshdjsrqhsj: '缺少活动结束日期和时间',
    qtxbmsj: '请填写报名时间',
    qsryxhz: '请输入邮箱后缀',
    qlxkhcgjljxkt: '需开通{forwardText}功能才可使用该功能，请联系客户成功经理进行开通',
    noApply: '未申请',
    dhtcjf: '兑换停车积分',
    merchantPage: '专柜页',
    zxffw: '至少选择1种消费范围',
    downloadPath: '下载路径',
    qxzmdqy: '请选择门店区域',
    fdtkddy: '6.4 本协议的标题仅为方便阅读所设，非对条款的定义、限制、解释或描述其范围或界限。',
    explanationExtra:
        '将展示在提现申请页，建议填写结算节点、打款方式等，上传图片建议尺寸宽710px*高不限',
    hxbj: '核销本金',
    commentedOn: '被评论',
    commentedOnIntro1:
        '如不勾选不包含作者自己评论，则作者给自己发布的图文//视频评论均计算被评论数量。',
    commentedOnIntro2:
        '如不勾选单个用户评论单条图文//视频多次评论仅计算一次，则用户评论单条图文//视频多次评论计算多次。',
    yhqtycg: '优惠券停用成功',
    fillInbjlz: '请填写HI呗奖励值',
    xgzfbdyjfhjyzdxs:
        '线上//下规则分别对应积分和经验值的线上//下规则（目前线下仅针对自助积分、商圈积分场景）',
    xwtj: '行为条件',
    jbyy: '举报原因',
    cysp: '参与商品',
    hyjgxcg: '会员价更新成功',
    btg: '不通过',
    settingSucess: '设置成功',
    qxzdbhp: '请选择打包货品',
    dyscd: '对已生成的{{num}}条专柜码修改链接跳转，推广码无变化',
    dyscdyg: '对已生成的{{num}}条员工码修改链接跳转，推广码无变化',
    tkze: '退款总额',
    tkzeYuan: '退款总额（元）',
    pltjmgz: '批量添加敏感字',
    bjkq: '编辑卡券',
    tqrs: '退券人数',
    gxcg: '更新成功',
    rnqwsgSuccess:
        '【签名】恭喜您申购的【商品名称】已申购成功！请您携带本人身份证，于【x】日内前往【门店名称】购买，逾期未购买将视为放弃购买资格。提货时请您出示身份证和申购码，经工作人员核验成功后方可购买，不可由他人代购。点击查看申购码s.co-mall.com//3lmo4（核销二维码或核销码列表页）',
    qdkc: '渠道库存',
    qsrccmc: '请输入车场名称',
    ptsplb: '普通商品列表',
    tcjf: '停车积分',
    sryl: '生日有礼',
    sjwkjb: '可跨级别，如VIP在满足VIP3获得条件后，直接从VIP1升级为VIP3',
    levelCardLimit: '等级卡面建议600*380像素，支持JPG、BMP、PNG格式，且图片小于2M',
    levelIconLimit: '等级图标建议66*30像素，支持JPG、BMP、PNG格式，且图片小于2M',
    audity: '审核',
    memberGiftManageNameRequired: '请输入礼品名称，不超过60字',
    gmhhdqjdkcy: '报名//购买后活动期间都可参与',
    lqsx: '领取时效',
    xztw: '选择图文',
    planNameRequired: '请填写计划名称。例如：钻石卡会员福利',
    rule: '规则',
    qtxdhdjfjg: '请填写兑换的积分价格',
    zdyxfc: '自定义悬浮窗',
    qxzkkfpzl: '请选择可开发票种类',
    xyxzsdsj: '开启后，买家下单选择同城配时，需要选择送达时间；商家按送达时间发货',
    redeCode: '兑换码',
    xzsffsqd: '新增三方发送渠道',
    ndkxcxxysrys: 'APP内打开小程序需要输入原始ID',
    shq: '售后券',
    wxOffcialAdjustRule:
        '4、微信公众号规则调整：模板内容将不支持①自定义颜色、表情符号②尾部//备注内容③首行内容；主内容不超过20 字；不再支持开启历史模板，已开启的历史模板可继续使用。',
    smjbnwZero: '售卖价不能为0',
    qdqksypzxxm: '确定清空所有配置信息吗?',
    gqd: '改签到',
    yjje: '应缴金额',
    yhqqm: '优惠券券码',
    qymcbnzf: '权益名称不能重复',
    zffsybkq: '支付方式已被开启',
    gspmtdhddh: '配置后，该商品每天兑换达到后，将不可再兑换',
    xtdjbbtdkmbyyrkm:
        '相同等级被不同的卡模板引用若卡面、卡等级图标发生变更后，相关的卡模板也会发生变更',
    ydjbs: '原单据标识',
    yybz: '原因备注',
    xszcmd: '线上注册门店',
    ccbfbzzxrhyh:
        '超出部分不每日停车优惠项（含优惠券、会员权益）累计上限，超出部分不在执行任何优惠，如限制停车金额则停车时长不作限制行任何优惠',
    hkzt: '汇款状态',
    gqqmpmc: '改签前门票名称',
    moneyTotal: '金额',
    qxzfl: '请选择分类',
    tydhj: '通用兑换价',
    zdct: '自动成团',
    ktcghptrsm: '举例：开团成功后拼团人数满4人自动成团。13点开团成功，13：30满足4人，自动成团。',
    mrmdzdgm: '每人每单最多购买',
    qxzyysj: '请选择预约时间',
    cswc: '超时完成',
    qewmyxqsz: '券二维码有效期设置',
    cjfzzffs: '创建分账支付方式',
    ddjmc: '默认使用crm的等级名称，也可以自定义在前台展示的等级名称',
    txshxx: '填写审核信息',
    releaseTime: '发布时间',
    yyqjwxzjdymd: '包裹已成功叫单，预约取件无需自己打印面单',
    qtgddrjdymd: '包裹已成功叫单，请通过打单软件打印面单',
    buttonsConfig: '悬浮按钮',
    qsrbskdddzmdzh: '请输入百世快递的电子面单账号',
    scjjh: '送抽奖机会',
    jyqldsxyhkhdyqjl: '限制，仅邀请来的是新用户可获得邀请奖励',
    thdyxjl: '天获得以下奖励',
    rightMobileInput: '请输入正确的手机号，多个手机号用英文逗号“,”分割',
    revert: '原路退回',
    xzsjm: '选择商家码',
    qxzsjm: '请选择商家码',
    kjant: '砍价按钮图',
    qdkcsl: '渠道库存数量',
    hidden: '隐藏',
    sfqx: '是否取消',
    qxxzygsjjs: '请先选择一个上级角色',
    please: '请为【',
    dzp: '大转盘',
    xsddl: '销售订单量',
    dkxd: '代客下单',
    rtjdgsjhyyw: '如添加多个手机号用英文“,”隔开',
    myxgsly: '每月限购数量应<=自定义时间限购数',
    yqcgkhdyqlxjl:
        '邀请人分享邀请活动，邀请成功可获得邀请连续奖励。举例：A用户邀请B用户，B用户注册成功，给A用户发放邀请奖励',
    qxzhfrq: '请选择回复人群',
    tcqmx: '停车券明细',
    szzj: '收藏专柜',
    xssx: '显示顺序',
    gifts: '礼包',
    bfhx: '部分核销',
    tqzmz: '退券总面值',
    sftqze: '三方退券总额',
    qsrdlzh: '请输入登录账号',
    hdjp: '活动奖品',
    cycleRewardRule: '每满设置',
    gdrq: '固定日期',
    zjh: '证件号',
    xsddfh: '线上订单复核',
    kkpmd: '可开票门店',
    wasthcl: '未按时提货处理',
    jdgwmktsxfzhl: '降低购物门槛，提升消费转化率',
    hyjfsz: '会员积分设置',
    fzx: '辅助线',
    zxsb: '执行失败',
    jhmc: '计划名称',
    hdms: '活动描述',
    cymzswgdys: '此页面展示为固定样式，具体以分销中心为准',
    orderTotalqtxgx: '使用门槛-选择订单金额满时，请填写该项！',
    orderNumqtxgx: '使用门槛-选择订单件数满时，请填写该项！',
    limitGetqtxgx: '限制领取-选择限制每个用户领取数量时，请填写该项！',
    btxapzdyhqdqlbnrpzdyhqzzsxsjhzwjssj:
        '不填写按配置的优惠券读取礼包内容配置的优惠券最早生效时间和最晚结束时间',
    qtxyqcs: '请填写邀请次数',
    gmxz: '购买限制',
    fssj: '发生时间',
    zyhzhdqjkcggm:
        '如配置1，则用户在活动期间可成功购买1次后，再次抢购时将购买不成功。如用户取消订单则还原名额，如售后不还原名额',
    templateId: '模板id',
    sfkf: '三方客服',
    bgPic: '背景图片',
    xzsjsfbq: '选择社交身份标签',
    ksyyhq: '可使用优惠券(张)',
    zsgxyzqx: '至少勾选一种权限',
    yqzlcs: '邀请助力次数',
    zjshsqd: '专柜售后申请单',
    dysddsqhk: '对以上订单申请汇款',
    jzcszdy: '仅支持设置大于0的内购价折扣，支持两位小数',
    shl: '商户联',
    shlbkdwtk: '商户联，不可对外提供',
    zzygztrjzsjl:
        '如不勾选只有首次关注他人赠送奖励，则只要关注他人就赠送激励，取关后再关注还赠送奖励',
    yfje: '运费金额',
    longTerm: '长期',
    btxzswgqyyhzqyx: '距上次登录后隔XX天重新发起身份验证，不填写则视为该企业用户长期有效',
    back: '返回',
    ymzcbbyks:
        '关闭等级将对已有会员造成影响，如需关闭请提前检查营促销活动上是否有引用对应等级，以免造成不必要客诉，请谨慎操作。点击「关闭」按钮执行关闭操作，点击取消将弹窗取消',
    cshdcjcsbnwk: '初始获得抽奖次数不能为空，并且仅支持到正整数',
    wcl: '未处理',
    zkjsjqkdrxzjmdjxfg:
        '外部开奖时，需要在活动结束后在列表页中导入中奖名单，在开奖时间前可导入新中奖名单进行覆盖',
    mrwzysfz: '默认为只有身份证、护照、港澳通行证',
    register: '注册',
    zdsyjbsljzcdzzs: '最多使用金币数量仅支持到正整数',
    ktskqsjhdl: '默认微信登录，可同时开启手机号登录，如使用手机号登录，需先开通短信服务',
    qtzbtdddjdzsdydan: '设置展示后，前台在不同的订单节点展示对应的按钮。',
    ewmyxqsz: '二维码有效期设置',
    ysyl: '样式预览',
    jrxcxck: '进入小程序查看',
    selectType: '请选择类型',
    hjgsqtghdddtyfhjbttz: '社区团购活动结束后，会将该社区团购活动订单统一发货给不同团长',
    yhqsj: '优惠券数据',
    xzzfzdwbsrk: '限制字符长度文本输入框',
    xxEmpty: '***能为空',
    qtxkddh: '请填写快递单号',
    wszyjtxsj: '未设置佣金体现时间',
    wzscmdbm: '网站商城门店编码',
    tjfwdd: '添加服务地点',
    srfw: '生日范围',
    qtxsfbz: '请填写收费标准，且必须大于0',
    yycq: '预约抽签',
    qzsxzygrqtz: '请至少选择一个人群特征',
    templateIntro1: '如您选择“模板三”，需要先对接发票系统',
    templateIntro2:
        '当配送方式为【同城配】时，才会展示【取件码，如#1234】，若配送方式为快递//自提时，不展示取件码',
    qzsxzyzgz: '请至少选择一种规则',
    sbsl: '设备数量',
    fxysjh: '分销员手机号',
    fmtpjycc: '封面图片建议尺寸：520*416px',
    rdxnrdydl: '如短信内容带有短链，需在短信尾部加上{退订回复【R】',
    dgsmdxk: '多个说明单选框',
    jpbkjfhyhq: '奖品包括积分和优惠券，用户中奖总次数达到最高次数限制，将不会再抽到任何奖品',
    yhjjcsdcsdd:
        ' 奖品包括积分和优惠券，用户抽奖次数达到该活动抽奖的总次数，将必中奖一次，如果奖品已发完，或奖品均已达到每日发放数量，将可能导致无法中奖，因此需要保证奖品充足',
    fksj: '付款时间',
    wxdl: '微信登录',
    preview: '预览',
    kysjbnwk: '可约时间不能为空',
    yxhkfp: '允许换开发票',
    qsrjpmc: '请输入奖品名称',
    qsrjqmcThan20: '请输入奖品名称，不超过20个字',
    qxzschtzhdlfs: '请选择商城后台账号登录方式',
    sqpz: '商圈配置',
    qzqtxqlgz: '请正确填写清零规则',
    selectTag: '按标签选择',
    qtxycrs: '请填写用餐人数',
    yqhyzl: '邀请好友助力',
    activityFriZl: '活动期内邀请好友助力',
    alreadyCheckout: '已结算',
    maxBindQuantity: '绑定车牌个数',
    xzzccw: '新增注册成为CRM会员的用户',
    addAndRegister: '新增注册成为CRM会员的用户，只有通过当前活动注册，才可获得额外奖励',
    sfqyyxzsjm: '是否启用已选择商家码?',
    proposalIntro1:
        '1.建议与 强制开卡字段配置一致，如微信有用户该信息，用户开卡时将自动填充并提供，如商场开卡字段从微信都成功获取时，将直接开卡',
    proposalIntro2:
        '2.如租户设置了强制开卡，建议取消掉强制开卡页面，使用微信的开卡页面，避免用户注册//登录时进入两次开卡页面，减少用户注册门槛',
    ddbytj:
        '在“保有月份”内计算是否达到保级条件，未达到降为低一级，不跨级降级，达到保有条件，不降级',
    tksbddyybb: '退款失败订单语音播报',
    tzdsfym: '跳转到三方页面',
    qxzyzsdyhqnr: '请选择要展示的优惠券内容',
    customRecord: '消费记录',
    topLimitDay: '预约时限',
    network: '网址',
    yhqsz: '优惠券设置',
    xzrq: '新增人群',
    hfxx: '回复信息',
    copyLottery: '复制抽奖券',
    narrow: '缩小',
    fwyhszl: '访问用户数总量',
    tzzzcgzhewmy:
        '支持所有访问方式，用户点击悬浮窗后，跳转至左侧公众号二维码页，长按识别关注公众号',
    qxzygmd: '请选择一个门店',
    xyzcsyzfbxcx: '需要支持使用支付宝小程序，才可使用该功能',
    qxxzsxmd: '请先选择生效门店',
    descriptionRule: '您可输入活动规则，活动图片等信息',
    dpcxgz: ' 优先执行单/多品促销规则，不能同时享受促销优惠和会员折扣优惠',
    bmbnzf: '可手工填写，如不填写系统自动生成，编码不能重复',
    access: '访问',
    wxhyk: '微信会员卡',
    zsqdjsyslxz: '自身券叠加使用数量限制',
    yyzxpxqlwyhtglqrk: '用于在小票详情里为用户提供领取入口，图片尺寸建议为750px*高不限',
    qxzygmb: '请选择一个模板',
    picNolimitHeight: '图片尺寸建议为750px*高不限',
    invoiceTime: '开票时间',
    bqjb: '标签角标',
    sfaccbm: '是否按场次报名',
    ewhd: '额外获得',
    jwkd: '10位以内数字，可带小数点，结尾可带“元”',
    swynszkdxs: '10位以内数字，可带小数',
    swynszkdxsd: '10位以内数字，可带小数点',
    wynszfh: '17位以内，数字、符号',
    wynszfhdh: '17位以内，数字、符号（电话号码，例：+86-0766-66888866）',
    jkwcph8wyn: '仅可为车牌号，8位以内',
    jkwcphdywyzh:
        '仅可为车牌号，8位以内，第一位与最后一位可为汉字，其余为字母或数字（车牌号码：粤A8Z888挂）',
    yncszkdxs: '32位以内纯数字，可带小数',
    wynzktxsz: '32位以内，只可填写数字，可带小数点',
    gyncszhgynczm: '10个以内纯汉字或20个以内纯字母或符号',
    gynchzhgynczm:
        '10个以内纯汉字或20个以内纯字母或符号（中文名10个汉字内；纯英文名20个字母内；中文和字母混合按中文名算，10个字内）',
    gynhz: '5个以内汉字',
    gynzf20: '20个以内字符',
    gynzf20Tip: '20个以内字符（1个汉字算1个字符）',
    zd40gzf: '最多40个字符',
    jbdj: '加倍叠加',
    yhsd: '优惠时段',
    dgfxfq: '导购分享发券',
    dgdxfq: '导购短信发券',
    srfqmczgygmc: '输入发券门店/专柜/员工名称',
    sydd: '收银订单-售后申请',
    bfcg: '部分成功',
    szdkxdgz: '设置代客下单规则',
    rwszdydyfgzzyhwfxd:
        '设置后以下专柜将单独设置运费规则，且无法参与门店运费优惠，如未设置对应的运费规则则用户无法下单，去',
    xzcyhs: '新注册用户数(CRM)',
    jyxhyyhlmdzsmcw: '仅影响会员优惠栏目的展示名称为“会员优惠”还是“会员专享”',
    pageName: '页面名称',
    yfxx: '应付信息',
    xsjfgz: '线上积分规则',
    rpzztsj:
        '如配置发货时间，请按配置时间发货，避免用户投诉；如配置自提时间，则发货时间将于自提时间结合使用；发货时间配置4月25日-4月28日发货，自提时间配置10:00-11:00、12:00-13:00则用户选择自提时间段只能选择4月25日10:00-11:00、12:00-13:00之间的时间段，即4月25日的10:00-11:00',
    selectFtPrice: '请输入{{cardLevelName}}的分摊价格',
    bjjb: '笔记举报',
    bauthorMobile: '笔记作者',
    authorMobile: '笔记作者',
    mrmtzdkcy: '每人每天最多可参与',
    plhx: '批量核销',
    sqjfcj:
        '线上//线下规则分别指用户线上//线下购物行为所获得经验值的规则（目前线下仅针对商圈积分场景）',
    taxRateType: '税率类型',
    qtxxfsj: '请填写消费时间',
    xzht: '新增话题',
    ccxt: '车场系统',
    zqgl: '中签管理',
    upload: '上传',
    activityTypeExtra1: '宣传活动，用户在前端可浏览无需做任何操作',
    activityTypeExtra2: '报名活动，用户可在前端进行报名，报名成功后才可参加',
    activityTypeExtra3: '卖票活动，用户需在前端购买门票，购买后才可参加。{modal}',
    tjsjd: '添加时间段',
    dfh: '待复核',
    txxcxlj: '填写小程序链接',
    ptspdd: '普通商品订单',
    zdytb: '自定义图标',
    qxzkkpzj: '请选择可开票专柜',
    mhsj: '盲盒售价',
    bgsj: '变更时间',
    dkddxmjebl: '抵扣订单项目金额比例',
    stopReceiveDays: '红包停止领取时间',
    createProduct: '创建商品',
    yfgz: '运费规则',
    changeCent: '消耗积分',
    jscdlxcxhzcdl:
        '首次登录线上应用(含微信//支付宝小程序、APP等)的用户，即首次登录小程序后再次登录APP不再发放奖励',
    hyyhsz: '会员优惠设置',
    dyxp: '打印小票',
    xzdj: '选择等级',
    guideSubsiteName: '导购员所在门店',
    cjqh: '抽奖券号',
    spxx: '审批信息',
    tcps: '同城配送',
    characterError:
        '10个以内纯汉字或20个以内纯字母或符号（中文名10个汉字内；纯英文名20个字母内；中文和字母混合按中文名算，10个字内）',
    characterErrorTip: '10个以内纯汉字或20个以内纯字母或符号',
    xxddhx: '线下到店核销',
    yhsyzfb:
        '开启后，该优惠券只能在支付宝小程序中领取，请谨慎开启；校验场景：用户使用支付宝APP扫导购端面对面发券二维码领券时才会执行该校验，此校验是支付宝对会员ID、身份证、设备号、手机号做唯一校验；如需使用，需提前联系客户成功经理进行配置',
    drddxx: '导入订单信息',
    hktjcghjddyxcl: '提示：汇款提交成功后将等待银行处理，具体到账时间需以银行为准',
    jrsj: '加入时间',
    myhxgqsrdy: '每用户限购请输入大于0的正整数',
    yhcyxz: '用户参与限制',
    productT: '商品类别',
    syewmmrhf: '使用二维码默认回复',
    tzdjslrjtz: '天自动结算利润给团长',
    mrzhdqjff: '每人在活动期间发放',
    qscfxhb: '请上传分享海报',
    gzfn: '请输入文字64个字符内',
    gzfn_2: '请输入文字100个字符内',
    ykj: '已开奖',
    qdljf: '签到领积分',
    uploadPicLimit:
        '上传图片最大10M，仅支持支持PNG、JPEG、JPG、GIF格式；删除资源库图片后，已引用图片的资源将失效无法显示。',
    qyjtj: '启用阶梯价',
    qtxzqdjplxx: '请填写正确的奖品类型项',
    zjfwtbServices1: '专柜服务图标（专柜页）命名为“ZGY_专柜编码”（如：ZGY_00001）',
    zjfwtbServices2: '专柜服务图标（商场导览）命名为“SCDL_专柜编码”（如：SCDL_00001）',
    pcgz: '排斥规则',
    khdht: '开盒动画图',
    guideProvide: '是否允许导购发券',
    jfmxy: '积分明细页',
    bzzx: '帮助中心',
    mzbnw: '面值不能为0',
    remittanceTime: '申请汇款时间',
    zjsyzkzsdyxx:
        '创建后，专柜首页中可展示店员信息，用户可通过员工的企业微信联系店员，创建时展示专柜同隶属专柜，可在',
    canTenItem: '最多可添加10项,且至少有1项',
    dcxfm: '单次消费满',
    yhgzgzhhjksdhf:
        '用户关注1.通过编辑回复内容，快速进行自动回复设置。用户关注公众号后即可收到回复，关注后1分钟内只能给粉丝发送3条消息，回复内容最多不能超过3条。公众号后即可收到回复',
    mcsgslbyz: '每场申购数量不一致',
    giftName: '礼包名称',
    qsrqmz: '请输入券面值',
    tjcc: '添加场次',
    xzmddzxpfppz: '新增门店电子小票发票配置',
    fpbzxxwks: '发票备注信息为空时停车缴费订单号，备注信息填写后即显示为所填内容',
    szczkfp: '设置储值卡发票',
    wfjIndexNotConfig: '王府井首页-固定格式，暂不支持配置',
    qsrsfsydyjkh: '请输入顺丰速运的月结卡号',
    dsdhx: '电商端核销',
    lqcjpz:
        '领取场景配置：图片尺寸建议为宽600px*高600px。配置优惠券组件后展示在优惠券组件左侧。不配置展示默认样式固定文字“组合”。',
    end2: '结束',
    fzyfq: '复制运费券',
    ztq: '只退券',
    bhjf: '消费产生的金额（不含积分、代金券），金额不能为0，多专柜合并支付视为1笔消费',
    yctdd: '已成团订单',
    qxzjsrq: '请选择结束日期',
    bsddpslbt: '本时段的配送量不填，视为不限制单量',
    wgyhsq: '无感优惠授权',
    pass: '通过',
    mqxxjzdzzjfcj:
        '线上//线下积分分别指用户线上//线下行为所获得积分的规则（目前线下仅针对自助积分场景）',
    dj: '底价',
    spbj: '视频笔记',
    presents: '礼品',
    tqgbsqtghd: '提前关闭社区团购活动，已产生的订单将立马成团，并且不可再修改活动，请谨慎操作',
    zdkscPic:
        '尺寸：750*750，格式：JPG、JPEG，大小：1M，张数：最多可上传5张。 上传图片后，图片在售卖优惠券列表展示，券详情页展示。',
    zdkscPic3:
        '尺寸：750*750，格式：JPG、JPEG，大小：1M，张数：最多可上传5张。 上传图片后，图片在售卖优惠券列表展示，券详情页展示，如不配置则不展示图片。',
    zdkscPic2:
        '建议尺寸：500px * 400px，或5 ：4比例，大小不超过1M，支持jpg，png，jpeg格式，最多可上传6张照片',
    sgbmdsl: '申购白名单数量',
    zdrqbkj: '指定人群不可见',
    hqxcxmsb: '获取小程序码失败',
    shsp: '售后商品',
    xzxcxym: '选择小程序页面',
    ggkcj: '刮刮卡抽奖',
    zkddmdxzrq: '只可对单门店选择人群',
    qtxgxkc: '请填写共享库存',
    applyMerchants: '适用专柜',
    cys: '次以上',
    yj: '已拣',
    commonShare: '共享',
    singleShare: '独享',
    qsrclwzzs: '请输入除零外正整数',
    average: '中评',
    dqjpjczslxz: '当前奖品均存在数量限制，必须设置未中奖奖项',
    zzhdsjdmtd:
        '如配置12:00-18:00；20:00-21:00，则在活动时间的每天的12:00-18:00；20:00-21:00可购买商品',
    warnTip:
        '注意：如同时配置活动时间和活动场次，则活动时间只生效到日期，如2020年5月4日，请谨慎配置',
    dgjxxcksyggn: '需维护微信支付商户号、APPID等关键信息才可使用该功能，请联系客户成功经理进行开通',
    hdjl: '活动奖励',
    czsp: '称重商品',
    shrsjh: '收货人手机号',
    ddjeyxxzfjylsjebpp: '订单金额与线下支付交易流水金额不匹配，请检查！',
    djsyccwz: '使用车场配置（倒计时以车场为准）',
    zdypz: '自定义配置',
    ksjf: '快速积分',
    ddbmqsje:
        '起送金额不包含商品的运费金额。如不满足则提示：订单不满起送金额，不能配送，请返回购物车修改商品。',
    sjhxxzdgddlhcnjsdqywxtz: '手机号需先在导购端登录后才能接收到企业微信通知',
    minutes: '分钟',
    addProduct: '添加商品',
    editProduct: '编辑商品',
    statisticType: '统计方式',
    qshqwdrjlzck: '数据正在处理中，请稍后前往导入记录中查看',
    jckk: '剪裁框宽',
    ksq: '开始前',
    details: '详情',
    limitCount: '限购数量',
    pljjsycTip1: '单个用户单条图文//视频//评论仅计算一次点赞',
    pljjsycTip2:
        '如不勾选单个用户单条图文//视频//评论仅计算一次，则用户点赞在取消在点赞，计算2次点赞。',
    pljjsycTip3: '单个用户评论单个图文//视频多次评论仅计算一次评论',
    selectApplyLevel: '请选择适用等级',
    sjlx: '事件类型',
    ptrs: '拼团人数',
    qxzwj: '请选择文件',
    deleteTime: '删除时间',
    glzdtb: '管理置顶图标',
    jfjbgz: '积分加倍规则',
    fhrxm: '复核人姓名',
    importRecord: '导入记录',
    marketActivityTip1: '若最低奖励≠最高奖励，则赠送用户随机的积分值',
    marketActivityTip2: '若最低奖励=最高奖励，则赠送用户固定积分值',
    marketActivityTip3: '若配置双倍奖励人数，则前n个用户会赠送2倍奖励',
    marketActivityTip4: '若最低奖励≠最高奖励，则赠送用户随机的经验值',
    marketActivityTip5: '若最低奖励=最高奖励，则赠送用户固定经验值',
    qxzyhqhyhqlb: '请选择优惠券或优惠券礼包',
    saveFailToResult: '保存失败，第{{num}}个阶梯请选择优惠券或优惠券礼包',
    saveFailToSendNum: '保存失败，第{{num}}个阶梯请填写优惠券每人每次发放次数',
    ptspptlb: '普通商品拼团列表',
    cjszzdkxzbqgzdsl: '用户在1次结算中最多可选择本券规则的数量',
    qsrtpbq: '请输入图片标签',
    dcmx: '导出明细',
    passageway: '通道',
    jlyhzs: '奖励用户总数',
    hdlbyExtra:
        '配置后，在可视化装修、活动列表页、活动日历中不可见，通过扫描渠道推广码生成的二维码仍然可见',
    bbhwkcsp: '不包含无库存商品',
    passagewayNo: '通道编号',
    qrgbgz: '确认关闭规则',
    cyyhsf: '参与用户身份',
    wyydbty: '我已阅读并同意',
    jygxxdcsygbqzfx:
        '为避免用户的薅羊毛行为以及受微信禁止诱导分享的管控，建议勾选限定次数以规避潜在风险',
    qtxjsmc: '请填写角色名称',
    fxyxxjl: '分销员信息记录',
    ljxf: '累计消费',
    xh: '消耗',
    cch: '超出后',
    tgysqdcwhydyh: '通过以上渠道成为会员的用户',
    zxsz: '装修设置',
    mcmgspsgsl: '每场每个商品申购数量',
    mrz: '默认值',
    yfze: '运费总额',
    yqs: '已签收',
    jpyfw: '奖品已发完',
    jc: '剪裁',
    dbq: '对不起',
    qbxbh: '且必须包含',
    tndd: '天内订单',
    hsjh: '或手机号',
    xzmdfldh: '选择门店分类导航',
    qbzjsp: '全部专柜商品',
    bscmrsyxtzdddct: '不上传默认使用系统自带的弹层图,建议上传295*392px,支持jpg、png',
    fzdjrkmyfy: '否则点击入口没有反应',
    zjspzfjezb: '专柜商品支付金额占比',
    qqgyc: '请求过一次',
    yyxz: '预约须知',
    qsrdxnr: '请输入短信内容',
    qszdysx: '请设置对应上限/下限值',
    dm: '大码',
    gy: '共有',
    jdcg: '叫单成功',
    dgsszjsp: '导购所属专柜商品',
    clz: '处理中',
    yfqmc: '运费券名称',
    rzyx: '认证邮箱',
    qtxgmsj: '请填写购买时间',
    qjskq: '请谨慎开启',
    jmxs: '减免小时',
    djssz: '倒计时时长',
    qsryhxgsl: '请输入用户限购数量',
    ddszzj: '订单所在专柜',
    dyhycdlh: '当用户一次登录后',
    sztcjffp: '设置停车缴费发票',
    hpxx: '货品信息',
    xzcyh: '新注册用户',
    yysj: '预约时间',
    ykdjl: '元可得奖励',
    sxmd: '所选门店',
    ndhddjb: '年度获得的金币',
    amountErrorMessage: '请输入大于0小于999999的正整数',
    thresholdMessage: '最大999999元，最小0.01元，金额不得为0和负数',
    yss: '以上时',
    qsrxxbt: '请输入消息标题',
    ptyhxg: '普通用户限购',
    zf: '涨粉',
    plxzewm: '批量下载二维码',
    xjq: '现金券',
    dzkbczsdxxhsjbyz: '导致卡包处展示的信息和实际不一致',
    yhqddxq: '优惠券订单详情',
    jxqsd: '矩形起始点',
    shkqwsj: '稍后可前往数据',
    qxztymddygm: '请选择同一门店的员工码',
    dxwgdddxwa: '短信为固定的短信文案，不可更改',
    srdy: '生日当月',
    kqgzgzhhfxxs: '开启关注公众号回复消息时',
    l_kqgzgzhhfxxs_1:
        '开启关注公众号回复消息时，请确认在微信公众号后台自动回复-被关注回复已关闭，同时启用，则可能导致无法准确控制给用户下发的内容消息。',
    shd: '售后单',
    xsqyq: '线上券页签',
    qzqtxxm: '请正确填写姓名',
    qtxjb: '请填写金币',
    rhdyxjldsz: '人获得以下奖励的数值',
    td_1: '太短',
    ptsp: '普通商品',
    gbqxmyszspe: '该标签下没有设置商品哦～',
    qtjdtggljmb: '请添加第{{index}}条广告链接目标',
    zjfl: '专柜分类',
    yjdzsj: '佣金到账时间',
    qgkjhdspzdz: '取该砍价活动所配置的值',
    sjjs: '上级角色',
    cmh: '抽盲盒',
    xzjs: '选择角色',
    qxxzygmd: '请先选择一个门店',
    hyzlxgl: '会员资料项管理',
    jj: '解决',
    cz: '操作',
    qtjccxx: '请添加场次信息',
    bmme: '报名名额',
    tgxzddfh: '通过选择订单发货',
    yhzcjcsw: '用户总抽奖次数为0次，请重新设置',
    js_1: '减少',
    jd: '解冻',
    jfsm: '缴费说明',
    hcgz: '互斥规则',
    wdz: '未到账',
    zdysjxgs: '自定义时间限购数',
    xfxw: '消费行为',
    hdqnljxf: '活动期内累计消费',
    mjxdxztcps: '买家下单选择同城配时',
    pzkjfpxszkjfs: '配置开具发票需设置开具方式，否则点击入口没有反应',
    psjlaqxjljs:
        '配送距离按骑行距离计算，非地图直线距离。运费按增加计算：实际配送重量(距离)大于起送重量(距离)，不满增加重量(距离)，运费按增加计算，依次类推',
    yb: '邮编',
    wmm: '位密码',
    bgsjzz: '变更上级组织',
    hmdgl: '黑名单管理',
    gdje: '固定金额',
    yhdnlm: '用户的年龄满',
    zjxbktjzdyzzjg: '专柜下不可添加自定义组织机构',
    khxrs: '可核销人数',
    bcf: '不触发',
    dhys: '导航颜色',
    hdqjxzrs: '活动期间限制人数',
    zwbq: '暂无标签',
    nrdcgg: '内容弹窗广告',
    dhzdkc: '兑换最大库存',
    djpxbnxy: '等级排序不能小于',
    ykj_1: '一口价',
    dqy: '待签约',
    cczq: '场次周期',
    gzgzhljgdyh: '关注公众号了解更多优惠',
    qstsy: '起送提示语',
    jqtxdghdyxjldsz: '请填写{{rewardStartText}}第X个获得以下奖励的数值',
    fzdjrkmfy: '否则点击入口没反应',
    xxdt: '线下地推',
    sjyx: '事件营销',
    btx: '不填写',
    cksqqx: '查看授权权限',
    dyxx: '订阅消息',
    dzmdzh: '电子面单账号',
    qxzdhkddrq: '请选择待汇款订单日期!',
    qxzlszj: '请选择隶属专柜',
    sxzdspsch: '所选中的商品删除后，将不再参与此活动，但不影响已产生的订单，是否删除？',
    tcqdjsy: '停车券叠加使用',
    tnksy: '天内可使用，超出时间奖励将被收回',
    fjldrjksjs:
        '发奖励当日就开始计算。如配置1天，若为3月10号发放的奖励，则需再3月10日23:59之前可使用，3月11日奖励将会被回收',
    zb: '占比',
    qsrjtkddkhbm: '请输入极兔快递的客户编码',
    bddh: '拨打电话',
    zqhcngmsp: '中签后才能购买商品',
    plxgmby: '批量修改目标页',
    jfbtmc: '积分标题名称',
    qrjjshtgm: '确认拒绝审核通过吗？',
    qsrwsjh: '请输入11位手机号',
    jljs: '奖励计算',
    tkfzdd: '退款分账订单',
    dddhkzje: '订单待汇款总金额',
    sjdmz: '上架的面值，必须填写活动价',
    hxyqxx: '还需要其信息？再此基础上最多可添加6项自定义报名信息，',
    zdyszmlksgps: '自定义设置每轮可申购瓶数',
    ddzl: '订单总量',
    yeqtp: '余额券图片',
    qywxbjtzsj: '企业微信报警通知事件',
    ggk: '刮刮卡',
    bzd: '不置顶',
    rbgxdgyhpldttw: '如不勾选单个用户评论单条图文',
    jzczwzywzf: '3.支持中文字、英文字符',
    sfqrscgfwdd: '是否确认删除该服务地点？',
    xzmr: '限制每人',
    zx: '左旋',
    qtxjfdkgz: '请填写积分抵扣规则',
    wpzmrhfnr: '未配置默认回复内容',
    lqrxz: '领取人限制',
    sfsymmzf: '是否使用密码支付',
    yjht: '有奖话题',
    xmmc: '项目名称',
    zssyfzbxwzxdkdwdbs: '最少使用分值必须为最小抵扣单位的倍数',
    htmc: '话题名称',
    topicId: '话题ID',
    plxgmbym: '批量修改目标页面',
    mdsz: '门店设置',
    cyyhhydj: '参与用户会员等级',
    dfh_1: '待发货',
    txdsfzffpdshh: '填写第三方支付分配的商户号',
    zkqhpd: '只看缺货配单',
    wdcl: '我的车辆',
    liang: '辆',
    dgsjh: '导购手机号',
    tjhykdj: '添加会员卡等级',
    bjsj: '编辑时间',
    zclwxs: '支持两位小数',
    bkztj: '不可再添加',
    dhsj: '兑换时间',
    qrzxffm: '确认重新发放吗？',
    ddlb: '订单列表',
    dgsp: '单个视频',
    sybgnqqxqrywcxtdj:
        '提示：使用本功能前请先确认已完成系统对接。状态关闭后，生成的订单不会进行分账处理；状态开启后所生成的订单会按设定的分账方式、分账比例进行处理',
    xxbt: '消息标题',
    sfqrjhd: '是否确认拣货单{{pickingListNo}}拣货完成?',
    ygyhzbdbtdkjhdz: '一个用户在本店不同的砍价活动中，最多帮砍的次数',
    tkzt: '退款状态',
    gqt: '过期退',
    jbcszdzw: '解绑次数最大值为100万',
    qxzjlcsxzzq: '请选择奖励次数限制周期',
    sqdl: '授权登录',
    h_sqdl_tip: '建议图片宽度为300px，高度不超过300px；大小不超过1M；',
    fpyjxx: '发票邮寄信息',
    dclx: '弹层类型',
    xsjs: '销售件数',
    wsh: '未审核',
    qsq: '去授权',
    spmc: '商品名称',
    xgjk: '修改接口',
    afkfjl: '按访客发奖励',
    wszmshddzfddqxsj: '未设置秒杀活动待支付订单取消时间',
    shrmc: '收货人名称',
    hyzxy: '会员中心页',
    db: '底部',
    xtyc: '系统异常',
    hyzxycdlztymdzsgycjbhzcdc: '会员中心一次登录中同一门店展示过一次就不会再次弹出',
    zjsszndmddzjyqjs: '则结算时只能单门店多专柜一起结算',
    ysrzywmc: '延时入账业务名称',
    zdfs: '指定粉丝',
    zdyts: '自定义天数',
    dhdx: '兑换对象',
    yhxdskxsdysfdhyj: '用户下单时可享受对应身份的会员价',
    hdlx: '活动类型',
    fxsz: '分享设置',
    djqy: '等级权益',
    sb: '设备',
    sxsj: '失效时间',
    hdrl: '活动日历',
    yyhthx: '运营后台核销',
    jzczdpz: '仅支持最多配置',
    kdjd: '可叠加单/多促销',
    lpmc: '礼品名称',
    qxzzdkjrq: '请选择指定可见人群',
    ydh: '运单号',
    xxfsjp: '线下发送奖品',
    xxhx: '线下核销',
    lbbt: '列表标题',
    sydjtygz: '所有等级，同一规则',
    zdbyhqlqhdtyx: '若设置为0，则代表优惠券领取后当天有效，大于0，则代表自领取多少天内有效',
    wfp: '未分配',
    ljmbpz: '链接目标配置',
    qzsxzyxjl: '请至少选择一项奖励',
    qrscztsjm: '确认删除这条数据吗?',
    gzsmtb: '规则说明图标',
    wxxcxdyxx: '微信小程序订阅消息',
    ygh: '已归还',
    qxtjkpzdqy: '请先添加可配置的区域',
    srflmc: '输入分类名称',
    mqxxgzjsyysqjf: '目前线下规则仅适用于商圈积分',
    txsm: '提现说明',
    wgtcyhsqrz: '无感停车优惠授权日志',
    sfqczzt: '三方券充值状态',
    xghd: '修改后的',
    qtxxzhd: '请填写选中后的Hi贝',
    czjdcz: '处做解冻操作',
    tcqdk: '停车券抵扣',
    hdtpdxx: '活动图片等信息',
    cyrsjh: '参与人手机号',
    qgzhysq: '且公众号已授权',
    gqcg: '改签成功',
    gldymkzxgygzszj: '管理店员模块中修改员工展示专柜',
    rddd: '日的订单',
    zysjqjstxjxg: '重要数据请谨慎填写及修改',
    kkzcdgdxcxjrdkxdmkdsplbymmrxzdyq:
        '导购关联的数据专柜开通了快速开单业务，可控制从导购端小程序进入代客下单模块的商品列表页面默认选中的页签。',
    dxp: '的小票',
    qlxkhcgjljxzx:
        '如勾选商圈（微信//支付宝）消费，需提前在微信 或支付宝 圈店。请联系客户成功经理进行咨询',
    xzmdztpz: '新增门店主体配置',
    wjmmjzwdrsjwjmzs: '3.文件命名将作为导入数据文件名展示',
    fzbl: '分账比例',
    qsrhdddmc: '请输入活动地点名称，不超过100个字',
    sqsj: '申请时间',
    bxz: '不限制',
    clxx: '处理信息',
    qxzhdtj: '请选择获得条件',
    byxxjjtyqcs: '不允许下级阶梯邀请次数/共计获得次数 小于上级阶梯人数',
    hyfp: '会员发票',
    qsrsqshh: '请输入商圈商户号',
    dhyxsj: '兑换有效时间',
    jxs: '计销售',
    gjmdxzhzgjzjxzbndwk: '根据门店选择或者根据专柜选择不能都为空',
    zzp: '张照片',
    yxjs: '已选角色',
    qxzxnfl: '请选择虚拟分类',
    ccmc: '车场名称',
    djhwxyshdjszqbz: '对接后微信与商户的结算周期变长',
    gmlx: '个目录项',
    spewm: '商品二维码',
    ffxz: '发放限制',
    qtjsymdtydyhq: '请添加适用门店通用的优惠券（或全部专柜可用），否则用户购买后可能无法使用。',
    jdtys: '进度条颜色',
    dcy: '待参与',
    qsrkcsl: '请输入库存数量',
    yhqhxsj: '优惠券核销数据',
    dxnrbhfdcgg: '短信内容包含房地产广告',
    jpplsxbxydzp: '3.奖品排列顺序必须与大转盘UI奖品顺序保持一致',
    bzhdjl: '不在获得奖励',
    qxxzmdhzxzzj: '请先选择门店后再选择专柜',
    ptsb: '拼团失败',
    kyszrqnr: '可以设置热区内容',
    mmxs: '密码形式',
    rzqbkzccysg:
        '如配置30天，若用户在9月1日成功核销，则用户在9月30日之前不可再次参与申购，在9月31日可参与申购；',
    jc_1: '借出',
    zxjf: '执行积分',
    klbwkydb: '我想要{price}元拿走这个商品，快来帮我砍一刀吧！',
    bqbkzxghd: '并且不可再修改活动',
    yqrjkhdjl: '被邀请人完成任务，邀请人即可获得奖励',
    kfh: '可复核',
    tjsjh: '添加手机号',
    ybgh: '已被归还',
    qsryhm: '请输入用户名',
    ghtx: '更换头像',
    qsrkfbz: '请输入客服备注',
    dhczls: '待汇操作历史',
    rxxddshwdjyzxdglgxs:
        '当订单发生全部或部分售后时，通过审核后即对已发放的未使用奖励进行回收。若线下订单售后未对接与正向单关联关系时，则线下小票发生售后不回收奖励',
    qzlbzdrhmd: '请在列表中导入黑名单',
    sfqxyd: '是否取消运单?',
    sjmsl: '商家码数量',
    gr: '个人',
    zftp: '转发图片',
    zdsc: '最多上传',
    qsrhdgg: '请输入活动公告，限120字',
    xzmdpzdzxpfp: '新增门店配置电子小票发票',
    bmspxq: '报名商品详情',
    qxztzfs: '请选择通知方式',
    xzbj: '选择笔记',
    njssfddbjtj: '内计算是否达到保级条件',
    zffsmc: '支付方式名称',
    gzh: '关注后',
    yhbcz: '用户不存在',
    ddqsqksqtk: '订单签收前可申请退款//售后',
    h_ddqsqksqtk_tip:
        '开启后，订单签收前均可在运营后台//用户端随时申请退款//售后（跨境订单除外，订单签收后才可申请售后）；关闭后，只有已签收的订单才可支持售后并在用户端展示售后入口按钮。',
    tjrq: '添加热区',
    jbslydkjebxtscz: '金币数量与抵扣金额必须同时存在',
    jcyghddhydbq: '给参与该活动的会员打标签',
    yxdjfs: '允许叠加方式',
    mrwxdl: '默认微信登录',
    xz: '限制',
    plxzygm: '批量下载员工码',
    jbcg: '举报成功',
    yjsdlsbdgxbzjlzzs: '已解锁的历史绑定关系不在记录中展示',
    xzggw: '新增广告位',
    ydrsjh: '预订人手机号',
    cwts: '错误提示',
    dkxm: '抵扣项目',
    bm: '报名',
    zmfxy: '招募分销员',
    wjs: '未结束',
    yhf: '已回复',
    xztgmd: '选择推广门店',
    szzhykxfsfzshyjfbk: '设置在会员卡下方是否展示会员积分板块',
    zdzcsj: '指定注册时间',
    qxzqsxsj: '请选择券生效时间',
    zyhmchdjxyy: '则用户每场活动仅需预约',
    nzwsjqxclxgyw: '您暂无数据权限处理相关业务',
    hxjf: '核销积分',
    xsjf: '线上缴费',
    whyzlhdcjmsx: '为好友助力获得抽奖码上限',
    couponName: '优惠券名称',
    yqxrzc: '邀请新人注册',
    hymlx: '会员码类型',
    xgcsmbylj: '修改初始目标页链接',
    tc: '填充',
    rqg: '人抢购',
    bmzt: '报名状态',
    qsrqh: '请输入券号',
    wz_1: '文章',
    fzmdztpz: '复制门店主体配置',
    qsryhqm: '请输入用户全名',
    pldrzjtp: '批量导入专柜图片',
    xs: '像素',
    thdjl: '天获得奖励',
    jhwcbdyxp: '拣货完成并打印小票',
    jb: '金币',
    l_jb: '金币 = 1 积分',
    sqrz: '授权日志',
    dzhmm: '对账号密码、手机做双重验证',
    yse: '样式二',
    tzldy: '跳转落地页',
    xqdyhzb: '新签到用户占比',
    bmcghhjyhbdyhq: '报名成功后会给用户绑定优惠券',
    syzdjdfhs: '使用自动叫单发货时',
    xxgwxwshdjyzdgz: '线下购物行为所获得经验值的规则',
    bd: '闭店',
    xdcs: '下单次数',
    h_kdsbz: '跨地(市)标志',
    h_kdsbz_tip: '数电票时,  必选，开具发票的地点是否与发票使用者的所在地不在同一省（市）；',
    tgjsh: '团购结束后',
    bytj: '保有条件',
    fwdz: '服务地址',
    yydh: '预约单号',
    xzmrmtffcs: '限制每人每天发放次数',
    shrdz: '收货人地址',
    yyqyyhjsyzm: '用于企业用户接收验证码，例：@co-mall.com',
    kjkcbnw: '砍价库存不能为0',
    ggzybgbcg: '该规则已被关闭成功',
    jjcl: '降级策略',
    dzhzxsz: '端账户中心设置',
    zztbzwx: '正在同步至微信',
    dkxdddzqtzssqtk: '代客下单订单在前台展示申请退款//售后按钮',
    qd: '签到',
    jsycrmdjsdjbmw: '使用crm等级时，等级编码为crm等级编码，不可更改',
    zl: '专利',
    tx: '填写',
    kydrq: '可预订人群',
    zy: '周一',
    tkdbb: '退款单播报',
    wdkbq: '我的卡包券',
    yclt: '以此类推',
    qtxtgjkmc: '请填写推广监控名称',
    rzmd: '认证门店',
    yjfklxsl: '一级反馈类型数量',
    fxy: '分销员',
    yj_1: '押金',
    qsbmks: '缺少报名开始',
    qsbmksjssj: '缺少报名开始/结束时间',
    pzzd: '配置指导',
    xd: '下单',
    cjj: '抽奖价',
    schzszsyhqdy: '上传后展示在首页和签到页',
    qxzyylx: '请选择原因类型',
    wyn: '万以内',
    gwyqdkhdhl: '跟我一起打卡获得好礼',
    zs: '周三',
    wynywzf: '32位以内英文字符',
    wyncywzm: '32位以内纯英文字母',
    wynywzfTip: '32位以内，只可填写英文字母',
    spdcpljsdc: '视频多次评论计算多次',
    pldssm: '批量定时售卖',
    wzzdzdxz: '网址最大长度限制',
    wzzdzdxz_1: '网址最大长度限制1024个字符',
    qtxyjbl: '请填写佣金比例',
    yss_1: '样式三',
    sysl: '剩余数量',
    qrtcpmm: '确认剔除排名吗？',
    zq_2: '制券',
    qtxymlj: '请填写页面路径',
    wyz: '未验证',
    ljjf: '累计积分',
    yhkytssydztcq: '停车结算时,用户可以同时使用多张停车券',
    gjtqtxcjhdmrhdcs: '保存失败，第{{index}}个阶梯请填写抽奖活动每人获得次数',
    djgjtqscyh: '保存失败，第{{index}}个阶梯请上传用户领取入口图',
    zfmmxgsz: '支付密码修改设置',
    h_zfmmxgsz_tip: '用户是否可在C端账户中心设置、修改、重置支付密码',
    cxjd: '查询进度',
    ht_1: '后天',
    qsrhxsl: '请输入核销数量',
    zd: '置顶',
    tygjg: '同一个价格',
    qsrfxbt: '请输入分享标题，不超过12个字',
    ysy: '样式一',
    zfsm: '支付说明',
    jyfk: '交易付款',
    shzdds: '售后总订单数',
    bmdzqrs: '白名单中签人数',
    ymxzq: '页面选择器',
    qxzwxgzh: '请选择微信公众号',
    hzxckdbt: '或者新窗口的标题',
    kgmsp: '可购买商品',
    qsrshdh: '请输入售后单号',
    dkxdywcjdd: '代客下单业务场景订单',
    tktq: '退款退券',
    zkndzwfzqkzjyhxfdnrxx: '则可能导致无法准确控制给用户下发的内容消息',
    jbsnyjslyxssdtkhtj: '即表示您已接受了以下所述的条款和条件',
    zjewm: '专柜二维码',
    drdnrgszq: '导入的内容格式正确',
    hyfs: '会员粉丝',
    cc: '尺寸',
    bgq: '变更前',
    bkbj: '不可编辑',
    szjg: '设置价格',
    xshx: '线上核销',
    qtxjpszxzhdje: '请填写奖品设置选中后的金额',
    jzcdmdsztsdxxx: '仅支持单门店设置推送短信消息',
    ryshqtjzjjxrgsh: '若要审核请添加专柜进行人工审核',
    fxt: '分享图',
    ewmhhxmlby: '二维码或核销码列表页',
    tqcdyh: '天前触达用户',
    yxzxd: '优先执行单',
    jpmc: '奖品名称',
    rzxdr: '如重新导入',
    yd: '与单',
    bc: '保存',
    xzt: '新专题',
    zjsysktssy: '在结算页时可同时使用',
    qtxyfljgz: '保存失败，请填写邮费立减规则',
    sjmbm: '商家码编码',
    cjr: '创建人',
    rshbhyme: '如售后不还原名额',
    zw: '周五',
    tfxcx: '他方小程序',
    jebbhyf: '金额不包含运费',
    xpbh: '小票编号',
    fhzqtjrs: '符合中签条件人数',
    hykmc: '会员卡名称',
    wzjthjf: '未中奖退还积分',
    rqtp: '热区图片',
    ze: '周二',
    cjkjh: '次集卡机会',
    maxPriceCount: '符合条件人群最大瓶数',
    szhyxzjjddszyfgz: '设置后以下专柜将单独设置运费规则',
    qtjhfnr: '请添加回复内容',
    yhz: '用户在',
    yg: '预估',
    qxzjpsl: '请限制奖品数量',
    xh_1: '序号',
    szdjzktb: '设置等级折扣图标',
    dcts: '弹窗提示',
    yhdyz: '用户第一周',
    tsymcc: '特殊页面传参',
    jssdmddzjktsjs: '结算时多门店多专柜可同时结算',
    wbl: '外部了',
    sz: '收藏',
    bkdwtg: '不可对外提供',
    xzyq: '选择页签',
    sz_1: '数组',
    qsrxx: '请输入选项',
    qsrxxbcg50gz: '请输入选项，不超过50个字',
    xcrdj: '需次日点击',
    xzbblj: '下载报表路径',
    xtzdjsmlksgps: '系统自动计算每轮可申购瓶数',
    zsnr: '赠送内容',
    cpmbgl: '产品模板管理',
    qsrdxyzm: '请输入短信验证码',
    xcxdlxx: '小程序登录选项',
    kjj: '可降级',
    bmsxjf: '报名所需积分',
    sfzrbymd: '是否植入本页面的',
    yhzhdqjmzzqcsh: '用户在活动期间满足中签次数后，再次抽签时，将会把该用户从抽签用户中剔除',
    qsrzzjgmc: '请输入组织机构名称',
    xny: '新能源',
    zxxj: '执行现金',
    anzxgbcz: '按钮执行关闭操作',
    ktsqsm: '开通授权说明',
    h_ktsqsm_msg: '请输入正确格式的授权说明，不超过100个字',
    ftdjfj: '分摊的积分价',
    zkjgwzs: '折扣价格文字色',
    zjgl: '专柜管理',
    tbsb: '同步失败',
    blsm: '变量说明',
    hdxc: '活动宣传',
    dxbcg: '仅支持mpeg，ogg，wav，aac，flac，wma，aiff 格式，大小不超过2.0MB',
    gjtqxzcjhd: '保存失败，第{{index}}个阶梯请选择抽奖活动',
    tzfs: '通知方式',
    bmmd: '报名门店',
    xgms: '修改描述',
    mbjbzhd: '逐笔清零：每笔金币在获得',
    xzzdrq: '限制指定人群',
    ddgz: '订单跟踪',
    zxff: '重新发放',
    bmr: '不默认',
    szkyfxbzdhblwcjs:
        '0表示拼手气红包没有过期时间，始终可以分享并直到红包领完才结束。例如：设置1天，下单1天后不能再分享，如未领完则红包也无法再领取',
    fwmc: '服务名称',
    qjxxyhqtxwz: '请将线下优惠券填写完整',
    tkje: '退款金额',
    yhzhzcztxdqkyjf: '用户账户正常状态下当前可用积分',
    plty: '批量停用',
    sfzxrz: '是否只渲染在',
    bmjcg: '报名即成功',
    ccljts: '超出累计天数',
    myh: '每用户',
    srnrxz: '输入内容限制120个字',
    wxzftx: '无需重复填写',
    qfzkydblmc: '支持自定义内容及输入变量，如需输入变量，请复制可用的变量名称',
    qsh: '请稍后',
    rgffbxyyyrycz: '人工发放必须由运营人员操作',
    gzsm: '规则说明',
    bmxz: '报名限制',
    zwglgzh: '暂未关联公众号',
    czzt: '充值状态',
    qtxjpszxzhdyhqhzyhqlb: '请填写奖品设置选中后的优惠券或者优惠券礼包',
    bmzdhdhbnzbmchd: '报名指定活动后不能再报名此活动',
    bjys: '边角样式',
    xgzt: '限购状态',
    qwztxjljf: '请完整填写奖励积分',
    kplx: '开票类型',
    blueticket: '蓝票',
    redticket: '红票',
    mr: '默认',
    lxxxblsz: '离线消息保留时长',
    ydyqx: '运单已取消',
    tjrqhfnr: '添加人群回复内容',
    gzybgb: '规则已被关闭',
    xycj: '校验场景',
    fqrs: '发券人数',
    xzlp: '新增礼品',
    qsryyzdh: '请输入预约者电话，限定11位',
    tghdlb: '推广活动列表',
    zcxx: '注册信息',
    dsyjpjdddrfjsl: '当所有奖品均达到当日放奖数量',
    qwztxjppz: '请完整填写奖品配置',
    ffzt: '发放状态',
    bjzh: '编辑账号',
    bkcyrq: '不可参与人群',
    cymdyfgzjs: '参与门店运费规则计算',
    pzcccghjmrzsjfjlrk: '配置车场成功后将默认展示缴费记录入口',
    jzjkk: '将直接开卡',
    qbfs: '全部粉丝',
    zffslx: '支付方式类型',
    zxgbxyh: '在修改本协议后',
    mdbh: '门店编号',
    xsgs: '显示个数',
    zdkdhsl: '最大可兑换数量',
    yyms: '预约模式',
    xcxkfp: '小程序开发票',
    qszhyqy: '请设置会员权益',
    cymdjzj: '参与门店及专柜',
    jldct: '奖励弹层图',
    qmz: '券面值',
    qbj: '券本金',
    xzzx: '下载中心',
    shbtgyybnwk: '审核不通过原因不能为空',
    l_shbtgyybnwk_1: '审核不通过原因不能为空，且限制在20字以内',
    xpsj: '小票时间',
    xzkdpsgs: '新增快递配送公司',
    ldlms: '类单例模式',
    bqfsp: '不区分商品',
    qbxdy: '且必须大于',
    xzjlxw: '选择奖励行为',
    qzqtxyx: '请正确填写邮箱',
    xxjffbzyhxs: '线下积分分别指用户线上',
    ptn: '平台内',
    ybqdyxq: '因不确定原需求',
    dgysjh: '导购员手机号',
    mrspzlhjyxxsyzjdbxysh:
        '默认商品资料和经营信息所有专柜都不需要审核，若要审核请添加专柜进行人工审核（若不审核商品资料和经营信息遭到机关、用户投诉等，由商家自行承担相关责任）',
    jpsl: '奖品数量',
    tymdxjzcczytyesmsj: '同一门店下仅支持存在一条余额说明数据',
    yhzjzcsddzgcsxz: '用户中奖总次数达到最高次数限制',
    ymbt: '页面标题',
    tsfh: '特殊符号',
    jglx: '价格类型',
    hzd: '合作等',
    cpxx: '车牌信息',
    fbpjy: '发表评价页',
    bgh: '变更后',
    jzclrdy: '仅支持录入大于',
    jcgzbhdjjb: '基础规则包含等级加倍',
    tjxyd: '天进行预订',
    hydj: '会员等级',
    qgsj: '抢购时间',
    xzdhm: '新增兑换码',
    ydlfwhys: '已登录访问会员数',
    zfjgyhzthdyyhmcjrdhdcdk: '支付结果页和专题活动页用户每次进入都会弹出弹框',
    qm: '签名',
    mxhgz: '每相互关注',
    ht_2: '话题',
    sc_1: '删除',
    jhzl: '拣货重量',
    hzxcks: '或者新窗口上',
    xxffjp: '线下发放奖品',
    yhxzcg: '用户新增成功',
    j: '街',
    dbd: '待绑定',
    qsrzqdhtyxq: '请输入正确的话题有效期',
    fxzq: '分销赚钱',
    dscsygzffss: '端首次使用该支付方式时',
    qtyybnwk: '其他原因不能为空',
    yhfw: '用户范围',
    qtxwxxcx: '请填写微信小程序',
    mlmc: '目录名称',
    asppz: '按商品配置',
    hpsjjgkqw: '货品数据结果可前往',
    rdmddzjzs: '如单门店多专柜展示',
    xlyhjkcy: '新老用户均可参与',
    zcyh: '注册用户',
    zfjf: '支付积分',
    fwxw: '访问行为',
    zfsxf: '支付手续费',
    zcdmc: '子菜单名称',
    pdmc: '票档名称',
    getAfterLottery: ' 每达到{{stepValue}}人获得以下奖励',
    xzaspjedk: '选择按商品金额抵扣',
    zfdds: '支付订单数',
    szyfgz: '设置运费规则',
    xsqbl: '显示全部列',
    zsqdslkbj: '自身券叠数量可编辑',
    szhdjh: '设置活动价后',
    shbz: '审核备注',
    hyyhbt: '会员优惠标题',
    bcgz: '保存规则',
    hykh: '会员卡号',
    tjsb: '添加设备',
    dhtp: '兑换图片',
    zhxgsj: '最后修改时间',
    byptwclsdpxtdj: '并与平台完成了数电票系统对接',
    xsje: '销售金额',
    xsjez: '销售金额（总）',
    ycbg: '已拆包裹',
    jkdt: '集卡大图',
    dsz: '的数字',
    xjzje: '现金总金额',
    yhjbpldyy: '笔记服务-用户举报评论的原因',
    syjf: '使用积分',
    dy: '店员',
    bjlbcx: '笔记列表查询',
    xzjcgz: '新增基础规则',
    qsrsjh: '请输入手机号',
    myzdmbnr: '没有找到模板内容!',
    drzqxx: '导入中签信息',
    qsrccjtdz: '请输入车场具体地址',
    gzpz: '规则配置',
    bjmdztpz: '编辑门店主体配置',
    fhcg: '发货成功',
    rewardLimitFontSize: '请输入大于0,小于99的正整数',
    couponNum: '券号',
    jycc: '建议尺寸',
    yhqkdjsyzsxz: '优惠券可叠加使用张数限制',
    h_yhqkdjsyzsxz_msg: '仅支持输入正整数，最大可设置99',
    h_yhqkdjsyzsxz_tip: '用户在1次结算内最大使用可叠加优惠券张数限制，最多99张',
    szs: '收藏数',
    qtxyemc: '请填写余额名称',
    ysxxzstxyg: '以上信息至少填写一个',
    ckw: '此卡为',
    syrbkj: '所有人不可见',
    jfdh: '积分兑换',
    zcmdwdqmddyh: '注册门店未当前门店的用户',
    shdzzpsfwwdmj: '收货地址在配送范围外的买家',
    sfqmx: '三方券明细',
    qqrgddydbwb: '请确认该订单已打包完毕',
    xfcys: '悬浮窗样式',
    dhsplx: '兑换商品类型',
    xfzk: '消费折扣',
    dfk: '待付款',
    zddqhtpzdfldhsj: '自动读取后台配置的分类导航数据',
    zj: '最近',
    scmc: '商城名称',
    h_scmc_msg: '商城名称,不能为空',
    h_scmc_tip: '建议不超过4个字',
    yhqgqq: '优惠券过期前',
    zbz: '直播中',
    ffyhqdsl: '发放优惠券的数量',
    qlxkhjlmqyyccwcdj: '如展示“剩余车位数”请联系客户经理明确已与车场完成对接，否则导致展示出错',
    zqrlx: '中签人类型',
    djs: '等级时',
    ywzf: '英文字符',
    mdxzdejzzjg: '门店下最多二级组织结构',
    qxxzmdhzj: '请先选择门店或专柜',
    qjxsyhqtxwz: '请将线上优惠券填写完整',
    qgrs: '抢购人数',
    dqmdygbjhgn: '当前门店已关闭拣货功能，如需使用拣货功能请到',
    hdykq: '活动已开启',
    qysb: '签约失败',
    hdfqrsjhmyzc: '活动发起人手机号没有注册',
    jlpz: '奖励配置',
    yf: '月份',
    mzbkwk: '面值不可为空，并且仅支持到小数点后两位',
    tyzhdhhdl: '同一账户多会话登录',
    h_tyzhdhhdl_tip:
        '如：同一账户下，用户A登录后用户B再次登录，用户A将被系统自动登出即置为未登录状态',
    qxzxfczsdztym: '请选择悬浮窗展示的专题页面',
    hddb: '回到顶部',
    dyy: '打印员',
    sfjyyxzsjm: '是否禁用已选择商家码？',
    jl: '举例',
    rzsjh: '认证手机号',
    ydyspbz: '已订阅商品不足',
    qfhgwcxgsp: '请返回购物车修改商品',
    cysj: '参与时间',
    gatxz: '港澳通行证',
    pjsj: '评价时间',
    yjfy: '预计分佣',
    hyhq: '含优惠券',
    kpszbnwk: '卡片设置不能为空',
    zjspxlzb: '专柜商品销量占比',
    lpbm: '礼品编码',
    cyxz: '参与须知',
    backgroundPicExtra: '建议尺寸：宽750px，高1960px。',
    sczyktph: '删除资源库图片后',
    hdybjt: '活动页背景图',
    xzyesm: '新增余额说明',
    dgnc: '导购昵称',
    syhykp: '首页会员卡片',
    yyykc: '已预约库存',
    czz: '成长值',
    cjmdfklx: '创建门店反馈类型',
    fscstz: '发送测试通知',
    fhwtg: '复核未通过',
    fzlx: '分账类型',
    dxsrk: '单行输入框',
    lqrkt: '领取入口图',
    qsrsstjcxhzjxcz: '请输入搜索条件查询后再进行操作',
    hdqjmyhzdgm: '活动期间每用户最多购买',
    qsryysx: '请输入预约时限',
    jem: '金额满',
    ljjlspddczfdjehjddmjycr: '累计奖励是判断多次支付的金额合计达到门槛于次日',
    qewmyxq: '券二维码有效期',
    ptm: '拼团满',
    ptmValue: '拼团满#value#人',
    qbhdjpcchd: '全部活动均排斥此活动',
    gynczmhfh: '个以内纯字母或符号',
    smhdbp: '扫码核对标品',
    ssspmc: '搜索商品名称',
    zhbjcg: '账号编辑成功',
    kkxxzd: '开卡信息字段',
    xsjsdw: '小时计时单位',
    xqryhxmhsfzbcyz: '需确认用户姓名和身份证保持一致',
    dydy: '大于等于',
    xshby: '限时红包雨，促活跃，烘托活动气氛',
    nsrsbm: '纳税人识别码',
    bywxgzhhtkt:
        ' 使用微信公众号模板消息需先绑定认证服务号，并于微信公众号后台开通“模板消息”功能；',
    wctk: '完成退款',
    fzjsyxck: '否则将使用新窗口',
    bjxhdyg: '不进行活动预告',
    ahydjxg: '按会员等级限购',
    bkmc: '板块名称',
    zdzjbcy: '指定专柜不参与',
    jbzffjlzytgdqhddl:
        '首次登录线上应用（含微信//支付宝小程序、APP等）的用户，如首次登录小程序后再次登陆APP，将不再发放奖励只有通过当前活动登陆，才可获得额外奖励',
    smlx: '扫码类型',
    jsydjkdh: '仅适用等级可兑换',
    zjjtyf: '增加阶梯运费',
    gqwthddsfgb: '过期未提货订单是否关闭',
    tcyhq: '停车优惠券',
    znqbg: '周年庆报告',
    qxzqdkclx: '请选择渠道库存类型',
    mrqdgdjlhljqdewjl: '每日签到固定奖励和累计签到额外奖励，至少设置其一',
    qxsj: '取消时间',
    xsze: '销售总额',
    yxdyjfl: '已选的一级分类',
    xzmrzhdnff: '限制每人在活动内发放',
    zjtz: '中奖通知',
    xssc: '线上商城',
    xzmb: '新增模板',
    xlchkf: '先离场后扣费',
    tyggz: '同一个规则',
    mhsp: '盲盒商品',
    xx: '选项',
    gmcqdddh: '购买此券的订单号',
    ejflsjzs: '二级分类数据展示',
    dghxsy: '导购核销使用',
    xzfldh: '选择分类导航',
    ccsz: '车场设置',
    sx_2: '上限',
    jjshyy: '拒绝售后原因',
    dgyhfxdgtwspdcjjsyc: '单个用户分享单个图文//视频多次仅计算一次分享',
    zzsyhgdjyz: '则赠送用户固定经验值',
    ydrxm: '预订人姓名',
    xt: '选填',
    kpztqc: '开票主体全称',
    lqytp: '领取页图片',
    qssmrw: '缺省时默认为',
    dyjhd: '打印拣货单',
    djsz: '等级设置',
    bd_1: '标点',
    l_cycj_1: '参与抽奖',
    rbfhzbyxbm: '如不符合则不允许报名',
    gkfkhhdjl: '支付奖励，提升消费动力，促进复购',
    zyhxytsmzckbmcg: '则用户需要同时满足才可报名成功',
    cts: '成团数',
    jgqj: '价格区间',
    yhzcjpddslxz: '用户中此奖品达到数量限制',
    l_yhzcjpddslxz: '每用户在活动期间中此奖品的总次数，用户中此奖品达到数量限制，不会再抽到此奖品',
    jybxy: '建议不小于',
    jsztq: '较上周同期',
    sfjylsh: '三方交易流水号',
    fplxsz: '发票类型设置',
    ddrq: '订单日期',
    zxjfsc: '装修积分商城',
    xdshbgnkq:
        '注意：下单时红包功能开启，下单过后，平台将红包功能关闭，该下单用户不能再分享和领取红包。若拼手气红包活动过期，之前即使有未领取光的红包也不能再领，显示“红包结束页”样式',
    zyhxyxbmcjhd: '则用户需要先报名参加活动',
    qsrczr: '请输入操作人',
    jlhy: '建立好友',
    kkx: '可靠性',
    bjpsgz: '编辑配送规则',
    xjdhgz: '新建兑换规则',
    jky: '件可用',
    dczs: '弹层展示',
    htsdgbddsxyrgshqxgbd: '后台手动关闭订单时需要人工审核取消关闭单',
    h_htsdgbddsxyrgshqxgbd_tip:
        '当订单已复核但未发货时，通过后台关闭该订单后所生成的取消关闭单需要人工审核通过',
    g: '规',
    z: '则',
    dz: '店长',
    qxzmdjzj: '请选择门店及专柜',
    qxzqynghd: '请选择企业内购活动',
    qsypz: '券使用配置',
    mbjfyxqw: '每笔积分有效期为',
    jzsztxsqy: '将展示在提现申请页',
    qxzktsqxy: '请选择开通授权协议',
    szsyccsjdhbtsjxt: '设置所有场次数据都和本条数据相同',
    wdzc: '我的资产',
    zhdl: '最后登录IP',
    xzgz: '新增规则',
    qsrhysjh: '请输入会员手机号',
    hyzczzsxxsymdkyqjezh:
        '会员资产中展示线下所有门店可用券金额总和，余额券列表中展示所有有可券金额的线下门店，此选项需要接口可支持，选择前需和客户经理确认。',
    bbhyp: '不包含样品',
    fzmdpzczkfp: '复制门店配置储值卡发票',
    yqyly: '邀请有礼页',
    gz_1: '关注',
    qsrddje: '请输入订单金额',
    cs: '参数',
    zspxyflpxyz: '展示排序与分类排序一致',
    tptk: '退票退款',
    bx: '不限',
    sfzczz: '是否支持转赠',
    spbssl: '商品本身税率',
    zdmcbnxyzxmc: '最大名次不能小于最小名次',
    dyxq: '的有效期',
    sjflsj: '三级分类数据',
    mbtb: '模板头部',
    example_2: '例:',
    sgsj: '申购时间',
    rcsdjf: '日产生的积分',
    yhqptdd: '优惠券拼团订单',
    qsrjdkddkhbm: '请输入京东快递的客户编码',
    mchdmyhzdgm: '每场活动每用户最多购买',
    gzzt: '关注状态',
    zl_1: '周六',
    instructions: '使用说明',
    tsspjsts: '特殊商品结算提示',
    hdsz: '活动设置',
    szdd: '设置订单',
    qxzzdkjhykdj: '请选择指定可见会员卡等级',
    xcxzq: '线上制券 (小程序制券)',
    dlzh: '登录账号',
    zglbzdgqgzsffdsl:
        '指该礼包中单个券规则所发放的数量，不包含「未绑定、已失效、已作废」三种状态的券',
    hygrjj: '会员个人简介',
    rzgwczydmddzjzs: '如在购物车中以多门店多专柜展示',
    jxfs: '继续发送',
    jsyhzcmj: '减少用户注册门槛',
    jybcg: '建议不超过',
    qxzyhq: '请选择优惠券',
    hdym: '回调域名',
    hjf: '含积分',
    hqly: '获取来源',
    kcyrq: '可参与人群',
    gzgzhpz: '关注公众号配置',
    cqzp: '抽签藏品',
    qdsm: '渠道说明',
    kgdqtzf: '空格等其他字符',
    xtcj: '系统创建',
    nrdcsjcl: '内容弹窗数据处理',
    xyyhgm: '先预约后购买，新品、尖货抢不停',
    zhjl: '组合奖励',
    trzz: '他人转载',
    kdzdx: '可调整大小',
    xfczswz: '悬浮窗展示位置',
    zdljsj: '指定揽件时间',
    tcjfy: '停车缴费页',
    dsxnfl: '电商虚拟分类',
    ssdl: '设施导览',
    drbmrs: '导入报名人数',
    pljy: '批量禁用',
    bcbdyzdb: '保存本地用作对比',
    ewmmrhfsz: '二维码默认回复设置',
    tcjflj: '停车缴费链接',
    spdcszjjsyc: '视频多次收藏仅计算一次',
    spxl: '商品销量',
    shspjeTop10: '售后商品金额top10',
    shspsTop10: '售后商品数top10',
    spxlTop10: '商品销量top10',
    spzfjeTop10: '商品支付金额top10',
    bfhdjpcchd: '部分活动均排斥此活动',
    yylhjcjhd: '用于老虎机抽奖活动',
    gdbcg: '高度不超过',
    hdfk: '货到付款',
    dzczk: '电子充值卡',
    xyzxcxhtpzywymhglgzhcnfw: '需要在小程序后台配置业务域名或关联公众号才能访问',
    szdf: '收藏得分',
    shbtg: '审核不通过',
    lspp: '连锁品牌',
    ckgzhlx:
        '1.公众号是服务号（可在“应用-微信公众号-公众号设置”查看公众号类型）前往公众号->管理公众号绑定公众号信息',
    whyzcbll: '位好友注册并浏览',
    qszxxzfjylshje: '请设置线下支付交易流水号金额',
    xshddhfkcj: '需审核订单后方可参加',
    sjzyIntro1:
        '支持小程序的类目包括：生活服务>百货/超市/便利店、商家自营>百货商场/购物中心、生活服务>服饰内衣、生活服务>服饰内衣、生活服务>美妆，可登录“微信公众平台”，设置小程序服务类目，',
    sjzyIntro2:
        '2、订阅消息为一次性消息（一次性订阅消息用于解决用户使用小程序后，后续服务环节的通知问题。用户自主订阅后，开发者可不限时间地下发条对应的服务消息）',
    sjzyIntro3: '3、不同类目的消息推送的内容关键字略有差异，但推送的具体内容会保持一致',
    sjzyIntro4: '4、订阅消息可下发用户仅限已订阅该模板的小程序会员',
    qsmsptxm: '请扫描商品条形码',
    tpcc: '图片尺寸',
    qmc: '券名称',
    khx: '开户行',
    yxfe: '月消费额',
    hdbjt: '活动背景图',
    xfmj: '消费门槛',
    cjszszjtlszj: '创建时展示专柜同隶属专柜',
    nxzdzzjglxbt: '您选择的组织机构类型不同，不可一起变更组织机构',
    lqhyxq: '领取后有效期',
    qxzhxhdhff: '请选择核销活动后发放',
    ytcj: '应退差价',
    yhqddlsx: '用户前端登录时效',
    h_yhqddlsx_tip:
        '如：用户A2024.5.8 10:00:00登录，如登录时效为1天，则该用户2024.5.9再次访问小程序时提示需重新登录；如不填则为永久有效',
    qsbmksrq: '缺少报名开始日期',
    cjje: '差价金额',
    plsz: '批量设置',
    qwsxftjxx: '请完善消费条件信息',
    rgbzydyyhjhbqk: '如关闭则已订阅用户将会被清空，是否确定关闭',
    wjzzsc: '文件正在上传',
    bmsh: '报名审核',
    zf_1: '字符',
    sqxf: '商圈消费',
    bmrsjh: '报名人手机号',
    ppsj: '品牌数据',
    tpgszc: '图片格式支持',
    zxksz: '最小可设置',
    btzmrzs: '不填则默认展示',
    ddfhdjwxfhfw: '订单发货对接微信发货服务',
    dcxdjl: '导出下单记录',
    xnhdbxw: '虚拟号段必须为',
    yybjchd: '用于编辑传后端',
    yjsz: '右击设置',
    jhwc: '拣货完成',
    zsykcs: '则剩余库存数',
    bxtxhdj: '必须填写活动价',
    fwlx: '服务类型',
    zdzj: '指定专柜',
    shcg: '审核成功',
    bmxg: '报名限购',
    dhhxjtjf: '兑换海信集团积分',
    yxflmb: '营销分类模板',
    cyrs: '参与人数',
    cytxzl: '参与填写资料',
    jddlby: '及订单列表页',
    swxtsz: '税务系统设置',
    xgjs: '修改角色',
    szdjxg: '设置等级限购',
    dataProcessing: '数据正在处理，请进入“下载中心”下载对应数据',
    bmje: '报名金额',
    hyyhwa: '会员优惠文案',
    zhbjsj: '最后编辑时间',
    qxzhxmd: '请选择核销门店',
    jfsclb: '积分商城列表',
    xzyshd: '选择预售活动',
    kmszshydj: '卡面上展示会员等级',
    hfzt: '回复状态',
    fzkjhd: '复制砍价活动',
    tkxjje: '退款现金金额',
    cjh: '创建后',
    xqms: '详情描述',
    zcy: '注册页',
    bxzsczs: '不限制时此值是',
    zhdsjzsxdrq: '则活动时间只生效到日期',
    scjp: '删除奖品',
    xzhysf: '选择会员身份',
    djtbbnwk: '等级图标不能为空',
    bmgz: '报名规则',
    pldcsjdc: '批量导出时间弹层',
    dt: '底图',
    mebnwk: '名额不能为空',
    xgcg: '修改成功',
    srdshbtgyy: '输入的审核不通过原因',
    l_srdshbtgyy_1: '输入的审核不通过原因，将显示给前端用户，限制在20字以内……',
    tcjss: '停车结算时',
    kp: '卡片',
    djqwhssdztb: '点击前为红色水滴状图标',
    gqtx: '过期提醒',
    alccj: '按轮次抽奖',
    zdzktj: '最多只可添加4级角色',
    tjyhxg: '添加用户限购',
    gncksyggn: '功能才可使用该功能',
    kqhspygj:
        '开启后商品预估价(或购买多件时预估单价)展示在"商品详情页”、"购物车”，放大价格差异刺激消费者下单。',
    dkxddx:
        '代客下单线下商品结算场景，用户每次扫码结算时，如购买了开启提示商品，则会弹出提示信息。（提示信息可在代客下单规则中配置）',
    dkxdcj:
        '代客下单场景，线下商品（商家码）模式开单，用户购买了配置需要给出提示语商品时，则给用户弹出提示框，告知用户特殊商品说明信息',
    yyly: '应用来源',
    fwtz: '服务通知',
    yt: '一天',
    cgsp: '常规商品',
    ydrxb: '预订人性别',
    hdtj: '获得条件',
    ffsbzzs: '发放失败总张数',
    couponQuantity: '张数',
    tkcgsj: '退款成功时间',
    zj_1: '至今',
    jgsz: '价格设置',
    yxhz: '邮箱后缀',
    zjcsxz: '中奖次数限制',
    jzrs: '净增人数',
    sfqystkgm: '是否启用实体卡购买',
    tpgg: '图片广告',
    lqcgzzs: '领取成功总张数',
    qxztymdddfh: '请选择同一门店订单发货',
    yyqj: '预约取件',
    jsxgwdyhqdzsgz:
        '谨慎修改我的优惠券的展示规则，规则修改后用户重新进入小程序后，前端我的优惠券页面才会发生变更',
    bdcp: '绑定车牌',
    tjxjzz: '添加下级组织',
    qbydyhy: '全部已订阅会员',
    qdszhmdm: '确定设置黑名单吗？',
    schdksh: '商场活动可视化',
    yjyx: '永久有效',
    dlaqcl: '登录安全策略',
    hdmtdksjssj: '活动每天的开始结束时间',
    jfze: '积分总额',
    qsrzxdh: '请输入咨询电话',
    bcgdr: '本次共导入',
    pointAndBaifen: '积分(百分比)',
    pljjsbdzsl: '评论均计算被点赞数量',
    kjspslyx: '砍价商品数量有限',
    hdsj: '活动数据',
    cjl: '次奖励',
    dklx: '抵扣类型',
    qsrzqdz: '请输入正确地址',
    cjdzlj: '抽奖地址链接',
    yq: '邀请',
    invateNumPeople: '邀请{{num}}人',
    xzdd: '新增地点',
    kfy: '客服员',
    zjycxzbqrssj: '最近一次新增标签人数时间',
    dyxxkxfyhjxydygmbdxcxhy: '订阅消息可下发用户仅限已订阅该模板的小程序会员',
    gzhgzrs: '公众号关注人数',
    mdznglyckdjmb: '门店只能关联一次卡等级模版',
    jljt: '奖励阶梯',
    pjr: '评价人',
    qjjltjtxwz: '请将奖励条件填写完整',
    gdw: '高度为',
    mjxx: '买家信息',
    cjdds: '成交订单数',
    qsrxmmc: '请输入项目名称',
    jyzfb: '经验值翻倍',
    qrshtgm: '确认审核通过吗？',
    qsrmrfjsl: '请输入每日放奖数量',
    ylfh: '原路返回',
    hdxs: '活动形式',
    xxktdxfw: '需先开通短信服务',
    zxdh: '咨询电话',
    qtxzqdzjbmjdysjm: '请填写正确的专柜编码及对应商家码',
    ljdzyfz: '链接地址已复制',
    kyy: '可预约',
    rsx: '日上限',
    zcjcszdktj: '再此基础上最多可添加',
    ljsx: '累计上限',
    qsrccskf: '请输入车场收款方ID',
    aspxz: '按商品选择',
    qsrytsddkhbm: '请输入圆通速递的客户编码',
    myhkzjsl: '每用户可中奖数量',
    bjzjztgz: '编辑专柜自提规则',
    sfzr: '是否植入',
    zdzcsz999t: '最大支持设置9999天',
    zdzcsz28r: '最大支持设置28日',
    zdzcsz: '最大支持设置2376小时（99天）',
    mz: '美妆',
    gkl: '顾客联',
    lqzx: '领券中心',
    zxsqqgbsgzzx: '执行时取其高倍数规则执行',
    jzsbncg: '字数不能超过20个字',
    fdqkx: '否的情况下',
    jkndzwfzj: '将可能导致无法中奖',
    mgzbnwk: '敏感字不能为空',
    dpl: '待评论',
    bjyesm: '编辑余额说明',
    sjzzscz: '数据正在生成中',
    yhddgjpdzjslxz: '用户达到该奖品的中奖数量限制',
    ypsnlyx: '因配送能力有限',
    xzklb: '选择卡类别',
    djpx: '等级排序',
    yhzccg: '用户注册成功',
    ck: '查看',
    hour: '小时',
    tghdfzcg: '团购活动复制成功',
    pldclj: '批量导出路径',
    glzj: '关联专柜',
    kdjtbfsbgh: '卡等级图标发生变更后',
    myzdmbnr_1: '没有找到模版内容',
    bjbk: '编辑板块',
    jsqtzkj: '仅社区团长可见',
    lydxgnhjsjbd: '利用短信功能或计算机病毒',
    qsrqzhxdyy: '请输入强制核销的原因',
    rddctsj: '如到达成团时间',
    nrpz: '内容配置',
    qxxzygewm: '请先选择一个二维码',
    q: '请',
    qtxjybfb: '请填写经验百分比',
    mdhyxx: '门店会员信息',
    lq: '领取',
    rpzdggz: '若配置多个规则',
    tt: '抬头',
    jysc: '建议上传',
    qsrzdyxmc: '请输入自定义项名称，不超过20个字',
    kjtysz: '砍价通用设置',
    bnzbjhfxx: '处理完成后，不能再编辑回复信息',
    jfzje: '积分总金额',
    ysjfj: '样式见附件',
    qmxx: '券码信息',
    sqyy: '申请原因',
    hkhyx: '黑卡会员享',
    qsrqmc: '请输入券名称',
    shsps: '售后商品数',
    czsj: '操作时间',
    qxzxwtj: '请选择行为条件',
    qsrkhx: '请输入开户行',
    bmy: '不满意',
    xtzdff: '系统自动发放',
    zkjgbjs: '折扣价格背景色',
    sqtgkc: '社区团购库存',
    moreBuyNum: '最多购买{{ruleCount}}件',
    pzgxxhzlpyytpdyztzs: '配置该信息会在礼品预约图片第一张图展示，如“钻卡专享”',
    dgywyhcjgwqd: '导购赋能，加速成交，提升业绩',
    jbnr: '举报内容',
    djtb: '等级图标',
    dcfssj: '导出粉丝数据',
    zb_1: '直播',
    qxzcxhd: '请选择促销活动',
    amdtj: '按门店统计',
    hxyhq: '核销优惠券',
    yxdcdsj: '已选的产地数据',
    mspmrzdkdh: '每商品每日最大可兑换',
    xzz: '限制在',
    shrk: '售后入口',
    xgzs: '限购总数',
    zcsdtgjkmc: '注册时的推广监控名称/二维码名称',
    mrtcyhx: '每日停车优惠项',
    bklx: '板块类型',
    bjdzd: '笔记点赞得',
    bjgzh: '编辑公众号',
    hykkm: '会员卡卡面',
    rqfw: '日期范围',
    xnsp: '虚拟商品',
    xnspjygl: '虚拟商品经营管理',
    xnspzlzx: '虚拟商品资料中心',
    scdlmd: '首次登录门店',
    qpzxsgs: '请配置显示个数',
    yjsdsjd: '预计送达时间段',
    kpztsz: '开票主体设置',
    mryhsx: '每日优惠上限',
    sxjf: '所需积分',
    zzjfgzsz: '自助积分规则设置',
    qxzsj: '请选择时间',
    qdqkyhqm: '确定清空优惠券吗',
    yxflsplb: '营销分类商品列表',
    cybmdyhckycycj: '如配置10:00-11:00；则每日的10:00-11:00参与报名的用户才可以参与抽奖',
    hxzj: '核销专柜',
    qpzyjsdsjd: '请配置预计送达时间段',
    qsrsqtgmc: '请输入社区团购名称',
    sppljjsbplsl: '视频评论均计算被评论数量',
    kjzt: '开奖状态',
    mdzfpx: '门店支付排行',
    zhdlsj: '最后登录时间',
    pzhds: '配置活动时',
    tnr: '条内容',
    bty: '不同意',
    yhzryyjhdmdqdcgh:
        '用户在任意一家活动门店签到成功后，在其他门店都是已签到成功的状态，且累计签到、连续签到都将按照用户是否签到计算，',
    ydj: '已冻结',
    qtxwzdyfljgz: '保存失败，请填写完整的邮费立减规则',
    szmfme: '设置免费名额',
    qxzyhqlb: '请选择优惠券礼包',
    bgx: '变更项',
    fzdh: '分组导航',
    hqbzjky: '或全部专柜可用',
    srnxczdtccmc: '输入你想查找的停车场名称',
    hdbxczzsygqydcc: '活动必须存在至少一个启用的场次',
    pldcwpzslsp: '批量导出未配置税率商品',
    spfm: '视频封面',
    scwwczq: '上传未完成之前，不要关闭页面，否则可能数据错误',
    rxtmddsphfgzqdrdsp: '4.请正确填写商品编码，如相同门店的商品会覆盖之前导入的商品；',
    jjshtgcg: '拒绝审核通过成功',
    xzfsdf: '新增粉丝得分',
    bmrxm: '报名人姓名',
    zdrq: '针对人群',
    zncz: '只能抽中',
    dcl: '待处理',
    hcanbjt: '合成按钮背景图',
    cmrfssx: '超每日发送上限',
    gmtj: '购买条件',
    qlxglysz: '请联系管理员设置',
    dnxfebnwk: '的年消费额不能为空',
    hdxw: '互动行为',
    xzdymb: '选择打印模板',
    jyxhyyhlmxdbtzswaw: '仅影响会员优惠栏目下的标题展示文案为“会员活动”还是“会员价”',
    hd: '活动',
    qtxmztjhdjlsz: '请填写满足条件后的奖励数值',
    sy: '所有',
    tgjkmc: '推广监控名称',
    qsrlpbm: '请输入礼品编码',
    h_qsrlpbm_msg: '请输入礼品编码，不超过20字',
    yczqjg: '预测中签结果',
    fjyhq: '富基优惠券',
    qxzjpsz: '请选择奖品设置',
    yssl: '已售数量',
    dkxddd: '代客下单订单',
    xzpsgz: '新增配送规则',
    syspfl: '所有商品分类',
    bzbhyxqtgdxgnegcwf: '保证不会因向其提供短信功能而构成违法',
    qtxxcx: '请填写小程序',
    xs_2: '先生',
    qsrfwddmc: '请输入服务地点名称',
    ndmmqdbc: '您的密码强度不错',
    djkm: '等级卡面',
    zsxzygsjmdy: '至少选择一个商家码打印',
    hyzj: '会员组件',
    rssglqywazbxyydsyfwd: '如上述关联企业未按照本协议约定使用服务的',
    qsrhdmc: '请输入活动名称',
    xtqlxkhcgjljxpz: '需提前联系客户成功经理进行配置',
    hzszxpdb: '会展示在小票底部',
    wjyfsxsmty: '未经乙方事先书面同意',
    ch: '次后',
    fxyl: '分享有礼',
    qsrkjyxq: '请输入砍价有效期',
    xzdd_1: '选择订单',
    bgzzjgcg: '变更组织机构成功',
    qtxwzdjfdkgz: '请填写完整的积分抵扣规则',
    bmjwshtg: '报名即为审核通过',
    rtsfhdgjbgz: '如同时符合多个加倍规则',
    qk: '清空',
    dcq: '导出券',
    jlfxyh: '奖励分享用户',
    hellob: 'Hi贝',
    yhxbxx: '用户性别信息',
    shyy: '审核原因',
    kdl: '可登录',
    mgyhznzygmdxbmcg: '每个用户只能在一个门店下报名成功（不区分商品）',
    jywjdxz: '建议文件大小在',
    bmyhts: '避免用户投诉',
    qsryydh: '请输入预约单号',
    qdrcc: '请导入场次',
    qsrjfbtmc: '请输入积分标题名称',
    tnddd: '天内的订单',
    drzjmd: '导入中奖名单',
    qsrmrzhdqjffcs: '请输入每人在活动期间发放次数',
    rd: '日的',
    mcxg: '每场限购',
    clzt: '处理状态',
    sfqrsc: '是否确认删除?',
    ys: '预售',
    jssm: '结算说明',
    yhzcs: '用户在超市',
    qsrzhhyhm: '请输入账号或用户名',
    rgfh: '人工复核',
    bkrs: '帮砍人数',
    ccksh: '场次开始后',
    kjspxzsz: '跨境商品限值设置',
    qtxxcxlj: '请填写小程序路径',
    qjspz: '请谨慎配置',
    wzscmdmc: '网站商城门店名称',
    ktztpdzxssx: '可拖拽图片调整显示顺序',
    bhccqyxx: '包含车场区域信息',
    smldym: '扫码落地页面',
    myhcshdcjcs: '每用户初始获得抽奖次数',
    ptt: '普通团',
    tgym: '推广页面',
    yhqdbff: '优惠券打包发放',
    shrs: '售后人数',
    xyjxxmsfzsmrz: '需要进行姓名、身份证实名认证',
    thdlztsx: '天后登录状态失效，再次访问需要重新登录',
    qscgdhdxct: '请上传更多活动宣传图',
    zdxf: '最低消费',
    q_1: '起',
    xghdtkyygshtzsjssx: '修改后的条款应于公示或通知时即时生效',
    rbtx: '如不填写',
    bfhdjhcchd: '部分活动均互斥此活动',
    xyhwdtzcdyh: '新用户为当天注册的用户',
    qsrjfdhwa: '请输入积分兑换文案',
    hys: '会员数',
    xfjf: '消费积分',
    ghzn: '个汉字内',
    jlschxzs: '距离上次核销至少',
    yyxm: '预约姓名',
    gzn: '个字内',
    tyjqzhxyhq: '停用及强制核销优惠券',
    fzpz: '复制配置',
    glndqy: '公里内的区域',
    qxztw: '请选择图文',
    anbjys: '按钮背景颜色',
    srsjck: '输入时间参考',
    ckqtkdjq: '查看其他可叠加券',
    jlfffs: '奖励发放方式',
    tdyhbkj: '特定用户不可见',
    qsrfxms: '请输入分享描述，不超过20个字',
    sjflzkzt: '三级分类展开状态',
    tjspm: '添加商品码',
    dyfdh: '的运费的和',
    xwhwxzfshh: '需维护微信支付商户号',
    sxmd_1: '生效门店',
    yfze_1: '应付总额',
    yfzey: '应付总额（元）',
    mlrqhfznsz: '每类人群回复只能设置',
    hxsl: '核销数量',
    fxwa: '分享文案',
    hymc: '会员名称',
    azjtj: '按专柜统计（批量）',
    yykkhxdyhqjl: '可查看优惠券核销 // 转赠记录',
    shwc: '审核完成',
    jgzryhycghfkgm: '经工作人员核验成功后方可购买',
    dkwdyhqmrym: '打开我的优惠券默认页面',
    zqrs: '中签人数',
    mrbkje: '每人帮砍金额',
    bdcqzh: '不动产权证号',
    zj_2: '增加',
    jbgz: '加倍规则',
    kpztdkhyxzh: '开票主体的开户银行账号',
    yeqxxzfewmmc: '余额券线下支付二维码名称',
    qtjdtggtp: '请添加第{{index}}条广告图片',
    zkc: '总库存',
    qzsxzyxhxqd: '请至少选择一项核销渠道',
    bbhyf: '不包含运费',
    nh: '您好',
    l_nh: '您好，欢迎关注',
    tjxnhd: '添加虚拟号段',
    qsrqdkc: '请输入渠道库存',
    sfsc: '是否删除',
    yjsy: '佣金收益',
    yhddhzxjslxdlts: '用户断登后重新计算连续登陆天数',
    fbcg: '发布成功',
    bqfdj: '不区分等级',
    wzdx: '文字大小',
    sysjhyzmdl: '使用手机号验证码登录',
    qtjcc: '请添加场次',
    jtk: '仅退款',
    rjfsc: '如积分商城',
    cashCoupon: '代金券',
    zhsysl: '账号使用数量',
    djqtp: '代金券图片',
    qsrhtmc: '请输入话题名称',
    qsrgzmc: '请输入规则名称',
    l_qsrgzmc: '请输入规则名称，不超过20个字',
    rkyjf: '如可用积分',
    ssfz: '所属分组',
    commissionId: '佣金Id',
    splr: '商品利润',
    fhh: '发货后',
    ckhd: '查看活动',
    tcsm: '停车说明',
    zhsp: '组合商品',
    qsrxcxbt: '请输入小程序标题',
    mhhdzsj: '盲盒活动总数据',
    zzjfzn: '自助积分指南',
    fszt: '发送状态',
    hymcbncg: '会员名称不能超过',
    zhczyh: '最后操作用户',
    bcj: '不创建',
    qsrptmc: '请输入拼团名称。例如：双十一拼团活动',
    ctxz: '参团限制',
    bzzdsr: '备注最多输入50字',
    yhlxdlhdzgjtjlh: '用户连续登陆获得最高阶梯奖励后，重新计算并循环发放奖励',
    dyxfebnwk: '的月消费额不能为空',
    mfyy: '免费预约',
    sjmqycg: '商家码启用成功',
    tjzzjgcg: '添加组织机构成功',
    gddt: '高德地图',
    spbsssbm: '商品本身税收编码',
    h_spbsssbm_spzjflssbm: '商品本身税收编码>商品子级分类税收编码>商品父级分类税收编码',
    bszpcgz: '不设置排斥规则',
    whyfw: '位好友访问',
    zjjsqxgl: '专柜角色权限管理',
    qsrkhxzh: '请输入开户行账号',
    dqyyyxrybqdyh: '当前拥有以下任一标签的用户',
    my: '满意',
    lqgz: '领取规则',
    cszscjmslbnwk: '初始赠送抽奖码数量不能为空，并且仅支持到正整数',
    mykbt: '没有可不填',
    anbjt: '按钮背景图',
    wdk: '我的卡',
    mhkmkc: '盲盒可卖库存',
    sjkfhsjcl: '数据库返回数据处理',
    xcxkp: '小程序卡片',
    zjewmsj: '专柜二维码数据',
    zjhypl: '专柜会员评论',
    mgyhgmsl: '每个用户购买数量',
    mcsgsl: '每场申购数量',
    zbjsj: '直播间数据',
    mjsz: '门槛设置',
    dczhsp: '导出组合商品',
    msqh: '模式切换',
    syrdyzbkj: '所有人都隐藏不可见',
    rblc: '如比例尺',
    dyrs: '订阅人数',
    chdksrqd: '从获得开始日期第',
    fwyylb: '服务预约列表',
    zz: '最长',
    ftdxjj: '分摊的现金价',
    cashMoney: '现金',
    qsrnsrsbh: '请输入纳税人识别号',
    sqsj_1: '授权时间',
    ckys: '查看预售',
    lysj: '来源事件',
    pljb: '评论举报',
    fzdl: '复制短链',
    yfje_1: '应付金额',

    ty: '同意',
    hfrq: '回复人群',
    hfrq_1: '回复人群:【{{crowdScope}}】最多只能设置一套回复内容',
    khdyxjl: '可获得以下奖励',
    yhsxsz: '优惠上限设置',
    qtxbxydje: '请填写不小于0的金额',
    mqjzcscyztp: '目前仅支持上传一张图片',
    priceErrorMessage: '请填写>0的数字，仅支持2位小数',
    l_priceErrorMessage: '请填写>0的正整数',
    pldczjxx: '批量导出专柜信息',
    fsqssj: '发送起始时间',
    fldqbsj: '分类的全部数据',
    ccmx: '场次明细',
    zfsywxzfftcfw: '支付使用微信支付分停车服务',
    xtdzccg: '系统等注册成功',
    lcsdbdzhzdkf: '离场时对绑定账户自动扣费',
    zxljdjxg: '执行逻辑等级限购',
    l_zxljdjxg: '限购：执行逻辑等级限购>普通用户限购',
    ynqxsz: '以内且行数在',
    ppmcjxcx: '品牌名称进行查询',
    hk: '黑卡',
    dssjgbcg: '定时上架关闭成功',
    qshzs: '请稍后重试',
    hdxz: '活动须知',
    syhqgzsz: '送优惠券规则设置',
    fgdr: '覆盖导入',
    tpsf: '图片缩放',
    wdyjfk: '我的意见反馈',
    r: '人',
    jyze: '交易总额',
    rzdct: '人自动成团',
    yhsfkz: '用户是否可在',
    cjhfy: '创建回复语',
    ssbk: '所属版块',
    xypzccxx: '需要配置车场信息',
    wxgzhmbxx: '微信公众号模板消息',
    tgbjhfnr: '通过编辑回复内容',
    zxhzhjwfhf: '是否确认注销账号，注销后账号将无法恢复？',
    balanceNoUse: '该账号还有{{balance}}佣金未使用',
    ewmtg: '二维码推广',
    qrscjt: '确认删除阶梯',
    xgsj: '修改时间',
    mrzsbk: '每人最少帮砍0.01元',
    wxzsp: '未选择商品',
    jhmty: '聚合码通用',
    h_jhmty_limit: '张。建议图片100*100方图；大小不超过1M；jpg格式。',
    h_jhmty_tip:
        '通用logo适用于全部门店生成的聚合码，请慎重修改。最多上传1张。建议尺寸为100*100方图；大小不超过1M；jpg格式。',
    bjtzsj: '报警通知事件',
    xzdgyl: '选择的供应链',
    qsrfwmc: '请输入服务名称',
    fwsj: '服务时间',
    sbl: '是变量',
    jka: '即可按',
    dmmc: '大码名称',
    pdhdjgrqbnwk: '票档活动价格日期不能为空',
    jsrq: '结束日期',
    jzhyjyzw: '将置会员经验值为',
    shbtgyy: '审核不通过原因',
    jfhl: '积分换礼',
    f: '份',
    sfjh: '是否拣货',
    sfql: '是否清理',
    qdxgwztztm: '确定修改为暂停状态吗',
    ddh: '待兑换',
    kyzdybj: '可以自定义半径',
    qsrqlx: '请输入券类型',
    fzz: '分账中',
    hp: '红票',
    qjgygdyl: '且价格应该大于零',
    ccksq: '场次开始前',
    ptptj: '普通拼团价',
    cxjyhdsftscl: '诚信经营和第三方投诉处理',
    zbxqlqsj: '则不限券领取时间',
    wfqtydhwzgzd: '违反其他约定或网站规则的',
    ddxqyrkt: '订单详情页入口图',
    bzsdfl: '不展示的分类',
    qygckmbndxxjxtx: '3.请严格参考模板内的信息进行填写',
    qygckmbndxxjxtx_4: '4.请严格参考模板内的信息进行填写',
    qhwb: '前后文本',
    sysp: '适用商品',
    ftdjg: '分摊的价格',
    yyyfdgyhgsdznj: '由于乙方的故意或过失导致您及',
    xmm: '新密码',
    gbzt: '关闭状态',
    qfx: '去分享',
    shareToFriend: '每分享{{stepValue}}位好友访问',
    jxyhffjl: '新用户注册成功，给新用户发放奖励',
    qxzshzt: '请选择审核状态',
    ytj: '已提交',
    yqdylhd: '请填写活动名称。例如：1月签到有礼活动',
    xzkhs: '新增客户数',
    yszhyqy: '已设置会员权益',
    qsrppmc: '请输入品牌名称',
    mbnrjbzc: '模板内容将不支持',
    spsjhdsjchsm: '商品上架后到时间才会售卖',
    xzdmdwjyzgydmd: '选择的门店为经验值共用的门店，前台才会展示经验值规则哦',
    jyrq: '交易日期',
    dzffsdsx: '的支付方式都生效',
    qzzclwxs: '且只支持两位小数',
    yhqm: '用户全名',
    szmwyhdbkje: '设置每位用户的帮砍金额',
    qjjftxwz: '请将积分填写完整',
    dhxddsjzzc: '该报表仅支持【导出】功能，待后续迭代升级再支持【查看】功能!',
    anpz: '按钮配制',
    w_3: '位',
    fdblsfzbjztx: '浮动比例是否在编辑状态下',
    xd_1: '限定',
    zdktx: '最多可填写',
    y_3: '余',
    bjzh_1: '编辑租户',
    tcqdjsyzsbxwdy: '停车券叠加使用张数必须为大于1的正整数',
    qxzfhsp: '请选择发货商品',
    cspydbdzdkjhsl: '此商品已达本单最大可拣货数量',
    bqy: '标签页',
    qsrfwdz: '请输入服务地址',
    xszbyyf: '可降级，需设置保有月份',
    mrmt: '每人每天',
    sxxdyxx: '上限需大于下限',
    zs_1: '周四',
    jbmx: '金币明细',
    xzfm: '新增封面',
    qsrxmm: '请输入新密码',
    xdrbmcgdcs: '下单人报名成功的次数',
    bxszwzjjx: '必须设置未中奖奖项',
    wsyqcgyxqs: '未使用且超过有效期时',
    qbzj: '全部专柜',
    scklb: '删除卡类别',
    mgsjdbkzf: '每个时间段不可重复',
    spssflbm: '商品税收分类编码',
    zzjgmc: '组织机构名称',
    tnklq: '天内可领取，超出时间不能领取',
    tcqdjsyzsbkcg: '停车券叠加使用张数不可超过10',
    qtqszhmddz: '门店发货地址为“门店管理”栏目中设置的“门店地址”，请提前设置好门店地址。',
    mrfqkjcs: '每人发起砍价次数',
    dcxf: '单次消费',
    xxzcsj: '线下注册时间',
    zjtsy: '中奖提示语',
    dqkysywyhxyxtdywyxshdjds:
        '当全款预售业务已和下游系统定义为预销售或定金单时，请填写订单转销售单日期，日期不可早于活动开始时间。',
    qsrhydjjbcs: '请输入会员等级解绑次数',
    hyzc: '会员资产',
    qzsxzyzxftj: '请至少选择一种消费条件',
    qxzhp: '请选择货品',
    ejfklxsl: '二级反馈类型数量',
    dqye: '当前余额',
    qsrttkddkhbh: '请输入天天快递的客户编号',
    glsjhztjsjh: '管理手机号中添加手机号',
    dhsxsj: '兑换失效时间',
    yhdsssqtk: '用户端随时申请退款',
    sxj: '上下架',
    bk: '边框',
    mzdht: '没找到话题',
    zgrhl: '这个人很懒',
    sjr: '收件人',
    hdqjmyhzq: '活动期间每用户中签',
    zctk: '再次退款',
    schjzszqdylsytb: '图片建议尺寸宽750*高360，上传后将展示在签到有礼首页头部',
    cdgn: '菜单功能',
    dgymyscmp: '导购员没有上传名片',
    jbgzsmjdyjsgs: '加倍规则说明及对应计算公式',
    shykp: '送好友卡片',
    zdycc: '自定义场次',
    mrcydk: '每日参与打卡',
    sykdhsl: '剩余可兑换数量',
    xftj: '消费条件',
    anbjtp: '按钮背景图片',
    xzkh: '新增客户',
    czpxx: '称重品信息',
    fhd: '发货单',
    xzzty: '选择专题页',
    cxhdy: '促销活动页',
    jzcsrsz: '仅支持输入数字',
    sqjf: '商圈积分',
    wtgsh: '未通过审核',
    qzxtx: '请重新填写',
    ybqx: '已被取消',
    fxbt: '分享标题',
    ptzq: '普通中签',
    qlx: '券类型',
    qghzgzhzsjl: '取关后再关注还赠送奖励',
    wgz: '未关注',
    mryhsztzsw: '默认语灰色字体展示为',
    qszlcdjssz: '请设置离场倒计时时长',
    ylt: '预览图',
    dfstgjdsfsy: '等方式提供给第三方使用',
    yhzcsj: '用户注册时间',
    btmcbxatstx: '注：表头名称必须按提示填写（不含符号、空格等其他字符）,否则会导致执行错误',
    tjbjwbdc: '添加编辑文本弹层',
    yycqdr: '预约抽签导入',
    zssps: '在售商品数',
    ddzt: '订单状态',
    fxrjl: '分享人奖励',
    mqy: '目前有',
    fztcq: '复制停车券',
    tjdj: '添加等级',
    qdyndxy: '期待与您的相遇',
    xzdmdwjfgydmd: '选择的门店为积分共用的门店',
    l_xzdmdwjfgydmd_1: '选择的门店为积分共用的门店，前台才会展示积分规则哦',
    gzcj: '规则创建',
    rdlsxw: '如登录时效为',
    jlfs: '奖励方式',
    h_jlfs_tip: '同一门店，同活动时间段，同一支付方式只允许存在一种开启中奖励方式',
    zfrs: '支付人数',
    szswspfp: '设置实物商品发票',
    djpzsx: '当奖品只剩下“未中奖”时，不允许用户继续抽奖',
    db_1: '打标',
    yeqzf: '余额券支付',
    mrsy: '默认使用',
    zzzsczjfbdtw: '则作者首次自己发布的图文',

    qkys: '全款预售',
    ddmd: '订单门店',
    pzzzfm: '配置转赠封面',
    gq: '过期',
    qsrhdzbm: '请输入活动组编码',
    qbysyrybcddmm: '请不要使用容易被猜到的密码',
    jlffhkzgtsnlq:
        '不填写代表不限制时效。奖励发放后可在该天数内领取，例如填写1即奖励发放当天可领，填写2即奖励发放当日与次日可领取，以此类推',
    xzyfhd: '新增邮费活动',
    qsrklbmc: '请输入卡类别名称，不超过20个字',
    xgmfme: '修改免费名额',
    fxhdspwa: '分享活动商品文案',
    yx: '右旋',
    rxscygmqtgdgddjsc: '若需生成员工码请通过导购端点击生成',
    cj: '创建',
    zdpp: '指定品牌',
    wxzfwzfffsdbdczyhtg:
        '微信支付为支付分分数达标的车主用户提供“先离场后扣费”服务，基于用户微信账户和车牌的绑定关系，离场时对绑定账户自动扣费。用户一次开通，支持停车场均可使用。\r\nhttps:pay.weixin.qq.com//wiki//doc//apiv3_partner//open//pay//chapter3_7_1.shtml',
    qxzcytxzl: '请选择参与填写资料',
    yhk: '已换开',
    zflsh: '支付流水号',
    qzqtxsjhqbkzf: '4.请正确填写手机号且不可重复，若重复可能会导致发多次',
    tjhz: '添加后缀',
    dscxzdhqtdncx: '定时程序炸弹或其他电脑程序',
    zcsyzfbxcx: '支持使用支付宝小程序',
    djjr: '点击进入',
    qtxjfyxq: '请填写积分有效期',
    bmhhdqjjkcy: '报名后活动期间均可参与',
    ycz: '已充值',
    yqm: '邀请满',
    szfxgz: '设置分销规则',
    qscwjcgh: '请上传文件成功后',
    pblsp: '瀑布流商品',
    ztlsh: '中台流水号',
    czk: '储值卡',
    skf: '收款方',
    bmsl: '报名数量',
    jhzdyydymhxckd: '将会在打印页的页眉和新窗口的',
    yhxfhddz: '用户消费回调地址',
    qsrbkrs: '请输入帮砍人数',
    gjtqxzyhqhyhqlb: '个阶梯请选择优惠券或优惠券礼包',
    rqsz: '热区设置',
    jsp: '件商品',
    hybz: '会员备注',
    ztdpz: '自提点配置',
    zdyhf: '自定义回复',
    spyhqzhj: '商品优惠券折后价',
    xzcjcs: '新增抽奖次数',
    shdh: '商户单号',
    fqcs: '发起次数',
    fzjyhfstx: '分钟给用户发送提醒',
    xxdzxp: '线下电子小票',
    xzscjhddmd: '选择生成拣货单的门店',
    shxx: '审核信息',
    rxztcjeztcszbzxz: '如限制停车金额则停车时长不作限制',
    hyxm: '会员姓名',
    yxmd: '已选门店',
    bmhxyzhtjxsh: '报名后需要在后台进行审核',
    mchdzd: '每场活动最多',
    bjje: '保价金额',
    gwcyzszjlxbq: '购物车页展示专柜类型标签',
    h_gwcyzszjlxbq_tip_before:
        '设置“不展示”，在购物车列表页不展示专柜所对应的类型，例如自营、联营。',
    wxgzhgzdz: '微信公众号规则调整',
    cxjf: '查询缴费',
    zzsth: '正则式替换',
    zszjcsxz: '最少中奖次数限制',
    xf: '消费',
    zzjg: '组织机构',
    organizationId: '组织机构ID',
    hfmr: '恢复默认',
    qjscz: '请谨慎操作',
    yhjb: '用户举报',
    xzchjl: '新增促活激励',
    zyhpldttw: '则用户评论单条图文',
    mmzd: '密码长度',
    zgjl: '最高奖励',
    cyzxx: '参与者信息',
    qdkclx: '渠道库存类型',
    hbzf: '合并支付',
    qxzjhd: '请选择拣货单',
    hyrz: '会员认证',
    wdczk: '我的储值卡',
    qsrrq: '请输入日期',
    hqjfqd: '获取积分渠道',
    nwqxszsjm: '您无权限设置商家码',
    tjcl: '添加车辆',
    yhyxfcszsw: '用户月消费次数至少为',
    jlbm: '记录编码',
    kzdslljbkcg: '申购规则-设置抽奖次数-可中的数量累计不可超过100%',
    kjy: '砍价页',
    tpzy: '图片指引',
    xzlrqwqbfsh: '选择了人群为全部粉丝后',
    xzlrqwqbfsh_1: '选择了人群为全部粉丝后，不能在添加其他人群卡片，请删除',
    plzx: '批量注销',
    jfyxqgz: '积分有效期规则',
    bjdcmrbxz: '编辑弹层默认不选中',
    hyyh: '会员优惠',
    fjqldxsxxxfwj: '富集券联动，线上线下通用，消费无界',
    ztym: '专题页面',
    qxbcwxsqpzxx: '请先保存微信商圈配置信息',
    hfsxzt: '回复生效状态',
    hpmx: '货品明细',
    qrjygdj: '确认禁用该等级',
    fhxx: '返回信息',
    jzsj: '截止时间',
    qxzgx: '请选择关系',
    ffsl: '发放数量',
    ffslmf: '发放数量（每份）',
    zbj: '直播间',
    sxnr: '首行内容',
    rxcgnqlxkhjl: '如需此功能请联系客户经理',
    yhjsdtyhfnr: '用户将收到统一回复内容',
    ycjrxf: '用此兼容写法',
    kt: '开通',
    jfjgjzclwxs: '积分价格仅支持两位小数',
    zjycqbyhjssj: '最近一次全部用户计算时间',
    gm: '拱门',
    qxzplczddd: '请选择批量操作的订单',
    tgdrbgfh: '通过导入表格发货',
    sfscgxy: '是否删除该协议',
    bdz: '变动值',
    mhkmkcqsrdy: '盲盒可卖库存请输入大于0的正整数',
    byqrhdjshqs: '被邀请人活动结束后签收，邀请人同样获得奖励（不包含优惠券等虚拟订单）',
    sfdj: '是否冻结',
    szxcxfwlm: '设置小程序服务类目',
    ejfklx: '二级反馈类型',
    qksyzdyhyjm: '清空所有自定义会员价吗',
    ljdzyfz_1: '路径地址已复制',
    shdcmrxz: '审核弹层默认选中',
    tsjdn: '同时间段内',
    jhdzbfyhfssb: '用户设置屏蔽接受消息或者取消关注，均会导致部分用户发送失败',
    bzhdjl_1: '次奖励后，不再获得奖励',
    xxwbt: '选项为必填',
    tqze: '退券总额',
    bmcghckcj: '报名成功后才可参加',
    xxztjsy: '线下自提及使用',
    qsrttkddkhmy: '请输入天天快递的客户密钥',
    qtxyyxd: '请填写预约下单',
    dlaqclzskqygaqclyz: '登录安全策略至少开启一个安全策略因子',
    qsrschtdlcsxz: '请输入商城后台登录次数限制',
    wzfjstccdy: '位字符就是停车场对应',
    jyzsm: '经验值说明',
    zdysxsj: '自定义生效时间',
    rqxtsmzyxtz: '人群需同时满足以下特征',
    dtmrdycsccsx: '地图每日调用次数超出上限',
    jhmcbnzf: '计划名称不能重复，字数不超过20个字',
    yjfklxbnwk: '一级反馈类型不能为空',
    jpglzhbdcg: '奖品概率之和不得超过100%，目前已超出',
    xzmdztpz_1: '新增门店自提配置',
    sfyzsj: '身份验证时间',
    tnyxyhzmjdsqfp: '天内允许用户在买家端申请发票',
    jkbjt: '集卡背景图',
    qxfs: '取消发送',
    qsrlqhdyhqmc: '请输入领取后的优惠券名称',
    qsrlqhdyhqmczcz: '请输入领取后的优惠券名称，最长10字',
    slpz: '税率配置',
    dhzt: '兑换状态',
    dxyzmhy: '短信验证码核验',
    xsq: '线上券',
    jpslxz: '奖品数量限制',
    lbst: '列表视图',
    bnpzxtdmz: '不能配置相同的面值',
    mbwbxx: '模板尾部信息',
    srdt: '生日当天',
    sqsqszfbfpdsq: '申请商圈时支付宝分配的商圈id',
    zbzt: '直播状态',
    szmb: '设置模板',
    xxxwshdjfdgz: '线下行为所获得积分的规则',
    dbcl: '打标策略',
    xgjg: '修改价格',
    xzjbcs: '限制解绑次数',
    gzdjjlbzwsjsksy: '该字段仅记录不作为实际收款使用',
    kpzl: '开票种类',
    zthd: '专题活动',
    hxmdbm: '核销门店编码',
    ldy: '落地页',
    xzkpyxq: '限制开票有效期',
    wff: '未发放',
    jfzswa: '积分展示文案',
    tjccxx: '添加场次信息',
    ffsb: '发放失败',
    qsrmmebmfy: '请输入每名额报名费用',
    fzmddzxpfppz: '复制门店电子小票发票配置',
    czsb: '充值失败',
    shsq: '售后申请',
    zyhkddjs: '则用户看到的就是',
    hdqjkcy: '活动期间可参与',
    dataSCLoading: '数据正在生成中，稍后可前往数据--->>文件导入/导出记录查看',
    xzcjq: '新增抽奖券',
    qtxzdzjcs: '请填写最多中奖次数',
    bmkssjbndybmjssj: '报名开始时间不能大于报名结束时间',
    kssjdyjssj: '开始时间不能大于结束时间',
    kssjxyjssj: '结束时间不能小于开始时间',
    dqggmmhgaqe: '定期更改密码会更安全哦',
    showTimeError: '请选择展示时间',
    zbjmc: '直播间名称',
    spfw: '商品范围',
    zqxgslx: '周期限购数量需',
    zxql: '执行清零',
    zdsyfzxydydyzxsyfz: '最多使用分值需要大于等于最小使用分值',
    sqtjbkch: ' 确定后将立即申请汇款，申请提交不可撤回',
    fwss: '服务设施',
    lastModifiedUser: '操作人',
    qsrkjkc: '请输入砍价库存',
    xymc: '协议名称',
    lqdjssjbnzqsxjssjzq: '抱歉，领券的结束时间不能在券生效结束时间之前，请返回修改',
    dlmm: '登录密码',
    mccmyhxg: '每场次每用户限购',
    zhje: '折后金额',
    bbhzzzjdz: '不包含作者自己点赞',
    qsryxgdccbmme: '请输入要修改的场次报名名额',
    yh: '用户',
    ptlx: '拼团类型',
    qsrhdsm: '请输入活动说明，不超过1000个字',
    zjf: '总积分',
    xzhtjl: '新增话题激励',
    qgxjlx: '请勾选奖励项，最少选一项',
    yhnc: '用户昵称',
    szcjcs: '设置抽奖次数',
    sfzcsmkp: '是否支持扫码开票',
    ddzfrq: '订单支付日期',
    zdsyfzbxwzxdkdwdbs: '最多使用分值必须为最小抵扣单位的倍数',
    myhxzbmme: '每用户限制报名名额',
    mzq: '面值券',
    qjccsltxwz: '请将场次数量填写完整',
    xzgyl: '选择供应链',
    dyfzfpz: '第一分账方配置',
    tcqmc: '停车券名称',
    qtxjtrs: '请填写阶梯人数',
    tjjtjl: '添加阶梯奖励',
    wxd: '微信端',
    ddjebj: '达到金额保价',
    amountAfterTip: '元（包含）以上时，默认保价',
    zdyx: '自定义项',
    qsrdydy: '请输入大于等于0的正整数',
    gncd: '功能菜单',
    qxzmbldy: '请选择目标落地页',
    hyhdxg: '会员活动相关',
    zfwddctp: '支付完的弹层图片',
    yffzsl: '已发放总数量',
    kybl: '可用变量',
    dhsxsj_1: '兑换生效时间',
    wjydc: '文件已导出',
    tpbl: '图片比例',
    plxgccbmme: '批量修改场次报名名额',
    sffsqd: '三方发送渠道',
    dszx: '定时执行',
    fkcgsj: '付款成功时间',
    sfq: '三方券',
    khyl: '开盒有礼',
    sqmc: '社区名称',
    qqrmdpzfpxx: '请确认门店配置发票信息',
    drhmd: '导入黑名单',
    yjgz: '佣金规则',
    ljwz: '链接文字',
    hxr: '核销人',
    qdzh: '请调整回',
    gs: '估算',
    qsrffcs: '请输入发放次数',
    syyj: '剩余佣金',
    mgyhlqsl: '限制，每个用户领取数量',
    wzqp: '文字气泡',
    xzdyy: '新增打印员',
    fxfl: '分享返利',
    syyxq: '使用有效期(天)',
    rewards: '完善完成后赠送',
    qwzszwswchzsnr: '请完整设置完善完成后赠送内容',
    qkyhq: '清空优惠券',
    nrtb: '内容头部',
    xnm: '小柠檬',
    bjchjl: '编辑促活激励',
    dbje: '打包金额',
    mchdmgzqbmdyhsg: '每场活动每个中签白名单用户申购',
    mrdhj: '默认兑换价',
    fxxt: '分享小图',
    jsyxq: '结束有效期',
    xszydgz: '需设置预订规则',
    dxk: '多选框',
    zffsmm: '支付方式密码',
    fly: '分类页',
    jfdhcjm: '积分兑换抽奖码',
    xyxgjygbmsqxgdzzzm: '需要向国家有关部门申请相关的资质证明',
    bbhxxzjpp: '不包含线下专柜品牌',
    jzqclgxzzzlyjzz: '精准券策略，高效转化，助力业绩增长',
    jckg: '剪裁框高',
    tygbmdhdhjxsgxy: '同一个编码的活动会进行申购校验',
    yhdzsdywxewm: '活动咨询人必须是店员身份，用户端展示店员微信二维码,',
    xzpz: '新增配置',
    hbgs: '红包个数',
    bjzs: '笔记总数',
    dhgz: '兑换规则',
    qsryxhkcs: '请输入允许换开次数，仅支持输入正整数，最大可换开10次',
    yqhycghdcjcs: '邀请好友成功获得抽奖次数',
    ytccskdyzftgj: '由停车场收款的业主方提供给',
    qxzdkxddgkxsfw: '请选择代客下单导购可销售范围',
    an: '按钮',
    jsqx: '角色权限',
    qxzgyl: '请选择供应链',
    hdgymddccs: '后端关于门店的传参是',
    jpzdglbnwk: '奖品中的概率不能为空、请输入大于0，小于100的数字，支持两位小数',
    xxgdjssjbnzydqdjssj: '新修改的结束时间不能早于当前的结束时间',
    tpgd: '图片高度',
    fxbzlhdcjmsxbnwk: '分享被助力获得抽奖码上限不能为空，并且仅支持到正整数',
    hdkg: '活动开关',
    dcsj_1: '导出数据',
    ztgz: '自提规则',
    tgqd: '推广渠道',
    cjsb: '裁剪失败',
    shfxyxx: '审核分销员信息',
    ptsz: '拼团设置',
    spjysdsh: '商品经营手动审核',
    sfscgjl: '是否删除该奖励',
    hddksjsrq: '活动的开始结束日期',
    zdtxed: '最低提现额度',
    scdl: '商场导览',
    ddhfzsb: '订单号复制失败',
    xzrq_1: '选择日期',
    xzyfq: '新增运费券',
    xzzdyx: '新增自定义项',
    ymjj: '页面间距',
    smhcn: '声明或承诺',
    qczjy: '券充值交易id',
    scdhm: '生成兑换码',
    dqmdykqjh: '当前门店已开启拣货，请及时处理拣货单',
    wszzdqrshsj: '未设置自动确认收货时间',
    zjjsje: '专柜结算金额',
    xzys: '选择样式',
    drzdyh: '导入指定用户',
    tymd: '同一门店',
    bsz: '被收藏',
    ewjl: '额外奖励',
    gzygb: '规则已关闭',
    qdhjljsqhk: '确定后将立即申请汇款',
    ycrxm: '用餐人姓名',
    fszhktyj: '发送账号开通邮件',
    bjfw: '笔记服务',
    jfxykj: '仅分销员可见',
    bmccrq: '报名场次日期',
    pt: '拼团',
    kfpz: '客服凭证',
    xysmrz: '需要实名认证（姓名、身份证）',
    rznyd: '日只能预订',
    qxsq: '取消授权',
    zysqxcxgzhzj: '注意授权小程序公众号组件',
    thrsjh: '提货人手机号',
    kdjckyt: '可点击查看原图',
    wdfk: '我的反馈',
    xshxx: '线上和线下',
    mqfl: '卖券分类',
    smrzfwxy: '实名认证服务协议',
    fztghd: '复制团购活动',
    yxtsjscq: '由系统随机生成券',
    yxdjxhtgyy: '精准、有效的精细化推广运营',
    zfsz: '支付设置',
    zdyxblyghc: '最多允许保留一个缓存',
    yj_2: '圆角',
    qtxxtzxcqsj: '请填写系统执行抽签时间',
    ljdz: '链接地址',
    pt_1: '拼图',
    qsrgjzss: '请输入关键字搜索',
    ssypthd: '双十一拼团活动',
    xzbg: '新增包裹',
    mgymjzctjyggzhzj: '每个页面仅支持添加一个公众号组件',
    zjglbnwk: '中奖概率不能为空',
    l_zjglbnwk: '中奖概率格式错误！',
    spzj: '商品总价',
    psfw: '配送范围',
    hdmcbkzf: '活动名称不可重复，文字限制不超过20字',
    gzxx: '规则信息',
    spdcpljjsyc: '视频多次评论仅计算一次',
    qtxdzxy: '请填写电子协议',
    qzqtxsp: '4.请正确填写商品/货品编码，若重复只视为一个商品/货品',
    jjbyjyx: '不清零，即金币永久有效',
    srq: '生日前',
    pccjyw: '片尺寸建议为',
    ddqsh: '订单签收后',
    qsrdh: '请输入电话',
    qsrsqmc: '请输入社区名称',
    drsb: '导入失败',
    fzhjzxksjf: '分钟后将重新开始计费',
    bjhtjl: '编辑话题激励',
    klbmc: '卡类别名称',
    fwl: '访问量',
    yhmyxf: '用户每月消费',
    zxdgy: '招新导购员',
    ewmxzlj: '二维码下载路径',
    ksh: '开始后',
    gzhsfwh: '公众号是服务号',
    yyqg: '预约抢购',
    wxsjq: '微信商家券',
    qbhy: '全部会员',
    symddpsfs: '使用门店的配送方式',
    yfgzymd: '运费规则与门店',
    l_yfgzymd_1: '运费，将按照实际支付的现金部分进行收取，运费规则与门店//专柜使用一套运费规则',
    dydczs: '抖音弹层展示',
    dcts_1: '待成团数',
    fwyhs: '访问用户数',
    qsrzqgsdsjh: '请输入正确格式的手机号',
    plszsj: '批量设置售价',
    dgyhdttw: '单个用户单条图文',
    hyewm: '会员二维码',
    jcjl: '基础奖励',
    drwj: '导入文件',
    zgzrs: '总关注人数',
    xzgzh: '新增公众号',
    sycw: '剩余车位',
    qbnrbhzzlsljfbdqbtw: '全部内容包含作者历史累计发布的全部图文',
    zxsbsdjzx: '执行时倍数叠加执行',
    selected: '选择',
    jcsj: '借出时间',
    yhtglhjcj: '用户通过老虎机抽奖，赢取奖励',
    hykm: '会员卡面',
    rkan: '入口按钮',
    xsscsy: '线上商城使用',
    qsrnr: '请输入内容',
    sjkc: '实际库存',
    sfqtqje: '三方券退券金额',
    yxzzszdyqjpwa: '已选择展示自定义券奖品文案',
    zyscxhgzzsjl: '只有首次相互关注赠送奖励，只要历史相互关注过不重复赠送',
    gjhbzjehhbgs: '随机分配：根据红包总金额和红包个数，进行随机分配金额；',
    cdsjzkzt: '产地数据展开状态',
    zxdkdw: '最小抵扣单位',
    xtqjxxtdjxfsj: '如勾选线下电子小票，需提前进行系统对接消费数据。',
    shyy_1: '售后原因',
    gzqanbjt: '规则区按钮背景图',
    hdsjnhd: '活动时间内获得',
    qsrzqgsddlyx: '请输入正确格式的登录邮箱',
    xzzjlx: '选择专柜类型',
    yeqdklx: '余额券抵扣类型',
    yxsn: '一小时内',
    qhwbzzssrk: '前后文本正整数输入框',
    qnxdbrsfz: '请您携带本人身份证',
    zjzfzb: '专柜支付占比',
    sj: '升级：',
    jlfw: '奖励范围',
    jgnzpzcx: '功能再配置此项',
    qlxkhjlpzsqwcsqjfdsq: '功能再配置此项，请联系客户经理配置商圈完成商圈积分的申请',
    rxzsymdkc:
        '如选择使用门店库存，活动设置10个门店参加，则用户从这10个门店购买的商品分别扣减10个门店的库存；',
    wxddrj: '微信打单软件',
    yydh_1: '预约电话',
    yjy: '已禁用',
    sfqrscgcdxpzdqbnr: '是否确认删除该菜单下配置的全部内容',
    kpzscs: '卡片赠送次数',
    scdj: '删除等级',
    bq: '标签',
    jfyazbxyydxyfcdxydwyzr: '甲方应按照本协议约定向乙方承担相应的违约责任',
    ttwxxnr: '条图文消息内容',
    bjmdpzczkfp: '编辑门店配置储值卡发票',
    fxychdyccjjh: '分享一次获得一次抽奖机会，获得抽奖次数上限',
    bkdbxyyfbxzfjdsfdpck: '包括但不限于依法必须支付给第三方的赔偿款',
    szmd: '设置门店',
    yxgscw: '邮箱格式错误',
    xstcsz: '小时停车时长',
    ptsp_1: '拼团商品',
    xzzdzjsxyzdgzjlxxdzj: '选择指定专柜时需要指定该专柜类型下的专柜',
    bnsqsh: '不能申请售后',
    ddh_1: '订单号',
    qsylj: '缺省页链接',
    sjqx: '数据权限',
    qx: '取消',
    qasxtj: '输入时间参考：08:00-13:00，请按顺序添加',
    jyckdw: '建议传宽度为',
    mhzdkmzs: '盲盒最大可卖总数',
    mqxxjzdsqjfcj: '目前线下仅针对商圈积分场景',
    szh: '设置后',
    hydyh: '会员的用户',
    zxkpcg: '重新开票成功',
    sxzdsjhsch: '所选中的手机号删除后，在此合作企业下不可再用该手机号进行认证，是否删除？',
    ykyzdyzqtzsddjmc: '也可以自定义在前台展示的等级名称',
    yxkh: '银行卡号',
    h_1: '和',
    khqtqdjsy: '可和其他券叠加使用',
    sfzcfpyj: '是否支持发票邮寄',
    szjyzzdymc: '设置经验值自定义名称',
    ydyhyqpyyqptgm: '引导用户邀请朋友一起拼团购买',
    xzyqyl: '新增邀请有礼',
    rqmc: '热区名称',
    suppoert7Count: '最多可支持7档，不可再添加',
    supportMaxCount: '最多可支持{{maxCount}}档，不可再添加',
    yxqazrtjs:
        '有效期按自然天计算。举例：如设置购券5天以后失效，用户7月10日15:00领取优惠券，则该优惠券的可以时间为7月14日23:59:59不可用。',
    qxzhydj: '请选择会员等级',
    schyxq: '转链并生成短链接，生成后有效期30天',
    jfqy: '积分权益',
    zjsdshzj: '增加手动审核专柜',
    mtxlj: '茅台系列酒',
    sfqclmx: '三方券处理明细',
    qsrljdz: '请输入链接地址',
    zdshsj: '自动审核时间',
    showWechatandAliLoading: '正在同步至微信/支付宝卡券，同步成功，系统将自动开启该券规则',
    showAlipayLoading: '正在同步至支付宝卡券，同步成功，系统将自动开启该券规则',
    showWechatLoading: '正在同步至微信卡券，同步成功，系统将自动开启该券规则',
    tkxj: '退款现金',
    wwynzf: '5位以内符号',
    wwynzfzktxfh: '5位以内符号，只可填写符号',
    wynszzmhzf: '32位以内数字、字母或符号',
    hdsm: '活动说明',
    qsyhs: '签收用户数',
    xnflsplb: '虚拟分类商品列表',
    qrjyjs: '确认禁用角色',
    tjlx: '条件类型',
    ejlx: '二级类型',
    jfbdyzs: '甲方不得以转售',
    hykdjzksz: '会员卡等级折扣设置',
    jyzfbbkwk: '经验值翻倍不可为空',
    yndsj: '以内的数据',
    qsryhqbm: '请输入优惠券编码',
    qsryhqlx: '请输入优惠券类型',
    zsfwgzhfs: '"中是否为公众号粉丝',
    fyzj: '费用增加',
    schdy: '商场活动页',
    pldssmcg: '批量定时售卖成功',
    sgtj: '申购条件',
    qjyjgxsggz: '请将已经勾选的 申购规则-申购条件 填写完整',
    bmyhzq: '报名用户中签',
    fcbq: '非常抱歉',
    mm: '密码',
    yfjezdzclwxs: '应付金额最多支持两位小数',
    zkj: '折扣价',
    ckqgz: '查看券规则',
    clearingDaybefore: '日可发放上月结算的佣金',
    rkffsyjsdyj: '每月#value#日可发放上月结算的佣金',
    ctsj: '成团时间',
    qtxpp: '请填写品牌ID',
    sfscgklb: '是否删除该卡类别',
    ywdxd: '业务短信等',
    wzj: '未中奖',
    zx_1: '最小',
    bqnwktggnlmqx: '抱歉您未开通该功能栏目权限',
    cyzsjh: '参与者手机号',
    wzshdndspjsyj: '交易完成后需要再等N天，对未退款、未在售后单内的商品结算佣金',
    qsrcjqmc: '请输入抽奖券名称',
    qsrcjqmcLimit: '请输入抽奖券名称，不超过20个字',
    spbh: '商品编号',
    zdysjxg: '自定义时间限购',
    dlsjrlckkym: '登录时进入两次开卡页面',
    pljycg: '批量禁用成功',
    tkdzt: '退款单状态',
    accbmgm: '按场次报名//购买',
    mrdbj: '默认都保价',
    azhydjjb: '按照会员等级加倍',
    scdlsj: '首次登录时间',
    kq: '开启',
    qsrljwz: '请输入链接文字',
    qxzsjzzjg: '请选择上级组织结构',
    cjsnzdsykdjyhqzsxz: '次结算内最大使用可叠加优惠券张数限制',
    ljjb: '累计金币',
    shh: '商户号',
    mhhd: '盲盒活动',
    plksjhbdy: '批量开始拣货并打印',
    yfljjejexydxdysyd: '保存失败，邮费立减金额金额下一档需大于上一档',
    fxhdcjmsxbnwk: '分享获得抽奖码上限不能为空，并且仅支持到正整数',
    dhxfjf: '兑换消费积分',
    ptyhsgsl: '普通用户申购数量',
    xzjkhd: '新增集卡活动',
    rbpzzbzstp: '如不配置则不展示图片',
    xh_2: '行号',
    lsl: '例税率',
    wjjntpslbcg: '文件夹内图片数量不超过',
    jxbj: '继续编辑',
    dsfzf: '第三方支付',
    pjtp: '评价图片',
    qxcg: '取消成功',
    xzgzrs: '新增关注人数',
    jpszzbxxzyx: '奖品设置中必须选中一项',
    bjpld: '笔记评论得',
    z_3: '镇',
    ljqd: '累计签到',
    jk: '集卡',
    hdydt: '活动页底图',
    dddpsfs: '订单的配送方式',
    adtnrjs: '按单条内容计算',
    fxtp: '分享图片',
    sharePictureExtra: '图片尺寸建议为250px * 200px',
    pictureLimit80: '图片尺寸建议为80px*80px',
    jkdhsl: '增/减可兑换数量',
    qsrydkddmm: '请输入韵达快递的密码',
    hyyhlmc: '会员优惠栏名称',
    dcyrlq: '大促预热利器',
    xlcgdd: '销量从高到低',
    mrmykmfbmme: '每人每月可免费报名名额',
    ccsj: '场次时间',
    czl: '差重量',
    dckbmcg:
        '如填写30天，活动日期为1日-30日，若用户是25号参加的活动，则从25号当天开始计算，用户的注册时间必须为上月26日24:00及之前注册（若上月为30天）的才可报名成功',
    abzflpz: '按标准分类配置',
    zadjpzxktzdzsx: '长按点击配置项可拖拽调整顺序',
    xzrqtz: '选择人群特征',
    djmc: '等级名称',
    dhmbh: '兑换码编号',
    jlzdds: '奖励总订单数',
    wgq: '未过期',
    bsy: '不使用',
    yfje_2: '已付金额',
    pjnr: '评价内容',
    lht: '礼盒图',
    schdpz: '商场活动配置',
    shrxxwqyzdddkhdfh: '收货人信息完全一致的订单可合单发货',
    dykqzs: '端越靠前展示',
    bjhf: '编辑回复',
    qsrsl: '请输入税率',
    xzhzqy: '新增合作企业',
    sy_1: '使用',
    mmxgcg: '密码修改成功',
    ccbmme: '场次报名名额',
    shje: '售后金额',
    qxzklb: '请选择卡类别',
    qtxbz: '请填写备注',
    dymd: '打印面单',
    jfbgsj: '积分变更时间',
    wxgzpt: '微信公众平台',
    qtxpszljtj: '请填写配送重量阶梯价，且必须大于0',
    cssmjldpdwfsc: '产生售卖记录的票档无法删除',
    fxs: '分享数',

    qxzplczdpl: '请选择批量操作的评论',
    dx: '短信',
    blzidwajs:
        '变量字段：couponType会自动读取发给用户券类型名称，如更改变量字段则无法自动读取对应内容。shortLink自动生成支付宝或微信小程序的对应小票详情页短链。案例：尊敬的会员您好，您通过支付奖励得到停车券，请您前往小票中心领取并在有效期内使用。s-test.co-mall.com/a47q3',
    srsjgsck: '输入时间格式参考：08:00-13:00，请按顺序添加',
    lhhd: '联合活动',
    zzshshdscxyddyzsjfxx: '则在审核售后单时查询原订单已赠送积分信息',
    hdqjxfzyqhybmkj: '活动期间消费者邀请好友帮忙砍价',
    zdsrrq: '指定生日日期',
    tjpsqy: '添加配送区域',
    qsrqbnwyjzrq: '起始日期不能晚于截止日期',
    qtxjpszxzhdjfhzfs: '请填写奖品设置选中后的积分或者份数',
    mslb: '秒杀列表',
    sqdltp: '授权登录图片',
    h_sqdltp_tip: '建议图片尺寸小于750*880；大小不超过1M；',
    sm: '书面',
    bjbr: '被举报人',
    wzf: '未支付',
    lmspxlpx: '类目商品销量排行',
    wdfwyy: '我的服务预约',
    bjzdyrq: '编辑自定义人群',
    dkgz: '抵扣规则',
    bgtsyq: '包裹特殊要求',
    bpzzsmrysgdwz: '不配置展示默认样式固定文字',
    dzs: '的整数',
    czpx: '创作排行',
    sb_1: '上报',
    txccdxgxx: '填写车场的相关信息',
    zckzm: '支持扩展名',
    qrffjlm: '确认发放奖励吗？',
    dkje: '抵扣金额',
    sst: '随时退',
    fzkncxlm: '否则可能出现乱码',
    qsrplsp: '请输入评论商品',
    yxzh: '银行账号',
    gmsl: '购买数量',
    zsxzyzjl: '至少选择一种奖励',
    hdqjljxfxyjekhddycsjkjh: '活动期间累计消费相应金额可获得对应次数集卡计划',
    h_ccjtdz_tip:
        '数电票时，必填；地址中（包含车场区域信息）且必须包含 街、路、村、乡、镇、道 关键词，最多80个字符',
    dqmdwgldgzh: '当前门店无关联的公众号，请点击下方按钮去授权公众号，拉取公众号下模板。',
    qtxnyr: '请填写年月日',
    againSong: '再送',
    point: '积分',
    sPoint: '送{{point}}积分',
    fzsj: '分账时间',
    ydjbh: '原单据编号',
    rjfjy: '如积分将于',
    l_rjfjy: '如积分将于2022年12月31日清零',
    rzqbhzccysg:
        '如配置5天，若用户在9月1日申购成功，则用户在9月5日之前不会再次参与申购，在9月6日时可参与申购；',
    qszxzclljmb: '请设置寻找车辆链接目标',
    yqzdshq: '逾期自动收货前，用户申请',
    h_yqzdshq_fill: '逾期自动收货前，用户申请#value#天时，系统自动确认收货',
    cjzjzsyg: '创建专柜展示员工',
    xzdhdx: '选择兑换对象',
    mxk: '卖小客',
    qwgzh: '前往公众号',
    qdsc: '确定删除',
    ydz: '元打折',
    swbxs: '首位必须是',
    xfzxzyxqngmkjsp: '消费者须在有效期内购买砍价商品',
    htcj: '后台创建',
    rsysjhdl: '如使用手机号登录',
    chddb: '除回到顶部，最多还可选中9个子窗口，可拖动调整顺序',
    pssj: '配送时间',
    xzschdy: '新增商城活动页',
    zyyqsdddckzcshbzyhdzsshrkan: '只有已签收的订单才可支持售后并在用户端展示售后入口按钮',
    lpqtp: '礼品券图片',
    qszpd: '请设置票档',
    qsrddh: '请输入订单号',
    yzflsh: '原支付流水号',
    jbgz_1: '解绑规则',
    jyqxdqzkkym: '建议取消掉强制开卡页面',
    qrfh: '确认发货',
    ccsjhbnlq: '天内可领取，超出时间后不能领取',
    kpnr: '开票内容',
    dk: '抵扣',
    djf: '兑积分',
    bmx: '贝明细',
    dczdkybnwk: '单次最大可约不能为空',
    x_1: '限',
    szyhqlb: '设置优惠券礼包',
    zwglwzqx: '暂无管理文章权限',
    xdsj: '下单时间',
    gmddkc: '个门店的库存',
    bmcgtx: '报名成功提醒',
    djsfshxth: '对接三方审核系统后',
    zddx: '指定对象',
    cjxftsdcgmjz: '刺激消费，提升单次购买价值',
    xszhdlsj: '线上最后登录时间',
    bqxx: '标签信息',
    zqxzldbz: '准确性之类的保证',
    jtdzsjxyyxwz: '具体到账时间需以银行为准',
    bmqx: '报名取消',
    sjmc: '事件名称',
    wxgg: '微信广告',
    scdg: '商场导购',
    drzqrs: '导入中签人数',
    sfzsklb: '是否展示卡列表',
    cssz: '参数设置',
    qshck: '请稍后查看',
    scmcj: '商城名称及',
    jfdhwa: '积分兑换文案',
    qrgb: '确认关闭',
    qkxx: '清空选项',
    sxbmsx: '失效报名筛选',
    srz: '生日在',
    hdccdmgsjdbkzf: '活动场次的每个时间段不可重复',
    cjhzsj: '抽奖汇总数据',
    seogl: 'SEO管理',
    wxgzh: '微信公众号',
    jfzcmqsmdjfdxgnbzrhmshasdbz: '甲方在此明确声明对甲方短信功能不作任何明示或暗示的保证',
    szhdj: '设置活动价',
    yhhdzgjtjlh: '不勾选，用户获得最高阶梯奖励后，在活动时间内无法继续获得奖励',
    wxdyxx: '微信订阅消息',
    zdsc_1: '最大上传',
    tzmc: '团长名称',
    xxbnwk: '选项不能为空',
    jkssj: '将开始时间',
    cbfh: '拆包发货',
    ktzdzsx: '可拖拽调整顺序',
    sqgl: '商圈管理',
    bg: '包裹',
    wd: '我的',
    ptgm: '拼团购买',
    rsjkczsr: '若是减库存则输入<0的数字，若是增加库存则输入>0的数字',
    zdzq: '最多中签',
    fjzz: '父级组织ID',
    splb_1: '商品列表',
    mphd: '卖票活动',
    qsrmhkmkc: '请输入盲盒可卖库存',
    tgqdscdlyhspx: '推广渠道首次登录用户数排行',
    zsl: '总数量',
    jfbd: '积分变动',
    xszsj: '小时制时间',
    mb_1: '每被',
    zbzcpz: '暂不支持配置',
    zmhfh: '字母或符号',
    zjdsjd: '之间的时间段',
    gzgzh: '关注公众号',
    bjxggz: '编辑限购规则',
    qxzycsj: '请选择用餐时间',
    qdfphm: '全电发票号码',
    jfssglqydxwstwjfdxw: '甲方上述关联企业的行为视同为甲方的行为',
    zdyjl: '自定义奖励',
    qzxsqqbqx: '请重新授权全部权限',
    xyhzccg: '新用户注册成功',
    hdsmzdccxz: '活动说明长度超出限制',
    remarkPlaceHolder: '请输入活动说明, 不超过120字',
    twddctrs: '团未达到成团人数',
    tjsj_1: '添加时间',
    yhsf: '用户身份',
    hydjffgz: '会员等级付费规则',
    bjqdm: '编辑渠道码',
    fpqx: '分配权限',
    lygl: '联营管理',
    kdy: '快递员',
    jtjl: '阶梯奖励',
    jf_1: '甲方',
    hdgjldyhhqbhzczs: '需完善以上信息才会送用户奖励，获得过奖励的用户后期不会再次赠送',
    tpdxbncg: '图片大小不能超过',
    xsswdd: '线上实物订单',
    jsxzcg: '角色新增成功',
    qsrzqdrq: '请输入正确的日期',
    hxmd: '核销门店',
    lqytpbnwk: '领取页图片不能为空',
    szdcc: '是针对场次',
    s_2: '四',
    yndjkhqwxxcxdydzthdy: '页内点击可唤起微信小程序对应的专题活动页',
    yfgzdljs: '运费规则独立计算',
    xgdkmbyhfsbg: '相关的卡模板也会发生变更',
    qsrdlmm: '请输入登录密码',
    tfxcx_1: '它方小程序',
    yqldsxyhs: '邀请来的是新用户时，给新用户发放礼物',
    qwztxdkgz: '请完整填写抵扣规则',
    qtxx: '其他信息',
    userNameRule: '请输入4~32位账号，数字、字母、或字符',
    sjyz: '手机验证',
    qxzxcxkptp: '请选择小程序卡片图片',
    yeqxxzfewmzswz: '余额券线下支付二维码展示位置',
    h_yeqxxzfewmzswz_before:
        '顾客在线下使用pos支付时扫码支付余额券，控制余额券二维码展示入口,开启前请咨询客户经理确认已开通此功能接口。',
    qtxzqdycrsjh: '请填写正确的用餐人手机号',
    hdxzcg: '活动新增成功',
    mhzdkms: '盲盒最大可卖数',
    dpcx: '多品促销',
    fphm: '发票号码',
    zzfxbt: '转赠分享标题',
    h_zzfxbt_placeholder: '赠您{facevalue}元储值卡～',
    h_zzfxbt_tip: '可配置{facevalue}参数，转赠时显示卡面值',
    ljdkxcxrszb: '累计打开小程序人数占比',
    hdkssjywydqsj: '活动开始时间要晚于当前时间',
    ddyfjexydxdysyd: '保存失败，订单应付金额下一档需大于上一档',
    qtxhdksygsj: '请填写活动开始预告时间',
    mhykddj: '名好友砍到底价',
    bxf: '笔消费',
    xgtj: '限购条件',
    sszzjg: '所属组织机构',
    organizations: '门店//专柜（数据权限）',
    bdlx: '变动类型',
    clsb: '处理失败',
    ptmc: '拼团名称',
    gzhbdmdxx: '公众号绑定门店信息',
    z_4: '在',
    lqhktbzwxkbz: '领取后可同步至微信卡包中',
    zcdh: '注册电话',
    mmzzcg: '密码重置成功',
    bmcghff: '报名成功后发放',
    fszd: '发送中断',
    htdlydwz: '后台登录页底文字',
    h_htdlydwz_msg: '后台登录页底文字,不能为空',
    h_htdlydwz_placeholder: '请输入，最长100字',
    szzsh: '设置展示后',
    shsqxx: '售后申请信息',
    c: '场',
    dxsjb: '单选升级版',
    xznrdc: '新增内容弹窗',
    cyfs: '参与方式',
    zhcjrq: '账号创建日期',
    yhhl: '优惠好礼',
    l_yhhl: '优惠好礼，点击',
    mdxxdxf: '门店信息的下方',
    expressCompanies: '快递-配送公司',
    cityWideCompanies: '同城配-配送公司',
    zfbcyf: '支付补偿运费',
    swsp: '实物商品',
    bmyhzc: '避免用户注册',
    ddxx: '订单信息',
    zwm: '中文名',
    yxpt: '游戏平台',
    jbzt: '举报状态',
    pm: '品名',
    qsrhsnr: '请输入话术内容',
    jczj: '基础组件',
    fklx: '反馈类型',
    ytcje: '元停车金额',
    qdbkcs: '全店帮砍次数',
    czzt_1: '操作状态',
    xzydcdbb: '选择要导出的报表',
    xzzdyzzjg: '新增自定义组织机构',
    glyzh: '管理员账号',
    txzzs: '填写正整数',
    qxxwkhxzj: '权限下无可核销专柜',
    kdhsl: '可兑换数量',
    qsrzgyzsdwldkhxyh: '请输入中国邮政速递物流的客户协议号',
    cczsgyhzcmdxjfzz: '如积分区分门店，此处展示该用户注册门店下积分总值',
    xzmb_1: '选择模板',
    nxfe: '年消费额',
    txsz: '填写数字',
    kqqqzxkhjlqryktcgnjk: '开启前请咨询客户经理确认已开通此功能接口',
    fxbzlhdcjmsx: '分享被助力获得抽奖码上限',
    ewmhfxxsxsj: '二维码回复消息生效时间',
    qxzygfwdd: '请新增一个服务地点',
    jskf: '技术开发',
    rzehyqcbz: '如周二会员全场八折',
    qxzbq: '请选择标签',
    zhxx: '账户信息',
    mdyyzt: '门店营业状态',
    xzmddygx: '新增门店对应关系',
    wddd: '我的订单',
    shr: '收货人',
    yyzzlt: '原因在这里填，审核通过后，在页面中展示',
    ysjqsrdy: '预售价请输入大于0的正整数',
    bjxq: '笔记详情',
    btjzzjg: '不同级组织机构 无法一起变更',
    qtxmyhzqcs: '请填写每用户中签次数',
    qsz: '请设置',
    bpz: '不配置',
    zxczr: '注销操作人',
    yhcklh: '开盒惊喜，购物添趣，提升体验',
    zcqgsjgmbcg: '再次抢购时将购买不成功',
    qtxbyqrjlzdynr: '请填写被邀请人奖励自定义内容',
    sfhf: '是否回复',
    dsfs: '定时发送',
    tzxcxs: '跳转小程序时',
    thr: '提货人',
    jgcddg: '价格从低到高',
    pz: '配置',
    kzdyzjtzdmc: '可自定义专柜团长的名称，例如：社区合作伙伴',
    wdptsp: '我的拼图碎片',
    qtxztdxxdz: '请填写自提点详细地址，最短五个字，最长50个字',
    dghx: '导购核销',
    ljtsxydxdysydts: '保存失败，累计天数下一档需大于上一档天数',
    tpcj: '图片裁剪',
    bmjf: '报名积分',
    glhykdj: '关联会员卡等级',
    zz_1: '转租',
    tzddxq: '跳转订单详情',
    kjyxq: '砍价有效期',
    bmbmdgl: '报名白名单管理',
    bsdzcpsl: '本时段支持配送量',
    zjjt: '增加阶梯',
    bxybkdbxyxsdtkhtj: '本协议包括但不限于下述的条款和条件',
    dbkddcrss: '当帮砍达到此人数时，砍价成功',
    hyzkbq: '会员折扣标签',
    fzyx: '分钟有效',
    ksjxzdhfsz: '快速进行自动回复设置',
    zdsr: '最多输入',
    bjf: '倍积分',
    wsgcgbfs: '未申购成功不发送',
    subtractFreight: '邮费立减',
    yflj: ' 元，邮费立减',
    qsrzjcssx: '请输入中奖次数上限',
    yltsj: '有两条数据',
    dqmd: '当前门店',
    gsjhmyzc: '该手机号没有注册',
    bjyfgz: '编辑运费规则',
    spzsl: '商品总数量',
    zchtzdym: '注册后跳转的页面',
    jkyl: '集卡有礼',
    wjdr: '文件导入',
    yhqkcjs: '优惠券库存减少',
    rpzsbjlrs: '若配置双倍奖励人数',
    qwgspxzyzpsfs: '请为该商品选择一种配送方式',
    zjgl_1: '中奖概率',
    jwddx: '经纬度对象',
    mqjzcamtjxxflj: '目前仅支持按每天进行消费累计',
    nbjsjf: '内部结算缴费',
    schdpcgz: '商场活动排斥规则',
    czqhp: '存在缺货配',
    jplx: '奖品类型',
    ztmdpz: '主体门店配置',
    dhq: '待获取',
    qtxmcxg: '请填写每场限购',
    msgl: '秒杀管理',
    qzqtxlqsl: '请正确填写领取数量',
    jtfw: '交通服务',
    qxzwlgs: '请选择物流公司',
    qschdt: '请上传活动图',
    btxzzddqxzdyhqhyhqlbks: '不填写则自动读取选择的优惠券或优惠券礼包开始',
    sqtghdjsh: '社区团购活动结束后',
    cyshdz: '常用收货地址',
    jfdhlj: '积分兑换链接',
    szzhykxfsfzshyyhbk: '设置在会员卡下方是否展示会员优惠板块',
    yhdqmdxdhydj: '用户当前门店下的会员等级',
    xdrsjh: '下单人手机号',
    zsbcg: '字数不超过',
    zxzfje: '在线支付金额',
    jhdxq: '拣货单详情',
    qxzjyrq: '请选择交易日期',
    syhq: '送优惠券',
    tpbq: '图片标签',
    qtxcjsm: '请填写场景说明',
    shjhyfbc: '售后寄回运费补偿',
    qszyhqffsl: '请设置优惠券发放数量',
    xztg: '新增团购',
    xxzcmd: '线下注册门店',
    gjpjtjesxxydyjexxdje: '保存失败，第{{num}}个奖品阶梯金额上限需要大于金额下限的金额',
    qtxdkgz: '请填写抵扣规则',
    ppg: '品牌馆',
    x_2: '项',
    qsrsjmc: '请输入事件名称',
    mrdgzdgdksmscqbzssp:
        '默认导购在导购端可售卖商城全部在售商品，选择导购所属专柜则导购只能售卖导购账号关联的专柜商品',
    qsrzdgmjs: '请输入最多购买件数',
    kqcg: '扣券成功',
    gk: '概况',
    ddewm: '订单二维码',
    yxfebndynxfe: '月消费额不能大于年消费额',
    yxkhhjylshzsxysryx: '银行卡号或交易流水号至少需要输入一项',
    kj: '砍价',
    qsryhqmc: '请输入优惠券名称',
    yyjl: '预约记录',
    tkz: '退款中',
    yjtk: '已退款',
    sfqrsccthf: '是否确认删除此条回复',
    axyhzcfjl: '按新用户注册发奖励',
    bjymm: '编辑页面码',
    qsrzqgsdyx: '请输入正确格式的邮箱',
    klbtp: '卡列表图片',
    h_klbtp_tip: '建议尺寸：654px * 高不限，大小不超过1M，支持jpg，png，jpeg格式',
    sm_1: '说明',
    qfdj: '区分等级',
    czjg: '操作结果',
    bmjssj: '报名结束时间',
    xtmrbmxxwxm: '系统默认报名信息为姓名、手机号、日期、时间。',
    yhtsd: '用户投诉等',
    qzsxzyghdmstj: '请至少选择一个活动模式条件',
    hfzd200z: '回复最多200字',
    z_5: '增',
    gyhhzs: '个用户会赠送',
    cjzjjl: '抽奖中奖记录',
    tjbmcgjwshtg: '提交报名成功即为审核通过',
    xzfsd: '新增粉丝得',
    xryl: '新人有礼',
    zdxnfl: '指定虚拟分类',
    zqjg: '中签结果',
    sjzzcl: '数据正在处理',
    jhsy: '拣货剩余',
    qsrzdfs: '请输入指定粉丝/会员',
    xshd: '显示活动',
    dssmgb: '定时售卖关闭',
    qtsjbnxsyh: '其他时间不能享受优惠',
    sqzt: '授权状态',
    sssj: '上送数据',
    jldctp: '奖励弹窗图片',
    rewardPictureExtra: '图片尺寸建议为260px * 318px',
    qbfh: '全部发货',
    bjhyzk: '编辑会员折扣',
    ccsjjljbsh: '超出时间奖励将被收回',
    yffsl: '已发放数量',
    wxsrdqxx: '无需输入地区信息',
    xggmsl: '限购购买数量',
    bcbfbcd: '保存并发布菜单',
    qdxgwbdztm: '确定修改为闭店状态吗',
    dkddjebljzcdzzs: '抵扣订单金额比例仅支持到正整数',
    bjtg: '编辑团购',
    lmmb: '类目模板',
    sqsz: '申请设置',
    sgzq: '申购周期',
    jzkp: '禁止开票',
    szmzlxdl: '设置每周连续登陆',
    qsrxymc: '请输入协议名称',
    yxs: '一小时',
    ddjhyyqsx: '订单聚合页页签顺序',
    yhkyjxyy: '如配置10:00-11:00；则每日的10:00-11:00用户可以进行预约',
    ddspxx: '订单商品信息',
    tcsz: '停车时长',
    gxjga: '挂学警港澳',
    cyyhsfxz: '参与用户身份限制',
    hysjhgjbyyfjsxczxbygz: '会员升级后根据保有月份计算下次执行保有规则',
    mlrqzdszhf: '每类人群最多设置回复',
    mlrqzdszhf_1: '每类人群回复只能设置1条图文消息内容',
    mlrqzdszhf_3: '每类人群最多设置回复3条内容',
    clgllj: '车辆管理链接',
    nhwcgyqhy: '你还未成功邀请好友',
    xdzl: '下单重量',
    dgyszzj: '导购员所在专柜',
    sjsf: '社交身份',
    qdtgm: '渠道推广码',
    qdyysywxsmsq: '请打印员使用微信扫码授权',
    xzjyzgz: '新增经验值规则',
    tpyj: '图片圆角',
    bdjjtdxfwdmyzqtfbxyyddyt: '不得将集团短信服务代码用作其他非本协议约定的用途',
    qxzygsjzzjg: '请选择一个上级组织机构',
    shjg: '审核结果',
    szmrmymfme: '设置每人每月免费名额',
    mrmtkqgs: '每人每天可抢个数',
    jhzhdtfydbxs: '将会在活动投放页顶部显示，建议尺寸：500px * 500px',
    picLimit500: '图片建议尺寸为500px*500px',
    yxshshdszszsjfts: '允许审核售后单时展示赠送积分提示',
    h_yxshshdszszsjfts_tip:
        '选择“是”则在审核售后单时查询原订单已赠送积分信息（如积分由三方计算需确认对接后勾选）',
    djcksgm: '点击查看申购码',
    tjhydj: '添加会员等级',
    sdfs: '手动发送',
    hydjgzbkwk: '会员等级规则不可为空',
    qxzspbzfl: '请选择商品标准分类',
    jt: '阶梯',
    tjzcsq: '提交注册申请',
    yxyhsqshsjsz: '允许用户申请售后时间设置',
    h_yxyhsqshsjsz_fill: '订单签收#value#天内允许用户在前端申请售后',
    gjpjtjexxxydysgjtdjesx: '保存失败，第{{num}}个奖品阶梯金额下限需要大于上个阶梯的金额上限',
    qly: '券来源',
    hdyxyhq: '获得以下优惠券',
    tb: '同比',
    ybqy: '已被启用',
    lxjgxh: '两项均勾选后',
    mdth: '门店退回',
    yxqcykhzx: '银行全称与开户支行',
    smkp: '扫码开票',
    dkjeqtx: '抵扣金额请填写>0的数字，仅支持两位小数',
    cxyh: '促销优惠',
    djbm: '等级编码',
    nxyh: '男性用户',
    syyhqs: '使用优惠券时',
    bjjcgz: '编辑基础规则',
    xbmhcq: '限量发售，营造紧迫感，促进即时决策',
    tcjfsl: '停车缴费税率',
    h_tcjfsl_placeholder: '填写数字0-100，例税率9%则填写9',
    szxktrsydxgnhttrlydxgnfsdxhszjdxgnzrhbfzrtrd:
        '擅自许可他人使用短信功能或替他人利用短信功能发送短信或擅自将短信功能转让或部分转让他人的',
    dhhm: '电话号码',
    n: '女',
    qbhyfs: '全部会员粉丝',
    xzsp: '选择视频',
    wszzdctsj: '未设置自动成团时间',
    xszdct: '小时自动成团',
    zdctExtra: '举例：开团成功后4小时自动成团，13点开团成功，17点只有团长一人自动成团。',
    http: '话题图片',
    dzmzk: '电子面值卡',
    qdm: '渠道码',
    r_1: '如',
    mflq: '免费领取',
    zdzjcsxz: '最多中奖次数限制',
    ayhwdqd: '限制，按用户维度签到',
    txm: '条形码',
    zfbsmlqsfxy: '支付宝扫码领券身份校验',
    tjyhqlb: '添加优惠券礼包',
    lhjhd: '老虎机活动',
    djh: '待拣货',
    bjfdcg: '变价幅度超过',
    zwjs: '暂无介绍',
    bmtj: '报名条件',
    myhzqcs: '每用户中签次数',
    zcfwxyzxdl: '再次访问需要重新登录',
    jhcs: '拣货超时',
    yhqzt: '优惠券状态',
    zdtb: '置顶图标',
    nljzz: '努力加载中',
    jsqhf: '拒收请回复',
    jszt: '结算状态',
    fzjzzjgzh: '非专柜组织机构账号，无法创建专柜店员',
    qsrmrfqkjcs: '请输入每人发起砍价次数',
    bfzjcymdyfgzjs: '部分专柜参与门店运费规则计算，部分专柜独立计算运费规则',
    zjcd: '增加菜单',
    jzz: '加载中',
    qxzcjfs: '请选择创建方式',
    jffs: '计费方式',
    ty_1: '团员',
    qxbjmb: '请先编辑模板',
    jyzsxx: '经验值上下限',
    ztddgshzdfh: '自提订单过审后自动发货',
    wz_2: '位置',
    thdsjd: '同活动时间段',
    qxzdq: '请选择地区',
    dqyjysxdwxhykmb: '当前已经有生效的微信会员卡模板，是否确定覆盖已生效的微信会员卡模板？',
    dhj: '兑换价',
    gx: '关系',
    pldf: '评论得分',

    qsrjlbm: '请输入记录编码',
    qxzxy: '请选择协议',
    cszscjmsl: '初始赠送抽奖码数量',
    sqjmc: '授权集名称',
    cjhdsz: '抽奖活动设置',
    qshzcsxz: '请稍后再尝试下载',
    hypj: '会员评价',
    qwxcdrhwyzr: '且无需承担任何违约责任',
    khyhl: '开盒赢好礼',
    sqlx: '申请类型',
    zdypsfw: '自定义配送范围',
    gzck: '个子窗口',
    dgjyzdyhydqjyzs: '当该经验值大于会员当前经验值时，将置会员经验值为0',
    xxxyh: '线下新用户',
    tzghj: '团长供货价',
    gtcqsyhjmdcjftcsz: '该停车券使用后减免当次缴费停车时长',
    cgshje: '成功售后金额',
    addSuccess: '添加成功',
    wdyy: '我的预约',
    dmddzjzs: '单门店多专柜展示',
    lpxx: '礼品信息',
    qwsxwtjxx: '请完善行为条件信息',
    gxhzysgcgfs: '勾选后只有申购成功发送',
    hwx: '含微信',
    xzcc: '限制场次',
    prepageCouponCode: '预生成，导出券code模式',
    onlineModeExtra:
        '系统随机生成券code：由系统随机生成券code为用户绑券；导出券code：可在列表页导出券code，放到三方平台发放券，用户凭券code来小程序上进行绑券',
    crmCoupon: '线下制券 (如CRM制券)',
    hyxghd: '会员相关活动',
    sb_2: '失败',
    cyrq: '参与日期',
    dx_1: '多选',
    dgymc: '导购员名称',
    bjzt: '笔记状态',
    jllx: '奖励类型',
    tjhbjewmymbzsmdxx: '添加和编辑二维码页面不展示门店信息',
    xzysb: '选择压缩包',
    dchxjl: '导出核销记录',
    gzhhy: '该账号还有',
    dhzdjyxy: '动画长度建议小于',
    djwlgsssx: '默认设置（对接物流公司时生效）',
    xtzxcqsj: '系统执行抽签时间',
    ckbl: '查看变量',
    zcsjmhqyhcqtxdzcxx: '注册时静默获取用户此前填写的注册信息',
    hdtgdgxylgj: '拉新、活动推广的高效引流工具',
    wj: '文件',
    jlygddtsdmcyz: '尽量与高德地图上的名称一致',
    bkjltst: '帮砍奖励提示图',
    descriptionValue:
        '<p>1.活动期间消费者邀请好友帮忙砍价，邀请{帮砍人数}名好友砍到底价￥{商品底价}后，即可按￥{商品底价}购买商品；<//p><p> 2.好友帮忙砍到最低价￥{商品底价}后，消费者须在有效期内购买砍价商品，逾期商品将恢复原价；<//p><p>3.同一商品，同一用户仅可享受{发起次数}次优惠价格；<//p><p> 4.同一商品，同一用户仅能帮好友砍价1次；<//p><p> 5.砍价商品数量有限。商品售罄后，商品将无法购买，即使你已经发起砍价；<//p><p> 6.若系统判断用户为恶意刷单，针对已下单的商品有权不予发货；对于刷单金额较大或行为较为恶劣者，该平台有权追究用户的法律责任。<img src="https:b-puzhehei-cdn.co-mall.net//global//market//bargain//ruler.png" width="100%"//><//p>',
    bargainRewardPictureExtra: '建议尺寸：宽520px, 高660px。',
    qszccrq: '请设置场次日期',
    xzddjsms: '选择订单件数满时',
    dy_1: '大于',
    hbyhd: '红包雨活动',
    zdyhq: '指定优惠券',
    yysj_1: '运营数据',
    cjqdm: '创建渠道码',
    wszyxyhsqshsj: '未设置允许用户申请售后时间',
    zfjl: '支付奖励',
    czje: '操作金额',
    zdycyxxsz: '自定义参与信息设置',
    zhgl: '租户管理',
    zzant: '指针按钮图',
    yhdzjf: '用户的总积分',
    dqkcjyz: '到期扣除经验值',
    xzzdyrq: '新增自定义人群',
    zzsdy: '正则式定义',
    nbxyxzygkpsqy: '您必须要选择一个可配送区域',
    cktccmcxfhsztdcdsx: '查看停车场名称下方灰色字体的场地属性',
    jbmc: '金币名称',
    ejfklxbnwk: '二级反馈类型不能为空',
    xzmqhd: '新增卖券活动',
    pointPictureRule: '图片尺寸建议为200px * 200px',
    fwjlsgjdrdbxfjezjddnjjttjjffdytjdjl:
        '范围奖励是根据当日多笔消费金额总计达到哪级阶梯条件即发放对应条件的奖励。',
    gxsj: '更新数据',
    hdj: '活动价',
    rwxyyhgxx: '如微信有用户该信息',
    zssy: '最少使用',
    zdsy: '最多使用',
    fsmd: '发生门店',
    qtqdjjsskytygxdjdyhqyqsy:
        '其他券叠加结算时可与同样勾选叠加的优惠券一起使用；自身可叠加仅可以本规则券一起使用，自身券叠数量可编辑。',
    sjhyxx: '收集会员信息，提升会员忠诚度',
    pldy: '批量打印',
    tgqdfwyhpx: '推广渠道访问用户排行',
    qszngjzk: '请设置内购价折扣',
    qsrshyy: '请输入售后原因',
    qsrkpr: '请输入开票人',
    zcaqclzskqygaqclyz: '注册安全策略至少开启一个安全策略因子',
    yqhyjl: '邀请好友记录',
    dzxy: '电子协议',
    openBiDatart: '点击打开“数据罗盘”标签页',
    qlxkfcl: '请联系客服处理',
    kjsjbnzyhdjssj: '开奖时间不能早于活动结束时间',
    yhzdlqdwysfbs: '用户在登录渠道唯一身份标识',
    wb: '外部',
    ljsmrs: '累计扫码人数',
    cjlh: '次奖励后',
    wzhjsxjbzcnhbz: '完整和及时性均不作承诺和保证',
    wlsjmdbm: '物流商家门店编码',
    yhtgsqdtgm: '用户通过扫渠道推广码/公众号二维码完成',
    tkjf: '退款积分',
    sjmjycg: '商家码禁用成功',
    hdbmd: '活动白名单',
    wzh: '位账号',
    wpztymjj: '未配置团员买家价',
    plsp: '评论商品',
    btbnwk: '标题不能为空',
    xtzdjtzjslr: '社区团购活动结束后，系统自动给团长结算利润（利润',
    xfje: '消费金额',
    xfjey: '消费金额(元)',
    twyysjcd: '图文页右上角菜单',
    rzhszlqzkk: '如租户设置了强制开卡',
    plxzmd: '批量下载门店',
    sppx: '商品排序',
    shtgrq: '创建//审核通过日期',
    bgx_1: '不勾选',
    dfhddyybb: '待发货订单语音播报',
    ks: '开始',
    ysx: '已失效',
    bddyy: '绑定打印员',
    rszw_1: '如设置为',
    bcxg: '保存修改',
    cckjh: '次抽卡机会',
    gh: '归还',
    srspmc: '输入商品名称',
    zfhsjjyztsjjhsy: '则发货时间将于自提时间结合使用',
    qsrspssflbm: '请输入商品税收分类编码',
    tz: '团长',
    g_1: '高',
    tjjs: '添加角色',
    qsrhyyhwa: '请输入会员优惠文案',
    dyaccbmdhd: '对于按场次报名的活动',
    qzsxzyzzjlx: '请至少选择一种专柜类型',
    lsmyxq: '临时码有效期',
    zjshspjezb: '专柜售后商品金额占比',
    zdybmxx: '自定义报名信息',
    dxx: '单选项',
    bgsxcs: '表格筛选参数',
    mrhd: '每人获得',
    cp: '产品',
    gwzx: '购物中心',
    sfjbdyy: '是否解绑打印员',
    jysbb: '及以上版本',
    gk_1: '隔开',
    rbmzzts: '如不满足则提示',
    ljsj: '领奖时间',
    zjjlbjt: '中奖记录背景图',
    yeqmx: '余额券明细',
    bcg10gzcghbjq: '不超过10个字，超过会被截取',
    cghbjq: '超过会被截取',
    cdz: '次点赞',
    dcyhqsj: '导出优惠券数据',
    qzz: '权重值',
    bjdgyxx: '编辑导购员信息',
    zdymc: '自定义名称',
    lqhdyhqmc: '领取后的优惠券名称',
    sjfw: '时间范围',
    sphzb: '视频号直播',
    szcd: '设置菜单',
    hdhdgccbxczzsygqydpd: '活动或单个场次必须存在至少一个启用的票档',
    bmjssjbt: '报名结束时间必填',
    qwsxggz: '请完善限购规则',
    yxjghdfxjhy: '允许将该活动分享给好友',
    jtgyhqzjsfklq:
        '不配置，则不限券领取时间，若配置则控制券在商品详情页是否展示，及通过优惠券组件是否可领券',
    tjbmd: '添加白名单',
    szdzxzbndyzdz: '设置的最小值不能大于最大值',
    jpsz: '奖品设置',
    zzsyhsjdjfz: '则赠送用户随机的积分值',
    qxzfhdd: '请选择发货订单',
    zyhmcbxsy: '则用户每次必须使用',
    mhccl: '如：盲盒抽抽乐，不超过20字',
    sjjgzsm: '升降级规则说明',
    qsrmdcid: '请输入门店CID',
    jqsrcrmid: '请输入CRMID',
    qqsb: '请求失败',
    gjtzgxfxydyzdxfdje: '保存失败，第{{index}}个阶梯最高消费需要大于最低消费的金额',
    llxw: '流量行为',
    jb_1: '禁播',
    qtxxzje: '请填写限制金额',
    kqgbanzt: '开启关闭按钮状态',
    qbcyyhdj: '全部参与用户等级',
    yhqhxcg: '优惠券核销成功',
    cgpmx: '常规品明细',
    chzs: '才会展示',
    hdzj: '活动专柜',
    wdyhqzssj: '我的优惠券展示数据',
    yfh: '已复核',
    z_6: '字',
    hpdc: '货品弹层',
    mhbz: '盲盒包装',
    zddj: '指定等级',
    qapzsjfh: '请按配置时间发货',
    qtxyhewkhddcjcs: '请填写用户额外可获得的抽奖次数',
    zdcg: '置顶成功',
    qsrxzkpyxqts: '请输入限制开票有效期天数，仅支持输入正整数，最小可设置1天，最大可设置9999天',
    sfgh: '是否归还',
    dhk: '待汇款',
    zhfgzqddrdmd: '则会覆盖之前的导入的门店',
    schddd: '商城活动订单',
    zhczqhxcskhzxl: '账户储值券，唤醒沉睡用户，增销量',
    ny: '您有',
    sctp: '上传图片',
    qxxzygglgzhdmd: '请先选择一个关联公众号的门店',
    qsryhnc: '请输入用户昵称',
    qsrsjlx: '请输入事件类型',
    qyxq: '权益详情',
    xxsy: '线下使用',
    qtxtgqd: '请填写推广渠道',
    xzcyhewkhdcjcs: '新注册用户额外可获得抽奖次数',
    zjccxx: '增加场次信息',
    bgmd: '变更门店',
    qxjd: '取消叫单',
    qkhd: '清空活动',
    fzyhwfsdgqtx: '否则用户无法收到过期提醒',
    qysyzxydl: '启用双因子校验登录',
    cshdcjcs: '初始获得抽奖次数',
    qtxpsjljtj: '请填写配送距离阶梯价，且必须大于0',
    gldj: '关联等级',
    kqcg_1: '开启成功',
    jscclxtshdqhzxjs:
        '每个连签奖励只可被领取一次，即使超出连续天数或断签后重新计算，已获得奖励也将不会再次发放',
    ktcgh: '开团成功后',
    ktcghzdct: '开团成功后#value#人，自动成团',
    gzfyn: '个字符以内',
    gjtzdxfxydysgjtdzgxf: '保存失败，第{{index}}个阶梯最低消费需要大于上个阶梯的最高消费',
    ppsjzkzt: '品牌数据展开状态',
    bm_1: '编码',
    bjymhc: '编辑页面回传',
    zdjssj: '置顶结束时间',
    fd: '放大',
    sh_1: '税号',
    dryh: '导入用户',
    qtxzcwa: '请填写资产文案',
    js_2: '机审',
    bdyy: '变动原因',
    hykhddz: '会员卡回调地址',
    qaz: '请按照',
    yjb: '已解绑',
    yysbzjsydkpzt: '用于识别专柜使用的开票主体',
    xchd: '宣传活动',
    myd: '满意度',
    j_5: '家',
    tzsz: '跳转设置',
    k: '宽',
    rxggxkcs: '如修改共享库存数',
    qsrcjqh: '请输入抽奖券号',
    kjddcw: '跨境订单除外',
    jhwcsb: '拣货完成失败',
    yc: '移除',
    cxdsd: '程序等手段',
    jnzh: '境内账户',
    qsrlpqmc: '请输入礼品券名称',
    giftCouponRule: '请输入礼品券名称，不超过30个字',
    bjplsz: '笔记评论设置',
    yx_1: '以下',
    kzyeqewmzsrk: '控制余额券二维码展示入口',
    sfscgtxx: '是否删除该条选项',
    gqtxxykqxcxdyxxgn: '过期提醒需要开启小程序订阅消息功能',
    zzsc: '作者删除',
    mrjrdym: '默认进入的页面',
    hdqnyqhyzl: '活动期内邀请好友助力',
    yfz: '已封账',
    tqfhbj: '退券返还本金',
    qsryyrxm: '请输入预约人姓名',
    anbj: '按钮背景',
    grxgs: '个人限购数',
    stk: '实体卡',
    ttx: '天提醒',
    hxdy: '核销店员',
    pd: '票档',
    yyxd: '预约下单',
    sqbqd: '社群变渠道，轻松赚大钱',
    d_3: '对',
    qxjxcxygzhgl: '请先将小程序与公众号关联',
    mbldy: '目标落地页',
    yhqlbsmsqtxftj: '优惠券礼包售卖时请填写分摊价',
    zjxctmmw: '专柜宣传图命名为',
    kjfp: '开具发票',
    qsranwldkhbm: '请输入安能物流的客户编码',
    zctccjksy: '支持停车场均可使用',
    wyhbq: '为用户绑券',
    mqxxjzdzzjf: '目前线下仅针对自助积分',
    xxcx: '信息查询',
    pldcmd: '批量导出门店',
    yshbtg: '因审核不通过',
    hxk: '获新客、促活跃、提复购',
    cqzs: '产权证书',
    h_cqzs_bdcqzh: '产权证书//不动产权证号',
    hykxqyzzsfpl: '会员卡详情页中展示发票栏',
    hkzxd: '还可执行单',
    sfzcdgdxgrzrq: '是否支持导购端修改入账日期',
    hdddmc: '活动地点名称',
    qdyl: '签到有礼',
    glgzhbdgzhxx: '管理公众号绑定公众号信息',
    authorId: '作者ID',
    tyhd: '同一活动',
    drlj: '当日累计',
    sfxsmrz: '是否显示默认值',
    dckyycg: '的才可预约成功',
    qxdd: '取消订单',
    whyzlhdcjmsxbnwk: '为好友助力获得抽奖码上限不能为空，并且仅支持到正整数',
    qzsxzygzj: '请至少选择一个专柜',
    sfzyxqjsrq: '身份证有效期结束日期',
    hzzt: '合作状态',
    jbcsbgsxydxdysydgs: '保存失败，{{rewardStartText}}个数下一档需大于上一档个数',
    tjtwdc: '添加图文弹层',
    qynghddd: '企业内购活动订单',
    bqmczf: '标签名称重复',
    sfyhqze: '三方优惠券总额',
    rbpzhykdjjg: '若不配置会员卡等级价格',
    l_rbpzhykdjjg:
        '价格：若不配置会员卡等级价格，则所有用户都以普通兑换价购买；若配置了等级，则对应等级的会员以会员价进行购买',
    cktp: '查看图片',
    tzl: '通知栏',
    ljxfxydxdysydje: '保存失败，累计消费下一档需大于上一档金额',
    ymxxdfrkhfnzcjy: '移民信息等非认可或非您正常交易',
    ccyybdds: '此处用于本地调试',
    ftgjfzhfsdxxjswjfdxw: '凡通过甲方账户发送的信息均视为甲方的行为',
    dxtz: '短信通知',
    gzxghyhzxjrxcxh: '规则修改后用户重新进入小程序后',
    jtz: '阶梯值',
    szmdgx: '设置门店关系',
    szzh: '数字组合',
    qxgzrs: '取消关注人数',
    dkddjebl: '抵扣订单金额比例',
    qsrklbbm: '请输入卡类别编码，不超过20个字',
    klb: '卡类别',
    jbdjf: '金币兑积分',
    kzzj: '可在专柜',
    mhyx: '盲盒营销',
    xdr: '下单人',
    glsjh: '管理手机号',
    fzkb: '辅助空白',
    zdkj: '自动开奖',
    mqjzcxxddkzdlq: '目前仅支持线下订单可主动领取，线上订单仍由系统自动发放',
    jlsj: '奖励数据',
    ewmhtxm: '二维码和条形码',
    bgzt: '变更状态',
    xzmlx: '新增目录项',
    qpzgqts: '请配置过期天数',
    qqybzz: '请求已被终止',
    sjhdl: '手机号登录',
    zcmd: '注册门店',
    wszktsj: '未设置开团时间',
    xthykdjqtxbtxfjegz: '相同会员卡等级请填写不同消费金额规则',
    cjrq: '创建日期',
    cjshr: '创建//审核人',
    bjnrdc: '编辑内容弹窗',
    ptbk: '普通板块',
    lwtp: '礼物图片',
    ctdz: '餐厅订座',
    kqwxhyktj: '开启微信会员卡条件',
    ygrs: '预估人数',
    hdmchzszqdhdlb: '活动名称会展示在前端活动列表，最好是当前活动的商品主题，例如：水果盛宴',
    dpsfsw: '当配送方式为',
    gxsj_1: '更新时间',
    kfgbddyy: '客服关闭订单原因',
    zkshzx: '在可视化装修',
    xzxpt: '下载小票图',
    yezf: '余额支付',
    cdtb: '菜单图标',
    yndsz: '以内的数字',
    spyj: '商品原价',
    tzsx: '拓展属性',
    wzlm: '文章栏目',
    gx_1: '勾选',
    zfhgz: '支付后关注',
    kddhzczdsr: '快递单号支持最大输入',
    jzqzc: '及之前注册',
    mr_1: '每人',
    bky: '不可用',
    tcc: '停车场',
    thdyxjldsz: '天获得以下奖励的数值',
    tjbjtpdc: '添加编辑图片弹层',
    xl: '销量',
    jfrtgyfrjxyhtgygzxnr: '甲方如通过乙方软件向用户提供有关资讯内容',
    dytcyc: '打印退出异常',
    qyx: '权益项',
    zcsr: '支持输入',
    l_zcsr_1: '支持输入>0的数字，支持两位小数',
    jkzs: '集卡赠送',
    jx: '继续',
    rnyxlqxzyd: '如您有下列情形之一的',
    bmyjs: '报名已结束',
    qxzzjdlym: '请选择专柜导览页面',
    wzdrhmdqx: '未找到任何门店权限',
    tjcg_1: '提交成功',
    glsptmjh: '关联商品条码集合',
    qpzztsj: '请配置自提时间',
    hfs: '和负数',
    hdksh: '活动开始后',
    wpzzbnfsxx: '未配置则不能发送消息',
    txhznzhkcxyzs: '填写后在年终回馈查询页展示',
    xtsjsc: '系统随机生成',
    zcljtsy: '注册拦截提示语',
    dcjl: '单次奖励',
    tgzj: '推广专柜',
    hdqjljxf: '活动期间累计消费',
    hdqjljxffhmk: '活动期间累计消费，在活动期间内额度一直累加计算是否符合消费门槛',
    xzys_1: '新增预售',
    scjr: '首次进入',
    mf: '每份',
    mgyhmtgdzs: '每个用户每天固定展示',
    ssk: '搜索框',
    tksl: '退款数量',
    yhycpdbdgx: '用户与车牌的绑定关系',
    zfhff: '支付后发放',
    qsrsfsydmm: '请输入顺丰速运的密码（没有可不填）',
    hyjfpz: '会员积分配置',
    yxyz: '邮箱验证',
    rzfzswygsp: '若重复只视为一个商品',
    bjlbjl: '编辑裂变激励',
    yyzt: '预约状态',
    fkmzsfs: '付款码展示方式',
    ysfz: '延时分账',
    dxsrk_1: '多行输入框',
    qxzkdj: '请选择卡等级',
    rtxfsdxdsjhqqr: ' 如填写发送对象的手机号，请确认该手机号在"',
    dcxfmm: '单次消费每满xx元',
    qschdbjtp: '请上传活动背景图片',
    dqfs: '对齐方式',
    qxzmyklb: '请选择某一卡类别',
    kjhd: '砍价活动',
    ffcs: '发放次数',
    hykbjt: '会员卡背景图',
    dhzrs: '兑换总人数',
    jlsz: '奖励设置',
    yqy: '已签约',
    sccccrkqjz: '上传车场出入口全景照',
    hykmb: '会员卡模板',
    szhdgg: '设置活动公告',
    tpccjywk: '图片尺寸建议为宽30px*30px',
    jytxgsrmtz: '建议填写格式如每天早',
    xzfw: '新增服务',
    yhszpbjsxxhzqxgz: '用户设置屏蔽接受消息或者取消关注',
    wdzqy: '我的中签页',
    ftj: '分摊价',
    qxszsmjgzszftj: '请先设置售卖价格再设置分摊价',
    pctdspfl: '排除特定商品分类',
    czrq: '操作日期',
    qszkjfpsfljmb: '请设置开具发票三方链接目标',
    qsrdhzdkc: '请输入兑换最大库存',
    jppx: '奖品排序',
    tjl: '条记录',
    smsz: '售卖设置',
    byxjgspfxjhy: '不允许将该商品分享给好友',
    ndjyxz: '年度交易限值',
    cd: '产地',
    xzym: '新增页面',
    fxylxg: '分享预览效果',
    bzzckqlsmb: '不再支持开启历史模板',
    xnzhsp: '虚拟组合商品',
    jytxhyqyjfwxgms: '展示在会员卡详情内，最长500个中文字符，建议填写会员权益及服务相关描述',
    xzgzhewm: '新增公众号二维码',
    hdcytx: '活动参与提醒',
    rqhypldrsbqd: '人群会员批量导入失败清单',
    ndllqbzc: '您的浏览器不支持WebSocket',
    qzcqrxmm: '请再次确认新密码!',
    tn: '天内',
    hxzjmc: '核销专柜名称',
    ghsj: '归还时间',
    qsrwjmc: '请输入文件名称',
    tzxfqsfyz: '天重新发起身份验证',
    qsrbjpldf: '请输入笔记评论得分',
    xytspzsyxz: '需要同时配置使用须知',
    gbdjjdyyhyzcyx: '关闭等级将对已有会员造成影响',
    qtxkdhdzdkc: '请填写可兑换的最大库存',
    yfh_1: '已发货',
    jzcdc: '仅支持导出',
    jhz: '拣货中',
    qr: '嵌入',
    rggjss: '如果高级搜索',
    bnps: '不能配送',
    dzyc: '到账异常',
    ppxdxzm: '匹配小大写字母',
    tpjycc: '图片建议尺寸',
    ryhgm: '如用户购买',
    ydsj: '运单时间',
    rgxzwsjflzdxpphcddqc: '如果选中为三级分类则底下品牌和产地都清除',
    smyhqddewm: '售卖优惠券订单二维码',
    h_wdyhj_smyhqddewm: '我的优惠券、售卖优惠券订单二维码',
    xtwccqhfsyxxx: '系统完成抽签后发送以下消息',
    qszxxzfjylsh: '请设置线下支付交易流水号',
    wheelPictureExtra: '图片尺寸建议为570px * 570px，',
    rhhq: '如何获取',
    zbsyhmyzsxf: '如填写3次，则表示用户每月至少消费>=3次才可参与活动',
    zpt: '转盘图',
    qsrytsddkhmy: '请输入圆通速递的客户密钥',
    tppz: '图片配置',
    ddgyxx: '订单概要信息',
    hqjl: '获取几率',
    tgzbdh: '通过直播带货，增加品牌和商品曝光',
    plxgptptj: '批量修改普通拼团价',
    xfmd: '消费门店',
    zjyxfl: '专柜营销分类',
    jfscsp: '积分商城商品',
    wlj: '无链接',
    hyqy: '会员权益',
    kpfs: '开票方式',
    ckjh: '查看计划',
    c_1: '川',
    hkje: '汇款金额（元）',
    wzdl: '我知道了',
    hf: '回复',
    hdfqr: '活动发起人',
    zljqdcs: '总累计签到次数',
    bzflhwpzssflbm: '标准分类还未配置税收分类编码',
    qsrmyhkzjsl: '请输入每用户可中奖数量',
    hxmdmc: '核销门店名称',
    yysjh: '预约手机号',
    zqrq: '中签日期',
    thwth: '天后未提货，订单自动关闭，自动退款',
    yhqze: '优惠券总额',
    qsrdj: '请输入底价',
    scbj: '删除笔记',
    mmqdyb: '密码强度一般',
    dcdjqs: '弹窗点击确实',
    qbzjcy: '全部专柜参与',
    wpz: '未配置',
    qdtjzcd: '确定添加子菜单',
    spsszjbzfl: '商品所属子级标准分类',
    ycrsjh: '用餐人手机号',
    s_4: '市',
    ljdzfzsb: '路径地址复制失败',
    qzxdr: '请重新导入',
    mrsczskp: '每日首次赠送卡片',
    qsryx: '请输入邮箱',
    bf: '部分',
    tx_1: '头像',
    qsrtcjfsl: '请输入停车缴费税率，仅支持填写0-100',
    ggzybkqcg: '该规则已被开启成功',
    zfwcs: '周访问次数',
    rhdsxmdwdmd: '若活动生效门店为多门店，请保证多个门店下卡等级一致，若不一致会有用户领取不到奖励',
    txh: '填写后',
    gy_1: '个月',
    ktdycspdzpx: '可拖动右侧商品调整排序',
    dlyysxyys: '登录页隐私协议样式',
    bfhdpcchd: '部分活动排斥此活动',
    mmjl: '每满奖励',
    xzhykdj: '选择会员卡等级',
    gkpdhx: '个卡片导航项',
    ljlx: '链接类型',
    qsrzhms: '请输入租户描述',
    fpttmc: '发票抬头名称',
    zjdbsj: '最近打标时间',
    hfkssj: '回复开始时间',
    wszzdctrs: '未设置自动成团人数',
    qsrstkddzhmm: '请输入申通快递的账户密码',
    bl: '比例',
    kfyqshan: '开放延期收货按钮',
    ftjjzcdxsdhlw: '分摊价仅支持到小数点后两位',
    jyh: '计优惠',
    sfyxdzjg: '是否允许调整价格',
    yhzzndgmdbm: '用户在组内多个门店报名',
    mrscdlhd: '每日首次登录活动,',
    getNumeChance: '可获得{{count}}次集卡机会',
    mrscshykp: '每日首次送好友卡片',
    sccg: '删除成功',
    zxtj: '重新提交',
    cc_1: '橱窗',
    ls: '临时',
    qtjjlpz: '请添加奖励配置',
    qsryyjg: '请输入预约价格',
    smsrjhdh: '扫码输入拣货单号/订单号',
    ymm: '页面码',
    bjsdshzj: '编辑手动审核专柜',
    yhzqdkllwxzrhcz: '用户在前端可浏览无需做任何操作',
    cs_1: '次数',
    xzhyzk: '新增会员折扣',
    khbm: '客户编码',
    ztxz: '专题选择',
    swbxzdl: '视为不限制单量',
    ddsh: '等待审核',
    hym: '会员码',
    drscsp: '导入删除商品',
    qsrqxsj: '请输入取消时间',
    szzdzcsz: '数字最大支持设置',
    n_1: '年',
    jgcgdd: '价格从高到低',
    wgbjblbzjsyscdylxd: '为改变将保留并直接使用上次打印留下的',
    ddbb: '订单播报',
    dssj: '定时时间',
    jbxtzddcjzwwdlzt: '将被系统自动登出即置为未登录状态',
    jszhspsjhdsjchsm:
        '设置后，商品上架后到时间才会售卖；商品开售前5分钟给用户发送提醒，需先开启订阅消息',
    zr: '周日',
    jbgzsz: '基本规则设置',
    dbxfm: '单笔消费满',
    rpzfhsj: '如配置发货时间',
    dqzt: '当前状态',
    qtp: '券图片',
    qsrcdmc: '请输入菜单名称',
    zspxqybzspjqy: '在商品详情页不展示评价区域',
    zdsp: '指定商品',
    bjzjm: '编辑专柜码',
    ghdzhdlb: '该活动在活动列表、活动日历不展示',
    chq: '传回去',
    wdbj: '我的笔记',
    wkj: '未开奖',
    stkbm: '实体卡别名',
    zqsz: '周期设置',
    lsgmgyxzjdsp: '历史购买过以下专柜的商品',
    yzsz: '隐藏设置',
    qbyxfl: '全部已选分类',
    wjsp: '未拣商品',
    kfbz: '客服备注',
    jtj: '阶梯价',
    qtxxfzk: '请填写消费折扣',
    qscggtp: '请上传广告图片',
    xdrhykdj: '下单人会员卡等级',
    xzht_1: '选择话题',
    bmxx: '报名信息',
    gwjfjbgz: '购物积分加倍规则',
    yjjssj: '佣金结算时间',
    tzlj: '跳转链接',
    bq_1: '抱歉',
    mrqdgdjl: '每日签到固定奖励',
    qxfpmdqx: '请先分配门店权限',
    hdksq: '活动开始前',
    yxpbh: '原小票编号',
    qsrxcxwz: '请输入小程序网址',
    dlj: '短链接',
    tksb: '退款失败',
    yhxyxbmhcyhd: '用户需要先报名后参与活动',
    xzddjems: '选择订单金额满时',
    hdzbmme: '活动总报名名额',
    djxz: '点击下载',
    sfyzsjqsrzzs: '身份验证时间请输入正整数',
    yhtx: '用户头像',
    azqxg: '按周期限购',
    zrgs: '责任归属',
    jydycc: '禁用对应场次',
    jpslbnxyyffsl: '奖品数量不能小于已发放数量',
    xzrq_2: '选择人群',
    gd_1: '高度',
    principalSetting: '设置本金',
    syffsl: '剩余发放数量',
    sdphm: '发票号码//数电票号码',
    cr: '次日',
    zffsktsq: '支付方式开通授权',
    bmhd: '报名活动',
    xtzt: '系统状态',
    cjhd: '抽奖活动',
    tbcg: '同步成功',
    yfcn: '乙方承诺',
    kfscbz: '客服删除备注',
    hdrlzbkj: '活动日历中不可见',
    hxhd: '核销活动',
    dmjg: '大码价格',
    yb_1: '已绑',
    cksycxywgn: '才可使用此项业务功能',
    fqmd: '发券门店',
    qsrymbt: '请输入页面标题',
    hykdjgz: '会员卡等级规则',
    gzqdly: '关注渠道来源',
    txszyyxzmgyhffjldsx: '当需要限制时，填写数值用于限制每个用户发放奖励的上限',
    hdgztb: '活动规则图标',
    zhmm: '账户密码',
    jfscdhq: '积分商城兑换券',
    qxzzdrq: '请选择针对人群',
    zwb: '主文本',
    zt: '自提',
    zc_1: '正常',
    hyxg: '会员相关',
    q_4: '强',
    sjyxlx: '事件营销类型',
    ysq: '已授权',
    qxzyzcbjjfs: '线下pos使用优惠券时，请选择一种成本计价方式',
    ydzt: '预订状态',
    psjg: '配送价格',
    gzyqy: '规则已启用',
    yx_2: '有效',
    zdhy: '指定会员',
    qxgzsj: '最近取消关注时间',
    smsj: '售卖时间',
    gqqccxx: '改签前场次信息',
    jzclrdy0dje: '仅支持录入大于0的金额，支持两位小数',
    dyfzffzblxy: '第一分账方分账比例小于100%，请完善第二分账方信息',
    yeqdk: '余额券抵扣',
    qzcsrxmm: '请再次输入新密码',
    fhhzdqrshsjsz: '发货后自动确认收货时间设置',
    bjplrk: '笔记评论入口',
    h_bjplrk_tip_before: '对接三方审核系统后，展示评论入口后用户才可发布评论。',
    gkr: '购卡人',
    mgjltjbnzd: '每个奖励条件不能重叠，且需要递增',
    yc_1: '异常',
    gdgj: '更多工具',
    sj_1: '售价',
    zhdqjnmthydsyhzq: '在活动期间内每天会有多少用户中签',
    mfbm: '免费报名',
    rqbkzyhdkssj: '日期不可早于活动开始时间',
    sqzffsmc: '授权支付方式名称',
    jtsz: '阶梯设置',
    ckhjh: '转给8名好友，可获得3次开盒机会',
    sfqyjs: '是否启用角色',
    pldsxjcg: '批量定时下架成功',
    wjysmb: '文件样式模版',
    dkxddgkxsfw: '代客下单导购可销售范围',
    dgzdyyf: '导购自定义运费',
    ycxybzjpcz: '因此需要保证奖品充足',
    fplx: '发票类型',
    kd_1: '开店',
    xfe: '消费额',
    fsdx: '发送短信',
    qsrewmmc: '请输入二维码名称',
    qgzzt: '券规则状态',
    dhxz: '兑换限制',
    qsrzhmc: '请输入租户名称',
    ywkdbhp: '已无可打包货品',
    zdsj: '置顶时间',
    kqz: '开启中',
    zdrqyzbkj: '指定人群隐藏不可见',
    lhjjs: '老虎机机身',
    soltMachinePictureExtra: '图片尺寸建议为750px * 1044px',
    xnhd: '虚拟号段',
    zspxqyzswpcdt: '在商品详情页展示未拼成的团，用户点击可参与拼团',
    zcxcxdlmbk: '支持小程序的类目包括',
    gspggw: '个商品广告位',
    jzjsp: '家专柜商品',
    bgje: '变更金额',
    fp: '发票',
    psgsdyjzh: '配送公司的月结账户',
    lxyqcgdmyhhdbtjtjl: '连续邀请成功多名用户获得不同阶梯奖励',
    dzk: '电子卡',
    bzbkrsme: '不占帮砍人数名额',
    tjbjxkpdc: '添加编辑小卡片弹层',
    bjhzqy: '编辑合作企业',
    fznznjfsfs: '分钟内只能给粉丝发送',
    smly: '扫码来源',
    qbdj: '全部等级',
    wzphcx: '伪装破坏程序',
    yr: '月日',
    chzdjcz: '次后做冻结操作',
    xkzwzptcgzfgdddyh: '新客指未在平台成功支付过订单的用户(不包含积分商城、商城活动订单)',
    schtdlcsxz: '商城后台登录次数限制',
    zhzygmdjxsg: '只会在一个门店进行申购',
    dhmd: '兑换门店',
    fzfshh: '分账方商户号',
    qxzschdy: '请选择商城活动页',
    zddh: '最多兑换',
    mxzdtj: '每行最多添加',
    xzkp: '新增卡片',
    rlydxgnfbbfxxd: '如利用短信功能发布不发信息等',
    wbdbq: '未绑定标签',
    szkc: '设置库存',
    scdlyhszl: '首次登录用户数总量',
    hdqjmyhxgsl: '活动期间每用户限购数量',
    cg: '草稿',
    kdj: '客单价',
    jytxrqnsdztdxhddzte: '请填写提示信息，建议填写如请您收到自提短信后到店自提哦～～',
    sltp: '示例图片',
    fqrs_1: '发起人数',
    ysmc: '颜色名称',
    zdmd: '指定门店',
    fqyg: '发券员工',
    psgz: '配送规则',
    lmshspspx: '类目售后商品数排行',
    djdran: '点击导入按钮',
    xycq: '需要抽签',
    khmy: '客户密钥',
    bhzcqq: '不会再次请求',
    ywc: '已完成',
    ffsz: '发放设置',
    qsrkddh: '请输入快递单号',
    szsmyhqgzs: '设置售卖优惠券规则时',
    jx_1: '仅限',
    kpr: '开票人',
    ccjy: '尺寸建议',
    wlzh: '物流账号',
    wxts: '温馨提示',
    yyqgdd: '预约抢购订单',
    skfp: '税控发票',
    hdsj_1: '互动数据',
    qjxfe: '期间消费额',
    ysdd: '预售订单',
    qsrsj: '请输入时间',
    z_7: '总',
    hylb: '好友裂变',
    fhsb: '发货失败',
    bcdsj: '保存的数据',
    yhxx: '用户信息',
    gksl: '购卡数量',
    spssfjbzfl: '商品所属父级标准分类',
    dcmdjg: '导出门店结果',
    qzqtxjmje: '请正确填写减免金额',
    hyzt: '会员状态',
    hdydjsrq: '活动已到结束日期',
    jhwccg: '拣货完成成功',
    zsyyyydyhjkqg:
        '不配置，则不限制抢购人数。注意：如已预约用户数<抢购用户数，则所有已预约的用户均可抢购',
    cwzz: '财务转账',
    qsbmjsrq: '缺少报名结束日期',
    dqtx: '当前头像',
    xzsq: '新增商圈',
    szkdsl: '设置可兑数量',
    rx: '任选',
    xsbh: '小时备货',
    qsryyjf: '请输入预约积分',
    xcxxtdmb: '小程序相同的模板',
    qdqksyjghmymfmem: '确定清空所有价格和每月免费名额么？',
    kcxxzdddzcjd: '可查询新增地点的注册进度',
    jzrq: '截止日期',
    fhsl: '发货数量',
    sjjpdbz: '升降级判断标准',
    spdj: '商品底价',
    mrxdslx: '每日限兑数量需',
    htzt: '话题状态',
    qsrdlsjh: '请输入登录手机号',
    jsms: '角色描述',
    tsqy: '同时启用',
    yyxdsjdw: '预约下单时间单位',
    jlsj_1: '奖励时机',
    qdj: '请点击',
    fzzt: '分账状态',
    dyxxwycxxx: '订阅消息为一次性消息',
    qsrhykmc: '请输入会员卡名称',
    ncydzfjlhdzsn: '您参与的支付奖励活动赠送您',
    kyxqdjssj: '卡有效期的结束时间，必须大于活动结束时间',
    bzffjl: '不再发放奖励',
    bgzhfygb: '被关注回复已关闭',
    qbfhsdbgzs: '全部发货时的包裹展示',
    cx: '查询',
    psfwzdymcbnzf: '配送范围自定义名称不能重复',
    fsz: '发送中',
    drljxf: '当日累计消费',
    bjzsbkxysphzhtbjs: '笔记总数不可小于视频或者话题笔记数',
    hdmcbncg: '活动名称不能超过20字符',
    profitProduct: '利润',
    lr: '利润（总）',
    bmrhhdhdkybmchdxz: '报名任何活动后都可以报名此活动选择',
    hzqyyrzrs: '合作企业已认证人数',
    gkzxxsy: '顾客在线下使用',
    qsrqrdxx: '请输入确认的信息',
    qtjggnr: '请添加广告内容',
    qxzsszzjg: '请选择所属组织机构',
    qwztxtxhydjxx: '请完整填写填写会员等级信息',
    schtzhdlfs: '商城后台账号登录方式',
    qsrfxfklbdybm: '请输入发行方卡类别对应编码',
    qxxzzj: '请先选择专柜',
    xzzbj: '选择直播间',
    hz: '或者',
    ddhyfz: '订单号已复制',
    kpdh: '卡片导航',
    sqyhx: '授权优惠项',
    jdt: '进度条',
    ddb: '点点吧',
    gyhqznzzfbxcxzlq: '该优惠券只能在支付宝小程序中领取',
    bkdbxyszxktrsyqzhhmm: '包括但不限于擅自许可他人使用其帐号和密码',
    jhd: '拣货单',
    azkhd: '安卓客户端',
    zwhzmhhazwms: '中文和字母混合按中文名算',
    sjzszyz: '手机做双重验证',
    dzf: '待支付',
    xzcydrq: '限制参与的人群',
    gzdyfw: '个自定义范围',
    kdjdyhqgz: '可叠加的优惠券规则',
    slzjdhynh1: '示例：尊敬的会员您好，您参与的支付奖励活动赠送您',
    slzjdhynh2: '张优惠券，请尽快领取:',
    slzjdhynh3: '同时赠送您还有:',
    slzjdhynh4: '次抽奖机会，点击前往如{抽奖地址链接}进行抽奖。:',
    zdsczfbhwxxcxddyxpxqydl: '自动生成支付宝或微信小程序的对应小票详情页短链',
    yjwckk: '一键完成开卡',
    ddts: '到店天数',
    fsqd: '发送渠道',
    qxzgljs: '请选择关联角色',
    mdsy: '买单收银',
    bjpf: '笔记评分',
    zbcx: '坐标查询',
    qxxzpd: '请先选择票档',
    mhsjbbkpsf: '盲盒售价不包括配送费',
    wqyzddckhdfh: '完全一致订单才可合单发货',
    ldycs: '落地页参数',
    xcxbt: '小程序标题',
    zytgdqhdzc: '只有通过当前活动注册',
    zcdl: '再次登录',
    lbmd: '礼包门店',
    gqhmpmc: '改签后门票名称',
    sycs: '适用城市',
    qsrzdyts: '请输入自定义天数',
    qtjhdcc: '请添加活动场次',
    jqjhibtxwz: '请将Hi贝填写完整',
    ljzq: '累积周期',
    jypzlrghyhdhyhdxsymhhdsmym: '建议配置例如该会员活动或优惠的线上页面或活动说明页面',
    zdjl: '最低奖励',
    spmcbncg: '商品名称不能超过 14 个字符',
    tpzsmyhqlbzs: '图片在售卖优惠券列表展示',
    cyrq_1: '参与人群',
    ztsj: '自提时间',
    wj_1: '未拣',
    qr_1: '确认',
    qsrq: '签收日期',
    pzhdgmsjzjzhbdcgdr: '配置后的购买时间在加总后不得超过当日24:00',
    zhpz: '账户配置',
    ckfp: '查看发票',
    sfzsfkm: '是否展示付款码',
    tcdl: '退出登录',
    bszdcdtmrwbsd:
        '图片建议尺寸：宽620px*高770px，不设置弹层底图默认为白色底。上传后展示在首页和签到页',
    ylgg: '预览广告',
    scyy: '生成原因',
    sctph: '上传图片后',
    zfzje: '支付总金额',
    bksxcs: '帮砍上限次数',
    xyxxsjbwz: '协议选项数据不完整～',
    qtxdwjzjmd: '请填写用户定位中的定位距最近门店',
    qxzmrmd: '请选择用户定位中的默认门店',
    bl_1: '保留',
    hxsj: '核销时间',
    gdjjg: '个等级价格',
    xcxsy: '小程序首页',
    zsz: '展示在',
    ybd: '已绑定',
    qszcckssj: '请设置场次开始时间',
    rxsy: '如需使用',
    zzsxqkydq: '正在生效且可用的券',
    xzpzxchzsgx: '选中配置项才会展示该项',
    ljsb: '连接失败',
    ffzs: '发放张数',
    rmhd: '热门活动',
    sgh: '申购后',
    mjkxpssj: '买家可选配送时间',
    qwsspphxx: '请完善商品偏好信息',
    yfqzs: '已发券张数',
    df: '代发',
    qqrzwxgzhhtzdhf: '请确认在微信公众号后台自动回复',
    dtp: '的图片',
    xt_1: '系统',
    sjlx_1: '数据类型',
    hdme: '活动名额',
    qrqxyd: '确认取消预订？',
    qxjh: '取消拣货',
    tgkkxl: '提高开卡效率',
    bmsz: '报名设置',
    qrfg: '确认覆盖',
    yyy: '已预约',
    syrky: '所有人可用',
    lxdl: '连续登陆',
    md: '每单',
    dlqzzs: '待领取总张数',
    qqrxmm: '请确认新密码',
    tpsz: '图片设置',
    fzdhgz: '复制兑换规则',
    gz1bnwk: '规则1不能为空',
    gz2bnwk: '规则2不能为空',
    gz3bnwk: '规则3不能为空',
    gz4bnwk: '规则4不能为空',
    gz5bnwk: '规则5不能为空',
    xcxdyxx: '小程序订阅消息',
    xxzf: '线下支付',
    qdgbcym: '确定关闭此页面?',
    qxzd: '取消置顶',
    hdtpz: '活动图配置',
    wszyqshanzssj: '未设置延期收货按钮展示时间',
    zsqdj: '自身券叠加',
    qsrkdsl: '请输入可兑数量',
    spzt: '商品状态',
    jyfs: '经营方式',
    cksygzffs: '才可使用该支付方式',
    qsrsmj: '请输入售卖价',
    wcsz: '位纯数字',
    tkjylsh: '退款交易流水号',
    tjzck: '添加子窗口',
    bjflsl: '编辑分类税率',
    kqys: '卡券颜色',
    hdqjmrzdgm: '活动期间每人最多购买',
    xzxzmgyhlqsls: '选择限制每个用户领取数量时',
    drsj: '导入数据',
    kjfpyyqsx: '开具发票页页签顺序',
    kfjl: '客服记录',
    fxhdcjmsx: '分享获得抽奖码上限',
    bjdgzh: '编辑导购账号',
    xzjbgz: '新增加倍规则',
    tqfhjf: '退券返还积分',
    yfk: '已付款',
    qsrndqm: '请输入您的全名',
    pldrhy: '批量导入会员',
    kpz: '开票中',
    jhdjshs: '距活动结束还剩',
    bbh: '不包含',
    zdkssj: '置顶开始时间',
    xhyzcjl: '请填写活动名称。例如：新会员注册奖励',
    tgjfsclb: '推广积分商城列表',
    mdxzkhs: '门店新增客户数',
    kjfs: '开奖方式',
    zjm: '专柜码',
    szycbmqqgd: '设置延迟避免请求过多',
    bh: '包含',
    spygjzs: '商品预估价展示',
    sfkqjsts: '是否开启结算提示',
    tjzj: '添加专柜',
    xdje: '下单金额',
    bmsxyyhsffhgjf: '报名时校验用户是否符合该积分',
    wyd: '网页端',
    rahdyy: '如按活动预约，则用户在活动期间只需预约1次，按场次预约，则用户每场活动仅需预约',
    ahdyy: '按活动预约',
    dhtj: '兑换条件',
    pointRequired: '本金不可为空，并且仅支持到正整数',
    dcxhc: '与单/多促销互斥',
    qszcz: '请慎重操作',
    xzmdpztcfp: '新增门店配置停车发票',
    zntd: '只能提到',
    bjbkwk: '本金不可为空，并且仅支持到小数点后两位',
    jytpkdw: '建议图片宽度为',
    hp_1: '好评',
    tggl: '团购管理',
    xfcbjt: '悬浮窗背景图',
    ydwa: '引导文案',
    qsrbmxz: '请输入报名须知',
    wszdzfddqxsj: '未设置待支付订单取消时间',
    zszzfhhqshdqryn: '展示在支付后或签收后的确认页内',
    dq: '地区',
    ksjhbdyjhd: '开始拣货并打印拣货单',
    hxs: '后显示',
    ccrq: '场次日期',
    zqm: '中签码',
    wckmsylzd: '为查看模式预留字段',
    kqdd: '卡券订单',
    yhktgygdqywxlxdy: '用户可通过员工的企业微信联系店员',
    qxzfxzt: '请选择分享专题',
    qzsxzyzjl: '请至少选择一种奖励',
    selectAllAttend: '已选择【全部商品】参与',
    cjht: '创建话题',
    xzzt: '选择专题',
    zdsjm: '指定商家码',
    h_2: '或',
    zxcg: '注销成功',
    kfsc: '客服删除',
    fhy: '非会员',
    xqys: '详情颜色',
    tgfwdgs: '提供服务的公司',
    qsrjfzswa: '请输入积分展示文案',
    lqsj: '领券时间',
    zdyltkjmj: '自动原路退款给买家',
    qsrqm: '请输入券码',
    gwc: '购物车',
    yrk: '已入库',
    jjs: '级角色',
    xzsdshzj: '新增手动审核专柜',
    qtxyhsx: '请填写优惠上限',
    shsb: '审核失败',
    xy_1: '协议',
    xzhd: '选择活动',
    qsrshlbsl: '请输入售后礼包数量',
    ghtp: '更换图片',
    zqxx: '中签信息',
    jytk: '交易退款',
    tcjf_1: '停车缴费',
    jmlx: '减免类型',
    gmjkcyhd: '购买即可参与活动',
    zstjyt: '至少添加一台Wi-Fi设备',
    xnspxqy: '虚拟商品详情页',
    bkyxzxd: '不可以选择下单',
    jkzsccjssz: '仅可在首次创建时设置，提交后，不允许修改',
    fxk: '复选框',
    zdyjzd: '指定要加载的',
    jyglgzhdmdjmdxdzj: '仅已关联公众号的门店及门店下的专柜',
    jyglgzhdmdjmdxdzj_1: '5.仅已关联公众号的门店及门店下的专柜，且公众号已授权，可生成二维码',
    jyglgzhdmdjmdxdzj_2: '6.批量导入二维码回复内容引用默认恢复设置的内容',
    wdcj: '我的抽奖',
    bjjbgz: '编辑加倍规则',
    xzps: '新增配送',
    tjxjzzjg: '添加下级组织机构',
    sykms: '剩余可卖数',
    zffszt: '支付方式状态',
    yqhycg: '邀请好友成功',
    ljyq: '立即邀请',
    bjhydj: '编辑会员等级',
    sswrqxsdhlw: '四舍五入取小数点后两位',
    lbqjsl: '礼包券及数量',
    hdqjmyhkbm: '活动期间每用户可报名//购买',
    yxshqxgbdszszsjfts: '允许审核取消关闭单时展示赠送积分提示',
    h_yxshqxgbdszszsjfts_tip:
        '选择“是”则在审核取消关闭单时查询原订单已赠送积分信息（如积分由三方计算需确认对接后勾选）',
    ftjzhyptjbd: '分摊价总和与拼团价不等',
    de: '的',
    jkscxjyyz: '将恪守诚信经营原则',
    schdxxxz: '商场活动信息选择',
    mchdqhdqjbttjyhsgsldxz: '每场活动且活动期间不同条件用户申购数量的限制',
    hdt: '活动图',
    thanLowConsumption: '>=最低消费',
    underConsumption: '<=最高消费',
    tpccjyw638: '图片尺寸建议为638*200px，支持jpg、png格式',
    ahdxg: '按活动限购',
    qjksy: '请尽快使用',
    qshtg: '且审核通过',
    bm_2: '不满',
    fssjbnzydqsj: '发送时间不能早于当前时间',
    yf_1: '乙方',
    htdllxscmm: '后台登录连续输错密码',
    yhqtkdxq: '优惠券退款单详情',
    qxzyggzh: '请选择一个公众号',
    qt: '清退',
    z_8: '折',
    yjfklxbnzf: '一级反馈类型不能重复',
    zjxq: '专柜详情',
    kdjsy: '可叠加使用',
    jyhzdlqjl: '用户主动领取奖励：用户在一个活动内仅可领取一次奖励，不区分活动门店',
    dbb: '的版本',
    dmddzjzs_1: '多门店多专柜展示',
    kyxqdkshjssj: '卡有效期的开始和结束时间，必须大于活动开始时间',
    tghdxzcg: '团购活动新增成功',
    ykp: '已开票',
    pm_1: '排名',
    zxcxymzzzszdymc: '在小程序页面中则展示自定义名称',
    qxzsjh: '请选择手机号',
    plqycg: '批量启用成功',
    dcjyxz: '单次交易限值',
    fs: '粉丝',
    azsbtd: '按住鼠标拖动',
    jyzqd: '经验值渠道',
    mhhdlb: '盲盒活动列表',
    jyhycsdddbsyx: '禁用后已产生的订单不受影响',
    jfqd: '积分渠道',
    shbtghzhdjsrwshzzdtk: '审核不通过或者活动结束仍未审核则自动退款',
    bjmdpztcfp: '编辑门店配置停车发票',
    yhfxsp: '分销商品赚佣，社交裂变，高效转化',
    jycz: '禁用操作',
    sywxfpdkh: '使用微信分配的卡号',
    jhrq: '计划人群',
    zczz10gzwz: '2.支持最长10个中文字',
    jmjejzcdxsdhlw: '减免金额仅支持到小数点后两位',
    xyjxxm: '需要进行姓名',
    dgykdsj: '导购员开单时间',
    sry: '输入以',
    sryhttp: '输入以http或https开头的url地址',
    qywxbjtzdx: '企业微信报警通知对象',
    dchddd: '导出活动订单',
    xjqf: '新建群发',
    dzjhbzfsw: '多专柜合并支付视为',
    mblb: '模板列表',
    zdktj: '最多可添加',
    dcdd: '导出订单',
    mnjb: '每年解绑',
    smldy: '扫码落地页',
    gqhyhjwfsmjrgzh: '过期后用户将无法扫码进入公众号',
    gqhyhjwfsmjrgzh_1: '临时二维码以生成成功后最多30天内有效，过期后用户将无法扫码进入公众号',
    zffzs: '总发放张数',
    qtjhykdj: '请添加会员卡等级',
    zthgzjxdspjhbgwxjzt: '暂停后该专柜下的商品将会变更为下架状态',
    qxzxzdhhykdj: '请选择限制兑换会员卡等级',
    spbzfl: '商品标准分类',
    jsmc: '角色名称',
    roleErrorMessage: '角色名称最多40个字符',
    tnyxyhzmjdsqdzfp: '天内允许用户在买家端申请电子发票',
    bkjrq: '不可见人群',
    zdlx: '置顶类型',
    yhyq: '用户邀请',
    g_2: '挂',
    btmcbxtxw: ' 注：表头名称必须填写为“三方券码”（不含符号、空格等其他字符）,否则会导致执行错误',
    gmsp: '购买商品',
    z_9: '指',
    a: '按',
    dldzyfz: '短链地址已复制',
    smtp: '售卖图片',
    h_smtp_tip: '建议尺寸：654px * 高不限，大小不超过1M，支持jpg，png，jpeg格式',
    jfzs: '积分展示',
    nhql: '年后清零',
    tgsj: '团购时间',
    xzptsp: '选择普通商品',
    gwcyzxxs: '购物车页展现形式',
    h_gwcyzxxs_tip:
        '如在购物车中以多门店多专柜展示，结算时多门店多专柜可同时结算；如单门店多专柜展示，则结算时只能单门店多专柜一起结算；',
    zsp: '种商品',
    mkzxgygzszj: '模块中修改员工展示专柜',
    xtx: '小甜心',
    ymtsghd: '月茅台申购活动',
    bzsqjm: '不展示取件码',
    jsnyjfqkj: '即使你已经发起砍价',
    bfzjdljsyfgz: '部分专柜独立计算运费规则',
    glfklx: '管理反馈类型',
    mbpl: '每被评论',
    bjht: '编辑话题',
    hykxzgz: '会员卡限制规则',
    gxzrsj: '每日8点30更新昨日数据',
    qszngj: '请设置内购价',
    qw_1: '千万',
    jsmczd: '角色名称最多',
    qwpz: '前往配置',
    xztcq: '新增停车券',
    wfjxccz: '无法进行此操作',
    qsryfqmc: '请输入运费券名称',
    yfqNameRequired: '请输入运费券名称，不超过30个字',
    qtxddzxsdrq: '请填写订单转销售单日期',
    bnztjqtrqkp: '不能在添加其他人群卡片',
    qtxlbmc: '请填写礼包名称',
    yhkzhdpzdmdfbjxqd: '用户可在活动配置的门店分别进行签到',
    zc: '支持',
    bzc: '不支持',
    hz_1: '护照',
    scddxqy: '商场订单详情页',
    bythyfyczdsyf: '不予退还乙方已充值的使用费',
    xgh: '修改后',
    qtjggtp: '请添加广告图片',
    qqrgzjycgtjzszmdsqhgx: '请确认该专柜已成功添加至所在门店商圈后勾选',
    qsryyzwmc: '请输入原因中文名称',
    h_qsryyzwmc_msg: '请输入原因中文名称，不超过20个字',
    sddb: '手动打标',
    fzhdrsb: '否则会导入失败',
    kz_1: '控制',
    djqyxq: '等级权益详情',
    dcbb: '导出报表',
    ndllqzc: '您的浏览器支持WebSocket',
    spmcbnwk: '商品名称不能为空',
    ygxkcs: '原共享库存数',
    dxgmmhxz: '端修改密码后选择',
    qmfx: '全民分销',
    mchdbttjyhsgslbt: '每场活动不同条件用户申购数量不同',
    zfmxb: '支付明细表',
    fznlc: '分钟内离场',
    scpz: '素材配置',
    qxzht: '请选择话题',
    ckyhq: '查看优惠券',
    wcyhdcs: '未参与活动次数',
    szhmd: '设置黑名单',
    qsrjsmc: '请输入角色名称',
    ptdd: '拼团订单',
    hkz: '换开中',
    kqq: '开启前',
    syyhzcsxtfpdkh: '使用用户注册时系统分配的卡号',
    gzcdxdd: '个主菜单下的第',
    jt_1: '今天',
    qxzyhqhzyhqlb: '请选择优惠券或者优惠券礼包',
    fzje: '分账金额',
    hxfs: '核销方式',
    ywdsfgylsprhasplrjs: '以外的三方供应链商品仍会按商品利润计算',
    qwztxccrqhkssj: '请完整填写场次日期和开始时间',
    xgsp: '限购商品',
    ybdcp: '已绑定车牌',
    djfqqxqrsfyktcyw: '兑积分前请先确认是否已开通此业务，否则点击入口没反应',
    jhy: '拣货员',
    cckfdh: '车场客服电话',
    gzhmbxx: '公众号模版消息',
    fgzqxzdsj: '覆盖之前选择的数据',
    kshdj: '可稍后点击',
    qsrpx: '请输入排序',
    qxzydcdbb: '请选择要导出的报表',
    zpzdjxg: '再配置等级限购',
    xdsjh: '下单手机号',
    zc_2: '支持',
    ktd: '开头的',
    tzcdxkjxpx: '拖拽菜单项可进行排序',
    qsrshrmc: '请输入收货人名称',
    receiveNameRule: '请输入收货人名称，不超过20个字',
    rnxz: '如您选择',
    hydjbnzf: '会员等级不能重复',
    xzfw_1: '选择服务',
    zcsjzxdsjndyh: '注册时间在选定时间内的用户',
    qsrrqmc: '请输入人群名称',
    qsrtwxq: '请输入图文详情',
    h_qsrtwxq_msg: '请输入图文详情，不能超过1000个字',
    qsrzdylj: '请输入自定义链接',
    yhbmsjzytxsj: '用户报名时间早于提醒时间',
    qsrdhsm: '请输入兑换说明',
    fxysqxx: '分销员申请信息',
    distributorId: '分销员id',
    plshbtg: '批量审核不通过',
    zsyyygcd: '至少要有一个菜单',
    jlgz: '奖励规则',
    xtzddzhzdjcz: '系统自动对账号做冻结操作',
    cgcp: '常规车牌',
    hdfqrbxsnrptdzcyh: '活动发起人必须是内容平台的注册用户',
    yjcddnrjqc: '一级菜单的内容将清除',
    bcsb: '保存失败',
    ptjxz: '拼团进行中',
    dtms: '大图模式',
    thbkzlqhb: '天后不可再领取红包',
    qxcz: '取消操作',
    yeqxxzfewm: '余额券线下支付二维码',
    qrxmm: '确认新密码',
    rgxdg: '如勾选多个',
    qtxjhmc: '请填写计划名称',
    sz_2: '数字',
    zxtx: '最小填写',
    yxfehnxfezsxytxyl: '月消费额和年消费额至少需要填写一列',
    ydk: '元抵扣',
    qschdtp: '请上传活动图片',
    qtxd: '请填写第',
    wbxzmtqsqhbsl: '0为不限制每天抢手气红包数量',
    dxtzzcs: '短信通知总次数',
    syxz: '使用须知',
    dccjan: '单次抽奖按钮',
    singleButtonPictureExtra: '图片尺寸建议为242px * 126px',
    qsrxzfsdf: '请输入新增粉丝得分',
    fqkjh: '发起砍价后，自己可以默认砍掉的金额（不占帮砍人数名额）',
    qsrjtwz: '请输入具体位置',
    js_3: '计算',
    tj: '添加',
    hdjs: '活动介绍',
    zfzsbhlz: '字符至少包含两种',
    cjxdy: '创建新店员',
    djmcbnwk: '等级名称不能为空',
    qsrhfzs: '请输入合法整数',
    zfsqrz: '支付授权日志',
    drfs: '导入方式',
    bxysh: '不需要审核',
    zyhzhdqjzxyy: '则用户在活动期间只需预约',
    dlzc: '登录注册',
    dgdkdspmrxxksz: '导购端开单商品默认选项卡设置',
    gwhkjb: '购物回馈加倍',
    qsjl: '起送距离',
    bjanzt: '编辑按钮状态',
    ssflmslx: '税收分类免税类型',
    xzyhqlb: '新增优惠券礼包',
    rhcx: '如何查询',
    ppsz: '匹配数字',
    myhbmcc: '每用户报名场次',
    djwxfhfw: '对接微信发货服务',
    fwzt: '服务状态',
    rs: '人审',
    hyknrsz: '会员卡内容设置',
    tgtzymlq: '通过跳转页面领取',
    xzdrsjmmb: '下载导入商家码模板',
    xcxhtszfp: '小程序后台设置发票',
    gbhqtymzsjsdyx: '关闭后前台页面展示将受到影响',
    hfsj: '回复时间',
    zh: '租户',
    sfqrjb: '是否确认解绑',
    pbxnhd: '屏蔽虚拟号段',
    yfz_1: '已分账',
    zjlx: '证件类型',
    scqbzssp: '商城全部在售商品',
    txhzyemxcxyzs: '填写后在余额明细查询页展示',
    qszpdmc: '请设置票档名称',
    zngwyk: '祝您购物愉快',
    kjhdglbq: '可将活动关联标签',
    qgz: '券规则',
    xzbqqx: '新增标签权限',
    ptdzfp: '普通电子发票',
    yzffs: '原支付方式',
    mmw: '命名为',
    zdyan: '自定义按钮',
    ckjszq: '查看结算周期',
    zzc: '只支持',
    dkdwqtx: '抵扣单位请填写>0的正整数',
    wzxzbcg: '文字限制不超过',
    tyyhjnbhykj: '同一用户仅能帮好友砍价',
    zssyqtx: '最少使用请填写>0的正整数',
    cqsj: '抽签时间',
    qje: '券金额',
    yyddh: '预约订单号',
    sxjssj: '生效结束时间',
    ddqs: '订单签收',
    xdrkdj: '下单人卡等级',
    aqsz: '安全设置',
    sjasdsjfh: '商家按送达时间发货',
    dqjzc: '当前仅支持',
    fwb: '副文本',
    hxlsh: 'POS核销流水号',
    lbzs: '轮播展示',
    tzqzcdyhkcyhd: '天之前注册的用户可参与活动',
    qsjebbhspdyfje: '起送金额不包含商品的运费金额',
    nhkcj: '您还可抽奖',
    l_nhkcj_1: '您还可抽奖5次',
    sfbz: '收费标准',
    bqtp: '标签图片',
    qtxxgtjxzxddyz: '请填写限购条件选中项的对应值',
    zjxxdqhtpzdzjnr: '专柜信息读取后台配置的专柜内容',
    kprsjh: '开票人手机号',
    zzsyhgdjfz: '则赠送用户固定积分值',
    whzqykznbflhd: '为合作企业开展内部福利活动',
    zgyh: '则该用户',
    qsrzdtxed: '请输入最低提现额度',
    rbgxzyscgztrzsjl: '如不勾选只有首次关注他人赠送奖励',
    lrzy: '例如自营',
    qzxsz: '请重新设置',
    mmyxzq: '密码有效周期',
    xxkqdyxx: '需先开启订阅消息',
    qsrzs: '请输入张数',
    yhsmhrdyewmyszwmrhfnr: '用户扫码后如对应二维码已设置为默认回复内容',
    yhsmhrdyewmyszwmrhfnr_1:
        '1.用户扫码后如对应二维码已设置为默认回复内容，用户将收到统一回复内容。修改后，会影响所有设置为默认回复内容的二维码消息内容发送',
    bhjtggfwhcdyh: '变化将通过该服务号触达用户',
    sh_2: '售后',
    qdhdyxjl: '签到获得以下奖励',
    smewm: '扫描二维码',
    xqtz: '详情跳转',
    xzlbjl: '新增裂变激励',
    xzsfq: '选择三方券',
    gzhhxcxytjdmbyddd: '公众号或小程序已添加的模板一旦达到',
    xzhydj: '新增会员等级',
    jcyf: '基础运费',
    jpxz: '奖品限制',
    bcms: '补充描述',
    fzze: '分账总额',
    zcdhj: '正常兑换价',
    zcdhj_1: '请设置（正常兑换价）的分摊价',
    xfsj: '消费时间',
    zdhkxz: '最多还可选中',
    qrplxgxzdptptjm: '确认批量修改选中的普通拼团价吗?',
    qtxymbt: '请填写页面标题',
    ydh_1: '已兑换',
    l_ydh_1: '已兑换/剩余数量',
    xzf: '需支付',
    sfyxkp: '是否允许开票',
    cyzt: '参与状态',
    sjhgrzldrhbd: '数据或个人资料的任何病毒',
    cjxht: '创建新话题',
    yhyckt: '用户一次开通',
    aflpzdfdbl: '按分类配置的浮动比例',
    submit: '提交',
    kyjb: '可用金币',
    zhms: '租户描述',
    qsrbjdzdf: '请输入笔记点赞得分',
    dxfwxy: '《短信服务协议》',
    jfsp: '积分商品',
    hdxct: '活动宣传图',
    qsryhxfje: '请输入用户消费金额',
    qtjwjcgh: '请添加文件成功后，点击导入按钮',
    syzjglw: '剩余中奖概率为',
    hdy: '活动页',
    hybkjl: '好友帮砍奖励',
    wbms: '文本描述',
    zhbgsj: '账户变更时间',
    yhqqh: '优惠券券号',
    hyqtrhfswhyfrhcpdzcyxd: '或以其他任何方式危害乙方任何产品的正常运行的',
    srsjmsx: '输入商家码筛选',
    bmdzq: '白名单中签',
    zfbsjq: '支付宝商家券',
    wdhd: '我的活动',
    kqzt: '开启状态',
    zstq: '至少提前',
    cczgljtsh: '超出最高累计天数后，重新从第一阶段计算',
    bkdbxywlf: '包括但不限于无漏发',
    mz_1: '面值',
    cdmc: '菜单名称',
    rjfysfjsxqrdjhgx: '如积分由三方计算需确认对接后勾选',
    xzrytj: '选择人员条件',
    zqmd: '中签门店',
    yhjs: '用户介绍',
    qsrjsms: '请输入角色描述',
    moreThan400: '最多400个字符',
    bmkssjbt: '报名开始时间必填',
    qsryskddkhmy: '请输入优速快递的客户密钥',
    zdbzfl: '指定标准分类',
    qtsq: '前台申请',
    gljs: '关联角色',
    ysms: '预售模式',
    zffsybgb: '支付方式已被关闭',
    xp: '新品',
    zbzcsmjhczp: '暂不支持扫码拣货称重品',
    cksyggn: '才可使用该功能',
    qdzdxk: '全店最多限砍',
    lxkf: '联系客服',
    hxgdtjgdpz: '横向滚动添加更多配置',
    jbktzdxbcktz: '将不可拓展对象变成可拓展',
    qsrnsddyzm: '请输入您收到的验证码',
    ddsl: '订单数量',
    orderCount: '订单数',
    dlyx: '登录邮箱',
    jkcgrs: '集卡成功人数',
    jzctxzzs: '仅支持填写正整数',
    mryc: '每日一场',
    gmdcj: '个门店参加',
    rhscgwj: '然后上传该文件',
    yjrq: '右击热区',
    qjr: '请进入',
    xdyhs: '下单用户数',
    kxzryyj: '可选择任意一级',
    zspzygjt: '至少配置一个阶梯',
    ysj: '预售价',
    jlscsgzs: '距离上次申购至少',
    dmdxyjhs: '当门店需要拣货时',
    bmxzsrfw: '报名限制生日范围',
    hyxttyglmm: '会员系统统一管理密码',
    h_hyxttyglmm_tip: '是：C端修改密码后选择"是"的支付方式都生效，否：可单独修改该支付方式密码',
    by: '本月',
    jftzym: '积分跳转页面',
    ydd: '移动端',
    qsrspslhxztssl: '请输入商品税率或选择特殊税率',
    ptlb: '拼团列表',
    nsrsbh: '纳税人识别号',
    yzf: '已作废',
    scydfs: '生成运单方式',
    qszhydjgz: '请设置会员等级规则',
    zjkzzhdksyjsdywgnsjqxfw: '门店/专柜控制账号的可使用角色的业务功能数据权限范围',
    sjwszjtjs: '商家未设置阶梯价时，所有订单为统一运费标准',
    qxzxyyzdhysf: '请选择需要隐藏的会员身份',
    qzqddjejsdydsz: '请正确订单金额件数对应的数值',
    xtazcsxxdgzasxzx: '系统按照从上向下的规则按顺序执行',
    sfkspljhyxjhd: '是否开始批量拣货已选拣货单',
    myhmyksg: '每用户每月可申购≤',
    kfzkbxsjdxftdydfwxx: '开发者可不限时间地下发条对应的服务消息',
    khbz: '客户备注',
    qtxhdmc: '请填写活动名称',
    ljxzgzs: '累计新增关注数',
    sy_2: '适用',
    lqrs: '领券人数',
    jsgz: '结算规则',
    qfhxg: '请返回修改',
    xzymsz: '新增页面设置',
    hylljl: '好友浏览记录',
    mf_1: '免费',
    rccjkyfhzyccfhzwzzymzs:
        '如车场接口有返回则以车场返回值为最终页面展示，尽量与高德地图上的名称一致',
    shr_1: '审核人',
    fssz: '发送设置',
    wszptrs: '未设置拼团人数',
    qsrztkdddzmdzh: '请输入中通快递的电子面单账号',
    qdyscgljm: '确定要删除该链接吗',
    gztr: '关注他人',
    jscdlhg: '距上次登录后隔',
    mjjexydxdysydje: '保存失败，门槛金额下一档需大于上一档金额',
    ytk: '有退款',
    zjh_1: '座机号',
    xzsdpqxqryzswkt: '选择数电票请先确认已在税务开通',
    ahykdj: '按会员卡等级',
    zyhqydz: '张优惠券已到账',
    zhycczsj: '最后一次操作时间',
    zcsj: '注册时间',
    dh: '导航',
    bmkssj: '报名开始时间',
    dzkbm: '电子卡别名',
    xgzssxbnwk: '限购总数上限不能为空，并且仅支持到正整数',
    qxzkjsj: '请选择开奖时间',
    bmlhd: '报名类活动',
    gnqx: '功能权限',
    dkfsd: '打款方式等',
    ckbkjlt: '拆开帮砍奖励图',
    qxztgsj: '请选择团购时间',
    kdw: '宽度为',
    kkjb: '可跨级别',
    gznr: '规则内容',
    sygqd: '使用过渠道',
    yhbdzp: '用户拨动转盘，赢取积分或奖励',
    czjydh: '充值交易单号',
    spbsyspssbzfldpzssbms: '商品本身与商品所属标准分类都配置税收编码时，优先使用商品配置的税率',
    jyhdsjzwpxbzssj: '如不填写，将以活动时间作为排行榜展示时间',
    jhdhyspwwcjh: '拣货单还有商品未完成拣货!',
    jgrq: '价格日期',
    zjqxgbcld: '专柜取消关闭处理单',
    zqbmdgl: '中签白名单管理',
    jhdzgzhxgdbfgnbksy: '将会导致公众号相关的部分功能不可使用',
    bdsjh: '绑定手机号',
    dds: '订单数（总）',
    mfw: '梦服务',
    dbfs: '打标方式',
    kqh: '开启后',
    bbhjfsc: '不包含积分商城',
    bkjjj: '不跨级降级',
    byqr: '被邀请人',
    bcsx: '保存生效',
    yzzt: '验证状态',
    hdjl_1: '活动记录',
    zjddgl: '专柜订单管理',
    xzjk: '新增接口',
    bjl: '倍奖励',
    kpzt: '开票主体',
    h_kpzt_placeholder: '选填，用于识别专柜使用的开票主体。字母、数字组合，最多30位',
    dy_2: '定义',
    r_3: '日',
    sjhdl_1: '手机号登陆',
    sqdd: '商圈订单',
    mhdd: '盲盒订单',
    smjg: '售卖价格',
    s_5: '时',
    wx: '微信',
    ptzhsp: '普通组合商品',
    bjym: '编辑页面',
    qcqb: '清除全部',
    yddktjzzjgdzdcj: '已达到可添加组织机构的最大层级',
    sccc: '删除场次',
    xstcjfhx: '线上停车缴费核销',
    bncg: '不能超过20个字',
    ljhc: '立即合成',
    lqsm: '领取说明',
    rgff: '人工发放',
    ms: '描述',
    plxgmbycg: '批量修改目标页成功',
    clzxjg: '策略执行结果',
    qtchzsjfgze: '前台才会展示积分规则哦',
    sffqtj: '三方发券统计',
    jflx: '积分类型',
    xzjl: '新增奖励',
    spxsjs: '商品销售件数',
    dz_1: '地址',
    h_xfyyzzdz: '销方营业执照地址',
    urlAddress: 'url地址',
    sycws: '剩余车位数',
    hybmkdzdj: '好友帮忙砍到最低价',
    mrzs: '默认展示',
    maotaiPresaleForecastPage: '默认展示“进行中”的活动',
    jp: '奖品',
    qrhpzj: '确认货品组件',
    tnbkzcsg: '天内不可再次申购',
    sksj: '收款时间',
    zszhykms: '1.展示在会员卡面上',
    zdhjf: '总兑换积分',
    zswa: '展示文案',
    h_zswa_msg: '请输入展示文案，不超过20个字',
    h_zswa_placeholder: '请输入展示文案，例如：我的卡',
    ydtjsx: '已达添加上限',
    jlffxz: '奖励发放限制',
    xzzh: '新增租户',
    jlzh: '奖励追回',
    qsrdhcssx: '请输入兑换次数上限',
    ydyhqydz: '元的优惠券已到账',
    ahykdjdh: '按会员卡等级兑换',
    qd_1: '渠道',
    fpjscg: '分配角色成功',
    hwfjfdqtxy: '或违反甲方的其它协议',
    ydhspzs: '已兑换商品总数',
    hbdqm99yby: '如华北地区满99元包邮，不超过20个字',
    jfsc: '积分商场',
    dj_1: '单价',
    qtxgzmc: '请填写规则名称，最长20个字',
    qgjtccdjjtqk:
        '请根据停车场对接具体情况，配置不同减免项的停车券，否则将导致用户在停车缴费时，无法使用停车券',
    qrscm: '确认删除吗',
    xzmrzhdnffcs: '限制每人在活动内发放次数',
    bjys_1: '编辑预售',
    fhyfs: '非会员粉丝',
    gmdxwhydj: '该门店下无会员等级',
    tgqdjyepx: '推广渠道交易额排行',
    jshf: '接受回访',
    drsj_1: '导入时间',
    yxsysppzdsl: '优先使用商品配置的税率',
    tylxdhyqyjzcsz: '同一类型的会员权益仅支持设置1档',
    wkh: '未开户',
    accyy: '按场次预约',
    qsrdhjf: '请输入兑换积分',
    zhcsjc: '转换成时间戳',
    scqfs: '生成券方式',
    produceJson: '生成JSON',
    qsrhy: '请输入会员ID',
    flmcbnwk: '分类名称不能为空',
    gzhqxjqqybzz: '公众号权限集请求已被终止',
    slsyyxj: '税率使用优先级',
    h_slsyyxj_tip: '税率使用优先级：商品本身税率>商品所属子级标准分类>商品所属父级标准分类',
    xzdjq: '新增代金券',
    yjflzkzt: '一级分类展开状态',
    tjsc: '提交删除',
    dssy: '电商首页',
    zxsrqsjxwyhdkssj: '转销售日期时间需晚于活动开始时间',
    qyngdspbzczxqsgz: '拼团、预约抢购、预约抽签、企业内购等商品不支持执行起送规则。',
    yhkcy: '用户可参与',
    zjzfddzb: '专柜支付订单占比',
    dw: '单位',
    sffdx: '是否发短信',
    qdyzxfqtk: '确定要重新发起退款?',
    notificatonContent:
        '尊敬的会员您好，您参与支付奖励赠送您{number}张优惠券，请尽快领取。 {shortLink}',
    NotificatonContentValue:
        '尊敬的会员您好，您参与支付奖励赠送您{number}张优惠券已到账，请您在“我的优惠券”中查收。',
    tcympz: '停车页面配置',
    pldrsz: '批量导入设置',
    tpbj: '图片笔记',
    jlxz: '奖励限制',
    qd_2: '强度',
    srbqmc: '输入标签名称',
    bjgzhewm: '编辑公众号二维码',
    hdksygsj: '活动开始预告时间',
    xdrq: '下单日期',
    wz_3: '文字',
    bszdbbxg: '不设置代表不限购',
    dsy: '待使用',
    qxxzyggzh: '请先选择一个公众号',
    plzxcg: '批量注销成功',
    rffsyzryjsdqbyj: '每月第N日发放上一自然月结算的全部佣金',
    szxzq: '色值选择器',
    slt: '缩略图',
    gdpz: '更多配置',
    qxzymbj: '请选择页面背景',
    hyzx: '会员专享',
    zbql: '逐笔清零',
    qsrysrzywmc: '请输入延时入账业务名称，不超过10个字',
    qbdc: '全部导出',
    ybmme: '已报名名额',
    zxcg_1: '执行成功',
    poshx: 'POS核销',
    yqrfxyqhd: '邀请人分享邀请活动',
    xsnr: '显示内容',
    hydjxg: '会员等级限购',
    dgdhx: '导购端核销',
    qsrfwdd: '请输入服务地点',
    zsplrkhyhckfbpl: '展示评论入口后用户才可发布评论',
    kjcgs: '砍价成功数',
    hydjgz: '会员等级规则',
    ckewm: '查看二维码',
    sf: '实付',
    gm_1: '购买',
    zsxyygyjfklx: '至少需要一个一级反馈类型',
    aq: '安全',
    hdgzbjs: '活动规则背景色',
    sxf: '手续费',
    h_sxf_msg: '请输入手续费',
    ptz: '拼团中',
    op: '欧派',
    znzdrqcy: '只能指定人群参与',
    zhhyd: '综合活跃度',
    qyzt: '签约状态',
    hdht: '活动话题',
    dtdh: '地图导航',
    y_4: '月',
    mdxx: '门店信息',
    fknr: '反馈内容',
    zhfkm: '账户付款码',
    ktddzplsx: '可拖动调整排列顺序',
    qxgz: '取消规则',
    gzhmbxx_1: '公众号模板消息',
    qrplxgxzdszkcm: '确认批量修改选中的设置库存吗',
    kqxxtz: '开启消息通知',
    qszzsnr: '请设置赠送内容',
    xzqyx: '新增权益项',
    plr: '评论人',
    hydrxfjedk: '会员当日消费金额抵扣',
    xpjly: '小票记录页',
    pjxx: '评价信息',
    jxxyhcy: '仅限新用户参与',
    btys: '标题颜色',
    kjsj: '开奖时间',
    khsb: '开户失败',
    qxzlbnr: '请选择礼包内容',
    xbkdhyzsjl:
        '向帮砍的好友赠送奖励，帮砍好友每个活动只会赠送1次奖励，促进帮砍好友进行下单，提高交易量，建议合理添加',
    xx_1: '线下',
    xs_3: '线上',
    khxzh: '开户行账号',
    qxzsfq: '请选择三方券',
    zbsj: '直播时间',
    qtxxzhdjfz: '请填写选中后的积分值',
    xssy: '线上使用',
    ykfpkysqhkdcs: '已开发票可以申请换开的次数',
    ygbm: '员工编码',
    fxymc: '分销员名称',
    tpccjyw: '图片尺寸建议为',
    xzdrspmb: '下载导入商品模板',
    dhsl: '导航数量',
    cllx: '处理类型',
    zxjy: '在线建议',
    klbbm: '卡类别编码',
    zhbgjylsh: '账户变更交易流水号',
    kjcg: '砍价成功',
    hc: '红冲',
    ahyrq: '按会员人群',
    zdzcsgjt: '最多支持4个阶梯',
    t: '条',
    shan: '售后按钮',
    lrwsydyhq: '例如未使用的优惠券',
    zstr: '，赠送他人',
    shtgcg: '审核通过成功',
    ccgz: '场次规则',
    xgjb: '修改金币',
    jzxtzhywybs: '指magic系统中会员唯一标识',
    ddmjje: '达到门槛金额',
    dzfddqxsjsz: '待支付订单取消时间设置(在线支付)',
    dzfddqxsjsz_xxzf: '待支付订单取消时间设置(线下支付)',
    xsqtkd: '线上券退款单',
    zlsxymc: '这里是协议名称',
    mc: '名称',
    fzyhwfsdkstx: '否则用户无法收到开始提醒',
    spjwfgm: '商品将无法购买',
    zjmd: '中奖名单',
    drrs: '导入人数',
    dbsj: '打标时间',
    zjxx: '专柜信息',
    hytymm: '会员统一密码',
    hyzc_1: '会员注册',
    cz_1: '充值',
    ms_1: '秒杀',
    zwckqgzqx: '暂无查看券规则权限',
    xzspfl: '选择商品分类',
    sqqx: '授权权限',
    qxzyhqjp: '请选择优惠券奖品',
    qsrpjkddyjzh: '请输入品骏快递的月结账号',
    xzmbxx: '新增模板消息',
    qtxbmtjxzxddyz: '请填写报名条件选中项的对应值',
    nyrgszctsjd:
        '年月日格式（支持+24小时制时间），支持填时间段，两个时间点之间用“~”符号连接（例如：2019年10月1日，或：2019年10月1日 15:01，年月日可用“.-//”代替， ）',
    xszsjgszcsjd:
        '24小时制时间格式（支持+年月日），支持填时间段，两个时间点之间用“~”符号连接（例如：15:01，或：09:00~15:00，或：2019年10月1日 15:01，年月日可用“.-//”代替， ）',
    bkqx: '不可取消',
    hzb: '汇总表',
    xnsplb: '虚拟商品列表',
    sfgbsz: '是否关闭设置',
    qtxjfbfb: '请填写积分百分比',
    yy: '愿意',
    dje: '的金额',
    dx_2: '大小',
    zjjswcsj: '最近计算完成时间',
    sggz: '申购规则',
    tszsnhy: '同时赠送您还有',
    dgfx: '导购分销',
    nrqy: '内容区域',
    wd_1: '稳定',
    ffjl: '发放奖励',
    ffcjjhdsl: '发放抽奖机会的数量',
    tx_2: '提醒',
    cjtgm: '创建推广码',
    sfksjh: '是否开始拣货',
    qsryhqqm: '请输入优惠券券码',
    cr_1: '插入',
    zdffjl: '最多发放奖励',
    hdqjljyqxyslxrzckyhddycsjkjh: '活动期间累计邀请相应数量新人注册可以获得对应次数集卡机会',
    ddzj: '订单专柜',
    hdgzsm: '活动规则说明',
    lbxx: '礼包信息',
    giftNameRequired: '请填写礼包名称，不超过30个字符',
    jkgl: '集卡攻略',
    kqml: '开启目录',
    qsrbkcs: '请输入帮砍次数',
    qmqkthzcgx: '请明确开通后在此勾选',
    xsrdydyos: '限输入大于等于2的偶数',
    qh_1: '区号',
    hxhdhff: '核销活动后发放',
    pldb: '批量打标',
    zszhykxqn: '展示在会员卡详情内，建议填写商家固定电话',
    tmdjzckqygqdylhd: '同时间段内，同门店仅支持开启一个签到有礼活动。',
    ddqshcksqsh: '订单签收后才可申请售后',
    dzpjjesx: '单张票据金额上限',
    h_dzpjjesx_msg: '请输入单张票据金额上限，最小可设置0.01，最大可设置99999.99',
    h_dzpjjesx_tip: '单位：元，保留2位小数',
    yhdjgz: '优惠叠加规则',
    fwjsrq: '服务结束日期',
    yjlx: '一级类型',
    wxzf: '微信支付',
    yfkssflbm: '预付卡税收分类编码',
    zbjdds: '直播间订单数',
    txzdymch: '填写自定义名称后',
    gynchzh: '个以内纯汉字或',
    qzqtxmdbm: '4.请正确填写门店编码，如重新导入，则会覆盖之前的导入的门店；',
    djs_1: '待结算',
    dhsl_1: '兑换数量',
    mdpz: '门店配置',
    bgzz: '变更组织',
    sp: '商品',
    szhfhdmmzh: '数字和符号的密码组合',
    xnspptlb: '虚拟商品拼团列表',
    xgdjpslbxdyyszdsl: '修改的奖品数量必须大于已设置的数量',
    tgqdxdpx: '推广渠道下单排行',
    qrplxgxzdccbmmem: '确认批量修改选中的场次报名名额吗',
    zdznszythfnr: '最多只能设置一套回复内容',
    qxzmzxzrq: '请选择每周限制日期',
    qsh_1: '签收后',
    bfb: '百分比',
    byqrjl: '被邀请人奖励',
    cjyh: '创建用户',
    gmslxz: '购买数量限制',
    djxx: '等级信息',
    sqtz: '申请团长',
    ty_2: '通用',
    qsryesm: '请输入余额说明，不能超过20000个字',
    d_5: '档',
    ft: '方图',
    pljjsycdz: '评论仅计算一次点赞',
    bmxzdj: '报名限制等级',
    wxzbj: '微信直播间',
    supportGtZero: '仅支持录入大于0的整数',
    tq: '提前',
    qrkq: '确认开启',
    qsrtcssflbh: '请输入停车税收分类编号',
    zktxsz: '只可填写数字',
    qsryhjs: '请输入用户介绍',
    fzmdpztcfp: '复制门店配置停车发票',
    qwztxfybl: '请完整填写分佣比例',
    qd_3: '圈店',
    zxbm: '招新部门',
    qszjltj: '请设置奖励条件',
    qbspcy: '全部商品参与',
    sycjcs: '剩余抽奖次数',
    mpbjjhzs: '每篇笔记均获赞数',
    myd_1: '每月第',
    cjmsl: '抽奖码数量',
    cqsjbnwk: '抽签时间不能为空',
    hyxx: '会员信息',
    qbjg: '全部结果',
    yhqzzs: '优惠券总张数',
    ky: '可约',
    zzfxtp: '转赠分享图片',
    h_zzfxtp_tip: '建议尺寸：500px * 400px (图片比例5:4即可)，大小不超过1M；支持jpg，png，jpeg格式',
    qmd: '券门店',
    sjflsjzs: '三级分类数据展示',
    xzqsgz: '新增起送规则',
    mrkhzr: '默认客户责任',
    qtxkpsqydydyfz: '请填写可配送区域对应的运费值',
    jhzhdtfygzsmcxs: '将会在活动投放页规则说明处显示',
    mrtx: '默认头像',
    lptp: '礼品图片',
    h_lptp_msg: '图片{{file.name}}超出限制，最大上传 2M 图片',
    h_lptp_tip: '尺寸建议750x750,大小2M以下,支持jpg、png格式',
    zdsr_1: '最大输入100字符',
    sfqrscgmb: '是否确认删除该模板?',
    zjpd: '增加票档',
    qrdzbshtg: '确认订座并审核通过',
    schdxqy: '商场活动详情页',
    yhcjcsddghdcjdzcs: '用户抽奖次数达到该活动抽奖的总次数',
    zj_4: '组件',
    zcjdqts: '注册距当前天数',
    xzzthd: '选择专题活动',
    zjjl: '中奖记录',
    djlsh: '单据流水号',
    zyxcl: '做一下处理',
    spsllx: '商品税率类型',
    dhyhq: '兑换优惠券',
    klx: '卡类型',
    yscd: '已生成的',
    hdxx: '活动信息',
    tbdbq: '同步打标签',
    kscq: '开始抽签',
    jy: '借用',
    ftjzhhlbdmjbd: '分摊价总和和礼包单卖价不等',
    qqdyqdmsb: '请求打印渠道码失败',
    bx_1: '必选',
    xzsj_1: '选中数据',
    bcbsh: '保存并审核',
    hbdzxd: '画布的中心点',
    mrhf: '默认回复',
    jhyjftcfwpz: '会员积分-停车服务配置',
    hyhd: '会员活动',
    qdyly: '签到有礼页',
    zcaqcl: '注册安全策略',
    yj_3: '永久',
    gdhdxct: '更多活动宣传图',
    cxyszfbdhy: '此校验是支付宝对会员',
    bhfh: '不含符号',
    szdq: '所在地区',
    sfzyxqksrq: '身份证有效期开始日期',
    qxzljsj: '请选择领奖时间',
    bhc: '不互斥',
    qrtjsh: '确认提交审核？',
    tsrqjl: '特殊日期奖励',
    qydj: '启用等级',
    yy_1: '应用',
    zfyh: '支付优惠',
    qszjlcsxz: '请设置奖励次数限制',
    zszzcsjsflxdbq: '暂时只支持社交身份类型的标签',
    bhsj: '备货时间',
    yrzrs: '已认证人数',
    qsrcdnr: '请输入菜单内容',
    ptj: '拼团价',
    ddycbj: '订单异常报警',
    yxflmby: '营销分类模板页',
    hyyhl: '会员优惠栏',
    ccskf: '车场收款方Id',
    xzewm: '下载二维码',
    zfsq: '支付授权',
    sctpjycck: '上传图片建议尺寸宽',
    xsys: '显示样式',
    yhkyjxgm: '如配置12:00-12:30；则每日的12:00-12:30用户可以进行购买',
    thtk: '退货退款',
    sppj: '商品评价',
    qszhdrqhsj: '请设置活动日期和时间',
    drbmxx: '导入报名信息',
    mtzdff: '每天最多发放',
    bxybm: '不需要报名',
    gmrs: '购买人数',
    qxsysm: '权限使用说明',
    smj: '售卖价',
    wsxxyl: '完善信息有礼',
    qdqkspm: '确定清空商品吗',
    bznr: '备注内容',
    mpfx: '名片分享',
    xfjezcsr: '消费金额支持输入',
    qxzjflx: '请选择积分类型',
    qxzljlx: '请选择链接类型',
    zwkfpdjs: '暂无可分配的角色',
    qxzdhxz: '请选择兑换限制',
    tkbfcg: '退款部分成功',
    pldrsjm: '批量导入商家码',
    hxyy: '核销原因',
    kpzt_1: '开票状态',
    dcanzt: '导出按钮状态',
    xzqynghd: '选择企业内购活动',
    xzyeqdkdqlx: '选择余额券抵扣的券类型',
    mbnryc200: '模板内容已超200字符，请修改',
    gmsj: '购买时间',
    wewjl: '无额外奖励',
    sfqrtj: '是否确认提交',
    hspbj: '含视频笔记',
    qzxzyhqsjdc: '数据生成中，请在下载优惠券数据导出',
    accbmhd: '按场次报名活动',
    sfxysmrz: '是否需要实名认证',
    qsrlqsl: '请输入领取数量',
    qxsqsj: '取消授权时间',
    jssjbnzydqsj: '结束时间不能早于当前时间',
    qxzzcfxdzty: '请选择支持分享的专题页，若专题页不支持分享，无法拉新裂变，达不到活动效果',
    xysyddcjlb: '需要使用的的插件列表',
    xxqfmk: '消息群发模块',
    bjzzjgcg: '编辑组织机构成功',
    kddxggzffsmm: '可单独修改该支付方式密码',
    fqzj: '发券专柜',
    qzyblmdxx: '请注意变量名大小写',
    qzy: '请注意',
    sfjzkp: '是否禁止开票',
    djzt: '叠加状态',
    xtsjscq: '系统随机生成券code模式',
    fdbl: '浮动比例',
    wdschd: '我的商场活动',
    bmwks: '报名未开始',
    zl_2: '质量',
    xzjfgz: '新增积分规则',
    ljsjbnzykjsj: '领奖时间不能早于开奖时间',
    qbfhyfs: '全部非会员粉丝',
    qsrbjszdf: '请输入笔记收藏得分',
    syzhmmdl: '使用账号密码登录',
    tymdxjzcczytnzhksj: '同一门店下仅支持存在一条年终回馈数据',
    fqydw: '个人//非企业单位',
    sfdyjhd: '是否打印拣货单',
    jycc_1: '禁用场次',
    xzmdpzczkfp: '新增门店配置储值卡发票',
    yspsfjejxbj: '以商品实付金额进行保价',
    smrztgh: '实名认证通过后',
    yhqhyhqlbjzcszyz: '优惠券和优惠券礼包仅支持设置一种',
    jfdhyccjcs: '积分兑换一次抽奖次数，获得抽奖次数上限',
    sz_3: '实重',
    yszqbfsrqhfxx: '已设置全部粉丝人群回复消息',
    kqx: '可取消',
    sfgyl: '三方供应链',
    hdwks: '活动未开始',
    l_hdwks_1: '活动未开始-预告设置',
    gjtxfmjdje: '保存失败，请填写第{{index}}个阶梯消费门槛的金额',
    zxsrq: '转销售日期',
    qj_1: '区间',
    zc_3: '资产',
    yhdyxjl: '已获得以下奖励',
    xzyfgz: '新增运费规则',
    plsccc: '批量生成场次',
    zdhbwz: '最大红包位置',
    mtewhdcjcs: '每天额外获得抽奖次数',
    qsrljje: '请输入立减金额',
    zdkzz: '最多可转赠',
    rxxg: '仍需修改',
    gldd: '关联订单',
    zdyzz: '自定义组织',
    kcgl: '库存管理',
    zhclsj: '最后处理时间',
    fzffzbl: '分账方分账比例',
    hdyant: '活动页按钮图',
    fwlm: '服务类目',
    qsrdlyx: '请输入登录邮箱',
    qszxfcmc: '请设置悬浮窗名称',
    ptcg: '拼团成功',
    mrfjsl: '每日放奖数量',
    qsrzqsjh: '请输入正确手机号',
    xgsj_1: '限购时间',
    jrstrz: '进入实体认证',
    ycsj: '用餐时间',
    mgz: '敏感字',
    yscdwsyyhqjaxghdgzzx: '修改领取和使用规则后，已生成的未使用优惠券将按修改后的规则执行',
    bdyjbcpqy: '绑定与解绑车牌权益',
    inputIpplease: '请输入IP，不超过30个字',
    xsms: '限时秒杀',
    wxlsh: '微信流水号',
    qsrbz: '请输入备注',
    dxk_1: '单选框',
    shyh: '审核用户',
    tjxy: '添加协议',
    xzxjzzjg: '选择下级组织机构',
    jflx_1: '缴费类型',
    wfpttfq: '我方平台他方券',
    zpxx: '赠品信息',
    qzqtxzjm: '请正确填写中奖码',
    gxnsgd: '恭喜您申购的',
    byqrzhdqjxdqqs: '被邀请人在活动期间下单且签收',
    zdgm: '最多购买',
    ymlj: '页面路径',
    xkt: '需开通',
    yecx: '余额查询',
    fpjs: '分配角色',
    qsrhdgz: '请输入活动规则',
    lxyqd: '连续邀请第',
    qdzyhs: '签到总用户数',
    qsrcc: '请输入车场POI',
    bj: '保级：',
    zts: '自提时',
    ruleConfigSuffix: '人//次',
    wddjwdyj: '未达到降为低一级',
    dgkdzcysrz: '导购开单支持延时入账',
    tygbmdhdhjxzqxy: '同一个编码的活动会进行中签校验',
    qsrpsgzmc: '请输入配送规则名称，不超过20个字',
    xxrgtz: '线下人工通知',
    jrddym: '今日订单已满',
    hzje: '汇总金额',
    ksrk: '快速入口',
    zyhxyjxxmhsfzdrz: '则用户需要进行姓名和身份证的认证',
    xszqjxfe: '可降级，需设置期间消费额',
    lx_1: '类型',
    djxzpldrmb: '点击下载批量导入模板',
    mzdy: '面值定义',
    zxsj: '执行时间',
    jebdw: '金额不得为',
    jpxzhxzdlx: '奖品项最后选中的类型',
    sq: '商圈',
    yhsqzt: '用户授权状态',
    sfgzgzh: '是否关注公众号',
    qxshsqd: '取消售后申请单',
    pzjfcj: '拍照积分场景',
    xzsfyeq: '选择三方余额券',
    bz: '标准',
    rxzsjhwrzfs: '如选择手机号为认证方式，请新增合作企业后在列表中操作-管理手机号中添加手机号',
    qsrlj: '请输入累计',
    qxzspm: '请选择商品码',
    pmjl: '排名奖励',
    xsscsysj: '线上首次使用时间',
    xbsl: '限报数量',
    yky: '元可用',
    qtxsz: '请填写数字',
    qsrqdlx: '请输入渠道类型',
    qxsz: '权限设置',
    xzbq: '选择标签',
    cjdtsj: '抽奖动态数据',
    glmdzgsjqx: '关联门店//专柜（数据权限）',
    sjje: '随机金额',
    xzbk: '新增板块',
    ydwz: '预订位置',
    qscjlt: '请上传奖励图',
    sfkfh: '是否可复核',
    kpz_1: '可配置',
    xyphyysjjxldxz: '注意：需要配合预约时间进行联动选择',
    azydd: '安卓移动端',
    qsrydkddkhzh: '请输入韵达快递的客户账号',
    xxyfs: '消息已发送',
    kkpzj: '可开票专柜',
    dqs: '待签收',
    mkmc: '模块名称',
    qsrstkddzhmc: '请输入申通快递的账号名称',
    tzsz_1: '团长设置',
    qszxfczdytb: '请设置悬浮窗自定义图标',
    hfxx_1: '回复消息',
    ptmqlb: '拼团卖券列表',
    fmmc: '封面名称',
    azmsx: '按字母筛选',
    qtxzdtxed: '请填写最低提现额度',
    tgmc: '团购名称',
    sfzrxy: '身份证人像页',
    kdj_1: '卡等级',
    xcxmc: '小程序名称',
    shdbb: '售后单播报',
    lx_2: '连续',
    sz_4: '首重',
    szKg: '首重(kg)',
    yfYuan: '运费(元)',
    xzKg: '续重(kg)',
    xfYuan: '续费(元)',
    gtgshd: '个体工商户等',
    yhqzk: '优惠券折扣',
    tcpm: '剔除排名',
    qsrsyxz: '请输入使用须知',
    ngj: '内购价',
    qtzj: '其他组件',
    xzdlb: '选择的类别',
    mjjkxzxdsj: '买家仅可选择下单时间+预约时间之后的可配送时间段',
    qsrsstjjxsx: '请输入搜索条件进行筛选',
    szptxg: '设置普通限购',
    dhsm: '兑换说明',
    szzj_1: '设置专柜',
    bntsxscxyhhhyzkyh: '不能同时享受促销优惠和会员折扣优惠',
    yhqlqzjdwzzc: '优惠券领取组件等位置左侧',
    kztsjd: '可自提时间段',
    xtmrtsw: '如不配置，系统默认提示为：订单不满起送金额，不能配送，请返回购物车修改商品',
    ddjcxx: '订单基础信息',
    ydz_1: '已到账',
    ksjssj: '开始结束时间',
    zxkf: '在线客服',
    jgzdzw: '价格最大值为',
    mghfrqzsytjyghfnr: '每个回复人群至少要添加一个回复内容',
    qjysxxtxwz: '请将以上信息填写完整',
    gqxx: '改签信息',
    qsrppid: '请输入品牌ID',
    jxkd: '矩形宽度',
    ljsmcs: '累计扫码次数',
    dgfq: '导购发券',
    pxbkwk: '排序不可为空',
    bjbhdld: '北京百货大楼店',
    ktddzsx: '1. 添加的奖品项，可拖动调整顺序',
    xztcppsgs: '新增同城配配送公司',
    bjmgz: '编辑敏感字',
    sdtd: '手动填单',
    zz_4: '转账',
    mlrqzdznszythfnr: '每类人群最多只能设置一套回复内容',
    qxzzdsjd: '请选择置顶时间段',
    kpsdq: '可配送地区',
    jdsb: '叫单失败',
    tzt: '团状态',
    xxjfgz: '线下积分规则',
    zxfs: '执行方式',
    fksj_1: '反馈时间',
    qsrtcqdjsyzs: '请输入停车券叠加使用张数',
    fl: '分类',
    sc_3: '商城',
    h_sc_3_tip: '建议图片宽度为106px，高度不超过29px；大小不超过1M；',
    bdjb: '变动金币',
    jpglzh: '奖品概率之和',
    hz_2: '会在',
    xcxdsqdhszgxsffsqddsykygxkcs: '小程序电商渠道和设置共享三方发送渠道的剩余可用共享库存数',
    ptzf: '普通支付',
    jpzfsxz: '奖品总份数限制',
    qxxzyjlx: '请先选择一级类型',
    dzz: '地址中',
    gx_2: '共享',
    qsrbmjg: '请输入报名价格',
    kpsz: '卡片设置',
    ycrs: '用餐人数',
    xxzm: '小写字母',
    ycdj: '依次点击',
    djmcbncg: '等级名称不能超过9个字',
    qxzdjfs: '请选择叠加方式',
    fbt: '非必填',
    zz_5: '转赠',
    yeq: '余额券',
    jaqtjpdzjgljxcqjp: '将按其他奖品的中奖概率继续抽取奖品',
    xjfl: '下级分类',
    bmddxq: '报名订单详情',
    zhxzs: '总核销张数',
    qzkkzdpzyz: '强制开卡字段配置一致',
    xq_1: '先去',
    zh_1: '账号',
    xfcsdje: '消费产生的金额',
    dbzr: '对比昨日',
    jpzstb: '奖品展示图标',
    bhzd: '包含折叠',
    qsrgzhmc: '请输入公众号名称',
    bxdyhdjssj: '必须大于活动结束时间',
    qdbcxg: '确定保存修改',
    jptp: '奖品图片',
    tcpmcg: '剔除排名成功',
    khxyh: '客户协议号',
    zhczsj: '最后操作时间',
    zkzx: '钻卡专享',
    fsjgxs: '发送间隔小时',
    tssl: '提示示例',
    qx_1: '全选',
    bfsb: '部分失败',
    fcjsfg: '非沉浸式风格',
    zfyl: '支付有礼',
    qsrkdkdtcfy: '请输入可抵扣的停车费用',
    qgxptbk: '请勾选普通板块',
    xtfh: '系统复核',
    qrplxgxzdgxkcm: '确认批量修改选中的共享库存吗？',
    zzjfznbnwk: '自助积分指南不能为空',
    zsyyhdyptdhjgm: '则所有用户都以普通兑换价购买',
    pzyhqlbs: '配置优惠券礼包时',
    gk_2: '购卡',
    zzmm: '重置密码',
    qtxztsjsm: '请填写自提时间说明，建议填写格式如每天早9：00-晚18：00',
    m_2: '每',
    tjschd: '推荐商场活动',
    qxzykqctydfwdzj: '请选择已开启餐厅预订服务的专柜',
    yhzzdyh: '用户自主订阅后',
    wdyhqzssjnr: '我的优惠券展示数据内容',
    h_wdyhqzssjnr_popover: '指制作路径为【优惠券管理->制券方式：线下制券->导入三方券码】的券',
    jksssjcl: '接口上送数据处理',
    fhsjpz: '发货时间配置',
    xf_1: '续费',
    qxzsjsjd: '请选择数据时间段',
    qmdbm: '券门店编码',
    qtxjpszxzhdyhqfs: '请填写奖品设置选中后的优惠券份数，份数必须大于0',
    drgmxe: '单人购买限额',
    qtxczyy: '请填写操作原因',
    yhqxq: '优惠券详情',
    ywm: '英文名',
    ayhxg: '按用户限购',
    qdscdqmbm: '确定删除当前模板吗',
    pldssjcg: '批量定时上架成功',
    schdrl: '商场活动日历',
    xsxttz: '线上系统通知',
    xk: '新客',
    xxk: '限新客',
    zdrqbkcy: '指定人群不可参与',
    drcssx: '单日次数上限',
    yyzjqz: '用于组件去重',
    backgroundPictureLimit: '图片尺寸建议为750px * 1150px',
    dp: '单品',
    hfwz: '回复文字',
    qygdjh: '启用该等级后',
    qsrygbm: '请输入员工编码',
    mbsz: '每被收藏',
    bdmd: '变动门店',
    gmb: '个模板',
    qxzmyxzrq: '请选择每月限制日期',
    sfxsgd: '是否显示更多',
    jhmdzyhbkj: '仅黑名单中用户不可见',
    ryywqzxkf: '如有疑问请咨询客服',
    drhy: '导入会员',
    qxzdgdkdspmrxxksz: '请选择导购端开单商品默认选项卡设置',
    ddxhjf: '订单消耗积分',
    xzsppp: '选择商品品牌',
    fzdjq: '复制代金券',
    jsyy: '拒审原因',
    glyyx: '管理员邮箱',
    qsrmgz: '请输入敏感字',
    h_qsrmgz_msg: '请输入敏感字,最多120个字符',
    lb_1: '留白',
    btrgz: '被他人关注',
    yeqlbzzssyykqjedxxmd: '余额券列表中展示所有有可券金额的线下门店',
    xglqhsygzh: '修改领取和使用规则后',
    rszgq: '如设置购券',
    cjcs: '抽奖次数',
    kpje: '开票金额',
    qxzhdrq: '请选择活动日期',
    bqsj: '绑券时间',
    yyd: '已预订',
    qxzjblx: '请选择金币类型',
    bqykdthdjwfzct:
        '关闭活动后，活动不可再开启。并且已开的团活动将无法再参团。是否确认关闭该拼团活动？',
    yqrjl: '邀请人奖励',
    qsrfxcssx: '请输入分享次数上限',
    ycxhd: '营促销活动',
    hp_2: '货品',
    qxxzsymd: '请先选择适用门店',
    dlljtsy: '登录拦截提示语',
    dj_2: '等级',
    qxzsjlx: '请选择事件类型',
    qsrwb: '请输入文本',
    mdxz: '门店选择',
    qsmj: '券售卖价',
    qsrglyzh: '请输入管理员账号',
    zjxz: '中奖限制',
    gdjsz: '该等级设置',
    myhhdqjgmdslydydymdgmdsl: '每用户活动期间购买的数量应大于等于每单购买的数量',
    tcqydkgz: '停车权益抵扣规则',
    zszhyzz: '赠送中或已转赠',
    qtxljdz: '请填写链接地址',
    qzhdgzzsmbmyqks: '请在活动规则中说明避免引起客诉',
    fxan: '分享按钮',
    bdjsy: '不叠加使用',
    btdjbtgz: '不同等级，不同规则',
    gyhyyjs: '该用户已有角色',
    qsryeqxxzfewmmc: '请输入余额券线下支付二维码名称',
    bjtzdx: '报警通知对象',
    cjsfg: '沉浸式风格',
    asjmpz: '按商家码配置',
    ffsj: '发放时间',
    tswz: '提示文字',
    bqhx: '标签画像',
    yfb: '已发布',
    bld: '便利店',
    zgwclbybzszjsdydlx: '在购物车列表页不展示专柜所对应的类型',
    cksz: '查看设置',
    kh: '卡号',
    zblb: '直播列表',
    kcsbkwk: '库存数不可为空',
    qm_1: '券码',
    plszsl: '批量设置数量',
    klbxbxyygsjd: '卡类别下必须有一个上架的',
    xygkxf: '用于兑换指定商品，吸引顾客消费',
    fsy: '防损员',
    jylsh: '交易流水号',
    ztjmd: '中添加门店',
    sgbq: '手工标签',
    bjymsz: '编辑页面设置',
    hysjh: '会员手机号',
    qsrkdjmbmc: '请输入卡等级模版名称',
    jccspz: '基础参数配置',
    shfw: '生活服务',
    qtxlxyqd: '请填写连续邀请第',
    ktcghksjsctsj:
        '开团成功后开始计算成团时间，如到达成团时间（24小时）团未达到成团人数，团自动取消并退款。',
    tjyhq: '添加优惠券',
    ckzh: '查看账号',
    bjhmd: '编辑黑名单',
    kqcc: '开启场次',
    kszbl: '可设置变量',
    xxzfjylsh: '线下支付交易流水号',
    z_10: '注',
    hyyhym: '会员优惠页面',
    zfcg: '支付成功',
    qdrzdhy: '请导入指定会员',
    jz_1: '仅指',
    hpbh: '货品编号',
    yxgnzsyz: '以下功能暂时隐藏',
    qxzxzdhrq: '请选择限制兑换人群',
    parkingValueTypeExtra:
        '减免金额：该优惠券使用后减免当次缴费停车金额，减免小时：该停车券使用后减免当次缴费停车时长，全免：该停车券使用后减免当次全部停车金额',
    zbs: '则表示',
    qm_2: '全免',
    cjsm: '场景说明',
    bjnr: '笔记内容',
    czdj: '成长等级',
    dcczxx: '单次充值下限',
    sfqysmrz: '是否启用实名认证',
    zqx: '准确性',
    qdly: '渠道来源',
    mrmymfme: '每人每月免费名额',
    jsq: '结束前',
    qsrqdzdxkcs: '请输入全店最多限砍次数,请输入大于0的正整数',
    mghdxg: '每个活动限购',
    rsz: '如设置',
    wxzblb: '微信直播列表',
    hqh: '获取后',
    tnxwcgm: '天内需完成购买，超出后不可购买',
    qxzsmyhqhd: '请选择售卖优惠券活动',
    ljsz: '累计时长',
    kgxdyhqlb: '可勾选的优惠券列表',
    bhsc: '百货商场',
    zffh: '支付复核',
    hmd: '黑名单',
    fxhdcjcs: '分享获得抽奖次数',
    rpzldj: '若配置了等级',
    kjhdlb: '砍价活动列表',
    pdxx: '票档信息',
    kphqjlzhbdy: '卡片获取几率之和不等于100',
    yhxq: '用户详情',
    rpzfhsjqapzdfhsjjxfh: '如配置发货时间请按配置的发货时间进行发货',
    qtxzdynr: '请填写自定义内容',
    tbgzhcd: '同步公众号菜单',
    spze: '商品总额',
    xzdhkddrq: '选择待汇款订单日期',
    jsjsyy: '机审拒审原因',
    qszjlxw: '请设置奖励行为',
    jbdzsb: '金币的整数倍',
    zsqy: '专属权益',
    l_zsqy: '专属权益，点击',
    djmydgdyzjs: '当界面有多个打印组件时',
    bbhzzzjpl: '不包含作者自己评论',
    fdqmd: '非当前门店',
    mgrzfwhhxcxzdktsqy: '每个认证服务号或小程序最多可同时启用',
    dczt: '弹层状态',
    yhqgq: '优惠券过期',
    hksm: '回馈说明',
    zsykc: '总剩余库存',
    xc: '下称',
    tbcgh: '同步成功后',
    dzsj: '到账时间',
    xzcl: '寻找车辆',
    mrmymfbm: '每人每月免费报名/购买',
    tzxcx: '跳转小程序',
    glfxhd: '管理分销活动',
    xsyxxddsytyffxz: '如勾选线下订单，需提前进行系统对接。全选后，线上与线下订单使用同一发放限制',
    tpdx: '图片单选',
    jjysyy: '拒绝验收原因',
    yjzh: '月结账号',
    hyjfl: '会员积分栏',
    bcbtgsh: '保存并通过审核',
    yjsdsj: '预计送达时间',
    xzxggz: '新增限购规则',
    me: '面额',
    jzsdrsfqmdq: '仅展示导入三方券码的券',
    xxhxlsh: '线下核销流水号',
    zdrqkj: '指定人群可见',
    lmzfddpx: '类目支付订单排行',
    jbsb: '举报失败',
    rj: '软件',
    slbdw: '数量不得为0和负数',
    qtxyytjxzxddyz: '请填写预约条件选中项的对应值',
    qzssr: '请至少输入',
    xzdxwbjs: '需在短信尾部加上',
    zdhykdj: '指定会员卡等级',
    xzcjhd: '选择抽奖活动',
    zjshtk: '专柜售后退款',
    qxgl: '权限管理',
    pxwzfdd: '拍下未支付订单',
    h_pxwzfdd_fill: '拍下未支付订单#value#分钟内未付款，自动取消订单',
    spdcjsdc: '视频多次计算多次',
    amdwdqd: '不限制，按门店维度签到',
    qsrzffsmc: '请输入支付方式名称',
    zjyz: '总经验值',
    kd_2: '快递',
    qxztpggljmb: '请选择图片广告链接目标',
    jezdzclwxs: '金额最多支持两位小数',
    tzgl: '团长管理',
    qxxzhdbmsj: '请先选择活动报名时间',
    cjzjdy: '创建专柜店员',
    shrsj: '收货人手机',
    mrzdkfp: '默认自动开发票',
    jksqzt: '接口授权状态',
    csyhje: '初始优惠金额',
    spmrxds: '商品每日限兑数',
    gwsjyzjbgz: '购物送经验值加倍规则',
    bmdsjh: '白名单手机号',
    cxgz: '促销规则',
    bjszd: '笔记收藏得',
    shbtgdyyhzsjyh: '审核不通过的原因会展示给用户',
    jjcghdd: '借出//归还地点',
    kzsx: '扩展属性',
    syrjkj: '所有人均可见',
    l_syrjkj_1: '不限制，所有人均可见',
    yxhzbnwk: '邮箱后缀不能为空',
    jsxsj: '计算新时间',
    szftjg: '设置分摊价格',
    hdxq: '活动详情',
    gxkc: '共享库存',
    htsdbd: '后台手动绑定',
    yrx: '元任选',
    fpfs: '分配方式',
    zh_2: '组合',
    bgqje: '变更前金额',
    rpztq: '如配置提前',
    wcz: '未充值',
    tqtk: '退券退款',
    qdsykc: '渠道剩余库存',
    zspcycxyhhsyyhqh: '订单金额：指商品参与促销优惠和使用优惠券后，实际支付金额',
    ns: '女士',
    gjz: '关键字',
    jxxwbtqwsxx: '选项为必填，请完善信息',
    hfnrzdbncg: '回复内容最多不能超过',
    yypzzzyhqsfxfm: '用于配置转赠优惠券时分享封面',
    smdmylx: '什么都没有留下',
    yfyhje: '运费优惠金额',
    xyzhdjshzlbyzdrzjmd: '需要在活动结束后在列表页中导入中奖名单',
    xzpldrmb: '下载批量导入模板',
    fzhxsdjxhk: '境外账户(分账后需手动进行汇款)',
    qtxljwz: '请填写链接文字',
    mdq: '门店群',
    mrmtzdkcs: '每人每天最多可参数',
    xcxlj: '小程序链接',
    jfsj: '缴费时间',
    kjfpdddsfyfpsyzdszdbztys: '开具发票的地点是否与发票使用者的所在地不在同一省',
    fwnxf: '范围内消费',
    jlnr: '奖励内容',
    mpmc: '门票名称',
    szjfjg: '】设置积分价格',
    cfsmrzcj: '触发实名认证场景',
    lsmd: '隶属门店',
    qsrcshdcjcs: '请输入初始获得抽奖次数',
    rs_1: '人数',
    gmjs: '购买件数',
    mhbjt: '盲盒背景图',
    rsyw: '若上月为',
    xzklb_1: '新增卡类别',
    qsrsjhm: '请输入手机号码',
    sfqd: '三方渠道',
    xzzj: '新增专柜',
    symdkc: '使用门店库存',
    fzcc: '复制场次',
    hb: '环比',
    yhsjh: '用户手机号',
    kkfpzl: '可开发票种类',
    qxzzt: '请选择专题',
    hdksrq: '活动开始日期',
    khzh: '客户账号',
    qxzhd: '请选择活动',
    sqcwfxy: '申请成为分销员',
    yyr: '预约人',
    jzcyrzdgzh: '仅支持已认证的公众号',
    l_jzcyrzdgzh_1:
        '仅支持已认证的公众号，推送小程序卡片或文字链接至小程序，请先将小程序与公众号关联。',
    bjpz: '报警配置',
    tjsh: '提交审核',
    swbxw: '首位必须为',
    yhjbyhdyy: '笔记服务-用户举报用户的原因',
    jhdcjsj: '拣货单创建时间',
    sjfp: '随机分配',
    fpkl: '发票开立',
    yhzccjhtsyh: '当奖品只剩下“未中奖”时，用户再次抽奖会提示用户“奖品已发完”',
    cyrxm: '参与人姓名',
    zjxfsj: '最近消费时间',
    rdjqfmdcczsgyhzcmddqdj: '如等级区分门店，此处展示该用户注册门店当前等级',
    dgzh: '的公众号',
    jfscbq: '积分商城标签',
    hxrq: '核销日期',
    wsxxzzs: '完善信息中展示',
    dgjpdddrfjsl: '当该奖品达到当日放奖数量',
    l_dgjpdddrfjsl:
        '当该奖品达到当日放奖数量、用户达到该奖品的中奖数量限制，将不会在抽到此奖品，将按其他奖品的中奖概率继续抽取奖品。当所有奖品均达到当日放奖数量、用户达到所有奖品的中奖数量限制、用户达到中奖次数限制，只能抽中“未中奖”',
    tw: '图文',
    qxxzczzh: '请先选择操作账号',
    zdff: '自动发放',
    gjxzdmddtjzzjxx: '根据选择的门店动态加载专柜选项',
    bntsw: '不能同时为',
    bjmbxx: '编辑模板消息',
    xcxbxggzhsglgx: '小程序必须跟公众号是关联关系',
    dszqbzjs:
        '当设置全部专柜时，门店内所有专柜将统一采用所设定的配送公司规则。若针对特定专柜设置配送规则，则系统会优先道循该专柜的配送规则。全部设置时统一规则，指定设置时则道循特定规则。',
    ckzxyl: '查看装修样例',
    yhddsyjpdzjslxz: '用户达到所有奖品的中奖数量限制',
    hyzczzsqsl: '会员资产中展示券数量',
    bmdgl: '白名单管理',
    bjbch: '编辑保存后',
    cjzcs: '抽奖总次数',
    gzmn: '个字母内',
    sfz: '身份证',
    zftgfwf: '支付推广服务费',
    spje: '商品金额',
    zhqb: '组合券包',
    lj: '链接',
    scyq: '首次邀请',
    heartbeat: '心跳中',
    inviterMuchPeople: '邀请满{{stepValue}}人',
    yjkh: '月结卡号',
    xytp: '协议图片',
    tplx: '图片类型',
    dzpcj: '大转盘抽奖',
    dzqsl: '单张券数量',
    xcbk: '宣传板块',
    tygmxgljtz: '条员工码修改链接跳转',
    qxzkkxxzd: '请选择开卡信息字段',
    zffs: '支付方式',
    zdkhk: '最多可换开10次',
    yxdejfl: '已选的二级分类',
    sztxgz: '设置提现规则',
    rbgxdgyhfxdgtw:
        '如不勾选单个用户分享单个图文//视频多次仅计算一次，则同一篇文章用户分享多次算多次。',
    qsc: '请上传',
    mmebmf: '每名额报名费',
    zlyzsj: '着陆页左上角',
    h_zlyzsj_tip: '建议图片宽度为106px，高度不超过29px；大小不超过1M；',
    jkgz: '集卡规则',
    zxsq: '重新授权',
    zs_4: '赠送',
    jlsx: '奖励时效',
    spzjflssbm: '商品子级分类税收编码',
    bmkssjwyhdjssj: '报名开始时间晚于活动结束时间',
    zsyshy: '中使用时会有',
    bk_1: '绑卡',
    cj_1: '差价',
    tzdqxbtk: '团自动取消并退款',
    wlsjmdmc: '物流商家门店名称',
    hdbmsj: '活动报名时间',
    kjzkc: '砍价总库存',
    yj_4: '佣金',
    spbz: '商品不足',
    drxfjedkgz: '当日消费金额抵扣规则',
    bjmrhfnr: '编辑默认回复内容',
    cjjpsz: '抽奖奖品设置',
    jk_1: '即可',
    qsrshrxm: '请输入收货人姓名',
    jlz: '奖励值',
    zjcgzzs: '在基础规则之上',
    zjcgzzs_1: '在基础规则之上，如同时符合多个加倍规则，执行时倍数叠加执行',
    zjcgzzs_2: '在基础规则之上，如同时符合多个加倍规则，执行时取其高倍数规则执行',
    ffbm: '付费报名',
    qzqtxzddhcs: '请正确填写最多兑换次数',
    spxqyzspjrk: '商品详情页展示评价入口',
    h_spxqyzspjrk_tip_before:
        '设置“不展示”，在商品详情页不展示评价区域，及订单列表页、签收成功页也不会展示立即评价按钮。',
    mshddzfddqxsjsz: '秒杀活动待支付订单取消时间设置',
    m_3: '满',
    dkddxmjeblqtx: '抵扣订单项目金额比例请填写1-100的正整数',
    hpzl: '货品重量',
    yytj: '预约条件',
    zzsyhsjdjyz: '则赠送用户随机的经验值',
    yhsmhhfnrwszdmrhfnr:
        '用户扫码后回复内容为设置的默认回复内容，取消勾选可设置该二维码的自定义回复内容',
    chd: '传后端',
    mhjjt: '盲盒简介图',
    qxzfw: '请选择服务',
    rxzsygxkc:
        '如选择使用共享库存，活动设置10个门店参加，则用户从这10个门店购买的商品均扣减该商品的共享库存；',
    qwztxyqxdsgtjyxzdsgsl: '请完整填写页签下的申购条件与限制的申购数量',
    qsrdbkddkhbm: '请输入德邦快递的客户编码',
    bkgg: '不可更改',
    kmcbjs: '卡名称背景色',
    ljbm: '立即报名',
    dccjan_1: '多次抽奖按钮',
    moreButtonPictureExtra: '图片尺寸建议为240px * 126px',
    dwmc: '单位名称',
    fwjlss: '范围奖励时是',
    zdbkdcs: '最多帮砍的次数',
    myhxzbmcc: '每用户限制报名场次',
    wdyd: '我的预订',
    yplylhd: '月评论有礼活动',
    kzdsl: '可中的数量（瓶）',
    fpnr: '发票内容',
    wschdhdtssxsyqst: '未上传或多活动同时生效使用缺省图',
    zyhq: '张优惠券',
    ycx: '已撤销',
    qzqtxmccjxhjf: '请正确填写每次抽奖消耗积分',
    syrq: '适用人群',
    spyl: '商品预览',
    jpslbnwk: '奖品数量不能为空、不能超过9位数，并且仅支持到正整数',
    quantityErrorMessage: '奖品数量不能为空，并且仅支持到正整数',
    xffw: '消费范围',
    dzphd: '大转盘活动',
    gzhss: '公众号搜索',
    mtyslb: '茅台预售列表',
    yytcczcymdtx: '用于停车场注册页面的填写',
    zfsj: '支付时间',
    btddlfshdddlysbyz: '不同的登录方式后端的登录样式不一致',
    ztdz: '自提地址',
    sjzj: '数据专柜',
    yfqtp: '运费券图片',
    khyx: '开户银行',
    dqmm: '当前密码',
    subsiteIdsExtra: '创建二维码成功后，二维码名称为“门店名称+活动名称”',
    merchantIdsExtra: '创建二维码成功后，二维码名称为“专柜名称+活动名称”',
    qdqxfhbgbcym: '确定取消发货并关闭此页面',
    mdewmdmdcs: '门店二维码带门店参数，专柜二维码带门店、专柜参数',
    yjl: '一键连',
    zgyhqdkysjw: '则该优惠券的可以时间为',
    hdqnyqhyzc: '活动期内邀请好友注册',
    fspc: '发送频次',
    cksltjxz: '查看示例图及下载',
    sfqygsjm: '是否启用该商家码？',
    scmb: '删除模板',
    tzpz: '通知配置',
    qsrlqsm: '请输入领取说明',
    h_qsrlqsm_msg: '请输入领取说明，不能超过1000个字',
    bjmdfklx: '编辑门店反馈类型',
    rcq: '入场券',
    jzcdzzs: '仅支持1-50的正整数',
    zdcsrq: '指定出生日期',
    zxzj: '招新专柜',
    qxzygzj: '请选择一个专柜',
    qsrdjmc: '请输入等级名称',
    qsrjtkddkhmy: '请输入极兔快递的客户密钥',
    qxzksrq: '请选择开始日期',
    jzdsrxbzzjyy: '最多输入50字，需备注增减原因',
    zslx: '展示类型',
    zdzxsrq: '指定转销售日期',
    jzctx: '仅支持填写',
    xtnth: '系统内退回',
    txsj: '提现时间',
    rjfqfmd: '如积分区分门店',
    xcxm: '小程序码',
    bjmddzxpfppz: '编辑门店电子小票发票配置',
    scgz: '删除规则',
    song: '送',
    dx_3: '对象',
    qsrsjmbm: '请输入商家码编码',
    ztdxx: '自提点信息',
    cjhd_1: '创建活动',
    rgxyzxcxnfwxcxzwdwyhwxwz:
        '如果想要在小程序内访问小程序之外的网页或微信文章，需要在小程序后台配置业务域名或关联公众号才能访问。',
    xzxzmgyhslcss: '选择限制每个用户数量次数时',
    xspdxx: '显示票档信息',
    zyxsc: '只允许上传',
    szsj: '设置售价',
    mcmgspbmddsl: '每场每个商品白名单的数量',
    rygy: '日一个月',
    xphysjh: '小票会员手机号',
    shcyzg: '审核参与资格',
    lhj: '老虎机',
    ckdgzh: '查看导购账号',
    wlgs: '物流公司',
    xjyl: '详见样例',
    fxxcx: '分享小程序',
    qm_3: '全名',
    qxzfwrq: '请选择服务日期',
    mqycc: '目前已超出',
    scfbsj: '首次发布时间',
    zxffcg: '重新发放成功',
    pszlmzj: '配送重量每增加',
    nmxzhddkshjss: '那么选择活动的开始和结束时',
    jzzfbddgtw: '即作者发布的单个图文',
    jzszqfs: '仅展示制券方式->线上券',
    tjjp: '添加奖品',
    jycs: '交易次数',
    smzcs: '扫码总次数',
    wahpjjfz: '文案后拼接积分值',
    rhddjb: '日获得的金币',
    qsrmjje: '请输入门槛金额',
    yhgzqdly: '用户关注渠道来源',
    bxy: '本协议',
    yhkksjzdtcbtg: '用户开卡时将自动填充并提供',
    mdd: '每达到',
    jysyhdxzm: '建议使用含大写字母',
    sfkzz: '是否可转赠',
    ejfklxbnzf: '二级反馈类型不能重复',
    zjszxtjddshqrxgxx: '在结算中心提交订单时会确认相关信息',
    ddjl: '订单奖励',
    tyhsx: '天以后失效',
    ejflzkzt: '二级分类展开状态',
    hfr: '回复人',
    mdpsgspz: '门店配送公司配置',
    wdyhq: '我的优惠券',
    sz_5: '时在',
    zfsqsz: '开启后用户在c端首次使用该支付方式时，需确认用户协议后可使用该支付方式。',
    h_zfsqsz_tip: '支付授权设置',
    twynmcdj: '图文页内名称点击',
    gs_1: '公司',
    gzcdcwxx: '个主菜单错误信息',
    ftmt: '飞天茅台',
    yqr: '邀请人',
    sykmzs: '剩余可卖总数',
    qdffzt: '渠道发放状态',
    qsrjlx: '请输入奖励项，最少填一项',
    rgxylkckxg: '如果需要立刻查看效果',
    rgxylkckxg_1: '请取消关注后重新关注',
    orderNum: '共{{total}}个订单',
    contentNum: '共{{total}}个',
    hdbchjsdkc:
        '活动保存后将锁定库存，库存用于社区团购商品可卖数，如社区团购独立库存为零，则无可卖数量',
    qscbqtp: '请上传标签图片',
    qsrzhym: '请输入租户域名',
    bdz_1: '本单中',
    rxsh: '如需售后',
    tkhjlbhs: '退款后奖励不回收',
    yhddzjcsxz: '用户达到中奖次数限制',
    sbt: '时必填',
    qtxssflbm: '请填写税收分类编码',
    jfsydxgnydfhbxyjqtsygfdyq: '甲方使用短信功能应当符合本协议及其他使用规范的要求',
    wdcjm: '我的抽奖码',
    gj: '工具',
    bkxzdqzzbgzz: '不可选择当前正在变更组织',
    sfqyq: '三方券页签',
    wbkjs: '外部开奖时',
    rzfs: '认证方式',
    zckyscddhm: '中查看已生成的兑换码',
    ty_3: '停用',
    glmd: '关联门店',
    glmdzg: '关联门店//专柜',
    xysh: '需要审核',
    mbmc: '模板名称',
    al: '案例',
    lqsj_1: '领取时间',
    kydrq_1: '可预订日期',
    qxzhxzj: '请选择核销专柜',
    zhswsydjl: '只回收未使用的奖励',
    syff: '使用方法',
    csqsjjrwxgzhhtscbfbqydmb: '此时请商家进入微信公众号后台删除部分不启用的模板',
    zdrqsj: '指定日期时间',
    tnwzfdddyh: '新客指自注册日90天内无支付订单的用户',
    zwjztxxydrdxx: '1.在文件中填写需要导入的信息（样式如图所示），然后上传该文件',
    gptyqzjyhdflzr: '该平台有权追究用户的法律责任',
    xzjh: '新增计划',
    xzhtjl_1: '选择话题激励',
    hybq: '会员标签',
    sfjc: '是否借出',
    qsc_1: '请删除',
    yqhy: '邀请好友',
    fpjl: '发票记录',
    qxsq_1: '请先授权',
    gtbxszfxbkzdbjdzsf: '该图标显示在发现板块置顶笔记的左上方',
    tycpbdhybd: '同一车牌被多会员绑定',
    htxz: '话题选择',
    tyyhq: '停用优惠券',
    confirmStopCoupon: '确认停用会员{{mobile}}的【{{.name}}】优惠券，券号【{{couponNo}}】?',
    fwgj: '服务工具',
    dczdky: '单次最大可约',
    sgxztj: '申购限制条件',
    qxzzb: '请选择坐标',
    mdzt: '门店自提',
    spph: '商品偏好',
    lcdjs: '离场倒计时',
    qtxxfje: '请填写消费金额',
    bzzd: '备注最多',
    sppbl: '商品瀑布流',
    bk_2: '包括',
    zxzgdsxjcc: '则需在高德上新建车场',
    xz_3: '续重',
    yhznbmtygcc: '用户只能报名同一个场次',
    qzdjsxxtsjw: '且字段及顺序相同时计为',
    zy_2: '自由',
    dtxsjksdts: '到提醒时间可收到提示',
    txcgjl: '提现成功记录',
    yhjbbjdyy: '笔记服务-用户举报笔记的原因',
    jzclwxs: '仅支持两位小数',
    tkclxx: '退款处理信息',
    hdbz: '活动备注',
    bjewm: '编辑二维码',
    rdcdrhfgzqdrdzjm: '如多次导入会覆盖之前导入的中奖码',
    l_rdcdrhfgzqdrdzjm_1: '请正确填写中奖码，如多次导入会覆盖之前导入的中奖码',
    zbxzdyjzzjg: '总部下最多一级组织机构',
    qtxshbtgdyy: '请填写审核不通过的原因',
    ctwt: '冲突问题',
    d_6: '点',
    xgmbym: '修改目标页面',
    htyxq: '话题有效期',
    dranzt: '导入按钮状态',
    qycz: '启用操作',
    tb_1: '图标',
    qsrdhmc: '请输入兑换名称',
    btjg: '不同价格',
    hdqjmyhzdsg: '活动期间每用户最多申购',
    qszxg: '请慎重修改',
    ksnfbsdqnzksrqdsnf: '开始年份不是当前年则开始日期带上年份',
    qxzcc: '请选择场次',
    qsrcdwz: '请输入菜单网址',
    tksfh: '天开始发货',
    qxztp: '请选择图片',
    qsrmrmtffcs: '请输入每人每天发放次数',
    ptxx: '拼团信息',
    zasbgzgzh: '长按识别关注公众号',
    pthd: '拼团活动',
    gmshjtsy: '购买时会给提示语',
    klgwqhw: '快来跟我抢好物～',
    qsrfhr: '请输入复核人',
    sfqrscyxzsjm: '是否确认删除已选择商家码',
    mfx: '每分享',
    gme: '个名额',
    lsmb: '历史模板',
    yfdzrxzhmz: '乙方的责任限制和免责',
    kqsb: '扣券失败',
    wzmc: '位置名称',
    spzlsdsh: '商品资料手动审核',
    djhbwls: '点击后变为蓝色',
    xqdyhs: '新签到用户数',
    ydscfs: '运单生成方式',
    zxkp: '重新开票',
    djzt_1: '等级状态',
    sjpszldyqszl: '实际配送重量大于起送重量',
    xtsz: '系统设置',
    uploadIntro5: '4.请正确填写模板内容，如重新导入，则会覆盖之前导入的内容',
    rxgbqtqjcycxhdssfyyydydj: '如需关闭请提前检查营促销活动上是否有引用对应等级',
    ljjl: '累计奖励',
    qymc: '权益名称',
    ydk_1: '已打开',
    xgjssj: '限购结束时间',
    bjfxd: '笔记分享得',
    yqhyyqcyb: '邀请好友一起参与吧',
    qmbh: '券码编号',
    qryjfkclwc: '确认意见反馈处理完成?',
    hdjsbyqrhyqrdbhddjl: '活动结束被邀请人和邀请人都不会得到奖励，请在活动规则中说明避免引起客诉；',
    ccsxgyhzcmddqdj: '如等级区分门店，此处筛选该用户注册门店当前等级',
    tymjj: '团员买家价',
    qsrhync: '请输入会员昵称',
    tjxjjs: '添加下级角色',
    jlxw: '奖励行为',
    ggbtbncg: '广告标题不能超过',
    pltjsp: '批量添加商品',
    jjgq: '即将过期',
    hfsxsj: '回复失效时间',
    mrbbj: '默认不保价',
    cwhysj: '成为会员时间',
    zjdd: '租借地点',
    lbffsl: '礼包发放数量',
    kq_1: '快去',
    plqy: '批量启用',
    qtxxzhdz: '请填写选中后的值',
    plxgszkc: '批量修改设置库存',
    xzmgz: '新增敏感字',
    bxdyhdkssj: '必须大于活动开始时间',
    xdkxqy: '下单可享权益',
    dhf: '待回复',
    zdffwkjsff: '自动发放为开奖时发放，人工发放必须由运营人员操作，才能发放奖励',
    bt: '必填',
    sqz: '授权中',
    hbzje: '红包总金额',
    zjjskssj: '最近计算开始时间',
    zj_5: '直角',
    qjsyxq: '券结束有效期',
    kpsqy: '可配送区域',
    qsrmddz: '请输入门店地址',
    sjje_1: '实缴金额',
    jfdyw: '甲方的义务',
    bx_2: '必须',
    fhsz: '发货设置',
    jfxyfbzssglqyygazbxyydsyyfrjjdxfsfw:
        '甲方向乙方保证上述关联企业严格按照本协议约定使用乙方软件及短信发送服务',
    jb_2: '解绑',
    qsrjfsm: '请输入积分说明',
    tksj: '退款时间',
    qxzxnyhqsp: '请选择虚拟优惠券商品',
    hdwkss: '活动未开始时，添加的奖品项，可拖动调整顺序',
    ts: '天数',
    mdspxlpx: '门店商品销量排行',
    yxd: '已下单',
    zhkjyh: '只会扣减用户',
    xj_2: '小计',
    yjh: '已拣货',
    bt_1: '标题',
    ktsqxy: '开通授权协议',
    szxpzyfgzdzj: '设置需配置运费规则的专柜',
    zsz_1: '赠送中',
    transfered: '转出',
    rzsj: '认证时间',
    dngjzk: '的内购价折扣',
    gynzf: '5个以内字符',
    gynzfTip: '5个以内字符（1个汉字算1个字符）',
    cqjg: '抽签结果',
    sy_3: '首页',
    gzcdcwxx_1: '个子菜单错误信息',
    wsz_1: '未设置',
    sfjd: '是否解冻',
    zssybnxyzxdkdw: '最少使用不能小于最小抵扣单位',
    gjtqzsgxygjlx: '保存失败，第{{index}}个阶梯请至少勾选一个奖励项',
    cz_2: '差重',
    my_1: '密钥',
    cjm: '抽奖码',
    yhjkljl: '卡片社交，分享集卡成果，口碑传播',
    dsxjgbcg: '定时下架关闭成功',
    jfdhcjcs: '积分兑换抽奖次数',
    kbsj: '开播时间',
    spsqh: '商品售罄后',
    kdbl: '宽度比例',
    grjj: '个人简介',
    gqhccxx: '改签后场次信息',
    rcsj: '入场时间',
    jbze: '金币总额',
    gbcg: '关闭成功',
    plxzzjm: '批量下载专柜码',
    zcwa: '资产文案',
    h_zcwa_msg: '请输入资产文案，不超过20个字',
    h_zcwa_placeholder: '请填写资产文案。例如：我的储值卡',
    xcxwz: '小程序网址',
    yhsq: '用户申请',
    sftqmzze: '三方退券面值总额',
    qtxzqdyyrsjh: '请填写正确的预约人手机号',
    xzydjl: '新增预订记录',
    tpdxcg: '图片大小超过',
    yd_1: '约定',
    sjdkgd: '手机打开高德',
    wbddh: '外部订单号',
    zdkhk_1: '最大可换开',
    jfh: '缴费后',
    aqbnrjs: '按全部内容计算',
    tysp: '同一商品',
    ptyhzq: '普通用户中签',
    zdyyjbl: '佣金规则：自定义佣金比例',
    czlx: '操作类型',
    yshd: '预售活动',
    hfnr: '回复内容',
    couponInfo2: '券信息',
    yxdsjfl: '已选的三级分类',
    rwsjn: '任务时间内',
    yjs_1: '元计算',
    qzqtxfsdxd: '请正确填写发送对象的Openid或手机号，多个用","隔开。',
    sxkssj: '生效开始时间',
    gkssfzsskmd: '购卡时是否展示售卡门店',
    ts_1: '天时',
    sdshgz: '手动审核规则',
    szxggz: '设置限购规则',
    jj_1: '降级',
    jyzsxygdyxx: '经验值上限应该大于下限',
    qsrtzmc: '请输入团长名称',
    hzqy: '合作企业',
    xssp: '销售商品',
    zcs: '中查收',
    bchjljsx: '确定保存修改？保存后将立即生效',
    qdxgm: '确定修改吗',
    bjj: '不降级',
    ssq: '省市区',
    zf_2: '转发',
    xzyhqs: '选择优惠券时',
    lbt: '轮播图',
    zdyys: '自定义颜色',
    sjyz_1: '送经验值',
    ytcf: '元停车费',
    hxgd: '横向滚动',
    gzsj: '最近关注时间',
    bgxbnsy: '不勾选不能使用',
    ksjfsj: '快速积分商家',
    tjsmrwtxsl: '添加时默认为填写数量',
    xzdrppmb: '下载导入品牌模板',
    yxyg: '一行一个',
    sxbj: '所需本金',
    xxtp: '消息图片',
    fpsqsj: '发票申请时间',
    pltj: '批量添加',
    ming: '名',
    topdi: 'TOP第',
    hdyxjl: '获得以下奖励',
    gzhdyxjl: '关注，获得以下奖励',
    jcjbgz: '基础加倍规则',
    sbh: '设备号',
    dlhyh: '登录后用户',
    jssz: '结算设置',
    jzczzs: '仅支持正整数',
    jyn: '近一年',
    qsrqymc: '请输入企业名称',
    cgtkje: '成功退款金额',
    jfdk: '积分抵扣',
    mccxzrs: '每场次限制人数',
    syjcyf: 'km，使用基础运费',
    ygz: '已关注',
    yjwsy: '佣金未使用',
    bmjg: '报名价格',
    anmc: '按钮名称',
    zsbf: '整数部分',
    jpzjglbzq: '奖品中奖概率不正确，所有奖品概率之和应为100%',
    cjjgtz: '抽奖结果通知',
    sx_3: '失效',
    bxzzjzbgzsyysxmdxsyzj: '不选择专柜指本规则适用于所选门店下所有专柜',
    dgmdmfq: '导购面对面发券',
    fzlj: '复制路径',
    qtxyyrsjh: '请填写预约人手机号',
    cls: '查历史',
    yhzcwchmrjydhydj: '用户注册完成后默认给予的会员等级',
    qymd: '启用门店',
    yyzwmc: '原因中文名称',
    zpzptxg: '再配置普通限购',
    jfjllj: '积分记录链接',
    dqr: '待确认',
    jfhyeq: '积分换余额券',
    wxz: '无限制',
    tpgs: '图片格式',
    spksq: '商品开售前',
    tqzq: '提前周期',
    ljmbbnwk: '链接目标不能为空',
    mdbnwk: '门店不能为空',
    tzwy: '跳转网页',
    scbjbz: '删除笔记备注',
    kqdccrqbkcchdrqfw: '开启的场次日期不可超出活动日期范围，请删除/禁用对应场次，或者修改活动日期',
    mrkth: '默认开团后24小时',
    dhmc: '导航名称',
    czmd: '出账门店',
    xzdhx: '新增导航项',
    qsryfkssflbm: '请输入预付卡税收分类编码',
    sjzje: '实际总金额',
    spxse: '商品销售额',
    yyjssjyzyhdyqgkssj: '预约结束时间应早于或等于抢购开始时间',
    cy: '参与',
    zydpxykq: '值越大排序越靠前',
    value: '值',
    wb_1: '文本',
    zhszsrk: '组合数字输入框',
    ysrz: '延时入账',
    swmrpsgs: '设为默认配送公司',
    bjygm: '编辑员工码',
    lsewmysccghzd: '临时二维码以生成成功后最多',
    qxzdssj: '请选择定时时间',
    szhyqy: '设置会员权益',
    qsrmmje: '请输入每满金额',
    xzkjhd: '新增砍价活动',
    gmdgmdspjkjgspdgxkc: '个门店购买的商品均扣减该商品的共享库存',
    yhdjsz: '优惠叠加设置',
    tjklb: '添加卡类别',
    jkhd: '集卡活动',
    qxzspsllx: '请选择商品税率类型',
    upload1: '置顶位置1图标',
    upload2: '置顶位置2图标',
    upload2Extra: '建议尺寸: 123px * 60px',
    hdzj_1: '活动总计',
    dmbm: '大码编码',
    btlmdxxtsdnrgjzlycy: '不同类目的消息推送的内容关键字略有差异',
    wbkj: '外部开奖',
    xzqy: '新增权益',
    zfxx: '支付信息',
    qsrwzdh: '请输入完整电话',
    mqhd: '卖券活动',
    xxdd: '线下订单',
    sgtp: '手工填票',
    kqanzt: '开启按钮状态',
    gb: '关闭',
    qxzz: '且限制在',
    mdspzfjepx: '门店商品支付金额排行',
    bcc: '不超出',
    wgdyxqcxbbtx: '无固定有效期此项不必填写',
    tghtgbgddhsscdqxgbdxyrgshtg: '通过后台关闭该订单后所生成的取消关闭单需要人工审核通过',
    ejflsj: '二级分类数据',
    ztpsddfhhzbhzdfh: '自提配送订单复核后则不会自动发货',
    hykzk: '会员卡折扣',
    qsrztkddmm: '请输入中通快递的密码',
    zsxzyghd: '至少选择一个活动',
    ckxq: '查看详情',
    hxjylsh: '核销交易流水号',
    qrsh: '确认收货',
    js_4: '角色',
    cg_1: '超过',
    nydjhdjhd: '您有待拣货的拣货单',
    kzhyzxck: '已放入你的账户，可在会员中心查看',
    qxzhfnrrq: '请选择回复内容人群',
    qtjjp: '请添加奖品',
    sjmsccg: '商家码删除成功',
    myhbmme: '每用户报名名额',
    shbtgdyhhzdtk: '审核不通过的用户会自动退款',
    yczk: '元储值卡',
    anwz: '按钮文字',
    rszbdkq: '如设置绑定卡券',
    yhzdlq: '用户主动领取',
    fwddmc: '服务地点名称',
    bj_1: '笔记',
    tjxx: '添加选项',
    qxszhdrqhhdsj: '请先设置活动日期和活动时间',
    sxfjyyyzjjszddjs: '手续费将用于与专柜结算账单的计算',
    dysys: '店员扫一扫',
    yjhgzjfbz: '原计划该组件分别在',
    mplhd: '卖票类活动',
    pxzxtzzxcjdykqzs: '排序值相同则最新创建的越靠前展示',
    l_pxzxtzzxcjdykqzs_1: '排序值相同则最新创建的越靠前展示',
    xzyy: '新增原因',
    qxqyzs: '券详情页展示',
    bxyjszgflys: '本协议将受中国法律约束',
    rszjkczsr: '若是增加库存则输入',
    qsrskr: '请输入收款人',
    jsn: '近三年',
    hdyqsx: '后端要求蛇形',
    ykfx: '元可分享',
    ljkhs: '累计客户数',
    nhmyglmqx: '您还没有该栏目权限',
    clickOpenV: '点击开启声音',
    clickCloseV: '点击关闭声音',
    qbspbksxmdhzjdsysp:
        '全部商品包括所选门店和专柜的所有商品；若设置部分商品不参加，则默认其余商品可用该券',
    syyqbmdscdjhm: '适用于全部门店生成的聚合码',
    sydjdhyknhfsdjbd: '上一等级的会员可能会发生等级变动',
    yxyhz: '允许用户在',
    yhqbmc: '优惠券包名称',
    couponAndBag: '优惠券/优惠券包名称',
    dnrc: '电脑蠕虫',
    qsrzhhdym: '请输入租户回调域名',
    qshdrqsj: '缺少活动日期时间',
    rsx_1: '日失效',
    ccxx: '场次信息',
    xcxscywcjdd: '小程序商城业务场景订单',
    qszpdsl: '请设置票档数量',
    fwsz: '范围设置',
    qxzhykdj: '请选择会员卡等级',
    mjzjsykyxzpssj: '开启后，买家在结算页可以选择配送时间，不开启则默认展示"请尽快自提"',
    jhlkjdqzzsxdcdtbzht: '将会立刻将当前正在生效的菜单同步至后台',
    jhlkjdqzzsxdcdtbzht_1: '同步菜单后',
    jbcsblxdltsxydxdysydts: '保存失败，连续登陆天数下一档需大于上一档天数',
    zmtz: '招募团长',
    scxgsj: '上次修改时间',
    qsrcph: '请输入车牌号',
    bjkjhd: '编辑砍价活动',
    scdlxz: '商场导览选择',
    dddx: '到店动线',
    pldrspsl: '批量导入商品税率',
    jxzdjhjljjs: '进行中的计划将立即结束',
    cjqdtgm: '创建渠道推广码',
    xykqxcxdyxxgn: '需要开启小程序订阅消息功能',
    fh_1: '发货',
    wpztzghj: '未配置团长供货价',
    plczxfdczs: '批量操作悬浮弹层展示',
    wsyddjnfx: '实际支付的金额（含运费），0为所有订单均能分享',
    xgsb: '修改失败',
    mdshspspx: '门店售后商品数排行',
    tjzhcg: '添加租户成功',
    ccjsq: '场次结束前',
    xsgz: '线上规则',
    dgfqgjmdxzhzgjzjxzbndwk: '导购发券根据门店选择或者根据专柜选择不能都为空',
    qsrxcx: '请输入小程序',
    hthx: '后台核销',
    jwzh: '境外账户',
    zzszyfp: '增值税专用发票',
    djjxpz: '点击进行配置',
    dhd: '的活动',
    sfzh: '身份证号',
    qssj: '签收时间',
    sqksjf: '商圈快速积分',
    sykc: '剩余库存',
    hl: '会龄',
    ydzsbje: '需确保每人最少可领取1元的整数倍金额',
    ycxtz: '营促销通知',
    xyhjl: '新用户奖励',
    hcqy: '互斥取优',
    cqmydzqjhyd: '抽签码越多中签机会越大',
    grxgsqsrzzs: '个人限购数请输入正整数',
    cxzhj: '促销折后价',
    mpbjjbszs: '每篇笔记均被收藏数',
    dgpl: '单个评论',
    tgjk: '推广监控ID',
    hyjf: '会员积分',
    bfdq: '[部分地区]',
    klbbnzf: '卡类别不能重复',
    fillInAppid: '请填写appid',
    zs_6: '重试',
    zhmc: '账号名称',
    qtxqmc: '请填写券名称',
    hmdrybcyjs: '黑名单人员不参与计算，白名单人员优先参与计算，最多支持到增加10轮',
    qtxdydy: '请填写大于0的正整数',
    qtxdydydzzs: '请填写大于等于0的正整数',
    qsrcckfdh: '请输入车场客服电话',
    hx: '核销',
    xxnr: '消息内容',
    ly: '来源',
    zm: '张满',
    dz_2: '点赞',
    jdzhcyghddhysx: '编辑保存后，仅对之后参与该活动的会员生效，对之前已参与的客户无法生效',
    mrds: '默认都是',
    qxzxyplscdpd: '请选择需要批量删除的票档',
    yhzbh: '用户在百货',
    wdfp: '我的发票',
    xpzh: '小票字号',
    xyxdjfpxt: '需要先对接发票系统',
    xxgzfbzyhxs: '线下规则分别指用户线上',
    sf_1: '是否',
    xxwbtx: '选项为必填项',
    ssfl: '所属分类',
    spxgbj: '商品相关报警',
    zhjsksytcjfhxfjfdk:
        '租户结算可使用停车积分或消费积分抵扣，使用什么类型积分抵扣，这里就展示对应分值',
    ckgd: '查看更多',
    gxnsgcgl: '恭喜您申购成功了',
    yyqghd: '预约抢购活动',
    hysf: '会员身份',
    fhwtgyybb: '复核未通过语音播报',
    hdfh: '合单发货',
    yqwgmjswfqgmzg: '逾期未购买将视为放弃购买资格',
    qwztxjgxx: '请完整填写价格信息',
    jfdzsb: '积分的整数倍',
    qzqtxsfqmqbkzf: '4.请正确填写三方券码且不可重复，若重复可能会导致发多次',
    sffqqdtj: '三方发券渠道统计',
    schdlb: '商场活动列表',
    yeqsz: '余额券设置',
    mrfhydfs: '默认发货运单方式',
    tcjfsm: '停车缴费说明',
    xzdzzzjbhzdyzz: '选中的组织子级包含自定义组织，不可再变更到自定义组织下',
    dkh: '待开户',
    jfscy: '积分商城页',
    qxzxb: '请选择性别',
    zmrqyspcj: '则默认其余商品参加',
    qj_2: '[全境]',
    zfssmzfyeq: '支付时扫码支付余额券',
    mrmtff: '每人每天发放',
    lmspzfjepx: '类目商品支付金额排行',
    ysgcg: '已申购成功',
    hzrq: '绘制热区',
    djkmbnwk: '等级卡面不能为空',
    yb_2: '一般',
    fxyxm: '分销员姓名',
    zqxz: '周期限制',
    qylx: '权益类型',
    zffssfrz: '支付方式身份认证',
    h_zffssfrz_tip:
        '开启后用户在c端首次使用该支付方式时，需确认用户姓名和身份证保持一致，才可使用该支付方式。身份认证需为付费接口，如需此功能请联系客户经理',
    pblzjjktjzzdwzqzntj: '瀑布流组件仅可添加在置底位置且只能添加',
    gdyszbzcpz: '固定样式暂不支持配置',
    bqlx: '标签类型',
    bfs: '不发送',
    yxkp: '允许开票',
    zdyxddspyqbyfh: '针对已下单的商品有权不予发货',
    fxhydjl: '分享好友得奖励',
    qxzydbdbq: '请选择要打标的标签',
    gzhqxjqqsb: '公众号权限集请求失败',
    fzfbl: '分账方比例',
    yhdl: '用户登录',
    qsrshrsjh: '请输入收货人手机号',
    zjjskc: '增加/减少库存',
    xy_2: '小于',
    dccxsj: '导出查询数据',
    qsrsl_1: '请输入数量',
    hdlb: '活动列表',
    xzhmd: '新增黑名单',
    introductionRule: '备注最多400个字符',
    shfs: '审核方式',
    rxtpdyhweysd: '若系统判断用户为恶意刷单',
    kscewm: '可生成二维码',
    zyhxzztsjdznxz: '则用户选择自提时间段只能选择',
    qszzl: '请设置重量',
    xzczjl: '新增创作激励',
    ygm: '员工码',
    alreadyProducePageTpe: '已生成的{{pageType}}同时修改为该页面',
    newProducePageType: '新生成的{{pageType}}按修改后的链接类型跳转',
    xdzfsjl: '下单支付送奖励',
    pictureExtra: '图片尺寸建议为532px * 282px',
    lj_1: '累计',
    xxy: '选项一',
    wfz: '未封账',
    ztsjsm: '自提时间说明',
    jfsm_1: '积分说明',
    drgz: '导入规则',
    bmkssjbnwybmjssj: '报名开始时间不能晚于报名结束时间',
    bdsj: '绑定时间',
    ye: '余额',
    xsxxsy: '线上线下使用',
    bjschdy: '编辑商城活动页',
    hdms_1: '活动模式',
    dt_1: '代替',
    qyxx: '区域信息',
    sfzghy: '身份证国徽页',
    scdlyhs: '首次登录用户数(小程序)',
    cc_2: '车场',
    tzdsfkp: '跳转到三方开票',
    jslb: '角色列表',
    fhsj: '发货时间',
    gsdtp: '格式的图片',
    qsrtcqmc: '请输入停车券名称',
    parkCouponRequired: '请输入停车券名称，不超过30个字',
    cfbtsj: '触发不同事件，获得不同奖励',
    dlhyy: '登录欢迎语',
    h_dlhyy_placeholder: '如：手机号登录//注册，最多10个字符',
    h_dlhyy_default: '手机号登陆//注册',
    qxzhzqyhzlbzcz: '请新增合作企业后在列表中操作',
    hhtbj: '含话题笔记',
    xsnmzptrs: '小时内满足拼团人数，成团',
    groupBuyingTimeFill: '开团后#value#小时内满足拼团人数，成团',
    hfsx: '回复时效',
    sfqm: '三方券码',
    ssf: '诉讼费',
    xgmm: '修改密码',
    mrmtkcy: '没人每天可参与',
    xmmqr: '新密码确认',
    fpdm: '发票代码',
    gzhxtdmb: '公众号相同的模板',
    sfzcsgtp: '是否支持手工填票',
    qxxzygzzjg: '请先选择一个组织机构',
    glyhzwthsj: '过了用户最晚提货时间（或用户选择的提货时间）N天后未提货，订单自动关闭，自动退款',
    zddbh: '主订单编号',
    xclhd: '宣传类活动',
    zdyh: '指定用户',
    canGet: '可获得',
    scdlyhewkhdcjcs: '首次登录用户额外可获得抽奖次数',
    tcyq: '停车用券',
    dbxfcsdje: '单笔消费产生的金额',
    xxzzdqbzchxqdw: '线下制作的券不支持核销渠道为「线上核销」',
    qsrdyZero: '请输入大于0,小于9999的正整数',
    yl_1: '音乐',
    qtxjf: '请填写积分',
    qsryhsjh: '请输入用户手机号',
    sq_1: '授权',
    ztsjpz: '自提时间配置',
    jytp: '建议图片',
    yemc: '余额名称',
    h_yemc_msg: '请输入余额名称，不超过20个字',
    h_yemc_placeholder: '请填写余额名称。例如：当前余额',
    jxsjqdyh: '将显示给前端用户',
    djkmjy: '等级卡面建议',
    nhd: '年后的',
    bdjwxfhfw: '不对接微信发货服务',
    jltj: '奖励条件',
    rhdyxjl: '人获得以下奖励',
    xxe: '选项二',
    trdwzjzszyqylhdyz: '填入的文字将展示在邀请有礼活动页中',
    mt: '明天',
    qxzzzjg: '请选择组织机构',
    zdwgz: '最短五个字',
    ljjlss: '累计奖励时是',
    zjbnwk: '专柜不能为空',
    jmxsbkwk: '减免小时不可为空，并且仅支持到小数点后两位',
    xmmbnyzqdmmxt: '新密码不能与之前的密码相同',
    btxzbxz: '不填写则不限制',
    zjsjmkpxxm: '专柜商家码开票信息命',
    hgz: '或规则',
    zzsdzzyfp: '增值税电子专用发票',
    qsrhddd: '请输入活动地点',
    yqrzs: '邀请人总数',
    yjfh: '以及符号',
    wjygq: '处理完成（文件已过期）',
    fwjl: '范围奖励',
    p: '瓶',
    yhpmcyhd: '用户凭码参与活动',
    bbzbwfzhrmghgflfgyjdnjyflysldqtrhwj:
        '并保证不违反中华人民共和国法律法规以及对您具有法律约束力的其他任何文件',
    tgmd: '推广门店',
    wlgsmc: '物流公司名称',
    xfcs: '消费次数',
    tcp: '同城配',
    bjnzhk: '编辑年终回馈',
    sgcg: '申购成功',
    yyrxm: '预约人姓名',
    jbzq: '解绑周期',
    qsrhfnr: '请输入回复内容',
    fptzwa: '发票跳转文案',
    qxthxdrzh: '请先退回下单人账户',
    xzbb: '下载报表',
    xsztnr: '显示专题内容',
    hygz: '欢迎关注',
    jsfs: '结算方式',
    qxzsfyeq: '请选择三方余额券',
    sfqrtbgzhnr: '是否确认同步公众号内容',
    sfqrtbgzhnr_1: '是否确认同步公众号内容，同步成功后，保存生效',
    tcqsxsjndmryhsd:
        '停车券生效时间内的每日优惠时段（如：优惠时段10:00-22:00，其他时间不能享受优惠），不填写则不限制',
    jzcohzzs: '仅支持0和正整数',
    xzswxt: '选择税务系统',
    gzhqy: '公众号迁移',
    qrsfscgtp: '确认是否删除该图片',
    qsrtgjkmc: '请输入推广监控名称',
    ewmlx: '二维码类型',
    y_6: '由',
    jfqlwa: '积分清零文案',
    js_5: '件数',
    qjjyztxwz: '请将经验值填写完整',
    n_2: '男',
    qsrplrsjh: '请输入评论人手机号',
    qtjd: '请添加第',
    qsrygxm: '请输入员工姓名',
    qsrmtewhdcjcs: '请输入每天额外获得抽奖次数',
    zcyhbxmyxfcsdd: '如配置3次以上，则从用户必须每月消费次数达到3次及以上才可预约成功',
    yxzj: '营销组件',
    kyjf: '可用积分',
    slbkdyybm: '数量不可低于已报名/购买数量',
    whkq: '维护客群',
    hzfb: '或支付宝',
    tpy: '图片一',
    djt: '档阶梯',
    gbyxqgzh: '改变有效期规则后',
    l_gbyxqgzh: '注意，改变有效期规则后，已经产生的积分还按原有效期处理',
    kfkm: '卡付款码',
    pzhzszwdyhqb:
        '图片尺寸建议为宽600px*高600px。配置后展示在我的优惠券包、优惠券领取组件等位置左侧。',
    pzyhqzjhzszyhqzjzc: '配置优惠券组件后展示在优惠券组件左侧',
    qzqddjemjdydsz: '请正确订单金额门槛对应的数值',
    xq_2: '需求',
    zxj: '执行价',
    cxhdlbcx: '促销活动列表查询',
    ftjzhbdysmjg: '分摊价总和不等于售卖价格',
    tkcg: '退款成功',
    hyfpsz: '会员发票设置',
    dzytzyrzdct: '点只有团长一人自动成团',
    azxsdfg: '按照小数点分隔',
    tkyhq: '退款优惠券',
    pldrzj: '批量导入专柜',
    dcsbqd: '导出失败清单',
    ddlx: '订单类型',
    lmzfpx: '类目支付排行',
    dxgn: '短信功能',
    smcs: '扫码次数',
    yxdfxdshpyxxdssp: '有些地方选的是货品有些选的是商品',
    qxzpp: '请选择品牌',
    tps: '图片三',
    dc: '导出',
    czms: '操作描述',
    sbjljq: '双倍奖励给前',
    zdjfjlbnxyzxjfjl: '最大积分奖励不能小于最小积分奖励!',
    xctp: '宣传图片',
    bxysmrz: '不需要实名认证',
    lbnr: '礼包内容',
    zszdyqljpmc: '展示自定义券类奖品名称',
    qsrfxzlwa: '请输入分享助力文案',
    kpnrzsxzyz: '开票内容至少选择一种',
    rfh: '日发货',
    yff: '已发放',
    zjdlpzyfgz: '专柜独立配置运费规则，运费规则独立计算',
    h_zjdlpzyfgz_tip:
        '如用户购买3家专柜商品，专柜1、专柜2、专柜3，则收取运费=专柜1+专柜2+专柜3的运费的和；先去',
    ksgtx: '可手工填写',
    kd_3: '可订',
    yyq: '已邀请',
    plszhdj: '批量设置活动价',
    nmyqx: '您没有权限',
    drbmd: '导入白名单',
    yfrndzh: '已放入你的账户',
    szqp: '数组气泡',
    qsrmbbt: '请输入模板标题',
    sygxkc: '使用共享库存',
    qtxljmb: '请填写链接目标',
    fwbj: '服务半径',
    cypt: '参与拼团',
    ykqdlsmbkjxsy: '已开启的历史模板可继续使用',
    zjygzs: '专柜员工展示',
    qszzdyxfctzlj: '请设置自定义悬浮窗跳转链接',
    mdshspjepx: '门店售后商品金额排行',
    bfsp: '部分商品',
    xzdr: '新增导入',
    zxcgs: '执行成功数',
    sdp: '数电票',
    nbfhhdbmtj: '您不符合活动报名条件',
    mrbj: '默认保价',
    qsrdjsyslxz: '请输入叠加使用数量限制',
    yqzcrs: '邀请注册人数',
    qscdhtp: '请上传兑换图片',
    zzshqxgbdscxyddyzsjfxx: '则在审核取消关闭单时查询原订单已赠送积分信息',
    sckp: '删除卡片',
    zdsyqtx: '最多使用请填写>0的正整数',
    gzhqb: '1个组合券包',
    gzlx: '规则类型',
    fpzt: '发票状态',
    xzlq: '限制领取',
    kfhf: '客服回复',
    czksy: '储值卡首页',
    qxzsqbb: '请选择商圈版本',
    gbjg: '公布结果',
    tpe: '图片二',
    wz_4: '未知',
    xgdd: '相关订单',
    xfm: '消费满',
    zhfgzqdrdnr: '则会覆盖之前导入的内容',
    jfmx: '积分明细',
    jhsz: '拣货设置',
    h_jhsz_tip: '当门店需要拣货时，自提配送订单复核后则不会自动发货',
    qsrdqmm: '请输入当前密码',
    tpdc: '图片弹窗',
    mrlqcs: '不限制，每人领取次数',
    qsranwldkhmy: '请输入安能物流的客户密钥',
    qtxkpmc: '请填写开票名称',
    mrmtkcy_1: '每人每天可参与',
    xszcsj: '线上注册时间',
    bml: '报名类',
    fhr: '复核人',
    yxygsbd: '原校验格式不动',
    zj_6: '总价',
    sgslxz: '申购数量限制',
    yqspjhfyj: '逾期商品将恢复原价',
    xzzjztgz: '新增专柜自提规则',
    zshy: '赠送好友',
    rqbnwk: '日期不能为空',
    yxfcs: '月消费次数',
    xjje: '小计金额',
    wjphzxxffjl: '无奖品或者线下发放奖励',
    ymdbbt: '页面顶部标题',
    jfj: '积分价',
    cdsj: '产地数据',
    xks: '新客数',
    qsrspxq: '请输入商品详情',
    hgmdjsygdj: '或购买多件时预估单价',
    ewmmc: '二维码名称',
    hdqjmyhkcggm: '活动期间每用户可成功购买',
    jbr: '举报人',
    tcje: '停车金额',
    zxjd: '重新叫单',
    qxzyshdyhq: '请选择要售后的优惠券',
    mdtcskdztmc: '门店停车收款的主体名称',
    zznc: '作者昵称',
    xybj: '需要保价',
    lbtp: '礼包图片',
    gztcwssflbm: '该主体财务税收分类编码',
    yhfs: '优惠方式',
    tyrkybjgfqzkj:
        '同一砍价活动里，同一人可以帮几个发起者砍价，最多9999次。同一活动中，同一人仅能帮同一好友砍价一次',
    fhfs: '发货方式',
    qxzydwz: '请选择预订位置',
    dyh: '的用户',
    ccjjh: '次抽奖机会',
    fs_1: '发送',
    qnxbjcd: '请您先编辑菜单',
    pzh: '配置后',
    djwlgss: '对接物流公司时',
    qsrzfbt: '请输入转发标题',
    qsrzfbt28: '请输入转发标题，最长28字',
    pldcsj: '批量导出数据',
    ddsz: '订单设置',
    bxkg: '不限宽高',
    qxzzckpnr: '请选择支持开票内容',
    yyrq: '运营人群',
    ykysjwdgcdywsz: '也可以是经纬度构成的一维数组',
    kjgddzqtzssqtk: '跨境购订单在前台展示申请退款//售后按钮',
    zbjzt: '直播间状态',
    ds: '底色',
    xsbf: '小数部分',
    dyhq: '的优惠券',
    xfsjly: '消费数据来源',
    detqcxfedzxjs: '当日累计，用户当日消费累计计算，第二天清除消费额度重新计算',
    qtxwzkpszzdmyx: '请填写完整卡片设置中的每一项',
    yzqfw: '月周期范围',
    jfqd_1: '缴费渠道',
    syrkdh: '所有人可兑换',
    syrkdh_1: '不限制，所有人可兑换',
    shsj: '审核时间',
    lbjssj: '礼包结束时间',
    sbyy: '失败原因',
    fhcg_1: '复核成功',
    yjckc: '已借出库存',
    qtxpz: '请填写配置',
    qtxyyrxm: '请填写预约人姓名',
    txx: '条消息',
    zsszqy: '至少设置其一',
    qsrhksm: '请输入回馈说明，不能超过20000个字',
    lmshspjepx: '类目售后商品金额排行',
    zshrxxywyh: '如勾选，若实名认证的是用户A的信息，则收货人信息也为用户A的信息，且不可更改。',
    jw: '计为',
    sfscggz: '是否删除该规则',
    bjzzjg: '编辑组织机构',
    xsj: '销售价',
    yy_2: '，预约',
    myjb: '每月解绑',
    zxly: '招新来源',
    gcgtj: '共成功添加',
    bmxm: '报名姓名',
    qzsxzygmd: '请至少选择一个门店',
    qxsctp: '请先上传图片',
    jppz: '奖品配置',
    xzgzhxzlbydrkcyyhdbmd: '新增规则后需在列表页导入可参与用户的白名单',
    szsp: '设置商品',
    ckshxx: '查看审核信息',
    lxyqrsxydxdysydrs: '保存失败，连续邀请人数下一档需大于上一档人数',
    wdhm: '位兑换码',
    qpzxsdhdlx: '请配置显示的活动类型',
    jzzhywybs: '指CRM中会员唯一标识',
    csxz: '次数限制',
    zwkfhdpsgs: '暂无可发货的配送公司',
    jxgd: '矩形高度',
    zcdly: '注册登录页',
    ct: '成团',
    xsngm: '小时内购买',
    qsrzhm: '请输入租户名',
    bindingTime: '绑定//兑换时间',
    jlx: '奖励项',
    zsschdy: '装饰商城活动页',
    zjbs: '总金币数',
    dxsz: '抵现设置',
    xb_1: '性别',
    rxsgsh: '如需手工审核',
    ydzt_1: '运单状态',
    llsj: '流量数据',
    fzfzhlx: '分账方账户类型',
    mjcggmdzkqh: '买家成功购买电子卡券后',
    hdcjbkqh: '活动创建并开启后就进行活动预告',
    l_mjcggmdzkqh: '买家成功购买电子卡券后，未使用且超过有效期时，自动原路退款给买家',
    sqqd: '授权渠道',
    xjyg: '新建员工',
    sdshzjmc: '手动审核专柜名称',
    hyj: '会员价',
    bjqdtgm: '编辑渠道推广码',
    zdrqkcy: '指定人群可参与',
    myhksg: '每用户可申购≤',
    yhqhdzxjjqspjylmzdspcbjbf:
        '利润指结算时商品扣除促销//优惠券后的执行价减去商品经营栏目中的商品成本价部分；选择按商品金额抵扣，如遇除ERP以外的三方供应链商品仍会按商品利润计算',
    dhcj: '导航层级',
    shrxm: '收货人姓名',
    xcxzcx: '小程序中查询',
    jlt: '奖励图',
    fjy: '分拣员',
    yzbkjrq: '隐藏不可见人群',
    kdqjdz: '快递取件地址',
    cjewm: '创建二维码',
    qsctb: '请上传图标',
    tkfs: '退款方式',
    wpzsqtgkc: '未配置社区团购库存',
    mmgzrx: '命名规则如下',
    qtxqgsj: '请填写抢购时间',
    kjsjjywyhdyjssj: '开奖时间建议晚于或等于结束时间',
    wjyzcxbbtx: '无经验值此项不必填写',
    reason: '原因',
    sfzt: '身份状态',
    bjztd: '编辑自提点',
    sykhjh: '剩余开盒机会',
    cymj: '参与门槛',
    smrzxmzje: '实名认证需满足金额',
    h_smrzxmzje_tip:
        '购卡单笔订单实付金额达到设定值后，触发实名认证场景（实名认证通过后，不再触发）',
    dzqh: '单张券号',
    qtxjbgz: '请填写解绑规则',
    fzdzzscc: '否则导致展示出错',
    hdzbm: '活动组编码',
    yems: '余额描述',
    gmhckcj: '购买后才可参加',
    yhq: '优惠券',
    yhqpc: '优惠券批次',
    schjbkhf: '删除后将不可恢复，确定删除吗?',
    sjhm: '手机号码',
    bbsj: '报表数据',
    xzkpsqy: '选择可配送区域',
    qwzszpdjg: '请完整设置票档价格',
    mbtbxx: '模板头部信息',
    cjje_1: '成交金额',
    fwksrq: '服务开始日期',
    dhlx: '导航类型',
    ztx: '则填写',
    numberRange: '请输入0～365之间的数字',
    l_numberRange: '请输入0～365之间的数字',
    l_numberRange_1: '请输入1～9999之间的数字',
    zxzt: '执行状态',
    qsrbjfxdf: '请输入笔记分享得分',
    jyhqtymzsjsdyx: '禁用后前台页面展示将受到影响',
    bjfzzffs: '编辑分账支付方式',
    jssj: '结算时间',
    zlcyjf: '重量参与计费',
    sqsy: '售券收益',
    gzf: '个字符',
    zlbcyjf: '重量不参与计费',
    wsx: '未失效',
    sjmjy: '商家码禁用',
    pzjfshbtgdyy: '拍照积分审核不通过的原因',
    xcfs: '寻车方式',
    yf_2: '运费',
    tjsb_1: '提交失败',
    jsfz: '即时分账',
    xssz: '显示设置',
    kpsb: '开票失败',
    qxqkdjxg: '请先清空等级限购',
    l_qxqkdjxg: '请先清空等级限购，再配置普通限购',
    bjbt: '笔记标题',
    fg_1: '法规',
    szpcgz: '设置排斥规则',
    mdzj: '门店专柜',
    qtxysrzywmc: '请填写延时入账业务名称',
    scjsrgscw: '市场价输入格式错误，价格最大值为999999.99，仅保留2位小数',
    bmcghschdewm: '报名成功后生成活动二维码',
    tzsfjsjf: '跳转三方结算缴费',
    ghzs: '个汉字算',
    tjjtgz: '添加阶梯规则',
    qsrljzqts: '请输入累计周期天数',
    yhjwfgmyhq: '用户将无法购买优惠券',
    xgjf: '修改积分',
    mdewm: '门店二维码',
    aflzpx: '按分类中排序',
    mtjsqytddsjbzdhy: '每天计算前一天达到升级标准的会员，并执行升级',
    zktxsjgs: '只可填写时间格式',
    bh_1: '编号',
    yqjltbbjt: '邀请记录图标背景图',
    yhq_1: '优惠前',
    byxyhqxdd: '不允许用户取消订单',
    zjsyytyfgz: '专柜使用一套运费规则',
    yjbl: '佣金比例',
    hdbh: '活动编号',
    tpjykdw: '图片建议宽度为',
    qksp: '清空商品',
    hxfwhjdtzwt: '后续服务环节的通知问题',
    nxzdzhhyyjwsy: '您选择的账号还有佣金未使用',
    yyjlbzxx: '用于记录备注信息',
    xxgz: '线下规则',
    mdzjgzhewm: '门店专柜公众号二维码',
    spsl: '商品数量',
    drxydsjqshck: '导入需一段时间请稍后查看',
    xzjs_1: '新增角色',
    sjmgzdgx: '数据每个整点更新，最近更新时间',
    sqr: '申请人',
    glyj: '管理佣金',
    fsny: '服饰内衣',
    jyzzdymc: '经验值自定义名称',
    qwdylksjht: '前往抖音来客商家后台获取抖音门店ID',
    h_jyzzdymc_placeholder: '如：成长值，最多4个字符',
    h_jyzzdymc_tip:
        '填写自定义名称后，在小程序页面中则展示自定义名称。如，将经验值自定义为“成长值”，则用户看到的就是“成长值”不填则默认展示“经验值”',
    tyd: '天预订',
    jhdh: '拣货单号',
    zdjybnxyzxjy: '最大经验不能小于最小经验!',
    sqsjh: '授权手机号',
    pzdyy: '配置打印员',
    spxzcg: '商品新增成功',
    pxxh: '排序序号',
    sbnrhzxdjfs: '失败内容后重新点击发送',
    qtjmzdy: '请添加面值定义',
    zb_2: '总部',
    nxyh_1: '女性用户',
    qsrwz: '请输入文字',
    mcys: '名称颜色',
    nqrbxy: '您确认本协议',
    qxzsxmd: '请选择生效门店',
    zjsl: '中奖数量',
    hyzxhymxf: '会员中心会员码下方',
    wc: '完成',
    zjdyxrwc: '组件打印渲染完成',
    khzskdjsy: '可和自身可叠加使用',
    tnth: '天内提货',
    hxzmz: '核销总面值',
    szsrk: '数字输入框',
    zpmx: '赠品明细',
    qsrdw: '请输入单位',
    ccjtdz: '车场具体地址',
    dhlx_1: '兑换类型',
    bjdhgz: '编辑兑换规则',
    mdljkhs: '门店累计客户数',
    spzfje: '商品支付金额',
    jbpz: '基本配置',
    qsrfwdh: '请输入服务电话',
    xxddfh: '线下订单复核',
    qgkjspsszddjz: '{price}是变量，取该砍价商品所设置的底价值',
    yshsl: '已售后数量',
    login: '登录',
    jbsj: '举报时间',
    qszjsfssfljmb: '请设置结算方式三方链接目标',
    btxzzddqxzdyhqsygz: '不填写则自动读取选择的优惠券使用规则',
    gllszjcg: '管理隶属专柜成功',
    yhzqthdymkkdgbq: '用户在前台活动页面可看到该标签',
    ckdgyxx: '查看导购员信息',
    sjyhgmyx: '预售造势，锁定用户，资金回笼',
    qsrqbm: '请输入券编码',
    gwcy: '购物车页',
    qzqtxzbqlgz: '请正确填写逐笔清零规则',
    yhqshxx: '优惠券售后信息',
    myhzhdqjzdkhdcjjhw: '每用户在活动期间最多可获得抽奖机会为',
    jbyyd: '将被应用到',
    sjmqy: '商家码启用',
    czgz: '成长规则',
    mrpxgz: '默认排序规则',
    jlrq: '奖励人群',
    fxms: '分享描述',
    yhdjxfch: '用户点击悬浮窗后',
    picLimitText: '建议图片宽度为800*640，高度不限；大小不超过1M；格式:JPG,PNG,JPEG',
    shdq: '收货地区',
    pl: '评论',
    hfcg: '回复成功',
    xzcg: '新增成功',
    plxggxkc: '批量修改共享库存',
    zjsymdyfgz: '专柜使用门店运费规则，参与门店运费规则计算',
    zwsp: '暂无商品',
    wzgl: '文章管理',
    qsrxxnr: '请输入消息内容，不超过50个字',
    xtdcgbmdyhjxzdcq: '如配置12:00；则每日的12:00系统对成功报名的用户进行自动抽签',
    glymm: '管理员密码',
    zsxz: '至少选择',
    sfbddyy: '是否绑定打印员',
    xzfxzt: '选择分享专题',
    ryyzyhqkcbzs: '任意一种优惠券库存不足时，优惠券礼包失效。',
    qxzxnsp: '请选择虚拟商品',
    mbdz: '每被点赞',
    szhyj: '设置会员价',
    wfjsy: '王府井首页',
    hj: '合计',
    ygsz: '预告设置',
    dxfq: '定向发券',
    tpcc_1: '图片超出',
    tpcc_10: '图片超出10M限制',
    rqbjq: '热区编辑器',
    bjhdjl: '编辑互动激励',
    dzlpk: '电子礼品卡',
    sjmcbnwk: '事件名称不能为空',
    bjjs: '编辑角色',
    dcz: '待充值',
    twxq: '图文详情',
    bz_1: '标志',
    hdhmd: '活动黑名单',
    qdmc: '渠道名称',
    sfjl: '身份记录',
    xzqdyl: '新增签到有礼',
    gzmc: '规则名称',
    ybjc: '已被借出',
    tkwc: '退款完成',
    yhkzqtsqsh: '用户可在前台申请售后',
    l_yhkzqtsqsh: '勾选后，优惠券未使用，未过期，用户可在前台申请售后',
    jfhqqd: '积分获取渠道',
    ygq: '已过期',
    ycj: '已创建',
    cyzxm: '参与者姓名',
    ptdd_1: '普通订单',
    lxcxsjxbq: '来小程序上进行绑券',
    qsrdz: '请输入地址',
    yfjem: '应付金额满',
    sykcwyhkygmdspsl:
        '提醒：剩余库存为用户可以购买的商品数量；如修改共享库存数，则剩余库存数=编辑后共享库存数 - 原共享库存数；',
    qsryxj: '请输入优先级',
    qlxglypz: '对不起，您还没有该栏目权限，请联系管理员配置',
    wsq_1: '未授权',
    zhjcxx: '账号基础信息',
    hdjsrq: '活动结束日期',
    yhnr: '优惠内容',
    mrfqsl: '每人发券数量',
    scdlyh: '首次登录用户',
    dzzt: '到账状态',
    bl_2: '变量',
    qsrtccsm:
        '请输入停车场说明，如：\n1、停车收费标准：4元//小时，不满1小时计时单位，按15分钟1元计算；\n2、请于缴费后15分钟内离场，15分钟后将重新开始计费',
    ms_2: '模式',
    kth: '开团后',
    mdhzzjdpsfs: '门店或者专柜的配送方式',
    zaewmsbgz: '长按二维码识别关注',
    cktb: '窗口图标',
    jfcjy: '积分抽奖页',
    pzjf: '拍照积分',
    symd: '使用门店',
    xcxmscz: '小程序码生成中，请稍后再尝试下载',
    qjssz: '对接后微信与商户的结算周期变长，请谨慎设置。',
    tkdcjsj: '退款单创建时间',
    bjmqfl: '编辑卖券分类',
    yhqffsj: '优惠券发放数据',
    qxzhdznxmdsx: '请选择活动在哪些门店生效',
    tzqd: '通知渠道',
    list: '列表',
    qsrdbkddkhmy: '请输入德邦快递的客户密钥',
    tjtj: '添加条件',
    xznzhk: '新增年终回馈',
    bmsjh: '报名手机号',
    qtxyysj: '请填写预约时间',
    qsryemc: '请输入余额名称',
    ddszmd: '订单所在门店',
    szyhq: '设置优惠券',
    shbc: '售后补偿',
    tcfs: '填充方式',
    gdbxz: '高度不限制',
    qsrpjkddmy: '请输入品骏快递的密钥',
    yjsl: '已拣数量',
    qymc_1: '企业名称',
    zxzh: '注销账号',
    dcx: '多促销',
    jxzdhdbyxxg: '进行中的活动不允许修改',
    mddw: '门店定位',
    zdyhqb: '指定优惠券包',
    qsryhqlbmc: '请输入优惠券礼包名称',
    sfjygsjm: '是否禁用该商家码？',
    yhglqxbfsq: '用户管理权限部分授权',
    gdfl: '更多福利',
    l_gdfl: '更多福利，点击',
    hdrq: '活动日期',
    pldrzjxx: '批量导入专柜信息',
    xzyhq: '选择优惠券',
    cpys: '车牌颜色',
    yzf_1: '已支付',
    mdzfddpx: '门店支付订单排行',
    fsdx_1: '发送对象',
    yqshanzssjsz: '延期收货按钮展示时间设置',
    myj: '免押金',
    qbyh: '全部用户',
    lbsj: '礼包时间',
    hdzqzrs: '活动中签总人数',
    jfhd: '积分活动',
    dhkzje: '待汇款总金额',
    jfqdzgtcpzsyywx: '缴费渠道指该停车配置适用于微信//支付宝小程序',
    zl_3: '重量',
    qtxjltjmzsz: '请填写奖励条件满足数值',
    jytpccxy: '建议图片尺寸小于750*880；大小不超过1M；',
    kmcwzs: '卡名称文字色',
    qtxwzdjbgz: '请填写完整的解绑规则',
    ywsz: '业务设置',
    mgyh: '每个用户',
    dqmdwkthyk: '当前门店未开通会员卡',
    zcbcg: '注册不成功',
    qxzkkpmd: '请选择可开票门店',
    yxdppsj: '已选的品牌数据',
    psjlmzj: '配送距离每增加',
    ycjs: '移除角色',
    mrcyhdlq: '每日参与活动领取',
    ahyrqdh: '按会员人群兑换',
    sjhzwyxy: '手机号做唯一校验',
    zxljz: '连接失败,重新连接中',
    wdyhqsz: '我的优惠券设置',
    sqtgdd: '社区团购订单',
    zshtpzdzjjsnr: '展示后台配置的专柜介绍内容',
    qgxxcbk: '请勾选宣传板块',
    xnhycz: '虚拟号已存在',
    xzls: '选择流水',
    ddmdbh: '订单门店编号',
    ysxyybjt: '隐私协议页背景图',
    h_ysxyybjt_tip: '隐私协议页背景图',
    qtxyysx: '请填写预约时限',
    scsj_1: '生成时间',
    sjzzjg: '上级组织机构',
    yysjxz: '预约时间限制',
    kdjmbmc: '卡等级模版名称',
    shspje: '售后商品金额',
    ysgxkcdd: '预售共享库存订单',
    zhxzcg: '账号新增成功',
    wbd: '未绑定',
    wxhykpz: '微信会员卡配置',
    jhpqshy: '距货品签收还有',
    h_jhpqshy_fill: '距货品签收还有#value#天时，开放延期收货按钮',
    yylx: '原因类型',
    wbsrk: '文本输入框',
    khbh: '客户编号',
    qsrspptj: '请输入商品拼团价',
    lcmmsrbyz: '两次密码输入不一致!',
    zt_1: '暂停',
    czpmx: '称重品明细',
    dlhyybnwk: '登录欢迎语不能为空',
    ddzfjf: '订单支付积分',
    khpz: '客户凭证',
    qtxshyy: '因审核不通过，请填写审核原因',
    gzhmc: '公众号名称',
    drcg: '导入成功',
    gzybkq: '规则已被开启',
    pgkhd: '苹果客户端',
    tscp: '特殊车牌',
    mcsgsj: '每场申购时间',
    qsrzcwa: '请输入资产文案',
    qxzhdsp: '请选择活动商品',
    ttlx: '抬头类型',
    bqtb: '标签图标',
    qytb: '权益图标',
    hdjsq: '活动结束前',
    fxhb: '分享海报',
    yhzsmyhqqtkkdflmc: '用户在售卖优惠券前台可看到分类名称',
    psgzmc: '配送规则名称',
    xzjl_1: '下载记录',
    yhsqqxddyy: '用户申请取消订单原因',
    dldzfzsb: '短链地址复制失败',
    hdcc: '活动场次',
    zxzbfyhcys: '只限制部分用户参与时',
    l_zxzbfyhcys_1: '只限制部分用户参与时，新增规则后需在列表页导入可参与用户的白名单',
    zfzt: '支付状态',
    zxrqhzxsjbnwk: '执行日期和执行时间不能为空',
    cjmhd: '抽奖码活动',
    gcp: '个车牌',
    qrdz: '确认订座',
    zzzfmm: '重置支付密码',
    ydhspshzs: '已兑换商品售后总数',
    zbsj_1: '直播数据',
    mrsz: '默认设置',
    jpsljwkjsl: '奖品数量即为开奖数量',
    qtxxzhdjyz: '请填写选中后的经验值',
    qsrdhm: '请输入兑换码',
    kprxm: '开票人姓名',
    tpjj: '图片间距',
    fzlpq: '复制礼品券',
    hym_1: '会员名',
    hdtp: '活动图片',
    ddzb: '订单总表',
    kzlbydcq: '可在列表页导出券',
    fsfs: '发送方式',
    zxrqbnwk: '执行日期不能为空',
    ptjs: '拼团结束',
    fzsb: '分账失败',
    hyjffwkthddz: '会员积分服务开通回调地址',
    zfftcfw: '支付分停车服务',
    random: '随机生成',
    qtxmyhsgsl: '请填写每用户申购数量',
    qjsggztxwz: '请将  申购规则-设置抽奖次数 填写完整',
    grda: '个人档案',
    yhbjcg: '用户编辑成功',
    lpqmc: '礼品券名称',
    kmwz: '卡面文字',
    jgck: '仅供查看',
    qlxglyjxsz: '请联系管理员进行设置',
    gxxx: '更新信息',
    ljmb: '链接目标',
    mbbt: '模板标题',
    grxx: '个人信息',
    htdlym: '后台登录页面',
    h_htdlym_tip: '建议图片宽度为360px，高度不超过72px；大小不超过1M；',
    qrfsm: '确认发送吗?',
    zdyrq: '自定义人群',
    lxqd: '连续签到',
    jdszmztjhdjl:
        '活动时间10月1日～10月30日一个月，设置每周连续登陆2天获得奖励，任务时间内2次奖励后，不在获得奖励。用户第一周、第三周满足条件获得奖励，第四周用户满足条件也不在发放奖励。',
    dszmztjhdjl:
        '举例：活动时间10月1日～10月30日一个月，设置每周连续登陆2天获得奖励，任务时间内2次奖励后，不在获得奖励。用户第一周、第三周满足条件获得奖励，第四周用户满足条件也不在发放奖励。',
    nsrl: '年生日礼',
    flsj: '分类数据',
    xlh: '序列号',
    xfcx: '消费查询',
    pzx: '配置项',
    mhfmt: '盲盒封面图',
    mcbnwk: '名称不能为空',
    zjl: '中奖率',
    qszl: '起送重量',
    sp_1: '审批',
    txzt: '提现状态',
    mrqy: '默认区域',
    bjbqqx: '编辑标签权限',
    qxzzffs: '请选择支付方式',
    defzfpz: '第二分账方配置',
    hksb: '汇款失败',
    xybm: '需要报名',
    yeqlbzsfs: '余额券列表展示方式',
    jgqjzzyxyyz: '价格区间左值应小于右值，且价格应大于零',
    szpsgs: '设置配送公司',
    qxjyxspfh: '请先将已选商品发货',
    qsryskddkhbh: '请输入优速快递的客户编号',
    jz_2: '建站',
    jffb: '积分翻倍',
    ksrqbngg: '开始日期不能更改, 请调整回',
    gjhdnwcsp: '该拣货单内无此商品',
    mrdj: '默认等级',
    xzdhkddtj: '选择待汇款订单条件',
    jpff: '奖品发放',
    jexx: '金额下限',
    fwtp: '服务图片',
    hzdydwzhzdydwzhzdydwzhzdydwzhzdydwzhzdydwz:
        '很长的一段文字很长的一段文字很长的一段文字很长的一段文字很长的一段文字很长的一段文字',
    dctp: '弹窗图片',
    ygbh: '员工编号',
    mnffdsyjbz: '固定清零：每年发放的所有金币在',
    qsrzqgsdsqsm: '请输入正确格式的授权说明',
    d_8: '第',
    sctpz: '上传图片中',
    jlzhzzs: '奖励追回总张数',
    qsrbdsjh: '请输入绑定手机号',
    xzlp_2q: '新增礼品券',
    mdzj_1: '门店组件',
    bxzjbcs: '不限制解绑次数',
    cph: '车牌号',
    qszcxjf: '请设置查询缴费',
    jlsx_1: '奖励上限',
    ykjbxdyl: '一口价必须大于零',
    qxzsqyy: '请选择申请原因',
    d_9: '等',
    yxf: '已消费',
    fxspbd: '风险商品榜单',
    bjztxdfdbl: '编辑状态下的浮动比例',
    ktdjzsx: '可拖动间整顺序',
    qsrhybq: '请输入会员标签',
    bccg: '保存成功',
    zxhyzkh: '执行会员折扣后，还可执行单/多促销',
    xcx: '小程序',
    zm_1: '字母',
    glxx: '关联信息',
    zpbqgl: '赠品标签管理',
    rgshsj: '人工审核时间',
    qtxkdjmbmc: '请填写卡等级模版名称',
    zmrdyyhqjeky: '为0则默认大于优惠券金额可用',
    dhkddsj: '待汇款订单数据',
    kjhfsz: '快捷回复设置',
    jfzf: '积分支付',
    qrsc: '确认删除',
    zssj: '赠送时间',
    jftzwa: '积分跳转文案',
    sqlsh: '申请流水号',
    zfbxcxdyxx: '支付宝小程序订阅消息',
    xzkyddhydj: '限制可预订的会员等级',
    zdzqrs: '指定中签人数',
    lszj: '隶属专柜',
    shdzzpsfwwdmjbkyxd: '收货地址在配送范围外的买家不可以下单',
    qzqtxjmxs: '请正确填写减免小时',
    skr: '收款人',
    lxqdts: '请输入累计/连续签到天数',
    bjjzqdydsj: '编辑将之前对应的数据',
    rqfq: '人群发券',
    dqzhwmdqx: '当前账号无门店权限，请先分配门店权限',
    jydj: '禁用等级',
    hyk: '会员卡',
    qsrfmmc: '请输入封面名称',
    qsrfmNothan30: '请输入封面名称，不超过30个字',
    maxRedPackageAmount: '指定第X个人最大红包面额',
    tcfw: '停车服务',
    wszhysqyqshsj: '未设置会员申请延期收货时间',
    ffjlcg: '发放奖励成功',
    qsrmz: '请输入面值',
    zdzq_1: '自动长期',
    qfdx: '群发对象',
    psqhb: '拼手气红包',
    psqhbDes: '随机金额，刺激分享，扩大曝光',
    xzdhgz: '新增兑换规则',
    byxyhjxcj: '不允许用户继续抽奖',
    tzsqsxzdydmd: '选择门店后，该门店可招募社区团长，团长申请时选择对应的门店',
    jkhdsj: '集卡活动数据',
    xdmrffjlcs: '限定每人发放奖励次数，选填',
    ljzx: '立即执行',
    djscrq: '点击删除热区',
    qdlx: '渠道类型',
    zjksdyxr: '组件开始打印渲染',
    dddyfhdwfhs: '当订单已复核但未发货时',
    qtxfxyxm: '请填写分销员姓名',
    zxzf: '在线支付',
    zxzfjfkq: '在线支付(积分支付//卡券支付)',
    wdyeq: '我的余额券',
    yyrsjh: '预约人手机号',
    zdzjcy: '指定专柜参与',
    xsqdd: '线上券订单',
    hysqyqshsjsz: '会员申请延期收货时间设置',
    xshdyybb: '新售后单语音播报',
    xzmqfl: '新增卖券分类',
    clsj: '处理时间',
    zddqxzdyhqhlbbt: '自动读取选择的优惠券或礼包标题',
    jesx: '金额上限',
    qxzyhxglx: '请选择用户限购类型',
    smrz: '实名认证',
    yxz: '已选择',
    dhmscz: '兑换码生成中',
    zcdz: '注册地址',
    dzyx: '电子邮箱',
    qxjwsq: '权限集未授权',
    l_qxjwsq_1: '权限集未授权，请先授权，才可使用此项业务功能',
    xzq: '选择券',
    xtgly: '系统管理员',
    fxzlwa: '分享助力文案',
    mccjxh: '每次抽奖消耗',
    xse: '销售额',
    zcqd: '注册渠道',
    zfftc: '支付分停车',
    touchlessParking: '无感缴费',
    czmsp: '未知乐趣，探索惊喜，创意营销',
    tzqzcdyhkcjhd: '天之前注册的用户可参加活动',
    szsysm: '设置使用说明',
    zsys: '展示样式',
    jyszdtsxyyhqdyxq:
        '建议设置的天数小于优惠券的有效期，否则用户无法收到过期提醒。过期提醒需要开启小程序订阅消息功能，未开启则不会提醒',
    tjdzdyx: '添加的自定义项，可拖动间整顺序',
    yzhd: '隐藏活动',
    hdksygsjyzydhkssj: '活动开始预告时间应早于兑换开始时间',
    xyktzfbxcxcksyggn: '需要开通支付宝小程序才可使用该功能，请联系客户成功经理进行开通',
    tqzs: '退券张数',
    zckpnr: '支持开票内容',
    xzl: '选择列',
    qsrhptm: '请输入货品条码',
    djgbsy: '点击关闭声音',
    ssbk_1: '所属板块',
    rbtxxtdqmrtp: '建议尺寸:590 * 784  大小不超过1M  格式:JPG,PNG,JPEG,若不填写系统读取默认图片',
    gdybcz: '该店员不存在',
    xgkssj: '限购开始时间',
    xzfx: '旋转方向',
    wb_2: '尾部',
    qdqkhdm: '确定清空活动吗',
    sfzsmd: '是否展示门店',
    dhjp: '兑换奖品',
    ztm: '自提码',
    tcwyylsqxygkdd: '停车无忧引流神器，吸引顾客到店',
    zfbt: '转发标题',
    thjf: '退还积分',
    wdyeqlbym: '我的余额券列表页面',
    bjyy: '编辑原因',
    sjhxysb: 'openid/手机号校验失败，请',
    bjlx: '笔记类型',
    xxkt: '需先开通',
    cjsj: '抽奖时间',
    hdqjljxyslzlzkhddycsjkjh: '活动期间累计相应数量助力值可获得对应次数集卡机会',
    xzlx: '选择类型',
    yqx: '已取消',
    yqxygb: '已取消//已关闭',
    qddcbjt: '签到弹层背景图',
    rgjpyfw: '如果奖品已发完',
    qrtyhy: '确认停用会员',
    xzhdyxqnmrzdffjl: '限制活动有效期内每人最多发放奖励{{activityRewardLimit}}次',
    xzhdyxqn: '限制活动有效期内每人最多发放奖励',
    sqhk: '申请汇款',
    hdxg: '活动限购',
    gqtssz: '过期天数设置',
    shdz: '收货地址',
    bktp: '板块图片',
    hxm: '核销码',
    gzhcdzwpznrsj: '公众号菜单暂无配置内容数据，同步失败',
    cjhf: '创建回复',
    sdpkxzxsr: '数电票可选择性输入',
    spkc: '商品库存',
    xzhym: '选择会员名',
    zfb: '支付宝',
    jfmxymzwyhzs: '积分明细页面中为用户展示',
    dryzgmyh: '导入延长购买用户',
    tplb: '图片列表',
    ciandr: '次//人',
    mgyhfqkjjwyc: '同一活动，每个用户发起砍价计为一次',
    shrxxwyhsmrzdxx: '收货人信息为用户实名认证的信息',
    fhdyqjldnln: '丰厚的邀请奖励等你来拿，快快行动吧~',
    qszhydj: '请设置会员等级',
    xdrbmcgcs: '下单人报名成功次数',
    lqyhq: '领取优惠券',
    shz: '售后中',
    tjbmhxsgsh: '提交报名后需手工审核',
    zdznszlg: '最多只能设置两个',
    djdttcc: '点击地图停车场',
    dlzh_1: '登录账户',
    bmsj: '报名时间',
    cjzs: '抽奖赠送',
    jfgq: '积分过期',
    qxzmrzdkfp: '请选择默认自动开发票',
    zdksz1yz: '最多可设置1亿张',
    gzfd: '公证费等',
    dgzz: '对公转帐',
    pxbzssj: '排行榜展示时间',
    wb_3: '未绑',
    bmxz_1: '报名须知',
    zfmmsz: '支付密码设置',
    mm_1: '每满',
    dhmc_1: '兑换名称',
    qsrhdbh: '请输入活动编号',
    activityNoRule: '请输入活动编号，不超过30个字',
    qxzrq: '请选择日期',
    drsfqm: '导入三方券码',
    zfje: '支付金额',
    fbml: '非报名类',
    qsz_1: '去设置',
    wwc: '未完成',
    qsrshdq: '请输入收货地区',
    dxzfs: '短信字符数',
    hdewm: '活动二维码',
    bbhqmblzdzfsl: '（不包含签名/变量字段字符数量）超过70个字符则按多条发送',
    yhckqlbzxyxzmdckdyqxx: '会员资产中展示券数量，用户查看券列表中需要选择门店查看对应券信息。',
    qtjsymdtydyhqhyhqlb:
        '优惠券和优惠券礼包仅支持设置一种，请添加适用门店通用的优惠券和优惠券礼包（或全部专柜可用），否则用户购买后可能无法使用。',
    gbh: '关闭后',
    jlff: '奖励发放',
    tghdbjcg: '团购活动编辑成功',
    qsryhxm: '请输入用户姓名',
    qsrzswa: '请输入展示文案',
    zzsdzptfp: '增值税电子普通发票',
    fpxx: '发票信息',
    zdksz_1: '仅支持输入正整数，最大可设置366天',
    pdsfksc: '判断是否可删除',
    syccpz: '使用车场配置',
    mbe: '模板二',
    glzbsp: '管理直播商品',
    fwdh: '服务电话',
    nsxdmdhwkyxldxfw: '您所选的门店还未开营销类短信服务，请联系客户经理开通后可使用此功能',
    ksrq: '开始日期',
    lqhktbzzfbkbz: '领取后可同步至支付宝卡包中',
    gq_1: '改签',
    wzbt: '文章标题',
    mjkxztsj: '买家可选自提时间',
    fx: '分享',
    myhzdsg: '每用户最多申购',
    wdl: '未登录',
    ljzq_1: '累计周期',
    zfjylsh: '支付交易流水号',
    dqkc: '到期扣除',
    qtyy: '其他原因',
    tzqzcdyh: '天之前注册的用户',
    tppz_1: '图片凭证',
    dcjlck: '导出记录查看',
    hxzs: '核销张数',
    tnjjgqdjfjxtx: '天内即将过期的积分进行提醒',
    bmzdyxmcwbtx: '报名自定义项名称为必填项',
    xztj: '限制条件',
    qrtzdqsjm: '确认停止当前数据吗?',
    qxzswxt: '请选择税务系统',
    xzztd: '新增自提点',
    zxrq: '执行日期',
    wszyjjssj: '未设置佣金结算时间',
    cctfsl: '场次投放数量',
    qdmj: '券单卖价',
    gdql: '固定清零',
    nrxgsz: '内容相关设置',
    qsrq_1: '起始日期',
    zbsp: '直播商品',
    bzxczddkszxffjl: '用户断登后重新计算连续登陆天数，并重新从最低档开始重新发放奖励',
    wdjf: '我的积分',
    xssj: '销售数据',
    qsje: '起送金额',
    qxzsjzj: '请选择数据专柜',
    bs: '表示',
    jblx: '举报类型',
    wdjfjlhsjfsc: '不超过8个字，例如：我的积分记录或是积分商城',
    bmxzyhsf: '报名限制用户身份',
    xzzjdlym: '选择专柜导览页面',
    bjt: '背景图',
    zyxzdsjkyplsc: '只有新增的数据可以批量删除',
    fplxpz: '发票类型配置',
    mbs: '模板三',
    hykhlx: '会员卡号类型',
    kdps: '快递配送',
    ljsq: '立即授权',
    date: '日期',
    qsy: '请使用',
    qhsp: '缺货商品',
    qwztxyhnr: '请完整填写优惠内容',
    userRuleLimit: '同一类型的限购同活动仅支持设置1档',
    mgyhzhdqjnkybczdcs: '配置后，每个用户在活动期间内可以被抽中的次数',
    smjf: '扫码积分',
    yhxg: '用户限购',
    txje: '提现金额',
    fqsjmc: '发券事件名称',
    qsrwlgsmc: '请输入物流公司名称',
    yxsg: '一行三个',
    sygz: '使用规则',
    csrq: '出生日期',
    zcjl: '注册奖励',
    plshtg: '批量审核通过',
    ddwk: '订单为空',
    kdjmbmc_1: '卡等级模板名称',
    fptt: '发票抬头',
    fwrq: '服务日期',
    wfdl: '无法登录',
    hyzhsl: '会员账号数量',
    yxzj_1: '已选专柜',
    qtjhdsp: '请添加活动商品',
    ygxm: '员工姓名',
    mtyssp: '茅台预售商品',
    mcsgslyz: '每场申购数量一致',
    qsrhdbz: '请输入活动备注',
    remarkmessage: '请输入活动备注，不超过500个字',
    hxrs: '核销人数',
    qdbs: '渠道标识',
    xzqbzjdbgzjlxxdqbzjsx: '选择全部专柜代表该专柜类型下的全部专柜生效',
    byqrzhdqjzccwhy: '被邀请人在活动期间注册成为会员',
    sfgbsdshgz: '是否关闭手动审核规则',
    xzhdjl: '新增互动激励',
    sxtj: '失效条件',
    ffcgzzs: '发放成功总张数',
    ztfs: '暂停发送',
    jzcsrzzs: '仅支持输入正整数',
    syfw: '商业服务',
    hzf: '或字符',
    gzhsz: '公众号设置',
    ddly: '订单来源',
    carparkOrderChannel: '缴费渠道',
    tsrhzh: '提升入会转化',
    yxj: '优先级',
    zzsxskmz: '转赠时显示卡面值',
    qwzszpdhdjg: '请完整设置票档活动价格',
    hdtksjs:
        '如填写30天，活动日期为1日-30日，若用户是25号参加的活动，则从25号当天开始计算，用户的注册时间必须为上月26日24:00及之前注册（若上月为30天）的才可预约成功',
    mzmjffjl:
        '全部内容包含作者历史累计发布的全部图文//视频//评论，满足门槛发放奖励。单条内容按作者发布的单个图文//单个视频//单个评论，满足门槛发放奖励。',
    jbdyy: '解绑打印员',
    wgtcsqrz: '无感停车授权日志',
    flmc: '分类名称',
    xlxz: '下拉选择',
    dgyfxsp: '导购员分销商品，获得佣金',
    yhkybd: '用户可以绑定',
    qsrhxlsh: '请输入核销流水号',
    kjggzglszdflx:
        '设置售卖优惠券规则时，可将该规则关联设置的分类下，用户在售卖优惠券前台可看到分类名称。',
    zxsj_1: '注销时间',
    qlgz: '清零规则',
    xyhs: '新用户数',
    xzdgddlhcnjsdqywxtz: '需在导购端登录后才能接收到企业微信通知',
    zdjsyj: '天自动结算佣金',
    tzdjsyj: '签收后#value#天自动结算佣金',
    glsp: '管理商品',
    jszt_1: '角色状态',
    rxfzqfhlbfzzqlj: '该路径是页面路径未带任何参数，仅供查看，如需复制请返回列表复制正确路径',
    qtxzqrs: '请填写中签人数',
    xzdj_1: '新增等级',
    batch: '批量',
    hyy: '会员页',
    yhpq: '用户凭券',
    qydw: '企业单位',
    xzhy: '选择会员',
    qzqtxgdqlgz: '请正确填写固定清零规则',
    bkzgzsz: '帮砍者规则设置',
    xsdd: '线上订单',
    qxxwkhxmd: '权限下无可核销门店',
    fzfmc: '分账方名称',
    btxzwsxjssj: '不填写则为生效结束时间',
    kcms: '库存模式',
    gmzxe: '购买总限额',
    bzjyc: '必中奖一次',
    wkp: '未开票',
    wcyhddcs: '未参与活动的次数',
    tcqtp: '停车券图片',
    zdhydj: '指定会员等级',
    zdhyrq: '指定会员人群',
    qxzydscfs: '请选择运单生成方式',
    errorformat: '格式错误，请输入数字、字母、或字符',
    qbm: '券编码',
    hyqyy: '会员权益页',
    hc_1: '缓存',
    yxlg: '一行两个',
    hdcytxsjbnwk: '活动参与提醒时间不能为空',
    bmgl: '报名管理',
    qdfszt: '渠道发送状态',
    bjcx: '保级查询',
    lqsbzzs: '领取失败总张数',
    xgcc: '限购场次',
    kjsz: '砍价设置',
    zdrq_1: '指定人群',
    syzt: '使用状态',
    ljcg: '连接成功',
    kjhf: '快捷回复',
    yqyl: '邀请有礼',
    qsrygzsxm: '请输入员工真实姓名',
    kddh: '快递单号',
    jezdzw: '金额最大值为100万',
    xzsp_1: '选择商品',
    hqdhkddsj: '获取待汇款订单数据',
    bkcs: '帮砍次数',
    ytgsh: '已通过审核',
    zsnr_1: '展示内容',
    totalScore: '评分',
    spxqy: '商品详情页',
    pldcbzckpsp: '批量导出不支持开票商品',
    j_6: '给',
    qxzfwsj: '请选择服务时间',
    qsrdzpjjesx: '请输入单张票据金额上限，最小可设置0.01，最大可设置99999.99',
    gj_1: '高级',
    qsrhxm: '请输入核销码',
    qsrkpzt: '请输入开票主体',
    sygzgzhzjq: '使用关注公众号组件前',
    ryhdrxf: '若用户当日消费',
    smyhq: '售卖优惠券',
    jfscxqy: '积分商城详情页',
    xsjdsjc: '新时间的时间戳',
    yp: '音频',
    limitSizeMessage: '超出限制，最大上传 2MB 音频',
    qjccsjtxwz: '请将场次数据填写完整',
    kzz: '可转赠',
    qtqzzyh: '请提前周知用户',
    zlyzxjwz: '着陆页左下角文字',
    h_zlyzxjwz_msg: '着陆页左下角文字,不能为空',
    h_zlyzxjwz_placeholder: '请输入，最长100字',
    shsl: '售后数量',
    tjbq: '条件标签',
    zxdg: '咨询导购',
    dscxhdy: '电商促销活动页',
    jcsj_1: '进场时间',
    qzqtxspbm: '请正确填写商品编码',
    zdjd: '自动叫单',
    jcgz: '基础规则',
    bmbmd: '报名白名单',
    hdydjsqdzhdjsrq: '活动已到结束日期，请调整活动结束日期',
    hdldypz: '活动落地页配置',
    szngjzk: '设置内购价折扣',
    csjebdgyxsjydjdce: '初始金额不得高于销售价与底价的差额',
    yjfklx: '一级反馈类型',
    qjyjtxwz: '请将已经勾选的 报名规则-报名条件 填写完整',
    zje: '总金额',
    jyxhyrqkdh: '仅已选会员人群可兑换',
    sdbq: '手动标签',
    zkjyxyscj: '折扣价应小于市场价，且价格应该大于零',
    sfwgjss: '是否为高级搜索',
    sd: '上调',
    jxt: '下调',
    gswj: '格式文件',
    qxzdd: '请选择订单',
    bzxx: '备注信息',
    sfsjj: '是否升降级',
    jsm: '角色名',
    spyy: '审批原因',
    skrxm: '收款人姓名',
    cbjjfs: '成本计价方式',
    dgzx: '导购咨询',
    ddfw: '订单范围',
    jexz: '金额限制',
    qxzhyrq: '请选择会员人群',
    qsrjsm: '请输入角色名',
    qrgbhd: '确认关闭活动',
    ljfs: '立即发送',
    gscw: '格式错误：请按照【',
    zhdlrq: '最后登录日期',
    bkydjgyd: '好友裂变，帮砍越多价格越低',
    qxg: '请修改',
    xzxnsp: '选择虚拟商品',
    drpp: '导入品牌',
    ffqq: '非法窃取',
    zqbmdyhsgsl: '中签白名单用户申购数量',
    zdht: '指定话题',
    qjm: '取件码',
    xzzh_1: '新增账号',
    qzzszyd: '权重值设置越大，排序越靠前，填写正整数',
    kjrq: '可见人群',
    qtxyybz: '请填写原因备注',
    spmxb: '商品明细表',
    zzjftzym: '自助积分跳转页面',
    dtdl: '地图导览',
    qqrgddspydbwb: '请确认该订单商品已打包完毕',
    xzzffs: '选择支付方式',
    ysy_1: '已使用',
    bth: '不退还',
    yjbj: '圆角半径',
    qxzyhdj: '请选择用户等级',
    fzfmcbncg: '分账方名称不能超过20个字符',
    tzdsfkfp: '跳转到三方开发票',
    cjqmc: '抽奖券名称',
    fbm: '非报名',
    rbshspzlhjyxxzdjg:
        '默认商品资料和经营信息所有专柜都不需要审核，若要审核请添加专柜进行人工审核（若不审核商品资料和经营信息遭到机关、用户投诉等，由商家自行承担相关责任）',
    tcsfbz:
        '请输入停车场说明，如：\n1、停车收费标准：4元//小时，不满1小时计时单位，按15分钟1元计算；\n2、请于缴费后15分钟内离场，15分钟后将重新开始计费',
    fwdd: '服务地点',
    bjczjl: '编辑创作激励',
    jbcpcs: '解绑车牌次数',
    jysj: '交易时间',
    bgxbxybmtj: '不勾选不校验报名条件，如勾选多个，则用户需要同时满足才可报名成功',
    ptgg: '拼图规格',
    zgxf: '最高消费',
    kqtbsz: '卡券同步设置',
    fssjh: '粉丝手机号',
    ptsr: '平台收入',
    qxzzsxzygkydyeqdkqlx: '请选择至少选择一个可用的余额券抵扣券类型',
    bdz_2: '被点赞',
    tnyxyhzqdsqsh: '天内允许用户在前端申请售后',
    th: '退还',
    tgqdfwpx: '推广渠道访问排行',
    tgsh: '通过审核',
    sd_1: '送达//自提时间',
    sywxhykh:
        '3.使用微信会员卡后，将原有注册页面替换为微信开卡组件，注册时静默获取用户此前填写的注册信息，无需重复填写，一键完成开卡，提高开卡效率，提升入会转化。',
    jffbbkwk: '积分翻倍不可为空',
    pl_2: '品类',
    ptdhj: '普通兑换价',
    zjshspszb: '专柜售后商品数占比',
    jqsrhdhdmc: '请输入活动名称。例如：2020年生日礼',
    mmzd_1: '密码最多16个字符',
    jssjbxdykssj: '结束时间必须大于开始时间',
    mgjpsszdzjglzhbxdy: '2. 每个奖品所设置的中奖概率之和必须等于100%',
    cqjsh: '抽签结束后',
    qsl: '券数量',
    fb: '诽谤',
    payGiftPicLimitExtra: '图片尺寸建议为750*1644px，大小2M以下，支持jpg,png格式',
    mrqdlqjfhjl: '每日签到领取积分或奖励',
    tcssflbh: '停车税收分类编号',
    ndqmyglmqx: '您当前没有该栏目权限，请联系管理员进行设置。',
    sph: '视频号',
    splj: '视频链接',
    mrtb: '默认图标',
    bmrs: '报名人数',
    qsrbskddmy: '请输入百世快递的密钥',
    zdqxdd: '分钟内未付款，自动取消订单',
    kqzf: '卡券支付',
    xr: '渲染',
    szhmdcg: '设置黑名单成功',
    xyxx: '协议选项',
    wrk: '未入库',
    zddfw: '至少选择1种订单范围',
    sflsh: '三方流水号',
    fqzgzsz: '发起者规则设置',
    yxbkjkcghxddsz: '发起砍价后，允许帮砍及砍成功后下单的时长，1-48小时',
    cs_2: '城市',
    hddd: '活动地点',
    drfwnljxf: '当日范围内累计消费',
    onlineAndOffline: '如线上、线下、合作等',
    comallTerm1: '一、接受条款',
    comallTerm2: '二、 定义',
    comallTerm3: '三、甲方的义务',
    comallTerm4: '四、诚信经营和第三方投诉处理',
    comallTerm5: '五、乙方的责任限制和免责',
    comallTerm6: '六、其他',
    comallAgreement1:
        '1.1 欢迎您使用北京科码先锋互联网技术股份有限公司（下称“科码先锋”）短信功能。以下所述条款和条件即构成您与北京科码先锋互联网技术股份有限公司就使用北京科码先锋互联网技术股份有限公司短信功能所达成的协议（下称“本协议”）。您确认本协议，即表示您已接受了以下所述的条款和条件。如果您不同意接受全部的条款和条件，那么您将无权使用北京科码先锋互联网技术股份有限公司短信功能，您可以退出北京科码先锋互联网技术股份有限公司短信功能。无论您是否勾选同意本协议或是否使用了短信服务，一旦您使用了北京科码先锋互联网技术股份有限公司短信功能，即表示您已接受了以下所述的条款和条件，同意接受本协议约束，本协议包括但不限于下述的条款和条件、北京科码先锋互联网技术股份有限公司短信功能的相关规则和通知',
    comallAgreement2:
        '1.2  科码先锋有权随时修改本协议条款和条件，并只需公示于网站或通知予您，而无需征得您的事先同意。修改后的条款应于公示或通知时即时生效。在修改本协议后，您继续使用短信功能应被视作您已接受了修改后的条款。除非得到科码先锋的书面授权，否则任何人将不得修改本协议。',
    comallAgreement3:
        '2.1  甲方：指在北京科码先锋互联网技术股份有限公司的平台、产品、系统等注册成功，并在科码先锋平台上或使用科码先锋产品开设店铺、销售商品、提供服务的公司、个人、个体工商户等。',
    comallAgreement4: '2.2   乙方：北京科码先锋互联网技术股份有限公司',
    comallAgreement5:
        '2.3  短信功能：指由科码先锋向甲方提供的、在北京科码先锋互联网技术股份有限公司系统后台可开通短信通知的功能。',
    comallAgreement6: '2.4   用户：仅指“短信接受方”。',
    comallAgreement7:
        '3.1 如甲方及其关联企业共用甲方短信账户发送短信的。甲方在此确认，凡通过甲方账户发送的信息均视为甲方的行为，甲方上述关联企业的行为视同为甲方的行为。甲方向乙方保证上述关联企业严格按照本协议约定使用乙方软件及短信发送服务，如上述关联企业未按照本协议约定使用服务的，则视为甲方违约，甲方应按照本协议约定向乙方承担相应的违约责任。',
    comallAgreement8:
        '3.2 甲方保证使用乙方短信业务仅向其内部员工或注册客户（以下统称“用户”）发送短信，甲方只有在获得用户同意接收的确认后，方可向用户提供短信业务服务，用户同意后又明确拒绝接收短信息的，应当立即停止向其发送。甲方保证，向其用户提供行业短信业务服务前，已明确告知用户服务内容的业务形式、使用方法、取消方式等并能够证明已获得用户同意（书面、互联网上确认等）。当产生用户投诉时，甲方应提供用户同意接收短信的证明文件。',
    comallAgreement9:
        '3.3 乙方提供的短信产品的用途仅限于甲方进行内部员工管理，或者甲方向其自身客户发布与其业务相关的信息。甲方不得擅自超出使用许可提供信息服务，不得将集团短信服务代码用作其他非本协议约定的用途，未经乙方事先书面同意，甲方不得以转售、转租、借用、代发、嵌入（指嵌入未与乙方具有合作关系的产品）等方式提供给第三方使用，不得将集团短信业务服务代码用于个人业务产品的宣传、推广（包括SP业务短信等)。甲方如通过乙方软件向用户提供有关资讯内容，需要向国家有关部门申请相关的资质证明，并自觉遵守相关规定。',
    comallAgreement10: '3.4 甲方需在此声明并保证使用短信功能发送信息时，不包含以下任一内容：',
    comallAgreement11:
        '(a) 涉嫌欺诈、涉嫌提供虚假信息、涉嫌销售或试图销售假冒伪劣及盗窃的物品以及其它法律法规限制或禁止销售及推广的物品。',
    comallAgreement12:
        '(b)涉嫌侵犯或教唆他人侵犯第三方的版权，专利，商业秘密或其它合法权利，或涉嫌不正当竞争。',
    comallAgreement13:
        '(c)涉嫌违反相关法律，法规，规章或决定(包括但不限于国家出口限制，消费者保护，不正当竞争和歧视或虚假广告)。',
    comallAgreement14: '(d)涉嫌含有侮辱、诽谤，恐吓或非法骚扰等内容。',
    comallAgreement15: '(e)涉嫌含有淫秽，或包含任何色情内容。',
    comallAgreement16: '(f)涉嫌含有种族，性别，地区，民族，生理残疾等歧视性或倾向性内容。',
    comallAgreement17:
        '(g)涉嫌含有蓄意毁坏、恶意干扰、秘密地截取或侵占任何系统、数据或个人资料的任何病毒、伪装破坏程序、电脑蠕虫、定时程序炸弹或其他电脑程序。',
    comallAgreement18:
        '(h)短信内容包含房地产广告、发票、移民信息等非认可或非您正常交易、推广所需发送的内容；',
    comallAgreement19: '3.5 甲方应自行对您通过短信功能发布的内容所引起的一切法律纠纷承担全部责任。',
    comallAgreement20:
        '3.6 甲方使用短信功能应当符合本协议及其他使用规范的要求，并保证不违反中华人民共和国法律法规以及对您具有法律约束力的其他任何文件，也不构成对任何第三人任何形式的侵权，保证不会因向其提供短信功能而构成违法、违约或者对任何第三人的侵权，保证使用短信功能不具有任何恶意，如利用短信功能发布不发信息等。',
    comallAgreement21:
        '4.1 乙方承诺，将恪守诚信经营原则，在使用短信功能时，如您有下列情形之一的，乙方同意甲方有权不经通知您即立即单方停止乙方的短信功能，不予退还乙方已充值的使用费，且无需承担任何违约责任。',
    comallAgreement22: '(a)涉嫌利用短信功能实施欺诈或其他违法行为的（甲方有权单方对此作出认定）；',
    comallAgreement23:
        '(b) 未经有甲方面同意，擅自许可他人使用短信功能或替他人利用短信功能发送短信或擅自将短信功能转让或部分转让他人的（包括但不限于擅自许可他人使用其帐号和密码，或擅自将其帐号和密码转让或泄露给他人的）；',
    comallAgreement24:
        '(c) 利用短信功能或计算机病毒/程序等手段，非法窃取、删除、修改或增加乙方或乙方其他商家的任何信息，或以其他任何方式危害乙方任何产品的正常运行的；',
    comallAgreement25: '(d) 违反其他约定或网站规则的。',
    comallAgreement26:
        '4.2 如因上述造成乙方损失的（包括但不限于依法必须支付给第三方的赔偿款、诉讼费、律师费、公证费等），均由甲方承担。',
    comallAgreement27:
        '5.1  甲方在此明确声明对甲方短信功能不作任何明示或暗示的保证，包括但不限于无漏发、准确性之类的保证、声明或承诺。',
    comallAgreement28:
        '5.2 甲方对甲方短信功能所涉的技术和信息的有效性，准确性，可靠性，质量，稳定，完整和及时性均不作承诺和保证。',
    comallAgreement29:
        '5.3 甲方对乙方的任何通知，不论是口头还是书面的，均不表示甲方做出在本协议陈述以外的任何担保责任。',
    comallAgreement30: '5.4   对下列情形，甲方不承担任何责任：',
    comallAgreement31: '(a)并非由于甲方的故意或过失而导致甲方短信功能未能提供的；',
    comallAgreement32: '(b)由于乙方的故意或过失导致您及/或任何第三方遭受损失的；',
    comallAgreement33: '(c)乙方违反本协议，或违反甲方的其它协议、合同、约定、及/或规则。',
    comallAgreement34: '(d)因不可抗力导致甲方不能履行其义务的。',
    comallAgreement35: '6.1 本协议取代双方就有关本协议所载任何事项于先前以口头及书面达成的共识。',
    comallAgreement36: '6.2   本协议将受中国法律约束。',
    comallAgreement37:
        '6.3 如本协议的任何条款被视作无效或无法执行，则上述条款可被分离，其余部份则仍具有法律效力。',
    comallAgreement38:
        '6.4 本协议的标题仅为方便阅读所设，非对条款的定义、限制、解释或描述其范围或界限。',
    comallAgreement39:
        '6.5 乙方于甲方过失或违约时放弃本协议规定的权利的，不得视为其对乙方的其他或以后同类之过失或违约行为弃权。',
    get: '获得新增奖励',
    highest: '最高',
    bj_2: '保价',
    reminderExtra: '配置后，文案展示在前台小程序结算中心处',
    sjzstq: '商家至少提前',
    dan: '单',
    qsrxxbtbcg: '请输入消息标题，不超过20个字',
    stop: '停止',
    selectedSubsiteMerchant: '已选门店/专柜',
    selectedSubsiteMerchant_2: '选择门店/专柜',
    fen: '分',
    noteId: '笔记ID',
    followScoreExtra:
        '笔记得分=点赞数*点赞得分+评论数*评论得分+分享数*分享得分+收藏数*收藏得分+通过当前笔记新增粉丝数*新增粉丝得分',
    creationRewardTypeExtra:
        '全部内容包含作者历史累计发布的全部图文//视频笔记，满足门槛发放奖励。单条内容按作者发布的单个图文//单个视频，满足门槛发放奖励。',
    rewardCalculationExtra:
        '如不勾选不包含作者自己收藏，则作者首次自己发布的图文//视频均计算被收藏数量。如不勾选单个用户收藏单个图文//视频多次收藏仅计算一次，则用户收藏图文//视频多次计算多次。',
    rewardCalculationExtra2:
        '如不勾选不包含作者自己点赞，则作者给自己发布的图文//视频//评论均计算被点赞数量。如不勾选单个用户单条图文//视频//评论仅计算一次，则用户点赞在取消在点赞，计算2次点赞。',
    rangeExtra: '按单条内容计算，即作者发布的单个图文//单个视频，满足门槛发放奖励',
    lotteryTimeExtra: '如不填写，将在活动结束后，次日0点自动开奖',
    limit600Input: '请输入600个字符以内',
    jmgz: '每关注',
    jzyscgztrzsjl: '只有首次关注他人赠送奖励，历史关注过取关再关注不重复赠送',
    activityRewardLimitExtra:
        '默认语灰色字体展示为：举例：活动时间10月1日～10月30日一个月，设置每周连续登陆2天获得奖励，任务时间内2次奖励后，不在获得奖励。用户第一周、第三周满足条件获得奖励，第四周用户满足条件也不在发放奖励。',
    rewardCalculationExtra3:
        '如不勾选只有首次相互关注赠送奖励，则只要相互关注就赠送激励，取关后再相互关注还赠送奖励',
    bcsbgsxydxdysydgs: '保存失败，个数下一档需大于上一档个数',
    jqtxdxghdyxjl: '请填写第X个获得以下奖励的数值',
    jfxwb: '分享(外部)',
    jpzhdskjhdglbq:
        '配置活动时（如积分商城、商场活动）可将活动关联标签，用户在前台活动页面可看到该标签',
    previewMode: '生成H5落地页',
    previewModeExtra:
        'H5落地页用于投放在第三方平台，显示专题内容，用户在H5页内点击可唤起微信小程序对应的专题活动页',
    lqsrbcg10: '请输入，不超过10个字符',
    lqsrbcg15: '请输入，不超过15个字符',
    lxfczswz1: '用户未关注时门店首页、会员中心一次登录中同一门店展示过一次就不会再次弹出',
    lxfczswz2: '支付结果页和专题活动页用户每次进入都会弹出弹框',
    transAmount: '变动积分',
    jybd: '预绑定',
    tagInfo: '类型-标签',
    jiosydd: 'IOS移动端',
    regIp: '注册IP',
    dyId: '抖音门店ID',
    modifyFailWait: '修改失败，请稍后重试',
    picLimit36: '图片尺寸建议为100px*36px',
    l_picLimit_310: '图片尺寸建议为650px * 310px',
    iconLimitTips: '建议图片宽度为100px，高度不超过36px；大小不超过1M；',
    cornerIconLimitTips: '建议图片宽度为50px，高度不超过50px；大小不超过1M；',
    cornerIconExtra: '图片尺寸建议为50px*50px',
    jyxxxpzhzhy: '以下信息配置后在会员',
    jyhscdlsdmd: '用户首次登录magic时的门店',
    jasmineyhscdlsdsj: '用户首次登录magic时的时间',
    birthday: '生日',
    jhqlylysj: '获取来源(来源事件)',
    jhxmdhxzg: '核销门店-核销专柜',
    jhxtksj: '核销/退款时间',
    jmagicwxxcx: 'MAGIC-微信小程序',
    jmagiczfbxcx: 'MAGIC-支付宝小程序',
    jmagicazkhd: 'MAGIC-安卓客户端',
    jmagicpgkhd: 'MAGIC-苹果客户端',
    jmagicwyd: 'MAGIC-网页端',
    jmagicdouyin: 'MAGIC-抖音小程序',
    crm: 'CRM',
    jttdwmc: '抬头/单位名称',
    jcrmyhbcz: 'CRM用户不存在',
    jdwy: '单位：元',
    jdddxyg: '到店动线(预估)',
    jshjey: '售后金额（元）',
    inAllNumProduct: '共{{num}}种商品',
    magicOrder: 'magic订单',
    jqsrzd30gzf: '请输入，最多30个字符',
    qsrkdjmbmcbcg20z: '请输入卡等级模版名称，不超过20个字',
    jzc8z10wszzm: '支持8～10位数字、字母',
    jwxhyksjhwxstnl: '微信会员卡是结合微信生态能力提供的会员拉新、促活解决方案，可实现会员快速开卡',
    jcwwxzfdppsj: '2.成为微信支付的品牌商家，请联系您的客户经理先行完成线下申请',
    jmqwxhykjzcjttywxhyk:
        '4.目前微信会员卡仅支持集团统一微信会员卡，不支持各门店将自己的会员卡插入微信卡包',
    jhyxgbhjtggfwhcdyh: '会员相关（会员等级、积分）变化将通过该服务号触达用户。可前往',
    jgzhglgzh: '公众号->管理公众号',
    jjxbd: '进行绑定',
    brandIdExtra: '使用集团品牌创建后，微信生成的品牌id，提交后，不允许修改',
    cardLogo: '会员卡LOGO',
    qschyklogo: '请上传会员卡LOGO',
    logoPicExtra: '120*120像素，只支持JPG、BMP、PNG格式，文件大小不能超过2M',
    jzszkms: '1.展示在卡面上',
    jzczwzywzfbd: '3.支持中文字、英文字符、标点',
    jsyxtfpdkh: '使用系统分配的卡号，用户可在卡包中出示二维码//条形码，使用pos扫码识别卡号',
    picLimit380: '380像素，只支持JPG、BMP、PNG格式，文件大小不能超过2M',
    zdzc8gz: '最多支持8个字',
    jbcg4gz: '不超过4个字，例如：我的积分',
    r500jfdy2xstcq: '如500积分=2小时停车券',
    ji: '及',
    jbcg8gzwdfp: '不超过8个字，例如：我的发票',
    jfptzym: '发票跳转页面',
    bgPictureExtra: '图片尺寸建议为宽750px*高340px',
    levelId: '等级ID',
    jIconPictureExtra: '建议尺寸：90px * 36px，小于1M，支持jpg，png，jpeg格式',
    levelIconNotEmpty: '等级icon不能为空',
    jhspbj: '含视频笔记',
    jbq: '并且',
    jdjjclbnwk: '的降级策略不能为空',
    jdnxfxdy: '的年消费需大于',
    jdyxfxdy: '的月消费需大于',
    jdyxfe: '的月消费额',
    jbyyf: '保有月份',
    subsiteId: '门店Id',
    jcp: '差评',
    have: '有',
    jqsrhfnrzfyn: '请输入回复内容，500个字符以内',
    jqsrhsnrzcz: '请输入话术内容，最长500字',
    jqsrszzydpxykq: '请输入数字，值越大排序越靠前',
    jyplylhdbcgzf: '如：8月评论有礼活动，不超过20字',
    jzdshtgdsyplffjl: '针对审核通过的所有评论发放奖励',
    frontDayMessage: '请填写1-15天',
    l_frontDayMessage: '请填写1-100的正整数',
    jpictureExtra: '尺寸：750px（宽度），小于5M，支持jpg，png，jpeg格式',
    jmultiple: '倍',
    h_mgcydhgkr: '敏感词用逗号隔开如:(A,B,C)',
    h_phone: '电话',
    h_zdksz: '最多可设置',
    h_effective_days_limit_msg:
        '请输入限制开票有效期天数，仅支持输入正整数，最小可设置1天，最大可设置9999天',
    h_change_count_limit_msg: '请输入允许换开次数，仅支持输入正整数，最大可换开10次',
    h_change_count_limit_placeholder: '最多可换开10次',
    h_parking_invoice_form_region_validate_msg:
        '地址中（包含车场区域信息）且必须包含 街、路、村、乡、镇、道 关键词，最多80个字符',
    h_invoice_info_by_subsite_tip:
        '选择全部专柜代表该专柜类型下的全部专柜生效，选择指定专柜时需要指定该专柜类型下的专柜',
    h_invoice_mode_tip: '选择数电票请先确认已在税务开通，并与平台完成了数电票系统对接',
    h_select_city_address_placeholder: '请输入详细地址(无需输入地区信息)',
    h_ts_kfyqshan: '天时，开放延期收货按钮',
    h_print_remark_tip:
        '填写后，会展示在小票底部-门店信息的下方，如【在购物中如有问题请及时联系我们】',
    h_template_one: '模板一',
    h_template_two: '模板二',
    h_template_three: '模板三',
    h_style: '样式',
    h_order_invoice: '订单//发票',
    h_zj7d: '最近7天',
    h_zj15d: '最近15天',
    h_zj30d: '最近30天',
    h_zj60d: '最近60天',
    h_zj90d: '最近90天',
    h_zj180d: '最近180天',
    h_zj365d: '最近365天',
    h_allow_login_falures_tips_before: '超出后，系统自动对账号做冻结操作，可使用上一级账号到',
    h_allow_login_falures_tips_link: '权限->账号管理',
    h_allow_login_falures_tips_after: '处做解冻操作',
    h_allow_login_falures_tips_msg: '抱歉您未开通该功能栏目权限，请联系管理员设置',
    h_coupon_qrcode_setting_tip:
        '不配置，默认都是5分钟有效；配置后，控制「我的优惠券->二维码，售卖优惠券->订单二维码，积分商城兑换券->订单二维码」的有效期',
    h_zkfl: '展开分类',
    h_qtxsz_lsl: '请填写数字，例税率17%，则填写17',
    h_dqjzcxlxs:
        '2.当前仅支持xlxs、xls、csv格式文件（建议文件大小在20M以内且行数在10万以内，请使用Office',
    h_jysbbfz: '2010及以上版本，否则可能出现乱码',
    h_wjydcshkqw: '文件已导出，稍后可前往',
    h_wx: '无效',
    h_mk: '梦卡',
    h_stkxxztjsy: '(实体卡, 线下自提及使用)',
    h_dzkxxxssy: '(电子卡, 线上线下使用,可转赠)',
    h_value_card_title_msg: '请输入页面标题，不超过20个字',
    h_value_card_title_placeholder: '请填写页面标题。例如：储值卡',
    linkTypes: {
        none: '无链接',
        miniprogram: '它方小程序',
        urlLink: 'URL地址',
        subsite: '门店首页',
        platform: '平台首页',
        loginStorePage: '登录/注册页',
        cart: '购物车',
        newMerchant: '专柜首页',
        category: '标准/虚拟分类商品列表',
        categoryNav: '营销分类模板',
        categoryNavProduct: '营销分类商品列表',
        products: '普通商品列表',
        couponProductList: '虚拟商品列表',
        product: '商品详情',
        merchantGuide: '商场导览',
        merchantGuideBrand: '品牌馆',
        facilityGuide: '设施导览',
        fengmap: '地图导航',
        search: '搜索',
        newTopic: '专题活动',
        member: '会员中心',
        member_membership: '会员权益',
        liveInfo: '微信直播间',
        live: '微信直播列表',
        topicContent: '话题',
        contentNoteDetail: '笔记详情',
        marketing: '营促销活动',
        flashList: '秒杀列表',
        wheelDraw: '大转盘抽奖',
        invite: '邀请有礼',
        checkinIndex: '签到有礼',
        cardCollection: '集卡活动',
        collectionPoint: '集点卡',
        watchword: '口令领券',
        redPackageRain: '红包雨',
        marketing_wechatOfficialAccounts: '关注公众号',
        sharingReward: '分享有礼',
        marketing_paymentRewardActivity: '支付有礼',
        marketing_paymentReward: '支付奖励',
        groupBuyingList: '普通商品拼团列表',
        couponProductGroupList: '虚拟商品拼团列表',
        marketing_groupBuyingDetail: '拼团商品',
        blindBoxList: '盲盒活动列表',
        blindBox: '盲盒活动',
        marketing_openBoxDetail: '开盒有礼',
        fullPayment: '全款预售',
        reserve: '预约抢购',
        marketing_maotaiPreSaleList: '茅台预售列表',
        marketing_maotaiPreSaleDetail: '茅台预售商品',
        internalPurchase: '企业内购',
        marketing_fixedPrice: '打包一口价',
        marketing_bargainActivityList: '砍价活动列表',
        marketing_bargainActivity: '砍价活动',
        credit: '积分活动',
        member_creditEshop: '积分商城',
        credit_creditShopDetail: '积分商城商品',
        scratchCard: '积分刮刮卡',
        lotteryCode: '积分抽奖码',
        member_selfCrediting: '自助积分',
        fastIntegrate: '快速积分',
        fastIntegrateMerchant: '快速积分商家',
        pointExchange: '积分换余额券',
        'member-menu': '会员相关活动',
        serviceReserved: '服务预约',
        reserveService: '服务预约列表',
        mallActivity: '商场活动',
        member_mallActivity: '商场活动列表',
        member_birthdayCaring: '生日有礼',
        member_storedValueCard: '储值卡首页',
        member_restaurantReservation: '餐厅订座',
        member_reserveGift: '礼品预约',
        service: '服务工具',
        service_identification: '个人信息',
        service_passwordEditor: '重置密码',
        member_parking: '停车缴费',
        member_coupon: '我的优惠券',
        service_onlineCoupon: '线上券',
        service_exchange: '兑换优惠券',
        service_balanceCoupon: '我的余额券',
        service_thirdCoupon: 'CRM券',
        service_distribution: '分销赚钱',
        service_virtualOrder: '虚拟商品订单',
        service_ordinaryProductOrders: '普通商品订单',
        service_myLotteryCode: '我的抽奖',
        service_reserveService: '我的服务预约',
        service_myMallAcivityOrder: '我的商场活动',
        service_myRestaurantReservation: '我的预订',
        service_invoiceCenter: '我的发票',
        service_findCars: '寻找车辆',
        service_myCars: '我的车辆',
        service_myPoint: '我的积分',
        service_couponTransfer: '赠送优惠券',
        member_offlineOrderComment: '电子小票',
        member_feedbackAdd: '意见反馈',
        member_feedback: '我的意见反馈',
        service_openInvoice: '开具发票',
        service_topicActivities: '创作排行',
        service_connectWifi: 'Wi-Fi一键连',
        popupPicture: '图片弹窗',
        defaultMerchant: '专柜首页',
        gamePlatform: '游戏平台',
        anniversary: '周年庆报告',
        dreamService: '梦服务',
        service_storedValueCardOrder: '储值卡订单',
        service_depositOrder: '订金订单',
        service_offlineDepositOrder: '线下订金单',
        service_storeValueCardOrder: '储值卡订单',
    },
    h_input_url: '请填写URL',
    h_xcxappid: '小程序appId',
    h_qtxxcxappid: '请填写小程序appId',
    h_qtxwxxcxappid: '请填写微信小程序appId',
    h_ysid: '原始ID',
    qzdl: '强制登录',
    h_jycc64: '建议尺寸：64*64像素',
    jPicLimit700: '尺寸： 700*800，大小： 不超过1M，格式：JPG、JPEG、PNG',
    jqsrfwmcbcg20gz: '请输入服务名称，不超过20个字',
    wifiDevice: 'Wi-Fi设备',
    wifiPictureConfigExtra: '建议尺寸750*300， 2M以下',
    ssidNotEmpty: 'SSID不能为空',
    ssidzd32gzf: 'SSID最多32个字符',
    wifiNameSSID: 'Wi-Fi名称SSID',
    wifiPassword: 'Wi-Fi密码',
    jqsrxxdzbcg10z: '请输入详细地址，不超过100个字',
    jjyccbcg1m: '建议尺寸:750 * 422  大小:不超过1M  格式:JPG,PNG,JPEG,AVIF,WEBP',
    posterLimitExtra: '建议尺寸:560*560   大小:不超过1M  格式:JPG,PNG,JPEG,AVIF,WEBP',
    infoExtra: '建议传宽度为750px的图片；建议字号选择为14px',
    targetUserTypeExtra:
        '配置后，只能指定人群参与，不符合的人群在活动详情页点击报名//购买时会给提示语：抱歉～您不符合活动报名条件，如有疑问请咨询客服',
    jxzbxyshgmjkcyhd:
        '选择[不需要审核]，购买即可参与活动；选择[需要审核]，购买后需要在后台对订单进行审核，审核不通过或者活动结束仍未审核则自动退款',
    jqxgzdzfdddzkz:
        '取消规则对【支付成功，且审核通过】的订单做控制，即订单审核通过后前端不展示「取消订单」按钮，用户无法发起取消',
    excludeRuleExtra1: '选择【不互斥]】，报名任何活动后都可以报名此活动',
    excludeRuleExtra2: '选择【全部活动均排斥此活动】，报名其他活动后不能再报名此活动',
    excludeRuleExtra3: '选择【部分活动均互斥此活动】，报名指定活动后不能在报名此活动',
    creatorId: '活动发起人ID',
    rewardDistributionTimeExtra:
        '奖品发放后不追回，如必须用户参加活动才能得到奖品，请选择核销活动后发放',
    seniorSettingExtra1:
        '通过订阅消息发送活动报名开始提醒、报名名额空缺提醒、报名成功提醒、活动参与提醒，',
    seniorSettingExtra2:
        '需要开启小程序订阅消息功能，并提前配置订阅消息模版id，未配置则不能发送消息',
    subscriptMessage: '活动参与提醒',
    subscriptMessageExtra:
        '用户报名时间早于提醒时间，到提醒时间可收到提示，否则用户无法收到开始提醒',
    mallActivityStatisticTotal: '参与人数//报名人数//活动名额',
    restore: '还原',
    aspectRatioOptionsFree: '自由',
    aspectRatioOptionsRatio11: '1:1',
    aspectRatioOptionsRatio43: '4:3',
    aspectRatioOptionsRatio169: '16:9',
    aspectRatioOptionsRatio23: '2:3',
    jdsgdjjg: '共{{num}}个等级价格',
    jszhdjhxtjzxhdj:
        '设置活动价后，系统将执行活动价，若活动价与免费名额同时设置，优先执行免费名额后再执行活动价',
    jqbdjtygjg: '全部等级，同一个价格',
    jbtdjbtjg: '不同等级，不同价格',
    jqbdjtyggz: '全部等级，同一个规则',
    jbtdjbtgz: '不同等级，不同规则',
    jvenue: '场次',
    jzjcgzzsrtsfhdgjbgz: '在基础规则之上，如同时符合多个加倍规则，执行时倍数叠加执行',
    jzjcgzzszxsqgbsgzzx: '在基础规则之上，如同时符合多个加倍规则，执行时取其高倍数规则执行',
    jbdy1jf: '金币 = 1 积分',
    qsrfwnzhjdsz: '请输入1～9999之间的数字',
    jdkdwdydsz: '抵扣单位请填写>0的正整数',
    jdkjeqtxdydsz: '抵扣金额请填写>0的数字，仅支持两位小数',
    getYeveryX: '每满{{x}} 元，送{{y}} 元',
    jzdsy: '最多使用',
    h_value_card_ms_placeholder:
        '此卡为{subsiteName}{cardName},  仅限{subsiteName}线下使用,  祝您购物愉快',
    h_value_card_ms_tip:
        '配置后，在结算中心提交订单时会确认相关信息，可设置变量{subsiteName}：门店名称，{cardName}：卡类别名称，请注意变量名大小写，以及符号',
    h_ysmj: '元， 售卖价',
    routers: {},
    loader: {},
    type: {
        arrayTreeNodeTree: {
            label: '记录',
            sum: '总计',
        },
    },
    error: {
        requestFailed: '请求失败',
        requestTerminated: '请求已被终止。',
    },
    validation: {
        number: {
            default: '请输入合法的数字',
            integer: '请输入合法的整数',
            positive: '请输入合法的正数',
            price: '请输入合法的价格',
        },
        array: {
            default: '请输入合法的数组',
        },
        boolean: {
            default: '请输入合法的布尔值',
        },
        object: {
            default: '请输入合法的对象',
        },
        string: {
            default: '请输入合法的字符串',
        },
    },
    change_language: '切换语言',
    picUploadTip: {
        one: '1.图片格式支持png、jpg',
        two: '2.文件夹内图片数量不超过100张,',
        three: '命名规则如下:',
        four: '专柜logo命名为“logo_专柜编码”（如：logo_00001）',
        five: '专柜宣传图命名为“XC_专柜编码”（如：XC_00001）',
    },
    merchantSelfTitleType1: '闭店后该专柜下的商品将会变更为下架状态，确定修改为闭店状态吗？',
    merchantSelfTitleType2: '暂停后该专柜下的商品将会变更为下架状态，确定修改为暂停状态吗？',
    closeStoreIng: '闭店ing',
    keywordsErrorMessage: '请输入商品名称/国际条形码/商品编码/品牌/标签',
    mostFillInPhoneNum: '最多可填写999个手机号码',
    tgfwfdd: '推广服务费-订单',
    tgfwfbfshsysp: '推广服务费-部分售后剩余商品',
    tgfwfhc: '推广服务费-红冲',
    priceRuleExtra: '如设置99元任选2件，金额不包含运费，最多支持3档阶梯',
    yuanAndCasual: '元，任选',
    suppoert3Count: '最多可支持3档，不可再添加',
    consumeTradeNo: '核销流水号',
    marketMemberRegister: '事件营销-会员注册',
    marketReceiveOrder: '事件营销-订单奖励',
    marketPaymentPosReceipt: '事件营销-支付奖励（线下）',
    marketParmentOrder: '事件营销-支付奖励（线上）',
    marketPaymentBusinessMall: '事件营销-支付奖励（商圈）',
    notificatonContentDefaultValue: '尊敬的用户，您有content已到账，请尽快使用>>查看: shortLink',
    jblzdzddqfsyh:
        ' 变量字段：content会自动读取发给用户券张数和券名称，shortLink为“我的优惠券”链接，如更改变量字段则无法自动读取对应内容。案例：尊敬的用户，您有1张满100减10元的优惠券已到账，请尽快使用&gt;&gt;查看:',
    messageAgreeMent: '短信服务协议',
    pointGt: '积分≥',
    unitPriceGt: '客单价≥',
    transactionsNumGt: '交易次数≥',
    totalPriceGt: '消费金额≥',
    canSignUp: '的可报名',
    canActivity: '可参加活动',
    UserRegisteredBetween: '之间注册的用户',
    maotaiMessageTitle:
        '小程序订阅消息、公众号模版消息、短信：勾选后只有申购成功发送，未申购成功不发送',
    maotaiMessageName: '名称：抽奖结果通知',
    maotaiMessageActivity: '活动名称：5月茅台申购活动',
    maotaiMessageResult: '抽签结果：恭喜您申购成功了！',
    maotaiMessageSubsite: '门店名称：北京百货大楼店',
    maotaiMessageCp: '抽签藏品：商品名称',
    maotaiMessageSuccess: '中签结果：申购成功',
    maotaiMessageTime: '截止时间：2022年6月1日18：00',
    maotaiPresaleAct: '每个用户只能在一个门店下报名成功',
    notAbove20Char: '不超过20个字',
    dnxfe: '的年消费额',
    shopCelebration: '如：8月店庆活动，不超过20字',
    imgLimitTips: '建议图片宽度为750px，高度250px；大小不超过1M',
    annualWelfareAddFormExtra: '尺寸: 750*350像素，小于1M，支持jpg、png、jpeg格式',
    blackCardDiscount: ' 黑卡9.5折',
    setNamePic: '请设置{{name}}图标',
    picLimitHeight50: '图片尺寸建议为165px*50px',
    qqrmdpzfpxxdjrkmfy: '请确认门店配置发票信息，否则点击入口没有反应',
    mchntIdContent:
        '收款方ID（2088开头的16位纯数字），由停车场收款的业主方提供给ISV，该字段仅记录不作为实际收款使用',
    amapPoiIdTitle: '如何获取POI',
    amapPoiId: '车场POI',
    moneyYuan: '金额(元)',
    jyxwsxzdhybdcph: '注：以下为生效中的会员绑定车牌号关系，已解锁的历史绑定关系不在记录中展示',
    goldClearRuleTip1:
        '如设置为“在1年后的12月31日清零”，则用户在2021年度获得的金币，将在2022年12月31日23:59:59执行清零',
    goldClearRuleTip2:
        '如设置为“1年后清零”，则用户在2021年05月01日获得的金币，将在2022年05月01日00:00:00执行清零',
    failureAfter: '后失效',
    dayAndTo: '日 至',
    remainingParking: '（剩余车位396个）',
    beijing: '京',
    qtxdydje: '请填写大于0的金额',
    qtxdydydcs: '请填写大于等于0的次数',
    bookingParking: '预约车位',
    gzyd: '关注引导',
    gzyl: '关注有礼',
    sq_2: '授权',
    cksq: '查看授权权限',
    cxsq: '重新授权',
    gzgzhyl: '关注公众号有礼',
    followGiftStatus_tip: '请确保公众号为开启状态，奖励才能发送出去',
    gzhzs: '关注后赠送',
    followGiftReward_tip:
        '用户关注后系统自动将奖励发放到用户账户上，用户重新进入小程序通过弹窗获知奖励内容',
    jl_2: '奖励',
    zsgz: '赠送规则',
    zsgzText1: '1、设置规则前，用户（同一个UnionId）已关注公众号，不会补送奖励。',
    zsgzText2:
        '2、用户（同一个UnionId）第一次关注获得奖励后，取消关注后再重新关注，不会重复赠送奖励。',
    zsgzText3: '3、只给小程序的会员发送奖励，非会员粉丝无法发送奖励',
    checkRule: '用户之前未获得奖励，取关后又重新关注不可获得奖励。',
    gzhhf: '关注后回复',
    pzjl: '配置奖励后设置关注后回复的内容，及时告知用户获得了奖励',
    gzgzhylxx: '通过为用户赠送礼物的方式拉新',
    glgzhqx: '请先开通 营销活动->关注公众号有礼 栏目权限',
    bcmessageChannel: '消息渠道',
    smsContent: '尊敬的用户，您的生日礼{activityName}已准备好，请尽快领取>>查看: shortLink',
    yhaxdydyhcckfsxx: '用户需按次订阅，订阅后才可发送消息',
    srlwlqsm: '生日礼物领取提醒',
    lwmc: '礼物名称',
    dqhdmcc: '读取活动名称',
    lqmd: '领取门店',
    lqpzdhdmc: '领取配置的门店名称',
    dqmddz: '读取门店地址',
    hyncsrklbwjlql: '#会员昵称#生日快乐，别忘记领取了～',
    birthdayPage: '生日页',
    qqw: '请前往',
    xxts: '消息->消息推送',
    cdkgdyxx: '处打开该订阅消息',
    ckfsxx: '才可发送信息',
    ttpzlghdgz:
        '同时配置两个通知规则，会给用户在不同时间点发送两次通知。若用户的生日时间与活动时间发送通知时间重合，为避免对用户造成的打扰，只会给用户发送一次生日（举例：用户为6月1日的生日，活动时间为：生日当月，活动开始当天与会员生日当天通知重合，则会在6月1日给用户发送一次通知）',
    azhdsjfstz: '按照活动时间发送通知：',
    azhysrhdsjfstz: '按照会员生日时间发送通知：',
    rhdsjwsrdt: '若活动时间为：生日当天，则用户生日当天',
    hyhsrdqjtgyhfstt:
        '或用户生日的前x天给用户发送通知（举例：用户8月5日的生日，则8月5日时或前6天即7月30日给用户发送消息）',
    rhdsjwdrdy:
        '若活动时间为：生日当月，则用户生日月的第一天或用户生日月的月首前x天给用户发送通知（举例：用户8月5日的生日，则8月1日时或前6天即7月30日给用户发送消息）',
    rhdsjwsrqjt:
        '若活动时间为：生日前x天，则用户生日前x天或用户生日前x+y天给用户发送通知（举例：用户8月5日的生日，则前2天即8月3日时或生日前6天即7月30日给用户发送消息通知）',
    hysidtjxtz: '会员生日当天8:00，进行通知（举例：用户8月5日生日，则8月5日的8:00给用户发送通知）',
    tzsj: '通知时间',
    memberBirthday: '会员生日',
    jxtz: '进行通知',
    activityStart: '活动开始',
    wpmdyhgddr:
        '为避免对用户过多打扰，截止到21:00不再给用户发送消息，未收到消息的用户次日8:00会继续发送',
    pzgzsshhwyhfsxxtz: '配置规则生效后，会为用户发送消息通知，若规则关闭，则消息也不再发送。',
    cgqsgzfadtfs: '超过70个字符按多条发送',
    blzihzddqhdmm: '变量字段：activityName会自动读取活动名称，shortLink为“领取生日礼”的链接；',
    rgablzdzwfzddqdynr: '如更改变量字段则无法自动读取对应内容。',
    alzjdyhndsrl: '案例：尊敬的用户，您的生日礼“100元无门槛代金券”已准备好，请尽快领取>>查看:',
    khxmzgtzqddcftj: '客户需满足各通知渠道的触发条件才可正常接收通知',
    empty: '空',
    jtxshbtgdyy: '请填写审核不通过的原因，审核不通过的原因会展示给用户~   不超过500个字',
    jqsrshyyyyzyhd: '请输入售后原因（非必填），用于在用户端的报名记录中展示',
    jqsrkfbzyyjlbzxx: '请输入客服备注（非必填），用于记录备注信息，以便于同步给其他客服',
    display_mall_activity_status: '活动状态展示',
    display_mall_activity_status_tip:
        '配置不展示后，商场活动固定列表页（经典主题）、可视化装修后的前端页面中不展示活动状态',
    display_mall_activity_status_true: '展示活动状态',
    display_mall_activity_status_false: '不展示活动状态',
    display_mall_activity_stock: '剩余数量展示',
    display_mall_activity_stock_tip:
        '配置不展示后，商场活动固定列表页（经典主题）、商场活动详情页、选择票档页面、可视化装修后的前端页面中剩余数量等都不展示剩余数量',
    display_mall_activity_stock_true: '展示剩余数量',
    display_mall_activity_stock_false: '不展示剩余数量',
    toConfigureGiftCover: '去配置转赠封面',
    transferMobile: '赠送人手机号',
    wechatPayScoreTouchless: '微信支付分无感停车',
    virtualAssetTouchless: '无感优惠停车',
    totalParkingFee: '实际金额',
    unPayAmount: '待缴金额',
    hysjl: '会员升级礼',
    jllqfs: '奖励领取方式',
    sjhljff: '升级后立即发放',
    sjhljffTip: '会员升级后奖励立即发放到账户，可通过会同权益页查看奖励内容',
    yhzdlqTip: '会员升级后需到会员权益页领取相关奖励内容',
    sjl: '升级礼',
    lp: '礼品',
    qtxsjllpgz: '请填写升级礼礼品规则',
    qxzlphddj: '请选择礼品获得等级',
    sjlzsnrzsxzyg: '升级礼赠送内容至少选择一个',
    qtxsjlzsnrz: '请填写升级礼赠送内容值',
    qxzsjlzsnryhq: '请选择升级礼赠送内容优惠券',
    qtxsjlzsnryhqsl: '请填写升级礼赠送内容优惠券数量',
    fafang: '发放',
    hddj: '获得等级',
    touchlessParkingSystem: '无感缴费渠道',
    enabledTouchlessParking: '启用无感缴费',
    orderChannel: '下单渠道',
    douyinMiniP: '抖音小程序',
    upstreamOrderId: '渠道订单号',
    privilegeFollowReplyMessage: '请先开通【公众号->关注公众号回复】 栏目权限，再编辑',
    qwztxgzhzsnr: '请完整设置关注后赠送内容',
    allowDeposit: '订金业务',
    depositOrder: '订金业务订单',
    depositAfterSale: '订金业务售后单',
    depositOrderRefund: '订金业务退款单',
    kdmssz: '开单模式设置',
    xzkdmssz: '新增开单模式设置',
    kdmo: '开单模式',
    scspkd: '商城商品',
    spkd: '线下商品',
    djkd: '订金开单',
    kdmssyzg: '开单模式适用专柜',
    zsgxygkdms: '至少勾选1个开单模式',
    djpzm: '订金凭证码',
    dgzdgzsdglddhwdcdmkz:
        '导购在导购助手的管理订单或我的成单模块中可查看订金凭证码，凭证码用于线下销售使用订金时校验码。',
    djdh: '订金单号',
    djsjm: '订金商家码',
    zfptshddh: '支付平台商户订单号',
    ddjjfw: '订金金额范围',
    djze: '订金总额',
    xph: '小票号',
    djsyje: '订金使用金额',
    ddsxdj: '订单销售单据',
    iosApp: 'IOS系统APP',
    androidApp: '安卓系统APP',
    h5: 'H5移动端',
    zfptjydh: ' 支付平台交易单号',
    kddg: '开单导购',
    djsp: '订金商品',
    zfqd: '支付渠道',
    applyInfo: '申请信息',
    ddsjm: '订单商家码',
    sfje: '实付金额',
    ddshdh: '订金售后单号',
    shzt: '售后状态',
    ddshxx: '订金售后信息',
    refundInfo: '售后信息',
    sqshsj: '申请售后时间',
    shsqr: '售后申请人',
    xpdh: '小票单号',
    djdtk: '订金单退款',
    xsdtdj: '已提取订金退款',
    ygcfs: '预估触发数',
    ygcfsTip:
        '取人群中预估会员与订阅会员数的交集，因数据实时变化，故此处人数为预估，以实际执行人数为准',
    manageGiftWarn: '请先开通【管理礼品】 栏目权限，再编辑',
    xzlp_3: '选择礼品',
    qwhlptp: '请前往礼品管理维护礼品图片',
    jfscyfgz: '积分商城运费规则',
    symdyfgz: '使用门店运费规则',
    qxzlp: '请选择礼品',
    sfzcdjyw: '是否支持订金业务',
    kdr: '开单人',
    zxfs_2: '注销方式',
    xszx: '线上注销',
    xxzx: '线下注销',
    bxszx: '不显示注销',
    zxts: '注销提示',
    zxtsRule: '您可输入注销提示',
    zxxy: '注销协议',
    thirdRequestLog: '三方日志查询',
    ywlx: '业务类型',
    jgms: '结果描述',
    ckmx: '查看明细',
    jkms: '接口描述',
    qqdz: '请求地址',
    qqbw: '请求报文',
    xxbw: '响应报文',
    xxzt: '响应状态',
    qqsj: '请求时间',
    cxqq: '重新请求',
    cxqqcg: '重新请求成功',
    zsrq: '赠送日期',
    receiverMobile: '接收人手机号',
    zszt: '赠送状态',
    transferring: '转赠中',
    withdrawn: '已撤回',
    received: '已接收',
    receiveFailed: '接收失败',
    rejected_2: '已拒收',
    receiveTime: '接收时间',
    yxdqgl: '营销档期管理',
    yxdqmc: '营销档期名称',
    hddqsj: '活动档期时间',
    cjdq: '创建档期',
    yxdq: '营销档期',
    dqsj: '档期时间',
    hynx: '会员粘性',
    thirdId: '三方ID',
    thirdActivityId: '三方活动id',
    yxrl: '营销日历',
    cyhd: '参与活动',
    collectionPoints: '集点卡',
    collectionPointsCoupon: '集点卡兑换券',
    collectionPointsTip: '集点游戏化，兑换激励，持续复购',
    collectionPointsRewardsRecord: '兑换记录',
    hdjlsj: '获得时间',
    shdbh: '售后单编号',
    jdsl: '集点数量',
    jds: '集点数',
    xhyhs: '消耗集点数',
    rxfffwdzxpxqr: '若消费范围为「电子小票」，需确认电子小票中要有对应专柜，规则才会执行',
    rxzxxdzxp: '若选择线下电子小票、需联系客户成功经理开通后才可使用',
    rwpzzwsx:
        '若未配置，则无上限，用户消费后根据规则系统自动赠送点；配置后，用户当日消费获得的集点达到最大上限后，再消费系统将不会再赠送，0:00后重新计算赠送集点',
    pageRule: '页面规则',
    obtainRule: '获得规则设置',
    limitEveryDay: '集点上限',
    ddfssh: '订单发生售后，判断排除售后金额后是否仍满足门槛，若不满足则退回对应点',
    ykjyd: '元，可集1点',
    hdygjd: '元获得1个集点',
    dbxfmm: '单笔消费每满',
    jdgz: '集点规则',
    jdmx: '集点明细',
    dhzq: '兑换专区',
    qdh: '去兑换',
    kyds: '可用点数',
    jm: '集满',
    jd_2: '集点',
    jdkduyhq: '个集点可兑换优惠券',
    yjdtb: '已集点图标',
    wjdtb: '未集点图标',
    qsrdbxfm: '请输入单笔消费满金额',
    qsrdbxfmm: '请输入单笔消费每满金额',
    qtjdhgz: '请添加兑换规则',
    qtxdhgzdjmdgs: '请填写兑换规则的集满的个数，不可为空',
    qsrmrmtffgs: '请输入每人每天发放个数',
    hdqjzdff: '活动期间最多发放',
    qsrhdqjzdffgs: '请输入活动期间最多发放个数',
    qxzmrtb: '请选择默认图标',
    qsczdytb: '请上传自定义图标',
    dhdbh: '兑换单编号',
    collectionPointsRewardjt: '保存失败，第{{index}}个阶梯集满个数需要大于上个阶梯的集满个数',
    collectionPointsRewardsm: '每人每次发放的数量不得大于活动期间该券规则发放的份数',
    collectionPointsBgSize: '图片尺寸建议为宽750px，高不限，大小2M以下，支持jpg,png格式',
    collectionPointPicSize: '图片尺寸建议为宽50px*高50px，大小2M以下，支持jpg,png格式',
    increaseSalesVolume: '提升销量//复购',
    increaseSalesVolumeOther: '提升销量/复购',
    attractTrafficNewCustomers: '引流拉新',
    marketing: '营销推广',
    promoteInteractiveActivities: '促活互动',
    glwz: '管理文章',
    wzmc_1: '文章名称',
    wzmcInputPlease: '请输入文章名称',
    wzsx: '文章顺序',
    wzsxSortTip: '数字越大，越靠前展示',
    wznr: '文章内容',
    wznrInputPlease: '请输入文章内容',
    wznr_notMoreThan2000_1: '请输入文字内容，不能超过20000个字',
    versions: '版本号',
    versionsTip: '版本号发生变化时小程序中会展示更新提醒，如不变或不填，则不提醒',
    addArticle: '新增文章',
    editArticle: '编辑文章',
    isCloseArticle: '是否关闭文章',
    isOpendArticle: '是否开启文章',
    articleTip: '文章配置后将会展示在对应的固定位置',
    yardManagement: '车场管理',
    noNamed: '未命名',
    addCustomMenu: '+ 添加自定义菜单',
    designatedLevelVisible: '指定等级可见',
    jumpMethod: '跳转方式',
    maxSelectSix: '最多只能勾选6个菜单',
    fillInMenuName: '请输入菜单名称',
    setBookParkingLinkType: '请设置预约车位三方链接目标',
    setBookParkingCardLevel: '请设置预约车位可见会员卡等级',
    setCustomMenuLinkType: '请设置{{name}}的链接目标',
    crmId: 'CRM ID',
    consumeAndExchangeRecord: '核销 // 转赠记录',
    exchangeRecord: '转赠记录',
    sx: '顺序',
    watchwordCouponCollection: '口令领券',
    watchwordCouponCollectionDescription: '输入口令，领取红包，增加裂变，提升复购',
    watchwordContent: '口令内容',
    watchwordMessage: '口令内容不建议太长，用户无法很好的输入',
    everyBodyActivityReceive: '每人在活动期间内最多可领取',
    displayWatchwordEntry: '展示获取口令入口:',
    showWatchwordEntry: '展示获取口令入口',
    hiddenWatchwordEntry: '隐藏获取口令入口',
    watchwordBackgroundImageExtra: '图片尺寸建议为宽750px*高1134px',
    watchwordBtnImageExtra: '图片尺寸建议为宽532px*高60px',
    watchwordPictureExtra: '图片尺寸建议为宽30px*30px',
    watchwordTitle: '输入口令 立抢红包',
    pleaseWatchword: '请输入口令',
    noWatchwordToclick: '没有口令？猛戳我',
    viewMyCard: '查看我的卡包',
    redeemNow: '立即兑换',
    watchwordErrorMessage:
        '支持英文大小写、特殊字符（¥，！，@，～，%）、汉字，不允许输入标点符号及空格',
    watchwordExtra:
        '支持英文大小写、特殊字符（¥，！，@，～，%）、汉字，不允许输入标点符号及空格；口令内容不建议太长，用户无法很好的输入',
    activitySendNum: '活动期间最多发放',
    useCarparkConfig: '使用车场配置（以接口返回为准）',
    systemName: '系统名称',
    carparkNameRequire: '请输入车场名称',
    carparkNameExtra: '如车场接口有返回则以车场返回值为最终页面展示',
    watchwordNumError: '活动期间最多发放数量不能大于库存!',
    watchwordNumAddError: '活动期间最多发放数量不能超出券剩余库存!',
    watchwordNumEditError: '活动期间最大发放数量不能低于已发放数量!',
    watchwordNumPlaceholder: '活动期间最多发放数量不能为空',
    chargeBenefits: '充电权益',
    chargeBenefitDescription: '充电权益说明',
    benefitDescription: '权益说明',
    benefitType: '权益方式',
    benefitTypeExtra: '说明：金额为每1元、时长为每30分钟为一个单位获取相关权益',
    duration: '时长(30分钟)',
    chargeBenefitRule: '充电权益规则',
    addLevel: '增加等级',
    firstBenefit: '首借权益',
    firstBenefitExtra: '说明：会员首次租借充电宝是否享受减免',
    canDiscountsAndExemptions: '可享受减免',
    everydayBenefit: '每日赠送权益',
    conversionBenefit: '兑换权益',
    conversionMax: '日兑换限量',
    everydayBenefitHourError: '每日赠送权益必须输入30的倍数',
    firstDiscountHourError: '可享受减免必须输入30的倍数',
    scoreYuan: '积分/1元',
    scoreMinutes: '积分/30分钟',
    everydayBenefitError: '每日赠送权益请输入除0以外的正整数',
    conversionMaxError: '日兑换限量请输入除0以外的正整数',
    conversionBenefitError: '兑换权益请输入除0以外的正整数',
    gradeEquityError: '请至少添加一项会员等级权益',
    selectGrade: '请选择会员等级',
    pleaseFillInChargeBenefit: '请至少填写一项每日赠送权益、兑换权益其中一项',
    virtualProductConfDes: '售卖卡券或无物流商品，复购无忧',
    newFission: '拉新裂变',
    enhanceStickiness: '提升粘性',
    interactiveGames: '互动游戏',
    paymentMarketing: '支付后营销',
    fixedPriceDesciption: '刺激连带消费，精选搭配，一口价享',
    reductionOther: '单多品促销',
    reductionDescription: '满减/满赠/打折/加价购/减运费，刺激销量',
    promotionPriceDescription: '特价促销，吸引眼球，销量飙升',
    skillDescription: '限时秒杀，引流聚气，高转化率',
    marketingSchedule: '营销档期',
    marketingScheduleTip: '用于运营数据统计，便于查看同一营销计划内整体活动数据',
    selectMarketingSchedule: '选择营销档期',
    noActivityPrivile: '您暂无查看该活动权限',
    allActivities: '全部活动',
    accordingSchedule: '按档期',
    accordingActivities: '按活动',
    backToday: '返回今日',
    createMarketingSchedule: '创建营销档期',
    pleaseMarketingName: '请输入营销档期名称',
    addAnnex: '添加附件',
    editMessageContent: '编辑消息内容',
    mail: '邮件',
    theme: '主题',
    requestTheme: '请输入主题',
    couponExpiredAutoShelfOff: '优惠券失效是否自动下架:',
    siteEmail: '站内信',
    birthdayCaringDesc: '生日专享礼，温馨关怀，增强用户粘性',
    festivalMarketingDesc: '节日献礼，情感营销，口碑相传',
    memberCardDiscountDesc: '会员专属折扣，增强归属感，促增长',
    creditEShopDesc: '消费赚积分，兑换享优惠，激励消费',
    scratchCardDesc: '刮卡惊喜多，积分价值显，用户留存高',
    lotteryCodeDesc: '积分换码赢好礼，积分变机遇，消费热',
    pointRightsAndInterestsDesc: '积分回馈顾客，增强忠诚度，提升粘性',
    storedValueCardBuyActivityDesc: '购储值卡满额赠礼，锁定消费，促业绩增长',
    storedValueCardActivityDesc: '预存锁定消费，稳定客源，增强粘性',
    useExpirationDate: '使用有效期',
    siteMailImgLimit: '建议图片宽度为750px，高度不超过1000px,大小不超过1M;',
    couponDetails: '优惠券详情页',
    orderDetail: '订单详情页',
    virtualProductOrder: '普通/虚拟商品订单',
    invoiceViewVariable:
        "发票备注支持个性化编辑，您可根据需要增加停车订单号等字段。若希望用户清晰识别这些字段，请在相应变量前添加明确的字段描述，如'订单号：{orderNo}'，以便用户一目了然。",
    variableIntro: '用户停车缴费生成的订单编号（示例：CP012345678901234567）',
    sequenceIntro: '按排序值倒序排序，排序值越高排列越前',
    registerReward: '在活动期间关注成功且注册成为小程序会员可获得奖励',
    registerRewardTip: '不勾选，则活动期间关注成功的用户，未注册成为会员的，不会获得奖励',
    sceneValueNull: '场景值不能为空',
    wxts20Tip: '含会员昵称不超过20个字符，支持汉字、数字、字母或符号',
    xxnrmbpz: '设置消息模板开关后，消息内容至具体活动下配置',
    shtzyh: '如活动报名需审核，将报名结果通知于用户',
    bmcgtzyh: '用户报名成功后，活动即将开始前通知用户',
    kqtzyh: '如商场活动报名已满，用户订阅该消息在商场活动名额有空缺时通知用户',
    bmqtzyh: '商场活动未开始时，用户订阅该消息在商场活动即将开始报名前通知用户',
    zfylwasl:
        '示例：尊敬的会员，您参与的支付奖励活动赠送{$number}张优惠券，请尽快领取：{$shortLink}。同时赠送您{$count}次抽奖机会，点击{抽奖地址链接}进行抽奖。',
    zfylshortLink: '{$shortLink}：自动生成支付宝或微信小程序对应小票详情页短链',
    zfylcount: '{$count}：发放积分抽奖机会数量',
    zfylnumber: '{$number}：发放优惠券的数量',
    jfcjmbl:
        '变量字段：receiveStartTime会自动读取活动领奖开始时间， receiveEndTime会自动读取活动领奖结束时间，subsiteName活动所属门店名称，subsiteMobile门店电话；',
    registerEventPicture: '新人礼弹窗图',
    registerEventPictureTips: {
        desc: '弹窗展示位置：平台首页、门店首页、会员中心',
        size: '建议尺寸：590*784 大小不超过1M 格式：JPG,PNG,JPEG',
    },
    registerEventUseButton: '奖励按钮图',
    registerEventUseButtonValidate: '请完整设置奖励按钮图',
    sellerDrawer: '开票人姓名或税局账户',
    pleaseSellerDrawer: '请输入开票人姓名或税局账户',
    tzsjzsxzyx: '通知时间至少勾选一项',
    syqd: '适用渠道',
    qzsgxygsyqd: '请至少勾选一个适用渠道',
    wxorapp: '微信/APP',
    zfbxcx: '支付宝小程序',
    taxPreferentialPolicies: '享受税收优惠政策',
    preferentialPoliciesType: '优惠政策类型',
    simpleLevy: '简易征收',
    simpleLevy3: '按3%简易征收',
    simpleLevy5: '按5%简易征收',
    simpleLevy1Drop5: '按5%简易征收减按1.5%计征',
};
