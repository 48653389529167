import { PureComponent, createElement } from 'react';
import builder, { actions, services } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';

import { debounce, defaults, get, mapValues } from 'lodash';
import { subsiteLinkTypeSelectorValidate } from '../../types/format';
import { language } from '@comall-backend-builder/core/lib/services';

export interface NewPopUpAdsCopyFormProps extends FormComponentProps {
    entity: Entity;
    scope: string;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
}

class NewPopUpAdsCopyForm extends PureComponent<NewPopUpAdsCopyFormProps> {
    static defaultProps: any = {};
    componentWillUnmount() {
        this.props.unmountComponent();
    }
    render() {
        const { entity, onSubmit, wrappedComponentRef, scope } = this.props;
        let fields = [
            { property: 'name' },
            { property: 'showTime' },
            {
                property: 'scope',
                controlConfig: {
                    onChange: (value: any, name: string) => {
                        const store = builder.getStore();

                        store.dispatch(
                            actions.formChangeAction(
                                'DataFormPlus-NewPopUpAdsCopyFormContainer',
                                name,
                                value
                            )
                        );
                        store.dispatch(
                            actions.formChangeAction(
                                'DataFormPlus-NewPopUpAdsCopyFormContainer',
                                'subsites',
                                []
                            )
                        );
                        store.dispatch(
                            actions.formStatusChangeAction(
                                'DataFormPlus-NewPopUpAdsCopyFormContainer',
                                'subsites',
                                true
                            )
                        );
                    },
                },
            },
            {
                property: 'subsites',
                visible: (values: any) => get(values, 'scope') === 'SUBSITE',
            },
            {
                property: 'channels',
            },
            // { property: 'type' },
            { property: 'homeType', visible: (values: any) => get(values, 'scope') === 'SUBSITE' },
            {
                property: 'platformType',
                visible: (values: any) => get(values, 'scope') === 'PLATFORM',
            },
            { property: 'crowd' },
            { property: 'config' },
            {
                property: 'second',
                visible: (values: any) => get(values, 'config') === 'dayThreeTime',
            },
            { property: 'pictureType' },
            {
                property: 'pic',
                visible: (values: any) => get(values, 'pictureType') === 'SINGLE_IMAGE_FIXED',
            },
            {
                property: 'target',
                controlConfig: {
                    subsitePreRequired: scope === 'SUBSITE',
                    isPlatform: scope !== 'SUBSITE',
                    subsitePreRequiredMsg: services.language.getText('selectBeforeMerchant'),
                    bussinessType: 'new-pop-up-ad',
                    hideNeedUrlLink: true,
                },
                visible: (values: any) => get(values, 'pictureType') === 'SINGLE_IMAGE_FIXED',
            },
            {
                property: 'interval',
                visible: (values: any) => get(values, 'pictureType') === 'MULTIPLE_IMAGE_CAROUSEL',
            },
            {
                property: 'operator',
                controlConfig: {
                    subsitePreRequired: scope === 'SUBSITE',
                    isPlatform: scope !== 'SUBSITE',
                    subsitePreRequiredMsg: services.language.getText('selectBeforeMerchant'),
                    bussinessType: 'new-pop-up-ad',
                    hideNeedUrlLink: true,
                },
                visible: (values: any) => get(values, 'pictureType') === 'MULTIPLE_IMAGE_CAROUSEL',
            },
        ];

        let formProps = {
            entity,
            componentId: 'NewPopUpAdsCopyFormContainer',
            wrappedComponentRef,
            onSubmit,
            direction: 'horizontal',
            style: { width: 600 },
            labelCol: 6,
            controlCol: 18,
            fields: fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('CopyFormPlus'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;
    let form: any;
    let scope = get(_dispatch, 'components.DataFormPlus-NewPopUpAdsCopyFormContainer.fields.scope');
    /**
     * 提交前额外验证
     * @param entityFields
     */
    const validate = (entityFields: any) => {
        const scope = get(entityFields, 'scope', null);
        const subsites = get(entityFields, 'subsites', null);
        const pictureType = get(entityFields, 'pictureType', 'SINGLE_IMAGE_FIXED');
        const operator = get(entityFields, 'operator', []);
        if (scope === 'SUBSITE' && (!subsites || !subsites.length)) {
            AntMessage.warning(services.language.getText('selectSubsite'));
            return false;
        }
        const target = get(entityFields, 'target', {});
        if (!subsiteLinkTypeSelectorValidate(target || {})) {
            return false;
        }
        if (pictureType === 'MULTIPLE_IMAGE_CAROUSEL') {
            if (!operator || operator.length === 0) {
                AntMessage.warning(language.getText('qtjggtp'));
                return false;
            }
            const isFull = operator.every((item: any) => {
                return item.pic && item.pic.path;
            });
            if (!isFull) {
                AntMessage.warning(language.getText('qscggtp'));
                return false;
            }
        }
        return true;
    };

    return defaults(
        {
            onSubmit,
            onFieldChange,
            scope,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: debounce((event: any, fields: any) => {
                const entityFields = mapValues(fields, (field, name) => {
                    return field.value;
                });
                if (entityFields) {
                    const hasValidate = validate(entityFields);
                    if (hasValidate) {
                        entity.add(entityFields, params);
                    }
                }
                return false;
            }, 300),
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('NewPopUpAdsCopyFormContainer'));
            },
        }
    );
}

export const NewPopUpAdsCopyFormView = connect(mapDispatchToProps)(NewPopUpAdsCopyForm);
