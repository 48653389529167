import { builder } from '@comall-backend-builder/core';
import './report-management';
import './coupon';
import './delivery';
import './invoice';
import './payment-allocation';
import { InfoLoader } from './info-loader';
import { CGBConfigLoader } from './cgb-config-loader';
import { CgbLeadersLoader } from './cgb-leaders-loader';
import { CGBManageLoader } from './cgb-manage-loader';
import { MemberPageLoader } from './member-page-loader';
import { ContentMemberPageLoader } from './content-member-page-loader';
import { PreSaleListLoader } from './presale-list-loader';
import { popUpAdsLoader, newPopUpAdsLoader } from './pop-up-ads-loader';
import { themeStyleLoader } from './theme-style';
import { recruitDistributorLoader } from './recruit-distributor';
import { popUpContentLoader } from './pop-up-content-loader';
import { creditEshopLoader, CreditShopCardLevelLoader } from './credit-eshop-loader';
import { couponOrderLoader } from './coupon-order-loader';
import { couponRefundOrderLoader } from './coupon-refund-order-loader';
import { FeedBacksLoader, FeedBackTypesLoader } from './feedbacks-loader';
import { MerchantUserLoader } from './merchant-user-loader';
import { CarparkLoader, CarparkOrderLoader, CarNumberLoader } from './carpark-loader';
import { inviteLoader } from './invite-loader';
import { InvitationActivityLoader } from './invitation-activity-loader';
import { CardCollectionLoader } from './card-collection-loader';
import { StoreCardLevelLoader } from './store-card-level';
import { StoreCardLevelBenefitLoader } from './store-card-level-benefit';
import { CardLevelLoader } from './card-level';
import { MemberLevelLoader } from './member-level';
import { MemberBenefitLoader } from './member-benefit';
import { IntegralDescriptionLoader } from './integral-description-loader';
import { NotePromotionEncourageLoader } from './note-promotion-encourage-loader';
import { TopicPromotionEncourageLoader } from './topic-promotion-encourage-loader';
import { TopicPromotionEncourageDataLoader } from './topic-promotion-encourage-data-loader';
import { WheelDrawLoader } from './wheel-draw-loader';
import { CheckinActivityLoader } from './checkin-activity-loader';
import { ActivityRewardItemLoader } from './activity-reward-item-loader';
import {
    MallActivitySettingLoader,
    MallActivitySettingUserCardLevelLoader,
} from './mall-activity-setting-loader';
import { mallActivityDesignPagesLoader } from './mall-activity-design-loader';
import {
    MallActivityLoader,
    MallActivityUserCardLevelLoader,
    MallActivityLimitCountTypeLoader,
} from './mall-activity-loader';
import {
    NewMallActivityLoader,
    NewMallActivityTicketPurchaseRecordsLoader,
    NewMallActivityUserCardLevelLoader,
    NewMallActivitySettingLoader,
} from './new-mall-activity-loader';
import { MallActivityRegistrationLoader } from './mall-activity-registration-loader';
import { MagicMallActivityRegistrationLoader } from './magic-mall-activity-registration-loader';

import { MallActivityAfterSaleLoader } from './mall-activity-after-sale-loader';
import { MallActivityVerificationLoader } from './mall-activity-verification-loader';
import { CreditEshopReportLoader } from './credit-eshop-report-loader';
import { BirthdayCaringLoader } from './birthday-caring-loader';
import { GroupBuyingLoader, GroupBuyingGoodsSelectorLoader } from './group-buying-loader';
import { LotteryCodeLoader } from './lottery-code-loader';
import { WfjLotteryCodeLoader } from './wfj-lottery-code-loader';
import { SelfCreditingLoader } from './self-crediting-loader';
import { PrintSettingLoader } from './print-setting-loader';
import { PhotoCreditReviewLoader } from './photo-credit-review-loader';
import { ContentComplainLoader } from './content-complain-loader';
import { ContentNotesLoader } from './content-notes-loader';
import { ContentNotesTopPicLoader } from './content-notes-top-pic-loader';
import { QrcodeRuleLoader } from './qrcode-rule';
import { ContentPlateLoader } from './content-plate-loader';
import { ContentTopicLoader } from './content-topic-loader';
import { ScratchCardLoader } from './scratch-card-loader';
import { ActivationPromotionEncourageLoader } from './activation-promotion-encourage-loader';
import { ServiceReservedLoader } from './service_reserved_activities';
import { ServiceReservedRefundOrderLoader } from './service-reserved-activity-refunds';
import { ReservationDetailsLoader } from './reservation-details';
import { RedPackageRainLoader } from './red-package-rain-loader';
import { DeliveryBenefitsLoader, DeliveryModesLoader } from './delivery-benefits';
import { CouponExchangeRefundRecordLoader } from './coupon-exchange-refund-record-loader';
import { CouponExchangeRecordLoader } from './coupon-exchange-record-loader';
import { timeLimitActivityLoader } from './time-limit-activity-loader';
import { CouponConsumeLoader } from './coupon-consume-loader';
import { AuthorizeMemberManagementLoader } from './authorize-member-management-loader';
import {
    MaotaiCardLevelsLoader,
    MaotaiPresaleLoder,
    MaotaiPresaleForecastLoder,
    MaotaiLimitRuleLoader,
} from './maotai-presale';
import { CouponUnConsumeLoader } from './coupon-un-consume-loader';
import { CouponDownloadLoader } from './coupon-download-loader';
import { MerchantTagLoader } from './merchant-tag-loader';
import { MerchantBigCodesLoader } from './merchant-big-codes-loader';
import { MerchantFacilityTagLoader } from './merchant-facility-tag-loader';
import { MallActivityTagLoader } from './mall-activity-tag-loader';
import { CouponServiceAppliesLoader } from './coupon-service-applies-loader';
import { AnnualWelfareDescriptionLoader } from './annual-welfare-description';
import { BalanceDescriptionLoader } from './balance-description';
import { ReportDownloadCenterLoader } from './report-download-center-loader';
import { MiniProgramChargesLoader } from './mini-program-charges-dashboard-loader';
import { WechatPublicUserCountLoader } from './wechat-public-user-count-loader';
import { InteractivePromotionEncourageLoader } from './interactive-promotion-encourage-loader';
import { FissionPromotionEncourageLoader } from './fission-promotion-encourage-loader';
import { UserVassalageMerchantsLoader } from './user-vassalage-merchants';
import { PosReceiptLoader } from './pos-receipt-loader';
import { MerchantFacilityLoader } from './merchant-facility-loader';
import {
    PaymentsDataPanelLoader,
    ProductsDataPanelLoader,
    RefundsDataPanelLoader,
    MembersDataPanelLoader,
    MiniprogramCodeDataListLoader,
} from './data-panel';
import {
    LotteryMaintainLoader,
    ApplyMessageLoader,
    ApplyMessageDownloadLoader,
} from './presale-sign-up-loader';
import {
    DrawLotsReplenishLoader,
    TicketMessageLoader,
    TicketMessageDownloadLoader,
} from './draw-lots-replenish';
import {
    DrawLotsAppointLoader,
    TicketMessageAppointLoader,
    TicketMessageAppointDownloadLoader,
} from './draw-lots-appoint';
import { SignOrdertMessageLoader, SignOrdertMessageDownloadLoader } from './sign-order-loader';
import { SubsitesMerchantsLoader, SubsiteLoader } from './subsites-merchants';
import { GoldExchangeRules } from './gold-exchange-rule';
import { ReserveActivitiesLoader } from './reserve-activities-loader';
import { MallActivityRefundOrderLoader } from './mall-activity-refund-order';
import { MiniprogramCodeLoader } from './miniprogram-code';
import { MiniprogramCodeV2Loader } from './miniprogram-code-v2';
import { GuidesApplyRecordsLoader } from './guides-apply-records-loader';
import { FullPaymentActivitiesLoader } from './full-payment-activities-loader';
import { FixedPricePromotionsLoader } from './fixed-price-promotions';
import {
    DistributionActivitiesLoader,
    DistributionActivityWithdrawalRecordLoader,
} from './distribution-activities';
import { DistributionActivitiesNewLoader } from './distribution-activities-new';
import { GuidesCommissionLoader } from './guide-commission-loader';
import { ProductSettingLoader } from './product-setting-loader';
import { eventMarketLoader } from './event-market-loader';
import { eventMarketTypeLoader } from './event-market-loader';
import { ProductSearchKeywordsLoader } from './product-search-keywords-loader';
import { SalesPlansLoader } from './sales-plans-loader';
import { orderSettingLoader } from './order-setting';
import { CommonSettingLoader } from './common-setting-loader';
import { MembersIdentitiesApplyLoader } from './member-distributor';
import { MemberDistributorLoader } from './member-distributor';
import { BlindBoxLoader } from './blind-box-loader';
import { SalesElectronicCouponLoader } from './sales-electronic-coupon-loader';
import { OpenBoxLoader } from './open-box-loader';

import './message';
import './member';
import './market';
import './delivery';
import './wechat';
import './product';
import { orderPickingLoader } from './order-picking-loader';
import { reviewedOrdersLoader } from './reviewed-orders-loader';
import { ComponentsDemoLoader } from './components-demo';
import { SlotMachinesLoader } from './slot-machines-loader';
import { SlotMachinesReportLoader } from './slot-machines-report-loader';
import { SlotMachinesDetailLoader } from './slot-machines-detail-loader';
import { purchaseLimitationLoader } from './purchase-limitation-loader';
import { ReserveDrawLotsActivitiesLoader } from './reserve-draw-lots-activities-loader';
import {
    MaotaiBlacklistManagerListLoader,
    MaotaiBlacklistManagerRecordLoader,
    MaotaiWhitelistManagerRecordLoader,
    ReserveDrawLotsImportActivitiesLoader,
} from './reserve-draw-lots-import-activities-loader';
import {
    WhitelistManagerRecordLoader,
    RegistrationWhitelistManagerRecordLoader,
} from './reserve-draw-lots-import-activities-loader';
import { BlacklistManagerRecordLoader } from './reserve-draw-lots-import-activities-loader';
import { PhotoCreditReviewLogsLoader } from './photo-credit-review-logs-loader';
import { PhotoCreditReviewReasonsLoader } from './photo-credit-review-reasons-loader';
import { OfflineOrderCommentsLoader } from './offline-order-comments-loader';
import { OfflineOrderCommentsCustomizationLoader } from './offline-order-comments-customization-loader';
import { ContentServicePageLoader } from './content-service-page-loader';
import { ExpressCompanyLoader } from './settings/express-company-loader';
import { ExpressCompanySettingLoader } from './settings/express-company-setting-loader';
import { CombineGoodsLoader } from './combine-goods-loader';
import { HotWordsSearchLoader } from './hot-words-search-loader';
import { CrossborderTradeConfigLoader } from './crossborder-trade-config';
import { shoppingListLoader } from './shopping-list-loader';
import { ServiceReservedConsumeLoader } from './member/service-reserved-consume-loader';
import { ProductAuditRulesLoader } from './product-audit-rules-loader';
import { DeliveryLogisticsConfigsLoader } from './settings/delivery-logistics-configs-loader';
import { DeliveryLogisticsCompaniesLoader } from './settings/delivery-logistics-companies-loader';
import { DeliveryPrintersLoader } from './settings/delivery-printers-loader';
import { PaymentModeLoader } from './settings/payment-mode';
import { DeliveryPackageLoader } from './order/delivery-package-loader';
import { BatchDeliveryLoader } from './order/batch-delivery-loader';
import { ReplyCommonWordConfigsLoader } from './reply-common-words-configs-loader';
import { goldDeductionLoader } from './gold-deduction-loader';
import { SettingReasonLoader } from './setting-reason-loader';
import { FilterWordLoader } from './filter-word-loader';
import { BackInfoLoader } from './settings/personalization/back-info-loader';
import { FrontInfoLoader } from './settings/personalization/front-info-loader';
import { BlackListLoader } from './settings/black-list-loader';
import { SettingSubsiteMessageFloor } from './setting-subsite-message-floor';
import { SettingSubsiteMessage, BusinessMallsLoader } from './setting-subsite-message';
import {
    SubsitesMerchantsSorterLoader,
    DeliveryShopMerchantsSorterLoader,
} from './subsites-merchants-sorter-loader';
import {
    MerchantSelfLoader,
    SubsiteFloorsLoader,
    MerchantBySubsitesLoader,
    MerchantTagsLoader,
} from './merchant-self-loader';
import { MiniProgramCodeDefalutLinksLoader } from './mini-program-code-default-links-loader';
import {
    MiniprogramCodeV2StaffLoader,
    MiniprogramCodeV2MerchantLoader,
} from './mini-program-code-v2-staff-loader';
import { ServiceWifiLoader } from './member/service-wifi-loader';
import { ShowStyleLabelLoader } from './show-style-label-loader';
import { ShoppingListRuleLoader } from './shopping-list-rule-loader';
import { SubsiteMineSourceLoader } from './subsite-mine-source-loader';
import { CardLevelTemplateLoader } from './card-level-template';
import { SubsiteMemberCardLevelLoader } from './subsite-member-card-level';
import {
    ChannelCardTemplatesLoader,
    ChannelWechatOfficialAccountsLoader,
} from './wechat-card-manage-loader';
import { BargainActivityLoader } from './bargain-activity-loader';
import { BargainActivityConfigsLoader } from './bargain-activity-configs-loader';
import { ImproveInformationLoader } from './improve-information';
import { SecurityConfigLoader } from './security-config-loader';
import {
    MyMemberCouponsSettingLoader,
    MemberBalanceSettingsLoader,
} from './settings/member-assets-settings-loader';
import { ActivityLogsLoader, OperationLogsLoader } from './activity-logs';
import { OfflinePaymentPaymentResultLoader } from './offline-payment-payment-result';
import { jqSaleBillLoader, jqMessageNotifyLoader } from './jiuqu-content-loader';
import { newOpenScreenLoader } from './open-screen-ads-loader';
import { ScrmLevelsUpgradeDowngradeRulesLoader } from './member/scrm-levels-upgrade-downgrade-rules-loader';
import { SecurityPolicyLoader } from './security-policy-loader';
import { MemberUpgradeGiftLoader } from './member/member-upgrade-gift-loader';
import { DepositOrderLoader } from './order/deposit-order-loader';
import { DepositOrderAfterSaleLoader } from './order/deposit-order-after-sale-loader';
import { DepositOrderRefundLoader } from './order/deposit-order-refund-loader';
import { PosRecieptRefundLoader } from './order/pos-receipt-refund-loader';
import { ArticleManageLoader } from './article-manage-loader';
import { WatchwordLoader } from './watchword-loader';
import { ChargeBenefitLoader } from './charge-benefit-loader';

builder.registerLoader('/info', InfoLoader);
builder.registerLoader('/admin/config', CGBConfigLoader);
builder.registerLoader('/admin/leaders', CgbLeadersLoader);
builder.registerLoader('/admin/activities', CGBManageLoader);
builder.registerLoader('/admin/presales', PreSaleListLoader);
builder.registerLoader('/admin/member_pages', MemberPageLoader);
builder.registerLoader('/admin/content_member_pages', ContentMemberPageLoader);
builder.registerLoader('/admin/content_service_pages', ContentServicePageLoader);
builder.registerLoader('/admin/pop_up_ads', popUpAdsLoader);
builder.registerLoader('/admin/new_pop_up_ads', newPopUpAdsLoader);
builder.registerLoader('/admin/open_screen_ads', newOpenScreenLoader);
builder.registerLoader('/admin/theme_style', themeStyleLoader);
builder.registerLoader('/admin/page/distributor', recruitDistributorLoader);
builder.registerLoader('/admin/pop_up_content', popUpContentLoader);
builder.registerLoader('/admin/creditEshop', creditEshopLoader);
builder.registerLoader('/admin/creditShop/userCardLevels', CreditShopCardLevelLoader);
builder.registerLoader('/admin/feedbacks', FeedBacksLoader);
builder.registerLoader('/admin/feedback/types', FeedBackTypesLoader);
builder.registerLoader('/admin/couponOrder', couponOrderLoader);
builder.registerLoader('/admin/couponRefundOrder', couponRefundOrderLoader);
builder.registerLoader('/admin/merchant_users', MerchantUserLoader);
builder.registerLoader('/admin/carparks', CarparkLoader);
builder.registerLoader('/admin/invite', inviteLoader);
builder.registerLoader('/admin/carpark_orders', CarparkOrderLoader);
builder.registerLoader('/admin/invitations', InvitationActivityLoader);
builder.registerLoader('/admin/cardCollection', CardCollectionLoader);
builder.registerLoader('/admin/storeCardLevels', StoreCardLevelLoader);
builder.registerLoader('/admin/storeCardLevelsBenefit', StoreCardLevelBenefitLoader);
builder.registerLoader('/admin/cardLevels', CardLevelLoader);
builder.registerLoader('/admin/memberLevels', MemberLevelLoader);
builder.registerLoader('/admin/memberBenefits', MemberBenefitLoader);
builder.registerLoader('/admin/integralDescription', IntegralDescriptionLoader);
builder.registerLoader('/admin/notePromotionEncourage', NotePromotionEncourageLoader);
builder.registerLoader('/admin/topicPromotionEncourage', TopicPromotionEncourageLoader);
builder.registerLoader('/admin/topicPromotionEncourageData', TopicPromotionEncourageDataLoader);
builder.registerLoader('/admin/wheelDraw', WheelDrawLoader);
builder.registerLoader('/admin/checkin/activities', CheckinActivityLoader);
builder.registerLoader('/admin/activityRewardItems', ActivityRewardItemLoader);
builder.registerLoader('/admin/mallActivitySetting', MallActivitySettingLoader);
builder.registerLoader(
    '/admin/mallActivitySetting/userCardLevels',
    MallActivitySettingUserCardLevelLoader
);
builder.registerLoader('/admin/mallActivityDesignPages', mallActivityDesignPagesLoader);

builder.registerLoader('/admin/mallActivity', MallActivityLoader);
builder.registerLoader('/admin/newMallActivity', NewMallActivityLoader);
builder.registerLoader(
    '/admin/v1/mall_activities/display_config/display_config',
    NewMallActivitySettingLoader
);
builder.registerLoader(
    '/admin/v1/mall_activity/ticket_purchase_records',
    NewMallActivityTicketPurchaseRecordsLoader
);
builder.registerLoader('/admin/mallActivity/userCardLevels', MallActivityUserCardLevelLoader);
builder.registerLoader('/admin/newMallActivity/userCardLevels', NewMallActivityUserCardLevelLoader);
builder.registerLoader('/admin/mallActivity/limitCountType', MallActivityLimitCountTypeLoader);
builder.registerLoader('/admin/mallActivityRegistration', MallActivityRegistrationLoader);
builder.registerLoader('/admin/magicMallActivityRegistration', MagicMallActivityRegistrationLoader);
builder.registerLoader('/admin/mallActivityAfterSale', MallActivityAfterSaleLoader);
builder.registerLoader('/admin/mallActivityVerification', MallActivityVerificationLoader);
builder.registerLoader('/admin/creditEshopReport', CreditEshopReportLoader);
builder.registerLoader('/admin/birthdayCaring', BirthdayCaringLoader);
builder.registerLoader('/admin/group-buying', GroupBuyingLoader);
builder.registerLoader('/admin/group-buying/goods-selector', GroupBuyingGoodsSelectorLoader);
builder.registerLoader('/admin/lotteryCode', LotteryCodeLoader);
builder.registerLoader('/admin/wfjLotteryCode', WfjLotteryCodeLoader);
builder.registerLoader('/admin/selfCrediting', SelfCreditingLoader);
builder.registerLoader('/admin/printSetting', PrintSettingLoader);
builder.registerLoader('/admin/photoCreditReview', PhotoCreditReviewLoader);
builder.registerLoader('/admin/photoCreditReviewLogs', PhotoCreditReviewLogsLoader);
builder.registerLoader('/admin/photoCreditReviewReasons', PhotoCreditReviewReasonsLoader);
builder.registerLoader('/admin/complain/records', ContentComplainLoader);
builder.registerLoader('/admin/notes', ContentNotesLoader);
builder.registerLoader('/admin/notesTopPic', ContentNotesTopPicLoader);
builder.registerLoader('/admin/qrcodeRule', QrcodeRuleLoader);
builder.registerLoader('/admin/plates', ContentPlateLoader);
builder.registerLoader('/admin/topics', ContentTopicLoader);
builder.registerLoader('/admin/scratchCard', ScratchCardLoader);
builder.registerLoader('/admin/activationPromotionEncourage', ActivationPromotionEncourageLoader);
builder.registerLoader('/admin/service_reserved_activities', ServiceReservedLoader);
builder.registerLoader('/admin/serviceReservedRefundOrder', ServiceReservedRefundOrderLoader);
builder.registerLoader('/admin/service_reserved_activity_orders', ReservationDetailsLoader);
builder.registerLoader('/admin/redPackageRain', RedPackageRainLoader);
builder.registerLoader('/admin/delivery_benefits', DeliveryBenefitsLoader);
builder.registerLoader('/admin/delivery_modes', DeliveryModesLoader);
builder.registerLoader('/admin/couponExchangeRefundRecord', CouponExchangeRefundRecordLoader);
builder.registerLoader('/admin/couponExchangeRecord', CouponExchangeRecordLoader);
builder.registerLoader('/admin/timelimitActivity', timeLimitActivityLoader);
builder.registerLoader('/admin/couponConsumeLoader', CouponConsumeLoader);
builder.registerLoader('/admin/couponUnConsumeLoader', CouponUnConsumeLoader);
builder.registerLoader('/admin/couponDownloadLoader', CouponDownloadLoader);
builder.registerLoader('/admin/merchantTag', MerchantTagLoader);
builder.registerLoader('/admin/merchantBigCodes', MerchantBigCodesLoader);
builder.registerLoader('/admin/facility_tag', MerchantFacilityTagLoader);
builder.registerLoader('/admin/mallActivityTag', MallActivityTagLoader);
builder.registerLoader('/admin/couponServiceApplies', CouponServiceAppliesLoader);
builder.registerLoader('/admin/annual-welfare', AnnualWelfareDescriptionLoader);
builder.registerLoader('/admin/balance-description', BalanceDescriptionLoader);
builder.registerLoader('/admin/reportDownloadCenter', ReportDownloadCenterLoader);
builder.registerLoader('/admin/miniProgramCharges', MiniProgramChargesLoader);
builder.registerLoader('/admin/wechatPublicUserCount', WechatPublicUserCountLoader);
builder.registerLoader('/admin/interactivePromotionEncourage', InteractivePromotionEncourageLoader);
builder.registerLoader('/admin/fissionPromotionEncourage', FissionPromotionEncourageLoader);
builder.registerLoader('/admin/payments-data-panel', PaymentsDataPanelLoader);
builder.registerLoader('/admin/products-data-panel', ProductsDataPanelLoader);
builder.registerLoader('/admin/refunds-data-panel', RefundsDataPanelLoader);
builder.registerLoader('/admin/members-data-panel', MembersDataPanelLoader);
builder.registerLoader('/admin/subsites/mine/merchants', SubsitesMerchantsLoader);
builder.registerLoader('/admin/gold_exchange_rules', GoldExchangeRules);
builder.registerLoader('/admin/reserve-activities', ReserveActivitiesLoader);
builder.registerLoader('/admin/mallActivityRefundOrder', MallActivityRefundOrderLoader);
builder.registerLoader('/admin/miniprogramCode', MiniprogramCodeLoader);
builder.registerLoader('/admin/miniprogramCodeV2', MiniprogramCodeV2Loader);
builder.registerLoader('/admin/miniprogramCodeV2Staff', MiniprogramCodeV2StaffLoader);
builder.registerLoader('/admin/miniprogramCodeV2Merchant', MiniprogramCodeV2MerchantLoader);
builder.registerLoader('/admin/guides/wechatwork/apply_user_records', GuidesApplyRecordsLoader);
builder.registerLoader('/admin/full-payment-activities', FullPaymentActivitiesLoader);
builder.registerLoader('/admin/distribution_activities', DistributionActivitiesLoader);
builder.registerLoader('/admin/distribution_activities_new', DistributionActivitiesNewLoader);
builder.registerLoader('/admin/distribution_goods_settlement_records', GuidesCommissionLoader);
builder.registerLoader('/admin/product_auto_audit_rules', ProductSettingLoader);
builder.registerLoader('/admin/eventMarket', eventMarketLoader);
builder.registerLoader('/admin/eventMarketType', eventMarketTypeLoader);
builder.registerLoader('/admin/product_search_keywords', ProductSearchKeywordsLoader);
builder.registerLoader('/admin/sales-plans', SalesPlansLoader);
builder.registerLoader('/admin/picking_lists', orderPickingLoader);
builder.registerLoader('/admin/orders/reviewed', reviewedOrdersLoader);
builder.registerLoader('/admin/order-setting', orderSettingLoader);
builder.registerLoader('/admin/common-setting', CommonSettingLoader);
builder.registerLoader('/components-demo', ComponentsDemoLoader);
builder.registerLoader('/admin/slotMachines', SlotMachinesLoader);
builder.registerLoader('/admin/slotMachinesReport', SlotMachinesReportLoader);
builder.registerLoader('/admin/slotMachinesDetail', SlotMachinesDetailLoader);
builder.registerLoader('/admin/purchaseLimitation', purchaseLimitationLoader);
builder.registerLoader('/admin/reserveDrawLotsActivities', ReserveDrawLotsActivitiesLoader);
builder.registerLoader(
    '/admin/reserveDrawLotsImportActivities',
    ReserveDrawLotsImportActivitiesLoader
);
builder.registerLoader('/admin/whitelistManagerRecord', WhitelistManagerRecordLoader);
builder.registerLoader('/admin/maotaiWhitelistManagerRecord', MaotaiWhitelistManagerRecordLoader);
builder.registerLoader(
    '/admin/maotaiRegistrationWhitelistManagerRecord',
    RegistrationWhitelistManagerRecordLoader
);
builder.registerLoader('/admin/blacklistManagerRecord', BlacklistManagerRecordLoader);
builder.registerLoader('/admin/maotaiBlacklistManagerList', MaotaiBlacklistManagerListLoader);
builder.registerLoader('/admin/maotaiBlacklistManagerRecord', MaotaiBlacklistManagerRecordLoader);
builder.registerLoader('/admin/members/identities/apply_records', MembersIdentitiesApplyLoader);
builder.registerLoader('/admin/members/distributor', MemberDistributorLoader);
builder.registerLoader('/admin/blind-box', BlindBoxLoader);
builder.registerLoader('/admin/open-box', OpenBoxLoader);
builder.registerLoader('/admin/presale_sign_up/activity', LotteryMaintainLoader);
builder.registerLoader('/admin/presale_sign_up/sign_up', ApplyMessageLoader);
builder.registerLoader('/admin/presale_sign_up/sign_up/import_record', ApplyMessageDownloadLoader);
builder.registerLoader('/admin/draw_lots_replenish', DrawLotsReplenishLoader);
builder.registerLoader('/admin/draw_lots_replenish/record', TicketMessageLoader);
builder.registerLoader('/admin/draw_lots_replenish/import_record', TicketMessageDownloadLoader);
builder.registerLoader('/admin/draw_lots_appoint', DrawLotsAppointLoader);
builder.registerLoader('/admin/draw_lots_appoint/record', TicketMessageAppointLoader);
builder.registerLoader(
    '/admin/draw_lots_appoint/import_record',
    TicketMessageAppointDownloadLoader
);

builder.registerLoader('/admin/maotaiCardLevels', MaotaiCardLevelsLoader);
builder.registerLoader('/admin/maotaiPresale', MaotaiPresaleLoder);
builder.registerLoader('/admin/maotaiPresale/forecast', MaotaiPresaleForecastLoder);
builder.registerLoader('/admin/maotaiPresale/limitRule', MaotaiLimitRuleLoader);
builder.registerLoader('/admin/member/authorize_merchants', AuthorizeMemberManagementLoader);
builder.registerLoader('/admin/sign_order', SignOrdertMessageLoader);
builder.registerLoader('/admin/sign_order_import', SignOrdertMessageDownloadLoader);
builder.registerLoader('/admin/miniprogram-code-data-list', MiniprogramCodeDataListLoader);
builder.registerLoader(
    '/admin/distribution_activity_commission_withdrawal_records',
    DistributionActivityWithdrawalRecordLoader
);
builder.registerLoader('/admin/offline-order-comments', OfflineOrderCommentsLoader);
builder.registerLoader(
    '/admin/offline-order-comments-customization',
    OfflineOrderCommentsCustomizationLoader
);
builder.registerLoader('/admin/pos_receipts', PosReceiptLoader);
builder.registerLoader('/admin/express-company', ExpressCompanyLoader);
builder.registerLoader('/admin/express-company-setting', ExpressCompanySettingLoader);
builder.registerLoader('/admin/combine-goods', CombineGoodsLoader);
builder.registerLoader('/admin/hot_word', HotWordsSearchLoader);
builder.registerLoader('/admin/crossborder_trade_config', CrossborderTradeConfigLoader);
builder.registerLoader('/admin/shopping_list_union_order_records', shoppingListLoader);
builder.registerLoader(
    '/admin/service_reserved_activity_orders/consume_records',
    ServiceReservedConsumeLoader
);
builder.registerLoader('/admin/user_vassalage_merchant', UserVassalageMerchantsLoader);
builder.registerLoader('/admin/product_audit_rules', ProductAuditRulesLoader);
builder.registerLoader('/admin/delivery-logistics-configs', DeliveryLogisticsConfigsLoader);
builder.registerLoader('/admin/delivery-logistics-companies', DeliveryLogisticsCompaniesLoader);
builder.registerLoader('/admin/delivery-printers', DeliveryPrintersLoader);
builder.registerLoader('/admin/delivery-package', DeliveryPackageLoader);
builder.registerLoader('/admin/batch-delivery', BatchDeliveryLoader);
builder.registerLoader('/admin/reply-common-words-configs', ReplyCommonWordConfigsLoader);
builder.registerLoader('/admin/facility', MerchantFacilityLoader);
builder.registerLoader('/admin/payment_mode_rule', goldDeductionLoader);
builder.registerLoader('/admin/reason', SettingReasonLoader);
builder.registerLoader('/admin/payment_mode', PaymentModeLoader);
builder.registerLoader('/admin/filter_word', FilterWordLoader);
builder.registerLoader('/admin/back_info', BackInfoLoader);
builder.registerLoader('/admin/front_info', FrontInfoLoader);
builder.registerLoader('/admin/blacklist', BlackListLoader);
builder.registerLoader('/admin/sales-electronic-coupon', SalesElectronicCouponLoader);
builder.registerLoader('/admin/subsite_floor', SettingSubsiteMessageFloor);
builder.registerLoader('/admin/subsite', SettingSubsiteMessage);
builder.registerLoader('/subsites/mine/merchants', SubsitesMerchantsSorterLoader);
builder.registerLoader('/admin/delivery_config/merchants', DeliveryShopMerchantsSorterLoader);
builder.registerLoader('/admin/merchant_self', MerchantSelfLoader);
builder.registerLoader('/admin/subsites/get_floors', SubsiteFloorsLoader);
builder.registerLoader('/admin/miniProgramCodeDefaultLinks', MiniProgramCodeDefalutLinksLoader);
builder.registerLoader('/admin/fixed_price_promotions', FixedPricePromotionsLoader);
builder.registerLoader('/admin/wifi', ServiceWifiLoader);
builder.registerLoader('/admin/styel_label', ShowStyleLabelLoader);
builder.registerLoader('/admin/merchant_by_subsites', MerchantBySubsitesLoader);
builder.registerLoader('/admin/shopping_list_rule', ShoppingListRuleLoader);
builder.registerLoader('/source/admin/v1/subsites/mine', SubsiteMineSourceLoader);
builder.registerLoader('/admin/cardLevelsTemplate', CardLevelTemplateLoader);
builder.registerLoader('/admin/subsiteMemberCardLevel', SubsiteMemberCardLevelLoader);
builder.registerLoader('/admin/merchant_tags', MerchantTagsLoader);
builder.registerLoader('/admin/channelCardTemplates', ChannelCardTemplatesLoader);
builder.registerLoader('/admin/channelWechatOfficialAccounts', ChannelWechatOfficialAccountsLoader);
builder.registerLoader('/admin/bargain_activity', BargainActivityLoader);
builder.registerLoader('/admin/bargain_activity_configs', BargainActivityConfigsLoader);
builder.registerLoader('/admin/improve_information', ImproveInformationLoader);

builder.registerLoader('/admin/security_config', SecurityConfigLoader);
builder.registerLoader('/admin/member_coupon_settings', MyMemberCouponsSettingLoader);
builder.registerLoader('/admin/member_balance_settings', MemberBalanceSettingsLoader);
builder.registerLoader('/activities_logs', ActivityLogsLoader);
builder.registerLoader('/operation_logs', OperationLogsLoader);
builder.registerLoader('/admin/offline_payment/payment_result', OfflinePaymentPaymentResultLoader);
builder.registerLoader('/admin/supply_chain/trade_records', jqSaleBillLoader);
builder.registerLoader('/admin/business_remind', jqMessageNotifyLoader);
builder.registerLoader('/admin/car_number', CarNumberLoader);
builder.registerLoader(
    '/admin/scrm_levels_upgrade_downgrade_rules',
    ScrmLevelsUpgradeDowngradeRulesLoader
);

builder.registerLoader('/admin/business-malls', BusinessMallsLoader);
builder.registerLoader('/admin/security_policy', SecurityPolicyLoader);
builder.registerLoader('/admin/defaults/subsite', SubsiteLoader);
builder.registerLoader('/admin/member-upgrade-gift', MemberUpgradeGiftLoader);
builder.registerLoader('/admin/deposit_order', DepositOrderLoader);
builder.registerLoader('/admin/deposit_order_after_sale', DepositOrderAfterSaleLoader);
builder.registerLoader('/admin/deposit_order_refund', DepositOrderRefundLoader);
builder.registerLoader('/admin/pos_reciept_refund', PosRecieptRefundLoader);
builder.registerLoader('/admin/article_manage', ArticleManageLoader);
builder.registerLoader('/admin/watchword', WatchwordLoader);
builder.registerLoader('/admin/chargeBenefits', ChargeBenefitLoader);
