import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { ComponentsManager, services } from '@comall-backend-builder/core';

/**
 * 用户管理列表
 */
class mallActivityTable extends PureComponent<any, any> {
    state = { uploadModalVisible: false };
    selectedRowKeys: string[] = [];
    onChange = (selectedRowKeys: string[]) => {
        this.selectedRowKeys = selectedRowKeys;
    };
    renderTable = () => {
        const { entity, componentId } = this.props;
        let tableProps = {
            entity,
            componentId,
            rowKey: 'id',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return `共 ${total} 条记录`;
                },
            },

            columns: [
                {
                    property: 'info',
                    width: 380,
                },
                {
                    property: 'subSites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '门店',
                    },
                },
                {
                    property: 'merchants',
                    width: 180,
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '专柜',
                    },
                },
                {
                    property: 'registrationStatus',
                },
                {
                    property: 'mallActivityStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '未开始',
                                status: 'success',
                            },
                            STARTED: {
                                text: '进行中',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '已结束',
                                status: 'default',
                            },
                        },
                    },
                },
                { property: 'mallActivityStatisticTotal' },
                {
                    property: 'blackUserListFileUrl',
                    displayConfig: {
                        entity,
                    },
                },
            ],
            actionColumn: {
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                width: 224,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
                                    apiPath: '/admin/mall_activities/:id/disabled',
                                    config: {
                                        content: '{{row.name}}已被关闭',
                                        text: '关闭',
                                    },
                                    confirm: {
                                        text:
                                            services.language.getText('isCloseActivity') +
                                            '{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
                                    apiPath: '/admin/mall_activities/:id/enabled',
                                    config: {
                                        content: '{{row.name}}已被开启',
                                        text: '开启',
                                    },
                                    confirm: {
                                        text: '是否开启{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: services.language.getText('common.edit'),
                        path: '/mall-activity/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: services.language.getText('copy'),
                        path: '/mall-activity/copy/{{row.id}}',
                    },
                    // {
                    //     type: 'component',
                    //     component: 'EntityButton',
                    //     config: {
                    //         props: {
                    //             type: 'link',
                    //             disabled: false,
                    //             children: '报名记录',
                    //         },
                    //         propsHandler(buttonProps: any, entityButtonProps: any): any {
                    //             const id = entityButtonProps.row.id;
                    //             return {
                    //                 ...buttonProps,
                    //                 target: '_blank',
                    //                 className: 'table-action-column-item',
                    //                 href: '#/magic-mall-activity-registration?id=' + id,
                    //                 style: { padding: 0 },
                    //             };
                    //         },
                    //     },
                    // },

                    {
                        type: 'link',
                        text: '报名记录',
                        path: '/mall-activity/record/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'ExportHandlerButton',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
                            apiPath: '/admin/mall_activities/{{row.id}}/export',
                            text: '下载数据',
                        },
                    },
                    {
                        type: 'component',
                        component: 'PageNewPreviewCodeButton',
                        config: {
                            codeTypes: 'all',
                            codeModalType: 'item',
                            codePageType: 'mallActivityPage',
                        },
                    },
                ],
            },
        };
        return createElement(ComponentsManager.get('DataTable'), tableProps);
    };

    render() {
        return <div>{this.renderTable()}</div>;
    }
}
function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
    };
}

export const MallActivityTable = connect(mapStateToProps)(mallActivityTable);
