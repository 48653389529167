import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
export const config: Config = {
    entities: {
        CouponRefundOrderEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/couponRefundOrder',
            filters: {
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                subsites: {
                    type: 'string.options.select',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<ksjssj>>',
                },
                refundDateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<tkcgsj>>',
                    startField: 'refundedStartTime',
                    endField: 'refundedEndTime',
                },
                orderType: {
                    type: 'string.options.select',
                    displayName: '<<ddlx>>',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'SALE_COUPON', name: '<<smyhq>>' },
                        { id: 'CREDIT_ESHOP', name: '<<creditEShop>>' },
                        { id: 'GROUP_BUYING_COUPON', name: '<<yhqptdd>>' },
                    ],
                },
                saleRuleName: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                },
                serviceApplyNo: {
                    type: 'string',
                    displayName: '<<afterSalesOrderNo>>',
                },
                orderOrigin: {
                    type: 'string.options.select',
                    displayName: '<<orderChannel>>',
                    options: [
                        { id: 'WECHAT', name: '<<wxMiniP>>' },
                        { id: 'ALIPAY', name: '<<aliMiniP>>' },
                        { id: 'APP', name: 'APP' },
                        { id: 'DOUYIN', name: '<<douyinMiniP>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                saleRuleName: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                upstreamOrderId: {
                    type: 'string',
                    displayName: '<<upstreamOrderId>>',
                },
                couponName: {
                    type: 'array.stringLineFeed',
                    displayName: '<<couponName>>',
                },
                couponNo: {
                    type: 'array.stringLineFeed',
                    displayName: '<<couponBaCode>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<createTimeStr>>',
                },
                refundTime: {
                    type: 'string',
                    displayName: '<<tkcgsj>>',
                },
                interfaceStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<tkdzt>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                refundMoney: {
                    type: 'number',
                    displayName: '<<tkxjje>>',
                },

                refundItemMode: {
                    type: 'string.statusBadge',
                    displayName: '<<tkfs>>',
                },
                orderType: {
                    type: 'string.options.select',
                    displayName: '<<ddlx>>',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'SALE_COUPON', name: '<<smyhq>>' },
                        { id: 'CREDIT_ESHOP', name: '<<creditEShop>>' },
                        { id: 'GROUP_BUYING_COUPON', name: '<<yhqptdd>>' },
                    ],
                },
                orderOrigin: {
                    type: 'string.options.select',
                    displayName: '<<orderChannel>>',
                    options: [
                        { id: 'WECHAT', name: '<<wxMiniP>>' },
                        { id: 'ALIPAY', name: '<<aliMiniP>>' },
                        { id: 'APP', name: 'APP' },
                        { id: 'DOUYIN', name: '<<douyinMiniP>>' },
                    ],
                },
                serviceApplyNo: {
                    type: 'string',
                    displayName: '<<afterSalesOrderNo>>',
                },
                refundCreateReason: {
                    type: 'string',
                    displayName: '<<scyy>>',
                },
                refundPointValue: {
                    type: 'number',
                    displayName: '<<tkjf>>',
                },
            },
        },
    },
    components: {
        CouponRefundOrderView: {
            component: 'Viewport',
            entity: 'CouponRefundOrderEntity',
        },
        CouponRefundOrderPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'CouponRefundOrderFilter',
                },
                {
                    component: 'GridLayout',
                    className: 'mt24',
                    style: {
                        marginBottom: 12,
                    },
                    items: [
                        {
                            component: 'ExportDataByFilterParamsButton',
                            params: {
                                text: '<<exportSearchResult>>',
                                style: {
                                    width: '120px',
                                },
                                apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                apiPath:
                                    '/admin/refunds/download?order_types=SALE_COUPON,CREDIT_ESHOP,GROUP_BUYING_COUPON,COUPON_PACKAGE',
                                requestMethod: 'get',
                            },
                        },
                    ],
                },
                { component: 'CouponRefundOrderTables' },
            ],
        },
        CouponRefundOrderFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsites',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'orderNo',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'dateRange',
                },
                {
                    property: 'orderType',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'saleRuleName',
                },
                {
                    property: 'serviceApplyNo',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'refundDateRange',
                },
                {
                    property: 'orderOrigin',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
            ],
        },
        CouponRefundOrderTables: {
            component: 'Tabs',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<all>>',
                    content: {
                        component: 'CouponRefundOrderTable',
                    },
                    params: {},
                },
                {
                    title: '<<wcl>>',
                    content: {
                        component: 'CouponRefundOrderTable',
                    },
                    params: { interfaceStatus: 'APPLYING' },
                },
                {
                    title: '<<tkcg>>',
                    content: {
                        component: 'CouponRefundOrderTable',
                    },
                    params: { interfaceStatus: 'REFUND_SUCC' },
                },
                {
                    title: '<<tksb>>',
                    content: {
                        component: 'CouponRefundOrderTable',
                    },
                    params: { interfaceStatus: 'REFUND_FAIL' },
                },
            ],
        },
        CouponRefundOrderTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: {
                        x: true,
                    },
                    columns: [
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'saleRuleName',
                            width: 300,
                            displayConfig: {
                                style: {
                                    maxWidth: '360px',
                                },
                            },
                        },
                        {
                            property: 'orderNo',
                        },
                        {
                            property: 'upstreamOrderId',
                        },
                        {
                            property: 'couponName',
                            displayConfig: {
                                showField: 'couponName',
                            },
                        },
                        {
                            property: 'couponNo',
                            displayConfig: {
                                showField: 'couponNo',
                                displayCouponStatus: true,
                            },
                        },
                        {
                            property: 'interfaceStatus',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                                statusConfig: {
                                    APPLYING: {
                                        text: '<<wcl>>',
                                        status: 'warning',
                                    },
                                    SUBMIT_SUCC: {
                                        text: '<<tjcg_1>>',
                                        status: 'processing',
                                    },
                                    SUBMIT_FAIL: {
                                        text: '<<tjsb_1>>',
                                        status: 'error',
                                    },
                                    REFUND_SUCC: {
                                        text: '<<tkcg>>',
                                        status: 'success',
                                    },
                                    REFUND_FAIL: {
                                        text: '<<tksb>>',
                                        status: 'error',
                                    },
                                },
                            },
                        },
                        {
                            property: 'createTime',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'refundTime',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                            },
                        },
                        {
                            property: 'refundMoney',
                        },
                        { property: 'refundPointValue' },
                        {
                            property: 'refundItemMode',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                                statusConfig: {
                                    REVERT: {
                                        text: '<<revert>>',
                                        status: 'processing',
                                    },
                                    TRANSFERS: {
                                        text: '<<zz_4>>',
                                        status: 'processing',
                                    },
                                    SYSTEM: {
                                        text: '<<xtnth>>',
                                        status: 'processing',
                                    },
                                    STORES: {
                                        text: '<<mdth>>',
                                        status: 'processing',
                                    },
                                },
                            },
                        },
                        {
                            property: 'orderType',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '150px',
                                },
                            },
                        },
                        {
                            property: 'orderOrigin',
                        },
                        {
                            property: 'serviceApplyNo',
                        },
                        {
                            property: 'refundCreateReason',
                            displayConfig: {
                                className: 'ant-table-cell-multi-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                    '-webkit-line-clamp': '3',
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        width: 100,
                        fixed: 'right',
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    statusKey: 'canRefund',
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                            apiPath: '/admin/refunds/:id',
                                            config: {
                                                content: '<<zctk>>',
                                                text: '<<zctk>>',
                                            },
                                            confirm: {
                                                text: '<<qdyzxfqtk>>',
                                            },
                                        },
                                        {
                                            value: false,
                                            config: {
                                                content: '<<zctk>>',
                                                text: '<<zctk>>',
                                                disabled: true,
                                            },
                                        },
                                    ],
                                },
                            },
                            // {
                            //             type: 'link',
                            //             text: '查看详情',
                            //             path: '/coupon-refund-order/{{row.id}}',
                            //         },
                        ],
                    },
                },
            ],
        },
        CouponRefundOrderInfoPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'CouponRefundOrderEntity',
            loaderType: 'get',
            items: [
                {
                    component: 'CouponRefundOrderInfo',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/coupon-refund-order',
                    component: 'CouponRefundOrderView',
                    breadcrumbName: '<<saleCouponRefund>>',
                    privilege: {
                        path: 'saleCouponRefund',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/:id',
                            component: 'CouponRefundOrderInfoPage',
                            breadcrumbName: '<<yhqtkdxq>>',
                        },
                        { path: '/', component: 'CouponRefundOrderPage' },
                    ],
                },
            ],
        },
    ],
};
