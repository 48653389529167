import { find } from 'lodash';

/**
 * 组织机构类型
 */
export enum OrganizationType {
    // 门店
    SUBSITE = 'subsite',
    // 专柜
    MERCHANT = 'merchant',
    // 自定义门店
    SUBSITE_CUSTOM = 'subsite_custom',
    // 自定义专柜
    MERCHANT_CUSTOM = 'merchant_custom',
    // 自定义专柜
    MERCHANT_CUSTOM2 = 'merchant_custom2',
    // 租户
    TENANT = 'tenant',
}

export enum AttributeCode {
    ORGANIZATION_TYPE = 'organization_type',
    ORGIN_ID = 'origin_id',
    SUBSITE_ID = 'subsite_id',
    STATUS = 'status',
}

export const getAttributeValue = (organization: any, attributeCode: AttributeCode) => {
    const organizationTypeObj = find(organization.extendAttributes, {
        attributeCode,
    });
    const attributeValue = organizationTypeObj ? organizationTypeObj.attributeValue : '';
    return attributeValue;
};

export const handleFilterCustomOrganization = (treeData: any[]) => {
    return treeData.reduce((previous: any[], current: any) => {
        const organizationType = getAttributeValue(current, AttributeCode.ORGANIZATION_TYPE);
        if (
            organizationType === OrganizationType.SUBSITE &&
            getAttributeValue(current, AttributeCode.STATUS) !== '营业'
        ) {
            return previous;
        }
        if (current.children && current.children.length) {
            current.children = handleFilterCustomOrganization(current.children);
        }
        if (
            organizationType === OrganizationType.SUBSITE_CUSTOM ||
            organizationType === OrganizationType.MERCHANT_CUSTOM ||
            organizationType === OrganizationType.MERCHANT_CUSTOM2
        ) {
            return previous.concat(current.children || []);
        }
        return [...previous, current];
    }, []);
};

/**
 * 递归处理树数据
 */
export const handleOrganizationTreeData = (treeData: any) => {
    treeData.forEach((item: any) => {
        const organizationType = getAttributeValue(item, AttributeCode.ORGANIZATION_TYPE);
        switch (organizationType) {
            // case OrganizationType.TENANT:
            //     item.name = '总部';
            //     break;
            case OrganizationType.SUBSITE:
                item.name = '门店-' + item.name;
                break;
            case OrganizationType.MERCHANT:
                item.name = '专柜-' + item.name;
                break;
            default:
                break;
        }

        item.key = item.id;
        item.value = item.id;
        item.title = item.name;
        if (item.children && item.children.length > 0) {
            handleOrganizationTreeData(item.children);
        }
    });
    return treeData;
};

/**
 * 获取选中节点的父级节点
 */
export const getSelectedTreeNodeParents = (selectedTreeNode: any, treeData: any) => {
    const selectedTreeNodeParents: any = [];
    if (selectedTreeNode && selectedTreeNode.parentPath) {
        const parentIds = selectedTreeNode.parentPath.split(',');
        let parentNode = treeData;
        parentIds.forEach((id: any) => {
            if (!id) {
                return false;
            }
            let node = _findNode(id, parentNode);
            if (node) {
                parentNode = node.children;
                selectedTreeNodeParents.push(node);
            }
        });
        selectedTreeNodeParents.push(selectedTreeNode);
        return selectedTreeNodeParents;
    }
};

const _findNode = (id: any, treeData: any) => {
    let node: any;
    treeData.forEach((item: any) => {
        if (String(id) === item.id) {
            node = item;
            return false;
        } else if (item.children && item.children.length > 0) {
            _findNode(id, item.children);
        }
    });
    return node;
};
