import React, { Component } from 'react';
import { Button, message, Popconfirm, InputNumber } from 'antd';
import { cloneDeep, isEqual, map } from 'lodash';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

type RulesValue = {
    /**
     * 任选件数
     */
    rewardConditionValue?: number;
    /**
     * 打包金额
     */
    rewardValue?: number;
};

export interface FixedPriceRulesProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Array<RulesValue> | null, name: string) => void;
    /**
     * 当前值
     */
    value: Array<RulesValue> | null;
    /**
     * 是否禁止删除和新增规则
     */
    disabled: boolean;
}

/**
 * 消费满减邮费规则阶梯组件
 */
export class FixedPriceRules extends Component<FixedPriceRulesProps> {
    componentDidMount() {
        let { value } = this.props;
        if (!value) {
            value = [{}];
        }
        this.changeValue(value);
    }
    changeValue = (value: Array<RulesValue> | null) => {
        let { name, onChange } = this.props;
        if (onChange) {
            onChange(value, name);
        }
    };
    onFieldChange = (field: keyof RulesValue, fieldValue: number | undefined, index: number) => {
        let value = cloneDeep(this.props.value) || [];
        if (value.length) {
            value[index][field] = fieldValue;
        } else {
            value.push({ [field]: fieldValue });
        }
        this.changeValue(value);
    };

    onAddRule() {
        let value = cloneDeep(this.props.value);
        if (!value?.length) {
            value = [{}];
        }
        if (value.length >= 3) {
            message.warning(language.getText('suppoert3Count'));
            return;
        }
        value.push({});
        this.changeValue(value);
    }

    onRemoveRule(index: number) {
        let value = cloneDeep(this.props.value) || [];
        value.splice(index, 1);
        if (!value.length) {
            value = [{}];
        }
        this.changeValue(value);
    }

    shouldComponentUpdate(nextProps: FixedPriceRulesProps) {
        return !isEqual(this.props, nextProps);
    }

    render() {
        let { value, disabled } = this.props;
        disabled = disabled ? disabled : false;
        return (
            <div className="fixed-price-rules">
                {map(value, (rule, index) => this.renderRule(rule, index, disabled))}
                {value && value.length ? null : this.renderRule({}, 0, disabled)}
                <div className="extra">{language.getText('priceRuleExtra')}</div>
                {(!value || value.length < 3) && !disabled && (
                    <Button
                        type="default"
                        className="add-rule-button"
                        onClick={() => this.onAddRule()}
                    >
                        {language.getText('addRule')}
                    </Button>
                )}
            </div>
        );
    }

    renderRule(rule: RulesValue, index: number, disabled: boolean) {
        return (
            <div className="rule" key={index}>
                <div className="rule-content">
                    <span className="item-text">{language.getText('dbje')}</span>
                    <div className="rule-count-input">
                        <InputNumber
                            value={rule.rewardValue}
                            precision={2}
                            max={999999}
                            min={0.01}
                            placeholder={services.language.getText('inputPlease')}
                            onChange={(e) => this.onFieldChange('rewardValue', e, index)}
                        />
                    </div>
                    <span className="item-text"> {language.getText('yuanAndCasual')} </span>
                    <div className="rule-count-input">
                        <InputNumber
                            value={rule.rewardConditionValue}
                            precision={0}
                            max={999999}
                            min={1}
                            placeholder={services.language.getText('inputPlease')}
                            onChange={(e) => this.onFieldChange('rewardConditionValue', e, index)}
                        />
                    </div>
                    <span className="item-text"> {language.getText('jsp')} </span>
                    {!disabled && (
                        <Popconfirm
                            onConfirm={this.onRemoveRule.bind(this, index)}
                            title={services.language.getText('sfscggz')}
                            okText={language.getText('common.ok')}
                            cancelText={language.getText('common.cancel')}
                        >
                            <Button type="default" className="remove-rule-button">
                                {services.language.getText('common.delete')}
                            </Button>
                        </Popconfirm>
                    )}
                </div>
            </div>
        );
    }
}
