//@ts-nocheck
import React, { PureComponent } from 'react';
import { Icon, Button } from 'antd';
import { actions, services } from '@comall-backend-builder/core';
import { get } from 'lodash';
import classnames from 'classnames';
import {
    Popconfirm as AntPopconfirm,
    Modal as AntModal,
    message as AntMessage,
    Button as AntButton,
} from 'antd';
import QRCode from 'qrcode.react';
import { DESIGN_PAGE_TYPES, NEW_CLIENT_DESIGN_PAGE_TYPES } from '../../constants/page-design';

import './index.less';
const language = services.language;
const navigation = services.navigation;
const searchAction = actions.searchAction;

const tenantDomain = encodeURIComponent(ENV.TENANT_DOMAIN || window.location.origin);

const defaultPagePreview = './images/editor/default-page-preview.png';

function getNewType(object: any) {
    for (let key in object) {
        object[key] = object[key].replace(/<<|>>/g, '');
    }
    return object;
}

/**
 * 可视化页面--模板列表项
 */
export class PageTemplateListItem extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            hover: false,
        };
    }

    handleEdit = () => {
        const {
            id,
            parentProps: { applicableChannels },
        } = this.props;
        let path = `/page-template-manage/edit/${id}`;
        if (applicableChannels === 'NEW_CLIENT') {
            path = `/new-page-template-manage/edit/${id}`;
        }
        navigation.goto(path);
    };
    handleDesign = () => {
        const {
            id,
            type,
            parentProps: { applicableChannels },
        } = this.props;
        const typeToNewType = {
            platform: 'PLATFORM_PAGE',
            home: 'STORE_PAGE',
            merchant: 'MERCHANT_PAGE',
            topic: 'CUSTOM_PAGE',
            member: 'MEMBER_CENTER_PAGE',
        };
        if (applicableChannels === 'NEW_CLIENT') {
            window.open(`/design-editor/?type=${typeToNewType[type]}&id=${id}&mode=template`);
            return;
        }
        let path = `/page-template-manage/design/${id}/${type}`;
        navigation.goto(path);
    };
    handlePreview = () => {
        AntModal.info({
            title: language.getText('components.TemplateManage.preview'),
            content: this.renderPreviewContent(),
            width: 520,
        });
    };

    handleSelect = () => {
        const { parentProps, id, name } = this.props;
        parentProps.onSelected({ id, name });
    };

    copyLink = (url: string) => {
        return function() {
            let input = document.createElement('input');
            input.value = url;
            document.body.appendChild(input);
            input.select();
            if (document.execCommand('Copy')) {
                AntMessage.success(services.language.getText('copySuccess'));
            } else {
                AntMessage.success(services.language.getText('copyError'));
            }
            document.body.removeChild(input);
        };
    };
    renderPreviewContent = () => {
        const classNamePrefix = 'template-preview-modal-wrapper';
        const { preview, name, type, id, lastModifyTime } = this.props;
        const time = (lastModifyTime + '').replace(' ', '');
        const url =
            ENV.DESIGN_CLIENT_URL +
            `?page=WYSIWG&template=1&mode=preview&type=${type}&id=${id}&time=${time}&tenant=${tenantDomain}`;
        return (
            <div className={classNamePrefix}>
                <div className={`${classNamePrefix}-pic`}>
                    <img
                        alt={language.getText('ylt')}
                        src={get(preview, '[0].path') || defaultPagePreview}
                    />
                </div>
                <div className={`${classNamePrefix}-right`}>
                    <div className={`${classNamePrefix}-name`}>{name}</div>
                    <div className={`${classNamePrefix}-type`}>{get(type, 'name')}</div>

                    <div className={`${classNamePrefix}-code`}>
                        <div className={`${classNamePrefix}-code-description`}>
                            {language.getText('smewmyl')}
                            <br />
                            {language.getText('orClick')}
                            <AntButton
                                type="link"
                                style={{ padding: 0 }}
                                className="span-link"
                                onClick={this.copyLink(url)}
                            >
                                {language.getText('copyLink')}
                            </AntButton>
                            {language.getText('onMobileOpen')}
                        </div>
                        <QRCode value={url} />
                    </div>
                </div>
            </div>
        );
    };

    handleDelete = () => {
        const {
            parentProps: {
                entity,
                params: { type },
            },
            id,
        } = this.props;
        entity.delete({ id }, { action: searchAction(entity, { type: type || '' }) });
    };
    render() {
        const { preview, name, type, parentProps } = this.props;
        const { hover } = this.state;
        const enableEdit = get(parentProps, 'type', 'template') === 'template';
        const isNewClient = get(parentProps, 'applicableChannels') === 'NEW_CLIENT';
        const CURRENT_DESIGN_PAGE_TYPES = getNewType(DESIGN_PAGE_TYPES);
        const CURRENT_NEW_CLIENT_DESIGN_PAGE_TYPES = getNewType(NEW_CLIENT_DESIGN_PAGE_TYPES);

        const pageType = isNewClient
            ? language.getText(CURRENT_NEW_CLIENT_DESIGN_PAGE_TYPES[type])
            : language.getText(CURRENT_DESIGN_PAGE_TYPES[type]);
        return (
            <div className={classnames('template-item', { hover: hover })}>
                <div className="template-item-preview">
                    <div className={classnames('actions', 'mask')}>
                        {enableEdit && (
                            <Button type="primary" onClick={this.handleDesign}>
                                {language.getText('components.TemplateManage.decorate')}
                            </Button>
                        )}
                        {!enableEdit && (
                            <Button type="primary" onClick={this.handleSelect}>
                                {language.getText('components.TemplateManage.select')}
                            </Button>
                        )}
                        {!isNewClient && (
                            <Button onClick={this.handlePreview}>
                                {language.getText('components.TemplateManage.preview')}
                            </Button>
                        )}
                    </div>
                    <img
                        alt={language.getText('ylt')}
                        src={get(preview, '[0].path') || defaultPagePreview}
                    />
                </div>
                <div className="template-item-content">
                    <p className="template-item-title">{name}</p>
                    <p className="template-item-extra">{pageType}</p>
                </div>
                {enableEdit && (
                    <ul className="template-item-actions">
                        <li>
                            <AntPopconfirm
                                title={language.getText('qdscdqmbm')}
                                onConfirm={this.handleDelete}
                                okText={language.getText('yes')}
                                cancelText={language.getText('no')}
                            >
                                <Icon type="delete" title={language.getText('scmb')} key="delete" />
                            </AntPopconfirm>
                        </li>
                        <li onClick={this.handleEdit}>
                            <Icon type="setting" title={language.getText('szmb')} key="setting" />
                        </li>
                    </ul>
                )}
            </div>
        );
    }
}
