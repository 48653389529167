import React, { CSSProperties, Component } from 'react';
import { TipsModal, TipsModalProps } from '../components/tips-modal';

interface ExtraElementProps {
    tips: string[];
    tipsModal?: TipsModalProps;
}

class ExtraElement extends Component<ExtraElementProps> {
    renderTip = (tip: string) => {
        const { tipsModal } = this.props;
        const style = {
            color: 'rgb(204, 204, 204)',
        };
        const tipsStyle: CSSProperties = {
            display: 'inline-block',
            ...(tipsModal?.style || {}),
        };
        if (tip.includes('{modal}') && tipsModal) {
            const [before, after] = tip.split('{modal}');
            return (
                <div style={style}>
                    <span>{before || ''}</span>
                    <TipsModal {...tipsModal} style={tipsStyle} />
                    <span>{after || ''}</span>
                </div>
            );
        }
        return <div style={style}>{tip}</div>;
    };
    render() {
        const { tips } = this.props;
        return <div>{tips.map(this.renderTip)}</div>;
    }
}

export const extraElement = (props: ExtraElementProps) => {
    return React.createElement(ExtraElement, props);
};
