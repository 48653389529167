/**
 * 新可视化页面模板管理
 */
import { Config } from '@comall-backend-builder/core/lib/parser';

import { TemplateTabs, TemplateTabPane } from './page-template-base';

export const config: Config = {
    components: {
        NewPageTemplateManageView: {
            component: 'Viewport',
        },
        NewPageTemplateManagePage: {
            entity: 'NewPageTemplateEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            params: {
                applicableChannels: 'NEW_CLIENT',
            },
            className: 'table-content-viewport',
            items: [
                {
                    component: 'GridLayout',
                    items: [
                        {
                            component: 'NewPageTemplateFilter',
                            span: 24,
                        },
                        {
                            component: 'Button',
                            text: '<<components.TemplateManage.createTemplate>>',
                            type: 'primary',
                            icon: 'plus',
                            span: 12,
                            route: '/new-page-template-manage/add',
                            className: 'mt24',
                            style: {
                                marginBottom: 10,
                            },
                        },
                    ],
                },
                {
                    component: 'NewPageTemplateManageTabs',
                },
            ],
        },
        NewPageTemplateManageTabs: {
            ...TemplateTabs('NewPageTemplateTabPane'),
        },
        NewPageTemplateFilter: {
            component: 'FilterForm',
            direction: 'inline',
            fields: [
                {
                    property: 'name',
                },
            ],
            submit: { text: '<<common.search>>' },
        },
        NewPageTemplateTabPane: {
            className: 'template-manage-tabpane',
            ...TemplateTabPane,
            applicableChannels: 'NEW_CLIENT',
            type: 'template',
            grid: { gutter: 8, column: 6 },
        },
        NewPageTemplateAdd: {
            component: 'Card',
            title: '<<components.TemplateManage.createTemplate>>',
            content: {
                component: 'NewPageTemplateAddForm',
            },
        },
        NewPageTemplateAddForm: {
            component: 'CreateFormPlus',
            entity: 'NewPageTemplateEntity',
            params: {
                applicableChannels: 'NEW_CLIENT',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'type',
                },
                {
                    property: 'preview',
                    width: 200,
                    rules: [{ required: false }],
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                    displayConfig: {
                        style: {
                            width: 50,
                        },
                    },
                },
                // {
                //     property: 'isDefault',
                //     visible: (e: any) => {
                //         return get(e, 'type.id') === 'merchant';
                //     },
                // },
                { property: 'enabled' },
            ],
            labelCol: 10,
            submit: { text: '<<common.submit>>' },
            submitSuccessBehavior: {
                route: '/new-page-template-manage',
            },
            footer: {
                items: [
                    {
                        text: '<<common.cancel>>',
                        htmlType: 'button',
                        type: 'default',
                        route: 'goBack',
                        style: { marginLeft: 16 },
                    },
                ],
            },
            style: {
                width: '400px',
            },
        },
        NewPageTemplateEdit: {
            component: 'Card',
            title: '<<components.TemplateManage.setTemplate>>',
            content: {
                component: 'NewPageTemplateEditForm',
            },
        },
        NewPageTemplateEditForm: {
            component: 'ModifyFormPlus',
            entity: 'NewPageTemplateEntity',
            params: {
                applicableChannels: 'NEW_CLIENT',
            },
            loaderType: 'get',
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'type',
                },
                {
                    property: 'preview',
                    width: 200,
                    rules: [{ required: false }],
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                    displayConfig: {
                        style: {
                            width: 50,
                        },
                    },
                },
                // {
                //     property: 'isDefault',
                //     visible: (e: any) => {
                //         return get(e, 'type.id') === 'merchant';
                //     },
                // },
                { property: 'enabled' },
            ],
            labelCol: 10,
            submit: { text: '<<common.submit>>' },
            submitSuccessBehavior: {
                route: '/new-page-template-manage',
            },
            footer: {
                items: [
                    {
                        text: '<<common.cancel>>',
                        htmlType: 'button',
                        type: 'default',
                        route: 'goBack',
                        style: { marginLeft: 16 },
                    },
                ],
            },
            style: {
                width: '400px',
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/new-page-template-manage',
                    component: 'NewPageTemplateManageView',
                    breadcrumbName: '<<components.NavMenu.templatesPageManagement>>',
                    privilege: {
                        path: 'decorateTemplate',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'NewPageTemplateEdit',
                        },
                        {
                            path: '/add',
                            component: 'NewPageTemplateAdd',
                        },
                        {
                            path: '/',
                            component: 'NewPageTemplateManagePage',
                        },
                    ],
                },
            ],
        },
    ],
};
