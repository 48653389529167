import { services } from '@comall-backend-builder/core';
import { Button as AntButton, Table as AntTable } from 'antd';
import { TableProps } from 'antd/lib/table';
import React from 'react';

interface Subsite {
    id: string;
    name: string;
    address: string;
    status: number;
}

interface Props {
    value?: Subsite[];
    onRemove(value: Subsite): void;
}
export default function SubsitesDisplayTable(props: Props) {
    const { value, onRemove } = props;
    const tableProps: TableProps<Subsite> = {
        dataSource: value,
        pagination: false,
        columns: [
            {
                dataIndex: 'name',
                title: services.language.getText('subsiteName'),
                render(name) {
                    return name;
                },
            },
            {
                dataIndex: 'address',
                title: '门店地址',
                render(address) {
                    return address;
                },
            },
            {
                dataIndex: 'status',
                title: '营业状态',
                render(status: number) {
                    const statusMap: Record<number, string> = {
                        0: '删除',
                        1: '正常',
                        2: '停用',
                    };
                    return statusMap[status] || '未知';
                },
            },
            {
                title: services.language.getText('common.tableAction'),
                render(_, subsite) {
                    return (
                        <AntButton type="link" onClick={() => onRemove(subsite)}>
                            {services.language.getText('common.delete')}
                        </AntButton>
                    );
                },
            },
        ],
        scroll: {
            y: 300,
        },
    };
    return <AntTable {...tableProps} />;
}
