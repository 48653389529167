import React, { Component } from 'react';
import { Row as AntRow, InputNumber, Button, message, Popconfirm } from 'antd';
import { clone, map, concat, isEmpty, dropRight } from 'lodash';
import { ImageUpload } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core';
import './index.less';

type ActivityRewardValue = {
    percent?: number;
    cardCollectionItemPicture?: any;
    fixed?: boolean;
    amount?: number;
};

enum RewardItems {
    percent = 'PERCENT',
    amount = 'AMOUNT',
}

export interface CardCollectionItemProps {
    name: string;
    value: Array<ActivityRewardValue>;
    maxCount: number;
    disabled: boolean;
    fixed?: boolean;
    onChange: (value: Array<ActivityRewardValue> | null, name: string) => void;
}
export class CardCollectionItemConfig extends Component<CardCollectionItemProps> {
    static defaultProps = {
        maxCount: 3,
        disabled: false,
    };
    componentDidMount() {
        let { name, value, onChange } = this.props;
        if (!value) {
            let item: ActivityRewardValue = {};
            value = concat([], item);
            if (onChange) {
                onChange(value, name);
            }
        }
    }

    componentWillReceiveProps(nextProps: CardCollectionItemProps) {
        let { name, value, onChange, fixed, maxCount } = this.props;
        const valueLength = nextProps.value ? nextProps.value.length : 0;
        const length = value ? value.length : 0;

        if (
            nextProps.value.length === length &&
            maxCount === nextProps.maxCount &&
            fixed === nextProps.fixed
        ) {
            return;
        }

        if (nextProps.fixed) {
            // 固定数量
            if (valueLength < nextProps.maxCount && nextProps.value) {
                //  当前值小于固定数量,补齐~
                let empty = [];
                for (let index = 0; index < nextProps.maxCount - valueLength; index++) {
                    empty.push({});
                }
                onChange(concat(nextProps.value, empty), name);
            } else {
                let arr = dropRight(nextProps.value, valueLength - nextProps.maxCount);
                onChange(arr, name);
            }
        }
    }

    onAddReward() {
        let { name, value, onChange, maxCount } = this.props;
        if (value !== null && !isEmpty(value)) {
            if (value.length >= maxCount) {
                message.warning(
                    services.language.getText('mostSupport') +
                        maxCount +
                        services.language.getText('notAdd')
                );
                return;
            }
            value = concat([], value);
            value.splice(value.length, 0, {});
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onRemoveReward = (index: number) => {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(index, 1);
            if (!value.length) {
                let item: ActivityRewardValue = {};
                value = concat([], item);
            }
        } else {
            let item: ActivityRewardValue = {};
            value = concat([], item);
        }

        if (onChange) {
            onChange(value, name);
        }
    };

    onInputValueChange(event: any, index: number, type: string) {
        let { name, value, onChange } = this.props;
        let inputValue = event;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            if (type === RewardItems.percent) {
                item.percent = inputValue;
            }
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: ActivityRewardValue = {};

            if (type === RewardItems.percent) {
                item.percent = inputValue;
            }
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    private onNumInputValueChange(event: any, index: number, type: string) {
        let { name, value, onChange } = this.props;
        let inputValue = event;

        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            if (type === RewardItems.amount) {
                item.amount = inputValue;
            }
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: ActivityRewardValue = {};

            if (type === RewardItems.amount) {
                item.amount = inputValue;
            }
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    render() {
        let { value, maxCount, disabled } = this.props;
        return (
            <div className="card-collection-item-config">
                {map(value, (reward, index) => this.renderReward(reward, index, disabled))}
                {value && value.length ? null : this.renderReward({}, 0, disabled)}
                {value && value.length < maxCount && !disabled && (
                    <Button
                        type="default"
                        className="reward-add-reward-button"
                        onClick={() => this.onAddReward()}
                    >
                        {services.language.getText('xzkp')}
                    </Button>
                )}
            </div>
        );
    }

    onPicChange = (cardCollectionItemPicture: any, index: number) => {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.cardCollectionItemPicture = cardCollectionItemPicture;
            value = concat([], value);
            value.splice(index, 1, item);
        } else {
            let item: ActivityRewardValue = {};
            item.cardCollectionItemPicture = cardCollectionItemPicture;
            value = concat([], item);
        }
        if (onChange) {
            onChange(value, name);
        }
    };
    renderReward(reward: ActivityRewardValue, index: number, isDisabled: boolean) {
        const { fixed } = this.props;
        let _imageControlInfo = Object.assign(
            {},
            {
                name: 'cardCollectionItemPicture',
                fileName: 'fileName',
                value: reward.cardCollectionItemPicture,
                accept: 'image/*',
                uploadType: 'single',
                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                mode: 'picture-card',
                multiple: false,
                maxCount: 1,
                limitTips: {
                    limitText: services.language.getText('pictureLimit80'),
                    limitUnit: ' ',
                    className: 'image-picker-tips-ext',
                },
            }
        );
        return (
            <div className="reward" key={index}>
                <AntRow>
                    <div className="reward-content">
                        <span>
                            {services.language.getText('kp')}
                            {index + 1}:
                        </span>
                        {!fixed && !isDisabled && (
                            <Popconfirm
                                onConfirm={this.onRemoveReward.bind(this, index)}
                                title={services.language.getText('sfscggz')}
                                okText={services.language.getText('common.ok')}
                                cancelText={services.language.getText('common.cancel')}
                            >
                                <Button type="default" className="reward-remove-reward-button">
                                    {services.language.getText('sckp')}
                                </Button>
                            </Popconfirm>
                        )}
                    </div>
                </AntRow>

                <div className="reward-content-list">
                    <div className="reward-content-item">
                        <div className="reward-pic">
                            <ImageUpload
                                {..._imageControlInfo}
                                onChange={(value: any, name: string) => {
                                    this.onPicChange(value, index);
                                    return {};
                                }}
                            />
                            <span className="reward-item-text">
                                {services.language.getText('hqjl')}
                            </span>
                            <InputNumber
                                type="number"
                                min={0}
                                precision={2}
                                max={100}
                                disabled={isDisabled}
                                value={reward.percent}
                                onChange={(e) =>
                                    this.onInputValueChange(e, index, RewardItems.percent)
                                }
                            />
                            <span className="reward-item-text">%</span>
                            <span className="reward-item-text">
                                {services.language.getText('syffsl')}
                            </span>
                            <InputNumber
                                type="number"
                                min={0}
                                precision={0}
                                max={10000000}
                                disabled={isDisabled}
                                value={reward.amount}
                                onChange={(e) =>
                                    this.onNumInputValueChange(e, index, RewardItems.amount)
                                }
                            />
                            <span className="reward-item-text">
                                {services.language.getText('zhang')}
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
