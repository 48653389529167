import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { mapValues, defaults, get, debounce } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

export interface TopicPromotionEncourageEditFormProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
}

class topicPromotionEncourageEditFormView extends PureComponent<
    TopicPromotionEncourageEditFormProps
> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }
    now = new Date();

    render() {
        const { entity, onSubmit, wrappedComponentRef } = this.props;
        const executeStatus = get(entity, 'fields.activityVo.executeStatus');
        const disabled = executeStatus === 'NOT_STARTED' ? false : true;
        // false 未到开奖时间； true 正处于或者超过开奖时间
        const isLottery = +new Date(get(entity, 'fields.encourageInfo.lotteryTime')) <= +new Date();

        let fields = [
            {
                property: 'baseInfo.eventType',
                className: 'property-hide',
                controlConfig: {
                    disabled: disabled,
                },
            },
            {
                property: 'baseInfo.name',
                controlConfig: {
                    disabled: disabled,
                },
            },
            {
                property: 'baseInfo.dateRange',
                controlConfig: {
                    disabled: disabled,
                    style: {
                        width: 450,
                    },
                    range: {
                        start: this.now,
                    },
                },
            },
            {
                property: 'baseInfo.ruleIllustrate',
                controlConfig: {
                    disabled: isLottery,
                },
            },
            {
                property: 'encourageInfo.topic',
                controlConfig: {
                    disabled: disabled,
                },
            },
            {
                property: 'encourageInfo.rewardBehavior',
                controlConfig: {
                    disabled: disabled,
                },
            },
            {
                property: 'encourageInfo.subsiteIds',
                controlConfig: {
                    disabled: disabled,
                },
            },
            {
                property: 'encourageInfo.range',
            },
            {
                property: 'encourageInfo.type',
            },
            {
                property: 'encourageInfo.ladderRewardRule',
                controlConfig: {
                    disabled: isLottery,
                    isRange: true,
                    maxCount: 3,
                    rewardType: 'multi',
                    stepValueTextConfig: {
                        start: 'TOP第',
                        end: '获得以下奖励',
                        unit: '名',
                    },
                    rewardTextConfig: {
                        start: ' ',
                    },
                },
            },
            {
                property: 'encourageInfo.showTime',
                controlConfig: {
                    disabled: isLottery,
                },
            },
            {
                property: 'encourageInfo.lotteryTime',
                controlConfig: {
                    disabled: isLottery,
                },
            },
            {
                property: 'encourageInfo.lotteryType',
                controlConfig: {
                    disabled: isLottery,
                },
            },
        ];

        let formProps = {
            entity,
            componentId: 'TopicPromotionEncourageEditFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'topic-promotion-encourage-edit-form-container',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 800 },
            fields: fields,
            submit: { text: language.getText('common.save') },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('ModifyForm'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;
    let form: any;

    /**
     * 提交前额外验证
     * @param entityFields
     */
    const validate = (entityFields: any) => {
        const rewardRule = get(entityFields, 'encourageInfo.ladderRewardRule', null);
        const lotteryTime = get(entityFields, 'encourageInfo.lotteryTime', null);
        const endTime = get(entityFields, 'baseInfo.dateRange.end', null);
        let lastMaxNum: any = null;
        rewardRule.forEach((item: any) => {
            if (!item.rangeStartValue && !item.rangeEndValue) {
                AntMessage.warning(language.getText('qjjltjtxwz'));
                throw new Error();
            }

            if (item.rangeStartValue > item.rangeEndValue) {
                AntMessage.warning(language.getText('zdmcbnxyzxmc'));
                throw new Error();
            }
            if (lastMaxNum && item.rangeStartValue <= lastMaxNum) {
                AntMessage.warning(language.getText('mgjltjbnzd'));
                throw new Error();
            }
            lastMaxNum = item.rangeEndValue;
            if (
                !item.couponsChecked &&
                !item.goldChecked &&
                !item.onlineCouponsChecked &&
                !item.pointChecked
            ) {
                AntMessage.warning(language.getText('qzsxzyxjl'));
                throw new Error();
            }

            if (item.pointChecked && !item.point) {
                AntMessage.warning(language.getText('qjjftxwz'));
                throw new Error();
            }
            if (item.goldChecked && !item.gold) {
                AntMessage.warning(language.getText('jqjhibtxwz'));
                throw new Error();
            }
            if (item.onlineCouponsChecked && !item.onlineCouponsValue.length) {
                AntMessage.warning(language.getText('qjxsyhqtxwz'));
                throw new Error();
            }
            if (item.couponsChecked && !item.couponsValue.length) {
                AntMessage.warning(language.getText('qjxxyhqtxwz'));
                throw new Error();
            }
        });

        if (lotteryTime && endTime && +new Date(lotteryTime) < +new Date(endTime)) {
            AntMessage.warning(language.getText('kjsjbnzyhdjssj'));
            return false;
        }

        return true;
    };

    return defaults(
        {
            onSubmit,
            onFieldChange,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: debounce((event: any, fields: any) => {
                const entityFields = mapValues(fields, (field, name) => {
                    return field.value;
                });
                if (entityFields) {
                    const hasValidate = validate(entityFields);
                    if (hasValidate) {
                        entity.modify(entityFields, params);
                    }
                }
            }, 300),
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(
                        actions.unmountComponentAction('TopicPromotionEncourageEditFormContainer')
                    );
            },
        }
    );
}
export const TopicPromotionEncourageEditFormView = connect(mapDispatchToProps)(
    topicPromotionEncourageEditFormView
);
