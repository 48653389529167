import React from 'react';
import { Input, Form, Switch } from 'antd';
import { get } from 'lodash';
import { tools } from '@comall-backend-builder/design-components-basis';

import MoreConfigForm from './more-config-form';
import './index.less';

export interface MoreConfig {
    text: string;
    color: string;
    image: string;
    linkParams: any;
    linkType: string;
}

export interface LivePreviewCardFormValues {
    title: {
        text: string;
        color: string;
        image: string;
    };
    more?: MoreConfig;
    background: {
        color: string;
        image: string;
    };
    showMore: boolean;
}

const FormItem = Form.Item;
const UploadPicture = tools.UploadPicture;
const ColorPicker = tools.ColorPicker;
const formItemLayout = tools.FormItemLayout;

const LivePreviewCardForm: React.FC<any> = (props) => {
    const { selector, form, sourceRoot, uploadAction, linkTypes, value } = props;
    const { getFieldDecorator, getFieldValue } = form;

    function handleChangeMore(more: MoreConfig): void {
        const { form, onChange } = props;
        const { getFieldsValue } = form;
        onChange &&
            onChange({
                ...getFieldsValue(),
                more,
            });
    }

    return (
        <Form className="live-preview-card-form">
            <FormItem {...formItemLayout} label="标题">
                {getFieldDecorator('title.text', {
                    initialValue: '直播',
                })(<Input />)}
            </FormItem>
            <FormItem {...formItemLayout}>
                <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-5"></div>
                <div className="title-color">
                    {getFieldDecorator('title.color', {
                        initialValue: '#000000',
                    })(<ColorPicker />)}
                </div>
            </FormItem>
            <FormItem {...formItemLayout}>
                <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-5"></div>
                <div className="image-wrap">
                    {getFieldDecorator('title.image', {
                        initialValue: '',
                    })(
                        <UploadPicture
                            className="image-uploader"
                            sourceRoot={sourceRoot}
                            uploadAction={uploadAction}
                        />
                    )}
                </div>
            </FormItem>
            <FormItem {...formItemLayout} label="更多">
                {getFieldDecorator('showMore', {
                    valuePropName: 'checked',
                })(<Switch />)}
            </FormItem>
            {getFieldValue('showMore') && (
                <FormItem {...formItemLayout} label="更多配置">
                    <MoreConfigForm
                        sourceRoot={sourceRoot}
                        selector={selector}
                        value={get(value, 'more', {})}
                        uploadAction={uploadAction}
                        linkTypes={linkTypes}
                        onChange={handleChangeMore}
                    />
                </FormItem>
            )}
            <FormItem {...formItemLayout} label="底图">
                {getFieldDecorator('background.color', {
                    initialValue: '#999999',
                })(<ColorPicker />)}
            </FormItem>
            <FormItem {...formItemLayout}>
                <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-5"></div>
                <div className="image-wrap">
                    {getFieldDecorator('background.image', {
                        initialValue: '',
                    })(
                        <UploadPicture
                            className="image-uploader"
                            sourceRoot={sourceRoot}
                            uploadAction={uploadAction}
                        />
                    )}
                </div>
            </FormItem>
        </Form>
    );
};

export const LivePreviewCard = tools.ConfigFormDecorator(LivePreviewCardForm);
