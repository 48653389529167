import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        CommentActivityEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/comment_activity',
            filters: {
                activityName: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<applicableSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string',
                    displayName: '<<endTime>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<applicableSubsite>>',
                },
                activityProductType: {
                    type: 'string.options.select',
                    displayName: '<<cysp>>',
                    options: [
                        { id: 'ALL', name: '<<allProduct>>' },
                        { id: 'INCLUDE_PRODUCT', name: '<<includeProduct>>' },
                        { id: 'EXCLUDE_PRODUCT', name: '<<excludeProduct>>' },
                    ],
                },
                // merchants: {
                //     type: 'array.popover',
                //     displayName: '<<applyMerchants>>',
                // },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<status>>',
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'NOTSTARTED',
                    options: [
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseInfo>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrhdmc>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<jyplylhdbcgzf>>',
                                maxLength: 20,
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<hdrq>>',
                            rules: [{ required: true, message: '<<qxzhdrq>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                    },
                },
                ruleInfo: {
                    type: 'object.subForm',
                    displayName: '<<activityRule>>',
                    properties: {
                        subsiteId: {
                            type: 'string.options.select',
                            className: 'custom-array-options',
                            displayName: '<<otherSelectSubsite>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<selectSubsite>>',
                                style: {
                                    width: 300,
                                },
                            },
                        },
                        channel: {
                            type: 'string.radioIllustrate',
                            displayName: '<<ddfw>>',
                            name: 'order-channel',
                            defaultValue: 'ONLINE',
                            options: [
                                { id: 'ONLINE', name: '<<xsdd>>' },
                                { id: 'OFFLINE', name: '<<xsdd>>' },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                illustrate: [],
                                style: { display: 'flex', paddingTop: 9 },
                            },
                        },
                        activityProductType: {
                            type: 'string.options.radio',
                            displayName: '<<activityProduct>>',
                            defaultValue: 'ALL',
                            options: [
                                { id: 'ALL', name: '<<allProduct>>' },
                                { id: 'INCLUDE_PRODUCT', name: '<<includeProduct>>' },
                                { id: 'EXCLUDE_PRODUCT', name: '<<excludeProduct>>' },
                            ],
                            rules: [{ required: true }],
                            extra: '<<jzdshtgdsyplffjl>>',
                        },
                        products: {
                            type: 'object.productCategory.selector',
                            displayName: '<<components.Tools.selectProduct>>',
                            controlConfig: {
                                selectProductTypes: ['NORMAL'],
                                subsiteSelectMode: 'ONLY_SUBSITE',
                                disableBand: true,
                            },
                        },
                        crowdScope: {
                            type: 'string.radioIllustrate',
                            displayName: '<<syrq>>',
                            name: 'order-ruleType-member-level',
                            defaultValue: 'CYCLE',
                            options: [
                                { id: 'CYCLE', name: '<<sydjtygz>>' },
                                { id: 'LADDER', name: '<<btdjbtgz>>' },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                illustrate: [],
                                style: { display: 'flex', paddingTop: 9 },
                            },
                        },
                        memberLevelRule: {
                            type: 'array.commentActivityReward',
                            displayName: ' ',
                            colon: false,
                            options: [],
                            source: {
                                apiPath: '/loader/admin/activityRewardItems',
                                paramsFilter: ['ORDER_COMMENT'],
                            },
                            controlConfig: {
                                rewardType: 'multi',
                                style: { display: 'flex' },
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        CommentActivityView: {
            component: 'Viewport',
            entity: 'CommentActivityEntity',
        },
        CommentActivityPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'CommentActivityFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/market/comment-activity/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'CommentActivityTable' },
            ],
        },
        CommentActivityFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
                style: {
                    marginLeft: 16,
                },
            },
            fields: [
                {
                    property: 'activityName',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<qsrhdmc>>',
                    },
                },
                {
                    property: 'subsiteId',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                // {
                //     property: 'merchantId',
                //     controlConfig: {
                //         style: { width: 300 },
                //     },
                // },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                // {
                //     property: 'dateRange',
                //     controlConfig: {
                //         style: { width: 300 },
                //     },
                // },
            ],
        },
        CommentActivityTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'activityProductType',
                },
                {
                    property: 'subsites',
                },
                // {
                //     property: 'merchants',
                // },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOTSTARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/comment_reward_activities/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isCloseActivity>>{{row.name}}?',
                                    },
                                },
                                {
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/comment_reward_activities/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/market/comment-activity/edit/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'NewImportButton',
                        statusKey: 'row.canImport',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
                            apiPath: '/admin/files/locations/800/upload',
                            text: '<<importProduct>>',
                            templateSource: {
                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
                                apiPath: '/admin/promotion/export.xlsx',
                            },
                            specialTips: ['<<rxtmddsphfgzqdrdsp>>'],
                        },
                    },
                ],
            },
        },
        CommentActivityAddPage: {
            component: 'FlexLayout',
            entity: 'CommentActivityEntity',
            direction: 'horizontal',
            items: [{ component: 'CommentActivityAddFormView' }],
        },
        CommentActivityEditPage: {
            component: 'FlexLayout',
            entity: 'CommentActivityEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'CommentActivityEditFormView' }],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/market/comment-activity',
                    component: 'CommentActivityView',
                    breadcrumbName: '<<commentActivity>>',
                    privilege: {
                        path: 'commentReward',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'CommentActivityAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'CommentActivityEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        { path: '/', component: 'CommentActivityPage' },
                    ],
                },
            ],
        },
    ],
};
