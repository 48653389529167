import { BaseConfig } from '../interfaces';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { passwrodReg } from '../services/utils';
export let config: BaseConfig = {
    entities: {
        tenantManage: {
            apiPath: '/loader/admin/tenants',
            apiRoot: `${ENV.AUTH_API_ROOT}/CAE-PRIVILEGE`,
            properties: {
                id: { type: 'string' },
                name: {
                    type: 'string',
                    displayName: '租户名称',
                    rules: [{ required: true, whitespace: true, message: '请输入租户名' }],
                    controlConfig: {
                        placeholder: '请输入租户名称',
                        maxLength: 32,
                    },
                },
                domainName: {
                    type: 'string',
                    displayName: '回调域名',
                    rules: [{ required: true, whitespace: true, message: '请输入租户回调域名' }],
                    controlConfig: {
                        placeholder: '请输入租户域名',
                        maxLength: 500,
                        style: { width: 500 },
                    },
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<accountStatus>>',
                    defaultValue: 'NORMAL',
                    options: [
                        { id: 'NORMAL', name: '启用' },
                        { id: 'ABNORMAL', name: '禁用' },
                    ],
                },
                createTime: {
                    type: 'string.date',
                    displayName: '<<createTimeStr>>',
                },
                createUserName: { type: 'string', displayName: '创建人' },
                remark: {
                    type: 'string.introduction',
                    displayName: '租户描述',
                    rules: [{ max: 500 }],
                    controlConfig: {
                        placeholder: '请输入租户描述',
                        maxLength: 500,
                    },
                },
                administratorName: {
                    type: 'string',
                    displayName: '管理员账号',
                    rules: [{ required: true, whitespace: true, message: '请输入管理员账号' }],
                    controlConfig: {
                        placeholder: '请输入管理员账号',
                        maxLength: 32,
                    },
                },
                administratorPassword: {
                    type: 'string.password',
                    displayName: '管理员密码',
                    rules: [
                        { required: true, message: '<<qsrdlmm>>' },
                        {
                            pattern: passwrodReg,
                            message: '请输入8~16位密码，数字、字母、字符至少包含两种',
                        },
                    ],
                    controlConfig: {
                        placeholder: '<<qsrdlmm>>',
                        maxLength: 16,
                    },
                },
                administratorEmail: {
                    type: 'string',
                    displayName: '管理员邮箱',
                    rules: [
                        { type: 'email', max: 100, message: '请输入正确格式的邮箱' },
                        { required: true, message: '请输入邮箱' },
                    ],
                    controlConfig: {
                        placeholder: '请输入邮箱',
                        maxLength: 100,
                    },
                },
            },
            filters: {
                name: {
                    type: 'string',
                    displayName: '租户名称',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '状态',
                    defaultValue: 'ALL',
                    options: [
                        {
                            id: 'ALL',
                            name: '全部',
                        },
                        {
                            id: 'NORMAL',
                            name: '启用',
                        },
                        {
                            id: 'ABNORMAL',
                            name: '禁用',
                        },
                    ],
                },
            },
        },
    },
    components: {
        TenantView: {
            component: 'Viewport',
            entity: 'tenantManage',
        },
        TenantManagePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'GridLayout',
                    items: [
                        {
                            component: 'Button',
                            text: '新增租户',
                            type: 'primary',
                            icon: 'plus',
                            route: '/tenant-manage/tenant-add',
                            style: { float: 'left' },
                        },
                        {
                            component: 'TenantManageFilter',
                            style: { float: 'right' },
                        },
                    ],
                },
                { component: 'TenantManageTable' },
            ],
        },
        TenantManageTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return `共 ${total} 条记录`;
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
                {
                    property: 'domainName',
                    width: 255,
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '255px',
                        },
                    },
                },
                {
                    property: 'status',
                    width: 100,
                    title: '状态',
                    displayConfig: {
                        statusConfig: {
                            NORMAL: {
                                text: '启用',
                                status: 'success',
                            },
                            ABNORMAL: {
                                text: '禁用',
                                status: 'error',
                            },
                        },
                    },
                },
                { property: 'createTime', sorter: true, width: 260 },
                { property: 'createUserName', width: 200 },
                {
                    property: 'remark',
                    width: 260,
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 200,
                style: { width: 200 },
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'status',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            apiRoot: `${ENV.AUTH_API_ROOT}/CAE-PRIVILEGE`,
                            handles: [
                                {
                                    params: { status: 'ABNORMAL' },
                                    value: 'NORMAL',
                                    apiPath: '/admin/tenants/:id/status',
                                    config: { content: '禁用', text: '禁用' },
                                    confirm: {
                                        text: '<<isDisabled>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: 'NORMAL' },
                                    value: 'ABNORMAL',
                                    apiPath: '/admin/tenants/:id/status',
                                    config: { content: '启用', text: '启用' },
                                    confirm: {
                                        text: '<<isEnable>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'component',
                        component: 'Dropdown',
                        config: {
                            style: { display: 'inline-flex' },
                            content: {
                                contentType: 'link',
                                text: '更多',
                            },
                            menuItems: [
                                {
                                    component: 'Button',
                                    configs: {
                                        text: '编辑',
                                        type: 'link',
                                        route: '/tenant-manage/tenant-edit/{{row.id}}',
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },

        TenantManageFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
                style: {
                    marginLeft: 16,
                },
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: { placeholder: '请输入租户名称' },
                },
                {
                    property: 'status',
                    controlConfig: {
                        style: { width: 180 },
                    },
                },
            ],
        },
        TenantEditPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'TenantEditForm' }],
        },
        TenantEditForm: {
            component: 'ModifyForm',
            direction: 'horizontal',
            loaderType: 'get',
            entity: 'tenantManage',
            labelCol: 6,
            controlCol: 10,
            style: { width: 720 },
            fields: [
                { property: 'name', controlConfig: { disabled: true } },
                { property: 'domainName' },
                { property: 'remark' },
            ],
            submit: { text: '<<common.save>>' },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        TenantAddPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'TenantAddForm' }],
        },
        TenantAddForm: {
            component: 'CreateForm',
            direction: 'horizontal',
            entity: 'tenantManage',
            labelCol: 6,
            controlCol: 10,
            style: { width: 720 },
            fields: [
                { property: 'name' },
                { property: 'domainName' },
                { property: 'administratorName' },
                { property: 'administratorPassword' },
                { property: 'administratorEmail' },
                { property: 'status' },
                { property: 'remark' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                message.success('添加租户成功', () => services.behaviorHandle({ route: 'goBack' }));
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/tenant-manage',
                    breadcrumbName: '租户管理',
                    category: '系统设置',
                    component: 'TenantView',
                    privilege: {
                        path: 'tenant',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/tenant-add',
                            component: 'TenantAddPage',
                            breadcrumbName: '新增租户',
                        },
                        {
                            path: '/tenant-edit/:id',
                            component: 'TenantEditPage',
                            breadcrumbName: '编辑租户',
                        },
                        { path: '/', component: 'TenantManagePage' },
                    ],
                },
            ],
        },
    ],
};
