import React, { PureComponent } from 'react';
import { Button, Input } from 'antd';

import './index.less';
import { cloneDeep } from 'lodash';
import { services } from '@comall-backend-builder/core';

interface ProductSelectedDisplayFilterProps {
    hidden?: boolean;
    onFilter: (params: ProductSelectedDisplayFilterStates) => void;
}

export interface ProductSelectedDisplayFilterStates {
    productName?: string;
    productCode?: string;
    merchantName?: string;
}

/**
 * 选择商品展示筛选器
 */
export class ProductSelectedDisplayFilter extends PureComponent<
    ProductSelectedDisplayFilterProps,
    ProductSelectedDisplayFilterStates
> {
    state: ProductSelectedDisplayFilterStates = {};

    onChangeField = (field: keyof ProductSelectedDisplayFilterStates, value?: string) => {
        const newState = cloneDeep(this.state);
        newState[field] = value;
        this.setState(newState);
    };

    renderFilterItem = (title: string, onChange: (value: string) => void, value?: string) => {
        return (
            <div className="presale-product-selected-filter__item">
                <span>{title}:&nbsp;&nbsp;</span>
                <Input
                    placeholder={services.language.getText('inputPlease')}
                    value={value}
                    style={{ width: '100px' }}
                    onChange={(e) => onChange(e.target.value)}
                ></Input>
            </div>
        );
    };

    getOnChange = (field: keyof ProductSelectedDisplayFilterStates) => {
        return (value: string) => this.onChangeField(field, value);
    };

    onClear = () => {
        this.setState({ productName: undefined, productCode: undefined, merchantName: undefined });
    };

    onSubmit = () => {
        const { onFilter } = this.props;
        onFilter(this.state);
    };

    render() {
        const { hidden } = this.props;
        if (hidden) {
            return null;
        }
        const { productName, productCode, merchantName } = this.state;
        return (
            <div className="presale-product-selected-filter">
                {this.renderFilterItem(
                    services.language.getText('spmc'),
                    this.getOnChange('productName'),
                    productName
                )}
                {this.renderFilterItem(
                    services.language.getText('productCode'),
                    this.getOnChange('productCode'),
                    productCode
                )}
                {this.renderFilterItem(
                    services.language.getText('merchantName'),
                    this.getOnChange('merchantName'),
                    merchantName
                )}
                <div className="presale-product-selected-filter__buttons">
                    <Button onClick={this.onSubmit} type="primary">
                        {services.language.getText('filter')}
                    </Button>
                    <Button onClick={this.onClear} type="default">
                        {services.language.getText('common.reset')}
                    </Button>
                </div>
            </div>
        );
    }
}
