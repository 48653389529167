import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;

export const AuthorizeMemberManagementLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/member/authorize_merchants';
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start;
            data.endTime = data.dateRange.end;
            delete data.dateRange;
        }
        return await api.get(data, config).then((res) => {
            return res;
        });
    },
};
