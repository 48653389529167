import React, { PureComponent } from 'react';
import { ProductBrandsSelectDisplay } from './product-brands-select-display';
import { ProductBrandsSelector } from './product-brands-selector';

export interface Brand {
    id: string;
    name: string;
    /**
     * 被选中的品牌
     */
    select?: boolean;
    /**
     * 将要被删除的品牌
     */
    delete?: boolean;
}

export class SelectProductBrands extends PureComponent<
    any,
    {
        value: Array<Brand>;
    }
> {
    state = { value: new Array<Brand>() };

    /**
     * 选择品牌
     * @param selectValues
     */
    onChange = (selectValues: Brand[]) => {
        const { onChange } = this.props;
        if (onChange) {
            if (selectValues && selectValues.length === 0) {
                onChange(undefined);
            } else {
                onChange(selectValues);
            }
        }
    };

    /**
     * 确认后回调
     */
    onSelect = (selectValues: Brand[]) => {
        this.onChange(selectValues);
    };

    render() {
        const { value, disabled, type } = this.props;
        return (
            <div>
                {!disabled && (
                    <ProductBrandsSelector
                        onChange={this.onSelect}
                        selectValues={value}
                        type={type}
                    />
                )}
                <ProductBrandsSelectDisplay
                    data={value}
                    onRemove={this.onChange}
                    disabled={disabled}
                />
            </div>
        );
    }
}
