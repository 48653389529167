import React, { Component } from 'react';
import { Button } from 'antd';
import { newClientCustomSelectorConfig } from '../../applications/design/design';
import { getThirdLinkTypeEffectPathConfigList } from '../link-type-effect-path/config';
import { LinkTypePlus } from '../../applications/design/components';
import { services } from '@comall-backend-builder/core';

type ThirdLinkValue = { linkType: string; linkParams: any } | null;

export interface ThirdLinkProps {
    name: string;
    value: string | null;
    onChange: (value: string | null, name: string) => void;
}

const DEFAULT_VALUE = {
    linkType: '',
    linkParams: null,
};

export class ThirdLink extends Component<ThirdLinkProps> {
    changeValue = (value: ThirdLinkValue) => {
        let { name, onChange } = this.props;
        if (onChange) {
            onChange(value ? JSON.stringify(value) : null, name);
        }
    };

    private get valueStatus() {
        const { value } = this.props;
        return Boolean(value);
    }

    onStatusButtonClilk = () => {
        if (this.valueStatus) {
            this.changeValue(null);
        } else {
            this.changeValue(DEFAULT_VALUE);
        }
    };

    renderStatusButton = () => {
        return (
            <div>
                <Button type="primary" onClick={this.onStatusButtonClilk}>
                    {this.valueStatus
                        ? services.language.getText('clearSetting')
                        : services.language.getText('settings')}
                </Button>
            </div>
        );
    };

    thirdLinkTypeEffectPathConfigList = getThirdLinkTypeEffectPathConfigList();

    render() {
        const { value } = this.props;
        const linkValue = value ? JSON.parse(value) : DEFAULT_VALUE;
        return (
            <div className="carpark-linkType">
                {this.renderStatusButton()}
                {this.valueStatus && (
                    <LinkTypePlus
                        selector={newClientCustomSelectorConfig as any}
                        linkTypes={this.thirdLinkTypeEffectPathConfigList}
                        linkTypeMode="cascader"
                        value={linkValue}
                        onChange={this.changeValue}
                    />
                )}
            </div>
        );
    }
}
