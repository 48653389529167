import React, { PureComponent } from 'react';
import { Icon, Row, Col } from 'antd';
import { cloneDeep, get } from 'lodash';
import { tools } from '@comall-backend-builder/design-components-basis';
const LinkType = tools.LinkType;
const EffectiveTime = tools.EffectiveTime;
const UploadPicture = tools.UploadPicture;
type LinkTypeMode = 'select' | 'cascader';
//图片广告位项
export class ImageAdItem extends PureComponent<{
    sourceRoot?: {
        image?: string;
    };
    items?: Array<any>;
    index?: number;
    onChange: (value: any) => void;
    item?: any;
    uploadAction?: string;
    linkTypes?: Array<any>;
    linkTypeMode?: LinkTypeMode;
    effectiveTime?: { start: string; end: string };
    selector: any;
}> {
    state = {
        uploadLoading: false,
    };
    change = (item: any) => {
        const { items, index, onChange } = this.props;
        let newItems: any = cloneDeep(items);
        newItems.splice(index, 1, item);
        onChange(newItems);
    };

    changeEffectiveTime = (time: any) => {
        const { item } = this.props;
        this.change({ ...item, ...time });
    };

    onUpload = (path: any) => {
        const { item } = this.props;
        let newItem = cloneDeep(item);
        newItem.pic = path;
        this.change(newItem);
    };

    removeItem = () => {
        const { items, onChange, index } = this.props;
        let newValue: any = cloneDeep(items);
        newValue.splice(index, 1);
        onChange(newValue);
    };

    render() {
        const { item, linkTypes, linkTypeMode, uploadAction, selector, sourceRoot } = this
            .props as any;
        return (
            <Row className="entry-item">
                <Icon type="close" className="item-remove" title="移除" onClick={this.removeItem} />
                <Col className="entry-item-image" span={4}>
                    <UploadPicture
                        sourceRoot={sourceRoot}
                        value={item.pic}
                        uploadAction={uploadAction}
                        onChange={this.onUpload}
                    />
                </Col>
                <Col className="entry-item-content" span={19}>
                    <LinkType
                        selector={selector}
                        linkTypes={linkTypes}
                        linkTypeMode={linkTypeMode}
                        value={item}
                        onChange={this.change}
                    />
                    <EffectiveTime
                        value={{ effectiveTime: get(item, 'effectiveTime', null) }}
                        onChange={this.changeEffectiveTime}
                    />
                </Col>
            </Row>
        );
    }
}
