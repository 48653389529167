import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import { CheckboxGroup } from '@comall-backend-builder/components-basis';
import { CarparkCheckoutType } from '../../../../components';
import { concat, get } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

export class CarparkPageContentMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <CarparkPageContent {...displayInfo} />;
    }
}

export enum ContentType {
    QueryPayment = 'PAGE_CONTENT_QUERY_PAYMENT',
    AddVehicle = 'PAGE_CONTENT_ADD_VEHICLE',
    PointsDisplay = 'PAGE_CONTENT_POINTS_DISPLAY',
    PageContentParkingSpaceFreeNum = 'PAGE_CONTENT_PARKING_SPACE_FREE_NUM',
}

const getOptions = (checkoutType?: CarparkCheckoutType) => {
    return [
        {
            id: ContentType.QueryPayment,
            name: language.getText('cxjf'),
            disabled: checkoutType !== CarparkCheckoutType.TRIPARTITE,
        },
        {
            id: ContentType.AddVehicle,
            name: language.getText('tjcl'),
        },
        {
            id: ContentType.PointsDisplay,
            name: language.getText('jfzs'),
        },
        {
            id: ContentType.PageContentParkingSpaceFreeNum,
            name: language.getText('sycws'),
        },
    ];
};
const defaultValue = [ContentType.QueryPayment];

class CarparkPageContent extends React.Component<any, { options: any[] }> {
    constructor(props: any) {
        super(props);
        const { value, onChange } = this.props;
        this.state = {
            options: getOptions(this.getCarparkCheckoutType(this.props)),
        };
        if (!value?.length) {
            onChange(defaultValue);
        }
    }
    componentWillReceiveProps(nextProps: any) {
        if (this.getCarparkCheckoutType(nextProps) !== this.getCarparkCheckoutType(this.props)) {
            this.update(nextProps);
        }
    }
    getCarparkCheckoutType = (props: any): CarparkCheckoutType => {
        return get(props, 'row.carparkConfig.checkoutType.type', CarparkCheckoutType.INTERNAL);
    };
    update = (props: any) => {
        const { value = [], onChange } = props;
        const carparkCheckoutType = this.getCarparkCheckoutType(props);
        this.setState({ options: getOptions(carparkCheckoutType) });
        if (
            carparkCheckoutType === CarparkCheckoutType.INTERNAL &&
            !value.includes(ContentType.QueryPayment)
        ) {
            onChange(concat(defaultValue, value));
        }
    };
    render() {
        const { options } = this.state;
        if (!options.length) {
            return null;
        }
        const displayInfo: any = this.props || {};
        return <CheckboxGroup {...displayInfo} options={options} />;
    }
}
