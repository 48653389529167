import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { mapValues, defaults, get } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import './index.less';

export interface WfjLotteryCodeCopyFormProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
    pointRule: string;
}

class wfjlotteryCodeCopyFormView extends PureComponent<WfjLotteryCodeCopyFormProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }
    render() {
        const { entity, onSubmit, wrappedComponentRef } = this.props;

        let { pointRule } = this.props;

        if (!pointRule) {
            pointRule = 'NO';
        }

        let fields = [
            { property: 'baseInfo.name' },
            {
                property: 'baseInfo.dateRange',
                controlConfig: {
                    style: {
                        width: 450,
                    },
                },
                rules: [
                    { required: true, message: services.language.getText('acitivityTimeRequired') },
                    {
                        async validator(_: any, value: any) {
                            if (!value) {
                                return;
                            }
                            const currentEndDate = value.end;
                            if (new Date(currentEndDate).getTime() < new Date().getTime()) {
                                throw new Error(services.language.getText('jssjbnzydqsj'));
                            }
                        },
                    },
                ],
            },
            {
                property: 'baseInfo.drawTime',
            },
            {
                property: 'baseInfo.subsiteIds',
                controlConfig: {
                    style: {
                        width: 450,
                    },
                },
            },
            { property: 'baseInfo.shareContent' },
            { property: 'baseInfo.shareAssistContent' },
            { property: 'baseInfo.description' },
            { property: 'baseInfo.remark' },
            {
                property: 'numberInfo.activityRewardLimit',
                controlConfig: {
                    addonafter: '张',
                    style: {
                        width: '120px',
                    },
                },
            },
            {
                property: 'numberInfo.shareRewardLimit',
                controlConfig: {
                    addonafter: '张',
                    style: {
                        width: '120px',
                    },
                },
            },
            {
                property: 'numberInfo.shareAssistLimit',
                controlConfig: {
                    max: 999999,
                    // disabled: isDisabled,
                    addonafter: '张',
                    style: {
                        width: '120px',
                    },
                },
            },
            {
                property: 'numberInfo.assistLimit',
                controlConfig: {
                    max: 999999,
                    addonafter: '张',
                    style: {
                        width: '120px',
                    },
                },
            },
            {
                property: 'numberInfo.pointRule',
            },
            {
                property: 'numberInfo.point',
                controlConfig: {
                    style: { width: '120px' },
                    placeholder: '0',
                    addonafter: '积分',
                    tip: '',
                },
                className: pointRule === 'YES' ? '' : 'property-hide',
            },
            {
                property: 'numberInfo.pointExchangeLimit',
                controlConfig: {
                    style: { width: '120px' },
                    placeholder: '0',
                    addonafter: services.language.getText('frequency'),
                    tip: '',
                },
                className: pointRule === 'YES' ? '' : 'property-hide',
            },
            { property: 'prizeInfo.name' },
            {
                property: 'prizeInfo.type',
                controlConfig: {
                    reqConfig: {
                        apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                        apiPath: '/admin/activities/reward_items',
                    },
                    reqParams: { eventType: 'LOTTERY_CODE' },
                    baseConfig: {
                        isConditionsInput: false,
                        isItmesRadio: true,
                        isLadder: false,
                    },
                },
            },
            { property: 'prizeInfo.picture' },
            {
                property: 'prizeInfo.quantity',
                controlConfig: {
                    style: {
                        width: '120px',
                    },
                },
            },
            { property: 'otherInfo.marketingSchedule' },
        ];

        let formProps = {
            entity,
            componentId: 'WfjLotteryCodeCopyFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'wfj-lottery-code-copy-form-container',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            fields: fields,
            submit: { text: '保存' },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;
    let form: any;
    let editExecuteStatus = get(
        _dispatch,
        'components.DataFormPlus-WfjLotteryCodeCopyFormContainer.fields.editExecuteStatus'
    );

    let pointRule = get(
        _dispatch,
        'components.DataFormPlus-WfjLotteryCodeCopyFormContainer.fields.numberInfo.pointRule'
    );

    /**
     * 提交前额外验证
     * @param entityFields
     */
    const validate = (entityFields: any) => {
        if (pointRule === 'YES') {
            const point = get(entityFields, 'numberInfo.point', null);
            const pointExchangeLimit = get(entityFields, 'numberInfo.pointExchangeLimit', null);
            const reg = /^([1-9]\d*)$/;
            if (!point || point === '0' || !reg.test(point)) {
                AntMessage.warning('请正确填写每次抽奖消耗积分');
                return false;
            }
            if (
                !pointExchangeLimit ||
                pointExchangeLimit === '0' ||
                !reg.test(pointExchangeLimit)
            ) {
                AntMessage.warning('请正确填写最多兑换次数');
                return false;
            }
        }
        if (
            get(entityFields, 'prizeInfo.type[0].checked') === 'COUPON' &&
            !get(entityFields, 'prizeInfo.type[0].assetRewards[0].rewardValue.length')
        ) {
            AntMessage.warning('请选择优惠券奖品');
            return false;
        }
        return true;
    };

    return defaults(
        {
            onSubmit,
            onFieldChange,
            editExecuteStatus,
            pointRule,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: (event: any, fields: any) => {
                const entityFields = mapValues(fields, (field) => {
                    return field.value;
                });
                if (entityFields) {
                    const hasValidate = validate(entityFields);
                    if (hasValidate) {
                        entity.add(entityFields, params);
                    }
                }
            },
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('WfjLotteryCodeCopyFormContainer'));
            },
        }
    );
}
export const WfjLotteryCodeCopyFormView = connect(mapDispatchToProps)(wfjlotteryCodeCopyFormView);
