import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import { CommonSettingOption } from './common-setting-option';

export class CommonSettingOptionMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <CommonSettingOption {...controlInfo} />;
    }
}
