import * as React from 'react';
import classNames from 'classnames';
import { map } from 'lodash';

import { ArrayMode } from '@comall-backend-builder/types';
import { CreditEshopAssignDeliveryModes } from '../../../components';

export class CreditEshopAssignDeliveryModesMode extends ArrayMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(array: any, displayInfo: any) {
        const { className, style } = displayInfo;
        if (array && array.length > 0) {
            return (
                <div className={className} style={style}>
                    {map(array, (item, index) => {
                        return (
                            <div key={index}>
                                <span>{item.name}</span>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return this.getNotAvailableDisplayComponent(displayInfo);
        }
    }

    /**
     * 获取无数据状态展示组件
     */
    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            '暂无数据'
        );
    }
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <CreditEshopAssignDeliveryModes {...controlInfo} />;
    }
}
