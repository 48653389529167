import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import { message as AntMessage, Button as AntButton } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services';

import get from 'lodash/get';

/**
 *  字符串描述
 */
export class StringCopyMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        const style = get(displayInfo, 'style');
        const onCopySuccess = get(displayInfo, 'onCopySuccess');
        const copy = () => {
            let input = document.createElement('input');
            input.value = value;
            document.body.appendChild(input);
            input.select();
            if (document.execCommand('Copy')) {
                AntMessage.success(language.getText('copySuccess'));
                if (onCopySuccess && typeof onCopySuccess === 'function') {
                    onCopySuccess();
                }
            } else {
                AntMessage.success(language.getText('copyError'));
            }
            document.body.removeChild(input);
        };
        if (value) {
            return (
                <div>
                    <span style={style}>{value}</span>
                    <AntButton onClick={copy} type="link">
                        复制
                    </AntButton>
                </div>
            );
        }
        return <div></div>;
    }
}
