import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
export const MerchantUserLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiPath = '/admin/users/mine';
        const { id } = data;
        if (!id) {
            if (data.status === 'ALL') {
                delete data.status;
            }
            return await api.get(data, config);
        } else {
            config.apiPath = `/admin/users/${id}`;
            return await api.get({}, config);
        }
    },

    post: async function(params, config: ApiRequestConfig) {
        config.apiPath = '/admin/users';
        params.staffCode = params.staffCode && params.staffCode.trim();
        if (params.staffCode === '') {
            delete params.staffCode;
        }
        return await api.post(params, config);
    },

    put: async function(params, config: ApiRequestConfig) {
        if (params.id) {
            config.apiPath = `/admin/users/${params.id}`;
        }
        params.staffCode = params.staffCode && params.staffCode.trim();
        if (params.staffCode === '') {
            delete params.staffCode;
        }
        return await api.put(params, config);
    },
};
