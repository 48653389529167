import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

/**
 * 退款状态
 */
export enum RefundOrderStatus {
    /**
     * 未处理
     */
    APPLYING = 'APPLYING',
    /**
     * 提交成功
     */
    SUBMIT_SUCC = 'SUBMIT_SUCC',
    /**
     * 提交失败
     */
    SUBMIT_FAIL = 'SUBMIT_FAIL',
    /**
     * 退款成功
     */
    REFUND_SUCC = 'REFUND_SUCC',
    /**
     * 退款失败
     */
    REFUND_FAIL = 'REFUND_FAIL',
}

/**
 * 退款方式
 */
export enum RefundOrderMode {
    /**
     * 原路返回
     */
    REVERT = 'REVERT',
    /**
     * 转账
     */
    TRANSFERS = 'TRANSFERS',
    /**
     * 系统内退回
     */
    SYSTEM = 'SYSTEM',
    /**
     * 门店退回
     */
    STORES = 'STORES',
}

export const REFUND_ORDER_STATUS_TO_TEXT = {
    [RefundOrderStatus.APPLYING]: '<<wcl>>',
    [RefundOrderStatus.SUBMIT_SUCC]: '<<tjcg_1>>',
    [RefundOrderStatus.SUBMIT_FAIL]: '<<tjsb_1>>',
    [RefundOrderStatus.REFUND_SUCC]: '<<tkcg>>',
    [RefundOrderStatus.REFUND_FAIL]: '<<tksb>>',
} as const;

export const REFUND_ORDER_MODE_TO_TEXT = {
    [RefundOrderMode.REVERT]: '原路返回',
    [RefundOrderMode.TRANSFERS]: '转账',
    [RefundOrderMode.SYSTEM]: '<<xtnth>>',
    [RefundOrderMode.STORES]: '<<mdth>>',
} as const;

export const REFUND_ORDER_STATUS_OPTIONS = [
    {
        name: '<<all>>',
        id: 'ALL',
    },
    {
        name: REFUND_ORDER_STATUS_TO_TEXT[RefundOrderStatus.APPLYING],
        id: RefundOrderStatus.APPLYING,
    },
    {
        name: REFUND_ORDER_STATUS_TO_TEXT[RefundOrderStatus.SUBMIT_SUCC],
        id: RefundOrderStatus.SUBMIT_SUCC,
    },
    {
        name: REFUND_ORDER_STATUS_TO_TEXT[RefundOrderStatus.SUBMIT_FAIL],
        id: RefundOrderStatus.SUBMIT_FAIL,
    },
    {
        name: REFUND_ORDER_STATUS_TO_TEXT[RefundOrderStatus.REFUND_SUCC],
        id: RefundOrderStatus.REFUND_SUCC,
    },
    {
        name: REFUND_ORDER_STATUS_TO_TEXT[RefundOrderStatus.REFUND_FAIL],
        id: RefundOrderStatus.REFUND_FAIL,
    },
];

export const REFUND_ORDER_MODE_OPTIONS = [
    {
        name: '<<all>>',
        id: 'ALL',
    },
    {
        name: REFUND_ORDER_MODE_TO_TEXT[RefundOrderMode.REVERT],
        id: RefundOrderMode.REVERT,
    },
    {
        name: REFUND_ORDER_MODE_TO_TEXT[RefundOrderMode.TRANSFERS],
        id: RefundOrderMode.TRANSFERS,
    },
    {
        name: REFUND_ORDER_MODE_TO_TEXT[RefundOrderMode.SYSTEM],
        id: RefundOrderMode.SYSTEM,
    },
    {
        name: REFUND_ORDER_MODE_TO_TEXT[RefundOrderMode.STORES],
        id: RefundOrderMode.STORES,
    },
];

export const REFUND_ORDER_STATUS_BADGE_CONFIG = {
    [RefundOrderStatus.APPLYING]: {
        text: REFUND_ORDER_STATUS_TO_TEXT[RefundOrderStatus.APPLYING],
        status: 'processing',
    },
    [RefundOrderStatus.SUBMIT_SUCC]: {
        text: REFUND_ORDER_STATUS_TO_TEXT[RefundOrderStatus.SUBMIT_SUCC],
        status: 'success',
    },
    [RefundOrderStatus.SUBMIT_FAIL]: {
        text: REFUND_ORDER_STATUS_TO_TEXT[RefundOrderStatus.SUBMIT_FAIL],
        status: 'error',
    },
    [RefundOrderStatus.REFUND_SUCC]: {
        text: REFUND_ORDER_STATUS_TO_TEXT[RefundOrderStatus.REFUND_SUCC],
        status: 'success',
    },
    [RefundOrderStatus.REFUND_FAIL]: {
        text: REFUND_ORDER_STATUS_TO_TEXT[RefundOrderStatus.REFUND_FAIL],
        status: 'error',
    },
};

export const config: Config = {
    entities: {
        MallActivityRefundOrderEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/mallActivityRefundOrder',
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                    },
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<ddh_1>>',
                    controlConfig: {
                        placeholder: '<<inputPlease>>',
                    },
                },
                interfaceStatus: {
                    type: 'string.options.select',
                    displayName: '退款状态',
                    options: REFUND_ORDER_STATUS_OPTIONS,
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                    },
                },
            },
            properties: {
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                interfaceStatus: {
                    type: 'string.statusBadge',
                    displayName: '退款状态',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<createTimeStr>>',
                },
                refundMoney: {
                    type: 'string',
                    displayName: '<<tkxjje>>',
                },
                refundPoint: {
                    type: 'string',
                    displayName: '<<tkjf>>',
                },
                refundItemMode: {
                    type: 'string.options.select',
                    displayName: '<<tkfs>>',
                    options: REFUND_ORDER_MODE_OPTIONS,
                },
                refundCreateReason: {
                    type: 'string',
                    displayName: '<<scyy>>',
                },
            },
        },
    },
    components: {
        MallActivityRefundOrderView: {
            component: 'Viewport',
        },
        MallActivityRefundOrderPage: {
            component: 'FlexLayout',
            entity: 'MallActivityRefundOrderEntity',
            direction: 'vertical',
            items: [
                {
                    component: 'MallActivityRefundOrderFilter',
                },
                {
                    component: 'MallActivityRefundOrderTable',
                },
            ],
        },
        MallActivityRefundOrderFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 20,
            },
            fields: [
                {
                    property: 'subsiteId',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'orderNo',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'interfaceStatus',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
            ],
        },
        MallActivityRefundOrderTable: {
            component: 'FlexLayout',
            direction: 'vertical',

            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: {
                        x: true,
                    },
                    columns: [
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'orderNo',
                        },
                        {
                            property: 'interfaceStatus',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '100px',
                                },
                                statusConfig: REFUND_ORDER_STATUS_BADGE_CONFIG,
                            },
                        },
                        {
                            property: 'createTime',
                        },
                        {
                            property: 'refundMoney',
                        },
                        {
                            property: 'refundPoint',
                        },
                        {
                            property: 'refundItemMode',
                        },
                        {
                            property: 'refundCreateReason',
                            displayConfig: {
                                className: 'ant-table-cell-multi-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                    '-webkit-line-clamp': '3',
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        width: 100,
                        fixed: 'right',
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    statusKey: 'canRefund',
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                            apiPath: '/admin/mall_activity_refunds/:id',
                                            config: {
                                                content: '再次退款',
                                                text: '再次退款',
                                            },
                                            confirm: {
                                                text: '确定要重新发起退款?',
                                            },
                                        },
                                        {
                                            value: false,
                                            config: {
                                                content: '再次退款',
                                                text: '再次退款',
                                                disabled: true,
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/mall-activity-refund-order',
                    component: 'MallActivityRefundOrderView',
                    breadcrumbName: '退款单',
                    privilege: {
                        path: 'mallActivityRefund',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/',
                            component: 'MallActivityRefundOrderPage',
                        },
                    ],
                },
            ],
        },
    ],
};
