import React, { Component, createElement } from 'react';
import { Checkbox, Button, message, Tabs, Rate } from 'antd';
import { services } from '@comall-backend-builder/core';
import { ComponentsManager } from '@comall-backend-builder/core';
import { language, navigation } from '@comall-backend-builder/core/lib/services';
import { map } from 'lodash';
import { ImagePreviewPlus } from '../../../components';

import './index.less';

const CheckboxGroup = Checkbox.Group;
const { api } = services;
interface OrderCommentsContainerState {
    activeKey: string;
    checkedList: number[];
    indeterminate: boolean;
    checkAll: boolean;
    statistics: {
        noAuditCount: number;
        noPassCount: number;
        passCount: number;
    };
}

enum TabKeys {
    WAITING = '0',
    REJECTED = '1',
    APPROVED = '2',
}
interface OrderComment {
    id: number;
    subsiteName: string;
    merchantName: string;
    memberPhone: string;
    createTime: string;
    productUrl: string;
    productName: string;
    picUrl: string[];
    starts: number;
    content: string;
    orderNum: string;
    status: TabKeys;
}

/**
 * 会员评论
 */
export class OrderCommentsContainer extends Component<any, OrderCommentsContainerState> {
    constructor(props: any) {
        super(props);
        const activeKey = this.getTabKey();
        this.state = {
            activeKey: activeKey ? activeKey : TabKeys.WAITING!,
            checkedList: [],
            indeterminate: false,
            checkAll: false,
            statistics: {
                noAuditCount: 0,
                noPassCount: 0,
                passCount: 0,
            },
        };
    }
    componentDidMount(): void {
        this.loadStatistics();
        this.loadData();
    }

    getTabKey = () => {
        const history = navigation.getHistory();
        const {
            location: { hash },
        } = history;
        if (!!hash) {
            return hash.slice(1);
        } else {
            return null;
        }
    };

    loadData = () => {
        const { entity } = this.props;
        if (!entity.filters.status) {
            entity.filtersChange({
                status: '0',
            });
        }
        entity.search();
    };
    loadStatistics = () => {
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/reviewAudit/statistics',
        };
        api.get({}, config).then((res: any) => {
            this.setState({ statistics: res });
        });
    };

    changeActiveTab = (activeKey: TabKeys) => {
        this.initEntityFilters(activeKey);
        this.setState(
            { activeKey, checkedList: [], indeterminate: false, checkAll: false },
            this.loadData
        );
    };
    initEntityFilters = (activeKey: string) => {
        const { entity } = this.props;
        entity.filtersChange({
            status: activeKey,
            subsiteId: undefined,
            merchantId: undefined,
            mobile: undefined,
            productName: undefined,
            orderNo: undefined,
        });
        entity.pageChange({ page: 1 });
    };

    /**
     * 全选/取消勾选
     */
    onCheckAllChange = (e: any) => {
        const { entity } = this.props;
        const checkedList = entity.result.map((comment: OrderComment) => {
            return comment.id;
        });
        this.setState({
            checkedList: e.target.checked ? checkedList : [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
    };
    /**
     * 勾选/取消
     * @param e
     */
    onCheckboxChange = (checkedList: any) => {
        const { entity } = this.props;
        this.setState({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < entity.result.length,
            checkAll: checkedList.length === entity.result.length,
        });
    };

    /**
     * 页数改变清空选中评论
     */
    changeCommentsPage = () => {
        this.setState({
            checkedList: [],
        });
    };

    reviewComment = (id: number, type: 'pass' | 'reject') => {
        const { activeKey } = this.state;
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath:
                type === 'pass'
                    ? `/admin/reviewAudit/${id}/pass`
                    : `/admin/reviewAudit/${id}/notPass`,
        };
        api.put({}, config).then(() => {
            message.success(
                type === 'pass'
                    ? services.language.getText('approved')
                    : services.language.getText('shbtg')
            );
            this.setState(
                {
                    checkedList: [],
                    indeterminate: false,
                    checkAll: false,
                },
                () => {
                    this.initEntityFilters(activeKey);
                    this.loadStatistics();
                    this.loadData();
                }
            );
        });
    };

    batchReviewComment = (type: 'pass' | 'reject') => {
        const { checkedList, activeKey } = this.state;
        if (!checkedList || !checkedList.length) {
            message.info(services.language.getText('qxzplczdpl'));
            return;
        }
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath:
                type === 'pass'
                    ? `/admin/reviewAudit/batchPass`
                    : `/admin/reviewAudit/batchNotPass`,
        };
        api.put({ ids: checkedList }, config).then(() => {
            message.success(
                type === 'pass'
                    ? services.language.getText('plshtg')
                    : services.language.getText('plshbtg')
            );
            this.setState(
                {
                    checkedList: [],
                    indeterminate: false,
                    checkAll: false,
                },
                () => {
                    this.initEntityFilters(activeKey);
                    this.loadStatistics();
                    this.loadData();
                }
            );
        });
    };

    getFilterConfig = () => {
        const { entity } = this.props;
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        this.setState(
                            {
                                checkedList: [],
                                indeterminate: false,
                                checkAll: false,
                            },
                            () => {
                                this.loadData();
                            }
                        );
                    },
                    onReset: () => {
                        const { activeKey } = this.state;
                        this.initEntityFilters(activeKey);
                    },
                    fields: [
                        {
                            property: 'subsiteId',
                        },
                        {
                            property: 'merchantId',
                        },
                        {
                            property: 'mobile',
                        },
                        {
                            property: 'productName',
                        },
                        {
                            property: 'orderNo',
                        },
                    ],
                },
            ],
        };
    };

    render() {
        const { activeKey, statistics } = this.state;

        return (
            <div className="order-comments">
                <Tabs activeKey={activeKey} onTabClick={this.changeActiveTab} animated={false}>
                    <Tabs.TabPane
                        tab={`${language.getText('wsh')}(${statistics.noAuditCount})`}
                        key={TabKeys.WAITING}
                    >
                        {this.renderComments()}
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={`${language.getText('approved')}(${statistics.passCount})`}
                        key={TabKeys.APPROVED}
                    >
                        {this.renderComments()}
                    </Tabs.TabPane>
                    <Tabs.TabPane
                        tab={services.language.getText('shbtg') + `(${statistics.noPassCount})`}
                        key={TabKeys.REJECTED}
                    >
                        {this.renderComments()}
                    </Tabs.TabPane>
                </Tabs>
            </div>
        );
    }

    renderComments = () => {
        const { entity } = this.props;
        const { activeKey } = this.state;

        const config = this.getFilterConfig();
        const filterContent = createElement(ComponentsManager.get(config.component), config);
        const dataListProps = {
            entity: entity,
            component: 'DataList',
            renderItem: this.renderItem,
            loadFirstPage: false,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: any) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            onPageChange: this.changeCommentsPage,
        };
        const dataListContent = createElement(
            ComponentsManager.get(dataListProps.component),
            dataListProps
        );
        return (
            <div>
                {filterContent}
                <div className="comment-actions">
                    <Checkbox
                        className="all-delivery-checkbox"
                        indeterminate={this.state.indeterminate}
                        onChange={this.onCheckAllChange}
                        checked={this.state.checkAll}
                    >
                        {language.getText('qx_1')}
                    </Checkbox>
                    {(activeKey === TabKeys.WAITING || activeKey === TabKeys.REJECTED) && (
                        <Button
                            className="batch-delivery-button"
                            type="primary"
                            icon="plus"
                            onClick={() => {
                                this.batchReviewComment('pass');
                            }}
                        >
                            {language.getText('plshtg')}
                        </Button>
                    )}
                    {(activeKey === TabKeys.WAITING || activeKey === TabKeys.APPROVED) && (
                        <Button
                            className="batch-delivery-button"
                            type="primary"
                            icon="plus"
                            onClick={() => {
                                this.batchReviewComment('reject');
                            }}
                        >
                            {language.getText('plshbtg')}
                        </Button>
                    )}
                </div>
                <div className="comment-list">
                    <CheckboxGroup value={this.state.checkedList} onChange={this.onCheckboxChange}>
                        {dataListContent}
                    </CheckboxGroup>
                </div>
            </div>
        );
    };

    renderItem = (item: OrderComment) => {
        const { preview } = this.props;
        // 原数据集array需要格式化，在preview中显示
        let formatArray: any = [];
        map(item.picUrl, (item, key) => {
            formatArray.push({
                id: key,
                imageurl: item,
                text: item,
                component: 'Image',
            });
        });

        // 预览组件配置信息
        const previewProps = {
            ...preview,
            type: 'modal',
            visible: true,
            imageConfig: {
                style: {
                    width: 100,
                    height: 100,
                },
            },
            items: formatArray,
        };
        return (
            <div className="comment-item" key={item.id}>
                <div className="comment-item__header">
                    <div className="comment-item__header-left">
                        <Checkbox value={item.id} className="comment-item__header-checkbox" />
                        <span className="comment-item__header-item">
                            {item.subsiteName}
                            {item.merchantName ? `-${item.merchantName}` : ''}
                        </span>
                    </div>
                    <div className="comment-item__header-right">
                        <span className="comment-item__header-item">
                            {language.getText('pjr')}：{item.memberPhone}
                        </span>
                        <span className="comment-item__header-item">
                            {language.getText('pjsj')}：{item.createTime}
                        </span>
                    </div>
                </div>
                <div className="comment-item__content">
                    <div className="comment-item__content-item">
                        <div className="comment-item__content-item-left">
                            <img
                                src={item.productUrl}
                                alt=""
                                className="comment-item__product-img"
                            />
                        </div>
                        <div className="comment-item__content-item-right">{item.productName}</div>
                    </div>
                    <div className="comment-item__content-item">
                        <div
                            className="comment-item__content-item-left"
                            style={{ marginTop: '6px' }}
                        >
                            {language.getText('sppj')}：
                        </div>
                        <div className="comment-item__content-item-right">
                            <Rate value={item.starts} disabled style={{ fontSize: '18px' }} />
                        </div>
                    </div>
                    <div className="comment-item__content-item">
                        <div className="comment-item__content-item-left">
                            {language.getText('pjnr')}：
                        </div>
                        <div className="comment-item__content-item-right">{item.content}</div>
                    </div>
                    <div className="comment-item__content-item">
                        <div className="comment-item__content-item-left">
                            {language.getText('pjtp')}：
                        </div>
                        <div className="comment-item__content-item-right">
                            {item.picUrl && item.picUrl.length ? (
                                <ImagePreviewPlus {...previewProps} />
                            ) : (
                                language.getText('indoorMapProviderNone')
                            )}
                        </div>
                    </div>
                    <div className="comment-item__content-item">
                        <div className="comment-item__content-item-left">
                            {language.getText('xgdd')}：
                        </div>
                        <div className="comment-item__content-item-right">{item.orderNum}</div>
                    </div>
                </div>
                {String(item.status) === TabKeys.WAITING && (
                    <div className="comment-item__btns">
                        <Button
                            type="primary"
                            className="comment-item__btn"
                            onClick={() => {
                                this.reviewComment(item.id, 'pass');
                            }}
                        >
                            {language.getText('approved')}
                        </Button>
                        <Button
                            type="default"
                            onClick={() => {
                                this.reviewComment(item.id, 'reject');
                            }}
                        >
                            {services.language.getText('shbtg')}
                        </Button>
                    </div>
                )}
                {String(item.status) === TabKeys.APPROVED && (
                    <div className="comment-item__btns">
                        <Button
                            type="primary"
                            onClick={() => {
                                this.reviewComment(item.id, 'reject');
                            }}
                        >
                            {services.language.getText('shbtg')}
                        </Button>
                    </div>
                )}
                {String(item.status) === TabKeys.REJECTED && (
                    <div className="comment-item__btns">
                        <Button
                            type="primary"
                            onClick={() => {
                                this.reviewComment(item.id, 'pass');
                            }}
                        >
                            {services.language.getText('approved')}
                        </Button>
                    </div>
                )}
            </div>
        );
    };
}
