import React, { PureComponent } from 'react';
import { Table, Button } from 'antd';
import { cloneDeep, remove } from 'lodash';
import update from 'immutability-helper';
import './index.less';
import { services } from '@comall-backend-builder/core';

interface CardLevelRightsDisplayProps {
    data: any[];
    onChange: (data: any[]) => void;
    onEdit?(data: any, index: number): void;
    modifiable: boolean;
    editSequence?: boolean;
}

/**
 * 会员卡权益项展示
 */
class CardLevelRightsDisplay extends PureComponent<CardLevelRightsDisplayProps> {
    selectedRows: any[] = [];

    getTableConfig = () => {
        const { modifiable, editSequence, data } = this.props;
        let config: any = {
            rowKey: 'name',
            columns: [
                {
                    title: '权益名称',
                    dataIndex: 'name',
                    key: 'name',
                    width: 180,
                },
                {
                    title: '权益图标',
                    dataIndex: 'iconUrl',
                    key: 'iconUrl',
                    width: 180,
                    render: (iconUrl: string) => (
                        <img className="right-icon-img" src={iconUrl} alt=""></img>
                    ),
                },
            ],
        };
        if (modifiable) {
            if (editSequence) {
                config.scroll = { x: true };
                config.columns.push({
                    title: services.language.getText('common.tableAction'),
                    key: 'operation',
                    //fixed: 'right' as const,
                    width: 400,
                    render: (i: any, row: any, index: number) => {
                        return (
                            <div>
                                <Button type="link" onClick={() => this.handleEdit(row, index)}>
                                    编辑
                                </Button>
                                <Button
                                    type="link"
                                    disabled={index === 0}
                                    onClick={this.onChangeSequence.bind(this, index, 'ASCEND')}
                                >
                                    上调
                                </Button>
                                <Button
                                    type="link"
                                    disabled={index === data.length - 1}
                                    onClick={this.onChangeSequence.bind(this, index, 'DESCEND')}
                                >
                                    下调
                                </Button>
                                <Button type="link" onClick={this.onRemove(row.name)}>
                                    {services.language.getText('common.delete')}
                                </Button>
                            </div>
                        );
                    },
                });
            } else {
                config.columns.push({
                    title: services.language.getText('common.tableAction'),
                    className: 'action-column',
                    key: 'operation',
                    //fixed: 'right' as const,
                    width: 180,
                    render: (i: any, row: any, index: number) => {
                        return (
                            <div>
                                <Button type="link" onClick={this.onRemove(row.name)}>
                                    {services.language.getText('common.delete')}
                                </Button>
                                <Button type="link" onClick={() => this.handleEdit(row, index)}>
                                    编辑
                                </Button>
                            </div>
                        );
                    },
                });
            }
        }
        return config;
    };

    onChangeSequence = (index: number, sequence: 'ASCEND' | 'DESCEND') => {
        const { data, onChange } = this.props;
        const newData = cloneDeep(data || []);
        if (
            (sequence === 'ASCEND' && index === 0) ||
            (sequence === 'DESCEND' && index === data.length - 1)
        ) {
            return;
        }
        const currentValue = data[index];
        const targetIndex = sequence === 'ASCEND' ? index - 1 : index + 1;
        const newDataSource = update(newData, {
            $splice: [
                [index, 1],
                [targetIndex, 0, currentValue],
            ],
        });
        onChange(newDataSource);
    };

    onRemove = (name: string) => {
        const { data, onChange } = this.props;
        return () => {
            const result = cloneDeep(data);
            remove(result, (i) => name === i.name);
            onChange(result);
        };
    };

    handleEdit = (row: any, index: number) => {
        const { onEdit } = this.props;
        onEdit && onEdit(row, index);
    };

    render() {
        const { data, modifiable } = this.props;
        let tableConfig = this.getTableConfig();
        const tableProps = { ...tableConfig, dataSource: data };
        return (
            <div className={'card-level-right-display ' + (modifiable ? 'modify' : '')}>
                <Table {...tableProps} pagination={false}></Table>
            </div>
        );
    }
}

export { CardLevelRightsDisplay };
