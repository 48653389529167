import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { remove, get, map, isEqual } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Modal, Menu, message } from 'antd';

const CouponTableId = 'PayWithGiftRewardCouponSelectorDataTable';
const CouponPackageTableId = 'CouponPackageSelectorDataTable';
let Entity: any = {};
let couponEntity: any = {};
let couponPackageEntity: any = {};
/**
 * CASH:代金券
 * GIFT:礼品券
 * FREIGHT:运费券
 * PARKING:停车券
 * BALANCE:余额券
 * LOTTERY:抽奖券
 * PACKAGE:优惠券礼包
 */

export enum CouponType {
    CASH = 'CASH',
    GIFT = 'GIFT',
    FREIGHT = 'FREIGHT',
    PARKING = 'PARKING',
    BALANCE = 'BALANCE',
    LOTTERY = 'LOTTERY',
    PACKAGE = 'PACKAGE',
}
export enum EventCouponValueTypeEnum {
    No = 'NO',
    Amount = 'AMOUNT',
    EveryReduce = 'EVERY_REDUCE',
    Random = 'RANDOM',
    Discount = 'DISCOUNT',
    Hours = 'HOURS',
    Times = 'TIMES',
}
/**
 * SelectCoupon: 指定优惠券
 * SelectCouponPackage: 指定优惠券包
 */
enum MenuCouponItem {
    SelectCoupon = 'selectCoupon',
    SelectCouponPackage = 'selectCouponPackage',
}
export interface Coupon {
    id: string;
    // type: string;
    name: number;
    startTime: string;
    endTime: string;
    quantity?: number;
    /**
     * 被选中的优惠券
     */
    select?: boolean;
}
interface CouponPackage {
    id: string;
    name: number;
    startTime: string;
    endTime: string;
    /**
     * 礼包中的优惠券信息
     */
    couponInfo: Array<CouponPackageItems>;
    /**
     * 被选中的优惠券礼包
     */
    select?: boolean;
    /**
     * 优惠券礼包
     */
    couponType: string;
}

interface CouponPackageItems {
    batchNo: string;
    couponDefinitionId: number;
    couponDefinitionName: string;
    couponTypeDesc: string;
    quantity: number;
}
/**
 * 选择优惠券，礼包组件
 */
class payWithGiftRewardCouponSelector extends PureComponent<
    {
        onRef: any;
        rewardIndex: number;
        selectValues: any[]; //选中数据
        onChange: (values: any[], type: string) => void;
        params?: any; //表格筛选参数
        buttonText?: string;
        requestStatus: string;
        rowSelectionType?: string;
        // selectMode: string;
        // activityProductType: string;
        verifyTip?: string;
    },
    any
> {
    dispatch: any = {};
    selectedRowsCoupon: any[] = [];
    selectedRowsCouponPackage: any[] = [];

    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('CouponSelector');
        couponEntity = new Entity({});
        Entity = EntitiesManager.get('CouponPackageSelector');
        couponPackageEntity = new Entity({});
        this.state = {
            visible: false,
            selectType: 'selectCoupon',
        };
    }

    componentDidMount() {
        this.props.onRef(this);
    }
    componentWillReceiveProps(nextProps: any) {
        //如果数据发生变化，则清空数据
        const nextType = nextProps.selectValues;
        const currentDisplayType = this.props.selectValues;
        if (nextProps.rewardIndex !== this.props.rewardIndex) {
            this.onClearAll();
        } else if (!isEqual(nextType, currentDisplayType)) {
            this.onClearAll();
        }
    }

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(couponEntity));
        this.dispatch(actions.unmountEntityAction(couponPackageEntity));
    }
    onCouponTableRowSelect = (record: any, selected: boolean, rowSelectionType: string) => {
        if (rowSelectionType === 'radio') {
            this.selectedRowsCoupon = [record];
        } else {
            if (selected) {
                this.selectedRowsCoupon.push(record);
            } else {
                remove(this.selectedRowsCoupon, (i) => {
                    return i.id === record.id;
                });
            }
        }
    };
    onCouponPackageTableRowSelect = (record: any, selected: boolean, rowSelectionType: string) => {
        if (rowSelectionType === 'radio') {
            this.selectedRowsCouponPackage = [record];
        } else {
            if (selected) {
                this.selectedRowsCouponPackage.push(record);
            } else {
                remove(this.selectedRowsCouponPackage, (i) => {
                    return i.id === record.id;
                });
            }
        }
    };

    onCouponTableRowSelectAll = (selected: boolean, rows: any[]) => {
        if (selected) {
            this.selectedRowsCoupon = rows;
        } else {
            this.selectedRowsCoupon = [];
        }
    };
    onCouponPackageTableRowSelectAll = (selected: boolean, rows: any[]) => {
        if (selected) {
            this.selectedRowsCouponPackage = rows;
        } else {
            this.selectedRowsCouponPackage = [];
        }
    };

    onClearAll = () => {
        this.selectedRowsCoupon = [];
        this.selectedRowsCouponPackage = [];
        this.dispatch(actions.tableRowSelectionChangeAction(CouponTableId, []));
        this.dispatch(actions.tableRowSelectionChangeAction(CouponPackageTableId, []));
    };

    onOk = () => {
        const { onChange } = this.props;
        const { selectType } = this.state;
        if (onChange) {
            if (selectType === MenuCouponItem.SelectCoupon) {
                onChange(this.selectedRowsCoupon, selectType);
            } else if (selectType === MenuCouponItem.SelectCouponPackage) {
                onChange(this.selectedRowsCouponPackage, selectType);
            }
            this.toggleModal();
        }
    };

    getCouponConfig = () => {
        const { params, selectValues } = this.props;
        let columns: any[] = [];
        columns = [
            {
                property: 'name',
                displayConfig: {
                    className: 'ant-table-cell-ellipsis',
                    style: {
                        maxWidth: '200px',
                    },
                },
            },
            {
                property: 'code',
            },
            {
                property: 'typeName',
            },
            {
                property: 'sourceDesc',
            },
            // {
            //     property: 'amountValue',
            // },
            // {
            //     property: 'consumeEffectivePeriodRule.dynamicEffectivePeriod',
            // }
            {
                property: 'startTime',
            },
            {
                property: 'endTime',
            },
            {
                property: 'consumeChannelDescription',
            },
        ];
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: couponEntity,
            params: params || {},
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'right' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        couponEntity.pageChange(
                            Object.assign({}, couponEntity.paging, { page: 1 })
                        );
                        couponEntity.search(params);
                    },
                    fields: [
                        {
                            property: 'name',
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: CouponTableId,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    rowSelection: {
                        type: 'radio',
                        selectedRowKeys: map(selectValues, 'id'),
                        onSelect: (record: Coupon, selected: boolean) => {
                            this.onCouponTableRowSelect(record, selected, 'radio');
                        },
                        onSelectAll: (selected: boolean, rows: Coupon[]) => {
                            this.onCouponTableRowSelectAll(selected, rows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    scroll: { x: true },
                    columns: columns,
                },
            ],
        };
    };

    getCouponPackageConfig = () => {
        const { params, selectValues } = this.props;

        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: couponPackageEntity,
            params: params || {},
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'right' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        couponPackageEntity.pageChange(
                            Object.assign({}, couponPackageEntity.paging, { page: 1 })
                        );
                        couponPackageEntity.search(params);
                    },
                    fields: [
                        {
                            property: 'name',
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: CouponPackageTableId,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    rowSelection: {
                        type: 'radio',
                        selectedRowKeys: map(selectValues, 'id'),
                        onSelect: (record: CouponPackage, selected: boolean, selectedRow: any) => {
                            this.onCouponPackageTableRowSelect(record, selected, 'radio');
                        },
                        onSelectAll: (selected: boolean, rows: CouponPackage[]) => {
                            this.onCouponPackageTableRowSelectAll(selected, rows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    columns: [
                        {
                            property: 'name',
                        },
                        {
                            property: 'startTime',
                        },
                        {
                            property: 'endTime',
                        },
                        {
                            property: 'executeStatus',
                        },
                    ],
                },
            ],
        };
    };

    setDisplayType = () => {
        const { selectValues } = this.props;
        let displayType = MenuCouponItem.SelectCoupon;
        if (selectValues.length && selectValues[0].type === CouponType.PACKAGE) {
            displayType = MenuCouponItem.SelectCouponPackage;
        }
        return displayType;
    };

    toggleModal = () => {
        let entity = couponEntity;
        if (this.setDisplayType() === MenuCouponItem.SelectCouponPackage) {
            entity = couponPackageEntity;
            this.setState({
                selectType: MenuCouponItem.SelectCouponPackage,
            });
        }
        if (this.checkSubsiteOrMerchantExist()) {
            const { params } = this.props;
            const { visible } = this.state;

            this.setState({ visible: !visible }, () => {
                if (!visible && entity.paging) {
                    entity.search({
                        ...entity.paging,
                        ...params,
                    });
                    this.onClearAll();
                }
            });
        }
    };

    onSelectKey = (key: string) => {
        if (this.state.selectType !== key) {
            this.setState({
                selectType: key,
            });
            this.onClearAll();
        }
    };

    render() {
        const couponConfig = this.getCouponConfig();
        const couponContent = createElement(
            ComponentsManager.get(couponConfig.component),
            couponConfig
        );

        const couponPackageConfig = this.getCouponPackageConfig();
        const couponPackageContent = createElement(
            ComponentsManager.get(couponPackageConfig.component),
            couponPackageConfig
        );

        const modalProps = {
            width: 1200,
            title: '请选择优惠券',
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };

        const { selectType } = this.state;
        return (
            <div style={{ display: 'inline-block' }}>
                {/* <Button type={'link'} onClick={this.toggleModal}>
                    {buttonText}
                </Button> */}
                <Modal {...modalProps}>
                    <div style={{ display: 'flex' }}>
                        <Menu
                            selectedKeys={[selectType]}
                            onClick={({ key }) => {
                                this.onSelectKey(key);
                            }}
                        >
                            <Menu.Item key={MenuCouponItem.SelectCoupon}>指定优惠券</Menu.Item>
                            <Menu.Item key={MenuCouponItem.SelectCouponPackage}>
                                指定优惠券包
                            </Menu.Item>
                        </Menu>
                        <div style={{ padding: '0 20px', width: '100%' }}>
                            {selectType === MenuCouponItem.SelectCoupon && couponContent}
                            {selectType === MenuCouponItem.SelectCouponPackage &&
                                couponPackageContent}
                        </div>
                    </div>
                </Modal>
            </div>
        );
    }

    private checkSubsiteOrMerchantExist = () => {
        const { params, verifyTip = '请先选择门店' } = this.props;
        const { subsiteIds } = params;
        if (!subsiteIds || subsiteIds.length === 0) {
            message.warning(verifyTip);
            return false;
        }
        return true;
    };
}

export const PayWithGiftRewardCouponSelector = connect((_state: any) => {
    let requestStatus = get(couponEntity, 'requestStatus', null);
    const result = { requestStatus };
    return result;
})(payWithGiftRewardCouponSelector);
