import { PureComponent, createElement } from 'react';
import { ComponentsManager, services } from '@comall-backend-builder/core';

export class EventMarketPayCheckButton extends PureComponent<any, any> {
    render() {
        const { row } = this.props;
        const { id, typeId } = row;
        const path = `/menu-list/market/eventMarket/check/${id}`;
        if (typeId === 10) {
            return createElement(ComponentsManager.get('Button'), {
                text: services.language.getText('reportData'),
                type: 'link',
                route: path,
                style: {
                    marginLeft: 5,
                },
            });
        }
        return null;
    }
}
