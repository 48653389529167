import React, { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { defaults, get } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { DistributionModal } from '../distribution-modal';
import './index.less';

export interface DistributionFormProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
    data: any;
}

class DistributionFormView extends PureComponent<DistributionFormProps> {
    state = {
        visible: false,
    };
    componentWillUnmount() {
        const { unmountComponent } = this.props;
        if (unmountComponent) {
            unmountComponent();
        }
    }
    //审核弹窗
    onAudit = async (val: any) => {
        const id = this.props.params.id;
        try {
            services.loading.open();
            const params: any = { remark: val.reason || '' };
            if (val.result) {
                params.auditStatus = 'SUCCESS';
            } else {
                if (!val.reason) {
                    AntMessage.error(services.language.getText('qtxshyy'));
                    services.loading.close();
                    return;
                }
                params.auditStatus = 'FAIL';
            }
            await services.api.put(params, {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`,
                apiPath: `/admin/members/identities/apply_records/${id}/audit`,
            });
            services.loading.close();

            AntMessage.success(services.language.getText('shcg'));
            setTimeout(() => {
                services.behaviorHandle({ route: 'goBack' });
            }, 1500);
        } catch (error) {
            services.errorHandle(error as any);
        }
    };
    //保存并审核
    onSaveAndAudit = (event: any) => {
        event.stopPropagation();
        const { getForm } = this.props;

        const form = getForm();
        form.validateFields().then(async (entityFields: any) => {
            const res = transformAndValidate(entityFields);
            if (res) {
                try {
                    services.loading.open();
                    await saveData(res, this.props);
                    services.loading.close();
                    // AntMessage.success(services.language.getText('common.saveSuccess'));
                    this.setState({ visible: true });
                } catch (error) {
                    services.errorHandle(error as any);
                }
            }
        });
    };
    //保存
    onSubmit = (event: any) => {
        event.stopPropagation();
        const { getForm } = this.props;

        const form = getForm();
        form.validateFields().then(async (entityFields: any) => {
            const res = transformAndValidate(entityFields);
            if (res) {
                try {
                    services.loading.open();
                    await saveData(res, this.props);
                    services.loading.close();

                    AntMessage.success(services.language.getText('common.saveSuccess'));
                    setTimeout(() => {
                        services.behaviorHandle({ route: 'goBack' });
                    }, 1500);
                } catch (error) {
                    services.errorHandle(error as any);
                }
            }
        });
    };
    close = () => {
        this.setState({ visible: false });
    };
    render() {
        const { entity, wrappedComponentRef } = this.props;
        const { visible } = this.state;
        let fields = [
            {
                property: 'userInfo.name',
                controlConfig: {
                    style: { width: 200 },
                },
                modifiable: (e: any) => {
                    return get(e, 'auditStatus') !== 'SUCCESS';
                },
            },
            { property: 'userInfo.mobile', modifiable: false },
            { property: 'userInfo.nameEn', modifiable: false },
            {
                property: 'userInfo.auditStatus',
                label: services.language.getText('auditStatus'),
                modifiable: false,
            },
            {
                property: 'userInfo.remark',
                label: services.language.getText('reason'),
                modifiable: false,
            },
        ];

        const formProps = {
            entity,
            componentId: 'DistributionFormContainer',
            wrappedComponentRef,
            loaderType: 'get',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            fields: fields,
            submit: false,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        text: services.language.getText('common.save'),
                        type: 'primary',
                        style: { marginLeft: 10 },
                        onClick: this.onSubmit,
                    },
                    {
                        htmlType: 'button',
                        text: services.language.getText('bcbsh'),
                        type: 'primary',
                        style: { marginLeft: 10 },
                        onClick: this.onSaveAndAudit,
                    },
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: services.language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: async (entity: any) => {
                return true;
            },
        };
        return (
            <>
                {createElement(ComponentsManager.get('ModifyFormPlus'), formProps)}
                {visible && (
                    <DistributionModal
                        onCancel={this.close}
                        onAudit={this.onAudit}
                        visible={visible}
                    ></DistributionModal>
                )}
            </>
        );
    }
}

function mapStateToProps(_dispatch: any, props: any) {
    const data =
        get(_dispatch, 'components.["DataFormPlus-DistributionFormContainer"].fields') || {};

    return {
        data,
        unmountComponent: () => {
            builder
                .getStore()
                .dispatch(actions.unmountComponentAction('DataFormPlus-DistributionFormContainer'));
        },
    };
}

function transformAndValidate(params: any) {
    const {
        userInfo: { name },
    } = params;
    if (!name) {
        AntMessage.error(services.language.getText('qtxfxyxm'));
        return false;
    }
    return {
        applyName: name,
    };
}

async function saveData(params: any, props: any) {
    return await services.api.put(params, {
        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`,
        apiPath: `/admin/members/identities/apply_records/${props.params.id}`,
    });
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit } = props;
    let form: any;

    return defaults(
        { onSubmit },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
        }
    );
}
export const DistributionEditForm = connect(
    mapStateToProps,
    mapDispatchToProps
)(DistributionFormView);
