import React, { useState, useEffect, useCallback } from 'react';
import { services } from '@comall-backend-builder/core';
import { Button, ExtendedParentComponentProps } from '@comall-backend-builder/components-basis';
import { message } from 'antd';
import { PrivilegeTree, PrivilegeTreeProps } from '../privilege-management/privilege-tree';
import { errorHandle } from '../../services/error-handle';
import '../privilege-management/index.less';

const { navigation, api } = services;

interface EntityPrivilege extends PrivilegeTreeProps {
    id: string;
}

interface PrivilegeTreeNode {
    id: string;
    name: string;
    enable: boolean;
    isLeaf: boolean;
    children: Array<PrivilegeTreeNode> | null;
}

interface Entity {
    id: string;
    name: string;
}

function getValue(nodes: PrivilegeTreeNode[]) {
    let keys: string[] = [];
    for (let { id, enable, children } of nodes) {
        if (enable) {
            keys.push(id);
        }
        if (children?.length) {
            keys = keys.concat(getValue(children));
        }
    }
    return keys;
}

export const MerchantUserPrivilegeManagement: React.FunctionComponent<ExtendedParentComponentProps> = ({
    params,
}) => {
    const userId = params?.id;
    const [initialized, setInitialized] = useState<boolean>(false);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [entities, setEntities] = useState<Array<EntityPrivilege>>([]);

    useEffect(() => {
        const initializePromises: Promise<any>[] = [];

        const data = [
            {
                id: '99900002',
                name: '专柜',
            },
        ];

        api.get<any[]>(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: '/WEB-API/admin/defaults/subsite',
            }
        ).then((subsites) => {
            if (subsites.length === 0) {
                message.error('当前账号无门店权限，请先分配门店权限');
                return;
            }
            setEntities((prevState) => {
                let nextState = prevState.concat();
                data.forEach(({ id, name }, index) => {
                    nextState.push({
                        id,
                        name: `${name}`,
                        value: [],
                        options: [],
                        onChange: (value: string[]) => {
                            setEntities((prevState) => {
                                let entity = prevState[index];
                                let nextState = prevState.concat();
                                nextState.splice(index, 1, { ...entity, value });
                                return nextState;
                            });
                        },
                    });
                });
                return nextState;
            });

            const subsiteIds: any = [];
            for (var i = 0; i < subsites.length; i++) {
                subsiteIds.push(subsites[i].id);
            }
            data.forEach(({ id }, index) => {
                let promise = api.get<PrivilegeTreeNode[]>(
                    { userId, entityId: 99900002 },
                    {
                        customHeaders: {
                            subsiteIds: subsiteIds.join(','),
                        },
                        apiRoot: `${ENV.AUTH_API_ROOT}/CAE-PRIVILEGE`,
                        apiPath: '/admin/users/:userId/entities/:entityId/record_privileges',
                    }
                );
                promise.then((data) => {
                    //CAE会返回父级和子级id一致的时候，所以前端你此处采用子级id前缀拼接上父级的，以"_"分隔 开始
                    for (let i = 0; i < data.length; i++) {
                        const children = data[i].children;
                        if (children) {
                            for (let j = 0; j < children.length; j++) {
                                children[j].id = data[i].id + '_' + children[j].id;
                            }
                        }
                    }
                    //结束
                    setEntities((prevState) => {
                        let entity = prevState[index];
                        let nextState = prevState.concat();
                        nextState.splice(index, 1, {
                            ...entity,
                            value: getValue(data),
                            options: data,
                        });
                        return nextState;
                    });
                }, errorHandle);
                initializePromises.push(promise);
            });
        });

        Promise.all(initializePromises).then(() => {
            setInitialized(true);
        });
    }, [userId]);

    const handleSubmit = useCallback(() => {
        api.get<any[]>(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: '/WEB-API/admin/defaults/subsite',
            }
        ).then((subsites) => {
            if (subsites.length === 0) {
                message.error('当前账号无门店权限，请先分配门店权限');
                return;
            }
            const subsiteIds: any = [];
            for (var i = 0; i < subsites.length; i++) {
                subsiteIds.push(subsites[i].id);
            }
            //开始提交
            setSubmitting(true);
            const submitPromises = [];

            for (let i = 0; i < entities.length; i++) {
                const { id, value } = entities[i];
                //提交ids时 CAE 要求只要子级的
                let newValue = [];
                for (let j = 0; j < value.length; j++) {
                    const v = value[j];
                    const index = v.indexOf('_');
                    if (index > 0) {
                        newValue.push(value[j].substring(index + 1, value[j].length));
                    }
                }

                const promise = api.put(
                    { userId: userId, entityId: id, ids: newValue },
                    {
                        customHeaders: {
                            subsiteIds: subsiteIds.join(','),
                        },
                        apiRoot: `${ENV.AUTH_API_ROOT}/CAE-PRIVILEGE`,
                        apiPath: '/admin/users/:userId/entities/:entityId/record_privileges',
                    }
                );
                promise.catch(errorHandle);
                submitPromises.push(promise);
            }

            Promise.all(submitPromises).then(
                () => {
                    message.success(services.language.getText('common.saveSuccess'));
                    setSubmitting(false);
                    navigation.goto('/merchant-user-manage');
                },
                () => {
                    setSubmitting(false);
                }
            );
        });
    }, [userId, entities]);

    return (
        <>
            <div className="layout privilege-tree-container">
                {entities.map((entity) => (
                    <PrivilegeTree {...entity} key={entity.id}></PrivilegeTree>
                ))}
            </div>
            <div className="fixed-footer-toolbar">
                <Button type="default" onClick={navigation.goBack} style={{ marginRight: 12 }}>
                    取消
                </Button>
                <Button
                    type="primary"
                    disabled={!initialized}
                    loading={submitting}
                    onClick={handleSubmit}
                >
                    提交
                </Button>
            </div>
        </>
    );
};
