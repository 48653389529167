/*
 * @Author: zhulu
 * @Date: 2019-12-18 14:09:41
 * @Description: 可视化页面管理
 */
import { Config } from '@comall-backend-builder/core/lib/parser';
export const config: Config = {
    components: {
        TopicManageView: {
            component: 'Viewport',
            entity: 'TopicManageEntity',
        },
        TopicManageDesign: {
            component: 'Card',
            content: {
                component: 'PageDesign',
                client: ENV.DESIGN_CLIENT_URL,
                apiPath: '/admin/topic_pages/:id',
                type: 'topic',
            },
        },
        TopicManage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'GridLayout',
                    items: [
                        {
                            component: 'TopicManageFilter',
                            span: 24,
                        },
                        {
                            component: 'Button',
                            text: '<<components.TopicManage.createPage>>',
                            type: 'primary',
                            icon: 'plus',
                            span: 4,
                            route: '/topic-manage/add',
                            className: 'mt24 mb24',
                        },
                    ],
                },

                {
                    component: 'TopicManageTable',
                },
            ],
        },
        TopicManageFilter: {
            component: 'FilterForm',
            direction: 'inline',
            fields: [
                {
                    property: 'groupSubsiteName',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        optionFilterProp: 'children',
                        style: { width: '160px' },
                    },
                },
                // {
                //     property: 'type',
                //     controlConfig: {
                //         style: { width: '160px' },
                //     },
                // },
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: '160px' },
                    },
                },
            ],
            submit: { text: '<<common.search>>' },
        },
        TopicManageTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'groupSubsiteName',
                },
                // {
                //     property: 'type',
                // },
                {
                    property: 'enabled',
                },
                {
                    property: 'name',
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'lastModifyTime',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                items: [
                    {
                        type: 'link',
                        text: '<<components.TopicManage.setting>>',
                        path: '/topic-manage/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<components.TopicManage.decorate>>',
                        path: '/topic-manage/design/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'PagePreviewButton',
                        config: {
                            type: 'all',
                        },
                    },
                    {
                        type: 'delete',
                        text: '<<components.TopicManage.delete>>',
                        statusKey: 'row.canDelete',
                        config: {
                            type: 'link',
                            tooltip: null,
                            title: '<<confirmDelete>>',
                            okText: '<<common.ok>>',
                            cancelText: '<<common.cancel>>',
                        },
                    },
                ],
            },
        },
        TopicManageAdd: {
            component: 'Card',
            title: '<<components.TopicManage.createPage>>',
            entities: [{ name: 'PageTemplateEntity', entityName: 'PageTemplateEntity' }],
            content: {
                component: 'TopicManageAddForm',
            },
        },
        TopicManageAddForm: {
            component: 'CreateForm',
            entity: 'TopicManageEntity',
            fields: [
                {
                    property: 'name',
                },
                { property: 'groupSubsiteName' },
                { property: 'subsites' },
                {
                    property: 'enabled',
                },
                {
                    property: 'template',
                    controlConfig: {
                        pageType: 'topic',
                    },
                },
            ],
            labelCol: 4,
            controlCol: 20,
            submit: { text: '<<common.submit>>' },
            submitSuccessBehavior: {
                route: '/topic-manage',
            },
            footer: {
                items: [
                    {
                        text: '<<common.cancel>>',
                        htmlType: 'button',
                        type: 'default',
                        route: 'goBack',
                        style: { marginLeft: 16 },
                    },
                ],
            },
        },
        TopicManageEdit: {
            component: 'Card',
            title: '<<components.TopicManage.setPage>>',
            content: {
                component: 'TopicManageEditForm',
            },
        },
        TopicManageEditForm: {
            component: 'ModifyForm',
            entity: 'TopicManageEntity',
            fields: [
                {
                    property: 'name',
                },
                { property: 'groupSubsiteName' },
                { property: 'subsites' },
                {
                    property: 'enabled',
                },
                {
                    property: 'template',
                    controlConfig: {
                        type: 'topic',
                    },
                },
            ],
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            submit: { text: '<<common.save>>' },
            submitSuccessBehavior: {
                route: '/topic-manage',
            },
            footer: {
                items: [
                    {
                        text: '<<common.cancel>>',
                        htmlType: 'button',
                        type: 'default',
                        route: 'goBack',
                        style: { marginLeft: 16 },
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/topic-manage',
                    component: 'TopicManageView',
                    breadcrumbName: '<<components.NavMenu.topicPagesManagement>>',
                    privilege: {
                        path: 'topicPage',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/design/:id',
                            component: 'TopicManageDesign',
                        },
                        {
                            path: '/add',
                            component: 'TopicManageAdd',
                        },
                        {
                            path: '/edit/:id',
                            component: 'TopicManageEdit',
                        },
                        {
                            path: '/',
                            component: 'TopicManage',
                        },
                    ],
                },
            ],
        },
    ],
};
