import * as React from 'react';
import { Icon, Button, message as AntMessage, Button as AntButton, Modal } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services';
import './index.less';

interface State {
    /**
     * 是否展开收起 默认展开
     */
    expand: boolean;

    /**
     * 是否打开示例弹层
     */
    visible: boolean;

    /**
     * 配置引导提示
     */
    tip: string;

    /**
     * 示例类型
     */
    type: string;
}

export class MerchantBusinessGuideDisplay extends React.Component<any, State> {
    state: State = {
        expand: false,
        visible: false,
        tip: language.getText('qxbcwxsqpzxx'),
        type: '',
    };

    componentDidMount() {
        const { row } = this.props;
        if (row.baseInfo?.wxMallId) {
            this.setState({ tip: '' });
        }
    }

    componentWillReceiveProps(nextProps: any): void {
        const { row } = nextProps;
        const { expand } = this.state;
        const wxMallId = row.baseInfo?.wxMallId;
        if (!wxMallId || wxMallId.length > 10 || wxMallId.length < 8) {
            this.setState({ tip: language.getText('qxbcwxsqpzxx') });
            // 如果再次清除商圈商户号，则收起配置引导
            if (expand) {
                this.setState({ expand: false });
            }
        } else {
            this.setState({ tip: '' });
        }
    }

    /** 切换收起展开 */
    toggle = () => {
        const { expand } = this.state;
        const { row } = this.props;
        const wxMallId = row.baseInfo?.wxMallId;
        if (!wxMallId || wxMallId.length > 10 || wxMallId.length < 8) {
            this.setState({ tip: language.getText('qxbcwxsqpzxx') });
        } else {
            this.setState({ tip: '', expand: !expand });
        }
    };

    /*
     * 复制
     */
    copy = (value: string) => {
        const inputEl = document.createElement('input');
        inputEl.value = value;
        document.body.appendChild(inputEl);
        inputEl.select();
        if (document.execCommand('copy')) {
            AntMessage.success(language.getText('copySuccess'));
        } else {
            AntMessage.warn(language.getText('copyError'));
        }
        document.body.removeChild(inputEl);
    };
    /*
     * 打开示例弹层
     */
    openExample = (type: string) => {
        const { visible } = this.state;
        this.setState({ visible: !visible, type: type });
    };

    render() {
        const { row } = this.props;
        const { expand, visible, type, tip } = this.state;
        let picUrl = '',
            picUrlTwo = '',
            style = {},
            width = 600;
        if (type === 'memberPoint') {
            // 会员积分
            picUrl = require('./member_point1.png');
            picUrlTwo = require('./member_point2.png');
            width = 900;
            style = { height: '600px', overflow: 'hidden', 'overflow-y': 'auto' };
        } else {
            //会员积分-停车服务配置
            picUrl = require('./parking_fee1.png');
            picUrlTwo = require('./parking_fee2.png');
            width = 900;
            style = { height: '600px', overflow: 'hidden', 'overflow-y': 'auto' };
        }
        return (
            <div className="merchant-business-guide">
                <Button type="link" style={{ marginLeft: 8, fontSize: 12 }} onClick={this.toggle}>
                    {expand ? language.getText('putAway') : language.getText('launch')}{' '}
                    <Icon type={expand ? 'up' : 'down'} />
                </Button>
                <div className="tip">{tip}</div>
                {expand && (
                    <div>
                        <div className="bold">
                            {language.getText('hyjf')}：
                            <AntButton type="link" onClick={() => this.openExample('memberPoint')}>
                                {language.getText('example')}
                            </AntButton>
                        </div>
                        <div>
                            {language.getText('yhxfhddz')}：
                            {row.baseInfo?.transactionNotificationUrl}
                            {row.baseInfo?.transactionNotificationUrl ? (
                                <AntButton
                                    onClick={() => {
                                        this.copy(row.baseInfo?.transactionNotificationUrl);
                                    }}
                                    type="link"
                                >
                                    {language.getText('copy')}
                                </AntButton>
                            ) : (
                                <span>{language.getText('qxbcwxsqpzxx')}</span>
                            )}
                        </div>
                        <div>
                            {language.getText('hyjffwkthddz')}：
                            {row.baseInfo?.authorizeNotificationUrl}
                            {row.baseInfo?.authorizeNotificationUrl ? (
                                <AntButton
                                    onClick={() => {
                                        this.copy(row.baseInfo?.authorizeNotificationUrl);
                                    }}
                                    type="link"
                                >
                                    {language.getText('copy')}
                                </AntButton>
                            ) : (
                                <span>{language.getText('qxbcwxsqpzxx')}</span>
                            )}
                        </div>
                        <div>
                            {language.getText('jfjllj')}：{row.baseInfo?.pointDetailPage}
                            {row.baseInfo?.pointDetailPage && (
                                <AntButton
                                    onClick={() => {
                                        this.copy(row.baseInfo?.pointDetailPage);
                                    }}
                                    type="link"
                                >
                                    {language.getText('copy')}
                                </AntButton>
                            )}
                        </div>
                        <div>
                            {language.getText('jfdhlj')}：{row.baseInfo?.creditShopPage}
                            {row.baseInfo?.creditShopPage && (
                                <AntButton
                                    onClick={() => {
                                        this.copy(row.baseInfo?.creditShopPage);
                                    }}
                                    type="link"
                                >
                                    {language.getText('copy')}
                                </AntButton>
                            )}
                        </div>
                        <div className="bold">
                            {language.getText('jhyjftcfwpz')}：
                            <AntButton type="link" onClick={() => this.openExample('parkingFee')}>
                                {language.getText('example')}
                            </AntButton>
                        </div>
                        <div>
                            {language.getText('clgllj')}：{row.baseInfo?.myCarsPage}
                            {row.baseInfo?.myCarsPage && (
                                <AntButton
                                    onClick={() => {
                                        this.copy(row.baseInfo?.myCarsPage);
                                    }}
                                    type="link"
                                >
                                    {language.getText('copy')}
                                </AntButton>
                            )}
                        </div>
                        <div>
                            {language.getText('tcjflj')}：{row.baseInfo?.parkingFeePage}
                            {row.baseInfo?.parkingFeePage && (
                                <AntButton
                                    onClick={() => {
                                        this.copy(row.baseInfo?.parkingFeePage);
                                    }}
                                    type="link"
                                >
                                    {language.getText('copy')}
                                </AntButton>
                            )}
                        </div>
                    </div>
                )}

                <Modal
                    zIndex={9999}
                    footer={null}
                    title={language.getText('picRelation')}
                    onCancel={() => this.openExample('memberPoint')}
                    visible={visible}
                    width={`${width + 50}px`}
                >
                    <div style={style}>
                        <img src={picUrl} width={`${width}px`} alt="" />
                        {picUrlTwo && <img src={picUrlTwo} width={`${width}px`} alt="" />}
                    </div>
                </Modal>
            </div>
        );
    }
}
