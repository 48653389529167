import React, { Component } from 'react';
import { Icon } from 'antd';
import { DragSource, DropTarget } from 'react-dnd';
import './index.less';

let dragingIndex = -1;
class UploadPreviewImage extends Component<any> {
    render() {
        const {
            isOver,
            connectDragSource,
            connectDropTarget,
            image,
            id,
            index,
            handlePreview,
            handleDelete,
            disabled,
        } = this.props;
        const style = {
            backgroundImage: `url(${image})`,
        };
        const iconStyle = {
            color: '#FFFFFF',
            marginLeft: '5px',
            cursor: 'pointer',
        };
        let previewClassName = 'drag-upload-preview-image';
        if (isOver) {
            if (index > dragingIndex) {
                previewClassName += ' drop-over-downward';
            }
            if (index < dragingIndex) {
                previewClassName += ' drop-over-upward';
            }
        }
        const onPreview = handlePreview.bind(this, id, image);
        const onDelete = disabled ? undefined : handleDelete.bind(this, id, image);
        const deleteStyle = disabled
            ? {
                  ...iconStyle,
                  cursor: 'not-allowed',
              }
            : iconStyle;
        return connectDragSource(
            connectDropTarget(
                <div className={previewClassName} key={id}>
                    <div className="drag-upload-preview-image-container" style={style}>
                        <div className="drag-upload-preview-image-container-mask">
                            <Icon type="eye" style={iconStyle} onClick={onPreview} />
                            <Icon type="delete" style={deleteStyle} onClick={onDelete} />
                        </div>
                    </div>
                </div>
            )
        );
    }
}

const previewSource = {
    beginDrag(props: any) {
        dragingIndex = props.index;
        return {
            index: props.index,
        };
    },
};
const previewTarget = {
    drop(props: any, monitor: any) {
        const dragIndex = monitor.getItem().index;
        const hoverIndex = props.index;
        if (dragIndex === hoverIndex) {
            return;
        }
        props.moveRow(dragIndex, hoverIndex);

        monitor.getItem().index = hoverIndex;
    },
};

export default DropTarget('UploadPreviewImage', previewTarget, (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
}))(
    DragSource('UploadPreviewImage', previewSource, (connect) => ({
        connectDragSource: connect.dragSource(),
    }))(UploadPreviewImage)
);
