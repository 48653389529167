import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { api, errorHandle, language } from '@comall-backend-builder/core/lib/services';
import { Button, DatePicker, Form, Select, Table, Input } from 'antd';
import PropTypes from 'prop-types';
import { ColumnProps } from 'antd/lib/table';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { ForwardDataCenterModal } from '../../../services';
import './index.less';

let todayTime: any = moment();
let today = moment().format('YYYY-MM-DD');
let defaultParams: Record<string, string> = {
    startTime: today,
    endTime: today,
};
interface responseSuccess {
    page: number;
    perPage: number;
    result: OrderVo[];
    totalNum: number;
    totalPage: number;
}

interface OrderVo {
    accountBalance: number;
    accountId: number;
    accountStatementId: number;
    afterSalesOrderNo: string;
    category: string;
    createTime: string;
    id: string;
    lastModifiedTime: string;
    lastModifiedTimestamp: number;
    orderNo: string;
    orderPaidAmount: number;
    paymentNo: string;
    returnFreight: number;
    statementDate: string;
    status: string;
    tenantId: string;
    tenantName: string;
    transactionAmount: number;
    transactionReceiptFileUrl: string;
    transactionRecordId: number;
    transactionType: string;
    transferRemark: string;
}
const transactionTypeName: Record<string, string> = {
    TRANSACTION_PLAY: 'jyfk',
    TRANSACTION_REFUND: 'jytk',
    PAY_ADVERTISING_EXPENSES: 'zftgfwf',
    RETURN_FREIGHT: 'zfbcyf',
    EXPENSES_COLLECT: 'tgfwfdd',
    EXPENSES_COLLECT_BACK: 'tgfwfbfshsysp',
    EXPENSES_REFUND: 'tgfwfhc',
    RETURN_FREIGHT_REFUND: 'shjhyfbc',
    FINANCIAL_TRANSFER: 'cwzz',
};
const statusName: Record<string, string> = {
    BILLING_GENERATED: 'yfz',
    BILLING_NOT_GENERATED: 'wfz',
};

const CollectionCreateForm = Form.create({ name: 'searchForm' })(
    class extends React.Component {
        static propTypes: {
            onSearch: PropTypes.Requireable<(...args: any[]) => any>;
            onExport: PropTypes.Requireable<(...args: any[]) => any>;
            onReset: any;
        };
        handleReset = () => {
            const { form, onReset } = this.props as any;
            form.resetFields();
            onReset();
        };
        handleSearch = (e: any) => {
            e.preventDefault();
            const { form, onSearch } = this.props as any;
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    if (!isEmpty(values.dateRange)) {
                        const dateFormat = 'YYYY-MM-DD';
                        const dateRange =
                            values.dateRange && values.dateRange.length
                                ? [
                                      moment(values.dateRange[0] || undefined).format(dateFormat),
                                      moment(values.dateRange[1] || undefined).format(dateFormat),
                                  ]
                                : undefined;
                        if (dateRange && dateRange.length === 2) {
                            values.startTime = dateRange[0];
                            values.endTime = dateRange[1];
                            delete values.dateRange;
                        }
                    } else {
                        delete values.dateRange;
                    }
                    onSearch(values);
                }
            });
        };
        handleExport = (e: any) => {
            e.preventDefault();
            const { form, onExport } = this.props as any;
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    if (!isEmpty(values.dateRange)) {
                        const dateFormat = 'YYYY-MM-DD';
                        const dateRange =
                            values.dateRange && values.dateRange.length
                                ? [
                                      moment(values.dateRange[0] || undefined).format(dateFormat),
                                      moment(values.dateRange[1] || undefined).format(dateFormat),
                                  ]
                                : undefined;
                        if (dateRange && dateRange.length === 2) {
                            values.startTime = dateRange[0];
                            values.endTime = dateRange[1];
                            delete values.dateRange;
                        }
                    } else {
                        delete values.dateRange;
                    }
                    onExport(values);
                }
            });
        };
        render() {
            const { form } = this.props as any;
            const { getFieldDecorator } = form;
            return (
                <Form layout="inline" ref="searchFrom">
                    <Form.Item label={language.getText('lastModifiedTime')}>
                        {getFieldDecorator('dateRange', {
                            initialValue: [todayTime, todayTime],
                        })(<DatePicker.RangePicker allowClear={true} />)}
                    </Form.Item>
                    <Form.Item label={language.getText('transactionTypeName')}>
                        {getFieldDecorator('transactionType')(
                            <Select
                                style={{ width: '200px' }}
                                placeholder={services.language.getText('selectPlease')}
                            >
                                <Select.Option value="TRANSACTION_PLAY">
                                    {language.getText('jyfk')}
                                </Select.Option>
                                <Select.Option value="TRANSACTION_REFUND">
                                    {language.getText('jytk')}
                                </Select.Option>
                                <Select.Option value="PAY_ADVERTISING_EXPENSES">
                                    {language.getText('zftgfwf')}
                                </Select.Option>
                                <Select.Option value="RETURN_FREIGHT">
                                    {language.getText('zfbcyf')}
                                </Select.Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('categoryName')}>
                        {getFieldDecorator('category')(
                            <Select
                                style={{ width: '250px' }}
                                placeholder={services.language.getText('selectPlease')}
                            >
                                <Select.Option value="EXPENSES_COLLECT">
                                    {services.language.getText('tgfwfdd')}
                                </Select.Option>
                                <Select.Option value="EXPENSES_COLLECT_BACK">
                                    {services.language.getText('tgfwfbfshsysp')}
                                </Select.Option>
                                <Select.Option value="EXPENSES_REFUND">
                                    {services.language.getText('tgfwfhc')}
                                </Select.Option>
                                <Select.Option value="RETURN_FREIGHT_REFUND">
                                    {services.language.getText('shjhyfbc')}
                                </Select.Option>
                                <Select.Option value="FINANCIAL_TRANSFER">
                                    {language.getText('cwzz')}
                                </Select.Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('statusName')}>
                        {getFieldDecorator('status')(
                            <Select
                                style={{ width: '200px' }}
                                placeholder={services.language.getText('selectPlease')}
                            >
                                <Select.Option value="BILLING_GENERATED">
                                    {language.getText('yfz')}
                                </Select.Option>
                                <Select.Option value="BILLING_NOT_GENERATED">
                                    {language.getText('wfz')}
                                </Select.Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={language.getText('orderNumber')}>
                        {getFieldDecorator('orderNo')(
                            <Input
                                style={{ width: 250 }}
                                placeholder={language.getText('inputOrderNumber')}
                            />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.handleSearch}>
                            {language.getText('common.search')}
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="reset" onClick={this.handleReset}>
                            {language.getText('common.reset')}
                        </Button>
                    </Form.Item>
                    {/* <Form.Item>
                        <Button type="primary" onClick={this.handleExport}>
                            导出
                        </Button>
                    </Form.Item> */}
                </Form>
            );
        }
    }
);

export class JQCapitalFlowPage extends PureComponent {
    formData = React.createRef();
    state = {
        datasource: [],
        searchParams: {},
        sortedInfo: {
            column: {},
            columnKey: '',
            field: '',
            order: '',
        },
        total: 0,
        current: 0,
        pagination: {
            page: 1,
            perPage: 10,
        },
        loading: false,
    };

    componentDidMount() {
        this.onSearchFind({ ...defaultParams });
        // this.onSearch();
    }

    onSearchFind = (searchParams: object = {}) => {
        this.setState(
            {
                pagination: {
                    page: 1,
                    perPage: 10,
                },
            },
            () => {
                this.onSearch(searchParams);
            }
        );
    };

    onSearch = async (searchParams: object = {}) => {
        this.setState({ searchParams: searchParams, loading: true });
        const { pagination } = this.state;

        try {
            let res: responseSuccess = await services.api.get(
                {
                    ...searchParams,
                    ...pagination,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`,
                    apiPath: '/admin/supply_chain/accounts/statements/items',
                }
            );
            if (res && res.result) {
                res.result =
                    res.result?.map((item: OrderVo, index: number) => {
                        return {
                            ...item,
                            id: String(item.id),
                            transactionTypeName: language.getText(
                                transactionTypeName[item.transactionType]
                            ),
                            categoryName: language.getText(transactionTypeName[item.category]),

                            statusName: language.getText(statusName[item.status]),
                        };
                    }) || [];
                this.setState({
                    datasource: res.result,
                    total: res.totalNum,
                    current: res.page,
                    loading: false,
                });
            }
        } catch (error) {
            this.setState({ loading: false });
            errorHandle(error as any);
            throw error;
        }
    };
    pageChange = (pageVal: any) => {
        this.setState({ pagination: { ...pageVal } });
    };
    handleTableChange = async (pagination: any, filters: any, sorter: any) => {
        const { props } = this.formData as any;
        let nValues: any = {};
        if (props && props.form) {
            props.form.validateFields((err: any, values: any) => {
                if (!err) {
                    if (!isEmpty(values.dateRange)) {
                        const dateFormat = 'YYYY-MM-DD';
                        const dateRange =
                            values.dateRange && values.dateRange.length
                                ? [
                                      moment(values.dateRange[0] || undefined).format(dateFormat),
                                      moment(values.dateRange[1] || undefined).format(dateFormat),
                                  ]
                                : undefined;
                        if (dateRange && dateRange.length === 2) {
                            values.startTime = dateRange[0];
                            values.endTime = dateRange[1];
                            delete values.dateRange;
                        }
                    } else {
                        delete values.dateRange;
                    }
                    nValues = values;
                }
            });
        }
        this.setState({ sortedInfo: sorter }, () => {
            this.onSearch(nValues);
        });
    };
    onReset = () => {
        this.setState({ sortedInfo: {} });
    };
    onExport = async (searchParams: object = {}) => {
        try {
            await api.post(
                {
                    ...searchParams,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`,
                    apiPath: '/admin/supply_chain/accounts/financial_transactions/export',
                }
            );
            ForwardDataCenterModal();
        } catch (error) {
            errorHandle(error as any);
            throw error;
        }
    };

    onClickOpen = (e: any, img: string) => {
        if (e) {
            e.stopPropagation();
        }
        if (!img) {
            return;
        }
        let a = window.open(``, '_blank');
        a?.document.write(`<!DOCTYPE html><html><body><img alt='' src='${img}' /></body></html>`);
    };

    getTableColumns = (): ColumnProps<any>[] => {
        return [
            {
                title: language.getText('tenantName'),
                dataIndex: 'tenantName',
                key: 'tenantName',
            },
            {
                title: language.getText('transactionTypeName'),
                dataIndex: 'transactionTypeName',
                key: 'transactionTypeName',
            },
            {
                title: language.getText('statusName'),
                dataIndex: 'statusName',
                key: 'statusName',
            },
            {
                title: language.getText('lastModifiedTime'),
                dataIndex: 'lastModifiedTime',
                key: 'lastModifiedTime',
                width: 180,
            },
            {
                title: language.getText('orderNumber'),
                dataIndex: 'orderNo',
                key: 'orderNo',
            },
            {
                title: language.getText('paymentNo'),
                key: 'paymentNo',
                dataIndex: 'paymentNo',
            },
            {
                title: language.getText('afterSalesOrderNo'),
                dataIndex: 'afterSalesOrderNo',
                key: 'afterSalesOrderNo',
            },
            {
                title: language.getText('orderPaidAmount'),
                key: 'orderPaidAmount',
                dataIndex: 'orderPaidAmount',
            },
            {
                title: language.getText('categoryName'),
                key: 'categoryName',
                dataIndex: 'categoryName',
            },
            {
                title: language.getText('transactionAmount'),
                key: 'transactionAmount',
                dataIndex: 'transactionAmount',
            },
            {
                title: language.getText('accountBalance'),
                key: 'accountBalance',
                dataIndex: 'accountBalance',
            },
            {
                title: language.getText('transactionReceiptFileUrl'),
                key: 'transactionReceiptFileUrl',
                dataIndex: 'transactionReceiptFileUrl',
                render: (val: string) => {
                    if (val) {
                        return (
                            <img
                                width={100}
                                height={100}
                                src={val}
                                alt=""
                                onClick={(e) => this.onClickOpen(e, val)}
                            />
                        );
                    }
                    return null;
                },
            },
            {
                title: language.getText('transferRemark'),
                key: 'transferRemark',
                dataIndex: 'transferRemark',
            },
        ];
    };

    render() {
        const { datasource, pagination, total, current, loading } = this.state;
        const columns = this.getTableColumns();
        return (
            <div className="credit-shop-find">
                <div style={{ marginBottom: 24 }}>
                    <CollectionCreateForm
                        wrappedComponentRef={(form: any) => (this.formData = form)}
                        onSearch={this.onSearchFind}
                        onReset={this.onReset}
                        onExport={this.onExport}
                    />
                </div>
                <Table
                    scroll={{ x: true }}
                    loading={loading}
                    rowKey={(record: OrderVo) => record.id}
                    pagination={{
                        pageSize: pagination.perPage,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        total,
                        current,
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                        onChange: (page: number | undefined, perPage: number | undefined) => {
                            this.pageChange({ page, perPage });
                        },
                        onShowSizeChange: (
                            page: number | undefined,
                            perPage: number | undefined
                        ) => {
                            this.pageChange({ page, perPage });
                        },
                    }}
                    onChange={this.handleTableChange}
                    columns={columns}
                    dataSource={datasource}
                />
            </div>
        );
    }
}
