import * as React from 'react';
import moment from 'moment';
import { Button } from 'antd';
import { ObjectMode } from '@comall-backend-builder/types';
import { TimeRangePicker as BaseTimeRangePicker } from '@comall-backend-builder/components-basis';
import { globalConfig } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

export class ObjectTimeRangePlusMode extends ObjectMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        let { start, end } = value || {};
        if (start && end) {
            const format = displayInfo.format;
            if (format) {
                const monthFormat = globalConfig.get('format.month');
                start = moment(start, monthFormat).format(format);
                end = moment(end, monthFormat).format(format);
            }
            return super.getAvailableDisplayComponent(`${start} ~ ${end}`, displayInfo);
        } else {
            return super.getNotAvailableDisplayComponent(displayInfo);
        }
    }

    /**
     * 获取输入组件，日期的选择区间
     */
    public getControlComponent(controlInfo: any) {
        return <TimeRangePickerPlus {...controlInfo} />;
    }
}

class TimeRangePicker extends BaseTimeRangePicker {
    componentWillReceiveProps(nextProps: any) {
        const { newValue } = nextProps;
        const { value } = this.props;
        if (!newValue && !!value) {
            this.startString = '';
            this.endString = '';
        }
    }
}

class TimeRangePickerPlus extends React.Component<any> {
    clear = () => {
        const { onChange, name } = this.props;
        onChange && onChange(undefined, name);
    };
    render() {
        return (
            <div style={{ display: 'flex', alignItems: 'center' }}>
                <TimeRangePicker {...(this.props as any)} />
                <Button onClick={this.clear} type="link">
                    {language.getText('common.clear')}
                </Button>
            </div>
        );
    }
}
