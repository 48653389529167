import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { api, errorHandle, language } from '@comall-backend-builder/core/lib/services';
import { Button, Checkbox, Table, Select, message, Popover } from 'antd';
import './index.less';
import { services } from '@comall-backend-builder/core';
const { Option } = Select;

interface GetRes {
    id: string;
    rule_list: any[];
    status: string;
}
const dataTypes: any = {
    TEXT: 'wb_1',
    SINGLE_CHOICE: 'dxx',
    MULTIPLE_CHOICE: 'dx_1',
    DATE: 'date',
    REGION: 'ssq',
};
/**
 * 用户资料管理
 */
class memberProfileItemsTable extends PureComponent<any, any> {
    state = {
        setList: [],
        uploadModalVisible: false,
    };

    componentDidMount() {
        this.getInitData();
    }
    onChange = (i: any, v: any, type: string) => {
        const { setList } = this.state;
        if (type === 'defaultValue') {
            setList.forEach((item: any) => {
                if (item.id === i.id) {
                    item[type] = v;
                }
            });
        } else if (type === 'used' || type === 'required') {
            setList.forEach((item: any) => {
                if (item.id === i.id) {
                    item[type] = v.target.checked;
                }
            });
        }
        this.setState({ setList: setList });
    };
    onOk = () => {
        const { setList } = this.state;
        api.put(
            { memberProfileItems: setList },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                apiPath: `/admin/member_profile_items`,
            }
        )
            .then((data: any) => {
                message.success(language.getText('common.saveSuccess'));
            })
            .catch((error) => {
                errorHandle(error);
            });
    };
    getInitData() {
        api.get<GetRes>(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                apiPath: `/admin/member_profile_items?page=1&per_page=20`,
            }
        )
            .then((data: any) => {
                if (data?.memberProfileItems.length) {
                    this.setState({
                        setList: data.memberProfileItems,
                    });
                }
            })
            .catch((error) => {
                errorHandle(error);
            });
    }
    getTableConfig = () => {
        let config: any = {
            rowKey: 'id',
            columns: [
                {
                    title: language.getText('otherBaseInfo'),
                    width: 300,
                    dataIndex: 'name',
                    key: 'name',
                    render: (v: string, i: any) => {
                        if (v === language.getText('zjlx')) {
                            return (
                                <Popover
                                    placement="right"
                                    content={services.language.getText('mrwzysfz')}
                                >
                                    <span>
                                        <span>{v} </span>
                                        <span className="member-profile-items-icon">?</span>
                                    </span>
                                </Popover>
                            );
                        }
                        return <span>{v}</span>;
                    },
                },
                {
                    title: language.getText('sy_1'),
                    dataIndex: 'used',
                    key: 'used',
                    render: (v: string, i: any) => (
                        <span>
                            <Checkbox
                                disabled={i.baseField}
                                defaultChecked={!!v}
                                onChange={(value: any) => this.onChange(i, value, 'used')}
                            />
                        </span>
                    ),
                },
                {
                    title: language.getText('bt'),
                    dataIndex: 'required',
                    key: 'required',
                    render: (v: string, i: any) => (
                        <span>
                            <Checkbox
                                disabled={i.baseField}
                                defaultChecked={!!v}
                                onChange={(value: any) => this.onChange(i, value, 'required')}
                            />
                        </span>
                    ),
                },
                {
                    title: language.getText('mrz'),
                    dataIndex: 'dataDicts',
                    key: 'dataDicts',
                    render: (v: any, i: any) => {
                        if (i.dataType === 'SINGLE_CHOICE' && v.length > 0) {
                            return (
                                <Select
                                    style={{ width: 120 }}
                                    defaultValue={i.defaultValue}
                                    onChange={(value: any) =>
                                        this.onChange(i, value, 'defaultValue')
                                    }
                                >
                                    {v.map((item: any) => {
                                        return <Option value={item.id}>{item.value}</Option>;
                                    })}
                                </Select>
                            );
                        }
                        return <span>-</span>;
                    },
                },
                {
                    title: language.getText('sjlx_1'),
                    dataIndex: 'dataType',
                    key: 'dataType',
                    render: (v: string, i: any) => <span>{language.getText(dataTypes[v])}</span>,
                },
            ],
        };
        return config;
    };
    render() {
        const { setList } = this.state;
        let tableConfig = this.getTableConfig();
        const tableProps = { ...tableConfig, dataSource: setList };
        return (
            <div className="member-profile-items">
                <div className="member-profile-items-tips">
                    {language.getText('jyxxxpzhzhy')} -&gt; {language.getText('zhpz')} -&gt;
                    {language.getText('wsxxzzs')}
                </div>
                <Table
                    className="member-profile-items-table"
                    {...tableProps}
                    pagination={false}
                ></Table>
                <div className="member-profile-items-btn">
                    <Button type="primary" onClick={this.onOk}>
                        {language.getText('common.save')}
                    </Button>
                </div>
            </div>
        );
    }
}
function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
    };
}

export const MemberProfileItemsTable = connect(mapStateToProps)(memberProfileItemsTable);
