import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export let config: Config = {
    components: {
        MerchantBookkeepingBillView: {
            component: 'Viewport',
            entity: 'MerchantBookkeepingBillEntity',
        },
        MerchantBookkeepingBillPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [{ component: 'MerchantBookkeepingBillTable' }],
        },
        MerchantBookkeepingBillTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'MerchantBookkeepingBillFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'ExportBillButton',
                                    params: {
                                        apiRoot: `${ENV.AUTH_API_ROOT}/DATA-STATISTICS`,
                                        apiPath:
                                            '/admin/merchant_bookkeeping_bills/merchant_bookkeeping_bills.xls',
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: 'MerchantBookkeepingBillDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: { x: true },
                    columns: [
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'merchantName',
                        },
                        {
                            property: 'totalSalesAmount',
                        },
                        {
                            property: 'totalRefundAmount',
                        },
                        {
                            property: 'totalGoodsAmount',
                        },
                        {
                            property: 'totalFreightAmount',
                        },
                        {
                            property: 'totalCouponAmount',
                        },
                        {
                            property: 'totalBalanceCouponAmount',
                        },
                        {
                            property: 'totalPointAmount',
                        },
                        {
                            property: 'totalGoldAmount',
                        },
                        {
                            property: 'totalPlatformIncome',
                        },
                        {
                            property: 'totalProcedureFee',
                        },
                        {
                            property: 'totalMerchantSettlementAmount',
                        },
                    ],
                },
            ],
        },
        MerchantBookkeepingBillFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'dateRange',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/merchant-bookkeeping-bill',
                    breadcrumbName: '<<merchantBookkeepingBill>>',
                    component: 'MerchantBookkeepingBillView',
                    privilege: {
                        path: 'merchantBookkeepingBill',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'MerchantBookkeepingBillPage' }],
                },
            ],
        },
    ],
};
