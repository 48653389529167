import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;
export const couponBalanceSaleLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/DATA-STATISTICS`;
        config.apiPath = '/admin/coupon_recharge_records';
        if (!isEmpty(data.effectiveDateRange)) {
            data.couponStartTime = data.effectiveDateRange.start + ' 00:00:00';
            data.couponEndTime = data.effectiveDateRange.end + ' 23:59:59';
            delete data.effectiveDateRange;
        }
        if (!isEmpty(data.orderDateRange)) {
            data.orderStartTime = data.orderDateRange.start + ' 00:00:00';
            data.orderEndTime = data.orderDateRange.end + ' 23:59:59';
            delete data.orderDateRange;
        }
        if (data.status === 'ALL') {
            delete data.status;
        }
        if (data.orderType === 'ALL') {
            delete data.orderType;
        }
        return await api.get(data, config);
    },
};
