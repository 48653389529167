import React, { PureComponent } from 'react';
import { Input, Form, Switch } from 'antd';
import { services } from '@comall-backend-builder/core';
import { tools } from '@comall-backend-builder/design-components-basis';
import PresaleMore from './presale-more';
import { PresaleShowContent } from './show-content';
import './index.less';

export interface PresaleMoreConfig {
    image: string;
    title: string;
    color: string;
    linkParams: any;
    linkType: string;
}

const FormItem = Form.Item;
const language = services.language;
const UploadPicture = tools.UploadPicture;
const ColorPicker = tools.ColorPicker;
/**
 * 抢购组件-配置表单
 */
class presale extends PureComponent<any> {
    productMoreChange = (value: PresaleMoreConfig) => {
        const { getFieldsValue } = this.props.form;
        const { onChange } = this.props;
        onChange({ ...getFieldsValue(), more: value });
    };

    render() {
        const { selector, form, sourceRoot, uploadAction, linkTypes } = this.props;

        const { getFieldDecorator, getFieldValue } = form;
        const formItemLayout = tools.FormItemLayout;
        return (
            <Form className="editor-presale">
                <FormItem {...formItemLayout} label={language.getText('components.Presale.title')}>
                    <div>
                        {getFieldDecorator('title.text', {
                            initialValue: '限时秒杀',
                        })(<Input />)}
                    </div>
                </FormItem>
                <FormItem {...formItemLayout}>
                    <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-5"></div>
                    <div className="title-color">
                        {getFieldDecorator('title.color')(<ColorPicker />)}
                    </div>
                </FormItem>
                <FormItem {...formItemLayout}>
                    <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-5"></div>
                    <div className="image-wrap">
                        {getFieldDecorator('title.image')(
                            <UploadPicture
                                className="image-uploader"
                                sourceRoot={sourceRoot}
                                uploadAction={uploadAction}
                            />
                        )}
                        <span>{language.getText('components.Presale.titleImageSize')}</span>
                    </div>
                </FormItem>
                <FormItem {...formItemLayout} label={language.getText('components.Presale.more')}>
                    <div>
                        <div>
                            {getFieldDecorator('showMore', { valuePropName: 'checked' })(
                                <Switch />
                            )}
                        </div>
                    </div>
                </FormItem>
                {getFieldValue('showMore') && (
                    <FormItem
                        {...formItemLayout}
                        label={language.getText('components.Presale.moreConfig')}
                    >
                        <PresaleMore
                            sourceRoot={sourceRoot}
                            value={this.props.value.more}
                            onChange={this.productMoreChange}
                            selector={selector}
                            uploadAction={uploadAction}
                            linkTypes={linkTypes}
                        />
                    </FormItem>
                )}
                <FormItem
                    {...formItemLayout}
                    label={language.getText('components.Presale.backgroudImage')}
                >
                    <div>{getFieldDecorator('background.color')(<ColorPicker />)}</div>
                </FormItem>
                <FormItem {...formItemLayout}>
                    <div className="ant-col ant-form-item-label ant-col-xs-24 ant-col-sm-5"></div>
                    <div className="image-wrap">
                        {getFieldDecorator('background.image')(
                            <UploadPicture
                                className="image-uploader"
                                sourceRoot={sourceRoot}
                                uploadAction={uploadAction}
                            />
                        )}
                        <span>{language.getText('components.Presale.backgroudImageSize')}</span>
                    </div>
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={language.getText('components.Presale.showContent')}
                >
                    <div>
                        {getFieldDecorator('showContent', {
                            initialValue: ['title', 'salePrice', 'marketPrice'],
                        })(<PresaleShowContent form={form} />)}
                    </div>
                </FormItem>
            </Form>
        );
    }
}

export const Presale = tools.ConfigFormDecorator(presale);
