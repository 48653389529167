import React, { PureComponent, createElement, Fragment } from 'react';
// import { api } from '@comall-backend-builder/core/lib/services';
import { errorHandle } from '../../../applications/cae/services/error-handle';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { connect } from 'react-redux';
import {
    message as AntMessage,
    Modal,
    Row,
    Col,
    Button,
    // Descriptions,
} from 'antd';
import { debounce, get } from 'lodash';
import { api, language } from '@comall-backend-builder/core/lib/services';
import './index.less';

class SignatureNewMallActivityView extends PureComponent<any> {
    state = {
        visible: false,
        ticketVisible: false,
        reviewInfo: [],
        loading: false,
        ticketRoundInfos: [],
        values: {
            data: '',
            time: '',
        },
        changedTicketId: '',
    };
    openModal = () => {
        this.loadData();
    };

    loadData = async () => {
        const { id } = this.props.row;
        try {
            let reviewInfo: any = await api.get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
                    apiPath: `/admin/v1/mall_activity/ticket_purchase_records/${id}/allow_change_tickets`,
                }
            );
            if (reviewInfo.length) {
                this.setState({
                    // dateArray,
                    reviewInfo: reviewInfo,
                    visible: true,
                });
            }
        } catch (error) {
            errorHandle(error as any);
            throw error;
        }
    };

    onSubmit = debounce(async () => {
        const { changedTicketId } = this.state;
        const { entity, params: entityParams, row } = this.props;
        // const merchantId = subsite.mchId;
        const param: Record<string, string> = {
            changedTicketId: changedTicketId,
        };
        this.setState({
            ticketVisible: false,
            loading: true,
        });
        try {
            await api.put(
                { ...param },
                {
                    apiPath: `/admin/v1/mall_activity/ticket_purchase_records/${row.id}/change_ticket`,
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
                }
            );
        } catch (error) {
            errorHandle(error as any);
            throw error;
        }
        AntMessage.success('改签成功');
        entity && entity.search(entityParams);
        this.setState({
            loading: false,
            ticketRoundInfos: [],
            values: {
                data: '',
                time: '',
            },
            changedTicketId: '',
        });
    }, 1000);
    //@ts-ignore
    listenRef: {
        handleSubmit: () => Promise<any>;
        handleReset: () => Promise<any>;
    };
    onSignatureOk = () => {
        const { changedTicketId } = this.state;
        if (changedTicketId) {
            this.setState({ visible: false, ticketVisible: true });
        } else {
            AntMessage.warning('请选择场次');
        }
    };
    splitDates = (startDate: string, endDate: string) => {
        let dates = [];
        let currentDate = new Date(startDate);

        while (currentDate <= new Date(endDate)) {
            dates.push(new Date(currentDate).toISOString().split('T')[0]);
            currentDate.setDate(currentDate.getDate() + 1);
        }
        return dates;
    };
    onDateClick = (v: any) => {
        const { values } = this.state;
        this.setState({
            values: {
                ...values,
                data: v.startDate,
                time: '',
            },
            ticketRoundInfos: v.ticketRoundInfos,
            changedTicketId: '',
        });
    };
    onTimeClick = (v: any) => {
        const { values } = this.state;
        this.setState({
            values: {
                ...values,
                time: `${v.startTime}-${v.endTime}`,
            },
            changedTicketId: v.tickets[0].id,
        });
    };
    render() {
        const { triggerComponent, row } = this.props;
        const {
            visible,
            loading,
            ticketVisible,
            reviewInfo = [],
            ticketRoundInfos,
            values,
        } = this.state;
        const buttonProps = {
            ...triggerComponent,
            onClick: this.openModal,
        };

        return (
            <Fragment>
                {row.orderStatus === 'AUDIT_APPROVED'
                    ? createElement(ComponentsManager.get('Button'), buttonProps)
                    : null}
                <Modal
                    visible={visible}
                    className="signature-new-mall-activity-view"
                    title="改签"
                    mask={false}
                    maskClosable
                    okText={language.getText('common.ok')}
                    cancelText={language.getText('common.cancel')}
                    width="680px"
                    okButtonProps={{ loading }}
                    onOk={() => {
                        // this.listenRef.handleSubmit();
                        // this.onSubmit();
                        this.onSignatureOk();
                    }}
                    onCancel={() => {
                        // this.listenRef.handleReset();
                        this.setState({ visible: false });
                    }}
                >
                    <Row>
                        <Col span={4}>
                            <div className="label-tit">票档：</div>
                        </Col>
                        <Col span={20}>
                            <p className="content">
                                <Button type="primary">{get(row, 'ticketName') || '-'}</Button>
                            </p>
                        </Col>
                        <Col span={4}>
                            <div className="label-tit">日期：</div>
                        </Col>
                        <Col span={20}>
                            <p className="data">
                                {reviewInfo.map((d: any) => {
                                    const btn = this.onDateClick.bind(this, d);
                                    const type =
                                        values.data === d.startDate ? 'primary' : 'default';

                                    return (
                                        <Button type={type} onClick={btn}>
                                            {d.startDate}
                                        </Button>
                                    );
                                })}
                            </p>
                        </Col>
                        <Col span={4}>
                            <div className="label-tit">场次：</div>
                        </Col>
                        <Col span={20}>
                            <p className="time">
                                {ticketRoundInfos &&
                                    ticketRoundInfos.map((t: any) => {
                                        const timeBtn = this.onTimeClick.bind(this, t);
                                        const type =
                                            values.time === `${t.startTime}-${t.endTime}`
                                                ? 'primary'
                                                : 'default';

                                        return (
                                            <Button type={type} onClick={timeBtn}>
                                                {t.startTime}
                                                {t.endTime ? '-' + t.endTime : ''}(余
                                                {t.availableStock})
                                            </Button>
                                        );
                                    })}
                            </p>
                        </Col>
                        <Col span={4}>
                            <div className="label-tit">数量：</div>
                        </Col>
                        <Col span={20}>
                            <p style={{ lineHeight: '32px' }}>
                                {get(row, 'ticketsPurchasedQuantity') || '-'}
                            </p>
                        </Col>
                    </Row>
                </Modal>
                <Modal
                    visible={ticketVisible}
                    className="signature-new-mall-activity-view"
                    title="提示"
                    mask={false}
                    maskClosable
                    okText={language.getText('confirmgq')}
                    cancelText={language.getText('common.cancel')}
                    width="680px"
                    onOk={() => {
                        this.onSubmit();
                    }}
                    onCancel={() => {
                        this.setState({ ticketVisible: false });
                    }}
                >
                    <div>
                        您确定要将【{row.ticketName}】由【{get(row, 'joinTime') || ''}】
                        {services.language.getText('gqd')}【{values.data} {values.time}｜
                        {get(row, 'ticketsPurchasedQuantity') || ''}
                        张】吗？
                    </div>
                </Modal>
            </Fragment>
        );
    }
}

function mapStateToProps(_state: any, props: any) {
    const { entity } = props;
    return { fields: entity.fields, entity };
}
export const SignatureNewMallActivityButton = connect(
    mapStateToProps,
    null
)(SignatureNewMallActivityView);
