import React, { Component } from 'react';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { services } from '@comall-backend-builder/core';
import { InputNumber, Checkbox } from 'antd';
import { cloneDeep, remove } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';
import './index.less';
import { NewCouponSelector } from '../new-coupon-selector';
import { Coupon } from '../new-coupon-selector/select-coupon';
import { TipsModal, TipsModalProps } from '../../components';

enum AssetRewardRewardType {
    POINT = 'POINT',
    EXPERIENCE = 'EXPERIENCE',
    GOLD = 'GOLD',
}

type RewardType = AssetRewardRewardType | 'COUPON';

export interface AssetRewards {
    rewardType: AssetRewardRewardType;
    rewardValue?: number | undefined;
}

export interface ActivityRewardBaseValue {
    assetRewards: Array<AssetRewards>;
    couponRewards?: Array<Coupon>;
}

interface RenderRewardConfig {
    name: string;
    value: number | undefined;
    selected: boolean;
    disabled: boolean;
    onSelect: (event: any) => void;
    onInput: (value: number | undefined) => void;
}

interface RenderCouponsConfig {
    name: string;
    value: Coupon[] | undefined;
    selected: boolean;
    disabled: boolean;
    onSelect: (event: any) => void;
}

/**
 * 后端接口返回的目前支持的选项
 */
enum RewardItems {
    point = 'POINT',
    gold = 'GOLD',
    experience = 'EXPERIENCE',
    coupons = 'COUPON',
}

interface RewardOption {
    code: RewardItems;
    name: string;
}

interface ActivityRewardBaseProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    value?: ActivityRewardBaseValue;
    /**
     * 候选项集合
     */
    options: Array<RewardOption>;
    optionsConfig?: ApiRequestConfig & {
        params?: Record<string, string>;
    };
    getSubsiteIds?: (row: Record<string, any>) => string;
    /**
     * 是否禁用（禁用只限制修改阶梯值、赠删阶梯，不限制修改阶梯内奖励内容）
     */
    disabled: boolean;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: ActivityRewardBaseValue, name: string) => void;
    row: Record<string, any>;
    tipsModalConfig: ActivityRewardTipsModalProps;
    /**
     * 限制券包不可调整数量
     */
    restrictPackage?: boolean;
}

interface ActivityRewardTipsModalProps extends TipsModalProps {
    position?: 'top' | 'bottom';
    extraConfig?: {
        before?: string;
        after?: string;
    };
}

interface ActivityRewardBaseStates {
    options: Array<RewardOption>;
}

export class ActivityRewardBase extends Component<
    ActivityRewardBaseProps,
    ActivityRewardBaseStates
> {
    constructor(props: ActivityRewardBaseProps) {
        super(props);
        this.state = {
            options: this.props?.options || [],
        };
    }
    componentDidMount() {
        this.getOptions();
    }

    getOptions = async () => {
        const { optionsConfig } = this.props;
        if (!optionsConfig) {
            return;
        }
        const { apiPath, apiRoot, params = {} } = optionsConfig;
        const options: RewardOption[] = await services.api.get(params, { apiPath, apiRoot });
        this.setState({ options });
    };

    coverValue = (): ActivityRewardBaseValue => {
        const { value = { assetRewards: [], couponRewards: undefined } } = this.props;
        return cloneDeep(value);
    };

    changeValue = (value: ActivityRewardBaseValue) => {
        const { name, onChange } = this.props;
        if (onChange) {
            onChange(value, name);
        }
    };

    onInputValueChange(number: number | undefined, rewardType: RewardType) {
        const value = this.coverValue();
        const reward = value.assetRewards.find((item) => item.rewardType === rewardType);
        if (reward) {
            reward.rewardValue = number;
        }
        this.changeValue(value);
    }

    onCheckboxChange(checked: boolean, rewardType: RewardType) {
        const value = this.coverValue();
        if (rewardType === 'COUPON') {
            value.couponRewards = checked ? [] : undefined;
        } else if (checked) {
            value.assetRewards.push({ rewardType });
        } else {
            remove(value.assetRewards, (item) => item.rewardType === rewardType);
        }
        this.changeValue(value);
    }

    onChangeCoupon = (coupons: Coupon[]) => {
        const value = this.coverValue();
        const couponRewards = coupons.map((item) => {
            if (item.type === 'PACKAGE') {
                item.quantity = 1;
            }
            return item;
        });
        value.couponRewards = couponRewards;
        this.changeValue(value);
    };

    private get subsiteIds() {
        const { getSubsiteIds, row } = this.props;

        if (!getSubsiteIds) {
            return '';
        }
        return getSubsiteIds(row);
    }

    private get rewards() {
        const { value: { assetRewards = [], couponRewards } = {}, disabled } = this.props;
        const { options } = this.state;
        const findOption = (type: RewardItems) => options?.find((item) => item.code === type);
        const findReward = (type: AssetRewardRewardType) =>
            assetRewards.find((item) => item.rewardType === type);
        const config = (
            option: RewardOption | undefined,
            valueType: AssetRewardRewardType
        ): RenderRewardConfig | null => {
            if (!option) {
                return null;
            }
            const reward = findReward(valueType);
            const selected = Boolean(reward);
            const onSelect = (e: any) => this.onCheckboxChange(e.target.checked, valueType);
            const onInput = (value: number | undefined) =>
                this.onInputValueChange(value, valueType);
            return {
                name: option.name,
                value: reward?.rewardValue,
                selected,
                disabled,
                onSelect,
                onInput,
            };
        };
        const coupon = findOption(RewardItems.coupons);
        return {
            point: config(findOption(RewardItems.point), AssetRewardRewardType.POINT),
            gold: config(findOption(RewardItems.gold), AssetRewardRewardType.GOLD),
            experience: config(
                findOption(RewardItems.experience),
                AssetRewardRewardType.EXPERIENCE
            ),
            coupons: coupon
                ? {
                      name: coupon.name,
                      value: couponRewards,
                      selected: Boolean(couponRewards),
                      disabled,
                      onSelect: (e: any) => this.onCheckboxChange(e.target.checked, 'COUPON'),
                  }
                : null,
        };
    }

    renderReward = (reward: RenderRewardConfig | null) => {
        if (!reward) {
            return null;
        }
        const { value, name, selected, disabled, onSelect, onInput } = reward;
        return (
            <div className="reward-content-item">
                <Checkbox
                    className="reward-content-item-checkbox"
                    onChange={onSelect}
                    checked={selected}
                    value="point"
                    disabled={disabled}
                >
                    {language.getText('jl_2')}
                </Checkbox>
                <InputNumber
                    type="number"
                    min={1}
                    precision={0}
                    value={value}
                    onChange={onInput}
                    disabled={disabled || !selected}
                />
                <span className="reward-item-text">{name}</span>
            </div>
        );
    };

    renderCoupons = (coupon: RenderCouponsConfig | null) => {
        if (!coupon) {
            return null;
        }
        const { restrictPackage } = this.props;
        const { value, selected, disabled, onSelect } = coupon;
        const subsiteIds = this.subsiteIds;
        const isPackage =
            value && restrictPackage ? value.some((c) => c.type === 'PACKAGE') : false;
        return (
            <div className="reward-content-item">
                <Checkbox
                    className="reward-content-item-checkbox"
                    onChange={onSelect}
                    checked={selected}
                    value="point"
                    disabled={disabled}
                >
                    {language.getText('yhq')}
                </Checkbox>
                <NewCouponSelector
                    disabled={disabled || !selected}
                    value={value}
                    onChange={this.onChangeCoupon}
                    subsiteIds={subsiteIds}
                    inputNumberProps={{
                        min: 1,
                        precision: 0,
                        disabled: isPackage,
                    }}
                />
            </div>
        );
    };

    renderTipsModal = (currentPosition: 'top' | 'bottom') => {
        const { tipsModalConfig } = this.props;
        if (tipsModalConfig) {
            const { position = 'bottom', extraConfig = {} } = tipsModalConfig;
            const { before, after } = extraConfig;
            if (position === currentPosition) {
                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: 5,
                            color: '#ccc',
                        }}
                    >
                        {before && <span style={{ marginRight: 5 }}>{before}</span>}
                        <TipsModal {...tipsModalConfig} />
                        {after && <span style={{ marginLeft: 5 }}>{after}</span>}
                    </div>
                );
            }
        }
    };

    render() {
        const { point, gold, experience, coupons } = this.rewards;
        return (
            <div className="activity-reward-base">
                {this.renderTipsModal('top')}
                <div className="reward-content-list">
                    {this.renderReward(point)}
                    {this.renderReward(gold)}
                    {this.renderReward(experience)}
                </div>
                {coupons && (
                    <div className="reward-content-list">{this.renderCoupons(coupons)}</div>
                )}
                {this.renderTipsModal('bottom')}
            </div>
        );
    }
}
