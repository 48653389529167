import { Form as AntForm, Modal, message, Input, Radio } from 'antd';
import { FormLabelAlign } from 'antd/lib/form/FormItem';
import React, { FC, useState, useEffect, Fragment } from 'react';
import { LinkTypePlus } from '../../applications/design/components';
import { newClientCustomSelectorConfig } from '../../applications/design/design';
import {
    getNewLinkTypeEffectPathConfigList,
    getPopupLinkTypeEffectPathConfigList,
} from '../link-type-effect-path/config';
import { SingleImageUpload } from '../single-image-upload';
import { checkLinkTypeParamsRequired } from '../../types/format';

import {
    FloatingActionButton,
    FloatingActionType,
    CustomFloatingActionButtonLink,
    CustomLinkType,
} from './common';
import { services } from '@comall-backend-builder/core';

const { getText } = services.language;

const labelCol = { span: 6 };
const wrapperCol = { span: 18 };

const formItemProps = {
    labelAlign: 'left' as FormLabelAlign,
    labelCol,
    wrapperCol,
};

interface FloatingActionButtonsSettingModalProps {
    button: FloatingActionButton;
    onSubmit: (value: FloatingActionButton) => void;
    onCancel: () => void;
    visible: boolean;
    subsiteIds: string[];
}

export const FloatingActionButtonsSettingModal: FC<FloatingActionButtonsSettingModalProps> = (
    props
) => {
    const { button, onSubmit, visible, onCancel, subsiteIds } = props;
    const [value, setValue] = useState<FloatingActionButton>(button);
    const { name, icon, link, type } = value;
    useEffect(() => {
        setValue(button);
    }, [button]);
    const onOk = () => {
        if (type === FloatingActionType.Custom && !name) {
            message.warn(getText('qszxfcmc'));
            return;
        }
        if (icon.custom && !icon.url) {
            message.warn(getText('qszxfczdytb'));
            return;
        }
        if (
            type === FloatingActionType.Custom &&
            (!link ||
                !link.linkType ||
                !checkLinkTypeParamsRequired(link.linkType, link.linkParams))
        ) {
            message.warn(getText('qszzdyxfctzlj'));
            return;
        }
        onSubmit(value);
    };
    const onNameChange = (e: any) => {
        setValue({ ...value, name: e.target.value });
    };
    const onIconTypeChange = (e: any) => {
        setValue({ ...value, icon: { custom: e.target.value } });
    };
    const onLinkChange = (val: CustomFloatingActionButtonLink) => {
        setValue({ ...value, link: val });
    };
    const onPictureChange = (url: any, name: string) => {
        setValue({ ...value, icon: { custom: true, url } });
        return {};
    };
    const onCannel = () => {
        onCancel();
    };
    let title = name,
        ModalContent = (
            <AntForm.Item {...formItemProps} label={getText('tb_1')}>
                <Radio.Group value={icon.custom} onChange={onIconTypeChange}>
                    <Radio value={false}>{getText('mrtb')}</Radio>
                    <Radio value={true}>{getText('zdytb')}</Radio>
                </Radio.Group>
                {icon.custom && <CustomIcon value={icon.url} onChange={onPictureChange} />}
            </AntForm.Item>
        );
    if (value.type === FloatingActionType.Custom) {
        title = getText('zdyxfc');
        ModalContent = (
            <Fragment>
                <AntForm.Item {...formItemProps} label={getText('windowName')}>
                    <Input maxLength={4} value={name} onChange={onNameChange} />
                </AntForm.Item>
                <AntForm.Item {...formItemProps} label={getText('cktb')}>
                    <CustomIcon value={icon.url} onChange={onPictureChange} />
                </AntForm.Item>
                <AntForm.Item {...formItemProps} label={getText('zslx')}>
                    <CustomLink subsiteIds={subsiteIds} link={link} onChange={onLinkChange} />
                </AntForm.Item>
            </Fragment>
        );
    }
    return (
        <Modal
            visible={visible}
            onOk={onOk}
            onCancel={onCannel}
            okText={getText('common.ok')}
            cancelText={getText('common.cancel')}
            title={title}
            destroyOnClose
        >
            {ModalContent}
        </Modal>
    );
};

const CustomIcon = (props: {
    value: string | undefined;
    onChange: (value: any, name: string) => {};
}) => {
    const { value, onChange } = props;
    return (
        <div>
            <SingleImageUpload value={value} onChange={onChange} />
            <span>{getText('h_jycc64')}</span>
        </div>
    );
};

const CustomLink = (props: {
    link: CustomFloatingActionButtonLink | undefined;
    onChange: (value: CustomFloatingActionButtonLink) => void;
    subsiteIds: string[];
}) => {
    const { link, onChange, subsiteIds } = props;
    const popupLinkTypeEffectPathConfigList = getPopupLinkTypeEffectPathConfigList();
    let type = CustomLinkType.Navigator;
    if (link && popupLinkTypeEffectPathConfigList.some((item) => item.id === link.linkType)) {
        type = CustomLinkType.Popup;
    }
    const [linkType, setLinkType] = useState(type);
    useEffect(() => {
        if (link && popupLinkTypeEffectPathConfigList.some((item) => item.id === link.linkType)) {
            setLinkType(CustomLinkType.Popup);
        }
    }, [link, popupLinkTypeEffectPathConfigList]);
    if (!link) {
        return null;
    }
    const onLinkTypeChange = (e: any) => {
        setLinkType(e.target.value);
        onChange({ linkType: '', linkParams: '' });
    };
    const linkTypes = {
        [CustomLinkType.Navigator]: getNewLinkTypeEffectPathConfigList(),
        [CustomLinkType.Popup]: popupLinkTypeEffectPathConfigList,
    }[linkType];
    if (!subsiteIds.length) {
        return <span>{getText('selectBeforeMerchant')}</span>;
    }
    return (
        <Fragment>
            <Radio.Group
                style={{ marginBottom: '16px' }}
                value={linkType}
                onChange={onLinkTypeChange}
            >
                <Radio.Button value={CustomLinkType.Navigator}>{getText('tzlj')}</Radio.Button>
                <Radio.Button value={CustomLinkType.Popup}>{getText('dcts')}</Radio.Button>
            </Radio.Group>
            <LinkTypePlus
                selector={newClientCustomSelectorConfig as any}
                linkTypes={linkTypes}
                linkTypeMode="cascader"
                value={link}
                onChange={onChange}
                subsiteIds={subsiteIds}
            />
        </Fragment>
    );
};
