import { builder } from '@comall-backend-builder/core';
import { BuyLimitLoader } from './buy-limit';
import { SubsitePickUpDeliveryLoader } from './subsite-pick-up-delivery-loader';
import { SubsiteCitywideDeliveryLoader } from './subsite-citywide-delivery-loader';
import { SubsiteExpressDeliveryLoader } from './subsite-express-delivery-loader';
import { MerchantPickUpDeliveryLoader } from './merchant-pick-up-delivery-loader';
import { MerchantExpressDeliveryLoader } from './merchant-express-delivery-loader';
import { DeliverySubsitesMerchantsLoader } from './delivery-subsites-merchants-loader';
import { CreditEshopPickUpDeliveryLoader } from './credit-eshop-pick-up-delivery-loader';
import { CreditEshopExpressDeliveryLoader } from './credit-eshop-express-delivery-loader';

builder.registerLoader('/admin/buy_limit_rules', BuyLimitLoader);
builder.registerLoader('/admin/subsite_pick_up_delivery', SubsitePickUpDeliveryLoader);
builder.registerLoader('/admin/subsite_citywidh_delivery', SubsiteCitywideDeliveryLoader);
builder.registerLoader('/admin/subsite_express_delivery', SubsiteExpressDeliveryLoader);
builder.registerLoader('/admin/merchant_pick_up_delivery', MerchantPickUpDeliveryLoader);
builder.registerLoader('/admin/merchant_express_delivery', MerchantExpressDeliveryLoader);
builder.registerLoader('/admin/delivery_subsites_merchants', DeliverySubsitesMerchantsLoader);
builder.registerLoader('/admin/credit_eshop_pick_up_delivery', CreditEshopPickUpDeliveryLoader);
builder.registerLoader('/admin/credit_eshop_express_delivery', CreditEshopExpressDeliveryLoader);
