import { reduce } from 'lodash';
import { ArrayFormat } from '@comall-backend-builder/types';

export class ArrayMemberLevelParkBenefitFormat extends ArrayFormat {
    /**
     * 将数据格式化为请求参数
     */
    public formatParams(key: number, value: any) {
        return {
            [key]: reduce<any, any>(
                value,
                (result, rule) => {
                    if (
                        !!rule.memberLevelId &&
                        (!!rule.amount || rule.amount === 0) &&
                        !!rule.deductionAmount
                    ) {
                        result.push(rule);
                    }
                    return result;
                },
                []
            ),
        };
    }

    /**
     * 对数据进行校验
     */
    public validate(_rule: any, _value: any, callback: any) {
        callback();
    }
}
