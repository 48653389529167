import React, { Fragment, PureComponent } from 'react';
import { Button } from '@comall-backend-builder/components-basis';
import { UploadFile } from './upload-file';
import { AddRelationMobileModal } from './add-relation-mobile-modal';
import './index.less';
import { services } from '@comall-backend-builder/core';

let internalPurchaseEnterpriseId: any;
export { internalPurchaseEnterpriseId };
export class EnterpriseRelationMobileManageButton extends PureComponent<any> {
    state = { uploadModalVisible: false, relationMobileModalVisible: false };
    componentDidMount() {
        internalPurchaseEnterpriseId = this.props.params.enterpriseId;
    }
    componentWillUnmount() {
        internalPurchaseEnterpriseId = -1;
    }
    handleUpload = () => {
        this.setState({ uploadModalVisible: true });
    };
    onClose = () => {
        this.setState({ uploadModalVisible: false });
    };

    handleAddRelationMobile = () => {
        this.setState({ relationMobileModalVisible: true });
    };
    onCloseRelationMobileModal = () => {
        const { entity } = this.props;
        this.setState({ relationMobileModalVisible: false });
        entity.search();
    };
    render() {
        let enterpriseId = this.props.params.enterpriseId;
        const { uploadModalVisible, relationMobileModalVisible } = this.state;
        const uploadFileProps = {
            enterpriseId: enterpriseId,
            visible: uploadModalVisible,
            onClose: this.onClose,
            ...this.props,
        };

        let importResultRoute =
            '/menu-list/market/internal-purchase/enterprise/' +
            enterpriseId +
            '/mobile-import-result';
        return (
            <Fragment>
                <div className="batch-button">
                    <Button
                        text={services.language.getText('tjsjh')}
                        type="primary"
                        icon="plus"
                        onClick={this.handleAddRelationMobile}
                    />
                    <Button
                        type="default"
                        style={{
                            marginLeft: 15,
                            paddingLeft: '15px',
                            paddingRight: '15px',
                        }}
                        onClick={this.handleUpload}
                    >
                        {services.language.getText('batchImport')}
                    </Button>
                    <Button
                        type="default"
                        style={{
                            marginLeft: 15,
                            paddingLeft: '15px',
                            paddingRight: '15px',
                        }}
                        route={importResultRoute}
                    >
                        {services.language.getText('importResult')}
                    </Button>
                </div>
                <UploadFile {...uploadFileProps} />
                <AddRelationMobileModal
                    {...this.props}
                    visible={relationMobileModalVisible}
                    onClose={this.onCloseRelationMobileModal}
                />
            </Fragment>
        );
    }
}
