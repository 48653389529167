import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { find } from 'lodash';
import { Icon } from 'antd';
import { Column, Bar, Pie, Line } from '@ant-design/charts';

import './index.less';
import { formatNumber } from '../../services/utils';
import { language } from '@comall-backend-builder/core/lib/services';

class dataPanelsRefundsCardView extends PureComponent<{
    entity: any;
    fields: any;
}> {
    state = {
        value: [],
    };

    // /**
    //  * 格式化数字显示
    //  */
    // formatNumber = (amount: number, tips: string) => {
    //     if (amount > 10000000) return (amount / 10000000).toFixed(2) + '千万' + tips;
    //     if (amount > 10000) return (amount / 10000).toFixed(2) + '万' + tips;
    //     if (amount > 1000)
    //         return Math.floor(amount / 1000) + ',' + Math.floor(amount % 1000) + tips;
    //     if (amount >= 0) return amount + tips;
    //     return '-' + tips;
    // };

    /**
     * 渲染售后金额/人数折线图
     */
    renderRefundsAmountLineChart = (data: any, type: string = 'amount') => {
        const config = {
            data: data ? data : [],
            xField: 'xfieldValue',
            yField: 'yfieldValue',
            seriesField: 'seriesFieldValue',
            yAxis: {
                label: {
                    formatter: function formatter(v: any) {
                        return ''.concat(v).replace(/\d{1,3}(?=(\d{3})+$)/g, function(s) {
                            return ''.concat(s, ',');
                        });
                    },
                },
            },
            color: ['#ff794b', '#bb2f2a', '#FAA219'],
            // meta: {
            //     date: {
            //         type: 'cat',
            //         ticks: [1965, 1970, 1975, 1980, 1985, 1990, 1995, 2000, 2005, 2010, 2015],
            //     },
            // },
        };

        if (type !== 'amount') {
            config.color = ['#7ca5fb', '#7868fd', '#FAA219'];
        }

        return (
            <div className="half">
                <Line {...config} />
            </div>
        );
    };

    /**
     * 渲染售后金额/件数饼图
     */
    renderRefundsAmountPiehart = (data: any, type: string = 'amount') => {
        const refundAmountTopPieChart =
            data && data.refundAmountTopPieChart ? data.refundAmountTopPieChart : [];
        const refundAmountTopRankingList =
            data && data.refundAmountTopRankingList ? data.refundAmountTopRankingList : [];
        const refundCountTopPieChart =
            data && data.refundCountTopPieChart ? data.refundCountTopPieChart : [];
        const refundCountTopRankingList =
            data && data.refundCountTopRankingList ? data.refundCountTopRankingList : [];
        const list = type === 'amount' ? refundAmountTopRankingList : refundCountTopRankingList;
        const config = {
            data: type === 'amount' ? refundAmountTopPieChart : refundCountTopPieChart,
            angleField: 'value',
            colorField: 'type',
            appendPadding: 10,
            radius: 0.9,
            label: {
                type: 'inner',
                offset: '-30%',
                content: function content(_ref: any) {
                    var percent = _ref.percent;
                    return ''.concat(Math.round(percent * 100) + '', '%');
                },
                style: {
                    fontSize: 14,
                    textAlign: 'center',
                },
            },
        };

        return (
            <div className="half">
                <div className="column-chart-title title-center">
                    {type === 'amount'
                        ? language.getText('shspjeTop10')
                        : language.getText('shspsTop10')}
                </div>
                <div className="ranking-list-chart">
                    <div className="ranking-list">
                        <div className="ranking-list-header">
                            <div className="ranking-list-header-col ranking">
                                {language.getText('pm_1')}
                            </div>
                            <div className="ranking-list-header-col name">
                                {language.getText('spmc')}
                            </div>
                            <div className="ranking-list-header-col sales">
                                {type === 'amount'
                                    ? language.getText('moneyTotal')
                                    : language.getText('js_5')}
                            </div>
                            <div className="ranking-list-header-col percent">
                                {language.getText('zb')}
                            </div>
                        </div>
                        {list &&
                            list.map((item: any, index: number) => {
                                const name = item.name.trim();
                                return (
                                    <div className="ranking-list-item">
                                        <div className="ranking-list-item-col ranking">
                                            {index + 1}
                                        </div>
                                        <div className="ranking-list-item-col name">
                                            {name.length > 5 ? name.slice(0, 5) + '...' : name}
                                        </div>
                                        <div className="ranking-list-item-col sales">
                                            {formatNumber(
                                                item.value,
                                                type === 'amount'
                                                    ? language.getText('yuan')
                                                    : language.getText('jian')
                                            )}
                                        </div>
                                        <div className="ranking-list-item-col percent">
                                            {item.percent}%
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                    <div className="ranking-chart">
                        <Pie {...config} />
                    </div>
                </div>
            </div>
        );
    };

    /**
     * 渲染门店售后商品金额/商品排行条形图
     */
    renderRefundsAmountBarhart = (data: any, type: string = 'amount') => {
        const config = {
            data: data ? data : [],
            xField: 'yfieldValue',
            yField: 'xfieldValue',
            barWidthRatio: 0.6,
            meta: {
                xfieldValue: { alias: language.getText('subsites') },
                yfieldValue: {
                    alias: type === 'amount' ? language.getText('shje') : language.getText('shsps'),
                },
            },
            scrollbar: { type: 'vertical' as any },
            color: '#bbe2ff',
        };
        return (
            <div className="half">
                <div className="column-chart-title">
                    {type === 'amount'
                        ? language.getText('mdshspjepx')
                        : language.getText('mdshspspx')}
                </div>
                <Bar {...config} />
            </div>
        );
    };

    /**
     * 渲染专柜售后商品金额占比饼图
     */
    renderMerchantRefundsAmountPiehart = (data: any, type: string = 'amount') => {
        const config = {
            data: data ? data : [],
            appendPadding: 10,
            angleField: 'value',
            colorField: 'type',
            radius: 1,
            innerRadius: 0.6,
            label: {
                type: 'inner',
                offset: '-50%',
                content: function content(_ref: any) {
                    var percent = _ref.percent;
                    return ''.concat(Math.round(percent * 100) + '', '%');
                },
                style: {
                    textAlign: 'center',
                    fontSize: 14,
                },
            },
            interactions: [{ type: 'element-selected' }],
            meta: {
                value: {
                    formatter: function formatter(v: any) {
                        return type === 'amount'
                            ? ''.concat('\xA5 ', v ? Number(v).toFixed(2) : '0')
                            : ''.concat(v ? v : 0, ' ' + language.getText('jian'));
                    },
                },
            },
            // statistic: {
            //     content: {
            //         formatter: (v: any) => {
            //             return v ? v.value + '%' : '100%';
            //         },
            //     },
            // },
        };

        return (
            <div className="half">
                <div className="column-chart-title">
                    {type === 'amount'
                        ? language.getText('zjshspjezb')
                        : language.getText('zjshspszb')}
                </div>
                <Pie {...config} />
            </div>
        );
    };

    /**
     * 渲染类目售后商品金额排行柱状图
     */
    renderRefundsAmountHistogram = (data: any, type: string = 'amount') => {
        if (data && data.length > 0) {
            data = data.map((item: any) => {
                return {
                    ...item,
                    title: item.xfieldValue,
                };
            });
        }
        const config = {
            data: data ? data : [],
            xField: 'xfieldValue',
            yField: 'yfieldValue',
            meta: {
                xfieldValue: {
                    alias: language.getText('categories'),
                    formatter: function formatter(v: any) {
                        return v && v.length > 6 ? v.slice(0, 6) + '...' : v;
                    },
                },
                yfieldValue: {
                    alias: type === 'amount' ? language.getText('shje') : language.getText('shsps'),
                },
            },
            tooltip: {
                title: 'title',
            },
            xAxis: { label: { autoRotate: true } },
            scrollbar: { type: 'horizontal' as any },
            color: '#a2f5cc',
        };
        return (
            <div className="half">
                <div className="column-chart-title">
                    {type === 'amount'
                        ? language.getText('lmshspjepx')
                        : language.getText('lmshspspx')}
                </div>
                <Column {...config} />
            </div>
        );
    };

    /**
     * 渲染支付概览子项
     */
    renderOverviewItem = (title: string, data: string, yesterday: number, lastWeek: number) => {
        return (
            <div className="col">
                <div className="title">{title}</div>
                <div className="data">{data}</div>
                <div className="contrast">
                    <span className="text">{language.getText('dbzr')}：</span>
                    <span className="percent">{!!yesterday ? Math.abs(yesterday) + '%' : '-'}</span>
                    {!!yesterday && (
                        <Icon
                            type={yesterday < 0 ? 'arrow-down' : 'arrow-up'}
                            className={yesterday < 0 ? 'icon-green' : 'icon-red'}
                        />
                    )}
                </div>
                <div className="contrast">
                    <span className="text">{language.getText('jsztq')}：</span>
                    <span className="percent">{!!lastWeek ? Math.abs(lastWeek) + '%' : '-'}</span>
                    {!!lastWeek && (
                        <Icon
                            type={lastWeek < 0 ? 'arrow-down' : 'arrow-up'}
                            className={lastWeek < 0 ? 'icon-green' : 'icon-red'}
                        />
                    )}
                </div>
            </div>
        );
    };

    /**
     * 渲染支付概览
     */
    renderOverview = (data: any[]) => {
        const appliedRefundTotalAmount = find(
            data,
            (item) => item.overviewType === 'APPLIED_REFUND_TOTAL_AMOUNT'
        );
        const appliedRefundMemberCount = find(
            data,
            (item) => item.overviewType === 'APPLIED_REFUND_MEMBER_COUNT'
        );
        const appliedRefundProductCount = find(
            data,
            (item) => item.overviewType === 'APPLIED_REFUND_PRODUCT_COUNT'
        );
        const refundTotalAmount = find(
            data,
            (item) => item.overviewType === 'REFUNDED_TOTAL_AMOUNT'
        );
        return (
            <div className="data-panel-reprot">
                {appliedRefundTotalAmount &&
                    this.renderOverviewItem(
                        language.getText('shje'),
                        formatNumber(
                            appliedRefundTotalAmount.overviewValue,
                            language.getText('yuan')
                        ),
                        appliedRefundTotalAmount.compareWithYesterday,
                        appliedRefundTotalAmount.compareWithLastWeek
                    )}
                {appliedRefundMemberCount &&
                    this.renderOverviewItem(
                        language.getText('shrs'),
                        formatNumber(
                            appliedRefundMemberCount.overviewValue,
                            language.getText('people')
                        ),
                        appliedRefundMemberCount.compareWithYesterday,
                        appliedRefundMemberCount.compareWithLastWeek
                    )}
                {appliedRefundProductCount &&
                    this.renderOverviewItem(
                        language.getText('shsps'),
                        formatNumber(
                            appliedRefundProductCount.overviewValue,
                            language.getText('jian')
                        ),
                        appliedRefundProductCount.compareWithYesterday,
                        appliedRefundProductCount.compareWithLastWeek
                    )}
                {refundTotalAmount &&
                    this.renderOverviewItem(
                        language.getText('cgshje'),
                        formatNumber(refundTotalAmount.overviewValue, language.getText('yuan')),
                        refundTotalAmount.compareWithYesterday,
                        refundTotalAmount.compareWithLastWeek
                    )}
            </div>
        );
    };

    render() {
        const { fields } = this.props;
        return (
            <div className="data-panel-refunds-view">
                {this.renderOverview(fields.overviewStatistics)}
                <div className="chart chart-double">
                    {this.renderRefundsAmountLineChart(fields.dailyAmount)}
                    {this.renderRefundsAmountLineChart(fields.dailyMemberProductCount, 'count')}
                </div>
                <div className="chart chart-double">
                    {this.renderRefundsAmountPiehart(fields.productAmountTopRanking)}
                    {this.renderRefundsAmountPiehart(fields.productCountTopRanking, 'count')}
                </div>
                <div className="chart chart-double static-height">
                    {this.renderRefundsAmountBarhart(fields.subsiteAmountRanking)}
                    {this.renderRefundsAmountBarhart(fields.subsiteProductAmountRanking, 'count')}
                </div>
                <div className="chart chart-double static-height">
                    {this.renderMerchantRefundsAmountPiehart(
                        fields.merchantProductAmountTopRanking
                    )}
                    {this.renderMerchantRefundsAmountPiehart(
                        fields.merchantProductCountTopRanking,
                        'count'
                    )}
                </div>
                <div className="chart chart-double static-height">
                    {this.renderRefundsAmountHistogram(fields.categoryAmountRanking)}
                    {this.renderRefundsAmountHistogram(fields.categoryProductCountRanking, 'count')}
                </div>
            </div>
        );
    }
}

function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
        fields: entity.result ? entity.result : entity.fields ? entity.fields : [],
    };
}

export const DataPanelsRefundsCardView = connect(mapStateToProps, null)(dataPanelsRefundsCardView);
