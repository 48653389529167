import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        OpenBoxEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/open-box',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsryhqlbmc>>' },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                },
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>' },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<ksjssj>>',
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                editExecuteStatus: {
                    type: 'string',
                },
                pageShowId: {
                    type: 'number',
                },
                shareConfigId: {
                    type: 'number',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherBaseInfo>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [{ required: true, whitespace: true, message: '<<qsrhdmc>>' }],
                            controlConfig: {
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRange.tip',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subsiteIds: {
                            type: 'array.subsites.selector',
                            displayName: '<<sxmd_1>>',
                            rules: [{ required: true }],
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],
                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.baseInfo?.subsiteIds?.length &&
                                        row.baseInfo.subsiteIds
                                            .map((item: any) => item.id)
                                            .join(',');
                                    return { subsiteIds };
                                },
                            },
                        },
                    },
                },
                ruleInfo: {
                    type: 'object.subForm',
                    displayName: '<<ruleSetting>>',
                    collapsed: true,
                    properties: {
                        activityRewardLimit: {
                            type: 'number.tip',
                            displayName: '<<jcjl>>',
                            displayConfig: { addonafter: '<<zhang>>' },
                            rules: [
                                {
                                    required: true,
                                    message: '<<cshdcjcsbnwk>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(\+?[0-9]*)$/;
                                        if (reg.test(value)) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<inputPlease>>',
                                addonbefore: '<<myhcshdcjcs>>',
                                addonafter: '<<frequency>>',
                                min: 0,
                                max: 999999,
                                style: { width: '120px' },
                                rules: [
                                    {
                                        type: 'number',
                                    },
                                ],
                            },
                        },
                        activityDrawLimit: {
                            type: 'object.openBox.drawLimit',
                            displayName: '<<ewjl>>',
                            rules: [
                                {
                                    required: false,
                                },
                            ],
                        },
                        inviteFriends: {
                            type: 'object.affiliateMarketing.inviteFriends',
                            displayName: '<<yqhycghdcjcs>>',
                        },
                        excludeTypeActivity: {
                            rules: [{ required: true }],
                            type: 'array.activities.openBox.selector',
                            displayName: '<<pcgz>>',
                        },
                        periodLimit: {
                            type: 'object.affiliateMarketing.periodLimit',
                            displayName: '<<zjcsxz>>',
                            controlConfig: {
                                markPage: 'openBox',
                            },
                        },
                    },
                },
                pictureInfo: {
                    type: 'object.subForm',
                    displayName: '<<pageDecoration>>',
                    collapsed: true,
                    properties: {
                        activityPicture: {
                            displayName: '<<hdxct>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/open-box/activity_picture.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                maxSize: 3 * 1024,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<picLimit1640>>',
                        },
                        rulePictureIcon: {
                            displayName: '<<gzsmtb>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/open-box/rule_picture_icon.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                maxSize: 3 * 1024,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<picLimit88>>',
                        },
                        rulePicture: {
                            displayName: '<<gzsm>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/open-box/rule_picture.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                maxSize: 3 * 1024,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<picLimit680>>',
                        },
                        audioUrl: {
                            displayName: '<<yl_1>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                accept:
                                    'audio/ogg,audio/mpeg,audio/wav,audio/aac,audio/flac,audio/wma,audio/aiff',
                                maxCount: 1,
                                maxSize: 2 * 1024,
                                multiple: false,
                                fileName: 'files',
                                uploadType: 'single',
                                uploadUrl: `${ENV.AUTH_API_ROOT}/FILE/admin/files/locations/5001/upload`,
                                mode: 'text',
                                uploadLimitSizeErrorMessage:
                                    '<<yp>>{{file.name}}<<limitSizeMessage>>',
                            },
                            extra: '<<dxbcg>>',
                        },
                        audioOpenPicture: {
                            type: 'imageObjectArray',
                            displayName: '<<kqzt>>',
                            className: 'sigle-image-field open-box-audio',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/open-box/audio_open.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                maxSize: 3 * 1024,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                        },
                        audioClosePicture: {
                            type: 'imageObjectArray',
                            displayName: '<<gbzt>>',
                            className: 'sigle-image-field open-box-audio',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/open-box/audio_close.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                maxSize: 3 * 1024,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                        },
                        backgroundPicture: {
                            type: 'imageObjectArray',
                            displayName: '<<hdbjt>>',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/open-box/background_picture.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                maxSize: 3 * 1024,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<picLimit1640>>',
                        },
                        giftBoxPicture: {
                            type: 'imageObjectArray',
                            displayName: '<<lht>>',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/open-box/gift_box_picture.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                maxSize: 3 * 1024,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<picLimit750_2>>',
                        },
                        giftBoxCartoonPicture: {
                            type: 'imageObjectArray',
                            displayName: '<<khdht>>',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/open-box/gift_box_cartoon_picture.gif',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                maxSize: 3 * 1024,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<giftBoxCartoonPictureExtra>>',
                        },
                        tipColor: {
                            displayName: '<<tswz>>',
                            type: 'string.color',
                            controlConfig: {
                                originalColor: '#000',
                            },
                        },
                        buttonBackgroundStyle: {
                            type: 'string.options.radio',
                            displayName: '<<anbj>>',
                            defaultValue: 'NONE',
                            rules: [{ required: true }],
                            options: [
                                { id: 'NONE', name: '<<solidColor>>' },
                                { id: 'BACKGROUND_URL', name: '<<bjt>>' },
                            ],
                        },
                        buttonBackgroundColor: {
                            displayName: '<<anbjys>>',
                            type: 'string.color',
                            controlConfig: {
                                originalColor: '#fff',
                            },
                        },
                        buttonBackgroundPicture: {
                            displayName: '<<anbjtp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/open-box/button_background_picture.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                maxSize: 3 * 1024,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<picLimit160>>',
                        },
                        buttonColor: {
                            displayName: '<<anwz>>',
                            type: 'string.color',
                            controlConfig: {
                                originalColor: '#000',
                            },
                        },
                        sharePicture: {
                            displayName: '<<fxtp>>',
                            type: 'array.imageObjectArrayForTopPic',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/open-box/share_picture.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                maxSize: 3 * 1024,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                                extra: {
                                    tip: '<<picLimit520>>',
                                    img: './images/avatar/open-box-share-picture.png',
                                    btnText: '<<viewDiagram>>',
                                    triggerType: 'hover',
                                },
                            },
                        },
                        shareContent: {
                            type: 'string',
                            displayName: '<<fxwa>>',
                            defaultValue: '<<khyhl>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrfxwa50Rule>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qsrfxwa>>',
                                maxLength: 50,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        winningRecordPicture: {
                            displayName: '<<zjjlbjt>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/open-box/winning_record_picture.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                maxSize: 3 * 1024,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<picLimit1640>>',
                        },
                        moreActivityPicture: {
                            displayName: '<<gdhdxct>>',
                            type: 'array.imageObjectArrayForTopPic',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                maxSize: 3 * 1024,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                                extra: {
                                    tip: '<<picLimit680>>',
                                    img: './images/avatar/open-box-more-activity-picture.png',
                                    btnText: '<<viewDiagram>>',
                                    triggerType: 'hover',
                                },
                            },
                        },
                        target: {
                            type: 'object.subsiteLinkTypeSelector',
                            displayName: '<<lj>>',
                            className: 'open-box-target',
                            controlConfig: {
                                bussinessType: 'open-box',
                                hideNeedUrlLink: true,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                    },
                },
                prizeInfo: {
                    type: 'object.subForm',
                    displayName: '<<jpsz>>',
                    collapsed: true,
                    properties: {
                        assetRewards: {
                            type: 'array.rewardSetting',
                            displayName: '<<jpsz>>',
                            controlConfig: {
                                eventType: 'OPEN_BOX',
                            },
                        },
                        finishWithNoPrize: {
                            type: 'array.optionIds.checkbox',
                            displayName: '<<byxyhjxcj>>',
                            defaultValue: [],
                            options: [
                                {
                                    name: '<<djpzsx>>',
                                    id: 'true',
                                },
                            ],
                            extra: '<<yhzccjhtsyh>>',
                        },
                    },
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<status>>',
                },
            },
        },
    },
    components: {
        OpenBoxView: {
            component: 'Viewport',
            entity: 'OpenBoxEntity',
        },
        OpenBoxPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'OpenBoxFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/open-box/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'OpenBoxTable' },
            ],
        },
        OpenBoxFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'subsiteIds',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        OpenBoxTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'startTime',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'endTime',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'subsites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 250,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}`,
                                    apiPath:
                                        '/AFFILIATE-MARKETING/admin/wheel_draw_activities/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isCloseActivity>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}`,
                                    apiPath:
                                        '/AFFILIATE-MARKETING/admin/wheel_draw_activities/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/menu-list/market/open-box/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/menu-list/market/open-box/copy/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'ExportHandlerButton',
                        text: '<<dcbb>>',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/open_box_activities/{{row.id}}/export_jobs',
                        },
                    },
                ],
            },
        },
        OpenBoxAddPage: {
            component: 'FlexLayout',
            entity: 'OpenBoxEntity',
            direction: 'horizontal',
            items: [{ component: 'OpenBoxPreview' }, { component: 'OpenBoxAddFormView' }],
        },
        OpenBoxEditPage: {
            component: 'FlexLayout',
            entity: 'OpenBoxEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'OpenBoxPreview' }, { component: 'OpenBoxEditFormView' }],
        },
        OpenBoxCopyPage: {
            component: 'FlexLayout',
            entity: 'OpenBoxEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'OpenBoxPreview' }, { component: 'OpenBoxCopyFormView' }],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/open-box',
                    component: 'OpenBoxView',
                    breadcrumbName: '<<khyl>>',
                    privilege: {
                        path: 'openBoxActivity',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'OpenBoxAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'OpenBoxEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/copy/:id',
                            component: 'OpenBoxCopyPage',
                            breadcrumbName: '<<copyActivity>>',
                        },
                        { path: '/', component: 'OpenBoxPage' },
                    ],
                },
            ],
        },
    ],
};
