import React, { useState } from 'react';
import { Modal } from 'antd';

import './index.less';
import { services } from '@comall-backend-builder/core';

export const PhotoCreditReviewPreviewPicture: React.FC<any> = (props: any) => {
    const { imgUrl } = props;
    const [visible, setVisible] = useState<boolean>(false);
    const [width, setWidth] = useState<number>(50);

    function handleVisible(): void {
        setVisible((value) => !value);
    }
    const imgStyle = { width: `${width}%` };

    function onEnlarged() {
        setWidth(width + 5);
    }

    function onReduce() {
        if (width - 5 < 50) {
            setWidth(50);
        } else {
            setWidth(width - 5);
        }
    }
    const enlarged = './images/editor/enlarged.png';
    const reduce = './images/editor/reduce.png';

    return (
        <>
            <div
                className="photo-credit-review-preview-picture"
                style={{
                    backgroundImage: `url(${imgUrl})`,
                }}
                onClick={handleVisible}
            />
            <Modal
                destroyOnClose
                title="查看图片"
                visible={visible}
                width="1500px"
                okButtonProps={{
                    hidden: true,
                }}
                onCancel={handleVisible}
            >
                <div className="photo-credit-review-preview-picture__modal">
                    <div className="photo-credit-review-preview-picture__modal__handle">
                        <img
                            src={enlarged}
                            alt="放大"
                            className="photo-credit-review-preview-picture__modal__handle__item"
                            onClick={onEnlarged}
                        />
                        <img
                            src={reduce}
                            alt={services.language.getText('narrow')}
                            className="photo-credit-review-preview-picture__modal__handle__item"
                            onClick={onReduce}
                        />
                    </div>
                    <img
                        src={imgUrl}
                        alt={services.language.getText('audity')}
                        style={imgStyle}
                        className="photo-credit-review-preview-picture__modal__img"
                    />
                </div>
            </Modal>
        </>
    );
};
