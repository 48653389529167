import { Config } from '@comall-backend-builder/core/lib/parser';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';
import get from 'lodash/get';

export const config: Config = {
    entities: {
        StoredValueCardConfigEntity: {
            apiPath: '/loader/admin/stored_value_card_config',
            filters: {
                pageTitle: {
                    type: 'string',
                    displayName: '<<ymbt>>',
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 250 } },
                },
                // subsiteIds: {
                //     type: 'string.options.autoComplete',
                //     displayName: '<<subsites>>',
                //     options: [],
                //     source: {
                //         apiPath: '/admin/defaults/subsite',
                //     },
                //     controlConfig: { placeholder: '<<selectPlease>>', style: { width: 250 } },
                // },
            },
            properties: {
                baseConfig: {
                    type: 'object.subForm',
                    displayName: '<<basicSetting>>',
                    collapsed: true,
                    defaultOpen: true,
                    properties: {
                        // subsiteIds: {
                        //     type: 'array.options.autoComplete',
                        //     displayName: '<<otherSelectSubsite>>',
                        //     options: [],
                        //     rules: [{ required: true, message: '<<selectSubsite>>' }],
                        //     source: {
                        //         apiPath: '/admin/defaults/subsite',
                        //     },
                        //     controlConfig: {
                        //         placeholder: '<<selectSubsite>>',
                        //         style: {
                        //             width: 450,
                        //         },
                        //     },
                        // },
                        pageTitle: {
                            type: 'string',
                            displayName: '<<ymbt>>',
                            defaultValue: '<<czk>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<h_value_card_title_msg>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<h_value_card_title_placeholder>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        showSubsiteWidget: {
                            type: 'string.options.radio',
                            displayName: '<<sfzsmd>>',
                            defaultValue: 'true',
                            options: [
                                { id: 'true', name: '<<yes>>' },
                                { id: 'false', name: '<<no>>' },
                            ],
                        },
                        showMode: {
                            type: 'string.options.radio',
                            displayName: '<<xzys>>',
                            defaultValue: '1',
                            options: [
                                { id: '1', name: '<<h_style>>1' },
                                { id: '2', name: '<<h_style>>2' },
                            ],
                        },
                    },
                },
                assetConfig: {
                    type: 'object.subForm',
                    displayName: '<<zc_3>>',
                    collapsed: true,
                    defaultOpen: true,
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<zcwa>>',
                            defaultValue: '<<wdczk>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<h_zcwa_msg>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<h_zcwa_placeholder>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        balanceName: {
                            type: 'string',
                            displayName: '<<yemc>>',
                            defaultValue: '<<dqye>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<h_yemc_msg>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<h_yemc_placeholder>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        balanceDescription: {
                            type: 'string.text.paragraph',
                            displayName: '<<yems>>',
                            rules: [{ max: 1000 }],
                            controlConfig: {
                                rows: 4,
                                maxLength: 1000,
                                style: { width: 450 },
                            },
                        },
                        backgroundPicture: {
                            displayName: '<<bgPic>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/stored-value-card/icon-card-background-new.png',
                                },
                            ],
                            controlConfig: {
                                maxCount: 1,
                                maxSize: 3 * 1024,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<tpccjyw>>654px*236px',
                        },
                        showPayCode: {
                            type: 'string.options.radio',
                            displayName: '<<sfzsfkm>>',
                            defaultValue: 'true',
                            options: [
                                { id: 'true', name: '<<yes>>' },
                                { id: 'false', name: '<<no>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        showCard: {
                            type: 'string.options.radio',
                            displayName: '<<sfzsklb>>',
                            defaultValue: 'true',
                            options: [
                                { id: 'true', name: '<<yes>>' },
                                { id: 'false', name: '<<no>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        showCardName: {
                            type: 'string',
                            displayName: '<<zswa>>',
                            defaultValue: '<<wdk>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<h_zswa_msg>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<h_zswa_placeholder>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        showStyle: {
                            type: 'string.imageRadio',
                            displayName: '<<zsys>>',
                            defaultValue: '1',
                            options: [
                                { id: '1', name: '<<ysy>>' },
                                { id: '2', name: '<<yse>>' },
                            ],
                            controlConfig: {
                                images: {
                                    '1': '../../images/editor/stored-value-card-one.jpg',
                                    '2': '../../images/editor/stored-value-card-two.jpg',
                                },
                                style: { display: 'flex', paddingTop: 9 },
                            },
                        },
                    },
                },
                menusConfig: {
                    type: 'object.subForm',
                    displayName: '<<service>>',
                    collapsed: true,
                    defaultOpen: true,
                    properties: {
                        service: {
                            type: 'array.storedValueCardMenus',
                            displayName: '<<gncd>>',
                        },
                    },
                },
                otherConfig: {
                    type: 'object.subForm',
                    displayName: '<<gj_1>>',
                    collapsed: true,
                    defaultOpen: true,
                    properties: {
                        showSubsiteWhenPurchaseCard: {
                            type: 'string.options.radio',
                            displayName: '<<gkssfzsskmd>>',
                            defaultValue: 'false',
                            options: [
                                { id: 'true', name: '<<yes>>' },
                                { id: 'false', name: '<<no>>' },
                            ],
                            rules: [{ required: true }],
                        },
                    },
                },
                pageTitle: {
                    type: 'string',
                    displayName: '<<pageName>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<kqzt>>',
                    options: [
                        { id: 'true', name: '<<beforeOpen>>' },
                        { id: 'false', name: '<<beforeClose>>' },
                    ],
                },
            },
        },
    },
    components: {
        StoredValueCardConfigView: {
            component: 'Viewport',
            entity: 'StoredValueCardConfigEntity',
        },
        StoredValueCardConfigPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'StoredValueCardConfigHeader',
                },
                {
                    component: 'StoredValueCardConfigTable',
                },
            ],
        },
        StoredValueCardConfigHeader: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'GridLayout',
                    items: [
                        {
                            component: 'StoredValueCardConfigFilter',
                            span: 24,
                        },
                        {
                            component: 'Button',
                            text: '<<common.add>>',
                            icon: 'plus',
                            type: 'primary',
                            route: '/stored-value-card-config/add',
                            span: 4,
                            style: {
                                marginRight: 24,
                            },
                            className: 'mt24 mb24',
                        },
                    ],
                },
            ],
        },
        StoredValueCardConfigFilter: {
            component: 'FilterForm',
            direction: 'inline',
            fields: [
                // {
                //     property: 'subsiteIds',
                // },
                {
                    property: 'pageTitle',
                },
            ],
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 12,
                },
            },
            reset: true,
        },
        StoredValueCardConfigTable: {
            component: 'DataTable',
            loadFirstPage: true,
            scroll: {
                x: true,
            },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'pageTitle',
                },
                // {
                //     property: 'subsites',
                //     displayConfig: {
                //         className: 'ant-table-cell-ellipsis',
                //         style: {
                //             maxWidth: '140px',
                //         },
                //         countSuffix: '<<subsites>>',
                //     },
                // },
                {
                    property: 'status',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 190,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-ASSETS`,
                                    apiPath: '/admin/stored_value_cards/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-ASSETS`,
                                    apiPath: '/admin/stored_value_cards/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/stored-value-card-config/edit/{{row.id}}',
                    },
                ],
            },
        },
        StoredValueCardConfigAddPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'StoredValueCardConfigEntity',
            items: [
                { component: 'StoredValueCardConfigPreview' },
                { component: 'StoredValueCardConfigAddForm' },
            ],
        },
        StoredValueCardConfigAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            fields: [
                // {
                //     property: 'baseConfig.subsiteIds',
                // },
                {
                    property: 'baseConfig.pageTitle',
                },
                {
                    property: 'baseConfig.showSubsiteWidget',
                },
                {
                    property: 'baseConfig.showMode',
                },
                {
                    property: 'assetConfig.name',
                },
                {
                    property: 'assetConfig.balanceName',
                },
                {
                    property: 'assetConfig.balanceDescription',
                },
                {
                    property: 'assetConfig.backgroundPicture',
                },
                {
                    property: 'assetConfig.showPayCode',
                },
                {
                    property: 'assetConfig.showCard',
                },
                {
                    property: 'assetConfig.showCardName',
                    visible: (values: any) => get(values, 'assetConfig.showCard') === 'true',
                },
                {
                    property: 'assetConfig.showStyle',
                },
                {
                    property: 'menusConfig.service',
                },
                // {
                //     property: 'pageConfig.advertisingSpace',
                // },
                {
                    property: 'otherConfig.showSubsiteWhenPurchaseCard',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                return true;
            },
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        StoredValueCardConfigEditPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'StoredValueCardConfigEntity',
            loaderType: 'get',
            items: [
                { component: 'StoredValueCardConfigPreview' },
                { component: 'StoredValueCardConfigEditForm' },
            ],
        },
        StoredValueCardConfigEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            fields: [
                // {
                //     property: 'baseConfig.subsiteIds',
                // },
                {
                    property: 'baseConfig.pageTitle',
                },
                {
                    property: 'baseConfig.showSubsiteWidget',
                },
                {
                    property: 'baseConfig.showMode',
                },
                {
                    property: 'assetConfig.name',
                },
                {
                    property: 'assetConfig.balanceName',
                },
                {
                    property: 'assetConfig.balanceDescription',
                },
                {
                    property: 'assetConfig.backgroundPicture',
                },
                {
                    property: 'assetConfig.showPayCode',
                },
                {
                    property: 'assetConfig.showCard',
                },
                {
                    property: 'assetConfig.showCardName',
                    visible: (values: any) => get(values, 'assetConfig.showCard') === 'true',
                },
                {
                    property: 'assetConfig.showStyle',
                },
                {
                    property: 'menusConfig.service',
                },
                {
                    property: 'otherConfig.showSubsiteWhenPurchaseCard',
                },
                // {
                //     property: 'pageConfig.advertisingSpace',
                // },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                return true;
            },
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/stored-value-card-config',
                    component: 'StoredValueCardConfigView',
                    breadcrumbName: '<<newCSubsiteCarpark>>',
                    privilege: {
                        path: 'storedValueCardConfig',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'StoredValueCardConfigAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'StoredValueCardConfigEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        {
                            path: '/',
                            component: 'StoredValueCardConfigPage',
                        },
                    ],
                },
            ],
        },
    ],
};
