import React from 'react';
import { connect } from 'react-redux';
import { get } from 'lodash';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

/**
 * 打包一口价预览效果
 * @param props
 */
const fixedPricePreview = (props: any) => {
    const { preview = {} } = props;
    const tagName = get(preview, 'baseInfo.tagName', language.getText('fixedPrice'));
    return (
        <div className="fixed-price-preview">
            <div className="preview-top">
                <img alt="" width="100%" src="./images/avatar/theme-head.png" />
                <div className="preview-title">{tagName}</div>
            </div>
            <div className="preview-content">{renderContent(preview)}</div>
        </div>
    );
};

const renderContent = (preview: any) => {
    const bannerPicture = get(preview, 'pageConfigInfo.bannerPicture[0].path', '');
    const filterImage = require('./filter.png');
    const prodcutListImage = require('./product_list.png');

    return (
        <div className="preview-box">
            {bannerPicture && (
                <div className="top-image-view">
                    <img className="top-image" src={bannerPicture} alt="" />
                </div>
            )}
            <img className="preview-image" src={filterImage} alt="" />
            {renderRuleInfo(preview)}
            <img className="preview-image" src={prodcutListImage} alt="" />
        </div>
    );
};
const renderRuleInfo = (preview: any) => {
    const tagName = get(preview, 'baseInfo.tagName', language.getText('fixedPrice'));
    const rewardInfos = get(preview, 'ruleConfig.rewardInfos', []);
    const enableCountdown = get(preview, 'pageConfigInfo.enableCountdown', 'false') === 'true';
    const rules = rewardInfos
        .map(
            (item: any) =>
                `${item.rewardValue || 'x'}${language.getText('yuan')}${item.rewardConditionValue ||
                    'y'}${language.getText('jian')}`
        )
        .join(',');
    return (
        <div className="preview-rule">
            {enableCountdown && (
                <div className="preview-countdown">
                    <span>{language.getText('toEndOnly')}</span>
                    <span className="preview-countdown-number">23</span>
                    <span>:</span>
                    <span className="preview-countdown-number">34</span>
                    <span>:</span>
                    <span className="preview-countdown-number">05</span>
                </div>
            )}
            <div className="reward-info">
                <span className="rule-tag">{tagName}</span>
                <span className="rules">{rules}</span>
            </div>
        </div>
    );
};

const mapStateToProps = (_state: any, props: any) => {
    let preview = null;
    const entityId = props.entity.id;
    for (var compId in _state.components) {
        const comp = _state.components[compId];
        if (
            (comp.type === 'CreateForm' || comp.type === 'EditForm') &&
            comp.entityId === entityId
        ) {
            preview = comp.fields;
        }
    }
    return { preview: preview };
};

export const FixedPricePreview = connect(mapStateToProps)(fixedPricePreview);
