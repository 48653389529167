import React, { Component, createElement } from 'react';
import { Icon, Checkbox, Button, message, Modal } from 'antd';
import { services, Loader } from '@comall-backend-builder/core';
import { AutomaticDeliveryForm } from '../automatic-delivery-form';
import { ComponentsManager } from '@comall-backend-builder/core';
import { find, debounce, cloneDeep, assign, lowerCase, forEach } from 'lodash';
import { DeliveryFormData, LogisticsMethod, DefaultFormData, DeliveryMode } from '../types';
import { formatDate } from '../../services';

import './index.less';

const CheckboxGroup = Checkbox.Group;
const { info } = Modal;
const { errorHandle } = services;
const printerIcon = './images/editor/printer-icon.png';
interface BatchDeliveryOrderListButtonsState {
    visible: boolean;
    checkedOrders: DeliveryOrder[];
    checkedList: number[];
    indeterminate: boolean;
    checkAll: boolean;
    formData: DeliveryFormData;
    expressCompanyList: any;
    deliveryLogisticsConfig: any;
    isSubmitting: boolean;
    disableSubmit: boolean;
    deliveryMode: DeliveryMode;
}
interface DeliveryOrder {
    id: number;
    subsite: {
        id: number;
        name: string;
    };
    merchant: {
        id: number;
        name: string;
    };
    payableAmount: number;
    items: any;
}

/**
 * 批量发货-订单项
 */
export class BatchDeliveryOrderList extends Component<any, BatchDeliveryOrderListButtonsState> {
    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
            checkedOrders: [],
            checkedList: [],
            indeterminate: false,
            checkAll: false, // 门店配送公司配置
            deliveryLogisticsConfig: {},
            // 编辑的表单数据
            formData: cloneDeep(DefaultFormData),
            // 可选物流公司列表
            expressCompanyList: [],
            isSubmitting: false,
            disableSubmit: false,
            deliveryMode: DeliveryMode.EXPRESS,
        };
        this.debounceSubmit = debounce(this.onSubmit, 300);
    }
    debounceSubmit: () => void;

    getFilterConfig = () => {
        const { entity } = this.props;
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,

            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        let deliveryMode: DeliveryMode;
                        if (entity.filters && entity.filters.deliveryModeId === '1004') {
                            deliveryMode = DeliveryMode.CITYWIDE;
                        } else {
                            deliveryMode = DeliveryMode.EXPRESS;
                        }
                        this.setState({
                            deliveryMode,
                        });
                        this.initState();
                        entity.search();
                    },
                    fields: [
                        {
                            property: 'deliveryModeId',
                        },
                        {
                            property: 'subsiteIds',
                        },
                        {
                            property: 'merchantIds',
                        },
                        {
                            property: 'dateRange',
                        },
                    ],
                },
            ],
        };
    };

    initState = () => {
        this.setState({
            checkedOrders: [],
            checkedList: [],
            indeterminate: false,
            checkAll: false,
        });
    };

    /**
     * 复制订单号
     * @param orderNo
     * @returns
     */
    copyOrderNo = (orderNo: any) => {
        return function() {
            let input = document.createElement('input');
            input.value = orderNo;
            document.body.appendChild(input);
            input.select();
            if (document.execCommand('Copy')) {
                message.success(services.language.getText('ddhyfz'));
            } else {
                message.warning(services.language.getText('ddhfzsb'));
            }
            document.body.removeChild(input);
        };
    };
    /**
     * 勾选/取消
     * @param e
     */
    onCheckboxChange = (checkedList: any) => {
        const { entity } = this.props;
        this.setState({
            checkedList,
            indeterminate: !!checkedList.length && checkedList.length < entity.result.length,
            checkAll: checkedList.length === entity.result.length,
        });
    };
    /**
     * 全选/取消勾选
     */
    onCheckAllChange = (e: any) => {
        const { entity } = this.props;
        const checkedList = entity.result.map((order: DeliveryOrder) => {
            return order.id;
        });
        this.setState({
            checkedList: e.target.checked ? checkedList : [],
            indeterminate: false,
            checkAll: e.target.checked,
        });
    };
    /**
     * 页数改变清空选中订单
     */
    changeOrdersPage = () => {
        this.setState({
            checkedList: [],
        });
    };
    /**
     * 打开批量发货弹窗
     */
    showDeliveryModal = () => {
        const { entity } = this.props;
        const { checkedList } = this.state;
        if (!checkedList || !checkedList.length) {
            message.error(services.language.getText('qxzfhdd'));
            return;
        }
        const orders = entity.result;
        let isSameSubsite = true;
        let preSubsiteId: number;
        // 判断是否在同一个门店下，并记录已选中的订单
        const checkedOrders = checkedList.map((orderId: number, index: number) => {
            const order = find(orders, { id: orderId });
            if (preSubsiteId && preSubsiteId !== order.subsite.id) {
                isSameSubsite = false;
            }
            preSubsiteId = order.subsite.id;
            return order;
        });
        if (!isSameSubsite) {
            message.error(services.language.getText('qxztymdddfh'));
            return;
        }
        this.setState(
            {
                visible: true,
                checkedOrders,
            },
            this.getDeliveryLogisticsConfig
        );
    };
    // 关闭批量发货弹窗
    closeDeliveryModal = () => {
        this.setState({
            visible: false,
            formData: cloneDeep(DefaultFormData),
        });
    };

    // 获取门店配送公司设置
    getDeliveryLogisticsConfig = () => {
        const { checkedOrders } = this.state;
        const subsiteId = checkedOrders[0].subsite.id;
        const merchantIds: Array<string> = [];
        forEach(checkedOrders, (order) => {
            const isHave = merchantIds.find((i: string) => i === order.merchant.id + '');
            if (!isHave) {
                merchantIds.push(order.merchant.id + '');
            }
        });
        Loader.load('get', {
            params: {
                subsiteId: subsiteId,
                merchantIds: merchantIds.join(','),
            },
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: `/admin/delivery_logistics_configs/by_merchant_id`,
        })
            .then((res: any) => {
                this.setState(
                    {
                        deliveryLogisticsConfig: res,
                    },
                    this.loadExpressCompany
                );
            })
            .catch(services.errorHandle);
    };
    getConfigInfo = (res: any) => {
        if (!res) {
            return {
                companyList: [],
            };
        }
        const { deliveryMode } = this.state;
        if (deliveryMode === DeliveryMode.EXPRESS) {
            //快递
            const expressCompanies = [];
            if (res.expressCompanies && res.expressCompanies.length > 0) {
                const automaticList = res.expressCompanies.filter(
                    (item: any) =>
                        item.logisticsBillGenerationMethods &&
                        item.logisticsBillGenerationMethods.includes(LogisticsMethod.AUTOMATIC)
                );
                const manualList = res.expressCompanies.filter(
                    (item: any) =>
                        item.logisticsBillGenerationMethods &&
                        item.logisticsBillGenerationMethods.includes(LogisticsMethod.MANUAL)
                );
                if (automaticList && automaticList.length > 0) {
                    expressCompanies.push({
                        logisticsMethod: LogisticsMethod.AUTOMATIC,
                        companys: automaticList,
                    });
                }
                if (manualList && manualList.length > 0) {
                    expressCompanies.push({
                        logisticsMethod: LogisticsMethod.MANUAL,
                        companys: manualList,
                    });
                }
                return {
                    companyList: expressCompanies,
                };
            }
        }
        if (deliveryMode === DeliveryMode.CITYWIDE) {
            //同城配
            const cityWideCompanies = [];
            if (res.cityWideCompanies && res.cityWideCompanies.length > 0) {
                const automaticList = res.cityWideCompanies.filter(
                    (item: any) =>
                        item.logisticsBillGenerationMethods &&
                        item.logisticsBillGenerationMethods.includes(LogisticsMethod.AUTOMATIC)
                );
                const manualList = res.cityWideCompanies.filter(
                    (item: any) =>
                        item.logisticsBillGenerationMethods &&
                        item.logisticsBillGenerationMethods.includes(LogisticsMethod.MANUAL)
                );
                if (automaticList && automaticList.length > 0) {
                    cityWideCompanies.push({
                        logisticsMethod: LogisticsMethod.AUTOMATIC,
                        companys: automaticList,
                    });
                }
                if (manualList && manualList.length > 0) {
                    cityWideCompanies.push({
                        logisticsMethod: LogisticsMethod.MANUAL,
                        companys: manualList,
                    });
                }
            }
            return {
                companyList: cityWideCompanies,
            };
        }
        return {
            companyList: [],
        };
    };
    /**
     * 加载物流公司列表
     */
    loadExpressCompany = debounce((name?: string) => {
        const { deliveryLogisticsConfig } = this.state;
        const configInfo = this.getConfigInfo(deliveryLogisticsConfig);
        const automaticLogisticsMethodCompanys = configInfo.companyList.find(
            (c: any) => c.logisticsMethod === LogisticsMethod.AUTOMATIC
        );
        let automaticCompanys = automaticLogisticsMethodCompanys
            ? automaticLogisticsMethodCompanys.companys
            : [];
        if (name) {
            automaticCompanys = automaticCompanys.filter((c: any) => c.companyName.includes(name));
        }
        this.setState(
            {
                expressCompanyList: automaticCompanys,
            },
            () => {
                // 默认选中第一家
                if (automaticCompanys && automaticCompanys.length) {
                    this.onChangeCompany(automaticCompanys[0]['companyId']);
                }
            }
        );
    }, 200);
    /**
     * 获取快递公司的服务类型
     */
    getServiceTypes = (companyCode: string) => {
        Loader.load('get', {
            params: {
                companyCode,
            },
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: `/admin/logistics_company_services`,
        })
            .then((res: any) => {
                this.setState({
                    formData: assign(this.state.formData, {
                        serviceTypes: res,
                        serviceType: res && res.length ? res[0].code : '',
                    }),
                });
            })
            .catch(errorHandle);
    };

    /**
     * 物流公司变更(重置表单数据)
     * @param value
     */
    onChangeCompany = (value: string) => {
        if (value === this.state.formData.companyId) {
            return;
        }
        const { expressCompanyList } = this.state;

        const selectedCompany: any = expressCompanyList.find((i: any) => i.companyId === value);
        const companyCode = selectedCompany['companyCode'];
        const deliveryLogisticsCompanyId = selectedCompany['id'];
        const companyAccount = selectedCompany['account'];
        this.setState({
            formData: assign(cloneDeep(DefaultFormData), {
                companyId: value,
                companyAccount,
                deliveryLogisticsCompanyId,
            }),
        });
        this.getServiceTypes(companyCode);
    };

    // 改变表单数据
    changeFormData = (formData: DeliveryFormData) => {
        this.setState({
            formData,
        });
    };
    onSubmit = () => {
        const { deliveryMode, formData, deliveryLogisticsConfig, checkedOrders } = this.state;
        const {
            companyId,
            companyAccount,
            deliveryLogisticsCompanyId,
            pickupTimeRange,
            serviceType,
            selectedPickupDay,
            selectedPickupTimeRange,
            pickup,
        } = formData;
        const subsiteId = checkedOrders[0].subsite.id;
        let params: any = {
            subsiteId,
            companyId,
            companyAccount,
            deliveryLogisticsCompanyId,
            deliveryMode,
            insured: null,
            logisticsBillGenerationMethod: LogisticsMethod.AUTOMATIC,
            logisticsConfigId: deliveryLogisticsConfig?.id,
            remark: '',
            serviceType: serviceType ? serviceType : null,
        };
        //组装发货的包裹项
        let packages: any = [];
        checkedOrders.forEach((order: DeliveryOrder) => {
            let packageItems: any = [];
            order.items.forEach((item: any) => {
                packageItems.push({
                    orderId: order.id,
                    orderItemId: item.id,
                });
            });
            packages.push({
                deliveryOrderId: order.id,
                packageItems: packageItems,
            });
        });

        params.packages = packages;

        if (pickup && (!selectedPickupDay || !selectedPickupTimeRange)) {
            return message.warning(services.language.getText('qxzyysj'));
        }
        if (pickup && selectedPickupDay && selectedPickupTimeRange) {
            const selectedPickupDate = pickupTimeRange[selectedPickupDay].date;

            params.pickupStartTime = formatDate(
                selectedPickupDate + ' ' + selectedPickupTimeRange.split('-')[0],
                'yyyy-MM-dd hh:mm:ss'
            );
            params.pickupEndTime = formatDate(
                selectedPickupDate + ' ' + selectedPickupTimeRange.split('-')[1],
                'yyyy-MM-dd hh:mm:ss'
            );
        }

        this.logisticsDeliver(params);
    };
    //发货请求
    logisticsDeliver = (params: any) => {
        this.setState({ isSubmitting: true });
        Loader.load('post', {
            params: params,
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: `/admin/delivery_packages`,
        })
            .then((res: any) => {
                this.showResultModal(res);
            })
            .catch(errorHandle)
            .finally(() => {
                this.setState({
                    isSubmitting: false,
                });
            });
    };
    // 根据返回结果弹出结果窗口
    showResultModal = (res: any) => {
        const { entity } = this.props;
        const { formData } = this.state;
        this.closeDeliveryModal();
        // 手动填单发货成功提示
        const confirmProps: any = {
            title: services.language.getText('fhcg'),
            content: services.language.getText('qdgbcym'),
            okText: services.language.getText('common.ok'),
            okCancel: true,
            cancelButtonProps: {
                style: {
                    display: 'none',
                },
            },
            onOk: () => {
                if (res.success) {
                    entity.search();
                    this.initState();
                }
            },
        };
        if (res.success) {
            // 自动叫单  指定预约取件成功提示
            if (formData.pickup) {
                confirmProps.title = services.language.getText('jdcg');
                confirmProps.content = (
                    <div>
                        <div style={{ margin: '10px 0' }}>
                            {services.language.getText('yyqjwxzjdymd')}
                        </div>
                    </div>
                );
            } else {
                // 自动叫单  未指定预约取件成功提示
                confirmProps.title = services.language.getText('dymd');
                confirmProps.content = (
                    <div>
                        <div>{services.language.getText('qtgddrjdymd')}</div>
                        <img
                            style={{
                                display: 'block',
                                width: '100px',
                                height: '100px',
                                margin: '10px auto',
                            }}
                            alt={services.language.getText('wxddrj')}
                            src={printerIcon}
                        ></img>
                        <div style={{ margin: '0 auto 10px', textAlign: 'center' }}>
                            {services.language.getText('wxddrj')}
                        </div>
                    </div>
                );
            }
        } else {
            confirmProps.title = services.language.getText('jdsb');

            confirmProps.content = (
                <div>
                    {res &&
                        res.failedOrders &&
                        res.failedOrders.map((order: any) => {
                            return (
                                <div style={{ margin: '10px 0' }}>
                                    <div>
                                        {services.language.getText('ddh_1')}：{order.orderNo}
                                    </div>
                                    <div style={{ color: 'red' }}>{order.reason}</div>
                                </div>
                            );
                        })}
                </div>
            );
        }
        info(confirmProps);
    };

    render() {
        const { entity } = this.props;
        const {
            formData,
            expressCompanyList,
            disableSubmit,
            visible,
            isSubmitting,
            deliveryMode,
        } = this.state;

        const config = this.getFilterConfig();
        const filterContent = createElement(ComponentsManager.get(config.component), config);
        const dataListProps = {
            entity: entity,
            component: 'DataList',
            renderItem: this.renderItem,
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '15', '20', '50', '100'],
                showTotal(total: any) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            onPageChange: this.changeOrdersPage,
        };
        const dataListContent = createElement(
            ComponentsManager.get(dataListProps.component),
            dataListProps
        );

        const modalProps = {
            width: 500,
            title: '',
            visible: visible,
            footer: null,
            onCancel: this.closeDeliveryModal,
        };
        return (
            <div className="batch-delivery-order-list">
                {filterContent}
                <div className="order-actions">
                    <Checkbox
                        className="all-delivery-checkbox"
                        indeterminate={this.state.indeterminate}
                        onChange={this.onCheckAllChange}
                        checked={this.state.checkAll}
                    >
                        {services.language.getText('qx_1')}
                    </Checkbox>

                    <Button
                        className="batch-delivery-button"
                        type="primary"
                        onClick={this.showDeliveryModal}
                    >
                        {services.language.getText('orderLogistics')}
                    </Button>
                </div>
                <div className="order-list">
                    <CheckboxGroup value={this.state.checkedList} onChange={this.onCheckboxChange}>
                        {dataListContent}
                    </CheckboxGroup>
                </div>
                <Modal {...modalProps} className="automatic-delivery-modal">
                    <AutomaticDeliveryForm
                        formData={formData}
                        deliveryMode={deliveryMode}
                        expressCompanyList={expressCompanyList}
                        onChangeCompany={this.onChangeCompany}
                        changeFormData={this.changeFormData}
                        loadExpressCompany={this.loadExpressCompany}
                        batchDelivery={true}
                    ></AutomaticDeliveryForm>
                    <div className="automatic-delivery-modal__button">
                        <Button
                            loading={isSubmitting}
                            disabled={disableSubmit}
                            onClick={this.debounceSubmit}
                            type="primary"
                        >
                            {services.language.getText('zdjd')}
                        </Button>
                        <Button onClick={this.closeDeliveryModal} style={{ marginLeft: '20px' }}>
                            {services.language.getText('common.cancel')}
                        </Button>
                    </div>
                </Modal>
            </div>
        );
    }

    renderItem = (item: any) => {
        return (
            <div className="order-item" key={item.id}>
                <div className="order-item__left">
                    <Checkbox value={item.id} />
                </div>
                <div className="order-item__right">
                    <div className="order-item__header">
                        <div className="order-item__header-left">
                            <span className="order-item__header-item">
                                {services.language.getText('orderNumber')}：{item.orderNo}{' '}
                                <Icon type="copy" onClick={this.copyOrderNo(item.orderNo)} />
                            </span>

                            <span className="order-item__header-item">
                                {services.language.getText('xdsj')}：{item.orderCreateTime}
                            </span>
                            <span className="order-item__header-item">
                                {services.language.getText('deliveryType')}：
                                {item.delivery.deliveryModeId === 1004
                                    ? services.language.getText('tcp')
                                    : services.language.getText('kd_2')}
                            </span>
                        </div>
                    </div>
                    <div className="order-item__header">
                        <div className="order-item__header-left">
                            <span className="order-item__header-item">
                                {services.language.getText('subsites')}：{item.subsite.name}
                            </span>
                            <span className="order-item__header-item">
                                {services.language.getText('merchant')}：{item.merchant.name}
                            </span>
                            <span>
                                {services.language.getText('ddlx')}：{item.orderTypeName}
                            </span>
                        </div>
                    </div>
                    <div className="order-item__content">
                        <div className="order-item__content-left">
                            {item.items &&
                                item.items.map((goods: any) => {
                                    let quantity = goods.quantity;
                                    if (lowerCase(goods.displayUnit) === 'kg') {
                                        quantity = Number(goods.quantity / 1000);
                                    }
                                    return (
                                        <div key={goods.id} className="order-item__goods-item">
                                            <div>
                                                <img
                                                    className="order-item__goods-img"
                                                    src={goods.imageUrl}
                                                    alt={goods.productName}
                                                ></img>
                                            </div>
                                            <div className="order-item__goods-detail">
                                                <div>{goods.productName}</div>
                                                <div>{goods.styleName}</div>
                                            </div>
                                            <div className="order-item__goods-detail">
                                                <div>
                                                    {goods.executePrice}
                                                    {services.language.getText('yuan')}
                                                </div>
                                                <div>
                                                    *{quantity}
                                                    {goods.productType === 'WEIGHT'
                                                        ? lowerCase(goods.displayUnit)
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                        </div>
                        <div className="order-item__content-right">
                            <div>
                                {services.language.getText('yfzey')}：{item.payableAmount}
                            </div>
                            <div>
                                {services.language.getText('shr')}：{item.delivery.receiverName}
                            </div>
                            <div>
                                {services.language.getText('phoneNum')}：{item.delivery.mobile}
                            </div>
                            <div>
                                {services.language.getText('member')}：{item.member.mobile}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
}
