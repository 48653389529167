import React, { Component } from 'react';
import { get } from 'lodash';
import { AutoReplyVo } from '../../../../containers/wechat/components/wechant-content';
import { WechatOfficialAutoReplySettingPageData } from '../../../../containers/wechat/components/wechat-official-auto-reply-setting-page-data';

interface Props {
    value: AutoReplyVo[];
    appIdPath: string;
    row: any;
    onChange(value: AutoReplyVo[]): void;
}
export default class WechatOfficialQRcodeReplyInput extends Component<Props> {
    render() {
        const { appIdPath, row, onChange, value } = this.props;
        const appId = get(row, appIdPath);
        if (!appId) {
            return <div style={{ color: '#f5222d' }}>请先选择一个公众号</div>;
        }
        return (
            <WechatOfficialAutoReplySettingPageData
                appId={appId}
                inputOnly
                replyData={value}
                onChange={onChange}
            />
        );
    }
}
