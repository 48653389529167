import React, { PureComponent, createElement } from 'react';
import { remove, forEach, map, find } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { message, Modal } from 'antd';
import './index.less';

export interface ExternalCoupon {
    /**
     * 券编码
     */
    code: string;
    /**
     * 券类型
     */
    name: string;
    /**
     * 被选中的专柜
     */
    select?: boolean;
}

const TableComponentId = 'ExternalCouponSelectorTable';
let Entity: any = {};
let entity: any = {};

/**
 * 选择专柜
 */
export class ExternalCouponSelector extends PureComponent<{
    selectValues: ExternalCoupon[];
    onChange: (products: ExternalCoupon[]) => void;
    type: 'radio' | undefined;
    params?: any;
}> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('ExternalCouponSelector');
        entity = new Entity({});
    }
    dispatch: any = {};
    state = { visible: false };

    selectedRows = new Array<ExternalCoupon>();

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(entity));
    }

    onTableRowSelect = (record: ExternalCoupon, selected: boolean) => {
        const { type } = this.props;
        if (selected) {
            if ('radio' === type) {
                this.selectedRows = [record];
            } else {
                if (find(this.selectedRows, { code: record.code })) {
                    return;
                }
                this.selectedRows.push(record);
            }
        } else {
            remove(this.selectedRows, (i) => {
                return i.code === record.code;
            });
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: ExternalCoupon[]) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            this.selectedRows = [];
        }
    };

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
    };

    onOk = () => {
        const { onChange } = this.props;
        if (onChange) {
            forEach(this.selectedRows, (subsite) => {
                subsite.select = true;
            });
            onChange(this.selectedRows);
            this.toggleModal();
        }
    };

    getConfig = () => {
        const { selectValues, type, params } = this.props;
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'right', marginBottom: '10px' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        entity.search(params);
                    },
                    fields: [
                        {
                            property: 'code',
                        },
                        {
                            property: 'name',
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    scroll: {
                        y: 400,
                    },
                    pagination: false,
                    rowSelection: {
                        type: type ? type : 'checkbox',
                        selectedRowKeys: map(selectValues, 'id'),
                        onSelect: (record: ExternalCoupon, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: ExternalCoupon[]) => {
                            this.onTableRowSelectAll(selected, rows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: false,
                    columns: [
                        {
                            property: 'code',
                            width: 200,
                        },
                        {
                            property: 'name',
                        },
                    ],
                },
            ],
        };
    };

    toggleModal = () => {
        const { params } = this.props;
        if (!params.subsiteId || !params.subsiteId.length) {
            message.warn(services.language.getText('qxxzygmd'));
            return;
        }
        const { visible } = this.state;

        this.setState({ visible: !visible }, () => {
            if (!visible) {
                entity.search({
                    ...params,
                });
                this.onClearAll();
            }
        });
    };

    render() {
        const { visible } = this.state;
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 600,
            title: services.language.getText('xzsfyeq'),
            visible: visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
            className: 'external-coupon-selector-modal',
        };

        return (
            <div className="external-coupon-selector">
                <span className="external-coupon-selector__button" onClick={this.toggleModal}>
                    {services.language.getText('xzsfyeq')}
                </span>
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}
