import React, { Component } from 'react';
import { Form, Input, Button, Modal, Table, message } from 'antd';
import PropTypes from 'prop-types';
import { services } from '@comall-backend-builder/core';
import { filter } from 'lodash';
import './index.less';

const api = services.api;

interface VirtualNumberItem {
    id: number;
    virtualNumber: string;
    modifiedUser: string;
    createTime: string;
}

interface VirtualNumberEditFormState {
    virtualNumberList: VirtualNumberItem[];
    searchCode: string;
    showModal: boolean;
    addNumber: string;
    editItem?: VirtualNumberItem;
}

const CollectionCreateForm = Form.create({ name: 'searchForm' })(
    class extends React.Component {
        static propTypes: {
            onSearch: PropTypes.Requireable<(...args: any[]) => any>;
        };
        handleSearch = (e: any) => {
            e.preventDefault();
            const { form, onSearch } = this.props as any;
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSearch(values);
                }
            });
        };
        handleReset = () => {
            const { form } = this.props as any;
            form.resetFields();
        };
        render() {
            const { form } = this.props as any;
            const { getFieldDecorator } = form;
            return (
                <Form layout="inline" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Form.Item label="虚拟号段">
                        {getFieldDecorator('phoneCode')(
                            <Input
                                placeholder={services.language.getText('inputPlease')}
                                style={{ width: 190 }}
                            />
                        )}
                    </Form.Item>
                    <div>
                        <Form.Item style={{ marginLeft: 'auto' }}>
                            <Button type="primary" onClick={this.handleSearch}>
                                查询
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="reset" onClick={this.handleReset}>
                                重置
                            </Button>
                        </Form.Item>
                    </div>
                </Form>
            );
        }
    }
);

export class VirtualNumberEditForm extends Component<any, VirtualNumberEditFormState> {
    state: VirtualNumberEditFormState = {
        virtualNumberList: [],
        searchCode: '',
        showModal: false,
        addNumber: '',
        editItem: undefined,
    };
    componentDidMount(): void {
        this.loadData();
    }
    loadData = async () => {
        try {
            const config = {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PASSPORT`,
                apiPath: '/admin/securitypolicy/virtualNumber',
            };
            const res = await api.get<VirtualNumberItem[]>({}, config);
            this.setState({
                virtualNumberList: res,
            });
        } catch (error) {
            services.errorHandle(error as any);
        }
    };
    onChangeParams = (searchParams: { phoneCode: string }) => {
        this.setState({
            searchCode: searchParams.phoneCode,
        });
    };

    onShowAddMoadl = () => {
        this.setState({
            showModal: true,
        });
    };
    closeAddModal = () => {
        this.setState({
            showModal: false,
            addNumber: '',
            editItem: undefined,
        });
    };
    onAddNumber = async () => {
        const { addNumber, virtualNumberList, editItem } = this.state;
        if (addNumber.length < 3) {
            message.warning('虚拟号段必须为3-11位数字，首位必须是1');
            return;
        }
        const numberList = editItem
            ? virtualNumberList
                  .filter((i) => i.virtualNumber !== editItem.virtualNumber)
                  .map((i) => i.virtualNumber)
            : virtualNumberList.map((i) => i.virtualNumber);
        if (numberList.includes(addNumber)) {
            message.warning('虚拟号已存在');
            return;
        }
        if (editItem) {
            await this.editVirtualNumber(editItem.id, addNumber);
        } else {
            await this.addVirtualNumber(addNumber);
        }
        this.closeAddModal();
    };

    addVirtualNumber = async (addVirtualNumber: string) => {
        try {
            const config = {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PASSPORT`,
                apiPath: '/admin/securitypolicy/virtualNumber',
            };
            await api.post({ virtualNumber: addVirtualNumber }, config);
            await this.loadData();
        } catch (error) {
            services.errorHandle(error as any);
        }
    };
    editVirtualNumber = async (id: number, addVirtualNumber: string) => {
        try {
            const config = {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PASSPORT`,
                apiPath: '/admin/securitypolicy/virtualNumber',
            };
            await api.put({ id, virtualNumber: addVirtualNumber }, config);
            await this.loadData();
        } catch (error) {
            services.errorHandle(error as any);
        }
    };

    onAddNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (!value || /^1\d{0,10}$/.test(value)) {
            this.setState({
                addNumber: value,
            });
        }
    };
    onEditItem = (row: VirtualNumberItem) => {
        this.setState({
            editItem: row,
            addNumber: row.virtualNumber,
            showModal: true,
        });
    };
    onRemoveItem = async (row: VirtualNumberItem) => {
        try {
            const config = {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PASSPORT`,
                apiPath: `/admin/securitypolicy/virtualNumber/${row.id}`,
            };
            await api.delete({}, config);
            await this.loadData();
        } catch (error) {
            services.errorHandle(error as any);
        }
    };

    renderFilter = () => {
        const classes = {
            filter: `${prefix}__filter`,
        };
        return (
            <div className={classes.filter}>
                <CollectionCreateForm onSearch={this.onChangeParams} />
            </div>
        );
    };

    renderButton = () => {
        const classes = {
            buttons: `${prefix}__buttons`,
        };
        return (
            <div className={classes.buttons}>
                <Button type="primary" onClick={this.onShowAddMoadl}>
                    添加虚拟号段
                </Button>
            </div>
        );
    };

    renderMoadl = () => {
        const { showModal, addNumber } = this.state;
        const classes = {
            add: `${prefix}__add`,
            label: `${prefix}__add__label`,
        };
        return (
            <Modal
                width={400}
                onCancel={this.closeAddModal}
                onOk={this.onAddNumber}
                visible={showModal}
                title="虚拟号段"
            >
                <div className={classes.add}>
                    <span className={classes.label}>虚拟号段</span>
                    <Input
                        value={addNumber}
                        placeholder="3-11位数字,首位必须为1"
                        style={{ width: 230 }}
                        onChange={this.onAddNumberChange}
                    />
                </div>
            </Modal>
        );
    };

    renderTable = () => {
        const { virtualNumberList, searchCode } = this.state;
        const currentVirtualNumberList = searchCode
            ? filter(virtualNumberList, (o) => o.virtualNumber === searchCode)
            : virtualNumberList;
        return (
            <Table
                pagination={false}
                scroll={{ y: '600px' }}
                rowKey={'id'}
                dataSource={currentVirtualNumberList}
                columns={[
                    {
                        title: '虚拟号段',
                        dataIndex: 'virtualNumber',
                        key: 'virtualNumber',
                    },
                    {
                        title: services.language.getText('lastModifiedUser'),
                        dataIndex: 'modifiedUser',
                        key: 'modifiedUser',
                    },
                    {
                        title: services.language.getText('tjsj_1'),
                        dataIndex: 'createTime',
                        key: 'createTime',
                    },
                    {
                        title: services.language.getText('common.tableAction'),
                        className: 'action-column',
                        width: 200,
                        render: (row: VirtualNumberItem) => {
                            return (
                                <div>
                                    <Button
                                        type={'link'}
                                        onClick={this.onEditItem.bind(this, row)}
                                        style={{ padding: '0' }}
                                    >
                                        编辑
                                    </Button>
                                    <Button
                                        type={'link'}
                                        onClick={this.onRemoveItem.bind(this, row)}
                                        style={{ padding: '0' }}
                                    >
                                        {services.language.getText('common.delete')}
                                    </Button>
                                </div>
                            );
                        },
                    },
                ]}
            />
        );
    };
    render() {
        return (
            <div className={prefix}>
                {this.renderFilter()}
                {this.renderButton()}
                {this.renderTable()}
                {this.renderMoadl()}
            </div>
        );
    }
}

const prefix = 'virtual-number-edit-form';
