import React, { PureComponent } from 'react';
import { map, cloneDeep, remove, uniqBy, isEmpty, get, isFunction } from 'lodash';
import { Popconfirm, Button, Icon, Row, Col } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CouponSelector } from '../../tools/coupon-selector';
import { tools } from '@comall-backend-builder/design-components-basis';
import { language } from '@comall-backend-builder/core/lib/services';

const LinkType = tools.LinkType;
export class ShowCoupons extends PureComponent<any> {
    removeCoupon = (item: any) => {
        const { onChange, value } = this.props;
        let newValue = cloneDeep(value);
        remove(newValue, { id: item.id });
        onChange && onChange(newValue);
    };

    clearAll = () => {
        const { onChange } = this.props;
        onChange([]);
    };

    changeTarget = (selected: any) => {
        const { onChange, value } = this.props;
        onChange(uniqBy(value.concat(selected), 'id'));
    };

    changeLinkType = (item: any, index: any) => {
        const { value, onChange } = this.props;
        let newItems: any = cloneDeep(value);
        newItems.splice(index, 1, item);
        onChange && onChange(newItems);
    };

    renderCoupons(coupons: any) {
        const isDragDropDisabled = coupons.length === 1;
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable
                    isDropDisabled={isDragDropDisabled}
                    droppableId="coupon-list"
                    direction="vertical"
                >
                    {(provided: any) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {map(coupons, (item, index) =>
                                this.renderCoupon(item, index, isDragDropDisabled)
                            )}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        );
    }

    renderCoupon = (item: any, index: any, isDragDisabled: any) => {
        const { linkTypes, selector } = this.props;
        return (
            <Draggable
                isDragDisabled={isDragDisabled}
                draggableId={'coupon-' + index}
                key={index}
                index={index}
            >
                {(provided: any) => {
                    return (
                        <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                        >
                            <Row className="entry-item">
                                <Icon
                                    type="close"
                                    className="item-remove"
                                    title="移除"
                                    onClick={() => this.removeCoupon(item)}
                                />
                                <Col className="entry-item-content" span={18}>
                                    <div>{item.name}</div>
                                    <LinkType
                                        selector={selector}
                                        linkTypes={linkTypes}
                                        linkTypeMode={'cascader'}
                                        value={item}
                                        onChange={(newItem) => this.changeLinkType(newItem, index)}
                                    />
                                </Col>
                            </Row>
                        </div>
                    );
                }}
            </Draggable>
        );
    };
    onDragEnd = (result: any) => {
        if (!result.destination) {
            return;
        }
        const { value, onChange } = this.props;
        let newValue = cloneDeep(value);
        const startIndex = result.source.index;
        const endIndex = result.destination.index;
        const [removed] = newValue.splice(startIndex, 1);
        newValue.splice(endIndex, 0, removed);

        onChange(cloneDeep(newValue));
    };
    render() {
        const { value, selector = {} } = this.props;
        const hasCoupons = !isEmpty(value);
        const couponsProps = {
            value: value,
            onChange: this.changeTarget,
        };
        const customRenderSelector = get(selector, 'coupon.render');
        return (
            <div className="coupons-show entry-list">
                {this.renderCoupons(value)}
                {hasCoupons && (
                    <Popconfirm
                        title="确定清空优惠券吗?"
                        onConfirm={this.clearAll}
                        okText={language.getText('common.ok')}
                        cancelText={language.getText('common.cancel')}
                    >
                        <Button className="show-action" type={'link'}>
                            清空优惠券
                        </Button>
                    </Popconfirm>
                )}
                {isFunction(customRenderSelector) ? (
                    customRenderSelector(couponsProps)
                ) : (
                    <CouponSelector
                        modalConfig={get(selector, 'coupon.modalConfig', {})}
                        contentConfig={get(selector, 'coupon.contentConfig', {})}
                        {...couponsProps}
                    />
                )}
            </div>
        );
    }
}
