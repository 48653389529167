import React, { PureComponent, Fragment } from 'react';
import Print from '../print';
import { PickintOrderTemplate } from '../picking-order-template';
import { PickingOrderTicketTemplate } from '../picking-order-ticket-template';
import { PickingLists, PickingListPrintType } from '../../../services/order-print';

/**
 * 拣货单面单模板
 */
export class PickOrdersTemplate extends PureComponent<
    {
        data: PickingLists[];
        setWrappedInstance: (ref: any) => void;
    },
    any
> {
    constructor(props: any) {
        super(props);
        this.setRef = this.setRef.bind(this);
    }
    setRef = (ref: any) => {
        const { setWrappedInstance } = this.props;
        if (setWrappedInstance) {
            setWrappedInstance(ref);
        }
    };
    render() {
        const { data = [] } = this.props;
        let orderKey = 1;
        return (
            <Print ref={this.setRef} insertHead={false}>
                <div style={{ display: 'none' }}>
                    {data &&
                        data.map((order: PickingLists) => {
                            orderKey += 1;
                            const printType = order.printType;
                            return (
                                <Fragment key={orderKey}>
                                    {printType === PickingListPrintType.A4 && (
                                        <PickintOrderTemplate order={order} />
                                    )}
                                    {printType !== PickingListPrintType.A4 && (
                                        <PickingOrderTicketTemplate order={order} />
                                    )}
                                </Fragment>
                            );
                        })}
                </div>
            </Print>
        );
    }
}
