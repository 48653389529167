import React, { PureComponent, createElement } from 'react';
import { message as AntMessage, Button, Popconfirm } from 'antd';
import { remove, find, chunk, map, cloneDeep } from 'lodash';
import { connect } from 'react-redux';
import { services, ComponentsManager } from '@comall-backend-builder/core';
import { Target } from '../../components/page-new-preview-code-button';
import { BigCodesPrint } from '../../components/big-codes-print';
import { language } from '@comall-backend-builder/core/lib/services';
const api = services.api;

/**
 * 专柜商家码列表
 */
class merchantCodeTable extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedRows: [],
            bigCodes: [],
        };
        this.setReceiptRef = this.setReceiptRef.bind(this);
    }
    printLoading = false;
    pickOrderRef = null;
    setReceiptRef(ref: any) {
        this.pickOrderRef = ref;
    }
    renderComponent() {
        const { entity, componentId } = this.props;
        // const { selectedRows } = this.state;
        const selectedRowKeys = map(this.state.selectedRows, 'id');
        let config = {
            component: 'DataTable',
            scroll: { x: true },
            entity,
            componentId,
            loadFirstPage: true,
            rowKey: 'id',
            rowSelection: {
                selectedRowKeys,
                onChange: (e: any) => {
                    // SELECTED_ROW = [];
                },
                onSelect: (record: any, selected: boolean) => {
                    this.onTableRowSelect(record, selected);
                },
                onSelectAll: (selected: boolean, rows: any) => {
                    this.onTableRowSelectAll(selected, rows);
                },
            },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'settingInfo.pictures',
                    displayConfig: {
                        style: {
                            width: 60,
                            height: 60,
                        },
                    },
                },
                {
                    property: 'baseInfo.merchantBigName',
                },
                /*  {
                    property: 'baseInfo.bigCode',
                },
                {
                    property: 'baseInfo.price',
                }, */
                {
                    property: 'baseInfo.allowPriceAdjustment',
                },
                {
                    property: 'settingInfo.supportInvoicing',
                },
                {
                    property: 'settingInfo.listAllowDepositValue',
                },
                {
                    property: 'settingInfo.sequence',
                },
                {
                    property: 'baseInfo.subsiteName',
                },
                {
                    property: 'baseInfo.merchantName',
                    width: 260,
                },
                {
                    property: 'baseInfo.merchantType',
                },
                {
                    property: 'baseInfo.enabled',
                    displayConfig: {
                        statusConfig: {
                            true: {
                                text: language.getText('enable'),
                                status: 'success',
                            },
                            false: {
                                text: language.getText('disabled'),
                                status: 'error',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            type: 'link',
                            statusKey: 'enabled',
                            renderComponent: 'Button',
                            style: { display: 'inline-flex' },
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                            handles: [
                                {
                                    value: false,
                                    apiPath: '/admin/merchant_big_codes/enabled',
                                    params: {},
                                    config: {
                                        content: language.getText('sjmqy'),
                                        text: language.getText('common.opend'),
                                    },
                                    confirm: {
                                        okText: language.getText('yes'),
                                        noText: language.getText('no'),
                                        text: language.getText('sfqygsjm'),
                                    },
                                },
                                {
                                    value: true,
                                    apiPath: '/admin/merchant_big_codes/disabled',
                                    params: {},
                                    config: {
                                        content: language.getText('sjmjy'),
                                        text: language.getText('disabled'),
                                    },
                                    confirm: {
                                        okText: language.getText('yes'),
                                        noText: language.getText('no'),
                                        text: language.getText('sfjygsjm'),
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: language.getText('common.edit'),
                        path: '/merchant-big-codes/edit/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'PageNewPreviewCodeButton',
                        config: {
                            buttonTitle: language.getText('qrCode'),
                            codeTypes: 'all',
                            codeModalType: 'item',
                            codePageType: 'cashierCounter',
                        },
                    },
                    {
                        type: 'delete',
                        text: services.language.getText('common.delete'),
                        config: {
                            type: 'link',
                            tooltip: null,
                            title: language.getText('sfqrsc'),
                            okText: language.getText('yes'),
                            cancelText: language.getText('no'),
                        },
                    },
                ],
            },
            title: () => {
                return (
                    <div>
                        <Popconfirm
                            onConfirm={this.batchEnabledGoods}
                            title={services.language.getText('sfqyyxzsjm')}
                            okText={language.getText('yes')}
                            cancelText={language.getText('no')}
                        >
                            <Button className="setting-item-ok" type="primary" ghost>
                                {language.getText('plqy')}
                            </Button>
                        </Popconfirm>
                        &nbsp;&nbsp;
                        <Popconfirm
                            onConfirm={this.batchDisabledGoods}
                            title={language.getText('sfjyyxzsjm')}
                            okText={language.getText('yes')}
                            cancelText={language.getText('no')}
                        >
                            <Button className="setting-item-ok" type="primary" ghost>
                                {language.getText('pljy')}
                            </Button>
                        </Popconfirm>
                        &nbsp;&nbsp;
                        <Popconfirm
                            onConfirm={this.batchDeleteGoods}
                            title={language.getText('sfqrscyxzsjm')}
                            okText={language.getText('yes')}
                            cancelText={language.getText('no')}
                        >
                            <Button className="setting-item-ok" type="primary" ghost>
                                {services.language.getText('batchDelete')}
                            </Button>
                        </Popconfirm>
                        &nbsp;&nbsp;
                        <Button
                            className="setting-item-ok"
                            type="primary"
                            ghost
                            onClick={this.onBatchPrint}
                        >
                            {language.getText('pldy')}
                        </Button>
                    </div>
                );
            },
        };

        return createElement(ComponentsManager.get('DataTable'), config);
    }

    onBatchPrint = () => {
        const { selectedRows } = this.state;
        if (this.printLoading) {
            return;
        }
        if (!selectedRows.length) {
            AntMessage.error(language.getText('zsxzygsjmdy'));
            return;
        }
        const duplicateList = cloneDeep(selectedRows);
        this.clearSelectedRows();
        this.printLoading = true;
        AntMessage.loading(language.getText('generating'), 0);
        const apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        const apiPath = '/admin/mini_program_codes/batch_append';
        const commands: any[] = [];
        duplicateList.forEach((item: any) => {
            const { merchantInfo, bigCode } = item;
            const targetInfo = new Target({
                row: item,
                codeTypes: 'all',
                codeModalType: 'item',
                codePageType: 'cashierCounter',
                getSubsitesArray: () => {},
            });
            const { linkName, linkType, getTarget } = targetInfo;
            const { target, linkPath } = getTarget(item.subsiteId);
            commands.push({
                name: `${merchantInfo.name}-${merchantInfo.code}-${bigCode}`,
                pageName: linkName,
                needUrlLink: true,
                target,
                subsiteId: item.subsiteId,
                merchantIds: undefined,
                applicationVersion: 'V2',
                channel: language.getText('defaultChannel'),
                channelType: '',
                statistic_type: 'CHANNEL',
                type: linkType,
                url: linkPath,
            });
        });
        const servicesList: any[] = [];
        chunk(commands, 5).forEach((items: any) => {
            servicesList.push(services.api.post(items, { apiRoot, apiPath }));
        });
        Promise.all(servicesList)
            .then((resList: any) => {
                const bigCodes: any[] = [];
                resList.forEach((res: any, rowIndex: number) => {
                    res.forEach((item: any, index: number) => {
                        const codeItem = duplicateList[rowIndex * 5 + index];
                        const codeFile = find(item.codeFiles, {
                            codeFileType: 'AGGREGATION_CODE',
                        });
                        bigCodes.push({
                            id: codeItem?.bigCode,
                            name: codeItem?.name,
                            subsiteName: codeItem?.subsiteInfo?.name,
                            merchantName: codeItem?.merchantInfo?.name,
                            url: codeFile?.fileUrl,
                        });
                    });
                });
                AntMessage.destroy();
                this.printLoading = false;
                this.setState(
                    {
                        bigCodes,
                    },
                    () => {
                        if (this.pickOrderRef) {
                            (this.pickOrderRef as any).onPrint();
                            this.setState({
                                bigCodes: [],
                            });
                        }
                    }
                );
            })
            .catch(() => {
                AntMessage.destroy();
                this.printLoading = false;
                AntMessage.error(language.getText('qqdyqdmsb'));
            });
    };

    onTableRowSelect = (record: any, selected: boolean) => {
        const { selectedRows } = this.state;
        const rows = cloneDeep(selectedRows);
        if (selected) {
            rows.push(record);
        } else {
            remove(rows, (i: any) => {
                return i.id === record.id;
            });
        }
        this.setState({
            selectedRows: rows,
        });
    };

    onTableRowSelectAll = (selected: boolean, rows: any[]) => {
        if (selected) {
            this.setState({ selectedRows: rows });
        } else {
            this.setState({ selectedRows: [] });
        }
    };
    //删除
    batchDeleteGoods = () => {
        const { entity } = this.props;
        const { selectedRows } = this.state;
        if (!selectedRows.length) {
            AntMessage.error(services.language.getText('qxzsjm'));
            return;
        }
        let ids = selectedRows.map((item: any) => item.id);
        api.put(
            {
                big_code_ids: ids,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: '/admin/merchant_big_codes/batch_delete',
            }
        ).then(
            () => {
                AntMessage.success(language.getText('sjmsccg'));
                this.clearSelectedRows();
                entity.search();
            },
            (error) => {
                services.errorHandle(error);
            }
        );
    };
    clearSelectedRows = () => {
        this.setState({
            selectedRows: [],
        });
    };
    //启用
    batchEnabledGoods = () => {
        const { entity } = this.props;
        const { selectedRows } = this.state;
        if (!selectedRows.length) {
            AntMessage.error(services.language.getText('qxzsjm'));
            return;
        }
        let ids = selectedRows.map((item: any) => item.id);
        api.put(
            {
                big_code_ids: ids,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: '/admin/merchant_big_codes/enabled',
            }
        ).then(
            () => {
                AntMessage.success(language.getText('sjmqycg'));
                this.clearSelectedRows();
                entity.search();
            },
            (error) => {
                services.errorHandle(error);
            }
        );
    };
    //禁用
    batchDisabledGoods = () => {
        const { entity } = this.props;
        const { selectedRows } = this.state;
        if (!selectedRows.length) {
            AntMessage.error(services.language.getText('qxzsjm'));
            return;
        }
        let ids = selectedRows.map((item: any) => item.id);
        api.put(
            {
                big_code_ids: ids,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                apiPath: '/admin/merchant_big_codes/disabled',
            }
        ).then(
            () => {
                AntMessage.success(language.getText('sjmjycg'));
                this.clearSelectedRows();
                entity.search();
            },
            (error) => {
                services.errorHandle(error);
            }
        );
    };

    renderPrint = () => {
        const { bigCodes } = this.state;
        if (bigCodes.length) {
            return <BigCodesPrint setWrappedInstance={this.setReceiptRef} bigCodes={bigCodes} />;
        }
        return null;
    };

    render() {
        return (
            <div>
                {this.renderComponent()}
                {this.renderPrint()}
            </div>
        );
    }
}

function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    return {
        entity,
        componentId,
        fields: entity.fields,
    };
}

export const MerchantCodeTableView = connect(mapStateToProps)(merchantCodeTable);
