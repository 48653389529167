import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { services } from '@comall-backend-builder/core';
import { Input, InputNumber, message, message as AntMessage } from 'antd';
import { FormComponentProps } from 'antd/lib/form';
import { api } from '@comall-backend-builder/core/lib/services';
import { DateTimePicker } from '@comall-backend-builder/components-basis';
import { SelectShop, Shop } from '../../select-shop';
import { ShopSelectedDisplayMode } from '../../../components';

import './index.less';

export interface PhotoCreditReviewEditAgreeFormProps extends FormComponentProps {
    onSubmit: (e: any, fields: any) => void;
    onInit: () => void;
    getForm: () => any;
    visible: boolean;
    id: string;
    data: any;
    changeVisible: () => void;
    refreshList: () => void;
    params: any;
    submitRef: {
        current: any;
    };
}

export interface PhotoCreditReviewEditAgreeFormStates {
    data: any | undefined;
    visible: boolean;
}
/**
 * 添加奖励
 */
class photoCreditReviewEditAgreeForm extends PureComponent<
    PhotoCreditReviewEditAgreeFormProps,
    PhotoCreditReviewEditAgreeFormStates
> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: undefined,
            visible: false,
        };
    }

    componentDidMount() {
        const { submitRef, data } = this.props;
        if (submitRef) {
            submitRef.current = this.onSubmit;
        }
        if (data) {
            this.setState({
                data: data,
            });
        }
    }

    onSubmit = () => {
        const hasValidate = this.validate();
        const { data } = this.state;
        const { params, refreshList } = this.props;
        if (hasValidate) {
            api.put(
                {
                    amount: data.amount,
                    consumptionTime: data.consumptionTime,
                    goodsNum: data.goodsNum,
                    id: params.id,
                    orderNo: data.orderNo,
                    status: 'SUCCESS',
                    subsiteId: data.shop && data.shop.subSiteId,
                    merchantCode: data.shop && data.shop.code,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`,
                    apiPath: '/admin/v2/photo_crediting',
                }
            )
                .then((res) => {
                    if (!!res) {
                        AntMessage.success(services.language.getText('shcg'));
                    } else {
                        AntMessage.error('审核失败，请联系客服处理');
                    }
                })
                .catch((error) => {
                    services.errorHandle(error);
                })
                .finally(() => {
                    refreshList();
                });
        }
    };
    /**
     * 提交前额外验证
     * @param entityFields
     */
    validate = () => {
        const { data } = this.state;
        if (!data.shop || (data.shop && !data.shop.subSiteId)) {
            message.warning('请选择门店及专柜');
            return false;
        }
        if (!data.consumptionTime) {
            message.warning(services.language.getText('qtxxfsj'));
            return false;
        }
        if (!data.amount || (data.amount && data.amount < 0)) {
            message.warning('请填写消费金额，消费金额支持输入>0的数字，支持两位小数');
            return false;
        }
        return true;
    };

    onMerchantChange = (merchants: Shop[]) => {
        const { data } = this.state;
        if (merchants && merchants.length > 0) {
            data.shop = merchants[0];
        } else {
            data.shop = null;
        }
        this.setState(
            {
                data,
            },
            () => {
                this.forceUpdate();
            }
        );
    };

    handleChange = (key: string, val: string) => {
        let { data } = this.state;
        data[key] = val;
        this.setState(
            {
                data: data,
            },
            () => {
                this.forceUpdate();
            }
        );
    };

    onhandlePickerChange(valueString: any) {
        let { data } = this.state;
        data['consumptionTime'] = valueString;
        this.setState(
            {
                data: data,
            },
            () => {
                this.forceUpdate();
            }
        );
        return {};
    }

    renderModal = () => {
        const { data } = this.state;
        return (
            data && (
                <div>
                    <div className="photo-credit-review-edit-agree-form__flex">
                        <div className="photo-credit-review-edit-agree-form__title">
                            <span className="photo-credit-review-edit-agree-form__tip">*</span>
                            门店名称：
                        </div>
                        <div className="photo-credit-review-edit-agree-form__value">
                            <SelectShop
                                value={data.shop ? [data.shop] : []}
                                type="radio"
                                displayMode={ShopSelectedDisplayMode.SINGLE}
                                onChange={this.onMerchantChange}
                            />
                        </div>
                    </div>
                    <div className="photo-credit-review-edit-agree-form__flex">
                        <div className="photo-credit-review-edit-agree-form__title">订单编号：</div>
                        <div className="photo-credit-review-edit-agree-form__value">
                            <Input
                                style={{ width: '300px' }}
                                value={data.orderNo}
                                onChange={(e) => this.handleChange('orderNo', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="photo-credit-review-edit-agree-form__flex">
                        <div className="photo-credit-review-edit-agree-form__title">商品数量：</div>
                        <div className="photo-credit-review-edit-agree-form__value">
                            <InputNumber
                                placeholder={services.language.getText('inputPlease')}
                                value={data.goodsNum}
                                style={{ width: '300px' }}
                                min={1}
                                max={99999}
                                step={1}
                                precision={0}
                                onChange={(value: any) => this.handleChange('goodsNum', value)}
                            ></InputNumber>
                        </div>
                    </div>
                    <div className="photo-credit-review-edit-agree-form__flex">
                        <div className="photo-credit-review-edit-agree-form__title">
                            <span className="photo-credit-review-edit-agree-form__tip">*</span>
                            消费时间：
                        </div>
                        <div className="photo-credit-review-edit-agree-form__value">
                            <DateTimePicker
                                style={{ width: '300px' }}
                                name="consumptionTime"
                                value={data.consumptionTime}
                                onChange={(time) => this.onhandlePickerChange(time)}
                            />
                        </div>
                    </div>
                    <div className="photo-credit-review-edit-agree-form__flex">
                        <div className="photo-credit-review-edit-agree-form__title">
                            <span className="photo-credit-review-edit-agree-form__tip">*</span>
                            {services.language.getText('xfje')}：
                        </div>
                        <div className="photo-credit-review-edit-agree-form__value">
                            <InputNumber
                                placeholder={services.language.getText('inputPlease')}
                                value={data.amount}
                                style={{ width: '300px' }}
                                min={0}
                                max={99999999999999}
                                step={1}
                                precision={2}
                                onChange={(value: any) => this.handleChange('amount', value)}
                            ></InputNumber>
                        </div>
                    </div>
                </div>
            )
        );
    };

    render() {
        return <div className="photo-credit-review-edit-agree-form">{this.renderModal()}</div>;
    }
}

function mapStateToProps(state: any, props: any) {
    const { entity } = props;
    return entity;
}

export const PhotoCreditReviewEditAgreeForm = connect(mapStateToProps)(
    photoCreditReviewEditAgreeForm
);
