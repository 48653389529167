import { services } from '@comall-backend-builder/core';
import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { get, map } from 'lodash';
import moment from 'moment';
import { MerchantSelfType } from '../../config/merchant/merchant-manager/merchant-self';
const { api } = services;

export const WechatOfficialAccountQrcodeRulesLoader: Loader = {
    get(params, config) {
        const { id } = params;
        if (id) {
            return api.get<Record<string, any>>(params, config).then(transformResponseToEntity);
        }
        return api.get<Record<string, any>>(params, config).then(transformResponseResult);
    },
    post(params, config) {
        return api.post(transformEntityToReqeust(params), config);
    },
    put(params, config) {
        return api.put(transformEntityToReqeust(params), config);
    },
};

function transformResponseResult(response: Record<string, any>) {
    return {
        ...response,
        result: response.result?.map((res: any) => ({
            ...res,
            wechatOfficialAccountName: res.wechatOfficialAccount.name,
            replyEffectTime:
                res.replyStartTime && res.replyEndTime
                    ? '自定义'
                    : services.language.getText('longTerm'),
            replyStartTime: res.replyStartTime || '',
            replyEndTime: res.replyEndTime || '',
            subsite: res.subsiteWithMerchant?.name || '',
            merchant: res.subsiteWithMerchant?.merchant?.name
                ? `${res.subsiteWithMerchant?.merchant?.name}[${res.subsiteWithMerchant?.merchant?.code}]`
                : '',
            openMiniProgramPercentage:
                res.openMiniProgramPercentage && `${res.openMiniProgramPercentage}%`,
        })),
    };
}

function transformResponseToEntity(response: Record<string, any>) {
    const { createTime, effectiveDays, subsiteWithMerchant } = response;
    let effectiveTypeDisplay = '永久';
    if (effectiveDays) {
        const createMoment = moment(createTime);
        const endMoment = createMoment.add(effectiveDays, 'day');
        const endTime = endMoment.format('YYYY.MM.DD HH:mm:ss');
        effectiveTypeDisplay = `临时-${endTime}后失效`;
    }
    const subsite = [],
        merchant = [];
    if (subsiteWithMerchant) {
        const { address, code, id, merchant: subsiteMerchant, name, status } = subsiteWithMerchant;
        subsite.push({
            address,
            code,
            id,
            name,
            status,
        });
        const merchantStatusMap: Record<any, any> = { 0: '筹备', 1: '开店', 2: '闭店' };
        const merchantSelfTypeOptions: Record<any, any> = {
            [MerchantSelfType.JOINT]: '联营',
            [MerchantSelfType.MERCHANT_SELF]: '自营',
            [MerchantSelfType.LEASE]: '租赁',
            [MerchantSelfType.LEAGUE]: '加盟',
            [MerchantSelfType.OTHER]: '其他',
            [MerchantSelfType.SELF]: '自营',
        };
        if (subsiteMerchant) {
            merchant.push({
                ...subsiteMerchant,
                merchantId: subsiteMerchant.id,
                merchantName: subsiteMerchant.name,
                merchantStatus: merchantStatusMap[subsiteMerchant.status],
                merchantType: merchantSelfTypeOptions[subsiteMerchant.type],
                select: true,
                subSiteName: subsiteWithMerchant.name,
            });
        }
    }

    return {
        ...response,
        wechatOfficialAccountName: response.wechatOfficialAccount.name,
        baseInfo: {
            wechatOfficialAccount: response.wechatOfficialAccount,
            name: response.name,
            effectiveType: response.effectiveDays ? 'temporary' : 'eternal',
            effectiveDays: response.effectiveDays,
            replyEffectiveType:
                response.replyStartTime && response.replyEndTime ? 'temporary' : 'eternal',
            replyDateRange:
                response.replyStartTime && response.replyEndTime
                    ? {
                          start: response.replyStartTime,
                          end: response.replyEndTime,
                      }
                    : null,
            effectiveTypeDisplay,
            dimensionType: response.dimensionType,
            subsite,
            merchant,
        },
        content: {
            useDefaultReply: response.useDefaultReply,
            replyContents: response.replyContents
                ? response.replyContents.map((content: any) => {
                      content.messageContent = JSON.parse(content.messageContent);
                      return content;
                  })
                : [],
        },
    };
}

function transformEntityToReqeust(entity: any) {
    const {
        baseInfo: {
            replyDateRange,
            effectiveType,
            replyEffectiveType,
            subsite,
            dimensionType,
            merchant: merchants,
            wechatOfficialAccount,
            ...baseInfo
        },
        content: { useDefaultReply, replyContents },
        ...rest
    } = entity;
    const { start: replyStartTime, end: replyEndTime } = replyDateRange || {};
    const merchant = get(merchants, '[0]');
    const merchantStatusMap: Record<any, any> = { 筹备: 0, 开店: 1, 闭店: 2 };
    const request = {
        ...rest,
        ...baseInfo,
        useDefaultReply,
        replyContents: map(replyContents, (content) => ({
            ...content,
            messageContent: JSON.stringify(content.messageContent),
        })),
        replyStartTime: replyEffectiveType === 'temporary' ? replyStartTime : undefined,
        replyEndTime: replyEffectiveType === 'temporary' ? replyEndTime : undefined,
        appId: wechatOfficialAccount.appId,
        dimensionType: dimensionType ? dimensionType : 'CHANNEL',
    };
    if (dimensionType === 'SUBSITE') {
        request.subsite = get(subsite, '[0]');
    } else if (dimensionType === 'MERCHANT') {
        request.merchant = {
            code: merchant.code,
            id: merchant.merchantId,
            name: merchant.merchantName,
            status: merchantStatusMap[merchant.merchantStatus],
            type: merchant.merchantType,
        };
    }
    return request;
}
