import React, { createElement, PureComponent } from 'react';
import { isNil, map } from 'lodash';
import { ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { navigation } from '@comall-backend-builder/core/lib/services';
import { ComponentConfig } from '@comall-backend-builder/components-basis/lib/flex-layout';
import {
    CustomStyleComponentProps,
    ExtendedParentComponentProps,
    PrivilegeComponentProps,
} from '@comall-backend-builder/components-basis';

import {
    default as AntTabs,
    TabsProps as AntTabsProps,
    TabPaneProps as AntTabPaneProps,
} from 'antd/lib/tabs';
import { Icon } from 'antd';

export interface TabPaneProps
    extends Pick<AntTabPaneProps, 'forceRender'>,
        CustomStyleComponentProps,
        ExtendedParentComponentProps,
        PrivilegeComponentProps {
    /**
     * 标签标题
     */
    title: string;
    /**
     * 标签图标类型
     */
    icon: string;
    /**
     * 标签面板内使用的组件配置
     */
    content: ComponentConfig;
    /**
     * 获取
     */
    key?: string;
}

export interface TabsProps
    extends Pick<
            AntTabsProps,
            'animated' | 'defaultActiveKey' | 'size' | 'tabBarStyle' | 'tabPosition'
        >,
        CustomStyleComponentProps,
        ExtendedParentComponentProps {
    /**
     * 标签面板配置
     */
    items: Array<TabPaneProps>;
    /**
     * 是否采用缓存，如果为 false，切换 tab 时会重新加载组件
     */
    cache?: boolean;
    /**
     * 基础路由路径，如果不传，则从地址栏url截取
     */
    basePath?: string;
}

type TabsState = {
    activeKey: string;
};

const AntTabPane = AntTabs.TabPane;

export class TabsPlus extends PureComponent<TabsProps, TabsState> {
    /**
     * Tabs组件默认使用缓存策略，同时默认显示的标签为第一个
     * @type {{defaultActiveKey: string, cache: boolean}}
     */
    static defaultProps: Pick<TabsProps, 'defaultActiveKey' | 'cache'> = {
        defaultActiveKey: '0',
        cache: true,
    };

    constructor(props: TabsProps) {
        super(props);
        const { defaultActiveKey } = props;
        const activeKey = this.getTabKey();
        this.state = {
            activeKey: activeKey ? activeKey : defaultActiveKey!,
        };
    }

    // 用于记录每个tab下当前的页码
    pages: any = {};

    onChange = (key: string) => {
        // 当tabs下的所有tab公用一个实体实例时。记录每个tab下的页码，并且在切换时使用独立页码。避免因为公用页码导致的无数据问题
        const { entity, basePath } = this.props;
        const { activeKey } = this.state;
        if (entity && entity.paging && entity.paging.page) {
            this.pages[activeKey] = entity.paging.page;
            entity.paging.page = this.pages[key] ? this.pages[key] : 1;
        }
        // 修改地址路径，用于返回页面时识别选中的tab
        const history = navigation.getHistory();
        const pathname = basePath || navigation.getPathname().split('/#')[0];
        const childrenRoute = pathname.slice(-1) === '/' ? '#' + key : '/#' + key;
        history.replace({ pathname: pathname + childrenRoute });
        this.setState({
            activeKey: key,
        });
    };

    getTabKey = () => {
        const history = navigation.getHistory();
        const {
            location: { hash },
        } = history;
        if (!!hash) {
            return hash.slice(1);
        } else {
            return null;
        }
    };

    private getPaneKey(item: TabPaneProps, index: number) {
        if (!isNil(item.key)) {
            return item.key;
        }
        return String(index);
    }

    render() {
        const {
            className,
            style,
            items,
            animated,
            defaultActiveKey,
            size,
            tabBarStyle,
            tabPosition,
        } = this.props;
        let props = {
            className,
            style,
            animated,
            size,
            tabBarStyle,
            tabPosition,
            onChange: this.onChange,
            activeKey: this.state.activeKey,
            defaultActiveKey,
        };

        if (defaultActiveKey) {
            props.defaultActiveKey = defaultActiveKey;
        }

        return (
            <AntTabs {...props}>
                {map(items, (item, index) => this.renderItem(item, index))}
            </AntTabs>
        );
    }

    renderItem(item: TabPaneProps, index: number) {
        const { entity, entities, routes, params, children, cache } = this.props;
        const { className, style, title, icon, forceRender, params: tabParams, content } = item;
        if (item.privilege) {
            const { path, level } = item.privilege;
            if (!services.privilege.check(path, level)) {
                return undefined;
            }
        }
        const itemKey = this.getPaneKey(item, index);
        let itemProps = {
            key: itemKey,
            className,
            style,
            forceRender,
            tab: (
                <span>
                    {icon ? <Icon type={icon} /> : undefined}
                    {title}
                </span>
            ),
        };
        const ContentComponent = ComponentsManager.get(content.component);
        // if (!!content.component) {
        //     delete content.component;
        // }
        let contentProps = {
            ...content,
            entity,
            entities,
            routes,
            params: { ...params, ...tabParams },
        };
        //如果不使用缓存的时候，每次切换都要刷新数据
        if (!cache && this.state.activeKey !== itemKey) {
            return <AntTabPane {...itemProps} />;
        }

        return (
            <AntTabPane {...itemProps}>
                {createElement(ContentComponent, contentProps, children)}
            </AntTabPane>
        );
    }
}
