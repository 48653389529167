/*
 * @Author: zhuhuajuan
 * @Date: 2020-8-21 14:09:41
 * @Description: 意见反馈管理
 */
import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { get } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

export const config: Config = {
    entities: {
        FeedbacksEntity: {
            apiPath: '/loader/admin/feedbacks',
            filters: {
                memberMobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                crmId: {
                    type: 'string',
                    displayName: 'CRM ID',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                type: {
                    type: 'object.filterOptions.autoComplete',
                    displayName: '<<yjlx>>',
                    options: [],
                    source: {
                        apiPath: '/admin/feedback/types/types',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'subsiteIds',
                        paramsName: 'subsiteId',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                typeInfo: {
                    type: 'object.filterOptions.autoComplete',
                    displayName: '<<ejlx>>',
                    options: [],
                    source: {
                        apiPath: '/admin/feedback/types/infos',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'type',
                        paramsName: 'typeId',
                        needDependences: true,
                        needDependencesMessage: '<<qxxzyjlx>>',
                    },
                },
                content: {
                    type: 'string',
                    displayName: '<<fknr>>',
                },
                dateRange: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<fksj_1>>',
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                replyDateRange: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '<<hfsj>>',
                    controlConfig: {
                        style: {
                            width: 450,
                        },
                    },
                },
                replyStatus: {
                    type: 'string.options.select',
                    displayName: '<<hfzt>>',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'REPLIED', name: '<<yhf>>' },
                        { id: 'NO_REPLY', name: '<<dhf>>' },
                    ],
                },
                feedbackStatus: {
                    type: 'string.options.select',
                    displayName: '<<clzt>>',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'UNFINISHED', name: '<<wwc>>' },
                        { id: 'FINISHED', name: '<<ywc>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                content: {
                    type: 'string.tip',
                    displayName: '<<content>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<fksj_1>>',
                },
                lastModifyTime: {
                    type: 'string',
                    displayName: '<<zhclsj>>',
                },
                memberId: {
                    type: 'string',
                    displayName: '<<memberId>>',
                },
                memberName: {
                    type: 'string',
                    displayName: '<<hyxm>>',
                },
                memberPhone: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
                memberCardLevelName: {
                    type: 'string',
                    displayName: '<<hydj>>',
                },
                crmId: {
                    type: 'string',
                    displayName: 'CRM ID',
                },
                type: {
                    type: 'string',
                    displayName: '<<yjfklx>>',
                },
                typeInfo: {
                    type: 'string',
                    displayName: '<<ejfklx>>',
                },
                pictures: {
                    type: 'array.images',
                    displayName: '<<pictures>>',
                },
                status: {
                    type: 'string.statusBadge',
                    displayName: '<<clzt>>',
                },
                replyStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<hfzt>>',
                },
                replyTime: {
                    type: 'string',
                    displayName: '<<hfsj>>',
                },
                remark: {
                    type: 'string.text.paragraph',
                    displayName: '<<hf>>',
                    rules: [{ max: 200, message: '<<hfzd200z>>' }],
                },
            },
        },
        FeedbackTypeEntity: {
            apiPath: '/loader/admin/feedback/types',
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                subsiteId: {
                    type: 'string',
                    displayName: '<<subsiteId>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsites>>',
                },
                subsiteFeedBackTypeCount: {
                    type: 'string',
                    displayName: '<<yjfklxsl>>',
                },
                subsiteFeedBackTypeInfoCount: {
                    type: 'string',
                    displayName: '<<ejfklxsl>>',
                },
                subsite: {
                    type: 'array.subsites.selector',
                    displayName: '<<otherSelectSubsite>>',
                    controlConfig: {
                        type: 'radio',
                    },
                    rules: [
                        {
                            required: true,
                            type: 'array',
                        },
                    ],
                },
                feedBackTypeList: {
                    type: 'array.feedbackTypes',
                    displayName: '<<fklx>>',
                    rules: [
                        {
                            required: true,
                            message: '<<zsxyygyjfklx>>',
                        },
                    ],
                },
            },
        },
    },
    components: {
        FeedbacksView: {
            component: 'Viewport',
            entity: 'FeedbacksEntity',
        },
        FeedbacksPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'FeedbacksFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            style: {
                                display: 'flex',
                            },
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<glfklx>>',
                                    type: 'primary',
                                    style: {
                                        display: 'inline-block',
                                        width: '120px',
                                    },
                                    route: '/feedbacks/types',
                                },
                                {
                                    component: 'ExportDataByFilterParamsButton',
                                    params: {
                                        text: '<<exportSearchResult>>',
                                        style: {
                                            display: 'inline-block',
                                            width: '120px',
                                            marginLeft: '20px',
                                        },
                                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                        apiPath: '/admin/feedbacks/export',
                                        type: 'feedback',
                                        requestMethod: 'get',
                                        target: '_blank',
                                    },
                                },
                            ],
                        },
                    ],
                },
                { component: 'FeedbacksTable' },
            ],
        },
        FeedbacksFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'memberMobile',
                },
                {
                    property: 'crmId',
                },
                {
                    property: 'subsiteIds',
                },
                {
                    property: 'content',
                },
                {
                    property: 'dateRange',
                },
                {
                    property: 'replyDateRange',
                },
                {
                    property: 'type',
                },
                {
                    property: 'typeInfo',
                },
                {
                    property: 'replyStatus',
                    controlConfig: {
                        style: { width: 120 },
                    },
                },
                {
                    property: 'feedbackStatus',
                    controlConfig: {
                        style: { width: 120 },
                    },
                },
            ],
        },
        FeedbacksTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'FeedbackDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: {
                        x: true,
                    },
                    columns: [
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'memberName',
                        },
                        {
                            property: 'crmId',
                        },
                        {
                            property: 'memberPhone',
                        },
                        {
                            property: 'memberCardLevelName',
                        },
                        {
                            property: 'type',
                        },
                        {
                            property: 'typeInfo',
                        },
                        {
                            property: 'content',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '200px',
                                },
                            },
                        },
                        {
                            property: 'status',
                            displayConfig: {
                                statusConfig: {
                                    UNFINISHED: {
                                        text: '<<wwc>>',
                                        status: 'processing',
                                    },
                                    FINISHED: {
                                        text: '<<ywc>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                        {
                            property: 'createTime',
                        },
                        {
                            property: 'replyStatus',
                            displayConfig: {
                                statusConfig: {
                                    REPLIED: {
                                        text: '<<yhf>>',
                                        status: 'default',
                                    },
                                    NO_REPLY: {
                                        text: '<<dhf>>',
                                        status: 'processing',
                                    },
                                },
                            },
                        },
                        {
                            property: 'replyTime',
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        fixed: 'right',
                        items: [
                            {
                                type: 'link',
                                text: '<<common.view>>',
                                path: '/feedbacks/info/{{row.id}}',
                            },
                            {
                                type: 'link',
                                text: '<<kfhf>>',
                                statusKey: 'row.canEdit',
                                path: '/feedbacks/edit/{{row.id}}',
                            },
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    statusKey: 'status',
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            value: 'UNFINISHED',
                                            apiPath: '/admin/feedbacks/:id/status',
                                            config: {
                                                content: '<<jj>>',
                                                text: '<<jj>>',
                                            },
                                            confirm: {
                                                text: '<<qryjfkclwc>>',
                                                okText: '<<jx>>',
                                                content: '<<bnzbjhfxx>>',
                                                className: 'confirm-content',
                                            },
                                        },
                                        {
                                            value: 'FINISHED',
                                            config: {
                                                content: '<<jj>>',
                                                text: '<<jj>>',
                                                disabled: true,
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        },
        FeedbacksEditPage: {
            component: 'Card',
            content: { component: 'FeedbacksEditForm' },
        },
        FeedbacksEditForm: {
            component: 'ModifyForm',
            direction: 'horizontal',
            entity: 'FeedbacksEntity',
            loaderType: 'get',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: [
                { property: 'subsiteName', modifiable: false },
                { property: 'type', modifiable: false },
                { property: 'content', modifiable: false },
                {
                    property: 'pictures',
                    modifiable: false,
                    displayConfig: {
                        style: {
                            width: 120,
                        },
                        preview: {
                            visible: true,
                            maxCount: 3,
                            autoplay: true,
                            size: 500,
                        },
                    },
                },
                { property: 'createTime', modifiable: false },
                { property: 'memberName', modifiable: false },
                { property: 'memberPhone', modifiable: false },
                {
                    property: 'remark',
                },
            ],
            submit: { text: '<<common.save>>' },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        FeedbacksInfoPage: {
            component: 'Card',
            content: { component: 'FeedbacksInfoView' },
        },
        FeedbacksInfoView: {
            component: 'Detail',
            entity: 'FeedbacksEntity',
            loaderType: 'get',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: [
                { property: 'subsiteName' },
                { property: 'type', label: '<<fklx>>' },
                { property: 'content' },
                {
                    property: 'pictures',
                    displayConfig: {
                        style: {
                            width: 120,
                        },
                        preview: {
                            visible: true,
                            maxCount: 3,
                            autoplay: true,
                            size: 500,
                        },
                    },
                },
                { property: 'createTime' },
                { property: 'memberName' },
                { property: 'crmId' },
                { property: 'memberPhone' },
                { property: 'memberCardLevelName' },
                {
                    property: 'remark',
                },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'primary',
                    },
                ],
            },
        },

        FeedbackTypeView: {
            component: 'Viewport',
            entity: 'FeedbackTypeEntity',
        },
        FeedbackTypeManagePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'FeedbackTypeEntity',
            items: [
                {
                    component: 'FeedbackTypeFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    style: {
                        paddingTop: 10,
                        paddingBottom: 10,
                    },
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<cjmdfklx>>',
                                    type: 'primary',
                                    span: 4,
                                    route: '/feedbacks/types/add',
                                },
                            ],
                        },
                    ],
                },

                { component: 'FeedbackTypeTable' },
            ],
        },
        FeedbackTypeFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'subsiteId',
                },
            ],
        },
        FeedbackTypeTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'FeedbackTypeDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'subsiteFeedBackTypeCount',
                        },
                        {
                            property: 'subsiteFeedBackTypeInfoCount',
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        items: [
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                path: '/feedbacks/types/edit/{{row.subsiteId}}',
                            },
                        ],
                    },
                },
            ],
        },
        FeedbackTypeAddPage: {
            component: 'Card',
            content: { component: 'FeedbackTypeAddForm' },
        },
        FeedbackTypeAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'FeedbackTypeEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'subsite',
                },
                {
                    property: 'feedBackTypeList',
                },
            ],
            submit: true,
            onValidate: (entity: any) => {
                const feedBackTypeList = get(entity, 'feedBackTypeList');
                let flag = true;
                if (!feedBackTypeList || !feedBackTypeList.length) {
                    AntMessage.error(language.getText('zsxyygyjfklx'));
                    flag = false;
                }
                return flag;
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: (fields: any, res: any) => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        FeedbackTypeEditPage: {
            component: 'Card',
            content: { component: 'FeedbackTypeEditForm' },
        },
        FeedbackTypeEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            loaderType: 'get',
            entity: 'FeedbackTypeEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'subsite',
                },
                {
                    property: 'feedBackTypeList',
                },
            ],
            submit: true,
            onValidate: (entity: any) => {
                const feedBackTypeList = get(entity, 'feedBackTypeList');
                let flag = true;
                if (!feedBackTypeList || !feedBackTypeList.length) {
                    AntMessage.error(language.getText('zsxyygyjfklx'));
                    flag = false;
                }
                return flag;
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: (fields: any, res: any) => {
                AntMessage.success(services.language.getText('editSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/feedbacks',
                    component: 'FeedbacksView',
                    breadcrumbName: '<<feedbacks>>',
                    privilege: {
                        path: 'feedbacks',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'FeedbacksEditPage',
                            breadcrumbName: '<<kfhf>>',
                        },
                        {
                            path: '/info/:id',
                            component: 'FeedbacksInfoPage',
                            breadcrumbName: '<<common.view>>',
                        },
                        {
                            path: '/types',
                            component: 'FeedbackTypeView',
                            breadcrumbName: '<<glfklx>>',
                            childRoutes: [
                                {
                                    path: '/add',
                                    component: 'FeedbackTypeAddPage',
                                    breadcrumbName: '<<cjmdfklx>>',
                                },
                                {
                                    path: '/edit/:id',
                                    component: 'FeedbackTypeEditPage',
                                    breadcrumbName: '<<bjmdfklx>>',
                                },
                                { path: '/', component: 'FeedbackTypeManagePage' },
                            ],
                        },
                        { path: '/', component: 'FeedbacksPage' },
                    ],
                },
            ],
        },
    ],
};
