import React, { PureComponent } from 'react';
import { Input, InputNumber, Radio } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services';
import './index.less';
import { cloneDeep } from 'lodash';
interface FreeExitTimeConfig {
    /**
     * 倒计时时长
     */
    freeExitTime?: number;
    /**
     * 自定义配置 false 车场配置 true
     */
    tripartiteSystemSupport: boolean;
}
interface CarParkInfo {
    /**
     * 门店停车场系统Id
     */
    carparkSystemId?: string;
    /**
     * 停车场系统名称
     */
    carparkSystemName?: string;
    /**
     * 门店车场名称
     */
    name: string;
    /**
     * 门店车场具体地址
     */
    address?: string;
    /**
     * 门店车场客服电话
     */
    mobile?: string;
    /**
     * 停车场系统名称
     */
    freeExitTimeConfig?: FreeExitTimeConfig;
    /**
     * 错误状态 默认是false
     */
    errorStatus?: boolean;
}

interface Props {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    value: CarParkInfo[];
    onChange: (value: Array<CarParkInfo> | null, name: string) => void;
}

export class CarparkModuleInfoDisplay extends PureComponent<Props, any> {
    defaultProps = {
        errorStatus: false,
    };
    onValueChange(num: number | undefined, index: number) {
        let { value, name, onChange } = this.props;
        if (!value) {
            return;
        }
        if (num) {
            if (num < 0) {
                num = 0;
            }
        }
        const newValue = cloneDeep(value);
        let rowItem: any = newValue[index];
        if (rowItem) {
            rowItem.freeExitTimeConfig = {
                freeExitTime: num,
            };
            if (onChange) {
                onChange(newValue, name);
            }
        }
    }
    changeName(v: string | undefined, index: number) {
        let { value, name, onChange } = this.props;
        if (!value) {
            return;
        }
        const newValue = cloneDeep(value);
        let rowItem: any = newValue[index];
        if (rowItem) {
            rowItem.name = v;
            if (!v) {
                rowItem.errorStatus = true;
            } else {
                rowItem.errorStatus = false;
            }
            if (onChange) {
                onChange(newValue, name);
            }
        }
    }
    changeAddress(v: string | undefined, index: number) {
        let { value, name, onChange } = this.props;
        if (!value) {
            return;
        }
        const newValue = cloneDeep(value);
        let rowItem: any = newValue[index];
        if (rowItem) {
            rowItem.address = v;
            if (onChange) {
                onChange(newValue, name);
            }
        }
    }
    changeMobile(v: string | undefined, index: number) {
        let { value, name, onChange } = this.props;
        if (!value) {
            return;
        }
        const newValue = cloneDeep(value);
        let rowItem: any = newValue[index];
        if (rowItem) {
            rowItem.mobile = v;
            if (onChange) {
                onChange(newValue, name);
            }
        }
    }
    render() {
        const { value } = this.props;
        return (
            <>
                {value?.length > 0 &&
                    value.map((item: CarParkInfo, index: number) =>
                        this.renderCarpark(item, index)
                    )}
            </>
        );
    }

    renderCarpark(item: CarParkInfo, index: number) {
        return (
            <div className="carpark-module-info" key={item.carparkSystemId}>
                <div className="carpark-item">
                    <label className="carpark-label">{language.getText('systemName')}:</label>
                    {item.carparkSystemName}
                </div>
                <div className="carpark-item">
                    <label className="carpark-label">{language.getText('lcdjs')}:</label>
                    <Radio.Group value={item.freeExitTimeConfig?.tripartiteSystemSupport} disabled>
                        <Radio value={true}>{language.getText('useCarparkConfig')}</Radio>
                        <Radio value={false}>{language.getText('zdypz')}</Radio>
                    </Radio.Group>
                </div>
                {!item.freeExitTimeConfig?.tripartiteSystemSupport && (
                    <div className="carpark-item">
                        <label className="carpark-label">{language.getText('djssz')}:</label>
                        <InputNumber
                            className="carpark-input"
                            value={item.freeExitTimeConfig?.freeExitTime}
                            onChange={(value) => this.onValueChange(value, index)}
                            style={{ width: 100 }}
                        />
                        <span className="carpark-minutes">{language.getText('minutes')}</span>
                    </div>
                )}
                <div className="carpark-item carpark-name">
                    <span className="rules">*</span>
                    <label className="carpark-label">{language.getText('ccmc')}:</label>
                    <Input
                        className="carpark-input"
                        style={{ border: item.errorStatus ? '1px solid #f5222d' : '' }}
                        placeholder={language.getText('inputPlease')}
                        onChange={(e) => this.changeName(e.target.value, index)}
                        value={item.name}
                    ></Input>
                </div>
                {item.errorStatus && (
                    <div className="carpark-name-error">
                        {language.getText('carparkNameRequire')}
                    </div>
                )}
                <div className="carpark-name-extra">{language.getText('carparkNameExtra')}</div>
                <div className="carpark-item">
                    <label className="carpark-label">{language.getText('ccjtdz')}:</label>
                    <Input
                        className="carpark-input"
                        placeholder={language.getText('inputPlease')}
                        value={item.address}
                        onChange={(e) => this.changeAddress(e.target.value, index)}
                    ></Input>
                </div>
                <div className="carpark-item">
                    <label className="carpark-label">{language.getText('cckfdh')}:</label>
                    <Input
                        className="carpark-input"
                        placeholder={language.getText('inputPlease')}
                        value={item.mobile}
                        onChange={(e) => this.changeMobile(e.target.value, index)}
                    ></Input>
                </div>
            </div>
        );
    }
}
