import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { remove, get, map } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Modal } from 'antd';

const TableComponentId = 'CardLevelSelectorDataTable';
let Entity: any = {};
let entity: any = {};

/**
 * 选择会员卡等级
 */
class memberCardSelector extends PureComponent<{
    onChange: (products: any[]) => void;
    params?: any;
    buttonText?: string;
    requestStatus: string;
    rowSelectionType?: string;
}> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('cardLevel');
        entity = new Entity({});
    }
    dispatch: any = {};
    state = { visible: false };

    selectedRows: any[] = [];

    onTableRowSelect = (record: any, selected: boolean) => {
        const { rowSelectionType } = this.props;
        if (rowSelectionType === 'radio') {
            this.selectedRows = [record];
        } else {
            if (selected) {
                this.selectedRows.push(record);
            } else {
                remove(this.selectedRows, (i) => {
                    return i.id === record.id;
                });
            }
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: any[]) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            this.selectedRows = [];
        }
    };
    componentWillReceiveProps(nextProps: any) {
        if (nextProps.requestStatus === 'success' && this.props.requestStatus === 'pending') {
            const selectedRows = this.selectedRows;
            if (selectedRows && selectedRows.length > 0)
                setTimeout(() => {
                    this.dispatch(
                        actions.tableRowSelectionChangeAction(
                            TableComponentId,
                            map(selectedRows, 'id')
                        )
                    );
                }, 300);
        }
    }

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(entity));
    }

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
    };

    onOk = () => {
        const { onChange } = this.props;
        if (onChange) {
            onChange(this.selectedRows);
            this.toggleModal();
        }
    };

    getConfig = () => {
        let { params, rowSelectionType } = this.props;
        if (!rowSelectionType) {
            rowSelectionType = 'checkbox';
        }
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: params || {},
            items: [
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    rowSelection: {
                        type: rowSelectionType,
                        onSelect: (record: any, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: any) => {
                            this.onTableRowSelectAll(selected, rows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    columns: [
                        { property: 'baseInfo.name' },
                        {
                            property: 'baseInfo.status',
                            displayConfig: {
                                statusConfig: {
                                    ENABLE: {
                                        text: '启用',
                                        status: 'processing',
                                    },
                                    DISABLE: {
                                        text: '禁用',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                    ],
                },
            ],
        };
    };

    toggleModal = () => {
        const { visible } = this.state;
        const { params } = this.props;
        this.setState({ visible: !visible }, () => {
            if (!visible && entity.paging) {
                entity.search({
                    ...entity.paging,
                    ...params,
                });
                this.onClearAll();
            }
        });
    };

    render() {
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 900,
            title: '选择会员卡等级',
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };
        return (
            <div style={{ display: 'inline-block' }}>
                <Button type={'link'} onClick={this.toggleModal}>
                    选择会员卡等级
                </Button>
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}

export const MemberCardSelector = connect((_state: any) => {
    let requestStatus = get(entity, 'requestStatus', null);
    const result = { requestStatus };
    return result;
})(memberCardSelector);
