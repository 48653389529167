import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { internalPurchaseEnterpriseId } from '../../containers/internal-purchase/relation-mobile-manage-button';

const api = services.api;

export const InternalPurchaseMobileLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const paramsFilter = config.paramsFilter;
        const relationMobileImportResult =
            paramsFilter &&
            paramsFilter.length > 0 &&
            paramsFilter[0] === 'relationMobileImportResult';
        if (data.id && relationMobileImportResult) {
            config.apiPath = '/admin/enterprises/relation_mobile/file/' + data.id;
            delete config.paramsFilter;
            delete data.id;
        } else {
            config.apiPath = '/admin/enterprises/relation_mobile';
            if (internalPurchaseEnterpriseId > 0) {
                data.enterpriseId = internalPurchaseEnterpriseId;
            }
        }

        return api.get(data, config);
    },
    post: async function(params: any, config: any) {
        params.enterpriseId = internalPurchaseEnterpriseId;

        return await api.post(params, config);
    },
    delete: async function(params: any, config: any) {
        let ids = '';
        if (params.id) {
            ids = params.id;
        } else {
            ids = params.ids.join(',');
        }
        config.apiPath = `/admin/enterprises/relation_mobile?ids=${ids}`;
        return await api.delete(params, config);
    },
};
