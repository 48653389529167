import React from 'react';
import { ApplicationConfigForm } from '@comall-backend-builder/design-components-basis';
import {
    Nav,
    NoticeBar,
    SearchBar,
    Showcase,
    Text,
} from '@comall-backend-builder/design-components-basis';
import {
    Coupon,
    FlashProducts,
    MemberAssets,
    MemberCard,
    MemberDistribution,
    MemberGroup,
    MemberOrder,
    MemberService,
    Root,
    MerchantInfo,
    MerchantIntro,
    WfjDesignIndex,
    ContentNoteIndex,
    MemberContentInteraction,
    ContentNoteMine,
    MemberCover,
    HotArea,
    CardNav,
    IndexMemberCard,
    WfjIndexSubsite,
    HomeMemberCard,
    LivePreviewCard,
    Presale,
    WeiXin,
    VirtualProducts,
    PlatformGoods,
    Carousel,
    Products,
    ActitivtyRecommend,
    PersionalProfile,
    ImageAd,
    ServiceSubsite,
    WaterfallProducts,
} from '../forms';
import { CustomSelectCategoryNav } from './components/custom-select-category-nav';
import { DesignMallActivityList } from './components/design-mall-activity-list';
import { DesignMallActivityCalendar } from './components/design-mall-activity-calendar';
import { CustomSelectLiveInfo } from './components/custom-select-live-info';
import { CustomSelectCategory } from './components/custom-select-category';
import { CustomCGBRecruitLeaderAdv } from './components/custom-select-cgb-recruit-leader-adv';
import { CustomSelectPresale } from './components/custom-select-presale';
import { CustomSelectInvite } from './components/custom-select-invite';
import { CustomSelectWheelDraw } from './components/custorm-select-wheel-draw';
import { CustomSelectMallActivity } from './components/custom-select-mall-activity';
import { CustomSelectMallActivityList } from './components/custom-select-mall-activity-list';
import { CustomSelectServiceReserved } from './components/custom-select-service-reserved';
import { CustomSelectLotteryCode } from './components/custom-select-lottery-code';
import { CustomSelectCouponSaleActivitySelected } from './components/custom-select-coupon-sale-activity';
import { CustomSelectScratchCard } from './components/custom-select-scratch-card';
import { CustomSelectScratchCreditShopDetail } from './components/custom-select-credit-shop-detail';
import { CustomSelectRedPackageRain } from './components/custom-select-red-package-rain';
import { CustomSelectSubsite } from './components/custom-select-subsite';
import { CustomSelectMultiSubsite } from './components/custom-select-multi-subsite';
import { CustomSelectGood } from './components/custom-select-good';
import CustomUrlLink from './components/custom-url-link';
import DreamServiceUrlLink from './components/dream-service-link';
import CustomMiniprogram from './components/custom-miniprogram';
import { CustomSelectMerchant } from './components/custom-select-merchant';
import { CustomSelectCardCollection } from './components/custom-select-card-collection';
import { CustomSelectProduct } from './components/custom-select-product';
import { CustomSelectCouponProduct } from './components/custom-select-coupon-product';
import { CustomRecruitDistributorAdv } from './components/custom-select-recruit-distributor-adv';
import { TopicSelector } from './components/custom-select-topic';
import { MallActivityPageSelector } from './components/custom-select-activity-page';
import { TopicContentSelector } from './components/custom-select-topic-content';
import { CustomSelectCoupon } from './components/custom-select-coupon';
import { CustomSelectMallActivityListPlus } from './components/custom-select-mall-activity-list-plus';
import { CustomTopicPromotionEncourage } from './components/custom-topic-promotion-encourage';
import { CustomSelectContentNote } from './components/custom-select-content-note';
import { CustomEShopHome } from './components/custom-eshop-home';
import { CustomVirtualCategory } from './components/custom-select-virtual-category';
import { CustomSelectPromotionActivity } from './components/custom-select-promotion-activity';
import { CustomSelectBlindBox } from './components/custom-select-blind-box';
import { CustomSelectOpenBox } from './components/custom-select-open-box';
import { CustomSelectGroupProduct } from './components/custom-select-group-product';
import { MaotaiSelectProduct } from './components/maotai-select-product';
// import { CustomSelectMerchantGuide } from './components/custom-select-merchant-guide';
import { CustomSelectVirtualGood } from './components/custom-select-virtual-good';
import { CustomSelectInternalPurchase } from './components/custom-select-internal-purchase';
import CustomBalanceCouponTitle from './components/custom-balance-coupon-title';
import { CustomSelectFixedPrice } from './components/custom-select-fixed-price';
import { CustomSelectBargain } from './components/custom-select-bargain';
import { CustomSelectPaymentMode } from './components/custom-select-payment-mode';
import { SingleImageUpload } from '../../../components';
import { CustomSelectSharingReward } from './components/custom-select-share';
import { CustomSelectWatchword } from './components/custorm-select-watchword';
import { CustomSelectRestaurantReservationMerchant } from './components/custom-select-restaurant-reservation-merchant';
import { get } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { getI18NLinkNameById } from '../../../components/link-type-effect-path/config';
import { CustomSelectCollectionPoint } from './components/custom-select-collection-point';

const customSelectorConfig = {
    multiProduct: {
        contentConfig: {
            entity: 'CustomDesignProductSelector',
            content: {
                items: [
                    {},
                    {
                        columns: [
                            {
                                property: 'name',
                                width: 300,
                            },
                            {
                                property: 'goodsMvo.csku',
                                width: 100,
                            },
                        ],
                    },
                ],
            },
        },
        modalConfig: {
            title: '请选择商品',
        },
    },
    multiActivity: {
        contentConfig: {
            entity: 'MallActivityEntity',
            content: {
                items: [
                    {},
                    {
                        columns: [
                            {
                                property: 'name',
                                width: 300,
                            },
                            {
                                property: 'mallActivityStatus',
                                width: 100,
                                displayConfig: {
                                    className: 'ant-table-cell-ellipsis',
                                    style: {
                                        maxWidth: '100px',
                                    },
                                    statusConfig: {
                                        NOT_STARTED: {
                                            text: '未开始',
                                            status: 'success',
                                        },
                                        STARTED: {
                                            text: '进行中',
                                            status: 'processing',
                                        },
                                        COMPLETE: {
                                            text: '已结束',
                                            status: 'default',
                                        },
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
        modalConfig: {
            title: '请选择活动',
        },
    },
    singleProduct: {
        render: (props: any) => <CustomSelectProduct {...props} />,
    },
    topic: {
        render: (props: any) => <TopicSelector {...props} />,
        // contentConfig: {
        //     entity: 'CustomDesignTopicSelector',
        //     content: {
        //         items: [
        //             {},
        //             {
        //                 columns: [
        //                     {
        //                         property: 'name',
        //                         width: 300,
        //                     },
        //                     {
        //                         property: 'createTime',
        //                         width: 200,
        //                     },
        //                 ],
        //             },
        //         ],
        //     },
        // },
        // modalConfig: {
        //     title: '请选择专题',
        // },
    },
    newTopic: {
        render: (props: any) => <TopicSelector {...props} newTopic />,
    },
    /** 话题选择 */
    topicContent: {
        render: (props: any) => <TopicContentSelector {...props} />,
    },
    category: {
        render: (props: any) => <CustomSelectCategory {...props} />,
    },
    merchantCategory: {
        render: (props: any) => <CustomSelectCategory {...props} />,
    },
    categoryNavProduct: {
        render: (props: any) => <CustomSelectCategory type="categoryNavProduct" {...props} />,
    },
    merchantCategoryNavProduct: {
        render: (props: any) => (
            <CustomSelectCategory
                modalConfig={{ title: '专柜营销分类' }}
                type="merchantCategoryNavProduct"
                {...props}
            />
        ),
    },
    categoryNav: {
        render: (props: any) => <CustomSelectCategory type="categoryNav" {...props} />,
    },
    liveInfo: {
        render: (props: any) => <CustomSelectLiveInfo {...props} />,
    },
    urlLink: {
        render: (props: any) => <CustomUrlLink {...props} />,
    },
    dreamService: {
        render: (props: any) => <DreamServiceUrlLink {...props} />,
    },
    miniprogram: {
        render: (props: any) => <CustomMiniprogram {...props} />,
    },
    customUrl: {
        modalConfig: {
            title: '请输入自定义链接',
        },
    },
    coupon: {
        contentConfig: {
            entity: 'CustomDesignCouponSelector',
            content: {
                items: [
                    {},
                    {
                        columns: [
                            {
                                property: 'name',
                                displayConfig: {
                                    className: 'ant-table-cell-ellipsis',
                                    style: {
                                        maxWidth: '200px',
                                    },
                                },
                            },
                            {
                                property: 'code',
                                width: 200,
                            },
                            {
                                property: 'typeName',
                                width: 100,
                            },
                            {
                                property: 'sourceDesc',
                                width: 100,
                            },
                            {
                                property: 'startTime',
                                width: 200,
                            },
                            {
                                property: 'endTime',
                                width: 200,
                            },
                            {
                                property: 'consumeChannelDescription',
                                width: 150,
                            },
                        ],
                    },
                ],
            },
        },
        extraConfig: {
            entity: 'CustomDesignCouponPackageSelector',
            content: {
                items: [
                    {},
                    {
                        columns: [
                            {
                                property: 'name',
                                width: 300,
                            },
                            {
                                property: 'startTime',
                                width: 200,
                            },
                            {
                                property: 'endTime',
                                width: 200,
                            },
                            {
                                property: 'executeStatus',
                                width: 200,
                            },
                        ],
                    },
                ],
            },
        },
        modalConfig: {
            title: '请选择优惠券',
        },
        extraModalConfig: {
            title: '请选择优惠券礼包',
        },
    },
    platformGoods: {
        contentConfig: {
            entity: 'DesignPlatformGoodsSelector',
            content: {
                items: [
                    {},
                    {
                        scroll: { x: true },
                        params: { a: 1 },
                        columns: [
                            {
                                property: 'name',
                                width: 400,
                            },
                            {
                                property: 'barcode',
                                width: 150,
                            },
                            {
                                property: 'goodsMvo.subsiteName',
                                width: 100,
                            },
                            {
                                property: 'goodsMvo.merchantName',
                                width: 150,
                            },
                            {
                                property: 'goodsMvo.csku',
                                width: 160,
                            },
                        ],
                    },
                ],
            },
        },
        modalConfig: {
            title: '请选择商品',
        },
    },
    presale: {
        render: (props: any) => <CustomSelectPresale {...props} />,
    },
    invite: {
        render: (props: any) => <CustomSelectInvite {...props} />,
    },
    wheelDraw: {
        render: (props: any) => <CustomSelectWheelDraw {...props} />,
    },
    watchword: {
        render: (props: any) => <CustomSelectWatchword {...props} />,
    },
    redPackageRain: {
        render: (props: any) => <CustomSelectRedPackageRain {...props} />,
    },
    lotteryCode: {
        render: (props: any) => <CustomSelectLotteryCode {...props} />,
    },
    couponProductInfo: {
        render: (props: any) => <CustomSelectCouponSaleActivitySelected {...props} />,
    },
    mallActivity: {
        render: (props: any) => <CustomSelectMallActivity {...props} />,
    },
    mallActivityList: {
        render: (props: any) => <CustomSelectMallActivityList {...props} />,
    },
    mallActivityPage: {
        render: (props: any) => <MallActivityPageSelector {...props} />,
    },
    mallActivityCalendarPage: {
        render: (props: any) => <span>商场活动日历</span>,
    },
    myMallActivityPage: {
        render: (props: any) => <span>我的商场活动</span>,
    },
    scratchCard: {
        render: (props: any) => <CustomSelectScratchCard {...props} />,
    },
    serviceReserved: {
        render: (props: any) => <CustomSelectServiceReserved {...props} />,
    },
    subsite: {
        render: (props: any) => <CustomSelectSubsite {...props} />,
    },
    subsiteByLocation: {
        render: (props: any) => <CustomSelectMultiSubsite {...props} />,
    },
    good: {
        render: (props: any) => <CustomSelectGood {...props} />,
    },
    merchant: {
        render: (props: any) => <CustomSelectMerchant {...props} />,
    },
    newMerchant: {
        render: (props: any) => <CustomSelectMerchant {...props} newMerchant />,
    },
    cardCollection: {
        render: (props: any) => <CustomSelectCardCollection {...props} />,
    },
    collectionPoint: {
        render: (props: any) => <CustomSelectCollectionPoint {...props} />,
    },
    couponProduct: {
        render: (props: any) => <CustomSelectCouponProduct {...props} />,
    },
    blindBox: {
        render: (props: any) => <CustomSelectBlindBox {...props} />,
    },
    // merchantGuide: {
    //     render: (props: any) => <CustomSelectMerchantGuide {...props} />,
    // },
    merchantGuide: {
        render: (props: any) => <span>商场导览</span>,
    },
    'service.virtualOrder': {
        render: (props: any) => <span>虚拟商品订单</span>,
    },
    'service.storedValueCardOrder': {
        render: (props: any) => <span>储值卡订单</span>,
    },
    'service.depositOrder': {
        render: (props: any) => <span>订金订单</span>,
    },
    'service.offlineDepositOrder': {
        render: (props: any) => <span>线下订金单</span>,
    },
    'service.ordinaryProductOrders': {
        render: (props: any) => <span>{services.language.getText('ptspdd')}</span>,
    },
    virtualGood: {
        render: (props: any) => <CustomSelectVirtualGood {...props} />,
    },
    fastIntegrate: {
        render: (props: any) => <span>{services.language.getText('ksjf')}</span>,
    },
    fastIntegrateMerchant: {
        render: (props: any) => <span>快速积分商家</span>,
    },
    pointExchange: {
        render: (props: any) => <span>积分换余额券</span>,
    },
    'member.selfCrediting': {
        render: (props: any) => <span>自助积分</span>,
    },
    'member.offlineOrderComment': {
        render: (props: any) => <span>电子小票</span>,
    },
    flashList: {
        render: (props: any) => <span>秒杀列表</span>,
    },
    checkinIndex: {
        render: (props: any) => <span>签到有礼</span>,
    },
    groupBuyingList: {
        render: (props: any) => <span>{services.language.getText('ptspptlb')}</span>,
    },
    couponProductGroupList: {
        render: (props: any) => <span>虚拟商品拼团列表</span>,
    },
    blindBoxList: {
        render: (props: any) => <span>盲盒活动列表</span>,
    },
    fullPayment: {
        render: (props: any) => <span>全款预售</span>,
    },
    reserve: {
        render: (props: any) => <span>预约抢购</span>,
    },
    'member.creditEshop': {
        render: (props: any) => <span>积分商城</span>,
    },
    reserveService: {
        render: (props: any) => <span>服务预约列表</span>,
    },
    'member.birthdayCaring': {
        render: (props: any) => <span>{services.language.getText('sryl')}</span>,
    },
    'member.parking': {
        render: (props: any) => <span>停车缴费</span>,
    },
    'service.myCars': {
        render: (props: any) => <span>我的车辆</span>,
    },
    'member.coupon': {
        render: (props: any) => <span>我的优惠券</span>,
    },
    'service.distribution': {
        render: (props: any) => <span>分销赚钱</span>,
    },
    gamePlatform: {
        render: (props: any) => <span>游戏平台</span>,
    },
    anniversary: {
        render: (props: any) => <span>周年庆报告</span>,
    },
    internalPurchase: {
        render: (props: any) => <CustomSelectInternalPurchase {...props} />,
    },
};

const oldSelectorConfigPolyfill = {
    multiProduct: {
        contentConfig: {
            entity: 'CustomDesignProductSelector',
            content: {
                items: [
                    {},
                    {
                        columns: [
                            {
                                property: 'name',
                                width: 300,
                            },
                            {
                                property: 'goodsMvo.csku',
                                width: 100,
                            },
                        ],
                    },
                ],
            },
        },
        modalConfig: {
            title: '<<productSelectRequired>>',
        },
    },
    multiActivity: {
        contentConfig: {
            entity: 'MallActivityEntity',
            content: {
                items: [
                    {},
                    {
                        columns: [
                            {
                                property: 'name',
                                width: 300,
                            },
                            {
                                property: 'mallActivityStatus',
                                width: 100,
                                displayConfig: {
                                    className: 'ant-table-cell-ellipsis',
                                    style: {
                                        maxWidth: '100px',
                                    },
                                    statusConfig: {
                                        NOT_STARTED: {
                                            text: '<<notStarted>>',
                                            status: 'success',
                                        },
                                        STARTED: {
                                            text: '<<inprogress>>',
                                            status: 'processing',
                                        },
                                        COMPLETE: {
                                            text: '<<end>>',
                                            status: 'default',
                                        },
                                    },
                                },
                            },
                        ],
                    },
                ],
            },
        },
        modalConfig: {
            title: '<<qxzhd>>',
        },
    },
    singleProduct: {
        render: (props: any) => <CustomSelectProduct {...props} />,
    },
    topic: {
        render: (props: any) => <TopicSelector {...props} />,
    },
    /** 话题选择 */
    topicContent: {
        render: (props: any) => <TopicContentSelector {...props} />,
    },
    /** 笔记详情 */
    contentNoteDetail: {
        render: (props: any) => <CustomSelectContentNote {...props} />,
    },
    merchantCategory: {
        render: (props: any) => <CustomSelectCategory {...props} />,
    },
    merchantCategoryNavProduct: {
        render: (props: any) => (
            <CustomSelectCategory
                modalConfig={{ title: services.language.getText('zjyxfl') }}
                type="merchantCategoryNavProduct"
                {...props}
            />
        ),
    },
    dreamService: {
        render: (props: any) => <DreamServiceUrlLink {...props} />,
    },
    customUrl: {
        modalConfig: {
            title: '请输入自定义链接',
        },
    },
    coupon: {
        contentConfig: {
            entity: 'CustomDesignCouponSelector',
            content: {
                items: [
                    {},
                    {
                        columns: [
                            {
                                property: 'name',
                                displayConfig: {
                                    className: 'ant-table-cell-ellipsis',
                                    style: {
                                        maxWidth: '200px',
                                    },
                                },
                            },
                            {
                                property: 'code',
                                width: 200,
                            },
                            {
                                property: 'typeName',
                                width: 100,
                            },
                            {
                                property: 'sourceDesc',
                                width: 100,
                            },
                            {
                                property: 'startTime',
                                width: 200,
                            },
                            {
                                property: 'endTime',
                                width: 200,
                            },
                            {
                                property: 'consumeChannelDescription',
                                width: 150,
                            },
                        ],
                    },
                ],
            },
        },
        extraConfig: {
            entity: 'CustomDesignCouponPackageSelector',
            content: {
                items: [
                    {},
                    {
                        columns: [
                            {
                                property: 'name',
                                width: 300,
                            },
                            {
                                property: 'startTime',
                                width: 200,
                            },
                            {
                                property: 'endTime',
                                width: 200,
                            },
                            {
                                property: 'executeStatus',
                                width: 200,
                            },
                        ],
                    },
                ],
            },
        },
        modalConfig: {
            title: '请选择优惠券',
        },
        extraModalConfig: {
            title: '请选择优惠券礼包',
        },
    },
    platformGoods: {
        contentConfig: {
            entity: 'DesignPlatformGoodsSelector',
            content: {
                items: [
                    {},
                    {
                        scroll: { x: true },
                        params: { a: 1 },
                        columns: [
                            {
                                property: 'name',
                                width: 400,
                            },
                            {
                                property: 'barcode',
                                width: 150,
                            },
                            {
                                property: 'goodsMvo.subsiteName',
                                width: 100,
                            },
                            {
                                property: 'goodsMvo.merchantName',
                                width: 150,
                            },
                            {
                                property: 'goodsMvo.csku',
                                width: 160,
                            },
                        ],
                    },
                ],
            },
        },
        modalConfig: {
            title: '请选择商品',
        },
    },

    presale: {
        render: (props: any) => <CustomSelectPresale {...props} />,
    },
    couponProductInfo: {
        render: (props: any) => <CustomSelectCouponSaleActivitySelected {...props} />,
    },
    mallActivityList: {
        render: (props: any) => <CustomSelectMallActivityList {...props} />,
    },
    mallActivityPage: {
        render: (props: any) => <MallActivityPageSelector {...props} />,
    },

    mallActivityCalendarPage: {
        render: (props: any) => <span>商场活动日历</span>,
    },
    myMallActivityPage: {
        render: (props: any) => <span>我的商场活动</span>,
    },
    subsiteByLocation: {
        render: (props: any) => <CustomSelectMultiSubsite {...props} />,
    },

    good: {
        render: (props: any) => <CustomSelectGood {...props} />,
    },
    merchant: {
        render: (props: any) => <CustomSelectMerchant {...props} />,
    },
    couponProduct: {
        render: (props: any) => <CustomSelectCouponProduct {...props} />,
    },
    virtualGood: {
        render: (props: any) => <CustomSelectVirtualGood {...props} />,
    },
    'member.offlineOrderComment': {
        render: (props: any) => <span>电子小票</span>,
    },
};
// 新C端链接类型-链接目标配置
const newClientCustomSelectorConfig = {
    newTopic: {
        render: (props: any) => {
            return <TopicSelector {...props} newTopic />;
        },
    },

    category: {
        render: (props: any) => <CustomSelectCategory {...props} />,
    },

    categoryNavProduct: {
        render: (props: any) => <CustomSelectCategory type="categoryNavProduct" {...props} />,
    },

    categoryNav: {
        render: (props: any) => <CustomSelectCategory type="categoryNav" {...props} />,
    },
    liveInfo: {
        render: (props: any) => <CustomSelectLiveInfo {...props} />,
    },
    urlLink: {
        render: (props: any) => <CustomUrlLink {...props} />,
    },

    miniprogram: {
        render: (props: any) => <CustomMiniprogram {...props} />,
    },

    invite: {
        render: (props: any) => <CustomSelectInvite {...props} />,
    },
    wheelDraw: {
        render: (props: any) => <CustomSelectWheelDraw {...props} />,
    },
    watchword: {
        render: (props: any) => <CustomSelectWatchword {...props} />,
    },
    redPackageRain: {
        render: (props: any) => <CustomSelectRedPackageRain {...props} />,
    },
    sharingReward: {
        render: (props: any) => <CustomSelectSharingReward {...props} />,
    },
    lotteryCode: {
        render: (props: any) => <CustomSelectLotteryCode {...props} />,
    },

    mallActivity: {
        render: (props: any) => {
            return <CustomSelectMallActivity {...props} />;
        },
    },

    scratchCard: {
        render: (props: any) => <CustomSelectScratchCard {...props} />,
    },

    serviceReserved: {
        render: (props: any) => <CustomSelectServiceReserved {...props} />,
    },
    subsite: {
        render: (props: any) => <CustomSelectSubsite {...props} />,
    },

    newMerchant: {
        render: (props: any) => <CustomSelectMerchant {...props} newMerchant />,
    },

    cardCollection: {
        render: (props: any) => <CustomSelectCardCollection {...props} />,
    },

    collectionPoint: {
        render: (props: any) => <CustomSelectCollectionPoint {...props} />,
    },

    blindBox: {
        render: (props: any) => <CustomSelectBlindBox {...props} />,
    },
    'marketing.groupBuyingDetail': {
        render: (props: any) => <CustomSelectGroupProduct {...props} />,
    },
    'marketing.openBoxDetail': {
        render: (props: any) => <CustomSelectOpenBox {...props} />,
    },
    'marketing.maotaiPreSaleDetail': {
        render: (props: any) => <MaotaiSelectProduct {...props} />,
    },
    'marketing.maotaiPreSaleList': {
        render: (props: any) => <span>{getI18NLinkNameById('marketing.maotaiPreSaleList')}</span>,
    },
    'marketing.fixedPrice': {
        render: (props: any) => <CustomSelectFixedPrice {...props} />,
    },
    merchantGuide: {
        render: (props: any) => <span>{getI18NLinkNameById('merchantGuide')}</span>,
    },
    merchantGuideBrand: {
        render: (props: any) => <span>{getI18NLinkNameById('merchantGuideBrand')}</span>,
    },
    facilityGuide: {
        render: (props: any) => <span>{getI18NLinkNameById('facilityGuide')}</span>,
    },
    'service.virtualOrder': {
        render: (props: any) => <span>{getI18NLinkNameById('service.virtualOrder')}</span>,
    },
    'service.depositOrder': {
        render: (props: any) => <span>{getI18NLinkNameById('service.depositOrder')}</span>,
    },
    'service.offlineDepositOrder': {
        render: (props: any) => <span>{getI18NLinkNameById('service.offlineDepositOrder')}</span>,
    },
    'service.ordinaryProductOrders': {
        render: (props: any) => <span>{getI18NLinkNameById('service.ordinaryProductOrders')}</span>,
    },
    fastIntegrate: {
        render: (props: any) => <span>{getI18NLinkNameById('fastIntegrate')}</span>,
    },
    fastIntegrateMerchant: {
        render: (props: any) => <span>{getI18NLinkNameById('fastIntegrateMerchant')}</span>,
    },
    'member.selfCrediting': {
        render: (props: any) => <span>{getI18NLinkNameById('member.selfCrediting')}</span>,
    },

    flashList: {
        render: (props: any) => <span>{getI18NLinkNameById('flashList')}</span>,
    },
    checkinIndex: {
        render: (props: any) => <span>{getI18NLinkNameById('checkinIndex')}</span>,
    },
    groupBuyingList: {
        render: (props: any) => <span>{getI18NLinkNameById('groupBuyingList')}</span>,
    },
    couponProductGroupList: {
        render: (props: any) => <span>{getI18NLinkNameById('couponProductGroupList')}</span>,
    },
    blindBoxList: {
        render: (props: any) => <span>{getI18NLinkNameById('blindBoxList')}</span>,
    },
    fullPayment: {
        render: (props: any) => <span>{getI18NLinkNameById('fullPayment')}</span>,
    },
    reserve: {
        render: (props: any) => <span>{getI18NLinkNameById('reserve')}</span>,
    },
    'member.creditEshop': {
        render: (props: any) => <span>{getI18NLinkNameById('member.creditEshop')}</span>,
    },
    'credit.creditShopDetail': {
        render: (props: any) => <CustomSelectScratchCreditShopDetail {...props} />,
    },
    reserveService: {
        render: (props: any) => <span>{getI18NLinkNameById('reserveService')}</span>,
    },
    'member.birthdayCaring': {
        render: (props: any) => <span>{getI18NLinkNameById('member.birthdayCaring')}</span>,
    },
    'member.parking': {
        render: (props: any) => <span>{getI18NLinkNameById('member.parking')}</span>,
    },
    'service.myCars': {
        render: (props: any) => <span>{getI18NLinkNameById('service.myCars')}</span>,
    },
    'member.coupon': {
        render: (props: any) => <span>{getI18NLinkNameById('member.coupon')}</span>,
    },
    'service.onlineCoupon': {
        render: (props: any) => <span>{getI18NLinkNameById('service.onlineCoupon')}</span>,
    },
    'service.distribution': {
        render: (props: any) => <span>{getI18NLinkNameById('service.distribution')}</span>,
    },
    'service.myPoint': {
        render: (props: any) => <span>{getI18NLinkNameById('service.myPoint')}</span>,
    },
    'service.identification': {
        render: (props: any) => <span>{getI18NLinkNameById('service.identification')}</span>,
    },
    'service.passwordEditor': {
        render: (props: any) => <CustomSelectPaymentMode {...props} />,
    },
    'service.exchange': {
        render: (props: any) => <span>{getI18NLinkNameById('service.exchange')}</span>,
    },
    'service.myLotteryCode': {
        render: (props: any) => <span>{getI18NLinkNameById('service.myLotteryCode')}</span>,
    },
    'service.reserveService': {
        render: (props: any) => <span>{getI18NLinkNameById('service.reserveService')}</span>,
    },
    'service.myMallAcivityOrder': {
        render: (props: any) => <span>{getI18NLinkNameById('service.myMallAcivityOrder')}</span>,
    },
    'service.myRestaurantReservation': {
        render: (props: any) => (
            <span>{getI18NLinkNameById('service.myRestaurantReservation')}</span>
        ),
    },
    'service.invoiceCenter': {
        render: (props: any) => <span>{getI18NLinkNameById('service.invoiceCenter')}</span>,
    },
    internalPurchase: {
        render: (props: any) => <CustomSelectInternalPurchase {...props} />,
    },
    'service.balanceCoupon': {
        render: (props: any) => <CustomBalanceCouponTitle {...props} />,
    },
    'service.thirdCoupon': {
        render: (props: any) => <span>{getI18NLinkNameById('service.thirdCoupon')}</span>,
    },
    'marketing.paymentRewardActivity': {
        render: (props: any) => (
            <span>{getI18NLinkNameById('marketing.paymentRewardActivity')}</span>
        ),
    },
    'marketing.paymentReward': {
        render: (props: any) => <span>{getI18NLinkNameById('marketing.paymentReward')}</span>,
    },
    'member.feedbackAdd': {
        render: (props: any) => <span>{getI18NLinkNameById('member.feedbackAdd')}</span>,
    },
    'member.feedback': {
        render: (props: any) => <span>{getI18NLinkNameById('member.feedback')}</span>,
    },
    'member.mallActivity': {
        render: (props: any) => <span>{getI18NLinkNameById('member.mallActivity')}</span>,
    },
    'service.openInvoice': {
        render: (props: any) => <span>{getI18NLinkNameById('service.openInvoice')}</span>,
    },
    'service.topicActivities': {
        render: (props: any) => <span>{getI18NLinkNameById('service.topicActivities')}</span>,
    },
    'service.connectWifi': {
        render: (props: any) => <span>{getI18NLinkNameById('service.connectWifi')}</span>,
    },
    'marketing.bargainActivityList': {
        render: (props: any) => <span>{getI18NLinkNameById('marketing.bargainActivityList')}</span>,
    },
    'member.storedValueCard': {
        render: (props: any) => <span>{getI18NLinkNameById('member.storedValueCard')}</span>,
    },
    'marketing.bargainActivity': {
        render: (props: any) => <CustomSelectBargain {...props} />,
    },
    'member.restaurantReservation': {
        render: (props: any) => <CustomSelectRestaurantReservationMerchant {...props} />,
    },
    'service.findCars': {
        render: (props: any) => <span>{getI18NLinkNameById('service.findCars')}</span>,
    },
    popupPicture: {
        render: (props: any) => {
            const onChange = (url: string) => {
                props.onChange({ url });
            };
            const value = get(props, 'value.linkParams.url');
            return <SingleImageUpload {...props} value={value} onChange={onChange} />;
        },
    },
    // FIXEDME: 兼容老SAAS选择器数据，后续不兼容时考虑移除
    ...oldSelectorConfigPolyfill,
};

/** 内容弹窗广告 链接目标配置 */
const contentPopUpConfig = {
    /** 停车缴费 */
    'member.parking': {
        render: (props: any) => <CustomSelectSubsite {...props} />,
    },
    /** 商场导购 */
    merchantGuide: {
        render: (props: any) => <CustomSelectSubsite {...props} />,
    },
    /** 积分商城 */
    'member.creditEshop': {
        render: (props: any) => <CustomSelectSubsite {...props} />,
    },
    /** 我的卡包券 */
    'member.couponOutline': {
        render: (props: any) => <CustomSelectCoupon {...props} />,
    },
    /** 商场活动列表 */
    mallActivityList: {
        render: (props: any) => <CustomSelectMallActivityListPlus {...props} />,
    },
    /** 话题激励 */
    'topic.promotionEncourage': {
        render: (props: any) => <CustomTopicPromotionEncourage {...props} />,
    },
    /** 笔记详情 */
    contentNoteDetail: {
        render: (props: any) => <CustomSelectContentNote {...props} />,
    },
    /** 电商首页 */
    'eShop.home': {
        render: (props: any) => <CustomEShopHome {...props} />,
    },
    /** 电商虚拟分类 */
    'eShop.virtualCategory': {
        render: (props: any) => <CustomVirtualCategory {...props} />,
    },
    /** 电商促销活动页 */
    'eShop.promotionActivity': {
        render: (props: any) => <CustomSelectPromotionActivity {...props} />,
    },
};

export { customSelectorConfig, contentPopUpConfig, newClientCustomSelectorConfig };

const virtualProductsSlectorConfig = {
    multiProduct: {
        contentConfig: {
            entity: 'CustomDesignVirtualProductSelector',
            content: {
                items: [
                    {},
                    {
                        columns: [
                            {
                                property: 'name',
                                width: 300,
                            },
                            {
                                property: 'goodsMvo.csku',
                                width: 100,
                            },
                        ],
                    },
                ],
            },
        },
        modalConfig: {
            title: '请选择虚拟商品',
        },
    },
    singleProduct: {
        contentConfig: {
            entity: 'CustomDesignVirtualProductSelector',
            content: {
                items: [
                    {},
                    {
                        columns: [
                            {
                                property: 'name',
                                width: 300,
                            },
                            {
                                property: 'goodsMvo.csku',
                                width: 100,
                            },
                        ],
                    },
                ],
            },
        },
        modalConfig: {
            title: '请选择虚拟商品',
        },
    },
};

ApplicationConfigForm.register(
    {
        name: 'root',
        render: (data: any, extra: any) => <Root {...data} {...extra} />,
    },
    true
);

ApplicationConfigForm.register({
    name: 'WfjDesignIndex',
    title: '王府井首页',
    render: (data: any, extra: any) => <WfjDesignIndex {...data} {...extra} />,
    validate: () => true,
});

ApplicationConfigForm.register(
    {
        name: 'products',
        defaultConfig: {
            showStyle: '1',
            showContent: ['buy', 'name', 'price', 'marketPrice'],
            showContentColor: {
                price: '#d21820',
                marketPrice: '#999999',
            },
            timeContent: null,
        },
        render: (data: any, extra: any) => (
            <Products {...data} {...extra} selector={customSelectorConfig} />
        ),
    },
    true
);

ApplicationConfigForm.register(
    {
        name: 'platformGoods',
        title: '商品',
        defaultConfig: {
            showStyle: '1',
            showContent: ['buy', 'name', 'price', 'marketPrice'],
            showContentColor: {
                price: '#d21820',
                marketPrice: '#999999',
            },
            timeContent: null,
        },
        validate: (data: any) => {
            return new Promise(function(resolve) {
                resolve(data);
            });
        },
        render: (data: any, extra: any) => (
            <PlatformGoods {...data} {...extra} selector={customSelectorConfig} />
        ),
    },
    false
);

ApplicationConfigForm.register(
    {
        defaultConfig: {
            showStyle: '1',
            showContent: ['buy', 'name', 'price'],
        },
        name: 'virtualProducts',
        title: '选择虚拟商品',
        render: (data: any, extra: any) => (
            <VirtualProducts
                hideMarketPrice={true}
                {...data}
                {...extra}
                selector={virtualProductsSlectorConfig}
            />
        ),
        validate: (data: any) => {
            return new Promise(function(resolve) {
                resolve(data);
            });
        },
    },
    false
);

ApplicationConfigForm.register(
    {
        defaultConfig: {
            showStyle: '1',
            fillStyle: '2',
            imageHeight: 180,
            margin: 10,
            marginImage: 10,
            borderRadius: 0,
        },
        name: 'imageAd',
        render: (data: any, extra: any) => (
            <ImageAd {...data} {...extra} selector={customSelectorConfig} />
        ),
    },
    true
);

ApplicationConfigForm.register(
    {
        name: 'nav',
        render: (data: any, extra: any) => (
            <Nav {...data} {...extra} selector={customSelectorConfig} />
        ),
    },
    true
);

ApplicationConfigForm.register(
    {
        name: 'noticeBar',
        render: (data: any, extra: any) => (
            <NoticeBar {...data} {...extra} selector={customSelectorConfig} />
        ),
    },
    true
);

ApplicationConfigForm.register(
    {
        name: 'searchBar',
        render: (data: any, extra: any) => (
            <SearchBar {...data} {...extra} selector={customSelectorConfig} />
        ),
    },
    true
);

ApplicationConfigForm.register(
    {
        defaultConfig: {
            imgMargin: 10,
            margin: 10,
            height: 63,
        },
        name: 'showcase',
        render: (data: any, extra: any) => (
            <Showcase {...data} {...extra} selector={customSelectorConfig} />
        ),
    },
    true
);

ApplicationConfigForm.register(
    {
        defaultConfig: {
            fontSize: 12,
            fontColor: '#c4c4c4',
        },
        name: 'text',
        render: (data: any, extra: any) => (
            <Text {...data} {...extra} selector={customSelectorConfig} />
        ),
    },
    true
);
ApplicationConfigForm.register(
    {
        defaultConfig: {
            fontSize: 18,
        },
        name: 'title',
    },
    true
);

ApplicationConfigForm.register(
    {
        name: 'coupon',
        title: '优惠券',
        defaultConfig: {
            backgroundColor: '#ffffff',
        },
        validate: (data: any) => {
            return new Promise(function(resolve) {
                resolve(data);
            });
        },
        render: (data: any, extra: any) => (
            <Coupon {...data} {...extra} selector={customSelectorConfig} />
        ),
    },
    false
);
ApplicationConfigForm.register({
    name: 'categoryNavigate',
    title: '分组导航',
    render: (data: any, extra: any) => <CustomSelectCategoryNav />,
    validate: () => true,
});
ApplicationConfigForm.register({
    name: 'mallActivity.list',
    title: '商场活动列表',
    defaultConfig: {
        showStyle: 'list',
        show: ['tag', 'share'],
    },
    render: (data: any, extra: any) => <DesignMallActivityList {...data} {...extra} />,
    validate: (data: any) => {
        return new Promise(function(resolve) {
            resolve(data);
        });
    },
});

ApplicationConfigForm.register({
    name: 'mallActivity.calendar',
    title: '活动日历',
    render: (data: any, extra: any) => <DesignMallActivityCalendar />,
    validate: (data: any) => true,
});
ApplicationConfigForm.register({
    name: 'mallActivity.recommend',
    title: '推荐商场活动',
    defaultConfig: {
        showStyle: ENV.TENANT === 'hxgc' ? '2' : '1',
        titleColor: '#000',
        title: '',
        showContent: ['title', 'time'],
        entry: [],
    },
    render: (data: any, extra: any) => (
        <ActitivtyRecommend {...data} {...extra} selector={customSelectorConfig} />
    ),
    validate: (data: any) => {
        return new Promise(function(resolve) {
            resolve(data);
        });
    },
});

ApplicationConfigForm.register({
    name: 'hotArea',
    title: '热区图片',
    render: (data: any, extra: any) => <HotArea {...data} {...extra}></HotArea>,
    validate: () => true,
});

ApplicationConfigForm.register({
    name: 'carousel',
    title: '轮播图',
    defaultConfig: {
        showStyle: '1',
        fillStyle: '2',
        type: '1',
        imageHeight: 150,
        margin: 0,
        marginImage: 10,
        borderRadius: 0,
    },
    validate: (data: any) => {
        return new Promise(function(resolve) {
            resolve(data);
        });
    },
    render: (data: any, extra: any) => {
        return <Carousel {...data} {...extra} />;
    },
});

ApplicationConfigForm.register(
    {
        name: 'flashProducts',
        title: '秒杀',
        defaultConfig: {
            title: {
                text: '限时秒杀',
                color: '#000000',
            },
            background: {
                color: '#ffffff',
            },
            showMore: false,
            showContent: ['title', 'salePrice', 'marketPrice'],
            showContentColor: {
                title: '#000000',
                salePrice: '#d21820',
                marketPrice: '#dcdcdc',
            },
        },
        validate: () => true,
        render: (data: any, extra: any) => (
            <FlashProducts {...data} {...extra} selector={customSelectorConfig} />
        ),
    },
    false
);

ApplicationConfigForm.register(
    {
        name: 'flashProductsPlus',
        title: '秒杀2',
        defaultConfig: {
            title: {
                text: '限时秒杀',
                color: '#000000',
            },
            background: {
                color: '#ffffff',
            },
            showMore: false,
            showContent: ['title', 'salePrice', 'marketPrice', 'countDown'],
            showContentColor: {
                title: '#000000',
                salePrice: '#d21820',
                marketPrice: '#dcdcdc',
            },
        },
        validate: () => true,
        render: (data: any, extra: any) => (
            <FlashProducts {...data} {...extra} selector={customSelectorConfig} />
        ),
    },
    false
);

ApplicationConfigForm.register(
    {
        name: 'weixin',
        title: '关注公众号',
        defaultConfig: {
            title: {
                text: '微信公众号',
                color: '#000000',
            },
            background: {
                color: '#ffffff',
            },
            showMore: false,
        },
        validate: () => true,
        render: (data: any, extra: any) => <WeiXin {...data} {...extra} />,
    },
    false
);

ApplicationConfigForm.register(
    {
        name: 'presale',
        title: '预售',
        defaultConfig: {
            title: {
                text: '预售活动',
                color: '#000000',
            },
            background: {
                color: '#ffffff',
            },
            showMore: false,
            showContent: ['title', 'salePrice', 'marketPrice'],
            showContentColor: {
                title: '#000000',
                salePrice: '#d21820',
                marketPrice: '#dcdcdc',
            },
        },
        validate: () => true,
        render: (data: any, extra: any) => (
            <Presale {...data} {...extra} selector={customSelectorConfig} />
        ),
    },
    false
);

ApplicationConfigForm.register({
    name: 'cgbRecruitLeaderAdv',
    title: '招募团长',
    render: (data: any, extra: any) => <CustomCGBRecruitLeaderAdv {...data} {...extra} />,
    validate: () => true,
});

ApplicationConfigForm.register({
    name: 'recruitDistributorAdv',
    title: '招募分销员',
    defaultConfig: {
        electronicProtocol: { show: 'show' },
    },
    render: (data: any, extra: any) => <CustomRecruitDistributorAdv {...data} {...extra} />,
    validate: (data: any) => true,
});

ApplicationConfigForm.register({
    name: 'memberAssets',
    title: '我的资产',
    defaultConfig: {
        showContent: [],
        type: '1',
    },
    render: (data: any, extra: any) => <MemberAssets {...data} {...extra} />,
    validate: () => true,
});
ApplicationConfigForm.register({
    name: 'memberCard',
    title: '会员卡',
    defaultConfig: {
        bgImage: null,
        level: 'show',
        code: 'show',
        identity: 'hide',
        memberLevel: 'hide',
    },
    render: (data: any, extra: any) => <MemberCard {...data} {...extra} />,
    validate: () => true,
});

ApplicationConfigForm.register({
    name: 'memberDistribution',
    title: '分销中心',
    defaultConfig: {
        title: '分销中心',
        secondary: '查看结算金额',
    },
    render: (data: any, extra: any) => <MemberDistribution {...data} {...extra} />,
    validate: () => true,
});
ApplicationConfigForm.register({
    name: 'memberOrder',
    title: '我的订单',
    render: (data: any, extra: any) => <MemberOrder {...data} {...extra} />,
    validate: () => true,
});
ApplicationConfigForm.register({
    name: 'memberGroup',
    title: '社区团购',
    defaultConfig: {
        title: '社区团购',
        secondary: '查看结算金额',
    },
    render: (data: any, extra: any) => <MemberGroup {...data} {...extra} />,
    validate: () => true,
});
ApplicationConfigForm.register({
    name: 'memberService',
    title: '服务工具',
    defaultConfig: {
        title: '服务',
        entry: [
            { name: 'couponOrder', title: '卡券订单', checked: true },
            { name: 'group', title: '社区团购', checked: true },
            { name: 'group.order', title: '拼团订单', checked: true },
            { name: 'identification', title: '会员认证', checked: true },
            { name: 'feedback', title: '意见反馈', checked: true },
            { name: 'invoice', title: '发票开立', checked: true },
            { name: 'favorite', title: '收藏', checked: true },
            { name: 'coupon', title: '优惠券', checked: true },
            { name: 'consignee', title: '收货地址', checked: true },
            { name: 'services', title: '在线客服', checked: true },
            { name: 'help', title: '帮助中心', checked: true },
            // { name: 'invitation', title: '邀请有礼', checked: true },
            { name: 'membership', title: '会员权益', checked: true },
            { name: 'parking', title: '停车缴费', checked: true },
            { name: 'activity', title: '我的活动', checked: true },
            { name: 'creditEshop', title: '积分换礼', checked: true },
            { name: 'birthdayCaring', title: '生日有礼', checked: true },
            { name: 'relegation', title: '保级查询', checked: true },
            { name: 'selfCrediting', title: '自助积分', checked: true },
            { name: 'authentication', title: '修改密码', checked: true },
        ],
    },
    render: (data: any, extra: any) => <MemberService {...data} {...extra} />,
    validate: () => true,
});
ApplicationConfigForm.register({
    name: 'merchantInfo',
    title: '专柜信息',
    defaultConfig: {
        showContent: ['shoppingGuide', 'makeCall', 'collectMerchant', 'mapGuide'],
    },
    render: (data: any, extra: any) => <MerchantInfo {...data} {...extra} />,
    validate: () => true,
});
ApplicationConfigForm.register({
    name: 'merchantIntro',
    title: '专柜介绍',
    render: (data: any, extra: any) => <MerchantIntro {...data} {...extra} />,
    validate: () => true,
});

ApplicationConfigForm.register({
    name: 'contentNoteIndex',
    title: '笔记',
    render: (data: any, extra: any) => <ContentNoteIndex {...data} {...extra} />,
    validate: () => true,
});

ApplicationConfigForm.register({
    name: 'memberContentInteraction',
    title: '互动数据',
    render: (data: any, extra: any) => <MemberContentInteraction {...data} {...extra} />,
    validate: () => true,
});

ApplicationConfigForm.register({
    name: 'contentNoteMine',
    title: '我的笔记',
    render: (data: any, extra: any) => <ContentNoteMine {...data} {...extra} />,
    validate: () => true,
});

ApplicationConfigForm.register({
    name: 'persionalProfile',
    title: '个人信息',
    render: (data: any, extra: any) => <PersionalProfile {...data} {...extra} />,
    validate: () => true,
});

ApplicationConfigForm.register({
    name: 'memberCover',
    title: '会员卡面',
    defaultConfig: {
        bgImage: null,
        title: null,
        levelShow: 'show',
        progressBarShow: 'show',
        codeShow: 'show',
        progressBarColor: '#ff0000',
    },
    render: (data: any, extra: any) => <MemberCover {...data} {...extra} />,
    validate: () => true,
});

ApplicationConfigForm.register({
    name: 'cardNav',
    title: '卡片导航',
    render: (data: any, extra: any) => (
        <CardNav {...data} {...extra} selector={customSelectorConfig} />
    ),
    validate: () => true,
});

ApplicationConfigForm.register({
    defaultConfig: {
        bgImage: null,
        level: 'show',
    },
    name: 'indexMemberCard',
    title: '首页会员卡片',
    render: (data: any, extra: any) => <IndexMemberCard {...data} {...extra} />,
    validate: () => true,
});

ApplicationConfigForm.register({
    defaultConfig: {
        subsiteImage: null,
        mode: '2',
    },
    name: 'wfjIndexSubsite',
    title: '门店信息',
    render: (data: any, extra: any) => <WfjIndexSubsite {...data} {...extra} />,
    validate: () => true,
});

ApplicationConfigForm.register({
    defaultConfig: {
        bgImage: null,
        showQrCode: 'show',
        showCredit: 'show',
        showButton: false,
        button: {
            bgColor: '#F3AA56',
            title: '签到领积分',
            color: '#999999',
            linkParams: null,
            linkType: 'none',
        },
    },
    name: 'homeMemberCard',
    title: '会员卡',
    render: (data: any, extra: any) => (
        <HomeMemberCard {...data} {...extra} selector={customSelectorConfig} />
    ),
    validate: () => true,
});

ApplicationConfigForm.register({
    name: 'livePreviewCard',
    title: '直播',
    defaultConfig: {
        title: {
            text: '直播',
            color: '#000000',
            image: '',
        },
        more: {
            text: '查看更多',
            color: '#999999',
            image: '',
        },
        background: {
            color: '#ffffff',
            image: '',
        },
        showMore: false,
    },
    validate: () => true,
    render: (data: any, extra: any) => {
        return <LivePreviewCard {...data} {...extra} />;
    },
});

ApplicationConfigForm.register({
    defaultConfig: {
        fontSize: 28,
        showBusinessHours: 'hide',
    },
    name: 'serviceSubsite',
    title: '门店信息',
    render: (data: any, extra: any) => <ServiceSubsite {...data} {...extra} />,
    validate: () => true,
});

ApplicationConfigForm.register(
    {
        defaultConfig: {
            showStyle: '3',
            showCountRadio: 'all',
            sortOrder: '0',
            showContent: ['buy', 'name', 'price', 'marketPrice'],
            showContentColor: {
                price: '#d21820',
                marketPrice: '#999999',
            },
        },
        name: 'waterfallProducts',
        title: '瀑布流商品',
        render: (data: any, extra: any) => (
            <WaterfallProducts {...data} {...extra} selector={customSelectorConfig} />
        ),
        validate: (data: any) => {
            return new Promise(function(resolve) {
                resolve(data);
            });
        },
    },
    false
);
ApplicationConfigForm.register(
    {
        defaultConfig: {
            showStyle: '3',
            showCountRadio: 'all',
            merchantSortOrder: '1',
            showContent: ['buy', 'name', 'price', 'marketPrice'],
            showContentColor: {
                price: '#d21820',
                marketPrice: '#999999',
            },
        },
        name: 'merchantWaterfallProducts',
        title: '瀑布流商品',
        render: (data: any, extra: any) => (
            <WaterfallProducts
                {...data}
                {...extra}
                selector={customSelectorConfig}
                type="merchant"
            />
        ),
        validate: (data: any) => {
            return new Promise(function(resolve) {
                resolve(data);
            });
        },
    },
    false
);
