import React, { PureComponent } from 'react';
import { Table, Button, message, Popconfirm, InputNumber } from 'antd';
import { cloneDeep, remove, find, forEach } from 'lodash';
import { services } from '@comall-backend-builder/core';
import './index.less';

interface SalesPlansProductSelectedDisplayProps {
    data: any[];
    onChange: (data: any[]) => void;
    modifiable: boolean;
}

interface SalesPlansProductSelectedDisplayState {
    /**
     * 设置库存
     */
    stock: undefined | number;
}

/**
 * 销售计划选择商品展示和内容编辑
 */
class SalesPlansProductSelectedDisplay extends PureComponent<
    SalesPlansProductSelectedDisplayProps,
    SalesPlansProductSelectedDisplayState
> {
    state = {
        stock: undefined,
    };

    selectedRows: any[] = [];

    getTableConfig = () => {
        const { modifiable } = this.props;
        let config: any = {
            rowKey: 'id',
            columns: [
                {
                    title: services.language.getText('components.Products.name'),
                    dataIndex: 'productName',
                    key: 'productName',
                    width: 300,
                    render: (i: string) => (
                        <span title={i} className="sales-plans-goods-field-name">
                            {i}
                        </span>
                    ),
                },
                {
                    title: services.language.getText('goodName'),
                    dataIndex: 'name',
                    key: 'name',
                    width: 300,
                    render: (i: string) => (
                        <span title={i} className="sales-plans-goods-field-name">
                            {i}
                        </span>
                    ),
                },
                {
                    title: services.language.getText('productCode'),
                    dataIndex: 'productCode',
                    key: 'productCode',
                    width: 150,
                },
                {
                    title: services.language.getText('goodCode'),
                    dataIndex: 'goodsCode',
                    key: 'goodsCode',
                    width: 150,
                },
                {
                    title: services.language.getText('subsiteName'),
                    dataIndex: 'subsiteName',
                    key: 'subsiteName',
                    width: 150,
                },
                {
                    title: services.language.getText('merchantName'),
                    dataIndex: 'merchantName',
                    key: 'merchantName',
                    width: 150,
                },
                {
                    title: services.language.getText('xsj'),
                    dataIndex: 'salesPrice',
                    key: 'salesPrice',
                    width: 120,
                    align: 'center',
                },
                {
                    title: '库存',
                    dataIndex: 'goodsStock',
                    key: 'goodsStock',
                    width: 120,
                    align: 'center',
                },
                {
                    title: '设置库存',
                    dataIndex: 'stock',
                    key: 'stock',
                    align: 'center',
                    width: 150,
                    render: (i: number, row: any) => {
                        return (
                            <InputNumber
                                value={i}
                                min={0}
                                max={999999}
                                step={1}
                                precision={0}
                                onChange={(value: any) => this.changeValue('stock', value, row.id)}
                            ></InputNumber>
                        );
                    },
                },
            ],
            scroll: {
                x: 1550,
                y: 500,
            },
        };
        if (modifiable) {
            config.columns.push({
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                key: 'operation',
                fixed: 'right',
                width: 100,
                render: (i: any, row: any) => {
                    return (
                        <Button type={'link'} onClick={this.onRemove(row.id)}>
                            {services.language.getText('common.delete')}
                        </Button>
                    );
                },
            });
            config.rowSelection = {
                onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                    this.selectedRows = selectedRows;
                },
            };
        }
        return config;
    };

    changeValue = (field: string, value: any, id: string) => {
        const { data, onChange } = this.props;
        const result = cloneDeep(data);
        let item = find(result, (i) => id === i.id);
        if (item) {
            item[field] = value;
            onChange(result);
        }
    };

    renderFooter = () => {
        const { stock } = this.state;
        return (
            <div className="sales-plans-product-selected-display-footer">
                <div className="batch-remove">
                    <Popconfirm
                        onConfirm={this.batchRemove()}
                        title={services.language.getText('confirmDeleteColumn')}
                        okText={services.language.getText('common.ok')}
                        cancelText={services.language.getText('common.cancel')}
                    >
                        <Button className="setting-item-ok" type="default">
                            {services.language.getText('batchDelete')}
                        </Button>
                    </Popconfirm>
                </div>
                <div className="setting-item">
                    <InputNumber
                        className="item-number"
                        style={{ width: 200 }}
                        placeholder="批量修改设置库存"
                        value={stock}
                        min={0}
                        max={999999}
                        step={1}
                        precision={0}
                        onChange={(e) => this.changeShareStockInputValue('stock', e)}
                    ></InputNumber>
                    <Popconfirm
                        onConfirm={this.shareStockChangeValue('stock')}
                        title="确认批量修改选中的设置库存吗？"
                        okText={services.language.getText('common.ok')}
                        cancelText={services.language.getText('common.cancel')}
                    >
                        <Button className="setting-item-ok" type="default">
                            {services.language.getText('confirmEdit')}
                        </Button>
                    </Popconfirm>
                </div>
            </div>
        );
    };

    shareStockChangeValue = (field: string) => {
        return () => {
            // @ts-ignore：
            const fieldValue = this.state[field];
            const selectedRow = this.selectedRows;
            if (!selectedRow || selectedRow.length === 0) {
                message.info(services.language.getText('selectSetDataColumn'));
                return;
            }
            const { data, onChange } = this.props;
            const result = cloneDeep(data);
            forEach(result, (i) => {
                const item = find(selectedRow, (j) => i.id === j.id);
                item && (i[field] = fieldValue);
            });
            onChange(result);
        };
    };

    changeShareStockInputValue = (field: 'stock', value: any) => {
        // @ts-ignore：
        this.setState({ [field]: value });
    };

    onRemove = (id: string) => {
        const { data, onChange } = this.props;
        return () => {
            const result = cloneDeep(data);
            remove(result, (i) => id === i.id);
            onChange(result);
        };
    };

    batchRemove = () => {
        return () => {
            // @ts-ignore：
            const selectedRow = this.selectedRows;
            if (!selectedRow || selectedRow.length === 0) {
                message.info(services.language.getText('selectBatchDeleteColumn'));
                return;
            }
            const { data, onChange } = this.props;
            const result = cloneDeep(data);
            remove(result, (i) => !!find(selectedRow, (j) => j.id === i.id));
            onChange(result);
        };
    };

    render() {
        const { data, modifiable } = this.props;
        let tableConfig = this.getTableConfig();
        const tableProps = { ...tableConfig, dataSource: data };
        return (
            <div className={'sales-plans-product-selected-display' + (modifiable ? 'modify' : '')}>
                <Table {...tableProps} pagination={false}></Table>
                {modifiable && this.renderFooter()}
            </div>
        );
    }
}

export { SalesPlansProductSelectedDisplay };
