import { connect } from 'react-redux';
import { HeaderLogo } from '../../components/header-logo';

function mapStateToProps(state: any, props: any) {
    return {
        fields: props.entity.fields,
    };
}

export const HeaderLogoContainer = connect(mapStateToProps)(HeaderLogo);
