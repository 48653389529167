import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        AllocationOrderManager: {
            apiPath: '/loader/admin/payment_allocation_records',
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
            filters: {
                subsiteIds: {
                    type: 'string.options.autoComplete.plus',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/loader/source/admin/v1/subsites/mine',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 240 },
                        allowClear: true,
                    },
                },
                payTime: {
                    type: 'object.dateRangePlus',
                    displayName: '<<zfsj>>',
                    startField: 'paidStartTime',
                    endField: 'paidEndTime',
                },
                allocationTime: {
                    type: 'object.dateRangePlus',
                    displayName: '<<fzsj>>',
                    startField: 'allocationStartTime',
                    endField: 'allocationEndTime',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                outOrderNo: {
                    type: 'string',
                    displayName: '<<wbddh>>',
                },
            },
        },
        AllocationRefundOrderManager: {
            apiPath: '/loader/admin/payment_allocation_refund_records',
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
            filters: {
                subsiteIds: {
                    type: 'string.options.autoComplete.plus',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/loader/source/admin/v1/subsites/mine',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 240 },
                        allowClear: true,
                    },
                },
                payTime: {
                    type: 'object.dateRangePlus',
                    displayName: '<<zfsj>>',
                    startField: 'paidStartTime',
                    endField: 'paidEndTime',
                },
                allocationTime: {
                    type: 'object.dateRangePlus',
                    displayName: '<<fzsj>>',
                    startField: 'allocationStartTime',
                    endField: 'allocationEndTime',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                outOrderNo: {
                    type: 'string',
                    displayName: '<<wbddh>>',
                },
            },
        },
    },
    components: {
        AllocationOrderManagerViewPort: {
            component: 'Viewport',
        },
        AllocationOrderManagerQueryPage: {
            entity: 'AllocationOrderManager',
            component: 'TabsPlus',
            type: 'card',
            defaultActiveKey: 'success',
            cache: false,
            animated: false,
            basePath: '/allocation-order-manager',
            items: [
                {
                    title: '<<yfz_1>>',
                    params: { allocationState: 'SUCCESS' },
                    content: {
                        component: 'AllocationOrderManagerTab',
                    },
                    key: 'success',
                },
                {
                    title: '<<tkfzdd>>',
                    params: { allocationState: 'SUCCESS' },
                    content: {
                        component: 'AllocationRefundOrderManagerTab',
                    },
                    key: 'refund',
                },
            ],
        },
        AllocationOrderManagerTab: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                { component: 'AllocationOrderManagerFilter' },
                { component: 'AllocationOrderManagerButtonGroup' },
                { component: 'AllocationOrderManagerDataList' },
            ],
        },
        AllocationRefundOrderManagerTab: {
            entity: 'AllocationRefundOrderManager',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                { component: 'AllocationOrderManagerFilter' },
                { component: 'AllocationRefundOrderManagerButtonGroup' },
                { component: 'AllocationRefundOrderManagerDataList' },
            ],
        },
        AllocationOrderManagerFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                { property: 'subsiteIds' },
                { property: 'payTime' },
                { property: 'allocationTime' },
                { property: 'orderNo' },
                { property: 'outOrderNo' },
            ],
        },
        AllocationOrderManagerButtonGroup: {
            component: 'GridLayout',
            style: { marginTop: 24, marginBottom: 24 },
            items: [
                {
                    component: 'ExportDataByFilterParamsButton',
                    params: {
                        text: '<<exportSearchResult>>',
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                        apiPath: '/loader/admin/payment_allocation_records/export',
                        requestMethod: 'get',
                        target: '_blank',
                    },
                    span: 4,
                },
            ],
        },
        AllocationRefundOrderManagerButtonGroup: {
            component: 'GridLayout',
            style: { marginTop: 24, marginBottom: 24 },
            items: [
                {
                    component: 'ExportDataByFilterParamsButton',
                    params: {
                        text: '<<exportSearchResult>>',
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                        apiPath: '/loader/admin/payment_allocation_refund_records/export',
                        requestMethod: 'get',
                        target: '_blank',
                    },
                    span: 4,
                },
            ],
        },
        AllocationOrderManagerDataList: {
            component: 'DataList',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            grid: { gutter: 24 },
            renderItem: 'PaymentAllocationOrderListItem',
        },
        AllocationRefundOrderManagerDataList: {
            component: 'DataList',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            grid: { gutter: 24 },
            renderItem: 'PaymentAllocationRefundOrderListItem',
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/allocation-order-manager',
                    component: 'AllocationOrderManagerViewPort',
                    breadcrumbName: '<<paymentAllocation>>  /  <<allocationOrderManager>>',
                    privilege: {
                        path: 'paymentAllocationOrders',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/',
                            component: 'AllocationOrderManagerQueryPage',
                        },
                    ],
                },
            ],
        },
    ],
};
