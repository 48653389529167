import React, { PureComponent } from 'react';
import { Table } from 'antd';
import { CouponOrderAppliesSelector } from '../coupon-order-applies-selector';
import { services } from '@comall-backend-builder/core';

interface CouponOrderAppliesSelectorDisplayProps {
    orderId: number;
    id: number;
    data: Array<any>;
    onChange: (data: Array<any>) => void;
    disabled?: boolean;
    apiPath?: any;
    params?: any;
    showCouponName?: boolean;
}
interface CouponOrderAppliesSelectorDisplayState {
    selectedRows: Array<any>;
}

/**
 * 优惠券选择并且展示选择内容
 */
class CouponOrderAppliesSelectorDisplay extends PureComponent<
    CouponOrderAppliesSelectorDisplayProps,
    CouponOrderAppliesSelectorDisplayState
> {
    static defaultProps = {
        orderId: 0,
        id: 0,
        disabled: false,
    };

    getTableConfig = () => {
        const { showCouponName } = this.props;
        let columns: any = [
            {
                title: services.language.getText('couponBaCode'),
                key: 'couponNo',
                dataIndex: 'couponNo',
                render: (text: any) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: '优惠券状态',
                key: 'couponStatus',
                dataIndex: 'couponStatus',
                render: (text: any, row: any) => {
                    let couponStatusStr = '';
                    if (text === 'ENABLED') {
                        couponStatusStr = '待使用';
                        if (row.expire) {
                            couponStatusStr += '已过期';
                        }
                    } else if (text === 'USED') {
                        couponStatusStr = '已使用';
                        if (row.expire) {
                            couponStatusStr += '已过期';
                        }
                    } else if (text === 'EXPIRED') {
                        couponStatusStr = '已过期';
                    } else if (text === 'REFUND_APPLY') {
                        couponStatusStr = '退款中';
                    } else if (text === 'REFUND_FINISH') {
                        couponStatusStr = '退款完成';
                    }
                    return couponStatusStr;
                },
            },
            {
                title: services.language.getText('cllx'),
                key: 'refundMoney',
                dataIndex: 'refundMoney',
                render: (text: any) => {
                    let refundMoneyStr = '';
                    text = text + '';
                    if (text === 'true') {
                        refundMoneyStr = '退款退券';
                    } else {
                        refundMoneyStr = services.language.getText('ztq');
                    }
                    return refundMoneyStr;
                },
            },
            {
                title: services.language.getText('zxxj'),
                key: 'executePrice',
                dataIndex: 'executePrice',
                render: (text: any) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: services.language.getText('zxjf'),
                key: 'pointExecutePrice',
                dataIndex: 'pointExecutePrice',
                render: (text: any) => {
                    return <span>{text}</span>;
                },
            },
        ];
        if (showCouponName) {
            columns.unshift({
                title: '门票名称',
                key: 'couponName',
                width: 280,
                dataIndex: 'couponName',
                render: (text: any) => {
                    return (
                        <span
                            style={{
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: 248,
                            }}
                        >
                            {text}
                        </span>
                    );
                },
            });
        }
        let tableConfig: any = {
            columns: columns,
            style: {
                marginTop: 10,
            },
        };
        return tableConfig;
    };

    onSelectCoupon = (coupons: Array<any>) => {
        //覆盖之前的选择
        const { onChange } = this.props;
        onChange(coupons);
    };

    render() {
        const { data, id, orderId, disabled, apiPath, params } = this.props;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: data,
            pagination: false,
        };

        return (
            <div className="coupon-order-applies-selector-display">
                <CouponOrderAppliesSelector
                    disabled={disabled}
                    orderId={orderId}
                    apiPath={apiPath}
                    params={params || { orderItemId: id }}
                    selectValues={data}
                    onChange={this.onSelectCoupon}
                />
                <Table {...tableProps}></Table>
            </div>
        );
    }
}

export { CouponOrderAppliesSelectorDisplay };
