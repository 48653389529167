import React, { PureComponent } from 'react';
import { Cascader } from 'antd';
import { services } from '@comall-backend-builder/core';

const { api } = services;

export interface ProductCategoriesSelectStates {
    categories: any;
}
export class ProductCategoriesSelect extends PureComponent<
    {
        disabled: boolean | undefined;
        value: any;
        onChange: (value: Array<number> | undefined) => void;
        style: any;
    },
    ProductCategoriesSelectStates
> {
    constructor(props: any) {
        super(props);
        this.state = {
            categories: [],
        };
    }

    componentDidMount() {
        this.loadProductCategories();
    }

    loadProductCategories = () => {
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/categories/tree',
        };
        api.get({}, config).then((res: any) => {
            res = res.map((item: any) => {
                item.isLeaf = item.isParent ? false : true; //isLeaf为false 代表可查询下一级
                return item;
            });
            this.setState({
                categories: res,
            });
        });
    };

    onChange = (value: any[]) => {
        const { onChange } = this.props;
        if (onChange) {
            if (value && value.length === 0) {
                onChange(undefined);
            } else {
                onChange(value);
            }
        }
    };

    render() {
        const { categories } = this.state;
        const { value, style } = this.props;
        return (
            <Cascader
                value={value}
                fieldNames={{ label: 'name', value: 'id', children: 'childrens' }}
                options={categories}
                onChange={this.onChange}
                changeOnSelect
                style={style}
            />
        );
    }
}
