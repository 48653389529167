import { actions, services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { store } from '@comall-backend-builder/core/lib/store';
import { message as AntMessage } from 'antd';
export const config: Config = {
    entities: {
        allocationConfigManager: {
            apiPath: '/loader/admin/payment_allocation_configs',
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
            filters: {
                subsiteIds: {
                    type: 'string.options.autoComplete.plus',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/loader/source/admin/v1/subsites/mine',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 240 },
                        allowClear: true,
                    },
                },
            },
            properties: {
                subsiteVo: {
                    type: 'object.subForm',
                    properties: {
                        subsiteName: {
                            type: 'string',
                            displayName: '<<subsiteName>>',
                        },
                    },
                },
                paymentModeName: {
                    type: 'string',
                    displayName: '<<zffs>>',
                },
                allocationType: {
                    type: 'string.options.select',
                    displayName: '<<fzlx>>',
                    options: [
                        { id: 'IMMEDIATE', name: '<<jsfz>>' },
                        { id: 'DEFERRED', name: '<<ysfz>>' },
                    ],
                },
                accounts: {
                    displayName: '<<fzfbl>>',
                    type: 'array.allocationAccountRatio',
                },
                enabled: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'true', name: '<<beforeOpen>>' },
                        { id: 'false', name: '<<beforeClose>>' },
                    ],
                },
                lastModifyTime: {
                    displayName: '<<gxsj_1>>',
                    type: 'string',
                },
                basicConfig: {
                    type: 'object.subForm',
                    displayName: '<<jbpz>>',
                    properties: {
                        subsiteId: {
                            type: 'string.options.autoComplete',
                            displayName: '<<subsiteName>>',
                            options: [],
                            rules: [{ required: true }],
                            source: {
                                apiPath: '/loader/source/admin/v1/subsites/mine',
                            },
                            controlConfig: {
                                placeholder: '<<selectPlease>>',
                                style: { width: 240 },
                                allowClear: true,
                            },
                        },
                        paymentMode: {
                            type: 'object.option.autoComplete.plus',
                            displayName: '<<qxzzffs>>',
                            rules: [{ required: true }],
                            options: [],
                            source: {
                                apiPath: '/admin/payment_mode',
                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                                params: { page: 1, perPage: 999 },
                                dataType: 'paging',
                            },
                            controlConfig: {
                                placeholder: '<<selectPlease>>',
                                style: { width: 240 },
                                allowClear: true,
                            },
                        },
                        allocationType: {
                            type: 'string.options.radio',
                            displayName: '<<fzlx>>',
                            defaultValue: 'IMMEDIATE',
                            rules: [{ required: true }],
                            options: [
                                { id: 'IMMEDIATE', name: '<<jsfz>>' },
                                // { id: 'DEFERRED', name: '<<ysfz>>' },
                            ],
                        },
                    },
                },
                allocationConfigA: {
                    type: 'object.subForm',
                    displayName: '<<dyfzfpz>>',
                    properties: {
                        accountName: {
                            type: 'string',
                            displayName: '<<fzfmc>>',
                            rules: [{ required: true }, { max: 20, message: '<<fzfmcbncg>>' }],
                            controlConfig: {
                                placeholder: '<<bncg>>',
                                style: { width: 240 },
                            },
                        },
                        accountNumber: {
                            type: 'string',
                            displayName: '<<fzfshh>>',
                            rules: [{ required: true }, { max: 100, message: '<<zdsr_1>>' }],
                            controlConfig: {
                                placeholder: '<<txdsfzffpdshh>>',
                                style: { width: 240 },
                            },
                        },
                        accountType: {
                            type: 'string.options.radio',
                            displayName: '<<fzfzhlx>>',
                            defaultValue: 'DOMESTIC',
                            rules: [{ required: true }],
                            options: [
                                { id: 'DOMESTIC', name: '<<jnzh>>' },
                                { id: 'FOREIGN', name: '<<fzhxsdjxhk>>' },
                            ],
                        },
                        allocationRatio: {
                            type: 'number.positive.plus',
                            displayName: '<<fzffzbl>>',
                            defaultValue: '100',
                            rules: [{ required: true }],
                            controlConfig: {
                                formatter: (value: number) => `${value}%`,
                                min: 0,
                                max: 100,
                                precision: 2,
                            },
                        },
                    },
                },
                allocationConfigB: {
                    type: 'object.subForm',
                    displayName: '<<defzfpz>>',
                    properties: {
                        accountName: {
                            type: 'string',
                            displayName: '<<fzfmc>>',
                            rules: [
                                // { required: true },
                                { max: 20, message: '<<fzfmcbncg>>' },
                            ],
                            controlConfig: {
                                placeholder: '<<bncg>>',
                                style: { width: 240 },
                            },
                        },
                        accountNumber: {
                            type: 'string',
                            displayName: '<<fzfshh>>',
                            rules: [
                                // { required: true },
                                { max: 100, message: '<<zdsr_1>>' },
                            ],
                            controlConfig: {
                                placeholder: '<<txdsfzffpdshh>>',
                                style: { width: 240 },
                            },
                        },
                        accountType: {
                            type: 'string.options.radio',
                            displayName: '<<fzfzhlx>>',
                            defaultValue: 'FOREIGN',
                            // rules: [{ required: true }],
                            options: [
                                { id: 'DOMESTIC', name: '<<jnzh>>' },
                                { id: 'FOREIGN', name: '<<fzhxsdjxhk>>' },
                            ],
                            controlConfig: {
                                disabled: true,
                            },
                        },
                        allocationRatio: {
                            type: 'number.positive.plus',
                            displayName: '<<fzffzbl>>',
                            defaultValue: '0',
                            // rules: [{ required: true }],
                            controlConfig: {
                                formatter: (value: number) => `${value}%`,
                                min: 0,
                                max: 100,
                                disabled: true,
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        AllocationConfigManagerViewPort: {
            component: 'Viewport',
            entity: 'allocationConfigManager',
        },
        AllocationConfigManagerQueryPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'AllocationConfigManagerFilter',
                },
                {
                    component: 'AllocationConfigManagerButtonGroup',
                },

                {
                    component: 'Text',
                    text: '<<sybgnqqxqrywcxtdj>>',
                    style: {
                        color: '#f00',
                    },
                },
                {
                    component: 'AllocationConfigManagerTable',
                },
            ],
        },
        AllocationConfigManagerFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteIds',
                },
            ],
        },
        AllocationConfigManagerButtonGroup: {
            component: 'GridLayout',
            direction: 'horizontal',
            style: {
                marginTop: 24,
                marginBottom: 24,
            },
            items: [
                {
                    component: 'Button',
                    type: 'primary',
                    text: '<<cjfzzffs>>',
                    route: '/allocation-config-manager/add',
                    // span: 6
                },
            ],
        },
        AllocationConfigManagerTable: {
            component: 'DataTable',
            loadFirstPage: true,
            style: {
                marginTop: 24,
            },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '20', '50'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                { property: 'subsiteVo.subsiteName' },
                { property: 'paymentModeName' },
                { property: 'allocationType' },
                { property: 'accounts' },
                { property: 'enabled' },
                { property: 'lastModifyTime' },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'enabled',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                                    apiPath: '/admin/payment_allocation_configs/:id/disable',
                                    config: {
                                        content: '{{row.name}}<<beforeClose>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                        className: 'confirm-content',
                                    },
                                },
                                {
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                                    apiPath: '/admin/payment_allocation_configs/:id/enable',
                                    config: {
                                        content: '{{row.name}}<<beforeOpen>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/allocation-config-manager/edit/{{row.id}}',
                    },
                ],
            },
        },
        AllocationConfigManagerAddPage: {
            component: 'CreateFormPlus',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'basicConfig.subsiteId' },
                { property: 'basicConfig.paymentMode' },
                { property: 'basicConfig.allocationType' },
                { property: 'allocationConfigA.accountName' },
                { property: 'allocationConfigA.accountNumber' },
                { property: 'allocationConfigA.accountType' },
                { property: 'allocationConfigA.allocationRatio' },
                { property: 'allocationConfigB.accountName' },
                { property: 'allocationConfigB.accountNumber' },
                { property: 'allocationConfigB.accountType' },
                { property: 'allocationConfigB.allocationRatio' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onFieldChange,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
            onValidate,
        },
        AllocationConfigManagerEditPage: {
            entity: 'allocationConfigManager',
            loaderType: 'get',
            component: 'ModifyFormPlus',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'basicConfig.subsiteId', controlConfig: { disabled: true } },
                { property: 'basicConfig.paymentMode', controlConfig: { disabled: true } },
                { property: 'basicConfig.allocationType' },
                { property: 'allocationConfigA.accountName' },
                { property: 'allocationConfigA.accountNumber' },
                { property: 'allocationConfigA.accountType' },
                { property: 'allocationConfigA.allocationRatio' },
                { property: 'allocationConfigB.accountName' },
                { property: 'allocationConfigB.accountNumber' },
                { property: 'allocationConfigB.accountType' },
                { property: 'allocationConfigB.allocationRatio' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onFieldChange,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
            onValidate,
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/allocation-config-manager',
                    component: 'AllocationConfigManagerViewPort',
                    breadcrumbName: '<<allocationConfigManagerViewPort>>',
                    privilege: {
                        path: 'paymentAllocationConfigManager',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            breadcrumbName: '<<cjfzzffs>>',
                            component: 'AllocationConfigManagerAddPage',
                        },
                        {
                            path: '/edit/:id',
                            breadcrumbName: '<<bjfzzffs>>',
                            component: 'AllocationConfigManagerEditPage',
                        },
                        {
                            path: '/',
                            component: 'AllocationConfigManagerQueryPage',
                        },
                    ],
                },
            ],
        },
    ],
};

function onFieldChange(
    componentId: string,
    uuids: Record<string, string>,
    name: string,
    value: unknown
) {
    if (name === 'allocationConfigA.accountType') {
        const otherAccountType = value === 'DOMESTIC' ? 'FOREIGN' : 'DOMESTIC';
        store.dispatch(
            actions.formChangeAction(
                uuids[componentId],
                'allocationConfigB.accountType',
                otherAccountType
            )
        );
    }

    if (name === 'allocationConfigA.allocationRatio' && !isNaN(Number(value))) {
        const otherAllocationRatio = Math.max(100 - Number(value), 0);
        store.dispatch(
            actions.formChangeAction(
                uuids[componentId],
                'allocationConfigB.allocationRatio',
                otherAllocationRatio
            )
        );
    }
    store.dispatch(actions.formChangeAction(uuids[componentId], name, value));
}

function onValidate(record: any) {
    if (Number(record.allocationConfigA.allocationRatio) < 100) {
        if (!record.allocationConfigB.accountName || !record.allocationConfigB.accountNumber) {
            AntMessage.error(services.language.getText('dyfzffzblxy'));
            return false;
        }
    }
    return true;
}
