import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { get } from 'lodash';
import { services } from '@comall-backend-builder/core';

/**
 * 暂时只定义需要用到的code
 */
const paymentModeCode = {
    '8311': '积分抵扣',
    '8315': '金币抵扣',
    // '0511': '电子礼品卡',
    '8314': '余额券支付',
    '6016': '余额支付',
};

const showPaymentSetting = (values: any) =>
    Object.keys(paymentModeCode).includes(get(values, 'paymentModeCode', '').toString());
const paymentAuthorizationProtocolConfigEnabled = (values: any) =>
    showPaymentSetting(values) &&
    get(values, 'paymentAuthorizationProtocolConfig.enabled') === 'true';
// const paymentPasswordConfigEnabled = (values: any) =>
//     showPaymentSetting(values) && get(values, 'paymentPasswordConfig.enabled', '') === 'true';

export const config: Config = {
    entities: {
        PaymentModeManagementEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
            apiPath: '/loader/admin/payment_mode',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<zffsmc>>',
                },
                enabled: {
                    type: 'string.options.select',
                    displayName: '<<zffszt>>',
                    options: [
                        { id: 'true', name: '<<common.opend>>' },
                        { id: 'false', name: '<<common.close>>' },
                    ],
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                    displayName: '<<zffsmc>>',
                },
                enabled: {
                    type: 'string.statusBadge',
                    displayName: '<<status>>',
                },
                status: {
                    type: 'string',
                },
                sequence: {
                    type: 'string',
                    displayName: '<<yxj>>',
                },
                typeName: {
                    type: 'string',
                    displayName: '<<zffslx>>',
                },
                paymentModeCode: {
                    type: 'string',
                },
                procedureFee: {
                    type: 'string.formatter',
                    displayName: '<<sxf>>',
                },
                enabledPaymentPassword: {
                    type: 'string.options.select',
                    displayName: '<<sfsymmzf>>',
                    options: [
                        { id: 'true', name: '<<yes>>' },
                        { id: 'false', name: '<<no>>' },
                    ],
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<basicSetting>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<zffsmc>>',
                            rules: [{ required: true, message: '<<qsrzffsmc>>' }],
                            controlConfig: {
                                placeholder: '<<qsrzffsmc>>',
                                maxLength: 20,
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        typeName: {
                            type: 'string',
                            displayName: '<<zffslx>>',
                            controlConfig: {
                                disabled: true,
                                placeholder: '',
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        instruction: {
                            type: 'string',
                            displayName: '<<zfsm>>',
                            controlConfig: {
                                disabled: true,
                                placeholder: '',
                                style: {
                                    width: 400,
                                },
                            },
                        },
                        sequence: {
                            displayName: '<<yxj>>',
                            type: 'number.integer',
                            rules: [{ required: true, message: '<<qsryxj>>' }],
                            defaultValue: 0,
                            controlConfig: {
                                placeholder: '<<inputPlease>>',
                                max: 999999,
                                min: 0,
                                style: {
                                    width: 120,
                                },
                            },
                        },
                        procedureFee: {
                            displayName: '<<sxf>>',
                            type: 'number.tip',
                            // rules: [{ required: true, message: '<<h_sxf_msg>>' }],
                            defaultValue: 0,
                            controlConfig: {
                                placeholder: '<<inputPlease>>',
                                max: 99.99,
                                precision: 2,
                                min: 0,
                                addonafter: '%',
                                style: {
                                    width: 120,
                                },
                            },
                            extra: `<<sxfjyyyzjjszddjs>>`,
                        },
                    },
                },
                paymentInfo: {
                    type: 'object.subForm',
                    displayName: '<<zfsz>>',
                    properties: {},
                },
                paymentAuthorizationProtocolConfig: {
                    type: 'object.subForm',
                    displayName: '<<zfsqsz>>',
                    properties: {
                        enabled: {
                            type: 'string.options.radio',
                            displayName: '<<zffsktsq>>',
                            defaultValue: 'false',
                            options: [
                                {
                                    id: 'true',
                                    name: '<<common.opend>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<common.close>>',
                                },
                            ],
                            rules: [{ required: true }],
                            extra: `<<zfsqsz>>`,
                        },
                        enabledAuthorization: {
                            type: 'string.options.radio',
                            displayName: '<<zffssfrz>>',
                            defaultValue: 'false',
                            options: [
                                {
                                    id: 'true',
                                    name: '<<common.opend>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<common.close>>',
                                },
                            ],
                            rules: [{ required: true }],
                            extra: `<<h_zffssfrz_tip>>`,
                        },
                        description: {
                            type: 'string.text.paragraph',
                            displayName: '<<ktsqsm>>',
                            rules: [
                                {
                                    max: 100,
                                    message: '<<h_ktsqsm_msg>>',
                                },
                            ],
                            controlConfig: {
                                rows: 2,
                                maxLength: 100,
                            },
                        },
                        protocolArticle: {
                            type: 'array.article.seletor',
                            displayName: '<<ktsqxy>>',
                        },
                    },
                },
                paymentPasswordConfig: {
                    type: 'object.subForm',
                    displayName: '<<zfmmsz>>',
                    properties: {
                        enabled: {
                            type: 'string.options.radio',
                            displayName: '<<zffsmm>>',
                            defaultValue: 'false',
                            options: [
                                {
                                    id: 'true',
                                    name: '<<common.opend>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<common.close>>',
                                },
                            ],
                            rules: [{ required: true }],
                        },
                        allowModify: {
                            type: 'string.options.radio',
                            displayName: '<<zfmmxgsz>>',
                            defaultValue: 'false',
                            options: [
                                {
                                    id: 'true',
                                    name: '<<common.opend>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<common.close>>',
                                },
                            ],
                            rules: [{ required: true }],
                            extra: `<<h_zfmmxgsz_tip>>`,
                        },
                        useMemberPassword: {
                            type: 'string.options.radio',
                            displayName: '<<hyxttyglmm>>',
                            defaultValue: 'false',
                            options: [
                                {
                                    id: 'true',
                                    name: '<<yes>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<no>>',
                                },
                            ],
                            rules: [{ required: true }],
                            extra: `<<h_hyxttyglmm_tip>>`,
                        },
                    },
                },
            },
        },
        ArticleSelector: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/admin/payment_agreement',
            properties: {
                id: {
                    type: 'string',
                    displayName: '<<wz_1>>ID',
                },
                businessTypeStr: {
                    type: 'string',
                    displayName: '<<ywlx>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<wzmc_1>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: '1', name: '<<beforeOpen>>' },
                        { id: '0', name: '<<beforeClose>>' },
                    ],
                },
                createTime: {
                    type: 'string',
                    displayName: '<<cjrq>>',
                },
            },
            filters: {
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<wzmc_1>>',
                },
            },
        },
    },
    components: {
        PaymentModeManagementView: {
            component: 'Viewport',
            entity: 'PaymentModeManagementEntity',
        },
        PaymentModeManagementPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'PaymentModeManagementFilter',
                },
                { component: 'PaymentModeManagementTable' },
            ],
        },
        PaymentModeManagementFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            style: {
                marginBottom: 16,
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'enabled',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
            ],
        },
        PaymentModeManagementTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'PaymentModeManagementDataTable',
                    loadFirstPage: true,
                    scroll: { x: true },
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'name',
                        },
                        {
                            property: 'enabled',
                            displayConfig: {
                                statusConfig: {
                                    true: {
                                        text: '<<common.opend>>',
                                        status: 'success',
                                    },
                                    false: {
                                        text: '<<common.close>>',
                                        status: 'default',
                                    },
                                },
                            },
                        },
                        {
                            property: 'sequence',
                        },
                        {
                            property: 'typeName',
                        },
                        {
                            property: 'procedureFee',
                            displayConfig: {
                                format: (value: string) => {
                                    return value ? value + '%' : value;
                                },
                            },
                        },
                        {
                            property: 'enabledPaymentPassword',
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        fixed: 'right',
                        className: 'action-column',
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: false },
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                                            apiPath: '/admin/payment_mode/:id/disabled',
                                            config: {
                                                content: '<<zffsybgb>>',
                                                text: '<<common.close>>',
                                                style: { color: 'red' },
                                            },
                                            confirm: {
                                                text: '<<qrgb>>{{row.name}}?',
                                                className: 'confirm-content',
                                            },
                                        },
                                        {
                                            params: { status: true },
                                            value: false,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
                                            apiPath: '/admin/payment_mode/:id/enabled',
                                            config: {
                                                content: '<<zffsybkq>>',
                                                text: '<<common.opend>>',
                                            },
                                            confirm: {
                                                text: '<<qrkq>>{{row.name}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                text: '<<common.edit>>',
                                type: 'link',
                                path: '/payment-mode-management/edit/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        PaymentModeManagementEditPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'PaymentModeManagementEditForm' }],
        },
        PaymentModeManagementEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'PaymentModeManagementEntity',
            loaderType: 'get',
            labelCol: 5,
            controlCol: 19,
            style: { width: 950 },
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.typeName' },
                { property: 'baseInfo.instruction' },
                { property: 'baseInfo.sequence' },
                { property: 'baseInfo.procedureFee' },
                // {
                //     property: 'paymentInfo',
                //     visible: showPaymentSetting,
                // },
                {
                    property: 'paymentAuthorizationProtocolConfig.enabled',
                    visible: showPaymentSetting,
                },
                {
                    property: 'paymentAuthorizationProtocolConfig.enabledAuthorization',
                    visible: paymentAuthorizationProtocolConfigEnabled,
                },
                {
                    property: 'paymentAuthorizationProtocolConfig.description',
                    visible: paymentAuthorizationProtocolConfigEnabled,
                },
                {
                    property: 'paymentAuthorizationProtocolConfig.protocolArticle',
                    visible: paymentAuthorizationProtocolConfigEnabled,
                },
                {
                    property: 'paymentPasswordConfig.enabled',
                    visible: showPaymentSetting,
                },
                {
                    property: 'paymentPasswordConfig.allowModify',
                    visible: showPaymentSetting,
                },
                {
                    property: 'paymentPasswordConfig.useMemberPassword',
                    visible: (values: any) =>
                        get(values, 'paymentPasswordConfig.allowModify') === 'true',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.goBack>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const paymentAuthorizationProtocolConfig = get(
                    entity,
                    'paymentAuthorizationProtocolConfig'
                );
                if (paymentAuthorizationProtocolConfig) {
                    const { enabled, protocolArticle } = paymentAuthorizationProtocolConfig;
                    if (enabled === 'true' && (!protocolArticle || !protocolArticle.length)) {
                        AntMessage.warning(services.language.getText('qxzktsqxy'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/payment-mode-management',
                    component: 'PaymentModeManagementView',
                    breadcrumbName: '<<paymentModeManagement>>',
                    privilege: {
                        path: 'paymentModeManagement',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'PaymentModeManagementEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        { path: '/', component: 'PaymentModeManagementPage' },
                    ],
                },
            ],
        },
    ],
};
