import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { Modal, Button } from 'antd';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
const api = services.api;

export interface AddPrinterButtonState {
    visible: boolean;
    codeImage: string;
    subsiteName: string;
}

/**
 * 添加/编辑物流公司
 */
export class AddPrinterButton extends PureComponent<any, AddPrinterButtonState> {
    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
            codeImage: '',
            subsiteName: '',
        };
    }
    componentDidMount() {
        this.loadImage();
        const { location } = this.props;
        const search = location.search;
        let { subsiteName } = Object.fromEntries(new URLSearchParams(search).entries()) || {};
        this.setState({ subsiteName });
    }

    toggleModal = () => {
        this.setState({
            visible: !this.state.visible,
        });
    };
    loadImage = () => {
        const { params } = this.props;
        return api
            .get(
                {
                    scene: 'subsiteId=' + params.subsiteId,
                    page: 'sub-packages/delivery/pages/apply-printer/index',
                    type: 'LOGISTICS_PRINTER',
                },
                {
                    apiRoot: `${ENV.API_ROOT}`,
                    apiPath: '/wechat/jhcode',
                }
            )
            .then((data: any) => {
                if (data) {
                    const result = data.image;
                    const wxaCodeimg = 'data:image/jpg;base64,' + result;
                    this.setState({
                        codeImage: wxaCodeimg,
                    });
                }
            });
    };

    render() {
        let { visible, codeImage, subsiteName } = this.state;

        const modalProps = {
            width: 400,
            title: services.language.getText('xzdyy'),
            visible: visible,
            footer: null,
            onCancel: this.toggleModal,
        };

        return (
            <div className="add-printer-button">
                <div className="subsite-name">
                    {services.language.getText('subsiteName')}：{subsiteName}
                </div>
                <div className="button-box">
                    <Button type="primary" onClick={this.toggleModal}>
                        {services.language.getText('xzdyy')}
                    </Button>
                    <div className="tips">{language.getText('xpzdyyyydydzmd')}</div>
                </div>

                <Modal {...modalProps}>
                    <div className="add-printer-modal">
                        <img className="code-img" alt="" src={codeImage} />
                        <div className="code-tip">{services.language.getText('qdyysywxsmsq')}</div>
                    </div>
                </Modal>
            </div>
        );
    }
}
