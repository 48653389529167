import React, { PureComponent, Fragment, createElement } from 'react';
import { connect } from 'react-redux';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import './index.less';

function mapStateToProps(state: any, props: any) {
    const { entity } = props;
    return {
        entity,
    };
}
/**
 * 团长管理列表
 */
class CgbLeaders extends PureComponent<any, any> {
    renderTable = () => {
        const { entity } = this.props;
        let tableProps = {
            entity,
            rowKey: 'id',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: {
                x: 1600,
            },
            columns: [
                {
                    property: 'name',
                    displayName: services.language.getText('tzmc'),
                    width: 8,
                },
                {
                    property: 'mobile',
                    displayName: services.language.getText('phoneNum'),
                    width: 8,
                },
                {
                    property: 'subsiteName',
                    displayName: services.language.getText('belongSubsite'),
                    width: 8,
                },
                {
                    property: 'communityName',
                    displayName: services.language.getText('sqmc'),
                    width: 8,
                },
                {
                    property: 'address',
                    displayName: services.language.getText('szdq'),
                    width: 12,
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                    },
                },
                {
                    property: 'createTimeStr',
                    displayName: services.language.getText('jrsj'),
                    width: 10,
                },
                {
                    property: 'totalOrderAmount',
                    displayName: services.language.getText('xsjez'),
                    width: 6,
                },
                {
                    property: 'profit',
                    displayName: services.language.getText('lr'),
                    width: 6,
                },
                {
                    property: 'orderCount',
                    displayName: services.language.getText('dds'),
                    width: 7,
                },
                {
                    property: 'status',
                    displayName: services.language.getText('status'),
                    width: 8,
                    displayConfig: {
                        statusConfig: {
                            NORMAL: {
                                text: services.language.getText('zc_1'),
                                status: 'success',
                            },
                            ABNORMAL: {
                                text: services.language.getText('qt'),
                                status: 'error',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                width: 5,
                items: [{}],
            },
        };
        return createElement(ComponentsManager.get('DataTable'), tableProps);
    };
    render() {
        return (
            <Fragment>
                <div>{this.renderTable()}</div>
            </Fragment>
        );
    }
}

export const CgbLeadersContainers = connect(mapStateToProps)(CgbLeaders);
