import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
export const MiniProgramCodeDefalutLinksLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/mini_program_code_default_links';
        return await api.get(data, config).then((res: any) => {
            if (res.result) {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        const targetJson = JSON.parse(item.target);
                        item.pageName = targetJson.linkName;
                        return item;
                    });
            }
            return res;
        });
    },
};
