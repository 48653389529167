import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { Modal } from 'antd';
import { chain, cloneDeep, find, get } from 'lodash';
import {
    ExchangeLimitEnum,
    HideSettingEnum,
    HideSettingTypeEnum,
} from '../config/member/point-manage/credit-eshop';

const api = services.api;
export const creditEshopLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = '/admin/credit_products';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/credit_products/${id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.baseInfo = {
                    dateRange: {
                        start: res.startTime,
                        end: res.endTime,
                    },
                };
                res.subsiteId = res.subsites.map((item: any) => ({
                    id: item.id + '',
                    name: item.name,
                    address: item.address,
                    status: item.status,
                }));
                res.coupon = res.couponDefinition
                    ? [
                          {
                              ...res.couponDefinition,
                              batchNo: res.couponDefinition.code,
                              names: res.name,
                          },
                      ]
                    : [];
                res.gift =
                    res.productType === 'POINTS' && res.pointsGoodsInfo
                        ? {
                              id: res.pointsGoodsInfo.productId,
                              name: res.pointsGoodsInfo.productName,
                              weight: res.pointsGoodsInfo?.weight,
                              salesUnit: res.pointsGoodsInfo?.salesUnit,
                              marketPrice: res.pointsGoodsInfo?.marketPrice,
                              images: res.images,
                              receiveExplain: res.info,
                              details: res.description,
                              names: res.name,
                          }
                        : undefined;
                let maxSaleNumberEveryDay = '';
                if (
                    res.productLimit &&
                    res.productLimit.limitRules &&
                    res.productLimit.limitRules.length > 0
                ) {
                    const maxSaleNumberEveryVo = find(res.productLimit.limitRules, {
                        ruleType: 'EACH_DAY',
                    });
                    if (maxSaleNumberEveryVo && maxSaleNumberEveryVo.limitCount) {
                        maxSaleNumberEveryDay = maxSaleNumberEveryVo.limitCount;
                    }
                }
                res.stock = {
                    maxCount: res.totalQuantity,
                    changeCount: '',
                    surplusCount: res.stock,
                    defalutMaxCount: res.totalQuantity,
                    defalutSurplusCount: res.stock,
                    maxSaleNumberEveryDay: maxSaleNumberEveryDay,
                };
                res.images = res.images.map((item: any) => {
                    return {
                        id: item.id,
                        path: item.url,
                    };
                });
                // res.brand = res.pointsGoodsInfo?.brand ? [res.pointsGoodsInfo?.brand] : [];
                // res.categories = res.pointsGoodsInfo?.categories.map((item: any) => {
                //     return parseInt(item.id);
                // });
                res.weight = res.pointsGoodsInfo?.weight;
                res.exchangeConditions =
                    res.exchangeConditions && res.exchangeConditions.length
                        ? res.exchangeConditions.map((item: any) => {
                              if (item.apportionInfos && item.apportionInfos.length > 0) {
                                  item.apportionInfos = item.apportionInfos.map((info: any) => {
                                      info.id = info.couponDefinitionId;
                                      info.name = info.couponName;
                                      info.type = info.couponTypeName;
                                      return info;
                                  });
                              }
                              //历史数据 cycleLimits将活动期间数据放到最后
                              if (item.cycleLimits) {
                                  let cycleLimitsPediod = item.cycleLimits.filter(
                                      (item: any) => item.ruleType !== 'EACH_PERIOD'
                                  );
                                  if (!cycleLimitsPediod || cycleLimitsPediod.length === 0) {
                                      item.cycleLimits.push({
                                          ruleType: 'EACH_DAY',
                                          limitCount: null,
                                      });
                                  }
                                  const index = item.cycleLimits.findIndex(
                                      (i: any) => i.ruleType === 'EACH_PERIOD'
                                  );
                                  if (index < 0) {
                                      item.cycleLimits.push({
                                          ruleType: 'EACH_PERIOD',
                                          limitCount: null,
                                      });
                                  } else {
                                      item.cycleLimits.push(item.cycleLimits.splice(index, 1)[0]);
                                  }
                              }
                              return item;
                          })
                        : [
                              {
                                  cardLevelId: null,
                                  cardLevelName: null,
                                  points: undefined,
                                  salesPrice: undefined,
                                  cycleLimits: null,
                                  apportionInfos: [],
                              },
                          ];
                const commonExchange: any = find(res.exchangeConditions, { cardLevelId: null });
                let cardLevelExchange = res.exchangeConditions.filter(
                    (item: any) => item.cardLevelId !== null
                );
                if (commonExchange) {
                    commonExchange.cardLevelId = -1;
                }
                res.exchangeInfo = {
                    exchange: {
                        commonExchange: [commonExchange],
                        cardLevelExchange: cardLevelExchange,
                    },
                    // selectVirtualProduct:
                    //     res.saleRuleGoods &&
                    //     res.saleRuleGoods.map((item: any) => {
                    //         item.apportionPrices = item.apportionPrices.map((price: any) => {
                    //             let couponTypeDesc = '';
                    //             if (price.couponType === 'CASH') couponTypeDesc = '代金券';
                    //             if (price.couponType === 'GIFT') couponTypeDesc = '礼品券';
                    //             if (price.couponType === 'PARKING') couponTypeDesc = '停车券';
                    //             if (price.couponType === 'FREIGHT') couponTypeDesc = '运费券';
                    //             if (price.couponType === 'BALANCE') couponTypeDesc = '余额券';
                    //             return {
                    //                 ...price,
                    //                 price: {
                    //                     apportionPoints: price.apportionPoints,
                    //                     apportionPrice: price.apportionPrice || 0,
                    //                 },
                    //                 couponDefinitionName: price.couponName,
                    //                 couponTypeDesc,
                    //             };
                    //         });
                    //
                    //         return {
                    //             ...item,
                    //             price: {
                    //                 points: item.points,
                    //                 salesPrice: item.salesPrice || 0,
                    //             },
                    //             virtualProductCouponDefinitionType: res.definitionType,
                    //             virtualProductCouponDefinitionId: res.couponDefinitionId,
                    //             limitNumber:
                    //                 item.saleRuleGoodsStock && item.saleRuleGoodsStock > 0
                    //                     ? item.saleRuleGoodsStock
                    //                     : 0,
                    //             id: item.goodsId,
                    //             maxLimitNumber:
                    //                 Number(item.saleRuleGoodsStock) + Number(item.goodsStock),
                    //         };
                    //     }),
                };
                if (res.normalConditions && res.normalConditions.length > 0) {
                    const [condition] = res.normalConditions;
                    if (condition.type === ExchangeLimitEnum.MEMBER_CARD_LEVEL) {
                        res.exchangeInfo.exchangeLimit = ExchangeLimitEnum.MEMBER_CARD_LEVEL;
                        res.exchangeInfo.memberLevels = (condition.scopes || []).map(
                            (item: any) => {
                                return {
                                    id: item.id.toString(),
                                    name: item.name,
                                };
                            }
                        );
                        res.exchangeInfo.limitCrowds = [];
                    } else if (condition.type === ExchangeLimitEnum.CROWD) {
                        res.exchangeInfo.exchangeLimit = ExchangeLimitEnum.CROWD;
                        res.exchangeInfo.memberLevels = [];
                        res.exchangeInfo.limitCrowds = (condition.scopes || []).map((item: any) => {
                            return {
                                id: item.id,
                                name: item.name,
                                peopleNumber: item.peopleNumber,
                                subsiteName: item.subsiteName,
                                creationMode: item.creationMode,
                            };
                        });
                    }
                } else {
                    res.exchangeInfo.exchangeLimit = ExchangeLimitEnum.NO_RESTRICTIONS;
                    res.exchangeInfo.memberLevels = [];
                    res.exchangeInfo.limitCrowds = [];
                }
                const hideSetting =
                    res.hideSettingVo &&
                    res.hideSettingVo.type &&
                    res.hideSettingVo.type !== HideSettingTypeEnum.NO_RESTRICTIONS
                        ? res.hideSettingVo.type === HideSettingTypeEnum.MEMBER_CARD_LEVEL ||
                          res.hideSettingVo.type === HideSettingTypeEnum.CROWD
                            ? HideSettingEnum.RESTRICTIONS
                            : HideSettingEnum.RESTRICTIONS_HIDE
                        : HideSettingEnum.NO_RESTRICTIONS;
                const showSettingType =
                    (res.hideSettingVo &&
                        res.hideSettingVo.type &&
                        res.hideSettingVo.type === HideSettingTypeEnum.MEMBER_CARD_LEVEL) ||
                    res.hideSettingVo.type === HideSettingTypeEnum.CROWD
                        ? res.hideSettingVo.type
                        : HideSettingTypeEnum.MEMBER_CARD_LEVEL;
                const showSettingMemberLevels =
                    res.hideSettingVo &&
                    res.hideSettingVo.type === HideSettingTypeEnum.MEMBER_CARD_LEVEL
                        ? (res.hideSettingVo.values || []).map((item: any) => ({
                              id: item.value.toString(),
                          }))
                        : [];
                const showSettingCrowds =
                    res.hideSettingVo && res.hideSettingVo.type === HideSettingTypeEnum.CROWD
                        ? (res.hideSettingVo.values || []).map((item: any) => {
                              return {
                                  id: item.value,
                                  name: item.name,
                                  peopleNumber: item.peopleNumber,
                                  subsiteName: item.subsiteName,
                                  creationMode: item.creationMode,
                              };
                          })
                        : [];

                const hideSettingType =
                    (res.hideSettingVo &&
                        res.hideSettingVo.type &&
                        res.hideSettingVo.type === HideSettingTypeEnum.MEMBER_CARD_LEVEL_LIMIT) ||
                    res.hideSettingVo.type === HideSettingTypeEnum.CROWD_LIMIT
                        ? res.hideSettingVo.type
                        : HideSettingTypeEnum.MEMBER_CARD_LEVEL_LIMIT;
                const hideSettingMemberLevels =
                    res.hideSettingVo &&
                    res.hideSettingVo.type === HideSettingTypeEnum.MEMBER_CARD_LEVEL_LIMIT
                        ? (res.hideSettingVo.values || []).map((item: any) => ({
                              id: item.value.toString(),
                          }))
                        : [];
                const hideSettingCrowds =
                    res.hideSettingVo && res.hideSettingVo.type === HideSettingTypeEnum.CROWD_LIMIT
                        ? (res.hideSettingVo.values || []).map((item: any) => {
                              return {
                                  id: item.value,
                                  name: item.name,
                                  peopleNumber: item.peopleNumber,
                                  subsiteName: item.subsiteName,
                                  creationMode: item.creationMode,
                              };
                          })
                        : [];
                res.showTag = {
                    afterSales: {
                        overdueAutoRefund: res.refundType.includes('EXPIRE') ? true : false,
                        anytimeRefund: res.refundType.includes('ANY_TIME') ? true : false,
                        packageRefund: res.refundType.includes('PACKAGE_REFUND') ? true : false,
                    },
                    notice: {
                        type: res.noticeType,
                        time: res.noticeTime ? res.noticeTime : '',
                    },
                    canShare: res.canShare ? ['true'] : ['false'],
                    targetHideUserLevels:
                        res.hideMemberCardLevel && res.hideMemberCardLevel.length
                            ? { checked: true, userLevels: res.hideMemberCardLevel }
                            : {
                                  checked: false,
                                  userLevels: null,
                              },
                    tagIdList:
                        get(res, 'saleRuleTags') && get(res, 'saleRuleTags.length')
                            ? get(res, 'saleRuleTags').map((item: any) => {
                                  const { tagId, tagName } = item;
                                  return { id: tagId, name: tagName };
                              })
                            : [],
                    sequence: window.location.href.includes('/copy') ? undefined : res.sequence,
                    info: res.info,
                    giftInfo: res.productType === 'POINTS' ? res.info : undefined,
                    deliveryModeType: get(res, 'pointsGoodsInfo.deliveryModeType', 'UNIFIED'),
                    assignDeliveryModes: get(res, 'pointsGoodsInfo.assignDeliveryModes', []),
                    hideSetting,
                    showSettingType,
                    showSettingMemberLevels,
                    showSettingCrowds,
                    hideSettingType,
                    hideSettingMemberLevels,
                    hideSettingCrowds,
                };
                res.pictureInfo = {
                    description: res.description,
                    giftDescription: res.productType === 'POINTS' ? res.description : undefined,
                };
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = '/admin/credit_products';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config).catch((err) => {
            if (err.response.body.err_code === 300004011) {
                let msg = JSON.parse(err.response.body.err_msg);
                Modal.error({
                    title: '错误提示',
                    content: msg.message,
                    okText: '继续编辑',
                    onOk: () => {
                        services.behaviorHandle({ route: '/credit-shop/edit/' + msg.id });
                    },
                });
            } else {
                throw err;
            }
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = `/admin/credit_products/${id}`;
        const newParams: any = dataParser(params);
        return await api.put(newParams, config).catch((err) => {
            if (err.response.body.err_code === 300004011) {
                let msg = JSON.parse(err.response.body.err_msg);
                err.response.body.err_msg = null;
                err.response.body.message = null;
                Modal.error({
                    title: '错误提示',
                    content: msg.message,
                    okText: '继续编辑',
                    onOk: () => {},
                });
            }

            throw err;
        });
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    const {
        baseInfo,
        subsiteId,
        coupon,
        name,
        stock,
        images,
        exchangeInfo,
        showTag,
        pictureInfo,
        productType,
        weight,
        gift,
    } = params;
    let newParams: any = {
        productType,
    };
    if (baseInfo?.dateRange) {
        newParams.startTime = baseInfo.dateRange.start;
        newParams.endTime = baseInfo.dateRange.end;
    }
    newParams.subsiteId = subsiteId.map((item: { id: string; name: string }) => item.id).join(',');
    const isCoupon = productType === 'VIRTUAL_COUPON';
    if (isCoupon) {
        newParams.couponDefinitionId = coupon[0].id;
        newParams.definitionType = coupon[0].type.includes('PACKAGE') ? 'COUPON_PACKAGE' : 'COUPON';
    } else {
        const pointsGoodsInfo = {
            productId: gift.id,
            weight,
            assignDeliveryModes: showTag.assignDeliveryModes,
            deliveryModeType: showTag.deliveryModeType,
        };
        newParams.pointsGoodsInfo = pointsGoodsInfo;
    }

    newParams.name = name;
    const isAddCopy =
        window.location.href.includes('/add') || window.location.href.includes('/copy');
    if (isAddCopy) {
        //新增
        newParams.totalQuantity = stock.maxCount;
    } else {
        //编辑
        newParams.changeStock = stock.changeCount ? stock.changeCount : 0;
    }
    if (stock.maxSaleNumberEveryDay) {
        newParams.productLimit = {
            limitRules: [
                {
                    ruleType: 'EACH_DAY',
                    limitCount: stock.maxSaleNumberEveryDay,
                },
            ],
        };
    }
    newParams.images = images.map(({ id, path }: { id: string; path: string }) => ({
        id,
        url: path,
    }));
    newParams.saleRuleDefinitionType = 'VIRTUAL_GOODS';

    // 设置兑换限制
    const exchangeLimit = exchangeInfo.exchangeLimit;
    const memberLevels = exchangeInfo.memberLevels;
    const limitCrowds = exchangeInfo.limitCrowds;
    if (exchangeLimit === ExchangeLimitEnum.CROWD) {
        newParams.normalConditions = [
            {
                type: 'CROWD',
                scopes: limitCrowds,
            },
        ];
    } else if (exchangeLimit === ExchangeLimitEnum.MEMBER_CARD_LEVEL) {
        newParams.normalConditions = [
            {
                type: 'MEMBER_CARD_LEVEL',
                scopes: memberLevels,
            },
        ];
    } else {
        newParams.normalConditions = [];
    }

    const exchange = exchangeInfo.exchange;
    if (exchange) {
        if (newParams.definitionType === 'COUPON') {
            exchange.commonExchange[0].apportionInfos = [
                {
                    apportionPoints: exchange.commonExchange[0].points,
                    apportionPrice: exchange.commonExchange[0].salesPrice,
                    id: coupon[0].id,
                    quantity: 1,
                },
            ];
            if (exchange.cardLevelExchange && exchange.cardLevelExchange.length > 0) {
                exchange.cardLevelExchange.forEach((item: any) => {
                    item.apportionInfos = [
                        {
                            apportionPoints: item.points,
                            apportionPrice: item.salesPrice,
                            id: params.coupon[0].id,
                            quantity: 1,
                        },
                    ];
                });
            }
        }
        const exchangeArray: any[] = [];
        exchange.commonExchange.forEach((item: any) => {
            const newItem = cloneDeep(item);
            newItem.cardLevelId = null; //传后端null，必须new，不然组件二次编辑会修改不了值
            if (newItem.cycleLimits) {
                //排除没有填写的
                let newCycleLimits = newItem.cycleLimits.filter((item: any) => item.limitCount);
                newItem.cycleLimits = newCycleLimits;
            }
            exchangeArray.push(newItem);
        });
        if (exchange.cardLevelExchange && exchange.cardLevelExchange.length > 0) {
            let newCardLevelExchange = cloneDeep(exchange.cardLevelExchange);
            if (exchangeLimit === ExchangeLimitEnum.MEMBER_CARD_LEVEL) {
                newCardLevelExchange = newCardLevelExchange.filter((item: any) => {
                    return (memberLevels || [])
                        .map((i: any) => i.id.toString())
                        .includes(item.cardLevelId.toString());
                });
            } else if (exchangeLimit === ExchangeLimitEnum.CROWD) {
                newCardLevelExchange = [];
            }
            newCardLevelExchange.forEach((item: any) => {
                if (item.cycleLimits) {
                    //排除没有填写的
                    let newCycleLimits = item.cycleLimits.filter((item: any) => item.limitCount);
                    item.cycleLimits = newCycleLimits;
                }
                exchangeArray.push(item);
            });
        }
        newParams.point = exchange.commonExchange[0].points;
        newParams.salePrice = exchange.commonExchange[0].salesPrice;
        newParams.exchangeConditions = exchangeArray;
    }
    if (showTag) {
        if (isCoupon) {
            const refundTypeArray: string[] = [];
            const afterSales = params.showTag.afterSales;
            if (afterSales.overdueAutoRefund) {
                refundTypeArray.push('EXPIRE');
            }
            if (afterSales.anytimeRefund) {
                refundTypeArray.push('ANY_TIME');
            }
            if (afterSales.packageRefund) {
                refundTypeArray.push('PACKAGE_REFUND');
            }
            newParams.refundType = refundTypeArray.join(',');
        }
        newParams.canShare = get(params, 'showTag.canShare[0]') !== 'false';
        newParams.noticeType = showTag.notice.type;
        newParams.noticeTime =
            showTag.notice.type && showTag.notice.type === 'MANUAL_TIME'
                ? params.showTag.notice.time
                : '';
        newParams.hideMemberCardLevel =
            showTag.targetHideUserLevels && showTag.targetHideUserLevels.checked
                ? params.showTag.targetHideUserLevels.userLevels.map((item: any) => item.id)
                : null;
        newParams.tagIdList = params.showTag.tagIdList.map((item: any) => item.id);
        newParams.sequence = params.showTag.sequence;
        newParams.info =
            params.productType === 'POINTS' ? params.showTag.giftInfo : params.showTag.info;
        let settingType = HideSettingTypeEnum.NO_RESTRICTIONS;
        let settingValues = [];
        if (
            params.showTag.hideSetting &&
            params.showTag.hideSetting === HideSettingEnum.RESTRICTIONS
        ) {
            if (
                params.showTag.showSettingType &&
                params.showTag.showSettingType === HideSettingTypeEnum.MEMBER_CARD_LEVEL
            ) {
                settingType = HideSettingTypeEnum.MEMBER_CARD_LEVEL;
                settingValues = (params.showTag.showSettingMemberLevels || []).map((item: any) => {
                    return {
                        value: item.id,
                        name: item.name,
                    };
                });
            }
            if (
                params.showTag.showSettingType &&
                params.showTag.showSettingType === HideSettingTypeEnum.CROWD
            ) {
                settingType = HideSettingTypeEnum.CROWD;
                settingValues = (params.showTag.showSettingCrowds || []).map((item: any) => {
                    return {
                        value: item.id,
                        name: item.name,
                        peopleNumber: item.number,
                        subsiteName: item.subsiteName,
                    };
                });
            }
        }
        if (
            params.showTag.hideSetting &&
            params.showTag.hideSetting === HideSettingEnum.RESTRICTIONS_HIDE
        ) {
            if (
                params.showTag.hideSettingType &&
                params.showTag.hideSettingType === HideSettingTypeEnum.MEMBER_CARD_LEVEL_LIMIT
            ) {
                settingType = HideSettingTypeEnum.MEMBER_CARD_LEVEL_LIMIT;
                settingValues = (params.showTag.hideSettingMemberLevels || []).map((item: any) => {
                    return {
                        value: item.id,
                        name: item.name,
                    };
                });
            }
            if (
                params.showTag.hideSettingType &&
                params.showTag.hideSettingType === HideSettingTypeEnum.CROWD_LIMIT
            ) {
                settingType = HideSettingTypeEnum.CROWD_LIMIT;
                settingValues = (params.showTag.hideSettingCrowds || []).map((item: any) => {
                    return {
                        value: item.id,
                        name: item.name,
                        peopleNumber: item.number,
                        subsiteName: item.subsiteName,
                    };
                });
            }
        }
        newParams.hideSettingVo = {
            saleRuleId: params.id,
            type: settingType,
            values: settingValues,
        };
    }
    if (pictureInfo) {
        newParams.description =
            params.productType === 'POINTS'
                ? params.pictureInfo.giftDescription
                : params.pictureInfo.description;
    }
    //以前的虚拟商品传参，后续会继续用到
    // newParams.productId = params.exchangeInfo.selectVirtualProduct[0].productId;
    // newParams.couponDefinitionId =
    //     params.exchangeInfo.selectVirtualProduct[0].virtualProductCouponDefinitionId;
    // const definitionType =
    //     params.exchangeInfo.selectVirtualProduct[0].virtualProductCouponDefinitionType;
    // newParams.definitionType = definitionType;
    // newParams.goods = {
    //     goods: params.exchangeInfo.selectVirtualProduct.map((good: any) => {
    //         return {
    //             displayStock: good.goodsStock,
    //             goodsId: good.id,
    //             points: good.price.points,
    //             salesPrice: good.price.salesPrice || 0,
    //             stock: good.limitNumber,
    //             goodsPriceApportionInfo:
    //                 definitionType === 'COUPON_PACKAGE'
    //                     ? good.apportionPrices.map((item: any) => {
    //                         return {
    //                             apportionPoints: item.price.apportionPoints,
    //                             apportionPrice: item.price.apportionPrice || 0,
    //                             id: item.couponDefinitionId,
    //                             quantity: item.quantity,
    //                         };
    //                     })
    //                     : null,
    //         };
    //     }),
    // };
    return newParams;
}

export const CreditShopCardLevelLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        // 业务上只允许选择同一分站下的门店
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`;
        config.apiPath = '/admin/store_card_levels';
        // 需要的是非分页数据
        const params: any = { page: 1, per_page: 100, status: 'ENABLE' };

        if (data.subsiteId && data.subsiteId.length) {
            params.subsiteId = data.subsiteId.map((i: any) => i.id);
        }
        const res = await api.get<any>(params, config);
        return chain(res.result)
            .map((card) => ({ id: card.cardLevelId + '', name: card.name }))
            .unionBy('id')
            .value();
    },
};
