import React, { Component } from 'react';
import './index.less';
import { services } from '@comall-backend-builder/core';

export class CGBLeaderSetup extends Component<any, any> {
    render() {
        const applyLeaderIcon = './images/editor/apply-leader.png';
        const applyLeaderSetupIcon = './images/editor/apply-leader-setup.png';
        return (
            <div className="cgb-leader-setup-view">
                <div className="left-view">
                    <img alt={services.language.getText('sqtz')} src={applyLeaderIcon} />
                </div>
                <div className="right-view">
                    <img alt={services.language.getText('tzsz_1')} src={applyLeaderSetupIcon} />
                </div>
            </div>
        );
    }
}
