import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { BirthdayCaringMessage } from './birthday-caring-message';

export class BirthdayCaringMessageMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <BirthdayCaringMessage {...controlInfo} />;
    }
}
