// @ts-nocheck
import React, { PureComponent } from 'react';
import { Button, Modal, Upload, Icon, message, message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { ExportButton } from '@comall-backend-builder/components-basis';
import { ForwardDataCenterModal } from '../../services';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const { Dragger } = Upload;

/**
 * 预售计划导入商品数据按钮
 */
export class SalesPlansImportButton extends PureComponent<
    any,
    { fileList: any; showModal: boolean; statusMsg: string }
> {
    state = {
        fileList: [],
        showModal: false,
        statusMsg: language.getText('uploadFileClickButton'),
    };

    toggleModal = () => {
        const { showModal } = this.state;
        this.setState({ showModal: !showModal });
    };

    onImport = () => {
        this.setState({
            statusMsg: language.getText('importBeforeNotPage'),
        });
        const {
            row: { id },
            entity,
        } = this.props;
        const { fileList } = this.state;
        const formData = new FormData();
        formData.append('files', fileList[0]);
        formData.append('meta_data', JSON.stringify({ salesPlanId: id }));
        services.api
            .upload(
                { files: formData },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/FILE`,
                    apiPath: '/admin/files/locations/802/upload',
                    fileName: 'files',
                    progressCallBack: () => {},
                    useCustomData: true,
                }
            )
            .then((res: any) => {
                ForwardDataCenterModal();
                entity.search();
                this.toggleModal();
            })
            .catch((err: any) => {
                AntMessage.error(err.response.body.err_msg);
                this.setState({
                    statusMsg: language.getText('importFail'),
                });
                throw err;
            });
    };

    handleOk = () => {
        const { fileList } = this.state;
        if (fileList && fileList.length > 0) {
            this.onImport();
        } else {
            message.error(language.getText('pleaseUploadFile'));
            return;
        }
    };

    render() {
        const { showModal, fileList, statusMsg } = this.state;
        const { row } = this.props;
        let disabled = false;
        if (row.canImport !== undefined) {
            disabled = !row.canImport;
        }
        const uploadProps = {
            accept: '.xlsx,.xls,.csv',
            multiple: false,
            onRemove: () => {
                this.setState({ fileList: [] });
            },
            beforeUpload: (file: any) => {
                this.setState({
                    fileList: [file],
                });
                return false;
            },
            fileList,
        };
        return (
            <div className="sales-plans-import-botton">
                <Button
                    disabled={disabled}
                    type="link"
                    className="btn-data-statistics"
                    onClick={this.toggleModal}
                >
                    {language.getText('importProduct')}
                </Button>
                <Modal
                    onCancel={this.toggleModal}
                    visible={showModal}
                    title={language.getText('batchImport')}
                    footer={[
                        <span className="sales-plans-import-botton-status-msg">{statusMsg}</span>,
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            {language.getText('import')}
                        </Button>,
                        <Button key="back" onClick={this.toggleModal}>
                            {language.getText('common.goBack')}
                        </Button>,
                    ]}
                >
                    <Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">{language.getText('selectFile')}</p>
                        <p className="ant-upload-hint">
                            {language.getText('supportExtension')}.xlsx、.xls、.csv
                        </p>
                    </Dragger>
                    <div className="sales-plans-import-botton-tips">
                        <p className="sales-plans-import-botton-tips-text">
                            {language.getText('uploadIntro')}
                        </p>
                        <p className="sales-plans-import-botton-tips-text">
                            {language.getText('uploadIntro1')}
                        </p>
                        <p className="sales-plans-import-botton-tips-text">
                            {language.getText('uploadIntro2')}
                        </p>
                        <p className="sales-plans-import-botton-tips-text">
                            {language.getText('uploadIntro3')}
                        </p>
                        <p className="sales-plans-import-botton-tips-text">
                            {language.getText('uploadIntro4')}
                        </p>
                    </div>
                    <div className="upload-tips">
                        <ExportButton
                            type="link"
                            text={language.getText('downloadTemplate')}
                            showErrorMessage={true}
                            source={{
                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                                apiPath: '/admin/sales_plans/template.xlsx',
                            }}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}
