import * as React from 'react';
import classNames from 'classnames';
import { isArray } from 'lodash';

import { ObjectMode } from '@comall-backend-builder/types';

export class ObjectLinkMode extends ObjectMode {
    /**
     * 获取展示组件
     */
    public getAvailableDisplayComponent(value: any, displayInfo: any) {
        if (isArray(value)) {
            return (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {value.map((item: any) => (
                        <Link {...displayInfo} text={item.text} url={item.url} />
                    ))}
                </div>
            );
        }
        const { text, url } = value;
        return <Link {...displayInfo} text={text} url={url} />;
    }
}

class Link extends React.Component<{
    className?: string;
    style?: React.CSSProperties;
    target?: '_blank' | '_self';
    url?: string;
    text?: string;
}> {
    render() {
        const { className, style, target = '_self', url, text = '' } = this.props;
        if (!url) {
            return <span>{text}</span>;
        }
        return (
            <a
                className={classNames('type-object mode-object-url', className)}
                style={style}
                href={url}
                target={target}
            >
                {text}
            </a>
        );
    }
}
