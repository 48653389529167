import { BaseConfig } from '../../../applications/cae/interfaces';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import get from 'lodash/get';

export let config: BaseConfig = {
    entities: {
        PopUpAds: {
            apiPath: '/loader/admin/pop_up_ads',
            properties: {
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                    rules: [{ required: true }],
                },
                scope: {
                    type: 'string.options.radio',
                    displayName: '适用范围',
                    defaultValue: 'SUBSITE',
                    rules: [{ required: true }],
                    options: [
                        { id: 'SUBSITE', name: '<<subsites>>' },
                        { id: 'PLATFORM', name: '平台' },
                    ],
                },
                subsites: {
                    type: 'array.options.autoComplete',
                    displayName: '<<applicableSubsite>>',
                    options: [],
                    defaultValue: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                name: {
                    type: 'string',
                    displayName: '展示标题',
                    rules: [
                        { required: true },
                        {
                            max: 16,
                            message: '广告标题不能超过16字符',
                        },
                    ],
                },
                showTime: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '展示时间',
                    rules: [{ required: true }],
                    controlConfig: {
                        style: { width: 380 },
                    },
                },
                startTime: {
                    type: 'string.dateTime',
                    displayName: '展示开始时间',
                },
                endTime: {
                    type: 'string.dateTime',
                    displayName: '展示结束时间',
                },
                status: {
                    type: 'string.options.radio',
                    displayName: '<<status>>',
                    defaultValue: 'ENABLED',
                    options: [
                        { id: 'ENABLED', name: '<<enable>>' },
                        { id: 'DISABLED', name: '<<disabled>>' },
                    ],
                },
                pic: {
                    type: 'string.image.picker',
                    displayName: '弹窗广告',
                    rules: [{ required: true }],
                    controlConfig: {
                        fileName: 'fileName',
                        uploadType: 'single',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                        maxCount: 1,
                        limitTips: {
                            limitText: '建议图片宽度为750px，高度不超过1000px；大小不超过1M；',
                            limitUnit: ' ',
                            className: 'image-picker-tips-ext',
                        },
                    },
                },
                type: {
                    type: 'string.options.select',
                    displayName: '展示位置',
                    options: [{ id: 'home', name: '首页' }],
                    rules: [{ required: true }],
                },
                config: {
                    type: 'string.options.select',
                    displayName: '触发条件',
                    options: [{ id: 'dayThreeTime', name: '每个用户每天固定展示3次' }],
                    rules: [{ required: true }],
                },
                second: {
                    type: 'number.integer',
                    displayName: '触发次数',
                },
                crowd: {
                    type: 'string.options.select',
                    displayName: '投放人群',
                    options: [{ id: 'all', name: '<<all>>' }],
                    rules: [{ required: true }],
                },
                linkType: {
                    type: 'object.linkType',
                    displayName: '链接配置',
                    defaultValue: { linkType: 'none', linkParams: {} },
                },
                platformLinkType: {
                    type: 'object.linkType',
                    displayName: '链接配置',
                    defaultValue: { linkType: 'none', linkParams: {} },
                    controlConfig: {
                        usePlatForm: true,
                    },
                },
            },
            filters: {
                subsiteName: {
                    type: 'object.option.autoComplete',
                    displayName: '<<applicableSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                showTime: {
                    type: 'object.dateTimeRangePlus',
                    displayName: '展示时间',
                    controlConfig: {
                        style: { width: 380 },
                    },
                },
            },
        },
    },
    components: {
        PopUpAdsView: {
            component: 'Viewport',
            entity: 'PopUpAds',
        },
        PopUpAdsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    submit: {
                        text: '<<common.search>>',
                        style: {
                            marginRight: 16,
                        },
                    },
                    reset: {
                        text: '<<common.reset>>',
                    },
                    fields: [
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'showTime',
                        },
                    ],
                },
                {
                    component: 'Button',
                    text: '新增广告',
                    route: '/pop-up-ads/add',
                    type: 'primary',
                    icon: 'plus',
                    style: {
                        width: 110,
                    },
                    className: 'mt24 mb24',
                },
                { component: 'PopUpAdsTable' },
            ],
        },

        PopUpAdsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return `共 ${total} 条记录`;
                },
            },
            columns: [
                {
                    property: 'subsiteName',
                    displayConfig: {
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'name',
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                items: [
                    {
                        text: '<<common.edit>>',
                        type: 'link',
                        statusKey: 'row.canEdit',
                        path: '/pop-up-ads/edit/{{row.id}}',
                    },
                    {
                        type: 'delete',
                        text: '<<common.delete>>',
                        statusKey: 'row.canDelete',
                        config: {
                            type: 'link',
                            id: 'row.id',
                            tooltip: null,
                            title: '<<confirmDelete>>',
                            okText: '<<common.ok>>',
                            cancelText: '<<common.cancel>>',
                        },
                    },
                ],
            },
        },

        PopUpAdsAddPage: {
            entity: 'PopUpAds',
            component: 'FlexLayout',
            items: [
                { component: 'PopUpAdsPreview' },
                {
                    component: 'PopUpAdsAddForm',
                },
            ],
        },
        PopUpAdsAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            style: { width: 600 },
            labelCol: 6,
            controlCol: 18,
            fields: [
                { property: 'name' },
                { property: 'showTime' },
                { property: 'scope' },
                {
                    property: 'subsites',
                    visible: (values: any) => get(values, 'scope') === 'SUBSITE',
                },
                { property: 'type' },
                { property: 'crowd' },
                { property: 'config' },
                {
                    property: 'pic',
                },
                {
                    property: 'linkType',
                    className: 'link-type-property',
                    visible: (values: any) => get(values, 'scope') === 'SUBSITE',
                },
                {
                    property: 'platformLinkType',
                    className: 'link-type-property',
                    visible: (values: any) => get(values, 'scope') === 'PLATFORM',
                },
            ],
            submit: true,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entityFields: any) => {
                const scope = get(entityFields, 'scope', null);
                const subsites = get(entityFields, 'subsites', null);
                if (scope === 'SUBSITE' && (!subsites || !subsites.length)) {
                    AntMessage.warning(services.language.getText('selectSubsite'));
                    return false;
                }

                return true;
            },
        },
        PopUpAdsEditPage: {
            component: 'FlexLayout',
            entity: 'PopUpAds',
            loaderType: 'get',
            items: [
                { component: 'PopUpAdsPreview' },
                {
                    component: 'PopUpAdsEditForm',
                },
            ],
        },
        PopUpAdsEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            style: { width: 600 },
            labelCol: 6,
            controlCol: 18,
            fields: [
                { property: 'name' },
                { property: 'showTime' },
                { property: 'scope' },
                {
                    property: 'subsites',
                    visible: (values: any) => get(values, 'scope') === 'SUBSITE',
                },
                { property: 'type' },
                { property: 'crowd' },
                { property: 'config' },
                {
                    property: 'pic',
                },
                {
                    property: 'linkType',
                    className: 'link-type-property',
                    visible: (values: any) => get(values, 'scope') === 'SUBSITE',
                },
                {
                    property: 'platformLinkType',
                    className: 'link-type-property',
                    visible: (values: any) => get(values, 'scope') === 'PLATFORM',
                },
            ],
            submit: true,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
            onValidate: (entityFields: any) => {
                const scope = get(entityFields, 'scope', null);
                const subsites = get(entityFields, 'subsites', null);
                if (scope === 'SUBSITE' && (!subsites || !subsites.length)) {
                    AntMessage.warning(services.language.getText('selectSubsite'));
                    return false;
                }

                return true;
            },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/pop-up-ads',
                    breadcrumbName: '管理弹窗',
                    component: 'PopUpAdsView',
                    privilege: {
                        path: 'popUpAds',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/edit/:id',
                            component: 'PopUpAdsEditPage',
                            breadcrumbName: '编辑广告',
                        },
                        {
                            path: '/add',
                            component: 'PopUpAdsAddPage',
                            breadcrumbName: '新增广告',
                        },
                        { path: '/', component: 'PopUpAdsPage' },
                    ],
                },
            ],
        },
    ],
};
