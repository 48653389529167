import React, { PureComponent } from 'react';
import { PickingLists, PickingListCategoryItem } from '../../../services/order-print';
import { forEach } from 'lodash';

import './index.less';
import { services } from '@comall-backend-builder/core';

interface PickingListCategoryItemGoods extends PickingListCategoryItem {
    /**
     * 分类
     */
    name: string;
}

const formatQuantity = (quantity: number) => {
    const quantities = quantity.toString().split('.');
    if (quantities.length < 2) {
        return quantity;
    } else {
        return quantities[0] + '.' + quantities[1].slice(0, 3);
    }
};

const getPickQuantity = (item: PickingListCategoryItemGoods) => {
    if (!item.goods) {
        return '';
    }
    const quantity =
        item.goods.displayUnit === 'KG'
            ? formatQuantity(Number(item.goods.needPickQuantity) / 1000)
            : item.goods.needPickQuantity;
    const unit =
        item.goods.displayUnit === 'PIECE'
            ? services.language.getText('jian')
            : item.goods.displayUnit === 'KG'
            ? 'kg'
            : 'g';
    return quantity + '' + unit;
};

export class PickingOrderTicketTemplate extends PureComponent<
    {
        order: PickingLists;
    },
    any
> {
    render() {
        const order = this.props.order || {};
        const goods: PickingListCategoryItemGoods[] = [];
        forEach(order.categories, (category) => {
            forEach(category.items, (item) => {
                goods.push({
                    ...item,
                    name: category.name,
                });
            });
        });
        return (
            <div
                className="print-paper"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <div
                    style={{
                        textAlign: 'left',
                        marginTop: '10px',
                        marginBottom: '10px',
                    }}
                >
                    {services.language.getText('shl')}
                </div>
                <div
                    style={{
                        padding: '20px',
                        marginBottom: '20px',
                        textAlign: 'center',
                        borderBottom: '1px solid #000',
                    }}
                >
                    {order.subsite.name}
                </div>
                <img
                    style={{
                        width: '260px',
                        height: '90px',
                    }}
                    src={order.pickingListNoCode}
                    alt=""
                />
                <div>
                    {services.language.getText('jhdh')}：{order.pickingListNo}
                </div>
                <div>
                    {services.language.getText('jhdcjsj')}：{order.createTime}
                </div>
                <div
                    style={{
                        margin: '10px 0',
                        padding: '12px 0',
                        borderTop: '1px solid #000',
                        borderBottom: '1px dashed #000',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                    }}
                >
                    <span>{services.language.getText('pm')}</span>
                    <span>{services.language.getText('specifications')}</span>
                    <span>{services.language.getText('passageway')}</span>
                    <span>{services.language.getText('numberAndWeight')}</span>
                </div>
                {goods.map((item, index) => {
                    return (
                        <div
                            style={{
                                width: '100%',
                                marginBottom: '30px',
                            }}
                        >
                            <div style={{ width: '100%' }}>
                                {index + 1} {item.goods.name}
                            </div>
                            <div
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                }}
                            >
                                <span
                                    style={{
                                        width: '50%',
                                    }}
                                >
                                    {item.goods.styleName}
                                </span>
                                <span
                                    style={{
                                        width: '25%',
                                        textAlign: 'center',
                                    }}
                                >
                                    {item.goods.pickRoutes && item.goods.pickRoutes.passageway}
                                </span>
                                <span
                                    style={{
                                        width: '25%',
                                        textAlign: 'right',
                                    }}
                                >
                                    {getPickQuantity(item)}
                                </span>
                            </div>
                            <div style={{ margin: '5px 0' }}>
                                {services.language.getText('productCode')}：{item.goods.productCode}
                            </div>
                            <div style={{ margin: '5px 0' }}>
                                {services.language.getText('goodBarCode')}：{item.goods.barCode}
                            </div>
                        </div>
                    );
                })}
                <div
                    style={{
                        width: '100%',
                        height: '2px',
                        borderTop: '1px solid #000',
                    }}
                ></div>
            </div>
        );
    }
}
