import React, { Component } from 'react';
import { Radio } from 'antd';
import { RadioChangeEvent } from 'antd/lib/radio';
import { language } from '@comall-backend-builder/core/lib/services';

interface DeliverySceneValue {
    /**
     * 小程序订单是否为自动叫单
     */
    miniProgramOrderAutomatic: boolean;
    /**
     * 代客下单业务是否为自动叫单
     */
    shoppingListAutomatic: boolean;
}
interface DeliverySceneProps {
    onChange?: (value: DeliverySceneValue) => void;
    value: DeliverySceneValue;
}

export class DeliveryScene extends Component<DeliverySceneProps, any> {
    static defaultProps = {
        value: {
            miniProgramOrderAutomatic: true,
            shoppingListAutomatic: true,
        },
    };
    onChangeMiniProgramOrder = ({ target: { value: radioValue } }: RadioChangeEvent) => {
        const { value, onChange } = this.props;
        if (onChange) {
            onChange({
                ...value,
                miniProgramOrderAutomatic: radioValue,
            });
        }
    };

    onChangeShoppingList = ({ target: { value: radioValue } }: RadioChangeEvent) => {
        const { value, onChange } = this.props;
        if (onChange) {
            onChange({
                ...value,
                shoppingListAutomatic: radioValue,
            });
        }
    };

    render() {
        const { value } = this.props;
        return (
            <div className="discount-scene">
                <div className="discount-scene-label">{language.getText('xcxscywcjdd')}</div>
                <Radio.Group
                    value={value.miniProgramOrderAutomatic}
                    onChange={this.onChangeMiniProgramOrder}
                >
                    <Radio value={true}>{language.getText('zdjd')}</Radio>
                    <Radio value={false}>{language.getText('sdtd')}</Radio>
                </Radio.Group>
                <div className="discount-scene-label">{language.getText('dkxdywcjdd')}</div>
                <Radio.Group
                    value={value.shoppingListAutomatic}
                    onChange={this.onChangeShoppingList}
                >
                    <Radio value={true}>{language.getText('zdjd')}</Radio>
                    <Radio value={false}>{language.getText('sdtd')}</Radio>
                </Radio.Group>
            </div>
        );
    }
}
