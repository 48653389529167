import * as React from 'react';
import { Radio } from 'antd';
import { ObjectMode } from '@comall-backend-builder/types';
import { SingleImageUpload } from '../../../../components';

import './index.less';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

export class FloatingActionControlIconMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <FloatingActionControlIcon {...controlInfo} />;
    }
}

export interface FloatingActionButtonsIcon {
    custom: boolean;
    inactiveUrl?: string;
    activeUrl?: string;
}

interface FloatingActionControlIconProps {
    value?: FloatingActionButtonsIcon;
    onChange: (value: FloatingActionButtonsIcon) => void;
}

export const FloatingActionControlIcon: React.FC<FloatingActionControlIconProps> = ({
    value,
    onChange,
}) => {
    if (!value) {
        return <div></div>;
    }
    const { custom, inactiveUrl, activeUrl } = value;
    const onCustomChange = (e: any) => {
        onChange({ ...value, custom: e.target.value });
    };
    const onInactiveUrlChange = (inactiveUrl: any, name: string) => {
        onChange({ ...value, inactiveUrl });
        return {};
    };
    const onActiveUrlChange = (activeUrl: any, name: string) => {
        onChange({ ...value, activeUrl });
        return {};
    };

    return (
        <div className="floating-action-control-icon">
            <Radio.Group value={custom} onChange={onCustomChange}>
                <Radio value={false}>{language.getText('mrtb')}</Radio>
                <Radio value={true}>{services.language.getText('zdytb')}</Radio>
            </Radio.Group>
            <div hidden={!custom} className="floating-action-control-icon__content">
                <CustomIcon
                    value={inactiveUrl}
                    onChange={onInactiveUrlChange}
                    desc={language.getText('kqq')}
                />
                <CustomIcon
                    value={activeUrl}
                    onChange={onActiveUrlChange}
                    desc={language.getText('kqh')}
                />
                <span className="floating-action-control-icon__tips">
                    {language.getText('h_jycc64')}
                </span>
            </div>
        </div>
    );
};

const CustomIcon = (props: {
    value: string | undefined;
    onChange: (value: any, name: string) => {};
    desc: string;
}) => {
    const { value, onChange, desc } = props;
    return (
        <div className="floating-action-control-icon__upload">
            <SingleImageUpload value={value} onChange={onChange} />
            {desc}
        </div>
    );
};
