import React, { PureComponent, createElement } from 'react';
import { defaultsDeep, set, assign, get } from 'lodash';
import { Modal, message as AntMessage } from 'antd';
import { builder, actions, services } from '@comall-backend-builder/core';
import { ComponentFactory } from '@comall-backend-builder/core/lib/parser/component';
const language = services.language;

let SELECTED_ROW: any = [];

const rowSelectionType: any = 'checkout';

const getConfig = (type: 'Single' | 'Multi', componentName: string) => {
    return {
        component: 'Card',
        entity: 'DesignPlatformGoodsSelector',
        content: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'FilterForm',
                    componentId: componentName + '-FilterForm',
                    direction: 'inline',
                    fields: [
                        {
                            property: 'subsiteName',
                            controlConfig: {
                                style: { width: 214 },
                            },
                        },
                        {
                            property: 'merchantName',
                            controlConfig: {
                                style: { width: 200 },
                            },
                        },
                        {
                            property: 'name',
                            controlConfig: {
                                style: { width: 200 },
                            },
                        },

                        {
                            property: 'codes',
                            controlConfig: {
                                style: { width: 200 },
                            },
                        },
                    ],
                    submit: {
                        icon: 'search',
                        text: '',
                    },
                    style: {
                        marginBottom: 16,
                        float: 'right',
                    },
                },
                {
                    component: 'DataTable',
                    componentId: componentName + '-table',
                    loadFirstPage: true,
                    rowSelection: {
                        type: rowSelectionType,
                        onChange: () => {},
                        onSelect: function() {
                            SELECTED_ROW = arguments[2];
                        },
                    },
                    pagination: {
                        pageSize: 10,
                    },
                    columns: [
                        {
                            property: 'name',
                        },
                    ],
                },
            ],
        },
    };
};

function initDynamicComponent(type: any, contentConfig: any, componentName: any) {
    let config = defaultsDeep(contentConfig, getConfig(type, componentName));
    set(config, 'content.items[1].componentId', componentName + '-table');
    set(config, 'content.items[1].rowSelection', {
        type: rowSelectionType,
        onChange: () => {},
        onSelect: function() {
            SELECTED_ROW = arguments[2];
        },
        onSelectAll: function() {
            SELECTED_ROW = arguments[2];
        },
    });
    return ComponentFactory(componentName, config);
}

function generateDynamicComponentName(type: string) {
    return (
        'DesignPlatformGoodsSelector-' +
        type +
        '-' +
        parseInt(Math.random() * 1e8 + '').toString(16)
    );
}

/**
 * 可视化-配置链接-选择商品(点选、多选)
 */

export class SelectPlatformGoods extends PureComponent<{
    contentConfig?: Record<string, any>;
    modalConfig?: Record<string, any>;
    type: 'Single' | 'Multi';
    showModal: boolean;
    onChange: (data: any) => void;
    onCancel: () => void;
}> {
    componentName = '';
    renderComponent: any = null;
    dispatch: any = null;

    constructor(props: any) {
        super(props);
        const { type, contentConfig } = props;
        this.componentName = generateDynamicComponentName(type);
        let dynamicComponent = initDynamicComponent(type, contentConfig, this.componentName);
        this.renderComponent = createElement(dynamicComponent);
        this.dispatch = builder.getStore().dispatch;
    }

    onOk = () => {
        if (!SELECTED_ROW.length) {
            AntMessage.error(language.getText('components.Tools.productSelectRequired'));
            return;
        }

        const { onChange, type } = this.props;
        if (type === 'Single') {
            let linkParams: any = {};
            if (SELECTED_ROW.length) {
                const row = SELECTED_ROW[0];
                const goods = row.goods ? row.goods : row.goodsMvo;
                linkParams = {
                    id: get(row, 'id', null),
                    name: get(row, 'name', null),
                };
                if (goods && goods.id) {
                    linkParams['goodsId'] = goods.id;
                }
            }
            onChange(linkParams);
        } else {
            onChange(SELECTED_ROW);
        }
        SELECTED_ROW = [];
        this.dispatch(actions.tableRowSelectionChangeAction(this.componentName + '-table', []));
    };

    onCancel = () => {
        const { onCancel } = this.props;
        onCancel();
    };

    render() {
        const { showModal, modalConfig = {} } = this.props;
        const props = {};
        assign(
            props,
            {
                width: 800,
                visible: showModal,
                title: '选择商品',
                onCancel: this.onCancel,
                onOk: this.onOk,
            },
            modalConfig
        );
        return <Modal {...props}>{this.renderComponent}</Modal>;
    }
}
