import React, { PureComponent } from 'react';
import { find, cloneDeep, remove } from 'lodash';
import update from 'immutability-helper';
import { services } from '@comall-backend-builder/core';
import { Table, Button, Input, Tooltip, Icon, Popconfirm } from 'antd';
import { CardLevelBenefitModal } from './card-level-benefit-modal';
import CardLevelUploadButton from './card-level-upload-button';
import CardLevelAddModal from './card-level-add-modal';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const { api } = services;

export interface TableDataItem {
    id?: string;
    /**
     * 等级编码
     */
    levelCode: string;
    levelId: string;
    level: number;
    levelName: string;
    isDefault: boolean;
    /**
     * 等级权益
     */
    benefitData?: BenefitData;
    styleIconUrl: string;
    styleBackgroundPictureUrl: string;
}

interface BenefitData {
    benefitInfos: {
        title: string;
        iconUrl: string;
        description: string;
    }[];
    description: string;
}

export class CardLevelItems extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            levelsList: [],
        };
    }

    componentWillReceiveProps(nextProps: any) {
        const { value: nextValue } = nextProps;
        const { value } = this.props;
        if (!value && nextValue) {
            this.props.onChange(nextValue);
        }
    }

    componentDidMount() {
        this.loadData();
    }

    private loadData = async () => {
        const { isEdit } = this.props;
        const res: any[] = await api
            .get(
                {
                    scenario: 'TRADE',
                    status: 'ENABLE',
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                    apiPath: '/admin/levels',
                }
            )
            .then((res: any) => {
                return res.map((i: any) => {
                    i.id = i.id.toString();
                    return i;
                });
            });
        if (!isEdit) {
            const dataSource = res.map(this.transformData);
            this.props.onChange(dataSource);
        }
        this.setState({
            levelsList: res,
        });
    };

    private transformData = (item: any): TableDataItem => {
        return {
            levelCode: item.code,
            levelId: item.id,
            level: item.level,
            levelName: item.name,
            isDefault: item.isDefault,
            benefitData: undefined,
            styleIconUrl: '',
            styleBackgroundPictureUrl: '',
        };
    };

    private changeLevelName = (row: any, name: string) => {
        const { value, onChange } = this.props;
        const newData = cloneDeep(value || []);
        const currentData = find(newData, { levelId: row.levelId });
        if (currentData) {
            currentData.levelName = name;
            onChange(newData);
        }
    };

    private onChangeBenefit = (row: any, data: any) => {
        const { value, onChange } = this.props;
        const newData = cloneDeep(value || []);
        const currentData = find(newData, { levelId: row.levelId });
        if (currentData) {
            currentData.benefitData = data;
            onChange(newData);
        }
    };

    private onChangeImg = (row: any, key: string, url: string) => {
        const { value, onChange } = this.props;
        const newData = cloneDeep(value || []);
        const currentData = find(newData, { levelId: row.levelId });
        if (currentData) {
            currentData[key] = url;
            onChange(newData);
        }
    };

    private onDelete = (id: string) => {
        const { value, onChange } = this.props;
        const newData = cloneDeep(value || []);
        remove(newData, { levelId: id });
        setTimeout(() => {
            onChange(newData);
        }, 10);
    };

    private onChangeSequence = (index: number, sequence: 'ASCEND' | 'DESCEND') => {
        const { value, onChange } = this.props;
        const newData = cloneDeep(value || []);
        if (
            (sequence === 'ASCEND' && index === 0) ||
            (sequence === 'DESCEND' && index === value.length - 1)
        ) {
            return;
        }
        const currentValue = value[index];
        const targetIndex = sequence === 'ASCEND' ? index - 1 : index + 1;
        const newDataSource = update(newData, {
            $splice: [
                [index, 1],
                [targetIndex, 0, currentValue],
            ],
        });
        onChange(newDataSource);
    };

    private getTableProps = () => {
        const { value, isSubsite } = this.props;
        const columns = [
            {
                title() {
                    return (
                        <span>
                            <span className={`${prefix}__red`}>*</span>
                            <span>{language.getText('djbm')}</span>
                            <Tooltip
                                title={language.getText('jsycrmdjsdjbmw')}
                                className={`${prefix}__icon`}
                            >
                                <Icon type="question-circle" />
                            </Tooltip>
                        </span>
                    );
                },
                width: '150px',
                align: 'center' as const,
                dataIndex: 'levelCode',
                key: 'levelCode',
            },
            {
                title() {
                    return (
                        <span>
                            <span className={`${prefix}__red`}>*</span>
                            <span>{language.getText('djmc')}</span>
                            <Tooltip
                                title={services.language.getText('ddjmc')}
                                className={`${prefix}__icon`}
                            >
                                <Icon type="question-circle" />
                            </Tooltip>
                        </span>
                    );
                },
                width: '150px',
                align: 'center' as const,
                dataIndex: 'levelName',
                key: 'levelName',
                render: (value: string, row: any) => {
                    return (
                        <div>
                            <Input
                                value={value}
                                placeholder={services.language.getText('inputPlease')}
                                maxLength={20}
                                onChange={(e: any) => this.changeLevelName(row, e.target.value)}
                            />
                        </div>
                    );
                },
            },
            {
                title: language.getText('djqy'),
                width: '200px',
                align: 'center' as const,
                dataIndex: 'benefitData',
                key: 'benefitData',
                render: (value: any, row: any) => {
                    return (
                        <CardLevelBenefitModal
                            key={`benefitData-${row.levelId}`}
                            value={value}
                            onChangeBenefit={this.onChangeBenefit.bind(this, row)}
                        />
                    );
                },
            },
            {
                title() {
                    return (
                        <span>
                            <span className={`${prefix}__red`}>*</span>
                            <span>{language.getText('djtb')}</span>
                        </span>
                    );
                },
                dataIndex: 'styleIconUrl',
                key: 'styleIconUrl',
                width: '200px',
                align: 'center' as const,
                render: (value: any, row: any) => {
                    return (
                        <CardLevelUploadButton
                            value={value}
                            style={{ height: '30px' }}
                            onChange={this.onChangeImg.bind(this, row, 'styleIconUrl')}
                        />
                    );
                },
            },
            {
                title() {
                    return (
                        <span>
                            <span className={`${prefix}__red`}>*</span>
                            <span>{language.getText('djkm')}</span>
                        </span>
                    );
                },
                dataIndex: 'styleBackgroundPictureUrl',
                key: 'styleBackgroundPictureUrl',
                width: '200px',
                align: 'center' as const,
                render: (value: any, row: any) => {
                    return (
                        <CardLevelUploadButton
                            value={value}
                            style={{ width: '100px' }}
                            onChange={this.onChangeImg.bind(this, row, 'styleBackgroundPictureUrl')}
                        />
                    );
                },
            },
            {
                title() {
                    return (
                        <span>
                            <span>{language.getText('mrdj')}</span>
                            <Tooltip
                                title={language.getText('yhzcwchmrjydhydj')}
                                className={`${prefix}__icon`}
                            >
                                <Icon type="question-circle" />
                            </Tooltip>
                        </span>
                    );
                },
                width: '130px',
                align: 'center' as const,
                dataIndex: 'isDefault',
                render: (isDefault: boolean) => {
                    return (
                        <span>{isDefault ? language.getText('yes') : language.getText('no')}</span>
                    );
                },
            },
            {
                title: services.language.getText('common.tableAction'),
                dataIndex: 'levelId',
                key: 'levelId',
                align: 'center' as const,
                render: (id: string, row: any, index: number) => {
                    return (
                        <div>
                            <Button
                                type="link"
                                disabled={index === 0}
                                onClick={this.onChangeSequence.bind(this, index, 'ASCEND')}
                            >
                                {language.getText('sd')}
                            </Button>
                            <Button
                                type="link"
                                disabled={index === value.length - 1}
                                onClick={this.onChangeSequence.bind(this, index, 'DESCEND')}
                            >
                                {language.getText('jxt')}
                            </Button>
                            <Popconfirm
                                disabled={row.isDefault}
                                onConfirm={this.onDelete.bind(this, id)}
                                title={`${language.getText('sfsc')}${row.levelName ||
                                    language.getText('gdjsz')}`}
                                okText={services.language.getText('common.ok')}
                                cancelText={services.language.getText('common.cancel')}
                            >
                                <Button type="link" disabled={row.isDefault}>
                                    {services.language.getText('common.delete')}
                                </Button>
                            </Popconfirm>
                        </div>
                    );
                },
            },
        ];
        if (isSubsite) {
            columns.pop();
        }
        return {
            dataSource: value,
            pagination: false as const,
            scroll: {
                y: 400,
                x: true,
            },
            rowKey: 'id',
            columns,
        };
    };

    private onChangeLevelList = (ids: number[]) => {
        const { value, onChange } = this.props;
        const { levelsList } = this.state;
        const newData = cloneDeep(value || []);
        ids.forEach((id: number) => {
            if (!find(value, { levelId: id })) {
                const curData = find(levelsList, { id: id });
                newData.push(this.transformData(curData));
            }
        });
        onChange(newData);
    };

    render() {
        const { value, isSubsite } = this.props;
        const { levelsList } = this.state;
        const tableProps = this.getTableProps();
        return (
            <div className={prefix}>
                <Table {...tableProps}></Table>
                {isSubsite ? null : (
                    <CardLevelAddModal
                        currentData={value}
                        datasource={levelsList}
                        onChange={this.onChangeLevelList}
                    />
                )}
                <div className="ant-form-extra">{language.getText('levelCardLimit')}</div>
                <div className="ant-form-extra">{language.getText('levelIconLimit')}</div>
            </div>
        );
    }
}

const prefix = 'card-level-items-modal';
