import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
export const config: Config = {
    components: {
        StoreSearchPageView: {
            component: 'Viewport',
            entity: 'StoreSearchPageEntity',
        },
        StoreSearchPageDesign: {
            component: 'Card',
            content: {
                component: 'PageDesign',
                client: ENV.DESIGN_CLIENT_URL,
                apiPath: '/admin/pages/:type/:id',
                type: 'STORE_SEARCH_PAGE',
            },
        },
        StoreSearchPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'GridLayout',
                    items: [
                        {
                            component: 'FilterForm',
                            direction: 'inline',
                            submit: {
                                text: '<<common.search>>',
                                style: {
                                    marginRight: 16,
                                },
                            },
                            reset: {
                                text: '<<common.reset>>',
                            },
                            fields: [
                                {
                                    property: 'name',
                                },
                                {
                                    property: 'status',
                                    controlConfig: {
                                        style: { width: 180 },
                                    },
                                },
                            ],
                        },
                        {
                            component: 'Button',
                            text: '<<createSearchPage>>',
                            type: 'primary',
                            icon: 'plus',
                            span: 4,
                            route: '/store-search-page/add',
                            className: 'mt24 mb24',
                        },
                    ],
                },

                {
                    component: 'StoreSearchPageTable',
                },
            ],
        },
        StoreSearchPageTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'groupSubsiteName',
                },
                // {
                //     property: 'type',
                // },
                {
                    property: 'pageName',
                    width: 180,
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'lastModifyTime',
                },
                {
                    property: 'enabled',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 230,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            type: 'link',
                            statusKey: 'enabled',
                            renderComponent: 'Button',
                            style: { display: 'inline-flex' },
                            apiRoot: ENV.API_ROOT.replace('/WEB-API', '/WEB-API'),
                            handles: [
                                {
                                    value: false,
                                    apiPath: '/admin/pages/update/enabled',
                                    params: {
                                        enabled: 'enabled',
                                    },
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                                {
                                    value: true,
                                    apiPath: '/admin/pages/update/enabled',
                                    params: {
                                        enabled: 'disable',
                                    },
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<components.PageManage.setting>>',
                        path: '/store-search-page/edit/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'CustomLink',
                        text: '<<components.PageManage.decorate>>',
                        config: {
                            url: `/design-editor/?type=STORE_SEARCH_PAGE&id={{row.id}}`,
                            target: '_blank',
                        },
                    },
                    {
                        type: 'component',
                        component: 'PageNewPreviewButton',
                        config: {
                            type: 'storeSearchPage',
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.log>>',
                        path: '/store-search-page/log/{{row.id}}/DESIGN_PAGE',
                    },
                    {
                        type: 'delete',
                        text: '<<components.PageManage.delete>>',
                        statusKey: 'row.canDelete',
                        config: {
                            type: 'link',
                            tooltip: null,
                            title: '<<confirmDelete>>',
                            okText: '<<common.ok>>',
                            cancelText: '<<common.cancel>>',
                        },
                    },
                ],
            },
        },
        StoreSearchPageAdd: {
            component: 'Card',
            title: '<<components.StorePage.createPage>>',
            content: {
                component: 'StoreSearchPageAddForm',
            },
        },
        StoreSearchPageAddForm: {
            component: 'CreateForm',
            entity: 'StoreSearchPageEntity',
            fields: [
                {
                    property: 'name',
                },
                { property: 'groupSubsiteName' },
                { property: 'subsites' },
            ],
            labelCol: 4,
            controlCol: 20,
            submit: { text: '<<common.submit>>' },
            submitSuccessBehavior: {
                route: '/store-search-page',
            },
            footer: {
                items: [
                    {
                        text: '<<common.cancel>>',
                        htmlType: 'button',
                        type: 'default',
                        route: 'goBack',
                        style: { marginLeft: 16 },
                    },
                ],
            },
        },
        StoreSearchPageEdit: {
            component: 'Card',
            title: '<<components.StorePage.setPage>>',
            content: {
                component: 'StoreSearchPageEditForm',
            },
        },
        StoreSearchPageEditForm: {
            component: 'ModifyForm',
            entity: 'StoreSearchPageEntity',
            fields: [
                {
                    property: 'name',
                },
                { property: 'groupSubsiteName' },
                { property: 'subsites' },
            ],
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            submit: { text: '<<common.save>>' },
            submitSuccessBehavior: {
                route: '/store-search-page',
            },
            footer: {
                items: [
                    {
                        text: '<<common.cancel>>',
                        htmlType: 'button',
                        type: 'default',
                        route: 'goBack',
                        style: { marginLeft: 16 },
                    },
                ],
            },
        },
        StoreSearchLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'OperationLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-EVENT-TRACKING`,
                    apiPath: '/admin/operation_logs/v2/page',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/store-search-page',
                    component: 'StoreSearchPageView',
                    breadcrumbName: '<<storeSearchPage>>',
                    privilege: {
                        path: 'storeSearchPage',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/design/:id',
                            component: 'StoreSearchPageDesign',
                        },
                        {
                            path: '/add',
                            component: 'StoreSearchPageAdd',
                        },
                        {
                            path: '/edit/:id',
                            component: 'StoreSearchPageEdit',
                        },
                        {
                            path: '/log/:business_id/:business_type',
                            component: 'StoreSearchLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        {
                            path: '/',
                            component: 'StoreSearchPage',
                        },
                    ],
                },
            ],
        },
    ],
};
