import * as React from 'react';
import { Tag, Popover } from 'antd';
import { modes } from '@comall-backend-builder/types';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
/**
 * 门店分组查看
 */
export class GroupSubsiteMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        const {
            row: { subsites },
        } = displayInfo;

        return (
            <div>
                {value}{' '}
                {subsites && (
                    <Popover
                        placement={'right'}
                        content={
                            <div style={{ maxWidth: 400 }}>
                                {subsites.map((i: any) => (
                                    <Tag key={i.id} style={{ marginBottom: 10 }}>
                                        {i.name}
                                    </Tag>
                                ))}
                            </div>
                        }
                    >
                        <span className="group-tips">
                            {language.getText('inAll')}
                            {subsites.length}
                            {language.getText('muchSubsites')}
                        </span>
                    </Popover>
                )}
            </div>
        );
    }
}
