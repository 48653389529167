import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { remove, get, map, forEach, isArray } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Modal } from 'antd';

const api = services.api;

const TableComponentId = 'CGBProductSelectorDataTable';
let Entity: any = {};
let entity: any = {};

/**
 * 选择虚拟商品
 */
class virtualProductSelector extends PureComponent<{
    onChange: (products: any[]) => void;
    params?: any;
    buttonText?: string;
    requestStatus: string;
    rowSelectionType?: string;
}> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('CustomDesignVirtualProductSelector');
        entity = new Entity({});
    }
    dispatch: any = {};
    state = { visible: false };

    selectedRows: any[] = [];

    onTableRowSelect = (record: any, selected: boolean) => {
        const { rowSelectionType } = this.props;
        if (rowSelectionType === 'radio') {
            this.selectedRows = [record];
        } else {
            if (selected) {
                this.selectedRows.push(record);
            } else {
                remove(this.selectedRows, (i) => {
                    return i.id === record.id;
                });
            }
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: any[]) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            this.selectedRows = [];
        }
    };
    componentWillReceiveProps(nextProps: any) {
        if (nextProps.requestStatus === 'success' && this.props.requestStatus === 'pending') {
            const selectedRows = this.selectedRows;
            if (selectedRows && selectedRows.length > 0)
                setTimeout(() => {
                    this.dispatch(
                        actions.tableRowSelectionChangeAction(
                            TableComponentId,
                            map(selectedRows, 'id')
                        )
                    );
                }, 300);
        }
    }

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(entity));
    }

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
    };

    onOk = () => {
        const { onChange } = this.props;
        if (onChange) {
            forEach(this.selectedRows, (product) => {
                product.select = true;
                product.couponType = 'PACKAGE';
            });

            if (
                isArray(this.selectedRows) &&
                this.selectedRows.length > 0 &&
                this.selectedRows[0].virtualProductCouponDefinitionType === 'COUPON_PACKAGE'
            ) {
                const apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
                const apiPath = `/admin/coupon_package_rules/${this.selectedRows[0].virtualProductCouponDefinitionId}`;
                api.get({}, { apiRoot, apiPath }).then((res: any) => {
                    this.selectedRows[0].apportionPrices = res.ruleItems.map((coupon: any) => {
                        coupon = {
                            couponDefinitionId: coupon.couponRule.id,
                            couponDefinitionName: coupon.couponRule.name,
                            couponTypeDesc: coupon.couponRule.typeName,
                            quantity: coupon.quantity,
                            stock: 0,
                        };
                        return coupon;
                    });
                    onChange(this.selectedRows);
                    this.toggleModal();
                });
            } else {
                onChange(this.selectedRows);
                this.toggleModal();
            }
        }
    };

    getConfig = () => {
        let { params, rowSelectionType } = this.props;
        if (!rowSelectionType) {
            rowSelectionType = 'checkbox';
        }
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: params || {},
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'right' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        entity.pageChange(Object.assign({}, params, entity.paging, { page: 1 }));
                        entity.search(params);
                    },
                    fields: [
                        {
                            property: 'name',
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,

                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    rowSelection: {
                        type: rowSelectionType,
                        onSelect: (record: any, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: any) => {
                            this.onTableRowSelectAll(selected, rows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    columns: [
                        { property: 'goodsMvo.productName' },
                        { property: 'goodsMvo.name' },
                        { property: 'goodsMvo.subsiteName' },
                        { property: 'goodsMvo.merchantName' },
                        { property: 'goodsMvo.spuCode' },
                        { property: 'goodsMvo.csku' },
                    ],
                },
            ],
        };
    };

    toggleModal = () => {
        const { visible } = this.state;
        const { params } = this.props;
        this.setState({ visible: !visible }, () => {
            if (!visible && entity.paging) {
                entity.search({
                    ...entity.paging,
                    ...params,
                });
                this.onClearAll();
            }
        });
    };

    render() {
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 900,
            title: '请选择虚拟商品',
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };
        const { buttonText = '选择虚拟商品' } = this.props;
        return (
            <div style={{ display: 'inline-block' }}>
                <Button type={'primary'} onClick={this.toggleModal}>
                    {buttonText}
                </Button>
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}

export const VirtualProductSelector = connect((_state: any) => {
    let requestStatus = get(entity, 'requestStatus', null);
    const result = { requestStatus };
    return result;
})(virtualProductSelector);
