import React, { PureComponent } from 'react';
import { GiftSubsiteSelected, SubsiteSelector } from '../../components';

export interface GiftSubsite {
    address: string;
    id: string;
    name: string;
    status: number;
    hasLeaders: boolean;
    /**
     * 被选中的分站
     */
    select?: boolean;
    /**
     * 将要被删除的分站
     */
    delete?: boolean;
    type: 'radio' | undefined;
}

export class GiftConfigSelectSubsite extends PureComponent<
    any,
    {
        value: Array<GiftSubsite>;
    }
> {
    state = { value: new Array<GiftSubsite>() };

    /**
     * 选择分站
     * @param selectValues
     */
    onChange = (selectValues: GiftSubsite[]) => {
        const { onChange, canBeNull } = this.props;
        if (onChange) {
            if (selectValues && selectValues.length === 0) {
                onChange(undefined);
                if (canBeNull) {
                    onChange([]);
                }
            } else {
                onChange(selectValues);
            }
        }
    };

    /**
     * 添加分站弹窗确认后回调
     * @param selectValues 选中分站
     */
    onSelect = (selectValues: GiftSubsite[]) => {
        const { name, onChange } = this.props;
        if (onChange) {
            onChange(selectValues, name);
        }
    };

    render() {
        const { value, disabled, type } = this.props;
        return (
            <div>
                {!disabled && (
                    <SubsiteSelector type={type} onChange={this.onSelect} selectValues={value} />
                )}
                <GiftSubsiteSelected
                    data={value}
                    onRemove={this.onChange}
                    onChange={this.onChange}
                    disabled={disabled}
                ></GiftSubsiteSelected>
            </div>
        );
    }
}
