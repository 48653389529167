import React, { PureComponent } from 'react';
import { Input, Button } from 'antd';
import { uniqueId, cloneDeep, set, remove } from 'lodash';
import DragTable from '../../../../components/drag-table';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

interface WifiEquipmentItem {
    id: string;
    name: string;
    password: string;
}

export class ArrayWifiEquipment extends PureComponent<any> {
    private onChange = (data: string = '', id: string, fields: keyof WifiEquipmentItem) => {
        const { value, onChange } = this.props;
        const newValues: WifiEquipmentItem[] = cloneDeep(value);
        const row = newValues.find((item) => item.id === id);
        if (!row) return;
        if (fields === 'name') {
            const name = data.slice(0, 32);
            set(row, fields, name);
        } else if (fields === 'password') {
            const password = data.slice(0, 16);
            set(row, fields, password);
        }
        onChange && onChange(newValues);
    };

    getTableConfig = () => {
        let columns = [
            {
                title: language.getText('wifiNameSSID'),
                dataIndex: 'name',
                key: 'name',
                width: 200,
                align: 'center' as const,
                render: (value: string, record: WifiEquipmentItem) => {
                    return (
                        <Input
                            style={{ width: 150 }}
                            value={value}
                            maxLength={32}
                            onChange={(e) => this.onChange(e.target.value, record.id, 'name')}
                        />
                    );
                },
            },
            {
                title: language.getText('wifiPassword'),
                dataIndex: 'password',
                key: 'password',
                width: 200,
                align: 'center' as const,
                render: (value: string, record: WifiEquipmentItem) => {
                    return (
                        <Input
                            style={{ width: 150 }}
                            value={value}
                            maxLength={16}
                            onChange={(e) => this.onChange(e.target.value, record.id, 'password')}
                        />
                    );
                },
            },
            {
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                dataIndex: 'id',
                key: 'id',
                width: 180,
                align: 'center' as const,
                render: (id: string) => {
                    return (
                        <Button type="link" onClick={this.onRemove.bind(this, id)}>
                            {services.language.getText('common.delete')}
                        </Button>
                    );
                },
            },
        ];

        let config: any = {
            rowKey: 'id',
            scroll: {
                y: 400,
            },
            columns: columns,
        };

        return config;
    };

    private onIndexChange = (data: any) => {
        const { onChange } = this.props;
        onChange && onChange(data);
    };

    private onRemove = (id: string) => {
        const { onChange, value } = this.props;
        const newValue = cloneDeep(value);
        remove(newValue, (item: WifiEquipmentItem) => item.id === id);
        onChange && onChange(newValue);
    };

    private onAddWifi = () => {
        const { value, onChange } = this.props;
        const newValue = cloneDeep(value);
        newValue.push({
            id: uniqueId('temp_'),
            name: '',
            password: '',
        });
        onChange && onChange(newValue);
    };
    render() {
        const { value } = this.props;
        let tableConfig = this.getTableConfig();
        const tableProps = { ...tableConfig, dataSource: value };
        return (
            <div style={{ width: 600 }}>
                <DragTable {...tableProps} onUpdateData={this.onIndexChange} pagination={false} />
                {value && value.length < 10 ? (
                    <Button type="default" onClick={this.onAddWifi}>
                        {language.getText('tjsb')}
                    </Button>
                ) : null}
            </div>
        );
    }
}
