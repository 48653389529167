import { builder } from '@comall-backend-builder/core';
import { CashCouponRulesLoader } from './cash-coupon-rules-loader';
import { FreightCouponRulesLoader } from './freight-coupon-rules-loader';
import { GiftCouponRulesLoader } from './gift-coupon-rules-loader';
import { ParkingCouponRulesLoader } from './parking-coupon-rules-loader';
import { CouponPackageRulesLoader } from './coupon-package-rules-loader';
import { CouponRulesDetailReportLoader } from './coupon-rules-detail-report-loader';
import { GivingCouponSchedulesLoader } from './giving-coupon-schedules-lodader';
import { couponConsumeHistoryLoader } from './coupon-consume-history-loader';
import { couponSaleActivityLoader } from './coupon-sale-activity-loader';
import { CouponSaleCategoriesLoader } from './coupon-sale-categories-loader';
import { LotteryCouponRulesLoader } from './lottery-coupon-rules-loader';
import { BalanceCouponRulesLoader } from './balance-coupon-rules-loader';
import { CouponManageLoader } from './coupon-manage-loader';
import { CouponChannelStocksLoader } from './coupon-channel-stocks-loader';
import { EfutureCouponRulesLoader } from './efuture-coupon-rules-loader';
import { CouponTransferCoverLoader } from './coupon-transfer-cover-loader';
import { ExternalCouponRulesLoader } from './external-coupon-rules-loader';
import { BfplatCouponRulesLoader } from './bfplat-coupon-rules-loader';
import { CouponTransferRecordLoader } from './coupon-transfer-record-loader';

builder.registerLoader('/admin/cash_coupon_rules', CashCouponRulesLoader);
builder.registerLoader('/admin/freight_coupon_rules', FreightCouponRulesLoader);
builder.registerLoader('/admin/gift_coupon_rules', GiftCouponRulesLoader);
builder.registerLoader('/admin/parking_coupon_rules', ParkingCouponRulesLoader);
builder.registerLoader('/admin/coupon_package_rules', CouponPackageRulesLoader);
builder.registerLoader('/admin/coupon_rules_details_report', CouponRulesDetailReportLoader);
builder.registerLoader('/admin/giving_coupon_schedules', GivingCouponSchedulesLoader);
builder.registerLoader('/admin/coupon_consume_history', couponConsumeHistoryLoader);
builder.registerLoader('/admin/coupon_sale_activity', couponSaleActivityLoader);
builder.registerLoader('/admin/coupon_sale_categories', CouponSaleCategoriesLoader);
builder.registerLoader('/admin/lottery_coupon_rules', LotteryCouponRulesLoader);
builder.registerLoader('/admin/balance_coupon_rules', BalanceCouponRulesLoader);
builder.registerLoader('/admin/coupon_manage', CouponManageLoader);
builder.registerLoader('/admin/coupon_channel_stocks_statistics', CouponChannelStocksLoader);
builder.registerLoader('/admin/efuture_coupon_rules', EfutureCouponRulesLoader);
builder.registerLoader('/admin/coupon_transfer_cover', CouponTransferCoverLoader);
builder.registerLoader('/admin/external_coupon_rules', ExternalCouponRulesLoader);
builder.registerLoader('/admin/bfplat_coupon_rules', BfplatCouponRulesLoader);
builder.registerLoader('/admin/coupon_transfer_record', CouponTransferRecordLoader);
