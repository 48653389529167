import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { api, errorHandle } from '@comall-backend-builder/core/lib/services';

export const config: Config = {
    entities: {
        SlotMachinesEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/slotMachines',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsryhqlbmc>>' },
                },
                subSiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<sxmd_1>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                editExecuteStatus: {
                    type: 'string',
                },
                isOpen: {
                    type: 'boolean',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                couponRuleCode: {
                    type: 'number',
                    displayName: '<<hdbh>>',
                },
                subSites: {
                    type: 'array.popover',
                    displayName: '<<sxmd_1>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherBaseInfo>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [{ required: true, whitespace: true, message: '<<qsrhdmc>>' }],
                            controlConfig: {
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subsiteIds: {
                            type: 'array.options.autoComplete',
                            className: 'custom-array-options',
                            displayName: '<<sxmd_1>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<qxzsxmd>>',
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        couponRuleCode: {
                            type: 'string',
                            displayName: '<<hdbh>>',
                            rules: [{ required: true, whitespace: true, message: '<<qsrhdbh>>' }],
                            controlConfig: {
                                placeholder: '<<activityNoRule>>',
                                maxLength: 30,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],
                            controlConfig: {
                                subsiteField: 'baseInfo.subsiteIds',
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.baseInfo?.subsiteIds?.length &&
                                        row.baseInfo.subsiteIds
                                            .map((item: any) => item.id)
                                            .join(',');
                                    return { subsiteIds };
                                },
                            },
                        },
                    },
                },
                pictureInfo: {
                    type: 'object.subForm',
                    displayName: '<<pageDecoration>>',
                    properties: {
                        downloadSample: {
                            type: 'string.entityButton',
                            displayName: '<<ckzxyl>>',
                            controlConfig: {
                                props: {
                                    type: 'primary',
                                    children: '<<ckzxyl>>',
                                    onClick() {
                                        api.download(
                                            {},
                                            {
                                                apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                                apiPath:
                                                    '/admin/slot_machine_activities/example_files',
                                                customHeaders: {
                                                    'content-disposition':
                                                        'attachment;filename="filename.zip"',
                                                },
                                            }
                                        ).catch(errorHandle);
                                    },
                                },
                            },
                        },
                        backgroundPicture: {
                            displayName: '<<hdbjt>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<iconPictureExtra>>',
                        },
                        soltMachinePicture: {
                            displayName: '<<lhjjs>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<soltMachinePictureExtra>>',
                        },
                        singleButtonPicture: {
                            displayName: '<<dccjan>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<singleButtonPictureExtra>>',
                        },
                        moreButtonPicture: {
                            displayName: '<<dccjan_1>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<moreButtonPictureExtra>>',
                        },
                        ruleDesc: {
                            type: 'string.richTextPlus',
                            displayName: '<<activityRule>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                        },
                    },
                },
                prizeInfo: {
                    type: 'object.subForm',
                    displayName: '<<jpsz>>',
                    properties: {
                        assetRewards: {
                            type: 'array.rewardSetting',
                            displayName: '<<jpsz>>',
                            extra: '<<hdwkss>>',
                            controlConfig: {
                                eventType: 'SLOT_MACHINE',
                            },
                        },
                        finishWithNoPrize: {
                            type: 'array.optionIds.checkbox',
                            displayName: '<<byxyhjxcj>>',
                            defaultValue: [],
                            options: [
                                {
                                    name: '<<djpzsx>>',
                                    id: 'true',
                                },
                            ],
                            extra: '<<yhzccjhtsyh>>',
                        },
                    },
                },
            },
        },
        SlotMachinesReportEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/slotMachinesReport',
            filters: {
                mobile: {
                    type: 'string.phone',
                    displayName: '<<sjhm>>：',
                    defaultValue: undefined,
                    controlConfig: {
                        placeholder: '<<qsrsjh>>',
                    },
                },
                nickName: {
                    type: 'string',
                    displayName: '<<nickName>>：',
                    defaultValue: undefined,
                    controlConfig: {
                        placeholder: '<<qsrhync>>',
                    },
                },
                lotteryCouponCode: {
                    type: 'string',
                    displayName: '<<cjqh>>',
                    defaultValue: undefined,
                    controlConfig: {
                        placeholder: '<<qsrcjqh>>',
                    },
                },
            },
            properties: {
                couponId: {
                    type: 'string',
                },
                couponName: {
                    type: 'string',
                    displayName: '<<cjqmc>>',
                },
                couponNo: {
                    type: 'string',
                    displayName: '<<couponNum>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<sjhm>>',
                },
                nickName: {
                    type: 'string',
                    displayName: '<<nickName>>',
                },
                lotteryNumber: {
                    type: 'number',
                    displayName: '<<cjzcs>>',
                },
                residueLotteryNumber: {
                    type: 'number',
                    displayName: '<<sycjcs>>',
                },
            },
        },
        SlotMachinesDetailEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/slotMachinesDetail',
            properties: {
                couponName: {
                    type: 'string',
                    displayName: '<<cjqmc>>',
                },
                couponNo: {
                    type: 'string',
                    displayName: '<<couponNum>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<sjhm>>',
                },
                nickName: {
                    type: 'string',
                    displayName: '<<nickName>>',
                },
                lotteryTime: {
                    type: 'string',
                    displayName: '<<cjsj>>',
                },
                rewardName: {
                    type: 'string',
                    displayName: '<<jpmc>>',
                },
                rewardType: {
                    type: 'string',
                    displayName: '<<jplx>>',
                },
            },
        },
    },
    components: {
        SlotMachinesView: {
            component: 'Viewport',
        },
        SlotMachinesPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [{ component: 'SlotMachinesTables' }],
        },
        SlotMachinesTables: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'SlotMachinesEntity',
            items: [
                {
                    component: 'SlotMachinesFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/slot-machines/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'SlotMachinesTable' },
            ],
        },
        SlotMachinesFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'subSiteId',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
            ],
        },
        SlotMachinesTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'couponRuleCode',
                },
                {
                    property: 'subSites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '200px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'startTime',
                },
                {
                    property: 'endTime',
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 250,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/slot_machine_activities/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.baseInfo.name}}?',
                                        className: 'confirm-content',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/slot_machine_activities/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.baseInfo.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path:
                            '/menu-list/market/slot-machines/edit/{{row.id}}/{{row.executeStatus}}',
                    },
                    {
                        type: 'link',
                        text: '<<viewData>>',
                        path: '/menu-list/market/slot-machines/report/{{row.id}}',
                    },
                ],
            },
        },
        SlotMachinesReportFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'mobile',
                },
                {
                    property: 'nickName',
                },
                {
                    property: 'lotteryCouponCode',
                },
            ],
        },
        SlotMachinesReportTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'couponName',
                },
                {
                    property: 'couponNo',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'nickName',
                },
                {
                    property: 'lotteryNumber',
                },
                {
                    property: 'residueLotteryNumber',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                items: [
                    {
                        type: 'link',
                        text: '<<details>>',
                        path:
                            '/menu-list/market/slot-machines/detail/{{row.activityId}}/{{row.couponId}}',
                    },
                    {
                        type: 'component',
                        component: 'ExportDataButton',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: `/admin/slot_machine_activities/{{row.activityId}}/{{row.couponId}}.xls`,
                        },
                    },
                ],
            },
        },
        SlotMachinesDetailTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'couponName',
                },
                {
                    property: 'couponNo',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'nickName',
                },
                {
                    property: 'lotteryTime',
                },
                {
                    property: 'rewardName',
                },
                {
                    property: 'rewardType',
                },
            ],
        },
        SlotMachinesReport: {
            component: 'FlexLayout',
            entity: 'SlotMachinesReportEntity',
            direction: 'vertical',
            items: [
                {
                    component: 'SlotMachinesReportFilter',
                    style: {
                        marginBottom: 16,
                    },
                },
                {
                    component: 'SlotMachinesReportTable',
                },
            ],
        },
        SlotMachinesDetail: {
            component: 'FlexLayout',
            entity: 'SlotMachinesDetailEntity',
            direction: 'vertical',
            items: [
                {
                    component: 'SlotMachinesDetailTable',
                },
            ],
        },
        SlotMachinesAddPage: {
            component: 'FlexLayout',
            entity: 'SlotMachinesEntity',
            direction: 'horizontal',
            items: [{ component: 'SlotMachinesAddFormView' }],
        },
        SlotMachinesEditPage: {
            component: 'FlexLayout',
            entity: 'SlotMachinesEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'SlotMachinesEditFormView' }],
        },
        SlotMachinesReportPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'SlotMachinesReportEntity',
            items: [
                {
                    component: 'SlotMachinesReport',
                },
            ],
        },
        SlotMachinesDetailPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'SlotMachinesDetailEntity',
            items: [
                {
                    component: 'SlotMachinesDetail',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/slot-machines',
                    component: 'SlotMachinesView',
                    breadcrumbName: '<<lhjhd>>',
                    privilege: {
                        path: 'slotMachine',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'SlotMachinesAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id/:executeStatus',
                            component: 'SlotMachinesEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/report/:id',
                            component: 'SlotMachinesReportPage',
                            breadcrumbName: '<<viewData>>',
                        },
                        {
                            path: '/detail/:id',
                            component: 'SlotMachinesDetailPage',
                            breadcrumbName: '<<details>>',
                        },
                        { path: '/', component: 'SlotMachinesPage' },
                    ],
                },
            ],
        },
    ],
};
