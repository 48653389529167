import { Config } from '@comall-backend-builder/core/lib/parser';
import get from 'lodash/get';
import { services } from '@comall-backend-builder/core';
import { message } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services';
import moment from 'moment';
import { extraElement } from '../../../services/extra-element';
import { TipsModalContentType } from '../../../components';

export enum MerchantTagStatus {
    ENABLE = 'ENABLED',
    DISABLE = 'DISABLED',
    ALL = 'ALL',
}
export const config: Config = {
    entities: {
        merchantBigCodeEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/merchantBigCodes',
            filters: {
                bigCode: {
                    type: 'string',
                    displayName: '<<bigCode>>',
                    controlConfig: { placeholder: '<<inputPlease>>', style: { width: 150 } },
                },
                name: {
                    type: 'string',
                    displayName: '<<bigCodeName>>',
                    controlConfig: { placeholder: '<<inputPlease>>', style: { width: 150 } },
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                merchantId: {
                    type: 'string.filterOptions.autoComplete',
                    displayName: '<<merchant>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                        //专柜默认正序排序
                        params: {
                            sequence: 'code',
                            order: 'asc',
                        },
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                allowPriceAdjustment: {
                    type: 'string.options.select',
                    displayName: '<<allowPriceAdjustment>>',
                    defaultValue: MerchantTagStatus.ALL,
                    options: [
                        { id: MerchantTagStatus.ALL, name: '<<selectPlease>>' },
                        { id: 'true', name: '<<customPrice>>' },
                        { id: 'false', name: '<<sellOriginalPrice>>' },
                    ],
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 150 } },
                },
                enabled: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    defaultValue: MerchantTagStatus.ALL,
                    options: [
                        { id: MerchantTagStatus.ALL, name: '<<selectPlease>>' },
                        { id: 'true', name: '<<enable>>' },
                        { id: 'false', name: '<<disabled>>' },
                    ],
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 150 } },
                },
                supportInvoicing: {
                    type: 'string.options.select',
                    displayName: '<<isSupportInvoice>>',
                    defaultValue: MerchantTagStatus.ALL,
                    options: [
                        { id: MerchantTagStatus.ALL, name: '<<selectPlease>>' },
                        { id: 'true', name: '<<yes>>' },
                        { id: 'false', name: '<<no>>' },
                    ],
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 150 } },
                },
                allowDeposit: {
                    type: 'string.options.select',
                    displayName: '<<sfzcdjyw>>',
                    defaultValue: MerchantTagStatus.ALL,
                    options: [
                        { id: MerchantTagStatus.ALL, name: '<<selectPlease>>' },
                        { id: 'true', name: '<<zc>>' },
                        { id: 'false', name: '<<bzc>>' },
                    ],
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 150 } },
                },
            },
            properties: {
                id: {
                    type: 'number',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<basicSetting>>',
                    properties: {
                        merchants: {
                            type: 'array.shop.selector',
                            displayName: '<<selectSubsites>>',
                            controlConfig: {
                                type: 'radio',
                                fieldParams: {
                                    merchantType: '1,2,4',
                                },
                                fields: [
                                    {
                                        property: 'subSiteName',
                                    },
                                    {
                                        property: 'merchantName',
                                    },
                                ],
                            },
                            rules: [
                                {
                                    message: '<<rules.MerchantPageManage.nameRequired>>',
                                    validator: (rule: any, value: any) => {
                                        if (value) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                        },
                        bigCode: {
                            displayName: '<<bigCode>>',
                            type: 'string',
                            rules: [
                                { max: 100 },
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<bigCodeRequired>>',
                                },
                            ],
                            controlConfig: {
                                style: { width: '250px' },
                                placeholder: '<<bigCodeRequired>>',
                                maxLength: 100,
                            },
                        },
                        name: {
                            displayName: '<<bigCodeName>>',
                            type: 'string',
                            controlConfig: {
                                style: { width: '250px' },
                                placeholder: '<<bigCodeNameRequired>>',
                                maxLength: 60,
                            },
                        },
                        merchantBigName: {
                            displayName: '<<bigCode>>',
                            type: 'string.MerchantBigCodesName',
                            controlConfig: {
                                style: { width: '250px' },
                                placeholder: '<<bigCodeNameRequired>>',
                                maxLength: 100,
                            },
                        },
                        price: {
                            type: 'string',
                            displayName: '<<bigCodeOriginPrice>>',
                            defaultValue: '',
                            displayConfig: { addonafter: '<<yuan>>' },
                            rules: [
                                {
                                    message: '<<priceRules>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^(?!0+$)(?!0*\.0*$)\d{1,7}(\.\d{1,2})?$/;
                                        if (value) {
                                            if (Number(value) >= 0.01 && reg.test(value)) {
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        }
                                        if (value === 0) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<pleaseInputNumber>>',
                                style: { width: '150px' },
                                allowFloat: true,
                                rules: [
                                    {
                                        precision: 2,
                                        type: 'number',
                                        min: 0,
                                    },
                                ],
                            },
                        },

                        allowPriceAdjustment: {
                            type: 'string.options.radio',
                            displayName: '<<allowPriceAdjustment>>',
                            defaultValue: 'true',
                            options: [
                                { id: 'true', name: '<<customPrice>>' },
                                { id: 'false', name: '<<sellOriginalPrice>>' },
                            ],
                            extra: '<<allowPriceAdjustmentExtra>>',
                        },
                        subsiteName: {
                            displayName: '<<subsites>>',
                            type: 'string',
                        },
                        merchantName: {
                            displayName: '<<merchant>>',
                            type: 'string',
                        },
                        merchantType: {
                            displayName: '<<merchantType>>',
                            type: 'string',
                        },
                        enabled: {
                            type: 'string.statusBadge',
                            displayName: '<<status>>',
                            defaultValue: 'true',
                            options: [
                                {
                                    id: 'true',
                                    name: '<<enable>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<disabled>>',
                                },
                            ],
                            /* type: 'string.options.radio',
                            displayName: '<<status>>',
                            defaultValue: 'false',
                            options: [
                                { id: 'true', name: '<<enable>>' },
                                { id: 'false', name: '<<disabled>>' },
                            ], */
                        },
                    },
                },

                settingInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherSetting>>',
                    properties: {
                        settlementPrompt: {
                            type: 'string.radioWithModalTip',
                            displayName: '<<sfkqjsts>>',
                            defaultValue: 'false',
                            options: [
                                {
                                    id: 'true',
                                    name: '<<show>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<hide>>',
                                },
                            ],
                            controlConfig: {
                                tipsModalConfig: {
                                    type: 'extra',
                                    extraConfig: {
                                        before: '<<dkxddx>>',
                                    },
                                    contentType: TipsModalContentType.MerchantBigCodesTips,
                                    showIcon: false,
                                    text: '<<viewExample>>',
                                    textColor: '#1890ff',
                                    title: '',
                                    width: 1048,
                                },
                            },
                        },
                        listAllowDepositValue: {
                            type: 'string.options.radio',
                            displayName: '<<sfzcdjyw>>',
                            defaultValue: 'false',
                            options: [
                                { id: 'true', name: '<<zc>>' },
                                { id: 'false', name: '<<bzc>>' },
                            ],
                        },
                        allowDeposit: {
                            type: 'string.options.radio',
                            displayName: '<<allowDeposit>>',
                            defaultValue: 'false',
                            options: [
                                { id: 'true', name: '<<zc>>' },
                                { id: 'false', name: '<<bzc>>' },
                            ],
                        },
                        supportInvoicing: {
                            type: 'string.options.radio',
                            displayName: '<<isSupportInvoice>>',
                            defaultValue: 'false',

                            options: [
                                { id: 'true', name: '<<yes>>' },
                                { id: 'false', name: '<<no>>' },
                            ],
                        },
                        name: {
                            displayName: '<<invoiceName>>',
                            type: 'string',
                            controlConfig: {
                                style: { width: '250px' },
                                placeholder: '<<invoiceNameRequired>>',
                                maxLength: 100,
                            },
                            rules: [{ required: true, message: '<<invoiceNameRequired>>' }],
                        },
                        taxCode: {
                            displayName: '<<taxCode>>',
                            type: 'string',
                            controlConfig: {
                                style: { width: '250px' },
                                placeholder: '<<taxCodeRequired>>',
                                maxLength: 100,
                            },
                            rules: [{ required: true, message: '<<taxCodeRequired>>' }],
                        },
                        taxRate: {
                            displayName: '<<taxRate>>',
                            type: 'object.MerchantCodeTaxrateRule',
                            controlConfig: {
                                style: { width: '250px' },
                                placeholder: '<<taxRateRequired>>',
                                maxLength: 100,
                            },
                        },
                        sequence: {
                            displayName: '<<sequenceValue>>',
                            type: 'number.integer',
                            defaultValue: '0',
                            controlConfig: {
                                style: { width: '250px' },
                                placeholder: '<<sequenceRequired>>',
                                max: 999999,
                                min: 0,
                            },
                        },
                        barCodes: {
                            displayName: '<<barCodes>>',
                            type: 'array.merchantBarCode',
                        },
                        pictures: {
                            displayName: '<<productPictures>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'files',
                                uploadType: 'single',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/locations/7/upload`,
                                mode: 'picture-card',
                                maxSize: 2 * 1024,
                                uploadLimitSizeErrorMessage:
                                    '<<pictures>>{{file.name}}<<pictureUploadLimit>>',
                            },
                            extra: '<<productPicturesExtra>>',
                        },
                        orderDelayedSyncDate: {
                            displayName: '<<ysrzrq>>',
                            type: 'string.date',
                            controlConfig: {
                                placeholder: '<<qxzysrzrq>>',
                                style: { width: 240 },
                                allowClear: true,
                                range: {
                                    start: new Date(
                                        moment()
                                            .add(1, 'days')
                                            .format('YYYY-MM-DD')
                                    ),
                                },
                            },
                            extra: extraElement({
                                tips: ['<<viewExample_dkxd>>'],
                                tipsModal: {
                                    contentType: TipsModalContentType.BigCodesDate,
                                    showIcon: false,
                                    text: '<<viewExample>>',
                                    textColor: '#1890ff',
                                    title: '',
                                    width: 453,
                                },
                            }),
                        },
                    },
                },
            },
        },
    },
    components: {
        merchantBigCodeView: {
            component: 'Viewport',
            entity: 'merchantBigCodeEntity',
        },
        merchantBigCodePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'merchantBigCodeFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            style: {
                                display: 'flex',
                            },
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addMerchatCode>>',
                                    type: 'primary',
                                    route: '/merchant-big-codes/add',
                                    style: {
                                        marginRight: 10,
                                    },
                                },
                                {
                                    component: 'MerchantBigCodesButton',
                                    props: {
                                        triggerType: 'primary',
                                        triggerText: '<<batchImport>>',
                                        closeTxt: '<<import>>',
                                        triggerIcon: 'plus',
                                        controlUploadApiPath: '/admin/files/locations/1210/upload',
                                        controlDownloadTemplate: {
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
                                            apiPath: `/admin/merchant_big_codes/excel_import_template`,
                                        },
                                        controlUploadRules: [
                                            '<<merchantBigCodeIntro1>>',
                                            {
                                                text: '<<merchantBigCodeNote>>',
                                                color: 'red',
                                            },
                                            '<<merchantBigCodeIntro2>>',
                                            '<<merchantBigCodeIntro3>>',
                                            {
                                                color: 'red',
                                                text: '<<merchantBigCodeIntro4>>',
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    ],
                },

                { component: 'MerchantCodeTable' },
            ],
        },
        MerchantCodeTable: {
            component: 'MerchantCodeTableView',
        },
        merchantBigCodeFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'bigCode',
                },
                {
                    property: 'name',
                },
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'allowPriceAdjustment',
                },
                {
                    property: 'enabled',
                },
                {
                    property: 'supportInvoicing',
                },
                {
                    property: 'allowDeposit',
                },
            ],
        },
        //新增
        merchantBigCodeAddPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'merchantBigCodeEntity',
            items: [{ component: 'merchantBigCodeAddFormView' }],
        },
        merchantBigCodeAddFormView: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            labelCol: 3,
            controlCol: 15,
            style: { width: 1200 },
            fields: [
                { property: 'baseInfo.merchants' },
                { property: 'baseInfo.bigCode' },
                { property: 'baseInfo.name' },
                { property: 'baseInfo.price' },
                { property: 'baseInfo.allowPriceAdjustment' },
                { property: 'settingInfo.allowDeposit' },
                { property: 'settingInfo.supportInvoicing' },
                {
                    property: 'settingInfo.name',
                    visible: (values: any) =>
                        get(values, 'settingInfo.supportInvoicing') === 'true',
                },
                {
                    property: 'settingInfo.taxCode',
                    visible: (values: any) =>
                        get(values, 'settingInfo.supportInvoicing') === 'true',
                },
                {
                    property: 'settingInfo.taxRate',
                    visible: (values: any) =>
                        get(values, 'settingInfo.supportInvoicing') === 'true',
                },
                { property: 'settingInfo.sequence' },
                { property: 'settingInfo.barCodes' },
                { property: 'settingInfo.pictures' },
                { property: 'settingInfo.orderDelayedSyncDate' },
                { property: 'settingInfo.settlementPrompt' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const allowPriceAdjustment = get(entity, 'baseInfo.allowPriceAdjustment');
                const price = get(entity, 'baseInfo.price');
                if (allowPriceAdjustment === 'false' && !price) {
                    message.error(language.getText('taxRateMessageError'));
                    return false;
                }

                const supportInvoicing = get(entity, 'settingInfo.supportInvoicing');
                const taxRateType = get(entity, 'settingInfo.taxRate.exchangeRatio') || 'STANDARD';
                const taxRate = get(entity, 'settingInfo.taxRate.exchangeAmount');
                if (supportInvoicing === 'true' && taxRateType === 'STANDARD') {
                    if (!taxRate) {
                        message.error(language.getText('taxRateRequired'));
                        return false;
                    }
                    const reg = /^[1-9][0-9]?$/;
                    if (!reg.test(String(taxRate))) {
                        message.error(language.getText('taxRateAllowNumber1to99'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                message.success(language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        //编辑
        merchantBigCodeEditPage: {
            component: 'FlexLayout',
            entity: 'merchantBigCodeEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'merchantBigCodeEditFormView' }],
        },
        merchantBigCodeEditFormView: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            loaderType: 'get',
            labelCol: 3,
            controlCol: 15,
            style: { width: 1200 },
            fields: [
                {
                    property: 'baseInfo.merchants',
                    controlConfig: { disabled: true },
                },
                { property: 'baseInfo.bigCode', controlConfig: { disabled: true } },
                { property: 'baseInfo.name' },
                { property: 'baseInfo.price' },
                { property: 'baseInfo.allowPriceAdjustment' },
                {
                    property: 'baseInfo.enabled',
                    className: 'hidden',
                },
                { property: 'settingInfo.allowDeposit' },
                { property: 'settingInfo.supportInvoicing' },
                {
                    property: 'settingInfo.name',
                    visible: (values: any) =>
                        get(values, 'settingInfo.supportInvoicing') === 'true',
                },
                {
                    property: 'settingInfo.taxCode',
                    visible: (values: any) =>
                        get(values, 'settingInfo.supportInvoicing') === 'true',
                },
                {
                    property: 'settingInfo.taxRate',
                    visible: (values: any) =>
                        get(values, 'settingInfo.supportInvoicing') === 'true',
                },
                { property: 'settingInfo.sequence' },
                { property: 'settingInfo.barCodes' },
                { property: 'settingInfo.pictures' },
                { property: 'settingInfo.orderDelayedSyncDate' },
                { property: 'settingInfo.settlementPrompt' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const allowPriceAdjustment = get(entity, 'baseInfo.allowPriceAdjustment');
                const price = get(entity, 'baseInfo.price');
                if (allowPriceAdjustment === 'false' && !price) {
                    message.error(language.getText('taxRateMessageError'));
                    return false;
                }

                const supportInvoicing = get(entity, 'settingInfo.supportInvoicing');
                const taxRateType = get(entity, 'settingInfo.taxRate.exchangeRatio') || 'STANDARD';
                const taxRate = get(entity, 'settingInfo.taxRate.exchangeAmount');
                if (supportInvoicing === 'true' && taxRateType === 'STANDARD') {
                    if (!taxRate) {
                        message.error(language.getText('taxRateRequired'));
                        return false;
                    }
                    const reg = /^[1-9][0-9]?$/;
                    if (!reg.test(String(taxRate))) {
                        message.error(language.getText('taxRateAllowNumber1to99'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/merchant-big-codes',
                    component: 'merchantBigCodeView',
                    breadcrumbName: '<<merchantBigCodeManager>>',
                    privilege: {
                        path: 'merchantBigCodeManager',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'merchantBigCodeAddPage',
                            breadcrumbName: '<<addMerchatCode>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'merchantBigCodeEditPage',
                            breadcrumbName: '<<editMerchantCode>>',
                        },
                        { path: '/', component: 'merchantBigCodePage' },
                    ],
                },
            ],
        },
    ],
};
