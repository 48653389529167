import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { PointRewardContent } from '../../../components';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

export class PointRewardContentMode extends ObjectMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(object: any, displayInfo: any) {
        return (
            object && (
                <div>
                    <span>
                        {' '}
                        {services.interpolate(language.getText('getYeveryX'), {
                            x: object.ladderValue,
                            y: object.ladderAmount,
                        })}
                    </span>
                </div>
            )
        );
    }

    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <PointRewardContent {...controlInfo} />;
    }
}
