import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { OpenBoxActivitySelect } from './open-box-activity-select';
import { OpenBoxActivitySelectedDisplay } from './open-box-activity-selected-display';

export class OpenBoxActivityMode extends modes.ArrayMode {
    getDisplayComponent(value: any) {
        return (
            <OpenBoxActivitySelectedDisplay
                data={value}
                onRemove={() => {}}
                disabled={true}
            ></OpenBoxActivitySelectedDisplay>
        );
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <OpenBoxActivitySelect {...displayInfo} />;
    }
}
