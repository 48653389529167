import React from 'react';

import { MaotaiPresaleConditionsProps } from '../index';
import RealName from './realName';
import Age from './age';
import Level from './level';
import Registration from './registration';
import CommonForm from './commonForm';
import Region from './region';
import { language } from '@comall-backend-builder/core/lib/services';

export enum ConditionType {
    /**
     * 年龄
     */
    AGE = 'AGE',
    /**
     * 实名认证
     */
    REALNAME = 'REALNAME',
    /**
     * 注册时间
     */
    REGISTRATION_TIME = 'REGISTRATION_TIME',
    /**
     * 等级
     */
    LEVEL = 'LEVEL',
    /**
     * 消费金额
     */
    CONSUME_AMOUNT = 'CONSUME_AMOUNT',
    /**
     * 交易次数
     */
    TRANSACTION_QUANTITY = 'TRANSACTION_QUANTITY',
    /**
     * 客单价
     */

    UNIT_PRICE = 'UNIT_PRICE',
    /**
     * 总积分
     */
    TOTAL_POINT = 'TOTAL_POINT',
    /**
     * 超市积分
     */
    SUPERMARKET_POINT = 'SUPERMARKET_POINT',

    /**
     * 百货积分
     */
    STORE_POINT = 'STORE_POINT',
    /**
     * 地区
     */
    REGION = 'REGION',
}
interface ConditionItemProps extends MaotaiPresaleConditionsProps {
    conditionType: ConditionType;
    options?: any[];
    endText: string;
    index: number;
}

export default class ConditionItem extends React.PureComponent<ConditionItemProps> {
    render() {
        const { conditionType } = this.props;
        return (
            <div>
                {conditionType === ConditionType.REALNAME && <RealName {...this.props}></RealName>}
                {conditionType === ConditionType.AGE && <Age {...this.props}></Age>}
                {conditionType === ConditionType.LEVEL && <Level {...this.props}></Level>}
                {conditionType === ConditionType.REGISTRATION_TIME && (
                    <Registration {...this.props}></Registration>
                )}
                {conditionType === ConditionType.TOTAL_POINT && (
                    <CommonForm
                        frontText={language.getText('yhdzjf')}
                        middleText={language.getText('pointGt')}
                        {...this.props}
                    ></CommonForm>
                )}
                {conditionType === ConditionType.SUPERMARKET_POINT && (
                    <CommonForm
                        frontText={language.getText('yhzcs')}
                        middleText={language.getText('pointGt')}
                        {...this.props}
                    ></CommonForm>
                )}
                {conditionType === ConditionType.STORE_POINT && (
                    <CommonForm
                        frontText={language.getText('yhzbh')}
                        middleText={language.getText('pointGt')}
                        {...this.props}
                    ></CommonForm>
                )}
                {conditionType === ConditionType.CONSUME_AMOUNT && (
                    <CommonForm
                        frontText={language.getText('yhz')}
                        middleText={language.getText('totalPriceGt')}
                        {...this.props}
                    ></CommonForm>
                )}
                {conditionType === ConditionType.TRANSACTION_QUANTITY && (
                    <CommonForm
                        frontText={language.getText('yhz')}
                        middleText={language.getText('transactionsNumGt')}
                        {...this.props}
                    ></CommonForm>
                )}
                {conditionType === ConditionType.UNIT_PRICE && (
                    <CommonForm
                        frontText={language.getText('yhz')}
                        middleText={language.getText('unitPriceGt')}
                        {...this.props}
                    ></CommonForm>
                )}
                {conditionType === ConditionType.REGION && (
                    <Region
                        frontText={language.getText('yhz')}
                        middleText={language.getText('canSignUp')}
                        {...this.props}
                    ></Region>
                )}
            </div>
        );
    }
}
