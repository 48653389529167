import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { MallActivityPayList } from './mall-activity-pay-list';

export class ObjectMallActivityPayList extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <MallActivityPayList {...controlInfo} />;
    }
}
