import React, { Component } from 'react';
import { Radio } from 'antd';
import { cloneDeep, find } from 'lodash';
import { api, language } from '@comall-backend-builder/core/lib/services';

const { getText } = language;

interface StringRadioStoredValueCardTypeProps {
    onChange: (value: any) => void;
    value: string;
}

interface StringRadioStoredValueCardTypeStates {
    options: any[];
}

export class StringRadioStoredValueCardType extends Component<
    StringRadioStoredValueCardTypeProps,
    StringRadioStoredValueCardTypeStates
> {
    constructor(props: any) {
        super(props);
        this.state = {
            options: [],
        };
    }
    componentDidMount() {
        const { value, onChange } = this.props;
        if (!value) {
            onChange('E_STORED_VALUE_CARD');
        }
        this.loadOptions();
    }
    loadOptions = async () => {
        const res: any = await api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: '/MAGIC-MEMBER-ASSETS/admin/stored_value_card_configs',
            }
        );
        if (res) {
            const options = [
                {
                    id: 'E_STORED_VALUE_CARD',
                    name: getText('dzmzk'),
                },
                {
                    id: 'E_RECHARGE_STORED_VALUE_CARD',
                    name: getText('dzczk'),
                },
            ];
            const enablePStoredValueCard: any = find(res, {
                param: 'enablePStoredValueCard',
            });
            if (enablePStoredValueCard && enablePStoredValueCard.value === 'true') {
                options.push({ id: 'P_STORED_VALUE_CARD', name: getText('stk') });
            }
            this.setState({
                options,
            });
        }
    };
    changeUnifiedReward = (e: any) => {
        const { onChange } = this.props;
        const unifiedReward: string = e.target.value;
        onChange && onChange(cloneDeep(unifiedReward));
    };

    render() {
        const { value } = this.props;
        const { options } = this.state;
        return (
            <div>
                <Radio.Group onChange={this.changeUnifiedReward} value={value}>
                    {options &&
                        options.map((item, index) => (
                            <Radio key={index} value={item.id}>
                                {item.name}
                            </Radio>
                        ))}
                </Radio.Group>
            </div>
        );
    }
}
