import React, { Component } from 'react';
import { Card } from 'antd';
import { GroupBuyingActivityDataLoader } from '../../loaders/group-buying-loader';
import './index.less';
import { services } from '@comall-backend-builder/core';

interface GroupBuyingActivityDataProps {
    params: {
        id: string;
    };
}

interface GroupBuyingActivityDataItem {
    groupBuyingName: string;
    doneNumbers: number;
    waitingNumbers: number;
    successOrder: number;
    newMembers: number;
}

interface GroupBuyingActivityDataState {
    activityData: {
        name: string;
        groupBuyingName: string;
        doneNumbers: number;
        waitingNumbers: number;
        successOrder: number;
        newMembers: number;
        products: GroupBuyingActivityDataItem[];
    };
}

export class GroupBuyingActivityData extends Component<
    GroupBuyingActivityDataProps,
    GroupBuyingActivityDataState
> {
    state = {
        activityData: {
            name: '',
            groupBuyingName: '',
            doneNumbers: 0,
            waitingNumbers: 0,
            successOrder: 0,
            newMembers: 0,
            products: [],
        },
    };
    componentDidMount() {
        GroupBuyingActivityDataLoader.get(this.props.params).then((res) => {
            this.setState({ activityData: res });
        });
    }
    /**
     * 渲染每条数据
     * @param item
     */
    renderActivityItem = (item: GroupBuyingActivityDataItem) => {
        return (
            <Card type="inner" title={item.groupBuyingName}>
                <div className="group-buying-activity">
                    <div className="group-buying-activity-item">
                        <div className="group-buying-activity-item-title">成团数</div>
                        <div>{item.doneNumbers}</div>
                    </div>
                    <div className="group-buying-activity-item">
                        <div className="group-buying-activity-item-title">待成团数</div>
                        <div>{item.waitingNumbers}</div>
                    </div>
                    <div className="group-buying-activity-item">
                        <div className="group-buying-activity-item-title">
                            {services.language.getText('yctdd')}
                        </div>
                        <div>{item.successOrder}</div>
                    </div>
                    <div className="group-buying-activity-item">
                        <div className="group-buying-activity-item-title">新客数</div>
                        <div>{item.newMembers}</div>
                    </div>
                </div>
            </Card>
        );
    };

    render() {
        const { activityData } = this.state;
        return (
            <Card title={activityData.name}>
                {this.renderActivityItem(activityData)}
                {activityData.products.map((item) => {
                    return this.renderActivityItem(item);
                })}
            </Card>
        );
    }
}
