import * as React from 'react';
import classNames from 'classnames';
import { find } from 'lodash';

import { ObjectMode } from '@comall-backend-builder/types';
import { WinningLotsNumber } from '../../../components';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

export class ObjectWinningLotsNumberMode extends ObjectMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(object: any, displayInfo: any) {
        const { className, style, options } = displayInfo;
        if (object && object.number > 0) {
            let typeOption = find(options, { id: object.type }) || {};

            return (
                <div
                    className={classNames('type-object mode-object-winning-lots-number', className)}
                    style={style}
                >
                    <div>
                        <span>{typeOption.name}</span>
                        {/* <span>最多中签 {object.number} 人</span> */}
                        <span>
                            {services.interpolate(services.language.getText('maximumWinningLots'), {
                                maximumWinningLots: object.number,
                            })}
                        </span>
                    </div>
                </div>
            );
        } else {
            return this.getNotAvailableDisplayComponent(displayInfo);
        }
    }

    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            language.getText('notData')
        );
    }

    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <WinningLotsNumber {...controlInfo} />;
    }
}
