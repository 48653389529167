import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { SubsiteMerchantDelivery } from './subsite-merchant-delivery';

export class SubsiteMerchantDeliveryMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <SubsiteMerchantDelivery {...displayInfo} />;
    }
}
