const OLD_TENANT_IDS = ['magic', 'huijia'];

enum PageType {
    platform_home_pages = 'PLATFORM_PAGE',
    home_pages = 'STORE_PAGE',
    topic_pages = 'CUSTOM_PAGE',
    member_pages = 'MEMBER_CENTER_PAGE',
    merchant_pages = 'MERCHANT_PAGE',
}

export function getDesignPageApiPath(originType: keyof typeof PageType): string {
    if (OLD_TENANT_IDS.includes(ENV.TENANT)) {
        return `/admin/${originType}`;
    }
    return `/admin/pages/${PageType[originType]}`;
}
