import React, { PureComponent, createElement } from 'react';
import builder, { actions, services } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';

import { debounce, defaults, get, mapValues } from 'lodash';
import './index.less';
export interface ThemeStyleFormProps extends FormComponentProps {
    id: string;
    styleMode: string;
    entity: Entity;
    scope: string;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
}

class ThemeStyleForm extends PureComponent<ThemeStyleFormProps> {
    static defaultProps: any = {};
    componentWillUnmount() {
        this.props.unmountComponent();
    }
    renderModal = () => {
        const { entity, onSubmit, wrappedComponentRef, id = '', styleMode = '0' } = this.props;
        let fields = [
            { property: 'id', className: 'hidden' },
            { property: 'navigation.style' },
            {
                property: 'themeStyle.styleMode',
                controlConfig: {
                    onChange: (value: any, name: string) => {
                        const store = builder.getStore();

                        store.dispatch(
                            actions.formChangeAction(
                                'DataFormPlus-ThemeStyleFormContainer',
                                name,
                                value
                            )
                        );
                    },
                },
            },
            { property: 'themeStyle.color' },
            { property: 'themeStyle.button' },
            {
                property: 'themeStyle.backgroundImage',
                className: styleMode === '0' ? 'hidden' : '',
            },
        ];

        let formProps = {
            entity,
            componentId: 'ThemeStyleFormContainer',
            wrappedComponentRef,
            onSubmit,
            direction: 'horizontal',
            style: { width: window.innerWidth < 1600 ? 500 : 660 },
            labelCol: 6,
            controlCol: 18,
            fields: fields,
            submit: true,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () => {
                    !id && window.location.reload();
                });
            },
        };
        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    };

    render() {
        return <div className="theme-style-review-agree-form">{this.renderModal()}</div>;
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;

    let id = get(_dispatch, 'components.DataFormPlus-ThemeStyleFormContainer.fields.id');
    let styleMode = get(
        _dispatch,
        'components.DataFormPlus-ThemeStyleFormContainer.fields.themeStyle.styleMode'
    );
    let form: any;
    return defaults(
        {
            onSubmit,
            onFieldChange,
            id,
            styleMode,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: debounce((event: any, fields: any) => {
                const entityFields = mapValues(fields, (field, name) => {
                    return field.value;
                });
                if (entityFields) {
                    entity.modify(entityFields, params);
                }
                return false;
            }, 300),
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('ThemeStyleFormContainer'));
            },
        }
    );
}

export const ThemeStyleFormView = connect(mapDispatchToProps)(ThemeStyleForm);
