import React, { Component } from 'react';
import { InputNumber, Select } from 'antd';
import { map, forEach } from 'lodash';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

type WinningLotsNumberValue = {
    /**
     * 中签限制类型
     */
    type?: string;
    /**
     * 中签限制人数
     */
    number?: number;
};

export interface WinningLotsNumberProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: WinningLotsNumberValue | null, name: string) => void;
    /**
     * 当前值
     */
    value: WinningLotsNumberValue;
    /**
     * 候选项集合
     */
    options: Array<any>;
    defaultTypeValue: string;
    tip: string;
    rules: any;
    addonbefore?: string;
    addonafter?: string;
}
export class WinningLotsNumber extends Component<WinningLotsNumberProps> {
    onTypeChange(type: string) {
        let { name, value, onChange } = this.props;
        if (!value) {
            value = {};
        }
        value.type = type;
        if (onChange) {
            onChange(value, name);
        }
    }

    onNumberChange(event: any) {
        let { name, value, defaultTypeValue, onChange } = this.props;
        if (!value) {
            value = {};
        }
        if (!value.type) {
            value.type = defaultTypeValue;
        }
        value.number = parseInt(event);
        if (onChange) {
            onChange(value, name);
        }
    }

    render() {
        const {
            value,
            options,
            defaultTypeValue,
            tip,
            rules,
            addonbefore,
            addonafter,
        } = this.props;
        let ruleInfo = {};
        if (rules && rules.length) {
            forEach(rules, (item) => {
                Object.assign(ruleInfo, item);
            });
        }
        return (
            <div className="winning-lots-number">
                <div className="winning-lots-number-content">
                    <Select
                        className="type-selection"
                        placeholder={language.getText('selectPlease')}
                        defaultValue={defaultTypeValue}
                        value={(value && value.type) || defaultTypeValue}
                        onChange={(type: any) => this.onTypeChange(type)}
                    >
                        {map(options, ({ id, name }) => (
                            <Select.Option key={id}>{name}</Select.Option>
                        ))}
                    </Select>
                    <span className="item-text">{addonbefore}</span>
                    <div className="number-input">
                        <InputNumber
                            value={value && value.number}
                            onChange={this.onNumberChange.bind(this)}
                            {...ruleInfo}
                        />
                    </div>
                    <span className="item-text">{addonafter}</span>
                </div>
                {tip && <div style={{ fontSize: 12, color: '#ccc', lineHeight: 1.5 }}>{tip}</div>}
            </div>
        );
    }
}
