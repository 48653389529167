import React, { PureComponent, createElement } from 'react';
import { forEach, map, remove } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { language, privilege } from '@comall-backend-builder/core/lib/services';
import { Button, Modal, message } from 'antd';

const api = services.api;
const TableComponentId = 'SalesElectronicCouponSelectorTable';
let Entity: any = {};
let entity: any = {};

/**
 * 选择优惠券
 */
export class CouponSelector extends PureComponent<{
    selectValues: any[];
    onChange: (products: any[]) => void;
    params?: any;
    type?: string;
    buttonProps?: any;
    canShowAmountValue?: boolean;
    checkSubsiteOnSelect?: boolean;
}> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('CouponSelector');
        entity = new Entity({});
    }
    dispatch: any = {};
    state = { visible: false };

    selectedRows = new Array<any>();

    onTableRowSelect = (record: any, selected: boolean) => {
        const { type } = this.props;
        if (selected) {
            if ('radio' === type) {
                this.selectedRows = [record];
            } else {
                this.selectedRows.push(record);
            }
        } else {
            remove(this.selectedRows, (i) => {
                return i.id === record.id;
            });
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: any[]) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            this.selectedRows = [];
        }
    };

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
    };

    onOk = () => {
        const { onChange } = this.props;
        if (onChange) {
            forEach(this.selectedRows, (coupon) => {
                coupon.select = true;
                coupon.batchNo = coupon.code;
            });
            onChange(this.selectedRows);
            this.toggleModal();
        }
    };

    getConfig = () => {
        const { params, selectValues, type } = this.props;
        let columns: any[] = [];
        columns = [
            {
                property: 'name',
                width: 160,
                displayConfig: {
                    className: 'ant-table-cell-ellipsis',
                    style: {
                        maxWidth: '140px',
                    },
                },
            },
            {
                property: 'code',
                width: 160,
                displayConfig: {
                    className: 'ant-table-cell-ellipsis',
                    style: {
                        maxWidth: '140px',
                    },
                },
            },
            {
                property: 'typeName',
                width: 100,
            },
            {
                property: 'amountValue',
                width: 120,
            },
            {
                property: 'startTime',
                width: 220,
            },
            {
                property: 'endTime',
                width: 220,
            },
            {
                property: 'executeStatus',
                width: 100,
            },
        ];
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: params || {},
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        entity.pageChange(Object.assign({}, entity.paging, { page: 1 }));
                        entity.search(params);
                    },
                    fields: [
                        {
                            property: 'name',
                        },
                        {
                            property: 'couponType',
                            controlConfig: {
                                style: { width: 180 },
                            },
                        },
                        {
                            property: 'executeStatus',
                            controlConfig: {
                                style: { width: 180 },
                            },
                        },
                    ],
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    items: [
                        {
                            component: 'GridLayout',
                            style: {
                                display: 'flex',
                                margin: '15px 0',
                                alignItems: 'center',
                            },
                            items: [
                                {
                                    component: 'Button',
                                    text: language.getText('couponManage'),
                                    type: 'primary',
                                    onClick: (value: any) => {
                                        if (privilege.check('coupon', 'full')) {
                                            window.open('#/menu-list/coupon');
                                        } else {
                                            message.warning(language.getText('couponManageWarn'));
                                        }
                                    },
                                },
                                {
                                    component: 'Button',
                                    style: {
                                        marginLeft: '10px',
                                    },
                                    text: language.getText('refresh'),
                                    type: 'link',
                                    onClick: (value: any) => {
                                        const store = builder.getStore();
                                        store.dispatch(actions.filtersChangeAction(entity, {}));
                                        entity.pageChange(
                                            Object.assign({}, entity.paging, { page: 1 })
                                        );
                                        entity.search(params);
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    rowSelection: {
                        type: type ? type : 'radio',
                        selectedRowKeys: map(selectValues, 'id'),
                        onSelect: (record: any, selected: boolean, selectedRow: any) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: any[]) => {
                            this.onTableRowSelectAll(selected, rows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    scroll: { x: 1100, y: 500 },
                    columns: columns,
                },
            ],
        };
    };

    toggleModal = () => {
        const { visible } = this.state;
        const { params } = this.props;

        this.setState({ visible: !visible }, () => {
            if (!visible && entity.paging) {
                entity.search({
                    ...entity.paging,
                    ...params,
                });
                this.onClearAll();
            }
        });
    };

    getAllCoupon = () => {
        const { onChange } = this.props;
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: '/admin/subsites/mine',
            }
        ).then((response: any) => {
            if (onChange) {
                forEach(response.result, (subsite) => {
                    subsite.select = true;
                });
                onChange(response.result);
            }
        });
    };

    render() {
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 900,
            title: language.getText('components.Tools.selectCoupon'),
            visible: this.state.visible,
            okText: language.getText('common.ok'),
            cancelText: language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };
        const btnProps = Object.assign(
            {},
            { text: language.getText('components.Tools.selectCoupon'), type: 'link' },
            this.props.buttonProps
        );
        return (
            <div style={{ display: 'inline-block' }}>
                <Button {...btnProps} onClick={this.toggleModal}>
                    {btnProps.text}
                </Button>
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}
