import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { get, isArray, map } from 'lodash';

const api = services.api;
export const couponSaleActivityLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = '/admin/sale_rules';
        const { id } = data;
        if (!id) {
            if (data.status === 'ALL') {
                delete data.status;
            }
        } else {
            config.apiPath = `/admin/sale_rules/${id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.packageCouponItems = map(res.packageCouponItems, (item: any) => {
                    const couponTypeDes = getTypeName(item.couponType);
                    item.couponDefinitionName = item.name;
                    item.couponDefinitionId = item.id;
                    item.couponTypeDesc = couponTypeDes;
                    return item;
                });

                res.baseInfo = {
                    selectCoupon: [
                        { ...res.couponDefinitions[0], couponInfo: res.packageCouponItems },
                    ],
                    subsites: res.subsites.map((item: any) => ({
                        id: item.id + '',
                        name: item.name,
                    })),
                    dateRange: {
                        start: res.startTime,
                        end: res.endTime,
                    },
                };
                res.saleInfo = {
                    name: res.name,
                    couponPicture: res.images.map((item: { id: number; imgUrl: string }) => ({
                        id: item.id,
                        path: item.imgUrl,
                    })),
                    couponSalePrice: res.salePrice,
                    saleLimitType:
                        res.rules && res.rules.length > 0 && res.rules[0].ruleType === 'EACH_PERIOD'
                            ? 'YES'
                            : 'NO',
                    saleLimitNum: res.rules && res.rules.length > 0 ? res.rules[0].ruleCount : null,
                };
                res.useRulesInfo = {
                    info: res.info,
                };

                res.categoryInfo = {
                    categories: res.categories,
                };
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = '/admin/sale_rules';
        let newParams: any = {};
        if (params.baseInfo) {
            newParams.subsiteIds = params.baseInfo.subsites
                .map((item: { id: string; name: string }) => item.id)
                .join(',');
            if (params.baseInfo.dateRange) {
                newParams.startTime = params.baseInfo.dateRange.start;
                newParams.endTime = params.baseInfo.dateRange.end;
            }
            const couponInfo = get(params, 'baseInfo.selectCoupon[0].couponInfo');
            newParams.couponDefinitionId = get(params, 'baseInfo.selectCoupon[0].id');
            if (!!couponInfo && isArray(couponInfo) && couponInfo.length > 0) {
                newParams.packageItemPrices = map(couponInfo, (item) => ({
                    apportionPrice: item.apportionPrice,
                    id: item.couponDefinitionId,
                    quantity: item.quantity,
                }));
            }
        }
        if (params.saleInfo) {
            if (params.saleInfo.couponPicture) {
                newParams.imgIds = params.saleInfo.couponPicture
                    .map((item: { id: string; path: string }) => item.id)
                    .join(',');
            }
            newParams.name = params.saleInfo.name;
            newParams.salePrice = params.saleInfo.couponSalePrice;
            if (params.saleInfo.saleLimitType === 'YES') {
                newParams.rules = [
                    {
                        ruleType: 'EACH_PERIOD',
                        ruleCount: params.saleInfo.saleLimitNum,
                    },
                ];
            }
        }
        if (params.useRulesInfo) {
            newParams.info = params.useRulesInfo.info;
        }

        if (params.categoryInfo) {
            if (params.categoryInfo.categories) {
                newParams.categoryIds = params.categoryInfo.categories
                    .map((item: any) => item.id)
                    .join(',');
            }
        }

        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = `/admin/sale_rules/${id}`;
        let newParams: any = {};
        if (params.baseInfo) {
            newParams.subsiteIds = params.baseInfo.subsites
                .map((item: { id: string; name: string }) => item.id)
                .join(',');
            if (params.baseInfo.dateRange) {
                newParams.startTime = params.baseInfo.dateRange.start;
                newParams.endTime = params.baseInfo.dateRange.end;
            }

            const couponInfo = get(params, 'baseInfo.selectCoupon[0].couponInfo');
            newParams.couponDefinitionId = get(params, 'baseInfo.selectCoupon[0].id');
            if (!!couponInfo && isArray(couponInfo) && couponInfo.length > 0) {
                newParams.packageItemPrices = map(couponInfo, (item) => ({
                    apportionPrice: item.apportionPrice,
                    id: item.couponDefinitionId,
                    quantity: item.quantity,
                }));
            }
        }
        if (params.saleInfo) {
            if (params.saleInfo.couponPicture) {
                newParams.imgIds = params.saleInfo.couponPicture
                    .map((item: { id: string; path: string }) => item.id)
                    .join(',');
            }
            newParams.name = params.saleInfo.name;
            newParams.salePrice = params.saleInfo.couponSalePrice;
            if (params.saleInfo.saleLimitType === 'YES') {
                newParams.rules = [
                    {
                        ruleType: 'EACH_PERIOD',
                        ruleCount: params.saleInfo.saleLimitNum,
                    },
                ];
            }
        }
        if (params.useRulesInfo) {
            newParams.info = params.useRulesInfo.info;
        }
        if (params.categoryInfo) {
            if (params.categoryInfo.categories) {
                newParams.categoryIds = params.categoryInfo.categories
                    .map((item: any) => item.id)
                    .join(',');
            }
        }
        return await api.put(newParams, config);
    },
};

export function getTypeName(type: string) {
    return (
        [
            { id: 'CASH', name: '代金券' },
            { id: 'GIFT', name: '礼品券' },
            { id: 'FREIGHT', name: '运费券' },
            { id: 'PARKING', name: '停车券' },
            { id: 'LOTTERY', name: '抽奖券' },
            { id: 'ENTRANCE', name: '入场券' },
            { id: 'BALANCE', name: '长益余额券' },
            { id: 'EFUTURE_CASH', name: services.language.getText('efutureCash') },
            { id: 'EFUTURE_BALANCE', name: services.language.getText('efutureBalance') },
            { id: 'BFPLAT_COUPON', name: services.language.getText('bfplat') },
        ].find((item) => item.id === type)?.name || ''
    );
}
