import React, { Component, Fragment } from 'react';
import { cloneDeep, debounce, forEach } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { Button, Tabs as AntTabs, Switch, message as AntMessage } from 'antd';
import { api } from '@comall-backend-builder/core/lib/services';
import { CrowdScopeEnum, AutoReplyVo, WechatMsgType } from '../components/wechant-content';
import { WechatOfficialAutoReplySettingPageStoreSelector } from '../components/wechat-official-auto-reply-setting-page-store-selector';
import { WechatOfficialAutoReplySettingPageData } from '../components/wechat-official-auto-reply-setting-page-data';
import { AuthRecordInfo } from '../wechat-official-accounts-auth-list-page';
import './index.less';
import { encodePathToURL } from '../utils';

const AntTabPane = AntTabs.TabPane;

export enum FunctionIdType {
    /**
     * 消息管理
     */
    MESSAGE = '1',
    /**
     * 用户管理
     */
    USER = '2',
    /**
     * 群发与通知
     */
    NOTIFICATION = '7',
    /**
     * 素材管理
     */
    SOURCEMATERIAL = '11',
    /**
     * 账户服务
     */
    ACCOUNT = '3',
    /**
     * 菜单管理
     */
    MENU = '15',
}

export interface WechatOfficialAutoReplySettingPageStates {
    /**
     * 是否是编辑
     */
    isEdit: boolean;
    /**
     * 是否已选择门店
     */
    subsite?: {
        id: number;
        name: string;
    };
    /**
     * 公众号appId
     */
    appId?: string;
    appName?: string;
    /**
     * 是否开关
     */
    switchChecked: boolean;
    /**
     * 回复内容设置
     */
    replyContents: Array<AutoReplyVo>;
    /**
     * 权限不满足提示语
     */
    recordErrorTip: string;
}

export const getAuthRecordErrorTip = async (
    appId: string,
    validFunctions: Array<FunctionIdType>
) => {
    //通过公众号信息得到appId，获取门店是否已经授权可回复消息
    const recordResult: Array<AuthRecordInfo> = await api.get(
        {
            appIds: appId,
        },
        {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
            apiPath: '/admin/wechat_official_accounts/auth_record',
        }
    );
    if (
        recordResult &&
        recordResult.length > 0 &&
        recordResult[0].functions &&
        recordResult[0].functions.length > 0
    ) {
        const noAuthNameList: Array<string> = [];
        recordResult[0].functions.forEach((i: any) => {
            if (validFunctions.includes(i.id) && !i.authStatus) {
                noAuthNameList.push(`【${i.name}】`);
            }
        });
        if (noAuthNameList && noAuthNameList.length > 0) {
            return `${noAuthNameList.join('，')}${services.language.getText('l_qxjwsq_1')}`;
        }
    } else {
        return services.language.getText('l_qxjwsq_1');
    }
    return '';
};

export class WechatOfficialAutoReplySettingPage extends Component<
    any,
    WechatOfficialAutoReplySettingPageStates
> {
    constructor(props: any) {
        super(props);
        this.state = {
            isEdit: false,
            appId: undefined,
            switchChecked: false,
            replyContents: [],
            recordErrorTip: '',
        };
    }

    componentDidMount() {
        this.loadSubsiteInfo();
    }

    /**
     * 获取目前选择的公众号
     */
    loadSubsiteInfo = async () => {
        const subsiteListResult: any = await api.get({}, { apiPath: '/admin/v1/subsites/mine' });
        if (subsiteListResult && subsiteListResult.result && subsiteListResult.result.length > 0) {
            this.setState(
                {
                    subsite: {
                        id: subsiteListResult.result[0].id,
                        name: subsiteListResult.result[0].name,
                    },
                },
                () => {
                    this.loadWechatOfficialAccount();
                }
            );
        }
    };

    /**
     * 获取目前选择的公众号的详细信息，以及是否有支持的权限开通
     */
    loadWechatOfficialAccount = async () => {
        const { subsite } = this.state;
        if (subsite && subsite.id) {
            //1.获取门店对应的公众号信息
            const amountResult: any = await api.get(
                {
                    subsiteId: subsite.id,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                    apiPath: `/admin/wechat_official_accounts/by_subsite_id`,
                }
            );
            if (amountResult && amountResult.appId) {
                this.setState({
                    appId: amountResult.appId,
                    appName: amountResult.appName,
                });
                const validFunctions = [
                    FunctionIdType.MESSAGE,
                    FunctionIdType.USER,
                    FunctionIdType.NOTIFICATION,
                    FunctionIdType.SOURCEMATERIAL,
                ];
                const tip = await getAuthRecordErrorTip(amountResult.appId, validFunctions);
                if (tip) {
                    this.setState({
                        recordErrorTip: tip,
                    });
                } else {
                    this.loadMessages();
                }
            } else {
                this.setState({
                    appId: '',
                    appName: '',
                    replyContents: [],
                });
            }
        }
    };

    /**
     * 获取公众号是否已经配置的自动回复信息
     */
    loadMessages = async () => {
        const { appId } = this.state;
        if (!appId) {
            return;
        }
        const messageResult: any = await api.get(
            { appId: appId, replyType: 'FOLLOWED' },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                apiPath: '/admin/wechat_official_account_reply_messages/by_app_id',
            }
        );
        if (
            messageResult &&
            messageResult.replyContents &&
            messageResult.replyContents.length > 0
        ) {
            this.setState({
                isEdit: true,
                replyContents: messageResult.replyContents.map((content: any) => {
                    content.messageContent = JSON.parse(content.messageContent);
                    return content;
                }),
                switchChecked: messageResult.status ? messageResult.status : false,
            });
        } else {
            //没数据的时候 给一个默认的
            this.setFirst();
        }
    };

    setFirst = () => {
        const { appName } = this.state;
        const { subsite } = this.state;
        const storeName = subsite ? subsite.name : '';
        const content = `${services.language.getText(
            'l_nh'
        )}${appName}!  \n\n${services.language.getText('nh')}${services.language.getText(
            'l_gdfl'
        )}<a href="pages/home/index" data-miniprogram-appid="{miniprogram-appid}" data-miniprogram-path="${encodePathToURL(
            'pages/home/index',
            subsite?.id
        )}">${services.language.getText('hyzc_1')}</a>\n${services.language.getText(
            'l_zsqy'
        )}<a  href="subpackages/member-card-level-rights/index" data-miniprogram-appid="{miniprogram-appid}" data-miniprogram-path="${encodePathToURL(
            'subpackages/member-card-level-rights/index',
            subsite?.id
        )}">${services.language.getText('member_membership')}</a>\n${services.language.getText(
            'l_yhhl'
        )}<a  href="subpackages/coupons-center/index" data-miniprogram-appid="{miniprogram-appid}" data-miniprogram-path="${encodePathToURL(
            'subpackages/coupons-center/index',
            subsite?.id
        )}">${services.language.getText('wdyhq')}</a>\n\n${storeName}，${services.language.getText(
            'qdyndxy'
        )}！`;
        this.setState({
            replyContents: [
                {
                    hide: false,
                    crowdScope: CrowdScopeEnum.ALL,
                    messageContent: [
                        {
                            msgtype: WechatMsgType.TEXT,
                            text: {
                                content: content,
                                targetList: [
                                    {
                                        href: 'pages/home/index',
                                        target: {
                                            linkName: services.language.getText(
                                                'linkTypes.loginStorePage'
                                            ),
                                            linkPath: encodePathToURL(
                                                'pages/home/index',
                                                subsite?.id
                                            ),
                                            linkType: 'loginStorePage',
                                            linkParams: null,
                                        },
                                    },
                                    {
                                        href: 'subpackages/member-card-level-rights/index',
                                        target: {
                                            linkName: services.language.getText('linkTypes.hyqy'),
                                            linkPath: encodePathToURL(
                                                'subpackages/member-card-level-rights/index',
                                                subsite?.id
                                            ),
                                            linkType: 'member.membership',
                                            linkParams: null,
                                        },
                                    },
                                    {
                                        href: 'subpackages/coupons-center/index',
                                        target: {
                                            linkName: services.language.getText('member_coupon'),
                                            linkPath: encodePathToURL(
                                                'subpackages/coupons-center/index',
                                                subsite?.id
                                            ),
                                            linkType: 'member.coupon',
                                            linkParams: null,
                                        },
                                    },
                                ],
                            },
                        },
                        {
                            msgtype: WechatMsgType.MINIPROGRAMPAGE,
                            miniprogrampage: {
                                appid: '{miniprogram-appid}',
                                title: services.language.getText('memberCenterPage'),
                                target: {
                                    linkName: services.language.getText('memberCenterPage'),
                                    linkPath: encodePathToURL(
                                        'pages/member-center/index',
                                        subsite?.id
                                    ),
                                    linkType: 'member',
                                    linkParams: '',
                                },
                                pagepath: encodePathToURL('pages/member-center/index', subsite?.id),
                                thumbMediaId: '',
                                thumbMediaInfo: undefined,
                            },
                        },
                    ],
                },
            ],
            switchChecked: true,
        });
    };

    handleSwitchChange = (checked: boolean) => {
        this.setState(
            {
                switchChecked: checked,
            },
            () => {
                const { appId } = this.state;
                const params = {
                    appId,
                    replyType: 'FOLLOWED',
                };
                const apiPath = checked
                    ? '/admin/wechat_official_account_reply_messages/enabled_by_app_id'
                    : '/admin/wechat_official_account_reply_messages/disabled_by_app_id';

                api.put(params, {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                    apiPath: apiPath,
                }).then(
                    () => {
                        this.setState({
                            switchChecked: checked,
                        });
                        if (checked) {
                            //开启状态走详情接口，获取内容
                            this.loadMessages();
                        }
                    },
                    (error) => {
                        services.errorHandle(error);
                    }
                );
            }
        );
    };

    submitVali = () => {
        const { replyContents } = this.state;
        const length = replyContents.length;
        //1.至少一个回复内容
        if (length === 0) {
            AntMessage.warn(services.language.getText('qtjhfnr'));
            return false;
        }
        //2.判断会员等级有待选择的
        let hasValidate = true;
        let hasContentValidate = true;
        let hasImageValidata = true;
        forEach(replyContents, (c) => {
            if (!c.crowdScope) {
                hasValidate = false;
                return false;
            }
            if (!c.messageContent || c.messageContent.length === 0) {
                hasContentValidate = false;
                return false;
            }
            forEach(c.messageContent, (c) => {
                if (
                    c.msgtype === WechatMsgType.MINIPROGRAMPAGE &&
                    (!c.miniprogrampage || !c.miniprogrampage.thumbMediaId)
                ) {
                    hasImageValidata = false;
                    return false;
                }
            });
        });
        if (!hasValidate) {
            AntMessage.warn(services.language.getText('qxzhfrq'));
            return false;
        }
        if (!hasImageValidata) {
            AntMessage.warn(services.language.getText('qxzxcxkptp'));
            return false;
        }
        //3.判断人群有没有重复的
        const allList = replyContents
            ? replyContents.filter((vo: AutoReplyVo) => vo.crowdScope === CrowdScopeEnum.ALL)
            : [];
        const memberList = replyContents
            ? replyContents.filter((vo: AutoReplyVo) => vo.crowdScope === CrowdScopeEnum.MEMBER)
            : [];
        const noMemberList = replyContents
            ? replyContents.filter((vo: AutoReplyVo) => vo.crowdScope === CrowdScopeEnum.NON_MEMBER)
            : [];
        if (allList.length > 1 || memberList.length > 1 || noMemberList.length > 1) {
            AntMessage.warn(services.language.getText('mlrqzdznszythfnr'));
            return false;
        }
        if (allList.length > 0 && (memberList.length > 0 || noMemberList.length > 0)) {
            AntMessage.warn(services.language.getText('xzlrqwqbfsh_1'));
            return false;
        }
        //4.每个回复人群至少要添加一个回复内容
        if (!hasContentValidate) {
            AntMessage.warn(services.language.getText('mghfrqzsytjyghfnr'));
            return false;
        }
        return true;
    };

    isSubmitReply = false;

    submit = debounce(() => {
        const { isEdit, appId, replyContents } = this.state;
        //判断添加条件
        const vali = this.submitVali();
        if (!vali) {
            return;
        }
        if (this.isSubmitReply) {
            return false;
        }
        this.isSubmitReply = true;
        const newReplyContents = cloneDeep(replyContents);
        const params = {
            appId,
            replyContents: newReplyContents
                ? newReplyContents.map((content: any) => {
                      content.messageContent = JSON.stringify(content.messageContent);
                      return content;
                  })
                : [],
            replyType: 'FOLLOWED',
            status: true,
        };
        if (isEdit) {
            api.put(params, {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                apiPath: '/admin/wechat_official_account_reply_messages/by_app_id',
            })
                .then(
                    () => {
                        this.submitSuccess(services.language.getText('xgcg'));
                    },
                    (error) => {
                        services.errorHandle(error);
                    }
                )
                .finally(() => {
                    setTimeout(() => {
                        this.isSubmitReply = false;
                    }, 3000);
                });
        } else {
            api.post(params, {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT`,
                apiPath: '/admin/wechat_official_account_reply_messages',
            })
                .then(
                    () => {
                        this.submitSuccess(services.language.getText('addSuccess'));
                    },
                    (error) => {
                        services.errorHandle(error);
                    }
                )
                .finally(() => {
                    setTimeout(() => {
                        this.isSubmitReply = false;
                    }, 3000);
                });
        }
    }, 500);

    submitSuccess = (title: string) => {
        AntMessage.success(title);
        setTimeout(() => {
            this.loadMessages();
        }, 2000);
    };

    onChangeReplyDate = (replyData: Array<AutoReplyVo>) => {
        this.setState({
            replyContents: replyData,
        });
    };

    onChangeSubsite = (subsites: any[]) => {
        if (subsites && subsites.length > 0) {
            this.setState(
                {
                    isEdit: false,
                    subsite: {
                        id: subsites[0].id,
                        name: subsites[0].name,
                    },
                    appId: undefined,
                    switchChecked: false,
                    replyContents: [],
                    recordErrorTip: '',
                },
                () => {
                    this.loadWechatOfficialAccount();
                }
            );
        }
    };

    renderTip = () => {
        return (
            <div className="wechat-official-auto-reply-setting-page__tips">
                <div>{services.language.getText('syxz')}</div>
                <div>{services.language.getText('yhgzgzhhjksdhf')}</div>
                <div>2.{services.language.getText('l_jzcyrzdgzh_1')}</div>
                <div>3.{services.language.getText('l_kqgzgzhhfxxs_1')}</div>
            </div>
        );
    };

    renderSwitch = () => {
        const { switchChecked } = this.state;
        return (
            <div className="wechat-official-auto-reply-setting-page__switch">
                <div>{services.language.getText('kqzt')}：</div>
                <Switch
                    checkedChildren={services.language.getText('kai')}
                    unCheckedChildren={services.language.getText('guan')}
                    onChange={this.handleSwitchChange.bind(this)}
                    checked={switchChecked}
                />
            </div>
        );
    };

    renderFooter = () => {
        return (
            <div className="wechat-official-auto-reply-setting-page__footer">
                <Button
                    onClick={this.submit}
                    className="wechat-official-auto-reply-setting-page__footer__button"
                    type="primary"
                >
                    {services.language.getText('bc')}
                </Button>
            </div>
        );
    };

    renderContent = () => {
        const { replyContents, appId } = this.state;
        if (!appId) {
            return null;
        }
        return (
            <div className="repaly-wrap">
                <WechatOfficialAutoReplySettingPageData
                    appId={appId}
                    replyData={replyContents}
                    onChange={this.onChangeReplyDate}
                />
            </div>
        );
    };

    render() {
        const { appName, switchChecked, recordErrorTip, subsite, appId } = this.state;
        return (
            <Fragment>
                <WechatOfficialAutoReplySettingPageStoreSelector
                    appId={appId}
                    appName={appName}
                    currentSubsite={subsite}
                    recordErrorTip={recordErrorTip}
                    onChange={this.onChangeSubsite}
                />
                <div className="wechat-official-auto-reply-setting-page">
                    <AntTabs>
                        <AntTabPane
                            tab={services.language.getText('followReplyMessage')}
                            key="reply"
                        >
                            {this.renderTip()}
                            {appName && !recordErrorTip && (
                                <div>
                                    {this.renderSwitch()}
                                    {switchChecked && this.renderContent()}
                                    {switchChecked && this.renderFooter()}
                                </div>
                            )}
                        </AntTabPane>
                    </AntTabs>
                </div>
            </Fragment>
        );
    }
}
