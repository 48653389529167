import React, { Component } from 'react';
import { api } from '@comall-backend-builder/core/lib/services';

import './index.less';
import { services } from '@comall-backend-builder/core';

interface BiDatartPageState {
    absolutePath?: string;
}

export class BiDatartPage extends Component<{}, BiDatartPageState> {
    componentDidMount() {
        this.loadData();
    }
    loadData = () => {
        const apiRoot = ENV.API_ROOT.replace('/WEB-API', '/MAGIC-SOURCE-DATART');
        const apiPath = '/auth_pass_on';
        api.get(
            {},
            {
                apiRoot,
                apiPath,
            }
        ).then((res: any) => {
            this.setState({ absolutePath: res.absolutePath });
            window.open(res.absolutePath, '_blank');
        });
    };
    goDatart = () => {
        const { absolutePath } = this.state || {};
        if (absolutePath) {
            window.open(absolutePath, '_blank');
        }
    };
    render() {
        const image = require('./bi-datart-image.png');
        return (
            <div className="bi-datart-page">
                <img src={image} className="bi-datart-image" alt="" />
                <div onClick={this.goDatart} className="bi-datart-button">
                    {services.language.getText('biDatart')}
                </div>
                <div className="bi-datart-tips">{services.language.getText('openBiDatart')}</div>
            </div>
        );
    }
}
