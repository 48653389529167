import React, { PureComponent } from 'react';
import { CouponSelectedDisplay } from '../../components';
import { Coupon } from '../select-coupon';

export class DisplayCoupon extends PureComponent<
    any,
    {
        value: Array<Coupon>;
    }
> {
    state = { value: new Array<Coupon>() };

    render() {
        const { row } = this.props;
        let value: any = [];
        if (row.couponInfo && row.couponInfo.selectCoupon) {
            value = row.couponInfo.selectCoupon;
        }
        return (
            <div>
                <CouponSelectedDisplay data={value} onRemove={() => {}}></CouponSelectedDisplay>
            </div>
        );
    }
}
