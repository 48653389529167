import { connect } from 'react-redux';
import React, { PureComponent } from 'react';
import { errorHandle } from '../../applications/cae/services/error-handle';
import { services } from '@comall-backend-builder/core';
import './index.less';

const api = services.api;

export interface CgbRecruitLeaderCodeImage {
    image: string | null;
}

export class CgbRecruitLeaderCode extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);

        this.state = {
            codeImage: '',
        };
    }

    componentDidMount() {
        this.loadImage();
    }

    loadImage = () => {
        return api
            .get<CgbRecruitLeaderCodeImage>(
                {
                    scene: 'type=leader',
                    page: 'sub-packages/cgb/pages/cgb-recruit-leader/index',
                    type: 'RECRUIT_LEADER',
                },
                {
                    apiRoot: `${ENV.API_ROOT}`,
                    apiPath: '/wechat/jhcode',
                }
            )
            .then((data) => {
                if (data) {
                    const result = data.image;
                    const wxaCodeimg = 'data:image/jpg;base64,' + result;
                    this.setState({
                        codeImage: wxaCodeimg,
                    });
                }
            }, errorHandle);
    };

    render() {
        const { codeImage } = this.state;
        return (
            <div className="cgb-recruit-leader-code-view">
                {codeImage && (
                    <div>
                        <div className="title">{services.language.getText('wxCode')}：</div>
                        <div>
                            <img
                                className="code-img"
                                width="200"
                                alt={services.language.getText('wxCode')}
                                height="200"
                                src={codeImage}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

export const CgbRecruitLeaderCodeContainer = connect()(CgbRecruitLeaderCode);
