import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';
// import { EntityButtonProps } from '../../../components';
// import { api } from '@comall-backend-builder/core/lib/services';
// import { ForwardDataCenterModal } from '../../../services';
// import { services } from '@comall-backend-builder/core';
// import { isEmpty } from 'lodash';

export const config: Config = {
    entities: {
        CouponManageEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
            apiPath: '/loader/admin/coupon_manage',
            filters: {
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                    controlConfig: {
                        placeholder: '<<qsrhysjh>>',
                        style: { width: 200 },
                    },
                },
                couponNo: {
                    type: 'string',
                    displayName: '<<couponNum>>',
                    controlConfig: {
                        placeholder: '<<qsrqh>>',
                        style: { width: 200 },
                    },
                },
                name: {
                    type: 'string',
                    displayName: '<<couponName>>',
                    controlConfig: {
                        placeholder: '<<qsryhqmc>>',
                        style: { width: 200 },
                    },
                },
                // type: {
                //     type: 'string.options.select',
                //     displayName: '<<couponType>>',
                //     options: [
                //         { id: 'ALL', name: '<<all>>' },
                //         { id: 'CASH', name: '<<cashCoupon>>' },
                //         { id: 'GIFT', name: '礼品券' },
                //         { id: 'PARKING', name: '停车券' },
                //         { id: 'FREIGHT', name: '运费券' },
                //     ],
                //     controlConfig: {
                //         placeholder: '<<selectPlease>>',
                //         style: { width: 158 },
                //     },
                // },
                // subsiteIds: {
                //     type: 'string.options.autoComplete',
                //     displayName: '<<applicableSubsite>>',
                //     options: [],
                //     source: {
                //         apiPath: '/admin/defaults/subsite',
                //     },
                //     controlConfig: {
                //         placeholder: '<<selectPlease>>',
                //         style: { width: 200 },
                //     },
                // },
                // merchantIds: {
                //     displayName: '<<applyMerchants>>',
                //     type: 'string.filterOptions.autoComplete',
                //     options: [],
                //     source: {
                //         apiPath: '/loader/admin/subsites/mine/merchants',
                //         apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                //         loadOnInit: false,
                //     },
                //     controlConfig: {
                //         placeholder: '<<selectPlease>>',
                //         style: { width: 200 },
                //         dependences: 'subsiteIds',
                //         paramsName: 'subSiteName',
                //         needDependences: true,
                //         needDependencesMessage: '<<selectBeforeMerchant>>',
                //     },
                // },
                // bindingDateRange: {
                //     type: 'object.dateRangePlus',
                //     displayName: '绑定时间',
                //     controlConfig: {
                //         style: { width: 300 },
                //     },
                // },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
                crmId: {
                    type: 'string',
                    displayName: '<<crmId>>',
                },
                couponNo: {
                    type: 'string',
                    displayName: '<<couponNum>>',
                },
                type: {
                    type: 'string.options.select',
                    displayName: '<<couponType>>',
                    options: [
                        { id: 'CASH', name: '<<cashCoupon>>' },
                        { id: 'GIFT', name: '<<gift>>' },
                        { id: 'PARKING', name: '<<parking>>' },
                        { id: 'FREIGHT', name: '<<freight>>' },
                        { id: 'BALANCE', name: '<<balance>>' },
                        { id: 'EFUTURE_CASH', name: '<<efutureCash>>' },
                        { id: 'EFUTURE_BALANCE', name: '<<efutureBalance>>' },
                        { id: 'BFPLAT_COUPON', name: '<<bfplat>>' },
                    ],
                },
                name: {
                    type: 'string',
                    displayName: '<<couponName>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<yhqzt>>',
                    options: [
                        { id: 'ENABLED', name: '<<dsy>>' },
                        { id: 'USED', name: '<<ysy_1>>' },
                        { id: 'INVALID', name: '<<ysx>>' },
                        { id: 'AVAILABLE', name: '<<zzsxqkydq>>' },
                        { id: 'EXPIRED', name: '<<ygq>>' },
                        { id: 'EXPIRING', name: '<<jjgq>>' },
                        { id: 'UNBOUND', name: '<<dbd>>' },
                        { id: 'FREEZE', name: '<<ydj>>' },
                        { id: 'TRANSFERRING', name: '<<zsz_1>>' },
                    ],
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<applicableSubsite>>',
                },
                merchants: {
                    type: 'array.popover',
                    displayName: '<<applyMerchants>>',
                },
                bindingTime: {
                    type: 'string',
                    displayName: '<<bindingTime>>',
                },
                endTime: {
                    type: 'string',
                    displayName: '<<sxsj>>',
                },
                lastModifiedTime: {
                    type: 'string',
                    displayName: '<<czsj>>',
                },
                reason: {
                    type: 'string',
                    displayName: '<<reason>>',
                },
                lastModifiedUser: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
            },
        },
    },
    components: {
        CouponManageView: {
            component: 'Viewport',
            entity: 'CouponManageEntity',
        },
        CouponManagePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'CouponManageFilter',
                },
                {
                    component: 'GridLayout',
                    style: { display: 'flex', marginBottom: 12 },
                    className: 'mt24',
                    items: [
                        {
                            component: 'PromotionCouponManageImport',
                        },
                        // {
                        //     component: 'EntityButton',
                        //     props: {
                        //         type: 'primary',
                        //         children: '导出优惠券数据',
                        //         style: {
                        //             marginLeft: 10,
                        //         },
                        //         onClick: (_: any, entityButtonProps: EntityButtonProps) => {
                        //             const params: any = Object.assign(
                        //                 {},
                        //                 entityButtonProps.entity.params,
                        //                 entityButtonProps.entity.filters
                        //             );
                        //             const config = {
                        //                 apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                        //                 apiPath: '/admin/coupons/export.xlsx',
                        //             };

                        //             if (!isEmpty(params.bindingDateRange)) {
                        //                 params.bindingStartTime =
                        //                     params.bindingDateRange.start + ' 00:00:00';
                        //                 params.bindingEndTime =
                        //                     params.bindingDateRange.end + ' 23:59:59';
                        //                 delete params.bindingDateRange;
                        //             }
                        //             if (params.couponStatus === 'ALL') {
                        //                 delete params.couponStatus;
                        //             }
                        //             if (params.type === 'ALL') {
                        //                 delete params.type;
                        //             }

                        //             params.couponRuleId = params.id;
                        //             params.source = 'ONLINE';
                        //             params.subsiteIds = params?.subsiteIds?.id || '';
                        //             params.merchantIds = params?.merchantIds?.id || '';
                        //             delete params.id;
                        //             api.get(params, config)
                        //                 .then(() => {
                        //                     ForwardDataCenterModal();
                        //                 })
                        //                 .catch((error) => {
                        //                     services.errorHandle(error);
                        //                 });
                        //         },
                        //     },
                        // },
                    ],
                },
                { component: 'CouponManageTable' },
                // { component: 'CouponManageTables' },
            ],
        },
        CouponManageFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'mobile',
                },
                {
                    property: 'couponNo',
                },
                {
                    property: 'name',
                },
                // {
                //     property: 'type',
                // },
                // {
                //     property: 'subsiteIds',
                // },
                // {
                //     property: 'merchantIds',
                // },
                // {
                //     property: 'bindingDateRange',
                // },
            ],
        },
        /* CouponManageTables: {
            component: 'TabsPlus',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<all>>',
                    content: {
                        component: 'CouponManageTable',
                    },
                    params: { couponStatus: 'ALL' },
                },
                {
                    title: '待使用',
                    content: {
                        component: 'CouponManageTable',
                    },
                    params: { couponStatus: 'ENABLED' },
                },
                {
                    title: '已使用',
                    content: {
                        component: 'CouponManageTable',
                    },
                    params: { couponStatus: 'USED' },
                },
                {
                    title: '已失效',
                    content: {
                        component: 'CouponManageTable',
                    },
                    params: { couponStatus: 'INVALID' },
                },
                {
                    title: '已过期',
                    content: {
                        component: 'CouponManageTable',
                    },
                    params: { couponStatus: 'EXPIRED' },
                },
            ],
        }, */
        CouponManageTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: false,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: { x: true },
                    columns: [
                        { property: 'mobile' },
                        { property: 'crmId' },
                        { property: 'couponNo' },
                        { property: 'type' },
                        {
                            property: 'name',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '420px',
                                },
                            },
                        },
                        { property: 'status' },
                        { property: 'subsites' },
                        { property: 'merchants' },
                        { property: 'bindingTime' },
                        { property: 'endTime' },
                        { property: 'lastModifiedTime' },
                        { property: 'lastModifiedUser' },
                        {
                            property: 'reason',
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '250px',
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        fixed: 'right',
                        width: 100,
                        items: [
                            {
                                type: 'component',
                                component: 'CouponManageListButton',
                            },
                        ],
                    },
                    locale: { emptyText: '<<qsrsstjjxsx>>' },
                },
            ],
        },
        CouponManageInfoPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'CouponManageEntity',
            loaderType: 'get',
            items: [
                {
                    component: 'CouponManageInfo',
                },
            ],
        },
        CouponManageApplyPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'CouponManageApplyForm',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/coupon/coupon-manage',
                    component: 'CouponManageView',
                    breadcrumbName: '<<couponManage>>',
                    privilege: {
                        path: 'couponManager',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'CouponManagePage' }],
                },
            ],
        },
    ],
};
