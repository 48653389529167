import { api, language, navigation } from '@comall-backend-builder/core/lib/services';
import { Button } from '@comall-backend-builder/components-basis';
import { message as AntMessage, Row as AntRow, Col as AntCol, Select, Input } from 'antd';
import React, { Component } from 'react';
import { ShopSelectedDisplayMode } from '../../components';
import { Shop } from '../../containers';
import { cloneDeep } from 'lodash';

import { SelectShop } from '../../containers';
import { connect } from 'react-redux';

import './index.less';
import { services } from '@comall-backend-builder/core';

const { Option } = Select;

interface PictureVo {
    id: number;
    url: string;
}
export interface GuidesApplyRecordsEditProps {
    params: any;
    config: any;
}

export interface GuidesApplyRecordsEditStates {
    data: any | null;
}

class guidesApplyRecordsEditForm extends Component<
    GuidesApplyRecordsEditProps,
    GuidesApplyRecordsEditStates
> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: null,
        };
    }

    componentDidMount() {
        const { params } = this.props;
        const id = params.id;
        //获取详细信息
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: `/admin/guides/wechatwork/apply_user_records/${id}`,
            }
        ).then((response: any) => {
            response.shop = {
                id: response.merchant.merchantId,
                subSiteId: response.merchant.subsiteId,
                subSiteName: response.merchant.subsiteName,
                merchantId: response.merchant.merchantId,
                merchantName: response.merchant.merchantName,
                merchantType: response.merchant.merchantType,
                merchantStatus: response.merchant.merchantStatus,
            };
            response.dataMerchants = response.dataMerchants
                ? response.dataMerchants.map((m: any) => {
                      return {
                          ...m,
                          id: `${m.subsiteId},${m.merchantId}`,
                          subSiteId: m.subsiteId,
                          subSiteName: m.subsiteName,
                          merchantId: m.merchantId,
                          merchantName: m.merchantName,
                          merchantType: m.merchantType,
                          merchantStatus: m.merchantStatus,
                      };
                  })
                : [];
            this.setState({
                data: response,
            });
        });
    }

    onFiledChange(filed: string, e: any) {
        const { data } = this.state;
        if (filed === 'roleType') {
            data[filed] = e;
        } else {
            data[filed] = e.target.value;
        }
        this.setState({
            data,
        });
    }

    onMerchantChange = (merchants: Shop[]) => {
        const { data } = this.state;
        if (merchants && merchants.length > 0) {
            data.shop = merchants[0];
        } else {
            data.shop = null;
        }
        this.setState({
            data,
        });
    };

    onDataMerchantsChange = (merchants: Shop[]) => {
        const { data } = this.state;
        if (merchants && merchants.length > 0) {
            data.dataMerchants = merchants;
        } else {
            data.dataMerchants = [];
        }
        this.setState({
            data,
        });
    };

    // 审核
    submit(auditStatus: 'SUCCESS' | 'FAIL') {
        const { data } = this.state;
        if (!data) {
            return;
        }
        if (auditStatus === 'SUCCESS') {
            let reg = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,4})$/;
            if (!data.email || !reg.test(data.email)) {
                AntMessage.warning('请正确填写邮箱');
                return;
            }
            reg = /^.{2,10}$/;
            if (!data.name || !reg.test(data.name)) {
                AntMessage.warning('请正确填写姓名');
                return;
            }
            if (!data.shop || data.shop.length === 0) {
                AntMessage.warning(language.getText('pleaseSelectMerchant'));
                return;
            }
            if (!data.shop || data.shop.length === 0) {
                AntMessage.warning('请选择隶属专柜');
                return;
            }
            if (!data.dataMerchants || data.dataMerchants.length === 0) {
                AntMessage.warning('请选择数据专柜');
                return;
            }
            const params = cloneDeep(data);
            params.auditStatus = auditStatus;
            params.subsiteId = data.shop.subSiteId;
            params.merchantId = data.shop.merchantId;
            params.dataMerchants = params.dataMerchants.map((m: any) => {
                return {
                    merchantId: m.merchantId,
                    subsiteId: m.subSiteId,
                };
            });
            delete params.shop;
            delete params.subsite;
            delete params.createTime;
            api.put(params, {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: `/admin/guides/wechatwork/apply_user_records/audit_status`,
            })
                .then(() => {
                    AntMessage.success(services.language.getText('shtgcg'));
                    navigation.goBack();
                })
                .catch((err) => {
                    const {
                        body: { err_msg },
                    } = err.response;
                    if (err_msg) {
                        AntMessage.warning(err_msg);
                    }
                });
        } else {
            if (!data.auditRemark) {
                AntMessage.warning('请填写原因备注');
                return;
            }
            const params = {
                id: data.id,
                auditRemark: data.auditRemark,
                auditStatus,
            };
            api.put(params, {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: `/admin/guides/wechatwork/apply_user_records/audit_status`,
            })
                .then(() => {
                    AntMessage.success('拒绝审核通过成功');
                    navigation.goBack();
                })
                .catch((err) => {
                    const {
                        body: { err_msg },
                    } = err.response;
                    if (err_msg) {
                        AntMessage.warning(err_msg);
                    }
                });
        }
    }

    render() {
        const { data } = this.state;
        const { config } = this.props;
        const readOnly = config && config.readOnly ? config.readOnly : false;
        return data ? (
            <div className="guides-apply-records-edit-form">
                <AntRow className="guides-apply-records-edit-form-row" style={{ display: 'flex' }}>
                    <AntCol style={{ textAlign: 'end', marginRight: 15 }} span={4}>
                        员工编码:
                    </AntCol>
                    <AntCol span={8}>
                        <Input
                            disabled={readOnly}
                            onInput={this.onFiledChange.bind(this, 'guideCode')}
                            value={data.guideCode}
                        />
                    </AntCol>
                </AntRow>
                <AntRow className="guides-apply-records-edit-form-row" style={{ display: 'flex' }}>
                    <AntCol style={{ textAlign: 'end', marginRight: 15 }} span={4}>
                        <span className="guides-apply-records-edit-form-required">*</span>登录账号:
                    </AntCol>
                    <AntCol span={8}>
                        <Input disabled value={data.loginName} />
                    </AntCol>
                </AntRow>
                <AntRow className="guides-apply-records-edit-form-row" style={{ display: 'flex' }}>
                    <AntCol style={{ textAlign: 'end', marginRight: 15 }} span={4}>
                        <span className="guides-apply-records-edit-form-required">*</span>邮箱:
                    </AntCol>
                    <AntCol span={8}>
                        <Input
                            onInput={this.onFiledChange.bind(this, 'email')}
                            value={data.email}
                            disabled={readOnly}
                        />
                    </AntCol>
                </AntRow>
                <AntRow className="guides-apply-records-edit-form-row" style={{ display: 'flex' }}>
                    <AntCol style={{ textAlign: 'end', marginRight: 15 }} span={4}>
                        <span className="guides-apply-records-edit-form-required">*</span>用户全名:
                    </AntCol>
                    <AntCol span={8}>
                        <Input
                            disabled={readOnly}
                            onInput={this.onFiledChange.bind(this, 'name')}
                            value={data.name}
                        />
                    </AntCol>
                </AntRow>
                <AntRow className="guides-apply-records-edit-form-row" style={{ display: 'flex' }}>
                    <AntCol style={{ textAlign: 'end', marginRight: 15 }} span={4}>
                        <span className="guides-apply-records-edit-form-required">*</span>身份记录:
                    </AntCol>
                    <AntCol span={8}>
                        <Select
                            disabled={readOnly}
                            value={data.roleType}
                            style={{ width: '200px' }}
                            onChange={this.onFiledChange.bind(this, 'roleType')}
                        >
                            <Option key="SHOP_ASSISTANT" value="SHOP_ASSISTANT">
                                {services.language.getText('dy')}
                            </Option>
                            <Option key="SHOP_MANAGER" value="SHOP_MANAGER">
                                {services.language.getText('dz')}
                            </Option>
                        </Select>
                    </AntCol>
                </AntRow>
                <AntRow className="guides-apply-records-edit-form-row" style={{ display: 'flex' }}>
                    <AntCol style={{ textAlign: 'end', marginRight: 15 }} span={4}>
                        <span className="guides-apply-records-edit-form-required">*</span>
                        {services.language.getText('phoneNum')}:
                    </AntCol>
                    <AntCol span={8}>
                        <Input disabled value={data.mobile} />
                    </AntCol>
                </AntRow>
                <AntRow className="guides-apply-records-edit-form-row">
                    <AntCol style={{ textAlign: 'end', marginRight: 15, paddingTop: 5 }} span={4}>
                        {services.language.getText('lszj')}:
                    </AntCol>
                    <AntCol span={15}>
                        <SelectShop
                            value={data.shop ? [data.shop] : []}
                            disabled={readOnly}
                            type="radio"
                            displayMode={ShopSelectedDisplayMode.SINGLE}
                            onChange={this.onMerchantChange}
                        />
                    </AntCol>
                </AntRow>
                <AntRow className="guides-apply-records-edit-form-row">
                    <AntCol style={{ textAlign: 'end', marginRight: 15, paddingTop: 5 }} span={4}>
                        {services.language.getText('sjzj')}:
                    </AntCol>
                    <AntCol span={15}>
                        <SelectShop
                            value={data.dataMerchants}
                            disabled={readOnly}
                            type={undefined}
                            onChange={this.onDataMerchantsChange}
                        />
                    </AntCol>
                </AntRow>
                <AntRow className="guides-apply-records-edit-form-row">
                    <AntCol style={{ textAlign: 'end', marginRight: 15, paddingTop: 5 }} span={4}>
                        {services.language.getText('yybz')}:
                    </AntCol>
                    <AntCol span={8}>
                        <textarea
                            value={data.auditRemark}
                            disabled={readOnly}
                            className="guides-apply-records-textarea"
                            onInput={this.onFiledChange.bind(this, 'auditRemark')}
                            rows={3}
                            placeholder={language.getText('yyzzlt')}
                        ></textarea>
                    </AntCol>
                </AntRow>
                <div style={{ textAlign: 'center', marginTop: '30px' }}>
                    {!readOnly && (
                        <Button
                            type="primary"
                            onClick={this.submit.bind(this, 'SUCCESS')}
                            style={{ marginRight: 24 }}
                        >
                            {language.getText('approved')}
                        </Button>
                    )}
                    {!readOnly && (
                        <Button
                            type="primary"
                            onClick={this.submit.bind(this, 'FAIL')}
                            style={{ marginRight: 24 }}
                        >
                            {services.language.getText('shbtg')}
                        </Button>
                    )}
                    <Button route="goBack">{services.language.getText('back')}</Button>
                </div>
            </div>
        ) : null;
    }
}
function mapStateToProps(_state: any, props: any) {
    const { params } = props;
    return {
        params,
    };
}
export const GuidesApplyRecordsEditForm = connect(mapStateToProps)(guidesApplyRecordsEditForm);
