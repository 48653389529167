import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { api, ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { map } from 'lodash';

export const BalanceDescriptionLoader: Loader = {
    async get(params: any, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/articles/AMOUNT_ACCOUNTS';
        const { id } = params;
        if (id) {
            config.apiPath = `/admin/articles/AMOUNT_ACCOUNTS/${id}`;
        }
        return api.get(params, config).then((res: any) => {
            if (!id) {
                res.result = res.result.map((item: any) => ({
                    ...item,
                    subsiteName: item.subsites,
                }));
            }
            return res;
        });
    },
    async delete(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/articles/AMOUNT_ACCOUNTS/${id}`;
        return api.delete(params, config);
    },
    async post(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/articles/AMOUNT_ACCOUNTS';
        if (params.subsites) {
            params.subsiteIds = map(params.subsites, 'id').toString();
            delete params.subsites;
        }
        return api.post(params, config);
    },
    async put(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/articles/AMOUNT_ACCOUNTS/${id}`;
        if (params.subsites) {
            params.subsiteIds = map(params.subsites, 'id').toString();
            delete params.subsites;
        }
        delete params.id;
        return api.put(params, config);
    },
};
