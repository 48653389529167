import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
export const PointDeductionProductLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = '/admin/deduction_product';
        const res: any = await api.get(data, config);
        res.result.forEach((item: any) => {
            item.barcode = item.code;
            item.brandName = item.brandVo?.name;
        });

        return res;
    },
    delete: async function(params: any, config: any) {
        let ids = '';
        if (params.id) {
            ids = params.id;
        } else {
            ids = params.ids.join(',');
        }
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = `/admin/deduction_product?ids=${ids}`;
        return await api.delete(params, config);
    },
};
