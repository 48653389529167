import React, { PureComponent } from 'react';
import { Row, Col, Form, Checkbox, Radio } from 'antd';
import { tools } from '@comall-backend-builder/design-components-basis';
import './index.less';

const FormItem = Form.Item;
/**
 * 会员资产组件-配置表单
 */
class memberAssets extends PureComponent<any> {
    render() {
        const { form } = this.props;

        const { getFieldDecorator } = form;
        const formItemLayout = tools.FormItemLayout;
        const { showContent } = this.props.value;
        const overMaxLength = showContent.length >= 3;
        return (
            <Form className="editor-member-assets">
                <FormItem {...formItemLayout} label={'展示样式'}>
                    {getFieldDecorator('type', { initialValue: '1' })(
                        <Radio.Group>
                            <Radio value={'1'}>样式一</Radio>
                            <Radio value={'2'}>样式二</Radio>
                        </Radio.Group>
                    )}
                </FormItem>
                <FormItem {...formItemLayout} label={'展示内容'}>
                    {getFieldDecorator('showContent', {
                        initialValue: [],
                    })(
                        <Checkbox.Group style={{ width: '100%' }}>
                            <Row className="show-content">
                                {ENV.TENANT !== 'wfj' && (
                                    <Col span={24}>
                                        <Checkbox
                                            disabled={
                                                overMaxLength && !showContent.includes('balance')
                                            }
                                            value="balance"
                                        >
                                            余额
                                        </Checkbox>
                                    </Col>
                                )}
                                <Col span={24}>
                                    <Checkbox
                                        disabled={
                                            overMaxLength && !showContent.includes('integral')
                                        }
                                        value="integral"
                                    >
                                        积分
                                    </Checkbox>
                                </Col>
                                <Col span={24}>
                                    <Checkbox
                                        disabled={overMaxLength && !showContent.includes('coupon')}
                                        value="coupon"
                                    >
                                        优惠券
                                    </Checkbox>
                                </Col>
                                <Col span={24}>
                                    <Checkbox
                                        disabled={overMaxLength && !showContent.includes('card')}
                                        value="card"
                                    >
                                        电子礼品卡
                                    </Checkbox>
                                </Col>
                                <Col span={24}>
                                    <Checkbox
                                        disabled={overMaxLength && !showContent.includes('level')}
                                        value="level"
                                    >
                                        会员等级
                                    </Checkbox>
                                </Col>
                                {(ENV.TENANT === 'hxgc' ||
                                    ENV.TENANT === 'localhost' ||
                                    ENV.TENANT === 'magic') && (
                                    <Col span={24}>
                                        <Checkbox
                                            disabled={
                                                overMaxLength && !showContent.includes('reward')
                                            }
                                            value="reward"
                                        >
                                            Hi贝
                                        </Checkbox>
                                    </Col>
                                )}
                            </Row>
                        </Checkbox.Group>
                    )}
                </FormItem>
            </Form>
        );
    }
}

export const MemberAssets = tools.ConfigFormDecorator(memberAssets);
