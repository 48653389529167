import * as React from 'react';
import classNames from 'classnames';
import { ArrayMode } from '@comall-backend-builder/types';
import { CreditShopMemberLimit } from '../../../components';

export class ArrayCreditShopMemberLimitMode extends ArrayMode {
    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            '暂无数据'
        );
    }
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <CreditShopMemberLimit {...controlInfo} />;
    }
}
