import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { defaults, get, isArray, forEach, mapValues } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { checkLinkTypeParamsRequired } from '../../../types/format';
import './index.less';

export interface OpenBoxCopyFormProps extends FormComponentProps {
    editExecuteStatus: string;
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
}

class openBoxCopyFormView extends PureComponent<OpenBoxCopyFormProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }
    render() {
        const { entity, onSubmit, wrappedComponentRef } = this.props;
        let fields = [
            {
                property: 'editExecuteStatus',
                className: 'property-hide',
            },
            {
                property: 'pageShowId',
                className: 'property-hide',
            },
            {
                property: 'shareConfigId',
                className: 'property-hide',
            },
            { property: 'baseInfo.name' },
            {
                property: 'baseInfo.dateRange',
                controlConfig: {
                    style: {
                        width: 450,
                    },
                },
            },
            {
                property: 'baseInfo.subsiteIds',
                controlConfig: {
                    style: {
                        width: 450,
                    },
                },
            },
            { property: 'baseInfo.marketingSchedule' },
            { property: 'ruleInfo.activityRewardLimit' },
            { property: 'ruleInfo.activityDrawLimit' },
            { property: 'ruleInfo.inviteFriends' },
            { property: 'ruleInfo.excludeTypeActivity' },
            { property: 'ruleInfo.periodLimit' },
            { property: 'pictureInfo.activityPicture' },
            { property: 'pictureInfo.rulePictureIcon' },
            { property: 'pictureInfo.rulePicture' },
            { property: 'pictureInfo.audioUrl' },
            {
                property: 'pictureInfo.audioOpenPicture',
                visible: (values: any) =>
                    get(values, 'pictureInfo.audioUrl') &&
                    get(values, 'pictureInfo.audioUrl')?.length,
            },
            {
                property: 'pictureInfo.audioClosePicture',
                visible: (values: any) =>
                    get(values, 'pictureInfo.audioUrl') &&
                    get(values, 'pictureInfo.audioUrl')?.length,
            },
            { property: 'pictureInfo.backgroundPicture' },
            {
                property: 'pictureInfo.giftBoxPicture',
            },
            { property: 'pictureInfo.giftBoxCartoonPicture' },
            { property: 'pictureInfo.tipColor' },
            {
                property: 'pictureInfo.buttonBackgroundStyle',
            },
            {
                property: 'pictureInfo.buttonBackgroundColor',
                visible: (values: any) =>
                    get(values, 'pictureInfo.buttonBackgroundStyle') === 'NONE',
            },
            {
                property: 'pictureInfo.buttonBackgroundPicture',
                visible: (values: any) =>
                    get(values, 'pictureInfo.buttonBackgroundStyle') === 'BACKGROUND_URL',
            },
            { property: 'pictureInfo.buttonColor' },
            { property: 'pictureInfo.sharePicture' },
            { property: 'pictureInfo.shareContent' },
            { property: 'pictureInfo.winningRecordPicture' },
            { property: 'pictureInfo.moreActivityPicture' },
            { property: 'pictureInfo.target' },
            {
                property: 'prizeInfo.assetRewards',
                className: 'prize-info-asset-rewards',
                controlConfig: {
                    eventType: 'OPEN_BOX',
                },
            },
            {
                property: 'prizeInfo.finishWithNoPrize',
                visible: (values: any) => {
                    const assetRewards = get(values, 'prizeInfo.assetRewards') || [];
                    const findNoneReward = assetRewards.filter(
                        (item: any) => item.type.checked === 'NONE'
                    );
                    if (findNoneReward.length > 0) {
                        return true;
                    }
                    return false;
                },
            },
        ];

        let formProps = {
            entity,
            componentId: 'OpenBoxCopyFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'open-box-copy-form-container',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            fields: fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: services.language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { entity, onSubmit, onFieldChange, params } = props;
    let form: any;

    let editExecuteStatus = get(
        _dispatch,
        'components.OpenBoxCopyFormContainer.fields.editExecuteStatus'
    );
    const onValidate = (entityFields: any) => {
        //抽奖次数校验
        const activityRewardLimit = get(entityFields, 'ruleInfo.activityRewardLimit', null);
        const inviteFriends = get(entityFields, 'ruleInfo.inviteFriends', null);
        if (activityRewardLimit === 0 && (!inviteFriends || inviteFriends.length === 0)) {
            AntMessage.warning(services.language.getText('yhzcjcsw'));
            return false;
        }
        //额外奖励校验
        const activityDrawLimit = get(entityFields, 'ruleInfo.activityDrawLimit', null);
        if (
            activityDrawLimit &&
            activityDrawLimit.normalConditionType &&
            !activityDrawLimit.exchangeLimit
        ) {
            AntMessage.warning(services.language.getText('qtxyhewkhddcjcs'));
            return false;
        }
        if (inviteFriends && inviteFriends.length > 0) {
            let hasValidate = true;
            let maxInviteValue = 0;
            let maxRewardValue = 0;
            forEach(inviteFriends, (reward: any) => {
                if (reward.inviteValue) {
                    if (reward.inviteValue > maxInviteValue) {
                        maxInviteValue = reward.inviteValue;
                    } else {
                        AntMessage.warning(services.language.getText('byxxjjtyqcs'));
                        hasValidate = false;
                        return false;
                    }
                } else {
                    AntMessage.warning(services.language.getText('qtxyqcs'));
                    hasValidate = false;
                    return false;
                }
                if (reward.rewardValue) {
                    if (reward.rewardValue > maxRewardValue) {
                        maxRewardValue = reward.rewardValue;
                    } else {
                        AntMessage.warning(services.language.getText('byxxjjtyqcs'));
                        hasValidate = false;
                        return false;
                    }
                } else {
                    AntMessage.warning(services.language.getText('qtxjtrs'));
                    hasValidate = false;
                    return false;
                }
            });
            if (!hasValidate) {
                return;
            }
        }
        //活动校验
        const excludeTypeActivity = get(entityFields, 'ruleInfo.excludeTypeActivity', null);
        if (
            excludeTypeActivity.type === 'SOME' &&
            (!excludeTypeActivity.activityList || excludeTypeActivity.activityList.length === 0)
        ) {
            AntMessage.warning(services.language.getText('qxzhd'));
            return false;
        }
        //中奖次数校验
        const periodLimit = get(entityFields, 'ruleInfo.periodLimit', null);
        if (
            periodLimit &&
            periodLimit?.list &&
            periodLimit.list.length &&
            !periodLimit.list[0].limit
        ) {
            AntMessage.warning(services.language.getText('qtxzdzjcs'));
            return false;
        }
        //链接类型校验
        const moreActivityPicture = get(entityFields, 'pictureInfo.moreActivityPicture', null);
        const target = get(entityFields, 'pictureInfo.target', null);
        if (moreActivityPicture && moreActivityPicture.length > 0) {
            if (!target || !target.linkType) {
                AntMessage.warning(services.language.getText('qxzljlx'));
                return false;
            }
            if (target && !checkLinkTypeParamsRequired(target.linkType, target.linkParams)) {
                AntMessage.warning(services.language.getText('selectLinkTarget'));
                return false;
            }
        } else {
            if (target && target.linkType) {
                AntMessage.warning(services.language.getText('qscgdhdxct'));
                return false;
            }
        }
        //奖品校验
        const assetRewards = get(entityFields, 'prizeInfo.assetRewards', null);
        if (!assetRewards || !isArray(assetRewards) || assetRewards.length <= 0) {
            AntMessage.warning(services.language.getText('qtjjp'));
            return false;
        }
        if (assetRewards) {
            //验证中奖率100%
            let conditionValue = 0;
            forEach(assetRewards, (item: any) => {
                if (item.status === 'ENABLED') {
                    conditionValue += parseFloat(item.conditionValue);
                }
            });
            if (Number(conditionValue.toFixed(2)) !== 100) {
                AntMessage.warning(services.language.getText('jpzjglbzq'));
                return false;
            }
        }
        return true;
    };

    return defaults(
        {
            onSubmit,
            onFieldChange,
            editExecuteStatus,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('OpenBoxCopyFormContainer'));
            },
            onSubmit: async (event: any, fields: any) => {
                const entityFields = mapValues(fields, (field) => {
                    return field.value;
                });

                if (entityFields) {
                    if (onValidate(entityFields)) {
                        entity.add(entityFields, params);
                    }
                }
            },
        }
    );
}
export const OpenBoxCopyFormView = connect(mapDispatchToProps)(openBoxCopyFormView);
