import { ComponentsManager, services } from '@comall-backend-builder/core';
import { EntitiesManager, Entity } from '@comall-backend-builder/core/lib/parser';
import { Button, Icon, Modal } from 'antd';
import { ModalProps } from 'antd/lib/modal';
import { isEqual, noop, uniqueId } from 'lodash';
import React, { Component, createElement } from 'react';

interface SubsiteValue {
    id: number;
    name: string;
}
interface Props {
    appId: string;
    subsite?: SubsiteValue;
    onChange(subsite?: SubsiteValue): void;
}
interface State {
    subsite?: SubsiteValue;
    modalVisible: boolean;
}

export default class WechatOfficialReleatedSubsiteSelector extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            modalVisible: false,
        };
        this.entity = new (EntitiesManager.get('WechatOfficialAccountSubsite'))({
            appId: props.appId,
        });
        this.entity.setMeta({ apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-OFFIACCOUNT` });
    }

    // static getDerivedStateFromProps(props: Props, state: State) {
    //     if (!isEqual(props.subsite, state.subsite)) {
    //         return {
    //             subsite: props.subsite,
    //         };
    //     }
    // }

    componentDidUpdate(prevProps: Props, prevState: State) {}

    private entity: Entity;
    private componentId = `WechatOfficialReleatedSubsiteSelector-${uniqueId()}`;
    private toggleModalVisible = () => {
        const { modalVisible } = this.state;
        this.setState({ modalVisible: !modalVisible });
    };
    private onOK = () => {
        const { subsite } = this.state;
        const { onChange, subsite: propsSubsite } = this.props;
        if (!isEqual(subsite, propsSubsite)) {
            onChange(subsite);
        }
        this.toggleModalVisible();
    };
    private get buttonLabel() {
        const { subsite } = this.props;
        if (subsite && subsite.name) {
            return subsite.name;
        }
        return services.language.getText('selectSubsite');
    }
    private get modalConfig() {
        const { subsite } = this.state;
        return {
            component: 'DataTable',
            scroll: {
                y: 400,
            },
            rowKey: 'id',
            rowSelection: {
                type: 'radio',
                onChange: noop,
                onSelect: (row: SubsiteValue) => {
                    this.setState({ subsite: row });
                },
                selectedRowKeys: (subsite && subsite.id && [subsite.id]) || [],
            },
            columns: [{ property: 'name' }, { property: 'address' }, { property: 'status' }],
            loadFirstPage: true,
            pagination: false,
            componentId: this.componentId,
            entity: this.entity,
        };
    }
    private get modalContent() {
        return createElement(ComponentsManager.get(this.modalConfig.component), this.modalConfig);
    }
    private get modalProps(): ModalProps {
        return {
            width: 900,
            title: services.language.getText('otherSelectSubsite'),
            visible: this.state.modalVisible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOK,
            onCancel: this.toggleModalVisible,
        };
    }
    render() {
        return (
            <>
                <Button onClick={this.toggleModalVisible}>
                    {this.buttonLabel}
                    <Icon type="down" style={{ marginLeft: 8 }} />
                </Button>
                <Modal {...this.modalProps}>{this.modalContent}</Modal>
            </>
        );
    }
}
