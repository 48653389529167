import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const WechatBusinessMallsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/business_malls/${id}`;
        } else {
            config.apiPath = `/admin/business_malls`;
            data.channel = 'WECHAT';
        }

        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.subsiteName = res.subsite.name;
                res.baseInfo = {
                    subsiteId: res.subsite.id,
                    version: res.version,
                    mallId: res.mallId,
                    mallBrandId: res.mallBrandId,
                };
                res.merchantInfo = {
                    merchants:
                        res.merchants && res.merchants.length > 0
                            ? res.merchants.map((m: any) => {
                                  return {
                                      ...m,
                                      id: res.subsite.id + ',' + m.merchantId,
                                      merchantId: m.merchantId + '',
                                  };
                              })
                            : undefined,
                };
                res.memberPointInfo = {
                    transactionNotificationUrl: res.transactionNotificationUrl,
                    authorizeNotificationUrl: res.authorizeNotificationUrl,
                    pointDetailPage: 'subpackages/my-points/index',
                    creditShopPage: 'subpackages/credit-shop/index',
                    myCarsPage: 'subpackages/parking-fee/pages/my-cars/index',
                    parkingFeePage: 'subpackages/parking-fee/index',
                };
            } else {
                res.result = res.result.map((item: any) => {
                    return {
                        ...item,
                        subsiteName: item.subsite.name,
                    };
                });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiPath = `/admin/business_malls`;
        const newParams = {
            channel: 'WECHAT',
            ...params.baseInfo,
            merchantIds: params.merchantInfo.merchants.map((item: any) => item.merchantId),
        };
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiPath = `/admin/business_malls/${id}`;
        const newParams = {
            channel: 'WECHAT',
            ...params.baseInfo,
            merchantIds: params.merchantInfo.merchants.map((item: any) => item.merchantId),
        };
        return await api.put(newParams, config);
    },
};

export const AlipayBusinessMallsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/business_malls/${id}`;
        } else {
            config.apiPath = `/admin/business_malls`;
            data.channel = 'ALIPAY';
        }

        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.subsiteName = res.subsite.name;
                res.baseInfo = {
                    subsiteId: res.subsite.id,
                    mallId: res.mallId,
                };
                res.merchantInfo = {
                    merchants:
                        res.merchants && res.merchants.length > 0
                            ? res.merchants.map((m: any) => {
                                  return {
                                      ...m,
                                      id: res.subsite.id + ',' + m.merchantId,
                                      merchantId: m.merchantId + '',
                                  };
                              })
                            : undefined,
                };
            } else {
                res.result = res.result.map((item: any) => {
                    return {
                        ...item,
                        subsiteName: item.subsite.name,
                    };
                });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiPath = `/admin/business_malls`;
        const newParams = {
            channel: 'ALIPAY',
            ...params.baseInfo,
            version: 'V1',
            merchantIds: params.merchantInfo.merchants.map((item: any) => item.merchantId),
        };
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiPath = `/admin/business_malls/${id}`;
        const newParams = {
            channel: 'ALIPAY',
            ...params.baseInfo,
            version: 'V1',
            merchantIds: params.merchantInfo.merchants.map((item: any) => item.merchantId),
        };
        return await api.put(newParams, config);
    },
};
