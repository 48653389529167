/*
 * @Author: zhuhuajuan
 * @Date: 2020-8-21 14:09:41
 * @Description: 意见反馈管理
 */
import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import get from 'lodash/get';

import { TipsModalContentType } from '../../../components';

const illustrates: { [str: string]: any } = {
    SUPERPOSITION: '<<zjcgzzs_1>>',
    OPTIMAL: '<<zjcgzzs_2>>',
};

export const config: Config = {
    entities: {
        IntegralDescriptionEntity: {
            apiPath: '/loader/admin/integralDescription',
            filters: {
                subsiteName: {
                    type: 'object.option.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                subsiteName: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                    rules: [{ required: true }],
                },
                subsites: {
                    type: 'array.subsites.selector',
                    displayName: '<<otherSelectSubsite>>',
                    rules: [{ required: true }],
                },
                // subsites: {
                //     type: 'array.options.autoComplete',
                //     displayName: '<<otherSelectSubsite>>',
                //     options: [],
                //     source: {
                //         apiPath: '/admin/defaults/subsite',
                //     },
                //     rules: [{ required: true }],
                // },
                copyWriting: {
                    type: 'string',
                    displayName: '<<jfqlwa>>',
                    rules: [
                        {
                            message: '文案最多30字',
                            validator: (rule: any, value: any) => {
                                if (value && value.length > 30) {
                                    return false;
                                }
                                return true;
                            },
                        },
                    ],
                    controlConfig: {
                        style: {
                            width: 500,
                        },
                        placeholder: '<<l_rjfjy>>',
                    },
                },
                desc: {
                    type: 'string.richTextPlus',
                    displayName: '<<jfsm_1>>',
                    controlConfig: {
                        toolbarId: 'descriptionRichText',
                        style: {
                            height: 300,
                        },
                        uploadConfig: {
                            apiPath: `/admin/images/upload`,
                            fileName: 'fileName',
                        },
                    },
                    rules: [
                        {
                            required: true,
                            max: 20000,
                            message: '<<l_notMoreThan2000_1>>',
                        },
                    ],
                },
                bgPicture: {
                    type: 'array.imageObjectArrayForTopPic',
                    displayName: '<<bjt>>',
                    className: 'sigle-image-field',
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                        extra: {
                            tip: '<<picLimit320>>',
                            img: './images/avatar/integral-description-bg.jpg',
                            btnText: '<<example>>',
                            triggerType: 'hover',
                            style: {
                                width: 500,
                            },
                        },
                    },
                },
                type: {
                    type: 'string.options.radio',
                    displayName: '<<jflx>>',
                    defaultValue: 'CONSUME_POINT',
                    options: [
                        {
                            id: 'CONSUME_POINT',
                            name: '<<xfjf>>',
                        },
                        {
                            id: 'STOP_CAR_POINT',
                            name: '<<tcjf>>',
                        },
                    ],
                    rules: [{ required: true }],
                },
                doubleRuleParticipateCardinalityCalculate: {
                    type: 'string.radioWithModalTip',
                    displayName: '<<jcgzbhdjjb>>',
                    defaultValue: 'false',
                    options: [
                        {
                            id: 'false',
                            name: '<<bbh>>',
                        },
                        {
                            id: 'true',
                            name: '<<bh>>',
                        },
                    ],
                    controlConfig: {
                        tipsModalConfig: {
                            contentType:
                                TipsModalContentType.DoubleRuleParticipateCardinalityCalculate,
                            showIcon: true,
                            title: '<<jbgzsmjdyjsgs>>',
                            width: 648,
                        },
                    },
                    rules: [{ required: true }],
                },
                coefficientRuleExecuteType: {
                    type: 'string.radioIllustrate',
                    displayName: '<<gwjfjbgz>>',
                    defaultValue: 'OPTIMAL',
                    name: JSON.stringify(illustrates),
                    options: [
                        { id: 'SUPERPOSITION', name: '<<jbdj>>' },
                        { id: 'OPTIMAL', name: '<<hcqy>>' },
                    ],
                    rules: [
                        {
                            required: true,
                        },
                    ],
                    controlConfig: {
                        illustrate: illustrates,
                        style: { display: 'flex', flexDirection: 'column', paddingTop: 9 },
                    },
                },
                navigationPointBenefit: {
                    type: 'string.navigationLink',
                    displayName: '<<pointRightsAndInterests>>',
                    controlConfig: {
                        text: '>><<qsz_1>>',
                        href: '#/point-benefit',
                    },
                },
                navigationBirthdayCaring: {
                    type: 'string.navigationLink',
                    displayName: '<<birthdayCaring>>',
                    controlConfig: {
                        text: '>><<qsz_1>>',
                        href: '#/birthday-caring',
                    },
                },
                navigationFestivalMarketing: {
                    type: 'string.navigationLink',
                    displayName: '<<festivalMarketing>>',
                    controlConfig: {
                        text: '>><<qsz_1>>',
                        href: '#/festival-marketing',
                    },
                },
                navigationPointSetting: {
                    type: 'string.navigationLink',
                    displayName: '<<jfdk>>',
                    controlConfig: {
                        text: '>><<qsz_1>>',
                        href: '#/point-setting',
                    },
                },
            },
        },
    },
    components: {
        IntegralDescriptionView: {
            component: 'Viewport',
            entity: 'IntegralDescriptionEntity',
        },
        IntegralDescriptionPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'IntegralDescriptionFilter',
                },
                {
                    component: 'Button',
                    text: '<<xzjfgz>>',
                    route: '/integral-description/add',
                    type: 'primary',
                    icon: 'plus',
                    style: {
                        width: 140,
                    },
                    className: 'mt24 mb24',
                },
                { component: 'IntegralDescriptionTable' },
            ],
        },
        IntegralDescriptionFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteName',
                },
            ],
        },
        IntegralDescriptionTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'IntegralDescriptionDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'subsiteName',
                            displayConfig: {
                                countSuffix: '<<subsites>>',
                                style: {
                                    maxWidth: '200px',
                                },
                            },
                        },
                        {
                            property: 'type',
                        },
                        {
                            property: 'desc',
                            displayConfig: {
                                style: {
                                    maxWidth: 500,
                                    height: 80,
                                },
                            },
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        items: [
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                path: '/integral-description/edit/{{row.id}}',
                            },
                            {
                                type: 'delete',
                                text: '<<common.delete>>',
                                config: {
                                    type: 'link',
                                    tooltip: null,
                                    title: '<<confirmDelete>>',
                                    okText: '<<common.ok>>',
                                    cancelText: '<<common.cancel>>',
                                },
                            },
                        ],
                    },
                },
            ],
        },
        IntegralDescriptionAddPage: {
            component: 'Card',
            content: { component: 'IntegralDescriptionAddForm' },
        },
        IntegralDescriptionAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 16,
            style: { width: 900 },
            fields: [
                {
                    property: 'subsites',
                    extra: '<<l_xzdmdwjfgydmd_1>>',
                },
                { property: 'type' },
                { property: 'copyWriting' },
                { property: 'desc' },
                {
                    property: 'bgPicture',
                },
                {
                    property: 'doubleRuleParticipateCardinalityCalculate',
                    visible: (values: any) => get(values, 'type') === 'CONSUME_POINT',
                },
                {
                    property: 'coefficientRuleExecuteType',
                    visible: (values: any) => get(values, 'type') === 'CONSUME_POINT',
                },
                {
                    property: 'navigationPointBenefit',
                    visible: (values: any) => get(values, 'type') === 'CONSUME_POINT',
                },
                {
                    property: 'navigationBirthdayCaring',
                    visible: (values: any) => get(values, 'type') === 'CONSUME_POINT',
                },
                {
                    property: 'navigationFestivalMarketing',
                    visible: (values: any) => get(values, 'type') === 'CONSUME_POINT',
                },
                {
                    property: 'navigationPointSetting',
                    visible: (values: any) => get(values, 'type') === 'CONSUME_POINT',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },

            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        IntegralDescriptionEditPage: {
            component: 'Card',
            content: { component: 'IntegralDescriptionEditForm' },
        },
        IntegralDescriptionEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'IntegralDescriptionEntity',
            loaderType: 'get',
            labelCol: 6,
            controlCol: 16,
            style: { width: 900 },
            fields: [
                {
                    property: 'subsites',
                    extra: '<<l_xzdmdwjfgydmd_1>>',
                },
                { property: 'type' },
                { property: 'copyWriting' },
                { property: 'desc' },
                { property: 'bgPicture' },
                {
                    property: 'doubleRuleParticipateCardinalityCalculate',
                    visible: (values: any) => get(values, 'type') === 'CONSUME_POINT',
                },
                {
                    property: 'coefficientRuleExecuteType',
                    visible: (values: any) => get(values, 'type') === 'CONSUME_POINT',
                },
                {
                    property: 'navigationPointBenefit',
                    visible: (values: any) => get(values, 'type') === 'CONSUME_POINT',
                },
                {
                    property: 'navigationBirthdayCaring',
                    visible: (values: any) => get(values, 'type') === 'CONSUME_POINT',
                },
                {
                    property: 'navigationFestivalMarketing',
                    visible: (values: any) => get(values, 'type') === 'CONSUME_POINT',
                },
                {
                    property: 'navigationPointSetting',
                    visible: (values: any) => get(values, 'type') === 'CONSUME_POINT',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/integral-description',
                    component: 'IntegralDescriptionView',
                    breadcrumbName: '<<pointExplain>>',
                    privilege: {
                        path: 'pointExplain',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add/',
                            component: 'IntegralDescriptionAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'IntegralDescriptionEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },

                        { path: '/', component: 'IntegralDescriptionPage' },
                    ],
                },
            ],
        },
    ],
};
