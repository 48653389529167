import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;

export const SignOrdertMessageLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-OPERATION-TOOL`;
        config.apiPath = '/admin/sign_order';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/sign_order/${id}`;
        }
        return await api.get(data, config);
    },
};

export const SignOrdertMessageDownloadLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-OPERATION-TOOL`;
        config.apiPath = '/admin/sign_order_import';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/sign_order_import/${id}`;
        }
        if (!isEmpty(data.dateRange)) {
            data.startTime = data.dateRange.start + ' 00:00:00';
            data.endTime = data.dateRange.end + ' 23:59:59';
            delete data.dateRange;
        }
        return await api.get(data, config);
    },
};
