import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import { get } from 'lodash';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { language } from '@comall-backend-builder/core/lib/services';
const formValidate = (entity: any) => {
    const {
        baseInfo: { deductionMode },
        benefitRule: { gradeEquity, firstDiscountHour, firstBenefit },
    } = entity;

    if (deductionMode === 'HOUR' && gradeEquity.cardLevelBenefit === 'ENABLE') {
        // 如果权益方式选择了时长 等级权益中每日赠送
        const memberCardLevelBenefitError =
            gradeEquity.memberCardLevelBenefit?.length &&
            gradeEquity.memberCardLevelBenefit.filter(
                (item: any) => item.everydayBenefit && item.everydayBenefit % 30 !== 0
            );
        if (memberCardLevelBenefitError.length > 0) {
            AntMessage.error(language.getText('everydayBenefitHourError'));
            return false;
        }
        if (firstBenefit === 'ENABLE' && firstDiscountHour % 30 !== 0) {
            AntMessage.error(language.getText('firstDiscountHourError'));
            return false;
        }
    }
    // 如果等级权益选择了使用 则会员等级至少有一条数据
    let flag = true;
    let message = '';
    if (gradeEquity.cardLevelBenefit === 'ENABLE' && gradeEquity.memberCardLevelBenefit?.length) {
        gradeEquity.memberCardLevelBenefit.forEach((item: any) => {
            var reg = /^(0|\+?[1-9][0-9]*)$/;

            if (
                item.everydayBenefit === '0' ||
                item.everydayBenefit === 0 ||
                (item.everydayBenefit && !reg.test(item.everydayBenefit))
            ) {
                flag = false;
                message = language.getText('everydayBenefitError');
                return false;
            }
            if (
                item.conversionMax === 0 ||
                item.conversionMax === '0' ||
                (item.conversionMax && !reg.test(item.conversionMax))
            ) {
                flag = false;
                message = language.getText('conversionMaxError');
                return false;
            }
            if (
                item.conversionBenefit === 0 ||
                item.conversionBenefit === '0' ||
                (item.conversionBenefit && !reg.test(item.conversionBenefit))
            ) {
                flag = false;
                message = language.getText('conversionBenefitError');
                return false;
            }
            if (
                gradeEquity.memberCardLevelBenefit?.length === 1 &&
                !item.memberCardLevelId &&
                !item.everydayBenefit &&
                !item.conversionBenefit
            ) {
                flag = false;
                message = language.getText('gradeEquityError');
                return false;
            }
            if (!item.memberCardLevelId) {
                flag = false;
                message = language.getText('selectGrade');
                return false;
            }
            if (!item.everydayBenefit && !item.conversionBenefit) {
                flag = false;
                message = language.getText('pleaseFillInChargeBenefit');
                return false;
            }
        });
    }
    if (!flag) {
        AntMessage.error(message);
        return false;
    }
    return true;
};

const chargeBenefit = {
    apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`,
    apiPath: '/loader/admin/chargeBenefits',
    properties: {
        id: { type: 'string', displayName: 'ID' },
        subsiteId: {
            type: 'string.options.select',
            displayName: '<<otherSelectSubsite>>',
            options: [],
            rules: [{ required: true, message: '<<applicableSubsiteRequired>>' }],
            source: {
                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`,
                apiPath: `/admin/member_benefits/subsites?type=CHARGE_BENEFITS`,
            },
            controlConfig: {
                placeholder: '<<selectSubsite>>',
                style: {
                    width: 400,
                },
            },
        },
        subsiteName: {
            type: 'string',
            displayName: '<<subsiteName>>',
        },
        status: {
            type: 'string.options.radio',
            displayName: '<<status>>',
            defaultValue: 'ENABLED',
            options: [
                { id: 'ENABLE', name: '<<beforeOpen>>' },
                { id: 'DISABLE', name: '<<beforeClose>>' },
            ],
        },
        baseInfo: {
            type: 'object.subForm',
            displayName: '<<baseInfo>>',
            properties: {
                subsiteId: {
                    type: 'string.options.select',
                    displayName: '<<otherSelectSubsite>>',
                    options: [],
                    rules: [{ required: true, message: '<<applicableSubsiteRequired>>' }],
                    source: {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`,
                        apiPath: `/admin/member_benefits/subsites?type=CHARGE_BENEFITS`,
                    },
                    controlConfig: {
                        placeholder: '<<selectSubsite>>',
                        style: {
                            width: 400,
                        },
                    },
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                deductionMode: {
                    type: 'string.options.radio',
                    displayName: '<<benefitType>>',
                    defaultValue: 'MONEY',
                    rules: [{ required: true }],
                    options: [
                        {
                            id: 'MONEY',
                            name: '<<moneyTotal>>',
                        },
                        {
                            id: 'HOUR',
                            name: '<<duration>>',
                        },
                    ],
                    extra: '<<benefitTypeExtra>>',
                },
            },
        },

        benefitInfo: {
            type: 'object.subForm',
            displayName: '<<chargeBenefitDescription>>',
            properties: {
                explain: {
                    type: 'string.richTextPlus',
                    displayName: '<<benefitDescription>>',
                    rules: [{ max: 20000, message: '<<notMoreThan2000>>' }],
                    controlConfig: {
                        toolbarId: 'descriptionRichText',
                        style: {
                            height: 300,
                        },
                        uploadConfig: {
                            fileName: 'fileName',
                            apiPath: `/admin/images/upload`,
                        },
                    },
                },
            },
        },
        benefitRule: {
            type: 'object.subForm',
            displayName: '<<chargeBenefitRule>>',
            properties: {
                firstBenefit: {
                    type: 'string.options.radio',
                    displayName: '<<firstBenefit>>',
                    defaultValue: 'DISABLE',
                    rules: [{ required: true }],
                    options: [
                        {
                            id: 'ENABLE',
                            name: '<<sy_1>>',
                        },
                        {
                            id: 'DISABLE',
                            name: '<<bsy>>',
                        },
                    ],
                    extra: '<<firstBenefitExtra>>',
                },
                gradeEquity: {
                    type: 'object.gradeEquity',
                    displayName: '<<djqy>>',
                    rules: [{ required: true }],
                },
                firstDiscountMoney: {
                    type: 'number.tip',
                    displayName: '<<canDiscountsAndExemptions>>',
                    rules: [
                        {
                            required: true,
                            type: 'number',
                            min: 1,
                            message: '<<qsrclwzzs>>',
                            validator: (rule: any, value: any) => {
                                var reg = /^(0|\+?[1-9][0-9]*)$/;
                                if (value === 0 || (value && !reg.test(value))) {
                                    return false;
                                } else {
                                    return true;
                                }
                            },
                        },
                    ],
                    controlConfig: {
                        style: {
                            width: 120,
                        },
                        addonafter: '<<yuan>>',
                    },
                },
                firstDiscountHour: {
                    type: 'number.tip',
                    displayName: '<<canDiscountsAndExemptions>>',
                    rules: [
                        {
                            required: true,
                            type: 'number',
                            min: 1,
                            message: '<<qsrclwzzs>>',
                            validator: (rule: any, value: any) => {
                                var reg = /^(0|\+?[1-9][0-9]*)$/;
                                if (value === 0 || (value && !reg.test(value))) {
                                    return false;
                                } else {
                                    return true;
                                }
                            },
                        },
                    ],
                    controlConfig: {
                        style: {
                            width: 120,
                        },
                        addonafter: '<<minutes>>',
                    },
                },
            },
        },
    },
    filters: {
        subsiteName: {
            type: 'object.option.autoComplete',
            displayName: '<<subsiteName>>',
            options: [],
            source: {
                apiPath: '/admin/defaults/subsite',
            },
            controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
        },
    },
};

export let config: Config = {
    entities: {
        chargeBenefit,
    },
    components: {
        ChargeBenefitView: {
            component: 'Viewport',
            entity: 'chargeBenefit',
        },
        ChargeBenefitPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [{ component: 'ChargeBenefitFilter' }, { component: 'ChargeBenefitTable' }],
        },
        ChargeBenefitFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteName',
                },
            ],
        },
        ChargeBenefitTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'Button',
                    text: '<<xzqy>>',
                    route: '/charge-benefits/add',
                    type: 'primary',
                    icon: 'plus',
                    style: {
                        width: 140,
                    },
                    className: 'mt24 mb24',
                },
                {
                    component: 'DataTable',
                    componentId: 'ChargeBenefitDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'status',
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            params: { status: 'DISABLE' },
                                            value: 'ENABLE',
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`,
                                            apiPath: '/admin/member_benefits/:id/disabled',
                                            config: {
                                                content: '{{row.typeName}}<<areadyClosed>>',
                                                text: '<<common.close>>',
                                                style: { color: 'red' },
                                            },
                                            confirm: {
                                                text: '<<isAreadyClose>>{{row.typeName}}?',
                                            },
                                        },
                                        {
                                            params: { status: 'ENABLE' },
                                            value: 'DISABLE',
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`,
                                            apiPath: '/admin/member_benefits/:id/enabled',
                                            config: {
                                                content: '{{row.typeName}}<<areadyOpend>>',
                                                text: '<<common.opend>>',
                                            },
                                            confirm: {
                                                text: '<<isAreadyOpend>>{{row.typeName}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                path: '/charge-benefits/edit/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        ChargeBenefitAddPage: {
            component: 'FlexLayout',
            entity: 'chargeBenefit',
            direction: 'horizontal',
            items: [{ component: 'ChargeBenefitAddForm' }],
        },
        ChargeBenefitAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'chargeBenefit',
            labelCol: 6,
            controlCol: 18,
            fields: [
                { property: 'baseInfo.subsiteId' },
                { property: 'baseInfo.deductionMode' },
                { property: 'benefitRule.firstBenefit' },
                {
                    property: 'benefitRule.firstDiscountMoney',
                    visible: (e: any) => {
                        const deductionMode: string = get(e, 'baseInfo.deductionMode');
                        const firstBenefit: string = get(e, 'benefitRule.firstBenefit');
                        return firstBenefit === 'ENABLE' && deductionMode === 'MONEY';
                    },
                },
                {
                    property: 'benefitRule.firstDiscountHour',
                    visible: (e: any) => {
                        const deductionMode: string = get(e, 'baseInfo.deductionMode');
                        const firstBenefit: string = get(e, 'benefitRule.firstBenefit');
                        return firstBenefit === 'ENABLE' && deductionMode === 'HOUR';
                    },
                },
                { property: 'benefitRule.gradeEquity' },
                { property: 'benefitInfo.explain' },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            submit: true,
            onValidate: formValidate,
            submitSuccessBehavior: {
                route: '/charge-benefits',
            },
        },
        ChargeBenefitEditPage: {
            component: 'FlexLayout',
            entity: 'memberBenefit',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'ChargeBenefitEditForm' }],
        },
        ChargeBenefitEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            loaderType: 'get',
            entity: 'chargeBenefit',
            labelCol: 6,
            controlCol: 18,
            fields: [
                { property: 'baseInfo.subsiteName', modifiable: false },
                { property: 'baseInfo.deductionMode' },
                { property: 'benefitRule.firstBenefit' },
                {
                    property: 'benefitRule.firstDiscountMoney',
                    visible: (e: any) => {
                        const deductionMode: string = get(e, 'baseInfo.deductionMode');
                        const firstBenefit: string = get(e, 'benefitRule.firstBenefit');
                        return firstBenefit === 'ENABLE' && deductionMode === 'MONEY';
                    },
                },
                {
                    property: 'benefitRule.firstDiscountHour',
                    visible: (e: any) => {
                        const deductionMode: string = get(e, 'baseInfo.deductionMode');
                        const firstBenefit: string = get(e, 'benefitRule.firstBenefit');
                        return firstBenefit === 'ENABLE' && deductionMode === 'HOUR';
                    },
                },
                { property: 'benefitRule.gradeEquity' },
                { property: 'benefitInfo.explain' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: formValidate,
            onSubmitSuccess: () => {
                AntMessage.success(language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/charge-benefits',
                    breadcrumbName: '<<chargeBenefits>>',
                    component: 'ChargeBenefitView',
                    privilege: {
                        path: 'chargeBenefits',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add/',
                            component: 'ChargeBenefitAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'ChargeBenefitEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        { path: '/', component: 'ChargeBenefitPage' },
                    ],
                },
            ],
        },
    ],
};
