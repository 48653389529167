import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import { get, some, filter, forEach } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';
import { PushAndSiteMailSceneType } from '../../../containers/wechat/common';
import {
    MessageNotificationMethodValueItem,
    MsgChannelType,
} from '../../../types/mode/array/array-message-notification-method/message-notification-method';

enum BirthdayCaringType {
    /**
     * 生日当天
     */
    SAME_DAY = 'SAME_DAY',
    /**
     * 生日当月
     */
    SAME_MONTH = 'SAME_MONTH',
    /**
     * 自定义
     */
    FRONT_DAY = 'FRONT_DAY',
}

enum RewardMode {
    /**
     * 领取
     */
    RECEIVE = 'RECEIVE',
    /**
     * 购物奖励翻倍
     */
    SHOPPING_REWARD_DOUBLE = 'SHOPPING_REWARD_DOUBLE',
}

export const checkBusinessNotifications = (msgItem: MessageNotificationMethodValueItem) => {
    const { msgChannelType } = msgItem;
    const siteMailAndMessage =
        msgChannelType === MsgChannelType.SITEMAIL || msgChannelType === MsgChannelType.APP_PUSH;
    if (msgChannelType === MsgChannelType.SMS) {
        const { params = [] } = msgItem;
        const [content] = params;
        if (!content || !content.value) {
            AntMessage.warning(language.getText('qsrdxnr'));
            return false;
        }
    }
    let hasValidate = true;
    if (siteMailAndMessage) {
        if (!msgItem.params || !msgItem.params.length) {
            AntMessage.warning(
                `${services.language.getText('selectFillin')}${services.language.getText('tzfs')}`
            );
            return false;
        } else {
            forEach(msgItem.params || [], (o: any) => {
                if (o.fieldType !== 'file') {
                    if (!o.value) {
                        AntMessage.warning(`${services.language.getText('selectFillin')}${o.name}`);
                        hasValidate = false;
                        return false;
                    }
                }
            });
        }
    }
    if (msgChannelType === MsgChannelType.EMAIL) {
        if (!msgItem.params || !msgItem.params.length) {
            AntMessage.warning(
                `${services.language.getText('selectFillin')}${services.language.getText('tzfs')}`
            );
            return false;
        } else {
            forEach(msgItem.params, (param: any) => {
                if (param.fieldType === 'string' && !param.value) {
                    AntMessage.warning(services.language.getText('requestTheme'));
                    hasValidate = false;
                    return false;
                }
            });
        }
    }
    return hasValidate;
};
export const checkNotificationLink = (msgItem: MessageNotificationMethodValueItem) => {
    const { targetType, targetUrl, msgChannelType } = msgItem;
    if (msgChannelType === MsgChannelType.SMS || msgChannelType === MsgChannelType.EMAIL) {
        return true;
    }
    if (!targetType) {
        AntMessage.warning(services.language.getText('qxzljlx'));
        return false;
    }
    const needUrl = [
        'subpackages/topic',
        'wheel-draw/pages',
        'packet-rain/index',
        'scratch-card/index',
        'lottery-code/index',
    ];
    if (targetType === 'parameterConcatenation') {
        if (!targetUrl) {
            AntMessage.warning(services.language.getText('selectLinkTarget'));
            return false;
        }
        if (needUrl.some((i) => targetUrl.includes(i)) && !targetUrl.includes('?')) {
            AntMessage.warning(services.language.getText('selectLinkTarget'));
            return false;
        }
    }
    return true;
};

const formValidate = (entity: any) => {
    const birthdayCaringType = get(entity, 'baseInfo.birthdayCaringType', null);
    if (birthdayCaringType === BirthdayCaringType.FRONT_DAY) {
        const frontDay = get(entity, 'baseInfo.frontDay', null);
        if (!frontDay) {
            AntMessage.warning(language.getText('qsrzdyts'));
            return false;
        }
    }
    const rewardMode = get(entity, 'rewardInfo.rewardMode', RewardMode.RECEIVE);
    if (rewardMode === RewardMode.RECEIVE) {
        let memberLevel = get(entity, 'rewardInfo.receiveRule.memberLevel');
        if (!memberLevel) {
            AntMessage.warning(language.getText('hydjgzbkwk'));
            return false;
        }
        if (memberLevel.length === 0) {
            AntMessage.warning(language.getText('gmdxwhydj'));
            return false;
        }
        if (memberLevel) {
            const hasMemberLevel = some(memberLevel, (i) => i.coupons && i.coupons.length > 0);
            const hasMemberExperience = some(memberLevel, (i) => i.experience);
            if (!hasMemberLevel && !hasMemberExperience) {
                AntMessage.warning(language.getText('hydjgzbkwk'));
                return false;
            }
        }
        let picture = get(entity, 'rewardInfo.picture');
        if (!picture || picture.length === 0) {
            AntMessage.warning(language.getText('lqytpbnwk'));
            return false;
        }
    }
    if (rewardMode === RewardMode.SHOPPING_REWARD_DOUBLE) {
        let shoppingRewardRule = get(entity, 'rewardInfo.shoppingRewardRule');
        if (shoppingRewardRule) {
            const unifiedReward = get(shoppingRewardRule, 'unifiedReward');
            const rewardInfos = get(shoppingRewardRule, 'rewardInfos');
            if (
                unifiedReward &&
                (!rewardInfos || (!rewardInfos[0].rewardValue && !rewardInfos[0].experienceValue))
            ) {
                AntMessage.warning(language.getText('hydjgzbkwk'));
                return false;
            }
            if (!unifiedReward) {
                if (!rewardInfos || rewardInfos.length === 0) {
                    AntMessage.warning(language.getText('gmdxwhydj'));
                    return false;
                } else {
                    const hasValue = some(rewardInfos, (r) => !!r.rewardValue);
                    const hasExperienceValue = some(rewardInfos, (r) => !!r.experienceValue);
                    if (!hasValue && !hasExperienceValue) {
                        AntMessage.warning(language.getText('hydjgzbkwk'));
                        return false;
                    }
                }
            }
        }
    }
    if (rewardMode === RewardMode.RECEIVE) {
        const selectedInfo = filter(entity.settingInfo.businessNotifications, ['status', 1]);
        if (selectedInfo.length > 0) {
            for (let index = 0; index < selectedInfo.length; index++) {
                const item = selectedInfo[index];
                if (!checkBusinessNotifications(item)) {
                    return false;
                }
                if (!checkNotificationLink(item)) {
                    return false;
                }
            }
            if (
                !entity.settingInfo.message.activityCheck &&
                !entity.settingInfo.message.memberCheck
            ) {
                AntMessage.warning(language.getText('tzsjzsxzyx'));
                return false;
            }
        }
    }
    return true;
};

export const config: Config = {
    entities: {
        BirthdayCaringEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/birthdayCaring',
            filters: {
                activityName: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<applicableSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                merchantId: {
                    displayName: '<<applyMerchants>>',
                    type: 'string.filterOptions.autoComplete',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                        //专柜默认正序排序
                        params: {
                            sequence: 'code',
                            order: 'asc',
                        },
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 150 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: [
                        { id: 'ENABLED', name: '<<beforeOpen>>' },
                        { id: 'DISABLED', name: '<<beforeClose>>' },
                    ],
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrhdmc>>',
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<jqsrhdhdmc>>',
                                maxLength: 20,
                                style: {
                                    width: 400,
                                },
                            },
                            extra: '<<jzsbncg>>',
                        },
                        subsiteId: {
                            type: 'array.subsites.selector',
                            displayName: '<<sxmd_1>>',
                            rules: [{ required: true }],
                        },
                        birthdayCaringType: {
                            type: 'string.options.radio',
                            displayName: '<<acitivityTime>>',
                            defaultValue: 'SAME_DAY',
                            options: [
                                { id: 'SAME_DAY', name: '<<srdt>>' },
                                { id: 'SAME_MONTH', name: '<<srdy>>' },
                                { id: 'FRONT_DAY', name: '<<custom>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        frontDay: {
                            type: 'number.tip',
                            displayName: '<<zdyts>>',
                            rules: [
                                {
                                    type: 'number',
                                    min: 1,
                                    message: '<<frontDayMessage>>',
                                    validator: (rule: any, value: any) => {
                                        const reg = /^\+?[1-9]\d*$/;
                                        if (
                                            value != null &&
                                            value !== '' &&
                                            (value < 1 || value > 15 || !reg.test(value))
                                        ) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 100,
                                },
                                addonbefore: '<<srq>>',
                                addonafter: '<<day>>',
                                tip: '',
                            },
                        },
                    },
                },
                rewardInfo: {
                    type: 'object.subForm',
                    displayName: '<<jlsz>>',
                    properties: {
                        rewardMode: {
                            type: 'string.options.radio',
                            displayName: '<<jlfs>>',
                            defaultValue: RewardMode.RECEIVE,
                            options: [
                                { id: RewardMode.RECEIVE, name: '<<lq>>' },
                                { id: RewardMode.SHOPPING_REWARD_DOUBLE, name: '<<gwhkjb>>' },
                            ],
                            rules: [{ required: true }],
                            extra: '<<jyhzdlqjl>>',
                        },
                        merchants: {
                            type: 'array.merchant.selector',
                            displayName: '<<applyMerchants>>',
                            defaultValue: [],
                            controlConfig: {
                                subsiteField: 'baseInfo.subsiteId',
                                tips: '<<bxzzjzbgzsyysxmdxsyzj>>',
                                hideSubsiteColume: false,
                                multipleSubsite: true,
                                customParams: {
                                    isContainsSelfMerchant: false,
                                },
                                customFields: [
                                    {
                                        property: 'subSiteName',
                                    },
                                    {
                                        property: 'merchantName',
                                    },
                                ],
                                customColumns: [
                                    {
                                        property: 'subSiteName',
                                    },
                                    {
                                        property: 'merchantName',
                                    },
                                    {
                                        property: 'code',
                                        sorter: true,
                                    },
                                    {
                                        property: 'merchantType',
                                    },
                                    {
                                        property: 'merchantStatus',
                                    },
                                ],
                            },
                        },
                        receiveRule: {
                            type: 'object.birthdayMemberLevelCouponRuleMode',
                            displayName: '<<hydjgz>>',
                            rules: [{ required: true }],
                        },
                        shoppingRewardRule: {
                            type: 'object.memberCardLevelPointReward',
                            displayName: '<<hydjgz>>',
                            controlConfig: {
                                subsiteField: 'baseInfo.subsiteId',
                                enableExperienceValue: true,
                                multipleSubsite: true,
                            },
                            rules: [{ required: true }],
                        },
                        picture: {
                            displayName: '<<lqytp>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<jpictureExtra>>',
                        },
                    },
                },
                settingInfo: {
                    type: 'object.subForm',
                    displayName: '<<JQMessageNotify>>',
                    properties: {
                        businessNotifications: {
                            type: 'array.messageNotificationMethod',
                            displayName: '<<tzfs>>',
                            controlConfig: {
                                scene: PushAndSiteMailSceneType.BIRTHDAY_GIFT_RECEIVE_REMIND,
                                title: '<<khxmzgtzqddcftj>>',
                            },
                        },
                        message: {
                            type: 'object.birthdayMessageTime',
                            displayName: '<<tzsj>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },
                    },
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                // birthdayCaringType: {
                //     type: 'string.options.select',
                //     displayName: '领取时间',
                //     options: [
                //         { id: 'SAME_DAY', name: '生日当天' },
                //         { id: 'SAME_MONTH', name: '生日当月' },
                //         { id: 'FRONT_DAY', name: '<<custom>>' },
                //     ],
                // },
                subSites: {
                    type: 'array.popover',
                    displayName: '<<applicableSubsite>>',
                },
                merchants: {
                    type: 'array.popover',
                    displayName: '<<applyMerchants>>',
                },
                rewardMode: {
                    type: 'string.options.select',
                    displayName: '<<jlfs>>',
                    options: [
                        { id: RewardMode.RECEIVE, name: '<<lq>>' },
                        { id: RewardMode.SHOPPING_REWARD_DOUBLE, name: '<<gwhkjb>>' },
                    ],
                },
                rewardTypes: {
                    type: 'string.formatter',
                    displayName: '<<jlx>>',
                },
                unifiedReward: {
                    type: 'string.options.select',
                    displayName: '<<hydj>>',
                    options: [
                        { id: 'true', name: '<<sydjtygz>>' },
                        { id: 'false', name: '<<btdjbtgz>>' },
                    ],
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: [
                        { id: 'true', name: '<<beforeOpen>>' },
                        { id: 'false', name: '<<beforeClose>>' },
                    ],
                },
            },
        },
    },
    components: {
        BirthdayCaringView: {
            component: 'Viewport',
            entity: 'BirthdayCaringEntity',
        },
        BirthdayCaringPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'BirthdayCaringFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/birthday-caring/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'BirthdayCaringTable' },
            ],
        },
        BirthdayCaringFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
                style: {
                    marginLeft: 16,
                },
            },
            fields: [
                {
                    property: 'activityName',
                },
                {
                    property: 'subsiteId',
                    controlConfig: {
                        style: {
                            width: 200,
                        },
                        placeholder: '<<selectPlease>>',
                        showSearch: true,
                        allowClear: true,
                    },
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'status',
                    controlConfig: {
                        style: { width: 180 },
                    },
                },
            ],
        },
        BirthdayCaringTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                // {
                //     property: 'birthdayCaringType',
                // },
                {
                    property: 'subSites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'merchants',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<merchant>>',
                    },
                },
                {
                    property: 'rewardMode',
                },
                {
                    property: 'rewardTypes',
                    displayConfig: {
                        format: (value: string[]) => {
                            return value.join('、');
                        },
                    },
                    width: '240px',
                },
                {
                    property: 'unifiedReward',
                },
                {
                    property: 'status',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 130,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: 'true',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/birthday_caring_activities/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isCloseActivity>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: 'false',
                                    apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                                    apiPath: '/admin/birthday_caring_activities/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/birthday-caring/edit/{{row.id}}',
                    },
                ],
            },
        },
        BirthdayCaringAddPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'BirthdayCaringAddForm' }],
        },
        BirthdayCaringAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'BirthdayCaringEntity',
            labelCol: 4,
            controlCol: 20,
            style: { width: 1000 },
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.subsiteId' },
                {
                    property: 'baseInfo.birthdayCaringType',
                },
                {
                    property: 'baseInfo.frontDay',
                    visible: (values: any) =>
                        get(values, 'baseInfo.birthdayCaringType') === BirthdayCaringType.FRONT_DAY,
                },
                { property: 'rewardInfo.rewardMode' },
                {
                    property: 'rewardInfo.merchants',
                    visible: (values: any) =>
                        get(values, 'rewardInfo.rewardMode') === RewardMode.SHOPPING_REWARD_DOUBLE,
                },
                {
                    property: 'rewardInfo.receiveRule',
                    visible: (values: any) =>
                        get(values, 'rewardInfo.rewardMode') === RewardMode.RECEIVE,
                },
                {
                    property: 'rewardInfo.shoppingRewardRule',
                    visible: (values: any) =>
                        get(values, 'rewardInfo.rewardMode') === RewardMode.SHOPPING_REWARD_DOUBLE,
                },
                {
                    property: 'rewardInfo.picture',
                    visible: (values: any) =>
                        get(values, 'rewardInfo.rewardMode') === RewardMode.RECEIVE,
                },
                {
                    property: 'settingInfo.businessNotifications',
                    visible: (values: any) =>
                        get(values, 'rewardInfo.rewardMode') === RewardMode.RECEIVE,
                },
                {
                    property: 'settingInfo.message',
                    visible: (values: any) => {
                        return (
                            get(values, 'rewardInfo.rewardMode') === RewardMode.RECEIVE &&
                            filter(values.settingInfo.businessNotifications || [], ['status', 1])
                                .length
                        );
                    },
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: formValidate,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        BirthdayCaringEditPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'BirthdayCaringEditForm' }],
        },
        BirthdayCaringEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'BirthdayCaringEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            style: { width: 1000 },
            fields: [
                {
                    property: 'baseInfo.name',
                    controlConfig: {
                        disabled: true,
                        style: {
                            width: 400,
                        },
                    },
                },
                {
                    property: 'baseInfo.subsiteId',
                    controlConfig: {
                        disabled: true,
                        style: {
                            width: 300,
                        },
                    },
                },
                {
                    property: 'baseInfo.birthdayCaringType',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'baseInfo.frontDay',
                    controlConfig: {
                        disabled: true,
                        style: {
                            width: 100,
                        },
                        addonbefore: '<<srq>>',
                        addonafter: '<<day>>',
                    },
                    visible: (values: any) =>
                        get(values, 'baseInfo.birthdayCaringType') === BirthdayCaringType.FRONT_DAY,
                },
                { property: 'rewardInfo.rewardMode' },
                {
                    property: 'rewardInfo.merchants',
                    visible: (values: any) =>
                        get(values, 'rewardInfo.rewardMode') === RewardMode.SHOPPING_REWARD_DOUBLE,
                },
                {
                    property: 'rewardInfo.receiveRule',
                    visible: (values: any) =>
                        get(values, 'rewardInfo.rewardMode') === RewardMode.RECEIVE,
                },
                {
                    property: 'rewardInfo.shoppingRewardRule',
                    visible: (values: any) =>
                        get(values, 'rewardInfo.rewardMode') === RewardMode.SHOPPING_REWARD_DOUBLE,
                },
                {
                    property: 'rewardInfo.picture',
                    visible: (values: any) =>
                        get(values, 'rewardInfo.rewardMode') === RewardMode.RECEIVE,
                },
                {
                    property: 'settingInfo.businessNotifications',
                    visible: (values: any) =>
                        get(values, 'rewardInfo.rewardMode') === RewardMode.RECEIVE,
                },
                {
                    property: 'settingInfo.message',
                    visible: (values: any) => {
                        return (
                            get(values, 'rewardInfo.rewardMode') === RewardMode.RECEIVE &&
                            filter(values.settingInfo.businessNotifications || [], ['status', 1])
                                .length
                        );
                    },
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: formValidate,
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/birthday-caring',
                    component: 'BirthdayCaringView',
                    breadcrumbName: '<<birthdayCaring>>',
                    privilege: {
                        path: 'birthdayCaring',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'BirthdayCaringAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'BirthdayCaringEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        { path: '/', component: 'BirthdayCaringPage' },
                    ],
                },
            ],
        },
    ],
};
