import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { mapValues, defaults, get, forEach, debounce } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { LinkTypeSelectorBussinessType } from '../../types/mode/object/subsite-link-type-selector/subsite-link-type-selector';
import { checkLinkTypeParamsRequired } from '../../types/format';
import './index.less';

export interface EventMarketCopyFormViewProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
    data: any;
}

const EVENT_MARKET_TYPE_PAYMENT_REWARD = '10';

class eventMarketCopyFormView extends PureComponent<EventMarketCopyFormViewProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }

    render() {
        const { entity, onSubmit, wrappedComponentRef, data } = this.props;
        const { baseInfo, conditionConfig } = data;
        let countDisabled =
            conditionConfig &&
            ((conditionConfig.condition === '1' && conditionConfig.limits === '0') ||
                conditionConfig.limits === '-1');
        //订单范围如包含‘线上订单(1)’ 则奖励发放方式赋值为‘系统自动发放且不可编辑(disabled)’
        let isIncludeOnlineOrder =
            conditionConfig && conditionConfig.range && conditionConfig.range.includes('1');
        let fields = [
            {
                property: 'baseInfo.typeId',
                controlConfig: {
                    style: { width: 380 },
                    disabled: true,
                },
            },
            {
                property: 'baseInfo.name',
                controlConfig: {
                    style: { width: 380 },
                    maxLength: 100,
                },
            },
            {
                property: 'baseInfo.time',
                controlConfig: {
                    style: { width: 380 },
                    maxLength: 100,
                },
            },
            {
                property: 'baseInfo.subsiteId',
                visible: (values: any) => {
                    return get(values, 'baseInfo.typeId') === EVENT_MARKET_TYPE_PAYMENT_REWARD;
                },
                controlConfig: {
                    style: {
                        width: 380,
                    },
                    placeholder: services.language.getText('selectPlease'),
                    allowClear: true,
                    showSearch: true,
                    name: 'name',
                    dependences: 'name',
                },
            },
            {
                property: 'baseInfo.selectMode',
                visible: (values: any) => {
                    return get(values, 'baseInfo.typeId') === EVENT_MARKET_TYPE_PAYMENT_REWARD;
                },
            },
            {
                property: 'baseInfo.merchants',
                visible: (values: any) => {
                    return (
                        get(values, 'baseInfo.typeId') === EVENT_MARKET_TYPE_PAYMENT_REWARD &&
                        get(values, 'baseInfo.selectMode') !== '0'
                    );
                },
                controlConfig: {
                    subsiteField: 'baseInfo.subsiteId',
                    needDependencesText: services.language.getText('qxxzmdhzxzzj'),
                    hideSubsiteColume: false,
                    customFields: [
                        {
                            property: 'merchantType',
                        },
                        {
                            property: 'merchantTagId',
                        },
                        {
                            property: 'merchantName',
                        },
                    ],
                    customColumns: [
                        {
                            property: 'subSiteName',
                        },
                        {
                            property: 'merchantName',
                        },
                        {
                            property: 'merchantType',
                        },
                        {
                            property: 'merchantStatus',
                        },
                    ],
                },
            },
            {
                property: 'baseInfo.subsiteIds',
                visible: (values: any) => {
                    return get(values, 'baseInfo.typeId') !== EVENT_MARKET_TYPE_PAYMENT_REWARD;
                },
                controlConfig: {
                    style: { width: 380 },
                },
            },
            {
                property: 'baseInfo.awardAction',
                visible: (values: any) => {
                    return get(values, 'baseInfo.typeId') === '5';
                },
            },
            {
                property: 'baseInfo.status',
                visible: (values: any) => {
                    return get(values, 'baseInfo.typeId') !== EVENT_MARKET_TYPE_PAYMENT_REWARD;
                },
            },
            {
                property: 'baseInfo.marketingSchedule',
                controlConfig: {
                    style: { width: 380 },
                    maxLength: 100,
                },
            },
            /*  {
                property: 'baseInfo.sequence',
                controlConfig: {
                    style: { width: 380 },
                    placeholder: '请输入排序值',
                    max: 99999,
                    min: 1,
                },
            }, */
            {
                property: 'conditionConfig.range',
                visible: (values: any) => {
                    return get(values, 'baseInfo.typeId') === EVENT_MARKET_TYPE_PAYMENT_REWARD;
                },
                controlConfig: {
                    onChange: (value: any) => {
                        const store = builder.getStore();
                        if (value.includes('1')) {
                            //订单范围如包含‘线上订单(1)’ 则奖励发放方式赋值为‘系统自动发放且不可编辑(disabled)’
                            store.dispatch(
                                actions.formChangeAction(
                                    'DataFormPlus-EventMarketCopyFormContainer',
                                    'conditionConfig.grantMode',
                                    '0'
                                )
                            );
                        }
                    },
                },
            },
            {
                property: 'conditionConfig.grantMode',
                visible: (values: any) => {
                    return get(values, 'baseInfo.typeId') === EVENT_MARKET_TYPE_PAYMENT_REWARD;
                },
                controlConfig: {
                    disabled: isIncludeOnlineOrder,
                },
            },
            {
                property: 'conditionConfig.condition',
                visible: (values: any) => {
                    return get(values, 'baseInfo.typeId') === EVENT_MARKET_TYPE_PAYMENT_REWARD;
                },
            },
            {
                property: 'conditionConfig.claimTime',
                visible: (values: any) => {
                    return (
                        get(values, 'baseInfo.typeId') === EVENT_MARKET_TYPE_PAYMENT_REWARD &&
                        get(values, 'conditionConfig.grantMode') === '1'
                    );
                },
            },
            {
                property: 'conditionConfig.cycle',
                visible: (values: any) => {
                    return (
                        get(values, 'baseInfo.typeId') === EVENT_MARKET_TYPE_PAYMENT_REWARD &&
                        get(values, 'conditionConfig.condition') === '1'
                    );
                },
                controlConfig: {
                    style: { width: 100 },
                    addonafter: services.language.getText('day'),
                },
            },
            {
                property: 'conditionConfig.time',
                visible: (values: any) => {
                    return get(values, 'baseInfo.typeId') === EVENT_MARKET_TYPE_PAYMENT_REWARD;
                },
            },
            // {
            //     property: 'conditionConfig.threshold',
            //     visible: (values: any) => {
            //         return get(values, 'baseInfo.typeId') === EVENT_MARKET_TYPE_PAYMENT_REWARD;
            //     },
            // },
            {
                property: 'conditionConfig.limits',
                visible: (values: any) => {
                    return get(values, 'baseInfo.typeId') === EVENT_MARKET_TYPE_PAYMENT_REWARD;
                },
                controlConfig: {
                    onChange: (value: any) => {
                        const store = builder.getStore();
                        if (
                            value === '0' &&
                            data &&
                            data.conditionConfig &&
                            data.conditionConfig.condition === '1'
                        ) {
                            //奖励条件为累积周期&& 发放限制为每天每人发放数量限制时发放数量value 为1
                            store.dispatch(
                                actions.formChangeAction(
                                    'DataFormPlus-EventMarketCopyFormContainer',
                                    'conditionConfig.count',
                                    1
                                )
                            );
                        }
                        if (value === '-1') {
                            store.dispatch(
                                actions.formChangeAction(
                                    'DataFormPlus-EventMarketCopyFormContainer',
                                    'conditionConfig.count',
                                    ''
                                )
                            );
                        }
                    },
                },
            },
            {
                property: 'conditionConfig.count',
                visible: (values: any) => {
                    return get(values, 'baseInfo.typeId') === EVENT_MARKET_TYPE_PAYMENT_REWARD;
                },
                controlConfig: {
                    style: { width: '100px' },
                    precision: 0,
                    addonafter: services.language.getText('frequency'),
                    disabled: countDisabled,
                },
            },
            {
                property: 'conditionConfig.pictureEntrance',
                visible: (values: any) => {
                    return (
                        get(values, 'baseInfo.typeId') === EVENT_MARKET_TYPE_PAYMENT_REWARD &&
                        get(values, 'conditionConfig.grantMode') === '1'
                    );
                },
                controlConfig: {
                    maxCount: 1,
                    multiple: false,
                    fileName: 'files',
                    uploadType: 'multi',
                    uploadUrl: `${ENV.AUTH_API_ROOT}/FILE/admin/files/locations/127/upload`,
                    mode: 'picture-card',
                    extra: {
                        tip: services.language.getText('yyzxpxqlwyhtglqrk'),
                        img: './images/avatar/event-market-ticket-picture.png',
                        ext: services.language.getText('xjyl'),
                        triggerType: 'click',
                        style: {
                            maxWidth: '375px',
                            maxHeight: '400px',
                        },
                    },
                },
            },
            {
                property: 'conditionConfig.notification',
                visible: (values: any) => {
                    return get(values, 'baseInfo.typeId') === EVENT_MARKET_TYPE_PAYMENT_REWARD;
                },
            },
            {
                property: 'conditionConfig.recover',
                visible: (values: any) => {
                    return get(values, 'baseInfo.typeId') === EVENT_MARKET_TYPE_PAYMENT_REWARD;
                },
            },
            {
                property: 'rewardConfig.memberSource',
                visible: (values: any) => {
                    const typeId = get(values, 'baseInfo.typeId');
                    if (typeId !== EVENT_MARKET_TYPE_PAYMENT_REWARD) {
                        return true;
                    }
                    return false;
                },
            },
            {
                property: 'rewardConfig.registerMode',
                visible: (values: any) => {
                    return get(values, 'baseInfo.typeId') === '1';
                },
            },
            {
                property: 'rewardConfig.target',
                controlConfig: {
                    hideNeedUrlLink: true,
                    subsitePreRequired: true,
                    subsitePreRequiredMsg: services.language.getText('selectBeforeMerchant'),
                    bussinessType: LinkTypeSelectorBussinessType.EventMarket,
                },
                visible: (values: any) => {
                    return (
                        get(values, 'baseInfo.typeId') === '1' &&
                        get(values, 'rewardConfig.registerMode') === '1'
                    );
                },
            },
            {
                property: 'rewardConfig.reward',
                visible: (values: any) => {
                    const typeId = get(values, 'baseInfo.typeId');
                    if (typeId === '1') {
                        const mode = get(values, 'rewardConfig.registerMode');
                        if (mode === '0') {
                            return true;
                        }
                        return false;
                    } else if (typeId !== EVENT_MARKET_TYPE_PAYMENT_REWARD) {
                        return true;
                    }
                    return false;
                },
                controlConfig: {
                    eventTypeId: get(baseInfo, 'typeId'),
                },
            },
            {
                property: 'rewardConfig.ladderConf',
                visible: (values: any) => {
                    return get(values, 'baseInfo.typeId') === EVENT_MARKET_TYPE_PAYMENT_REWARD;
                },
                controlConfig: {
                    extra: services.language.getText('bhjf'),
                    columns: [
                        {
                            title: services.language.getText('jexx'),
                            key: 'totalMoneyMin',
                            align: 'center' as const,
                            type: 'InputNumber',
                        },
                        {
                            title: services.language.getText('jesx'),
                            key: 'totalMoneyMax',
                            align: 'center' as const,
                            type: 'InputNumber',
                        },
                        {
                            title: services.language.getText('yhq'),
                            key: 'coupons',
                            align: 'center' as const,
                            type: 'coupons',
                            displayValue: 'totalMoneyMin',
                        },
                        {
                            title: services.language.getText('common.tableAction'),
                            className: 'action-column',
                            key: 'id',
                            align: 'center' as const,
                            type: 'operations',
                        },
                    ],
                    initData: { totalMoneyMin: '', totalMoneyMax: '', coupons: [] },
                },
            },
            {
                property: 'description.pictures',
                visible: (values: any) => {
                    return get(values, 'baseInfo.typeId') === '8';
                },
            },
            {
                property: 'description.registerEventPicture',
                visible: (values: any) => {
                    return get(values, 'baseInfo.typeId') === '1';
                },
            },
            {
                property: 'description.systemPictures',
                visible: (values: any) => {
                    return (
                        get(values, 'baseInfo.typeId') === '1' &&
                        get(values, 'rewardConfig.registerMode') === '0'
                    );
                },
            },
            {
                property: 'description.auserPictures',
                visible: (values: any) => {
                    return (
                        get(values, 'baseInfo.typeId') === '1' &&
                        get(values, 'rewardConfig.registerMode') === '1'
                    );
                },
            },
            {
                property: 'description.registerEventUseButton',
                visible: (values: any) => {
                    return (
                        get(values, 'baseInfo.typeId') === '1' &&
                        get(values, 'rewardConfig.registerMode') === '0'
                    );
                },
            },
            // {
            //     property: 'description.picturesHelp',
            //     visible: (values: any) => {
            //         return (
            //             get(values, 'baseInfo.typeId') === '1' ||
            //             get(values, 'baseInfo.typeId') === '8'
            //         );
            //     },
            // },
            {
                property: 'description.remark',
            },
        ];

        let formProps = {
            entity,
            componentId: 'EventMarketCopyFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'event-market-copy-form-container',
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            // style: { width: 820 },
            fields: fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: services.language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;
    let form: any;

    /**
     * 提交前额外验证
     * @param entityFields
     */
    const validate = (entityFields: any) => {
        const { rewardConfig } = entityFields;
        //链接类型
        const typeId = get(entityFields, 'baseInfo.typeId', null);
        const registerMode = get(entityFields, 'rewardConfig.registerMode', null);
        const target = get(entityFields, 'rewardConfig.target', null);
        const registerEventUseButton = get(
            entityFields,
            'description.registerEventUseButton',
            null
        );
        if (typeId === '1' && registerMode === '1') {
            if (!target || !target.linkType) {
                AntMessage.warning(services.language.getText('qxzljlx'));
                return false;
            }
            if (target && !checkLinkTypeParamsRequired(target.linkType, target.linkParams)) {
                AntMessage.warning(services.language.getText('selectLinkTarget'));
                return false;
            }
            return true;
        }
        if (typeId === '1' && registerMode === '0') {
            // 奖励按钮图非必填，但如果设置了图片或者链接类型，那么图片和链接类型就必须都有
            const { picture, link: { linkType, linkParams } = { linkType: '', linkParams: null } } =
                registerEventUseButton || {};
            if (new Set([Boolean(picture), Boolean(linkType)]).size > 1) {
                AntMessage.warning(services.language.getText('registerEventUseButtonValidate'));
                return false;
            }
            if (linkType && !checkLinkTypeParamsRequired(linkType, linkParams)) {
                AntMessage.warning(services.language.getText('selectLinkTarget'));
                return false;
            }
        }
        // 奖品设置
        const rewards = rewardConfig.reward[0];
        const {
            couponsValue,
            pointChecked,
            pointMin,
            pointMax,
            pointPercentChecked,
            pointPercent,
            couponsChecked,
            experienceChecked,
            experienceMin,
            experienceMax,
            experiencePercentChecked,
            experiencePercent,
            goldChecked,
            reward,
        } = rewards;
        if (
            !pointChecked &&
            !pointPercentChecked &&
            !couponsChecked &&
            !experienceChecked &&
            !experiencePercentChecked &&
            !goldChecked
        ) {
            AntMessage.error(services.language.getText('qzsxzyzjl'));
            return false;
        }

        if (pointChecked && (!pointMin || !pointMax)) {
            AntMessage.error(services.language.getText('qjjftxwz'));
            return false;
        }
        if (pointChecked && pointMin && pointMax && pointMin > pointMax) {
            AntMessage.error(services.language.getText('zdjfjlbnxyzxjfjl'));
            return false;
        }
        if (pointPercentChecked && !pointPercent) {
            AntMessage.error(services.language.getText('qtxjfbfb'));
            return false;
        }

        if (couponsChecked && !couponsValue.length) {
            AntMessage.error(services.language.getText('qxzyhqhzyhqlb'));
            return false;
        }

        if (experienceChecked && (!experienceMin || !experienceMax)) {
            AntMessage.error(services.language.getText('qjjyztxwz'));
            return false;
        }
        if (experienceChecked && experienceMin && experienceMax && experienceMin > experienceMax) {
            AntMessage.error(services.language.getText('zdjybnxyzxjy'));
            return false;
        }
        if (experiencePercentChecked && !experiencePercent) {
            AntMessage.error(services.language.getText('qtxjybfb'));
            return false;
        }
        if (goldChecked && !reward) {
            AntMessage.error(services.language.getText('fillInbjlz'));
            return false;
        }

        return true;
    };

    //支付奖励奖品配置及短信checkbox校验
    const valiConfDate = (entityFields: any) => {
        const { conditionConfig, rewardConfig } = entityFields;
        const {
            limits,
            count,
            grantMode,
            notification,
            // pictureEntrance,
            condition,
            cycle,
        } = conditionConfig;
        const { ladderConf } = rewardConfig;
        let hasValidate = true;
        let maxConditionValue = 0;
        if (condition === '1' && !cycle) {
            AntMessage.error(services.language.getText('qsrljzqts'));
            return false;
        }
        if (limits !== '-1' && !count) {
            AntMessage.error(services.language.getText('qsrffcs'));
            return false;
        }
        //用户主动领取需校验短信及图片
        // if (grantMode === '1' && (!pictureEntrance || !pictureEntrance.length)) {
        //     AntMessage.error('请上传用户自动领取入口图片');
        //     return false;
        // }
        if (grantMode === '1' && notification && notification.channel && !notification.content) {
            AntMessage.error(services.language.getText('qsrdxnr'));
            return false;
        }
        // if (grantMode === '1' && notification && notification.channel && !notification.agreement) {
        //     AntMessage.error('请查看并同意短信服务协议');
        //     return false;
        // }
        forEach(ladderConf, (item: any, index: any) => {
            if (!item.totalMoneyMin || !item.totalMoneyMax) {
                AntMessage.error(services.language.getText('qtxjpszxzhdje'));
                hasValidate = false;
                return false;
            }
            if (!item.coupons || !item.coupons.length) {
                AntMessage.error(services.language.getText('qtxjpszxzhdyhqhzyhqlb'));
                hasValidate = false;
                return false;
            }
            if (item.totalMoneyMin > item.totalMoneyMax) {
                AntMessage.warning(
                    services.interpolate(services.language.getText('gjpjtjesxxydyjexxdje'), {
                        num: index + 1,
                    })
                );
                hasValidate = false;
                return false;
            }
            if (item.totalMoneyMin > maxConditionValue) {
                maxConditionValue = item.totalMoneyMax;
            } else {
                AntMessage.warning(
                    services.interpolate(services.language.getText('gjpjtjexxxydysgjtdjesx'), {
                        num: index + 1,
                    })
                );
                hasValidate = false;
                return false;
            }
        });
        if (!hasValidate) {
            return false;
        }
        return true;
    };

    return defaults(
        {
            onSubmit,
            onFieldChange,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: debounce((event: any, fields: any) => {
                const entityFields = mapValues(fields, (field, name) => {
                    return field.value;
                });
                const {
                    baseInfo: { typeId },
                } = entityFields;
                if (entityFields) {
                    const hasValidate =
                        typeId === EVENT_MARKET_TYPE_PAYMENT_REWARD
                            ? valiConfDate(entityFields)
                            : validate(entityFields);
                    if (hasValidate) {
                        entity.add(entityFields, params);
                    }
                }
            }, 300),
            data:
                get(_dispatch, 'components.["DataFormPlus-EventMarketCopyFormContainer"].fields') ||
                {},

            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(
                        actions.unmountComponentAction('DataFormPlus-EventMarketCopyFormContainer')
                    );
            },
        }
    );
}
export const EventMarketCopyFormView = connect(mapDispatchToProps)(eventMarketCopyFormView);
