// @ts-nocheck
import React, { Component } from 'react';
import { Modal, Upload, Icon, Button, message as AntMessage } from 'antd';
import { ExportButton } from '@comall-backend-builder/components-basis';
import { services } from '@comall-backend-builder/core';
import { get } from 'lodash';
import { ForwardDataCenterModal } from '../../../services';

const { Dragger } = Upload;
export class UploadFile extends Component<any, any> {
    state = {
        visible: false,
        confirmLoading: false,
        statusMsg: services.language.getText('uploadFileClickButton'),
        resource: {
            resourceId: 0,
            resourceName: '',
            resourceUrl: '',
            type: 'IMPORT',
        },
        fileList: [],
    };
    onImport = (resource: any) => {
        const { row, pageType } = this.props;
        const registerTimeRange = get(row, 'roundsInfo.registerTimeRange');
        const apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`;
        const apiPath =
            pageType === 'normal'
                ? '/admin/presale/import_round?showWhite=false'
                : '/admin/presale/import_round';
        services.api
            .post(
                {
                    id: resource.resourceId,
                    fileUrl: resource.resourceUrl,
                    startRegisterTime: registerTimeRange.start,
                    endRegisterTime: registerTimeRange.end,
                },
                { apiRoot, apiPath }
            )
            .then((res: any) => {
                ForwardDataCenterModal();
                this.props.onOk(res ? res.roundVos : []);
            })
            .catch((err: any) => {
                AntMessage.error(err.response.body.err_msg);

                this.setState({
                    statusMsg: services.language.getText('importFail'),
                });
                throw err;
            });
        return false;
    };

    handleOk = () => {
        const { resource } = this.state;
        if (resource && resource.resourceId) {
            this.onImport(resource);
        } else {
            AntMessage.error(services.language.getText('pleaseUploadFile'));
            return;
        }
    };
    closeModal = () => {
        this.props.onClose();
    };
    render() {
        const { fileList, statusMsg } = this.state;
        const uploadProps = {
            accept: '.xlsx,.xls',
            multiple: false,
            onRemove: (file: any) => {
                this.setState((state: any) => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                    };
                });
            },
            beforeUpload: (file: any) => {
                const apiRoot = `${ENV.AUTH_API_ROOT}/FILE`;
                const apiPath = '/admin/files/locations/560/upload';
                services.api
                    .upload(
                        { files: file },
                        { apiRoot, apiPath, fileName: 'files', progressCallBack: () => {} }
                    )
                    .then((res: any) => {
                        const { name, path, id } = res;
                        this.setState({
                            resource: {
                                resourceId: id,
                                resourceName: name,
                                resourceUrl: path,
                                type: 'IMPORT',
                            },
                            fileList: [file],
                        });
                    })
                    .catch((err: any) => {
                        if (err) {
                            AntMessage.error(
                                err.response?.body?.err_msg ||
                                    services.language.getText('uoloadFail')
                            );
                        }
                    });
                return false;
            },
            fileList,
        };
        const { visible, pageType } = this.props;
        return (
            <Modal
                onCancel={this.closeModal}
                visible={visible}
                title={services.language.getText('batchImport')}
                footer={[
                    <span className="internal-purchase-goods-status-msg">{statusMsg}</span>,
                    <Button key="submit" type="primary" onClick={this.handleOk}>
                        导入
                    </Button>,
                    <Button key="back" onClick={this.closeModal}>
                        {services.language.getText('back')}
                    </Button>,
                ]}
            >
                <Dragger {...uploadProps}>
                    <p className="ant-upload-drag-icon">
                        <Icon type="inbox" />
                    </p>
                    <p className="ant-upload-text">{services.language.getText('selectFile')}</p>
                    <p className="ant-upload-hint">支持扩展名：.xlsx、.xls</p>
                </Dragger>
                <div className="upload-tips">
                    <ExportButton
                        type="link"
                        text={services.language.getText('xzpldrmb')}
                        showErrorMessage={true}
                        source={{
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                            apiPath:
                                pageType === 'normal'
                                    ? '/admin/presale/download_round_template?showWhite=false'
                                    : '/admin/presale/download_round_template',
                        }}
                    />
                </div>
                <div className="import-button-tips">
                    <p className="import-button-tips-text">
                        {services.language.getText('importRules')}
                    </p>
                    <p className="import-button-tips-text">
                        {services.language.getText('merchantBigCodeIntro1')}
                    </p>
                    <p className="import-button-tips-text-red">
                        {services.language.getText('merchantBigCodeNote')}
                    </p>
                    <p className="import-button-tips-text">
                        2.当前仅支持xlxs、xls、csv格式文件（建议文件大小在20M以内且行数在10万以内，请使用Office
                        2010及以上版本，否则可能出现乱码
                    </p>
                    <p className="import-button-tips-text">3.请严格参考模版内的信息进行填写</p>
                </div>
            </Modal>
        );
    }
}
