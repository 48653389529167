import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { ArticleSeletor, ArticleSelected } from '../../../components';

export class ArticleSelectorMode extends modes.ArrayMode {
    getDisplayComponent(value: any) {
        return <ArticleSelected data={value} onRemove={() => {}} disabled={true}></ArticleSelected>;
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <ArticleSeletor {...displayInfo} />;
    }
}
