import React, { Component } from 'react';
import { InputNumber, Button, Checkbox } from 'antd';
import { cloneDeep, get } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

export interface MallActivityPayValue {
    list: PeriodLimitItem[];
}

export interface PeriodLimitItem {
    /**
     * 限制次数
     */
    limit: string;
    //
    leastLimit?: string;
}

export interface AffiliateMarketingPeriodLimitProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    value: MallActivityPayValue | null;
    /**
     * 是否禁用（禁用只限制修改阶梯值、赠删阶梯，不限制修改阶梯内奖励内容）
     */
    disabled: boolean;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: MallActivityPayValue | null, name: string) => void;
    //标记页面名称
    markPage?: string;
    row?: any;
}

export class AffiliateMarketingPeriodLimit extends Component<AffiliateMarketingPeriodLimitProps> {
    harderChangeSetting = () => {
        const { onChange, name, value } = this.props;
        if (value && value.list && value.list.length > 0) {
            onChange(
                {
                    list: [],
                },
                name
            );
        } else {
            onChange(
                {
                    list: [
                        {
                            limit: '',
                            leastLimit: '',
                        },
                    ],
                },
                name
            );
        }
    };

    changeLimitValue = (limitValue: string, index: number) => {
        const { value, onChange, name } = this.props;
        const list = cloneDeep(value!.list);
        const record: PeriodLimitItem = list[index];
        list.splice(index, 1, {
            ...record,
            limit: limitValue,
        });
        onChange(
            {
                list,
            },
            name
        );
    };
    onCheckChange = (e: any, index: number) => {
        const { value, onChange, name } = this.props;
        const { checked } = e.target;
        const list = cloneDeep(value!.list);
        const record: PeriodLimitItem = list[index];
        list.splice(index, 1, {
            ...record,
            leastLimit: checked ? '1' : '',
        });
        onChange(
            {
                list,
            },
            name
        );
    };

    render() {
        const { value, markPage, row } = this.props;
        let maxDrawQuantity = 0;
        //额外奖励
        let activityDrawLimit = null;

        if (markPage && markPage === 'openBox') {
            const activityRewardLimit = get(row, 'ruleInfo.activityRewardLimit', null);
            const inviteFriends = get(row, 'ruleInfo.inviteFriends', null);
            if (activityRewardLimit) {
                maxDrawQuantity += activityRewardLimit;
            }
            if (inviteFriends && inviteFriends?.length) {
                let arr = cloneDeep(inviteFriends).sort(function(a: any, b: any) {
                    return b.rewardValue - a.rewardValue;
                });
                if (arr?.length && arr[0]?.rewardValue) maxDrawQuantity += arr[0].rewardValue;
            }
            if (get(row, 'ruleInfo.activityDrawLimit')) {
                activityDrawLimit = get(row, 'ruleInfo.activityDrawLimit');
            }
        }
        let userText =
            activityDrawLimit?.normalConditionType === 'CRM_NEW_MEMBER'
                ? services.language.getText('xzcyh')
                : services.language.getText('scdlyh');
        let max = maxDrawQuantity
            ? activityDrawLimit?.exchangeLimit
                ? maxDrawQuantity + activityDrawLimit.exchangeLimit
                : maxDrawQuantity
            : 999999;
        return (
            <div>
                <Button type="primary" onClick={this.harderChangeSetting}>
                    {value && value.list && value.list.length > 0
                        ? services.language.getText('clearSetting')
                        : services.language.getText('settings')}
                </Button>
                {value && value.list && value.list.length > 0 && (
                    <div>
                        {markPage && markPage === 'openBox' && maxDrawQuantity !== 0 && (
                            <div>
                                {services.language.getText('myhzhdqjzdkhdcjjhw')}
                                {maxDrawQuantity}
                                {services.language.getText('frequency')}
                                {activityDrawLimit && activityDrawLimit.exchangeLimit
                                    ? `，${userText}${language.getText('ewhd')}${
                                          activityDrawLimit.exchangeLimit
                                      }${language.getText('frequency')}`
                                    : ''}
                            </div>
                        )}
                        {value.list.map((item: PeriodLimitItem, index: number) => {
                            const checked = item?.leastLimit === '1';
                            return (
                                <div key={index}>
                                    <div style={{ marginTop: '5px' }}>
                                        <span>
                                            {services.language.getText('zdzjcsxz')}&nbsp;&nbsp;
                                        </span>
                                        <InputNumber
                                            placeholder={services.language.getText('inputPlease')}
                                            value={item.limit ? parseInt(item.limit) : undefined}
                                            min={1}
                                            max={max}
                                            precision={0}
                                            onChange={(value: any) =>
                                                this.changeLimitValue(value, index)
                                            }
                                        ></InputNumber>
                                        <span>
                                            &nbsp;&nbsp;{services.language.getText('frequency')}
                                        </span>
                                    </div>
                                    {markPage && markPage === 'openBox' && (
                                        <div>
                                            {index === value.list.length - 1 && (
                                                <div className="ant-form-extra">
                                                    {services.language.getText('jpbkjfhyhq')}
                                                </div>
                                            )}
                                            <div>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {services.language.getText('zszjcsxz')}
                                                    &nbsp;&nbsp;
                                                    <Checkbox
                                                        checked={checked}
                                                        value={item}
                                                        onChange={(value: any) =>
                                                            this.onCheckChange(value, index)
                                                        }
                                                    ></Checkbox>
                                                    &nbsp;&nbsp;{' '}
                                                    {services.language.getText('bzjyc')}
                                                </div>
                                                <div className="ant-form-extra">
                                                    {services.language.getText('yhjjcsdcsdd')}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
        );
    }
}
