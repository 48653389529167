import React, { Component } from 'react';
import { InputNumber } from 'antd';
import { message as AntMessage } from 'antd';
import { isEmpty } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

/**
 * 抵扣规则值对象
 */
interface PointExchangeRuleValue {
    exchangeRatio: number;
    exchangeAmount: number;
}
interface PointExchangeRuleProps {
    onChange: (value: any) => void;
    value: PointExchangeRuleValue | undefined;
    isNoDefault: boolean | false; //是否显示默认值
    contentReg: boolean | false; //为true的话有值才校验数据格式,原校验格式不动(只加了为false的判断)
    companyText: string | '';
}

export class PointExchangeRule extends Component<PointExchangeRuleProps> {
    changeValue = (value: any) => {
        const currentValue = this.props.value ? this.props.value : {};
        const newValue = Object.assign({ ...currentValue }, { ...value });
        this.props.onChange(newValue);
    };

    onChangeExchangeRatio = (exchangeRatio: any) => {
        this.changeValue({ exchangeRatio });
    };
    onValidExchangeRatio = (e: any) => {
        const { contentReg } = this.props;
        const exchangeRatio = e.target.value;
        const reg = /^\+?[0-9]\d*$/;
        if (!reg.test(String(exchangeRatio)) && !contentReg) {
            AntMessage.warning(language.getText('jdkdwdydsz'));
            return;
        }
        if (contentReg && exchangeRatio && !reg.test(exchangeRatio)) {
            AntMessage.warning(language.getText('jdkdwdydsz'));
            return;
        }
        this.changeValue({ exchangeRatio });
    };
    onChangeExchangeAmount = (exchangeAmount: any) => {
        this.changeValue({ exchangeAmount });
    };
    onValidExchangeAmount = (e: any) => {
        const { contentReg } = this.props;
        const exchangeAmount = e.target.value;
        const reg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;

        if (!reg.test(String(exchangeAmount)) && !contentReg) {
            AntMessage.warning(language.getText('jdkjeqtxdydsz'));
            return;
        }
        if (contentReg && !isEmpty(exchangeAmount) && !reg.test(exchangeAmount)) {
            AntMessage.warning(language.getText('jdkjeqtxdydsz'));
            return;
        }

        this.changeValue({ exchangeAmount });
    };

    render() {
        const { value, isNoDefault, companyText } = this.props;
        return (
            <div className="point-exchange-rule">
                <div className="rule-item">
                    <span className="item-text">{language.getText('zxdkdw')}</span>
                    <InputNumber
                        className="item-input"
                        min={1}
                        step={1}
                        value={
                            value && value.exchangeRatio > 0
                                ? value.exchangeRatio
                                : isNoDefault
                                ? undefined
                                : 1
                        }
                        onChange={this.onChangeExchangeRatio}
                        onBlur={this.onValidExchangeRatio}
                    />
                    <span className="item-text">
                        {companyText ? companyText : language.getText('point')} ={' '}
                    </span>
                    <InputNumber
                        className="item-input"
                        min={0.01}
                        step={1}
                        value={
                            value && value.exchangeAmount > 0
                                ? value.exchangeAmount
                                : isNoDefault
                                ? undefined
                                : 1
                        }
                        onChange={this.onChangeExchangeAmount}
                        onBlur={this.onValidExchangeAmount}
                    />
                    <span className="item-text">{language.getText('yuan')}</span>
                </div>
            </div>
        );
    }
}
