import { Config } from '@comall-backend-builder/core/lib/parser';
import { message } from 'antd';
import { TipsModalContentType } from '../../../components';
import { services } from '@comall-backend-builder/core';

export enum ServiceSettingsStatus {
    ENABLE = 'ENABLED',
    DISABLE = 'DISABLED',
}
export const config: Config = {
    entities: {
        MyCouponsSettingEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/member_coupon_settings',
            filters: {
                subsiteIds: {
                    type: 'string.options.autoComplete.plus',
                    displayName: '<<applicableSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 240 },
                        allowClear: true,
                    },
                },
            },
            properties: {
                subsites: {
                    type: 'array.popover',
                    displayName: '<<applicableSubsite>>',
                },
                couponSourcesStr: {
                    type: 'string.date',
                    displayName: '<<qly>>',
                },
                couponModesStr: {
                    type: 'string',
                    displayName: '<<wdyhqzssj>>',
                },
                couponCenterDefaultSourceStr: {
                    type: 'string',
                    displayName: '<<mrjrdym>>',
                },
                tips: {
                    type: 'string',
                    displayName: '<<wxts>>',
                    rules: [{ required: false }],
                    extra: '<<jsxgwdyhqdzsgz>>',
                },
                subsiteIds: {
                    type: 'array.subsites.selector',
                    displayName: '<<applicableSubsite>>',
                    defaultValue: [],
                    rules: [{ required: true }],
                },
                couponSources: {
                    type: 'array.optionIds.checkbox.popover',
                    displayName: '<<qly>>',
                    defaultValue: ['ONLINE'],
                    rules: [
                        {
                            required: true,
                            message: '<<qxzyzsdyhqnr>>',
                        },
                    ],
                    options: [
                        {
                            id: 'ONLINE',
                            name: '<<xsq>>',
                        },
                        {
                            id: 'OFFLINE',
                            name: '<<sfq>>',
                        },
                    ],
                    controlConfig: {
                        popoverConfigs: [
                            {
                                showIcon: true,
                                imageUrl: './images/editor/coupon-online.png',
                            },
                            {
                                showIcon: true,
                                imageUrl: './images/editor/coupon-offline.png',
                            },
                        ],
                    },
                },
                couponModes: {
                    type: 'array.optionIds.checkbox.popover',
                    displayName: '<<wdyhqzssjnr>>',
                    defaultValue: ['GENERATE_COUPON_CODE', 'PREPARE_COUPON_CODE'],
                    rules: [{ required: true, message: '<<qxzyzsdyhqnr>>' }],
                    options: [
                        {
                            id: 'GENERATE_COUPON_CODE',
                            name: '<<jzszqfs>>',
                        },
                        {
                            id: 'PREPARE_COUPON_CODE',
                            name: '<<jzsdrsfqmdq>>',
                        },
                    ],
                    controlConfig: {
                        popoverConfigs: [
                            {
                                showIcon: false,
                            },
                            {
                                showIcon: true,
                                contentText: '<<h_wdyhqzssjnr_popover>>',
                            },
                        ],
                    },
                },
                couponCenterDefaultSource: {
                    type: 'string.options.radio',
                    displayName: '<<dkwdyhqmrym>>',
                    defaultValue: 'ONLINE',
                    options: [
                        { id: 'ONLINE', name: '<<xsqyq>>' },
                        { id: 'OFFLINE', name: '<<sfqyq>>' },
                    ],
                    rules: [{ required: true }],
                },
            },
        },
        MemberBalanceSettingsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/member_balance_settings',
            properties: {
                balanceCouponQrCode: {
                    type: 'array.checkboxWithModalTip',
                    displayName: '<<yeqxxzfewmzswz>>',
                    defaultValue: [],
                    options: [
                        {
                            name: '<<hyzxhymxf>>',
                            id: 'MemberQRCode',
                        },
                        {
                            name: '<<wdyeqlbym>>',
                            id: 'MyBalanceCoupons',
                        },
                    ],
                    controlConfig: {
                        tipsModalConfig: {
                            type: 'extra',
                            extraConfig: {
                                before: '<<h_yeqxxzfewmzswz_before>>',
                            },
                            contentType: TipsModalContentType.BalanceCouponQrCode,
                            showIcon: false,
                            text: '<<viewExample>>',
                            textColor: '#1890ff',
                            title: '',
                            width: 1048,
                        },
                    },
                },
                balanceCouponQrCodeName: {
                    type: 'string',
                    displayName: '<<yeqxxzfewmmc>>',
                    controlConfig: {
                        placeholder: '<<inputPlease>>',
                        maxLength: 10,
                        style: {
                            width: 400,
                        },
                    },
                },
                balanceCouponShowMode: {
                    type: 'string.radio.popover',
                    displayName: '<<yeqlbzsfs>>',
                    defaultValue: 'SINGLE_COUNT',
                    options: [
                        {
                            id: 'SINGLE_COUNT',
                            name: '<<qsl>>',
                        },
                        {
                            id: 'MULTI_AMOUNT',
                            name: '<<qje>>',
                        },
                    ],
                    controlConfig: {
                        popoverConfigs: [
                            {
                                showIcon: true,
                                imageUrl: './images/editor/balance-coupon-single-count.png',
                            },
                            {
                                showIcon: true,
                                imageUrl: './images/editor/balance-coupon-multi-amount.png',
                            },
                        ],
                        tipConfigs: ['<<yhckqlbzxyxzmdckdyqxx>>', '<<hyzczzsxxsymdkyqjezh>>'],
                    },
                },
            },
        },
    },
    components: {
        MemberAssetsSettingView: {
            component: 'Viewport',
        },
        MemberAssetsSettingPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            style: {
                padding: '12px 32px 24px 32px',
            },
            items: [{ component: 'MemberAssetsSettingTabs' }],
        },
        MemberAssetsSettingTabs: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<wdyhqsz>>',
                    content: {
                        component: 'MyCouponsSettingPage',
                    },
                },
                {
                    title: '<<yeqsz>>',
                    content: {
                        component: 'MemberBalanceSettingsView',
                    },
                },
            ],
        },
        MyCouponsSettingPage: {
            entity: 'MyCouponsSettingEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'myCouponsSettingFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            style: {
                                display: 'flex',
                            },
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzgz>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    route: '/member-assets-setting/my-coupons-settings/add',
                                },
                                {
                                    component: 'CouponQrcodeValidPeriodSetting',
                                },
                            ],
                        },
                    ],
                },
                { component: 'myCouponsSettingTable' },
            ],
        },
        myCouponsSettingFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteIds',
                },
            ],
        },
        myCouponsSettingTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'subsites',
                },
                {
                    property: 'couponSourcesStr',
                },
                {
                    property: 'couponModesStr',
                },
                {
                    property: 'couponCenterDefaultSourceStr',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                width: 250,
                className: 'column-left action-column',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    value: ServiceSettingsStatus.ENABLE,
                                    apiPath: '/admin/member_coupon_settings/:id/disabled',
                                    config: {
                                        content: '<<beforeClose>>',
                                        text: '<<common.close>>',
                                        style: {
                                            color: 'red',
                                        },
                                    },
                                    confirm: {
                                        text: '<<sfgbsz>>?',
                                    },
                                },
                                {
                                    value: ServiceSettingsStatus.DISABLE,
                                    apiPath: '/admin/member_coupon_settings/:id/enabled',
                                    config: {
                                        content: '<<beforeOpen>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>><<settings>>?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/member-assets-setting/my-coupons-settings/edit/{{row.id}}',
                    },
                ],
            },
        },
        MyCouponsSettingsAddPage: {
            component: 'Card',
            entity: 'MyCouponsSettingEntity',
            content: { component: 'MyCouponsSettingAddFormView' },
        },
        MyCouponsSettingsEditPage: {
            component: 'Card',
            entity: 'MyCouponsSettingEntity',
            loaderType: 'get',
            direction: 'horizontal',
            content: { component: 'MyCouponsSettingEditFormView' },
        },
        MemberBalanceSettingsView: {
            component: 'Card',
            entity: 'MemberBalanceSettingsEntity',
            loaderType: 'get',
            content: {
                component: 'MemberBalanceSettingsForm',
            },
        },
        MemberBalanceSettingsForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            loaderType: 'get',
            labelCol: 6,
            controlCol: 18,
            style: { width: 1200 },
            fields: [
                {
                    property: 'balanceCouponQrCode',
                },
                {
                    property: 'balanceCouponQrCodeName',
                },
                {
                    property: 'balanceCouponShowMode',
                },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'submit',
                        text: '<<common.save>>',
                    },
                ],
            },
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'));
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/member-assets-setting',
                    component: 'MemberAssetsSettingView',
                    breadcrumbName: '<<MemberAssetsSetting>>',
                    privilege: {
                        path: 'memberAssetsSetting',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/my-coupons-settings/add',
                            component: 'MyCouponsSettingsAddPage',
                        },
                        {
                            path: '/my-coupons-settings/edit/:id',
                            component: 'MyCouponsSettingsEditPage',
                        },
                        { path: '/', component: 'MemberAssetsSettingPage' },
                    ],
                },
            ],
        },
    ],
};
