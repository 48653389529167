import React, { PureComponent } from 'react';
import { Row, Col, Switch, Input } from 'antd';
import { get } from 'lodash';
import { tools } from '@comall-backend-builder/design-components-basis';
import { IProductMore } from './index';
import './index.less';

const LinkType = tools.LinkType;
const UploadPicture = tools.UploadPicture;

export type LinkTypeMode = 'select' | 'cascader';

interface iProps {
    value: IProductMore;
    selector: any;
    onChange: (args: any) => void;
    uploadAction: string;
    sourceRoot: { image?: string };
    linkTypes: Array<any>;
    linkTypeMode?: LinkTypeMode;
}
export default class ProductMore extends PureComponent<iProps, any> {
    onUpload = (path: string) => {
        this.change('pic', path);
    };
    change = (type: any, val: any) => {
        let { onChange, value } = this.props;
        let newValue: any = {
            pic: get(value, 'pic', null),
            title: get(value, 'title', ''),
            text: get(value, 'text', ''),
            border: get(value, 'border', false),
            linkParams: get(value, 'linkParams', null),
            linkType: get(value, 'linkType', 'none'),
        };
        if (type !== 'linkTypes') {
            newValue[type] = val;
        } else {
            newValue['linkParams'] = val.linkParams;
            newValue['linkType'] = val.linkType;
        }
        onChange({ ...newValue });
    };
    render() {
        let { value, selector, uploadAction, linkTypes, linkTypeMode, sourceRoot } = this.props;
        return (
            <Row className="entry-item">
                <Col className="entry-item-image" span={4}>
                    <UploadPicture
                        sourceRoot={sourceRoot}
                        value={get(value, 'pic', '')}
                        uploadAction={uploadAction}
                        onChange={this.onUpload}
                    />
                </Col>
                <Col className="entry-item-content" span={19}>
                    <LinkType
                        linkTypeMode={linkTypeMode}
                        linkTypes={linkTypes}
                        selector={selector}
                        value={{
                            linkParams: get(value, 'linkParams', null),
                            linkType: get(value, 'linkType', 'none'),
                        }}
                        onChange={(value) => this.change('linkTypes', value)}
                    />
                    <div className="link-item-field">
                        <label className="field-name">主文本：</label>
                        <Input
                            value={get(value, 'title', '')}
                            className="link-input"
                            onChange={(e) => this.change('title', e.target.value)}
                        />
                    </div>
                    <div className="link-item-field">
                        <label className="field-name">副文本：</label>
                        <Input
                            className="link-input"
                            value={get(value, 'text', '')}
                            onChange={(e) => this.change('text', e.target.value)}
                        />
                    </div>
                    <div className="link-item-field">
                        <label className="field-name">边框：</label>
                        <Switch
                            onChange={(checked) => this.change('border', checked)}
                            checked={get(value, 'border', false)}
                        />
                    </div>
                </Col>
            </Row>
        );
    }
}
