import React, { Component } from 'react';
import { Icon } from 'antd';
import { connect } from 'react-redux';
import { get } from 'lodash';
import * as actions from '@comall-backend-builder/core/lib/actions';
import { localStorage, api, language } from '@comall-backend-builder/core/lib/services';
import { refreshToken } from '../../services/refresh-token';
/**
 * type: 'oauth' 授权码登出模式 需要跳转到指定地址
 */
class logout extends Component<any, any> {
    logout = () => {
        const token = window.btoa(`${ENV.CLIENT_ID}:${ENV.CLIENT_SECRET}`);
        let customHeaders = {
            Authorization: `Basic ${token}`,
            'Content-Type': 'application/x-www-form-urlencoded',
        };
        const user: any = localStorage.get('user');
        const accessToken = get(user, 'accessToken', null);
        const apiPath = `/oauth/token?access_token=${accessToken}`;
        // 清除拣货助手中，已选门店的数据
        const cyStore = localStorage.get('cyStore');
        if (cyStore) {
            localStorage.remove('cyStore');
        }
        // 清除模板消息中，已选门店的数据
        const wechantTemplatesStore = localStorage.get('wechantTemplatesStore');
        if (wechantTemplatesStore) {
            localStorage.remove('wechantTemplatesStore');
        }
        // 清除档期里，已选门店的数据
        const marketingCalendarPageSettingStoreSelector = localStorage.get(
            'marketingCalendarPageSettingStoreSelector'
        );
        if (marketingCalendarPageSettingStoreSelector) {
            localStorage.remove('marketingCalendarPageSettingStoreSelector');
        }
        api.delete({}, { apiPath, apiRoot: ENV.AUTH_ROOT, customHeaders }).then(() => {
            this.props.logout();
            if (get(this.props, 'type', null) === 'oauth') {
                refreshToken.clearLoginInfo();
            }
        });
    };
    render() {
        return (
            <div onClick={this.logout}>
                <Icon type="logout"></Icon>
                <span>{language.getText('tcdl')}</span>
            </div>
        );
    }
}

export const Logout = connect(null, (dispatch) => {
    return {
        logout: () => {
            dispatch(actions.logoutSuccessAction());
        },
    };
})(logout);
