import React, { PureComponent } from 'react';
import { Button, Modal, Input } from 'antd';
import { services } from '@comall-backend-builder/core';

const { api } = services;
const { TextArea } = Input;
const config = {
    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
    apiPath: '/admin/presale/remark',
};
export interface MaotaiRemarkProps {
    name: string;
    value: any[];
    onChange: (value: any[]) => void;
}
export class MaotaiRemark extends PureComponent<MaotaiRemarkProps> {
    state = { visible: false, remark: '' };

    showModal = () => {
        api.get({}, config).then((res: any) => {
            this.setState({
                remark: res.remark || '',
                visible: true,
            });
        });
    };

    handleOk = () => {
        const { remark = '' } = this.state;

        api.put({ remark }, config).then((res: any) => {
            this.setState({
                visible: false,
            });
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };

    onChange = ({ target: { value } }: any) => {
        this.setState({ remark: value });
    };

    render() {
        const { remark } = this.state;
        return (
            <div>
                <Button type="primary" onClick={this.showModal}>
                    {services.language.getText('szhdgg')}
                </Button>
                <Modal
                    title={services.language.getText('szhdgg')}
                    visible={this.state.visible}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                >
                    <TextArea
                        value={remark}
                        onChange={this.onChange}
                        maxLength={120}
                        placeholder={services.language.getText('qsrhdgg')}
                        autoSize={{ minRows: 3, maxRows: 8 }}
                    />
                </Modal>
            </div>
        );
    }
}
