import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        NewMallActivityTicketPurchaseRecordsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
            apiPath: '/loader/admin/v1/mall_activity/ticket_purchase_records',
            filters: {
                mallActivityName: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                subsiteId: {
                    type: 'string.options.select',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                merchantId: {
                    type: 'string.filterOptions.autoComplete',
                    displayName: '<<merchant>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                createOrderMemberMobile: {
                    type: 'string',
                    displayName: '<<xdrsjh>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                joinMobile: {
                    type: 'string',
                    displayName: '<<cyrsjh>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                joinName: {
                    type: 'string',
                    displayName: '<<cyrxm>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                    controlConfig: { placeholder: '<<inputOrderNumber>>' },
                },
                ticketName: {
                    type: 'string',
                    displayName: '<<pdmc>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<xdsj>>',
                },
                joinDate: {
                    type: 'object.dateRangePlus',
                    displayName: '<<cyrq>>',
                },
            },
            properties: {
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                mallActivityName: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                ticketName: {
                    type: 'string',
                    displayName: '<<pdmc>>',
                },
                ticketsPurchasedQuantity: {
                    type: 'number',
                    displayName: '<<gmsl>>',
                },
                totalSalesPrice: {
                    type: 'number',
                    displayName: '<<xjzje>>',
                },
                totalPointPrice: {
                    type: 'number',
                    displayName: '<<jfze>>',
                },
                // orderStatus: {
                //     type: 'string',
                //     displayName: '<<ddzt>>',
                // },
                orderStatusStr: {
                    type: 'string',
                    displayName: '<<ddzt>>',
                },
                orderCreateTime: {
                    type: 'string',
                    displayName: '<<xdsj>>',
                },
                joinTime: {
                    type: 'string',
                    displayName: '<<cysj>>',
                },
                registrationTimeSnapshot: {
                    type: 'string',
                    displayName: '<<cysj>>',
                },
                createOrderMemberMobile: {
                    type: 'string.date',
                    displayName: '<<xdrsjh>>',
                },
                joinName: {
                    type: 'string.statusBadge',
                    displayName: '<<cyrxm>>',
                },
                joinMobile: {
                    type: 'string.date',
                    displayName: '<<cyrsjh>>',
                },
                merchantInfos: {
                    type: 'array.popover',
                    displayName: '<<merchantName>>',
                },
            },
        },
    },
    components: {
        NewMallActivityTicketPurchaseRecordsView: {
            component: 'Viewport',
            entity: 'NewMallActivityTicketPurchaseRecordsEntity',
        },
        NewMallActivityTicketPurchaseRecordsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'NewMallActivityTicketPurchaseRecordsEntity',
            items: [
                {
                    component: 'NewMallActivityTicketPurchaseRecordsFilter',
                },
                { component: 'NewMallActivityTicketPurchaseRecordsTables' },
            ],
        },
        NewMallActivityTicketPurchaseRecordsDetailPage: {
            entity: 'NewMallActivityTicketPurchaseRecordsEntity',
            // loaderType: 'get',
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [
                {
                    component: 'NewMallActivityTicketPurchaseRecordDetail',
                },
            ],
        },
        NewMallActivityTicketPurchaseRecordsFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'createOrderMemberMobile',
                    controlConfig: {
                        style: { width: 250 },
                    },
                },
                {
                    property: 'joinMobile',
                    controlConfig: {
                        style: { width: 250 },
                    },
                },
                {
                    property: 'joinName',
                    controlConfig: {
                        style: { width: 250 },
                    },
                },
                {
                    property: 'orderNo',
                    controlConfig: {
                        style: { width: 250 },
                    },
                },
                {
                    property: 'ticketName',
                    controlConfig: {
                        style: { width: 250 },
                    },
                },
                {
                    property: 'dateRange',
                },
                {
                    property: 'joinDate',
                },
            ],
        },
        NewMallActivityTicketPurchaseRecordsTables: {
            component: 'Tabs',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<all>>',
                    content: {
                        component: 'NewMallActivityTicketPurchaseRecordsTable',
                    },
                    params: { orderStatus: 'ALL' },
                },
                {
                    title: '<<dfk>>',
                    content: {
                        component: 'NewMallActivityTicketPurchaseRecordsTable',
                    },
                    params: { orderStatus: 'WAIT_PAY' },
                },
                {
                    title: '<<waiting>>',
                    content: {
                        component: 'NewMallActivityTicketPurchaseRecordsTable',
                    },
                    params: { orderStatus: 'WAIT_AUDIT' },
                },
                {
                    title: '<<rejected>>',
                    content: {
                        component: 'NewMallActivityTicketPurchaseRecordsTable',
                    },
                    params: { orderStatus: 'AUDIT_REJECTED' },
                },
                {
                    title: '<<dcy>>',
                    content: {
                        component: 'NewMallActivityTicketPurchaseRecordsTable',
                    },
                    params: { orderStatus: 'WAIT_CONSUME' },
                },
                {
                    title: '<<ywc>>',
                    content: {
                        component: 'NewMallActivityTicketPurchaseRecordsTable',
                    },
                    params: { orderStatus: 'CONSUMED' },
                },
                {
                    title: '<<common.close>>/<<common.cancel>>',
                    content: {
                        component: 'NewMallActivityTicketPurchaseRecordsTable',
                    },
                    params: { orderStatus: 'CANCELED_OR_CLOSED' },
                },
            ],
        },
        NewMallActivityTicketPurchaseRecordsTable: {
            component: 'FlexLayout',
            direction: 'vertical',

            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    scroll: {
                        x: 1300,
                    },
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'mallActivityName',
                            width: 300,
                        },
                        {
                            property: 'joinTime',
                            width: 220,
                        },
                        {
                            property: 'ticketName',
                            width: 200,
                        },
                        {
                            property: 'ticketsPurchasedQuantity',
                            width: 80,
                        },
                        {
                            property: 'totalSalesPrice',
                            width: 100,
                        },
                        {
                            property: 'totalPointPrice',
                            width: 80,
                        },
                        {
                            property: 'joinName',
                            width: 120,
                        },
                        {
                            property: 'joinMobile',
                            width: 140,
                        },
                        {
                            property: 'createOrderMemberMobile',
                            width: 140,
                        },
                        {
                            property: 'orderCreateTime',
                            width: 200,
                        },
                        {
                            property: 'orderNo',
                            width: 250,
                        },
                        {
                            property: 'subsiteName',
                            width: 160,
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '160px',
                                },
                            },
                        },
                        {
                            property: 'merchantInfos',
                            width: 180,
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '140px',
                                },
                                countSuffix: '<<merchant>>',
                            },
                        },
                        {
                            property: 'orderStatusStr',
                            width: 80,
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        fixed: 'right',
                        width: 180,
                        items: [
                            {
                                disabled: true,
                                type: 'link',
                                text: '<<details>>',
                                path: '/new-mall-activity/record-detail/{{row.id}}',
                            },
                            {
                                type: 'component',
                                component: 'ReviewNewMallActivityButton',
                                config: {
                                    triggerComponent: {
                                        component: 'Button',
                                        type: 'link',
                                        text: '<<audity>>',
                                    },
                                },
                            },
                            {
                                type: 'component',
                                component: 'ConsumeNewMallActivityButton',
                                config: {
                                    triggerComponent: {
                                        component: 'Button',
                                        type: 'link',
                                        text: '核销',
                                    },
                                },
                            },
                            {
                                type: 'component',
                                component: 'MagicActivityAfterSaleButton',
                                config: {
                                    link: `/new-mall-activity/apply`,
                                },
                            },
                            {
                                type: 'component',
                                component: 'SignatureNewMallActivityButton',
                                config: {
                                    triggerComponent: {
                                        component: 'Button',
                                        type: 'link',
                                        text: '<<gq_1>>',
                                    },
                                },
                            },
                        ],
                    },
                },
            ],
        },
        NewMallActivityRegistrationApplyPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'NewMallActivityRecordApplyForm',
                },
            ],
        },
        NewMallActivityAfterSaleDetailPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'NewMallActivityAfterSaleInfo',
                },
            ],
        },
    },
};
