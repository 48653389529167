import React, { Component } from 'react';
import { Table, Button, Tag } from 'antd';
import { CrowdItem } from './giving-coupon-crowds';
import { TextTips } from '../../../../components/text-tips';
import './index.less';
import { services } from '@comall-backend-builder/core';

interface Props {
    data: Array<CrowdItem>;
    disabled?: boolean;
    toggleModal?: () => void;
}

export class CrowdsSelectedDisplay extends Component<Props, any> {
    getTableConfig = () => {
        const { toggleModal, disabled } = this.props;
        let config: any = {
            rowKey: 'id',
            columns: [
                {
                    title: services.language.getText('peopleName'),
                    dataIndex: 'crowdName',
                    render: (name: string, row: any) => {
                        return (
                            <div>
                                <span>{name}</span>
                                {row.status && row.status === 'DELETE' ? (
                                    <Tag color="red">已删除</Tag>
                                ) : null}
                            </div>
                        );
                    },
                },
                {
                    title: services.language.getText('createType'),
                    dataIndex: 'creationMode',
                    render: (value: string) => {
                        return value === 'RULE_MODE' ? '规则创建' : '导入指定用户';
                    },
                },
                {
                    title: '门店',
                    dataIndex: 'subsiteName',
                },
                {
                    title: (
                        <TextTips
                            text={services.language.getText('peopleNum')}
                            tips={services.language.getText('limitPeopleCount')}
                        />
                    ),
                    dataIndex: 'peopleNumber',
                },
            ],
            style: {
                marginTop: 20,
            },
        };
        if (!disabled) {
            config.columns.push({
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                key: 'operation',
                width: 100,
                render: (i: any, row: any) => {
                    return (
                        <Button type={'link'} onClick={toggleModal}>
                            修改
                        </Button>
                    );
                },
            });
        }
        return config;
    };

    render() {
        const { data } = this.props;
        if (!data || data.length === 0) return null;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: data,
        };

        return <Table {...tableProps} pagination={false}></Table>;
    }
}
