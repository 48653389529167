import React, { Component } from 'react';
import { CheckboxGroup } from '@comall-backend-builder/components-basis';
import { isEqual } from 'lodash';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const { api } = services;

export interface CheckboxPlusProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: any, name: string) => void;
    /**
     * 当前值
     */
    value: Array<string>;
    /**
     * 是否禁用
     */
    disabled?: boolean;
    /**
     * add时默认全部选中
     */
    addDefaultSelectAll?: boolean;
    /**
     * 请求参数
     */
    reqParams?: any;
    /**
     * 请求配置
     */
    reqConfig: ApiRequestConfig;
    /**
     * 接口数据转换方法
     */
    convertFunction?: (data: any) => Array<ItemVO>;
}

/**
 * 页面类型
 */
export enum PageType {
    ADD = 'ADD',
    EDIT = 'EDIT',
}

export interface CheckboxPlusState {
    /**
     * checkbox项
     */
    items: Array<ItemVO>;
}

export interface ItemVO {
    id: string;
    name: string;
}

export class CheckboxPlus extends Component<CheckboxPlusProps, CheckboxPlusState> {
    static defaultProps = {
        addDefaultSelectAll: true,
    };
    constructor(props: any) {
        super(props);
        this.state = {
            items: [],
        };
    }
    pageType = PageType.EDIT;

    componentDidMount() {
        setTimeout(this.loadData, 300);
        // 判断页面类型
        const arr = window.location.href.split('/');
        const params = arr[arr.length - 1];
        if (isNaN(Number(params))) {
            this.pageType = PageType.ADD;
        }
    }

    convert = (data: any): Array<ItemVO> =>
        data.map((item: any) => ({ id: item.code, name: item.name }));

    loadData = () => {
        const {
            value,
            addDefaultSelectAll,
            name,
            onChange,
            reqParams,
            reqConfig,
            convertFunction,
        } = this.props;
        api.get(!!reqParams ? reqParams : {}, reqConfig).then((res: any) => {
            if (!!res) {
                this.setState(
                    {
                        items: convertFunction ? convertFunction(res) : this.convert(res),
                    },
                    () => {
                        const { items } = this.state;
                        // add时默认全选;
                        if (addDefaultSelectAll && !value && this.pageType === PageType.ADD) {
                            const newValue = items.map((item) => item.id);
                            if (onChange) {
                                onChange(newValue, name);
                            }
                        }
                        this.forceUpdate();
                    }
                );
            }
        });
    };

    shouldComponentUpdate(nextProps: CheckboxPlusProps) {
        return !isEqual(this.props, nextProps);
    }
    onChange = (value: any) => {
        const { name, onChange } = this.props;
        if (onChange) {
            onChange(value, name);
        }
    };
    render() {
        let { name, value, disabled } = this.props;
        const { items } = this.state;
        return (
            <div>
                <CheckboxGroup
                    disabled={!!disabled}
                    options={items}
                    name={name}
                    value={value}
                    onChange={this.onChange}
                ></CheckboxGroup>
            </div>
        );
    }
}
