import React, { PureComponent } from 'react';
import { forEach, cloneDeep, findIndex } from 'lodash';
import { ProductSelectedDisplay, ProductSelector } from '../../components';

/**
 * 拼团活动选择商品
 */
export class CGBSelectProduct extends PureComponent<{
    value: any[];
    onChange: (v: any) => void;
}> {
    state = {
        value: [],
    };

    processSelectedData = (data: any[]) => {
        let items: any[] = [];
        if (data && data.length) {
            forEach(data, (product) => {
                forEach(product.goodsVos, (goods: any) => {
                    items.push({
                        id: goods.id,
                        name: goods.name,
                        productName: product.name,
                        productCode: product.barcode,
                        goodsCode: goods.csku,
                        subsiteName: goods.subsiteName,
                        merchantName: goods.merchantName,
                        marketPrice: (+goods.marketPrice.value).toFixed(2),
                        groupPrice: null,
                        groupBuyPrice: null,
                        profit: null,
                        groupStock: null,
                        groupLeftStock: goods.stock,
                    });
                });
            });
        }
        return items;
    };

    onChange = (goods: any[]) => {
        const { onChange } = this.props;
        forEach(goods, (i) => {
            i.profit = (i.groupBuyPrice - i.groupPrice).toFixed(2);
        });
        onChange(goods);
    };

    onSelect = (values: any[]) => {
        const { value } = this.props;
        const data = this.processSelectedData(values);
        const newData: any[] = cloneDeep(value || []);
        data.forEach((i: any) => {
            const hasIndex = findIndex(value, (o: any) => o.id === i.id);
            if (hasIndex === -1) {
                newData.unshift(i);
            }
        });
        this.onChange(newData);
    };

    render() {
        const { value } = this.props;
        const hasValue = value && value.length > 0;
        return (
            <div className="cgb-select-product">
                <ProductSelector onChange={this.onSelect} />
                {hasValue && (
                    <ProductSelectedDisplay
                        modifiable={true}
                        data={value}
                        onChange={this.onChange}
                    ></ProductSelectedDisplay>
                )}
            </div>
        );
    }
}
