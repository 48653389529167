import { ObjectFormat } from '@comall-backend-builder/types';
// import { language } from '@comall-backend-builder/core/lib/services';
import { message as AntMessage } from 'antd';
import { SubsiteLinkTypeSelectorValue } from '../../../mode/object/subsite-link-type-selector/subsite-link-type-selector';

/**
 *  链接类型必填校验
 */
export class SubsiteLinkTypeSelectorFormat extends ObjectFormat {
    /**
     * 对数据进行校验
     */
    public validate(rule: any, value: SubsiteLinkTypeSelectorValue, callback: any) {
        callback();
    }
}

interface LinkType {
    linkType: string;
    linkParams?: Record<string, any>;
}
/**
 * 链接类型必填校验
 * @param param0
 * @param param1
 * @returns
 */
export function subsiteLinkTypeSelectorValidate(
    { linkType, linkParams }: LinkType,
    { required, requiredMsg } = { required: false, requiredMsg: '请选择链接类型' }
) {
    const defalutValidMessage = '请选择链接目标';
    if (linkType) {
        if (!checkLinkTypeParamsRequired(linkType, linkParams)) {
            AntMessage.error(defalutValidMessage);
            return false;
        }
    } else {
        if (required) {
            AntMessage.error(requiredMsg);
            return false;
        }
    }
    return true;
}

export const checkLinkTypeParamsRequired = (linkType: string, linkParams?: Record<string, any>) => {
    const needValidLinkTypes = Object.keys(needValidLinkTypeMap);
    if (needValidLinkTypes.includes(linkType)) {
        if (linkType === 'miniprogram' && !linkParams?.appId) {
            return false;
        } else if (linkType === 'urlLink' && !linkParams?.url) {
            return false;
        } else if (linkType === 'dreamService' && !linkParams?.url) {
            return false;
        } else if (linkType === 'popupPicture' && !linkParams?.url) {
            return false;
        } else if (!linkParams) {
            return false;
        }
    }
    return true;
};

const needValidLinkTypeMap = {
    urlLink: {
        name: 'URL地址',
    },
    miniprogram: {
        name: '它方小程序',
    },
    liveInfo: {
        name: '直播间',
    },
    subsite: {
        name: '门店首页',
    },
    newMerchant: {
        name: '专柜首页',
    },
    category: {
        name: '标准/虚拟分类商品列表',
    },
    categoryNav: {
        name: '营销分类模板',
    },
    categoryNavProduct: {
        name: '营销分类商品列表',
    },
    product: {
        name: '商品详情',
    },
    newTopic: {
        name: '专题活动',
    },
    wheelDraw: {
        name: '大转盘抽奖',
    },
    invite: {
        name: '邀请有礼',
    },
    cardCollection: {
        name: '集卡活动',
    },
    watchword: {
        name: '口令领券',
    },
    redPackageRain: {
        name: '红包雨',
    },
    blindBox: {
        name: '盲盒活动',
    },
    'marketing.openBoxDetail': {
        name: '开盒有礼',
    },
    scratchCard: {
        name: '积分刮刮卡',
    },
    lotteryCode: {
        name: '积分抽奖码',
    },
    serviceReserved: {
        name: '服务预约',
    },
    'credit.creditShopDetail': {
        name: '积分商城商品',
    },
    'marketing.groupBuyingDetail': {
        name: '拼团商品',
    },
    'marketing.maotaiPreSaleDetail': {
        name: '茅台预售商品',
    },
    mallActivity: {
        name: '商场活动',
    },
    internalPurchase: {
        name: '企业内购',
    },
    popupPicture: {
        name: '图片弹窗',
    },
    contentNoteDetail: {
        name: '笔记详情',
    },
    topicContent: {
        name: '话题',
    },
    'marketing.fixedPrice': {
        name: '打包一口价',
    },
    dreamService: {
        name: '梦服务',
    },
    // 老SAAS兼容
    topic: {
        name: '专题活动',
    },
};
