import * as React from 'react';
import { modes } from '@comall-backend-builder/types';
import { DateTimeRangePickerPlus } from '../../../components';

export class ObjectDateTimeRangePlusMode extends modes.ObjectDateTimeRangeMode {
    /**
     * 获取输入组件，日期时间的选择区间
     */
    public getControlComponent(controlInfo: any) {
        return <DateTimeRangePickerPlus {...controlInfo} />;
    }
}
