import React, { useCallback, useMemo } from 'react';
import { Table, Button, Popover, InputNumber, message as AntMessage } from 'antd';
import { find, sumBy, get, findIndex } from 'lodash';
import { CouponSelector } from '../coupon-selector';

import { CouponPackageSelector } from '../coupon-package-selector';
import { CouponSelectorWithDisplay } from '../coupon-selector-with-display';
import { CouponPackageSelectorDisplay } from '../coupon-package-selector-display';

import { BirthdayMemberLevel } from './birthday-member-level-types';

import './index.less';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

interface MemberLevelTableProps {
    filterCouponParams?: any;
    data: Array<BirthdayMemberLevel> | undefined;
    onChange: (memberLevels: Array<BirthdayMemberLevel>) => void;
}

/**
 *  * 会员生日营销，会员等级列表
 * @param props
 */
export const MemberLevelTable = (props: MemberLevelTableProps) => {
    const { data } = props;

    const onReset = useCallback(
        (record) => () => {
            const { data, onChange } = props;
            let newData = data ? data.slice(0) : [];
            let row: any = find(newData, { levelId: record.levelId });
            if (row) {
                row.coupons = [];
                row.experience = '';
                onChange(newData);
            }
        },
        [props]
    );

    const modalToMemberLevelCoupon = useCallback((item: any) => {
        return {
            id: item.id,
            type: item.couponType || item.type,
            name: item.name,
            startTime: item.startTime,
            endTime: item.endTime,
            batchNo: item.code || item.batchNo,
            couponTypeDes: item.typeName || item.couponTypeDes,
            sourceDesc: item.sourceDesc,
            consumeChannelDescription: item.consumeChannelDescription,
            quantity: item.quantity || 1,
            stock: item.stock,
        };
    }, []);

    const changeCoupons = (record: any, coupons: Array<any>) => {
        let newData = data ? data.slice(0) : [];
        let row: any = find(newData, { levelId: record.levelId });
        if (row) {
            let newCoupons: any = [];
            coupons.forEach((selectCuopon: any) => {
                const hasIndex = findIndex(
                    newCoupons,
                    (coupon: any) => coupon.id === selectCuopon.id
                );
                if (hasIndex === -1) {
                    newCoupons.push(selectCuopon);
                }
            });
            row.coupons = newCoupons.map(modalToMemberLevelCoupon);
            props.onChange(newData);
        }
    };

    const changeExperience = (record: any, value: any) => {
        let newData = data ? data.slice(0) : [];
        let row: any = find(newData, { levelId: record.levelId });
        if (row) {
            var reg = /^(0|\+?[1-9][0-9]*)$/;
            if (value && reg.test(value)) {
                row.experience = value;
                props.onChange(newData);
            } else {
                AntMessage.warning(services.language.getText('inputNumber'));
                row.experience = '';
                props.onChange(newData);
                return false;
            }
        }
    };

    // const changePointDouble = (record: any, value: any) => {
    //     let newData = data ? data.slice(0) : [];
    //     let row: any = find(newData, { levelId: record.levelId });
    //     if (row) {
    //         const reg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;
    //         if (value <= 1) {
    //             AntMessage.warning('请填写>1的数字');
    //             return false;
    //         }
    //         if (!reg.test(value)) {
    //             AntMessage.warning('积分回馈最多支持两位小数');
    //             return false;
    //         }
    //         row.pointDouble = value;
    //         props.onChange(newData);
    //     }
    // };

    const onAddCoupons = (record: any, coupons: any) => {
        if (!coupons || coupons.length === 0) {
            return;
        }
        let newData = data ? data.slice(0) : [];
        let row: any = find(newData, { levelId: record.levelId });
        if (row) {
            let newCoupons: any = [];
            coupons.forEach((selectCuopon: any) => {
                const hasIndex = findIndex(
                    newCoupons,
                    (coupon: any) => coupon.id === selectCuopon.id
                );
                if (hasIndex === -1) {
                    newCoupons.push(selectCuopon);
                }
            });
            row.coupons = newCoupons.map(modalToMemberLevelCoupon);
            props.onChange(newData);
        }
    };

    const tableProps = useMemo(() => {
        return {
            dataSource: data,
            pagination: false as const,
            columns: [
                {
                    title: language.getText('hydj'),
                    dataIndex: 'levelName',
                    key: 'levelName',
                    align: 'center' as const,
                },
                // {
                //     title: '下单返积分',
                //     dataIndex: 'pointDouble',
                //     key: 'pointDouble',
                //     align: 'center' as const,
                //     render(pointDouble: any, record: any) {
                //         return (
                //             <div style={{ display: 'flex', alignItems: 'center' }}>
                //                 <InputNumber
                //                     placeholder={services.language.getText('inputPlease')}
                //                     value={pointDouble}
                //                     min={1}
                //                     max={999999999}
                //                     onChange={(value: any) => changePointDouble(record, value)}
                //                 ></InputNumber>
                //                 &nbsp;&nbsp;倍
                //             </div>
                //         );
                //     },
                // },
                {
                    title: language.getText('syhq'),
                    dataIndex: 'coupons',
                    key: 'coupons',
                    align: 'center' as const,
                    render: (coupons: Array<any>, record: any) => {
                        const hasCoupons = coupons && coupons.length > 0;
                        const isPackage = get(record, 'coupons[0].type') === 'PACKAGE';
                        return (
                            <div>
                                {hasCoupons ? (
                                    <label>
                                        {sumBy(coupons, (c) => c.quantity)}
                                        {isPackage
                                            ? services.language.getText('gifts')
                                            : services.language.getText('zhang')}
                                        {isPackage ? (
                                            <Popover
                                                title={`【${record.levelName}】 ${language.getText(
                                                    'syhqgzsz'
                                                )}`}
                                                content={
                                                    <CouponPackageSelectorDisplay
                                                        onChange={(coupons) =>
                                                            changeCoupons(record, coupons)
                                                        }
                                                        disablePrice={true}
                                                        data={coupons}
                                                    />
                                                }
                                            >
                                                <Button type="link">
                                                    {language.getText('settings')}
                                                </Button>
                                            </Popover>
                                        ) : (
                                            <Popover
                                                title={`【${record.levelName}】 ${language.getText(
                                                    'syhqgzsz'
                                                )}`}
                                                content={
                                                    <CouponSelectorWithDisplay
                                                        onChange={(coupons) =>
                                                            changeCoupons(record, coupons)
                                                        }
                                                        data={coupons}
                                                        filterParams={props.filterCouponParams}
                                                        canShowApportionPrice={false}
                                                    />
                                                }
                                            >
                                                <Button type="link">
                                                    {language.getText('settings')}
                                                </Button>
                                            </Popover>
                                        )}
                                    </label>
                                ) : (
                                    <>
                                        <CouponSelector
                                            type={'checkbox'}
                                            selectValues={[]}
                                            onChange={(coupons) => onAddCoupons(record, coupons)}
                                            buttonProps={{
                                                text: language.getText('szyhq'),
                                                type: 'link',
                                            }}
                                            params={props.filterCouponParams}
                                        ></CouponSelector>
                                        <CouponPackageSelector
                                            type="radio"
                                            selectValues={[]}
                                            onChange={(coupons) => onAddCoupons(record, coupons)}
                                            buttonProps={{
                                                text: language.getText('szyhqlb'),
                                                type: 'link',
                                            }}
                                            params={props.filterCouponParams}
                                        ></CouponPackageSelector>
                                    </>
                                )}
                            </div>
                        );
                    },
                },
                {
                    title: language.getText('sjyz_1'),
                    dataIndex: 'experience',
                    key: 'experience',
                    align: 'center' as const,
                    render(experience: any, record: any) {
                        return (
                            <InputNumber
                                placeholder={services.language.getText('inputPlease')}
                                value={experience}
                                min={0}
                                max={999999999}
                                onChange={(value: any) => changeExperience(record, value)}
                            ></InputNumber>
                        );
                    },
                },
                {
                    title: services.language.getText('common.tableAction'),
                    className: 'action-column',
                    dataIndex: 'id',
                    key: 'id',
                    align: 'center' as const,
                    render: (id: string, record: any) => {
                        return (
                            <Button type="link" onClick={onReset(record)}>
                                {language.getText('common.reset')}
                            </Button>
                        );
                    },
                },
            ],
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, props.filterCouponParams]);

    return (
        <div className="member-level-table">
            <Table {...tableProps}></Table>
        </div>
    );
};
