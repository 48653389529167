import React, { PureComponent, createElement } from 'react';
import { forEach, map, remove } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Button, Modal } from 'antd';

const TableComponentId = 'SendOutChannelSelectorTable';
let Entity: any = {};
let entity: any = {};

export interface SendOutChennel {
    channelId: number;
    channelName: string;
    description: string;
    stockModel: string;
    status: string;
    maxCouponQuantity: string;
    /**
     * 被选中的渠道
     */
    select?: boolean;
    oldMaxCouponQuantity?: string;
}

export interface SendOutChannelSelectProps {
    selectValues: any[];
    onChange: (products: any[]) => void;
    params?: any;
    type?: string;
    buttonProps?: any;
}

/**
 * 选择渠道
 */
export class SendOutChannelSelect extends PureComponent<SendOutChannelSelectProps, {}> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('SendOutChannelSelector');
        entity = new Entity({});
    }
    dispatch: any = {};
    state = { visible: false };

    selectedRows = new Array<any>();

    componentWillUnmount() {
        //this.dispatch(actions.unmountEntityAction(entity));
    }

    onTableRowSelect = (record: SendOutChennel, selected: boolean) => {
        const { type } = this.props;
        if (selected) {
            if ('radio' === type) {
                this.selectedRows = [record];
            } else {
                this.selectedRows.push(record);
            }
        } else {
            remove(this.selectedRows, (i) => {
                return i.channelId === record.channelId;
            });
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: SendOutChennel[]) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            this.selectedRows = [];
        }
    };

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
    };

    onOk = () => {
        const { onChange } = this.props;
        if (onChange) {
            const newSelectedRows: any = [];
            forEach(this.selectedRows, (channel) => {
                newSelectedRows.push({
                    channelId: channel.id,
                    channelName: channel.name,
                    code: channel.code,
                    description: channel.description,
                    stockModel: '',
                    maxCouponQuantity: '',
                    status: 'true',
                });
            });
            onChange(newSelectedRows);
            this.toggleModal();
        }
    };

    getConfig = () => {
        const { params, selectValues, type } = this.props;
        let columns: any[] = [];
        columns = [
            {
                property: 'name',
            },
            {
                property: 'description',
                displayConfig: {
                    className: 'ant-table-cell-ellipsis',
                    style: {
                        maxWidth: '400px',
                    },
                },
            },
        ];
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: params || {},
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'right', marginTop: '10px' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        entity.pageChange(Object.assign({}, entity.paging, { page: 1 }));
                        entity.search(params);
                    },
                    fields: [
                        {
                            property: 'name',
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '20', '30'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    rowSelection: {
                        type: type ? type : 'radio',
                        selectedRowKeys: map(selectValues, 'channelId'),
                        onSelect: (record: SendOutChennel, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: SendOutChennel[]) => {
                            this.onTableRowSelectAll(selected, rows);
                        },
                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    scroll: { x: true },
                    columns: columns,
                },
            ],
        };
    };

    toggleModal = () => {
        const { visible } = this.state;
        const { params } = this.props;

        this.setState({ visible: !visible }, () => {
            if (!visible && entity.paging) {
                entity.search({
                    ...entity.paging,
                    ...params,
                });
                this.onClearAll();
            }
        });
    };

    render() {
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 900,
            title: services.language.getText('fsqd'),
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.reset'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };
        const btnProps = Object.assign(
            {},
            { text: services.language.getText('xzsffsqd'), type: 'primary' },
            this.props.buttonProps
        );
        return (
            <div style={{ display: 'inline-block' }}>
                <Button {...btnProps} onClick={this.toggleModal}>
                    {btnProps.text}
                </Button>
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}
