import { forEach, isEmpty } from 'lodash';
import { services } from '@comall-backend-builder/core/';

export const MerchantBigCodesLoader = {
    get: async function(data: any, config: any) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = `/admin/merchant_big_codes`;
        return services.api.get(data, config).then((res: any) => {
            if (res && !isEmpty(res.result)) {
                forEach(res.result, (item) => {
                    item.name = item.name || '';
                    item.code = item.bigCode;
                    item.subsiteName = item.subsiteInfo.name;
                    item.merchantName = item.merchantInfo.name;
                });
            }
            return res;
        });
    },
};
