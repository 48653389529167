/**
 * 优惠券退款单详情
 */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Row as AntRow, Col as AntCol, Card as AntCard } from 'antd';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';

enum CouponRefundOrderPaymentType {
    COD = 'COD',
    THIRDPARTY = 'THIRDPARTY',
    INTERNALPAY = 'INTERNALPAY',
}

enum CouponRefundOrderPaymentTypeStr {
    COD = 'hdfk',
    THIRDPARTY = 'zxzfjfkq',
    INTERNALPAY = 'dsfzf',
}

interface CouponRefundOrder {
    auditTime: string;
    auditUser: string;
    createUser: string;
    lastModifiedTime: string;
    lastModifiedUser: string;
    mergePaymentNo: string;
    orderCreateTime: string;
    orderId: number;
    orderNo: string;
    paymentType: CouponRefundOrderPaymentType;
}

class couponRefundOrderInfo extends PureComponent<{
    fields: CouponRefundOrder | undefined;
    entity: Entity;
}> {
    render() {
        const { fields } = this.props;

        return fields ? (
            <div className="coupon-order-info">
                <AntCard>
                    <AntRow style={{ marginBottom: 20 }}>
                        <AntCard type="inner" title={services.language.getText('otherBaseInfo')}>
                            <AntRow style={{ marginBottom: 10 }}>
                                <AntCol span={3}>{services.language.getText('ydjbs')}:</AntCol>
                                <AntCol span={5}>{fields.orderId}</AntCol>
                                <AntCol span={3}>{services.language.getText('ydjbh')}:</AntCol>
                                <AntCol span={5}>{fields.orderNo}</AntCol>
                                <AntCol span={3}>{services.language.getText('yzffs')}:</AntCol>
                                <AntCol span={5}>
                                    {services.language.getText(
                                        CouponRefundOrderPaymentTypeStr[fields.paymentType]
                                    )}
                                </AntCol>
                            </AntRow>
                            <AntRow style={{ marginBottom: 10 }}>
                                <AntCol span={3}>{services.language.getText('yzflsh')}:</AntCol>
                                <AntCol span={5}>{fields.mergePaymentNo}</AntCol>
                                <AntCol span={3}>
                                    {services.language.getText('createTimeStr')}:
                                </AntCol>
                                <AntCol span={5}>{fields.orderCreateTime}</AntCol>
                                <AntCol span={3}>{services.language.getText('cjyh')}:</AntCol>
                                <AntCol span={5}>{fields.createUser}</AntCol>
                            </AntRow>
                            <AntRow style={{ marginBottom: 10 }}>
                                <AntCol span={3}>{services.language.getText('shyh')}:</AntCol>
                                <AntCol span={5}>{fields.auditUser}</AntCol>
                                <AntCol span={3}>{services.language.getText('shsj')}:</AntCol>
                                <AntCol span={5}>{fields.auditTime}</AntCol>
                            </AntRow>
                            <AntRow style={{ marginBottom: 10 }}>
                                <AntCol span={3}>{services.language.getText('zhczyh')}:</AntCol>
                                <AntCol span={5}>{fields.lastModifiedUser}</AntCol>
                                <AntCol span={3}>{services.language.getText('zhczsj')}:</AntCol>
                                <AntCol span={5}>{fields.lastModifiedTime}</AntCol>
                            </AntRow>
                        </AntCard>
                    </AntRow>
                </AntCard>
            </div>
        ) : null;
    }
}

function mapStateToProps(state: any, props: any) {
    const {
        entity: { fields },
        componentId,
    } = props;

    return {
        fields,
        componentId,
    };
}

export const CouponRefundOrderInfo = connect(mapStateToProps)(couponRefundOrderInfo);
