import React from 'react';
import { Switch } from 'antd';
import { language } from '@comall-backend-builder/core/lib/services';

interface CreateMerchantSelectorProps {
    onChange: (val: boolean) => void;
    value: boolean;
}

export default class CreateMerchantSelector extends React.Component<CreateMerchantSelectorProps> {
    onChange = (e: any) => {
        this.props.onChange(e);
    };
    render() {
        const { value } = this.props;
        return (
            <div>
                <Switch checked={!!value} onChange={this.onChange} />
                <div style={{ lineHeight: '20px', color: '#BFBFBF' }}>
                    {language.getText('zjsyzkzsdyxx')}
                    <a href="/#merchant-user" target="_blank">
                        {language.getText('merchantManageUser')}
                    </a>
                    {language.getText('mkzxgygzszj')}
                </div>
            </div>
        );
    }
}
