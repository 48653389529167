import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';

import { services } from '@comall-backend-builder/core';
import { get } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

export enum activityStatus {
    NOTSTARTED = 'NOTSTARTED',
    STARTED = 'STARTED',
    COMPLETE = 'COMPLETE',
}
const activityStatusOptions = [
    {
        id: activityStatus.NOTSTARTED,
        name: '<<notStarted>>',
    },
    {
        id: activityStatus.STARTED,
        name: '<<inprogress>>',
    },
    {
        id: activityStatus.COMPLETE,
        name: '<<end>>',
    },
];

const validator = (entity: any) => {
    const rewardInfos: any = get(entity, 'ruleConfig.rewardInfos', null);
    let hasValidate = true;
    if (!rewardInfos?.length) {
        AntMessage.warning(language.getText('saveFail'));
        return false;
    }
    let maxRewardConditionValue = 0,
        maxRewardValue = 0;
    const warning = (content: string) => {
        AntMessage.warning(content);
        hasValidate = false;
    };
    for (const item of rewardInfos) {
        const { rewardConditionValue, rewardValue } = item;
        if (!rewardConditionValue || !rewardValue) {
            warning(language.getText('saveFail'));
            break;
        }
        if (rewardConditionValue > maxRewardConditionValue) {
            maxRewardConditionValue = rewardConditionValue;
        } else {
            warning(language.getText('failResult'));
            break;
        }
        if (rewardValue > maxRewardValue) {
            maxRewardValue = rewardValue;
        } else {
            warning(language.getText('failResult2'));
            break;
        }
    }
    if (!hasValidate) {
        return false;
    }
    return true;
};

const formConfig = {
    labelCol: 4,
    controlCol: 20,
    style: {
        width: 700,
    },
    fields: [
        {
            property: 'baseInfo.name',
        },
        {
            property: 'baseInfo.tagName',
        },
        {
            property: 'baseInfo.dateRange',
        },
        {
            property: 'baseInfo.subsiteId',
        },
        {
            property: 'baseInfo.marketingSchedule',
        },
        {
            property: 'ruleConfig.rewardInfos',
        },
        {
            property: 'productConfig.goods',
        },
        {
            property: 'pageConfigInfo.bannerPicture',
        },
        {
            property: 'pageConfigInfo.enableCountdown',
        },
    ],
    onValidate: validator,
    submit: true,
    footer: {
        items: [
            {
                htmlType: 'button',
                route: 'goBack',
                text: '<<common.cancel>>',
                type: 'default',
                style: { marginLeft: 10 },
            },
        ],
    },
    onSubmitSuccess: () => {
        AntMessage.success(services.language.getText('common.saveSuccess'), () =>
            services.behaviorHandle({ route: 'goBack' })
        );
    },
};

export const config: Config = {
    entities: {
        FixedPriceEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
            apiPath: '/loader/admin/fixed_price_promotions',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                activityStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: activityStatusOptions,
                },
                subsiteIds: {
                    type: 'string.options.autoComplete.plus',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 240 },
                        allowClear: true,
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<acitivityTime>>',
                },
            },
            properties: {
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string',
                    displayName: '<<endTime>>',
                },
                subsite: {
                    type: 'object',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<subsiteName>>',
                        },
                    },
                },
                activityStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    options: activityStatusOptions,
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [
                                { required: true },
                                {
                                    validator: (_rule: any, value: any, callback: any) => {
                                        if (value && !value.trim()) {
                                            return callback(
                                                language.getText('activityNameNotEmpty')
                                            );
                                        }
                                        callback();
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                                style: {
                                    width: 386,
                                },
                            },
                        },
                        tagName: {
                            type: 'string',
                            displayName: '<<tagName>>',
                            controlConfig: {
                                placeholder: '<<tagNameRule>>',
                                maxLength: 10,
                                style: {
                                    width: 386,
                                },
                            },
                            extra: '<<tagNageExtra>>',
                        },
                        dateRange: {
                            type: 'object.dateTimeRangePlus',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true }],
                            controlConfig: {
                                style: {
                                    width: 386,
                                },
                            },
                        },
                        subsiteId: {
                            type: 'string.options.select',
                            displayName: '<<activityMerchant>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [
                                {
                                    required: true,
                                    message: '<<selectSubsite>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 386,
                                },
                                placeholder: '<<selectPlease>>',
                                allowClear: true,
                                showSearch: true,
                                optionFilterProp: 'children',
                                filterOption: (input: any, option: any) =>
                                    (option?.name ?? '')
                                        .toLowerCase()
                                        .includes(input.toLowerCase()),
                            },
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],

                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    return { subsiteIds: row.baseInfo?.subsiteId };
                                },
                            },
                        },
                    },
                },
                ruleConfig: {
                    type: 'object.subForm',
                    displayName: '<<ruleSetting>>',
                    properties: {
                        rewardInfos: {
                            type: 'array.FixedPriceRules',
                            displayName: '<<rewardInfos>>',
                            rules: [{ required: true }],
                        },
                    },
                },
                productConfig: {
                    type: 'object.subForm',
                    displayName: '<<productPoolSetting>>',
                    properties: {
                        goods: {
                            type: 'array.PresaleProducts',
                            displayName: '<<components.Tools.selectProduct>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<components.Tools.productSelectRequired>>',
                                },
                            ],
                            controlConfig: {
                                selectProductTypes: ['NORMAL'],
                                selectShelfStatus: 'ALL',
                                getSubsiteIds: (row: any) => {
                                    const subsiteId = get(row, 'baseInfo.subsiteId', undefined);
                                    return subsiteId ? [subsiteId] : [];
                                },
                            },
                            extra: '<<productPoolSettingExtra>>',
                        },
                    },
                },
                pageConfigInfo: {
                    type: 'object.subForm',
                    displayName: '<<components.PageDesign.pageSetting>>',
                    properties: {
                        bannerPicture: {
                            displayName: '<<topPic>>',
                            type: 'imageObjectArray',
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'single',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<topPicExtra>>',
                        },
                        enableCountdown: {
                            displayName: '<<countDown2>>',
                            type: 'string.options.radio',
                            defaultValue: 'false',
                            options: [
                                {
                                    id: 'true',
                                    name: '<<display>>',
                                },
                                {
                                    id: 'false',
                                    name: '<<noDisplay>>',
                                },
                            ],
                            extra: '<<selectDisplayTip>>',
                            rules: [{ required: true }],
                        },
                    },
                },
            },
        },
    },
    components: {
        FixedPriceView: {
            component: 'Viewport',
            entity: 'FixedPriceEntity',
        },
        FixedPricePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'FixedPriceFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'horizontal',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'Button',
                            text: '<<addActivity>>',
                            type: 'primary',
                            icon: 'plus',
                            route: '/fixed-price/add',
                            style: { flex: 0 },
                        },
                    ],
                },
                { component: 'FixedPriceTable' },
            ],
        },
        FixedPriceTable: {
            component: 'DataTable',
            loadFirstPage: true,
            scroll: { x: true },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'startTime',
                    width: '200px',
                },
                {
                    property: 'endTime',
                    width: '200px',
                },
                {
                    property: 'subsite.name',
                    width: '200px',
                },

                {
                    property: 'activityStatus',
                    width: '200px',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 378,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            statusKey: 'enable',
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
                                    apiPath: '/admin/fixed_price_promotions/:id/disable',
                                    config: {
                                        content: '{{row.name}}<<beforeClose>>',
                                        text: '<<common.close>>',
                                        style: { color: 'red' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                        className: 'confirm-content',
                                    },
                                },
                                {
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
                                    apiPath: '/admin/fixed_price_promotions/:id/enable',
                                    config: {
                                        content: '{{row.name}}<<beforeOpen>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/fixed-price/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/fixed-price/copy/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'PageNewPreviewCodeButton',
                        config: {
                            codeTypes: 'all',
                            codeModalType: 'item',
                            codePageType: 'fixedPricePage',
                        },
                    },
                    {
                        type: 'link',
                        text: '<<viewData>>',
                        path: '/fixed-price/report/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'ExportHandlerButton',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
                            apiPath: '/admin/fixed_price_promotions/{{row.id}}/export',
                        },
                    },
                ],
            },
        },
        FixedPriceFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<selectActivityName>>',
                    },
                },
                {
                    property: 'activityStatus',
                    controlConfig: {
                        style: { width: 100 },
                    },
                },
                {
                    property: 'subsiteIds',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: {
                            width: 300,
                        },
                        placeholder: ['<<selectStartTime>>', '<<selectEndTime>>'],
                    },
                },
            ],
        },
        AddFixedPricePage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'FixedPriceEntity',
            items: [{ component: 'FixedPricePreview' }, { component: 'AddFixedPriceForm' }],
        },
        AddFixedPriceForm: {
            component: 'CreateFormPlus',
            ...formConfig,
        },
        EditFixedPricePage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'FixedPriceEntity',
            loaderType: 'get',
            items: [{ component: 'FixedPricePreview' }, { component: 'EditFixedPriceForm' }],
        },
        EditFixedPriceForm: {
            component: 'ModifyFormPlus',
            ...formConfig,
        },
        CopyFixedPricePage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'FixedPriceEntity',
            loaderType: 'get',
            items: [{ component: 'FixedPricePreview' }, { component: 'CopyFixedPriceForm' }],
        },
        CopyFixedPriceForm: {
            component: 'CopyFormPlus',
            direction: 'horizontal',
            ...formConfig,
        },
        FixedPriceReportPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'FixedPriceDataReportView',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/fixed-price',
                    component: 'FixedPriceView',
                    breadcrumbName: '<<fixedPrice>>',
                    // privilege: {
                    //     path: 'timelimitActivity',
                    //     level: 'full',
                    // },
                    privilege: {
                        path: 'fixedPrice',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'AddFixedPricePage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'EditFixedPricePage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/copy/:id',
                            component: 'CopyFixedPricePage',
                            breadcrumbName: '<<copyActivity>>',
                        },
                        {
                            path: '/report/:id',
                            component: 'FixedPriceReportPage',
                            breadcrumbName: '<<viewData>>',
                        },
                        { path: '/', component: 'FixedPricePage' },
                    ],
                },
            ],
        },
    ],
};
