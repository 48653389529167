import React, { PureComponent } from 'react';
import { Checkbox } from 'antd';
import ConditionItem, { ConditionType } from './conditonItem';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

interface RegistrationTime {
    checked?: boolean;
    conditionType?: ConditionType;
    conditionValue?: any;
}

export const MaotaiPresaleConditionsDefaultValue = [
    {
        conditionType: ConditionType.REALNAME,
        checked: true,
    },
    {
        conditionType: ConditionType.AGE,
        conditionValue: { value: 18 },
        checked: true,
    },
    {
        conditionType: ConditionType.LEVEL,
        conditionValue: { value: [] },
    },
    {
        conditionType: ConditionType.REGISTRATION_TIME,
        conditionValue: { timeType: 'STATIC' },
    },
    {
        conditionType: ConditionType.TOTAL_POINT,
        conditionValue: { timeType: 'CURRENT_MOUTH' },
    },
    {
        conditionType: ConditionType.SUPERMARKET_POINT,
        conditionValue: { timeType: 'CURRENT_MOUTH' },
    },

    {
        conditionType: ConditionType.STORE_POINT,
        conditionValue: { timeType: 'CURRENT_MOUTH' },
    },

    {
        conditionType: ConditionType.CONSUME_AMOUNT,
        conditionValue: { timeType: 'CURRENT_MOUTH' },
    },
    {
        conditionType: ConditionType.TRANSACTION_QUANTITY,
        conditionValue: { timeType: 'CURRENT_MOUTH' },
    },
    {
        conditionType: ConditionType.UNIT_PRICE,
        conditionValue: { timeType: 'CURRENT_MOUTH' },
    },
    {
        conditionType: ConditionType.REGION,
        conditionValue: {
            value: {
                regionIds: [],
                regionNames: [],
            },
        },
    },
];

export const MaotaiPresaleConditionsTypeList = [
    { conditionType: ConditionType.REALNAME, endText: '' },
    { conditionType: ConditionType.AGE, endText: 'canSignUp' },
    { conditionType: ConditionType.LEVEL, endText: 'canSignUp' },
    { conditionType: ConditionType.REGISTRATION_TIME, endText: 'canActivity' },
    { conditionType: ConditionType.TOTAL_POINT, endText: 'canSignUp' },
    { conditionType: ConditionType.SUPERMARKET_POINT, endText: 'canSignUp' },
    { conditionType: ConditionType.STORE_POINT, endText: 'canSignUp' },
    { conditionType: ConditionType.CONSUME_AMOUNT, endText: 'canSignUp' },
    { conditionType: ConditionType.TRANSACTION_QUANTITY, endText: 'canSignUp' },
    { conditionType: ConditionType.UNIT_PRICE, endText: 'canSignUp' },
    { conditionType: ConditionType.REGION, endText: '' },
];

export interface MaotaiPresaleConditionsProps {
    value: RegistrationTime[];
    onChange: (val: RegistrationTime[]) => void;
}
export class MaotaiPresaleConditions extends PureComponent<MaotaiPresaleConditionsProps> {
    static defaultProps = {
        value: [],
    };

    onCheckChange = (e: any) => {
        const { checked, value: conditionType } = e.target;
        const { onChange, value } = this.props;
        const item = value.find((i) => i.conditionType === conditionType);
        if (!item) {
            onChange([...value, { conditionType, checked }]);
        } else {
            const list = value.map((item) => {
                if (item.conditionType === conditionType) {
                    return { ...item, checked };
                }
                return item;
            });
            onChange(list);
        }
    };

    renderConditions = () => {
        const { value } = this.props;
        return MaotaiPresaleConditionsTypeList.map((i, index) => {
            const checked = !!value.find((item) => item.conditionType === i.conditionType)?.checked;
            return (
                <div className="maotai-presale-conditions-row" key={i.conditionType}>
                    <Checkbox
                        checked={checked}
                        disabled={
                            i.conditionType === ConditionType.REALNAME ||
                            i.conditionType === ConditionType.AGE
                        }
                        onChange={this.onCheckChange}
                        value={i.conditionType}
                    ></Checkbox>
                    &nbsp; &nbsp; &nbsp; &nbsp;
                    <ConditionItem
                        index={index}
                        endText={language.getText(i.endText)}
                        conditionType={i.conditionType}
                        {...this.props}
                    ></ConditionItem>
                </div>
            );
        });
    };

    render() {
        return (
            <div className="maotai-presale-conditions">
                <div className="maotai-presale-conditions-tips">
                    {language.getText('bgxbxybmtj')}
                </div>
                <div>{this.renderConditions()}</div>
            </div>
        );
    }
}
