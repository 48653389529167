import React, { PureComponent } from 'react';
import { Table, Button } from 'antd';
import { cloneDeep, remove } from 'lodash';
import { CouponCategory } from '../../components/coupon-sale-category-selector';
import { PaginationConfig } from 'antd/lib/table/interface';
import { services } from '@comall-backend-builder/core';

interface CouponSelectedDisplayProps {
    data: Array<CouponCategory>;
    onRemove: (data: Array<CouponCategory>) => void;
    disabled?: boolean;
}
interface CouponSelectedDisplayState {
    selectedRows: Array<CouponCategory>;
}

class CouponSaleCategorySelectorDisplay extends PureComponent<
    CouponSelectedDisplayProps,
    CouponSelectedDisplayState
> {
    getTableConfig = () => {
        const { disabled } = this.props;
        let tableConfig: any = {
            rowKey: 'id',
            columns: [
                {
                    title: '分类名称',
                    dataIndex: 'name',
                    key: 'name',
                },

                {
                    title: services.language.getText('common.tableAction'),
                    className: 'action-column',
                    key: 'operation',
                    width: 100,
                    render: (i: any, row: any) => {
                        return !disabled ? (
                            <Button
                                type={'link'}
                                onClick={this.onRemove(row)}
                                style={{ padding: '0' }}
                            >
                                {services.language.getText('common.delete')}
                            </Button>
                        ) : null;
                    },
                },
            ],
            style: {
                marginTop: 20,
            },
        };
        return tableConfig;
    };

    onRemove = (row: CouponCategory) => {
        const { data, onRemove } = this.props;

        return () => {
            const result = cloneDeep(data);
            remove(result, (coupon) => row.id === coupon.id);
            onRemove(result);
        };
    };

    batchChangeValue = (field: string, value: any) => {};

    changeBatchInputValue = (field: string, value: any) => {};

    render() {
        const { data } = this.props;
        const pagination = false as PaginationConfig | false;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: data,
            pagination,
        };

        return <Table {...tableProps}></Table>;
    }
}

export { CouponSaleCategorySelectorDisplay };
