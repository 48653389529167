import React, { PureComponent } from 'react';
import { ArticleSelect } from './article-select';
import { ArticleSelected } from './article-selected';

export interface Article {
    id: string;
    name: string;
    status: 'DRAFT' | 'PUBLISHED';
    categoryName: string;
    createTime: string;
}

class ArticleSeletor extends PureComponent<
    any,
    {
        value: Array<Article>;
    }
> {
    state = { value: new Array<Article>() };

    onChange = (selectValues: Article[]) => {
        const { onChange } = this.props;
        if (onChange) {
            if (selectValues && selectValues.length === 0) {
                onChange([]);
            } else {
                onChange(selectValues);
            }
        }
    };

    onSelect = (selectValues: Article[]) => {
        const { name, onChange } = this.props;
        if (onChange) {
            onChange(selectValues, name);
        }
    };

    render() {
        const { value, disabled, type } = this.props;
        return (
            <div>
                {!disabled && (
                    <ArticleSelect type={type} onChange={this.onSelect} selectValues={value} />
                )}
                <ArticleSelected
                    data={value}
                    onRemove={this.onChange}
                    disabled={disabled}
                ></ArticleSelected>
            </div>
        );
    }
}

export { ArticleSelect, ArticleSelected, ArticleSeletor };
