import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';

export const config: Config = {
    entities: {
        annualWelfare: {
            apiPath: '/loader/admin/annual-welfare',
            properties: {
                subsites: {
                    type: 'array.options.autoComplete',
                    displayName: '<<otherSelectSubsite>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    rules: [{ required: true }],
                },
                subsiteName: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                img: {
                    displayName: '<<pictures>>',
                    type: 'imageObjectArray',
                    className: 'sigle-image-field',
                    rules: [{ required: true }],
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        fileName: 'fileName',
                        uploadType: 'single',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                        limitTips: {
                            limitText: '<<imgLimitTips>>',
                            limitUnit: ' ',
                            className: 'image-picker-tips-ext',
                        },
                    },
                },
                imgUrl: {
                    type: 'string.image.picker',
                    displayName: '<<pictures>>',
                },
                desc: {
                    type: 'string.richTextPlus',
                    displayName: '<<hksm>>',
                    controlConfig: {
                        toolbarId: 'descriptionRichText',
                        style: {
                            height: 300,
                        },
                        uploadConfig: {
                            apiPath: `/admin/images/upload`,
                            fileName: 'fileName',
                        },
                    },
                    rules: [
                        {
                            required: true,
                            max: 20000,
                            message: '<<qsrhksm>>',
                        },
                    ],
                },
            },
        },
    },
    components: {
        AnnualWelfareView: {
            component: 'Viewport',
            entity: 'annualWelfare',
        },
        AnnualWelfarePage: {
            component: 'Card',
            entity: 'annualWelfare',
            className: 'card-table-content',
            content: {
                component: 'AnnualWelfareLayout',
            },
        },
        AnnualWelfareLayout: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'GridLayout',
                    className: 'mb24',
                    items: [
                        {
                            component: 'Button',
                            text: '<<xznzhk>>',
                            type: 'primary',
                            icon: 'plus',
                            span: 4,
                            route: '/annual-welfare/add',
                        },
                    ],
                },
                {
                    component: 'AnnualWelfareTable',
                },
            ],
        },
        AnnualWelfareTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                { property: 'subsiteName', displayConfig: { countSuffix: '<<subsites>>' } },
                { property: 'imgUrl', displayConfig: { style: { width: 180 } } },
                { property: 'desc', displayConfig: { style: { width: 240 } } },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                items: [
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/annual-welfare/edit/{{row.id}}',
                    },
                    {
                        type: 'delete',
                        text: '<<common.delete>>',
                        config: {
                            type: 'link',
                            tooltip: null,
                            title: '<<confirmDelete>>',
                            okText: '<<common.ok>>',
                            cancelText: '<<common.cancel>>',
                        },
                    },
                ],
            },
        },
        AnnualWelfareAddPage: {
            component: 'Card',
            entity: 'annualWelfare',
            content: {
                component: 'AnnualWelfareAddForm',
            },
        },
        AnnualWelfareAddForm: {
            component: 'CreateForm',
            labelCol: 4,
            controlCol: 16,
            style: { width: 900 },
            fields: [
                {
                    property: 'subsites',
                    extra: '<<tymdxjzcczytnzhksj>>',
                },
                {
                    property: 'img',
                    extra: '<<annualWelfareAddFormExtra>>',
                },
                {
                    property: 'desc',
                    extra: '<<txhznzhkcxyzs>>',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.addSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        AnnualWelfareEditPage: {
            component: 'Card',
            content: {
                component: 'AnnualWelfareEditForm',
            },
        },
        AnnualWelfareEditForm: {
            component: 'ModifyForm',
            entity: 'annualWelfare',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 16,
            style: { width: 900 },
            fields: [
                {
                    property: 'subsites',
                    extra: '<<tymdxjzcczytnzhksj>>',
                },
                {
                    property: 'img',
                    extra: '<<annualWelfareAddFormExtra>>',
                },
                {
                    property: 'desc',
                    extra: '<<txhznzhkcxyzs>>',
                },
            ],
            submit: { text: '<<common.save>>' },
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/annual-welfare',
                    breadcrumbName: '<<annualWelfareDesc>>',
                    component: 'AnnualWelfareView',
                    childRoutes: [
                        {
                            path: '/add',
                            breadcrumbName: '<<xznzhk>>',
                            component: 'AnnualWelfareAddPage',
                        },
                        {
                            path: '/edit/:id',
                            breadcrumbName: '<<bjnzhk>>',
                            component: 'AnnualWelfareEditPage',
                        },
                        {
                            path: '/',
                            component: 'AnnualWelfarePage',
                        },
                    ],
                },
            ],
        },
    ],
};
