import { Config } from '@comall-backend-builder/core/lib/parser';

export let config: Config = {
    entities: {
        privileges: {
            apiPath: '/loader/privileges',
        },
        sessions: {
            apiPath: '/loader/admin/user',
            apiRoot: `${ENV.AUTH_API_ROOT}/CAE-AUTHORIZATION`,
        },
    },

    components: {
        SideNavFrameWrapper: {
            component: 'CustomAuthentication',
            entities: [
                {
                    name: 'privileges',
                    entityName: 'privileges',
                },
            ],
            content: { component: 'SideNavFrame' },
        },

        UserMenu: {
            component: 'Nav',
            className: 'nav-header',
            mode: 'horizontal',
            theme: 'light',
            items: [
                {
                    type: 'menu',
                    key: 'user',
                    component: {
                        entities: {
                            name: 'sessions',
                            entityName: 'sessions',
                        },
                        name: 'UserProfileMini',
                    },
                    items: [
                        {
                            type: 'item',
                            key: 'setting',
                            title: '用户信息',
                            icon: 'setting',
                            route: '/account/setting',
                        },
                        {
                            type: 'item',
                            key: 'logout',
                            component: {
                                name: 'Logout',
                                type: 'oauth',
                            },
                        },
                    ],
                },
            ],
        },
    },

    // 路由定义
    routes: [
        {
            path: '/',
            component: 'SideNavFrameWrapper',
            childRoutes: [
                {
                    path: '/account/setting',
                    component: 'AccountSetting',
                },
            ],
        },
    ],
};

if (ENV.TENANT === 'miao' || ENV.TENANT === 'localhost') {
    config.components!.UserMenu.items[0].items.push({
        type: 'item',
        key: 'changeLanguage',
        component: {
            name: 'ChangeLanguage',
            type: 'oauth',
        },
    });
}
