import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;

export const SalesPlansLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = '/admin/sales_plans';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/sales_plans/${id}`;
        } else {
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.baseInfo = {
                    name: res.name,
                    dateRange: {
                        start: res.startTime,
                        end: res.endTime,
                    },
                };
                res.ruleInfo = {
                    dimension: res.stock.dimension,
                    period: res.stock.period,
                    subsites: res.subsites,
                    goods:
                        res.goods &&
                        res.goods.map((item: any) => {
                            item.subsiteId = item.subsite.id;
                            item.subsiteName = item.subsite.name;
                            item.merchantId = item.merchant.id;
                            item.merchantName = item.merchant.name;
                            return item;
                        }),
                };
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
        config.apiPath = '/admin/sales_plans';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        if (params.id) {
            config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`;
            config.apiPath = `/admin/sales_plans/${params.id}`;
        }
        const newParams: any = dataParser(params);
        newParams.id = params.id;
        return await api.put(newParams, config);
    },
};
/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    if (params.baseInfo) {
        newParams.name = params.baseInfo.name;
        newParams.startTime = params.baseInfo.dateRange.start;
        newParams.endTime = params.baseInfo.dateRange.end;
    }
    if (params.ruleInfo) {
        newParams.stock = {
            dimension: params.ruleInfo.dimension,
            period: params.ruleInfo.period,
        };
        newParams.subsites = params.ruleInfo.subsites;
        newParams.goods = params.ruleInfo.goods;
    }
    return newParams;
}
