import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { forEach, map, remove, get } from 'lodash';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { Input, Modal } from 'antd';

const { getText } = services.language;

interface MemberType {
    id: string;
    loginName?: string;
    name?: string;
    select?: boolean;
}

const TableComponentId = 'MemberSelectorTable';
let TableComponentNo = 1;
let MemberEntity: any = {};
let contentEntity: any = {};
/**
 * 选择话题
 */
class MemberContent extends PureComponent<{
    value: MemberType[];
    onChange: (products: MemberType[]) => void;
    params?: any;
    type?: string;
    requestStatus: any;
}> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        MemberEntity = EntitiesManager.get('MemberSelector');
        contentEntity = new MemberEntity({});
        this.tableComponentId = TableComponentId + TableComponentNo;
        TableComponentNo++;
    }
    tableComponentId: string = '';
    dispatch: any = {};
    state = { selectModalVisible: false, memberList: [] };
    selectedRows = new Array<MemberType>();

    componentWillUnmount() {
        this.dispatch(actions.unmountEntityAction(contentEntity));
    }
    componentWillReceiveProps(nextProps: any) {
        let tableRowSelectionChangeAction = false;
        //在选中列表中删除数据后更新弹窗选中状态
        if (
            (!nextProps.value || nextProps.value.length === 0) &&
            this.props.value &&
            this.props.value.length > 0
        ) {
            tableRowSelectionChangeAction = true;
            this.selectedRows = [];
        }
        if (nextProps.value) {
            tableRowSelectionChangeAction = true;
            this.selectedRows = nextProps.value.map((subsite: any) => ({
                ...subsite,
                id: String(subsite.id),
            }));
        }
        if (
            (nextProps.requestStatus === 'success' && this.props.requestStatus === 'pending') ||
            tableRowSelectionChangeAction
        ) {
            const selectedRows = this.selectedRows;
            if (selectedRows && selectedRows.length > 0) {
                setTimeout(() => {
                    this.dispatch(
                        actions.tableRowSelectionChangeAction(
                            this.tableComponentId,
                            map(selectedRows, (value) => {
                                return value.id + '';
                            })
                        )
                    );
                }, 300);
            } else {
                this.dispatch(actions.tableRowSelectionChangeAction(this.tableComponentId, []));
            }
        }
    }
    onTableRowSelect = (record: MemberType, selected: boolean) => {
        if (selected) {
            this.selectedRows = [record];
        } else {
            remove(this.selectedRows, (i) => {
                return i.id === record.id;
            });
        }
    };

    onClearAll = () => {
        this.selectedRows = [];
        this.dispatch(actions.tableRowSelectionChangeAction(TableComponentId, []));
    };

    onSelectOk = () => {
        const { onChange } = this.props;
        if (onChange) {
            forEach(this.selectedRows, (subsite) => {
                subsite.select = true;
            });

            onChange(this.selectedRows);
            this.toggleModalSelectMember();
        }
    };
    getSelectConfig = () => {
        const { value } = this.props;
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: contentEntity,
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'left' },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    // onSubmit: (event: any) => {
                    //     event.stopPropagation();
                    //     contentEntity.search(params);
                    // },
                    fields: [
                        {
                            property: 'mobile',
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    rowKey: 'id',
                    rowSelection: {
                        type: 'radio',
                        selectedRowKeys: map(value, 'id'),
                        onSelect: (record: MemberType, selected: boolean, selectedRow: any) => {
                            this.onTableRowSelect(record, selected);
                        },

                        onChange: () => {},
                    },
                    loadFirstPage: true,
                    columns: [
                        {
                            property: 'loginName',
                        },
                    ],
                },
            ],
        };
    };

    toggleModalSelectMember = () => {
        const { selectModalVisible } = this.state;
        this.setState({ selectModalVisible: !selectModalVisible }, () => {
            if (!selectModalVisible && contentEntity.paging) {
                contentEntity.search({
                    ...contentEntity.paging,
                });
            }
        });
    };
    showModal = () => {
        this.setState({
            selectModalVisible: true,
        });
    };
    render() {
        const selectConfig = this.getSelectConfig();
        const selectModalContent = createElement(
            ComponentsManager.get(selectConfig.component),
            selectConfig
        );

        const selectModalProps = {
            width: 900,
            title: getText('xzhym'),
            visible: this.state.selectModalVisible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onSelectOk,
            onCancel: this.toggleModalSelectMember,
        };
        let inputVal;
        if (this.props.value && this.props.value[0]) {
            inputVal = this.props.value[0].loginName;
        }
        return (
            <div style={{ display: 'inline-block' }}>
                <Input
                    value={inputVal}
                    onClick={this.showModal}
                    placeholder={services.language.getText('selectPlease')}
                ></Input>
                <Modal {...selectModalProps}>{selectModalContent}</Modal>
            </div>
        );
    }
}
export const Member = connect((_state: any) => {
    let requestStatus = get(contentEntity, 'requestStatus', null);
    const result = { requestStatus };
    return result;
})(MemberContent);
