import { api, errorHandle, navigation } from '@comall-backend-builder/core/lib/services';
import { Button } from '@comall-backend-builder/components-basis';
import {
    Modal,
    message as AntMessage,
    Row as AntRow,
    Card as AntCard,
    Col as AntCol,
    Table as AntTable,
    Select,
    Upload,
    Icon,
    Popover,
} from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { map, find } from 'lodash';
import { UploadFile } from 'antd/es/upload/interface';
import { UploadChangeParam } from 'antd/lib/upload';
import { CouponOrderAppliesSelectorDisplay } from '../../components/coupon-order-applies-selector-display';
import { CouponOrderAppliesSelector } from '../../components/coupon-order-applies-selector';
import { services } from '@comall-backend-builder/core';

const { Option } = Select;

interface PictureVo {
    id: number;
    url: string;
}
export interface MallActivityRegistrationApplyProps {
    params: any;
}

export interface MallActivityRegistrationApplyStates {
    data: any | null;
    sibilitiesData: any | null;
    reasons: any | null;
    reasonId: number;
    responsibilityId: number;
    remark: string;
    pictures: Array<PictureVo>;
    fileList: Array<UploadFile>;
    previewVisible: boolean;
    previewImage: string;
}

class mallActivityRegistrationApplyForm extends Component<
    MallActivityRegistrationApplyProps,
    MallActivityRegistrationApplyStates
> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: null,
            sibilitiesData: null,
            reasons: null,
            reasonId: 0,
            responsibilityId: 0,
            remark: '',
            pictures: [],
            fileList: [],
            previewVisible: false,
            previewImage: '',
        };
    }

    componentDidMount() {
        const { params } = this.props;
        const id = params.id;
        //获取详细信息
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                apiPath: `/admin/mall_activity_orders/${id}`,
            }
        ).then((response: any) => {
            response.itemList = [
                {
                    id: response.orderProduct.itemId,
                    saleRuleName: response.activity.name,
                    executePrice: response.payableAmount,
                    pointExcutePrice: response.pointAmount,
                    quantity: response.orderProduct.quantity,
                },
            ];
            response.itemList.map((item: any) => {
                item.coupons = [];
                return item;
            });
            this.setState({
                data: response,
            });
        });
        //获取选择原因
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                apiPath: `/admin/reasons/2`,
            }
        ).then((response: any) => {
            this.setState({
                reasons: response,
            });
        });
        //获取责任归属
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                apiPath: `/admin/responsibilities`,
            }
        ).then((response: any) => {
            this.setState({
                sibilitiesData: response,
                responsibilityId: 2, //默认客户责任
            });
        });
    }

    //加载会员信息
    renderMemberInfo = () => {
        const { data } = this.state;
        return (
            <AntCard type="inner" title={services.language.getText('hyxx')}>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('name')}:</AntCol>
                    <AntCol span={5}>{data.memberName}</AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('phoneNum')}:</AntCol>
                    <AntCol span={5}>{data.mobile}</AntCol>
                </AntRow>
            </AntCard>
        );
    };

    onChangeResponsibilityId = (e: any) => {
        this.setState({
            responsibilityId: e,
        });
    };

    onChangeReasonId = (e: any) => {
        this.setState({
            reasonId: e,
        });
    };

    onChangeRemark = (e: any) => {
        if (e.target.value) {
            const value = e.target.value;
            this.setState({
                remark: value,
            });
        }
    };

    handleChange = (info: UploadChangeParam) => {
        const fileList = info.fileList;
        const pictures: Array<PictureVo> = [];
        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i].response;
            if (file) {
                pictures.push({
                    id: file.id,
                    url: file.path,
                });
            }
        }
        this.setState({
            pictures: pictures,
            fileList: info.fileList,
        });
    };

    handlePreview = (file: UploadFile) => {
        if (file && file.response && file.response.path) {
            this.setState({
                previewImage: file.response.path,
                previewVisible: true,
            });
        }
    };

    handleCancel = () => this.setState({ previewVisible: false });

    renderApplyInfo = () => {
        const { data, reasons, reasonId } = this.state;
        return (
            <AntCard type="inner" title={services.language.getText('shsqxx')}>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('orderNumber')}:</AntCol>
                    <AntCol span={5}>{data.orderNo}</AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('sqlx')}:</AntCol>
                    <AntCol span={5}>退券退款</AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('sqyy')}:</AntCol>
                    <AntCol span={5}>
                        <Select
                            value={reasonId}
                            style={{ width: '200px' }}
                            onChange={(e: any) => this.onChangeReasonId(e)}
                        >
                            <Option value={0}>请选择</Option>
                            {map(reasons, (item) => {
                                return (
                                    <Option key={item.id} value={item.id}>
                                        {item.reason}
                                    </Option>
                                );
                            })}
                        </Select>
                    </AntCol>
                </AntRow>
            </AntCard>
        );
    };

    renderServiceInfo = () => {
        const {
            sibilitiesData,
            responsibilityId,
            fileList,
            previewVisible,
            previewImage,
        } = this.state;
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">{services.language.getText('upload')}</div>
            </div>
        );
        const url = `${ENV.API_ROOT}/admin/images/upload`;
        return (
            <AntCard type="inner" title={services.language.getText('kfjl')}>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('kfbz')}:</AntCol>
                    <AntCol span={15}>
                        <textarea
                            className="ant-input"
                            placeholder={services.language.getText('inputPlease')}
                            style={{
                                height: '73px',
                                minHeight: '73px',
                                maxHeight: '136px',
                                overflowY: 'hidden',
                            }}
                            onChange={(e: any) => this.onChangeRemark(e)}
                            maxLength={400}
                        ></textarea>
                    </AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('kfpz')}:</AntCol>
                    <AntCol span={20}>
                        <Upload
                            name="fileName"
                            accept="image/*"
                            action={url}
                            listType="picture-card"
                            fileList={fileList}
                            onChange={this.handleChange}
                            onPreview={this.handlePreview}
                        >
                            {fileList.length >= 6 ? null : uploadButton}
                        </Upload>
                        <Modal visible={previewVisible} footer={null} onCancel={this.handleCancel}>
                            <img alt="example" style={{ width: '100%' }} src={previewImage} />
                        </Modal>
                    </AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('zrgs')}:</AntCol>
                    <AntCol span={5}>
                        <Select
                            value={responsibilityId}
                            style={{ width: '200px' }}
                            onChange={(e: any) => this.onChangeResponsibilityId(e)}
                        >
                            {map(sibilitiesData, (item) => {
                                return (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('auditStatus')}:</AntCol>
                    <AntCol span={5}></AntCol>
                </AntRow>
            </AntCard>
        );
    };

    onAddCoupons = (row: any, coupons: any) => {
        const { data } = this.state;
        let item = find(data.itemList, { id: row.id }) || {};
        if (!coupons || coupons.length === 0) {
            return;
        }
        if (row) {
            item.coupons = coupons; //需求：修改后的，覆盖之前选择的数据
            this.setState(
                {
                    data: data,
                },
                () => {
                    this.forceUpdate();
                }
            );
        }
    };

    getTableConfig = () => {
        const { params } = this.props;
        let newColumns = [
            {
                title: services.language.getText('activityName'),
                key: 'saleRuleName',
                dataIndex: 'saleRuleName',
                render: (text: any) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: services.language.getText('zxxj'),
                key: 'executePrice',
                dataIndex: 'executePrice',
                render: (text: any) => {
                    return <span>{(+text || 0).toFixed(2)}</span>;
                },
            },
            {
                title: services.language.getText('zxjf'),
                key: 'pointExcutePrice',
                dataIndex: 'pointExcutePrice',
                render: (text: any) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: services.language.getText('gmsl'),
                key: 'quantity',
                dataIndex: 'quantity',
                render: (text: any) => {
                    return <span>{text}</span>;
                },
            },
            {
                title: services.language.getText('shsl'),
                key: 'coupons',
                dataIndex: 'coupons',
                render: (coupons: any, row: any) => {
                    const hasCoupons = coupons && coupons.length > 0;
                    return (
                        <div>
                            {hasCoupons ? (
                                <label>
                                    {coupons.length}张
                                    <Popover
                                        title={services.language.getText('xzyhq')}
                                        content={
                                            <CouponOrderAppliesSelectorDisplay
                                                disabled={false}
                                                orderId={params.id}
                                                params={{ orderItemId: row.id, canRefund: true }}
                                                apiPath={`/admin/mall_activity_orders/${params.id}/order_item_records`}
                                                id={row.id}
                                                onChange={(coupons) =>
                                                    this.onAddCoupons(row, coupons)
                                                }
                                                data={coupons}
                                            />
                                        }
                                    >
                                        <span
                                            style={{
                                                marginLeft: '10px',
                                                color: '#1890ff',
                                                cursor: 'pointer',
                                            }}
                                        >
                                            修改
                                        </span>
                                    </Popover>
                                </label>
                            ) : (
                                <CouponOrderAppliesSelector
                                    orderId={params.id}
                                    apiPath={`/admin/mall_activity_orders/${params.id}/order_item_records`}
                                    params={{ orderItemId: row.id, canRefund: true }}
                                    selectValues={[]}
                                    onChange={(coupons) => this.onAddCoupons(row, coupons)}
                                />
                            )}
                        </div>
                    );
                },
            },
            {
                title: services.language.getText('tkxj'),
                key: 'coupons',
                dataIndex: 'coupons',
                render: (coupons: any) => {
                    let money = 0;
                    const hasCoupons = coupons && coupons.length > 0;
                    if (hasCoupons) {
                        coupons.map((coupon: any) => {
                            money = money + coupon.executePrice;
                            return coupon;
                        });
                    }
                    return hasCoupons ? <span>{(+money || 0).toFixed(2)}</span> : null;
                },
            },
            {
                title: services.language.getText('tkjf'),
                key: 'coupons',
                dataIndex: 'coupons',
                render: (coupons: any) => {
                    let point = 0;
                    const hasCoupons = coupons && coupons.length > 0;
                    if (hasCoupons) {
                        coupons.map((coupon: any) => {
                            point = point + coupon.pointExecutePrice;
                            return coupon;
                        });
                    }
                    return hasCoupons ? <span>{point}</span> : null;
                },
            },
        ];
        let config: any = {
            rowKey: 'id',
            columns: [...newColumns],
            scroll: {
                y: 400,
            },
            style: {
                marginTop: 20,
            },
        };
        return config;
    };

    renderCouponInfo = () => {
        const { data } = this.state;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: data.itemList,
        };
        return <AntTable {...tableProps} pagination={false}></AntTable>;
    };

    // 审核
    submit(auditStatus: 'SUCCESS' | 'FAIL') {
        const { data, reasonId, remark, responsibilityId, pictures } = this.state;
        const { params } = this.props;

        if (auditStatus === 'SUCCESS') {
            if (!reasonId) {
                AntMessage.warning(services.language.getText('qxzsqyy'));
                return false;
            }
            let refundCoupons: any = [];
            data.itemList.map((item: any) => {
                if (item.coupons && item.coupons.length > 0) {
                    item.coupons.map((coupon: any) => {
                        refundCoupons.push(coupon);
                        return coupon;
                    });
                }
                // 复用虚拟订单售后  暂不明确为何要清空
                // item.coupons = [];
                return item;
            });
            if (refundCoupons.length === 0) {
                AntMessage.warning(services.language.getText('qxzyshdyhq'));
                return false;
            }
            api.post(
                {
                    orderId: params.id,
                    reasonId: reasonId,
                    remark: remark,
                    responsibilityId: responsibilityId,
                    pictures: pictures,
                    refundCoupons: refundCoupons,
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                    apiPath: `/admin/mall_activity_service_applies`,
                }
            )
                .then(() => {
                    AntMessage.success(services.language.getText('shtgcg'));
                    navigation.goBack();
                })
                .catch(errorHandle);
        }
    }

    render() {
        const { data } = this.state;
        return data ? (
            <div className="coupon-service-applies-edit-form">
                <AntCard>
                    <AntRow style={{ marginBottom: 20 }}>{this.renderMemberInfo()}</AntRow>
                    <AntRow style={{ marginBottom: 20 }}>{this.renderApplyInfo()}</AntRow>
                    <AntRow style={{ marginBottom: 20 }}>{this.renderServiceInfo()}</AntRow>
                    <AntRow style={{ marginBottom: 20 }}>{this.renderCouponInfo()}</AntRow>
                </AntCard>
                <div style={{ textAlign: 'center', marginTop: '30px' }}>
                    <Button
                        type="primary"
                        onClick={this.submit.bind(this, 'SUCCESS')}
                        style={{ marginRight: 24 }}
                    >
                        {services.language.getText('bcbtgsh')}
                    </Button>
                    <Button route="goBack">{services.language.getText('qxshsqd')}</Button>
                </div>
            </div>
        ) : null;
    }
}
function mapStateToProps(_state: any, props: any) {
    const { params } = props;
    return {
        params,
    };
}
export const MallActivityRegistrationApplyForm = connect(mapStateToProps)(
    mallActivityRegistrationApplyForm
);
