import React, { PureComponent, createElement } from 'react';
import { Modal as AntModal } from 'antd';
import {
    ComponentsManager,
    EntitiesManager,
    builder,
    actions,
    services,
} from '@comall-backend-builder/core';
import { isEqual, remove } from 'lodash';
// import './index.less';
import { Shop } from '../../../../containers/select-shop';

const TableComponentId = 'MerchantPageSelector';
let Entity: any = {};
let entity: any = {};

interface SelectMerchantProps {
    onChange: (value: any[]) => void;
    visible: boolean;
    params: any;
    onCancel: () => void;
    customFields?: {
        property: string;
    }[];
    customColumns?: {
        property: string;
    }[];
}

export class SelectMerchant extends PureComponent<SelectMerchantProps, any> {
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('MerchantPageSelector');
        entity = new Entity({});
        this.state = { selectedRowKeys: [] };
    }
    dispatch: any = {};
    selectedRows = new Array<Shop>();

    componentWillReceiveProps(nextProps: any) {
        const { params: preParams, visible: preVisible } = this.props;
        const { params: nextParams, visible: nextVisible } = nextProps;
        if (!preVisible && nextVisible) {
            //判断filters 当前是否有排序字段 没有则更新页面显示（解决同一组件多场景调用用下 !.filters.order页面排序区却高亮的问题）
            if (entity && (!entity.filters || (entity.filters && !entity.filters.order))) {
                const store = builder.getStore();
                store.dispatch(
                    actions.tableSortChangeAction(TableComponentId, {
                        field: undefined,
                        order: undefined,
                    })
                );
            }
            entity.search(nextParams);
            this.selectedRows = [];
        }
        if (!isEqual(preParams, nextParams) && !!nextParams.subSiteName) {
            this.selectedRows = [];
            this.setState({ selectedRowKeys: [] });
        }
    }

    onTableRowSelect = (record: Shop, selected: boolean) => {
        if (selected) {
            this.selectedRows.push(record);
        } else {
            remove(this.selectedRows, (r) => r.id === record.id);
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: Shop[]) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            this.selectedRows = [];
        }
    };

    onOk = () => {
        const { onChange } = this.props;
        onChange && onChange(this.selectedRows);
    };
    initEntityFilters = () => {
        entity.filtersChange({
            merchantType: undefined,
            merchantTagId: undefined,
            merchantName: undefined,
            sorter: undefined,
        });
        entity.pageChange({ page: 1 });
        const store = builder.getStore();
        store.dispatch(actions.filtersChangeAction(entity, {}));
        store.dispatch(
            actions.tableSortChangeAction(TableComponentId, { field: undefined, order: undefined })
        );
    };

    getConfig = () => {
        const { params, customFields, customColumns } = this.props;
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: params || {},
            items: [
                {
                    component: 'FilterForm',
                    direction: 'inline',
                    style: {
                        textAlign: customFields && customFields.length > 0 ? 'left' : 'right',
                        marginBottom: '20px',
                    },
                    fieldCol: {
                        span: 12,
                    },
                    labelCol: 5,
                    reset: true,
                    submit: {
                        text: services.language.getText('common.search'),
                        style: {
                            marginRight: 16,
                        },
                    },
                    onSubmit: (event: any) => {
                        event.stopPropagation();
                        entity.search(params);
                    },
                    onReset: () => {
                        this.initEntityFilters();
                    },
                    fields:
                        customFields && customFields.length > 0
                            ? customFields
                            : [
                                  {
                                      property: 'merchantCode',
                                  },
                                  {
                                      property: 'merchantName',
                                  },
                              ],
                },
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    key: Math.random(),
                    scroll: {
                        y: 400,
                    },
                    pagination: false,
                    rowKey: 'id',
                    rowSelection: {
                        type: 'checkbox',
                        selectedRowKeys: this.state.selectedRowKeys,
                        onSelect: (record: Shop, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: Shop[]) => {
                            this.onTableRowSelectAll(selected, rows);
                        },
                        onChange: (selectedRowKeys: any, selectedRows: any) => {
                            this.setState({ selectedRowKeys });
                        },
                    },
                    loadFirstPage: true,
                    columns:
                        customColumns && customColumns.length > 0
                            ? customColumns
                            : [
                                  {
                                      property: 'merchantName',
                                  },
                                  {
                                      property: 'code',
                                      sorter: true,
                                  },
                                  {
                                      property: 'merchantType',
                                  },
                                  {
                                      property: 'merchantStatus',
                                  },
                              ],
                },
            ],
        };
    };

    render() {
        const { visible, onCancel } = this.props;
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 900,
            title: services.language.getText('selectSubsites'),
            visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: onCancel,
        };
        return <AntModal {...modalProps}>{modalContent}</AntModal>;
    }
}
