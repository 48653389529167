import React, { PureComponent } from 'react';
import { InputNumber, message, Button, Popover } from 'antd';
import { InputNumberProps } from 'antd/lib/input-number';
import { services as coreServices } from '@comall-backend-builder/core';
import { chain, isEqual, cloneDeep, isNil } from 'lodash';
import moment from 'moment';
import { services } from '@comall-backend-builder/design-components-basis';

import {
    NewMallActivityRow,
    MallActivityTicketPrice,
    MallActivityFreePeriodUnit,
    NewMallActivityMemberCardLevel,
    MallActivityTicket,
    NewMallActivityRoundItem,
    NewMallActivityType,
    NewMallActivityTicketType,
    CardLevelChangeResult,
    MallActivityTicketPromotionPrice,
    MallActivityTicketPriceFreeRule,
} from './types';
import { PaginationResult } from '../../services/api';
import { language } from '@comall-backend-builder/core/lib/services';

const api = coreServices.api;
const { utils } = services;

interface PopoverNumberInputButtonProps extends Omit<InputNumberProps, 'onSubmit'> {
    buttonType?: 'link' | 'default' | 'ghost' | 'primary' | 'dashed' | 'danger';
    text: string;
    onSubmit: (value?: number) => void;
    disabled?: boolean;
    disabledMsg?: string;
}

export class PopoverNumberInputButton extends PureComponent<
    PopoverNumberInputButtonProps,
    {
        value?: number;
        visible: boolean;
    }
> {
    constructor(props: PopoverNumberInputButtonProps) {
        super(props);
        this.state = {
            visible: false,
        };
    }
    onChange = (num?: number) => {
        this.setState({ value: num });
    };
    onSubmit = () => {
        const { value } = this.state;
        const { onSubmit } = this.props;
        onSubmit(value);
        this.toggleVisible();
    };
    toggleVisible = () => {
        const { disabled, disabledMsg = '' } = this.props;
        if (disabled) {
            message.warn(disabledMsg);
            return;
        }
        const { visible } = this.state;
        this.setState({ visible: !visible, value: undefined });
    };
    private get inputProps() {
        const { buttonType, text, onSubmit, ...other } = this.props;
        return other;
    }
    renderPopoverInput = () => {
        const { value } = this.state;

        return (
            <div className="new-mall-activity__input-popover">
                <InputNumber
                    min={1}
                    max={999999}
                    precision={0}
                    placeholder={coreServices.language.getText('inputPlease')}
                    {...this.inputProps}
                    value={value}
                    onChange={this.onChange}
                ></InputNumber>
                <Button className="popover-btn" type="default" onClick={this.toggleVisible}>
                    {language.getText('common.cancel')}
                </Button>
                <Button className="popover-btn" type="primary" onClick={this.onSubmit}>
                    {language.getText('common.ok')}
                </Button>
            </div>
        );
    };
    render() {
        const { text, buttonType = 'default' } = this.props;
        const { visible } = this.state;
        return (
            <Popover
                placement={'top'}
                trigger="click"
                content={this.renderPopoverInput()}
                visible={visible}
            >
                <Button type={buttonType} onClick={this.toggleVisible}>
                    {text}
                </Button>
            </Popover>
        );
    }
}

/**
 * 获取门店卡等级列表
 * @param subsiteId 门店id
 */
async function getCardLevels(subsiteId: string) {
    const config = {
        apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
        apiPath: '/admin/store_card_levels',
    };
    const params = { subsiteId, page: 1, per_page: 500, status: 'ENABLE' };
    const res = await api.get<PaginationResult<{ cardLevelId: string; name: string }>>(
        params,
        config
    );
    return chain(res.result)
        .filter((card) => Boolean(card.cardLevelId))
        .map((card) => ({ id: card.cardLevelId + '', name: card.name }))
        .unionBy('id')
        .value();
}

/**
 * 根据表单上门店和卡等级购买限制是否发生了变更，判断票档价格可以设置的卡等级列表是否需要发生变更
 * @returns changed 卡等级是否发生了变更
 * @returns allCardLevels 变更后的门店所有卡等级列表
 * @returns limitCardLevels 变更后的购买限制卡等级列表
 */
export async function getCardLevelChangeResult(
    nextRow: NewMallActivityRow,
    prevRow?: NewMallActivityRow
): Promise<CardLevelChangeResult> {
    const getSubsiteId = (row?: NewMallActivityRow) => row?.baseInfo?.merchants?.[0]?.subSiteId;
    const getTargetUserLevels = (row?: NewMallActivityRow) =>
        row?.registrationInfo?.targetUserLevels;
    const prevSubsiteId = getSubsiteId(prevRow);
    const nextSubsiteId = getSubsiteId(nextRow);
    const prevTargetUserLevels = getTargetUserLevels(prevRow);
    const nextTargetUserLevels = getTargetUserLevels(nextRow);
    // 门店发生变更时，重新获取卡等级列表
    // 此时之前配置的限制购买卡等级列表会被清空
    if (nextSubsiteId !== prevSubsiteId) {
        const allCardLevels = nextSubsiteId ? await getCardLevels(nextSubsiteId) : [];
        return {
            changed: true,
            allCardLevels,
            limitCardLevels: prevRow ? [] : nextTargetUserLevels,
        };
    }
    // 限制购买卡等级列表发生变更时
    if (!isEqual(prevTargetUserLevels, nextTargetUserLevels)) {
        return {
            changed: true,
            limitCardLevels: nextTargetUserLevels,
        };
    }
    return {
        changed: false,
    };
}

export function isEqualId(idX: string | number, idY: string | number) {
    return String(idX) === String(idY);
}

/**
 * 默认票档价格
 */
export const defaultMallActivityTicketPrice: MallActivityTicketPrice = {
    memberCardLevelId: -1,
    pointPrice: 0,
    salePrice: 0,
};
/**
 * 默认票档价格
 */
export const defaultMallActivityTicketPriceFreeRule: MallActivityTicketPriceFreeRule = {
    memberCardLevelId: -1,
    freePeriodUnit: MallActivityFreePeriodUnit.Month,
    freePeriodValue: 1,
    freeQuota: 0,
};

type CardLevelRule = { memberCardLevelId: number };

/**
 * 是否区分卡等级的规则，区分返回true
 */
function isNotAllCardLevel(list?: CardLevelRule[]) {
    return list?.some(({ memberCardLevelId }) => Number(memberCardLevelId) !== -1);
}

/**
 * 根据卡等级集合，重新生成票档的不同卡等级的价格集合
 * @param tickets 票档集合
 * @param cardLevels 卡等级集合
 */
export function getNewTicketsOnCardlevelsChange(
    tickets: MallActivityTicket[],
    cardLevels: NewMallActivityMemberCardLevel[]
) {
    const newValue = cloneDeep(tickets);
    // 根据集合判断是否区分卡等级的规则，如果是根据新的卡等级返回新集合，不是则返回原集合
    function newValueByCardLevels<T extends CardLevelRule>(list: T[], defaultValue: T): T[] {
        if (isNotAllCardLevel(list)) {
            return cardLevels.map((level) => {
                const item =
                    list!.find((i) => isEqualId(i.memberCardLevelId, level.id)) || defaultValue;
                return { ...item, memberCardLevelId: Number(level.id) };
            });
        }
        return list;
    }
    newValue.forEach((item) => {
        item.ticketPrices = newValueByCardLevels(item.ticketPrices, defaultMallActivityTicketPrice);
        item.ticketPriceFreeRules = newValueByCardLevels(
            item.ticketPriceFreeRules!,
            defaultMallActivityTicketPriceFreeRule
        );
    });
    return newValue;
}

/**
 * 校验票档价格信息填写
 */
export function validateTicketPrices(ticketPrices: MallActivityTicketPrice[]) {
    if (!ticketPrices?.length) {
        return true;
    }
    return ticketPrices.some(({ pointPrice, salePrice }) => isNil(pointPrice) || isNil(salePrice));
}
/**
 * 校验票档活动价格信息
 */
export function validateTicketPromotionPrice(promotionPrice: MallActivityTicketPromotionPrice) {
    const { startTime, endTime, pointPrice, salePrice } = promotionPrice;
    if (!startTime || !endTime) {
        return language.getText('pdhdjgrqbnwk');
    }
    if (isNil(pointPrice) && isNil(salePrice)) {
        return language.getText('qwzszpdhdjg');
    }
    return false;
}

/**
 * 生成rowKey
 */
export function handleKeys<T extends MallActivityTicket | NewMallActivityRoundItem>(value: T[]) {
    const valueWithKeys = value.map((item) => ({
        ...item,
        rowKey: item.rowKey || utils.uuid(),
    }));
    return valueWithKeys;
}

/**
 * 获取商场活动开始结束日期和时间
 * @description 由于商场活动新增和编辑活动时间字段不一致，所以需要传入是否新增表单
 * @param row 详情
 * @param isAdd 是否新增
 */
export function getRowDateTime(row: NewMallActivityRow, isAdd: boolean) {
    const startDate = isAdd ? row?.baseInfo?.dateRange?.start : row?.baseInfo?.editStartTime;
    const endDate = isAdd ? row?.baseInfo?.dateRange?.end : row?.baseInfo?.editEndTime;
    const startTime = row?.baseInfo?.dayTime?.[0]?.start;
    const endTime = row?.baseInfo?.dayTime?.[0]?.end;
    return { startDate, endDate, startTime, endTime };
}

/**
 * 判断日期dateA是否晚于dateB
 */
export function isAfter(dateA: string, dateB: string) {
    return moment(dateA).valueOf() > moment(dateB).valueOf();
}

function validateTickets(tickets?: MallActivityTicket[]) {
    if (!tickets?.length) {
        return language.getText('qszpd');
    }
    if (!tickets.some((t) => t.enable)) {
        return language.getText('hdhdgccbxczzsygqydpd');
    }
    if (tickets.length !== 1 && tickets.some((t) => !t.name)) {
        return language.getText('qszpdmc');
    }
    const validator = (item: MallActivityTicket) => {
        if (isNil(item.totalStock)) {
            return language.getText('qszpdsl');
        }
        if (validateTicketPrices(item.ticketPrices)) {
            return language.getText('qwzszpdjg');
        }
        return false;
    };
    let errMsg = '';
    for (let index = 0; index < tickets.length; index++) {
        const result = validator(tickets[index]);
        if (result) {
            errMsg = result;
            break;
        }
    }
    if (errMsg) {
        return errMsg;
    }
    return false;
}

function validateRound(
    startDate: string,
    endDate: string,
    round: NewMallActivityRoundItem,
    prevRound?: NewMallActivityRoundItem
) {
    const { roundStartDate: roundDate, startTime: roundStartTime, tickets } = round;
    if (!roundDate) {
        return language.getText('qszccrq');
    }
    if (!roundStartTime) {
        return language.getText('qszcckssj');
    }
    // 场次开启状态下需要校验和活动的时间冲突，未开启不校验
    if (round.enable && (isAfter(startDate, roundDate) || isAfter(roundDate, endDate))) {
        return language.getText('kqdccrqbkcchdrqfw');
    }
    const ticketResult = validateTickets(tickets);
    if (ticketResult) {
        return ticketResult;
    }
    if (validateRoundsConflict(round, prevRound)) {
        return language.getText('hdccdmgsjdbkzf');
    }
    return false;
}
/**
 * 对场次按照场次开始日期加时间进行排序
 * @param rounds 场次集合
 * @returns
 */
export function sortRoundsByStartDateTime(rounds: NewMallActivityRoundItem[]) {
    return rounds.sort(
        (a, b) =>
            moment(`${a.roundStartDate} ${a.startTime}`).valueOf() -
            moment(`${b.roundStartDate} ${b.startTime}`).valueOf()
    );
}

/**
 * 校验当前场次和上一个场次时间是否有冲突
 * @param round 当前场次
 * @param prevRound 上一个场次
 * @returns 是否存在时间冲突
 */
export function validateRoundsConflict(
    round: NewMallActivityRoundItem,
    prevRound?: NewMallActivityRoundItem
) {
    if (!prevRound) {
        return false;
    }
    // 校验场次时间是否冲突
    // 目前只检验上一场次的结束时间是否晚于当前场次的开始时间，所以场次必须严格按照时间顺序来设置
    const roundStart = `${round.roundStartDate} ${round.startTime}`;
    const prevRoundEnd = `${prevRound.roundStartDate} ${prevRound.endTime || prevRound.startTime}`;
    // 如果上一场有结束时间，那么只要上一场的结束时间不晚于下一场的结束时间即可，也就是上一场的结束时间可以等于下一场的开始时间
    if (prevRound.endTime && !isAfter(prevRoundEnd, roundStart)) {
        return false;
    } else if (isAfter(roundStart, prevRoundEnd)) {
        // 如果上一场没有结束时间只有开始时间，那么一下场的开始时间必须晚于上一场的开始时间
        return false;
    }
    // 校验未通过
    return true;
}

export function validateRounds(row: NewMallActivityRow, isAdd: boolean) {
    const {
        registrationInfo: { type, ticketType, activityRounds, activitySingleRoundTickets },
    } = row;
    const { startDate, endDate } = getRowDateTime(row, isAdd);
    if (!startDate || !endDate) {
        return language.getText('qshdrqsj');
    }
    // 宣传类活动没有场次票档信息不校验
    if (type === NewMallActivityType.Notification) {
        return false;
    }
    // 按活动参加时，校验票档
    if (ticketType === NewMallActivityTicketType.Activity) {
        const activityTicketsResult = validateTickets(activitySingleRoundTickets);
        if (activityTicketsResult) {
            return activityTicketsResult;
        }
    }
    // 按场次参加时，场次相关校验
    if (ticketType === NewMallActivityTicketType.Round) {
        if (!activityRounds?.length) {
            return language.getText('qtjhdcc');
        }
        if (!activityRounds.some((r) => r.enable)) {
            return language.getText('hdbxczzsygqydcc');
        }
        let result = '';
        const rounds = sortRoundsByStartDateTime(activityRounds);
        for (let index = 0; index < rounds.length; index++) {
            const validateRoundResult = validateRound(
                startDate,
                endDate,
                rounds[index],
                rounds[index - 1]
            );
            if (validateRoundResult) {
                result = validateRoundResult;
                break;
            }
        }
        if (result) {
            return result;
        }
    }
    return false;
}

export function disabledDate(
    current: moment.Moment | null,
    row: NewMallActivityRow,
    isAdd: boolean
) {
    const { startDate, endDate } = getRowDateTime(row, isAdd);
    if (!current) {
        return false;
    }
    const currentDate = moment(current.format('YYYY-MM-DD'));
    if (startDate && currentDate < moment(startDate)) {
        return true;
    }
    if (endDate && currentDate > moment(endDate)) {
        return true;
    }
    return false;
}

export function formatTime(time?: string) {
    if (!time || time.length === 8) {
        return time;
    }
    if (time.length === 5) {
        return time + ':00';
    }
    return time;
}

/**
 * 新增票档
 * @param ticket 票档
 * @returns
 */
export function newTicket(): MallActivityTicket {
    return {
        rowKey: utils.uuid(),
        ticketPrices: [],
        enable: true,
    };
}

/**
 * 新增票档
 * @param ticket 票档
 * @returns
 */
export function newRound(): NewMallActivityRoundItem {
    return {
        rowKey: utils.uuid(),
        tickets: [newTicket()],
        enable: true,
    };
}

/**
 * 复制票档，将id、库存和rowKey重置
 * @param ticket 票档
 * @returns
 */
export function cloneTicket(ticket: MallActivityTicket): MallActivityTicket {
    return {
        ...cloneDeep(ticket),
        id: undefined,
        rowKey: utils.uuid(),
        soldQuantity: undefined,
        enable: true,
    };
}

/**
 * 复制场次，将id、启禁用状态和rowKey重置
 * @param round 场次
 * @returns
 */
export function cloneRound(round: NewMallActivityRoundItem): NewMallActivityRoundItem {
    const { tickets, ...others } = round;
    return {
        ...cloneDeep(others),
        id: undefined,
        enable: true,
        rowKey: utils.uuid(),
        tickets: tickets?.map(cloneTicket),
    };
}
