import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { EntityButtonProps } from '../../../components';
import { isEmpty } from 'lodash';
import { ForwardDataCenterModal } from '../../../services';
import { api } from '@comall-backend-builder/core/lib/services';

export const config: Config = {
    entities: {
        DepositOrderEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/deposit_order',
            filters: {
                orderNo: {
                    type: 'string',
                    displayName: '<<djdh>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
                guideName: {
                    type: 'string',
                    displayName: '<<kdr>>',
                },
                merchantBigCode: {
                    type: 'string',
                    displayName: '<<djsjm>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<ddzt>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'PAYMENT', name: '<<dsy>>' },
                        { id: 'USED', name: '<<ywc>>' },
                        { id: 'REFUNDING', name: '<<tkz>>' },
                        { id: 'CANCELLED', name: '<<yjtk>>' },
                    ],
                    controlConfig: {
                        style: { width: '150px' },
                    },
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                merchantId: {
                    type: 'string.filterOptions.autoComplete',
                    displayName: '<<merchantName>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<xdsj>>',
                },
                amountRange: {
                    type: 'object.amountRange',
                    displayName: '<<ddjjfw>>',
                },
            },
            properties: {
                orderNo: {
                    type: 'string',
                    displayName: '<<djdh>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                merchantBigCode: {
                    type: 'string',
                    displayName: '<<djsjm>>',
                },
                orderAmount: {
                    type: 'string',
                    displayName: '<<djze>>',
                },
                merchantTradeNo: {
                    type: 'string',
                    displayName: '<<zfptshddh>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<ddzt>>',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'PAYMENT', name: '<<dsy>>' },
                        { id: 'USED', name: '<<ywc>>' },
                        { id: 'REFUNDING', name: '<<tkz>>' },
                        { id: 'CANCELLED', name: '<<yjtk>>' },
                    ],
                },
                orderCreateTime: {
                    type: 'string',
                    displayName: '<<xdsj>>',
                },
                guideName: {
                    type: 'string',
                    displayName: '<<kdr>>',
                },
            },
        },
    },
    components: {
        DepositOrderView: {
            component: 'Viewport',
            entity: 'DepositOrderEntity',
        },
        DepositOrderPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'DepositOrderFilter',
                },
                {
                    component: 'EntityButton',
                    props: {
                        type: 'primary',
                        children: '<<exportSearchResult>>',
                        style: {
                            width: 110,
                            marginBottom: 24,
                            marginTop: 17,
                        },
                        onClick: async (
                            _: any,
                            entityButtonProps: EntityButtonProps
                        ): Promise<void> => {
                            try {
                                const params: Record<string, any> = Object.assign(
                                    {},
                                    entityButtonProps.entity.params,
                                    entityButtonProps.entity.filters
                                );

                                if (params.status === 'ALL') {
                                    delete params.status;
                                }
                                if (!isEmpty(params.subsiteId)) {
                                    params.subsiteId = params.subsiteId.id;
                                }
                                if (!isEmpty(params.merchantId)) {
                                    params.merchantId = params.merchantId.id;
                                }
                                if (!isEmpty(params.dateRange)) {
                                    params.startTime = params.dateRange.start + ' 00:00:00';
                                    params.endTime = params.dateRange.end + ' 23:59:59';
                                    delete params.dateRange;
                                }
                                if (!isEmpty(params.amountRange)) {
                                    params.startOrderAmount = params.amountRange.start
                                        ? params.amountRange.start
                                        : undefined;
                                    params.endOrderAmount = params.amountRange.end
                                        ? params.amountRange.end
                                        : undefined;
                                    delete params.amountRange;
                                }
                                const config = {
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`,
                                    apiPath: '/admin/deposit_orders/export',
                                };

                                await api.get(params, config);
                                ForwardDataCenterModal();
                            } catch (err) {
                                services.errorHandle(err as any);
                            }
                        },
                    },
                },
                { component: 'DepositOrderTable' },
            ],
        },
        DepositOrderFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '查询',
            },
            reset: {
                text: '重置',
            },
            fields: [
                {
                    property: 'orderNo',
                },
                {
                    property: 'mobile',
                },
                {
                    property: 'merchantBigCode',
                },
                {
                    property: 'status',
                },
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'guideName',
                },
                {
                    property: 'dateRange',
                },
                {
                    property: 'amountRange',
                },
            ],
        },
        DepositOrderTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: { x: true },
                    columns: [
                        {
                            property: 'orderNo',
                        },
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'merchantName',
                        },
                        {
                            property: 'guideName',
                        },
                        {
                            property: 'merchantBigCode',
                        },
                        {
                            property: 'orderAmount',
                        },
                        {
                            property: 'merchantTradeNo',
                        },
                        {
                            property: 'mobile',
                        },
                        {
                            property: 'status',
                        },
                        {
                            property: 'orderCreateTime',
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        fixed: 'right',
                        width: 160,
                        items: [
                            {
                                type: 'link',
                                text: '<<details>>',
                                path: '/deposit-order/info/{{row.id}}',
                            },
                            {
                                type: 'link',
                                text: '<<sh_2>>',
                                statusKey: 'row.canRefund',
                                path: '/deposit-order/apply/{{row.id}}',
                            },
                        ],
                    },
                },
            ],
        },
        DepositOrderInfoPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DepositOrderInfo',
                },
            ],
        },
        DepositOrderApplyPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DepositOrderApply',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/deposit-order',
                    component: 'DepositOrderView',
                    breadcrumbName: '<<depositOrder>>',
                    privilege: {
                        path: 'depositOrder',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/info/:id',
                            component: 'DepositOrderInfoPage',
                            breadcrumbName: '<<details>>',
                        },
                        {
                            path: '/apply/:id',
                            component: 'DepositOrderApplyPage',
                            breadcrumbName: '<<sh_2>>',
                        },
                        { path: '/', component: 'DepositOrderPage' },
                    ],
                },
            ],
        },
    ],
};
