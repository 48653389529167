import React, { PureComponent } from 'react';
import { Select } from '@comall-backend-builder/components-basis';

let config = {};

/**
 * filter中支持依赖数据联动的下拉单选
 */
export class FilterSelect extends PureComponent<any, any> {
    constructor(props: any) {
        super(props);
        const {
            entity: {
                filters,
                metainfo: { filters: filter },
            },
        } = props;
        if (filter && filter[props.name]) {
            // 保存当前filter的source配置
            config = filter[props.name].source;
        }
        this.state = {
            // 依赖项的值
            dependencesValue: filters && filters[props.dependences],
            // 当前filter的属性名
            name: props.name,
            // 依赖项的属性名
            dependences: props.dependences,
            // 依赖变动时重新请求数据入参名称，如果未设置，默认入参名称为依赖项的属性名
            paramsName: props.paramsName ? props.paramsName : props.dependences,
        };
    }

    // 当props发生变化时，更新待选项数据
    componentWillReceiveProps(nextProps: any) {
        const {
            entity: { filters: nextFilters },
            entity,
        } = nextProps;
        const { dependencesValue, dependences, name, paramsName } = this.state;
        // 如果props变更后，依赖项有值
        if (nextFilters && nextFilters[dependences]) {
            // 如果变更前依赖项没有值，或者变更后值和变更前不同，重新请求待选项数据
            if (!dependencesValue || dependencesValue !== nextFilters[dependences]) {
                // 请求待选项数据
                entity.loadFilterOptions(name, config, { [paramsName]: nextFilters[dependences] });
                // 将已选选项置空
                entity.filtersChange({ [name]: null });
                // 保存新的依赖项的值
                this.setState({
                    dependencesValue: nextFilters[dependences],
                });
            }
        } else if (dependencesValue) {
            // 如果变更后依赖项没有值，变更前有值
            // 不带参数请求待选项数据
            entity.loadFilterOptions(name, config, {});
            // 清楚保存的依赖项的值
            this.setState({
                dependencesValue: null,
            });
        }
    }

    render() {
        const props: any = this.props;
        return <Select {...props} />;
    }
}
