// @ts-nocheck
import React, { PureComponent } from 'react';
import { Button, Modal, message, Select, Input, Spin } from 'antd';
import { services } from '@comall-backend-builder/core';
import { errorHandle } from '@comall-backend-builder/core/lib/services';

import './index.less';

const { api } = services;

const DeliveryMode = {
    EXPRESS_DELIVERY: '快递',
    SELF_MENTION: '自提',
    CITYWIDE_DELIVERY: '同城配',
};

enum DeliveryModeEnum {
    /**
     * 快递
     */
    EXPRESS_DELIVERY = 'EXPRESS_DELIVERY',
    /**
     * 自提
     */
    SELF_MENTION = 'SELF_MENTION',
    /**
     * 同城配
     */
    CITYWIDE_DELIVERY = 'CITYWIDE_DELIVERY',
}

/**
 * 发货按钮
 */
export class DeliverButton extends PureComponent<
    any,
    {
        showModal: boolean;
        expressCompanies: {
            id: string;
            name: string;
        }[];
        loadingExpress: boolean;
        selectedExpressCompanyId: string;
        expressNumber: string;
    }
> {
    state = {
        showModal: false,
        expressCompanies: [],
        loadingExpress: false,
        selectedExpressCompanyId: '',
        expressNumber: '',
    };

    componentDidMount() {
        const { row } = this.props;
        if (row && row.deliveryMode !== DeliveryModeEnum.SELF_MENTION) {
        }
    }

    toggleModal = () => {
        const { showModal, expressCompanies } = this.state;
        const { row } = this.props;
        if (
            !showModal &&
            row &&
            row.deliveryMode !== DeliveryModeEnum.SELF_MENTION &&
            expressCompanies.length === 0
        ) {
            this.getExpressCompanies();
        }
        this.setState({ showModal: !showModal });
    };

    goDeliverPage = () => {
        const { row } = this.props;
        const deliveryMode = row && row.deliveryMode ? row.deliveryMode : null;
        let deliverType = '0';
        if (deliveryMode === DeliveryModeEnum.SELF_MENTION) {
            deliverType = '1002';
        }
        if (deliveryMode === DeliveryModeEnum.CITYWIDE_DELIVERY) {
            deliverType = '1004';
        }
        if (deliveryMode === DeliveryModeEnum.EXPRESS_DELIVERY) {
            deliverType = '1001';
        }

        window.open(`#/manage-order/deliver/${row.id}?deliverType=${deliverType}`, '_blank');
    };

    getExpressCompanies = () => {
        const { row } = this.props;
        this.setState({
            loadingExpress: true,
        });
        api.get(
            { deliveryMode: row.deliveryMode },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: '/admin/express_companies',
            }
        ).then((res) => {
            this.setState({
                expressCompanies: res,
                loadingExpress: false,
            });
        });
    };

    onSelectExpressCompany = (id) => {
        this.setState({
            selectedExpressCompanyId: id,
        });
    };

    onInputExpressNumber = (e) => {
        this.setState({
            expressNumber: e.target.value,
        });
    };

    handleOk = () => {
        const { entity, row } = this.props;
        const { selectedExpressCompanyId, expressNumber } = this.state;

        if (row.deliveryMode !== DeliveryModeEnum.SELF_MENTION) {
            if (!selectedExpressCompanyId) {
                message.warning(services.language.getText('qxzwlgs'));
                return;
            }
            if (
                row.deliveryMode === DeliveryModeEnum.EXPRESS_DELIVERY &&
                (!expressNumber.trim() || expressNumber.trim().length === 0)
            ) {
                message.warning(services.language.getText('qsrkddh'));
                return;
            }
        }
        const params = {
            deliveryMode: row.deliveryMode,
            expressCompanyId: selectedExpressCompanyId,
            expressNumber,
        };
        message.loading();
        api.put(params, {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: `/admin/orders/${row.id}/deliver`,
        })
            .then(() => {
                message.destroy();
                entity.search();
                this.toggleModal();
            })
            .catch((error) => {
                message.destroy();
                this.toggleModal();
                errorHandle(error);
            });
    };

    render() {
        const { showModal, loadingExpress, expressCompanies } = this.state;
        const { row } = this.props;
        let disabled = false;
        if (row.canImport !== undefined) {
            disabled = !row.canImport;
        }
        const deliveryMode = row && row.deliveryMode ? row.deliveryMode : null;

        return (
            <div className="deliver-button">
                <Button
                    disabled={disabled}
                    type="link"
                    className="btn-data-statistics"
                    onClick={this.goDeliverPage}
                >
                    {services.language.getText('fh_1')}
                </Button>
                <Modal
                    onCancel={this.toggleModal}
                    visible={showModal}
                    title={services.language.getText('qrfh')}
                    footer={[
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            {services.language.getText('common.ok')}
                        </Button>,
                        <Button key="back" onClick={this.toggleModal}>
                            {services.language.getText('common.cancel')}
                        </Button>,
                    ]}
                >
                    {loadingExpress && (
                        <div className="deliver-button-loading">
                            <Spin />
                        </div>
                    )}
                    {!loadingExpress && (
                        <div className="deliver-button-row">
                            <div className="deliver-button-col-title">
                                {services.language.getText('deliveryType')}:
                            </div>
                            <div className="deliver-button-col-content">
                                {DeliveryMode[deliveryMode]}
                            </div>
                        </div>
                    )}
                    {!loadingExpress && deliveryMode !== DeliveryModeEnum.SELF_MENTION && (
                        <div className="deliver-button-row">
                            <div className="deliver-button-col-title">
                                {services.language.getText('wlgs')}:
                            </div>
                            <Select
                                onSelect={this.onSelectExpressCompany}
                                className="deliver-button-col-content"
                                placeholder={services.language.getText('selectPlease')}
                            >
                                {expressCompanies.map((company) => {
                                    return (
                                        <Select.Option key={company.id} value={company.id}>
                                            {company.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </div>
                    )}
                    {!loadingExpress && deliveryMode !== DeliveryModeEnum.SELF_MENTION && (
                        <div className="deliver-button-row">
                            <div className="deliver-button-col-title">
                                {services.language.getText('kddh')}:
                            </div>
                            <div className="deliver-button-col-content">
                                <Input
                                    placeholder={services.language.getText('inputPlease')}
                                    onChange={this.onInputExpressNumber}
                                />
                            </div>
                        </div>
                    )}
                    {!loadingExpress && deliveryMode === DeliveryModeEnum.SELF_MENTION && (
                        <div className="deliver-button-row">
                            <div className="deliver-button-col-title">
                                {services.language.getText('qzy')}:
                            </div>
                            <div className="deliver-button-col-content text-red">
                                {services.language.getText('qqrgddydbwb')}
                            </div>
                        </div>
                    )}
                </Modal>
            </div>
        );
    }
}
