import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { find } from 'lodash';
import { OrganizationType } from '../containers/organization/common';
const api = services.api;
export const OrganizationLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiPath = '/organizations';
        const { id } = data;
        if (!id) {
            // data.order ='ASCEND';
            // data.field = 'sequence'
            data.extendAttributes = 'status:营业,筹备,开店,闭店,null';
            return api.get(data, config).then((res: any) => {
                res.result.forEach((item: any) => {
                    const organizationTypeObj = find(item.extendAttributes, {
                        attributeCode: 'organization_type',
                    });
                    const organizationType = organizationTypeObj
                        ? organizationTypeObj.attributeValue
                        : '';
                    const prefix =
                        organizationType === OrganizationType.SUBSITE
                            ? '门店-'
                            : organizationType === OrganizationType.MERCHANT
                            ? '专柜-'
                            : '';
                    item.originName = item.name;
                    item.name = prefix + item.name;
                    let organizationTypeStr = '自定义组织';
                    switch (organizationType) {
                        case OrganizationType.TENANT:
                            organizationTypeStr = '系统-总部';
                            // item.name = '总部';
                            break;
                        case OrganizationType.SUBSITE:
                            organizationTypeStr = '系统-门店';
                            break;
                        case OrganizationType.MERCHANT:
                            organizationTypeStr = '系统-专柜';
                            break;
                        default:
                            break;
                    }
                    item.organizationType = organizationTypeStr;

                    const statusObj = find(item.extendAttributes, {
                        attributeCode: 'status',
                    });
                    const status = statusObj ? statusObj.attributeValue : '';

                    item.status = status;
                    item.canAddOrganization = [
                        OrganizationType.TENANT,
                        OrganizationType.SUBSITE,
                        OrganizationType.MERCHANT_CUSTOM,
                    ].includes(organizationType);
                    item.canDelete =
                        (item.children && item.children.length) ||
                        organizationType === OrganizationType.TENANT ||
                        organizationType === OrganizationType.SUBSITE ||
                        organizationType === OrganizationType.MERCHANT
                            ? false
                            : true;
                    item.canEdit =
                        organizationType !== OrganizationType.TENANT &&
                        organizationType !== OrganizationType.SUBSITE &&
                        organizationType !== OrganizationType.MERCHANT;
                    item.nameStr = item.name;
                });
                return res;
            });
        } else {
            config.apiPath = `/organizations/${id}`;
            return await api.get({}, config);
        }
    },
    delete: async function(data: any, config: any) {
        const { id } = data;
        config.apiPath = `/organizations/${id}`;
        return services.api.delete(data, config);
    },
};
