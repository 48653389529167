import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';
import get from 'lodash/get';
import { forEach } from 'lodash';
import { InvoiceModeEnum } from '../../../types/mode/string/invoice-mode/invoice-mode';
import { extraElement } from '../../../services/extra-element';
import { TipsModalContentType } from '../../../components';

const { getText } = services.language;

export const config: Config = {
    entities: {
        NormalInvoiceTypeEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
            apiPath: '/loader/admin/normal_invoice_type',
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<components.PageDesign.pageSetting>>',
                    properties: {
                        id: {
                            type: 'number.integer',
                        },
                        contents: {
                            type: 'array.optionIds.checkbox',
                            displayName: '<<zckpnr>>',
                            defaultValue: [],
                            options: [
                                {
                                    name: '<<productDetail>>',
                                    id: 'PRODUCT_DETAIL',
                                },
                                {
                                    name: '<<productT>>',
                                    id: 'PRODUCT_CATEGORY',
                                },
                            ],
                            rules: [
                                {
                                    required: true,
                                    message: '<<qxzzckpnr>>',
                                },
                            ],
                        },
                        authInvoicing: {
                            type: 'string.options.radio',
                            displayName: '<<mrzdkfp>>',
                            defaultValue: 'true',
                            options: [
                                { id: 'true', name: '<<yes>>' },
                                { id: 'false', name: '<<no>>' },
                            ],
                            rules: [
                                {
                                    required: true,
                                    message: '<<qxzmrzdkfp>>',
                                },
                            ],
                        },
                    },
                },
            },
        },
        NormalInvoiceConfigsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
            apiPath: '/loader/admin/normal_invoice_configs',
            filters: {
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 300 },
                    },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'true', name: '<<yxkp>>' },
                        { id: 'false', name: '<<jzkp>>' },
                    ],
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 200 },
                    },
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseInfo>>',
                    properties: {
                        subsite: {
                            type: 'options.select.object',
                            displayName: '<<kkpmd>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [
                                {
                                    required: true,
                                    message: '<<qxzkkpmd>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 300,
                                },
                                placeholder: '<<selectPlease>>',
                                allowClear: true,
                                showSearch: true,
                            },
                        },
                        subsiteInvoiceInfo: {
                            type: 'object.invoiceInfo.selector.bySubsite',
                            displayName: '<<xzzjlx>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<qxzkkpzj>>',
                                },
                                {
                                    message: '<<qzsxzyzzjlx>>',
                                    validator: (rule: any, value: any) => {
                                        if (
                                            value &&
                                            (!value.merchantTypes ||
                                                value.merchantTypes.length === 0)
                                        ) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                                {
                                    message: '<<pleaseSelectMerchant>>',
                                    validator: (rule: any, value: any) => {
                                        if (
                                            value &&
                                            value.merchantScope &&
                                            value.merchantScope !== 'ALL' &&
                                            value.merchantTypes &&
                                            value.merchantTypes.length > 0 &&
                                            (!value.merchants || value.merchants.length === 0)
                                        ) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                        },
                        invoiceMode: {
                            type: 'string.invoice.mode',
                            displayName: '<<kkfpzl>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<qxzkkfpzl>>',
                                },
                            ],
                        },
                    },
                },
                sellerInfo: {
                    type: 'object.subForm',
                    displayName: '<<kpztsz>>',
                    properties: {
                        sellerName: {
                            type: 'string',
                            displayName: '<<kpzt>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrkpzt>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<kpztqc>>',
                                maxLength: 100,
                            },
                        },
                        sellerCode: {
                            type: 'string',
                            displayName: '<<kpzt>>code',
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<h_kpzt_placeholder>>',
                                maxLength: 30,
                            },
                        },
                        sellerTaxNo: {
                            type: 'string',
                            displayName: '<<nsrsbh>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrnsrsbh>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<sh_1>>',
                                maxLength: 25,
                            },
                        },
                        sellerAddress: {
                            type: 'string',
                            displayName: '<<dz_1>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrdz>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<h_xfyyzzdz>>',
                                maxLength: 100,
                            },
                        },
                        sellerPhone: {
                            type: 'string.phone.input',
                            displayName: '<<h_phone>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrdh>>',
                                },
                            ],
                        },
                        sellerBank: {
                            type: 'string',
                            displayName: '<<khx>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrkhx>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<yxqcykhzx>>',
                                maxLength: 100,
                            },
                        },
                        sellerBankAccount: {
                            type: 'string',
                            displayName: '<<khxzh>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrkhxzh>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<kpztdkhyxzh>>',
                                maxLength: 100,
                            },
                        },
                        sellerPayee: {
                            type: 'string',
                            displayName: '<<skr>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrskr>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<skrxm>>',
                                maxLength: 100,
                            },
                        },
                        sellerReviewer: {
                            type: 'string',
                            displayName: '<<fhr>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrfhr>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<fhrxm>>',
                                maxLength: 100,
                            },
                        },
                        sellerDrawer: {
                            type: 'string',
                            displayName: '<<sellerDrawer>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<pleaseSellerDrawer>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<sellerDrawer>>',
                                maxLength: 100,
                            },
                        },
                    },
                },
                invoiceSystemInfo: {
                    type: 'object.subForm',
                    displayName: '<<swxtsz>>',
                    properties: {
                        invoiceSystem: {
                            type: 'object.invoice.system',
                            displayName: '<<xzswxt>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },
                    },
                },
                otherInfo: {
                    type: 'object.subForm',
                    displayName: '<<ywsz>>',
                    properties: {
                        isEffectiveDaysLimit: {
                            type: 'string.options.radio',
                            displayName: '<<xzkpyxq>>',
                            defaultValue: 'false',
                            options: [
                                { id: 'true', name: '<<yes>>' },
                                { id: 'false', name: '<<no>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        effectiveDaysLimit: {
                            type: 'number.tip',
                            displayName: '<<ts>>',
                            rules: [
                                {
                                    type: 'number',
                                    message: '<<qsrxzkpyxqts>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && (!reg.test(value) || value > 9999)) {
                                            return false;
                                        }
                                        if (!value || value === 0) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '',
                                style: {
                                    width: 125,
                                },
                                addonbefore: '<<ddqsh>>',
                                addonafter: '<<tnyxyhzmjdsqdzfp>>',
                                min: 1,
                                max: 9999,
                                tip: '',
                            },
                        },
                        isChangeCountLimit: {
                            type: 'string.options.radio',
                            displayName: '<<yxhkfp>>',
                            defaultValue: 'true',
                            options: [
                                { id: 'true', name: '<<yes>>' },
                                { id: 'false', name: '<<no>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        changeCountLimit: {
                            type: 'number.tip',
                            displayName: '<<cs_1>>',
                            displayConfig: { addonafter: '' },
                            rules: [
                                {
                                    type: 'number',
                                    message: '<<qsryxhkcs>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && (!reg.test(value) || value > 10)) {
                                            return false;
                                        }
                                        if (!value || value === 0) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                addonafter: '',
                                placeholder: '<<zdkhk>>',
                                style: { width: '150px' },
                                min: 1,
                                max: 10,
                            },
                            extra: '<<ykfpkysqhkdcs>>',
                        },
                        amountTopLimit: {
                            type: 'number.tip',
                            displayName: '<<dzpjjesx>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrdzpjjesx>>',
                                    validator: (rule: any, value: any) => {
                                        if (!value || value === 0 || value > 99999.99) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<h_zdksz>>99999.99',
                                style: {
                                    width: 180,
                                },
                                addonbefore: '',
                                addonafter: '',
                                precision: 2,
                                min: 0.01,
                                max: 99999.99,
                            },
                            extra: '<<h_dzpjjesx_tip>>',
                        },
                    },
                },
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                invoiceMode: {
                    type: 'string.options.select',
                    displayName: '<<kpzl>>',
                    options: [
                        { id: 'TAX_CONTROL', name: '<<skfp>>' },
                        { id: 'DIGITAL_ELECTRONIC', name: '<<sdp>>' },
                    ],
                },
                name: {
                    type: 'string',
                    displayName: '<<kpzt>>',
                },
                taxNo: {
                    type: 'string',
                    displayName: '<<nsrsbh>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'true', name: '<<yxkp>>' },
                        { id: 'false', name: '<<jzkp>>' },
                    ],
                },
            },
        },
        ParkingInvoiceConfigsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
            apiPath: '/loader/admin/parking_invoice_configs',
            filters: {
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 300 },
                    },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'true', name: '<<yxkp>>' },
                        { id: 'false', name: '<<jzkp>>' },
                    ],
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 200 },
                    },
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseInfo>>',
                    properties: {
                        subsiteId: {
                            type: 'string.options.select',
                            displayName: '<<kkpmd>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [
                                {
                                    required: true,
                                    message: '<<qxzkkpmd>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 300,
                                },
                                placeholder: '<<selectPlease>>',
                                allowClear: true,
                                showSearch: true,
                            },
                        },
                        invoiceMode: {
                            type: 'string.invoice.mode',
                            displayName: '<<kkfpzl>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<qxzkkfpzl>>',
                                },
                            ],
                        },
                        taxCode: {
                            type: 'string',
                            displayName: '<<tcssflbh>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrtcssflbh>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<gztcwssflbm>>',
                            },
                        },
                        taxRate: {
                            type: 'number.taxRate',
                            displayName: '<<tcjfsl>>',
                            rules: [
                                {
                                    required: true,
                                    validator: (rule: any, value: any) => {
                                        var reg = /(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/;
                                        if (value && !reg.test(value)) {
                                            return false;
                                        } else {
                                            return true;
                                        }
                                    },
                                    message: '<<qsrtcjfsl>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<h_tcjfsl_placeholder>>',
                                suffix: '%',
                                min: 0,
                                max: 100,
                            },
                        },
                        taxPreferentialPolicies: {
                            type: 'string.options.radio',
                            displayName: '<<taxPreferentialPolicies>>',
                            defaultValue: 'false',
                            options: [
                                { id: 'true', name: '<<yes>>' },
                                { id: 'false', name: '<<no>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        taxPreferentialPoliciesType: {
                            type: 'string.options.select',
                            displayName: '<<preferentialPoliciesType>>',
                            defaultValue: 'SIMPLE_TAX',
                            options: [
                                { id: 'SIMPLE_TAX', name: '<<simpleLevy>>' },
                                { id: 'SIMPLE_TAX_3_PERCENT', name: '<<simpleLevy3>>' },
                                { id: 'SIMPLE_TAX_5_PERCENT', name: '<<simpleLevy5>>' },
                                {
                                    id: 'SIMPLE_TAX_5_PERCENT_REDUCED_TO_1_5_PERCENT',
                                    name: '<<simpleLevy1Drop5>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 230,
                                },
                            },
                            rules: [{ required: true }],
                        },
                    },
                },
                sellerInfo: {
                    type: 'object.subForm',
                    displayName: '<<kpztsz>>',
                    properties: {
                        sellerName: {
                            type: 'string',
                            displayName: '<<kpzt>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrkpzt>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<mdtcskdztmc>>',
                                maxLength: 100,
                            },
                        },
                        sellerTaxNo: {
                            type: 'string',
                            displayName: '<<nsrsbh>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrnsrsbh>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<sh_1>>',
                                maxLength: 100,
                            },
                        },
                        sellerAddress: {
                            type: 'string',
                            displayName: '<<dz_1>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrdz>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<h_xfyyzzdz>>',
                                maxLength: 100,
                            },
                        },
                        sellerPhone: {
                            type: 'string.phone.input',
                            displayName: '<<h_phone>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrdh>>',
                                },
                            ],
                        },
                        sellerBank: {
                            type: 'string',
                            displayName: '<<khx>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrkhx>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<yxqcykhzx>>',
                                maxLength: 100,
                            },
                        },
                        sellerBankAccount: {
                            type: 'string',
                            displayName: '<<khxzh>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrkhxzh>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<kpztdkhyxzh>>',
                                maxLength: 100,
                            },
                        },
                        sellerPayee: {
                            type: 'string',
                            displayName: '<<skr>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrskr>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<skrxm>>',
                                maxLength: 100,
                            },
                        },
                        sellerReviewer: {
                            type: 'string',
                            displayName: '<<fhr>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrfhr>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<fhrxm>>',
                                maxLength: 100,
                            },
                        },
                        sellerDrawer: {
                            type: 'string',
                            displayName: '<<sellerDrawer>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<pleaseSellerDrawer>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<sellerDrawer>>',
                                maxLength: 100,
                            },
                        },
                        remark: {
                            type: 'string.text.paragraph',
                            displayName: '<<remark>>',
                            defaultValue: '订单号：{orderNo}',
                            rules: [{ max: 200 }],
                            controlConfig: {
                                rows: 4,
                                maxLength: 200,
                            },
                            extra: extraElement({
                                tips: ['<<invoiceViewVariable>>  {modal}'],
                                tipsModal: {
                                    contentType: TipsModalContentType.ViewVariable,
                                    showIcon: false,
                                    text: '<<ckbl>>',
                                    textColor: '#1890ff',
                                    title: '<<kybl>>',
                                    width: 700,
                                },
                            }),
                        },
                        regionAndAddress: {
                            type: 'object.select.city.address',
                            displayName: '<<ccjtdz>>',
                            controlConfig: {
                                style: { width: 450 },
                                placeholder: '<<selectPlease>>',
                            },
                            extra: '<<h_ccjtdz_tip>>',
                        },
                        realPropertyCertificate: {
                            type: 'string',
                            displayName: '<<h_cqzs_bdcqzh>>',
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<inputPlease>>',
                                maxLength: 40,
                            },
                            extra: '<<sdpkxzxsr>>',
                        },
                        crossCityFlag: {
                            type: 'string.options.select',
                            displayName: '<<h_kdsbz>>',
                            defaultValue: 'false',
                            options: [
                                { id: 'false', name: '<<no>>' },
                                { id: 'true', name: '<<yes>>' },
                            ],
                            controlConfig: {
                                style: {
                                    width: 100,
                                },
                            },
                            extra: '<<h_kdsbz_tip>>',
                        },
                    },
                },
                invoiceSystemInfo: {
                    type: 'object.subForm',
                    displayName: '<<swxtsz>>',
                    properties: {
                        invoiceSystem: {
                            type: 'object.invoice.system',
                            displayName: '<<xzswxt>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },
                    },
                },
                otherInfo: {
                    type: 'object.subForm',
                    displayName: '<<ywsz>>',
                    properties: {
                        isEffectiveDaysLimit: {
                            type: 'string.options.radio',
                            displayName: '<<xzkpyxq>>',
                            defaultValue: 'false',
                            options: [
                                { id: 'true', name: '<<yes>>' },
                                { id: 'false', name: '<<no>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        effectiveDaysLimit: {
                            type: 'number.tip',
                            displayName: '<<ts>>',
                            rules: [
                                {
                                    type: 'number',
                                    message: '<<h_effective_days_limit_msg>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && (!reg.test(value) || value > 9999)) {
                                            return false;
                                        }
                                        if (!value || value === 0) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '',
                                style: {
                                    width: 125,
                                },
                                addonbefore: '<<jfh>>',
                                addonafter: '<<tnyxyhzmjdsqfp>>',
                                min: 1,
                                max: 9999,
                                tip: '',
                            },
                        },
                        isChangeCountLimit: {
                            type: 'string.options.radio',
                            displayName: '<<yxhkfp>>',
                            defaultValue: 'true',
                            options: [
                                { id: 'true', name: '<<yes>>' },
                                { id: 'false', name: '<<no>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        changeCountLimit: {
                            type: 'number.tip',
                            displayName: '<<cs_1>>',
                            displayConfig: { addonafter: '' },
                            rules: [
                                {
                                    type: 'number',
                                    message: '<<h_change_count_limit_msg>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && (!reg.test(value) || value > 10)) {
                                            return false;
                                        }
                                        if (!value || value === 0) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                addonafter: '',
                                placeholder: '<<h_change_count_limit_placeholder>>',
                                style: { width: '150px' },
                                min: 1,
                                max: 10,
                            },
                            extra: '<<ykfpkysqhkdcs>>',
                        },
                        amountTopLimit: {
                            type: 'number.tip',
                            displayName: '<<dzpjjesx>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<h_dzpjjesx_msg>>',
                                    validator: (rule: any, value: any) => {
                                        if (!value || value === 0 || value > 99999.99) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<h_zdksz>>99999.99',
                                style: {
                                    width: 180,
                                },
                                addonbefore: '',
                                addonafter: '',
                                precision: 2,
                                min: 0.01,
                                max: 99999.99,
                            },
                            extra: '<<h_dzpjjesx_tip>>',
                        },
                    },
                },
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                invoiceMode: {
                    type: 'string.options.select',
                    displayName: '<<kpzl>>',
                    options: [
                        { id: 'TAX_CONTROL', name: '<<skfp>>' },
                        { id: 'DIGITAL_ELECTRONIC', name: '<<sdp>>' },
                    ],
                },
                name: {
                    type: 'string',
                    displayName: '<<kpzt>>',
                },
                taxNo: {
                    type: 'string',
                    displayName: '<<nsrsbh>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'true', name: '<<yxkp>>' },
                        { id: 'false', name: '<<jzkp>>' },
                    ],
                },
            },
        },
        StoredValueCardInvoiceConfigsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
            apiPath: '/loader/admin/stored_value_card_invoice_configs',
            filters: {
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 300 },
                    },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'true', name: '<<yxkp>>' },
                        { id: 'false', name: '<<jzkp>>' },
                    ],
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 200 },
                    },
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseInfo>>',
                    properties: {
                        subsiteId: {
                            type: 'string.options.select',
                            displayName: '<<kkpmd>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [
                                {
                                    required: true,
                                    message: '<<qxzkkpmd>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 300,
                                },
                                placeholder: '<<selectPlease>>',
                                allowClear: true,
                                showSearch: true,
                            },
                        },
                        authInvoicing: {
                            type: 'string.options.radio',
                            displayName: '<<mrzdkfp>>',
                            defaultValue: 'true',
                            options: [
                                { id: 'true', name: '<<yes>>' },
                                { id: 'false', name: '<<no>>' },
                            ],
                            rules: [
                                {
                                    required: true,
                                    message: '<<qxzmrzdkfp>>',
                                },
                            ],
                        },
                        invoiceMode: {
                            type: 'string.invoice.mode',
                            displayName: '<<kkfpzl>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<qxzkkfpzl>>',
                                },
                            ],
                        },
                        taxCode: {
                            type: 'string',
                            displayName: '<<yfkssflbm>>',
                            defaultValue: '6010000000000000000',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsryfkssflbm>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<gztcwssflbm>>',
                            },
                        },
                        taxRateType: {
                            type: 'string.options.select',
                            displayName: '<<taxRateType>>',
                            defaultValue: 'NO_TAX',
                            options: [{ id: 'NO_TAX', name: '<<nonTaxtion>>' }],
                            controlConfig: {
                                style: {
                                    width: 300,
                                },
                                disabled: true,
                            },
                        },
                    },
                },
                sellerInfo: {
                    type: 'object.subForm',
                    displayName: '<<kpztsz>>',
                    properties: {
                        sellerName: {
                            type: 'string',
                            displayName: '<<kpzt>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrkpzt>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<mdtcskdztmc>>',
                                maxLength: 100,
                            },
                        },
                        sellerTaxNo: {
                            type: 'string',
                            displayName: '<<nsrsbh>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrnsrsbh>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<sh_1>>',
                                maxLength: 100,
                            },
                        },
                        sellerAddress: {
                            type: 'string',
                            displayName: '<<dz_1>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrdz>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<h_xfyyzzdz>>',
                                maxLength: 100,
                            },
                        },
                        sellerPhone: {
                            type: 'string.phone.input',
                            displayName: '<<h_phone>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrdh>>',
                                },
                            ],
                        },
                        sellerBank: {
                            type: 'string',
                            displayName: '<<khx>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrkhx>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<yxqcykhzx>>',
                                maxLength: 100,
                            },
                        },
                        sellerBankAccount: {
                            type: 'string',
                            displayName: '<<khxzh>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrkhxzh>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<kpztdkhyxzh>>',
                                maxLength: 100,
                            },
                        },
                        sellerPayee: {
                            type: 'string',
                            displayName: '<<skr>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrskr>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<skrxm>>',
                                maxLength: 100,
                            },
                        },
                        sellerReviewer: {
                            type: 'string',
                            displayName: '<<fhr>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrfhr>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<fhrxm>>',
                                maxLength: 100,
                            },
                        },
                        sellerDrawer: {
                            type: 'string',
                            displayName: '<<sellerDrawer>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<pleaseSellerDrawer>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<sellerDrawer>>',
                                maxLength: 100,
                            },
                        },
                    },
                },
                invoiceSystemInfo: {
                    type: 'object.subForm',
                    displayName: '<<swxtsz>>',
                    properties: {
                        invoiceSystem: {
                            type: 'object.invoice.system',
                            displayName: '<<xzswxt>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },
                    },
                },
                otherInfo: {
                    type: 'object.subForm',
                    displayName: '<<ywsz>>',
                    properties: {
                        isEffectiveDaysLimit: {
                            type: 'string.options.radio',
                            displayName: '<<xzkpyxq>>',
                            defaultValue: 'false',
                            options: [
                                { id: 'true', name: '<<yes>>' },
                                { id: 'false', name: '<<no>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        effectiveDaysLimit: {
                            type: 'number.tip',
                            displayName: '<<ts>>',
                            rules: [
                                {
                                    type: 'number',
                                    message: '<<h_effective_days_limit_msg>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && (!reg.test(value) || value > 9999)) {
                                            return false;
                                        }
                                        if (!value || value === 0) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '',
                                style: {
                                    width: 125,
                                },
                                addonbefore: '<<jfh>>',
                                addonafter: '<<tnyxyhzmjdsqfp>>',
                                min: 1,
                                max: 9999,
                                tip: '',
                            },
                        },
                        isChangeCountLimit: {
                            type: 'string.options.radio',
                            displayName: '<<yxhkfp>>',
                            defaultValue: 'true',
                            options: [
                                { id: 'true', name: '<<yes>>' },
                                { id: 'false', name: '<<no>>' },
                            ],
                            rules: [{ required: true }],
                        },
                        changeCountLimit: {
                            type: 'number.tip',
                            displayName: '<<cs_1>>',
                            displayConfig: { addonafter: '' },
                            rules: [
                                {
                                    type: 'number',
                                    message: '<<h_change_count_limit_msg>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^\+?[1-9]\d*$/;
                                        if (value && (!reg.test(value) || value > 10)) {
                                            return false;
                                        }
                                        if (!value || value === 0) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                addonafter: '',
                                placeholder: '<<zdkhk>>',
                                style: { width: '150px' },
                                min: 1,
                                max: 10,
                            },
                            extra: '<<ykfpkysqhkdcs>>',
                        },
                        amountTopLimit: {
                            type: 'number.tip',
                            displayName: '<<dzpjjesx>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrdzpjjesx>>',
                                    validator: (rule: any, value: any) => {
                                        if (!value || value === 0 || value > 99999.99) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                placeholder: '<<h_zdksz>>99999.99',
                                style: {
                                    width: 180,
                                },
                                addonbefore: '',
                                addonafter: '',
                                precision: 2,
                                min: 0.01,
                                max: 99999.99,
                            },
                            extra: '<<h_dzpjjesx_tip>>',
                        },
                    },
                },
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                invoiceMode: {
                    type: 'string.options.select',
                    displayName: '<<kpzl>>',
                    options: [
                        { id: 'TAX_CONTROL', name: '<<skfp>>' },
                        { id: 'DIGITAL_ELECTRONIC', name: '<<sdp>>' },
                    ],
                },
                name: {
                    type: 'string',
                    displayName: '<<kpzt>>',
                },
                taxNo: {
                    type: 'string',
                    displayName: '<<nsrsbh>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'true', name: '<<yxkp>>' },
                        { id: 'false', name: '<<jzkp>>' },
                    ],
                },
            },
        },
        MagicShopMerchants: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
            apiPath: '/loader/admin/merchants',
            properties: {
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                merchantCodeQuantity: {
                    type: 'string',
                    displayName: '<<sjmsl>>',
                },
            },
        },
    },
    components: {
        InvoiceConfigsView: {
            component: 'Viewport',
        },
        InvoiceConfigsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'tabs-content-viewport',
            items: [{ component: 'ParkingInvoiceConfigsPageTables' }],
        },
        ParkingInvoiceConfigsPageTables: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<szswspfp>>',
                    content: {
                        component: 'NormalInvoiceConfigsPage',
                    },
                },
                {
                    title: '<<sztcjffp>>',
                    content: {
                        component: 'ParkingInvoiceConfigsPage',
                    },
                },
                {
                    title: '<<szczkfp>>',
                    content: {
                        component: 'StoredValueCardInvoiceConfigsPage',
                    },
                },
            ],
        },
        NormalInvoiceConfigsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'NormalInvoiceConfigsEntity',
            items: [
                {
                    component: 'NormalInvoiceConfigsHeader',
                },
                {
                    component: 'NormalInvoiceConfigsFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    style: {
                        paddingTop: 10,
                        paddingBottom: 10,
                    },
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzmdztpz>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/invoice-config/add-normal',
                                },
                            ],
                        },
                    ],
                },
                { component: 'NormalInvoiceConfigsTable' },
            ],
        },
        NormalInvoiceConfigsFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteIds',
                },
                {
                    property: 'status',
                },
            ],
        },
        NormalInvoiceConfigsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'subsiteName',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'invoiceMode',
                },
                {
                    property: 'name',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'taxNo',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'status',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 220,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
                                    apiPath: '/admin/invoice_configs/:id/disabled',
                                    config: {
                                        content: '<<jzkp>>',
                                        text: '<<jzkp>>',
                                    },
                                    confirm: {
                                        text: '<<sfjzkp>>?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
                                    apiPath: '/admin/invoice_configs/:id/enabled',
                                    config: {
                                        content: '<<yxkp>>',
                                        text: '<<yxkp>>',
                                    },
                                    confirm: {
                                        text: '<<sfyxkp>>?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/invoice-config/edit-normal/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<fzpz>>',
                        path: '/invoice-config/copy-normal/{{row.id}}',
                    },
                ],
            },
        },
        NormalInvoiceConfigsAddPage: {
            component: 'Card',
            content: { component: 'NormalInvoiceConfigsAddForm' },
        },
        NormalInvoiceConfigsAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'NormalInvoiceConfigsEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.subsite' },
                {
                    property: 'baseInfo.subsiteInvoiceInfo',
                    visible: (values: any) => {
                        const subsite = get(values, 'baseInfo.subsite');
                        if (subsite && subsite.format === 'STORE') {
                            return true;
                        }
                        return false;
                    },
                },
                { property: 'baseInfo.invoiceMode' },
                { property: 'sellerInfo.sellerName' },
                { property: 'sellerInfo.sellerCode' },
                { property: 'sellerInfo.sellerTaxNo' },
                { property: 'sellerInfo.sellerAddress' },
                { property: 'sellerInfo.sellerPhone' },
                { property: 'sellerInfo.sellerBank' },
                { property: 'sellerInfo.sellerBankAccount' },
                {
                    property: 'sellerInfo.sellerPayee',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (invoiceMode && invoiceMode === InvoiceModeEnum.TAX_CONTROL) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerReviewer',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (invoiceMode && invoiceMode === InvoiceModeEnum.TAX_CONTROL) {
                            return true;
                        }
                        return false;
                    },
                },
                { property: 'sellerInfo.sellerDrawer' },
                { property: 'invoiceSystemInfo.invoiceSystem' },
                { property: 'otherInfo.isEffectiveDaysLimit' },
                {
                    property: 'otherInfo.effectiveDaysLimit',
                    visible: (values: any) =>
                        get(values, 'otherInfo.isEffectiveDaysLimit') === 'true',
                },
                { property: 'otherInfo.isChangeCountLimit' },
                {
                    property: 'otherInfo.changeCountLimit',
                    visible: (values: any) =>
                        get(values, 'otherInfo.isChangeCountLimit') === 'true',
                },
                {
                    property: 'otherInfo.amountTopLimit',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (invoiceMode && invoiceMode === InvoiceModeEnum.TAX_CONTROL) {
                            return true;
                        }
                        return false;
                    },
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const sellerPhone = entity.baseInfo.sellerPhone;
                if (sellerPhone) {
                    const sellerPhoneArray = sellerPhone.split('-');
                    if (
                        sellerPhoneArray.length === 2 &&
                        (sellerPhoneArray[0] === '' || sellerPhoneArray[1] === '')
                    ) {
                        AntMessage.warn(getText('qsrwzdh'));
                        return false;
                    }
                }
                if (
                    entity.invoiceSystemInfo &&
                    entity.invoiceSystemInfo.invoiceSystem &&
                    entity.invoiceSystemInfo.invoiceSystem.id
                ) {
                    const invoiceSystem = entity.invoiceSystemInfo.invoiceSystem;
                    if (invoiceSystem.params) {
                        let hasValidate = true;
                        if (invoiceSystem.params) {
                            forEach(invoiceSystem.params, (param) => {
                                param.value = param.value.replace(/\s*/g, '');
                                if (param.required && (!param.value || param.value === null)) {
                                    AntMessage.warning(`${getText('selectFillin')}${param.name}`);
                                    hasValidate = false;
                                    return false;
                                }
                            });
                        }
                        if (!hasValidate) {
                            return false;
                        }
                    }
                } else {
                    AntMessage.warning(getText('qxzswxt'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        NormalInvoiceConfigsEditPage: {
            component: 'FlexLayout',
            entity: 'NormalInvoiceConfigsEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'NormalInvoiceConfigsEditFormView' }],
        },
        NormalInvoiceConfigsCopyPage: {
            component: 'FlexLayout',
            entity: 'NormalInvoiceConfigsEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'NormalInvoiceConfigsEditFormView', type: 'copy' }],
        },
        NormalInvoiceTypeEditPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'NormalInvoiceTypeEditFormView' }],
        },
        NormalInvoiceTypeEditFormView: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'NormalInvoiceTypeEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            style: {
                height: '480px',
            },
            fields: [
                {
                    property: 'baseInfo.id',
                    visible: () => false,
                },
                {
                    property: 'baseInfo.contents',
                },
                {
                    property: 'baseInfo.authInvoicing',
                },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'submit',
                        text: '<<common.save>>',
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () => {
                    window.location.href = '/#/invoice-config';
                });
            },
        },
        NormalInvoiceTaxrateEditPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'NormalInvoiceTaxrateEditPageTables' }],
        },
        NormalInvoiceTaxrateEditPageTables: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<abzflpz>>',
                    content: {
                        component: 'NormalInvoiceTaxrateCategoryEditForm',
                    },
                },
                {
                    title: '<<asppz>>',
                    content: {
                        component: 'NormalInvoiceTaxrateProductEditForm',
                    },
                },
                {
                    title: '<<asjmpz>>',
                    content: {
                        component: 'NormalInvoiceTaxrateMerchantCodeConfigView',
                    },
                },
            ],
        },
        ParkingInvoiceConfigsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'ParkingInvoiceConfigsEntity',
            items: [
                {
                    component: 'ParkingInvoiceConfigsFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzmdpztcfp>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/invoice-config/add-parking',
                                },
                            ],
                        },
                    ],
                },
                { component: 'ParkingInvoiceConfigsTable' },
            ],
        },
        ParkingInvoiceConfigsFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteIds',
                },
                {
                    property: 'status',
                },
            ],
        },
        ParkingInvoiceConfigsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'subsiteName',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'invoiceMode',
                },
                {
                    property: 'name',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'taxNo',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'status',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 220,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
                                    apiPath: '/admin/invoice_configs/:id/disabled',
                                    config: {
                                        content: '<<jzkp>>',
                                        text: '<<jzkp>>',
                                    },
                                    confirm: {
                                        text: '<<sfjzkp>>?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
                                    apiPath: '/admin/invoice_configs/:id/enabled',
                                    config: {
                                        content: '<<yxkp>>',
                                        text: '<<yxkp>>',
                                    },
                                    confirm: {
                                        text: '<<sfyxkp>>?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/invoice-config/edit-parking/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<fzpz>>',
                        path: '/invoice-config/copy-parking/{{row.id}}',
                    },
                ],
            },
        },
        ParkingInvoiceConfigsAddPage: {
            component: 'Card',
            content: { component: 'ParkingInvoiceConfigsAddForm' },
        },
        ParkingInvoiceConfigsAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'ParkingInvoiceConfigsEntity',
            labelCol: 5,
            controlCol: 19,
            style: { width: 800 },
            fields: [
                { property: 'baseInfo.subsiteId' },
                { property: 'baseInfo.invoiceMode' },
                { property: 'baseInfo.taxCode' },
                { property: 'baseInfo.taxPreferentialPolicies' },
                {
                    property: 'baseInfo.taxPreferentialPoliciesType',
                    visible: (values: any) => {
                        const taxPreferentialPolicies = get(
                            values,
                            'baseInfo.taxPreferentialPolicies'
                        );
                        if (taxPreferentialPolicies && taxPreferentialPolicies === 'true') {
                            return true;
                        }
                        return false;
                    },
                },
                { property: 'baseInfo.taxRate' },
                { property: 'sellerInfo.sellerName' },
                { property: 'sellerInfo.sellerTaxNo' },
                { property: 'sellerInfo.sellerAddress' },
                { property: 'sellerInfo.sellerPhone' },
                { property: 'sellerInfo.sellerBank' },
                { property: 'sellerInfo.sellerBankAccount' },
                {
                    property: 'sellerInfo.sellerPayee',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (invoiceMode && invoiceMode === InvoiceModeEnum.TAX_CONTROL) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerReviewer',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (invoiceMode && invoiceMode === InvoiceModeEnum.TAX_CONTROL) {
                            return true;
                        }
                        return false;
                    },
                },
                { property: 'sellerInfo.sellerDrawer' },
                {
                    property: 'sellerInfo.remark',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (invoiceMode && invoiceMode === InvoiceModeEnum.TAX_CONTROL) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.regionAndAddress',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (invoiceMode && invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.realPropertyCertificate',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (invoiceMode && invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.crossCityFlag',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (invoiceMode && invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC) {
                            return true;
                        }
                        return false;
                    },
                },
                { property: 'invoiceSystemInfo.invoiceSystem' },
                { property: 'otherInfo.isEffectiveDaysLimit' },
                {
                    property: 'otherInfo.effectiveDaysLimit',
                    visible: (values: any) =>
                        get(values, 'otherInfo.isEffectiveDaysLimit') === 'true',
                },
                { property: 'otherInfo.isChangeCountLimit' },
                {
                    property: 'otherInfo.changeCountLimit',
                    visible: (values: any) =>
                        get(values, 'otherInfo.isChangeCountLimit') === 'true',
                },
                {
                    property: 'otherInfo.amountTopLimit',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (invoiceMode && invoiceMode === InvoiceModeEnum.TAX_CONTROL) {
                            return true;
                        }
                        return false;
                    },
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const sellerPhone = entity.baseInfo.sellerPhone;
                if (sellerPhone) {
                    const sellerPhoneArray = sellerPhone.split('-');
                    if (
                        sellerPhoneArray.length === 2 &&
                        (sellerPhoneArray[0] === '' || sellerPhoneArray[1] === '')
                    ) {
                        AntMessage.warn(getText('qsrwzdh'));
                        return false;
                    }
                }
                if (
                    entity.invoiceSystemInfo &&
                    entity.invoiceSystemInfo.invoiceSystem &&
                    entity.invoiceSystemInfo.invoiceSystem.id
                ) {
                    const invoiceSystem = entity.invoiceSystemInfo.invoiceSystem;
                    if (invoiceSystem.params) {
                        let hasValidate = true;
                        if (invoiceSystem.params) {
                            forEach(invoiceSystem.params, (param) => {
                                param.value = param.value.replace(/\s*/g, '');
                                if (param.required && (!param.value || param.value === null)) {
                                    AntMessage.warning(`${getText('selectFillin')}${param.name}`);
                                    hasValidate = false;
                                    return false;
                                }
                            });
                        }
                        if (!hasValidate) {
                            return false;
                        }
                    }
                } else {
                    AntMessage.warning(getText('qxzswxt'));
                    return false;
                }
                if (
                    entity.baseInfo.invoiceMode &&
                    entity.baseInfo.invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC
                ) {
                    const region = entity.sellerInfo.regionAndAddress
                        ? entity.sellerInfo.regionAndAddress.regionIds
                        : [];
                    const address = entity.sellerInfo.regionAndAddress
                        ? entity.sellerInfo.regionAndAddress.address
                        : '';
                    if (!region || region.length === 0 || !address || address.length > 80) {
                        AntMessage.warning(getText('h_parking_invoice_form_region_validate_msg'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        ParkingInvoiceConfigsEditPage: {
            component: 'FlexLayout',
            entity: 'ParkingInvoiceConfigsEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'ParkingInvoiceConfigsEditFormView' }],
        },
        ParkingInvoiceConfigsCopyPage: {
            component: 'FlexLayout',
            entity: 'ParkingInvoiceConfigsEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'ParkingInvoiceConfigsEditFormView', type: 'copy' }],
        },
        NormalInvoiceTaxrateMerchantCodeConfigView: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'MagicShopMerchants',
            items: [{ component: 'NormalInvoiceTaxrateMerchantCodeConfigTable' }],
        },
        NormalInvoiceTaxrateMerchantCodeConfigTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'subsiteName',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'name',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'merchantCodeQuantity',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 220,
                items: [
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/invoice-config/merchant-category-code-edit/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'MerchantCategoryCodeTaxRatesImportButton',
                    },
                ],
            },
        },
        MerchantCategoryCodeTaxRatesEditPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'MerchantCategoryCodeTaxRatesEditView' }],
        },
        StoredValueCardInvoiceConfigsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'StoredValueCardInvoiceConfigsEntity',
            items: [
                {
                    component: 'StoredValueCardInvoiceConfigsFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzmdpzczkfp>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/invoice-config/add-stored-value-card',
                                },
                            ],
                        },
                    ],
                },
                { component: 'StoredValueCardInvoiceConfigsTable' },
            ],
        },
        StoredValueCardInvoiceConfigsFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteIds',
                },
                {
                    property: 'status',
                },
            ],
        },
        StoredValueCardInvoiceConfigsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'subsiteName',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'invoiceMode',
                },
                {
                    property: 'name',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'taxNo',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'status',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 220,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
                                    apiPath: '/admin/invoice_configs/:id/disabled',
                                    config: {
                                        content: '<<jzkp>>',
                                        text: '<<jzkp>>',
                                    },
                                    confirm: {
                                        text: '<<sfjzkp>>?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
                                    apiPath: '/admin/invoice_configs/:id/enabled',
                                    config: {
                                        content: '<<yxkp>>',
                                        text: '<<yxkp>>',
                                    },
                                    confirm: {
                                        text: '<<sfyxkp>>?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/invoice-config/edit-stored-value-card/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<fzpz>>',
                        path: '/invoice-config/copy-stored-value-card/{{row.id}}',
                    },
                ],
            },
        },
        StoredValueCardInvoiceConfigsAddPage: {
            component: 'Card',
            content: { component: 'StoredValueCardInvoiceConfigsAddForm' },
        },
        StoredValueCardInvoiceConfigsAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'StoredValueCardInvoiceConfigsEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.subsiteId' },
                { property: 'baseInfo.authInvoicing' },
                { property: 'baseInfo.invoiceMode' },
                {
                    property: 'baseInfo.taxCode',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'baseInfo.taxRateType',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerName',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerTaxNo',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerAddress',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerPhone',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerBank',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerBankAccount',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerPayee',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (invoiceMode && invoiceMode === InvoiceModeEnum.TAX_CONTROL) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerReviewer',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (invoiceMode && invoiceMode === InvoiceModeEnum.TAX_CONTROL) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerDrawer',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                { property: 'invoiceSystemInfo.invoiceSystem' },
                { property: 'otherInfo.isEffectiveDaysLimit' },
                {
                    property: 'otherInfo.effectiveDaysLimit',
                    visible: (values: any) =>
                        get(values, 'otherInfo.isEffectiveDaysLimit') === 'true',
                },
                { property: 'otherInfo.isChangeCountLimit' },
                {
                    property: 'otherInfo.changeCountLimit',
                    visible: (values: any) =>
                        get(values, 'otherInfo.isChangeCountLimit') === 'true',
                },
                {
                    property: 'otherInfo.amountTopLimit',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (invoiceMode && invoiceMode === InvoiceModeEnum.TAX_CONTROL) {
                            return true;
                        }
                        return false;
                    },
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const sellerPhone = entity.baseInfo.sellerPhone;
                if (sellerPhone) {
                    const sellerPhoneArray = sellerPhone.split('-');
                    if (
                        sellerPhoneArray.length === 2 &&
                        (sellerPhoneArray[0] === '' || sellerPhoneArray[1] === '')
                    ) {
                        AntMessage.warn(getText('qsrwzdh'));
                        return false;
                    }
                }
                if (
                    entity.invoiceSystemInfo &&
                    entity.invoiceSystemInfo.invoiceSystem &&
                    entity.invoiceSystemInfo.invoiceSystem.id
                ) {
                    const invoiceSystem = entity.invoiceSystemInfo.invoiceSystem;
                    if (invoiceSystem.params) {
                        let hasValidate = true;
                        if (invoiceSystem.params) {
                            forEach(invoiceSystem.params, (param) => {
                                param.value = param.value.replace(/\s*/g, '');
                                if (param.required && (!param.value || param.value === null)) {
                                    AntMessage.warning(`${getText('selectFillin')}${param.name}`);
                                    hasValidate = false;
                                    return false;
                                }
                            });
                        }
                        if (!hasValidate) {
                            return false;
                        }
                    }
                } else {
                    AntMessage.warning(getText('qxzswxt'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        StoredValueCardInvoiceConfigsEditPage: {
            component: 'Card',
            content: { component: 'StoredValueCardInvoiceConfigsEditForm' },
        },
        StoredValueCardInvoiceConfigsEditForm: {
            component: 'ModifyFormPlus',
            loaderType: 'get',
            direction: 'horizontal',
            entity: 'StoredValueCardInvoiceConfigsEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.subsiteId' },
                { property: 'baseInfo.authInvoicing' },
                { property: 'baseInfo.invoiceMode' },
                {
                    property: 'baseInfo.taxCode',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'baseInfo.taxRateType',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerName',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerTaxNo',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerAddress',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerPhone',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerBank',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerBankAccount',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerPayee',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (invoiceMode && invoiceMode === InvoiceModeEnum.TAX_CONTROL) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerReviewer',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (invoiceMode && invoiceMode === InvoiceModeEnum.TAX_CONTROL) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerDrawer',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                { property: 'invoiceSystemInfo.invoiceSystem' },
                { property: 'otherInfo.isEffectiveDaysLimit' },
                {
                    property: 'otherInfo.effectiveDaysLimit',
                    visible: (values: any) =>
                        get(values, 'otherInfo.isEffectiveDaysLimit') === 'true',
                },
                { property: 'otherInfo.isChangeCountLimit' },
                {
                    property: 'otherInfo.changeCountLimit',
                    visible: (values: any) =>
                        get(values, 'otherInfo.isChangeCountLimit') === 'true',
                },
                {
                    property: 'otherInfo.amountTopLimit',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (invoiceMode && invoiceMode === InvoiceModeEnum.TAX_CONTROL) {
                            return true;
                        }
                        return false;
                    },
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const sellerPhone = entity.baseInfo.sellerPhone;
                if (sellerPhone) {
                    const sellerPhoneArray = sellerPhone.split('-');
                    if (
                        sellerPhoneArray.length === 2 &&
                        (sellerPhoneArray[0] === '' || sellerPhoneArray[1] === '')
                    ) {
                        AntMessage.warn(getText('qsrwzdh'));
                        return false;
                    }
                }
                if (
                    entity.invoiceSystemInfo &&
                    entity.invoiceSystemInfo.invoiceSystem &&
                    entity.invoiceSystemInfo.invoiceSystem.id
                ) {
                    const invoiceSystem = entity.invoiceSystemInfo.invoiceSystem;
                    if (invoiceSystem.params) {
                        let hasValidate = true;
                        if (invoiceSystem.params) {
                            forEach(invoiceSystem.params, (param) => {
                                param.value = param.value.replace(/\s*/g, '');
                                if (param.required && (!param.value || param.value === null)) {
                                    AntMessage.warning(`${getText('selectFillin')}${param.name}`);
                                    hasValidate = false;
                                    return false;
                                }
                            });
                        }
                        if (!hasValidate) {
                            return false;
                        }
                    }
                } else {
                    AntMessage.warning(getText('qxzswxt'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        StoredValueCardInvoiceConfigsCopyPage: {
            component: 'Card',
            content: { component: 'StoredValueCardInvoiceConfigsCopyForm' },
        },
        StoredValueCardInvoiceConfigsCopyForm: {
            component: 'CopyFormPlus',
            loaderType: 'get',
            direction: 'horizontal',
            entity: 'StoredValueCardInvoiceConfigsEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.subsiteId' },
                { property: 'baseInfo.authInvoicing' },
                { property: 'baseInfo.invoiceMode' },
                {
                    property: 'baseInfo.taxCode',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'baseInfo.taxRateType',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerName',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerTaxNo',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerAddress',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerPhone',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerBank',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerBankAccount',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerPayee',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (invoiceMode && invoiceMode === InvoiceModeEnum.TAX_CONTROL) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerReviewer',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (invoiceMode && invoiceMode === InvoiceModeEnum.TAX_CONTROL) {
                            return true;
                        }
                        return false;
                    },
                },
                {
                    property: 'sellerInfo.sellerDrawer',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (
                            invoiceMode &&
                            (invoiceMode === InvoiceModeEnum.TAX_CONTROL ||
                                invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC)
                        ) {
                            return true;
                        }
                        return false;
                    },
                },
                { property: 'invoiceSystemInfo.invoiceSystem' },
                { property: 'otherInfo.isEffectiveDaysLimit' },
                {
                    property: 'otherInfo.effectiveDaysLimit',
                    visible: (values: any) =>
                        get(values, 'otherInfo.isEffectiveDaysLimit') === 'true',
                },
                { property: 'otherInfo.isChangeCountLimit' },
                {
                    property: 'otherInfo.changeCountLimit',
                    visible: (values: any) =>
                        get(values, 'otherInfo.isChangeCountLimit') === 'true',
                },
                {
                    property: 'otherInfo.amountTopLimit',
                    visible: (values: any) => {
                        const invoiceMode = get(values, 'baseInfo.invoiceMode');
                        if (invoiceMode && invoiceMode === InvoiceModeEnum.TAX_CONTROL) {
                            return true;
                        }
                        return false;
                    },
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const sellerPhone = entity.baseInfo.sellerPhone;
                if (sellerPhone) {
                    const sellerPhoneArray = sellerPhone.split('-');
                    if (
                        sellerPhoneArray.length === 2 &&
                        (sellerPhoneArray[0] === '' || sellerPhoneArray[1] === '')
                    ) {
                        AntMessage.warn(getText('qsrwzdh'));
                        return false;
                    }
                }
                if (
                    entity.invoiceSystemInfo &&
                    entity.invoiceSystemInfo.invoiceSystem &&
                    entity.invoiceSystemInfo.invoiceSystem.id
                ) {
                    const invoiceSystem = entity.invoiceSystemInfo.invoiceSystem;
                    if (invoiceSystem.params) {
                        let hasValidate = true;
                        if (invoiceSystem.params) {
                            forEach(invoiceSystem.params, (param) => {
                                param.value = param.value.replace(/\s*/g, '');
                                if (param.required && (!param.value || param.value === null)) {
                                    AntMessage.warning(`${getText('selectFillin')}${param.name}`);
                                    hasValidate = false;
                                    return false;
                                }
                            });
                        }
                        if (!hasValidate) {
                            return false;
                        }
                    }
                } else {
                    AntMessage.warning(getText('qxzswxt'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/invoice-config',
                    component: 'InvoiceConfigsView',
                    breadcrumbName: '<<invoiceSetting>>',
                    privilege: {
                        path: 'invoiceConfig',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/normal-invoice-type',
                            component: 'NormalInvoiceTypeEditPage',
                            breadcrumbName: '<<fplxpz>>',
                        },
                        {
                            path: '/normal-invoice-taxrate',
                            component: 'NormalInvoiceTaxrateEditPage',
                            breadcrumbName: '<<slpz>>',
                        },
                        {
                            path: '/merchant-category-code-edit/:id',
                            component: 'MerchantCategoryCodeTaxRatesEditPage',
                            breadcrumbName: '<<slpz>>',
                        },
                        {
                            path: '/add-normal',
                            component: 'NormalInvoiceConfigsAddPage',
                            breadcrumbName: '<<xzmdztpz>>',
                        },
                        {
                            path: '/edit-normal/:id',
                            component: 'NormalInvoiceConfigsEditPage',
                            breadcrumbName: '<<bjmdztpz>>',
                        },
                        {
                            path: '/copy-normal/:id',
                            component: 'NormalInvoiceConfigsCopyPage',
                            breadcrumbName: '<<fzmdztpz>>',
                        },
                        {
                            path: '/add-parking',
                            component: 'ParkingInvoiceConfigsAddPage',
                            breadcrumbName: '<<xzmdpztcfp>>',
                        },
                        {
                            path: '/edit-parking/:id',
                            component: 'ParkingInvoiceConfigsEditPage',
                            breadcrumbName: '<<bjmdpztcfp>>',
                        },
                        {
                            path: '/copy-parking/:id',
                            component: 'ParkingInvoiceConfigsCopyPage',
                            breadcrumbName: '<<fzmdpztcfp>>',
                        },
                        {
                            path: '/add-stored-value-card',
                            component: 'StoredValueCardInvoiceConfigsAddPage',
                            breadcrumbName: '<<xzmdpzczkfp>>',
                        },
                        {
                            path: '/edit-stored-value-card/:id',
                            component: 'StoredValueCardInvoiceConfigsEditPage',
                            breadcrumbName: '<<bjmdpzczkfp>>',
                        },
                        {
                            path: '/copy-stored-value-card/:id',
                            component: 'StoredValueCardInvoiceConfigsCopyPage',
                            breadcrumbName: '<<fzmdpzczkfp>>',
                        },
                        { path: '/', component: 'InvoiceConfigsPage' },
                    ],
                },
            ],
        },
    ],
};
