import React, { PureComponent } from 'react';
import { Table, Button, Popconfirm, message } from 'antd';
import { cloneDeep, remove, forEach, filter, map } from 'lodash';
import { Shop } from '../../containers/select-shop';
import { services } from '@comall-backend-builder/core';
import './index.less';
import { ColumnProps } from 'antd/lib/table';
import { language } from '@comall-backend-builder/core/lib/services';

export enum ShopSelectedDisplayMode {
    /**
     * 普通（多选）
     */
    NORMAL = 'NORMAL',
    /**
     * 单选
     */
    SINGLE = 'SINGLE',
}

interface ShopSelectedDisplayProps<T> {
    data: Array<Shop>;
    onChange: (data: Array<Shop>) => void;
    onRemove: (data: Array<Shop>) => void;
    disabled?: boolean;
    columns?: ColumnProps<T>[];
    mode?: ShopSelectedDisplayMode;
    hideSubsiteColume?: boolean;
    oldDataDeleteable: boolean;
}
interface ShopSelectedDisplayState {
    selectedRows: Array<Shop>;
}

/**
 * 专柜展示和内容编辑
 */
class ShopSelectedDisplay<T> extends PureComponent<
    ShopSelectedDisplayProps<T>,
    ShopSelectedDisplayState
> {
    static defaultProps = {
        oldDataDeleteable: true,
    };
    initSelectRowIds =
        this.props.data &&
        this.props.data.filter((data) => data.select === undefined).map(({ id }) => id);
    getTableConfig = () => {
        const { disabled, columns, hideSubsiteColume } = this.props;
        const subsiteColumn = hideSubsiteColume
            ? []
            : [
                  {
                      title: language.getText('subsiteName'),
                      dataIndex: 'subSiteName',
                      key: 'subSiteName',
                  },
              ];
        let newColumns =
            columns && columns.length
                ? [...columns]
                : [
                      ...subsiteColumn,
                      {
                          title: services.language.getText('merchantName'),
                          dataIndex: 'merchantName',
                          key: 'merchantName',
                      },
                      {
                          title: services.language.getText('merchantCode'),
                          dataIndex: 'merchantCode',
                          key: 'merchantCode',
                          render: (text: string, record: any) => {
                              return <span>{text || record.code}</span>;
                          },
                      },
                      {
                          title: language.getText('merchantType'),
                          dataIndex: 'merchantType',
                          key: 'merchantType',
                      },
                      {
                          title: language.getText('merchantStatus'),
                          dataIndex: 'merchantStatus',
                          key: 'merchantStatus',
                      },
                  ];
        let operation = {};
        if (!disabled) {
            operation = {
                title: language.getText('common.tableAction'),
                className: 'action-column',
                key: 'operation',
                width: 100,
                render: (i: any, row: any) => {
                    const { oldDataDeleteable } = this.props;
                    const deleteable = oldDataDeleteable || !this.initSelectRowIds.includes(row.id);
                    return (
                        <Button type={'link'} disabled={!deleteable} onClick={this.onRemove(row)}>
                            {language.getText('common.delete')}
                        </Button>
                    );
                },
            };
        }

        let config: any = {
            rowKey: 'id',
            scroll: {
                y: 400,
            },
            columns: [...newColumns, operation],
            footer: () => this.renderFooter(),
            style: {
                marginTop: 20,
            },
        };
        return config;
    };

    renderFooter = () => {
        const { disabled, mode } = this.props;
        return !disabled && (!mode || mode !== ShopSelectedDisplayMode.SINGLE) ? (
            <div className="product-selected-footer">
                <div className="setting-item">
                    <Popconfirm
                        onConfirm={this.onRemoveAll}
                        title={language.getText('confirmDeleteColumn')}
                        okText={language.getText('common.ok')}
                        cancelText={language.getText('common.cancel')}
                    >
                        <Button className="setting-item-ok" type="primary">
                            {language.getText('batchDelete')}
                        </Button>
                    </Popconfirm>
                </div>
            </div>
        ) : null;
    };

    onRemoveAll = () => {
        const { data, onRemove } = this.props;
        const { selectedRows } = this.state;
        if (data.filter((s) => s.select).length === 0) {
            message.info(language.getText('selectBatchDeleteColumn'));
            return;
        }
        if (selectedRows) {
            const results = cloneDeep(data);
            forEach(results, (shop: Shop) => {
                if (shop.select) {
                    shop.delete = true;
                }
            });
            remove(results, (shop) => shop.delete);
            onRemove(results);
        }
    };

    onRemove = (row: Shop) => {
        const { data, onRemove } = this.props;

        return () => {
            const result = cloneDeep(data);
            remove(result, (shop) => row.id === shop.id);
            onRemove(result);
        };
    };

    batchChangeValue = (field: string, value: any) => {};

    changeBatchInputValue = (field: string, value: any) => {};

    render() {
        const { data, onChange, mode, disabled } = this.props;
        if (data && !data.length) {
            return <div>{language.getText('notData')}</div>;
        }
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: data,
        };
        if (!disabled) {
            tableProps.rowSelection = {
                selectedRowKeys: new Array<string>(),
                onChange: (
                    selectedRowKeys: Array<number> | Array<string>,
                    selectedRows: Array<Shop>
                ) => {
                    const { oldDataDeleteable } = this.props;
                    const validSelectedRows = selectedRows.filter(
                        (record) => oldDataDeleteable || !this.initSelectRowIds.includes(record.id)
                    );
                    this.setState({ selectedRows: validSelectedRows });
                    onChange(validSelectedRows);
                },
                getCheckboxProps: (record: any) => {
                    const { oldDataDeleteable } = this.props;
                    if (oldDataDeleteable) {
                        return {};
                    }
                    return {
                        disabled: this.initSelectRowIds.includes(record.id),
                    };
                },
            };
        }

        if (data && !disabled) {
            const selectedRowKeys = map(
                filter(data, (shop) => {
                    return shop.select ? true : false;
                }),
                'id'
            );
            this.setState({ selectedRows: data });
            tableProps.rowSelection.selectedRowKeys = selectedRowKeys;
        }

        if (mode && mode === ShopSelectedDisplayMode.SINGLE) {
            delete tableProps.rowSelection;
        }
        return <Table {...tableProps} pagination={false}></Table>;
    }
}

export { ShopSelectedDisplay };
