import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        PointDeductionProductEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/pointDeductionProduct',
            filters: {
                keywords: {
                    type: 'string',
                    displayName: '<<keywordsSearch>>',
                    controlConfig: {
                        placeholder: '<<keywordsPlaceholder>>',
                        style: { width: 300 },
                    },
                },
                name: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                    controlConfig: { style: { width: 170 } },
                },
                code: {
                    type: 'string',
                    displayName: '<<productCode>>',
                    controlConfig: { style: { width: 170 } },
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                merchantId: {
                    displayName: '<<merchantName>>',
                    type: 'string.filterOptions.autoComplete',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                supplyChain: {
                    type: 'string.options.select',
                    displayName: '<<supplierName>>',
                    options: [
                        { id: 'ERP', name: 'ERP' },
                        { id: 'THIRD_PARTY', name: '<<sfgyl>>' },
                    ],
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                tradeType: {
                    type: 'string.options.select',
                    displayName: '<<tradeType>>',
                    options: [
                        { id: 'DOMESTIC_TRADE', name: '<<domesticTrade>>' },
                        { id: 'DUTY_PAID_IMPORT', name: '<<dutyPaidImport>>' },
                        { id: 'BONDED_DIRECT_SUPPLY', name: '<<bondedDirectSupply>>' },
                        { id: 'HONG_KONG_DIRECT', name: '<<hongkongMail>>' },
                        { id: 'OVERSEAS_DIRECT', name: '<<overseasDirectMail>>' },
                    ],
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                },
                brandName: {
                    type: 'string',
                    displayName: '<<brandName>>',
                },
                productInfo: {
                    type: 'object.picAndNameAndCode',
                    displayName: '<<productInformation>>',
                },
                count: {
                    type: 'string',
                    displayName: '<<sjkc>>',
                },
                code: {
                    type: 'string',
                    displayName: '<<productCode>>',
                },
                goodsName: {
                    type: 'string',
                    displayName: '<<goodName>>',
                },
                goodsCode: {
                    type: 'string',
                    displayName: '<<goodCode>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchantName>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                supplier: {
                    type: 'string.options.select',
                    displayName: '<<supplierName>>',
                    options: [
                        { id: 'ERP', name: 'ERP' },
                        { id: 'HOU_NIAO', name: '<<houniao>>' },
                        { id: 'OU_PAI', name: '<<op>>' },
                    ],
                },
                tradeType: {
                    type: 'string.options.select',
                    displayName: '<<tradeType>>',
                    options: [
                        { id: 'DOMESTIC_TRADE', name: '<<domesticTrade>>' },
                        { id: 'DUTY_PAID_IMPORT', name: '<<dutyPaidImport>>' },
                        { id: 'BONDED_DIRECT_SUPPLY', name: '<<bondedDirectSupply>>' },
                        { id: 'HONG_KONG_DIRECT', name: '<<hongkongMail>>' },
                        { id: 'OVERSEAS_DIRECT', name: '<<overseasDirectMail>>' },
                    ],
                },
            },
        },
        MerchantBigCodesEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PRODUCT`,
            apiPath: '/loader/admin/deduction_merchant_big_codes',
            filters: {
                code: {
                    type: 'string',
                    displayName: '<<bigCode>>',
                    controlConfig: { style: { width: 170 } },
                },
                name: {
                    type: 'string',
                    displayName: '<<bigCodeName>>',
                    controlConfig: { style: { width: 170 } },
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                merchantId: {
                    displayName: '<<merchantName>>',
                    type: 'string.filterOptions.autoComplete',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                code: {
                    type: 'string',
                    displayName: '<<bigCode>>',
                },
                name: {
                    type: 'string',
                    displayName: '<<bigCodeName>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsites>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<merchant>>',
                },
            },
        },
    },
    components: {
        PointDeductionProductView: {
            component: 'Viewport',
            entity: 'PointDeductionProductEntity',
        },
        PointDeductionProductTabsView: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'tabs-content-viewport',
            items: [{ component: 'PointDeductionProductTabsPage' }],
        },
        PointDeductionProductTabsPage: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<sp>>',
                    content: {
                        component: 'PointDeductionProductPage',
                    },
                },
                {
                    title: '<<bigCode>>',
                    content: {
                        component: 'MerchantBigCodesPage',
                    },
                },
            ],
        },
        PointDeductionProductPage: {
            entity: 'PointDeductionProductEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                { component: 'PointDeductionProductFilter' },
                {
                    component: 'GridLayout',
                    className: 'mt24',
                    style: {
                        marginBottom: 8,
                    },
                    items: [
                        {
                            component: 'PointDeductionProductButton',
                        },
                    ],
                },
                { component: 'PointDeductionProductTable' },
            ],
        },
        PointDeductionProductFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            labelCol: 6,
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'code',
                },

                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'supplyChain',
                },
                {
                    property: 'tradeType',
                },
            ],
        },
        PointDeductionProductTable: {
            component: 'PointDedectionProductTableView',
        },
        MerchantBigCodesPage: {
            entity: 'MerchantBigCodesEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                { component: 'MerchantBigCodesFilter' },
                {
                    component: 'GridLayout',
                    className: 'mt24',
                    style: {
                        marginBottom: 8,
                    },
                    items: [
                        {
                            component: 'MerchantBigCodesButtons',
                        },
                    ],
                },
                { component: 'MerchantBigCodesTable' },
            ],
        },
        MerchantBigCodesFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            labelCol: 6,
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'code',
                },
                {
                    property: 'name',
                },
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
            ],
        },
        MerchantBigCodesTable: {
            component: 'MerchantBigCodesTableView',
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/point-deduction-product',
                    component: 'PointDeductionProductView',
                    breadcrumbName: '<<pointDeductionProductSet>>',
                    privilege: {
                        path: 'pointDeductionProductSet',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'PointDeductionProductTabsView' }],
                },
            ],
        },
    ],
};
