// @ts-nocheck
import React, { PureComponent } from 'react';
import { Button, Modal, Upload, Icon, message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { ExportButton } from '@comall-backend-builder/components-basis';
import { ForwardDataCenterModal } from '../../services';

import './index.less';
import 'antd/dist/antd.css';

const { api } = services;
const { Dragger } = Upload;

interface Resource {
    resourceId: number;
    resourceName: string;
    resourceUrl: string;
    type: string;
}

/**
 * 全款预售导入商品数据按钮
 */
export class FullPaymentActivitiesImportButton extends PureComponent<
    any,
    { fileList: any; showModal: boolean; statusMsg: string; resource: Resource }
> {
    state = {
        fileList: [],
        showModal: false,
        statusMsg: services.language.getText('uploadFileClickButton'),
        resource: {
            resourceId: 0,
            resourceName: '',
            resourceUrl: '',
            type: 'IMPORT',
        },
    };

    toggleModal = () => {
        const { showModal } = this.state;
        this.setState({ showModal: !showModal });
    };

    onImport = (resource: any) => {
        this.setState({
            statusMsg: services.language.getText('importBeforeNotPage'),
        });
        const {
            row: { id },
            entity,
        } = this.props;
        const apiRoot = `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`;
        const apiPath = `/admin/full_payment_activities/${id}/upload`;

        api.put(resource, { apiRoot, apiPath })
            .then(
                (res) => {
                    ForwardDataCenterModal();

                    entity.search();
                    this.toggleModal();
                },
                (err) => {
                    message.error(err.response.body.err_msg);
                    this.setState({
                        statusMsg: services.language.getText('importFailPrivity'),
                    });
                }
            )
            .catch(() => {
                this.setState({
                    statusMsg: services.language.getText('importFail'),
                });
            });
    };

    handleOk = () => {
        const { resource } = this.state;
        if (resource && resource.resourceId) {
            this.onImport(resource);
        } else {
            message.error(services.language.getText('pleaseUploadFile'));
            return;
        }
    };

    render() {
        const { showModal, fileList, statusMsg } = this.state;
        const { row } = this.props;
        let disabled = false;
        if (row.canImport !== undefined) {
            disabled = !row.canImport;
        }
        const uploadProps = {
            accept: '.xlsx,.xls,.csv',
            multiple: false,
            onRemove: (file: any) => {
                this.setState((state: any) => {
                    const index = state.fileList.indexOf(file);
                    const newFileList = state.fileList.slice();
                    newFileList.splice(index, 1);
                    return {
                        fileList: newFileList,
                        resource: {
                            resourceId: 0,
                            resourceName: '',
                            resourceUrl: '',
                            type: 'IMPORT',
                        },
                    };
                });
            },
            beforeUpload: (file: any) => {
                const apiRoot = `${ENV.AUTH_API_ROOT}/FILE`;
                const apiPath = '/admin/files/locations/60/upload';
                api.upload(
                    { files: file },
                    { apiRoot, apiPath, fileName: 'files', progressCallBack: () => {} }
                )
                    .then((res) => {
                        const { name, path, id } = res;
                        this.setState({
                            resource: {
                                resourceId: id,
                                resourceName: name,
                                resourceUrl: path,
                                type: 'IMPORT',
                            },
                            fileList: [file],
                        });
                    })
                    .catch((err) => {
                        if (err) {
                            message.error(
                                err.response?.body?.err_msg ||
                                    services.language.getText('uoloadFail')
                            );
                        }
                    });
                return false;
            },
            fileList,
        };
        return (
            <div className="giving-coupon-schedules-data-statistics">
                <Button
                    disabled={disabled}
                    type="link"
                    className="btn-data-statistics"
                    onClick={this.toggleModal}
                >
                    {services.language.getText('importProduct')}
                </Button>
                <Modal
                    onCancel={this.toggleModal}
                    visible={showModal}
                    title={services.language.getText('importProduct')}
                    footer={[
                        <span className="giving-coupon-schedules-status-msg">{statusMsg}</span>,
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            {services.language.getText('import')}
                        </Button>,
                        <Button key="back" onClick={this.toggleModal}>
                            {services.language.getText('back')}
                        </Button>,
                    ]}
                >
                    <Dragger {...uploadProps}>
                        <p className="ant-upload-drag-icon">
                            <Icon type="inbox" />
                        </p>
                        <p className="ant-upload-text">{services.language.getText('selectFile')}</p>
                        <p className="ant-upload-hint">
                            {services.language.getText('supportExtension')}.xlsx、.xls、.csv
                        </p>
                    </Dragger>
                    <div className="giving-coupon-schedules-tips">
                        <p className="giving-coupon-schedules-tips-text">
                            {services.language.getText('importRules')}
                        </p>
                        <p className="giving-coupon-schedules-tips-text">
                            {services.language.getText('uploadIntro1')}
                        </p>
                        <p className="giving-coupon-schedules-tips-text">
                            {services.language.getText('uploadIntro2')}
                        </p>
                        <p className="giving-coupon-schedules-tips-text">
                            {services.language.getText('uploadIntro3')}
                        </p>
                        <p className="giving-coupon-schedules-tips-text">
                            {services.language.getText('rxtmddsphfgzqdrdsp')}
                        </p>
                    </div>
                    <div className="upload-tips">
                        <ExportButton
                            type="link"
                            text={services.language.getText('downloadTemplate')}
                            showErrorMessage={true}
                            source={{
                                apiRoot: `${ENV.AUTH_API_ROOT}/CYBERSHOP-PRESALE`,
                                apiPath: '/admin/full_payment_activities/template.xlsx',
                            }}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}
