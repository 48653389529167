import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { GivingCouponCrowds, GivingCouponCrowdEnum } from './giving-coupon-crowds';
import { CrowdsSelectedDisplay } from './crowds-selected-display';

export class GivingCouponCrowdsMode extends modes.ObjectMode {
    getDisplayComponent(value: any, displayInfo: any) {
        if (value && value.mode === GivingCouponCrowdEnum.Crowd) {
            return <CrowdsSelectedDisplay data={value.crowds} disabled={true} />;
        } else {
            return <div>导入指定用户</div>;
        }
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <GivingCouponCrowds {...displayInfo} />;
    }
}
