import React, { Component } from 'react';
import { map, find, forEach } from 'lodash';
import { Checkbox, Input, message as AntMessage, Select } from 'antd';
import { NumberInputTip } from '../../components';
import './index.less';
import { services } from '@comall-backend-builder/core';

type Condition = {
    code: ConditionCode;
    tip: string;
};

enum ConditionCode {
    /**
     * 是否支持配置 X天之前注册的用户可参加活动
     */
    MINREGISTERDAYS = 'MINREGISTERDAYS',
    /**
     * 是否支持配置 用户X天内消费Y次以上
     */
    CONSUMPTIONT = 'CONSUMPTIONT',
    /**
     * 是否支持配置 实名认证
     */
    CERTIFICATION = 'CERTIFICATION',
    /**
     * 限购条件:活动期间每用户最多购买X件
     */
    QUANTITYLIMIT = 'QUANTITYLIMIT',
    /**
     * 限购条件:每人每单最多购买X件
     */
    QUANTITYLIMITPERORDER = 'QUANTITYLIMITPERORDER',
    /**
     * 标签管理/最近消费时间
     */
    DISSIPATE = 'DISSIPATE',
    /**
     * 标签管理/最近消费金额
     */
    CONSUMPTIONAMOUNT = 'CONSUMPTIONAMOUNT',
    /**
     * 标签管理/最近消费次数
     */
    DEALCOUNT = 'DEALCOUNT',
    /**
     * 标签管理/到店天数
     */
    BEHAVIOR = 'BEHAVIOR',
}

type ParticipateConditionValue = {
    /**
     * 类型
     */
    code: ConditionCode;
    /**
     * 是否选中
     */
    checked: boolean;
    /**
     * 输入值
     */
    value?: number;
    /**
     * 提示信息
     */
    tip?: string;
    /**
     * 下拉框选中的value
     */
    selectValue?: string;
    /**
     * 其他value
     */
    otherValues?: {
        min: any;
        max: any;
    };
};
const consumptionOptions = [
    {
        id: '7',
        name: '最近7天',
    },
    {
        id: '15',
        name: '最近15天',
    },
    {
        id: '30',
        name: '最近30天',
    },
    {
        id: '60',
        name: '最近60天',
    },
    {
        id: '90',
        name: '最近90天',
    },
    {
        id: '180',
        name: '最近180天',
    },
    {
        id: '-1',
        name: '不限',
    },
];

export interface ParticipateConditionProps {
    name: string;
    codes: Array<Condition>;
    value: Array<ParticipateConditionValue>;
    onChange: (value: Array<ParticipateConditionValue>, name: string) => void;
}

export class ParticipateCondition extends Component<ParticipateConditionProps> {
    componentDidMount() {
        let { name, value, onChange, codes } = this.props;
        //初始化value值
        if (!value) {
            let defalutValue: Array<ParticipateConditionValue> = [];
            if (codes && codes.length > 0) {
                forEach(codes, (o) => {
                    let item = {
                        code: o.code,
                        checked: false,
                        tip: o.tip,
                    } as any;
                    if (
                        o.code === ConditionCode.CONSUMPTIONAMOUNT ||
                        o.code === ConditionCode.DEALCOUNT ||
                        o.code === ConditionCode.BEHAVIOR
                    ) {
                        item.selectValue = consumptionOptions[0].id;
                        item.otherValues = {
                            min: undefined,
                            max: undefined,
                        };
                    }
                    if (o.code === ConditionCode.DISSIPATE) {
                        item.selectValue = consumptionOptions[0].id;
                    }
                    defalutValue.push(item);
                });
            }
            if (onChange) {
                onChange(defalutValue, name);
            }
        }
    }

    onCheckboxChange(event: any, code: string) {
        let { name, value, onChange } = this.props;
        let checkboxValue = event.target.checked;
        if (value) {
            let currentParticipateConditionValue: any = find(value, { code: code });
            if (currentParticipateConditionValue) {
                currentParticipateConditionValue.checked = checkboxValue;
            }
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onInputValueChange(event: any, code: string, valueType: string) {
        let { name, value, onChange } = this.props;
        let inputValue = event.target.value;
        let reg = /^\+?[1-9]\d*$/;
        if (inputValue !== null && inputValue !== '' && !reg.test(inputValue)) {
            AntMessage.warning(services.language.getText('inputThanZero'));
            inputValue = null;
        }
        if (value) {
            let currentParticipateConditionValue: any = find(value, { code: code });
            if (valueType === 'value') {
                currentParticipateConditionValue.value = inputValue;
            }
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    onInputDecimalValueChange(event: any, code: string, valueType: string) {
        let { name, value, onChange } = this.props;
        let inputValue: any = event;
        if (value) {
            let currentParticipateConditionValue: any = find(value, { code: code });
            if (valueType === 'min') {
                currentParticipateConditionValue.otherValues.min = inputValue;
            }
            if (valueType === 'max') {
                currentParticipateConditionValue.otherValues.max = inputValue;
            }
        }
        if (onChange) {
            onChange(value, name);
        }
    }
    onSelectValueChange(event: any, code: string, valueType: string) {
        let { name, value, onChange } = this.props;
        if (value) {
            let currentParticipateConditionValue: any = find(value, { code: code });
            if (valueType === 'selectValue') {
                currentParticipateConditionValue.selectValue = event;
            }
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    renderItem = (code: Condition) => {
        const { value } = this.props;
        let currentValue: any = find(value, { code: code.code });
        let defaultSelectValue = currentValue?.selectValue ? currentValue.selectValue : '';

        return (
            currentValue && (
                <div className="participage-condition-item">
                    <div className="participage-condition-item-info">
                        <Checkbox
                            className="participage-condition-item-checkbox"
                            onChange={(e) => this.onCheckboxChange(e, code.code)}
                            checked={currentValue.checked}
                            value={code.code}
                        ></Checkbox>
                        {code.code === ConditionCode.MINREGISTERDAYS && (
                            <div className="participage-condition-item-content">
                                <Input
                                    className="participage-condition-item-input"
                                    placeholder={services.language.getText('inputPlease')}
                                    type="text"
                                    maxLength={20}
                                    value={currentValue.value}
                                    onChange={(e) => this.onInputValueChange(e, code.code, 'value')}
                                />
                                <span>{services.language.getText('tzqzcdyhkcjhd')}</span>
                            </div>
                        )}
                        {code.code === ConditionCode.CONSUMPTIONT && (
                            <div className="participage-condition-item-content">
                                <span>{services.language.getText('yhmyxf')}</span>
                                <Input
                                    className="participage-condition-item-input"
                                    placeholder={services.language.getText('inputPlease')}
                                    type="text"
                                    maxLength={20}
                                    value={currentValue.value}
                                    onChange={(e) => this.onInputValueChange(e, code.code, 'value')}
                                />
                                <span>{services.language.getText('cys')}</span>
                            </div>
                        )}
                        {code.code === ConditionCode.CERTIFICATION && (
                            <div className="participage-condition-item-content">
                                <span>{services.language.getText('xysmrz')}</span>
                            </div>
                        )}
                        {code.code === ConditionCode.QUANTITYLIMIT && (
                            <div className="participage-condition-item-content">
                                <span>{services.language.getText('hdqjmyhzdgm')}</span>
                                <Input
                                    className="participage-condition-item-input"
                                    placeholder={services.language.getText('inputPlease')}
                                    type="text"
                                    maxLength={20}
                                    value={currentValue.value}
                                    onChange={(e) => this.onInputValueChange(e, code.code, 'value')}
                                />
                                <span>{services.language.getText('jian')}</span>
                            </div>
                        )}
                        {code.code === ConditionCode.QUANTITYLIMITPERORDER && (
                            <div className="participage-condition-item-content">
                                <span>{services.language.getText('mrmdzdgm')}</span>
                                <Input
                                    className="participage-condition-item-input"
                                    placeholder={services.language.getText('inputPlease')}
                                    type="text"
                                    maxLength={20}
                                    value={currentValue.value}
                                    onChange={(e) => this.onInputValueChange(e, code.code, 'value')}
                                />
                                <span>{services.language.getText('jian')}</span>
                            </div>
                        )}
                        {code.code === ConditionCode.DISSIPATE && (
                            <div className="participage-condition-item-content">
                                <span>{services.language.getText('zjxfsj')}</span>
                                <Select
                                    value={defaultSelectValue}
                                    style={{ width: '140px' }}
                                    onChange={(e: any) =>
                                        this.onSelectValueChange(e, code.code, 'selectValue')
                                    }
                                >
                                    {consumptionOptions &&
                                        consumptionOptions.map((option: any) => (
                                            <Select.Option key={option.id} value={option.id}>
                                                {option.name}
                                            </Select.Option>
                                        ))}
                                </Select>
                            </div>
                        )}
                        {code.code === ConditionCode.CONSUMPTIONAMOUNT && (
                            <div
                                className="participage-condition-item-content"
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                <span>
                                    {services.language.getText('xfje')}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                                <Select
                                    value={defaultSelectValue}
                                    style={{ width: '140px' }}
                                    placeholder={services.language.getText('selectPlease')}
                                    onChange={(e: any) =>
                                        this.onSelectValueChange(e, code.code, 'selectValue')
                                    }
                                >
                                    {consumptionOptions &&
                                        consumptionOptions.map((option: any) => (
                                            <Select.Option key={option.id} value={option.id}>
                                                {option.name}
                                            </Select.Option>
                                        ))}
                                </Select>
                                <div style={{ marginLeft: 20, display: 'flex' }}>
                                    <NumberInputTip
                                        placeholder={services.language.getText('inputPlease')}
                                        type="text"
                                        addonafter={services.language.getText('yuan')}
                                        min={0}
                                        precision={0}
                                        style={{ width: 100 }}
                                        value={currentValue?.otherValues?.min}
                                        onChange={(value: any) =>
                                            this.onInputDecimalValueChange(value, code.code, 'min')
                                        }
                                    />
                                    <div style={{ marginLeft: 35 }}>--</div>
                                    <NumberInputTip
                                        placeholder={services.language.getText('inputPlease')}
                                        type="text"
                                        min={0}
                                        precision={0}
                                        style={{ width: 100, marginLeft: 5 }}
                                        addonafter={services.language.getText('yuan')}
                                        value={currentValue?.otherValues?.max}
                                        onChange={(value: any) =>
                                            this.onInputDecimalValueChange(value, code.code, 'max')
                                        }
                                    />
                                </div>
                            </div>
                        )}

                        {code.code === ConditionCode.DEALCOUNT && (
                            <div
                                className="participage-condition-item-content"
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                <span>
                                    {services.language.getText('xfcs')}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                                <Select
                                    value={defaultSelectValue}
                                    style={{ width: '140px' }}
                                    placeholder={services.language.getText('selectPlease')}
                                    onChange={(e: any) =>
                                        this.onSelectValueChange(e, code.code, 'selectValue')
                                    }
                                >
                                    {consumptionOptions &&
                                        consumptionOptions.map((option: any) => (
                                            <Select.Option key={option.id} value={option.id}>
                                                {option.name}
                                            </Select.Option>
                                        ))}
                                </Select>
                                <div style={{ marginLeft: 20, display: 'flex' }}>
                                    <NumberInputTip
                                        placeholder={services.language.getText('inputPlease')}
                                        type="text"
                                        addonafter={services.language.getText('frequency')}
                                        min={0}
                                        precision={0}
                                        style={{ width: 100 }}
                                        value={currentValue?.otherValues?.min}
                                        onChange={(value: any) =>
                                            this.onInputDecimalValueChange(value, code.code, 'min')
                                        }
                                    />
                                    <div style={{ marginLeft: 35 }}>--</div>
                                    <NumberInputTip
                                        placeholder={services.language.getText('inputPlease')}
                                        type="text"
                                        min={0}
                                        precision={0}
                                        style={{ width: 100, marginLeft: 5 }}
                                        addonafter={services.language.getText('frequency')}
                                        value={currentValue?.otherValues?.max}
                                        onChange={(value: any) =>
                                            this.onInputDecimalValueChange(value, code.code, 'max')
                                        }
                                    />
                                </div>
                            </div>
                        )}
                        {code.code === ConditionCode.BEHAVIOR && (
                            <div
                                className="participage-condition-item-content"
                                style={{ display: 'flex', alignItems: 'center' }}
                            >
                                <span>
                                    {services.language.getText('ddts')}
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                </span>
                                <Select
                                    value={defaultSelectValue}
                                    style={{ width: '140px' }}
                                    placeholder={services.language.getText('selectPlease')}
                                    onChange={(e: any) =>
                                        this.onSelectValueChange(e, code.code, 'selectValue')
                                    }
                                >
                                    {consumptionOptions &&
                                        consumptionOptions.map((option: any) => (
                                            <Select.Option key={option.id} value={option.id}>
                                                {option.name}
                                            </Select.Option>
                                        ))}
                                </Select>
                                <div style={{ marginLeft: 20, display: 'flex' }}>
                                    <NumberInputTip
                                        placeholder={services.language.getText('inputPlease')}
                                        type="text"
                                        addonafter={services.language.getText('day')}
                                        min={0}
                                        precision={0}
                                        style={{ width: 100 }}
                                        value={currentValue?.otherValues?.min}
                                        onChange={(value: any) =>
                                            this.onInputDecimalValueChange(value, code.code, 'min')
                                        }
                                    />
                                    <div style={{ marginLeft: 35 }}>--</div>
                                    <NumberInputTip
                                        placeholder={services.language.getText('inputPlease')}
                                        type="text"
                                        min={0}
                                        precision={0}
                                        style={{ width: 100, marginLeft: 5 }}
                                        addonafter={services.language.getText('day')}
                                        value={currentValue?.otherValues?.max}
                                        onChange={(value: any) =>
                                            this.onInputDecimalValueChange(value, code.code, 'max')
                                        }
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <div className="participage-condition-item-tip">{code.tip}</div>
                </div>
            )
        );
    };

    render() {
        const { codes } = this.props;
        return (
            <div className="participage-condition">
                {map(codes, (o, index) => {
                    return <div key={index}>{this.renderItem(o)}</div>;
                })}
            </div>
        );
    }
}
