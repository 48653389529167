import React, { PureComponent } from 'react';
import { cloneDeep, remove, find } from 'lodash';
import { Button, Checkbox, Icon, InputNumber, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { StoredValueCardProduct } from './stored-value-card-setting';
import { DateRangePicker } from '@comall-backend-builder/components-basis';
import './index.less';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';
import { TextTips } from '../../../../components';

interface Props {
    data: StoredValueCardProduct[];
    onChange: (data: StoredValueCardProduct[]) => void;
}
interface States {
    visible: boolean;
    changeRow?: StoredValueCardProduct;
}

/**
 * 货品展示和内容编辑
 */
class StoreValueCardProductDisplay extends PureComponent<Props, States> {
    constructor(props: any) {
        super(props);
        this.state = { visible: false };
    }

    onRemove = (id: number) => {
        const { data, onChange } = this.props;
        return () => {
            const result = cloneDeep(data);
            remove(result, (i) => id === i.id);
            onChange(result);
        };
    };

    toggle = () => {
        this.setState({ visible: !this.state.visible });
    };

    delete = (index: number) => {
        const { data, onChange } = this.props;
        const result = cloneDeep(data);
        remove(result, (item: StoredValueCardProduct, itemIndex: number) => itemIndex === index);
        onChange(result);
    };

    editDescription = (value: StoredValueCardProduct) => {
        this.setState({
            changeRow: value,
            visible: true,
        });
    };

    onChangeRange = (index: number, newRange: any) => {
        const { data, onChange } = this.props;
        const result = cloneDeep(data);
        result[index].effectiveStartTime = newRange ? newRange.start : undefined;
        result[index].effectiveEndTime = newRange ? newRange.end : undefined;
        onChange(result);
        return newRange;
    };

    onCheckboxChange = (value: any, index: number, styleIndex: number) => {
        const { data, onChange } = this.props;
        const result = cloneDeep(data);
        result[index].styles[styleIndex].status = value.target.checked;
        onChange(result);
    };
    onPriceChange = (value: any, index: number, styleIndex: number) => {
        const { data, onChange } = this.props;
        const result = cloneDeep(data);
        result[index].styles[styleIndex].price = value;
        onChange(result);
    };

    hide = (index: number, isHide: boolean) => {
        const { data, onChange } = this.props;
        const result = cloneDeep(data);
        result[index].isHide = isHide;
        onChange(result);
    };

    onInputValueChange = (e: any, index: number, type: string) => {
        const { data, onChange } = this.props;
        const result = cloneDeep(data);
        if (type === 'totalAmountLimit') {
            result[index].totalAmountLimit = e;
        } else if (type === 'memberAmountLimit') {
            result[index].memberAmountLimit = e;
        } else if (type === 'sequence') {
            result[index].sequence = e;
        }
        onChange(result);
    };

    renderTable = () => {
        const { data } = this.props;
        if (!data || data.length === 0) {
            return null;
        }
        return (
            <div className="stored-value-card-product-display">
                <div className="table">
                    <div>
                        <div className="table-tr table-flex">
                            <div className="table-td-width-sequence">
                                <TextTips
                                    text={services.language.getText('sequence')}
                                    tips={services.language.getText('sequenceIntro')}
                                />
                            </div>
                            <div className="table-td-width-name">{language.getText('klbmc')}</div>
                            <div className="table-td-width-type">{language.getText('klx')}</div>
                            <div className="table-td-width-time">
                                {language.getText('useExpirationDate')}
                            </div>
                            <div className="table-td-width-number">{language.getText('gmzxe')}</div>
                            <div className="table-td-width-number">
                                {language.getText('drgmxe')}
                            </div>
                            <div
                                className="table-td-width-info"
                                style={{ justifyContent: 'center' }}
                            >
                                {language.getText('szsysm')}
                            </div>
                            <div className="table-td-width-delete">
                                {language.getText('common.tableAction')}
                            </div>
                        </div>
                        {data.map((d, index: number) => {
                            const disabled =
                                d.type === 'E_RECHARGE_STORED_VALUE_CARD' ? true : false;
                            return (
                                <div>
                                    <div className="item-table-tr table-flex">
                                        <div className="table-td-width-sequence">
                                            <InputNumber
                                                disabled={disabled}
                                                min={1}
                                                max={99999}
                                                value={d.sequence}
                                                onChange={(e) =>
                                                    this.onInputValueChange(e, index, 'sequence')
                                                }
                                            />
                                        </div>
                                        <div className="table-td-width-name">
                                            {d.isHide ? (
                                                <Icon
                                                    type="down"
                                                    style={{ marginLeft: 8 }}
                                                    onClick={this.hide.bind(this, index, false)}
                                                />
                                            ) : (
                                                <Icon
                                                    type="up"
                                                    style={{ marginLeft: 8 }}
                                                    onClick={this.hide.bind(this, index, true)}
                                                />
                                            )}
                                            &nbsp;&nbsp;{d.name}
                                        </div>
                                        <div className="table-td-width-type">
                                            {d.type === 'E_STORED_VALUE_CARD' && '电子面值卡'}
                                            {d.type === 'E_RECHARGE_STORED_VALUE_CARD' &&
                                                '电子充值卡'}
                                            {d.type === 'P_STORED_VALUE_CARD' && '实体卡'}
                                        </div>
                                        <div
                                            className="table-td-width-time"
                                            style={{ padding: '0 10px' }}
                                        >
                                            <DateRangePicker
                                                name="dateRange"
                                                value={
                                                    d.effectiveStartTime && d.effectiveEndTime
                                                        ? {
                                                              start: d.effectiveStartTime,
                                                              end: d.effectiveEndTime,
                                                          }
                                                        : undefined
                                                }
                                                onChange={(value, name) =>
                                                    this.onChangeRange(index, value)
                                                }
                                                allowClear
                                            />
                                        </div>
                                        <div
                                            className="table-td-width-number"
                                            style={{ padding: '0 10px' }}
                                        >
                                            <InputNumber
                                                disabled={disabled}
                                                type="number"
                                                min={0.01}
                                                max={99999999.99}
                                                step={0.01}
                                                precision={2}
                                                value={d.totalAmountLimit}
                                                onChange={(e) =>
                                                    this.onInputValueChange(
                                                        e,
                                                        index,
                                                        'totalAmountLimit'
                                                    )
                                                }
                                            />
                                        </div>
                                        <div
                                            className="table-td-width-number"
                                            style={{ padding: '0 10px' }}
                                        >
                                            <InputNumber
                                                disabled={disabled}
                                                type="number"
                                                min={0.01}
                                                max={999999.99}
                                                step={0.01}
                                                precision={2}
                                                value={d.memberAmountLimit}
                                                onChange={(e) =>
                                                    this.onInputValueChange(
                                                        e,
                                                        index,
                                                        'memberAmountLimit'
                                                    )
                                                }
                                            />
                                        </div>
                                        <div
                                            className="table-td-width-info"
                                            style={{ textAlign: 'left' }}
                                        >
                                            <div className="text">{d.info}</div>
                                            <Button
                                                onClick={this.editDescription.bind(this, d)}
                                                type="link"
                                            >
                                                {language.getText('common.edit')}
                                            </Button>
                                        </div>
                                        <div className="table-td-width-delete">
                                            <Button
                                                onClick={this.delete.bind(this, index)}
                                                type="link"
                                                style={{ padding: 0 }}
                                            >
                                                {services.language.getText('common.delete')}
                                            </Button>
                                        </div>
                                    </div>
                                    {!d.isHide && (
                                        <div className="style-table">
                                            <div className="content">
                                                <div className="style-table-tr table-flex">
                                                    <div className="style-table-td">
                                                        {language.getText('mz_1')}
                                                    </div>
                                                    <div className="style-table-td">
                                                        {language.getText('smj')}
                                                    </div>
                                                    <div className="style-table-td">
                                                        {language.getText('hdj')}
                                                    </div>
                                                    <div className="style-table-td">
                                                        {language.getText('upShelf')}
                                                    </div>
                                                </div>
                                                <div>
                                                    {d.styles &&
                                                        d.styles.map((style: any, styleIndex) => {
                                                            return (
                                                                <div className="style-item-table-tr table-flex">
                                                                    <div className="style-table-td">
                                                                        {style.amount}
                                                                    </div>
                                                                    <div className="style-table-td">
                                                                        {style.salesPrice}
                                                                    </div>
                                                                    <div className="style-table-td">
                                                                        <InputNumber
                                                                            disabled={disabled}
                                                                            value={style.price}
                                                                            min={0.01}
                                                                            max={99999999}
                                                                            precision={2}
                                                                            placeholder={services.language.getText(
                                                                                'inputPlease'
                                                                            )}
                                                                            onChange={(
                                                                                value: any
                                                                            ) =>
                                                                                this.onPriceChange(
                                                                                    value,
                                                                                    index,
                                                                                    styleIndex
                                                                                )
                                                                            }
                                                                        />
                                                                    </div>
                                                                    <div className="style-table-td">
                                                                        <Checkbox
                                                                            checked={style.status}
                                                                            onChange={(e) =>
                                                                                this.onCheckboxChange(
                                                                                    e,
                                                                                    index,
                                                                                    styleIndex
                                                                                )
                                                                            }
                                                                        ></Checkbox>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        );
    };

    onCancel = () => {
        this.setState({
            visible: false,
        });
    };

    onInputChange = (e: any) => {
        const { changeRow } = this.state;
        if (!changeRow) {
            return;
        }
        const { value } = e.target;
        const newChangeRow = cloneDeep(changeRow);
        newChangeRow.info = value;
        this.setState({
            changeRow: newChangeRow,
        });
    };

    onOk = () => {
        const { data, onChange } = this.props;
        const { changeRow } = this.state;
        if (!changeRow) {
            return;
        }
        const result = cloneDeep(data);
        let item = find(result, (i) => changeRow.id === i.id);
        if (item) {
            item.info = changeRow.info;
            onChange(result);
            this.setState({
                changeRow: undefined,
                visible: false,
            });
        }
    };

    renderEditModal = () => {
        const { visible, changeRow } = this.state;
        return (
            <Modal
                title={language.getText('xgms')}
                width={800}
                visible={visible}
                className="role-selector-modal"
                onCancel={this.onCancel}
                onOk={this.onOk}
            >
                <div className="audit-guide-user-form">
                    <div className="audit-guide-user-form-item">
                        <div className="audit-guide-user-form-label">{language.getText('ms')}:</div>
                        <TextArea
                            onChange={this.onInputChange}
                            className="audit-guide-user-form-input"
                            rows={4}
                            placeholder={services.language.getText('inputPlease')}
                            value={changeRow?.info}
                            maxLength={1000}
                        ></TextArea>
                    </div>
                </div>
            </Modal>
        );
    };

    render() {
        return (
            <div>
                {this.renderTable()}
                {this.renderEditModal()}
            </div>
        );
    }
}

export { StoreValueCardProductDisplay };
