import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
const api = services.api;

export const CouponChannelStocksLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        config.apiPath = `/admin/coupon_channel_stocks/statistics`;
        if (data.id) {
            data.couponRuleId = data.id;
            delete data.id;
        }
        if (data.status && data.status === 'ALL') {
            delete data.status;
        }
        return await api.get(data, config).then((res: any) => {
            return res;
        });
    },
};
