import { PureComponent, createElement } from 'react';
import { ComponentsManager, EntitiesManager, services } from '@comall-backend-builder/core';

const TableComponentId = 'OperationLogsTable';
let Entity: any = {};
let entity: any = {};

export class OperationLogsTable extends PureComponent<{
    params?: any;
}> {
    constructor(props: any) {
        super(props);
        Entity = EntitiesManager.get('OperationLogsEntity');
        const { apiRoot, apiPath } = props;
        entity = new Entity({ apiRoot, apiPath });
    }

    getConfig = () => {
        const { params } = this.props;
        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: params || {},
            items: [
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    // size:'small',
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    loadFirstPage: true,
                    style: {
                        marginBottom: 15,
                    },
                    columns: [
                        {
                            property: 'createTime',
                            displayConfig: {
                                style: {
                                    maxWidth: '140px',
                                },
                            },
                        },
                        {
                            property: 'operationItems',
                            displayConfig: {
                                pageType: 'operationLogs',
                            },
                        },
                        {
                            property: 'createUser',
                        },
                    ],
                },
            ],
        };
    };

    render() {
        const config = this.getConfig();
        return createElement(ComponentsManager.get(config.component), config);
    }
}
