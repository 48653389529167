import React, { PureComponent } from 'react';
import { Form } from 'antd';
import { tools } from '@comall-backend-builder/design-components-basis';

import './index.less';
import { services } from '@comall-backend-builder/core';
/**
 * 王府井首页
 */
class wfjDesignIndex extends PureComponent<any> {
    render() {
        return (
            <Form className="editor-wfj-index">
                <div className="tips">{services.language.getText('wfjIndexNotConfig')}</div>
            </Form>
        );
    }
}

export const WfjDesignIndex = tools.ConfigFormDecorator(wfjDesignIndex);
