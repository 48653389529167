import { Config } from '@comall-backend-builder/core/lib/parser';
export const UserSubscribesConfig: Config = {
    entities: {
        UserSubscribesEntity: {
            apiPath: '/loader/admin/sales-electronic-coupon',
            properties: {},
        },
    },
    components: {
        UserSubscribesPageView: {
            component: 'Viewport',
        },
        UserProductsSubscribesPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'UserSubscribesPage',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/user-subscriptions',
                    component: 'UserSubscribesPageView',
                    breadcrumbName: '<<mySubscriptions>>',
                    privilege: {
                        path: 'mySubscribeGoods',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/',
                            component: 'UserProductsSubscribesPage',
                        },
                    ],
                },
            ],
        },
    ],
};
