import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { get, map } from 'lodash';
const { api } = services;

/** 接口上送数据处理 */
const processToVO = (data: any) => {
    data.picId = 0;
    data.picUrl = get(data, 'pic', null);
    const link = {
        linkType: get(data, 'linkType.linkType', 'none'),
        linkParams: get(data, 'linkType.linkParams', null),
    };
    data.linkType = link.linkType;
    data.linkParams = link.linkParams ? JSON.stringify(link.linkParams) : null;
    data.startTime = get(data, 'showTime.start', null);
    data.endTime = get(data, 'showTime.end', null);

    data.popUpAdsCitys = data.popUpAdsCitys.map((item: any) => {
        return { city_name: item.name, id: item.id };
    });

    delete data.pic;
    delete data.showTime;
    return data;
};

/** 数据库返回数据处理 */
function processToVM(data: any) {
    data.pic = [{ id: data.picId, path: data.picUrl }];
    let citys = '';
    if (data.linkParams) {
        data.linkParams = JSON.parse(data.linkParams);
    }
    data.linkType = { linkType: data.linkType, linkParams: data.linkParams };
    data.showTime = { start: data.startTime, end: data.endTime };

    data.popUpAdsCitys = data.popUpAdsCitys.map((item: any, index: number) => {
        if (item) {
            citys += item.cityName;
            if (index < data.popUpAdsCitys.length - 1) {
                citys += '      ';
            }
        }

        return { name: item.cityName, id: item.id + '' };
    });

    data.canEdit = data.status === 'ENABLE' ? false : true;
    data.canDelete = data.status === 'ENABLE' ? false : true;

    data.citys = citys;

    return data;
}

/** 内容弹窗数据处理 */
export const popUpContentLoader = {
    /** 列表、详情查询接口 */
    get: function(data: any, config: ApiRequestConfig) {
        config.apiPath = '/admin/pop_up_ads';
        if (data.id) config.apiPath = `/admin/pop_up_ads/${data.id}`;

        if (data.showTime) {
            data.startTime = get(data, 'showTime.start', null);
            data.endTime = get(data, 'showTime.end', null);
            data.showTime = undefined;
        }

        const subsiteName = data.subsiteName;
        if (subsiteName) {
            data.subsiteName = subsiteName.name ? subsiteName.name : subsiteName;
        }

        return api.get(data, config).then((res: any) => {
            if (data.id) {
                return processToVM(res);
            }
            if (res && res.result) {
                map(res.result, processToVM);
            }
            return res;
        });
    },

    /** 修改接口 */
    put: function(data: any, config: ApiRequestConfig) {
        config.apiPath = `/admin/pop_up_ads/${data.id}`;

        return api.put(processToVO(data), config);
    },

    /** 新增接口 */
    post: function(data: any, config: ApiRequestConfig) {
        config.apiPath = '/admin/pop_up_ads';

        return api.post(processToVO(data), config);
    },

    /** 新增接口 */
    delete: function(data: any, config: ApiRequestConfig) {
        config.apiPath = `/admin/pop_up_ads/${data.id}`;

        return api.delete(data, config);
    },
};
