import React, { FC, useRef, useState, useEffect } from 'react';
import { Modal, Button, InputNumber, message, Row, Col } from 'antd';
import { RcFile } from 'antd/lib/upload';
import { ModalProps } from 'antd/lib/modal';
import { RadioChangeEvent } from 'antd/lib/radio';
import ReactCropper, { ReactCropperElement } from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import ButtonRadio from './ButtonRadio/ButtonRadio';
import './index.less';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

interface CropImageModalProps {
    modalVisible: boolean;
    title?: string;
    file?: RcFile;
    customOptions?: CustomOptions;
    onConfirm(file?: RcFile | File): void;
    onModalCancel?(): void;
}
export interface CustomOptions {
    cropBoxWidth?: number;
    cropBoxHeight?: number;
    degree?: number;
    ratio?: number;
    cornerRadiusStyle?: CornerRadiusStyle;
}
interface InitCropBoxData {
    left: number;
    top: number;
    width: number;
    height: number;
}
enum AspectRatio {
    Ratio169 = '16:9',
    Ratio43 = '4:3',
    Ratio11 = '1:1',
    Ratio23 = '2:3',
    Free = 'Free',
}
const aspectRatioToValue = {
    [AspectRatio.Ratio169]: 16 / 9,
    [AspectRatio.Ratio43]: 4 / 3,
    [AspectRatio.Ratio11]: 1,
    [AspectRatio.Ratio23]: 2 / 3,
    [AspectRatio.Free]: NaN,
};
export enum CornerRadiusStyle {
    Square = 'square',
    Rounded = 'rounded',
    Arch = 'arch',
}

const degreeStep = 15;
const ratioStep = 0.1;
const rotateRightImg = 'https://puzhehei.oss-cn-beijing.aliyuncs.com/global/crop/rotate-right.png';
const rotateLeftImg = 'https://puzhehei.oss-cn-beijing.aliyuncs.com/global/crop/rotate-left.png';
const squareImg = 'https://puzhehei.oss-cn-beijing.aliyuncs.com/global/crop/square.png';
const squareActiveImg =
    'https://puzhehei.oss-cn-beijing.aliyuncs.com/global/crop/square-active.png';
const roundedImg = 'https://puzhehei.oss-cn-beijing.aliyuncs.com/global/crop/rounded.png';
const roundedActiveImg =
    'https://puzhehei.oss-cn-beijing.aliyuncs.com/global/crop/rounded-active.png';
const archImg = 'https://puzhehei.oss-cn-beijing.aliyuncs.com/global/crop/arch.png';
const archActiveImg = 'https://puzhehei.oss-cn-beijing.aliyuncs.com/global/crop/arch-active.png';

const CropImageModal: FC<CropImageModalProps> = (props) => {
    const {
        title = language.getText('tpcj'),
        file,
        modalVisible,
        customOptions,
        onConfirm,
        onModalCancel,
    } = props;
    const wrapperRef = useRef<HTMLDivElement>(null);
    const cropperRef = useRef<ReactCropperElement>(null);
    const [isCrop, setCropState] = useState(false);
    const [image, setImage] = useState('');
    const [initCropBoxData, setInitCropBoxData] = useState<InitCropBoxData>();
    const [innerCropBoxWidth, setInnerCropBoxWidth] = useState(customOptions?.cropBoxWidth);
    const [innerCropBoxHeight, setInnerCropBoxHeight] = useState(customOptions?.cropBoxHeight);
    const [aspectRatio, setAspectRatio] = useState(AspectRatio.Free);
    const [cornerRadiusStyle, setCornerRadiusStyle] = useState(
        customOptions?.cornerRadiusStyle || CornerRadiusStyle.Square
    );

    useEffect(() => {
        if (modalVisible && file) {
            const reader = new FileReader();
            reader.onload = () => {
                setImage(reader.result as string);
            };
            reader.readAsDataURL(file);
        }
    }, [file, modalVisible]);

    const handleCrop = () => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            const cropBox = cropper.getCropBoxData();
            if (cropBox.width && cropBox.height) {
                setInnerCropBoxWidth(Math.round(cropBox.width) * 2);
                setInnerCropBoxHeight(Math.round(cropBox.height) * 2);
            }
        }
    };

    const handleCropBoxWidthChange = (value?: number) => {
        const cropper = cropperRef.current?.cropper;
        if (cropper && value) {
            cropper.setCropBoxData({
                width: Math.round(value / 2),
            });
        }
        setInnerCropBoxWidth(value);
    };

    const handleCropBoxHeightChange = (value?: number) => {
        const cropper = cropperRef.current?.cropper;
        if (cropper && value) {
            cropper.setCropBoxData({
                height: Math.round(value / 2),
            });
        }
        setInnerCropBoxHeight(value);
    };

    const handleRotateRight = () => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            cropper.rotate(degreeStep);
        }
    };

    const handleRotateLeft = () => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            cropper.rotate(degreeStep * -1);
        }
    };

    const handleZoomUp = () => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            cropper.zoom(ratioStep);
        }
    };

    const handleZoomDown = () => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            cropper.zoom(ratioStep * -1);
        }
    };

    const handleReset = () => {
        const cropper = cropperRef.current?.cropper;
        if (cropper) {
            ratioChange(AspectRatio.Free);
            borderChange(customOptions?.cornerRadiusStyle || CornerRadiusStyle.Square);
            cropper.reset();
            if (initCropBoxData) {
                cropper.setCropBoxData(initCropBoxData);
            }
        }
    };

    const handleRatioChange = (e: RadioChangeEvent) => {
        const { value } = e.target;
        ratioChange(value);
    };

    const ratioChange = (aspectRatio: AspectRatio) => {
        const cropper = cropperRef.current?.cropper;
        setAspectRatio(aspectRatio);
        if (aspectRatio && cropper) {
            const ratioValue = aspectRatioToValue[aspectRatio];
            cropper.setAspectRatio(ratioValue);
        }
    };

    const handleBorderChange = (e: RadioChangeEvent) => {
        const { value } = e.target;
        borderChange(value);
    };

    const borderChange = (cornerRadiusStyle: CornerRadiusStyle) => {
        setCornerRadiusStyle(cornerRadiusStyle);
        const wrapper = wrapperRef.current;
        if (wrapper) {
            const cropViewBoxList = wrapper.getElementsByClassName('cropper-view-box');
            if (cropViewBoxList && cropViewBoxList.length) {
                const cropViewBox = cropViewBoxList[0] as HTMLDivElement;
                if (cornerRadiusStyle === CornerRadiusStyle.Square) {
                    cropViewBox.style.borderRadius = '0';
                } else if (cornerRadiusStyle === CornerRadiusStyle.Rounded) {
                    cropViewBox.style.borderRadius = '16px';
                } else if (cornerRadiusStyle === CornerRadiusStyle.Arch) {
                    const radius = innerCropBoxWidth ? `${innerCropBoxWidth * 0.66}px` : '50%';
                    cropViewBox.style.borderRadius = `${radius} ${radius} 0 0`;
                }
            }
        }
    };

    const onCancel = () => {
        onModalCancel?.();
    };

    const onOk = () => {
        if (isCrop) {
            const cropper = cropperRef.current?.cropper;
            if (cropper) {
                cropper.getCroppedCanvas().toBlob((blob) => {
                    if (!blob) {
                        message.error(language.getText('cjsb'));
                        return;
                    }
                    const croppedFile = new File([blob], file?.name || 'cropped-image.png', {
                        type: file?.type || 'image/png',
                    });
                    onConfirm(croppedFile);
                }, 'image/png');
            }
        } else {
            onConfirm(file);
        }
    };

    const onStartCrop = () => {
        const cropper = cropperRef.current?.cropper;
        if (isCrop) {
            handleReset();
            cropper?.clear();
            setCropState(false);
        } else {
            const { cropBoxWidth, cropBoxHeight, degree, ratio, cornerRadiusStyle } =
                customOptions || {};
            cropper?.crop();
            if (cropBoxWidth && cropBoxHeight) {
                cropper?.setCropBoxData({
                    width: cropBoxWidth / 2,
                    height: cropBoxHeight / 2,
                });
            }
            setInitCropBoxData(cropper?.getCropBoxData());
            if (degree) {
                cropper?.rotateTo(degree);
            }
            if (ratio) {
                cropper?.zoomTo(ratio);
            }
            if (cornerRadiusStyle) {
                borderChange(cornerRadiusStyle);
            }
            setCropState(true);
        }
    };
    const footerBtn = [
        <Button key="back" onClick={onCancel}>
            {language.getText('common.cancel')}
        </Button>,
        <Button key="crop" onClick={onStartCrop}>
            {isCrop ? language.getText('restore') : language.getText('jc')}
        </Button>,
        <Button key="submit" type="primary" onClick={onOk}>
            {language.getText('common.ok')}
        </Button>,
    ];
    const modalProps: ModalProps = {
        visible: modalVisible,
        onCancel,
        onOk,
        title: title || language.getText('selectPlease'),
        mask: true,
        okText: services.language.getText('common.ok'),
        cancelText: services.language.getText('common.cancel'),
        width: 900,
        centered: true,
        maskClosable: false,
        footer: footerBtn,
    };
    const wrapperStyle = {
        justifyContent: isCrop ? 'space-between' : 'center',
    };
    const borderText = cornerRadiusStyleOptions.find((i) => i.value === cornerRadiusStyle)?.label;
    const newAspectRatioOptions = aspectRatioOptions.map((i: any) => ({
        ...i,
        label: language.getText(i.label),
    }));
    const newCornerRadiusStyleOptions = cornerRadiusStyleOptions.map((i: any) => ({
        ...i,
        label: language.getText(i.label),
    }));
    return (
        <Modal {...modalProps}>
            <div className="crop-image" style={wrapperStyle}>
                <div className="crop-image__image" ref={wrapperRef}>
                    {image ? (
                        <ReactCropper
                            width={375}
                            height={375}
                            viewMode={0}
                            dragMode="move"
                            src={image}
                            ref={cropperRef}
                            autoCrop={false}
                            autoCropArea={1}
                            guides={false}
                            center={false}
                            highlight={false}
                            crop={handleCrop}
                        />
                    ) : null}
                </div>
                {isCrop ? (
                    <div className="crop-image__info">
                        <Row type="flex" align="middle" className="crop-image__info__row">
                            <Col span={6}>{services.language.getText('jckk')}</Col>
                            <Col span={18}>
                                <div className="crop-image__info__item">
                                    <div className="crop-image__info__item__text"></div>
                                    <InputNumber
                                        className="crop-image__info__item__iptNumber"
                                        min={0}
                                        value={innerCropBoxWidth}
                                        onChange={handleCropBoxWidthChange}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row type="flex" align="middle" className="crop-image__info__row">
                            <Col span={6}>{language.getText('jckg')}</Col>
                            <Col span={18}>
                                <div className="crop-image__info__item">
                                    <div className="crop-image__info__item__text"></div>
                                    <InputNumber
                                        className="crop-image__info__item__iptNumber"
                                        min={0}
                                        value={innerCropBoxHeight}
                                        onChange={handleCropBoxHeightChange}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row type="flex" align="middle" className="crop-image__info__row">
                            <Col span={6}>{language.getText('dqfs')}</Col>
                            <Col span={18}>
                                <div className="crop-image__info__item">
                                    <div className="crop-image__info__item__text"></div>
                                    <ButtonRadio
                                        value={aspectRatio}
                                        onChange={handleRatioChange}
                                        options={newAspectRatioOptions}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row type="flex" align="middle" className="crop-image__info__row">
                            <Col span={6}>{language.getText('xzfx')}</Col>
                            <Col span={18}>
                                <div className="crop-image__info__item">
                                    <div className="crop-image__info__item__text"></div>
                                    <div className="crop-image__info__item__group">
                                        <div
                                            className="crop-image__info__item__btn"
                                            onClick={handleRotateRight}
                                        >
                                            <img
                                                className="crop-image__info__item__btn__icon"
                                                src={rotateRightImg}
                                                alt={language.getText('yx')}
                                            />
                                        </div>
                                        <div
                                            className="crop-image__info__item__btn"
                                            onClick={handleRotateLeft}
                                        >
                                            <img
                                                className="crop-image__info__item__btn__icon"
                                                src={rotateLeftImg}
                                                alt={language.getText('zx')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row type="flex" align="middle" className="crop-image__info__row">
                            <Col span={6}>{language.getText('tpsf')}</Col>
                            <Col span={18}>
                                <div className="crop-image__info__item">
                                    <div className="crop-image__info__item__text"></div>
                                    <Button.Group>
                                        <Button onClick={handleZoomUp}>放大</Button>
                                        <Button onClick={handleZoomDown}>
                                            {services.language.getText('narrow')}
                                        </Button>
                                    </Button.Group>
                                </div>
                            </Col>
                        </Row>
                        <Row type="flex" align="middle" className="crop-image__info__row">
                            <Col span={6}>{language.getText('bjys')}</Col>
                            <Col span={18}>
                                <div className="crop-image__info__item">
                                    <div className="crop-image__info__item__text">
                                        {borderText && language.getText(borderText)}
                                    </div>
                                    <ButtonRadio
                                        value={cornerRadiusStyle}
                                        onChange={handleBorderChange}
                                        options={newCornerRadiusStyleOptions}
                                    />
                                </div>
                            </Col>
                        </Row>

                        <div className="crop-image__info__footer">
                            <Button type="primary" onClick={handleReset}>
                                {language.getText('common.reset')}
                            </Button>
                        </div>
                    </div>
                ) : null}
            </div>
        </Modal>
    );
};

const aspectRatioOptions = [
    {
        value: AspectRatio.Free,
        label: 'aspectRatioOptionsFree',
    },
    {
        value: AspectRatio.Ratio11,
        label: 'aspectRatioOptionsRatio11',
    },
    {
        value: AspectRatio.Ratio43,
        label: 'aspectRatioOptionsRatio43',
    },
    {
        value: AspectRatio.Ratio169,
        label: 'aspectRatioOptionsRatio169',
    },
    {
        value: AspectRatio.Ratio23,
        label: 'aspectRatioOptionsRatio23',
    },
];

const cornerRadiusStyleOptions = [
    {
        value: CornerRadiusStyle.Square,
        label: 'zj_5',
        renderInner: (checked: boolean) => {
            const url = checked ? squareActiveImg : squareImg;
            return <img src={url} alt="" className="ratio-img" />;
        },
    },
    {
        value: CornerRadiusStyle.Rounded,
        label: 'yj_2',
        renderInner: (checked: boolean) => {
            const url = checked ? roundedActiveImg : roundedImg;
            return <img src={url} alt="" className="ratio-img" />;
        },
    },
    {
        value: CornerRadiusStyle.Arch,
        label: 'gm',
        renderInner: (checked: boolean) => {
            const url = checked ? archActiveImg : archImg;
            return <img src={url} alt="" className="ratio-img" />;
        },
    },
];

export default CropImageModal;
