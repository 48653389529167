import React, { PureComponent } from 'react';
import { InputNumber, DatePicker, Select } from 'antd';
import moment from 'moment';
import { get } from 'lodash';
import './index.less';
import { services } from '@comall-backend-builder/core';

const { Option } = Select;

export enum RegistrationTimeType {
    /*
     *指定日期
     */
    DAY_TIME = 'DAY_TIME',
    /*
     *活动开始前
     */
    BEFORE_BEGIN = 'BEFORE_BEGIN',
    /*
     *活动开始后
     */
    AFTER_BEGIN = 'AFTER_BEGIN',
    /*
     *活动结束前
     */
    BEFORE_END = 'BEFORE_END',
}
export enum TimeUnit {
    /**
     * 天
     */
    DAY = 'DAY',
    /**
     * 小时
     */
    HOUR = 'HOUR',
}

export class MallActivityRegistrationTime extends PureComponent<any> {
    static defaultProps = {
        value: {},
    };

    disabledDate = (current: moment.Moment | null) => {
        const { value, type } = this.props;
        const timeStr = get(value, 'timeStr');

        if (type === 'endTime' && timeStr) {
            return !!(current && current < moment(timeStr));
        } else {
            return !!(current && current < moment().subtract(1, 'days'));
        }
    };

    onTimeTypeChange = (val: any) => {
        const { value, onChange } = this.props;
        if (!value) {
            onChange && onChange({ timeType: val });
        } else {
            onChange && onChange({ ...value, timeType: val });
        }
    };
    onTimeChange = (val: any) => {
        const { value, onChange } = this.props;

        const timeStr = moment(val || undefined).format('YYYY-MM-DD HH:mm:ss');
        if (!value) {
            onChange && onChange({ timeStr: timeStr });
        } else {
            onChange && onChange({ ...value, timeStr: timeStr });
        }
    };
    numChange = (val: any) => {
        const { value, onChange } = this.props;
        if (!value) {
            onChange && onChange({ num: val });
        } else {
            onChange && onChange({ ...value, num: val });
        }
    };
    unitChange = (val: any) => {
        const { value, onChange } = this.props;
        if (!value) {
            onChange && onChange({ timeUnit: val });
        } else {
            onChange && onChange({ ...value, timeUnit: val });
        }
    };
    render() {
        const { value, mode, row, isActivityStarted } = this.props;
        const timeType = get(value, 'timeType');
        const timeUnit = get(value, 'timeUnit');
        const num = get(value, 'num');
        const timeStr = get(value, 'timeStr');
        const session = get(row, 'registrationInfo.session');
        let disabled = false;
        if (mode === 'BEGIN' && session === 'NO' && isActivityStarted) {
            disabled = true;
        }

        return (
            <div className="registration-time-components">
                {mode === 'BEGIN' ? (
                    <Select
                        style={{ width: '180px' }}
                        onChange={this.onTimeTypeChange}
                        optionLabelProp="label"
                        value={timeType}
                        disabled={disabled}
                    >
                        <Option
                            value={RegistrationTimeType.DAY_TIME}
                            label="指定日期时间"
                            disabled={session === 'YES' && isActivityStarted}
                        >
                            指定日期时间
                        </Option>
                        <Option
                            value={RegistrationTimeType.BEFORE_BEGIN}
                            label={session === 'NO' ? '活动开始前' : '场次开始前'}
                        >
                            {session === 'NO' ? '活动开始前' : '场次开始前'}
                        </Option>
                    </Select>
                ) : (
                    <Select
                        style={{ width: '180px' }}
                        onChange={this.onTimeTypeChange}
                        optionLabelProp="label"
                        value={timeType}
                    >
                        <Option value={RegistrationTimeType.DAY_TIME} label="指定日期时间">
                            指定日期时间
                        </Option>
                        <Option
                            value={RegistrationTimeType.BEFORE_BEGIN}
                            label={session === 'NO' ? '活动开始前' : '场次开始前'}
                            disabled={session === 'NO' && isActivityStarted}
                        >
                            {session === 'NO' ? '活动开始前' : '场次开始前'}
                        </Option>

                        <Option
                            value={RegistrationTimeType.AFTER_BEGIN}
                            label={session === 'NO' ? '活动开始后' : '场次开始后'}
                            disabled={session === 'NO' && isActivityStarted}
                        >
                            {session === 'NO' ? '活动开始后' : '场次开始后'}
                        </Option>
                        <Option
                            value={RegistrationTimeType.BEFORE_END}
                            label={session === 'NO' ? '活动结束前' : '场次结束前'}
                        >
                            {session === 'NO' ? '活动结束前' : '场次结束前'}
                        </Option>
                    </Select>
                )}
                {timeType === RegistrationTimeType.DAY_TIME ? (
                    <DatePicker
                        disabled={disabled}
                        style={{ marginLeft: '20px' }}
                        format="YYYY-MM-DD HH:mm:ss"
                        showTime
                        value={timeStr ? moment(timeStr) : undefined}
                        onChange={this.onTimeChange}
                        placeholder={services.language.getText('selectPlease')}
                    />
                ) : (
                    <>
                        <InputNumber
                            value={num}
                            onChange={this.numChange}
                            placeholder={services.language.getText('inputPlease')}
                            style={{ width: '150px', marginLeft: '20px' }}
                            min={timeUnit === TimeUnit.HOUR && mode !== ' BEGIN' ? 0 : 1}
                            max={99999999999}
                            step={1}
                            precision={0}
                        />
                        <Select
                            placeholder={services.language.getText('selectPlease')}
                            style={{ width: 90 }}
                            onChange={this.unitChange}
                            value={timeUnit}
                            disabled={disabled}
                        >
                            <Option value={TimeUnit.DAY}>天</Option>
                            <Option value={TimeUnit.HOUR}>小时</Option>
                        </Select>
                    </>
                )}
            </div>
        );
    }
}
