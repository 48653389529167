import React, { Component } from 'react';
import { Radio, Form, Tooltip, Icon, Switch, DatePicker, Slider } from 'antd';
import { services } from '@comall-backend-builder/core';
import { ShowProducts } from './show-products';
import { ShowContent } from './show-content';
import ProductMore from './product-more';
import { tools } from '@comall-backend-builder/design-components-basis';
import moment from 'moment';

import './index.less';

const language = services.language;
const FormItemLayout = tools.FormItemLayout;
const ConfigFormDecorator = tools.ConfigFormDecorator;
const { RangePicker } = DatePicker;
const FormItem = Form.Item;
const RadioGroup = Radio.Group;

export interface IProductMore {
    pic: string;
    title: string;
    text: string;
    border: boolean;
    linkParams: any;
    linkType: string;
}
/**
 * 商品列表组件-配置信息
 *  */
class products extends Component<any> {
    productMoreChange = (value: IProductMore) => {
        const { getFieldsValue } = this.props.form;
        const { onChange } = this.props;
        onChange({ ...getFieldsValue(), productMore: value });
    };

    dateChange = (val: any) => {
        const { onChange } = this.props;
        const { getFieldsValue } = this.props.form;
        let date = val.map((i: any) => i.format('YYYY/MM/DD HH:mm:ss'));
        onChange({ ...getFieldsValue(), timeContent: date });
    };

    render() {
        const { getFieldDecorator, getFieldValue } = this.props.form;
        const { selector, uploadAction, linkTypes, linkTypeMode, sourceRoot } = this.props;
        const effectiveTime = getFieldValue('timeContent');
        return (
            <Form className="editor-products">
                <FormItem
                    {...FormItemLayout}
                    label={language.getText('components.Products.showStyle')}
                >
                    {getFieldDecorator(
                        'showStyle',
                        {}
                    )(
                        <RadioGroup className="show-style">
                            <Radio value={'1'}>
                                {language.getText('components.Products.list')}
                            </Radio>
                            <Radio value={'2'}>
                                {language.getText('components.Products.card')}
                            </Radio>
                            <Radio value={'3'}>一行两个</Radio>
                            <Radio value={'4'}>一行三个</Radio>
                            <Radio value={'5'}>
                                {language.getText('components.Products.scroll')}
                            </Radio>
                        </RadioGroup>
                    )}
                </FormItem>
                <FormItem {...FormItemLayout} label={'图片圆角'}>
                    {getFieldDecorator('borderRadius')(<Slider max={48} min={0} />)}
                </FormItem>
                {/* 横向滚动添加更多配置 */}
                {getFieldValue('showStyle') === '5' && (
                    <FormItem {...FormItemLayout} label={'是否显示更多'}>
                        {getFieldDecorator('showMore', { valuePropName: 'checked' })(<Switch />)}
                    </FormItem>
                )}
                {getFieldValue('showStyle') === '5' && getFieldValue('showMore') && (
                    <FormItem {...FormItemLayout} label="更多配置">
                        <ProductMore
                            sourceRoot={sourceRoot}
                            value={this.props.value.productMore}
                            onChange={this.productMoreChange}
                            selector={selector}
                            uploadAction={uploadAction}
                            linkTypes={linkTypes}
                            linkTypeMode={linkTypeMode}
                        />
                    </FormItem>
                )}
                <FormItem
                    {...FormItemLayout}
                    label={
                        <span>
                            {language.getText('components.Products.products')}&nbsp;
                            <Tooltip title={language.getText('components.Products.dragTips')}>
                                <Icon type="question-circle-o" />
                            </Tooltip>
                        </span>
                    }
                >
                    {getFieldDecorator('entry', {
                        initialValue: [],
                    })(<ShowProducts selector={selector} />)}
                </FormItem>
                <FormItem
                    {...FormItemLayout}
                    label={language.getText('components.Products.content')}
                >
                    {getFieldDecorator('showContent', {
                        initialValue: [],
                    })(<ShowContent getFieldDecorator={getFieldDecorator} />)}
                </FormItem>

                <FormItem
                    {...FormItemLayout}
                    label={<span>{language.getText('components.Tools.effectiveTime')}</span>}
                >
                    <RangePicker
                        style={{ width: 380 }}
                        value={
                            effectiveTime && effectiveTime.length
                                ? effectiveTime.map((i: any) => moment(i))
                                : null
                        }
                        onChange={this.dateChange}
                        showTime
                    />
                </FormItem>
            </Form>
        );
    }
}

export const Products = ConfigFormDecorator(products);
