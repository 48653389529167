import React, { PureComponent, createElement } from 'react';
import { defaultsDeep, set, assign } from 'lodash';
import { Modal, message as AntMessage } from 'antd';
import { builder, actions } from '@comall-backend-builder/core';
import { ComponentFactory } from '@comall-backend-builder/core/lib/parser/component';
import { services } from '@comall-backend-builder/core';

const language = services.language;

let SELECTED_ROW: Array<{ id: number; name: string }> = [];

const getCategoryNavConfig = (componentName: string) => {
    return {
        component: 'Card',
        componentId: componentName,
        entity: 'CustomDesignCategoryNavSelector',
        content: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'FilterForm',
                    componentId: componentName + '-FilterForm',
                    direction: 'inline',
                    fields: [
                        {
                            property: 'name',
                        },
                    ],
                    submit: {
                        icon: 'search',
                        text: '',
                    },
                    style: {
                        marginBottom: 16,
                        float: 'right',
                    },
                },
                {
                    component: 'DataTable',
                    componentId: componentName + '-table',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                    },
                    columns: [
                        {
                            property: 'name',
                        },
                        {
                            property: 'id',
                        },
                        {
                            property: 'level',
                        },
                    ],
                },
            ],
        },
    };
};

function initDynamicCategoryNavComponent(
    contentConfig: any,
    componentName: any,
    subsiteIds?: string[]
) {
    let config = defaultsDeep(contentConfig, getCategoryNavConfig(componentName));
    set(config, 'params.subsiteIds', subsiteIds);
    set(config, 'content.items[1].componentId', componentName + '-table');
    set(config, 'content.items[1].rowSelection', {
        type: 'radio',
        onChange: () => {},
        onSelect: function() {
            SELECTED_ROW = arguments[2];
        },
    });
    return ComponentFactory(componentName, config);
}

function generateDynamicCategoryNavComponentName() {
    return 'DesignCategoryNavSelector-' + parseInt(Math.random() * 1e8 + '').toString(16);
}

/**
 * 选择分类
 */
export class SelectCategoryNav extends PureComponent<{
    contentConfig?: Record<string, any>;
    modalConfig?: Record<string, any>;
    showModal: boolean;
    subsiteIds?: string[];
    onChange: (data: any) => void;
    onCancel: () => void;
}> {
    categoryNavComponentName = '';
    renderCategoryNavComponent: any = null;
    dispatch: any = null;

    constructor(props: any) {
        super(props);
        const { contentConfig, subsiteIds } = props;
        this.categoryNavComponentName = generateDynamicCategoryNavComponentName();
        const dynamicCategoryNavComponent = initDynamicCategoryNavComponent(
            contentConfig,
            this.categoryNavComponentName,
            subsiteIds
        );

        this.renderCategoryNavComponent = createElement(dynamicCategoryNavComponent);
        this.dispatch = builder.getStore().dispatch;
    }
    componentWillReceiveProps(nextProps: any) {
        const { subsiteIds } = this.props;
        if (nextProps?.subsiteIds?.join(',') !== subsiteIds?.join(',')) {
            const { contentConfig, subsiteIds } = nextProps;
            this.categoryNavComponentName = generateDynamicCategoryNavComponentName();
            const dynamicCategoryNavComponent = initDynamicCategoryNavComponent(
                contentConfig,
                this.categoryNavComponentName,
                subsiteIds
            );
            this.renderCategoryNavComponent = createElement(dynamicCategoryNavComponent);
        }
    }
    onOk = () => {
        if (!SELECTED_ROW.length) {
            AntMessage.error(language.getText('components.Tools.categoryNavSelectRequired'));
            return;
        }

        const { onChange } = this.props;

        onChange({
            id: SELECTED_ROW[0].id,
            name: SELECTED_ROW[0].name,
        });
        builder
            .getStore()
            .dispatch(
                actions.tableRowSelectionChangeAction(this.categoryNavComponentName + '-table', [])
            );
    };

    onCancel = () => {
        const { onCancel } = this.props;
        onCancel();
    };

    render() {
        const { showModal, modalConfig = {} } = this.props;
        const props = {};
        assign(
            props,
            {
                width: 800,
                visible: showModal,
                title: language.getText('xzfldh'),
                onCancel: this.onCancel,
                onOk: this.onOk,
            },
            modalConfig
        );
        return <Modal {...props}>{this.renderCategoryNavComponent}</Modal>;
    }
}
