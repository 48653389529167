import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { find } from 'lodash';
import {
    CardCodeType,
    memberPricePathOptions,
    pointJumpPathOptions,
    pointSupportPathOptions,
    invoiceJumpPathOptions,
    FavorModuleTitle,
    MemberPriceTitle,
} from '../config/member/member-info/card-level-manage';
import { getUrlParam } from '../applications/cae/services/utils';

const api = services.api;

export const ChannelCardTemplatesLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`;
        config.apiPath = `/admin/channel_card_templates/${data.id}`;
        let res: any = await api.get({}, config);
        return transformRes(res);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`;
        config.apiPath = `/admin/channel_card_templates/${id}`;
        const newParams: any = dataParser(params);
        return await api.put(newParams, config);
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`;
        config.apiPath = `/admin/channel_card_templates`;
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
};

export const ChannelWechatOfficialAccountsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/wechat_official_accounts?page=1&per_page=1000`;
        let res: any = await api.get(data, config);
        return (res.result || []).map((item: any) => ({
            id: item.appId,
            name: item.name,
        }));
    },
};

function transformRes(res: any) {
    const {
        status,
        appId,
        brandId,
        brandName,
        logoPicUrl,
        title,
        needDisplayLevel,
        codeMode,
        codeType,
        backgroundPicUrl,
        servicePhone,
        description,
        commonFieldList,
        showFavor,
        favorModuleTitle,
        memberPriceTitle,
        memberPriceWord,
        memberPricePath,
        showPoint,
        pointValueWord,
        pointCostWord,
        pointJumpWord,
        pointJumpPath,
        pointSupportPath,
        showInvoice,
        invoiceJumpWord,
        invoiceJumpPath,
        channelLogoPicUrl,
        channelBackgroundPicUrl,
        levelName,
        mchId,
        memberCardNotificationUrl,
    } = res;
    const newRes: any = {};
    newRes.baseInfo = {
        status,
        mchId,
        appId,
        brandId,
        brandName,
        memberCardNotificationUrl,
        freezeBrandId: brandId,
        freezeBrandName: brandName,
    };
    const logoPic = logoPicUrl
        ? [
              {
                  path: logoPicUrl,
                  id: -1,
              },
          ]
        : undefined;
    const backgroundPic = backgroundPicUrl
        ? [
              {
                  path: backgroundPicUrl,
                  id: -1,
              },
          ]
        : undefined;
    newRes.contentInfo = {
        logoPic,
        title,
        needDisplayLevel: needDisplayLevel ? 'true' : 'false',
        codeMode,
        showCodeType: codeType !== CardCodeType.NoneCode ? 'true' : 'false',
        codeType: codeType !== CardCodeType.NoneCode ? codeType : CardCodeType.BarCode,
        backgroundPic,
        servicePhone,
        description,
        commonFieldList: commonFieldList || [],
        channelLogoPicUrl,
        channelBackgroundPicUrl,
        oldLogoPic: logoPicUrl,
        oldBackgroundPic: backgroundPicUrl,
        levelName,
    };
    const memberPriceId = memberPricePath
        ? find(memberPricePathOptions, { basePath: memberPricePath })?.id
        : undefined;
    newRes.discountsInfo = {
        showFavor: showFavor ? 'true' : 'false',
        favorModuleTitle: showFavor ? favorModuleTitle : FavorModuleTitle.Favor,
        memberPriceTitle: showFavor ? memberPriceTitle : MemberPriceTitle.MemberActivity,
        memberPriceWord,
        memberPricePath: memberPriceId,
    };
    const pointJumpId = pointJumpPath
        ? find(pointJumpPathOptions, { basePath: pointJumpPath })?.id
        : undefined;
    const pointSupportId = pointSupportPath
        ? find(pointSupportPathOptions, { basePath: pointSupportPath })?.id
        : undefined;
    newRes.pointInfo = {
        showPoint: showPoint ? 'true' : 'false',
        pointValueWord,
        pointCostWord,
        pointJumpWord,
        pointJumpPath: pointJumpId,
        pointSupportPath: pointSupportId,
    };
    const invoiceJumpId = invoiceJumpPath
        ? find(invoiceJumpPathOptions, { basePath: invoiceJumpPath })?.id
        : undefined;
    newRes.invoiceInfo = {
        showInvoice: showInvoice ? 'true' : 'false',
        invoiceJumpWord,
        invoiceJumpPath: invoiceJumpId,
    };
    return newRes;
}
function dataParser(params: any) {
    const levelPlanId = getUrlParam(window.location.hash, 'levelPlanId') || '';
    const levelPlanItemId = getUrlParam(window.location.hash, 'levelPlanItemId') || '';
    const levelName = getUrlParam(window.location.hash, 'levelName') || '';
    const newParams: any = {
        channel: 'WECHAT',
        levelPlanId,
        levelPlanItemId,
        levelName,
    };
    if (params.baseInfo) {
        const { status, appId, brandId, brandName, mchId } = params.baseInfo;
        newParams.status = status;
        newParams.appId = appId;
        newParams.brandId = brandId;
        newParams.brandName = brandName;
        newParams.mchId = mchId;
    }
    if (params.contentInfo) {
        const {
            logoPic,
            title,
            needDisplayLevel,
            codeMode,
            showCodeType,
            codeType,
            backgroundPic,
            servicePhone,
            description,
            commonFieldList,
            oldLogoPic,
            channelLogoPicUrl,
            oldBackgroundPic,
            channelBackgroundPicUrl,
        } = params.contentInfo;
        const logoPicUrl = logoPic && logoPic.length ? logoPic[0].path : '';
        newParams.logoPicUrl = logoPicUrl;
        if (oldLogoPic && oldLogoPic !== logoPicUrl) {
            newParams.channelLogoPicUrl = '';
        } else {
            newParams.channelLogoPicUrl = channelLogoPicUrl;
        }
        newParams.title = title;
        newParams.needDisplayLevel = needDisplayLevel === 'true';
        newParams.codeMode = codeMode;
        newParams.codeType = showCodeType === 'true' ? codeType : CardCodeType.NoneCode;
        const backgroundPicUrl = backgroundPic && backgroundPic.length ? backgroundPic[0].path : '';
        newParams.backgroundPicUrl = backgroundPicUrl;
        if (oldBackgroundPic && oldBackgroundPic !== backgroundPicUrl) {
            newParams.channelBackgroundPicUrl = '';
        } else {
            newParams.channelBackgroundPicUrl = channelBackgroundPicUrl;
        }
        newParams.servicePhone = servicePhone;
        newParams.description = description;
        newParams.commonFieldList = commonFieldList;
    }
    if (params.discountsInfo) {
        const {
            showFavor,
            favorModuleTitle,
            memberPriceTitle,
            memberPriceWord,
            memberPricePath,
        } = params.discountsInfo;
        if (showFavor === 'true') {
            newParams.showFavor = true;
            newParams.favorModuleTitle = favorModuleTitle;
            newParams.memberPriceTitle = memberPriceTitle;
            newParams.memberPriceWord = memberPriceWord;
            if (memberPricePath) {
                const currentRoute: any = find(memberPricePathOptions, { id: memberPricePath });
                newParams.memberPricePath = currentRoute?.basePath;
            }
        } else {
            newParams.showFavor = false;
            newParams.favorModuleTitle = undefined;
            newParams.memberPriceTitle = undefined;
            newParams.memberPriceWord = undefined;
            newParams.memberPricePath = undefined;
        }
    }
    if (params.pointInfo) {
        const {
            showPoint,
            pointValueWord,
            pointCostWord,
            pointJumpWord,
            pointJumpPath,
            pointSupportPath,
        } = params.pointInfo;
        if (showPoint === 'true') {
            newParams.showPoint = true;
            newParams.pointValueWord = pointValueWord;
            newParams.pointCostWord = pointCostWord;
            newParams.pointJumpWord = pointJumpWord;
            if (pointJumpPath) {
                const currentRoute: any = find(pointJumpPathOptions, { id: pointJumpPath });
                newParams.pointJumpPath = currentRoute?.basePath;
            }
            if (pointSupportPath) {
                const currentRoute: any = find(pointSupportPathOptions, { id: pointSupportPath });
                newParams.pointSupportPath = currentRoute?.basePath;
            }
        } else {
            newParams.showPoint = false;
            newParams.pointValueWord = undefined;
            newParams.pointCostWord = undefined;
            newParams.pointJumpWord = undefined;
            newParams.pointJumpPath = undefined;
            newParams.pointSupportPath = undefined;
        }
    }
    if (params.invoiceInfo) {
        const { showInvoice, invoiceJumpWord, invoiceJumpPath } = params.invoiceInfo;
        if (showInvoice === 'true') {
            newParams.showInvoice = true;
            newParams.invoiceJumpWord = invoiceJumpWord;
            if (invoiceJumpPath) {
                const currentRoute: any = find(invoiceJumpPathOptions, { id: invoiceJumpPath });
                newParams.invoiceJumpPath = currentRoute?.basePath;
            }
        } else {
            newParams.showInvoice = false;
            newParams.invoiceJumpWord = undefined;
            newParams.invoiceJumpPath = undefined;
        }
    }
    return newParams;
}
