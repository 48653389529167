import React, { PureComponent } from 'react';
import { Checkbox, Row, Col } from 'antd';
import { services } from '@comall-backend-builder/core';
import { tools } from '@comall-backend-builder/design-components-basis';

const language = services.language;
const ColorPicker = tools.ColorPicker;
export class FlashShowContent extends PureComponent<any> {
    select = (showContent: any) => {
        const { onChange } = this.props;
        onChange(showContent);
    };

    render() {
        const { value, form } = this.props;
        const { getFieldDecorator } = form;
        return (
            <Checkbox.Group value={value} style={{ width: '100%' }} onChange={this.select}>
                <Row className="show-content">
                    <Col span={24} className="show-content-item">
                        <Checkbox value="title">
                            {language.getText('components.FlashProducts.productName')}
                        </Checkbox>
                        {getFieldDecorator('showContentColor.title')(<ColorPicker />)}
                    </Col>
                    <Col span={24} className="show-content-item">
                        <Checkbox value="salePrice">
                            {language.getText('components.FlashProducts.salePrice')}
                        </Checkbox>
                        {getFieldDecorator('showContentColor.salePrice')(<ColorPicker />)}
                    </Col>
                    <Col span={24} className="show-content-item">
                        <Checkbox value="marketPrice">
                            {language.getText('components.FlashProducts.marketPrice')}
                        </Checkbox>
                        {getFieldDecorator('showContentColor.marketPrice')(<ColorPicker />)}
                    </Col>
                    {ENV.TENANT === 'jml' ? (
                        <Col span={24} className="show-content-item">
                            <Checkbox value="countDown">
                                {language.getText('components.FlashProducts.countDown')}
                            </Checkbox>
                        </Col>
                    ) : null}
                </Row>
            </Checkbox.Group>
        );
    }
}
