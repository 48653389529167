import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { Form, Select, Button, Table, Modal, message } from 'antd';
import PropTypes from 'prop-types';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const { api, navigation, privilege } = services;

interface Props {
    memberId: string;
}
interface State {
    subsites: any[];
    searchParams: any;
    datasource: any[];
    loading: boolean;
}

const CollectionCreateForm = Form.create({ name: 'searchForm' })(
    class extends React.Component {
        static propTypes: {
            onSearch: PropTypes.Requireable<(...args: any[]) => any>;
            subsites: [];
        };
        handleSearch = (e: any) => {
            e.preventDefault();
            const { form, onSearch } = this.props as any;
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSearch(values);
                }
            });
        };
        handleReset = () => {
            const { form } = this.props as any;
            form.resetFields();
        };
        render() {
            const { form, subsites } = this.props as any;
            const { getFieldDecorator } = form;
            const defaultValue = subsites && subsites.length > 0 ? subsites[0].id : undefined;
            return (
                <Form layout="inline">
                    <Form.Item label={language.getText('subsites')}>
                        {getFieldDecorator('subsiteId', {
                            initialValue: defaultValue,
                        })(
                            <Select
                                style={{ width: '200px' }}
                                showSearch
                                placeholder={services.language.getText('selectPlease')}
                                optionFilterProp="name"
                                filterOption={(input, option: any) =>
                                    (option.props.children || '')
                                        .toLowerCase()
                                        .indexOf(input.toLowerCase()) >= 0
                                }
                            >
                                {subsites &&
                                    subsites.map((subsite: any) => {
                                        return (
                                            <Select.Option key={subsite.id} value={subsite.id}>
                                                {subsite.name}
                                            </Select.Option>
                                        );
                                    })}
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item style={{ marginLeft: 'auto' }}>
                        <Button type="primary" shape="round" onClick={this.handleSearch}>
                            {language.getText('common.search')}
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="reset" shape="round" onClick={this.handleReset}>
                            {language.getText('common.reset')}
                        </Button>
                    </Form.Item>
                </Form>
            );
        }
    }
);

export default class MemberInfoTagsPage extends PureComponent<Props, State> {
    state: State = {
        subsites: [],
        searchParams: {},
        datasource: [],
        loading: true,
    };

    componentDidMount() {
        this.getSubsites();
    }

    private getSubsites = async () => {
        const subsites: any[] = await api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: '/WEB-API/admin/defaults/subsite',
            }
        );
        const defaultSubsite =
            subsites && (subsites as any[]).length > 0 ? (subsites as any[])[0] : undefined;
        this.setState(
            {
                subsites,
                searchParams: {
                    subsiteId: defaultSubsite.id,
                },
            },
            () => {
                this.onSearchData();
            }
        );
    };

    private onChangeParams = (searchParams: any) => {
        this.setState(
            {
                searchParams,
            },
            () => {
                this.onSearchData();
            }
        );
    };

    private onSearchData = async () => {
        try {
            const { memberId } = this.props;
            const { searchParams } = this.state;
            this.setState({
                loading: true,
            });
            const { subsiteId } = searchParams || {};
            const params: any = {
                memberId,
                subsiteId,
            };
            const res: any = await api.get(params, {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                apiPath: `/admin/member_tags`,
            });
            this.setState({
                datasource: res,
                loading: false,
            });
        } catch (error) {
            services.errorHandle(error as any);
        }
    };

    private renderFilter = () => {
        const classes = {
            filter: `${prefix}__filter`,
        };
        const { subsites } = this.state;
        return (
            <div className={classes.filter}>
                <CollectionCreateForm onSearch={this.onChangeParams} subsites={subsites} />
            </div>
        );
    };

    private onDeleteTag = (tagId: string) => {
        const { memberId } = this.props;
        Modal.confirm({
            title: '',
            content: language.getText('confirmDelete'),
            onOk: async () => {
                try {
                    await api.delete(
                        {},
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                            apiPath: `/admin/member_tags?member_id=${memberId}&tag_id=${tagId}`,
                        }
                    );
                    this.onSearchData();
                } catch (error) {
                    services.errorHandle(error as any);
                }
            },
            onCancel() {},
        });
    };

    private onGotoDetail = async (item: any) => {
        if (privilege.check('memberv2Tag', 'full')) {
            navigation.goto(`/label-manage/edit/${item.tagId}`);
        } else {
            message.warning(language.getText('qlxglypz'));
        }
    };

    private renderTable = () => {
        const { datasource, loading } = this.state;
        return (
            <div className={`${prefix}__table`}>
                <Table
                    rowClassName={(_, index: number) => {
                        if (index % 2) {
                            return `${prefix}__table__bg`;
                        }
                        return '';
                    }}
                    loading={loading}
                    rowKey={(record: any, index: number) => `${prefix}-${index}`}
                    pagination={false}
                    columns={[
                        {
                            title: language.getText('tagName'),
                            dataIndex: 'tagName',
                        },
                        {
                            title: language.getText('dbsj'),
                            dataIndex: 'createTime',
                        },
                        {
                            title: language.getText('dbcl'),
                            dataIndex: 'tagStrategy',
                        },
                        {
                            title: services.language.getText('lastModifiedUser'),
                            dataIndex: 'creator',
                        },
                        {
                            title: services.language.getText('common.tableAction'),
                            dataIndex: 'tagId',
                            className: 'action-column',
                            render: (tagId, row) => {
                                const disabled = row.tagStrategy !== language.getText('sddb');
                                return (
                                    <div>
                                        <Button
                                            className="table-action-column-item"
                                            type="link"
                                            onClick={this.onGotoDetail.bind(this, row)}
                                        >
                                            {language.getText('common.view')}
                                        </Button>
                                        <Button
                                            className="table-action-column-item"
                                            type="link"
                                            disabled={disabled}
                                            onClick={this.onDeleteTag.bind(this, tagId)}
                                        >
                                            {services.language.getText('common.delete')}
                                        </Button>
                                    </div>
                                );
                            },
                        },
                    ]}
                    dataSource={datasource}
                />
            </div>
        );
    };

    render() {
        return (
            <div className={prefix}>
                {this.renderFilter()}
                {this.renderTable()}
            </div>
        );
    }
}

const prefix = 'member-info-tags-page';
