import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { Button, Modal, message as AntMessage, Tabs } from 'antd';
import { ColorPickerPlus } from '../../../components';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
const api = services.api;
const defaultCardDiscountTag = {
    cardNameBackgroundColor: '#1c2566',
    cardNameWordColor: '#f9d96a',
    memberDiscountBackgroundColor: '#f9d96a',
    memberDiscountWordColor: '#1c2566',
};

export class MemberCardDiscountLevelStyles extends PureComponent<any, any> {
    state = {
        visible: false,
        levelId: '',
        levelList: [],
        cardDiscountTag: defaultCardDiscountTag,
    };

    componentDidMount() {
        this.loadLevelList();
    }

    loadLevelList = async () => {
        try {
            const res: any[] = await api
                .get(
                    {},
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
                        apiPath: '/admin/member_card_discount_level_styles',
                    }
                )
                .then((r: any) => {
                    return r.map((i: any) => {
                        i.id = i.id.toString();
                        return i;
                    });
                });
            if (res && res.length > 0) {
                const [item] = res;
                this.setState(
                    {
                        levelList: res,
                        levelId: item.id,
                    },
                    () => {
                        this.loadData();
                    }
                );
            }
        } catch (error) {
            services.errorHandle(error as any);
        }
    };

    loadData = async () => {
        const { levelId } = this.state;
        const res: any[] = await api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
                apiPath: `/admin/member_card_discount_level_styles/${levelId}`,
            }
        );
        this.setState({
            cardDiscountTag: res || defaultCardDiscountTag,
        });
    };

    onSubmit = () => {
        const { levelId, cardDiscountTag } = this.state;

        api.put(cardDiscountTag, {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`,
            apiPath: `/admin/member_card_discount_level_styles/${levelId}`,
        }).then(
            () => {
                AntMessage.success(services.language.getText('settingSucess'));
                //设置成功后重新获取数据
                this.loadData();
                this.toggleModal();
            },
            (error) => {
                services.errorHandle(error);
            }
        );
    };
    onChange = (
        color: string,
        type:
            | 'cardNameBackgroundColor'
            | 'cardNameWordColor'
            | 'memberDiscountBackgroundColor'
            | 'memberDiscountWordColor'
    ) => {
        const { cardDiscountTag } = this.state;
        const newValue = Object.assign({}, cardDiscountTag);
        newValue[type] = color;
        this.setState({
            cardDiscountTag: newValue,
        });
    };

    renderModal = () => {
        const { cardDiscountTag } = this.state;
        return (
            <div className="member-card-discount-level-styles-tag">
                <div>{language.getText('szdjzktb')}：</div>
                <div className="member-card-discount-tag">
                    <div className="row">
                        <div className="title">{language.getText('kmcbjs')}：</div>
                        <div className="content">
                            <ColorPickerPlus
                                {...({
                                    onChange: (v: string) => {
                                        this.onChange(v, 'cardNameBackgroundColor');
                                    },
                                    value: cardDiscountTag.cardNameBackgroundColor,
                                } as any)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">{language.getText('kmcwzs')}：</div>
                        <div className="content">
                            <ColorPickerPlus
                                {...({
                                    onChange: (v: string) => {
                                        this.onChange(v, 'cardNameWordColor');
                                    },
                                    value: cardDiscountTag.cardNameWordColor,
                                } as any)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">{language.getText('zkjgbjs')}：</div>
                        <div className="content">
                            <ColorPickerPlus
                                {...({
                                    onChange: (v: string) => {
                                        this.onChange(v, 'memberDiscountBackgroundColor');
                                    },
                                    value: cardDiscountTag.memberDiscountBackgroundColor,
                                } as any)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">{language.getText('zkjgwzs')}：</div>
                        <div className="content">
                            <ColorPickerPlus
                                {...({
                                    onChange: (v: string) => {
                                        this.onChange(v, 'memberDiscountWordColor');
                                    },
                                    value: cardDiscountTag.memberDiscountWordColor,
                                } as any)}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="title">{services.language.getText('ysyl')}：</div>
                        <div
                            className="preview"
                            style={{
                                backgroundColor: cardDiscountTag.memberDiscountBackgroundColor,
                            }}
                        >
                            <div
                                className="card-name"
                                style={{
                                    backgroundColor: cardDiscountTag.cardNameBackgroundColor,
                                    color: cardDiscountTag.cardNameWordColor,
                                }}
                            >
                                {language.getText('blackCardDiscount')}
                            </div>
                            <div
                                className="discount"
                                style={{ color: cardDiscountTag.memberDiscountWordColor }}
                            >
                                {services.language.getText('monetaryUnit')}95.00
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    toggleModal = () => {
        const { visible } = this.state;

        setTimeout(() => {
            this.setState({
                visible: !visible,
            });
        }, 100);
    };

    changeLevelId = (id: string) => {
        this.setState({ levelId: id }, () => {
            this.loadData();
        });
    };

    render() {
        const { visible, levelId, levelList } = this.state;
        const modalProps = {
            width: 950,
            title: language.getText('szdjzktb'),
            visible: this.state.visible,
            onCancel: this.toggleModal,
            onOk: this.onSubmit,
        };
        return (
            <div className={prefix} style={{ display: 'inline-block' }}>
                <Button style={{ margin: '16px 0 24px 10px' }} onClick={this.toggleModal}>
                    {language.getText('szdjzktb')}
                </Button>
                {visible ? (
                    <Modal {...modalProps}>
                        <Tabs activeKey={levelId} onTabClick={this.changeLevelId} animated={false}>
                            {levelList.map((item: any) => {
                                return <Tabs.TabPane tab={item.name} key={item.id}></Tabs.TabPane>;
                            })}
                        </Tabs>
                        <div className={`${prefix}-modal-form`}>{this.renderModal()}</div>
                    </Modal>
                ) : null}
            </div>
        );
    }
}

const prefix = 'member-card-discount-level-styles';
