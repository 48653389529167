import React, { PureComponent } from 'react';
import { Input, Form } from 'antd';
import { tools } from '@comall-backend-builder/design-components-basis';
import { ServiceItem } from './service-item';
import './index.less';

const FormItem = Form.Item;
const EntryList = tools.EntryList;

/**
 * 会员服务组件-配置表单
 */
class memberService extends PureComponent<any> {
    WrapImageAdItem = (props: any) => {
        const { uploadAction, selector, sourceRoot } = this.props;
        return (
            <ServiceItem
                {...props}
                sourceRoot={sourceRoot}
                selector={selector}
                uploadAction={uploadAction}
            />
        );
    };

    render() {
        const { form, linkTypes } = this.props;

        const { getFieldDecorator } = form;
        const formItemLayout = tools.FormItemLayout;
        return (
            <Form className="editor-member-service">
                <Form className="editor-member-service">
                    <FormItem {...formItemLayout} label={'菜单名称'}>
                        <div>
                            {getFieldDecorator(
                                'title',
                                {}
                            )(<Input maxLength={10} style={{ width: 190 }} />)}
                        </div>
                    </FormItem>
                    <FormItem {...formItemLayout} label={'配置项'}>
                        {getFieldDecorator('entry', {
                            initialValue: [],
                        })(
                            <EntryList
                                linkTypes={linkTypes}
                                maxCount={20}
                                renderItem={this.WrapImageAdItem}
                                renderTips={() => (
                                    <div>选中配置项才会展示该项，长按点击配置项可拖拽调整顺序</div>
                                )}
                            />
                        )}
                    </FormItem>
                </Form>
            </Form>
        );
    }
}

export const MemberService = tools.ConfigFormDecorator(memberService);
