import React, { PureComponent } from 'react';
import { Table, Button } from 'antd';
import { cloneDeep, remove } from 'lodash';
import { services } from '@comall-backend-builder/core';
import './index.less';
import { ColumnProps } from 'antd/lib/table';
import { language } from '@comall-backend-builder/core/lib/services';
import { MarketingSchedule } from './marketing-schedule-selector';

interface ShopSelectedDisplayProps<T> {
    data: Array<MarketingSchedule>;
    onChange?: (data: Array<MarketingSchedule>) => void;
    /**
     * 档期删除事件
     */
    onRemove?: (data: Array<MarketingSchedule>) => void;
    /**
     * 是否可编辑
     */
    disabled?: boolean;
    columns?: ColumnProps<T>[];
    oldDataDeleteable?: boolean;
}
interface ShopSelectedDisplayState {
    selectedRows: Array<MarketingSchedule>;
}

/**
 * 营销档期展示和内容编辑
 */
class MarketingScheduleDisplay<T> extends PureComponent<
    ShopSelectedDisplayProps<T>,
    ShopSelectedDisplayState
> {
    static defaultProps = {
        oldDataDeleteable: true,
    };
    initSelectRowIds =
        this.props.data &&
        this.props.data.filter((data) => data.select === undefined).map(({ id }) => id);
    getTableConfig = () => {
        const { columns } = this.props;

        let newColumns =
            columns && columns.length
                ? [...columns]
                : [
                      {
                          title: services.language.getText('subsiteName'),
                          dataIndex: 'subsiteName',
                          key: 'subsiteName',
                      },
                      {
                          title: services.language.getText('yxdq'),
                          dataIndex: 'name',
                          key: 'name',
                      },
                      {
                          title: language.getText('dqsj'),
                          dataIndex: 'time',
                          key: 'time',
                      },
                      {
                          title: language.getText('thirdId'),
                          dataIndex: 'thirdId',
                          key: 'thirdId',
                      },
                  ];

        const operation = {
            title: language.getText('common.tableAction'),
            className: 'action-column',
            key: 'operation',
            width: 100,
            render: (i: any, row: any) => {
                const { oldDataDeleteable, disabled } = this.props;
                const deleteable = oldDataDeleteable || !this.initSelectRowIds.includes(row.id);
                const deleteTxt = !disabled ? (
                    <Button type={'link'} disabled={!deleteable} onClick={this.onRemove(row)}>
                        {language.getText('common.delete')}
                    </Button>
                ) : null;
                return deleteTxt;
            },
        };

        let config: any = {
            rowKey: 'id',
            columns: [...newColumns, operation],
            style: {
                marginTop: 20,
            },
        };
        return config;
    };

    onRemove = (row: MarketingSchedule) => {
        const { data, onRemove } = this.props;

        return () => {
            const result = cloneDeep(data);
            remove(result, (shop) => row.id === shop.id);
            if (onRemove) {
                onRemove(result);
            }
        };
    };

    render() {
        const { data } = this.props;
        if (data && !data.length) {
            return <div>{language.getText('notData')}</div>;
        }
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: data,
        };
        return <Table {...tableProps} pagination={false}></Table>;
    }
}

export { MarketingScheduleDisplay };
