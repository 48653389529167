import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { find, forEach } from 'lodash';

const api = services.api;
export const OpenBoxLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/open_box_activities';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/open_box_activities/${id}`;
        } else {
            data.eventType = 'OPEN_BOX';
            if (data.dateRange) {
                data.startTime = data.dateRange.start;
                data.endTime = data.dateRange.end;
                data.dateRange = undefined;
            }
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.id = res.activityVo.id;
                res.editExecuteStatus = res.activityVo.executeStatus;
                res.shareConfigId = res.activityVo.shareConfig.id;

                const normalConditions = res.activityVo.normalConditions;
                const exchangeConditions = res.activityVo.exchangeConditions;
                const subsiteConditions: any = find(normalConditions, {
                    normalConditionType: 'SUB_SITE',
                });
                const excludeTypeActivityConditions: any = find(normalConditions, {
                    normalConditionType: 'EXCLUDE_MARKETING_ACTIVITY',
                });
                const rewardConditions: any = find(exchangeConditions, {
                    exchangeMode: 'GIVE',
                });
                const additionalConditions: any = find(exchangeConditions, {
                    exchangeMode: 'EXTRA_GAIN',
                });
                const shareConditions: any = exchangeConditions.filter(
                    (condition: any) => condition.exchangeMode === 'SHARE_ASSIST'
                );
                let excludeType = 'NONE';
                if (excludeTypeActivityConditions) {
                    if (excludeTypeActivityConditions.all) {
                        excludeType = 'ALL';
                    } else {
                        excludeType = 'SOME';
                    }
                }
                const inviteFriends: any[] = [];
                if (shareConditions && shareConditions.length > 0) {
                    forEach(shareConditions, (condition: any) => {
                        inviteFriends.push({
                            rewardValue: condition.exchangeValue,
                            inviteValue: condition.consumeValue,
                        });
                    });
                }
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : services.language.getText('longTerm'),
                    };
                }
                res.baseInfo = {
                    name: res.activityVo.name,
                    dateRange: {
                        start: res.activityVo.startTime,
                        end: res.activityVo.endTime,
                    },
                    subsiteIds: subsiteConditions ? subsiteConditions.includeIds : undefined,
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };
                const rewardConfig = res.activityVo.rewardConfig;
                res.ruleInfo = {
                    activityRewardLimit: rewardConditions.exchangeLimit,
                    inviteFriends: inviteFriends,
                    excludeTypeActivity: {
                        type: excludeType,
                        activityList: excludeTypeActivityConditions
                            ? excludeTypeActivityConditions.includeIds
                            : [],
                    },
                    activityDrawLimit: additionalConditions
                        ? {
                              ...additionalConditions,
                              scopeId: 1,
                          }
                        : undefined,
                    periodLimit: {
                        list:
                            rewardConfig?.activityRewardLimit ||
                            rewardConfig?.activityRewardLeastLimit
                                ? [
                                      {
                                          limit: rewardConfig.activityRewardLimit
                                              ? parseInt(rewardConfig.activityRewardLimit)
                                              : '',
                                          leastLimit: rewardConfig.activityRewardLeastLimit
                                              ? String(rewardConfig.activityRewardLeastLimit)
                                              : '',
                                      },
                                  ]
                                : [],
                    },
                };
                const pageConfigJson = JSON.parse(res.lottery.pageConfig);
                res.pictureInfo = {
                    activityPicture: [
                        {
                            id: pageConfigJson.activityPicture.id || '',
                            path: pageConfigJson.activityPicture.url || '',
                        },
                    ],
                    rulePictureIcon: [
                        {
                            id: pageConfigJson.rulePictureIcon.id || '',
                            path: pageConfigJson.rulePictureIcon.url || '',
                        },
                    ],
                    rulePicture: [
                        {
                            id: pageConfigJson.rulePicture.id || '',
                            path: pageConfigJson.rulePicture.url || '',
                        },
                    ],
                    backgroundPicture: [
                        {
                            id: pageConfigJson.backgroundPicture.id || '',
                            path: pageConfigJson.backgroundPicture.url || '',
                        },
                    ],
                    giftBoxPicture: [
                        {
                            id: pageConfigJson.giftBoxPicture.id || '',
                            path: pageConfigJson.giftBoxPicture.url || '',
                        },
                    ],
                    giftBoxCartoonPicture: [
                        {
                            id: pageConfigJson.giftBoxCartoonPicture.id || '',
                            path: pageConfigJson.giftBoxCartoonPicture.url || '',
                        },
                    ],
                    tipColor: pageConfigJson.tipColor,
                    buttonBackgroundStyle: pageConfigJson.buttonBackgroundStyle,
                    buttonBackgroundColor:
                        pageConfigJson.buttonBackgroundStyle === 'NONE'
                            ? pageConfigJson.buttonBackgroundColor
                            : '#fff',
                    buttonBackgroundPicture:
                        pageConfigJson.buttonBackgroundStyle === 'BACKGROUND_URL'
                            ? [
                                  {
                                      id: pageConfigJson.buttonBackgroundPicture.id || '',
                                      path: pageConfigJson.buttonBackgroundPicture.url || '',
                                  },
                              ]
                            : undefined,
                    buttonColor: pageConfigJson.buttonColor,
                    sharePicture: pageConfigJson.sharePicture
                        ? [
                              {
                                  id: pageConfigJson.sharePicture.id || '',
                                  path: pageConfigJson.sharePicture.url || '',
                              },
                          ]
                        : undefined,
                    shareContent: res.activityVo.shareConfig.shareContent,
                    winningRecordPicture: pageConfigJson.winningRecordPicture
                        ? [
                              {
                                  id: pageConfigJson.winningRecordPicture.id || '',
                                  path: pageConfigJson.winningRecordPicture.url || '',
                              },
                          ]
                        : undefined,
                    moreActivityPicture: pageConfigJson.moreActivityPicture
                        ? [
                              {
                                  id: pageConfigJson.moreActivityPicture.id || '',
                                  path: pageConfigJson.moreActivityPicture.url || '',
                              },
                          ]
                        : undefined,
                    audioUrl:
                        pageConfigJson.audioUrl && pageConfigJson.audioUrl?.id
                            ? [
                                  {
                                      id: pageConfigJson.audioUrl?.id || '',
                                      path: pageConfigJson.audioUrl?.url || '',
                                  },
                              ]
                            : undefined,
                    audioOpenPicture: pageConfigJson.audioOpenPicture
                        ? [
                              {
                                  id: pageConfigJson.audioOpenPicture?.id || '',
                                  path: pageConfigJson.audioOpenPicture?.url || '',
                              },
                          ]
                        : [
                              {
                                  id: '0',
                                  path:
                                      'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/open-box/audio_open.png',
                              },
                          ],
                    audioClosePicture: pageConfigJson.audioClosePicture
                        ? [
                              {
                                  id: pageConfigJson.audioClosePicture?.id || '',
                                  path: pageConfigJson.audioClosePicture?.url || '',
                              },
                          ]
                        : [
                              {
                                  id: '0',
                                  path:
                                      'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/open-box/audio_close.png',
                              },
                          ],
                    target: pageConfigJson.target,
                };
                const assetRewards = [];
                if (
                    res.activityVo.rewardConfig.activityRewards &&
                    res.activityVo.rewardConfig.activityRewards.length > 0
                ) {
                    const activityRewardList = res.activityVo.rewardConfig.activityRewards;
                    // 是否是复制
                    const isCopy = window.location.href.includes('/copy');
                    for (let i = 0; i < activityRewardList.length; i++) {
                        const activityReward = activityRewardList[i];
                        for (let j = 0; j < activityReward.couponRewards.length; j++) {
                            const coupon = activityReward.couponRewards[j];
                            const couponReward: any = coupon;
                            if (isCopy) {
                                couponReward.issuedNum = 0;
                                couponReward.issuedCount = 0;
                                couponReward.inventory = couponReward.quantity || 0;
                                delete couponReward.id;
                            }

                            couponReward.conditionValue = activityReward.conditionValue;
                            couponReward.quantityType = coupon.quantity ? 'YES' : 'NO';
                            couponReward.rewardPeriod.memberRewardLimitType = coupon.rewardPeriod
                                .memberRewardLimit
                                ? 'YES'
                                : 'NO';
                            couponReward.rewardPeriod.periodQuantityType = coupon.rewardPeriod
                                .periodQuantity
                                ? 'YES'
                                : 'NO';
                            couponReward.picture = {
                                id: coupon.picture.id || '',
                                path: coupon.picture.url || '',
                            };
                            couponReward.type = {
                                checked: 'COUPON',
                                assetRewards: [
                                    {
                                        rewardType: 'COUPON',
                                        rewardValue: [
                                            {
                                                ...coupon,
                                                name: couponReward.couponName,
                                                id: coupon.couponDefinitionId,
                                                quantity: coupon.count,
                                            },
                                        ],
                                    },
                                ],
                            };
                            assetRewards.push(couponReward);
                        }
                        for (let j = 0; j < activityReward.assetRewards.length; j++) {
                            const reward = activityReward.assetRewards[j];
                            let assetReward: any = reward;
                            if (isCopy) {
                                assetReward.inventory = assetReward.quantity || 0;
                                assetReward.issuedCount = 0;
                                assetReward.issuedNum = 0;
                                delete assetReward.id;
                            }
                            assetReward.conditionValue = activityReward.conditionValue;
                            assetReward.quantityType = reward.quantity ? 'YES' : 'NO';
                            assetReward.rewardPeriod.memberRewardLimitType = reward.rewardPeriod
                                .memberRewardLimit
                                ? 'YES'
                                : 'NO';
                            assetReward.rewardPeriod.periodQuantityType = reward.rewardPeriod
                                .periodQuantity
                                ? 'YES'
                                : 'NO';
                            assetReward.picture = {
                                id: reward.picture.id || '',
                                path: reward.picture.url || '',
                            };
                            assetReward.type = {
                                checked: reward.rewardType,
                                assetRewards: [
                                    {
                                        rewardType: reward.rewardType,
                                        rewardValue: reward.rewardValue,
                                    },
                                ],
                            };
                            assetRewards.push(assetReward);
                        }
                    }
                    assetRewards.sort(function(a, b) {
                        if (a.sequence < b.sequence) {
                            return -1;
                        } else if (a.sequence === b.sequence) {
                            return 0;
                        } else {
                            return 1;
                        }
                    });
                }
                res.prizeInfo = {
                    assetRewards: assetRewards,
                    finishWithNoPrize: res.lottery.finishWithNoPrize ? 'true' : undefined,
                };
            } else {
                if (!id) {
                    res.result =
                        res &&
                        res.result.map((item: any) => {
                            item.canExport =
                                item.executeStatus === 'STARTED' ||
                                item.executeStatus === 'COMPLETE';
                            return item;
                        });
                }
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/open_box_activities';
        const newParams: any = dataParser(params);

        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        if (params.id) {
            config.apiPath = `/admin/open_box_activities/${params.id}`;
        }
        const newParams: any = dataParser(params);
        newParams.activityVo.id = params.id;
        newParams.activityVo.shareConfig.id = params.shareConfigId;
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    if (params.baseInfo && params.ruleInfo) {
        newParams.activityVo = {
            eventType: 'OPEN_BOX',
            name: params.baseInfo.name,
            startTime: params.baseInfo.dateRange.start,
            endTime: params.baseInfo.dateRange.end,
            normalConditions: [
                {
                    all: false,
                    normalConditionType: 'SUB_SITE',
                    includeIds: params.baseInfo.subsiteIds,
                },
            ],
            shareConfig: {
                shareContent: params.pictureInfo.shareContent,
                status: true,
            },
        };
        const exchangeConditions: Array<any> = [];
        exchangeConditions.push({
            scopeId: -1,
            exchangeMode: 'GIVE',
            exchangeLimit: params.ruleInfo.activityRewardLimit,
            period: 'ACTIVITY',
            consumeValue: 0,
            normalConditionType: 'MEMBER_CARD_LEVEL',
        });
        const inviteFriends = params.ruleInfo.inviteFriends;
        if (inviteFriends && inviteFriends.length > 0) {
            forEach(inviteFriends, (info) => {
                exchangeConditions.push({
                    scopeId: -1,
                    exchangeMode: 'SHARE_ASSIST',
                    exchangeLimit: info.rewardValue,
                    exchangeValue: info.rewardValue,
                    exchangeRule: 'LADDER',
                    period: 'ACTIVITY',
                    consumeValue: info.inviteValue,
                    normalConditionType: 'MEMBER_CARD_LEVEL',
                });
            });
        }
        const activityDrawLimit = params.ruleInfo?.activityDrawLimit;
        if (activityDrawLimit && activityDrawLimit?.exchangeLimit) {
            exchangeConditions.push({ ...activityDrawLimit, scopeId: 1 });
        }
        newParams.activityVo.exchangeConditions = exchangeConditions;

        const excludeTypeActivity = params.ruleInfo.excludeTypeActivity;
        if (excludeTypeActivity.type === 'ALL') {
            newParams.activityVo.normalConditions.push({
                all: true,
                normalConditionType: 'EXCLUDE_MARKETING_ACTIVITY',
            });
        }
        if (excludeTypeActivity.type === 'SOME') {
            newParams.activityVo.normalConditions.push({
                all: false,
                normalConditionType: 'EXCLUDE_MARKETING_ACTIVITY',
                includeIds: excludeTypeActivity.activityList.map((activity: any) => {
                    return {
                        id: activity.id,
                    };
                }),
            });
        }
    }
    if (params.pictureInfo) {
        const pageConfig: any = {
            activityPicture: {
                id: params.pictureInfo.activityPicture[0].id,
                url: params.pictureInfo.activityPicture[0].path,
            },
            rulePictureIcon: {
                id: params.pictureInfo.rulePictureIcon[0].id,
                url: params.pictureInfo.rulePictureIcon[0].path,
            },
            rulePicture: {
                id: params.pictureInfo.rulePicture[0].id,
                url: params.pictureInfo.rulePicture[0].path,
            },
            backgroundPicture: {
                id: params.pictureInfo.backgroundPicture[0].id,
                url: params.pictureInfo.backgroundPicture[0].path,
            },
            giftBoxPicture: {
                id: params.pictureInfo.giftBoxPicture[0].id,
                url: params.pictureInfo.giftBoxPicture[0].path,
            },
            giftBoxCartoonPicture: {
                id: params.pictureInfo.giftBoxCartoonPicture[0].id,
                url: params.pictureInfo.giftBoxCartoonPicture[0].path,
            },
            tipColor: params.pictureInfo.tipColor,
            buttonBackgroundStyle: params.pictureInfo.buttonBackgroundStyle,
            buttonBackgroundColor:
                params.pictureInfo.buttonBackgroundStyle === 'NONE'
                    ? params.pictureInfo.buttonBackgroundColor
                    : undefined,
            buttonBackgroundPicture:
                params.pictureInfo.buttonBackgroundStyle === 'BACKGROUND_URL' &&
                params.pictureInfo.buttonBackgroundPicture &&
                params.pictureInfo.buttonBackgroundPicture.length > 0
                    ? {
                          id: params.pictureInfo.buttonBackgroundPicture[0].id,
                          url: params.pictureInfo.buttonBackgroundPicture[0].path,
                      }
                    : undefined,
            buttonColor: params.pictureInfo.buttonColor,
            winningRecordPicture: {
                id: params.pictureInfo.winningRecordPicture[0].id,
                url: params.pictureInfo.winningRecordPicture[0].path,
            },
            moreActivityPicture:
                params.pictureInfo.moreActivityPicture &&
                params.pictureInfo.moreActivityPicture.length > 0
                    ? {
                          id: params.pictureInfo.moreActivityPicture[0].id,
                          url: params.pictureInfo.moreActivityPicture[0].path,
                      }
                    : undefined,
            target: params.pictureInfo.target,
            sharePicture: {
                id: params.pictureInfo.sharePicture[0].id,
                url: params.pictureInfo.sharePicture[0].path,
            },
            audioUrl:
                params.pictureInfo.audioUrl && params.pictureInfo.audioUrl.length > 0
                    ? {
                          id: params.pictureInfo.audioUrl[0].id,
                          url: params.pictureInfo.audioUrl[0].path,
                      }
                    : undefined,
            audioOpenPicture:
                params.pictureInfo.audioOpenPicture &&
                params.pictureInfo.audioOpenPicture.length > 0
                    ? {
                          id: params.pictureInfo?.audioOpenPicture[0]?.id || null,
                          url: params.pictureInfo?.audioOpenPicture[0]?.path || null,
                      }
                    : undefined,
            audioClosePicture:
                params.pictureInfo.audioClosePicture &&
                params.pictureInfo.audioClosePicture.length > 0
                    ? {
                          id: params.pictureInfo?.audioClosePicture[0]?.id || null,
                          url: params.pictureInfo?.audioClosePicture[0]?.path || null,
                      }
                    : undefined,
        };
        let finishWithNoPrize = false;
        const assetRewards = params.prizeInfo.assetRewards || [];
        const findNoneReward = assetRewards.filter((item: any) => item.type.checked === 'NONE');
        if (
            findNoneReward.length > 0 &&
            params.prizeInfo.finishWithNoPrize &&
            params.prizeInfo.finishWithNoPrize.includes('true')
        ) {
            finishWithNoPrize = true;
        }
        newParams.lottery = {
            pageConfig: JSON.stringify(pageConfig),
            finishWithNoPrize,
        };
    }
    if (
        params.prizeInfo &&
        params.prizeInfo.assetRewards &&
        params.prizeInfo.assetRewards.length > 0
    ) {
        const assetRewardsInfo = [];
        // const couponRewardsInfo = [];
        for (let i = 0; i < params.prizeInfo.assetRewards.length; i++) {
            const assetReward = params.prizeInfo.assetRewards[i];
            var checked = assetReward.type.checked; //奖品项最后选中的类型
            var assetRewardsList = assetReward.type.assetRewards;
            if (checked === 'COUPON') {
                for (let j = 0; j < assetRewardsList.length; j++) {
                    const assetRewardsItem = assetRewardsList[j];
                    if (assetRewardsItem.rewardType === checked) {
                        //奖品项最终的输入
                        let couponDefinitionId;
                        let count;
                        if (
                            assetRewardsItem.rewardValue &&
                            assetRewardsItem.rewardValue.length > 0
                        ) {
                            couponDefinitionId = assetRewardsItem.rewardValue[0].id;
                            count = assetRewardsItem.rewardValue[0].quantity;
                        }
                        let raward = {
                            conditionValueMax: 0,
                            conditionValue: assetReward.conditionValue || null,
                            couponRewards: [
                                {
                                    count: count ? count : 1,
                                    couponDefinitionId: couponDefinitionId,
                                    name: assetReward.name,
                                    picture: assetReward.picture,
                                    quantity:
                                        assetReward.quantityType === 'YES'
                                            ? assetReward.quantity
                                            : null,
                                    sequence: assetReward.sequence,
                                    tip: assetReward.tip,
                                    id: assetReward.id || null,
                                    rewardPeriod: {
                                        crowdScope: 'ALL',
                                        memberRewardLimit:
                                            assetReward?.rewardPeriod?.memberRewardLimit || null,
                                        period: 'DAY',
                                        periodQuantity:
                                            assetReward?.rewardPeriod?.periodQuantity || null,
                                    },
                                    status: assetReward.status,
                                },
                            ],
                        };
                        assetRewardsInfo.push(raward);
                    }
                }
            } else {
                if (checked === 'NONE') {
                    let raward = {
                        conditionValueMax: 0,
                        conditionValue: assetReward.conditionValue || null,
                        assetRewards: [
                            {
                                rewardType: checked,
                                name: assetReward.name,
                                picture: assetReward.picture,
                                quantity:
                                    assetReward.quantityType === 'YES'
                                        ? assetReward.quantity
                                        : null,
                                sequence: assetReward.sequence,
                                tip: assetReward.tip,
                                id: assetReward.id || null,
                                status: assetReward.status,
                            },
                        ],
                    };
                    assetRewardsInfo.push(raward);
                } else {
                    if (assetRewardsList && assetRewardsList.length > 0) {
                        for (let j = 0; j < assetRewardsList.length; j++) {
                            const assetRewardsItem = assetRewardsList[j];
                            let raward = {
                                conditionValueMax: 0,
                                conditionValue: assetReward.conditionValue || null,
                                assetRewards: [
                                    {
                                        rewardType: checked,
                                        name: assetReward.name,
                                        picture: assetReward.picture,
                                        quantity:
                                            assetReward.quantityType === 'YES'
                                                ? assetReward.quantity
                                                : null,
                                        rewardValue: assetRewardsItem.rewardValue,
                                        sequence: assetReward.sequence,
                                        tip: assetReward.tip,
                                        id: assetReward.id || null,
                                        rewardPeriod: {
                                            crowdScope: 'ALL',
                                            memberRewardLimit:
                                                assetReward?.rewardPeriod?.memberRewardLimit ||
                                                null,
                                            period: 'DAY',
                                            periodQuantity:
                                                assetReward?.rewardPeriod?.periodQuantity || null,
                                        },
                                        status: assetReward.status,
                                    },
                                ],
                            };
                            assetRewardsInfo.push(raward);
                        }
                    }
                }
            }
        }
        const periodLimit = params.ruleInfo.periodLimit;
        newParams.activityVo.rewardConfig = {
            rewardRule: 'RANDOM',
            crowdScope: 'EACH',
            activityRewards: assetRewardsInfo,
            activityRewardLimit:
                periodLimit?.list && periodLimit.list.length ? periodLimit.list[0].limit : null,
            activityRewardLeastLimit:
                periodLimit?.list && periodLimit.list.length
                    ? parseInt(periodLimit.list[0].leastLimit)
                    : null,
        };
        newParams.marketingScheduleId =
            (params.baseInfo?.marketingSchedule?.length &&
                params.baseInfo.marketingSchedule[0].id) ||
            null;
    }
    return newParams;
}
