import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty } from 'lodash';

const api = services.api;

export const IntegralDescriptionLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-CAPITAL-RULE`;
        config.apiPath = '/admin/point_rules_desc';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/point_rules_desc/${id}`;
        }
        const subsiteName = data.subsiteName;
        if (subsiteName) {
            data.subsiteName = subsiteName.name ? subsiteName.name : subsiteName;
        }
        return await api.get(data, config).then((res: any) => {
            if (!id) {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        item.subsiteName = item.subsites;
                        return item;
                    });
            } else {
                res.doubleRuleParticipateCardinalityCalculate = res.doubleRuleParticipateCardinalityCalculate
                    ? 'true'
                    : 'false';
                if (res.picId && res.picUrl) {
                    res.bgPicture = [
                        {
                            id: res.picId,
                            path: res.picUrl,
                        },
                    ];
                }
            }
            return res;
        });
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-CAPITAL-RULE`;
        config.apiPath = `/admin/point_rules_desc/${id}`;
        const newParams = dataParser(params);
        newParams.id = id;
        return await api.put(newParams, config);
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-CAPITAL-RULE`;
        config.apiPath = `/admin/point_rules_desc`;
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
    delete: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-CAPITAL-RULE`;
        config.apiPath = `/admin/point_rules_desc/${id}`;
        const newParams = dataParser(params);
        newParams.id = id;
        return await api.delete(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    if (!isEmpty(params.subsites)) {
        params.subsiteIds = params.subsites
            .map((subsite: any) => {
                return subsite.id;
            })
            .join(',');
    }
    const bgPicture = params.bgPicture && params.bgPicture.length ? params.bgPicture[0] : {};
    params.picId = bgPicture.id;
    params.picUrl = bgPicture.path;

    return params;
}
