import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        MagicMallActivityRegistrationEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
            apiPath: '/loader/admin/magicMallActivityRegistration',
            filters: {
                activityName: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsrhdmc>>' },
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                    controlConfig: { placeholder: '<<inputOrderNumber>>' },
                },
                subSiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                memberMobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                    controlConfig: { placeholder: '<<hysjh>>' },
                },
                mobile: {
                    type: 'string',
                    displayName: '报名手机号',
                    controlConfig: { placeholder: '手机号' },
                },
                name: {
                    type: 'string',
                    displayName: '报名姓名',
                    controlConfig: { placeholder: '姓名' },
                },
                enable: {
                    type: 'string.options.select',
                    displayName: '失效报名筛选',
                    controlConfig: { placeholder: '<<selectPlease>>' },
                    defaultValue: '',
                    options: [
                        {
                            id: 'false',
                            name: '已失效',
                        },
                        {
                            id: 'true',
                            name: '未失效',
                        },
                    ],
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<xdsj>>',
                },
                joinDate: {
                    type: 'object.dateRangePlus',
                    displayName: '参与日期',
                },
            },
            properties: {
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                activityName: {
                    type: 'string',
                    displayName: '<<activityName>>',
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                },
                orderAmount: {
                    type: 'number',
                    displayName: '<<xjzje>>',
                },
                pointDealPrice: {
                    type: 'number',
                    displayName: '<<jfze>>',
                },
                orderStatus: {
                    type: 'string',
                    displayName: '<<ddzt>>',
                },
                orderStatusStr: {
                    type: 'string',
                    displayName: '<<ddzt>>',
                },
                orderCreateTime: {
                    type: 'string',
                    displayName: '<<xdsj>>',
                },
                joinTime: {
                    type: 'string',
                    displayName: '参与时间',
                },
                registrationTimeSnapshot: {
                    type: 'string',
                    displayName: '参与时间',
                },
                name: {
                    type: 'string.statusBadge',
                    displayName: '报名姓名',
                },
                mobile: {
                    type: 'string.date',
                    displayName: '报名手机号',
                },
                memberMobile: {
                    type: 'string.date',
                    displayName: '<<hysjh>>',
                },
                quantity: {
                    type: 'number',
                    displayName: '报名数量',
                },
            },
        },
    },
    components: {
        MagicMallActivityRegistrationView: {
            component: 'Viewport',
            entity: 'MagicMallActivityRegistrationEntity',
        },
        MagicMallActivityRegistrationPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'MagicMallActivityRegistrationEntity',
            items: [
                {
                    component: 'MagicMallActivityRegistrationFilter',
                },
                { component: 'MagicMallActivityRegistrationTables' },
            ],
        },
        MagicMallActivityRegistrationDetailPage: {
            entity: 'MagicMallActivityRegistrationEntity',
            loaderType: 'get',
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [
                {
                    component: 'MagicMallActivityRegistrationDetail',
                },
            ],
        },
        MagicMallActivityRegistrationFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'memberMobile',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'mobile',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                // {
                //     property: 'enable',
                //     controlConfig: {
                //         style: { width: 200 },
                //         placeholder: '<<selectPlease>>',
                //     },
                // },
                {
                    property: 'orderNo',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'dateRange',
                },
                {
                    property: 'joinDate',
                },
            ],
        },
        MagicMallActivityRegistrationTables: {
            component: 'Tabs',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<all>>',
                    content: {
                        component: 'MagicMallActivityRegistrationTable',
                    },
                    params: { orderStatus: 'ALL' },
                },
                {
                    title: '<<waiting>>',
                    content: {
                        component: 'MagicMallActivityRegistrationTable',
                    },
                    params: { orderStatus: 'WAIT_AUDIT' },
                },
                {
                    title: '审核未通过',
                    content: {
                        component: 'MagicMallActivityRegistrationTable',
                    },
                    params: { orderStatus: 'AUDIT_REJECTED' },
                },
                {
                    title: '待付款',
                    content: {
                        component: 'MagicMallActivityRegistrationTable',
                    },
                    params: { orderStatus: 'WAIT_PAY' },
                },
                {
                    title: '待参与',
                    content: {
                        component: 'MagicMallActivityRegistrationTable',
                    },
                    params: { orderStatus: 'WAIT_CONSUME' },
                },
                {
                    title: '已完成',
                    content: {
                        component: 'MagicMallActivityRegistrationTable',
                    },
                    params: { orderStatus: 'CONSUMED' },
                },
                {
                    title: '关闭/取消',
                    content: {
                        component: 'MagicMallActivityRegistrationTable',
                    },
                    params: { orderStatus: 'CANCELED_OR_CLOSED' },
                },
            ],
        },
        MagicMallActivityRegistrationApplyPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'MagicActivityRegistrationApplyForm',
                },
            ],
        },
        MagicMallActivityRegistrationTable: {
            component: 'FlexLayout',
            direction: 'vertical',

            items: [
                {
                    component: 'DataTable',
                    loadFirstPage: true,
                    scroll: {
                        x: 1300,
                    },
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    columns: [
                        {
                            property: 'subsiteName',
                            width: 160,
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '160px',
                                },
                            },
                        },
                        {
                            property: 'activityName',
                            width: 160,
                            displayConfig: {
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '160px',
                                },
                            },
                        },
                        {
                            property: 'orderNo',
                            width: 200,
                        },
                        {
                            property: 'quantity',
                            width: 80,
                        },
                        {
                            property: 'orderAmount',
                            width: 100,
                        },
                        {
                            property: 'pointDealPrice',
                            width: 80,
                        },
                        {
                            property: 'orderStatusStr',
                            width: 80,
                        },
                        {
                            property: 'orderCreateTime',
                            width: 200,
                        },
                        {
                            property: 'registrationTimeSnapshot',
                            width: 220,
                        },
                        {
                            property: 'memberMobile',
                            width: 140,
                        },
                        {
                            property: 'name',
                            width: 120,
                        },
                        {
                            property: 'mobile',
                            width: 140,
                        },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        fixed: 'right',
                        width: 180,
                        items: [
                            {
                                disabled: true,
                                type: 'link',
                                text: '<<details>>',
                                path: '/mall-activity/record-detail/{{row.id}}',
                            },
                            {
                                type: 'component',
                                component: 'ReviewMagicActivityButton',
                                config: {
                                    triggerComponent: {
                                        component: 'Button',
                                        type: 'link',
                                        text: '<<audity>>',
                                    },
                                },
                            },
                            {
                                type: 'component',
                                component: 'ConsumeActivityButton',
                                config: {
                                    triggerComponent: {
                                        component: 'Button',
                                        type: 'link',
                                        text: '核销',
                                    },
                                },
                            },
                            {
                                type: 'component',
                                component: 'MagicActivityAfterSaleButton',
                            },
                        ],
                    },
                },
            ],
        },
    },
};
