import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { mapValues, get, defaults, forEach, debounce } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import { InvoiceModeEnum } from '../../../types/mode/string/invoice-mode/invoice-mode';

const { getText } = services.language;

export interface ParkingInvoiceConfigsEditFormProps extends FormComponentProps {
    entity: Entity;
    fields: any;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    type: any;
    unmountComponent: () => void;
}
class ParkingInvoiceConfigsEditForm extends PureComponent<ParkingInvoiceConfigsEditFormProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }
    render() {
        const { entity, wrappedComponentRef, onSubmit, type } = this.props;
        let editFields: any = [
            { property: 'baseInfo.subsiteId' },
            { property: 'baseInfo.invoiceMode' },
            { property: 'baseInfo.taxCode' },
            { property: 'baseInfo.taxPreferentialPolicies' },
            {
                property: 'baseInfo.taxPreferentialPoliciesType',
                visible: (values: any) => {
                    const taxPreferentialPolicies = get(values, 'baseInfo.taxPreferentialPolicies');
                    if (taxPreferentialPolicies && taxPreferentialPolicies === 'true') {
                        return true;
                    }
                    return false;
                },
            },
            { property: 'baseInfo.taxRate' },
            { property: 'sellerInfo.sellerName' },
            { property: 'sellerInfo.sellerTaxNo' },
            { property: 'sellerInfo.sellerAddress' },
            { property: 'sellerInfo.sellerPhone' },
            { property: 'sellerInfo.sellerBank' },
            { property: 'sellerInfo.sellerBankAccount' },
            {
                property: 'sellerInfo.sellerPayee',
                visible: (values: any) => {
                    const invoiceMode = get(values, 'baseInfo.invoiceMode');
                    if (invoiceMode && invoiceMode === InvoiceModeEnum.TAX_CONTROL) {
                        return true;
                    }
                    return false;
                },
            },
            {
                property: 'sellerInfo.sellerReviewer',
                visible: (values: any) => {
                    const invoiceMode = get(values, 'baseInfo.invoiceMode');
                    if (invoiceMode && invoiceMode === InvoiceModeEnum.TAX_CONTROL) {
                        return true;
                    }
                    return false;
                },
            },
            { property: 'sellerInfo.sellerDrawer' },
            {
                property: 'sellerInfo.remark',
                visible: (values: any) => {
                    const invoiceMode = get(values, 'baseInfo.invoiceMode');
                    if (invoiceMode && invoiceMode === InvoiceModeEnum.TAX_CONTROL) {
                        return true;
                    }
                    return false;
                },
            },
            {
                property: 'sellerInfo.regionAndAddress',
                visible: (values: any) => {
                    const invoiceMode = get(values, 'baseInfo.invoiceMode');
                    if (invoiceMode && invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC) {
                        return true;
                    }
                    return false;
                },
            },
            {
                property: 'sellerInfo.realPropertyCertificate',
                visible: (values: any) => {
                    const invoiceMode = get(values, 'baseInfo.invoiceMode');
                    if (invoiceMode && invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC) {
                        return true;
                    }
                    return false;
                },
            },
            {
                property: 'sellerInfo.crossCityFlag',
                visible: (values: any) => {
                    const invoiceMode = get(values, 'baseInfo.invoiceMode');
                    if (invoiceMode && invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC) {
                        return true;
                    }
                    return false;
                },
            },
            { property: 'invoiceSystemInfo.invoiceSystem' },
            { property: 'otherInfo.isEffectiveDaysLimit' },
            {
                property: 'otherInfo.effectiveDaysLimit',
                visible: (values: any) => get(values, 'otherInfo.isEffectiveDaysLimit') === 'true',
            },
            { property: 'otherInfo.isChangeCountLimit' },
            {
                property: 'otherInfo.changeCountLimit',
                visible: (values: any) => get(values, 'otherInfo.isChangeCountLimit') === 'true',
            },
            {
                property: 'otherInfo.amountTopLimit',
                visible: (values: any) => {
                    const invoiceMode = get(values, 'baseInfo.invoiceMode');
                    if (invoiceMode && invoiceMode === InvoiceModeEnum.TAX_CONTROL) {
                        return true;
                    }
                    return false;
                },
            },
        ];
        let formProps = {
            entity,
            componentId: 'ParkingInvoiceConfigsEditFormContainer',
            wrappedComponentRef,
            onSubmit,
            direction: 'horizontal',
            labelCol: 6,
            controlCol: 18,
            style: { width: 720 },
            fields: editFields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: getText('qx'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                let successMsg = getText('editSuccess');
                if (type === 'copy') {
                    successMsg = getText('copySuccess');
                }
                AntMessage.success(successMsg, () => services.behaviorHandle({ route: 'goBack' }));
            },
        };
        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    }
}
function mapStateToProps(_state: any, props: any) {
    const { entity } = props;
    return {
        entity,
        fields: entity.fields || {},
    };
}
function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;
    let form: any;

    /**
     * 提交前额外验证
     * @param entityFields
     */
    const validate = (entityFields: any) => {
        const sellerPhone = entityFields.baseInfo.sellerPhone;
        if (sellerPhone) {
            const sellerPhoneArray = sellerPhone.split('-');
            if (
                sellerPhoneArray.length === 2 &&
                (sellerPhoneArray[0] === '' || sellerPhoneArray[1] === '')
            ) {
                AntMessage.warn(getText('qsrwzdh'));
                return false;
            }
        }
        if (
            entityFields.invoiceSystemInfo &&
            entityFields.invoiceSystemInfo.invoiceSystem &&
            entityFields.invoiceSystemInfo.invoiceSystem.id
        ) {
            const invoiceSystem = entityFields.invoiceSystemInfo.invoiceSystem;
            if (invoiceSystem.params) {
                let hasValidate = true;
                if (invoiceSystem.params) {
                    forEach(invoiceSystem.params, (param) => {
                        param.value = param.value.replace(/\s*/g, '');
                        if (param.required && (!param.value || param.value === null)) {
                            AntMessage.warning(`${getText('selectFillin')}${param.name}`);
                            hasValidate = false;
                            return false;
                        }
                    });
                }
                if (!hasValidate) {
                    return false;
                }
            }
        } else {
            AntMessage.warning(getText('qxzswxt'));
            return false;
        }
        if (
            entityFields.baseInfo.invoiceMode &&
            entityFields.baseInfo.invoiceMode === InvoiceModeEnum.DIGITAL_ELECTRONIC
        ) {
            const region = entityFields.sellerInfo.regionAndAddress
                ? entityFields.sellerInfo.regionAndAddress.regionIds
                : [];
            const address = entityFields.sellerInfo.regionAndAddress
                ? entityFields.sellerInfo.regionAndAddress.address
                : '';
            if (!region || region.length === 0 || !address || address.length > 80) {
                AntMessage.warning(getText('h_parking_invoice_form_region_validate_msg'));
                return false;
            }
        }
        return true;
    };
    return defaults(
        {
            onSubmit,
            onFieldChange,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: debounce((event: any, fields: any) => {
                let submit = true;

                const entityFields = mapValues(fields, (field, name) => {
                    return field.value;
                });
                if (submit) {
                    if (entityFields) {
                        const hasValidate = validate(entityFields);
                        if (!hasValidate || !submit) {
                            return;
                        }
                        if (props.type === 'copy') {
                            entity.add(entityFields, params);
                        } else {
                            entity.modify(entityFields, params);
                        }
                    }
                }
                return false;
            }, 300),
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(
                        actions.unmountComponentAction('ParkingInvoiceConfigsEditFormContainer')
                    );
            },
        }
    );
}
export const ParkingInvoiceConfigsEditFormView = connect(
    mapStateToProps,
    mapDispatchToProps
)(ParkingInvoiceConfigsEditForm);
