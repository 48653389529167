import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { cloneDeep } from 'lodash';

const api = services.api;
export const ProductSearchKeywordsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        const { id, enabled } = data;
        if (enabled && enabled === 'ALL') {
            delete data.enabled;
        }
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/product_search_keywords';
        if (id) {
            config.apiPath += `/${id}`;
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.selectedSubsites = res.subsites;
            }
            if (res.result) {
                res.result = res.result.map((item: any) => {
                    item.keywordStr = item.keyword;
                    return item;
                });
            }
            return res;
        });
    },

    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = `/admin/product_search_keywords/${id}`;
        const newParams = dataParser(params);
        newParams.id = id;
        return await api.put(newParams, config);
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/WEB-API`;
        config.apiPath = '/admin/product_search_keywords';
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = cloneDeep(params);
    newParams.subsiteIds = newParams.selectedSubsites.map((subsite: any) => {
        return Number(subsite.id);
    });
    delete newParams.selectedSubsites;
    return newParams;
}
