import React, { PureComponent } from 'react';
import { Table, Button, Popconfirm, message } from 'antd';
import { cloneDeep, remove, find, findIndex, map } from 'lodash';
import { MerchantsSelectorItem } from './merchants-selector-by-subsites';
import { services } from '@comall-backend-builder/core';

interface MerchantSelectedDisplayProps {
    data: Array<MerchantsSelectorItem>;
    onRemove: (data: Array<MerchantsSelectorItem>) => void;
    disabled?: boolean;
}
interface MerchantSelectedDisplayState {
    selectedRows: Array<MerchantsSelectorItem>;
}

export class MerchantsSelectorDisplay extends PureComponent<
    MerchantSelectedDisplayProps,
    MerchantSelectedDisplayState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedRows: [],
        };
    }

    getTableConfig = () => {
        const { disabled } = this.props;
        const operation = disabled
            ? {}
            : {
                  title: services.language.getText('common.tableAction'),
                  className: 'action-column',
                  key: 'operation',
                  width: 100,
                  render: (i: any, row: any) => {
                      return (
                          <Button type={'link'} onClick={this.onRemove(row)}>
                              {services.language.getText('common.delete')}
                          </Button>
                      );
                  },
              };

        const config: any = {
            rowKey: 'id',
            scroll: {
                y: 400,
            },
            columns: [
                {
                    title: services.language.getText('subsiteName'),
                    dataIndex: 'subsiteName',
                    key: 'subsiteName',
                },
                {
                    title: services.language.getText('merchantName'),
                    dataIndex: 'name',
                    key: 'name',
                },
                {
                    title: services.language.getText('merchantType'),
                    dataIndex: 'type',
                    key: 'type',
                },
                {
                    title: services.language.getText('merchantStatus'),
                    dataIndex: 'status',
                    key: 'status',
                },
                operation,
            ],
            footer: () => this.renderFooter(),
            style: {
                marginTop: 20,
            },
        };
        return config;
    };

    renderFooter = () => {
        const { disabled } = this.props;
        return !disabled ? (
            <div className="product-selected-footer">
                <div className="setting-item">
                    <Popconfirm
                        onConfirm={this.onRemoveAll}
                        title={services.language.getText('confirmDeleteColumn')}
                        okText={services.language.getText('common.ok')}
                        cancelText={services.language.getText('common.cancel')}
                    >
                        <Button className="setting-item-ok" type="primary">
                            {services.language.getText('batchDelete')}
                        </Button>
                    </Popconfirm>
                </div>
            </div>
        ) : null;
    };

    onRemoveAll = () => {
        const { data, onRemove } = this.props;
        const { selectedRows } = this.state;
        if (selectedRows.length === 0) {
            message.info(services.language.getText('selectBatchDeleteColumn'));
            return;
        }
        const results = cloneDeep(data);

        const newResults = results.filter((i: any) => {
            return (
                findIndex(selectedRows, {
                    id: i.id,
                }) < 0
            );
        });
        onRemove(newResults);
        this.setState({
            selectedRows: [],
        });
    };

    onRemove = (row: MerchantsSelectorItem) => {
        const { data, onRemove } = this.props;

        return () => {
            const result = cloneDeep(data);
            remove(result, (merchant) => row.id === merchant.id);
            onRemove(result);
        };
    };

    onTableRowSelect = (record: MerchantsSelectorItem, selected: boolean) => {
        const { selectedRows } = this.state;
        const rows = cloneDeep(selectedRows);
        if (selected) {
            if (find(rows, { id: record.id })) {
                return;
            }
            rows.push(record);
        } else {
            remove(rows, (i) => {
                return i.id === record.id;
            });
        }
        this.setState({
            selectedRows: rows,
        });
    };

    onTableRowSelectAll = (
        selected: boolean,
        rows: MerchantsSelectorItem[],
        unSelectedRows: any
    ) => {
        const { selectedRows } = this.state;
        let currentRows = cloneDeep(selectedRows);
        if (selected) {
            currentRows = rows;
        } else {
            map(unSelectedRows, (row) => {
                remove(currentRows, (i) => {
                    return i.id === row.id;
                });
            });
        }
        this.setState({
            selectedRows: currentRows,
        });
    };

    render() {
        const { data, disabled } = this.props;
        const { selectedRows } = this.state;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: data,
        };
        if (!disabled) {
            tableProps.rowSelection = {
                selectedRowKeys: map(selectedRows, 'id'),
                onSelect: (record: MerchantsSelectorItem, selected: boolean) => {
                    this.onTableRowSelect(record, selected);
                },
                onSelectAll: (
                    selected: boolean,
                    rows: MerchantsSelectorItem[],
                    unSelectedRows: MerchantsSelectorItem[]
                ) => {
                    this.onTableRowSelectAll(selected, rows, unSelectedRows);
                },
                onChange: () => {},
            };
        }

        return <Table {...tableProps} pagination={false}></Table>;
    }
}
