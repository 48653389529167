import React, { PureComponent, createElement } from 'react';
import { defaultsDeep, set, assign } from 'lodash';
import { Modal, message as AntMessage } from 'antd';
import { builder, actions, services } from '@comall-backend-builder/core';
import { ComponentFactory } from '@comall-backend-builder/core/lib/parser/component';
import { language } from '@comall-backend-builder/core/lib/services';

let SELECTED_ROW: any = [];

const getConfig = (componentName: string) => {
    return {
        component: 'Card',
        entity: 'CreditProductsSelector',
        componentId: componentName,
        content: {
            component: 'FlexLayout',
            direction: 'vertical',
            params: {
                subsiteIds: '',
                status: 'ENABLE',
            },
            items: [
                {
                    component: 'FilterForm',
                    componentId: componentName + '-FilterForm',
                    direction: 'inline',
                    style: { textAlign: 'right', marginBottom: '20px' },
                    reset: true,
                    submit: {
                        text: '查询',
                        style: {
                            marginRight: 16,
                        },
                    },
                    fields: [
                        {
                            property: 'creditEshopProductType',
                            controlConfig: {
                                style: { width: 120 },
                                allowClear: true,
                            },
                        },
                        {
                            property: 'keywords',
                            controlConfig: {
                                style: { width: 214 },
                            },
                        },
                        {
                            property: 'executeStatus',
                            controlConfig: {
                                style: { width: 214 },
                            },
                        },
                    ],
                },
                {
                    component: 'DataTable',
                    componentId: componentName + '-table',
                    loadFirstPage: true,
                    rowSelection: {
                        type: 'radio',
                        onChange: () => {},
                        onSelect: function() {
                            SELECTED_ROW = arguments[2];
                        },
                    },
                    pagination: {
                        pageSize: 10,
                    },
                    scroll: { x: true },
                    columns: [
                        {
                            property: 'productInfo',
                        },
                        {
                            property: 'ruleName',
                        },
                        {
                            property: 'executeStatus',
                        },
                        {
                            property: 'subsite.name',
                        },
                    ],
                },
            ],
        },
    };
};

function initDynamicComponent(contentConfig: any, componentName: any, subsiteIds?: string[]) {
    const config = defaultsDeep(contentConfig, getConfig(componentName));
    if (subsiteIds && subsiteIds.length > 0) {
        set(config, 'content.params.pageType', 'SUBSITE_INDEX');
    }
    set(config, 'content.params.subsiteIds', subsiteIds);
    set(config, 'content.items[1].componentId', componentName + '-table');
    set(config, 'content.items[1].rowSelection', {
        type: 'radio',
        onChange: () => {},
        onSelect: function() {
            SELECTED_ROW = arguments[2];
        },
        onSelectAll: function() {
            SELECTED_ROW = arguments[2];
        },
    });
    return ComponentFactory(componentName, config);
}

function generateDynamicComponentName() {
    return 'SelectCreditShop-' + parseInt(Math.random() * 1e8 + '').toString(16);
}

/**
 * 选择货品
 */
export class SelectCreditShopDetail extends PureComponent<{
    contentConfig?: Record<string, any>;
    modalConfig?: Record<string, any>;
    showModal: boolean;
    subsiteIds?: string[];
    onChange: (data: any) => void;
    onCancel: () => void;
}> {
    componentName = '';
    renderComponent: any = null;
    dispatch: any = null;

    constructor(props: any) {
        super(props);
        const { contentConfig, subsiteIds } = props;
        this.componentName = generateDynamicComponentName();
        const dynamicComponent = initDynamicComponent(
            contentConfig,
            this.componentName,
            subsiteIds
        );
        this.renderComponent = createElement(dynamicComponent);
        this.dispatch = builder.getStore().dispatch;
    }
    componentWillReceiveProps(nextProps: any) {
        const { subsiteIds } = this.props;
        if (nextProps?.subsiteIds?.join(',') !== subsiteIds?.join(',')) {
            const { contentConfig, subsiteIds } = nextProps;
            this.componentName = generateDynamicComponentName();
            const dynamicComponent = initDynamicComponent(
                contentConfig,
                this.componentName,
                subsiteIds
            );
            this.renderComponent = createElement(dynamicComponent);
        }
    }
    onOk = () => {
        if (!SELECTED_ROW.length) {
            AntMessage.error(services.language.getText('productSelectRequired'));
            return;
        }

        const { onChange } = this.props;

        onChange(SELECTED_ROW);
        SELECTED_ROW = [];
        this.dispatch(actions.tableRowSelectionChangeAction(this.componentName + '-table', []));
    };

    onCancel = () => {
        const { onCancel } = this.props;
        onCancel();
    };

    render() {
        const { showModal, modalConfig = {} } = this.props;
        const props = {};
        assign(
            props,
            {
                width: 1000,
                visible: showModal,
                title: language.getText('productSelectRequired'),
                onCancel: this.onCancel,
                onOk: this.onOk,
            },
            modalConfig
        );
        return <Modal {...props}>{this.renderComponent}</Modal>;
    }
}
