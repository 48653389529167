import React, { Component } from 'react';
import { services, Loader } from '@comall-backend-builder/core';
import { Modal, Button, message } from 'antd';
import { AutomaticDeliveryForm } from '../automatic-delivery-form';
import { assign, debounce, cloneDeep } from 'lodash';
import { DeliveryFormData, LogisticsMethod, DefaultFormData, DeliveryMode } from '../types';
import { formatDate } from '../../services';

import './index.less';

const { info } = Modal;
const { errorHandle } = services;
const printerIcon = './images/editor/printer-icon.png';
export interface DeliveryPackageButtonState {
    visible: boolean;
    formData: DeliveryFormData;
    expressCompanyList: any;
    deliveryLogisticsConfig: any;
    isSubmitting: boolean;
    disableSubmit: boolean;
    deliveryMode: DeliveryMode;
}

/**
 * 包裹运单重新叫单
 */
export class DeliveryPackageButton extends Component<any, DeliveryPackageButtonState> {
    constructor(props: any) {
        super(props);
        this.state = {
            visible: false,
            // 门店配送公司配置
            deliveryLogisticsConfig: {},
            // 编辑的表单数据
            formData: cloneDeep(DefaultFormData),
            // 可选物流公司列表
            expressCompanyList: [],
            isSubmitting: false,
            disableSubmit: false,
            deliveryMode: DeliveryMode.NONE,
        };

        this.debounceSubmit = debounce(this.onSubmit, 300);
    }
    debounceSubmit: () => void;

    // 获取门店配送公司设置
    getDeliveryLogisticsConfig = () => {
        const { row } = this.props;
        Loader.load('get', {
            params: {
                subsiteId: row.subsiteId,
                merchantId: row.orders[0].merchantId,
            },
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: `/admin/delivery_logistics_configs/by_merchant_id`,
        })
            .then((res: any) => {
                this.setState(
                    {
                        deliveryLogisticsConfig: res,
                    },
                    this.loadExpressCompany
                );
            })
            .catch(services.errorHandle);
    };
    getConfigInfo = (res: any) => {
        if (!res) {
            return {
                companyList: [],
            };
        }
        const { deliveryMode } = this.state;
        if (deliveryMode === DeliveryMode.EXPRESS) {
            //快递
            const expressCompanies = [];
            if (res.expressCompanies && res.expressCompanies.length > 0) {
                const automaticList = res.expressCompanies.filter(
                    (item: any) =>
                        item.logisticsBillGenerationMethods &&
                        item.logisticsBillGenerationMethods.includes(LogisticsMethod.AUTOMATIC)
                );
                const manualList = res.expressCompanies.filter(
                    (item: any) =>
                        item.logisticsBillGenerationMethods &&
                        item.logisticsBillGenerationMethods.includes(LogisticsMethod.MANUAL)
                );
                if (automaticList && automaticList.length > 0) {
                    expressCompanies.push({
                        logisticsMethod: LogisticsMethod.AUTOMATIC,
                        companys: automaticList,
                    });
                }
                if (manualList && manualList.length > 0) {
                    expressCompanies.push({
                        logisticsMethod: LogisticsMethod.MANUAL,
                        companys: manualList,
                    });
                }
                return {
                    companyList: expressCompanies,
                };
            }
        }
        if (deliveryMode === DeliveryMode.CITYWIDE) {
            //同城配
            const cityWideCompanies = [];
            if (res.cityWideCompanies && res.cityWideCompanies.length > 0) {
                const automaticList = res.cityWideCompanies.filter(
                    (item: any) =>
                        item.logisticsBillGenerationMethods &&
                        item.logisticsBillGenerationMethods.includes(LogisticsMethod.AUTOMATIC)
                );
                const manualList = res.cityWideCompanies.filter(
                    (item: any) =>
                        item.logisticsBillGenerationMethods &&
                        item.logisticsBillGenerationMethods.includes(LogisticsMethod.MANUAL)
                );
                if (automaticList && automaticList.length > 0) {
                    cityWideCompanies.push({
                        logisticsMethod: LogisticsMethod.AUTOMATIC,
                        companys: automaticList,
                    });
                }
                if (manualList && manualList.length > 0) {
                    cityWideCompanies.push({
                        logisticsMethod: LogisticsMethod.MANUAL,
                        companys: manualList,
                    });
                }
            }
            return {
                companyList: cityWideCompanies,
            };
        }
        return {
            companyList: [],
        };
    };
    /**
     * 加载物流公司列表
     */
    loadExpressCompany = debounce((name?: string) => {
        const { deliveryLogisticsConfig } = this.state;
        const configInfo = this.getConfigInfo(deliveryLogisticsConfig);
        const automaticLogisticsMethodCompanys = configInfo.companyList.find(
            (c: any) => c.logisticsMethod === LogisticsMethod.AUTOMATIC
        );
        let automaticCompanys = automaticLogisticsMethodCompanys
            ? automaticLogisticsMethodCompanys.companys
            : [];
        if (name) {
            automaticCompanys = automaticCompanys.filter((c: any) => c.companyName.includes(name));
        }
        this.setState(
            {
                expressCompanyList: automaticCompanys,
            },
            () => {
                // 默认选中第一家
                if (automaticCompanys && automaticCompanys.length) {
                    this.onChangeCompany(automaticCompanys[0]['companyId']);
                }
            }
        );
    }, 200);
    /**
     * 获取快递公司的服务类型
     */
    getServiceTypes = (companyCode: string) => {
        Loader.load('get', {
            params: {
                companyCode,
            },
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: `/admin/logistics_company_services`,
        })
            .then((res: any) => {
                this.setState({
                    formData: assign(this.state.formData, {
                        serviceTypes: res,
                        serviceType: res && res.length ? res[0].code : '',
                    }),
                });
            })
            .catch(errorHandle);
    };

    /**
     * 物流公司变更(重置表单数据)
     * @param value
     */
    onChangeCompany = (value: string) => {
        if (value === this.state.formData.companyId) {
            return;
        }
        const { expressCompanyList } = this.state;
        const selectedCompany: any = expressCompanyList.find((i: any) => i.companyId === value);
        const companyCode = selectedCompany['companyCode'];
        const deliveryLogisticsCompanyId = selectedCompany['id'];
        const companyAccount = selectedCompany['account'];
        const formData = assign(cloneDeep(DefaultFormData), {
            companyId: value,
            companyAccount,
            deliveryLogisticsCompanyId,
        });
        this.setState(
            {
                formData,
            },
            () => {
                this.computeInsured();
                this.getServiceTypes(companyCode);
            }
        );
    };
    // 重新计算保价
    computeInsured = () => {
        const { row } = this.props;
        const { deliveryLogisticsConfig } = this.state;
        let insured = false;
        if (deliveryLogisticsConfig && deliveryLogisticsConfig.insured) {
            // 都保价
            if (deliveryLogisticsConfig.insured.type === 'ALL') {
                insured = true;
            }
            // 达到金额保价
            if (deliveryLogisticsConfig.insured.type === 'LOWER_LIMIT') {
                let orderAmount = Number(row.totalPrice);

                if (orderAmount > Number(deliveryLogisticsConfig.insured.amount)) {
                    insured = true;
                }
            }
            this.setState({ formData: assign(this.state.formData, { insured: insured }) });
        }
    };
    // 改变表单数据
    changeFormData = (formData: DeliveryFormData) => {
        this.setState({
            formData,
        });
    };

    toggleModal = () => {
        if (!this.state.visible) {
            const { row } = this.props;
            let deliveryMode = DeliveryMode.NONE;
            const { deliveryModeId } = row;
            //1001：快递 1002：自提 1004：同城配 0：其他
            if (deliveryModeId === 1001) {
                deliveryMode = DeliveryMode.EXPRESS;
            }
            if (deliveryModeId === 1002) {
                deliveryMode = DeliveryMode.PICK_UP;
            }
            if (deliveryModeId === 1004) {
                deliveryMode = DeliveryMode.CITYWIDE;
            }
            this.setState({ deliveryMode }, () => {
                this.getDeliveryLogisticsConfig();
            });
        }
        this.setState({
            visible: !this.state.visible,
        });
    };
    onSubmit = () => {
        const { row } = this.props;
        const { deliveryMode, formData, deliveryLogisticsConfig } = this.state;
        const {
            companyId,
            companyAccount,
            deliveryLogisticsCompanyId,
            pickupTimeRange,
            serviceType,
            selectedPickupDay,
            selectedPickupTimeRange,
            pickup,
            remark,
            insured,
        } = formData;
        const params: any = {
            subsiteId: row.subsiteId,
            companyId,
            companyAccount,
            deliveryLogisticsBillId: row.deliveryLogisticsBillId,
            deliveryLogisticsCompanyId,
            deliveryMode,
            deliveryPackageId: row.deliveryPackageId,
            insured,
            logisticsBillGenerationMethod: LogisticsMethod.AUTOMATIC,
            logisticsConfigId: deliveryLogisticsConfig?.id,
            remark,
            serviceType: serviceType ? serviceType : null,
        };
        if (!companyId && deliveryMode !== DeliveryMode.PICK_UP) {
            return message.warning(services.language.getText('qtxkddh'));
        }

        if (pickup && (!selectedPickupDay || !selectedPickupTimeRange)) {
            return message.warning(services.language.getText('qxzyysj'));
        }
        if (pickup && selectedPickupDay && selectedPickupTimeRange) {
            const selectedPickupDate = pickupTimeRange[selectedPickupDay].date;

            params.pickupStartTime = formatDate(
                selectedPickupDate + ' ' + selectedPickupTimeRange.split('-')[0],
                'yyyy-MM-dd hh:mm:ss'
            );
            params.pickupEndTime = formatDate(
                selectedPickupDate + ' ' + selectedPickupTimeRange.split('-')[1],
                'yyyy-MM-dd hh:mm:ss'
            );
        }

        this.logisticsDeliver(params);
    };
    //发货请求
    logisticsDeliver = (params: any) => {
        this.setState({ isSubmitting: true });
        Loader.load('post', {
            params: params,
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
            apiPath: `/admin/delivery_logistics_bills`,
        })
            .then((res: any) => {
                this.showResultModal(res);
            })
            .catch(errorHandle)
            .finally(() => {
                this.setState({
                    isSubmitting: false,
                });
            });
    };
    // 根据返回结果弹出结果窗口
    showResultModal = (res: any) => {
        const { entity } = this.props;
        this.toggleModal();
        const { formData } = this.state;
        // 手动填单发货成功提示
        const confirmProps: any = {
            title: services.language.getText('fhcg'),
            content: services.language.getText('qdgbcym'),
            okText: services.language.getText('common.ok'),
            okCancel: true,
            cancelButtonProps: {
                style: {
                    display: 'none',
                },
            },
            onOk() {
                entity.search();
            },
        };
        if (res.success) {
            // 自动叫单  指定预约取件成功提示
            if (formData.pickup) {
                confirmProps.title = services.language.getText('jdcg');
                confirmProps.content = (
                    <div>
                        <div style={{ margin: '10px 0' }}>
                            {services.language.getText('yyqjwxzjdymd')}
                        </div>
                    </div>
                );
            } else {
                // 自动叫单  未指定预约取件成功提示
                confirmProps.title = services.language.getText('dymd');
                confirmProps.content = (
                    <div>
                        <div>{services.language.getText('qtgddrjdymd')}</div>
                        <img
                            style={{
                                display: 'block',
                                width: '100px',
                                height: '100px',
                                margin: '10px auto',
                            }}
                            alt={services.language.getText('wxddrj')}
                            src={printerIcon}
                        ></img>
                        <div style={{ margin: '0 auto 10px', textAlign: 'center' }}>
                            {services.language.getText('wxddrj')}
                        </div>
                    </div>
                );
            }
        } else {
            confirmProps.title = services.language.getText('jdsb');

            const reason = res && res.failedOrders ? res.failedOrders[0].reason : '';
            confirmProps.content = (
                <div>
                    <div style={{ margin: '10px 0' }}>{reason}</div>
                </div>
            );
        }
        info(confirmProps);
    };

    render() {
        const { row } = this.props;
        const {
            formData,
            expressCompanyList,
            disableSubmit,
            deliveryMode,
            visible,
            isSubmitting,
        } = this.state;
        if (!(row.logisticsBillGenerationMethod === LogisticsMethod.AUTOMATIC && !row.status)) {
            return '';
        }

        const modalProps = {
            width: 500,
            title: '',
            visible: visible,
            footer: null,
            onCancel: this.toggleModal,
        };

        return (
            <div style={{ display: 'inline' }}>
                <Button type="link" style={{ padding: 0 }} onClick={this.toggleModal}>
                    {services.language.getText('zxjd')}
                </Button>

                <Modal {...modalProps} className="automatic-delivery-modal">
                    <AutomaticDeliveryForm
                        formData={formData}
                        deliveryMode={deliveryMode}
                        expressCompanyList={expressCompanyList}
                        onChangeCompany={this.onChangeCompany}
                        changeFormData={this.changeFormData}
                        loadExpressCompany={this.loadExpressCompany}
                    ></AutomaticDeliveryForm>
                    <div className="automatic-delivery-modal__button">
                        <Button
                            loading={isSubmitting}
                            disabled={disableSubmit}
                            onClick={this.debounceSubmit}
                            type="primary"
                        >
                            {services.language.getText('zdjd')}
                        </Button>
                        <Button onClick={this.toggleModal} style={{ marginLeft: '20px' }}>
                            {services.language.getText('common.cancel')}
                        </Button>
                    </div>
                </Modal>
            </div>
        );
    }
}
