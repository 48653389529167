import { api, navigation } from '@comall-backend-builder/core/lib/services';
import { Button } from '@comall-backend-builder/components-basis';
import {
    Modal,
    message as AntMessage,
    Row as AntRow,
    Card as AntCard,
    Col as AntCol,
    Table as AntTable,
    Select,
    Popover,
} from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { map } from 'lodash';
import { CouponOrderChooseAppliesSelectorDisplay } from '../coupon-order';
import { services } from '@comall-backend-builder/core';
import { MultipleImageUpload } from '../../components';

const { Option } = Select;

interface PictureVo {
    id: number | string;
    url: string;
}
export interface CouponServiceAppliesEditProps {
    params: any;
}

export interface CouponServiceAppliesEditStates {
    data: any | null;
    couponRechargeRecords: any | undefined;
    sibilitiesData: any | null;
    responsibilityId: number;
    remark: string;
    pictures: Array<PictureVo>;
    previewPictureVisible: boolean;
    previewPictureImage: string;
}

enum CouponRechargeRecordVOStatus {
    SUCCESS = 'SUCCESS',
    FAIL = 'FAIL',
    NONE = 'NONE',
}

class couponServiceAppliesEditFrom extends Component<
    CouponServiceAppliesEditProps,
    CouponServiceAppliesEditStates
> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: null,
            couponRechargeRecords: undefined,
            sibilitiesData: null,
            responsibilityId: 0,
            remark: '',
            pictures: [],
            previewPictureVisible: false,
            previewPictureImage: '',
        };
    }

    componentDidMount() {
        const { params } = this.props;
        const id = params.id;
        //获取详细信息
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                apiPath: `/admin/service_applies/${id}`,
            }
        ).then((response: any) => {
            if (response) {
                response.serviceApplyItems = response.serviceApplyItems.map((item: any) => {
                    api.get(
                        {
                            definitionId: item.definitionId,
                        },
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                            apiPath: `/admin/service_applies/${id}/record`,
                        }
                    ).then((coupons: any) => {
                        item.coupons = coupons;
                    });
                    return item;
                });
                setTimeout(() => {
                    this.setState(
                        {
                            data: response,
                        },
                        () => {
                            this.loadCouponRechargeRecords();
                        }
                    );
                }, 200);
            }
        });
        //获取责任归属
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                apiPath: `/admin/responsibilities`,
            }
        ).then((response: any) => {
            this.setState({
                sibilitiesData: response,
                responsibilityId: 2, //默认客户责任
            });
        });
    }

    loadCouponRechargeRecords = () => {
        const { params } = this.props;
        const id = params.id;
        api.get(
            {
                serviceApplyId: id,
                direction: 'REDUCE',
                page: 1,
                perPage: 100,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                apiPath: ` /admin/coupon_recharge_records`,
            }
        ).then((response: any) => {
            if (response && response.result) {
                this.setState({
                    couponRechargeRecords: response.result,
                });
            }
        });
    };

    //加载会员信息
    renderMemberInfo = () => {
        const { data } = this.state;
        return (
            <AntCard type="inner" title={services.language.getText('hyxx')}>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('name')}:</AntCol>
                    <AntCol span={5}>{data.member.name}</AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('phoneNum')}:</AntCol>
                    <AntCol span={5}>{data.member.mobile}</AntCol>
                </AntRow>
            </AntCard>
        );
    };

    onChangeResponsibilityId = (e: any) => {
        this.setState({
            responsibilityId: e,
        });
    };

    onChangeRemark = (e: any) => {
        if (e.target.value) {
            const value = e.target.value;
            this.setState({
                remark: value,
            });
        }
    };

    handlePicturePreview = (url: string) => {
        if (url) {
            this.setState({
                previewPictureImage: url,
                previewPictureVisible: true,
            });
        }
    };

    handlePictureCancel = () => this.setState({ previewPictureVisible: false });

    renderApplyInfo = () => {
        const { data, previewPictureVisible, previewPictureImage } = this.state;
        return (
            <AntCard type="inner" title={services.language.getText('shsqxx')}>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('orderNumber')}:</AntCol>
                    <AntCol span={5}>{data.orderNo}</AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('sqlx')}:</AntCol>
                    <AntCol span={5}>{services.language.getText('thtk')}</AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('sqyy')}:</AntCol>
                    <AntCol span={5}>{data.reason.reason}</AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('bcms')}:</AntCol>
                    <AntCol span={5}>{data.memberRemark}</AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('tppz_1')}:</AntCol>
                    <AntCol span={15} style={{ display: 'flex' }}>
                        {map(data.memberPictures, (picture) => {
                            return (
                                <span key={picture.id}>
                                    <img
                                        style={{ marginRight: '10px' }}
                                        width={104}
                                        height={104}
                                        alt={services.language.getText('khpz')}
                                        src={picture.url}
                                        onClick={this.handlePicturePreview.bind(this, picture.url)}
                                    />
                                </span>
                            );
                        })}
                        <Modal
                            visible={previewPictureVisible}
                            footer={null}
                            onCancel={this.handlePictureCancel}
                        >
                            <img
                                alt="example"
                                style={{ width: '100%' }}
                                src={previewPictureImage}
                            />
                        </Modal>
                    </AntCol>
                </AntRow>
            </AntCard>
        );
    };

    onUploadImage = (value: PictureVo[]) => {
        this.setState({
            pictures: value,
        });
    };

    renderServiceInfo = () => {
        const { data, sibilitiesData, responsibilityId, pictures } = this.state;
        return (
            <AntCard type="inner" title={services.language.getText('kfjl')}>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('kfbz')}:</AntCol>
                    <AntCol span={15}>
                        <textarea
                            className="ant-input"
                            placeholder={services.language.getText('inputPlease')}
                            style={{
                                height: '73px',
                                minHeight: '73px',
                                maxHeight: '136px',
                                overflowY: 'hidden',
                            }}
                            maxLength={400}
                            onChange={(e: any) => this.onChangeRemark(e)}
                        ></textarea>
                    </AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('kfpz')}:</AntCol>
                    <AntCol span={20}>
                        <MultipleImageUpload
                            maxCount={6}
                            value={pictures}
                            onChange={this.onUploadImage}
                        />
                    </AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('zrgs')}:</AntCol>
                    <AntCol span={5}>
                        <Select
                            value={responsibilityId}
                            style={{ width: '200px' }}
                            onChange={(e: any) => this.onChangeResponsibilityId(e)}
                        >
                            {map(sibilitiesData, (item) => {
                                return (
                                    <Option key={item.id} value={item.id}>
                                        {item.name}
                                    </Option>
                                );
                            })}
                        </Select>
                    </AntCol>
                </AntRow>
                <AntRow style={{ marginBottom: 10 }}>
                    <AntCol span={3}>{services.language.getText('auditStatus')}:</AntCol>
                    <AntCol span={5}>
                        {data.status === 'APPLYING' && (
                            <span>{services.language.getText('waiting')}</span>
                        )}
                        {data.status === 'APPLY_SUCC' && (
                            <span>{services.language.getText('approved')}</span>
                        )}
                        {data.status === 'APPLY_FAIL' && (
                            <span>{services.language.getText('shbtg')}</span>
                        )}
                        {data.status === 'CANCEL' && (
                            <span>{services.language.getText('yqx')}</span>
                        )}
                    </AntCol>
                </AntRow>
            </AntCard>
        );
    };

    renderCouponInfo = () => {
        const { data } = this.state;
        const serviceApplyItems = data.serviceApplyItems;
        const tableProps = serviceApplyItems
            ? {
                  loading: false,
                  columns: [
                      {
                          title: services.language.getText('couponName'),
                          key: 'definitionName',
                          dataIndex: 'definitionName',
                          render: (text: any) => {
                              return <span>{text}</span>;
                          },
                      },
                      {
                          title: services.language.getText('qlx'),
                          key: 'couponTypeName',
                          dataIndex: 'couponTypeName',
                          render: (text: any) => {
                              return <span>{text}</span>;
                          },
                      },
                      {
                          title: services.language.getText('cllx'),
                          key: 'refundMoney',
                          dataIndex: 'refundMoney',
                          render: (text: any, row: any) => {
                              let refundMoneyName = text.join(' ');
                              if (row.refundAmount !== null && row.refundAmount === 0) {
                                  refundMoneyName = refundMoneyName.replace(
                                      'true',
                                      services.language.getText('jtk')
                                  );
                              } else {
                                  refundMoneyName = refundMoneyName.replace(
                                      'true',
                                      services.language.getText('tktq')
                                  );
                              }
                              refundMoneyName = refundMoneyName.replace(
                                  'false',
                                  services.language.getText('ztq')
                              );
                              return <span>{refundMoneyName}</span>;
                          },
                      },
                      {
                          title: services.language.getText('zxxj'),
                          key: 'executePrice',
                          dataIndex: 'executePrice',
                          render: (text: any) => {
                              return <span>{text}</span>;
                          },
                      },
                      {
                          title: services.language.getText('zxjf'),
                          key: 'pointExecutePrice',
                          dataIndex: 'pointExecutePrice',
                          render: (text: any) => {
                              return <span>{text}</span>;
                          },
                      },
                      {
                          title: services.language.getText('gmsl'),
                          key: 'originQuantity',
                          dataIndex: 'originQuantity',
                          render: (text: any) => {
                              return <span>{text}</span>;
                          },
                      },
                      {
                          title: services.language.getText('shsl'),
                          key: 'quantity',
                          dataIndex: 'quantity',
                          render: (text: any, row: any) => {
                              const hasCoupons = row.coupons && row.coupons.length > 0;
                              return hasCoupons ? (
                                  <div>
                                      <label>
                                          {row.coupons.length}
                                          <Popover
                                              placement={'top'}
                                              title={services.language.getText('ckyhq')}
                                              content={
                                                  <CouponOrderChooseAppliesSelectorDisplay
                                                      disabled={true}
                                                      orderId={row.orderId}
                                                      id={row.id}
                                                      onChange={() => {}}
                                                      data={row.coupons}
                                                  />
                                              }
                                          >
                                              <span
                                                  style={{ marginLeft: '10px', color: '#1890ff' }}
                                              >
                                                  {services.language.getText('common.view')}
                                              </span>
                                          </Popover>
                                      </label>
                                  </div>
                              ) : null;
                          },
                      },
                      {
                          title: services.language.getText('tkxj'),
                          key: 'refundPrice',
                          dataIndex: 'refundPrice',
                          render: (text: any) => {
                              return <span>{text}</span>;
                          },
                      },
                      {
                          title: services.language.getText('tkjf'),
                          key: 'pointRefundPrice',
                          dataIndex: 'pointRefundPrice',
                          render: (text: any) => {
                              return <span>{text}</span>;
                          },
                      },
                      {
                          title: services.language.getText('sftqze'),
                          key: 'refundAmount',
                          dataIndex: 'refundAmount',
                          render: (text: any) => {
                              return <span>{text}</span>;
                          },
                      },
                  ],
              }
            : {};
        return serviceApplyItems ? (
            <AntCard type="inner" title={services.language.getText('yhqshxx')}>
                <AntTable dataSource={serviceApplyItems} {...tableProps} />
            </AntCard>
        ) : null;
    };

    // 审核
    submit(auditStatus: 'SUCCESS' | 'FAIL') {
        const { data, remark, responsibilityId, pictures } = this.state;
        if (auditStatus === 'SUCCESS') {
            api.put(
                {
                    remark: remark,
                    responsibilityId: responsibilityId,
                    pictures: pictures,
                    status: 'APPLY_SUCC',
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                    apiPath: `/admin/service_applies/${data.id}`,
                }
            ).then(() => {
                AntMessage.success(services.language.getText('shtgcg'));
                navigation.goBack();
            });
        }
        if (auditStatus === 'FAIL') {
            api.put(
                {
                    status: 'APPLY_FAIL',
                },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                    apiPath: `/admin/service_applies/${data.id}`,
                }
            ).then(() => {
                AntMessage.success(services.language.getText('jjshtgcg'));
                navigation.goBack();
            });
        }
    }

    renderThirdBalanceInfo = () => {
        const { couponRechargeRecords } = this.state;
        if (!couponRechargeRecords || couponRechargeRecords.length === 0) {
            return null;
        }
        const tableProps = {
            loading: false,
            columns: [
                {
                    title: services.language.getText('sfjylsh'),
                    key: 'transactionId',
                    dataIndex: 'transactionId',
                    render: (text: any) => {
                        return <span>{text}</span>;
                    },
                },
                {
                    title: services.language.getText('couponName'),
                    key: 'couponName',
                    dataIndex: 'couponName',
                    render: (text: any) => {
                        return <span>{text}</span>;
                    },
                },
                {
                    title: services.language.getText('qbm'),
                    key: 'externalCouponCode',
                    dataIndex: 'externalCouponCode',
                    render: (text: any) => {
                        return <span>{text}</span>;
                    },
                },
                {
                    title: services.language.getText('dynamicEffectivePeriod'),
                    key: 'endTime',
                    dataIndex: 'endTime',
                    render: (text: any) => {
                        return <span>{text}</span>;
                    },
                },
                {
                    title: services.language.getText('tqze'),
                    key: 'totalAmount',
                    dataIndex: 'totalAmount',
                    render: (text: any) => {
                        if (!text) {
                            return null;
                        }
                        return <span>{Math.abs(text)}</span>;
                    },
                },
                {
                    title: services.language.getText('clzt'),
                    key: 'status',
                    dataIndex: 'status',
                    render: (text: any) => {
                        if (!text) {
                            return null;
                        }
                        const statusText: any = {
                            [CouponRechargeRecordVOStatus.SUCCESS]: services.language.getText(
                                'kqcg'
                            ),
                            [CouponRechargeRecordVOStatus.FAIL]: services.language.getText('kqsb'),
                            [CouponRechargeRecordVOStatus.NONE]: services.language.getText('wcl'),
                        };
                        return <span>{statusText[text]}</span>;
                    },
                },
                {
                    title: services.language.getText('reason'),
                    key: 'result',
                    dataIndex: 'result',
                    render: (text: any) => {
                        return <span>{text}</span>;
                    },
                },
                {
                    title: services.language.getText('czsj'),
                    key: 'rechargeTime',
                    dataIndex: 'rechargeTime',
                    render: (text: any) => {
                        return <span>{text}</span>;
                    },
                },
            ],
        };
        return (
            <AntCard type="inner" title={services.language.getText('sfqclmx')}>
                <AntTable dataSource={couponRechargeRecords} {...tableProps} />
            </AntCard>
        );
    };

    render() {
        const { data } = this.state;
        return data ? (
            <div className="coupon-service-applies-edit-form">
                <AntCard>
                    <AntRow style={{ marginBottom: 20 }}>{this.renderMemberInfo()}</AntRow>
                    <AntRow style={{ marginBottom: 20 }}>{this.renderApplyInfo()}</AntRow>
                    <AntRow style={{ marginBottom: 20 }}>{this.renderServiceInfo()}</AntRow>
                    <AntRow style={{ marginBottom: 20 }}>{this.renderCouponInfo()}</AntRow>
                    <AntRow style={{ marginBottom: 20 }}>{this.renderThirdBalanceInfo()}</AntRow>
                </AntCard>
                <div style={{ textAlign: 'center', marginTop: '30px' }}>
                    {data.status === 'APPLYING' && (
                        <Button
                            type="primary"
                            onClick={this.submit.bind(this, 'SUCCESS')}
                            style={{ marginRight: 24 }}
                        >
                            {services.language.getText('bcbtgsh')}
                        </Button>
                    )}
                    {data.status === 'APPLYING' && (
                        <Button
                            type="primary"
                            onClick={this.submit.bind(this, 'FAIL')}
                            style={{ marginRight: 24 }}
                        >
                            {services.language.getText('shbtg')}
                        </Button>
                    )}
                    <Button route="goBack">{services.language.getText('back')}</Button>
                </div>
            </div>
        ) : null;
    }
}
function mapStateToProps(_state: any, props: any) {
    const { params } = props;
    return {
        params,
    };
}
export const CouponServiceAppliesEditFrom = connect(mapStateToProps)(couponServiceAppliesEditFrom);
