import { Button } from '@comall-backend-builder/components-basis';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { api, errorHandle, language } from '@comall-backend-builder/core/lib/services';
import { Modal as AntModal, Input as AntInput, message as AntMessage, Select } from 'antd';
import React, { Component } from 'react';
import { find, trim } from 'lodash';

export interface OfflineOrderCommentsReplyProps {
    fields: any;
    row: any;
    entity: Entity;
    componentId: string;
}
interface OfflineOrderCommentsReplyState {
    modalVisible: boolean;
    remark: string;
    chooseReplyId: string;
    replyData: any[];
}

export class OfflineOrderCommentsReply extends Component<
    OfflineOrderCommentsReplyProps,
    OfflineOrderCommentsReplyState
> {
    constructor(props: OfflineOrderCommentsReplyProps) {
        super(props);
        this.state = {
            modalVisible: false,
            remark: '',
            chooseReplyId: '',
            replyData: [],
        };
    }

    isSubmitReply = false;

    loadQuickReplySettings = async () => {
        const {
            row: { subsiteId },
        } = this.props;
        const config = {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COMMENT`,
            apiPath: '/admin/reply_common_words_configs',
        };
        const result: any = await api.get(
            {
                subsiteIds: subsiteId,
            },
            config
        );
        if (result) {
            this.setState({
                replyData: result,
            });
        }
    };

    handleChange = (val: any) => {
        const { replyData } = this.state;
        const row = find(replyData, { id: val });
        let remark = '';
        if (row) {
            remark = row.content;
        }
        if (val === 0) {
            remark = '';
        }
        this.setState({
            chooseReplyId: val,
            remark: remark,
        });
    };

    open = () => {
        this.loadQuickReplySettings();
        this.setState({ modalVisible: true });
    };

    close = () => {
        this.setState({ modalVisible: false, remark: '', chooseReplyId: '' });
    };

    inputChange = (e: any) => {
        const { value } = e.target;
        this.setState({ remark: value });
    };

    submit = () => {
        const { remark } = this.state;

        if (!trim(remark)) {
            AntMessage.error(language.getText('qsrhfnr'));
            return;
        }

        if (this.isSubmitReply) {
            return;
        }

        this.isSubmitReply = true;

        const {
            entity,
            row: { id, subsiteId },
        } = this.props;
        api.post(
            {
                objectId: id,
                scene: 'COMMENT',
                subsiteId: subsiteId,
                content: remark,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COMMENT`,
                apiPath: '/admin/comments',
            }
        )
            .then(() => {
                AntMessage.success(language.getText('hfcg'));

                this.close();

                // 列表数据刷新
                entity.search({
                    ...entity.params,
                    ...entity.filters,
                });
            })
            .catch((error) => {
                errorHandle(error);
                this.isSubmitReply = false;
            });
    };

    render() {
        const { modalVisible, remark, replyData, chooseReplyId } = this.state;
        const {
            row: { canReply },
        } = this.props;
        return canReply ? (
            <span style={{ marginRight: '10px' }}>
                <Button type="link" onClick={this.open} text={language.getText('hf')} />
                <AntModal
                    visible={modalVisible}
                    okText={language.getText('submit')}
                    title={language.getText('hf')}
                    onOk={this.submit}
                    onCancel={this.close}
                    cancelButtonProps={{
                        onClick: this.close,
                    }}
                >
                    <div>
                        <Select
                            style={{ marginBottom: '20px', width: '100%' }}
                            value={chooseReplyId ? chooseReplyId : undefined}
                            placeholder={language.getText('kjhf')}
                            onSelect={(transAction: any) => this.handleChange(transAction)}
                            allowClear
                        >
                            {replyData &&
                                replyData.map((option) => (
                                    <Select.Option key={option.id}>{option.content}</Select.Option>
                                ))}
                        </Select>
                        <AntInput.TextArea
                            placeholder={language.getText('qsrhfnr')}
                            autoSize={{ minRows: 4, maxRows: 4 }}
                            onChange={this.inputChange}
                            value={remark}
                            maxLength={500}
                        />
                    </div>
                </AntModal>
            </span>
        ) : (
            <span style={{ marginRight: '10px' }}>
                <Button type="link" disabled text={language.getText('hf')} />
            </span>
        );
    }
}
