import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';

import { invoiceContentsText } from '../../../containers/invoice/normal-invoice-configs-header';
import { forEach } from 'lodash';

const { getText } = services.language;

export const config: Config = {
    entities: {
        ReceiptInvoiceConfigsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
            apiPath: '/loader/admin/receipt_invoice_configs',
            filters: {
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 300 },
                    },
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'true', name: '<<yxkp>>' },
                        { id: 'false', name: '<<jzkp>>' },
                    ],
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 200 },
                    },
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseInfo>>',
                    properties: {
                        subsiteId: {
                            type: 'string.options.select',
                            displayName: '<<kkpmd>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [
                                {
                                    required: true,
                                    message: '<<qxzkkpmd>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 300,
                                },
                                placeholder: '<<selectPlease>>',
                                allowClear: true,
                                showSearch: true,
                            },
                        },
                    },
                },
                invoiceSystemInfo: {
                    type: 'object.subForm',
                    displayName: '<<swxtsz>>',
                    properties: {
                        invoiceSystem: {
                            type: 'object.invoice.system',
                            displayName: '<<xzswxt>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            controlConfig: {
                                searchParams: {
                                    type: 'POS_RECEIPT',
                                },
                            },
                        },
                    },
                },
                otherInfo: {
                    type: 'object.subForm',
                    displayName: '<<ywsz>>',
                    properties: {
                        invoiceMode: {
                            type: 'string.invoice.mode',
                            displayName: '<<kkfpzl>>',
                            rules: [
                                {
                                    required: true,
                                    message: '<<qxzkkfpzl>>',
                                },
                            ],
                        },
                        contents: {
                            type: 'array.optionIds.checkbox',
                            displayName: '<<zckpnr>>',
                            defaultValue: ['PRODUCT_DETAIL'],
                            options: [
                                {
                                    name: '<<productDetail>>',
                                    id: 'PRODUCT_DETAIL',
                                },
                            ],
                            rules: [
                                {
                                    required: true,
                                    message: '<<kpnrzsxzyz>>',
                                },
                            ],
                        },
                    },
                },
                id: {
                    type: 'string',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                invoiceMode: {
                    type: 'string.options.select',
                    displayName: '<<kpzl>>',
                    options: [
                        { id: 'TAX_CONTROL', name: '<<skfp>>' },
                        { id: 'DIGITAL_ELECTRONIC', name: '<<sdp>>' },
                    ],
                },
                contents: {
                    type: 'string.formatter',
                    displayName: '<<kpnr>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    options: [
                        { id: 'true', name: '<<yxkp>>' },
                        { id: 'false', name: '<<jzkp>>' },
                    ],
                },
            },
        },
    },
    components: {
        PosReceiptInvoiceConfigsView: {
            component: 'Viewport',
        },
        PosReceiptInvoiceConfigsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'ReceiptInvoiceConfigsPage' }],
        },
        ReceiptInvoiceConfigsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'ReceiptInvoiceConfigsEntity',
            items: [
                {
                    component: 'ReceiptInvoiceConfigsFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mb24',
                    style: {
                        marginTop: 18,
                    },
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzmdpzdzxpfp>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/pos-receipt-invoice-config/add-receipt',
                                },
                            ],
                        },
                    ],
                },
                { component: 'ReceiptInvoiceConfigsTable' },
            ],
        },
        ReceiptInvoiceConfigsTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'subsiteName',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'invoiceMode',
                },
                {
                    property: 'contents',
                    displayConfig: {
                        format: (value: string[]) => {
                            return value
                                .map((item) => getText(invoiceContentsText[item]))
                                .join('、');
                        },
                    },
                    width: '240px',
                },
                {
                    property: 'status',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 220,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
                                    apiPath: '/admin/invoice_configs/:id/disabled',
                                    config: {
                                        content: '<<jzkp>>',
                                        text: '<<jzkp>>',
                                    },
                                    confirm: {
                                        text: '<<sfjzkp>>?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`,
                                    apiPath: '/admin/invoice_configs/:id/enabled',
                                    config: {
                                        content: '<<yxkp>>',
                                        text: '<<yxkp>>',
                                    },
                                    confirm: {
                                        text: '<<sfyxkp>>?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        path: '/pos-receipt-invoice-config/edit-receipt/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<fzpz>>',
                        path: '/pos-receipt-invoice-config/copy-receipt/{{row.id}}',
                    },
                ],
            },
        },
        ReceiptInvoiceConfigsFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteIds',
                },
                {
                    property: 'status',
                },
            ],
        },
        ReceiptInvoiceConfigsAddPage: {
            component: 'Card',
            content: { component: 'ReceiptInvoiceConfigsAddForm' },
        },
        ReceiptInvoiceConfigsAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'ReceiptInvoiceConfigsEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.subsiteId' },
                { property: 'invoiceSystemInfo.invoiceSystem' },
                { property: 'otherInfo.invoiceMode' },
                { property: 'otherInfo.contents' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                if (
                    entity.invoiceSystemInfo &&
                    entity.invoiceSystemInfo.invoiceSystem &&
                    entity.invoiceSystemInfo.invoiceSystem.id
                ) {
                    const invoiceSystem = entity.invoiceSystemInfo.invoiceSystem;
                    if (invoiceSystem.params) {
                        let hasValidate = true;
                        if (invoiceSystem.params) {
                            forEach(invoiceSystem.params, (param) => {
                                param.value = param.value.replace(/\s*/g, '');
                                if (param.required && (!param.value || param.value === null)) {
                                    AntMessage.warning(`${getText('selectFillin')}${param.name}`);
                                    hasValidate = false;
                                    return false;
                                }
                            });
                        }
                        if (!hasValidate) {
                            return false;
                        }
                    }
                } else {
                    AntMessage.warning(getText('qxzswxt'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        ReceiptInvoiceConfigsEditPage: {
            component: 'Card',
            content: { component: 'ReceiptInvoiceConfigsEditForm' },
        },
        ReceiptInvoiceConfigsEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            loaderType: 'get',
            entity: 'ReceiptInvoiceConfigsEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.subsiteId' },
                { property: 'invoiceSystemInfo.invoiceSystem' },
                { property: 'otherInfo.invoiceMode' },
                { property: 'otherInfo.contents' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                if (
                    entity.invoiceSystemInfo &&
                    entity.invoiceSystemInfo.invoiceSystem &&
                    entity.invoiceSystemInfo.invoiceSystem.id
                ) {
                    const invoiceSystem = entity.invoiceSystemInfo.invoiceSystem;
                    if (invoiceSystem.params) {
                        let hasValidate = true;
                        if (invoiceSystem.params) {
                            forEach(invoiceSystem.params, (param) => {
                                param.value = param.value.replace(/\s*/g, '');
                                if (param.required && (!param.value || param.value === null)) {
                                    AntMessage.warning(`${getText('selectFillin')}${param.name}`);
                                    hasValidate = false;
                                    return false;
                                }
                            });
                        }
                        if (!hasValidate) {
                            return false;
                        }
                    }
                } else {
                    AntMessage.warning(getText('qxzswxt'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.modifySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        ReceiptInvoiceConfigsCopyPage: {
            component: 'Card',
            content: { component: 'ReceiptInvoiceConfigsCopyForm' },
        },
        ReceiptInvoiceConfigsCopyForm: {
            component: 'CopyFormPlus',
            direction: 'horizontal',
            loaderType: 'get',
            entity: 'ReceiptInvoiceConfigsEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.subsiteId' },
                { property: 'invoiceSystemInfo.invoiceSystem' },
                { property: 'otherInfo.invoiceMode' },
                { property: 'otherInfo.contents' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                if (
                    entity.invoiceSystemInfo &&
                    entity.invoiceSystemInfo.invoiceSystem &&
                    entity.invoiceSystemInfo.invoiceSystem.id
                ) {
                    const invoiceSystem = entity.invoiceSystemInfo.invoiceSystem;
                    if (invoiceSystem.params) {
                        let hasValidate = true;
                        if (invoiceSystem.params) {
                            forEach(invoiceSystem.params, (param) => {
                                param.value = param.value.replace(/\s*/g, '');
                                if (param.required && (!param.value || param.value === null)) {
                                    AntMessage.warning(`${getText('selectFillin')}${param.name}`);
                                    hasValidate = false;
                                    return false;
                                }
                            });
                        }
                        if (!hasValidate) {
                            return false;
                        }
                    }
                } else {
                    AntMessage.warning(getText('qxzswxt'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(getText('copySuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/pos-receipt-invoice-config',
                    component: 'PosReceiptInvoiceConfigsView',
                    breadcrumbName: '<<posReceiptInvoiceConfig>>',
                    privilege: {
                        path: 'posReceiptInvoiceConfig',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add-receipt',
                            component: 'ReceiptInvoiceConfigsAddPage',
                            breadcrumbName: '<<xzmddzxpfppz>>',
                        },
                        {
                            path: '/edit-receipt/:id',
                            component: 'ReceiptInvoiceConfigsEditPage',
                            breadcrumbName: '<<bjmddzxpfppz>>',
                        },
                        {
                            path: '/copy-receipt/:id',
                            component: 'ReceiptInvoiceConfigsCopyPage',
                            breadcrumbName: '<<fzmddzxpfppz>>',
                        },
                        { path: '/', component: 'PosReceiptInvoiceConfigsPage' },
                    ],
                },
            ],
        },
    ],
};
