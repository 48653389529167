import React, { PureComponent } from 'react';
import { Form, Radio } from 'antd';
import { services } from '@comall-backend-builder/core';
import { tools } from '@comall-backend-builder/design-components-basis';
import { ShowCoupons } from './show-coupons';
import './index.less';

const FormItem = Form.Item;

const RadioGroup = Radio.Group;
const language = services.language;
const ColorPicker = tools.ColorPicker;
const UploadPicture = tools.UploadPicture;
/**
 * 优惠券组件-配置表单
 */
class coupon extends PureComponent<any> {
    render() {
        const { form, linkTypes, selector, sourceRoot, uploadAction } = this.props;
        const { getFieldDecorator } = form;
        const formItemLayout = tools.FormItemLayout;
        return (
            <Form className="editor-coupon">
                <FormItem {...formItemLayout} label={language.getText('components.Coupon.addMode')}>
                    <div>
                        <div>
                            {getFieldDecorator('addMode', { initialValue: 'manual' })(
                                <RadioGroup className="add-mode">
                                    <Radio value={'manual'}>
                                        {language.getText('components.Coupon.manual')}
                                    </Radio>
                                </RadioGroup>
                            )}
                        </div>
                        <div>
                            {getFieldDecorator('entry', {
                                initialValue: [],
                            })(<ShowCoupons selector={selector} linkTypes={linkTypes} />)}
                        </div>
                    </div>
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={language.getText('components.Coupon.backgroundColor')}
                >
                    {getFieldDecorator('backgroundColor', {})(<ColorPicker />)}
                </FormItem>
                <FormItem
                    {...formItemLayout}
                    label={language.getText('components.Coupon.couponBaseImage')}
                >
                    <div>
                        <div className="image-wrap">
                            {getFieldDecorator('couponBaseImage')(
                                <UploadPicture
                                    className="image-uploader"
                                    sourceRoot={sourceRoot}
                                    uploadAction={uploadAction}
                                />
                            )}
                            <span>{language.getText('components.Coupon.couponBaseImageSize')}</span>
                        </div>
                    </div>
                </FormItem>
                {/* <FormItem
                    {...formItemLayout}
                    label={language.getText('components.Coupon.drawButtonBaseImage')}
                >
                    <div>
                        <div>{getFieldDecorator('showDrawButton')(<Switch />)}</div>

                        <div className="image-wrap">
                            {getFieldDecorator('drawButtonBaseImage')(
                                <UploadPicture
                                    className="image-uploader"
                                    sourceRoot={sourceRoot}
                                    uploadAction={uploadAction}
                                />
                            )}
                            <span>
                                {language.getText('components.Coupon.drawButtonBaseImageSize')}
                            </span>
                        </div>
                    </div>
                </FormItem> */}
                {/* <FormItem
                    {...formItemLayout}
                    label={language.getText('components.Coupon.showContent')}
                >
                    <div>
                        {getFieldDecorator('showContent', {
                            initialValue: ['name', 'amount', 'lowerLimit', 'drawProgress'],
                        })(<ShowContent form={form} />)}
                    </div>
                </FormItem> */}
            </Form>
        );
    }
}

export const Coupon = tools.ConfigFormDecorator(coupon);
