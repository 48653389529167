import React, { Component, Fragment } from 'react';
import { Button, Tabs as AntTabs, Popover, Icon, message } from 'antd';
import { forEach } from 'lodash';
import { api, localStorage, privilege } from '@comall-backend-builder/core/lib/services';
import { builder, EntitiesManager, services } from '@comall-backend-builder/core';
import {
    MessagesSubscriptionFindListItem,
    MessagesSubscriptionFindListItemInfo,
    MsgChannelType,
} from '../messages-subscription-find-list-item';
import { MessagesSubscriptionFindListItemEditModal } from '../components/messages-subscription-find-list-item-edit-modal';
import { MessagesSubscripitonPageViewStoreSelector } from '../components/messages-subscripiton-page-view-store-selector';
import { PushAndSiteMailSceneType } from '../../../containers/wechat/common';
import './index.less';

const AntTabPane = AntTabs.TabPane;

//是否有门店数据
const hasStoreInfo = () => !!localStorage.get('wechantTemplatesStore');

const getStoreId = () => {
    let storeInfo: any = localStorage.get('wechantTemplatesStore');
    if (storeInfo && storeInfo.id) {
        return storeInfo.id;
    } else {
        return '';
    }
};

interface ActiveKey {
    id: string;
    name: string;
}

interface MessagesSubscriptionPageViewStates {
    /**
     * 当前选中的tab
     */
    activeKey: ActiveKey | undefined;
    /**
     * tab集合
     */
    activeKeyList: Array<ActiveKey> | undefined;
    /**
     * 添加弹层
     */
    addModalVisible: boolean;
    /**
     * 模板集合
     */
    items: Array<{
        categoryId: number;
        categoryName: string;
        templates: Array<any>;
    }>;
    /**
     * 手动发送tab下的模板切换二级tab
     */
    manualTabKey?: string;
}

let Entity: any = {};
let entity: any = {};
/**
 * 动态创建订单调度台各个订单状态的tab
 */
export class MessagesSubscriptionPageView extends Component<
    any,
    MessagesSubscriptionPageViewStates
> {
    dispatch: any = {};
    constructor(props: any) {
        super(props);
        this.dispatch = builder.getStore().dispatch;
        Entity = EntitiesManager.get('storeSelect');
        entity = new Entity({});
        this.state = {
            activeKey: undefined,
            activeKeyList: undefined,
            addModalVisible: false,
            items: [],
            manualTabKey: undefined,
        };
    }

    componentDidMount() {
        this.fetchActiveKeyList();
    }

    /**
     * 获取tab集合
     */
    fetchActiveKeyList() {
        this.setState({
            activeKeyList: undefined,
        });
        let fetch = () => {
            if (!hasStoreInfo()) {
                return;
            }
            if (this.timer) {
                clearInterval(this.timer);
            }
            api.get(
                { subsiteId: getStoreId() },
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                    apiPath: '/admin/template_categories',
                }
            ).then((res: any) => {
                if (res && res.length > 0) {
                    this.setState(
                        {
                            activeKeyList: res,
                        },
                        () => {
                            this.onChange(res[0].id);
                        }
                    );
                }
            });
        };
        this.timer = setInterval(fetch, 100);
        fetch();
    }
    timer: any;

    componentWillUnmount() {
        clearInterval(this.timer);
    }

    /**
     * 获取当前tab下的模板
     */
    loadItems = async () => {
        const { activeKey } = this.state;
        if (!activeKey) {
            return;
        }
        const params = {
            subsiteId: hasStoreInfo() ? getStoreId() : '',
            firstCategoryId: activeKey.id,
        };
        api.get(params, {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
            apiPath: '/admin/template_definitions/groups',
        }).then((res: any) => {
            this.setState({
                items:
                    res && res.length > 0
                        ? res.map((item: any) => ({
                              ...item,
                              status: item.id ? true : false,
                          }))
                        : undefined,
            });
        });
    };

    /**
     * 切换tab查询
     * @param id
     */
    onChange = (id: string) => {
        const { activeKeyList } = this.state;
        const currenActiveKey = activeKeyList?.find((a) => a.id === id);
        if (currenActiveKey) {
            this.setState(
                {
                    activeKey: currenActiveKey,
                    manualTabKey:
                        currenActiveKey.name === '手动发送' ? MsgChannelType.WEAPP : undefined,
                },
                () => {
                    this.loadItems();
                }
            );
        }
    };

    /**
     * 关闭弹层
     */
    handleVisible = () => {
        const { addModalVisible } = this.state;
        this.setState({
            addModalVisible: !addModalVisible,
        });
    };

    /**
     * 切换手动发送下的二级tab
     * @param type
     */
    searchManual = (type: MsgChannelType) => {
        this.setState(
            {
                manualTabKey: type,
                items: [],
            },
            () => {
                this.loadItems();
            }
        );
    };

    renderAddButton = () => {
        const { addModalVisible, manualTabKey } = this.state;
        const { activeKey } = this.state;
        if (activeKey && activeKey.name === '手动发送') {
            return (
                <div>
                    <div>
                        <Button
                            type={manualTabKey === MsgChannelType.WEAPP ? 'primary' : 'default'}
                            onClick={this.searchManual.bind(this, MsgChannelType.WEAPP)}
                            style={{ marginBottom: '15px', width: '123px', borderRadius: '10px' }}
                        >
                            {services.language.getText('dyxx')}
                        </Button>
                        <Button
                            type={
                                manualTabKey === MsgChannelType.WECHAT_OFFICIAL_ACCOUNT
                                    ? 'primary'
                                    : 'default'
                            }
                            onClick={this.searchManual.bind(
                                this,
                                MsgChannelType.WECHAT_OFFICIAL_ACCOUNT
                            )}
                            style={{
                                marginBottom: '15px',
                                width: '123px',
                                borderRadius: '10px',
                                marginLeft: '20px',
                            }}
                        >
                            {services.language.getText('wechatTemplate')}
                        </Button>
                    </div>
                    {manualTabKey && manualTabKey === MsgChannelType.WECHAT_OFFICIAL_ACCOUNT && (
                        <Button
                            type="primary"
                            onClick={this.handleVisible}
                            style={{ marginBottom: '15px' }}
                        >
                            {services.language.getText('xzmb')}
                        </Button>
                    )}
                    {addModalVisible && (
                        <MessagesSubscriptionFindListItemEditModal
                            refreshList={this.loadItems}
                            handleVisible={this.handleVisible}
                            visible={addModalVisible}
                            msgChannelType={MsgChannelType.WECHAT_OFFICIAL_ACCOUNT}
                        />
                    )}
                </div>
            );
        }
        return null;
    };

    renderEmpty = () => {
        return <div className={innerClassNames.empty}> {services.language.getText('notData')}</div>;
    };

    renderItems = () => {
        const { items, activeKey, manualTabKey } = this.state;
        if (!activeKey) {
            return null;
        }
        if (!items || items.length === 0) {
            return this.renderEmpty();
        }
        if (activeKey.name === '手动发送') {
            const newTemplate: MessagesSubscriptionFindListItemInfo[] = [];
            forEach(items, (item) => {
                forEach(item.templates, (template: MessagesSubscriptionFindListItemInfo) => {
                    if (manualTabKey && template.msgChannelType === manualTabKey) {
                        newTemplate.push(template);
                    }
                });
            });
            if (newTemplate.length === 0) {
                return this.renderEmpty();
            }
            return (
                <div className={innerClassNames.list}>
                    {newTemplate.map((item: MessagesSubscriptionFindListItemInfo) => {
                        const props = {
                            ...item,
                            refreshList: this.loadItems,
                            activeKeyName: activeKey?.name,
                        };
                        return <MessagesSubscriptionFindListItem {...props} />;
                    })}
                </div>
            );
        }
        return (
            <div>
                <div className={innerClassNames.list}>
                    {items.map((item) => {
                        return (
                            <div className={innerClassNames.item}>
                                {activeKey && activeKey.name !== '手动发送' && (
                                    <div className={innerClassNames.itemTop}>
                                        <span>{item.categoryName}</span>
                                        {this.renderPopover(item.templates)}
                                    </div>
                                )}
                                {item.templates.map(
                                    (template: MessagesSubscriptionFindListItemInfo) => {
                                        const props = {
                                            ...template,
                                            refreshList: this.loadItems,
                                            activeKeyName: activeKey?.name,
                                        };
                                        return <MessagesSubscriptionFindListItem {...props} />;
                                    }
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    };

    createOnGoToPage = (url: string, permissionKey: string) => {
        return () => {
            if (privilege.check(permissionKey, 'full')) {
                window.open(url);
            } else {
                message.warning(services.language.getText('noPerationPermission'));
            }
        };
    };

    renderPopover = (templates: any[]) => {
        const [tmp] = templates;
        const isMall =
            tmp.scene === PushAndSiteMailSceneType.MALL_ACTIVITY_REGISTRATION_SUCCEEDED_MSG ||
            tmp.scene === PushAndSiteMailSceneType.MALL_ACTIVITY_PARTICIPATE_MSG ||
            tmp.scene === PushAndSiteMailSceneType.MALL_ACTIVITY_REGISTRATION_ABSENTED_MSG ||
            tmp.scene === PushAndSiteMailSceneType.MALL_ACTIVITY_REGISTRATION_STARTED_MSG;
        if (tmp) {
            const URLS = {
                BIRTHDAY_CARING: '#/birthday-caring',
                PAY_WITH_GIFTS: '#/menu-list/market/pay-with-gifts',
                NEW_MALL_ACTIVITY: '#/new-mall-activity',
                LOTTERY_CODE: '#/lottery-code',
            };

            const PERMISSION_KEYS = {
                BIRTHDAY_CARING: 'birthdayCaring',
                PAY_AWARD: 'payAward',
                NEW_MALL_ACTIVITY: 'newMallActivity',
                LOTTERY_CODE: 'lotteryCode',
            };
            let onGoToPage = undefined;
            let buttonText = '';
            if (tmp.scene === PushAndSiteMailSceneType.BIRTHDAY_GIFT_RECEIVE_REMIND) {
                buttonText = services.language.getText('birthdayCaring');
                onGoToPage = this.createOnGoToPage(
                    URLS.BIRTHDAY_CARING,
                    PERMISSION_KEYS.BIRTHDAY_CARING
                );
            }
            if (tmp.scene === PushAndSiteMailSceneType.PAY_AWARD_RECEIVE_REMIND) {
                buttonText = services.language.getText('zfyl');
                onGoToPage = this.createOnGoToPage(URLS.PAY_WITH_GIFTS, PERMISSION_KEYS.PAY_AWARD);
            }
            if (isMall) {
                buttonText = services.language.getText('newMallActivity');
                onGoToPage = this.createOnGoToPage(
                    URLS.NEW_MALL_ACTIVITY,
                    PERMISSION_KEYS.NEW_MALL_ACTIVITY
                );
            }
            if (tmp.scene === PushAndSiteMailSceneType.LOTTERY_CODE_AWARD_NOTICE) {
                buttonText = services.language.getText('lotteryCode');
                onGoToPage = this.createOnGoToPage(URLS.LOTTERY_CODE, PERMISSION_KEYS.LOTTERY_CODE);
            }
            if (buttonText && onGoToPage) {
                const content = (
                    <div style={{ width: 240 }}>
                        {services.language.getText('xxnrmbpz')}
                        <Button type="link" onClick={onGoToPage}>
                            {buttonText}
                        </Button>
                    </div>
                );
                return (
                    <Popover content={content}>
                        <Icon style={{ marginLeft: 10 }} type="question-circle" />
                    </Popover>
                );
            }
            return null;
        }
        return null;
    };

    renderActiveKeyList = () => {
        const { activeKey, activeKeyList } = this.state;
        if (!activeKeyList || activeKeyList.length === 0 || !activeKey) {
            return null;
        }
        return (
            <AntTabs
                className={innerClassNames.tabs}
                activeKey={activeKey.id}
                animated={false}
                onChange={this.onChange}
            >
                {activeKeyList.map((activeKeyVo) => {
                    return (
                        <AntTabPane tab={activeKeyVo.name} key={activeKeyVo.id}>
                            <div></div>
                        </AntTabPane>
                    );
                })}
            </AntTabs>
        );
    };

    render() {
        const { activeKeyList } = this.state;
        return (
            <Fragment>
                <div className={prefix}>
                    <MessagesSubscripitonPageViewStoreSelector
                        refresh={this.loadItems}
                        entity={entity}
                        activeKeyList={activeKeyList}
                    />
                    {this.renderActiveKeyList()}
                    {this.renderAddButton()}
                    {this.renderItems()}
                </div>
            </Fragment>
        );
    }
}

const prefix = 'messages-subscription-page-view';
const innerClassNames = {
    tabs: `${prefix}__tabs`,
    empty: `${prefix}__empty`,
    list: `${prefix}__list`,
    item: `${prefix}__item`,
    itemTop: `${prefix}__item__top`,
};
