import { DataFormPlus } from '../data-form-plus';
import { connect } from 'react-redux';
import { actions } from '@comall-backend-builder/core';
import { Dispatch } from 'redux';
import { defaults, mapValues } from 'lodash';
import { Entity } from '@comall-backend-builder/core/lib/parser';

function mapDispatchToProps(dispatch: Dispatch, props: { entity: Entity } & Record<string, any>) {
    const { componentId, entity, onInit, onSubmit, onReset, onValidate } = props;

    return defaults(
        {
            onInit,
            onSubmit,
            onReset,
            onValidate,
        },
        {
            formType: 'ModifyForm',
            // 提交表单
            onSubmit: function(_event: Event, fields: any[]) {
                if (_event) {
                    _event.stopPropagation();
                }
                var entityFields = mapValues(fields, function(field, _name) {
                    return field.value;
                });
                if (!onValidate) {
                    entity.add(entityFields, props.params);
                } else {
                    const hasValidate = onValidate(entityFields);
                    if (hasValidate) {
                        entity.add(entityFields, props.params);
                    }
                }
            },
            onSubmitStart: function() {
                dispatch(actions.componentSubmitAction(componentId, 'create'));
            },
        }
    );
}

export const CopyFormPlus = connect(null, mapDispatchToProps)(DataFormPlus);
