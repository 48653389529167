import React, { PureComponent } from 'react';
import './index.less';
import { services } from '@comall-backend-builder/core';
/**
 * 可视化会员中心组件选择器
 */
export class CustomComponentSelectorMember extends PureComponent<{
    onSelect: (value: any) => void;
}> {
    baseComponents = [
        {
            id: 'imageAd',
            name: '图片广告',
        },
        {
            id: 'nav',
            name: '导航',
        },
        {
            id: 'showcase',
            name: '橱窗',
        },
        {
            id: 'title',
            name: '标题',
        },
        {
            id: 'text',
            name: '文本',
        },
        {
            id: 'divider',
            name: services.language.getText('fzx'),
        },
        {
            id: 'space',
            name: '辅助空白',
        },
        {
            id: 'noticeBar',
            name: '通知栏',
        },
    ];
    marketingComponents = [
        { id: 'memberCard', name: '会员卡' },
        { id: 'memberAssets', name: '我的资产' },
        { id: 'memberOrder', name: '我的订单' },
        // { id: 'MemberDistribution', name: '分销中心' },
        // { id: 'MemberGroup', name: '社区团购' },
        { id: 'memberService', name: '服务工具' },
        { id: 'memberContentInteraction', name: '互动数据' },
        { id: 'contentNoteMine', name: '我的笔记' },
        { id: 'memberCover', name: '会员卡面' },
        { id: 'persionalProfile', name: '个人信息' },
    ];
    othersComponents = [{ id: 'weixin', name: '关注公众号' }];

    onSelect = (id: string) => {
        return () => this.props.onSelect(id);
    };

    render() {
        const base = this.baseComponents;
        const marketing = this.marketingComponents;
        const others = this.othersComponents;

        return (
            <div className="custom-component-selector">
                <div className="group">
                    <div className="group-title">基础组件</div>
                    <div className="group-content">
                        {base.map((i) => (
                            <div key={i.id} className="item" onClick={this.onSelect(i.id)}>
                                {i.name}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="group">
                    <div className="group-title">会员组件</div>
                    <div className="group-content">
                        {marketing.map((i) => (
                            <div key={i.id} className="item" onClick={this.onSelect(i.id)}>
                                {i.name}
                            </div>
                        ))}
                    </div>
                </div>
                <div className="group">
                    <div className="group-title">其他组件</div>
                    <div className="group-content">
                        {others.map((i) => (
                            <div key={i.id} className="item" onClick={this.onSelect(i.id)}>
                                {i.name}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}
