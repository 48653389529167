import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach, isEmpty, map } from 'lodash';
import moment from 'moment';
import { ReceiveLimitEnum } from '../../config/promotion/coupon/cash-coupon-rules';

const api = services.api;
export const ParkingCouponRulesLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        config.apiPath = '/admin/parking_coupon_rules';
        const { id, type = '' } = data;
        if (id) {
            config.apiPath = `/admin/parking_coupon_rules/${id}`;
        } else {
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.id = res.couponRule.id;
                let usedChannelLimit: any = [];
                let consumeRuleMemberLevels: any = [];
                let receiveRuleMemberLevels: any = [];
                forEach(res.consumeRule.dimensions, (dimension) => {
                    if (dimension.dimensionType === 'CHANNEL') {
                        usedChannelLimit = dimension.ids.map((value: any) => {
                            return value + '';
                        });
                    }
                    if (dimension.dimensionType === 'MEMBER_CARD_LEVEL') {
                        forEach(dimension.ids, (id: any) => {
                            consumeRuleMemberLevels.push({
                                id: id + '',
                            });
                        });
                    }
                });
                if (res.receiveRule.cardLevels && res.receiveRule.cardLevels.length > 0) {
                    forEach(res.receiveRule.cardLevels, (levelId: any) => {
                        receiveRuleMemberLevels.push({
                            id: levelId + '',
                        });
                    });
                }
                res.baseInfo = {
                    name: res.couponRule.name,
                    dateRange: {
                        start: res.consumeEffectivePeriodRule.period.startTime,
                        end: res.consumeEffectivePeriodRule.period.endTime,
                    },
                    dynamicEffectivePeriod: res.consumeEffectivePeriodRule.dynamicEffectivePeriod,
                    batchNo: res.couponRule.code,
                    stock: {
                        maxCount: res.couponRule.stock,
                        changeCount: '',
                        surplusCount: res.couponRule.leftStock,
                        defalutMaxCount: res.couponRule.stock,
                        defalutSurplusCount: res.couponRule.leftStock,
                    },
                };
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : services.language.getText('longTerm'),
                    };
                }
                res.productInfo = {
                    subsites: res.couponRule.subsites || [],
                    remark: res.couponRule.remark,
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };
                res.ruleInfo = {
                    usedLowestLimitAmountValue:
                        res.consumeRule.condition === 'MONEY'
                            ? res.consumeRule.conditionLowerValue
                            : null,
                    usedLowestLimitQuantityValue:
                        res.consumeRule.condition === 'QUANTITY'
                            ? res.consumeRule.conditionLowerValue
                            : null,
                };
                if (res.couponValue.type) {
                    res.ruleInfo.parkingValueType = res.couponValue.type;
                    res.ruleInfo.couponValueAmount =
                        res.couponValue.type === 'AMOUNT' ? res.couponValue.value : null;
                    res.ruleInfo.couponValueHours =
                        res.couponValue.type === 'HOURS' ? res.couponValue.value : null;
                }
                res.useRuleInfo = {
                    receivePeriod:
                        res.receiveRule.period.startTime && res.receiveRule.period.endTime
                            ? {
                                  start: res.receiveRule.period.startTime,
                                  end: res.receiveRule.period.endTime,
                              }
                            : undefined,
                    receiveLimit: res.receiveRule.receiveLimitType,
                    useLimitations:
                        consumeRuleMemberLevels.length > 0 ? 'MEMBER_LIMIT' : 'NO_LIMIT',
                    memberLevels: receiveRuleMemberLevels,
                    registerMemberSource: res.receiveRule.registerTimeLimit
                        ? res.receiveRule.registerTimeLimit.registerMemberSource
                        : 'ONLINE',
                    useLimitationsMode:
                        res.receiveRule.registerTimeLimit &&
                        res.receiveRule.registerTimeLimit.registerDays
                            ? 'DAY'
                            : 'DATE',
                    useLimitationsDay: res.receiveRule.registerTimeLimit?.registerDays || undefined,
                    useLimitationsDate: res.receiveRule.registerTimeLimit?.registerPeriod
                        ? {
                              start: moment(
                                  res.receiveRule.registerTimeLimit?.registerPeriod.startTime
                              ).format('YYYY-MM-DD'),
                              end: moment(
                                  res.receiveRule.registerTimeLimit?.registerPeriod.endTime
                              ).format('YYYY-MM-DD'),
                          }
                        : undefined,
                    usedChannelLimit: usedChannelLimit,
                    showChannels: res.showRules
                        ? res.showRules.map((value: any) => {
                              return value.showChannel;
                          })
                        : [],
                    source: res.couponRule.source,
                    onlineMode: res.couponRule.source === 'ONLINE' ? res.couponRule.mode : null,
                    offlineMode: res.couponRule.source === 'OFFLINE' ? res.couponRule.mode : null,
                    receiveQuantityLimitType: res.receiveRule.receiveQuantityLimitType,
                    memberLimitQuantity:
                        res.receiveRule.receiveQuantityLimitType === 'EVERY_MEMBER_LIMIT' ||
                        res.receiveRule.receiveQuantityLimitType === 'EVERY_MEMBER_DAILY_LIMIT'
                            ? res.receiveRule.memberLimitQuantity
                            : null,
                    target: res.linkRule && res.linkRule.target && JSON.parse(res.linkRule.target),
                    couponChannelStocks: res.couponChannelStocks
                        ? res.couponChannelStocks.map((channel: any) => {
                              channel.oldMaxCouponQuantity = channel.maxCouponQuantity;
                              if (type === 'copydata') {
                                  delete channel.id;
                              }
                              return channel;
                          })
                        : [],
                    receiveLimitCrowds: res.receiveRule.crowdLimits || [],
                };
                if (
                    res.pictureConfigs &&
                    res.pictureConfigs.length > 0 &&
                    res.pictureConfigs[0].pictureId
                ) {
                    res.pictureInfo = {
                        couponPicture: [
                            {
                                id: res.pictureConfigs[0].pictureId || '',
                                path: res.pictureConfigs[0].url || '',
                            },
                        ],
                    };
                }
                res.instructions = {
                    info: res.couponRule.instructions,
                };

                const seniorSetting: any = {
                    advanceTime:
                        res.expirationNoticeRule && res.expirationNoticeRule.advanceTime
                            ? (res.expirationNoticeRule.advanceTime / 24 / 60).toString()
                            : undefined,
                    isTransfer: res.transferRule && res.transferRule.quota ? 'true' : 'false',
                    transferCount: res.transferRule ? res.transferRule.quota : undefined,
                };
                const guideRule = res.guideDistributionRule;
                if (guideRule) {
                    seniorSetting.guideProvide = guideRule.guideProvide ? 'ENABLE' : 'DISABLE';
                    seniorSetting.selectMode = guideRule.subSiteOrMerchant || 'SUB_SITE';
                    seniorSetting.shop = guideRule.subSiteMerchants || [];
                    seniorSetting.subsite = guideRule.subsites || [];
                } else {
                    seniorSetting.guideProvide = 'DISABLE';
                    seniorSetting.selectMode = 'SUB_SITE';
                    seniorSetting.shop = [];
                    seniorSetting.subsite = [];
                }
                res.seniorSetting = seniorSetting;
                if (type === 'copydata') {
                    delete res.baseInfo.batchNo;
                    delete res.baseInfo.leftStock;
                }
            } else {
                res.result =
                    res &&
                    res.result.map((item: any) => {
                        item.info = {
                            name: item.name,
                            url:
                                item?.pictureConfigs?.[0]?.url ||
                                `https://b-puzhehei-cdn.co-mall.net/global/icon_coupon_center_parking.png`,
                            startTime: item.startTime,
                            endTime: item.endTime,
                        };
                        return item;
                    });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        config.apiPath = '/admin/parking_coupon_rules';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        const newParams: any = dataParser(params);
        newParams.couponRule.id = params.id;
        if (params?.type === 'copydata') {
            config.apiPath = '/admin/parking_coupon_rules';
            return await api.post(newParams, config);
        } else if (params.id) {
            config.apiPath = `/admin/parking_coupon_rules/${params.id}`;
        }
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    const subsiteArray: any[] = [];
    if (params.productInfo.subsites && params.productInfo.subsites.length > 0) {
        forEach(params.productInfo.subsites, (subsite) => {
            subsiteArray.push(subsite);
        });
    }
    newParams.couponRule = {
        code: params.baseInfo.batchNo,
        couponType: 'PARKING',
        instructions: params.instructions.info,
        name: params.baseInfo.name,
        subSiteOrMerchant: 'SUB_SITE',
        subsites: subsiteArray,
        stock: params.baseInfo.stock.maxCount,
        remark: params.productInfo.remark,
        source: params.useRuleInfo.source,
        mode:
            params.useRuleInfo.source === 'ONLINE'
                ? params.useRuleInfo.onlineMode
                : params.useRuleInfo.offlineMode,
    };
    newParams.consumeEffectivePeriodRule = {
        dynamicEffectivePeriod: params.baseInfo.dynamicEffectivePeriod,
        period: {
            startTime: params.baseInfo.dateRange.start,
            endTime: params.baseInfo.dateRange.end,
        },
    };
    let couponValue = null;
    if (params.ruleInfo.parkingValueType === 'AMOUNT') {
        couponValue = params.ruleInfo.couponValueAmount;
    }
    if (params.ruleInfo.parkingValueType === 'HOURS') {
        couponValue = params.ruleInfo.couponValueHours;
    }
    newParams.couponValue = {
        type: params.ruleInfo.parkingValueType,
        value: couponValue,
    };
    if (
        params.pictureInfo &&
        params.pictureInfo.couponPicture &&
        params.pictureInfo.couponPicture[0]
    ) {
        newParams.pictureConfigs = [
            {
                pictureId: params.pictureInfo.couponPicture[0].id,
                type: 'COVER',
            },
        ];
    }
    let conditionLowerValue = null;
    if (params.ruleInfo.parkingValueType === 'MONEY') {
        conditionLowerValue = params.ruleInfo.usedLowestLimitAmountValue;
    } else if (params.ruleInfo.parkingValueType === 'QUANTITY') {
        conditionLowerValue = params.ruleInfo.usedLowestLimitQuantityValue;
    }
    newParams.consumeRule = {
        canBeConsumed: true,
        condition: 'NOCONDITION',
        conditionLowerValue: conditionLowerValue,
        conditionUpperValue: null,
        dimensions: [
            {
                all: false,
                dimensionType: 'CHANNEL',
                ids: params.useRuleInfo.usedChannelLimit.map((value: any) => parseInt(value)),
            },
        ],
        orderLimitQuantity: null,
    };
    if (
        params.useRuleInfo.useLimitations &&
        params.useRuleInfo.useLimitations === 'MEMBER_LIMIT' &&
        params.useRuleInfo.receiveLimit === ReceiveLimitEnum.MEMBER_LIMIT
    ) {
        newParams.consumeRule.dimensions.push({
            all: false,
            dimensionType: 'MEMBER_CARD_LEVEL',
            ids: map(params.useRuleInfo.memberLevels, 'id'),
        });
    }
    newParams.receiveRule = {
        canBeReceiveWhenLackOfStock: false,
        canBeReceived: true,
        memberLimitQuantity:
            params.useRuleInfo.receiveQuantityLimitType === 'EVERY_MEMBER_LIMIT' ||
            params.useRuleInfo.receiveQuantityLimitType === 'EVERY_MEMBER_DAILY_LIMIT'
                ? params.useRuleInfo.memberLimitQuantity
                : null,
        orderLimitQuantity: null,
        period: {
            endTime: params.useRuleInfo.receivePeriod?.end ?? null,
            startTime: params.useRuleInfo.receivePeriod?.start ?? null,
        },
        receiveQuantityLimitType: params.useRuleInfo.receiveQuantityLimitType,
        cardLevels:
            params.useRuleInfo.receiveLimit === ReceiveLimitEnum.MEMBER_LIMIT
                ? map(params.useRuleInfo.memberLevels, 'id')
                : null,
        registerTimeLimit:
            params.useRuleInfo.receiveLimit === ReceiveLimitEnum.TIME_LIMIT
                ? {
                      registerMemberSource: params.useRuleInfo.registerMemberSource,
                      registerDays:
                          params.useRuleInfo.useLimitationsMode === 'DAY'
                              ? params.useRuleInfo.useLimitationsDay
                              : undefined,
                      registerPeriod:
                          params.useRuleInfo.useLimitationsMode === 'DATE'
                              ? {
                                    startTime:
                                        params.useRuleInfo.useLimitationsDate.start + ' 00:00:00',
                                    endTime:
                                        params.useRuleInfo.useLimitationsDate.end + ' 23:59:59',
                                }
                              : undefined,
                  }
                : undefined,
        crowdIds:
            params.useRuleInfo.receiveLimit === ReceiveLimitEnum.CROWD_LIMIT
                ? params.useRuleInfo.receiveLimitCrowds.map((i: any) => i.id)
                : undefined,
    };
    newParams.showRules =
        params.useRuleInfo.showChannels &&
        params.useRuleInfo.showChannels.map((value: any) => {
            const item: any = {};
            item.period = {
                endTime: params.baseInfo.dateRange.end,
                startTime: params.baseInfo.dateRange.start,
            };
            item.showChannel = value;
            return item;
        });
    if (params.seniorSetting) {
        newParams.expirationNoticeRule = params.seniorSetting.advanceTime
            ? {
                  advanceTime: Number(params.seniorSetting.advanceTime) * 24 * 60,
              }
            : null;
    }
    if (params.seniorSetting) {
        const shopArray: any = [];
        const subsiteArray: any[] = [];
        if (params.seniorSetting.shop && params.seniorSetting.shop.length > 0) {
            forEach(params.seniorSetting.shop, (shop) => {
                shopArray.push(`${shop.subSiteId},${shop.merchantId}`);
            });
        }
        if (params.seniorSetting.subsite && params.seniorSetting.subsite.length > 0) {
            forEach(params.seniorSetting.subsite, (subsite) => {
                subsiteArray.push(subsite);
            });
        }
        newParams.guideDistributionRule = {
            guideProvide: params.seniorSetting.guideProvide === 'ENABLE' ? true : false,
            subSiteOrMerchant: params.seniorSetting.selectMode,
            merchants: params.seniorSetting.selectMode === 'MERCHANT' ? shopArray : [],
            subsites: params.seniorSetting.selectMode === 'SUB_SITE' ? subsiteArray : [],
        };
    }
    if (params.useRuleInfo.target) {
        newParams.linkRule = {
            target: JSON.stringify(params.useRuleInfo.target),
        };
    }
    newParams.couponChannelStocks =
        params.useRuleInfo.couponChannelStocks && params.useRuleInfo.couponChannelStocks.length > 0
            ? params.useRuleInfo.couponChannelStocks
            : [];
    newParams.transferRule = {
        quota:
            params.seniorSetting.isTransfer === 'true'
                ? params.seniorSetting.transferCount
                : undefined,
    };
    newParams.marketingScheduleId =
        (params.productInfo?.marketingSchedule?.length &&
            params.productInfo.marketingSchedule[0].id) ||
        null;
    return newParams;
}
