import { services } from '@comall-backend-builder/core/';

export const CreditProductsLoader = {
    get: async function(data: any, config: any) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = '/admin/credit_products/goods';
        return services.api.get(data, config).then((res: any) => {
            res.result = res.result.map((item: any) => ({
                ...item,
                ruleName: item.name,
                name: item.productName,
                isProduct: true,
            }));
            return res;
        });
    },
};
