import { Config } from '@comall-backend-builder/core/lib/parser';

export enum OperationResults {
    /**
     * 成功
     */
    SUCCESS = 'SUCCESS',
    /**
     * 失败
     */
    FAIL = 'FAIL',
    /**
     * 部分成功
     */
    SOME_SUCCESS = 'SOME_SUCCESS',
}

export const OPERATION_RESULTS_OPYIONS = [
    {
        id: OperationResults.SUCCESS,
        name: '<<success>>',
    },
    {
        id: OperationResults.FAIL,
        name: '<<sb_2>>',
    },
    {
        id: OperationResults.SOME_SUCCESS,
        name: '<<bfcg>>',
    },
];

export const config: Config = {
    components: {
        maoTaiPresaleNormalView: {
            component: 'Viewport',
            entity: 'maotaiPresaleEntity',
        },
        maoTaiPresaleNormalPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'maoTaiPresaleNormalFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            style: {
                                display: 'flex',
                            },
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    route: '/maotai-presale-normal/add',
                                },
                                {
                                    component: 'MaotaiPreSaleExtendImportButton',
                                },
                                {
                                    component: 'MaotaiPresaleLimitRuleButton',
                                },
                                {
                                    component: 'MaotaiRemark',
                                },
                            ],
                        },
                    ],
                },
                { component: 'maoTaiPresaleNormalTable' },
            ],
        },
        maoTaiPresaleNormalLimitRuleButton: {
            component: 'maoTaiPresaleNormalLimitRule',
            entity: 'maoTaiPresaleNormalLimitRuleEntity',
        },
        maoTaiPresaleNormalForecastPage: {
            component: 'FlexLayout',
            entity: 'maoTaiPresaleNormalForecastEntity',
            direction: 'vertical',
            items: [
                {
                    component: 'maoTaiPresaleNormalForecastFilter',
                },
                {
                    component: 'Text',
                    text: '<<mrzs>>',
                    style: {
                        marginTop: 15,
                    },
                },
                { component: 'maoTaiPresaleNormalForecastTable' },
            ],
        },
        maoTaiPresaleNormalForecastFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'subsiteId',
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'code',
                },
            ],
        },
        maoTaiPresaleNormalForecastTable: {
            component: 'DataTable',
            loadFirstPage: true,
            scroll: {
                x: 1300,
            },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'subsiteName',
                    width: 150,
                },
                {
                    property: 'activityName',
                    width: 150,
                },
                {
                    property: 'code',
                    width: 150,
                },
                {
                    property: 'activityStartTime',
                    width: 150,
                },
                {
                    property: 'activityEndTime',
                    width: 150,
                },
                {
                    property: 'goodsName',
                    width: 100,
                },
                {
                    property: 'purchaseTime',
                    width: 150,
                },
                {
                    property: 'addRegisterCount',
                    width: 150,
                },
                {
                    property: 'prizeCount',
                    width: 150,
                },
                {
                    property: 'maxPrizeCount',
                    width: 150,
                },
                {
                    property: 'quantity',
                    width: 150,
                },
            ],
        },

        maoTaiPresaleNormalFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'status',
                    controlConfig: {
                        style: {
                            width: 150,
                        },
                    },
                },
                {
                    property: 'subsiteId',
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'mode',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
            ],
        },
        maoTaiPresaleNormalTable: {
            component: 'DataTable',
            loadFirstPage: true,
            scroll: {
                x: 1300,
            },
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
            },
            columns: [
                {
                    property: 'code',
                    width: 100,
                },
                {
                    property: 'subsites',
                    width: 200,
                },
                {
                    property: 'name',
                    width: 200,
                },
                {
                    property: 'startTime',
                    width: 120,
                },
                {
                    property: 'endTime',
                    width: 120,
                },
                {
                    property: 'status',
                    width: 100,
                },
                {
                    property: 'registrationCount',
                    width: 100,
                },
                {
                    property: 'prizeCount',
                    width: 100,
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                width: 360,
                className: 'column-left action-column',
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            statusKey: 'enable',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                                    apiPath: '/admin/presale/:id/disable',
                                    config: {
                                        content: '<<hd>>{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isCloseActivity>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-SOURCE-WANGFUJING-PRESALE`,
                                    apiPath: '/admin/presale/:id/enable',
                                    config: {
                                        content: '<<hd>>{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>><<hd>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },

                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        // statusKey: 'row.canEdit',
                        path: '/maotai-presale-normal/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copyActivity>>',
                        // statusKey: 'row.canEdit',
                        path: '/maotai-presale-normal/copy/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'CopyLinkButton',
                        params: {
                            content: '<<copyLink>>',
                        },
                    },
                ],
            },
        },
        maoTaiPresaleNormalAddPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'maotaiPresaleEntity',
            params: {
                pageType: 'normal',
            },
            items: [{ component: 'MaotaiPresaleAddFormView' }],
        },
        maoTaiPresaleNormalEditPage: {
            component: 'FlexLayout',
            entity: 'maotaiPresaleEntity',
            direction: 'vertical',
            loaderType: 'get',
            params: {
                pageType: 'normal',
            },
            items: [{ component: 'MaotaiPresaleEditFormView' }],
        },
        maoTaiPresaleNormalCopyPage: {
            component: 'FlexLayout',
            entity: 'maotaiPresaleEntity',
            direction: 'vertical',
            loaderType: 'get',
            params: {
                pageType: 'normal',
            },
            items: [{ component: 'MaotaiPresaleCopyFormView' }],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/maotai-presale-normal',
                    component: 'maoTaiPresaleNormalView',
                    breadcrumbName: '<<maotaiPresale>>',
                    privilege: {
                        path: 'maoTaiPresaleNormal',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'maoTaiPresaleNormalAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'maoTaiPresaleNormalEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/copy/:id',
                            component: 'maoTaiPresaleNormalCopyPage',
                            breadcrumbName: '<<copyActivity>>',
                        },

                        {
                            path: '/forecast',
                            component: 'maoTaiPresaleNormalForecastPage',
                        },
                        { path: '/', component: 'maoTaiPresaleNormalPage' },
                    ],
                },
            ],
        },
    ],
};
