import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { Input, message as AntMessage, Modal, Cascader } from 'antd';
import { api, language, localStorage } from '@comall-backend-builder/core/lib/services';
import { clone, cloneDeep, debounce, find, forEach } from 'lodash';
import { UploadFile } from 'antd/es/upload/interface';
import {
    SceneType,
    linkTypeList,
    pathRedirectType,
    PushAndSiteMailSceneType,
    SpecialType,
} from '../../common';
import './index.less';
import { MsgChannelType } from '../../messages-subscription-find-list-item';
import TextArea from 'antd/lib/input/TextArea';
import { PictureVo } from '../../../coupon-order';
import {
    getPathconfiglist,
    newLinkTypeEffectPathConfigList,
    getI18NLinkNameById,
} from '../../../../components/link-type-effect-path/config';
import { LinkTypePlus } from '../../../../applications/design/components';
import { newClientCustomSelectorConfig } from '../../../../applications/design/design';
import { ImageUpload } from '@comall-backend-builder/components-basis';
import { MessageParameterSelector } from '../message-parameter-selector';

export interface Props {
    msgChannelType: MsgChannelType;
    editId?: number;
    refreshList: () => void;
    handleVisible: () => void;
    visible: boolean;
}

interface TemplateParamsVo {
    name: string;
    value: any;
    color: string;
    defaultValue: string;
    editable: boolean;
    fieldType: string;
    info: string;
}

interface TemplateVo {
    id?: number;
    name: string;
    scene: SceneType;
    sceneRemark: string;
    alias: string;
    code: string;
    miniTargetUrl: string;
    top?: string;
    other?: Array<TemplateParamsVo>;
    bottom?: string;
    topDefaultValue?: string;
    detailContentColor?: string;
    bottomContentColor?: string;
    bottomDefaultValue?: string;
    templateId?: string;
    msgChannelType?: MsgChannelType;
}

interface linkType {
    linkType: string;
    linkParams: any;
}

export interface States {
    data: TemplateVo | undefined;
    pathParams: any | undefined; //特殊页面传参；比如专题页选择后的id，name
    subsiteWechantName: string;
    logoUrl: string;
    appId: string;
    fileList: Array<UploadFile>;
    pictures: Array<PictureVo>;
    previewImage: string;
    previewVisible: boolean;
    currentLinkType: linkType;
}

const targets = [
    {
        value:
            'sub-packages/offline-order-comment/pages/offline-order-comment/index?id={$posReceiptId}&orderNo={$orderNo}&posReceiptNo={$posReceiptNo}&subsiteId={$subsiteId}',
        label: '发表评价页',
    },
    {
        value: 'service',
        label: '服务工具',
        children: [
            {
                value: 'subpackages/electronic-bill/pages/list/index?subsiteId={$subsiteId}',
                label: '电子小票',
            },
        ],
    },
];
const needLinkParamsType = ['newTopic', 'subsite'];

/**
 * 站内信和Apppush编辑
 */
export class MessagesSubscriptionSiteMailPushEditModal extends PureComponent<Props, States> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: undefined,
            pathParams: undefined,
            subsiteWechantName: '',
            logoUrl: '',
            appId: '',
            fileList: [],
            pictures: [],
            previewImage: '',
            previewVisible: false,
            currentLinkType: {
                linkType: '',
                linkParams: null,
            },
        };
    }

    componentDidMount() {
        this.loadTemplateInfo();
    }

    loadTemplateInfo = () => {
        const { editId, msgChannelType } = this.props;
        api.get(
            { messageChannelType: msgChannelType },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                apiPath: '/admin/template_definitions/' + editId,
            }
        ).then((data: any) => {
            if (data && data.params) {
                const other: any = [];
                forEach(data.params, (param: any) => {
                    // 图片
                    if (param.fieldType === 'file' && param.value) {
                        param.value = JSON.parse(param.value);
                        param.value.path = param.value.url;
                        // param.value = [param.value];
                    }
                    if (param.name !== 'name' && param.name !== 'top' && param.name !== 'bottom') {
                        other.push(param);
                    }
                });
                data.other = other;
            }
            let currentLinkType = {
                linkType: data.linkType.type,
                linkParams: data.linkType.params
                    ? needLinkParamsType.includes(data.linkType.type)
                        ? JSON.parse(data.linkType.params)
                        : data.linkType.params
                    : null,
            };
            if (pathRedirectType.includes(data.scene)) {
                currentLinkType = {
                    linkType: SpecialType.PathRedirect,
                    linkParams: null,
                };
            }

            this.setState({
                data: data,
                currentLinkType,
            });
        });
    };

    /**
     * 提交的时候检验填写是否符合规范
     * 图片非必填
     */
    validate = () => {
        const { data, currentLinkType } = this.state;
        if (!data) {
            return false;
        }
        const { other } = data;

        let hasValidate = true;
        forEach(other || [], (o) => {
            // 图片非必填所以不需要检验图片是否填写
            if (o.editable && o.fieldType !== 'file') {
                if (!o.value) {
                    AntMessage.warning(`${services.language.getText('selectFillin')}${o.name}`);
                    hasValidate = false;
                    return false;
                }
            }
        });
        if (!hasValidate) {
            return false;
        }
        if (!currentLinkType || !currentLinkType.linkType) {
            AntMessage.warning(services.language.getText('qxzljlx'));
            return false;
        }
        if (needLinkParamsType.includes(currentLinkType.linkType) && !currentLinkType.linkParams) {
            AntMessage.warning(services.language.getText('selectLinkTarget'));
            return false;
        }
        return true;
    };

    isSubmit = false;

    /**
     * 提交
     */
    handleSubmit = debounce(() => {
        const hasValidate = this.validate();
        const { data, appId, currentLinkType } = this.state;
        const { refreshList, handleVisible, editId, msgChannelType } = this.props;
        if (!data) {
            return;
        }
        if (hasValidate) {
            const detailContent: any = [];
            if (data.other && data.other.length > 0) {
                forEach(data.other, (o: any) => {
                    if (o.editable) {
                        if (o.fieldType === 'file' && o.value) {
                            o.value = o.value.id ? o.value.id : o.value;
                        }
                        detailContent.push({
                            name: o.name,
                            value: o.value,
                            field_type: o.fieldType, //后端要求蛇形
                            editable: o.editable,
                        });
                    }
                });
            }
            const params = {
                msgChannelType,
                linkType: {
                    type: currentLinkType.linkType,
                    params: currentLinkType.linkParams
                        ? needLinkParamsType.includes(currentLinkType.linkType)
                            ? JSON.stringify(currentLinkType.linkParams)
                            : currentLinkType.linkParams
                        : null,
                },
                scene: data.scene,
            };
            if (this.isSubmit) {
                return;
            }
            this.isSubmit = true;
            if (editId) {
                api.put(
                    {
                        detailContent: JSON.stringify(detailContent),
                        ...params,
                    },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                        apiPath: '/admin/template_definitions/' + data.id,
                    }
                )
                    .then(() => {
                        AntMessage.success(services.language.getText('editSuccess'));
                        handleVisible();
                        refreshList();
                    })
                    .catch((error) => {
                        services.errorHandle(error);
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isSubmit = false;
                        }, 1000);
                    });
            } else {
                let store: any = localStorage.get('wechantTemplatesStore');
                api.post(
                    {
                        detailContents: detailContent,
                        title: data.name,
                        appId: appId,
                        templateId: data.templateId,
                        subsiteId: store.id,
                        ...params,
                    },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                        apiPath: '/admin/template_definitions/manual',
                    }
                )
                    .then(() => {
                        AntMessage.success(services.language.getText('addSuccess'));
                        handleVisible();
                        refreshList();
                    })
                    .catch((error) => {
                        services.errorHandle(error);
                    })
                    .finally(() => {
                        setTimeout(() => {
                            this.isSubmit = false;
                        }, 1000);
                    });
            }
        }
    }, 500);

    onHandleOtherChange(name: string, valueString: any) {
        let { data } = this.state;
        if (data && data.other) {
            const newData = cloneDeep(data);
            const current = find(newData.other, {
                name: name,
            });
            if (current) {
                current.value = valueString;
                this.setState({
                    data: newData,
                });
            }
        }
        return {};
    }

    /**
     * 站内信和Apppush 编辑页面左侧
     */
    renderLeftWrap = () => {
        const { data, subsiteWechantName } = this.state;
        const topImage = require('../images/icon-miniprogram-top.png');
        return (
            <div className="wechat-templates-find-list-item-edit-view__left-wrap">
                <div className="wechat-templates-find-list-item-edit-view__info">
                    <div className="wechat-templates-find-list-item-edit-view__info__top">
                        <img src={topImage} width="364px" alt="" />
                        <div className="wechat-templates-find-list-item-edit-view__info__top__name">
                            {subsiteWechantName}
                        </div>
                    </div>
                    <div className="wechat-templates-find-list-item-edit-view__info__data-info">
                        <div className="wechat-templates-find-list-item-edit-view__info__content">
                            <div className="wechat-templates-find-list-item-edit-view__info__header">
                                <div>
                                    {data && data.name
                                        ? data.name
                                        : services.language.getText('mbbt')}
                                </div>
                                <div className="wechat-templates-find-list-item-edit-view__info__dian-wrap">
                                    <div className="wechat-templates-find-list-item-edit-view__info__dian"></div>
                                    <div className="wechat-templates-find-list-item-edit-view__info__dian"></div>
                                    <div className="wechat-templates-find-list-item-edit-view__info__dian"></div>
                                </div>
                            </div>
                            {data && (
                                <div>
                                    {!!data.top && (
                                        <div className="wechat-templates-find-list-item-edit-view__info__center">
                                            <span
                                                style={{
                                                    whiteSpace: 'pre-line',
                                                }}
                                            >
                                                {data.top}
                                            </span>
                                        </div>
                                    )}
                                    <div className="wechat-templates-find-list-item-edit-view__info__other">
                                        {data.other && data.other?.length > 0 && (
                                            <div className="wechat-templates-find-list-item-edit-view__info__other-item">
                                                <span>{data.other[0].name}：</span>
                                                <span
                                                    style={{
                                                        color:
                                                            data && data.detailContentColor
                                                                ? data.detailContentColor
                                                                : '#000',
                                                        flex: 1,
                                                        whiteSpace: 'pre-line',
                                                    }}
                                                >
                                                    {data.other[0].value}
                                                </span>
                                            </div>
                                        )}
                                        {data.other && data.other?.length > 1 && (
                                            <div className="wechat-templates-find-list-item-edit-view__info__other-item">
                                                <span>{data.other[1].name}：</span>
                                                <span
                                                    className="wechat-templates-find-list-item-edit-view__info__msg-content"
                                                    style={{
                                                        color:
                                                            data && data.detailContentColor
                                                                ? data.detailContentColor
                                                                : '#000',
                                                    }}
                                                >
                                                    {data.other?.length > 2 &&
                                                        data.other[2].value && (
                                                            <img
                                                                className="img"
                                                                alt={language.getText('xxtp')}
                                                                src={data.other[2].value.path}
                                                            />
                                                        )}
                                                    <span className="value">
                                                        {data.other[1].value}
                                                    </span>
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    /**
     * 上传图片
     */
    onPicChange = (picValue: any) => {
        let { data } = this.state;
        let item: any;

        if (data !== null) {
            item = clone(data);
        }
        if (item.other && item.other.length > 2) {
            if (picValue.length) {
                item.other[2].value = picValue[0];
            } else {
                item.other[2].value = null;
            }
        }
        this.setState({
            data: item,
        });
    };

    /**
     * 右侧编辑字段展示
     */
    renderOther = (o: any, index: number) => {
        const { data } = this.state;
        if (!data) {
            return null;
        }
        const picValue =
            data?.other && data?.other.length > 2 && data.other[2].value && data.other[2].value.url;
        let _imageBgControlInfo = Object.assign(
            {},
            {
                name: 'messagePicture',
                accept: 'image/*',
                maxCount: 1,
                multiple: false,
                fileName: 'fileName',
                uploadType: 'single',
                value: picValue || '',
                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                mode: 'picture-card',
                preview: {
                    size: 500,
                },
                maxSize: 1 * 1024,
                limitTips: {
                    limitText: services.language.getText('siteMailImgLimit'),
                    limitUnit: ' ',
                    className: 'image-picker-tips-ext',
                    style: {
                        marginTop: 5,
                    },
                },
            }
        );

        return (
            <div className="wechat-templates-find-list-item-edit-view__other-wrap">
                {index === 0 && (
                    <Input
                        value={o.value}
                        onChange={(value: any) =>
                            this.onHandleOtherChange(o.name, value.target.value)
                        }
                    />
                )}
                {index === 1 && (
                    <TextArea
                        value={o.value}
                        onChange={(value: any) =>
                            this.onHandleOtherChange(o.name, value.target.value)
                        }
                    />
                )}
                {index === 2 && (
                    <ImageUpload
                        {..._imageBgControlInfo}
                        onChange={(value: any) => {
                            this.onPicChange(value);
                            return {};
                        }}
                    />
                )}
            </div>
        );
    };

    /**
     * 不同的场景值 获取不同的链接类型列表展示
     */
    linkTypeEffectPathConfigList = () => {
        const { data } = this.state;
        if (!data) {
            return;
        }
        return getPathconfiglist(linkTypeList[data.scene], newLinkTypeEffectPathConfigList);
    };

    /**
     * 修改链接类型
     */
    changeTargetValue = (val: any) => {
        this.setState({
            currentLinkType: { linkType: val.linkType, linkParams: val.linkParams },
        });
    };

    // 处理线下评价提醒
    handleOrderCommentChange = (value: string[]) => {
        this.setState({
            currentLinkType: {
                linkType: SpecialType.ParameterConcatenation,
                linkParams: value[value.length - 1],
            },
        });
    };

    getCascaderMiniTargetUrl = () => {
        const { currentLinkType } = this.state;
        if (currentLinkType && currentLinkType.linkParams) {
            if (
                currentLinkType.linkParams.includes('subpackages/electronic-bill/pages/list/index')
            ) {
                return ['service', currentLinkType.linkParams];
            }
            if (
                currentLinkType.linkParams.includes(
                    'sub-packages/offline-order-comment/pages/offline-order-comment/index'
                )
            ) {
                return [currentLinkType.linkParams];
            }
        }
        return undefined;
    };

    /**
     * 站内信和Apppush 编辑页面右侧
     */
    renderRightWrap = () => {
        const { data, currentLinkType } = this.state;
        if (!data) {
            return null;
        }
        let linkTypes = (this.linkTypeEffectPathConfigList()?.linkList as any) || [];
        /**
         * 链接类型的展示形式 select 是级联选择 text 是文本展示
         */
        let displayType = this.linkTypeEffectPathConfigList()?.displayType || 'select';
        const others: any[] = data.other?.filter((item: any) => item.editable) || [];
        const isOrderComment =
            ((data.scene as unknown) as PushAndSiteMailSceneType) ===
            PushAndSiteMailSceneType.POS_RECEIPT_ORDER_COMMENT;
        const linkName = this.getLinkTypeName((data.scene as unknown) as PushAndSiteMailSceneType);
        if (linkName) {
            displayType = 'text';
            linkTypes = linkName;
        }
        return (
            <div className="wechat-templates-find-list-item-edit-view__right-wrap">
                <div className="wechat-templates-find-list-item-edit-view__right-title">
                    {services.language.getText('nrpz')}
                </div>
                <div className="wechat-templates-find-list-item-edit-view__parameter">
                    <MessageParameterSelector params={{ scene: data?.scene }} />
                    <div>{language.getText('qfzkydblmc')}</div>
                </div>
                {others &&
                    others.map((o: any, index: number) => {
                        return (
                            <div
                                className="wechat-templates-find-list-item-edit-view__flex"
                                key={index}
                            >
                                <div className="wechat-templates-find-list-item-edit-view__other-title">
                                    {o.name}：
                                </div>
                                <div className="wechat-templates-find-list-item-edit-view__value">
                                    {this.renderOther(o, index)}
                                    {o.info && (
                                        <div className="wechat-templates-find-list-item-edit-view__tip">
                                            {services.language.getText('example_2')}
                                            {o.info}
                                        </div>
                                    )}
                                </div>
                            </div>
                        );
                    })}
                <div className="wechat-templates-find-list-item-edit-view__flex">
                    {isOrderComment && (
                        <>
                            <div className="wechat-templates-find-list-item-edit-view__other-title">
                                {language.getText('ljlx')}：
                            </div>
                            <div
                                className="wechat-templates-find-list-item-edit-view__value"
                                style={{ lineHeight: '32px' }}
                            >
                                <Cascader
                                    className="trans-action-selection"
                                    style={{ width: '230px' }}
                                    value={this.getCascaderMiniTargetUrl()}
                                    options={targets}
                                    placeholder={services.language.getText('selectPlease')}
                                    onChange={this.handleOrderCommentChange}
                                />
                            </div>
                        </>
                    )}
                    {displayType === 'select' && !isOrderComment && (
                        <div className="wechat-templates-find-list-item-edit-view__value link-type">
                            <LinkTypePlus
                                selector={newClientCustomSelectorConfig as any}
                                linkTypes={linkTypes}
                                linkTypeMode="cascader"
                                value={currentLinkType}
                                onChange={this.changeTargetValue}
                            />
                        </div>
                    )}
                    {displayType === 'text' && !isOrderComment && (
                        <>
                            <div className="wechat-templates-find-list-item-edit-view__other-title">
                                {language.getText('ljlx')}：
                            </div>
                            <div
                                className="wechat-templates-find-list-item-edit-view__value"
                                style={{ lineHeight: '32px' }}
                            >
                                {linkTypes}
                            </div>
                        </>
                    )}
                </div>
            </div>
        );
    };

    getLinkTypeName = (scene: PushAndSiteMailSceneType): string => {
        const specificScenes = [
            PushAndSiteMailSceneType.THIRD_COUPON_RECEIVE_REMIND,
            PushAndSiteMailSceneType.THIRD_COUPON_EXPIRED_NOTICE,
            PushAndSiteMailSceneType.THIRD_BIRTHDAY_GIFT_RECEIVE_REMIND,
        ];
        if (specificScenes.includes(scene)) {
            const linkTypeArray =
                linkTypeList[scene] && linkTypeList[scene].length ? linkTypeList[scene][0] : [];
            if (linkTypeArray && linkTypeArray.length > 0) {
                const name = linkTypeArray[linkTypeArray.length - 1];
                if (name) {
                    return getI18NLinkNameById(name);
                }
            }
        }

        return '';
    };
    renderSitemailLeftWrap = () => {
        const { data } = this.state;
        const content = data && data.other && data.other?.length > 1 ? data.other[1].value : '';
        const pic = data && data.other && data.other?.length > 2 ? data.other[2].value : '';
        return (
            <div className="wechat-templates-find-list-item-edit-view__left-wrap">
                <div className="wechat-templates-find-list-item-edit-view__sitemail">
                    <div className="wechat-templates-find-list-item-edit-view__sitemail__item">
                        <div className="wechat-templates-find-list-item-edit-view__sitemail__title">
                            {data && data.name ? data.name : services.language.getText('mbbt')}
                        </div>
                        {content || pic ? (
                            <div className="wechat-templates-find-list-item-edit-view__sitemail__content">
                                {pic ? (
                                    <div className="wechat-templates-find-list-item-edit-view__sitemail__content__left">
                                        <img
                                            className="img"
                                            alt={language.getText('xxtp')}
                                            src={pic.path}
                                        />
                                    </div>
                                ) : null}
                                <div className="wechat-templates-find-list-item-edit-view__sitemail__content__right">
                                    {content}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    };

    renderAppPushLeftWrap = () => {
        const { data } = this.state;
        const pushBg = require('../images/app-push-bg.png');
        const pushLogo = require('../images/app-push-logo.png');
        const content = data && data.other && data.other?.length > 1 ? data.other[1].value : '';
        const pic = data && data.other && data.other?.length > 2 ? data.other[2].value : '';
        const style = {
            height: '808px',
            backgroundImage: `url('${pushBg}')`,
            backgroundSize: '375px auto',
            backgroundPosition: 'top left',
            backgroundRepeat: 'no-repeat',
        };
        return (
            <div className="wechat-templates-find-list-item-edit-view__left-wrap" style={style}>
                <div className="wechat-templates-find-list-item-edit-view__app-push">
                    <div className="wechat-templates-find-list-item-edit-view__app-push__item">
                        <img
                            alt="LOGO"
                            className="wechat-templates-find-list-item-edit-view__app-push__item__logo"
                            src={pushLogo}
                        />
                        <div className="wechat-templates-find-list-item-edit-view__app-push__item__right">
                            <div className="wechat-templates-find-list-item-edit-view__app-push__item__right__top">
                                <div className="wechat-templates-find-list-item-edit-view__app-push__item__right__top__name">
                                    {data && data.name
                                        ? data.name
                                        : services.language.getText('mbbt')}
                                </div>
                                <div className="wechat-templates-find-list-item-edit-view__app-push__item__right__top__time">
                                    周六 10:00
                                </div>
                            </div>
                            <div className="wechat-templates-find-list-item-edit-view__app-push__item__right__bottom">
                                <div className="wechat-templates-find-list-item-edit-view__app-push__item__right__bottom__content">
                                    {content}
                                </div>
                                {pic ? (
                                    <div className="wechat-templates-find-list-item-edit-view__app-push__item__right__bottom__pic">
                                        <img
                                            className="img"
                                            alt={language.getText('xxtp')}
                                            src={pic.path}
                                        />
                                    </div>
                                ) : null}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    renderLeft = () => {
        const { msgChannelType } = this.props;
        if (msgChannelType === MsgChannelType.SITEMAIL) {
            return this.renderSitemailLeftWrap();
        } else if (msgChannelType === MsgChannelType.APP_PUSH) {
            return this.renderAppPushLeftWrap();
        } else {
            return this.renderLeftWrap();
        }
    };

    renderModal = () => {
        const { visible, handleVisible, editId } = this.props;
        return (
            <Modal
                destroyOnClose
                visible={visible}
                title={
                    editId
                        ? services.language.getText('bjmbxx')
                        : services.language.getText('xzmbxx')
                }
                width="1000px"
                onCancel={handleVisible}
                onOk={this.handleSubmit}
            >
                <div className="wechat-templates-find-list-item-edit-view__wrap">
                    {this.renderLeft()}
                    {this.renderRightWrap()}
                </div>
            </Modal>
        );
    };

    range = (start: number, end: number) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };

    render() {
        return (
            <div className="wechat-templates-find-list-item-edit-view">{this.renderModal()}</div>
        );
    }
}
