import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;

export const ExpressCompanySettingLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`;
        config.apiPath = `/admin/express_companies/${data.companyId}/subsite_configs`;
        if (data.id) {
            config.apiPath = `/admin/express_companies/${data.companyId}/subsite_configs/${data.id}`;
        } else {
            if (data.companyId) {
                delete data.companyId;
            }
        }
        return await api.get(data, config).then((res: any) => {
            return res;
        });
    },
    delete: async function(data: any, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`;
        config.apiPath = `/admin/express_companies/${data.companyId}/subsite_configs/${data.id}`;
        return services.api.delete(data, config);
    },
};
