import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';

export const config: Config = {
    entities: {
        CouponTransferCoverEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
            apiPath: '/loader/admin/coupon_transfer_cover',
            // filters: {
            //     name: {
            //         type: 'string',
            //         displayName: '名称',
            //         controlConfig: { placeholder: '请输入名称' },
            //     },
            // },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<fmmc>>',
                            rules: [
                                {
                                    required: true,
                                    whitespace: true,
                                    message: '<<qsrfmmc>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                placeholder: '<<qsrfmNothan30>>',
                                maxLength: 30,
                            },
                        },
                        subsites: {
                            type: 'array.subsites.selector',
                            displayName: '<<applicableSubsite>>',
                            defaultValue: [],
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },
                        pictures: {
                            displayName: '<<tplb>>',
                            type: 'imageObjectArray',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                            className: 'sigle-image-field',
                            controlConfig: {
                                maxCount: 6,
                                maxSize: 1024,
                                multiple: true,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                            },
                            extra: '<<zdkscPic2>>',
                        },
                    },
                },
                name: {
                    type: 'string',
                    displayName: '<<fmmc>>',
                },
                subsites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<isEnable>>',
                    options: [
                        { id: 'true', name: '<<yes>>' },
                        { id: 'false', name: '<<no>>' },
                    ],
                },
            },
        },
    },
    components: {
        CouponTransferCoverView: {
            component: 'Viewport',
            entity: 'CouponTransferCoverEntity',
        },
        CouponTransferCoverPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                // {
                //     component: 'CouponTransferCoverFilter',
                // },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzfm>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/coupon/coupon-transfer-cover/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'CouponTransferCoverTable' },
            ],
        },
        // CouponTransferCoverFilter: {
        //     component: 'FilterFormPlus',
        //     direction: 'inline',
        //     submit: {
        //         text: '<<common.search>>',
        //     },
        //     reset: {
        //         text: '<<common.reset>>',
        //     },
        //     fields: [
        //         {
        //             property: 'name',
        //         },
        //     ],
        // },
        CouponTransferCoverTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'subsites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'status',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                fixed: 'right',
                width: 210,
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-ASSETS`,
                                    apiPath: '/admin/member_assets_transfer_configs/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                        style: { color: '#f5222d' },
                                    },
                                    confirm: {
                                        text: '<<isAreadyClose>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-ASSETS`,
                                    apiPath: '/admin/member_assets_transfer_configs/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/menu-list/coupon/coupon-transfer-cover/edit/{{row.id}}',
                    },
                    {
                        type: 'delete',
                        text: '<<common.delete>>',
                        config: {
                            type: 'link',
                            tooltip: null,
                            title: '<<confirmDelete>>',
                            okText: '<<common.ok>>',
                            cancelText: '<<common.cancel>>',
                        },
                    },
                ],
            },
        },
        CouponTransferCoverAddPage: {
            component: 'Card',
            content: { component: 'CouponTransferCoverAddForm' },
        },
        CouponTransferCoverAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'CouponTransferCoverEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.subsites' },
                { property: 'baseInfo.pictures' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        CouponTransferCoverEditPage: {
            component: 'Card',
            content: { component: 'CouponTransferCoverEditForm' },
        },
        CouponTransferCoverEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'CouponTransferCoverEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.name' },
                { property: 'baseInfo.subsites' },
                { property: 'baseInfo.pictures' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/coupon/coupon-transfer-cover',
                    component: 'CouponTransferCoverView',
                    breadcrumbName: '<<pzzzfm>>',
                    privilege: {
                        path: 'memberAssetsTransferConfig',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'CouponTransferCoverAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'CouponTransferCoverEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        { path: '/', component: 'CouponTransferCoverPage' },
                    ],
                },
            ],
        },
    ],
};
