import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { find, isEmpty } from 'lodash';
import moment from 'moment';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;

export const BargainActivityLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/bargain_activities';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/bargain_activities/${id}`;
        } else {
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                const normalConditions = res.activityVo.normalConditions;
                const subsiteConditions: any = find(normalConditions, {
                    normalConditionType: 'SUB_SITE',
                });
                const isEdit = window.location.href.includes('/edit');
                const bargainGoods = res.bargainActivity.bargainGoods;
                res.id = res.activityVo.id;

                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : language.getText('longTerm'),
                    };
                }
                res.baseInfo = {
                    name: res.activityVo.name,
                    dateRange: {
                        start: res.activityVo.startTime,
                        end: res.activityVo.endTime,
                    },
                    subsites:
                        subsiteConditions && subsiteConditions.includeIds.length > 0
                            ? subsiteConditions.includeIds[0].id + ''
                            : undefined,
                    productOrGoods: {
                        products:
                            bargainGoods.productType === 'NORMAL'
                                ? [
                                      {
                                          ...bargainGoods,
                                          id: bargainGoods.goodsId,
                                          generalPrice: bargainGoods.price,
                                          stock: bargainGoods.quantity,
                                          subsiteName: bargainGoods.subsite.name,
                                          defalutStock: isEdit ? bargainGoods.quantity : undefined,
                                      },
                                  ]
                                : [],
                        goods:
                            bargainGoods.productType === 'VIRTUAL_COUPON'
                                ? [
                                      {
                                          ...bargainGoods,
                                          id: bargainGoods.goodsId,
                                          generalPrice: bargainGoods.price,
                                          stock: bargainGoods.quantity,
                                          subsiteName: bargainGoods.subsite.name,
                                          defalutStock: isEdit ? bargainGoods.quantity : undefined,
                                      },
                                  ]
                                : [],
                    },
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };
                res.initiatorInfo = {
                    bargainCountEachMember: res.bargainActivity.bargainRule.bargainCountEachMember,
                    effectiveHours: res.bargainActivity.bargainRule.effectiveHours,
                    initBargainAmount: res.bargainActivity.bargainRule.initBargainAmount,
                };

                let couponRewards = [];
                if (
                    res.activityVo.rewardConfig.activityRewards &&
                    res.activityVo.rewardConfig.activityRewards.length > 0
                ) {
                    couponRewards = res.activityVo.rewardConfig.activityRewards[0].couponRewards.map(
                        (couponItem: any) => {
                            couponItem.rewardId = couponItem.id; //用于编辑传后端
                            couponItem.id = couponItem?.couponDefinitionId?.toString() || ''; //用于组件去重
                            couponItem.startDate = moment(couponItem.startTim).format('YYYY.MM.DD');
                            couponItem.endDate = moment(couponItem.endTime).format('YYYY.MM.DD');
                            return couponItem;
                        }
                    );
                }
                res.helpInfo = {
                    bargainAmountCalculateMethod:
                        res.bargainActivity.bargainRule.bargainAmountCalculateMethod,
                    helpBargainCountEachMember:
                        res.bargainActivity.bargainRule.helpBargainCountEachMember,
                    helpMemberCount: res.bargainActivity.bargainRule.helpMemberCount,
                    rewardConfigId: res.activityVo.rewardConfig.id + '',
                    coupons: couponRewards,
                };
                if (res.bargainActivity.bargainPage && res.bargainActivity.bargainPage.pageConfig) {
                    const pageConfigJson = JSON.parse(res.bargainActivity.bargainPage.pageConfig);
                    res.pictureInfo = {
                        shareDescription: res.activityVo.shareConfig
                            ? res.activityVo.shareConfig.shareContent
                            : undefined,
                        backgroundPicture: pageConfigJson.backgroundPicture
                            ? [
                                  {
                                      id: pageConfigJson.backgroundPicture.id,
                                      path: pageConfigJson.backgroundPicture.path,
                                  },
                              ]
                            : undefined,
                        bargainButtonPicture: pageConfigJson.bargainButtonPicture
                            ? [
                                  {
                                      id: pageConfigJson.bargainButtonPicture.id,
                                      path: pageConfigJson.bargainButtonPicture.path,
                                  },
                              ]
                            : undefined,
                        bargainRewardPicture: pageConfigJson.bargainRewardPicture
                            ? [
                                  {
                                      id: pageConfigJson.bargainRewardPicture.id,
                                      path: pageConfigJson.bargainRewardPicture.path,
                                  },
                              ]
                            : undefined,
                        openBargainRewardPicture: pageConfigJson.openBargainRewardPicture
                            ? [
                                  {
                                      id: pageConfigJson.openBargainRewardPicture.id,
                                      path: pageConfigJson.openBargainRewardPicture.path,
                                  },
                              ]
                            : undefined,
                    };
                } else {
                    res.pictureInfo = {
                        shareDescription: `我想要{price}${services.language.getText(
                            'yuan'
                        )}拿走这个商品，快来帮我砍一刀吧！`,
                        backgroundPicture: [
                            {
                                id: 0,
                                path:
                                    'https://b-puzhehei-cdn.co-mall.net/global/market/bargain/bargain-bg-new.png',
                            },
                        ],
                        bargainButtonPicture: [
                            {
                                id: 0,
                                path:
                                    'https://b-puzhehei-cdn.co-mall.net/global/market/bargain/bargain-button.png',
                            },
                        ],
                        bargainRewardPicture: [
                            {
                                id: 0,
                                path:
                                    'https://b-puzhehei-cdn.co-mall.net/global/market/bargain/red-reward-bg-new.png',
                            },
                        ],
                        openBargainRewardPicture: [
                            {
                                id: 0,
                                path:
                                    'https://b-puzhehei-cdn.co-mall.net/global/market/bargain/open-red-reward-bg-new.png',
                            },
                        ],
                    };
                }
                res.pictureInfo.description = res.activityVo.description;
            } else {
                if (!id) {
                    res.result =
                        res &&
                        res.result.map((item: any) => {
                            item.product = {
                                name: item.productName,
                                img: item.picture.url,
                                priceName: services.language.getText('dj'),
                                price: item.price.toFixed(2),
                                styleName: item.multipleOptions ? item.goodsName : '',
                            };
                            item.subsiteName = item.subsites[0].name;
                            return item;
                        });
                }
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/bargain_activities';
        let newParams: any = {};
        newParams.activityVo = {
            eventType: 'BARGAIN',
            name: params.baseInfo.name,
            description: params.pictureInfo.description,
            startTime: params.baseInfo.dateRange.start,
            endTime: params.baseInfo.dateRange.end,
            normalConditions: [
                {
                    all: false,
                    normalConditionType: 'SUB_SITE',
                    includeIds: [
                        {
                            id: params.baseInfo.subsites,
                        },
                    ],
                },
            ],
            rewardConfig: {
                rewardRule: 'CYCLE',
                activityRewardLimit: 1,
                crowdScope: 'EACH',
                activity_rewards: [
                    {
                        couponRewards:
                            params.helpInfo.coupons &&
                            params.helpInfo.coupons.map((couponItem: any) => {
                                couponItem.status = 'ENABLED';
                                return couponItem;
                            }),
                    },
                ],
            },
            shareConfig: {
                shareContent: params.pictureInfo.shareDescription,
                sharePicture: undefined,
                status: true,
            },
        };
        const productOrGoods = params.baseInfo.productOrGoods;
        let bargainGoods: any = {};
        let productType = '';
        if (productOrGoods.products.length > 0) {
            bargainGoods = productOrGoods.products[0];
            productType = 'NORMAL';
        }
        if (productOrGoods.goods.length > 0) {
            bargainGoods = productOrGoods.goods[0];
            productType = 'VIRTUAL_COUPON';
        }
        const pageConfig: any = {
            backgroundPicture: {
                id: params.pictureInfo.backgroundPicture[0].id,
                path: params.pictureInfo.backgroundPicture[0].path,
            },
            bargainButtonPicture: {
                id: params.pictureInfo.bargainButtonPicture[0].id,
                path: params.pictureInfo.bargainButtonPicture[0].path,
            },
            bargainRewardPicture: {
                id: params.pictureInfo.bargainRewardPicture[0].id,
                path: params.pictureInfo.bargainRewardPicture[0].path,
            },
            openBargainRewardPicture: {
                id: params.pictureInfo.openBargainRewardPicture[0].id,
                path: params.pictureInfo.openBargainRewardPicture[0].path,
            },
        };
        newParams.bargainActivity = {
            bargainGoods: {
                ...bargainGoods,
                goodsId: bargainGoods.id,
                price: bargainGoods.generalPrice,
                productType: productType,
                quantity: bargainGoods.stock,
            },
            bargainRule: {
                bargainCountEachMember: params.initiatorInfo.bargainCountEachMember,
                effectiveHours: params.initiatorInfo.effectiveHours,
                initBargainAmount: params.initiatorInfo.initBargainAmount,
                bargainAmountCalculateMethod: params.helpInfo.bargainAmountCalculateMethod,
                helpBargainCountEachMember: params.helpInfo.helpBargainCountEachMember,
                helpMemberCount: params.helpInfo.helpMemberCount,
            },
            bargainPage: {
                pageConfig: JSON.stringify(pageConfig),
            },
        };
        newParams.marketingScheduleId =
            (params.baseInfo?.marketingSchedule?.length &&
                params.baseInfo.marketingSchedule[0].id) ||
            null;
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = `/admin/bargain_activities/${id}`;
        let newParams: any = {};
        newParams.id = id;
        newParams.name = params.baseInfo.name;
        newParams.endTime = params.baseInfo.dateRange.end;
        newParams.description = params.pictureInfo.description;

        const productOrGoods = params.baseInfo.productOrGoods;
        let bargainGoods: any = {};
        if (productOrGoods.products.length > 0) {
            bargainGoods = productOrGoods.products[0];
        }
        if (productOrGoods.goods.length > 0) {
            bargainGoods = productOrGoods.goods[0];
        }
        newParams.quantity = bargainGoods.stock;
        newParams.reward = {
            id: params.helpInfo.rewardConfigId,
            crowdScope: 'EACH',
            rewardRule: 'CYCLE',
            activityRewardLimit: 1,
            couponRewards:
                params.helpInfo.coupons && params.helpInfo.coupons.length > 0
                    ? params.helpInfo.coupons.map((couponItem: any) => {
                          return {
                              ...couponItem,
                              id: couponItem.rewardId,
                              status: 'ENABLED',
                          };
                      })
                    : undefined,
        };
        const pageConfig: any = {
            backgroundPicture: {
                id: params.pictureInfo.backgroundPicture[0].id,
                path: params.pictureInfo.backgroundPicture[0].path,
            },
            bargainButtonPicture: {
                id: params.pictureInfo.bargainButtonPicture[0].id,
                path: params.pictureInfo.bargainButtonPicture[0].path,
            },
            bargainRewardPicture: {
                id: params.pictureInfo.bargainRewardPicture[0].id,
                path: params.pictureInfo.bargainRewardPicture[0].path,
            },
            openBargainRewardPicture: {
                id: params.pictureInfo.openBargainRewardPicture[0].id,
                path: params.pictureInfo.openBargainRewardPicture[0].path,
            },
        };
        newParams.bargainPage = {
            pageConfig: JSON.stringify(pageConfig),
        };
        newParams.shareConfig = {
            shareContent: params.pictureInfo.shareDescription,
            sharePicture: undefined,
            status: true,
        };
        newParams.marketingScheduleId =
            (params.baseInfo?.marketingSchedule?.length &&
                params.baseInfo.marketingSchedule[0].id) ||
            null;

        return await api.put(newParams, config);
    },
};
