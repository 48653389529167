import React from 'react';
import classNames from 'classnames';
import { modes } from '@comall-backend-builder/types';
import { NumberInputTip } from '../../../components/number-input-tip';

export class NumberInputTipMode extends modes.NumberIntegerMode {
    getDisplayComponent(value: any, displayInfo: any): JSX.Element {
        if (value || value === 0) {
            return (
                <span>
                    <span>{value}</span>
                </span>
            );
        } else {
            return this.getNotAvailableDisplayComponent(displayInfo);
        }
    }

    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            '暂无数据'
        );
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <NumberInputTip {...displayInfo} />;
    }
}
