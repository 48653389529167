import React, { PureComponent } from 'react';
import { Input, Button, Popconfirm, message as AntMessage, Modal, Table, Form } from 'antd';
import { services } from '@comall-backend-builder/core';
import PropTypes from 'prop-types';
import { clone, isEqual, map, concat, isEmpty, forEach } from 'lodash';
import './index.less';

const { getText } = services.language;

type RulesValue = {
    /**
     * 协议前缀
     */
    agreementPrefix?: string;
    /**
     * 协议后缀
     */
    agreementSuffix?: string;
    /**
     * 协议id
     */
    id?: string;
    /**
     * 协议名称
     */
    name?: string;
    showModal?: number;
};
export interface PrivacyAgreement {
    id: string;
    name: string;
    positionName: string;
}

export interface CommonSettingOptionrProps {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: Array<RulesValue> | null, name: string) => void;
    /**
     * 当前值
     */
    value: Array<RulesValue> | null;
}

const CommonCreateForm = Form.create({ name: 'commonForm' })(
    class extends React.Component {
        static propTypes: {
            onSearch: PropTypes.Requireable<(...args: any[]) => any>;
        };
        handleReset = () => {
            const { form } = this.props as any;
            form.resetFields();
        };
        handleSearch = (e: any) => {
            e.preventDefault();
            const { form, onSearch } = this.props as any;
            form.validateFields((err: any, values: any) => {
                if (!err) {
                    onSearch(values);
                }
            });
        };
        render() {
            const { form } = this.props as any;
            const { getFieldDecorator } = form;
            return (
                <Form layout="inline" ref="commonForm">
                    <Form.Item label={getText('wzmc_1')}>
                        {getFieldDecorator('name')(
                            <Input placeholder={getText('wzmcInputPlease')} />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" onClick={this.handleSearch}>
                            {getText('cx')}
                        </Button>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="reset" onClick={this.handleReset}>
                            {getText('common.reset')}
                        </Button>
                    </Form.Item>
                </Form>
            );
        }
    }
);
/**
 * 邀请好友阶梯组件
 */
export class CommonSettingOption extends PureComponent<CommonSettingOptionrProps, any> {
    state = {
        searchParams: {},
        pagination: {
            page: 1,
            perPage: 10,
        },
        selectedRows: new Array<PrivacyAgreement>(),
        selectedIndex: 0,
        showModal: false,
        datasource: [],
        loading: false,
        total: 0,
    };
    tableConfig = {
        rowKey: 'id',
        columns: [
            {
                title: getText('ywlx'),
                dataIndex: 'businessTypeStr',
                key: 'businessTypeStr',
            },
            {
                title: getText('wzbt'),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: getText('status'),
                dataIndex: 'status',
                key: 'status',
                render: (status: number) => {
                    return (
                        <span className="goods-field-name">
                            {status === 1 ? getText('beforeOpen') : getText('beforeClose')}
                        </span>
                    );
                },
            },
            {
                title: getText('cjrq'),
                dataIndex: 'createTime',
                key: 'createTime',
            },
        ],
    };
    onAgreementPrefixChange(inputValue: any, index: number) {
        let { name, value, onChange } = this.props;
        value = value && value.length ? value : [{}];
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.agreementPrefix = inputValue.target.value;
            value = concat([], value);
            value.splice(index, 1, item);
            if (onChange) {
                onChange(value, name);
            }
        }
    }

    onAgreementSuffixChange(inputValue: any, index: number) {
        let { name, value, onChange } = this.props;
        value = value && value.length ? value : [{}];
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.agreementSuffix = inputValue.target.value;
            value = concat([], value);
            value.splice(index, 1, item);
            if (onChange) {
                onChange(value, name);
            }
        }
    }

    onAddRule() {
        let { name, value, onChange } = this.props;
        value = value && value.length ? value : [{}];
        if (value && value.length > 0) {
            let hasValidate = true;
            forEach(value, (reward: RulesValue) => {
                if (!reward.agreementPrefix && !reward.agreementSuffix && !reward.name) {
                    hasValidate = false;
                }
            });
            if (!hasValidate) {
                AntMessage.warning(getText('ysxxzstxyg'));
                return false;
            }
        } else {
            value = [{}];
        }
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(value.length, 0, {});
        }

        if (onChange) {
            onChange(value, name);
        }
    }

    onRemoveRule(index: number) {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(index, 1);
        }
        if (onChange) {
            onChange(value, name);
        }
    }

    shouldComponentUpdate(nextProps: CommonSettingOptionrProps) {
        return !isEqual(this.props, nextProps);
    }

    deleteProtocol(index: number) {
        let { name, value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[index]);
            item.id = '';
            item.name = '';
            value = concat([], value);
            value.splice(index, 1, item);
            if (onChange) {
                onChange(value, name);
            }
        }
    }
    openModal(index: number) {
        this.onSearch();
        this.setState({ showModal: true, selectedIndex: index }, () => {
            this.refreshPage();
        });
    }
    closeModal = () => {
        this.setState({ showModal: false }, () => {
            this.refreshPage();
        });
    };

    toggleModal = () => {
        const { showModal, selectedRows, selectedIndex } = this.state;

        let { name, value, onChange } = this.props;
        this.setState({ showModal: !showModal }, () => {
            value = value && value.length ? value : [{}];
            if (value !== null && !isEmpty(value) && selectedRows && selectedRows.length > 0) {
                let item = clone(value[selectedIndex]);
                item.id = selectedRows[0].id;
                item.name = selectedRows[0].name;
                value = concat([], value);
                value.splice(selectedIndex, 1, item);
                if (onChange) {
                    onChange(value, name);
                }
            } else {
                AntMessage.warning(getText('qxzxy'));
            }
        });
    };
    //刷新，不然弹不出 model
    refreshPage = () => {
        const { onChange, name, value } = this.props;
        if (value !== null && !isEmpty(value)) {
            let item = clone(value[0]);
            item.showModal = Math.random();
            let values = concat([], value);
            values.splice(0, 1, item);
            onChange(values, name);
        } else {
            onChange([{ showModal: Math.random() }], name);
        }
    };
    onSearch = async (searchParams: object = {}) => {
        this.setState({ searchParams: searchParams });
        const { pagination } = this.state;
        try {
            let res: any = await services.api.get(
                { ...searchParams, ...pagination },
                {
                    apiPath: '/admin/privacy_agreement',
                    apiRoot: ENV.API_ROOT,
                }
            );
            this.setState({ datasource: res.result, total: res.totalNum }, () => {
                this.refreshPage();
            });
        } catch (error) {
            throw error;
        }
    };
    onTableRowSelect = (record: RulesValue) => {
        const rows = [];
        rows.push(record);
        this.setState({ selectedRows: rows }, () => {
            this.refreshPage();
        });
    };

    renderRule(rule: RulesValue, index: number) {
        return (
            <div className="rule" key={index}>
                <div className="rule-content">
                    <div className="rule-count-input">
                        <Input
                            style={{
                                width: '400px',
                            }}
                            value={rule.agreementPrefix}
                            placeholder={services.language.getText('inputPlease')}
                            onChange={(e) => this.onAgreementPrefixChange(e, index)}
                        />
                    </div>

                    <div className="rule-count-input">
                        {rule.name ? (
                            <Popconfirm
                                onConfirm={this.deleteProtocol.bind(this, index)}
                                title={getText('sfscgxy')}
                                okText={services.language.getText('common.ok')}
                                cancelText={services.language.getText('common.cancel')}
                            >
                                <div className="add-rule-close">
                                    {rule.name.length > 6
                                        ? rule.name.substring(0, 6) + '...'
                                        : rule.name}
                                    &nbsp;x
                                </div>
                            </Popconfirm>
                        ) : (
                            <div className="rule-button">
                                <Button type="link" onClick={this.openModal.bind(this, index)}>
                                    {getText('tjxy')}
                                </Button>
                            </div>
                        )}
                    </div>
                    <div className="rule-count-input">
                        <Input
                            style={{
                                width: '400px',
                            }}
                            value={rule.agreementSuffix}
                            placeholder={services.language.getText('inputPlease')}
                            onChange={(e) => this.onAgreementSuffixChange(e, index)}
                        />
                    </div>
                    {index > 0 ? (
                        <Popconfirm
                            onConfirm={this.onRemoveRule.bind(this, index)}
                            title={getText('sfscgtxx')}
                            okText={services.language.getText('common.ok')}
                            cancelText={services.language.getText('common.cancel')}
                        >
                            <span style={{ color: '#1890ff', cursor: 'pointer' }}>
                                {services.language.getText('common.delete')}
                            </span>
                        </Popconfirm>
                    ) : null}
                </div>
            </div>
        );
    }
    pageChange = (pageVal: object = {}) => {
        const { searchParams } = this.state;
        this.setState({ pagination: { ...pageVal } }, () => {
            this.onSearch(searchParams);
        });
    };
    render() {
        let { value } = this.props;
        const { showModal, datasource, loading, selectedRows, total } = this.state;
        value = value && value.length ? value : [{}];
        return (
            <div className="common-setting-option">
                {/* <div>
                    <Button type="primary" onClick={this.harderChangeSetting}>
                        {value && value.length > 0 ? '清空选项' : '添加选项'}
                    </Button>
                </div> */}
                {map(value, (rule, index) => this.renderRule(rule, index))}
                {value && value.length > 0 && value.length < 10 && (
                    <Button
                        type="default"
                        className="add-rule-button"
                        onClick={() => this.onAddRule()}
                    >
                        {getText('tjxx')}
                    </Button>
                )}
                <Modal
                    onCancel={this.closeModal}
                    footer={[
                        <Button type="primary" onClick={this.toggleModal}>
                            {getText('common.ok')}
                        </Button>,
                    ]}
                    visible={showModal}
                    title={getText('xy_1')}
                    width={900}
                >
                    <div className="clerk-search">
                        <CommonCreateForm onSearch={this.onSearch} />
                    </div>
                    <Table
                        loading={loading}
                        rowSelection={{
                            type: 'radio',
                            selectedRowKeys: map(selectedRows, 'id'),
                            onSelect: (record: RulesValue) => {
                                this.onTableRowSelect(record);
                            },
                        }}
                        dataSource={datasource}
                        pagination={{
                            pageSize: 10,
                            total,
                            onChange: (page, perPage) => {
                                this.pageChange({ page, perPage });
                            },
                        }}
                        {...this.tableConfig}
                    ></Table>
                </Modal>
            </div>
        );
    }
}
