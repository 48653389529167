import { Component, createElement } from 'react';

import { ComponentsManager } from '@comall-backend-builder/core';
import { navigation } from '@comall-backend-builder/core/lib/services';
import { extendsParentProps } from '@comall-backend-builder/components-basis';
import { isEqual } from 'lodash';

/**
 * 模板调度台定时刷新
 *  @param {Boolean} isActive - 是否为激活状态面板
 *  @param {Boolean} switchRefresh - 切换到当前tab是否刷新 entity
 *  @param {Object} entity - 定时刷新对应 entity
 */
export class WechatTemplatesFindInterval extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            keyword: null,
        };
    }

    componentDidMount() {
        const { entity, params } = this.props;
        const keyword = this.getKeywords();
        if (keyword) {
            params.keyword = keyword;
        }
        const { loadFirstPage } = this.props.content;
        if (loadFirstPage !== true && params.subsiteId) {
            entity.search(params);
        }
    }

    shouldComponentUpdate(nextProps: any) {
        if (!nextProps.isActive) {
            return false;
        }
        if (isEqual(this.props, nextProps)) {
            return false;
        }
        return true;
    }

    componentWillReceiveProps(nextProps: any) {
        const {
            isActive,
            entity,
            params: { subsiteId },
            switchRefresh,
        } = this.props;
        const { params } = nextProps;
        const keyword = this.getKeywords();
        params.keyword = keyword;
        if (!params.subsiteId) {
            return;
        }
        if (entity && isActive && params.subsiteId !== subsiteId) {
            entity.search(params);
            return;
        }
        if (switchRefresh && nextProps.isActive && isActive !== nextProps.isActive) {
            entity.search(params);
            return;
        }
        if (keyword !== this.state.keyword && nextProps.isActive) {
            this.setState({
                keyword,
            });
            entity.search(params);
            return;
        }
    }

    getKeywords = () => {
        const history = navigation.getHistory();
        const {
            location: { pathname },
        } = history;
        const path = pathname.split('?');
        if (path && path.length > 1) {
            const query = path[1];
            const params = query.split('=');
            if (params && params.length > 1 && params[0] === 'keyword') {
                return params[1];
            } else {
                return null;
            }
        } else {
            return null;
        }
    };

    renderContent() {
        let { component, ...otherProps } = this.props.content;
        otherProps.refresh = this.props.refresh;
        let props = extendsParentProps(otherProps, this.props);
        return createElement(ComponentsManager.get(component), props);
    }

    render() {
        return this.renderContent();
    }
}
