import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { SalesElectronicCouponSelect } from './sales-electronic-coupon-select'

export class SalesElectronicCouponSelectMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <SalesElectronicCouponSelect {...controlInfo}/>
    }
}
