import { Config } from '@comall-backend-builder/core/lib/parser';

export const SubscribeConfigurationConfig: Config = {
    entities: {
        SubscribeConfigurationEntity: {
            apiPath: '/loader/admin/sales-electronic-coupon',
            properties: {},
        },
    },
    components: {
        SubscribeConfigurationView: {
            component: 'Viewport',
        },
        SubscribeConfigurationPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport-no-padding',
            items: [
                {
                    component: 'SubscribeConfigPage',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/subscription-configs',
                    component: 'SubscribeConfigurationView',
                    breadcrumbName: '<<subscriptionConfig>>',
                    privilege: {
                        path: 'JiuquSubscribeConfig',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/',
                            component: 'SubscribeConfigurationPage',
                        },
                    ],
                },
            ],
        },
    ],
};
