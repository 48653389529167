import React, { PureComponent } from 'react';
import { Modal, Input, message as AntMessage } from 'antd';
import { cloneDeep, find, forEach } from 'lodash';
import { UploadFileAll } from '../../../../components/upload-file-all';
import { services } from '@comall-backend-builder/core';
import { api, language } from '@comall-backend-builder/core/lib/services';
import { MessageParameterSelector } from '../message-parameter-selector';
import { RichTextPlus } from '../../../../components';
import { MsgChannelType } from '../../messages-subscription-find-list-item';
import { SceneType } from '../../common';

interface TemplateParamsVo {
    name: string;
    value: any;
    color: string;
    defaultValue: string;
    editable: boolean;
    fieldType: string;
    info: string;
}

interface TemplateVo {
    id?: number;
    name: string;
    scene: SceneType;
    sceneRemark: string;
    alias: string;
    code: string;
    miniTargetUrl: string;
    top?: string;
    other?: Array<TemplateParamsVo>;
    bottom?: string;
    topDefaultValue?: string;
    detailContentColor?: string;
    bottomContentColor?: string;
    bottomDefaultValue?: string;
    templateId?: string;
    msgChannelType?: MsgChannelType;
}

interface Props {
    /**
     * 切换弹层是否可见
     */
    handleVisible: () => void;
    /**
     * 弹层是否可见
     */
    visible: boolean;

    refreshList: () => void;

    /**
     * 模板ID
     */
    editId?: number;
}

/**
 * 邮件编辑
 */
interface State {
    data: TemplateVo | undefined;
    /**
     * 主题
     */
    theme: string;

    /**
     * 附件
     */
    fileIds: Array<any>;
    /**
     * 邮件正文
     */
    content: string;
}
export class MessagesSubscriptionEmailEditModal extends PureComponent<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            data: undefined,
            theme: '',
            fileIds: [],
            content: '',
        };
    }

    componentDidMount() {
        this.loadTemplateInfo();
    }

    loadTemplateInfo = () => {
        const { editId } = this.props;
        if (!editId) {
            //新增手动模板
            this.setState({
                data: {
                    name: '',
                    scene: SceneType.MANUAL_ADD,
                    sceneRemark: '',
                    alias: '',
                    code: '',
                    miniTargetUrl: '',
                    msgChannelType: MsgChannelType.WECHAT_OFFICIAL_ACCOUNT,
                },
            });
            return;
        }
        api.get(
            { messageChannelType: 'EMAIL' },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                apiPath: '/admin/template_definitions/' + editId,
            }
        ).then((data: any) => {
            if (data && data.params) {
                const top = find(data.params, { name: 'top' });
                const bottom = find(data.params, { name: 'bottom' });
                if (top) {
                    data.top = top.value;
                    data.topDefaultValue = top.defaultValue;
                }
                if (bottom) {
                    data.bottom = bottom.value;
                    data.bottomContentColor = bottom.color;
                    data.bottomDefaultValue = bottom.defaultValue;
                }
                const other: any = [];
                forEach(data.params, (param: any) => {
                    if (param.name !== 'name' && param.name !== 'top' && param.name !== 'bottom') {
                        if (param.fieldType === 'file' && param.value) {
                            param.value = JSON.parse(param.value);
                        }
                        other.push(param);
                    }
                });
                data.other = other;
                if (other.length > 0) {
                    data.detailContentColor = other[0].color;
                }
            }
            this.setState({
                data: data,
            });
        });
    };

    /**
     * 编辑邮件内容
     */
    isSubmit = false;
    handleSubmit = () => {
        const { data } = this.state;
        const { refreshList, handleVisible } = this.props;
        if (!data) {
            return;
        }
        const detailContent: any = [];
        let msg = '';
        let flag = true;
        if (data?.other && data.other.length > 0) {
            forEach(data.other, (o: any) => {
                // 主题是必填项
                if (o.fieldType === 'string' && !o.value) {
                    msg = language.getText('requestTheme');
                    flag = false;
                }
                let fileValue: string = '';
                if (o.fieldType === 'file') {
                    fileValue = o.value && o.value.map((item: any) => item.id).join(',');
                } else {
                    fileValue = o.value;
                }
                detailContent.push({
                    name: o.name,
                    value: fileValue,
                    field_type: o.fieldType, //后端要求蛇形
                });
            });
        }
        if (!flag) {
            AntMessage.error(msg);
            return;
        }
        const params = {
            msgChannelType: MsgChannelType.EMAIL,
            topContent: data.top,
            bottomContent: data.bottom,
            bottomContentColor: data.bottomContentColor,
            detailContentColor: data.detailContentColor,
            scene: data.scene,
        };

        if (this.isSubmit) {
            return;
        }
        this.isSubmit = true;

        api.put(
            {
                ...params,
                detailContent: JSON.stringify(detailContent),
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                apiPath: '/admin/template_definitions/' + data.id,
            }
        )
            .then(() => {
                AntMessage.success(language.getText('editSuccess'));
                handleVisible();
                refreshList();
            })
            .catch((error) => {
                services.errorHandle(error);
            })
            .finally(() => {
                setTimeout(() => {
                    this.isSubmit = false;
                }, 1000);
            });
    };

    changeTheme = (e: any) => {
        this.commonHandleChange(e.target.value, 'string');
    };

    /**
     * 修改附件
     */
    changeFile = (file: any) => {
        const { data } = this.state;
        if (!data) {
            return;
        }
        let newData = cloneDeep(data);
        console.log(newData);
        forEach(newData?.other, (item) => {
            if (item.fieldType === 'file') {
                item.value = [...item.value, file];
            }
        });
        this.setState({
            data: newData,
        });
    };

    /**
     * 删除附件
     */
    removeFile = (file: any) => {
        const { data } = this.state;
        if (!data) {
            return;
        }
        let newData = cloneDeep(data);
        forEach(newData?.other, (item) => {
            if (item.fieldType === 'file') {
                item.value = item.value.filter((o: any) => {
                    return o.id !== file.id;
                });
            }
        });
        this.setState({
            data: newData,
        });
    };

    /**
     * 修改邮件正文
     */
    changeRichText = (value: any) => {
        this.commonHandleChange(value, 'mediumtext');
    };

    commonHandleChange = (value: any, name: string) => {
        const { data } = this.state;
        if (!data) {
            return;
        }
        const newOthers: any = [];
        forEach(data.other, (item: any) => {
            if (item.fieldType === name) {
                item.value = value;
            }
            newOthers.push(item);
        });
        this.setState({
            data: { ...data, other: newOthers },
        });
    };

    render() {
        const { data } = this.state;
        const { visible, handleVisible } = this.props;
        const params = {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: `/FILE/admin/files/locations/1300/upload`,
        };
        const richText: any = {
            toolbarId: 'descriptionRichText',
            style: {
                height: 300,
            },
            uploadConfig: {
                apiPath: `/admin/images/upload`,
                fileName: 'fileName',
            },
            onChange: this.changeRichText,
        };
        return (
            <Modal
                visible={visible}
                width={1000}
                className="message-subscription-email-modal"
                title={language.getText('editMessageContent')}
                onCancel={handleVisible}
                onOk={this.handleSubmit}
            >
                <div className="message-subscription-email-item">
                    <MessageParameterSelector params={{ scene: data?.scene }} />
                    <div>{language.getText('qfzkydblmc')}</div>
                </div>
                {data?.other &&
                    data.other.length > 0 &&
                    data.other.map((item: any) => {
                        return (
                            <div key={item.id}>
                                <div className="message-subscription-email-item">
                                    {item.fieldType === 'string' && (
                                        <div style={{ color: 'red' }}>*</div>
                                    )}
                                    <div className="message-subscription-email-label">
                                        {item.name}：
                                    </div>
                                    {item.fieldType === 'string' && (
                                        <Input
                                            className="message-subscription-email-input"
                                            placeholder={language.getText('requestTheme')}
                                            value={item.value}
                                            onChange={this.changeTheme}
                                        />
                                    )}
                                    {item.fieldType === 'file' && (
                                        <UploadFileAll
                                            params={params}
                                            onAddFile={this.changeFile}
                                            removeFile={this.removeFile}
                                            fileList={item.value}
                                        />
                                    )}
                                    {item.fieldType === 'mediumtext' && (
                                        <RichTextPlus {...richText} value={item.value} />
                                    )}
                                </div>
                            </div>
                        );
                    })}
            </Modal>
        );
    }
}
