import React, { Component } from 'react';
import { Upload, Icon, message as AntMessage } from 'antd';
import { UploadChangeParam } from 'antd/lib/upload';
import { services } from '@comall-backend-builder/core';
import { localStorage } from '@comall-backend-builder/core/lib/services';
import './index.less';

export class ImgaeUpload extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = { loading: false };
    }
    beforeUpload = (file: any) => {
        const { maxSize, uploadLimitSizeErrorMessage } = this.props;
        if (file.size > maxSize * 1024) {
            var errorMessage =
                uploadLimitSizeErrorMessage === undefined
                    ? '图片{{file.name}}超出限制'
                    : uploadLimitSizeErrorMessage;
            AntMessage.error(services.interpolate(errorMessage, { file: file, maxSize: maxSize }));
            return false;
        }
        return true;
    };
    handleChange = (info: UploadChangeParam) => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
        if (info.file.status === 'done') {
            const { onChange } = this.props;
            if (info.file && info.file.response) {
                if (onChange) {
                    onChange(info.file.response);
                }
            }
            this.setState({
                loading: false,
            });
        }
    };

    render() {
        const { uploadParam } = this.props;
        const user: any = localStorage.get('user');
        const token = encodeURI(`Bearer ${user.accessToken}`);
        const _controlInfo = {
            ...uploadParam,
            showUploadList: false,
            headers: {
                Authorization: token,
            },
        };
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text">{services.language.getText('upload')}</div>
            </div>
        );
        return (
            <Upload {..._controlInfo} beforeUpload={this.beforeUpload} onChange={this.handleChange}>
                {uploadButton}
            </Upload>
        );
    }
}
