import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { ContentPlateTypes } from '../config/content/content-plate';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';

const api = services.api;

function formatSubmitParams(params: any) {
    const {
        resource: [resource],
        sequence,
        plateType,
        generalPlateIds,
        publicityPlateIds,
        topicValidity,
    } = params;
    return {
        ...params,
        resourceId: +resource.id,
        resourceUrl: resource.path,
        plateIds:
            plateType === ContentPlateTypes.GENERAL
                ? generalPlateIds.map((id: any) => +id)
                : plateType === ContentPlateTypes.PUBLICITY
                ? publicityPlateIds.map((id: any) => +id)
                : [],
        sequence: +sequence,
        resource: undefined,
        startTime: isEmpty(topicValidity) ? null : get(topicValidity, 'start'),
        endTime: isEmpty(topicValidity) ? null : get(topicValidity, 'end'),
    };
}

function formatResult(result: any) {
    const { plateIds, resourceId, resourceUrl, plateNames } = result;
    const plateIdsList = plateIds.map((id: any) => String(id));
    return {
        ...result,
        topicName: result.name,
        plateIds: plateIdsList,
        plateNames: plateNames ? plateNames.join('、') : '',
        generalPlateIds: result.plateType === ContentPlateTypes.GENERAL ? plateIdsList : [],
        publicityPlateIds: result.plateType === ContentPlateTypes.PUBLICITY ? plateIdsList : [],
        resource: resourceUrl
            ? [
                  {
                      id: resourceId,
                      path: resourceUrl,
                  },
              ]
            : [],
        topicValidity:
            isEmpty(get(result, 'startTime')) && isEmpty(get(result, 'endTime'))
                ? null
                : {
                      start: get(result, 'startTime'),
                      end: get(result, 'endTime'),
                  },
    };
}

function formatResultList(result: any) {
    return result.map(formatResult);
}

export const ContentTopicLoader: Loader = {
    async get(params, config: ApiRequestConfig) {
        return api.get<any>(params, config).then((response) => {
            if (response.result) {
                response.result = formatResultList(response.result);
            } else {
                response = formatResult(response);
            }
            return response;
        });
    },
    async post(params, config: ApiRequestConfig) {
        return api.post(formatSubmitParams(params), config);
    },
    async put(params, config: ApiRequestConfig) {
        return api.put(formatSubmitParams(params), config);
    },
};
