import * as React from 'react';
import classNames from 'classnames';
import { map } from 'lodash';
import { ArrayMode } from '@comall-backend-builder/types';
import { FullReductionLadder } from '../../../components';
import { services } from '@comall-backend-builder/core';

export class ArrayFullReductionLadderMode extends ArrayMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(array: any, displayInfo: any) {
        const { className, style } = displayInfo;
        if (array && array.length > 0) {
            return (
                <div className={classNames('type-array', className)} style={style}>
                    {map(array, ({ stepvalue, rewardValue }, index) => {
                        return (
                            <div key={index}>
                                <span>
                                    {services.language.getText('orderAmount')}≥ {stepvalue}{' '}
                                    {services.language.getText('yflj')}{' '}
                                </span>
                                <span>
                                    {rewardValue} {services.language.getText('yuan')}。
                                </span>
                            </div>
                        );
                    })}
                </div>
            );
        } else {
            return this.getNotAvailableDisplayComponent(displayInfo);
        }
    }
    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            services.language.getText('notData')
        );
    }
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <FullReductionLadder {...controlInfo} />;
    }
}
