import { Type } from '@comall-backend-builder/core';
import { formats } from '@comall-backend-builder/types';

import { PasswordValidityPeriodStatusMode } from './mode/string/password-validity-period-status';
import { PhoneMode } from './mode/string/phone';
import { IntroductionMode } from './mode/string/introduction';
import { LastLoginTimeMode } from './mode/string/last-login-time';
import { ImageAvatarMode } from './mode/string/image-avatar';
import { CustomTransferMode } from './mode/array/custom-transfer';

// cae 重构
import { RolePrivilegeMode } from './mode/array/role-privilege';
import { RoleSelectorMode } from './mode/array/role-selector';
import { OrganizationSelectorMode } from './mode/array/organization-selector';
import { OrganizationStoreSelectorMode } from './mode/array/organization-store-selector';
import { ExtendAttributesMode } from './mode/array/extend-attributes';
import { RoleSystemTagMode } from './mode/string/role-system-tag';
import { ArrayExtendAttributes } from './format/extend-attributes';
import { CreateMerchantMode } from './mode/boolean/create-merchant';
import { StringCascaderMode } from './mode/string/cascader';
const myTypes = new Map<string, Type>();
const { StringFormat, ArrayFormat, BooleanFormat } = formats;

myTypes.set(
    'string.passwordValidityPeriodStatus',
    new Type(new StringFormat(), new PasswordValidityPeriodStatusMode())
);
myTypes.set('string.phone', new Type(new StringFormat(), new PhoneMode()));
myTypes.set('string.introduction', new Type(new StringFormat(), new IntroductionMode()));
myTypes.set('string.lastLoginTime', new Type(new StringFormat(), new LastLoginTimeMode()));
myTypes.set('string.imageAvatar', new Type(new StringFormat(), new ImageAvatarMode()));
myTypes.set(
    'array.optionIds.customTransfer',
    new Type(new ArrayFormat(), new CustomTransferMode())
);

// cae 重构
myTypes.set('array.role.privilege', new Type(new ArrayFormat(), new RolePrivilegeMode()));
myTypes.set(
    'array.role.selector',
    new Type(new formats.ArrayNoValidationFormat(), new RoleSelectorMode())
);
myTypes.set(
    'object.organization.selector',
    new Type(new formats.ObjectNoValidationFormat(), new OrganizationSelectorMode())
);
myTypes.set(
    'array.extendAttributes',
    new Type(new ArrayExtendAttributes(), new ExtendAttributesMode())
);
myTypes.set(
    'array.organization.store',
    new Type(new formats.ArrayNoValidationFormat(), new OrganizationStoreSelectorMode())
);

myTypes.set('boolean.merchant.select', new Type(new BooleanFormat(), new CreateMerchantMode()));
myTypes.set('string.role.system', new Type(new StringFormat(), new RoleSystemTagMode()));

myTypes.set('string.cascader', new Type(new StringFormat(), new StringCascaderMode()));

export default myTypes;
