import React, { PureComponent, createElement } from 'react';
import { remove } from 'lodash';
import { ComponentsManager, EntitiesManager, services } from '@comall-backend-builder/core';
import { Button, Modal, message as AntMessage } from 'antd';

const TableComponentId = 'CardLevelSelectorTable';
let Entity: any = {};
let entity: any = {};

/**
 * 选择会员等级
 */
export class CardLevelSelector extends PureComponent<{
    subsiteId: any;
    onChange: (products: any[]) => void;
    params?: any;
}> {
    constructor(props: any) {
        super(props);
        Entity = EntitiesManager.get('cardLevel');
        entity = new Entity({});
    }
    state = { visible: false };

    selectedRows = new Array<any>();

    onTableRowSelect = (record: any, selected: boolean) => {
        if (selected) {
            this.selectedRows.push(record);
        } else {
            remove(this.selectedRows, (i) => {
                return i.id === record.id;
            });
        }
    };

    onTableRowSelectAll = (selected: boolean, rows: any[]) => {
        if (selected) {
            this.selectedRows = rows;
        } else {
            this.selectedRows = [];
        }
    };
    onClearAll = () => {
        this.selectedRows = [];
    };

    onOk = () => {
        const { onChange } = this.props;
        if (onChange) {
            onChange(this.selectedRows);
            this.toggleModal();
        }
    };

    getConfig = () => {
        const { params } = this.props;

        return {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: entity,
            params: params || {},
            items: [
                {
                    component: 'DataTable',
                    componentId: TableComponentId,
                    rowSelection: {
                        onSelect: (record: any, selected: boolean) => {
                            this.onTableRowSelect(record, selected);
                        },
                        onSelectAll: (selected: boolean, rows: any) => {
                            this.onTableRowSelectAll(selected, rows);
                        },
                        onChange: () => {},
                    },
                    columns: [
                        {
                            property: 'baseInfo.code',
                        },
                        {
                            property: 'baseInfo.level',
                        },
                        {
                            property: 'baseInfo.name',
                        },
                    ],
                },
            ],
        };
    };

    toggleModal = () => {
        const { visible } = this.state;
        const { subsiteId } = this.props;
        if (!subsiteId) {
            AntMessage.error('请选择门店');
            return;
        } else {
            this.setState({ visible: !visible }, () => {
                if (!visible) {
                    this.onClearAll();
                    entity.search({
                        subsiteId: subsiteId.id,
                    });
                }
            });
        }
    };

    render() {
        const config = this.getConfig();
        const modalContent = createElement(ComponentsManager.get(config.component), config);
        const modalProps = {
            width: 900,
            title: services.language.getText('xzdj'),
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };

        return (
            <div style={{ display: 'inline-block' }}>
                <Button type="primary" onClick={this.toggleModal}>
                    关联等级
                </Button>
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    }
}
