import React from 'react';
import classNames from 'classnames';
import { ObjectMode } from '@comall-backend-builder/types';
import { ObjectMemberMarkingContent } from './object-member-marking-content';
import { language } from '@comall-backend-builder/core/lib/services';

export class ObjectMemberMarkingContentMode extends ObjectMode {
    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            language.getText('notData')
        );
    }

    /**
     * 获取输入组件
     */
    getControlComponent(controlInfo: any): JSX.Element {
        return <ObjectMemberMarkingContent {...controlInfo} />;
    }
}
