import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { message as AntMessage } from 'antd';
import get from 'lodash/get';

export const config: Config = {
    entities: {
        DeliveryLogisticsConfigsEntity: {
            apiPath: '/loader/admin/delivery-logistics-configs',
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 200 },
                    },
                },
                merchantId: {
                    displayName: '<<merchantName>>',
                    type: 'string.filterOptions.autoComplete',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
            },
            properties: {
                id: {
                    type: 'number.integer',
                },
                subsiteId: {
                    type: 'number.integer',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                merchants: {
                    type: 'array.popover',
                    displayName: '<<merchantName>>',
                },
                expressCompanies: {
                    type: 'array.popover.list',
                    displayName: '<<expressCompanies>>',
                },
                cityWideCompanies: {
                    type: 'array.popover.list',
                    displayName: '<<cityWideCompanies>>',
                },
                insuredDescription: {
                    type: 'string',
                    displayName: '<<bj_2>>',
                },
                status: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    defaultValue: 'true',
                    options: [
                        { id: 'true', name: '<<enable>>' },
                        { id: 'false', name: '<<common.close>>' },
                    ],
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        subsite: {
                            type: 'options.select.object',
                            displayName: '<<otherSelectSubsite>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [
                                {
                                    required: true,
                                    message: '<<selectSubsite>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                placeholder: '<<selectSubsite>>',
                                allowClear: true,
                                showSearch: true,
                            },
                        },
                        subsiteMerchants: {
                            type: 'object.deliveryLogisticsConfigsMerchants',
                            displayName: '<<applyMerchants>>',
                            rules: [
                                {
                                    required: true,
                                },
                                {
                                    message: '<<pleaseSelectMerchant>>',
                                    validator: (rule: any, value: any) => {
                                        if (
                                            value &&
                                            value.selectMode &&
                                            value.selectMode === 'false' &&
                                            (!value.merchants || value.merchants.length === 0)
                                        ) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                merchantTip: '<<dszqbzjs>>',
                            },
                        },
                    },
                },
                insured: {
                    type: 'object.subForm',
                    displayName: '<<djwlgsssx>>',
                    properties: {
                        type: {
                            type: 'string.options.radio',
                            displayName: '<<bj_2>>',
                            defaultValue: 'ALL',
                            options: [
                                { id: 'ALL', name: '<<mrdbj>>' },
                                { id: 'NO', name: '<<mrbbj>>' },
                                { id: 'LOWER_LIMIT', name: '<<ddjebj>>' },
                            ],
                            rules: [{ required: true }],
                            extra: '<<yspsfjejxbj>>',
                        },
                        amount: {
                            type: 'number.tip',
                            displayName: '<<bjje>>',
                            displayConfig: { addonafter: '<<amountAfterTip>>' },
                            rules: [
                                {
                                    message: '<<amountErrorMessage>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^([1-9]\d*)$/;
                                        if (value) {
                                            if (
                                                Number(value) >= 1 &&
                                                reg.test(value) &&
                                                Number(value) <= 999999
                                            ) {
                                                return true;
                                            } else {
                                                return false;
                                            }
                                        }
                                        return true;
                                    },
                                },
                            ],
                            controlConfig: {
                                addonafter: '<<amountAfterTip>>',
                                placeholder: '<<pleaseInputNumber>>',
                                style: { width: '150px' },
                                rules: [
                                    {
                                        required: true,
                                        type: 'number',
                                        min: 0,
                                        max: 999999,
                                    },
                                ],
                            },
                        },
                        defaultAutomaticBiz: {
                            type: 'object.discountScene',
                            displayName: '<<mrfhydfs>>',
                            defaultValue: {
                                miniProgramOrderAutomatic: true,
                                shoppingListAutomatic: true,
                            },
                            rules: [{ required: true }],
                        },
                    },
                },
            },
        },
        DeliveryLogisticsCompaniesEntity: {
            apiPath: '/loader/admin/delivery-logistics-companies',
            properties: {
                id: {
                    type: 'number.integer',
                },
                companyName: {
                    type: 'string',
                    displayName: '<<wlgs>>',
                },
                company: {
                    type: 'object.option.select',
                    displayName: '<<wlgs>>',
                    options: [],
                    source: {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                        apiPath: '/admin/express_companies',
                    },
                },
                logisticsBillGenerationMethod: {
                    type: 'string.options.radio',
                    displayName: '<<scydfs>>',
                    defaultValue: 'AUTOMATIC',
                    options: [
                        { id: 'AUTOMATIC', name: '<<zdjd>>' },
                        { id: 'MANUAL', name: '<<sdtd>>' },
                    ],
                },
                account: {
                    type: 'string',
                    displayName: '<<kh>>',
                },
                password: {
                    type: 'string',
                    displayName: '<<mm>>',
                },
                defaulted: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    defaultValue: 'false',
                    options: [
                        { id: 'true', name: '<<mr>>' },
                        { id: 'false', name: '<<bmr>>' },
                    ],
                },
            },
        },
        DeliveryPrinterEntity: {
            apiPath: '/loader/admin/delivery-printers',
            properties: {
                id: {
                    type: 'number.integer',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<phoneNum>>',
                },
                bindStatus: {
                    type: 'string.options.select',
                    displayName: '<<status>>',
                    defaultValue: 'true',
                    options: [
                        { id: 'true', name: '<<ybd>>' },
                        { id: 'false', name: '<<wbd>>' },
                    ],
                },
            },
        },
    },
    components: {
        DeliveryLogisticsConfigsView: {
            component: 'Viewport',
        },
        // 门店配送公司列表
        DeliveryLogisticsConfigsPage: {
            entity: 'DeliveryLogisticsConfigsEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                { component: 'DeliveryLogisticsConfigsFilter' },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzps>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/delivery-logistics-configs/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'DeliveryLogisticsConfigsTable' },
            ],
        },
        DeliveryLogisticsConfigsFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
            ],
        },
        DeliveryLogisticsConfigsTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'DeliveryLogisticsConfigsDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: {
                        x: true,
                    },
                    columns: [
                        { property: 'subsiteName' },
                        {
                            property: 'merchants',
                            width: 180,
                        },
                        { property: 'expressCompanies' },
                        { property: 'cityWideCompanies' },
                        { property: 'insuredDescription' },
                        { property: 'status' },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        width: 320,
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    statusKey: 'status',
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },
                                    handles: [
                                        {
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                            apiPath:
                                                '/admin/delivery_logistics_configs/:id/disabled',
                                            config: {
                                                content: '{{row.subsiteName}}规则已关闭',
                                                text: '<<common.close>>',
                                            },
                                            confirm: {
                                                text: '<<isAreadyClose>>{{row.subsiteName}}规则?',
                                                className: 'confirm-content',
                                            },
                                        },
                                        {
                                            value: false,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                            apiPath:
                                                '/admin/delivery_logistics_configs/:id/enabled',
                                            config: {
                                                content: '{{row.subsiteName}}<<gzyqy>>',
                                                text: '<<enable>>',
                                            },
                                            confirm: {
                                                text: '<<isEnable>>{{row.subsiteName}}<<rule>>?',
                                            },
                                        },
                                    ],
                                },
                            },
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                path: '/delivery-logistics-configs/edit/{{row.id}}',
                            },
                            {
                                type: 'link',
                                text: '<<szpsgs>>',
                                path:
                                    '/delivery-logistics-configs/{{row.subsiteId}}/companies?subsiteName={{row.subsiteName}}&logisticsConfigId={{row.id}}',
                            },
                            {
                                type: 'link',
                                text: '<<pzdyy>>',
                                path:
                                    '/delivery-logistics-configs/{{row.subsiteId}}/printers?subsiteName={{row.subsiteName}}',
                            },
                        ],
                    },
                },
            ],
        },
        // 新增门店
        DeliveryLogisticsConfigsAddPage: {
            component: 'Card',
            content: { component: 'DeliveryLogisticsConfigsAddForm' },
        },
        DeliveryLogisticsConfigsAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'DeliveryLogisticsConfigsEntity',
            labelCol: 4,
            controlCol: 20,
            fields: [
                { property: 'baseInfo.subsite' },
                {
                    property: 'baseInfo.subsiteMerchants',
                    visible: (values: any) => {
                        const subsite = get(values, 'baseInfo.subsite');
                        if (subsite && subsite.format !== 'SUPERMARKET') {
                            return true;
                        }
                        return false;
                    },
                },
                { property: 'insured.type' },
                {
                    property: 'insured.amount',
                    visible: (values: any) => get(values, 'insured.type') === 'LOWER_LIMIT',
                },
                { property: 'insured.defaultAutomaticBiz' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        // 编辑门店
        DeliveryLogisticsConfigsEditPage: {
            component: 'Card',
            content: { component: 'DeliveryLogisticsConfigsEditForm' },
        },
        DeliveryLogisticsConfigsEditForm: {
            component: 'ModifyFormPlus',
            componentId: 'DeliveryLogisticsConfigsEditForm',
            direction: 'horizontal',
            entity: 'DeliveryLogisticsConfigsEntity',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: [
                {
                    property: 'baseInfo.subsite',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'baseInfo.subsiteMerchants',
                    visible: (values: any) => {
                        const subsite = get(values, 'baseInfo.subsite');
                        if (subsite && subsite.format !== 'SUPERMARKET') {
                            return true;
                        }
                        return false;
                    },
                },
                { property: 'insured.type' },
                {
                    property: 'insured.amount',
                    visible: (values: any) => get(values, 'insured.type') === 'LOWER_LIMIT',
                },
                { property: 'insured.defaultAutomaticBiz' },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        // 配送公司列表
        DeliveryLogisticsCompaniesPage: {
            entity: 'DeliveryLogisticsCompaniesEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'DeliveryLogisticsCompaniesTableView' }],
        },
        // 门店打印员列表
        DeliveryPrinterPage: {
            entity: 'DeliveryPrinterEntity',
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    style: {
                        paddingTop: 10,
                        paddingBottom: 10,
                    },
                    items: [
                        {
                            component: 'AddPrinterButton',
                        },
                    ],
                },
                { component: 'DeliveryPrinterTable' },
            ],
        },
        DeliveryPrinterTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'DeliveryPrinterTableDataTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: {
                        x: true,
                    },
                    columns: [{ property: 'mobile' }, { property: 'bindStatus' }],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        width: 320,
                        items: [
                            {
                                type: 'component',
                                component: 'ChangeStatusButton',
                                config: {
                                    statusKey: 'bindStatus',
                                    renderComponent: 'Button',
                                    type: 'link',
                                    style: { display: 'inline-flex' },

                                    handles: [
                                        {
                                            value: true,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                            apiPath: '/admin/printers/:id/unbind',
                                            config: {
                                                content: '<<dyy>>{{row.name}}<<yjb>>',
                                                text: '<<jbdyy>>',
                                            },
                                            confirm: {
                                                text: '<<sfjbdyy>>{{row.name}}?',
                                                className: 'confirm-content',
                                            },
                                        },
                                        {
                                            value: false,
                                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-DELIVERY`,
                                            apiPath: '/admin/printers/:id/bind',
                                            config: {
                                                content: '<<dyy>>{{row.name}}<<ybd>>',
                                                text: '<<bddyy>>',
                                            },
                                            confirm: {
                                                text: '<<sfbddyy>>{{row.name}}?',
                                            },
                                        },
                                    ],
                                },
                            },
                        ],
                    },
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/delivery-logistics-configs',
                    component: 'DeliveryLogisticsConfigsView',
                    breadcrumbName: '<<deliveryLogisticsConfigs>>',
                    privilege: {
                        path: 'subsiteLogisticsCompany',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'DeliveryLogisticsConfigsAddPage',
                            breadcrumbName: '<<addSubsite>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'DeliveryLogisticsConfigsEditPage',
                            breadcrumbName: '<<editSubsite>>',
                        },
                        {
                            path: '/:subsiteId/companies',
                            component: 'DeliveryLogisticsCompaniesPage',
                            breadcrumbName: '<<szpsgs>>',
                        },
                        {
                            path: '/:subsiteId/printers',
                            component: 'DeliveryPrinterPage',
                            breadcrumbName: '<<pzdyy>>',
                        },
                        { path: '/', component: 'DeliveryLogisticsConfigsPage' },
                    ],
                },
            ],
        },
    ],
};
