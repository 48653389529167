import React, { PureComponent } from 'react';
import { Table, Button, message, Popconfirm, InputNumber } from 'antd';
import { cloneDeep, remove, find, forEach } from 'lodash';
import { services } from '@comall-backend-builder/core';
import {
    ProductSelectedDisplayFilter,
    ProductSelectedDisplayFilterStates,
} from '../../product-selected-display-for-presale/product-selected-display-filter';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

interface ProductsSelectedDisplayProps {
    data: any[];
    onChange: (data: any[]) => void;
    modifiable: boolean;
    showFilter?: boolean;
}

interface ProductsSelectedDisplayState {
    /**
     * 共享库存
     */
    stock: undefined | number;
    filterParams: ProductSelectedDisplayFilterStates;
}

/**
 * 选择商品组件-选择完以商品的维度展示
 */
class ProductStyleSelectedDisplay extends PureComponent<
    ProductsSelectedDisplayProps,
    ProductsSelectedDisplayState
> {
    state: ProductsSelectedDisplayState = {
        stock: undefined,
        filterParams: {},
    };

    selectedRows: any[] = [];

    getTableConfig = () => {
        const { modifiable } = this.props;
        let config: any = {
            rowKey: 'id',
            columns: [
                {
                    title: services.language.getText('components.Products.name'),
                    dataIndex: 'productName',
                    key: 'productName',
                    width: 300,
                    render: (i: string) => (
                        <span title={i} className="product-field-name">
                            {i}
                        </span>
                    ),
                },
                {
                    title: services.language.getText('productCode'),
                    dataIndex: 'productCode',
                    key: 'productCode',
                    width: 150,
                },
                {
                    title: services.language.getText('merchantName'),
                    dataIndex: 'merchantName',
                    key: 'merchantName',
                    width: 100,
                },
                {
                    title: '预售价',
                    dataIndex: 'presalePrice',
                    key: 'presalePrice',
                    width: 150,
                    render: (i: number, row: any) => {
                        return (
                            <InputNumber
                                value={i}
                                min={0}
                                max={999999}
                                step={1}
                                precision={2}
                                onChange={(value: any) =>
                                    this.changeValue('presalePrice', value, row.id)
                                }
                            ></InputNumber>
                        );
                    },
                },
                {
                    title: services.language.getText('sykc'),
                    dataIndex: 'availableStock',
                    key: 'availableStock',
                    width: 150,
                    render: (i: number) => {
                        return (
                            <InputNumber
                                value={i}
                                step={1}
                                precision={0}
                                disabled={true}
                            ></InputNumber>
                        );
                    },
                },
                {
                    title: '共享库存',
                    dataIndex: 'stock',
                    key: 'stock',
                    align: 'center',
                    width: 150,
                    render: (i: number, row: any) => {
                        return (
                            <InputNumber
                                value={i}
                                min={0}
                                max={999999}
                                step={1}
                                precision={0}
                                onChange={(value: any) => this.changeValue('stock', value, row.id)}
                            ></InputNumber>
                        );
                    },
                },
                {
                    title: services.language.getText('limitUser'),
                    dataIndex: 'quantityLimit',
                    key: 'quantityLimit',
                    align: 'center',
                    width: 150,
                    render: (i: number, row: any) => {
                        return (
                            <span>
                                <InputNumber
                                    value={i}
                                    min={0}
                                    max={999999}
                                    step={1}
                                    precision={0}
                                    onChange={(value: any) =>
                                        this.changeValue('quantityLimit', value, row.id)
                                    }
                                ></InputNumber>{' '}
                                件
                            </span>
                        );
                    },
                },
            ],
            scroll: {
                x: 1150,
                y: 500,
            },
        };
        if (modifiable) {
            config.columns.push({
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                key: 'operation',
                fixed: 'right',
                width: 100,
                render: (i: any, row: any) => {
                    return (
                        <Popconfirm
                            onConfirm={this.onRemove(row.id)}
                            title={services.language.getText('qrscm')}
                            okText={services.language.getText('common.ok')}
                            cancelText={services.language.getText('common.cancel')}
                        >
                            <Button type={'link'}>删除</Button>
                        </Popconfirm>
                    );
                },
            });
            config.rowSelection = {
                onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                    this.selectedRows = selectedRows;
                },
            };
        }
        return config;
    };

    renderFooter = () => {
        const { stock } = this.state;
        return (
            <div className="product-style-selected-footer">
                <div className="batch-remove">
                    <Popconfirm
                        onConfirm={this.shareStockRemove()}
                        title={services.language.getText('confirmDeleteColumn')}
                        okText={services.language.getText('common.ok')}
                        cancelText={services.language.getText('common.cancel')}
                    >
                        <Button className="setting-item-ok" type="default">
                            {services.language.getText('batchDelete')}
                        </Button>
                    </Popconfirm>
                </div>
                <div className="setting-item">
                    <InputNumber
                        className="item-number"
                        style={{ width: 200 }}
                        placeholder={services.language.getText('plxggxkc')}
                        value={stock}
                        min={0}
                        max={999999}
                        step={1}
                        precision={0}
                        onChange={(e) => this.changeShareStockInputValue('stock', e)}
                    ></InputNumber>
                    <Popconfirm
                        onConfirm={this.shareStockChangeValue('stock')}
                        title={language.getText('plxggxkc')}
                        okText={services.language.getText('common.ok')}
                        cancelText={services.language.getText('common.cancel')}
                    >
                        <Button className="setting-item-ok" type="default">
                            {services.language.getText('confirmEdit')}
                        </Button>
                    </Popconfirm>
                </div>
            </div>
        );
    };

    shareStockChangeValue = (field: string) => {
        return () => {
            // @ts-ignore：
            const fieldValue = this.state[field];
            const selectedRow = this.selectedRows;
            if (!selectedRow || selectedRow.length === 0) {
                message.info(services.language.getText('selectSetDataColumn'));
                return;
            }
            const { data, onChange } = this.props;
            const result = cloneDeep(data);
            forEach(result, (i) => {
                const item = find(selectedRow, (j) => i.id === j.id);
                item && (i[field] = fieldValue);
            });
            onChange(result);
        };
    };

    changeShareStockInputValue = (field: 'stock', value: any) => {
        // @ts-ignore：
        this.setState({ [field]: value });
    };

    shareStockRemove = () => {
        return () => {
            // @ts-ignore：
            const selectedRow = this.selectedRows;
            if (!selectedRow || selectedRow.length === 0) {
                message.info(services.language.getText('selectBatchDeleteColumn'));
                return;
            }
            const { data, onChange } = this.props;
            const result = cloneDeep(data);
            remove(result, (i) => !!find(selectedRow, (j) => j.id === i.id));
            onChange(result);
        };
    };

    onRemove = (id: string) => {
        const { data, onChange } = this.props;
        return () => {
            const result = cloneDeep(data);
            remove(result, (i) => id === i.id);
            onChange(result);
        };
    };

    changeValue = (field: string, value: any, id: string) => {
        const { data, onChange } = this.props;
        const result = cloneDeep(data);
        let item = find(result, (i) => id === i.id);
        if (item) {
            item[field] = value;
            onChange(result);
        }
    };

    onFilter = (filterParams: ProductSelectedDisplayFilterStates) => {
        this.setState({ filterParams });
    };

    filterFn = () => {
        const { filterParams } = this.state;
        if (!filterParams) {
            return () => true;
        }
        return (item: any) => {
            let result = true;
            for (const key in filterParams) {
                const filterValue = filterParams[key as keyof typeof filterParams];
                const itemValue = item[key];
                if (!filterValue || itemValue?.includes(filterValue)) {
                    continue;
                }
                result = false;
                break;
            }
            return result;
        };
    };

    getDataSource = () => {
        const { data } = this.props;
        if (!data?.length) {
            return data;
        }
        return data.filter(this.filterFn());
    };

    render() {
        const { data, modifiable, showFilter } = this.props;
        let tableConfig = this.getTableConfig();
        const dataSource = this.getDataSource();
        const tableProps = { ...tableConfig, dataSource };
        const hiddenFilter = !showFilter || !data?.length;
        return (
            <div className={'product-style-selected-display' + (modifiable ? 'modify' : '')}>
                <ProductSelectedDisplayFilter hidden={hiddenFilter} onFilter={this.onFilter} />

                <Table
                    {...tableProps}
                    pagination={{
                        defaultPageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    }}
                ></Table>
                {modifiable && this.renderFooter()}
            </div>
        );
    }
}

export { ProductStyleSelectedDisplay };
