import React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { get } from 'lodash';
import { OrganizationSelector } from '../../../containers/organization/organization-selector';

export class OrganizationSelectorMode extends ObjectMode {
    public getAvailableDisplayComponent(value: any, displayInfo: any): JSX.Element {
        return <span>{get(value, 'organizationName')}</span>;
    }
    public getControlComponent(controlInfo: any) {
        return <OrganizationSelector {...controlInfo} />;
    }
}
