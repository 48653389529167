import * as React from 'react';
import classNames from 'classnames';

import { ObjectMode } from '@comall-backend-builder/types';
import { LimitSessionCount } from '../../../components';
import { language } from '@comall-backend-builder/core/lib/services';

export class ObjectLimitSessionCountMode extends ObjectMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(object: any, displayInfo: any) {
        const { className, style } = displayInfo;
        if (object) {
            return (
                <div
                    className={classNames('type-object mode-object-point-deduction', className)}
                    style={style}
                >
                    <div>
                        <span>
                            {language.getText('mrmtkcy')}
                            {object.dataCount} {language.getText('c')}
                        </span>
                        <span>
                            {language.getText('hdqjkcy')}
                            {object.activityCount} {language.getText('c')}
                        </span>
                    </div>
                </div>
            );
        } else {
            return this.getNotAvailableDisplayComponent(displayInfo);
        }
    }

    getNotAvailableDisplayComponent(displayInfo: any) {
        var className = displayInfo.className,
            style = displayInfo.style;
        return React.createElement(
            'span',
            { className: classNames('type-not-available', className), style: style },
            language.getText('notData')
        );
    }

    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <LimitSessionCount {...controlInfo} />;
    }
}
