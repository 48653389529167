import { services } from '@comall-backend-builder/core/';
const api = services.api;
export const CouponRulesLoader = {
    get: async function(data: any, config: any) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`;
        config.apiPath = `/admin/coupon_rules`;
        if (!data.id) {
            if (data.multiExecuteStatus && data.multiExecuteStatus.length > 0) {
                data.multiExecuteStatus = data.multiExecuteStatus.map((status: any) => {
                    return status.id;
                });
            }
        }
        return await api.get(data, config).then((res: any) => {
            res.result = res.result.map((item: any) => {
                return {
                    ...item,
                    amountValue: item.couponValue.type === 'AMOUNT' ? item.couponValue.value : '',
                };
            });
            return res;
        });
    },
};
