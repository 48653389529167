import React, { Component } from 'react';
import { InputNumber, Button, Popconfirm, Table, Input } from 'antd';
import { concat, isEmpty, cloneDeep } from 'lodash';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

export interface AffiliateMarkingInviteFriendsrProps {
    /**
     * 内容改变回调
     * @param value 新值
     */
    onChange: (value: any[]) => void;
    /**
     * 当前值
     */
    value: any[];
    /**
     * 是否禁止删除和新增规则
     */
    disabled: boolean;
    type: 'edit' | 'show';
}

/**
 * 邀请好友阶梯组件
 */
export class ServiceFacilities extends Component<AffiliateMarkingInviteFriendsrProps> {
    onAddRule() {
        const { value = [], onChange } = this.props;
        const newValue = cloneDeep(value);
        newValue.unshift({});
        if (onChange) {
            onChange(newValue);
        }
    }

    onRemoveRule(index: number) {
        let { value, onChange } = this.props;
        if (value !== null && !isEmpty(value)) {
            value = concat([], value);
            value.splice(index, 1);
        }
        if (onChange) {
            onChange(value);
        }
    }

    changeValue = (field: string, values: any, index: any) => {
        const { value, onChange } = this.props;
        let result = cloneDeep(value);
        let item = result[index];
        if (item) {
            item[field] = values;
            onChange(result);
        }
    };

    renderDisplay = () => {
        const { value } = this.props;
        const columns: any = [
            {
                title: language.getText('fwddmc'),
                dataIndex: 'name',
                key: 'name',
                render: (v: string, r: any, i: any) => {
                    return (
                        <Input
                            placeholder={language.getText('qsrfwddmc')}
                            maxLength={40}
                            value={v}
                            onChange={(e: any) => this.changeValue('name', e.target.value, i)}
                        />
                    );
                },
            },
            {
                title: language.getText('fwdz'),
                dataIndex: 'address',
                key: 'address',
                render: (v: string, r: any, i: any) => {
                    return (
                        <Input
                            placeholder={language.getText('qsrfwdz')}
                            maxLength={40}
                            value={v}
                            onChange={(e: any) => this.changeValue('address', e.target.value, i)}
                        />
                    );
                },
            },
            {
                title: language.getText('fwdh'),
                dataIndex: 'phone',
                key: 'phone',
                render: (v: string, r: any, i: any) => {
                    return (
                        <Input
                            placeholder={language.getText('qsrfwdh')}
                            maxLength={40}
                            value={v}
                            onChange={(e: any) => this.changeValue('phone', e.target.value, i)}
                        />
                    );
                },
            },
            {
                title: services.language.getText('sequence'),
                dataIndex: 'sequence',
                width: 100,
                key: 'sequence',
                render: (v: number, r: any, i: any) => {
                    return (
                        <InputNumber
                            value={v || 0}
                            min={0}
                            precision={0}
                            max={999999}
                            onChange={(e: any) => this.changeValue('sequence', e, i)}
                        />
                    );
                },
            },
        ];

        const actionColumn = {
            title: services.language.getText('common.tableAction'),
            className: 'action-column',
            width: 90,
            render: (v: string, r: any, i: any) => {
                return (
                    <Popconfirm
                        onConfirm={this.onRemoveRule.bind(this, i)}
                        title={language.getText('sfqrscgfwdd')}
                        okText={language.getText('common.ok')}
                        cancelText={language.getText('common.cancel')}
                    >
                        <span style={{ color: '#1890ff' }}>
                            {services.language.getText('common.delete')}
                        </span>
                    </Popconfirm>
                );
            },
        };
        columns.push(actionColumn);
        return (
            !!(value && value.length) && (
                <Table
                    className="organization-store-selector-table"
                    pagination={false}
                    bordered={false}
                    dataSource={value}
                    columns={columns}
                    scroll={{ y: 390 }}
                />
            )
        );
    };
    render() {
        return (
            <div className="affiliate-marketing-invite-friends">
                <div>
                    <Button type="link" onClick={this.onAddRule.bind(this)}>
                        {services.language.getText('tjfwdd')}
                    </Button>
                </div>
                {this.renderDisplay()}
            </div>
        );
    }
}
