import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { map, random, filter } from 'lodash';
import { SchedulerExecuteType, SchedulerMethodsValue } from '../../../components';
import { message as AntMessage } from 'antd';
import moment from 'moment';
import { getUrlParam } from '../../../applications/cae/services/utils';

const api = services.api;

const defaultName = () => {
    if (getUrlParam(window.location.hash, 'crowdId')) {
        return `人群发券${moment().format('YYYYMMDD')}${random(10000, 99999)}`;
    } else {
        return '';
    }
};

export const config: Config = {
    entities: {
        givingCouponSchedulesEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/admin/giving_coupon_schedules',
            filters: {
                name: {
                    type: 'string',
                    displayName: '<<jhmc>>',
                    controlConfig: { placeholder: '<<qsrhdmc>>' },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<zxzt>>',
                    options: [
                        {
                            id: 'ALL',
                            name: '<<all>>',
                        },
                        {
                            id: 'UN_START',
                            name: '<<notStarted>>',
                        },
                        {
                            id: 'RUNNING',
                            name: '<<inprogress>>',
                        },
                        {
                            id: 'END',
                            name: '<<end>>',
                        },
                    ],
                    controlConfig: {
                        style: { width: 170 },
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<zxsj>>',
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsites>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>' },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<jhmc>>',
                },
                executeTime: {
                    type: 'string.date',
                    displayName: '<<zxsj>>',
                },
                contraposeCrowd: {
                    type: 'string',
                    displayName: '<<zdrq>>',
                },
                memberGroupName: {
                    type: 'string',
                    displayName: '<<jhrq>>',
                },
                couponName: {
                    type: 'groupCouponMode',
                    displayName: '<<couponName>>',
                },
                couponInfos: {
                    type: 'array.options.select',
                    className: 'custom-array-options',
                    displayName: '<<xzyhq>>',
                    options: [],
                },
                subsiteName: {
                    type: 'array.popover',
                    displayName: '<<subsites>>',
                },
                subsites: {
                    type: 'array.options.select',
                    className: 'custom-array-options',
                    displayName: '<<otherSelectSubsite>>',
                    options: [],
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<zxzt>>',
                },
                resourceUrl: {
                    type: 'string',
                    displayName: '<<xzbblj>>',
                },
                executeSuccessQuantity: {
                    type: 'string',
                    displayName: '<<zxcgs>>',
                },
                status: {
                    type: 'string',
                    displayName: '<<kqgbanzt>>',
                },
                canBegin: {
                    type: 'string',
                    displayName: '<<kqanzt>>',
                },
                canEdit: {
                    type: 'string',
                    displayName: '<<bjanzt>>',
                },
                canImport: {
                    type: 'string',
                    displayName: '<<dranzt>>',
                },
                canExport: {
                    type: 'string',
                    displayName: '<<dcanzt>>',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],

                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.baseInfo?.subsiteIds?.length &&
                                        row.baseInfo.subsiteIds
                                            .map((item: any) => item.id)
                                            .join(',');
                                    return {
                                        subsiteIds,
                                    };
                                },
                            },
                        },
                        name: {
                            type: 'string',
                            displayName: '<<jhmc>>',
                            rules: [
                                {
                                    required: true,
                                    max: 20,
                                    whitespace: true,
                                    message: '<<jhmcbnzf>>',
                                    validator: (rule: any, value: any) => {
                                        if (value && value.length > 20) {
                                            return false;
                                        }
                                        const apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
                                        const apiPath =
                                            '/admin/giving_coupon_schedules/name/verify_unique';
                                        const params = {
                                            name: value,
                                        };
                                        return api.get(params, { apiRoot, apiPath }).then((res) => {
                                            if (res) {
                                                return;
                                            }

                                            throw new Error('');
                                        });
                                    },
                                },
                            ],
                        },
                        subsiteIds: {
                            type: 'array.subsites.selector',
                            displayName: '<<applicableSubsite>>',
                            rules: [{ required: true }],
                        },
                        couponCrowds: {
                            type: 'object.GivingCouponCrowds',
                            displayName: '<<zdrq>>',
                            rules: [{ required: true }],
                            defaultValue: {
                                mode: 'Member',
                                crowds: [],
                            },
                            controlConfig: {
                                getSubsiteIds(props: any) {
                                    const { row } = props;
                                    const subsiteIds =
                                        row.baseInfo && row.baseInfo.subsiteIds
                                            ? map(row.baseInfo.subsiteIds, 'id')
                                            : null;
                                    return subsiteIds;
                                },
                            },
                        },
                        // subsiteIds: {
                        //     type: 'array.options.autoComplete',
                        //     className: 'custom-array-options',
                        //     displayName: '<<applicableSubsite>>',
                        //     options: [],
                        //     source: {
                        //         apiPath: '/admin/defaults/subsite',
                        //     },
                        //     rules: [
                        //         {
                        //             required: true,
                        //         },
                        //     ],
                        //     controlConfig: {
                        //         placeholder: '<<selectPlease>>',
                        //     },
                        // },
                        schedulerMethods: {
                            type: 'object.schedulerMethods',
                            displayName: '<<zxfs>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },
                    },
                },
                couponInfo: {
                    type: 'object.subForm',
                    displayName: '<<yhqsz>>',
                    properties: {
                        selectCoupon: {
                            type: 'array.couponSelectorWithPackage',
                            controlConfig: {
                                disablePrice: true,
                                type: 'checkbox',
                                packageSelectType: 'radio',
                                canChangeQuantity: true,
                                canChangeQuantityTitle: '<<mrfqsl>>',
                                getSubsiteIds(props: any) {
                                    const { row } = props;
                                    const subsiteIds =
                                        row.baseInfo && row.baseInfo.subsiteIds
                                            ? map(row.baseInfo.subsiteIds, 'id')
                                            : null;
                                    return subsiteIds;
                                },
                                clearValueOnSubsiteChange: true,
                                checkSubsiteOnSelect: true,
                            },
                            displayName: '<<xzyhq>>',
                            rules: [{ required: true, type: 'array' }],
                        },
                    },
                },
                notificatonConfig: {
                    type: 'object.subForm',
                    displayName: '<<tzpz>>',
                    properties: {
                        notificationChannel: {
                            type: 'object.notificaton.content.checkbox.tip',
                            displayName: '<<tzfs>>',
                            controlConfig: {
                                showAgreementIn: 'false',
                            },
                        },
                    },
                },
            },
        },
    },
    components: {
        givingCouponSchedulesView: {
            component: 'Viewport',
            entity: 'givingCouponSchedulesEntity',
        },
        givingCouponSchedulesPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'givingCouponSchedulesFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzjh>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/coupon/giving-coupon/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'givingCouponSchedulesTable' },
            ],
        },
        givingCouponSchedulesFilter: {
            component: 'FilterForm',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
                style: {
                    marginRight: 10,
                },
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'name',
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 100 },
                    },
                },
                {
                    property: 'subsiteId',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: { width: 260 },
                    },
                },
            ],
        },
        givingCouponSchedulesTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'executeTime',
                },
                {
                    property: 'contraposeCrowd',
                },
                {
                    property: 'memberGroupName',
                },
                {
                    property: 'couponName',
                },
                {
                    property: 'subsiteName',
                    displayConfig: {
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '80px',
                        },
                        statusConfig: {
                            UN_START: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            RUNNING: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            END: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
                {
                    property: 'executeSuccessQuantity',
                },
            ],
            scroll: { x: true },
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 200,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        statusKey: 'row.canBegin',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: 'RUNNING' },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                    apiPath: '/admin/giving_coupon_schedules/:id/status',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: 'END' },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                    apiPath: '/admin/giving_coupon_schedules/:id/status',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '进行中的计划将立即结束，不可再开启，请谨慎操作。',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.view>>',
                        path: '/menu-list/coupon/giving-coupon/schedule-info/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/menu-list/coupon/giving-coupon/edit/{{row.id}}',
                    },
                    {
                        type: 'component',
                        text: '<<drsj>>',
                        statusKey: 'row.canImport',
                        component: 'givingCouponSchedulesImportButton',
                    },
                    {
                        type: 'component',
                        text: '<<xzbb>>',
                        statusKey: 'row.canExport',
                        component: 'givingCouponSchedulesExportButton',
                    },
                ],
            },
        },
        givingCouponSchedulesAddPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'givingCouponSchedulesAddForm' }],
        },
        givingCouponSchedulesAddForm: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            entity: 'givingCouponSchedulesEntity',
            labelCol: 6,
            controlCol: 18,
            style: { width: 820 },
            fields: [
                {
                    property: 'baseInfo.name',
                    controlConfig: {
                        style: { width: 300 },
                        placeholder: '<<planNameRequired>>',
                    },
                },
                {
                    property: 'baseInfo.subsiteIds',
                    controlConfig: {
                        style: { width: 450 },
                    },
                },
                { property: 'baseInfo.marketingSchedule' },
                {
                    property: 'baseInfo.couponCrowds',
                    controlConfig: {
                        style: { width: 450 },
                    },
                },
                {
                    property: 'baseInfo.schedulerMethods',
                },

                {
                    property: 'couponInfo.selectCoupon',
                    className: 'property-left',
                    extra: '<<qtjsymdtydyhq>>',
                },
                {
                    property: 'notificatonConfig.notificationChannel',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            loadDefaultValue: async () => {
                const subsiteId = getUrlParam(window.location.hash, 'subsiteId') || '';
                const crowdId = getUrlParam(window.location.hash, 'crowdId');
                if (crowdId && subsiteId) {
                    const name = defaultName();
                    const response: any = await api.get(
                        {},
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                            apiPath: '/admin/subsites/mine',
                        }
                    );

                    const res: any = await api.get(
                        {
                            page: 1,
                            perPage: 400,
                            subsiteId: subsiteId,
                        },
                        {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                            apiPath: '/admin/crowds',
                        }
                    );
                    const subsiteIds = filter(response.result, { id: subsiteId });
                    const crowds = filter(res.result, { id: crowdId }) || [];
                    return {
                        'baseInfo.name': name,
                        'baseInfo.subsiteIds': subsiteIds,
                        'baseInfo.couponCrowds': {
                            mode: 'Crowd',
                            crowds,
                        },
                    };
                }
                return {};
            },
            onValidate: (entity: any) => {
                const schedulerMethods: SchedulerMethodsValue = entity.baseInfo.schedulerMethods;
                const notificationChannel: any = entity.notificatonConfig.notificationChannel;
                if (
                    schedulerMethods &&
                    schedulerMethods.executeType === SchedulerExecuteType.AUTO &&
                    (!schedulerMethods.schedulerStartTime ||
                        !schedulerMethods.schedulerEndTime ||
                        !schedulerMethods.schedulerTime)
                ) {
                    AntMessage.error(services.language.getText('zxrqhzxsjbnwk'));
                    return false;
                }
                if (
                    schedulerMethods &&
                    schedulerMethods.executeType === SchedulerExecuteType.TIMING &&
                    !schedulerMethods.schedulerExecuteTime
                ) {
                    AntMessage.error(services.language.getText('zxrqbnwk'));
                    return false;
                }

                if (notificationChannel && notificationChannel.channel) {
                    if (!notificationChannel.content) {
                        AntMessage.error(services.language.getText('qsrdxnr'));
                        return false;
                    }
                    // if (!notificationChannel.agreement) {
                    //     AntMessage.error('请查看并同意短信服务协议');
                    //     return false;
                    // }
                }
                if (
                    entity.baseInfo &&
                    entity.baseInfo.couponCrowds &&
                    entity.baseInfo.couponCrowds.mode === 'Crowd'
                ) {
                    if (
                        !entity.baseInfo.couponCrowds.crowds ||
                        entity.baseInfo.couponCrowds.crowds.length === 0
                    ) {
                        AntMessage.error(services.language.getText('qxzzdrq'));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        givingCouponSchedulesEditPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            items: [{ component: 'givingCouponSchedulesEditForm' }],
        },
        givingCouponSchedulesEditForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'givingCouponSchedulesEntity',
            loaderType: 'get',
            labelCol: 6,
            controlCol: 18,
            style: { width: 820 },
            fields: [
                {
                    property: 'baseInfo.name',
                    modifiable: false,
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'baseInfo.subsiteIds',
                    controlConfig: {
                        style: { width: 450 },
                    },
                },
                { property: 'baseInfo.marketingSchedule' },
                {
                    property: 'baseInfo.couponCrowds',
                    controlConfig: {
                        style: { width: 450 },
                        hasDefaultValue: false,
                    },
                },
                {
                    property: 'baseInfo.schedulerMethods',
                    controlConfig: {
                        executeTypeDisabled: true,
                    },
                },

                {
                    property: 'couponInfo.selectCoupon',
                    className: 'property-left',
                    extra: '<<qtjsymdtydyhq>>',
                },
                {
                    property: 'notificatonConfig.notificationChannel',
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const schedulerMethods: SchedulerMethodsValue = entity.baseInfo.schedulerMethods;
                const notificationChannel: any = entity.notificatonConfig.notificationChannel;
                if (
                    schedulerMethods &&
                    schedulerMethods.executeType === SchedulerExecuteType.AUTO &&
                    (!schedulerMethods.schedulerStartTime ||
                        !schedulerMethods.schedulerEndTime ||
                        !schedulerMethods.schedulerTime)
                ) {
                    AntMessage.error(services.language.getText('zxrqhzxsjbnwk'));
                    return false;
                }
                if (
                    schedulerMethods &&
                    schedulerMethods.executeType === SchedulerExecuteType.TIMING &&
                    !schedulerMethods.schedulerExecuteTime
                ) {
                    AntMessage.error(services.language.getText('zxrqbnwk'));
                    return false;
                }

                if (notificationChannel && notificationChannel.channel) {
                    if (!notificationChannel.content) {
                        AntMessage.error(services.language.getText('qsrdxnr'));
                        return false;
                    }
                    // if (!notificationChannel.agreement) {
                    //     AntMessage.error('请查看并同意短信服务协议');
                    //     return false;
                    // }
                }
                if (
                    entity.baseInfo &&
                    entity.baseInfo.couponCrowds &&
                    entity.baseInfo.couponCrowds.mode === 'Crowd'
                ) {
                    if (
                        !entity.baseInfo.couponCrowds.crowds ||
                        entity.baseInfo.couponCrowds.crowds.length === 0
                    ) {
                        AntMessage.error(services.language.getText(''));
                        return false;
                    }
                }
                return true;
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        },
        givingCouponScheduleInfoPage: {
            component: 'FlexLayout',
            direction: 'horizontal',
            entity: 'givingCouponSchedulesEntity',
            loaderType: 'get',
            items: [
                {
                    component: 'Detail',
                    labelCol: 6,
                    controlCol: 18,
                    style: { width: 920 },
                    fields: [
                        {
                            property: 'baseInfo.name',
                        },
                        {
                            property: 'baseInfo.subsiteIds',
                        },
                        { property: 'baseInfo.marketingSchedule' },
                        {
                            property: 'baseInfo.couponCrowds',
                            controlConfig: {
                                style: { width: 450 },
                            },
                        },

                        { property: 'executeTime' },
                        { property: 'couponInfo.selectCoupon' },
                        {
                            property: 'notificatonConfig.notificationChannel',
                        },
                    ],
                    footer: {
                        items: [
                            {
                                htmlType: 'button',
                                route: 'goBack',
                                type: 'primary',
                                text: '<<common.cancel>>',
                            },
                        ],
                    },
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/coupon/giving-coupon',
                    component: 'givingCouponSchedulesView',
                    breadcrumbName: '<<dxfq>>',
                    privilege: {
                        path: 'givingCouponSchedule',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'givingCouponSchedulesAddPage',
                            breadcrumbName: '<<xzjh>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'givingCouponSchedulesEditPage',
                            breadcrumbName: '<<editPlan>>',
                        },
                        {
                            path: '/schedule-info/:id',
                            component: 'givingCouponScheduleInfoPage',
                            breadcrumbName: '<<ckjh>>',
                        },
                        { path: '/', component: 'givingCouponSchedulesPage' },
                    ],
                },
            ],
        },
    ],
};
