import { Modal as AntModal, Input, message as AntMessage } from 'antd';
import React, { ChangeEventHandler, useCallback, useEffect, useState } from 'react';
import { WechatUrlOptions } from './wechat-url-message';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

interface Props {
    onConfirm(value: WechatUrlOptions): void;
    value?: Partial<WechatUrlOptions>;
    visible: boolean;
    onCancel(): void;
}
export default function WechatUrlModalForm(props: Props) {
    const { onConfirm, value = { url: '', text: '', appId: '' }, visible, onCancel } = props;
    const [url, setUrl] = useState(value.url || '');
    const [text, setText] = useState(value.text || '');
    const onUrlChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (e) => {
            setUrl(e.target.value);
        },
        [setUrl]
    );
    const onTextChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
        (e) => {
            setText(e.target.value);
        },
        [setText]
    );

    const reset = useCallback(() => {
        setUrl(value.url || '');
        setText(value.text || '');
    }, [value]);
    const onOK = useCallback(() => {
        if (!text) {
            AntMessage.warning('请填写链接文字');
            return false;
        }
        if (!url) {
            AntMessage.warning('请填写链接地址');
            return false;
        }
        onConfirm({
            url,
            text,
        });
    }, [onConfirm, url, text]);
    useEffect(reset, [visible]);
    return (
        <AntModal
            visible={visible}
            okText={language.getText('common.ok')}
            title={services.language.getText('network')}
            onOk={onOK}
            onCancel={onCancel}
        >
            <div className="wechat-url-modal-form">
                <div className="item">
                    链接文字：
                    <Input
                        className="item-input"
                        required
                        placeholder={services.language.getText('gzfn')}
                        value={text}
                        onChange={onTextChange}
                        maxLength={64}
                    />
                </div>
                <div className="item">
                    链接地址：
                    <Input
                        className="item-input"
                        placeholder={services.language.getText('gzfn_2')}
                        value={url}
                        onChange={onUrlChange}
                        required
                        maxLength={100}
                    />
                </div>
            </div>
        </AntModal>
    );
}
