import React, { PureComponent, createElement, Fragment } from 'react';

import { ComponentsManager, services, Loader } from '@comall-backend-builder/core';
import { message as AntMessage, Modal, Select } from 'antd';

const { Option } = Select;

export class SetMemberTagModal extends PureComponent<any> {
    state = {
        visible: false,
        seletcted: [],
        inputValue: '',
        list: [],
    };
    openModal = () => {
        this.setState({ visible: true });
        const { row } = this.props;
        if (row.tags && row.tags.length) {
            const socialIdentityList = row.tags.filter(
                (i: any) => i.type.code === 'SOCIAL_IDENTITY'
            ); // 暂时只支持社交身份类型的标签
            this.setState({
                seletcted: socialIdentityList.map((i: any) => i.name) || [],
            });
        }

        Loader.load('get', {
            params: { typeCode: 'SOCIAL_IDENTITY' },
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`,
            apiPath: `/admin/tags/by_type`,
        })
            .then((res: any) => {
                this.setState({
                    list: res || [],
                });
            })
            .catch(services.errorHandle);
    };

    handleSubmit = () => {
        this.setState({ visible: false });
        const { seletcted } = this.state;
        const { entity, params: entityParams, row } = this.props;
        Loader.load('put', {
            params: {
                memberId: row.id,
                tags: seletcted.map((i) => ({ name: i, typeCode: 'SOCIAL_IDENTITY' })),
            },
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER`,
            apiPath: `/admin/tags/members`,
        })
            .then(() => {
                entity && entity.search(entityParams);
                this.setState({ visible: false });
                AntMessage.success(services.language.getText('actionSuccess'), 1.5);
            })
            .catch(services.errorHandle);
    };
    handleChange = (val: any) => {
        val = val.map((i: any) => i.substring(0, 20));
        if (val.length > 1) {
            return this.setState({ seletcted: [val[1]] });
        }
        this.setState({ seletcted: val });
        this.setState({
            inputValue: '',
        });
    };

    onSearch = (v: any) => {
        this.setState({
            inputValue: v,
        });
    };
    onBlur = () => {
        this.setState({
            inputValue: '',
        });
    };

    render() {
        const { triggerComponent, row } = this.props;
        const buttonProps = {
            ...triggerComponent,
            onClick: this.openModal,
        };
        const { list } = this.state;

        return (
            <Fragment>
                {createElement(ComponentsManager.get('Button'), buttonProps)}
                <Modal
                    visible={this.state.visible}
                    title={services.language.getText('xzbq')}
                    mask={false}
                    maskClosable
                    okText={services.language.getText('common.ok')}
                    cancelText={services.language.getText('common.cancel')}
                    width="580px"
                    onOk={this.handleSubmit}
                    onCancel={() => {
                        this.setState({ visible: false });
                    }}
                >
                    <div>
                        <span>{services.language.getText('sjsf')}：</span>
                        <Select
                            onSearch={this.onSearch}
                            onBlur={this.onBlur}
                            id={`memberTagForm-${row.id}`}
                            mode="tags"
                            style={{ width: '65%' }}
                            value={this.state.seletcted}
                            onChange={this.handleChange}
                        >
                            {list.map((i: any) => (
                                <Option key={i.name}>{i.name}</Option>
                            ))}
                        </Select>
                        {this.state.inputValue.length > 20 && (
                            <span style={{ color: 'red', marginLeft: 5 }}>
                                {services.language.getText('notAbove20Char')}
                            </span>
                        )}
                    </div>
                </Modal>
            </Fragment>
        );
    }
}
