import React, { PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import { Table, Button, Modal, message as AntMessage, Radio, Pagination, Select } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { find } from 'lodash';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;
const navigation = services.navigation;

export class SubsiteMemberCardLevelPage extends PureComponent<any, any> {
    state = {
        datasource: [],
        total: 0,
        current: 0,
        pagination: {
            page: 1,
            perPage: 10,
        },
        subsiteId: '',
        visible: false,
        selectedId: '',
        modalDatasource: [],
        subsites: [],
        loading: false,
    };

    componentDidMount() {
        this.lodaModalData();
        this.lodaSubsites();
    }

    lodaSubsites = async () => {
        const subsites = await api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}`,
                apiPath: '/WEB-API/admin/defaults/subsite',
            }
        );
        this.setState({
            subsites,
        });
    };

    lodaModalData = async () => {
        const res: any = await api.get(
            {
                page: 1,
                perPage: 400,
                organizationType: 'TENANT',
                scenario: 'TRADE',
                planType: 'TEMPLATE',
                status: 'ENABLE',
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                apiPath: '/admin/level_plans',
            }
        );
        this.setState({
            modalDatasource: this.transformData(res.result),
        });
    };

    loadData = async () => {
        const { pagination, subsiteId } = this.state;
        const res: any = await api.get(
            {
                ...pagination,
                organizationType: 'SUBSITE',
                organizationId: subsiteId,
                scenario: 'TRADE',
                planType: 'PLAN',
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                apiPath: '/admin/level_plans',
            }
        );
        this.setState({
            datasource: this.transformData(res.result),
            total: res.totalNum,
            current: res.page,
            loading: false,
        });
    };

    transformData = (data: any) => {
        const datasource: any[] = [];
        if (data && data.length) {
            data.forEach((item: any) => {
                item.levelItems.forEach((leveItem: any, index: number) => {
                    datasource.push({
                        id: item.id,
                        key: leveItem.id,
                        levelCode: leveItem.levelCode,
                        name: item.name,
                        levelId: leveItem.levelId,
                        levelName: leveItem.levelName,
                        styleIconUrl: leveItem.styleIconUrl,
                        styleBackgroundPictureUrl: leveItem.styleBackgroundPictureUrl,
                        status: item.status,
                        rowSpan: index === 0 ? item.levelItems.length : 0,
                        original: item,
                    });
                });
            });
        }
        return datasource;
    };

    private navigationToEdit = (id: string) => {
        navigation.goto(`/subsite-member-card-level-manage/edit/${id}`);
    };

    renderTable() {
        const { datasource } = this.state;
        const columns: ColumnProps<any>[] = [
            {
                title: language.getText('kdjmbmc_1'),
                dataIndex: 'name',
                fixed: 'left',
                className: `${prefix}__border`,
                render: (value: string, row: any, index: number) => {
                    const obj = {
                        children: value,
                        props: {
                            rowSpan: row.rowSpan,
                        },
                    };
                    return obj;
                },
            },
            {
                title: language.getText('djbm'),
                fixed: 'left',
                dataIndex: 'levelCode',
            },
            {
                title: language.getText('djmc'),
                fixed: 'left',
                dataIndex: 'levelName',
            },
            {
                title: language.getText('djtb'),
                dataIndex: 'styleIconUrl',
                render: (iconUrl: string) => (
                    <div className={`${prefix}__icon`}>
                        <img className={`${prefix}__icon`} src={iconUrl} alt=""></img>
                    </div>
                ),
            },
            {
                title: language.getText('djkm'),
                dataIndex: 'styleBackgroundPictureUrl',
                className: `${prefix}__border`,
                render: (iconUrl: string) => (
                    <div className={`${prefix}__back`}>
                        <img className={`${prefix}__back`} src={iconUrl} alt=""></img>
                    </div>
                ),
            },
            {
                title: language.getText('status'),
                dataIndex: 'status',
                className: `${prefix}__border`,
                render: (value: string, row: any, index: number) => {
                    const obj = {
                        children:
                            value === 'ENABLE'
                                ? language.getText('beforeOpen')
                                : language.getText('beforeClose'),
                        props: {
                            rowSpan: row.rowSpan,
                        },
                    };
                    return obj;
                },
            },
            {
                title: services.language.getText('common.tableAction'),
                dataIndex: 'id',
                className: `${prefix}__border`,
                render: (id: string, row: any, index: number) => {
                    const children = (
                        <div>
                            <Button
                                type="link"
                                onClick={this.onChangeStatus.bind(this, id, row.name, row.status)}
                            >
                                {row.status === 'ENABLE'
                                    ? language.getText('common.close')
                                    : language.getText('common.opend')}
                            </Button>
                            <Button type="link" onClick={this.navigationToEdit.bind(this, id)}>
                                {language.getText('common.edit')}
                            </Button>
                        </div>
                    );
                    const obj = {
                        children,
                        props: {
                            rowSpan: row.rowSpan,
                        },
                    };
                    return obj;
                },
            },
        ];
        return (
            <Table
                className="no-hover"
                columns={columns}
                dataSource={datasource}
                pagination={false}
            />
        );
    }

    private onChangeStatus = async (id: string, name: string, status: string) => {
        const closeText = services.language.getText('ymzcbbyks');
        const openText = `${language.getText('isAreadyOpend')}${name}`;
        Modal.confirm({
            content: status === 'ENABLE' ? closeText : openText,
            onOk: () => {
                api.put(
                    {
                        status: status === 'ENABLE' ? 'DISABLE' : 'ENABLE',
                    },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                        apiPath: `/admin/level_plans/${id}/status`,
                    }
                )
                    .then(() => {
                        this.loadData();
                    })
                    .catch((error) => {
                        services.errorHandle(error);
                    });
            },
            okText: services.language.getText('common.confirm'),
            cancelText: services.language.getText('common.cancel'),
            icon: '',
            width: 602,
        });
    };

    private pageChange = (pageVal: object = {}) => {
        this.setState({ pagination: { ...pageVal } }, () => {
            this.loadData();
        });
    };

    private onChangeSubsite = (id: any) => {
        this.setState(
            {
                subsiteId: id,
                loading: true,
            },
            () => {
                this.loadData();
            }
        );
    };

    private renderSelectorList = () => {
        const { subsites } = this.state;
        return (
            <div className={`${prefix}__filter`}>
                <div>{language.getText('subsites')}:</div>
                <Select
                    className="find-filter-width"
                    showSearch
                    placeholder={services.language.getText('selectPlease')}
                    optionFilterProp="name"
                    filterOption={(input, option: any) =>
                        (option.props.children || '').toLowerCase().indexOf(input.toLowerCase()) >=
                        0
                    }
                    onChange={this.onChangeSubsite}
                >
                    {subsites &&
                        subsites.map((subsite: any) => {
                            return (
                                <Select.Option key={subsite.id} value={subsite.id}>
                                    {subsite.name}
                                </Select.Option>
                            );
                        })}
                </Select>
                {this.renderCorrelationButton()}
            </div>
        );
    };

    onClearAll = () => {
        this.setState({
            selectedId: '',
        });
    };

    toggleModal = () => {
        const { visible, subsiteId } = this.state;
        if (!subsiteId) {
            AntMessage.error(language.getText('selectSubsite'));
            return;
        } else {
            this.setState({ visible: !visible }, () => {
                if (!visible) {
                    this.onClearAll();
                }
            });
        }
    };

    onOk = async () => {
        const { modalDatasource, selectedId, subsiteId } = this.state;
        const curData: any = find(modalDatasource, { id: selectedId });
        if (curData) {
            try {
                await api.post(
                    {
                        ...curData.original,
                        organizationType: 'SUBSITE',
                        organizationId: subsiteId,
                        planType: 'PLAN',
                        scenario: 'TRADE',
                        status: 'DISABLE',
                    },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                        apiPath: '/admin/level_plans',
                    }
                );
                this.loadData();
                this.toggleModal();
            } catch (error) {
                this.toggleModal();
                services.errorHandle(error as any);
            }
        }
    };

    onChangeSelectedId = (id: string) => {
        this.setState({
            selectedId: id,
        });
    };

    renderModalContent = () => {
        const { modalDatasource, selectedId } = this.state;
        const columns: ColumnProps<any>[] = [
            {
                title: language.getText('kdjmbmc_1'),
                dataIndex: 'name',
                className: `${prefix}__border`,
                render: (value: string, row: any, index: number) => {
                    const children = (
                        <div>
                            <Radio
                                checked={selectedId === row.id}
                                onClick={this.onChangeSelectedId.bind(this, row.id)}
                            ></Radio>
                            <span>{value}</span>
                        </div>
                    );
                    const obj = {
                        children,
                        props: {
                            rowSpan: row.rowSpan,
                        },
                    };
                    return obj;
                },
            },
            {
                title: language.getText('levelId'),
                dataIndex: 'levelId',
            },
            {
                title: language.getText('djmc'),
                dataIndex: 'levelName',
            },
            {
                title: language.getText('djtb'),
                dataIndex: 'styleIconUrl',
                render: (iconUrl: string) => (
                    <div className={`${prefix}__icon`}>
                        <img className={`${prefix}__icon`} src={iconUrl} alt=""></img>
                    </div>
                ),
            },
            {
                title: language.getText('djkm'),
                dataIndex: 'styleBackgroundPictureUrl',
                className: `${prefix}__border`,
                render: (iconUrl: string) => (
                    <div className={`${prefix}__back`}>
                        <img className={`${prefix}__back`} src={iconUrl} alt=""></img>
                    </div>
                ),
            },
        ];

        return (
            <Table
                scroll={{ y: '600px' }}
                rowKey={'key'}
                className="no-hover"
                columns={columns}
                dataSource={modalDatasource}
                pagination={false}
            />
        );
    };

    renderCorrelationButton = () => {
        const modalContent = this.renderModalContent();
        const { loading, subsiteId } = this.state;
        const modalProps = {
            width: 900,
            title: language.getText('glhykdj'),
            visible: this.state.visible,
            okText: services.language.getText('common.ok'),
            cancelText: services.language.getText('common.cancel'),
            onOk: this.onOk,
            onCancel: this.toggleModal,
        };
        const disabled = !subsiteId || loading;
        return (
            <div style={{ display: 'inline-block' }}>
                <Button disabled={disabled} type="primary" onClick={this.toggleModal}>
                    {language.getText('gldj')}
                </Button>
                <Modal {...modalProps}>{modalContent}</Modal>
            </div>
        );
    };

    render() {
        const { pagination, total, current } = this.state;
        const paginationCongid = {
            pageSize: pagination.perPage,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ['10', '15', '20'],
            total,
            current,
            showTotal(total: number) {
                return services.interpolate(services.language.getText('total'), {
                    total,
                });
            },
            onChange: (page: number | undefined, perPage: number | undefined) => {
                this.pageChange({ page, perPage });
            },
            onShowSizeChange: (page: number | undefined, perPage: number | undefined) => {
                this.pageChange({ page, perPage });
            },
        };
        return (
            <div className={prefix}>
                {this.renderSelectorList()}
                {/* <div className={`${prefix}__remark`}>门店只能关联一次卡等级模版，请谨慎操作</div> */}
                {this.renderTable()}
                <div className={`${prefix}__pagination`}>
                    <Pagination {...paginationCongid} />
                </div>
            </div>
        );
    }
}

const prefix = 'subsite-member-card-level-page';
