import React, { PureComponent } from 'react';
import { MsgChannelType } from '.';
import { services } from '@comall-backend-builder/core';

interface MessageStyle {
    innerClass: any;
    top: any;
    bottom: any;
    goImage?: any;
    miniImage?: any;
}
interface MessagesSubscriptioWechatAndAlipayProps {
    /**
     * 样式
     */
    messageStyle: MessageStyle;
    /**
     * 模板数据列表
     */
    messageList: any;
    /**
     * 模板类型
     */
    msgChannelType: MsgChannelType;
    /** 场景*/
    name: string;
    landingPage?: string;
}

/**
 * 微信小程序 微信公众号 支付宝小程序模板
 */
export class MessagesSubscriptioWechatAndAlipay extends PureComponent<
    MessagesSubscriptioWechatAndAlipayProps
> {
    render() {
        const {
            messageStyle: { innerClass, top, bottom, miniImage, goImage },
            messageList,
            msgChannelType,
            name,
            landingPage,
        } = this.props;
        return (
            <div className={innerClass.content}>
                <div className={innerClass.otherItems}>
                    <div className={innerClass.contentTitle}>{name}</div>
                    <div
                        style={{
                            whiteSpace: 'pre-line',
                        }}
                    >
                        {top && top.value}
                    </div>
                    {messageList.map((o: any, index: number) => {
                        return (
                            <div key={`${index}-${o.name}`} className={innerClass.otherItem}>
                                <span className={innerClass.otherItemTitle}>{o.name}</span>
                                <span
                                    className={innerClass.otherItemValue}
                                    style={{
                                        color: o && o.color ? o.color : '#000',
                                    }}
                                >
                                    {o.value}
                                </span>
                            </div>
                        );
                    })}
                    <div
                        style={{
                            color: bottom && bottom.color ? bottom.color : '#D0021B',
                            whiteSpace: 'pre-line',
                        }}
                    >
                        {bottom && bottom.value}
                    </div>
                </div>
                {(msgChannelType === MsgChannelType.WEAPP ||
                    msgChannelType === MsgChannelType.ALIPAY_SUBSCRIBE_MSG) && (
                    <div
                        className={innerClass.wechat}
                        style={landingPage ? { justifyContent: 'flex-start' } : undefined}
                    >
                        <div>
                            {landingPage
                                ? services.language.getText('tzldy')
                                : services.language.getText('jrxcxck')}
                        </div>
                        {landingPage ? (
                            <span style={{ marginLeft: '4px' }}>{landingPage}</span>
                        ) : (
                            <div>
                                <img
                                    src={goImage}
                                    alt=""
                                    style={{ marginLeft: '4px', width: '16px' }}
                                />
                            </div>
                        )}
                    </div>
                )}
                {msgChannelType === MsgChannelType.WECHAT_OFFICIAL_ACCOUNT && (
                    <div className={innerClass.wechat}>
                        <div>{services.language.getText('xcxmc')}</div>
                        <div>
                            <img
                                src={miniImage}
                                alt=""
                                style={{ marginRight: '4px', width: '16px' }}
                            />
                            <span>{services.language.getText('xcx')}</span>
                            <img
                                src={goImage}
                                alt=""
                                style={{ marginLeft: '4px', width: '16px' }}
                            />
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
