import { Config } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { services } from '@comall-backend-builder/core';

export const config: Config = {
    entities: {
        BackInfoEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/back_info',
            properties: {
                loginLogo: {
                    displayName: '<<htdlym>>LOGO',
                    type: 'imageObjectArray',
                    className: 'sigle-image-field',
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        className: 'sigle-image-field',
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                    rules: [{ required: true }],
                    extra: '<<h_htdlym_tip>>',
                },
                frameLogo: {
                    displayName: '<<zlyzsj>>LOGO',
                    type: 'imageObjectArray',
                    className: 'sigle-image-field',
                    controlConfig: {
                        maxCount: 1,
                        multiple: false,
                        className: 'sigle-image-field',
                        fileName: 'fileName',
                        uploadType: 'multi',
                        uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                        mode: 'picture-card',
                    },
                    rules: [{ required: true }],
                    extra: '<<h_zlyzsj_tip>>',
                },
                loginCopyRight: {
                    type: 'string',
                    displayName: '<<htdlydwz>>',
                    rules: [{ required: true, message: '<<h_htdlydwz_msg>>' }],
                    controlConfig: {
                        style: { width: '220px' },
                        placeholder: '<<h_htdlydwz_placeholder>>',
                    },
                },
                frameCopyRight: {
                    type: 'string',
                    displayName: '<<zlyzxjwz>>',
                    rules: [{ required: true, message: '<<h_zlyzxjwz_msg>>' }],
                    controlConfig: {
                        style: { width: '220px' },
                        placeholder: '<<h_zlyzxjwz_placeholder>>',
                    },
                },
            },
        },
    },
    components: {
        BackInfoView: {
            component: 'Viewport',
            entity: 'BackInfoEntity',
        },
        BackInfoPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'BackInfoRuleForm' }],
        },
        BackInfoRuleForm: {
            component: 'ModifyFormPlus',
            loaderType: 'get',
            direction: 'horizontal',
            entity: 'BackInfoEntity',
            labelCol: 4,
            controlCol: 16,
            style: { width: 900 },
            fields: [
                { property: 'loginLogo' },
                { property: 'frameLogo' },
                { property: 'loginCopyRight' },
                { property: 'frameCopyRight' },
            ],
            submit: {
                text: '<<common.submit>>',
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'));
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/backinfo',
                    breadcrumbName: '<<backinfo>>',
                    component: 'BackInfoView',
                    privilege: {
                        path: 'backinfo',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'BackInfoPage' }],
                },
            ],
        },
    ],
};
