import { PureComponent } from 'react';
import { Button as AntButton, message as AntMessage, Modal, Form, Input, Select } from 'antd';
import { FormComponentProps } from 'antd/es/form';
import React from 'react';
import { connect } from 'react-redux';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { api, errorHandle } from '@comall-backend-builder/core/lib/services';
import './index.less';
import { services } from '@comall-backend-builder/core';

export interface CouponManageListButtonProps {
    row: any;
    entity: Entity;
    params: any;
    form: FormComponentProps['form'];
}
interface Item {
    label?: string;
    value?: string | any;
    id: string;
    name: string;
}
const formItemLayout = {
    labelCol: {
        xs: { span: 10 },
        sm: { span: 5 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 10,
        },
        sm: {
            span: 12,
            offset: 16,
        },
    },
};
const formItmeList: Item[] = [
    { id: 'mobile', name: 'hysjh' },
    { id: 'couponNo', name: 'couponNum' },
    {
        id: 'type',
        name: 'couponType',
        value: { CASH: 'cashCoupon', GIFT: 'gift', PARKING: 'parking', FREIGHT: 'freight' },
    },
    { id: 'name', name: 'couponName' },
    { id: 'status', name: 'yhqzt', value: { ENABLED: 'dsy' } },
    { id: 'subsite', name: 'hxmd' },
    { id: 'merchant', name: ' ' },
    { id: 'reason', name: 'hxyy' },
];
/**
 * 售后单审核
 */
class couponManageListButton extends PureComponent<CouponManageListButtonProps, {}> {
    state = {
        visible: false,
        subsiteLoading: false,
        merchantLoading: false,
        confirmLoading: false,
        subsiteSelect: '',
        subsiteOptions: [],
        merchantOptions: [],
    };
    loadingCom = (loading: boolean) => {
        return <AntButton loading>加载中</AntButton>;
    };
    componentDidUpdate(prevProps: any, prevState: any) {
        const { id } = prevProps.row;
        if (prevState.visible !== this.state.visible) {
            this.setState({
                confirmLoading: false,
            });
            this.props.form.resetFields();
            let subsiteOptions: any = [];
            this.setState({ subsiteLoading: true });
            //获取门店数据
            api.get<Item[]>(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                    apiPath: `/admin/coupons/${id}/consume/subsite`,
                }
            )
                .then((data) => {
                    data = data || [];
                    subsiteOptions = data.map((item: Item) => ({
                        label: item.name,
                        value: item.id,
                    }));
                    this.setState({ subsiteOptions, subsiteLoading: false });
                    if (subsiteOptions.length === 1) {
                        const subsite = subsiteOptions[0].value;
                        this.props.form.setFieldsValue({ subsite });
                        this.handleChange(subsite);
                    }
                })
                .catch((error) => {
                    this.setState({ subsiteLoading: false });
                    errorHandle(error);
                });
        }
    }
    handleChange = (value: string) => {
        this.setState({
            subsiteSelect: value,
        });
        if (this.props.row.merchants?.length) {
            this.getMerchantData(value);
        }
    };
    getMerchantData = (subsiteId: string) => {
        //获取专柜数据
        this.setState({ merchantLoading: true });
        const { id } = this.props.row;
        let merchantOptions: any = [];
        api.get<Item[]>(
            { subsiteId },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                apiPath: `/admin/coupons/${id}/consume/merchant`,
            }
        )
            .then((data) => {
                data = data || [];
                merchantOptions = data.map((item: Item) => ({
                    label: item.name,
                    value: item.id,
                }));
                this.setState({ merchantOptions, merchantLoading: false });
                if (merchantOptions.length === 1) {
                    const merchant = merchantOptions[0].value;
                    this.props.form.setFieldsValue({ merchant });
                }
            })
            .catch((error) => {
                this.setState({ merchantLoading: false });
                errorHandle(error);
            });
    };
    showModal = () => {
        this.setState({
            visible: true,
        });
    };

    handleCancel = () => {
        this.setState({
            visible: false,
        });
    };
    handleSubmit = (e: any) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const { subsiteOptions, merchantOptions } = this.state;
                const { entity, params, row } = this.props;
                //权限下无可核销门店
                if (!subsiteOptions.length) return;
                //权限下无可核销专柜
                if (row.merchants.length && !merchantOptions.length) return;
                this.setState({
                    confirmLoading: true,
                });
                api.put(
                    {
                        couponNo: row.couponNo,
                        merchantId: values.merchant,
                        reason: values.resaon,
                        subsiteId: values.subsite,
                    },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                        apiPath: `/admin/coupons/${row.id}/force_consume`,
                    }
                )
                    .then(() => {
                        AntMessage.success(services.language.getText('yhqhxcg'));
                        this.handleCancel();
                        entity.search(params);
                    })
                    .catch((error) => {
                        this.setState({ confirmLoading: false });
                        let { response, status } = error;
                        let msg = services.language.getText('qqybzz');
                        const isUseCode = ['1600001007', '1600004026', '1600004027'];
                        //1600001007 优惠券已失效，无法核销 1600004026优惠券已使用，不能重复核销 1600004027优惠券未到使用时间，无法核销
                        if (status) {
                            if (response && response.body) {
                                msg =
                                    response.body.err_msg ||
                                    response.body.message ||
                                    response.body.error;
                                if (isUseCode.includes(String(response.body.err_code))) {
                                    this.handleCancel();
                                    entity.search(params);
                                }
                            }
                            if (!msg) {
                                msg = services.language.getText('qqsb');
                            }
                        }
                        AntMessage.error(msg);
                    });
            }
        });
    };
    submit = () => {
        const { row, entity, params } = this.props;
        const content = services.interpolate(services.language.getText('confirmStopCoupon'), {
            mobile: row.mobile,
            name: row.name,
            couponNo: row.couponNo,
        });
        Modal.confirm({
            style: {
                wordBreak: 'break-all',
            },
            title: services.language.getText('tyyhq'),
            content: content,
            okText: services.language.getText('common.confirm'),
            cancelText: services.language.getText('common.cancel'),
            onOk: () => {
                api.put(
                    {},
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-COUPON`,
                        apiPath: `/admin/coupons/${row.id}/canceled`,
                    }
                )
                    .then(() => {
                        AntMessage.success(services.language.getText('yhqtycg'));
                        entity.search(params);
                    })
                    .catch(errorHandle);
            },
        });
    };
    render() {
        const {
            row,
            form: { getFieldDecorator },
        } = this.props;
        const { status, type } = row;
        const {
            visible,
            confirmLoading,
            subsiteOptions,
            merchantOptions,
            subsiteLoading,
            merchantLoading,
            subsiteSelect,
        } = this.state;

        return (
            status === 'ENABLED' && (
                <span style={{ display: 'flex' }}>
                    <AntButton
                        type="link"
                        className="span-link action-divide"
                        style={{ padding: 0 }}
                        onClick={this.submit}
                    >
                        {services.language.getText('ty_3')}
                    </AntButton>
                    {(type === 'CASH' || type === 'GIFT') && (
                        <div>
                            <AntButton type="link" onClick={this.showModal}>
                                {services.language.getText('hx')}
                            </AntButton>
                            <Modal
                                title={services.language.getText('hxyhq')}
                                visible={visible}
                                footer={null}
                                onCancel={this.handleCancel}
                            >
                                <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                                    {formItmeList.map((item) => {
                                        if (item.id === 'subsite') {
                                            return (
                                                <Form.Item
                                                    labelAlign={'left'}
                                                    label={services.language.getText('hxmd')}
                                                    className="public-label"
                                                >
                                                    {subsiteLoading ? (
                                                        this.loadingCom(subsiteLoading)
                                                    ) : subsiteOptions.length ? (
                                                        getFieldDecorator('subsite', {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: services.language.getText(
                                                                        'qxzygmd'
                                                                    ),
                                                                },
                                                            ],
                                                        })(
                                                            <Select
                                                                placeholder={services.language.getText(
                                                                    'qxzhxmd'
                                                                )}
                                                                onChange={this.handleChange}
                                                                showSearch
                                                                filterOption={(
                                                                    input: any,
                                                                    option: any
                                                                ) =>
                                                                    (option.props.children || '')
                                                                        .toLowerCase()
                                                                        .indexOf(
                                                                            input.toLowerCase()
                                                                        ) >= 0
                                                                }
                                                            >
                                                                {subsiteOptions.map(
                                                                    (item: Item) => (
                                                                        <Select.Option
                                                                            key={item.value}
                                                                            value={item.value}
                                                                        >
                                                                            {item.label}
                                                                        </Select.Option>
                                                                    )
                                                                )}
                                                            </Select>
                                                        )
                                                    ) : (
                                                        <span className={'dange-no-privilege'}>
                                                            {services.language.getText('qxxwkhxmd')}
                                                        </span>
                                                    )}
                                                </Form.Item>
                                            );
                                        } else if (item.id === 'merchant') {
                                            return row.merchants?.length ? (
                                                <Form.Item
                                                    labelAlign={'left'}
                                                    label=" "
                                                    colon={false}
                                                    className="public-label subsite-not-show"
                                                >
                                                    {merchantLoading ? (
                                                        this.loadingCom(merchantLoading)
                                                    ) : merchantOptions.length || !subsiteSelect ? (
                                                        getFieldDecorator('merchant', {
                                                            rules: [
                                                                {
                                                                    required: true,
                                                                    message: services.language.getText(
                                                                        'qxzygzj'
                                                                    ),
                                                                },
                                                            ],
                                                        })(
                                                            <Select
                                                                placeholder={services.language.getText(
                                                                    'qxzhxzj'
                                                                )}
                                                                disabled={!subsiteSelect}
                                                                allowClear
                                                                showSearch
                                                                filterOption={(
                                                                    input: any,
                                                                    option: any
                                                                ) =>
                                                                    (option.props.children || '')
                                                                        .toLowerCase()
                                                                        .indexOf(
                                                                            input.toLowerCase()
                                                                        ) >= 0
                                                                }
                                                            >
                                                                {merchantOptions.map(
                                                                    (item: Item) => (
                                                                        <Select.Option
                                                                            key={item.value}
                                                                            value={item.value}
                                                                        >
                                                                            {item.label}
                                                                        </Select.Option>
                                                                    )
                                                                )}
                                                            </Select>
                                                        )
                                                    ) : (
                                                        <span className={'dange-no-privilege'}>
                                                            {services.language.getText('qxxwkhxzj')}
                                                        </span>
                                                    )}
                                                </Form.Item>
                                            ) : null;
                                        } else if (item.id === 'reason') {
                                            return (
                                                <Form.Item
                                                    labelAlign={'left'}
                                                    label={services.language.getText('hxyy')}
                                                    className="public-label"
                                                >
                                                    {getFieldDecorator('resaon')(
                                                        <Input.TextArea
                                                            style={{ marginTop: '10px' }}
                                                            rows={4}
                                                            placeholder={services.language.getText(
                                                                'qsrqzhxdyy'
                                                            )}
                                                            maxLength={100}
                                                        />
                                                    )}
                                                </Form.Item>
                                            );
                                        } else {
                                            return (
                                                <Form.Item
                                                    labelAlign={'left'}
                                                    label={services.language.getText(item.name)}
                                                    className="public-label"
                                                >
                                                    <div>
                                                        {item.value
                                                            ? item.value[row[item.id]]
                                                            : row[item.id]}
                                                    </div>
                                                </Form.Item>
                                            );
                                        }
                                    })}

                                    <Form.Item
                                        {...tailFormItemLayout}
                                        className="public-label pdt20"
                                    >
                                        <AntButton
                                            style={{ marginRight: 10 }}
                                            onClick={this.handleCancel}
                                        >
                                            {services.language.getText('common.cancel')}
                                        </AntButton>
                                        <AntButton
                                            type="primary"
                                            htmlType="submit"
                                            loading={confirmLoading}
                                        >
                                            {services.language.getText('common.submit')}
                                        </AntButton>
                                    </Form.Item>
                                </Form>
                            </Modal>
                        </div>
                    )}
                </span>
            )
        );
    }
}
function mapStateToProps(state: any, props: CouponManageListButtonProps) {
    const { entity, row, params } = props;
    return {
        entity,
        row,
        params,
    };
}
export const CouponManageListButton = connect(mapStateToProps)(
    Form.create()(couponManageListButton)
);
