import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';

const api = services.api;
export const MerchantUserLogLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-EVENT-TRACKING`;
        config.apiPath = '/admin/operation_logs/page';
        data.businessType = 'USER';
        data.businessId = data.id;
        delete data.id;
        return await api.get(data, config);
    },
};
