import React, { Component } from 'react';
import { Radio } from 'antd';
import { newClientCustomSelectorConfig } from '../../applications/design/design';
import { getThirdLinkTypeEffectPathConfigList } from '../link-type-effect-path/config';
import { LinkTypePlus } from '../../applications/design/components';
import { services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

const RadioGroup = Radio.Group;

type LinkValue = { linkType: string; linkParams: any } | null;

export enum CarparkCheckoutType {
    INTERNAL = 'INTERNAL',
    TRIPARTITE = 'TRIPARTITE',
}

type CarparkCheckoutTypeConfigValue = {
    type: CarparkCheckoutType;
    config: string | null;
};

export interface CarparkCheckoutTypeConfigProps {
    name: string;
    value: CarparkCheckoutTypeConfigValue;
    onChange: (value: CarparkCheckoutTypeConfigValue | null, name: string) => void;
}

const DEFAULT_VALUE = {
    type: CarparkCheckoutType.INTERNAL,
    config: null,
};

const DEFAULT_TRIPARTITE_TARGET = {
    linkType: '',
    linkParams: null,
};

export class CarparkCheckoutTypeConfig extends Component<CarparkCheckoutTypeConfigProps> {
    componentDidMount() {
        const { value } = this.props;
        if (!value) {
            this.changeValue(DEFAULT_VALUE);
        }
    }
    onCarparkCheckoutTypeChange = (e: any) => {
        const type = e.target.value;
        const { value } = this.props;
        if (!value) {
            return;
        }
        this.changeValue({
            ...value,
            type,
        });
    };
    onTripartiteTargetChange = (linkValue: LinkValue) => {
        const { value } = this.props;
        if (!value || value.type !== CarparkCheckoutType.TRIPARTITE) {
            return;
        }
        this.changeValue({
            ...value,
            config: linkValue ? JSON.stringify(linkValue) : null,
        });
    };
    changeValue = (value: CarparkCheckoutTypeConfigValue) => {
        let { name, onChange } = this.props;
        if (onChange) {
            onChange(value, name);
        }
    };

    private get linkValue() {
        const { value } = this.props;
        if (!value || !value.config) {
            return DEFAULT_TRIPARTITE_TARGET;
        }
        return JSON.parse(value.config);
    }

    renderCarparkCheckoutTypeRadio = () => {
        const { value = DEFAULT_VALUE } = this.props;
        const radioValue = value?.type || undefined;
        return (
            <RadioGroup onChange={this.onCarparkCheckoutTypeChange} value={radioValue}>
                <Radio value={CarparkCheckoutType.INTERNAL}>{language.getText('nbjsjf')}</Radio>
                <Radio value={CarparkCheckoutType.TRIPARTITE}>{language.getText('tzsfjsjf')}</Radio>
            </RadioGroup>
        );
    };

    renderTips = () => {
        const { value } = this.props;
        if (value?.type !== CarparkCheckoutType.INTERNAL) {
            return null;
        }
        return (
            <div style={{ color: '#ccc', lineHeight: '20px' }}>
                {services.language.getText('xzdjccxt')}
            </div>
        );
    };

    thirdLinkTypeEffectPathConfigList = getThirdLinkTypeEffectPathConfigList();

    renderLinkType = () => {
        const { value } = this.props;
        if (value?.type !== CarparkCheckoutType.TRIPARTITE) {
            return null;
        }
        const linkValue = this.linkValue;

        return (
            <LinkTypePlus
                selector={newClientCustomSelectorConfig as any}
                linkTypes={this.thirdLinkTypeEffectPathConfigList}
                linkTypeMode="cascader"
                value={linkValue}
                onChange={this.onTripartiteTargetChange}
            />
        );
    };

    render() {
        return (
            <div className="carpark-linkType">
                {this.renderCarparkCheckoutTypeRadio()}
                {this.renderTips()}
                {this.renderLinkType()}
            </div>
        );
    }
}
