import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'antd';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

const PLAY_NOTIFICATION = 'PLAY_NOTIFICATION';

/**
 * 小喇叭提醒
 *  @param {Boolean} visible - 是否可见
 *  @param {String} src -  音频地址
 *  @param {Boolean} muted - 是否关闭声音
 *  @param {Boolean} loop - 是否循环播放
 *  @param {Boolean} play - 是否播放
 *  @param {Boolean} markRead - 是否已读
 *  @param {Function} onClick -  点击喇叭事件回调
 *  @param {String} icon -  Icon类型
 */
export class VoiceReminder extends PureComponent<any, any> {
    static propTypes = {
        visible: PropTypes.bool,
        src: PropTypes.string,
        muted: PropTypes.bool,
        loop: PropTypes.bool,
        play: PropTypes.bool,
        markRead: PropTypes.bool,
        onClick: PropTypes.func,
        icon: PropTypes.string,
    };
    state = {
        muted: false,
    };

    componentDidMount() {
        const playNotification = localStorage.getItem(PLAY_NOTIFICATION);
        if (playNotification === 'OFF') {
            this.setState({ muted: true });
        }
        const audio: any = this.refs.audio;
        const { play } = this.props;
        if (!audio) {
            return;
        }
        setTimeout(function() {
            if (play) {
                audio.play();
            } else {
                audio.pause();
            }
        });
    }

    componentWillReceiveProps(props: any) {
        if (!props || !this.refs.audio) {
            return;
        }
        const audio: any = this.refs.audio;
        const { play, markRead } = props;

        if (markRead) {
            return;
        }
        setTimeout(function() {
            if (play) {
                audio.play();
            } else {
                if (!audio.paused) {
                    audio.pause();
                }
            }
        }, 500);
    }

    onClick = () => {
        const { onClick } = this.props;
        if (onClick) {
            onClick();
        }
    };

    switchVoice = () => {
        const { muted } = this.state;
        if (muted) {
            localStorage.setItem(PLAY_NOTIFICATION, 'ON');
            this.setState({ muted: false });
        } else {
            localStorage.setItem(PLAY_NOTIFICATION, 'OFF');
            this.setState({ muted: true });
        }
    };

    render() {
        const { visible, src, loop, markRead } = this.props;
        const { muted } = this.state;
        const title = muted ? language.getText('clickOpenV') : language.getText('clickCloseV');
        if (!visible) {
            return null;
        }
        return (
            <label className="voice-reminder" title={title} onClick={this.onClick}>
                <audio
                    ref="audio"
                    className="voice-reminder-audio"
                    src={src}
                    controls
                    loop={loop}
                    muted={muted}
                    preload="true"
                />
                <span onClick={this.switchVoice} style={{ position: 'relative' }} title={title}>
                    <Icon type="sound" style={{ fontSize: 16 }} />
                    {muted ? (
                        <i
                            style={{
                                position: 'absolute',
                                backgroundColor: '#fff',
                                top: 0,
                                right: 0,
                                bottom: 0,
                                left: '59%',
                            }}
                        />
                    ) : null}
                </span>
                {!markRead && <span className="dotted" />}
            </label>
        );
    }
}
