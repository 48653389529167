import React, { Component } from 'react';
import { Checkbox, Input, Button, Modal } from 'antd';
import { api } from '@comall-backend-builder/core/lib/services';
import { get } from 'lodash';
import { services } from '@comall-backend-builder/core';

const { TextArea } = Input;

export interface NotificatonContentValue {
    channel: boolean;
    content: string;
    agreement: boolean;
}
export interface ObjectPaymentNotificatonContentCheckboxTipState {
    hasMessageChannel: boolean;
    checkBoxDisabled: boolean;
    tip: string;
    isModalOpen: boolean;
    noteDefault: NotificatonContentValue;
}
const defaultValue: NotificatonContentValue = {
    channel: false,
    content: 'notificatonContent',
    agreement: false,
};
const defaultValueUser: NotificatonContentValue = {
    channel: false,
    content: 'NotificatonContentValue',
    agreement: false,
};

function getDefaultValue() {
    const curValue = { ...defaultValue, content: services.language.getText('notificatonContent') };
    return curValue;
}

function getDefaultValueUser() {
    const curValue = {
        ...defaultValueUser,
        content: services.language.getText('NotificatonContentValue'),
    };
    return curValue;
}

export class PaymentNotificatonContentCheckboxTip extends Component<
    any,
    ObjectPaymentNotificatonContentCheckboxTipState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            hasMessageChannel: false,
            checkBoxDisabled: true,
            tip: '',
            isModalOpen: false,
            noteDefault: getDefaultValueUser(),
        };
    }
    componentDidMount() {
        this.getNotificatonConfig();
    }
    componentWillReceiveProps(nextProps: any) {
        const { hasMessageChannel } = this.state;
        if (hasMessageChannel && nextProps && nextProps.row && nextProps.row.baseInfo) {
            const currentSubsite = get(this.props, 'row.baseInfo.subsiteId', '');
            const nextSubsite = get(nextProps, 'row.baseInfo.subsiteId', '');
            if (currentSubsite !== nextSubsite) {
                this.loadDisabled(nextProps);
            }
        }
        if (nextProps && nextProps.row && nextProps.row.conditionConfig) {
            const { name, onChange } = nextProps;
            const currentGrantMode = get(this.props, 'row.conditionConfig.grantMode', '');
            const nextGrantMode = get(nextProps, 'row.conditionConfig.grantMode', '');
            if (currentGrantMode !== nextGrantMode) {
                const noteDefault =
                    nextGrantMode.toString() === '0' ? getDefaultValueUser() : getDefaultValue();
                this.setState({ noteDefault }, () => {
                    onChange(
                        {
                            ...noteDefault,
                        },
                        name
                    );
                });
            }
        }
    }
    loadDisabled = (props: any) => {
        const { hasMessageChannel, noteDefault } = this.state;
        const subsites = get(props, 'row.baseInfo.subsiteId', '');
        if (hasMessageChannel) {
            if (subsites) {
                this.setState({
                    checkBoxDisabled: false,
                    tip: '',
                });
                const { name, onChange, value } = this.props;
                const currentValue = value || noteDefault;
                onChange(
                    {
                        ...currentValue,
                    },
                    name
                );
            } else {
                this.setState(
                    {
                        checkBoxDisabled: true,
                        tip: services.language.getText('selectBeforeMerchant'),
                    },
                    () => {
                        this.setDisabledAndClearValue();
                    }
                );
            }
        } else {
            this.setState(
                {
                    checkBoxDisabled: true,
                    tip: services.language.getText('nsxdmdhwkyxldxfw'),
                },
                () => {
                    this.setDisabledAndClearValue();
                }
            );
        }
    };

    getNotificatonConfig = () => {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                apiPath: `/admin/message_channel/hasMessageChannel`,
            }
        ).then((response: any) => {
            this.setState(
                {
                    hasMessageChannel: response,
                },
                () => {
                    this.loadDisabled(this.props);
                }
            );
        });
    };

    onChange = (event: any) => {
        const { name, onChange, value } = this.props;
        const currentValue = value || this.state.noteDefault;
        onChange(
            {
                ...currentValue,
                channel: event.target.checked,
            },
            name
        );
    };

    onInputChange = (event: any) => {
        const { name, onChange, value } = this.props;
        const currentValue = value || this.state.noteDefault;
        onChange(
            {
                ...currentValue,
                content: event.target.value,
            },
            name
        );
    };

    onAgreementChange = (event: any) => {
        const { name, onChange, value } = this.props;
        const currentValue = value || this.state.noteDefault;
        onChange(
            {
                ...currentValue,
                agreement: event.target.checked,
            },
            name
        );
    };

    onClickLink = () => {
        this.setState({
            isModalOpen: true,
        });
    };

    setDisabledAndClearValue = () => {
        const { name, onChange } = this.props;
        onChange(undefined, name);
    };

    handleOk = () => {
        const { name, onChange, value } = this.props;
        const currentValue = value || getDefaultValue();
        onChange(
            {
                ...currentValue,
                agreement: true,
            },
            name
        );
        this.setState({
            isModalOpen: false,
        });
    };

    onCancel = () => {
        this.setState({
            isModalOpen: false,
        });
    };

    render() {
        const { value, disabled, showAgreementIn } = this.props;
        const { checkBoxDisabled, tip, isModalOpen } = this.state;
        const checked = value && value.channel;
        const contentText = value && value.content ? value.content : '';
        const textLength = contentText.length;
        const agreementChecked = value && value.agreement;
        return (
            <div>
                <Checkbox
                    onChange={this.onChange}
                    disabled={checkBoxDisabled || disabled}
                    checked={checked}
                >
                    {services.language.getText('dx')}
                </Checkbox>
                {!checkBoxDisabled ? (
                    <div>
                        <TextArea
                            onChange={this.onInputChange}
                            value={contentText}
                            rows={4}
                            placeholder={services.language.getText('inputPlease')}
                            maxLength={500}
                            disabled={disabled}
                            style={{ width: 400 }}
                        ></TextArea>
                        <div style={{ fontSize: 12, color: '#ccc', lineHeight: 1.5 }}>
                            {services.language.getText('dxzfs')}{' '}
                            <span style={{ color: '#1890ff' }}>{textLength}</span>{' '}
                            {services.language.getText('bbhqmblzdzfsl')}
                        </div>
                        <div style={{ fontSize: 12, color: '#ccc', lineHeight: 1.5 }}>
                            {services.language.getText('blzidwajs')}
                        </div>
                        {(!showAgreementIn || showAgreementIn !== 'false') && (
                            <Checkbox onChange={this.onAgreementChange} checked={agreementChecked}>
                                {services.language.getText('ty')}
                                <Button
                                    type="link"
                                    onClick={this.onClickLink}
                                    style={{ padding: 0 }}
                                >
                                    {services.language.getText('dxfwxy')}
                                </Button>
                            </Checkbox>
                        )}
                    </div>
                ) : null}
                {tip && <div style={{ fontSize: 12, color: '#ccc', lineHeight: 1.5 }}>{tip}</div>}
                <Modal
                    title={services.language.getText('dxfwxy')}
                    visible={isModalOpen}
                    width={800}
                    onCancel={this.onCancel}
                    footer={[
                        <Button key="submit" type="primary" onClick={this.handleOk}>
                            {services.language.getText('wzdl')}
                        </Button>,
                    ]}
                >
                    <div
                        style={{ fontSize: 14, color: '#000', height: '60vh', overflowY: 'scroll' }}
                    >
                        <p style={{ fontWeight: 'bold' }}>
                            {services.language.getText('comallTerm1')}
                        </p>
                        <p>{services.language.getText('comallAgreement1')}</p>
                        <p>{services.language.getText('comallAgreement2')}</p>
                        <p style={{ fontWeight: 'bold' }}>
                            {services.language.getText('comallTerm2')}
                        </p>
                        <p>{services.language.getText('comallAgreement3')}</p>
                        <p>{services.language.getText('comallAgreement4')}</p>
                        <p>{services.language.getText('comallAgreement5')}</p>
                        <p>{services.language.getText('comallAgreement6')}</p>
                        <p style={{ fontWeight: 'bold' }}>
                            {services.language.getText('comallTerm3')}
                        </p>
                        <p>{services.language.getText('comallAgreement7')}</p>
                        <p>{services.language.getText('comallAgreement8')}</p>
                        <p>{services.language.getText('comallAgreement9')}</p>
                        <p>{services.language.getText('comallAgreement10')}</p>
                        <p>{services.language.getText('comallAgreement11')}</p>
                        <p>{services.language.getText('comallAgreement12')}</p>
                        <p>{services.language.getText('comallAgreement13')}</p>
                        <p>{services.language.getText('comallAgreement14')}</p>
                        <p>{services.language.getText('comallAgreement15')}</p>
                        <p>{services.language.getText('comallAgreement16')}</p>
                        <p>{services.language.getText('comallAgreement17')}</p>
                        <p>{services.language.getText('comallAgreement18')}</p>
                        <p>{services.language.getText('comallAgreement19')}</p>
                        <p>{services.language.getText('comallAgreement20')}</p>
                        <p style={{ fontWeight: 'bold' }}>
                            {services.language.getText('comallTerm4')}
                        </p>
                        <p>{services.language.getText('comallAgreement21')}</p>
                        <p>{services.language.getText('comallAgreement22')}</p>
                        <p>{services.language.getText('comallAgreement23')}</p>
                        <p>{services.language.getText('comallAgreement24')}</p>
                        <p>{services.language.getText('comallAgreement25')}</p>
                        <p>{services.language.getText('comallAgreement26')}</p>
                        <p style={{ fontWeight: 'bold' }}>
                            {services.language.getText('comallTerm5')}
                        </p>
                        <p>{services.language.getText('comallAgreement27')}</p>
                        <p>{services.language.getText('comallAgreement28')}</p>
                        <p>{services.language.getText('comallAgreement29')}</p>
                        <p>{services.language.getText('comallAgreement30')}</p>
                        <p>{services.language.getText('comallAgreement31')}</p>
                        <p>{services.language.getText('comallAgreement32')}</p>
                        <p>{services.language.getText('comallAgreement33')}</p>
                        <p>{services.language.getText('comallAgreement34')}</p>
                        <p style={{ fontWeight: 'bold' }}>
                            {services.language.getText('comallTerm6')}
                        </p>
                        <p>{services.language.getText('comallAgreement35')}</p>
                        <p>{services.language.getText('comallAgreement36')}</p>
                        <p>{services.language.getText('comallAgreement37')}</p>
                        <p>{services.language.getText('comallAgreement38')}</p>
                        <p>{services.language.getText('comallAgreement39')}</p>
                    </div>
                </Modal>
            </div>
        );
    }
}
