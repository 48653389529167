import React, { Component } from 'react';
import { Cascader, Checkbox, Input } from 'antd';
import { api, language } from '@comall-backend-builder/core/lib/services';
import { get, isEqual, last } from 'lodash';
import { TopicSelector } from '../../../../applications/design/design/components/custom-select-topic';
import { CustomSelectWheelDraw } from '../../../../applications/design/design/components/custorm-select-wheel-draw';
import { CustomSelectRedPackageRain } from '../../../../applications/design/design/components/custom-select-red-package-rain';
import { CustomSelectScratchCard } from '../../../../applications/design/design/components/custom-select-scratch-card';
import { CustomSelectLotteryCode } from '../../../../applications/design/design/components/custom-select-lottery-code';
import { searchStringify, searchWebParse } from '../../../../services/utils';
import { services } from '@comall-backend-builder/core';
const { TextArea } = Input;

export interface NotificatonContentValue {
    channel: Array<string>;
    content: string;
    url: string;
}
export interface ObjectPaymentNotificatonContentCheckboxTipState {
    /**
     * 门店是否支持短信通道
     */
    hasMessageChannel: boolean;
    /**
     * 是否可勾选
     */
    checkBoxDisabled: boolean;
    /**
     * 不可勾选文字提醒
     */
    tip: string;
    noteDefault: NotificatonContentValue;
}

export class PayWithGiftRewardNotificatonCheckbox extends Component<
    any,
    ObjectPaymentNotificatonContentCheckboxTipState
> {
    constructor(props: any) {
        super(props);
        this.state = {
            hasMessageChannel: false,
            checkBoxDisabled: true,
            tip: '',
            noteDefault: {
                channel: [],
                content: '',
                url: '',
            },
        };
    }

    componentDidMount() {
        this.getNotificatonConfig();
    }
    componentWillReceiveProps(nextProps: any) {
        const { hasMessageChannel } = this.state;
        if (hasMessageChannel && nextProps && nextProps.row && nextProps.row.baseInfo) {
            const currentSubsiteIds = this.getSubsiteIds(this.props);
            const nextSubsiteIds = this.getSubsiteIds(nextProps);
            if (!isEqual(nextSubsiteIds, currentSubsiteIds) && nextSubsiteIds) {
                setTimeout(() => {
                    this.loadDisabled(nextProps);
                }, 100);
            }
        }
    }
    getSubsiteIds = (props: any) => {
        const { row } = props;
        const subsiteIds = row.baseInfo && row.baseInfo.subsiteId ? row.baseInfo.subsiteId : null;
        return subsiteIds;
    };
    loadDisabled = (props: any) => {
        const { hasMessageChannel, noteDefault } = this.state;
        const subsites = get(props, 'row.baseInfo.subsiteId', '');
        if (hasMessageChannel) {
            if (subsites) {
                this.setState({
                    checkBoxDisabled: false,
                    tip: '',
                });
                const { name, onChange, value } = this.props;
                const currentValue = value || noteDefault;
                onChange(
                    {
                        ...currentValue,
                    },
                    name
                );
            } else {
                this.setState(
                    {
                        checkBoxDisabled: true,
                        tip: services.language.getText('selectBeforeMerchant'),
                    },
                    () => {
                        this.setDisabledAndClearValue();
                    }
                );
            }
        } else {
            this.setState(
                {
                    checkBoxDisabled: true,
                    tip: services.language.getText('nsxdmdhwkyxldxfw'),
                },
                () => {
                    this.setDisabledAndClearValue();
                }
            );
        }
    };

    getNotificatonConfig = () => {
        api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                apiPath: `/admin/message_channel/hasMessageChannel`,
            }
        ).then((response: any) => {
            this.setState(
                {
                    hasMessageChannel: response,
                },
                () => {
                    this.loadDisabled(this.props);
                }
            );
        });
    };

    onChange = (channel: any) => {
        const { name, onChange, value } = this.props;
        const currentValue = value || this.state.noteDefault;
        onChange(
            {
                ...currentValue,
                channel: channel,
            },
            name
        );
    };

    onInputChange = (event: any) => {
        const { name, onChange, value } = this.props;
        const currentValue = value || this.state.noteDefault;
        onChange(
            {
                ...currentValue,
                content: event.target.value,
            },
            name
        );
    };

    setDisabledAndClearValue = () => {
        const { name, onChange } = this.props;
        onChange(undefined, name);
    };

    getTargetUrlParamsComponent = (miniTargetUrl: string) => {
        const urlInfo = this.getUrlInfo();
        const pathParams = urlInfo?.pathParams;
        const currentSubsiteIds = this.getSubsiteIds(this.props);
        // 专题活动
        if (miniTargetUrl.includes('subpackages/topic')) {
            return (
                <TopicSelector
                    newTopic
                    subsiteIds={[currentSubsiteIds]}
                    value={{ linkParams: pathParams }}
                    onChange={this.onChangePathParams}
                />
            );
        }
        // 大转盘抽奖
        if (miniTargetUrl.includes('wheel-draw/pages')) {
            return (
                <CustomSelectWheelDraw
                    subsiteIds={[currentSubsiteIds]}
                    value={{ linkParams: pathParams }}
                    contentConfig={{}}
                    modalConfig={{}}
                    onChange={this.onChangePathParams}
                />
            );
        }
        // 红包雨
        if (miniTargetUrl.includes('packet-rain/index')) {
            return (
                <CustomSelectRedPackageRain
                    subsiteIds={[currentSubsiteIds]}
                    value={{ linkParams: pathParams }}
                    contentConfig={{}}
                    modalConfig={{}}
                    onChange={this.onChangePathParams}
                />
            );
        }
        // 积分刮刮卡
        if (miniTargetUrl.includes('scratch-card/index')) {
            return (
                <CustomSelectScratchCard
                    subsiteIds={[currentSubsiteIds]}
                    value={{ linkParams: pathParams }}
                    contentConfig={{}}
                    modalConfig={{}}
                    onChange={this.onChangePathParams}
                />
            );
        }
        // 积分抽奖码
        if (miniTargetUrl.includes('lottery-code/index')) {
            return (
                <CustomSelectLotteryCode
                    subsiteIds={[currentSubsiteIds]}
                    value={{ linkParams: pathParams }}
                    contentConfig={{}}
                    modalConfig={{}}
                    onChange={this.onChangePathParams}
                />
            );
        }
        //支付有礼
        if (miniTargetUrl.includes('market/payment-reward/pages/detail/index')) {
            return <div>{services.language.getText('zfyl')}</div>;
        }
        return null;
    };

    onChangePathParams = (pathValue: any) => {
        const { value, onChange } = this.props;
        const urlInfo = this.getUrlInfo();
        if (urlInfo) {
            if (
                urlInfo.url.includes('subpackages/topic') ||
                urlInfo.url.includes('wheel-draw/pages') ||
                urlInfo.url.includes('packet-rain/index') ||
                urlInfo.url.includes('scratch-card/index') ||
                urlInfo.url.includes('lottery-code/index')
            ) {
                const pathParams = {
                    id: pathValue.id,
                    name: pathValue.name,
                };
                const url =
                    urlInfo.url +
                    `${urlInfo.url.includes('?') ? '&' : '?'}${searchStringify(pathParams)}`;
                value.url = url;
                onChange(value);
            }
        }
    };

    getCascaderMiniTargetUrl = (miniTargetUrl: string) => {
        const marketing = [
            'wheel-draw/pages',
            'packet-rain/index',
            'market/payment-reward/pages/detail/index',
        ];
        const credit = ['scratch-card/index', 'lottery-code/index'];
        if (marketing.some((i) => miniTargetUrl.includes(i))) {
            return ['marketing', miniTargetUrl];
        }
        if (credit.some((i) => miniTargetUrl.includes(i))) {
            return ['credit', miniTargetUrl];
        }
        return [miniTargetUrl];
    };

    renderLinkTypePlus = () => {
        const { value } = this.props;
        const options = [
            {
                value: 'subpackages/topic/index?subsiteId={subsiteId}',
                label: services.language.getText('zthd'),
            },
            {
                value: 'marketing',
                label: services.language.getText('ycxhd'),
                children: [
                    {
                        value: 'subpackages/wheel-draw/pages/detail/index?subsiteId={subsiteId}',
                        label: services.language.getText('dzpcj'),
                    },
                    {
                        value: 'subpackages/packet-rain/index?subsiteId={subsiteId}',
                        label: services.language.getText('hby'),
                    },
                    {
                        value:
                            'subpackages/market/payment-reward/pages/detail/index?type=PAY_AWARD&subsiteId={subsiteId}',
                        label: services.language.getText('zfyl'),
                    },
                ],
            },
            {
                value: 'credit',
                label: services.language.getText('jfhd'),
                children: [
                    {
                        value: 'subpackages/scratch-card/index?subsiteId={subsiteId}',
                        label: services.language.getText('scratchCard'),
                    },
                    {
                        value: 'subpackages/lottery-code/index?subsiteId={subsiteId}',
                        label: services.language.getText('lotteryCode'),
                    },
                ],
            },
        ];
        const urlInfo = this.getUrlInfo();
        return (
            <div style={{ marginTop: '10px' }}>
                <div style={{ display: 'flex' }}>
                    <div>{services.language.getText('ljlx')}：</div>
                    <div>
                        <Cascader
                            className="trans-action-selection"
                            style={{ width: '230px' }}
                            value={urlInfo ? urlInfo.linkType : undefined}
                            options={options}
                            placeholder={services.language.getText('selectPlease')}
                            onChange={(transAction: any) => {
                                this.changeMiniTargetUrl(transAction);
                            }}
                        />
                    </div>
                </div>
                {value && value.url && (
                    <div style={{ display: 'flex' }}>
                        <div style={{ lineHeight: '40px' }}>
                            {services.language.getText('ljmb')}：
                        </div>
                        <div>{this.getTargetUrlParamsComponent(value.url)}</div>
                    </div>
                )}
            </div>
        );
    };

    changeMiniTargetUrl = (url: any) => {
        const targetUrl = url && url.length > 0 ? last(url) : '';
        const { value, onChange } = this.props;
        value.url = targetUrl;
        onChange(value);
    };

    getUrlInfo = () => {
        const { value } = this.props;
        if (!value || !value.url) {
            return undefined;
        }
        const urlVo = value.url.split('?');
        const pageParams: string = urlVo && urlVo.length >= 1 ? urlVo[1] : '';
        const scene = urlVo && urlVo.length > 1 ? searchWebParse(pageParams) : null;
        const url = scene
            ? value.url.replace('&id=' + scene.id, '').replace('&name=' + scene.name, '')
            : value.url;
        return {
            url: url,
            linkType: this.getCascaderMiniTargetUrl(url),
            pathParams: scene
                ? {
                      id: scene.id,
                      name: scene.name,
                  }
                : undefined,
        };
    };

    render() {
        const { value, disabled } = this.props;
        const { checkBoxDisabled, tip } = this.state;
        const channel = value ? value.channel : undefined;
        const content = value ? value.content : '';
        const textLength = content.length;
        const isHavaWechatChannel = channel
            ? channel.join(',').includes('WECHAT_SUBSCRIPTION_MSG')
            : false;
        const isHavaSmsChannel = channel ? channel.join(',').includes('SMS') : false;
        return (
            <div>
                <Checkbox.Group style={{ width: '100%' }} value={channel} onChange={this.onChange}>
                    <Checkbox value="SMS" disabled={checkBoxDisabled || disabled}>
                        {services.language.getText('dx')}
                    </Checkbox>
                    <Checkbox value="WECHAT_SUBSCRIPTION_MSG">
                        {services.language.getText('wxdyxx')}
                    </Checkbox>
                </Checkbox.Group>
                {isHavaSmsChannel ? (
                    <div>
                        <TextArea
                            onChange={this.onInputChange}
                            value={content}
                            rows={4}
                            placeholder={services.language.getText('inputPlease')}
                            maxLength={500}
                            disabled={disabled}
                            style={{ width: 400 }}
                        ></TextArea>
                        <div style={{ fontSize: 14, color: '#ccc', lineHeight: 1.5 }}>
                            {services.language.getText('dxzfs')}{' '}
                            <span style={{ color: '#1890ff' }}>{textLength}</span>{' '}
                            {services.language.getText('bbhqmblzdzfsl')}
                        </div>
                        <div style={{ fontSize: 14, color: '#ccc', lineHeight: 1.5 }}>
                            <div>{`${language.getText('slzjdhynh1')}{number}${language.getText(
                                'slzjdhynh2'
                            )}{shortLink}。${language.getText(
                                'slzjdhynh3'
                            )}{count}${language.getText('slzjdhynh4')}`}</div>
                            <div>{`{shortLink}：${language.getText(
                                'zdsczfbhwxxcxddyxpxqydl'
                            )}`}</div>
                            <div> {`{count}：${language.getText('ffcjjhdsl')}`}</div>
                            <div> {`{number}：${language.getText('ffyhqdsl')}`}</div>
                            <div>{services.language.getText('rdxnrdydl')}</div>
                        </div>
                    </div>
                ) : null}
                {isHavaWechatChannel ? this.renderLinkTypePlus() : null}
                {tip && <div style={{ fontSize: 12, color: '#ccc', lineHeight: 1.5 }}>{tip}</div>}
            </div>
        );
    }
}
