import React, { PureComponent } from 'react';
import {
    Modal,
    message as AntMessage,
    Form,
    Radio,
    Checkbox,
    InputNumber,
    Tooltip,
    Icon,
} from 'antd';
import PropTypes from 'prop-types';
import { SelectActivities } from './select-popular-activities';
import { language } from '@comall-backend-builder/core/lib/services';

const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};

const ActivityListConfigForm = Form.create({
    name: 'activityList',
    mapPropsToFields(props: any) {
        return !props.value
            ? {}
            : Object.entries(props.value).reduce((v0: any, [k, v]) => {
                  v0[k] = Form.createFormField({
                      value: v,
                  });
                  return v0;
              }, {});
    },
})(
    class extends React.Component {
        static propTypes: {
            onSubmit: PropTypes.Requireable<(...args: any[]) => any>;
            value: any;
        };
        handleSubmit = (e: any) => {
            const { form, onSubmit } = this.props as any;
            form.validateFields((err: any, values: any) => {
                onSubmit(values);
            });
        };
        numberChange = (val: any) => {
            const { form } = this.props as any;
            const { setFieldsValue } = form;
            setFieldsValue({ count: val });
        };
        render() {
            const { form } = this.props as any;
            const { getFieldDecorator, getFieldValue } = form;

            return (
                <Form ref="reviewFrom" {...formItemLayout} onSubmit={this.handleSubmit}>
                    <FormItem label={'显示个数'}>
                        {getFieldDecorator('showCount', {
                            initialValue: 'ALL',
                        })(
                            <Radio.Group>
                                <Radio value={'ALL'}>全部</Radio>
                                <Radio value={'COUNT'}>
                                    {getFieldDecorator('count', {
                                        initialValue: '',
                                    })(
                                        <>
                                            <InputNumber
                                                onChange={this.numberChange}
                                                min={1}
                                                precision={0}
                                                max={999999}
                                                size="small"
                                                value={getFieldValue('count') || ''}
                                            />
                                            &nbsp; 个
                                        </>
                                    )}
                                </Radio>
                            </Radio.Group>
                        )}
                    </FormItem>
                    <FormItem label={'显示活动'}>
                        {getFieldDecorator('showStatus', {
                            initialValue: ['STARTED', 'NOTSTARTED', 'COMPLETE'],
                        })(
                            <Checkbox.Group>
                                <Checkbox
                                    style={{ marginTop: 10, marginBottom: 5 }}
                                    value="STARTED"
                                >
                                    报名进行中
                                </Checkbox>
                                <br />

                                <Checkbox style={{ marginBottom: 5 }} value="NOTSTARTED">
                                    报名未开始
                                </Checkbox>
                                <br />

                                <Checkbox style={{ marginBottom: 5 }} value="COMPLETE">
                                    报名已结束
                                </Checkbox>
                            </Checkbox.Group>
                        )}
                    </FormItem>
                    {ENV.TENANT === 'hxgc' && (
                        <FormItem
                            label={
                                <span>
                                    热门活动&nbsp;
                                    <Tooltip title={'可拖动右侧商品调整排序'}>
                                        <Icon type="question-circle-o" />
                                    </Tooltip>
                                </span>
                            }
                        >
                            {getFieldDecorator('popularActivities', {
                                initialValue: [],
                            })(<SelectActivities />)}
                        </FormItem>
                    )}
                </Form>
            );
        }
    }
);
/**
 * 选择商城活动列表
 */
export class SelectMallActivityList extends PureComponent<{
    contentConfig?: Record<string, any>;
    modalConfig?: Record<string, any>;
    showModal: boolean;
    onChange: (data: any) => void;
    onCancel: () => void;
}> {
    //@ts-ignore
    listenRef: {
        handleSubmit: () => Promise<any>;
    };

    onOk = (val: any) => {
        const { onChange } = this.props;
        if (!val.showStatus || !val.showStatus.length) {
            return AntMessage.error('请配置显示的活动类型');
        }
        if (val.showCount === 'COUNT' && !val.count) {
            return AntMessage.error('请配置显示个数');
        }
        onChange(val);
    };

    onCancel = () => {
        const { onCancel } = this.props;
        onCancel();
    };

    render() {
        const { showModal, value } = this.props as any;
        return (
            <Modal
                visible={showModal}
                className="review-activity-view"
                title="选择活动"
                mask={false}
                maskClosable
                okText={language.getText('common.ok')}
                cancelText={language.getText('common.cancel')}
                width="580px"
                onOk={() => {
                    this.listenRef.handleSubmit();
                }}
                onCancel={this.onCancel}
            >
                <ActivityListConfigForm
                    wrappedComponentRef={(ref: any) => (this.listenRef = ref)}
                    onSubmit={this.onOk}
                    value={value}
                />
            </Modal>
        );
    }
}
