import { Config } from '@comall-backend-builder/core/lib/parser';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { get } from 'lodash';
import { TipsModalContentType } from '../../../components/tips-modal';

const allowOrderDelayedSync = (values: any) =>
    get(values, 'orderDelayedSync.allowOrderDelayedSync') === 'true';

export const config: Config = {
    entities: {
        ShoppingListEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`,
            apiPath: '/loader/admin/shopping_list_union_order_records',
            filters: {
                guideName: {
                    type: 'string',
                    displayName: '<<dgymc>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                guideMobile: {
                    type: 'string',
                    displayName: '<<dgysjh>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                orderNo: {
                    type: 'string',
                    displayName: '<<orderNumber>>',
                    controlConfig: { placeholder: '<<inputPlease>>' },
                },
                orderStatus: {
                    type: 'string.options.select',
                    displayName: '<<ddzt>>',
                    options: [
                        { id: '1', name: '<<yxd>>' },
                        { id: '2', name: '<<yfk>>' },
                        { id: '3', name: '<<yfh>>' },
                        { id: '4', name: '<<yfh_1>>' },
                        { id: '5', name: '<<yqs>>' },
                        { id: '6', name: '<<yqx>>' },
                        { id: '7', name: '<<beforeClose>>' },
                    ],
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<dgykdsj>>',
                    controlConfig: {
                        placeholder: ['<<selectStartTime>>', '<<selectEndTime>>'],
                        style: { width: 300 },
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                guideMobile: {
                    type: 'string',
                    displayName: '<<dgysjh>>',
                },
                guideName: {
                    type: 'string',
                    displayName: '<<dgymc>>',
                },
                orderInfo: {
                    type: 'object',
                    properties: {
                        createTime: {
                            type: 'string',
                            displayName: '<<xdsj>>',
                        },
                        mainOrderNo: {
                            type: 'string',
                            displayName: '<<zddbh>>',
                        },
                        memberMobile: {
                            type: 'string',
                            displayName: '<<member>>',
                        },
                        orderNo: {
                            type: 'string',
                            displayName: '<<orderNumber>>',
                        },
                        externalOrderNo: {
                            type: 'string',
                            displayName: '<<wbddh>>',
                        },
                        payableAmount: {
                            type: 'string',
                            displayName: '<<orderAmount>>',
                        },
                        statusName: {
                            type: 'string',
                            displayName: '<<ddzt>>',
                        },
                        subsite: {
                            type: 'object',
                            properties: {
                                name: {
                                    type: 'string',
                                    displayName: '<<ddszmd>>',
                                },
                                merchant: {
                                    type: 'object',
                                    properties: {
                                        name: {
                                            type: 'string',
                                            displayName: '<<ddszzj>>',
                                        },
                                    },
                                },
                            },
                        },
                    },
                },
                guideCode: {
                    type: 'string',
                    displayName: '<<ygbm>>',
                },
                guideSubsiteName: {
                    type: 'string',
                    displayName: '<<guideSubsiteName>>',
                },
                guideMerchantName: {
                    type: 'string',
                    displayName: '<<dgyszzj>>',
                },
                shoppingListCreateTime: {
                    type: 'string',
                    displayName: '<<dgykdsj>>',
                },
            },
        },
        ShoppingListRuleEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/shopping_list_rule',
            properties: {
                shoppingListPrivilege: {
                    type: 'string.options.radio',
                    displayName: '<<dkxddgkxsfw>>',
                    options: [
                        { id: 'ALL', name: '<<scqbzssp>>' },
                        { id: 'MERCHANT_PRIVILEGE', name: '<<dgsszjsp>>' },
                    ],
                    rules: [
                        {
                            required: true,
                            message: '<<qxzdkxddgkxsfw>>',
                        },
                    ],
                    extra: '<<mrdgzdgdksmscqbzssp>>',
                },
                specialProductSettlementTips: {
                    type: 'string.text.paragraphTip',
                    displayName: '<<tsspjsts>>',
                    controlConfig: {
                        tipsModalConfig: {
                            type: 'extra',
                            extraConfig: {
                                before: '<<dkxdcj>>',
                            },
                            contentType: TipsModalContentType.ShoppingListTips,
                            showIcon: false,
                            text: '<<viewExample>>',
                            textColor: '#1890ff',
                            title: '',
                            width: 1048,
                        },
                        rows: 4,
                        maxLength: 400,
                    },
                },
                guideShoppingListDefaultTab: {
                    type: 'string.options.radio',
                    displayName: '<<dgdkdspmrxxksz>>',
                    options: [
                        { id: 'NORMAL', name: '<<cgsp>>' },
                        { id: 'QUICK_BILLING', name: '<<bigCode>>' },
                    ],
                    rules: [
                        {
                            required: true,
                            message: '<<qxzdgdkdspmrxxksz>>',
                        },
                    ],
                    extra: '<<kkzcdgdxcxjrdkxdmkdsplbymmrxzdyq>>',
                },
                guideShoppingListCustomFreight: {
                    type: 'string.options.radio',
                    displayName: '<<dgzdyyf>>',
                    defaultValue: 'true',
                    options: [
                        { id: 'true', name: '<<kq>>' },
                        { id: 'false', name: '<<gb>>' },
                    ],
                    rules: [
                        {
                            required: true,
                        },
                    ],
                },
                orderDelayedSync: {
                    type: 'object.subForm',
                    displayName: '<<ysrz>>',
                    properties: {
                        allowOrderDelayedSync: {
                            type: 'string.options.radio',
                            displayName: '<<dgkdzcysrz>>',
                            defaultValue: 'false',
                            options: [
                                { id: 'false', name: '<<no>>' },
                                { id: 'true', name: '<<yes>>' },
                            ],
                        },
                        orderDelayedAlias: {
                            type: 'string',
                            displayName: '<<ysrzywmc>>',
                            controlConfig: {
                                placeholder: '<<qsrysrzywmc>>',
                                maxLength: 10,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        allowChangesOrderDelayedSyncTime: {
                            type: 'string.options.radio',
                            displayName: '<<sfzcdgdxgrzrq>>',
                            defaultValue: 'false',
                            options: [
                                { id: 'false', name: '<<bzc>>' },
                                { id: 'true', name: '<<zc_2>>' },
                            ],
                        },
                    },
                },
            },
        },
        GuideShoppingConfigsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`,
            apiPath: '/loader/admin/guide_shopping_configs',
            filters: {
                subsiteIds: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                merchantIds: {
                    type: 'string.filterOptions.autoComplete',
                    displayName: '<<merchantName>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'subsiteIds',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                mode: {
                    type: 'string.options.select',
                    displayName: '<<kdmo>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'SHOP', name: '<<scspkd>>' },
                        { id: 'BIG_CODE', name: '<<spkd>>' },
                        { id: 'DEPOSIT', name: '<<djkd>>' },
                    ],
                    controlConfig: {
                        placeholder: '<<common.pleaseSelect>>',
                        style: { width: 150 },
                    },
                },
            },
            properties: {
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<baseSetting>>',
                    properties: {
                        modes: {
                            type: 'array.depositModeMenus',
                            displayName: '<<kdmo>>',
                            rules: [
                                {
                                    required: true,
                                },
                            ],
                        },
                        subsite: {
                            type: 'options.select.object',
                            displayName: '<<applicableSubsite>>',
                            options: [],
                            source: {
                                apiPath: '/admin/defaults/subsite',
                            },
                            rules: [
                                {
                                    required: true,
                                    message: '<<selectSubsite>>',
                                },
                            ],
                            controlConfig: {
                                style: {
                                    width: 400,
                                },
                                placeholder: '<<selectSubsite>>',
                                allowClear: true,
                                showSearch: true,
                            },
                        },
                        subsiteMerchants: {
                            type: 'object.deliveryLogisticsConfigsMerchants',
                            displayName: '<<kdmssyzg>>',
                            rules: [
                                {
                                    required: true,
                                },
                                {
                                    message: '<<pleaseSelectMerchant>>',
                                    validator: (rule: any, value: any) => {
                                        if (
                                            value &&
                                            value.selectMode &&
                                            value.selectMode === 'false' &&
                                            (!value.merchants || value.merchants.length === 0)
                                        ) {
                                            return false;
                                        }
                                        return true;
                                    },
                                },
                            ],
                        },
                    },
                },
                modes: {
                    type: 'string',
                    displayName: '<<kdmo>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<subsiteName>>',
                },
                merchants: {
                    type: 'array.popover',
                    displayName: '<<merchantName>>',
                },
            },
        },
    },
    components: {
        ShoppingListView: {
            component: 'Viewport',
            entity: 'ShoppingListEntity',
        },
        ShoppingListPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'tabs-content-viewport',
            items: [{ component: 'ShoppingListTabs' }],
        },
        ShoppingListTabs: {
            component: 'TabsPlus',
            type: 'card',
            cache: false,
            animated: false,
            items: [
                {
                    title: '<<dkxddd>>',
                    content: {
                        component: 'ShoppingListRecordsPage',
                    },
                },
                {
                    title: '<<szdkxdgz>>',
                    content: {
                        component: 'ShoppingListRulePage',
                    },
                },
                {
                    title: '<<kdmssz>>',
                    content: {
                        component: 'GuideShoppingConfigsPage',
                    },
                },
            ],
        },
        ShoppingListRecordsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ShoppingListFilter',
                },
                {
                    component: 'GridLayout',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'ExportDataByFilterParamsButton',
                            params: {
                                text: '<<dcxdjl>>',
                                style: {
                                    width: '120px',
                                },
                                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-ORDER`,
                                apiPath:
                                    '/admin/shopping_list_union_order_records/download?order_type=NORMAL',
                                requestMethod: 'post',
                                target: '_blank',
                                noFormatTime: true,
                            },
                        },
                    ],
                },
                { component: 'ShoppingListTable' },
            ],
        },
        ShoppingListFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'guideName',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'guideMobile',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'orderNo',
                    controlConfig: {
                        style: { width: 250 },
                    },
                },
                {
                    property: 'orderStatus',
                    controlConfig: {
                        style: { width: 150 },
                    },
                },
                {
                    property: 'dateRange',
                },
            ],
        },
        ShoppingListTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: {
                x: 1300,
            },
            columns: [
                {
                    property: 'guideName',
                    width: 120,
                },
                {
                    property: 'guideCode',
                    width: 120,
                },
                {
                    property: 'guideMobile',
                    width: 150,
                },
                {
                    property: 'guideSubsiteName',
                    width: 150,
                },
                {
                    property: 'guideMerchantName',
                    width: 150,
                },
                {
                    property: 'shoppingListCreateTime',
                    width: 150,
                },
                {
                    property: 'orderInfo.orderNo',
                    width: 240,
                },
                {
                    property: 'orderInfo.mainOrderNo',
                    width: 240,
                },
                {
                    property: 'orderInfo.externalOrderNo',
                    width: 240,
                },
                {
                    property: 'orderInfo.subsite.name',
                    width: 150,
                },
                {
                    property: 'orderInfo.subsite.merchant.name',
                    width: 150,
                },
                {
                    property: 'orderInfo.payableAmount',
                    width: 150,
                },
                {
                    property: 'orderInfo.statusName',
                    width: 150,
                },
                {
                    property: 'orderInfo.memberMobile',
                    width: 150,
                },
                {
                    property: 'orderInfo.createTime',
                    width: 240,
                },
            ],
        },
        ShoppingListRulePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [{ component: 'ShoppingListRuleForm' }],
        },
        ShoppingListRuleForm: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            entity: 'ShoppingListRuleEntity',
            loaderType: 'get',
            labelCol: 6,
            controlCol: 18,
            style: {
                height: '480px',
            },
            fields: [
                { property: 'shoppingListPrivilege' },
                {
                    property: 'guideShoppingListDefaultTab',
                    className: 'hidden',
                },
                {
                    property: 'guideShoppingListCustomFreight',
                },
                { property: 'specialProductSettlementTips' },
                { property: 'orderDelayedSync.allowOrderDelayedSync' },
                { property: 'orderDelayedSync.orderDelayedAlias', visible: allowOrderDelayedSync },
                {
                    property: 'orderDelayedSync.allowChangesOrderDelayedSyncTime',
                    visible: allowOrderDelayedSync,
                },
            ],
            footer: {
                items: [
                    {
                        htmlType: 'submit',
                        text: '<<common.save>>',
                    },
                ],
            },
            onValidate: (entityFields: any) => {
                const {
                    orderDelayedSync: { allowOrderDelayedSync, orderDelayedAlias },
                } = entityFields;
                if (allowOrderDelayedSync === 'true' && !orderDelayedAlias?.trim()) {
                    message.warning(services.language.getText('qtxysrzywmc'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                message.success(services.language.getText('common.saveSuccess'));
            },
        },
        GuideShoppingConfigsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            entity: 'GuideShoppingConfigsEntity',
            items: [
                {
                    component: 'GuideShoppingConfigsFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<xzkdmssz>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route:
                                        '/menu-list/market/shopping-list/add-guide-shopping-configs',
                                },
                            ],
                        },
                    ],
                },
                { component: 'GuideShoppingConfigsTable' },
            ],
        },
        GuideShoppingConfigsFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subsiteIds',
                },
                {
                    property: 'merchantIds',
                },
                {
                    property: 'mode',
                },
            ],
        },
        GuideShoppingConfigsTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'DataTable',
                    componentId: 'GuideShoppingConfigsTable',
                    loadFirstPage: true,
                    pagination: {
                        pageSize: 10,
                        showSizeChanger: true,
                        showQuickJumper: true,
                        pageSizeOptions: ['10', '15', '20'],
                        showTotal(total: number) {
                            return services.interpolate(services.language.getText('total'), {
                                total,
                            });
                        },
                    },
                    scroll: { x: true },
                    columns: [
                        {
                            property: 'subsiteName',
                        },
                        {
                            property: 'merchants',
                            width: 180,
                            displayConfig: {
                                replaceEmpty: '<<qbzj>>',
                                className: 'ant-table-cell-ellipsis',
                                style: {
                                    maxWidth: '180px',
                                },
                                countSuffix: '<<common.merchant>>',
                            },
                        },
                        { property: 'modes' },
                    ],
                    actionColumn: {
                        title: '<<common.tableAction>>',
                        className: 'action-column',
                        fixed: 'right',
                        width: 160,
                        items: [
                            {
                                type: 'link',
                                text: '<<common.edit>>',
                                statusKey: 'row.canEdit',
                                path:
                                    '/menu-list/market/shopping-list/edit-guide-shopping-configs/{{row.id}}',
                            },
                            {
                                type: 'delete',
                                text: '<<common.delete>>',
                                config: {
                                    type: 'link',
                                    tooltip: null,
                                    title: '<<confirmDelete>>',
                                    okText: '<<common.ok>>',
                                    cancelText: '<<common.cancel>>',
                                },
                            },
                        ],
                    },
                },
            ],
        },
        GuideShoppingConfigsAddPage: {
            component: 'FlexLayout',
            entity: 'GuideShoppingConfigsEntity',
            direction: 'horizontal',
            items: [{ component: 'GuideShoppingConfigsAddFormView' }],
        },
        GuideShoppingConfigsAddFormView: {
            component: 'CreateFormPlus',
            direction: 'horizontal',
            labelCol: 3,
            controlCol: 15,
            style: { width: 1200 },
            fields: [
                { property: 'baseInfo.modes' },
                { property: 'baseInfo.subsite' },
                {
                    property: 'baseInfo.subsiteMerchants',
                    visible: (values: any) => {
                        const subsite = get(values, 'baseInfo.subsite');
                        if (subsite && subsite.format !== 'SUPERMARKET') {
                            return true;
                        }
                        return false;
                    },
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const modes = get(entity, 'baseInfo.modes', []);
                const visibleModes = modes.filter((i: any) => i.status);
                if (!visibleModes || visibleModes.length === 0) {
                    message.warning(services.language.getText('zsgxygkdms'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                const saveSuccess = services.language.getText('common.saveSuccess');
                message.success(saveSuccess, () => {
                    services.behaviorHandle({ route: 'goBack' });
                });
            },
        },
        GuideShoppingConfigsEditPage: {
            component: 'FlexLayout',
            entity: 'GuideShoppingConfigsEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [{ component: 'GuideShoppingConfigsEditFormView' }],
        },
        GuideShoppingConfigsEditFormView: {
            component: 'ModifyFormPlus',
            direction: 'horizontal',
            loaderType: 'get',
            labelCol: 3,
            controlCol: 15,
            style: { width: 1200 },
            fields: [
                { property: 'baseInfo.modes' },
                { property: 'baseInfo.subsite' },
                {
                    property: 'baseInfo.subsiteMerchants',
                    visible: (values: any) => {
                        const subsite = get(values, 'baseInfo.subsite');
                        if (subsite && subsite.format !== 'SUPERMARKET') {
                            return true;
                        }
                        return false;
                    },
                },
            ],
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '<<common.cancel>>',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onValidate: (entity: any) => {
                const modes = get(entity, 'baseInfo.modes', []);
                const visibleModes = modes.filter((i: any) => i.status);
                if (!visibleModes || visibleModes.length === 0) {
                    message.warning(services.language.getText('zsgxygkdms'));
                    return false;
                }
                return true;
            },
            onSubmitSuccess: () => {
                const saveSuccess = services.language.getText('common.saveSuccess');
                message.success(saveSuccess, () => {
                    services.behaviorHandle({ route: 'goBack' });
                });
            },
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/shopping-list',
                    component: 'ShoppingListView',
                    breadcrumbName: '<<dkxd>>',
                    privilege: {
                        path: 'shoppingListUnionOrder',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add-guide-shopping-configs',
                            component: 'GuideShoppingConfigsAddPage',
                            breadcrumbName: '<<common.add>>',
                        },
                        {
                            path: '/edit-guide-shopping-configs/:id',
                            component: 'GuideShoppingConfigsEditPage',
                            breadcrumbName: '<<common.edit>>',
                        },
                        { path: '/', component: 'ShoppingListPage' },
                    ],
                },
            ],
        },
    ],
};
