import { Config } from '@comall-backend-builder/core/lib/parser';
import { api, errorHandle } from '@comall-backend-builder/core/lib/services';
import { EntityButtonProps } from '../../../components';
import { services } from '@comall-backend-builder/core';

let time = new Date().getTime() - 24 * 60 * 60 * 1000;
let yesterday: any = new Date(time);
let yesterdayDate =
    yesterday.getFullYear() +
    '-' +
    (yesterday.getMonth() + 1 > 9 ? yesterday.getMonth() + 1 : '0' + (yesterday.getMonth() + 1)) +
    '-' +
    (yesterday.getDate() > 9 ? yesterday.getDate() : '0' + yesterday.getDate());

export const config: Config = {
    entities: {
        wechatPublicUserCountsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
            apiPath: '/loader/admin/wechatPublicUserCount',
            filters: {
                wechatPublicId: {
                    displayName: '<<wechatOfficialAccount>>',
                    type: 'object.option',
                    className: 'custom-array-options',
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: {
                            width: 250,
                        },
                    },
                    options: [],
                    source: {
                        apiPath: '/admin/wechat_publics',
                    },
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<date>>',
                    defaultValue: { start: yesterdayDate, end: yesterdayDate },
                    controlConfig: {
                        placeholder: ['<<startTime>>', '<<endTime>>'],
                    },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },

                name: {
                    type: 'string',
                    displayName: '<<wechatOfficialAccount>>',
                },
                newAttention: {
                    type: 'string',
                    displayName: '<<xzgzrs>>',
                },
                subtractAttention: {
                    type: 'string',
                    displayName: '<<qxgzrs>>',
                },
                netIncreaseAttention: {
                    type: 'string',
                    displayName: '<<jzrs>>',
                },
                attentionCount: {
                    type: 'string',
                    displayName: '<<zgzrs>>',
                },
            },
        },
    },
    components: {
        wechatPublicUserCountView: {
            component: 'Viewport',
        },
        wechatPublicUserCountPage: {
            component: 'FlexLayout',
            entity: 'wechatPublicUserCountsEntity',
            direction: 'vertical',
            items: [
                {
                    component: 'wechatPublicUserCountFilter',
                },
                {
                    component: 'WechatPublicTotal',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    style: {
                        paddingTop: 10,
                        paddingBottom: 16,
                    },
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'EntityButton',
                                    props: {
                                        type: 'primary',
                                        children: '<<dcsj_1>>',
                                        style: { float: 'right' },
                                        onClick: (_: any, entityButtonProps: EntityButtonProps) => {
                                            const params: any = Object.assign(
                                                {},
                                                entityButtonProps.entity.params,
                                                entityButtonProps.entity.filters
                                            );

                                            if (params.dateRange) {
                                                params.startTime = params.dateRange.start;
                                                params.endTime = params.dateRange.end;
                                                delete params.dateRange;
                                            }
                                            if (params.wechatPublicId && params.wechatPublicId.id) {
                                                params.wechatPublicId = params.wechatPublicId.id;
                                            }
                                            if (params.wechatPublicId === '0') {
                                                delete params.wechatPublicId;
                                            }

                                            const config = {
                                                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                                                apiPath:
                                                    '/admin/wechat_public_follows/download_data',
                                            };
                                            api.download(params, config).catch(errorHandle);
                                        },
                                    },
                                },
                            ],
                        },
                    ],
                },
                {
                    component: 'wechatPublicUserCountTable',
                },
            ],
        },
        wechatPublicUserCountFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'dateRange',
                },
                {
                    property: 'wechatPublicId',
                },
            ],
        },
        wechatPublicUserCountTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'name',
                },
                {
                    property: 'newAttention',
                },
                {
                    property: 'subtractAttention',
                },
                {
                    property: 'netIncreaseAttention',
                },
                {
                    property: 'attentionCount',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/wechat-public-user-count',
                    component: 'wechatPublicUserCountView',
                    breadcrumbName: '<<wechatPublicUserCount>>',
                    privilege: {
                        path: 'wechatPublicUserCount',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/',
                            component: 'wechatPublicUserCountPage',
                        },
                    ],
                },
            ],
        },
    ],
};
