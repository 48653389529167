import { ArrayMode } from '@comall-backend-builder/types';
import React from 'react';
import WechatOfficialQRcodeReplyInput from './wechat-official-qrcode-reply-input';
import WechatOfficialQrcodeReplyDisplay from './wechat-official-qrcode-reply-display';

export default class WechatOfficialQRcodeReplyArrayMode extends ArrayMode {
    public getControlComponent(controlInfo: any): JSX.Element {
        return <WechatOfficialQRcodeReplyInput {...controlInfo} />;
    }
    public getDisplayComponent(_array: any, displayInfo: any): JSX.Element {
        return <WechatOfficialQrcodeReplyDisplay {...displayInfo} />;
    }
}
