import { PureComponent, createElement } from 'react';
import builder, { actions, services } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';

import { debounce, defaults, get, mapValues } from 'lodash';
import { checkLinkTypeParamsRequired } from '../../types/format';

export interface OpenScreenAdsEditFormProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
}

class OpenScreenAdsEditForm extends PureComponent<OpenScreenAdsEditFormProps> {
    static defaultProps: any = {};
    componentWillUnmount() {
        this.props.unmountComponent();
    }
    render() {
        const { entity, onSubmit, wrappedComponentRef } = this.props;
        let fields = [
            { property: 'information.name' },
            { property: 'information.showTime' },
            { property: 'rules.channels' },
            { property: 'rules.contentType' },
            {
                property: 'rules.video',
                visible: (values: any) => get(values, 'rules.contentType') === 'VIDEO',
            },
            {
                property: 'rules.target',
                visible: (values: any) => get(values, 'rules.contentType') === 'VIDEO',
            },
            {
                property: 'rules.picture',
                visible: (values: any) => get(values, 'rules.contentType') === 'PICTURE',
            },
            {
                property: 'rules.carousel',
                visible: (values: any) =>
                    get(values, 'rules.contentType') === 'PICTURE' &&
                    get(values, 'rules.picture') &&
                    get(values, 'rules.picture').length > 1,
            },
            {
                property: 'rules.interval',
                visible: (values: any) =>
                    get(values, 'rules.carousel') === 'CAROUSEL' &&
                    get(values, 'rules.contentType') === 'PICTURE' &&
                    get(values, 'rules.picture') &&
                    get(values, 'rules.picture').length > 1,
            },
            { property: 'rules.countdown' },
            { property: 'rules.position' },
            { property: 'rules.mode' },
            {
                property: 'rules.times',
                visible: (values: any) => get(values, 'rules.mode') === 'DAILY',
            },
        ];

        let formProps = {
            entity,
            componentId: 'OpenScreenAdsEditFormContainer',
            wrappedComponentRef,
            onSubmit,
            direction: 'horizontal',
            style: { width: 600 },
            labelCol: 6,
            controlCol: 18,
            fields: fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: services.language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    }
}

function mapDispatchToProps(_dispatch: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;
    let form: any;
    let scope = get(
        _dispatch,
        'components.DataFormPlus-OpenScreenAdsEditFormContainer.fields.scope'
    );
    /**
     * 提交前额外验证
     * @param entityFields
     */
    const validate = (entityFields: any) => {
        const contentType = get(entityFields, 'rules.contentType', null);
        const picture = get(entityFields, 'rules.picture', null);
        if (contentType === 'PICTURE') {
            if (!picture || !picture.length) {
                AntMessage.warning(services.language.getText('qtjggnr'));
                return false;
            } else if (picture.length) {
                let picStatus = '';
                picture.map((pic: any, index: number) => {
                    if (!pic.picture || (pic?.picture && pic?.picture.length <= 0)) {
                        picStatus = services.interpolate(services.language.getText('qtjdtggtp'), {
                            index: index + 1,
                        });
                    }
                    if (
                        pic?.config?.linkType &&
                        !checkLinkTypeParamsRequired(pic?.config?.linkType, pic?.config?.linkParams)
                    ) {
                        picStatus = services.interpolate(services.language.getText('qtjdtggljmb'), {
                            index: index + 1,
                        });
                    }
                    return pic;
                });
                if (picStatus) {
                    AntMessage.warning(picStatus);
                    return false;
                }
            }
        }
        const target = get(entityFields, 'rules.target', {});
        if (target?.linkType && !checkLinkTypeParamsRequired(target.linkType, target.linkParams)) {
            AntMessage.warning(services.language.getText('qtxljmb'));
            return false;
        }
        return true;
    };

    return defaults(
        {
            onSubmit,
            onFieldChange,
            scope,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: debounce((event: any, fields: any) => {
                const entityFields = mapValues(fields, (field, name) => {
                    return field.value;
                });
                if (entityFields) {
                    const hasValidate = validate(entityFields);
                    if (hasValidate) {
                        entity.modify(entityFields, params);
                    }
                }
                return false;
            }, 300),
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('OpenScreenAdsEditFormContainer'));
            },
        }
    );
}

export const OpenScreenAdsEditFormView = connect(mapDispatchToProps)(OpenScreenAdsEditForm);
