import { BaseConfig } from '../../../cae/interfaces';

export const config: BaseConfig = {
    entities: {
        ThemeStyleEntitys: {
            apiPath: '/loader/admin/theme_style',
            properties: {
                id: {
                    type: 'string',
                },
                navigation: {
                    type: 'object.subForm',
                    displayName: '<<topNav>>',
                    properties: {
                        style: {
                            type: 'object.themeStyleNavigationSelector',
                            displayName: '<<pageStyle>>',
                            extra: '<<settingToWxMiniP>>',
                        },
                    },
                },
                themeStyle: {
                    type: 'object.subForm',
                    displayName: '<<components.PageDesign.pageSetting>>',
                    placeholder: '<<themeStyleTips>>',
                    properties: {
                        styleMode: {
                            type: 'string.options.radio',
                            displayName: '<<layoutStyle>>',
                            defaultValue: '0',
                            options: [
                                { id: '0', name: '<<classical>>' },
                                { id: '1', name: '<<simplicity>>' },
                            ],
                        },
                        color: {
                            type: 'object.themeStyleFontSelector',
                            displayName: '<<fontStyle>>',
                        },
                        button: {
                            type: 'object.themeStyleButtonSelector',
                            displayName: '<<highLightButton>>',
                        },
                        backgroundImage: {
                            type: 'string.image.picker',
                            displayName: '<<pageBackground>>',
                            controlConfig: {
                                fileName: 'fileName',
                                uploadType: 'single',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                                maxCount: 1,
                                limitTips: {
                                    limitText: '<<backgroundImgLimit>>',
                                    limitUnit: ' ',
                                    className: 'image-picker-tips-ext',
                                },
                            },
                        },
                    },
                },
                advancedSetting: {
                    type: 'object.subForm',
                    displayName: '<<seniorSetting>>',
                    // collapsed: true,
                    properties: {
                        hideTitlePages: {
                            type: 'object.themeStyleAdvancedSelector',
                            displayName: '<<page>>',
                        },
                    },
                },
            },
        },
    },
    components: {
        ThemeStyle: {
            component: 'Viewport',
        },
        ThemeStylePages: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'ThemeStylePanes',
                },
            ],
        },
        ThemeStylePanes: {
            component: 'FlexLayout',
            entity: 'ThemeStyleEntitys',
            loaderType: 'get',
            items: [
                { component: 'ThemeStylePreview' },
                {
                    component: 'ThemeStyleFormView',
                },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/theme-style',
                    component: 'ThemeStyle',
                    breadcrumbName: '<<themeStyle>>',
                    privilege: {
                        path: 'themeStyle',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/',
                            component: 'ThemeStylePages',
                        },
                    ],
                },
            ],
        },
    ],
};
