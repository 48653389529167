export const config = {
    entities: {
        GoodsSelectorEntity: {
            apiPath: '/loader/admin/goods',
            properties: {
                id: {
                    type: 'string',
                    format: 'title',
                },
                name: {
                    type: 'string',
                    displayName: '<<components.Products.name>>',
                },
                brandName: {
                    type: 'string',
                    displayName: '<<brandName>>',
                },
                productInfo: {
                    type: 'object.picAndNameAndCode',
                    displayName: '<<productInformation>>',
                },
                goodsMvo: {
                    type: 'object',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<goodName>>',
                        },
                        productId: {
                            type: 'string',
                        },
                        spuCode: {
                            type: 'string',
                            displayName: '<<productCode>>',
                        },
                        subsiteName: {
                            type: 'string',
                            displayName: '<<subsiteName>>',
                        },
                        merchantName: {
                            type: 'string',
                            displayName: '<<merchantName>>',
                        },
                        stock: {
                            type: 'string',
                            displayName: '<<sjkc>>',
                        },
                        csku: {
                            type: 'string',
                            displayName: '<<goodCode>>',
                        },
                        id: {
                            type: 'number',
                            displayName: '<<goodId>>',
                        },
                        productStyleName: {
                            type: 'string',
                            displayName: '<<styleGroupInfo>>',
                        },
                        salesPrice: {
                            type: 'object',
                            properties: {
                                value: {
                                    type: 'string',
                                    displayName: '<<price>>',
                                },
                            },
                        },
                        shelfStatus: {
                            type: 'string.options.select',
                            displayName: '<<status>>',
                            options: [
                                {
                                    id: 'NONE',
                                    name: '<<notListed>>',
                                },
                                {
                                    id: 'OFF_SHELF',
                                    name: '<<alreadyOffShelf>>',
                                },
                                {
                                    id: 'SHELF',
                                    name: '<<alreadyShelf>>',
                                },
                            ],
                        },
                    },
                },
                supply: {
                    type: 'string.options.select',
                    displayName: '<<supplierName>>',
                    options: [
                        { id: 'ERP', name: 'ERP' },
                        { id: 'HOU_NIAO', name: '<<houniao>>' },
                        { id: 'OU_PAI', name: '<<op>>' },
                    ],
                },
                tradeType: {
                    type: 'string.options.select',
                    displayName: '<<tradeType>>',
                    options: [
                        { id: 'DOMESTIC_TRADE', name: '<<domesticTrade>>' },
                        { id: 'DUTY_PAID_IMPORT', name: '<<dutyPaidImport>>' },
                        { id: 'BONDED_DIRECT_SUPPLY', name: '<<bondedDirectSupply>>' },
                        { id: 'HONG_KONG_DIRECT', name: '<<hongkongMail>>' },
                        { id: 'OVERSEAS_DIRECT', name: '<<overseasDirectMail>>' },
                    ],
                },
            },
            filters: {
                keywords: {
                    type: 'string',
                    displayName: '<<keywordsSearch>>',
                    controlConfig: {
                        placeholder: '<<keywordsPlaceholder>>',
                        style: { width: 300 },
                    },
                },
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                merchantId: {
                    type: 'string.filterOptions.autoComplete',
                    displayName: '专柜',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                        //专柜默认正序排序
                        params: {
                            sequence: 'code',
                            order: 'asc',
                        },
                    },
                    controlConfig: {
                        placeholder: '<<selectPlease>>',
                        style: { width: 170 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                name: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<components.Products.name>>',
                },
                codes: {
                    type: 'string',
                    displayName: '<<productCode>>',
                },
                // codes查询货品编码，code查询商品编码
                code: {
                    type: 'string',
                    displayName: '<<productCode>>',
                },
                shelfStatus: {
                    type: 'string.options.select',
                    displayName: '商品状态',
                    options: [
                        {
                            id: 'NONE',
                            name: '<<notListed>>',
                        },
                        {
                            id: 'OFF_SHELF',
                            name: '<<alreadyOffShelf>>',
                        },
                        {
                            id: 'SHELF',
                            name: '<<alreadyShelf>>',
                        },
                    ],
                    controlConfig: {
                        style: { width: 100 },
                    },
                },
                supplyChain: {
                    type: 'string.options.select',
                    displayName: '<<supplierName>>',
                    options: [
                        { id: 'ERP', name: 'ERP' },
                        { id: 'THIRD_PARTY', name: '三方供应链' },
                    ],
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
                tradeType: {
                    type: 'string.options.select',
                    displayName: '<<tradeType>>',
                    options: [
                        { id: 'DOMESTIC_TRADE', name: '<<domesticTrade>>' },
                        { id: 'DUTY_PAID_IMPORT', name: '<<dutyPaidImport>>' },
                        { id: 'BONDED_DIRECT_SUPPLY', name: '<<bondedDirectSupply>>' },
                        { id: 'HONG_KONG_DIRECT', name: '<<hongkongMail>>' },
                        { id: 'OVERSEAS_DIRECT', name: '<<overseasDirectMail>>' },
                    ],
                    controlConfig: { placeholder: '<<selectPlease>>', style: { width: 170 } },
                },
            },
        },
        ActivityLogsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/activities_logs',
            properties: {
                id: {
                    type: 'string',
                },
                time: {
                    type: 'string',
                    displayName: '<<zxsj>>',
                },
                operationTypes: {
                    type: 'array.list.item.texts',
                    displayName: '<<bgx>>',
                },
                oldValues: {
                    type: 'array.list.item.texts',
                    displayName: '<<bgq>>',
                },
                newValues: {
                    type: 'array.list.item.texts',
                    displayName: '<<bgh>>',
                },
                operator: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
            },
        },
        OperationLogsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/operation_logs',
            properties: {
                id: {
                    type: 'string',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<zxsj>>',
                },
                operationType: {
                    type: 'string',
                    displayName: '<<bgx>>',
                },
                operationItems: {
                    type: 'array.list.item.texts',
                    displayName: '<<bgx>>',
                },
                createUser: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
            },
        },
        SalesElectronicLogsEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}`,
            apiPath: '/loader/activities_logs',
            properties: {
                id: {
                    type: 'string',
                },
                creatorName: {
                    type: 'string',
                    displayName: '<<lastModifiedUser>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<czsj>>',
                },
                auditStatus: {
                    type: 'string.options.select',
                    displayName: '<<auditStatus>>',
                    options: [
                        {
                            id: 'APPROVED',
                            name: '<<approved>>',
                        },
                        {
                            id: 'REJECTED',
                            name: '<<shbtg>>',
                        },
                        {
                            id: 'AUDITING',
                            name: '审核中',
                        },
                        {
                            id: 'WAITING',
                            name: '<<waiting>>',
                        },
                    ],
                },
                auditRemark: {
                    type: 'string',
                    displayName: '审核失败原因',
                },
            },
        },
        PaymentResultEntity: {
            apiPath: '/loader/admin/offline_payment/payment_result',
            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-PAYMENT`,
            properties: {
                id: {
                    type: 'string',
                },
                bankCardNo: {
                    type: 'string',
                    displayName: '<<yxkh>>',
                },
                paidTime: {
                    type: 'string',
                    displayName: '交易日期',
                },
                outTradeNo: {
                    type: 'string',
                    displayName: '<<jylsh>>',
                },
                paidAmount: {
                    type: 'string',
                    displayName: '交易金额',
                },
                reviewable: {
                    displayName: '是否可复核',
                    type: 'string.options.select',
                    options: [
                        {
                            id: 'true',
                            name: '可复核',
                        },
                        {
                            id: 'false',
                            name: '不可复核',
                        },
                    ],
                },
            },
            filters: {
                bankCardNo: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<yxkh>>',
                    // rules: [{ required: true }],
                },
                date: {
                    type: 'string.date',
                    displayName: '交易日期',
                },
                outTradeNo: {
                    type: 'string',
                    format: 'trimString',
                    displayName: '<<jylsh>>',
                },
            },
        },
    },
};
