import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { MerchantsSelectorBySubsites } from './merchants-selector-by-subsites';

export class MerchantsSelectorBySubsitesMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <MerchantsSelectorBySubsites {...displayInfo} />;
    }
}
