import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';

const api = services.api;
export const PhotoCreditReviewLogsLoader: Loader = {
    get: async function(data, config) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MEMBER-BENEFITS`;
        config.apiPath = '/admin/photo_crediting/' + data.id + '/record_logs';
        delete data.id;
        return await api.get(data, config).then((res: any) => {
            return res;
        });
    },
};
