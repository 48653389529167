import React, { PureComponent, Fragment } from 'react';
import { cloneDeep, get, isFunction } from 'lodash';
import { LinkTypeSelector } from './link-type-selector';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
import { LinkTypeMode } from '@comall-backend-builder/design-components-basis/lib/types/link-type-mode';
import { ProductSelector } from '../../tools/product-selector';
import { TopicSelector } from '../../design/components/custom-select-topic';
import CustomUrlLink from '../../design/components/custom-url-link';
import { getI18NLinkNameById } from '../../../../components/link-type-effect-path/config';
/**
 * 可视化-配置链接类型
 */
export class LinkType extends PureComponent<{
    selector: {
        [linkType: string]: { render: (props: any) => JSX.Element };
    };
    onChange: (value: any) => void;
    value: { linkType: string; linkParams: any };
    linkTypes: Array<{ id: string; name: string }>;
    linkTypeMode?: LinkTypeMode;
    subsiteIds?: string[];
}> {
    static defaultProps = {
        selector: {},
    };

    changeType = (type: any) => {
        const { onChange, value } = this.props;
        let newValue = cloneDeep(value);
        newValue.linkType = type;
        newValue.linkParams = null;
        onChange(newValue);
    };

    changeTarget = (linkParams: Record<string, any>) => {
        const { value, onChange } = this.props;
        let newValue = cloneDeep(value);
        newValue.linkParams = linkParams;
        onChange(newValue);
    };

    getLinkTypeName = () => {
        const {
            value: { linkType },
        } = this.props;
        if (!linkType) {
            return '';
        }
        // let objType = find(linkTypes, { id: linkType });
        return getI18NLinkNameById(linkType);
    };

    renderLinkTarget = () => {
        const { selector, value, subsiteIds } = this.props;
        const { linkType, linkParams } = value;
        const selectorProps = {
            value: { linkType, linkParams },
            onChange: this.changeTarget,
        };
        switch (linkType) {
            case 'product':
                const customRenderProduct = get(selector, 'singleProduct.render');
                const nextProps = {
                    ...selectorProps,
                    subsiteIds: subsiteIds,
                };
                return isFunction(customRenderProduct) ? (
                    customRenderProduct(nextProps)
                ) : (
                    <ProductSelector
                        {...selectorProps}
                        type={'Single'}
                        contentConfig={get(selector, 'singleProduct.contentConfig')}
                        modalConfig={get(selector, 'singleProduct.modalConfig')}
                    />
                );
            case 'topic':
                const customRenderTopic = get(selector, 'topic.render');
                return isFunction(customRenderTopic) ? (
                    customRenderTopic(selectorProps)
                ) : (
                    <TopicSelector
                        {...selectorProps}
                        contentConfig={get(selector, 'topic.contentConfig')}
                        modalConfig={get(selector, 'topic.modalConfig')}
                    />
                );
            case 'link':
                const customRenderCustomUrl = get(selector, 'customUrl.render');
                return isFunction(customRenderCustomUrl) ? (
                    customRenderCustomUrl(selectorProps)
                ) : (
                    <CustomUrlLink
                        {...selectorProps}
                        modalConfig={get(selector, 'customUrl.modalConfig')}
                    />
                );
            // FIXEME: linkType优化传参部分，后续合并到Tools.Linktype
            default:
                const customRender = get(selector[linkType], 'render');
                const customContentConfig = get(selector[linkType], 'contentConfig');
                const customModalConfig = get(selector[linkType], 'modalConfig');
                const customProps = {
                    ...selectorProps,
                    modalConfig: customModalConfig,
                    contentConfig: customContentConfig,
                    subsiteIds: subsiteIds,
                };
                return isFunction(customRender) ? (
                    customRender(customProps)
                ) : (
                    <span>{this.getLinkTypeName()}</span>
                );
        }
    };

    render() {
        const { value, linkTypes, linkTypeMode } = this.props;
        return (
            <Fragment>
                <div className="link-item-field">
                    <label className="field-name">
                        {language.getText('components.Tools.linkType')}：
                    </label>
                    <LinkTypeSelector
                        value={value}
                        linkTypes={linkTypes}
                        linkTypeMode={linkTypeMode}
                        onChange={this.changeType}
                    />
                </div>
                <div className="link-item-field">
                    <label className="field-name">
                        {language.getText('components.Tools.linkTarget')}：
                    </label>
                    {this.renderLinkTarget()}
                </div>
            </Fragment>
        );
    }
}
