import { Config } from '@comall-backend-builder/core/lib/parser';

export let config: Config = {
    apiRoot: ENV.API_ROOT,

    entities: {
        configInfo: {
            apiRoot: ENV.API_ROOT,
            apiPath: '/admin/backend_config',
            properties: {
                picDomain: { type: 'string' },
                frameLogoFileUrl: { type: 'string' },
                frameCopyRight: { type: 'string' },
            },
        },
    },

    components: {
        SideNavFrame: {
            entity: 'configInfo',
            loaderType: 'get',
            component: 'Frame',
            type: 'side-nav',
            style: {
                minHeight: '100%',
            },
            sider: {
                fixed: true,
                items: [
                    {
                        component: 'HeaderLogoContainer',
                    },
                    {
                        component: 'SideNav',
                        mode: 'inline',
                        theme: 'dark',
                        className: 'sideNav',
                        items: [
                            {
                                type: 'item',
                                key: 'dashboard',
                                title: 'Dashboard',
                                route: '/dashboard',
                                icon: 'dashboard',
                                privilege: {
                                    path: 'dashboard',
                                    level: 'full',
                                },
                            },
                            /*{
                                type: 'item',
                                key: 'tenantManage',
                                title: '租户',
                                route: '/tenant-manage',
                                privilege: {
                                    path: 'tenant',
                                    level: 'full',
                                },
                            },*/
                            {
                                type: 'menu',
                                key: 'store',
                                title: '<<subsites>>',
                                icon: 'shop',
                                privilege: {
                                    path: 'store',
                                    level: 'full',
                                },
                                items: [
                                    {
                                        type: 'item',
                                        key: 'subsite-manage',
                                        title: '<<manageSubsiteInformation>>',
                                        route: '/subsite',
                                        privilege: {
                                            path: 'storeManager',
                                            level: 'full',
                                        },
                                    },
                                ],
                            },
                            {
                                type: 'menu',
                                key: 'cms',
                                title: 'CMS',
                                icon: 'form',
                                privilege: {
                                    path: 'cms',
                                    level: 'full',
                                },
                                items: [
                                    {
                                        type: 'item',
                                        key: 'pageTemplate',
                                        title: '<<components.NavMenu.templatesPageManagement>>',
                                        route: '/page-template-manage',
                                        privilege: {
                                            path: 'pageTemplate',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'menu',
                                        key: 'pageManager',
                                        title: '<<decorationPage>>',
                                        privilege: {
                                            path: 'pageManager',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'platformPageManage',
                                                title: '<<managePlatformHomepage>>',
                                                route: '/platform-page-manage',
                                                privilege: {
                                                    path: 'platformHomePage',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'pageManage',
                                                title: '<<components.NavMenu.homePagesManagement>>',
                                                route: '/page-manage',
                                                privilege: {
                                                    path: 'homePage',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'popUpAds',
                                                title: '<<managePopup>>',
                                                route: '/pop-up-ads',
                                                privilege: {
                                                    path: 'popUpAds',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'popUpContent',
                                                title: '<<manageContentPopup>>',
                                                route: '/pop-up-content',
                                                privilege: {
                                                    path: 'showPopUpAds',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'topicManage',
                                                title: '<<manageSpecialActivity>>',
                                                route: '/topic-manage',
                                                privilege: {
                                                    path: 'topicPage',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'mallActivityDesign',
                                                title: '<<manageMallActivity>>',
                                                route: '/mall-activity-design',
                                                privilege: {
                                                    path: 'mallActivityPage',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'designMember',
                                                title: '<<designMember>>',
                                                route: '/design-member',
                                                privilege: {
                                                    path: 'memberPages',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'designContentMember',
                                                title: '<<designContentMember>>',
                                                route: '/design-content-member',
                                                privilege: {
                                                    path: 'showMemberPages',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'designContentService',
                                                title: '<<designContentService>>',
                                                route: '/design-content-service',
                                                privilege: {
                                                    path: 'showServicePages',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'guideManager',
                                        title: '<<guideManager>>',
                                        privilege: {
                                            path: 'guideManager',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'guide',
                                                title: '<<guide>>',
                                                route: '/guide',
                                                privilege: {
                                                    path: 'guide',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'article',
                                        title: '<<article>>',
                                        privilege: {
                                            path: 'article',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'articleCategory',
                                                title: '<<articleCategory>>',
                                                route: '/articleCategory',
                                                privilege: {
                                                    path: 'articleCategory',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'articleManager',
                                                title: '<<articleManager>>',
                                                route: '/article',
                                                privilege: {
                                                    path: 'articleManager',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'appSection',
                                                title: '<<appSection>>',
                                                route: '/app-section',
                                                privilege: {
                                                    path: 'appSection',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'item',
                                        key: 'activityTag',
                                        title: '<<activityTag>>',
                                        route: '/mall-activity-tag',
                                        privilege: {
                                            path: 'activityTag',
                                            level: 'full',
                                        },
                                    },
                                ],
                            },
                            {
                                type: 'menu',
                                key: 'decorate',
                                title: '<<decorate>>',
                                icon: 'bg-colors',
                                privilege: {
                                    path: 'decorate',
                                    level: 'full',
                                },
                                items: [
                                    {
                                        type: 'item',
                                        key: 'decorateTemplate',
                                        title: '<<components.NavMenu.templatesPageManagement>>',
                                        route: '/new-page-template-manage',
                                        privilege: {
                                            path: 'decorateTemplate',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'menu',
                                        key: 'shoppingPage',
                                        title: '<<shoppingPage>>',
                                        privilege: {
                                            path: 'shoppingPage',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'platformPage',
                                                title: '<<platformPage>>',
                                                route: '/platform-page',
                                                privilege: {
                                                    path: 'platformPage',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'storePage',
                                                title: '<<storePage>>',
                                                route: '/store-page',
                                                privilege: {
                                                    path: 'storePage',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'merchantPageNew',
                                                title: '<<merchantPageNew>>',
                                                route: '/merchant-page',
                                                privilege: {
                                                    path: 'merchantPageNew',
                                                    level: 'full',
                                                },
                                            },

                                            {
                                                type: 'item',
                                                key: 'customPage',
                                                title: '<<customPage>>',
                                                route: '/topic-page',
                                                privilege: {
                                                    path: 'customPage',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'creditEshopPage',
                                                title: '积分商城',
                                                route: '/credit-eshop-page',
                                                privilege: {
                                                    path: 'creditEshopPage',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'memberCenterPage',
                                                title: '<<memberCenterPage>>',
                                                route: '/member-center-page',
                                                privilege: {
                                                    path: 'memberCenterPage',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'menu',
                                                key: 'genericComponent',
                                                title: '<<genericComponent>>',
                                                privilege: {
                                                    path: 'genericComponent',
                                                    level: 'full',
                                                },
                                                items: [
                                                    {
                                                        type: 'item',
                                                        key: 'customStoreTabbar',
                                                        title: '<<customStoreTabbar>>',
                                                        route: '/custom-store-tabbar',
                                                        privilege: {
                                                            path: 'tabBar',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'floatingActionButton',
                                                        title: '<<floatingActionButton>>',
                                                        route: '/floating-action-button',
                                                        privilege: {
                                                            path: 'floatingActionButton',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'newClientPopUpAds',
                                                        title: '<<newClientPopUpAds>>',
                                                        route: '/new-pop-up-ads',
                                                        privilege: {
                                                            path: 'newClientPopUpAds',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'splashAds',
                                                        title: '<<splashAds>>',
                                                        route: '/open-screen-ads',
                                                        privilege: {
                                                            path: 'splashAds',
                                                            level: 'full',
                                                        },
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'menu',
                                                key: 'searchPage',
                                                title: '<<searchPage>>',
                                                privilege: {
                                                    path: 'searchPage',
                                                    level: 'full',
                                                },
                                                items: [
                                                    {
                                                        type: 'item',
                                                        key: 'platformSearchPage',
                                                        title: '<<platformSearchPage>>',
                                                        route: '/platform-search-page',
                                                        privilege: {
                                                            path: 'platformSearchPage',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'storeSearchPage',
                                                        title: '<<storeSearchPage>>',
                                                        route: '/store-search-page',
                                                        privilege: {
                                                            path: 'storeSearchPage',
                                                            level: 'full',
                                                        },
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'item',
                                                key: 'payResultPage',
                                                title: '<<payResultPage>>',
                                                route: '/pay-result-page',
                                                privilege: {
                                                    path: 'payResultPage',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'posReceiptsPage',
                                                title: '<<posReceiptsPage>>',
                                                route: '/electronic-bill-page',
                                                privilege: {
                                                    path: 'posReceiptsPage',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'creditShopPage',
                                                title: '<<creditEShop>>',
                                                route: '/credit-shop-page',
                                                privilege: {
                                                    path: 'creditShopPage',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'carparkPage',
                                                title: '<<tcjf_1>>',
                                                route: '/carpark-page',
                                                privilege: {
                                                    path: 'carparkPage',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'item',
                                        key: 'themeStyle',
                                        title: '<<themeStyle>>',
                                        route: '/theme-style',
                                        privilege: {
                                            path: 'themeStyle',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'managementArticle',
                                        title: '<<article>>',
                                        route: '/article-manager',
                                        privilege: {
                                            path: 'managementArticle',
                                            level: 'full',
                                        },
                                    },
                                ],
                            },
                            {
                                type: 'menu',
                                key: 'merchant',
                                title: '<<merchant>>',
                                icon: 'appstore',
                                privilege: {
                                    path: 'merchant',
                                    level: 'full',
                                },
                                items: [
                                    {
                                        type: 'menu',
                                        key: 'merchantManager',
                                        title: '<<merchantManager>>',
                                        privilege: {
                                            path: 'merchantManager',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'storeInfo',
                                                title: '<<merchantManager>>',
                                                route: '/merchant-self',
                                                privilege: {
                                                    path: 'storeInfo',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'merchantBigCodeManager',
                                                title: '<<merchantBigCodeManager>>',
                                                route: '/merchant-big-codes',
                                                privilege: {
                                                    path: 'merchantBigCodeManager',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'subsiteTag',
                                                title: '<<merchantTag>>',
                                                route: '/merchant-tag',
                                                privilege: {
                                                    path: 'subsiteTag',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'cashDepositDetail',
                                                title: '<<cashDepositDetail>>',
                                                route: '/cashDepositDetail',
                                                privilege: {
                                                    path: 'cashDepositDetail',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'merchantConfig',
                                        title: '<<merchantConfig>>',
                                        privilege: {
                                            path: 'merchantConfig',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'shopPageManage',
                                                title: '<<components.NavMenu.homePagesManagement>>',
                                                route: '/merchant-page-manage',
                                                privilege: {
                                                    path: 'merchantPage',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'item',
                                        key: 'merchantUser',
                                        title: '<<merchantUser>>',
                                        route: '/merchant-user',
                                        privilege: {
                                            path: 'merchantUser',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'selfMerchantVirtualCategory',
                                        title: '<<selfMerchantVirtualCategory>>',
                                        route: '/virtual-category-self',
                                        privilege: {
                                            path: 'selfMerchantVirtualCategory',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'arbitrationBillManager',
                                        title: '<<arbitrationBillManager>>',
                                        route: '/arbitrationbill',
                                        privilege: {
                                            path: 'arbitrationBillManager',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'authorizeMemberManagement',
                                        title: '<<authorizeMemberManagement>>',
                                        route: '/authorize-member-management',
                                        privilege: {
                                            path: 'authorizeMemberManagement',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'menu',
                                        key: 'merchantManagerFacility',
                                        title: '<<merchantManagerFacility>>',
                                        privilege: {
                                            path: 'facilityManagement',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'facility',
                                                title: '<<merchantManagerFacility>>',
                                                route: '/merchant-facility',
                                                privilege: {
                                                    path: 'facility',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'facilityTag',
                                                title: '<<facilityTag>>',
                                                route: '/merchant-facility-tag',
                                                privilege: {
                                                    path: 'facilityTag',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                type: 'menu',
                                key: 'product',
                                title: '<<components.ComponentSelector.products>>',
                                icon: 'shopping',
                                privilege: {
                                    path: 'product',
                                    level: 'full',
                                },
                                items: [
                                    {
                                        type: 'menu',
                                        key: 'categories',
                                        title: '<<categories>>',
                                        privilege: {
                                            path: 'categories',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'brand',
                                                title: '<<brand>>',
                                                route: '/brand',
                                                privilege: {
                                                    path: 'brand',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'category',
                                                title: '<<category>>',
                                                route: '/category',
                                                privilege: {
                                                    path: 'category',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'virtualCategory',
                                                title: '<<virtualCategory>>',
                                                route: '/virtual-category',
                                                privilege: {
                                                    path: 'virtualCategory',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'item',
                                        key: 'specification',
                                        title: '<<specification>>',
                                        route: '/specification',
                                        privilege: {
                                            path: 'specification',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'menu',
                                        key: 'createSelfProduct',
                                        title: '<<createSelfProduct>>',
                                        privilege: {
                                            path: 'createSelfProduct',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'productinfo',
                                                title: '<<productinfo>>',
                                                route: '/product-info',
                                                privilege: {
                                                    path: 'productinfo',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'goods',
                                                title: '<<goods>>',
                                                route: '/goods',
                                                privilege: {
                                                    path: 'goods',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'weightGoods',
                                                title: '<<weightGoods>>',
                                                route: '/weight-goods',
                                                privilege: {
                                                    path: 'weightGoods',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'subsiteStyleGroup',
                                                title: '<<subsiteStyleGroup>>',
                                                route: '/subsite-style-group',
                                                privilege: {
                                                    path: 'subsiteStyleGroup',
                                                    level: 'full',
                                                },
                                            },
                                            /*{
                                                type: 'item',
                                                key: 'productTemplate',
                                                title: '产品模板管理',
                                                route: '/productTemplate',
                                                privilege: {
                                                    path: 'productTemplate',
                                                    level: 'full',
                                                },
                                            },*/
                                        ],
                                    },
                                    // {
                                    //     type: 'menu',
                                    //     key: 'virtualProductConf',
                                    //     title: '创建虚拟商品',
                                    //     privilege: {
                                    //         path: 'virtualProductConf',
                                    //         level: 'full',
                                    //     },
                                    //     items: [
                                    //         {
                                    //             type: 'item',
                                    //             key: 'virtualProduct',
                                    //             title: '虚拟商品资料中心',
                                    //             route: '/virtual-product',
                                    //             privilege: {
                                    //                 path: 'virtualProduct',
                                    //                 level: 'full',
                                    //             },
                                    //         },
                                    //         {
                                    //             type: 'item',
                                    //             key: 'virtualGoods',
                                    //             title: '虚拟商品经营管理',
                                    //             route: '/virtualGoods',
                                    //             privilege: {
                                    //                 path: 'virtualGoods',
                                    //                 level: 'full',
                                    //             },
                                    //         },
                                    //     ],
                                    // },
                                    {
                                        type: 'item',
                                        key: 'virtualProductConf',
                                        title: '<<virtualProductConf>>',
                                        route: '/sales-electronic-coupon',
                                        privilege: {
                                            path: 'virtualProductConf',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'combineGoods',
                                        title: '<<combineGoods>>',
                                        route: '/combine-goods',
                                        privilege: {
                                            path: 'combineGoods',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'inventoryRule',
                                        title: '<<inventoryRule>>',
                                        route: '/inventory-rule',
                                        privilege: {
                                            path: 'inventoryRule',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'goodsShelf',
                                        title: '<<goodsShelf>>',
                                        route: '/goodsShelf',
                                        privilege: {
                                            path: 'goodsShelf',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'menu',
                                        key: 'createMerchantProduct',
                                        title: '<<createMerchantProduct>>',
                                        privilege: {
                                            path: 'createMerchantProduct',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'merchantProductInfoMananger',
                                                title: '<<merchantProductInfoMananger>>',
                                                route: '/merchantProductInfoMananger',
                                                privilege: {
                                                    path: 'merchantProductInfoMananger',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'merchantGoodsManager',
                                                title: '<<merchantGoodsManager>>',
                                                route: '/merchantGoodsManager',
                                                privilege: {
                                                    path: 'merchantGoodsManager',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'merchantStyleGroup',
                                                title: '<<subsiteStyleGroup>>',
                                                route: '/merchant-style-group',
                                                privilege: {
                                                    path: 'merchantStyleGroup',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'auditMerchantProduct',
                                        title: '<<auditMerchantProduct>>',
                                        privilege: {
                                            path: 'auditMerchantProduct',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'merchantProductInfo',
                                                title: '<<merchantProductInfo>>',
                                                route: '/merchantProductInfo',
                                                privilege: {
                                                    path: 'merchantProductInfo',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'merchantGoods',
                                                title: '<<merchantGoods>>',
                                                route: '/merchantGoods',
                                                privilege: {
                                                    path: 'merchantGoods',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'promotionTag',
                                        title: '<<promotionTag>>',
                                        privilege: {
                                            path: 'promotionTag',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'goodsTag',
                                                title: '<<promotionTag>>',
                                                route: '/tag',
                                                privilege: {
                                                    path: 'goodsTag',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'tagShow',
                                                title: '<<tagShow>>',
                                                route: '/tagShow',
                                                privilege: {
                                                    path: 'tagShow',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'virtualProductVirtualCategory',
                                        title: '<<virtualProductVirtualCategory>>',
                                        privilege: {
                                            path: 'virtualProductVirtualCategory',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'virtualCouponProductVirtualCategory',
                                                title: '<<virtualCouponProductVirtualCategory>>',
                                                route: '/virtualCouponProductVirtualCategory',
                                                privilege: {
                                                    path: 'virtualCouponProductVirtualCategory',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'item',
                                        key: 'salesPlan',
                                        title: '<<salesPlan>>',
                                        route: '/salesPlans',
                                        privilege: {
                                            path: 'salesPlan',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'menu',
                                        key: 'JQSelectionPool',
                                        title: '<<JQSelectionPool>>',
                                        privilege: {
                                            path: 'JiuquPool',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'selectionPool',
                                                title: '<<selectionPool>>',
                                                route: '/selection-pool',
                                                privilege: {
                                                    path: 'goodsPool',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'mySubscriptions',
                                                title: '<<mySubscriptions>>',
                                                route: '/user-subscriptions',
                                                privilege: {
                                                    path: 'mySubscribeGoods',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'subscriptionConfig',
                                                title: '<<subscriptionConfig>>',
                                                route: '/subscription-configs',
                                                privilege: {
                                                    path: 'JiuquSubscribeConfig',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'JQSaleBill',
                                                title: '<<JQSaleBill>>',
                                                route: '/jq-sale-bill',
                                                privilege: {
                                                    path: 'supplyChainTradeRecord',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'JQMessageNotify',
                                                title: '<<JQMessageNotify>>',
                                                route: '/jq-message-notify',
                                                privilege: {
                                                    path: 'businessRemind',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'CapitalFlows',
                                                title: '<<CapitalFlows>>',
                                                route: '/capital-flows',
                                                privilege: {
                                                    path: 'supplyChainFinancialTransaction',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                type: 'menu',
                                key: 'promotion',
                                title: '<<promotion>>',
                                icon: 'transaction',
                                privilege: {
                                    path: 'promotion',
                                    level: 'full',
                                },
                                items: [
                                    {
                                        type: 'menu',
                                        key: 'promotionActivity',
                                        title: '<<promotionActivity>>',
                                        privilege: {
                                            path: 'promotionActivity',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'promotionPrice',
                                                title: '<<promotionPrice>>',
                                                route: '/promotionPrice',
                                                privilege: {
                                                    path: 'promotionPrice',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'merchantPromotionPrice',
                                                title: '<<merchantPromotionPrice>>',
                                                route: '/merchantPromotionPrice',
                                                privilege: {
                                                    path: 'merchantPromotionPrice',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'timelimitActivity',
                                                title: '<<timelimitActivity>>',
                                                route: '/timelimit-activity',
                                                privilege: {
                                                    path: 'timelimitActivity',
                                                    level: 'full',
                                                },
                                            },
                                            /*{
                                                type: 'item',
                                                key: 'timelimit',
                                                title: '秒杀管理',
                                                route: '/timelimit',
                                                privilege: {
                                                    path: 'timelimit',
                                                    level: 'full',
                                                },
                                            },*/
                                            {
                                                type: 'menu',
                                                key: 'discount',
                                                title: '<<discount>>',
                                                privilege: {
                                                    path: 'discount',
                                                    level: 'full',
                                                },
                                                items: [
                                                    {
                                                        type: 'item',
                                                        key: 'reduction',
                                                        title: '<<reduction>>',
                                                        route: '/promotion',
                                                        privilege: {
                                                            path: 'reduction',
                                                            level: 'full',
                                                        },
                                                    },
                                                    // {
                                                    //     type: 'item',
                                                    //     key: 'checkoutPromotion',
                                                    //     title: '结算促销',
                                                    //     route: '/checkoutPromotion',
                                                    //     privilege: {
                                                    //         path: 'checkoutPromotion',
                                                    //         level: 'full',
                                                    //     },
                                                    // },
                                                    /*{
                                                        type: 'item',
                                                        key: 'presentLabel',
                                                        title: '赠品标签管理',
                                                        route: '/presentLabel',
                                                        privilege: {
                                                            path: 'presentLabel',
                                                            level: 'full',
                                                        },
                                                    },*/
                                                    {
                                                        type: 'item',
                                                        key: 'present',
                                                        title: '<<present>>',
                                                        route: '/present',
                                                        privilege: {
                                                            path: 'present',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'promotionGroup',
                                                        title: '<<promotionGroup>>',
                                                        route: '/promotionGroup',
                                                        privilege: {
                                                            path: 'promotionGroup',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'promotionPriority',
                                                        title: '<<promotionPriority>>',
                                                        route: '/promotionPriority',
                                                        privilege: {
                                                            path: 'promotionPriority',
                                                            level: 'full',
                                                        },
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'item',
                                                key: 'fixedPrice',
                                                title: '<<fixedPrice>>',
                                                route: '/fixed-price',
                                                privilege: {
                                                    path: 'fixedPrice',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'item',
                                        key: 'coupon',
                                        title: '<<coupon>>',
                                        route: '/menu-list/coupon',
                                        privilege: {
                                            path: 'coupon',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'market',
                                        title: '<<marketActivity>>',
                                        route: '/menu-list/market',
                                        privilege: {
                                            path: 'market',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'marketingCalendar',
                                        title: '<<yxrl>>',
                                        route: '/marketing-calendar',
                                        privilege: {
                                            path: 'marketingCalendar',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'maotaiPresale',
                                        title: '<<maotaiPresale>>',
                                        route: '/maotai-presale',
                                        privilege: {
                                            path: 'maoTaiPresale',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'maoTaiPresaleNormal',
                                        title: '<<maotaiPresale>>',
                                        route: '/maotai-presale-normal',
                                        privilege: {
                                            path: 'maoTaiPresaleNormal',
                                            level: 'full',
                                        },
                                    },
                                ],
                            },
                            {
                                type: 'menu',
                                key: 'order',
                                title: '<<order>>',
                                icon: 'profile',
                                privilege: {
                                    path: 'order',
                                    level: 'full',
                                },
                                items: [
                                    {
                                        type: 'item',
                                        key: 'orderExamine',
                                        title: '<<orderExamine>>',
                                        route: '/orderExamine',
                                        privilege: {
                                            path: 'orderExamine',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'menu',
                                        key: 'orderManager',
                                        title: '<<orderManager>>',
                                        privilege: {
                                            path: 'orderManager',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'orderPage',
                                                title: '<<orderManager>>',
                                                route: '/order',
                                                privilege: {
                                                    path: 'orderPage',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'orderLogistics',
                                                title: '<<orderLogistics>>',
                                                route: '/batch-delivery',
                                                privilege: {
                                                    path: 'orderLogistics',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'orderProcessBill',
                                                title: '<<orderProcessBill>>',
                                                route: '/orderProcessBill',
                                                privilege: {
                                                    path: 'orderProcessBill',
                                                    level: 'full',
                                                },
                                            },
                                            // {
                                            //     type: 'item',
                                            //     key: 'couponExchangeRecord',
                                            //     title: '线上券订单',
                                            //     route: '/coupon-exchange-record',
                                            //     privilege: {
                                            //         path: 'couponExchangeRecord',
                                            //         level: 'full',
                                            //     },
                                            // },
                                            {
                                                type: 'item',
                                                key: 'saleCouponOrder',
                                                title: '<<saleCouponOrder>>',
                                                route: '/coupon-order',
                                                privilege: {
                                                    path: 'saleCouponOrder',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'depositOrder',
                                                title: '<<depositOrder>>',
                                                route: '/deposit-order',
                                                privilege: {
                                                    path: 'depositOrder',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'deliveryPackagesLogisticsBill',
                                                title: '<<deliveryPackagesLogisticsBill>>',
                                                route: '/delivery-package',
                                                privilege: {
                                                    path: 'deliveryPackagesLogisticsBill',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'posReceipt',
                                                title: '<<posReceipt>>',
                                                route: '/pos-eceipt',
                                                privilege: {
                                                    path: 'posReceipt',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'afterSale',
                                        title: '<<afterSale>>',
                                        privilege: {
                                            path: 'afterSale',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'afterSalesApply',
                                                title: '<<afterSale>>',
                                                route: '/afterSalesApply',
                                                privilege: {
                                                    path: 'afterSalesApply',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'refund',
                                                title: '<<refund>>',
                                                route: '/refund',
                                                privilege: {
                                                    path: 'refund',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'couponServiceApply',
                                                title: '<<couponServiceApply>>',
                                                route: '/coupon-service-applies',
                                                privilege: {
                                                    path: 'couponServiceApply',
                                                    level: 'full',
                                                },
                                            },
                                            // {
                                            //     type: 'item',
                                            //     key: 'couponExchangeRefundRecord',
                                            //     title: '线上券退款单',
                                            //     route: '/coupon-exchange-refund-record',
                                            //     privilege: {
                                            //         path: 'couponExchangeRefundRecord',
                                            //         level: 'full',
                                            //     },
                                            // },
                                            {
                                                type: 'item',
                                                key: 'saleCouponRefund',
                                                title: '<<saleCouponRefund>>',
                                                route: '/coupon-refund-order',
                                                privilege: {
                                                    path: 'saleCouponRefund',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'depositAfterSale',
                                                title: '<<depositAfterSale>>',
                                                route: '/deposit-after-sale',
                                                privilege: {
                                                    path: 'depositOrderServiceApply',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'depositOrderRefund',
                                                title: '<<depositOrderRefund>>',
                                                route: '/deposit-refund',
                                                privilege: {
                                                    path: 'depositOrderRefund',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'exceptionRefundManager',
                                        title: '<<exceptionRefundManager>>',
                                        privilege: {
                                            path: 'exceptionRefundManager',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'exceptionRefund',
                                                title: '<<exceptionRefund>>',
                                                route: '/exceptionRefund',
                                                privilege: {
                                                    path: 'exceptionRefund',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'paymentAllocation',
                                        title: '<<paymentAllocation>>',
                                        privilege: {
                                            path: 'paymentAllocationManager',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'allocationConfigManager',
                                                privilege: {
                                                    path: 'paymentAllocationConfigManager',
                                                    level: 'full',
                                                },
                                                title: '<<allocationConfigManager>>',
                                                route: '/allocation-config-manager',
                                            },
                                            {
                                                type: 'item',
                                                key: 'allocationOrderManager',
                                                privilege: {
                                                    path: 'paymentAllocationOrders',
                                                    level: 'full',
                                                },
                                                title: '<<allocationOrderManager>>',
                                                route: '/allocation-order-manager',
                                            },
                                            {
                                                type: 'item',
                                                key: 'remittanceManager',
                                                privilege: {
                                                    path: 'paymentRemittanceManager',
                                                    level: 'full',
                                                },
                                                title: '<<remittanceManager>>',
                                                route: '/remittance-manager',
                                            },
                                            {
                                                type: 'item',
                                                key: 'remittanceOrderManager',
                                                privilege: {
                                                    path: 'paymentRemittanceOrders',
                                                    level: 'full',
                                                },
                                                title: '<<remittanceOrderManager>>',
                                                route: '/remittance-order-manager',
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                type: 'menu',
                                key: 'picking',
                                title: '<<picking>>',
                                icon: 'file-protect',
                                privilege: {
                                    path: 'picking',
                                    level: 'full',
                                },
                                items: [
                                    {
                                        type: 'item',
                                        key: 'pickingList',
                                        title: '<<pickingList>>',
                                        route: '/order-picking-assistant',
                                        privilege: {
                                            path: 'pickingList',
                                            level: 'full',
                                        },
                                    },
                                ],
                            },
                            {
                                type: 'menu',
                                key: 'delivery',
                                title: '<<delivery>>',
                                icon: 'car',
                                privilege: {
                                    path: 'delivery',
                                    level: 'full',
                                },
                                items: [
                                    {
                                        type: 'item',
                                        key: 'subsiteDelivery',
                                        title: '<<subsiteDelivery>>',
                                        route: '/subsite-delivery',
                                        privilege: {
                                            path: 'subsiteDeliveryFreightRule',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'merchantDelivery',
                                        title: '<<merchantDelivery>>',
                                        route: '/merchant-delivery',
                                        privilege: {
                                            path: 'merchantDeliveryFreightRule',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'subsiteBuyLimitRule',
                                        title: '<<subsiteBuyLimitRule>>',
                                        route: '/subsite-buy-limit',
                                        privilege: {
                                            path: 'subsiteBuyLimitRule',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'merchantBuyLimitRule',
                                        title: '<<merchantBuyLimitRule>>',
                                        route: '/merchant-buy-limit',
                                        privilege: {
                                            path: 'merchantBuyLimitRule',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'deliveryLogisticsConfigs',
                                        title: '<<deliveryLogisticsConfigs>>',
                                        route: '/delivery-logistics-configs',
                                        privilege: {
                                            path: 'subsiteLogisticsCompany',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'deliveryBenefit',
                                        title: '<<deliveryBenefit>>',
                                        route: '/delivery-benefits',
                                        privilege: {
                                            path: 'deliveryBenefit',
                                            level: 'full',
                                        },
                                    },
                                ],
                            },
                            {
                                type: 'menu',
                                key: 'member',
                                title: '<<member>>',
                                icon: 'team',
                                privilege: {
                                    path: 'member',
                                    level: 'full',
                                },
                                items: [
                                    {
                                        type: 'menu',
                                        key: 'memberInfo',
                                        title: '<<memberInfo>>',
                                        privilege: {
                                            path: 'memberInfo',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'newMemberInfo',
                                                title: '<<newMemberInfo>>',
                                                route: '/member',
                                                privilege: {
                                                    path: 'newMemberInfo',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'cardLevel',
                                                title: '<<cardLevel>>',
                                                route: '/card-level',
                                                privilege: {
                                                    path: 'cardLevel',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'storeCardLevel',
                                                title: '<<storeCardLevel>>',
                                                route: '/store-card-level',
                                                privilege: {
                                                    path: 'storeCardLevel',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'memberLevelRule',
                                                title: '<<memberLevelRule>>',
                                                route: '/memberLevelRule',
                                                privilege: {
                                                    path: 'memberLevelRule',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'memberTag',
                                                title: '<<memberTag>>',
                                                route: '/memberTag',
                                                privilege: {
                                                    path: 'memberTag',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'socialIdentityTagFunction',
                                                title: '<<socialIdentityTagFunction>>',
                                                route: '/socialIdentityTagPermissions',
                                                privilege: {
                                                    path: 'socialIdentityTagFunction',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'memberProfileItem',
                                                title: '<<memberProfileItem>>',
                                                route: '/memberProfileItem',
                                                privilege: {
                                                    path: 'memberProfileItem',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'member360',
                                        title: '<<member360>>',
                                        privilege: {
                                            path: 'member360',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'memberManage',
                                                title: '<<memberManage>>',
                                                route: '/member-manage',
                                                privilege: {
                                                    path: 'memberManage',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'labelManage',
                                                title: '<<labelManage>>',
                                                route: '/label-manage',
                                                privilege: {
                                                    path: 'memberv2Tag',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'crowdManage',
                                                title: '<<crowdManage>>',
                                                route: '/crowd-manage',
                                                privilege: {
                                                    path: 'memberv2Crowds',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'levelSystem',
                                        title: '<<levelSystem>>',
                                        privilege: {
                                            path: 'levelSystem',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'memberCardLevelManage',
                                                title: '<<memberCardLevelManage>>',
                                                route: '/card-level-manage',
                                                privilege: {
                                                    path: 'memberCardLevelManage',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'subsiteMemberCardLevelManage',
                                                title: '<<subsiteMemberCardLevelManage>>',
                                                route: '/subsite-member-card-level-manage',
                                                privilege: {
                                                    path: 'subsiteMemberCardLevelManage',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'memberLevel',
                                                title: '<<memberLevel>>',
                                                route: '/member-level-manage',
                                                privilege: {
                                                    path: 'memberLevel',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'scrmLevelsUpdownGrade',
                                                title: '<<scrmLevelsUpdownGrade>>',
                                                route: '/member-level-up-down-grade',
                                                privilege: {
                                                    path: 'scrmLevelsUpgradeDowngradeRule',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'memberv2UpgradeGift',
                                                title: '<<hysjl>>',
                                                route: '/member-upgrade-gift',
                                                privilege: {
                                                    path: 'memberv2UpgradeGift',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'publicSentiment',
                                        title: '<<publicSentiment>>',
                                        privilege: {
                                            path: 'publicSentiment',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'reviewAudit',
                                                title: '<<reviewAudit>>',
                                                route: '/order-comments',
                                                privilege: {
                                                    path: 'reviewAudit',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'feedback',
                                                title: '<<feedback>>',
                                                route: '/feedback',
                                                privilege: {
                                                    path: 'feedback',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'feedbacks',
                                                title: '<<feedbacks>>',
                                                route: '/feedbacks',
                                                privilege: {
                                                    path: 'feedbacks',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'offlineOrderComments',
                                                title: '<<offlineOrderComments>>',
                                                route: '/offline-order-comments',
                                                privilege: {
                                                    path: 'posReceiptOrderComment',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'offlineOrderCommentsCustomization',
                                                title: '<<offlineOrderCommentsCustomization>>',
                                                route: '/offline-order-comments-customization',
                                                privilege: {
                                                    path: 'posReceiptOrderCustomComment',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'commentActivity',
                                                title: '<<commentActivity>>',
                                                route: '/market/comment-activity',
                                                privilege: {
                                                    path: 'commentReward',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'caring',
                                        title: '<<caring>>',
                                        privilege: {
                                            path: 'caring',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'birthdayCaring',
                                                title: '<<birthdayCaring>>',
                                                route: '/birthday-caring',
                                                privilege: {
                                                    path: 'birthdayCaring',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'festivalMarketing',
                                                title: '<<festivalMarketing>>',
                                                route: '/festival-marketing',
                                                privilege: {
                                                    path: 'festivalMarketing',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'annualWelfareDesc',
                                                title: '<<annualWelfareDesc>>',
                                                route: '/annual-welfare',
                                                privilege: {
                                                    path: 'annualWelfareDesc',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'amountAccountsDesc',
                                                title: '<<amountAccountsDesc>>',
                                                route: '/balance-description',
                                                privilege: {
                                                    path: 'amountAccountsDesc',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'memberCardDiscount',
                                                title: '<<memberCardDiscount>>',
                                                route: '/member_card_discount_activities',
                                                privilege: {
                                                    path: 'memberCardDiscount',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'memberService',
                                        title: '<<memberService>>',
                                        privilege: {
                                            path: 'memberService',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'menu',
                                                key: 'carpark',
                                                title: '<<carpark>>',
                                                privilege: {
                                                    path: 'carpark',
                                                    level: 'full',
                                                },
                                                items: [
                                                    // {
                                                    //     type: 'item',
                                                    //     key: 'subsite-carpark',
                                                    //     title: '停车页面配置',
                                                    //     route: '/subsite-carpark',
                                                    //     privilege: {
                                                    //         path: 'subsiteCarpark',
                                                    //         level: 'full',
                                                    //     },
                                                    // },
                                                    {
                                                        type: 'item',
                                                        key: 'newCSubsiteCarpark',
                                                        title: '<<yardManagement>>',
                                                        route: '/new-subsite-carpark',
                                                        privilege: {
                                                            path: 'newCSubsiteCarpark',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'carpark-order',
                                                        title: '<<carparkOrder>>',
                                                        route: '/carpark-order',
                                                        privilege: {
                                                            path: 'carparkOrder',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'memberBenefits',
                                                        title: '<<memberBenefits>>',
                                                        route: '/member-benefit',
                                                        privilege: {
                                                            path: 'memberBenefits',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'TouchlessParkingAuthRecord',
                                                        title: '<<TouchlessParkingAuthRecord>>',
                                                        route:
                                                            '/touchless-parking-authorization-record',
                                                        privilege: {
                                                            path: 'TouchlessParkingAuthRecord',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'memberCarNumber',
                                                        title: '<<memberCarNumber>>',
                                                        route: '/member-car-number',
                                                        privilege: {
                                                            path: 'memberCarNumber',
                                                            level: 'full',
                                                        },
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'menu',
                                                key: 'mallActivity',
                                                title: '<<mallActivity>>',
                                                privilege: {
                                                    path: 'mallActivity',
                                                    level: 'full',
                                                },
                                                items: [
                                                    {
                                                        type: 'item',
                                                        key: 'mallActivitySetting',
                                                        title: '<<mallActivitySetting>>',
                                                        route: '/mall-activity-setting',
                                                        privilege: {
                                                            path: 'mallActivitySetting',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'mallActivityRegistration',
                                                        title: '<<mallActivityRegistration>>',
                                                        route: '/mall-activity-registration',
                                                        privilege: {
                                                            path: 'mallActivityRegistration',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'mallActivityServiceApply',
                                                        title: '<<mallActivityServiceApply>>',
                                                        route: '/mall-activity-after-sale',
                                                        privilege: {
                                                            path: 'mallActivityServiceApply',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'mallActivityRefund',
                                                        title: '<<mallActivityRefund>>',
                                                        route: '/mall-activity-refund-order',
                                                        privilege: {
                                                            path: 'mallActivityRefund',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'mallActivityVerification',
                                                        title: '<<mallActivityVerification>>',
                                                        route: '/mall-activity-verification',
                                                        privilege: {
                                                            path: 'mallActivityVerification',
                                                            level: 'full',
                                                        },
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'menu',
                                                key: 'serviceReserved',
                                                title: '<<serviceReserved>>',
                                                privilege: {
                                                    path: 'serviceReserved',
                                                    level: 'full',
                                                },
                                                items: [
                                                    {
                                                        type: 'item',
                                                        key: 'serviceReservedSetting',
                                                        title: '<<serviceReservedSetting>>',
                                                        route: '/service-reserved-setting',
                                                        privilege: {
                                                            path: 'serviceReservedSetting',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'serviceReservedOrder',
                                                        title: '<<serviceReservedOrder>>',
                                                        route: '/reservation-details',
                                                        privilege: {
                                                            path: 'serviceReservedOrder',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'serviceReservedRefundOrder',
                                                        title: '<<serviceReservedRefundOrder>>',
                                                        route: '/service-reserved-refund-order',
                                                        privilege: {
                                                            path: 'serviceReservedRefundOrder',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'serviceReservedOrderConsumeRecords',
                                                        title: '<<mallActivityVerification>>',
                                                        route: '/service-reserved-consume-records',
                                                        privilege: {
                                                            path:
                                                                'serviceReservedOrderConsumeRecords',
                                                            level: 'full',
                                                        },
                                                    },
                                                ],
                                            },
                                            // {
                                            //     type: 'item',
                                            //     key: 'discardNewMallActivity',
                                            //     title: '废弃-新商场活动',
                                            //     route: '/mall-activity',
                                            //     privilege: {
                                            //         path: 'newMallActivity',
                                            //         level: 'full',
                                            //     },
                                            // },
                                            {
                                                type: 'item',
                                                key: 'newMallActivity',
                                                title: '<<newMallActivity>>',
                                                route: '/new-mall-activity',
                                                privilege: {
                                                    path: 'newMallActivity',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'wifi',
                                                title: '<<wifi>>',
                                                route: '/service-wifi',
                                                privilege: {
                                                    path: 'wifi',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'restaurant-reservation',
                                                title: '<<reservation>>',
                                                route: '/restaurant-reservation',
                                                privilege: {
                                                    path: 'backEndRestaurantReservation',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'chargeBenefits',
                                                title: '<<chargeBenefits>>',
                                                route: '/charge-benefits',
                                                privilege: {
                                                    path: 'chargeBenefits',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'pointManage',
                                        title: '<<pointManage>>',
                                        privilege: {
                                            path: 'pointManage',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'creditEShop',
                                                title: '<<creditEShop>>',
                                                route: '/credit-shop',
                                                privilege: {
                                                    path: 'creditEShop',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'scratchCard',
                                                title: '<<scratchCard>>',
                                                route: '/scratchCard',
                                                privilege: {
                                                    path: 'scratchCard',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'lotteryCode',
                                                title: '<<lotteryCode>>',
                                                route: '/lottery-code',
                                                privilege: {
                                                    path: 'lotteryCode',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'wfjLotteryCode',
                                                title: '<<lotteryCode>>',
                                                route: '/wfj-lottery-code',
                                                privilege: {
                                                    path: 'wfjLotteryCode',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'pointExplain',
                                                title: '<<pointExplain>>',
                                                route: '/integral-description',
                                                privilege: {
                                                    path: 'pointExplain',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'menu',
                                                key: 'selfCrediting',
                                                title: '自助积分',
                                                privilege: {
                                                    path: 'selfCrediting',
                                                    level: 'full',
                                                },
                                                items: [
                                                    {
                                                        type: 'item',
                                                        key: 'pageSetting',
                                                        title:
                                                            '<<components.PageDesign.pageSetting>>',
                                                        route: '/self-crediting-setting',
                                                        privilege: {
                                                            path: 'pageSetting',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'photoCreditReview',
                                                        title: '<<photoCreditReview>>',
                                                        route: '/photo-credit-review',
                                                        privilege: {
                                                            path: 'photoToCreditApprove',
                                                            level: 'full',
                                                        },
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'item',
                                                key: 'pointRightsAndInterests',
                                                title: '<<pointRightsAndInterests>>',
                                                route: '/point-benefit',
                                                privilege: {
                                                    path: 'pointRightsAndInterests',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'pointRuleSet',
                                                title: '<<pointRuleSet>>',
                                                route: '/point-setting',
                                                privilege: {
                                                    path: 'pointRuleSet',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'pointDeductionProductSet',
                                                title: '<<pointDeductionProductSet>>',
                                                route: '/point-deduction-product',
                                                privilege: {
                                                    path: 'pointDeductionProductSet',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'purchaseLimitation',
                                                title: '<<purchaseLimitation>>',
                                                route: '/point-purchase-limitation',
                                                privilege: {
                                                    path: 'creditEshopLimit',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'pointExchangeBalanceCouponRule',
                                                title: '<<pointExchangeBalanceCouponRule>>',
                                                route: '/point-exchange-rule',
                                                privilege: {
                                                    path: 'pointExchangeBalanceCouponRule',
                                                    level: 'full',
                                                },
                                            },
                                            // {
                                            //     type: 'item',
                                            //     key: 'businessMall',
                                            //     title: '商圈管理',
                                            //     route: '/business-malls',
                                            //     privilege: {
                                            //         path: 'businessMall',
                                            //         level: 'full',
                                            //     },
                                            // },
                                            {
                                                type: 'item',
                                                key: 'pointClearRule',
                                                title: '<<pointClearRule>>',
                                                route: '/point-rules',
                                                privilege: {
                                                    path: 'pointClearRule',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'hiBeiManage',
                                        title: '<<hiBeiManage>>',
                                        privilege: {
                                            path: 'hiBeiManage',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'goldExchangePoint',
                                                title: '<<goldExchangePoint>>',
                                                route: '/gold-exchange-point',
                                                privilege: {
                                                    path: 'goldExchangeRule',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'hiBeiRightsAndInterests',
                                                title: '<<hiBeiRightsAndInterests>>',
                                                route: '/hibei-benefit',
                                                privilege: {
                                                    path: 'hiBeiRightsAndInterests',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'goldDeduction',
                                                title: '<<goldDeduction>>',
                                                route: '/gold-deduction',
                                                privilege: {
                                                    path: 'goldDeduction',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'experienceManage',
                                        title: '<<experienceManage>>',
                                        privilege: {
                                            path: 'experienceManage',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'experienceRule',
                                                title: '<<experienceRule>>',
                                                route: '/experience-rule',
                                                privilege: {
                                                    path: 'experienceRule',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'experienceRightsAndInterests',
                                                title: '<<experienceRightsAndInterests>>',
                                                route: '/experience-benefit',
                                                privilege: {
                                                    path: 'experienceRightsAndInterests',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'memberStoredValueCard',
                                        title: '<<memberStoredValueCard>>',
                                        privilege: {
                                            path: 'memberStoredValueCard',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'storedValueCardParamConfig',
                                                title: '<<storedValueCardParamConfig>>',
                                                route: '/stored-value-card-setting',
                                                privilege: {
                                                    path: 'storedValueCardParamConfig',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'storedValueCardConfig',
                                                title: '<<newCSubsiteCarpark>>',
                                                route: '/stored-value-card-config',
                                                privilege: {
                                                    path: 'storedValueCardConfig',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'storedValueCardProduct',
                                                title: '<<storedValueCardProduct>>',
                                                route: '/stored-value-card-product',
                                                privilege: {
                                                    path: 'storedValueCardProduct',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'storedValueCardActivity',
                                                title: '<<storedValueCardActivity>>',
                                                route: '/stored-value-card-activity',
                                                privilege: {
                                                    path: 'storedValueCardActivity',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'storedValueCardBuyActivity',
                                                title: '<<storedValueCardBuyActivity>>',
                                                route: '/stored-value-card-buy-activity',
                                                privilege: {
                                                    path: 'storedValueCardRewardActivity',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'storedValueCardOrder',
                                                title: '<<storedValueCardOrder>>',
                                                route: '/stored-value-card-order',
                                                privilege: {
                                                    path: 'storedValueCardOrderManage',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                key: 'OfflineOrderReview',
                                                title: '<<OfflineOrderReview>>',
                                                route: '/offline-order-review',
                                                privilege: {
                                                    path: 'offlinePaymentBillManualReview',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'memberGiftManage',
                                        title: '<<memberGiftManage>>',
                                        privilege: {
                                            path: 'memberGiftManage',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'manageGift',
                                                title: '<<manageGift>>',
                                                route: '/manage-gift-config',
                                                privilege: {
                                                    path: 'manageGift',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                type: 'menu',
                                key: 'message',
                                title: '<<message>>',
                                icon: 'message',
                                privilege: {
                                    path: 'message',
                                    level: 'full',
                                },
                                items: [
                                    {
                                        type: 'item',
                                        key: 'subscriptionMessage',
                                        title: '<<subscriptionMessage>>',
                                        route: '/subscription-message',
                                        privilege: {
                                            path: 'subscriptionMessage',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'messageType',
                                        title: '<<messageType>>',
                                        route: '/messageType',
                                        privilege: {
                                            path: 'messageType',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'messageChannel',
                                        title: '<<messageChannel>>',
                                        route: '/messageChannel',
                                        privilege: {
                                            path: 'messageChannel',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'messageTemplate',
                                        title: '<<messageTemplate>>',
                                        route: '/messageTemplate',
                                        privilege: {
                                            path: 'messageTemplate',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'menu',
                                        key: 'batchMessage',
                                        title: '<<batchMessage>>',
                                        privilege: {
                                            path: 'batchMessage',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'messageBatchDefinitionChannel',
                                                title: '<<batchMessage>>',
                                                route: '/messageBatchDefinitionChannel',
                                                privilege: {
                                                    path: 'messageBatchDefinitionChannel',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'messageBatchTemplate',
                                                title: '<<messageBatchTemplate>>',
                                                route: '/messageBatchTemplate',
                                                privilege: {
                                                    path: 'messageBatchTemplate',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'item',
                                        key: 'messageHistory',
                                        title: '<<messageHistory>>',
                                        route: '/messageHistory',
                                        privilege: {
                                            path: 'messageHistory',
                                            level: 'full',
                                        },
                                    },
                                    // {
                                    //     type: 'item',
                                    //     key: 'messageBatch',
                                    //     title: '消息群发',
                                    //     route: '/messageBatch',
                                    //     privilege: {
                                    //         path: 'messageBatch',
                                    //         level: 'full',
                                    //     },
                                    // },
                                    {
                                        type: 'item',
                                        key: 'message-batch-v2',
                                        title: '<<messageBatch>>',
                                        route: '/message-batch-v2',
                                        privilege: {
                                            path: 'messageBatch',
                                            level: 'full',
                                        },
                                    },
                                ],
                            },
                            {
                                type: 'menu',
                                key: 'reportData',
                                title: '<<reportData>>',
                                icon: 'area-chart',
                                privilege: {
                                    path: 'reportData',
                                    level: 'full',
                                },
                                items: [
                                    {
                                        type: 'menu',
                                        key: 'report',
                                        title: '<<report>>',
                                        privilege: {
                                            path: 'report',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'wechatPublicUserCount',
                                                title: '<<wechatPublicUserCount>>',
                                                route: '/wechat-public-user-count',
                                                privilege: {
                                                    path: 'wechatPublicUserCount',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'reportManager',
                                                title: '<<reportManager>>',
                                                route: '/salesReport',
                                                privilege: {
                                                    path: 'reportManager',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'ServiceApplyReport',
                                                title: '<<ServiceApplyReport>>',
                                                route: '/afterSalesReport',
                                                privilege: {
                                                    path: 'ServiceApplyReport',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'menu',
                                                key: 'couponCode',
                                                title: '<<couponCode>>',
                                                route: '/couponCode',
                                                privilege: {
                                                    path: 'couponCode',
                                                    level: 'full',
                                                },
                                                items: [
                                                    {
                                                        type: 'item',
                                                        key: 'couponConsume',
                                                        title: '<<couponConsume>>',
                                                        route: '/coupon-consume',
                                                        privilege: {
                                                            path: 'couponConsume',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'couponUnConsume',
                                                        title: '<<couponUnConsume>>',
                                                        route: '/coupon-un-consume',
                                                        privilege: {
                                                            path: 'couponUnConsume',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'couponDownload',
                                                        title: '<<couponDownload>>',
                                                        route: '/coupon-download',
                                                        privilege: {
                                                            path: 'couponDownload',
                                                            level: 'full',
                                                        },
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'menu',
                                                key: 'couponReport',
                                                title: '<<couponReport>>',
                                                privilege: {
                                                    path: 'couponReport',
                                                    level: 'full',
                                                },
                                                items: [
                                                    {
                                                        type: 'item',
                                                        key: 'miniProgramChargesDashboard',
                                                        title: '<<miniProgramChargesDashboard>>',
                                                        route: '/mini-program-charges-dashboard',
                                                        privilege: {
                                                            path: 'miniProgramChargesDashboard',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'couponOrderReport',
                                                        title: '<<couponOrderReport>>',
                                                        route: '/coupon-sale-report',
                                                        privilege: {
                                                            path: 'couponOrderReport',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'balanceRechargeReport',
                                                        title: '<<balanceRechargeReport>>',
                                                        route: '/coupon-balance-sale-report',
                                                        privilege: {
                                                            path: 'balanceRechargeReport',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'couponPackageOrderReport',
                                                        title: '<<couponPackageOrderReport>>',
                                                        route: '/coupon-package-sale-report',
                                                        privilege: {
                                                            path: 'couponPackageOrderReport',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'couponRefundReport',
                                                        title: '<<couponRefundReport>>',
                                                        route: '/coupon-refund-report',
                                                        privilege: {
                                                            path: 'couponRefundReport',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'couponConsumeReport',
                                                        title: '<<couponConsumeReport>>',
                                                        route: '/coupon-consume-report',
                                                        privilege: {
                                                            path: 'couponConsumeReport',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'couponMerchantReport',
                                                        title: '<<couponMerchantReport>>',
                                                        route: '/coupon-merchant-report',
                                                        privilege: {
                                                            path: 'couponMerchantReport',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'couponActivityReport',
                                                        title: '<<couponActivityReport>>',
                                                        route: '/coupon-activity-report',
                                                        privilege: {
                                                            path: 'couponActivityReport',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'pointExchangeRecord',
                                                        title: '<<pointExchangeRecord>>',
                                                        route: '/point-exchange-record',
                                                        privilege: {
                                                            path: 'pointExchangeRecord',
                                                            level: 'full',
                                                        },
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'menu',
                                                key: 'financeReport',
                                                title: '<<financeReport>>',
                                                privilege: {
                                                    path: 'financeReport',
                                                    level: 'full',
                                                },
                                                items: [
                                                    {
                                                        type: 'item',
                                                        key: 'orderStatement',
                                                        title: '<<orderStatement>>',
                                                        route: '/orderStatement',
                                                        privilege: {
                                                            path: 'orderStatement',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'merchantSaleBill',
                                                        title: '<<merchantSaleBill>>',
                                                        route: '/merchant-sale-bill',
                                                        privilege: {
                                                            path: 'merchantSaleBill',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'merchantBookkeepingBill',
                                                        title: '<<merchantBookkeepingBill>>',
                                                        route: '/merchant-bookkeeping-bill',
                                                        privilege: {
                                                            path: 'merchantBookkeepingBill',
                                                            level: 'full',
                                                        },
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'item',
                                                key: 'deliveryLogisticsBillReport',
                                                title: '<<deliveryLogisticsBillReport>>',
                                                route: '/delivery-logistics-bill-report',
                                                privilege: {
                                                    path: 'deliveryLogisticsBillReport',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'dataPanel',
                                        title: '<<dataPanel>>',
                                        privilege: {
                                            path: 'dataPanel',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'paymentsData',
                                                title: '<<paymentsData>>',
                                                route: '/data-panels-payments',
                                                privilege: {
                                                    path: 'paymentsData',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'productsData',
                                                title: '<<productsData>>',
                                                route: '/data-panels-products',
                                                privilege: {
                                                    path: 'productsData',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'refundsData',
                                                title: '<<refundsData>>',
                                                route: '/data-panels-refunds',
                                                privilege: {
                                                    path: 'refundsData',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'membersData',
                                                title: '<<membersData>>',
                                                route: '/data-panels-members',
                                                privilege: {
                                                    path: 'membersData',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'biData',
                                                title: 'datart',
                                                route: '/data-panels-bi-pages',
                                                privilege: {
                                                    path: 'biData',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'miniProgramCodeDatas',
                                                title: '<<miniProgramCodeDatas>>',
                                                route: '/miniprogrom-code-datas',
                                                privilege: {
                                                    path: 'miniProgramCodeData',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'item',
                                        key: 'reportDownloadCenter',
                                        title: '<<reportDownloadCenter>>',
                                        route: '/report-download-center',
                                        privilege: {
                                            path: 'reportDownloadCenter',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'biDatart',
                                        title: '<<biDatart>>',
                                        route: '/bi-datart',
                                        privilege: {
                                            path: 'biDatart',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'operatorLog',
                                        title: '<<operatorLog>>',
                                        route: '/operatorLog',
                                        privilege: {
                                            path: 'operatorLog',
                                            level: 'full',
                                        },
                                    },
                                    // {
                                    //     type: 'item',
                                    //     key: 'marketingScheduleManagement',
                                    //     title: '<<yxdqgl>>',
                                    //     route: '/marketing-schedule-management',
                                    //     privilege: {
                                    //         path: 'marketingCalendar',
                                    //         level: 'full',
                                    //     },
                                    // },
                                ],
                            },
                            {
                                type: 'menu',
                                key: 'search',
                                title: '<<search>>',
                                icon: 'search',
                                privilege: {
                                    path: 'search',
                                    level: 'full',
                                },
                                items: [
                                    {
                                        type: 'item',
                                        key: 'productSearch',
                                        title: '<<productSearch>>',
                                        route: '/product-search-keywords',
                                        privilege: {
                                            path: 'productSearch',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'hotWordsSearch',
                                        title: '<<hotWordsSearch>>',
                                        route: '/hot-words-search',
                                        privilege: {
                                            path: 'hotWord',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'escapeWord',
                                        title: '<<escapeWord>>',
                                        route: '/escapeWord',
                                        privilege: {
                                            path: 'escapeWord',
                                            level: 'full',
                                        },
                                    },
                                ],
                            },
                            {
                                type: 'menu',
                                key: 'authority',
                                title: '<<authority>>',
                                icon: 'lock',
                                privilege: {
                                    path: 'authority',
                                    level: 'full',
                                },
                                items: [
                                    {
                                        type: 'menu',
                                        key: 'user',
                                        title: '<<userPrivilege>>',
                                        privilege: {
                                            path: 'user',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'userManage',
                                                title: '<<userManage>>',
                                                route: '/user-manage',
                                                privilege: {
                                                    path: 'user',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'roleManage',
                                                title: '<<roleManage>>',
                                                route: '/role-manage',
                                                privilege: {
                                                    path: 'role',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'merchantAuthority',
                                        title: '<<merchantAuthority>>',
                                        privilege: {
                                            path: 'merchantAuthority',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'merchantUserManage',
                                                title: '<<merchantUserManage>>',
                                                route: '/merchant-user-manage',
                                                privilege: {
                                                    path: 'merchantUserManage',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'merchantRoleManage',
                                                title: '<<roleManage>>',
                                                route: '/merchant-role-manage',
                                                privilege: {
                                                    path: 'merchantRole',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'applyUserRecord',
                                                title: '<<applyUserRecord>>',
                                                route: '/guides-apply-records',
                                                privilege: {
                                                    path: 'applyUserRecord',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'item',
                                        key: 'organizationManage',
                                        title: '<<organizationManage>>',
                                        route: '/organization-manage',
                                        privilege: {
                                            path: 'organization_manage',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'newRoleManage',
                                        title: '<<newRoleManage>>',
                                        route: '/new-role-manage',
                                        privilege: {
                                            path: 'role_manage',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'newUserManage',
                                        title: '<<newUserManage>>',
                                        route: '/new-user-manage',
                                        privilege: {
                                            path: 'user_manage',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'auditGuideUser',
                                        title: '<<auditGuideUser>>',
                                        route: '/audit-guide-user',
                                        privilege: {
                                            path: 'shopping_guide_application_records_manage',
                                            level: 'full',
                                        },
                                    },
                                ],
                            },
                            {
                                type: 'menu',
                                key: 'content',
                                title: '<<content>>',
                                icon: 'container',
                                privilege: {
                                    path: 'show',
                                    level: 'full',
                                },
                                items: [
                                    {
                                        type: 'item',
                                        key: 'content-complain',
                                        title: '<<complain>>',
                                        route: '/content-complain',
                                        privilege: {
                                            path: 'showComplain',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'content-score',
                                        title: '<<ontentScore>>',
                                        route: '/content-score',
                                        privilege: {
                                            path: 'showNoteScoreConfig',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'content-note',
                                        title: '<<contentNote>>',
                                        route: '/content-note',
                                        privilege: {
                                            path: 'showNote',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'show-style-label',
                                        title: '<<showStyleLabel>>',
                                        route: '/show-style-label',
                                        privilege: {
                                            path: 'showStyleLabel',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'content-plate',
                                        title: '<<contentPlate>>',
                                        route: '/content-plate',
                                        privilege: {
                                            path: 'showPlate',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'content-topic',
                                        title: '<<contentTopic>>',
                                        route: '/content-topic',
                                        privilege: {
                                            path: 'showTopic',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'menu',
                                        key: 'encourage',
                                        title: '<<encourage>>',
                                        privilege: {
                                            path: 'encourage',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'notePromotionEncourage',
                                                title: '<<notePromotionEncourage>>',
                                                route: '/note-promotion-encourage',
                                                privilege: {
                                                    path: 'notePromotionEncourage',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'topicPromotionEncourage',
                                                title: '<<topicPromotionEncourage>>',
                                                route: '/topic-promotion-encourage',
                                                privilege: {
                                                    path: 'topicPromotionEncourage',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'activationPromotionEncourage',
                                                title: '<<activationPromotionEncourage>>',
                                                route: '/activation-promotion-encourage',
                                                privilege: {
                                                    path: 'activationPromotionEncourage',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'interactivePromotionEncourage',
                                                title: '<<interactivePromotionEncourage>>',
                                                route: '/interactive-promotion-encourage',
                                                privilege: {
                                                    path: 'interactivePromotionEncourage',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'fissionPromotionEncourage',
                                                title: '<<fissionPromotionEncourage>>',
                                                route: '/fission-promotion-encourage',
                                                privilege: {
                                                    path: 'fissionPromotionEncourage',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                type: 'menu',
                                key: 'wechatOfficialAccount',
                                title: '<<wechatOfficialAccount>>',
                                icon: 'wechat',
                                privilege: {
                                    path: 'wechatOfficialAccount',
                                    level: 'full',
                                },
                                items: [
                                    {
                                        type: 'item',
                                        key: 'wechatOfficialAccountManger',
                                        title: '<<wechatOfficialAccountManger>>',
                                        route: '/wechat-official-accounts',
                                        privilege: {
                                            path: 'wechatOfficialAccountManger',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'wechatOfficialAccountFan',
                                        title: '<<wechatOfficialAccountFan>>',
                                        route: '/wechat-official-account-fan',
                                        privilege: {
                                            path: 'wechatOfficialAccountFan',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'wechatTemplate',
                                        title: '<<wechatTemplate>>',
                                        route: '/wechat-templates',
                                        privilege: {
                                            path: 'wechatTemplate',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'followReplyMessage',
                                        title: '<<followReplyMessage>>',
                                        route: '/wechat-official-auto-reply',
                                        privilege: {
                                            path: 'followReplyMessage',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'wechatOfficialParametricQrcode',
                                        title: '<<wechatOfficialParametricQrcode>>',
                                        route: '/wechat-official-parametric-qrcode',
                                        privilege: {
                                            path: 'qrCodeRules',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'customizeMenus',
                                        title: '<<customizeMenus>>',
                                        route: '/wechat-custom-menus',
                                        privilege: {
                                            path: 'customizeMenus',
                                            level: 'full',
                                        },
                                    },
                                ],
                            },
                            {
                                type: 'menu',
                                key: 'settings',
                                title: '<<settings>>',
                                icon: 'setting',
                                privilege: {
                                    path: 'settings',
                                    level: 'full',
                                },
                                items: [
                                    {
                                        type: 'menu',
                                        key: 'deliverySettings',
                                        title: '<<deliverySettings>>',
                                        privilege: {
                                            path: 'deliverySettings',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'region',
                                                title: '<<region>>',
                                                route: '/region',
                                                privilege: {
                                                    path: 'region',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'expressCompany',
                                                title: '<<expressCompany>>',
                                                route: '/express-company',
                                                privilege: {
                                                    path: 'expressCompany',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'deliveryMode',
                                                title: '<<deliveryMode>>',
                                                route: '/deliveryMode',
                                                privilege: {
                                                    path: 'deliveryMode',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'item',
                                        key: 'orderTest',
                                        title: '<<orderTest>>',
                                        route: '/orderTest',
                                        privilege: {
                                            path: 'orderTest',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'parameter',
                                        title: '<<parameter>>',
                                        route: '/parameter',
                                        privilege: {
                                            path: 'parameter',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'filterWord',
                                        title: '<<filterWord>>',
                                        route: '/filterWord',
                                        privilege: {
                                            path: 'filterWord',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'blacklist',
                                        title: '<<blacklist>>',
                                        route: '/blacklist',
                                        privilege: {
                                            path: 'blacklist',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'paymentModeManagement',
                                        title: '<<paymentModeManagement>>',
                                        route: '/payment-mode-management',
                                        privilege: {
                                            path: 'paymentModeManagement',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'menu',
                                        key: 'appVersion',
                                        title: '<<appVersion>>',
                                        privilege: {
                                            path: 'appVersion',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'version',
                                                title: '<<hotVersion>>',
                                                route: '/version',
                                                privilege: {
                                                    path: 'version',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'versionUpdate',
                                                title: '<<versionUpdate>>',
                                                route: '/versionUpdate',
                                                privilege: {
                                                    path: 'versionUpdate',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'logo',
                                        title: '<<manageLogo>>',
                                        privilege: {
                                            path: 'logo',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'backinfo',
                                                title: '<<backinfo>>',
                                                route: '/backinfo',
                                                privilege: {
                                                    path: 'backinfo',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'frontinfo',
                                                title: '<<frontinfo>>',
                                                route: '/frontinfo',
                                                privilege: {
                                                    path: 'frontinfo',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'item',
                                        key: 'merchantSystemConfig',
                                        title: '<<merchantSystemConfig>>',
                                        route: '/systemconfig',
                                        privilege: {
                                            path: 'merchantSystemConfig',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'menu',
                                        key: 'picture',
                                        title: '<<picture>>',
                                        privilege: {
                                            path: 'picture',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'albumConfig',
                                                title: '<<albumConfig>>',
                                                route: '/albumConfig',
                                                privilege: {
                                                    path: 'albumConfig',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'album',
                                                title: '<<album>>',
                                                route: '/album',
                                                privilege: {
                                                    path: 'album',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'operations',
                                        title: '<<operations>>',
                                        privilege: {
                                            path: 'operations',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'menu',
                                                key: 'promotionActivityMaintain',
                                                title: '<<promotionActivityMaintain>>',
                                                privilege: {
                                                    path: 'marketOperation',
                                                    level: 'full',
                                                },
                                                items: [
                                                    {
                                                        type: 'item',
                                                        key: 'lotteryMaintain',
                                                        title: '<<lotteryMaintain>>',
                                                        route: '/lottery-maintain',
                                                        privilege: {
                                                            path: 'marketOperation',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'drawLotsReplenish',
                                                        title: '<<drawLotsReplenish>>',
                                                        route: '/draw-lots-replenish',
                                                        privilege: {
                                                            path: 'reserveDrawLotsReplenish',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'drawLotsAppoint',
                                                        title: '<<drawLotsAppoint>>',
                                                        route: '/draw-lots-appoint',
                                                        privilege: {
                                                            path: 'reserveDrawLotsAppoint',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'signOrder',
                                                        title: '<<signOrder>>',
                                                        route: '/sign-order',
                                                        privilege: {
                                                            path: 'drawLotsOrderSign',
                                                            level: 'full',
                                                        },
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'item',
                                                key: 'cacheStats',
                                                title: '<<cacheStats>>',
                                                route: '/cacheStats',
                                                privilege: {
                                                    path: 'cacheStats',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'primaryKey',
                                                title: '<<primaryKey>>',
                                                route: '/primaryKey',
                                                privilege: {
                                                    path: 'primaryKey',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'requestLimit',
                                                title: '<<requestLimit>>',
                                                route: '/requestLimit',
                                                privilege: {
                                                    path: 'requestLimit',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'index',
                                                title: '<<createIndex>>',
                                                route: '/index',
                                                privilege: {
                                                    path: 'index',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'orderPaymentManager',
                                                title: '<<orderPaymentManager>>',
                                                route: '/orderPaymentManager',
                                                privilege: {
                                                    path: 'orderPaymentManager',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'thirdRequestLog',
                                                title: '<<thirdRequestLog>>',
                                                route: '/third-request-log',
                                                privilege: {
                                                    path: 'thirdRequestLog',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'item',
                                        key: 'invoiceSetting',
                                        title: '<<invoiceSetting>>',
                                        route: '/invoice-config',
                                        privilege: {
                                            path: 'invoiceConfig',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'posReceiptInvoiceConfig',
                                        title: '<<posReceiptInvoiceConfig>>',
                                        route: '/pos-receipt-invoice-config',
                                        privilege: {
                                            path: 'posReceiptInvoiceConfig',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'item',
                                        key: 'department',
                                        title: '<<department>>',
                                        route: '/department',
                                        privilege: {
                                            path: 'department',
                                            level: 'full',
                                        },
                                    },
                                    {
                                        type: 'menu',
                                        key: 'settingMore',
                                        title: '<<settingMore>>',
                                        privilege: {
                                            path: 'setting',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'orderSetting',
                                                title: '<<orderSetting>>',
                                                route: '/order-setting',
                                                privilege: {
                                                    path: 'orderSetting',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'printConfig',
                                                title: '<<printConfig>>',
                                                route: '/printSetting',
                                                privilege: {
                                                    path: 'printConfig',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'productAuditRules',
                                                title: '<<productAuditRules>>',
                                                route: '/productSetting',
                                                privilege: {
                                                    path: 'productAuditRules',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'broadcastConfig',
                                                title: '<<broadcastConfig>>',
                                                route: '/voiceSetting',
                                                privilege: {
                                                    path: 'broadcastConfig',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'commonSetting',
                                                title: '<<commonSetting>>',
                                                route: '/common-setting',
                                                privilege: {
                                                    path: 'commonSetting',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'menu',
                                                key: 'memberSecuritySetting',
                                                title: '<<memberSecuritySetting>>',
                                                privilege: {
                                                    path: 'memberSecuritySetting',
                                                    level: 'full',
                                                },
                                                items: [
                                                    {
                                                        type: 'item',
                                                        key: 'virtualNumberSetting',
                                                        title: '<<virtualNumberSetting>>',
                                                        route: '/virtual-number-setting',
                                                        privilege: {
                                                            path: 'virtualNumberSetting',
                                                            level: 'full',
                                                        },
                                                    },
                                                    {
                                                        type: 'item',
                                                        key: 'virtualNumberWhiteList',
                                                        title: '<<virtualNumberWhiteList>>',
                                                        route: '/virtual-number-white-list',
                                                        privilege: {
                                                            path: 'virtualNumberWhiteList',
                                                            level: 'full',
                                                        },
                                                    },
                                                ],
                                            },
                                            {
                                                type: 'item',
                                                key: 'securityConfig',
                                                title: '<<securityConfig>>',
                                                route: '/security-setting',
                                                privilege: {
                                                    path: 'securityConfig',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'MemberAssetsSetting',
                                                title: '<<MemberAssetsSetting>>',
                                                route: '/member-assets-setting',
                                                privilege: {
                                                    path: 'memberAssetsSetting',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'CrossborderTradeConfig',
                                                title: '<<CrossborderTradeConfig>>',
                                                route: '/crossborder-trade',
                                                privilege: {
                                                    path: 'CrossborderTradeConfig',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                type: 'item',
                                key: 'demoPage',
                                title: '<<demoPage>>',
                                icon: 'ellipsis',
                                route: '/components-demo',
                                privilege: {
                                    path: 'demoPage',
                                    level: 'full',
                                },
                            },
                            /*b2b2c功能，以下功能暂时隐藏，等待产品规划*/
                            /*{
                                type: 'menu',
                                key: 'unitedMerchant',
                                title: '联营管理',
                                privilege: {
                                    path: 'unitedMerchant',
                                    level: 'full',
                                },
                                items: [
                                    {
                                        type: 'menu',
                                        key: 'unitedStoreInfo',
                                        title: '专柜管理',
                                        privilege: {
                                            path: 'unitedStoreInfo',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'merchant',
                                                title: '专柜管理',
                                                route: '/merchant-join',
                                                privilege: {
                                                    path: 'merchant',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'unitedOrder',
                                        title: '订单管理',
                                        privilege: {
                                            path: 'unitedOrder',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'merchantOrder',
                                                title: '专柜订单管理',
                                                route: '/merchantOrder',
                                                privilege: {
                                                    path: 'merchantOrder',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'merchantOrderProcessBill',
                                                title: '专柜取消关闭处理单',
                                                route: '/merchantOrderprocessbill',
                                                privilege: {
                                                    path: 'merchantOrderProcessBill',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'merchantAfterSalesApply',
                                                title: '专柜售后申请单',
                                                route: '/merchantAfterSalesApply',
                                                privilege: {
                                                    path: 'merchantAfterSalesApply',
                                                    level: 'full',
                                                },
                                            },
                                            {
                                                type: 'item',
                                                key: 'merchantRefund',
                                                title: '专柜售后退款',
                                                route: '/merchantRefund',
                                                privilege: {
                                                    path: 'merchantRefund',
                                                    level: 'full',
                                                },
                                            },

                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'unitedAuthority',
                                        title: '权限管理',
                                        privilege: {
                                            path: 'unitedAuthority',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'merchantRole',
                                                title: '专柜角色权限管理',
                                                route: '/merchantRole',
                                                privilege: {
                                                    path: 'merchantRole',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                    {
                                        type: 'menu',
                                        key: 'unitedMember',
                                        title: '会员管理',
                                        privilege: {
                                            path: 'unitedMember',
                                            level: 'full',
                                        },
                                        items: [
                                            {
                                                type: 'item',
                                                key: 'merchantReviewAudit',
                                                title: '专柜会员评论',
                                                route: '/merchantReviewAudit',
                                                privilege: {
                                                    path: 'merchantReviewAudit',
                                                    level: 'full',
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },*/
                        ],
                    },
                ],
            },
            content: {
                items: [
                    {
                        component: 'Viewport',
                        className: 'content-viewport',
                        style: {
                            backgroundColor: '#fff',
                        },
                    },
                ],
            },
            header: {
                style: { overflowY: 'hidden' },
                items: [
                    {
                        component: 'UserMenu',
                        flex: '0 0 auto',
                    },
                ],
            },
            footer: {
                items: [
                    {
                        component: 'FooterInfoContainer',
                    },
                ],
            },
        },
    },

    // 路由定义
    routes: [
        {
            path: '/',
            component: 'SideNavFrameWrapper',
            childRoutes: [
                {
                    path: '/',
                    component: 'IframePage-dashboard',
                    breadcrumbName: 'Dashboard',
                },
            ],
        },
    ],
};
