import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { mapValues, defaults, find, get, debounce } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';
import './index.less';

export interface ReserveActivitiesEditFormProps extends FormComponentProps {
    entity: Entity;
    editStatus: string;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
}

class reserveActivitiesEditForm extends PureComponent<ReserveActivitiesEditFormProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }

    render() {
        const { entity, onSubmit, wrappedComponentRef, editStatus } = this.props;

        let editDisabled = false;
        if (editStatus === 'STARTED' || editStatus === 'COMPLETE') {
            editDisabled = true;
        }

        let fields = [
            {
                property: 'activityInfo.executeStatus',
                className: 'property-hide',
            },
            { property: 'activityInfo.name' },
            { property: 'activityInfo.dateRange' },
            { property: 'activityInfo.subsites' },
            {
                property: 'activityInfo.marketingSchedule',
            },
            { property: 'activityInfo.goods' },
            {
                property: 'activityInfo.reserveType',
                controlConfig: {
                    disabled: editDisabled,
                },
            },
            { property: 'activityInfo.maxOrderCount' },
            { property: 'activityInfo.description' },
            {
                property: 'roundInfo.period',
                controlConfig: {
                    disabled: editDisabled,
                },
            },
            {
                property: 'roundInfo.reserveTime',
                controlConfig: {
                    format: 'HH:mm',
                    tip: services.language.getText('yhkyjxyy'),
                    disabled: editDisabled,
                },
            },
            {
                property: 'roundInfo.time',
                controlConfig: {
                    format: 'HH:mm',
                    tip: services.language.getText('yhkyjxgm'),
                    disabled: editDisabled,
                },
            },
            {
                property: 'roundInfo.memberLimit',
            },
            {
                property: 'roundInfo.quantityLimit',
            },
            {
                property: 'seniorInfo.participateCondition',
            },
            {
                property: 'seniorInfo.receiptCertification',
            },
        ];

        let formProps = {
            entity,
            componentId: 'ReserveActivitiesEditFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'reserve-activities-edit-form-container',
            direction: 'horizontal',
            labelCol: 4,
            controlCol: 20,
            style: { width: 920 },
            fields: fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: services.language.getText('common.cancel'),
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    }
}

function mapDispatchToProps(state: any, props: any) {
    const { onSubmit, onFieldChange, entity, params } = props;
    let form: any;

    /**
     * 提交前额外验证
     * @param entityFields
     */
    const validate = (entity: any) => {
        //校验预约时间与抢购时间
        const reserveTime = entity.roundInfo.reserveTime[0];
        const time = entity.roundInfo.time[0];
        const reserveTimeStart = new Date('2021-1-1 ' + reserveTime.start + ':00').getTime();
        const reserveTimeEnd = new Date('2021-1-1 ' + reserveTime.end + ':00').getTime();
        const timeStart = new Date('2021-1-1 ' + time.start + ':00').getTime();
        if (timeStart - reserveTimeStart <= 0 || timeStart - reserveTimeEnd < 0) {
            AntMessage.error(services.language.getText('yyjssjyzyhdyqgkssj'));
            return false;
        }
        //校验预约条件配置输入值
        const participateCondition = entity.seniorInfo.participateCondition;
        if (participateCondition && participateCondition.length > 0) {
            const minRegisterDays: any = find(participateCondition, { code: 'MINREGISTERDAYS' });
            const consumption: any = find(participateCondition, { code: 'CONSUMPTIONT' });
            if (minRegisterDays.checked && !minRegisterDays.value) {
                AntMessage.error(services.language.getText('qtxyytjxzxddyz'));
                return false;
            }
            if (consumption.checked && !consumption.value) {
                AntMessage.error(services.language.getText('qtxyytjxzxddyz'));
                return false;
            }
        }
        return true;
    };

    let editStatus = get(entity, 'fields.activityInfo.executeStatus');

    return defaults(
        {
            onSubmit,
            onFieldChange,
            editStatus,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: debounce((event: any, fields: any) => {
                const entityFields = mapValues(fields, (field, name) => {
                    return field.value;
                });
                if (entityFields) {
                    const hasValidate = validate(entityFields);
                    if (hasValidate) {
                        entity.modify(entityFields, params);
                    }
                }
                return false;
            }, 300),
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(actions.unmountComponentAction('ReserveActivitiesEditFormContainer'));
            },
        }
    );
}
export const ReserveActivitiesEditFormView = connect(mapDispatchToProps)(reserveActivitiesEditForm);
