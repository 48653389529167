import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';

const api = services.api;
export const CreditEshopReportLoader: Loader = {
    get: async function(data, config) {
        const { id } = data;
        data.id = undefined;

        if (data.dateRange) {
            data.startTime = data.dateRange.start;
            data.endTime = data.dateRange.end;
            data.dateRange = undefined;
        }

        config.apiRoot = `${ENV.AUTH_API_ROOT}/COUPON`;
        config.apiPath = `/admin/credit_products/${id}/items`;

        const response: any = await api.get(data, config);

        response.result = response.result || [];
        response.result = response.result.map((item: any) => {
            item.id = item.orderNo;
            item.mobile = {
                text: item.mobile,
                url: '#/member/info/' + item.memberId,
            };
            const isCoupon = item.productType === 'VIRTUAL_COUPON';
            item.orderNo = {
                text: item.orderNo,
                url: isCoupon
                    ? `#/coupon-order/info/${item.orderId}/CREDIT_ESHOP/displayCouponsInfo`
                    : `/8005/order/viewDetail/${item.orderId}`,
            };
            item.serviceApplyNos = item.serviceApplyInfos?.map((info: any) => ({
                text: info.serviceApplyNo,
                url: isCoupon
                    ? `#/coupon-service-applies/info/${info.serviceApplyId}/displayCouponStatus`
                    : `/8006/afterSalesApply/detail/${info.serviceApplyId}`,
            }));
            let refundText = '无',
                refundUrl = '';
            if (item.refundAmount + item.refundPoint > 0) {
                refundText = '有退款（-';
                if (item.refundPoint) {
                    refundText += `${item.refundPoint}积分`;
                }
                if (item.refundPoint && item.refundAmount) {
                    refundText += '+';
                }
                if (item.refundAmount) {
                    refundText += `${services.language.getText(
                        'monetaryUnit'
                    )}${item.refundAmount.toFixed(2)}`;
                }
                refundText += '）';
                refundUrl = isCoupon ? '#/coupon-refund-order' : '/603/refund/refundList';
            }
            item.refundStatus = {
                text: refundText,
                url: refundUrl,
            };
            return item;
        });

        return response;
    },
};
