import React, { PureComponent } from 'react';
import { Table } from 'antd';
import { cloneDeep } from 'lodash';
import { PaginationConfig } from 'antd/lib/table/interface';
import { language } from '@comall-backend-builder/core/lib/services';
import { GiftProduct } from './gift-product-select';

interface GiftProductSelectorDisplayProps {
    data: GiftProduct;
}

/**
 * 优惠券展示和内容编辑
 */
export class GiftProductSelectorDisplay extends PureComponent<GiftProductSelectorDisplayProps> {
    getTableConfig = () => {
        return {
            rowKey: 'id',
            columns: [
                {
                    title: language.getText('lpmc'),
                    dataIndex: 'name',
                    key: 'name',
                    width: 350,
                },
                {
                    title: language.getText('dw'),
                    dataIndex: 'salesUnit',
                    key: 'salesUnit',
                },
                {
                    title: language.getText('zl_3'),
                    dataIndex: 'weight',
                    key: 'weight',
                },
                {
                    title: language.getText('marketPrice'),
                    dataIndex: 'marketPrice',
                    key: 'marketPrice',
                },
            ],
            scroll: { x: true },
            style: {
                marginTop: 20,
            },
        };
    };

    render() {
        const { data } = this.props;
        const pagination = false as PaginationConfig | false;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: cloneDeep([data]),
            pagination,
        };

        return (
            <div>
                <Table {...tableProps}></Table>
            </div>
        );
    }
}
