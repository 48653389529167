import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import {
    Button as AntButton,
    Form,
    AutoComplete,
    Select,
    Switch,
    List,
    InputNumber,
    message,
    Spin,
} from 'antd';
import { FormComponentProps } from 'antd/es/form';

import { api, errorHandle, language, navigation } from '@comall-backend-builder/core/lib/services';
import { options } from 'superagent';
import './index.less';

export interface InventoryRuleProps {
    id: string | number;
    row: any;
    entity: Entity;
    params: any;
    form: FormComponentProps['form'];
}
interface listItem {
    upperlimit: any;
    lowerlimit: any;
    tipsType: string;
    tips: string;
    stock: any;
    inventoryShowType?: string;
    prompt?: any;
}
interface InventoryState {
    loading: boolean;
    setList: listItem[];
    status: boolean;
}
interface GetRes {
    id: string;
    rule_list: any[];
    status: string;
}
interface PutRes {
    inventory_show_type: string;
    lowerlimit: string | number;
    upperlimit: string | number;
    prompt: any;
}
const formItemLayout = {
    labelCol: {
        xs: { span: 10 },
        sm: { span: 3 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 17 },
    },
};
const tailFormAddLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 10,
        },
        sm: {
            span: 4,
            offset: 3,
        },
    },
};
const tailFormItemLayout = {
    wrapperCol: {
        xs: {
            span: 24,
            offset: 10,
        },
        sm: {
            span: 18,
            offset: 18,
        },
    },
};
const dataSource = ['inventoryAdequate', 'inventoryNervous'];

class InventoryRule extends PureComponent<InventoryRuleProps, InventoryState> {
    state = {
        loading: false,
        status: false,
        setList: [],
    };
    componentDidMount() {
        this.getInitData();
    }
    addRules = () => {
        const { setList } = this.state;
        this.setState({
            setList: [
                ...setList,
                {
                    upperlimit: undefined,
                    lowerlimit: undefined,
                    tipsType: 'TIPS',
                    tips: '',
                    stock: undefined,
                },
            ],
        });
    };
    deleteItem = (index: number) => {
        const { setList } = this.state;
        if (setList.length < 2) {
            message.warning(language.getText('atLeastOneData'));
            return;
        }
        setList.splice(index, 1);
        this.setState({
            setList: [...setList],
        });
    };

    getInitData() {
        const { id } = this.props;
        api.get<GetRes>(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: `/admin/inventory_rule/${id}`,
            }
        )
            .then((data: any) => {
                const state = data.status === 'ENABLE';
                const ruleList = data?.ruleList || [];
                if (ruleList.length < 1) {
                    this.setState({
                        status: state,
                        setList: [
                            {
                                upperlimit: undefined,
                                lowerlimit: undefined,
                                tipsType: 'TIPS',
                                tips: '',
                                stock: undefined,
                            },
                        ],
                    });
                } else {
                    const setList = ruleList.map((item: listItem) => {
                        if (item.inventoryShowType === 'STOCK') {
                            item.stock = undefined;
                        } else {
                            item.tips = item.prompt;
                        }
                        return {
                            ...item,
                            tipsType: item.inventoryShowType,
                        };
                    });
                    this.setState({
                        status: state,
                        setList,
                    });
                }
            })
            .catch((error) => {
                errorHandle(error);
            });
    }
    handleCancel = () => {};
    handleSubmit = (e: any) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.judgmentRule();
            }
        });
    };
    judgmentRule = () => {
        const { setList, status } = this.state;

        let isError = '';
        const upArr: any[] = [];
        const loArr: any[] = [];
        setList.forEach((item: listItem) => {
            if (!item.upperlimit) {
                isError = language.getText('selectInventoryUpper');
                return;
            } else {
                upArr.push(item.upperlimit);
            }
            if (!item.lowerlimit) {
                isError = language.getText('selectInventoryLower');
                return;
            } else {
                loArr.push(item.lowerlimit);
            }
            if (item.lowerlimit >= item.upperlimit) {
                isError = language.getText('inventoryLowerNoThanInventoryUpper');
                return;
            }
            if (item.tipsType === 'TIPS' && !item.tips) {
                isError = language.getText('selectHintLanguage');
                return;
            }
        });
        const lastUp = upArr.sort((a: number, b: number) => {
            return a - b;
        });
        const lastLo = loArr.sort((a: number, b: number) => {
            return a - b;
        });
        for (let i = 1; i < lastLo.length; i++) {
            if (lastLo[i] <= lastUp[i - 1]) {
                isError = language.getText('upperAndLowerLimits');
            }
        }
        if (isError) {
            message.error(isError);
        } else {
            const dataParams: {
                rule_list: PutRes[];
                status: string;
            } = {
                rule_list: [],
                status: status ? 'ENABLE' : 'DISABLE',
            };
            dataParams.rule_list = setList.map((item: listItem) => {
                return {
                    inventory_show_type: item.tipsType,
                    lowerlimit: item.lowerlimit,
                    upperlimit: item.upperlimit,
                    prompt: item.tipsType === 'TIPS' ? item.tips : item.stock,
                };
            });
            this.submitApiData(dataParams);
        }
    };
    submitApiData = (data: { [key: string]: any } | undefined) => {
        this.setState({ loading: true });
        const { id } = this.props;
        api.put(
            {
                ...data,
            },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                apiPath: `/admin/inventory_rule/${id}`,
            }
        )
            .then(() => {
                this.setState({ loading: false });
                message.success(services.language.getText('common.saveSuccess'));
            })
            .catch((error) => {
                this.setState({ loading: false });
                errorHandle(error);
            });
    };
    changeStatus = (status: boolean) => {
        this.setState({
            status,
        });
    };
    changeValue = (value: any, option: any, index: number, type: string, item: listItem) => {
        switch (type) {
            case 'upperlimit':
                item.upperlimit = value;
                break;
            case 'lowerlimit':
                item.lowerlimit = value;
                break;
            case 'tipsType':
                item.tipsType = value;
                break;
            case 'tips':
                value = value.toString().slice(0, 30);
                item.tips = value;
                break;
            case 'stock':
                item.stock = value;
                break;
            default:
                break;
        }
        const { setList } = this.state;
        this.setState({
            setList: [...setList],
        });
    };
    render() {
        const {
            form: { getFieldDecorator },
        } = this.props;
        const { setList, status, loading } = this.state;
        return (
            <>
                <Spin spinning={loading}>
                    <Form {...formItemLayout} onSubmit={this.handleSubmit}>
                        <Form.Item
                            label={language.getText('openAndClose')}
                            className="public-label"
                        >
                            {getFieldDecorator('status')(
                                <Switch
                                    checkedChildren={language.getText('kai')}
                                    unCheckedChildren={language.getText('guan')}
                                    checked={status}
                                    onChange={this.changeStatus}
                                />
                            )}
                        </Form.Item>
                        <Form.Item
                            label={language.getText('setInventory')}
                            className="public-label"
                        >
                            {getFieldDecorator('InventoryRuleForm')(
                                <List
                                    className="rule-list"
                                    itemLayout="horizontal"
                                    dataSource={setList}
                                    renderItem={(item: listItem, index: number) => (
                                        <List.Item actions={[]}>
                                            <section className="pub-section">
                                                <AntButton>
                                                    {language.getText('inventoryDownLimit')}
                                                </AntButton>
                                                <InputNumber
                                                    min={1}
                                                    step={1}
                                                    precision={0}
                                                    placeholder={language.getText('selectPlease')}
                                                    value={item.lowerlimit}
                                                    onChange={(value) =>
                                                        this.changeValue(
                                                            value,
                                                            null,
                                                            index,
                                                            'lowerlimit',
                                                            item
                                                        )
                                                    }
                                                />
                                            </section>
                                            <section className="pub-section">
                                                <AntButton>
                                                    {language.getText('inventoryUpLimit')}
                                                </AntButton>
                                                <InputNumber
                                                    min={1}
                                                    step={1}
                                                    precision={0}
                                                    placeholder={language.getText('selectPlease')}
                                                    value={item.upperlimit}
                                                    onChange={(value) =>
                                                        this.changeValue(
                                                            value,
                                                            null,
                                                            index,
                                                            'upperlimit',
                                                            item
                                                        )
                                                    }
                                                />
                                            </section>
                                            <section className="pub-section tips">
                                                <AntButton>
                                                    {' '}
                                                    {language.getText('hintLanguageType')}
                                                </AntButton>
                                                <Select
                                                    value={item.tipsType}
                                                    onChange={(val: any) =>
                                                        this.changeValue(
                                                            val,
                                                            null,
                                                            index,
                                                            'tipsType',
                                                            item
                                                        )
                                                    }
                                                >
                                                    <Select.Option key={'TIPS'} value={'TIPS'}>
                                                        {language.getText('hintLanguage')}
                                                    </Select.Option>
                                                    <Select.Option key={'STOCK'} value={'STOCK'}>
                                                        {language.getText('inventoryNum')}
                                                    </Select.Option>
                                                </Select>
                                            </section>
                                            <section className="pub-section select-change">
                                                {item?.tipsType === 'TIPS' ? (
                                                    <AutoComplete
                                                        style={{ width: 200 }}
                                                        dataSource={dataSource.map((item: any) => {
                                                            return language.getText(item);
                                                        })}
                                                        placeholder={language.getText(
                                                            'selectCustomHint'
                                                        )}
                                                        value={item.tips}
                                                        onChange={(value: any) =>
                                                            this.changeValue(
                                                                value,
                                                                options,
                                                                index,
                                                                'tips',
                                                                item
                                                            )
                                                        }
                                                    />
                                                ) : (
                                                    <InputNumber
                                                        min={1}
                                                        step={1}
                                                        precision={0}
                                                        placeholder={language.getText(
                                                            'actualInventoryQuantity'
                                                        )}
                                                        value={item.stock}
                                                        disabled
                                                        onChange={(value) =>
                                                            this.changeValue(
                                                                value,
                                                                null,
                                                                index,
                                                                'stock',
                                                                item
                                                            )
                                                        }
                                                    />
                                                )}
                                            </section>
                                            <AntButton
                                                type="link"
                                                onClick={(event) => this.deleteItem(index)}
                                            >
                                                {services.language.getText('common.delete')}
                                            </AntButton>
                                        </List.Item>
                                    )}
                                />
                            )}
                        </Form.Item>
                        <Form.Item {...tailFormAddLayout}>
                            <AntButton type="primary" onClick={this.addRules}>
                                {language.getText('addRule')}
                            </AntButton>
                        </Form.Item>
                        <Form.Item {...tailFormItemLayout} className="public-label pdt20">
                            <AntButton style={{ marginRight: 10 }} onClick={navigation.goBack}>
                                {language.getText('common.cancel')}
                            </AntButton>
                            <AntButton
                                type="primary"
                                htmlType="submit"
                                // loading={confirmLoading}
                            >
                                {language.getText('common.submit')}
                            </AntButton>
                        </Form.Item>
                    </Form>
                </Spin>
            </>
        );
    }
}
function mapStateToProps(state: any, props: InventoryRuleProps) {
    const { entity, row, params, id } = props;
    return {
        id,
        entity,
        row,
        params,
    };
}
export const InventoryRuleForm = connect(mapStateToProps)(Form.create()(InventoryRule));
