import React, { PureComponent } from 'react';
import { Table, Button, message, Popconfirm } from 'antd';
import { cloneDeep, remove, find } from 'lodash';
import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';
import { services } from '@comall-backend-builder/core';

interface ProductSelectedDisplayForPresaleProps {
    data: any[];
    onChange: (data: any[]) => void;
    modifiable: boolean;
}

/**
 * 自提规则选择商品展示和内容编辑
 */
class ProductSelectedDisplayForSelfDelivery extends PureComponent<
    ProductSelectedDisplayForPresaleProps
> {
    selectedRows: any[] = [];

    getTableConfig = () => {
        const { modifiable } = this.props;
        let config: any = {
            rowKey: 'id',
            columns: [
                {
                    title: services.language.getText('components.Products.name'),
                    dataIndex: 'productName',
                    key: 'productName',
                    width: 140,
                    render: (i: string) => (
                        <span title={i} className="goods-field-name">
                            {i}
                        </span>
                    ),
                },
                {
                    title: services.language.getText('goodName'),
                    dataIndex: 'name',
                    key: 'name',
                    width: 140,
                    render: (i: string) => (
                        <span title={i} className="goods-field-name">
                            {i}
                        </span>
                    ),
                },
                {
                    title: services.language.getText('productCode'),
                    dataIndex: 'productCode',
                    key: 'productCode',
                    width: 100,
                },
                {
                    title: services.language.getText('goodCode'),
                    dataIndex: 'goodsCode',
                    key: 'goodsCode',
                    width: 100,
                },
            ],
            scroll: {
                y: 500,
            },
        };
        if (modifiable) {
            config.columns.push({
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                key: 'operation',
                fixed: 'right' as const,
                width: 100,
                render: (i: any, row: any) => {
                    return (
                        <Button type={'link'} onClick={this.onRemove(row.id)}>
                            {services.language.getText('common.delete')}
                        </Button>
                    );
                },
            });
            config.rowSelection = {
                onChange: (selectedRowKeys: any[], selectedRows: any[]) => {
                    this.selectedRows = selectedRows;
                },
            };
        }
        return config;
    };

    renderFooter = () => {
        return (
            <div className="product-selected-footer">
                <div className="batch-remove">
                    <Popconfirm
                        onConfirm={this.batchRemove()}
                        title={services.language.getText('confirmDeleteColumn')}
                        okText={language.getText('common.ok')}
                        cancelText={language.getText('common.cancel')}
                    >
                        <Button className="setting-item-ok" type="default">
                            {services.language.getText('batchDelete')}
                        </Button>
                    </Popconfirm>
                </div>
            </div>
        );
    };

    onRemove = (id: string) => {
        const { data, onChange } = this.props;
        return () => {
            const result = cloneDeep(data);
            remove(result, (i) => id === i.id);
            onChange(result);
        };
    };

    batchRemove = () => {
        return () => {
            // @ts-ignore：
            const selectedRow = this.selectedRows;
            if (!selectedRow || selectedRow.length === 0) {
                message.info(services.language.getText('selectBatchDeleteColumn'));
                return;
            }
            const { data, onChange } = this.props;
            const result = cloneDeep(data);
            remove(result, (i) => !!find(selectedRow, (j) => j.id === i.id));
            onChange(result);
        };
    };

    render() {
        const { data, modifiable } = this.props;
        let tableConfig = this.getTableConfig();
        const tableProps = { ...tableConfig, dataSource: data };
        return (
            <div
                className={
                    'product-select-display-for-self-delivery ' + (modifiable ? 'modify' : '')
                }
            >
                <Table {...tableProps} pagination={false}></Table>
                {modifiable && this.renderFooter()}
            </div>
        );
    }
}

export { ProductSelectedDisplayForSelfDelivery };
