import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach } from 'lodash';
import { MerchantSelfType } from '../../config/merchant/merchant-manager/merchant-self';
import { InvoiceModeEnum } from '../../types/mode/string/invoice-mode/invoice-mode';

const api = services.api;
export const NormalInvoiceConfigsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`;
        config.apiPath = '/admin/invoice_configs';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/invoice_configs/${id}`;
        } else {
            data.type = 'GOODS';
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                const merchantSelfTypeOptions: Record<any, any> = {
                    [MerchantSelfType.JOINT]: '联营',
                    [MerchantSelfType.MERCHANT_SELF]: '自营',
                    [MerchantSelfType.LEASE]: '租赁',
                    [MerchantSelfType.LEAGUE]: '加盟',
                    [MerchantSelfType.OTHER]: '其他',
                    [MerchantSelfType.SELF]: '自营',
                };
                res.baseInfo = {
                    subsite: res.subsite,
                    subsiteInvoiceInfo:
                        res.subsite && res.subsite.format === 'STORE'
                            ? {
                                  merchantTypes: res.merchantTypes ? res.merchantTypes : null,
                                  merchantScope: res.merchantScope,
                                  merchants:
                                      res.merchants && res.merchants.length > 0
                                          ? res.merchants.map((m: any) => ({
                                                ...m,
                                                id: m.subsiteId + ',' + m.id,
                                                merchantId: m.id,
                                                merchantName: m.name,
                                                merchantType: merchantSelfTypeOptions[m.type],
                                                merchantStatus: m.status,
                                                subSiteName: m.subsiteName,
                                            }))
                                          : [],
                              }
                            : undefined,
                    invoiceMode: res.invoiceMode,
                };
                res.sellerInfo = {
                    sellerName: res.seller.name,
                    sellerCode: res.seller.code,
                    sellerTaxNo: res.seller.taxNo,
                    sellerAddress: res.seller.address,
                    sellerPhone: res.seller.phone,
                    sellerBank: res.seller.bank,
                    sellerBankAccount: res.seller.bankAccount,
                    sellerPayee: res.seller.payee,
                    sellerReviewer: res.seller.reviewer,
                    sellerDrawer: res.seller.drawer,
                };
                res.invoiceSystemInfo = {
                    invoiceSystem: res.invoiceThirdSystem,
                };
                res.otherInfo = {
                    isEffectiveDaysLimit: res.effectiveDaysLimit ? 'true' : 'false',
                    effectiveDaysLimit: res.effectiveDaysLimit,
                    isChangeCountLimit: res.changeCountLimit ? 'true' : 'false',
                    changeCountLimit: res.changeCountLimit,
                    amountTopLimit: res.amountTopLimit,
                };
                // res.specialInfo = {
                //     extNo: res.extNo,
                //     clientNo: res.clientNo,
                // };
            } else {
                res.result = res.result.map((item: any) => {
                    let subsiteName = '';
                    if (item.subsites && item.subsites.length > 0) {
                        subsiteName = item.subsites[0].name;
                    }
                    item.subsiteName = subsiteName;
                    item.name = item.invoiceSeller && item.invoiceSeller.name;
                    item.taxNo = item.invoiceSeller && item.invoiceSeller.taxNo;
                    return item;
                });
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`;
        config.apiPath = '/admin/invoice_configs';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-INVOICE`;
        config.apiPath = `/admin/invoice_configs/${id}`;
        const newParams: any = dataParser(params);
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    newParams.type = 'GOODS';
    if (params.baseInfo && params.otherInfo) {
        newParams.amountTopLimit =
            params.baseInfo.invoiceMode === InvoiceModeEnum.TAX_CONTROL
                ? params.otherInfo.amountTopLimit
                : undefined;
        newParams.changeCountLimit =
            params.otherInfo.isChangeCountLimit === 'true'
                ? params.otherInfo.changeCountLimit
                : null;
        newParams.effectiveDaysLimit =
            params.otherInfo.isEffectiveDaysLimit === 'true'
                ? params.otherInfo.effectiveDaysLimit
                : null;
        if (params.baseInfo.subsite && params.baseInfo.subsite.format === 'STORE') {
            //后端要求SELF一直带上，无论新增或编辑
            if (!params.baseInfo.subsiteInvoiceInfo.merchantTypes.includes('SELF')) {
                params.baseInfo.subsiteInvoiceInfo.merchantTypes.push('SELF');
            }
            newParams.merchantTypes = params.baseInfo.subsiteInvoiceInfo.merchantTypes;
            newParams.merchantScope = params.baseInfo.subsiteInvoiceInfo.merchantScope;
            newParams.merchantIds = params.baseInfo.subsiteInvoiceInfo.merchants.map(
                (m: any) => m.merchantId
            );
        }
        if (params.baseInfo.subsite && params.baseInfo.subsite.format === 'SUPERMARKET') {
            newParams.merchantTypes = ['SELF'];
            newParams.merchantScope = 'ALL';
        }
        newParams.invoiceTypes = ['NORMAL'];
        newParams.invoiceMode = params.baseInfo.invoiceMode;
        newParams.seller = {
            address: params.sellerInfo.sellerAddress,
            bank: params.sellerInfo.sellerBank,
            bankAccount: params.sellerInfo.sellerBankAccount,
            drawer: params.sellerInfo.sellerDrawer,
            name: params.sellerInfo.sellerName,
            code: params.sellerInfo.sellerCode,
            payee:
                params.baseInfo.invoiceMode === InvoiceModeEnum.TAX_CONTROL
                    ? params.sellerInfo.sellerPayee
                    : undefined,
            phone: params.sellerInfo.sellerPhone,
            reviewer:
                params.baseInfo.invoiceMode === InvoiceModeEnum.TAX_CONTROL
                    ? params.sellerInfo.sellerReviewer
                    : undefined,
            taxNo: params.sellerInfo.sellerTaxNo,
        };
        let invoiceThirdSystemParams: any = {};
        const invoiceThirdSystem = params.invoiceSystemInfo.invoiceSystem;
        if (invoiceThirdSystem.params && invoiceThirdSystem.params.length > 0) {
            forEach(invoiceThirdSystem.params, (p) => {
                invoiceThirdSystemParams[p.field] = p.value;
            });
        }
        newParams.invoiceThirdSystem = {
            id: invoiceThirdSystem.id,
            params: JSON.stringify(invoiceThirdSystemParams),
        };
        newParams.subsiteId = params.baseInfo.subsite && params.baseInfo.subsite.id;
    }
    return newParams;
}
