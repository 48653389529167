import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { EntityButton } from '../../../components';

export class EntityButtonMode extends modes.StringMode {
    getAvailableDisplayComponent(value: any, displayInfo: any): JSX.Element {
        return <EntityButton {...displayInfo} />;
    }
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <EntityButton {...displayInfo} />;
    }
}
