import React, { Component } from 'react';
import { default as Radio, RadioGroupProps as AntRadioGroupProps } from 'antd/lib/radio';
import { find, isEqual } from 'lodash';
import { QuestionPoprver } from '../question-popover';
import './index.less';
import message from 'antd/lib/message';
import { language, privilege } from '@comall-backend-builder/core/lib/services';
import { TipsModal, TipsModalContentType } from '../tips-modal';

interface StringOptionsRadioPlusTip {
    /**
     * 提示文案
     */
    text?: string;
    /**
     *
     */
    hrefText?: {
        /**
         * 链接
         */
        href: string;
        /**
         * 链接文字
         */
        text: string;
        /**
         * 链接类型
         */
        target?: '_self' | '_parent' | '_top' | '_blank';
    };
    tipsModalConfig?: {
        showIcon?: boolean;
        /**
         * 气泡内容
         */
        contentType: TipsModalContentType;
        /**
         * 图标类型
         */
        iconType?: string;
        /**
         * 图标样式
         */
        iconStyle?: any;
        text?: string;
        textColor?: string;
        title: '';
        width?: number;
    };
}
export interface StringOptionsRadioPlusOption {
    disabled?: boolean;
    /**
     * 候选项id
     */
    value: string;
    /**
     * 候选项显示名称
     */
    name: string;
    /**
     * 提示语
     */
    tip?: StringOptionsRadioPlusTip;
    /**
     * 问号气泡提示
     */
    question?: string;
}

export interface StringOptionsRadioPlusProps
    extends Pick<AntRadioGroupProps, 'value' | 'disabled'> {
    /**
     * 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    name: string;
    /**
     * 内容改变回调
     * @param value 新值
     * @param name 输入组件的 name，作为该输入组件在其所属表单内的唯一识别符
     */
    onChange: (value: string, name: string) => void;
    /**
     * 候选项集合
     */
    setOptions: Array<StringOptionsRadioPlusOption>;
    /**
     * 样式模式 vertical纵向 horizontal横向
     */
    mode: 'vertical' | 'horizontal';
    privilege?: {
        path: string;
        level: 'none' | 'view' | 'modify' | 'full';
    };
}

export class StringOptionsRadioPlus extends Component<StringOptionsRadioPlusProps> {
    onChange = (e: any) => {
        const { name, onChange } = this.props;

        if (onChange) {
            onChange(e.target.value, name);
        }
    };
    componentWillReceiveProps(nextProps: any) {
        const { name, value, setOptions, onChange, defaultValueIndex } = nextProps;

        if (isEqual(this.props.setOptions, setOptions)) {
            return;
        }

        if (value) {
            if (!find(setOptions, { id: value })) {
                onChange(undefined, name);
            }
        } else if (defaultValueIndex !== undefined) {
            onChange(setOptions[defaultValueIndex].id, name);
        }
    }

    shouldComponentUpdate(nextProps: any) {
        return !isEqual(this.props, nextProps);
    }
    tips = (messageTxt: any) => {
        if (messageTxt) {
            message.warning(language.getText('notPrivilege') + messageTxt);
            return;
        }
        message.warning(language.getText('noPerationPermission'));
    };
    renderTip = (tip: StringOptionsRadioPlusTip) => {
        const { privilege: componentPrivilege } = this.props;
        if (componentPrivilege) {
            const { path, level } = componentPrivilege;
            if (!privilege.check(path, level)) {
                return (
                    tip && (
                        <div className="string-option-radio-plus-tip-wrap">
                            {tip.text && (
                                <span className="string-option-radio-plus-tip">{tip.text}</span>
                            )}
                            {tip.hrefText && (
                                <span
                                    style={{ color: '#1890ff' }}
                                    onClick={() => {
                                        this.tips(tip?.hrefText?.text);
                                    }}
                                >
                                    {tip.hrefText.text}
                                </span>
                            )}
                        </div>
                    )
                );
            }
        }
        return (
            tip && (
                <div className="string-option-radio-plus-tip-wrap">
                    {tip.text && <span className="string-option-radio-plus-tip">{tip.text}</span>}
                    {tip.hrefText && (
                        <a
                            className="string-option-radio-plus-a"
                            href={'/#' + tip.hrefText.href}
                            target={tip.hrefText.target ? tip.hrefText.target : '_self'}
                        >
                            {tip.hrefText.text}
                        </a>
                    )}
                    {tip.tipsModalConfig && (
                        <div style={{ display: 'inline-block' }}>
                            <TipsModal {...tip.tipsModalConfig} />
                        </div>
                    )}
                </div>
            )
        );
    };

    renderVertical = () => {
        const { value, disabled, setOptions } = this.props;
        const radioGroupProps = {
            value,
            disabled,
        };
        return (
            <div className="string-option-radio-plus">
                <Radio.Group {...radioGroupProps} onChange={this.onChange}>
                    {setOptions &&
                        setOptions.map((option) => {
                            return (
                                <div className="string-option-radio-plus-item" key={option.value}>
                                    <Radio
                                        value={option.value}
                                        disabled={option.disabled ? option.disabled : false}
                                    >
                                        {option.name}
                                        {option.question && (
                                            <QuestionPoprver content={option.question} />
                                        )}
                                    </Radio>
                                    {option.tip && this.renderTip(option.tip)}
                                </div>
                            );
                        })}
                </Radio.Group>
            </div>
        );
    };
    renderHorizontal = () => {
        const { value, disabled, setOptions } = this.props;
        const radioGroupProps = {
            value,
            disabled,
        };
        const option = find(setOptions, { value: value });
        return (
            <div className="string-option-radio-plus-horizontal">
                <div>
                    <Radio.Group {...radioGroupProps} onChange={this.onChange}>
                        {setOptions &&
                            setOptions.map((option) => {
                                return (
                                    <Radio
                                        value={option.value}
                                        disabled={option.disabled ? option.disabled : false}
                                    >
                                        {option.name}
                                        {option.question && (
                                            <QuestionPoprver content={option.question} />
                                        )}
                                    </Radio>
                                );
                            })}
                    </Radio.Group>
                </div>
                {option && option.tip && this.renderTip(option.tip)}
            </div>
        );
    };
    render() {
        const { mode } = this.props;
        const modeType = mode || 'vertical';
        return modeType === 'vertical' ? this.renderVertical() : this.renderHorizontal();
    }
}
