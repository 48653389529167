import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { SelectGoods } from '../../../containers/select-goods';

export class GoodsSelector extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <SelectGoods {...displayInfo} />;
    }
}
