import { channelOptions } from './types';
import { EntityButtonProps } from '../../../../components/entity-button/index';
import { Config } from '@comall-backend-builder/core/lib/parser';
import { services } from '@comall-backend-builder/core';
import { isEmpty } from 'lodash';
import { ForwardDataCenterModal } from '../../../../services';
const api = services.api;

const CouponConsumeTableItem =
    ENV.TENANT === 'wfj'
        ? {
              component: 'PageTip',
              props: {
                  tips: ['<<dhxddsjzzc>>'],
                  style: {
                      width: '100%',
                      height: '380px',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                  },
                  tipStyle: {
                      fontSize: '14px',
                  },
              },
          }
        : {
              component: 'DataTable',
              scroll: {
                  x: 1800,
              },
              loadFirstPage: true,
              pagination: {
                  pageSize: 10,
                  showSizeChanger: true,
                  showQuickJumper: true,
                  pageSizeOptions: ['10', '15', '20'],
                  showTotal(total: number) {
                      return services.interpolate(services.language.getText('total'), {
                          total,
                      });
                  },
              },
              columns: [
                  {
                      property: 'channelName',
                      width: 100,
                  },
                  {
                      property: 'createTime',
                      width: 200,
                  },
                  {
                      property: 'subsiteName',
                  },
                  {
                      property: 'merchantName',
                  },
                  {
                      property: 'consumeUser',
                  },
                  {
                      property: 'consumeTypeName',
                  },
                  {
                      property: 'couponName',
                      width: 180,
                  },
                  {
                      property: 'couponNo',
                      width: 240,
                  },
                  {
                      property: 'consumeOrderNo',
                      width: 260,
                  },
                  {
                      property: 'mobile',
                      width: 150,
                  },
                  {
                      property: 'requestId',
                      width: 150,
                  },
              ],
              actionColumn: {},
          };

const CouponConsumeTableSubmit =
    ENV.TENANT === 'wfj'
        ? {}
        : {
              submit: {
                  text: '<<common.search>>',
              },
              reset: {
                  text: '<<common.reset>>',
              },
          };

export const config: Config = {
    entities: {
        CouponConsumeEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
            apiPath: '/loader/admin/coupon_reports/coupon_consume',
            filters: {
                subsiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<hxmd>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                    controlConfig: { placeholder: '<<selectSubsite>>', style: { width: 240 } },
                },
                merchantId: {
                    displayName: '<<hxzj>>',
                    type: 'string.filterOptions.autoComplete',
                    options: [],
                    source: {
                        apiPath: '/loader/admin/subsites/mine/merchants',
                        apiRoot: `${ENV.AUTH_API_ROOT}/WEB-API`,
                        loadOnInit: false,
                        //专柜默认正序排序
                        params: {
                            sequence: 'code',
                            order: 'asc',
                        },
                    },
                    controlConfig: {
                        placeholder: '<<pleaseSelectMerchant>>',
                        style: { width: 240 },
                        dependences: 'subsiteId',
                        paramsName: 'subSiteName',
                        needDependences: true,
                        needDependencesMessage: '<<selectBeforeMerchant>>',
                    },
                },
                channel: {
                    type: 'string.options.select',
                    displayName: '<<usedChannelLimit>>',
                    options: channelOptions,
                },
                couponName: {
                    type: 'string.popover',
                    displayName: '<<couponName>>',
                },
                couponNo: {
                    type: 'string',
                    displayName: '<<yhqqm>>',
                },
                dateRange: {
                    type: 'object.dateRangePlus',
                    displayName: '<<hxsj>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
            },
            properties: {
                channelName: {
                    type: 'string',
                    displayName: '<<usedChannelLimit>>',
                },
                createTime: {
                    type: 'string',
                    displayName: '<<hxsj>>',
                },
                subsiteName: {
                    type: 'string',
                    displayName: '<<hxmdmc>>',
                },
                merchantName: {
                    type: 'string',
                    displayName: '<<hxzj>>',
                },
                consumeUser: {
                    type: 'string',
                    displayName: '<<hxdy>>',
                },
                consumeTypeName: {
                    type: 'string',
                    displayName: '<<hxfs>>',
                },
                couponName: {
                    type: 'string',
                    displayName: '<<couponName>>',
                },
                couponNo: {
                    type: 'string',
                    displayName: '<<yhqqm>>',
                },
                consumeOrderNo: {
                    type: 'string',
                    displayName: '<<hxjylsh>>',
                },
                mobile: {
                    type: 'string',
                    displayName: '<<hysjh>>',
                },
                requestId: {
                    type: 'string',
                    displayName: '<<hxlsh>>',
                },
            },
        },
    },
    components: {
        CouponConsumeView: {
            component: 'Viewport',
            entity: 'CouponConsumeEntity',
        },
        CouponConsumePage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'CouponConsumeFilter',
                },
                { component: 'CouponConsumeTable' },
            ],
        },
        CouponConsumeFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            ...CouponConsumeTableSubmit,
            fields: [
                {
                    property: 'subsiteId',
                },
                {
                    property: 'merchantId',
                },
                {
                    property: 'channel',
                    controlConfig: {
                        style: { width: 240 },
                    },
                },

                {
                    property: 'couponName',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<qsryhqmc>>',
                    },
                },
                {
                    property: 'couponNo',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<qsryhqqm>>',
                    },
                },
                {
                    property: 'dateRange',
                    controlConfig: {
                        style: { width: 240 },
                    },
                },
                {
                    property: 'mobile',
                    controlConfig: {
                        style: { width: 240 },
                        placeholder: '<<qsrhysjh>>',
                    },
                },
            ],
        },
        CouponConsumeTable: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'EntityButton',
                    props: {
                        type: 'primary',
                        children: '<<exportSearchResult>>',
                        style: {
                            width: 110,
                            marginBottom: 24,
                            marginTop: 17,
                        },
                        onClick: (_: any, entityButtonProps: EntityButtonProps) => {
                            const params: any = Object.assign(
                                {},
                                entityButtonProps.entity.params,
                                entityButtonProps.entity.filters,
                                {
                                    exchangeStatus: entityButtonProps.entity.filters
                                        ? (entityButtonProps.entity.filters as any).redeemStatus
                                        : undefined,
                                    redeemStatus: undefined,
                                }
                            );
                            if (!isEmpty(params.dateRange)) {
                                params.startTime = params.dateRange.start + ' 00:00:00';
                                params.endTime = params.dateRange.end + ' 23:59:59';
                                delete params.dateRange;
                            }
                            params.subsiteId =
                                params.subsiteId && params.subsiteId.id
                                    ? params.subsiteId.id
                                    : params.subsiteId;
                            params.merchantId =
                                params.merchantId && params.merchantId.id
                                    ? params.merchantId.id
                                    : params.merchantId;
                            const config = {
                                apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                                apiPath: '/admin/coupon_reports/coupon_consume/download',
                            };

                            api.get(params, config)
                                .then(() => {
                                    ForwardDataCenterModal();
                                })
                                .catch((error) => {
                                    services.errorHandle(error);
                                });
                        },
                    },
                },
                CouponConsumeTableItem,
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/coupon-consume-report',
                    component: 'CouponConsumeView',
                    breadcrumbName: '<<couponConsumeReport>>',
                    privilege: {
                        path: 'couponConsumeReport',
                        level: 'full',
                    },
                    childRoutes: [{ path: '/', component: 'CouponConsumePage' }],
                },
            ],
        },
    ],
};
