import * as React from 'react';
import { ObjectMode } from '@comall-backend-builder/types';
import { DeliveryScene } from './delivery-scene';

export class ObjectDeliverySceneMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <DeliveryScene {...controlInfo} />;
    }
}
