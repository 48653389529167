import React, { PureComponent, createElement, Fragment } from 'react';
// import { api } from '@comall-backend-builder/core/lib/services';
import { errorHandle } from '../../../applications/cae/services/error-handle';
import PropTypes from 'prop-types';
import { ComponentsManager } from '@comall-backend-builder/core';
import { connect } from 'react-redux';
import {
    message as AntMessage,
    Modal,
    Form,
    InputNumber,
    DatePicker,
    Row,
    Col,
    Descriptions,
} from 'antd';
import { debounce, get } from 'lodash';
import moment from 'moment';
import { api, language } from '@comall-backend-builder/core/lib/services';
import { NewMallActivityTicketType } from '../../../components';
import '../review-new-mall-activity-button/index.less';
const FormItem = Form.Item;
const formItemLayout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 4 },
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
    },
};
const ConsumeNewMallActivityForm = Form.create({ name: 'review' })(
    class extends React.Component {
        static propTypes: {
            waitConsumeQuantity: any;
            onSubmit: PropTypes.Requireable<(...args: any[]) => any>;
        };
        handleReset = () => {
            const { form } = this.props as any;
            form.resetFields();
        };
        handleSubmit = (e: any) => {
            const { form, onSubmit } = this.props as any;
            form.validateFields((err: any, values: any) => {
                onSubmit(values);
            });
        };
        render() {
            const { form, waitConsumeQuantity } = this.props as any;
            const { getFieldDecorator } = form;
            return (
                <Form
                    style={{ marginTop: 15 }}
                    ref="reviewFrom"
                    {...formItemLayout}
                    onSubmit={this.handleSubmit}
                >
                    <FormItem label={'核销数量'} style={{ marginBottom: 15 }}>
                        {getFieldDecorator('waitConsumeQuantity', {
                            initialValue: waitConsumeQuantity || 1,
                            rules: [
                                { required: true, message: '请输入核销数量' },
                                {
                                    validator: (rule: any, value: any, callback: any) => {
                                        if (value && value > 0) {
                                            callback();
                                        } else {
                                            callback('请输入正整数');
                                        }
                                    },
                                },
                            ],
                        })(<InputNumber />)}
                    </FormItem>
                    <FormItem label={'核销时间'}>
                        {getFieldDecorator('consumeTime', {
                            initialValue: moment(new Date(), 'YYYY-MM-DD HH:mm:ss'),
                            rules: [{ required: true, message: '请选择日期' }],
                        })(
                            <DatePicker
                                showTime
                                format="YYYY-MM-DD HH:mm:ss"
                                placeholder="选择日期"
                            />
                        )}
                    </FormItem>
                </Form>
            );
        }
    }
);

class ConsumeNewMallActivityView extends PureComponent<any> {
    state = { visible: false, reviewInfo: {}, loading: false };
    openModal = () => {
        this.setState({ visible: true });
        this.loadData();
    };

    loadData = async () => {
        const { id } = this.props.row;
        try {
            let reviewInfo: any = await api.get(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
                    apiPath: `/admin/v1/mall_activity/ticket_purchase_records/${id}`,
                }
            );
            const {
                roundStartDate,
                roundEndDate,
                roundStartTime,
                roundEndTime,
            } = reviewInfo.ticketInfo;
            reviewInfo.participateDate =
                reviewInfo.mallActivityInfo?.ticketType === NewMallActivityTicketType.Activity
                    ? `${roundStartDate}至${roundEndDate}`
                    : `${roundStartDate} ${roundStartTime}${
                          roundEndTime ? `-${roundEndTime}` : ''
                      }`;
            const orderPoint = get(reviewInfo, 'orderInfo.totalPointAmount');
            const orderPrice = get(reviewInfo, 'orderInfo.totalSalesAmount');
            let orderPriceStr = '';
            if (
                (!orderPoint || parseFloat(orderPoint) === 0) &&
                (!orderPrice || parseFloat(orderPrice) === 0)
            ) {
                orderPriceStr = '0';
            } else if (orderPoint && orderPrice) {
                orderPriceStr = `${orderPoint}积分+${orderPrice}${language.getText('yuan')}`;
            } else if (!orderPoint && orderPrice) {
                orderPriceStr = `${orderPrice}${language.getText('yuan')}`;
            } else if (orderPoint && !orderPrice) {
                orderPriceStr = `${orderPoint}积分`;
            }
            reviewInfo.orderPriceStr = orderPriceStr;
            this.setState({ reviewInfo });
        } catch (error) {
            errorHandle(error as any);
            throw error;
        }
    };
    onSubmit = debounce(async (value: any) => {
        const { subsiteId } = this.props.row;
        const { reviewInfo } = this.state;
        const { entity, params: entityParams } = this.props;
        // const merchantId = subsite.mchId;
        const consumeTime = value['consumeTime'].format('YYYY-MM-DD HH:mm:ss');
        const param: Record<string, string> = {
            consumeTime: consumeTime,
            orderId: get(reviewInfo, 'orderInfo.id'),
            type: 'MALL_ACTIVITY_NEW',
            quantity: value.waitConsumeQuantity,
            consumeType: 'MINI_PROGRAM_GUIDE',
            subsiteId,
            // merchantId,
        };
        this.setState({ loading: true });
        try {
            const {
                mallActivityOrder: { couponDefinitionId },
            } = await api.get<any>(
                {},
                {
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
                    apiPath: `/front/mall_activities/registration_records/${get(
                        reviewInfo,
                        'orderInfo.id'
                    )}/consume_info`,
                }
            );
            param.couponDefinitionId = couponDefinitionId;
            await api.post(
                { ...param },
                {
                    apiPath: `/admin/v2/consume_orders`,
                    apiRoot: `${ENV.AUTH_API_ROOT}/COUPON`,
                }
            );
        } catch (error) {
            errorHandle(error as any);
            throw error;
        }
        AntMessage.success(language.getText('actionSuccess'));
        entity && entity.search(entityParams);
        this.listenRef.handleReset();
        this.setState({ visible: false, loading: false });
    }, 500);
    //@ts-ignore
    listenRef: {
        handleSubmit: () => Promise<any>;
        handleReset: () => Promise<any>;
    };
    render() {
        const { triggerComponent, row } = this.props;
        const { reviewInfo, visible, loading } = this.state;
        const buttonProps = {
            ...triggerComponent,
            onClick: this.openModal,
        };

        return (
            <Fragment>
                {row.orderStatus === 'AUDIT_APPROVED'
                    ? createElement(ComponentsManager.get('Button'), buttonProps)
                    : null}
                <Modal
                    visible={visible}
                    className="consume-new-mall-activity-view"
                    title="核销参与资格"
                    mask={false}
                    maskClosable
                    okText={language.getText('common.ok')}
                    cancelText={language.getText('common.cancel')}
                    width="680px"
                    okButtonProps={{ loading }}
                    onOk={() => {
                        this.listenRef.handleSubmit();
                    }}
                    onCancel={() => {
                        this.listenRef.handleReset();
                        this.setState({ visible: false });
                    }}
                >
                    <Row>
                        <Col span={12}>
                            <Descriptions layout="vertical" bordered>
                                <Descriptions.Item label="买家信息">
                                    <Row>
                                        <Col span={24}>
                                            <span>
                                                下单人手机号：
                                                {get(reviewInfo, 'purchaseMemberInfo.mobile') || ''}
                                            </span>
                                        </Col>
                                        <Col span={24} className="mt">
                                            <span>
                                                下单人报名成功的次数：
                                                {get(
                                                    reviewInfo,
                                                    'purchaseMemberInfo.successTicketPurchaseCount'
                                                )}
                                            </span>
                                        </Col>
                                        <Col span={24} className="mt">
                                            <span>
                                                下单人卡等级：
                                                {get(
                                                    reviewInfo,
                                                    'purchaseMemberInfo.memberCardLevelName'
                                                ) || ''}
                                            </span>
                                        </Col>

                                        <Col span={24} className="mt">
                                            <span>
                                                未参与活动的次数：
                                                {get(reviewInfo, 'purchaseMemberInfo.unJoinCount')}
                                            </span>
                                        </Col>
                                    </Row>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={12}>
                            <Descriptions
                                layout="vertical"
                                bordered
                                style={{ marginLeft: 20 }}
                                className="participant-box"
                            >
                                <Descriptions.Item label="参与者信息">
                                    <Row>
                                        <Col span={24}>
                                            <span>
                                                参与人姓名：
                                                {get(reviewInfo, 'joinMemberInfo.name') || ''}
                                            </span>
                                        </Col>
                                        <Col span={24} className="mt">
                                            <span>
                                                参与人手机号：
                                                {get(reviewInfo, 'joinMemberInfo.mobile') || ''}
                                            </span>
                                        </Col>
                                        <Col span={24} className="mt">
                                            <span>
                                                参与时间：{get(reviewInfo, 'participateDate') || ''}
                                            </span>
                                        </Col>
                                    </Row>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                        <Col span={24}>
                            <Descriptions layout="vertical" bordered style={{ marginTop: 20 }}>
                                <Descriptions.Item label="票档信息">
                                    <Row>
                                        <Col span={12}>
                                            <span>
                                                门票名称：{get(reviewInfo, 'ticketInfo.name') || ''}
                                            </span>
                                        </Col>
                                        <Col span={8}>
                                            <span>
                                                总价：
                                                {get(reviewInfo, 'orderPriceStr') || '-'}
                                            </span>
                                        </Col>
                                        <Col span={4}>
                                            <span>
                                                数量：
                                                {get(reviewInfo, 'ticketInfo.purchasedQuantity') ||
                                                    ''}
                                            </span>
                                        </Col>
                                    </Row>
                                </Descriptions.Item>
                            </Descriptions>
                        </Col>
                    </Row>
                    <ConsumeNewMallActivityForm
                        wrappedComponentRef={(ref: any) => (this.listenRef = ref)}
                        onSubmit={this.onSubmit}
                        waitConsumeQuantity={get(row, 'waitConsumeQuantity')}
                    />
                </Modal>
            </Fragment>
        );
    }
}

function mapStateToProps(_state: any, props: any) {
    const { entity } = props;
    return { fields: entity.fields, entity };
}
export const ConsumeNewMallActivityButton = connect(
    mapStateToProps,
    null
)(ConsumeNewMallActivityView);
