import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { forEach } from 'lodash';

const api = services.api;
export const LuckyRedPackageLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/lucky_red_package_activities';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/lucky_red_package_activities/${id}`;
        } else {
            data.eventType = 'LUCKY_RED_PACKAGE';
            if (data.executeStatus === 'ALL') {
                delete data.executeStatus;
            }
        }
        return await api.get(data, config).then((res: any) => {
            if (id) {
                res.id = res.activity.id;
                res.baseInfo = {
                    name: res.activity.name,
                    dateRange: {
                        start: res.activity.startTime,
                        end: res.activity.endTime,
                    },
                };
                res.ruleInfo = {
                    activityRewardLimit: res.activity.rewardConfig.activityRewardLimit,
                    rewardRule: res.activity.rewardConfig.rewardRule,
                    crowdScope: res.activity.rewardConfig.crowdScope,
                    activityPeriodRewardLimit: res.activity.rewardConfig.activityPeriodRewardLimit,
                    conditionLowerValue: res.luckyRedPackage.conditionLowerValue,
                    totalAmount: res.luckyRedPackage.totalAmount,
                    stopReceiveDays: res.luckyRedPackage.stopReceiveDays,
                };
                let activityProductType = res.luckyRedPackage.luckyRedPackageCoupon.productScope;
                if (res.luckyRedPackage.luckyRedPackageCoupon.productScope === 'INCLUDE_PRODUCTS') {
                    activityProductType = 'INCLUDE_PRODUCT';
                } else if (
                    res.luckyRedPackage.luckyRedPackageCoupon.productScope === 'EXCLUDE_PRODUCTS'
                ) {
                    activityProductType = 'EXCLUDE_PRODUCT';
                }
                let productsValue: any = null;
                if (
                    res.luckyRedPackage.luckyRedPackageCoupon.goods &&
                    res.luckyRedPackage.luckyRedPackageCoupon.goods.length > 0
                ) {
                    productsValue = {
                        type: 'APPOINT_PRODUCT',
                        values: res.luckyRedPackage.luckyRedPackageCoupon.goods.map((item: any) => {
                            return {
                                ...item,
                                name: item.productName,
                                code: item.goodsCode,
                                subsiteName: item.subsite.name,
                                merchantName: item.merchant.name,
                            };
                        }),
                    };
                }
                if (
                    res.luckyRedPackage.luckyRedPackageCoupon.brands &&
                    res.luckyRedPackage.luckyRedPackageCoupon.brands.length > 0
                ) {
                    productsValue = {
                        type: 'APPOINT_BRAND',
                        values: res.luckyRedPackage.luckyRedPackageCoupon.brands,
                    };
                }
                let consumeChannel: any = [];
                forEach(res.luckyRedPackage.luckyRedPackageCoupon.dimensions, (dimension) => {
                    if (dimension.dimensionType === 'CHANNEL') {
                        consumeChannel = dimension.ids.map((value: any) => {
                            return value + '';
                        });
                    }
                });
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : services.language.getText('longTerm'),
                    };
                }
                res.productInfo = {
                    couponName: res.luckyRedPackage.luckyRedPackageCoupon.couponName,
                    conditionLowerValue:
                        res.luckyRedPackage.luckyRedPackageCoupon.conditionLowerValue,
                    validityPeriod: res.luckyRedPackage.luckyRedPackageCoupon.validityPeriod,
                    selectMode: res.luckyRedPackage.luckyRedPackageCoupon.subSiteOrMerchant,
                    shop: res.luckyRedPackage.luckyRedPackageCoupon.subSiteMerchants,
                    subsite: res.luckyRedPackage.luckyRedPackageCoupon.subsites,
                    activityProductType: activityProductType,
                    products: productsValue,
                    consumeChannel: consumeChannel,
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };
                res.pictureInfo = {
                    shareTitle: res.luckyRedPackage.title,
                    paymentFinishPicture: res.luckyRedPackage.luckyRedPackagePageShow
                        .paymentFinishPicture
                        ? [
                              {
                                  id:
                                      res.luckyRedPackage.luckyRedPackagePageShow
                                          .paymentFinishPicture.id,
                                  path:
                                      res.luckyRedPackage.luckyRedPackagePageShow
                                          .paymentFinishPicture.url,
                              },
                          ]
                        : null,
                    orderDetailPicture: res.luckyRedPackage.luckyRedPackagePageShow
                        .orderDetailPicture
                        ? [
                              {
                                  id:
                                      res.luckyRedPackage.luckyRedPackagePageShow.orderDetailPicture
                                          .id,
                                  path:
                                      res.luckyRedPackage.luckyRedPackagePageShow.orderDetailPicture
                                          .url,
                              },
                          ]
                        : null,
                    forwardPicture: res.luckyRedPackage.luckyRedPackagePageShow.forwardPicture
                        ? [
                              {
                                  id: res.luckyRedPackage.luckyRedPackagePageShow.forwardPicture.id,
                                  path:
                                      res.luckyRedPackage.luckyRedPackagePageShow.forwardPicture
                                          .url,
                              },
                          ]
                        : null,
                    activityPageBottomPicture: res.luckyRedPackage.luckyRedPackagePageShow
                        .activityPageBottomPicture
                        ? [
                              {
                                  id:
                                      res.luckyRedPackage.luckyRedPackagePageShow
                                          .activityPageBottomPicture.id,
                                  path:
                                      res.luckyRedPackage.luckyRedPackagePageShow
                                          .activityPageBottomPicture.url,
                              },
                          ]
                        : null,
                    activityPageButtonPicture: res.luckyRedPackage.luckyRedPackagePageShow
                        .activityPageButtonPicture
                        ? [
                              {
                                  id:
                                      res.luckyRedPackage.luckyRedPackagePageShow
                                          .activityPageButtonPicture.id,
                                  path:
                                      res.luckyRedPackage.luckyRedPackagePageShow
                                          .activityPageButtonPicture.url,
                              },
                          ]
                        : null,
                    activityRuleBackgroundColor:
                        res.luckyRedPackage.luckyRedPackagePageShow.activityRuleBackgroundColor,
                    description: res.activity.description,
                };
            } else {
                if (!id) {
                    res.result =
                        res &&
                        res.result.map((item: any) => {
                            item.canExport =
                                item.executeStatus === 'STARTED' ||
                                item.executeStatus === 'COMPLETE';
                            return item;
                        });
                }
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        config.apiPath = '/admin/lucky_red_package_activities';
        const newParams: any = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`;
        if (params.id) {
            config.apiPath = `/admin/lucky_red_package_activities/${params.id}`;
        }
        const newParams: any = dataParser(params);
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    let newParams: any = {};
    const shopArray: any = [];
    const subsiteArray: any[] = [];
    if (params.productInfo.shop && params.productInfo.shop.length > 0) {
        forEach(params.productInfo.shop, (shop) => {
            shopArray.push(`${shop.subSiteId},${shop.merchantId}`);
        });
    }
    if (params.productInfo.subsite && params.productInfo.subsite.length > 0) {
        forEach(params.productInfo.subsite, (subsite) => {
            subsiteArray.push(subsite);
        });
    }
    if (params.baseInfo) {
        newParams.activity = {
            eventType: 'LUCKY_RED_PACKAGE',
            name: params.baseInfo.name,
            startTime: params.baseInfo.dateRange.start,
            endTime: params.baseInfo.dateRange.end,
        };
    }
    if (params.ruleInfo) {
        newParams.activity.rewardConfig = {
            activityRewardLimit: params.ruleInfo.activityRewardLimit,
            rewardRule: params.ruleInfo.rewardRule,
            crowdScope: params.ruleInfo.crowdScope,
            activityPeriod: 'DAY',
            activityPeriodRewardLimit: params.ruleInfo.activityPeriodRewardLimit,
        };
        newParams.luckyRedPackage = {
            conditionLowerValue: params.ruleInfo.conditionLowerValue,
            totalAmount: params.ruleInfo.totalAmount,
            stopReceiveDays: params.ruleInfo.stopReceiveDays,
        };
    }
    if (params.productInfo) {
        newParams.marketingScheduleId =
            (params.productInfo.marketingSchedule?.length &&
                params.productInfo.marketingSchedule[0].id) ||
            null;
        newParams.luckyRedPackage.luckyRedPackageCoupon = {
            couponName: params.productInfo.couponName,
            conditionLowerValue: params.productInfo.conditionLowerValue,
            validityPeriod: params.productInfo.validityPeriod,
            subSiteOrMerchant: params.productInfo.selectMode,
            merchants: params.productInfo.selectMode === 'MERCHANT' ? shopArray : [],
            subsites: params.productInfo.selectMode === 'SUB_SITE' ? subsiteArray : [],
            dimensions: [
                {
                    all: false,
                    dimensionType: 'CHANNEL',
                    ids: params.productInfo.consumeChannel.map((value: any) => value),
                },
            ],
        };
        if (params.productInfo.activityProductType) {
            if (params.productInfo.activityProductType === 'ALL') {
                newParams.luckyRedPackage.luckyRedPackageCoupon.dimensions.push({
                    all: true,
                    dimensionType: 'GOODS',
                    ids: [-1],
                });
            } else if (
                params.productInfo.activityProductType === 'INCLUDE_PRODUCT' ||
                params.productInfo.activityProductType === 'EXCLUDE_PRODUCT'
            ) {
                let productsType = 'GOODS';
                if (params.productInfo.products && params.productInfo.products.type) {
                    if (params.productInfo.products.type === 'APPOINT_PRODUCT') {
                        productsType = 'GOODS';
                    } else if (params.productInfo.products.type === 'APPOINT_BRAND') {
                        productsType = 'BRAND';
                    }
                }
                newParams.luckyRedPackage.luckyRedPackageCoupon.dimensions.push({
                    all:
                        params.productInfo.activityProductType === 'EXCLUDE_PRODUCT' ? true : false,
                    dimensionType: productsType,
                    ids:
                        params.productInfo.products && params.productInfo.products.values
                            ? params.productInfo.products.values.map((value: any) => value.id)
                            : [],
                });
            }
        }
    }
    if (params.pictureInfo) {
        newParams.luckyRedPackage.title = params.pictureInfo.shareTitle;
        newParams.luckyRedPackage.luckyRedPackagePageShow = {
            paymentFinishPicture:
                params.pictureInfo.paymentFinishPicture &&
                params.pictureInfo.paymentFinishPicture.length > 0
                    ? {
                          id: params.pictureInfo.paymentFinishPicture[0].id,
                          url: params.pictureInfo.paymentFinishPicture[0].path,
                      }
                    : null,
            orderDetailPicture:
                params.pictureInfo.orderDetailPicture &&
                params.pictureInfo.orderDetailPicture.length > 0
                    ? {
                          id: params.pictureInfo.orderDetailPicture[0].id,
                          url: params.pictureInfo.orderDetailPicture[0].path,
                      }
                    : null,
            forwardPicture:
                params.pictureInfo.forwardPicture && params.pictureInfo.forwardPicture.length > 0
                    ? {
                          id: params.pictureInfo.forwardPicture[0].id,
                          url: params.pictureInfo.forwardPicture[0].path,
                      }
                    : null,
            activityPageBottomPicture:
                params.pictureInfo.activityPageBottomPicture &&
                params.pictureInfo.activityPageBottomPicture.length > 0
                    ? {
                          id: params.pictureInfo.activityPageBottomPicture[0].id,
                          url: params.pictureInfo.activityPageBottomPicture[0].path,
                      }
                    : null,
            activityPageButtonPicture:
                params.pictureInfo.activityPageButtonPicture &&
                params.pictureInfo.activityPageButtonPicture.length > 0
                    ? {
                          id: params.pictureInfo.activityPageButtonPicture[0].id,
                          url: params.pictureInfo.activityPageButtonPicture[0].path,
                      }
                    : null,
            activityRuleBackgroundColor: params.pictureInfo.activityRuleBackgroundColor
                ? params.pictureInfo.activityRuleBackgroundColor
                : '',
        };
        newParams.activity.description = params.pictureInfo.description;
    }

    return newParams;
}
