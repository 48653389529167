import React, { Fragment, PureComponent } from 'react';
import { services } from '@comall-backend-builder/core';
import {
    Button,
    DatePicker,
    message as AntMessage,
    Modal,
    Popover as AntPopover,
    Select,
    message,
    Icon,
    Popover,
    Table,
} from 'antd';
import { default as Radio } from 'antd/lib/radio';
import { api, localStorage, errorHandle } from '@comall-backend-builder/core/lib/services';
import { cloneDeep, find, forEach, remove } from 'lodash';
import moment from 'moment';
import { SendStatus, SendStatusText, SendStatusTip, disableEditing } from '../common';
import CmWebsocket from '../../../components/cm-websocket';
import { MsgChannelType } from '../messages-subscription-find-list-item';
import { MessagesSubscriptionFindListItemEditModal } from '../components/messages-subscription-find-list-item-edit-modal';
import MessageSubscriptionCrowdsSeletor from '../components/message-subscription-crowds-seletor';
import { TextTips } from '../../../components';
import './index.less';
import { MessagesSubscriptionEmailEditModal } from '../components/messages-subscription-find-list-item-edit-modal/message-subscription-email-edit-modal';
import { MessagesSubscriptionSiteMailPushEditModal } from '../components/messages-subscription-find-list-item-edit-modal/message-subscription-sitemail-push-edit-modal';

const timeOptions = [
    {
        id: 0,
        name: '立即发送',
    },
    {
        id: 1,
        name: '定时发送',
    },
];
const options = [
    {
        id: 1,
        name: '指定粉丝',
    },
    {
        id: 2,
        name: '指定会员',
    },
];

enum objectScopeEnum {
    /**
     * 全部已订阅
     */
    ALLSUBSCRIBED = 'ALL_SUBSCRIBED',
    /**
     * 指定会员等级
     */
    SUBSCRIBED = 'MEMBER_CARD_LEVEL_SUBSCRIBED',
    /**
     * 指定会员人群
     */
    CROWD = 'CROWD',
}
enum FansScopeEnum {
    /**
     * 全部粉丝
     */
    ALL = 'ALL',
    /**
     * 全部非会员粉丝
     */
    NON_MEMBER = 'NON_MEMBER',
    /**
     * 全部会员粉丝
     */
    MEMBER = 'MEMBER',
    /**
     * 指定对象
     */
    PART = 'PART',
}

interface Props {
    itemProps: any;
    refreshList: () => void;
}

interface States {
    editModalVisible: boolean;
    editTemplateId?: number;
    webSocketMessage: any | undefined;
    visibleSendModal: boolean;
    sendModalObject: {
        startDateType: number;
        startDate: string;
        fansScope: FansScopeEnum;
        objectScope: objectScopeEnum;
        type: number;
        sendMobileIds: string;
        sendOpenIds: string;
        cardLevelIds: any;
        crowdId: any;
    };
    checkSendModalResult?: any;
    confirmLoading: boolean;
    cardLevels: any[];
    supportMemberMarking: boolean;
    estimateNum?: number;
}

/**
 * 编辑
 */
export class MessagesSubscriptionFindListItemEditView extends PureComponent<Props, States> {
    constructor(props: any) {
        super(props);
        this.state = {
            editModalVisible: false,
            editTemplateId: undefined,
            webSocketMessage: undefined,
            visibleSendModal: false,
            sendModalObject: {
                startDateType: 0,
                startDate: '',
                fansScope: FansScopeEnum.ALL,
                objectScope: objectScopeEnum.ALLSUBSCRIBED,
                cardLevelIds: [],
                type: 0,
                sendMobileIds: '',
                sendOpenIds: '',
                crowdId: [],
            },
            checkSendModalResult: undefined,
            confirmLoading: false,
            cardLevels: [],
            supportMemberMarking: false,
        };
    }

    componentDidMount() {
        this.loadCardLevels();
        this.loadSupportMemberMarking();
    }

    loadSupportMemberMarking = async () => {
        const response = await api.get(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/CAE-IDENTITY`,
                apiPath: `/roles/super/privilege_set/memberv2Crowds/verify`,
            }
        );
        this.setState({
            supportMemberMarking: Boolean(response),
        });
    };

    isSubscriptionMessage = () => {
        const { itemProps } = this.props;
        return (
            itemProps.msgChannelType === MsgChannelType.WEAPP ||
            itemProps.msgChannelType === MsgChannelType.ALIPAY_SUBSCRIBE_MSG
        );
    };

    loadCardLevels = async () => {
        let store: any = localStorage.get('wechantTemplatesStore');
        //订阅消息，载会员等级
        if (store && store.id && this.isSubscriptionMessage()) {
            const params = { subsiteId: store.id, page: 1, per_page: 100, status: 'ENABLE' };
            api.get(params, {
                apiRoot: `${ENV.AUTH_API_ROOT}/MEMBER-CENTER`,
                apiPath: `/admin/store_card_levels`,
            }).then((response: any) => {
                const result: Array<any> = [];
                forEach(response.result, (level: any) => {
                    result.push({
                        id: level.cardLevelId,
                        name: level.name,
                    });
                });
                this.setState({
                    cardLevels: result,
                });
            });
        }
    };
    onEdit = () => {
        const { itemProps } = this.props;
        let store: any = localStorage.get('wechantTemplatesStore');
        //订阅消息，没有内容时，先创建
        if (this.isSubscriptionMessage() && !itemProps.id) {
            services.api
                .post(
                    {
                        detailContent: '',
                        detailContentColor: null,
                        msgChannelType: itemProps.msgChannelType,
                        scene: itemProps.scene,
                        targetUrl: itemProps.targetUrl,
                        subsiteId: store.id,
                        wechatTemplateId: itemProps.wechatTemplateId,
                    },
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                        apiPath: `/admin/template_definitions/contents`,
                    }
                )
                .then((result: any) => {
                    if (result) {
                        this.setState({
                            editModalVisible: true,
                            editTemplateId: result.id,
                        });
                    } else {
                        message.warning(services.language.getText('myzdmbnr'));
                    }
                })
                .catch((error) => {
                    services.errorHandle(error);
                })
                .finally(() => {
                    this.setState({ confirmLoading: false });
                });
        } else {
            this.setState({
                editModalVisible: true,
                editTemplateId: itemProps.id,
            });
        }
    };

    handleVisible = () => {
        const { editModalVisible } = this.state;
        this.setState({
            editModalVisible: !editModalVisible,
        });
    };

    renderModal = () => {
        const { editModalVisible, editTemplateId } = this.state;
        const { refreshList, itemProps } = this.props;
        const { msgChannelType } = itemProps;
        // 如果是微信消息订阅或者微信公众号模板消息
        const messageAndWechat =
            msgChannelType === MsgChannelType.WEAPP ||
            msgChannelType === MsgChannelType.WECHAT_OFFICIAL_ACCOUNT;
        // 邮件
        const emailMessage = msgChannelType === MsgChannelType.EMAIL;
        //站内信和push
        const siteMailAndMessage =
            msgChannelType === MsgChannelType.SITEMAIL ||
            msgChannelType === MsgChannelType.APP_PUSH;
        return (
            <Fragment>
                {/**微信和公众号 */}
                {editModalVisible && messageAndWechat && (
                    <MessagesSubscriptionFindListItemEditModal
                        refreshList={refreshList}
                        handleVisible={this.handleVisible}
                        visible={editModalVisible}
                        editId={editTemplateId}
                        msgChannelType={msgChannelType}
                    />
                )}
                {/**邮件 */}
                {editModalVisible && emailMessage && (
                    <MessagesSubscriptionEmailEditModal
                        visible={editModalVisible}
                        handleVisible={this.handleVisible}
                        refreshList={refreshList}
                        editId={itemProps.id}
                    />
                )}
                {/**站内信和push */}
                {editModalVisible && siteMailAndMessage && (
                    <MessagesSubscriptionSiteMailPushEditModal
                        msgChannelType={msgChannelType}
                        visible={editModalVisible}
                        handleVisible={this.handleVisible}
                        refreshList={refreshList}
                        editId={itemProps.id}
                    />
                )}
            </Fragment>
        );
    };

    onSend = () => {
        const { itemProps } = this.props;
        let hasValidate = true;
        if (itemProps.params) {
            forEach(itemProps.params, (o) => {
                if (o.editable && !o.value) {
                    AntMessage.warning(services.language.getText('qxbjmb'));
                    hasValidate = false;
                    return false;
                }
            });
        }
        if (!hasValidate) {
            return false;
        }
        this.onhardleSendModal();
    };
    onhardleSendModal = () => {
        const { visibleSendModal } = this.state;
        this.setState({
            visibleSendModal: !visibleSendModal,
            sendModalObject: {
                startDateType: 0,
                startDate: '',
                fansScope: FansScopeEnum.ALL,
                objectScope: this.isSubscriptionMessage()
                    ? objectScopeEnum.CROWD
                    : objectScopeEnum.ALLSUBSCRIBED,
                cardLevelIds: [],
                type: 0,
                sendMobileIds: '',
                sendOpenIds: '',
                crowdId: [],
            },
            checkSendModalResult: undefined,
        });
    };
    onShowSendModalClick = () => {
        const { itemProps } = this.props;
        const { sendModalObject } = this.state;
        let store: any = localStorage.get('wechantTemplatesStore');
        let mobileOrOpenIds = '';
        if (sendModalObject.type === 1) {
            mobileOrOpenIds = sendModalObject.sendMobileIds;
        }
        if (sendModalObject.type === 2) {
            mobileOrOpenIds = sendModalObject.sendOpenIds;
        }
        if (sendModalObject.type !== 0 && !mobileOrOpenIds) {
            AntMessage.warning(services.language.getText('qsrzdfs'));
            return;
        }
        if (sendModalObject.startDateType === 1) {
            if (!sendModalObject.startDate) {
                AntMessage.warning(services.language.getText('qxzdssj'));
                return;
            }
            if (new Date(sendModalObject.startDate).getTime() < new Date().getTime()) {
                AntMessage.warning(services.language.getText('fssjbnzydqsj'));
                return;
            }
        }
        if (
            sendModalObject.objectScope === objectScopeEnum.CROWD &&
            (!sendModalObject.crowdId || sendModalObject.crowdId.length === 0)
        ) {
            AntMessage.warning(services.language.getText('qxzhyrq'));
            return;
        }
        if (
            sendModalObject.objectScope === objectScopeEnum.SUBSCRIBED &&
            (!sendModalObject.cardLevelIds || sendModalObject.cardLevelIds.length === 0)
        ) {
            AntMessage.warning(services.language.getText('selectApplyLevel'));
            return;
        }
        if (sendModalObject.type !== 0) {
            //验证
            this.setState({ confirmLoading: true });
            const params: any = {
                code: itemProps.code,
                subsiteId: store.id,
                wechatTemplateContentId: itemProps.id,
                checkMobileOrOpenIds: true,
                mobileOrOpenIds,
                sendTime:
                    sendModalObject.startDateType === 1 ? sendModalObject.startDate : undefined,
            };
            if (this.isSubscriptionMessage()) {
                params.msgChannelType = itemProps.msgChannelType;
                params.objectScope = sendModalObject.objectScope;
                params.cardLevelIds =
                    sendModalObject.objectScope === objectScopeEnum.SUBSCRIBED
                        ? sendModalObject?.cardLevelIds.join()
                        : '';
                params.crowdId =
                    sendModalObject.objectScope === objectScopeEnum.CROWD
                        ? sendModalObject.crowdId.map((c: any) => c.id).join()
                        : '';
            } else {
                params.objectScope = sendModalObject.fansScope;
            }
            api.post(params, {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                apiPath: `/admin/wechat_template_sendrecords`,
            })
                .then((result: any) => {
                    if (result && result.errorValue && result.errorValue.length > 0) {
                        this.setState({
                            checkSendModalResult: cloneDeep(result),
                        });
                    } else {
                        this.setState(
                            {
                                checkSendModalResult: undefined,
                            },
                            () => {
                                this.onShowSendModalConfrim(mobileOrOpenIds);
                            }
                        );
                    }
                })
                .catch((error) => {
                    services.errorHandle(error);
                })
                .finally(() => {
                    this.setState({ confirmLoading: false });
                });
        } else {
            this.onShowSendModalConfrim();
        }
    };
    onShowSendModalConfrim = (mobileOrOpenIds?: string) => {
        const { itemProps, refreshList } = this.props;
        const { sendModalObject } = this.state;
        let store: any = localStorage.get('wechantTemplatesStore');

        const params: any = {
            code: itemProps.code,
            subsiteId: store.id,
            wechatTemplateContentId: itemProps.id,
            checkMobileOrOpenIds: false,
            // mobileOrOpenIds:
            //     sendModalObject.fansScope === FansScopeEnum.PART
            //         ? mobileOrOpenIds
            //         : undefined,
            // fansScope: sendModalObject.fansScope,
            sendTime: sendModalObject.startDateType === 1 ? sendModalObject.startDate : undefined,
        };
        if (this.isSubscriptionMessage()) {
            params.msgChannelType = itemProps.msgChannelType;
            params.objectScope = sendModalObject.objectScope;
            params.cardLevelIds =
                sendModalObject.objectScope === objectScopeEnum.SUBSCRIBED
                    ? sendModalObject?.cardLevelIds.join()
                    : '';
            params.crowdId =
                sendModalObject.objectScope === objectScopeEnum.CROWD
                    ? sendModalObject.crowdId.map((c: any) => c.id).join()
                    : '';
        } else {
            params.mobileOrOpenIds =
                sendModalObject.fansScope === FansScopeEnum.PART ? mobileOrOpenIds : undefined;
            params.objectScope = sendModalObject.fansScope;
        }
        Modal.confirm({
            title: services.language.getText('qrfsm'),
            onOk: () => {
                this.setState({ confirmLoading: true });
                api.post(
                    /*  {
                        code: itemProps.code,
                        subsiteId: store.id,
                        wechatTemplateContentId: itemProps.id,
                        checkMobileOrOpenIds: false,
                        mobileOrOpenIds:
                            sendModalObject.fansScope === FansScopeEnum.PART
                                ? mobileOrOpenIds
                                : undefined,
                        fansScope: sendModalObject.fansScope,
                        sendTime:
                            sendModalObject.startDateType === 1
                                ? sendModalObject.startDate
                                : undefined,
                    }, */
                    params,
                    {
                        apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                        apiPath: `/admin/wechat_template_sendrecords`,
                    }
                )
                    .then(() => {
                        refreshList();
                        this.setState({
                            visibleSendModal: false,
                            webSocketMessage: undefined,
                            checkSendModalResult: undefined,
                        });
                    })
                    .catch((error) => {
                        services.errorHandle(error);
                    })
                    .finally(() => {
                        this.setState({ confirmLoading: false });
                    });
            },
        });
    };
    clearErrorValue = () => {
        const { sendModalObject, checkSendModalResult } = this.state;
        if (!checkSendModalResult || checkSendModalResult.errorValue.length === 0) {
            return null;
        }
        let mobileOrOpenIds = '';
        if (sendModalObject.type === 1) {
            mobileOrOpenIds = sendModalObject.sendMobileIds;
        }
        if (sendModalObject.type === 2) {
            mobileOrOpenIds = sendModalObject.sendOpenIds;
        }
        const mobileOrOpenIdsArray = mobileOrOpenIds.split(',');
        remove(mobileOrOpenIdsArray, (id) => {
            const item = find(checkSendModalResult.errorValue, function(value) {
                return id === value;
            });
            if (item) {
                return true;
            }
            return false;
        });
        const mobileOrOpenIdsString = mobileOrOpenIdsArray.join(',');
        sendModalObject.sendMobileIds =
            sendModalObject.type === 1 ? mobileOrOpenIdsString : sendModalObject.sendMobileIds;
        sendModalObject.sendOpenIds =
            sendModalObject.type === 2 ? mobileOrOpenIdsString : sendModalObject.sendOpenIds;
        this.setState({
            sendModalObject: cloneDeep(sendModalObject),
            checkSendModalResult: undefined,
        });
    };
    disabledDate = (current: any) => {
        return (
            current &&
            current <
                moment()
                    .subtract(1, 'days')
                    .endOf('day')
        );
    };

    range = (start: number, end: number) => {
        const result = [];
        for (let i = start; i < end; i++) {
            result.push(i);
        }
        return result;
    };

    disabledTime = (current: any) => {
        const hours = moment().hours();
        const minutes = moment().minutes();
        const currentHour = moment(current).hour();
        if (current && moment(current).date() === moment().date()) {
            return {
                disabledHours: () => this.range(0, 24).splice(0, hours),
                disabledMinutes: () => {
                    if (currentHour === moment().hour()) {
                        return this.range(0, 60).splice(0, minutes);
                    }
                    return [];
                },
                disabledSeconds: () => [],
            };
        }
        return {
            disabledHours: () => [],
            disabledMinutes: () => [],
            disabledSeconds: () => [],
        };
    };

    handleDateChange = (data: any) => {
        const { sendModalObject } = this.state;
        const thisValue = moment(data || undefined).format('YYYY-MM-DD HH:mm:ss');
        sendModalObject.startDate = thisValue;
        this.setState({
            sendModalObject: cloneDeep(sendModalObject),
        });
    };
    onChangeStartDateType = (e: any) => {
        const value = e.target.value;
        const { sendModalObject } = this.state;
        sendModalObject.startDateType = value;
        this.setState({
            sendModalObject: cloneDeep(sendModalObject),
        });
    };

    onChangeSendModalObjectScope = (e: any) => {
        const value = e.target.value;
        const { sendModalObject } = this.state;
        sendModalObject.objectScope = value;
        if (value === objectScopeEnum.SUBSCRIBED) {
            this.loadCardLevels();
        }
        this.setState({
            sendModalObject: cloneDeep(sendModalObject),
        });
    };
    renderSendModal = () => {
        const {
            visibleSendModal,
            sendModalObject,
            checkSendModalResult,
            confirmLoading,
            cardLevels,
            estimateNum,
        } = this.state;
        let textareaValue = '';
        if (sendModalObject.type === 1) {
            textareaValue = sendModalObject.sendMobileIds;
        }
        if (sendModalObject.type === 2) {
            textareaValue = sendModalObject.sendOpenIds;
        }
        return (
            <Modal
                destroyOnClose
                visible={visibleSendModal}
                title={services.language.getText('fssz')}
                width="900px"
                confirmLoading={confirmLoading}
                onCancel={this.onhardleSendModal}
                onOk={this.onShowSendModalClick}
                zIndex={1}
            >
                <div className="wechat-templates-find-list-item-send-view">
                    <div className="wechat-templates-find-list-item-send-view__top wechat-templates-find-list-item-send-view__top-baseline">
                        <span className="wechat-templates-find-list-item-send-view__title">
                            {services.language.getText('sendTime')}:
                        </span>
                        <Radio.Group
                            value={sendModalObject.startDateType}
                            onChange={this.onChangeStartDateType}
                        >
                            {timeOptions.map((timeOption) => {
                                return (
                                    <div>
                                        <Radio value={timeOption.id}>{timeOption.name}</Radio>
                                        {timeOption.id === 1 &&
                                            sendModalObject.startDateType === 1 && (
                                                <DatePicker
                                                    style={{ marginLeft: '20px' }}
                                                    disabledDate={this.disabledDate}
                                                    disabledTime={this.disabledTime}
                                                    format="YYYY-MM-DD HH:mm:ss"
                                                    showTime
                                                    value={
                                                        sendModalObject.startDate
                                                            ? moment(sendModalObject.startDate)
                                                            : undefined
                                                    }
                                                    onChange={(value) =>
                                                        this.handleDateChange(value)
                                                    }
                                                    placeholder={services.language.getText(
                                                        'selectPlease'
                                                    )}
                                                    allowClear={false}
                                                />
                                            )}
                                    </div>
                                );
                            })}
                        </Radio.Group>
                    </div>
                    {this.isSubscriptionMessage() ? (
                        <div className="wechat-templates-find-list-item-send-view__top">
                            <span className="wechat-templates-find-list-item-send-view__title">
                                {services.language.getText('fsdx_1')}:
                            </span>
                            <Radio.Group
                                value={sendModalObject.objectScope}
                                onChange={this.onChangeSendModalObjectScope}
                            >
                                <Radio value={objectScopeEnum.ALLSUBSCRIBED}>
                                    {services.language.getText('qbydyhy')}
                                </Radio>
                                <Radio value={objectScopeEnum.SUBSCRIBED}>
                                    {services.language.getText('zdhydj')}
                                </Radio>
                                <Radio value={objectScopeEnum.CROWD}>
                                    {services.language.getText('zdhyrq')}
                                </Radio>
                            </Radio.Group>
                            {/*  <Select
                                value={sendModalObject.fansScope}
                                style={{ width: '300px' }}
                                onChange={this.onChangeSendModalFansScope}
                            >
                                <Select.Option value={FansScopeEnum.ALL}>
                                    全部已订阅会员
                                </Select.Option>
                                <Select.Option value={FansScopeEnum.NON_MEMBER}>
                                    指定会员等级
                                </Select.Option>
                            </Select> */}
                        </div>
                    ) : (
                        <div className="wechat-templates-find-list-item-send-view__top">
                            <span className="wechat-templates-find-list-item-send-view__title">
                                {services.language.getText('fsdx_1')}:
                            </span>
                            <Select
                                value={sendModalObject.fansScope}
                                style={{ width: '300px' }}
                                onChange={this.onChangeSendModalFansScope}
                            >
                                <Select.Option value={FansScopeEnum.ALL}>
                                    {services.language.getText('qbfs')}
                                </Select.Option>
                                <Select.Option value={FansScopeEnum.NON_MEMBER}>
                                    {services.language.getText('qbfhyfs')}
                                </Select.Option>
                                <Select.Option value={FansScopeEnum.MEMBER}>
                                    {services.language.getText('qbhyfs')}
                                </Select.Option>
                                <Select.Option value={FansScopeEnum.PART}>
                                    {services.language.getText('zddx')}
                                </Select.Option>
                            </Select>
                        </div>
                    )}
                    {sendModalObject.objectScope === objectScopeEnum.SUBSCRIBED && (
                        <div className="wechat-templates-find-list-item-send-view__top">
                            <span className="wechat-templates-find-list-item-send-view__title">
                                <span style={{ color: 'red' }}>*</span>{' '}
                                {services.language.getText('memberLevels')}:
                            </span>
                            <Select
                                value={sendModalObject.cardLevelIds}
                                mode="multiple"
                                allowClear
                                onChange={this.onChangeCardLevel}
                                placeholder={services.language.getText('qxzkdj')}
                                style={{ width: '300px' }}
                            >
                                {cardLevels.map((option) => {
                                    return (
                                        <Select.Option value={option.id}>
                                            {option.name}
                                        </Select.Option>
                                    );
                                })}
                            </Select>
                        </div>
                    )}
                    {sendModalObject.objectScope === objectScopeEnum.CROWD && (
                        <div>
                            <div className="wechat-templates-find-list-item-send-view__top wechat-templates-find-list-item-send-view__top-baseline">
                                <span className="wechat-templates-find-list-item-send-view__title">
                                    <span style={{ color: 'red' }}>*</span>{' '}
                                    {services.language.getText('selectVipPeople')}:
                                </span>
                                {this.renderChooseCrowd()}
                            </div>
                            <div className="wechat-templates-find-list-item-send-view__top">
                                <span className="wechat-templates-find-list-item-send-view__title">
                                    <span>{services.language.getText('ygcfs')}</span>
                                    <span style={{ margin: '0 2px' }}>
                                        <Popover
                                            placement={'right'}
                                            content={
                                                <div style={{ width: '200px', color: '#999' }}>
                                                    {services.language.getText('ygcfsTip')}
                                                </div>
                                            }
                                            trigger="hover"
                                        >
                                            <Icon type="question-circle" />
                                        </Popover>
                                    </span>
                                    <span>:</span>
                                </span>
                                {estimateNum || 0}
                                {services.language.getText('people')}
                            </div>
                        </div>
                    )}
                    {sendModalObject.fansScope === FansScopeEnum.PART && (
                        <div className="wechat-templates-find-list-item-send-view__type-wrap">
                            <Radio.Group
                                value={sendModalObject.type}
                                onChange={this.onChangeSendModalType}
                            >
                                {options.map((option) => {
                                    return <Radio value={option.id}>{option.name}</Radio>;
                                })}
                            </Radio.Group>
                        </div>
                    )}
                    {sendModalObject.type !== 0 && (
                        <div className="wechat-templates-find-list-item-send-view__center">
                            <div>
                                <textarea
                                    value={textareaValue}
                                    className="ant-input"
                                    style={{
                                        height: '200px',
                                        minHeight: '200px',
                                        maxHeight: '200px',
                                        overflowY: 'hidden',
                                    }}
                                    onChange={(e: any) => this.onChangeSendModalRemark(e)}
                                ></textarea>
                            </div>
                            <div className="wechat-templates-find-list-item-send-view__tip">
                                <span>{services.language.getText('qzqtxfsdxd')}</span>
                                <span>
                                    {services.language.getText('rtxfsdxdsjhqqr')}
                                    <span
                                        className="wechat-templates-find-list-item-send-view__link"
                                        onClick={function() {
                                            window.open(`#/wechat-official-account-fan`);
                                        }}
                                    >
                                        {services.language.getText('wechatOfficialAccountFan')}
                                    </span>
                                    {services.language.getText('zsfwgzhfs')}
                                </span>
                            </div>
                            {checkSendModalResult && (
                                <div>
                                    <div>
                                        <span>
                                            {services.interpolate(
                                                services.language.getText('addSuccessCount'),
                                                { successCount: checkSendModalResult.successCount }
                                            )}
                                            <AntPopover
                                                placement={'top'}
                                                content={this.renderCheckSendModalResultError()}
                                            >
                                                <span className="wechat-templates-find-list-item-send-view__primary">
                                                    {checkSendModalResult.errorValue.length}
                                                </span>
                                            </AntPopover>
                                            {services.language.getText('ge')}
                                        </span>
                                    </div>
                                    <div>
                                        <span>{services.language.getText('sjhxysb')}</span>
                                        <span
                                            className="wechat-templates-find-list-item-send-view__primary"
                                            onClick={this.clearErrorValue}
                                        >
                                            {services.language.getText('common.clear')}
                                        </span>
                                        <span>{services.language.getText('sbnrhzxdjfs')}</span>
                                    </div>
                                </div>
                            )}
                        </div>
                    )}
                </div>
            </Modal>
        );
    };

    renderChooseCrowd = () => {
        const { supportMemberMarking, sendModalObject } = this.state;
        if (!supportMemberMarking) {
            return (
                <Button type="link" disabled style={{ padding: 0 }}>
                    {services.language.getText('contactManager')}
                </Button>
            );
        }
        return (
            <div>
                <MessageSubscriptionCrowdsSeletor
                    value={sendModalObject.crowdId}
                    onChange={this.onChangeCrowdId}
                    getSubsiteIds={this.getSubsiteIds}
                />
                {this.renderSelectedDisplay()}
            </div>
        );
    };

    getSubsiteIds = () => {
        const store: any = localStorage.get('wechantTemplatesStore');
        const subsiteId = store ? [store.id] : [];
        return subsiteId;
    };

    onChangeCrowdId = (crowdIdInfo: any[]) => {
        const { sendModalObject } = this.state;
        if (crowdIdInfo?.length) {
            this.getEstimateNum(crowdIdInfo[0].id);
        }
        this.setState({
            sendModalObject: { ...sendModalObject, crowdId: crowdIdInfo },
        });
    };

    getEstimateNum = (id: string) => {
        api.post<number>(
            { id, subscriptionFlag: 1 },
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MEMBER-V2`,
                apiPath: '/admin/crowd_members/estimate',
            }
        )
            .then((res) => {
                this.setState({ estimateNum: res });
            })
            .catch((error) => {
                errorHandle(error);
            });
    };

    deleteSelected = (id: string) => {
        const { sendModalObject } = this.state;
        const crowdId = sendModalObject.crowdId.filter((i: any) => i.id !== id);
        sendModalObject.crowdId = crowdId;
        this.setState({
            sendModalObject: cloneDeep(sendModalObject),
        });
    };

    renderSelectedDisplay = () => {
        const { sendModalObject } = this.state;
        if (sendModalObject && sendModalObject.crowdId && sendModalObject.crowdId.length > 0) {
            return (
                <Table
                    rowKey={(record: any) => record.id}
                    pagination={false}
                    columns={[
                        {
                            title: services.language.getText('peopleName'),
                            dataIndex: 'crowdName',
                        },
                        {
                            title: services.language.getText('createType'),
                            dataIndex: 'creationMode',
                            render: (value: string) => {
                                return value === 'RULE_MODE'
                                    ? services.language.getText('gzcj')
                                    : services.language.getText('drzdyh');
                            },
                        },
                        {
                            title: services.language.getText('subsites'),
                            dataIndex: 'subsiteName',
                        },
                        {
                            title: (
                                <TextTips
                                    text={services.language.getText('peopleNum')}
                                    tips={services.language.getText('limitPeopleCount')}
                                />
                            ),
                            dataIndex: 'peopleNumber',
                        },
                        {
                            title: services.language.getText('common.tableAction'),
                            className: 'action-column',
                            align: 'center',
                            render: (text, record) => {
                                return (
                                    <Button
                                        type="link"
                                        onClick={() => this.deleteSelected(record.id)}
                                    >
                                        {services.language.getText('common.delete')}
                                    </Button>
                                );
                            },
                        },
                    ]}
                    dataSource={sendModalObject.crowdId}
                />
            );
        }
    };
    renderCheckSendModalResultError = () => {
        const { checkSendModalResult } = this.state;
        if (!checkSendModalResult || checkSendModalResult.errorValue.length === 0) {
            return null;
        }
        return (
            <div className="wechat-templates-find-list-item-send-view__error-list">
                {checkSendModalResult.errorValue.map((value: string) => {
                    return <span style={{ marginRight: '10px' }}>{value}</span>;
                })}
            </div>
        );
    };
    onChangeSendModalType = (e: any) => {
        const { sendModalObject } = this.state;
        const value = e.target.value;
        sendModalObject.type = value;
        this.setState({
            sendModalObject: cloneDeep(sendModalObject),
        });
    };
    // 适用等级-选择会员卡
    onChangeCardLevel = (optionIds: any) => {
        const { sendModalObject } = this.state;

        sendModalObject.cardLevelIds = optionIds;
        this.setState({
            sendModalObject: cloneDeep(sendModalObject),
        });
    };
    onChangeSendModalFansScope = (fansScope: FansScopeEnum) => {
        const { sendModalObject } = this.state;
        sendModalObject.fansScope = fansScope;
        sendModalObject.type = fansScope === FansScopeEnum.PART ? 1 : 0;
        sendModalObject.sendMobileIds = '';
        sendModalObject.sendOpenIds = '';
        this.setState({
            sendModalObject: cloneDeep(sendModalObject),
        });
    };
    onChangeSendModalRemark = (e: any) => {
        const { sendModalObject } = this.state;
        let value = e.target.value;
        if (value) {
            value = value.replace(/\s*/g, '').replace(/，/g, ',');
        }
        if (sendModalObject.type === 1) {
            sendModalObject.sendMobileIds = value;
        }
        if (sendModalObject.type === 2) {
            sendModalObject.sendOpenIds = value;
        }
        this.setState({
            sendModalObject: cloneDeep(sendModalObject),
        });
    };
    onCancelSend = () => {
        const { itemProps, refreshList } = this.props;
        const { webSocketMessage } = this.state;
        const message = webSocketMessage ? webSocketMessage : itemProps.sendRecord;
        api.put(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                apiPath: `/admin/wechat_template_sendrecords/${message.id}/canceled`,
            }
        )
            .then(() => {
                refreshList();
                this.setState({
                    webSocketMessage: undefined,
                });
            })
            .catch((error) => {
                services.errorHandle(error);
            });
    };
    onContinueSend = () => {
        const { itemProps, refreshList } = this.props;
        const { webSocketMessage } = this.state;
        const message = webSocketMessage ? webSocketMessage : itemProps.sendRecord;
        api.put(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                apiPath: `/admin/wechat_template_sendrecords/${message.id}/continue_sending`,
            }
        )
            .then(() => {
                refreshList();
                this.setState({
                    webSocketMessage: undefined,
                });
            })
            .catch((error) => {
                services.errorHandle(error);
            });
    };
    onTopSend = () => {
        const { itemProps, refreshList } = this.props;
        const { webSocketMessage } = this.state;
        const message = webSocketMessage ? webSocketMessage : itemProps.sendRecord;
        api.put(
            {},
            {
                apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MESSAGE`,
                apiPath: `/admin/wechat_template_sendrecords/${message.id}/stop`,
            }
        )
            .then(() => {
                refreshList();
                this.setState({
                    webSocketMessage: undefined,
                });
            })
            .catch((error) => {
                services.errorHandle(error);
            });
    };

    renderSendInfo = () => {
        const { itemProps } = this.props;
        const { webSocketMessage } = this.state;
        if (!itemProps) {
            return null;
        }
        const supportManualSending = itemProps.supportManualSending;
        if (!supportManualSending) {
            return null;
        }
        const message = webSocketMessage ? webSocketMessage : itemProps.sendRecord;
        if (!message) {
            return null;
        }
        const experience = message.actualSendCount;
        const experienceUpperLimit = message.expectedSendCount;
        const sendStatus = message.sendStatus as SendStatus;
        const progressValueStyle = {
            width:
                experience && experienceUpperLimit
                    ? experience >= experienceUpperLimit
                        ? `100%`
                        : `${(experience / experienceUpperLimit) * 100}%`
                    : 0,
        };
        return (
            <div className="messages-subscription-find-list-item-edit-view__send-info">
                <div>
                    {message.sendTime && sendStatus !== SendStatus.CANCELED ? (
                        <span>
                            {services.language.getText('sendStatus')}&nbsp;
                            <span style={{ fontSize: '10px' }}>-{message.sendTime}</span>&nbsp;
                        </span>
                    ) : (
                        <span>
                            {services.language.getText('fszt')}:{SendStatusText[sendStatus]}
                        </span>
                    )}
                    {message.reason && (
                        <span className="messages-subscription-find-list-item-edit-view__send-info__reason">
                            -{message.reason}
                        </span>
                    )}
                </div>
                {(sendStatus === SendStatus.SENDING || sendStatus === SendStatus.STOP) && (
                    <div className="messages-subscription-find-list-item-edit-view__send-info__button-wrap">
                        <div
                            className="messages-subscription-find-list-item-edit-view__send-info__button"
                            onClick={this.onCancelSend}
                        >
                            {services.language.getText('qxfs')}
                        </div>
                        {sendStatus === SendStatus.SENDING && (
                            <span
                                className="messages-subscription-find-list-item-edit-view__send-info__button"
                                onClick={this.onTopSend}
                            >
                                {services.language.getText('ztfs')}
                            </span>
                        )}
                        {sendStatus === SendStatus.STOP && (
                            <span
                                className="messages-subscription-find-list-item-edit-view__send-info__button"
                                onClick={this.onContinueSend}
                            >
                                {services.language.getText('jxfs')}
                            </span>
                        )}
                    </div>
                )}
                {(sendStatus === SendStatus.SENDING || sendStatus === SendStatus.STOP) && (
                    <div className="messages-subscription-find-list-item-edit-view__send-info__line-wrap">
                        <div>{experience}</div>
                        <div style={{ width: '100%', padding: '6px 6px 0 6px' }}>
                            <div className="messages-subscription-find-list-item-edit-view__send-info__progress">
                                <div
                                    className="messages-subscription-find-list-item-edit-view__send-info__progress__value"
                                    style={progressValueStyle}
                                ></div>
                            </div>
                        </div>
                        <div>{experienceUpperLimit}</div>
                    </div>
                )}
                {(sendStatus === SendStatus.COMPLETED || sendStatus === SendStatus.CANCELED) && (
                    <div className="messages-subscription-find-list-item-edit-view__send-info__success-wrap">
                        <span>
                            {services.language.getText('ytj')}&nbsp;&nbsp;{message.successSendCount}
                        </span>
                    </div>
                )}
                {SendStatusTip[sendStatus] && (
                    <div className="messages-subscription-find-list-item-edit-view__send-info__tip">
                        <span>{SendStatusTip[sendStatus]}</span>
                    </div>
                )}
            </div>
        );
    };

    messageChange = (message: any) => {
        if (message && message.data && message.data !== '连接成功') {
            const webSocketMessage = JSON.parse(message.data);
            this.setState(
                {
                    webSocketMessage: {
                        id: webSocketMessage.id,
                        sendStatus: webSocketMessage.send_status,
                        reason: webSocketMessage.reason,
                        actualSendCount: webSocketMessage.actual_send_count,
                        expectedSendCount: webSocketMessage.expected_send_count,
                        successSendCount: webSocketMessage.success_send_count,
                        sendTime: webSocketMessage.send_time,
                    },
                },
                () => {
                    this.forceUpdate();
                }
            );
        }
    };

    render() {
        const { itemProps } = this.props;
        const { webSocketMessage } = this.state;
        const message = webSocketMessage ? webSocketMessage : itemProps.sendRecord;
        const disabled =
            message &&
            message.sendStatus &&
            (message.sendStatus === SendStatus.SENDING || message.sendStatus === SendStatus.STOP)
                ? true
                : false;
        const hideEdit = disableEditing.includes(itemProps.scene);
        const editDisabled =
            message &&
            message.sendStatus &&
            message.sendTime &&
            message.sendStatus === SendStatus.SENDING
                ? false
                : disabled;
        let tenant = ENV.TENANT;
        if (tenant === 'localhost' || tenant === 'magic') {
            if (ENV.AUTH_API_ROOT.includes('dev')) {
                tenant = 'miao';
            } else if (ENV.AUTH_API_ROOT.includes('test')) {
                tenant = 'demo2';
            }
        }

        return (
            <div className="messages-subscription-find-list-item-edit-view">
                <div className="messages-subscription-find-list-item-edit-view__button-wrap">
                    <div>
                        {itemProps.supportManualSending && (
                            <Button
                                disabled={disabled}
                                className="messages-subscription-find-list-item-edit-view__button send"
                                type="primary"
                                onClick={this.onSend}
                            >
                                {services.language.getText('fssz')}
                            </Button>
                        )}
                    </div>
                    {itemProps.showEditButton && !hideEdit && (
                        <Button
                            disabled={editDisabled}
                            className="messages-subscription-find-list-item-edit-view__button"
                            type="primary"
                            onClick={this.onEdit}
                        >
                            {services.language.getText('common.edit')}
                        </Button>
                    )}
                </div>
                {this.renderSendInfo()}
                {this.renderModal()}
                {this.renderSendModal()}
                {itemProps.supportManualSending &&
                    message &&
                    message.id &&
                    message.sendStatus === SendStatus.SENDING && (
                        <CmWebsocket
                            socketUrl={`/MAGIC-WEB-SOCKET/${tenant}/websocket?id=${
                                message.id
                            }_${Math.random()}`}
                            id={message.id}
                            onMessageChange={this.messageChange}
                        />
                    )}
            </div>
        );
    }
}
