import { PureComponent, createElement } from 'react';
import { services, actions, builder } from '@comall-backend-builder/core';
import { Entity, ComponentsManager } from '@comall-backend-builder/core/lib/parser';
import { message as AntMessage } from 'antd';
import { mapValues, defaults, get, map, isArray, find, round, debounce } from 'lodash';
import { connect } from 'react-redux';
import { FormComponentProps } from 'antd/lib/form';

enum ExecuteStatus {
    /**
     * 未发放
     */
    NOTSTARTED = 'NOTSTARTED',
    /**
     * 发放中
     */
    STARTED = 'STARTED',
    /**
     * 已结束
     */
    COMPLETE = 'COMPLETE',
}

export interface CouponSaleActivityEditFormProps extends FormComponentProps {
    entity: Entity;
    getForm: () => any;
    params: any;
    onSubmit: (e: any, fields: any) => void;
    unmountComponent: () => void;
    executeStatus: ExecuteStatus;
}

class couponSaleActivityEditForm extends PureComponent<CouponSaleActivityEditFormProps> {
    componentWillUnmount() {
        this.props.unmountComponent();
    }
    render() {
        const { entity, onSubmit, wrappedComponentRef, executeStatus } = this.props;

        let fields = [
            { property: 'baseInfo.subsites' },
            {
                property: 'baseInfo.selectCoupon',
                extra:
                    '优惠券和优惠券礼包仅支持设置一种，请添加适用门店通用的优惠券和优惠券礼包（或全部专柜可用），否则用户购买后可能无法使用。',
                controlConfig: {
                    disabled: executeStatus !== ExecuteStatus.NOTSTARTED,
                },
            },
            {
                property: 'baseInfo.dateRange',
                extra: '不填写则自动读取选择的优惠券或优惠券礼包开始、结束时间',
            },
            { property: 'saleInfo.name' },
            {
                property: 'saleInfo.couponPicture',
                extra: services.language.getText('zdkscPic3'),
            },
            {
                property: 'saleInfo.couponSalePrice',
            },
            { property: 'saleInfo.saleLimitType' },
            {
                property: 'saleInfo.saleLimitNum',
                visible: (values: any) => get(values, 'saleInfo.saleLimitType') === 'YES',
            },
            {
                property: 'useRulesInfo.info',
            },
            {
                property: 'categoryInfo.categories',
                controlConfig: {
                    style: {
                        width: 500,
                    },
                    getParams(props: any) {
                        const { row } = props;
                        const subsiteIds =
                            row.baseInfo && row.baseInfo.subsites
                                ? map(row.baseInfo.subsites, 'id').join(',')
                                : null;
                        return { subsiteIds };
                    },
                },
            },
        ];

        let formProps = {
            entity,
            componentId: 'CouponSaleActivityEditFormContainer',
            wrappedComponentRef,
            onSubmit,
            className: 'coupon-sale-activity-edit-form-container',
            direction: 'horizontal',
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            fields: fields,
            submit: true,
            footer: {
                items: [
                    {
                        htmlType: 'button',
                        route: 'goBack',
                        text: '取消',
                        type: 'default',
                        style: { marginLeft: 10 },
                    },
                ],
            },
            onSubmitSuccess: () => {
                AntMessage.success(services.language.getText('common.saveSuccess'), () =>
                    services.behaviorHandle({ route: 'goBack' })
                );
            },
        };
        return createElement(ComponentsManager.get('ModifyFormPlus'), formProps);
    }
}

function mapStateToProps(state: any, props: any) {
    const { onSubmit, entity, params } = props;
    let form: any;
    let executeStatus;
    const components = get(state, 'components');
    const componentsKey = Object.keys(components);
    const key = find(componentsKey, (item) => item.indexOf('DataFormPlus') > -1);
    if (!!key) {
        const temp = components[key];
        executeStatus = get(temp, 'fields.executeStatus');
    }

    /**
     * 提交前额外验证
     * @param entityFields
     */
    const validate = (entity: any) => {
        const couponInfo = get(entity, 'baseInfo.selectCoupon[0].couponInfo');
        const saleLimitType = get(entity, 'saleInfo.saleLimitType');
        const saleLimitNum = get(entity, 'saleInfo.saleLimitNum');
        const useRulesInfo = get(entity, 'useRulesInfo.info');
        const couponSalePrice = get(entity, 'saleInfo.couponSalePrice');

        if (saleLimitType === 'YES' && !saleLimitNum) {
            AntMessage.error(services.language.getText('qsryhxgsl'));
            return false;
        }

        if (!!couponInfo && isArray(couponInfo)) {
            if (!useRulesInfo || !useRulesInfo.trim()) {
                AntMessage.error('配置优惠券礼包时，需要同时配置使用须知');
                return false;
            }

            if (couponInfo.length > 0) {
                const notExist = couponInfo.some(
                    (item: any) => !item.apportionPrice && item.apportionPrice !== 0
                );
                if (notExist) {
                    AntMessage.error(services.language.getText('yhqlbsmsqtxftj'));
                    return false;
                }
                let apportionPrice = 0;
                couponInfo.forEach((item: any) => {
                    apportionPrice += item.apportionPrice * item.quantity;
                });

                if (round(Number(couponSalePrice), 2) !== round(Number(apportionPrice), 2)) {
                    AntMessage.error(services.language.getText('ftjzhylbdmjbd'));
                    return false;
                }
            }
        }
        return true;
    };

    return defaults(
        {
            onSubmit,
            executeStatus,
        },
        {
            wrappedComponentRef: (instance: any) => {
                form = instance && instance.props.form;
            },
            getForm: () => form,
            onSubmit: debounce((event: any, fields: any) => {
                const entityFields = mapValues(fields, (field, name) => {
                    return field.value;
                });
                if (entityFields) {
                    const hasValidate = validate(entityFields);
                    if (hasValidate) {
                        entity.modify(entityFields, params);
                    }
                }
            }, 300),
            unmountComponent: () => {
                builder
                    .getStore()
                    .dispatch(
                        actions.unmountComponentAction('CouponSaleActivityEditFormContainer')
                    );
            },
        }
    );
}
export const CouponSaleActivityEditFormView = connect(mapStateToProps)(couponSaleActivityEditForm);
