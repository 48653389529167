import React, { PureComponent } from 'react';
import { Button } from 'antd';
import { services } from '@comall-backend-builder/core';
import { SelectSubsite } from './select-subsite';
import { isEqual } from 'lodash';
import { api } from '@comall-backend-builder/core/lib/services';
import { PaginationResult } from '../../../../../services/api';
// import './index.less';

interface Props {
    value: any;
    contentConfig: Record<string, any>;
    modalConfig: Record<string, any>;
    onChange: (value: any) => void;
    type?: string;
    subsiteIds?: string[];
}
interface State {
    shouldShowSelecButton: boolean;
    visibleModal: boolean;
}
const { language } = services;
class CustomSelectSubsite extends PureComponent<Props, State> {
    state: State = {
        visibleModal: false,
        shouldShowSelecButton: true,
    };
    componentDidMount() {
        this.initDefaultValueIfNeeded();
    }
    componentDidUpdate(prevProps: Props) {
        if (!isEqual(this.props.subsiteIds, prevProps.subsiteIds)) {
            this.reset();
            this.initDefaultValueIfNeeded();
        }
    }

    private initDefaultValueIfNeeded() {
        const { subsiteIds } = this.props;
        if (subsiteIds?.length !== 1) {
            return;
        }
        api.get<PaginationResult<{ id: string; name: string }>>(
            { subsiteIds },
            { apiPath: '/admin/subsites/mine' }
        ).then((res) => {
            const [subsite] = res.result;
            if (res.result.length === 1 && subsite.id === subsiteIds[0]) {
                this.props.onChange(subsite);
                this.setState({ shouldShowSelecButton: false });
            }
        });
    }

    private reset() {
        const { onChange } = this.props;
        onChange(undefined);
        this.setState({ shouldShowSelecButton: true });
    }

    showModal = () => {
        this.setState({ visibleModal: true });
    };
    hideModal = () => {
        this.setState({ visibleModal: false });
    };
    onChange = (value: { id: number; name: string }) => {
        const { onChange } = this.props;

        this.hideModal();
        onChange({
            ...value,
        });
    };
    render() {
        const {
            value: { linkParams },
            contentConfig,
            modalConfig,
            subsiteIds,
        } = this.props;
        const { visibleModal, shouldShowSelecButton } = this.state;
        const hasTarget = linkParams && linkParams.name;

        const selectProps = {
            value: linkParams,
            onChange: this.onChange,
        };
        return (
            <span className="link-name">
                {hasTarget ? (
                    <span className="link-name-label">{linkParams.name}</span>
                ) : (
                    language.getText('notSelect')
                )}
                {shouldShowSelecButton && (
                    <Button type={'link'} onClick={this.showModal} className="link-type-edit">
                        {hasTarget
                            ? language.getText('components.Tools.modify')
                            : language.getText('components.Tools.select')}
                    </Button>
                )}
                <SelectSubsite
                    contentConfig={contentConfig}
                    modalConfig={modalConfig}
                    showModal={visibleModal}
                    onCancel={this.hideModal}
                    subsiteIds={subsiteIds}
                    {...selectProps}
                />
            </span>
        );
    }
}

export { CustomSelectSubsite };
