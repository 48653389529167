import React, { Component } from 'react';
import { Table, Button } from 'antd';
import { cloneDeep, remove, forEach } from 'lodash';
import './index.less';
import { services } from '@comall-backend-builder/core';

const STATUS_MAP: any = {
    NOT_STARTED: 'notStarted',
    STARTED: 'inprogress',
    COMPLETE: 'end',
};

/**
 * 分站展示和内容编辑
 */
class OpenBoxActivitySelectedDisplay extends Component<any> {
    state: any = {
        displayAll: false,
    };
    selectedRows: any[] = [];

    getTableConfig = () => {
        const { disabled } = this.props;
        let config: any = {
            rowKey: 'id',
            columns: [
                {
                    title: services.language.getText('activityName'),
                    dataIndex: 'name',
                    key: 'name',
                    ellipsis: true,
                },
                {
                    title: services.language.getText('startTime'),
                    dataIndex: 'startTime',
                    key: 'startTime',
                },
                {
                    title: services.language.getText('endTime'),
                    dataIndex: 'endTime',
                    key: 'endTime',
                },
                {
                    title: services.language.getText('activityStatus'),
                    dataIndex: 'executeStatus',
                    key: 'executeStatus',
                    render: (executeStatus: string) => (
                        <span className="goods-field-name">
                            {services.language.getText(STATUS_MAP[executeStatus])}
                        </span>
                    ),
                },
                {
                    title: services.language.getText('common.tableAction'),
                    className: 'action-column',
                    key: 'operation',
                    width: 100,
                    render: (i: any, row: any) => {
                        return !disabled ? (
                            <Button type={'link'} onClick={this.onRemove(row)}>
                                {services.language.getText('common.delete')}
                            </Button>
                        ) : null;
                    },
                },
            ],
            footer: () => this.renderFooter(),
            style: {
                marginTop: 20,
            },
        };
        return config;
    };

    renderFooter = () => {
        const { disabled, data } = this.props;
        const { displayAll } = this.state;
        return (
            <div className="product-selected-footer">
                {data && data.length > 5 && (
                    <Button
                        className="table-footer-expand"
                        type="link"
                        onClick={this.handleDisplayChange}
                    >
                        {displayAll
                            ? services.language.getText('yzhd')
                            : services.language.getText('ckhd')}
                    </Button>
                )}
                {!disabled && (
                    <div className="setting-item">
                        <Button
                            className="setting-item-ok"
                            type="primary"
                            onClick={this.onRemoveAll}
                        >
                            {services.language.getText('allClear')}
                        </Button>
                    </div>
                )}
            </div>
        );
    };

    onRemoveAll = () => {
        const { data, onRemove } = this.props;

        const results = cloneDeep(data);

        forEach(results, (subsite: any) => {
            if (subsite.select) {
                subsite.delete = true;
            }
        });

        onRemove([]);
    };

    onRemove = (row: any) => {
        const { data, onRemove } = this.props;

        return () => {
            const result = cloneDeep(data);
            remove(result, (subsite: any) => row.id === subsite.id);
            onRemove(result);
        };
    };

    handleDisplayChange = () => {
        const { displayAll } = this.state;
        this.setState({
            displayAll: !displayAll,
        });
    };

    render() {
        const { data } = this.props;
        if (!data || data.length === 0) return '';
        const { displayAll } = this.state;
        let tableConfig = this.getTableConfig();
        const tableProps = {
            ...tableConfig,
            dataSource: displayAll ? data : data && data.slice(0, 5),
        };

        return <Table {...tableProps} pagination={false}></Table>;
    }
}

export { OpenBoxActivitySelectedDisplay };
