import * as React from 'react';

import { ArrayMode } from '@comall-backend-builder/types';
import { CheckboxGroup } from '@comall-backend-builder/components-basis';
import { getSupportAlipay } from '../../../services/tenant';
import { language } from '@comall-backend-builder/core/lib/services';

export class CarparkSupportAlipayMode extends ArrayMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        const options = [
            {
                name: language.getText('zcsyzfbxcx'),
                id: 'support',
                disabled: !getSupportAlipay(),
            },
        ];
        return <CheckboxGroup {...controlInfo} options={options} defaultValue={[]} />;
    }
}
