import React, { Component } from 'react';
import { Button } from '@comall-backend-builder/components-basis';
import { Entity } from '@comall-backend-builder/core/lib/parser';
import { api, language } from '@comall-backend-builder/core/lib/services';
import { Modal, message as AntMessage } from 'antd';

export interface BlacklistButtonProps {
    row: any;
    entity: Entity;
    params: any;
}
export class BlacklistButton extends Component<BlacklistButtonProps> {
    setBlackList = () => {
        const { row, entity, params } = this.props;
        Modal.confirm({
            title: language.getText('qdszhmdm'),
            onOk: () => {
                api.post(
                    {
                        memberId: row.contentAuthorMemberId,
                    },
                    {
                        apiRoot: ENV.API_ROOT.replace('/WEB-API', '/SHOW'),
                        apiPath: '/admin/system/blacklists',
                    }
                ).then(() => {
                    AntMessage.success(language.getText('szhmdcg'));
                    entity.search(params);
                });
            },
        });
    };
    render() {
        const { showBlacklist } = this.props.row;
        return (
            <Button type="link" disabled={!showBlacklist} onClick={this.setBlackList}>
                {language.getText('szhmd')}
            </Button>
        );
    }
}
