import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export let config: Config = {
    components: {
        CustomTabbarPageView: {
            component: 'Viewport',
            entity: 'CustomTabbarPageEntity',
        },
        CustomTabbarPageDesign: {
            component: 'Card',
            content: {
                component: 'DesignMemberCenter',
                client: ENV.DESIGN_CLIENT_URL,
                apiPath: '/admin/pages/:type/:id',
                type: 'TAB_BAR',
            },
        },
        CustomTabbarPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'GridLayout',
                    items: [
                        {
                            component: 'CustomTabbarPageFilter',
                            span: 24,
                        },
                        {
                            component: 'Button',
                            text: '<<addFooterNav>>',
                            type: 'primary',
                            icon: 'plus',
                            span: 4,
                            route: '/custom-store-tabbar/add',
                            className: 'mt24 mb24',
                        },
                    ],
                },

                {
                    component: 'CustomTabbarPageTable',
                },
            ],
        },
        CustomTabbarPageFilter: {
            component: 'FilterForm',
            direction: 'inline',
            fields: [
                {
                    property: 'groupSubsiteName',
                    controlConfig: {
                        allowClear: true,
                        showSearch: true,
                        optionFilterProp: 'children',
                        style: { width: '160px' },
                    },
                },
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: '160px' },
                    },
                },
            ],
            submit: { text: '<<common.search>>' },
        },
        CustomTabbarPageTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'groupSubsiteName',
                },
                {
                    property: 'enabled',
                },
                {
                    property: 'pageName',
                    width: 180,
                },
                {
                    property: 'channels',
                },
                {
                    property: 'createTime',
                },
                {
                    property: 'lastModifyTime',
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                items: [
                    {
                        type: 'link',
                        text: '<<components.PageManage.setting>>',
                        path: '/custom-store-tabbar/edit/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'CustomLink',
                        text: '<<components.PageManage.decorate>>',
                        config: {
                            url: `/design-editor/?type=TAB_BAR&id={{row.id}}&channels={{row.channels}}`,
                            target: '_blank',
                        },
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/custom-store-tabbar/copy/{{row.id}}',
                    },

                    {
                        type: 'link',
                        text: '<<common.log>>',
                        path: '/custom-store-tabbar/log/{{row.id}}/DESIGN_PAGE',
                    },
                    {
                        type: 'delete',
                        text: '<<components.PageManage.delete>>',
                        statusKey: 'row.canDelete',
                        config: {
                            type: 'link',
                            tooltip: null,
                            title: '<<confirmDelete>>',
                            okText: '<<common.ok>>',
                            cancelText: '<<common.cancel>>',
                        },
                    },
                ],
            },
        },
        CustomTabbarPageAdd: {
            component: 'Card',
            title: '<<addFooterNav>>',
            content: {
                component: 'CustomTabbarPageAddForm',
            },
        },
        CustomTabbarPageAddForm: {
            component: 'CreateForm',
            entity: 'CustomTabbarPageEntity',
            fields: [
                {
                    property: 'name',
                },
                { property: 'groupSubsiteName' },
                { property: 'subsites' },
                {
                    property: 'channels',
                    controlConfig: {
                        isAdd: true,
                    },
                },
                {
                    property: 'enabled',
                },
            ],
            labelCol: 4,
            controlCol: 20,
            submit: { text: '<<common.submit>>' },
            submitSuccessBehavior: {
                route: '/custom-store-tabbar',
            },
            footer: {
                items: [
                    {
                        text: '<<common.cancel>>',
                        htmlType: 'button',
                        type: 'default',
                        route: 'goBack',
                        style: { marginLeft: 16 },
                    },
                ],
            },
        },
        CustomTabbarPageEdit: {
            component: 'Card',
            title: '<<setFooterNav>>',
            content: {
                component: 'CustomTabbarPageEditForm',
            },
        },
        CustomTabbarPageEditForm: {
            component: 'ModifyForm',
            entity: 'CustomTabbarPageEntity',
            fields: [
                {
                    property: 'name',
                },
                { property: 'groupSubsiteName' },
                { property: 'subsites' },
                {
                    property: 'channels',
                    controlConfig: {
                        disabled: true,
                    },
                },
                {
                    property: 'enabled',
                },
            ],
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            submit: { text: '<<common.save>>' },
            submitSuccessBehavior: {
                route: '/custom-store-tabbar',
            },
            footer: {
                items: [
                    {
                        text: '<<common.cancel>>',
                        htmlType: 'button',
                        type: 'default',
                        route: 'goBack',
                        style: { marginLeft: 16 },
                    },
                ],
            },
        },
        CustomTabbarPageCopy: {
            component: 'Card',
            title: '<<setFooterNav>>',
            content: {
                component: 'CustomTabbarPageCopyForm',
            },
        },
        CustomTabbarPageCopyForm: {
            component: 'CopyFormPlus',
            entity: 'CustomTabbarPageEntity',
            fields: [
                {
                    property: 'name',
                },
                { property: 'groupSubsiteName' },
                { property: 'subsites' },
                {
                    property: 'channels',
                },
                {
                    property: 'enabled',
                },
            ],
            loaderType: 'get',
            labelCol: 4,
            controlCol: 20,
            submit: { text: '<<common.submit>>' },
            submitSuccessBehavior: {
                route: '/custom-store-tabbar',
            },
            footer: {
                items: [
                    {
                        text: '<<common.cancel>>',
                        htmlType: 'button',
                        type: 'default',
                        route: 'goBack',
                        style: { marginLeft: 16 },
                    },
                ],
            },
        },
        CustomTabbarLogsPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            items: [
                {
                    component: 'OperationLogsTable',
                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-EVENT-TRACKING`,
                    apiPath: '/admin/operation_logs/v2/page',
                },
                { component: 'PageBackFooter' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/custom-store-tabbar',
                    component: 'CustomTabbarPageView',
                    breadcrumbName: '<<subsiteFooterNav>>',
                    privilege: {
                        path: 'tabBar',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/design/:id',
                            component: 'CustomTabbarPageDesign',
                        },
                        {
                            path: '/add',
                            component: 'CustomTabbarPageAdd',
                        },
                        {
                            path: '/edit/:id',
                            component: 'CustomTabbarPageEdit',
                        },
                        {
                            path: '/copy/:id',
                            component: 'CustomTabbarPageCopy',
                        },
                        {
                            path: '/log/:business_id/:business_type',
                            component: 'CustomTabbarLogsPage',
                            breadcrumbName: '<<common.log>>',
                        },
                        {
                            path: '/',
                            component: 'CustomTabbarPage',
                        },
                    ],
                },
            ],
        },
    ],
};
