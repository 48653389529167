import { Loader } from '@comall-backend-builder/core/lib/loaders';
import { services } from '@comall-backend-builder/core';
import { ApiRequestConfig } from '@comall-backend-builder/core/lib/services/api';
import { isEmpty, isNull } from 'lodash';
import { language } from '@comall-backend-builder/core/lib/services';

const api = services.api;

export const FixedPricePromotionsLoader: Loader = {
    get: async function(data, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`;
        config.apiPath = '/admin/fixed_price_promotions';
        const { id } = data;
        if (id) {
            config.apiPath = `/admin/fixed_price_promotions/${id}`;
        } else {
            if (!isEmpty(data.dateRange)) {
                data.startTime = data.dateRange.start + ' 00:00:00';
                data.endTime = data.dateRange.end + ' 23:59:59';
                delete data.dateRange;
            }
        }
        const params: any = {};
        for (const key in data) {
            if (Object.prototype.hasOwnProperty.call(data, key)) {
                const element = data[key];
                if (!isNull(element)) {
                    params[key] = element;
                }
            }
        }
        return await api.get(params, config).then((res: any) => {
            if (id) {
                const {
                    name,
                    tagName,
                    startTime,
                    endTime,
                    subsiteId,
                    rewardInfos,
                    goods,
                    pageConfigInfo: { enableCountdown, bannerPicture },
                } = res;
                if (res.marketingSchedule) {
                    const { startTime, endTime } = res.marketingSchedule || {};
                    res.marketingSchedule = {
                        ...res.marketingSchedule,
                        time:
                            startTime && endTime
                                ? `${startTime}-${endTime}`
                                : language.getText('longTerm'),
                    };
                }
                res.baseInfo = {
                    name,
                    tagName,
                    dateRange: {
                        start: startTime,
                        end: endTime,
                    },
                    subsiteId,
                    marketingSchedule: res.marketingSchedule ? [res.marketingSchedule] : [],
                };
                res.ruleConfig = { rewardInfos };
                res.productConfig = {
                    goods: goods.map((g: any) => ({
                        ...g,
                        merchantName: g.merchant.name,
                        subsiteName: g.subsite.name,
                        subsiteId: g.subsite.id,
                        goodsDeductPoint: g.pointPrice,
                    })),
                };
                res.pageConfigInfo = {
                    bannerPicture: bannerPicture
                        ? [{ id: bannerPicture.id, path: bannerPicture.url }]
                        : [],
                    enableCountdown: enableCountdown.toString(),
                };
            }
            return res;
        });
    },
    post: async function(params, config: ApiRequestConfig) {
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`;
        config.apiPath = '/admin/fixed_price_promotions';
        const newParams = dataParser(params);
        return await api.post(newParams, config);
    },
    put: async function(params, config: ApiRequestConfig) {
        const { id } = params;
        config.apiRoot = `${ENV.AUTH_API_ROOT}/MAGIC-PROMOTION`;
        config.apiPath = `/admin/fixed_price_promotions/${id}`;

        const newParams = dataParser(params);
        newParams.id = id;
        return await api.put(newParams, config);
    },
};

/**
 * 处理修改和新增接口数据格式
 * @param params
 */
function dataParser(params: any) {
    const {
        baseInfo: {
            dateRange: { start, end },
            marketingSchedule,
            ...baseInfo
        },
        ruleConfig: { rewardInfos },
        productConfig: { goods },
        pageConfigInfo: { enableCountdown, bannerPicture },
    } = params;
    return {
        ...baseInfo,
        startTime: start,
        endTime: end,
        rewardInfos,
        goodsIds: goods.map((g: any) => g.id),
        pageConfigInfo: {
            enableCountdown,
            bannerPicture: bannerPicture?.length
                ? { id: bannerPicture[0].id, url: bannerPicture[0].path }
                : undefined,
        },
        marketingScheduleId: (marketingSchedule?.length && marketingSchedule[0].id) || null,
    };
}
