import React, { useMemo } from 'react';
import { Tag, Popover } from 'antd';
import { modes } from '@comall-backend-builder/types';
import { interpolate } from '@comall-backend-builder/core/lib/services';

import './index.less';

const useContainerId = ((id) => () => useMemo(() => `group-popover-container-${id++}`, []))(0);

export const GroupPopover: React.FC<any> = (props) => {
    const { value = [], displayInfo } = props;
    const remark = interpolate(displayInfo.remark, displayInfo.row);
    const tips = interpolate(displayInfo.tips, displayInfo.row);
    const name = displayInfo.propertyName || displayInfo.name;
    const content = displayInfo.row[name] || value;
    const containerId = useContainerId();

    function getPopupContainer(): HTMLElement {
        return document.getElementById(containerId) || document.body;
    }

    return (
        <div id={containerId}>
            {remark}{' '}
            {content && content.length > 0 ? (
                <Popover
                    autoAdjustOverflow
                    placement={'right'}
                    content={
                        <div style={{ maxWidth: 400 }}>
                            {content.map((i: any) => (
                                <Tag key={i.id} style={{ margin: 5 }}>
                                    {i.name}
                                </Tag>
                            ))}
                        </div>
                    }
                    getPopupContainer={getPopupContainer}
                >
                    <span className="group-tips">{tips}</span>
                </Popover>
            ) : (
                <span className="group-tips">NA</span>
            )}
        </div>
    );
};
/**
 * 分组数据气泡展示
 * propertyName:参数名
 * remark：说明文字
 * tips 提示文字
 */
export class GroupPopoverMode extends modes.StringMode {
    /**
     * 获取展示组件
     */
    public getDisplayComponent(value: any, displayInfo: any) {
        return <GroupPopover value={value} displayInfo={displayInfo} />;
    }
}
