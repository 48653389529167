/**
 * 跳转不同的活动页面
 * @param type 活动类型
 * @returns  privilege 权限 route 路由
 */
export function goActivityTarget(type: ActivityScheduleType) {
    // 权限
    let privilege: any = null;
    //跳转路由
    let route: string = '';

    //跳转到的页面类型 edit 编辑 info 查看 edit-RESERVE 预约抢购编辑
    let pageType: string = 'edit';
    switch (type) {
        case 'INVITATION':
            privilege = {
                path: 'invitationActivity',
                level: 'full',
            };
            route = '/menu-list/market/invitation-activity';
            break;
        case 'SHARE_PAGE':
            privilege = {
                path: 'sharePageActivity',
                level: 'full',
            };
            pageType = 'info';
            route = '/menu-list/market/share-page-activity';
            break;
        case 'EVENT_MARKET':
            privilege = {
                path: 'eventMarket',
                level: 'full',
            };
            route = '/menu-list/market/eventMarket';
            break;
        case 'GROUP_BUYING':
            privilege = {
                path: 'groupBuyingRule',
                level: 'full',
            };
            route = '/menu-list/market/group-buying';
            break;
        case 'BARGAIN':
            privilege = {
                path: 'bargain',
                level: 'full',
            };
            route = '/menu-list/market/bargain-activity';
            break;
        case 'CASH':
            privilege = {
                path: 'cashCouponRule',
                level: 'full',
            };
            route = '/menu-list/coupon/cash-coupon-rules';
            break;
        case 'GIFT':
            privilege = {
                path: 'giftCouponRule',
                level: 'full',
            };
            route = '/menu-list/coupon/gift-coupon-rules';
            break;
        case 'FREIGHT':
            privilege = {
                path: 'freightCouponRule',
                level: 'full',
            };
            route = '/menu-list/coupon/freight-coupon-rules';
            break;
        case 'PARKING':
            privilege = {
                path: 'parkingCouponRule',
                level: 'full',
            };
            route = '/menu-list/coupon/parking-coupon-rules';
            break;
        case 'BALANCE':
            privilege = {
                path: 'balanceCouponRule',
                level: 'full',
            };
            route = '/menu-list/coupon/balance-coupon-rules';
            break;
        case 'BFPLAT_COUPON':
            privilege = {
                path: 'bfplatCouponRule',
                level: 'full',
            };
            route = '/menu-list/coupon/bfplat-coupon-rules';
            break;
        case 'EFUTURE_COUPON':
            privilege = {
                path: 'eFutureCouponRule',
                level: 'full',
            };
            route = '/menu-list/coupon/efuture-coupon-rules';
            break;
        case 'GIVING_COUPON_SCHEDULE':
            privilege = {
                path: 'givingCouponSchedule',
                level: 'full',
            };
            route = '/menu-list/coupon/giving-coupon';
            break;
        case 'VIRTUAL_PRODUCT':
            privilege = {
                path: 'virtualProductConf',
                level: 'full',
            };
            route = '/sales-electronic-coupon';
            break;
        case 'TIME_LIMIT':
            privilege = {
                path: 'timelimitActivity',
                level: 'full',
            };
            route = '/timelimit-activity';
            break;
        case 'SINGLE_MULTI_PROMOTION':
            privilege = {
                path: 'reduction',
                level: 'full',
            };
            route = '/promotion';
            break;
        case 'FIXED_PRICE':
            privilege = {
                path: 'fixedPrice',
                level: 'full',
            };
            route = '/fixed-price';
            break;
        case 'RESERVE':
            privilege = {
                path: 'presaleActivity',
                level: 'full',
            };
            pageType = 'edit-RESERVE';
            route = '/menu-list/market/presale-activities';
            break;
        case 'FULL_PAYMENT':
            privilege = {
                path: 'fullPaymentActivity',
                level: 'full',
            };
            route = '/menu-list/market/full-payment-activities';
            break;
        case 'RESERVE_DRAW_LOTS':
            privilege = {
                path: 'reserveDrawLots',
                level: 'full',
            };
            route = '/menu-list/market/reserve-draw-lots';
            break;
        case 'PAYMENT_AWARD':
            privilege = {
                path: 'payAward',
                level: 'full',
            };
            route = '/menu-list/market/pay-with-gifts';
            break;
        case 'COLLECTION_POINT':
            privilege = {
                path: 'collectionPoint',
                level: 'full',
            };
            route = '/menu-list/market/collection-points';
            break;
        case 'CARD_COLLECTION':
            privilege = {
                path: 'cardCollectionActivity',
                level: 'full',
            };
            route = '/menu-list/market/cardCollection';
            break;
        case 'WATCHWORD':
            privilege = {
                path: 'watchword',
                level: 'full',
            };
            route = '/menu-list/market/watchword';
            break;
        case 'SIGN_IN':
            privilege = {
                path: 'checkinActivity',
                level: 'full',
            };
            route = '/menu-list/market/checkin-activity';
            break;
        case 'IMPROVE_INFORMATION':
            privilege = {
                path: 'improveInformation',
                level: 'full',
            };
            route = '/menu-list/market/improve-information';
            break;
        case 'WHEEL_DRAW':
            privilege = {
                path: 'wheelDraw',
                level: 'full',
            };
            route = '/menu-list/market/wheel-draw';
            break;
        case 'RED_PACKAGE_RAIN':
            privilege = {
                path: 'redPackageRain',
                level: 'full',
            };
            route = '/menu-list/market/red-package-rain';
            break;
        case 'SLOT_MACHINE':
            privilege = {
                path: 'slotMachine',
                level: 'full',
            };
            route = '/menu-list/market/slot-machines';
            break;
        case 'LUCKY_RED_PACKAGE':
            privilege = {
                path: 'luckyRedPackage',
                level: 'full',
            };
            route = '/menu-list/market/lucky-red-package';
            break;
        case 'OPEN_BOX':
            privilege = {
                path: 'openBoxActivity',
                level: 'full',
            };
            route = '/menu-list/market/open-box';
            break;
        case 'BLIND_BOX':
            privilege = {
                path: 'blindBoxMarketing',
                level: 'full',
            };
            route = '/menu-list/market/blind-box';
            break;
        case 'FESTIVAL_MARKETING':
            privilege = {
                path: 'festivalMarketing',
                level: 'full',
            };
            route = '/festival-marketing';
            break;
        case 'MEMBER_CARD_DISCOUNT':
            privilege = {
                path: 'memberCardDiscount',
                level: 'full',
            };
            route = '/member_card_discount_activities';
            break;
        case 'SCRATCH_CARD':
            privilege = {
                path: 'scratchCard',
                level: 'full',
            };
            route = '/scratchCard';
            break;
        case 'LOTTERY_CODE':
            privilege = {
                path: 'lotteryCode',
                level: 'full',
            };
            route = '/lottery-code';
            break;
        case 'STORED_VALUE_CARD_REWARD':
            privilege = {
                path: 'storedValueCardRewardActivity',
                level: 'full',
            };
            route = '/stored-value-card-buy-activity';
            break;
        default:
            privilege = {
                path: '',
                level: '',
            };
            route = '/';
            break;
    }
    return { privilege, route, pageType };
}

export enum ActivityScheduleType {
    /*
     * 邀请有礼
     */
    INVITATION = 'INVITATION',
    /*
     * 分享有礼
     */
    SHARE_PAGE = 'SHARE_PAGE',
    /*
     * 事件营销
     */
    EVENT_MARKET = 'EVENT_MARKET',
    /*
     * 拼团活动
     */
    GROUP_BUYING = 'GROUP_BUYING',
    /*
     * 砍价
     */
    BARGAIN = 'BARGAIN',
    /*
     * 代金券
     */
    CASH = 'CASH',
    /*
     * 礼品券
     */
    GIFT = 'GIFT',
    /*
     * 运费券
     */
    FREIGHT = 'FREIGHT',
    /*
     * 停车券
     */
    PARKING = 'PARKING',
    /*
     * 长益余额券
     */
    BALANCE = 'BALANCE',
    /*
     * 长益编码券
     */
    BFPLAT_COUPON = 'BFPLAT_COUPON',
    /*
     * 富基优惠券
     */
    EFUTURE_COUPON = 'EFUTURE_COUPON',
    /*
     * 定向发券
     */
    GIVING_COUPON_SCHEDULE = 'GIVING_COUPON_SCHEDULE',
    /*
     * 虚拟商品
     */
    VIRTUAL_PRODUCT = 'VIRTUAL_PRODUCT',
    /*
     * 秒杀
     */
    TIME_LIMIT = 'TIME_LIMIT',
    /*
     * 单多品促销
     */
    SINGLE_MULTI_PROMOTION = 'SINGLE_MULTI_PROMOTION',
    /*
     * 打包一口价
     */
    FIXED_PRICE = 'FIXED_PRICE',
    /*
     * 预约抢购
     */
    RESERVE = 'RESERVE',
    /*
     * 全款预售
     */
    FULL_PAYMENT = 'FULL_PAYMENT',
    /*
     * 预约抽签
     */
    RESERVE_DRAW_LOTS = 'RESERVE_DRAW_LOTS',
    /*
     * 支付有礼
     */
    PAYMENT_AWARD = 'PAYMENT_AWARD',
    /*
     * 集点卡
     */
    COLLECTION_POINT = 'COLLECTION_POINT',
    /*
     * 集卡活动
     */
    CARD_COLLECTION = 'CARD_COLLECTION',
    /*
     * 口令领券
     */
    WATCHWORD = 'WATCHWORD',
    /*
     * 签到有礼
     */
    SIGN_IN = 'SIGN_IN',
    /*
     * 完善有礼
     */
    IMPROVE_INFORMATION = 'IMPROVE_INFORMATION',
    /*
     * 大转盘
     */
    WHEEL_DRAW = 'WHEEL_DRAW',
    /*
     * 红包雨
     */
    RED_PACKAGE_RAIN = 'RED_PACKAGE_RAIN',
    /*
     * 老虎机
     */
    SLOT_MACHINE = 'SLOT_MACHINE',
    /*
     * 拼手气红包
     */
    LUCKY_RED_PACKAGE = 'LUCKY_RED_PACKAGE',
    /*
     * 开盒有礼
     */
    OPEN_BOX = 'OPEN_BOX',
    /*
     * 盲盒营销
     */
    BLIND_BOX = 'BLIND_BOX',
    /*
     * 节日有礼
     */
    FESTIVAL_MARKETING = 'FESTIVAL_MARKETING',
    /*
     * 会员折扣
     */
    MEMBER_CARD_DISCOUNT = 'MEMBER_CARD_DISCOUNT',
    /*
     * 积分刮刮卡
     */
    SCRATCH_CARD = 'SCRATCH_CARD',
    /*
     * 积分抽奖码
     */
    LOTTERY_CODE = 'LOTTERY_CODE',
    /*
     * 购卡营销活动
     */
    STORED_VALUE_CARD_REWARD = 'STORED_VALUE_CARD_REWARD',
}
