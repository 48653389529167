import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { ActivityRewardInvited } from '../../../../components/activity-reward-invited';

/**
 *  邀请有礼，被邀请人奖励
 */
export class ObjectActivityRewardInvitedMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <ActivityRewardInvited {...controlInfo} />;
    }
}
