import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { ArrayBalanceCoupons } from './array-balance-coupons';

/**
 * 余额券抵扣类型选择
 */
export class ArrayBalanceCouponsMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <ArrayBalanceCoupons {...displayInfo} />;
    }
}
