import React from 'react';
import { modes } from '@comall-backend-builder/types';
import { ArrayTagIdsSelect } from './array-tag-ids-select';

export class ArrayTagIdsSelectMode extends modes.ArrayMode {
    /**
     * 获取输入组件
     */
    getControlComponent(displayInfo: any): JSX.Element {
        return <ArrayTagIdsSelect {...displayInfo} />;
    }
}
