import React, { Component } from 'react';
import { InputNumber, Radio } from 'antd';
import './index.less';
import { services } from '@comall-backend-builder/core';
import { cloneDeep } from 'lodash';

interface Props {
    onChange: (value: CollectionPointsTimesRuleValue) => void;
    value: CollectionPointsTimesRuleValue;
}

const enum Type {
    /**
     * 单笔消费满
     */
    CONSUMPTION = 'CONSUMPTION',
    /**
     * 单笔消费每满
     */
    CONSUMPTION_PER_FULL = 'CONSUMPTION_PER_FULL',
}

interface CollectionPointsTimesRuleValue {
    type: Type;
    consumptionCount?: number;
    consumptionPerFullCount?: number;
}

export class CollectionPointsTimesRule extends Component<Props, unknown> {
    componentDidMount() {
        const { value, onChange } = this.props;
        if (!value) {
            const defalutValue = {
                type: Type.CONSUMPTION,
                consumptionCount: undefined,
                consumptionPerFullCount: undefined,
            };
            onChange(defalutValue);
        }
    }

    changeValue = (inputValue: number, type: string) => {
        const { value, onChange } = this.props;
        const newValue = cloneDeep(value);
        if (type === 'consumptionCount') {
            newValue.consumptionCount = inputValue;
        } else if (type === 'consumptionPerFullCount') {
            newValue.consumptionPerFullCount = inputValue;
        }
        onChange(newValue);
    };

    changeLimits = (e: any) => {
        const { value, onChange } = this.props;
        const type: any = e.target.value;
        const newValue = cloneDeep(value);
        newValue.type = type;
        if (type === Type.CONSUMPTION) {
            newValue.consumptionCount = undefined;
        } else if (type === Type.CONSUMPTION_PER_FULL) {
            newValue.consumptionPerFullCount = undefined;
        }
        onChange(newValue);
    };

    render() {
        const { value } = this.props;
        if (!value) {
            return null;
        }
        return (
            <div className="collection-points-times-rule">
                <Radio.Group onChange={this.changeLimits} value={value.type}>
                    <div className="item">
                        <Radio value={Type.CONSUMPTION}>
                            {services.language.getText('dbxfm')}&nbsp;&nbsp;
                            <InputNumber
                                disabled={value.type === Type.CONSUMPTION ? false : true}
                                placeholder={services.language.getText('inputPlease')}
                                value={value.consumptionCount}
                                min={0.01}
                                max={999999.99}
                                precision={2}
                                onChange={(value: any) =>
                                    this.changeValue(value, 'consumptionCount')
                                }
                            ></InputNumber>
                            <span className="item-count">
                                {services.language.getText('hdygjd')}
                            </span>
                        </Radio>
                    </div>
                    <div className="item">
                        <Radio value={Type.CONSUMPTION_PER_FULL}>
                            {services.language.getText('dbxfmm')}&nbsp;&nbsp;
                            <InputNumber
                                disabled={value.type === Type.CONSUMPTION_PER_FULL ? false : true}
                                placeholder={services.language.getText('inputPlease')}
                                value={value.consumptionPerFullCount}
                                min={0.01}
                                max={999999.99}
                                precision={2}
                                onChange={(value: any) =>
                                    this.changeValue(value, 'consumptionPerFullCount')
                                }
                            ></InputNumber>
                            <span className="item-count">
                                {services.language.getText('hdygjd')}
                            </span>
                        </Radio>
                    </div>
                </Radio.Group>
            </div>
        );
    }
}
