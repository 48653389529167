import React, { PureComponent } from 'react';
import { message as AntMessage, Button, Modal, message } from 'antd';
import { services } from '@comall-backend-builder/core';

import './index.less';
import 'antd/dist/antd.css';
import { language } from '@comall-backend-builder/core/lib/services';

const { api } = services;

interface Resource {
    resourceId: number;
    resourceName: string;
    resourceUrl: string;
    type: string;
}

/**
 * 审核按钮
 */
export class AuditButton extends PureComponent<
    any,
    { showModal: boolean; auditRemark: string; submitting: boolean }
> {
    state = {
        showModal: false,
        auditRemark: '',
        submitting: false,
    };

    toggleModal = () => {
        const { showModal } = this.state;
        this.setState({ showModal: !showModal });
    };

    onConfirm = () => {
        this.setState({
            submitting: true,
        });
        const { confirm, row, entity, params: componentProps } = this.props;
        const { auditRemark } = this.state;
        const apiRoot = confirm.apiRoot;
        const apiPath = confirm.apiPath.replace(/{id}/, row.id);
        const params = confirm.params ? confirm.params : {};
        params.id = row.id;
        params.auditRemark = auditRemark;
        api.put(params, { apiRoot, apiPath })
            .then(() => {
                this.toggleModal();
                AntMessage.success(language.getText('shcg'));
                entity.search(componentProps);
            })
            .catch((err) => {
                const {
                    body: { err_msg },
                } = err.response;
                if (err_msg) {
                    AntMessage.warning(err_msg);
                }
            })
            .finally(() => {
                this.setState({ submitting: false });
            });
    };

    onCancel = () => {
        const { cancel, row, entity, params: componentProps, remarkName } = this.props;
        const { auditRemark } = this.state;
        if (!auditRemark || auditRemark.length < 0) {
            message.warning(language.getText('qtxyybz'));
            return;
        }
        this.setState({
            submitting: true,
        });
        const apiRoot = cancel.apiRoot;
        const apiPath = cancel.apiPath.replace(/{id}/, row.id);
        const params = cancel.params ? cancel.params : {};
        params.id = row.id;
        params[remarkName ? remarkName : 'auditRemark'] = auditRemark;
        api.put(params, { apiRoot, apiPath })
            .then(() => {
                this.toggleModal();
                AntMessage.success(language.getText('shcg'));
                entity.search(componentProps);
            })
            .catch((err) => {
                const {
                    body: { err_msg },
                } = err.response;
                if (err_msg) {
                    AntMessage.warning(err_msg);
                }
            })
            .finally(() => {
                this.setState({ submitting: false });
            });
    };

    onInputReason = (e: any) => {
        this.setState({
            auditRemark: e.target.value,
        });
    };

    render() {
        const { showModal, submitting } = this.state;
        const { text, confirm, cancel, content, disabled } = this.props;
        return (
            <div className="audit-button">
                <Button
                    disabled={disabled}
                    type="link"
                    className="btn-data-statistics"
                    onClick={this.toggleModal}
                >
                    {text}
                </Button>
                <Modal
                    onCancel={this.toggleModal}
                    visible={showModal}
                    footer={[
                        <Button key="confirm" onClick={this.onConfirm} disabled={submitting}>
                            {confirm.text}
                        </Button>,
                        <Button
                            key="concel"
                            type="primary"
                            onClick={this.onCancel}
                            disabled={submitting}
                        >
                            {cancel.text}
                        </Button>,
                    ]}
                >
                    {content && (
                        <div className="audit-button-content">
                            <span className="audit-button-content-title">{content.text}</span>
                            <textarea
                                className="audit-button-content-textarea"
                                onInput={this.onInputReason}
                                rows={3}
                                placeholder={language.getText('yyzzlt')}
                            ></textarea>
                        </div>
                    )}
                </Modal>
            </div>
        );
    }
}
