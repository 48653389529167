import { services } from '@comall-backend-builder/core';
import { Config } from '@comall-backend-builder/core/lib/parser';

export const config: Config = {
    entities: {
        redPackageRainEntity: {
            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
            apiPath: '/loader/admin/redPackageRain',
            filters: {
                subSiteId: {
                    type: 'string.options.autoComplete',
                    displayName: '<<subsiteName>>',
                    options: [],
                    source: {
                        apiPath: '/admin/defaults/subsite',
                    },
                },
                name: {
                    type: 'string',
                    displayName: '<<activityName>>',
                    controlConfig: { placeholder: '<<qsryhqlbmc>>' },
                },
                executeStatus: {
                    type: 'string.options.select',
                    displayName: '<<activityStatus>>',
                    defaultValue: 'ALL',
                    options: [
                        { id: 'ALL', name: '<<all>>' },
                        { id: 'NOT_STARTED', name: '<<notStarted>>' },
                        { id: 'STARTED', name: '<<inprogress>>' },
                        { id: 'COMPLETE', name: '<<end>>' },
                    ],
                    controlConfig: { style: { width: 150 } },
                },
            },
            properties: {
                id: {
                    type: 'string',
                },
                pageShowId: {
                    type: 'number',
                },
                editExecuteStatus: {
                    type: 'string',
                },
                baseInfo: {
                    type: 'object.subForm',
                    displayName: '<<otherBaseInfo>>',
                    properties: {
                        name: {
                            type: 'string',
                            displayName: '<<activityName>>',
                            rules: [{ required: true, whitespace: true, message: '<<qsrhdmc>>' }],
                            controlConfig: {
                                placeholder: '<<activityNameRequired>>',
                                maxLength: 20,
                                style: {
                                    width: 450,
                                },
                            },
                        },
                        dateRange: {
                            type: 'object.dateTimeRange.tip',
                            displayName: '<<acitivityTime>>',
                            rules: [{ required: true, message: '<<acitivityTimeRequired>>' }],
                            controlConfig: {
                                style: {
                                    width: 450,
                                },
                                range: {
                                    start: new Date(),
                                },
                            },
                        },
                        subsiteIds: {
                            type: 'array.subsites.selector',
                            displayName: '<<sxmd_1>>',
                            rules: [{ required: true }],
                        },
                        marketingSchedule: {
                            type: 'array.marketing.schedule',
                            displayName: '<<marketingSchedule>>',
                            defaultValue: [],
                            controlConfig: {
                                tips: '<<marketingScheduleTip>>',
                                getParams: (row: any) => {
                                    const subsiteIds =
                                        row.baseInfo?.subsiteIds?.length &&
                                        row.baseInfo.subsiteIds
                                            .map((item: any) => item.id)
                                            .join(',');
                                    return { subsiteIds };
                                },
                            },
                        },
                        // subsiteIds: {
                        //     type: 'array.options.autoComplete',
                        //     className: 'custom-array-options',
                        //     displayName: '<<sxmd_1>>',
                        //     options: [],
                        //     source: {
                        //         apiPath: '/admin/defaults/subsite',
                        //     },
                        //     rules: [
                        //         {
                        //             required: true,
                        //         },
                        //     ],
                        //     controlConfig: {
                        //         placeholder: '请选择生效门店',
                        //         style: {
                        //             width: 450,
                        //         },
                        //     },
                        // },
                    },
                },
                ruleInfo: {
                    type: 'object.subForm',
                    displayName: '<<gzpz>>',
                    collapsed: true,
                    properties: {
                        activityPeriodRewardLimit: {
                            type: 'number.tip',
                            displayName: '<<drcssx>>',
                            rules: [
                                {
                                    required: true,
                                    type: 'number',
                                    min: 1,
                                    max: 99,
                                    message: '<<rewardLimitFontSize>>',
                                    validator: (rule: any, value: any) => {
                                        var reg = /^([1-9]\d*)$/;
                                        if (value && reg.test(value) && value > 0 && value < 100) {
                                            return true;
                                        } else {
                                            return false;
                                        }
                                    },
                                },
                            ],
                            controlConfig: {
                                style: { width: '150px' },
                                placeholder: '<<inputPlease>>',
                                addonbefore: '<<mrmtzdkcy>>',
                                addonafter: '<<frequency>>',
                                tip: '',
                            },
                        },
                    },
                },
                pictureInfo: {
                    type: 'object.subForm',
                    displayName: '<<pageDecoration>>',
                    collapsed: true,
                    properties: {
                        backgroundPicture: {
                            displayName: '<<hdbjt>>',
                            type: 'array.imageObjectArrayForTopPic',
                            className: 'sigle-image-field',
                            defaultValue: [
                                {
                                    id: '0',
                                    path:
                                        'https://b-puzhehei-cdn.co-mall.net/global/magic-backend/red-package-rain/red-package-rain-picture.png',
                                },
                            ],
                            rules: [{ required: true }],
                            controlConfig: {
                                maxCount: 1,
                                multiple: false,
                                fileName: 'fileName',
                                uploadType: 'multi',
                                uploadUrl: `${ENV.API_ROOT}/admin/images/upload`,
                                mode: 'picture-card',
                                extra: {
                                    tip: '<<backgroundPictureLimit>>',
                                },
                            },
                        },
                        description: {
                            type: 'string.richTextPlus',
                            displayName: '<<activityRule>>',
                            rules: [
                                { whitespace: true, max: 20000, message: '<<notMoreThan2000>>' },
                            ],
                            controlConfig: {
                                placeholder: '<<descriptionRule>>',
                                toolbarId: 'descriptionRichText',
                                style: {
                                    height: 300,
                                },
                                uploadConfig: {
                                    apiPath: `/admin/images/upload`,
                                    fileName: 'fileName',
                                },
                            },
                            extra: '<<descriptionExtra>>',
                        },
                    },
                },
                prizeInfo: {
                    type: 'object.subForm',
                    displayName: '<<jpsz>>',
                    collapsed: true,
                    properties: {
                        assetRewards: {
                            type: 'array.rewardSetting',
                            displayName: '<<jpsz>>',
                            controlConfig: {
                                eventType: 'RED_PACKAGE_RAIN',
                            },
                        },
                    },
                },
                subSites: {
                    type: 'array.popover',
                    displayName: '<<subsiteName>>',
                },
                name: {
                    type: 'string.popover',
                    displayName: '<<activityName>>',
                },
                startTime: {
                    type: 'string.date',
                    displayName: '<<startTime>>',
                },
                endTime: {
                    type: 'string.date',
                    displayName: '<<endTime>>',
                },
                executeStatus: {
                    type: 'string.statusBadge',
                    displayName: '<<activityStatus>>',
                },
            },
        },
    },
    components: {
        redPackageRainView: {
            component: 'Viewport',
            entity: 'redPackageRainEntity',
        },
        redPackageRainPage: {
            component: 'FlexLayout',
            direction: 'vertical',
            className: 'table-content-viewport',
            items: [
                {
                    component: 'redPackageRainFilter',
                },
                {
                    component: 'FlexLayout',
                    direction: 'vertical',
                    className: 'mt24 mb24',
                    items: [
                        {
                            component: 'GridLayout',
                            items: [
                                {
                                    component: 'Button',
                                    text: '<<addActivity>>',
                                    type: 'primary',
                                    icon: 'plus',
                                    span: 4,
                                    route: '/menu-list/market/red-package-rain/add',
                                },
                            ],
                        },
                    ],
                },
                { component: 'redPackageRainTable' },
            ],
        },
        redPackageRainFilter: {
            component: 'FilterFormPlus',
            direction: 'inline',
            submit: {
                text: '<<common.search>>',
            },
            reset: {
                text: '<<common.reset>>',
            },
            fields: [
                {
                    property: 'subSiteId',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
                {
                    property: 'name',
                    controlConfig: {
                        style: { width: 300 },
                    },
                },
                {
                    property: 'executeStatus',
                    controlConfig: {
                        style: { width: 200 },
                    },
                },
            ],
        },
        redPackageRainTable: {
            component: 'DataTable',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            columns: [
                {
                    property: 'subSites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: '<<subsites>>',
                    },
                },
                {
                    property: 'name',
                    width: 180,
                },
                {
                    property: 'startTime',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'endTime',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '180px',
                        },
                    },
                },
                {
                    property: 'executeStatus',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: '<<notStarted>>',
                                status: 'success',
                            },
                            STARTED: {
                                text: '<<inprogress>>',
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: '<<end>>',
                                status: 'default',
                            },
                        },
                    },
                },
            ],
            actionColumn: {
                title: '<<common.tableAction>>',
                className: 'action-column',
                width: 240,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}`,
                                    apiPath:
                                        '/AFFILIATE-MARKETING/admin/red_package_rain_activities/:id/disabled',
                                    config: {
                                        content: '{{row.name}}<<areadyClosed>>',
                                        text: '<<common.close>>',
                                    },
                                    confirm: {
                                        text: '<<isCloseActivity>>{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}`,
                                    apiPath:
                                        '/AFFILIATE-MARKETING/admin/red_package_rain_activities/:id/enabled',
                                    config: {
                                        content: '{{row.name}}<<areadyOpend>>',
                                        text: '<<common.opend>>',
                                    },
                                    confirm: {
                                        text: '<<isAreadyOpend>>{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: '<<common.edit>>',
                        statusKey: 'row.canEdit',
                        path: '/menu-list/market/red-package-rain/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: '<<copy>>',
                        path: '/menu-list/market/red-package-rain/copy/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'ExportHandlerButton',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/AFFILIATE-MARKETING`,
                            apiPath: '/admin/red_package_rain_activities/{{row.id}}/export.xls',
                        },
                    },
                ],
            },
        },
        redPackageRainAddPage: {
            component: 'FlexLayout',
            entity: 'redPackageRainEntity',
            direction: 'horizontal',
            items: [
                { component: 'RedPackageRainPreview' },
                { component: 'RedPackageRainAddFormView' },
            ],
        },
        redPackageRainEditPage: {
            component: 'FlexLayout',
            entity: 'redPackageRainEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [
                { component: 'RedPackageRainPreview' },
                { component: 'RedPackageRainEditFormView' },
            ],
        },
        redPackageRainCopyPage: {
            component: 'FlexLayout',
            entity: 'redPackageRainEntity',
            loaderType: 'get',
            direction: 'horizontal',
            items: [
                { component: 'RedPackageRainPreview' },
                { component: 'RedPackageRainCopyFormView' },
            ],
        },
    },
    routes: [
        {
            path: '/',
            childRoutes: [
                {
                    path: '/menu-list/market/red-package-rain',
                    component: 'redPackageRainView',
                    breadcrumbName: '<<hbyhd>>',
                    privilege: {
                        path: 'redPackageRain',
                        level: 'full',
                    },
                    childRoutes: [
                        {
                            path: '/add',
                            component: 'redPackageRainAddPage',
                            breadcrumbName: '<<addActivity>>',
                        },
                        {
                            path: '/edit/:id',
                            component: 'redPackageRainEditPage',
                            breadcrumbName: '<<editActivity>>',
                        },
                        {
                            path: '/copy/:id',
                            component: 'redPackageRainCopyPage',
                            breadcrumbName: '<<copyActivity>>',
                        },
                        { path: '/', component: 'redPackageRainPage' },
                    ],
                },
            ],
        },
    ],
};
