import React, { PureComponent } from 'react';
import { Form } from 'antd';
import { tools } from '@comall-backend-builder/design-components-basis';

import './index.less';
/**
 * 首页内容列表组件配置表单
 */
class contentNoteIndex extends PureComponent<any> {
    render() {
        return (
            <Form className="editor-content-note-index">
                <div className="tips">固定样式暂不支持配置</div>
            </Form>
        );
    }
}

export const ContentNoteIndex = tools.ConfigFormDecorator(contentNoteIndex);
