import { forIn, defaults, merge } from 'lodash';

import { builder, services, globalConfig } from '@comall-backend-builder/core';
import * as defaultComponents from '@comall-backend-builder/components-basis';
import { LanguageConfig } from '@comall-backend-builder/design-components-basis';
import { defaultTypes } from '@comall-backend-builder/types';
import { AVAILABLE_LANGUAGES } from '@comall-backend-builder/core/lib/services/language';
import './services';

import * as myComponents from './components';
import * as myContainers from './containers';
import { zhCN } from './language/zh-CN';
import {
    zhCN as designZhCN,
    // enUS as designEnUS,
    zhHK as designZhHK,
    testLanguage,
} from './applications/design/languages';
// import antdEnUSLanguage from 'antd/lib/locale-provider/en_US';
import antdZhCNLanguage from 'antd/lib/locale-provider/zh_CN';
import antdZhTWLanguage from 'antd/lib/locale-provider/zh_TW';
import 'moment/locale/zh-cn';

let zhCNPreset = globalConfig.get('language.zhCNPreset');
// let enUSPreset = globalConfig.get('language.enUSPreset');

builder.registerLanguage(
    merge(
        {},
        zhCNPreset,
        {
            context: designZhCN,
            antdLocale: antdZhCNLanguage,
        },
        {
            context: zhCN,
        },
        {
            context: LanguageConfig.zhCN,
        }
    )
);

// builder.registerLanguage(
//     merge(
//         {},
//         enUSPreset,
//         { context: designEnUS, antdLocale: antdEnUSLanguage },
//         {
//             context: LanguageConfig.enUS,
//         }
//     )
// );

builder.registerLanguage(
    merge(
        { id: 'zh-HK', name: '香港繁体', momentLocale: 'zh-hk' },
        {},
        { context: designZhHK, antdLocale: antdZhCNLanguage },
        {}
    )
);

builder.registerLanguage(
    merge(
        { id: 'test-language', name: '测试语言', momentLocale: 'en' },
        {},
        { context: testLanguage, antdLocale: antdZhTWLanguage },
        {}
    )
);

services.localStorage.prefix = 'comall-backend-magic-';
services.localStorage.token = 'comall-magic-token';

if (ENV.TENANT === 'eslitehk' || ENV.TENANT === 'eslitehktest') {
    services.localStorage.set('language', 'zh-HK');
}

// services.localStorage.set('language', 'zh-HK');

builder.init({
    'api.token': 'magic-api-token',
    'features.i18n': true,
    'features.withCredentials': true,
});

console.log('AVAILABLE_LANGUAGES', AVAILABLE_LANGUAGES);

forIn(defaults(defaultComponents, myComponents, myContainers), (component, name) => {
    builder.registerComponent(name, component);
});

defaultTypes.forEach((type, name) => {
    builder.registerType(name, type);
});
