import React, { PureComponent } from 'react';
import { Form } from 'antd';
import { tools } from '@comall-backend-builder/design-components-basis';
import { DrawHotarea } from '../../../../components';

const FormItem = Form.Item;
const UploadPicture = tools.UploadPicture;
/**
 * 会员卡组件-配置表单
 */
class hotArea extends PureComponent<any> {
    render() {
        const { form, sourceRoot, uploadAction, value } = this.props;

        const { getFieldDecorator } = form;
        const formItemLayout = tools.FormItemLayout;

        return (
            <Form className="editor-member-card">
                <FormItem {...formItemLayout} label={'图片'}>
                    {getFieldDecorator('img')(
                        <UploadPicture
                            className="image-uploader"
                            sourceRoot={sourceRoot}
                            uploadAction={uploadAction}
                        />
                    )}
                    <p className="upload-picture-tips">图片建议宽度为750px</p>
                </FormItem>
                <FormItem {...formItemLayout} label={'热区'}>
                    {getFieldDecorator('area')(
                        <DrawHotarea
                            items={value.area ? value.area.items : null}
                            img={value.img}
                        ></DrawHotarea>
                    )}
                </FormItem>
            </Form>
        );
    }
}

export const HotArea = tools.ConfigFormDecorator(hotArea);
