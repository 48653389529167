import React, { PureComponent } from 'react';
import { message } from 'antd';
import { services } from '@comall-backend-builder/core';
import { DesignPage } from '@comall-backend-builder/design-components-basis';
import { isNumber } from 'lodash';
import { CustomComponentSelectorMerchant } from '../../applications/design/design/components/custom-component-selector-merchant';
import { MERCHANT_DESIGN_PAGE_LINK_TYPES_LIST } from '../../applications/design/constants/page-design';
const api = services.api;

const tenantDomain = ENV.TENANT_DOMAIN || window.location.origin;

/**
 * 当前门店组
 */
let subsites: any = {};

let merchantId: number;

export { subsites, merchantId };
/**
 * 可视化--专柜详情页设计器
 */
export class PageDesignMerchant extends PureComponent<any> {
    componentWillUnmount() {
        subsites = {};
        merchantId = -1;
    }
    loadProfile = () => {
        const {
            params: { id },
            apiPath,
        } = this.props;
        return api.get({ id }, { apiPath: apiPath }).then((res: any) => {
            subsites = res.subsites;
            merchantId = res.merchantId;
            return res;
        });
    };
    onSave = (data: any) => {
        const { apiPath } = this.props;
        api.put(data, { apiPath: apiPath }).then(() => {
            const saveSuccess = services.language.getText('common.saveSuccess');
            message.success(saveSuccess);
            services.navigation.goBack();
        });
    };
    onCancel = () => {
        services.navigation.goBack();
    };

    getClientUrl = (profile: any, fixed: boolean) => {
        const { client } = this.props;
        let url = client + `?page=WYSIWG&mode=edit`;
        if (profile) {
            url = url + `&type=merchant&fixed=${fixed}&tenant=${encodeURIComponent(tenantDomain)}`;
        }
        if (subsites && subsites.length > 0) {
            url = url + `&subsites=${JSON.stringify(subsites)}`;
        }
        if (isNumber(merchantId)) {
            url = url + `&merchantId=${merchantId}`;
        }
        return url;
    };
    render() {
        const submitProps = {
            onClick: this.onSave,
        };
        const cancelProps = {
            onClick: this.onCancel,
        };
        return (
            <DesignPage
                clientUrl={this.getClientUrl}
                loadProfile={this.loadProfile}
                componentsSelector={(props) => <CustomComponentSelectorMerchant {...props} />}
                submit={submitProps}
                cancel={cancelProps}
                isTemplate={false}
                linkTypes={MERCHANT_DESIGN_PAGE_LINK_TYPES_LIST}
                verbose={process.env.NODE_ENV === 'development'}
                linkTypeMode={'cascader'}
                uploadAction={`${ENV.API_ROOT}/admin/images/upload`}
            ></DesignPage>
        );
    }
}
