import * as React from 'react';

import { ObjectMode } from '@comall-backend-builder/types';
import { MerchantCodeTaxrateRule } from './merchant-code-taxrate-rule';
import './index.less';
/**
 *  管理专柜商家码-税率
 */
export class MerchantCodeTaxrateRuleMode extends ObjectMode {
    /**
     * 获取输入组件
     */
    public getControlComponent(controlInfo: any) {
        return <MerchantCodeTaxrateRule {...controlInfo} />;
    }
}
