import React, { PureComponent, createElement } from 'react';
import { connect } from 'react-redux';
import { ComponentsManager, services } from '@comall-backend-builder/core';
import { language } from '@comall-backend-builder/core/lib/services';

class newMallActivityTable extends PureComponent<any, any> {
    state = { uploadModalVisible: false };
    componentWillReceiveProps(nextProps: any) {
        const { requestStatus } = this.props;
        const { requestStatus: nextRequestStatus } = nextProps;
        if (requestStatus === 'pending' && nextRequestStatus === 'success') {
            setTimeout(() => {
                const event = new Event('resize');
                window.dispatchEvent(event);
            }, 0);
        }
    }
    selectedRowKeys: string[] = [];
    onChange = (selectedRowKeys: string[]) => {
        this.selectedRowKeys = selectedRowKeys;
    };
    renderTable = () => {
        const { entity, componentId } = this.props;
        let tableProps = {
            entity,
            componentId,
            rowKey: 'id',
            loadFirstPage: true,
            pagination: {
                pageSize: 10,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ['10', '15', '20'],
                showTotal(total: number) {
                    return services.interpolate(services.language.getText('total'), {
                        total,
                    });
                },
            },
            scroll: { x: true },
            columns: [
                {
                    property: 'info',
                    width: 380,
                },
                {
                    property: 'type',
                },
                {
                    property: 'subSites',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: language.getText('subsites'),
                    },
                },
                {
                    property: 'merchants',
                    width: 180,
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '140px',
                        },
                        countSuffix: language.getText('merchant'),
                    },
                },
                {
                    property: 'participationStatus',
                },
                {
                    property: 'status',
                    displayConfig: {
                        className: 'ant-table-cell-ellipsis',
                        style: {
                            maxWidth: '100px',
                        },
                        statusConfig: {
                            NOT_STARTED: {
                                text: language.getText('notStarted'),
                                status: 'success',
                            },
                            STARTED: {
                                text: language.getText('inprogress'),
                                status: 'processing',
                            },
                            COMPLETE: {
                                text: language.getText('end'),
                                status: 'default',
                            },
                        },
                    },
                },
                { property: 'mallActivityStatisticTotal' },
                {
                    property: 'blackUserListFileUrl',
                    displayConfig: {
                        entity,
                    },
                },
            ],
            actionColumn: {
                title: services.language.getText('common.tableAction'),
                className: 'action-column',
                width: 190,
                fixed: 'right',
                items: [
                    {
                        type: 'component',
                        component: 'ChangeStatusButton',
                        config: {
                            renderComponent: 'Button',
                            statusKey: 'enable',
                            type: 'link',
                            style: { display: 'inline-flex' },
                            handles: [
                                {
                                    params: { status: false },
                                    value: true,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
                                    apiPath: '/admin/v1/mall_activities/:id/disabled',
                                    config: {
                                        content: '{{row.name}}' + language.getText('areadyClosed'),
                                        text: language.getText('gb'),
                                    },
                                    confirm: {
                                        text:
                                            services.language.getText('isCloseActivity') +
                                            '{{row.name}}?',
                                    },
                                },
                                {
                                    params: { status: true },
                                    value: false,
                                    apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
                                    apiPath: '/admin/v1/mall_activities/:id/enabled',
                                    config: {
                                        content: '{{row.name}}' + language.getText('areadyOpend'),
                                        text: language.getText('kq'),
                                    },
                                    confirm: {
                                        text: language.getText('isAreadyOpend') + '{{row.name}}?',
                                    },
                                },
                            ],
                        },
                    },
                    {
                        type: 'link',
                        text: services.language.getText('common.edit'),
                        path: '/new-mall-activity/edit/{{row.id}}',
                    },
                    {
                        type: 'link',
                        text: services.language.getText('copy'),
                        path: '/new-mall-activity/copy/{{row.id}}/copy',
                    },
                    // {
                    //     type: 'component',
                    //     component: 'EntityButton',
                    //     config: {
                    //         props: {
                    //             type: 'link',
                    //             disabled: false,
                    //             children: '报名记录',
                    //         },
                    //         propsHandler(buttonProps: any, entityButtonProps: any): any {
                    //             const id = entityButtonProps.row.id;
                    //             return {
                    //                 ...buttonProps,
                    //                 target: '_blank',
                    //                 className: 'table-action-column-item',
                    //                 href: '#/magic-mall-activity-registration?id=' + id,
                    //                 style: { padding: 0 },
                    //             };
                    //         },
                    //     },
                    // },

                    {
                        type: 'link',
                        text: language.getText('hdjl_1'),
                        path: '/new-mall-activity/record/{{row.id}}',
                    },
                    {
                        type: 'component',
                        component: 'ExportHandlerButton',
                        params: {
                            apiRoot: `${ENV.AUTH_API_ROOT}/MAGIC-MALL-ACTIVITY`,
                            apiPath: '/admin/v1/mall_activities/{{row.id}}/export',
                            text: language.getText('downloadData'),
                        },
                    },
                    {
                        type: 'component',
                        component: 'PageNewPreviewCodeButton',
                        config: {
                            codeTypes: 'all',
                            codeModalType: 'item',
                            codePageType: 'mallActivityPage',
                        },
                    },
                    {
                        text: language.getText('common.log'),
                        type: 'link',
                        path: '/new-mall-activity/logs/{{row.id}}',
                    },
                ],
            },
        };
        return createElement(ComponentsManager.get('DataTable'), tableProps);
    };

    render() {
        return <div>{this.renderTable()}</div>;
    }
}
function mapStateToProps(state: any, props: any) {
    const { entity, componentId } = props;
    let requestStatus = entity?.requestStatus;
    return {
        entity,
        componentId,
        requestStatus,
    };
}

export const NewMallActivityTable = connect(mapStateToProps)(newMallActivityTable);
