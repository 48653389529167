import React, { PureComponent } from 'react';
import { message, Input, Button, Table, Modal as AntModal } from 'antd';
import { cloneDeep, find, remove } from 'lodash';

import './index.less';
import { language } from '@comall-backend-builder/core/lib/services';

interface TypeBase {
    id?: number;
    name: string;
    selectedRowKeys: any[];
}

interface MerchantBarCodeObject extends TypeBase {
    typeInfoInputValue?: string;
}

export interface MerchantBarCodeProps {
    value: MerchantBarCodeObject[] | undefined;
    disabled: boolean;
    /**
     * 内容改变回调
     * @param value 新值
     */
    onChange: (value: MerchantBarCodeObject[] | undefined) => void;
}
export class MerchantBarCode extends PureComponent<MerchantBarCodeProps, any> {
    constructor(props: any) {
        super(props);
        this.state = { typeInputValue: '', selectedRowKeys: [] };
    }

    changeTypeInputValue = (e: any) => {
        this.setState({ typeInputValue: e.target.value });
    };

    // 增加
    onAddType = () => {
        const { value, onChange } = this.props;
        const { typeInputValue } = this.state;
        if (value && value.length >= 20) {
            message.error(language.getText('set20ProductBarCode'));
            return;
        }
        if (!typeInputValue) {
            message.error(language.getText('barCodeNotEmpty'));
            return;
        }
        if (!/^[^\u4e00-\u9fa5]*$/.test(typeInputValue) || typeInputValue.includes(' ')) {
            message.error(language.getText('barCodeNoHasSpace'));
            return;
        }
        const newValue = value && value.length ? cloneDeep(value) : [];
        const hasValue = find(newValue, { name: typeInputValue.trim() });
        if (hasValue) {
            message.error(language.getText('barCodeNoRepeat'));
            return;
        }
        newValue.push({
            name: typeInputValue.trim(),
            selectedRowKeys: [],
        });
        this.setState({ typeInputValue: '' });
        onChange(newValue);
    };
    // 删除
    deleteTypeItem = (name: string) => {
        const { value, onChange } = this.props;
        const newValue = value && value.length ? cloneDeep(value) : [];
        remove(newValue, { name });
        onChange(newValue);
    };
    // 批量删除
    deleteAllTypeItem = () => {
        const { value, onChange } = this.props;
        const { selectedRowKeys } = this.state;
        if (!selectedRowKeys || selectedRowKeys.length <= 0) {
            message.error(language.getText('selectDeleteBarCode'));
            return;
        }

        AntModal.confirm({
            title: language.getText('tip'),
            content: language.getText('isDeleteSelectedBarCode'),
            onOk: () => {
                const newValue = value && value.length ? cloneDeep(value) : [];
                var filteredArr = newValue.filter((item) => !selectedRowKeys.includes(item.name));
                // remove(newValue, { name });
                this.setState({ selectedRowKeys: [] });
                onChange(filteredArr);
            },
            okText: language.getText('common.confirm'),
            cancelText: language.getText('common.cancel'),
        });
    };

    onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        // setSelectedRowKeys(newSelectedRowKeys);
        this.setState({ selectedRowKeys: newSelectedRowKeys });
    };
    render() {
        const { value, disabled } = this.props;
        const { typeInputValue, selectedRowKeys } = this.state;
        const columns = [
            {
                title: language.getText('productBarCode'),
                dataIndex: 'name',
                key: 'name',
                width: 200,
            },
        ];
        const rowSelection = {
            selectedRowKeys,
            onChange: this.onSelectChange,
        };
        return (
            <div className="feedback-types">
                <div className="types">
                    {/* <div className="types-left">商品条码：</div> */}
                    <div className="types-right">
                        <Input
                            className="type-input"
                            maxLength={100}
                            value={typeInputValue}
                            onChange={this.changeTypeInputValue}
                            placeholder={language.getText('inputAddBarCode')}
                        />
                        <Button type="link" onClick={this.onAddType}>
                            {language.getText('addBarCode')}
                        </Button>
                    </div>
                </div>
                <div className="form-extra">{language.getText('has13StandardCode')}</div>
                {value && value.length > 0 && (
                    <div>
                        <Button onClick={this.deleteAllTypeItem}>
                            {language.getText('batchDeleteBarCode')}
                        </Button>
                        <Table
                            className="role-selector-table"
                            rowSelection={rowSelection}
                            pagination={false}
                            rowKey={'name'}
                            bordered={false}
                            dataSource={value}
                            columns={[
                                ...columns,
                                {
                                    title: language.getText('common.tableAction'),
                                    className: 'action-column',
                                    width: 100,
                                    render: (text, record) => {
                                        return (
                                            <Button
                                                type="link"
                                                disabled={disabled}
                                                onClick={() => this.deleteTypeItem(record.name)}
                                            >
                                                {language.getText('common.delete')}
                                            </Button>
                                        );
                                    },
                                },
                            ]}
                        />
                    </div>
                )}
            </div>
        );
    }
}
